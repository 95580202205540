import { Tooltip } from '@material-ui/core';
import { getIDB } from '@src/util/localForage_idb_controller';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { getFormattedDate, getFormattedTextStatus, textColor } from '../../result';
import { RESULT_INFO_PANEL, TOOLTIP_NAME } from '@src/common/ui-constants';
import { Icon } from 'fireflink-ui';

function ResultInfoPanel(props) {
  let history = useHistory();
  let activeTab = history.location.pathname;
  const hideClickableLink = activeTab.includes('/script');

  const focusMoreDetails = () => {
    props.focusMoreDetails(true);
  };
  // temporary fix for file download
  let executionStatus = async () => await getIDB('execResult')();
  return (
    <div className="p-3">
      <div className="grid grid-cols-5 gap-1 items-center">
        <div className="flex-1">
          <label className=" details-key-style-common">{RESULT_INFO_PANEL.EXECUTION_ID}</label>
          <Tooltip title={props.data.runId} placement="bottom-start">
            <div className="text-value truncate">{props.data.runId}</div>
          </Tooltip>
        </div>
        <div className="flex-1">
          <label className=" details-key-style-common">{RESULT_INFO_PANEL.STATUS}</label>

          {/* temporary fix for file download */}
          <Tooltip
            title={getFormattedTextStatus(
              executionStatus?.responseObject?.executionResponses[0]?.executionStatus === 'Terminated'
                ? 'Terminated'
                : props?.data?.status
            )}
            placement="bottom-start"
          >
            <div
              className={`text-value truncate ${textColor(executionStatus?.responseObject?.executionResponses[0]?.executionStatus === 'Terminated' ? 'Terminated' : props?.data?.status)}`}
            >
              {getFormattedTextStatus(
                executionStatus?.responseObject?.executionResponses[0]?.executionStatus === 'Terminated'
                  ? 'Terminated'
                  : props.data?.status
              )}
            </div>
          </Tooltip>
        </div>
        <div className="flex-1">
          {props.resultType === 'module' || props.resultType === 'root' ? (
            <>
              <label className=" details-key-style-common">{RESULT_INFO_PANEL.MODULE_NAME}</label>
              <Tooltip
                title={
                  props.data.moduleName && props.data.moduleName.toLowerCase() === 'root'
                    ? props.data.moduleName.concat(' Module')
                    : props.data.moduleName
                }
                placement="bottom-start"
              >
                <div className="text-value truncate">
                  {props.data.moduleName && props.data.moduleName.toLowerCase() === 'root'
                    ? props.data.moduleName.concat(' Module')
                    : props.data.moduleName}
                </div>
              </Tooltip>
            </>
          ) : props.resultType === 'suite' ? (
            <>
              <label className=" details-key-style-common">{RESULT_INFO_PANEL.SUITE_NAME}</label>
              <Tooltip
                title={props.data.suiteName.replace(/%20/g, ' ').replace(/%23P%23I/g, '(')}
                placement="bottom-start"
              >
                <div className="text-value truncate">
                  {props.data.suiteName.replace(/%20/g, ' ').replace(/%23P%23I/g, '(')}
                </div>
              </Tooltip>
            </>
          ) : (
            <>
              <label className=" details-key-style-common">{RESULT_INFO_PANEL.SCRIPT_NAME}</label>
              <Tooltip title={props.data.scriptName} placement="bottom-start">
                <div className="text-value truncate">{props.data.scriptName}</div>
              </Tooltip>
            </>
          )}
        </div>
        <div className="flex-1">
          <label className=" details-key-style-common">{RESULT_INFO_PANEL.MACHINE}</label>
          <Tooltip title={props.data.machine ? props.data.machine : 'N/A'} placement="bottom-start">
            <div className="text-value truncate">{props.data.machine ? props.data.machine : 'N/A'}</div>
          </Tooltip>
        </div>
        <div className="flex-1 truncate">
          <label className=" details-key-style-common" title="Execution Environment">
            {RESULT_INFO_PANEL.EXECUTION_ENVIRONMENT}
          </label>

          <Tooltip title={props.data.execution ? props.data.execution : 'Local'} placement="bottom-start">
            <div className="text-value truncate">{props.data.execution ? props.data.execution : 'Local'}</div>
          </Tooltip>
        </div>
        <div className="flex-1 mt-10 truncate">
          <label className=" details-key-style-common" title="OS">
            {RESULT_INFO_PANEL.OS}
          </label>
          {props.data.os &&
            props.data.os.map((os) => {
              if (hideClickableLink) {
                return (
                  <Tooltip title={os} placement="bottom-start">
                    <div className="text-value truncate">
                      {os} {props?.data?.osVersion ? props?.data?.osVersion : ''}
                    </div>
                  </Tooltip>
                );
              } else {
                return (
                  <Tooltip title={os} placement="bottom-start">
                    <div
                      className="text-value truncate cursor-pointer link-text-color"
                      onClick={() => focusMoreDetails()}
                    >
                      {os} {props?.data?.osVersion ? props?.data?.osVersion : ''}
                    </div>
                  </Tooltip>
                );
              }
            })}
        </div>
        {props.data.browser && !!props.data.browser[0] && props.projectType !== 'Mobile' ? (
          <div className="flex-1 mt-10">
            <label className=" details-key-style-common">{RESULT_INFO_PANEL.BROWSER}</label>
            <Tooltip title={props?.data?.browser} placement="bottom-start">
              {hideClickableLink ? (
                <div className="text-value truncate">{props?.data?.browser}</div>
              ) : (
                <div className="text-value truncate cursor-pointer link-text-color" onClick={() => focusMoreDetails()}>
                  {props?.data?.browser}
                </div>
              )}
            </Tooltip>
          </div>
        ) : (
          ''
        )}
        {props.data.deviceName ? (
          <div className="flex-1 mt-10">
            <label className=" details-key-style-common">{RESULT_INFO_PANEL.DEVICE}</label>
            <Tooltip title={props.data.deviceName ? props.data.deviceName : 'N/A'} placement="bottom-start">
              {hideClickableLink ? (
                <div className="text-value truncate">{props.data.deviceName ? props.data.deviceName : 'N/A'}</div>
              ) : (
                <div className="text-value truncate cursor-pointer link-text-color" onClick={() => focusMoreDetails()}>
                  {props.data.deviceName ? props.data.deviceName : 'N/A'}
                </div>
              )}
            </Tooltip>
          </div>
        ) : (
          ''
        )}
        {props.data.duration ? (
          <div className="flex-1 mt-10">
            <div className="flex gap-1 cursor-pointer">
              <label className=" details-key-style-common">{RESULT_INFO_PANEL.DURATION} </label>

              <Tooltip
                title={
                  props.scriptType === 'Script' ? TOOLTIP_NAME.SCRIPT_TOOLTIP_NAME : TOOLTIP_NAME.MODULE_TOOLTIP_NAME
                }
                placement="top"
              >
                <span className="mt-1">
                  <Icon name="info_icon" width={14} height={14} />
                </span>
              </Tooltip>
            </div>
            <Tooltip title={props.data.duration} placement="bottom-start">
              <div className="text-value truncate">{props.data.duration}</div>
            </Tooltip>
          </div>
        ) : (
          ''
        )}
        <div className="flex-1 mt-10">
          <label className=" details-key-style-common">{RESULT_INFO_PANEL.EXECUTED_ON}</label>
          <Tooltip title={getFormattedDate(props.data.executedOn, 'full')} placement="bottom-start">
            <div className="text-value truncate">{getFormattedDate(props.data.executedOn, 'full')}</div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default ResultInfoPanel;

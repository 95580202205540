import { Checkbox } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { getAllUserByProjectIdRequest } from '../../api/api_services';
import { addUsersListReq } from '../../api/api_services';
import { getSelectedProject } from '../../service/common_service';
import { useAlert } from '../common/alert_service/useAlert';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { ListItemIcon } from '@mui/material';
import './user_page.css';
import { ReactComponent as ShutCase2 } from '../../assets/cases_black_24dp.svg';
import { ReactComponent as ShutCase1 } from '../../assets/work_outline_black_24dpOne.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const useStyles = makeStyles((theme) => ({
  hoverColor: {
    '&:hover': {
      color: '#1648C6',
    },
  },
  whiteColor: {
    color: '#727171',
  },
  iconOpen: {
    transform: 'none',
  },
  rootMenuItem: {
    color: '#727171',
    width: '123%',
    marginLeft: '-20px',
    paddingLeft: '30px',
    height: '36px',

    '&:hover': {
      background: '#F0F3F9',
      color: '#1648C6 !important',
    },
  },
  menuItem: {
    fontStyle: 'bold',

    width: '300px',
  },
  selectRoot: {
    '&:focus': {
      backgroundColor: 'transparent',
      width: '300px',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: '2px solid transparent',
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: '2px solid transparent',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '2px solid #1648C6',
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuPaper: {
    maxHeight: 196,
    width: 222,
    flex: 1.5,
    position: 'absolute',
    marginLeft: '-10px',
    marginTop: '4px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
}));
const IndividualUserTablePage = ({ ...props }) => {
  const { AlertContatiner, MyAlert } = useAlert();
  const [userDataAddArray, setUserDataAddArray] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [roleIndex, setRoleIndex] = useState();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();
  const [oldRole, setOldRole] = useState();
  const [showMenuItem, setShowMenuItem] = React.useState(true);
  const [selectedUserObject, setSelectedUserObject] = useState();

  useEffect(() => {}, [userDataAddArray]);
  function getuserDataAddArray(projectId) {
    getAllUserByProjectIdRequest(projectId)
      .then((results) => {
        const users = results.data && results.data.responseObject ? results.data.responseObject : [];
        const rolesList = results.data && results.data.responseMap ? results.data.responseMap.roles : [];
        const defaultRole = rolesList.length ? rolesList[0] : {};
        // Assign first role as default role to the user
        const usersList = [];
        if (props.action === 'Add user to Project') {
          users.forEach((user) => {
            usersList.push({
              ...user,
              role: defaultRole,
            });
          });
        }

        if (props.action === 'Edit User') {
          props.user.selected = true;
          usersList.push(props.user);
          if (props.user) {
            let selectedRoleObj = rolesList.find((rolObj) => rolObj.id === props.user.role.id);
            const index = rolesList.indexOf(selectedRoleObj);
            if (index > -1) {
              rolesList.splice(index, 1);
              rolesList.splice(0, 0, selectedRoleObj);
            }
          }
        }
        setUserDataAddArray(usersList);
        setRolesList(rolesList);
        if (results.data.responseObject.length === 0) {
          setLoading(false);
        }
      })
      .catch((error) => {});
  }
  React.useEffect(() => {
    const selectedProject = getSelectedProject();
    getuserDataAddArray(selectedProject.id);
  }, []);
  const changeUserRole = (roleIndex, user) => {
    // user.role = rolesList[roleIndex];
    // setNote(event.target.value);
    setRoleIndex(rolesList[roleIndex]);
    if (userDataAddArray && userDataAddArray.length) {
      let selectedUserObj = userDataAddArray.find((userObj) => userObj.id === user.id);
      if (selectedUserObj) {
        setOldRole(selectedUserObj.role);
        setSelectedUserObject(selectedUserObj);
        selectedUserObj.role = rolesList[roleIndex];
      }
    }
    setUser(user);
  };
  const onSelectUser = (checked, i) => {
    setUserDataAddArray((v) =>
      v.map((user, index) => {
        if (index === i) {
          if (checked) user.selected = true;
          else user.selected = false;
        }
        return user;
      })
    );
  };
  function selectAllUsers(event) {
    if (event.target.checked)
      setUserDataAddArray((v) =>
        v.map((user, index) => {
          user.selected = true;
          return user;
        })
      );
    else {
      // event.target.checked=false;
      setUserDataAddArray((v) =>
        v.map((user) => {
          user.selected = false;
          return user;
        })
      );
    }
  }
  const [createUpdateCalled, setCreateUpdateCalled] = React.useState(true);
  const addUpdateUser = () => {
    setCreateUpdateCalled(true);
    if (createUpdateCalled) {
      setCreateUpdateCalled(false);

      const selectedProject = getSelectedProject();
      const payload = [];
      user && (user.role = roleIndex);

      userDataAddArray.forEach((user) => {
        if (user.selected) {
          payload.push({
            id: user.id,
            projectRoles: [
              {
                projectId: selectedProject.id,
                projectName: selectedProject.name,
                roleId: user.role.id,
                roleName: user.role.name,
              },
            ],
          });
        }
      });

      if (!payload.length) {
        MyAlert.info('You should add atleast one user');
        return null;
      }

      addUsersListReq(selectedProject.id, payload)
        .then((results) => {
          if (results.data.responseObject) {
            props.MyAlert.success('added to the project successfully');
            props.saveCompleted();
          }
        })
        .catch((error) => {
          console.error(error);
          return null;
        });
    }
  };
  const addEditUpdateUser = () => {
    setCreateUpdateCalled(true);
    if (createUpdateCalled) {
      setCreateUpdateCalled(false);

      const selectedProject = getSelectedProject();
      const payload = [];
      user && (user.role = roleIndex);
      userDataAddArray.forEach((user) => {
        if (user.selected) {
          payload.push({
            id: user.id,
            projectRoles: [
              {
                projectId: selectedProject.id,
                projectName: selectedProject.name,
                roleId: user.role.id,
                roleName: user.role.name,
              },
            ],
          });
        } else {
          payload.push({
            id: user.id,
            projectRoles: [
              {
                projectId: selectedProject.id,
                projectName: selectedProject.name,
                roleId: user.role.id,
                roleName: user.role.name,
              },
            ],
          });
        }
      });
      if (props.user !== user) {
        MyAlert.info(`No changes were done to the ${props.user.name} user`);
        return null;
      }

      addUsersListReq(selectedProject.id, payload)
        .then((results) => {
          if (results.data.responseObject) {
            props.MyAlert.success(`${user.name} User Updated Successfully`);
            props.saveCompleted();
          }
        })
        .catch((error) => {
          console.error(error);
          return null;
        });
    }
  };
  const closeModal = () => {
    props.cancelClicked();
  };
  function setOldData() {
    let selectedObj = userDataAddArray.find((userObj) => userObj === selectedUserObject);
    if (selectedObj) {
      selectedObj.role = oldRole;
    }
  }

  const classes = useStyles();

  return (
    <div style={{ marginLeft: '18px', marginRight: '18px' }}>
      <div className="fixed ml-60 top-12">
        <AlertContatiner />
      </div>
      <div
        className="role_permission_style1 adduser-content-height"
        onBlur={() => {
          setCreateUpdateCalled(true);
        }}
      >
        <table className="add-user-table">
          <thead
            className="details-table-header w-10"
            style={{
              height: '40px',
              background: '#F6F7F9',
            }}
          >
            <tr>
              <th
                className="w-10"
                style={{ paddingLeft: '30px' }}
                onClick={() => {
                  setCreateUpdateCalled(true);
                }}
              >
                {userDataAddArray.some((v) => v.selected) ? (
                  <Checkbox
                    type="checkbox"
                    size="small"
                    color="primary"
                    style={{
                      width: props.action === 'Edit User' ? '16px' : '14px !important',
                      height: props.action === 'Edit User' ? '16px' : '14px !important',
                      marginLeft: props.action === 'Edit User' ? '5px' : '-5px',
                      position: 'relative',
                      marginTop: props.action === 'Edit User' ? '0px' : '-4px',
                      border: '2px solid transparent',
                      pointerEvents: props.action === 'Edit User' ? 'none' : 'auto',
                      color: props.action === 'Edit User' ? '#869FDF' : '#1648C6',
                    }}
                    onChange={(e) => selectAllUsers(e)}
                    checked={userDataAddArray.every((v) => v.selected) || true}
                    indeterminate={!userDataAddArray.every((v) => v.selected)}
                    inputProps={{ 'aria-label': 'indeterminate checkbox' }}
                  />
                ) : (
                  <input
                    type="checkbox"
                    style={{
                      top: '404px',
                      left: '522px',
                      width: '14px',
                      height: '14px',
                    }}
                    checked={false}
                    onChange={(e) => selectAllUsers(e)}
                    className="appearance-auto checked:bg-green-600 m-2 checked:border-transparent "
                  ></input>
                )}
                {userDataAddArray.some((v) => v.selected) ? (
                  <span
                    className="uppercase fontPoppinsSemiboldMd"
                    style={{
                      marginLeft: '15px',
                      color: '#727171',
                    }}
                  >
                    Sl.No
                  </span>
                ) : (
                  <span
                    className="uppercase fontPoppinsSemiboldMd"
                    style={{
                      marginLeft: '20px',
                      marginTop: '6px',
                      color: '#727171',
                    }}
                  >
                    Sl.No
                  </span>
                )}
              </th>
              <th
                className="w-10 uppercase fontPoppinsSemiboldMd"
                style={{
                  paddingLeft: '78px',
                  width: '300px',
                  height: '17px',
                  color: '#727171',
                }}
              >
                User Name
              </th>
              <th
                className="w-10 uppercase fontPoppinsSemiboldMd"
                style={{
                  paddingLeft: '65px',
                  width: '300px',
                  height: '17px',
                  color: '#727171',
                }}
              >
                Role
              </th>
            </tr>
          </thead>
          <div id="journal-scroll" className="add-user-data">
            <tbody className="details-table-body overflow-y w-10">
              {userDataAddArray.map((user, index) => (
                <tr key={index}>
                  <td className="w-10" style={{ height: '42px', paddingLeft: '30px' }}>
                    <input
                      id={'check' + index}
                      type="checkbox"
                      style={{
                        width: '16px !important',
                        height: '16px !important',
                        color: props.action === 'Edit User' ? '#869FDF' : '#1648C6',
                      }}
                      disabled={props.action === 'Edit User'}
                      checked={user.selected}
                      onChange={(event) => {
                        onSelectUser(event.target.checked, index);
                      }}
                      className="appearance-auto checked:bg-green-600 m-2 checked:border-transparent ..."
                    ></input>

                    <span style={{ marginLeft: '16px' }}>
                      {' '}
                      {index < 10 ? '0' : ''}
                      {index + 1}
                    </span>
                  </td>
                  <td className="w-10" style={{ height: '42px', paddingLeft: '68px' }}>
                    <span>{user.selected}</span>
                    <span
                      style={{
                        paddingLeft: '50px',
                        marginLeft: '10px',
                        position: 'absolute',
                        marginTop: '-9px',
                      }}
                    >
                      {' '}
                      {user.name}
                    </span>
                  </td>
                  <td className="w-10" style={{ height: '42px', paddingLeft: '240px' }}>
                    <FormControl
                      variant="outlined"
                      style={{ width: '200px', marginRight: '115px' }}
                      className={classes.formControl}
                    >
                      <Select
                        sx={{ border: '2px solid' }}
                        value={''}
                        displayEmpty
                        IconComponent={KeyboardArrowDownIcon}
                        SelectDisplayProps={{
                          style: { paddingTop: 8, paddingBottom: 8 },
                        }}
                        classes={{
                          root: classes.selectRoot,
                          icon: classes.whiteColor,
                          iconOpen: classes.iconOpen,
                        }}
                        onOpen={() => {
                          setShowMenuItem((prev) => false);
                        }}
                        onClose={() => {
                          setShowMenuItem((prev) => true);
                        }}
                        onChange={(e) => {
                          changeUserRole(e.target.value, user);
                        }}
                        MenuProps={{
                          classes: { paper: classes.menuPaper },
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                        className="user"
                        name={user.id}
                        id="journal-scroll"
                      >
                        <MenuItem value="" disabled style={{ display: showMenuItem ? 'block' : 'none' }}>
                          {' '}
                          <span
                            className="fontPoppinsRegularMd"
                            style={{
                              background: 'none',
                              color: '#727171',
                              width: '170px',
                            }}
                          >
                            {user?.role?.name}
                          </span>
                        </MenuItem>
                        {rolesList
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((role, optindex) => (
                            <MenuItem
                              className="fontPoppinsRegularMd"
                              placeholder="Excecute Role"
                              disabled={
                                props.action === 'Edit User' &&
                                props?.user?.role['name'] === rolesList[optindex]['name']
                              }
                              classes={{ root: classes.rootMenuItem }}
                              key={role.id}
                              value={optindex}
                            >
                              {rolesList[optindex]?.projectId ? (
                                <ListItemIcon
                                  classes={{ root: classes.hoverColor }}
                                  style={{
                                    position: 'relative',
                                    display: 'flex',
                                    justifyContent: 'left',
                                    alignItems: 'center',
                                    right: '12px',
                                    minWidth: '200px',
                                    background: 'transparent',
                                    height: '35px',
                                  }}
                                >
                                  <span className="changeColorIcon">
                                    <ShutCase1 className=" rounded relative" style={{ width: '20px' }} />
                                  </span>
                                </ListItemIcon>
                              ) : (
                                <ListItemIcon
                                  classes={{ root: classes.hoverColor }}
                                  style={{
                                    position: 'relative',
                                    display: 'flex',
                                    justifyContent: 'left',
                                    alignItems: 'center',
                                    right: '12px',
                                    minWidth: '200px',
                                    background: 'transparent',
                                    height: '35px',
                                  }}
                                >
                                  <span className="changeColorIcon">
                                    {' '}
                                    <ShutCase2 className=" rounded relative" />
                                  </span>
                                </ListItemIcon>
                              )}
                              <span
                                className="fontPoppinsRegularMd"
                                style={{
                                  background: 'none',
                                  maxWidth: '140px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  marginLeft: '-160px',
                                }}
                              >
                                {role.name}
                              </span>
                            </MenuItem>
                          ))}
                        ;
                      </Select>
                    </FormControl>
                  </td>
                </tr>
              ))}
            </tbody>
          </div>
        </table>
      </div>
      {(props.action === 'Add user to Project' || props.action === 'Edit User') && userDataAddArray && (
        <span
          className="fontPoppinsRegularMd"
          style={{
            color: '#EA0322',
            marginLeft: '2px',
          }}
        >
          {userDataAddArray.length !== 0 && props.action === 'Add user to Project' ? (
            <span>Note: You should select at least one user to add</span>
          ) : null}
        </span>
      )}
      {(props.action === 'Add user to Project' || props.action === 'Edit User') &&
      userDataAddArray &&
      loading ? null : (
        <span
          className="fontPoppinsRegularMd"
          style={{
            color: '#EA0322',
            marginLeft: '2px',
          }}
        >
          {userDataAddArray.length === 0 && (
            <span
              className="fontPoppinsRegularMd"
              style={{
                color: '#3C3838',
                marginLeft: '350px',
                position: 'absolute',
                marginTop: '-150px',
              }}
            >
              List of all the users are already allocated
            </span>
          )}
        </span>
      )}

      <div className="border-t border-gray-200 mt-5" style={{ width: '107%', marginLeft: '-18px', height: '30px' }}>
        <div className="mt-7">
          {props.action === 'Add user to Project' && (
            <div className="text-right " style={{ marginRight: '45px', marginTop: '-10px' }}>
              <button className="gray-btn cursor:pointer mr-6 " onClick={closeModal}>
                <label className="ml-2 mr-2 cursor ">Cancel</label>
              </button>
              <button
                className="primary-btn   cursor:pointer"
                onClick={addUpdateUser}
                disabled={userDataAddArray.length === 0 || !userDataAddArray?.some((user) => user?.selected)}
              >
                <label className="ml-2 mr-2 cursor">Add</label>
              </button>
            </div>
          )}
          {props.action === 'Edit User' && (
            <div className="text-right" style={{ marginRight: '45px', marginTop: '-10px' }}>
              <button
                className="gray-btn cursor:pointer "
                style={{ marginRight: '8px' }}
                onClick={() => {
                  closeModal();
                  setOldData();
                }}
              >
                <label className="ml-2 mr-2 cursor">Cancel</label>
              </button>
              <button className="primary-btn ml-4 cursor:pointer" onClick={addEditUpdateUser} disabled={!user}>
                <label className="ml-2 mr-2 cursor">Update</label>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default IndividualUserTablePage;

import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import Restapi, { RestApiProvider } from './restapi/restapi';
import Snippet from './snippet/js/snippet';
import History from './history/history';
import Setting from './setting/setting';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { Tooltip } from '@material-ui/core';
import '@pages/test-development/script/scripts/webservice/restapi/css/common_Webservice.scss';
const Webservice = (props) => {
  const index = props.location.pathname.lastIndexOf('/');
  const path = props.location.pathname.slice(0, index);
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);

  const createVariableModal = (val) => {
    props.showWebserviceModal(val);
  };

  return (
    <RestApiProvider>
      <Switch>
        <Route path={`${path}/snippet`} exact={true} strict={true} key="snippet">
          <>
            <Snippet {...props} />
          </>
        </Route>
        <Route path={`${path}/restapi`} exact={true} strict={true} key="restapi">
          <Restapi
            {...props}
            resetProgress={resetProgress}
            startOrResumeProgress={startOrResumeProgress}
            stopProgress={stopProgress}
            MyAlert={props.MyAlert}
            createVariableModal={createVariableModal}
            variableData={props.variableData}
            variableType={props.variableType}
            type="Webservice"
          />
        </Route>
        <Route path={`${path}/history`} exact={true} strict={true} key="history">
          <>
            <div className="flex items-center w-full history-height-40 bg-white">
              <label className=" fontPoppinsSemiboldLg flex-auto project_label mr-4 -ml-2">
                <span className="fontPoppinsSemiboldSm">History</span>
              </label>
              {true && (
                <>
                  <div className="flex relative flex-auto">
                    <Tooltip title="Search Snippets" placement="top">
                      <div className="absolute right-0 -top-4">
                        {/*<IconButton className={`btn-right right-0`} size="small" >
                            <SearchIcon className='history-section-search-icon'/>
                        </IconButton> */}
                      </div>
                    </Tooltip>
                  </div>
                </>
              )}
            </div>
            <History {...props} />
          </>
        </Route>
        <Route path={`${path}/setting`} exact={true} strict={true} key="setting">
          <Setting {...props} />
        </Route>
      </Switch>
    </RestApiProvider>
  );
};

export default Webservice;

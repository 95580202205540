import React from 'react';
import { Icon } from 'fireflink-ui';
import { FILTER_STATUS_CONSTANT } from '@src/common/ui-constants';
import { TooltipPoppin } from '@pages/analytics/common/util';
import { isEmptyValue, filterTooltipTitle } from '@util/common_utils';
import LicenseRowCardStyle from '@pages/licenses/styles/license-row-card.module.scss';
import cx from 'classnames';
import { colors } from '@src/css_config/colorConstants';
import PropTypes from 'prop-types';
import { ClickAwayListener } from '@mui/base';

const FilterDropDownList = (props) => {
  let { data = [], onApply, selectedStatus = 'ALL', open = false, onClickFilter } = props;

  let listToRender = [
    {
      label: FILTER_STATUS_CONSTANT.ALL,
      onClick: () => onApply('ALL'),
      class: `result-primary filter mt-1 ${selectedStatus === 'ALL' && 'selected-filter'}`,
    },

    {
      label: FILTER_STATUS_CONSTANT.PASSED,
      onClick: () => onApply('PASS'),
      class: `result-passed filter ${selectedStatus === 'PASS' && 'selected-filter'}`,
    },
    {
      label: FILTER_STATUS_CONSTANT.FAILED,
      onClick: () => onApply('FAIL'),
      class: `result-failed filter ${selectedStatus === 'FAIL' && 'selected-filter'}`,
    },
    {
      label: FILTER_STATUS_CONSTANT.WARNING,
      onClick: () => onApply('WARNING'),
      class: `result-warning filter ${selectedStatus === 'WARNING' && 'selected-filter'}`,
    },
    {
      label: FILTER_STATUS_CONSTANT.SKIPPED,
      onClick: () => onApply('SKIP'),
      class: `result-skipped filter mb-1 ${selectedStatus === 'SKIP' && 'selected-filter'}`,
    },
  ];

  return (
    <div className="relative">
      <TooltipPoppin
        title={filterTooltipTitle(data, selectedStatus)}
        placement="top"
        className={cx(LicenseRowCardStyle['ToolTipRenderer'], 'fontPoppinsRegularXs')}
      >
        <div className="expandIconContainer cursor-pointer">
          {selectedStatus === 'ALL' ? (
            <Icon
              name="rs_filter_outline"
              disabled={isEmptyValue(data)}
              color={colors.rs_primary}
              height={14}
              width={14}
              onClick={() => onClickFilter(true)}
            />
          ) : (
            <Icon name="rs_filter_filled" height={14} width={14} onClick={() => onClickFilter(true)} />
          )}
        </div>
      </TooltipPoppin>
      {open && (
        <ClickAwayListener onClickAway={() => onClickFilter(false)}>
          <div
            className="status-filter absolute flex flex-col mt-4 step-result cursor-pointer right-0 top-4 z-10"
            onClick={(e) => e.stopPropagation()} // Stop event propagation to avoid closing the filter
          >
            {listToRender?.map((val) => (
              <div className={val.class} onClick={val.onClick}>
                <div className=" mt-1 mx-4">{val.label}</div>
              </div>
            ))}
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

FilterDropDownList.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  onClickFilter: PropTypes.func.isRequired, // to open list
  onApply: PropTypes.func.isRequired,
  selectedStatus: PropTypes.string.isRequired,
};

export default FilterDropDownList;

import React, { useState, useEffect, useContext } from 'react';
import {
  deleteProgramElementReq,
  delPackageReq,
  getAnalyticsReq,
  getFirstLevelPackageTreeReq,
  getPackageReq,
  getPackageTreeReq,
  getProgramElementReq,
  getSingleUserRequest,
} from '@api/api_services';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MoreInfoButton from '@src/pages/common/more_info_button';
import CreateProgramElement from './create_program_element';
import TreeToolbar from '@src/pages/repository/shared/tree_toolbar';
import TableTree from '@src/pages/common/table_tree/table_tree';
import PackageModal from './modals/package_modal';
import DeleteModal from '@src/pages/common/delete_modal';
import DetailsModal from '@src/pages/repository/shared/details_modal';
import { useHistory } from 'react-router-dom';
import UserDetailsModal from '@pages/test-development/shared/user-details-modal';
import { REPO, trimPath } from '@src/common/ui-constants';

import DetailsModalProgramElement from './modals/details_modal_programelement';
import Tooltip from '@material-ui/core/Tooltip';
import { CommonLoader } from '@src/pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import {
  getUrlPath,
  packageElementNavigation,
} from '@pages/test-development/script/modules/module/utils/common-functions';
import { HighlightText } from '@src/util/common_utils';

const ProgramElement = (props) => {
  const isEditable = React.useMemo(() => window.permission?.isEditAccess('repository'), []);
  let renderTree;
  let [pages, setpages] = useState(0);
  const [message, setMessage] = useState();
  let [createProgramEle, setProgramEle] = useState(false);
  let [resetOnElementUpdateOrCancel, setResetOnElementUpdateOrCancel] = useState(false);

  const [deleteData, setDeleteData] = useState();
  const [node, setNode] = useState({});
  let [type, setType] = useState('');
  let [popupData, setPopupData] = useState([]);
  let [elements, setelements] = useState(0);
  const [reloadTree, setReloadTree] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rootData, setRootData] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [programElementMode, setProgramElementMode] = useState('ADD');
  let [treeHeader, setTreeHeader] = useState('Program Elements');
  let [dropdownTreeData, setDropdownTreeData] = useState([]);
  const [packageId, setPackageId] = useState();
  const [packageName, setPackageName] = useState();
  let [nodeToAdd, setNodeToAdd] = useState(null);

  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  let [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openProgramElementModal, setopenProgramElementModal] = useState(false);
  const [detailsType, setDetailsType] = useState('folder');
  const history = useHistory();

  const [showPackageModal, setShowPackageModal] = useState(false);
  const [showSubPackageModal, setShowSubPackageModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [userDetails, setUserDetails] = React.useState([]);
  const search = window.location.search;
  const id = new URLSearchParams(search).get('id');
  const [mode, setMode] = useState();
  const [searchedText, setSearchedText] = useState('');
  const [searchedTextQuery, setSearchedTextQuery] = useState('');
  const [licenseLevelProgramElementCount, setLicenseLevelProgramElementCount] = useState();
  const [childResourcesAvailable, setChildResourcesAvailable] = useState(false);

  useEffect(() => {
    if (resetOnElementUpdateOrCancel) {
      setSearchedText('');
      setSearchedTextQuery('');
      setMode('');
      getPackageTree('RESET');

      setResetOnElementUpdateOrCancel(false);
    } else {
      let timerState = setTimeout(() => getPackageTree(), 800);
      return () => clearTimeout(timerState);
    }
  }, [reloadTree, id]);

  function getPackageTree(mode) {
    let res;
    setTreeData([]);
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
      isBulkLoad: true,
    });
    setIsLoading(true);
    if (mode === 'RESET') {
      setSearchedText('');
      setSearchedTextQuery('');
    }
    if (mode !== 'RESET' && searchedTextQuery?.length) {
      searchTreeApi(searchedTextQuery);
    } else {
      if (id) {
        getPackageTreeReq(id)
          .then((childResponse) => {
            stopProgress();
            setpages(10);
            let nodeTitle = childResponse.data.responseObject.packageTree[0].name;
            if (nodeTitle === 'Root') {
              res = createTreeData(childResponse.data.responseObject.packageTree, 'root');
            } else {
              res = createTreeData(childResponse.data.responseObject.packageTree, 'child');
            }
            setTreeData(res.treeData);
            setRootData(res.rootData);
            if (res?.rootData?.subPackageCount || res?.rootData?.programElementCount) {
              setChildResourcesAvailable(true);
            } else {
              setChildResourcesAvailable(false);
            }
            setDropdownTreeData(childResponse.data.responseObject.packageTree);

            startOrResumeProgress({
              stopAt: 100,
              intervalDuration: 10,
            });
            setIsLoading(false);
          })
          .catch((error) => {
            resetProgress();
            setIsLoading(false);
            console.error('GET_SINGLE_PACKAGE_TREE :', error);
            history.push('/repository/program_elements');
          });
      } else {
        getFirstLevelPackageTreeReq()
          .then((response) => {
            stopProgress();
            const responseObject = response.data.responseObject;
            setDropdownTreeData(responseObject?.packageTree);
            setpages(responseObject?.packageTree[0].subPackageCount);
            if (responseObject?.packageTree[0].subPackageCount === 0) {
              setNodeToAdd(0);
            } else {
              setNodeToAdd(null);
            }
            if (responseObject?.packageTree[0]) {
              res = createTreeData(responseObject?.packageTree, 'root');
              setTreeData(res.treeData);
              setRootData(res.rootData);
              setChildResourcesAvailable(!!res?.rootData?.subPackageCount);
            }
            startOrResumeProgress({
              stopAt: 100,
              intervalDuration: 10,
            });
            setIsLoading(false);
          })
          .catch((error) => {
            resetProgress();
            setIsLoading(false);
            console.error('GET_PACKAGE_TREE : ', error);
          });
      }
    }
  }
  const searchTreeApi = async (_searchText) => {
    const requestBody = {
      module: 'package',
      collectionName: 'packages',
      responseFilterRequired: 'true',
      searchText: _searchText,
      additionalSearchText: id ? id : '',
      facetQueries: [],
    };
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
      isBulkLoad: true,
    });
    setIsLoading(true);
    try {
      setSearchedTextQuery(_searchText);
      const response = await getAnalyticsReq(requestBody);
      if (response?.data?.responseObject) {
        const responseObject = response.data.responseObject;
        if (responseObject?.licenseLevelProgramElementCount) {
          setLicenseLevelProgramElementCount(responseObject.licenseLevelProgramElementCount);
        } else {
          setLicenseLevelProgramElementCount(0);
        }
        if (responseObject?.packageTree) {
          setDropdownTreeData(responseObject.packageTree);
        }
        setpages(responseObject?.packageTree[0].subPackageCount);
        setelements(responseObject?.packageTree[0].programElementCount);
        if (responseObject?.packageTree[0].subPackageCount === 0) {
          setNodeToAdd(0);
        } else {
          setNodeToAdd(null);
        }
        if (responseObject?.packageTree) {
          let res;
          let nodeTitle = responseObject.packageTree[0].name;
          if (nodeTitle === 'Root') {
            res = createTreeData(responseObject.packageTree, 'root');
          } else {
            res = createTreeData(responseObject.packageTree, 'child');
          }
          setTreeData(res.treeData);
          setRootData(res.rootData);
          if (res?.rootData?.subPackageCount || res?.rootData?.programElementCount) {
            setChildResourcesAvailable(true);
          } else {
            setChildResourcesAvailable(false);
          }
        }
        setIsLoading(false);
        resetProgress();
      } else {
        setTreeData([]);
        setRootData([]);
        setIsLoading(false);
        resetProgress();
        console.warn('GET_FIRST_LEVEL_ALL_MODULE_TREE : ', response?.data?.message);
      }
    } catch (err) {
      resetProgress();
      setIsLoading(false);
      console.error('GET_ALL_MODULE_TREE : ', err);
    }
  };
  const loadMoreData = async (_packageId) => {
    let _packageTree = [];
    try {
      let res;
      if (_packageId) {
        res = await getPackageTreeReq(_packageId);
      } else {
        res = await getPackageTreeReq();
      }
      if (res?.data?.responseCode === 200 && res?.data?.responseObject) {
        _packageTree = res.data.responseObject.packageTree[0].children;
      }
      return _packageTree;
    } catch (error) {
      console.error('error during load more package data:', error);
      return _packageTree;
    }
  };

  let programElement = (val) => {
    setPopupData('');
    setProgramEle(val);
    setProgramElementMode('ADD');
    setPackageName('');
    setPackageId('');
  };

  const closeModal = (val) => {
    if (openEditModal) {
      setOpenEditModal(val);
    } else if (openDeleteModal) {
      setOpenDeleteModal(val);
    } else if (showPackageModal) {
      setShowPackageModal(val);
    } else if (showSubPackageModal) {
      setShowSubPackageModal(val);
    } else if (openDetailsModal) {
      setOpenDetailsModal(val);
    } else if (showUserModal) {
      setShowUserModal(val);
    } else if (openProgramElementModal) {
      setopenProgramElementModal(val);
    }
  };

  let openDetails = (val) => {
    setOpenDetailsModal(val);
  };

  const closeUserDetailsModal = (value) => {
    setOpenUserDetailsModal(value);
    setUserDetails({});
  };
  function getUserDetails(userNode) {
    getSingleUserRequest(userNode.modifiedBy).then((results) => {
      if (results.data.responseCode === 200 && results.data.responseObject) {
        setUserDetails(results.data.responseObject);
        setOpenUserDetailsModal(true);
      }
    });
  }

  const createTreeData = (response, type) => {
    let res_obj = {
      treeData: [],
      rootData: {},
    };

    if (!response[0].children) {
      response[0]['children'] = [];
    }

    const { children, ...root_data } = response[0];
    if (type === 'root') {
      res_obj.treeData = response[0].children;
    } else {
      res_obj.treeData = response;
    }
    let title = root_data.title;
    if (!root_data.title.toLowerCase().includes('package') && type.toLowerCase() === 'root') {
      root_data.title = title.concat(' Package');
    }
    res_obj.rootData = root_data;
    return res_obj;
  };

  const DeleteFunction = () => {
    if (deleteData.folder) {
      delPackageReq(deleteData._key).then((res) => {
        if (res.data.responseCode === 200) {
          closeModal(false);
          props.MyAlert.success(
            `${node.node.title} ${
              node.data.title.toLowerCase().includes('package') ? '' : 'Package'
            } deleted successfully`
          );
          node.node.remove();
          updateDropDown();
        }
      });
    } else {
      let project = JSON.parse(localStorage.getItem('selected-project'));
      deleteProgramElementReq(deleteData.parentId, deleteData._key, project.id).then((res) => {
        if (res.data.responseCode === 200) {
          closeModal(false);
          node.node.remove();
          props.MyAlert.success(`${node.data.desc} deleted successfully`);
          updateDropDown();
        }
      });
    }
  };
  const reloadTreeData = (value) => {
    setReloadTree(!reloadTree);
  };

  const methodName = (name, nodeObj) => {
    setNode(nodeObj);
    if (name === 'Details') {
      if (nodeObj.data.folder) {
        detailsModal('package', nodeObj);
      } else {
        detailsModal('pgElemrent', nodeObj);
      }
    } else if (name === 'package') {
      setPackageId(nodeObj.data._key);
      setPackageName(nodeObj.data.title);
      setNodeToAdd(nodeObj.node);
      setShowSubPackageModal(true);
      setMode('ADD_SUB');
    } else if (name === 'element') {
      setPackageName(nodeObj.data.title);
      setPackageId(nodeObj.data._key);
      setNodeToAdd(nodeObj.node);
      setProgramElementMode('ADD_SUB');
      setProgramEle(true);
    }
  };

  const updateDropDown = () => {
    if (id) {
      getPackageTreeReq(id).then((response) => {
        if (response.data.responseObject.packageTree.length === 0) {
          history.push(`/repository/program_elements`);
        }
      });
    }
    getPackageTreeReq().then((response) => {
      setDropdownTreeData(response?.data?.responseObject?.packageTree);
      if (response.data.responseObject?.packageTree[0].subPackageCount === 0) {
        history.push(`/repository/program_elements`);
        reloadTreeData(true);
      }
    });
  };
  const updatedValue = (value, type) => {
    const selectedNode = node.node;
    if (type === 'folder') {
      if (mode === 'EDIT') {
        selectedNode.data.modifiedBy = value.modifiedBy;
        selectedNode.data.modifiedByUname = value.modifiedByUname;
        selectedNode.title = value.name;
        selectedNode.render(true, true);
      } else {
        selectedNode.addChildren(value);
        if (selectedNode.expanded === true) {
          selectedNode.setExpanded(true);
        }
      }
    } else {
    }
    updateDropDown();
  };
  const detailsModal = (type, node) => {
    if (type === 'package') {
      let key;
      if (node.isRootNode) {
        key = node.data.key;
      } else {
        key = node.data._key;
      }
      getPackageReq(key).then((res) => {
        if (res.data.responseCode === 200 && res.data.responseObject) {
          setPopupData(res.data.responseObject);
          setDetailsType('Package');
          setOpenDetailsModal(true);
        }
      });
    } else {
      getProgramElementReq(node.data.parentId, node.data._key).then((res) => {
        if (res.data.responseCode === 200 && res.data.responseObject) {
          setPopupData(res.data.responseObject);
          setopenProgramElementModal(true);
        }
      });
    }
  };

  const rootNodeClicked = (node) => {
    if (node.folder && treeData.length) {
      if (node.name === 'Root' && node.title === 'Root package') {
        let pageData = {
          Name: node.title,
          id: node.key,
          type: 'page',
        };
        localStorage.setItem('rootJson', JSON.stringify(pageData));
        history.push(`/repository/program_elements/${node.title}/Package?id=${node.key}&type=package`);
      }
    }
  };
  const onNodeSelected = (nodeObj) => {
    var node = nodeObj.node;
    if (node.folder) {
      getPackageReq(node.key).then((response) => {
        if (response.data.responseCode === 200) {
          let searchKeyArray = node.data.searchKey.slice(1).split('/');
          searchKeyArray = searchKeyArray.slice(1);
          let nameArray = trimPath(response.data.responseObject.path).split('/');
          const { json } = packageElementNavigation(searchKeyArray, nameArray, null);
          localStorage.setItem('parentAndSonJson', JSON.stringify(json));
          history.push(`/repository/program_elements/${getUrlPath(json)}Package?id=${nodeObj.data._key}&type=package`);
        }
      });
    } else {
      getProgramElementReq(nodeObj.data.parentId, nodeObj.node.key).then((response) => {
        let elementPath = trimPath(response.data.responseObject.path).split('/');
        let eleSearchKeyArray = node.data.searchKey.slice(1).split('/');
        eleSearchKeyArray = eleSearchKeyArray.slice(1);
        const { json } = packageElementNavigation(eleSearchKeyArray, elementPath, nodeObj.data.parentId);
        localStorage.setItem('parentAndSonJson', JSON.stringify(json));
        history.push(
          `/repository/program_elements/${getUrlPath(json)}Program Element?id=${nodeObj.data._key}&parentId=${
            nodeObj.data.parentId
          }`
        );
      });
    }
  };

  const onEditClicked = (ev, nodeObj) => {
    setNode(nodeObj);
    if (nodeObj.data.folder) {
      let key = nodeObj.data._key;
      getPackageReq(key).then((response) => {
        setType('page');
        setPopupData(response.data.responseObject);
        setOpenEditModal(true);
        setMode('EDIT');
      });
    } else {
      let key = nodeObj.data._key;
      let parentId = nodeObj.data.parentId;
      setPackageName(nodeObj.node.parent.title);
      setPackageId(nodeObj.node.parent.key);
      getProgramElementReq(parentId, key).then((response) => {
        setProgramElementMode('EDIT');
        setPopupData(response.data.responseObject);
        setProgramEle(true);
      });
    }
  };

  const actionColumnHtml = (nodeObj) => {
    let type;
    if (nodeObj.isRootNode) {
      type = 'root';
    } else if (nodeObj.node.folder) {
      type = 'folder';
    } else {
      type = 'nonFolder';
    }
    return (
      <div className="action-col-wrp">
        {!(nodeObj.data.folder || nodeObj.isRootNode) && isEditable ? (
          <Tooltip id="prgElebtn" title={nodeObj.isRootNode ? '' : 'Edit'} placement="top">
            <button
              disabled={nodeObj.isRootNode}
              className="state-button py-0 px-1 rounded-l"
              onClick={(event) => {
                onEditClicked(event, nodeObj);
                if (!nodeObj.data.folder && !nodeObj.isRootNode) {
                  if (document.getElementById('prgElebtn')) {
                    document.getElementById('prgElebtn').remove();
                  }
                }
              }}
            >
              <EditOutlinedIcon />
            </button>
          </Tooltip>
        ) : null}
        {!nodeObj.isRootNode && (
          <MoreInfoButton
            methodListOfFolder={REPO.PACKAGE_ACTION_MENU}
            methodListOfNonFolder={REPO.PROGRAM_ACTION_MENU}
            methodListForRoot={REPO.ROOT_ACTION_MENU}
            nodeObj={nodeObj}
            methodName={methodName}
            type={type}
            typeToDisplay="more"
          />
        )}
      </div>
    );
  };

  const colDefs = [
    {
      field: 'title',
      title: 'PACKAGE',
      class: 'title',
      isTitle: true,
      width: '220px',
      render: (nodeObj) => {
        return (
          <>
            {nodeObj.isRootNode ? (
              <Tooltip title={`Packages - ${nodeObj.data.subPackageCount}`}>
                <span className="count-badge folder-count ml-2">P {nodeObj.data.subPackageCount}</span>
              </Tooltip>
            ) : null}
            {nodeObj.node ? (
              nodeObj.node && nodeObj.node.data && nodeObj.node.folder && !nodeObj.data.isMockNode ? (
                <>
                  <Tooltip title={`Packages - ${nodeObj.data.subPackageCount}`}>
                    <span className="count-badge file-count ml-2">SP {nodeObj.data.subPackageCount}</span>
                  </Tooltip>
                  <Tooltip title={`Program Elements - ${nodeObj.node.data.programElementCount}`}>
                    <span className="count-badge file-count">
                      {' '}
                      PE{' '}
                      {nodeObj.node.data.programElementCount
                        ? nodeObj.node.data.programElementCount
                        : nodeObj.node.data.programElementCount}{' '}
                    </span>
                  </Tooltip>
                </>
              ) : nodeObj.isRootNode ? (
                <Tooltip title={`Program Elements - ${nodeObj.data.programElementCount}`}>
                  <span className="count-badge file-count"> PE {nodeObj.data.programElementCount}</span>
                </Tooltip>
              ) : null
            ) : null}
          </>
        );
      },
    },
    // {
    //   field: "state",
    //   title: "STATE",
    //   class: "state ",
    //   width: "170px",
    //   render: (nodeObj) => {
    //     return (
    //       !nodeObj.data.isMockNode && stateColumnHtml(nodeObj)
    //     )
    //   }

    // },
    // {
    //   field: "ver",
    //   title: "VERSION",
    //   class: "version ",
    //   width: "50px",
    //   render: (nodeObj) => {
    //     return (
    //       <div>
    //         {!nodeObj.isRootNode && !nodeObj.data.isMockNode && (
    //           <span className="version-text-color ">
    //             {nodeObj.data.ver?.toFixed(1)}
    //           </span>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      field: 'modifiedByUname',
      title: 'MODIFIED BY',
      class: 'modified-by ',
      width: '160px',
      render: (nodeObj) => {
        return (
          <>
            {!nodeObj.isRootNode && !nodeObj.data.isMockNode && (
              <div
                className="hyper_link_color-common  cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap w-24"
                onClick={() => getUserDetails(nodeObj.data)}
                name="modifiedBy"
              >
                <HighlightText text={nodeObj.data.modifiedByUname} highlight={searchedTextQuery} />
              </div>
            )}
          </>
        );
      },
    },
    {
      field: 'action',
      title: 'ACTIONS',
      class: 'action ',
      width: '150px',
      render: (nodeObj) => {
        return !nodeObj.data.isMockNode && actionColumnHtml(nodeObj);
      },
    },
  ];

  const LABELS = {
    EXPAND_ALL: REPO.EXPAND_ALL_PACKAGES,
    COLLAPSE_ALL: REPO.COLLAPSE_ALL_PACKAGES,
    SHOW_ALL_ELEMENTS: REPO.SHOW_ALL_PROGRAM_ELEMENTS,
    HIDE_ALL_ELEMENTS: REPO.HIDE_ALL_PROGRAM_ELEMENTS,
    SHOW_PRE_POST_CONDITION: REPO.SHOW_PRE_POST_CONDITION,
    HIDE_PRE_POST_CONDITION: REPO.HIDE_PRE_POST_CONDITION,
  };

  if (isLoading) {
    renderTree = <CommonLoader />;
  } else if (treeData && treeData.length) {
    if (props.pageType === 'parent') {
      renderTree = (
        <TableTree
          data={treeData}
          rootData={rootData}
          colDefs={colDefs}
          hideElements={false}
          hideConditions={false}
          hideConditionBtn={true}
          onRootNodeClicked={rootNodeClicked}
          conditions={[{ key: 'isPreCondition', value: true }]}
          labels={LABELS}
          onNodeSelected={onNodeSelected}
          titleKey="desc"
          id="program-element-tree"
          expandAllApi={loadMoreData}
          loadMoreData={loadMoreData}
          childrenCountKeysArray={['subPackageCount', 'programElementCount']}
          userSearched={searchedTextQuery}
        />
      );
    } else {
      renderTree = (
        <TableTree
          data={treeData}
          rootData={rootData}
          colDefs={colDefs}
          hideElements={false}
          hideConditions={false}
          hideConditionBtn={true}
          onRootNodeClicked={rootNodeClicked}
          conditions={[{ key: 'isPreCondition', value: true }]}
          labels={LABELS}
          onNodeSelected={onNodeSelected}
          titleKey="desc"
          id="program-element-tree"
          userSearched={searchedTextQuery}
        />
      );
    }
  } else if (treeData?.length === 0 && searchedText?.length) {
    renderTree = (
      <div className="">
        <TableTree
          data={treeData}
          rootData={rootData}
          colDefs={colDefs}
          hideElements={false}
          hideConditions={false}
          hideConditionBtn={true}
          onRootNodeClicked={rootNodeClicked}
          conditions={[{ key: 'isPreCondition', value: true }]}
          labels={LABELS}
          onNodeSelected={onNodeSelected}
          titleKey="desc"
          id="program-element-tree"
          expandAllApi={loadMoreData}
          loadMoreData={loadMoreData}
          childrenCountKeysArray={['subPackageCount', 'programElementCount']}
          userSearched={searchedTextQuery}
        />
      </div>
    );
  } else {
    renderTree = (
      <div>
        <div>
          <TableTree
            data={treeData}
            rootData={rootData}
            colDefs={colDefs}
            hideElements={false}
            hideConditions={false}
            hideConditionBtn={true}
            onRootNodeClicked={rootNodeClicked}
            conditions={[{ key: 'isPreCondition', value: true }]}
            labels={LABELS}
            onNodeSelected={onNodeSelected}
            titleKey="desc"
            id="program-element-tree"
            expandAllApi={loadMoreData}
            loadMoreData={loadMoreData}
            childrenCountKeysArray={['subPackageCount', 'programElementCount']}
            userSearched={searchedTextQuery}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="content-wrapper">
      {createProgramEle === true ? (
        <CreateProgramElement
          parentName={packageName}
          parentId={packageId}
          treeData={dropdownTreeData}
          data={popupData}
          programElement={programElement}
          reloadTree={reloadTreeData}
          mode={programElementMode}
          MyAlert={props.MyAlert}
          nodeToAdd={nodeToAdd}
          setResetOnElementUpdateOrCancel={setResetOnElementUpdateOrCancel}
        />
      ) : (
        <div className="content_area_style" id="journal-scroll">
          <div className="">
            <TreeToolbar
              header={treeHeader}
              treeData={dropdownTreeData}
              childResources={childResourcesAvailable}
              page="Program Element"
              containerCount={pages}
              resourceCount={elements}
              programElement={programElement}
              reloadTree={reloadTreeData}
              MyAlert={props.MyAlert}
              nodeToAdd={nodeToAdd}
              searchTree={searchTreeApi}
              resetTree={getPackageTree}
              setSearchedText={setSearchedText}
            />
          </div>
          <div className="table-height overflow-auto" id="journal-scroll">
            {renderTree}
          </div>
          {openEditModal ? (
            <PackageModal
              data={popupData}
              reloadTree={reloadTreeData}
              treeData={dropdownTreeData}
              closeModal={closeModal}
              mode={mode}
              nodeToAdd={nodeToAdd}
              updatedValue={updatedValue}
              MyAlert={props.MyAlert}
            />
          ) : null}

          {/* to add sub page by clicking plus button on the node */}
          {showSubPackageModal ? (
            <PackageModal
              parentId={packageId}
              parentName={packageName}
              reloadTree={reloadTreeData}
              treeData={dropdownTreeData}
              closeModal={closeModal}
              mode={mode}
              nodeToAdd={nodeToAdd}
              updatedValue={updatedValue}
              MyAlert={props.MyAlert}
            />
          ) : null}
          {showPackageModal ? (
            <PackageModal
              pageName={props.pageName}
              reloadTree={reloadTreeData}
              treeData={dropdownTreeData}
              closeModal={closeModal}
              mode={mode}
              nodeToAdd={nodeToAdd}
              MyAlert={props.MyAlert}
            />
          ) : null}

          {openDeleteModal ? (
            <DeleteModal nodeToDel={node} message={message} closeModal={closeModal} method={DeleteFunction} />
          ) : null}
          {showUserModal ? <UserDetailsModal userDetails={userDetails} closeModal={closeModal} /> : null}
          {openDetailsModal ? (
            <DetailsModal
              data={popupData}
              openDetails={openDetails}
              closeModal={closeModal}
              containerName={detailsType}
            />
          ) : null}
          {openProgramElementModal ? <DetailsModalProgramElement closeModal={closeModal} data={popupData} /> : null}
          {openUserDetailsModal ? <UserDetailsModal data={userDetails} closeModal={closeUserDetailsModal} /> : null}
        </div>
      )}
    </div>
  );
};

export default ProgramElement;

import React from 'react';
import CommonDrawerJs from '@src/common/atoms/CommonDrawer';
import { DEFECT_CONSTANTS, PROJECT, PROJECTDETAILS_CONSTANTS } from '@src/common/ui-constants';
import Grid from '@mui/material/Grid';
import cx from 'classnames';
import styles from '@src/common/atoms/atoms.module.scss';
import { Label } from '@src/common/atoms/LabelComponent';

const ProjectDetailsDrawer = (props) => {
  const getDetailsField = (fieldName, fieldValue, fieldClasses) => {
    const occupyFullRow = [PROJECTDETAILS_CONSTANTS.DESCRIPTION].includes(fieldName);
    return (
      <Grid item xs={4} sm={occupyFullRow ? 8 : 4} md={occupyFullRow ? 12 : 4} key={fieldName}>
        <div className="flex flex-col justify-center">
          <div className="flex">
            <Label label={fieldName} className={cx('truncate', styles['details-label'])} />
          </div>
          <div className="flex">
            {PROJECTDETAILS_CONSTANTS.STATUS === fieldName ? (
              <p className={`truncate fontPoppinsRegularSm ${fieldClasses}`}>{fieldValue}</p>
            ) : (
              <Label
                label={fieldValue || '--'}
                fontClass={'fontPoppinsRegularSm'}
                className={cx(`truncate ${fieldClasses}`, styles['details-value'])}
              />
            )}
          </div>
        </div>
      </Grid>
    );
  };

  const getDeatilsFieldContainer = () => {
    let detailsFields = [];
    if (props?.projectDetails?.type === PROJECT.WEBSERVICE_PROJECT) {
      detailsFields = [
        {
          label: PROJECTDETAILS_CONSTANTS.BASEURI,
          value: props?.projectDetails?.baseUri,
        },
        {
          label: PROJECTDETAILS_CONSTANTS.PORT,
          value: props?.projectDetails?.port,
        },
      ];
    } else if (
      [PROJECT.WEB_PROJECT, PROJECT.WEB_AND_MOBILE_PROJECT, PROJECT.MS_DYNAMICS, PROJECT.SALESFORCE_PROJECT].includes(
        props?.projectDetails?.type
      )
    ) {
      detailsFields = [
        {
          label: PROJECTDETAILS_CONSTANTS.WEBURL,
          value: props?.projectDetails?.url,
        },
      ];
    }
    if (props?.projectDetails?.type?.includes(PROJECT.MOBILE_PROJECT)) {
      detailsFields = detailsFields.concat([
        {
          label: PROJECTDETAILS_CONSTANTS.APPTYPE,
          value: props?.projectDetails?.appType,
        },
      ]);
    }
    if (
      [
        PROJECT.WEB_AND_MOBILE_PROJECT,
        PROJECT.MOBILE_PROJECT,
        PROJECT.MS_DYNAMICS,
        PROJECT.SALESFORCE_PROJECT,
      ].includes(props?.projectDetails?.type)
    ) {
      detailsFields = detailsFields.concat([
        {
          label: PROJECTDETAILS_CONSTANTS.APPPACKAGE,
          value: props?.projectDetails?.appPackage,
        },
        {
          label: PROJECTDETAILS_CONSTANTS.APPACTIVITY,
          value: props?.projectDetails?.appActivity,
        },
        {
          label: PROJECTDETAILS_CONSTANTS.BUNDLEID,
          value: props?.projectDetails?.bundleId,
        },
      ]);
    }
    const fieldList = [
      {
        label: PROJECTDETAILS_CONSTANTS.NAME,
        value: props?.projectDetails?.name,
      },
      {
        label: PROJECTDETAILS_CONSTANTS.TYPE,
        value: props?.projectDetails?.type,
      },
      ...detailsFields,
      {
        label: PROJECTDETAILS_CONSTANTS.DEFECT_MANAGEMENT,
        value: props?.projectDetails?.defectPlatform?.platform,
      },
      {
        label: PROJECTDETAILS_CONSTANTS.CREATEDBY,
        value: props?.projectDetails?.createdByUname,
      },
      {
        label: PROJECTDETAILS_CONSTANTS.CREATEDON,
        value: props?.projectDetails?.createdOn,
      },
      {
        label: PROJECTDETAILS_CONSTANTS.MODIFIEDBY,
        value: props?.projectDetails?.modifiedByUname,
      },
      {
        label: PROJECTDETAILS_CONSTANTS.MODIFIEDON,
        value: props?.projectDetails?.modifiedOn,
      },
      {
        label: PROJECTDETAILS_CONSTANTS.STATUS,
        value: props?.projectDetails?.status,
        className:
          props?.projectDetails?.status === PROJECT.STATUS.OPEN
            ? 'label-success'
            : props?.projectDetails?.status === PROJECT.STATUS.CLOSE
              ? 'label-error'
              : 'label-archive',
      },
      {
        label: PROJECTDETAILS_CONSTANTS.TIME_ZONE,
        value: props?.projectDetails?.timeZoneValue,
      },
      {
        label: PROJECTDETAILS_CONSTANTS.DESCRIPTION,
        value: props?.projectDetails?.description,
      },
    ];
    return <>{fieldList.map((field) => getDetailsField(field.label, field.value, field.className || ''))}</>;
  };

  return (
    <CommonDrawerJs
      isDrawerOpen={true}
      onDrawerClose={() => props.handleDetailsModal(false)}
      headerContent={`${PROJECTDETAILS_CONSTANTS.PROJECT_DETAILS} - ${props?.projectDetails.name}`}
      drawerWidth={DEFECT_CONSTANTS.PROJECT_DETAILS_DRAWER_WIDTH}
      leftButtonText={PROJECTDETAILS_CONSTANTS.CLOSE}
      isLeftButtonVisible={true}
      onLeftButtonClick={() => props.handleDetailsModal(false)}
      isRightButtonVisible={false}
    >
      <div className="modal-body" id="journal-scroll">
        <div className="m-4">
          <Grid
            container
            rowSpacing={{ xs: 2, md: 3, sm: 2 }}
            columnSpacing={{ xs: 2, md: 4, sm: 2 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {getDeatilsFieldContainer()}
          </Grid>
        </div>
      </div>
    </CommonDrawerJs>
  );
};

export default ProjectDetailsDrawer;

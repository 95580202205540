import React, { useEffect, useState, useContext } from 'react';
import Grid from '@mui/material/Grid';
import DefectCreateModal from './defect-create-modal';
import { Tooltip } from '@material-ui/core';
import { Download } from '@mui/icons-material';
import {
  downloadFileFromCloud,
  getSingleDefectReq,
  getTemplateForEmailConfigReq,
  getAllComments,
} from '@src/api/api_services';
import { saveFileFromBlob } from '@src/pages/test-development/shared/common-methods';
import { useLocation } from 'react-router-dom';
import { FORM_FIELDS, DEFECT_DATE_FIELD } from './defectMgmtConstants';
import RichTextEditor from '../common/rich-text-editor';
import EditIcon from '@mui/icons-material/Edit';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { createContext } from 'react';
import Comment from './defect-comments/components/Comment/Comment';
import { isEmptyValue } from '@src/util/common_utils';
import { UI_VALIDATIONS } from '@src/util/validations';
function DefectDetailsView(props) {
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [detailsViewData, setdetailsViewData] = useState([]);
  const [showDefectModal, setShowDefectModal] = useState(false);
  const [selectedDefectData, setSelectedDefectData] = useState({});
  const [modalType, setModalType] = useState('');
  const [defectMgmtData, setDefectMgmtData] = useState({});
  const [comments, setComments] = useState([]);

  let project = JSON.parse(localStorage.getItem('selected-project'));
  const location = useLocation();
  const defectId = location.pathname.split('/')[2];
  const { TEXTBOX, LINK, ATTACHMENT, TEXTEDITOR, DATE } = FORM_FIELDS;
  const { DEFECTS_VALUES_MAX_CHAR_COUNT } = UI_VALIDATIONS;
  const { CREATED_ON, MODIFIED_ON } = DEFECT_DATE_FIELD;
  let labelTitle = '';
  selectedDefectData?.projectLabels?.forEach((system, index) => {
    labelTitle +=
      (system?.name ? system?.name : '') + `${index < selectedDefectData?.projectLabels?.length - 1 ? ',' : ' '}`;
  });

  const getTemplateForEmailConfig = async () => {
    try {
      let response = await getTemplateForEmailConfigReq(project.id);
      if (response?.data?.responseCode === 200 && response?.data?.responseObject) {
        let template = response?.data?.responseObject;
        setDefectMgmtData(template);
        props.handelDisableEmailConfig(template.templates_selected);
        return template;
      }
    } catch (err) {
      console.error('get Template For Email Config api error', err);
    }
  };
  const getSingleDefect = async () => {
    try {
      resetProgress();
      startOrResumeProgress({
        stopAt: 90,
      });
      const response = await getSingleDefectReq(project?.id, defectId);
      stopProgress();
      if (response?.data?.responseCode === 200) {
        setSelectedDefectData(response?.data?.responseObject);
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 10,
        });
        return response?.data?.responseObject;
      } else {
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 10,
        });
      }
    } catch (err) {
      resetProgress();
      console.error('Get Single defect error', err);
    }
  };

  const initDefectData = async () => {
    setdetailsViewData([]);
    const template = await getTemplateForEmailConfig();
    const singleDefectData = await getSingleDefect();
    getUserComments();
    mapKeyAndValue(template?.defect_details_templates[0]?.defectDetails, singleDefectData?.defectDetails);
  };
  useEffect(() => {
    initDefectData();
    getUserComments();
  }, []);

  const getUserComments = () => {
    getAllComments(defectId).then((data) => {
      setComments(data.data.responseObject);
    });
  };

  function mapKeyAndValue(defectTemplateData, defectDetails) {
    defectTemplateData.forEach((item) => {
      if (['Assign to', 'Created By', 'Modified By'].includes(item?.label)) {
        item.value = defectDetails && defectDetails[item.label]?.name;
      } else {
        item.value = defectDetails && defectDetails[item.label];
      }
    });
    setdetailsViewData(defectTemplateData);
  }

  const downloadFile = (file) => {
    downloadFileFromCloud(file.id)
      .then((response) => {
        saveFileFromBlob(response.data, file.name);
      })
      .catch((err) => {
        console.error('DOWNLOAD FILE API FAILED', err);
      });
  };

  const handleOnClickDefectValues = (data) => {
    if (data?.value) {
      const fieldId = document.getElementById(data?.label);
      if (fieldId?.classList?.contains('wrapContent')) {
        fieldId?.classList?.remove('wrapContent');
        fieldId?.classList?.add('contentDataStyle');
      } else {
        fieldId?.classList?.remove('contentDataStyle');
        fieldId?.classList?.add('wrapContent');
      }
    }
  };

  const getDefectValue = (data, value) => {
    return (
      <>
        {value !== '--' && value?.length > DEFECTS_VALUES_MAX_CHAR_COUNT ? (
          <Tooltip title={value !== '--' && value?.length > DEFECTS_VALUES_MAX_CHAR_COUNT && value} placement="top">
            <label
              id={data.label}
              className="wrapContent label-overflow cursor-pointer"
              onClick={() => {
                handleOnClickDefectValues(data);
              }}
            >
              {value || '--'}
            </label>
          </Tooltip>
        ) : (
          <label className="label-overflow">{value || '--'}</label>
        )}
      </>
    );
  };

  const getDisplayTimeZoneBasedDate = (data) => {
    let label = data.label.concat(' - onDisplay');
    let value = selectedDefectData?.defectDetails[label];
    return getDefectValue(data, value);
  };
  const detailViewComponents = (data) => {
    let value;
    if (data.type !== ATTACHMENT) {
      value = data.value && Array.isArray(data.value) ? data.value.join(',') : data.value;
    }
    if (data?.label === 'Labels') {
      return (
        <>
          <Tooltip title={labelTitle}>
            <div className="label-overflow">{!isEmptyValue(labelTitle) ? labelTitle : '--'}</div>
          </Tooltip>
        </>
      );
    }
    if (data.label === 'Module') {
      return (
        <>
          <Tooltip title={data?.value !== '--' && data.value?.split(':')?.shift()} placement="right-end">
            <div
              id={data?.value}
              className="wrapContent label-overflow cursor-pointer"
              onClick={() => {
                handleOnClickDefectValues(data);
              }}
            >
              {data?.value ? data.value?.split('/')?.pop()?.split(':')?.shift() : '--'}
            </div>
          </Tooltip>
        </>
      );
    }

    if (data.type === DATE && [CREATED_ON, MODIFIED_ON].includes(data.label)) {
      return getDisplayTimeZoneBasedDate(data);
    }
    if (data.type === LINK && value) {
      return (
        <>
          <Tooltip title={value !== '--' && value} placement="top">
            <a href={value} target="_blank" className="label-overflow tab-style-common">
              {value?.length > 25 ? `${value.substring(0, 25)}...` : value}
            </a>
          </Tooltip>
        </>
      );
    }
    if (data.type === ATTACHMENT) {
      if (data?.value && Array.isArray(data?.value) && data?.value.length > 0) {
        return (
          <div>
            {data?.value?.map((option, index) => (
              <div key={index}>
                <Tooltip title={option?.name !== '--' && option?.name} placement="top">
                  <label className="label-overflow">
                    {option?.name.length > 25 ? `${option?.name.substring(0, 25)}...` : option?.name || '--'}
                  </label>
                </Tooltip>
                <Download
                  color="primary"
                  className="h-6 w-6 mt-1 mr-2 origin-center cursor-pointer"
                  onClick={() => {
                    downloadFile(option);
                  }}
                />
              </div>
            ))}
          </div>
        );
      } else {
        <label className="label-overflow">--</label>;
      }
    }
    if (data.type === TEXTEDITOR) {
      return (
        <div className="mt-2">
          <RichTextEditor mode="view" convertedContent={value || ''} readOnly={true} toolbarHidden={true} />
        </div>
      );
    }
    return getDefectValue(data, value);
  };
  const detailViewElements = (data) => {
    return (
      <>
        <div className="flex flex-col justify-center">
          <div className="flex">
            <div className="flex ">
              <label className="fontPoppinsMediumLg">
                {data ? (
                  <Tooltip title={data?.label} placement="top">
                    <div className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                      {data?.label.length > 32 ? `${data?.label.substring(0, 32)}...` : data?.label}
                    </div>
                  </Tooltip>
                ) : null}
              </label>
            </div>
          </div>
          <div className="contentDataStyle">
            {data?.label === 'Comments' ? (
              <>
                <div className="comment-area">
                  <div className="comment-section">
                    <commentContext.Provider>
                      {comments?.length > 0
                        ? comments?.map((comment, index) => {
                            return (
                              <Comment
                                key={index}
                                id={index}
                                data={comment}
                                commentsArray={comments}
                                onAddComment={setComments}
                                detailsView={true}
                              />
                            );
                          })
                        : '--'}
                    </commentContext.Provider>
                  </div>
                </div>
              </>
            ) : (
              detailViewComponents(data)
            )}
          </div>
        </div>
      </>
    );
  };

  const { isEditable, hasFullAccess } = React.useMemo(
    () => ({
      isEditable: window.permission?.isEditAccess('defects'),
      hasFullAccess: window.permission?.isFullAccess('defects'),
    }),
    []
  );

  return (
    <div>
      <div className="page-table-body w-full" id="journal-scroll">
        <div className="content_area_style" id="journal-scroll">
          <div className="pt-3 pb-3 border_style flex flex-wrap items-center tableHeader">
            <label className="project_label text-sm flex-auto">
              {detailsViewData.map((data) =>
                data?.label === 'Summary' ? (
                  <Tooltip title={data?.value} placement="top">
                    <label>{data?.value}</label>
                  </Tooltip>
                ) : null
              )}
            </label>
            {(isEditable || hasFullAccess) && (
              <div className="pr-4">
                <div className="flex flex-row ml-32">
                  <div className="ml-auto pl-4">
                    <button
                      className="ml-1 primary-btn"
                      onClick={() => {
                        getSingleDefect();
                        setShowDefectModal(true);
                        setModalType('edit');
                      }}
                    >
                      <EditIcon className="mr-1" fontSize="small" />
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="details-content-height" id="journal-scroll">
            <div className="mt-3 mx-6 my-6 shadow-none">
              <Grid container spacing={{ xs: 2, md: 3, sm: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {detailsViewData &&
                  detailsViewData.length > 0 &&
                  detailsViewData.map((data, index) => {
                    return [TEXTBOX, TEXTEDITOR].includes(data.type) && data?.label !== 'Comments' ? (
                      <Grid item xs={12} sm={12} md={12} key={index}>
                        {detailViewElements(data)}
                      </Grid>
                    ) : data?.label !== 'Comments' ? (
                      <Grid item xs={4} sm={4} md={4} key={index}>
                        {detailViewElements(data)}
                      </Grid>
                    ) : null;
                  })}
                {detailsViewData &&
                  detailsViewData.length > 0 &&
                  detailsViewData.map((data, index) => {
                    return data?.label === 'Comments' ? (
                      <Grid item xs={12} sm={12} md={12} key={index}>
                        {detailViewElements(data)}
                      </Grid>
                    ) : null;
                  })}
              </Grid>
            </div>
          </div>
        </div>
      </div>
      {showDefectModal && Object.keys(selectedDefectData).length > 0 && (
        <DefectCreateModal
          open={showDefectModal}
          closeModal={setShowDefectModal}
          modalType={modalType}
          defectMgmtData={defectMgmtData}
          MyAlert={props?.MyAlert}
          selectedDefectData={selectedDefectData}
          setSelectedDefectData={setSelectedDefectData}
          initDefectData={initDefectData}
        />
      )}
    </div>
  );
}

export default DefectDetailsView;
export const commentContext = createContext();

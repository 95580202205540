import React from 'react';
import { Label } from '../LabelComponent';
import './icon-with-labelled-input.scss';
import { Tooltip } from '@material-ui/core';

const IconWithLabelledInput = ({
  text,
  className,
  showLabel = true,
  toolTipTitle = '',
  placement = '',
  showToolTip = false,
  labelProps = {},
}) => {
  const content = <span className="fontPoppinsRegularSm name-wrapper">{text}</span>;
  return (
    <>
      {showLabel && <Label {...labelProps} />}
      <div className="flex flex-col os-section">
        {showToolTip ? (
          <Tooltip title={toolTipTitle} placement={placement}>
            {content}
          </Tooltip>
        ) : (
          content
        )}
      </div>
    </>
  );
};

export default IconWithLabelledInput;

import React from 'react';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import { useAlert } from '@pages/common/alert_service/useAlert';
import Analytics from './index';
import DefectsAnalytics from './DefectsAnalytics';
import './analytics.css';
import {
  getCurrentProjectType,
  getAutomationProjectTypes,
  getPrivilege,
  getAnalyticsTabs,
  getCurrentProjectData,
} from '@src/util/common_utils';
import IndividualProjectDashboardPage from '../dashboard/IndividualProjectDashboardPage';
import DashBoardTable from '../custom_dashboard/DashBoardTable';
import CustomGraphTable from '../custom_dashboard/custom_graph/CustomGraphTable';
import { DASHBOARD_CONSTANTS } from '@src/common/ui-constants';
import './Analytics.scss';

const AnalyticsTabRouter = (props) => {
  const { AlertContatiner, MyAlert } = useAlert();
  const history = useHistory();
  let activeTab = history.location.pathname.split('/')[2];

  // TODO :: FILTER TABS LIST ROUTES BASED ON LICENSE PRIVIEGE :: START
  const updateLicenseFeatures = getAnalyticsTabs();
  const defectAnalytics = updateLicenseFeatures?.some((features) => features.includes('defectAnalytics'));
  const automationSuite = updateLicenseFeatures?.some((features) => features.includes('automationSuite'));
  const manualSuite = updateLicenseFeatures?.some((features) => features.includes('manualSuite'));
  let projectType = getCurrentProjectType();
  let isAutomationProject = getAutomationProjectTypes(getPrivilege())?.includes(projectType);
  const selectedProject = getCurrentProjectData();
  let tabsList = [];

  if (automationSuite && isAutomationProject) {
    tabsList.push({
      name: 'Automation Suite Analytics',
      pathUrl: `/dashboard/automation-suite`,
      selectedName: 'automation-suite',
    });
  }
  if (manualSuite) {
    tabsList.push({
      name: 'Manual Suite Analytics',
      pathUrl: `/dashboard/manual-suite`,
      selectedName: 'manual-suite',
    });
  }
  if (defectAnalytics) {
    tabsList.push({
      name: 'Defects Analytics',
      pathUrl: '/dashboard/defects',
      selectedName: 'defects',
    });
  }
  tabsList.unshift({
    name: 'Project Dashboard',
    pathUrl: `/dashboard/project-dashboard`,
    selectedName: 'project-dashboard',
  });
  tabsList.push({
    name: 'Custom Dashboard',
    pathUrl: `/dashboard/custom-dashboard`,
    selectedName: 'custom-dashboard',
  });
  // TODO :: FILTER TABS LIST ROUTES BASED ON LICENSE PRIVIEGE :: START

  const renderTabs = () => {
    return (
      <>
        <div className=" tab-navbar-section">
          <ul className="navBar  flex flex-row  -ml-1">
            {tabsList.map((tab, index) => {
              return (
                <span key={tab.selectedName}>
                  <span
                    className={
                      activeTab === tab.selectedName
                        ? 'flex flex-row  pt-2 pb-0  custom-dashBoard-selected-tab-style mr-6 '
                        : 'flex flex-row  pt-2 pb-0  custom-dashBoard-tab-style mr-6'
                    }
                  >
                    <Link
                      className={
                        activeTab === tab.selectedName
                          ? 'fontPoppinsSemiboldMd border-b-2 navBarBorder custom-dashBoard-selected-tab-style'
                          : 'custom-dashBoard-tab-style'
                      }
                      to={tab.pathUrl}
                    >
                      {tab.name}
                    </Link>
                  </span>
                </span>
              );
            })}
          </ul>
          <div style={{ marginTop: '-2px' }} className="border-b-2 border-solid "></div>
        </div>

        {!['project-dashboard', 'custom-dashboard'].includes(activeTab) ? (
          <div className="tab-header px-1 py-2 fontPoppinsSemiboldMd capitalize mt-2 ml-1">
            {' '}
            {DASHBOARD_CONSTANTS.ANALYTICS}- {activeTab.replace(/-/, ' ')}{' '}
          </div>
        ) : (
          activeTab === 'project-dashboard' && (
            <div className="tab-header px-1  py-2 fontPoppinsSemiboldMd capitalize mt-2 ml-1">
              {' '}
              {DASHBOARD_CONSTANTS.PROJECT_DASHBOARD}
            </div>
          )
        )}
      </>
    );
  };
  return (
    <div className="page-list-body h-90v overflow-hidden">
      <div className="alert-position-style">
        <AlertContatiner></AlertContatiner>
      </div>
      {renderTabs()}
      <Switch>
        {
          <Route path="/dashboard/project-dashboard" exact={true} strict={true} key="project-dashboard">
            <div className="mt-2 overflow-y-auto">
              <IndividualProjectDashboardPage project={selectedProject} />
            </div>
          </Route>
        }
        {automationSuite && isAutomationProject && (
          <Route path="/dashboard/automation-suite" exact={true} strict={true} key="automation-analytics-page">
            <Analytics {...props} MyAlert={MyAlert} />
          </Route>
        )}

        {manualSuite && (
          <Route path="/dashboard/manual-suite" exact={true} strict={true} key="manual-analytics-page">
            <Analytics {...props} MyAlert={MyAlert} />
          </Route>
        )}

        {
          <Route path="/dashboard/defects" exact={true} strict={true} key="defects-analytics-page">
            <DefectsAnalytics {...props} MyAlert={MyAlert} />
          </Route>
        }
        {
          <Route path="/dashboard/custom-dashboard" exact={true} strict={true} key="defects-analytics-page">
            <DashBoardTable />
          </Route>
        }
        {
          <Route
            path={`/dashboard/custom-dashboard/:graphName/customiseGraph`}
            exact={true}
            strict={true}
            key="custom-graph-page"
          >
            <CustomGraphTable />
          </Route>
        }
      </Switch>
    </div>
  );
};

export default AnalyticsTabRouter;

import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';
import { useAlert } from '../../common/alert_service/useAlert';
import Breadcrumbs from '../../common/breadcrumbs';
import { SearchIcon } from '@heroicons/react/solid';
import '../../common/dropdown.css';
const ResultHistory = (props) => {
  const { AlertContatiner, MyAlert } = useAlert();
  let activeTab = props.location.pathname.split('/')[3];

  const [tabsList, setTabsList] = useState([
    {
      name: 'Result Configuration',
      pathUrl: `/configuration/Result&HistoryConfiguration/ResultConfiguration`,
      selectedName: 'ResultConfiguration',
    },

    {
      name: 'History',
      pathUrl: `/configuration/Result&HistoryConfiguration/History`,
      selectedName: 'History',
    },
  ]);

  const renderTabs = () => {
    return (
      <div>
        <ul className="navBar text-blue-600 flex flex-row primary-tab">
          {tabsList.map((tab, index) => {
            return (
              <li key={tab.name}>
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    props.history.push(tab.pathUrl);
                  }}
                  className={`${activeTab === tab.selectedName ? 'selected_tab_style' : ''}`}
                >
                  {tab.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <div className="page-container">
      <div className="page-list-body">
        {renderTabs()}
        <AlertContatiner />
        <div className="page-table-body">
          <div className="configPage pr-0 content_area_style" id="journal-scroll">
            <div className="grid grid-cols-2 mb-4 ">
              <span className=" ml-3 mt-4 configPageHeading">History</span>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginRight: '15px',
                }}
              >
                <SearchIcon
                  style={{ marginRight: '-1.5rem', color: 'grey' }}
                  className=" mt-4 w-20 h-7   items-center float-left"
                />
              </div>
            </div>
            <hr></hr>

            <div className="pagecountborder-set mb-3" id="footer-notfixed">
              <div className="float-right pr-4">
                <span className="pagecount">1-5 of 5</span>
                <span className="pageborderleft ml-2 pl-2">
                  <button className="pageArrow">{'<'}</button>{' '}
                  <span className="currentpagecount ml-2 mr-2">
                    {' '}
                    <span className="pl-2 pr-2">1</span>
                  </span>
                  <button className="pageArrow">{'>'}</button>{' '}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ResultHistory);

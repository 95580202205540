import React from 'react';

function LeftArrowSvgIcon() {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="6.585" height="11.175" viewBox="0 0 6.585 11.175">
        <path
          id="keyboard_arrow_right_black_48dp"
          d="M15.29,15.88,11.41,12l3.88-3.88a1,1,0,0,0-1.41-1.41L9.29,11.3a1,1,0,0,0,0,1.41l4.59,4.59a1,1,0,0,0,1.41,0,1.017,1.017,0,0,0,0-1.42Z"
          transform="translate(-8.997 -6.417)"
          fill="#434db8"
        />
      </svg>
    </div>
  );
}

export default LeftArrowSvgIcon;

import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';
import { useAlert } from '../../common/alert_service/useAlert';
import { SearchIcon } from '@heroicons/react/solid';
import '../../common/dropdown.css';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import HomeIcon from '@material-ui/icons/Home';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import '../ElementConfig/elementConfig.css';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
    color: 'rgb(22,72,198)',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height: 600,
  bgcolor: 'background.paper',

  boxShadow: 12,
};

const Memory_history = (props) => {
  const classes = useStyles();
  const { AlertContatiner, MyAlert } = useAlert();
  let activeTab = props.location.pathname.split('/')[3];

  const [tabsList, setTabsList] = useState([
    {
      name: 'Memory Configuration',
      pathUrl: `/configuration/memoryConfiguration`,
      selectedName: 'memoryConfiguration',
    },

    // {
    //     "name": "History",
    //     "pathUrl": `/configuration/memoryConfiguration/history`,
    //     "selectedName": "history"
    // }
  ]);

  const renderTabs = () => {
    return (
      <div>
        <ul className="navBar text-blue-600 flex flex-row primary-tab">
          {tabsList.map((tab, index) => {
            return (
              <li key={tab.name}>
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    props.history.push(tab.pathUrl);
                  }}
                  className={`${activeTab === tab.selectedName ? 'selected_tab_style' : ''}`}
                >
                  {tab.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };
  return (
    <>
      <div className={classes.root}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" href="/">
            <HomeIcon className={classes.icon} />
          </Link>
          <Link className="breadcrumb" href="/getting-started/installation/">
            Configuration
          </Link>
          <Link className="breadcrumb" href="/getting-started/installation/">
            Memory Configuration
          </Link>
          <Link className="breadcrumb" href="/getting-started/installation/">
            History
          </Link>
        </Breadcrumbs>
      </div>

      <div className="flex ">{renderTabs()}</div>

      <div className="page-list-body">
        <div className="page-table-body">
          <div className="content_area_style" id="journal-scroll">
            <div className="grid grid-cols-2 mb-4 ">
              <span className=" ml-3 mt-4 text-sm flex-auto  configPageHeading">History</span>
              <div className="icon">
                <SearchIcon className="search mt-4 w-15 h-5   items-center float-left" />
              </div>
            </div>

            <hr></hr>

            <div className=" grid cols-grid-6">
              <span className="text-sm text-gray-700 hover:bg-gray-100 h-10 p-3">
                Manjunath has distributed memory on 28 jan 2021 13:20
              </span>
              <span className="text-sm text-gray-700 hover:bg-gray-100 h-10 p-3">
                Manjunath has purchased Storge Plan on 29 jan 2021 12:20
              </span>
              <span className="text-sm text-gray-700 hover:bg-gray-100 h-10 p-3">
                Manjunath has distributed memory on 30 jan 2021 13:20
              </span>
            </div>

            <div className="pagecountborder-set mb-3" id="footer-notfixed">
              <div className="float-right pr-4">
                <span className="pagecount">1-5 of 5</span>
                <span className="pageborderleft ml-2 pl-2">
                  <button className="pageArrow">{'<'}</button>{' '}
                  <span className="currentpagecount ml-2 mr-2">
                    {' '}
                    <span className="pl-2 pr-2">1</span>
                  </span>
                  <button className="pageArrow">{'>'}</button>{' '}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </>
  );
};

export default withRouter(Memory_history);

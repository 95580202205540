import { useEffect, useState } from 'react';
import Button from '../Button/Button';
import CommentForm from '../CommentForm/CommentForm';
import './CommentBoxContainer.scss';
import { createComments } from '@src/api/api_services';

const CommentBoxContainer = (props) => {
  const {
    onCancel,
    id,
    commentId,
    userId,
    onRespond,
    commentsArray,
    replyCommentsLength,
    replyTo,
    isThreadCommentReply,
    defectId,
    dataPassToParent,
    projectUsers,
  } = props;
  const [reset, setReset] = useState(false);
  const [currentComment, SetCurrentComment] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const handleClick = () => {
    let userEmailArray = [];
    let emailArray = [];
    let getProjectUser = [...projectUsers];

    let userName = currentComment.split(/(?=[" ",@])/gi);
    let fetchOnlyUserName = userName.filter((atuserName) => atuserName.includes('@'));
    let fetchUser = fetchOnlyUserName.map((user) => user.slice(1));
    let unique = [...new Set(fetchUser.filter((word) => word.trim().length > 0))];

    getProjectUser.map((usernames) => {
      unique.map((user) => {
        if (usernames.name.includes(user.slice(0, 5))) {
          emailArray.push(usernames);
        }
      });
    });

    emailArray.map((userEmail) => {
      userEmailArray.push(userEmail.emailId);
    });

    var userCheckwithSpecial = new RegExp('^[s@"@"@A-Za-z]+$').test(currentComment);
    const data = {
      description: currentComment,
      commentParentId: commentId,
      emailId:
        userCheckwithSpecial === true || currentComment === '@' ? [] : userEmailArray?.length > 0 ? userEmailArray : [],
      comments: [],
    };

    createComments(defectId, data).then((response) => {
      if (response.data.responseObject) {
        dataPassToParent(response.data.responseObject);
      }
    });

    if (isThreadCommentReply) {
      data.isThreadCommentReply = isThreadCommentReply;
    }

    const commentsList = JSON.parse(JSON.stringify(commentsArray));
    commentsList.filter((comment) => {
      if (comment.id === commentId) {
        if (comment.comments) {
          let replyComments = comment.comments;
          comment.comments = [...replyComments, data];
        } else {
          comment.comments = [data];
        }
        return comment;
      } else {
        comment.comments?.filter((replyComments) => {
          if (replyComments.id === id) {
            if (replyComments.comments) {
              let replyThreadComments = replyComments.comments;
              replyComments.comments = [...replyThreadComments, data];
            } else {
              replyComments.comments = [data];
            }
          }
        });
      }
    });
    onRespond(commentsList);
    onCancel();
  };

  useEffect(() => {
    if (currentComment.trim().length === 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [currentComment]);

  return (
    <div className="wrapper">
      <div className="commentBoxContainer">
        <CommentForm
          getData={SetCurrentComment}
          id={id}
          isReset={reset}
          setReset={setReset}
          placeholder={'Add reply comments...'}
          replyTo={replyTo}
          projectUsers={projectUsers}
        />
        <div className="commentbox-footer">
          <button type="button" className="ml-3 primary-btn right-spacing-12" onClick={onCancel}>
            {' '}
            cancel{' '}
          </button>
          <button
            type="button"
            className="ml-3 primary-btn"
            id="respondButton"
            onClick={() => handleClick()}
            {...(isDisabled ? { disabled: 'disabled' } : '')}
          >
            {' '}
            Respond{' '}
          </button>
        </div>
      </div>
    </div>
  );
};
export default CommentBoxContainer;

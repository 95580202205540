import React from 'react';
import { LABELS_CONSTANTS } from '@src/common/ui-constants';
import CommonDrawerJs from '@pages/configuration/plugin/CommonIntegrationDrawer';
import { Tooltip } from '@material-ui/core';

const ConfigDetails = (props) => {
  const result = props?.projectDetails?.projects;
  const rowLabelDetails = props.labelDetails;
  return (
    <CommonDrawerJs
      isDrawerOpen={true}
      headerContent={`${LABELS_CONSTANTS.LABEL_DETAILS} - ${rowLabelDetails?.name}`}
      drawerWidth="470px"
      leftButtonText={LABELS_CONSTANTS.CLOSE}
      isLeftButtonVisible={true}
      isRightButtonVisible={false}
      onDrawerOpen={props.onClose}
      onLeftButtonClick={props.onClose}
    >
      <div className="w-full h-full flex flex-col ">
        <div id="Journal-scroll" className="grid grid-cols-3 mt-4 pb-4 ml-6 overflow-y-auto max-h-72">
          <div className="overflow-hidden-common">
            <label
              htmlFor="userName"
              className="block label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {LABELS_CONSTANTS.LABEL_NAME}
            </label>
            <Tooltip title={rowLabelDetails?.name} placement="bottom">
              <label
                className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1"
                title={result?.name}
              >
                {rowLabelDetails?.name.length >= 12
                  ? rowLabelDetails?.name.substring(0, 10) + '...'
                  : rowLabelDetails?.name}
              </label>
            </Tooltip>
          </div>
          <div className="overflow-hidden-common ml-6">
            <label
              htmlFor="userName"
              className="block label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {LABELS_CONSTANTS.LABEL_TYPE}
            </label>
            <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
              {rowLabelDetails?.type}
            </label>
          </div>
          <div className="ml-6">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {LABELS_CONSTANTS.CREATED_BY}
            </label>
            <Tooltip title={rowLabelDetails?.createdByUname} placement="bottom">
              <label className="config-details-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
                {rowLabelDetails?.createdByUname.length >= 12
                  ? rowLabelDetails?.createdByUname.substring(0, 10) + '...'
                  : rowLabelDetails?.createdByUname || '--'}
              </label>
            </Tooltip>
          </div>
          <div className="mt-6">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {LABELS_CONSTANTS.CREATED_ON}
            </label>
            <label className="config-details-style-commonoverflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
              {' '}
              <label className="config-details-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-200 fontPoppinsRegularSm mt-1">
                {rowLabelDetails?.createdOn || '--'}
              </label>
            </label>
          </div>
          <div className="mt-6 ml-6">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {LABELS_CONSTANTS.MODIFIED_BY}
            </label>
            <label className="instance-details-style fontPoppinsRegularSm">
              {rowLabelDetails?.modifiedByUname || '--'}
            </label>
          </div>
          <div className="mt-6 ml-6">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {LABELS_CONSTANTS.MODIFIED_ON}
            </label>
            <label className="config-details-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
              {rowLabelDetails?.modifiedOn || '--'}
            </label>
          </div>
        </div>
        <div className="grid grid-cols-1 mt-1 pb-4 ml-5 overflow-y-auto max-h-72">
          <div className="mt-2">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {LABELS_CONSTANTS.DESCRIPTION}
            </label>
            <label className="details-data-style-common overflow-visible w-full whitespace-normal break-words float-left fontPoppinsRegularSm mt-1">
              {' '}
              <Tooltip title={rowLabelDetails?.description || '--'} placement="bottom-start">
                <label className="details-data-style-common overflow-visible w-full whitespace-normal break-words float-left max-w-200 fontPoppinsRegularSm p-1">
                  {rowLabelDetails?.description || '--'}
                </label>
              </Tooltip>
            </label>
          </div>
        </div>
      </div>
    </CommonDrawerJs>
  );
};

export default ConfigDetails;

import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import style from '@pages/licenses/styles/license-form.module.scss';
import { LICENSE_FORM_TYPES } from '@util/licenseUtils';

export const LicenseFormHeader = (licenseName) => {
  const { pathname } = useLocation();
  const formTypeData = Object.values(LICENSE_FORM_TYPES).find((formType) => pathname.endsWith(formType.endingPath));
  return (
    <>
      <div className={cx('w-full mx-auto fontPoppinsRegularMd', style['license-form'])}>
        <div className="pl-1">
          <h1 className={cx('fontPoppinsSemiboldMd my-1', style['heading'])}>
            {formTypeData?.type === 'BUY'
              ? formTypeData?.label
              : formTypeData?.label !== undefined
                ? formTypeData?.label + ' - ' + licenseName
                : ''}
          </h1>
        </div>
      </div>
    </>
  );
};

import React, { Fragment, useEffect, useState } from 'react';
import FileModal from '../test_data/files/file_modal';
import FolderModal from '../test_data/folder/folder_modal.js';
import '../common/tree_toolbar.css';
import TreeSearchBar from '../common/table_tree/tree_searchbar';

function TestDataTreeToolbar({ searchTree, resetTree, getTree, ...props }) {
  const currentPageName = props.page;
  let containerName = '';
  if (currentPageName.toLowerCase() === 'element') {
    containerName = 'Page';
  } else if (currentPageName.toLowerCase() === 'program element') {
    containerName = 'Package';
  } else if (currentPageName.toLowerCase() === 'step group') {
    containerName = 'Library';
  } else if (currentPageName.toLowerCase() === 'test data') {
    containerName = 'Folder';
  }
  const reloadTree = (value) => {
    props.reloadTree(value);
  };

  let treeData = props.treeData;
  let disabled = treeData?.length ? false : true;
  if (treeData) {
    if (treeData[0]) {
      if (!treeData[0].title.includes(' folder') && treeData[0].title.toLowerCase() === 'root') {
        treeData[0].title = treeData[0].title.concat(' Folder');
      }
      if (treeData?.length === 1 && !treeData[0]?.children?.length) {
        if (treeData[0]?.folder && treeData[0].title.toLowerCase() === 'root folder') {
          disabled = true;
        } else {
          disabled = false;
        }
      }
    }
  } else {
    disabled = true;
  }

  useEffect(() => {
    if (props.callcreate === true && props.page === 'Element') {
      createPage();
    }
  }, [props.callcreate]);

  const [openElementModal, setOpenElementModal] = useState(false);
  const [openFolderModal, setopenFolderModal] = useState(false);
  const [openFileModal, setopenFileModal] = useState(false);

  const closeModal = (value) => {
    if (openFolderModal) {
      setopenFolderModal(value);
    } else if (openFileModal) {
      setopenFileModal(value);
    }
  };
  let handelPage = () => {
    if (props.page === 'Element') {
      repositoryPage();
    } else if (props.page === 'Module') {
      testDevelopmentPage();
    } else if (props.page === 'Test Data') {
      testDataPage();
    } else if (props.page === 'Program Element') {
      props.programElement(true);
    } else if (props.page === 'Step Group') {
      repositoryPage();
    }
  };
  let repositoryPage = () => {
    setOpenElementModal(true);
  };
  let testDevelopmentPage = () => {
    alert('Module');
  };
  let createPage = () => {
    setopenFolderModal(true);
  };

  let testDataPage = () => {
    setopenFileModal(true);
  };

  const formatTreeHeader = (header) => {
    if (header !== 'Test Data') {
      header = header.concat(' ' + containerName);
    }
    return header;
  };
  const treeHeader = formatTreeHeader(props.header);
  return (
    <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
      <label className="project_label text-sm flex-auto" title={treeHeader}>
        {treeHeader}
      </label>
      <div className="pr-4">
        <div className="flex flex-row ml-32">
          <TreeSearchBar
            disabled={disabled}
            treeData={treeData}
            searchTree={searchTree}
            resetTree={resetTree}
            setSearchedText={props.setSearchedText}
          />
          {global.permission.isEditAccess('testData') && (
            <>
              <button
                id="createcontainerbutton"
                className="ml-3 primary-btn mr-3"
                disabled={props.isLoading}
                onClick={() => {
                  createPage();
                }}
              >
                + {containerName}
              </button>
              <button
                className={`ml-3  ${props.containerCount > 0 ? 'primary-btn' : 'primary-btn btn-disabled'}`}
                onClick={handelPage}
                disabled={props.isLoading || !(props.containerCount > 0)}
              >
                + {props.page !== 'Test Data' ? props.page : 'File'}
              </button>
            </>
          )}
        </div>
      </div>
      {openFolderModal ? (
        <FolderModal
          closeModal={closeModal}
          reloadTree={reloadTree}
          treeData={props.treeData}
          mode="ADD"
          MyAlert={props.MyAlert}
          nodeToAdd={props.nodeToAdd}
        />
      ) : null}
      {openFileModal ? (
        <FileModal
          closeModal={closeModal}
          reloadTree={reloadTree}
          treeData={props.treeData}
          mode="ADD"
          nodeToAdd={props.nodeToAdd}
          MyAlert={props.MyAlert}
        />
      ) : null}
    </div>
  );
}

export default TestDataTreeToolbar;

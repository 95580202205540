import { useContext } from 'react';
import { ClientsContext } from '../components/ClientsProvider';
import StackFilledBarChart from './StackFilledBarChart';

function ClientsAccess() {
  const { accessesData } = useContext(ClientsContext);
  const { title, labels, colors, series, yAxisLabel } = accessesData;
  return <StackFilledBarChart title={title} labels={labels} colors={colors} series={series} yAxisLabel={yAxisLabel} />;
}

export default ClientsAccess;

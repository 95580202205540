import PieChart from './PieChart';
import DashboardStyle from '../styles/dashboard.module.scss';
import { useCallback } from 'react';
import { getLicenseFeatures, isEmptyValue } from '@src/util/common_utils';
import { LICENSE_FEATURES } from '@common/ui-constants';

const projectStatusesData = {
  title: 'Status',
  colors: ['#399365 ', '#BE4854', '#E0B94F'],
  fillColor: ['#399365', '#BE4854', '#E0B94F'],
  labels: ['Open', 'Closed', 'Archive'],
  titleOffsetX: -98,
  strokeWidth: 0,
};

const initialSeries = [0, 0, 0];

function ProjectStatus({ series = initialSeries, tooltipData }) {
  const hasDefects = window.privilege.hasDefects(getLicenseFeatures());
  const hasAutomation = window.privilege.hasAutomation(getLicenseFeatures());
  const hasManualTestCase = window.privilege.hasManualTestCase(getLicenseFeatures());
  let purchasedProjectsType = getLicenseFeatures()?.filter(
    (type) => ![LICENSE_FEATURES.MANUAL_TESTCASES, LICENSE_FEATURES.DEFECTS, LICENSE_FEATURES.ALL].includes(type)
  );
  let isOnlyAutomation = hasAutomation && !hasDefects && !hasManualTestCase;

  const chartTooltipFormatter = useCallback(
    ({ seriesIndex, w }) => {
      if (seriesIndex === 3) {
        return null;
      }
      let tooltipObject = {};
      if (isOnlyAutomation && !isEmptyValue(purchasedProjectsType)) {
        if (purchasedProjectsType.includes(LICENSE_FEATURES.WEB)) {
          tooltipObject[LICENSE_FEATURES.WEB] = tooltipData[seriesIndex]?.webCount || 0;
        }

        if (purchasedProjectsType.includes(LICENSE_FEATURES.MOBILE)) {
          tooltipObject[LICENSE_FEATURES.MOBILE] = tooltipData[seriesIndex]?.mobileCount || 0;
        }

        if (
          purchasedProjectsType.includes(LICENSE_FEATURES.WEB) &&
          purchasedProjectsType.includes(LICENSE_FEATURES.MOBILE)
        ) {
          tooltipObject['Web & Mobile'] = tooltipData[seriesIndex]?.webAndMobileCount || 0;
        }

        if (purchasedProjectsType.includes(LICENSE_FEATURES.WEB_SERVICE)) {
          tooltipObject['Web Service'] = tooltipData[seriesIndex]?.webserviceCount || 0;
        }
        if (purchasedProjectsType.includes(LICENSE_FEATURES.SALESFORCE)) {
          tooltipObject[LICENSE_FEATURES.SALESFORCE] = tooltipData[seriesIndex]?.salesforceCount || 0;
        }

        if (purchasedProjectsType.includes(LICENSE_FEATURES.MS_DYNAMICS)) {
          tooltipObject['MS Dynamics'] = tooltipData[seriesIndex]?.msDynamicsCount || 0;
        }
      } else {
        tooltipObject = {
          Web: tooltipData[seriesIndex]?.webCount || 0,
          Mobile: tooltipData[seriesIndex]?.mobileCount || 0,
          'Web & Mobile': tooltipData[seriesIndex]?.webAndMobileCount || 0,
          'Web Service': tooltipData[seriesIndex]?.webserviceCount || 0,
          Salesforce: tooltipData[seriesIndex]?.salesforceCount || 0,
          'MS Dynamics': tooltipData[seriesIndex]?.msDynamicsCount || 0,
        };
      }

      const { colors } = w.globals;
      return (
        `<div class="${DashboardStyle['piechart-tooltip-wrapper']} ${DashboardStyle['piechart-tooltip']} border-2 rounded-lg" style="border-color: ${colors?.[seriesIndex]}">` +
        Object.entries(tooltipObject)
          .map(([typeKey, typeValue]) => `<span> ${typeKey} : ${typeValue} </span>`)
          .join('') +
        `</div>`
      );
    },
    [tooltipData]
  );

  return (
    <PieChart
      colors={projectStatusesData.colors}
      fillColor={projectStatusesData.fillColor}
      title={projectStatusesData.title}
      titleOffsetX={projectStatusesData.titleOffsetX}
      labels={projectStatusesData.labels}
      strokeWidth={projectStatusesData.strokeWidth}
      series={series}
      chartTooltipFormatter={chartTooltipFormatter}
    />
  );
}

export default ProjectStatus;

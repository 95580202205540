import React, { useEffect, Fragment } from 'react';
import './simple_tree.scss';
import $ from 'jquery';

const SimpleTree = (props) => {
  useEffect(() => {
    initSimpleTree();
  });

  function initSimpleTree() {
    if (props.data) {
      // If tree is already generated
      if ($('#dropdown-select-tree').html()) {
        return;
      }
      console.log('initSimpleTree');
      $('#dropdown-select-tree').fancytree({
        checkbox: false,
        autoScroll: true,
        selectMode: 1,
        source: props.data,
        expanded: true,
        // minExpandLevel: 2,
        filter: {
          counter: false,
          mode: 'hide',
          highlight: false,
        },
        extensions: ['filter'],
        click: function (event, data) {
          let targetType = $.ui.fancytree.getEventTargetType(event.originalEvent);
          if (targetType === 'title') {
            //for below object node key is added by prajwal
            props.nodeSelected({
              data: { ...data.node.data, key: data.node.key },
              title: data.node.title,
              node: data.node,
            });
          }
        },
        dblclick: function (e, data) {
          e.preventDefault();
          e.stopPropagation();
        },
        createNode: function (event, data) {
          const node = data.node;
          const _span = $(node.span).find('.fancytree-title');
          let title = _span.text();
          _span.attr('title', title);
        },
        renderNode: function (event, data) {},
        renderColumns: function (event, data) {},
      });
    }
    $('.fancytree-container').addClass('fancytree-connectors');
  }

  const filterTreeData = (query) => {
    if (props.showOnlyFolders) {
      let string = query.replaceAll(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\\\');
      const regEx = new RegExp(string, 'i');
      $.ui.fancytree.getTree('#dropdown-select-tree').filterNodes(function (node) {
        var match = regEx.test(node.title);
        // below condition is modified by Prajwal (prev cond ::> match && node.folder)
        return match && node.folder && node.title !== 'Postconditions' && node.title !== 'Preconditions';
      });
    } else {
      $.ui.fancytree.getTree('#dropdown-select-tree').filterNodes(query, {
        autoExpand: true,
      });
    }
    const filterdCount = $.ui.fancytree.getTree(`#dropdown-select-tree`).findAll(query).length;

    if (!filterdCount || !props.data || !props.data.length) {
      $('.no-simple-tree-data-found').show();
    } else {
      $('.no-simple-tree-data-found').hide();
    }
  };

  useEffect(() => {
    filterTreeData(props.filter);
  }, [props.filter]);

  return (
    <Fragment>
      <div className="pt-2">
        <div id="dropdown-select-tree" width="100%" className="fancytree"></div>
        <div className="no-simple-tree-data-found fontPoppinsMediumMd">
          {props.noDataMessage ? props.noDataMessage : 'No data found.'}
        </div>
      </div>
    </Fragment>
  );
};
export default SimpleTree;

import React from 'react';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { getScreenVideoReq } from '@api/api_services';

let resultSetting = {
  captureScreenshots: 'For Failed steps only',
  captureVideoForFailedTestScript: 'For Failed scripts only',
  displayLogsInScriptResult: 'On Suite Execution Completion / Termination',
};
const ResultSettings = (props) => {
  let captureVideo = ['For Failed scripts only', 'For all scripts', "Don't capture"];
  let captureScreenShotarray = ['For Failed steps only', 'For all Steps', "Don't Capture"];
  let captureExeVideoArray = ['Yes', 'No'];
  let displayLoginArray = ['On Suite Execution Start', 'On Suite Execution Completion / Termination'];

  const [captureScreenshotVal, setCaptureScreenshotVal] = React.useState('For Failed steps only');
  const [reload, setReload] = React.useState(false);

  function handleCheck(val, type) {
    if (type === 'Screenshots') {
      resultSetting.captureScreenshots = val;
    } else if (type === 'Video') {
      if (val === 'Yes') {
        resultSetting.captureVideoForFailedTestScript = true;
      } else {
        resultSetting.captureVideoForFailedTestScript = false;
      }
    } else if (type === 'captureVideo') {
      resultSetting.captureVideoForFailedTestScript = val;
    } else {
      resultSetting.displayLogsInScriptResult = val;
    }
    props.getResultSettingsData(resultSetting);
  }

  React.useEffect(() => {
    if (props.resultSettingsData?.captureScreenshots) {
      let failedTest = props?.resultSettingsData?.captureVideoForFailedTestScript;
      resultSetting = {
        captureScreenshots: props?.resultSettingsData?.captureScreenshots,
        captureVideoForFailedTestScript:
          failedTest === false || failedTest === 'false'
            ? 'For Failed scripts only'
            : props?.resultSettingsData?.captureVideoForFailedTestScript,
        displayLogsInScriptResult: props?.resultSettingsData?.displayLogsInScriptResult,
      };
    } else {
      let projectId = JSON.parse(localStorage.getItem('selected-project')).id;
      getScreenVideoReq(projectId)
        .then((results) => {
          let runSettingsData = results.data.responseObject;
          resultSetting.captureScreenshots =
            runSettingsData?.captureScreenShotFor === 'allSteps'
              ? 'For all Steps'
              : runSettingsData?.captureScreenShotFor === 'failedSteps'
                ? 'For Failed steps only'
                : "Don't Capture";
          resultSetting.captureVideoForFailedTestScript = runSettingsData?.captureVideoForFailedTestScript
            ? runSettingsData?.captureVideoForFailedTestScript
            : 'For Failed scripts only';
          resultSetting.displayLogsInScriptResult = 'On Suite Execution Completion / Termination';
        })
        .catch((error) => {
          console.error('Failed to load result settings data', error);
        });
    }
    setReload(true);
    props.getResultSettingsData(resultSetting);
  }, []);

  return (
    <div className="left-border-style mt-2 mb-2  flex justify-between">
      <div>
        <label className="capture-screenshot-label-style grid">Capture Screenshots</label>
        {reload
          ? captureScreenShotarray.map((radiotxt, optindex) => {
              return (
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  defaultValue="top"
                  style={{ display: '-webkit-inline-flex' }}
                >
                  <FormControlLabel
                    disabled={props?.rerunMode}
                    control={
                      <Radio
                        color="primary"
                        name="captureScreenShot"
                        {...{
                          checked:
                            radiotxt?.toLocaleLowerCase() === resultSetting?.captureScreenshots?.toLocaleLowerCase(),
                        }}
                        id={radiotxt + optindex}
                        label={radiotxt}
                        className=" m-2 cursor-pointer"
                        value={radiotxt}
                        onChange={(e) => handleCheck(radiotxt, 'Screenshots')}
                      />
                    }
                    label={<Typography>{radiotxt}</Typography>}
                  />
                </RadioGroup>
              );
            })
          : null}
      </div>

      <div>
        <label className="capture-screenshot-label-style grid">Capture Execution video</label>
        {reload
          ? captureVideo.map((radiotxt, optindex) => {
              return (
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  defaultValue="top"
                  style={{ display: '-webkit-inline-flex' }}
                >
                  <FormControlLabel
                    disabled={props?.rerunMode}
                    control={
                      <Radio
                        color="primary"
                        name="captureExecutionVideo"
                        {...{
                          checked:
                            radiotxt?.toLocaleLowerCase() ===
                            resultSetting?.captureVideoForFailedTestScript?.toLocaleLowerCase(),
                        }}
                        id={radiotxt + optindex}
                        label={radiotxt}
                        className=" m-2 cursor-pointer"
                        value={radiotxt}
                        onChange={(e) => handleCheck(radiotxt, 'captureVideo')}
                      />
                    }
                    label={<Typography>{radiotxt}</Typography>}
                  />
                </RadioGroup>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default ResultSettings;

import React, { useEffect, useState } from 'react';
import ProgramElement from './program_element_tree';

function PackageChild(props) {
  const [data, setData] = useState(false);
  const search = window.location.search;
  const id = new URLSearchParams(search).get('id');

  const test = () => {
    setData(true);
  };
  useEffect(() => {
    test();
  }, [id]);

  return (
    <div className="page-table-body">
      <ProgramElement dat={data} pageType="child" MyAlert={props.MyAlert} />
    </div>
  );
}

export default PackageChild;

import React from 'react';
import Chart from 'react-apexcharts';
import { colors } from '@src/css_config/colorConstants.js';
import { chartFontFamily } from '@common/ui-constants';

const ColumnChart = ({ dataindex, categories, seriesdata, labelColor }) => {
  let options = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
        tools: {
          download: false,
        },
      },
      events: {
        dataPointSelection: function (event, chartContext, config) {
          dataindex(config.dataPointIndex);
        },
      },
    },

    series: [
      {
        name: 'Servings',
        data: seriesdata,
      },
    ],

    colors: [colors.red_columnChart],
    xaxis: {
      categories: categories,
      title: {
        text: 'Failure Rate',
        style: {
          fontSize: '10px',
          fontWeight: 500,
          fontFamily: chartFontFamily,
          color: colors.grey_dark,
        },
      },
      labels: {
        show: true,
        offsetY: 1,
        style: {
          colors: labelColor,
          fontSize: '10px',
          fontFamily: chartFontFamily,
        },
      },

      axisTicks: {
        show: false,
      },
      offsetX: 0,
    },
    yaxis: {
      title: {
        text: 'No.of Scripts',
        style: {
          fontSize: '10px',
          fontWeight: 500,
          fontFamily: chartFontFamily,
          color: colors.grey_dark,
        },
      },
      labels: {
        style: {
          color: colors.black,
          fontSize: '10px',
          fontFamily: chartFontFamily,
        },
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '24px',
        borderRadius: 7,
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
  };

  return (
    <div>
      <Chart options={options} series={options.series} type="bar" height="260px" width="95%" />
    </div>
  );
};
export default ColumnChart;

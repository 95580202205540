import React, { useCallback, useState } from 'react';
import { RiBug2Fill } from 'react-icons/ri';
import { getSelectedProject } from '@src/service/common_service';
import { getUserName, getUserPrivilege } from '@src/util/common_utils';
import DefectList from '@pages/configuration/DefectConfig/DefectListDrawer';
import { ALERT_MODAL_CONSTANTS, DEFECT_CONSTANTS } from '@src/common/ui-constants';
import AlertPopUps from '@pages/common/alert_pop_ups/AlertPopUps';
import { MODAL_BODY } from '@pages/configuration/DefectConfig/defect-constants';

const BugIcon = (props) => {
  const { issueData } = props;
  const { WARNING } = ALERT_MODAL_CONSTANTS;
  const [openDefectListDrawer, setOpenDefectListDrawer] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const onClickHandler = () => {
    const selectedProject = getSelectedProject();
    if (!selectedProject.defectPlatform?.platform && getUserPrivilege()) {
      setOpenWarningModal(true);
    } else {
      setOpenDefectListDrawer(true);
    }
  };
  const getModalContent = (type) => {
    return [
      <div className="mb-2 capitalize fontPoppinsRegularMd">Hi {getUserName()},</div>,
      <p className="fontPoppinsRegularMd">{MODAL_BODY.WARNING_TEXT}</p>,
    ];
  };

  const cleanUpFunction = useCallback(() => {
    setOpenDefectListDrawer(false);
  }, []);

  return (
    <>
      <span onClick={onClickHandler} className="p-0.5">
        <RiBug2Fill className="text-sm cursor-pointer result-failed" />
      </span>
      {openDefectListDrawer && (
        <DefectList
          history={props.history}
          cleanUpFunction={cleanUpFunction}
          openDrawer={openDefectListDrawer}
          closeDrawer={setOpenDefectListDrawer}
          createButtonRequired={true}
          scriptData={issueData?.nodeObj?.data}
          issueData={issueData}
        />
      )}
      {openWarningModal && (
        <AlertPopUps
          open={openWarningModal}
          alertType={WARNING}
          execution={true}
          showHowToProceed={false}
          showCancelBtn={true}
          showSaveBtn={false}
          onCancelBtnClick={() => {
            setOpenWarningModal(false);
          }}
          dialogClass={'info-child-modal-z-index'}
          cancelBtnText={DEFECT_CONSTANTS.CLOSE}
          content={getModalContent(DEFECT_CONSTANTS.WARNING)}
          alertTitle={DEFECT_CONSTANTS.NOT_CONFIGURED}
        />
      )}
    </>
  );
};

export default BugIcon;

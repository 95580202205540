import React, { useEffect, useState } from 'react';
import { FilterCategory } from './filter-category';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { ReactComponent as SearchIconGreay } from '@assets/analytics/SearchIconGreay.svg';
import './filters.css';
import { Close } from '@mui/icons-material';
import { Icon } from 'fireflink-ui';
import { MULTIFORM_ANALYTICS } from '@src/common/ui-constants';
import { UI_VALIDATIONS } from '@src/util/validations';
export const FilterGroup = (props) => {
  const [expandGroup, setExpandGroup] = useState(true);
  const [menuData, setMenuData] = useState(props.categories || []);
  const [searchValue, setSearchValue] = useState('');
  const [showSearchIcon, setShowSearchIcon] = useState(true);

  useEffect(() => {
    if (searchValue === '') {
      setMenuData(props.categories);
    }
  }, [props.categories, searchValue]);

  const handleSearch = (categories, searchInput) => {
    if (!categories) return;

    if (props.uiLabel === "Suite") {
      const filtered = categories.map(category => ({
        ...category,
        categories: category.categories.filter(subCategory =>
          subCategory.label.toLowerCase().includes(searchInput.toLowerCase()) ||
          subCategory.parentName.toLowerCase().includes(searchInput.toLowerCase())
        )
      })).filter(category => category.categories.length > 0);
      setMenuData(filtered);
    } else if (props.uiLabel === "Labels") {
      const filtered = categories.filter(category =>
        category.label.toLowerCase().includes(searchInput.toLowerCase())
      );
      setMenuData(filtered);
    }
  };

  return (
    props.categories ? (
      <>
        <div className="group">
          <div className="filtersHeader">
            <div className="grid grid-cols-6 pl-2 pt-1 filters_labels">
              <div className="col-span-5 inline-block fontPoppinsSemiboldSm rs-blue">
                {props.uiLabel}{' '}
                {props.uiLabel === 'Suite' ? (
                  <span className="suiteLabel fontPoppinsRegularXs">
                    (Total Suites: <span className="suiteCount fontPoppinsRegularXs">{props.categories.length}</span>)
                  </span>
                ) : props.uiLabel === 'Labels' ? (
                  <span className="suiteCount fontPoppinsRegularXs">
                    (Total Labels: <span className="labelCountNumber fontPoppinsRegularXs">{props.categories.length}</span>)
                  </span>
                ) : (
                  ''
                )}
              </div>
              <div className="col-span-1 inline-block">
                {expandGroup ? (
                  <ChevronDownIcon
                    className="down-arrow-icon ChevronIcon cursor-hand"
                    onClick={() => setExpandGroup(false)}
                  />
                ) : (
                  <ChevronRightIcon
                    className="down-arrow-icon ChevronIcon cursor-hand"
                    onClick={() => setExpandGroup(true)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={`${expandGroup ? 'block suite-filter fontPoppinsMediumSm' : 'hidden suite-filter'} ${props?.categories?.length > UI_VALIDATIONS.FILTERS_OPTION_SCROLLBAR_LIMIT ? 'scrollable' : ''}`}>
          {props.type === 'menu' && (
            <div className="search-section ml-2">
              <div className="flex search-btnBlock items-center relative">
                <input
                  placeholder={MULTIFORM_ANALYTICS.searchSuite}
                  value={searchValue}
                  onChange={(e) => {
                    const value = e.target.value;
                    setSearchValue(value);
                    handleSearch(props.categories, value);
                  }}
                  onKeyUp={() => setShowSearchIcon(!searchValue)}
                  className=""
                />
                {showSearchIcon ? (
                  <Icon name="search_icon" className="absolute right-2" height={22} width={22}/>
                ) : (
                  <Icon
                    name="close"
                    className="cursor-pointer absolute right-2"
                    onClick={() => {
                      setSearchValue('');
                      setShowSearchIcon(true);
                      setMenuData(props.categories);
                    }}
                  />
                )}
              </div>
              <div className="mt-1 ml-8">{searchValue && menuData.length === 0 && MULTIFORM_ANALYTICS.noResultFound}</div>
            </div>
          )}
          {props.uiLabel === 'Labels' && (
            props?.categories?.length > UI_VALIDATIONS.FILTER_OPTION_SEARCHBAR_LIMIT && (
            <div className="search-section ml-2">
              <div className="flex search-btnBlock items-center relative">
                <input
                  placeholder={MULTIFORM_ANALYTICS.searchLabels}
                  value={searchValue}
                  onChange={(e) => {
                    const value = e.target.value;
                    setSearchValue(value);
                    handleSearch(props.categories, value);
                  }}
                  onKeyUp={() => setShowSearchIcon(!searchValue)}
                  className=""
                />
                {showSearchIcon ? (
                  <Icon name="search_icon" className="absolute right-2" height={22} width={22}/>
                ) : (
                  <Icon
                    name="close"
                    className="cursor-pointer absolute right-2"
                    onClick={() => {
                      setSearchValue('');
                      setShowSearchIcon(true);
                      setMenuData(props.categories);
                    }}
                  />
                )}
              </div>
              <div className="mt-1 ml-8">{searchValue && menuData.length === 0 && MULTIFORM_ANALYTICS.noResultFound}</div>
            </div>
            )
          )}
          {menuData.map((category, index) => (
            <FilterCategory
              key={category.id || index}
              category={props.type}
              {...category}
              checkedExecutionIds={props.checkedExecutionIds}
              checkedLabelsIds={props.checkedLabelsIds}
              handleFilterValues={props.handleFilterValues}
              handleAll={props.handleAll}
              suiteArray={props.suiteArray}
            />
          ))}
        </div>
      </>
    ) : null
  );
};

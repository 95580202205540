import React, { useEffect, useRef, useState, useContext } from 'react';
import { Field, useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';
import { FormControl, Select, TextareaAutosize, TextField, Tooltip, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { MenuItem, Grid } from '@mui/material';
import DatePickerView from 'react-datepicker';
import moment from 'moment';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ColumnResizer from 'react-table-column-resizer';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MultiAutocomplete from '../../modules/module/modals/labels-multiselect-dropdown';
import { format } from 'date-fns';

import {
  getScriptReq,
  getAllFiles,
  updateScriptReq,
  getAllTestCaseTemplate,
  downloadFileFromCloud,
} from '@src/api/api_services';
import './manual-test-case.scss';
import { WARNING_POPUP_CONSTANTS } from '@src/common/ui-constants';
import { CommonLoader } from '@src/pages/common/common-loader';
import { MdEdit } from 'react-icons/md';
import { ReactComponent as LinkIcon } from '@assets/svg-imports/ionic-ios-link.svg';
import { ReactComponent as LinkIconBlue } from '@assets/svg-imports/ionic-ios-link-blue.svg';
import { saveFileFromBlob } from '@src/pages/test-development/shared/common-methods';
import { checkValidationForAlphanumericSpecialCharacters } from '@src/pages/analytics/common/util';
import RichTextEditor from '@src/pages/common/rich-text-editor';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { _formatDate, DATE_FORMATS, splitedScriptNameAndID } from '@src/util/common_utils';

export default function TestCaseManagement(props) {
  const [testCaseTemplateFields, setTestCaseTemplateFields] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [validateSchema, setValidateSchema] = useState({});
  const [fileNameOptions, setFileNameOptions] = useState([]);
  const [templateTableData, setTemplateTableData] = useState({});
  const [testCaseData, setTestCaseData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [dataTouched, setDataTouched] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [labelSelectedOptions, setLabelSelectedOptions] = useState([]);
  const [labelResponseObject, setLabelResponseObject] = useState([]);
  let [selectedLabelData, setSelectedLabelData] = useState([]);
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const { HEADER_TITLE, SUB_HEADER_TITLE, BODY_TEXT, SUB_BODY_TEXT } = WARNING_POPUP_CONSTANTS;
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  const manualScriptId = new URLSearchParams(search).get('manualScriptId');
  const project = JSON.parse(localStorage.getItem('selected-project'));
  const location = useLocation();
  const history = useHistory();
  const projectLabelsObject = [];
  props?.setManualScriptId(manualScriptId);

  labelSelectedOptions?.map((labelValue) => {
    labelResponseObject?.filter((objectValue) => {
      if (objectValue?.name === labelValue) {
        projectLabelsObject.push(objectValue);
      }
    });
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validateSchema,
    onSubmit: (values) => {
      handleOnSubmit(values);
    },
  });
  let tempInitialValues = {};
  let tablepayloadData = {};
  const labelDataPassToParent = (labelData) => {
    setLabelSelectedOptions(labelData);
  };

  const isEditable = React.useMemo(() => window.permission.isEditAccess('testDevelopment'), []);

  function createYupSchema(schema, config) {
    const { id, validationType, validations = [] } = config;
    if (!yup[validationType]) {
      return schema;
    }
    let validator = yup[validationType]();
    validations.forEach((validation) => {
      const { params, type } = validation;
      if (!validator[type]) {
        return;
      }
      validator = validator[type](...params);
    });
    schema[id] = validator;
    return schema;
  }
  const compareTestStepsObjects = (templateData, scriptData) => {
    let a = templateData?.headers;
    let b = scriptData?.headers;
    let testStepsObject = {};
    let matchedObject = {};
    for (let i = 0; i < a.length; i++) {
      let match = false;
      let matchedText = '';
      for (let j = 0; j < b.length; j++) {
        if (a[i]?.toLowerCase() === b[j]?.toLowerCase()) {
          match = true;
          matchedText = b[j];
          break;
        }
      }
      if (!match) {
        matchedObject[a[i]] = a[i];
      } else {
        matchedObject[matchedText] = matchedText;
      }
    }

    for (let i = 0; i < scriptData?.rows?.length; i++) {
      const keys = Object?.keys(matchedObject);
      const rowKeys = Object?.keys(scriptData?.rows[i]);
      for (let j = 0; j < keys?.length; j++) {
        if (!rowKeys?.includes(keys[j])) {
          scriptData.rows[i][keys[j]] = '';
        }
      }
    }
    testStepsObject.headers = Object?.keys(matchedObject);
    testStepsObject.rows = scriptData?.rows;
    return testStepsObject;
  };
  const compareTestCaseDetailsObjects = (a, b) => {
    let testCaseDetailsArray = [];
    for (let i = 0; i < a.length; i++) {
      let match = false;
      let matchedObject = {};
      for (let j = 0; j < b.length; j++) {
        if (
          a[i]?.label?.toLowerCase() === b[j]?.label?.toLowerCase() &&
          a[i]?.type?.toLowerCase() === b[j]?.type?.toLowerCase()
        ) {
          match = true;
          matchedObject = b[j];
          break;
        }
      }
      if (!match) {
        testCaseDetailsArray.push(a[i]);
      } else {
        a[i].value = matchedObject?.value;
        testCaseDetailsArray.push(a[i]);
      }
    }
    return testCaseDetailsArray;
  };

  const sortBasedOnOrder = (testCaseDetails) => {
    return testCaseDetails?.sort((a, b) => {
      return a?.order - b?.order;
    });
  };

  async function getScript(moduleId, manualScriptId) {
    try {
      resetProgress();
      startOrResumeProgress({
        stopAt: 90,
      });
      setIsLoading(true);
      let getdata = await getScriptReq(moduleId, manualScriptId);
      stopProgress();
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 10,
      });
      let responseData = getdata?.data?.responseObject;
      const scriptNameWithPrefix = `${responseData.prefix} - ${responseData.name}`;
      setTestCaseData(responseData);
      let manualTestCaseObj = responseData?.manualTestCase;
      if (manualTestCaseObj) {
        if (Object.entries(manualTestCaseObj).length) {
          const response = await getAllTestCaseTemplate('config');
          if (response?.data?.responseCode === 200) {
            let testCaseDetails = compareTestCaseDetailsObjects(
              response?.data?.responseObject?.defaultTemplate?.testCaseDetails,
              manualTestCaseObj?.testCaseDetails
            );
            const testSteps = compareTestStepsObjects(
              response?.data?.responseObject?.defaultTemplate?.testSteps,
              manualTestCaseObj?.testSteps
            );
            testCaseDetails = sortBasedOnOrder(testCaseDetails);
            setTestCaseData({
              ...responseData,
              manualTestCase: {
                ...manualTestCaseObj,
                testCaseDetails: testCaseDetails,
                testSteps: testSteps,
              },
            });
            createValidationSchema(testCaseDetails, testSteps, scriptNameWithPrefix);
          } else {
            console.error('Get All TestCase Template error', response?.data?.message);
          }
        } else {
          getTemplateData(responseData, scriptNameWithPrefix);
        }
      } else {
        getTemplateData(responseData, scriptNameWithPrefix);
      }
      setIsLoading(false);
    } catch (err) {
      resetProgress();
      setIsLoading(false);
      console.error(err);
    }
  }

  useEffect(() => {
    let labelData = testCaseData?.manualTestCase?.testCaseDetails?.find((item) => item.label === 'Labels');
    setSelectedLabelData(labelData?.value);
  }, [testCaseData]);

  let tempValidateSchema = '';
  async function getTemplateData(scriptData, projectName) {
    try {
      setIsLoading(true);
      const responseData = await getAllTestCaseTemplate('config');
      setIsLoading(false);
      if (responseData?.data?.responseCode === 200) {
        let testCaseDetails = responseData?.data?.responseObject?.defaultTemplate?.testCaseDetails;
        testCaseDetails = sortBasedOnOrder(testCaseDetails);
        const testSteps = responseData?.data?.responseObject?.defaultTemplate?.testSteps;
        setTestCaseData({
          ...scriptData,
          manualTestCase: {
            ...scriptData.manualTestCase,
            testCaseDetails: testCaseDetails,
            testSteps: testSteps,
          },
        });
        createValidationSchema(testCaseDetails, testSteps, projectName);
      } else {
        console.error('Template error', responseData?.data?.message);
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  }
  const createValidationSchema = (testCaseDetailsResponse, testStepsResponse, projectName) => {
    let testCaseDetails = JSON.parse(JSON.stringify(testCaseDetailsResponse));
    let testSteps = JSON.parse(JSON.stringify(testStepsResponse));
    if (testSteps?.rows?.length === 0 || !testSteps.rows) {
      let rowData = Array.from({ length: 3 }).map(() => {
        let rowPrototype = {};
        testSteps?.headers.map((data) => {
          rowPrototype[data] = '';
        });
        return { ...rowPrototype };
      });
      testSteps.rows = [...rowData];
      setTemplateTableData(testSteps);
    } else {
      setTemplateTableData(testSteps);
    }

    let fieldTypeArray = [
      'textField',
      'textArea',
      'dropdown',
      'checkbox',
      'radioButton',
      'link',
      'attachment',
      'date',
      'textEditor',
    ];
    for (let i = 0; i < testCaseDetails.length; i++) {
      let element = testCaseDetails[i];
      let validations = [];
      if (fieldTypeArray.includes(element.type)) {
        if (element.type === 'checkbox') {
          element.validationType = 'array';
        } else if (element.type === 'date') {
          element.validationType = 'date';
        } else if (element.type === 'attachment') {
          element.validationType = 'object';
        } else {
          element.validationType = 'string';
        }
      }
      if (['textField', 'textArea'].includes(element.type)) {
        let validationObj = {
          type: 'matches',
          params: [checkValidationForAlphanumericSpecialCharacters, `Input should be alphanumeric`],
        };
        validations.push(
          validationObj,
          {
            type: 'trim',
            params: ['Space is not allowed at starting and at the end'],
          },
          {
            type: 'strict',
            params: [true],
          }
        );
      }
      if (element?.minLength) {
        let validationObj = {
          type: 'min',
          params: [element.minLength, `Input cannot be less than ${element.minLength} characters`],
        };
        validations.push(validationObj);
      }
      if (element?.maxLength) {
        let validationObj = {
          type: 'max',
          params: [element.maxLength, `Input cannot be more than ${element.maxLength} characters`],
        };
        validations.push(validationObj);
      }
      if (element.mandatory === 'yes') {
        let validationObj = {
          type: 'required',
          params: ['This field is required'],
        };
        validations.push(validationObj);

        if (element.type === 'checkbox') {
          let validationObj = {
            type: 'min',
            params: [1, 'This field is required'],
          };
          validations.push(validationObj);
        }
      }
      if (element.type === 'link') {
        let validationObj = {
          type: 'url',
          params: ['Enter valid url'],
        };
        validations.push(validationObj);
      }
      element.validations = validations;
      element.id = element.label;
    }
    setTestCaseTemplateFields(testCaseDetails);
    createFormikSchema(testCaseDetails, projectName);
  };
  function createFormikSchema(testCaseDetails, projectName) {
    testCaseDetails.forEach((item) => {
      if (item?.type === 'checkbox') {
        if (item?.value?.length > 0) {
          tempInitialValues[item.label] = item.value;
        } else {
          tempInitialValues[item.label] = [];
        }
      } else if (['textField', 'textArea'].includes(item?.type)) {
        if (item?.value?.length > 0) {
          tempInitialValues[item.label] = item.value[0] || '';
        } else {
          tempInitialValues[item.label] = item.defaultValue || '';
        }
      } else if (item?.type === 'date') {
        if (item?.value?.length > 0) {
          const myDate = moment(
            item.value[0],
            item.dateFormat && typeof item.dateFormat === 'string' ? _formatDate(item?.dateFormat)?.toUpperCase() : DATE_FORMATS.DATE_WITH_MONTH_NUMBER
          ).toDate();
          tempInitialValues[item.label] = myDate || '';
        } else {
          tempInitialValues[item.label] = '';
        }
      } else {
        if (item?.value?.length > 0) {
          tempInitialValues[item.label] = item.value[0] || '';
        } else {
          tempInitialValues[item.label] = '';
        }
      }
      if ((item?.label === 'Name' || item?.label === 'Test Case Name') && item.order === '1') {
        tempInitialValues[item.label] = projectName;
      }
    });
    setInitialValues(tempInitialValues);
    const yepSchema = testCaseDetails.reduce(createYupSchema, {});
    tempValidateSchema = yup.object().shape(yepSchema);
    setValidateSchema(tempValidateSchema);
  }

  useEffect(() => {
    getScript(moduleId, manualScriptId);
    getFileNames();
    if (location?.state) {
      const state = JSON.parse(location?.state);
      setEditMode(state?.enableSave || false);
    }
  }, []);

  const handleClickDatepickerIcon = (id) => {
    if (id) {
      document.getElementById(id).focus();
    }
  };

  const formatDate = (dateVal) => {
    return dateVal.replaceAll('-', '/').replaceAll('mm', 'MM');
  };

  const getFileNames = async () => {
    try {
      let opt = [];
      const response = await getAllFiles(project.id, 'testData');
      if (response.data && response.data.responseObject) {
        response.data.responseObject.forEach((file) => {
          opt.push({
            id: file.id,
            label: file.actualPath.substring(file.actualPath.indexOf('Root') + 5),
          });
        });
        setFileNameOptions(opt);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const handleOnSubmit = async (formValues) => {
    setDataTouched(false);
    setDisableSaveButton(true);
    const updatedData = JSON.parse(JSON.stringify(testCaseData.manualTestCase.testCaseDetails));
    const labelsData = JSON.parse(JSON.stringify(testCaseData));
    if (labelsData.projectLabels) {
      labelsData.projectLabels = projectLabelsObject;
      testCaseData.projectLabels = labelsData.projectLabels;
    }
    updatedData.forEach((data) => {
      if (data) {
        if (formValues.hasOwnProperty(data.label)) {
          if (formValues[data.label] || (data.label === 'Labels' && formValues[data.label] === '')) {
            if (data.type === 'date') {
              data.value = [
                moment(formValues[data.label]).format(
                  data.dateFormat && typeof data.dateFormat === 'string' ? _formatDate(data.dateFormat).toUpperCase() : 'DD-MM-YYYY'
                ),
              ];
            } else if (data.type === 'checkbox') {
              data.value = formValues[data.label];
            } else if (data.label === 'Test Case Name' && data.type === 'textField') {
              const [, testCaseName] = splitedScriptNameAndID(formValues[data.label]);
              data.value = [testCaseName];
            } else if (data.label === 'Labels' && formValues.hasOwnProperty(data.label)) {
              data.value = labelSelectedOptions;
            } else {
              data.value = [formValues[data.label]];
            }
          } else {
            data.value = [];
          }
        }
      }
    });
    const testCaseDataCopy = { ...testCaseData };
    if (testCaseDataCopy?.manualTestCase) {
      testCaseDataCopy.manualTestCase.testCaseDetails = updatedData;
    }
    if (testCaseDataCopy?.manualTestCase) {
      testCaseDataCopy.manualTestCase.testSteps = tablepayloadData;
    }
    try {
      const response = await updateScriptReq(moduleId, manualScriptId, 'UPDATE', testCaseDataCopy);
      if (response?.data?.responseCode === 200) {
        setDataTouched(false);
        setEditMode(false);
        if (history?.location?.state) {
          history.location.state = undefined;
          history.replace({ ...history.location });
        }
        getScript(moduleId, manualScriptId);
        props.MyAlert.success(`Changes are saved successfully`);
      } else if (response?.data?.responseCode === 302 && response?.data?.message.toLowerCase() === 'no changes made') {
        props.MyAlert.info(response?.data?.message);
        console.error(response);
      } else {
        props.MyAlert.warning(response?.data?.status);
        console.error(response);
      }
      setDisableSaveButton(false);
    } catch (err) {
      setDisableSaveButton(false);
      console.error('UPDATE_MANUAL_SCRIPT: ', err);
    }
    setTestCaseData({ ...testCaseDataCopy });
  };
  const handleDataChange = (e, props) => {
    setDataTouched(true);
    props.handleChange(e);
  };

  const downloadFile = (file) => {
    downloadFileFromCloud(file.id)
      .then((d) => {
        saveFileFromBlob(d.data, file.label);
      })
      .catch((err) => {
        console.error('donwload file from cloud', err);
      });
  };

  const nonEditableView = (data) => {
    let value = '';
    if ((data?.label === 'Name' || data?.label === 'Test Case Name') && data?.order === '1') {
      value = `${testCaseData?.prefix} - ${testCaseData.name}`;
    } else if (data?.type === 'attachment') {
      value = data.value && Array.isArray(data.value) ? data.value[0] : data.value;
    } else {
      value = data.value && Array.isArray(data.value) ? data.value.join(',') : data.value;
    }
    const defaultValue = data?.defaultValue || '';
    if (data.type === 'link' && value) {
      return (
        <>
          <a href={value} target="_blank" className="label-overflow tab-style-common">
            {value}
          </a>
        </>
      );
    }
    if (data.type === 'attachment' && value) {
      return (
        <>
          <label className="label-overflow testCase fontPoppinsRegularMd">{value?.label || '--'}</label>
          <img
            onClick={() => {
              downloadFile(value);
            }}
            className="more-button relative inline-block text-left ml-1 cursor-pointer"
            src="/assets/images/file_download.svg"
            alt=""
            height="25px"
            width="25px"
          />
        </>
      );
    }
    if (data.type === 'textEditor') {
      return (
        <div className="mt-2">
          <RichTextEditor convertedContent={value || ''} readOnly={true} toolbarHidden={true} />
        </div>
      );
    }
    return (
      <>
        <Tooltip title={value ? value : defaultValue} placement="top-start">
          <label
            id="TestCaseName"
            className="wrapContent label-overflow cursor-pointer testCase fontPoppinsRegularMd"
            onClick={() => {
              if (value ? value : defaultValue) {
                const fieldId = document.getElementById('TestCaseName');
                if (fieldId?.classList?.contains('wrapContent')) {
                  fieldId?.classList?.remove('wrapContent');
                  fieldId?.classList?.add('contentDataStyle');
                } else {
                  fieldId?.classList?.remove('contentDataStyle');
                  fieldId?.classList?.add('wrapContent');
                }
              }
            }}
          >
            {value ? value : defaultValue ? defaultValue : '--'}
          </label>
        </Tooltip>
      </>
    );
  };
  const navigateToUrl = (url, error) => {
    if (!error && url) {
      window.open(url, '_blank');
    }
  };
  const testCaseComponents = (data, props, error) => {
    if (data && props) {
      const value = props?.values[data.id];
      const { id, type, enable, placeholder, maxLength } = data;
      switch (type) {
        case 'textField':
          return enable === 'yes' ? (
            <div className="mt-3 user-input-container">
              <TextField
                title={value}
                error={formik.errors[id] && formik.touched[id] ? true : false}
                className="w-64 "
                InputLabelProps={{
                  shrink: true,
                }}
                type="text"
                autoComplete="off"
                name={id}
                id={id}
                placeholder={placeholder}
                onChange={(e) => handleDataChange(e, props)}
                helperText={formik.errors[id] && formik.touched[id] ? error : ''}
                value={value}
                disabled={!editMode}
                onBlur={(e) => {
                  formik.handleBlur(e);
                }}
              />
            </div>
          ) : (
            <label className="label-overflow testCase fontPoppinsRegularMd">{value}</label>
          );

        case 'textArea':
          return (
            <>
              {enable === 'yes' ? (
                <div className="-mt-1">
                  <div>
                    <TextareaAutosize
                      title={value}
                      name={id}
                      id={id}
                      onChange={(e) => handleDataChange(e, props)}
                      maxLength={maxLength}
                      value={value}
                      minRows={1}
                      maxRows={1}
                      placeholder={placeholder}
                      className="block w-64 input-style-textarea  popup-input-bg pl-px  mt-1  border-0 border-b input-field-color descriptionStyle"
                      disabled={!editMode}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                      }}
                    />
                    {maxLength ? (
                      <div className="flex justify-between">
                        <div>
                          {formik.errors[id] && formik.touched[id] && (
                            <p className="MuiFormHelperText-root Mui-error mt-1">{error}</p>
                          )}
                        </div>
                        <div className="mt-1 text-sm text-gray-500 text-right  lg:mr-32 md:mr-10 sm:mr-16 ">
                          {value ? value.length : 0}/{maxLength}
                        </div>
                      </div>
                    ) : (
                      <div>
                        {formik.errors[id] && formik.touched[id] && (
                          <p className="MuiFormHelperText-root Mui-error mt-1">{error}</p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <label className="label-overflow testCase fontPoppinsRegularMd">{value}</label>
              )}
            </>
          );

        case 'textEditor':
          return (
            <>
              <div className="mt-2">
                <FormikProvider value={formik}>
                  <Field name={id}>
                    {({ form, field }) => {
                      const { setFieldValue } = form;
                      const { value } = field;
                      return (
                        <div>
                          <RichTextEditor
                            readOnly={enable === 'no' ? true : false}
                            toolbarHidden={enable === 'no' ? true : false}
                            convertedContent={value || ''}
                            setConvertedContent={(content) => {
                              if (content) {
                                const validateContent = JSON.parse(content);
                                if (validateContent?.blocks?.length === 1 && validateContent?.blocks[0]?.text === '') {
                                  setFieldValue(id, '');
                                } else {
                                  setFieldValue(id, content);
                                }
                              } else {
                                setFieldValue(id, '');
                              }
                            }}
                          />
                          <div>
                            {formik.errors[id] && formik.touched[id] && (
                              <p className="MuiFormHelperText-root Mui-error mt-1">{error}</p>
                            )}
                          </div>
                        </div>
                      );
                    }}
                  </Field>
                </FormikProvider>
              </div>
            </>
          );

        case 'radioButton':
          return (
            <>
              <div className="w-100 mt-2">
                <div className="grid grid-cols-2 gap-2">
                  {data.options.map((option, index) => (
                    <div key={index} className="flex" title={option}>
                      <input
                        type="radio"
                        disabled={!editMode}
                        onChange={(e) => handleDataChange(e, props)}
                        className="mr-2"
                        name={id}
                        id={id}
                        value={option}
                        checked={option === value}
                      />
                      <label className="text-xs overflow-hidden overflow-ellipsis whitespace-nowrap fontPoppinsRegularMd">
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
                {formik.errors[id] && formik.touched[id] && (
                  <p className="MuiFormHelperText-root Mui-error mt-1.5">{error}</p>
                )}
              </div>
            </>
          );

        case 'link':
          return (
            <>
              <div className="flex mt-3 user-input-container">
                <Tooltip title="Click here to navigate" placement="top">
                  <div
                    onClick={() => {
                      navigateToUrl(value, formik.errors[id]);
                    }}
                  >
                    {value && !formik.errors[id] ? (
                      <LinkIconBlue className="h-4 w-4 mt-1 mr-2 origin-center cursor-pointer" />
                    ) : (
                      <LinkIcon className="h-4 w-4 mt-1 mr-2 origin-center cursor-pointer" />
                    )}
                  </div>
                </Tooltip>
                <TextField
                  title={value}
                  error={formik.errors[id] && formik.touched[id] ? true : false}
                  className="w-56 link-placeholder-text"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="text"
                  autoComplete="off"
                  name={id}
                  id={id}
                  placeholder="Attached Link will be shown here if any URL is added"
                  onChange={(e) => handleDataChange(e, props)}
                  helperText={formik.errors[id] && formik.touched[id] ? error : ''}
                  value={value}
                  disabled={!editMode}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                />
              </div>
            </>
          );

        case 'dropdown':
          return (
            <>
              <div className="mt-2">
                <FormControl variant="standard">
                  <Select
                    onBlur={(e) => {
                      formik.handleBlur(e);
                    }}
                    disabled={!editMode}
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                    }}
                    id={id}
                    name={id}
                    onChange={(e) => handleDataChange(e, props)}
                    value={value}
                    defaultValue=""
                    className="w-64 select-selected-value"
                  >
                    {data.options.map((option, index) => (
                      <MenuItem
                        title={option}
                        key={index}
                        className="hover:text-blue-700 cursor-pointer hover:bg-blue-100 text-xs w-64 overflow-hidden overflow-ellipsis whitespace-nowrap manual-test-case-select"
                        value={option}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.errors[id] && formik.touched[id] && (
                    <p className="MuiFormHelperText-root Mui-error">{error}</p>
                  )}
                </FormControl>
              </div>
            </>
          );

        case 'date':
          return (
            <>
              <div className="flex">
                <div className=" h-8 border-2 border-gray-300 w-60">
                  <FormikProvider value={formik}>
                    <Field name={id}>
                      {({ form, field }) => {
                        const { setFieldValue } = form;
                        const { value } = field;
                        return (
                          <DatePickerView
                            className="h-7"
                            id={id}
                            {...field}
                            disabled={!editMode}
                            selected={value}
                            onChange={(val) => {
                              setDataTouched(true);
                              setFieldValue(id, val);
                            }}
                            onKeyDown={(e) => e.preventDefault()}
                            dateFormat={
                              data.dateFormat && typeof data.dateFormat === 'string'
                                ? _formatDate(data.dateFormat)
                                : DATE_FORMATS.DATE_WITH_MONTH_NAME
                            }
                            maxDate={new Date()}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                            }}
                          />
                        );
                      }}
                    </Field>
                  </FormikProvider>
                </div>
                <div className=" bg-gray-200" onClick={() => editMode && handleClickDatepickerIcon(id)}>
                  <CalendarTodayOutlinedIcon className="mt-1" />
                </div>
              </div>
              <div className="text-xs opacity-50">
                [{data.dateFormat && Array.isArray(data.dateFormat) ? data.dateFormat.join(',') : data.dateFormat}]
              </div>
              {formik.errors[id] && formik.touched[id] && <p className="MuiFormHelperText-root Mui-error">{error}</p>}
            </>
          );

        case 'checkbox':
          return (
            <>
              <div className="w-100 mt-2">
                <div className="grid grid-cols-2 gap-2">
                  {data.options.map((option, index) => (
                    <div key={index} className="flex" title={option}>
                      <input
                        disabled={!editMode}
                        type="checkbox"
                        className="mr-2"
                        name={id}
                        id={id}
                        value={option}
                        onChange={(e) => {
                          handleDataChange(e, props);
                        }}
                        checked={value && value.length > 0 && value.includes(option)}
                      />
                      <label className="text-xs overflow-hidden overflow-ellipsis whitespace-nowrap fontPoppinsRegularMd">
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
                {formik.errors[id] && formik.touched[id] && (
                  <p className="MuiFormHelperText-root Mui-error mt-1.5">{error}</p>
                )}
              </div>
            </>
          );

        case 'attachment':
          return (
            <>
              <div className="mt-1">
                <FormikProvider value={formik}>
                  <Field name={id}>
                    {({ form, field }) => {
                      const { setFieldValue } = form;
                      const { value } = field;
                      return (
                        <>
                          <div className="flex flex-row ">
                            <div>
                              <Autocomplete
                                disabled={!editMode}
                                className={
                                  value
                                    ? 'mt-1 fontPoppinsRegularMd w-56 auto-complete'
                                    : 'mt-1 fontPoppinsRegularMd w-64 auto-complete'
                                }
                                value={value || null}
                                options={fileNameOptions}
                                renderOption={(option) => (
                                  <Typography title={option.label} className="select-selected-value text-gray-500">
                                    {option.label}
                                  </Typography>
                                )}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, val) => {
                                  setDataTouched(true);
                                  if (val !== null) {
                                    fileNameOptions.forEach((file) => {
                                      if (val.id === file.id) {
                                        setFieldValue(id, val !== null ? file : '');
                                      }
                                    });
                                  } else {
                                    setFieldValue(id, '');
                                  }
                                }}
                                name={id}
                                id={id}
                                renderInput={(params) => (
                                  <TextField
                                    onBlur={(e) => {
                                      formik.handleBlur(e);
                                    }}
                                    error={formik.errors[id] && formik.touched[id] ? true : false}
                                    {...params}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    className="test-case-font"
                                    placeholder="Search and select file name"
                                    variant="standard"
                                    helperText={formik.errors[id] && formik.touched[id] ? error : ''}
                                    name={id}
                                  />
                                )}
                              />
                            </div>
                            {value && (
                              <div className="ml-2 mt-2 w-20">
                                <img
                                  onClick={() => {
                                    downloadFile(value);
                                  }}
                                  className="more-button relative inline-block text-left ml-1 cursor-pointer"
                                  src="/assets/images/file_download.svg"
                                  alt=""
                                  height="25px"
                                  width="25px"
                                />
                              </div>
                            )}
                          </div>
                        </>
                      );
                    }}
                  </Field>
                </FormikProvider>
              </div>
            </>
          );

        default:
          return (
            <>
              <label className="label-overflow testCase fontPoppinsRegularMd"></label>
            </>
          );
      }
    }
  };
  const testCaseDetailElements = (data, props, error, index) => {
    return (
      <>
        <div className="flex flex-col justify-center">
          <div className="flex">
            <div className="flex ">
              <h6 className="common-label-text labelWidth">
                {data ? (
                  <Tooltip title={data?.label} placement="top">
                    {editMode ? (
                      <div className="overflow-hidden overflow-ellipsis whitespace-nowrap fontPoppinsMediumMd">
                        {data?.mandatory === 'yes' ? <span className="text-red-400 mr-1">&#42;</span> : null}
                        <span className="fontPoppinsMediumMd">{data?.label}</span>
                      </div>
                    ) : (
                      <div className="overflow-hidden overflow-ellipsis whitespace-nowrap fontPoppinsMediumMd">
                        <span className="fontPoppinsMediumMd">{data?.label}</span>
                      </div>
                    )}
                  </Tooltip>
                ) : null}
              </h6>
            </div>
          </div>
          <div className="contentDataStyle">
            {editMode ? testCaseComponents(data, props, error, index) : nonEditableView(data)}
          </div>
        </div>
      </>
    );
  };
  const templateTableDataSetter = (data) => {
    setTemplateTableData({ ...data });
  };
  const TestStepsTable = (props) => {
    let [tableRowData, setTableRowData] = useState(props.tableData.rows);
    tablepayloadData = props.tableData;

    const debounce = (func, delay) => {
      let timeoutId;
      return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          func(...args);
        }, delay);
      };
    };

    const tsRowChangeHandler = debounce((e, index, cellName, currentRowData, colIndex) => {
      let rowData = [...tableRowData];
      let editedValue;
      if (Array.isArray(currentRowData[cellName]) || e.target.innerText.includes('\n')) {
        editedValue = e.target.innerText.replaceAll('\n\n', '\n').split('\n');
        if (!editedValue[editedValue.length - 1]) {
          editedValue.pop();
        }
        rowData[index][cellName] = editedValue;
      } else {
        rowData[index][cellName] = e.target.innerText;
      }
      tablepayloadData = {
        headers: props.tableData?.headers,
        rows: rowData,
      };

      const tdRefId = document.getElementById('tdRef' + index + colIndex);
      tdRefId.focus();
    }, 200);

    const addRowDDTS = (index) => {
      setDataTouched(true);
      let rowData = tableRowData;
      let rowPrototype = {};
      props.tableData?.headers.map((data) => {
        rowPrototype[data] = '';
      });
      rowData.splice(index, 0, rowPrototype);
      props.templateTableDataSetter({
        headers: props.tableData?.headers,
        rows: rowData,
      });
    };
    const deleteRowDDTS = (index) => {
      setDataTouched(true);
      let rowData = tableRowData;
      if (tableRowData.length > 1) {
        rowData.splice(index, 1);
        props.templateTableDataSetter({
          headers: props.tableData?.headers,
          rows: rowData,
        });
      }
    };
    const AddRow = (props) => {
      const [showOptions, setShowOptions] = useState(false);
      const [rowId, setRowId] = React.useState();
      let menuRef = useRef();
      useEffect(() => {
        let handeler = (event) => {
          if (menuRef.current && !menuRef?.current?.contains(event.target)) {
            setShowOptions(false);
          }
        };
        document.addEventListener('mousedown', handeler);
        return () => {
          document.removeEventListener('mouseleave', handeler);
        };
      }, []);
      const handelDropdown = () => {
        setShowOptions(!showOptions);
      };
      return (
        <>
          {showOptions && rowId === props.index && (
            <>
              <div
                className="shadow-none origin-top-right absolute mt-2 w-40 rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabIndex="-1"
                ref={menuRef}
              >
                <div className="create_Issue_Modal -my-20" contentEditable={false}>
                  <div
                    className="cursor-pointer hover:bg-gray-200 p-1 text-sm text-left"
                    onClick={() => addRowDDTS(props.index)}
                  >
                    <span className="ml-2">Insert row above</span>
                  </div>
                  <div
                    className="cursor-pointer hover:bg-gray-200 p-1 text-sm text-left"
                    onClick={() => addRowDDTS(props.index + 1)}
                  >
                    <span className="ml-2">Insert row below</span>
                  </div>
                  {props.data.length === 1 ? null : (
                    <div
                      className="cursor-pointer hover:bg-gray-200 p-1 text-sm text-left"
                      onClick={() => deleteRowDDTS(props.index)}
                    >
                      <span className="ml-2">delete row</span>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          <button
            disabled={!editMode}
            contentEditable={false}
            onClick={() => {
              handelDropdown();
              setRowId(props.index);
            }}
          >
            <MoreVertIcon />
          </button>
        </>
      );
    };
    const handlePaste = (e) => {
      e.preventDefault();
      if (editMode) {
        const currentlyFocusedCell = document.activeElement;
        const pastedText = e.clipboardData.getData('text/plain');
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        range.deleteContents();
        const lines = pastedText.split(/\r?\n/);
        const fragment = document.createDocumentFragment();
        let lastInsertedNode = null;
        lines.forEach((line, index) => {
          if (index > 0) {
            fragment.appendChild(document.createElement('br'));
          }
          const textNode = document.createTextNode(line);
          fragment.appendChild(textNode);
          lastInsertedNode = textNode;
        });
        range.insertNode(fragment);
        const newRange = document.createRange();
        newRange.setStartAfter(lastInsertedNode);
        newRange.collapse(true);
        selection.removeAllRanges();
        selection.addRange(newRange);

        const inputEvent = new Event('input', { bubbles: true });
        currentlyFocusedCell.dispatchEvent(inputEvent);
        currentlyFocusedCell.focus();
      }
    };

    return (
      <>
        <div>
          <div className="shadow">
            <div className="w-auto overflow-scroll relative" id="journal-scroll">
              <table className="testStepTable mt-8">
                <thead className="test-case-table-row" key={'tcmthead'}>
                  <tr key={'tableHeader'}>
                    <th className="p-2 testStepTable" key={'emptycelladdcolumn'}></th>
                    {props.tableData?.headers?.map((data, index) => {
                      return (
                        !['actual result', 'status', 'Comment', 'Attachment', 'comment', 'attachment'].includes(
                          data?.toLowerCase()
                        ) && (
                          <>
                            <th className="p-2 testStepTable common-label-text fontPoppinsMediumMd" key={data + index}>
                              {data}{' '}
                            </th>
                            <ColumnResizer minWidth={100} className="columnResizer" />
                          </>
                        )
                      );
                    })}
                  </tr>
                </thead>
                <tbody key={'tcmtbody'}>
                  {tableRowData?.map((rowData, index) => (
                    <tr
                      className={`h-6 testStepTable ${
                        (index + 1) % 2 === 0 ? 'test-case-table-even-row' : 'test-case-table-odd-row'
                      }`}
                      key={index}
                    >
                      <td className="testStepTable fontPoppinsRegularSm w-5" key={'add' + index}>
                        <AddRow index={index} data={[...tableRowData]} />
                      </td>
                      {props.tableData?.headers?.map((cellname, colIndex) => {
                        return (
                          !['actual result', 'status', 'Comment', 'Attachment', 'comment', 'attachment'].includes(
                            cellname?.toLowerCase()
                          ) && (
                            <>
                              <td
                                className="testStepTable break-words fontPoppinsRegularSm text-left pl-2 table-cell-text focus:border-opacity-0 focus:outline-none"
                                key={index + colIndex}
                                id={'tdRef' + index + colIndex}
                                onInput={(e) => tsRowChangeHandler(e, index, cellname, rowData, colIndex)}
                                contentEditable={editMode}
                                suppressContentEditableWarning={true}
                                onPaste={handlePaste}
                              >
                                {Array.isArray(rowData[cellname])
                                  ? rowData[cellname].map((data) => (
                                      <>
                                        {data}
                                        <br />
                                      </>
                                    ))
                                  : rowData[cellname]}{' '}
                              </td>
                              <ColumnResizer minWidth={100} className="columnResizer" />
                            </>
                          )
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  };
  if (isLoading) {
    return <CommonLoader />;
  }

  if (testCaseData && Object.keys(testCaseData).length > 0) {
    return (
      <div className="page-table-body w-full" id="journal-scroll">
        <>
          <div className="content_area_style" id="journal-scroll">
            <div className="pt-3 pb-3 border_style flex flex-wrap items-center border_bottom_style">
              <label className="project_label text-sm flex-auto">
                {testCaseData?.prefix} - {testCaseData.name}
              </label>
              {isEditable && (
                <div className="pr-4">
                  <div className="flex flex-row ml-32">
                    <div className="ml-auto pl-4">
                      <button
                        disabled={disableSaveButton}
                        type="button"
                        onClick={() => {
                          if (editMode) {
                            formik.handleSubmit();
                          } else {
                            setEditMode(true);
                            setDataTouched(true);
                          }
                        }}
                        className="ml-3 py-1.5 px-3.5 primary-btn"
                      >
                        {editMode ? (
                          'Save'
                        ) : (
                          <span>
                            {' '}
                            <MdEdit className="float-left mr-2 mt-1 individual-user-edit" /> Edit
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="tab-height content-height">
              <div className=" mt-3 mx-6 my-6 shadow-none">
                <Grid container spacing={{ xs: 2, md: 3, sm: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                  {testCaseTemplateFields &&
                    testCaseTemplateFields.length > 0 &&
                    testCaseTemplateFields.map((data, index) => {
                      if (data?.label === 'Test Case Name' || data?.label === 'Labels') return null;
                      let error = formik.errors.hasOwnProperty(data.id) && formik.errors[data.id];
                      return (
                        <Grid
                          item
                          xs={data?.type === 'textEditor' ? 12 : 4}
                          sm={data?.type === 'textEditor' ? 12 : 4}
                          md={data?.type === 'textEditor' ? 12 : 4}
                          key={data.id}
                        >
                          {data?.type === 'textEditor'
                            ? testCaseDetailElements(data, formik, error, index)
                            : testCaseDetailElements(data, formik, error, index)}
                        </Grid>
                      );
                    })}
                  {testCaseTemplateFields &&
                  editMode &&
                  testCaseTemplateFields.find((data) => data.label === 'Labels') ? (
                    <div className="w-full mt-5 ml-3">
                      <Grid item xs={3} sm={3} md={3}>
                        <MultiAutocomplete
                          selectedOptionsValue={selectedLabelData}
                          labelSelectedOptions={labelDataPassToParent}
                          labelResponse={setLabelResponseObject}
                        />
                      </Grid>
                    </div>
                  ) : (
                    <div className="w-full mt-5 ml-6">
                      <h6 className="common-label-text labelWidth">
                        <span className="fontPoppinsMediumMd">Labels</span>
                      </h6>
                      <Tooltip
                        placement="top-start"
                        title={selectedLabelData?.length > 0 ? selectedLabelData?.join(' , ') : '--'}
                      >
                        <label
                          id="TestCaseName"
                          className="wrapContent label-overflow cursor-pointer testCase fontPoppinsRegularMd"
                        >
                          {selectedLabelData?.length > 0
                            ? selectedLabelData?.length > 2
                              ? `${selectedLabelData.slice(0, 2).join(' , ')} ...`
                              : selectedLabelData.join(' , ')
                            : '--'}
                        </label>
                      </Tooltip>
                    </div>
                  )}
                </Grid>
              </div>
              <div>
                <TestStepsTable tableData={templateTableData} templateTableDataSetter={templateTableDataSetter} />
                <Prompt
                  when={dataTouched}
                  message={JSON.stringify({
                    headerTitle: HEADER_TITLE,
                    subHeaderTitle: SUB_HEADER_TITLE,
                    bodyText: BODY_TEXT,
                    subBodyText: SUB_BODY_TEXT,
                  })}
                />
              </div>
            </div>
          </div>
        </>
      </div>
    );
  } else {
    return <label>No Data found...</label>;
  }
}

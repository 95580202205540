import React, { Suspense } from 'react';
import Dashboard_page from '../user_config/dashboard_page';
import Reposoitory from '../repository/repository';
import { FaBeer } from 'react-icons/fa';
import TestDevelopmentRoot from '../test-development/test-development';
import TestDataLandingPage from '../test_data/testdata_landingpage';
import LicenseManagement from '@pages/licenses';
import SystemConfigRouter from '../configuration/system_config/system_config_router';
import ExecutionPage from '../execution/execution';
import PluginRouter from '../configuration/plugin/plugin_router';
import ConfigRouter from '../configuration/config_router';
import AnalyticsDashboard from '../analytics/analytics-dashboard';
import { CommonLoader } from './common-loader';
import { getLicenseFeatures, getCurrentProjectType } from '@src/util/common_utils';
import DefectLandingPage from '../defects/defect-landing-page';
import { getPrivilege } from '@src/util/common_utils';

export let getDefaultPath = (key) => {
  switch (key?.toLowerCase()) {
    case 'menu-repository':
      return '/repository/element/project_elements';
    case 'menu-testdevelopment':
      return '/testdevelopment/Script';
    case 'menu-analytics':
      return window.privilege.hasAutomation(getLicenseFeatures()) &&
        window.privilege.getAutomationPurchasedProjects(getPrivilege())?.includes(getCurrentProjectType())
        ? '/analytics-menu/automation-suite'
        : window.privilege.hasManualTestCase(getLicenseFeatures())
          ? '/analytics-menu/manual-suite'
          : window.privilege.hasDefects(getLicenseFeatures())
            ? '/analytics-menu/defects'
            : null;
    case 'menu-licenses':
      return '/licenses/fireflink-license';
    default:
      return null;
  }
};

export const SidebarData = [
  {
    title: 'Dashboard',
    image: '/assets/images/Dashboard.svg',
    selectedImage: '/assets/images/Dashboard_Blue.svg',
    text: 'Dashboard',
    selectedText: 'Dashboard',
    imageDark: '/assets/images/darkModeIcons/dashboardDark_logo.svg',
    path: '/dashboard',
    moduleName: 'dashboard',
    key: 'menu-dashbord',
    main: () => <AnalyticsDashboard />,
  },
  {
    title: 'Projects Menu',
    image: '/assets/images/Project Menu.svg',
    selectedImage: '/assets/img/project_menu.svg',
    selectedText: 'Projects',
    text: 'Projects',
    selectedRectangle: '/assets/img/rectangle.png',
    imageDark: '/assets/images/darkModeIcons/projectDark_logo.svg',
    path: '/projectmenu',
    key: 'menu-project',
    moduleName: 'projectMenu',
    icon: <FaBeer />,
    main: () => <Dashboard_page />,
  },
  {
    title: 'Project Menu',
    image: '/assets/images/Project Menu.svg',
    selectedImage: '/assets/img/project_menu.svg',
    selectedText: 'Project',
    text: 'Project',
    selectedRectangle: '/assets/img/rectangle.png',
    imageDark: '/assets/images/darkModeIcons/projectDark_logo.svg',
    path: '/projectmenu/project-config',
    key: 'menu-project-config',
    moduleName: 'projectMenu',
    icon: <FaBeer />,
    main: () => <Dashboard_page />,
  },
  {
    title: 'Repository',
    image: '/assets/images/Repository.svg',
    selectedImage: '/assets/img/repository.svg',
    selectedText: 'Repository',
    text: 'Repository',
    selectedRectangle: '/assets/img/rectangle.png',
    imageDark: '/assets/images/darkModeIcons/repositoryDark_logo.svg',
    path: '/repository',
    defaultPath: '/repository/element/project_elements',
    key: 'menu-repository',
    moduleName: 'repository',
    main: () => <Reposoitory />,
  },
  {
    title: 'Test Data',
    image: '/assets/images/Test Data.svg',
    selectedImage: '/assets/img/test-data.svg',
    selectedText: 'Test Data',
    text: 'Test Data',
    selectedRectangle: '/assets/img/rectangle.png',
    imageDark: '/assets/images/darkModeIcons/testdataDark_logo.svg',
    path: '/testData',
    key: 'menu-testdata',
    moduleName: 'testData',
    main: () => <TestDataLandingPage />,
  },
  {
    title: 'Test Development',
    image: '/assets/images/Test Development.svg',
    selectedImage: '/assets/img/test_development.svg',
    selectedText: 'Test Development',
    text: 'Test Dev',
    selectedRectangle: '/assets/img/rectangle.png',
    imageDark: '/assets/images/darkModeIcons/test_development_Dark.svg',
    path: '/testdevelopment',
    defaultPath: '/testdevelopment/Script',
    key: 'menu-testdevelopment',
    moduleName: 'testDevelopment',
    main: () => <TestDevelopmentRoot />,
  },
  {
    title: 'Execution',
    image: '/assets/images/Execution_gray.svg',
    selectedImage: '/assets/img/execution.svg',
    selectedText: 'Test Execution',
    text: 'Execution',
    selectedRectangle: '/assets/img/rectangle.png',
    imageDark: '/assets/images/darkModeIcons/executionDark_logo.svg',
    path: '/execution/suite',
    key: 'menu-execution',
    moduleName: 'execution',
    main: () => <ExecutionPage />,
  },
  {
    title: 'Defects',
    image: '/assets/images/Defect_dark.svg',
    selectedImage: '/assets/img/Defect.svg',
    selectedText: 'Defects',
    text: 'Defects',
    selectedRectangle: '/assets/img/rectangle.png',
    imageDark: '/assets/images/darkModeIcons/executionDark_logo.svg',
    path: '/defect',
    key: 'menu-defect',
    moduleName: 'defects',
    main: () => (
      <Suspense
        fallback={
          <div>
            <CommonLoader />
          </div>
        }
      >
        <DefectLandingPage />
      </Suspense>
    ),
  },
  // {
  //   title: "Web Service Workbench",
  //   image: "/assets/images/Webservice.svg",
  //   selectedImage: "/assets/images/http_logo_selected.svg",
  //   imageDark: "/assets/images/darkModeIcons/httpDark_logo.svg",
  //   path: "/webService",
  //   key: "menu-webservice",
  //   moduleName: "webServiceWorkbench",
  //   main: () => <h1>webservice workbench</h1>
  // },
  // {
  //   title: "Database Workbench",
  //   image: "/assets/images/Database.svg",
  //   selectedImage: "/assets/images/Database_Blue.svg",
  //   imageDark: "/assets/images/darkModeIcons/databaseDark_logo.svg",
  //   path: "/database",
  //   key: "menu-database",
  //   moduleName: "databaseWorkbench",
  //   main: () => <h1>database workbench</h1>
  // },
  {
    title: 'Configuration',
    image: '/assets/images/Configuration.svg',
    text: '/assets/img/config_text.svg',
    selectedImage: '/assets/images/Configuration_Blue.svg',
    selectedText: 'Configuration',
    selectedRectangle: '/assets/img/rectangle.png',
    imageDark: '/assets/images/darkModeIcons/configurationDark_logo.svg',
    moduleName: 'configuration',
    subRoute: [
      {
        title: 'Environment configuration',
        path: `/configuration/environmentconfiguration/systemconfiguration`,
        key: 'env-config',
        main: () => <SystemConfigRouter />,
      },
      {
        title: 'Element configuration',
        path: `/configuration/elementconfiguration`,
        key: 'element-config',
        main: () => <h1>Element config</h1>,
      },
      {
        title: 'Email configuration',
        path: `/configuration/emailconfiguration`,
        key: 'email-config',
        main: () => <h1>Email config</h1>,
      },
      {
        title: 'Plugin configuration',
        path: `/configuration/pluginconfiguration/plugin`,
        key: 'plugin-config',
        main: () => <PluginRouter />,
      },
      {
        title: 'Global Variable',
        path: `/configuration/globalvariable`,
        key: 'global-variable',
        main: () => <h1>Global Variable</h1>,
      },
      {
        title: 'Screenshot and Video',
        path: `/configuration/screenshotandvideo`,
        key: 'screenshot-and-video',
        main: () => <h1>Screenshot and Video</h1>,
      },
      {
        title: 'Wait configuration',
        path: `/configuration/waitconfiguration`,
        key: 'wait-configuration',
        main: () => <h1>wait configuration</h1>,
      },
      {
        title: 'Custom Fields',
        path: `/configuration/customfields`,
        key: 'custom-fields',
        main: () => <h1>Custom Fields</h1>,
      },
      {
        title: 'JDK Configuration',
        path: `/configuration/jdkconfiguration`,
        key: 'jdk-configuration',
        main: () => <h1>JDK Configuration</h1>,
      },
      {
        title: 'Change Password',
        path: `/configuration/changepassword`,
        key: 'change-password',
        main: () => <h1>change password</h1>,
      },
    ],
    key: 'menu-configuration',
    path: '/configuration',
    main: () => <ConfigRouter />,
  },
  {
    title: 'Licenses',
    image: '/assets/images/user-license-shield-20x20.svg',
    selectedImage: '/assets/images/user-license-shield-selected.svg',
    text: 'License',
    selectedText: 'Licenses',
    imageDark: '/assets/images/darkModeIcons/user-license-shield-dark-mode.svg',
    path: '/licenses',
    defaultPath: '/licenses/fireflink-license',
    key: 'menu-licenses',
    main: () => <LicenseManagement />,
  },
  // {
  //   title: "NLP Help",
  //   image: "/assets/images/Help.svg",
  //   selectedImage: "/assets/images/nlp_logo_selected.svg",
  //   imageDark: "/assets/images/darkModeIcons/nlpDark_logo.svg",
  //   path: "/nphelp",
  //   key: "menu-nlphelp",
  //   moduleName: "nlpHelp",
  //   main: () => <h1>nlp help</h1>
  // },
];

import React, { useContext, useEffect, useState } from 'react';
import Authorization from '../../webservice/restapi/request/Authorization/authorization';
import { RestContext } from '@pages/test-development/script/scripts/webservice/restapi/restapi';
import { updateParentAuthorization, getScriptReq, getSingleModuleTreeReq, getSingleModuleReq } from '@api/api_services';
import '@pages/test-development/test-development.scss';
import { ASSERT_CONSTANT } from '@src/pages/test-development/script/scripts/webservice/restapi/request/assert/jsx/constant';
import { isEmptyObject } from '@src/util/common_utils';

const WebserviceAuth = (props) => {
  const { MyAlert, label, getAuth, showWebserviceModal } = props || 'unknown';
  const context = useContext(RestContext);
  const search = window.location.search;
  const [scriptId, setScriptId] = useState(new URLSearchParams(search).get('scriptId'));
  if (scriptId !== new URLSearchParams(search).get('scriptId')) {
    setScriptId(new URLSearchParams(search).get('scriptId'));
  }
  const [moduleId, setModuleId] = useState(new URLSearchParams(search).get('moduleId'));
  if (moduleId !== new URLSearchParams(search).get('moduleId')) {
    setModuleId(new URLSearchParams(search).get('moduleId'));
  }
  const manualScriptId = new URLSearchParams(search).get('manualScriptId');
  const [isDisabled, setDisabled] = useState(true);
  const [webserviceAuthData, setWebserviceAuthData] = useState(null);
  const { errors, authType } = context.values;
  const authValue = getAuth();
  const {
    AUTH_TYPE,
    AUTHORIZATION_WARNING,
    WEBSERVICE_AUTH_SUCCESS,
    WEBSERVICE_AUTH_TITLE,
    UPDATE,
    MODULE,
    SCRIPT,
    NO_AUTH_TEXT,
    ROOT,
  } = ASSERT_CONSTANT;
  const [parentAuthData, setParentAuthData] = useState(null);
  const [moduleName, setModuleName] = useState('');
  const [dataFetched, setDataFetched] = useState(false);  
  useEffect(() => {
    getWebserviceAuth();
  }, [moduleId, scriptId]);

  useEffect(() => {
    if (typeof showWebserviceModal === 'function') {
      if (authType !== webserviceAuthData?.authType) {
        showWebserviceModal(true);
      } else {
        showWebserviceModal(false);
      }
    }
  }, [authType, authValue, dataFetched]);

  const handleDisableUpdateAuth = () => {
    let selectedAuthValue =
      webserviceAuthData?.auth && !webserviceAuthData?.inherited ? JSON.stringify(webserviceAuthData?.auth) : null;
    selectedAuthValue = selectedAuthValue || null;
    const userAuthValue = authValue ? JSON.stringify(authValue) : null;
    if (authType === webserviceAuthData?.authType && userAuthValue === selectedAuthValue) {
      setDisabled(true);
    } else {
      showWebserviceModal(true);
      setDisabled(false);
    }
  };

  useEffect(() => {
    handleDisableUpdateAuth();
  }, [authType, authValue, webserviceAuthData]);

  const getWebserviceAuth = async () => {
    try {
      let response = null;
      let authorizationInfo = null;
      let parentId = null;
      if (label === SCRIPT) {
        const id = scriptId ? scriptId : manualScriptId;
        response = (await getScriptReq(moduleId, id)).data.responseObject;
      } else {
        response = (await getSingleModuleTreeReq(moduleId, false)).data.responseObject?.moduleTree[0];
      }
      if (response?.authorizationInfo) {
        authorizationInfo = response.authorizationInfo;
        parentId = response.authorizationInfo.parentId ?? response.parentId;
      } else {
        parentId = response?.parentId;
      }

      setWebserviceAuthData(authorizationInfo);
      setDataFetched(true);
      if ([MODULE, SCRIPT, ROOT].includes(label) && parentId) {
        getSingleWebserviceAuthReq(parentId);
      }
    } catch (error) {
      console.error('SINGLE TREE MODULE REQUEST API FAILED!', error);
    }
  };

  const getSingleWebserviceAuthReq = async (parentId) => {
    try {
      let response = await getSingleModuleReq(parentId);
      response = response.data.responseObject;
      if (response?.authorizationInfo?.inherited) {
        getSingleWebserviceAuthReq(response?.authorizationInfo?.parentId);
      } else {
        setModuleName(response?.name);
      }
      setParentAuthData(response);
    } catch (error) {
      console.error('SINGLE MODULE REQUEST API FAILED!', error);
    }
  };

  const onHandleWebServiceAuth = async () => {
    try {
      const isAuthError = !isEmptyObject(errors || {}) && AUTH_TYPE.includes(authType);
      if (isAuthError) {
        MyAlert?.alertbanner(AUTHORIZATION_WARNING);
      } else {
        showWebserviceModal(false);
        let parentId = null;
        const inherited = authType === 'Inherit from Parent';
        let body = {
          authType: authType,
          auth: getAuth(),
          inherited: inherited,
          parentId: parentId,
        };
        let formData = new FormData();
        if (authType === 'Inherit from Parent') {
          if (!isEmptyObject(parentAuthData)) {
            if (parentAuthData?.authorizationInfo?.inherited) {
              body = parentAuthData?.authorizationInfo;
            } else {
              const inheritedAuth =
                parentAuthData?.authorizationInfo?.authType !== NO_AUTH_TEXT
                  ? parentAuthData?.authorizationInfo?.auth
                  : null;
              body = {
                ...parentAuthData?.authorizationInfo,
                auth: inheritedAuth,
                inherited: true,
                parentId: parentAuthData?.id,
              };
            }
          }
        }

        const metaData = {
          mode: label,
          moduleId,
          scriptId: scriptId || null,
          parentId:
            webserviceAuthData?.inherited && authType !== webserviceAuthData?.authType
              ? webserviceAuthData?.parentId
              : moduleId,
        };
        const headers = { type: 'formdata' };
        formData.append('body', JSON.stringify(body));
        formData.append('metaData', JSON.stringify(metaData));
        const response = await updateParentAuthorization(formData, headers);
        if (response.data.responseCode === 200) {
          setWebserviceAuthData(body);
          setDisabled(true);
          MyAlert.success(WEBSERVICE_AUTH_SUCCESS);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  return (
    <div>
      <div class="border_style flex flex-wrap items-center pt-2.5 pb-2.5">
        <span class="fontPoppinsSemiboldSm flex-auto pl-0 web-service-auth-header">{WEBSERVICE_AUTH_TITLE}</span>
        <div class="pr-4">
          <div class="flex flex-row ml-32">
            <div class="ml-auto pl-4">
              <button
                onClick={onHandleWebServiceAuth}
                className={`fontMontserratMediumXs ${isDisabled ? 'webservice-auth-btn-disable' : 'webservice-auth-btn'}`}
                disabled={isDisabled}
              >
                {UPDATE}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Authorization
        webserviceAuthData={webserviceAuthData}
        setDisabled={setDisabled}
        parentAuthData={parentAuthData}
        label={label}
        moduleName={moduleName}
        {...props}
      />
    </div>
  );
};

export default WebserviceAuth;

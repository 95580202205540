import ColumnChart from './ColumnChart';
import DashboardStyle from '../styles/dashboard.module.scss';
import { useContext } from 'react';
import { ClientsContext } from '../components/ClientsProvider';

const chartProperties = {
  text: 'Browser & Version',
  titleOffsetX: -13,
  strokeWidth: 9,
  yaxisTitle: 'No. of occupied systems',
  xaxisShow: false,
  xaxisLabelsShow: false,
  tooltipEnabled: true,
  showLabelCount: true,
  classNameDashboardStyle: true,
};

function ClientBrowserVersion() {
  const { browserVersionsData } = useContext(ClientsContext);
  const { labels, colors, series, versions } = browserVersionsData;

  function customTooltipFormatter({ seriesIndex }) {
    return `<div class="${DashboardStyle['column-tooltip']} ${
      DashboardStyle['flex-direction']
    } border-2 rounded-lg" style="border-color: ${colors?.[seriesIndex]};">
      <span> System : ${series[seriesIndex].data[0]} </span>
      <span> Versions : ${versions[seriesIndex]?.join(', ') || 'N/A'}</span>
      </div>`;
  }

  return (
    <ColumnChart
      chartProperties={chartProperties}
      colors={colors}
      labels={labels}
      series={series}
      customTooltipFormatter={customTooltipFormatter}
    />
  );
}

export default ClientBrowserVersion;

import { GITHUB_CONSTANTS } from '../plugin_constants';
import CommonDrawerJs from '../CommonIntegrationDrawer';
import { Tooltip } from '@mui/material';

function GitlabDetails(props) {
  const selectedInstance = props.data;
  return (
    <CommonDrawerJs
      isDrawerOpen={true}
      headerContent={`${GITHUB_CONSTANTS.INSTANCE_DETAILS} - ${selectedInstance?.instanceName}`}
      drawerWidth="549.95px"
      leftButtonText={GITHUB_CONSTANTS.CLOSE}
      isLeftButtonVisible={true}
      isRightButtonVisible={false}
      onDrawerOpen={props.onClose}
      onLeftButtonClick={props.onClose}
    >
      <div className="w-full h-full flex flex-col ">
        <div id="Journal-scroll" className="grid grid-cols-3 mt-4 pb-4 ml-6 overflow-y-auto max-h-72">
          <div className="overflow-hidden-common">
            <label
              htmlFor="userName"
              className="block label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {GITHUB_CONSTANTS.INSTANCE_NAME}
            </label>
            <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-2">
              {selectedInstance?.instanceName}
            </label>
          </div>
          <div className="overflow-hidden-common ml-10">
            <label
              htmlFor="userName"
              className="block label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {GITHUB_CONSTANTS.USERNAME}
            </label>
            <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-2">
              {selectedInstance?.createdByUname}
            </label>
          </div>
          <div className="ml-14">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {GITHUB_CONSTANTS.ORGANIZATION_NAME}
            </label>
            <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsMediumSm mt-1">
              <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
              <Tooltip title={selectedInstance?.organization} placement="bottom-end">
                {selectedInstance?.organization.length > 16 ? `${selectedInstance?.organization.substring(0, 16)}...` : selectedInstance?.organization}
              </Tooltip>
              </label>
            </label>
          </div>
          <div className="mt-8">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {GITHUB_CONSTANTS.REPOSITORY}
            </label>
            <label className="instance-details-style fontPoppinsRegularSm mt-2">
              {selectedInstance?.gitHubRepositoryName}
            </label>
          </div>
          <div className="mt-8 ml-8">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {GITHUB_CONSTANTS.FIRE_FLINK_PROJECT}
            </label>
            <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-2">
              {selectedInstance?.fireFlinkProjectName}
            </label>
          </div>
          <div className="mt-8 ml-11">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {GITHUB_CONSTANTS.SUITE_NAME}
            </label>
            <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-2">
              {selectedInstance?.suiteName}
            </label>
          </div>
          <div className="mt-8">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {GITHUB_CONSTANTS.CREATED_ON}
            </label>
            <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-2">
              {selectedInstance?.createdOn}
            </label>
          </div>
          <div className="mt-8">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {GITHUB_CONSTANTS.MODIFIED_ON}
            </label>
            <label className="instance-details-style fontPoppinsRegularSm mt-2">{selectedInstance?.modifiedOn}</label>
          </div>
        </div>
      </div>
    </CommonDrawerJs>
  );
}

export default GitlabDetails;

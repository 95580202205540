import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CommonDrawerJs from '@src/common/atoms/CommonDrawer';
import { DEFECT_CONSTANTS } from '@src/common/ui-constants';
import NavigateToCreateInstanceModal from '@pages/configuration/system_config/system_config v1.1/modals/navigate-to-instance-modal';
import CommonButton from '@src/common/button/Button';
import { UPDTAE_PLATFORM } from '@pages/configuration/DefectConfig/defect-constants';
import IconWithLabelledInput from '@src/common/atoms/icon-with-labelled-input/IconWithLabelledInput';
import { updateProjectDefectRequest } from '@api/api_services';
import SelectBox from '@src/common/atoms/SelectComponent';
import { TooltipPoppin } from '@src/pages/analytics/common/util';
import Checkbox from '@mui/material/Checkbox';
import { Label } from '@src/common/atoms/LabelComponent';
import { ReactComponent as InfoIcon } from '@assets/InfoIcon.svg';
import styles from '@pages/configuration/DefectConfig/defect-config.module.scss';

const DefectEditPage = (props) => {
  const { defectPlatform, id, name, type } = props?.projectDetails || {};
  const [defectPlatforms, setDefectPlatforms] = useState([]);
  const [navigateToInstance, setNavigateToInstance] = useState({
    openNavigationModal: false,
    data: null,
  });

  const [checkedForAutomation, setCheckedForAutomation] = useState(false);
  const [checkedForManual, setCheckedForManual] = useState(false);

  const handleChangeForAutomationLinkIssue = (event) => {
    setCheckedForAutomation(event.target.checked);
  };
  const handleChangeForManualLinkIssue = (event) => {
    setCheckedForManual(event.target.checked);
  };

  const updatePlatform = async (projectData) => {
    try {
      const response = await updateProjectDefectRequest(projectData, id);
      if (response?.data?.status === 'SUCCESS') {
        if (!props.titleText) {
          props?.MyAlert?.success(UPDTAE_PLATFORM.PLATFORM_UPDATE_SUCCESS);
          props.handleEditModal(false);
        }
        props.getAllProjects(projectData);
      } else {
        props?.MyAlert?.danger(response.data?.message);
      }
    } catch (error) {
      console.error('UPDATE_PLATFORM_REQ : ', error);
    }
  };

  const onSubmit = (values) => {
    if (values) {
      const { platform, linkAutomation, linkManual } = defectPlatform || {};
      if (
        platform !== values.defectPlatform ||
        (values.defectPlatform === UPDTAE_PLATFORM.JIRA &&
          (linkAutomation !== checkedForAutomation || linkManual !== checkedForManual))
      ) {
        const payload = {
          ...values,
          defectPlatform: {
            platform: values.defectPlatform,
          },
        };
        if (values.defectPlatform === UPDTAE_PLATFORM.JIRA) {
          payload.defectPlatform.linkAutomation = checkedForAutomation;
          payload.defectPlatform.linkManual = checkedForManual;
        }
        updatePlatform(payload);
      } else {
        if (!props.titleText) {
          props?.MyAlert?.info(UPDTAE_PLATFORM.NO_PLATFORM_CHANGE);
          props.handleEditModal(false);
          return;
        }
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      name: name || '',
      type: type || '',
      defectPlatform: defectPlatform?.platform || '',
    },
    validationSchema: Yup.object({
      defectPlatform: Yup.string().required(UPDTAE_PLATFORM.PLATFORM_REQUIRED_MSG),
    }),
    onSubmit,
  });

  useEffect(() => {
    setCheckedForAutomation(defectPlatform?.linkAutomation || false);
    setCheckedForManual(defectPlatform?.linkManual || false);
    if (props?.defectData?.length) {
      const tempDefectPlatforms = props?.defectData.map((platform, index) => ({
        key: index,
        label: platform?.defectPlatformName,
        value: platform?.defectPlatformName,
        isAvailed: platform?.defectAvailed,
      }));
      setDefectPlatforms([...tempDefectPlatforms]);
    }
  }, []);

  const getSelectedPlatform = (selectedValue) => {
    if (selectedValue) {
      return [
        {
          label: selectedValue,
          value: selectedValue,
        },
      ];
    } else {
      return [];
    }
  };
  const handleChange = ([value]) => {
    if (value) {
      const { label, isAvailed } = value || {};
      if (isAvailed) {
        formik.setFieldValue('defectPlatform', label, true);
      } else {
        handleNavigationModal(true, label);
      }
    } else {
      formik.setFieldValue('defectPlatform', '', true);
    }
  };

  const handleNavigationModal = (viewNavigationModal, instanceName) => {
    setNavigateToInstance({
      openNavigationModal: viewNavigationModal,
      data: instanceName,
    });
  };

  const renderFooterContent = () => {
    return (
      <div className="flex flex-row justify-end -mt-3">
        <div>
          <CommonButton
            btnType="secondary"
            label={DEFECT_CONSTANTS.CANCEL}
            onClick={() => props.handleEditModal(false)}
          />
        </div>
        <div className="ml-4">
          <CommonButton
            label={props.titleText ? DEFECT_CONSTANTS.SELECT : DEFECT_CONSTANTS.UPDATE}
            type="submit"
            form={UPDTAE_PLATFORM.FORM_ID}
          />
        </div>
      </div>
    );
  };
  return (
    <>
      {!navigateToInstance?.openNavigationModal && (
        <CommonDrawerJs
          isDrawerOpen={true}
          onDrawerClose={() => props.handleEditModal(false)}
          titleText={props.titleText || DEFECT_CONSTANTS.EDIT_PLATFORM}
          drawerWidth={props?.drawerWidth || UPDTAE_PLATFORM.DRAWER_WIDTH}
          footerContent={renderFooterContent()}
        >
          <form id={UPDTAE_PLATFORM.FORM_ID} onSubmit={formik.handleSubmit}>
            <div className="modal-body" id="journal-scroll">
              <div className="my-4 px-4">
                <div className="">
                  <IconWithLabelledInput
                    labelProps={{
                      label: DEFECT_CONSTANTS.PROJECT_NAME,
                      required: true,
                    }}
                    text={formik.values.name}
                  />
                </div>
                <div className="mt-4">
                  <IconWithLabelledInput
                    labelProps={{
                      label: DEFECT_CONSTANTS.PROJECT_TYPE,
                      required: true,
                    }}
                    text={formik.values.type}
                  />
                </div>
                <div className="mt-4">
                  <SelectBox
                    name="defectPlatform"
                    labelProps={{
                      label: DEFECT_CONSTANTS.PLATFORM,
                      required: true,
                    }}
                    options={defectPlatforms}
                    searchable={false}
                    backspaceDelete={false}
                    placeholder={DEFECT_CONSTANTS.DEFECT_PLATFORM_PLACEHOLDER}
                    handleOptionChange={handleChange}
                    selectedOption={getSelectedPlatform(formik.values.defectPlatform)}
                    error={formik.touched.defectPlatform && formik.errors.defectPlatform}
                    errMsg={formik.errors.defectPlatform}
                  />
                </div>
                {formik.values.defectPlatform === UPDTAE_PLATFORM.JIRA && (
                  <div className="mt-4">
                    <div className="flex items-center w-full">
                      <Label fontClass="fontPoppinsRegularSm" label={UPDTAE_PLATFORM.LINK_ISSUE} required={false} />
                      <TooltipPoppin
                        title="The information in the Summary will be synced to the Title in Jira"
                        className={styles['tooltip-style']}
                      >
                        <span className="ml-1">
                          <InfoIcon className={styles['info-icon-style']} />
                        </span>
                      </TooltipPoppin>
                    </div>
                    <div className={styles['custom-check-box-style']}>
                      <Checkbox
                        sx={{ pr: 0.5, pl: 0, pt: 0, pb: 0 }}
                        size="small"
                        checked={checkedForAutomation}
                        onChange={(e) => {
                          handleChangeForAutomationLinkIssue(e);
                        }}
                        name="automationLinkIssue"
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      <Label
                        className={styles['label-black']}
                        fontClass="fontPoppinsRegularSm"
                        label={UPDTAE_PLATFORM.AUTOMATION_SCRIPTS}
                      />
                      <Checkbox
                        sx={{ pr: 0.5, pl: 0, pt: 0, pb: 0 }}
                        size="small"
                        checked={checkedForManual}
                        onChange={(e) => {
                          handleChangeForManualLinkIssue(e);
                        }}
                        name="manualLinkIssue"
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      <Label
                        className={styles['label-black']}
                        fontClass="fontPoppinsRegularSm"
                        label={UPDTAE_PLATFORM.MANUAL_TEST_CASE}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </CommonDrawerJs>
      )}
      {navigateToInstance?.openNavigationModal && (
        <NavigateToCreateInstanceModal
          history={props.history}
          cleanUpFunction={props.cleanUpFunction}
          closeModal={handleNavigationModal}
          data={navigateToInstance.data}
        />
      )}
    </>
  );
};

export default DefectEditPage;

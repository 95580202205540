import React, { useState, Fragment } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { Menu, Transition } from '@headlessui/react';
import { useAlert } from '@pages/common/alert_service/useAlert';
import DefectTablePage from './defect-table-page';
import DefectEmail from './defect-email-configuration';

import DefectDetailsView from './defect-details-view';
import { saveFileFromBlob } from '../test-development/shared/common-methods';
import { getCurrentProjectName, getFormatedDate } from '@src/util/common_utils';
import { exportDefects } from '@src/api/api_services';

function DefectTab(props) {
  const [showEmail, setShowEmail] = useState(false);
  const [emailConfig, setEmailConfig] = useState('');
  const [exportDefect, setExportDefects] = useState('');
  const [noOfDefects, setNoOfDefects] = useState(0);
  const { isEditable, hasFullAccess } = React.useMemo(
    () => ({
      isEditable: window.permission?.isEditAccess('defects'),
      hasFullAccess: window.permission?.isFullAccess('defects'),
    }),
    []
  );
  const contextMenuData = [
    // {
    //   title: "Help",
    //   disabled: false
    // },
    ...(isEditable || hasFullAccess
      ? [
          {
            title: 'Edit Email Configuration',
            disabled: !emailConfig,
          },
        ]
      : []),

    {
      title: 'Export Defects',
      disabled: !exportDefect,
    },
  ];
  const { AlertContatiner, MyAlert } = useAlert();
  const [tabsList] = useState([
    {
      name: 'Defects',
      logo: '/assets/images/project_rec_logo.svg',
      selectedLogo: '/assets/images/project_rec_logo_blue.svg',
      pathUrl: `/defect`,
      selectedName: 'defect',
    },
  ]);
  const { history } = props;
  let activeTab = history.location.pathname.split('/')[1];
  let projectName = getCurrentProjectName();
  const currentDate = new Date();
  let formatedDate = getFormatedDate(currentDate);

  const totalNoOfDefects = (count) => {
    setNoOfDefects(count);
  };

  const exportDefectFunc = async () => {
    try {
      const response = await exportDefects();
      saveFileFromBlob(response.data, `${projectName}_Defects_${formatedDate}.xlsx`);
      MyAlert.success(`Exporting of ${noOfDefects} defects was successful`);
    } catch (error) {
      console.error(`Export Defect Error : `, error);
    }
  };

  const optionMethodsHandler = (option) => {
    if (option === 'Edit Email Configuration') {
      setShowEmail(true);
    } else if (option === 'Export Defects') {
      exportDefectFunc();
    }
  };

  const renderTabs = () => {
    return (
      <ul className="navBar text-blue-600 flex flex-row primary-tab">
        {tabsList.map((tab, index) => {
          return (
            <li key={index}>
              <Link className={activeTab === tab.selectedName ? 'selected_tab_style' : ''} to={tab.pathUrl}>
                {tab.name}
              </Link>
            </li>
          );
        })}
      </ul>
    );
  };
  const handelDisableEmailConfig = (value) => {
    setEmailConfig(value);
  };
  const handelDisableExportDefect = (value) => {
    setExportDefects(value);
  };
  return (
    <>
      <div className="alert-position-style">
        <AlertContatiner></AlertContatiner>
      </div>
      <div className="page-tab-with-actions">
        {renderTabs()}
        <div className="clearfix ml-auto">
          <div className="float-right ml-5  mr-1">
            <Menu as="div" className="relative inline-block text-left">
              {({ open }) => (
                <>
                  <div>
                    <Menu.Button className="rounded-full flex items-center text-gray-700">
                      <span className="sr-only">Open options</span>
                      <DotsVerticalIcon className="h-5 w-5 black" aria-hidden="true" />
                    </Menu.Button>
                  </div>

                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <div className="py-1">
                        {contextMenuData.map((data, index) => (
                          <Menu.Item key={data.title}>
                            {({ active }) => (
                              <label
                                className={`block px-4 py-2 fontPoppinsRegularMd
                                  ${active ? 'bg-gray-100 text-blue-700 ' : 'text-gray-700'}
                                  ${data.disabled ? 'opacity-30 cursor-none text-gray-700' : 'cursor-pointer'}
                                  `}
                                onClick={() => {
                                  if (!data.disabled) {
                                    optionMethodsHandler(data.title);
                                  }
                                }}
                              >
                                {data.title}
                              </label>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        </div>
      </div>
      <Switch>
        <Route path="/defect" exact={true} strict={true}>
          <DefectTablePage
            MyAlert={MyAlert}
            handelDisableEmailConfig={handelDisableEmailConfig}
            handelDisableExportDefect={handelDisableExportDefect}
            totalNoOfDefects={totalNoOfDefects}
          />
        </Route>
        <Route path="/defect/:id/details" exact={true} strict={true}>
          <DefectDetailsView MyAlert={MyAlert} handelDisableEmailConfig={handelDisableEmailConfig} />
        </Route>
      </Switch>

      {showEmail && <DefectEmail open={showEmail} showPopup={setShowEmail} />}
    </>
  );
}

export default DefectTab;

import React, { Fragment, useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';
import { useAlert } from '../../common/alert_service/useAlert';
import * as Yup from 'yup';
import { SearchIcon } from '@heroicons/react/solid';
import '../../common/dropdown.css';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '../../common/breadcrumbs';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import HomeIcon from '@material-ui/icons/Home';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import '../ElementConfig/elementConfig.css';
import Box from '@mui/material/Box';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@mui/material/Modal';
import { useFormik } from 'formik';
import MyInput from '../../common/Inputs/MyInput';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';
import { usePagination, useTable, useRowSelect } from 'react-table';
import { getSFDCConfig, postSFDCConfig, putSFDCConfig, deleteSFDCConfig } from '../../../api/api_services';
import { Menu, Transition } from '@headlessui/react';
import { Tooltip } from '@material-ui/core';
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@material-ui/icons';
import { DotsVerticalIcon } from '@heroicons/react/solid';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
    color: 'rgb(22,72,198)',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const SfdcConfig = (props) => {
  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().max(25, 'Must be 25 characters or less').required('Element type name is required'),
    }),
    onSubmit: (values) => {
      // alert(JSON.stringify(props,null,2))
    },
  });

  const [showOptions, setShowOptions] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [SfdcData, setSfdcData] = React.useState([]);
  const [getDltData, setGetDltData] = useState();
  const [Editname, setEditname] = useState('');
  const [EditDecr, setEditDecr] = useState('');
  const [groupID, setGroupID] = React.useState({});
  const [editData, setEditData] = React.useState();
  const [editDecrName, setEditDecrName] = React.useState();
  const [elementName, setElementName] = useState('');
  const [pageIndexValue, setPageIndex] = React.useState(0);
  const [tableData, setTableData] = React.useState([]);

  const onInputChange = (e) => {
    const { value } = e.target;

    const re = /^[A-Za-z]+$/;
    if (value === '' || re.test(value)) {
      setElementName(value);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    formik.values.name = '';
    formik.setErrors({});
    setElementName('');
  };

  const handleEditOpen = () => setOpenEdit(true);
  const handleEditClose = () => {
    setOpenEdit(false);
    formik.setErrors({});
  };

  const handleDeleteOpen = () => setOpenDelete(true);
  const handleDeleteClose = () => setOpenDelete(false);

  const { AlertContatiner, MyAlert } = useAlert();
  let activeTab = props.location.pathname.split('/')[3];

  const handelDropdown = () => {
    setShowOptions(!showOptions);
  };
  // if click outside of dropdown also it will close
  let menuRef = useRef();
  useEffect(() => {
    let handeler = (event) => {
      if (menuRef.current && !menuRef?.current?.contains(event.target)) {
        setShowOptions(false);
      }
    };
    document.addEventListener('mousedown', handeler);
    return () => {
      document.removeEventListener('mouseleave', handeler);
    };
  }, []);

  let projectId = JSON.parse(localStorage.getItem('selected-project')).id;
  React.useEffect(() => {
    const search = window.location.search;
    let projectId = new URLSearchParams(search).get('id') || JSON.parse(localStorage.getItem('selected-project'));

    if (new URLSearchParams(search).get('id')) {
      projectId = new URLSearchParams(search).get('id');
    } else {
      projectId = JSON.parse(localStorage.getItem('selected-project')).id;
    }

    if (projectId) {
      getSFDCConfig(projectId).then((results) => {
        localStorage.setItem('configReq', false);
        if (results && results.data && results.data.responseObject) {
          setSfdcData(results.data.responseObject);
        }
      });
    } else {
      localStorage.setItem('configReq', true);
      getSFDCConfig().then((results) => {
        localStorage.setItem('configReq', false);
        if (results && results.data && results.data.responseObject) {
          setSfdcData(results.data.responseObject);
        }

        //  console.log(expUnit,"checking-----");
      });
    }
  }, [projectId, getDltData]);
  //  create------
  const createSfdc = (e) => {
    localStorage.setItem('resultPutReq', true);
    const names = document.getElementById('name').value;
    const descr = document.getElementById('description').value;
    let data = {
      elementType: names,
      description: descr,
    };

    if (projectId) {
      postSFDCConfig(projectId, data)
        .then((results) => {
          localStorage.setItem('resultPutReq', false);
          if (results.data.responseCode === 200) {
            setGetDltData(results);

            MyAlert.success(`${data.elementType} created successfully`);
            handleClose();
          }
          if (results.data.message === `Element Type ${data.elementType} already exist`) {
            setGetDltData(results);

            MyAlert.danger(results.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      localStorage.setItem('resultPutReq', true);
      postSFDCConfig(projectId, data)
        .then((results) => {
          localStorage.setItem('resultPutReq', false);
          if (results.data.responseCode === 200) {
            setGetDltData(results);

            MyAlert.success(`${data.elementType} created successfully`);
            handleClose();
          }
          if (results.data.message === `Element Type ${data.elementType} already exist`) {
            setGetDltData(results);

            MyAlert.danger(results.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  // update
  const selectUser = (id) => {
    SfdcData.map((res, index) => {
      if (res.id === id) {
        formik.values.Editname = res.elementType;

        setEditname(res.elementType);
        setEditDecr(res.description);
      }
    });
  };
  const updateEditVal = () => {
    localStorage.setItem('resultGetReq', true);
    let data = {
      elementType: editData,
      description: editDecrName,
    };
    if (projectId) {
      putSFDCConfig(groupID.id, data, projectId)
        .then((res) => {
          localStorage.setItem('resultGetReq', false);
          setGetDltData(res.data);
          if (res.data.responseCode === 200) {
            MyAlert.success(`${data.elementType} updated successfully`);
          }
          if (groupID.elementType === data.elementType && groupID.description === data.description) {
            MyAlert.info(`No changes were done to the ${data.elementType}`);
            return null;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      localStorage.setItem('resultGetReq', true);
      putSFDCConfig(groupID.id, data, projectId)
        .then((res) => {
          localStorage.setItem('resultGetReq', false);
          setGetDltData(res.data);
          if (res.data.responseCode === 200) {
            MyAlert.success(`${data.elementType} updated successfully`);
          }
          if (groupID.elementType === data.elementType && groupID.description === data.description) {
            MyAlert.info(`No changes were done to the ${data.elementType}`);
            return null;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    handleEditClose();
  };

  // delete
  const deleteData = () => {
    localStorage.setItem('resultPutReq', true);
    if (projectId) {
      deleteSFDCConfig(projectId, groupID.id)
        .then((res) => {
          localStorage.setItem('resultPutReq', false);
          setGetDltData(res.data);
          if (res.data.responseCode === 200) {
            MyAlert.success(`${groupID.elementType} deleted successfully`);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      localStorage.setItem('resultPutReq', true);
      deleteSFDCConfig(projectId, groupID.id)
        .then((res) => {
          localStorage.setItem('resultPutReq', false);
          setGetDltData(res.data);
          if (res.data.responseCode === 200) {
            MyAlert.success(`${groupID.elementType} deleted successfully`);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    handleDeleteClose();
  };
  const classes = useStyles();
  // menuBTN

  const menuBtn = (ele) => {
    return (
      <div className="actionIcons -ml-4">
        <button
          disabled={ele?.isDefault === true}
          className={
            ele?.isDefault === true ? 'opacity-1 float-left  text-blue-300 ' : 'float-left text-blue-700 opacity-100'
          }
          onClick={() => {
            handleEditOpen(true);
            setGroupID(ele);
            selectUser(ele.id);
            setEditData(ele.elementType);
            setEditDecrName(ele.description);
          }}
        >
          <Tooltip title="Edit">
            <EditOutlinedIcon />
          </Tooltip>
        </button>
        <button
          disabled={ele?.isDefault === true}
          className={
            ele?.isDefault === true
              ? 'opacity-1 float-left mx-1  text-blue-300'
              : 'float-left mx-1 text-blue-700 opacity-100'
          }
          onClick={() => {
            handleDeleteOpen(true);
            setGroupID(ele);
          }}
        >
          <Tooltip title="Delete">
            <DeleteOutlinedIcon />
          </Tooltip>
        </button>
        <button
          className={
            ele?.isDefault === true ? 'opacity-1 float-left  text-blue-300' : 'float-left  text-blue-700 opacity-100'
          }
        >
          <Tooltip title="More">
            <MoreVertIcon className=" text-blue-700" />
          </Tooltip>
        </button>
      </div>
    );
  };
  const getVariables = async () => {
    let varData = [];
    let res = await getSFDCConfig();
    if (res.data && res.data.responseObject && res.data.responseObject.length > 0) {
      res.data.responseObject.map((ele, index) =>
        varData.push({
          slno: (
            <div className="text-blue-700 cursor-pointer text-xs Poppins-Regular font-regular flex">
              <div className="actionIcons -mt-1 -ml-8">
                <DragIndicatorIcon />
              </div>{' '}
              {/* <div className="ml-4"> {index+1}{" "}</div> */}
            </div>
          ),
          elementType: (
            // <span
            //   className="text-gray-700 cursor-pointer text-sm Poppins-Regular font-regular"

            // >
            //   {" "}
            //   {ele.elementType.charAt(0).toUpperCase() + ele.elementType.slice(1)}{" "}
            // </span>
            <div className="text-gray-700 cursor-pointer text-sm Poppins-Regular font-regular flex">
              <div className="actionIcons mx-2  mt-2 -ml-6 text-blue-700 cursor-pointer text-xs Poppins-Regular font-regular flex">
                <DragIndicatorIcon />
              </div>{' '}
              <div className="h-8 mt-3"> {ele.elementType.charAt(0).toUpperCase() + ele.elementType.slice(1)} </div>
            </div>
          ),
          createdByUname: (
            <span className="text-gray-700 cursor-pointer text-sm Poppins-Regular font-regular">
              {' '}
              {ele ? (ele?.createdByUname ? ele?.createdByUname : '--') : '--'}
            </span>
          ),
          createdOn: (
            <span className="text-gray-700 cursor-pointer text-sm Poppins-Regular font-regular">
              {' '}
              {ele ? (ele?.createdByUname ? ele?.createdOn : '--') : '--'}{' '}
            </span>
          ),

          actions: menuBtn(ele),
        })
      );
      if (varData.length == 7 * pageIndexValue && pageIndexValue != 0) {
        setPageIndex(pageIndexValue - 1);
      }
      setTableData(varData);
    } else {
      setTableData([]);
    }
  };
  useEffect(() => {
    getVariables();
  }, [getDltData]);

  // react table
  const data = React.useMemo(() => tableData, [tableData]);

  const columns = React.useMemo(
    () => [
      // {
      //   Header: "Sl No",
      //   accessor: "slno",
      //   width: "8%",

      // },
      {
        Header: 'Element Type',
        accessor: 'elementType',
        width: '20%',
      },
      {
        Header: 'Created By',
        accessor: 'createdByUname',
        width: '20%',
      },
      {
        Header: 'Created On',
        accessor: 'createdOn',
        width: '13%',
      },

      {
        Header: 'Actions',
        accessor: 'actions',
        width: '12%',
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: pageIndexValue, pageSize: 7 },
    },
    usePagination,
    useRowSelect
  );
  return (
    <div className="page-container">
      <div class="flex ">
        <div ref={menuRef} class="relative inline-block text-left repo-dev-tab-height">
          <div>
            <span class=" text-gray-700 font-semibold py-1 px-4 rounded inline-flex items-center">
              <span className="selectedElm" onClick={handelDropdown}>
                Element Type
              </span>

              <ArrowDropDownIcon onClick={handelDropdown} class="fill-current h-7 w-11  -mx-6" />
              <Link to="/configuration/ElementConfiguration/Locators/web" className="mx-8 nonSelectedElm">
                Locators
              </Link>
              <Link to="" className="mx-1 nonSelectedElm">
                History
              </Link>
            </span>
          </div>
          {showOptions && (
            <div
              style={{ width: '100px', marginTop: '-5px' }}
              class="origin-top-left absolute right-70 mt-2 w-75 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabindex="-1"
            >
              <div class="py-1" role="none">
                <Link
                  to="/configuration/ElementConfiguration/ElementType/Web"
                  class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-0"
                >
                  Web
                </Link>
                <Link
                  to="/configuration/ElementConfiguration/ElementType/android"
                  class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-1"
                >
                  Android
                </Link>
                <Link
                  to="/configuration/ElementConfiguration/ElementType/ios"
                  class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-2"
                >
                  iOS
                </Link>
                <Link
                  onClick={() => {
                    setShowOptions(false);
                  }}
                  to="/configuration/ElementConfiguration/ElementType/sfdc"
                  class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-2"
                >
                  Salesforce
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="page-list-body mt-2">
        <div className="alert-position-style">
          <AlertContatiner></AlertContatiner>
        </div>

        <div className="page-table-body">
          <div className="content_area_style" style={{ height: '81vh' }}>
            <div className="grid grid-cols-2 mb-4 ">
              <span className=" ml-3 mt-4 Poppins-SemiBold configPageHeading">Salesforce</span>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginRight: '15px',
                }}
              >
                <SearchIcon
                  style={{ marginRight: '-1.5rem', color: 'grey' }}
                  className=" mt-4 w-20 h-7   items-center float-left"
                />
                <button
                  onClick={handleOpen}
                  className="primary-btn mt-4 w-25 ml-10  h-7 mr-6  bg-white  text-sm  text-gray-800  border border-gray-400 opacity-100 rounded"
                >
                  + Element Type
                </button>
              </div>
            </div>

            {/*----------------------Modal Section-------------------------------- */}

            <Modal
              open={open}
              //  onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box>
                <div
                  style={{
                    width: '40rem',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                  className="  pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xs transform transition-all sm:align-middle w-2/4"
                >
                  <div className="inline-block" style={{ display: 'flex' }}>
                    <div className="flex flex-start ml-2 my-3">
                      <span className="fontPoppinsSemiboldMd font-bold  mt-4 mx-3 text-sm block">
                        Create Element Type
                      </span>
                    </div>
                    <div onClick={handleClose} style={{ marginLeft: '25.6rem', color: 'grey', marginTop: '20px' }}>
                      <CloseIcon />
                    </div>
                  </div>

                  <hr className="line mt-px" />
                  <div className="text-sm font-sans mt-3 text-gray-500 ml-16 mr-8">
                    <MyInput
                      type="text"
                      error={formik.errors.name && formik.touched.name}
                      values={formik.values.name}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        onInputChange(e);
                        formik.handleChange(e);
                      }}
                      value={elementName}
                      name="name"
                      id="name"
                      autoComplete="off"
                      style={{ width: '50%' }}
                      autoFocus={true}
                      className=" block w-9/12 Apptype"
                      placeholder="Enter element type name"
                      helperText={formik.touched.name && formik.errors.name}
                      label={
                        <>
                          {' '}
                          <span
                            class="text-red-400"
                            style={{ color: '#EA0322', fontSize: '12px', fontFamily: 'Poppins-SemiBold' }}
                          >
                            *
                          </span>
                          <span style={{ fontSize: '16px', fontFamily: 'Poppins-Regular' }}> Name</span>
                        </>
                      }
                    />

                    <div className="mt-5">
                      <label htmlFor="Description" className="input-filed-label-style-common">
                        Description
                      </label>
                      <textarea
                        id="description"
                        name="description"
                        placeholder="Your description goes here..."
                        rows="1"
                        maxLength="200"
                        className="input-style-textarea  shadow-sm block  mt-1  border-0 border-b  input-field-style-textarea "
                      ></textarea>
                    </div>
                  </div>

                  <hr className="line mt-3" />
                  <div className="mt-2 pb-2 flex flex-row float-right">
                    <button
                      type="submit"
                      onClick={handleClose}
                      className="mt-3 gray-btn ml-1"
                      style={{
                        fontSize: '14px',
                        fontFamily: 'Poppins-SemiBold',
                        marginRight: '12px',
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="mt-3 ml-4 primary-btn "
                      onClick={createSfdc}
                      style={{
                        fontSize: '14px',
                        fontFamily: 'Poppins-SemiBold',
                        marginRight: '12px',
                      }}
                    >
                      Create
                    </button>
                  </div>
                </div>
              </Box>
            </Modal>

            {/*----------------------Modal Section Close-------------------------------- */}

            {/*----------------------Edit Modal Section-------------------------------- */}

            <Modal
              open={openEdit}
              onClose={handleEditClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box>
                <div
                  style={{
                    width: '40rem',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                  className="  pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xs transform transition-all sm:align-middle w-2/4"
                >
                  <div className="inline-block" style={{ display: 'flex' }}>
                    <div className="flex flex-start ml-2 my-3">
                      <span className="font-bold Poppins-SemiBold mt-4 mx-3 text-sm block">Edit Element Type</span>
                    </div>
                    <div onClick={handleEditClose} style={{ marginLeft: '25.6rem', color: 'grey', marginTop: '20px' }}>
                      <CloseIcon />
                    </div>
                  </div>

                  <hr className="line mt-px" />
                  <div className="text-sm font-sans mt-3 text-gray-500 ml-16 mr-8">
                    <MyInput
                      type="text"
                      error={formik.errors.name && formik.touched.name}
                      value={editData}
                      onBlur={formik.handleBlur}
                      //  onChange={formik.handleChange}
                      onChange={(e) => {
                        setEditData(e.target.value);
                      }}
                      name="Editname"
                      id="Editname"
                      autoComplete="off"
                      style={{ width: '50%' }}
                      autoFocus={true}
                      className=" block w-9/12 Apptype"
                      placeholder="Enter element type name"
                      helperText={formik.touched.name && formik.errors.name}
                      label={
                        <>
                          {' '}
                          <span
                            class="text-red-400"
                            style={{ color: '#EA0322', fontSize: '12px', fontFamily: 'Poppins-SemiBold' }}
                          >
                            *
                          </span>
                          <span style={{ fontSize: '16px', fontFamily: 'Poppins-Regular' }}> Name</span>
                        </>
                      }
                    />

                    <div className="mt-5">
                      <label htmlFor="Description" className="input-filed-label-style-common">
                        Description
                      </label>
                      <textarea
                        id="description"
                        name="description"
                        value={editDecrName}
                        onChange={(e) => {
                          setEditDecrName(e.target.value);
                        }}
                        placeholder="Your description goes here..."
                        rows="1"
                        maxLength="200"
                        className="input-style-textarea  shadow-sm block  mt-1  border-0 border-b  input-field-style-textarea "
                      ></textarea>
                    </div>
                  </div>

                  <hr className="line mt-3" />
                  <div className="mt-2 pb-2 flex flex-row float-right">
                    <button
                      type="button"
                      className="mt-3 gray-btn ml-1"
                      onClick={handleEditClose}
                      style={{
                        fontSize: '14px',
                        fontFamily: 'Poppins-SemiBold',
                        marginRight: '12px',
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="mt-3 ml-4 primary-btn "
                      onClick={updateEditVal}
                      style={{
                        fontSize: '14px',
                        fontFamily: 'Poppins-SemiBold',
                        marginRight: '12px',
                      }}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </Box>
            </Modal>

            {/*----------------------Edit Modal Section Close-------------------------------- */}

            {/*----------------------Delete Modal Section-------------------------------- */}

            <Modal
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box>
                <div
                  style={{
                    width: '40rem',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                  className=" marginupper pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/4"
                >
                  <div className="inline-block">
                    <div className="flex flex-start ml-2">
                      <span className="pt-5 pl-3">
                        <ExclamationIcon className="h-6 w-6 text-yellow-600 " />
                      </span>

                      <span className="font-bold Poppins-SemiBold mt-4 mx-3 text-sm block">Warning </span>
                      <span
                        style={{
                          marginLeft: '-70px',
                          marginTop: '35px',
                        }}
                        className="text-sm Poppins-Regular  "
                      >
                        Delete{' '}
                      </span>
                    </div>
                  </div>

                  <hr className="line mt-px" />
                  <div className="text-sm font-sans mt-3 text-gray-500 ml-16 mr-8">
                    <p className="" style={{ color: 'black' }}>
                      Are you sure you want to delete Element type?
                    </p>
                    <p className="mt-2" style={{ color: 'black' }}>
                      How do you want to proceed?
                    </p>
                  </div>

                  <hr className="line mt-3" />
                  <div className="mt-2 pb-2 flex flex-row float-right">
                    <button
                      type="button"
                      className="mt-3 gray-btn ml-1"
                      onClick={deleteData}
                      style={{
                        fontSize: '14px',
                        fontFamily: 'Poppins-SemiBold',
                        marginRight: '12px',
                      }}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="mt-3 ml-4 primary-btn "
                      onClick={handleDeleteClose}
                      style={{
                        fontSize: '14px',
                        fontFamily: 'Poppins-SemiBold',
                        marginRight: '12px',
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Box>
            </Modal>

            {/*----------------------Delete Modal Section Close-------------------------------- */}

            <div
              className=" grid grid-cols-3 "
              style={{
                // background: "rgb(240,243,249)",
                display: 'flex',
              }}
            >
              {/* <table>
            <thead style={{background: "rgb(240,243,249)",textAlign:'left',padding: "1rem",height:'40px'}}>
            <tr >
            <th></th>
            <th className="text-sm">Sl No</th>
            <th className="text-sm">Element Type</th>
            <th className="text-sm">Created By</th>
            <th className="text-sm">Created On</th>
            <th className="text-sm">Actions</th>
            
            </tr>
            
            </thead>
            <tbody style={{textAlign:'left'}}>

            
            {
            SfdcData.map((data,index)=>{
              return(
                <tr  key={data.id}  className="hover:bg-gray-100 visibleData" style={{height:'50px'}}>
            <td className='text-blue-700 '><span className="drag"><DragIndicatorIcon/></span></td> 
            <td className="text-gray-700">{index+1}</td>
            <td id="Link" className="text-gray-700">{data.elementType}</td>
            <td>{data?data?.createdByUname?data?.createdByUname:"--":"--"}</td>
            <td>{data?data?.createdOn?data?.createdOn.split(" ").splice(0,1):"--":"--"}</td>
            <td style={{display:'flex',flexDirection:'row'}}>
            <span className="drag" style={{marginTop:'10px',marginLeft:'-2.7rem'}}>
            <button disabled={data?.isDefault===true} onClick={()=>{handleEditOpen(true); setGroupID(data);selectUser(data.id)}}><EditOutlinedIcon  className={data?.isDefault===true?"opacity-1 float-left edit-style text-blue-300":"float-left edit-style text-blue-700 opacity-100"}/></button>
            <button disabled={data?.isDefault===true} onClick={()=>{handleDeleteOpen(true);setGroupID(data);}}><DeleteOutlinedIcon   className={data?.isDefault===true?"opacity-1 float-left edit-style text-blue-300":"float-left edit-style text-blue-700 opacity-100"}/></button>
            <button><MoreVertIcon className="float-left edit-style text-blue-700"/></button>
            </span>
            </td>
            </tr>

              )
            })
           }
            
            </tbody>
            
            </table> */}
              <table {...getTableProps()} className="">
                <thead className="fontPoppinsSemiboldMd">
                  {headerGroups.map((headerGroup) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      className="text-sm h-9 ml-4 bg-gray-100 hover:bg-gray-100"
                    >
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps()}
                          width={column.width}
                          className=" text-left mx-2"
                          style={{ paddingLeft: '2.8rem' }}
                        >
                          {column.render('Header')}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className="fontPoppinsRegularMd text text-gray-700">
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} className="configHover h-9 content-start">
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="p-0  text-left text-gray-700"
                              style={{ paddingLeft: '2.8rem' }}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="pagecountborder ">
              <div className="float-right text-sm mr-3 pt-0.5 pb-0.5">
                <span className="pagecount">
                  {pageSize * pageIndex + 1}-{page.length + pageSize * pageIndex + 1 - 1} of {data.length}
                  {''}
                  {/* {page[0].index + 1} - {page[page.length - 1].index + 1} of{" "}
                  {data.length}{" "} */}
                </span>
                <span className="pageborderleft ml-2 pl-2">
                  <button
                    onClick={() => {
                      previousPage();
                      setPageIndex(pageIndexValue - 1);
                    }}
                    disabled={!canPreviousPage}
                    className={canPreviousPage ? 'pagination-clickable-style ' : 'pageArrow'}
                  >
                    {'<'}
                  </button>{' '}
                  <span className="currentpagecount text-sm ml-2 mr-2">
                    {' '}
                    <span className="pl-2 pr-2">{pageIndex + 1}</span>
                  </span>
                  <button
                    onClick={() => {
                      nextPage();
                      setPageIndex(pageIndexValue + 1);
                    }}
                    disabled={!canNextPage}
                    className={canNextPage ? 'pagination-clickable-style ' : 'pageArrow'}
                  >
                    {'>'}
                  </button>{' '}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default withRouter(SfdcConfig);

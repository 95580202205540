import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { editSingleFolderReq, postFolderReq } from '../../../api/api_services';
import Modal from 'react-modal';
import IconButton from '@material-ui/core/IconButton';
import { checkPageExistParentPage } from '../../../common/ui-constants';

import { TextField, TextareaAutosize } from '@material-ui/core';

import TreeWithRadioButton from '@src/pages/common/table_tree/table-tree-with-radio-button';
function FolderModal(props) {
  const nameFieldRef = useRef(null);
  const search = window.location.search;
  const id = new URLSearchParams(search).get('id');
  const { children, ...default_node } = props?.treeData[0];
  const [showModal, setShowModal] = useState(true);
  const [selectedFolder, setSelectedFolder] = useState('');
  const [descCount, setdescCount] = React.useState(0);
  let [noOfRows, setNoOfRows] = useState(1);
  let [focusedDesc, setFocusedDesc] = useState(false);
  let [executionOrder, setExecutionOrder] = useState(
    props.nodeToAdd ? (props.nodeToAdd === 0 ? 1 : findExecutionOrder(props.nodeToAdd)) : 1
  );
  const ROOT_FOLDER_CONSTANT="Root Folder";
  function findExecutionOrder(node, rootNode = false) {
    if (rootNode) {
      const childs = node.children;
      if (childs?.length) {
        return childs[childs.length - 1].executionOrder + 1;
      } else {
        return 1;
      }
    }
    else if (node.children) {
        return Number(node.getLastChild().data.executionOrder) + 1;
    }else{
      return 1;
    }
  }
  let initialValues;
  useEffect(() => {
    if (props.data) {
      setdescCount(props?.data?.desc?.length);
    }
  }, []);
  if (props.data) {
    initialValues = {
      folderName: props.data.name,
      description: props.data.desc,
      parentPage: props.data.parentName,
    };
  } else if (props?.parentName) {
    initialValues = {
      folderName: '',
      description: '',
      parentPage: props?.parentName,
    };
  } else if (props.treeData[0]?.children?.length > 0) {
    initialValues = {
      folderName: '',
      description: '',
      parentPage: '',
    };
  } else {
    initialValues = {
      folderName: '',
      description: '',
      parentPage: 'Root',
    };
  }

  const treeNodeSelected = ([data]) => {
    if (!data) {
      formikDetails.setFieldValue('parentPage', '');
    } else if (data && data.data.isRootNode) {
      setExecutionOrder(findExecutionOrder(data.data, true));
      setSelectedFolder({ ...data.data, data: data.data });
      formikDetails.setFieldValue('parentPage', data.data.title);
    } else if (data && data.data.key) {
      setExecutionOrder(findExecutionOrder(data.node));
      setSelectedFolder({ ...data.node, data: data.data });
      formikDetails.setFieldValue('parentPage', data.data.title);
    }
    nameFieldRef?.current?.focus();
  };

  const modifyError = (folderName) => {
    return (
      <span>
        {folderName.split(/(\(|\)|-)/).map((part, index) => {
          if (part === '(' || part === ')') {
            return (
              <span key={index}>
                <strong>{part}</strong>&nbsp;
              </span>
            );
          } else if (part === '-') {
            return (
              <span key={index}>
                <strong>{part}</strong>
              </span>
            );
          }
          return part;
        })}
      </span>
    );
  };
  async function createContainerReq(reqBody) {
    let response;
    const { name = '' } = reqBody || {};
    try {
      response = await postFolderReq(reqBody);
      if (response.data.responseObject) {
        props.closeModal(false);
        if (props.mode === 'ADD') {
        } else {
          const {
            modifiedOn,
            parentName,
            folder,
            modifiedBy,
            name: _name,
            modifiedByUname,
            subFolderCount,
            fileCount,
            id,
            createdByUname,
            parentId,
          } = response?.data?.responseObject || {};
          const updateData = {
            modifiedOn: modifiedOn,
            parentName: parentName,
            folder: folder,
            modifiedBy: modifiedBy,
            title: _name,
            modifiedByUname: modifiedByUname,
            subFolderCount: subFolderCount,
            fileCount: fileCount,
            key: id,
            createdByUname: createdByUname,
            parentId: parentId,
          };
          props.updatedValue(updateData, 'folder');
        }
        props.reloadTree(true);
        props.MyAlert.success(
          `${name && (name.length > 20 ? name.substring(0, 20) + '...' : name)} Folder created successfully`
        );
      } else {
        if (response.data.responseCode === 400 && response.data.message.includes('already exists')) {
          formikDetails.setFieldError('folderName', 'Name already exists');
        } else if (response.data.responseCode === 400) {
          formikDetails.setFieldError('folderName', response.data.message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function updateContainerReq(reqBody) {
    let response;
    const { name = '', id } = reqBody || {};
    try {
      response = await editSingleFolderReq(id, reqBody);
      if (response.data.responseObject) {
        props.closeModal(false);
        props.updatedValue(name, 'folder');
        props.MyAlert.success(
          `${name && (name.length > 20 ? name.substring(0, 20) + '...' : name)} Folder updated successfully`
        );
        if (id) {
          props.reloadTree(true);
        }
      } else {
        if (response.data.responseCode === 400 && response.data.message.includes('already exists')) {
          formikDetails.setFieldError('folderName', 'Name already exists');
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const createFolder = () => {
    const values = formikDetails.values;
    let exist = false;
    if (props.treeData[0]?.children?.length > 0) {
      if (props.parentId) {
        exist = checkPageExistParentPage(props.treeData, props.parentId, values.folderName);
      } else if (!selectedFolder) {
        exist = checkPageExistParentPage(props.treeData, default_node.key, values.folderName);
      } else {
        exist = checkPageExistParentPage(props.treeData, selectedFolder.data.key, values.folderName);
      }
    }
    let requestBody;
    if (exist) {
      props.closeModal(false);
      props.MyAlert.info(`Folder ${values.folderName} Already Exist`);
    } else {
      if (values) {
        requestBody = {
          name: values.folderName,
          desc: values.description,
          executionOrder: executionOrder,
        };
        requestBody['programElements'] = [];
        if (props.parentId) {
          requestBody['parentId'] = props.parentId;
          requestBody['parentName'] = props.parentName;
        } else if (!selectedFolder) {
          requestBody['parentId'] = default_node.key;
          requestBody['parentName'] = default_node.title;
        } else {
          requestBody['parentId'] = selectedFolder.data.key;
          requestBody['parentName'] = selectedFolder.title;
        }
        createContainerReq(requestBody);
      }
    }
  };
  const updateFolder = () => {
    const values = formikDetails.values;
    let exist = false;
    let noupdate = false;
    if (values.folderName === props.data.name && values.description === props.data.desc) {
      noupdate = true;
    } else if (values.folderName === props.data.name && values.description !== props.data.desc) {
      exist = false;
    } else if (values.folderName !== props.data.name) {
      exist = checkPageExistParentPage(props.treeData, props.data.parentId, values.folderName);
    }
    if (noupdate) {
      props.closeModal(false);
      props.MyAlert.success(`${values.folderName} Folder updated successfully`);
    } else if (exist) {
      props.closeModal(false);
      props.MyAlert.info(`${values.folderName} already`);
    } else {
      props.data.name = values.folderName;
      props.data.desc = values.description;
      updateContainerReq(props.data);
    }
  };

  const validationSchema = yup.object({
    folderName: yup
      .string()
      .required('Name is required')
      .matches(/^[a-zA-Z0-9-_() ]*$/, 'Name should accept only ( ) - special characters')
      .trim('Space is not allowed at starting and at the end')
      .strict(true)
      .min(2, 'Name must be minimum 2 characters'),
    parentPage: yup.string().required('Parent Folder is requied'),
    description: yup.string(),
  });

  const onSubmit = (values) => {
    if (['ADD', 'ADD_SUB'].includes(props?.mode)) {
      createFolder();
    } else {
      updateFolder();
    }
  };

  const formikDetails = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  if (formikDetails.values.folderName === '' && formikDetails.touched.folderName) {
  }
  let errorExistForParentPage = false;
  if (formikDetails.values.parentPage === '' && formikDetails.touched.parentPage) {
    errorExistForParentPage = true;
  } else {
    errorExistForParentPage = false;
  }
  return (
    <Modal
      isOpen={showModal}
      onRequestClose={(e) => {
        if (e.key === "Escape"){
          setShowModal(false);
          props.closeModal(false);
        }
      }}
      className="p-px modal-dialog"
    >
      <div className="modal-container page-modal-size modal-position">
        <div className="modal-header">
          {props.mode === 'ADD' || props.mode === 'ADD_SUB' ? (
            <h2 className="title Popup-header-common"> Create Folder</h2>
          ) : (
            <div className="title title-trim Popup-header-common">Edit Folder - {props.data.name}</div>
          )}
          <IconButton
            component="span"
            className="close"
            onClick={() => {
              props.closeModal(false);
              setShowModal(false);
            }}
          >
            <img
              style={{
                cursor: 'pointer',
              }}
              src="/assets/images/close_black.svg"
              alt=""
              height="25px"
              width="25px"
              draggable="false"
            />
          </IconButton>
        </div>
        <form onSubmit={formikDetails.handleSubmit}>
          <div className="modal-body-folder" id="journal-scroll">
            <div>
              <div className="mt-2">
                <div>
                  <TextField
                    error={formikDetails.errors.folderName && formikDetails.touched.folderName}
                    size="40"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={nameFieldRef}
                    className="w-1/2 mui-input-text-field input-filed-label-style-common"
                    autoFocus={true}
                    type="text"
                    name="folderName"
                    id="folderName"
                    autoComplete="off"
                    placeholder={`Enter folder name`}
                    onBlur={formikDetails.handleBlur}
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.folderName}
                    label={
                      <>
                        {' '}
                        <span className="text-red-400">&#42;</span>Name
                      </>
                    }
                  />
                </div>
                {formikDetails.errors.folderName && formikDetails.touched.folderName ? (
                  <div className="errorMessage">{modifyError(formikDetails.errors.folderName)}</div>
                ) : null}
              </div>
              <div className="mt-7 w-full">
                <label
                  htmlFor="description"
                  className={focusedDesc ? 'input-label-for-select' : 'input-filed-label-style-common'}
                >
                  Description
                </label>
                <TextareaAutosize
                  rowsMax={noOfRows}
                  onBlur={(e) => {
                    setNoOfRows(1);
                    e.target.classList.add('descriptionStyle');
                    setFocusedDesc(false);
                  }}
                  onFocus={(e) => {
                    setNoOfRows(null);
                    e.target.classList.remove('descriptionStyle');
                    setFocusedDesc(true);
                  }}
                  autoComplete="off"
                  id="description"
                  name="description"
                  maxLength="200"
                  className="block w-full input-style-textarea popup-input-bg  border-0 border-b input-field-color descriptionStyle"
                  onKeyUp={(e) => setdescCount(e.target.value.length)}
                  value={formikDetails.values.description}
                  placeholder={props.mode === 'ADD' || props.mode === 'ADD_SUB' ? 'Your description goes here...' : ''}
                  onChange={formikDetails.handleChange}
                />
                <div className="mt-1 text-sm text-gray-500 text-right fontPoppinsRegularMd">{descCount}/200</div>
                {formikDetails.errors.description && formikDetails.touched.description && (
                  <p style={{ color: 'red' }}>{formikDetails.errors.description}</p>
                )}
              </div>
              <div className="mt-5">
                <label
                  htmlFor="parentPage"
                  className={
                    errorExistForParentPage ? 'fontPoppinsRegularMd text-red-500' : 'input-filed-label-style-common'
                  }
                >
                  <span className="text-red-400">&#42;</span>Parent Folder
                </label>
                <div className="popup-input-bg">
                  <TreeWithRadioButton
                    moduleSelection={true}
                    individualTree={id ? true : false}
                    data={props?.treeData ? props.treeData : []}
                    operation={
                      ['ADD_SUB', 'EDIT'].includes(props.mode) || (!props.treeData[0]?.children?.length && !id)
                        ? 'edit'
                        : null
                    }
                    placeholder={!props.parentId?props.parentName || ROOT_FOLDER_CONSTANT:props.parentId?props.parentName:'Search and select parent module'}
                    nodeSelected={treeNodeSelected.bind(this)}
                    hideElements={true}
                    hideElementsBtn={true}
                    buttonLabel="Folder"
                  />
                </div>
                {formikDetails.errors.parentPage &&
                formikDetails.touched.parentPage &&
                formikDetails.values.parentPage === '' ? (
                  <div className="errorMessage">{formikDetails.errors.parentPage}</div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="modal-footer mt-2">
            <button
              type="button"
              className="cancel-button-common fontPoppinsMediumMd cancel-button-layout-style-project mr-3"
              onClick={() => {
                props.closeModal(false);
                setShowModal(false);
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="create-button-style-common fontPoppinsMediumMd text-white create-button-layout-style-project"
            >
              {props.mode === 'ADD' || props.mode === 'ADD_SUB' ? <span>Create</span> : <span>Update</span>}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default FolderModal;

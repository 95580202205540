import React, { useState, useRef, forwardRef } from 'react';
import { usePagination, useTable, useGlobalFilter } from 'react-table';
import { ReactComponent as ExpandArrow } from '@assets/analytics/ExpandArrow.svg';
import { ReactComponent as ShrinkIcon } from '@assets/analytics/ShrinkIcon.svg';
import BugSummaryDetails from '../components/modals/bug-summary-details';
import { isEmptyValue } from '@src/util/common_utils';
import { defectsAnalyticsTableHeaderTrim } from './util';
import SearchBar from './search-bar';

import '../Analytics.scss';
import { Box, Modal, Fade } from '@mui/material';
import { getScrollPosition } from './util';

const DefectsAnalyticTable = forwardRef((props, ref) => {
  let { columns, data, openBugSummaryModal, closeBugSummaryModal, selectedDefectID, setScrollPos } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const bigTableRef = useRef();

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, state, setGlobalFilter } = useTable(
    {
      columns,
      data: data || [],
    },
    useGlobalFilter,
    usePagination
  );
  const { globalFilter } = state;

  // TODO :: handle Search filter :: START
  let [openSearch, setOpenSearch] = useState(false);
  let handleSearchClose = () => {
    setOpenSearch(false);
    setGlobalFilter('');
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  // TODO :: handle Search filter :: END

  return (
    <>
      <div className="col-span-12 analytics-testscript-table">
        <div className="col-span-12 defects-analytics-table-header fontPoppinsSemiboldSm uppercase">
          <span
            title={props.header}
            className="overflow-hidden overflow-ellipsis whitespace-nowrap w-5/12 inline-block"
          >
            {defectsAnalyticsTableHeaderTrim(props.header)}
          </span>
          <span className="float-right ">
            <span className="flex justify-center gap-1 ">
              <SearchBar
                open={openSearch}
                searchValue={globalFilter || ''}
                handleSearch={(e) => {
                  setGlobalFilter(e.target.value);
                }}
                onSearchClose={handleSearchClose}
                handleSearchOpen={handleSearchOpen}
                disable={isEmptyValue(data)}
              />

              <div className="expandIconContainer" onClick={handleOpen} size="small">
                <ExpandArrow className="expIcon" />
              </div>
            </span>
          </span>
        </div>
        <div className="col-span-12 analytics-table-container">
          <table {...getTableProps()} className="col-span-12 defect-analytics-table-body analytics-table ">
            <thead>
              {headerGroups?.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="fontPoppinsSemiboldSm analyticsTableHeaderText h-8 ml-4 uppercase"
                >
                  {headerGroup.headers?.map((column) => (
                    <th width={column.width} {...column.getHeaderProps()} className="text-left">
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody
              ref={ref}
              onScroll={() => getScrollPosition(ref, setScrollPos)}
              {...getTableBodyProps()}
              className="analytics-table-body"
            >
              {rows?.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className=" h-8">
                    {row.cells.map((cell) => {
                      return (
                        <td
                          width={cell.column.width}
                          {...cell.getCellProps()}
                          className="pl-1 text-left  analytics-table-text fontPoppinsMediumSm rs-text-black"
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {(isEmptyValue(data) || isEmptyValue(rows)) && (
            <div className="analyticstable_message fontPoppinsMediumSm">{props?.message}</div>
          )}
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className="expand-failer-modalTable">
            <div className="col-span-12 ">
              <div className="col-span-12 defects-analytics-table-header fontPoppinsSemiboldSm uppercase">
                <span title={props.header}>{defectsAnalyticsTableHeaderTrim(props.header, 'bigTable')}</span>
                <span className="float-right ">
                  <span className="flex justify-center gap-1 ">
                    <SearchBar
                      open={openSearch}
                      searchValue={globalFilter || ''}
                      handleSearch={(e) => {
                        setGlobalFilter(e.target.value);
                      }}
                      onSearchClose={handleSearchClose}
                      handleSearchOpen={handleSearchOpen}
                      disable={isEmptyValue(data)}
                    />
                    <span className="expandIconContainer mr-2" onClick={handleClose}>
                      <ShrinkIcon />
                    </span>
                  </span>
                </span>
              </div>
              <div className="col-span-12 px-2">
                <table {...getTableProps()} className="col-span-12  analytics-table defectanalytics-table-expanded ">
                  <thead>
                    {headerGroups?.map((headerGroup) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className="fontPoppinsSemiboldSm analyticsTableHeaderText h-8 ml-4"
                      >
                        {headerGroup.headers.map((column) => (
                          <th width={column.width} {...column.getHeaderProps()} className="text-left uppercase">
                            {column.render('Header')}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody
                    ref={bigTableRef}
                    onScroll={() => getScrollPosition(bigTableRef, setScrollPos)}
                    {...getTableBodyProps()}
                    className="analytics-table-body"
                  >
                    {rows?.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} className=" h-8 border-b">
                          {row.cells.map((cell) => {
                            return (
                              <td
                                width={cell.column.width}
                                {...cell.getCellProps()}
                                className=" text-left fontPoppinsMediumSm"
                              >
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {(isEmptyValue(data) || isEmptyValue(rows)) && (
                  <div className="analyticstable_message_expand fontPoppinsMediumSm mt-5">{props?.message}</div>
                )}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openBugSummaryModal}
        onClose={() => closeBugSummaryModal()}
        closeAfterTransition
      >
        <Box className="expand-failer-modalTable">
          <div className="flex justify-between p-3">
            <div className="fontPoppinsSemiboldMd rs-blue uppercase modalHead-trimmer" title={selectedDefectID}>
              {selectedDefectID} BUG SUMMARY
            </div>
            <div className=" expandIconContainer cursor-pointer" onClick={() => closeBugSummaryModal()}>
              <ShrinkIcon />
            </div>
          </div>
          <BugSummaryDetails selectedDefectID={selectedDefectID} />
        </Box>
      </Modal>
    </>
  );
});
export default DefectsAnalyticTable;

import React, { Fragment, useState } from 'react';
import { Link, useHistory, Switch, Route } from 'react-router-dom';
import DefectDetailsTemplate from './defect_details_template';
import DefectLifecycleTemplate from './defect_lifecycle_template';
import DefectTemplateCreationPage from './defect_template_creation_page';
import DefectLifecycleView from './defect_lifecycle_view';

export default function DefectTemplate() {
  const history = useHistory();
  const pathArray = history.location.pathname.split('/');
  const activeTab = pathArray[pathArray.length - 1];

  const [tabsList, setTabsList] = useState([
    {
      name: 'Defect Details Template',
      pathUrl: `/configuration/template/defecttemplate/defectdetails`,
      selectedName: 'defectdetails',
    },

    {
      name: 'Defect Life Cycle Template',
      pathUrl: `/configuration/template/defecttemplate/defectlifecycleTemplate`,
      selectedName: 'defectlifecycleTemplate',
    },
  ]);
  function renderTabs() {
    if (activeTab !== 'defectdetails' && activeTab !== 'defectlifecycleTemplate') {
      return null;
    } else {
      return (
        <div>
          <ul className="navBar text-blue-600 flex flex-row primary-tab">
            {tabsList.map((tab, index) => {
              return (
                <li key={tab.name}>
                  <Link to={tab.pathUrl} className={`${activeTab === tab.selectedName ? 'selected_tab_style' : ''}`}>
                    {tab.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
  }
  return (
    <>
      {renderTabs()}
      <Switch>
        <Route exact path="/configuration/template/defecttemplate" component={DefectDetailsTemplate} />
        <Route exact path="/configuration/template/defecttemplate/defectdetails" component={DefectDetailsTemplate} />
        <Route
          exact
          path="/configuration/template/defecttemplate/defectlifecycleTemplate"
          component={DefectLifecycleTemplate}
        />
        <Route
          path="/configuration/template/defecttemplate/defectdetails/defecttemplatecreation"
          component={DefectTemplateCreationPage}
        />
        <Route
          exact
          path="/configuration/template/defecttemplate/defectlifecycleTemplate/defectlifecycleview"
          component={DefectLifecycleView}
        />
      </Switch>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import './searchabledropdown.scss';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { ClickAwayListener } from '@mui/base';
import { ReactComponent as SearchIconFilled } from '@assets/analytics/searchIcon-rs.svg';

const SearchableDropdown = ({
  list,
  searchLabel,
  optionKey,
  StartIcon,
  fontSize,
  selectedValue,
  EndIcon,
  handleItemClick,
  noDataFound,
  enableSearch,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [filteredList, setFilteredList] = useState(list);

  useEffect(() => {
    if (searchValue == null || searchValue == '' || searchValue == undefined) {
      setFilteredList(list);
    } else {
      let searchkey = searchValue?.toLowerCase();
      let tempArray = list?.filter((item) => item[optionKey]?.toLowerCase()?.includes(searchkey));
      setFilteredList(tempArray);
    }
  }, [searchValue, list]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
      <div className="search-wrapper">
        <Tooltip title={selectedValue} placement="left">
          <div onClick={() => setShowDropdown(!showDropdown)} className={`${fontSize} search-element`}>
            <StartIcon />
            <div className="searchable-title-element">
              {selectedValue?.length > 25 ? selectedValue?.slice(0, 25) + '...' : selectedValue}
            </div>
            <EndIcon />
          </div>
        </Tooltip>
        {showDropdown && (
          <div className="drop-box">
            {enableSearch && (
              <div className="search-block">
                <input
                  className="fontPoppinsRegularSm search-input"
                  type="text"
                  placeholder={searchLabel}
                  value={searchValue}
                  onChange={(e) => handleSearch(e)}
                />
                <SearchIconFilled className="search-icon" />
              </div>
            )}
            <div className="drop-box-scroll">
              {filteredList.length ? (
                filteredList?.map((item, index) => {
                  return (
                    <Tooltip title={item[optionKey]} disableInteractive placement="top">
                      <div
                        onClick={() => handleItemClick(item)}
                        className={`${fontSize} drop-item ${selectedValue === item[optionKey] ? 'selected-item' : ''}`}
                        key={`item[optionKey]-${index}`}
                      >
                        {item[optionKey]?.slice(0, 35)}
                      </div>
                    </Tooltip>
                  );
                })
              ) : (
                <div className="fontPoppinsRegularSm no-data-found">{noDataFound}</div>
              )}
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

SearchableDropdown.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchLabel: PropTypes.string,
  optionKey: PropTypes.string.isRequired,
  fontSize: PropTypes.string.isRequired,
  selectedValue: PropTypes.string.isRequired,
  handleItemClick: PropTypes.func.isRequired,
  noDataFound: PropTypes.string,
  enableSearch: PropTypes.bool,
};
SearchableDropdown.defaultProps = {
  list: [
    {
      label: '',
      value: '',
    },
  ],
  searchLabel: 'Search',
  optionKey: 'value',
  fontSize: 'fontPoppinsRegularSm',
  noDataFound: 'No Results Found',
  enableSearch: true,
};

export default SearchableDropdown;

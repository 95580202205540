import React from 'react';
import Styles from './chip.module.scss';
import PropTypes from 'prop-types';

const Chip = (props) => {
  const { text, title, onClick, key } = props;

  return (
    <div key={key || ''} className={Styles['custom-chip-wrapper']}>
      <span className={Styles['custom-chip-wrapper-text']} title={title || ''}>
        {text}
      </span>
      <span
        className={Styles['custom-chip-wrapper-close']}
        onClick={(e) => (onClick ? onClick(text) : e.preventDefault())}
      ></span>
    </div>
  );
};

Chip.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
  onClick: PropTypes.func, // for onChange event
};

export default Chip;

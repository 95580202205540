import React, { useEffect, useState, useMemo, useContext } from 'react';
import MyInput from '@pagescommon/Inputs/MyInput';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { RestContext } from '@pages/test-development/script/scripts/webservice/restapi/restapi';
import { WEBSERVICE } from '@pages/test-development/script/scripts/webservice/restapi/constants/constants';
import '@pages/test-development/script/scripts/webservice/restapi/css/common_Webservice.scss';
import Info from '../../request_parameter/images/info-gray-24dp.svg';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';
import InfoIconHover from '@pages/test-development/script/scripts/webservice/restapi/request/request_parameter/images/info_black_24dp.svg';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { colors } from '@src/css_config/colorConstants.js';
import useSyncContextValues from '@src/hooks/useSyncContextValues';

const BlueRadio = withStyles({
  root: {
    color: colors.button_blue_50,
    '&$checked': {
      color: colors.button_blue_50,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
const useStyles = makeStyles({
  smallRadioButton: {
    '& svg': {
      width: '0.6em',
      height: '0.6em',
    },
  },
  customRadioButton: {
    padding: '4.5px',
    '&:hover': {
      backgroundColor: 'transparent',
      pointerEvents: 'none',
    },
    '&.Mui-checked': {
      color: colors.button_blue_50,
    },
    '&:not(.Mui-checked)': {
      color: colors.gray_radio_button,
    },
  },
});
export default function Hawk(props) {
  const { onAuthIconHover, authIconHover } = props;
  const isEditable = useMemo(() => window.permission?.isEditAccess('testDevelopment'), []);
  const context = useContext(RestContext);
  const formik = useFormik({
    initialValues: {
      authID: '',
      authKey: '',
      algorithm: '',
      userName: '',
      nonce: '',
      timeStamp: '',
      extra: '',
      applicationID: '',
      delegated: '',
      ...(context?.values?.hawkAuthData || {}),
    },
    validationSchema: Yup.object({
      authID: Yup.string().required(WEBSERVICE.HAWK_AUTH_ID),
      authKey: Yup.string().required(WEBSERVICE.HAWK_AUTH_KEY),
      algorithm: Yup.string().required(WEBSERVICE.HAKW_ALGORITHM),
    }),
  });
  useSyncContextValues(formik, context, 'hawkAuthData');

  const [authID, setAuthId] = useState('');
  const [authKey, setAuthKey] = useState('');
  const [userName, setUserName] = useState('');
  const [nonce, setNonce] = useState('');
  const [timeStamp, setTimeStamp] = useState('');
  const [applicationId, setApplicationId] = useState('');
  const [delegated, setDelegated] = useState('');
  const [extra, setExtra] = useState('');

  const classes = useStyles();

  const handleChange1 = (e) => {
    const { value, data, action, name, checked } = e.target || 'unknown';
    const updatedValue = value ? value : checked
    context.setFieldValue('authType', 'Hawk');
    
    const values = {
        ...(context?.values?.hawkAuthData || {}),
        [name]: props.getUserInputValue(updatedValue, false, data, action),
      };
    
    context.setFieldValue('hawkAuthData', values);
    const updatedhawkData = { ...(context?.values?.hawkData || {}), [name]: updatedValue };
    context.setFieldValue('hawkData', updatedhawkData);
    formik.handleChange(e);
    formik.validateForm(values).then((errors) => context.setFieldValue('errors', errors));
    if (name === 'authID') {
      setAuthId(value);
    }
    if (name === 'authKey') {
      setAuthKey(value);
    }
    if (name === 'userName') {
      setUserName(value);
    }
    if (name === 'nonce') {
      setNonce(value);
    }
    if (name === 'timeStamp') {
      setTimeStamp(value);
    }
    if (name === 'applicationID') {
      setApplicationId(value);
    }
    if (name === 'delegated') {
      setDelegated(value);
    }
    if (name === 'extra') {
      setExtra(value);
    }
    const currentHawkValue = { ...(context.values.currentHawkValue || {}), [name]: e };
    context.setFieldValue('currentHawkValue', currentHawkValue);
    props.handleSaveAsSteps(false);
  };

  useEffect(() => {
    formik.validateForm().then((errors) => context.setFieldValue('errors', errors));
    return () => context.setFieldValue('currentHawkValue', {});
  }, []);
  return (
    <div className="mb-5 relative top-12 hawk-container">
      <form onSubmit={formik.handleSubmit} className="space-y-3" noValidate>
        <div className="grid grid-cols-3 gap-8 mt-4">
          <div>
            <div
              className="fontPoppinsMediumSm  text-left flex common-text-color-in-request"
              onDragStart={props.handleDragStart}
            >
              {' '}
              <span className="common-auth-star-symbol">*</span> Hawk Auth ID
            </div>
            <MyInput
              canCreateVariable={isEditable}
              type="text"
              placeholder="Enter hawk auth ID"
              variableInput={true}
              className="input-style-for-auth2-common"
              inputValue={'input'}
              error={formik.errors.authID && formik.touched.authID}
              value={authID ? authID : props.getUserInputValue(formik.values.authID, true)}
              onMouseDown={formik.handleBlur}
              onChange={handleChange1}
              autoComplete="off"
              name="authID"
              helperText={formik.touched.authID && formik.errors.authID}
              currentValue={context.values.currentHawkValue?.authID}
              {...props}
            />
          </div>
          <div>
            <div
              className="fontPoppinsMediumSm  text-left flex common-text-color-in-request"
              onDragStart={props.handleDragStart}
            >
              {' '}
              <span className="common-auth-star-symbol">*</span> Hawk Auth Key
            </div>
            <MyInput
              canCreateVariable={isEditable}
              type="text"
              placeholder="Enter hawk auth key"
              variableInput={true}
              className="input-style-for-auth2-common"
              inputValue={'input'}
              error={formik.errors.authKey && formik.touched.authKey}
              value={authKey ? authKey : props.getUserInputValue(formik.values.authKey, true)}
              onMouseDown={formik.handleBlur}
              onChange={handleChange1}
              autoComplete="off"
              name="authKey"
              helperText={formik.touched.authKey && formik.errors.authKey}
              currentValue={context.values.currentHawkValue?.authKey}
              {...props}
            />
          </div>
          <div className="gap-4 flex">
            <FormLabel
              component="legend"
              className="fontPoppinsMediumSm  text-left flex common-text-color-in-request mt-5"
            >
              <span className="common-auth-star-symbol">*</span>Algorithm
            </FormLabel>

            <RadioGroup
              value={formik.values.algorithm}
              onChange={handleChange1}
              autoComplete="off"
              name="algorithm"
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <FormControlLabel
                value="SHA256"
                className={` mr-4 ${classes.smallRadioButton}`}
                control={<BlueRadio className={` ${classes.customRadioButton}`} />}
                label="SHA256"
              />
              <FormControlLabel
                value="SHA1"
                className={` mr-4 ${classes.smallRadioButton}`}
                control={<BlueRadio className={` ${classes.customRadioButton}`} />}
                label="SHA1"
              />
            </RadioGroup>
          </div>
        </div>
        <h5 className="font-bold text-left relative top-3 advanced-text-style" onDragStart={props.handleDragStart}>
          Advanced
        </h5>

        <div className="borderStyle3"></div>
        <div>
          <div className="grid grid-cols-3 gap-8 ">
            <div>
              <div
                className="fontPoppinsMediumSm  text-left flex common-text-color-in-request"
                onDragStart={props.handleDragStart}
              >
                {' '}
                Username
              </div>
              <MyInput
                canCreateVariable={isEditable}
                type="text"
                placeholder="Enter username"
                variableInput={true}
                className="input-style-for-auth2-common"
                inputValue={'input'}
                value={userName ? userName : props.getUserInputValue(formik.values.userName, true)}
                onChange={handleChange1}
                autoComplete="off"
                name="userName"
                currentValue={context.values.currentHawkValue?.userName}
                {...props}
              />
            </div>
            <div>
              <div
                className="fontPoppinsMediumSm  text-left flex common-text-color-in-request"
                onDragStart={props.handleDragStart}
              >
                {' '}
                Nonce{' '}
                <CustomTooltip
                  title={<span className="fontPoppinsRegularXs">This is a random string generated by the client.</span>}
                  placement="right"
                >
                  <img
                    src={authIconHover?.Nonce ? InfoIconHover : Info}
                    id="Nonce"
                    onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                    onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                    className="ml-2 w-4 h-4 object-contain"
                    alt=""
                    height="15px"
                    width="15px"
                    draggable="false"
                  />
                </CustomTooltip>
              </div>
              <MyInput
                canCreateVariable={isEditable}
                type="text"
                placeholder="Enter nonce"
                variableInput={true}
                value={nonce ? nonce : props.getUserInputValue(formik.values.nonce, true)}
                className="input-style-for-auth2-common"
                inputValue={'input'}
                onChange={handleChange1}
                autoComplete="off"
                name="nonce"
                currentValue={context.values.currentHawkValue?.nonce}
                {...props}
              />
            </div>
            <div>
              <div
                className="fontPoppinsMediumSm  text-left flex common-text-color-in-request"
                onDragStart={props.handleDragStart}
              >
                {' '}
                Timestamp
              </div>
              <MyInput
                canCreateVariable={isEditable}
                type="text"
                placeholder="Enter timestamp"
                variableInput={true}
                className="input-style-for-auth2-common"
                inputValue={'input'}
                value={timeStamp ? timeStamp : props.getUserInputValue(formik.values.timeStamp, true)}
                onChange={handleChange1}
                autoComplete="off"
                name="timeStamp"
                currentValue={context.values.currentHawkValue?.timeStamp}
                {...props}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-8 ">
          <div>
            <div
              className="fontPoppinsMediumSm  text-left flex common-text-color-in-request"
              onDragStart={props.handleDragStart}
            >
              {' '}
              Extra{' '}
              <CustomTooltip
                title={
                  <span className="fontPoppinsRegularXs">
                    Any application-specific information to be sent with the request.
                  </span>
                }
                placement="bottom-start"
              >
                <img
                  src={authIconHover?.Extra ? InfoIconHover : Info}
                  id="Extra"
                  onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                  onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                  className="ml-2 w-4 h-4 object-contain"
                  alt=""
                  height="15px"
                  width="15px"
                  draggable="false"
                />
              </CustomTooltip>
            </div>
            <MyInput
              canCreateVariable={isEditable}
              type="text"
              placeholder="Enter Extra"
              variableInput={true}
              value={extra ? extra : props.getUserInputValue(formik.values.extra, true)}
              className="input-style-for-auth2-common"
              inputValue={'input'}
              onChange={handleChange1}
              autoComplete="off"
              name="extra"
              currentValue={context.values.currentHawkValue?.extra}
              {...props}
            />
          </div>
          <div>
            <div
              className="fontPoppinsMediumSm  text-left flex common-text-color-in-request"
              onDragStart={props.handleDragStart}
            >
              {' '}
              Application ID
              <CustomTooltip
                title={
                  <span className="fontPoppinsRegularXs">
                    This provides binding between the credentials and the application in a way that prevents an attacker
                    from tricking an application to use credentials issued to someone else.
                  </span>
                }
                placement="bottom-start"
              >
                <img
                  src={authIconHover?.App_id ? InfoIconHover : Info}
                  id="App_id"
                  onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                  onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                  className="ml-2 w-4 h-4 object-contain"
                  alt=""
                  height="15px"
                  width="15px"
                  draggable="false"
                />
              </CustomTooltip>
            </div>
            <MyInput
              canCreateVariable={isEditable}
              type="text"
              placeholder="Enter application ID"
              variableInput={true}
              value={applicationId ? applicationId : props.getUserInputValue(formik.values.applicationID, true)}
              className="input-style-for-auth2-common"
              inputValue={'input'}
              onChange={handleChange1}
              autoComplete="off"
              name="applicationID"
              currentValue={context.values.currentHawkValue?.applicationID}
              {...props}
            />
          </div>
          <div>
            <div
              className="fontPoppinsMediumSm  text-left flex common-text-color-in-request"
              onDragStart={props.handleDragStart}
            >
              {' '}
              Delegated{' '}
              <CustomTooltip
                title={
                  <span className="fontPoppinsRegularXs">
                    This is the application id of the application to which the credentials- were directly issued to.
                  </span>
                }
                placement="right"
              >
                <img
                  src={authIconHover?.Deleted ? InfoIconHover : Info}
                  id="Deleted"
                  onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                  onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                  className="ml-2 w-4 h-4 object-contain"
                  alt=""
                  height="15px"
                  width="15px"
                  draggable="false"
                />
              </CustomTooltip>
            </div>
            <MyInput
              canCreateVariable={isEditable}
              type="text"
              placeholder="Enter Delegated"
              variableInput={true}
              className="input-style-for-auth2-common"
              inputValue={'input'}
              value={delegated ? delegated : props.getUserInputValue(formik.values.delegated, true)}
              onChange={handleChange1}
              autoComplete="off"
              name="delegated"
              currentValue={context.values.currentHawkValue?.delegated}
              {...props}
            />
          </div>
          <div className="flex">
            <FormControlLabel
              style={{ pointerEvents: 'none' }}
              control={
                <Checkbox
                  style={{ pointerEvents: 'auto' }}
                  onChange={handleChange1}
                  name="payloadhash"
                  color="primary"
                  checked={context?.values?.hawkData?.payloadhash}
                />
              }
              label={
                <Typography className=" fontPoppinsMediumSm  text-left flex common-text-color-in-request">
                  Include payload hash{' '}
                  <CustomTooltip
                    title={
                      <span className="fontPoppinsRegularXs">
                        Hawk authentication provides optional support for payload validation. If this option is
                        selected, the payload hash will be calculated and included in MAC calculation and in
                        authorization header.
                      </span>
                    }
                    placement="bottom-start"
                  >
                    <img
                      src={authIconHover?.payload_hash ? InfoIconHover : Info}
                      id="payload_hash"
                      onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                      onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                      className="ml-2 w-4 h-4 object-contain pointer-events-none"
                      alt=""
                      height="14px"
                      width="14px"
                      draggable="false"
                    />
                  </CustomTooltip>
                </Typography>
              }
              clickable={false}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

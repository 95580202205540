import React, { useEffect, useState, Fragment } from 'react';
import { Radio, RadioGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import style from '@pages/common/Inputs/inputs.module.scss';
import cx from 'classnames';
import { DASHBOARD_CONSTANTS } from '@src/common/ui-constants';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { getAllUesrsDashBoard, createDashBoard, updateDashboard } from '@src/api/api_services';
import { getCurrentProjectId } from '@util/common_utils';
import { Drawer, Select, Input, TextArea } from 'fireflink-ui';
import './dashBoard.scss';
import { useHistory } from 'react-router-dom';
import SearchBar from '@src/pages/analytics/common/search-bar';
import { HighlightText, isEmptyValue } from '@src/util/common_utils';
import NoResultsFound from '@src/pages/common/Table/NoResultFound';

const validationSchema = yup.object({
  dashboardName: yup
    .string()
    .matches(/^[^\s].*?(?<!\s)$/, 'Space is not allowed at starting and at the end')
    .matches(/^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/, 'Name should be alphanumeric')
    .min(3, 'Name must be at least 3 characters')
    .max(25, 'Name must not exceed 25 characters')
    .required('Name is required'),
});
const CreateEditDashboard = (props) => {
  const MenuProps = {
    classes: {
      root: cx(style['select-box-menu-list-wrapper']),
    },
  };
  const [selectedOption, setSelectedOption] = useState(
    props?.actionType === 'Edit' ? props.selectedDashBoard?.permission : 'Select Users'
  );
  const [selectedValues, setSelectedValues] = useState([]);
  const [isFieldChanged, setIsFieldChanged] = useState(false);
  const [accessType, setAccessType] = useState('');
  const [dataLoading, setdataLoading] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [userSearched, setuserSearched] = useState('');
  let [isHighlight, setIsHighlight] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');
  const initialValues = {
    dashboardName: props.selectedDashBoard?.name || '',
    description: props.selectedDashBoard?.description || '',
  };
  let permissionNameArray = [
    {
      role: 'NoAccess',
    },
    {
      role: 'View',
    },
    {
      role: 'Edit',
    },
    {
      role: 'FullAccess',
    },
  ];
  let optionsdata = ['NoAccess', 'View', 'Edit', 'FullAccess'];
  const [usersList, setUsersList] = useState([]);
  const [updatePayload, setUpdatePayload] = useState(props?.selectedDashBoard?.users);

  useEffect(() => {
    if (props?.selectedDashBoard.permission === 'All Users') {
      setAccessType(props?.selectedDashBoard?.allUsersAccess);
    }
    setdataLoading(true);
  }, []);

  useEffect(() => {
    const getUsers = async () => {
      let params = 'dashBoard';
      try {
        const response = await getAllUesrsDashBoard(projectId, params);
        if (response?.data?.responseCode === 200 && response?.data.message === 'SUCCESS') {
          setUsersList(response.data.responseObject);
        }
      } catch (error) {
        console.error('getAllUesrsDashBoard error:', error);
      }
    };
    getUsers();
  }, []);

  useEffect(() => {
    const defaultValues = usersList?.map((user) => ({
      name: user.name,
      userId: user.id,
      access: 'NoAccess',
    }));
    setSelectedValues(defaultValues);
  }, [usersList]);
  const handChangeAllUserOption = (option) => {
    setAccessType(option);
    const defaultValues = usersList?.map((user) => ({
      name: user.name,
      userId: user.id,
      access: option,
    }));
    setSelectedValues(defaultValues);
    if (props.actionType === 'Edit') {
      const defaultValues = updatePayload?.map((user) => ({
        name: user.name,
        userId: user.userId,
        access: option,
      }));
      setUpdatePayload(defaultValues);
    }
  };
  const handleSelectRadioChange = (indx, value) => {
    const updatedSelectedValues = [...updatePayload];
    updatedSelectedValues[indx] = {
      name: updatePayload[indx]?.name,
      userId: updatePayload[indx]?.userId,
      access: value,
    };
    setUpdatePayload(updatedSelectedValues);
  };
  const handleRadioChange = (indx, value) => {
    const updatedSelectedValues = [...selectedValues];
    updatedSelectedValues[indx] = {
      name: usersList[indx].name,
      userId: usersList[indx].id,
      access: value,
    };
    setSelectedValues(updatedSelectedValues);
  };
  const optionArray = [
    {
      name: 'All Users',
    },
    {
      name: 'Select Users',
    },
  ];
  const handlePermission = (event) => {
    setSelectedOption(event.value);
    if (event.value === props?.selectedDashBoard.permission) {
      setUpdatePayload(props?.selectedDashBoard?.users);
    } else {
      const defaultValues = usersList?.map((user) => ({
        name: user.name,
        userId: user.id,
        access: 'NoAccess',
      }));
      setUpdatePayload(defaultValues);
      if (event.value === 'All Users') {
        setAccessType('NoAccess');
      }
    }
  };
  const hasAllUsersPermission = () => {
    return (
      selectedOption === 'All Users' &&
      props.selectedDashBoard?.permission === 'All Users' &&
      accessType !== props?.selectedDashBoard?.allUsersAccess
    );
  };

  const checkHasSamevalue = () => {
    if (
      formikDetails.values.dashboardName !== props.selectedDashBoard.name ||
      formikDetails.values.description !== props.selectedDashBoard?.description ||
      selectedOption !== props.selectedDashBoard?.permission ||
      JSON.stringify(props.selectedDashBoard.users) !== JSON.stringify(updatePayload)
    ) {
      return true;
    } else {
      let hasPermission = hasAllUsersPermission();
      return hasPermission;
    }
  };
  const formikDetails = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      if (props.actionType !== 'Edit') {
        handleCreateDashBoard();
      } else {
        if (checkHasSamevalue()) {
          handleUpdateDashBoard();
        } else {
          props.MyAlert.info('Nothing there to update');
          props?.onClose();
        }
      }
    },
  });
  const handleRightButtonClick = () => {
    formikDetails.handleSubmit();
  };
  const history = useHistory();
  const projectId = getCurrentProjectId();
  const maxCharCount = 200;
  const handleCreateDashBoard = () => {
    let payload = {
      name: formikDetails?.values?.dashboardName,
      projectId: projectId,
      users: selectedValues,
      description: formikDetails.values.description,
      permission: selectedOption,
    };
    if (selectedOption === 'All Users') {
      payload.allUsersAccess = accessType;
    }
    createDashBoard(payload).then((response) => {
      if (response?.data?.status === 'SUCCESS') {
        props?.onClose();
        props.reloadTree();
        props.MyAlert.success(response.data.message);
        history.push(
          `/dashboard/custom-dashboard/${response?.data?.responseObject?.name}/customiseGraph?id=${response?.data?.responseObject?.id}&name=${response?.data?.responseObject?.name}&access=FullAccess &message=${response?.data?.message}`
        );
      } else {
        formikDetails.setFieldError('dashboardName', response.data.message);
      }
    });
  };
  const handleUpdateDashBoard = () => {
    let payload = {
      name: formikDetails?.values?.dashboardName,
      projectId: projectId,
      users: updatePayload,
      description: formikDetails?.values?.description,
      permission: selectedOption,
    };
    if (selectedOption === 'All Users') {
      payload.allUsersAccess = accessType;
    }
    const id = props?.selectedDashBoard?.id;
    updateDashboard(id, payload).then((response) => {
      if (response?.data?.status === 'SUCCESS') {
        props.onClose();
        props.reloadTree();
        props.MyAlert.success(response.data.message);
      } else {
        formikDetails.setFieldError('dashboardName', response.data.message);
      }
    });
  };
  const primaryButtonProps = {
    label: props.actionType === 'Edit' ? DASHBOARD_CONSTANTS.UPDATE : DASHBOARD_CONSTANTS.CREATE,
    disabled: false,
    onClick: () => {
      handleRightButtonClick();
    },
  };
  const secondaryButtonProps = {
    label: DASHBOARD_CONSTANTS.CANCEL,
    onClick: props.onClose,
  };

  const handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setSearchTextHighlight('');
    setIsHighlight(!isHighlight);
  };

  const handleSearchOpen = () => {
    setOpenSearch(true);
  };
  const searchData = props.actionType === 'Edit' ? updatePayload : usersList;
  return (
    <Drawer
      isOpen={true}
      onClose={props.onClose}
      overlay={true}
      title={props.actionType === 'Edit' ? DASHBOARD_CONSTANTS.EDIT_DASHBOARD : DASHBOARD_CONSTANTS.CREATE_DASHBOARD}
      primaryButtonProps={primaryButtonProps}
      secondaryButtonProps={secondaryButtonProps}
    >
      {dataLoading && (
        <section className="create-dashboard-box relative search-dashBoard">
          <div className="flex flex-col w-full">
            <div className="fontPoppinsMediumSm integration-label ">
              {
                <>
                  {' '}
                  <span className="text-red-600">*</span> {DASHBOARD_CONSTANTS.NAME}
                </>
              }{' '}
            </div>
            <div className="w-full textFieldDashboard custom-dashBoard-input-field">
              <Input
                name="dashboardName"
                autoComplete="off"
                placeholder="Enter Name"
                id="dashboardName"
                value={formikDetails.values.dashboardName}
                onChange={formikDetails.handleChange}
                onBlur={formikDetails.handleBlur}
                variant={
                  formikDetails.touched.dashboardName && formikDetails.errors.dashboardName ? 'danger' : 'default'
                }
                error={formikDetails.touched.dashboardName && formikDetails.errors.dashboardName}
                helperText={
                  formikDetails.touched.dashboardName && formikDetails.errors.dashboardName
                    ? formikDetails.errors.dashboardName
                    : ''
                }
              />
            </div>
          </div>
          <div className=" w-full flex flex-col mt-4 ">
            <div className="dashboardDescription">
              <TextArea
                className=" "
                value={formikDetails.values.description}
                onChange={formikDetails.handleChange}
                id="description"
                name="description"
                labelProps={{
                  label: DASHBOARD_CONSTANTS.DESCRIPTION,
                }}
                placeholder="Your Description Goes Here......"
                variant="default"
                capacity="200"
              />
            </div>
          </div>
          <div className="w-full flex flex-col ">
            <label className=" fontPoppinsMediumSm  integration-label">
              {' '}
              {DASHBOARD_CONSTANTS.DASHBOARD_PERMISSION}
            </label>
            <div className="w-full ">
              <Select
                name="permission"
                onChange={(e) => handlePermission(e)}
                options={optionArray.map((data) => ({
                  label: data.name,
                  value: data.name,
                }))}
                selectedOption={{
                  label: selectedOption,
                  value: selectedOption,
                }}
                className="fontPoppinsRegularSm"
              />
            </div>
          </div>
          {selectedOption === 'Select Users' && (
            <div className="mt-2">
              <SearchBar
                open={openSearch}
                searchValue={userSearched || ''}
                handleSearch={(e) => {
                  setuserSearched(e.target.value);
                }}
                onSearchClose={handleSearchClose}
                handleSearchOpen={handleSearchOpen}
                disable={isEmptyValue(searchData)}
              />
            </div>
          )}

          <div className=" w-full">
            {selectedOption === 'All Users' ? (
              <div className="label-margin ml-3 mr-11 w-full">
                <FormControl>
                  <RadioGroup row defaultValue={props.actionType === 'Edit' ? accessType : 'NoAccess'}>
                    <FormControlLabel
                      value="NoAccess"
                      onChange={() => {
                        handChangeAllUserOption('NoAccess');
                        setIsFieldChanged(true);
                      }}
                      control={<Radio className="dashboard-radio" />}
                      label={
                        <span className="fontPoppinsRegularSm selected-value-field">
                          {DASHBOARD_CONSTANTS.NO_ACCESS}
                        </span>
                      }
                    />
                    <FormControlLabel
                      value="View"
                      onChange={() => {
                        handChangeAllUserOption('View');
                        setIsFieldChanged(true);
                      }}
                      control={<Radio className="dashboard-radio" />}
                      label={
                        <span className="fontPoppinsRegularSm selected-value-field">{DASHBOARD_CONSTANTS.VIEW}</span>
                      }
                    />
                    <FormControlLabel
                      value="Edit"
                      onChange={() => {
                        handChangeAllUserOption('Edit');
                        setIsFieldChanged(true);
                      }}
                      control={<Radio className="dashboard-radio" />}
                      label={
                        <span className="fontPoppinsRegularSm selected-value-field">{DASHBOARD_CONSTANTS.EDIT}</span>
                      }
                    />
                    <FormControlLabel
                      value="FullAccess"
                      onChange={() => {
                        handChangeAllUserOption('FullAccess');
                        setIsFieldChanged(true);
                      }}
                      control={<Radio className="dashboard-radio" />}
                      label={
                        <span className="fontPoppinsRegularSm selected-value-field">
                          {DASHBOARD_CONSTANTS.FULL_ACCESS}
                        </span>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            ) : (
              selectedOption === 'Select Users' && (
                <div className="select-user-tbox journal-scroll mt-2 dashBoard-access ">
                  <div
                    className="create-dashboard-theader flex flex-row project-details-header   sticky"
                    style={{ background: '#f0f3f9', height: '35px' }}
                  >
                    <label className="uppercase fontPoppinsMediumSm section_label_width my-2 mr-4 ml-3 project-details-header">
                      {DASHBOARD_CONSTANTS.NAME}
                    </label>
                    {permissionNameArray.map((perVal, perindx) => {
                      return (
                        <label
                          className={`uppercase fontPoppinsMediumSm px-4 my-2 ml-2 ${
                            perVal.role === 'View' ? '-ml-4 ' : perVal.role === 'Edit' ? '-mr-1' : ''
                          } `}
                        >
                          {perVal.role}
                        </label>
                      );
                    })}
                  </div>
                  <div className="journal-scroll radio_groups_style">
                    {searchData
                      .filter((val) => val.name.includes(userSearched))
                      .map((val, indx) => (
                        <div key={indx} className="grid grid-rows-1 grid-flow-col table-content-height relative">
                          <div className="label_style mt-2 ml-0.5 my-1 role-proj-permission-content">
                            <label className="fontPoppinsMediumSm" title={val.name.length >= 10 && val.name}>
                              <HighlightText
                                text={val.name.length >= 10 ? val.name.substring(0, 10) + '...' : val.name}
                                highlight={searchTextHighlight}
                              />
                            </label>
                          </div>
                          <RadioGroup
                            row
                            aria-label="position"
                            name={`position-${indx}`}
                            defaultValue={optionsdata?.includes(val.access) ? val.access : 'NoAccess'}
                            className="ml-32"
                          >
                            {optionsdata.map((radiotxt, optindex) => (
                              <div key={optindex} className="pr-4">
                                <FormControlLabel
                                  control={
                                    <Radio
                                      name={val.value}
                                      id={`${indx}-${radiotxt}-${optindex}`}
                                      label={radiotxt}
                                      className="cursor-pointer dashboard-radio"
                                      value={radiotxt}
                                      onChange={(e) => {
                                        const handleChange =
                                          props.actionType === 'Edit' ? handleSelectRadioChange : handleRadioChange;
                                        handleChange(indx, e.target.value);
                                        setIsFieldChanged(true);
                                      }}
                                    />
                                  }
                                />
                              </div>
                            ))}
                          </RadioGroup>
                        </div>
                      ))}
                  </div>
                  {userSearched && searchData.filter((val) => val.name.includes(userSearched)).length === 0 && (
                    <NoResultsFound className="no-result-found" />
                  )}
                </div>
              )
            )}
          </div>
        </section>
      )}
    </Drawer>
  );
};
export default CreateEditDashboard;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';
import { useAlert } from '../../common/alert_service/useAlert';
import Breadcrumbs from '../../common/breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';
import '../../common/dropdown.css';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { getWaitConfigReq, putWaitConfigReq } from '@api/api_services';
import ConfigMemoryStyle from '@pages/configuration/assests/styles/configmemory.module.scss';
import cx from 'classnames';
import { UI_VALIDATIONS } from '@src/util/validations';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),

    minWidth: 120,
    marginTop: '15px',
  },
  MuiSelectSelect: {
    borderRadius: 0,
    backgroundColor: 'white',
  },
}));

const WaitConfigRouter = (props) => {
  const classes = useStyles();
  const [values, setValues] = useState({});
  const [userId, setUserId] = useState('');
  const [ExplicitWaitvalues, setExplicitWaitValues] = useState('');
  const [ImplicitWaitvalues, setImplicitWaitValues] = useState('');
  const [DelayBetweenSteps, setDelayBetweenStepsValues] = useState('');
  const [disablebtn, setDisableBtn] = useState(true);
  const [expUnit, setExpUnit] = useState('');
  const [impUnit, setImpUnit] = useState('');
  const [dbsUnit, setDbsUnit] = useState('');
  const [disableCancelbtn, setDisableCancelBtn] = useState(true);
  const [oldExpValue, setOldExpValue] = useState(-1);
  const [oldImpValue, setOldImpValue] = useState(-1);
  const [oldDbsValue, setOldDbsValue] = useState(-1);
  const [oldExpUnit, setoldExpUnit] = useState(-1);
  const [oldImpUnit, setoldImpUnit] = useState(-1);
  const [oldDbsUnit, setoldDbsUnit] = useState(-1);
  const [errorMsgImplicit, setErrorMsgImplicit] = useState('');
  const [errorMsgExplicit, setErrorMsgExplicit] = useState('');
  const [errorMsgExplicitOne, setErrorMsgExplicitOne] = useState('');
  const [errorMsgImplicitOne, setErrorMsgImplicitOne] = useState('');
  const [errorMsgDelay, setErrorMsgDelay] = useState('');
  const [errorMsgDelayLength, setErrorMsgDelayLength] = useState('');
  const [explicitError, setExplicitError] = useState(false);
  const [implicitError, setImplicitError] = useState(false);
  const [delayBtnError, setDelayBtnError] = useState(false);

  const [explicitbutton, setExplicitbutton] = useState({
    decrement: false,
    increment: false,
  });
  const [implicitbutton, setImplicitbutton] = useState({
    decrement: false,
    increment: false,
  });
  const [dbsbutton, setDbsButton] = useState({
    decrement: false,
    increment: false,
  });

  const explicitHandleBlur = (event) => {
    if (event.target.value) {
      if (Number(event.target.value) === 0) {
        setExplicitbutton({ decrement: true, increment: false });
      } else {
        if (
          (expUnit === 'seconds' && Number(event.target.value) >= 999) ||
          (expUnit === 'milliseconds' && Number(event.target.value) >= 999000)
        ) {
          setExplicitbutton({ decrement: false, increment: true });
        } else {
          setExplicitbutton({ decrement: false, increment: false });
        }
      }
    } else {
      setErrorMsgExplicit('explicit wait is required');
      setExplicitError(true);
      setExplicitbutton({ decrement: true, increment: false });
    }
  };
  const implicitHandleBlur = (event) => {
    if (event.target.value) {
      if (Number(event.target.value) === 0) {
        setImplicitbutton({ decrement: true, increment: false });
      } else {
        if (
          (impUnit === 'seconds' && Number(event.target.value) >= 999) ||
          (impUnit === 'milliseconds' && Number(event.target.value) >= 999000)
        ) {
          setImplicitbutton({ decrement: false, increment: true });
        } else {
          setImplicitbutton({ decrement: false, increment: false });
        }
      }
    } else {
      setErrorMsgImplicit('implicit wait is required');
      setImplicitError(true);
      setImplicitbutton({ decrement: true, increment: false });
    }
  };

  const bdsHandleBlur = (event) => {
    if (event.target.value) {
      if (Number(event.target.value) === 0) {
        setDbsButton({ decrement: true, increment: false });
      } else {
        if (
          (dbsUnit === 'seconds' && Number(event.target.value) >= 99) ||
          (dbsUnit === 'milliseconds' && Number(event.target.value) >= 99000)
        ) {
          setDbsButton({ decrement: false, increment: true });
        } else {
          setDbsButton({ decrement: false, increment: false });
        }
      }
    } else {
      setErrorMsgDelayLength('delay between steps is required');
      setDelayBtnError(true);
      setDbsButton({ decrement: true, increment: false });
    }
  };
  const handleChange = (event) => {
    setExpUnit(event.target.value);
    setExplicitWaitValues(UI_VALIDATIONS.WAIT_TIME_CONFIGURATION);
    setExplicitbutton({ decrement: true, increment: false });
    setDisableBtn(false);
    setDisableCancelBtn(false);
    setErrorMsgExplicit('');
  };

  const ImphandleChange = (event) => {
    setImpUnit(event.target.value);
    setImplicitWaitValues(UI_VALIDATIONS.WAIT_TIME_CONFIGURATION);
    setImplicitbutton({ decrement: true, increment: false });
    setDisableBtn(false);
    setDisableCancelBtn(false);
    setErrorMsgImplicit('');
  };

  const DbhandleChange = (event) => {
    setDbsUnit(event.target.value);
    setDelayBetweenStepsValues(0);
    setDbsButton({ decrement: true, increment: false });
    setDisableBtn(false);
    setDisableCancelBtn(false);
    setErrorMsgDelayLength('');
  };

  const updateVal = (e) => {
    setDisableCancelBtn(true);
    setDisableBtn(true);
    localStorage.setItem('configPutReq', true);
    let data = {
      explicitWait: ExplicitWaitvalues,
      implicitWait: ImplicitWaitvalues,
      delayBetweenSteps: DelayBetweenSteps,
      id: userId,
      explicitWaitUnit: expUnit,
      implicitWaitUnit: impUnit,
      delayBetweenStepsUnit: dbsUnit,
    };
    if (
      Number(ImplicitWaitvalues) === Number(oldImpValue) &&
      Number(ExplicitWaitvalues) === Number(oldExpValue) &&
      Number(DelayBetweenSteps) === Number(oldDbsValue) &&
      oldExpUnit === expUnit &&
      oldImpUnit === impUnit &&
      oldDbsUnit === dbsUnit
    ) {
      localStorage.setItem('configPutReq', false);
      MyAlert.info(`No changes were done to wait configuration. `);
      return;
    }
    putWaitConfigReq(userId, data)
      .then((results) => {
        setOldExpValue(ExplicitWaitvalues);
        setoldExpUnit(expUnit);
        setoldImpUnit(impUnit);
        setoldDbsUnit(dbsUnit);
        setOldImpValue(ImplicitWaitvalues);
        setOldDbsValue(DelayBetweenSteps);
        localStorage.setItem('configPutReq', false);
        localStorage.setItem('data', ExplicitWaitvalues);

        let ExpData = localStorage.getItem('data');
        if (results.data.responseCode === 200) {
          MyAlert.success(`Wait configuration updated successfully`);
        } else if (
          results.data.responseCode === 400 &&
          results.data.message === 'explicitWait must be greater then equal to 0,'
        ) {
          MyAlert.warning(`${results.data.message}`);
        } else if (
          results.data.responseCode === 400 &&
          results.data.message === 'implicitWait must be greater then equal to 0,'
        ) {
          MyAlert.warning(`${results.data.message}`);
        } else if (
          results.data.responseCode === 400 &&
          results.data.message === 'delayBetweenSteps must be greater then equal to 0,'
        ) {
          MyAlert.warning(`${results.data.message}`);
        } else if (
          results.data.responseCode === 400 &&
          results.data.message ===
            'explicitWait must be greater then equal to 0,implicitWait must be greater then equal to 0,'
        ) {
          MyAlert.warning(`${results.data.message}`);
        } else if (
          results.data.responseCode === 400 &&
          results.data.message ===
            'explicitWait must be greater then equal to 0,delayBetweenSteps must be greater then equal to 0,'
        ) {
          MyAlert.warning(`${results.data.message}`);
        } else if (
          results.data.responseCode === 400 &&
          results.data.message ===
            'implicitWait must be greater then equal to 0,delayBetweenSteps must be greater then equal to 0,'
        ) {
          MyAlert.warning(`${results.data.message}`);
        } else if (
          results.data.responseCode === 400 &&
          results.data.message ===
            'explicitWait must be greater then equal to 0,implicitWait must be greater then equal to 0,delayBetweenSteps must be greater then equal to 0,'
        ) {
          MyAlert.warning(`${results.data.message}`);
        } else if (results.data.responseCode === 400 && results.data.message === 'explicitWait is mandatory,') {
          MyAlert.warning(`${results.data.message}`);
        } else if (results.data.responseCode === 400 && results.data.message === 'implicitWait is mandatory,') {
          MyAlert.warning(`${results.data.message}`);
        } else if (results.data.responseCode === 400) {
          MyAlert.warning(`${results.data.message}`);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    if (
      Number(ImplicitWaitvalues) === Number(oldImpValue) &&
      Number(ExplicitWaitvalues) === Number(oldExpValue) &&
      Number(DelayBetweenSteps) === Number(oldDbsValue) &&
      expUnit === oldExpValue &&
      impUnit === oldImpValue &&
      dbsUnit === oldDbsValue
    ) {
      localStorage.setItem('configPutReq', false);
      MyAlert.info(`No changes were done to wait configuration. `);
      return;
    }
    let projectId = JSON.parse(localStorage.getItem('selected-project')).id;
    if (projectId) {
      putWaitConfigReq(userId, data, projectId)
        .then((results) => {
          localStorage.setItem('configPutReq', false);
          if (results.data.responseCode === 200) {
            MyAlert.success(`Wait configuration updated successfully`);
          } else if (
            results.data.responseCode === 400 &&
            results.data.message === 'explicitWait must be greater then equal to 0,'
          ) {
            MyAlert.warning(`${results.data.message}`);
          } else if (
            results.data.responseCode === 400 &&
            results.data.message === 'implicitWait must be greater then equal to 0,'
          ) {
            MyAlert.warning(`${results.data.message}`);
          } else if (
            results.data.responseCode === 400 &&
            results.data.message === 'delayBetweenSteps must be greater then equal to 0,'
          ) {
            MyAlert.warning(`${results.data.message}`);
          } else if (
            results.data.responseCode === 400 &&
            results.data.message ===
              'explicitWait must be greater then equal to 0,implicitWait must be greater then equal to 0,'
          ) {
            MyAlert.warning(`${results.data.message}`);
          } else if (
            results.data.responseCode === 400 &&
            results.data.message ===
              'explicitWait must be greater then equal to 0,delayBetweenSteps must be greater then equal to 0,'
          ) {
            MyAlert.warning(`${results.data.message}`);
          } else if (
            results.data.responseCode === 400 &&
            results.data.message ===
              'implicitWait must be greater then equal to 0,delayBetweenSteps must be greater then equal to 0,'
          ) {
            MyAlert.warning(`${results.data.message}`);
          } else if (
            results.data.responseCode === 400 &&
            results.data.message ===
              'explicitWait must be greater then equal to 0,implicitWait must be greater then equal to 0,delayBetweenSteps must be greater then equal to 0,'
          ) {
            MyAlert.warning(`${results.data.message}`);
          } else if (results.data.responseCode === 400 && results.data.message === 'explicitWait is mandatory,') {
            MyAlert.warning(`${results.data.message}`);
          } else if (results.data.responseCode === 400 && results.data.message === 'implicitWait is mandatory,') {
            MyAlert.warning(`${results.data.message}`);
          } else if (results.data.responseCode === 400) {
            MyAlert.warning(`${results.data.message}`);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  var ExplicitWaitData = document.getElementById('ExplicitWait')?.value;
  const handelChange = (e) => {
    setExplicitWaitValues(e.target.value);
    let ExplicitWait = document.getElementById('ExplicitWait').value;
    var regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;
    let regExp_alpha = /^[a-zA-Z]+$/;
    if (regex_symbols.test(ExplicitWait)) {
      setExplicitWaitValues(ExplicitWaitvalues);
      setDisableBtn(true);
      setDisableCancelBtn(true);
    }
    if (regExp_alpha.test(ExplicitWait)) {
      setExplicitWaitValues(ExplicitWaitvalues);
    }
    const value = e.target.value.replace(/\D/g, '');
    setExplicitWaitValues(value);
    if (expUnit === 'seconds' && ExplicitWait > 999) {
      setErrorMsgExplicit('Value greater than 999 not allowed');
      setDisableBtn(true);
      setDisableCancelBtn(true);
      if (ExplicitWait > 999) {
        setExplicitError(true);
      } else {
        setExplicitError(false);
      }
    } else if (expUnit === 'milliseconds' && ExplicitWait > 999000) {
      setErrorMsgExplicit('Value greater than 999000 not allowed');
      setExplicitError(false);
    } else {
      setErrorMsgExplicit('');
      setDisableBtn(false);
      setDisableCancelBtn(false);
      setExplicitError(false);
    }
    if (ExplicitWait < 0) {
      setErrorMsgExplicit('');
      setErrorMsgExplicitOne('Value less than 0 not allowed');
      setExplicitError(false);
    } else {
      setErrorMsgExplicitOne('');
      if ((expUnit === 'seconds' && ExplicitWait > 999) || (expUnit === 'milliseconds' && ExplicitWait > 999000)) {
        setExplicitError(true);
      } else {
        setExplicitError(false);
      }
    }
  };

  const handelImplicitChange = (e) => {
    setImplicitWaitValues(e.target.value);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let ImplicitWait = document.getElementById('ImplicitWait').value;
    var regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;
    if (regex_symbols.test(ImplicitWait)) {
      setImplicitWaitValues(ImplicitWaitvalues);
      setDisableBtn(true);
      setDisableCancelBtn(true);
    }
    const value = e.target.value.replace(/\D/g, '');
    setImplicitWaitValues(value);
    if (impUnit === 'seconds' && ImplicitWait > 999) {
      setErrorMsgImplicit('value greater than 999 not allowed');
      if (ImplicitWait > 999) {
        setImplicitError(true);
      } else {
        setImplicitError(false);
      }
    } else if (impUnit === 'milliseconds' && ImplicitWait > 999000) {
      setErrorMsgImplicit('value greater than 999000 not allowed');
      setImplicitError(false);
    } else {
      setErrorMsgImplicit('');
      setDisableBtn(false);
      setDisableCancelBtn(false);
      setImplicitError(false);
    }
    if (ImplicitWait < 0) {
      setErrorMsgImplicitOne('Value less than 0 not allowed');
      setImplicitError(false);
    } else {
      setErrorMsgImplicitOne('');
      if ((impUnit === 'seconds' && ImplicitWait > 999) || (impUnit === 'milliseconds' && ImplicitWait > 999000)) {
        setImplicitError(true);
      } else {
        setImplicitError(false);
      }
    }
  };
  const handelDBSChange = (e) => {
    setDelayBetweenStepsValues(e.target.value);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let DelayBetweenStep = document.getElementById('dbsValue').value;
    var regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;
    if (regex_symbols.test(DelayBetweenStep)) {
      setDelayBetweenStepsValues(DelayBetweenSteps);
      setDisableBtn(true);
      setDisableCancelBtn(true);
    }
    if (dbsUnit === 'seconds' && DelayBetweenStep > 99) {
      setErrorMsgDelayLength('Value greater than 99 not allowed');
      if (DelayBetweenStep > 20) {
        setDelayBtnError(true);
      } else {
        setDelayBtnError(false);
      }
    } else if (dbsUnit === 'milliseconds' && DelayBetweenStep > 99000) {
      setErrorMsgDelayLength('Value greater than 99000 not allowed');
      setDelayBtnError(false);
    } else {
      setErrorMsgDelayLength('');
      setDisableBtn(false);
      setDisableCancelBtn(false);
      setDelayBtnError(false);
    }
    const value = e.target.value.replace(/\D/g, '');
    setDelayBetweenStepsValues(value);
    if (DelayBetweenStep < 0) {
      setErrorMsgDelay('Value less than 0 not allowed');
      setDelayBtnError(false);
    } else {
      setErrorMsgDelay('');
      if (
        (dbsUnit === 'seconds' && DelayBetweenStep > 99) ||
        (dbsUnit === 'milliseconds' && DelayBetweenStep > 99000)
      ) {
        setDelayBtnError(true);
      } else {
        setDelayBtnError(false);
      }
    }
  };

  const { AlertContatiner, MyAlert } = useAlert();
  let activeTab = props.location.pathname.split('/')[3];

  const [tabsList, setTabsList] = useState([
    {
      name: 'Wait Configuration',
      pathUrl: `/configuration/RunConfiguration/Waitconfiguration`,
      selectedName: 'Waitconfiguration',
    },

    // {
    //   "name": "History",
    //   "pathUrl": `/configuration/RunConfiguration/History`,
    //   "selectedName": "history"
    // }
  ]);

  let projectId = JSON.parse(localStorage.getItem('selected-project')).id;
  const handleWaitTimebtn = (data) => {
    if (data?.delayBetweenSteps === 0 && ['milliseconds', 'seconds'].includes(data?.delayBetweenStepsUnit)) {
      setDbsButton({ decrement: true, increment: false });
    } else if (
      (data?.delayBetweenStepsUnit === 'milliseconds' && data?.delayBetweenSteps === 99000) ||
      (data?.delayBetweenStepsUnit === 'seconds' && data?.delayBetweenSteps === 99)
    ) {
      setDbsButton({ decrement: false, increment: true });
    } else {
      setDbsButton({ decrement: false, increment: false });
    }
    if (data?.implicitWait === 0 && ['milliseconds', 'seconds'].includes(data?.implicitWaitUnit)) {
      setImplicitbutton({ decrement: true, increment: false });
    } else if (
      (data?.implicitWaitUnit === 'milliseconds' && data?.implicitWait === 999000) ||
      (data?.implicitWaitUnit === 'seconds' && data?.implicitWait === 999)
    ) {
      setImplicitbutton({ decrement: false, increment: true });
    } else {
      setImplicitbutton({ decrement: false, increment: false });
    }
    if (data?.explicitWait === 0 && ['milliseconds', 'seconds'].includes(data?.explicitWaitUnit)) {
      setExplicitbutton({ decrement: true, increment: false });
    } else if (
      (data?.explicitWaitUnit === 'milliseconds' && data?.explicitWait === 999000) ||
      (data?.explicitWaitUnit === 'seconds' && data?.explicitWait === 999)
    ) {
      setExplicitbutton({ decrement: false, increment: true });
    } else {
      setExplicitbutton({ decrement: false, increment: false });
    }
  };
  React.useEffect(() => {
    const search = window.location.search;
    let projectId = new URLSearchParams(search).get('id') || JSON.parse(localStorage.getItem('selected-project'));

    if (new URLSearchParams(search).get('id')) {
      projectId = new URLSearchParams(search).get('id');
    } else {
      projectId = JSON.parse(localStorage.getItem('selected-project')).id;
    }

    if (projectId) {
      getWaitConfigReq(projectId).then((results) => {
        localStorage.setItem('configReq', false);
        if (results && results.data && results.data.responseObject) {
          setValues(results.data.responseObject);
          setUserId(results.data.responseObject.id);
          setExplicitWaitValues(results.data.responseObject.explicitWait);
          setImplicitWaitValues(results.data.responseObject.implicitWait);
          setDelayBetweenStepsValues(results.data.responseObject.delayBetweenSteps);
          setExpUnit(results.data.responseObject.explicitWaitUnit);
          setImpUnit(results.data.responseObject.implicitWaitUnit);
          setDbsUnit(results.data.responseObject.delayBetweenStepsUnit);
          setOldExpValue(results?.data?.responseObject?.explicitWait);
          setoldExpUnit(results?.data?.responseObject?.explicitWaitUnit);
          setoldImpUnit(results?.data?.responseObject?.implicitWaitUnit);
          setoldDbsUnit(results?.data?.responseObject?.delayBetweenStepsUnit);
          setOldImpValue(results?.data?.responseObject?.implicitWait);
          setOldDbsValue(results?.data?.responseObject?.delayBetweenSteps);
          handleWaitTimebtn(results?.data?.responseObject);
        }
      });
    } else {
      localStorage.setItem('configReq', true);
      getWaitConfigReq().then((results) => {
        localStorage.setItem('configReq', false);
        setValues(results?.data?.responseObject);
        setUserId(results?.data?.responseObject?.id);
        setExplicitWaitValues(results?.data?.responseObject?.explicitWait);
        setImplicitWaitValues(results?.data?.responseObject?.implicitWait);
        setDelayBetweenStepsValues(results?.data?.responseObject?.delayBetweenSteps);
        setExpUnit(results?.data?.responseObject?.explicitWaitUnit);
        setImpUnit(results.data?.responseObject?.implicitWaitUnit);
        setDbsUnit(results?.data?.responseObject?.delayBetweenStepsUnit);
        setOldExpValue(results?.data?.responseObject?.explicitWait);
        setoldExpUnit(results?.data?.responseObject?.explicitWaitUnit);
        setoldImpUnit(results?.data?.responseObject?.implicitWaitUnit);
        setoldDbsUnit(results?.data?.responseObject?.delayBetweenStepsUnit);
        setOldImpValue(results?.data?.responseObject?.implicitWait);
        setOldDbsValue(results?.data?.responseObject?.delayBetweenSteps);
        handleWaitTimebtn(results?.data?.responseObject);
      });
    }
  }, [projectId]);

  // individual update

  const renderTabs = () => {
    return (
      <div>
        <ul className="navBar text-blue-600 flex flex-row primary-tab">
          {tabsList.map((tab, index) => {
            return (
              <li key={tab.name}>
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    props.history.push(tab.pathUrl);
                  }}
                  className={`${activeTab === tab.selectedName ? 'selected_tab_style' : ''}`}
                >
                  {tab.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  let renderPage = (MyAlert) => {
    return (
      <Switch>
        <Route
          path="/configuration/environmentconfiguration/systemconfiguration"
          exact={true}
          strict={true}
          key="system-config"
        >
          <waitConfigPage MyAlert={MyAlert} />
        </Route>
        <Route path="/configuration/RunConfiguration" exact={true} strict={true} key="wait-config">
          <waitConfigPage MyAlert={MyAlert} />
        </Route>
        <Route path="/configuration" exact={true} strict={true} key="wait-config">
          <waitConfigPage MyAlert={MyAlert} />
        </Route>
      </Switch>
    );
  };

  const cancelValue = (e) => {
    setDisableBtn(true);
    setDisableCancelBtn(true);
    let projectId = JSON.parse(localStorage.getItem('selected-project')).id;
    if (projectId) {
      setExplicitWaitValues(oldExpValue);
      setExpUnit(oldExpUnit);
      setImplicitWaitValues(oldImpValue);
      setImpUnit(oldImpUnit);
      setDelayBetweenStepsValues(oldDbsValue);
      setDbsUnit(oldDbsUnit);
      setErrorMsgExplicit('');
      setErrorMsgExplicitOne('');
      setErrorMsgImplicit('');
      setErrorMsgImplicitOne('');
      setErrorMsgDelayLength('');
      setErrorMsgDelay('');
    } else {
      localStorage.setItem('configReq', true);
      setExplicitWaitValues(oldExpValue);
      setExpUnit(oldExpUnit);
      setImplicitWaitValues(oldImpValue);
      setImpUnit(oldImpUnit);
      setDelayBetweenStepsValues(oldDbsValue);
      setDbsUnit(oldDbsUnit);
      setErrorMsgExplicit('');
      setErrorMsgExplicitOne('');
      setErrorMsgImplicit('');
      setErrorMsgImplicitOne('');
      setErrorMsgDelayLength('');
      setErrorMsgDelay('');
    }
  };

  const incrExp = (e) => {
    setExplicitWaitValues(parseInt(ExplicitWaitvalues) + 1);
    let ExplicitWait = document.getElementById('ExplicitWait').value;
    setDisableBtn(false);
    setDisableCancelBtn(false);
    setErrorMsgExplicit('');
    if ((expUnit === 'seconds' && ExplicitWait >= 998) || (expUnit === 'milliseconds' && ExplicitWait >= 998999)) {
      setExplicitbutton({ decrement: false, increment: true });
    } else {
      setExplicitbutton({ decrement: false, increment: false });
    }
    if (expUnit === 'milliseconds' && ExplicitWait > 998999) {
      setExplicitWaitValues(999000);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setExplicitError(false);
    }
    if (expUnit === 'milliseconds' && ExplicitWait < 998999) {
      setErrorMsgExplicit('');
      setDisableBtn(false);
      setDisableCancelBtn(false);
      setExplicitError(false);
    }
    if (expUnit === 'seconds' && ExplicitWait > 998) {
      setExplicitWaitValues(999);
      setExplicitError(false);
    }
    if (ExplicitWait <= 0) {
      setErrorMsgExplicitOne('Value less than 0 not allowed');
      setExplicitError(false);
    }
    if (ExplicitWait === '') {
      setExplicitWaitValues(1);
      setDisableBtn(false);
      setDisableCancelBtn(false);
      setErrorMsgExplicitOne('');
      setExplicitError(false);
    } else {
      setErrorMsgExplicitOne('');
      setExplicitError(false);
    }
  };

  const decrExp = () => {
    setExplicitWaitValues(ExplicitWaitvalues - 1);
    let ExplicitWait = document.getElementById('ExplicitWait').value;
    setErrorMsgExplicit('');
    if ((expUnit === 'seconds' && ExplicitWait > 999) || (expUnit === 'milliseconds' && ExplicitWait > 999000)) {
      setExplicitbutton({ decrement: false, increment: true });
    } else if (ExplicitWaitvalues - 1 === 0) {
      setExplicitbutton({ decrement: true, increment: false });
    } else {
      setExplicitbutton({ decrement: false, increment: false });
    }
    if (expUnit === 'milliseconds' && ExplicitWait > 999000) {
      setExplicitWaitValues(999000);
      setErrorMsgExplicit('');
      setDisableBtn(false);
      setDisableCancelBtn(false);
      setExplicitError(false);
    }
    if (expUnit === 'seconds' && ExplicitWait > 999) {
      setExplicitWaitValues(999);
      setErrorMsgExplicit('');
      setDisableBtn(false);
      setDisableCancelBtn(false);
      setExplicitError(false);
    }
    if (expUnit === 'milliseconds' && ExplicitWaitvalues <= 999000) {
      setErrorMsgExplicit('');
      setDisableBtn(false);
      setDisableCancelBtn(false);
      setExplicitError(false);
    }
    if (expUnit === 'seconds' && ExplicitWaitvalues <= 999) {
      setErrorMsgExplicit('');
      setDisableBtn(false);
      setDisableCancelBtn(false);
      setExplicitError(false);
    }
    if (ExplicitWait <= 0) {
      setExplicitWaitValues(0);
      setExplicitError(false);
    } else {
      setErrorMsgExplicitOne('');
      setExplicitError(false);
    }
  };

  const incrImplicit = () => {
    setImplicitWaitValues(parseInt(ImplicitWaitvalues) + 1);
    let ImplicitWait = document.getElementById('ImplicitWait').value;
    setDisableBtn(false);
    setDisableCancelBtn(false);
    setErrorMsgImplicit('');
    if ((impUnit === 'seconds' && ImplicitWait >= 998) || (impUnit === 'milliseconds' && ImplicitWait >= 998999)) {
      setImplicitbutton({ decrement: false, increment: true });
    } else {
      setImplicitbutton({ decrement: false, increment: false });
    }
    if (impUnit === 'milliseconds' && ImplicitWait > 998999) {
      setImplicitWaitValues(999000);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setImplicitError(false);
    }
    if (impUnit === 'milliseconds' && ImplicitWait < 998999) {
      setErrorMsgImplicit('');
      setDisableBtn(false);
      setDisableCancelBtn(false);
      setImplicitError(false);
    }
    if (impUnit === 'seconds' && ImplicitWait > 998) {
      setImplicitWaitValues(999);
      setImplicitError(false);
    }
    if (ImplicitWait <= 0) {
      setErrorMsgImplicitOne('Value less than 0 not allowed');
      setImplicitError(false);
    }
    if (ImplicitWait === '') {
      setImplicitWaitValues(1);
      setDisableBtn(false);
      setDisableCancelBtn(false);
      setErrorMsgImplicitOne('');
      setImplicitError(false);
    } else {
      setErrorMsgImplicitOne('');
      setImplicitError(false);
    }
  };

  const decrImplicit = () => {
    setImplicitWaitValues(ImplicitWaitvalues - 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    setErrorMsgImplicit('');
    let ImplicitWait = document.getElementById('ImplicitWait').value;
    if ((impUnit === 'seconds' && ImplicitWait > 999) || (impUnit === 'milliseconds' && ImplicitWait > 999000)) {
      setImplicitbutton({ decrement: false, increment: true });
    } else if (ImplicitWaitvalues - 1 === 0) {
      setImplicitbutton({ decrement: true, increment: false });
    } else {
      setImplicitbutton({ decrement: false, increment: false });
    }
    if (impUnit === 'milliseconds' && ImplicitWaitvalues > 999000) {
      setImplicitWaitValues(999000);
      setErrorMsgImplicit('');
      setDisableBtn(false);
      setDisableCancelBtn(false);
      setImplicitError(false);
    }
    if (impUnit === 'seconds' && ImplicitWaitvalues > 999) {
      setImplicitWaitValues(999);
      setErrorMsgImplicit('');
      setDisableBtn(false);
      setDisableCancelBtn(false);
      setImplicitError(false);
    }
    if (impUnit === 'milliseconds' && ImplicitWaitvalues <= 999000) {
      setErrorMsgImplicit('');
      setDisableBtn(false);
      setDisableCancelBtn(false);
      setImplicitError(false);
    }
    if (impUnit === 'seconds' && ImplicitWaitvalues <= 999) {
      setErrorMsgImplicit('');
      setDisableBtn(false);
      setDisableCancelBtn(false);
      setImplicitError(false);
    }
    if (ImplicitWait <= 0) {
      setImplicitWaitValues(0);
      setImplicitError(false);
    } else {
      setErrorMsgImplicitOne('');
      setImplicitError(false);
    }
  };
  const incrDbs = () => {
    setDelayBetweenStepsValues(parseInt(DelayBetweenSteps) + 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    setErrorMsgDelayLength('');
    let dbs = document.getElementById('dbsValue').value;
    if ((dbsUnit === 'seconds' && dbs >= 98) || (dbsUnit === 'milliseconds' && dbs >= 98999)) {
      setDbsButton({ decrement: false, increment: true });
    } else {
      setDbsButton({ decrement: false, increment: false });
    }
    if (dbsUnit === 'milliseconds' && dbs > 98999) {
      setDelayBetweenStepsValues(99000);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setDelayBtnError(false);
    }
    if (dbsUnit === 'milliseconds' && dbs < 98999) {
      setErrorMsgDelayLength('');
      setDisableBtn(false);
      setDisableCancelBtn(false);
      setDelayBtnError(false);
    }
    if (dbsUnit === 'seconds' && dbs > 98) {
      setDelayBetweenStepsValues(99);
      setDelayBtnError(false);
    }
    if (dbs < 0) {
      setErrorMsgDelay('Value less than 0 not allowed');
      setDelayBtnError(false);
    }
    if (dbs === '') {
      setDelayBetweenStepsValues(1);
      setDisableBtn(false);
      setDisableCancelBtn(false);
      setErrorMsgDelay('');
      setDelayBtnError(false);
    } else {
      setErrorMsgDelay('');
      setDelayBtnError(false);
    }
  };
  const decrDbs = () => {
    setDelayBetweenStepsValues(DelayBetweenSteps - 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    setErrorMsgDelayLength('');
    let dbs = document.getElementById('dbsValue').value;
    if (
      (impUnit === 'seconds' && DelayBetweenSteps > 99) ||
      (impUnit === 'milliseconds' && DelayBetweenSteps > 99000)
    ) {
      setDbsButton({ decrement: false, increment: true });
    } else if (DelayBetweenSteps - 1 === 0) {
      setDbsButton({ decrement: true, increment: false });
    } else {
      setDbsButton({ decrement: false, increment: false });
    }
    if (dbsUnit === 'milliseconds' && dbs > 99000) {
      setDelayBetweenStepsValues(99000);
      setErrorMsgDelayLength('');
      setDisableBtn(false);
      setDisableCancelBtn(false);
      setDelayBtnError(false);
    }
    if (dbsUnit === 'seconds' && dbs > 99) {
      setDelayBetweenStepsValues(99);
      setErrorMsgDelayLength('');
      setDisableBtn(false);
      setDisableCancelBtn(false);
      setDelayBtnError(false);
    }
    if (dbsUnit === 'milliseconds' && dbs <= 99000) {
      setErrorMsgDelayLength('');
      setDisableBtn(false);
      setDisableCancelBtn(false);
      setDelayBtnError(false);
    }
    if (dbsUnit === 'seconds' && dbs <= 99) {
      setErrorMsgDelayLength('');
      setDisableBtn(false);
      setDisableCancelBtn(false);
      setDelayBtnError(false);
    }
    if (dbs < 0) {
      setDelayBetweenStepsValues(0);
      setDelayBtnError(false);
    } else {
      setErrorMsgDelay('');
      setDelayBtnError(false);
    }
  };

  const isEditable = React.useMemo(() => window.permission?.isEditAccess('configuration'), []);
  const handleNumberValueChange = React.useCallback(
    (changeHandlerFunction) => (isEditable ? changeHandlerFunction : () => {}),
    [isEditable]
  );

  return (
    <div className="page-container ">
      <div className="page-list-body">
        <div className="alert-position-style">
          <AlertContatiner></AlertContatiner>
        </div>
        {renderTabs()}
        <div className="page-table-body mt-1">
          {renderPage(MyAlert)}
          <div className="configPage pr-0 content_area_style " id="journal-scroll">
            <div className="grid grid-cols-2 mb-4 ">
              <span className=" mx-1 mt-4 project_label">Wait Configuration</span>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginRight: '15px',
                }}
              >
                {isEditable && (
                  <>
                    <button
                      onClick={cancelValue}
                      className={
                        explicitError ||
                        implicitError ||
                        delayBtnError ||
                        ExplicitWaitvalues === '' ||
                        ImplicitWaitvalues === '' ||
                        DelayBetweenSteps === ''
                          ? 'gray-btn mt-4'
                          : 'gray-btn mt-4'
                      }
                      disabled={
                        explicitError ||
                        implicitError ||
                        delayBtnError ||
                        ExplicitWaitvalues === '' ||
                        ImplicitWaitvalues === '' ||
                        DelayBetweenSteps === ''
                      }
                    >
                      Cancel
                    </button>
                    <button
                      onClick={updateVal}
                      disabled={
                        explicitError ||
                        implicitError ||
                        delayBtnError ||
                        ExplicitWaitvalues === '' ||
                        ImplicitWaitvalues === '' ||
                        DelayBetweenSteps === ''
                      }
                      className={
                        explicitError || implicitError || delayBtnError
                          ? 'primary-btn mt-4 ml-7'
                          : 'primary-btn mt-4 ml-7'
                      }
                    >
                      Save
                    </button>
                  </>
                )}
              </div>
            </div>
            <hr></hr>
            <div className="fixed mt-20">
              <div className="grid grid-cols-7 gap-6 mt-3 " style={{ marginTop: '0.4rem', display: 'flex' }}>
                <form
                  className="ml-4"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <label className="w-40 fontPoppinsRegularMd flex  pl-4">
                    <span className="text-red-600 mr-1">* </span> Explicit Wait
                  </label>
                  <div
                    style={{
                      marginLeft: '1rem',
                      flexDirection: 'row',
                      justifyContent: 'stretch',
                      display: 'flex',
                    }}
                  >
                    <input
                      autoComplete="off"
                      disabled={!isEditable}
                      value={ExplicitWaitvalues}
                      pattern="[0-9\.]+"
                      maxLength={expUnit === 'seconds' ? '3' : '6'}
                      onChange={(e) => {
                        if (e.target.value.indexOf(' ') > -1) {
                          return;
                        }
                        handelChange(e);
                      }}
                      onBlur={(e) => {
                        explicitHandleBlur(e);
                      }}
                      id="ExplicitWait"
                      name="ExplicitWait"
                      type="text"
                      className={cx(
                        'numberField fontPoppinsRegularMd border-b-indigo-500 pb-0',
                        ConfigMemoryStyle['email-config-input-border']
                      )}
                      style={{
                        background: 'rgb(251,252,253)',
                        width: '5.6rem',
                        marginTop: '3.9px',
                        height: '32px',
                        textAlign: 'right',
                      }}
                    ></input>
                    <div className="flex">
                      <div>
                        <ArrowDropUpIcon
                          increment
                          onClick={!explicitbutton.increment && handleNumberValueChange(incrExp)}
                          style={{
                            color: 'blue',
                            marginLeft: '16px',
                            marginTop: '5px',
                            opacity: !explicitbutton.increment ? '1' : '0.5',
                          }}
                        />
                      </div>
                      <div>
                        <ArrowDropDownIcon
                          onClick={!explicitbutton.decrement && handleNumberValueChange(decrExp)}
                          style={{
                            color: 'blue',
                            marginLeft: '-24px',
                            marginBottom: '-37px',
                            opacity: !explicitbutton.decrement ? '1' : '0.5',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {errorMsgExplicit && (
                    <p className="errorMessage w-48 mt-1 h-1 ml-4  text-red-500">{errorMsgExplicit}</p>
                  )}
                  {errorMsgExplicitOne && (
                    <p className="errorMessage w-48 mt-1  ml-4 h-1  text-red-500">{errorMsgExplicitOne}</p>
                  )}
                </form>

                <div className="fixed ml-60" style={{ marginTop: '10px', width: '30px' }}>
                  <FormControl
                    className={classes.formControl}
                    fullWidth={true}
                    style={{ width: '80px', height: '19px' }}
                  >
                    <Select
                      disabled={!isEditable}
                      classes={{
                        root: classes.selectControl,
                        icon: classes.arrowDownIcon,
                      }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                      }}
                      disableUnderline
                      value={expUnit}
                      onChange={handleChange}
                      displayEmpty
                      className="fontPoppinsRegularMd"
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <option
                        className="hover:text-blue-700  cursor-pointer hover:bg-blue-100 fontPoppinsRegularMd"
                        value="milliseconds"
                        name="explicitWaitUnit"
                        onChange={(e) => {
                          setExpUnit(e.target.value);
                        }}
                        id="explicitWaitUnit"
                      >
                        Milliseconds
                      </option>
                      <option
                        value="seconds"
                        className="hover:text-blue-700 cursor-pointer hover:bg-blue-100 fontPoppinsRegularMd"
                        onChange={(e) => {
                          setExpUnit(e.target.value);
                        }}
                      >
                        Seconds
                      </option>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>

            {/*---------------------Implicit wait------------------ */}
            <div className="fixed mt-44">
              <div className="grid grid-cols-7 gap-6 mt-3 " style={{ marginTop: '0.4rem', display: 'flex' }}>
                <form
                  className="ml-4"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <label className="w-40 fontPoppinsRegularMd flex  pl-4">
                    <span className="text-red-600 mr-1">*</span> Implicit Wait
                  </label>
                  <div
                    style={{
                      marginLeft: '1rem',
                      flexDirection: 'row',
                      justifyContent: 'stretch',
                      display: 'flex',
                    }}
                  >
                    <input
                      autoComplete="off"
                      disabled={!isEditable}
                      value={ImplicitWaitvalues}
                      id="ImplicitWait"
                      name="ImplicitWait"
                      maxLength={impUnit === 'seconds' ? '3' : '6'}
                      onChange={(e) => {
                        if (e.target.value.indexOf(' ') > -1) {
                          return;
                        }
                        handelImplicitChange(e);
                      }}
                      onBlur={(e) => {
                        implicitHandleBlur(e);
                      }}
                      type="text"
                      className={cx(
                        'numberField fontPoppinsRegularMd pb-0',
                        ConfigMemoryStyle['email-config-input-border']
                      )}
                      style={{
                        background: 'rgb(251,252,253)',
                        width: '5.6rem',
                        marginTop: '3.9px',
                        height: '32px',
                        textAlign: 'right',
                      }}
                    ></input>
                    <div className="flex">
                      <div>
                        <ArrowDropUpIcon
                          onClick={!implicitbutton.increment && handleNumberValueChange(incrImplicit)}
                          style={{
                            color: 'blue',
                            marginLeft: '16px',
                            marginTop: '5px',
                            opacity: !implicitbutton.increment ? '1' : '0.5',
                          }}
                        />
                      </div>
                      <div>
                        <ArrowDropDownIcon
                          onClick={!implicitbutton.decrement && handleNumberValueChange(decrImplicit)}
                          style={{
                            color: 'blue',
                            marginLeft: '-24px',
                            marginBottom: '-37px',
                            opacity: !implicitbutton.decrement ? '1' : '0.5',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {errorMsgImplicitOne && (
                    <p className="errorMessage mt-1 w-48  h-1 text-red-500  ml-4">{errorMsgImplicitOne}</p>
                  )}
                  {errorMsgImplicit && (
                    <p className="errorMessage w-48 mt-1 h-1 ml-4 text-red-500">{errorMsgImplicit}</p>
                  )}
                </form>
                <div className="fixed ml-60" style={{ marginTop: '10px', width: '30px' }}>
                  <FormControl
                    className={classes.formControl}
                    fullWidth={true}
                    style={{ width: '80px', height: '19px' }}
                  >
                    <Select
                      disabled={!isEditable}
                      classes={{
                        root: classes.selectControl,
                        icon: classes.arrowDownIcon,
                      }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                      }}
                      disableUnderline
                      value={impUnit}
                      onChange={ImphandleChange}
                      displayEmpty
                      className={'fontPoppinsRegularMd'}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <option
                        value="milliseconds"
                        className="hover:text-blue-700  cursor-pointer hover:bg-blue-100 fontPoppinsRegularMd"
                        name="explicitWaitUnit"
                        onChange={(e) => {
                          setImpUnit(e.target.value);
                        }}
                        id="explicitWaitUnit"
                      >
                        Milliseconds
                      </option>

                      <option
                        value="seconds"
                        className="hover:text-blue-700  cursor-pointer hover:bg-blue-100 fontPoppinsRegularMd"
                        onChange={(e) => {
                          setImpUnit(e.target.value);
                        }}
                      >
                        Seconds
                      </option>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>

            {/*---------------------Delay between steps------------------ */}
            <div className="fixed mt-72">
              <div className="grid grid-cols-7 gap-6 mt-3 " style={{ marginTop: '0.4rem', display: 'flex' }}>
                <form
                  className="ml-4"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <label className=" w-44 fontPoppinsRegularMd flex  pl-4">
                    <span className="text-red-600 mr-1">*</span>
                    Delay Between Steps
                  </label>
                  <div
                    style={{
                      marginLeft: '1rem',
                      flexDirection: 'row',
                      justifyContent: 'stretch',
                      display: 'flex',
                    }}
                  >
                    <input
                      autoComplete="off"
                      disabled={!isEditable}
                      value={DelayBetweenSteps}
                      onChange={(e) => {
                        if (e.target.value.indexOf(' ') > -1) {
                          return;
                        }
                        handelDBSChange(e);
                      }}
                      onBlur={(e) => {
                        bdsHandleBlur(e);
                      }}
                      maxLength={dbsUnit === 'seconds' ? '2' : '5'}
                      id="dbsValue"
                      name="dbsValue"
                      type="text"
                      className={cx(
                        'numberField fontPoppinsRegularMd pb-0',
                        ConfigMemoryStyle['email-config-input-border']
                      )}
                      style={{
                        background: 'rgb(251,252,253)',
                        width: '5.6rem',
                        marginTop: '3.9px',
                        height: '32px',
                        textAlign: 'right',
                      }}
                    />
                    <div className="flex">
                      <div>
                        <ArrowDropUpIcon
                          onClick={!dbsbutton.increment && handleNumberValueChange(incrDbs)}
                          style={{
                            color: 'blue',
                            marginLeft: '16px',
                            marginTop: '5px',
                            opacity: !dbsbutton.increment ? '1' : '0.5',
                          }}
                        />
                      </div>
                      <div>
                        <ArrowDropDownIcon
                          onClick={!dbsbutton.decrement && handleNumberValueChange(decrDbs)}
                          style={{
                            color: 'blue',
                            marginLeft: '-24px',
                            marginBottom: '-37px',
                            opacity: !dbsbutton.decrement ? '1' : '0.5',
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {errorMsgDelayLength && (
                    <p className="errorMessage mt-1 w-48 h-1 ml-4 text-xs text-red-500">{errorMsgDelayLength} </p>
                  )}
                  {errorMsgDelay && (
                    <p className="errorMessage mt-1 w-48 ml-4 h-2.5 text-xs text-red-500">{errorMsgDelay}</p>
                  )}
                </form>

                <div className="fixed ml-60" style={{ marginTop: '10px', width: '30px' }}>
                  <FormControl
                    className={classes.formControl}
                    fullWidth={true}
                    style={{ width: '80px', height: '19px' }}
                  >
                    <Select
                      disabled={!isEditable}
                      classes={{
                        root: classes.selectControl,
                        icon: classes.arrowDownIcon,
                      }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                      }}
                      disableUnderline
                      value={dbsUnit}
                      onChange={DbhandleChange}
                      displayEmpty
                      className={'fontPoppinsRegularMd'}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <option
                        value="milliseconds"
                        className="hover:text-blue-700  cursor-pointer hover:bg-blue-100 fontPoppinsRegularMd"
                        onChange={(e) => {
                          setDbsUnit(e.target.value);
                        }}
                      >
                        Milliseconds
                      </option>
                      <option
                        value="seconds"
                        className="hover:text-blue-700  cursor-pointer hover:bg-blue-100 fontPoppinsRegularMd"
                        onChange={(e) => {
                          setDbsUnit(e.target.value);
                          setDelayBetweenStepsValues(0);
                        }}
                      >
                        Seconds
                      </option>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(WaitConfigRouter);

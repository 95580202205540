import React, { Fragment, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'react-phone-number-input/style.css';
import '../user_page.css';
import { Typography } from '@material-ui/core';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { isValidPhoneNumber, getCountryCallingCode } from 'react-phone-number-input';
import PhoneInput from 'react-phone-input-2';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import 'react-phone-input-2/lib/material.css';
import { createUserRequest, updateUserRequest, getUserByEmailId } from '@api/api_services';
import MyInput from '../Inputs/MyInput';
import useGeoLocation from 'react-ipgeolocation';
import { makeStyles } from '@material-ui/core/styles';
import { useAlert } from '@pages/common/alert_service/useAlert';
import { fontPoppins } from '@src/css_config/fontConstants';
import cx from 'classnames';
import { Tooltip } from '@material-ui/core';
import { getAdminPrivilege } from '@util/common_utils';
import { isEmailValid } from '@src/util/common_utils';
const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectRoot: {
    '&:focus': {
      backgroundColor: 'red',
    },
  },
}));
const UserCreatePage = ({ ...props }) => {
  const classes = useStyles();
  const closePopupFunction = () => {
    if (props.popupHeader === 'Edit User') {
      props.func('closePopup');
    } else {
      props.func('closePopup');
    }
  };
  let [isOpen, setIsOpen] = useState(true);
  const [open, setOpen1] = useState(false);
  const [countryLocate, setCountryLocate] = useState('IN');
  const [clickedd, setClickedd] = useState(false);
  const [errorContent, setErrorContent] = useState();
  const [showResults, setShowResults] = useState(false);
  const [existName, setExistName] = useState('');
  const [existPhone, setExistPhone] = useState('');
  const [existPhone2, setExistPhone2] = useState('');
  const [createUpdateCalled, setCreateUpdateCalled] = useState(true);
  const [fill, setFill] = useState(false);
  let tempField = [{ value: null }];
  const [fields, setFields] = useState(tempField);
  const location = useGeoLocation();
  const { AlertContatiner, MyAlert } = useAlert();
  const maximumProject = JSON.parse(localStorage.getItem('allowedProjects'))?.privilege?.project?.users;
  const { rMd } = fontPoppins;
  const [isEmailExist, setEmailExist] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: props.user.name,
      emailId: props.user.emailId,
      privilege: props.user.privilege,
      activationStatus: props.user.status,
      phoneNumbers: {
        primaryPhone: props.user?.phoneNumbers?.primaryPhone,
        secondaryPhone: props.user?.phoneNumbers?.secondaryPhone,
      },
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, 'Minimum 3 characters')
        .trim('Space is not allowed at starting and at the end')
        .strict(true)
        .max(25, 'Maximum 25 characters')
        .matches(/^[a-zA-Z0-9-_ ]+$/, 'Name should be alphanumeric')
        .required('Name is required'),
      emailId: Yup.string()
        .test('emailId', 'Enter valid email Id', async (value) => {
          return isEmailValid(value);
        })
        .required('Email is required'),
      privilege: Yup.string().required('Select user privilege'),
      phoneNumbers: Yup.object({
        primaryPhone: Yup.string(),
      }),
    }),
    onSubmit: (values) => {
      if (createUpdateCalled) {
        setCreateUpdateCalled(false);
        if (props.button === 'Create') {
          let data = {
            name: values.name,
            emailId: values.emailId,
            phoneNumbers: {
              primaryPhone:
                values.phoneNumbers.primaryPhone[0] !== '+'
                  ? `+${values.phoneNumbers.primaryPhone}`
                  : values.phoneNumbers.primaryPhone,
              ...(!!values.phoneNumbers.secondaryPhone && {
                secondaryPhone:
                  values.phoneNumbers.secondaryPhone[0] !== '+'
                    ? `+${values.phoneNumbers.secondaryPhone}`
                    : values.phoneNumbers.secondaryPhone,
              }),
            },
            licenses: [
              {
                id: `${JSON.parse(localStorage.getItem('test-optimize-user')).licenseId}`,
                privilege: values.privilege,
              },
            ],
          };
          if (props?.userCount?.length > maximumProject - 1) {
            MyAlert.warning(` Exceeded the allocated limit, please upgrade license or buy new license. `);
          } else {
            !(fields[0]?.error || fields[1]?.error) &&
              createUserRequest(data, props.user.id)
                .then((res) => {
                  if (res.data.responseObject) {
                    props.func('getAllUsers');
                    props.func('closePopup');
                    props.MyAlert.success(`${values.name} User created successfully`);
                  } else {
                    setOpen1(true);
                    setErrorContent(res.data.message);
                  }
                })
                .catch((error) => {
                  setOpen1(false);
                  setErrorContent('');
                  console.log(error);
                });
          }
        } else if (props.button === 'Update') {
          const user = formik.values;
          if (JSON.stringify(formik.initialValues) === JSON.stringify(user)) {
            MyAlert.info(`No changes were done to the ${user.name} user`);
            return null;
          }
          const data = {
            id: props.user.id,
            name: user.name,
            emailId: user.emailId,
            phoneNumbers: {
              primaryPhone: user.phoneNumbers && user.phoneNumbers.primaryPhone ? user.phoneNumbers.primaryPhone : '',
              secondaryPhone:
                user.phoneNumbers && user.phoneNumbers.secondaryPhone ? user.phoneNumbers.secondaryPhone : '',
            },
            licenses: [
              {
                id: `${JSON.parse(localStorage.getItem('test-optimize-user')).licenseId}`,
                privilege: user.privilege,
                licenseStatus: user.activationStatus,
              },
            ],
          };
          updateUserRequest(data, props.user.userId)
            .then((res) => {
              if (res.data.responseObject) {
                props.func('getAllUsers');
                props.func('closePopup');
                props.MyAlert.success(`${user.name} User Updated successfully`);
              } else {
                setOpen1(true);
                setErrorContent(res.data.message);
              }
            })
            .catch((error) => {
              setOpen1(false);
              setErrorContent('');
              console.log(error);
            });
        }
      }
    },
  });

  useEffect(() => {
    if (location?.country) {
      setCountryLocate(location?.country);
    }
  }, [location?.country]);

  useEffect(() => {
    if (props.popupHeader !== 'Edit User') {
      formik.setFieldValue('name', existName ? existName : '');
      formik.setFieldValue('phoneNumbers', {
        primaryPhone: existPhone ? existPhone : getCountryCallingCode(countryLocate),
        secondaryPhone: existPhone2 ? existPhone2 : getCountryCallingCode(countryLocate),
      });
    }
  }, [existName, existPhone, existPhone2, props.popupHeader]);

  let handelContinue = (e) => {
    setOpen1(false);
  };

  const cancelFunction = () => {
    setOpen1(false);
    props.func('closePopup');
  };

  if (props?.user?.phoneNumbers) {
    tempField = [];
    let tempObj = {
      value: props?.user?.phoneNumbers?.primaryPhone,
    };
    tempField.push(tempObj);
    if (props?.user?.phoneNumbers?.secondaryPhone) {
      let tempObj2 = {
        value: props?.user?.phoneNumbers?.secondaryPhone,
      };
      tempField.push(tempObj2);
    }
  }

  const user = localStorage.getItem('test-optimize-user');
  const userSession = user ? JSON.parse(user) : {};
  const userId = userSession.id;

  const errorMsg = () => {
    setOpen1(false);
    if (formik.values.privilege === 'Super Admin' && props.superAdminCount === 2) {
      setOpen1(true);
      setErrorContent("Can't update more than 2 Super Admin for a license");
    }
    if (formik.values.privilege === 'Admin') {
      setOpen1(true);
      setErrorContent("Can't update more than 2 Super Admin for a license");
    }
  };

  const errorShow = () => {
    setOpen1(false);
    if (!formik.values.emailId || !formik.values.privilege || !formik.touched.emailId || !formik.values.privilege) {
      document.getElementById('errorsEmail').innerHTML = '';
      document.getElementById('errorsPriviledge').innerHTML = '';
    }
  };
  const removeError = (e) => {
    if ((formik.values.emailId === undefined && formik.touched.emailId) || formik.values.emailId) {
      document.getElementById('errorsEmail').innerHTML = '';
    }
    if ((formik.values.privilege === undefined && formik.touched.privilege) || formik.values.privilege) {
      document.getElementById('errorsPriviledge').innerHTML = '';
    }
    if (formik.values.privilege !== 'Super Admin' && document.getElementById('userphonenumber')) {
      document.getElementById('userphonenumber').style.color = '#727171';
    }
    if (formik.values.emailId) document.getElementById('emailblue').style.color = '#727171';
  };
  function handleAdd() {
    const values = [...fields];
    values.push({ value: null });
    setFields(values);
  }

  function setDropDownValue(dropValue) {
    formik.setFieldValue('privilege', dropValue);
  }

  let submitBtn = () => {
    //future
  };

  const onclickP = (e) => {
    if (!clickedd) {
      setClickedd(true);

      if (!formik.values.emailId && !formik.touched.emailId) {
        document.getElementById('emailblue').style.color = '#EA0322';
        document.getElementById('errorsEmail').style.color = '#EA0322';
        document.getElementById('errorsEmail').style.position = 'absolute';
      }
    }
    if (formik.values.privilege) {
      document.getElementById('errorsEmail').innerHTML = '';
    }
  };
  const mySelectBlur = () => {
    document.getElementById('pprivilege').style.color = '#727171';
  };
  const focusCheck = (e) => {
    if (e.target.value.length < 3 && formik.errors.name && formik.touched.name) {
      document.getElementById('nameLvlText').style.color = '#EA0322';
    } else {
      document.getElementById('nameLvlText').style.color = '#1648C6';
    }
  };
  const blurCheck = (e) => {
    if (e.target.value.length > 0 && e.target.value.length < 3) {
      document.getElementById('nameLvlText').style.color = '#EA0322';
    } else if (formik.values.projectName && e.target.value.length > 3) {
      document.getElementById('nameLvlText').style.color = '#727171';
    } else {
      document.getElementById('nameLvlText').style.color = '#727171';
    }
  };

  const checkExistingEmail = (e) => {
    if (isEmailValid(e?.target?.value)) {
      getUserByEmailId(e.target.value)
        .then((res) => {
          setExistName(res.data.responseObject?.name);
          setExistPhone(res.data.responseObject?.['phone number']);
          setEmailExist(true);
          if (res.data.message === 'No user exists with this EmailId') {
            setEmailExist(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      setShowResults(true);
    } else {
      setShowResults(false);
    }
  };
  let getAllowedUserList = (uList) => {
    const myPrivilegeIsAdmin = getAdminPrivilege();
    if (myPrivilegeIsAdmin) {
      return uList.filter((val) => val !== 'Super Admin');
    } else {
      return uList;
    }
  };

  const allowedPrivileges =
    getAllowedUserList(
      JSON.parse(localStorage.getItem('allowedProjects'))?.features?.projectsMenu?.restrictions?.allowedUsers
    ) || [];

  function validatePhoneNumberField(phoneNumberIndex, event) {
    const values = [...fields];
    values[phoneNumberIndex].error = '';
    if (
      formik.values?.phoneNumbers?.primaryPhone?.length > 2 &&
      formik.values?.phoneNumbers?.primaryPhone?.length <= 8
    ) {
      values[phoneNumberIndex].error = 'Valid Phone number is required';
    }
    setFields(values);
  }
  function validateSecondaryPhoneNumber(phoneNumberIndex, event) {
    const values = [...fields];
    values[phoneNumberIndex].error = '';
    let inputNumbers = [2, 4, 6, 8];
    if (
      formik.values?.phoneNumbers?.secondaryPhone?.length > 2 &&
      formik.values?.phoneNumbers?.secondaryPhone?.length <= inputNumbers[3]
    ) {
      values[phoneNumberIndex].error = 'Valid Phone number is required';
    } else if (formik.values.phoneNumbers.secondaryPhone === formik.values.phoneNumbers.primaryPhone) {
      values[phoneNumberIndex].error = 'The two numbers should not be the same';
    }
    setFields(values);
  }

  function resetPhoneNumberFieldError(phoneNumberIndex, countryData = {}) {
    const values = [...fields];
    values[phoneNumberIndex].error = '';
    values[phoneNumberIndex].value = countryData.dialCode;
    formik.setFieldValue('phoneNumbers', {
      primaryPhone: values[0]?.value,
      secondaryPhone: values[1]?.value,
    });
    setFields(values);
  }

  function handlePhoneNumberChange(phoneNumberIndex, phoneNumber, countryData) {
    if (phoneNumber !== countryData.dialCode) {
      const values = [...fields];
      values[phoneNumberIndex].value = phoneNumber;
      values[phoneNumberIndex].countryData = countryData;
      values[phoneNumberIndex].error = '';
      setFields(values);
      formik.setFieldValue('phoneNumbers', {
        primaryPhone: values[0]?.value,
        secondaryPhone: values[1]?.value,
      });
    } else {
      resetPhoneNumberFieldError(phoneNumberIndex, countryData);
    }
  }

  function handleAddPhoneNumberField() {
    const values = [...fields];
    values.push({ value: null, countryData: null });
    setFields(values);
  }

  function handleRemovePhoneNumberField(phoneNumberIndex) {
    const values = [...fields];
    values.splice(phoneNumberIndex, 1);
    setFields(values);
  }

  const formikOnChangeHandler = (e) => {
    formik.values.emailId = e?.target?.value;
    removeError(e);
  };
  return (
    <div
      style={{ marginLeft: '18px' }}
      onBlur={(e) => {
        setCreateUpdateCalled(true);
      }}
    >
      <div className="fixed ml-6 top-12">
        <AlertContatiner />
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-index-child-popup inset-0 overflow-y-auto"
          open={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <div className="flex  items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className="inline-block marginupper_error align-bottom bg-white rounded-sm px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6 duplicate-pop"
                onBlur={() => setOpen1(true)}
              >
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none "
                    onClick={() => setOpen1(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10">
                    <img
                      alt=""
                      src="/assets/images/remove_circle.jpg"
                      className="flex flex-row mr-2 ml-2"
                      height="20px"
                      width="20px"
                    ></img>
                  </div>
                  <div className="mt-1 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-sm leading-6 font-small text-gray-900">
                      <div className="warning-popup-header">Error.</div>
                      <div className="warning-popup-content">
                        {errorContent?.includes('Phone') || errorContent?.includes('More than') ? '' : 'Duplicate'}
                      </div>
                    </Dialog.Title>
                    <hr className="line mt-2" />
                    <div className="mt-2 warning-popup-content">
                      {errorContent?.includes('already exist') ? (
                        <p className="mt-2 pt-2"> An user with the email Id {formik.values?.emailId} already exist.</p>
                      ) : (
                        <p className="mt-2">{errorContent}</p>
                      )}
                      <p className="mt-2"> How do you want to proceed? </p>
                    </div>
                  </div>
                </div>
                <hr className="line mt-6" />
                <div className="mt-4 sm:mt-2 sm:flex sm:flex-row-reverse">
                  <button type="button" className="primary-btn" onClick={handelContinue}>
                    <span>Continue Editing</span>
                  </button>

                  <button type="button" className="gray-btn mr-6" onClick={cancelFunction}>
                    <span>Cancel</span>
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <form onSubmit={formik.handleSubmit} className="space-y-8 divide-y divide-gray-200">
        <div
          id="journal-scroll"
          style={{
            maxHeight: '400px',
            marginTop: '32px',
          }}
        >
          <div className={props.popupHeader === 'Edit User' ? 'user-edit-body-style' : 'user-create-body-style'}>
            <div className="grid grid-cols-2  mt-2 pb-3">
              <div style={{ top: '15px', position: 'relative' }}>
                <div style={{ position: 'relative', bottom: '11px' }}>
                  {(formik.errors.emailId && formik.touched.emailId) ||
                  (formik.values.privilege && !formik.values.emailId) ||
                  (formik.touched.privilege && formik.errors.privilege && !formik.values.emailId) ? (
                    <>
                      {' '}
                      <span
                        className="fontPoppinsRegularMd"
                        style={{
                          color: '#EA0322',
                        }}
                      >
                        *
                      </span>
                      <span
                        id="emailblue"
                        className="fontPoppinsRegularMd"
                        style={{
                          color: '#EA0322',
                        }}
                      >
                        {' '}
                        Email{' '}
                      </span>
                    </>
                  ) : (
                    <>
                      {' '}
                      <span
                        className="fontPoppinsRegularMd"
                        style={{
                          color: '#EA0322',
                        }}
                      >
                        *
                      </span>
                      <span
                        className="fontPoppinsRegularMd"
                        id="emailblue"
                        style={{
                          color: '#727171',
                        }}
                      >
                        {' '}
                        Email{' '}
                      </span>
                    </>
                  )}
                </div>
                {props.popupHeader !== 'Edit User' ? (
                  <MyInput
                    type="text"
                    error={
                      (formik.errors.emailId && formik.touched.emailId) ||
                      (formik.values.privilege && !formik.values.emailId) ||
                      (formik.touched.privilege && formik.errors.privilege && !formik.values.emailId)
                    }
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      document.getElementById('emailblue').style.color = 'gray';
                      checkExistingEmail(e);
                    }}
                    onKeyUp={(e) => {
                      formikOnChangeHandler(e);
                    }}
                    onFocus={(e) => {
                      document.getElementById('emailblue').style.color = '#1648C6';
                    }}
                    name="emailId"
                    id="emailId"
                    autoFocus={false}
                    autoComplete="off"
                    InputProps={
                      props.popupHeader === 'Edit User' ? { disableUnderline: true } : { disableUnderline: false }
                    }
                    className=" block w-9/12 Apptype"
                    placeholder="Enter email"
                    style={{ width: '231px' }}
                    helperText={
                      <div className="fontPoppinsRegularSm" style={{ position: 'absolute' }}>
                        {(formik.errors.emailId && formik.touched.emailId) ||
                        (formik.values.privilege && !formik.values.emailId) ||
                        (formik.touched.privilege && formik.errors.privilege) ? (
                          <p id="emailError mt-1" className="emailErrorContent">
                            {formik.errors.emailId}
                          </p>
                        ) : (
                          ' '
                        )}
                      </div>
                    }
                  />
                ) : (
                  <MyInput
                    type="text"
                    name="emailId"
                    id="emailId"
                    disabled={true}
                    InputProps={{ disableUnderline: true }}
                    className=" block w-9/12 Apptype"
                    style={{ width: '231px' }}
                    value={props.user.emailId}
                  />
                )}

                <p
                  id="errorsEmail"
                  className="fontPoppinsRegularSm"
                  style={{
                    marginTop: '-4px',
                    letterSpacing: '0.8px',
                  }}
                ></p>
              </div>
              <div className="w-11/12" style={{ marginTop: '5px', width: '95%' }}>
                <div>
                  {(formik.touched.privilege && formik.errors.privilege) ||
                  (formik.touched.name && !formik.values.privilege) ? (
                    <Typography
                      id="userprivilege"
                      className="fontPoppinsRegularMd"
                      style={{
                        color: '#EA0322',
                        marginTop: '4px',
                        marginBottom: '-20px',
                      }}
                    >
                      {' '}
                      <span
                        className="fontPoppinsRegularMd text-red-400"
                        style={{
                          color: 'red',
                        }}
                      >
                        *
                      </span>{' '}
                      Privilege
                    </Typography>
                  ) : (
                    <Typography
                      id="userprivilege"
                      className="fontPoppinsRegularMd"
                      style={{
                        color: '#727171',
                        marginTop: '4px',
                        marginBottom: '-20px',
                      }}
                    >
                      {' '}
                      <span
                        className="fontPoppinsRegularMd text-red-400"
                        style={{
                          color: '#EA0322',
                        }}
                      >
                        *
                      </span>{' '}
                      Privilege
                    </Typography>
                  )}
                </div>
                <FormControl
                  style={{ width: '228px', left: '29px', top: '-18px' }}
                  variant="standard"
                  error={
                    (formik.errors.privilege && formik.touched.privilege) ||
                    (formik.touched.name && !formik.values.privilege)
                  }
                >
                  <Select
                    classes={{ root: classes.selectRoot }}
                    displayEmpty
                    defaultValue=""
                    renderValue={(selected) => {
                      if (!selected) return <span className="fontPoppinsRegularSm">Select user privilege</span>;
                      else return <span className="fontPoppinsRegularSm">{selected}</span>;
                    }}
                    type="text"
                    error={formik.errors.privilege && formik.touched.privilege}
                    id="pprivilege"
                    className={` block w-full -ml-7 mt-10 Apptype ${
                      props.user.status === 'DISABLED' && 'user-modal-dropdown'
                    }`}
                    style={{
                      color: props.button === 'Create' ? '#dad5d5' : '#727171',
                    }}
                    name="privilege"
                    selectedValue={props.user.privilege}
                    value={formik.values.privilege}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      document.getElementById('userprivilege').style.color = 'gray';
                      setCreateUpdateCalled(true);
                      document.getElementById('errorsEmail').innerHTML = '';
                      mySelectBlur(e);
                    }}
                    onChange={(e) => {
                      removeError(e);
                      formik.handleChange(e);
                    }}
                    disabled={userId === props.user.id || props.user.status === 'DISABLED'}
                    disableUnderline={props.user.status === 'DISABLED'}
                    InputProps={{ disableUnderline: true }}
                    func={setDropDownValue}
                    onFocus={(e) => {
                      document.getElementById('userprivilege').style.color = '#1648C6';
                      document.getElementById('pprivilege').style.position = 'relative';
                    }}
                    onClick={onclickP}
                    helperText={
                      formik.touched.privilege && formik.errors.privilege ? (
                        <p
                          className="fontPoppinsRegularSm"
                          style={{
                            marginLeft: '-30px',
                            position: 'absolute',
                          }}
                        >
                          {formik.errors.privilege}
                        </p>
                      ) : (
                        ' '
                      )
                    }
                  >
                    {Object.values(allowedPrivileges).map((option, optionIndex) => (
                      <MenuItem
                        id="sideLeft"
                        key={`labeled-select-${option}-${optionIndex}`}
                        value={option}
                        className="hoverItem fontPoppinsRegularSm"
                      >
                        <span className="text-gray-500"> {option}</span>
                      </MenuItem>
                    ))}
                  </Select>
                  {((formik.errors.privilege && formik.touched.privilege) ||
                    (formik.touched.name && !formik.values.privilege)) && (
                    <FormHelperText
                      className="fontPoppinsRegularSm"
                      style={{
                        color: '#EA0322',
                        marginLeft: '-28px',
                      }}
                    >
                      Select user privilege
                    </FormHelperText>
                  )}
                </FormControl>
                <p id="error"></p>
                <p id="errorsPriviledge" className="fontPoppinsRegularSm" style={{ marginTop: '-20px' }}></p>
              </div>
            </div>
            {showResults && (
              <div className="grid grid-cols-2  mt-2 pb-3">
                <div className="mt-4">
                  <div style={{ position: 'relative', bottom: '11px' }}>
                    {formik.errors.name && formik.touched.name ? (
                      <>
                        {' '}
                        <span
                          className="fontPoppinsRegularMd"
                          style={{
                            color: '#EA0322',
                          }}
                        >
                          *
                        </span>
                        <span
                          id="nameLvlText"
                          className="fontPoppinsRegularMd"
                          style={{
                            color: '#Ea0322',
                          }}
                        >
                          {' '}
                          Name{' '}
                        </span>
                      </>
                    ) : (
                      <>
                        {' '}
                        <span
                          className="fontPoppinsRegularMd"
                          style={{
                            color: '#EA0322',
                          }}
                        >
                          *
                        </span>
                        <span
                          id="nameLvlText"
                          className="fontPoppinsRegularMd"
                          style={{
                            color: '#727171',
                          }}
                        >
                          {' '}
                          Name{' '}
                        </span>
                      </>
                    )}
                  </div>
                  <MyInput
                    type="text"
                    disabled={existName}
                    error={formik.errors.name && formik.touched.name}
                    value={formik.values.name}
                    onFocus={(e) => {
                      focusCheck(e);
                    }}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      setCreateUpdateCalled(true);
                      blurCheck(e);
                    }}
                    onChange={formik.handleChange}
                    name="name"
                    id="name"
                    autoComplete="off"
                    autoFocus={false}
                    className=" block w-9/12 Apptype"
                    placeholder="Enter user name"
                    helperText={
                      <div className="absolute fontPoppinsRegularSm">{formik.touched.name && formik.errors.name}</div>
                    }
                  />
                </div>

                <div className="mt-1">
                  <div>
                    <label
                      htmlFor="phoneNumbers"
                      id="phoneNumber"
                      className="input-filed-label-style-common labelColor"
                    >
                      {'Phone Number'}
                    </label>
                    <div className="flex flex-col items-end w-4/5">
                      <div
                        className={cx('phone-input-wrapper', 'w-full', {
                          error: !!fields[0]?.error,
                        })}
                      >
                        <PhoneInput
                          className="fontPoppinsRegularMd"
                          type="text"
                          id="addPrimaryNumberId"
                          disabled={existPhone || isEmailExist}
                          placeholder="Enter phone number"
                          searchPlaceholder="Search..."
                          searchNotFound="please try again"
                          specialLabel=""
                          value={existPhone ? existPhone : formik.values.phoneNumbers.primaryPhone}
                          defaultCountry={location?.country?.toLowerCase()}
                          enableAreaCodeStretch
                          country={location?.country?.toLowerCase()}
                          enableSearch
                          disableSearchIcon
                          inputStyle={{
                            ...rMd,
                            height: '26px',
                            width: '231px',
                            borderBottom: '1px solid #989797',
                            borderRadius: '0px',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderRight: 'none',
                            boxShadow: '0px 0px',
                            marginLeft: '-1px',
                          }}
                          searchStyle={{
                            borderBottom: '1px solid #989797',
                            borderRadius: '0px',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderRight: 'none',
                            boxShadow: '0px 0px',
                            width: '90%',
                          }}
                          dropdownStyle={{
                            width: '250px',
                            height: '236px',
                            borderRadius: '5px',
                            boxShadow: '0px 0px 6px #00000029',
                            textAlign: 'left',
                            position: 'fixed',
                            backgroundColor: '#FBFCFD',
                          }}
                          international
                          name="PrimaryNumberName"
                          autoComplete="off"
                          onBlur={(e) => {
                            formik.handleBlur(e);
                            validatePhoneNumberField(0, e);
                          }}
                          onKeyUp={() => resetPhoneNumberFieldError(0)}
                          onChange={(phoneNumber, country) => handlePhoneNumberChange(0, phoneNumber, country)}
                          countryCodeEditable={false}
                        />
                        <div className="flex justify-between">
                          {fields[0].value &&
                          fields[0].value.length.toString().replace(/^\s+|\s+$/g, '') > 12 &&
                          !isValidPhoneNumber(fields[0].value) ? (
                            <FormHelperText className="fontPoppinsRegularSm" error>
                              Phone number is required (Valid)
                            </FormHelperText>
                          ) : (
                            <FormHelperText
                              className="fontPoppinsRegularSm"
                              error={
                                !!(
                                  !!fields[0]?.error ||
                                  ((formik.touched.phoneNumbers || formik.touched.phoneNumbers?.primaryPhone) &&
                                    formik.errors.phoneNumbers?.primaryPhone)
                                )
                              }
                            >
                              {fields[0]?.error ||
                                ((formik.touched.phoneNumbers || formik.touched.phoneNumbers?.primaryPhone) &&
                                  formik.errors.phoneNumbers?.primaryPhone)}
                            </FormHelperText>
                          )}
                          <div className="text-xs inline">
                            {fields.length === 1 && (
                              <button
                                type="button"
                                data-value={fields.length}
                                disabled={
                                  !!(
                                    (!fields[0]?.value && !isValidPhoneNumber(fields[0]?.value || '')) ||
                                    fields[0]?.value.length <= 10 ||
                                    fields[0]?.value.length >= 14
                                  )
                                }
                                className={cx({
                                  'opacity-50 fontPoppinsRegularSm': !(
                                    fields[0]?.value &&
                                    isValidPhoneNumber('+' + formik.values.phoneNumbers.primaryPhone?.toString()) ===
                                      true
                                  ),
                                  'text-blue-700 rounded-lg fontPoppinsRegularSm':
                                    fields[0]?.value &&
                                    isValidPhoneNumber('+' + formik.values.phoneNumbers.primaryPhone?.toString()) ===
                                      true,
                                })}
                                onClick={() => {
                                  if (
                                    isValidPhoneNumber('+' + formik.values.phoneNumbers.primaryPhone?.toString()) ===
                                    true
                                  ) {
                                    handleAddPhoneNumberField();
                                  }
                                }}
                                hidden={fields[0]?.error}
                              >
                                + Phone
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {fields.length > 1 && (
                    <div>
                      <div className="flex flex-col items-end w-4/5">
                        <div
                          className={cx('phone-input-wrapper', 'w-full', {
                            error: !!fields[1]?.error,
                          })}
                        >
                          <PhoneInput
                            className="fontPoppinsRegularMd"
                            id="secondaryPhoneNumberId"
                            type="text"
                            placeholder="Enter secondary phone number"
                            value={fields[1]?.value || ''}
                            searchPlaceholder="Search..."
                            searchNotFound="No Countries Found"
                            specialLabel=""
                            defaultCountry={location?.country?.toLowerCase()}
                            enableAreaCodeStretch
                            country={location?.country?.toLowerCase()}
                            enableSearch
                            international
                            name="secondaryPhoneName"
                            autoComplete="off"
                            inputStyle={{
                              ...rMd,
                              height: '26px',
                              width: '231px',
                              borderBottom: '1px solid #989797',
                              borderRadius: '0px',
                              borderTop: 'none',
                              borderLeft: 'none',
                              borderRight: 'none',
                              boxShadow: '0px 0px',
                              marginLeft: '-1px',
                            }}
                            searchStyle={{
                              borderBottom: '1px solid #989797',
                              borderRadius: '0px',
                              borderTop: 'none',
                              borderLeft: 'none',
                              borderRight: 'none',
                              boxShadow: '0px 0px',
                              width: '90%',
                            }}
                            dropdownStyle={{
                              width: '250px',
                              height: '236px',
                              borderRadius: '5px',
                              boxShadow: '0px 0px 6px #00000029',
                              textAlign: 'left',
                              position: 'fixed',
                              backgroundColor: '#FBFCFD',
                            }}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                              validateSecondaryPhoneNumber(1, e);
                            }}
                            onChange={(phoneNumber, countryData) =>
                              handlePhoneNumberChange(1, phoneNumber, countryData)
                            }
                            countryCodeEditable={false}
                          />
                          {fields[1].value &&
                          fields[1].value.length.toString().replace(/^\s+|\s+$/g, '') > 12 &&
                          !isValidPhoneNumber(fields[1].value) ? (
                            <FormHelperText className="fontPoppinsRegularSm" error>
                              Phone number is required (Valid)
                            </FormHelperText>
                          ) : (
                            <FormHelperText
                              className="fontPoppinsRegularSm"
                              error={
                                !!(
                                  !!fields[1]?.error ||
                                  ((formik.touched.phoneNumbers || formik.touched.phoneNumbers?.secondaryPhone) &&
                                    formik.errors.phoneNumbers?.secondaryPhone)
                                )
                              }
                            >
                              {fields[1]?.error ||
                                ((formik.touched.phoneNumbers || formik.touched.phoneNumbers?.secondaryPhone) &&
                                  formik.errors.phoneNumbers?.secondaryPhone)}
                            </FormHelperText>
                          )}
                        </div>

                        <div className="text-xs inline">
                          <button
                            type="button"
                            onClick={() => handleRemovePhoneNumberField(1)}
                            className="fontPoppinsRegularSm text-red-500 "
                            hidden={fields[1]?.error}
                          >
                            - Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {props.popupHeader === 'Edit User' && (
              <div className="grid grid-cols-2  mt-2 pb-3">
                <div className="mt-4">
                  <div style={{ position: 'relative', bottom: '11px' }}>
                    {formik.errors.name && formik.touched.name ? (
                      <>
                        {' '}
                        <span
                          className="fontPoppinsRegularMd"
                          style={{
                            color: '#EA0322',
                          }}
                        >
                          *
                        </span>
                        <span
                          id="nameLvlText"
                          className="fontPoppinsRegularMd"
                          style={{
                            color: '#Ea0322',
                          }}
                        >
                          {' '}
                          Name{' '}
                        </span>
                      </>
                    ) : (
                      <>
                        {' '}
                        <span
                          className="fontPoppinsRegularMd"
                          style={{
                            color: '#EA0322',
                          }}
                        >
                          *
                        </span>
                        <span
                          id="nameLvlText"
                          className="fontPoppinsRegularMd"
                          style={{
                            color: '#727171',
                          }}
                        >
                          {' '}
                          Name{' '}
                        </span>
                      </>
                    )}
                  </div>
                  <MyInput
                    type="text"
                    disabled={props.popupHeader === 'Edit User'}
                    error={formik.errors.name && formik.touched.name}
                    value={formik.values.name}
                    InputProps={{ disableUnderline: true }}
                    onFocus={(e) => {
                      focusCheck(e);
                    }}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      setCreateUpdateCalled(true);
                      blurCheck(e);
                    }}
                    onChange={formik.handleChange}
                    name="name"
                    id="name"
                    autoComplete="off"
                    autoFocus={false}
                    className=" block w-9/12 Apptype"
                    placeholder="Enter user name"
                    helperText={
                      <div className="fontPoppinsRegularSm absolute w-96">
                        {formik.touched.name && formik.errors.name}
                      </div>
                    }
                  />
                </div>

                <div className="w-11/12 mt-1">
                  <label
                    htmlFor="phoneNumbers"
                    id="userphonenumber"
                    className="input-filed-label-style-common"
                    style={{
                      color: '#727171',
                    }}
                  >
                    {fields[0] ? 'Phone Number' : fields[1] ? 'Secondary Phone Number' : null}
                  </label>

                  <>
                    <div key={`${'primary'}-${'phone'}`} className="flex flex-row">
                      <span>
                        <PhoneInput
                          className="fontPoppinsRegularMd"
                          type="text"
                          id="phoneNumberEditId"
                          disabled={props.popupHeader === 'Edit User'}
                          placeholder="Enter phone number"
                          searchPlaceholder="search..."
                          searchNotFound="please try again"
                          specialLabel=""
                          value={formik?.values?.phoneNumbers?.primaryPhone}
                          country={formik.values.phoneNumbers.primaryPhone ? null : location?.country?.toLowerCase()}
                          inputStyle={{
                            ...rMd,
                            height: '26px',
                            width: '231px',
                            borderBottom: '1px solid #989797',
                            borderRadius: '0px',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderRight: 'none',
                            boxShadow: '0px 0px',
                            marginLeft: '-1px',
                          }}
                          buttonStyle={{
                            cursor: 'not-allowed',
                          }}
                          international
                          name="phoneNumberEditName"
                        />
                      </span>
                      <Tooltip title={'Primary Number'} placement="top">
                        <span className="primaryNo mt-2 p-0.5">P</span>
                      </Tooltip>
                    </div>
                    <div key={`${'secondary'}-${'phone'}`} className="flex flex-row">
                      {formik?.values?.phoneNumbers?.secondaryPhone?.length > 1 && (
                        <>
                          <span>
                            <PhoneInput
                              className="fontPoppinsRegularMd"
                              type="text"
                              id="secondaryPhoneEditId"
                              disabled={props.popupHeader === 'Edit User'}
                              specialLabel=""
                              value={formik?.values?.phoneNumbers?.secondaryPhone}
                              inputStyle={{
                                ...rMd,
                                height: '26px',
                                width: '231px',
                                borderBottom: '1px solid #989797',
                                borderRadius: '0px',
                                borderTop: 'none',
                                borderLeft: 'none',
                                borderRight: 'none',
                                boxShadow: '0px 0px',
                                marginLeft: '-1px',
                              }}
                              international
                              name="secondaryPhoneEditName"
                            />
                          </span>
                          <Tooltip title={'Secondary Number'} placement="top">
                            <span className="primaryNo mt-2 p-0.5">S</span>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </>

                  <p
                    id="visible"
                    className="text-white-500"
                    style={{
                      fontSize: '1px',
                      marginTop: '9px',
                      color: 'white',
                    }}
                  ></p>
                  {formik.values.privilege === 'Admin' ? (
                    <p id="visible" className="text-red-500" style={{ fontSize: '1px', color: 'white' }}></p>
                  ) : (
                    ''
                  )}
                  {formik.values.privilege === 'User' ? (
                    <p id="visible" className="text-red-500" style={{ fontSize: '1px', color: 'white' }}></p>
                  ) : (
                    ''
                  )}
                  {fields.length === 1 && (
                    <button
                      type="button"
                      style={{
                        color: '#727171',
                        left: '520px',
                        bottom: props.popupHeader === 'Edit User' ? '180px' : '110px',
                        position: 'absolute',
                      }}
                      className="fontPoppinsRegularSm HoverPhone"
                      hidden={
                        fields?.length > 1 || fields[0]?.value?.length < 10 || fields[0]?.value?.length === undefined
                      }
                      data-value={fields.length}
                      disabled={
                        (!fields[0]?.value && !isValidPhoneNumber(fields[0]?.value || '')) ||
                        fields[0]?.value.length < 10 ||
                        fields[0]?.value.length > 13
                      }
                      onClick={() => handleAdd()}
                    >
                      + Phone
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="pt-2 ml-2 border-t border-#e2e5ec-200" style={{ width: '104%', marginLeft: '-18px' }}>
          <div className="flex justify-end button_user" style={{ marginRight: '24px', paddingTop: '5px' }}>
            <button
              type="button"
              style={{ marginRight: '24px' }}
              className="gray-btn cancel-button-layout-style-user mr-3"
              onClick={closePopupFunction}
              onMouseDown={(e) => e.preventDefault()}
            >
              <span
                className="cancel-label-layout-style-user"
                style={{
                  color: '#0A0A0A',
                }}
              >
                Cancel
              </span>
            </button>

            {fill ? (
              formik?.values?.phoneNumbers?.primaryPhone?.length <= 13 &&
              formik?.values?.phoneNumbers?.primaryPhone?.length >= 14 ? (
                <button type="submit" className="primary-btn" onClick={submitBtn}>
                  <span>{existName && !errorContent ? 'Add User' : props.button}</span>
                </button>
              ) : (
                <button className="primary-btn">
                  <span>{existName ? 'Add User' : props.button}</span>
                </button>
              )
            ) : (
              <div
                onClick={() => {
                  errorMsg();
                  errorShow();
                }}
              >
                <button type="submit" className="primary-btn">
                  <span>{existName ? 'Add User' : props.button}</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
export default UserCreatePage;

import Stack from '@mui/material/Stack';
import { Tooltip } from '@material-ui/core';

export const ToggleHeadless = (props) => {
  let tooltipTitle = `Browser will launch without any user interface`;

  const disableToggle = () => {
    const browserName = props?.browserData?.selectedOption?.[0]?.value?.toLowerCase();
    if (props?.disabled) {
      return true;
    }
    if (props?.executionEnvData?.selectedOption?.[0].value?.toLowerCase().includes('local')) {
      if (browserName) {
        if (browserName.includes('chrome') || browserName.includes('edge') || browserName.includes('firefox')) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    }
    return true;
  };

  const displayToggle = () => {
    const projectType = props?.project?.type;
    if (projectType) {
      if (!['Web Service', 'Mobile'].includes(projectType)) {
        return true;
      }
    } else {
      return true;
    }
    return false;
  };

  return (
    <>
      {displayToggle() && (
        <div className="flex grid-cols-2 gap-1">
          <div className="w-36 fontPoppinsRegularMd">Headless Mode</div>
          <div>
            <Tooltip title={tooltipTitle} placement="bottom">
              <Stack direction="row" spacing={1} alignItems="center" className="headless-switch">
                <span className="mt-1.5">
                  <label className="switch mx-2.5">
                    <input
                      type="checkbox"
                      checked={props?.headlessSwitch}
                      className="switchdisable"
                      onChange={(e) => {
                        props?.handleHeadlessSwitch(e);
                      }}
                      disabled={disableToggle()}
                    />
                    <span className="slider round" />
                  </label>
                </span>
              </Stack>
            </Tooltip>
          </div>
        </div>
      )}
    </>
  );
};

import React, { useState, useEffect, useRef } from 'react';
import { usePagination, useTable, useGlobalFilter } from 'react-table';
import '../Analytics.scss';
import { Box, Modal, Fade } from '@mui/material';
import { ReactComponent as ExpandArrow } from '@assets/analytics/ExpandArrow.svg';
import { ReactComponent as ShrinkIcon } from '@assets/analytics/ShrinkIcon.svg';
import ScriptResultAccordion from './scripts-result-accordion';
import { ANALYTICS_MESSAGE } from '@common/ui-constants';
import { getScrollPosition } from './util';
import { isEmptyValue } from '@src/util/common_utils';
import SearchBar from './search-bar';
const TestScriptTable = (props) => {
  let {
    columns,
    data,
    scriptResultData,
    openScriptResultModal,
    closeResultModal,
    selectedScriptName,
    selectedScript,
    testScriptTableRef,
    setScrollPos,
  } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const bigTableRef = useRef();

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, state, setGlobalFilter } = useTable(
    {
      columns,
      data: data || [],
    },
    useGlobalFilter,
    usePagination
  );

  const { globalFilter } = state;

  useEffect(() => {
    testScriptTableRef.current.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [props.header]);

  // Effect to handle changes in data and reset search state
  useEffect(() => {
    if (isEmptyValue(data)) {
      handleSearchClose();
    }
  }, [data]);

  // TODO :: handle Search filter :: START
  let [openSearch, setOpenSearch] = useState(false);
  let handleSearchClose = () => {
    setOpenSearch(false);
    setGlobalFilter('');
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  // TODO :: handle Search filter :: END

  return (
    <>
      <div
        style={{
          borderColor: `${props.color}`,
        }}
        className="grid grid-cols-12 testscript-table "
      >
        <div
          className="col-span-12 analytics-table-header fontPoppinsSemiboldSm uppercase"
          style={{
            color: `${props.color}`,
          }}
        >
          {props.header}

          <span className="float-right">
            <span className="flex gap-1  justify-center">
              <SearchBar
                open={openSearch}
                searchValue={globalFilter || ''}
                handleSearch={(e) => {
                  setGlobalFilter(e.target.value);
                }}
                onSearchClose={handleSearchClose}
                handleSearchOpen={handleSearchOpen}
                disable={isEmptyValue(data)}
              />
              <span className="expandIconContainer" onClick={handleOpen}>
                <ExpandArrow className="expIcon" />
              </span>
            </span>
          </span>
        </div>
        <div className="col-span-12">
          <div className="col-span-12 testscript-table-container">
            <table {...getTableProps()} className="col-span-12 testscriptTable analytics-table">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    className=" AnalyticheaderStyle h-8 ml-4 fontPoppinsSemiboldSm "
                  >
                    {headerGroup.headers.map((column) => (
                      <th width={column.width} {...column.getHeaderProps()} className="text-left uppercase">
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody
                ref={testScriptTableRef}
                onScroll={() => getScrollPosition(testScriptTableRef, setScrollPos)}
                {...getTableBodyProps()}
                className="analytics-table-body"
              >
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className=" h-8 fontPoppinsMediumSm ">
                      {row.cells.map((cell) => {
                        return (
                          <td
                            width={cell.column.width}
                            {...cell.getCellProps()}
                            className="text-left fontPoppinsMediumSm"
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {(isEmptyValue(data) || isEmptyValue(rows)) && (
              <div
                className={
                  props.showfilter
                    ? 'testscript-table-message fontPoppinsMediumSm'
                    : 'testscript-message fontPoppinsMediumSm'
                }
              >
                {ANALYTICS_MESSAGE.NO_SCRIPTS_FOUND}
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className="expand-failer-modalTable">
            <div className="grid grid-cols-12 ">
              <div
                className="col-span-12 p-3 fontPoppinsSemiboldSm uppercase"
                style={{
                  color: `${props.color}`,
                }}
              >
                {props.header}

                <span className="float-right">
                  <span className="flex justify-center gap-1">
                    <SearchBar
                      open={openSearch}
                      searchValue={globalFilter || ''}
                      handleSearch={(e) => {
                        setGlobalFilter(e.target.value);
                      }}
                      onSearchClose={handleSearchClose}
                      handleSearchOpen={handleSearchOpen}
                      disable={isEmptyValue(data)}
                    />
                    <span className="expandIconContainer" onClick={handleClose}>
                      <ShrinkIcon />
                    </span>
                  </span>
                </span>
              </div>
              <div className="col-span-12 px-3">
                <table {...getTableProps()} className="col-span-12 analyticstableExpanded">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className="AnalyticheaderStyle h-8 ml-4 fontPoppinsSemiboldSm  "
                      >
                        {headerGroup.headers.map((column) => (
                          <th width={column.width} {...column.getHeaderProps()} className=" text-left uppercase">
                            {column.render('Header')}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody
                    ref={bigTableRef}
                    onScroll={() => getScrollPosition(bigTableRef, setScrollPos)}
                    {...getTableBodyProps()}
                    className="analytics-table-body"
                  >
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} className=" h-8 fontPoppinsMediumSm ">
                          {row.cells.map((cell) => {
                            return (
                              <td
                                width={cell.column.width}
                                {...cell.getCellProps()}
                                className="text-left  fontPoppinsMediumSm"
                              >
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {(isEmptyValue(data) || isEmptyValue(rows)) && (
                  <div
                    className={`${
                      props.showfilter ? 'testscript-table-message' : 'testscript-message'
                    } fontPoppinsMediumSm`}
                  >
                    {ANALYTICS_MESSAGE.NO_SCRIPTS_FOUND}
                  </div>
                )}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openScriptResultModal}
        onClose={() => closeResultModal()}
        closeAfterTransition
      >
        <Fade in={openScriptResultModal}>
          <Box className="expand-failer-modalTable">
            <div className="flex justify-between p-3">
              <div className="fontPoppinsSemiboldSm rs-blue uppercase modalHead-trimmer" title={selectedScriptName}>
                {selectedScriptName}
              </div>
              <div className=" expandIconContainer cursor-pointer" onClick={() => closeResultModal()}>
                <ShrinkIcon />
              </div>
            </div>
            {scriptResultData?.length !== 0 && openScriptResultModal && (
              <ScriptResultAccordion content={scriptResultData} selectedScript={selectedScript} />
            )}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
export default TestScriptTable;

import matchSorter from 'match-sorter';
// A great library for fuzzy filtering/sorting items

export function dateBetweenFilterFn(rows, id, filterValues) {
  let sd = filterValues[0] ? new Date(filterValues[0]) : undefined;
  let ed = filterValues[1] ? new Date(filterValues[1]) : undefined;
  if (ed || sd) {
    return rows.filter((r) => {
      const cellDate = new Date(r.values[id]);
      if (ed && sd) {
        return cellDate >= sd && cellDate <= ed;
      } else if (sd) {
        return cellDate >= sd;
      } else if (ed) {
        return cellDate <= ed;
      }
    });
  } else {
    return rows;
  }
}

export function fuzzyTextFilterFn(page, id, filterValue) {
  return matchSorter(page, filterValue, {
    keys: [(page) => page.values[id]],
  });
}

export function multipleRowFilterFn(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue !== undefined ? filterValue.includes(rowValue) : true;
  });
}

export function textFilterFn(page, id, filterValue) {
  return page.filter((row) => {
    const rowValue = row.values[id];
    return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true;
  });
}

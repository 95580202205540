import React, { memo } from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from 'fireflink-ui';
import { useHistory } from 'react-router';
import { ReactComponent as AccountCircleIcon } from '@assets/header-icons/user-profile.svg';
import { ReactComponent as ProfileIcon } from '@assets/header-icons/user-detail-icon.svg';
import cx from 'classnames';
import { LAYOUT_CONSTANTS } from '@pages/common/Layout/constants/LayoutConstants';
import styles from '@pages/common/Layout/styles/user_profile_style.module.scss';
import { signOutReq } from '@src/api/api_services';
import {
  getUserEmailId,
  getCurrentLicenseId,
  getAccessToken,
  getLicenseStatus,
  getUserStatus,
  getUserName,
} from '@src/util/common_utils';
import { TooltipPoppin } from '@src/pages/analytics/common/util';
import { isEmptyValue } from '@src/util/common_utils';
import { useEffect } from 'react';
import { getWebSiteHostName } from '@util/common_utils';
import { GLOBAL_CONSTANTS } from '@src/common/ui-constants';
import { Icon } from 'fireflink-ui';
import { colors } from '@src/css_config/colorConstants';

function UserProfileComponent() {
  const userStatus = getUserStatus();
  const licenseStatus = getLicenseStatus();
  const isDisabled = licenseStatus === 'EXPIRED' || userStatus === 'DISABLED';
  const emailId = getUserEmailId();
  const name = getUserName();
  const currentLicenseId = getCurrentLicenseId();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const checkIsMultiTabUser = () => {
    const accessToken = getAccessToken();
    const isMultiTabUser = isEmptyValue(accessToken);
    if (isMultiTabUser) {
      return true;
    }
    return false;
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (checkIsMultiTabUser()) {
  //       redirectToSignIn();
  //     }
  //   }, 500);
  // }, []);

  const handleClick = (event) => {
    if (!checkIsMultiTabUser()) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const callSignoutApi = () => {
    const accessToken = getAccessToken();
    let payload = {
      emailId,
      currentLicenseId,
    };

    signOutReq(payload)
      .then((response) => {
        if (response.data.status === GLOBAL_CONSTANTS.SUCCESS) {
          if (process?.env?.REACT_APP_ENVIRONMENT !== 'onprem') {
            localStorage.clear();
            window.location.reload();
            window.location.replace(getWebSiteHostName() + `/signin?product=fireflink-platform`);
          } else {
            localStorage.clear();
            redirectToSignIn();
          }
        }
      })
      .catch((error) => {
        console.error('SignOut API Failed!', error);
      });
  };

  let signOut = () => {
    if (isDisabled && window.location.pathname === '/licenses/fireflink-license') {
      callSignoutApi();
    } else {
      callSignoutApi();
    }
  };

  const redirectToSignIn = () => {
    window.location.replace(getWebSiteHostName() + `/signin?product=fireflink-platform`);
    window.location.reload();
  };

  return (
    <>
      <Tooltip title={LAYOUT_CONSTANTS.USER_PROFILE} disableInteractive>
        <Icon
          name="user_profile"
          onClick={handleClick}
          height={24}
          width={24}
          color={colors.rs_primary}
          className=" cursor-pointer mr-5 ml-5 mt-3"
        />
      </Tooltip>
      <Menu
        className={styles.user_profile_style}
        anchorEl={anchorEl}
        id="account-menu"
        open={!checkIsMultiTabUser() && open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            ml: 1.3,
            width: 260,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        MenuListProps={{
          sx: {
            color: LAYOUT_CONSTANTS.PRIMARY_COLOR,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div className="flex py-1 px-3 fontPoppinsRegularMd border-b border-solid border-gray-200">
          <div className="mt-1">
            <Avatar sx={{ width: 32, height: 32, backgroundColor: 'inherit' }}>
              <ProfileIcon />
            </Avatar>
          </div>
          <div className="">
            <TooltipPoppin title={name}>
              <div
                className={cx('overflow-hidden overflow-ellipsis whitespace-nowrap float-left w-52', styles['rsGrey'])}
              >
                {name}
              </div>
            </TooltipPoppin>
            <TooltipPoppin title={emailId}>
              <div className="overflow-hidden overflow-ellipsis whitespace-nowrap float-left w-52">{emailId}</div>
            </TooltipPoppin>
          </div>
        </div>
        <MenuItem className="fontPoppinsRegularMd" onClick={signOut}>
          {LAYOUT_CONSTANTS.SIGN_OUT}
        </MenuItem>
      </Menu>
    </>
  );
}

export default memo(UserProfileComponent);

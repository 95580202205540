import React, { useState, useEffect, useContext } from 'react';
import styles from './analytics.module.scss';
import cx from 'classnames';
import moment from 'moment';
import { getColorCode, statisticsModuleChartColor } from '@src/css_config/colorConstants';
import { CommonLoader } from '../common/common-loader';
import { getAnalyticsReq } from '@api/api_services';
import { ReactComponent as FilterOutline } from '@assets/analytics/FilterOutline.svg';
import { ReactComponent as FilterFilled } from '@assets/analytics/FilterFilled.svg';
import { getCurrentProjectId, isEmptyValue } from '@util/common_utils';
import { defectsCardLableStyling, defaultDate, noDataMessage, defectAnalyticsLabel } from '@common/ui-constants';
import { TooltipPoppin } from './common/util';
import { FilterSection } from '../common/filters/filter-section';
import DefectCardDetails from './components/defects-card/defects-card-details';
import SeverityStatistics from './components/severity-statistics/severity-statistics.js';
import PriorityStatistics from './components/priority-statistics/priority-statistics';
import ModuleStatistics from './components/module-statistics/module-statistics';
import StatusStatistics from './components/status-statistics/status-statistics';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import AllDefectsTable from './components/all-defects-table/all-defects-table.js';
import DefectsCycleTime from './components/defects-cycle-time/defectsCycleTime';
import { getCurrentProjectTimeZoneName } from '@src/util/common_utils';

const DefectsAnalytics = () => {
  let [showFiltersList, setShowFiltersList] = useState(false);
  let [defectsDb, setDefectsDb] = useState();
  let timeZoneName = getCurrentProjectTimeZoneName();
  const [currentTimeRange, setCurrentTimeRange] = useState({
    to: moment().tz(timeZoneName)?.format('DD/MM/YYYY'),
    from: moment().tz(timeZoneName)?.subtract(7, 'days').add(1, 'day').format('DD/MM/YYYY'),
  });
  let [filterValues, setFilterValues] = useState({});
  let [filterMenuData, setFilterMenuData] = useState([]);
  let [checkedCreatedByIds, setCheckedCreatedByIds] = useState([]);
  let [checkedAssignToIds, setCheckedAssignToIds] = useState([]);
  let [checkedLabelsIds, setCheckedLabelsIds] = useState([]);
  let [callAnalytics, setCallAnalytics] = useState(true);
  let [dataFound, setDataFound] = useState(false);
  let [isLoading, setIsLoading] = useState(true);
  let [showALLComponent, setshowALLComponent] = useState(false);
  let [reqBody, setReqBody] = useState(null);
  let [callAllApiOnFilterChange, setCallAllApiOnFilterChange] = useState(false);

  const { resetProgress, startOrResumeProgress } = useContext(ProgressContainerContext);

  // TODO :: Donut Series for Statitics :: START

  const chartIntialData = {
    series: [],
    label: [],
    colorCode: [],
  };
  let [severityChartData, setSeverityChartData] = useState(chartIntialData);
  let [priorityChartData, setPriorityChartData] = useState(chartIntialData);
  let [moduleChartData, setModuleChartData] = useState(chartIntialData);
  let [statusChartData, setStatusChartData] = useState(chartIntialData);

  // TODO :: Donut Series for Statitics :: END

  let [defectCardData, setDefectCardData] = useState([]);

  let checkboxLabel = 'checkbox';
  let filterHeaderData = {
    CreatedBy: {
      Header: 'Created By',
      MenuType: 'search-menu',
      RequestKey: 'createdBy',
    },
    AssignTo: {
      Header: 'Assign To',
      MenuType: 'search-menu',
      RequestKey: 'assignTo',
    },
    Labels: {
      Header: 'Labels',
      MenuType: 'search-menu',
      RequestKey: 'labels',
    },
  };

  let requestbody = {
    module: 'defectAnalytics',
    collectionName: 'defects',
    requestFilterRequired: 'true',
    responseFilterRequired: 'true',
    searchText: '',
    facetQueries: [
      {
        name: 'projectId',
        values: [getCurrentProjectId()],
      },
      {
        name: 'Date',
        values: [currentTimeRange.from, currentTimeRange.to],
      },
      {
        name: 'createdBy',
        values: [],
      },

      {
        name: 'assignTo',
        values: [],
      },
      {
        name: 'projectLabel',
        values: [],
      },
    ],
  };

  const handleTimePeriod = (from, to) => {
    setCheckedAssignToIds([]); // TO Remove selected checknox for AssignTo and CreatedBy
    setCheckedCreatedByIds([]);
    setCheckedLabelsIds([]);
    let req = Object.keys(filterValues).length === 0 ? requestbody : filterValues;
    requestbody = req;

    req.facetQueries[1].values = [from, to];

    setCurrentTimeRange({
      to: to,
      from: from,
    });
    setFilterValues(req);
    if (to !== defaultDate && from !== defaultDate) {
      setFilterMenuData([]);
      setCallAnalytics(true);
    }
  };

  //TODO :: handle Checkbox Filters :: START

  const handleAll = (e, data) => {
    let filterData = {
      name: data.label,
      value: e.target.checked ? data?.categories.map((x) => x.label) : [],
      checked: e.target.checked,
    };
    handleFilterValues(filterData, filterData.name, true);
  };

  const handleFilterValues = (filterData, filterGroup, selectAll = false) => {
    let chexValue;
    if (selectAll) {
      //TODO :: handle Select All Checkbox in filter
      chexValue = filterData;
      if (chexValue.checked) {
        if (filterGroup === 'Created By') {
          setCheckedCreatedByIds(chexValue.value);
        } else if (filterGroup === 'Assign To') {
          setCheckedAssignToIds(chexValue.value);
        } else {
          setCheckedLabelsIds(chexValue.value);
        }
      } else {
        if (filterGroup === 'Created By') {
          setCheckedCreatedByIds([]);
        } else if (filterGroup === 'Assign To') {
          setCheckedAssignToIds([]);
        } else {
          setCheckedLabelsIds([]);
        }
      }
    } else {
      //TODO :: handle Select Single Checkbox in filter

      chexValue = filterData.target;
      if (chexValue.checked) {
        if (filterGroup === 'Created By') {
          setCheckedCreatedByIds([...checkedCreatedByIds, chexValue.value]);
        } else if (filterGroup === 'Assign To') {
          setCheckedAssignToIds([...checkedAssignToIds, chexValue.value]);
        } else {
          setCheckedLabelsIds([...checkedLabelsIds, chexValue.value]);
        }
      } else {
        if (filterGroup === 'Created By') {
          setCheckedCreatedByIds([...checkedCreatedByIds.filter((ind) => ind !== chexValue.value)]);
        } else if (filterGroup === 'Assign To') {
          setCheckedAssignToIds([...checkedAssignToIds.filter((ind) => ind !== chexValue.value)]);
        } else {
          setCheckedLabelsIds([...checkedLabelsIds.filter((ind) => ind !== chexValue.value)]);
        }
      }
    }
    setCallAnalytics(true);
  };

  const createFiltersData = (data) => {
    let tempFilterData = [];
    tempFilterData[0] = {
      id: 'Menu1',
      label: filterHeaderData['CreatedBy'].Header,
      type: filterHeaderData['CreatedBy'].MenuType,
      categories: formSuiteData([data?.createdBy]),
      uiLabel: filterHeaderData['CreatedBy'].Header,
    };
    tempFilterData[1] = {
      id: 'Menu2',
      label: filterHeaderData['AssignTo'].Header,
      type: filterHeaderData['AssignTo'].MenuType,
      categories: formSuiteData([data?.assignedTo]),
      uiLabel: filterHeaderData['AssignTo'].Header,
    };
    tempFilterData[2] = {
      id: 'Menu3',
      label: filterHeaderData['Labels'].Header,
      type: filterHeaderData['Labels'].MenuType,
      categories: formSuiteData([data?.facetProjectLabelData]),
      uiLabel: filterHeaderData['Labels'].Header,
    };
    return tempFilterData;
  };

  const filtersDataHandler = (id, parentName, label, value, type, items, uiLabel, parentGroup) => {
    let tempObj = {};
    tempObj.id = id;
    tempObj.parentName = parentName;
    tempObj.label = label;
    tempObj.value = isEmptyValue(value) ? '' : value;
    tempObj.type = isEmptyValue(type) ? '' : type;
    tempObj.parentGroup = isEmptyValue(parentGroup) ? '' : parentGroup;
    tempObj.uiLabel = uiLabel;
    if (items !== undefined && items.length > 0 && items[0] !== null) {
      tempObj.categories = items.map((obj, index) => {
        return filtersDataHandler(`${label}-${obj}`, label, obj, false, '', [], uiLabel, parentGroup);
      });
    }
    return tempObj;
  };

  const formSuiteData = (data) => {
    let tempSuiteData = [];
    if (data[0]?.['name'] === 'Created By') {
      tempSuiteData = data.map((obj, index) => {
        return filtersDataHandler(
          `SubMenu_CreatedBy`,
          filterHeaderData['CreatedBy'].Header,
          obj.name,
          '',
          checkboxLabel,
          obj.values,
          filterHeaderData['CreatedBy'].Header,
          filterHeaderData['CreatedBy'].Header
        );
      });
    } else if (data[0]?.['name'] === 'Assign To') {
      tempSuiteData = data.map((obj, index) => {
        return filtersDataHandler(
          `SubMenu_AssignTo`,
          filterHeaderData['AssignTo'].Header,
          obj.name,
          '',
          checkboxLabel,
          obj.values,
          filterHeaderData['AssignTo'].Header,
          filterHeaderData['AssignTo'].Header
        );
      });
    } else if (data[0]?.['name'] === 'projectLabel') {
      tempSuiteData = data.map((obj, index) => {
        return filtersDataHandler(
          `SubMenu_Labels`,
          filterHeaderData['Labels'].Header,
          obj.name,
          '',
          checkboxLabel,
          obj.values,
          filterHeaderData['Labels'].Header,
          filterHeaderData['Labels'].Header
        );
      });
    }
    return tempSuiteData;
  };

  //TODO :: handle Checkbox Filters :: END

  // TODO ::  handle series and label for donutChart :: START
  let handleSeries = (chartData) => {
    let severityTempSeries = [];
    let severitydynamicLabel = [];
    let severityColorCode = [];

    let priorityDynamicLabel = [];
    let priortyTempSeries = [];
    let priroityColorCode = [];

    let moduleLabelTemp = [];
    let moduleTempSeries = [];

    let statusDynamicLabel = [];
    let statusTempSeries = [];
    let statusColorCode = [];

    chartData?.forEach((val) => {
      if (val.name === 'Severity') {
        val.defectData.forEach((s) => {
          // TODO :: set dynamic color, series and label values :: START
          severitydynamicLabel.push(s.name);
          severityTempSeries.push(Number(s.count));
          severityColorCode.push(getColorCode(s.name));
          // TODO  :: set dynamic color, series and label values  :: END
        });
      } else if (val.name === 'Priority') {
        val.defectData.forEach((s) => {
          priorityDynamicLabel.push(s.name);
          priortyTempSeries.push(Number(s.count));
          priroityColorCode.push(getColorCode(s.name));
        });
      } else if (val.name === 'Status') {
        val.defectData.forEach((s) => {
          statusDynamicLabel.push(s.name);
          statusTempSeries.push(Number(s.count));
          statusColorCode.push(getColorCode(s.name));
        });
      } else if (val.name === 'Modules') {
        val.defectData.forEach((s) => {
          moduleLabelTemp.push(s.name);
          moduleTempSeries.push(Number(s.count));
        });
      }
    });

    setSeverityChartData({
      series: severityTempSeries,
      label: severitydynamicLabel,
      colorCode: severityColorCode,
    });

    setPriorityChartData({
      series: priortyTempSeries,
      label: priorityDynamicLabel,
      colorCode: priroityColorCode,
    });

    setModuleChartData({
      series: moduleTempSeries,
      label: moduleLabelTemp,
      colorCode: statisticsModuleChartColor,
    });

    setStatusChartData({
      series: statusTempSeries,
      label: statusDynamicLabel,
      colorCode: statusColorCode,
    });
  };
  // TODO ::  handle series and label for donutChart :: END

  let getAnalyticsData = () => {
    let req = Object.keys(filterValues).length === 0 ? requestbody : filterValues;
    requestbody = req;
    requestbody.facetQueries[2].values = checkedCreatedByIds;
    requestbody.facetQueries[3].values = checkedAssignToIds;
    requestbody.facetQueries[4].values = checkedLabelsIds;

    setReqBody(requestbody);
    resetProgress();
    startOrResumeProgress({
      stopAt: 95,
      intervalDuration: 10,
    });

    getAnalyticsReq(requestbody)
      .then((respsone) => {
        const resultsData = respsone.data;
        if (resultsData) {
          setDataFound(resultsData.dataFound);
          if (resultsData.dataFound) {
            setCallAllApiOnFilterChange(true);
            setShowFiltersList(true);
            setDefectsDb(resultsData);

            if (resultsData.dataFound && resultsData?.defectCards?.length > 0) {
              setshowALLComponent(true);
              setDefectCardData(
                resultsData?.defectCards?.map((res, index) => {
                  return { ...res, ...defectsCardLableStyling[index] };
                })
              );
              setFilterMenuData(createFiltersData(resultsData?.facetData));
              handleSeries(resultsData?.defectChart);
              setIsLoading(false);
              resetProgress();
            } else {
              setshowALLComponent(false);
            }
          }
          setIsLoading(false);
          resetProgress();
        }
        setCallAnalytics(false);
        resetProgress();
      })
      .catch((err) => {
        resetProgress();
        console.error('DEFECTS ANANLYTICS API FAILED', err);
      });
  };

  // TODO  :: CALL API ON CHANGE OF FILTERS :: START

  useEffect(() => {
    if (callAnalytics) {
      getAnalyticsData();
      setCallAllApiOnFilterChange(false);
    }
  }, [currentTimeRange, callAnalytics]);

  // TODO  :: CALL API ON CHANGE OF FILTERS :: END

  // TODO :: TO OPEN Defect Details MODAL BUG SUMMARY :: START

  let [openSeverityBugSummaryModal, setOpenSeverityBugSummaryModal] = useState(false);
  let [openPriorityBugSummaryModal, setOpenPriorityBugSummaryModal] = useState(false);
  let [openModuleBugSummaryModal, setOpenModuleBugSummaryModal] = useState(false);
  let [openStatusBugSummaryModal, setOpenStatusBugSummaryModal] = useState(false);

  let [selectedDefectID, setSelectedDefectID] = useState('');

  const closeBugSummaryModal = () => {
    setOpenSeverityBugSummaryModal(false);
    setOpenPriorityBugSummaryModal(false);
    setOpenModuleBugSummaryModal(false);
    setOpenStatusBugSummaryModal(false);
  };

  const getBugSummary = (defectId, openModalFor) => {
    //! NOTE ::  send the selectedDefectID to bug-summary-details component
    if (defectId && openModalFor) {
      setSelectedDefectID(defectId);
      if (openModalFor === 'severityBugSummary') setOpenSeverityBugSummaryModal(true);
      else if (openModalFor === 'priorityBugSummary') setOpenPriorityBugSummaryModal(true);
      else if (openModalFor === 'moduleBugSummary') setOpenModuleBugSummaryModal(true);
      else if (openModalFor === 'statusBugSummary') setOpenStatusBugSummaryModal(true);
    }
  };
  // TODO :: TO OPEN Defect Details MODAL BUG SUMMARY :: END

  return (
    <section className={styles['analyticsPage']}>
      {isLoading ? (
        <CommonLoader />
      ) : dataFound ? (
        <article className={styles['analyticsBody']}>
          <div className={cx(showFiltersList ? 'w-9/12' : 'w-full', styles['scriptsBody'])}>
            {showALLComponent ? (
              <>
                <div className={styles['statusBlock']}>
                  {defectCardData?.map((status, index) => {
                    return (
                      <DefectCardDetails
                        count={status.count}
                        key={status.name + index}
                        name={status.name}
                        color={status.color}
                        iconBg={status.iconBg}
                      />
                    );
                  })}
                </div>
                <div className={styles['scriptTrendChartBlock']}>
                  <div className={styles['scriptTrendHeader']}>
                    <span className={cx('fontPoppinsSemiboldSm uppercase', styles['leftHeader'])}>
                      {defectAnalyticsLabel.header.DEFECTS_CYCLE_TIME}
                    </span>
                    <span className={cx('fontPoppinsSemiboldXs', styles['rightHeader'])}>
                      {defectAnalyticsLabel.header.DEFECTS_VS_DAYS}
                    </span>
                  </div>
                  <DefectsCycleTime defectsDb={defectsDb} reqBody={reqBody} showFilter={showFiltersList} />
                </div>

                <section className="defect_Statistics section-box w-full p-3">
                  <div className="section_title rs-blue uppercase fontPoppinsSemiboldSm">
                    {defectAnalyticsLabel.header.DEFECTS_STATISTICS}
                  </div>
                  <article className="MulltiSectionBlock flex flex-col gap-4 py-2">
                    <SeverityStatistics
                      openSeverityBugSummaryModal={openSeverityBugSummaryModal}
                      closeBugSummaryModal={closeBugSummaryModal}
                      getBugSummary={getBugSummary}
                      selectedDefectID={selectedDefectID}
                      severityChartData={severityChartData}
                      reqBody={reqBody}
                      callAllApiOnFilterChange={callAllApiOnFilterChange}
                    />

                    <PriorityStatistics
                      openPriorityBugSummaryModal={openPriorityBugSummaryModal}
                      closeBugSummaryModal={closeBugSummaryModal}
                      getBugSummary={getBugSummary}
                      selectedDefectID={selectedDefectID}
                      priorityChartData={priorityChartData}
                      reqBody={reqBody}
                      callAllApiOnFilterChange={callAllApiOnFilterChange}
                    />

                    <ModuleStatistics
                      openModuleBugSummaryModal={openModuleBugSummaryModal}
                      closeBugSummaryModal={closeBugSummaryModal}
                      getBugSummary={getBugSummary}
                      selectedDefectID={selectedDefectID}
                      moduleChartData={moduleChartData}
                      reqBody={reqBody}
                      callAllApiOnFilterChange={callAllApiOnFilterChange}
                    />

                    <StatusStatistics
                      openStatusBugSummaryModal={openStatusBugSummaryModal}
                      closeBugSummaryModal={closeBugSummaryModal}
                      getBugSummary={getBugSummary}
                      selectedDefectID={selectedDefectID}
                      statusChartData={statusChartData}
                      reqBody={reqBody}
                      callAllApiOnFilterChange={callAllApiOnFilterChange}
                    />
                  </article>
                </section>
                <section className={cx(`${styles['all_defectes_list_section']} section-box`)}>
                  <AllDefectsTable
                    defectsDb={defectsDb}
                    reqBody={reqBody}
                    callAllApiOnFilterChange={callAllApiOnFilterChange}
                  />
                </section>
              </>
            ) : (
              <div className={styles['flex-ana-container']}>
                <div>
                  <div className={cx('fontPoppinsSemiboldLg', styles['flex-ana-item'])}>
                    {noDataMessage.defectsNotFound}
                  </div>
                  <div className={cx('fontPoppinsSemiboldLg', styles['flex-ana-item'])}>
                    {noDataMessage.defectsNotFoundForFilter}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={cx(showFiltersList ? styles['checkedFilter'] : styles['uncheckedFilter'])}>
            <div
              className={cx(
                `fontPoppinsSemiboldLg flex flex-col items-center justify-between  ${
                  showFiltersList
                    ? styles['analytics_filters_header_open']
                    : styles[' analytics_filters_header_closed ']
                }`
              )}
            >
              <div className="flex justify-between items-center w-full">
                {showFiltersList ? (
                  <div className=" fontPoppinsSemiboldSm rs-blue "> {defectAnalyticsLabel.header.FILTERS}</div>
                ) : null}

                <button
                  className={styles['analytics_filter_icon']}
                  onClick={() => {
                    showFiltersList ? setShowFiltersList(false) : setShowFiltersList(true);
                  }}
                >
                  {showFiltersList ? (
                    <FilterOutline className={styles['analytics_filter_icon']} />
                  ) : (
                    <TooltipPoppin placement="top" title="Filter">
                      <FilterFilled className={styles['analytics_filter_icon']} />
                    </TooltipPoppin>
                  )}
                </button>
              </div>

              <div className=" w-full overflow-auto" style={{ display: `${showFiltersList ? 'Block' : 'none'}` }}>
                <div className="filtermenu">
                  <FilterSection
                    data={filterMenuData}
                    handleTimePeriod={handleTimePeriod}
                    handleFilterValues={handleFilterValues}
                    handleAll={handleAll}
                    defectSection={true}
                    checkedAssignToIds={checkedAssignToIds}
                    setCheckedAssignToIds={setCheckedAssignToIds}
                    checkedCreatedByIds={checkedCreatedByIds}
                    setCheckedCreatedByIds={setCheckedCreatedByIds}
                    checkedLabelsIds={checkedLabelsIds}
                    setCheckedLabelsIds={setCheckedLabelsIds}
                  />
                </div>
              </div>
            </div>
          </div>
        </article>
      ) : (
        <article className={styles['flex-ana-container']}>
          <div>
            <div className={cx('fontPoppinsSemiboldLg', styles['flex-ana-item'])}>{noDataMessage.defectsNotFound}</div>
            <div className={cx('fontPoppinsSemiboldLg', styles['flex-ana-item'])}>
              {noDataMessage.defectsNotCreated}
            </div>
          </div>
        </article>
      )}
    </section>
  );
};

export default DefectsAnalytics;

import React, { useContext, useEffect } from 'react';
import '../../auth.scss';
import { ASSERT_CONSTANT } from '../../../assert/jsx/constant';
import { RestContext } from '../../../../restapi';
import AlgorithmDetails from './AlgorithmDetails';
import JwtSelectDetails from './JwtSelectDetails';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useSyncContextValues from '@src/hooks/useSyncContextValues';

const JWTBearer = ({ selectedStepData, ...props }) => {
  const context = useContext(RestContext);

  const {
    ACTION_OPTIONS,
    ALGORITHM,
    ADD_TOKEN_TO,
    ALGORITHM_OPTIONS,
    ACTION,
    ALGORITHM_TITLE,
    JWT_SECRET_KEY_VALIDATION,
    JWT_PRIVATE_KEY_VALIDATION,
    REQUEST_HEADER_TOOLTIP,
    ALGORITHM_TOOLTIP,
  } = ASSERT_CONSTANT;

  const formik = useFormik({
    initialValues: {
      action: 'requestHeader',
      algorithm: 'HS256',
      secret: '',
      privateKey: '',
      payload: '',
      tokenPrefix: '',
      jwtHeaders: '',
      privateKeyFileName: '',
      encoded: false,
      ...(context.values.jwtAuth || {}),
    },
    validationSchema: Yup.object({
      secret: Yup.string().when('algorithm', {
        is: (algorithm) => algorithm.includes('HS'),
        then: Yup.string().required(JWT_SECRET_KEY_VALIDATION),
        otherwise: Yup.string().notRequired(),
      }),
      privateKey: Yup.string().when('algorithm', {
        is: (algorithm) => !algorithm.includes('HS'),
        then: Yup.string().required(JWT_PRIVATE_KEY_VALIDATION),
        otherwise: Yup.string().notRequired(),
      }),
    }),
  });

  useSyncContextValues(formik, context, 'jwtAuth');
  const { algorithm, jwtAuth, action } = context.values;

  useEffect(() => {
    if (algorithm.label !== jwtAuth.algorithm) {
      context.values.algorithm = filterAlgorithmOption();
    }
    if (action.label !== jwtAuth.action) {
      context.values.action = filterActionOption();
    }
    formik.validateForm().then((errors) => context.setFieldValue('errors', errors));
    return () => context.setFieldValue('currentJwtBearerToken', {});
  }, [algorithm, formik.values.privateKey, formik.values.secret]);

  const filterActionOption = () => ACTION_OPTIONS.find((option) => option.value === formik.values.action);
  const filterAlgorithmOption = () => ALGORITHM_OPTIONS.find((option) => option.value === formik.values.algorithm);

  const onSelectJWT = (e, contextValue) => {
    formik.setFieldValue(contextValue, e.value);

    context.setFieldValue(contextValue, e);
    context.setFieldValue('jwtAuth', {
      ...context.values.jwtAuth,
      [contextValue]: e.value,
    });

    props.handleSaveAsSteps(false);
  };

  const jwtBearerChange = (e) => {
    let { name, value, checked, data } = e?.target;
    const jwtAuth = context?.values?.jwtAuth || {};
    if (value === false) {
      value = { ...jwtAuth, [name]: checked };
    } else {
      value = { ...jwtAuth, [name]: props.getUserInputValue(value, false, data, action) };
    }
    context.setFieldValue('authType', 'JWTBearer');
    context.setFieldValue('jwtAuth', value);
    formik.handleChange(e);
    const currentBearerTokenValue = { ...(context.values.currentJwtBearerToken || {}), [name]: e };
    context.setFieldValue('currentJwtBearerToken', currentBearerTokenValue);

    formik.validateForm(value).then((errors) => {
      context.setFieldValue('errors', errors);
    });

    props.handleSaveAsSteps(false);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="jwt-bearer-container">
        <div className="jwt-bearer">
          <div className="jwt-select-algo">
            <div>
              <JwtSelectDetails
                options={ACTION_OPTIONS}
                jwtTitle={ADD_TOKEN_TO}
                jwtTooltipTitle={REQUEST_HEADER_TOOLTIP}
                onSelectJwt={onSelectJWT}
                selectedOption={filterActionOption()}
                contextValue={ACTION}
              />
            </div>
            <div className="mt-2 ">
              <JwtSelectDetails
                options={ALGORITHM_OPTIONS}
                jwtTitle={ALGORITHM_TITLE}
                jwtTooltipTitle={ALGORITHM_TOOLTIP}
                onSelectJwt={onSelectJWT}
                selectedOption={filterAlgorithmOption()}
                contextValue={ALGORITHM}
              />
            </div>
          </div>
          <AlgorithmDetails title={algorithm?.header} formik={formik} jwtBearerChange={jwtBearerChange} {...props} />
        </div>
      </div>
    </form>
  );
};

export default JWTBearer;

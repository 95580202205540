import React, { useEffect } from 'react';
import { InputLabel } from '@material-ui/core';
import { Field } from 'formik';
import Select from 'react-dropdown-select';

function DataproviderSuggestion(props) {
  const dataProviderClick = (data) => {
    if (data && data.length) {
      props.setSelectedDataProviderData(data[0]);
      props.setDataProviderName(data[0].name);
      props.setVariableValue(data[0].name, props.stepInputName, 'startIteration');
      props.setFieldValue(`${props.stepInputName}`, data[0].name);
    } else {
      props.setSelectedDataProviderData([]);
      props.setDataProviderName('');
      props.setVariableValue('', props.stepInputName, 'startIteration');
      props.setFieldValue(`${props.stepInputName}`, '');
    }
  };

  const selectedDataProviderForEdit = () => {
    if (props.initialValues.dataProviderName && props.dataProviderData && props.dataProviderData.length) {
      const _selectedValue = props.dataProviderData.find((opt) => opt.name === props.initialValues.dataProviderName);
      return _selectedValue ? [_selectedValue] : [];
    }
    return [];
  };

  useEffect(() => {
    props.setIsDataProviderModal(true);
    if (props.initialValues.dataProviderName && props.dataProviderData && props.dataProviderData.length) {
      const _selectedValue = props.dataProviderData.find((opt) => opt.name === props.initialValues.dataProviderName);
      if (!_selectedValue) {
        props.setFieldValue(`${props.stepInputName}`, '');
        props.setSelectedDataProviderData('');
      } else {
        props.setSelectedDataProviderData(_selectedValue);
        props.setDataProviderName(_selectedValue?.name);
      }
    } else {
      props.setDataProviderName('');
    }
  }, []);

  return (
    <>
      <div className="text-xs text-blue-700" id="hovered">
        <Field name={props.stepInputName}>
          {({ field, form, meta }) => {
            return (
              <div>
                <InputLabel shrink htmlFor="type">
                  <span className="text-red-500">&#42;</span>{' '}
                  <span className={'text-grey-500'}>Data Provider Name</span>
                </InputLabel>
                <div className=" mt-1.5">
                  <Select
                    options={props.dataProviderData && props.dataProviderData.length ? props.dataProviderData : []}
                    searchable={true}
                    clearable={true}
                    values={selectedDataProviderForEdit()}
                    labelField="name"
                    valueField="name"
                    searchBy="name"
                    closeOnSelect={true}
                    className="dp-select pl-0 text-sm font-semibold"
                    multi={false}
                    style={{ border: '0', borderBottom: '3px solid #1647c4' }}
                    placeholder="Search and Select a dataprovider"
                    onChange={(values) => dataProviderClick(values)}
                  />
                </div>
                {meta.touched && meta.error && (
                  <div className="errorMessage">
                    {meta.error.charAt(0).toUpperCase() + meta.error.slice(1).toLowerCase()}
                  </div>
                )}
              </div>
            );
          }}
        </Field>
      </div>
    </>
  );
}

export default DataproviderSuggestion;

import React from 'react';
import { Route } from 'react-router-dom';
import LicenceManagement from './pages';
import AddLicenceManagement from './pages/AddLicenceManagement';
import { Routes } from './Routes';
function LicenceManagementRouting() {
  const routes = [
    {
      path: Routes.baseroute,
      exact: true,
      component: LicenceManagement,
    },
    {
      path: Routes.addlicence,
      exact: true,
      component: AddLicenceManagement,
    },
  ];

  return (
    <>
      {routes.map((route) => (
        <Route {...route} />
      ))}
    </>
  );
}

export default LicenceManagementRouting;

import React from 'react';

import TextField from '@mui/material/TextField';

import { BITBUCKET_CONSTANTS } from '../plugin_constants';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import CommonDrawerJs from '../CommonIntegrationDrawer';
import { syncPassword } from '@api/api_services';
import { colors } from '@src/css_config/colorConstants';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { getEncryptData } from '@common/security';

const SyncronizeInstance = (props) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const emailId = JSON.parse(localStorage.getItem('test-optimize-user')).userName;
  const initialValues = {
    password: '',
  };
  const validationSchema = yup.object({
    password: yup.string().required('Password  is required'),
  });
  const handleSync = () => {
    if (formikDetails.values.password.trim() === '') {
      formikDetails.setFieldError('password', 'Password is required');
    } else {
      let payload = {
        bitBucketUserName: props.seletedInstance.bitBucketUserName,
        bitBucketAppPassword: getEncryptData(props.seletedInstance.bitBucketAppPassword),
        bitBucketWorkspaceUUID: props.seletedInstance.bitBucketWorkspaceUUID,
        fireFlinkPassword: getEncryptData(formikDetails?.values.password),
      };

      syncPassword(payload, props?.seletedInstance?.id).then((response) => {
        if (response?.data?.status === 'SUCCESS') {
          props.onClose();
          props.reloadTree();
          props.MyAlert.success(
            `${props.seletedInstance.instanceName} ${BITBUCKET_CONSTANTS.SYNCHRONIZED_SUCCESSFULLY}`
          );
        } else if (response.data.responseCode === 400 && response.data.responseObject === null) {
          formikDetails.setFieldError('password', 'Invalid Password');
        } else if (response.data.responseCode === 400 && response.data.status.includes('Server Busy')) {
          props.onClose();
          props.MyAlert.warning(response?.data?.message);
        }
      });
    }
  };

  const formikDetails = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSync,
  });
  return (
    <CommonDrawerJs
      isDrawerOpen={true}
      headerContent={`${BITBUCKET_CONSTANTS.SYNCHRONIZE_INSTANCE} - ${props?.seletedInstance?.instanceName}`}
      drawerWidth="450px"
      rightButtonText="Sync"
      leftButtonText="Cancel"
      isLeftButtonVisible={true}
      onDrawerOpen={props.onClose}
      onRightButtonClick={handleSync}
      onLeftButtonClick={props.onClose}
    >
      <section className="w-full  flex flex-col justify-center items-center">
        <div className=" flex flex-col mt-4">
          <div className="integration-label fontPoppinsMediumSm">
            {
              <>
                {' '}
                <span className="text-red-600">*</span> {BITBUCKET_CONSTANTS.EMAIL}
              </>
            }
          </div>
          <div className="mt-2">
            <TextField
              className="instance-input-field w-auto"
              variant="outlined"
              name="name"
              autoComplete="off"
              placeholder="Enter Email"
              value={emailId}
              disabled={true}
              style={{ width: '420px', background: colors.rs_disabled_color }}
            />
          </div>
        </div>
        <div className="flex flex-col mt-2">
          <div className="integration-label fontPoppinsMediumSm">
            {
              <>
                {' '}
                <span className="text-red-600">*</span> {BITBUCKET_CONSTANTS.PASSWORD}
              </>
            }
          </div>
          <div className="mt-2">
            <TextField
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                      {showPassword ? (
                        <VisibilityOutlinedIcon className="mt-0 text-[#727171]" />
                      ) : (
                        <VisibilityOffOutlinedIcon className="mt-0 ml-2 text-[#727171]" />
                      )}
                    </span>
                  </InputAdornment>
                ),
              }}
              autoComplete="new-password"
              style={{ width: '420px' }}
              className="instance-input-field"
              placeholder="Enter Fireflink Password"
              name="password"
              value={formikDetails.values.fireflinkPassword}
              onChange={formikDetails.handleChange}
              onBlur={formikDetails.handleBlur}
              error={formikDetails.errors.password}
            />
          </div>
          {formikDetails.errors.password && (
            <div className="error-message mt-2 fontPoppinsRegularXs">{formikDetails.errors.password}</div>
          )}
        </div>
      </section>
    </CommonDrawerJs>
  );
};

export default SyncronizeInstance;

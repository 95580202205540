import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CommonTable from '@common/atoms/TableComponent';
import CommonDrawer from '@common/atoms/CommonDrawer';
import CommonButton from '@common/button/Button';
import { DEFECT_CONSTANTS, ALERT_MODAL_CONSTANTS } from '@src/common/ui-constants';
import { useAlert } from '@pages/common/alert_service/useAlert';
import DefectDetailsDrawer from '@pages/configuration/DefectConfig/DefectDetailsDrawer';
import { getSelectedProject } from '@src/service/common_service';
import {
  getDefectListColumns,
  getScriptSpecificDefects,
  getStepsNameAutomationSteps,
  getStepsNameResultSteps,
  getStepsNameResultTree,
} from '@pages/configuration/DefectConfig/defect-management-service';
import GitlabIssueDrawer from '@pages/configuration/DefectConfig/defect-drawers/GitlabIssueDrawer';
import {
  getGitlabInstanceReq,
  getTemplateForEmailConfigReq,
  getAllJiraList,
  getAllSlackInstance,
  getDefectsStatistic,
  getScriptResultReq,
  getMandatoryFieldForJIRA,
  _getTemplateForEmailConfigReq,
  _getGitlabMandatoryField,
} from '@api/api_services';
import FireFlinkIssueDrawer from '@pages/configuration/DefectConfig/defect-drawers/FireflinkIssueDrawer';
import JiraIssueDrawer from '@pages/configuration/DefectConfig/defect-drawers/JiraIssueDrawer';
import SlackIssueDrawer from '@pages/configuration/DefectConfig/defect-drawers/SlackIssueDrawer';
import styles from '@pages/configuration/DefectConfig/defect-config.module.scss';
import { DEFAULT_STATE_VALUE, JIRA_ISSUE_DRAWER } from '@pages/configuration/DefectConfig/defect-constants';
import UpdateDefectPlatform from '@pages/configuration/DefectConfig/UpdateDefectPlatform';
import NavigateToCreateInstanceModal from '@pages/configuration/system_config/system_config v1.1/modals/navigate-to-instance-modal';
import AlertPopUps from '@src/pages/common/alert_pop_ups/AlertPopUps';
import { getAdminPrivilege, getSuperAdminPrivilege, getUserName, isEmptyValue } from '@src/util/common_utils';

const DefectList = ({
  history,
  cleanUpFunction = () => {},
  closeDrawer = () => {},
  openDrawer = true,
  createButtonRequired = false,
  scriptData = {},
  issueData = {},
}) => {
  const { WARNING } = ALERT_MODAL_CONSTANTS;
  const [openTemplateMismatchModal, setTemplateMismatchModal] = useState(false);
  const { AlertContatiner, MyAlert } = useAlert();
  const { _key, testCaseType, id, key } = scriptData;
  const [reloadTable, setReloadTable] = useState(true);
  const [searchTextHighlight, setSearchTextHighlight] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const selectedProject = getSelectedProject();
  const [tableData, setTableData] = useState([]);
  const [openDefectListDrawer, setOpenDefectListDrawer] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState('');
  const [updatePlatform, setUpdatePlatform] = useState(DEFAULT_STATE_VALUE.UPDATEPLATFORM);
  const [openDetailsDrawer, setOpenDetailsDrawer] = useState(false);
  const [selectedDefectData, setSelectedDefectData] = useState({});
  const [fireFlinkIssueDrawer, setFireFlinkIssueDrawer] = useState(DEFAULT_STATE_VALUE.FIREFLINK);
  const [gitlabIssueDrawer, setGitlabIssueDrawer] = useState(DEFAULT_STATE_VALUE.GITLAB);
  const [jiraIssueDrawer, setJiraIssueDrawer] = useState(DEFAULT_STATE_VALUE.JIRA);
  const [slackIssueDrawer, setSlackIssueDrawer] = useState(DEFAULT_STATE_VALUE.SLACK);
  const [navigateToInstance, setNavigateToInstance] = useState({
    openNavigationModal: false,
    data: null,
  });

  const historyPath = useHistory();
  const isSuperAdmin = getSuperAdminPrivilege();
  const isAdmin = getAdminPrivilege();
  const userName = getUserName();

  const [misMatchTemplateData, setMisMatchTemplateData] = useState();

  const onCancelClicked = () => {
    closeIssueDrawer('JIRA')();
    setTemplateMismatchModal(false);
  };

  const missMatchModalHandler = (data) => {
    setTemplateMismatchModal(true);
    setMisMatchTemplateData(data);
  };

  const navigateToTemplate = () => {
    const isProjectIdPresent = jiraIssueDrawer?.defectMgmtData?.defect_details_templates[0]?.projectId;
    const defectTemplatePath = JIRA_ISSUE_DRAWER.TEMPLATE_MISMATCH.NAVIGATION_PATH;

    if (cleanUpFunction) {
      cleanUpFunction();
    }
    if (isProjectIdPresent) {
      if (history) {
        history.push(defectTemplatePath);
      } else {
        historyPath.push(defectTemplatePath);
      }
    } else {
      localStorage.setItem('selected-project', JSON.stringify({ id: '', name: 'All Projects' }));
      setTimeout(() => {
        if (history) {
          history.replace(defectTemplatePath);
        } else {
          historyPath.replace(defectTemplatePath);
        }
      }, 100);
    }
  };

  const mismatchTableData = () => {
    return (
      <table>
        <thead className="sticky top-0 fontPoppinsRegularSm">
          <tr className={styles['table-header-style']}>
            <th>{JIRA_ISSUE_DRAWER.TEMPLATE_MISMATCH.MISMATCH_FIELDS}</th>
          </tr>
        </thead>
        <tbody>
          {misMatchTemplateData?.map((item, index) => (
            <tr key={index} className="text-left border-b-2 fontPoppinsRegularSm">
              <td>{item}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const templateMismatchFunction = () => {
    if (isSuperAdmin || isAdmin) {
      return (
        <>
          <div className="mb-4">Hi {userName},</div>
          <div className="mb-3">
            {JIRA_ISSUE_DRAWER.TEMPLATE_MISMATCH.ADMIN_HEADER_CONTENT}{' '}
            <span className={styles['navigation-text-color']} onClick={navigateToTemplate}>
              {JIRA_ISSUE_DRAWER.TEMPLATE_MISMATCH.NAVIGATE_DEFECT_TEMPLATE}
            </span>
          </div>
          <div className="overflow-auto h-12">{mismatchTableData()}</div>
        </>
      );
    } else {
      return (
        <>
          <div className="mb-4">Hi {userName},</div>
          <div>{JIRA_ISSUE_DRAWER.TEMPLATE_MISMATCH.USER_CONTENT_MESSAGE}</div>
        </>
      );
    }
  };

  const handleClose = useCallback(() => {
    closeDrawer(false);
  }, [closeDrawer]);

  const handleNavigationModal = (viewNavigationModal, instanceName) => {
    if (!viewNavigationModal && tableData.length === 0) {
      handleClose();
    } else {
      setOpenDefectListDrawer(true);
    }
    if (viewNavigationModal) {
      setOpenDefectListDrawer(false);
    }
    setNavigateToInstance({
      openNavigationModal: viewNavigationModal,
      data: instanceName,
    });
  };

  async function getDefectStatistic() {
    try {
      const {
        data: { responseObject },
      } = await getDefectsStatistic();
      if (responseObject) {
        setUpdatePlatform({
          openDrawer: true,
          data: responseObject,
          projectDetails: selectedProject,
        });
      }
    } catch (error) {
      console.error('GET_DEFECT_STATISTIC : ', error);
    }
  }

  async function getDefectsList() {
    try {
      setIsLoading(true);
      let payload;
      if (testCaseType && Array.isArray(testCaseType)) {
        payload = {
          testCaseType: [...testCaseType],
          type: 'all',
        };
      } else {
        payload = {
          testCaseType: [{ id: key ? key : _key ? _key : id }],
          type: 'all',
        };
      }
      const { dataList } = await getScriptSpecificDefects(payload);
      setTableData(dataList);
      setIsLoading(false);
      return dataList;
    } catch (error) {
      console.error('GET_DEFECTS_API_ERROR:', error);
    }
  }

  useEffect(() => {
    if (reloadTable) {
      (async () => {
        try {
          const dataList = await getDefectsList();
          if (!dataList?.length && createButtonRequired) {
            const { platform } = selectedProject?.defectPlatform || {};
            if (platform) {
              createDefectHandler();
            } else {
              getDefectStatistic();
            }
          } else {
            setOpenDefectListDrawer(true);
          }
        } catch (error) {
          console.error('GET_DEFECTS_LIST ', error);
        }
      })();
      setReloadTable(false);
    }
  }, [reloadTable]);

  const handleOnIdClick = (id, rowData) => {
    setSelectedRowData(rowData.original);
    setOpenDetailsDrawer(true);
  };

  const closeDetailsDrawer = () => {
    setSelectedRowData('');
    setOpenDetailsDrawer(false);
  };

  const updatePlatformHandler = (projectData) => {
    selectedProject.defectPlatform = { ...projectData.defectPlatform };
    localStorage.setItem('selected-project', JSON.stringify(selectedProject));
    createDefectHandler();
  };

  const closeIssueDrawer = (defectPlatform) => (isIssueCreated) => {
    if (tableData.length === 0 && isIssueCreated !== true) {
      handleClose();
    } else {
      setOpenDefectListDrawer(true);
    }
    switch (defectPlatform) {
      case 'UPDATEPLATFORM':
        setUpdatePlatform(DEFAULT_STATE_VALUE.UPDATEPLATFORM);
        break;
      case 'FIREFLINK':
        setFireFlinkIssueDrawer(DEFAULT_STATE_VALUE.FIREFLINK);
        break;
      case 'GITLAB':
        setGitlabIssueDrawer(DEFAULT_STATE_VALUE.GITLAB);
        break;
      case 'SLACK':
        setSlackIssueDrawer(DEFAULT_STATE_VALUE.SLACK);
        break;
      case 'JIRA':
        setJiraIssueDrawer(DEFAULT_STATE_VALUE.JIRA);
        break;
      default:
        break;
    }
  };

  const columns = useMemo(() => getDefectListColumns(searchTextHighlight, handleOnIdClick), [searchTextHighlight]);

  const getFailedSteps = async () => {
    if (issueData?.nodeObj?.data?.entityId) {
      return await getScriptData(
        issueData,
        issueData.nodeObj?.data?._key ? issueData.nodeObj?.data?._key : issueData.nodeObj?.data?.key
      );
    } else {
      return getFailedScriptSteps(issueData?.nodeObj, issueData?.stepsDetails?.scriptResultData);
    }
  };

  const getFailedScriptSteps = (treeNodeObj, scriptResultData) => {
    if (issueData?.section === 'resultTree') {
      return getStepsNameResultTree(treeNodeObj);
    } else if (issueData?.section === 'resultSteps') {
      return getStepsNameResultSteps(issueData?.stepsDetails?.data, scriptResultData);
    } else {
      return getStepsNameAutomationSteps(issueData?.stepsDetails?.data, scriptResultData);
    }
  };

  const getScriptData = async (issueData, scriptId) => {
    try {
      const response = await getScriptResultReq(
        issueData?.executionId,
        issueData?.clientId,
        issueData?.runId,
        scriptId
      );
      if (response?.data?.responseCode === 200 && response.data.responseObject) {
        return getFailedScriptSteps({ data: response.data.responseObject }, response.data.responseObject);
      }
    } catch (err) {
      console.error('GET_SCRIPT_DATA : ', err);
    }
  };

  const openJiraIssueDrawer = async () => {
    try {
      const jiraFleldResponse = await getMandatoryFieldForJIRA(selectedProject.id);
      const response = await getAllJiraList();
      if (jiraFleldResponse?.data?.responseCode === 200) {
        if (response?.data?.responseCode === 200 && response?.data?.responseObject?.length > 0) {
          setJiraIssueDrawer({
            openDrawer: true,
            defectMgmtData: jiraFleldResponse?.data?.responseObject,
            data: {
              steps: await getFailedSteps(),
              envDetails: {
                section: issueData?.section,
                systemData: issueData?.systemData,
              },
              isFromSteps: !['resultTree', 'resultSteps'].includes(issueData?.section),
              scriptRunDetails: getScriptRunDetails(issueData),
            },
            instances: [...response?.data?.responseObject],
          });
        } else {
          handleNavigationModal(true, 'Jira');
        }
      } else {
        handleNavigationModal(true, 'Jira');
      }
    } catch (err) {
      console.error('GET_JIRA_DEFECT : ', err);
    }
  };

  const openSlackIssueDrawer = async () => {
    try {
      const response = await getAllSlackInstance();
      const fieldResponse = await _getTemplateForEmailConfigReq(selectedProject.id);

      if (fieldResponse?.data?.responseCode === 200) {
        if (response?.data?.responseCode === 200 && !isEmptyValue(response?.data?.responseObject)) {
          const slackInstances = response.data.responseObject;
          setSlackIssueDrawer({
            openDrawer: true,
            defectMgmtData: fieldResponse?.data?.responseObject,
            data: {
              steps: await getFailedSteps(),
              envDetails: {
                section: issueData?.section,
                systemData: issueData?.systemData,
              },
              isFromSteps: ![
                DEFECT_CONSTANTS.ISSUE_DATA_SECTION_RESULT_TREE,
                DEFECT_CONSTANTS.ISSUE_DATA_SECTION_RESULT_STEPS,
              ].includes(issueData?.section),
              scriptRunDetails: getScriptRunDetails(issueData),
            },
            instances: slackInstances,
          });
        } else {
          handleNavigationModal(true, DEFECT_CONSTANTS.SLACK);
        }
      } else {
        handleNavigationModal(true, 'Slack');
      }
    } catch (err) {
      console.error('GET_SLACK_INSTANCES : ', err);
    }
  };

  const openFireflinkIssueDrawer = async () => {
    try {
      const response = await getTemplateForEmailConfigReq(selectedProject.id);
      if (response?.data?.responseObject?.templates_selected) {
        setFireFlinkIssueDrawer({
          openDrawer: true,
          defectMgmtData: response?.data?.responseObject,
          data: {
            steps: await getFailedSteps(),
            envDetails: {
              section: issueData?.section,
              systemData: issueData?.systemData,
            },
            isFromSteps: ![
              DEFECT_CONSTANTS.ISSUE_DATA_SECTION_RESULT_TREE,
              DEFECT_CONSTANTS.ISSUE_DATA_SECTION_RESULT_STEPS,
            ].includes(issueData?.section),
            scriptRunDetails: getScriptRunDetails(issueData),
          },
        });
      } else {
        handleNavigationModal(true, 'Fireflink');
      }
    } catch (error) {
      console.error('GET_FIREFLINK_DEFECT : ', error);
    }
  };

  const openGitLabIssueDrawer = async () => {
    try {
      const fieldResponse = await _getGitlabMandatoryField(selectedProject.id);
      const response = await getGitlabInstanceReq();

      if (fieldResponse?.data?.responseCode === 200) {
        if (response?.data?.responseCode === 200 && !isEmptyValue(response?.data?.responseObject)) {
          setGitlabIssueDrawer({
            openDrawer: true,
            defectMgmtData: fieldResponse?.data?.responseObject,
            data: {
              steps: await getFailedSteps(),
              envDetails: {
                section: issueData?.section,
                systemData: issueData?.systemData,
              },

              isFromSteps: ![
                DEFECT_CONSTANTS.ISSUE_DATA_SECTION_RESULT_TREE,
                DEFECT_CONSTANTS.ISSUE_DATA_SECTION_RESULT_STEPS,
              ].includes(issueData?.section),
              scriptRunDetails: getScriptRunDetails(issueData),
            },
            instances: [...response?.data?.responseObject],
          });
        } else {
          handleNavigationModal(true, DEFECT_CONSTANTS.GITLAB);
        }
      } else {
        handleNavigationModal(true, 'Gitlab');
      }
    } catch (err) {
      console.error('GET_GITLAB_INSTANCES : ', err);
    }
  };

  const getScriptRunDetails = (issueData) => {
    if (issueData.nodeObj?.data) {
      return {
        scriptId: key ? key : _key ? _key : id,
        scriptType: issueData.nodeObj.data?.testCaseExecutionType || issueData.nodeObj.data?.testCaseType,
        moduleId: issueData.nodeObj.data.parentId,
        executionId: issueData.executionId,
        scriptName: issueData.nodeObj.data.name,
      };
    }
    return {};
  };

  const createDefectHandler = () => {
    if (!tableData.length) {
      setOpenDefectListDrawer(false);
    }
    const { platform } = selectedProject?.defectPlatform || {};
    switch (platform?.toLowerCase()) {
      case 'gitlab':
        openGitLabIssueDrawer();
        break;
      case 'slack':
        openSlackIssueDrawer();
        break;
      case 'jira':
        openJiraIssueDrawer();
        break;
      case 'fireflink':
        openFireflinkIssueDrawer();
        break;
      default:
        getDefectStatistic();
        break;
    }
  };

  const additionalheaderButtons = () => {
    if (!createButtonRequired) {
      return '';
    }
    return (
      <CommonButton
        className="px-2 mr-6"
        btnType="modal-header"
        label={DEFECT_CONSTANTS.CREATE_DEFECT}
        onClick={createDefectHandler}
      />
    );
  };

  return (
    <>
      {!openTemplateMismatchModal && openDefectListDrawer && (
        <CommonDrawer
          isDrawerOpen={openDrawer}
          titleText={DEFECT_CONSTANTS.DEFECTS}
          drawerWidth={DEFECT_CONSTANTS.DRAWER_WIDTH}
          onDrawerClose={handleClose}
          isRightButtonVisible={false}
          leftButtonText={DEFECT_CONSTANTS.CLOSE}
          onLeftButtonClick={handleClose}
          additionalheaderButtons={additionalheaderButtons()}
        >
          <div className={styles['alert-custom-style']}>
            <AlertContatiner />
          </div>
          <CommonTable
            columns={columns}
            data={tableData || []}
            noDataMessage={DEFECT_CONSTANTS.NO_DEFECTS_MESSAGE}
            compSpecificClassName={styles['defect-list']}
            isLoading={isLoading}
          />
        </CommonDrawer>
      )}
      {openDetailsDrawer && (
        <DefectDetailsDrawer
          closeDrawer={closeDetailsDrawer}
          openDrawer={openDetailsDrawer}
          defectId={selectedRowData?._id}
          titleText={selectedRowData?.defectDetails?.Summary}
        />
      )}
      {gitlabIssueDrawer.openDrawer && (
        <GitlabIssueDrawer
          closeDrawer={closeIssueDrawer('GITLAB')}
          openDrawer={gitlabIssueDrawer.openDrawer}
          reloadTable={setReloadTable}
          failedStepsData={gitlabIssueDrawer.data}
          allInstances={gitlabIssueDrawer.instances}
          backButtonRequired={!!tableData.length}
          MyAlert={MyAlert}
          drawerType={'add'}
          defectMgmtData={gitlabIssueDrawer.defectMgmtData}
          selectedDefectData={selectedDefectData}
          setSelectedDefectData={setSelectedDefectData}
          history={history}
          cleanUpFunction={cleanUpFunction}
        />
      )}
      {fireFlinkIssueDrawer.openDrawer && (
        <FireFlinkIssueDrawer
          closeDrawer={closeIssueDrawer('FIREFLINK')}
          openDrawer={fireFlinkIssueDrawer.openDrawer}
          reloadTable={getDefectsList}
          failedStepsData={fireFlinkIssueDrawer.data}
          defectMgmtData={fireFlinkIssueDrawer.defectMgmtData}
          selectedDefectData={selectedDefectData}
          setSelectedDefectData={setSelectedDefectData}
          drawerType={'add'}
          backButtonRequired={!!tableData.length}
          MyAlert={MyAlert}
          history={history}
          cleanUpFunction={cleanUpFunction}
        />
      )}
      {!openTemplateMismatchModal && jiraIssueDrawer.openDrawer && (
        <JiraIssueDrawer
          closeDrawer={closeIssueDrawer('JIRA')}
          openDrawer={jiraIssueDrawer.openDrawer}
          reloadTable={setReloadTable}
          failedStepsData={jiraIssueDrawer.data}
          allInstances={jiraIssueDrawer.instances}
          backButtonRequired={!!tableData.length}
          MyAlert={MyAlert}
          drawerType={'add'}
          defectMgmtData={jiraIssueDrawer.defectMgmtData}
          selectedDefectData={selectedDefectData}
          setSelectedDefectData={setSelectedDefectData}
          missMatchModalHandler={missMatchModalHandler}
          history={history}
          cleanUpFunction={cleanUpFunction}
        />
      )}
      {slackIssueDrawer.openDrawer && (
        <SlackIssueDrawer
          closeDrawer={closeIssueDrawer('SLACK')}
          openDrawer={slackIssueDrawer.openDrawer}
          reloadTable={setReloadTable}
          failedStepsData={slackIssueDrawer.data}
          allInstances={slackIssueDrawer.instances}
          backButtonRequired={!!tableData.length}
          MyAlert={MyAlert}
          drawerType={'add'}
          defectMgmtData={slackIssueDrawer.defectMgmtData}
          selectedDefectData={selectedDefectData}
          setSelectedDefectData={setSelectedDefectData}
          history={history}
          cleanUpFunction={cleanUpFunction}
        />
      )}
      {!navigateToInstance?.openNavigationModal && updatePlatform.openDrawer && (
        <UpdateDefectPlatform
          history={history}
          cleanUpFunction={cleanUpFunction}
          drawerWidth={DEFECT_CONSTANTS.DRAWER_WIDTH}
          titleText={DEFECT_CONSTANTS.SELECT_PLATFORM}
          editModal={updatePlatform.openDrawer}
          handleEditModal={closeIssueDrawer('UPDATEPLATFORM')}
          projectDetails={updatePlatform.projectDetails}
          defectData={updatePlatform.data}
          getAllProjects={updatePlatformHandler}
        />
      )}
      {navigateToInstance?.openNavigationModal && (
        <NavigateToCreateInstanceModal
          history={history}
          cleanUpFunction={cleanUpFunction}
          closeModal={handleNavigationModal}
          data={navigateToInstance.data}
        />
      )}
      {openTemplateMismatchModal ? (
        <div>
          <AlertPopUps
            alertTitle={JIRA_ISSUE_DRAWER.TEMPLATE_MISMATCH.TITLE_TEMPLTEMPLATE_MISMATCH}
            open={openTemplateMismatchModal}
            alertType={WARNING}
            showHowToProceed={false}
            showCancelBtn={true}
            showSaveBtn={false}
            onCancelBtnClick={onCancelClicked}
            content={[templateMismatchFunction()]}
            execution={true}
            defectTemplateMismatchSection={true}
          />
        </div>
      ) : null}
    </>
  );
};

export default DefectList;

import React, { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import { FormControl, Select, TextareaAutosize, TextField, Tooltip } from '@material-ui/core';
import * as yup from 'yup';
import moment from 'moment';
import { Field, useFormik, FormikProvider } from 'formik';
import { MenuItem, Grid } from '@mui/material';
import DatePickerView from 'react-datepicker';
import { CalendarTodayOutlined, AddCircle, DeleteOutline } from '@mui/icons-material';
import { ReactComponent as LinkIcon } from '@assets/svg-imports/ionic-ios-link.svg';
import { ReactComponent as LinkIconBlue } from '@assets/svg-imports/ionic-ios-link-blue.svg';
import { ReactComponent as AttachmentIconBlue } from '@assets/svg-imports/attachment_blue_24dp.svg';
import {
  createDefectReq,
  deleteMultipleFilesReq,
  getAllModuleTreeReq,
  getUsersByProjectIdReq,
  updateDefectReq,
  getAllComments,
  createComments,
  deleteComments,
  updateComments,
} from '@src/api/api_services';
import { checkValidationForAlphanumericSpecialCharacters } from '@src/pages/analytics/common/util';
import './defect-mgmt.scss';
import TreeWithRadioButton from '@pages/common/table_tree/table-tree-with-radio-button';
import { useAlert } from '@pages/common/alert_service/useAlert';
import { FORM_FIELDS, FORM_FIELDS_ARRAY, MESSAGES } from './defectMgmtConstants';
import RichTextEditor from '@pages/common/rich-text-editor';
import { getDefectDescription } from './defect-utils';
import { SelectDropdown } from '@pages/configuration/plugin/gitlab/select-dropdown';
import Comment from './defect-comments/components/Comment/Comment';
import './defect-comments/sass/style.scss';
import CommentForm from './defect-comments/components/CommentForm/CommentForm';
import { createContext } from 'react';
import MultiAutocomplete from '../test-development/script/modules/module/modals/labels-multiselect-dropdown';
import { isEmptyValue, validateFileExtension } from '@src/util/common_utils';
import { DEFECT_ATTACTMENT_FILE_ALLOWED } from '@util/validations';

function DefectCreateModal({
  isFromSteps,
  open,
  closeModal,
  modalType,
  defectMgmtData,
  selectedDefectData,
  setSelectedDefectData,
  reloadHandler = () => {},
  initDefectData = () => {},
  failedSteps,
  envDetails,
  selectedModule,
  ...props
}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isCreateAnotherDefect, setIsCreateAnotherDefect] = useState(false);
  const [anotherDefectCheck, setAnotherDefectCheck] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [validateSchema, setValidateSchema] = useState({});
  const [defectTemplateFields, setDefectTemplateFields] = useState([]);
  // const [moduleTreeData, setModuleTreeData] = useState([]);
  const [defaultSelectedModule, setDefaultSelectedModule] = useState([]);
  const { AlertContatiner, MyAlert } = useAlert();
  const [projectUsers, setProjectUsers] = useState([]);
  const [tempUploadFiles, setTempUploadFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [newFilesToBeUploaded, setNewFilesToBeUploaded] = useState([]);
  const [comments, setComments] = useState([]);
  const [currentComment, SetCurrentComment] = useState('');
  const [reset, setReset] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [labelSelectedOptions, setLabelSelectedOptions] = useState([]);
  const [labelResponseObject, setLabelResponseObject] = useState([]);
  const [resetLabels, setResetLabels] = useState(false);
  const [isSchemaBuilt, setIsSchemaBuilt] = useState(false);
  const projectLabelsObject = [];

  let [dropdownOpen, setDropdownOpen] = useState(false);
  let onOpen = () => setDropdownOpen(true);
  let onClose = () => setDropdownOpen(false);

  const { TEXTFIELD, TEXTBOX, DROPDOWN, CHECKBOX, RADIOBUTTON, LINK, ATTACHMENT, DATE, TEXTEDITOR } = FORM_FIELDS;
  const {
    CREATE_SUCCESS,
    UPDATE_SUCCESS,
    ALPHA_ERR_MSG,
    CREATE_DEFECT_ERR,
    REQUIRED_ERR_MSG,
    NO_SPACE_AT_START_AND_END_MSG,
    YES,
    VALID_URL,
    MAX_NO_OF_FILES_ALLOWED,
    DUPLICATE_FILE,
    MAX_FILE_SIZE_ALLOWED,
    CREATE_DEFECT,
    EDIT_DEFECT,
    CREATE_ANOTHER_DEFECT,
    CREATE,
    UPDATE,
    ADD,
    EDIT,
    NO,
  } = MESSAGES;
  const [moduleTree, setModuleTree] = useState([]);
  const [moduleLevelScriptCount, setModuleLevelScriptCount] = useState(0);
  let tempInitialValues = {};
  let tempValidateSchema = '';
  let loginUserName = JSON.parse(localStorage.getItem('test-optimize-user')).name;
  let project = JSON.parse(localStorage.getItem('selected-project'));
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validateSchema,
    onSubmit: (values) => {
      handleOnSubmit(values);
    },
  });
  const parentSpecClass = {
    overflowClass: ' w-1/2',
  };
  useEffect(() => {
    (async function () {
      const allUsers = await getUsersByProjectId();
      setProjectUsers(allUsers);
      setIsSchemaBuilt(false);
      createValidationSchema(allUsers);
    })();
  }, []);

  useEffect(() => {
    (async function () {
      try {
        const response = await getAllModuleTreeReq(false);
        if (response?.data?.responseObject?.moduleTree?.length) {
          setModuleTree(response.data.responseObject.moduleTree);
          setModuleLevelScriptCount(response?.data?.responseObject?.moduleLevelScriptCount);
        } else {
          setModuleTree([]);
        }
      } catch (err) {
        console.error('GET_MODULE_TREE :', err);
      }
    })();
  }, []);

  const handleClick = () => {
    let userEmailArray = [];
    let emailArray = [];

    let getProjectUser = [...projectUsers];
    let userName = currentComment.split(/(?=[" ",@])/gi);
    let fetchOnlyUserName = userName.filter((atuserName) => atuserName.includes('@'));
    let fetchUser = fetchOnlyUserName.map((user) => user.slice(1));
    let unique = [...new Set(fetchUser.filter((word) => word.trim().length > 0))];

    getProjectUser.forEach((usernames) => {
      unique.forEach((user) => {
        if (usernames.name.includes(user.slice(0, 5))) {
          emailArray.push(usernames);
        }
      });
    });

    emailArray.forEach((userEmail) => {
      userEmailArray.push(userEmail.emailId);
    });

    var userCheckwithSpecial = new RegExp('^[s@"@"@A-Za-z]+$').test(currentComment);

    let data = {
      emailId:
        userCheckwithSpecial === true || currentComment === '@' ? [] : userEmailArray?.length > 0 ? userEmailArray : [],
      description: currentComment,
      commentParentId: '',
      comments: [],
    };

    createCommentsAPI(selectedDefectData?.defectDetails?.ID, data);
    setReset(true);
  };

  let createCommentsAPI = (defectId, data) => {
    createComments(defectId, data)
      .then((response) => {
        getAllCommentsAPI();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const dataPassToParent = (dataPassToParent) => {
    getAllCommentsAPI();
  };

  const labelDataPassToParent = (labelData) => {
    setLabelSelectedOptions(labelData);
  };

  labelSelectedOptions?.forEach((labelValue) => {
    labelResponseObject?.forEach((objectValue) => {
      if (objectValue.name === labelValue) {
        projectLabelsObject.push(objectValue);
      }
    });
  });

  const deleteComment = (id) => {
    let duplicateCommentArray = [...comments];
    let matchedComment = duplicateCommentArray.filter((comment) => {
      return comment.id === id ? comment : '';
    });
    let index = duplicateCommentArray.indexOf(matchedComment[0]);
    duplicateCommentArray.splice(index, 1);

    deleteComments(selectedDefectData?.defectDetails?.ID, id, duplicateCommentArray)
      .then(() => {
        setComments(duplicateCommentArray);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const updateComment = (id, updatedComment) => {
    let duplicateCommentArray = [...comments];
    duplicateCommentArray.map((comment) => {
      if (comment.id === id) {
        let userEmailArray = [];
        let emailArray = [];
        let getProjectUser = [...projectUsers];
        let userName = updatedComment.split(/(?=[" ",@])/gi);
        let fetchOnlyUserName = userName.filter((atuserName) => atuserName.includes('@'));
        let fetchUser = fetchOnlyUserName.map((user) => user.slice(1));
        let unique = [...new Set(fetchUser.filter((word) => word.trim().length > 0))];

        getProjectUser.forEach((usernames) => {
          unique.forEach((user) => {
            if (usernames.name.includes(user.slice(0, 5))) {
              emailArray.push(usernames);
            }
          });
        });

        var userCheckwithSpecial = new RegExp('^[s@"@"@A-Za-z]+$').test(updatedComment);

        emailArray.forEach((userEmail) => {
          if (userCheckwithSpecial) {
            userEmailArray = [];
          } else {
            userEmailArray.push(userEmail.emailId);
          }
        });

        comment.description = updatedComment;
        comment.emailId = [...new Set(userEmailArray)];
      }
      return comment;
    });
    let updatedObject = [...duplicateCommentArray];
    const filteredResult = updatedObject.find((update) => update.id === id);
    updateComments(selectedDefectData?.defectDetails?.ID, id, filteredResult, 'Update')
      .then(() => {
        getAllCommentsAPI();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const updateReplyComment = (id, childCommentId, updatedComment) => {
    let duplicateCommentArray = [...comments];
    let sendObjToBackend = [];
    duplicateCommentArray.forEach((comment) => {
      if (comment.id === id) {
        comment.comments.forEach((replyComment) => {
          if (replyComment.id === childCommentId) {
            let userEmailArray = [];
            let emailArray = [];
            let getProjectUser = [...projectUsers];
            let userName = updatedComment.split(/(?=[" ",@])/gi);
            let fetchOnlyUserName = userName.filter((atuserName) => atuserName.includes('@'));
            let fetchUser = fetchOnlyUserName.map((user) => user.slice(1));
            let unique = [...new Set(fetchUser)];

            getProjectUser.forEach((usernames) => {
              unique.forEach((user) => {
                if (usernames.name.includes(user.slice(0, 5))) {
                  emailArray.push(usernames);
                }
              });
            });

            var userCheckwithSpecial = new RegExp('^[s@"@"@A-Za-z]+$').test(updatedComment);

            emailArray.forEach((userEmail) => {
              if (userCheckwithSpecial) {
                userEmailArray = [];
              } else {
                userEmailArray.push(userEmail.emailId);
              }
            });

            sendObjToBackend.push(replyComment);
            replyComment.description = updatedComment;
            replyComment.emailId = [...new Set(userEmailArray)];
          }
        });
        return comment;
      }
    });

    sendObjToBackend.forEach((filteredResult) => {
      updateComments(selectedDefectData?.defectDetails?.ID, filteredResult.id, filteredResult, 'Update')
        .then(() => {
          getAllCommentsAPI();
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  const deleteReplyComment = (parentCommentId, childCommentId) => {
    let duplicateCommentArray = [...comments];
    let updateObject = [];
    duplicateCommentArray.forEach((comment) => {
      if (comment.id === parentCommentId) {
        updateObject.push(comment);
        let replyCommentsArray = [...comment.comments];
        comment.comments.forEach((replyComment, index) => {
          if (replyComment.id === childCommentId) {
            replyCommentsArray.splice(index, 1);
            comment.comments = [...replyCommentsArray];
          }
        });
      }
    });
    deleteComments(selectedDefectData?.defectDetails?.ID, childCommentId, duplicateCommentArray)
      .then(() => {
        setComments(duplicateCommentArray);
      })
      .catch((err) => {
        console.error(err);
      });

    updateObject.forEach((filteredResult) => {
      updateComments(selectedDefectData?.defectDetails?.ID, filteredResult.id, filteredResult)
        .then(() => {
          setComments(comments);
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  const deleteThreadComment = (parentCommentId, childCommentId) => {
    let duplicateCommentArray = [...comments];
    let findObj = [];
    duplicateCommentArray.forEach((comment) => {
      comment.comments?.forEach((replyComment) => {
        if (replyComment.id === parentCommentId) {
          findObj.push(comment);
          let threadCommentsArray = [...replyComment.comments];
          replyComment.comments.forEach((replyCommentThread, index) => {
            if (replyCommentThread.id === childCommentId) {
              threadCommentsArray.splice(index, 1);
              replyComment.comments = [...threadCommentsArray];
            }
          });
        }
      });
    });
    deleteComments(selectedDefectData?.defectDetails?.ID, childCommentId)
      .then(() => {
        setComments(duplicateCommentArray);
      })
      .catch((err) => {
        console.error(err);
      });

    findObj.forEach((filteredResult) => {
      updateComments(selectedDefectData?.defectDetails?.ID, filteredResult.id, filteredResult)
        .then(() => {
          setComments(comments);
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  const updateThreadComment = (parentCommentId, childCommentId, updatedComment) => {
    let duplicateCommentArray = [...comments];
    let findObj = [];
    let sendObjToBack = [];
    duplicateCommentArray.forEach((comment) => {
      comment.comments?.forEach((replyComment) => {
        if (replyComment.id === parentCommentId) {
          findObj.push(comment);
          replyComment.comments.forEach((replyCommentThread) => {
            if (replyCommentThread.id === childCommentId) {
              let userEmailArray = [];
              let emailArray = [];
              let getProjectUser = [...projectUsers];
              let userName = updatedComment.split(/(?=[" ",@])/gi);
              let fetchOnlyUserName = userName.filter((atuserName) => atuserName.includes('@'));
              let fetchUser = fetchOnlyUserName.map((user) => user.slice(1));
              let unique = [...new Set(fetchUser.filter((word) => word.trim().length > 0))];

              getProjectUser.forEach((usernames) => {
                unique.forEach((user) => {
                  if (usernames.name.includes(user.slice(0, 5))) {
                    emailArray.push(usernames);
                  }
                });
              });

              var userCheckwithSpecial = new RegExp('^[s@"@"@A-Za-z]+$').test(updatedComment);

              emailArray.forEach((userEmail) => {
                if (userCheckwithSpecial) {
                  userEmailArray = [];
                } else {
                  userEmailArray.push(userEmail.emailId);
                }
              });
              sendObjToBack.push(replyCommentThread);
              replyCommentThread.description = updatedComment;
              replyCommentThread.emailId = [...new Set(userEmailArray)];
            }
          });
        }
      });
    });
    sendObjToBack.forEach((filteredResult) => {
      updateComments(selectedDefectData?.defectDetails?.ID, filteredResult.id, filteredResult, 'Update')
        .then(() => {
          getAllCommentsAPI();
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  const getUsersByProjectId = async () => {
    try {
      const response = await getUsersByProjectIdReq(project?.id);
      if (response?.data?.responseCode === 200) {
        const data = response?.data?.responseObject;
        const allUsers = data.map((val) => {
          return {
            id: val?.user?.id,
            name: val?.user?.name,
            emailId: val?.user?.emailId,
          };
        });
        return allUsers;
      }
    } catch (err) {
      console.error('Get Users By ProjectId error', err);
    }
  };

  const sortBasedOnOrder = (defectTemplateData) => {
    return defectTemplateData?.sort((a, b) => {
      return a?.order - b?.order;
    });
  };
  function createYupSchema(schema, config) {
    const { id, validationType, validations = [] } = config;
    if (!yup[validationType]) {
      return schema;
    }
    let validator = yup[validationType]();
    validations.forEach((validation) => {
      const { params, type } = validation;
      if (!validator[type]) {
        return;
      }
      validator = validator[type](...params);
    });
    schema[id] = validator;
    return schema;
  }

  const createValidationSchema = (allUsers) => {
    let defectTemplateData = JSON.parse(
      JSON.stringify(sortBasedOnOrder(defectMgmtData?.defect_details_templates[0]?.defectDetails))
    );
    for (let i = 0; i < defectTemplateData?.length; i++) {
      let element = defectTemplateData[i];
      let validations = [];
      if (element?.label === 'State' && modalType === ADD) {
        element.enable = NO;
      }

      if (FORM_FIELDS_ARRAY.includes(element?.type)) {
        if ([CHECKBOX, ATTACHMENT].includes(element.type)) {
          element.validationType = 'array';
        } else if (element?.type === DATE) {
          if (['Created On', 'Modified On'].includes(element?.label)) {
            element.validationType = 'string';
          } else {
            element.validationType = DATE;
          }
        } else if (['Modified By', 'Created By'].includes(element?.label)) {
          element.validationType = 'object';
        } else {
          element.validationType = 'string';
        }
      }
      if ([TEXTFIELD, TEXTBOX].includes(element.type)) {
        validations.push(
          {
            type: 'trim',
            params: [NO_SPACE_AT_START_AND_END_MSG],
          },
          {
            type: 'strict',
            params: [true],
          }
        );

        if (element.type === TEXTFIELD) {
          validations.push({
            type: 'matches',
            params: [checkValidationForAlphanumericSpecialCharacters, ALPHA_ERR_MSG],
          });
        }

        if (element?.label === 'Summary') {
          validations.push(
            {
              type: 'max',
              params: [250, `Input cannot be more than 250 characters`],
            },
            {
              type: 'min',
              params: [3, `Input cannot be less than 3 characters`],
            }
          );
        }
      }
      if (element?.minLength) {
        let validationObj = {
          type: 'min',
          params: [element.minLength, `Input cannot be less than ${element.minLength} characters`],
        };
        validations.push(validationObj);
      }
      if (element?.maxLength) {
        let validationObj = {
          type: 'max',
          params: [element.maxLength, `Input cannot be more than ${element.maxLength} characters`],
        };
        validations.push(validationObj);
      }
      if (element?.mandatory === YES) {
        let validationObj = {
          type: 'required',
          params: [REQUIRED_ERR_MSG(element.label)],
        };
        validations.push(validationObj);

        if ([CHECKBOX, ATTACHMENT].includes(element.type)) {
          let validationObj = {
            type: 'min',
            params: [1, REQUIRED_ERR_MSG(element.label)],
          };
          validations.push(validationObj);
        }
      }
      if (element.type === LINK) {
        let validationObj = {
          type: 'url',
          params: [VALID_URL],
        };
        validations.push(validationObj);
      }
      element.validations = validations;
      element.id = element.label;
    }
    createFormikSchema(defectTemplateData, allUsers);
  };

  function handleDynamicStateValues(currentState, data) {
    const nextState = data.filter((a) => {
      if (a.current_state === currentState) return a.next_states;
      return null;
    });
    return nextState[0].next_states;
  }

  function createFormikSchema(defectTemplateData, allUsers) {
    const { defectDetails } = selectedDefectData;
    defectTemplateData.forEach((item) => {
      if (item?.label === 'State') {
        if (modalType === ADD) {
          item.options = defectMgmtData?.defect_life_cycle_templates[0]?.states || [];
        } else if (modalType === EDIT) {
          let nextState;
          if (defectMgmtData?.defect_life_cycle_templates[0]?._id !== selectedDefectData?.defectLifeCycleTemplateId) {
            nextState = handleDynamicStateValues(
              defectMgmtData?.defect_life_cycle_templates[0]?.states[0],
              defectMgmtData?.defect_life_cycle_templates[0]?.state_transitions
            );
            if (!nextState.includes(defectMgmtData?.defect_life_cycle_templates[0]?.states[0])) {
              nextState.unshift(defectMgmtData?.defect_life_cycle_templates[0]?.states[0]);
            }
            item.options = nextState || [];
          } else {
            nextState = handleDynamicStateValues(
              selectedDefectData.state,
              defectMgmtData?.defect_life_cycle_templates[0]?.state_transitions
            );
            if (!nextState.includes(selectedDefectData.state)) {
              nextState.unshift(selectedDefectData.state);
            }
            item.options = nextState || [];
          }
        }
        if (modalType === ADD) {
          tempInitialValues[item.label] = item?.options?.[0] || '';
        } else if (
          modalType === EDIT &&
          defectMgmtData?.defect_life_cycle_templates[0]?._id !== selectedDefectData?.defectLifeCycleTemplateId
        ) {
          tempInitialValues[item.label] = defectMgmtData?.defect_life_cycle_templates[0]?.states[0];
        } else if (defectDetails && defectDetails[item.label]) {
          tempInitialValues[item.label] = defectDetails[item.label];
        } else {
          tempInitialValues[item.label] = '';
        }
      } else if (item?.label === 'Assign to') {
        item.options = allUsers;
        if (defectDetails && defectDetails[item.label]) {
          tempInitialValues[item.label] = defectDetails[item.label]?.id;
        } else {
          tempInitialValues[item.label] = '';
        }
      } else if (item?.label?.toLowerCase() === 'description') {
        if (defectDetails && defectDetails[item.label]) {
          tempInitialValues[item.label] = defectDetails[item.label];
        } else if (failedSteps || envDetails) {
          tempInitialValues[item.label] = `{"blocks":${getDefectDescription(
            failedSteps,
            envDetails,
            isFromSteps
          )},"entityMap":{}}`;
        } else {
          tempInitialValues[item.label] = '';
        }
      } else if (item?.label?.toLowerCase() === 'module') {
        if (selectedModule) {
          const [defaultCheckedNodeName, defaultCheckedNodeId] = selectedModule.split(':');
          setDefaultSelectedModule([{ key: 'key', value: defaultCheckedNodeId || '' }]);
          tempInitialValues[item.label] = selectedModule || '';
        } else {
          const [defaultCheckedNodeName, defaultCheckedNodeId] =
            defectDetails && defectDetails[item.label] ? defectDetails[item.label].split(':') : ['', ''];
          setDefaultSelectedModule([{ key: 'key', value: defaultCheckedNodeId || '' }]);
          tempInitialValues[item.label] = defectDetails?.[item?.label] || '';
        }
      } else if ([CHECKBOX, ATTACHMENT].includes(item?.type)) {
        if (defectDetails && defectDetails[item.label]) {
          tempInitialValues[item.label] = defectDetails[item.label];
        } else {
          tempInitialValues[item.label] = [];
        }
      } else if ([TEXTFIELD, TEXTBOX].includes(item?.type)) {
        if (defectDetails && defectDetails[item.label]) {
          tempInitialValues[item.label] = defectDetails[item.label];
        } else {
          tempInitialValues[item.label] = item?.defaultValue || '';
        }
      } else if (item?.type === DATE) {
        if (defectDetails && defectDetails[item.label]?.length > 0) {
          if (['Created On', 'Modified On'].includes(item.label)) {
            tempInitialValues[item.label] = defectDetails[item.label];
          } else {
            const myDate = moment(
              defectDetails[item.label],
              item?.dateFormat && typeof item.dateFormat === 'string' ? item?.dateFormat?.toUpperCase() : 'DD-MM-YYYY'
            ).toDate();
            if (myDate instanceof Date && !isNaN(myDate)) {
              tempInitialValues[item.label] = myDate;
            } else {
              tempInitialValues[item.label] = '';
            }
          }
        } else {
          tempInitialValues[item.label] = '';
        }
      } else {
        tempInitialValues[item.label] = (defectDetails && defectDetails[item.label]) || '';
      }
    });
    setInitialValues(tempInitialValues);
    setDefectTemplateFields(defectTemplateData);
    const yepSchema = defectTemplateData.reduce(createYupSchema, {});
    tempValidateSchema = yup.object().shape(yepSchema);
    setValidateSchema(tempValidateSchema);
    setIsSchemaBuilt(true);
  }

  const handleOnSubmit = async (values) => {
    const formData = new FormData();
    const data = {
      defectTemplateId: defectMgmtData?.defect_details_templates[0]?._id,
      defectLifeCycleTemplateId: defectMgmtData?.defect_life_cycle_templates[0]?._id,
      projectLabels: projectLabelsObject,
    };
    const formValues = { ...values };
    if (modalType === ADD) {
      formValues['Created By'] = {};
      formValues['Modified By'] = {};
    }
    let a = 0;
    projectUsers.forEach((user) => {
      if (user?.id === formValues['Assign to']) {
        formValues['Assign to'] = user;
        a++;
      }
    });
    if (a === 0) {
      formValues['Assign to'] = {};
    }
    if (!formValues['Assign to']) {
      formValues['Assign to'] = {};
    }
    data.state_transitions = defectMgmtData?.defect_life_cycle_templates[0]?.state_transitions;
    defectMgmtData?.defect_details_templates[0]?.defectDetails?.forEach((data) => {
      if (data) {
        if (
          formValues.hasOwnProperty(data.label) &&
          formValues[data.label] &&
          data.type === 'date' &&
          !['Created On', 'Modified On'].includes(data.label)
        ) {
          formValues[data.label] = moment(formValues[data.label]).format(
            data.dateFormat && typeof data.dateFormat === 'string' ? data.dateFormat.toUpperCase() : 'DD-MM-YYYY'
          );
        }
      }
    });
    data.defectDetails = formValues;
    data.state = formValues['State'];
    formData.append('data', JSON.stringify(data));
    newFilesToBeUploaded.forEach((data) => {
      formData.append('file', data?.file);
    });

    try {
      let response;
      setIsLoading(true);
      if (modalType === ADD) {
        try {
          response = await createDefectReq(project?.id, formData);
          if (response?.data?.responseCode === 507) {
            MyAlert.info(response?.data?.message);
            setIsLoading(false);
            return;
          }
        } catch (error) {
          MyAlert.info(`Failed to upload File(s)`);
        }
      } else {
        formValues['Comments'] = comments;
        const fileIds = [];
        deletedFiles.forEach((fileData) => {
          if (fileData?.id) {
            fileIds.push(fileData.id);
          }
        });
        try {
          response = await updateDefectReq(project?.id, formData, selectedDefectData?.defectDetails?.ID);
          if (response?.data?.responseCode === 507) {
            MyAlert.info(response?.data?.message);
            setIsLoading(false);
            return;
          }
        } catch (error) {
          MyAlert.info(`Failed to upload File(s)`);
        }
      }
      if (response?.data?.responseCode === 200) {
        if (modalType === EDIT) {
          closeModal(false);
          props.MyAlert.success(selectedDefectData?.defectDetails?.ID + ' ' + UPDATE_SUCCESS);
        }
        if (isCreateAnotherDefect) {
          setResetLabels(true);
          setIsCreateAnotherDefect(false);
          MyAlert.success(`${response?.data?.responseObject?.id} ${CREATE_SUCCESS}`);
          setAnotherDefectCheck(true);
          formik.resetForm();
          let timeOutId = setTimeout(() => {
            setResetLabels(false);
            clearTimeout(timeOutId);
          }, 100);
        } else if (modalType === ADD) {
          closeModal(false);
          props.MyAlert.success(`${response?.data?.responseObject?.id} ${CREATE_SUCCESS}`);
        }
        tempInitialValues = {};
        tempValidateSchema = '';
        setSelectedDefectData({});
        initDefectData();
        reloadHandler(
          response?.data?.responseMap?.totalDefectsCount,
          response?.data?.responseObject,
          selectedDefectData?.defectDetails?.ID
        );
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error(CREATE_DEFECT_ERR, err);
    }
  };
  const handleClickDatepickerIcon = (id) => {
    if (id) {
      document.getElementById(id).focus();
    }
  };
  const handleSearchableSelectOnChange = (value, label) => {
    formik.setFieldTouched(label, true);
    if (value.length) {
      formik.setFieldValue(label, value[0].id);
    } else {
      formik.setFieldValue(label, '');
    }
  };

  const getSearchableSelectedOption = (data, value) => {
    if (value) {
      const selectedOption = data?.options?.find((option) => option?.id === value);
      return [
        {
          ...selectedOption,
          label: selectedOption?.name,
          value: selectedOption?.name,
        },
      ];
    } else {
      return [];
    }
  };
  const handleDataChange = (e, props) => {
    props.handleChange(e);
  };
  const formatDate = (dateVal) => {
    return dateVal?.replaceAll('mm', 'MM');
  };
  const navigateToUrl = (url, error) => {
    if (!error && url) {
      window.open(url, '_blank');
    }
  };
  const handleClickBtn = (id) => {
    let htmlEle = document.getElementById(id);
    if (htmlEle) {
      htmlEle.click();
    }
  };
  const deleteFile = (file) => {
    let files = tempUploadFiles;
    files = files.filter((fileVal) => {
      return file?.name !== fileVal?.name;
    });
    setTempUploadFiles(files);
    let newFilesToBeUploadedCopy = [...newFilesToBeUploaded];
    newFilesToBeUploadedCopy = newFilesToBeUploadedCopy.filter((fileVal) => {
      return file?.name !== fileVal?.name;
    });
    setNewFilesToBeUploaded(newFilesToBeUploadedCopy);
    const tempDeletedFiles = [...deletedFiles];
    tempDeletedFiles.push(file);
    setDeletedFiles(tempDeletedFiles);
  };

  const validateFilesSize = (existingFiles, newUploadedFiles) => {
    let fileSizeInBytes = 0;
    for (let i = 0; i < existingFiles?.length; i++) {
      fileSizeInBytes += existingFiles[i]?.sizeInBytes;
    }
    for (let i = 0; i < newUploadedFiles?.length; i++) {
      fileSizeInBytes += newUploadedFiles[i]?.size;
    }
    return fileSizeInBytes <= 31457280;
  };
  const validateFileAlreadyExists = (newUploadedFiles, files) => {
    for (let i = 0; i < newUploadedFiles.length; i++) {
      for (let j = 0; j < files.length; j++) {
        if (files[j].name === newUploadedFiles[i].name) {
          return true;
        }
      }
    }
    return false;
  };
  const onLoad = (event, data, setFieldValue, value) => {
    let allAtachmentValue = [];
    defectTemplateFields.forEach((el) => {
      if (el.type === 'attachment') {
        allAtachmentValue.push(...formik?.values[el.id]);
      }
    });
    const allFiles = tempUploadFiles;
    const existingFiles = [...value];
    const newUploadedFiles = event?.target?.files;
    const newFilesToBeUploadedCopy = [...newFilesToBeUploaded];
    if (existingFiles?.length + newUploadedFiles?.length > 5) {
      MyAlert.info(MAX_NO_OF_FILES_ALLOWED);
      event.target.value = '';
      return;
    }

    const existPlusNew = [...allAtachmentValue, ...existingFiles];
    if (validateFileAlreadyExists(newUploadedFiles, existPlusNew) && !anotherDefectCheck) {
      MyAlert.info(DUPLICATE_FILE);
      event.target.value = '';
      return;
    }

    if (!validateFilesSize(existingFiles, newUploadedFiles)) {
      MyAlert.info(MAX_FILE_SIZE_ALLOWED);
      event.target.value = '';
      return;
    }
    let file;
    let unsupportedFile = [];
    for (let i = 0; i < newUploadedFiles.length; i++) {
      file = newUploadedFiles[i];
      if (file && validateFileExtension(newUploadedFiles[i], DEFECT_ATTACTMENT_FILE_ALLOWED)) {
        const obj = {
          name: file?.name,
          sizeInBytes: file?.size,
        };
        for (let j = 0; j < existPlusNew.length; j++) {
          if (existPlusNew[j].name === file?.name) {
            MyAlert.info(DUPLICATE_FILE);
            return;
          }
        }
        allFiles.push(obj);
        existingFiles.push(obj);
        newFilesToBeUploadedCopy.push({
          name: file?.name,
          sizeInBytes: file?.size,
          file: file,
        });
      } else {
        unsupportedFile.push(newUploadedFiles[i]);
      }
    }

    if (!isEmptyValue(unsupportedFile)) {
      MyAlert.warning(`${unsupportedFile?.length} unsupported file. Please check the file format.`);
    }

    setFieldValue(data?.label, existingFiles);
    setTempUploadFiles(allFiles);
    setNewFilesToBeUploaded(newFilesToBeUploadedCopy);

    event.target.value = '';
  };

  const defectMgmtComponents = (data, props, error) => {
    if (data && props) {
      const value = props?.values[data.id];
      const { id, type, enable, placeholder, maxLength, label } = data;
      switch (type) {
        case TEXTFIELD:
          return enable === YES ? (
            <div className="mt-3 rs-user-input-container">
              <TextField
                error={formik.errors[id] && formik.touched[id] ? true : false}
                className="w-64"
                InputLabelProps={{
                  shrink: true,
                }}
                type="text"
                autoComplete="off"
                name={id}
                id={id}
                title={!value && placeholder?.length > 30 ? placeholder : value}
                placeholder={placeholder?.length > 30 ? placeholder?.substr(0, 30) + '...' : placeholder}
                onChange={(e) => handleDataChange(e, props)}
                helperText={<div className="errorMessage">{formik.errors[id] && formik.touched[id] ? error : ''}</div>}
                value={value}
                onBlur={(e) => {
                  formik.handleBlur(e);
                }}
              />
            </div>
          ) : (
            <label className="label-overflow testCase">
              {['Created By', 'Modified By'].includes(label) ? value?.name || '--' : value || '--'}
            </label>
          );

        case TEXTBOX:
          return (
            <>
              {enable === YES ? (
                <div className="mt-2">
                  <TextareaAutosize
                    name={id}
                    id={id}
                    onChange={(e) => handleDataChange(e, props)}
                    maxLength={maxLength}
                    value={value}
                    title={!value && placeholder?.length > 30 ? placeholder : value}
                    placeholder={placeholder?.length > 30 ? placeholder?.substr(0, 30) + '...' : placeholder}
                    className="w-full popup-input-bg pl-px mt-1 input-field-color textarea-content-style rs-input-style-textarea"
                    onBlur={(e) => {
                      formik.handleBlur(e);
                    }}
                  />
                  {maxLength ? (
                    <div className="flex justify-between">
                      <div>{formik.errors[id] && formik.touched[id] && <p className="errorMessage">{error}</p>}</div>
                      <div className="mt-1 fontPoppinsRegularSm text-gray-500 text-right mr-2">
                        {value ? value.length : 0}/{maxLength}
                      </div>
                    </div>
                  ) : (
                    <div>{formik.errors[id] && formik.touched[id] && <p className="errorMessage">{error}</p>}</div>
                  )}
                </div>
              ) : (
                <label className="label-overflow testCase">{value || '--'}</label>
              )}
            </>
          );

        case TEXTEDITOR:
          return (
            <>
              <div className="mt-2">
                <FormikProvider value={formik}>
                  <Field name={id}>
                    {({ form, field }) => {
                      const { setFieldValue } = form;
                      const { value } = field;
                      return (
                        <div>
                          <RichTextEditor
                            readOnly={enable === 'no' ? true : false}
                            toolbarHidden={enable === 'no' ? true : false}
                            convertedContent={selectedDefectData?.defectDetails?.[id] || value || ''}
                            descriptionContentNotEditable={true}
                            setConvertedContent={(content) => {
                              if (content) {
                                setFieldValue(id, content);
                              } else {
                                setFieldValue(id, '');
                              }
                            }}
                          />
                          <div>
                            {formik.errors[id] && formik.touched[id] && <p className="errorMessage">{error}</p>}
                          </div>
                        </div>
                      );
                    }}
                  </Field>
                </FormikProvider>
              </div>
            </>
          );

        case RADIOBUTTON:
          return (
            <>
              <div className="w-100 mt-2 rs-user-input-container">
                <div className="grid grid-cols-2 gap-2">
                  {data?.options?.map((option, index) => (
                    <div key={index} className="flex" title={option}>
                      <input
                        type="radio"
                        onChange={(e) => handleDataChange(e, props)}
                        className="mr-2 mt-0.5"
                        name={id}
                        id={id}
                        value={option}
                        checked={option === value}
                      />
                      <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap">
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
                {formik.errors[id] && formik.touched[id] && <p className="errorMessage">{error}</p>}
              </div>
            </>
          );

        case LINK:
          return (
            <>
              <div className="flex mt-3 rs-user-input-container">
                <Tooltip title="Click here to navigate" placement="top">
                  <div
                    onClick={() => {
                      navigateToUrl(value, formik.errors[id]);
                    }}
                  >
                    {value && !formik.errors[id] ? (
                      <LinkIconBlue className="h-4 w-4 mt-1 mr-2 origin-center cursor-pointer" />
                    ) : (
                      <LinkIcon className="h-4 w-4 mt-1 mr-2 origin-center cursor-pointer" />
                    )}
                  </div>
                </Tooltip>
                <TextField
                  title={!value && placeholder?.length > 30 ? placeholder : value}
                  error={formik.errors[id] && formik.touched[id] ? true : false}
                  className="w-56 link-placeholder-text"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="text"
                  autoComplete="off"
                  name={id}
                  id={id}
                  placeholder="Attached Link will be shown here if any URL is added"
                  onChange={(e) => handleDataChange(e, props)}
                  helperText={
                    <div className="errorMessage"> {formik.errors[id] && formik.touched[id] ? error : ''}</div>
                  }
                  value={value}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                />
              </div>
            </>
          );

        case DROPDOWN:
          return (
            <>
              <div className={label === 'Assign to' ? '' : 'mt-2'}>
                <FormControl variant="standard">
                  {label === 'Assign to' ? (
                    <div className="w-64">
                      <SelectDropdown
                        data={data?.options?.map((option) => ({
                          ...option,
                          label: option.name,
                          value: option.name,
                        }))}
                        disabled={enable && enable === NO ? true : false}
                        placeholder={placeholder ? placeholder : ''}
                        onChange={(value) => {
                          handleSearchableSelectOnChange(value, label);
                        }}
                        selectedData={
                          getSearchableSelectedOption(data, value)[0]?.label
                            ? getSearchableSelectedOption(data, value)
                            : []
                        }
                      />
                    </div>
                  ) : (
                    <Select
                      disabled={enable && enable === NO ? true : false}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                      }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                      }}
                      id={id}
                      name={id}
                      onChange={(e) => handleDataChange(e, props)}
                      value={value || ''}
                      defaultValue=""
                      className="w-64 select-selected-value fontPoppinsRegularMd"
                      displayEmpty={true}
                      renderValue={(value) =>
                        value ? (
                          <span className="fontPoppinsRegularMd">{value}</span>
                        ) : (
                          <span className="opacity-50 fontPoppinsRegularSm">{placeholder || ''}</span>
                        )
                      }
                    >
                      {data?.options?.map((option, index) => (
                        <MenuItem
                          title={option}
                          key={index}
                          className="hover:text-blue-700 cursor-pointer hover:bg-blue-100 text-xs w-64 overflow-hidden overflow-ellipsis whitespace-nowrap manual-test-case-select fontPoppinsRegularMd"
                          value={option}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  )}

                  {formik.errors[id] && formik.touched[id] && <p className="errorMessage">{error}</p>}
                </FormControl>
              </div>
            </>
          );

        case DATE:
          return (
            <>
              <div className="flex">
                <div className=" h-8 border-2 border-gray-300 w-60">
                  <FormikProvider value={formik}>
                    <Field name={id}>
                      {({ form, field }) => {
                        const { setFieldValue } = form;
                        const { value } = field;
                        return (
                          <DatePickerView
                            readOnly={['Created On', 'Modified On'].includes(label) ? true : false}
                            className="h-7"
                            id={id}
                            {...field}
                            selected={value}
                            onChange={(val) => {
                              setFieldValue(id, val);
                            }}
                            onKeyDown={(e) => e.preventDefault()}
                            dateFormat={
                              data?.dateFormat && typeof data.dateFormat === 'string'
                                ? formatDate(data.dateFormat)
                                : 'dd/MM/yyyy'
                            }
                            maxDate={new Date()}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                            }}
                          />
                        );
                      }}
                    </Field>
                  </FormikProvider>
                </div>
                <div className=" bg-gray-200" onClick={() => handleClickDatepickerIcon(id)}>
                  <CalendarTodayOutlined className="mt-1" />
                </div>
              </div>
              <div className="fontPoppinsRegularSm opacity-50">
                [{data?.dateFormat && Array.isArray(data.dateFormat) ? data.dateFormat.join(',') : data?.dateFormat}]
              </div>
              {formik.errors[id] && formik.touched[id] && <p className="errorMessage">{error}</p>}
            </>
          );

        case CHECKBOX:
          return (
            <>
              <div className="w-100 mt-2">
                <div className="grid grid-cols-2 gap-2">
                  {data?.options?.map((option, index) => (
                    <div key={index} className="flex" title={option}>
                      <input
                        type="checkbox"
                        className="mr-2"
                        name={id}
                        id={id}
                        value={option}
                        onChange={(e) => {
                          handleDataChange(e, props);
                        }}
                        checked={value && value?.length > 0 && value.includes(option)}
                      />
                      <label className="text-xs overflow-hidden overflow-ellipsis whitespace-nowrap fontPoppinsRegularMd">
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
                {formik.errors[id] && formik.touched[id] && <p className="errorMessage">{error}</p>}
              </div>
            </>
          );

        case ATTACHMENT:
          return (
            <>
              <div className="mt-1">
                <FormikProvider value={formik}>
                  <Field name={id}>
                    {({ form, field }) => {
                      const { setFieldValue } = form;
                      const { value } = field;
                      return (
                        <>
                          <button onChange={(event) => onLoad(event, data, setFieldValue, value)} className="hidden">
                            <input type="file" id={id} multiple accept={Object.keys(DEFECT_ATTACTMENT_FILE_ALLOWED)} />
                          </button>
                          {value?.length > 0 ? (
                            value?.map((option, index) => (
                              <div key={index} className="flex flex-row">
                                <div className="flex items-center fontPoppinsRegularMd">
                                  <Tooltip title={option?.name} placement="top">
                                    <p
                                      className="text-sm hyper_link_color-common
                                overflow-hidden
                                overflow-ellipsis
                                whitespace-nowrap
                                w-36"
                                    >
                                      {option?.name}
                                    </p>
                                  </Tooltip>
                                  <Tooltip title="Delete" placement="top">
                                    <DeleteOutline
                                      color="primary"
                                      className="h-6 w-6 mt-1 mr-2 origin-center cursor-pointer"
                                      onClick={() => {
                                        deleteFile(option);
                                        const filteredValue = value?.filter((file) => {
                                          return file?.name !== option?.name;
                                        });
                                        setFieldValue(id, filteredValue);
                                      }}
                                    />
                                  </Tooltip>
                                  {index === value?.length - 1 && (
                                    <Tooltip
                                      title={value?.length >= 5 ? `${MAX_NO_OF_FILES_ALLOWED}` : 'Add File'}
                                      placement="top"
                                    >
                                      <button disabled={value?.length >= 5} onClick={() => handleClickBtn(id)}>
                                        <AddCircle
                                          className={
                                            value?.length >= 5
                                              ? ' text-gray-400'
                                              : 'text-blue-700 h-6 w-6 mt-1 mr-2 origin-center cursor-pointer'
                                          }
                                        />
                                      </button>
                                    </Tooltip>
                                  )}
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="flex flex-row">
                              <div className="flex items-center cursor-pointer" onClick={() => handleClickBtn(id)}>
                                <Tooltip title="Attach file" placement="top">
                                  <div className="flex">
                                    <AttachmentIconBlue className="h-6 w-6 mr-2 origin-center" />

                                    <p aria-disabled={true} className="fontPoppinsRegularMd hyper_link_color-common">
                                      Attach
                                    </p>
                                  </div>
                                </Tooltip>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    }}
                  </Field>
                </FormikProvider>
              </div>
              {formik.errors[id] && formik.touched[id] && <p className="errorMessage">{error}</p>}
            </>
          );

        default:
          return (
            <>
              <label className="label-overflow testCase"></label>
            </>
          );
      }
    }
  };

  const moduleButtonField = (data, props, error) => {
    const treeNodeSelected = (nodeObj) => {
      props.setFieldTouched('Module', true);
      if (nodeObj?.length) {
        props.setFieldValue('Module', `${nodeObj[0]?.data?.path}:${nodeObj[0]?.data?._key}`, true);
      } else {
        props.setFieldValue('Module', '', true);
      }
    };

    return (
      <div>
        <div>
          <TreeWithRadioButton
            editDefect={modalType === EDIT || selectedModule}
            moduleName={props?.values?.Module}
            moduleSelection={true}
            individualTree={false}
            hideRootRadioBtn={true}
            data={moduleTree}
            operation={null}
            defaultCheckedNode={defaultSelectedModule}
            placeholder={'Select module'}
            nodeSelected={treeNodeSelected.bind(this)}
            hideElements={true}
            hideElementsBtn={true}
            buttonLabel="Module"
            moduleLevelScriptCount={moduleLevelScriptCount}
            defect={true}
            parentSpecificClass={parentSpecClass}
          />
        </div>

        {props.errors.Module && props.touched.Module && props.values.Module === '' && (
          <p className="errorMessage">{props.errors.Module}</p>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (modalType === EDIT) {
      getAllCommentsAPI();
    }
  }, []);

  let getAllCommentsAPI = () => {
    getAllComments(selectedDefectData?.defectDetails?.ID).then((data) => {
      setComments(data.data.responseObject);
    });
  };

  useEffect(() => {
    if (currentComment.trim().length === 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [currentComment]);

  function projectLabelsArray() {
    let projectOptionValues = [];
    selectedDefectData?.projectLabels?.forEach((labelName) => {
      projectOptionValues.push(labelName?.name);
    });
    return projectOptionValues;
  }

  const defectMgmtDetailElements = (data, props, error, index) => {
    return (
      <>
        <div className="flex flex-col justify-center">
          <div className="flex">
            <div className="flex ">
              <label className="common-label-text ">
                {data ? (
                  <Tooltip title={data?.label} placement="top-start">
                    <span className="overflow-hidden overflow-ellipsis whitespace-nowrap w-64 fontPoppinsRegularMd">
                      {data?.mandatory === YES ? <span className="text-red-400 mr-1">&#42;</span> : null}
                      {data?.label?.length > 30 ? data?.label?.substr(0, 30) + '...' : data?.label}
                    </span>
                  </Tooltip>
                ) : null}
              </label>
            </div>
          </div>
          <div className="contentDataStyle">
            {data?.label === 'Module' ? (
              moduleButtonField(data, props, error)
            ) : modalType === EDIT && data?.label === 'Comments' ? (
              <>
                <div className="comment-area">
                  <div className="initial-comment">
                    <CommentForm
                      id={'currentUser'}
                      getData={SetCurrentComment}
                      isReset={reset}
                      setReset={setReset}
                      placeholder={'Add a comment....'}
                      projectUsers={projectUsers}
                    />
                    <div className="commentbox-footer">
                      <button
                        type="button"
                        className="primary-btn"
                        onClick={() => handleClick()}
                        {...(isDisabled ? { disabled: 'disabled' } : '')}
                      >
                        {' '}
                        Comment{' '}
                      </button>
                    </div>
                  </div>
                  <div className="comment-section">
                    <commentContext.Provider>
                      {comments?.map((comment, index) => {
                        return (
                          <Comment
                            key={index}
                            onDelete={deleteComment}
                            id={index}
                            data={comment}
                            commentsArray={comments}
                            onAddComment={setComments}
                            onUpdateComment={updateComment}
                            onUpdateReplyComment={updateReplyComment}
                            onDelteReplyComment={deleteReplyComment}
                            onDeleteThreadComment={deleteThreadComment}
                            onUpdateThreadComment={updateThreadComment}
                            defectId={selectedDefectData?.defectDetails?.ID}
                            projectUsers={projectUsers}
                            dataPassToParent={dataPassToParent}
                            loginUserName={loginUserName}
                          />
                        );
                      })}
                    </commentContext.Provider>
                  </div>
                </div>
              </>
            ) : data?.label === 'Labels' ? (
              <MultiAutocomplete
                selectedOptionsValue={modalType === EDIT ? projectLabelsArray() : []}
                labelSelectedOptions={labelDataPassToParent}
                labelResponse={setLabelResponseObject}
                defects={true}
                MyAlert={MyAlert}
                resetLabels={resetLabels}
                setResetLabels={setResetLabels}
                onOpen={onOpen}
                onClose={onClose}
              />
            ) : (
              defectMgmtComponents(data, props, error, index)
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <Modal open={open} className="modal-dialog">
        <div className="common-var-max-popup-style defect_modal_style modal-container h-5/6">
          <AlertContatiner></AlertContatiner>
          <div className="modal-header ">
            <label className="Popup-header-common">
              {modalType === ADD ? CREATE_DEFECT : `${EDIT_DEFECT} - ${selectedDefectData?.defectDetails?.ID}`}
            </label>
            <IconButton
              color="secondary"
              component="span"
              className="close"
              onClick={() => {
                tempInitialValues = {};
                tempValidateSchema = '';

                closeModal(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className={dropdownOpen ? 'overflow-y-hidden flex p-2' : 'modal-body'} id="journal-scroll">
            {isSchemaBuilt && (
              <div className="shadow-none">
                <Grid container spacing={{ xs: 2, md: 3, sm: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                  {defectTemplateFields &&
                    defectTemplateFields?.length > 0 &&
                    defectTemplateFields?.map((data, index) => {
                      let error = formik.errors.hasOwnProperty(data.id) && formik.errors[data.id];

                      return ['ID', 'Modified By', 'Created By', 'Created On', 'Modified On'].includes(data?.label) ||
                        (modalType === ADD && data?.label === 'Comments') ? (
                        <></>
                      ) : [TEXTEDITOR, TEXTBOX].includes(data?.type) ||
                        (modalType === EDIT && data?.label === 'Comments') ||
                        data?.label === 'Labels' ? (
                        <Grid item xs={12} sm={12} md={12} key={data?.id}>
                          {defectMgmtDetailElements(data, formik, error, index)}
                        </Grid>
                      ) : (
                        <Grid item xs={4} sm={4} md={6} key={data?.id}>
                          {defectMgmtDetailElements(data, formik, error, index)}
                        </Grid>
                      );
                    })}
                </Grid>
              </div>
            )}
          </div>

          <div className="modal-footer">
            <div className="float-right">
              {modalType === ADD && (
                <span>
                  <input
                    type="checkbox"
                    name="createDefect"
                    id="createDefect"
                    checked={isCreateAnotherDefect}
                    onChange={() => setIsCreateAnotherDefect(!isCreateAnotherDefect)}
                  />
                  <label htmlFor="createDefect" className="input-filed-label-style-common ml-2">
                    {CREATE_ANOTHER_DEFECT}
                  </label>
                </span>
              )}
              <button
                className="gray-btn mr-3"
                onClick={() => {
                  tempInitialValues = {};
                  tempValidateSchema = '';
                  closeModal();
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="primary-btn"
                disabled={isLoading}
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                {modalType === ADD ? CREATE : UPDATE}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default DefectCreateModal;
export const commentContext = createContext();

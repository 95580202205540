import React from 'react';
import MyInput from '@pages/common/Inputs/MyInput';
import MySelect from '@pages/common/Inputs/MySelect';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { RestContext } from '../restapi';
import { WEBSERVICE } from '../constants/constants';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import '@src/pages/test-development/script/scripts/webservice/restapi/Save_api/save_api_req.scss';
import { MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@src/css_config/colorConstants';
import classNames from 'classnames';
import { REGEX } from '@src/util/validations';

const useStyles = makeStyles((theme) => ({
  blueBackground: {
    '&:hover ,  &.Mui-selected , &.Mui-selected:hover': {
      backgroundColor: `${colors.rs_primary}`,
      color: colors.text_white,
    },
    '&:first-child': {
      marginTop: '10px',
    },
    '&:last-child': {
      marginBottom: '10px',
    },
  },
}));

export default function SaveAapiRreq({
  handleClickClose,
  CreateWebServiceStepNLP,
  scriptId,
  moduleId,
  changeButton,
  ...props
}) {
  const context = React.useContext(RestContext);
  const history = useHistory();
  const index = props.location.pathname.lastIndexOf('/');
  let path = props.location.pathname.slice(0, index).slice(0, props.location.pathname.slice(0, index).lastIndexOf('/'));
  const search = window.location.search;
  const stepId = new URLSearchParams(search).get('stepId');
  const {
    values: { requestName, ifFailed },
  } = context;
  const stepGroupId = new URLSearchParams(search).get('id');
  const libraryId = new URLSearchParams(search).get('parentId');
  const stepGroupName = new URLSearchParams(search).get('stepGroup');
  const getPassValue = (value) => {
    value = value.trim();
    return value ? WEBSERVICE.PASS_MESSAGE_WITH_VALUE.replace('#{value}', value) : WEBSERVICE.PASS_MESSAGE;
  };

  const {
    VALIDATION_SAVE_STEP_NAME,
    VALIDATION_REQUEST_NAME_WHITESPACE,
    VALIDATION_REQUEST_NAME_ALPHANUMERIC,
    VALIDATION_REQUEST_NAME_LENGTH,
    CUSTOM_VALIDATION
  } = WEBSERVICE;

  const getFailValue = (value) => {
    value = value.trim();
    return value ? WEBSERVICE.FAIL_MESSAGE_WITH_VALUE.replace('#{value}', value) : WEBSERVICE.FAIL_MESSAGE;
  };
  const getFailedOption = (value) => {
    return (
      value ||
      (props?.type === 'Webservice'
        ? 'MARK_THIS_STEP_AS_FAILED_AND_CONTINUE_SCRIPT_EXECUTION'
        : 'MARK_THIS_STEP_AS_FAILED_AND_CONTINUE_STEP_GROUP_EXECUTION')
    );
  };
  const formik = useFormik({
    initialValues: {
      name: requestName || '',
      passMessage: getPassValue(requestName),
      failMessage: getFailValue(requestName),
      ifFailed: getFailedOption(ifFailed),
      stepId: stepId,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(WEBSERVICE.VALIDATION_NAME)
        .test(CUSTOM_VALIDATION, function (value) {
          const errorMessage = getErrorMessage(value);
          return errorMessage ? this.createError({ message: errorMessage }) : true;
        }),
      passMessage: Yup.string().required(WEBSERVICE.VALIDATION_PASS_MESSAGE),
      failMessage: Yup.string().required(WEBSERVICE.VALIDATION_FAIL_MESSAGE),
    }),
    onSubmit: () => {
      CreateWebServiceStepNLP();
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const values = {
      ...formik.values,
      [name]: value,
      passMessage: getPassValue(value),
      failMessage: getFailValue(value),
      stepId: stepId,
    };
    formik.setValues(values);
    context.setValues(Object.assign({}, context.values, { stepInputs: values }));
  };

  const getErrorMessage = (value) => {
    let error;

    if (!value || value.trim().length === 0) {
      error = VALIDATION_REQUEST_NAME_WHITESPACE;
    } else if (REGEX.ALPHANUMERIC_VALIDATION.test(value)) {
      error = VALIDATION_REQUEST_NAME_ALPHANUMERIC;
    } else if (value.trim().length < 3) {
      error = VALIDATION_REQUEST_NAME_LENGTH;
    } else if (value.length < 3) {
      error = VALIDATION_SAVE_STEP_NAME;
    }

    return error;
  };

  const handleChange4 = (e) => {
    const { name, value } = e.target;
    const values = { ...formik.values, [name]: value };
    formik.setValues(values);
    context.setValues(Object.assign({}, context.values, { stepInputs: values }));
  };
  let historyLocation = history.location.pathname;
  let stepGroupIndex = historyLocation.lastIndexOf('/');
  let stepGroupPath = historyLocation.slice(0, stepGroupIndex);
  const URL =
    props?.type === 'Webservice'
      ? `${path}/Steps?moduleId=${moduleId}&scriptId=${scriptId}`
      : `${stepGroupPath}/Steps?id=${stepGroupId}&parentId=${libraryId}&stepGroup=${stepGroupName}&defaultStepGroup=false`;
  const handleSaveAndContinue = async (cont) => {
    if (formik.isValid) {
      try {
        await CreateWebServiceStepNLP(formik.values);
        handleClickClose();
        localStorage.setItem('Scriptadd', libraryId ? stepGroupName : path.slice(path.lastIndexOf('/') + 1));
        if (cont) {
          window.location.reload();
        } else {
          history.push(URL);
        }
      } catch (error) {
        console.error('Error occurred while creating web service step:', error);
      }
    }
  };
  const ifFailedList =
    props?.type === 'Webservice'
      ? [
          {
            key: 0,
            label: 'Mark this step as Failed and continue script execution',
            value: 'MARK_THIS_STEP_AS_FAILED_AND_CONTINUE_SCRIPT_EXECUTION',
          },
          {
            key: 1,
            label: 'Mark this step as Warning and continue script execution',
            value: 'MARK_THIS_STEP_AS_WARNING_AND_CONTINUE_SCRIPT_EXECUTION',
          },
          {
            key: 2,
            label: 'Mark this step as Failed and stop script execution',
            value: 'MARK_THIS_STEP_AS_FAILED_AND_STOP_SCRIPT_EXECUTION',
          },
          {
            key: 3,
            label: 'Mark this step as Failed and stop current module execution',
            value: 'MARK_THIS_STEP_AS_FAILED_AND_STOP_CURRENT_MODULE_EXECUTION',
          },
          {
            key: 4,
            label: 'Mark this step as Failed and stop complete execution',
            value: 'MARK_THIS_STEP_AS_FAILED_AND_STOP_COMPLETE_EXECUTION',
          },
        ]
      : [
          {
            key: 0,
            label: 'Mark this step as Failed and continue Step Group execution',
            value: 'MARK_THIS_STEP_AS_FAILED_AND_CONTINUE_STEP_GROUP_EXECUTION',
          },
          {
            key: 1,
            label: 'Mark this step as Warning and continue Step Group execution',
            value: 'MARK_THIS_STEP_AS_WARNING_AND_CONTINUE_STEP_GROUP_EXECUTION',
          },
          {
            key: 2,
            label: 'Mark this step as Failed and stop Step Group execution',
            value: 'MARK_THIS_STEP_AS_FAILED_AND_STOP_STEP_GROUP_EXECUTION',
          },
        ];

  return (
    <div className="save-api-request">
      <div className="modal-header-api">
        <div className="pl-2">
          <h2 className="title white fontPoppinsMediumSm">
            {changeButton ? WEBSERVICE.UPDATE_API_REQUEST_AS_STEP : WEBSERVICE.SAVE_API_REQUEST_AS_STEP}
          </h2>
          <IconButton
            color="primary"
            component="span"
            className="close"
            onClick={() => {
              handleClickClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className="modal-body" id="journal-scroll">
        <div>
          <form onSubmit={formik.handleSubmit} noValidate>
            <div className="pt-5 pl-5 pr-5">
              <div className="fontPoppinsMediumSm select-none mb-1">
                <span className="mr-0.5" style={{ color: colors.rs_error }}>
                  *
                </span>
                {WEBSERVICE.SAVE_API_REQUEST_NAME}
              </div>
              <div className="w-full mb-3">
                <MyInput
                  type="text"
                  placeholder="Enter api request name"
                  error={formik.errors.name && formik.touched.name}
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  onChange={handleChange}
                  autoComplete="off"
                  name="name"
                  helperText={formik.touched.name && formik.errors.name}
                  className="save-api-input"
                  variableInput={false}
                  inputValue={'input'}
                />
                {formik.errors.name && <span className="request-section-errors">{formik.errors.name}</span>}
              </div>
              <div className={classNames('fontPoppinsMediumSm select-none mb-1', {['pt-2']: Boolean(formik.errors.name)})}>
                <span className="mr-0.5" style={{ color: colors.rs_error }}>
                  *
                </span>
                {WEBSERVICE.SAVE_API_REQUEST_IF_FAILED}
              </div>
              <div className="w-full mb-3">
                <MySelect
                  variableInput={'restApi'}
                  sxPadding={'4px 10px 1px 10px'}
                  boxShadow={`0px 0px 1px grey`}
                  verticalAnchor={'bottom'}
                  verticalTransform={'top'}
                  marginTop={'3px'}
                  borderRadius={'4px'}
                  marginRight={'3px'}
                  classSelect={'fontPoppinsRegularSm'}
                  name="ifFailed"
                  value={formik.values.ifFailed}
                  onChange={handleChange4}
                  minWidth={`450px`}
                  background={'transparent'}
                  MenuProps={{
                    PaperProps: {
                      className: 'save-as-step-dropdown',
                    },
                  }}
                >
                  {ifFailedList.map((data) => {
                    return (
                      <MenuItem key={data.key} value={data.value} className="h-[30px]">
                        <span className="fontPoppinsRegularMd">{data.label}</span>
                      </MenuItem>
                    );
                  })}
                </MySelect>
              </div>
              <div className="fontPoppinsMediumSm select-none mb-1">
                <span className="mr-0.5" style={{ color: colors.rs_error }}>
                  *
                </span>
                {WEBSERVICE.SAVE_API_REQUEST_PASS_MESSAGE}
              </div>
              <div className="w-full mb-3">
                <MyInput
                  type="text"
                  placeholder="Pass message"
                  error={formik.errors.passMessage && formik.touched.passMessage}
                  value={formik.values.passMessage}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  autoComplete="off"
                  name="passMessage"
                  helperText={formik.touched.passMessage && formik.errors.passMessage}
                  className="save-api-input"
                  variableInput={false}
                  inputValue={'input'}
                />
              </div>
              <div className="fontPoppinsMediumSm select-none mb-1">
                <span className="mr-0.5" style={{ color: colors.rs_error }}>
                  *
                </span>
                {WEBSERVICE.SAVE_API_REQUEST_FAIL_MESSAGE}
              </div>
              <div className="w-full">
                <MyInput
                  type="text"
                  placeholder="Fail Message"
                  error={formik.errors.failMessage && formik.touched.failMessage}
                  value={formik.values.failMessage}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  autoComplete="off"
                  name="failMessage"
                  helperText={formik.touched.failMessage && formik.errors.failMessage}
                  className="save-api-input"
                  variableInput={false}
                  inputValue={'input'}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="modal-footer-section save-request-footer">
        <div className="float-right">
          <button
            onClick={() => {
              handleClickClose();
            }}
            type="button"
            className="modal-close-btn"
          >
            {WEBSERVICE.SAVE_API_REQUEST_CANCEL}
          </button>
          {!changeButton && (
            <button
              disabled={formik.errors.name}
              onClick={() => handleSaveAndContinue(true)}
              className=" modal-save-btn"
              type="button"
            >
              {WEBSERVICE.SAVE_API_REQUEST_SAVE_CONTINUE}
            </button>
          )}
          <button
            disabled={formik.errors.name}
            type="button"
            onClick={() => handleSaveAndContinue()}
            className=" modal-save-btn"
          >
            {changeButton ? WEBSERVICE.SAVE_API_REQUEST_UPDATE : WEBSERVICE.SAVE_API_REQUEST_SAVE}
          </button>
        </div>
      </div>
    </div>
  );
}

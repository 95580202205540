import React, { Fragment, useContext, useState, useEffect } from 'react';
import { useTable, usePagination, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy } from 'react-table';
import matchSorter from 'match-sorter';
import '../execution.css';
import { AiOutlineWarning } from 'react-icons/ai';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';
import { useHistory } from 'react-router-dom';
import { Sync } from '@material-ui/icons';
import ContextMenu from '../../common/context_menu';
import CreateEditSuite from './create-edit-suit';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import {
  deleteSuiteReq,
  executeSuitePostReq,
  getAllSuiteReq,
  getExecutionMachineDetailsReq,
  schedulePostReq,
  updateSuiteReq,
  executeManualSuite,
  cloneReq,
  getRunSettingsDataReq,
  getSingleSuiteReq,
  getTimeZoneList,
} from '@api/api_services';
import { useAlert } from '../../common/alert_service/useAlert';
import Stack from '@mui/material/Stack';
import { Tooltip } from '@mui/material';
import { getFormattedTextStatus } from '../../results/result';
import MachineDetails from '../../test-development/execution-dashboard/modals/machine-details';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import '../../../pages/common/tree_toolbar.css';
import Input from '@material-ui/core/Input';
import { encodeSpecialCharater } from '@src/pages/test-development/shared/common-methods';
import LoaderModal from '@src/pages/test-development/script/modules/module/modals/loader-modal';
import RunSettingsModal from './run-settings-landing/run-settings-modal';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { CommonLoader } from '@src/pages/common/common-loader';
import { suiteResultStatistics } from '@src/pages/test-development/execution-dashboard/execution-dashboard-util';
import SearchBar from '@src/pages/analytics/common/search-bar';
import {
  getLicenseFeatures,
  HighlightText,
  isEmptyValue,
  convertDateHyphenReverse,
  findMatchingQuery,
  getTruncatedText,
  isValueIncludes,
  getDateFilteredByRange,
  getVerifiedDateAuto,
  archive,
  isEmptyObject,
} from '@util/common_utils';
import NoResultsFound from '@src/pages/common/Table/NoResultFound';
import FilterModal from '@pagescommon/filters/filter-modal';
import MultipleSelectCheckmarks from '@pagescommon/multi_select_checkbox_dropdown/multiple-select-checkmarks.js';
import FormControl from '@mui/material/FormControl';
import Chip from '@pagescommon/chip/chip.js';
import {
  ACTIONS,
  ALERT_MODAL_CONSTANTS,
  SUITES_CONSTANTS,
  defectAnalyticsLabel,
  filterDisplayDateFormat,
  getSelectedFilterLableName,
} from '@common/ui-constants';
import DateSelector from '@pagescommon/date/date-selector';
import { Icon } from 'fireflink-ui';
import { getIDB, removeIDB, setIDB } from '@src/util/localForage_idb_controller';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Suites = ({ MyAlert, ...props }) => {
  let[runData,setRunData] = useState(false);
  let scheduleRepeatArray = ['Once', 'Daily', 'Weekly', 'Monthly'];
  var today = new Date();
  let timeZoneArray = [];
  const classes = useStyles();
  const privilege = JSON.parse(localStorage.getItem('allowedProjects'));
  const [privilegeObject, setPrivilegeObject] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [selectedName, setDeleteName] = React.useState();
  const [suiteObj, setSuiteObj] = React.useState(null);
  const history = useHistory();
  const [suiteScreen, setSuiteScreen] = React.useState(true);
  const tab = window.location.pathname.split('/')[3];
  const [repeatData, setRepeat] = React.useState(scheduleRepeatArray[0]);
  const [operation, setOperation] = React.useState('');
  const [rerun, setRerun] = React.useState(
    localStorage.getItem('rerun') !== null ? JSON.parse(localStorage.getItem('rerun')) : false
  );

  const { AlertContatiner, MyAlert: childalert } = useAlert();
  const [expiryDate, setExpiryDate] = React.useState(new Date());
  const [sheduledDate, setSheduledDate] = React.useState(new Date());
  const [expiryTime, setExpiryTime] = React.useState(new Date());
  const [scheduleTime, setScheduleTime] = React.useState(new Date());
  const [pageIndexValue, setPageIndexValue] = React.useState(0);
  const [machineNames, setMachineNames] = React.useState('');
  const [validationMinTime, setValidationMinTime] = React.useState();
  const [minMinute, setMinMinute] = React.useState();
  const [minHour, setMinHour] = React.useState();
  const [scheduleBtnDisable, setScheduleBtnDisable] = React.useState(false);
  const [expireBtnDisable, setExpireBtnDisable] = React.useState(false);
  const [expiryDateDisable, setExpiryDateDisable] = React.useState(true);
  const [modalOperation, setModalOperation] = React.useState('');
  const [scheduleNameClicked, setScheduleNameClicked] = React.useState(false);
  const [openExecuteModal, setOpenExecuteModal] = React.useState(false);
  const [showLoaderModal, setShowLoaderModal] = React.useState(false);
  const [suiteCount, setSuiteCount] = React.useState();
  const [numberOfParallelRuns, setNumberOfParallelRuns] = React.useState();
  const [scheduleBtnClicked, setScheduleBtnClicked] = React.useState(false);
  const [showDeviceModal, setShowDeviceModal] = React.useState(false);
  const [selectedSuiteObjForExecution, setSelectedSuiteObjForExecution] = React.useState(false);
  const [recallTable, setRecallTable] = React.useState(false);
  const [runSettingsObject, setRunSettingsObject] = React.useState({});
  const [runSettingsManualObject, setRunSettingsManualObject] = React.useState({});
  const [operationForRunSettings, setOperationForRunSettings] = React.useState('create');
  const hasAutomation = window.privilege.hasAutomation(getLicenseFeatures());
  let [isHighlight, setIsHighlight] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');
  const [timezonedata, setTimeZoneData] = useState();
  const [timeZoneObject, setTimeZoneObject] = useState();

  const { isEditable, hasFullAccess, isProjectOpen } = React.useMemo(
    () => ({
      isProjectOpen: window.permission?.isProjectOpen(),
      isEditable: window.permission?.isEditAccess('execution'),
      hasFullAccess: window.permission?.isFullAccess('execution'),
    }),
    []
  );

  const timeZoneList = () => {
    getTimeZoneList().then((res) => {
      let resObject = res.data.responseObject;
      timeZoneArray.push(Object.values(resObject));
      setTimeZoneObject(resObject);
      setTimeZoneData(timeZoneArray);
    });
  };

  useEffect(() => {
    timeZoneList();
  }, []);
  let selectedSuiteData = async () => {
    let selectedSuiteItem = await getIDB('selectedSuite');
    selectedSuiteItem !== null ? setSuiteObj(selectedSuiteItem) : setSuiteObj({});
    selectedSuiteItem !== null ? setOperation('update') : setOperation('create');
  };
  useEffect(() => {
    selectedSuiteData();
  }, []);
  function getUserPrivilegeAccess() {
    const licenseFeatures = getLicenseFeatures();
    const hasManualTestCase = window.privilege.hasManualTestCase(licenseFeatures);
    const hasAutomation = window.privilege.hasAutomation(licenseFeatures);
    const hasDefects = window.privilege.hasDefects(licenseFeatures);
    const hasCBasic = window.privilege.hasCBasic(licenseFeatures);
    const privilegeObj = {
      hasManualTestCase,
      hasAutomation,
      hasDefects,
      hasCBasic,
    };
    setPrivilegeObject(privilegeObj);
  }

  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [isLoading, setIsLoading] = React.useState(true);
  let hasCloneOption = isProjectOpen && (isEditable || hasFullAccess);

  function getTimeForValidation() {
    let d = new Date();
    let time = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
    setValidationMinTime(time);
    setMinHour(d.getHours());
    setMinMinute(d.getMinutes());
  }

  const changeExpiryTime = (newValue) => {
    setExpiryTime(newValue);
  };

  const changeScheduleTime = (newValue) => {
    setScheduleTime(newValue);
  };

  const changeExpiryDate = (date) => {
    setExpiryDate(date);
    checkExpiryValidation();
    checkScheduleValidation();
    checkExpiryDateValidation();
  };
  const changeScheduleDate = (date) => {
    setSheduledDate(date);
    checkExpiryValidation();
    checkScheduleValidation();
    checkExpiryDateValidation();
    setTimeout(() => {
      let htmlEleForError = document.getElementById('date-picker-inline-expiredate-helper-text');
      if (htmlEleForError) {
        htmlEleForError.innerHTML = 'Expiry Date should not be less than Schedule Date';
      }
    }, 100);
  };

  function checkExpiryValidation() {
    setTimeout(() => {
      let expiryAreaInvalid = document?.getElementById('expiryId')?.getAttribute('aria-invalid');
      if (expiryAreaInvalid === 'true') {
        setExpireBtnDisable(true);
      } else {
        setExpireBtnDisable(false);
      }
    }, 100);
  }

  function checkExpiryDateValidation() {
    setTimeout(() => {
      let expiryDateInvalid = document?.getElementById('date-picker-inline-expiredate-helper-text');
      if (expiryDateInvalid) {
        setExpiryDateDisable(true);
      } else {
        setExpiryDateDisable(false);
      }
    }, 100);
  }

  function checkScheduleValidation() {
    setTimeout(() => {
      let scheduleAreaInvalid = document?.getElementById('scheduleId')?.getAttribute('aria-invalid');
      if (scheduleAreaInvalid === 'true') {
        setScheduleBtnDisable(true);
      } else {
        setScheduleBtnDisable(false);
      }
    }, 100);
  }

  const formik = useFormik({
    initialValues: {
      Name: '',
    },

    validationSchema: Yup.object({
      Name: Yup.string()
        .min(3, 'Minimum 3 characters')
        .max(25, 'Maximum 25 characters')
        .required('Required!')
        .matches(/^[-_A-Za-z0-9 ]*$|^[-_A-Za-z0-9 ][A-Za-z0-9 ]*[-_A-Za-z-_]$/, 'Name should be alphanumeric')
        .matches(/^[-a-zA-Z0-9-()_]+(\s+[-a-zA-Z0-9-()_]+)*$/, 'Space is not allowed in the beginning and at the end'),
    }),
    onSubmit: (setVal) => {
      getTimeForValidation();
      scheduleExecutionFun(suiteObj);
      setScheduleBtnClicked(true);
    },
  });

  function createAlertFun(type, msg) {
    if (type === 'success') {
      if (MyAlert) {
        MyAlert.success(msg);
      }
    } else if (type === 'danger') {
      if (MyAlert) {
        MyAlert.danger(msg);
      }
    } else if (type === 'info') {
      if (MyAlert) {
        MyAlert.info(msg);
      }
    } else if (type === 'warning') {
      if (MyAlert) {
        MyAlert.warning(msg);
      }
    }
  }

  function setFunctionality(value, option, suiteCount) {
    setDeleteName(value.name);
    if (option && option === ACTIONS.DELETE) {
      setOpen(true);
      setSuiteObj(value);
    } else if (option && option === ACTIONS.SCHEDULE) {
      scheduledSuite(value);
    } else if (option && option === ACTIONS.DETAILS) {
      setModalOperation(SUITES_CONSTANTS.DETAILS);
      setShowModal(true);
      setSuiteObj(value);
    } else if (option && option === ACTIONS.CLONE) {
      if (privilege?.features?.execution?.restrictions?.numberOfSuites > suiteCount) {
        cloneSuite(value);
      } else {
        createAlertFun(ALERT_MODAL_CONSTANTS.WARNING, SUITES_CONSTANTS.SCHEDULED_WARNING);
      }
    }
  }

  const scheduledSuite = async (value) => {
    const _suiteObj = await getSingleSuiteData(value);
    if (_suiteObj) {
      setSuiteObj(_suiteObj);
      setSelectedSuiteObjForExecution({ ..._suiteObj });
      getRunSettingsDataReq(value?._id, SUITES_CONSTANTS.SCHEDULE).then((results) => {
        if (results?.data?.responseObject) {
          let tempMacObj = results?.data?.responseObject;
          let tempObj = {
            executionTermination: tempMacObj.executionTermination,
            machines: tempMacObj.machines,
            resultSetting: tempMacObj.resultSetting,
            waitTime: tempMacObj.waitTime,
          };
          setRunSettingsObject(tempObj);
          setShowDeviceModal(true);
          setOperationForRunSettings(SUITES_CONSTANTS.UPDATE);
        } else {
          setOperationForRunSettings(SUITES_CONSTANTS.CREATE);
          setRunSettingsObject({ noData: true });
          let tempRunSettingsObj = {
            executionTermination: value?.executionTermination,
            resultSetting: value?.resultSetting,
            waitTime: value?.waitTime,
          };
          setRunSettingsObject(tempRunSettingsObj);
          setShowDeviceModal(true);
        }
      });
    }
    setModalOperation(SUITES_CONSTANTS.SCHEDULE);
    setExpiryTime(new Date());
    setScheduleTime(new Date());
    setExpiryDate(new Date());
    setSheduledDate(new Date());
    formik.resetForm();
    const timer = setTimeout(() => {
      checkExpiryValidation();
      checkScheduleValidation();
      checkExpiryDateValidation();
      let htmlEleForErrorExpiry = document.getElementById(SUITES_CONSTANTS.DATE_PICKER_EXPIRY_TEXT);
      console.log(htmlEleForErrorExpiry.innerHTML);
      if (htmlEleForErrorExpiry) {
        htmlEleForErrorExpiry.innerHTML = SUITES_CONSTANTS.DATE_PICKER_EXPIRY_SCHEDULE_DATE;
      }
    }, 100);
    if (timer) {
      clearTimeout(timer);
    }
  };

  const getSingleSuiteData = async (value) => {
    const suiteObj = await getSingleSuiteReq(value?._id ? value?._id : value?.id);
    if (suiteObj.data.responseObject) {
      let suiteData = suiteObj.data.responseObject;
      suiteData._id = suiteData.id;
      return suiteData;
    }
  };

  const cloneSuite = async (suiteData) => {
    startOrResumeProgress({
      stopAt: 60,
      intervalDuration: 20,
    });
    setIsLoading(true);
    let response;
    try {
      response = await cloneReq(suiteData.id, suiteData.id, 'suites', suiteData.projectId, null);
      if (response?.data?.status === 'SUCCESS' || response?.data?.message === 'SUCCESS') {
        resetProgress();
        getAllSuite('clonecall');
        let title = `${
          suiteData?.name?.length > 22 ? suiteData?.name?.substring(0, 22) + '...' : suiteData?.name
        } cloned successfully`;
        createAlertFun('success', `${title}`);
      } else {
        createAlertFun('danger', response?.data?.message);
      }
    } catch (err) {
      stopProgress();
      console.error('clone suite api error', err);
    }
  };

  let handelDlt = (id) => {
    deleteSuiteReq(id).then((res) => {
      if (res?.data?.responseObject) {
        setOpen(false);
        createAlertFun('success', `${getTruncatedText(suiteObj?.name || null, 22)} suite deleted successfully.`);
        getAllSuite();
      } else {
        createAlertFun('danger', res.data.message);
        setOpen(false);
      }
    });
  };

  function romoveRerun() {
    localStorage.removeItem('rerun');
    setRerun(false);
  }

  function createSuite(operation, data) {
    if (operation === 'create') {
      if (privilege?.features?.execution?.restrictions?.numberOfSuites > suiteCount) {
        history.push('/execution/suite/create-suite');
        localStorage.removeItem('selectedSuite');
        removeIDB('selectedSuite');
        localStorage.removeItem('selectedSingleSystem');
        localStorage.removeItem('selectedMultpleSystem');
        localStorage.removeItem('selectedDistributionSystem');
        localStorage.removeItem('selectedAutomaticDistributionSystem');
        localStorage.removeItem('rerun');
        localStorage.removeItem('selectedSuiteObj');
        localStorage.removeItem('selectedNodes');
        removeIDB('selectedNodes');
        localStorage.removeItem('allSelectedNodesForAutomaticDistributions');
        removeIDB('allSelectedNodesForAutomaticDistributions');
        localStorage.removeItem('selectedMobDevice');
        localStorage.removeItem('selectedSingleMobDevice');
        localStorage.removeItem('automaticSelectedMobDevice');
        localStorage.removeItem('manualySelectedMobDevice');
        setRerun(false);
        setSuiteScreen(false);
        setOperation(operation);
      } else if (suiteCount) {
        createAlertFun('warning', SUITES_CONSTANTS.SCHEDULED_WARNING);
      }
    } else if (operation === SUITES_CONSTANTS.DEVICE) {
      setModalOperation(operation);
    } else {
      localStorage.removeItem('selectedDistributionSystem');
      localStorage.removeItem('selectedAutomaticDistributionSystem');
      localStorage.removeItem('selectedSingleSystem');
      localStorage.removeItem('selectedMultpleSystem');
      localStorage.removeItem('selectedMobDevice');
      localStorage.removeItem('rerun');
      localStorage.removeItem('selectedSingleMobDevice');
      localStorage.removeItem('automaticSelectedMobDevice');
      localStorage.removeItem('manualySelectedMobDevice');
      editSuite(data);
      setSuiteScreen(false);
      setOperation(operation);
    }
  }
  const editSuite = async (data) => {
    const suiteData = await getSingleSuiteData(data);
    if (suiteData) {
      setSuiteObj({ ...suiteData });
      setRerun(false);
      const clone = (({ Actions, autoRun, ...o }) => o)(suiteData);
      if (clone?.clonedProjectEnvironmentVariables) {
        clone?.clonedProjectEnvironmentVariables.forEach((obj) => {
          delete obj['Actions'];
        });
      }
      if (clone?.clonedGlobalVariables) {
        clone?.clonedGlobalVariables.forEach((obj) => {
          delete obj['Actions'];
        });
      }

      if (clone?.clonedTestDataSets) {
        clone?.clonedTestDataSets.forEach((obj) => {
          delete obj['Actions'];
        });
      }

      if (clone && clone.selectedModulesAndScripts.length) {
        let tempArray = clone.selectedModulesAndScripts[0].children;
        let tempNodArray = [];
        tempArray.forEach((obj) => {
          const clone = (({ checkbox, ...o }) => o)(obj);
          tempNodArray.push(clone);
        });
        setIDB('selectedNodes', clone.selectedModulesAndScripts[0].children);
        setIDB('allSelectedNodesForAutomaticDistributions', tempNodArray);
      }
      history.push('/execution/suite/edit-suite');
      setIDB('selectedSuite', clone);
    }
  };

  function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);

    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);

    return (
      <span>
        Search:{' '}
        <input
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`Type here...`}
          style={{
            fontSize: '1.1rem',
            border: '0',
          }}
        />
      </span>
    );
  }

  // Define a default UI for filtering
  function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;
    return (
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search...`}
      />
    );
  }

  function fuzzyTextFilterFn(page, id, filterValue) {
    return matchSorter(page, filterValue, {
      keys: [(page) => page.values[id]],
    });
  }

  React.useEffect(() => {
    archive.suiteFlags = null;
    getUserPrivilegeAccess();
    getAllSuite();
  }, []);

  const [suites, setSuites] = React.useState([]);
  const [openMachineDetailsModal, setOpenMachineDetailsModal] = React.useState(false);
  const [machineDetails, setMachineDetails] = React.useState({});
  const contextMenuDataForManualSuite = [
    {
      option: 'Details',
    },
    ...(hasFullAccess
      ? [
          {
            option: 'Delete',
          },
        ]
      : []),
    ...(hasCloneOption
      ? [
          {
            option: 'Clone',
          },
        ]
      : []),
  ];
  const contextMenuDataWithDelete = [
    {
      option: 'Details',
    },
    ...(isEditable
      ? [
          {
            option: 'Schedule',
          },
        ]
      : []),
    ...(hasCloneOption
      ? [
          {
            option: 'Clone',
          },
        ]
      : []),
    ...(hasFullAccess
      ? [
          {
            option: 'Delete',
          },
        ]
      : []),
  ];

  function executeSuite(selectedSuite) {
    if (selectedSuite?.suiteType && selectedSuite?.suiteType.toLowerCase() === 'manual') {
      try {
        executeManualSuite(selectedSuite?._id).then((results) => {
          if (results?.data?.responseObject) {
            localStorage.setItem('urlSuiteId', selectedSuite?._id);
            history.push(
              `/execution/suite/${selectedSuite?.name}/suite-dashboard?suiteId=${selectedSuite?._id}&suiteType=${selectedSuite?.suiteType}`
            );
          } else if (results?.data?.message && results?.data?.message) {
            createAlertFun('info', `${results?.data?.message}`);
          }
        });
      } catch (err) {
        console.error(err);
      }
    } else {
      executeSuitePostReq(selectedSuite?._id).then((results) => {
        // const response = results.data.responseObject;
        if (results?.data?.responseObject) {
          localStorage.setItem('urlSuiteId', selectedSuite?._id);
          history.push(
            `/execution/suite/${encodeSpecialCharater(selectedSuite?.name)}/suite-dashboard?suiteId=${
              selectedSuite?._id
            }&suiteType=${selectedSuite?.suiteType}`
          );
        } else if (results?.data?.message && results?.data?.message.toLowerCase().includes('please connect a device')) {
          createAlertFun('info', results?.data?.message);
        } else if (
          results?.data?.message &&
          results?.data?.message.toLowerCase().includes('please install a browser')
        ) {
          createAlertFun('info', `${results?.data?.message}`);
        }
        // else if(results?.data?.message && results?.data?.message.startsWith("Selected") && results?.data?.message.includes(" client : TYSS-Shubham is Inactive. Please use 'Start-Flinko' shortcut to start the server and try again once the server is up.")){
        //     createAlertFun('info', `${results?.data?.message}`);
        // }
        else if (results?.data?.message && results?.data?.message) {
          createAlertFun('info', `${results?.data?.message}`);
        }
      });
    }
  }

  function updateAutorun(e, suiteObj) {
    e.preventDefault();
    if (e?.target?.checked) {
      suiteObj.autoRunSuite = 'true';
    } else {
      suiteObj.autoRunSuite = 'false';
    }

    const clone = (({ Actions, state, autoRun, ...o }) => o)(suiteObj); // remove b and c
    if (clone?.clonedGlobalVariables?.length) {
      clone?.clonedGlobalVariables.forEach((obj) => {
        delete obj['Actions'];
      });
    }
    if (clone?.clonedProjectEnvironmentVariables?.length) {
      clone?.clonedProjectEnvironmentVariables.forEach((obj) => {
        delete obj['Actions'];
      });
    }

    if (clone?.clonedTestDataSets) {
      clone?.clonedTestDataSets.forEach((obj) => {
        delete obj['Actions'];
      });
    }

    if (clone?.machines?.selectedMachines?.length) {
      clone?.machines?.selectedMachines.forEach((obj) => {
        delete obj['ExecutionEnviroment'];
        delete obj['browser'];
        delete obj['device'];
      });
    }
    let files = [];

    const formData = new FormData();
    formData.append('files', files);
    formData.append('data', JSON.stringify(clone));
    formData.append('updateMachines', false); //? NOTE:: this key required for machines should not update on access/autoRunSuite change

    updateSuiteReq(suiteObj._id, formData)
      .then((res) => {
        if (res.data && res.data.responseObject && res.data.responseObject.autoRunSuite === 'true') {
          getAllSuite();
          createAlertFun(
            'success',
            `Auto Run failed scripts option is enabled for ${getTruncatedText(suiteObj?.name, 22)}`
          );
        } else if (res.data && res.data.responseObject && res.data.responseObject.autoRunSuite === 'false') {
          getAllSuite();
          createAlertFun(
            'success',
            `Auto Run failed scripts option is disabled for ${getTruncatedText(suiteObj?.name, 22)}`
          );
        } else {
          createAlertFun('info', res.data.message);
        }
      })
      .catch((error) => {
        console.error('error');
      });
  }

  function getAllSuite(callType) {
    if (callType !== 'clonecall') {
      resetProgress();
    }
    startOrResumeProgress({
      stopAt: 90,
      intervalDuration: 10,
    });
    setIsLoading(true);

    getAllSuiteReq()
      .then((results) => {
        stopProgress();
        resetProgress();
        let response = results?.data?.responseObject;
        let suiteCount = results?.data?.responseMap?.licenseLevelSuiteCount;
        let numberOfParallelRuns = results.data.responseMap?.numberOfParallelRuns;
        setSuiteCount(suiteCount);
        setNumberOfParallelRuns(numberOfParallelRuns);
        if (response) {
          response = response.map((suiteObj) => {
            if (suiteObj?.executionResult?.resultStatus) {
              return {
                ...suiteObj,
                status: suiteObj?.executionResult?.resultStatus,
              };
            } else return suiteObj;
          });

          setSuites(response);
          resetProgress();
        }
        setIsLoading(false);
      })
      .catch((error) => {
        resetProgress();
        stopProgress();
        setIsLoading(false);
        console.error('GET_ALL_SUITE : ', error);
      });
    return [];
  }

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val) => !val;

  // Our table component
  function Table({ columns, data }) {
    const filterTypes = React.useMemo(
      () => ({
        // Add a new fuzzyTextFilterFn filter type.
        fuzzyText: fuzzyTextFilterFn,
        // Or, override the default text filter to use
        // "startWith"
        text: (page, id, filterValue) => {
          return page.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      []
    );

    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      nextPage,
      previousPage,
      state,
      visibleColumns,
      preGlobalFilteredRows,
      setGlobalFilter,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
        initialState: { pageIndex: pageIndexValue, pageSize: 9 },
      },
      useFilters, // useFilters!
      useGlobalFilter, // useGlobalFilter!
      useSortBy,
      usePagination
    );
    return (
      <>
        <table className="relative " {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr className="mt-4" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className="sticky top-0 px-4 py-3 text-current table-header_font-style bg-gray-100 "
                    {...column.getHeaderProps()}
                  >
                    <span className="flex flex-row">
                      {column.render('Header')}
                      {/* Render the columns filter UI */}
                      <div>
                        <span>
                          {/* {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <AiOutlineArrowDown className="mt-1 ml-1" />
                                                            : <AiOutlineArrowUp className="mt-1 ml-1" />
                                                        : ''} */}
                        </span>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr className="suite-project-row" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td className=" px-4 py-1 text-center" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }

  function setStateValue(stateValue, obj) {
    if (stateValue && !isEmptyObject(obj)) {
      obj.access = stateValue;
      const clone = (({ Actions, state, autoRun, ...o }) => o)(obj); // remove b and c
      let files = [];

      const formData = new FormData();
      formData.append('files', files);
      formData.append('data', JSON.stringify(clone));
      formData.append('updateMachines', false); //? NOTE:: this key required for machines should not update on access/autoRunSuite change
      updateSuiteReq(obj.id, formData)
        .then((res) => {
          if (res.data && res.data.responseObject) {
            getAllSuite();
            {
              res.data && res.data.responseObject.access === 'Private'
                ? createAlertFun('success', `${getTruncatedText(obj?.name, 22)} access is changed to private`)
                : createAlertFun('success', `${getTruncatedText(obj?.name, 22)} access is changed to public`);
            }
          } else if (res.data && !res.data.responseObject) {
            createAlertFun('info', `${getTruncatedText(res?.data?.message)}`);
          }
        })
        .catch((error) => {
          console.error('error');
        });
    }
  }

  function navigateToExecutionDetails(selectedObj) {
    const clone = (({ Actions, systemData, autoRun, ...o }) => o)(selectedObj);
    if (clone?.clonedGlobalVariables?.length) {
      clone?.clonedGlobalVariables.forEach((obj) => {
        delete obj['Actions'];
      });
    }
    if (clone?.clonedProjectEnvironmentVariables?.length) {
      clone?.clonedProjectEnvironmentVariables.forEach((obj) => {
        delete obj['Actions'];
      });
    }

    if (clone?.clonedTestDataSets) {
      clone?.clonedTestDataSets.forEach((obj) => {
        delete obj['Actions'];
      });
    }

    if (clone?.machines?.selectedMachines?.length) {
      clone?.machines?.selectedMachines.forEach((obj) => {
        delete obj['ExecutionEnviroment'];
        delete obj['browser'];
        delete obj['device'];
      });
    }
    localStorage.setItem('selectedSuiteObj', JSON.stringify(clone));
    localStorage.setItem('urlSuiteId', clone._id);

    history.push(
      `/execution/suite/${encodeSpecialCharater(selectedObj.name)}/suite-dashboard?suiteId=${
        selectedObj?._id
      }&suiteType=${selectedObj?.suiteType}`
    );
  }

  const getMachineDetails = async (data, execStatus, resultStatus) => {
    try {
      if (data) {
        let reqData = {
          machineName: data?.sysData?.machineInfo?.hostName,
          os: data?.sysData?.machineInfo?.osName,
          browser: data.sysData.browserName,
          device:
            data?.sysData?.deviceInfo && data?.sysData?.deviceInfo[0]?.name ? data?.sysData?.deviceInfo[0]?.name : '--',
          status: data?.status,
          owner: data?.owner,
        };
        if (execStatus) {
          if (execStatus === 'Completed') {
            if (resultStatus === 'PASS') {
              reqData.status = 'Passed';
            } else if (resultStatus === 'FAIL') {
              reqData.status = 'Failed';
            } else if (resultStatus === 'SKIP') {
              reqData.status = 'Skipped';
            } else if (resultStatus === 'WARNING') {
              reqData.status = 'Warning';
            } else {
              reqData.status = resultStatus;
            }
          } else {
            reqData.status = execStatus;
          }
        }
        getExecutionMachineDetailsReq(data.sysData.clientId).then((res) => {
          if (res.data && res.data.responseObject) {
            reqData.executions = res.data.responseObject;
          } else {
            reqData.executions = [];
          }
          setMachineDetails(reqData);
          setOpenMachineDetailsModal(true);
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  function getWidth(e) {
    let htmlEle = document.getElementById(e.data[e.cell.row.id].name);
    let width = htmlEle?.offsetWidth;
    return width;
  }

  // TODO :: handle Search filter :: START

  const [userSearched, setuserSearched] = useState('');
  const [suitesData, setSuiteData] = useState([]);
  let [openSearch, setOpenSearch] = useState(false);
  let [isFilterApplied, setIsFilterApplied] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  let openFilterModal = Boolean(anchorEl);
  const [suiteCaseType, setSuiteCaseType] = React.useState([]);
  const [access, setAccess] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  const [createdBy, setCreatedBy] = React.useState([]);
  const [modifiedBy, setModifiedBy] = useState([]);

  const [createdOn, setCreatedOn] = React.useState({
    from: null,
    to: null,
  });
  const [modifiedOn, setModifiedOn] = React.useState({
    from: null,
    to: null,
  });
  const [disableApplyClearBtn, setDisableApplyClearBtn] = useState(true);

  const [optionsListItem, setOptionsListItem] = useState({
    suiteCaseType: [],
    access: [],
    status: [],
    createdBy: [],
    modifiedBy: [],
  });

  useEffect(() => {
    setSuiteData(suites);
    if ((openSearch && userSearched) || isFilterApplied) {
      if (isFilterApplied) {
        handleSearchSubmit(searchTextHighlight, defectAnalyticsLabel.btnLabel.apply);
      } else {
        handleSearchSubmit(searchTextHighlight);
      }
    }
  }, [suites]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      if (isFilterApplied) {
        handleSearchSubmit('', 'RESET');
      } else {
        setSuiteData(suites);
        setSearchTextHighlight('');
        setIsHighlight(!isHighlight);
      }
    }
  }, [userSearched]);

  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');

    if (isFilterApplied) {
      handleSearchSubmit('', 'RESET');
    } else {
      setSuiteData(suites);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  let handleSearchSubmit = async (e, action) => {
    let newData;

    let query = {
      suiteType: suiteCaseType,
      access: access,
      status: status,
      createdByUname: createdBy,
      modifiedByUname: modifiedBy,
    };

    let queryDate = {
      createdOn: getVerifiedDateAuto(createdOn),
      modifiedOn: getVerifiedDateAuto(modifiedOn),
    };

    let isQuerySelected = Object.values(query)?.some((val) => val.length);

    if (
      action === defectAnalyticsLabel.btnLabel.apply ||
      (isFilterApplied && action !== defectAnalyticsLabel.btnLabel.clearAll)
    ) {
      if ((e?.key === 'Enter' && e?.target?.value?.trim() !== '') || searchTextHighlight) {
        let searchValue = e?.target?.value?.trim() || searchTextHighlight;
        if (action !== 'RESET') {
          newData = suites.filter(({ name }) => {
            return isValueIncludes(searchValue, name);
          });
        }

        let dataToFilterFrom = action === 'RESET' ? suites : newData;

        if (isQuerySelected) {
          newData = await findMatchingQuery(dataToFilterFrom, query);
        } else {
          newData = dataToFilterFrom;
        }

        if (queryDate.createdOn.length && queryDate.modifiedOn.length) {
          for (let dateFieldName in queryDate) {
            if (dateFieldName == 'createdOn') {
              newData = getDateFilteredByRange(
                newData,
                'createdOn',
                queryDate['createdOn'][0],
                queryDate['createdOn'][1]
              );
            } else if (!isEmptyValue(newData)) {
              newData = getDateFilteredByRange(
                newData,
                'modifiedOn',
                queryDate['modifiedOn'][0],
                queryDate['modifiedOn'][1]
              );
            }
          }
        } else if (queryDate.createdOn.length) {
          newData = getDateFilteredByRange(newData, 'createdOn', queryDate.createdOn[0], queryDate.createdOn[1]);
        } else if (queryDate.modifiedOn.length) {
          newData = getDateFilteredByRange(newData, 'modifiedOn', queryDate.modifiedOn[0], queryDate.modifiedOn[1]);
        }

        let searchkey = action === 'RESET' ? '' : e?.target?.value?.trim() || searchTextHighlight;
        setSearchTextHighlight(searchkey);
        setIsHighlight(!isHighlight);
      } else {
        if (isQuerySelected) {
          newData = await findMatchingQuery(suites, query);
        } else {
          newData = suites;
        }

        if (queryDate.createdOn.length && queryDate.modifiedOn.length) {
          for (let dateFieldName in queryDate) {
            if (dateFieldName == 'createdOn') {
              newData = getDateFilteredByRange(
                newData,
                'createdOn',
                queryDate['createdOn'][0],
                queryDate['createdOn'][1]
              );
            } else if (!isEmptyValue(newData)) {
              newData = getDateFilteredByRange(
                newData,
                'modifiedOn',
                queryDate['modifiedOn'][0],
                queryDate['modifiedOn'][1]
              );
            }
          }
        } else if (queryDate.createdOn.length) {
          newData = getDateFilteredByRange(newData, 'createdOn', queryDate.createdOn[0], queryDate.createdOn[1]);
        } else if (queryDate.modifiedOn.length) {
          newData = getDateFilteredByRange(newData, 'modifiedOn', queryDate.modifiedOn[0], queryDate.modifiedOn[1]);
        }
      }

      let selectedFilterList = {
        suiteCaseType,
        access,
        status,
        createdBy,
        modifiedBy,
        createdOn: {
          from: getVerifiedDateAuto(createdOn)?.at(0),
          to: getVerifiedDateAuto(createdOn)?.at(1),
        },
        modifiedOn: {
          from: getVerifiedDateAuto(modifiedOn)?.at(0),
          to: getVerifiedDateAuto(modifiedOn)?.at(1),
        },
      };

      sessionStorage.setItem('suitesFilters', JSON.stringify(selectedFilterList));
      setIsFilterApplied(true);
      setAnchorEl(null);
      setSuiteData(newData);
    } else if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (!e?.target?.value?.trim() == '' || (typeof e === 'string' && !isEmptyValue(e))) {
        newData = suites.filter(({ name }) => {
          return isValueIncludes(e, name);
        });

        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setSuiteData(newData);
        setIsHighlight(!isHighlight);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  const handleSelectDropdown = (event, action, perfromOn) => {
    if (action === 'add') {
      const selectedValue = typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
      switch (perfromOn) {
        case 'suiteCaseType':
          return setSuiteCaseType(selectedValue);
        case 'access':
          return setAccess(selectedValue);
        case 'status':
          return setStatus(selectedValue);
        case 'createdBy':
          return setCreatedBy(selectedValue);
        case 'modifiedBy':
          return setModifiedBy(selectedValue);
      }
    } else if (action === 'delete') {
      let valuetoDelete = typeof event === 'string' ? event : '';
      switch (perfromOn) {
        case 'suiteCaseType':
          return setSuiteCaseType(suiteCaseType.filter((val) => val !== valuetoDelete));
        case 'access':
          return setAccess(access.filter((val) => val !== valuetoDelete));
        case 'status':
          return setStatus(status.filter((val) => val !== valuetoDelete));
        case 'createdBy':
          return setCreatedBy(createdBy.filter((val) => val !== valuetoDelete));
        case 'modifiedBy':
          return setModifiedBy(modifiedBy.filter((val) => val !== valuetoDelete));
      }
    }
  };

  let renderChips = (listName) => {
    switch (listName) {
      case 'suiteCaseType':
        return suiteCaseType;
      case 'access':
        return access;
      case 'status':
        return status;
      case 'createdBy':
        return createdBy;
      case 'modifiedBy':
        return modifiedBy;
    }
  };

  let showSelectedList = [{ access }, { suiteCaseType }, { status }, { createdBy }, { modifiedBy }];

  let handleCreatedOnDate = (e, action) => {
    if (action == 'from') {
      setCreatedOn((perVal) => ({ ...perVal, from: e }));
    } else if (action == 'to') {
      setCreatedOn((perVal) => ({ ...perVal, to: e }));
    }
  };

  let handleModifiedOnDate = (e, action) => {
    if (action == 'from') {
      setModifiedOn((perVal) => ({ ...perVal, from: e }));
    } else if (action == 'to') {
      setModifiedOn((perVal) => ({ ...perVal, to: e }));
    }
  };

  const handleFilterOpen = async (event) => {
    setAnchorEl(event.currentTarget);

    let getUniqueList = (option) => {
      let itemList = [];
      suites?.forEach((val) => (val?.[option] ? itemList?.push(val?.[option]) : null));
      return [...new Set(itemList)];
    };

    setOptionsListItem({
      suiteCaseType: getUniqueList('suiteType'),
      access: getUniqueList('access'),
      status: getUniqueList('status'),
      createdBy: getUniqueList('createdByUname'),
      modifiedBy: getUniqueList('modifiedByUname'),
    });

    if (isFilterApplied) {
      let retriveData = JSON.parse(sessionStorage.getItem('suitesFilters') || null);
      setSuiteCaseType(retriveData?.suiteCaseType);
      setAccess(retriveData?.access);
      setStatus(retriveData?.status);
      setCreatedBy(retriveData?.createdBy);
      setModifiedBy(retriveData?.modifiedBy);
      setCreatedOn({
        from: convertDateHyphenReverse(retriveData?.createdOn?.from),
        to: convertDateHyphenReverse(retriveData?.createdOn?.to),
      });
      setModifiedOn({
        from: convertDateHyphenReverse(retriveData?.modifiedOn?.from),
        to: convertDateHyphenReverse(retriveData?.modifiedOn?.to),
      });
    }
  };

  const handleFilterClose = () => {
    if (!isFilterApplied) {
      setSuiteCaseType([]);
      setAccess([]);
      setStatus([]);
      setCreatedBy([]);
      setModifiedBy([]);
      setCreatedOn({ from: null, to: null });
      setModifiedOn({ from: null, to: null });
    }
    setAnchorEl(null);
  };

  let handleApplyFilter = async (action) => {
    if (action === defectAnalyticsLabel.btnLabel.apply) {
      handleSearchSubmit(searchTextHighlight, defectAnalyticsLabel.btnLabel.apply);
    } else if (action === defectAnalyticsLabel.btnLabel.clearAll) {
      setSuiteCaseType([]);
      setAccess([]);
      setStatus([]);
      setCreatedBy([]);
      setModifiedBy([]);
      setCreatedOn({ from: null, to: null });
      setModifiedOn({ from: null, to: null });
      if (isFilterApplied) {
        setIsFilterApplied(false);
        sessionStorage.removeItem('suitesFilters');
        if (searchTextHighlight) {
          handleSearchSubmit(searchTextHighlight, defectAnalyticsLabel.btnLabel.clearAll);
        } else {
          setSuiteData(suites);
        }
      }
    }
  };

  // TODO :: disable apply and clear all button
  useEffect(() => {
    if (!isEmptyValue([...access, ...suiteCaseType, ...status, ...createdBy, ...modifiedBy])) {
      setDisableApplyClearBtn(false);
    } else if ((createdOn.from && createdOn.to) || (modifiedOn.from && modifiedOn.to)) {
      setDisableApplyClearBtn(false);
    } else {
      setDisableApplyClearBtn(true);
    }
  }, [access, suiteCaseType, status, createdBy, modifiedBy, createdOn, modifiedOn]);

  // TODO :: handle Search filter :: END

  const columns = React.useMemo(
    () => [
      {
        Header: 'NAME',
        accessor: 'name',
        disableFilters: true,
        width: '15%',
        Cell: (e) => {
          let htmlEle = document.getElementById(e.data[e.cell.row.id].name);
          let tempWidth = htmlEle?.offsetWidth;
          if (!tempWidth) {
            setTimeout(() => {
              tempWidth = getWidth(e);
              setRecallTable(!recallTable);
            });
          }

          return (
            <>
              <div className="flex flex-row fontPoppinsRegularMd">
                <Tooltip title={e.value} placement="bottom">
                  <div
                    className={'suite-name-overflow-style cursor-pointer hyper_link_color-common '}
                    id={e.value}
                    onClick={(event) => {
                      navigateToExecutionDetails(e.cell.row.original);
                    }}
                  >
                    <HighlightText text={e.value} highlight={searchTextHighlight} />
                  </div>
                </Tooltip>
                <Tooltip title={`Scripts - ${e?.cell?.row?.original?.noOfScripts}`} placement="bottom">
                  <div className="type-badge type-label -mt-px"> S {e?.cell?.row?.original?.noOfScripts} </div>
                </Tooltip>
                <Tooltip title={`${e?.cell?.row?.original?.suiteType}`} placement="bottom">
                  <div className="type-badge type-label ml-2 -mt-px">
                    {' '}
                    {e?.cell?.row?.original?.suiteType === 'Automation' ? 'A' : 'M'}{' '}
                  </div>
                </Tooltip>
              </div>
            </>
          );
        },
      },
      {
        Header: 'ACCESS',
        accessor: 'state',
        disableFilters: true,
        width: '10%',
        Cell: (e) => {
          return (
            <div className="table-non-link-color-common float-left mr-2 mb-5 Access-table-font-style-common">
              <div className="border flex flex-row Pub-Pvt-Button-width rounded-xl">
                <button
                  value="Private"
                  id={`Private` + e?.row?.original.index}
                  onClick={() => setStateValue('Private', e.row?.original)}
                  disabled={!isEditable}
                  className={`${
                    e.row?.original.access === 'Private' ? 'selected-state-style' : ''
                  } p-1 m-1 priv-pub-font-style-button`}
                >
                  Private
                </button>
                <button
                  value="Public"
                  id={`Public` + e?.row?.original.index}
                  onClick={() => setStateValue('Public', e.row?.original)}
                  disabled={!isEditable}
                  className={`${
                    e?.row?.original.access === 'Public' ? 'selected-state-style' : ''
                  } p-1 m-1 priv-pub-font-style-button`}
                >
                  Public
                </button>
              </div>
            </div>
          );
        },
      },
      {
        Header: 'AUTO RUN FAILED SCRIPTS',
        accessor: 'autoRun',
        disableFilters: true,
        width: '10%',
        Cell: (e) => {
          return (
            <div className="table-non-link-color-common float-left table-font-style-common">
              <Stack direction="row" spacing={1} alignItems="center">
                <span className="mt-1.5">
                  <label className="switch mx-2.5">
                    <input
                      type="checkbox"
                      className="switchdisable"
                      onChange={(event) => updateAutorun(event, e.row.original)}
                      defaultChecked={e.row.original?.autoRunSuite === 'true'}
                      disabled={
                        (e.row.original?.suiteType ? (e.row.original?.suiteType === 'Manual' ? true : false) : false) ||
                        !isEditable
                      }
                    />
                    <span className="slider round" />
                  </label>
                </span>
              </Stack>
            </div>
          );
        },
      },
      {
        Header: 'LAST EXECUTED ON',
        accessor: 'executedOn',
        disableFilters: true,
        Cell: (e) => {
          return (
            <div className="table-non-link-color-common float-left table-font-style-common">
              {e.row.original?.executionResult?.['executedOn - onDisplay'] || 'N/A'}
            </div>
          );
        },
      },
      {
        Header: 'STATUS',
        accessor: 'executionResult.resultStatus',
        disableFilters: true,

        Cell: (e) => {
          return e.value === 'PASS' ? (
            <div
              className="float-left table-font-style-common cursor-pointer opacity-1 result-passed"
              onClick={(event) => {
                event.preventDefault();
                navigateToExecutionResult(e);
              }}
            >
              {getFormattedTextStatus(e.value)}
            </div>
          ) : e.value === 'Terminated' || e.value === 'FAIL' ? (
            <div
              className=" float-left table-font-style-common cursor-pointer opacity-1 result-failed"
              onClick={(event) => {
                event.preventDefault();
                navigateToExecutionResult(e);
              }}
            >
              {getFormattedTextStatus(e.value)}
            </div>
          ) : e.value === 'Aborted' ? (
            <div
              className=" float-left table-font-style-common cursor-pointer opacity-1 result-aborted"
              onClick={(event) => {
                event.preventDefault();
                navigateToExecutionResult(e);
              }}
            >
              {getFormattedTextStatus(e.value)}
            </div>
          ) : e.value === 'Scheduled' ? (
            <div className=" float-left table-font-style-common result-scheduled">
              {getFormattedTextStatus(e.value)}
            </div>
          ) : e.value === 'WARNING' ? (
            <div
              className=" text-yellow-400 float-left table-font-style-common cursor-pointer opacity-1"
              onClick={(event) => {
                event.preventDefault();
                navigateToExecutionResult(e);
              }}
            >
              {getFormattedTextStatus(e.value)}
            </div>
          ) : e.value === 'Cancelled' ? (
            <div className=" table-non-link-color-common float-left table-font-style-common" onClick={() => {}}>
              Cancelled
            </div>
          ) : e?.cell?.row?.values?.suiteType === 'Manual' && e.value === 'SKIP' ? (
            <div
              className=" text-gray-400 float-left table-font-style-common opacity-1 result-skipped"
              onClick={(event) => {
                event.preventDefault();
                navigateToExecutionResult(e);
              }}
            >
              {getFormattedTextStatus(e.value ? e.value : 'N/A')}
            </div>
          ) : (
            <div className=" text-gray-400 float-left table-font-style-common opacity-1 result-skipped">
              {getFormattedTextStatus(e.value ? e.value : 'N/A')}
            </div>
          );
        },
      },
      {
        Header: 'RESULT',
        accessor: 'result',
        disableFilters: true,
        Cell: (e) => {
          return (
            <div className="table-non-link-color-common float-left table-font-style-common cursor">
              {suiteResultStatistics(e.row.original?.executionResult)}
            </div>
          );
        },
      },
      {
        Header: 'ACTIONS',
        accessor: 'Actions',
        disableFilters: true,
        Cell: (e) => {
          return (
            <div className="table-non-link-color-common action-suite table-font-style-common mb-2">
              <span className="flex flex-row">
                {isProjectOpen && (
                  <Tooltip title="Run" placement="top">
                    <img
                      src="\assets\execution_logos\execution.svg"
                      className="project-action cursor-hand mr-3 mt-1"
                      height="17px"
                      width="17px"
                      onClick={() => {
                        setRunData(true)
                        openDeviceModal(e.row.original);
                        setModalOperation('');
                      }}
                    ></img>
                  </Tooltip>
                )}
                {isEditable && (
                  <span>
                    <Tooltip title="Edit" placement="top">
                      <img
                        src="\assets\execution_logos\edit.svg"
                        className="project-action  cursor-hand mr-3 mt-2"
                        height="17px"
                        width="17px"
                        onClick={() => {
                          createSuite('update', e.row.original);
                        }}
                      ></img>
                    </Tooltip>
                  </span>
                )}

                <div className="project-action cursor-hand">
                  <ContextMenu
                    contextMenuData={
                      e.row.original?.suiteType?.toLowerCase() === 'manual'
                        ? contextMenuDataForManualSuite
                        : contextMenuDataWithDelete
                    }
                    func={(event) => {
                      setFunctionality(e.row.original, event.target.textContent, suiteCount);
                    }}
                  />
                </div>
              </span>
            </div>
          );
        },
      },
    ],
    [suiteCount, isHighlight]
  );

  const handleChange = (event) => {
    setRepeat(event.target.value);
  };

  function clearFun() {
    history.push('/execution/suite/create-suite');
  }
  const navigateToExecutionResult = (event) => {
    if (event) {
      const row_data = event.cell.row.original;
      const exec_name = row_data.name;
      const script_id = row_data._id;
      let findId = event.cell.column.filteredRows.find((o) => o.original.id === script_id);
      let exec_id = findId.original.executionResult.executionId;
      let suiteIdObject = event?.data?.filter((e) => e.name === exec_name);
      let suiteId = suiteIdObject.length >= 1 ? suiteIdObject[0]?._id : '';
      let suiteType = suiteIdObject.length >= 1 ? suiteIdObject[0].suiteType : '';
      localStorage.setItem('urlSuiteId', suiteId);
      let multiple = false;
      let macDetails = event?.cell?.row?.original?.executionResult?.machines;
      if (
        (macDetails &&
          (['SEND_SUITE_TO_ALL', 'DISTRIBUTE'].includes(macDetails['executionType']) ||
            macDetails['userExecutionType'] === 'SINGLE_USER_MULTIPLE_MACHINE')) ||
        event?.cell?.row?.original?.systemData?.suiteType?.toLowerCase() === 'automation'
      ) {
        multiple = true;
      }
      history.push(
        `/execution/suite/${encodeSpecialCharater(
          exec_name
        )}/execution dashboard/overview/machines/Result?executionId=${exec_id}&multiple=${multiple}&suiteId=${suiteId}&suiteType=${suiteType}`
      );
    }
  };

  function scheduleExecutionFun(suiteObj) {
    let sceduleObj = {
      projectId: suiteObj ? suiteObj?.projectId : null,
      type: 'SUITE',
      suiteId: suiteObj ? suiteObj?.id : null,
      name: formik?.values?.Name,
      schedule: {
        suiteName: suiteObj ? suiteObj?.name : null,
        runIn: suiteObj.machines && suiteObj.machines.multiple === 'false' ? 'Single Machine' : 'Multiple Machine',
        machineName: machineNames,
        repeat: repeatData,
        scheduleDate: sheduledDate.toISOString(),
        scheduleTime: scheduleTime.toISOString(),
        expireDate: expiryDate.toISOString(),
        expireTime: expiryTime.toISOString(),
        estimatedDuration: '',
      },
    };
    let scheduleAreaInvalid;
    let expiryAreaInvalid;
    setTimeout(() => {
      scheduleAreaInvalid = document?.getElementById('scheduleId')?.getAttribute('aria-invalid');
      expiryAreaInvalid = document?.getElementById('expiryId')?.getAttribute('aria-invalid');
      if (scheduleAreaInvalid === 'true') {
        setScheduleBtnDisable(true);
      } else {
        setScheduleBtnDisable(false);
      }
      if (expiryAreaInvalid === 'true') {
        setExpireBtnDisable(true);
      } else {
        setExpireBtnDisable(false);
      }

      if (scheduleAreaInvalid !== 'true' && expiryAreaInvalid !== 'true') {
        schedulePostReq(sceduleObj)
          .then((res) => {
            if (res.data && res.data.responseObject) {
              // getAllSuite();

              setRepeat('Once');
              setShowModal(false);
              setScheduleNameClicked(false);
              createAlertFun('success', `${suiteObj?.name} scheduled successfully`);
              history.push(
                `/execution/suite/${encodeSpecialCharater(suiteObj?.name)}/suite-scheduled-instance?suiteId=${
                  suiteObj?._id
                }`
              );
            } else {
              // childalert.danger(res.data.message);

              if (res?.data?.message && res?.data?.message.toLowerCase().includes('please connect a device')) {
                createAlertFun('info', res?.data?.message);
              } else if (res?.data?.message && res?.data?.message.toLowerCase().includes('please install a browser')) {
                createAlertFun('info', `${res?.data?.message}`);
              } else if (
                res?.data?.message &&
                res?.data?.message.startsWith('Client') &&
                res?.data?.message.toLowerCase().includes('is not available')
              ) {
                createAlertFun('info', `${res?.data?.message}`);
              } else {
                createAlertFun('danger', res.data.message);
              }
              setScheduleBtnClicked(false);
            }
          })
          .catch((error) => {
            setScheduleBtnClicked(false);
            console.error('error');
          });
      }
    }, 100);
  }

  const executionModal = () => {
    return (
      <Modal open={openExecuteModal} className="modal-dialog">
        <div className="modal-container page-modal-size execute-warning">
          <div className="modal-header">
            {/* <AiOutlineWarning className="col-span-1"/> */}
            <label className="Popup-header-common Popup-header-layout-style-user col-span-1">Warning</label>
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                setOpenExecuteModal(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="modal-body" id="journal-scroll">
            Manual suite cannot be executed. You have to update results manually in execution dashboard section.
            <br />
            Are you sure you want to continue?
          </div>
          <div className="modal-footer">
            <button
              className="secondary-btn"
              onClick={() => {
                setOpenExecuteModal(false);
              }}
            >
              Cancel
            </button>
            <button id="selectBtn" className="primary-btn" onClick={() => executeSuite(suiteObj)}>
              Continue
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const openDeviceModal = async (selectedSuite) => {
    const suiteData = await getSingleSuiteData(selectedSuite);
    if (suiteData) {
      setSuiteObj({ ...suiteData });
      setSelectedSuiteObjForExecution(suiteData);
      let tempMacObj;
      if (suiteData?.machines?.selectedMachines && suiteData?.machines?.selectedMachines.length) {
        tempMacObj = {
          executionTermination: suiteData.executionTermination,
          machines: suiteData.machines,
          resultSetting: suiteData.resultSetting,
          waitTime: suiteData.waitTime,
        };
      } else {
        getRunSettingsDataReq(selectedSuite?._id, 'run').then((results) => {
          if (results?.data?.responseObject) {
            tempMacObj = results?.data?.responseObject;
          }
        });
      }
      if (tempMacObj) {
        if (suiteData.suiteType.toLowerCase() === 'manual') {
          let tempManualObj = {
            machines: tempMacObj.machines,
          };
          setRunSettingsManualObject(tempManualObj);
        } else {
          let tempObj = {
            executionTermination: tempMacObj.executionTermination,
            machines: tempMacObj.machines,
            resultSetting: tempMacObj.resultSetting,
            waitTime: tempMacObj.waitTime,
          };
          setRunSettingsObject(tempObj);
          setOperationForRunSettings('update');
        }
        setShowDeviceModal(true);
      } else {
        setRunSettingsManualObject({});
        setRunSettingsObject({ noData: true });
        setShowDeviceModal(true);
        setOperationForRunSettings('create');
      }
    }
  };

  function closeDeviceModal() {
    history.push(`/execution/suite`);
    setShowDeviceModal(false);
  }

  const getColumns = (columns) => {
    let updatedColumns = columns.filter((col) => col.accessor !== 'autoRun');
    return hasAutomation ? columns : updatedColumns;
  };
  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block marginupper pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/4">
                <div className="inline-block">
                  <div className="flex flex-start ml-2">
                    <span className="pt-5 pl-3">
                      <ExclamationIcon className="h-6 w-6 text-yellow-600 " />
                    </span>
                    <Dialog.Title as="h3" className="text-sm leading-6 ml-4 pt-3 font-small text-gray-900">
                      <span className="fontPoppinsSemiboldLg block">Warning </span>
                      <span className="fontPoppinsMediumMd">Delete </span>
                    </Dialog.Title>
                  </div>
                </div>
                <hr className="line mt-px" />
                <div className="fontPoppinsMediumMd mt-3 text-gray-500 ml-16 mr-8">
                  <p className="">
                    Your Suite Data will be lost.Are you sure you want to delete{' '}
                    {`${getTruncatedText(suiteObj?.name, 22)}`}?{' '}
                  </p>
                  <p className="mt-2"> How do you want to proceed?</p>
                </div>
                <hr className="line mt-3" />
                <div className="mt-2 pb-2 flex flex-row float-right">
                  <button type="button" className="mt-3 gray-btn ml-1" onClick={() => handelDlt(suiteObj._id)}>
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 ml-4 primary-btn "
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Modal open={showModal} className="modal-dialog">
        {modalOperation === 'schedule' ? (
          <div className="modal-container page-modal-size">
            <form onSubmit={formik.handleSubmit}>
              <div className="modal-header ">
                <label className="Popup-header-common Popup-header-layout-style-user">Schedule</label>
                <IconButton
                  color="primary"
                  component="span"
                  className="close"
                  onClick={() => {
                    setShowModal(false);
                    setRepeat('Once');
                    setScheduleNameClicked(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>

              <div className="modal-body" id="journal-scroll">
                <div className="grid grid-cols-2  mt-2 pb-3 ml-1 ">
                  <div>
                    <label className="schedule-font-style">
                      <span className="text-red-400">* </span>Name
                    </label>
                    <br></br>
                    <Input
                      className="w-1/2"
                      type="text"
                      name="Name"
                      id="Name"
                      autoComplete="off"
                      placeholder={`Enter the name of the scheduler`}
                      onChange={formik.handleChange}
                      onClick={() => setScheduleNameClicked(true)}
                      style={{ marginLeft: '9px', width: '225px' }}
                    />
                    {(formik.errors.Name && formik.touched.Name) || formik.values.Name ? (
                      <div className="text-red-500 text-xs md:mt-1 ml-2">{formik.errors.Name}</div>
                    ) : scheduleNameClicked && !formik.values.Name ? (
                      <div className="text-red-500 text-xs md:mt-1 ml-2">Required</div>
                    ) : null}
                  </div>

                  <div></div>
                </div>

                <div className="grid grid-cols-2  mt-2 pb-3 ml-1 ">
                  <div>
                    <label className="schedule-font-style">
                      <span className="text-red-400">* </span>Suite Name
                    </label>
                    <br />
                    <TextField
                      id="standard-basic"
                      disabled="true"
                      className={classes.root}
                      value={suiteObj?.name ? suiteObj?.name : null}
                    />
                  </div>
                  <div>
                    <label className="schedule-font-style">
                      <span className="text-red-400">* </span> Run In
                    </label>
                    <br />
                    <TextField
                      id="standard-basic"
                      disabled="true"
                      className={classes.root}
                      value={
                        suiteObj?.machines && suiteObj?.machines?.multiple === 'false'
                          ? 'Single Machine'
                          : 'Multiple Machine'
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2  mt-2 pb-3 ml-1 ">
                  <div>
                    <label className="schedule-font-style">
                      <span className="text-red-400">* </span> Machine Name
                    </label>
                    <br />
                    <TextField id="standard-basic" disabled="true" className={classes.root} value={machineNames} />
                  </div>
                  <div>
                    <label className="schedule-font-style">
                      <span className="text-red-400">* </span>Recurrence
                    </label>
                    <br />
                    <div className="className={classes.formControl} ml-2">
                      <Select
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={repeatData}
                        onChange={handleChange}
                        style={{ width: '80%' }}
                      >
                        {scheduleRepeatArray.map((scheduleVal) => (
                          <MenuItem value={scheduleVal}>{scheduleVal}</MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-2  mt-2 pb-3 ml-1 ">
                  <div className="schedule-style">
                    <label className="schedule-font-style">
                      <span className="text-red-400">* </span>Schedule Date/Time
                    </label>
                    <br />
                    <div className="flex flex-row mr-3">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justifyContent="space-around">
                          <KeyboardDatePicker
                            disableToolbar
                            InputProps={{ readOnly: true }}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            minDate={new Date()}
                            id="date-picker-inline"
                            value={sheduledDate}
                            onChange={changeScheduleDate}
                            autoOk={true}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                          <TimePicker
                            ampm={false}
                            openTo="hours"
                            views={['hours', 'minutes']}
                            mask="__:__:__"
                            inputFormat="HH:mm"
                            minTime={
                              JSON.stringify(sheduledDate)?.substring(0, 11) === JSON.stringify(today)?.substring(0, 11)
                                ? new Date(0, 0, 0, JSON.stringify(validationMinTime)?.substring(1, 3))
                                : null
                            }
                            shouldDisableTime={(timeValue, clockType) => {
                              if (
                                JSON.stringify(sheduledDate)?.substring(0, 11) ===
                                JSON.stringify(today)?.substring(0, 11)
                              ) {
                                let scheduledHour = scheduleTime.getHours();
                                if (JSON.stringify(minHour) === JSON.stringify(scheduledHour)) {
                                  if (clockType === 'minutes') {
                                    return timeValue <= minMinute;
                                  }
                                }
                              }
                            }}
                            // label="With seconds"
                            value={scheduleTime}
                            onChange={(newValue) => {
                              changeScheduleTime(newValue);
                              checkScheduleValidation();
                            }}
                            renderInput={(params) => <TextField id="scheduleId" {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div>
                    {!scheduleTime ? <div className="schedule-no-data-style">Select Time</div> : null}
                  </div>
                  <div className="expire-style">
                    <label className="schedule-font-style">
                      <span className="text-red-400">* </span>Expire Date/Time
                    </label>
                    <br />
                    <div className="flex flex-row mr-3">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justifyContent="space-around">
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            InputProps={{ readOnly: true }}
                            minDate={new Date(sheduledDate)}
                            id="date-picker-inline-expiredate"
                            value={expiryDate}
                            onChange={changeExpiryDate}
                            autoOk={true}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                          <TimePicker
                            ampm={false}
                            openTo="hours"
                            views={['hours', 'minutes']}
                            inputFormat="HH:mm"
                            minTime={
                              JSON.stringify(expiryDate).substring(0, 11) ===
                              JSON.stringify(sheduledDate).substring(0, 11)
                                ? new Date(0, 0, 0, new Date(scheduleTime).toString().slice(16, 18))
                                : null
                            }
                            shouldDisableTime={(timeValue, clockType) => {
                              if (
                                JSON.stringify(sheduledDate)?.substring(0, 11) ===
                                JSON.stringify(expiryDate)?.substring(0, 11)
                              ) {
                                let scheduledHour = scheduleTime?.getHours();
                                let expiredHour = expiryTime?.getHours();
                                let minExpMinute = scheduleTime?.getMinutes();
                                if (expiredHour === scheduledHour) {
                                  if (clockType === 'minutes') {
                                    return timeValue <= minExpMinute;
                                  }
                                }
                              }
                            }}
                            mask="__:__:__"
                            // label="With seconds"
                            value={expiryTime}
                            onChange={(newValue) => {
                              changeExpiryTime(newValue);
                              checkExpiryValidation();
                            }}
                            renderInput={(params) => <TextField id="expiryId" {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                      <br />
                    </div>
                    {!expiryTime ? <div className="expiry-no-data-style">Select Time</div> : null}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  className="secondary-btn"
                  onClick={() => {
                    setShowModal(false);
                    setRepeat('Once');
                    setScheduleNameClicked(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="primary-btn"
                  disabled={
                    scheduleBtnDisable ||
                    expireBtnDisable ||
                    expiryDateDisable ||
                    !expiryTime ||
                    !scheduleTime ||
                    scheduleBtnClicked
                  }
                >
                  Schedule
                </button>
              </div>
            </form>
          </div>
        ) : modalOperation === 'details' ? (
          <div className="modal-container page-modal-size">
            <div className="modal-header ">
              <Tooltip title={`Suite Details - ${suiteObj?.name}`}>
                <label className="Popup-header-common Popup-header-layout-style-user">
                  Suite Details - {getTruncatedText(suiteObj?.name, 22)}
                </label>
              </Tooltip>
              <IconButton
                color="primary"
                component="span"
                className="close"
                onClick={() => {
                  setShowModal(false);
                  setRepeat('Once');
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div className="modal-body" id="journal-scroll">
              <div className="grid grid-cols-2  mt-2 pb-3 ml-1 ">
                <div>
                  <label htmlFor="Name" className="details-key-style-common">
                    Name
                  </label>
                  <br />
                  <Tooltip title={`${suiteObj?.name}`}>
                    <label htmlFor="Name" className="block truncate details-data-style-common">
                      {suiteObj?.name}
                    </label>
                  </Tooltip>
                </div>
                <div>
                  <label htmlFor="Name" className="details-key-style-common">
                    Description
                  </label>
                  <br />
                  <Tooltip title={`${suiteObj?.description || 'NA'}`}>
                    <label htmlFor="Name" className="details-data-style-common">
                      {getTruncatedText(suiteObj?.description || 'NA', 25)}
                    </label>
                  </Tooltip>
                </div>
              </div>

              <div className="grid grid-cols-2  mt-2 pb-3 ml-1 ">
                <div>
                  <label htmlFor="Name" className="details-key-style-common">
                    Suite Id
                  </label>
                  <br />
                  <label htmlFor="Name" className="details-data-style-common">
                    {suiteObj?._id ? suiteObj?._id : suiteObj?.id}
                  </label>
                </div>
                <div>
                  <label htmlFor="Name" className="details-key-style-common">
                    project Id
                  </label>
                  <br />
                  <label htmlFor="Name" className="details-data-style-common">
                    {suiteObj?._id ? suiteObj?.projectId : 'NA'}
                  </label>
                </div>
              </div>
              <hr className="border-dashed border-blue-100 col-span-2"></hr>
              <div className="grid grid-cols-2  mt-2 pb-3 ml-1 ">
                <div>
                  <label htmlFor="Name" className="details-key-style-common">
                    Created By
                  </label>
                  <br />
                  <label htmlFor="Name" className="details-data-style-common">
                    {suiteObj?.createdByUname}
                  </label>
                </div>
                <div>
                  <label htmlFor="Name" className="details-key-style-common">
                    Created On
                  </label>
                  <br />
                  <label htmlFor="Name" className="details-data-style-common">
                    {suiteObj?.createdOn}
                  </label>
                </div>
              </div>
              <div className="grid grid-cols-2  mt-2 pb-3 ml-1 ">
                <div>
                  <label htmlFor="Name" className="details-key-style-common">
                    Modified By
                  </label>
                  <br />
                  <label htmlFor="Name" className="details-data-style-common">
                    {suiteObj?.modifiedByUname ? suiteObj?.modifiedByUname : 'NA'}
                  </label>
                </div>
                <div>
                  <label htmlFor="Name" className="details-key-style-common">
                    Modified On
                  </label>
                  <br />
                  <label htmlFor="Name" className="details-data-style-common">
                    {suiteObj?.modifiedOn ? suiteObj?.modifiedOn : 'NA'}
                  </label>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="gray-btn"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        ) : null}
      </Modal>

      {executionModal()}

      {!tab ? (
        <div className="content_area_style  overflow-auto" id="journal-scroll">
          <AlertContatiner />
          <div className="content_area_header_style">
            <div>
              <label className="main-header-label float-left ">Suites</label>
            </div>

            <div className="flex flex-row float-right items-center">
              <SearchBar
                open={openSearch}
                searchValue={userSearched}
                handleSearch={handleSearch}
                onSearchClose={handleSearchClose}
                handleSearchOpen={handleSearchOpen}
                enterKeyHandler={handleSearchSubmit}
                disable={isEmptyValue(suites)}
              />

              {/* TODO :: FILTER BLOCK :: START */}

              <FilterModal
                open={openFilterModal}
                anchorEl={anchorEl}
                onClose={handleFilterClose}
                handleFilterOpen={handleFilterOpen}
                onApply={handleApplyFilter}
                isFilterApplied={isFilterApplied}
                disableFilters={isEmptyValue(suites)}
                disableClearAllBtn={disableApplyClearBtn}
                disableApplyBtn={disableApplyClearBtn}
              >
                <div
                  className={
                    showSelectedList.some((val) => val[Object.keys(val)].length > 0)
                      ? 'border-2 rounded mb-2 max-h-40 overflow-auto'
                      : 'hidden'
                  }
                >
                  {showSelectedList?.map((obj, index) => {
                    let itemDisplay = obj[Object.keys(obj)];
                    if (!isEmptyValue(itemDisplay)) {
                      return (
                        <div className="p-2" key={index + 1}>
                          <div className="rs-blue capitalize fontPoppinsSemiboldSm border-b-2">
                            {getSelectedFilterLableName(Object.keys(obj)?.[0])}
                          </div>
                          <div className="chips-section flex flex-wrap p-1">
                            {renderChips(Object.keys(obj)?.[0])?.map((val, chipIndex) => {
                              return (
                                <Chip
                                  key={chipIndex + 2}
                                  text={val}
                                  title={val}
                                  onClick={(e) => handleSelectDropdown(e, 'delete', Object.keys(obj)?.[0])}
                                />
                              );
                            })}
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>

                <div className="grid grid-rows-3 grid-flow-col gap-2">
                  <div className=" flex gap-2 mt-1 ">
                    <FormControl sx={{ width: 173 }}>
                      <label className="fontPoppinsRegularSm mb-1">Suite Type</label>
                      <MultipleSelectCheckmarks
                        options={optionsListItem['suiteCaseType'] || []}
                        onChange={(e) => handleSelectDropdown(e, 'add', 'suiteCaseType')}
                        value={suiteCaseType}
                      />
                    </FormControl>
                    <FormControl sx={{ width: 173 }}>
                      <label className="fontPoppinsRegularSm mb-1">Access</label>
                      <MultipleSelectCheckmarks
                        options={optionsListItem['access'] || []}
                        onChange={(e) => handleSelectDropdown(e, 'add', 'access')}
                        value={access}
                      />
                    </FormControl>

                    <FormControl sx={{ width: 173 }}>
                      <label className="fontPoppinsRegularSm mb-1">Status</label>
                      <MultipleSelectCheckmarks
                        options={optionsListItem['status'] || []}
                        onChange={(e) => handleSelectDropdown(e, 'add', 'status')}
                        value={status}
                      />
                    </FormControl>
                  </div>

                  <div className=" flex gap-2 mt-1 ">
                    <FormControl sx={{ width: 173 }}>
                      <label className="fontPoppinsRegularSm mb-1">Created By</label>
                      <MultipleSelectCheckmarks
                        options={optionsListItem['createdBy'] || []}
                        onChange={(e) => handleSelectDropdown(e, 'add', 'createdBy')}
                        value={createdBy}
                      />
                    </FormControl>

                    <FormControl sx={{ width: 173 }}>
                      <label className="fontPoppinsRegularSm mb-1">Created On</label>
                      <DateSelector
                        inputFormat={filterDisplayDateFormat}
                        value={createdOn.from}
                        onChange={(e) => handleCreatedOnDate(e, 'from')}
                        maxDate={createdOn?.to || new Date()}
                        label="From Date"
                      />
                    </FormControl>

                    <FormControl sx={{ width: 173 }}>
                      <label className="fontPoppinsRegularSm mb-1">&nbsp;</label>
                      <DateSelector
                        inputFormat={filterDisplayDateFormat}
                        value={createdOn.to}
                        onChange={(e) => handleCreatedOnDate(e, 'to')}
                        minDate={createdOn?.from}
                        maxDate={new Date()}
                        label="To Date"
                        disabled={!createdOn.from}
                      />
                    </FormControl>
                  </div>

                  <div className=" flex gap-2 mt-1 ">
                    <FormControl sx={{ width: 173 }}>
                      <label className="fontPoppinsRegularSm mb-1">Modified By</label>
                      <MultipleSelectCheckmarks
                        options={optionsListItem['modifiedBy'] || []}
                        onChange={(e) => handleSelectDropdown(e, 'add', 'modifiedBy')}
                        value={modifiedBy}
                      />
                    </FormControl>

                    <FormControl sx={{ width: 173 }}>
                      <label className="fontPoppinsRegularSm mb-1">Modified On</label>
                      <DateSelector
                        inputFormat={filterDisplayDateFormat}
                        value={modifiedOn.from}
                        onChange={(e) => handleModifiedOnDate(e, 'from')}
                        maxDate={modifiedOn?.to || new Date()}
                        label="From Date"
                      />
                    </FormControl>

                    <FormControl sx={{ width: 173 }}>
                      <label className="fontPoppinsRegularSm mb-1">&nbsp;</label>
                      <DateSelector
                        inputFormat={filterDisplayDateFormat}
                        value={modifiedOn.to}
                        onChange={(e) => handleModifiedOnDate(e, 'to')}
                        minDate={modifiedOn?.from}
                        maxDate={new Date()}
                        label="To Date"
                        disabled={!modifiedOn.from}
                      />
                    </FormControl>
                  </div>
                </div>
              </FilterModal>

              {/* TODO :: FILTER BLOCK:: END*/}

              {isProjectOpen && (
                <button
                  disabled={!suites.length}
                  className={`${!suites.length ? 'opacity-30' : ''} mx-1 mr-5`}
                  onClick={() => {
                    getAllSuite();
                  }}
                >
                  <Tooltip title="Sync" placement="top">
                    <Sync fontSize="small" className={'text-gray-700'} />
                  </Tooltip>
                </button>
              )}
              {isEditable && (
                <button
                  className=" primary-btn mr-3"
                  onClick={() => {
                    createSuite('create');
                    setSuiteObj({});
                  }}
                >
                  + Suite
                </button>
              )}
            </div>
          </div>

          <div className="table-height-suite overflow-auto" id="journal-scroll">
            {isLoading ? (
              <CommonLoader />
            ) : (
              <>
                <Table columns={columns} data={suitesData} />
                {isEmptyValue(suites) ? (
                  <div>
                    {isEditable && (
                      <div className="no-steps-module">
                        <div>
                          <div className="qucBtn">
                            <label className=" quick-start-style">Quick Start </label>
                            <br></br>
                          </div>
                          <label className="quick-start-label-style">
                            Step1 : <span className="quick-start-label-style">click</span>{' '}
                          </label>
                          <button
                            className=" primary-btn mr-2 ml-1"
                            onClick={() => {
                              createSuite('create');
                              setSuiteObj({});
                            }}
                          >
                            + Suite
                          </button>
                          <label className="quick-start-label-style">to start adding suites</label>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    {(openSearch || isFilterApplied) && isEmptyValue(suitesData) && (
                      <NoResultsFound className="mt-14" />
                    )}
                  </>
                )}
              </>
            )}

            {/* modal for machine details */}
            {openMachineDetailsModal ? (
              <MachineDetails
              runData={runData}
                data={machineDetails}
                closeModal={(value) => {
                  setOpenMachineDetailsModal(value);
                  setMachineDetails({});
                }}
              />
            ) : null}
            {showLoaderModal && <LoaderModal />}
          </div>
        </div>
      ) : (
        <div className="content_area_style_execution">
          {tab ? (
            <CreateEditSuite
              suiteObj={suiteObj}
              romoveRerun={romoveRerun}
              rerunMode={rerun}
              operation={operation}
              executionType={suiteObj?.exeType}
              getAllSuite={getAllSuite}
              createAlertFun={createAlertFun}
              clearFun={clearFun}
              suiteCount={suiteCount}
              numberOfParallelRuns={numberOfParallelRuns}
              runSettingsObject={runSettingsObject}
              privilegeObject={privilegeObject}
              runSettingsManualObject={runSettingsManualObject}
              setRunSettingsManualObject={setRunSettingsManualObject}
              setRunSettingsObject={setRunSettingsObject}
            />
          ) : null}
        </div>
      )}
      {showDeviceModal ? (
        <RunSettingsModal
          closeDeviceModal={closeDeviceModal}
          suiteType={selectedSuiteObjForExecution?.suiteType ? selectedSuiteObjForExecution.suiteType : 'Automation'}
          createAlertFun={createAlertFun}
          numberOfParallelRuns={numberOfParallelRuns}
          suiteObjMain={suiteObj}
          selectedSuiteObjForExecution={selectedSuiteObjForExecution}
          timeZoneObject={timeZoneObject}
          runSettingsObject={runSettingsObject}
          modalOperation={modalOperation}
          timezonedata={timezonedata[0]}
          runSettingsManualObject={runSettingsManualObject}
          operationForRunSettings={operationForRunSettings}
        />
      ) : null}
    </div>
  );
};

export default Suites;

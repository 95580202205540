import React from 'react';

function RightArrowSvgIcon() {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="6.585" height="11.175" viewBox="0 0 6.585 11.175">
        <path
          id="keyboard_arrow_right_black_48dp"
          d="M9.29,15.88,13.17,12,9.29,8.12A1,1,0,0,1,10.7,6.71l4.59,4.59a1,1,0,0,1,0,1.41L10.7,17.3a1,1,0,0,1-1.41,0,1.017,1.017,0,0,1,0-1.42Z"
          transform="translate(-8.997 -6.418)"
          fill="#434db8"
        />
      </svg>
    </div>
  );
}

export default RightArrowSvgIcon;

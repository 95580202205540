import { memo, useCallback, useEffect, useState } from 'react';
import { getProjectsSectionChartData } from '@api/api_services';

import { getLicenseFeatures } from '@src/util/common_utils';
import { projectTypesChartDataLabelColors, LICENSE_FEATURES } from '@common/ui-constants';

import ProjectStatus from '../charts/ProjectStatuses';
import SmallDonutChart from '../charts/SmallDonutChart';
import SectionHeader from '../components/SectionHeader';
import colors from '../../../css_config/variable.module.scss';

const initialProjectStatusesChartData = {
  series: [0, 0, 0],
  tooltipData: [null, null, null],
};

function Projects() {
  const [projectTypesChartData, setProjectTypesChartData] = useState({
    series: [],
    labels: [],
    colors: [],
  });
  const [projectStatusesChartData, setProjectStatusesChartData] = useState(initialProjectStatusesChartData);

  const licenseFeatures = getLicenseFeatures();
  const hasDefects = window.privilege.hasDefects(licenseFeatures);
  const hasAutomation = window.privilege.hasAutomation(licenseFeatures);
  const hasManualTestCase = window.privilege.hasManualTestCase(licenseFeatures);

  const { MANUAL_TESTCASES, DEFECTS, ALL, WEB_SERVICE, WEB, MOBILE, SALESFORCE, MS_DYNAMICS } = LICENSE_FEATURES;

  let purchasedProjectsType = licenseFeatures?.filter((type) => ![MANUAL_TESTCASES, DEFECTS].includes(type));
  const isOnlyAutomation = hasAutomation && !hasDefects && !hasManualTestCase;

  const getProjectTypesSeries = useCallback((data) => {
    const { webCount, mobileCount, webAndMobileCount, webserviceCount, salesforceCount, msDynamicsCount } = data;

    const projectTypesSeries = [
      webserviceCount,
      webCount,
      mobileCount,
      webAndMobileCount,
      salesforceCount,
      msDynamicsCount,
    ];

    return projectTypesSeries;
  }, []);

  let getProjectTypeOnlyOnAutomationPurchase = useCallback(
    (countOf) => {
      let series = [];
      let labels = [];
      let colorCode = [];
      const {
        webserviceCount = 0,
        webCount = 0,
        mobileCount = 0,
        webAndMobileCount = 0,
        salesforceCount = 0,
        msDynamicsCount = 0,
      } = countOf;

      if (purchasedProjectsType.includes(ALL)) {
        // NOTE :: If PRIVILEGE C-BASIC
        const cBasicProjectTypesSeries = getProjectTypesSeries(countOf);
        setProjectTypesChartData({
          series: cBasicProjectTypesSeries,
          ...projectTypesChartDataLabelColors,
        });
      } else {
        if (purchasedProjectsType.includes(WEB_SERVICE)) {
          series.push(webserviceCount);
          labels.push('Web Service');
          colorCode.push(colors.red_dark);
        }

        if (purchasedProjectsType.includes(WEB)) {
          series.push(webCount);
          labels.push(WEB);
          colorCode.push(colors.grey_dark);
        }

        if (purchasedProjectsType.includes(MOBILE)) {
          series.push(mobileCount);
          labels.push(MOBILE);
          colorCode.push(colors.yellow_dark);
        }

        if (purchasedProjectsType.includes(WEB) && purchasedProjectsType.includes(MOBILE)) {
          series.push(webAndMobileCount);
          labels.push('Web & Mobile');
          colorCode.push(colors.blue_bright);
        }

        if (purchasedProjectsType.includes(SALESFORCE)) {
          series.push(salesforceCount);
          labels.push(SALESFORCE);
          colorCode.push(colors.blue_saleforce);
        }

        if (purchasedProjectsType.includes(MS_DYNAMICS)) {
          series.push(msDynamicsCount);
          labels.push('MS Dynamics');
          colorCode.push(colors.cyan);
        }

        setProjectTypesChartData({
          series,
          labels,
          colors: colorCode,
        });
      }
    },
    [purchasedProjectsType]
  );

  useEffect(() => {
    getProjectsSectionChartData().then((response) => {
      const data = response?.data?.responseObject;
      if (data?.projectType) {
        if (isOnlyAutomation) {
          getProjectTypeOnlyOnAutomationPurchase(data.projectType);
        } else {
          const projectTypesSeries = getProjectTypesSeries(data.projectType);

          setProjectTypesChartData({
            series: projectTypesSeries,
            ...projectTypesChartDataLabelColors,
          });
        }
      }

      if (data?.projectStatus) {
        const { active, archive, closed } = data.projectStatus;
        const projectStatusSeries = [active, closed, archive];

        const { openProjectType, archiveProjectType, closedProjectType } = data;
        setProjectStatusesChartData({
          ...projectStatusesChartData,
          series: projectStatusSeries,
          tooltipData: [openProjectType, closedProjectType, archiveProjectType],
        });
      }
    });
  }, []);

  const { series, labels, colors: projectTypeColors } = projectTypesChartData;
  const { series: projectStatusSeries, tooltipData: projectStatustooltipData } = projectStatusesChartData;
  return (
    <div>
      <SectionHeader className="select-none fontPoppinsSemiboldLg">Projects</SectionHeader>
      <div className="grid grid-cols-2 gap-1 my-2">
        <SmallDonutChart
          series={series}
          labels={labels}
          colors={projectTypeColors}
          title="Types"
          totalLabel="All Projects"
        />
        <ProjectStatus series={projectStatusSeries} tooltipData={projectStatustooltipData} />
      </div>
    </div>
  );
}

export default memo(Projects);

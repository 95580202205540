import React, { useEffect, useState } from 'react';
import QrsMachineDetails from './qrs-machine-details';
import MachineTable from '@src/pages/common/MachineTable/MachineTable';
import './qrsStyles.scss';
import SearchBar from '@src/pages/analytics/common/search-bar';
import { isEmptyValue, isValueIncludes } from '@util/common_utils';
import NoResultsFound from '@src/pages/common/Table/NoResultFound';
import { FiDownload } from 'react-icons/fi';
import CommonButton from '@src/common/button/Button';

const SystemBaseUI = (props) => {
  const instanceList = {
    browserInstanceArray: props.browserInstanceArray,
    sauceLabInstanceArray: props.sauceLabInstanceArray,
    lambdaTestsInstanceArray: props.lambdaTestsInstanceArray,
  };
  const findSelectedSystem = () => {
    const tempMachine = props.systems?.find((_machine) => props.defaultMachine?.clientId === _machine.clientId);
    if (tempMachine) {
      return tempMachine;
    } else {
      return props.systems[0];
    }
  };
  const [selectedSystem, setSelectedSystem] = useState(findSelectedSystem());
  const [updatedSelectedMachine, setUpdatedSelectedMachine] = useState();
  const [changeDefaultMachine, setChangeDefaultMachine] = useState(false);
  const [webOsDataValueSystemBase, setWebOsDataValueSystemBase] = useState();
  const [androidDeviceValueSystemBase, setAndroidDeviceDataSystemBase] = useState();
  const [iosDeviceValueSystemBase, setIosDeviceValueSystemBase] = useState();
  const [androidDeviceAppSystemBase, setAndroidDeviceAppSystemBase] = useState();
  const [iosDeviceAppSystemBase, setIosDeviceAppSystemBase] = useState();
  const defaultMachineEnvOption = props?.defaultMachine?.executionEnvironment;

  const handleChangeDefaultMachine = () => {
    setTimeout(() => {
      setChangeDefaultMachine(true);
    }, 200);
  };

  const getUpdatedSelectedMachine = (updatedSystem) => {
    setUpdatedSelectedMachine(updatedSystem);
  };

  const handleSelectedSystem = (system) => {
    handleChangeDefaultMachine();
    if (system.clientId !== selectedSystem.clientId) {
      setSelectedSystem(system);
    }
  };

  const errorReturn = () => {
    const projectType = props?.project?.type;
    const runButtonClicked = props?.runButtonClicked;
    if (
      ((defaultMachineEnvOption !== 'Local' && ['Web'].includes(projectType)) ||
        ['Web & Mobile', 'Mobile'].includes(projectType)) &&
      ((runButtonClicked === true && webOsDataValueSystemBase?.selectedOption?.length === 0) ||
        (runButtonClicked === true &&
          (androidDeviceValueSystemBase?.selectedOption?.length === 0 ||
            androidDeviceAppSystemBase?.selectedOption?.length === 0)) ||
        (runButtonClicked === true &&
          (iosDeviceValueSystemBase?.selectedOption?.length === 0 ||
            iosDeviceAppSystemBase?.selectedOption?.length === 0)))
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (updatedSelectedMachine?.clientId === props?.defaultMachine?.clientId) {
      props.handleSelectedMachine(updatedSelectedMachine);
    } else if (changeDefaultMachine) {
      props.handleSelectedMachine(updatedSelectedMachine);
      setChangeDefaultMachine(false);
    }
  }, [updatedSelectedMachine, changeDefaultMachine]);

  // TODO :: handle Search filter :: START

  let [openSearch, setOpenSearch] = useState(false);
  const [userSearched, setuserSearched] = useState('');
  const [data, setData] = useState(props.systems || []);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');
  let [isHighlight, setIsHighlight] = useState(false);

  useEffect(() => {
    setData(props.systems);
    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [props.systems]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(props.systems);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setData(props.systems);
    setIsHighlight(!isHighlight);
    setSearchTextHighlight('');
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  let handleSearchSubmit = (e) => {
    e.preventDefault();
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = props.systems.filter(({ machine }) => {
          return isValueIncludes(e, machine?.hostName);
        });
        setData(newData);
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
      e.stopPropagation();
    }
  };

  // TODO :: handle Search filter :: END

  return (
    <>
      <div className="flex justify-between  items-center px-4 py-2">
        <div className="fontPoppinsMediumSm ">Execution Environment</div>
        <div className="flex items-center gap-2">
          <SearchBar
            open={openSearch}
            searchValue={userSearched}
            handleSearch={handleSearch}
            onSearchClose={handleSearchClose}
            handleSearchOpen={handleSearchOpen}
            enterKeyHandler={handleSearchSubmit}
            disable={isEmptyValue(props.systems)}
          />
          <CommonButton
            btnType="primary"
            label="Download Client"
            startIcon={<FiDownload className="inline mb-1 mr-1 w-3 h-3" />}
            onClick={() => {
              props.setDownloadClientModal(true);
            }}
          />
        </div>
      </div>
      {!isEmptyValue(data) && (
        <div className="flex justify-between gap-2">
          <div className="w-2/5 pl-4 py-4">
            <MachineTable
              inputType={'radio'}
              systems={data}
              selectedSystems={[props.defaultMachine]}
              onRowClickHandler={handleSelectedSystem}
              classes={'qrs-details-div-data-height'}
              currentVisibleSystem={selectedSystem}
              // errors = {[errorReturn() ? props?.defaultMachine?.clientId : ""]}
              isHighlight={isHighlight}
              searchTextHighlight={searchTextHighlight}
            />
          </div>
          <div className="w-3/5">
            <QrsMachineDetails
              selectedSystem={selectedSystem}
              instanceList={instanceList}
              defaultSystem={props.defaultMachine}
              getUpdatedSelectedMachine={getUpdatedSelectedMachine}
              project={props.project}
              disableHeadless={props.disableHeadless}
              androidDataPassToParent={props.androidDataPassToParent}
              iosDataPassToParent={props.iosDataPassToParent}
              webOSDataPassToParent={props.webOSDataPassToParent}
              runButtonClicked={props.runButtonClicked}
              webOsSystemParent={setWebOsDataValueSystemBase}
              andriodSystemParent={setAndroidDeviceDataSystemBase}
              iosSystemParent={setIosDeviceValueSystemBase}
              setRunButtonClickedAction={props.setRunButtonClickedAction}
              andriodDeviceAppSystemBase={setAndroidDeviceAppSystemBase}
              iosDeviceAppSystemBase={setIosDeviceAppSystemBase}
              runButtonDisabled={props.runButtonDisabled}
            />
          </div>
        </div>
      )}
      <div className="m-10">{isEmptyValue(data) && searchTextHighlight && <NoResultsFound />}</div>
    </>
  );
};

export default SystemBaseUI;

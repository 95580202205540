import React, { useMemo, useEffect, useState } from 'react';
import { Drawer, Box, OutlinedInput, IconButton } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '@assets/analytics/ShrinkIconWhite.svg';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { BITBUCKET_CONSTANTS } from '../plugin_constants';
import Verification from './verificationDrawer';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import { Tooltip } from '@material-ui/core';
import {
  validateBitbucket,
  isInstanceExist,
  getWorkSpaces,
  getProjects,
  isRepositoryExist,
  getProjectListDropdownReq,
  getAllSuiteReq,
  updateBitBucket,
  validateRepository,
} from '@api/api_services';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CommonDrawerJs from '../CommonIntegrationDrawer';
import { ReactComponent as Information } from '@assets/svg-imports/info icon.svg';
import Typography from '@mui/material/Typography';
import { colors } from '@src/css_config/colorConstants';
import { makeStyles } from '@material-ui/core/styles';
import style from '@pages/common/Inputs/inputs.module.scss';
import cx from 'classnames';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getEncryptData } from '@common/security';

const CreateEditInstance = (props) => {
  const [openVerification, setOpenVerification] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [projects, setProjects] = React.useState([]);
  const [projectFilterArray, setProjectFilterArray] = React.useState([]);
  const [scriptFilterArray, setScriptFilterArray] = React.useState([]);
  const [workSpaceFilterArray, setWorkSpaceFilterArray] = useState([]);
  const [projectArray, setProjectArray] = useState([]);
  const [projectNames, setProjectNames] = useState([]);
  const [projectId, setProjectId] = useState('');
  const [projectType, setProjectType] = useState(props.actionType === 'Edit' ? props.seletedInstance.projectType : '');
  const [suiteId, setSuiteId] = useState('');
  const [scriptName, setScriptName] = useState([]);
  const [project, setProject] = useState([]);
  const [iSvalidBitBucket, setIsValidBitBucket] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [workSpaceUUID, setWorkSpaceUUID] = useState('');
  const [projectUUID, setProjectUUID] = useState('');
  const biBucketUserName = props.seletedInstance.bitBucketUserName;
  const [workSpaceName, setWorkSpaceName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [fireflinkProjectName, setFireFlinkProjectName] = useState('');
  const [suiteName, setSuiteName] = useState('');
  const [showError, setShowError] = useState(false);
  const [instanceError, setInstanceError] = useState(false);
  const [repositoryError, setRepositoryError] = useState(false);
  const [invalidScope, setInvalidScope] = useState(false);
  const [repositoryFound, setRepositoryFound] = useState(false);
  const [matchingData, setMatchingData] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const initialValues = {
    username: props.actionType === 'Edit' ? props?.seletedInstance?.bitBucketUserName : '',
    userPasswordField: '',
    suiteName: props.actionType === 'Edit' ? props?.seletedInstance?.suiteName : '',
    Project: props.actionType === 'Edit' ? props?.seletedInstance?.projectName : '',
    instanceName: props?.actionType === 'Edit' ? props?.seletedInstance?.instanceName : '',
    workspace: props.actionType === 'Edit' ? props?.seletedInstance?.bitBucketWorkspaceName : '',
    repositoryName: props.actionType === 'Edit' ? props.seletedInstance.bitBucketRepositoryName : '',
    fireFlinkProject: props.actionType === 'Edit' ? props.seletedInstance.fireFlinkProjectName : '',
  };
  const validationSchema = yup.object({
    username: yup.string().required('Username  is required'),
    userPasswordField: yup.string().required('Password  is required'),
    instanceName: yup
      .string()
      .matches(/^[A-Za-z0-9 _]*$/, 'Name can not have special characters')
      .min(3, 'Name should contain at least 3 characters')
      .max(25, 'Name can contain at most 25 characters')
      .matches(/^[^\s].*[^\s]$/, 'Space is not allowed in the beginning and at the end')
      .required('Instance Name is required'),
    repositoryName: yup
      .string()
      .min(3, 'Name should contain atleast 3 characters')
      .max(25, 'Name can contain atmost 25 characters')
      .required('Repository Name  is required')
      .matches(/^\S*$/, 'Space is not allowed'),
    workspace: yup.string().required('Workspace  is required'),
    Project: yup.string().required('Project  is required'),
    fireFlinkProject: yup.string().required('FireFlink Project is required'),
    suiteName: yup.string().required('Suite  is required'),
  });

  const handleOpenVerification = () => {
    const requiredFields = ['instanceName', 'workspace', 'Project', 'repositoryName', 'fireFlinkProject', 'suiteName'];

    const allFieldsValid = requiredFields.every((field) => {
      const value = formikDetails.values[field].trim();
      if (value === '') {
        formikDetails.setFieldError(field, `${field} is required`);
        return false;
      }
      return true;
    });

    if (allFieldsValid && !instanceError && !repositoryError) {
      setOpenVerification(true);
    }
  };

  const handleCloseVerification = () => {
    setOpenVerification(false);
  };
  const formikDetails = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleOpenVerification,
  });
  const validInstance =
    formikDetails?.values?.instanceName?.length >= 3 && formikDetails?.values?.instanceName.length <= 25 && true;
  const iSsuiteExist = formikDetails?.values?.suiteName?.length > 0 && true;
  const getSelectedProjectName = (projectName) => {
    let project = projectFilterArray.filter((e) => e.name === projectName);
    setProjectId(project[0].id);
    setProjectType(project[0].type);
    setProjectNames(project);
    project
      ? localStorage.setItem('selected-Git-project', JSON.stringify(project[0]))
      : localStorage.setItem('selected-Git-project', JSON.stringify({ id: '', name: 'All Projects' }));
    getAllSuiteReq().then((results) => {
      let _suiteType = results?.data?.responseObject.filter((suiteType) => suiteType?.suiteType === 'Automation');
      setSuiteId(_suiteType[0]?.id);
      setScriptFilterArray(_suiteType ? _suiteType : []);
    });
  };

  const getSelectedWorkSpace = (projectName) => {
    let selectedDta = workSpaceFilterArray.filter((e) => e.name === projectName && e.uuid);
    setWorkSpaceUUID(selectedDta[0].uuid);
    setWorkSpaceName(selectedDta[0].name);
    let selectedDtaPayLoad = {
      bitBucketUserName: props.actionType === 'Edit' ? biBucketUserName : formikDetails.values.username,
      bitBucketAppPassword: getEncryptData(formikDetails.values.userPasswordField),
      bitBucketWorkspaceUUID: selectedDta[0].uuid,
    };
    if (showDetails) {
      getProjects(selectedDtaPayLoad).then((response) => {
        if (response?.data?.responseCode === 200 && response?.data.message === 'SUCCESS') {
          setProjectArray(response?.data?.responseObject?.values);
        }
      });
    }
  };
  const isValidBitbucket = () => {
    const payLoad = {
      bitBucketUserName: props.actionType === 'Edit' ? biBucketUserName : formikDetails.values.username,
      bitBucketAppPassword: getEncryptData(formikDetails.values.userPasswordField),
    };

    if (payLoad.bitBucketUserName.trim() === '' || payLoad.bitBucketAppPassword.trim() === '') {
      formikDetails.setFieldError('username', payLoad.bitBucketUserName.trim() === '' ? 'Username is required' : '');
      formikDetails.setFieldError(
        'userPasswordField',
        payLoad.bitBucketAppPassword.trim() === '' ? 'Password is required' : ''
      );
    } else {
      validateBitbucket(payLoad)
        .then((response) => {
          if (response?.data?.responseCode === 200 && response?.data.message === 'SUCCESS') {
            setShowDetails(true);
          } else if (
            response?.data?.responseCode === 400 &&
            response?.data?.status === 'Invalid BitBucket credentials'
          ) {
            setShowDetails(false);
            setShowError(true);
            setInvalidScope(false);
            formikDetails.setFieldError('username', BITBUCKET_CONSTANTS.INVALID_USERNAME_APP_PASSWORD);
            formikDetails.setFieldError('userPasswordField', BITBUCKET_CONSTANTS.INVALID_USERNAME_APP_PASSWORD);
          } else if (response?.data?.responseCode === 400 && response?.data?.status === 'Invalid scopes') {
            setInvalidScope(true);
            setShowError(false);
            formikDetails.setFieldError('username', BITBUCKET_CONSTANTS.INVALID_ACCESS);
            formikDetails.setFieldError('userPasswordField', BITBUCKET_CONSTANTS.INVALID_ACCESS);
          }
        })
        .catch((error) => {
          console.error('API error:', error);
        });
    }
  };

  useEffect(() => {
    if (showDetails) {
      getProjectListDropdownReq()
        .then((results) => {
          const responseProjects = results.data.responseObject;
          setProjects(responseProjects);
          setProjectFilterArray(responseProjects);
        })
        .catch((error) => {
          console.error('failed due to api response', error);
        });
    }
    if (showDetails) {
      let payLoad = {
        bitBucketUserName: props.actionType === 'Edit' ? biBucketUserName : formikDetails.values.username,
        bitBucketAppPassword: getEncryptData(formikDetails.values.userPasswordField),
      };
      getWorkSpaces(payLoad)
        .then((response) => {
          if (response?.data?.responseCode === 200 && response?.data.message === 'SUCCESS') {
            setWorkSpaceFilterArray(response?.data?.responseObject?.values);
          }
        })
        .catch((error) => {
          console.error('API error:', error);
        });
    }
    if (props.actionType === 'Edit' && showDetails) {
      localStorage.setItem(
        'selected-Git-project',
        JSON.stringify({ id: props?.seletedInstance?.projectId, name: props?.seletedInstance?.projectName })
      );
      getAllSuiteReq()
        .then((results) => {
          let _suiteType = results?.data?.responseObject.filter((suiteType) => suiteType.suiteType === 'Automation');
          setScriptFilterArray(_suiteType ? _suiteType : []);
        })
        .catch((error) => {
          console.error('API error:', error);
        });
      const repositoryData = props.seletedInstance;
      repositoryData.bitBucketAppPassword = getEncryptData(props.seletedInstance?.bitBucketAppPassword);
      repositoryData.fireFlinkPassword = getEncryptData(props.seletedInstance?.fireFlinkPassword);
      validateRepository(repositoryData)
        .then((response) => {
          if (response?.data?.responseCode === 200) {
            setRepositoryFound(false);
          }
          if (response?.data?.responseCode === 404) {
            setRepositoryFound(true);
          }
        })
        .catch((error) => {
          console.error('API error:', error);
        });
    }
    return () => {
      localStorage.removeItem('selected-Git-project');
    };
  }, [showDetails]);

  const handleSheetChange = (value) => {
    if (value) {
      getSelectedProjectName(value);
    }
    formikDetails.setFieldValue('projectName', value, true);
    formikDetails.setFieldValue('suiteName', '', true);
    setFireFlinkProjectName(value);
  };
  const handleSuiteChange = (value) => {
    if (value) {
      setScriptName(scriptFilterArray.filter((e) => e.name === value));
      scriptFilterArray.map((suite) => {
        if (suite?.name === value) {
          setSuiteId(suite?.id);
        }
      });
    }
    formikDetails.setFieldValue('suiteName', value, true);
    setSuiteName(value);
  };

  const handleWorkSpaceChange = (value) => {
    if (value) {
      getSelectedWorkSpace(value);
    }
    formikDetails.setFieldValue('workspace', value, true);
    formikDetails.setFieldValue('Project', '', true);
  };

  const handleProjectChange = (value) => {
    formikDetails.setFieldValue('Project', value, true);
    const project = projectArray.filter((e) => e.name === value);
    setProjectUUID(project[0].uuid);
    setProjectName(project[0].name);
  };

  useEffect(() => {
    const fetchInstanceData = async () => {
      const instanceName = formikDetails?.values?.instanceName;

      if (showDetails && instanceName?.trim().length >= 3 && instanceName.trim().length <= 25) {
        try {
          const response = await isInstanceExist({ instanceName });
          if (response?.data?.responseCode === 200) {
            setInstanceError(false);
          }
          if (response?.data?.responseCode === 400 && response?.data?.responseObject === null) {
            setInstanceError(true);
          }
        } catch (error) {
          console.error('API error:', error);
        }
      }
    };

    fetchInstanceData();
  }, [formikDetails.values.instanceName, instanceError]);

  useEffect(() => {
    const fetchRepositoryData = async () => {
      const repositoryName = formikDetails.values.repositoryName;
      let repositoryData = {
        bitBucketUserName: props.actionType === 'Edit' ? biBucketUserName : formikDetails.values.username,
        bitBucketAppPassword: getEncryptData(formikDetails.values.userPasswordField),
        bitBucketWorkspaceUUID: workSpaceUUID,
        bitBucketRepositoryName: formikDetails.values.repositoryName,
      };
      if (showDetails && repositoryName.length >= 3 && repositoryName.length <= 25) {
        try {
          const response = await isRepositoryExist(repositoryData);
          if (response?.data?.responseCode === 200) {
            setRepositoryError(false);
          }
          if (response?.data?.responseCode === 400) {
            setRepositoryError(true);
          }
        } catch (error) {
          console.error('API error:', error);
        }
      }
    };

    fetchRepositoryData();
  }, [formikDetails.values.repositoryName]);

  const isRepositoryNameEmpty = props.actionType === 'Edit' && formikDetails.values.repositoryName === '';

  const currentInstanceData = [
    {
      instanceName: formikDetails?.values?.instanceName,
      suiteName: formikDetails?.values?.suiteName,
      projectName: formikDetails?.values?.fireFlinkProject,
    },
  ];

  const exsitingInstanceData = [
    {
      instanceName: props?.seletedInstance?.instanceName,
      suiteName: props?.seletedInstance?.suiteName,
      projectName: props?.seletedInstance?.fireFlinkProjectName,
    },
  ];

  useEffect(() => {
    if (props.actionType === 'Edit' && showDetails) {
      if (JSON.stringify(currentInstanceData) === JSON.stringify(exsitingInstanceData)) {
        setMatchingData(true);
      } else {
        setMatchingData(false);
      }
    }
  }, [currentInstanceData, exsitingInstanceData]);

  const updateInstance = () => {
    const updatedData = {
      bitBucketUserName: biBucketUserName,
      bitBucketAppPassword: getEncryptData(formikDetails.values.userPasswordField),
      bitBucketWorkspaceUUID: props.seletedInstance.bitBucketWorkspaceUUID,
      instanceName: formikDetails.values.instanceName,
      projectType: projectType,
      suiteId: scriptName[0]?.id ? scriptName[0]?.id : props.seletedInstance.suiteId,
      projectType: projectType,
      suiteName: formikDetails.values.suiteName,
      fireFlinkProjectName: formikDetails.values.fireFlinkProject,
    };
    if (!instanceError && !isRepositoryNameEmpty) {
      updateBitBucket(updatedData, props?.seletedInstance?.id)
        .then((response) => {
          if (response?.data?.status === 'SUCCESS') {
            props.onClose();
            props.reloadTree();
            props.MyAlert.success(`${updatedData.instanceName} ${BITBUCKET_CONSTANTS.INSTANCE_UPDATED_SUCCESSFULLY}`);
          } else if (response.data.responseCode === 400 && response.data.status.includes('Server Busy')) {
            props.onClose();
            props.MyAlert.warning(response?.data?.message);
          }
        })
        .catch((error) => {
          console.error('API error:', error);
        });
    }
  };

  return (
    <CommonDrawerJs
      isDrawerOpen={true}
      headerContent={
        props.actionType === 'Edit'
          ? `${BITBUCKET_CONSTANTS.EDIT_INSTANCE} - ${props?.seletedInstance?.instanceName}`
          : BITBUCKET_CONSTANTS.CREATE_INSTANCE
      }
      drawerWidth="450px"
      rightButtonText={
        props.actionType === 'Edit'
          ? showDetails
            ? BITBUCKET_CONSTANTS.UPDATE
            : BITBUCKET_CONSTANTS.VERIFY
          : showDetails
          ? BITBUCKET_CONSTANTS.CREATE
          : BITBUCKET_CONSTANTS.VERIFY
      }
      leftButtonText="Cancel"
      isLeftButtonVisible={true}
      onDrawerOpen={props.onClose}
      onRightButtonClick={
        props.actionType === 'Edit' && showDetails
          ? updateInstance
          : showDetails
          ? formikDetails.handleSubmit
          : isValidBitbucket
      }
      onLeftButtonClick={props.onClose}
      disableRightButton={
        formikDetails.values.username.length === 0 ||
        formikDetails.values.userPasswordField.length === 0 ||
        (showDetails &&
          props.actionType === 'Edit' &&
          (matchingData || repositoryFound === true || !validInstance || !iSsuiteExist))
      }
    >
      <section className="w-full  flex flex-col justify-center items-center overflow-hidden">
        <div className="w-full flex flex-col mt-2 ml-8">
          <div className="fontPoppinsMediumSm integration-label mt-1 -mb-1">
            {
              <>
                {' '}
                <span className="text-red-600">*</span> {BITBUCKET_CONSTANTS.BUCKET_USERNAME}
              </>
            }{' '}
          </div>
          <div className="mt-2">
            <TextField
              className={`instance-input-field text-field-Style ${
                (showDetails || props.actionType === 'Edit') && 'disabled-field'
              }`}
              variant="outlined"
              name="username"
              autoComplete="off"
              placeholder={BITBUCKET_CONSTANTS.ENTER_BITBUCKET_USER_NAME}
              value={formikDetails.values.username}
              onChange={formikDetails.handleChange}
              onBlur={formikDetails.handleBlur}
              error={formikDetails.errors.username && formikDetails.touched.username}
              disabled={showDetails || props.actionType === 'Edit'}
            />

            {formikDetails.values.username.length === 0 && formikDetails.touched.username ? (
              <div className="error-message fontPoppinsRegularXs9px ml-1">{formikDetails.errors.username}</div>
            ) : null}
          </div>
        </div>
        <div className="w-full flex flex-col mt-2 ml-8">
          <div className="fontPoppinsMediumSm flex flex-row">
            <div className="integration-label mt-1 -mb-1">
              {
                <>
                  {' '}
                  <span className="text-red-600">*</span> {BITBUCKET_CONSTANTS.BUCKET_APP_PASSWORD}
                </>
              }
            </div>
            <div className="ml-1 mt-1">
              {!showDetails && (
                <Tooltip
                  title={
                    <div>
                      <Typography className="table-line info-text">{BITBUCKET_CONSTANTS.REQUIRED_ACCESS}</Typography>
                      <Typography className="info-text">{BITBUCKET_CONSTANTS.ACCOUNT_READ}</Typography>
                      <Typography className="info-text">{BITBUCKET_CONSTANTS.PROJECT_READ}</Typography>
                      <Typography className="info-text">{BITBUCKET_CONSTANTS.REPOSITORY_ADMIN}</Typography>
                      <Typography className="info-text">{BITBUCKET_CONSTANTS.PIPELINE_EDIT_VARIABLE}</Typography>
                    </div>
                  }
                >
                  <span>
                    <Information className="info-icon-span" style={{ marginTop: '0.25rem' }} />
                  </span>
                </Tooltip>
              )}
            </div>
          </div>
          <div className="mt-2">
            <TextField
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                      {!showDetails &&
                        (showPassword ? (
                          <VisibilityOutlinedIcon className="mt-0 text-[#727171]" />
                        ) : (
                          <VisibilityOffOutlinedIcon className="mt-0 ml-2 text-[#727171]" />
                        ))}
                    </span>
                  </InputAdornment>
                ),
              }}
              autoComplete="new-password"
              className={`instance-input-field text-field-Style ${showDetails && 'disabled-field'}`}
              placeholder={BITBUCKET_CONSTANTS.ENTER_BITBUCKET_APP_PASSWORD}
              name="userPasswordField"
              value={formikDetails.values.password}
              onChange={formikDetails.handleChange}
              onBlur={formikDetails.handleBlur}
              error={formikDetails.errors.userPasswordField && formikDetails.touched.userPasswordField}
              disabled={showDetails}
            />
            {formikDetails.values.userPasswordField.length === 0 && formikDetails.touched.userPasswordField ? (
              <div className="error-message fontPoppinsRegularXs9px mr-80 ml-1">
                {formikDetails.errors.userPasswordField}
              </div>
            ) : null}
            {formikDetails.values.userPasswordField.length > 0 && formikDetails.errors.userPasswordField && (
              <>
                {showError &&
                  (props.actionType !== 'Edit' ? (
                    <div className="error-message fontPoppinsRegularXs9px mr-64">
                      {BITBUCKET_CONSTANTS.INVALID_USERNAME_APP_PASSWORD}
                    </div>
                  ) : (
                    <div className="error-message fontPoppinsRegularXs9px mr-72">
                      {BITBUCKET_CONSTANTS.INVALID_APP_PASSWORD}
                    </div>
                  ))}

                {invalidScope && (
                  <div className="error-message fontPoppinsRegularXs invalid-message">
                    {BITBUCKET_CONSTANTS.INVALID_ACCESS}
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {showDetails && (
          <>
            <div className="w-full flex flex-col mt-2 ml-8">
              <div className="integration-label fontPoppinsMediumSm mt-1 -mb-1">
                {
                  <>
                    {' '}
                    <span className="text-red-600">*</span> {BITBUCKET_CONSTANTS.INSTANCE_NAME}
                  </>
                }
              </div>
              <div className="mt-2">
                <TextField
                  className="instance-input-field ml-5"
                  variant="outlined"
                  name="instanceName"
                  style={{ width: '420px' }}
                  autoComplete="off"
                  placeholder={BITBUCKET_CONSTANTS.ENTER_INSTANCE_NAME}
                  onChange={formikDetails.handleChange}
                  error={(formikDetails.touched.instanceName && formikDetails.errors.instanceName) || instanceError}
                  onBlur={formikDetails.handleBlur}
                  value={formikDetails.values.instanceName}
                />
                {formikDetails.errors.instanceName && formikDetails.touched.instanceName ? (
                  <div className="error-message fontPoppinsRegularXs9px ml-1">{formikDetails.errors.instanceName}</div>
                ) : null}
                {instanceError ? (
                  <div className="error-exist fontPoppinsRegularXs9px ml-1">{BITBUCKET_CONSTANTS.INSTANCE_ERROR}</div>
                ) : null}
              </div>
            </div>
            <div className="w-full flex flex-col mt-2 ml-8">
              <div className="integration-label fontPoppinsMediumSm mt-1 -mb-1">
                {
                  <>
                    {' '}
                    <span className="text-red-600">*</span> {BITBUCKET_CONSTANTS.WORKSPACE}
                  </>
                }
              </div>
              <div className="mt-2">
                <FormControl sx={{ minWidth: 120 }}>
                  <Select
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    className={` select-field-Style ${props.actionType === 'Edit' && 'disabled-field'}`}
                    onChange={(event) => {
                      handleWorkSpaceChange(event.target.value);
                      formikDetails.setFieldValue('workspace', event.target.value);
                    }}
                    name="workspace"
                    value={formikDetails?.values?.workspace}
                    error={formikDetails.touched.workspace && formikDetails.errors.workspace}
                    disabled={props.actionType === 'Edit'}
                    renderValue={(selected) => {
                      if (!selected)
                        return (
                          <span className="fontPoppinsRegularSm" style={{ opacity: '0.5' }}>
                            {BITBUCKET_CONSTANTS.SELECT_WORKSPACE}
                          </span>
                        );
                      else return <span className="fontPoppinsRegularSm">{selected}</span>;
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: '80px',
                          marginLeft: '3px',
                        },
                      },
                      classes: {
                        root: cx(style['select-box-menu-list-wrapper']),
                      },
                    }}
                  >
                    {workSpaceFilterArray?.map((option, optionIndex) => (
                      <MenuItem value={option?.name} key={option.name} primaryText="MenuItem">
                        <span> {option?.name}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {formikDetails.errors.workspace && formikDetails.touched.workspace ? (
                  <div className="error-message fontPoppinsRegularXs9px ml-1">{formikDetails.errors.workspace}</div>
                ) : null}
              </div>
            </div>
            <div className="w-full flex flex-col mt-2 ml-8">
              <div className="integration-label fontPoppinsMediumSm mt-1 -mb-1">
                {
                  <>
                    {' '}
                    <span className="text-red-600">*</span> {BITBUCKET_CONSTANTS.PROJECT}
                  </>
                }
              </div>
              <div className="mt-2">
                <FormControl sx={{ minWidth: 120 }}>
                  <Select
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    className={`select-field-Style ${props.actionType === 'Edit' && 'disabled-field'}`}
                    name="Project"
                    value={formikDetails.values.Project}
                    disabled={props.actionType === 'Edit'}
                    error={formikDetails.touched.Project && formikDetails.errors.Project}
                    renderValue={(selected) => {
                      if (!selected)
                        return (
                          <span className="fontPoppinsRegularSm" style={{ opacity: '0.5' }}>
                            {BITBUCKET_CONSTANTS.SELECT_PROJECT}
                          </span>
                        );
                      else return <span className="fontPoppinsRegularSm">{selected}</span>;
                    }}
                    onChange={(event) => {
                      handleProjectChange(event.target.value);
                      formikDetails.setFieldValue('Project', event.target.value);
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: '180px',
                          minHeight: '50px',
                          marginLeft: '3px',
                        },
                      },
                      classes: {
                        root: cx(style['select-box-menu-list-wrapper']),
                      },
                    }}
                  >
                    {projectArray?.map((option, optionIndex) => (
                      <MenuItem value={option?.name} key={option.name}>
                        <span> {option?.name}</span>
                      </MenuItem>
                    ))}
                    {projectArray?.length === 0 ? <MenuItem>{BITBUCKET_CONSTANTS.NO_OPTIONS}</MenuItem> : null}
                  </Select>
                </FormControl>
                {formikDetails.errors.Project && formikDetails.touched.Project ? (
                  <div className="error-message fontPoppinsRegularXs9px ml-1">{formikDetails.errors.Project}</div>
                ) : null}
              </div>
            </div>
            <div className="w-full flex flex-col mt-2 ml-8">
              <div className="integration-label fontPoppinsMediumSm mt-1 -mb-1">
                {
                  <>
                    {' '}
                    <span className="text-red-600">*</span> {BITBUCKET_CONSTANTS.REPOSITORY_NAME}
                  </>
                }
              </div>
              <div className="mt-2">
                <TextField
                  label=""
                  variant="outlined"
                  name="repositoryName"
                  className={`instance-input-field ml-5 text-field-Style ${
                    props.actionType === 'Edit' && !repositoryFound && 'disabled-field'
                  } ${repositoryFound && 'disabled-error'}`}
                  autoComplete="off"
                  placeholder={BITBUCKET_CONSTANTS.ENTER_REPOSITORY_NAME}
                  onChange={formikDetails.handleChange}
                  error={
                    (formikDetails.errors.repositoryName && formikDetails.touched.repositoryName) ||
                    repositoryError ||
                    isRepositoryNameEmpty ||
                    repositoryFound
                  }
                  onBlur={formikDetails.handleBlur}
                  value={formikDetails.values.repositoryName}
                  disabled={props.actionType === 'Edit'}
                />
                {formikDetails.errors.repositoryName && formikDetails.touched.repositoryName ? (
                  <div className="error-message fontPoppinsRegularXs9px ml-1">
                    {formikDetails.errors.repositoryName}
                  </div>
                ) : null}
                {repositoryError ? (
                  <div className="error-exist fontPoppinsRegularXs9px ml-1">{BITBUCKET_CONSTANTS.REPOSITORY_ERROR}</div>
                ) : null}
                {repositoryFound ? (
                  <div className="error-message fontPoppinsRegularXs9px field-wrap ml-1">
                    {BITBUCKET_CONSTANTS.REPOSITORY_NOT_FOUND}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="w-full flex flex-col mt-2 ml-8">
              <div className="integration-label fontPoppinsMediumSm mt-1 -mb-1">
                {
                  <>
                    {' '}
                    <span className="text-red-600">*</span> {BITBUCKET_CONSTANTS.FIRE_FLINK_PROJECT}
                  </>
                }
              </div>
              <div className="mt-2">
                <FormControl sx={{ minWidth: 120 }}>
                  <Select
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    className="select-field-Style"
                    name="fireFlinkProject"
                    onChange={(event) => {
                      handleSheetChange(event.target.value);
                      formikDetails.setFieldValue('fireFlinkProject', event.target.value);
                    }}
                    value={formikDetails.values.fireFlinkProject}
                    error={formikDetails.touched.fireFlinkProject && formikDetails.errors.fireFlinkProject}
                    renderValue={(selected) => {
                      if (!selected)
                        return (
                          <span className="fontPoppinsRegularSm" style={{ opacity: '0.5' }}>
                            {BITBUCKET_CONSTANTS.SELECT_FIREFLINK_PROJECT}
                          </span>
                        );
                      else return <span className="fontPoppinsRegularSm">{selected}</span>;
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: '180px',
                          minHeight: '50px',
                          marginLeft: '3px',
                        },
                      },
                      classes: {
                        root: cx(style['select-box-menu-list-wrapper']),
                      },
                    }}
                  >
                    {(projectFilterArray.every((option) => option.status === 'Closed') ||
                      !projectFilterArray.length) && <MenuItem>{BITBUCKET_CONSTANTS.NO_OPTIONS}</MenuItem>}
                    {projectFilterArray?.map((option, optionIndex) => {
                      if (option.status !== 'Closed') {
                        return (
                          <MenuItem value={option.name} key={option.name}>
                            <span>{option.name}</span>
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
                {formikDetails.errors.fireFlinkProject && formikDetails.touched.fireFlinkProject ? (
                  <div className="error-message fontPoppinsRegularXs9px ml-1">
                    {formikDetails.errors.fireFlinkProject}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="w-full flex flex-col mt-2 ml-8">
              <div className="integration-label fontPoppinsMediumSm mt-1 -mb-1">
                {
                  <>
                    {' '}
                    <span className="text-red-600">*</span> {BITBUCKET_CONSTANTS.SUITE_NAME}
                  </>
                }
              </div>
              <div className="mt-2">
                <FormControl sx={{ minWidth: 120 }}>
                  <Select
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    className="select-field-Style"
                    name="suiteName"
                    onChange={(event) => {
                      handleSuiteChange(event.target.value);
                      formikDetails.setFieldValue('suiteName', event.target.value);
                    }}
                    renderValue={(selected) => {
                      if (!selected)
                        return (
                          <span className="fontPoppinsRegularSm" style={{ opacity: '0.5' }}>
                            {BITBUCKET_CONSTANTS.SELECT_SUITE_NAME}
                          </span>
                        );
                      else return <span className="fontPoppinsRegularSm">{selected}</span>;
                    }}
                    value={formikDetails.values.suiteName}
                    error={formikDetails.touched.suiteName && formikDetails.errors.suiteName}
                    IconComponent={KeyboardArrowDownIcon}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          marginLeft: '3px',
                          minHeight: '50px',
                        },
                      },
                      classes: {
                        root: cx(style['select-box-menu-list-wrapper']),
                      },
                    }}
                  >
                    {scriptFilterArray
                      ?.filter((option) => option?.suiteType === 'Automation')
                      .map((option) => {
                        return (
                          <MenuItem value={option?.name} key={option.name}>
                            <span title={option?.name?.length > 25 ? option.name : ''}>
                              {option?.name?.length > 25 ? `${option?.name?.substring(0, 25)}...` : option?.name}
                            </span>
                          </MenuItem>
                        );
                      })}
                    {scriptFilterArray?.filter((option) => option?.suiteType === 'Automation').length === 0 ? (
                      <MenuItem>{BITBUCKET_CONSTANTS.NO_OPTIONS}</MenuItem>
                    ) : null}
                  </Select>
                </FormControl>
                {formikDetails.errors.suiteName && formikDetails.touched.suiteName ? (
                  <div className="error-message fontPoppinsRegularXs9px ml-1">{formikDetails.errors.suiteName}</div>
                ) : null}
              </div>
            </div>
          </>
        )}
      </section>
      {openVerification && (
        <Verification
          isOpen={openVerification}
          onClose={handleCloseVerification}
          verificationDetails={formikDetails.values}
          workSpaceUUID={workSpaceUUID}
          projectId={projectId}
          suiteId={suiteId}
          projectUUID={projectUUID}
          projectType={projectType}
          close={props.onClose}
          reloadTree={props.reloadTree}
          suiteName={suiteName}
          projectName={projectName}
          fireflinkProjectName={fireflinkProjectName}
          workSpaceName={workSpaceName}
          repositoryName={formikDetails.values.repositoryName}
          MyAlert={props.MyAlert}
        />
      )}
    </CommonDrawerJs>
  );
};

export default CreateEditInstance;

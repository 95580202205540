import React, { useState, useEffect, useRef } from 'react';
import * as yup from 'yup';
import { Field, Formik, useFormik } from 'formik';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, Select, TextField } from '@material-ui/core';
import {
  addBrowserStack,
  updateBrowserStack,
  updateSauceLab,
  validateInstance,
  createInstance,
} from '../../../../api/api_services';
import MyInput from '@src/pages/user_config/Inputs/MyInput';
import { useAlert } from '@src/pages/common/alert_service/useAlert';
import CloseIcon from '@material-ui/icons/Close';

function BrowserStackModal(props) {
  const userNameRef = useRef();
  const accessKeyRef = useRef();
  const { AlertContatiner, MyAlert } = useAlert();

  const [openModal, setOpenModal] = useState(true);

  const _dataCenter =
    props.type === 'sauceLab' && props.mode === 'EDIT'
      ? props?.data?.dataCenters.map((dtaCent) => ({ name: dtaCent, value: dtaCent }))
      : [];

  const [dataCentersOptions, setDataCentersOptions] = useState(_dataCenter);
  const [showDropdown, setShowDropdown] = useState(props.type === 'sauceLab' && props.mode === 'EDIT' ? true : false);

  const [createCalled, setCreateCalled] = useState(false);

  let initialValues;
  if (props.mode !== 'ADD') {
    initialValues = {
      instance: props.data.instanceName,
      username: props.data.username,
      accessKey: props.data.accessKey,
      defaultDataCenter: props?.data?.defaultDataCenter ? props?.data?.defaultDataCenter : '',
    };
  } else {
    initialValues = {
      instance: '',
      username: '',
      accessKey: '',
      defaultDataCenter: '',
    };
  }
  const validationSchema = yup.object({
    instance: yup
      .string()
      .required('Instance is required')
      .min(3, 'Minimum 3 characters')
      .max(25, 'Maximum 25 characters')
      .matches(/^[-_A-Za-z0-9 ]*$|^[-_A-Za-z0-9 ][A-Za-z0-9 ]*[-_A-Za-z-_]$/, 'Name should be alphanumeric')
      .matches(/^[-a-zA-Z0-9-()_]+(\s+[-a-zA-Z0-9-()_]+)*$/, 'Space is not allowed in the beginning and at the end'),

    username: yup.string().ensure().required('Username is required'),
    accessKey: yup.string().ensure().required('AccessKey is required'),
    defaultDataCenter:
      props.type === 'sauceLab' && props.mode !== 'ADD'
        ? yup.string().ensure().required('Select data center')
        : yup.string(),
  });

  const isInstanceNamePresent = (enteredValue) => {
    return props.instanceList.some((_instance) => _instance.instanceName.toLowerCase() === enteredValue.toLowerCase());
  };

  const onSubmit = (values) => {
    if (values && !createCalled) {
      setCreateCalled(true);

      if (props.mode === 'ADD' || props.mode === 'ADD_SUB') {
        if (props.type === 'sauceLab' && !formikDetails.values.defaultDataCenter) {
          if (!isInstanceNamePresent(values.instance)) {
            MyAlert.info('Please wait for Datacenters');
          }
          if (validateSauceLabInstance()) {
            userNameRef.current.disabled = true;
            userNameRef.current.style.opacity = 0.5;
            accessKeyRef.current.disabled = true;
            accessKeyRef.current.style.opacity = 0.5;
          }
        } else {
          if (props.type === 'BrowserStack') {
            createAccout();
          } else {
            createSauceLabAccount();
          }
        }
      } else {
        if (props.type === 'BrowserStack') {
          updateAccount();
        } else {
          updateSauceLabAccount();
        }
      }
    }
  };

  const formikDetails = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const updateAccount = () => {
    if (
      formikDetails.values.accessKey !== '' &&
      formikDetails.values.username !== '' &&
      formikDetails.values.instance !== ''
    ) {
      let data = {
        instanceName: formikDetails.values.instance,
        username: formikDetails.values.username,
        accessKey: formikDetails.values.accessKey,
      };
      if (
        data?.instanceName === props.data?.instanceName &&
        data?.username === props.data?.username &&
        data?.accessKey === props.data?.accessKey
      ) {
        MyAlert.info('No changes were done');
        setCreateCalled(false);
        return;
      }
      updateBrowserStack(data, props.data.id).then((response) => {
        setCreateCalled(false);
        if (response.data.responseCode === 400 && response.data.message.includes('name already exists')) {
          formikDetails.setFieldError('instance', response.data.message);
        }
        if (
          response?.data?.responseCode === 400 &&
          response?.data?.message === 'Either username or accessKey is invalid'
        ) {
          MyAlert.warning(`${response.data.message}`);
        } else if (response.data?.responseCode === 200) {
          setOpenModal(false);
          props.MyAlert.success(`Account is updated successfully`);
          props.closeModal(false);
          props.reloadTree();
        }
        // setCreateCalled(false)
      });
    }
  };
  const updateSauceLabAccount = () => {
    if (
      formikDetails.values.accessKey !== '' &&
      formikDetails.values.username !== '' &&
      formikDetails.values.instance !== '' &&
      formikDetails.values.defaultDataCenter !== ''
    ) {
      let data = {
        instanceName: formikDetails.values.instance,
        username: formikDetails.values.username,
        accessKey: formikDetails.values.accessKey,
        defaultDataCenter: formikDetails.values.defaultDataCenter,
      };
      if (
        data?.instanceName === props.data?.instanceName &&
        data?.username === props.data?.username &&
        data?.accessKey === props.data?.accessKey &&
        data?.defaultDataCenter === props.data?.defaultDataCenter
      ) {
        MyAlert.info('No changes were done');
        setCreateCalled(false);
        return;
      }
      updateSauceLab(data, props.data.id).then((response) => {
        setCreateCalled(false);
        if (response.data.responseCode === 400 && response.data.message.includes('name already exists')) {
          formikDetails.setFieldError('instance', response.data.message);
        }
        if (response.data.responseCode === 400 && response.data.message === 'Either username or accessKey is invalid') {
          MyAlert.warning(`${response.data.message}`);
        } else if (response.data.responseCode === 200) {
          setOpenModal(false);
          props.MyAlert.success(`Account is updated successfully`);
          props.closeModal(false);
          props.reloadTree();
        }
        // setCreateCalled(false)
      });
    }
  };
  const createAccout = () => {
    if (
      formikDetails.values.accessKey !== '' &&
      formikDetails.values.username !== '' &&
      formikDetails.values.instance !== ''
    ) {
      let data = {
        instanceName: formikDetails.values.instance,
        username: formikDetails.values.username,
        accessKey: formikDetails.values.accessKey,
      };
      addBrowserStack(data).then((response) => {
        let instanceName = response?.data?.responseObject?.instanceName;
        setCreateCalled(false);
        if (response.data.responseCode === 400 && response.data.message.includes('name already exists')) {
          formikDetails.setFieldError('instance', response.data.message);
        }
        if (response.data.responseCode === 400 && response.data.message === 'Either username or accessKey is invalid') {
          MyAlert.warning(`${response.data.message}`);
        } else if (response.data.responseCode === 201 && response.data.message === 'SUCCESS') {
          setOpenModal(false);
          props.MyAlert.success(
            instanceName.length >= 22
              ? instanceName.substring(0, 22) + '...' + ' instance is created successfully'
              : `${instanceName} instance is created successfully`
          );
          props.closeModal(false);
          props.reloadTree();
        }
      });
    }
  };

  const validateSauceLabInstance = () => {
    if (
      formikDetails.values.accessKey !== '' &&
      formikDetails.values.username !== '' &&
      formikDetails.values.instance !== ''
    ) {
      let validData = {
        instanceName: formikDetails.values.instance,
        username: formikDetails.values.username,
        accessKey: formikDetails.values.accessKey,
      };

      validateInstance(validData)
        .then((response) => {
          if (response?.data?.responseCode === 201 && response.data.message === 'SUCCESS') {
            document.getElementById('username').disabled = true;
            document.getElementById('accessKey').disabled = true;
            document.getElementById('username').style.opacity = 0.5;
            document.getElementById('accessKey').style.opacity = 0.5;
            setShowDropdown(true);

            const _dataCenter = response?.data?.responseObject?.dataCenters.map((dtaCent) => ({
              name: dtaCent,
              value: dtaCent,
            }));
            setDataCentersOptions(_dataCenter);
            formikDetails.setFieldValue('defaultDataCenter', _dataCenter[0].value);
          }

          if (response.data.responseCode === 400 && response.data.message.includes('is invalid')) {
            MyAlert.warning(`${response?.data?.message}`);
            document.getElementById('username').disabled = false;
            document.getElementById('accessKey').disabled = false;
            document.getElementById('username').style.opacity = 1;
            document.getElementById('accessKey').style.opacity = 1;
          }
          setCreateCalled(false);
        })
        .catch((error) => {
          console.log(`Error occoured  ${error}`);
        });
    }
  };

  const createSauceLabAccount = () => {
    if (
      formikDetails.values.accessKey !== '' &&
      formikDetails.values.username !== '' &&
      formikDetails.values.instance !== '' &&
      formikDetails.values.defaultDataCenter !== ''
    ) {
      let data = {
        instanceName: formikDetails.values.instance,
        username: formikDetails.values.username,
        accessKey: formikDetails.values.accessKey,
        defaultDataCenter: formikDetails.values.defaultDataCenter,
      };
      createInstance(data).then((response) => {
        let instanceName = response?.data?.responseObject?.instanceName;
        if (response?.data?.responseCode === 400 && response.data.message.includes('name already exists')) {
          formikDetails.setFieldError('instance', response.data.message);
        }
        if (
          response?.data?.responseCode === 400 &&
          response.data.message === 'Either username or accessKey is invalid'
        ) {
          document.getElementById('username').disabled = false;
          document.getElementById('accessKey').disabled = false;
          document.getElementById('username').style.opacity = 1;
          document.getElementById('accessKey').style.opacity = 1;
          MyAlert.warning(`${response.data.message}`);
        } else if (response?.data?.responseCode === 201 && response.data.message === 'SUCCESS') {
          setOpenModal(false);
          props.MyAlert.success(
            instanceName.length >= 22
              ? instanceName.substring(0, 22) + '...' + ' instance is created successfully'
              : `${instanceName} instance is created successfully`
          );
          props.closeModal(false);
          props.reloadTree();
        }
        setCreateCalled(false);
      });
    }
  };
  return (
    <div>
      <Modal open={openModal} className="modal_dialog outline-none focus:outline-none">
        <div className="modal_container browserStack_check_modal outline-none focus:outline-none">
          <div className="alert-position-style">
            <AlertContatiner></AlertContatiner>
          </div>
          <div className="modal_header">
            {props.mode === 'ADD' || props.mode === 'ADD_SUB' ? (
              <h2 className="title"> Create instance</h2>
            ) : (
              <div className="title title-trim" title={props.data.instanceName}>
                <h2 className="title"> Edit instance - {props.data.instanceName}</h2>
              </div>
            )}
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                props.closeModal(false);
                setOpenModal(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="modal_body">
            <form onSubmit={formikDetails.handleSubmit}>
              <div className="grid grid-cols-2 gap-4 mb-9">
                <div>
                  <MyInput
                    error={formikDetails.errors.instance && formikDetails.touched.instance}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={
                      <>
                        {' '}
                        <span className="text-red-400 mr-1">&#42;</span> <span className="">Instance Name</span>
                      </>
                    }
                    size="40"
                    className=" w-60"
                    type="text"
                    autoComplete="off"
                    autoFocus={false}
                    name="instance"
                    id="instance"
                    placeholder={`Enter instance name`}
                    onBlur={formikDetails.handleBlur}
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.instance}
                    helperText={
                      <div className="fontPoppinsRegularSm absolute">
                        {formikDetails.errors.instance && formikDetails.touched.instance && (
                          <p className="errorMessage fontPoppinsRegularSm absolute w-60">
                            {formikDetails.errors.instance}
                          </p>
                        )}
                      </div>
                    }
                  />
                </div>
                <div>
                  <MyInput
                    error={formikDetails.errors.username && formikDetails.touched.username}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={
                      <>
                        {' '}
                        <span className="text-red-400 mr-1">&#42;</span> <span className="">UserName</span>
                      </>
                    }
                    ref={userNameRef}
                    disabled={props.mode === 'EDIT' && props.type === 'sauceLab' ? true : false}
                    size="40"
                    className={
                      props.mode === 'EDIT' && props.type === 'sauceLab'
                        ? 'opacity-50 w-60'
                        : 'text-gray-700 w-60 open-sans-regular'
                    }
                    type="text"
                    autoComplete="off"
                    autoFocus={false}
                    name="username"
                    id="username"
                    placeholder={`Enter username name`}
                    onBlur={formikDetails.handleBlur}
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.username}
                    helperText={
                      <div className="fontPoppinsRegularSm absolute">
                        {formikDetails.errors.username && formikDetails.touched.username && (
                          <p className="errorMessage fontPoppinsRegularSm fixed">{formikDetails.errors.username}</p>
                        )}
                      </div>
                    }
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <div>
                  <MyInput
                    error={formikDetails.errors.accessKey && formikDetails.touched.accessKey}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={
                      <>
                        {' '}
                        <span className="text-red-400 mr-1">&#42;</span>
                        <span className="">Access Key</span>
                      </>
                    }
                    ref={accessKeyRef}
                    disabled={props.mode === 'EDIT' && props.type === 'sauceLab' ? true : false}
                    className={
                      props.mode === 'EDIT' && props.type === 'sauceLab'
                        ? 'opacity-50 w-60'
                        : 'text-gray-700 w-60 open-sans-regular'
                    }
                    size="40"
                    type="password"
                    autoFocus={false}
                    name="accessKey"
                    id="accessKey"
                    placeholder={`Enter accessKey name`}
                    onBlur={formikDetails.handleBlur}
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.accessKey}
                    helperText={
                      <div className="fontPoppinsRegularSm absolute">
                        {formikDetails.errors.accessKey && formikDetails.touched.accessKey && (
                          <p className="errorMessage fontPoppinsRegularSm fixed">{formikDetails.errors.accessKey}</p>
                        )}
                      </div>
                    }
                  />
                </div>

                {props.type === 'sauceLab' && showDropdown && (
                  <div>
                    <div htmlFor="dataCenter" className="input-filed-label-style-common">
                      <span className="text-red-400 mr-1">&#42;</span>
                      <span className="dataCenter">Select the Region</span>
                    </div>
                    <FormControl>
                      <Select
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                        }}
                        name="defaultDataCenter"
                        id="defaultDataCenter"
                        error={formikDetails.errors.defaultDataCenter && formikDetails.touched.defaultDataCenter}
                        onChange={formikDetails.handleChange}
                        onBlur={formikDetails.handleBlur}
                        value={formikDetails.values.defaultDataCenter}
                        className="w-60 fontPoppinsRegularMd"
                      >
                        {dataCentersOptions &&
                          dataCentersOptions.map((data) => (
                            <MenuItem key={data.value} value={data.value}>
                              {data.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    {formikDetails.errors.defaultDataCenter && formikDetails.touched.defaultDataCenter ? (
                      <div className="errorMessage">{formikDetails.errors.defaultDataCenter}</div>
                    ) : null}
                  </div>
                )}
              </div>
              <div className="modal_footer">
                <button
                  className="gray-btn mr-3"
                  type="button"
                  onClick={() => {
                    props.closeModal(false);
                    setOpenModal(false);
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="primary-btn">
                  {props.mode === 'ADD' || props.mode === 'ADD_SUB' ? (
                    <>
                      {props.type === 'sauceLab' && !formikDetails.values.defaultDataCenter ? (
                        <span>Save</span>
                      ) : (
                        <span>Create</span>
                      )}
                    </>
                  ) : (
                    <span>Update</span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default BrowserStackModal;

import { useContext } from 'react';
import { ClientsContext } from '../components/ClientsProvider';
import DonutChartWithDescriptionAndFooter from './DonutChartWithDescriptionAndFooter';

function ClientsOSAndVersions() {
  const { osChartData, selectedClientType } = useContext(ClientsContext);
  const { colors, labels, totalLabel, title, series, icons } = osChartData;
  return (
    <DonutChartWithDescriptionAndFooter
      colors={colors}
      labels={labels}
      totalLabel={totalLabel}
      title={title}
      series={series}
      icons={icons}
      typeOfData={selectedClientType}
    />
  );
}

export default ClientsOSAndVersions;

import React from 'react';
import HeaderComponent from '../../header';

const Header = ({ navOpen }) => {
  return (
    <div
      className={`${
        navOpen ? 'left-200p w-84per' : 'left-65p  w-94per'
      } fixed header flex justify-end dark:bg-gray-800 dark:text-white`}
    >
      <HeaderComponent />
    </div>
  );
};

export default Header;

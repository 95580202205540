import React, { Fragment, useEffect, useState, useContext, useMemo, memo } from 'react';
import '../../repository.scss';
import TableTree from '../../../common/table_tree/table_tree';
import MoreInfoButton from '../../../common/more_info_button';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DetailsModal from '../../shared/details_modal';
import TreeToolbar from '../../shared/tree_toolbar';
import {
  getElementReq,
  getPageTreeReq,
  delPageReq,
  delElementReq,
  getPageReq,
  singlePageTreeReq,
  getSingleUserRequest,
  changeEleAsSharedEleReq,
  unShareSharedEleReq,
  getSharedElementReq,
  cloneReq,
  getFirstLevelPageTreeReq,
  getAnalyticsReq,
} from '@api/api_services';
import { useHistory } from 'react-router-dom';
import { REPO, trimPath } from '../../../../common/ui-constants';
import LocatorDetailsModal from './modals/locator_details_modal';
import PageModal from './modals/page_modal';
import UserDetailsModal from '../../../test-development/shared/user-details-modal';
import DeleteModal from '../../../common/delete_modal';
import MergeConflictModal from './shared_recorded_elements/shared_elements/merge_conflict_modal';
import AddFromSharedModal from './shared_recorded_elements/shared_elements/add_from_shared_modal';
import Tooltip from '@material-ui/core/Tooltip';
import ElementModal from './modals/ElementModal';
import { encodeSpecialCharater } from '../../../test-development/shared/common-methods';
import ElementConflictPage from './shared_recorded_elements/shared_elements/element_conflict_page';
import CloneInfoModal from '@src/pages/common/cloneInfo_modal';
import LoaderModal from '@src/pages/test-development/script/modules/module/modals/loader-modal';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { CommonLoader } from '@src/pages/common/common-loader';
import { elementPlatformTypes } from './modals/element.utils';
import {
  pageElementNavigation,
  getUrlPath,
} from '@pages/test-development/script/modules/module/utils/common-functions';
import { getTruncatedText, isEmptyValue, HighlightText } from '@src/util/common_utils';
import { AddCircleOutlineOutlined } from '@material-ui/icons';
import MoveResource from '@src/pages/test-development/script/modules/module/modals/MoveResource';
import { VISUAL_TESTING_IMAGE_FILE_EXTENTION } from '@src/common/ui-constants';

const ElementTree = (props) => {
  const { isEditable, hasFullAccess, isProjectOpen } = React.useMemo(
    () => ({
      isProjectOpen: window.permission?.isProjectOpen(),
      isEditable: window.permission?.isEditAccess('repository'),
      hasFullAccess: window.permission?.isFullAccess('repository'),
    }),
    []
  );
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  let hasCloneOption = isProjectOpen && (isEditable || hasFullAccess);
  let [createAsSharedEle, setCreateAsSharedEle] = useState(false);
  const history = useHistory();
  let parentName = [];
  let prentsData = [];
  let renderTree;
  let projectType;
  let platForm;
  const search = window.location.search;
  let [elementConflictModal, setElementConflictModal] = useState(false);
  let [unsharedchildElements, setUnsharedchildElements] = useState([]);
  const id = new URLSearchParams(search).get('id');
  if (
    history.location.pathname !== '/repository/element/project_elements' &&
    history.location.pathname !== '/repository/element' &&
    history.location.pathname !== '/repository/element/project_elements/Root/Page' &&
    history.location.pathname !== '/repository/element/project_elements/Root/Screen'
  ) {
    prentsData = JSON.parse(localStorage.getItem('parentAndSonJson'));
    prentsData?.forEach((eleOrPage, index) => {
      let title = eleOrPage.Name;
      parentName.push(encodeSpecialCharater(title));
    });
    parentName = parentName?.reverse();
    let location = history.location.pathname.split('/');
    let locLength = location.length - 5;
    prentsData?.splice(0, prentsData.length - locLength);
    if (prentsData?.length) {
      localStorage.setItem('parentAndSonJson', JSON.stringify(prentsData));
    }
  } else {
    localStorage.removeItem('parentAndSonJson');
  }
  if (props.projectType) {
    projectType = props.projectType;
    platForm = props.platForm;
  } else {
    projectType = new URLSearchParams(search).get('projectType');
    if (projectType === 'web_mobile') {
      projectType = 'Web & Mobile';
    }
    platForm = new URLSearchParams(search).get('platForm');
  }
  let [pages, setpages] = useState(0);
  const countData = JSON.parse(localStorage.getItem('allowedProjects'))?.features?.repository?.restrictions;
  const [licenseLevelElementCount, setLicenseLevelElementCount] = useState(0);
  let [elements, setelements] = useState('');
  let [treeData, setTreeData] = useState([]);
  let [dropdownTreeData, setDropdownTreeData] = useState([]);
  const [elementTree, setElementTree] = useState([]);
  let [rootData, setRootData] = useState({});
  let [treeHeader, setTreeHeader] = useState('Project Elements');
  let [type, setType] = useState('');
  let [popupData, setPopupData] = useState([]);
  let [openEditModal, setOpenEditModal] = useState(false);
  let [details, setDetails] = useState(false);
  let [eleDetails, setEleDetails] = useState(false);
  const [message, setMessage] = useState('');
  const [node, setNode] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [reloadTree, setReloadTree] = useState(false);
  let [nodeToAdd, setNodeToAdd] = useState(null);
  let [addSharedEleTo, setAddSharedEleTo] = useState('');

  let [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);

  const [showPageModal, setShowPageModal] = useState(false);
  const [showSubPageModal, setShowSubPageModal] = useState(false);
  const [openElementModal, setOpenElementModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [userDetails, setUserDetails] = React.useState([]);
  const [locatorsDetails, setLocatorsDetails] = useState();
  const [mode, setMode] = useState();

  const [pageId, setPageId] = useState();
  const [pageName, setPageName] = useState();
  let [openMergeConflictModal, setopenMergeConflictModal] = useState(false);
  let [openAddFromSharedModal, setAddFromSharedModal] = useState(false);
  let [pageKeyForEle, setPageKeyForEle] = useState('');
  let [sharingEle, setSharingELe] = useState();
  let [sharedConflictEles, setSharedConflictEles] = useState();
  let project = JSON.parse(localStorage.getItem('selected-project'));
  let [cloningContainerData, setCloningContainerData] = useState();
  let [openCloneInfoModal, setOpenCloneInfoModal] = useState(false);
  const [showLoaderModal, setShowLoaderModal] = useState(false);
  const [searchedText, setSearchedText] = useState('');
  const [searchedTextQuery, setSearchedTextQuery] = useState('');
  const [childResourcesAvailable, setChildResourcesAvailable] = useState(false);
  const [moveResourceModalData, setMoveResourceModalData] = useState({ open: false, srcNodeData: {} });
  const reloadTreeData = (value) => {
    setReloadTree(!reloadTree);
  };

  let openEdit = (val) => {
    setOpenEditModal(val);
  };
  let PAGE_PLUS_ICON_MENU = [
    {
      name: `Add ${
        projectType === 'Mobile'
          ? 'Screen'
          : projectType === 'Web'
          ? 'Page'
          : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(projectType) && platForm === 'Web'
          ? 'Page'
          : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(projectType) &&
            ['Android', 'iOS', 'Ios', 'MobileWeb', 'Mobile'].includes(platForm)
          ? 'Screen'
          : 'Page'
      }`,
      // For single script changes - Sony
      // name: `Add ${[projectType?.toLowerCase(), platForm?.toLowerCase(), ].includes('web') ? "Page" : "Screen"}`,
      value: 'page',
    },
    {
      name: 'Add Element',
      value: 'element',
    },
  ];
  const closeModal = (val) => {
    if (openEditModal) {
      setOpenEditModal(val);
    } else if (openDeleteModal) {
      setOpenDeleteModal(val);
    } else if (showUserModal) {
      setShowUserModal(val);
    } else if (eleDetails) {
      setEleDetails(val);
    } else if (details) {
      setDetails(val);
    } else if (showPageModal) {
      setShowPageModal(val);
    } else if (showSubPageModal) {
      setShowSubPageModal(val);
    } else if (openElementModal) {
      setOpenElementModal(val);
      if (props?.getAllElemtsReq) {
        props?.getAllElemtsReq(projectType, platForm);
      }
    } else if (openMergeConflictModal) {
      setopenMergeConflictModal(val);
    } else if (openAddFromSharedModal) {
      setAddFromSharedModal(val);
    } else if (elementConflictModal) {
      setElementConflictModal(val);
    } else if (openCloneInfoModal) {
      setOpenCloneInfoModal(val);
    }
  };
  let openDetails = (val) => {
    setDetails(val);
  };

  const rootNodeClicked = (node) => {
    let projectTypeName;
    if (treeData.length) {
      if (projectType === 'Web & Mobile') {
        projectTypeName = 'web_mobile';
      } else {
        projectTypeName = projectType;
      }
      if (node.folder) {
        let pageData = {
          Name: node.title,
          id: node.key,
          type: 'page',
          projectType: projectTypeName,
          platForm: platForm,
        };
        const pageDetails = [];
        pageDetails.push(pageData);
        if (node.name === 'Root' && node.title === 'Root Page') {
          localStorage.setItem('rootJson', JSON.stringify(pageData));
          history.push(
            `/repository/element/project_elements/${getUrlPath(pageDetails)}${
              projectType === 'Web'
                ? 'Page'
                : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(projectType) && platForm === 'Web'
                ? 'Page'
                : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(projectType) &&
                  ['Android', 'iOS', 'Ios', 'MobileWeb']?.includes(platForm)
                ? 'Screen'
                : 'Screen'
            }?id=${node.key}&type=page&projectType=${projectTypeName}&platForm=${platForm}`
          );
        }
        history.push(
          `/repository/element/project_elements/${getUrlPath(pageDetails)}${
            [projectType?.toLowerCase(), platForm?.toLowerCase()].includes('web') ? 'Page' : 'Screen'
          }?id=${node.key}&type=page&projectType=${projectTypeName}&platForm=${platForm}`
        );
      }
    }
  };
  const onNodeSelected = (nodeObj) => {
    var node = nodeObj.node;
    let projectTypeName;
    if (projectType === 'Web & Mobile') {
      projectTypeName = 'web_mobile';
    } else {
      projectTypeName = projectType;
    }
    if (node.folder) {
      getPageReq(node.key).then((response) => {
        if (response.data.responseCode === 200) {
          let searchKeyArray = node.data.searchKey.slice(1).split('/');
          searchKeyArray = searchKeyArray.slice(1);
          let nameArray = trimPath(response.data.responseObject.path).split('/');
          const { json } = pageElementNavigation(searchKeyArray, nameArray, projectTypeName, platForm);
          localStorage.setItem('parentAndSonJson', JSON.stringify(json));
          history.push(
            `/repository/element/project_elements/${getUrlPath(json)}${
              projectType === 'Web'
                ? 'Page'
                : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(projectType) && platForm === 'Web'
                ? 'Page'
                : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(projectType) &&
                  ['Android', 'iOS', 'Ios', 'MobileWeb', 'Mobile'].includes(platForm)
                ? 'Screen'
                : 'Screen'
            }?id=${node.key}&type=page&projectType=${projectTypeName}&platForm=${platForm}`
          );
        }
      });
    } else {
      let isShared = nodeObj?.data?.isShared;
      if (isShared === 'N' || isShared === 'NO' || isShared === 'No') {
        getElementReq(node.data.parentId, node.key).then((response) => {
          let elementPath = trimPath(response.data.responseObject.path).split('/');
          let eleSearchKeyArray = node.data.searchKey.slice(1).split('/');
          eleSearchKeyArray = eleSearchKeyArray.slice(1);
          if (eleSearchKeyArray[eleSearchKeyArray.length - 1].includes('SH')) {
            eleSearchKeyArray[eleSearchKeyArray.length - 1] = node.key;
          }
          const { json } = pageElementNavigation(eleSearchKeyArray, elementPath, projectTypeName, platForm);
          localStorage.setItem('parentAndSonJson', JSON.stringify(json));
          history.push(
            `/repository/element/project_elements/${getUrlPath(json)}Element?pageId=${node.data.parentId}&eleId=${
              node.key
            }`
          );
        });
      } else {
        getSharedElementReq(node.key).then((response) => {
          let sharedelePath = response.data.responseObject.path;
          let patharray = sharedelePath.split(',');
          let editedArray = patharray.map((singlepath) => {
            let singleIndex = singlepath.indexOf('t');
            return singlepath.slice(singleIndex + 2, singleIndex.length);
          });
          let elementPath = editedArray.join('/').split('/');
          let eleSearchKeyArray = '';
          eleSearchKeyArray = node.data.searchKey.slice(1).split('/');
          eleSearchKeyArray = eleSearchKeyArray.slice(1, -3);
          eleSearchKeyArray.push(node.key);
          const { json } = pageElementNavigation(eleSearchKeyArray, elementPath, projectTypeName, platForm);
          localStorage.setItem('parentAndSonJson', JSON.stringify(json));
          history.push(
            `/repository/element/project_elements/${getUrlPath(json)}Element?pageId=${node.data.parentId}&eleId=${
              node.key
            }&isShared=Y`
          );
        });
      }
    }
  };

  const onCheckedNodes = (nodes) => {};

  let perviousPlatFromValue = useMemo(() => props?.platForm, [searchedTextQuery]);

  useEffect(() => {
    if (props.projectType !== null && props.projectType !== '') {
      // TODO :: this condition is to handle platform change in project element
      if (perviousPlatFromValue !== props?.platForm && !isEmptyValue(perviousPlatFromValue)) {
        getPageTree('RESET');
      } else {
        getPageTree();
      }
    }
  }, [reloadTree, id, props.projectType, props.platForm]);

  const searchTreeApi = async (_searchText) => {
    const requestBody = {
      module: 'pages',
      collectionName: 'pages',
      responseFilterRequired: 'true',
      searchText: _searchText,
      additionalSearchText: id ? id : '',
      facetQueries: [],
      platform: props.platForm,
    };
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
      isBulkLoad: true,
    });
    setIsLoading(true);
    try {
      setSearchedTextQuery(_searchText);
      const response = await getAnalyticsReq(requestBody);
      if (response?.data?.responseObject) {
        if (response?.data?.responseObject?.licenseLevelElementCount) {
          setLicenseLevelElementCount(response.data.responseObject.licenseLevelElementCount);
        } else {
          setLicenseLevelElementCount(0);
        }
        if (response?.data?.responseObject?.pageTree) {
          setDropdownTreeData(response.data.responseObject.pageTree[0]);
        }
        setpages(response.data.responseObject?.pageTree[0].subPageCount);
        setelements(response.data.responseObject?.pageTree[0].elementCount);
        if (typeof props?.setProjectElementsCount === 'function') {
          props?.setProjectElementsCount(response.data.responseObject?.pageTree[0].elementCount);
        }
        if (response.data.responseObject?.pageTree[0].subPageCount === 0) {
          setNodeToAdd(0);
        } else {
          setNodeToAdd(null);
        }
        if (response?.data?.responseObject?.pageTree) {
          const res = createTreeData(response.data.responseObject.pageTree, 'root');
          setTreeData(res.treeData);
          setRootData(res.rootData);
          setChildResourcesAvailable(!!res?.rootData?.subPageCount);
        }
        setIsLoading(false);
        resetProgress();
      } else {
        if (response?.data?.errorCode === 404) {
          setRootData([]);
          setTreeData([]);
        }
        setIsLoading(false);
        resetProgress();
        console.warn('GET_FIRST_LEVEL_ALL_PAGE_TREE : ', response?.data?.message);
      }
    } catch (err) {
      resetProgress();
      setIsLoading(false);
      console.error('GET_ALL_PAGE_TREE : ', err);
    }
  };
  async function getPageTree(mode) {
    let res;
    setTreeData([]);
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
      isBulkLoad: true,
    });
    setIsLoading(true);
    getElementTreeData(id); //to set element tree
    if (mode === 'RESET') {
      setSearchedText('');
      setSearchedTextQuery('');
    }
    if (mode !== 'RESET' && searchedTextQuery?.length) {
      setTimeout(() => searchTreeApi(searchedTextQuery), 800);
    } else {
      if (id) {
        getSinglePageTree();
      } else {
        getFirstLevelPageTreeReq(projectType, platForm)
          .then((response) => {
            stopProgress();
            const _responseObj = response?.data?.responseObject;
            if (_responseObj) {
              if (_responseObj?.licenseLevelElementCount) {
                setLicenseLevelElementCount(_responseObj.licenseLevelElementCount);
              } else {
                setLicenseLevelElementCount(0);
              }
              if (_responseObj.pageTree[0]) {
                setDropdownTreeData(_responseObj.pageTree);
              }
              setpages(_responseObj?.pageTree[0].subPageCount);
              setelements(_responseObj?.pageTree[0].elementCount);
              if (typeof props?.setProjectElementsCount === 'function') {
                props.setProjectElementsCount(_responseObj?.pageTree[0].elementCount);
              }
              if (_responseObj?.pageTree[0].subPageCount === 0) {
                setNodeToAdd(0);
              } else {
                setNodeToAdd(null);
              }
              if (_responseObj.pageTree[0]) {
                res = createTreeData(_responseObj.pageTree, 'root');
                setTreeData(res.treeData);
                setRootData(res.rootData);
                setChildResourcesAvailable(!!res?.rootData?.subPageCount);
              }
            }
            startOrResumeProgress({
              stopAt: 100,
              intervalDuration: 10,
            });
            resetProgress();
            setIsLoading(false);
          })
          .catch((error) => {
            resetProgress();
            setIsLoading(false);
            console.error('GET_PAGE_TREE :', error);
          });
      }
    }
  }

  const getElementTreeData = (id) => {
    if (id) {
      singlePageTreeReq(id, projectType, platForm, false)
        .then((folderResponse) => {
          let pageTreeTemp = folderResponse?.data?.responseObject?.pageTree;
          if (pageTreeTemp?.length) {
            if (!pageTreeTemp[0].children) {
              pageTreeTemp[0]['children'] = [];
            }
            setElementTree(pageTreeTemp);
          } else {
            setElementTree([]);
          }
        })
        .catch((error) => {
          console.error('GET_SINGLE_PAGE_TREE_WITHOUT_RESOURCE :', error);
          history.push('/repository');
        });
    } else {
      getPageTreeReq(projectType, platForm, false)
        .then((folderResponse) => {
          if (folderResponse?.data?.responseObject?.pageTree?.length) {
            setElementTree(folderResponse.data.responseObject.pageTree);
          } else {
            setElementTree([]);
          }
        })
        .catch((error) => {
          console.error('GET_PAGE_TREE_WITHOUT_RESOURCE :', error);
        });
    }
  };

  const getSinglePageTree = () => {
    let res;
    singlePageTreeReq(id, projectType, platForm)
      .then((childResponse) => {
        stopProgress();
        setpages(10);
        const { name, parentName } = childResponse?.data?.responseObject?.pageTree[0];
        let nodeTitle = name;
        setTreeHeader(nodeTitle);
        setDropdownTreeData(childResponse.data.responseObject.pageTree);
        if (nodeTitle === 'Root' && parentName === undefined) {
          res = createTreeData(childResponse.data.responseObject.pageTree, 'root');
        } else {
          res = createTreeData(childResponse.data.responseObject.pageTree, 'child');
        }
        setTreeData(res.treeData);
        setRootData(res.rootData);
        setChildResourcesAvailable(!!res?.treeData[0]?.children?.length);
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 10,
        });
        resetProgress();
        setIsLoading(false);
      })
      .catch((error) => {
        resetProgress();
        setIsLoading(false);
        console.error('GET_SINGLE_PAGE_TREE :', error);
        history.push('/repository');
      });
  };

  const loadMoreData = async (_pageId) => {
    let _pageTree = [];
    try {
      let res;
      if (_pageId) {
        res = await singlePageTreeReq(_pageId, projectType, platForm, true);
      } else {
        res = await getPageTreeReq(projectType, platForm);
      }
      if (res?.data?.responseCode === 200 && res?.data?.responseObject) {
        _pageTree = res.data.responseObject.pageTree[0].children;
      }
      return _pageTree;
    } catch (error) {
      console.error('error during load more page data:', error);
      return _pageTree;
    }
  };

  const createTreeData = (response, type) => {
    let res_obj = {
      treeData: [],
      rootData: {},
    };

    if (!response[0].children) {
      response[0]['children'] = [];
    }
    const { children, ...root_data } = response[0];
    if (type === 'root') {
      res_obj.treeData = response[0].children;
    } else {
      res_obj.treeData = response;
    }
    let title = root_data.title;
    if (projectType === 'Web' && type === 'root') {
      if (!root_data.title.toLowerCase().includes('page')) {
        root_data.title = title.concat(' Page');
      }
    } else if (projectType === 'Mobile' && type === 'root') {
      if (!root_data.title.toLowerCase().includes('screen')) {
        root_data.title = title.concat(' Screen');
      }
    } else if (
      ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(projectType) &&
      platForm === 'Web' &&
      type === 'root'
    ) {
      let title = root_data.title;
      if (title.includes('screen')) {
        root_data.title = title.slice(0, title.indexOf('s')).concat(' Page');
      } else if (!root_data.title.toLowerCase().includes('page')) {
        root_data.title = title.concat(' Page');
      }
    } else if (
      ['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectType) &&
      elementPlatformTypes.includes(platForm) &&
      type === 'root'
    ) {
      if (!root_data.title.toLowerCase().includes('screen')) {
        root_data.title = title.concat(' Screen');
      }
    }
    res_obj.rootData = root_data;
    return res_obj;
  };
  const onEditClicked = (ev, nodeObj) => {
    setNode(nodeObj);
    if (nodeObj.data.folder) {
      setType('page');
      getApiData('page', nodeObj.node, 'edit');
      setMode('EDIT');
    } else {
      setType('element');
      getApiData('element', nodeObj.node, 'edit');
      setMode('EDIT');
    }
  };

  const onDeleteClicked = (ev, nodeObj) => {
    setNode(nodeObj);
    if (nodeObj.data.folder) {
      let exist = checkDoesNameContainPageOrScreenName(nodeObj.node.title);
      if (nodeObj.node.children) {
        if ((nodeObj.data.elementCount > 0 || nodeObj.data.elementCount === 0) && nodeObj.data.subPageCount === 0) {
          props.MyAlert.info(
            `You are not allowed to delete ${
              nodeObj.data.title.length > 12 ? nodeObj.data.title.substring(0, 12) + '...' : nodeObj.data.title
            }${
              exist
                ? ''
                : projectType === 'Mobile'
                ? ' screen'
                : projectType === 'Web'
                ? ' page'
                : ['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectType) && platForm === 'Web'
                ? ' page'
                : ['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectType) &&
                  elementPlatformTypes.includes(platForm)
                ? ' screen'
                : ' page'
            } as the${
              projectType === 'Mobile'
                ? ' screen'
                : projectType === 'Web'
                ? ' page'
                : ['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectType) && platForm === 'Web'
                ? ' page'
                : ['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectType) &&
                  elementPlatformTypes.includes(platForm)
                ? ' screen'
                : ' page'
            } contains some elements`
          );
        } else if (nodeObj.data.elementCount === 0 && nodeObj.data.subPageCount >= 0) {
          props.MyAlert.info(
            `You are not allowed to delete ${
              nodeObj.data.title.length > 12 ? nodeObj.data.title.substring(0, 12) + '...' : nodeObj.data.title
            }${
              exist
                ? ''
                : projectType === 'Mobile'
                ? ' screen'
                : projectType === 'Web'
                ? ' page'
                : ['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectType) && platForm === 'Web'
                ? ' page'
                : ['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectType) &&
                  elementPlatformTypes.includes(platForm)
                ? ' screen'
                : ' page'
            } since it contains some sub${
              projectType === 'Mobile'
                ? ' screen'
                : projectType === 'Web'
                ? ' page'
                : ['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectType) && platForm === 'Web'
                ? ' page'
                : ['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectType) &&
                  elementPlatformTypes.includes(platForm)
                ? ' screen'
                : ' page'
            }s`
          );
        } else {
          props.MyAlert.info(
            `You are not allowed to delete ${
              nodeObj.data.title.length > 12 ? nodeObj.data.title.substring(0, 12) + '...' : nodeObj.data.title
            }${
              exist
                ? ' '
                : projectType === 'Mobile'
                ? ' Screen'
                : projectType === 'Web'
                ? ' Page'
                : ['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectType) && platForm === 'Web'
                ? ' Page'
                : ['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectType) &&
                  elementPlatformTypes.includes(platForm)
                ? ' Screen'
                : ' Page'
            } since it contains some sub${
              projectType === 'Mobile'
                ? ' Screen'
                : projectType === 'Web'
                ? ' Page'
                : ['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectType) && platForm === 'Web'
                ? ' Page'
                : ['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectType) &&
                  elementPlatformTypes.includes(platForm)
                ? ' Screen'
                : ' Page'
            }s and elements`
          );
        }
      } else {
        let exist = checkDoesNameContainPageOrScreenName(nodeObj.node.title);
        setMessage(
          `${nodeObj.data.title} ${
            exist
              ? ''
              : projectType === 'Mobile'
              ? ' Screen'
              : projectType === 'Web'
              ? ' Page'
              : ['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectType) && platForm === 'Web'
              ? ' Page'
              : ['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectType) &&
                elementPlatformTypes.includes(platForm)
              ? ' Screen'
              : ' Page'
          }`
        );
        setOpenDeleteModal(true);
      }
    } else {
      setMessage(`${nodeObj.data.title} ${nodeObj.data.type} `);
      setOpenDeleteModal(true);
    }
  };

  const checkDoesNameContainPageOrScreenName = (pageName) => {
    if (props.projectType === 'Mobile') {
      return pageName.toUpperCase().includes('screen');
    } else if (
      props.projectType === 'Web' ||
      (['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectType) && props.platForm === 'Web')
    ) {
      return pageName.toUpperCase().includes('page');
    } else if (
      ['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectType) &&
      elementPlatformTypes.includes(props.platForm)
    ) {
      return pageName.toUpperCase().includes('screen');
    } else {
      return false;
    }
  };
  async function deleteNode() {
    let response;
    const key = node.data._key;
    if (node.node.folder) {
      try {
        response = await delPageReq(key);
        if (response?.data.responseObject) {
          setOpenDeleteModal(false);
          node.node.remove();
          let exist = checkDoesNameContainPageOrScreenName(node.node.title);
          props.MyAlert.success(
            `${node.node.title.length > 22 ? node.node.title.substring(0, 22) + '...' : node.node.title} ${
              exist
                ? ''
                : projectType === 'Mobile'
                ? 'Screen'
                : projectType === 'Web'
                ? 'Page'
                : ['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectType) && platForm === 'Web'
                ? 'Page'
                : ['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectType) &&
                  elementPlatformTypes.includes(platForm)
                ? 'Screen'
                : 'Page'
            } deleted successfully`
          );
          setReloadTree(!reloadTree);
        } else {
          console.warn('DELETE_PAGE :', response.data.message);
        }
      } catch (error) {
        console.error('DELETE_PAGE :', error);
      }
    } else {
      try {
        const headerData = {
          sourceName: node?.data?.name,
          sourceType: node?.data?.type,
        };
        response = await delElementReq(node.data.parentId, key, headerData);
        if (response?.data.responseObject) {
          setOpenDeleteModal(false);
          node.node.remove();
          props.MyAlert.success(
            `${node.node.title.length > 22 ? node.node.title.substring(0, 22) + '...' : node.node.title} ${
              node.data.type
            }  deleted successfully`
          );
          setReloadTree(!reloadTree);
          props?.getAllElemtsReq(projectType, platForm);
        } else {
          console.warn('DELETE_ELEMENT:', response.data.message);
        }
      } catch (error) {
        console.error('DELETE_ELEMENT:', error);
      }
    }
    updateDropDown();
  }

  const getLocatorDetails = (nodeObj) => {
    getApiData('locators', nodeObj.node, 'details');
  };

  const onMoreButtonClicked = (name, nodeObj) => {
    setNode(nodeObj);
    if (name === 'Details') {
      if (nodeObj.data.folder) {
        if (nodeObj.isRootNode) {
          getApiData('page', nodeObj.data, 'details');
        } else {
          setType('page');
          getApiData('page', nodeObj.node, 'details');
        }
      } else {
        setType('element');
        getApiData('element', nodeObj.node, 'details');
      }
    } else if (name === 'page') {
      setPageId(nodeObj.data._key);
      setPageName(nodeObj.data.title);
      setShowSubPageModal(true);
      setNodeToAdd(nodeObj.node);
      setMode('ADD_SUB');
    } else if (name === 'element') {
      if (licenseLevelElementCount < countData.numberOfElements) {
        setPageId(nodeObj.data._key);
        setPageName(nodeObj.data.title);
        setOpenElementModal(true);
        setNodeToAdd(nodeObj.node);
        setMode('ADD_SUB');
      } else {
        props.MyAlert.info('Exceeded allocated limit, please upgrade or buy new license');
      }
    } else if (name === 'shared') {
      if (licenseLevelElementCount < countData.numberOfElements) {
        let unsharedElements = [];
        nodeObj.node.children?.map((child, index) => {
          if (!child.folder) {
            unsharedElements.push({ id: child.key, name: child.data.name, type: child.type });
          }
        });
        setUnsharedchildElements(unsharedElements);
        let node = nodeObj.node;
        setAddSharedEleTo(node.key);
        setAddFromSharedModal(true);
      } else {
        props.MyAlert.info('Exceeded allocated limit, please upgrade or buy new license');
      }
    } else if (name === 'History') {
      if (nodeObj.data.folder) {
        let node = nodeObj.node;
        history.push(`/repository/element/project_elements/${node.title}/history?id=${node.key}&type=page`);
      }
    } else if (name === 'Clone') {
      if (nodeObj.data.folder) {
        setCloningContainerData(nodeObj.data);
        setOpenCloneInfoModal(true);
      } else {
        cloneElement(nodeObj.data);
      }
    } else if (name === 'Move') {
      if (nodeObj.data.folder) {
        setType('page');
        const targetNode = nodeObj.isRootNode ? nodeObj.data : nodeObj.node;
        getApiData('page', targetNode, 'Move');
      } else {
        setType('element');
        getApiData('element', nodeObj.node, 'Move');
      }
    }
  };

  const cloneElement = async (eleData) => {
    setShowLoaderModal(true);
    let response;
    try {
      response = await cloneReq(eleData._key, eleData.parentId, 'elements', project.id, null);
      if (response?.data?.status === 'SUCCESS' || response?.data?.message === 'SUCCESS') {
        setShowLoaderModal(false);
        setReloadTree(!reloadTree);
        let str = response?.data?.responseObject.name;
        let lastIndex = str.lastIndexOf('-');
        str = str.substring(0, lastIndex);
        props.MyAlert.success(`${str.length > 25 ? str.substring(0, 25) + '...' : str} Element cloned successfully`);
      } else if (response?.data?.status?.toUpperCase() === 'FAILURE') {
        setShowLoaderModal(false);
        props.MyAlert.info('Exceeded allocated limit, please upgrade or buy new license');
      }
    } catch (err) {
      console.error('clone element api error', err);
    }
  };

  const cloneContainer = async (pageData) => {
    setShowLoaderModal(true);
    let response;
    try {
      response = await cloneReq(pageData._key, pageData.parentId, 'pages', project.id, null);
      if (response?.data?.message === 'SUCCESS' || response?.data?.status === 'SUCCESS') {
        setShowLoaderModal(false);
        setReloadTree(!reloadTree);
        let str = response?.data?.responseObject.name;
        let lastIndex = str.lastIndexOf('-');
        str = str.substring(0, lastIndex);
        props.MyAlert.success(
          ['Android', 'iOS', 'Ios', 'MobileWeb', 'Mobile'].includes(props.platForm)
            ? `${response?.data?.responseObject.name} Screen cloned successfully `
            : `${response?.data?.responseObject.name} Page cloned successfully `
        );
      } else if (response?.data?.responseCode === 400) {
        setShowLoaderModal(false);
        props.MyAlert.info(response?.data?.message);
      }
    } catch (err) {
      console.error('clone page api error', err);
    }
  };

  async function getApiData(type, node, operation) {
    let isShared = node?.data?.isShared;
    let response;
    try {
      if (['locators', 'element'].includes(type)) {
        if (isShared === 'Y') {
          response = await getSharedElementReq(node.key);
        } else {
          response = await getElementReq(node.data.parentId, node.key);
        }
      } else {
        response = await getPageReq(node.key);
      }

      if (response.data.responseObject) {
        if (['locators', 'element'].includes(type)) {
          assignIdForLocators(response.data.responseObject.locators);
        }
        if (['locators', 'element'].includes(type)) {
          let responseObject = response.data.responseObject;
          let locators = responseObject.locators;
          let updatedLocators = [];
          locators?.forEach((locator) => {
            if (['static', 'Static']?.includes(locator?.type)) {
              updatedLocators.push(locator);
            } else if (VISUAL_TESTING_IMAGE_FILE_EXTENTION.includes(locator.type)) {
              updatedLocators.push(locator);
            } else if (['dynamic', 'Dynamic'].includes(locator.type)) {
              let value = locator.value;
              locator.value = value?.replaceAll('dynamic', 'Dynamic Val');
              updatedLocators.push(locator);
            }
          });
          responseObject.locators = updatedLocators;
          setPopupData(response.data.responseObject);
        } else {
          setPopupData(response.data.responseObject);
        }
        setType(type);
        if (operation === 'Move') {
          setMoveResourceModalData({
            ...moveResourceModalData,
            open: true,
            srcNodeData: node,
          });
        }
        if (operation !== 'Move') {
          if (operation === 'edit') {
            setOpenEditModal(true);
          } else {
            if (['locators', 'element'].includes(type)) {
              setDetails(false);
              setEleDetails(true);
            } else {
              setDetails(true);
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  const assignIdForLocators = (loactors) => {
    loactors.forEach((data, index) => {
      data.id = index + 1;
    });
    setLocatorsDetails(loactors);
  };
  const showUserDetails = (node) => {
    getUserDetails(node);
  };

  const closeUserDetailsModal = (value) => {
    setOpenUserDetailsModal(value);
    setUserDetails({});
  };
  function getUserDetails(userNode) {
    getSingleUserRequest(userNode.modifiedBy).then((results) => {
      if (results.data.responseCode === 200 && results.data.responseObject) {
        setUserDetails(results.data.responseObject);
        setOpenUserDetailsModal(true);
      }
    });
  }
  let openElementConflict = (val) => {
    setElementConflictModal(val);
  };

  const createAsSharedElement = async (dataele, pageKey, createType, respData) => {
    setCreateAsSharedEle(true);
    setPageKeyForEle(pageKey);
    let response;
    setSharingELe(dataele);
    response = respData;
    if (
      response.data?.responseCode === 400 &&
      response.data?.message ===
        'Their is a conflict in Shared element would you like to crete New element or Merge into shared element'
    ) {
      setOpenElementModal(false);
      setSharedConflictEles(response.data?.responseObject);

      setopenMergeConflictModal(true);
    } else {
      setReloadTree(!reloadTree);
      props.MyAlert.success(`${getTruncatedText(dataele.name, 22)} ${dataele.type} created successfully`);
    }
  };

  const updateSharedStatus = async (event, nodeShared) => {
    document.getElementById(nodeShared.data?._key).disabled = true;
    setNode(nodeShared);
    setCreateAsSharedEle(false);
    let response;
    let reorderingData;
    let key;
    if (projectType !== 'Web') {
      key = platForm?.toLowerCase();
    } else {
      key = 'pages';
    }

    if (event.target.checked === true) {
      let projEle = await getElementReq(nodeShared.data?.parentId, nodeShared.data?._key);
      let dataele = projEle.data.responseObject;
      reorderingData = dataele;
      if (reorderingData) {
        reorderingData.parentId = nodeShared.data?.parentId;
        reorderingData.projectId = project.id;
        reorderingData.projectType = projectType;
        reorderingData.isRecorded = nodeShared.data?.isRecorded;
        reorderingData.folder = nodeShared.data?.folder;
        reorderingData.isShared = nodeShared.data?.isShared;
        reorderingData.parentName = dataele.parentName;
        if (projectType !== 'Web') {
          reorderingData.platform = platForm;
        }
        delete reorderingData.pageId;
        delete reorderingData.pageName;
      }
      setSharingELe(reorderingData);
      try {
        response = await changeEleAsSharedEleReq(nodeShared.data?.parentId, nodeShared.data?._key);
        if (response?.data?.responseCode === 201 && response?.data?.message === 'SUCCESS') {
          document.getElementById(nodeShared.data?._key).disabled = false;
          props.MyAlert.success(
            ['Android', 'iOS', 'Ios', 'MobileWeb', 'Mobile'].includes(props.platForm)
              ? `${
                  nodeShared.node.title.length > 22
                    ? nodeShared.node.title.substring(0, 22) + '...'
                    : nodeShared.node.title
                } ${nodeShared.data.type} has been shared to shared elements screen successfully`
              : `${
                  nodeShared.node.title.length > 22
                    ? nodeShared.node.title.substring(0, 22) + '...'
                    : nodeShared.node.title
                } ${nodeShared.data.type} has been shared to shared elements page successfully`
          );
          if (props?.getAllElemtsReq) {
            props?.getAllElemtsReq(projectType, platForm);
          }
          updateSharedData('Y', nodeShared);
        }
      } catch (err) {
        document.getElementById(nodeShared.data._key).checked = false;
        document.getElementById(nodeShared.data?._key).disabled = false;
        console.log(err);
      }
      if (
        response.data?.responseCode === 400 &&
        response.data?.message ===
          'Their is a conflict in Shared element would you like to crete New element or Merge into shared element'
      ) {
        document.getElementById(nodeShared.data?._key).disabled = false;
        setSharedConflictEles(response.data?.responseObject);
        setopenMergeConflictModal(true);
      } else {
        setSharingELe(reorderingData);
        setReloadTree(!reloadTree);
      }
    } else {
      try {
        response = await unShareSharedEleReq(nodeShared.data.parentId, nodeShared.data._key);
        if (response.data?.responseCode === 200 && response.data?.status === 'SUCCESS') {
          document.getElementById(nodeShared.data?._key).disabled = false;
          props.MyAlert.success(
            ['Android', 'iOS', 'Ios', 'MobileWeb', 'Mobile'].includes(props.platForm)
              ? `${
                  nodeShared.node.title.length > 22
                    ? nodeShared.node.title.substring(0, 22) + '...'
                    : nodeShared.node.title
                } ${nodeShared.data.type} has been unshared from shared elements Screen successfully`
              : `${
                  nodeShared.node.title.length > 22
                    ? nodeShared.node.title.substring(0, 22) + '...'
                    : nodeShared.node.title
                } ${nodeShared.data.type} has been unshared from shared elements page successfully`
          );
          if (props?.getAllElemtsReq) {
            props?.getAllElemtsReq(projectType, platForm);
          }
          updateSharedData('N', nodeShared);
          setReloadTree(!reloadTree);
        }
      } catch (err) {
        document.getElementById(nodeShared.data?._key).disabled = false;
        console.log(err);
      }
    }
  };

  let updateSharedData = (value, nodeShared) => {
    let selectedNode = node.node;
    if (nodeShared) {
      selectedNode = nodeShared.node;
    }
    selectedNode.data.isShared = value;
    selectedNode.render(true, true);
  };

  let unCheckShareElementBtn = (node) => {
    if (document.getElementById(node.id)) {
      document.getElementById(node.id).checked = false;
    }
  };
  const updateDropDown = async () => {
    try {
      const folderResponse = id
        ? await singlePageTreeReq(id, projectType, platForm, false)
        : await getPageTreeReq(projectType, platForm, false);
      const tempPageTree = folderResponse?.data?.responseObject?.pageTree;
      if (tempPageTree?.length === 0 || (tempPageTree[0].subPageCount === 0 && tempPageTree[0].elementCount === 0)) {
        history.push(`/repository/element/project_elements`);
      } else {
        const allTreeResponse = await getPageTreeReq(projectType, platForm);
        setDropdownTreeData(allTreeResponse.data.responseObject.pageTree);
        setElementTree([...tempPageTree]);
      }
    } catch (err) {
      console.error('GET_ONLY_PAGE : ', err);
    }
  };

  const updatedValue = (value, type) => {
    const selectedNode = node.node;
    if (type === 'folder') {
      if (mode === 'EDIT') {
        selectedNode.data.modifiedBy = value.modifiedBy;
        selectedNode.data.modifiedByUname = value.modifiedByUname;
        selectedNode.title = value.name;
        selectedNode.render(true, true);
      } else {
        selectedNode.children = value;
        if (selectedNode.expanded === true) {
          selectedNode.setExpanded(true);
        }
      }
    } else {
    }
    updateDropDown();
  };
  const createPageModal = () => {
    setShowPageModal(true);
    setMode('ADD');
  };

  const onMoreClicked = (isOpened) => {};

  const actionColumnHtml = (nodeObj) => {
    let type;
    if (nodeObj.isRootNode) {
      type = 'root';
    } else if (nodeObj.node.folder) {
      type = 'folder';
    } else {
      type = 'nonFolder';
    }
    return (
      <div className="action-col-wrp">
        <>
          {isEditable && !nodeObj.isRootNode && (
            <Tooltip title={nodeObj.data.isShared === 'Y' ? '' : 'Edit'} placement="top">
              <button
                disabled={nodeObj.data.isShared === 'Y'}
                className="state-button py-0 px-1 rounded-l "
                onClick={(event) => {
                  if (nodeObj.data.isShared === 'Y') {
                    props.MyAlert.info(
                      `${nodeObj.data.name} ${nodeObj.data.type} cannot be edited as it is shared to shared elements page`
                    );
                  } else {
                    onEditClicked(event, nodeObj);
                  }
                }}
              >
                <EditOutlinedIcon />
              </button>
            </Tooltip>
          )}

          {hasFullAccess && !nodeObj.isRootNode && (
            <Tooltip title={nodeObj.data.isShared === 'Y' ? '' : 'Delete'} placement="top">
              <button
                disabled={nodeObj.data.isShared === 'Y'}
                className="state-button py-0 px-1 rounded-l"
                onClick={(event) => {
                  if (nodeObj.data.isShared === 'Y') {
                    props.MyAlert.info(
                      `${nodeObj.data.name} ${nodeObj.data.type} cannot be deleted as it is shared to shared elements page`
                    );
                  } else {
                    onDeleteClicked(event, nodeObj);
                  }
                }}
              >
                <DeleteOutlinedIcon />
              </button>
            </Tooltip>
          )}
        </>

        {!nodeObj.isRootNode && (
          <MoreInfoButton
            methodListOfFolder={hasCloneOption ? REPO.PAGE_ACTION_MENU : REPO.ROOT_ACTION_MENU}
            methodListOfNonFolder={
              hasCloneOption
                ? nodeObj.data.isShared === 'Y'
                  ? REPO.SHARED_ELEMENT_MENU
                  : REPO.ELEMENT_ACTION_MENU
                : REPO.ROOT_ACTION_MENU
            }
            methodListForRoot={REPO.ROOT_ACTION_MENU}
            nodeObj={nodeObj}
            methodName={onMoreButtonClicked}
            type={type}
            typeToDisplay="more"
            moreButtonClicked={onMoreClicked}
          />
        )}
      </div>
    );
  };

  const addPageRootLevel = (name, nodeObj) => {
    onMoreButtonClicked(name, {
      ...nodeObj,
      node: { data: nodeObj.data, title: nodeObj.data.title, _key: nodeObj.data.key, key: nodeObj.data.key },
    });
  };

  const colDefs = [
    {
      field: 'title',
      title: `${
        projectType === 'Mobile'
          ? 'SCREEN'
          : projectType === 'Web'
          ? 'PAGE'
          : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(projectType) && platForm === 'Web'
          ? 'PAGE'
          : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(projectType) &&
            ['Mobile', 'Android', 'iOS', 'Ios', 'MobileWeb']?.includes(platForm)
          ? 'SCREEN'
          : 'PAGE'
      }`,
      class: 'title',
      isTitle: true,
      width: `calc(100% - 870px)`,
      folderTitleWidth: `calc(100% - 900px)`,
      render: (nodeObj) => {
        return (
          <>
            {nodeObj.isRootNode ? (
              <Tooltip
                title={`${
                  projectType === 'Web'
                    ? 'Pages'
                    : ['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectType) && platForm === 'Web'
                    ? 'Pages'
                    : ['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectType) &&
                      ['Android', 'iOS', 'Ios', 'MobileWeb'].includes(platForm)
                    ? 'Screens'
                    : 'Screen'
                }- ${nodeObj.data.subPageCount}`}
                placement="bottom"
              >
                <span className="count-badge folder-count ml-2 cursor-pointer">
                  {projectType === 'Web'
                    ? 'P'
                    : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(projectType) && platForm === 'Web'
                    ? 'P'
                    : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(projectType) &&
                      ['Android', 'iOS', 'Ios', 'MobileWeb']?.includes(platForm)
                    ? 'S'
                    : 'S'}
                  {/* For single script changes - Sony */}
                  {/* <Tooltip title={` ${[projectType?.toLowerCase(), platForm?.toLowerCase(), ].includes('web') ? "Pages" : "Screens"}- ${nodeObj.data.subPageCount}`} placement="bottom">

                <span className="count-badge folder-count ml-2 cursor-pointer">
                  {[projectType?.toLowerCase(), platForm?.toLowerCase(), ].includes('web') ? "P" : "S"} */}
                  {nodeObj.data.subPageCount}
                </span>
              </Tooltip>
            ) : null}
            {nodeObj.node && !nodeObj.data.isMockNode ? (
              nodeObj.node && nodeObj.node.data && nodeObj.node.folder ? (
                <>
                  <Tooltip
                    title={`${
                      projectType === 'Web'
                        ? 'Sub Pages'
                        : ['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectType) && platForm === 'Web'
                        ? 'Sub Pages'
                        : ['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectType) &&
                          ['Android', 'iOS', 'Ios', 'MobileWeb'].includes(platForm)
                        ? 'Sub Screens'
                        : 'Sub Screens'
                    } - ${nodeObj?.node?.data?.subPageCount}`}
                    placement="bottom"
                  >
                    <span className="count-badge file-count cursor-pointer">
                      {projectType === 'Web'
                        ? 'SP'
                        : ['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectType) && platForm === 'Web'
                        ? 'SP'
                        : ['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectType) &&
                          ['Android', 'iOS', 'Ios', 'MobileWeb'].includes(platForm)
                        ? 'SC'
                        : 'SC'}{' '}
                      {nodeObj?.node?.data?.subPageCount || 0}
                      {/* For single script changes - Sony */}
                      {/* <Tooltip title={`${[projectType?.toLowerCase(), platForm?.toLowerCase(), ].includes('web') ? "Sub Pages" : "Sub Screens"} - ${nodeObj?.node?.data?.subPageCount}`} placement="bottom">
                    <span className="count-badge file-count cursor-pointer">

                      {[projectType?.toLowerCase(), platForm?.toLowerCase(), ].includes('web') ? "SP" : "SC"}  {nodeObj?.node?.data?.subPageCount || 0} */}{' '}
                    </span>
                  </Tooltip>
                  <Tooltip title={`Elements - ${nodeObj.node.data.elementCount}`} placement="bottom">
                    <span className="count-badge file-count cursor-pointer">
                      {' '}
                      E {nodeObj.node.data.elementCount || 0}{' '}
                    </span>
                  </Tooltip>
                </>
              ) : nodeObj.isRootNode ? (
                <>
                  <Tooltip title={`Elements - ${nodeObj.data.elementCount}`} placement="bottom">
                    <span className="count-badge file-count cursor-pointer"> E {nodeObj.data.elementCount}</span>
                  </Tooltip>
                  {isEditable && nodeObj.data.name === 'Root' && (
                    <button className="add-btn">
                      <span
                        onClick={() => {
                          addPageRootLevel('page', nodeObj);
                        }}
                      >
                        <Tooltip
                          title={props?.projectType?.toLowerCase() === 'mobile' ? 'Add Screen' : 'Add Page'}
                          placement="right"
                        >
                          <AddCircleOutlineOutlined className="text-gray-400 hover:text-gray-600" />
                        </Tooltip>
                      </span>
                    </button>
                  )}
                </>
              ) : null
            ) : null}

            {isEditable && nodeObj.node.folder && !nodeObj.data.isMockNode && (
              <Tooltip title="Add" placement="right">
                <button className="add-btn">
                  <MoreInfoButton
                    type="add"
                    nodeObj={nodeObj}
                    plusIconArray={PAGE_PLUS_ICON_MENU}
                    methodName={onMoreButtonClicked}
                    repository={true}
                  />
                </button>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      field: 'elementType',
      title: 'TYPE',
      class: 'state elementDesc ',
      width: '70px',
      render: (nodeObj) => {
        const value = nodeObj?.data?.type || '';
        return (
          <span
            className="table-non-link-color-common fontPoppinsRegularMd capitalize"
            name="element-type"
            title={value}
          >
            {value}
          </span>
        );
      },
    },
    {
      field: 'locatorsCount',
      title: 'LOCATORS',
      class: 'locator',
      width: '65px',
      render: (nodeObj) => {
        return (
          <span
            className="locator-count-style  cursor-pointer fontPoppinsRegularMd"
            onClick={() => getLocatorDetails(nodeObj)}
            name="locators"
          >
            {nodeObj.data.locatorsCount}
          </span>
        );
      },
    },
    // {
    //   field: "state",
    //   title: "STATE",
    //   class: "state ",
    //   width: "170px",
    //   render: (nodeObj) => {
    //     return (
    //       !nodeObj.data.isMockNode && stateColumnHtml(nodeObj)
    //     )
    //   }
    // },
    // {
    //   field: "ver",
    //   title: "VERSION",
    //   class: "version ",
    //   width: "45px",
    //   render: (nodeObj) => {
    //     return (
    //       <div>
    //         {!nodeObj.isRootNode && !nodeObj.data.isMockNode && (
    //           <span className="version-text-color fontPoppinsRegularMd">
    //             {nodeObj.data.ver?.toFixed(1)}
    //           </span>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      field: 'Shared',
      title: 'SHARED',
      class: 'shared ',
      width: '50px',
      render: (nodeObj) => {
        const { folder } = nodeObj.data;
        return (
          <div>
            {!folder && !nodeObj.isRootNode && (
              <span className="mt-1.5">
                <label className="switch mx-2.5 ">
                  <input
                    type="checkbox"
                    id={nodeObj.data._key}
                    onChange={(event) => {
                      updateSharedStatus(event, nodeObj);
                    }}
                    defaultChecked={nodeObj.data.isShared === 'Y' || nodeObj.data.isShared === 'YES' ? true : false}
                    disabled={!isEditable}
                  />
                  <span className="slider round" />
                </label>
              </span>
            )}
          </div>
        );
      },
    },
    {
      field: 'modifiedBy',
      title: 'MODIFIED BY',
      class: 'modified-by ',
      width: '90px',
      render: (nodeObj) => {
        return (
          <>
            {!nodeObj.isRootNode && !nodeObj.data.isMockNode && (
              <Tooltip title={nodeObj.data.modifiedByUname} placement="top">
                <div
                  className="hyper_link_color-common fontPoppinsRegularMd  cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap w-24"
                  onClick={() => showUserDetails(nodeObj.data)}
                  name="modifiedBy"
                >
                  <HighlightText text={nodeObj.data.modifiedByUname} highlight={searchedTextQuery} />
                </div>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      field: 'action',
      title: 'ACTIONS',
      class: 'action ',
      width: '100px',
      render: (nodeObj) => {
        return !nodeObj.data.isMockNode && actionColumnHtml(nodeObj);
      },
    },
  ];

  const LABELS = {
    EXPAND_ALL:
      ['Android', 'Web & Mobile', 'iOS', 'Mobile'].includes(props.projectType) &&
      elementPlatformTypes.includes(props.platForm)
        ? REPO.EXPAND_ALL_SCREEN
        : REPO.EXPAND_ALL_PAGES,
    COLLAPSE_ALL:
      ['Android', 'Web & Mobile', 'iOS', 'Mobile'].includes(props.projectType) &&
      elementPlatformTypes.includes(props.platForm)
        ? REPO.COLLAPSE_ALL_SCREEN
        : REPO.COLLAPSE_ALL_PAGES,
    SHOW_ALL_ELEMENTS: REPO.SHOW_ALL_ELEMENTS,
    HIDE_ALL_ELEMENTS: REPO.HIDE_ALL_ELEMENTS,
    SHOW_PRE_POST_CONDITION: REPO.SHOW_PRE_POST_CONDITION,
    HIDE_PRE_POST_CONDITION: REPO.HIDE_PRE_POST_CONDITION,
  };

  if (isLoading) {
    renderTree = <CommonLoader />;
  } else if (treeData && treeData.length) {
    if (props.pageType === 'parent') {
      renderTree = (
        <TableTree
          data={treeData}
          rootData={rootData}
          colDefs={colDefs}
          showCheckbox={false}
          onCheckedNodes={onCheckedNodes}
          hideElements={false}
          hideConditions={true}
          onRootNodeClicked={rootNodeClicked}
          conditions={[{ key: 'isPreCondition', value: true }]}
          hideConditionBtn={true}
          labels={LABELS}
          onNodeSelected={onNodeSelected}
          id="element-tree"
          expandAllApi={loadMoreData}
          loadMoreData={loadMoreData}
          childrenCountKeysArray={['subPageCount', 'elementCount']}
          userSearched={searchedTextQuery}
        />
      );
    } else {
      renderTree = (
        <TableTree
          data={treeData}
          rootData={rootData}
          colDefs={colDefs}
          showCheckbox={false}
          onCheckedNodes={onCheckedNodes}
          hideElements={false}
          hideConditions={true}
          onRootNodeClicked={rootNodeClicked}
          conditions={[{ key: 'isPreCondition', value: true }]}
          hideConditionBtn={true}
          labels={LABELS}
          onNodeSelected={onNodeSelected}
          id="element-tree"
          userSearched={searchedTextQuery}
        />
      );
    }
  } else if (treeData?.length === 0 && searchedTextQuery?.length) {
    renderTree = (
      <div className="">
        <TableTree
          data={treeData}
          rootData={rootData}
          colDefs={colDefs}
          showCheckbox={false}
          onCheckedNodes={onCheckedNodes}
          hideElements={false}
          hideConditions={true}
          onRootNodeClicked={rootNodeClicked}
          conditions={[{ key: 'isPreCondition', value: true }]}
          hideConditionBtn={true}
          labels={LABELS}
          onNodeSelected={onNodeSelected}
          id="element-tree"
          userSearched={searchedTextQuery}
        />
      </div>
    );
  } else {
    renderTree = (
      <div>
        <div>
          <TableTree
            data={[]}
            rootData={rootData}
            colDefs={colDefs}
            showCheckbox={false}
            onCheckedNodes={onCheckedNodes}
            hideElements={false}
            hideConditions={true}
            onRootNodeClicked={rootNodeClicked}
            conditions={[{ key: 'isPreCondition', value: true }]}
            hideConditionBtn={true}
            labels={LABELS}
            onNodeSelected={onNodeSelected}
            id="element-tree"
            expandAllApi={loadMoreData}
            loadMoreData={loadMoreData}
            childrenCountKeysArray={['subPageCount', 'elementCount']}
            userSearched={searchedTextQuery}
          />
        </div>
        <div className="no-steps">
          {isEditable && (
            <div className="">
              <p className="font-bold mb-4 fontPoppinsRegularMd">Quick Start</p>
              <p className="mb-4 fontPoppinsRegularMd">
                <span className="font-bold">Step 1 :</span> Click
                <button onClick={createPageModal} className="ml-2 mr-2 primary-btn">
                  <span className="text-base">+</span>{' '}
                  {projectType === 'Web'
                    ? 'Page'
                    : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(projectType) && platForm === 'Web'
                    ? 'Page'
                    : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(projectType) &&
                      ['Android', 'iOS', 'Ios', 'MobileWeb']?.includes(platForm)
                    ? 'Screen'
                    : 'Screen'}
                </button>
                to start adding{' '}
                {projectType === 'Web'
                  ? 'Pages'
                  : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(projectType) && platForm === 'Web'
                  ? 'Pages'
                  : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(projectType) &&
                    ['Android', 'iOS', 'Ios', 'MobileWeb', 'Mobile'].includes(platForm)
                  ? 'Screens'
                  : 'Screens'}
                {/* For single script changes - Sony */}
                {/*    {[projectType?.toLowerCase(), platForm?.toLowerCase(), ].includes('web') ? "Page" : "Screen"}
                 </button>
                 to start adding{" "}
                 {[projectType?.toLowerCase(), platForm?.toLowerCase(), ].includes('web') ? "Pages" : "Screens"} */}
              </p>
              <p className="mb-4 fontPoppinsRegularMd">
                <span className="font-bold">Step 2 :</span> Click
                <button disabled={true} className="ml-2 mr-2 primary-btn">
                  <span className="text-base">+</span> Element
                </button>
                to start adding the Elements inside the{' '}
                {projectType === 'Web'
                  ? 'Pages'
                  : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(projectType) && platForm === 'Web'
                  ? 'Pages'
                  : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(projectType) &&
                    ['Android', 'iOS', 'Ios', 'MobileWeb', 'Mobile'].includes(platForm)
                  ? 'Screens'
                  : 'Screens'}
                {/* For single script changes - Sony */}
                {/* {[projectType?.toLowerCase(), platForm?.toLowerCase(), ].includes('web') ? "Pages" : "Screens"} */}
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="content-wrapper">
      {elementConflictModal ? (
        <ElementConflictPage
          closeModal={closeModal}
          sharingEle={sharingEle}
          updateSharedData={updateSharedData}
          projectType={projectType}
          platForm={platForm}
          MyAlert={props.MyAlert}
          reloadTree={reloadTreeData}
          getAllElemtsReq={props.getAllElemtsReq}
          pageKeyForEle={pageKeyForEle}
          createAsSharedEle={createAsSharedEle}
          unCheckShareElementBtn={unCheckShareElementBtn}
          sharedConflictEles={sharedConflictEles}
        />
      ) : (
        <div className="content_area_style" id="journal-scroll">
          <div className="">
            <TreeToolbar
              countValue={licenseLevelElementCount}
              header={treeHeader}
              treeData={elementTree}
              childResources={childResourcesAvailable}
              page="Element"
              containerCount={pages}
              resourceCount={elements}
              createAsSharedElement={createAsSharedElement}
              reloadTree={reloadTreeData}
              MyAlert={props.MyAlert}
              projectType={projectType}
              platForm={platForm}
              nodeToAdd={nodeToAdd}
              isLoading={isLoading}
              searchTree={searchTreeApi}
              resetTree={getPageTree}
              setSearchedText={setSearchedText}
            />
          </div>
          <div className="table-height overflow-auto" id="journal-scroll">
            {renderTree}
          </div>
        </div>
      )}
      {eleDetails ? (
        type === 'locators' ? (
          <LocatorDetailsModal
            locatorsArray={locatorsDetails}
            popupType={type}
            data={popupData}
            closeModal={closeModal}
            containerName="Page"
            mode="LOCATORS_DETAILS"
          />
        ) : (
          <LocatorDetailsModal
            locatorsArray={locatorsDetails}
            popupType={type}
            data={popupData}
            closeModal={closeModal}
            containerName="Page"
            mode="ELEMENT_DETAILS"
          />
        )
      ) : null}
      {details ? (
        <div>
          <DetailsModal
            data={popupData}
            openDetails={openDetails}
            closeModal={closeModal}
            containerName="Page"
            projectType={projectType}
            platForm={platForm}
          />
        </div>
      ) : null}
      {openEditModal && type === 'element' ? (
        <ElementModal
          treeData={elementTree}
          data={popupData}
          openEdit={openEdit}
          closeModal={closeModal}
          containerName="Page"
          nodeToAdd={nodeToAdd}
          reloadTree={reloadTreeData}
          mode={mode}
          MyAlert={props.MyAlert}
          projectType={projectType}
          platForm={platForm}
        />
      ) : null}
      {openEditModal && type === 'page' ? (
        <PageModal
          data={popupData}
          reloadTree={reloadTreeData}
          treeData={elementTree}
          closeModal={closeModal}
          mode={mode}
          nodeToAdd={nodeToAdd}
          MyAlert={props.MyAlert}
          updatedValue={updatedValue}
          projectType={projectType}
          platForm={platForm}
        />
      ) : null}
      {showSubPageModal ? (
        <PageModal
          parentId={pageId}
          nodeToAdd={nodeToAdd}
          parentName={pageName}
          reloadTree={reloadTreeData}
          treeData={elementTree}
          closeModal={closeModal}
          mode={mode}
          MyAlert={props.MyAlert}
          updatedValue={updatedValue}
          projectType={projectType}
          platForm={platForm}
        />
      ) : null}
      {showPageModal ? (
        <PageModal
          pageName={props.pageName}
          reloadTree={reloadTreeData}
          treeData={elementTree}
          closeModal={closeModal}
          mode={mode}
          nodeToAdd={nodeToAdd}
          MyAlert={props.MyAlert}
          updatedValue={updatedValue}
          projectType={projectType}
          platForm={platForm}
        />
      ) : null}
      {showUserModal ? <UserDetailsModal userDetails={userDetails} closeModal={closeModal} /> : null}
      {openDeleteModal ? (
        <DeleteModal node={node} message={message} closeModal={closeModal} method={deleteNode} />
      ) : null}
      {openMergeConflictModal ? (
        <MergeConflictModal
          closeModal={closeModal}
          updateSharedData={updateSharedData}
          openElementConflict={openElementConflict}
          reloadTree={reloadTreeData}
          MyAlert={props.MyAlert}
          projectType={projectType}
          platForm={platForm}
          pageKeyForEle={pageKeyForEle}
          getAllElemtsReq={props.getAllElemtsReq}
          createAsSharedEle={createAsSharedEle}
          sharingEle={sharingEle}
          unCheckShareElementBtn={unCheckShareElementBtn}
        />
      ) : null}
      {openAddFromSharedModal ? (
        <AddFromSharedModal
          closeModal={closeModal}
          reloadTree={reloadTreeData}
          addSharedEleTo={addSharedEleTo}
          getAllElemtsReq={props.getAllElemtsReq}
          MyAlert={props.MyAlert}
          projectType={projectType}
          platForm={platForm}
          unsharedchildElements={unsharedchildElements}
        />
      ) : null}
      {openElementModal ? (
        <ElementModal
          nodeToAdd={nodeToAdd}
          treeData={elementTree}
          parentId={pageId}
          parentName={pageName}
          reloadTree={reloadTreeData}
          getAllElemtsReq={props.getAllElemtsReq}
          createAsSharedElement={createAsSharedElement}
          closeModal={closeModal}
          mode={mode}
          MyAlert={props.MyAlert}
          projectType={projectType}
          platForm={platForm}
        />
      ) : null}
      {openUserDetailsModal ? <UserDetailsModal data={userDetails} closeModal={closeUserDetailsModal} /> : null}
      {openCloneInfoModal ? (
        <CloneInfoModal
          cloningContainerData={cloningContainerData}
          cloneContainer={cloneContainer}
          closeModal={closeModal}
        />
      ) : null}
      {moveResourceModalData?.open ? (
        <MoveResource
          MyAlert={props?.MyAlert}
          handleClose={() => {
            setMoveResourceModalData({ ...moveResourceModalData, open: false });
          }}
          moveResourceModalData={moveResourceModalData}
          reloadTree={reloadTreeData}
          pageOrElementMove={true}
          popupdata={popupData}
          page="Page"
          platForm={platForm}
        />
      ) : null}
      {showLoaderModal && <LoaderModal />}
    </div>
  );
};

export default memo(ElementTree);

import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import '../commons/template.scss';
import { TextField, TextareaAutosize, Select, Tooltip } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { FiMaximize2 } from 'react-icons/fi';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import Grid from '@mui/material/Grid';
import { createTemplateRequest, updateTemplateRequest } from '@api/api_services';
import FieldForm from '../commons/field_form';
import TSAddColumn from '../commons/ts_add_column';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import ColumnResizer from 'react-table-column-resizer';
import { useAlert } from '@pages/common/alert_service/useAlert';
import { getLabel, getOptionLabel } from '../utils/common-functions';
import CancelCreateTemp from '../commons/CancelCreateTemp';
import RichTextEditor from '@src/pages/common/rich-text-editor';
import MyInput from '@src/pages/user_config/Inputs/MyInput';

export default function TemplateCreationPage() {
  const history = useHistory();
  const [errorContent, setErrorContent] = React.useState();
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [openTSColumnModal, setOpenTSColumnModal] = useState(false);
  const location = useLocation();
  const [tsInitialRow, setTsInitialRow] = useState(3);
  const [openTSModal, setOpenTSModal] = useState(false);
  const [selectedCF, setSelectedCF] = useState('');
  const [showErrorMsg, setShowErrorMsg] = useState('');
  const [editFieldValue, setEditFieldValue] = useState({});
  const [alertWait, setAlertWait] = useState(true);

  const projectId = JSON.parse(localStorage.getItem('selected-project')).id;
  const mode = new URLSearchParams(location.search).get('mode');
  const getData = () => {
    if (['edit', 'view'].includes(mode)) {
      const selectedTemplateId = new URLSearchParams(location.search).get('templateId');
      if (selectedTemplateId) {
        return JSON.parse(localStorage.getItem('selectedTemplate'));
      } else {
        localStorage.removeItem('selectedTemplate');
        return JSON.parse(location.state);
      }
    } else {
      localStorage.removeItem('selectedTemplate');
      return '';
    }
  };
  const editData = getData();
  const { AlertContatiner, MyAlert } = useAlert();
  const [openDataLossModal, setOpenDataLossModal] = useState(false);

  const [showOptions, setShowOptions] = useState(false);
  const [rowId, setRowId] = useState();
  let menuRef = useRef();
  useEffect(() => {
    let handeler = (event) => {
      if (menuRef.current && !menuRef?.current?.contains(event.target)) {
        setShowOptions(false);
      }
    };
    document.addEventListener('mousedown', handeler);
    return () => {
      document.removeEventListener('mouseleave', handeler);
    };
  }, []);

  const initialValues = {
    name: '',
    testCaseDetails: [
      {
        label: 'Name',
        type: 'textField',
        placeholder: 'Enter Test case name here',
        maxLength: '25',
        minLength: '3',
        defaultValue: '',
        enable: 'yes',
        mandatory: 'yes',
        order: '1',
      },
      {
        label: 'testCaseNameDesc',
        type: 'textArea',
        placeholder: '',
        maxLength: '',
        minLength: '',
        defaultValue: '',
        enable: 'yes',
        mandatory: 'no',
        order: '2',
      },
      {
        label: 'City',
        type: 'checkbox',
        options: ['option1', 'option2', 'option3'],
        order: '3',
        defaultValue: 'option1',
        mandatory: 'no',
      },
      {
        label: 'testType',
        type: 'radioButton',
        options: ['option1', 'option2', 'option3'],
        order: '4',
        defaultValue: 'option1',
        mandatory: 'no',
      },
      {
        label: 'Attach link',
        type: 'link',
        order: '5',
        mandatory: 'no',
      },
      {
        label: 'test case type',
        type: 'dropdown',
        options: ['smoke', 'sanity', 'regression'],
        order: '6',
        mandatory: 'no',
      },
      {
        label: 'Approved By',
        type: 'date',
        dateFormat: ['dd-mm-yyyy', 'mm-yyyy-dd', 'yyyy-dd-mm'],
        order: '7',
        mandatory: 'no',
      },
      {
        label: 'File Attachment',
        type: 'attachment',
        order: '8',
        mandatory: 'no',
      },
    ],
    testSteps: {
      headers: ['testScenario', 'testStep', 'testData', 'prerequisite', 'expectedResult', 'actualResult', 'status'],
    },
  };

  const data = {
    name: '',
    testCaseDetails: [
      {
        label: 'Test Case Name',
        type: 'textField',
        placeholder: '',
        maxLength: '',
        minLength: '',
        defaultValue: '',
        enable: 'no',
        mandatory: 'yes',
        order: '1',
      },
      {
        label: 'Requirement Id',
        placeholder: '',
        maxLength: '',
        minLength: '',
        defaultValue: '',
        enable: 'yes',
        mandatory: 'no',
        type: 'textField',
        order: '2',
      },
      {
        label: 'Status',
        options: ['Passed', 'Failed', 'Warning', 'Skipped', 'Not Executed'],
        mandatory: 'no',
        type: 'dropdown',
        order: '3',
      },
      {
        label: 'Labels',
        options: ['Functional Testing', 'Regression Testing', 'Integration Testing', 'Smoke Testing'],
        mandatory: 'no',
        type: 'dropdown',
        order: '4',
      },
      {
        label: 'Description',
        type: 'textArea',
        placeholder: '',
        maxLength: '200',
        minLength: '0',
        defaultValue: '',
        enable: 'yes',
        mandatory: 'no',
        order: '5',
      },
      {
        label: 'Severity',
        options: ['Blocker', 'Critical', 'Major', 'Minor'],
        mandatory: 'no',
        type: 'dropdown',
        order: '6',
      },
      {
        label: 'Priority',
        options: ['Highest', 'High', 'Medium', 'Low', 'Lowest'],
        mandatory: 'no',
        type: 'dropdown',
        order: '7',
      },
      {
        label: 'Pre Conditions',
        placeholder: '',
        maxLength: '',
        minLength: '',
        defaultValue: '',
        enable: 'yes',
        mandatory: 'no',
        type: 'textField',
        order: '8',
      },
    ],
    testSteps: {
      headers: ['Test Steps', 'Input', 'Expected Result', 'Actual Result', 'Attachment', 'Status', 'Comment'],
    },
  };

  const [TemplateData, setTemplateData] = useState(
    mode === 'create'
      ? JSON.parse(JSON.stringify(data))
      : mode === 'edit' || mode === 'view'
        ? JSON.parse(JSON.stringify(editData.data))
        : null
  );
  const [templateName, setTemplateName] = useState(
    mode === 'create' ? '' : mode === 'edit' || mode === 'view' ? editData.data.name : null
  );
  const [templateId, setTemplateId] = useState(mode === 'edit' || mode === 'view' ? editData.data._id : '');

  const closeModal = (value) => {
    if (openAccountModal) {
      setEditFieldValue({});
      setOpenAccountModal(false);
    }
    if (openTSColumnModal) {
      setOpenTSColumnModal(false);
    }
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .ensure()
      .required('Template Name is required')
      .matches(/^[-_A-Za-z0-9 ]*$|^[-_A-Za-z0-9 ][A-Za-z0-9 ]*[-_A-Za-z-_]$/, 'Name should be alphanumeric')
      .matches(/^[-a-zA-Z0-9-()_]+(\s+[-a-zA-Z0-9-()_]+)*$/, 'Space is not allowed in the beginning and at the end')
      .min(3, 'Minimum three characters are required')
      .max(100, 'Maximum hundred characters are required'),
  });

  let checkValidationForAlphanumeric = /^[-_A-Za-z0-9 ]*$|^[-_A-Za-z0-9 ][A-Za-z0-9 ]*[-_A-Za-z-_]$/;

  const addNameField = (e) => {
    setShowErrorMsg('');
    setTemplateName(e.target.value);
    TemplateData.name = templateName;
  };

  const formikDetails = useFormik({
    initialValues,
    validationSchema,
  });

  const removeTestDetailsData = (value) => {
    let tempTemplateData = TemplateData;
    if (value) {
      let index = tempTemplateData.testCaseDetails.findIndex((x) => x.type === value.type && x.label === value.label);
      tempTemplateData.testCaseDetails.forEach((value, i) => {
        if (i > index) {
          value.order = parseInt(value.order) - 1 + '';
        }
      });
      index !== -1 && tempTemplateData.testCaseDetails.splice(index, 1);
      setTemplateData({ ...tempTemplateData });
      MyAlert.success(
        `In Test Case Details field with ${value.label.length > 15 ? value.label.substr(0, 15) + '...' : value.label} label removed successfully`
      );
    }
  };

  const testCaseDetailDescElements = (data) => {
    switch (data.type) {
      case 'textField':
        return (
          <div className="rs-user-input-container">
            <TextField
              disabled={true}
              InputProps={{ disableUnderline: true }}
              className="lg:w-72"
              title={data.placeholder.length > 30 ? data.placeholder : null}
              placeholder={data.placeholder.length > 30 ? data.placeholder.substr(0, 30) + '...' : data.placeholder}
              value={data.defaultValue}
            />
          </div>
        );

      case 'textArea':
        return (
          <>
            <div className="">
              <TextareaAutosize
                disabled={true}
                InputProps={{ disableUnderline: true }}
                title={data.placeholder.length > 30 ? data.placeholder : null}
                id="description"
                name="description"
                maxLength="200"
                maxRows={0}
                className=" bg-transparent block lg:w-72 border-0 border-b-2 rs-input-style-textarea popup-input-bg  -mt-1 pl-px  input-field-color descriptionStyle textarea-resize"
                placeholder={data.placeholder.length > 30 ? data.placeholder.substr(0, 30) + '...' : data.placeholder}
                value={data.defaultValue}
              />
              {data && data.minLength >= 0 && data.maxLength >= 0 && mode !== 'view' ? (
                <div className="mt-1 text-sm text-gray-500 text-right mr-2">
                  {data.minLength}/{data.maxLength}
                </div>
              ) : data.minLength >= 0 && data.maxLength >= 0 ? (
                <div className="mt-1 text-sm text-gray-500 text-right mr-14">
                  {data.minLength}/{data.maxLength}
                </div>
              ) : null}
            </div>
          </>
        );

      case 'radioButton':
        return (
          <>
            <div className="mt-3">
              <div className="grid grid-cols-2 gap-1">
                {data.options.map((option, index) => (
                  <div>
                    <input
                      type="radio"
                      className="mr-2"
                      name={`option${index}`}
                      id={`option${index}`}
                      value={option}
                      disabled={true}
                    />
                    <label className="details-data-style-common">{getOptionLabel(option)}</label>
                  </div>
                ))}
              </div>
            </div>
          </>
        );

      case 'link':
        return (
          <>
            <div className=" flex mt-3 lg:w-72">
              <div>
                <InsertLinkOutlinedIcon className="-mt-2 mr-2 origin-center rotate-90 opacity-60" />
              </div>
              <div aria-disabled={true} className="details-data-style-common opacity-50 w-72">
                {getLabel('Attached Link will be shown here if any URL is added')}
              </div>
            </div>
          </>
        );

      case 'dropdown':
        return (
          <>
            <FormControl variant="standard">
              <Select
                variant="standard"
                disabled={['Labels'].includes(data?.label) ? true : false}
                displayEmpty
                disableUnderline
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                renderValue={(selected) => {
                  if (!selected) return data?.placeholder;
                }}
                name="selectCustomField"
                id="selectCustomField"
                className="lg:w-72 lg:h-8 sm:w-40 fontPoppinsRegularMd"
              >
                {data.options.map((option, index) => (
                   <Tooltip title={option} placement="top">
                    <option
                      className="hover:text-blue-700 cursor-pointer hover:bg-blue-100 details-data-style-common lg:w-72 sm:w-40 fontPoppinsRegularMd truncate"
                      value={option}
                    >
                      {option}
                    </option>
                  </Tooltip>
                ))}
              </Select>
            </FormControl>
          </>
        );

      case 'date':
        return (
          <>
            <div className="flex">
              <div className=" h-8 border-2 border-gray-300 w-11/12"></div>
              <div className=" bg-gray-200">
                <CalendarTodayOutlinedIcon className="mt-1" />
              </div>
            </div>
            {TemplateData?.templateType === 'Predefined' ? (
              <div className="details-data-style-common opacity-50">[dd-mm-yyyy]</div>
            ) : (
              <div className="details-data-style-common opacity-50">[{data.dateFormat}]</div>
            )}
          </>
        );
      case 'checkbox':
        return (
          <>
            <div className="lg:w-72">
              <div className="grid grid-cols-2 gap-1">
                {data.options.map((option, index) => (
                  <div>
                    <input
                      type="checkbox"
                      className="mr-2"
                      name={`option${index}`}
                      id={`option${index}`}
                      value={option}
                      disabled={true}
                    />
                    <label className="details-data-style-common">{getOptionLabel(option)}</label>
                  </div>
                ))}
              </div>
            </div>
          </>
        );

      case 'attachment':
        return (
          <div className="rs-user-input-container">
            <TextField
              InputProps={{ disableUnderline: true }}
              placeholder="Search and select file name"
              disabled={true}
              className="lg:w-72"
            />
          </div>
        );
      case 'textEditor':
        return (
          <>
            <RichTextEditor convertedContent="" readOnly={true} toolbarHidden={false} className="w-full" />
          </>
        );

      default:
        return (
          <>
            <TextField disabled={true} className="w-80" />
          </>
        );
    }
  };
  const testCaseDetailElements = (props) => {
    return (
      <>
        <div className="flex flex-col justify-center">
          <div className="">
            <h6 className="fontPoppinsMediumMd">
              {props.data.mandatory === 'yes' ? <span className="text-red-400 mr-1">&#42;</span> : null}
              {getLabel(props.data.label)}
            </h6>
          </div>
          <div className="flex">
            <div className="contentDataStyleMTC">{testCaseDetailDescElements(props.data)}</div>
            <div className={props.data.type !== 'textEditor' ? ' flex ml-3' : ' flex mt-5'}>
              {!['Test Case Name', 'Requirement Id', 'Status', 'Labels'].includes(props.data.label) &&
              mode !== 'view' ? (
                <>
                  {
                    <>
                      <button
                        type="button"
                        onClick={(e) => {
                          onEditFieldHandler(e, props.data);
                        }}
                      >
                        <Tooltip title="Edit Field" placement="top">
                          <EditOutlinedIcon className="iconStyle cursor-pointer mt-3 hover:text-blue-700" />
                        </Tooltip>
                      </button>

                      <button type="button" className="ml-3">
                        <Tooltip title="Remove Field" placement="top">
                          <CloseIcon
                            className="iconStyle cursor-pointer mt-3 hover:text-red-700"
                            onClick={() => removeTestDetailsData(props?.data)}
                          />
                        </Tooltip>
                      </button>
                    </>
                  }
                </>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  };

  const testCaseDetailSection = () => {
    return (
      <>
        <div className="mt-3 mx-3 my-5 border">
          <div className="w-90v responsiveTestDetails">
            <Accordion defaultExpanded={true} className="">
              <div className="h-auto backgroundColor">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="cursor-pointer" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="flex-auto project_label_TCM fontPoppinsSemiboldLg">Test Case Details</div>
                </AccordionSummary>
              </div>
              <AccordionDetails className="h-auto backgroundColor">
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} className="mb-7">
                  {TemplateData.testCaseDetails.map((data) =>
                    data?.type === 'textEditor' ? (
                      <Grid item xs={12} sm={12} md={12}>
                        {testCaseDetailElements({ data })}
                      </Grid>
                    ) : (
                      <Grid item xs={2} sm={4} md={4}>
                        {testCaseDetailElements({ data })}
                      </Grid>
                    )
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </>
    );
  };

  const tsHeaderChangeHandler = (e, index) => {
    let tempTemplateData = TemplateData;
    let headerValue = e.target.textContent.toString();
    if (e.target.textContent === 'Status') {
      tempTemplateData.testSteps.headers[index] = '';
      MyAlert.warning('Creating column name with either Status or Actual Result is Not Allowed');
      setAlertWait(false);
      setTimeout(() => {
        setAlertWait(true);
      }, 5000);
      setTemplateData({ ...tempTemplateData });
    } else if (e.target.textContent === '') {
      tempTemplateData.testSteps.headers[index] = headerValue;
      MyAlert.warning('Creating column name with empty name is Not Allowed');
      setAlertWait(false);
      setTimeout(() => {
        setAlertWait(true);
      }, 5000);
      setTemplateData(tempTemplateData);
    } else if (e.target.textContent === 'Actual Result') {
      tempTemplateData.testSteps.headers[index] = '';
      MyAlert.warning('Creating column name with Actual Result is Not Allowed');
      setAlertWait(false);
      setTimeout(() => {
        setAlertWait(true);
      }, 5000);
      setTemplateData({ ...tempTemplateData });
    } else {
      tempTemplateData.testSteps.headers[index] = headerValue;
      setTemplateData(tempTemplateData);
    }
  };

  const tsHeaderBlurHandler = (e, index) => {
    let tempTemplateData = TemplateData;
    if (e.target.textContent === 'Status') {
      tempTemplateData.testSteps.headers[index] = '';
      e.target.textContent = '';
      MyAlert.warning('Creating column name with either Status or Actual Result is Not Allowed');
      setAlertWait(false);
      setTimeout(() => {
        setAlertWait(true);
      }, 5000);
      setTemplateData({ ...tempTemplateData });
    } else if (e.target.textContent === 'Actual Result') {
      tempTemplateData.testSteps.headers[index] = '';
      e.target.textContent = '';
      MyAlert.warning('Creating column name with Actual Result is Not Allowed');
      setAlertWait(false);
      setTimeout(() => {
        setAlertWait(true);
      }, 5000);
      setTemplateData({ ...tempTemplateData });
    }
  };

  const handelDropdown = () => {
    setShowOptions(!showOptions);
  };

  const addColDDTS = (index) => {
    let tempTemplateData = TemplateData;
    tempTemplateData.testSteps.headers.splice(index, 0, 'NewColumn');
    setTemplateData({ ...tempTemplateData });
    handelDropdown();
  };

  const deleteColDDTS = (index) => {
    let tempTemplateData = TemplateData;
    tempTemplateData.testSteps.headers.splice(index, 1);
    setTemplateData({ ...tempTemplateData });
    handelDropdown();
  };

  const testStepsDropdown = (index, data) => {
    return (
      <>
        {showOptions && rowId === index && (
          <>
            <div
              className="shadow-none origin-top-right absolute mt-2 w-40 
                            rounded-md bg-white ring-1 ring-black ring-opacity-5 
                            focus:outline-none z-50 -right-14"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabIndex="-1"
              ref={menuRef}
            >
              <div className="my-1" contentEditable={false}>
                <div
                  className="cursor-pointer hover:bg-gray-200 p-1 fontPoppinsRegularMd text-left"
                  onClick={() => addColDDTS(index)}
                >
                  <span className="ml-2">Insert column left</span>
                </div>
                <div
                  className="cursor-pointer hover:bg-gray-200 p-1 fontPoppinsRegularMd text-left"
                  onClick={() => addColDDTS(index + 1)}
                >
                  <span className="ml-2">Insert column right</span>
                </div>
                {[
                  'Test Steps',
                  'Input',
                  'Expected Result',
                  'Actual Result',
                  'Status',
                  'Comment',
                  'Attachment',
                ].includes(data) ? null : (
                  <div
                    className="cursor-pointer hover:bg-gray-200 p-1 fontPoppinsRegularMd text-left "
                    onClick={() => deleteColDDTS(index)}
                  >
                    <span className="ml-2">delete column</span>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  const testStepsTable = (view) => {
    return (
      <>
        <div className="mx-2.5">
          <div className=" mt-3 my-5">
            <div className="shadow">
              {view === 'pageView' && (
                <div className="flex justify-between h-16">
                  <div className="mx-4 my-5">
                    <h3 className="project_label_TCM fontPoppinsSemiboldLg text-sm flex-auto">Test Steps</h3>
                  </div>
                  <div className="flex justify-evenly mx-4 my-5">
                    <div className="mx-2 cursor-pointer">
                      <FiMaximize2
                        className=" text-lg mt-2 text-blue-800"
                        onClick={() => {
                          setOpenTSModal(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {mode === 'view' && (
                <div className="flex justify-between h-16">
                  <div className="mx-4 my-5">
                    <h3 className="project_label_TCM fontPoppinsSemiboldLg text-sm flex-auto">Test Steps</h3>
                  </div>
                  <div className="flex justify-evenly mx-4 my-5">
                    <div className="mx-2 cursor-pointer">
                      <FiMaximize2 className=" text-lg mt-2 text-gray-400 cursor-default" disabled={true} />
                    </div>
                  </div>
                </div>
              )}
              <div className="w-auto overflow-scroll " id="journal-scroll">
                <table className="testStepTable">
                  <thead className="bg-gray-100">
                    {TemplateData.testSteps.headers.map((data, index) => {
                      return (
                        <>
                          <th className={'p-2 testStepTable'}>
                            <div className="flex justify-evenly break-words">
                              <div
                                contentEditable={
                                  ['Actual Result', 'Status', 'Comment', 'Attachment'].includes(data) ||
                                  view === 'modalView' ||
                                  !alertWait
                                    ? false
                                    : true
                                }
                                className={
                                  ['Actual Result', 'Status', 'Comment', 'Attachment'].includes(data) ||
                                  view === 'modalView'
                                    ? 'focus:outline-none focus:border-opacity-0 w-4/5 text-left fontPoppinsMediumMd mt-2'
                                    : ' border-b border-blue-700 focus:outline-none w-4/5 text-left fontPoppinsMediumMd mt-2'
                                }
                                onInput={(e) => tsHeaderChangeHandler(e, index)}
                                onBlur={(e) => tsHeaderBlurHandler(e, index)}
                                suppressContentEditableWarning={true}
                              >
                                {data}
                              </div>
                              {mode !== 'view' && view !== 'modalView' && (
                                <div contentEditable={false} className="w-1/5  relative">
                                  <button
                                    className="mt-2"
                                    onClick={() => {
                                      handelDropdown();
                                      setRowId(index);
                                    }}
                                  >
                                    <MoreVertIcon fontSize="small" className=" text-blue-800 -mt-1" />
                                    {view !== 'modalView' && testStepsDropdown(index, data)}
                                  </button>
                                </div>
                              )}
                            </div>
                          </th>
                          <ColumnResizer minWidth={120} className="columnResizer" />
                        </>
                      );
                    })}
                  </thead>
                  <tbody>
                    {Array.from(Array(tsInitialRow)).map((data) => (
                      <tr
                        className="h-9 testStepTable"
                        contentEditable={
                          data === 'Status' || data === 'Actual Result' || view === 'modalView' || view === 'pageView'
                            ? false
                            : true
                        }
                      >
                        {Array.from(Array(TemplateData.testSteps.headers.length)).map((data) => (
                          <>
                            {' '}
                            <td className="testStepTable"></td>
                            <ColumnResizer minWidth={120} className="columnResizer" />
                          </>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const templateNameValidation = () => {
    if (TemplateData.name.length >= 50 || TemplateData.name.length < 2) {
      setShowErrorMsg('Name must be in between 2 to 50 characters');
      return true;
    }
    if (TemplateData.name.length !== TemplateData.name.trim().length) {
      setShowErrorMsg('Name must not contain space at beginning or at the end');
      return true;
    }
    if (!checkValidationForAlphanumeric.test(TemplateData.name)) {
      setShowErrorMsg('Name must not contain special characters');
      return true;
    }
    return false;
  };

  const createTemplate = (e) => {
    if (templateNameValidation()) {
      return;
    }
    const hasEmpty = checkEmptyColumnOrSpaces(TemplateData.testSteps.headers);
    if (hasEmpty) {
      MyAlert.info(`Column names cannot be empty`);
      return;
    }
    const hasDuplicates = checkDuplicateColumns(TemplateData.testSteps.headers);
    if (hasDuplicates) {
      MyAlert.info(`Table has duplicate columns, column names should be unique`);
      return;
    }
    const hasInvalidHeader = checkAlphanumericTableHeader(TemplateData.testSteps.headers);
    if (hasInvalidHeader) {
      MyAlert.info(`Test Steps table headers should be alphanumeric`);
      return;
    }
    const createTemplateData = trimSpaces();
    createTemplateRequest(createTemplateData)
      .then((results) => {
        const tempName = results.data?.responseObject?.name;
        if (results.data && results.data.responseObject && results.data.responseCode === 200) {
          history.push(`/configuration/template?actiont=create&tempName=${tempName}`);
          const templateId = results.data.responseObject.id;
        } else if (
          results.data &&
          results.data.responseCode === 400 &&
          results.data.message.includes('already exists')
        ) {
          MyAlert.warning(`${results.data.message}`);
        } else if (
          results.data &&
          results.data.responseCode === 400 &&
          results.data.message.includes('Request Validation Failed')
        ) {
          MyAlert.warning(`${results.data.details}`);
        } else {
          setErrorContent(results.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error('Create Template Error', error.response.message);
        }
      });
  };

  const checkDuplicateColumns = (a) => {
    let b = Array.from(new Set(a.map((e) => e.toLowerCase().trim())));
    return a.length !== b.length;
  };
  const checkEmptyColumnOrSpaces = (a) => {
    let hasEmpty = false;
    for (let str of a) {
      if (str.trim().length === 0) {
        hasEmpty = true;
        break;
      }
    }
    return hasEmpty;
  };
  const checkAlphanumericTableHeader = (a) => {
    let hasInvalidTableHeader = false;
    for (let str of a) {
      if (!checkValidationForAlphanumeric.test(str.trim())) {
        hasInvalidTableHeader = true;
        break;
      }
    }
    return hasInvalidTableHeader;
  };
  const trimSpaces = () => {
    const templateDataCopy = { ...TemplateData };
    templateDataCopy.testSteps.headers = templateDataCopy.testSteps.headers.map((val) => val.trim());
    setTemplateData(templateDataCopy);
    return templateDataCopy;
  };
  const updateTemplate = (e) => {
    if (templateNameValidation()) {
      return;
    }
    if (JSON.stringify(editData.data) === JSON.stringify(TemplateData)) {
      MyAlert.info(`No Changes were done to the ${TemplateData.name} template`);
      return;
    }
    const hasEmpty = checkEmptyColumnOrSpaces(TemplateData.testSteps.headers);
    if (hasEmpty) {
      MyAlert.info(`Column names cannot be empty`);
      return;
    }

    const hasDuplicates = checkDuplicateColumns(TemplateData.testSteps.headers);
    if (hasDuplicates) {
      MyAlert.info(`Table has duplicate columns, column names should be unique`);
      return;
    }
    const hasInvalidHeader = checkAlphanumericTableHeader(TemplateData.testSteps.headers);
    if (hasInvalidHeader) {
      MyAlert.info(`Test Steps table headers should be alphanumeric`);
      return;
    }
    const updateTemplateData = trimSpaces();

    updateTemplateRequest(templateId, updateTemplateData)
      .then((results) => {
        const tempName = results.data?.responseObject?.name;
        if (results.data && results.data.responseObject && results.data.responseCode === 200) {
          history.push(`/configuration/template?actiont=edit&tempName=${tempName}`);
          const templateId = results.data.responseObject.id;
        } else if (
          results.data &&
          results.data.responseCode === 400 &&
          results.data.message.includes('Resource with name')
        ) {
          MyAlert.warning(`${results.data.message}`);
        } else {
          setErrorContent(results.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error('Update Template Error', error.response.message);
        }
      });
  };

  const onAddFieldHandler = () => {
    setOpenAccountModal(true);
  };

  const onEditFieldHandler = (e, data) => {
    setEditFieldValue(data);
    setOpenAccountModal(true);
  };

  const onAddTSColumnHandler = () => {
    setOpenTSColumnModal(true);
  };

  const setselectedCFvalue = (value) => {
    setSelectedCF(value);
  };

  const addColValue = (value) => {
    const colLength = TemplateData.testSteps.headers.length;
    let tempTemplateData = TemplateData;
    {
      Array.from(Array(value)).map((data, i) => tempTemplateData.testSteps.headers.push(`Column${i + 1}`));
    }
    setTemplateData(tempTemplateData);
  };

  const addFormField = (value) => {
    if (Object.keys(editFieldValue).length !== 0) {
      let tempTemplateData = TemplateData;
      const index = tempTemplateData.testCaseDetails.findIndex((x) => x.order === editFieldValue.order);
      const indexD = tempTemplateData.testCaseDetails.findIndex(
        (x) => x.label.toLowerCase() === value.label.toLowerCase()
      );
      if (indexD === -1 || indexD === index) {
        value.order = editFieldValue?.order;
        tempTemplateData.testCaseDetails[index] = value;
        setTemplateData(tempTemplateData);
        MyAlert.success(
          `In Test Case Details field with ${value.label.length > 15 ? value.label.substr(0, 15) + '...' : value.label} label updated successfully`
        );
        setEditFieldValue({});
      } else {
        MyAlert.warning(
          `In Test Case Details field with ${value.label.length > 15 ? value.label.substr(0, 15) + '...' : value.label} label already exists`
        );
      }
    } else {
      value.order = TemplateData.testCaseDetails.length + 1 + '';
      let tempTemplateData = TemplateData;
      const index = tempTemplateData.testCaseDetails.findIndex(
        (x) => x.label === value.label || x.label.toUpperCase() === value.label.toUpperCase()
      );
      if (index === -1) {
        tempTemplateData.testCaseDetails.push(value);
        MyAlert.success(
          `In Test Case Details field with ${value.label.length > 15 ? value.label.substr(0, 15) + '...' : value.label} label added successfully`
        );
      } else {
        MyAlert.warning(
          `In Test Case Details field with ${value.label.length > 15 ? value.label.substr(0, 15) + '...' : value.label} label already exists`
        );
      }
      setTemplateData(tempTemplateData);
    }
  };

  const tsStepModel = () => {
    return (
      <>
        <Modal open={openTSModal} className="modalBody_Expand">
          <div className="modal_container browserStack_check_modal">
            <div className="modal_header">
              <h2 className="title"> Test Steps</h2>

              <IconButton
                color="primary"
                component="span"
                className="close"
                onClick={() => {
                  setOpenTSModal(false);
                }}
              >
                <img
                  className="cursor-pointer"
                  src="/assets/images/close_black.svg"
                  alt=""
                  height="25px"
                  width="25px"
                />{' '}
              </IconButton>
            </div>
            <div className="modal_body">{testStepsTable('modalView')}</div>
          </div>
        </Modal>
      </>
    );
  };

  const isEditable = React.useMemo(() => window.permission?.isEditAccess('configuration'), []);

  return (
    <div className="page-list-body">
      <div className="page-table-body mt-4 w-full" id="journal-scroll">
        <>
          <div className="content_area_style_TCM" id="journal-scroll">
            <div className="content_area_style_tcTemplate">
              <div className="alert-position-style">
                <AlertContatiner />
              </div>
              <div>
                <div className="border_style flex  items-center border-b-2">
                  {mode === 'create' ? (
                    <label className="project_label text-sm flex-auto ">Create Manual Test Case Template</label>
                  ) : mode === 'edit' ? (
                    <label className="project_label text-sm flex-auto ">
                      Edit Manual Test Case Template - {templateName}
                    </label>
                  ) : (
                    <label className="project_label text-sm flex-auto ">
                      Manual Test Case Template Details - {templateName}
                    </label>
                  )}

                  <div className="pr-4">
                    <div className="flex flex-row ml-32">
                      {mode === 'view' ? (
                        <>
                          <button
                            className="secondary-btn"
                            onClick={() => {
                              history.push(`/configuration/template`);
                            }}
                          >
                            Back
                          </button>
                          {isEditable && (
                            <button
                              className="primary-btn ml-5"
                              onClick={() => {
                                history.push(
                                  `/configuration/template/templatecreation?mode=edit`,
                                  JSON.stringify({ data: TemplateData })
                                );
                              }}
                              disabled={
                                TemplateData?.templateType === 'Predefined' ||
                                (!TemplateData.hasOwnProperty('projectId') && projectId)
                              }
                            >
                              Edit
                            </button>
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                  <br />
                  <br />
                  <hr className="dividerLine" />
                </div>
                <div className="flex justify-between mt-4 px-4">
                  <div className="rs-user-input-container">
                    <MyInput
                      error={formikDetails.errors.templateName && formikDetails.touched.templateName}
                      name="templateName"
                      id="templateName"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onBlur={addNameField}
                      onInput={addNameField}
                      disabled={mode === 'view' ? true : false}
                      value={templateName}
                      className=" w-60"
                      type="text"
                      autoComplete="off"
                      label={
                        <>
                          <div className="">
                            <span className="text-red-400 mr-1">&#42;</span>{' '}
                            <span className="text-lg" style={{ fontFamily: 'Poppins-Regular' }}>
                              Template Name
                            </span>
                          </div>
                        </>
                      }
                      helperText={
                        <div className="fontPoppinsRegularSm absolute">
                          {showErrorMsg && <p className="errorMessage fontPoppinsRegularSm fixed">{showErrorMsg}</p>}
                        </div>
                      }
                    />
                  </div>
                  <div className=" float-right">
                    {mode !== 'view' ? (
                      <button className="primary-btn ml-4" onClick={onAddFieldHandler}>
                        + Fields
                      </button>
                    ) : null}
                  </div>
                </div>
                <hr className=" ml-3 my-4 w-90v" />
              </div>
              <div className="tab-height content-height">
                {testCaseDetailSection()}
                {openTSModal && tsStepModel()}
                {mode === 'view' ? testStepsTable('modalView') : testStepsTable('pageView')}
              </div>

              {mode !== 'view' && (
                <div className="border_style flex justify-end  items-center border-t-2">
                  <div className="flex flex-row mr-2 my-3">
                    {mode !== 'view' && mode === 'edit' ? (
                      <button
                        className="secondary-btn mr-4"
                        onClick={() => {
                          history.push(`/configuration/template`);
                        }}
                      >
                        Cancel
                      </button>
                    ) : mode !== 'view' && mode === 'create' ? (
                      <button className="secondary-btn mr-4" onClick={() => setOpenDataLossModal(true)}>
                        Cancel
                      </button>
                    ) : null}

                    {mode === 'create' ? (
                      <button className="primary-btn ml-4" onClick={createTemplate}>
                        Create
                      </button>
                    ) : mode === 'edit' ? (
                      <button className="primary-btn ml-4" onClick={updateTemplate}>
                        Update
                      </button>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          </div>

          {openAccountModal && (
            <FieldForm
              closeModal={closeModal}
              selectedCF={setselectedCFvalue}
              addFormField={addFormField}
              editFieldValue={editFieldValue}
              data={TemplateData}
              source="tcm"
            />
          )}
          {openTSColumnModal && <TSAddColumn addColValue={addColValue} closeModal={closeModal} />}
          {openDataLossModal && (
            <CancelCreateTemp
              openCancelCreateTemp
              handleCloseForCancelCreateTemp={() => {
                setOpenDataLossModal(false);
              }}
            />
          )}
        </>
      </div>
    </div>
  );
}

import ProgressBarStyle from './progress-bar.module.scss';
import { colors } from '@src/css_config/colorConstants';
import cx from 'classnames';

const ProgressBar = (props) => {
  const { total = 100, filled = 0, filledColor = colors?.rs_primary, showPrecent = false } = props;

  let widthToFill = (100 * Number(filled)) / Number(total);

  return (
    <div className={ProgressBarStyle['progress-line-wrapper']}>
      <div
        className={cx(ProgressBarStyle['filled'], { 'flex justify-end items-end': showPrecent })}
        style={{
          height: '8px',
          width: `${widthToFill > 100 ? 100 : widthToFill}%`,
          backgroundColor: filledColor,
        }}
      >
        {showPrecent && <span className={cx(ProgressBarStyle['filled-value-circle'])}>{widthToFill}%</span>}
      </div>
    </div>
  );
};

export default ProgressBar;

import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Link, withRouter } from 'react-router-dom';
import { useAlert } from '../../../common/alert_service/useAlert';
import * as Yup from 'yup';
import { SearchIcon } from '@heroicons/react/solid';
import '../../../common/dropdown.css';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '../../../common/breadcrumbs';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import HomeIcon from '@material-ui/icons/Home';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { MdEdit } from 'react-icons/md';
import { AiOutlineDelete } from 'react-icons/ai';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import '../../ElementConfig/elementConfig.css';
import Box from '@mui/material/Box';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@mui/material/Modal';
import { useFormik } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getAndroidLocators, putAndroidLocators } from '../../../../api/api_services';

import { ExclamationIcon, XIcon } from '@heroicons/react/outline';

// let useClickOutside=(handeler)=>{
//     // if click outside of dropdown also it will close
//   let domNode=useRef()
//   useEffect(() => {
//     let mayBehandeler=(event)=>{
//       if( !domNode.current.contains(event.target.value) ){
//         handeler()

//       }
//     };
//     document.addEventListener("mousedown",mayBehandeler)
//     return () => {
//       document.addEventListener("mousedown",mayBehandeler)
//     }
//   });
//   return domNode

// }

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
    color: 'rgb(22,72,198)',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 44,
    height: 20,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(23px)',
      color: 'blue',
      '& + $track': {
        backgroundColor: '#fff',
        opacity: 1,
        border: '1px solid #0437F2',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '1px solid #0437F2',
    },
  },
  thumb: {
    width: 17,
    height: 17,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid gray`,
    backgroundColor: theme.palette.grey[500],

    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const Locator_android = (props) => {
  const formik = useFormik({
    initialValues: {
      name: 'Element name',
    },
    validationSchema: Yup.object({
      name: Yup.string()

        .required('Element type name is required'),
    }),
    onSubmit: (values) => {
      // alert(JSON.stringify(props,null,2))
    },
  });

  const [state, setState] = React.useState({
    checkedB: true,
  });

  const handleChange = (event, index) => {
    setWebLoc((webLoc) => webLoc.map((v, i) => (i == index ? { ...v, isEnabled: !v.isEnabled } : v)));
  };

  const [showOptions, setShowOptions] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [checked, setChecked] = React.useState(true);
  const [openSwitch, setOpenSwitch] = React.useState(true);
  const [webLoc, setWebLoc] = React.useState([]);
  const [getDltData, setGetDltData] = useState();

  const [groupID, setGroupID] = React.useState({});
  const [enab, setEnab] = React.useState();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEditOpen = () => setOpenEdit(true);
  const handleEditClose = () => setOpenEdit(false);

  const handleDeleteOpen = () => setOpenDelete(true);
  const handleDeleteClose = () => setOpenDelete(false);

  const { AlertContatiner, MyAlert } = useAlert();
  let activeTab = props.location.pathname.split('/')[3];

  const handelDropdown = () => {
    setShowOptions(!showOptions);
  };

  //   let domNode=useClickOutside(()=>{
  //     setShowOptions(false)
  //   })

  const toggle = () => {
    setChecked(false);
  };

  // if click outside of dropdown also it will close
  let menuRef = useRef();
  useEffect(() => {
    let handeler = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };
    document.addEventListener('mousedown', handeler);
    return () => {
      document.addEventListener('mousedown', handeler);
    };
  }, []);

  const [tabsList, setTabsList] = useState([
    {
      name: 'Element Type',

      pathUrl: `/configuration/ElementConfiguration/ElementType`,
      selectedName: 'ElementType',
    },

    {
      name: 'Locators',
      pathUrl: `/configuration/ElementConfiguration/ElementType/Locators`,
      selectedName: 'History',
    },
    {
      name: 'History',
      pathUrl: `/configuration/ElementConfiguration/ElementType/Locators/History`,
      selectedName: 'History',
    },
  ]);

  const renderTabs = () => {
    return (
      <div>
        <ul className="navBar text-blue-600 flex flex-row primary-tab">
          {tabsList.map((tab, index) => {
            return (
              <li key={tab.name}>
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    props.history.push(tab.pathUrl);
                  }}
                  className={`${activeTab === tab.selectedName ? 'selected_tab_style' : ''}`}
                >
                  {tab.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };
  const classes = useStyles();

  let projectId = JSON.parse(localStorage.getItem('selected-project')).id;
  React.useEffect(() => {
    const search = window.location.search;
    let projectId = new URLSearchParams(search).get('id') || JSON.parse(localStorage.getItem('selected-project'));

    if (new URLSearchParams(search).get('id')) {
      projectId = new URLSearchParams(search).get('id');
    } else {
      projectId = JSON.parse(localStorage.getItem('selected-project')).id;
    }

    if (projectId) {
      getAndroidLocators(projectId).then((results) => {
        localStorage.setItem('configReq', false);
        if (results && results.data && results.data.responseObject) {
          setWebLoc(results.data.responseObject);
        }
      });
    } else {
      localStorage.setItem('configReq', true);
      getAndroidLocators().then((results) => {
        localStorage.setItem('configReq', false);
        if (results && results.data && results.data.responseObject) {
          setWebLoc(results.data.responseObject);
          setEnab(results.data.responseObject.isEnabled);
        }

        //  console.log(expUnit,"checking-----");
      });
    }
  }, [projectId, getDltData]);

  // update
  const selectUser = (id) => {
    webLoc.map((res, index) => {
      if (res.id === id) {
        setEnab(res.isEnabled);
      }
    });
  };
  const updateVal = () => {
    // update
    localStorage.setItem('resultGetReq', true);
    let toggleBtn = document.getElementById('toggleBtn').value;

    let data = {
      isEnabled: !enab,
    };
    if (projectId) {
      putAndroidLocators(groupID.id, data, projectId)
        .then((res) => {
          localStorage.setItem('resultGetReq', false);

          if (res.data.responseCode === 200) {
            MyAlert.success(`${groupID.locatorType} updated successfully`);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      localStorage.setItem('resultGetReq', true);
      putAndroidLocators(groupID.id, data, projectId)
        .then((res) => {
          localStorage.setItem('resultGetReq', false);
          // setGetDltData(res.data)

          if (res.data.responseCode === 200) {
            MyAlert.success(`${groupID.locatorType} updated successfully`);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="page-container">
      <div className="flex ">
        <div ref={menuRef} className="relative inline-block text-left repo-dev-tab-height">
          <div>
            <span className=" text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center">
              <Link to="/configuration/ElementConfiguration/ElementType/Web" className="nonSelectedElm -ml-4">
                Element Type
              </Link>

              <Link className="mx-3 locatorSelectedElm" onClick={handelDropdown}>
                Locators
              </Link>
              <ArrowDropDownIcon
                onClick={handelDropdown}
                className="fill-current h-7 w-11 "
                style={{ marginLeft: '-26px' }}
              />
              <Link to="" className="mx-3 nonSelectedElm">
                History
              </Link>
            </span>
          </div>
          {showOptions && (
            <div
              style={{ width: '100px', marginTop: '-5px', marginLeft: '6.5rem' }}
              className="origin-top-left absolute right-70 mt-2 w-75 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabindex="-1"
            >
              <div className="py-1 fontPoppinsRegularMd" role="none">
                <Link
                  to="/configuration/ElementConfiguration/Locators/web"
                  className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-0"
                >
                  Web
                </Link>
                <Link
                  onClick={() => {
                    setShowOptions(false);
                  }}
                  to="/configuration/ElementConfiguration/Locators/android"
                  className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-1"
                >
                  Android
                </Link>
                <Link
                  to="/configuration/ElementConfiguration/Locators/ios"
                  className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-2"
                >
                  iOS
                </Link>
                <Link
                  to="/configuration/ElementConfiguration/Locators/Sfdc"
                  className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-2"
                >
                  Salesforce
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="page-list-body">
        <div className="alert-position-style">
          <AlertContatiner></AlertContatiner>
        </div>

        <div className="page-table-body">
          <div className="content_area_style" id="journal-scroll">
            <div className="grid grid-cols-2 mb-4 ">
              <span style={{ marginLeft: '2rem' }} className=" ml-3 mt-4 text-sm flex-auto configPageHeading">
                Android
              </span>
            </div>

            <div
              className=" grid grid-cols-6 "
              style={{
                // background: "rgb(240,243,249)",
                display: 'flex',
              }}
            >
              <table>
                <thead
                  style={{
                    background: 'rgb(240,243,249)',
                    textAlign: 'left',
                    padding: '0.8rem',
                    height: '40px',
                    fontFamily: 'Poppins-SemiBold',
                  }}
                >
                  <tr>
                    <th className="text-sm" style={{ width: '70px' }}></th>
                    <th className="text-sm" style={{ width: '130px' }}>
                      Sl No
                    </th>
                    <th className="text-sm" style={{ width: '13rem' }}>
                      Locator Name
                    </th>
                    <th className="text-sm" style={{ width: '62rem' }}>
                      Enabled
                    </th>
                  </tr>
                </thead>
                <tbody style={{ textAlign: 'left', fontFamily: 'Poppins-Regular' }}>
                  {webLoc.map((values, index) => {
                    return (
                      <tr className="hover:bg-gray-100 visibleData" style={{ height: '50px' }}>
                        <td className="text-blue-700 ">
                          <span className="drag">
                            <DragIndicatorIcon />
                          </span>
                        </td>
                        <td className="text-gray-700">{index + 1}</td>
                        <td className="text-gray-700">{values.locatorType}</td>
                        <td>
                          <div
                            className="switch1 mx-5.5 w-20 h-10"
                            onClick={() => {
                              setGroupID(values);
                              selectUser(values.id);
                            }}
                          >
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  checked={values.isEnabled}
                                  id="toggleBtn"
                                  onChange={(e) => {
                                    handleChange(values, index);
                                    updateVal();
                                  }}
                                  name={'checkedB' + index}
                                  value={enab}
                                />
                              }
                            />
                          </div>
                        </td>
                        <td></td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default withRouter(Locator_android);

import React, { useState, useEffect } from 'react';
import { lineChartDateFormat } from '@common/ui-constants';
import { isEmptyValue } from '@src/util/common_utils';
import DefectsCycleChartYearMonths from '@pages/common/charts/defectsCycleChartYearMonths';
import DefectsCycleChartSevendays from '@src/pages/common/charts/defectsCycleChartSevendays';
import dateFormat from 'dateformat';

const DefectsCycleTime = (props) => {
  let initSeriesvalue = [
    { name: 'Total Defects', data: [] },
    { name: 'Open Defects', data: [] },
    { name: 'Closed Defects', data: [] },
  ];
  let [finalcategories, setFinalcategories] = useState([]);
  let [finalseries, setFinalseries] = useState(initSeriesvalue);

  useEffect(() => {
    if (!isEmptyValue(props.defectsDb.dailyDefectSummary)) createLineChartData(props.defectsDb.dailyDefectSummary);
  }, [props.defectsDb]);

  async function createLineChartData(summaryData) {
    let categories = [];
    let series = JSON.parse(JSON.stringify(initSeriesvalue));

    let i, j;
    for (i = 0; i < summaryData?.length; i++) {
      categories.push(dateFormat(summaryData[i]?.resultName, lineChartDateFormat));

      for (j = 0; j < 3; j++) {
        if (summaryData[i]?.resultItems[j]?.name === 'Total') {
          series[0].data.push(summaryData[i]?.resultItems[j]?.count);
        } else if (summaryData[i]?.resultItems[j]?.name === 'NEW') {
          series[1].data.push(summaryData[i]?.resultItems[j].count);
        } else if (summaryData[i]?.resultItems[j]?.name === 'Closed') {
          series[2].data.push(summaryData[i]?.resultItems[j]?.count);
        }
      }
    }

    let res = await getfinalCategoriesAndSeries(categories, series);
    setFinalseries(res.series);
    setFinalcategories(res.categories);
  }

  async function getfinalCategoriesAndSeries(convertedCategories, convertedSeries) {
    if (convertedCategories.length === 1) {
      let oneDayCategories = ['', convertedCategories[0]];
      let oneDaySeriesCycle = [
        { name: 'Total Defects', data: [0, ...convertedSeries[0].data] },
        { name: 'Open Defects', data: [0, ...convertedSeries[1].data] },
        { name: 'Closed Defects', data: [0, ...convertedSeries[2].data] },
      ];
      return { categories: oneDayCategories, series: oneDaySeriesCycle };
    } else {
      return { categories: convertedCategories, series: convertedSeries };
    }
  }

  return (
    <>
      {finalcategories.length > 7 ? (
        <DefectsCycleChartYearMonths series={finalseries} categories={finalcategories} />
      ) : (
        <DefectsCycleChartSevendays series={finalseries} categories={finalcategories} showFilter={props.showFilter} />
      )}
    </>
  );
};

export default DefectsCycleTime;

import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import * as yup from 'yup';
import TemplateSelectDropdown from '@pages/test-development/script/modules/module/modals/modal-components/template-select-dropdown';
import { getAllTestCaseTemplate } from '@api/api_services';
import { useFormik } from 'formik';
import { Close } from '@mui/icons-material';

const AddManualTestCase = (props) => {
  const [openModal, setOpenModal] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [disableTemplateDropdown, setDisableTemplateDropdown] = useState(false);
  const customStylesPage = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: 'fit-content',
      background: '#fbfcfd 0% 0% no-repeat padding-box',
      boxShadow: '0px 0px 11px #000000a1',
      borderRadius: '4px',
      opacity: '1',
    },
  };

  const templateOnChange = (value) => {
    setSelectedTemplate(value);
    formikDetails.setFieldValue('templateId', value[0]._id);
  };

  let initialValues = {
    templateId: '',
  };
  const validationSchema = yup.object({
    templateId: yup.string().required('Template is required!!'),
  });
  const onSubmit = (values) => {
    if (values && values.templateId) {
      props.createManualScript(values.templateId);
    }
  };
  const formikDetails = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const getTemplates = async () => {
    try {
      const templateResponse = await getAllTestCaseTemplate('config');
      if (templateResponse?.data?.responseObject?.templates) {
        const defaultTemplateTemp = templateResponse.data.responseObject?.defaultTemplate;
        const allTemplates = templateResponse?.data?.responseObject.templates.map((temp) => {
          return { ...temp, label: temp.name };
        });
        setTemplates([...allTemplates]);
        setDisableTemplateDropdown(templateResponse.data.responseObject?.manualScriptPresent);
        if (allTemplates.length && defaultTemplateTemp) {
          templateOnChange([{ ...defaultTemplateTemp, label: defaultTemplateTemp.name }]);
        }
      } else {
        setTemplates([]);
      }
    } catch (err) {
      console.error('GET_TEMPLATES : ', err);
    }
  };
  useEffect(() => {
    getTemplates();
  }, []);

  const { isEditable, hasViewAccess, hasFullAccess } = React.useMemo(
    () => ({
      hasViewAccess: window.permission?.isViewAccess('configuration'),
      isEditable: window.permission?.isEditAccess('configuration'),
      hasFullAccess: window.permission?.isFullAccess('configuration'),
    }),
    []
  );

  return (
    <Modal
      isOpen={openModal}
      style={customStylesPage}
      className="p-px"
      onRequestClose={() => {
        setOpenModal(false);
        props.closeModal(false);
      }}
    >
      <div className="mt-2 p-1">
        <span className="Popup-header-common pl-3">
          {props?.modalHeaderLabel ? props.modalHeaderLabel : 'Would you like to add Manual Test Case ?'}
        </span>
        <div className="float-right">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="ml-5 mr-5 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <Close color="action" />
          </button>
        </div>
      </div>
      <form onSubmit={formikDetails.handleSubmit}>
        <div className="border-b border-t border-blue-100 mt-px mb-px pb-3">
          <div className="mx-6 my-3 grid grid-cols-1">
            <div className="">
              <div className="grid grid-cols-2">
                <label
                  htmlFor="templateId"
                  className={
                    formikDetails.errors.templateId && formikDetails.touched.templateId
                      ? 'text-xs text-red-500'
                      : 'input-filed-label-style-common'
                  }
                >
                  <span className="text-red-400">&#42;</span>Testcase Template
                </label>
                {selectedTemplate.length > 0 && (hasViewAccess || isEditable || hasFullAccess) && (
                  <button
                    type="button"
                    onClick={() => {
                      localStorage.setItem('selectedTemplate', JSON.stringify({ data: selectedTemplate[0] }));
                      window.open(
                        `/configuration/template/templatecreation?mode=view&templateId=${selectedTemplate[0]._id}`,
                        '_blank'
                      );
                    }}
                    className="ml-auto float-right text-blue-700 text-xs mr-9 fontPoppinsRegularMd"
                  >
                    View Template
                  </button>
                )}
              </div>
              <div className="my-2">
                <TemplateSelectDropdown
                  templateOnChange={templateOnChange}
                  options={templates}
                  value={selectedTemplate}
                  disable={disableTemplateDropdown}
                />
              </div>
              {formikDetails.errors.templateId && formikDetails.touched.templateId ? (
                <div className="text-red-500 text-xs md:mt-1">{formikDetails.errors.templateId}</div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="float-right my-3 mx-5">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="gray-btn"
          >
            Cancel
          </button>
          <button type="submit" className="primary-btn ml-3">
            Create
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddManualTestCase;

import React, { useContext, useEffect, useState } from 'react';
import MyInput from '@pagescommon/Inputs/MyInput';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { RestContext } from '@pages/test-development/script/scripts/webservice/restapi/restapi';
import '@pages/test-development/script/scripts/webservice/restapi/css/common_Webservice.scss';
import useSyncContextValues from '@src/hooks/useSyncContextValues';

export default function Bearer_token(props) {
  const context = useContext(RestContext);
  const formik = useFormik({
    initialValues: {
      ...(context?.values?.bearerTokenData || {}),
    },
    validationSchema: Yup.object({
      token: Yup.string().required('Token is required'),
    }),
  });
  useSyncContextValues(formik, context, 'bearerTokenData');
  const [tokenName, setTokenName] = useState('');
  const isEditable = React.useMemo(() => window.permission?.isEditAccess('testDevelopment'), []);
  const handleChange = (e) => {
    context.setFieldValue('authType', 'BearerToken');
    const values = { ...(context?.values?.bearerTokenData || {}), [e.target.name]: e.target.value };
    context.setFieldValue('bearerTokenData', values);
    formik.handleChange(e);
    formik.validateForm(values).then((errors) => context.setFieldValue('errors', errors));
    props.handleSaveAsSteps(false);
  };

  useEffect(() => {
    formik.validateForm().then((errors) => context.setFieldValue('errors', errors));
    return () => context.setFieldValue('currentBearerTokenValue', {});
  }, []);

  const nameOnChange = (e) => {
    const { value, data, action, name } = e.target || 'unknown';
    const values = {
      ...(context?.values?.bearerTokenData || {}),
      [name]: props.getUserInputValue(value, false, data, action),
    };
    context.setFieldValue('bearerTokenData', values);
    const values2 = { ...(context?.values?.tokenData || {}), [name]: value };
    context.setFieldValue('tokenData', values2);
    setTokenName(value);
    const currentBearerTokenValue = { ...(context.values.currentBearerTokenValue || {}), [name]: e };
    context.setFieldValue('currentBearerTokenValue', currentBearerTokenValue);
  };
  return (
    <div className="mt-4" style={{ width: '300px' }}>
      <form onSubmit={formik.handleSubmit} className="space-y-6" noValidate>
        <div>
          <div className="input-filed-label fontPoppinsMediumSm text-left" onDragStart={props.handleDragStart}>
            {' '}
            <span className="common-auth-star-symbol">*</span> Token
          </div>
          <MyInput
            canCreateVariable={isEditable}
            type="text"
            variableInput={true}
            placeholder="Enter token"
            className="my-input-style-for-authorization"
            error={formik.errors.token && formik.touched.token}
            value={tokenName ? tokenName : props.getUserInputValue(formik.values.token, true)}
            onMouseDown={formik.handleBlur}
            onChange={(e) => {
              handleChange(e);
              nameOnChange(e);
            }}
            autoComplete="off"
            name="token"
            helperText={formik.touched.token && formik.errors.token}
            currentValue={context.values.currentBearerTokenValue?.token}
            inputValue={'input'}
            {...props}
          />
        </div>
      </form>
    </div>
  );
}

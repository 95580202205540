import React, { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
function DeleteModal(props) {
  const [open, setOpenModal] = useState(true);
  let deleteMessage = `Your data will be lost. Are you sure you want to delete  ${props.message.length > 15 ? props.message.substring(0, 15) + '...' : props.message} ?`;
  if (props.tagData) {
    if (props.isResolve) {
      deleteMessage = 'Your data will be lost. Are you sure you want to resolve ' + props.message + '?';
    } else {
      deleteMessage = 'Your data will be lost. Are you sure you want to discard ' + props.message + '?';
    }
  }
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto zIndexDialog"
        open={open}
        onClose={() => {}}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block marginupper pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/4">
              <div className="inline-block">
                <div className="flex flex-start ml-2">
                  <span className="pt-5 pl-3">
                    <ExclamationIcon className="h-6 w-6 text-yellow-600 " />
                  </span>
                  <Dialog.Title as="h3" className="leading-6 ml-4 pt-3 text-gray-900">
                    <span className="fontPoppinsSemiboldLg block">Warning </span>
                    <span className="fontPoppinsMediumMd">
                      {props.tagData ? (props.isResolve ? `Resolve` : `Discard`) : `Delete`}{' '}
                    </span>
                  </Dialog.Title>
                </div>
              </div>
              <hr className="line mt-px" />
              <div className="fontPoppinsMediumMd mt-3 text-gray-500 ml-16 mr-8">
                <p className="" title={props.message.length > 15 && props.message} style={{ wordWrap: 'break-word' }}>
                  {deleteMessage}
                </p>
                <p className="mt-2"> How do you want to proceed?</p>
              </div>
              <hr className="line mt-3" />
              <div className="mt-2 pb-2 flex flex-row float-right">
                <button
                  type="button"
                  className="mt-3 gray-btn ml-1"
                  onClick={() => {
                    props.method();
                  }}
                >
                  {props.tagData ? (props.isResolve ? `Resolve` : `Discard`) : `Delete`}
                </button>
                <button
                  type="button"
                  className="mt-3 ml-4 primary-btn "
                  onClick={() => {
                    setOpenModal(false);
                    props.closeModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default DeleteModal;

import React from 'react';
import { ReactComponent as KeywordNotFoundIcon } from '@assets/folder_not_found.svg';
import PropTypes from 'prop-types';

const DataNotAvailable = (props) => {
  return (
    <div className={`flex flex-col justify-center items-center ${props.className}`}>
      <div className=" mt-4 mb-2">
        <KeywordNotFoundIcon />
      </div>
      <div className="fontPoppinsMediumLg rs-blue">{props.message}</div>
    </div>
  );
};

DataNotAvailable.propTypes = {
  className: PropTypes.string,
};

export default DataNotAvailable;

import React, { useMemo } from 'react';
import styles from './licensedetails.module.scss';
import { ReactComponent as FutureLicenseIcon } from '@assets/svg-imports/license2.0_grey.svg';
import { ReactComponent as CloseIcon } from '@assets/analytics/ShrinkIconWhite.svg';
import cx from 'classnames';
import FeaturesTree from './FeaturesTree';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getLicenseFeatures, showOnlyDate } from '@src/util/licenseUtils';
import { LICENSE_DETAILS_CONSTANTS } from '@src/common/ui-constants';
import { Tooltip } from '@mui/material';
import LicenseRowCardStyle from '@pages/licenses/styles/license-row-card.module.scss';

const UpcomingPlanDetails = (props) => {
  let { data, setUpcomingPlanDetails, setIsLicenseDetailsDrawerOpen } = props;
  let futureFeaturesList = getLicenseFeatures(data?.futureLicensePlan?.licenseFeatures);
  const { ownerDetails, userDetails } = useMemo(() => {
    const user = JSON.parse(localStorage.getItem('test-optimize-user') || '{}');
    return {
      ownerDetails: data.licenseUsers.find(({ emailId }) => emailId === data.licenseOwner) || {},
      userDetails: data.licenseUsers.find(({ emailId }) => user.userName === emailId) || {},
    };
  }, [data]);
  return (
    <section className={styles.licenseDetails}>
      <div className={styles.fixed}>
        <div className={styles.header}>
          <div className="flex items-center gap-2">
            <ArrowBackIcon
              fontSize=""
              className="cursor-pointer"
              onClick={() => {
                setUpcomingPlanDetails(false);
                setIsLicenseDetailsDrawerOpen(true);
              }}
            />
            <p className={styles.headerTitle}>{LICENSE_DETAILS_CONSTANTS?.UPCOMING_PLAN_DETAILS}</p>
          </div>
          <div
            onClick={() => {
              setUpcomingPlanDetails(false);
            }}
            className={styles.closeIcon}
          >
            <CloseIcon className="h-2 2-2" />
          </div>
        </div>
        <div className={styles.licenseNameBlock}>
          <div className={styles.leftBlock}>
            <FutureLicenseIcon className="h-3 w-3" />
            <p className="fontPoppinsRegularSm">{LICENSE_DETAILS_CONSTANTS?.LICENSE_NAME}</p>
          </div>
          <div className={styles.rightBlock}>
            <Tooltip
              fontSize="fontPoppinsSemiboldXs"
              placement="left"
              title={data?.licenseName}
              classes={{
                popper: cx(LicenseRowCardStyle['ToolTipRenderer']),
              }}
            >
              <span className="fontPoppinsSemiboldSm">
                {data?.licenseName?.length > 30 ? data?.licenseName?.slice(0, 30) + '...' : data?.licenseName}
              </span>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={cx(styles.detailsBlock)}>
        <div className={styles.privilegeBlock}>
          <div className={styles.leftBlock}>
            <span className="fontPoppinsRegularSm">{LICENSE_DETAILS_CONSTANTS?.MY_PRIVILEGE} </span>
          </div>
          <div className={styles.rightBlock}>
            <span className="fontPoppinsSemiboldSm">{userDetails.privilege}</span>
          </div>
        </div>
        <div className={styles.privilegeBlock}>
          <div className={styles.leftBlock}>
            <span className="fontPoppinsRegularSm">{LICENSE_DETAILS_CONSTANTS?.TYPE} </span>
          </div>
          <div className={styles.rightBlock}>
            <span className="fontPoppinsSemiboldSm">{data?.licenseTypeDisplay || data?.licenseType}</span>
          </div>
        </div>
        <div className={styles.featureBlock}>
          <div className={styles.leftBlock}>
            <span className="fontPoppinsRegularSm">{LICENSE_DETAILS_CONSTANTS?.FEATURES} </span>
          </div>
          <div className={styles.rightBlock}>
            <FeaturesTree data={futureFeaturesList} disableClick={true} />
          </div>
        </div>
        <div className={styles.privilegeBlock}>
          <div className={styles.leftBlock}>
            <span className="fontPoppinsRegularSm">{LICENSE_DETAILS_CONSTANTS?.STATUS} </span>
          </div>
          <div className={styles.rightBlock}>
            <span className={cx('fontPoppinsSemiboldSm', styles.inactive)}>
              {LICENSE_DETAILS_CONSTANTS?.NOT_ACTIVE}
            </span>
          </div>
        </div>
        <div className={styles.privilegeBlock}>
          <div className={styles.leftBlock}>
            <span className="fontPoppinsRegularSm">{LICENSE_DETAILS_CONSTANTS?.PARALLEL_RUNS} </span>
          </div>
          <div className={styles.rightBlock}>
            <span className="fontPoppinsSemiboldSm">
              {props?.hasAutomation ? data?.futureLicensePlan?.numberOfParallelRuns : '--'}
            </span>
          </div>
        </div>
        <div className={styles.privilegeBlock}>
          <div className={styles.leftBlock}>
            <span className="fontPoppinsRegularSm">{LICENSE_DETAILS_CONSTANTS?.STORAGE} </span>
          </div>
          <div className={styles.rightBlock}>
            <span className="fontPoppinsSemiboldSm">
              {data?.futureLicensePlan?.storage} {data?.futureLicensePlan?.storageUnit || 'GB'}
            </span>
          </div>
        </div>
        <div className={styles.privilegeBlock}>
          <div className={styles.leftBlock}>
            <span className="fontPoppinsRegularSm">{LICENSE_DETAILS_CONSTANTS?.TOTAL_AMOUNT_PAID} </span>
          </div>
          <div className={styles.rightBlock}>
            <span className="fontPoppinsSemiboldSm">${data?.futureLicensePlan?.grandTotal} </span>
          </div>
        </div>
        <div className={styles.privilegeBlock}>
          <div className={styles.leftBlock}>
            <span className="fontPoppinsRegularSm">{LICENSE_DETAILS_CONSTANTS?.PURCHASED_BY} </span>
          </div>
          <div className={styles.rightBlock}>
            <span className="fontPoppinsSemiboldSm">{ownerDetails.name} </span>
          </div>
        </div>
        <div className={styles.privilegeBlock}>
          <div className={styles.leftBlock}>
            <span className="fontPoppinsRegularSm">{LICENSE_DETAILS_CONSTANTS?.PURCHASED_ON} </span>
          </div>
          <div className={styles.rightBlock}>
            <span className="fontPoppinsSemiboldSm">{showOnlyDate(data?.modifiedOn)} </span>
          </div>
        </div>
        <div className={styles.privilegeBlock}>
          <div className={styles.leftBlock}>
            <span className="fontPoppinsRegularSm">{LICENSE_DETAILS_CONSTANTS?.ACTIVATES_ON} </span>
          </div>
          <div className={styles.rightBlock}>
            <span className="fontPoppinsSemiboldSm">{showOnlyDate(data?.futureLicensePlan?.startDate)} </span>
          </div>
        </div>
        <div className={cx(styles.privilegeBlock)}>
          <div className={styles.leftBlock}>
            <span className="fontPoppinsRegularSm">{LICENSE_DETAILS_CONSTANTS?.EXPIRING_ON}</span>
          </div>
          <div className={styles.rightBlock}>
            <span className="fontPoppinsSemiboldSm">{showOnlyDate(data?.futureLicensePlan?.expiryDate)} </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpcomingPlanDetails;

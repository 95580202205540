import { memo, useEffect, useMemo, useRef } from 'react';
import Chart from 'react-apexcharts';

import { fontPoppins } from '@src/css_config/fontConstants';
import { commonCustomTooltip } from '@src/common/CommonCustomTooltip';

import ChartCard from '../components/ChartCard';
import DashboardStyle from '../styles/dashboard.module.scss';

function handleChartElementsStyle(element) {
  if (element) {
    const totalValueElement = element.querySelector('.apexcharts-datalabel-value');

    if (totalValueElement) {
      const offsetYOfTotalValueElement = totalValueElement?.getAttribute('y');
      const totalValueLabelElement = element.querySelector('.apexcharts-datalabel-label');
      totalValueLabelElement?.setAttribute('y', Number(offsetYOfTotalValueElement) + 15);
    }

    const restSeriesLegendElement = element.querySelector(`.apexcharts-legend-series[seriesname="${noData.label}"]`);

    if (restSeriesLegendElement) {
      restSeriesLegendElement.style.visibility = 'hidden';
      restSeriesLegendElement.style.display = 'none';
    }

    const donutChartCircleElement = element.querySelector('.apexcharts-pie circle');
    if (donutChartCircleElement) {
      donutChartCircleElement.setAttribute('stroke', '#EBF2F7');
      donutChartCircleElement.setAttribute('stroke-width', '7');
    }

    const titleTextElement = element.querySelector('.apexcharts-title-text');
    const parentElementWidth = element.offsetWidth;

    if (titleTextElement && parentElementWidth) {
      titleTextElement.setAttribute('x', parentElementWidth / 2);
    }
  }
}

const noData = {
  color: '#EBF2F7',
  label: 'nodata',
};

function SmallDonutChart({
  series,
  labels,
  colors,
  title,
  totalLabel,
  customTooltipFormatter = commonCustomTooltip,
  roundedDirection = 'bl',
  roundedBorders = true,
}) {
  const seriesDataSum = series.reduce((preValue, currentValue) => (preValue += currentValue), 0);

  const tooltipEbabled = seriesDataSum >= 1 ? true : false;
  const ref = useRef();

  const chartOptions = useMemo(
    () => ({
      chart: {
        width: 100,
        height: '100%',
        redrawOnWindowResize: true,
        redrawOnParentResize: true,
        events: {
          mounted: (chart) => {
            handleChartElementsStyle(ref?.current);
          },
          updated: () => {
            handleChartElementsStyle(ref?.current);
          },
          mouseMove: () => {
            const element = ref.current;
            if (element) {
              const tooltipElement = element.querySelector('.apexcharts-tooltip');
              if (tooltipElement?.style?.left) {
                tooltipElement.style.left = 0;
                tooltipElement.style['margin-left'] = '24px';
              }
            }
          },
        },
      },
      colors: [...colors, noData.color],
      dataLabels: {
        enabled: false,
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
          },
        },
      },
      labels: [...labels, noData.label],
      legend: {
        onItemClick: {
          toggleDataSeries: false,
        },

        formatter: (seriesName, options) => {
          return `${seriesName} : <span class="fontPoppinsRegularXs">${
            options.w.globals.series[options.seriesIndex]
          }<span>`;
        },
        horizontalAlign: 'center',
        ...fontPoppins.rXs,
        offsetY: 6,
        itemMargin: {
          horizontal: 5,
          vertical: 0,
        },
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: '80%',
            labels: {
              show: true,
              value: {
                show: true,
                offsetY: -15,
                fontFamily: fontPoppins.sSm.fontFamily,
                fontSize: '16px',
                fontWeight: '700',
              },
              total: {
                show: true,
                showAlways: true,
                label: totalLabel,
                offsetY: 30,
                fontFamily: fontPoppins.rXs.fontFamily,
                fontSize: '8px',
                color: '#B3B8BD',
                formatter: function (w) {
                  return w.globals.seriesTotals.slice(0, -1).reduce((a, b) => {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },
      title: {
        text: title,
        margin: 10,
        offsetY: 0,
        floating: false,
        style: {
          ...fontPoppins.sDm,
          color: '#3C3838',
        },
      },
      tooltip: {
        enabled: tooltipEbabled,
        custom: customTooltipFormatter,
      },
    }),
    [labels, colors, title, totalLabel, customTooltipFormatter, tooltipEbabled]
  );
  useEffect(() => {
    ref.current && handleChartElementsStyle(ref.current);
  }, []);

  const noDataSeries = series.some((number) => number > 0) ? 0 : 1;
  return (
    <div ref={ref} className="h-full">
      <ChartCard
        className={DashboardStyle['small-donut-chart']}
        roundedDirection={roundedDirection}
        roundedBorders={roundedBorders}
      >
        <Chart options={chartOptions} series={[...series, noDataSeries]} type="donut" />
      </ChartCard>
    </div>
  );
}

export default memo(SmallDonutChart);

import React, { useState, Fragment, useEffect } from 'react';
import { Route, Switch, useHistory, withRouter } from 'react-router';
import PackageChild from './child';
import History from '../../shared/history/history';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import ProgramElementDetailsPage from './program_element_page';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function ProgramElementRouter(props) {
  const { history, location } = props;
  let historyLocation = history.location.pathname;
  let path;
  let index = historyLocation.lastIndexOf('/');
  path = historyLocation.slice(0, index);
  let count = path.split('/').length;

  let activeTab;
  const search = window.location.search;
  const id = new URLSearchParams(search).get('id');
  const type = new URLSearchParams(search).get('type');
  const parentId = new URLSearchParams(search).get('parentId');
  if (parentId) {
    activeTab = history.location.pathname.split('/')[count];
  } else {
    activeTab = history.location.pathname.split('/')[count];
  }
  useEffect(() => {
    if (parentId) {
      setTabsList([
        {
          name: 'Program Element',
          logo: '/assets/images/project_rec_logo.svg',
          selectedLogo: '/assets/images/project_rec_logo_blue.svg',
          pathUrl: `${path}/Program Element?id=${id}&parentId=${parentId}`,
          key: 'package-tab',
          selectedName: 'Program Element',
        },
      ]);
    } else {
      setTabsList([
        {
          name: 'Package',
          logo: '/assets/images/project_rec_logo.svg',
          selectedLogo: '/assets/images/project_rec_logo_blue.svg',
          pathUrl: `${path}/Package?id=${id}&type=package`,
          key: 'package-tab',
          selectedName: 'Package',
        },
      ]);
    }
  }, [parentId]);
  const [tabsList, setTabsList] = useState([
    {
      name: 'Package',
      logo: '/assets/images/project_rec_logo.svg',
      selectedLogo: '/assets/images/project_rec_logo_blue.svg',
      pathUrl: `${path}/Package?id=${id}&type=package`,
      key: 'package-tab',
      selectedName: 'Package',
    },
  ]);

  let renderTabs = () => {
    return (
      <ul className="navBar text-blue-600 flex flex-row primary-tab">
        {tabsList.map((tab, index) => {
          return (
            <li>
              <a
                href="#"
                key={tab.key}
                onClick={(event) => {
                  event.preventDefault();
                  props.history.push(tab.pathUrl);
                }}
                className={`${activeTab === tab.selectedName ? 'selected_tab_style' : ''}`}
              >
                {tab.name}
              </a>
            </li>
          );
        })}
      </ul>
    );
  };
  return (
    <div className="page-list-body">
      <div className="page-tab-with-actions">
        {renderTabs()}

        {/* <Menu as="div" className="ml-auto">
          {({ open }) => (
            <>
              <div>
                <Menu.Button className="bg-gray-100  rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                  <span className="sr-only">Open options</span>
                  <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          Help
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu> */}
      </div>

      {parentId ? (
        <Switch>
          <Route
            path={`${path}/Program Element`}
            exact={true}
            strict={true}
            key="program-element-page"
            component={ProgramElementDetailsPage}
          ></Route>

          <Route path={`${path}/history`} exact={true} strict={true} key="history-page" component={History}></Route>
        </Switch>
      ) : (
        <Switch>
          <Route path={`${path}/Package`} exact={true} strict={true} key="package-page">
            <PackageChild MyAlert={props.MyAlert} />
          </Route>

          <Route path={`${path}/history`} exact={true} strict={true} key="history-page" component={History}></Route>
        </Switch>
      )}
    </div>
  );
}

export default withRouter(ProgramElementRouter);

import React from 'react';
import styles from '@pages/analytics/analytics.module.scss';
import cx from 'classnames';
import DefectsCardIconsRenderer from './defects-card-icon-renderer';
import { countMessageRenderer } from '@pages/analytics/common/util';

const DefectCardDetails = ({ count, name, color, iconBg }) => {
  return (
    <section className={cx(styles['defectsDetailsCard'])}>
      <div className={styles['defectsDetailsHeader']}>
        <div className={cx('fontPoppinsSemiboldSm capitalize', styles['defectsDetailsHeaderContent'])}>
          <div style={{ color }}>{name === 'New Defects' ? 'Open Defects' : name}</div>
          <div>{countMessageRenderer(count, name)}</div>
        </div>
        <div className={styles['iconBlock']} style={{ background: iconBg }}>
          <DefectsCardIconsRenderer name={name} />
        </div>
      </div>
    </section>
  );
};

export default DefectCardDetails;

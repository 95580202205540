import React, { useState, useEffect } from 'react';
import './template.scss';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import LabeledInput from '@pages/common/Inputs/LabeledInput';

export default function TSAddColumn(props) {
  const [openModal, setOpenModal] = useState(true);
  const [initialModalStyle, setInitialModalStyle] = useState('modal_dialog_sb');

  const initialValues = {
    addColumn: '',
  };
  const validationSchema = yup.object({
    addColumn: yup
      .string()
      .ensure()
      .required('Number is required')
      .matches(/^[0-9.]+$/, 'Should be Number'),
  });

  const closePopUpModal = () => {
    props.closeModal(false);
    setOpenModal(false);
  };

  const onSubmit = () => {
    props.addColValue(formikDetails.values.addColumn);
    props.closeModal(false);
  };

  const formikDetails = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <>
      <Modal open={openModal} className={initialModalStyle}>
        <div className="modal_container browserStack_check_modal">
          <div className="modal_header">
            <h2 className="title"> Add Column</h2>

            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                closePopUpModal();
              }}
            >
              <img className="cursor-pointer" src="/assets/images/close_black.svg" alt="" height="25px" width="25px" />{' '}
            </IconButton>
          </div>
          <div className="modal_body">
            <form onSubmit={formikDetails.handleSubmit}>
              <div className="grid grid-cols-1 gap-4">
                <div>
                  <LabeledInput
                    error={formikDetails.errors.addColumn && formikDetails.touched.addColumn}
                    required
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: '1',
                        step: '1',
                        className: 'default-input',
                      },
                    }}
                    label={<>No. of Columns</>}
                    className=" w-full fontPoppinsRegularMd"
                    placeholder="Typ here"
                    autoComplete="off"
                    name="addColumn"
                    id="addColumn"
                    onBlur={formikDetails.handleBlur}
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.addColumn}
                  />
                  {formikDetails.errors.addColumn && formikDetails.touched.addColumn ? (
                    <div className="errorMessage">{formikDetails.errors.addColumn}</div>
                  ) : null}
                </div>
              </div>

              <div className="modal_footer">
                <button
                  className="gray-btn mr-3"
                  type="button"
                  onClick={() => {
                    closePopUpModal();
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="primary-btn">
                  <span>Add</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import { Route, Switch } from 'react-router';
import CreateVariable from '../../shared/variables/create-edit-variables';
import ScriptSteps from './steps/script-steps';
import WebServiceTD from './webservice/webservice';
import DatabaseTD from './database/database';
import { useAlert } from '../../../common/alert_service/useAlert';
import VariableRouter from './variables/variable-router';
import SettingsRouter from './settings/settings-router';
import HistoryTD from '../../shared/history/history';
import ElementModal from '../../../repository/components/project_elements/modals/ElementModal';
import { getPageTreeReq, getScriptReq, getAllTestCaseTemplate } from '@api/api_services';
import MergeConflictModal from '../../../repository/components/project_elements/shared_recorded_elements/shared_elements/merge_conflict_modal';
import ElementConflictModal from '../../../repository/components/project_elements/shared_recorded_elements/element_conflict_modal';
import DownloadClientModal from '../../../configuration/system_config/system_config v1.1/modals/download_client_modal';
import ManualTestCase from './testcase-template/manual-test-case';
import AddManualTestCase from './testcase-template/modals/add-manual-test-case';
import LoaderModal from '../modules/module/modals/loader-modal';
import { createScript as createScriptAlias } from '../modules/module/utils/script-create-api';
import { getUserPrivilege, isWebServiceRequired, purchasedFeatureTypeOfAutomation } from '@src/util/common_utils';
import { getLicenseFeatures } from '@src/util/common_utils';
import { DataContext } from '@pages/common/Layout/components/Navigation';
import WebserviceWarningModal from './webservice-warning-modal';
import { ArrowLeft } from '@material-ui/icons';
import ViewVariable from '../../shared/variables/view-variable';
import { WEBSERVICE } from '@pages/test-development/script/scripts/webservice/restapi/constants/constants';
import { TEST_DEV } from '@src/common/ui-constants';
import '@pages/test-development/script/scripts/webservice/restapi/css/common_Webservice.scss';

const ScriptsRouter = (props) => {
  const [downloadClientModal, setDownloadClientModal] = useState(false);
  const projectDetails = JSON.parse(localStorage.getItem('selected-project'));
  const countData = JSON.parse(localStorage.getItem('allowedProjects'))?.features?.repository?.restrictions;
  const { AlertContatiner, MyAlert } = useAlert();
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  let scriptId = new URLSearchParams(search).get('scriptId');
  let [manualScriptId, setManualScriptId] = useState(new URLSearchParams(search).get('manualScriptId') || '');
  const manualScriptIdRequired = new URLSearchParams(search).get('manualScriptIdRequired') || false;
  const [variableData, setVariableData] = useState(null);
  const { setWebserviceData } = useContext(DataContext);
  const [isCurlDisabled, setCurlDisabled] = useState(true);
  const [isExportCurlOpen, setExportCurlOpen] = useState(false);
  const [menuOptions, setMenuOptions] = useState(['Web']);
  const [selectedEnvironment, setSelectedEnvironment] = useState({});
  const inputElement = useRef();
  const [isViewVariableClick, setViewVariableClick] = useState(false);
  const { EXPORT_CURL_TEXT, IMPORT_CURL_TEXT, IMPORT } = WEBSERVICE;

  if (scriptId === 'null') {
    scriptId = '';
  }
  if (manualScriptId === 'null') {
    setManualScriptId('');
  }

  const index = props.location.pathname.lastIndexOf('/');
  let path = props.location.pathname.slice(0, index);
  let count = path.split('/').length;
  const tab = props.location.pathname.split('/')[count];
  let activeTab;
  if (tab === 'Variables') {
    props.history.push(`${path}/Local Variables?moduleId=${moduleId}&scriptId=${scriptId}`);
  } else if (tab === 'Settings' && !path.includes('/webservices/')) {
    props.history.push(`${path}/Pre Condition?moduleId=${moduleId}&scriptId=${scriptId}`);
  } else if (tab === 'webservices') {
    props.history.push(`${path}/restapi?moduleId=${moduleId}&scriptId=${scriptId}`);
  }
  if (tab === 'Local Variables' || tab === 'Global Variables' || tab === 'Project Environment Variables') {
    activeTab = 'Variables';
    const name = props.location.pathname.slice(0, index);
    const indexUpdated = name.lastIndexOf('/');
    path = name.slice(0, indexUpdated);
  } else if (
    ['Pre Condition', 'Post Condition', 'Dataproviders', 'Depends on Scripts', 'webservice-authorization'].includes(tab)
  ) {
    activeTab = 'Settings';
    const name = props.location.pathname.slice(0, index);
    const indexUpdated = name.lastIndexOf('/');
    path = name.slice(0, indexUpdated);
  } else if (tab === 'restapi' || tab === 'snippet' || tab === 'history' || tab === 'setting') {
    activeTab = 'webservices';
    const name = props.location.pathname.slice(0, index);
    const indexUpdated = name.lastIndexOf('/');
    path = name.slice(0, indexUpdated);
  } else {
    activeTab = tab;
    path = props.location.pathname.slice(0, index);
  }

  const [openCreateVariableModal, setOpenCreateVariableModal] = useState(false);
  let [openCreateElementModal, setOpenCreateElementModal] = useState(false);
  let [treeData, setTreeData] = useState([]);

  let [createAsSharedEle, setCreateAsSharedEle] = useState(false);
  let [pageKeyForEle, setPageKeyForEle] = useState('');
  let [sharingEle, setSharingELe] = useState();
  let [sharedConflictEles, setSharedConflictEles] = useState();
  let [openMergeConflictModal, setopenMergeConflictModal] = useState(false);
  let [elementConflictModal, setElementConflictModal] = useState(false);
  const [reloadTree, setReloadTree] = useState(false);
  const [node, setNode] = useState({});
  let [scriptType, setScriptType] = useState('');
  let [script, setScript] = useState({});

  const [showWebserviceWarningModal, setShowWebserviceWarningModal] = useState(false);
  const [clickedTabPath, setClickedPathTab] = useState(null);
  const [dataEnteredInWebservice, setDataEnteredInWebservice] = useState(false);
  const [activeWebserviceData, setActiveWebserviceData] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showLoaderModal, setShowLoaderModal] = useState(false);
  const [scriptData, setScriptData] = useState({});
  const [manualTest, setManualTest] = useState(false);
  const [isImportCurlOpen, setImportCurlOpen] = useState(false);
  const licenseFeatures = getLicenseFeatures();
  const hasManualTestCase = window.privilege.hasManualTestCase(licenseFeatures);
  const hasAutomation = window.privilege.hasAutomation(licenseFeatures);
  const hasCBasic = window.privilege.hasCBasic(licenseFeatures);
  const hasAutomationBasedOnProjType = purchasedFeatureTypeOfAutomation(projectDetails.type);

  useEffect(() => {
    if (projectDetails.type === 'Web') {
      let tempArray = [
        {
          Web: ['Web'],
        },
      ];
      setMenuOptions([...tempArray]);
    } else if (projectDetails.type === 'Mobile') {
      let tempArray = [
        {
          Android: ['Android'],
          iOS: ['iOS'],
          'Mobile (Android & iOS)': ['Android', 'iOS'],
          'Mobile (Android or iOS)': ['Mobile'],
        },
      ];
      setMenuOptions([...tempArray]);
    } else if (['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectDetails.type)) {
      let tempArray = [
        {
          Web: ['Web'],
          Android: ['Android', 'MobileWeb'],
          iOS: ['iOS', 'MobileWeb'],
          'Mobile (Android & iOS)': ['Android', 'iOS', 'MobileWeb'],
          'Mobile (Android or iOS)': ['Mobile', 'MobileWeb'],
          'Web & Mobile (Android & iOS)': ['Web', 'Android', 'iOS', 'MobileWeb'],
          'Web & Mobile (Android or iOS)': ['Web', 'Mobile', 'MobileWeb'],
        },
      ];
      setMenuOptions([...tempArray]);
    }
  }, [projectDetails?.type]);

  const [openViewVariableModal, setOpenViewVariableModal] = useState(false);
  const [recentVariableData, setRecentVariableData] = useState({});
  useEffect(() => {
    if (manualScriptId) {
      setManualTest(false);
    } else {
      setManualTest(true);
    }
  }, [manualScriptId]);
  const createScript = async (id, type = 'manual') => {
    try {
      const data = {
        name: scriptData.name,
        scriptType: scriptData.scriptType,
        desc: scriptData.desc,
        parentId: scriptData.parentId,
        parentName: scriptData.parentName,
        executionOrder: scriptData.executionOrder,
        hierarchy: scriptData.hierarchy,
        testCaseType: type === 'auto' ? 'Automation' : type,
        templateId: type === 'manual' ? id : null,
        manualTestCase: {},
        pair: true,
      };
      await createScriptAlias(data, type, props.history, MyAlert, [
        { type: scriptData.testCaseType, id: scriptData.id },
      ]);
      setOpenModal(false);
      setShowLoaderModal(false);
    } catch (err) {
      console.error('CREATE_SCRIPT', err);
    }
  };
  useEffect(() => {
    return () => {
      setDataEnteredInWebservice(false);
    };
  }, [dataEnteredInWebservice]);

  const setWebserviceStateValue = (val) => {
    setWebserviceData(val);
    setDataEnteredInWebservice(val);
    setActiveWebserviceData(val);
  };

  const handleWebServiceModal = async (action) => {
    if (action === 'exit') {
      await setWebserviceStateValue(false);
      setShowWebserviceWarningModal(false);
      props.history.push(clickedTabPath);
    } else {
      setShowWebserviceWarningModal(false);
      setClickedPathTab(null);
    }
  };

  const handleTabClick = async (url = ' ', selectedTabName) => {
    if (['Variables', 'Settings', 'webservices', 'History'].includes(selectedTabName)) {
      setManualTest(false);
    } else if (selectedTabName === 'Steps') {
      setManualTest(manualScriptId ? false : true);
    }
    if (activeWebserviceData && activeTab === 'webservices') {
      if (['Rest API', 'Snippet', 'webservices', 'History'].includes(selectedTabName)) {
        await setDataEnteredInWebservice(false);
        props.history.push(url);
      } else {
        await setDataEnteredInWebservice(true);
        setClickedPathTab(url);
        setShowWebserviceWarningModal(true);
      }
    } else if (activeWebserviceData && activeTab === 'Settings') {
      if (selectedTabName === 'Web Service Authorization') {
        await setDataEnteredInWebservice(false);
        props.history.push(url);
      } else {
        await setDataEnteredInWebservice(true);
        setClickedPathTab(url);
        setShowWebserviceWarningModal(true);
      }
    } else {
      setActiveWebserviceData(false);
      props.history.push(url);
    }
  };

  const { isTestDevelopmentEditable, isRepositoryEditable, hasFullAccess, isUser } = React.useMemo(
    () => ({
      isRepositoryEditable: window.permission.isEditAccess('repository'),
      isTestDevelopmentEditable: window.permission.isEditAccess('testDevelopment'),
      hasFullAccess: window.permission?.isFullAccess('testDevelopment'),
      isUser: getUserPrivilege(),
    }),
    []
  );
  const createVariableModal = () => {
    setOpenCreateVariableModal(true);
  };

  const createElementModal = async () => {
    try {
      let res;
      if (['Web & Mobile (Android & iOS)', 'Web & Mobile (Android or iOS)'].includes(scriptType)) {
        res = await getPageTreeReq(projectDetails.type, 'Web');
      } else if (['Mobile (Android & iOS)', 'Mobile (Android or iOS)'].includes(scriptType)) {
        res = await getPageTreeReq(projectDetails.type, 'Android');
      } else if (projectDetails.platform) {
        res = await getPageTreeReq(projectDetails.type, scriptType);
      } else {
        res = await getPageTreeReq(projectDetails.type);
      }
      if (res?.data?.responseObject?.pageTree?.[0]?.children?.length || scriptType === 'Web & Mobile') {
        if (res?.data?.responseObject?.licenseLevelElementCount < countData.numberOfElements) {
          setTreeData(res.data.responseObject.pageTree);
          setOpenCreateElementModal(true);
        } else {
          MyAlert.info('Exceeded allocated limit, please upgrade or buy new license');
        }
      } else {
        MyAlert.info('No pages available');
      }
    } catch (err) {
      console.error('Create Element Modal', err);
    }
  };
  const isRepoAndTestDevEditable = isRepositoryEditable && isTestDevelopmentEditable;
  const contextMenuDataForSteps = [
    ...(isRepoAndTestDevEditable
      ? [
          {
            option: 'Add Element',
          },
        ]
      : []),
    ...((isUser && (hasFullAccess || isTestDevelopmentEditable)) || isRepoAndTestDevEditable
      ? [
          {
            option: 'Add Variable',
          },
        ]
      : []),
    //   {
    //     option: "Optimizer",
    //   },
    //   {
    //     option: "NLP Help",
    //   },
    {
      option: 'Download Client',
    },
  ];

  const contextMenuDataForWebservices = [
    ...((isUser && (hasFullAccess || isTestDevelopmentEditable)) || isRepoAndTestDevEditable
      ? [
          {
            option: 'Add Variable',
          },
        ]
      : []),
    {
      option: 'View Variable',
    },
    {
      option: 'Download Client',
    },
    {
      option: EXPORT_CURL_TEXT,
    },
    {
      option: IMPORT_CURL_TEXT,
    },
  ];
  const contextMenuData = [
    // {
    //     option: "Optimizer"
    // },
    // {
    //     option: "NLP Help"
    // },
    {
      option: 'Download Client',
    },
  ];

  const tabsList = [
    {
      name: 'Automation Steps',
      selectedLogo: '/assets/images/modules_blue.svg',
      pathUrl: `${path}/Steps?moduleId=${moduleId}&scriptId=${scriptId}&manualScriptId=${manualScriptId}`,
      selectedName: 'Steps',
    },
    {
      name: 'Variables',
      selectedLogo: '/assets/images/variables_blue.svg',
      pathUrl: `${path}/Variables/Local Variables?moduleId=${moduleId}&scriptId=${scriptId}&manualScriptId=${manualScriptId}`,
      selectedName: 'Variables',
    },
    {
      name: 'Settings',
      selectedLogo: '/assets/images/settings_blue.svg',
      pathUrl: `${path}/Settings/Pre Condition?moduleId=${moduleId}&scriptId=${scriptId}&manualScriptId=${manualScriptId}`,
      selectedName: 'Settings',
    },
    // {
    //     "name": "Database",
    //     "selectedLogo": "/assets/images/database_logo_blue.svg",
    //     "pathUrl": `/testdevelopment/Script/${props.match.params.moduleName}/${props.match.params.scriptName}/database?moduleId=${moduleId}&scriptId=${scriptId}`,
    //     "selectedName": "database"
    // },
    // {
    //     "name": "Results",
    //     "selectedLogo": "/assets/images/result_blue.svg",
    //     "pathUrl": `/testdevelopment/Script/${props.match.params.moduleName}/${props.match.params.scriptName}/script/results?moduleId=${moduleId}&scriptId=${scriptId}`,
    //     "selectedName": "results"
    // },
    // {
    //     "name": "History",
    //     "selectedLogo": "/assets/images/history_logo_blue.svg",
    //     "pathUrl": `${path}/History?moduleId=${moduleId}&scriptId=${scriptId}&manualScriptId=${manualScriptId}`,
    //     "selectedName": "History"
    // },
  ];

  if (isWebServiceRequired(projectDetails?.type)) {
    tabsList.push({
      name: 'Web Service',
      selectedLogo: '/assets/images/http_logo_blue.svg',
      pathUrl: `${path}/webservices/restapi?moduleId=${moduleId}&scriptId=${scriptId}&manualScriptId=${manualScriptId}`,
      selectedName: 'webservices',
    });
  }

  const manualTab = {
    name: 'Manual Test Case',
    selectedLogo: '/assets/images/history_logo_blue.svg',
    pathUrl: `${path}/Manual Test Case?moduleId=${moduleId}&scriptId=${scriptId}&manualScriptId=${manualScriptId}`,
    selectedName: 'Manual Test Case',
  };
  const closeModal = (val) => {
    if (openCreateVariableModal) {
      setOpenCreateVariableModal(val);
    }
    if (openCreateElementModal) {
      setOpenCreateElementModal(val);
    }
    if (openMergeConflictModal) {
      setopenMergeConflictModal(val);
    }
    if (elementConflictModal) {
      setElementConflictModal(val);
    }
    if (DownloadClientModal) {
      setDownloadClientModal(val);
    }
    if (AddManualTestCase) {
      setOpenModal(val);
    }
    if (openViewVariableModal) {
      setOpenViewVariableModal(val);
    }
  };

  const optionMethodsHandler = (option) => {
    if (option === 'Add Variable') {
      createVariableModal();
    }
    if (option === 'Add Element') {
      if (!(scriptType === 'Webservice' || scriptType === 'Database')) {
        createElementModal();
      }
    }
    if (option === 'Add Program Element') {
      props.history.push('/repository/program_elements');
    }
    if (option === 'Download Client') {
      setDownloadClientModal(true);
    }
    if (option === EXPORT_CURL_TEXT) {
      setExportCurlOpen(true);
    }
    if (option === 'View Variable') {
      setViewVariableClick(true);
      setOpenViewVariableModal(true);
    }
    if (option === IMPORT_CURL_TEXT) {
      setImportCurlOpen(IMPORT);
    }
  };

  const contextMenuVariableTabContent = [
    {
      name: 'Local Variables',
      path: `${path}/Variables/Local Variables?moduleId=${moduleId}&scriptId=${scriptId}&manualScriptId=${manualScriptId}`,
    },
    {
      name: 'Global Variables',
      path: `${path}/Variables/Global Variables?moduleId=${moduleId}&scriptId=${scriptId}&manualScriptId=${manualScriptId}`,
    },
    {
      name: 'Project Environment Variables',
      path: `${path}/Variables/Project Environment Variables?moduleId=${moduleId}&scriptId=${scriptId}&manualScriptId=${manualScriptId}`,
    },
  ];
  const contextMenuSettingsTabContent = [
    {
      name: 'Pre-Post Condition',
      path: `${path}/Settings/Pre Condition?moduleId=${moduleId}&scriptId=${scriptId}&manualScriptId=${manualScriptId}`,
    },
    {
      name: 'Depends On Scripts',
      path: `${path}/Settings/Depends on Scripts?moduleId=${moduleId}&scriptId=${scriptId}&manualScriptId=${manualScriptId}`,
    },
    {
      name: 'Dataproviders',
      path: `${path}/Settings/Dataproviders?moduleId=${moduleId}&scriptId=${scriptId}&manualScriptId=${manualScriptId}`,
    },
    {
      name: 'Web Service Authorization',
      path: `${path}/Settings/webservice-authorization?moduleId=${moduleId}&scriptId=${scriptId}&manualScriptId=${manualScriptId}`,
    },
  ];
  const contextMenuWebServiceTabContent = [
    {
      name: 'Rest API',
      path: `${path}/webservices/restapi?moduleId=${moduleId}&scriptId=${scriptId}&manualScriptId=${manualScriptId}`,
    },
    {
      name: 'Snippet',
      path: `${path}/webservices/snippet?moduleId=${moduleId}&scriptId=${scriptId}&manualScriptId=${manualScriptId}`,
    },
    {
      name: 'History',
      path: `${path}/webservices/history?moduleId=${moduleId}&scriptId=${scriptId}&manualScriptId=${manualScriptId}`,
    },
    // we are commented for future implementation
    // {
    //     name: "Settings",
    //     path: `${path}/webservices/setting?moduleId=${moduleId}&scriptId=${scriptId}&manualScriptId=${manualScriptId}`
    // },
  ];

  const createAsSharedElement = async (dataele, pageKey, createType, respData) => {
    setCreateAsSharedEle(true);
    setPageKeyForEle(pageKey);
    let response;
    setSharingELe(dataele);
    response = respData;
    if (
      response.data?.responseCode === 400 &&
      response.data?.message ===
        'Their is a conflict in Shared element would you like to crete New element or Merge into shared element'
    ) {
      setOpenCreateElementModal(false);
      setSharedConflictEles(response.data?.responseObject);
      setopenMergeConflictModal(true);
    } else {
      props.MyAlert.success(`${dataele.name} ${dataele.type} created successfully`);
      if (props.callGetEleReqs) {
        props.callGetEleReqs();
      }
    }
  };
  let updateSharedData = (value, nodeShared) => {
    let selectedNode = node.node;
    if (nodeShared) {
      selectedNode = nodeShared.node;
    }
    selectedNode.data.isShared = value;
    selectedNode.render(true, true);
  };

  let openElementConflict = (val) => {
    setElementConflictModal(val);
  };
  let unCheckShareElementBtn = (node) => {
    console.log(node);
    console.log(document.getElementById(node.id), 'keyyyyy');
  };

  const openElementModalFunction = async (e, selOption) => {
    setSelectedEnvironment(selOption);
    try {
      let res;
      res = await getPageTreeReq(projectDetails.type, selOption);
      if (res?.data?.responseObject?.licenseLevelElementCount < countData.numberOfElements) {
        setTreeData(res.data.responseObject.pageTree);
        setOpenCreateElementModal(true);
        inputElement.current.style.visibility = 'hidden';
      } else {
        MyAlert.info('Exceeded allocated limit, please upgrade or buy new license');
      }
    } catch (err) {
      console.error('Create Element Modal', err);
    }
  };

  const contextMenuTab = (optionList) => {
    const isWebserviceTab = /Variables|Condition/i.test(optionList[0]?.name);
    return (
      <>
        {/* I have commented for the testing */}
        {/* <WarningPromptBeforeUnload isBlocking={dataEnteredInWebservice} /> */}
        <div className="inline-block">
          <Menu as="div" className="relative text-left">
            {({ open }) => (
              <>
                <div>
                  <Menu.Button>
                    <span className="sr-only">Open options</span>
                    <img
                      src="/assets/images/arrow_drop_down_black.svg"
                      alt="DropDownArrow"
                      aria-hidden="true"
                      className="ml-1 -pb-px font-bold text-sm "
                    />
                  </Menu.Button>
                </div>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="origin-top-right absolute left-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                  >
                    <div className={`py-4 ${isWebserviceTab ? 'w-56' : 'w-24'}`}>
                      {optionList.map((data) => (
                        <Menu.Item key={data.name}>
                          {({ active }) => (
                            <label
                              onClick={() => {
                                handleTabClick(data.path, data.name);
                              }}
                              className={`${
                                active ? 'bg-gray-100 text-blue-600 ' : 'text-gray-600 '
                              } block px-4 py-2 text-sm fontPoppinsRegularSm webservice-context-menu-data-hover`}
                            >
                              {data.name}
                            </label>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </>
    );
  };
  const renderMenu = () => {
    return (
      <Menu as="div" className={`relative text-left -mt-1`}>
        {({ open }) => (
          <>
            <div>
              <Menu.Button>
                <span className="sr-only">Open options</span>
                <DotsVerticalIcon className="h-5 w-5 black" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="origin-top-right absolute right-0 mt-2 py-4 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 "
                ref={inputElement}
              >
                <div className="py-1">
                  {activeTab === 'Steps' ? (
                    contextMenuDataForSteps.map((data) => {
                      let childOptionNeed = false;
                      if (data.option === 'Add Element') {
                        childOptionNeed = true;
                      } else {
                        childOptionNeed = false;
                      }
                      return (
                        <div className="w-56">
                          {!childOptionNeed ? (
                            <Menu.Item
                              key={data.option}
                              disabled={
                                data.option === 'Optimizer' ||
                                (data.option === 'Add Element' && ['Webservice', 'Database'].includes(scriptType))
                                  ? true
                                  : false
                              }
                            >
                              {({ active }) => (
                                <label
                                  onClick={() => {
                                    optionMethodsHandler(data.option);
                                  }}
                                  className={`${
                                    active ? 'bg-gray-100 text-blue-700' : 'text-gray-700'
                                  } block px-4 py-2 text-sm fontPoppinsRegularMd ${getOpacityClass(data, scriptType)}`}
                                  MyAlert={MyAlert}
                                >
                                  {data.option}
                                </label>
                              )}
                            </Menu.Item>
                          ) : (
                            <Menu as="div" className="more-button relative inline-block text-left">
                              {({ open }) => (
                                <>
                                  <div>
                                    <Menu.Button
                                      disabled={['Webservice', 'Database'].includes(scriptType)}
                                      className={
                                        'ml-4 text-gray-700 block py-2 text-sm fontPoppinsRegularMd ' +
                                        (['Webservice', 'Database'].includes(scriptType) ? 'opacity-50' : '')
                                      }
                                    >
                                      <ArrowLeft className="-ml-5" />
                                      Add Element
                                    </Menu.Button>
                                  </div>
                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items
                                      static
                                      className={
                                        'z-50 origin-top-right  absolute  -mt-6 w-68 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none dropdown-child-option-style-stepGroup'
                                      }
                                    >
                                      <div className="py-1">
                                        {menuOptions[0][scriptType]
                                          ?.filter((data) => {
                                            if (
                                              [
                                                'Web & Mobile (Android or iOS)',
                                                'Web & Mobile (Android & iOS)',
                                              ].includes(scriptType)
                                            ) {
                                              return true;
                                            }
                                            return data !== 'MobileWeb';
                                          })
                                          .map((data) => {
                                            return (
                                              <Menu.Item key={data}>
                                                {({ active }) => (
                                                  <label
                                                    onClick={(e) => openElementModalFunction(e, data)}
                                                    className={`${
                                                      active ? 'bg-gray-100 text-blue-700' : 'text-gray-700'
                                                    } block px-4 py-2 text-sm fontPoppinsRegularMd opacity-50"`}
                                                  >
                                                    {data}
                                                  </label>
                                                )}
                                              </Menu.Item>
                                            );
                                          })}
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </>
                              )}
                            </Menu>
                          )}
                        </div>
                      );
                    })
                  ) : activeTab === 'webservices' ? (
                    <div className="webservice-context-menu-data">
                      {contextMenuDataForWebservices.map((data) =>
                        ['snippet', 'history'].includes(tab) &&
                        [EXPORT_CURL_TEXT, IMPORT_CURL_TEXT].includes(data.option) ? null : (
                          <Menu.Item
                            key={data.option}
                            disabled={
                              data.option === 'Optimizer' ||
                              (data.option === EXPORT_CURL_TEXT && isCurlDisabled) ||
                              (data.option === 'Add Element' && ['Webservice', 'Database'].includes(scriptType))
                                ? true
                                : false
                            }
                          >
                            {({ active }) => (
                              <label
                                onClick={() => {
                                  optionMethodsHandler(data.option);
                                }}
                                className={getClassNames(data, isCurlDisabled, active, scriptType)}
                                MyAlert={MyAlert}
                              >
                                {data.option}
                              </label>
                            )}
                          </Menu.Item>
                        )
                      )}
                    </div>
                  ) : (
                    <div className="w-56">
                      {contextMenuData.map((data) => (
                        <Menu.Item key={data.option}>
                          {({ active }) => (
                            <label
                              onClick={() => {
                                optionMethodsHandler(data.option);
                              }}
                              className={`${
                                active ? ' fontPoppinsRegularSm' : 'text-gray-700 fontPoppinsRegularSm'
                              } block px-4 py-2 fontPoppinsRegularSm text-sm webservice-context-menu-data-hover`}
                            >
                              {data.option}
                            </label>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  )}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    );
  };
  const validateManualTestCaseExists = async () => {
    try {
      const templateResponse = await getAllTestCaseTemplate('config');
      if (templateResponse?.data?.responseObject?.manualScriptPresent) {
        setOpenModal(false);
        createScript(templateResponse?.data?.responseObject?.defaultTemplate?._id);
      } else {
        setOpenModal(true);
      }
    } catch (err) {
      console.error('Get All Test Case Template', err);
    }
  };

  const renderTabs = () => {
    return (
      <>
        <div className="flex ">
          <div className="page-tab-with-actions">
            {manualScriptId && moduleId && !scriptId ? (
              <ul className="navBar text-blue-600 flex flex-row  repo-dev-tab-height ">
                {manualTab ? (
                  <span key={manualTab.selectedName}>
                    <span
                      className={`${
                        activeTab === manualTab.selectedName
                          ? 'selected-repo-testdev-tab-style mr-4 '
                          : 'repo-testdev-tab-style mr-4 '
                      } flex flex-row p-2 pb-3 cursor-pointer`}
                    >
                      <span
                        onClick={(event) => {
                          event.preventDefault();
                          handleTabClick(manualTab.pathUrl, manualTab.selectedName);
                        }}
                        className={activeTab === manualTab.selectedName ? 'fontPoppinsSemiboldMd' : ''}
                      >
                        {manualTab.name}
                      </span>
                    </span>
                    {manualTab.selectedName === activeTab && activeTab === 'Manual Test Case' ? (
                      <hr className="bg-blue-700 ml-2 mr-5 selected-repo-tab-style -mt-3" />
                    ) : null}
                  </span>
                ) : null}
              </ul>
            ) : null}

            {scriptId && moduleId && !manualScriptId ? (
              <ul className="navBar text-blue-600 flex flex-row -ml-2 repo-dev-tab-height">
                {tabsList.map((tab, index) => {
                  return (
                    <span key={tab.selectedName}>
                      <span
                        className={`${
                          activeTab === tab.selectedName
                            ? 'selected-repo-testdev-tab-style mr-4 '
                            : 'repo-testdev-tab-style mr-4 '
                        } flex flex-row p-2 pb-3 cursor-pointer`}
                      >
                        <span
                          onClick={(event) => {
                            event.preventDefault();
                            handleTabClick(tab.pathUrl, tab.selectedName);
                          }}
                          className={activeTab === tab.selectedName ? 'fontPoppinsSemiboldMd' : ''}
                        >
                          {tab.name}
                        </span>
                        {activeTab === tab.selectedName && tab.selectedName === 'Variables'
                          ? contextMenuTab(contextMenuVariableTabContent)
                          : null}
                        {tab.selectedName === 'Settings' && activeTab === 'Settings'
                          ? contextMenuTab(contextMenuSettingsTabContent)
                          : null}
                        {tab.selectedName === 'webservices' && activeTab === 'webservices'
                          ? contextMenuTab(contextMenuWebServiceTabContent)
                          : null}
                      </span>
                      {tab.selectedName === activeTab &&
                      (activeTab === 'Variables' || activeTab === 'Settings' || activeTab === 'webservices') ? (
                        <hr className="bg-blue-700 ml-2 mr-7 selected-repo-tab-style -mt-5" />
                      ) : null}
                      {activeTab === tab.selectedName &&
                      activeTab !== 'Settings' &&
                      activeTab !== 'Variables' &&
                      activeTab !== 'webservices' ? (
                        <hr className="bg-blue-700 ml-2 mr-6 selected-repo-tab-style -mt-3" />
                      ) : null}
                    </span>
                  );
                })}
              </ul>
            ) : null}

            {scriptId && moduleId && manualScriptId ? (
              <div className="flex">
                <div>
                  <ul className="navBar text-blue-600 flex flex-row -ml-2 repo-dev-tab-height">
                    {tabsList.map((tab, index) => {
                      return (
                        <span key={tab.selectedName}>
                          <span
                            className={`${
                              activeTab === tab.selectedName
                                ? 'selected-repo-testdev-tab-style mr-4 '
                                : 'repo-testdev-tab-style mr-4 '
                            } flex flex-row p-2 pb-3 cursor-pointer`}
                          >
                            <span
                              onClick={(event) => {
                                event.preventDefault();
                                handleTabClick(tab.pathUrl, tab.selectedName);
                              }}
                              className={activeTab === tab.selectedName ? 'fontPoppinsSemiboldMd' : ''}
                            >
                              {tab.name}
                            </span>
                            {activeTab === tab.selectedName && tab.selectedName === 'Variables'
                              ? contextMenuTab(contextMenuVariableTabContent)
                              : null}
                            {tab.selectedName === 'Settings' && activeTab === 'Settings'
                              ? contextMenuTab(contextMenuSettingsTabContent)
                              : null}
                            {tab.selectedName === 'webservices' && activeTab === 'webservices'
                              ? contextMenuTab(contextMenuWebServiceTabContent)
                              : null}
                          </span>
                          {tab.selectedName === activeTab &&
                          (activeTab === 'Variables' || activeTab === 'Settings' || activeTab === 'webservices') ? (
                            <hr className="bg-blue-700 ml-2 mr-7 selected-repo-tab-style -mt-5" />
                          ) : null}
                          {activeTab === tab.selectedName &&
                          activeTab !== 'Settings' &&
                          activeTab !== 'Variables' &&
                          activeTab !== 'webservices' ? (
                            <hr className="bg-blue-700 ml-2 mr-6 selected-repo-tab-style -mt-3" />
                          ) : null}
                        </span>
                      );
                    })}
                  </ul>
                </div>
              </div>
            ) : null}
          </div>
          <div className="ml-auto flex">
            <div>
              <div>
                {!manualTest ||
                (scriptId && moduleId && manualScriptId) ||
                ['Variables', 'Settings', 'webservices', 'History'].includes(activeTab) ||
                !isTestDevelopmentEditable ||
                !(hasManualTestCase || hasCBasic) ? (
                  ''
                ) : (
                  <div className=" ml-auto p-1 flex flex-col">
                    <div className="float-right ">
                      <div className="ml-20">
                        <button
                          onClick={() => {
                            validateManualTestCaseExists();
                          }}
                          type="button"
                          className="ml-5 primary-btn"
                        >
                          + Manual Test Case
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {manualScriptId &&
                moduleId &&
                !scriptId &&
                isTestDevelopmentEditable &&
                hasAutomation &&
                hasAutomationBasedOnProjType ? (
                  <div className=" ml-auto p-1 flex flex-col">
                    <div className="float-right ml-auto">
                      <div className="ml-20">
                        <button
                          onClick={() => {
                            setShowLoaderModal(true);
                            createScript(null, 'auto');
                          }}
                          type="button"
                          className="ml-5 primary-btn"
                          disabled={!script?.pair}
                        >
                          {TEST_DEV.AUTOMATION_BUTTON_LABEL}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="-mr-9">
                {scriptId && moduleId && manualScriptId ? (
                  <ul className="navBar text-blue-600 flex flex-row  repo-dev-tab-height ">
                    {manualTab ? (
                      <span key={manualTab.selectedName}>
                        <span
                          className={`${
                            activeTab === manualTab.selectedName
                              ? 'selected-repo-testdev-tab-style mr-4 '
                              : 'repo-testdev-tab-style mr-4 '
                          } flex flex-row p-2 pb-3 cursor-pointer`}
                        >
                          <span
                            onClick={(event) => {
                              event.preventDefault();
                              handleTabClick(manualTab.pathUrl, manualTab.selectedName);
                            }}
                            className={activeTab === tab.selectedName ? 'fontPoppinsSemiboldMd' : ''}
                          >
                            {manualTab.name}
                          </span>
                        </span>
                        {manualTab.selectedName === activeTab && activeTab === 'Manual Test Case' ? (
                          <hr className="bg-blue-700 ml-2 mr-5 selected-repo-tab-style -mt-3" />
                        ) : null}
                      </span>
                    ) : null}
                  </ul>
                ) : null}
              </div>
            </div>
            <div className="clearfix ml-5 mt-3 ">{hasAutomation && hasAutomationBasedOnProjType && renderMenu()}</div>
          </div>
        </div>
      </>
    );
  };

  function getScriptType() {
    const id = scriptId ? scriptId : manualScriptId;

    getScriptReq(moduleId, id, manualScriptIdRequired).then((response) => {
      if (response?.data?.responseCode === 200 && response?.data?.responseObject) {
        let responseObject = response.data.responseObject;
        setScriptType(responseObject.scriptType);
        setScript(responseObject);
        if (manualScriptIdRequired && responseObject.automationScript) {
          setScriptType(responseObject?.automationScript?.scriptType);
          setScriptData(responseObject?.automationScript || {});
          setManualScriptId(responseObject?.manualScriptId || '');
        } else {
          setScriptData({ ...responseObject });
        }
      }
    });
  }
  useEffect(() => {
    getScriptType();
  }, []);

  const getRecentVariable = (data) => {
    setVariableData(data);
  };

  const handleCurlModal = (status) => {
    setCurlDisabled(status);
  };
  const onSetRecentVariableName = (variableData) => {
    setRecentVariableData(variableData);
  };
  const getClassNames = (data, isCurlDisabled, active, scriptType) => {
    let className = '';
    if (data.option === EXPORT_CURL_TEXT && isCurlDisabled) {
      className += 'disabled-curl-btn ';
    } else {
      className += active ? 'bg-gray-100 text-blue-700 ' : 'text-gray-700 ';
    }
    className += 'block cursor-pointer px-4 py-2 text-sm fontPoppinsRegularSm webservice-context-menu-data-hover';
    className += getOpacityClass(data, scriptType);
    return className.trim();
  };
  const getOpacityClass = (data, scriptType) => {
    return (data.option === 'Add Element' && ['Webservice', 'Database'].includes(scriptType)) ||
      data.option === 'Optimizer'
      ? 'opacity-50 '
      : '';
  };
  return (
    <div className="page-list-body">
      <div className="alert-position-style">
        <AlertContatiner></AlertContatiner>
      </div>
      {renderTabs()}

      <Switch>
        <Route
          path={`${path.slice(0, path.lastIndexOf('/'))}/:scriptName/Steps`}
          exact={true}
          strict={true}
          key="steps-page"
        >
          <ScriptSteps MyAlert={MyAlert} />
        </Route>
        <Route
          path={`${path.slice(0, path.lastIndexOf('/'))}/:scriptName/ManualTestCase`}
          exact={true}
          strict={true}
          key="manual-testcase"
        >
          <ManualTestCase MyAlert={MyAlert} setManualScriptId={setManualScriptId} />
        </Route>
        <Route path={`${path}/Variables/:variableType`} exact={true} strict={true} key="variables-router-page">
          <VariableRouter {...props} MyAlert={MyAlert} />
        </Route>
        <Route path={`${path}/Settings/:subSettings`} exact={true} strict={true} key="settings-router-page">
          <SettingsRouter
            {...props}
            showWebserviceModal={setWebserviceStateValue}
            variableData={variableData}
            MyAlert={MyAlert}
            handleCurlModal={handleCurlModal}
            recentVariableData={recentVariableData}
            isExportCurlOpen={isExportCurlOpen}
            setExportCurlOpen={setExportCurlOpen}
            setImportCurlOpen={setImportCurlOpen}
            isImportCurlOpen={isImportCurlOpen}
          />
        </Route>
        <Route path={`${path}/Database`} exact={true} strict={true} key="database-page" component={DatabaseTD}></Route>
        <Route path={`${path}/History`} exact={true} strict={true} key="history-page" component={HistoryTD}></Route>
        <Route path={`${path}/webservices/:restapi`} exact={true} strict={true} key="webservice-page">
          <WebServiceTD
            {...props}
            showWebserviceModal={setWebserviceStateValue}
            variableData={variableData}
            MyAlert={MyAlert}
            handleCurlModal={handleCurlModal}
            recentVariableData={recentVariableData}
            isExportCurlOpen={isExportCurlOpen}
            setExportCurlOpen={setExportCurlOpen}
            setImportCurlOpen={setImportCurlOpen}
            isImportCurlOpen={isImportCurlOpen}
          />
        </Route>

        {scriptId || manualScriptId ? (
          <Route path="/testdevelopment/Script" key="script-router-page" component={ScriptsRouter}>
            <ManualTestCase MyAlert={MyAlert} setManualScriptId={setManualScriptId} />
          </Route>
        ) : null}
      </Switch>
      {/* Create Variable Modal */}
      {openCreateVariableModal ? (
        <CreateVariable
          MyAlert={MyAlert}
          closeModal={closeModal}
          id={{ moduleId: moduleId, scriptId: scriptId }}
          getRecentVariable={getRecentVariable}
        />
      ) : null}
      {openViewVariableModal ? (
        <ViewVariable
          MyAlert={MyAlert}
          closeModal={closeModal}
          isViewVariableClick={isViewVariableClick}
          setViewVariableClick={setViewVariableClick}
          onSetRecentVariableName={onSetRecentVariableName}
        />
      ) : null}
      {/* Create Element Modal */}
      {openCreateElementModal ? (
        <ElementModal
          treeData={treeData}
          mode="ADD"
          reloadTree={() => {}}
          closeModal={closeModal}
          MyAlert={MyAlert}
          projectType={projectDetails.type}
          createAsSharedElement={createAsSharedElement}
          platForm={selectedEnvironment}
          scriptType={scriptType}
          subPlatForm={projectDetails.subPlatForm ? projectDetails.subPlatForm : ''}
        />
      ) : null}
      {openMergeConflictModal ? (
        <MergeConflictModal
          closeModal={closeModal}
          updateSharedData={updateSharedData}
          openElementConflict={openElementConflict}
          reloadTree={setReloadTree}
          MyAlert={props.MyAlert}
          pageKeyForEle={pageKeyForEle}
          createAsSharedEle={createAsSharedEle}
          sharingEle={sharingEle}
          unCheckShareElementBtn={unCheckShareElementBtn}
        />
      ) : null}
      {elementConflictModal ? (
        <ElementConflictModal
          closeModal={closeModal}
          sharingEle={sharingEle}
          updateSharedData={updateSharedData}
          projectType={projectDetails.type}
          platForm={projectDetails.platform ? projectDetails.platform : null}
          MyAlert={props.MyAlert}
          reloadTree={setReloadTree}
          pageKeyForEle={pageKeyForEle}
          createAsSharedEle={createAsSharedEle}
          unCheckShareElementBtn={unCheckShareElementBtn}
          sharedConflictEles={sharedConflictEles}
        />
      ) : null}

      {showWebserviceWarningModal && <WebserviceWarningModal handleWebserviceModal={handleWebServiceModal} />}
      {downloadClientModal && <DownloadClientModal closeModal={closeModal} />}
      {openModal && (
        <AddManualTestCase
          closeModal={closeModal}
          modalHeaderLabel="Choose Template"
          createManualScript={createScript}
        />
      )}
      {showLoaderModal && <LoaderModal />}
    </div>
  );
};

export default ScriptsRouter;

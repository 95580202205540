import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ProjectElementRouter from './components/project_elements/elementrouter';
import ProgramElementRouter from './components/program_elements/programelementrouter';
import RepositoryTabs from './repository_tabs';
import StepGroupRouter from './components/step_groups/stepgroup_router';
import { withRouter } from 'react-router';
import SharedElementsRouter from './components/project_elements/shared_recorded_elements/shared_elements/shared_elements-router';

function RepositoryRouter(props) {
  const { history } = props;
  let historyLocation = history.location.pathname;
  let path;
  if (historyLocation.includes('/repository/element/project_elements/')) {
    let index = historyLocation.lastIndexOf('/');
    path = historyLocation.slice(0, index);
  } else if (historyLocation.includes('/repository/program_elements/')) {
    let index = historyLocation.lastIndexOf('/');
    path = historyLocation.slice(0, index);
  } else if (historyLocation.includes('/repository/step_group/')) {
    let index = historyLocation.lastIndexOf('/');
    path = historyLocation.slice(0, index);
  }
  return (
    <div className="page-list-body">
      <Switch>
        <Route path="/repository" exact={true} strict={true} key="testData-page">
          <RepositoryTabs MyAlert={props.MyAlert} />
        </Route>
        <Route path="/repository/program_elements" exact={true} strict={true} key="testData-page">
          <RepositoryTabs MyAlert={props.MyAlert} />
        </Route>
        <Route path="/repository/step_group" exact={true} strict={true} key="testData-page">
          <RepositoryTabs MyAlert={props.MyAlert} />
        </Route>
        <Route path={`${path}/:programElementName`} exact={true} strict={true} key="program_element_page">
          {historyLocation.includes('/repository/program_elements/') ? (
            <ProgramElementRouter MyAlert={props.MyAlert} />
          ) : historyLocation.includes('/repository/element/project_elements/') ? (
            <ProjectElementRouter MyAlert={props.MyAlert} />
          ) : (
            <StepGroupRouter MyAlert={props.MyAlert} />
          )}
        </Route>
        <Route
          path="/repository/element/shared-elements/:element_name/Shared Element"
          exact={true}
          strict={true}
          key="shared-ele-details"
        >
          <SharedElementsRouter />
        </Route>
        <Route
          path="/repository/element/shared-elements/:element_name/history"
          exact={true}
          strict={true}
          key="shared-ele-details"
        >
          <SharedElementsRouter />
        </Route>
        <Route path="*">
          <RepositoryTabs MyAlert={props.MyAlert} />
        </Route>
      </Switch>
    </div>
  );
}

export default withRouter(RepositoryRouter);

import React from 'react';
import ProgramElement from './program_element_tree';

function PackageParent(props) {
  return (
    <>
      <ProgramElement pageType="parent" MyAlert={props.MyAlert} />
    </>
  );
}

export default PackageParent;

import React, { useState, useEffect } from 'react';
import { usePagination, useTable, useGlobalFilter } from 'react-table';
import '../Analytics.scss';
import { Box, Modal, Fade } from '@mui/material';
import ScriptResultAccordion from './scripts-result-accordion';
import { ANALYTICS_MESSAGE } from '@common/ui-constants';
import { TooltipPoppin } from './util';
import { isEmptyValue } from '@src/util/common_utils';
import { getAutomationSuiteMachineDetails, getScriptResultReq } from '@api/api_services';
import { Tooltip } from '@mui/material';
import { getFormattedTextStatus, textColor } from '@pages/results/result';
import { Icon, Chip } from 'fireflink-ui';
import { MULTIFORM_ANALYTICS_CONSTANTS } from '@common/ui-constants';

const NoOfExecutionDetails = (props) => {
  let {
    columns,
    data,
    open,
    onClose,
    selectedScriptName,
    selectedScript,
    setSelectedScript,
    showFilterOnFlaky = false,
    reqBody,
  } = props;

  let pageName = MULTIFORM_ANALYTICS_CONSTANTS.PAGE_NAME;

  let [dataList, setDataList] = useState(data || []);
  let [activePage, setActivePage] = useState(pageName.NO_OF_EXECUTION_ID_PAGE);
  let [machineList, setMachineList] = useState([]);
  let [showScriptResultAccordion, setShowScriptResultAccordion] = useState(false);
  let [scriptAccordionData, setScriptAccordionData] = useState({});
  let [stepResultPayload, setSetResultPayload] = useState({ executionId: '', clientId: '', runId: '', scriptId: '' });

  // state  for filter status
  let [displayFilterOption, setDisplayFilterOption] = useState(false);
  let [filteredStatus, setFilteredStatus] = useState('ALL');
  let [filterIcon, setFilterIcon] = useState(false);
  let [withBackground, setWithBackground] = useState(false);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data: dataList || [],
    },
    useGlobalFilter,
    usePagination
  );

  let updateTabs = async () => {
    if (selectedScript?.scriptId && selectedScript?.executionId && showScriptResultAccordion) {
      let stepResponse = await getScriptResultReq(
        stepResultPayload.executionId,
        stepResultPayload.clientId,
        stepResultPayload.runId,
        stepResultPayload.scriptId
      );
      setScriptAccordionData(stepResponse.data.responseObject);

      setActivePage(pageName.SCRIPT_RESULT_ACCORDION_PAGE);
    } else if (selectedScript?.scriptId && !isEmptyValue(selectedScript?.executionId)) {
      setActivePage(pageName.MULTI_FORM_MACHINE_PAGE);

      let facetQueries = reqBody.facetQueries.filter(obj => obj.name !== "executionId") // note :to remove executionId which are selected from side filter bar 
      let payload = {
        ...reqBody,
        facetQueries: [...facetQueries,
        { name: 'scriptID', values: [selectedScript?.scriptId] },
        { name: "executionId", values: [selectedScript.executionId] }  // add only one executionId which is selected for view machine details
        ]
      }

      let response = await getAutomationSuiteMachineDetails(payload);
      setMachineList(response.data.responseObject || []);
    } else if (selectedScript?.scriptId) {
      setActivePage(pageName.NO_OF_EXECUTION_ID_PAGE);
    }
  };

  useEffect(() => {
    if (selectedScript.scriptId || selectedScript.executionId) {
      updateTabs();
    }

    if (filteredStatus === 'ALL') {
      setDataList(data);
    } else {
      let filteredStatusResult = getFilterByStatus(filteredStatus);
      setDataList(filteredStatusResult);
    }
  }, [selectedScript?.scriptId, selectedScript?.executionId, showScriptResultAccordion, filteredStatus, data]);

  const handleCloseModal = () => {
    onClose();
    setActivePage(pageName.NO_OF_EXECUTION_ID_PAGE);
    setSelectedScript({ ...selectedScript, executionId: '' });

    setShowScriptResultAccordion(false);
    setMachineList([]);
  };

  let navigateBackToPreviousPage = () => {
    if (showScriptResultAccordion) {
      setShowScriptResultAccordion(false);
    } else {
      setSelectedScript({ ...selectedScript, executionId: '' });
      setMachineList([]);
    }
  };

  // TODO :: FILTER STATUS FUNCTION AND COMPONENT :: START
  const getFilterByStatus = (status) => {
    return data?.filter((row) => row.status === status);
  };

  function statusFilter() {
    return (
      <div
        className="status-filter absolute flex flex-col mt-6 step-result cursor-pointer mx-8 py-2 z-10"
        onClick={(e) => e.stopPropagation()} // Stop event propagation to avoid closing the filter
      >
        <div
          className={`result-primary filter mt-1 ${filteredStatus === 'ALL' ? 'selected-filter' : ''}`}
          onClick={() => {
            setFilteredStatus('ALL');
            setFilterIcon(false);
            setDisplayFilterOption(false);
          }}
        >
          <div className=" mx-4">{MULTIFORM_ANALYTICS_CONSTANTS.ALL}</div>
        </div>
        <div
          className={`result-passed filter ${filteredStatus === 'PASS' ? 'selected-filter' : ''}`}
          onClick={() => {
            setFilteredStatus('PASS');
            setFilterIcon(true);
            setDisplayFilterOption(false);
          }}
        >
          <div className=" mx-4">{MULTIFORM_ANALYTICS_CONSTANTS.PASSED}</div>
        </div>
        <div
          className={`result-failed filter ${filteredStatus === 'FAIL' ? 'selected-filter' : ''}`}
          onClick={() => {
            setFilteredStatus('FAIL');
            setFilterIcon(true);
            setDisplayFilterOption(false);
          }}
        >
          <div className=" mx-4">{MULTIFORM_ANALYTICS_CONSTANTS.FAILED}</div>
        </div>
        <div
          className={`result-warning filter ${filteredStatus === 'WARNING' ? 'selected-filter' : ''}`}
          onClick={() => {
            setFilteredStatus('WARNING');
            setFilterIcon(true);
            setDisplayFilterOption(false);
          }}
        >
          <div className=" mx-4">{MULTIFORM_ANALYTICS_CONSTANTS.WARNING}</div>
        </div>
        <div
          className={`result-skipped filter mb-1 ${filteredStatus === 'SKIP' ? 'selected-filter' : ''}`}
          onClick={() => {
            setFilteredStatus('SKIP');
            setFilterIcon(true);
            setDisplayFilterOption(false);
          }}
        >
          <div className=" mx-4">{MULTIFORM_ANALYTICS_CONSTANTS.SKIPPED}</div>
        </div>
      </div>
    );
  }
  // TODO :: FILTER STATUS FUNCTION AND COMPONENT :: END

  // TODO :: MACHINE ACCORDION FUNCTIONS : START

  let showExecutionStepResult = (runId, clientId) => {
    setSetResultPayload({
      executionId: selectedScript?.executionId,
      clientId: clientId,
      runId: runId,
      scriptId: selectedScript?.scriptId,
    });
    setShowScriptResultAccordion(true);
  };

  let toggleAccordionIcon = (event, elementId) => {
    let expandElement = document.getElementById(elementId + '-expand');
    let collapseElement = document.getElementById(elementId + '-collapse');
    if (expandElement && expandElement.style) {
      //panelHtmlElement path may need to change based on the number of elements added/removed
      const panelHtmlElement = collapseElement.parentElement.parentElement.parentElement.parentElement.nextSibling;
      if (expandElement.style.display === 'none') {
        expandElement.style.display = 'inline-block';
        panelHtmlElement.style.display = 'block';
      } else if (expandElement.style.display !== 'none') {
        expandElement.style.display = 'none';
        panelHtmlElement.style.display = 'none';
      }
    }
    if (collapseElement && collapseElement.style) {
      const panelHtmlElement = collapseElement.parentElement.parentElement.parentElement.parentElement.nextSibling;
      if (collapseElement.style.display === 'none' || collapseElement.style.display === '') {
        collapseElement.style.display = 'inline-block';
        panelHtmlElement.style.display = 'block';
      } else if (collapseElement.style.display !== 'none') {
        collapseElement.style.display = 'none';
        panelHtmlElement.style.display = 'none';
      }
    }
  };

  let accordionIcon = (elementId) => {
    return (
      <span>
        <Icon
          name="arrow_right_black_filled"
          height={24}
          width={24}
          className="expand-icon"
          id={`${elementId}-expand`}
        />
        <Icon
          name="arrow_drop_down_black"
          height={24}
          width={24}
          className="collapse-icon"
          id={`${elementId}-collapse`}
        />
      </span>
    );
  };

  // TODO :: MACHINE ACCORDION FUNCTIONS : START

  const handleMouseEnter = () => {
    setWithBackground(true);
  };
  const handleMouseLeave = () => {
    setWithBackground(false);
  };

  const displayCellValue = (header, value) => {
    const lowerHeader = header.toLocaleLowerCase();
    return (lowerHeader === MULTIFORM_ANALYTICS_CONSTANTS.EXECUTION_DURATION_LOWERCASE ||
      lowerHeader === MULTIFORM_ANALYTICS_CONSTANTS.EXECUTION_TYPE_LOWERCASE) &&
      value === ''
      ? '---'
      : value;
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className="expand-failer-modalTable">
            <div className="grid grid-cols-12 ">
              <div className="col-span-12 pt-3 px-3  flex justify-between ">
                <div className="flex rs-blue fontPoppinsSemiboldSm uppercase">
                  <span className="cursor-pointer" onClick={navigateBackToPreviousPage}>
                    {(selectedScript?.executionId || showScriptResultAccordion) && (
                      <Icon name="back_arrow_btn" height={14} width={14} />
                    )}
                  </span>
                  <span className="pl-2">
                    {activePage === pageName.NO_OF_EXECUTION_ID_PAGE && selectedScript?.scriptId}
                    {activePage === pageName.MULTI_FORM_MACHINE_PAGE && selectedScript?.executionId}
                    {activePage === pageName.SCRIPT_RESULT_ACCORDION_PAGE && selectedScriptName}
                  </span>
                </div>

                <div className="flex justify-center gap-1">
                  <span className="expandIconContainer" onClick={handleCloseModal}>
                    <Icon name="shrink" height={14} width={14} />
                  </span>
                </div>
              </div>

              {/* START ::  NO OF EXECUTION ID TABLE */}

              {activePage === pageName.NO_OF_EXECUTION_ID_PAGE && (
                <div className="col-span-12 px-3">
                  <table {...getTableProps()} className="col-span-12 analyticstableExpanded">
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr
                          {...headerGroup.getHeaderGroupProps()}
                          className="AnalyticheaderStyle h-8 ml-4 fontPoppinsSemiboldSm  "
                        >
                          {headerGroup.headers.map((column) => (
                            <th width={column.width} {...column.getHeaderProps()} className=" text-left uppercase">
                              {column.Header?.toLocaleLowerCase() === 'status' && showFilterOnFlaky ? (
                                <div className="flex relative">
                                  {column.render('Header')}
                                  {displayFilterOption || filterIcon ? (
                                    <TooltipPoppin title="Filter" placement="top">
                                      <div>
                                        <Icon
                                          name="rs_filter_filled"
                                          height={12}
                                          width={12}
                                          className="ml-2 mt-0.5 cursor-pointer "
                                          onClick={() => setDisplayFilterOption(!displayFilterOption)}
                                          onMouseLeave={() => {
                                            if (
                                              filterIcon &&
                                              !['PASS', 'FAIL', 'WARNING', 'SKIP'].includes(filteredStatus)
                                            ) {
                                              setFilterIcon(false);
                                            }
                                          }}
                                        />
                                      </div>
                                    </TooltipPoppin>
                                  ) : (
                                    <TooltipPoppin title="Filter" placement="top">
                                      <div>
                                        <Icon
                                          name="filter"
                                          height={15}
                                          width={15}
                                          className="ml-2 mt-0.5 cursor-pointer "
                                          onClick={() => setDisplayFilterOption(!displayFilterOption)}
                                          onMouseOver={() => {
                                            if (
                                              !filterIcon &&
                                              !['PASS', 'FAIL', 'WARNING', 'SKIP'].includes(filteredStatus)
                                            ) {
                                              setFilterIcon(true);
                                            }
                                          }}
                                          onMouseLeave={() => {
                                            if (
                                              filterIcon &&
                                              !['PASS', 'FAIL', 'WARNING', 'SKIP'].includes(filteredStatus)
                                            ) {
                                              setFilterIcon(false);
                                            }
                                          }}
                                        />
                                      </div>
                                    </TooltipPoppin>
                                  )}
                                  {displayFilterOption && statusFilter()}
                                </div>
                              ) : (
                                column.render('Header')
                              )}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()} className="analytics-table-body">
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()} className=" h-8 fontPoppinsMediumSm ">
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  width={cell.column.width}
                                  {...cell.getCellProps()}
                                  className="text-left  fontPoppinsMediumSm"
                                >
                                  {cell.column?.Header?.toLocaleLowerCase() === 'status' ? (
                                    <div
                                      className={`result-status flex items-center ${textColor(cell.value)}  ${
                                        row.original.runId ? 'cursor-pointer' : 'cursor-default'
                                      }`}
                                      onClick={() => {
                                        if (row.original.runId) {
                                          setSelectedScript({
                                            ...selectedScript,
                                            executionId: row.original.executionId,
                                          });
                                        }
                                      }}
                                    >
                                      {getFormattedTextStatus(cell.value)}
                                    </div>
                                  ) : displayCellValue(cell.column?.Header, cell.value) === cell.value ? (
                                    cell.render('Cell')
                                  ) : (
                                    '---'
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {(isEmptyValue(data) || isEmptyValue(rows)) && (
                    <div
                      className={`${props.showfilter ? 'testscript-table-message' : 'testscript-message'
                        } fontPoppinsMediumSm`}
                    >
                      {ANALYTICS_MESSAGE.NO_SCRIPTS_FOUND}
                    </div>
                  )}
                </div>
              )}

              {/* END ::  NO OF EXECUTION ID TABLE */}

              {/* START :: MULTI FORM MACHINE PAGE */}

              {activePage === pageName.MULTI_FORM_MACHINE_PAGE && (
                <div className="col-span-12 ">
                  <div className="grid grid-cols-12 px-4 py-2  AnalyticheaderStyle fontPoppinsSemiboldSm">
                    <div className="col-span-4">{MULTIFORM_ANALYTICS_CONSTANTS.MACHINE}</div>
                    <div className="col-span-4">{MULTIFORM_ANALYTICS_CONSTANTS.PLATFORM}</div>
                    <div className="col-span-2">{MULTIFORM_ANALYTICS_CONSTANTS.STATUS}</div>
                    <div className="col-span-2">{MULTIFORM_ANALYTICS_CONSTANTS.EXECUTION_DURATION}</div>
                  </div>

                  <div className="px-2 py-1 overflow-auto result-body max-h-[550px] " id="journal-scroll">
                    {machineList.map((mac, index) => (
                      <div key={`header${mac.machineName + index}`}>
                        <div
                          className="accordion"
                          onClick={(e) => {
                            toggleAccordionIcon(e, mac.machineName + index);
                          }}
                        >
                          <div className="grid grid-cols-12 items-center gap-2 cursor-pointer">
                            <div className="col-span-4 flex " key={mac.machineName + index}>
                              {accordionIcon(mac.machineName + index)}
                              <Tooltip
                                title={mac.machineName}
                                PopperProps={{ modifiers: [{ name: 'offset', options: { offset: [0, -15] } }] }}
                              >
                                <div
                                  title={mac.machineName}
                                  className="flex fontPoppinsSemiboldSm rs-blue max-w-64 items-center"
                                >
                                  <div className="truncate pr-2">{mac.machineName}</div>
                                  <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                    <Chip
                                      label={mac?.execution?.map((value) => value?.name)?.length}
                                      size="single-count"
                                      variant="primary"
                                      withBackground={withBackground}
                                    />
                                  </div>
                                </div>
                              </Tooltip>
                            </div>
                            <div className="col-span-4 fontPoppinsMediumSm truncate">
                              <Tooltip title={mac?.platform?.toString()} placement="top">
                                {mac?.platform?.toString()}
                              </Tooltip>
                            </div>
                            <div className="col-span-2  "></div>
                            <div className="col-span-2 fontPoppinsMediumSm">{mac.executionDuration}</div>
                          </div>
                        </div>
                        <div className="panel">
                          {mac?.execution?.map((executionInfo, exeChildIndex) => (
                            <div
                              className={`grid grid-cols-12 h-8 items-center gap-2`}
                              key={`executionInfo${index}${exeChildIndex}`}
                            >
                              <div className="col-span-1"> </div>
                              <div className="col-span-3 fontPoppinsMediumSm rs-blue ">
                                <span
                                  className="cursor-pointer"
                                  onClick={() => showExecutionStepResult(executionInfo?.runId, executionInfo?.clientId)}
                                >
                                  {executionInfo.name}
                                </span>
                              </div>
                              <div className="col-span-4 fontPoppinsMediumSm truncate ">
                                <Tooltip title={executionInfo?.platform?.toString()} placement="top">
                                  <span>{executionInfo?.platform?.toString()}</span>
                                </Tooltip>
                              </div>

                              <div className="col-span-2 fontPoppinsMediumSm">
                                <span
                                  className={`${textColor(executionInfo.status)} cursor-pointer`}
                                  onClick={() => showExecutionStepResult(executionInfo?.runId, executionInfo?.clientId)}
                                >
                                  {getFormattedTextStatus(executionInfo.status)}
                                </span>
                              </div>
                              <div className="col-span-2 fontPoppinsMediumSm"> {executionInfo.executionDuration}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* END :: MULTI FORM MACHINE PAGE */}
            </div>
            {/* START :: SCRIPT RESULT ACCORDION*/}

            {activePage === pageName.SCRIPT_RESULT_ACCORDION_PAGE && (
              <>
                {scriptAccordionData?.length !== 0 && (
                  <ScriptResultAccordion content={scriptAccordionData} selectedScript={selectedScript} />
                )}
              </>
            )}
            {/* END :: SCRIPT RESULT ACCORDION*/}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
export default NoOfExecutionDetails;

/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import $ from 'jquery';
import 'jquery.fancytree/dist/modules/jquery.fancytree.filter';
import './skin/ui.fancytree.css';
import './result_tree.scss';
import { Checkbox } from '@material-ui/core';
import {
  ExpandAllIcon,
  CollapseAllIcon,
  ShowAllChildsIcon,
  HideAllChildsIcon,
  ShowAllConditionIcon,
  HideAllConditionIcon,
} from './table_tree_icons';
import { CommonLoader } from '@src/pages/common/common-loader';
import { escapeHtml, splitedScriptNameAndID } from '@src/util/common_utils';

const GLOBAL = {
  SHOW_ALL_CHILD: true,
  SHOW_ALL_CONDITIONS: true,
  LABELS: {
    EXPAND_ALL: 'Expand All',
    COLLAPSE_ALL: 'Collapse All',
    SHOW_ALL_ELEMENTS: 'Show all elements',
    HIDE_ALL_ELEMENTS: 'Hide all elements',
    SHOW_PRE_POST_CONDITION: 'Show Pre/Post Conditions',
    HIDE_PRE_POST_CONDITION: 'Hide Pre/Post Conditions',
    NO_DATA_MSG: 'No data found.',
  },
};

const ResultTree = (props) => {
  const [isExpandAll, setIsExpandAll] = useState(false);
  const [isShowAllChild, setIsShowAllChild] = useState(true);
  const [isShowAllConditions, setIsAllConditions] = useState(true);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheckAllIndeterminate, setIsCheckAllInterminate] = useState(false);
  const [treeTableId, setTreeTableId] = useState(props?.id ? props.id : 'table-tree');
  const [treeLoading, setTreeLoading] = useState(false);
  const [disableExpandCollapse, setDisableExpandCollapse] = useState(false);

  function initSimpleTree(sourceData) {
    $(`#${treeTableId} .no-data-msg`).hide();
    const showCheckbox = props.showCheckbox ? props.showCheckbox : false;
    if (sourceData) {
      $(`#${treeTableId}`).fancytree({
        autoScroll: false,
        selectMode: 3,
        source: sourceData,
        checkbox: showCheckbox,
        expanded: true,
        extensions: ['filter'],
        filter: {
          counter: false,
          mode: 'hide',
          highlight: false,
        },
        table: {
          indentation: 20,
          nodeColumnIdx: 0,
        },
        click: function (event, data) {
          let node = data.node;
          let targetType = $.ui.fancytree.getEventTargetType(event.originalEvent);
          if (
            node.type.toLowerCase() === 'precondition' ||
            node.type.toLowerCase() === 'postcondition' ||
            node.type.toLowerCase() === 'group' ||
            node.type.toLowerCase() === 'dependantscript'
          ) {
            node.setExpanded(!node.expanded);
          } else if (targetType === 'title' && node.type.toLowerCase() !== 'group') {
            const nodeObj = {
              data: {
                ...node.data,
                _key: node.key,
                title: node.title,
                folder: node.folder,
              },
              node: node,
            };
            props.onNodeSelected(nodeObj);
          }
        },
        select: function (event, data) {
          const selectedNodes = [];
          const totalNodes = $.ui.fancytree.getTree(`#${treeTableId}`).count();
          const nodes = $.ui.fancytree.getTree(`#${treeTableId}`).getSelectedNodes();
          // Root node checkbox status
          setIsCheckAllInterminate(false);
          setIsCheckAll(totalNodes === nodes.length);

          // Partial check status
          if (nodes.length && totalNodes !== nodes.length) {
            setIsCheckAllInterminate(true);
          }

          nodes.map((node) => {
            selectedNodes.push({
              data: {
                ...node.data,
                _key: node.key,
                title: node.title,
                folder: data.node.folder,
              },
              node: node,
            });
          });
          props.onCheckedNodes(selectedNodes);
        },
        dblclick: function (e, data) {
          e.preventDefault();
          e.stopPropagation();
        },
        init: function () {},
        createNode: function (event, data) {
          const node = data.node;
          if (node.statusNodeType === 'nodata') {
            $(`#${treeTableId}`).html('');
            $(`#${treeTableId}`).show();
            return;
          }
          const nodeData = {
            data: {
              ...node.data,
              _key: node.key,
              title: node.title,
              folder: node.folder,
              type: node.type,
            },
            node: node,
          };
          const _liNode = node.span;

          props.colDefs.map((column, index) => {
            // Render Title column logic
            if (props.suiteType && (column.title === 'Start Time' || column.title === 'Duration')) {
              column.width = '10px';
            }
            if (index === 0) {
              let titleWidth = column.width;
              if (node.folder && column.folderTitleWidth) {
                titleWidth = column.folderTitleWidth;
              }
              const _node = $(node.span);
              _node.addClass(node.folder ? 'tree-folder-node' : 'tree-not-folder-node');

              _node.addClass(`type-${node.type}`);

              // Move title related items into tree-title container
              const _nodeHtml = _node.html();
              _node.html('');
              _node.append(
                `<span class="tree-title" style='width:${titleWidth ? titleWidth : ''};padding-right:${node.folder && column.paddingRight ? column.paddingRight : ''}'>${_nodeHtml}</span>`
              );

              const _span = $(node.span).find('.fancytree-title');

              if (column.render) {
                const title = _span.text();
                const isNodePrePostOrGroupStep  = !!node.folder || (node.folder === undefined && ['PRE', 'POST','Group','step'].includes(node?.type))
                _span.text('');
                if (isNodePrePostOrGroupStep) {
                  _span.append(
                    `<span class="fancytree-title ${!node?.folder ? 'tree-folder-title-repo-module-width' : ''}" title="${escapeHtml(title.replace(/  +/g, ' '))}" >${getHighlightedTitle(title)}</span>`
                  );
                } else
                if (node?.folder === true) {
                  _span.append(
                    `<span class="fancytree-title ${!node?.folder ? 'tree-folder-title-repo-module-width' : ''}" title="${escapeHtml(title)}">${node.parent?.children && (node.type === 'Group' || node.type === 'step') ? node.parent.children.findIndex((item) => item.data.executionDurationInHourMinSecFormat === node.data.executionDurationInHourMinSecFormat && item.key === node.key) + 1 : ''}${node.parent?.children && (node.type === 'Group' || node.type === 'step') ? '.' : ''} ${title.length > 40 ? title.substring(0, 40) + '...' : title}</span>`
                  );
                } else if (node?.folder === (false || undefined) && ['step', 'Group'].includes(node.type)) {
                  _span.append(
                    `<span class="fancytree-title ${!node?.folder ? 'tree-folder-title-repo-module-width' : ''}" title="${escapeHtml(title)}">${node.parent?.children && node.type === 'Group' ? node.parent.children.findIndex((item) => item.data.executionDurationInHourMinSecFormat === node.data.executionDurationInHourMinSecFormat && item.key === node.key) + 1 : ''}${node.parent?.children && node.type === 'Group' ? '.' : ''} ${title.length > 40 ? title.substring(0, 40) + '...' : title}</span>`
                  );
                } else if (node?.folder === false && ['step', 'Group'].includes(node.type)) {
                  _span.append(
                    `<span class="fancytree-title ${!node?.folder ? 'tree-folder-title-repo-module-width' : ''}" title="${escapeHtml(title)}">${node.parent?.children && node.type === 'Group' ? node.parent.children.findIndex((item) => item.data.executionDurationInHourMinSecFormat === node.data.executionDurationInHourMinSecFormat && item.key === node.key) + 1 : ''}${node.parent?.children && node.type === 'Group' ? '.' : ''} ${title.length > 40 ? title.substring(0, 40) + '...' : title}</span>`
                  );
                } else {
                  const [id, name] = splitedScriptNameAndID(title);
                  _span.append(
                    `<span class="fancytree-title ${!node?.folder ? 'tree-folder-title-repo-module-width' : ''} script-id-style-result-section" title="${escapeHtml(title)}">${node.parent?.children && (node.type === 'Group' || node.type === 'step') ? node.parent.children.findIndex((item) => item.data.executionDurationInHourMinSecFormat === node.data.executionDurationInHourMinSecFormat && item.key === node.key) + 1 : ''}${node.parent?.children && (node.type === 'Group' || node.type === 'step') ? '.' : ''}${getHighlightedTitle(id)} </span>`
                  );
                  _span.append(`<span class='name-id-separator'> - </span>`);
                  _span.append(
                    `<span class="fancytree-title ${!node?.folder ? 'tree-folder-title-repo-module-width' : ''}" title="${escapeHtml(title)}">${node.parent?.children && (node.type === 'Group' || node.type === 'step') ? node.parent.children.findIndex((item) => item.data.executionDurationInHourMinSecFormat === node.data.executionDurationInHourMinSecFormat && item.key === node.key) + 1 : ''}${node.parent?.children && (node.type === 'Group' || node.type === 'step') ? '.' : ''}${getHighlightedTitle(name)}</span>`
                  );
                }
                _span.append(`<span class="node-title-render"></span>`);
                _span.removeClass('fancytree-title').addClass('flex flex-1 fancytree-title-wrapper tree-title-width');
                if (node.type.toLowerCase() === 'group') {
                  _span.addClass('type-stepgroup');
                } else if (node.type.toLowerCase() === 'precondition') {
                  _span.addClass('type-precondition');
                } else if (node.type.toLowerCase() === 'postcondition') {
                  _span.addClass('type-postcondition');
                } else if (node.type.toLowerCase() === 'dependantscript') {
                  _span.addClass('type-dependantscript');
                }
                const _titleRender = _span.find('.node-title-render')[0];
                const el = React.createElement(column.render, nodeData);
                createRoot(_titleRender).render(el);
              }
            }

            // Render other columns logic
            if (index > 0) {
              $(_liNode).append(
                `<span class="node-col ${column.class}" style="width: ${column.width}">${index}</span>`
              );
              const _colRenderer = $(_liNode).find('.node-col')[index - 1];
              if (column.render) {
                const el = React.createElement(column.render, nodeData);
                createRoot(_colRenderer).render(el);
              } else {
                $(_colRenderer).html(node.data[column.field]);
              }
            }
          });
        },
        expand: function (event, data) {
          let persistData = [];
          const localData = localStorage.getItem(`${treeTableId}expanded`);
          if (localData) {
            persistData = localData.split('~');
            persistData.push(data?.node?.key);
            persistData = [...new Set(persistData)];
            localStorage.setItem(`${treeTableId}expanded`, persistData.join('~'));
          } else {
            persistData.push(data?.node?.key);
            localStorage.setItem(`${treeTableId}expanded`, persistData.join('~'));
          }
          showHideAllChild(GLOBAL.SHOW_ALL_CHILD);
          showHideAllConditions(GLOBAL.SHOW_ALL_CONDITIONS);
        },
        collapse: function (event, data) {
          let persistData = [];
          const localData = localStorage.getItem(`${treeTableId}expanded`);
          if (localData) {
            persistData = localData.split('~');
            persistData = [...new Set(persistData)];
            const index = persistData.indexOf(data?.node?.key);
            if (index > -1) {
              persistData.splice(index, 1);
            }
            localStorage.setItem(`${treeTableId}expanded`, persistData.join('~'));
          }
        },
        renderColumns: function (event, data) {},
        create: function (event, data) {},
      });
    }
    $('.fancytree-container').addClass('fancytree-connectors');

    // Events for fancytree-expander - hightlight lines on hover
    $('.fancytree-container').on('mouseenter', '.fancytree-expander', function (e) {
      e.preventDefault();
      $(this).closest('ul').find('> li').addClass('highlight-lines');
    });

    $('.fancytree-container').on('mouseleave', '.fancytree-expander', function (e) {
      e.preventDefault();
      $(this).closest('ul').find('> li').removeClass('highlight-lines');
    });
  }


  const getHighlightedTitle = (_title) => {
    if (props?.userSearched && props?.userSearched?.length && _title.length) {
      const startIndex = _title.toLowerCase().indexOf(props?.userSearched?.toLowerCase());
      let returnTitle = '';
      const titleArray = _title.split('');
      if (startIndex > -1) {
        titleArray.forEach((_t, ind) => {
          if (ind >= startIndex && ind < startIndex + props?.userSearched?.length) {
            if (ind === startIndex) {
              if (props?.userSearched.length === 1) {
                returnTitle += `<mark>${_t}</mark>`;
              } else {
                returnTitle += `<mark>${_t}`;
              }
            } else if (ind === startIndex + props?.userSearched.length - 1) {
              returnTitle += `${_t}</mark>`;
            } else {
              returnTitle += _t;
            }
          } else {
            returnTitle += _t;
          }
        });
        return returnTitle;
      }
      return _title;
    }
    return _title;
  };


  const expandCollapseAll = (isExpand) => {
    if (isExpand) {
      try {
        setDisableExpandCollapse(true);
        $.ui.fancytree
          .getTree(`#${treeTableId}`)
          ?.visit(async function (node) {
            await sleep(2);
            if (node.getLevel() === 1) {
              let moduleName = localStorage.getItem('moduleName');
              if (node.title === moduleName) {
                node.setExpanded(true);
              }
            }
            node.setExpanded(isExpand);
          })
          .then(() => {
            setDisableExpandCollapse(false);
            setIsExpandAll(isExpand);
          });
      } catch (error) {
        console.error('Error while expanding tree node:', error);
      }
    } else {
      setDisableExpandCollapse(true);
      $.ui.fancytree.getTree(`#${treeTableId}`).expandAll(isExpand);
      setDisableExpandCollapse(false);
      setIsExpandAll(isExpand);
    }
  };

  const showHideAllChild = (isShow) => {
    const childNodes = $(
      '.fancytree-exp-n:not(.fancytree-folder), .fancytree-exp-nl:not(.fancytree-folder), .tree-not-folder-node'
    ).parent('li');
    if (isShow) {
      childNodes.show();
    } else {
      childNodes.hide();
    }
    GLOBAL.SHOW_ALL_CHILD = isShow;
    setIsShowAllChild(isShow);
  };

  const showHideAllConditions = (isShow) => {
    const conditions = props.conditions;
    $.ui.fancytree.getTree(`#${treeTableId}`)?.visit(function (node) {
      conditions.forEach((element) => {
        if (element && element.key && node.data[element.key] === element.value) {
          if (isShow) {
            $(node.li).show();
          } else {
            $(node.li).hide();
          }
        }
      });
    });
    GLOBAL.SHOW_ALL_CONDITIONS = isShow;
    setIsAllConditions(isShow);
  };

  const checkUncheckAll = (e) => {
    const isChecked = e.target.checked;
    $.ui.fancytree.getTree(`#${treeTableId}`).selectAll(isChecked);
    setIsCheckAll(isChecked);
  };

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };
  const expandPersistedNodes = () => {
    $.ui.fancytree.getTree(`#${treeTableId}`)?.visit(async function (node) {
      const localData = localStorage.getItem(`${treeTableId}expanded`);
      if (localData) {
        let persistData = localData.split('~');
        if (persistData.includes(node.key)) {
          if (!node.expanded) {
            node.setExpanded(true);
          }
        }
      }
    });
  };
  const updateRoot = async (subTreeData, addCount) => {
    let startCount = 0;
    let subsetCount = addCount;
    let subTreeLength = subTreeData.length;
    let allowRender = true;
    await sleep(10);
    while (allowRender) {
      let tempData = subTreeData.slice(startCount, subsetCount);
      var treeRootNode = $.ui.fancytree.getTree(`#${treeTableId}`);
      if (treeRootNode) {
        treeRootNode?.rootNode?.addNode(tempData);
      }
      if (subsetCount === subTreeLength || subsetCount >= subTreeLength) {
        allowRender = false;
      }
      startCount = startCount + addCount;
      subsetCount = subsetCount + addCount;
      await sleep(5);
    }
    expandPersistedNodes();
  };

  useEffect(() => {
    if (props.id) {
      setTreeTableId(props.id);
    }
    let defaultCount = 7;
    let addCount = 5;
    let sourceData = [];
    if (props.data && props.data.length) {
      sourceData = props.data.slice(0, defaultCount);
    }
    initSimpleTree(sourceData);
    expandPersistedNodes();
    let subTreeData = props.data.slice(defaultCount, props.data.length);
    if (subTreeData && subTreeData.length) {
      updateRoot(subTreeData, addCount);
    }
  }, [treeTableId]);

  useEffect(() => {}, [props.conditions]);

  useEffect(() => {
    GLOBAL.LABELS = { ...GLOBAL.LABELS, ...props.labels };
  }, [props.labels]);

  useEffect(() => {
    showHideAllConditions(!props.hideConditions);
  }, [props.hideConditions]);

  useEffect(() => {
    showHideAllChild(!props.hideElements);
  }, [props.hideElements]);


  /* 
  Have commented the code to implement persist tree logic. Will analze and later we will remove this code if not required. 
  useEffect(() => {
    if (props.expandAll === undefined) return;
    expandCollapseAll(props.expandAll);
  }, [props.expandAll]); */

  return (
    <div className=" result-tree">
      <div className=" table-tree">
        {/* Header */}
        <div className="tree-header-wrp">
          {props.colDefs.map((column) => {
            if (props.suiteType && (column.title === 'Start Time' || column.title === 'Duration')) {
              column.title = '';
            }
            if (column.field === 'title') {
              return (
                <div className={`tree-header ${column.class} flex flex-row relative`}>
                  <div className="">{column.title}</div>
                  <div className="flex flex-row relative">
                    <span className=" absolute my-0 ml-0">
                      <button
                        className="root-btn exapnd-all-btn ml-3"
                        onClick={() => {
                          expandCollapseAll(!isExpandAll);
                        }}
                        title={isExpandAll ? GLOBAL.LABELS.COLLAPSE_ALL : GLOBAL.LABELS.EXPAND_ALL}
                        disabled={disableExpandCollapse}
                      >
                        {isExpandAll ? (
                          <CollapseAllIcon disable={disableExpandCollapse} />
                        ) : (
                          <ExpandAllIcon disable={disableExpandCollapse} />
                        )}
                      </button>
                    </span>
                    <span className="absolute my-0 ml-11">
                      <button
                        className="root-btn show-all"
                        onClick={() => {
                          showHideAllChild(!isShowAllChild);
                        }}
                        title={isShowAllChild ? GLOBAL.LABELS.HIDE_ALL_ELEMENTS : GLOBAL.LABELS.SHOW_ALL_ELEMENTS}
                      >
                        {isShowAllChild ? <HideAllChildsIcon /> : <ShowAllChildsIcon />}
                      </button>
                    </span>

                    <span className="absolute my-0 ml-20">
                      {!props.hideConditionBtn && (
                        <button
                          className="root-btn show-all-condition"
                          onClick={() => {
                            showHideAllConditions(!isShowAllConditions);
                          }}
                          title={
                            isShowAllConditions
                              ? GLOBAL.LABELS.HIDE_PRE_POST_CONDITION
                              : GLOBAL.LABELS.SHOW_PRE_POST_CONDITION
                          }
                        >
                          {isShowAllConditions ? <HideAllConditionIcon /> : <ShowAllConditionIcon />}
                        </button>
                      )}
                    </span>
                  </div>
                </div>
              );
            }

            return (
              <span className={`tree-header ${column.class}`} style={{ width: column.width }}>
                {column.title}
              </span>
            );
          })}
        </div>

        {/* Root Node */}
        <div className="tree-root-wrp">
          {props.colDefs.map((column) => {
            const showCheckbox = props.showCheckbox ? props.showCheckbox : false;
            let rootValue = props.rootData ? props.rootData[column.field] : '';
            if (column.field === 'title' && rootValue.toLowerCase() === 'root') {
              rootValue = rootValue.concat(' Module');
            }
            const colRender = column.render ? column.render({ data: props.rootData, node: {}, isRootNode: true }) : '';

            if (column.field === 'title') {
              return (
                <span className="root-title-wrp">
                  {showCheckbox && (
                    <Checkbox
                      color="primary"
                      disableRipple
                      checked={isCheckAll}
                      indeterminate={isCheckAllIndeterminate}
                      onChange={checkUncheckAll}
                      className="root-check-all"
                    />
                  )}
                  {/* <button 
                  className="root-btn exapnd-all-btn" 
                  onClick={() => {expandCollapseAll(!isExpandAll)}}
                  title={isExpandAll ? GLOBAL.LABELS.COLLAPSE_ALL : GLOBAL.LABELS.EXPAND_ALL}
                  disabled={disableExpandCollapse}
                >
                  {isExpandAll ? <CollapseAllIcon disable={disableExpandCollapse} /> : <ExpandAllIcon disable={disableExpandCollapse}/>}
                </button>
                <button 
                  className="root-btn show-all" 
                  onClick={() => {showHideAllChild(!isShowAllChild)}}
                  title={isShowAllChild ? GLOBAL.LABELS.HIDE_ALL_ELEMENTS : GLOBAL.LABELS.SHOW_ALL_ELEMENTS}
                >
                  {isShowAllChild ? <HideAllChildsIcon /> : <ShowAllChildsIcon />}
                </button>

                {!props.hideConditionBtn && (
                  <button 
                    className="root-btn show-all-condition" 
                    onClick={() => {showHideAllConditions(!isShowAllConditions)}}
                    title={isShowAllConditions ? GLOBAL.LABELS.HIDE_PRE_POST_CONDITION : GLOBAL.LABELS.SHOW_PRE_POST_CONDITION}
                  >
                    {isShowAllConditions ? <HideAllConditionIcon /> : <ShowAllConditionIcon />}
                  </button>
                )} */}

                  <span className={`root-header ${column.class}`}>
                    {rootValue}
                    {colRender ? colRender : rootValue}
                  </span>
                </span>
              );
            }
            return (
              <span
                className={`root-header ${column.class}`}
                style={{ width: column.field === 'type' ? '0px' : column.width }}
              >
                {colRender ? colRender : rootValue}
              </span>
            );
          })}
        </div>

        {treeLoading && <CommonLoader />}

        <div className="result-tree-wrp" id="journal-scroll">
          <div id={treeTableId} className="table-tree"></div>
        </div>

        <div className="no-data-msg">{GLOBAL.LABELS.NO_DATA_MSG}</div>
      </div>
    </div>
  );
};

export default ResultTree;

import '@pages/licenses/styles/license-alert-popup.scss';
import cx from 'classnames';
import style from '@pages/licenses/styles/payment-success-popup.module.scss';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import TopBorderPopup from './TopBorderPopup';
import { FREE_LICENSE_TYPE, showOnlyDateAndTime } from '@src/util/licenseUtils';
import PropTypes from 'prop-types';
import { getUserName } from '@src/util/common_utils';
import { getProjectListData } from '@api/api_services';
import { LICENSE_DETAILS_CONSTANTS } from '@common/ui-constants';
import { ReactComponent as PaymentFailed } from '@assets/svg-imports/Payment_Failed.svg';
import { ReactComponent as PaymentPending } from '@assets/svg-imports/Payment_Pending.svg';

function PaymentPopup({
  open,
  onClose,
  licenseDetails,
  action,
  orderDetails,
  licenses,
  handleContinue,
  handleTryAgain,
}) {
  const isCloseVisible = licenses?.length > 1;
  const getAllowedProject = async () => {
    const allowedProject = await getProjectListData();
    const response = allowedProject.data.responseObject;

    localStorage.setItem('allowedProjects', JSON.stringify(response));
  };

  const getOrderStatus = (status) =>
    status === 'Success' || licenseDetails?.licenseType === FREE_LICENSE_TYPE
      ? 'success'
      : ['Failure', 'Aborted', 'Cancelled']?.includes(status)
        ? 'danger'
        : 'warning';
  let orderStatus = getOrderStatus(orderDetails?.orderStatus);

  const IconRenderer = () => {
    if (orderStatus === 'success') {
      return <CheckCircleRoundedIcon className="w-full h-auto" />;
    } else if (orderStatus === 'danger') {
      return <PaymentFailed />;
    } else {
      return <PaymentPending />;
    }
  };
  return (
    <TopBorderPopup
      open={open}
      onClose={onClose}
      status={orderStatus}
      allowOutsideClick={false}
      maxWidth="sm"
      className={'blur-background'}
    >
      <div className={cx('flex my-3 mx-6 py-4')}>
        <div className={cx('w-2/12', style['tick-svg'], style[`text-${orderStatus}`])}>{IconRenderer()}</div>
        <div className="w-10/12 pt-2 pb-4">
          <h1 className={cx(style['popup-title'], style[`text-${orderStatus}`], 'fontPoppinsMediumXX2')}>
            {licenseDetails?.licenseType === FREE_LICENSE_TYPE
              ? LICENSE_DETAILS_CONSTANTS?.FREE_LICENSE
              : orderDetails?.orderStatus === 'Success'
                ? LICENSE_DETAILS_CONSTANTS?.PAYMENT_SUCCESSFUL
                : ['Failure', 'Aborted']?.includes(orderDetails?.orderStatus)
                  ? LICENSE_DETAILS_CONSTANTS?.PAYMENT_FAILED
                  : orderDetails?.orderStatus === 'Cancelled'
                    ? LICENSE_DETAILS_CONSTANTS?.PAYMENT_CANCELLED
                    : LICENSE_DETAILS_CONSTANTS?.PAYMENT_PENDING}
          </h1>
          <p className="fontPoppinsRegularMd">Hi {getUserName()},</p>
          <div className={cx(style['x'], 'mb-3', 'fontPoppinsRegularMd')}>
            {licenseDetails?.licenseType === FREE_LICENSE_TYPE ? (
              <p className="fontPoppinsRegularMd">
                {LICENSE_DETAILS_CONSTANTS?.CONGRATULATIONS_YOUR_FREE_TRIAL}&nbsp;{' '}
                <span className="fontPoppinsSemiboldMd">{LICENSE_DETAILS_CONSTANTS?.FIREFLINK}</span>&nbsp;
                {LICENSE_DETAILS_CONSTANTS?.FROM_TODAY_CLICK_ON}&nbsp;{' '}
                <span className="fontPoppinsSemiboldMd">Continue</span>&nbsp;{' '}
                {LICENSE_DETAILS_CONSTANTS?.TO_SWITCH_TO_YOUR} &nbsp;
                <span className="fontPoppinsSemiboldMd">{licenseDetails?.licenseName}</span>&nbsp;
                {LICENSE_DETAILS_CONSTANTS?.THANK_YOU_FOR_CHOOSING} &nbsp;
                <span className="fontPoppinsSemiboldMd">{LICENSE_DETAILS_CONSTANTS?.FIREFLINK}</span>
              </p>
            ) : orderDetails?.orderStatus === 'Success' ? (
              <>
                <p className="fontPoppinsRegularMd">
                  <span className="fontPoppinsSemiboldMd">Congratulations!</span>{' '}
                  {LICENSE_DETAILS_CONSTANTS?.PAYMENT_SUCCESSFUL_TEXT}
                </p>
                <p className="fontPoppinsRegularMd">
                  Click on <span className="fontPoppinsSemiboldMd">Continue</span> to switch to your{' '}
                  <span>{orderDetails?.license?.licenseName}</span>.
                </p>
                <p className="fontPoppinsRegularMd">{LICENSE_DETAILS_CONSTANTS?.THANK_YOU_FOR_PURCHASE}</p>
              </>
            ) : ['Failure', 'Aborted']?.includes(orderDetails?.orderStatus) ? (
              <>
                <p className="fontPoppinsRegularMd">
                  <span className="fontPoppinsSemiboldMd">Oops!</span> {LICENSE_DETAILS_CONSTANTS?.PAYMENT_FAILED_TEXT}
                </p>
                <p>{LICENSE_DETAILS_CONSTANTS?.PLEASE_CHECK_YOUR_PAYMENT} </p>
              </>
            ) : orderDetails?.orderStatus === 'Cancelled' ? (
              <p className="fontPoppinsRegularMd">
                {LICENSE_DETAILS_CONSTANTS?.YOUR_PAYMENT_HAS_BEEN_CANCELLED}
                <span className="fontPoppinsSemiboldMd">Try again</span>
                {LICENSE_DETAILS_CONSTANTS?.TO_CONTINUE_PAYMENT_PROCESS}
              </p>
            ) : (
              <p className="fontPoppinsRegularMd">
                {LICENSE_DETAILS_CONSTANTS?.PAYMENT_CURRENTLY} <span className="fontPoppinsSemiboldMd">Pending</span>.
                {LICENSE_DETAILS_CONSTANTS?.WE_ARE_PROCESSING_YOUR_TRANSACTION}
              </p>
            )}
          </div>
          {licenseDetails?.licenseType !== FREE_LICENSE_TYPE && orderDetails?.orderStatus !== 'Cancelled' ? (
            <>
              <div className="flex my-2">
                <p className={cx('w-1/2', style['statement'], 'fontPoppinsRegularSm')}>
                  {LICENSE_DETAILS_CONSTANTS?.PAYMENT_MODE} :
                </p>
                <p className={cx('w-1/2', style['statement'], style['text-dark'], 'fontPoppinsSemiboldSm')}>
                  {licenseDetails?.licenseType === FREE_LICENSE_TYPE ? '--' : orderDetails?.paymentMode}
                </p>
              </div>
              <div className="flex my-2">
                <p className={cx('w-1/2', style['statement'], 'fontPoppinsRegularSm')}>
                  {LICENSE_DETAILS_CONSTANTS?.DATE_AND_TIME} :
                </p>
                <p className={cx('w-1/2', style['statement'], style['text-dark'], 'fontPoppinsSemiboldSm')}>
                  {showOnlyDateAndTime(orderDetails?.transactionDate)}
                </p>
              </div>
              <div className="flex my-2">
                <p className={cx('w-1/2', style['statement'], 'fontPoppinsRegularSm')}>
                  {LICENSE_DETAILS_CONSTANTS?.TRANSACTION_AMOUNT}:
                </p>
                <p className={cx('w-1/2', 'fontPoppinsSemiboldSm')}>
                  ${orderDetails?.amount}
                  {licenseDetails?.licenseType === FREE_LICENSE_TYPE && (
                    <span className={cx('ml-3', style['text-success'])}>{LICENSE_DETAILS_CONSTANTS?.FREE}</span>
                  )}
                </p>
              </div>
              <div className="flex my-2">
                <p className={cx('w-1/2', style['statement'], 'fontPoppinsRegularSm')}>
                  {LICENSE_DETAILS_CONSTANTS?.TRANSACTION_ID} :
                </p>
                <p className={cx('w-1/2', style['statement'], style['text-dark'], 'fontPoppinsSemiboldSm')}>
                  {orderDetails?.id}
                </p>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <TopBorderPopup.Footer
        onCloseClick={() => {
          onClose();
          getAllowedProject();
        }}
        closeButtonText="Close"
        isCloseVisible={isCloseVisible}
      >
        {/* Commented for future usage */}
        {/* {orderDetails?.license?.licenseType !== FREE_LICENSE_TYPE && (
          <TopBorderPopup.FooterButton
            buttonText="Download Receipt"
            className={cx(style["download-title"], "fontMontserratMediumXs")}
            onClick={() => {}}
            disabled
          />
        )} */}
        {licenses?.length >= 1 &&
        (orderDetails?.orderStatus === 'Success' || licenseDetails?.licenseType === FREE_LICENSE_TYPE) ? (
          <TopBorderPopup.FooterButton
            buttonText="Continue"
            className={cx(style['download-title'], 'fontMontserratMediumXs')}
            onClick={() => handleContinue(orderDetails?.license?.licensePlanId || licenseDetails?.id)}
          />
        ) : orderStatus === 'danger' ? (
          <TopBorderPopup.FooterButton
            buttonText="Try Again"
            className={cx(style['download-title'], 'fontMontserratMediumXs')}
            onClick={handleTryAgain}
          />
        ) : null}
      </TopBorderPopup.Footer>
    </TopBorderPopup>
  );
}

PaymentPopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  licenseDetails: PropTypes.object,
  action: PropTypes.string.isRequired,
  orderDetails: PropTypes.object,
};

PaymentPopup.propTypes = {
  open: true,
  onClose: () => {},
  licenseDetails: {},
  orderDetails: {},
};

export default PaymentPopup;

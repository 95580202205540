import React from 'react';
import style from '@pages/auth/SignupPage/signup.module.scss';
import cx from 'classnames';
import { GLOBAL_CONSTANTS, SIGN_UP } from '@src/common/ui-constants';

function SignUpText() {
  return (
    <div className={cx(style['signUp_text_container'])}>
      <div className={cx(style['signup_header_text'])}>{GLOBAL_CONSTANTS.APP_TAG_LINE}</div>
      <div className={cx(style['signup_text_message'])}>{SIGN_UP.SIGNUP_MESSAGE}</div>
      <div className={cx(style['signup_ff_desc'])}>{SIGN_UP.FF_DESCRIPTION}</div>
      <p className={cx(style['signup_copyright_text'])}>{SIGN_UP.COPY_RIGHT}</p>
    </div>
  );
}
export default SignUpText;

import cx from 'classnames';
import DashboardStyle from '../styles/dashboard.module.scss';

function ChartCard({ children, className, style = {}, roundedDirection = '', roundedBorders = true }) {
  return (
    <div
      className={cx(`h-full w-full`, className, DashboardStyle['shadow'], DashboardStyle['chart-card'], {
        [`rounded${roundedDirection ? `-${roundedDirection}` : ''}-lg`]: roundedBorders,
      })}
      style={style}
    >
      {children}
    </div>
  );
}

export default ChartCard;

import { useEffect } from 'react';

const useSyncContextValues = (formik, context, data) => {
  useEffect(() => {
    if (context && context.values && context.values[data]) {
      formik.setValues({
        ...formik.values,
        ...context.values[data],
      });
    }
  }, [context]);
};

export default useSyncContextValues;

import React, { useContext, useEffect, useState, useMemo } from 'react';
import MyInput from '@pagescommon/Inputs/MyInput';
import MyPassword from '@pagescommon/Inputs/MyPassword';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { RestContext } from '@pages/test-development/script/scripts/webservice/restapi/restapi';
import { WEBSERVICE } from '@pages/test-development/script/scripts/webservice/restapi/constants/constants';
import '@pages/test-development/script/scripts/webservice/restapi/css/common_Webservice.scss';
import useSyncContextValues from '@src/hooks/useSyncContextValues';

export default function Basic_auth(props) {
  const context = useContext(RestContext);
  const formik = useFormik({
    initialValues: {
      userName: '',
      password: '',
      ...(context?.values?.basicAuthData || {}),
    },
    validationSchema: Yup.object({
      userName: Yup.string().required(WEBSERVICE.BASIC_USER_NAME),
      password: Yup.string().required(WEBSERVICE.BASIC_PASSWORD),
    }),
  });
  useSyncContextValues(formik, context, 'basicAuthData');
  const [authPassword, setAuthPassword] = useState();
  const [authName, setAuthName] = useState('');

  const handleChange = (e) => {
    context.setFieldValue('authType', 'Basic');
    const values = { ...(context?.values?.basicAuthData || {}), [e.target.name]: e.target.value };
    context.setFieldValue('basicAuthData', values);
    formik.handleChange(e);
    formik.validateForm(values).then((errors) => context.setFieldValue('errors', errors));
    props.handleSaveAsSteps(false);
  };

  useEffect(() => {
    formik.validateForm().then((errors) => context.setFieldValue('errors', errors));
    return () => context.setFieldValue('currentBasicAuthValue', {});
  }, []);

  const isEditable = useMemo(() => window.permission?.isEditAccess('testDevelopment'), []);

  const nameOnChange = (e) => {
    const { value, data, action, name } = e.target || 'unknown';
    const values1 = {
      ...(context?.values?.basicAuthData || {}),
      [name]: props.getUserInputValue(value, false, data, action),
    };
    context.setFieldValue('basicAuthData', values1);
    const values2 = { ...(context?.values?.authData || {}), [name]: value };
    context.setFieldValue('authData', values2);
    setAuthName(value);
    const currentBasicAuthValue = { ...(context.values.currentBasicAuthValue || {}), [name]: e };
    context.setFieldValue('currentBasicAuthValue', currentBasicAuthValue);
  };
  const authPwOnChange = (e) => {
    setAuthPassword(e.target.value);
  };

  return (
    <div className="mt-4">
      <form onSubmit={formik.handleSubmit} className="basic-auth-form-style" noValidate>
        <div>
          <div className="input-filed-label fontPoppinsMediumSm text-left" onDragStart={props.handleDragStart}>
            {' '}
            <span className="common-auth-star-symbol">*</span> Username
          </div>
          <MyInput
            canCreateVariable={isEditable}
            type="text"
            placeholder="Enter username"
            variableInput={true}
            className="my-input-style-for-authorization"
            error={formik.errors.userName && formik.touched.userName}
            value={authName ? authName : props.getUserInputValue(formik.values.userName, true)}
            onMouseDown={formik.handleBlur}
            onChange={(e) => {
              handleChange(e);
              nameOnChange(e);
            }}
            autoComplete={WEBSERVICE.PREVENT_BROWSER_USERNAME_AUTOFILL}
            name="userName"
            helperText={formik.touched.userName && formik.errors.userName}
            currentValue={context.values.currentBasicAuthValue?.userName}
            inputValue={'input'}
            {...props}
          />
        </div>
        <div className="basic-auth-error-input-alignment">
          <div className="input-filed-label fontPoppinsMediumSm text-left" onDragStart={props.handleDragStart}>
            {' '}
            <span className="common-auth-star-symbol">*</span> Password
          </div>
          <MyPassword
            placeholder="Enter password"
            error={formik.errors.password && formik.touched.password}
            value={authPassword ? authPassword : formik.values.password}
            onBlur={formik.handleBlur}
            autoComplete={WEBSERVICE.PREVENT_BROWSER_PASSWORD_AUTOFILL}
            className="placeholderpass my-password-style-for-authorization"
            onChange={(e) => {
              handleChange(e);
              authPwOnChange(e);
            }}
            name="password"
            helperText={formik.touched.password && formik.errors.password}
            inputValue={'input'}
          />
        </div>
      </form>
    </div>
  );
}

import { PLUGIN_CONSTANTS } from '@src/common/ui-constants';
import { AZURE_DEVOPS_CONSTANTS } from './plugin_constants';
import { getLicenseFeatures } from '@src/util/common_utils';

export const pluginData = [
  {
    name: PLUGIN_CONSTANTS.CLOUD_PLATFORMS,
    label: 'Cloud Platform',
    options: [
      {
        label: 'Browser Stack',
        image: '/assets/logo/BrowserStack@2x.png',
        path: '/configuration/integration/plugin/BrowserStack',
        content: 'Application Monitoring Tools',
      },
      {
        label: 'Saucelabs',
        image: '/assets/logo/SauceLabs@2x.png',
        path: '/configuration/integration/plugin/sauceLab',
        content: 'Cross Browser Testing and more.',
      },
      {
        label: 'Lambda Test',
        image: '/assets/logo/LambdaTest.png',
        path: '/configuration/integration/plugin/LambdaTest',
        content: 'Test Execution Platform and Automation Testing.',
      },
      /*  future requirements
                {
                    label: 'AWS',
                    image: "/assets/logo/AWS@2x.png",
                },
                {
                    label: 'Azure',
                    image: "/assets/logo/MS-Azure@2x.png",
                },
                {
                    label: 'IBM Cloud',
                    image: "/assets/logo/IBMCloud@2x.png",
                }
            */
    ],
  },
  {
    name: PLUGIN_CONSTANTS.CI_CD_TOOLS,
    label: 'CI / CD',
    options: [
      {
        label: 'Jenkins',
        image: '/assets/logo/Jenkins@2x.png',
        path: 'JENKINS',
        content: 'Java with plugins built for continuous integration.',
      },
      {
        label: 'Github',
        image: '/assets/logo/GitHub-logo.png',
        path: '/configuration/integration/plugin/GitHub',
        content: 'A code hosting platform for version control and collaboration.',
      },
      {
        label: 'Bitbucket',
        image: '/assets/logo/bitbucket-logo@2x.png',
        path: '/configuration/integration/plugin/Bitbucket',
        content: 'A cloud-based Git repository hosting service.',
      },
      {
        label: 'Azure',
        image: '/assets/logo/MS-Azure@2x.png',
        path: '/configuration/integration/plugin/Azure',
        content: AZURE_DEVOPS_CONSTANTS.CONTENT,
      },
      /*  future requirements
            {
                label: 'Bamboo',
                image: "/assets/logo/Bambo@2x.png",
            },
            {
                label: 'AWS',
                image: "/assets/logo/AWS@2x.png",
            },
            {
                label: 'Codeship',
                image: "/assets/logo/CodeShip@2x.png",
            }
            */
    ],
  },
  {
    name: PLUGIN_CONSTANTS.MANAGEMENT_TOOLS,
    label: 'Project Management / Test Management / Defect Management',
    options: [
      {
        label: 'JIRA',
        image: '/assets/logo/jira_logo.png',
        path: '/configuration/integration/plugin/Jira',
        content: 'Developers to plan, track and work faster.',
      },
      {
        label: 'Gitlab',
        image: '/assets/logo/gitlab-logo.png',
        path: '/configuration/integration/plugin/Gitlab',
        content: 'AI-powered DevSecOps Platform.',
      },
      /*  future requirement
            {
                label: 'HP ALM',
                image: "/assets/logo/HP-ALM@2x.png",
            },
            */
    ],
  },
  {
    name: PLUGIN_CONSTANTS.COLLABORATION_TOOLS,
    label: 'Collaboration Tools',
    options: [
      {
        label: 'Slack',
        image: '/assets/logo/Slack@2x.png',
        path: '/configuration/integration/plugin/Slack',
        content: 'Developers to plan, track and work faster.',
      },
      /* future requirement
            {
                label: 'Others',
                image: "/assets/logo/Custom@2x.png",
            },
            */
    ],
  },
];

export const displayPlugin = (pluginName) => {
  const hasAutomation = global.privilege.hasAutomation(getLicenseFeatures());
  const cloudAndCiCd = [PLUGIN_CONSTANTS.CLOUD_PLATFORMS, PLUGIN_CONSTANTS.CI_CD_TOOLS];
  const collabAndManagement = [PLUGIN_CONSTANTS.MANAGEMENT_TOOLS, PLUGIN_CONSTANTS.COLLABORATION_TOOLS];
  if (cloudAndCiCd?.includes(pluginName) && hasAutomation) {
    return true;
  } else if (collabAndManagement?.includes(pluginName)) {
    return true;
  } else return false;
};

import cx from 'classnames';
import LicenseRowCardStyle from '@pages/licenses/styles/license-row-card.module.scss';
import { PAYMENT_HISTORY_CONSTANTS } from '@src/common/ui-constants';
import { HighlightText } from '@util/common_utils';
import { Tooltip } from '@mui/material';

function LicensePaymentHistory({ isSelected, data, userSearched }) {
  return (
    <div
      className={cx(LicenseRowCardStyle['wrapper'], {
        [LicenseRowCardStyle['selected']]: isSelected,
      })}
    >
      <div className="flex items-center h-full">
        <div className={cx('flex items-center justify-between px-2', LicenseRowCardStyle['col-11.5'])}>
          <div className="flex flex-col w-1/12 ml-2">
            <h2 className={cx(LicenseRowCardStyle['title'], 'fontPoppinsSemiboldSm')}>
              {PAYMENT_HISTORY_CONSTANTS?.TRANSACTION_ID}
            </h2>
            <span className={cx(LicenseRowCardStyle['title'], 'fontPoppinsSemiboldSm whitespace-nowrap')}>
              {data?.trackingId}
            </span>
          </div>
          <div className="flex flex-col w-1/12 ">
            <h2 className={cx(LicenseRowCardStyle['title'], 'fontPoppinsSemiboldSm')}>
              {PAYMENT_HISTORY_CONSTANTS?.LICENSE_NAME}
            </h2>
            <Tooltip title={data?.license?.licenseName} disableInteractive>
              <span className={cx(LicenseRowCardStyle['title'], 'fontPoppinsSemiboldSm whitespace-nowrap')}>
                <HighlightText
                  text={
                    data?.license?.licenseName?.length > 18
                      ? `${data?.license?.licenseName?.slice(0, 18)}...`
                      : data?.license?.licenseName
                  }
                  highlight={userSearched}
                />
              </span>
            </Tooltip>
          </div>
          <div className="flex flex-col w-1/12 ">
            <h2 className={cx(LicenseRowCardStyle['title'], 'fontPoppinsSemiboldSm')}>
              {PAYMENT_HISTORY_CONSTANTS?.PAYMENT_STATUS}
            </h2>
            <span
              className={cx(LicenseRowCardStyle['title'], 'fontPoppinsMediumSm whitespace-nowrap', {
                [LicenseRowCardStyle['status_Success']]: ['Success', 'Successful']?.includes(data?.status),
                [LicenseRowCardStyle['status_Warning']]: data?.status === 'Pending',
                [LicenseRowCardStyle['status_Failed']]: data?.status === 'Failed',
              })}
            >
              <HighlightText
                text={['Success', 'Successful']?.includes(data?.status) ? 'Success' : data?.status}
                highlight={userSearched}
              />
            </span>
          </div>
          <div className="flex flex-col w-1/12 ">
            <h2 className={cx(LicenseRowCardStyle['title'], 'fontPoppinsSemiboldSm')}>
              {PAYMENT_HISTORY_CONSTANTS?.PAYMENT_MODE}
            </h2>
            <span className={cx(LicenseRowCardStyle['title'], 'fontPoppinsSemiboldSm')}>
              <HighlightText text={data?.paymentMode} highlight={userSearched} />
            </span>
          </div>
          <div className="flex flex-col w-1/12 ">
            <h2 className={cx(LicenseRowCardStyle['title'], 'fontPoppinsSemiboldSm')}>
              {PAYMENT_HISTORY_CONSTANTS?.PAYMENT_MADE_BY}
            </h2>
            <span className={cx(LicenseRowCardStyle['title'], 'fontPoppinsSemiboldSm')}>
              <HighlightText text={data?.initiatedBy || '--'} highlight={userSearched} />
            </span>
          </div>
          <div className="flex flex-col w-1/12 ">
            <h2 className={cx(LicenseRowCardStyle['title'], 'fontPoppinsSemiboldSm')}>
              {PAYMENT_HISTORY_CONSTANTS?.TRANSACTION_TIME}
            </h2>
            <span className={cx(LicenseRowCardStyle['title'], 'fontPoppinsSemiboldSm')}>{data?.transactionDate}</span>
          </div>
          <div className="flex flex-col w-1/12 mr-4">
            <h2 className={cx(LicenseRowCardStyle['title'], 'fontPoppinsSemiboldSm')}>
              {PAYMENT_HISTORY_CONSTANTS?.TRANSACTION_AMOUNT}
            </h2>
            <span className={cx(LicenseRowCardStyle['title'], 'fontPoppinsSemiboldSm')}>
              <HighlightText text={`$${data?.amount}`} highlight={userSearched} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LicensePaymentHistory;

import React, { useEffect } from 'react';
import { forgotPasswordRequest } from '@api/api_services';
import BackArrow from '@assets/svg-imports/BackArrowOrange.svg';
import LabeledInput from '@pages/common/Inputs/LabeledInput';
import { GLOBAL_CONSTANTS } from '@src/common/ui-constants';
import { getWebSiteHostName } from '@util/common_utils';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import commonStyles from '@pages/auth/common.module.scss';
import cx from 'classnames';
import CommonPage from '../common/CommonPage';
import CommonBorder from '../common/common-border';
import '@pages/auth/login.css';

import { isEmailValid } from '@util/common_utils';

export default function ForgetPassword(props) {
  const [errorshow, setErrorShow] = useState(false);
  const [createUpdateCalled, setCreateUpdateCalled] = useState(true);
  let history = useHistory();
  const formik = useFormik({
    initialValues: {
      emailId: '',
    },
    validationSchema: Yup.object({
      emailId: Yup.string()
        .test('emailId', 'Enter valid email Id', async (value) => {
          return isEmailValid(value);
        })
        .required('Email id is required'),
    }),
    onSubmit: (values) => {
      if (createUpdateCalled) {
        setCreateUpdateCalled(false);
        const data = {
          emailId: values.emailId,
        };
        forgotPasswordRequest(data)
          .then((res) => {
            const response = res.data.responseCode;
            if (response === 200 && res.status === 200) {
              history.push('/email-send');
            } else if (response !== 200) {
              setErrorShow(true);
            }
            if (res.data && res.data.message === 'User is not activated') {
              history.push('/user-activation?email=' + values.emailId);
            }
          })
          .catch((error) => {});
      }
    },
  });
  const URLPathname = window.location.pathname;
  const onpremENV = process.env.REACT_APP_ENVIRONMENT;

  useEffect(() => {
    if (URLPathname === '/forget-password' && onpremENV !== 'onprem') {
      localStorage.clear();
      window.location.replace(getWebSiteHostName() + `/forgot-password`);
    }
  }, []);

  return (
    <>
      <CommonPage wrapperClassname={`common-padding`}>
        <div className={cx('flex justify-center', commonStyles['content-header'])}>
          {GLOBAL_CONSTANTS.FORGOT_YOUR_PASSWORD}
        </div>
        <CommonBorder />
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col gap-4 mt-4">
            <div className="text-center fontPoppinsRegularMd width-97-per">
              {GLOBAL_CONSTANTS.FORGOT_PASSWORD_MESSAGE}
            </div>
            <div className="h-14">
              <LabeledInput
                label="Email"
                required
                placeholder="Enter your email"
                error={(formik.errors.emailId && formik.touched.emailId) || errorshow}
                value={formik.values.emailId}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  setCreateUpdateCalled(true);
                }}
                onChange={formik.handleChange}
                autoComplete="off"
                onFocus={() => setErrorShow(false)}
                name="emailId"
                autoFocus
                helperText={<>{formik.touched.emailId && formik.errors.emailId}</>}
                wrapperClassName="fontPoppinsRegularSm"
              />
              {errorshow && (
                <span className="errorBelow fontPoppinsRegularSm mr-10">
                  {`This email id doesn't exist, please enter valid email id`}
                </span>
              )}
            </div>
            <div className="text-center fontPoppinsRegularMd">{GLOBAL_CONSTANTS.FORGOT_CONFIRM_MESSAGE}</div>

            <div className="flex justify-center items-center gap-4 flex-col">
              <button
                type="submit"
                className={cx('fontPoppinsSemiboldLg', commonStyles['common-btn'], commonStyles['btn-bg-orange'])}
              >
                Send Reset Link To Email
              </button>

              <Link to={`/signin`} className={cx('fontPoppinsSemiboldLg', commonStyles['common-btn'], commonStyles['orange-text'])}>
                <span>
                  &nbsp;
                  <img alt="back" src={BackArrow} height="22px" width="22px" style={{ display: 'inline' }} />
                </span>
                <span className="pl-2">{GLOBAL_CONSTANTS.BACK_TO_SIGN_IN}</span>
              </Link>
            </div>
          </div>
        </form>
      </CommonPage>
    </>
  );
}

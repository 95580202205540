import React, { useState, useEffect } from 'react';
import { FilterItemDefects } from './filter-item-defects';
import './filters.css';
import { isEmptyValue } from '@src/util/common_utils';
import { Checkbox } from 'fireflink-ui';
export const FilterCategoryDefects = (props) => {
  const [expandCategory, setExpandCategory] = useState(false);

  useEffect(() => {
    if (props.id.includes('CreatedBy') || props.id.includes('AssignTo') || props.id.includes('Labels')) {
      setExpandCategory(true);
    } else {
      setExpandCategory(false);
    }
  }, [props]);

  const allowCheck = (categories, parentName) => {
    if (categories && parentName === 'Assign To') {
      if (props.checkedAssignToIds?.length > 0) {
        let isFound = false;
        let allfound = categories.every((category) => props.checkedAssignToIds.includes(category['label']));
        if (allfound) {
          return allfound;
        } else {
          isFound = categories.some((category) => props.checkedAssignToIds.includes(category['label']));
          if (isFound) {
            return false;
          }
        }
        if (!allfound && !isFound) {
          return false;
        }
      } else {
        return false;
      }
    } else if (categories && parentName === 'Created By') {
      if (props.checkedCreatedByIds?.length > 0) {
        let isFound = false;
        let allfound = categories.every((category) => props.checkedCreatedByIds.includes(category['label']));
        if (allfound) {
          return allfound;
        } else {
          isFound = categories.some((category) => props.checkedCreatedByIds.includes(category['label']));
          if (isFound) {
            return false;
          }
        }
        if (!allfound && !isFound) {
          return false;
        }
      }
    }
    else if (categories && parentName === 'Labels') {
      if (props.checkedLabelsIds?.length > 0) {
        let isFound = false;
        let allfound = categories.every((category) => props.checkedLabelsIds.includes(category['label']));
        if (allfound) {
          return allfound;
        } else {
          isFound = categories.some((category) => props.checkedLabelsIds.includes(category['label']));
          if (isFound) {
            return false;
          }
        }
        if (!allfound && !isFound) {
          return false;
        }
      }
    }
  };

  return (
    <div className="category">
      {expandCategory && !isEmptyValue(props?.categories?.length) && (
        <div>
          <>
            {props.parentName.includes('Created') ? (
              <div className="flex items-center m-2">
                <Checkbox
                  label={`Select All (${props?.categories?.length})`}
                  name={props.parentName}
                  id={props.id}
                  onChange={(e) => {
                    props.handleAll(e, props);
                  }}
                  value={props.label}
                  checked={allowCheck(props?.categories, props.parentName)}
                />
              </div>
            ) : props.parentName.includes('Assign') ? (
              <div className="flex items-center m-2">
                <Checkbox
                  label={`Select All (${props?.categories?.length})`}
                  name={props.parentName}
                  id={props.id}
                  onChange={(e) => {
                    props.handleAll(e, props);
                  }}
                  value={props.label}
                  checked={allowCheck(props?.categories, props.parentName)}
                />
              </div>
            ) : (
              <div className="flex items-center m-2">
                <Checkbox
                  label={`Select All (${props?.categories?.length})`}
                  name={props.parentName}
                  id={props.id}
                  onChange={(e) => {
                    props.handleAll(e, props);
                  }}
                  value={props.label}
                  checked={allowCheck(props?.categories, props.parentName)}
                />
              </div>
            )
          }

            {props?.categories?.map((filterItem, index) => (
              <FilterItemDefects
                id={filterItem.id}
                {...filterItem}
                key={index}
                handleFilterValues={props.handleFilterValues}
                checkedAssignToIds={props.checkedAssignToIds}
                checkedCreatedByIds={props.checkedCreatedByIds}
                checkedLabelsIds={props.checkedLabelsIds}
              />
            ))}
          </>
        </div>
      )}
    </div>
  );
};

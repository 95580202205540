import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Fragment } from 'react';
import { createRoleRequest, updateRoleRequest, createRoleByProjectRequest } from '@api/api_services';
import { getSelectedProject } from '../../../service/common_service';

import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import MyInput from '../Inputs/MyInput';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { useAlert } from '@pages/common/alert_service/useAlert';
import {
  getProjectPermissionSections,
  getUpdatedRoleName,
  getCurrentProjectType,
  getLicenseFeatures,
  getProjectsRoleCount,
  getAutomationProjectTypes,
  getPrivilege,
} from '@util/common_utils';

let permissionNameArray = [
  {
    role: 'NoAccess',
  },
  {
    role: 'View',
  },
  {
    role: 'Edit',
  },
  {
    role: 'FullAccess',
  },
];

let getTabpermissionObject = (permissionArr, tabpermission) => {
  if (tabpermission) {
    let tabPermissionObj = permissionArr?.reduce((acc, valObj) => {
      return { ...acc, [valObj?.value]: tabpermission[valObj?.value] };
    }, {});
    return tabPermissionObj;
  } else {
    let permissionObj = permissionArr?.reduce((acc, valObj) => {
      return { ...acc, [valObj?.value]: 'NoAccess' };
    }, {});
    return permissionObj;
  }
};

let optionsdata = ['NoAccess', 'View', 'Edit', 'FullAccess'];

const getNoAccess = (permissionArray) => {
  let tempRoleArray = [];
  permissionArray.map((val, indx) => {
    tempRoleArray[indx] = 'NoAccess';
    return null;
  });
  return tempRoleArray;
};

const RoleCreatePage = ({ ...props }) => {
  const licenseFeatures = getLicenseFeatures();
  const hasCBasic = window.privilege.hasCBasic(licenseFeatures);
  const hasDefects = window.privilege.hasDefects(licenseFeatures);
  const hasManualTestCase = window.privilege.hasManualTestCase(licenseFeatures);

  let purchasedProjectsType = getAutomationProjectTypes(getPrivilege());
  let Individualprojectype = getCurrentProjectType();
  let isProjectPurchaseInAutomation = purchasedProjectsType?.includes(Individualprojectype);
  let permissionFieldsData = [];
  const displayProjectPermissionSections = getProjectPermissionSections();

  displayProjectPermissionSections?.forEach((allowedRole) => {
    if (Individualprojectype) {
      if (
        (!isProjectPurchaseInAutomation && allowedRole?.name !== 'repository') ||
        (hasManualTestCase && allowedRole?.name !== 'repository') ||
        (hasDefects && allowedRole?.name !== 'repository')
      ) {
        permissionFieldsData.push({
          repoName: getUpdatedRoleName(allowedRole?.name),
          value: allowedRole?.name,
        });
      } else if (isProjectPurchaseInAutomation || hasCBasic) {
        permissionFieldsData.push({
          repoName: getUpdatedRoleName(allowedRole?.name),
          value: allowedRole?.name,
        });
      }
    } else {
      permissionFieldsData.push({
        repoName: getUpdatedRoleName(allowedRole?.name),
        value: allowedRole?.name,
      });
    }
  });

  let [roleArray, setRoleArray] = React.useState(getNoAccess(permissionFieldsData));

  const { AlertContatiner, MyAlert } = useAlert();
  const closePopupFunction = () => {
    if (props.header === 'Edit Role') {
      props.func('closePopup');
    } else {
      props.func('closePopup');
    }
  };
  const [open, setOpen] = React.useState(false);
  let handelDlt = () => {
    setOpen(false);
  };

  const cancelFunction = () => {
    setOpen(false);
    props.func('closePopup');
  };
  const [createUpdateCalled, setCreateUpdateCalled] = React.useState(true);
  const formik = useFormik({
    initialValues: {
      roleName: props.role.name,
      description: props.role.desc || '',
      tabPermissions: props.role.tabPermissions,
    },

    validationSchema: Yup.object({
      roleName: Yup.string()
        .min(3, 'Minimum 3 characters')
        .trim()
        .max(25, 'Maximum 25 characters')
        .required('Name is required'),
    }),
    onSubmit: (v) => {
      if (createUpdateCalled) {
        setCreateUpdateCalled(false);
        createRole();
      }
    },
  });
  const [descCount, setdescCount] = React.useState(props.role.desc ? props.role.desc.length : 0);
  let [noOfRows, setNoOfRows] = React.useState(1);

  let tabPermissions = React.useMemo(
    () => getTabpermissionObject(permissionFieldsData, props?.role?.tabPermissions),
    []
  );

  React.useEffect(() => {
    if (props) {
      if (props?.role?.tabPermissions) {
        let tempArray = roleArray;
        let tabPermission = props?.role?.tabPermissions;
        permissionFieldsData.forEach((val, indx) => (tempArray[indx] = tabPermission[val?.value]));
        setRoleArray(tempArray);
        formik.setFieldValue('tabPermissions', tempArray);
      }
    }
  }, []);
  const createRole = (btn) => {
    const selectedProject = getSelectedProject();
    const btn1 = props?.button;
    if (btn1 === 'Create') {
      if (props?.roleCount?.length > maximumProjectRoleCount - 1) {
        MyAlert.warning(` Exceeded the allocated limit, please upgrade license or buy new license. `);
      } else if (
        (props.roleCount.length < maximumProjectRoleCount - 1 && selectedProject.id === 'all') ||
        selectedProject.id === ''
      ) {
        const data = {
          name: formik.values.roleName,
          desc: formik.values.description,
          tabPermissions: tabPermissions,
        };
        createRoleRequest(data)
          .then((res) => {
            if (res.data && res.data.responseObject) {
              props.MyAlert.success(`${formik.values.roleName} Role created successfully`);
              const roleId = res.data.responseObject.id;

              sessionStorage.setItem('roleId', roleId);
              localStorage.setItem('selected-role', JSON.stringify(res.data.responseObject));
              props.func('getAllRoles');
              props.func('closePopup');
            } else {
              setOpen(true);
            }
          })
          .catch((error) => {
            console.log('error', error);
            setOpen(false);
          });
      } else if (props.roleCount.length < maximumProjectRoleCount && selectedProject.id) {
        const data = {
          name: formik.values.roleName,
          desc: formik.values.description,
          tabPermissions: tabPermissions,
          projectId: selectedProject.id,
        };
        createRoleByProjectRequest(data)
          .then((res) => {
            if (res.data && res.data.responseObject) {
              props.MyAlert.success(`${formik.values.roleName} Role created successfully`);
              props.func('getAllRoles');
              props.func('closePopup');
            } else {
              setOpen(true);
            }
          })
          .catch((error) => {
            console.log('error', error);
            setOpen(false);
          });
      }
    } else if (btn1 === 'Update') {
      if (selectedProject.id === 'all' || selectedProject.id === '') {
        let user = formik.values;
        let userData = {
          roleName: user.roleName,
          description: user.description,
        };
        let formikData = {
          roleName: formik.initialValues.roleName,
          description: formik.initialValues.description,
        };
        //sorting two objects because the objectKeys are not in the same order
        function sortObject(obj) {
          const sortedKeys = Object.keys(obj).sort();
          const sortedObject = {};
          sortedKeys.forEach((key) => {
            sortedObject[key] = obj[key];
          });
          return sortedObject;
        }
        function sortObjects(obj1, obj2) {
          const sortedObj1 = sortObject(obj1);
          const sortedObj2 = sortObject(obj2);

          return [sortedObj1, sortedObj2];
        }
        const [sortedInitialTabPermissions, sortedTabPermissions] = sortObjects(
          formik.initialValues.tabPermissions,
          tabPermissions
        );
        if (
          JSON.stringify(formikData) === JSON.stringify(userData) &&
          JSON.stringify(sortedInitialTabPermissions) === JSON.stringify(sortedTabPermissions)
        ) {
          MyAlert.info(`No changes were done to the ${user.roleName} role`);
          return null;
        }
        const data = {
          name: user.roleName,
          desc: user.description,
          tabPermissions: tabPermissions,
        };

        updateRoleRequest(data, props.role.id)
          .then((results) => {
            if (results.data.responseObject) {
              const user = formik.values;
              if (formik.values.description === '' && props.role.id === '') {
                MyAlert.info(`No changes were done to the ${user.roleName} role`);
                return null;
              }
              props.MyAlert.success(`${user.roleName} Role Updated successfully`);
              if (props.role.id === '') {
                const myUpRole = JSON.parse(localStorage.getItem('selected-role'));
                myUpRole.name = user.roleName;
                localStorage.setItem('selected-role', JSON.stringify(myUpRole));
              }
              props.func('getAllRoles');
              props.func('closePopup');
              if (results.data.message === "Can't be updated with other role name") {
                setOpen(true);
              } else {
                props.func('closePopup');
              }
            } else {
              if (results.data.message === "Can't be updated with other role name") {
                setOpen(true);
              } else {
                props.func('closePopup');
              }
            }
          })
          .catch((error) => {
            console.log('error', error);
            setOpen(false);
          });
      } else {
        const user = formik.values;
        if (JSON.stringify(formik.initialValues) === JSON.stringify({ ...user, tabPermissions })) {
          MyAlert.info(`No changes were done to the ${user.roleName} role`);
          return null;
        }
        const data = {
          name: user.roleName,
          desc: user.description,
          tabPermissions: tabPermissions,
          projectId: selectedProject.id,
        };

        updateRoleRequest(data, props.role.id)
          .then((results) => {
            if (results.data.responseObject) {
              const user = formik.values;
              if (formik.values.description === '' && props.role.id === '') {
                MyAlert.info(`No changes were done to the ${user.roleName} role`);
                return null;
              }
              props.MyAlert.success(`${user.roleName} Role Updated successfully`);
              props.func('getAllRoles');
              if (
                results.data.message === "Can't be updated with all project level role name" ||
                results.data.message === "Can't be updated with other role name"
              ) {
                setOpen(true);
              } else {
                props.func('closePopup');
              }
            } else {
              if (
                results.data.message === "Can't be updated with all project level role name" ||
                results.data.message === "Can't be updated with other role name"
              ) {
                setOpen(true);
              } else {
                props.func('closePopup');
              }
            }
          })
          .catch((error) => {
            console.log('error', error);
            setOpen(false);
          });
      }
    }
  };

  const setRadiobuttonVal = (dataVal) => {
    tabPermissions[dataVal.target.name] = dataVal.target.value;
  };

  function getDataForCheckBox(ind, radioText, radioIndex) {
    let tempArray = roleArray;
    tempArray[ind] = radioText;
    setRoleArray(tempArray);
    formik.setFieldValue('tabPermissions', tempArray);
  }
  const inputChange = (e) => {
    if (e.target.value.length > 0 && e.target.value.length < 3) {
      document.getElementById('nameLvlText').style.color = '#EA0322';
    }
  };
  const inputOnChange = (e) => {
    if (e.target.value.length >= 3) {
      document.getElementById('nameLvlText').style.color = '#727171';
    }
  };
  const bannerMsg = () => {
    if (
      (formik.values.description === '' && props.role.id === '' && props.button === 'Update') ||
      (JSON.stringify(formik.initialValues) === JSON.stringify({ ...formik.values, tabPermissions }) &&
        props.button === 'Update')
    ) {
      MyAlert.info(`No changes were done to the ${formik.values.roleName} role`);
      return null;
    }
  };
  const maximumProjectRoleCount = getProjectsRoleCount();
  return (
    <div
      style={{ marginLeft: '18px' }}
      onBlur={(e) => {
        setCreateUpdateCalled(true);
      }}
    >
      <div className="fixed ml-6 top-12">
        <AlertContatiner />
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed  inset-0 overflow-y-auto z-index-child-popup"
          open={open}
          onClose={() => setOpen(true)}
        >
          <div className="flex  items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity " />
            </Transition.Child>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block marginupper_error align-bottom bg-white rounded-sm px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6 duplicate-p">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none "
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10">
                    <img
                      alt=""
                      src="/assets/images/remove_circle.jpg"
                      className="flex flex-row mr-2 ml-2"
                      height="20px"
                      width="20px"
                    ></img>
                  </div>
                  <div className="mt-1 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-sm leading-6 font-small text-gray-900">
                      <div className="warning-popup-header">Error.</div>
                      <div className="warning-popup-content">Duplicate</div>
                    </Dialog.Title>
                    <hr className="line mt-2" />
                    <div className="mt-2 warning-popup-content">
                      <div>
                        <p className="mt-2">A role with the name {formik.values?.roleName} already exist.</p>
                        <p className="mt-2"> How do you want to proceed? </p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="line mt-6" />
                <div className="mt-4 sm:mt-2 sm:flex sm:flex-row-reverse">
                  <button type="button" className="primary-btn" onClick={handelDlt}>
                    <span>Continue Editing</span>
                  </button>
                  <button type="button" className="gray-btn mr-6" onClick={cancelFunction}>
                    <span> Cancel</span>
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <form onSubmit={formik.handleSubmit} className="space-y-8 ">
        <div id="journal-scroll" style={{ maxHeight: '400px' }}>
          <div>
            <div className="grid grid-cols-2  mt-2 pb-3 ">
              <div>
                <div>
                  {formik.errors.roleName && formik.touched.roleName && !formik.values.roleName ? (
                    <>
                      {' '}
                      <span className="fontPoppinsRegularMd" style={{ color: '#EA0322' }}>
                        *
                      </span>
                      <span id="nameLvlText" className="fontPoppinsRegularMd" style={{ color: '#Ea0322' }}>
                        {' '}
                        Name{' '}
                      </span>
                    </>
                  ) : (
                    <>
                      {' '}
                      <span className="fontPoppinsRegularMd" style={{ color: '#EA0322' }}>
                        *
                      </span>
                      <span id="nameLvlText" className="fontPoppinsRegularMd" style={{ color: '#727171' }}>
                        {' '}
                        Name{' '}
                      </span>
                    </>
                  )}
                </div>
                <MyInput
                  type="text"
                  error={formik.errors.roleName && formik.touched.roleName}
                  value={formik.values.roleName}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    setCreateUpdateCalled(true);
                    inputChange(e);
                  }}
                  onChange={(e) => {
                    formik.handleChange(e);
                    inputOnChange(e);
                  }}
                  name="roleName"
                  id="roleName"
                  autoFocus={true}
                  autoComplete="off"
                  className=" block w-9/12  "
                  placeholder="Enter role name"
                  helperText={
                    <div className="fontPoppinsRegularSm" style={{ position: 'absolute' }}>
                      {formik.touched.roleName && formik.errors.roleName}
                    </div>
                  }
                />
              </div>
            </div>
            <div className="grid grid-cols-1  mt-2 pb-3 ">
              <div>
                <label
                  htmlFor="description"
                  id="roledescription"
                  className="input-filed-label-style-common"
                  style={{ color: '#727171' }}
                >
                  Description
                </label>
                <TextareaAutosize
                  onBlur={(e) => {
                    setNoOfRows(1);
                    e.target.classList.add('descriptionStyle');
                    document.getElementById('roledescription').style.color = '#727171';
                    document.getElementById('description').style.height = 'initial';
                    setCreateUpdateCalled(true);
                  }}
                  onFocus={(e) => {
                    setNoOfRows(null);
                    e.target.classList.remove('descriptionStyle');
                    document.getElementById('roledescription').style.color = '#1648C6';
                  }}
                  id="description"
                  name="description"
                  value={formik.values.description}
                  placeholder="Your description goes here..."
                  style={{
                    paddingLeft: '1px',
                    maxHeight: '50px',
                    lineHeight: '17px',
                  }}
                  onChange={formik.handleChange}
                  onKeyUp={(e) => setdescCount(e.target.value.length)}
                  rowsMax={noOfRows}
                  maxLength="200"
                  className="rs-input-style-textarea  shadow-sm block description-width-project overflow-ellipsis  mt-1   border-0 border-b input-field-color"
                ></TextareaAutosize>
                <div className="mt-1 mr-5 text-sm text-gray-500 text-right">{descCount}/200</div>
              </div>
            </div>
          </div>
          <div>
            <label className="fontPoppinsMediumLg mb-8">
              <div class="mt-4 mb-4 text-gray-500" style={{ color: '#727171' }}>
                <span className="text-red-400 " style={{ color: '#EA0322' }}>
                  *
                </span>{' '}
                <span style={{ color: '#3C3838' }}>Project Permission</span>
              </div>
            </label>
            <div
              className="role_permission_style2 journal-scroll"
              style={{ height: '200px', width: '585px', position: 'sticky' }}
            >
              <div className="flex flex-row">
                <label className="uppercase fontPoppinsSemiboldMd section_label_width p-3 ">Section</label>
                {permissionNameArray.map((perVal, perindx) => {
                  return (
                    <label
                      className={`uppercase fontPoppinsSemiboldMd px-4 pt-3 ${perVal.role === 'View' ? 'mr-2' : ''} `}
                    >
                      {perVal.role}
                    </label>
                  );
                })}
              </div>
              <div className="journal-scroll" style={{ overflowY: 'auto', width: '585px', height: '150px' }}>
                {permissionFieldsData.map((val, indx) => {
                  return (
                    val.repoName !== 'Analytics' && (
                      <div className="grid grid-rows-1 grid-flow-col " style={{ gap: '1.1rem' }}>
                        <div className="slider_label_style p-1 role-proj-permission-content">
                          <label className="fontPoppinsRegularMd">{val.repoName}</label>
                        </div>
                        {optionsdata.map((radiotxt, optindex) => {
                          return (
                            <>
                              <RadioGroup
                                row
                                aria-label="position"
                                name="position"
                                defaultValue="top"
                                className="-ml-16 px-8"
                              >
                                <FormControlLabel
                                  control={
                                    <Radio
                                      color="primary"
                                      name={val.value}
                                      {...{
                                        checked: roleArray[indx] === radiotxt,
                                      }}
                                      id={indx + radiotxt + optindex}
                                      label={radiotxt}
                                      className=" m-2 cursor-pointer"
                                      value={radiotxt}
                                      onChange={(e) => {
                                        getDataForCheckBox(indx, radiotxt, optindex);

                                        setRadiobuttonVal(e);
                                        setCreateUpdateCalled(true);
                                      }}
                                    />
                                  }
                                />
                              </RadioGroup>
                              <div className={radiotxt !== 'FullAccess' ? 'line-style' : null}></div>
                            </>
                          );
                        })}
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="pt-8 mt-10 border-t border-#e2e5ec-200 " style={{ width: '104%', marginLeft: '-18px' }}>
          <div className="flex -mt-4 justify-end" style={{ marginRight: '30px' }}>
            <button
              type="button"
              className="gray-btn mr-3"
              style={{ marginRight: '12px' }}
              onClick={closePopupFunction}
              onMouseDown={(e) => e.preventDefault()}
            >
              <span>Cancel</span>
            </button>
            <button type="submit" className="primary-btn" style={{ marginLeft: '12px' }} onClick={bannerMsg}>
              <span>{props.button}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default RoleCreatePage;

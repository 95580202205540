import ClientBrowserVersion from '../charts/ClientBrowserVersion';
import ClientPhoneOs from '../charts/ClientPhoneOs';
import ClientsProvider from '../components/ClientsProvider';
import ClientSectionHeader from '../components/ClientSectionHeader';
import ClientsOSAndVersions from '../charts/ClientsOSAndVersions';
import ClientsAccess from '../charts/ClientsAccess';
import cx from 'classnames';
import DashboardStyle from '../styles/dashboard.module.scss';

function Clients() {
  return (
    <div className={DashboardStyle['client-os-version-container']}>
      <ClientsProvider>
        <ClientSectionHeader />
        <div className={cx(DashboardStyle['client-os-version-heigth'], 'grid grid-cols-2 gap-3 my-2 h-full')}>
          <ClientsOSAndVersions />
          <div className="grid grid-rows-2 gap-2 items-between">
            <div className="grid grid-cols-1">
              <ClientBrowserVersion />
            </div>
            <div className="grid grid-cols-2 gap-2">
              <ClientsAccess />
              <ClientPhoneOs />
            </div>
          </div>
        </div>
      </ClientsProvider>
    </div>
  );
}

export default Clients;

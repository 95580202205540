import React, { useMemo, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { AZURE_DEVOPS_CONSTANTS } from '../plugin_constants';
import { createAzureInstance, getProjectListDropdownReq, getAllSuiteReq, updateAzure } from '@api/api_services';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CommonDrawerJs from '../CommonIntegrationDrawer';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import style from '@pages/common/Inputs/inputs.module.scss';
import cx from 'classnames';

const AzureCreateEditInstance = (props) => {
  const [projects, setProjects] = React.useState([]);
  const [projectFilterArray, setProjectFilterArray] = React.useState([]);
  const [scriptFilterArray, setScriptFilterArray] = React.useState([]);
  const [projectNames, setProjectNames] = useState([]);
  const [projectId, setProjectId] = useState('');
  const [projectType, setProjectType] = useState(props.actionType === 'Edit' ? props.seletedInstance.projectType : '');
  const [suiteId, setSuiteId] = useState('');
  const [scriptName, setScriptName] = useState([]);
  const [fireflinkProjectName, setFireFlinkProjectName] = useState('');
  const [suiteName, setSuiteName] = useState('');
  const [instanceError, setInstanceError] = useState(false);
  const [createCalled, setCreateCalled] = useState(false);
  const [matchingData, setMatchingData] = useState(false);

  const initialValues = {
    suiteName: props.mode === 'EDIT' ? props?.data?.suiteName : '',
    instanceName: props.mode === 'EDIT' ? props?.data?.name : '',
    projectName: props.mode === 'EDIT' ? props?.data?.projectName : '',
  };
  const validationSchema = yup.object({
    username: yup.string().required('Username  is required'),
    userPasswordField: yup.string().required('Password  is required'),
    instanceName: yup
      .string()
      .matches(/^[A-Za-z0-9 _]*$/, 'Name can not have special characters')
      .min(3, 'Name should contain at least 3 characters')
      .max(25, 'Name can contain at most 25 characters')
      .matches(/^[^\s].*[^\s]$/, 'Space is not allowed in the beginning and at the end')
      .required('Instance Name is required'),
    projectName: yup.string().required('Project Name is required'),
    suiteName: yup.string().required('Suite Name is required'),
  });

  const handleSubmit = () => {
    if (props.mode === 'EDIT') {
      updateAzureAccount();
    } else {
      createAzureAccount();
    }
  };

  const createAzureAccount = () => {
    if (
      formikDetails.values.projectName !== '' &&
      formikDetails.values.suiteName !== '' &&
      formikDetails.values.instanceName !== ''
    ) {
      let data = {
        name: formikDetails.values.instanceName,
        suiteId: scriptName[0].id,
        projectId: projectNames[0].id,
        suiteName: formikDetails.values.suiteName,
        projectName: formikDetails.values.projectName,
      };
      createAzureInstance(data).then((response) => {
        if (response?.data?.responseCode === 400 && response.data.message.includes('name already exists')) {
          formikDetails.setFieldError('instanceName', response.data.message);
        }
        if (
          response?.data?.responseCode === 400 &&
          response.data.message === 'Either suiteName or accessKey is invalid'
        ) {
          document.getElementById('suiteName').disabled = false;
          document.getElementById('accessKey').disabled = false;
          document.getElementById('suiteName').style.opacity = 1;
          document.getElementById('accessKey').style.opacity = 1;
          props.MyAlert.warning(`${response.data.message}`);
        } else if (response?.data?.responseCode === 201) {
          props.MyAlert.success(response.data.message);
          props.reloadTree();
          props.onClose();
        }
        setCreateCalled(false);
      });
    }
  };
  const formikDetails = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const getSelectedProjectName = (projectName) => {
    let project = projectFilterArray.filter((e) => e.name === projectName);
    setProjectId(project[0].id);
    setProjectType(project[0].type);
    setProjectNames(project);
    project
      ? localStorage.setItem('selected-Git-project', JSON.stringify(project[0]))
      : localStorage.setItem('selected-Git-project', JSON.stringify({ id: '', name: 'All Projects' }));
    getAllSuiteReq().then((results) => {
      let _suiteType = results?.data?.responseObject.filter((suiteType) => suiteType?.suiteType === 'Automation');
      setSuiteId(_suiteType[0]?.id);
      setScriptFilterArray(_suiteType ? _suiteType : []);
    });
  };

  const handleSheetChange = (value) => {
    if (value) {
      getSelectedProjectName(value);
    }
    formikDetails.setFieldValue('projectName', value, true);
    formikDetails.setFieldValue('suiteName', '', true);
    setFireFlinkProjectName(value);
  };
  const handleSuiteChange = (value) => {
    if (value) {
      setScriptName(scriptFilterArray.filter((e) => e.name === value));
    }
    formikDetails.setFieldValue('suiteName', value, true);
    setSuiteName(value);
  };

  const currentInstanceData = [
    {
      instanceName: formikDetails?.values?.instanceName,
      suiteName: formikDetails?.values?.suiteName,
      projectName: formikDetails?.values?.projectName,
    },
  ];

  const exsitingInstanceData = [
    {
      instanceName: props?.data?.name,
      suiteName: props?.data?.suiteName,
      projectName: props?.data?.projectName,
    },
  ];

  useEffect(() => {
    if (props.mode === 'EDIT') {
      if (JSON.stringify(currentInstanceData) === JSON.stringify(exsitingInstanceData)) {
        setMatchingData(true);
      } else {
        setMatchingData(false);
      }
    }
  }, [currentInstanceData, exsitingInstanceData]);

  const updateAzureAccount = () => {
    if (
      formikDetails.values.projectName !== '' &&
      formikDetails.values.suiteName !== '' &&
      formikDetails.values.instanceName !== ''
    ) {
      let data = {
        name: formikDetails.values.instanceName,
        suiteId: scriptName[0]?.id ? scriptName[0]?.id : props.data.suiteId,
        projectId: projectNames[0]?.id ? projectNames[0]?.id : props.data.projectId,
        suiteName: formikDetails.values.suiteName,
        projectName: formikDetails.values.projectName,
      };

      updateAzure(data, props.data.id).then((response) => {
        setCreateCalled(false);
        if (response.data.responseCode === 400 && response.data.message.includes('name already exists')) {
          formikDetails.setFieldError('instance', response.data.message);
        }
        if (
          response.data.responseCode === 400 &&
          response.data.message === 'Either suiteName or projectName is invalid'
        ) {
          props.MyAlert.warning(`${response.data.message}`);
        }
        if (response?.data?.status === 'SUCCESS') {
          props.onClose();
          props.reloadTree();
          props.MyAlert.success(response.data.message);
        }
      });
    }
  };

  useEffect(() => {
    getProjectListDropdownReq()
      .then((results) => {
        setProjects(results.data.responseObject);
        setProjectFilterArray(results.data.responseObject);
      })
      .catch((error) => {});

    if (props.mode === 'EDIT') {
      localStorage.setItem(
        'selected-Git-project',
        JSON.stringify({ id: props.data.projectId, name: props.data.projectName })
      );
      getAllSuiteReq().then((results) => {
        let _suiteType = results?.data?.responseObject?.filter((suiteType) => suiteType?.suiteType === 'Automation');
        setScriptFilterArray(_suiteType ? _suiteType : []);
      });
    }
    return () => {
      localStorage.removeItem('selected-Git-project');
    };
  }, []);

  const isRightButtonDisabled = () => {
    const isRequiredFieldsEmpty =
      formikDetails.values.projectName === '' ||
      formikDetails.values.suiteName === '' ||
      formikDetails.values.instanceName === '';
    const isEditModeAndMatchingData = props.mode === 'EDIT' && matchingData;
    const isInstanceNameError = formikDetails.errors.instanceName;

    return isRequiredFieldsEmpty || isEditModeAndMatchingData || isInstanceNameError;
  };

  return (
    <CommonDrawerJs
      isDrawerOpen={true}
      headerContent={
        props.mode === 'EDIT'
          ? `${AZURE_DEVOPS_CONSTANTS.EDIT_INSTANCE} - ${props?.data?.name}`
          : AZURE_DEVOPS_CONSTANTS.CREATE_INSTANCE
      }
      drawerWidth="450px"
      rightButtonText={props.mode === 'EDIT' ? AZURE_DEVOPS_CONSTANTS.UPDATE : AZURE_DEVOPS_CONSTANTS.CREATE}
      leftButtonText="Cancel"
      isLeftButtonVisible={true}
      onDrawerOpen={props.onClose}
      onRightButtonClick={handleSubmit}
      onLeftButtonClick={props.onClose}
      disableRightButton={isRightButtonDisabled()}
    >
      <section className="w-full  flex flex-col justify-center items-center overflow-hidden pt-2 mt-2">
        <>
          <div className="w-full flex flex-col ml-8 ">
            <div className="integration-label fontPoppinsMediumSm mt-1 -mb-1">
              {
                <>
                  {' '}
                  <span className="text-red-600">*</span> {AZURE_DEVOPS_CONSTANTS.INSTANCE_NAME}
                </>
              }
            </div>
            <div className="mt-2 relative">
              <TextField
                className="instance-input-field ml-5 instance-width"
                variant="outlined"
                name="instanceName"
                autoComplete="off"
                placeholder={AZURE_DEVOPS_CONSTANTS.ENTER_INSTANCE_NAME}
                onChange={formikDetails.handleChange}
                error={formikDetails.touched.instanceName && formikDetails.errors.instanceName}
                onBlur={formikDetails.handleBlur}
                value={formikDetails.values.instanceName}
              />
              {formikDetails.errors.instanceName && formikDetails.touched.instanceName ? (
                <div className="error-message absolute fontPoppinsRegularXs9px ml-2">
                  {formikDetails.errors.instanceName}
                </div>
              ) : null}
              {instanceError ? (
                <div className="error-message absolute fontPoppinsRegularXs9px mr-56">
                  {AZURE_DEVOPS_CONSTANTS.INSTANCE_ERROR}
                </div>
              ) : null}
            </div>
          </div>

          <div className="w-full flex flex-col mt-2 ml-8">
            <div className="integration-label fontPoppinsMediumSm mt-1 -mb-1">
              {
                <>
                  {' '}
                  <span className="text-red-600">*</span> {AZURE_DEVOPS_CONSTANTS.PROJECT_NAME}
                </>
              }
            </div>
            <div className="mt-2 relative">
              <FormControl sx={{ minWidth: 120 }}>
                <Select
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  className="select-field-Style"
                  name="projectName"
                  onChange={(event) => {
                    handleSheetChange(event.target.value);
                    formikDetails.setFieldValue('projectName', event.target.value);
                  }}
                  IconComponent={KeyboardArrowDownIcon}
                  value={formikDetails.values.projectName}
                  onBlur={formikDetails.handleBlur}
                  error={formikDetails.touched.projectName && formikDetails.errors.projectName}
                  renderValue={(selected) => {
                    if (!selected)
                      return (
                        <span className="fontPoppinsRegularSm" style={{ opacity: '0.5' }}>
                          {AZURE_DEVOPS_CONSTANTS.SELECT_PROJECT_NAME}
                        </span>
                      );
                    else return <span className="fontPoppinsRegularSm">{selected}</span>;
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: '180px',
                        minHeight: '50px',
                        marginLeft: '3px',
                      },
                    },
                    classes: {
                      root: cx(style['select-box-menu-list-wrapper']),
                    },
                  }}
                >
                  {(projectFilterArray.every((option) => option.status === 'Closed') || !projectFilterArray.length) && (
                    <MenuItem>{AZURE_DEVOPS_CONSTANTS.NO_OPTIONS}</MenuItem>
                  )}
                  {projectFilterArray?.map((option, optionIndex) => {
                    if (option.status !== 'Closed') {
                      return (
                        <MenuItem value={option.name} key={option.name}>
                          <span>{option.name}</span>
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
              </FormControl>
              {formikDetails.errors.projectName && formikDetails.touched.projectName ? (
                <div className="error-message absolute fontPoppinsRegularXs9px ml-2">
                  {formikDetails.errors.projectName}
                </div>
              ) : null}
            </div>
          </div>
          <div className="w-full flex flex-col mt-2 ml-8">
            <div className="integration-label fontPoppinsMediumSm mt-1 -mb-1">
              {
                <>
                  {' '}
                  <span className="text-red-600">*</span> {AZURE_DEVOPS_CONSTANTS.SUITE_NAME}
                </>
              }
            </div>
            <div className="mt-2 relative">
              <FormControl sx={{ minWidth: 120 }}>
                <Select
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  className="select-field-Style"
                  name="suiteName"
                  onChange={(event) => {
                    handleSuiteChange(event.target.value);
                    formikDetails.setFieldValue('suiteName', event.target.value);
                  }}
                  renderValue={(selected) => {
                    if (!selected)
                      return (
                        <span className="fontPoppinsRegularSm" style={{ opacity: '0.5' }}>
                          {AZURE_DEVOPS_CONSTANTS.SELECT_SUITE_NAME}
                        </span>
                      );
                    else return <span className="fontPoppinsRegularSm">{selected}</span>;
                  }}
                  value={formikDetails.values.suiteName}
                  onBlur={formikDetails.handleBlur}
                  error={formikDetails.touched.suiteName && formikDetails.errors.suiteName}
                  IconComponent={KeyboardArrowDownIcon}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        minHeight: '50px',
                        maxHeight: '180px',
                        marginLeft: '3px',
                      },
                    },
                    classes: {
                      root: cx(style['select-box-menu-list-wrapper']),
                    },
                  }}
                >
                  {scriptFilterArray
                    ?.filter((option) => option?.suiteType === 'Automation')
                    .map((option) => {
                      return (
                        <MenuItem value={option?.name} key={option.name}>
                          <span title={option?.name?.length > 25 ? option.name : ''}>
                            {option?.name?.length > 25 ? `${option?.name?.substring(0, 25)}...` : option?.name}
                          </span>
                        </MenuItem>
                      );
                    })}
                  {scriptFilterArray?.filter((option) => option?.suiteType === 'Automation').length === 0 ? (
                    <MenuItem>{AZURE_DEVOPS_CONSTANTS.NO_OPTIONS}</MenuItem>
                  ) : null}
                </Select>
              </FormControl>
              {formikDetails.errors.suiteName && formikDetails.touched.suiteName ? (
                <div className="error-message absolute fontPoppinsRegularXs9px ml-2">
                  {formikDetails.errors.suiteName}
                </div>
              ) : null}
            </div>
          </div>
        </>
      </section>
    </CommonDrawerJs>
  );
};

export default AzureCreateEditInstance;

import React, { useState } from 'react';
import { Switch, Route, Link, withRouter } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useAlert } from '@pages/common/alert_service/useAlert';
import BrowserStackTable from './browser_sauce/browser_sauce_table';
import JiraIntegrationTable from './jira_integration';
import PluginTable from './plugin_table';
import LambdaTestTable from './lambda-test/lambda-test-table';
import InstanceDetails from './instance-details/instance_Details';
import SlackIntegrationTable from './slack_integration/slack_integration_table';
import GithubTable from './github-integration/github-table';
import GitlabTable from './gitlab/gitlab-table';
import BitBucketIntegrationTable from './bitBucket-integration/bitBucket_integration_table';
import AzureTable from './azure_devops_integrations/azure_table';

function PluginRouter(props) {
  const { AlertContatiner, MyAlert } = useAlert();
  const [instanceData, setInstanceData] = useState();

  const handelInstanceData = (values) => {
    setInstanceData(values);
  };
  const history = useHistory();
  let activeTab = props.location.pathname.split('/')[3];
  if (props.location.pathname === '/configuration/pluginconfiguration') {
    history.push('/configuration/pluginconfiguration/plugin');
  }
  if (props.location.pathname === '/configuration') {
    history.push('/configuration/integration');
  }
  let tab = props.location.pathname.split('/')[4];
  const [tabsList] = useState([
    {
      name: 'Plugin',
      pathUrl: `/configuration/integration/plugin`,
      selectedName: 'plugin',
    },
    // {
    //   name: "History",
    //   pathUrl: `/configuration/integration/history`,
    //   selectedName: "history",
    // },
  ]);
  const renderTabs = () => {
    return (
      <div>
        <ul className="navBar text-blue-600 flex flex-row primary-tab">
          {tabsList.map((tab, index) => {
            return (
              <li key={tab.name}>
                <Link
                  to={tab.pathUrl}
                  className={`${activeTab === tab.selectedName ? ' ml-2 selected_tab_style' : ''}`}
                >
                  {tab.name}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <div className="page-container">
      <div className="alert-position-style">
        <AlertContatiner></AlertContatiner>
      </div>
      <div className="">
        {tab ? '' : renderTabs()}
        <div className="">
          <Switch>
            <Route
              path="/configuration/integration"
              exact={true}
              strict={true}
              key={`plugin-table`}
              component={PluginTable}
            ></Route>
            <Route
              path="/configuration/integration/plugin"
              exact={true}
              strict={true}
              key={`plugin-table`}
              component={PluginTable}
            ></Route>
            <Route
              path="/configuration/integration/plugin/BrowserStack"
              exact={true}
              strict={true}
              key={`browserStack-table`}
            >
              <BrowserStackTable MyAlert={MyAlert} mode="BrowserStack" handelInstanceData={handelInstanceData} />
            </Route>
            <Route path="/configuration/integration/plugin/sauceLab" exact={true} strict={true} key={`sauce-table`}>
              <BrowserStackTable MyAlert={MyAlert} mode="sauceLab" handelInstanceData={handelInstanceData} />
            </Route>
            <Route path="/configuration/integration/plugin/LambdaTest" exact={true} strict={true} key={`lambdaTest`}>
              <LambdaTestTable MyAlert={MyAlert} handelInstanceData={handelInstanceData} />
            </Route>

            <Route
              path={`/configuration/integration/plugin/:pluginName/instanceDetails`}
              exact={true}
              strict={true}
              key={`instance-Details`}
            >
              <InstanceDetails MyAlert={MyAlert} {...props} data={instanceData} />
            </Route>
            <Route path="/configuration/integration/plugin/Jira" exact={true} strict={true} key={`jira-table`}>
              <JiraIntegrationTable MyAlert={MyAlert} />
            </Route>
            <Route path="/configuration/integration/plugin/Slack" exact={true} strict={true} key={`slack-table`}>
              <SlackIntegrationTable MyAlert={MyAlert} />
            </Route>
            <Route
              path="/configuration/integration/plugin/Bitbucket"
              exact={true}
              strict={true}
              key={`BitBucket-table`}
            >
              <BitBucketIntegrationTable MyAlert={MyAlert} />
            </Route>
            <Route path="/configuration/integration/plugin/GitHub" exact={true} strict={true} key={`github-table`}>
              <GithubTable MyAlert={MyAlert} git={'github'} />
            </Route>
            <Route path="/configuration/integration/plugin/Azure" exact={true} strict={true} key={`azure-table`}>
              <AzureTable MyAlert={MyAlert} data={'azure'} />
            </Route>
            <Route path="/configuration/integration/plugin/Gitlab" exact={true} strict={true} key={`gitlab-table`}>
              <GitlabTable MyAlert={MyAlert} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default withRouter(PluginRouter);

import React, { Fragment } from 'react';
import { useTable, usePagination, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy } from 'react-table';
import matchSorter from 'match-sorter';
import { AiOutlineDelete, AiOutlinePlayCircle } from 'react-icons/ai';
import { MdEdit } from 'react-icons/md';
import { FaStopCircle } from 'react-icons/fa';
import { FormControl, InputLabel, MenuItem, Select, Tooltip } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import Pagination from '../../../common/pagination';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import Stack from '@mui/material/Stack';

import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import UserDetailsPage from '../../common/user-details-page';
import {
  deleteSheduledDataReq,
  getAllSuiteOfSuiteScheduleReq,
  getSingleClientSystemReq,
  updateScheduleExecutionReq,
} from '../../../../api/api_services';
import moment from 'moment';
import MachineDetails from '../../../test-development/execution-dashboard/modals/machine-details';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const ScheduledInstance = ({ MyAlert, ...props }) => {
  var today = new Date();
  const classes = useStyles();
  const [dashboardData, setDashboardData] = React.useState([]);

  const [expiryDate, setExpiryDate] = React.useState(new Date('2014-08-18T21:11:54'));
  const [sheduledDate, setSheduledDate] = React.useState(new Date('2014-08-18T21:11:54'));
  const [expiryTime, setExpiryTime] = React.useState(new Date('2014-08-18T21:11:54'));
  const [scheduleTime, setScheduleTime] = React.useState(new Date('2014-08-18T21:11:54'));
  const [selectedSystem, setSelectedSystem] = React.useState();
  const [suiteArray, setSuiteArray] = React.useState([]);
  const [repeatData, setRepeat] = React.useState();
  const [validationMinTime, setValidationMinTime] = React.useState();
  const [minHour, setMinHour] = React.useState();
  const [minMinute, setMinMinute] = React.useState();
  const [scheduleBtnDisable, setScheduleBtnDisable] = React.useState(true);
  const [expireBtnDisable, setExpireBtnDisable] = React.useState(true);
  const [userData, setModifiedId] = React.useState();

  const changeExpiryTime = (newValue) => {
    setExpiryTime(newValue);
    checkExpiryValidation();
    checkScheduleValidation();
  };

  const changeScheduleTime = (newValue) => {
    setScheduleTime(newValue);
    checkExpiryValidation();
    checkScheduleValidation();
  };

  // const changeExpiryDate = (date) => {
  //     setExpiryDate(date);
  // };
  // const changeScheduleDate = (date) => {
  //     setSheduledDate(date);
  // };

  const formik = useFormik({
    initialValues: {
      Name: '',
    },

    validationSchema: Yup.object({
      Name: Yup.string().min(3, 'Minimum 3 characters').max(25, 'Maximum 25 characters').required('Required!'),
    }),
    onSubmit: (setVal) => {
      console.log(`values`, setVal);
    },
  });

  function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);

    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);

    return (
      <span>
        Search:{' '}
        <input
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`Type here...`}
          style={{
            fontSize: '1.1rem',
            border: '0',
          }}
        />
      </span>
    );
  }

  // Define a default UI for filtering
  function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search...`}
      />
    );
  }

  // This is a custom filter UI for selecting
  // a unique option from a list
  function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  // This is a custom filter UI that uses a
  // slider to set the filter value between a column's
  // min and max values
  function SliderColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    // Calculate the min and max
    // using the preFilteredRows

    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      preFilteredRows.forEach((row) => {
        min = Math.min(row.values[id], min);
        max = Math.max(row.values[id], max);
      });
      return [min, max];
    }, [id, preFilteredRows]);

    return (
      <>
        <input
          type="range"
          min={min}
          max={max}
          value={filterValue || min}
          onChange={(e) => {
            setFilter(parseInt(e.target.value, 10));
          }}
        />
        <button onClick={() => setFilter(undefined)}>Off</button>
      </>
    );
  }

  // This is a custom UI for our 'between' or number range
  // filter. It uses two number boxes and filters rows to
  // ones that have values between the two
  function NumberRangeColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id } }) {
    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      preFilteredRows.forEach((row) => {
        min = Math.min(row.values[id], min);
        max = Math.max(row.values[id], max);
      });
      return [min, max];
    }, [id, preFilteredRows]);

    return (
      <div
        style={{
          display: 'flex',
        }}
      >
        <input
          value={filterValue[0] || ''}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
          }}
          placeholder={`Min (${min})`}
          style={{
            width: '70px',
            marginRight: '0.5rem',
          }}
        />
        to
        <input
          value={filterValue[1] || ''}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
          }}
          placeholder={`Max (${max})`}
          style={{
            width: '70px',
            marginLeft: '0.5rem',
          }}
        />
      </div>
    );
  }

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  function getMachineDetails(macObj, exeObj) {
    // openModalfun('systemDetails')
    console.log('macObj', macObj);
    console.log('exeobj', exeObj);
    let reqData = {
      machineName: macObj?.sysData?.machineInfo.hostName,
      os: macObj?.sysData?.machineInfo?.osName,
      browser: macObj?.sysData?.browserName,
      device: macObj?.sysData?.deviceInfo[0]?.name ? macObj?.sysData?.deviceInfo[0]?.name : '--',
      status: macObj?.status,
      owner: macObj?.owner,
    };
    if (
      exeObj?.executionStatus !== 'Cancelled' &&
      exeObj?.executionStatus !== 'Running' &&
      exeObj?.executionStatus !== 'Pending' &&
      exeObj?.executionStatus !== 'Terminated'
    ) {
      if (exeObj?.resultStatus === 'PASS') {
        reqData.status = 'Passed';
      } else if (exeObj?.resultStatus === 'FAIL') {
        reqData.status = 'Failed';
      }
    } else {
      reqData.status = exeObj?.executionStatus;
    }
    console.log('reqData', reqData);
    getSingleClientSystemReq(macObj?.sysData?.clientId).then((res) => {
      if (res.data.responseObject) {
        reqData['executions'] = res.data.responseObject;
      } else {
        reqData['executions'] = [];
      }
      setSelectedSystem(reqData);
      openModalfun('systemDetails');
    });
  }

  React.useEffect(() => {
    getAllDashboard();
  }, []);

  function getAllDashboard() {
    let search = window.location.search;
    let urlSuiteId = new URLSearchParams(search).get('suiteId');
    const suiteId = urlSuiteId ? urlSuiteId : null;
    getAllSuiteOfSuiteScheduleReq(suiteId)
      .then((results) => {
        const response = results?.data?.responseObject;
        console.log('response', response);
        if (response) {
          // setIsLoaded(true);
          response.map((res, i) => {
            // stateIndex = i;

            if (res?.executionDashboard.executionStatus === 'Completed') {
              res.executionDashboard.suiteOfSuites.selectedMachines['status'] = res?.executionDashboard?.resultStatus;
              res.executionDashboard.suiteOfSuites.selectedMachines['createdByUname'] = res?.createdByUname;
            } else {
              res.executionDashboard.suiteOfSuites.selectedMachines['status'] =
                res?.executionDashboard?.executionStatus;
              res.executionDashboard.suiteOfSuites.selectedMachines['createdByUname'] = res?.createdByUname;
            }

            res.machineHostName = res?.executionDashboard.suiteOfSuites.selectedMachines;
            res.Actions = [];

            res.Actions.push(
              <span className="flex flex-row project-action delete-icon-style">
                <Tooltip title="Reschedule">
                  <EventOutlinedIcon
                    className="icon-blue-style delete-icon-style"
                    onClick={() => {
                      openModalfun('schedule', res);
                    }}
                  ></EventOutlinedIcon>
                </Tooltip>
                <Tooltip title="Delete">
                  <DeleteOutlinedIcon
                    className="icon-blue-style float-right ml-2 delete-icon-style"
                    onClick={() => {
                      setOpen(true);
                      setSelectedDataObj(res);
                    }}
                  />
                </Tooltip>
              </span>
            );
            // if (res && res?.executionDashboard?.suite) {
            //     let selectedMachine = res?.executionDashboard?.suite?.machines?.selectedMachines
            //     if (selectedMachine && selectedMachine.length) {
            //         let machineArray = []
            //         res.machine = []
            //         selectedMachine.map((selObj, index) => {
            //             if (selObj) {
            //                 machineArray.push(
            //                     <span className="flex flex-row" onClick={() => getMachineDetails(selObj, res)}> {index === selectedMachine.length - 1 ? selObj.machineInfo.hostName : selObj.machineInfo.hostName + ','} </span>
            //                 )
            //             }

            //         })
            //         res.machine = machineArray
            //     }
            // }

            res.scheduleTime = {};
            res.expireTime = {};
            if (res) {
              res.scheduleTime = res?.schedule?.updatedScheduleDate + ',' + res?.schedule?.updatedScheduleTime;
              res.expireTime = res?.schedule?.updatedExpireDate + ',' + res?.schedule?.updatedExpireTime;
            }
          });
          console.log('response', response);
          setDashboardData((d) => response);
        }
      })
      .catch((error) => {
        console.log(error);
        // setIsLoaded(true);
        // setError(error);
      });

    return [];
  }

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val) => !val;

  // Our table component
  function Table({ columns, data }) {
    const filterTypes = React.useMemo(
      () => ({
        // Add a new fuzzyTextFilterFn filter type.
        fuzzyText: fuzzyTextFilterFn,
        // Or, override the default text filter to use
        // "startWith"
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      []
    );

    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      nextPage,
      previousPage,
      state,
      visibleColumns,
      preGlobalFilteredRows,
      setGlobalFilter,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
        initialState: { pageIndex: 0, pageSize: 7 },
      },
      useFilters, // useFilters!
      useGlobalFilter, // useGlobalFilter!
      useSortBy,
      usePagination
    );
    return (
      <>
        <table class="relative w-full" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr class="mt-4" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    class="sticky top-0 px-6 py-3 text-current text-xs bg-gray-100 ml-5"
                    {...column.getHeaderProps()}
                    width={column.width}
                  >
                    <span className="flex flex-row">
                      {column.render('Header')}
                      {/* Render the columns filter UI */}
                      <div>
                        <span>
                          {/* {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <AiOutlineArrowDown className="mt-1 ml-1" />
                                                            : <AiOutlineArrowUp className="mt-1 ml-1" />
                                                        : ''} */}
                        </span>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr className="project-row" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td class="px-3 py-2 text-center" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {console.log(`operation`, operation)}
        {operation === 'details' ? (
          <div className="pagecountborder" id="schedule-footer-sos-details-popup">
            <div className="float-right pr-4">
              <span className="pagecount">
                {page[0]?.index + 1} - {page[page.length - 1]?.index + 1} of {suiteArray.length}
              </span>
              <span className="pageborderleft ml-2 pl-2">
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className={canPreviousPage ? 'pagination-clickable-style ' : 'pageArrow'}
                >
                  {'<'}
                </button>{' '}
                <span className="currentpagecount ml-2 mr-2">
                  {' '}
                  <span className="pl-2 pr-2">{pageIndex + 1}</span>
                </span>
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className={canNextPage ? 'pagination-clickable-style ' : 'pageArrow'}
                >
                  {'>'}
                </button>{' '}
              </span>
            </div>
          </div>
        ) : (
          <div className="pagecountborder" id="schedule-footer-suiteofsuite">
            <div className="float-right pr-4">
              <span className="pagecount">
                {page[0]?.index + 1} - {page[page.length - 1]?.index + 1} of {dashboardData.length}
              </span>
              <span className="pageborderleft ml-2 pl-2">
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className={canPreviousPage ? 'pagination-clickable-style ' : 'pageArrow'}
                >
                  {'<'}
                </button>{' '}
                <span className="currentpagecount ml-2 mr-2">
                  {' '}
                  <span className="pl-2 pr-2">{pageIndex + 1}</span>
                </span>
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className={canNextPage ? 'pagination-clickable-style ' : 'pageArrow'}
                >
                  {'>'}
                </button>{' '}
              </span>
            </div>
          </div>
        )}
        <br />
      </>
    );
  }

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue >= filterValue;
    });
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        columns: [],
      },
      // {
      //     Header: 'Sl.No',
      //     accessor: 'slNo',
      //     disableFilters: true,
      //     Cell: ({ value }) => {
      //         return (
      //             <div className="table-non-link-color-common float-left ml-3">{value}</div>
      //         )
      //     }
      // },
      {
        Header: 'Execution ID',
        accessor: '_id',
        disableFilters: true,
        Cell: (e) => (
          <a
            href=""
            onClick={(event) => {
              event.preventDefault();
              getData(e.cell.row.original, 'details');
            }}
            className="hyper_link_color-common float-left ml-2 table-font-style-common"
          >
            {' '}
            {e.value}{' '}
          </a>
        ),
      },
      {
        Header: 'Name',
        accessor: 'name',
        disableFilters: true,
        Cell: (e) => (
          <a
            href=""
            onClick={(event) => {
              event.preventDefault();
            }}
            className="table-non-link-color-common float-left ml-2 table-font-style-common"
          >
            {' '}
            {e.value}{' '}
          </a>
        ),
      },
      {
        Header: 'Machine',
        accessor: 'machineHostName',
        disableFilters: true,
        width: '11%',
        Cell: ({ value }) => {
          console.log('value', value);
          let title = ' ';
          value.forEach((system, index) => {
            title +=
              (system?.machineInfo?.hostName ? system?.machineInfo?.hostName : '') +
              `${index < value?.length - 1 ? ',' : ' '}`;
          });

          return (
            <Tooltip title={title} placement="top">
              <span className=" hyper_link_color-common float-left  dashboard-font-style-common text-overflow-style">
                {value.map((system, index) => {
                  console.log('system', system);
                  if (index < 2) {
                    return (
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          getMachineDetails(
                            {
                              sysData: system,
                              status: value?.status ? value?.status : 'N/A',
                              owner: value?.createdByUname,
                            },
                            value?.status,
                            value?.createdByUname
                          );
                        }}
                        className="cursor-pointer"
                      >
                        {system.machineInfo.hostName}
                        {value[index + 1] ? ',' : null}
                      </span>
                    );
                  } else {
                    return null;
                  }
                })}
                {value?.length > 2 ? '...' : null}
              </span>
            </Tooltip>
          );
        },
      },
      // {
      //     Header: 'Execution Type',
      //     accessor: 'exeType',
      //     disableFilters: true,
      //     Cell: ({ value }) => {
      //         return (
      //             <div className="table-non-link-color-common float-left ml-3 table-font-style-common">{value}</div>

      //         )
      //     }
      // },
      {
        Header: 'Scheduled By',
        accessor: 'createdByUname',
        disableFilters: true,
        Cell: (e) => (
          <a
            href=""
            onClick={(event) => {
              event.preventDefault();
              openModalfun('createdBy', e.cell.row.original);
            }}
            className="hyper_link_color-common float-left cursor-hand dashboard-font-style-common"
          >
            {' '}
            {e.value}{' '}
          </a>
        ),
      },
      {
        Header: 'Sheduled On',
        accessor: 'scheduleTime',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left table-font-style-common">{value}</div>;
        },
      },
      {
        Header: 'Expires On',
        accessor: 'expireTime',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left table-font-style-common">{value}</div>;
        },
      },

      // {
      //     Header: 'Name',
      //     accessor: 'name',
      //     disableFilters: true,
      //     Cell: e => <a href="" onClick={(e) => { e.preventDefault(); setShowModal(true); setpopupHeaderAndBtn("Role Details"); setdetailsHeader(e.target.text) }} className="hyper_link_color float-left ml-2"> {e.value} </a>

      // },

      {
        Header: 'Recurrence',
        accessor: 'schedule.repeat',
        disableFilters: true,

        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left table-font-style-common">{value}</div>;
        },
      },

      {
        Header: 'Actions',
        accessor: 'Actions',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common table-font-style-common">{value}</div>;
        },
      },
    ],
    []
  );

  const detailsColumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        columns: [],
      },

      {
        Header: 'Name',
        accessor: 'suite.name',
        disableFilters: true,
        Cell: (e) => (
          <a
            href=""
            onClick={(event) => {
              event.preventDefault();
            }}
            className="table-non-link-color-common float-left ml-2 table-font-style-common"
          >
            {' '}
            {e.value}{' '}
          </a>
        ),
      },
      {
        Header: 'No. of Scripts',
        accessor: 'suite.noOfScripts',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left table-font-style-common">{value}</div>;
        },
      },
      {
        Header: 'Access',
        accessor: 'suite.access',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left table-font-style-common">{value}</div>;
        },
      },
      {
        Header: 'Run In',
        accessor: 'suite.machines.multiple',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common float-left table-font-style-common">
              {value === 'false' ? 'Single Machine' : 'Multiple Machine'}
            </div>
          );
        },
      },
      {
        Header: 'Execution Type',
        accessor: 'suite.machines.executionType',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common float-left table-font-style-common">
              {value === 'SEND_SUITE_TO_ALL' ? 'Parallel' : value === 'DISTRIBUTE' ? 'Distribute' : 'Sequential'}
            </div>
          );
        },
      },

      // {
      //     Header: 'Execution Type',
      //     accessor: 'exeType',
      //     disableFilters: true,
      //     Cell: ({ value }) => {
      //         return (
      //             <div className="table-non-link-color-common float-left ml-3 table-font-style-common">{value}</div>

      //         )
      //     }
      // },
    ],
    []
  );

  const [age, setAge] = React.useState('');
  const [operation, setOperation] = React.useState();
  let scheduleRepeatArray = ['Once', 'Daily', 'Weekly', 'Montly'];
  const [showModal, setShowModal] = React.useState(false);
  const [selectedDataObj, setSelectedDataObj] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage] = React.useState(4);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  console.log('selectedDataObj', selectedDataObj);

  function getData(data, type) {
    console.log(`data`, data);
    setShowModal(true);
    setSelectedDataObj(data);
    setOperation(type);
    setSuiteArray(data?.executionDashboard?.suiteExecutions);
  }

  let handelDlt = (seletedObj) => {
    console.log(seletedObj);
    deleteSheduledDataReq(seletedObj._id).then((res) => {
      if (res) {
        setOpen(false);
        MyAlert.success(`${seletedObj?._id} deleted successfully`);
        getAllDashboard();
      }
    });
    // setOpen(false)
  };

  const handleChange = (event) => {
    setRepeat(event.target.value);
    checkExpiryValidation();
    checkScheduleValidation();
  };

  function getTimeForValidation() {
    let d = new Date();
    let time = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
    setValidationMinTime(time);
    setMinHour(d.getHours());
    setMinMinute(d.getMinutes());
    setExpireBtnDisable(true);
    setScheduleBtnDisable(true);
  }

  function openModalfun(btn, res) {
    console.log('res', res);
    if (btn === 'createdBy') {
      setShowModal(true);
      setOperation('createdBy');
      setModifiedId(res);
    } else if (btn === 'schedule') {
      setExpiryDate(res?.schedule.expireDate);
      setScheduleTime(res?.schedule.scheduleTime);
      setSheduledDate(res?.schedule.scheduleDate);
      setExpiryTime(res?.schedule.expireTime);
      setRepeat(res?.schedule.repeat);
      setShowModal(true);
      setSelectedDataObj(res);
      setOperation(btn);
      getTimeForValidation();

      // setTimeout(() => {
      let htmlEleForError = document.getElementById('date-picker-inline-helper-text');
      console.log('htmlEleForError', htmlEleForError);
      if (htmlEleForError) {
        htmlEleForError.innerHTML = 'Expiry Date should not be less than current Date';
      }
      // }, 1000);
    } else if (btn === 'systemDetails') {
      setOperation('systemDetails');
    }
  }

  function reScheduleExecutionFun(selctedSchedObj) {
    selctedSchedObj.schedule.repeat = repeatData;
    selctedSchedObj.schedule.scheduleDate = new Date(sheduledDate).toISOString();
    selctedSchedObj.schedule.scheduleTime = new Date(scheduleTime).toISOString();
    selctedSchedObj.schedule.expireDate = new Date(expiryDate).toISOString();
    selctedSchedObj.schedule.expireTime = new Date(expiryTime).toISOString();

    updateScheduleExecutionReq(selctedSchedObj.id, selctedSchedObj)
      .then((res) => {
        if (res.data && res.data.responseObject) {
          setShowModal(false);
          getAllDashboard();
          MyAlert.success(`${selctedSchedObj?.schedule?.suiteName} rescheduled successfully`);
        } else {
          // childalert.danger(res.data.message);
          // MyAlert.danger(res.data.message)
          if (res?.data?.message && res?.data?.message.toLowerCase().includes('please connect a device')) {
            // createAlertFun('info', res?.data?.message);
            MyAlert.info(res?.data?.message);
          } else if (res?.data?.message && res?.data?.message.toLowerCase().includes('please install a browser')) {
            MyAlert.info(res?.data?.message);
          } else if (
            res?.data?.message &&
            res?.data?.message.startsWith('Client') &&
            res?.data?.message.toLowerCase().includes('is not available')
          ) {
            MyAlert.info(res?.data?.message);
          } else {
            MyAlert.danger(res?.data?.message);
            // createAlertFun('danger', res.data.message)
          }
        }
      })
      .catch((error) => {
        console.log('error');
      });
  }

  const changeExpiryDate = (date) => {
    setExpiryDate(date);
    checkExpiryValidation();
    checkScheduleValidation();
  };

  const changeScheduleDate = (date) => {
    setSheduledDate(date);
    checkExpiryValidation();
    checkScheduleValidation();
    let htmlEleForError = document.getElementById('date-picker-inline-expiredate-helper-text');
    console.log('htmlEleForError', htmlEleForError);
    if (htmlEleForError) {
      htmlEleForError.innerHTML = 'Expiry Date should not be less than Schedule Date';
    }
  };

  function checkExpiryValidation() {
    setTimeout(() => {
      let expiryAreaInvalid = document?.getElementById('expiryId')?.getAttribute('aria-invalid');
      console.log('expiryAreaInvalid', expiryAreaInvalid);
      if (expiryAreaInvalid === 'true') {
        setExpireBtnDisable(true);
      } else {
        setExpireBtnDisable(false);
      }
    }, 100);
  }

  function checkScheduleValidation() {
    setTimeout(() => {
      let scheduleAreaInvalid = document?.getElementById('scheduleId')?.getAttribute('aria-invalid');
      console.log('scheduleAreaInvalid', scheduleAreaInvalid);
      if (scheduleAreaInvalid === 'true') {
        setScheduleBtnDisable(true);
      } else {
        setScheduleBtnDisable(false);
      }
    }, 100);
  }

  function closeMachineDetailsModal() {
    setShowModal(false);
    setOperation();
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block marginupper pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/4">
                <div className="inline-block">
                  <div className="flex flex-start ml-2">
                    <span className="pt-5 pl-3">
                      <ExclamationIcon className="h-6 w-6 text-yellow-600 " />
                    </span>
                    <Dialog.Title as="h3" className="text-sm leading-6 ml-4 pt-3 font-small text-gray-900">
                      <span className="fontPoppinsSemiboldLg block">Warning </span>
                      <span className="fontPoppinsMediumMd">Delete </span>
                    </Dialog.Title>
                  </div>
                </div>

                <hr className="line mt-px" />
                <div className="fontPoppinsMediumMd mt-3 text-gray-500 ml-16 mr-8">
                  <p className=""> Your data will be lost. Are you sure you want to delete {selectedDataObj.id}? </p>
                  <p className="mt-2"> How do you want to proceed?</p>
                </div>
                <hr className="line mt-3" />
                <div className="mt-2 pb-2 flex flex-row float-right">
                  <button type="button" className="mt-3 gray-btn ml-1" onClick={() => handelDlt(selectedDataObj)}>
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 ml-4 primary-btn "
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {operation === 'systemDetails' ? (
        <MachineDetails data={selectedSystem} closeModal={closeMachineDetailsModal} />
      ) : null}

      <Modal open={showModal} className="modal-dialog">
        {operation === 'createdBy' ? (
          <div className="modal-container sos-details-modal-style">
            <div className="modal-header ">
              <label className="Popup-header-common Popup-header-layout-style-user">
                {' '}
                <label> User Details -{userData.createdByUname}</label>
              </label>
              <IconButton
                color="primary"
                component="span"
                className="close"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div className="modal-body" id="journal-scroll">
              <UserDetailsPage modifiedBy={userData?.createdBy} />
            </div>
          </div>
        ) : operation === 'details' ? (
          <div className="modal-container sos-details-modal-style">
            <div className="modal-header ">
              <label className="Popup-header-common Popup-header-layout-style-user">{selectedDataObj.id}</label>
              <IconButton
                color="primary"
                component="span"
                className="close"
                onClick={() => {
                  setShowModal(false);
                  setOperation('');
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div className="modal-body" id="journal-scroll">
              <div className="flex flex-row">
                <div className="w-2/5">
                  <label htmlFor="suiteName" className="input-filed-label-style-common">
                    <span class="text-red-400">*</span> Name
                  </label>
                  <br></br>
                  <label className="create-set-header-label ml-3">{selectedDataObj.name}</label>
                </div>
                <div className="w-3/5">
                  <label htmlFor="Description" className="input-filed-label-style-common">
                    Description
                  </label>
                  <br></br>
                  <label>--</label>
                </div>
              </div>
              <div className="suite-of-suite-deails-area-style-popup ">
                <div className="grid grid-cols-2 mt-3 p-3 ">
                  <div className="suite-header-style">
                    <label className="module-script-label-style float-left"> Suites</label>
                  </div>
                </div>
                <Table columns={detailsColumns} data={suiteArray} />
                <div className="pb-4">
                  {/* {data.length ? (
                                            <Pagination postsPerPage={postsPerPage}
                                                totalPosts={data.length}
                                                paginate={paginate} setImmediatePrevPage={setImmediatePrevPage} indexOfFirstPost={indexOfFirstPost}
                                                indexOfLastPost={indexOfLastPost} setImmediateNextPage={setImmediateNextPage}
                                                currentPage={currentPage}
                                            />
                                        ) : null
                                        } */}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="gray-btn"
                onClick={() => {
                  setShowModal(false);
                  setOperation('');
                }}
              >
                Close
              </button>
            </div>
          </div>
        ) : (
          <div className="modal-container page-modal-size">
            <form onSubmit={formik.handleSubmit}>
              <div className="modal-header ">
                <label className="Popup-header-common Popup-header-layout-style-user">Reschedule</label>
                <IconButton
                  color="primary"
                  component="span"
                  className="close"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>

              <div className="schedule-popup-height" id="journal-scroll">
                <div className="grid grid-cols-2  mt-2 pb-3 ml-1 ">
                  <div>
                    <label className="schedule-font-style">
                      <span className="text-red-400">* </span>Name
                    </label>
                    <br></br>
                    <TextField
                      id="standard-basic"
                      disabled="true"
                      className={classes.root}
                      value={selectedDataObj && selectedDataObj?.name ? selectedDataObj?.name : null}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2  mt-2 pb-3 ml-1 ">
                  <div>
                    <label className="schedule-font-style">
                      <span className="text-red-400">* </span>Suite Name
                    </label>
                    <TextField
                      id="standard-basic"
                      disabled="true"
                      className={classes.root}
                      value={
                        selectedDataObj && selectedDataObj?.schedule && selectedDataObj?.schedule.suiteName
                          ? selectedDataObj?.schedule.suiteName
                          : null
                      }
                    />
                  </div>
                  <div>
                    <label className="schedule-font-style">
                      <span className="text-red-400">* </span>Repeat
                    </label>
                    <div className="className={classes.formControl}">
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={repeatData}
                        onChange={handleChange}
                        style={{ width: '80%' }}
                      >
                        {scheduleRepeatArray.map((scheduleVal) => (
                          <MenuItem value={scheduleVal}>{scheduleVal}</MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-2  mt-2 pb-3 ml-1 ">
                  <div className="schedule-style">
                    <label className="schedule-font-style">
                      <span className="text-red-400">* </span>Schedule Date/Time
                    </label>
                    <div className="flex flex-row mr-3">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justifyContent="space-around">
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            minDate={new Date()}
                            margin="normal"
                            id="date-picker-inline"
                            value={sheduledDate}
                            onChange={changeScheduleDate}
                            autoOk={true}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                          <TimePicker
                            ampm={false}
                            openTo="hours"
                            views={['hours', 'minutes']}
                            inputFormat="HH:mm"
                            mask="__:__:__"
                            minTime={
                              JSON.stringify(sheduledDate)?.substring(0, 11) === JSON.stringify(today)?.substring(0, 11)
                                ? new Date(0, 0, 0, JSON.stringify(validationMinTime)?.substring(1, 3))
                                : null
                            }
                            shouldDisableTime={(timeValue, clockType) => {
                              if (
                                JSON.stringify(sheduledDate)?.substring(0, 11) ===
                                JSON?.stringify(today)?.substring(0, 11)
                              ) {
                                let scheduledHour = moment(new Date(scheduleTime)).format('HH');
                                if (JSON.stringify(minHour) === scheduledHour) {
                                  if (clockType === 'minutes') {
                                    return timeValue <= minMinute;
                                  }
                                }
                              }
                            }}
                            // label="With seconds"
                            value={scheduleTime}
                            onChange={(newValue) => {
                              changeScheduleTime(newValue);
                              checkScheduleValidation();
                            }}
                            renderInput={(params) => <TextField id="scheduleId" {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="expire-style">
                    <label className="schedule-font-style">
                      <span className="text-red-400">* </span>Expire Date/Time
                    </label>
                    <div className="flex flex-row mr-3">
                      <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
                        <Grid container justifyContent="space-around">
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            minDate={new Date(sheduledDate)}
                            id="date-picker-inline-expiredate"
                            value={expiryDate}
                            onChange={changeExpiryDate}
                            autoOk={true}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                          <TimePicker
                            ampm={false}
                            openTo="hours"
                            views={['hours', 'minutes']}
                            inputFormat="HH:mm"
                            mask="__:__:__"
                            minTime={
                              JSON.stringify(expiryDate)?.substring(0, 11) ===
                              JSON.stringify(sheduledDate)?.substring(0, 11)
                                ? new Date(0, 0, 0, new Date(scheduleTime)?.toString()?.slice(16, 18))
                                : null
                            }
                            shouldDisableTime={(timeValue, clockType) => {
                              if (
                                JSON.stringify(sheduledDate)?.substring(0, 11) ===
                                JSON.stringify(expiryDate)?.substring(0, 11)
                              ) {
                                let scheduledHour = moment(new Date(scheduleTime)).format('HH');
                                let expiredHour = moment(new Date(expiryTime)).format('HH');
                                let minExpMinute = moment(new Date(scheduleTime)).format('mm');

                                if (expiredHour === scheduledHour) {
                                  if (clockType === 'minutes') {
                                    let stringVal = '40';
                                    return timeValue <= minExpMinute;
                                  }
                                }
                              }
                            }}
                            // label="With seconds"
                            value={expiryTime}
                            onChange={(newValue) => {
                              changeExpiryTime(newValue);
                              checkExpiryValidation();
                            }}
                            renderInput={(params) => <TextField id="expiryId" {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2  mt-2 pb-3 ml-1 ">
                  {/* <div>
                                        <InfoOutlinedIcon className="hyper_link_color-common mr-2 ml-1" /><label><span className=" schedule-font-style">Estimated Duration is</span><span className="schedule-time-font-style"> 02:30:00</span></label>

                                    </div> */}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  className="secondary-btn"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="primary-btn"
                  disabled={scheduleBtnDisable || expireBtnDisable}
                  onClick={() => reScheduleExecutionFun(selectedDataObj)}
                >
                  Reschedule
                </button>
              </div>
            </form>
          </div>
        )}
      </Modal>
      <div className="content_area_style overflow-auto" id="journal-scroll">
        <div>
          <div className="mt-3 suite-of-suite-header-style">
            <label className="project_label page-header-common float-left">Scheduled Instances</label>
          </div>
        </div>
        <div className="">
          {/* <div className="suite-of-suite-deails-area-style"> */}
          <div className="table-height-executionDashboard overflow-auto dashboard-table-height ">
            <Table columns={columns} data={dashboardData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ScheduledInstance;

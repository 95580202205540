import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useState } from 'react';
import Modal from 'react-modal';
import { DragIndicatorOutlined } from '@material-ui/icons';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import RemoveWarningModal from './shared_elements/remove_warning_modal';
import { TextField, Tooltip } from '@material-ui/core';
import DiscardElementModal from './shared_elements/discard_elemen_modal';
import { mergeElementReq, postElementReq } from '../../../../../api/api_services';

const ElementConflictModal = (props) => {
  let [disableAll, setDisableAll] = useState(false);
  let [selectedIndex, setSelectedIndex] = useState(0);
  const [conflict, setConflict] = useState(true);
  let [discardElementModal, setDiscardElementModal] = useState(false);
  const childRef = useRef();
  let isshare = false;
  let customStylesConflict = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      right: '25%',
      bottom: '5%',
      maxHeight: '534px',
      width: '1120px',
      padding: '0px',
      overflow: 'auto',
      boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
    },
  };

  let [elementData, setElementData] = useState(props.sharedConflictEles);
  let name = props.sharingEle.name;
  let [pageId, setPageId] = useState(props.sharingEle.parentId);
  let [eleId, setEleId] = useState(props.sharingEle.id);
  let [payloadIndex, setPayloadIndex] = useState(0);
  let project = JSON.parse(localStorage.getItem('selected-project'));
  const [sharingData, setSharingData] = useState(props.sharingEle);
  let [locForCreatAsEle, setLocForCreatAsEle] = useState(props.sharingEle.locators);
  let [sharingEleAsEle, setSharingEleAsEle] = useState(props.sharingEle);
  let [sharingLoc, setSharingLoc] = useState([...sharingData?.locators]);

  let closeModal = (val) => {
    setDiscardElementModal(val);
  };

  let clearMergeConflictReq = async () => {
    let response;
    let payload = elementData[payloadIndex];
    payload.locators = elementData[payloadIndex].Locators;
    payload.parentId = elementData[payloadIndex].parentId[0];
    payload.projectType = props.projectType;
    payload.projectId = project.id;
    delete payload?.Locators;
    delete payload?.pageIdNames;
    try {
      response = await mergeElementReq(payload, elementData[payloadIndex].id, eleId, pageId);
      if (response.data?.responseCode === 200 && response.data?.status === 'SUCCESS') {
        props?.getAllElemtsReq(props?.projectType, props?.platForm);
        props.reloadTree(true);
        props.updateSharedData('Y');
      } else {
        if (props.createAsSharedEle) {
          createAsEle();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {}, [elementData]);

  function handleOnDragEnd(result) {
    let sharedData = elementData;
    let sharingdata = sharingData;
    if (!result.destination) return;

    if (result.destination.droppableId === 'droppable' && result.source.droppableId === 'droppableSharing') {
      const shareditems = Array.from(elementData[selectedIndex].Locators);
      const sharingitems = Array.from(sharingData.locators);

      if (isshare === false) {
        const [modifiedSharingItem] = sharingitems.splice(result.source.index, 1);
        modifiedSharingItem.key = 'dropped';
        shareditems.splice(result.destination.index, 0, modifiedSharingItem);
        sharingdata.locators = sharingitems;
        sharedData[selectedIndex].Locators = shareditems;
        setSharingLoc([...sharingitems]);
        setElementData(sharedData);
        setSharingData(sharingdata);
        if (sharingdata.locators.length === 0) {
          setDisableAll(false);
          setPayloadIndex(selectedIndex);
        } else {
          setDisableAll(true);
        }
        childRef.current.getAlert();
      }
      isshare = false;
    } else if (
      result.destination.droppableId === 'droppableSharing' &&
      result.source.droppableId === 'droppableSharing'
    ) {
      return;
    } else {
      const items = Array.from(elementData[selectedIndex].Locators);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      sharedData[selectedIndex].Locators = items;
      setElementData(sharedData);
    }
  }

  const ReturnSharingElements = forwardRef((props, ref) => {
    let [locLength, setLocLength] = useState(false);
    let [locDivComps, setlocDivComps] = useState(
      <Droppable droppableId="droppableSharing">
        {(provided) => (
          <span className="pr-4 scrolClass locColWidth" {...provided.droppableProps} ref={provided.innerRef}>
            {sharingLoc?.map((locator, index) => {
              return (
                <ReturnCommonLocators
                  dltCmpltRow={dltCmpltRow}
                  locator={locator}
                  index={index}
                  sharingData={sharingData}
                  selectedIndex={props.selectedIndex}
                  sharedEleLocator={props.elementData[selectedIndex]?.Locators}
                />
              );
            })}
            {provided.placeholder}
          </span>
        )}
      </Droppable>
    );
    useEffect(() => {
      dltCmpltRow(props.sharingData.locators?.length);
    }, [props.sharingData]);
    useImperativeHandle(ref, () => ({
      getAlert() {
        dltCmpltRow(props.sharingData.locators?.length);
      },
    }));
    let prepLocComponents = (sharingLoc) => {
      return (
        <Droppable droppableId="droppableSharing" key="droppableSharing">
          {(provided) => (
            <span className="pr-4 scrolClass locColWidth" {...provided.droppableProps} ref={provided.innerRef}>
              {sharingLoc?.map((locator, index) => {
                return (
                  <ReturnCommonLocators
                    dltCmpltRow={dltCmpltRow}
                    locator={locator}
                    index={index}
                    sharingData={sharingData}
                    selectedIndex={props.selectedIndex}
                    sharedEleLocator={props.elementData[selectedIndex]?.Locators}
                  />
                );
              })}
              {provided.placeholder}
            </span>
          )}
        </Droppable>
      );
    };

    useEffect(
      (sharingLoc) => {
        if (sharingLoc?.length > 0) {
          setlocDivComps(prepLocComponents(sharingLoc));
        }
      },
      [sharingLoc]
    );

    let dltCmpltRow = (val, sharingElements) => {
      if (sharingElements !== undefined) {
        setSharingData(sharingElements);
      }
      if (val === 0) {
        setLocLength(true);
      }
    };
    return (
      <span>
        {locLength ? null : (
          <div className="flex flex-row colourLMNormal sharedRowmerge font14px pl-6 rowsepratorLine">
            <span className="eleInWidth">Project</span>
            <div className="nameColWidth path-trimmer" title={props.sharingData.name ? props.sharingData.name : '--'}>
              {props.sharingData.name}
            </div>
            <div
              className="pagenameColWidth path-trimmer"
              title={props.sharingData?.parentName ? props.sharingData?.parentName : '--'}
            >
              {props.sharingData?.parentName}
            </div>
            <span className="typeColWidth">{props.sharingData.type}</span>
            {locDivComps}
          </div>
        )}
      </span>
    );
  });

  let createAsEle = async () => {
    let payload = sharingEleAsEle;
    payload.isShared = 'N';
    payload.locators = locForCreatAsEle;
    let response;
    if (props.createAsSharedEle) {
      try {
        response = await postElementReq(payload, props.pageKeyForEle);
        if (response.data.responseObject) {
          props?.getAllElemtsReq(props?.projectType, props?.platForm);
          props.reloadTree(true);
          props.MyAlert.success(`${payload.name} ${payload.type} created successfully`);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const ReturnCommonLocators = (props) => {
    let sharingElements = sharingData;
    let [selectedLoc, setSelectedLoc] = useState(props.locator);
    let [dltLocRow, setDltLocRow] = useState(false);
    let [isSame, setIsSame] = useState(false);
    let [removeWarningModal, setRemoveWarningModal] = useState(false);
    let [isLocatorEditable, setIsLocatorEditable] = useState(false);
    let [locType, setLocType] = useState(props.locator.name);
    let [locValue, setLocValue] = useState(props.locator.name + '-' + props.locator.value);

    useEffect(() => {
      props.sharedEleLocator.map((sharingLoc) => {
        if (sharingLoc.value === props.locator?.value && sharingLoc.name === props.locator?.name) {
          setIsSame(true);
        }
        return null;
      });
    }, []);

    let setModifiedLoc = () => {
      let sharingdata = sharingData;
      const sharingitems = Array.from(sharingData.locators);

      sharingdata.locators = sharingitems;
      if (sharingdata.locators?.length === 0) {
        setDisableAll(false);
      }
      setSharingLoc([...sharingdata.locators]);
      setSharingData(sharingdata);

      if (sharingElements.locators?.length === 0) {
        setPayloadIndex(props.selectedIndex);
      }
      props.dltCmpltRow(sharingdata.locators?.length);
    };
    let closeModal = (val) => {
      setRemoveWarningModal(val);
    };
    let handelChange = (e) => {
      let editedVal = e.target.value.split('-');
      if (editedVal[1]) {
        setLocValue(props.locator.name + '-' + editedVal[1]);
      } else {
        setLocValue(props.locator.name + '-');
      }
    };

    let locOnBlur = (e) => {
      let editedVal = e.target.value.split('-');
      if (editedVal[1]) {
        setLocValue(props.locator.name + '-' + editedVal[1]);
        let sharingdata = sharingData;
        sharingdata.locators[props.index].value = editedVal[1];
        setSharingLoc([...sharingdata.locators]);
        setSharingData(sharingdata);
        props.dltCmpltRow(sharingdata.locators.length, sharingdata);
      } else {
        setLocValue(props.locator.name + '-' + props.locator.value);
      }
    };

    let handelLocatorOnClick = () => {
      if (props.locator?.status !== 'NOT_USED') {
        setRemoveWarningModal(true);
      } else {
        setDltLocRow(true);
        setModifiedLoc();
      }
    };

    return (
      <span>
        {dltLocRow ? null : (
          <Draggable
            key={'dragRowSharing' + selectedLoc?.name + selectedLoc?.value}
            draggableId={'dragRowSharing' + selectedLoc?.name + selectedLoc.value}
            index={props.index}
          >
            {(provided) => (
              <div>
                <div className="pb-3.5" key={`tableRowSharing`} ref={provided.innerRef} {...provided.draggableProps}>
                  <span>
                    <span {...provided.dragHandleProps}>
                      <DragIndicatorOutlined fontSize="small" className="text-blue-600 mb-2" />
                    </span>
                    <span
                      className={` ${isSame ? 'dangerColor' : null}`}
                      onClick={() => {
                        if (
                          locType === 'xpath' ||
                          locType === 'cssSelector' ||
                          locType === 'partialLinkText' ||
                          locType === 'AndroidUiAutomator' ||
                          locType === 'Pridicate String' ||
                          locType === 'Class Chain'
                        ) {
                        }
                      }}
                      onChange={() => {
                        if (isLocatorEditable === true && isSame === true) {
                          setIsSame(false);
                        }
                      }}
                    >
                      {!isLocatorEditable ? (
                        <span
                          className=" inline-block mergeLocWidth path-trimmer"
                          title={`${locValue ? locValue : '--'}`}
                        >
                          {locValue}
                        </span>
                      ) : (
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          autoComplete="off"
                          autoFocus={true}
                          onChange={(event) => {
                            handelChange(event);
                          }}
                          onBlur={(event) => {
                            locOnBlur(event);
                            setIsLocatorEditable(false);
                          }}
                          className=""
                          value={locValue}
                          type="text"
                          placeholder="Enter element name"
                        />
                      )}
                    </span>
                    <Tooltip title="Remove Locator" placement="bottom">
                      <button
                        className="dltLocator cursor-pointer pl-2 mb-2"
                        onClick={() => {
                          handelLocatorOnClick();
                        }}
                      >
                        {' '}
                        X
                      </button>
                    </Tooltip>
                  </span>
                </div>
                {provided.placeholder}
              </div>
            )}
          </Draggable>
        )}
        {removeWarningModal ? (
          <RemoveWarningModal
            locator={props.locator}
            closeModal={closeModal}
            setDltLocRow={setDltLocRow}
            setModifiedLoc={setModifiedLoc}
          />
        ) : null}
      </span>
    );
  };

  let handelDiscardEle = () => {
    let eledata = [...elementData];
    eledata.splice(selectedIndex, 1);
    props.MyAlert.success(
      `${elementData[selectedIndex]?.name} ${elementData[selectedIndex]?.type} element skipped successfully.`
    );

    if (eledata.length === selectedIndex) {
      setSelectedIndex(selectedIndex - 1);
    }
    setElementData(eledata);
  };

  let returnname = (nameArray) => {
    let names = [];
    for (let i = 0; i < nameArray?.length; i++) {
      names.push(nameArray[i].name);
    }
    return names.toString();
  };

  let beforStart = (result) => {
    const shareditems = Array.from(elementData[selectedIndex].Locators);
    const sharingitems = Array.from(sharingData.locators);
    let shareitem = result.source.index;
    let dragitem = sharingitems[shareitem];
    if (result.source?.droppableId === 'droppableSharing') {
      for (let i = 0; i < shareditems.length; i++) {
        if (
          dragitem?.name === 'id' ||
          (dragitem?.name === 'name') | (dragitem?.name === 'className') ||
          dragitem?.name === 'tagName' ||
          dragitem?.name === 'linkText' ||
          dragitem?.name === 'accessibilityID'
        ) {
          if (shareditems[i].name === dragitem.name) {
            isshare = true;
            break;
          }
        } else if (shareditems[i].name === dragitem.name && shareditems[i].value === dragitem.value) {
          isshare = true;
          break;
        }
      }
    }
  };

  return (
    <div>
      <Modal
        isOpen={conflict}
        style={customStylesConflict}
        onRequestClose={() => {
          props.unCheckShareElementBtn(props.sharingEle);
          setConflict(false);
          props.closeModal(false);
        }}
      >
        <div className=" pt-4 pb-4 sepratorLine overflow-y-auto" id="journal-scroll">
          <span className="">
            <span>
              <div className="pl-6 text-sm font-semibold ">{name} - Element Conflict</div>
              <button
                onClick={() => {
                  props.unCheckShareElementBtn(props.sharingEle);
                  createAsEle();
                  setConflict(false);
                  props.closeModal(false);
                }}
                type="button"
                className="float-right -mt-6 mr-3.5  text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="#525252">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </span>
          </span>
        </div>
        <div className="overflow-y-auto mt-5 " id="journal-scroll">
          <div>
            <div className="w-full pb-4 pl-6">
              <span className="text-sm font-semibold ">
                {selectedIndex + 1} out of {elementData.length} Conflict element
              </span>
              <span className="float-right ">
                {elementData.length > 1 ? (
                  <button
                    type="button"
                    className="gray-btn mr-4 Recordedsave"
                    disabled={sharingLoc.length === 0 || disableAll}
                    onClick={() => {
                      handelDiscardEle();
                    }}
                  >
                    <span className="cancel-label-common cancel-label-layout-style-project">Skip</span>
                  </button>
                ) : null}
              </span>
            </div>
            <div className="sepratorLine">
              <div className="h-12 tableHeaderBackground ">
                <div className="flex flex-row sharedtableHeaderStyle font12px font-semibold">
                  <span className="eleInWidth">Element In</span>
                  <span className="nameColWidth">Element Name</span>
                  <span className="pagenameColWidth">
                    {project.type === 'Mobile'
                      ? 'Screen '
                      : project.type === 'Web'
                        ? 'Page '
                        : (project.type === 'Web & Mobile' || project.type === 'Salesforce') &&
                            props?.platForm === 'Web'
                          ? 'Page '
                          : (project.type === 'Web & Mobile' || project.type === 'Salesforce') &&
                              (props?.platForm === 'Android' ||
                                props?.platForm === 'iOS' ||
                                props?.platForm === 'Ios' ||
                                props?.platForm === 'MobileWeb')
                            ? 'Screen '
                            : 'Page '}
                    Name
                  </span>
                  <span className="typeColWidth">Type</span>
                  <span className="pr-4 locColWidth">Locators</span>
                </div>
              </div>
              {elementData.length > 0 ? (
                <DragDropContext onBeforeDragStart={beforStart} onDragEnd={handleOnDragEnd}>
                  <ReturnSharingElements
                    sharingData={sharingData}
                    selectedIndex={selectedIndex}
                    elementData={elementData}
                    ref={childRef}
                  />
                  <div className="flex flex-row colourLMNormal sharedRowmerge font14px pl-6">
                    <span className={`eleInWidth ${sharingData.locators.length > 0 ? 'dangerColor' : null}`}>
                      Shared
                    </span>
                    <div
                      className={`nameColWidth path-trimmer ${sharingData.locators.length > 0 ? 'dangerColor' : null}`}
                      title={elementData[selectedIndex]?.name ? elementData[selectedIndex]?.name : '--'}
                    >
                      {elementData[selectedIndex]?.name ? elementData[selectedIndex]?.name : '--'}
                    </div>
                    <div
                      className="pagenameColWidth path-trimmer"
                      title={
                        elementData[selectedIndex]?.pageIdsNames
                          ? returnname(elementData[selectedIndex]?.pageIdsNames)
                          : '--'
                      }
                    >
                      {elementData[selectedIndex].pageIdsNames
                        ? returnname(elementData[selectedIndex]?.pageIdsNames)
                        : '--'}
                    </div>
                    <span className="typeColWidth">{elementData[selectedIndex]?.type}</span>
                    <Droppable droppableId="droppable" key="droppable">
                      {(provided, snapshot) => (
                        <span
                          className="pr-8 scrolClass locColWidth"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {elementData[selectedIndex]?.Locators?.map((locator, index) => {
                            return (
                              <Draggable key={'dragRow' + index} draggableId={'dragRow' + index} index={index}>
                                {(provided) => (
                                  <div>
                                    <div
                                      className="pb-3.5"
                                      key={`tableRow${index}`}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                    >
                                      <span {...provided.dragHandleProps}>
                                        <DragIndicatorOutlined fontSize="small" className="text-blue-600 mb-2" />
                                      </span>
                                      <span
                                        title={`${
                                          locator?.name && locator?.value ? locator?.name + '-' + locator?.value : '--'
                                        }`}
                                        className={`${
                                          elementData[selectedIndex]?.Locators[index].key === 'dropped'
                                            ? 'inline-block mergeLocWidth path-trimmer shareloctxt'
                                            : 'inline-block mergeLocWidth path-trimmer '
                                        }`}
                                      >
                                        {locator?.name}-{locator?.value}
                                      </span>
                                    </div>
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </span>
                      )}
                    </Droppable>
                  </div>
                </DragDropContext>
              ) : (
                <div className="flex flex-row colourLMNormal sharedRowmerge font14px pl-6 rowsepratorLine"></div>
              )}

              {elementData.length > 1 ? (
                <div className="sharedPagination">
                  <span className="pageborderleft ml-2 pl-2 float-right">
                    <button
                      type="button"
                      className={`${selectedIndex === 0 ? 'nxtIcon' : ' clickablenxtIcon'} cursor-pointer Recordedsave`}
                      onClick={() => {
                        setSelectedIndex(selectedIndex - 1);
                      }}
                      disabled={selectedIndex === 0 || disableAll}
                    >
                      {'<'}
                    </button>
                    {elementData.map((element, index) => {
                      return (
                        <span
                          className={`cursor-pointer  ${
                            selectedIndex === index ? 'selectedpagecountStyle' : 'unselectedpagecountStyle'
                          } ml-2 mr-2 text-center`}
                          onClick={() => {
                            if (!disableAll) {
                              setSelectedIndex(index);
                            }
                          }}
                        >
                          <span className="">{index + 1}</span>
                        </span>
                      );
                    })}
                    <button
                      type="button"
                      className={`${
                        selectedIndex === elementData.length - 1 ? 'nxtIcon' : 'clickablenxtIcon'
                      } cursor-pointer Recordedsave`}
                      onClick={() => {
                        setSelectedIndex(selectedIndex + 1);
                      }}
                      disabled={selectedIndex === elementData.length - 1 || disableAll}
                    >
                      {'>'}
                    </button>
                  </span>
                  <span className="pagecount float-right">
                    {selectedIndex + 1} - {selectedIndex + 1} of {elementData.length}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="mt-4 text-right mb-4 mr-4">
          <button
            type="button"
            onClick={() => {
              createAsEle();
              props.unCheckShareElementBtn(props.sharingEle);
              props.closeModal(false);
            }}
            className="gray-btn"
          >
            <span className="cancel-label-common cancel-label-layout-style-project">Cancel</span>
          </button>
          {selectedIndex !== 0 ? (
            <button
              type="button"
              onClick={() => {
                setSelectedIndex(selectedIndex - 1);
              }}
              disabled={disableAll}
              className="gray-btn ml-3 Recordedsave"
            >
              <span className="cancel-label-common cancel-label-layout-style-project">Back</span>
            </button>
          ) : null}
          {selectedIndex !== elementData.length - 1 ? (
            <button
              type="button"
              onClick={() => {
                setSelectedIndex(selectedIndex + 1);
              }}
              disabled={disableAll}
              className="gray-btn ml-3 Recordedsave"
            >
              <span className="cancel-label-common cancel-label-layout-style-project">Next</span>
            </button>
          ) : null}
          <button
            type="button"
            className="primary-btn ml-3"
            disabled={sharingLoc.length !== 0}
            onClick={() => {
              clearMergeConflictReq();
              props.closeModal(false);
            }}
          >
            <span className="create-label-common create-label-layout-style-project">Save Changes</span>
          </button>
        </div>
        {discardElementModal ? (
          <DiscardElementModal closeModal={closeModal} handelDiscardEle={handelDiscardEle} />
        ) : null}
      </Modal>
    </div>
  );
};

export default ElementConflictModal;

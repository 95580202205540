import ProjectInfoBar from './ProjectInfoBar';
import ProjectResources from './ProjectResources';
import { useState, useEffect } from 'react';
import { getIndividualDashboardData } from '@api/api_services';

function ProjectInfoAndResources({ projectId }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    getIndividualDashboardData(projectId).then((data) => {
      setData(data?.data?.responseObject);
    });
  }, [projectId]);

  return (
    <>
      <ProjectInfoBar projectData={data?.projectDetails} />
      <ProjectResources projectResourcesData={data?.resources} />
    </>
  );
}

export default ProjectInfoAndResources;

import React, { useContext, useState } from 'react';
import { Button, Drawer, Icon, Tooltip } from 'fireflink-ui';
import { colors } from '@src/css_config/colorConstants';
import { ASSERT_CONSTANT } from '../../../assert/jsx/constant';
import FileUploadButton from '@src/pages/common/fileUpload_button';
import { RestContext } from '../../../../restapi';
import JWTPayloadEditor from './JWTEditor';

const PrivateKeyAlgorithm = ({ formik, jwtBearerChange, ...props }) => {
  const {
    PRIVATE_KEY,
    PRIVATE_KEY_TOOLTIP,
    CHOOSE_FILE,
    PAYLOAD,
    PAYLOAD_TOOLTIP,
    PAYLOAD_EDITOR_NAME,
    REPLACE_FILE,
    PRIVATE_KEY_NAME,
    PEM_FILE_EXTENSION,
  } = ASSERT_CONSTANT;

  const { payload, privateKey, privateKeyFileName } = formik.values;

  const context = useContext(RestContext);

  const [privateKeyFile, setPrivateKeyFile] = useState(privateKeyFileName || '');
  const [fileName, setFileName] = useState(privateKeyFileName || '');

  const [showBtn, setShowBtn] = useState(false);
  const [open, setOpen] = useState(false);
  const [localFileError, setLocalFileError] = useState('');

  const [privateKeyData, setPrivateKeyData] = useState({
    payloadValue: payload || '',
    privateKeyValue: privateKey || '',
    file: context.values?.jwtPrivateKeyFile || null,
  });

  const { payloadValue, privateKeyValue, file } = privateKeyData;

  const createEvent = (name, value) => ({
    target: {
      name,
      value,
    },
  });

  const onPayloadChange = (value, variableData) => {
    if (value) {
      value = props.getUserInputValue(variableData, false);
    }
    const event = createEvent(PAYLOAD_EDITOR_NAME, value);
    jwtBearerChange(event);
    setPrivateKeyData({ ...privateKeyData, payloadValue: variableData });
  };

  const onFileSave = () => {
    if (privateKeyFile) {
      formik.values.privateKeyFileName = file?.name;
      context.values.jwtAuth.privateKeyFileName = file?.name;
    } else {
      setFileName('');
      formik.values.privateKeyFileName = '';
      context.values.jwtAuth.privateKeyFileName = '';
      onJwtRemoveFile();
    }
    onClickFileRead();
  };

  const onClickFileRead = async () => {
    if (privateKeyFile) {
      const fs = new FileReader();
      fs.readAsText(file);

      fs.onload = () => {
        const fileData = fs.result;
        setPrivateKeyData({ ...privateKeyData, privateKeyValue: fileData });
        const event = createEvent(PRIVATE_KEY_NAME, fileData);
        jwtBearerChange(event);
      };

      fs.onerror = (error) => {
        console.error('Error reading file:', error);
      };
    } else {
      console.error('No file selected');
    }
    setOpen(false);
  };

  const onPrivateKeyChange = (e) => {
    const { files } = e.target;
    const file = files[0];
    const fileExtension = file?.name.slice(((file?.name.lastIndexOf('.') - 1) >>> 0) + 2);
    if (fileExtension?.toLowerCase() !== PEM_FILE_EXTENSION) {
      setLocalFileError('Invalid File Type');
      props?.MyAlert.warning(`${e.target.files[0].name} is unsupported.`);
    } else {
       setLocalFileError('');
      setPrivateKeyData({ ...privateKeyData, file, showBtn: true });
      context.setFieldValue('jwtPrivateKeyFile', file);
      setFileName(file?.name);
      setPrivateKeyFile(file?.name);
      setShowBtn(true);
    }
 
  };

  const onTextareaChange = (e) => {
    jwtBearerChange(e);
    const { value } = e;
    setPrivateKeyData({ ...privateKeyData, privateKeyValue: value });
  };

  const onDrawerClose = () => {
    if (!fileName) {
      setPrivateKeyData({
        ...privateKeyData,
        file: null,
      });
      context.values.jwtAuth.privateKeyFileName = '';
      setLocalFileError('');
    }
    if (localFileError) {
      setLocalFileError('');
      setPrivateKeyFile('');
    }
    setOpen(false);
  };

  const saveButtonProps = {
    label: 'Save',
    variant: 'primary',
    disabled: (file?.name || fileName) && !localFileError ? false : true,
    onClick: onFileSave,
  };
  const cancelButtonProps = {
    label: 'Cancel',
    variant: 'secondary',
    disabled: false,
    onClick: onDrawerClose,
  };

  const onJwtRemoveFile = () => {
    setShowBtn(false);
    setFileName('');

    setPrivateKeyData({ ...privateKeyData, file: null, privateKeyValue: '', showBtn: false });
    const event = {
      target: {
        value: '',
        name: PRIVATE_KEY_NAME,
      },
    };
    jwtBearerChange(event);
  };

  return (
    <>
      <Drawer
        isOpen={open}
        onClose={() => setOpen(false)}
        title={CHOOSE_FILE}
        primaryButtonProps={saveButtonProps}
        secondaryButtonProps={cancelButtonProps}
        size="small"
        overlay={true}
      >
        <div className="fontPoppinsMediumMd flex items-center">
          <span className="fontPoppinsRegularSm choose-file-header">File From Local Drive</span>
          <FileUploadButton
            name="privateKey"
            id="value"
            accept={'.pem'}
            webServiceUploadBtn={true}
            onChange={onPrivateKeyChange}
            value={privateKeyFile || privateKeyFileName}
            setLocalFileName={setPrivateKeyFile}
            showBtn={showBtn}
            setShowBtn={setShowBtn}
             setLocalFileError={setLocalFileError}
          />
        </div>
      </Drawer>

      <div className="flex gap-2">
        <div className="w-full">
          <div className="flex mb-1 items-center pl-4">
            <div className="flex gap-2 items-center relative">
              <p className="fontPoppinsRegularSm jwt-select-title mt-2">
                <span className="common-auth-star-symbol">*</span> {PAYLOAD}
              </p>
              <div className="private-key_payload">
                <Tooltip placement="right" title={PAYLOAD_TOOLTIP}>
                  <span>
                    <Icon name="info_grey" width={14} height={14} color={colors.gray} />
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="ace-container">
            <JWTPayloadEditor
              getCodeValue={onPayloadChange}
              {...props}
              value={props.getUserInputValue(payloadValue, true)}
            />
          </div>
        </div>

        <div className="private-key-container">
          <div className="flex items-center file-container">
            <span className="common-auth-star-symbol mr-1 top-1 ">*</span>
            <div className="flex gap-2 items-center">
              <span className="fontPoppinsRegularSm jwt-select-title">{PRIVATE_KEY}</span>
              <Tooltip placement="right" title={PRIVATE_KEY_TOOLTIP}>
                <span>
                  <Icon name="info_grey" width={14} height={14} color={colors.gray} />
                </span>
              </Tooltip>
            </div>
            <Button
              label={fileName ? REPLACE_FILE : CHOOSE_FILE}
              size="small"
              onClick={() => setOpen(true)}
              className="ml-4"
            />
            {fileName && (
              <>
                <span className="file-name ">
                  {fileName && fileName?.length >= 15 ? fileName?.substring(0, 15) + '...' : fileName}
                </span>
                <span className="ml-1">
                  <Icon name="close_blue" width={14} height={14} onClick={onJwtRemoveFile} />
                </span>
              </>
            )}
          </div>
          <textarea
            className="private-key-textarea fontPoppinsRegularSm"
            name={PRIVATE_KEY_NAME}
            value={privateKeyValue}
            onChange={onTextareaChange}
          ></textarea>
        </div>
      </div>
    </>
  );
};

export default PrivateKeyAlgorithm;

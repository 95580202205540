import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Tooltip, FormControl, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { saveFileFromBlob } from '@src/pages/test-development/shared/common-methods';
import {
  exportProject,
  getProjectListDropdownReq,
  importProject,
  updateProjectRequest,
  getSingleProjectRequest,
} from '@src/api/api_services';
import {
  CustomFormControl,
  ImportExportSelectDropdown,
  displayRepository,
  displayTestExecution,
  displayDefects,
  displayTemplates,
  displayTemplatesSection,
  hasAllAutomationFeatures,
  isCombinedLicense,
  hasOnlyManual,
  hasOnlyWebService,
} from './ImportExportUtil';
import getProjectTypeIcons from '@src/pages/common/Layout/IconsComponents/SearchProjectIconsRenderer';
import { colors } from '@src/css_config/colorConstants';
import AlertPopUps from '@src/pages/common/alert_pop_ups/AlertPopUps';
import { ALERT_MODAL_CONSTANTS } from '@src/common/ui-constants';
import { IMPORT_EXPORT, REPOSITORY_VALUES, TEST_EXECUTION_VALUE, TEMPLATE_VALUE } from './ImportAndExportConstants';
import { ReactComponent as ImportIcon } from '@assets/svg-imports/import_icon.svg';
import { ReactComponent as ExportIcon } from '@assets/svg-imports/export_icon.svg';
import { IMPORT_EXPORT_CONSTANTS, LICENSE_DETAILS_CONSTANTS } from '@src/common/ui-constants';
import {
  purchasedFeatureTypeOfAutomation,
  getLicenseFeatures,
  isEmptyValue,
  validateZipFileExtension,
  validateFileExtension,
} from '@src/util/common_utils';
import { IMPORT_EXPORT_FILE_ALLOWED } from '@util/validations';

const useStyles = makeStyles({
  disabled: {
    '&.Mui-disabled': {
      color: colors.blue_dark,
      opacity: 0.5,
    },
  },
});
function ImportExportProject({ MyAlert, AlertContatiner, isExportPage }) {
  const checkboxref = useRef();
  const [isZipFileSelected, setIsZipFileSelected] = useState(null);
  const [projects, setProjects] = useState([]);
  const [isProjectSelected, setIsProjectSelected] = useState('');
  const [selectedProjectFileName, setSelectedProjectFileName] = useState('');
  const [showBtn, setShowBtn] = useState(false);
  const [selectedProjectName, setSelectedProjectName] = useState('');
  const [selectedProjectData, setSelectedProjectData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { ALERT } = ALERT_MODAL_CONSTANTS;
  const loggedInUser = JSON.parse(localStorage.getItem('test-optimize-user'));
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const {
    REPOSITORY,
    TEST_DATA,
    TEST_EXECUTION,
    DEFECTS,
    TEMPLATE,
    REPOSITORY_DATA,
    TEST_EXECUTION_DATA,
    TEMPLATE_DATA,
  } = IMPORT_EXPORT;
  const [repositoryData, setRepositoryData] = useState(JSON.parse(JSON.stringify(REPOSITORY_DATA)));
  const [repository, setRepository] = useState(REPOSITORY);
  const [testData, setTestData] = useState(TEST_DATA);
  const [defects, setDefects] = useState(DEFECTS);
  const [testExecutionData, setTestExecutionData] = useState(JSON.parse(JSON.stringify(TEST_EXECUTION_DATA)));
  const [testExecution, setTestExecution] = useState(TEST_EXECUTION);
  const [templatesData, setTemplatesData] = useState(JSON.parse(JSON.stringify(TEMPLATE_DATA)));
  const [templates, setTemplates] = useState(JSON.parse(JSON.stringify(TEMPLATE)));
  const [showResources, setShowResources] = useState(true);
  const [selectedRadioState, setselectedRadioState] = useState(true);
  const [isCompleteRadioButtonSelected, setIsCompleteRadioButtonSelected] = useState(false);
  const [selectedProjectType, setSelectedProjectType] = useState('');
  const [hasFeaturePurchased, setHasFeaturePurchased] = useState(true);
  const [isDisplayRepository, setIsDisplayRepository] = useState(true);
  const [isDisplaTestExec, setIsDisplayTestExec] = useState(true);
  const [isWebServiceProject, setIsWebServiceProject] = useState(false);
  const [isOnlyManual, setIsOnlyManual] = useState(hasOnlyManual());
  const hasCBasic = global.privilege.hasCBasic(getLicenseFeatures());
  const hasManual = global.privilege.hasManualTestCase(getLicenseFeatures());
  const hasDefects = global.privilege.hasDefects(getLicenseFeatures());
  const hasAutomation = global.privilege.hasAutomation(getLicenseFeatures());
  const classes = useStyles();

  useEffect(() => {
    getProjectList();
  }, []);

  useEffect(() => {
    if (!hasCBasic && !hasAllAutomationFeatures() && isCombinedLicense()) {
      clearCheckBoxesOnPRojectSelection();
      if (selectedProjectType) {
        setHasFeaturePurchased(purchasedFeatureTypeOfAutomation(selectedProjectType));
      } else {
        setHasFeaturePurchased(true);
      }
    }
    if (selectedProjectType === LICENSE_DETAILS_CONSTANTS.WEBSERVICE) {
      setIsWebServiceProject(true);
    } else {
      setIsWebServiceProject(false);
    }
  }, [selectedProjectType]);

  useEffect(() => {
    if (hasFeaturePurchased) {
      setIsDisplayRepository(true);
      setIsDisplayTestExec(true);
      if (hasAutomation && hasCBasic) {
        setIsOnlyManual(false);
      }
    } else {
      setIsDisplayRepository(false);
      if (!hasManual) {
        setIsDisplayTestExec(false);
      } else if (!hasDefects) {
        setIsOnlyManual(true);
      }
    }
  }, [hasFeaturePurchased]);

  useEffect(() => {
    if (hasAutomation && !hasOnlyWebService()) {
      clearCheckBoxesOnPRojectSelection();
    }
  }, [isWebServiceProject]);

  const clearCheckBoxesOnPRojectSelection = () => {
    clearRepositoryData();
    clearTestExecutionData();
    clearTemplatesData();
    clearParentCheckboxes();
  };

  useEffect(() => {
    clearState();
  }, [window?.location?.pathname]);

  const clearRepositoryData = () => {
    for (const val of repositoryData) {
      val.checked = false;
      val.disabled = false;
    }
    setRepositoryData([...repositoryData]);
  };

  const clearTestExecutionData = () => {
    for (const val of testExecutionData) {
      val.checked = false;
      val.disabled = false;
    }
    setTestExecutionData([...testExecutionData]);
  };

  const clearTemplatesData = () => {
    for (const val of templatesData) {
      val.checked = false;
      val.disabled = false;
    }
    setTemplatesData([...templatesData]);
  };

  const clearParentCheckboxes = () => {
    repository.checked = false;
    repository.disabled = false;
    setRepository({ ...repository });
    testExecution.checked = false;
    testExecution.disabled = false;
    setTestExecution({ ...testExecution });
    templates.checked = false;
    templates.disabled = false;
    setTemplates({ ...templates });
    testData.checked = false;
    testData.disabled = false;
    setTestData({ ...testData });
    defects.checked = false;
    defects.disabled = false;
    setDefects({ ...defects });
  };

  const clearState = () => {
    setIsProjectSelected('');
    setSelectedProjectType('');
    setSelectedProjectData([]);
    setIsZipFileSelected(null);
    setSelectedProjectFileName('');
    setIsCompleteRadioButtonSelected(false);
    setselectedRadioState(true);
    setShowResources(true);
    clearRepositoryData();
    clearTestExecutionData();
    clearTemplatesData();
    clearParentCheckboxes();
  };

  const clearStateAndLoading = () => {
    setIsLoading(false);
    clearState();
  };

  function getProjectLabelWithIcon(type, value) {
    return [
      <Tooltip title={value} placement="top">
        <span className="flex">
          {' '}
          {getProjectTypeIcons(type, false)} <span className="ml-1 fontPoppinsRegularMd">{value}</span>
        </span>
      </Tooltip>,
    ];
  }

  const getProjectList = () => {
    getProjectListDropdownReq()
      .then((results) => {
        let tempProjectList = [];
        if (results.data.responseObject) {
          results.data.responseObject.filter((project) => {
            if (project.status === 'Open')
              tempProjectList.push({
                value: project?.name,
                label: getProjectLabelWithIcon(project?.type, project?.name),
                name: project?.id,
                type: project?.type,
              });
          });
        }
        setProjects([...tempProjectList]);
      })
      .catch((error) => {
        console.error('Error while fetching all Project Dropdown - data', error);
      });
  };

  const checkAllCheckBoxes = (arrayPassed, checkValue, label) => {
    arrayPassed = arrayPassed.map((value) => {
      value.checked = checkValue;
      if (label === REPOSITORY.label) {
        if (value?.value === REPOSITORY_VALUES.PROJECT_ELEMENTS) {
          value.disabled = checkValue;
        }
      }
      return value;
    });
    switch (label) {
      case REPOSITORY.label:
        setRepositoryData([...arrayPassed]);
        break;
      case TEST_EXECUTION.label:
        setTestExecutionData([...arrayPassed]);
        break;
      case TEMPLATE.label:
        setTemplatesData([...arrayPassed]);
    }
  };

  const handleChildRepositoryChange = (event) => {
    const repoCopy = { ...repository };
    let repositoryDataCopy = [...repositoryData];
    if (event.target.value === REPOSITORY_VALUES.STEP_GROUPS) {
      checkAllCheckBoxes(repositoryDataCopy, event.target.checked, repository.label);
      checkTestData(event);
    } else {
      repositoryDataCopy = repositoryDataCopy.map((value) => {
        if (value?.value === event.target.value) {
          value.checked = event.target.checked;
        }
        return value;
      });
    }
    repoCopy.checked = checkInderminate(repositoryDataCopy, false);
    setRepository({ ...repoCopy });
    setRepositoryData([...repositoryDataCopy]);
  };

  const handleRootRepositoryChange = (event) => {
    const repoCopy = { ...repository };
    const checkRepoArrCopy = [...repositoryData];
    repoCopy.checked = event.target.checked;
    setRepository({ ...repoCopy });
    checkTestData(event);
    checkAllCheckBoxes(checkRepoArrCopy, event.target.checked, repository.label);
  };

  const handleTestDataChange = (event) => {
    const testDataCopy = { ...testData };
    testDataCopy.checked = event.target.checked;
    setTestData(testDataCopy);
  };

  const checkTestData = (event) => {
    const testDataCopy = { ...testData };
    testDataCopy.checked = event.target.checked;
    testDataCopy.disabled = event.target.checked;
    setTestData(testDataCopy);
  };

  const handleDefectsChange = (event) => {
    const defectsCopy = { ...defects };
    defectsCopy.checked = event.target.checked;
    if (displayTemplatesSection(TEMPLATE_VALUE.DEFECT)) {
      handleTemplatesOnTextExeAndDefectChange(event);
    }
    setDefects(defectsCopy);
  };

  const checkInderminate = (arrayPassed, isIndeterminate) => {
    let count = 0;
    arrayPassed.forEach((value) => {
      if (value.checked) {
        count = count + 1;
      }
    });
    if (isIndeterminate) {
      return count === arrayPassed.length ? false : count > 0 ? true : false;
    } else {
      return count === arrayPassed.length;
    }
  };

  const handleChildTestExeChange = (event) => {
    let testExecutionDataCopy = [...testExecutionData];
    let testExecutionCopy = { ...testExecution };
    testExecutionDataCopy.map((execValue) => {
      if (execValue?.value === event.target.value) {
        execValue.checked = event.target.checked;
      }
      if (
        event.target.value === TEST_EXECUTION_VALUE.SUITES ||
        event.target.value === TEST_EXECUTION_VALUE.EXECUTIONS
      ) {
        if (execValue?.value === TEST_EXECUTION_VALUE.SCRIPTS) {
          execValue.checked = event.target.checked;
          execValue.disabled = event.target.checked;
        }
      }
    });
    handleRepoOnTestExeChange(event);
    if (displayTemplatesSection(TEMPLATE_VALUE.MANUAL)) {
      handleTemplatesOnTextExeAndDefectChange(event);
    }
    setTestExecutionData([...testExecutionDataCopy]);
    testExecutionCopy.checked = checkInderminate(testExecutionDataCopy, false);
    setTestExecution({ ...testExecutionCopy });
  };

  const handleRootTestExeChange = (event) => {
    const testExecutionCopy = { ...testExecution };
    const testExecutionDataCopy = [...testExecutionData];
    testExecutionCopy.checked = event.target.checked;
    setTestExecution(testExecutionCopy);
    checkAllCheckBoxes(testExecutionDataCopy, event.target.checked, testExecution.label);
    handleRepoOnTestExeChange(event);
    handleChildTestExeChange(event);
  };

  const handleRepoOnTestExeChange = (event) => {
    const repoCopy = { ...repository };
    const repositoryDataCopy = [...repositoryData];
    repoCopy.checked = event.target.checked;
    repoCopy.disabled = event.target.checked;
    checkTestData(event);
    repositoryDataCopy.map((value) => {
      value.checked = event.target.checked;
      value.disabled = event.target.checked;
    });
    setRepository(repoCopy);
    setRepositoryData(repositoryDataCopy);
  };

  const handleChildTemplatesChange = (event) => {
    const templatesDataCopy = [...templatesData];
    const templatesCopy = { ...templates };
    templatesDataCopy.map((tempValue) => {
      if (event.target.value === tempValue?.value) {
        tempValue.checked = event.target.checked;
      } else if (!displayTemplatesSection(tempValue?.value)) {
        tempValue.checked = event.target.checked;
      }
    });
    setTemplatesData([...templatesDataCopy]);
    templatesCopy.checked = checkInderminate(templatesDataCopy, false);
    setTemplates({ ...templatesCopy });
  };

  const handleRootTemplatesChange = (event) => {
    const templatesCopy = { ...templates };
    templatesCopy.checked = event.target.checked;
    checkAllCheckBoxes(templatesData, event.target.checked, templates.label);
    handleChildTemplatesChange(event);
    setTemplates({ ...templatesCopy });
  };

  const handleTemplatesOnTextExeAndDefectChange = (event) => {
    let templatesDataCopy = [...templatesData];
    let templatesCopy = { ...templates };
    templatesDataCopy.map((tempVal) => {
      if (
        [TEST_EXECUTION_VALUE.SCRIPTS, TEST_EXECUTION_VALUE.SUITES, TEST_EXECUTION_VALUE.EXECUTIONS].includes(
          event.target.value
        )
      ) {
        if (tempVal?.value === TEMPLATE_VALUE.MANUAL && displayTemplatesSection(tempVal?.value)) {
          tempVal.checked = event.target.checked;
          tempVal.disabled = event.target.checked;
        }
      }
      if (event.target.value === TEMPLATE_VALUE.DEFECTS) {
        if (tempVal?.value === TEMPLATE_VALUE.DEFECT && displayTemplatesSection(tempVal?.value)) {
          tempVal.checked = event.target.checked;
          tempVal.disabled = event.target.checked;
        }
      }
      if (!displayTemplatesSection(tempVal?.value)) {
        tempVal.checked = event.target.checked;
      }
    });
    let result = templatesDataCopy.some((value) => {
      return value.disabled;
    });
    templatesCopy.disabled = result;
    setTemplatesData([...templatesDataCopy]);
    templatesCopy.checked = checkInderminate(templatesDataCopy, false);
    setTemplates({ ...templatesCopy });
  };

  const getSelectedValues = (childData, parentData) => {
    let updatedData = getActualData(childData, parentData);
    if (updatedData?.length) {
      let temp = [];
      updatedData?.filter((details) => {
        if (details?.checked) {
          temp?.push(details?.value);
        }
      });
      if (temp.includes(REPOSITORY_VALUES.STEP_GROUPS)) {
        temp.push(REPOSITORY_VALUES.PROGRAM_ELEMENTS);
      }
      return temp;
    } else if (updatedData?.checked) {
      return [updatedData?.value];
    } else return [];
  };

  const getActualData = (childData, parentData) => {
    switch (parentData?.value) {
      case REPOSITORY_VALUES.REPOSITORY: {
        if (displayRepository() && isDisplayRepository) {
          let alteredData = childData?.filter((data) => {
            if (displayProjectElements(data?.value)) {
              return data;
            }
          });
          return alteredData;
        } else return [];
      }
      case TEST_EXECUTION_VALUE.EXECUTIONS: {
        if (displayTestExecution() && isDisplaTestExec) {
          return childData;
        } else return [];
      }
      case REPOSITORY_VALUES.TEST_DATA: {
        return childData;
      }
      case TEMPLATE_VALUE.DEFECTS: {
        if (displayDefects()) {
          return childData;
        } else return {};
      }
      case TEMPLATE_VALUE.TEMPLATES: {
        if (displayTemplates()) {
          let alterData = childData?.filter((data) => {
            if (displayTemplatesSection(data?.value)) {
              return data;
            }
          });
          return alterData;
        } else return [];
      }
    }
  };

  const handleProjectChange = (value) => {
    setIsProjectSelected(value[0]?.name);
    setSelectedProjectName(value[0]?.value);
    setSelectedProjectType(value[0]?.type);
    setSelectedProjectData(value);
  };

  const handleExport = async () => {
    if (handleDisable()) {
      setIsLoading(true);
      const payloadData = {
        repository: getSelectedValues(repositoryData, repository),
        testData: getSelectedValues(testData, testData),
        executions: getSelectedValues(testExecutionData, testExecution),
        defects: getSelectedValues(defects, defects),
        templates: getSelectedValues(templatesData, templates),
      };
      try {
        const response = await exportProject(isProjectSelected, payloadData);
        saveFileFromBlob(response.data, `${isProjectSelected}-${selectedProjectName}.zip`);
        MyAlert.success(`${selectedProjectName} ${IMPORT_EXPORT_CONSTANTS.PROJECT_EXPORTED_SUCCESSFULLY}`);
        clearStateAndLoading();
      } catch (err) {
        console.error(`${IMPORT_EXPORT_CONSTANTS.EXPORT_API_FAILURE} :`, err);
      }
    }
  };

  const handleImport = async () => {
    if (handleDisable()) {
      setIsLoading(true);
      const data = {
        repository: getSelectedValues(repositoryData, repository),
        testData: getSelectedValues(testData, testData),
        executions: getSelectedValues(testExecutionData, testExecution),
        defects: getSelectedValues(defects, defects),
        templates: getSelectedValues(templatesData, templates),
      };
      const formData = new FormData();
      formData.append('data', JSON.stringify(data));
      formData.append('file', isZipFileSelected);

      //restricting simultaneous import for the same project of same credentials
      let projRes = await getSingleProjectRequest(isProjectSelected);
      let projData = projRes.data.responseObject;

      const trueFormData = new FormData();
      const falseFormData = new FormData();

      const truePayload = {
        name: projData?.name,
        type: projData?.type,
        platform: projData?.platform,
        appType: projData?.appType,
        importProgress: true,
      };

      const falsePayload = {
        name: projData?.name,
        type: projData?.type,
        platform: projData?.platform,
        appType: projData?.appType,
        importProgress: false,
      };

      trueFormData.append('data', JSON.stringify(truePayload));
      falseFormData.append('data', JSON.stringify(falsePayload));

      if (projData?.importProgress) {
        MyAlert.info(IMPORT_EXPORT_CONSTANTS.IMPORT_MULTI_THREAD_MESSAGE);
        clearStateAndLoading();
      } else {
        try {
          updateProjectRequest(trueFormData, isProjectSelected);
          const response = await importProject(isProjectSelected, formData);
          if (IMPORT_EXPORT_CONSTANTS.IMPORT_WARNING_MESSAGE.includes(response?.data?.message)) {
            MyAlert.warning(`${response.data.message}`);
          } else if (response?.data.status === 'FAILURE') {
            setOpen(true);
            setMessage(response?.data.message);
          } else {
            MyAlert.success(`${selectedProjectFileName} Imported Successfully`);
          }
          updateProjectRequest(falseFormData, isProjectSelected);
          clearStateAndLoading();
        } catch (err) {
          console.error(`${IMPORT_EXPORT_CONSTANTS.IMPORT_API_FAILURE} :`, err);
          MyAlert.info(`${IMPORT_EXPORT_CONSTANTS.UPLOAD_FILE_FAILED}`);
          updateProjectRequest(falseFormData, isProjectSelected);
          clearStateAndLoading();
        }
      }
    }
  };

  const handleFileChange = (e) => {
    if (isEmptyValue(e.target.files)) {
      MyAlert.warning(`Unsupported file`);
      return;
    }

    if (validateFileExtension(e?.target?.files?.[0], IMPORT_EXPORT_FILE_ALLOWED) || validateZipFileExtension(e)) {
      setIsZipFileSelected(e?.target?.files[0]);
      setSelectedProjectFileName(e?.target?.files?.[0]?.name);
      setShowBtn(true);
    } else {
      MyAlert.warning(`${e?.target?.files?.[0]?.name} is unsupported`);
    }
  };

  const onInputClick = (event) => {
    event.target.value = '';
  };

  const displayProjectElements = (value) => {
    return value === REPOSITORY_VALUES.PROJECT_ELEMENTS && (isWebServiceProject || hasOnlyWebService()) ? false : true;
  };
  const Repo_Children = (
    <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1, gap: '40px' }}>
      {repositoryData?.map((element, index) => {
        let { label, checked, id, disabled, value } = element;
        return (
          <Fragment key={`${label}-${index}`}>
            {displayProjectElements(value) && (
              <CustomFormControl
                label={label}
                id={`${label}-${index}`}
                key={`${label}-${index}`}
                control={
                  <Checkbox
                    id={id}
                    value={value}
                    style={{ padding: 0, color: colors.blue_dark }}
                    className={disabled ? classes.disabled : 'selected_tab_style '}
                    disabled={disabled}
                    checked={checked}
                    onChange={handleChildRepositoryChange}
                  />
                }
              />
            )}
          </Fragment>
        );
      })}
    </Box>
  );

  const Test_Children = (
    <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1, gap: '40px' }}>
      {testExecutionData?.map((element, index) => {
        let { label, checked, id, disabled, value } = element;
        return (
          <Fragment key={`${label}-${index}`}>
            {
              <CustomFormControl
                label={
                  value === TEST_EXECUTION_VALUE.SCRIPTS
                    ? isOnlyManual
                      ? TEST_EXECUTION_VALUE.TEST_CASES
                      : label
                    : label
                }
                id={`${label}-${index}`}
                key={`${label}-${index}`}
                control={
                  <Checkbox
                    id={id}
                    value={value}
                    disabled={disabled}
                    style={{ padding: 0, color: colors.blue_dark }}
                    className={disabled ? classes.disabled : 'selected_tab_style '}
                    checked={checked}
                    onChange={handleChildTestExeChange}
                  />
                }
              />
            }
          </Fragment>
        );
      })}
    </Box>
  );

  const Template_Children = (
    <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1, gap: '40px' }}>
      {templatesData?.map((element, index) => {
        let { label, checked, id, disabled, value } = element;
        return (
          <Fragment key={`${label}-${index}`}>
            {displayTemplatesSection(value) && (
              <CustomFormControl
                label={label}
                id={`${label}-${index}`}
                key={`${label}-${index}`}
                control={
                  <Checkbox
                    id={id}
                    value={value}
                    disabled={disabled}
                    style={{ padding: 0, color: colors.blue_dark }}
                    className={disabled ? classes.disabled : 'selected_tab_style '}
                    checked={checked}
                    onChange={handleChildTemplatesChange}
                  />
                }
              />
            )}
          </Fragment>
        );
      })}
    </Box>
  );

  const exportImportCompleteProject = async () => {
    setIsLoading(true);
    let repoData = getPayloadData(repositoryData, repository);
    let testExecData = getPayloadData(testExecutionData, testExecution);
    let templateData = getPayloadData(templatesData, templates);
    let defectData = getPayloadData(defects, defects);
    let testDataSet = getPayloadData(testData, testData);

    const payloadData = {
      repository: repoData,
      testData: testDataSet,
      executions: testExecData,
      defects: defectData,
      templates: templateData,
    };
    const data = new FormData();
    data.append('data', JSON.stringify(payloadData));
    data.append('file', isZipFileSelected);

    //restricting simultaneous import for the same project of same credentials
    let projResponse = await getSingleProjectRequest(isProjectSelected);
    let projData = projResponse.data.responseObject;

    const payloadDataTrue = new FormData();
    const payloadDataFalse = new FormData();

    const payloadTrue = {
      name: projData?.name,
      type: projData?.type,
      platform: projData?.platform,
      appType: projData?.appType,
      importProgress: true,
    };

    const payloadFalse = {
      name: projData?.name,
      type: projData?.type,
      platform: projData?.platform,
      appType: projData?.appType,
      importProgress: false,
    };

    payloadDataTrue.append('data', JSON.stringify(payloadTrue));
    payloadDataFalse.append('data', JSON.stringify(payloadFalse));

    if (isExportPage) {
      try {
        const response = await exportProject(isProjectSelected, payloadData);
        saveFileFromBlob(response.data, `${isProjectSelected}-${selectedProjectName}.zip`);
        MyAlert.success(`${selectedProjectName} ${IMPORT_EXPORT_CONSTANTS.PROJECT_EXPORTED_SUCCESSFULLY}`);
        clearStateAndLoading();
      } catch (err) {
        console.error(`${IMPORT_EXPORT_CONSTANTS.EXPORT_API_FAILURE} :`, err);
      }
    } else {
      if (projData?.importProgress) {
        MyAlert.info(IMPORT_EXPORT_CONSTANTS.IMPORT_MULTI_THREAD_MESSAGE);
        clearStateAndLoading();
      } else {
        try {
          updateProjectRequest(payloadDataTrue, isProjectSelected);
          const response = await importProject(isProjectSelected, data);
          if (IMPORT_EXPORT_CONSTANTS.IMPORT_WARNING_MESSAGE.includes(response?.data?.message)) {
            MyAlert.warning(`${response.data.message}`);
          } else if (response?.data.status === 'FAILURE') {
            setOpen(true);
            setMessage(response?.data.message);
          } else {
            MyAlert.success(`${selectedProjectFileName} Imported Successfully`);
          }
          updateProjectRequest(payloadDataFalse, isProjectSelected);
          clearStateAndLoading();
        } catch (err) {
          console.error(`${IMPORT_EXPORT_CONSTANTS.IMPORT_API_FAILURE} :`, err);
          MyAlert.info(`${IMPORT_EXPORT_CONSTANTS.UPLOAD_FILE_FAILED}`);
          updateProjectRequest(payloadDataFalse, isProjectSelected);
          clearStateAndLoading();
        }
      }
    }
  };
  const getPayloadData = (data, obj) => {
    if (data?.length) {
      let tempData = [];
      getActualData(data, obj)?.forEach((tempVal) => {
        tempData.push(tempVal?.value);
      });
      if (obj?.value === REPOSITORY_VALUES.REPOSITORY && !isEmptyValue(getActualData(data, obj))) {
        tempData.push(REPOSITORY_VALUES.PROGRAM_ELEMENTS);
      }
      return tempData;
    } else {
      return getActualData(data, obj)?.value ? [getActualData(data, obj)?.value] : [];
    }
  };
  const handleDisable = () => {
    let repositoryState = repositoryData.some((value) => value.checked);
    let testExecutionState = testExecutionData.some((value) => value.checked);
    let templateState = templatesData.some((value) => value.checked);
    let defectState = defects.checked;
    let testDataState = testData.checked;
    return repositoryState || testExecutionState || templateState || testDataState || defectState;
  };
  let handleDelete = (e) => {
    setOpen(false);
  };
  const resetCheckBoxStates = () => {
    clearRepositoryData();
    clearTestExecutionData();
    clearTemplatesData();
    clearParentCheckboxes();
  };
  const handleSeletedChange = (e) => {
    setIsCompleteRadioButtonSelected(!e.target.checked);
    setselectedRadioState(e.target.checked);
    setShowResources(true);
    resetCheckBoxStates();
  };
  const handleCompleteChange = (e) => {
    setIsCompleteRadioButtonSelected(e.target.checked);
    setselectedRadioState(!e.target.checked);
    setShowResources(false);
    resetCheckBoxStates();
  };
  const handleDisableImportExportButton = () => {
    if (isCompleteRadioButtonSelected) {
      if (isExportPage) {
        return !isProjectSelected || isLoading;
      } else {
        return !isProjectSelected || !isZipFileSelected || isLoading;
      }
    } else {
      if (isExportPage) {
        return !isProjectSelected || isLoading || !handleDisable();
      } else {
        return !isProjectSelected || !isZipFileSelected || isLoading || !handleDisable();
      }
    }
  };
  return (
    <>
      <div className="alert-position-style">
        <AlertContatiner></AlertContatiner>
        <AlertPopUps
          open={open}
          alertType={ALERT}
          showHowToProceed={false}
          showCancelBtn={false}
          showSaveBtn={true}
          saveBtnText="Ok"
          onSaveBtnClick={handleDelete}
          content={[<div className="mb-2 capitalize">Hi {loggedInUser?.name},</div>, <div>{message}</div>]}
        />
      </div>
      <div className={'content_area_style overflow-hidden'}>
        <div className="border-b-2 py-2.5">
          <label className="project_label">
            {isExportPage ? IMPORT_EXPORT_CONSTANTS.EXPORT : IMPORT_EXPORT_CONSTANTS.IMPORT}
          </label>
        </div>
        <ImportExportSelectDropdown
          options={projects}
          onChange={handleProjectChange}
          value={selectedProjectData || []}
          uploadFile={!isExportPage}
          disabled={!isProjectSelected}
          fileValue={isZipFileSelected?.name}
          showBtn={showBtn}
          setShowBtn={setShowBtn}
          fileRemove={() => {
            setIsZipFileSelected(null);
          }}
          onFileChange={(e) => {
            handleFileChange(e);
          }}
          onFileClick={(e) => {
            onInputClick(e);
          }}
        />
        <div className="flex flex-col ml-6">
          <FormControl>
            <RadioGroup row name="radio-buttons-group">
              <FormControlLabel
                value="selected"
                control={<Radio color="primary" onChange={handleSeletedChange} checked={selectedRadioState} />}
                label={
                  <span className="fontPoppinsRegularMd">
                    {isExportPage
                      ? IMPORT_EXPORT_CONSTANTS.EXPORT_SELECTED_RESOURCES
                      : IMPORT_EXPORT_CONSTANTS.IMPORT_SELECTED_RESOURCES}
                  </span>
                }
              />
              <FormControlLabel
                value="complete"
                control={
                  <Radio color="primary" onChange={handleCompleteChange} checked={isCompleteRadioButtonSelected} />
                }
                label={
                  <span className="fontPoppinsRegularMd">
                    {isExportPage
                      ? IMPORT_EXPORT_CONSTANTS.EXPORT_COMPLETE_PROJECT
                      : IMPORT_EXPORT_CONSTANTS.IMPORT_COMPLETE_PROJECT}
                  </span>
                }
              />
            </RadioGroup>
          </FormControl>
        </div>
        {showResources && (
          <div className="flex flex-col ml-5 mt-3">
            {displayRepository() && isDisplayRepository && (
              <div className="mb-4 ml-3">
                <FormControlLabel
                  className={'selected_tab_style'}
                  label={<span className="fontPoppinsSemiboldMd">{repository.label}</span>}
                  control={
                    <Checkbox
                      ref={checkboxref}
                      indeterminate={checkInderminate(repositoryData, true)}
                      id={repository.id}
                      value={repository?.value}
                      checked={repository.checked}
                      onChange={handleRootRepositoryChange}
                      disabled={repository.disabled}
                      className={repository.disabled ? classes.disabled : 'selected_tab_style '}
                      style={{ padding: 0, color: colors.blue_dark }}
                    />
                  }
                />
                {Repo_Children}
              </div>
            )}
            <div className="mb-4 ml-3">
              <FormControlLabel
                className={'selected_tab_style'}
                label={<span className="fontPoppinsSemiboldMd">{testData.label}</span>}
                control={
                  <Checkbox
                    id={testData?.id}
                    value={testData?.value}
                    checked={testData?.checked}
                    onChange={handleTestDataChange}
                    disabled={testData?.disabled}
                    className={testData?.disabled ? classes.disabled : 'selected_tab_style '}
                    style={{ padding: 0, color: colors.blue_dark }}
                  />
                }
              />
            </div>
            {displayTestExecution() && isDisplaTestExec && (
              <div className="mb-4 ml-3">
                <FormControlLabel
                  className="selected_tab_style"
                  label={<span className="fontPoppinsSemiboldMd">{testExecution.label}</span>}
                  control={
                    <Checkbox
                      indeterminate={checkInderminate(testExecutionData, true)}
                      id={testExecution?.id}
                      value={testExecution?.value}
                      checked={testExecution?.checked}
                      onChange={handleRootTestExeChange}
                      className="selected_tab_style "
                      style={{ padding: 0, color: colors.blue_dark }}
                    />
                  }
                />
                {Test_Children}
              </div>
            )}
            {displayDefects() && (
              <div className="mb-4 ml-3">
                <FormControlLabel
                  className="selected_tab_style"
                  label={<span className="fontPoppinsSemiboldMd">{defects.label}</span>}
                  control={
                    <Checkbox
                      id={defects.id}
                      value={defects?.value}
                      checked={defects.checked}
                      onChange={handleDefectsChange}
                      className="selected_tab_style "
                      style={{ padding: 0, color: colors.blue_dark }}
                    />
                  }
                />
              </div>
            )}
            {displayTemplates() && (
              <div className="mb-4 ml-3">
                <FormControlLabel
                  className="selected_tab_style "
                  label={<span className="fontPoppinsSemiboldMd">{templates.label}</span>}
                  control={
                    <Checkbox
                      indeterminate={checkInderminate(templatesData, true)}
                      id={templates.id}
                      value={templates?.value}
                      checked={templates.checked}
                      onChange={handleRootTemplatesChange}
                      disabled={templates.disabled}
                      style={{ padding: 0, color: colors.blue_dark }}
                      className={templates.disabled ? classes.disabled : 'selected_tab_style '}
                    />
                  }
                />
                {Template_Children}
              </div>
            )}
          </div>
        )}
        <div className="flex justify-left w-full">
          {!isExportPage ? (
            <button
              className="primary-btn mr-5 ml-6 h-10 text-lg flex justify-center items-center gap-1"
              onClick={isCompleteRadioButtonSelected ? exportImportCompleteProject : handleImport}
              disabled={handleDisableImportExportButton()}
            >
              <ImportIcon className="mr-1 mb-1" />
              {isLoading ? IMPORT_EXPORT_CONSTANTS.IMPORTING : IMPORT_EXPORT_CONSTANTS.IMPORT}
            </button>
          ) : (
            <button
              className="primary-btn mr-5 ml-6 h-10 text-lg flex justify-center items-center gap-1"
              onClick={isCompleteRadioButtonSelected ? exportImportCompleteProject : handleExport}
              disabled={handleDisableImportExportButton()}
            >
              <ExportIcon className="mr-1 mb-1" />
              {isLoading ? IMPORT_EXPORT_CONSTANTS.EXPORTING : IMPORT_EXPORT_CONSTANTS.EXPORT}
            </button>
          )}
        </div>
      </div>
    </>
  );
}
export default ImportExportProject;

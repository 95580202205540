import React, { useState, useEffect, useRef, forwardRef, useContext } from 'react';
import { usePagination, useTable, useGlobalFilter } from 'react-table';
import { ReactComponent as ExpandArrow } from '@assets/analytics/ExpandArrow.svg';
import { ReactComponent as ShrinkIcon } from '@assets/analytics/ShrinkIcon.svg';
import ScriptResultAccordion from './scripts-result-accordion';

import '../Analytics.scss';
import { Box, Modal, Fade } from '@mui/material';
import { getScrollPosition } from './util';
import { isEmptyValue } from '@src/util/common_utils';
import SearchBar from './search-bar';
import { setIDB } from '@src/util/localForage_idb_controller';
import { getExecutionTreeExpandAllResultReq } from '@src/api/api_services';

const AnalyticTable = forwardRef((props, ref) => {
  let {
    columns,
    data,
    scriptResultData,
    openScriptResultModal,
    closeResultModal,
    selectedScriptName,
    selectedScript,
    setScrollPos,
    headertextTransFrom = 'Uppercase',
  } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const bigTableRef = useRef();

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, state, setGlobalFilter } = useTable(
    {
      columns,
      data: data || [],
    },
    useGlobalFilter,
    usePagination
  );
  const { globalFilter } = state;

  // TODO :: handle Search filter :: START
  let [openSearch, setOpenSearch] = useState(false);
  let handleSearchClose = () => {
    setOpenSearch(false);
    setGlobalFilter('');
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  // TODO :: handle Search filter :: END

  /**
   * NOTE :: To Download screenShot and Video need to store storageDetails
   *  of execution result and Tree data for function used in step-result-inner-modal.js
   * downloadFile
   *
   */
  let setExeResultAndTreeDataInIDB = async () => {
    await getExecutionTreeExpandAllResultReq(selectedScript?.executionId)
      .then((response) => {
        if (response?.data?.responseObject) {
          setIDB('execResult', response.data);
          const _machineListarray = response?.data?.responseObject?.executionResponses.map((value) => ({
            storageDetails: value.storageDetails,
          }));
          setIDB('tree-data', _machineListarray);
        }
      })
      .catch((error) => {
        console.error('EXECUTION_RESULT_TREE : ', error);
      });
  };

  useEffect(() => {
    if (selectedScript?.executionId) {
      setExeResultAndTreeDataInIDB();
    }
  }, [selectedScript?.executionId]);

  return (
    <>
      <div className="col-span-12 analytics-testscript-table">
        <div
          className="col-span-12 analytics-table-header fontPoppinsSemiboldSm "
          style={{
            color: `${props.color}`,
            textTransform: `${headertextTransFrom}`,
          }}
        >
          {props.header}
          <span className="float-right ">
            <span className="flex justify-center gap-1 ">
              <SearchBar
                open={openSearch}
                searchValue={globalFilter || ''}
                handleSearch={(e) => {
                  setGlobalFilter(e.target.value);
                }}
                onSearchClose={handleSearchClose}
                handleSearchOpen={handleSearchOpen}
                disable={isEmptyValue(data)}
              />
              <div className="expandIconContainer" onClick={handleOpen} size="small">
                <ExpandArrow className="expIcon" />
              </div>
            </span>
          </span>
        </div>
        <div className="col-span-12 analytics-table-container">
          <table {...getTableProps()} className="col-span-12 analyticstable analytics-table ">
            <thead>
              {headerGroups?.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="fontPoppinsSemiboldSm analyticsTableHeaderText h-8 ml-4 uppercase"
                >
                  {headerGroup.headers?.map((column) => (
                    <th width={column.width} {...column.getHeaderProps()} className="text-left">
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody
              ref={ref}
              onScroll={() => getScrollPosition(ref, setScrollPos)}
              {...getTableBodyProps()}
              className="analytics-table-body"
            >
              {rows?.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className=" h-8">
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="pl-1 text-left  analytics-table-text fontPoppinsMediumSm rs-text-black"
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {(isEmptyValue(data) || isEmptyValue(rows)) && (
            <div className="analyticstable_message fontPoppinsMediumSm">{props?.message}</div>
          )}
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className="expand-failer-modalTable">
            <div className="col-span-12 ">
              <div
                className="col-span-12 analytics-table-header fontPoppinsSemiboldSm "
                style={{
                  color: `${props.color}`,
                  textTransform: `${headertextTransFrom}`,
                }}
              >
                {props.header}
                <span className="float-right ">
                  <span className="flex justify-center  gap-1 ">
                    <SearchBar
                      open={openSearch}
                      searchValue={globalFilter || ''}
                      handleSearch={(e) => {
                        setGlobalFilter(e.target.value);
                      }}
                      onSearchClose={handleSearchClose}
                      handleSearchOpen={handleSearchOpen}
                      disable={isEmptyValue(data)}
                    />
                    <span className="expandIconContainer mr-2" onClick={handleClose}>
                      <ShrinkIcon />
                    </span>
                  </span>
                </span>
              </div>
              <div className="col-span-12 px-2">
                <table {...getTableProps()} className="col-span-12  analytics-table analyticstableExpanded ">
                  <thead>
                    {headerGroups?.map((headerGroup) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className="fontPoppinsSemiboldSm analyticsTableHeaderText h-8 ml-4"
                      >
                        {headerGroup.headers.map((column) => (
                          <th width={column.width} {...column.getHeaderProps()} className="text-left uppercase">
                            {column.render('Header')}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody
                    ref={bigTableRef}
                    onScroll={() => getScrollPosition(bigTableRef, setScrollPos)}
                    {...getTableBodyProps()}
                    className="analytics-table-body"
                  >
                    {rows?.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} className=" h-8 border-b">
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()} className=" text-left fontPoppinsMediumSm">
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {(isEmptyValue(data) || isEmptyValue(rows)) && (
                  <div className="analyticstable_message_expand fontPoppinsMediumSm">{props?.message}</div>
                )}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openScriptResultModal}
        onClose={() => closeResultModal()}
        closeAfterTransition
      >
        <Box className="expand-failer-modalTable">
          <div className="flex justify-between p-3">
            <div className="fontPoppinsSemiboldMd rs-blue uppercase modalHead-trimmer" title={selectedScriptName}>
              {selectedScriptName}
            </div>
            <div className=" expandIconContainer cursor-pointer" onClick={() => closeResultModal()}>
              <ShrinkIcon />
            </div>
          </div>
          {scriptResultData?.length !== 0 && openScriptResultModal && (
            <ScriptResultAccordion content={scriptResultData} selectedScript={selectedScript} />
          )}
        </Box>
      </Modal>
    </>
  );
});
export default AnalyticTable;

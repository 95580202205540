import React, { useRef } from 'react';
import TableTree from '../../../common/table_tree/table_tree';
import {
  getAllModuleTreeReq,
  getAllEmailGroupListReq,
  getSingleScheduleExecutionDataReq,
  getSingleExecutionDataReq,
} from '@api/api_services';
import { useEffect, useContext } from 'react';
import MoreInfoButton from '../../../common/more_info_button';
import { FiMaximize2 } from 'react-icons/fi';
import { useTable, usePagination, useFilters, useGlobalFilter, useSortBy } from 'react-table';
import matchSorter from 'match-sorter';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { CommonLoader } from '@src/pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { Tooltip } from '@material-ui/core';
import { TEST_ENV_TABLE_WIDTH_SET } from '@common/ui-constants';
import {
  getTruncatedText,
  addMachineInstancesIfNotExist,
  calculatedScriptNumber,
  getReportTypes,
} from '@src/util/common_utils';
import { renderBrowserCell } from '@src/pages/execution/execution.js';
import { getModuleAndScriptData } from '@src/pages/test-development/shared/create-execution-tree';
import { getOsLabel } from '@src/pages/execution/suites/create-edit-child-pages/run-settings-child-pages/machine-child-pages/run-settings-machines-common/common-methods';
import MachineEnvironmentLayout from '@src/common/MachineEnvironmentLayout';
import MachineEnvironmentTableLayout from '@src/common/MachineEnvironmentTableLayout';
import { EMAIL_NOTIFICATION_CONSTANTS, SLACK_CONSTANTS } from '@src/pages/configuration/plugin/plugin_constants';

let suiteObj;
let treeArrayForMultipleTree = [];
let totalScriptCount = 0;
const ExecutionIdDetailsPage = (props) => {
  const selectedPage = window.location.pathname.split('/')[4];
  const selExeId = window.location.pathname.split('/')[5];

  const { resetProgress, startOrResumeProgress } = useContext(ProgressContainerContext);
  let [isLoading, setIsLoading] = React.useState(false);
  let [treeData, setTreeData] = React.useState([]);
  let [rootData, setRootData] = React.useState([]);
  let [isExpandAll, setIsExpandAll] = React.useState(false);
  let [treeValue, setTreeValue] = React.useState('');
  let [reloadTree, setReloadTree] = React.useState(false);
  let [emailTableData, setEmailData] = React.useState([]);
  const [slackTableData, setSlackTableData] = React.useState([]);
  const totalModCount = useRef(0);

  const plusIconArray = [
    {
      name: 'Add Module',
      value: 'folder',
    },
    {
      name: 'Add Script',
      value: 'file',
    },
  ];

  const LABELS = {
    EXPAND_ALL: 'Expand All',
    COLLAPSE_ALL: 'Collapse All',
    SHOW_ALL_ELEMENTS: 'Show all scripts',
    HIDE_ALL_ELEMENTS: 'Hide all scripts',
    SHOW_PRE_POST_CONDITION: 'Show Pre/Post Conditions',
    HIDE_PRE_POST_CONDITION: 'Hide Pre/Post Conditions',
  };

  async function getModuleTree() {
    resetProgress();
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 80,
    });
    setIsLoading(true);
    treeArrayForMultipleTree = [];
    let getdata;
    try {
      getdata = await getAllModuleTreeReq();
      let rootDetails = getdata.data.responseObject.moduleTree[0];
      updateTreeRootData(rootDetails);
      setIsExpandAll(rootDetails.expanded);
      if (suiteObj?.selectedModulesAndScripts) {
        if (suiteObj?.selectedModulesAndScripts?.length) {
          suiteObj?.selectedModulesAndScripts?.[0]?.children.forEach((obj) => {
            obj.checkbox = false;
          });
        }
      }
      let calculatedScriptCount;
      let calculatedModuleCount;
      if (suiteObj?.machines?.multiple === 'true' && suiteObj?.machines?.executionType === 'DISTRIBUTE') {
        if (['MANUAL', 'AUTOMATIC'].includes(suiteObj?.machines?.distributionMechanism)) {
          if (suiteObj?.machines?.selectedMachines?.length) {
            let selectedModulesAndScripts = suiteObj?.selectedModulesAndScripts?.[0]?.children;
            suiteObj.machines.selectedMachines.forEach((machine) => {
              machine?.machineInstances?.forEach((disObj) => {
                let tempSelModArray = disObj?.distribution?.selectedModules;
                if (tempSelModArray?.length) {
                  let modules = tempSelModArray.map((name) => name).join(', ');
                  let tempSelectedModulesAndScripts = JSON.parse(JSON.stringify([...selectedModulesAndScripts]));
                  let filteredModules = JSON.parse(
                    JSON.stringify(getModuleAndScriptData(tempSelectedModulesAndScripts, modules))
                  );
                  totalModCount.current = 0;
                  if (filteredModules?.length) {
                    calculatedScriptCount = calculateScriptCount(filteredModules);
                    calculatedModuleCount = calculateModuleCount(filteredModules);
                    totalScriptCount = 0;
                    let renderTreeObj = {
                      tableTreeId:
                        disObj?.distribution?.distributionId?.replaceAll(' ', '-') ||
                        disObj?.distribution?.clientSystemId?.replaceAll(' ', '-') ||
                        '',
                      treeArray: filteredModules,
                      rootData: {
                        key: filteredModules[0]?.parent?.key || filteredModules[0]?.parentId,
                        title: filteredModules[0]?.parent?.title || filteredModules[0]?.parentName,
                        calculatedScriptCount: calculatedScriptCount,
                        calculatedModuleCount: calculatedModuleCount,
                      },
                      deviceInfo: disObj,
                    };
                    treeArrayForMultipleTree.push(renderTreeObj);
                  }
                }
              });
            });
          }
        }
      }

      if (treeArrayForMultipleTree?.length) {
        let colDefsChild = [];
        treeArrayForMultipleTree.forEach((obj) => {
          if (selectedProject.type.toLowerCase() === 'web' || selectedProject.type.toLowerCase() === 'mobile') {
            colDefsChild = [
              {
                field: 'title',
                title: 'MODULE',
                class: 'title',
                isTitle: true,
                width: `calc(100% - 796px)`,
                folderTitleWidth: `calc(100% - 770px)`,
                paddingRight: '16px',
                render: (nodeObj) => {
                  createFilterData(treeData);
                  return (
                    <>
                      {nodeObj.isRootNode && (
                        <>
                          <span class="count-badge folder-count ml-2">M{obj?.rootData?.calculatedModuleCount} </span>
                          <span class="count-badge file-count">
                            {' '}
                            S{' '}
                            {obj?.rootData?.calculatedScriptCount
                              ? obj.rootData.calculatedScriptCount
                              : obj?.treeArray[0].calculatedScriptCount}{' '}
                          </span>
                        </>
                      )}

                      {nodeObj.data.scriptCount > 0 && (
                        <span class="count-badge file-count"> S {nodeObj.data.calculatedScriptCount} </span>
                      )}
                      {nodeObj.folder && (
                        <button class="add-btn">
                          <MoreInfoButton type="add" nodeObj={nodeObj} plusIconArray={plusIconArray} />
                        </button>
                      )}
                    </>
                  );
                },
              },
              {
                field: 'scriptType',
                title: 'TYPE',
                class: 'Type truncate',
                width: '74px',
                render: (nodeObj) => {
                  return (
                    <Tooltip title={nodeObj.data.scriptType}>
                      <span className="text-xs table-non-link-color-common fontPoppinsRegularMd">
                        {nodeObj.data.scriptType}
                      </span>
                    </Tooltip>
                  );
                },
              },
              {
                field: 'Execution Environment',
                title: 'EXECUTION ENVIRONMENT',
                class: 'executionEnvironment truncate',
                width: '180px',
                render: (nodeObj) => {
                  const executionEnv = obj?.deviceInfo?.executionEnv;
                  if (nodeObj.isRootNode || nodeObj.data.scriptCount > 0) return null;
                  return (
                    <Tooltip title={executionEnv}>
                      <span className="table-non-link-color-common fontPoppinsRegularMd cursor-pointer">
                        {executionEnv}
                      </span>
                    </Tooltip>
                  );
                },
              },
              {
                field: 'Os',
                title: 'OS & OS Ver.',
                class: 'os',
                width: '90px',
                render: (nodeObj) => {
                  const osName = `${obj?.deviceInfo?.machineInfo?.osName}-${obj?.deviceInfo?.machineInfo?.osVersion}`;
                  if (nodeObj.isRootNode || nodeObj.data.scriptCount > 0) return null;
                  return <span>{osName ? getOsLabel(osName) : ''}</span>;
                },
              },

              {
                field: selectedProject.type.toLowerCase() === 'web' ? 'Browser' : 'Device',
                title: selectedProject.type.toLowerCase() === 'web' ? 'BROWSER' : 'DEVICE',
                class: selectedProject.type.toLowerCase() === 'web' ? 'browser truncate' : 'device truncate',
                width: selectedProject.type.toLowerCase() === 'web' ? '68px' : '80px',
                render: (nodeObj) => {
                  const browserName = obj?.deviceInfo?.browserName;
                  const deviceArrayCopy = obj?.deviceInfo?.deviceInfo;
                  if (nodeObj.isRootNode || nodeObj.data.scriptCount > 0) return null;
                  if (selectedProject?.type?.toLowerCase() !== 'web') {
                    return <span className="">{deviceArrayCopy?.length ? deviceArrayCopy[0].name : ''}</span>;
                  } else {
                    return browserName ? (
                      <Tooltip title={browserName} placement="top">
                        {renderBrowserCell(browserName)}
                      </Tooltip>
                    ) : (
                      ''
                    );
                  }
                },
              },
              {
                field: 'numberOfRuns',
                title: 'No. of Runs',
                width: '80px',
                render: (nodeObj) => {
                  if (nodeObj?.isRootNode || nodeObj?.data?.scriptCount > 0) return null;
                  return <span className="text-xs table-non-link-color-common">{obj?.deviceInfo?.numberOfRuns}</span>;
                },
              },
            ];
          } else if (
            ['web & mobile', 'salesforce', 'ms dynamics', 'web service']?.includes(selectedProject.type.toLowerCase())
          ) {
            colDefsChild = [
              {
                field: 'title',
                title: 'MODULE',
                class: 'title',
                isTitle: true,
                width: `calc(100% - 650px)`,
                folderTitleWidth: `calc(100% - 770px)`,
                render: (nodeObj) => {
                  createFilterData(treeData);
                  return (
                    <>
                      {nodeObj.isRootNode && (
                        <>
                          <span class="count-badge folder-count ml-2">M {obj?.rootData?.calculatedModuleCount} </span>
                          <span class="count-badge file-count">
                            {' '}
                            S{' '}
                            {obj?.rootData?.calculatedScriptCount
                              ? obj.rootData.calculatedScriptCount
                              : obj?.treeArray[0].calculatedScriptCount}{' '}
                          </span>
                        </>
                      )}

                      {nodeObj.data.scriptCount > 0 && (
                        <span class="count-badge file-count"> S {nodeObj.data.calculatedScriptCount} </span>
                      )}
                      {nodeObj.folder && (
                        <button class="add-btn">
                          <MoreInfoButton type="add" nodeObj={nodeObj} plusIconArray={plusIconArray} />
                        </button>
                      )}
                    </>
                  );
                },
              },
              {
                field: 'scriptType',
                title: 'TYPE',
                class: 'Type truncate',
                width: '74px',
                render: (nodeObj) => {
                  return (
                    <Tooltip title={nodeObj.data.scriptType}>
                      <span className="text-xs table-non-link-color-common fontPoppinsRegularMd">
                        {nodeObj.data.scriptType}
                      </span>
                    </Tooltip>
                  );
                },
              },
              {
                field: 'Execution Environment',
                title: 'EXECUTION ENVIRONMENT',
                class: 'executionEnvironment truncate',
                width: '185px',
                render: (nodeObj) => {
                  const executionEnv = obj?.deviceInfo?.executionEnv;
                  if (nodeObj.isRootNode || nodeObj.data.scriptCount > 0) return null;
                  return (
                    <Tooltip title={executionEnv}>
                      <span className="table-non-link-color-common fontPoppinsRegularMd cursor-pointer">
                        {executionEnv}
                      </span>
                    </Tooltip>
                  );
                },
              },
              {
                field: 'Os',
                title: 'OS & OS Ver.',
                class: 'os',
                width: '90px',
                render: (nodeObj) => {
                  const osName = `${obj?.deviceInfo?.machineInfo?.osName}-${obj?.deviceInfo?.machineInfo?.osVersion}`;
                  if (nodeObj.isRootNode || nodeObj.data.scriptCount > 0) return null;
                  return <span className="version-text-color">{osName ? getOsLabel(osName) : ''}</span>;
                },
              },

              {
                field: 'Browser',
                title: 'BROWSER',
                class: 'browser',
                width: '68px',
                render: (nodeObj) => {
                  const browserName = obj?.deviceInfo?.browserName;
                  if (nodeObj.isRootNode || nodeObj.data.scriptCount > 0) return null;
                  return browserName ? (
                    <Tooltip title={browserName} placement="top">
                      {renderBrowserCell(browserName)}
                    </Tooltip>
                  ) : (
                    ''
                  );
                },
              },
              {
                field: 'Device',
                title: 'DEVICE',
                class: 'device truncate',
                width: '80px',
                render: (nodeObj) => {
                  const deviceArrayCopy = obj?.deviceInfo?.deviceInfo || [];
                  if (nodeObj.isRootNode || nodeObj.data.scriptCount > 0) return null;
                  return <span className="">{deviceArrayCopy?.length ? deviceArrayCopy[0].name : ''}</span>;
                },
              },
              {
                field: 'numberOfRuns',
                title: 'No. of Runs',
                width: '95px',
                render: (nodeObj) => {
                  if (nodeObj?.isRootNode || nodeObj?.data?.scriptCount > 0) return null;
                  return <span className="text-xs table-non-link-color-common">{obj?.deviceInfo?.numberOfRuns}</span>;
                },
              },
            ];
          }
          if (isLoading) {
            obj.treeName = <CommonLoader />;
          } else if (obj?.treeArray && obj?.treeArray.length > 0) {
            obj.treeName = (
              <TableTree
                data={obj?.treeArray}
                rootData={obj?.rootData}
                colDefs={colDefsChild}
                filter={treeValue}
                expandAll={isExpandAll}
                hideElements={false}
                hideConditions={true}
                showCheckbox={false}
                conditions={[
                  { key: 'title', value: 'Preconditions' },
                  { key: 'title', value: 'Postconditions' },
                ]}
                hideConditionBtn={false}
                labels={LABELS}
                id={obj.tableTreeId}
              />
            );
          }
        });
      }
      setTreeData(suiteObj?.selectedModulesAndScripts ? suiteObj?.selectedModulesAndScripts?.[0]?.children : []);
      resetProgress();
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  function getEmailData() {
    if (suiteObj?.emailData?.recipientGroup?.length) {
      getAllEmailGroupListReq().then((res) => {
        let responseEmail = res.data.responseObject ? res.data.responseObject : [];
        let emailGroupArray = [];
        if (responseEmail) {
          if (responseEmail.length && suiteObj?.emailData?.recipientGroup?.length) {
            suiteObj?.emailData?.recipientGroup?.forEach((grouepNameObj) => {
              let found = responseEmail.find((syelement) => syelement?.name === grouepNameObj);
              if (found) {
                found.emailUserList.forEach((userData) => {
                  userData.groupName = found?.name;
                  emailGroupArray.push(userData);
                });
              }
            });
          }
          currentEmailPosts = emailGroupArray.concat(suiteObj?.emailData?.emailRecipient);
          setEmailData(currentEmailPosts);
        }
      });
    } else if (suiteObj?.emailData?.emailRecipient?.length) {
      setEmailData(suiteObj?.emailData?.emailRecipient);
    }

    if (suiteObj?.slackData?.selectedSlackInstances?.length) {
      currentSlackPosts = suiteObj?.slackData?.selectedSlackInstances;
      setSlackTableData(currentSlackPosts);
    }
  }
  const updateTreeRootData = (rootDetails) => {
    let root = {
      subModuleCount: rootDetails?.subModuleCount,
      title: rootDetails?.title,
      scriptCount: rootDetails?.scriptCount?.$numberLong,
      key: rootDetails?.key,
      ver: rootDetails?.ver,
      modifiedByUname: rootDetails?.modifiedByUname,
    };
    setRootData(root);
  };

  useEffect(() => {
    setTreeData([]);
    if (selectedPage === 'suite-dashboard') {
      getSingleExecutionDataReq(selExeId)
        .then((results) => {
          const response = results.data.responseObject;
          suiteObj = response.suite;
          addMachineInstancesIfNotExist(suiteObj?.machines?.selectedMachines);
          getModuleTree();
          getEmailData();
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (selectedPage === 'suite-scheduled-instance') {
      getSingleScheduleExecutionDataReq(selExeId)
        .then((results) => {
          const response = results.data.responseObject;
          suiteObj = response?.suite;
          if (suiteObj?.selectedModulesAndScripts) {
            getModuleTree();
            let rootDetails = suiteObj?.selectedModulesAndScripts[0];
            updateTreeRootData(rootDetails);
            if (suiteObj?.selectedModulesAndScripts) {
              if (suiteObj?.selectedModulesAndScripts?.length) {
                suiteObj?.selectedModulesAndScripts?.[0]?.children.forEach((obj) => {
                  obj.checkbox = false;
                });
              }
            }
            setTreeData(suiteObj?.selectedModulesAndScripts?.[0]?.children);
            getEmailData();
          } else {
            console.error('ERR: selectedModulesAndScripts is not available');
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [reloadTree]);

  function calculateModuleCount(treeData) {
    if (treeData?.length) {
      treeData?.forEach((obj) => {
        if (obj?.folder) {
          totalModCount.current++;
          calculateModuleCount(obj?.children);
        }
      });
    }
    return totalModCount.current;
  }

  function calculateScriptCount(treeData) {
    if (treeData?.length) {
      treeData?.forEach((obj) => {
        if (obj?.folder) {
          filterScriptData(obj?.children);
          calculatedScriptNumber(obj);
        } else {
          if (obj?.type === 'Script') {
            totalScriptCount++;
          }
        }
      });
    }
    return totalScriptCount;
  }

  function filterScriptData(childData) {
    if (childData?.length) {
      childData?.forEach((obj) => {
        if (obj?.folder) {
          filterScriptData(obj?.children);
          calculatedScriptNumber(obj);
        } else {
          if (obj?.type === 'Script') {
            totalScriptCount++;
          }
        }
      });
    }
  }
  let renderTree;
  let renderTreeForMaximize;

  const colDefs = [
    {
      field: 'title',
      title: 'MODULE',
      class: 'title',
      isTitle: true,
      width: `calc(100% - 550px)`,
      folderTitleWidth: `calc(100% - 670px)`,
      paddingRight: '16px',
      render: (nodeObj) => {
        createFilterData(treeData);
        return (
          <>
            {nodeObj.isRootNode && (
              <>
                <span class="count-badge folder-count ml-2">
                  M {treeData[0]?.totalModuleCount ? treeData[0]?.totalModuleCount : 0}{' '}
                </span>
                <span class="count-badge file-count"> S {suiteObj?.noOfScripts} </span>
              </>
            )}
            {nodeObj.data.calculatedScriptCount > 0 && (
              <span class="count-badge file-count"> S {nodeObj?.data?.calculatedScriptCount} </span>
            )}
            {nodeObj.folder && (
              <button class="add-btn">
                <MoreInfoButton type="add" nodeObj={nodeObj} plusIconArray={plusIconArray} />
              </button>
            )}
          </>
        );
      },
    },
    {
      field: 'scriptType',
      title: 'TYPE',
      class: 'Type truncate',
      width: '80px',
      render: (nodeObj) => {
        return (
          <Tooltip title={nodeObj.data.scriptType}>
            <span className="text-xs table-non-link-color-common fontPoppinsRegularMd">{nodeObj.data.scriptType}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'modifiedByUname',
      title: 'MODIFIED BY',
      class: 'modified-by',
      width: '200px',
      render: (nodeObj) => {
        return (
          <span className="table-non-link-color-common table-font-style-common modifiedBy " marginRight="10px">
            {nodeObj.data.modifiedByUname === '--' ? nodeObj.data.createdByUname : nodeObj.data.modifiedByUname}
          </span>
        );
      },
    },
  ];

  if (isLoading) {
    renderTree = <CommonLoader />;
  } else if (treeData && treeData.length > 0) {
    renderTree = (
      <TableTree
        data={treeData}
        rootData={rootData}
        colDefs={colDefs}
        filter={treeValue}
        expandAll={isExpandAll}
        hideElements={false}
        hideConditions={true}
        showCheckbox={false}
        conditions={[
          { key: 'title', value: 'Preconditions' },
          { key: 'title', value: 'Postconditions' },
        ]}
        hideConditionBtn={false}
        labels={LABELS}
        id="systemTree"
      />
    );
  }

  if (isLoading) {
    renderTreeForMaximize = <CommonLoader />;
  } else if (treeData && treeData.length > 0) {
    renderTreeForMaximize = (
      <TableTree
        data={treeData}
        rootData={rootData}
        colDefs={colDefs}
        filter={treeValue}
        expandAll={isExpandAll}
        hideElements={false}
        hideConditions={true}
        showCheckbox={false}
        conditions={[
          { key: 'title', value: 'Preconditions' },
          { key: 'title', value: 'Postconditions' },
        ]}
        hideConditionBtn={false}
        labels={LABELS}
        id="renderTreeForMaximize"
      />
    );
  }

  function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    return (
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`Search...`}
      />
    );
  }

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  fuzzyTextFilterFn.autoRemove = (val) => !val;

  function Table({ columns, data }) {
    const filterTypes = React.useMemo(
      () => ({
        fuzzyText: fuzzyTextFilterFn,
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      []
    );

    const defaultColumn = React.useMemo(
      () => ({
        Filter: DefaultColumnFilter,
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        filterTypes,
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination
    );
    return (
      <>
        <table class="relative " {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr class="mt-4" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    class={`sticky top-0 px-3 py-3 text-current table-header_font-style text-sm bg-gray-100 ${
                      TEST_ENV_TABLE_WIDTH_SET.includes(column.totalWidth) ? 'w-' + column.width + 'px' : ''
                    }`}
                    {...column.getHeaderProps()}
                  >
                    <span className="flex flex-row">
                      {column.render('Header')}
                      <div>
                        <span></span>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {selectedTypeForMaximize &&
          (selectedTypeForMaximize === 'email' ||
            selectedTypeForMaximize === 'device' ||
            selectedTypeForMaximize === 'slack') ? (
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr className="project-row" {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td class={`px-3 py-3 text-center`} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr className="project-row" {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td class="px-3 py-3 text-center" {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        <br />
      </>
    );
  }

  const selectedProject = JSON.parse(localStorage.getItem('selected-project'));

  const columns = React.useMemo(
    () =>
      selectedProject.type.toLowerCase() === 'web'
        ? [
            {
              Header: 'NAME',
              columns: [],
            },
            {
              Header: 'EXECUTION ENVIRONMENT',
              accessor: 'executionEnv',
              width: '180',
              disableFilters: true,
              Cell: ({ value, row }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-180px truncate">
                      {value}
                      {`${row?.original?.headless === true ? '(headless)' : ''}`}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'OS & OS VERSION',
              accessor: 'machineInfo.osName',
              width: '140',
              disableFilters: true,
              Cell: ({ value, row }) => {
                return (
                  <div className=" table-non-link-color-common float-left table-font-style-common w-140px truncate version-text-color">
                    {value ? getOsLabel(value) : ''}
                    {`${row?.original?.machineInfo?.osVersion}`}
                  </div>
                );
              },
            },
            {
              Header: 'BROWSER',
              accessor: 'browserName',
              width: '80',
              disableFilters: true,
              Cell: ({ value }) => {
                return <div className="w-80px version-text-color">{value ? renderBrowserCell(value) : ''}</div>;
              },
            },
            {
              Header: 'BROWSER VERSION',
              accessor: 'browserVersion',
              width: '100',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="w-100px table-non-link-color-common float-left table-font-style-common truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'NO.OF RUNS',
              accessor: 'numberOfRuns',
              disableFilters: true,
              width: '80',
              Cell: ({ value }) => {
                return (
                  <div className="table-display-common table-non-link-color-common  w-80px table-font-style-common">
                    {value}
                  </div>
                );
              },
            },
          ]
        : selectedProject.type.toLowerCase() === 'mobile'
        ? [
            {
              Header: 'NAME',
              columns: [],
            },
            {
              Header: 'EXECUTION ENVIRONMENT',
              accessor: 'executionEnv',
              width: '180',
              disableFilters: true,
              Cell: ({ value, row }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-180px truncate">
                      {value}
                      {`${row?.original?.headless === true ? '(headless)' : ''}`}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'DEVICE',
              accessor: 'deviceInfo[0].name',
              width: '100',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="w-100px table-non-link-color-common float-left table-font-style-common truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'NO. OF RUNS',
              accessor: 'numberOfRuns',
              width: '100',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <div className="table-display-common table-non-link-color-common  w-100px table-font-style-common">
                    {value}
                  </div>
                );
              },
            },
          ]
        : [
            {
              Header: 'NAME',
              columns: [],
            },
            {
              Header: 'EXECUTION ENVIRONMENT',
              accessor: 'executionEnv',
              width: '180',
              disableFilters: true,
              Cell: ({ value, row }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-180px truncate">
                      {value}
                      {`${row?.original?.headless === true ? '(headless)' : ''}`}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'OS & OS VERSION',
              accessor: 'machineInfo.osName',
              width: '140',
              disableFilters: true,
              Cell: ({ value, row }) => {
                return (
                  <div className="table-non-link-color-common float-left table-font-style-common w-140px truncate version-text-color">
                    {value ? getOsLabel(value) : ''}
                    {`${row?.original?.machineInfo?.osVersion}`}
                  </div>
                );
              },
            },
            {
              Header: 'BROWSER',
              accessor: 'browserName',
              width: '80',
              disableFilters: true,
              Cell: ({ value }) => {
                return <div className="w-80px version-text-color">{value ? renderBrowserCell(value) : ''}</div>;
              },
            },
            {
              Header: 'BROWSER VERSION',
              accessor: 'browserVersion',
              width: '140',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="w-140px table-non-link-color-common float-left table-font-style-common truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'DEVICE',
              accessor: 'deviceInfo[0].name',
              width: '80',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="w-80px table-non-link-color-common float-left table-font-style-common truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'NO. OF RUNS',
              accessor: 'numberOfRuns',
              disableFilters: true,
              width: '80',
              Cell: ({ value }) => {
                return (
                  <div className="table-display-common table-non-link-color-common w-80px table-font-style-common">
                    {value}
                  </div>
                );
              },
            },
          ],
    []
  );

  const columnsManual = React.useMemo(
    () =>
      selectedProject.type.toLowerCase() === 'web'
        ? [
            {
              Header: 'Name',
              columns: [],
            },
            {
              Header: 'USER',
              accessor: 'clientId',
              width: '180',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-180px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'EXECUTION ENVIRONMENT',
              accessor: 'executionEnv',
              width: '200',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-200px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'OS',
              accessor: 'machineInfo.osName',
              disableFilters: true,
              Cell: ({ value }) => {
                return <span className="version-text-color">{value ? getOsLabel(value) : ''}</span>;
              },
            },
            {
              Header: 'OS VER',
              accessor: 'machineInfo.osVersion',
              width: '120',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-120px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'BROWSER',
              accessor: 'browserName',
              disableFilters: true,
              Cell: ({ value }) => {
                return value && renderBrowserCell(value);
              },
            },
            {
              Header: 'BROWSER VERSION',
              accessor: 'browserVersion',
              width: '160',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-160px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
          ]
        : selectedProject.type.toLowerCase() === 'mobile'
        ? [
            {
              Header: 'Name',
              columns: [],
            },
            {
              Header: 'USER',
              accessor: 'clientId',
              width: '200',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-200px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'EXECUTION ENVIRONMENT',
              accessor: 'executionEnv',
              width: '200',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-200px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'OS',
              accessor: 'machineInfo.osName',
              disableFilters: true,
              Cell: ({ value }) => {
                return <span className="version-text-color">{value ? getOsLabel(value) : ''}</span>;
              },
            },
            {
              Header: 'OS VER',
              accessor: 'machineInfo.osVersion',
              width: '120',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-120px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },

            {
              Header: 'DEVICE',
              accessor: 'deviceInfo[0].name',
              width: '180',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-180px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
          ]
        : [
            {
              Header: 'Name',
              columns: [],
            },
            {
              Header: 'USER',
              accessor: 'clientId',
              width: '120',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-120px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'EXECUTION ENVIRONMENT',
              accessor: 'executionEnv',
              width: '200',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-200px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'OS',
              accessor: 'machineInfo.osName',
              disableFilters: true,
              Cell: ({ value }) => {
                return <span className="version-text-color">{value ? getOsLabel(value) : ''}</span>;
              },
            },
            {
              Header: 'OS VER',
              accessor: 'machineInfo.osVersion',
              width: '100',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-100px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'BROWSER',
              accessor: 'browserName',
              disableFilters: true,
              Cell: ({ value }) => {
                return value && renderBrowserCell(value);
              },
            },
            {
              Header: 'BROWSER VERSION',
              accessor: 'browserVersion',
              width: '140',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-140px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'DEVICE',
              accessor: 'deviceInfo[0].name',
              width: '100',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-100px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
          ],
    []
  );

  const columnsEmail = React.useMemo(
    () => [
      {
        Header: 'Name',
        columns: [],
      },

      {
        Header: 'EMAIL ID',
        accessor: 'emailId',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <Tooltip title={value}>
              <div className="table-non-link-color-common table-font-style-common float-left">
                {getTruncatedText(value, 25)}
              </div>
            </Tooltip>
          );
        },
      },

      {
        Header: 'RECIPIENT NAME',
        accessor: 'name',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common table-font-style-common float-left">{value ? value : 'NA'}</div>
          );
        },
      },
      {
        Header: 'RECIPIENTS GROUP',
        accessor: 'groupName',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common table-font-style-common float-left">{value ? value : 'NA'}</div>
          );
        },
      },
    ],
    []
  );

  const columnsSlack = React.useMemo(
    () => [
      {
        Header: 'INSTANCE NAME',
        accessor: 'instanceName',
        width: '400',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <Tooltip title={value}>
              <div className="table-non-link-color-common float-left table-font-style-common">{value}</div>
            </Tooltip>
          );
        },
      },

      {
        Header: 'CHANNEL ID',
        accessor: 'channelId',
        width: '240',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <Tooltip title={value}>
              <div className="table-non-link-color-common float-left table-font-style-common">
                {getTruncatedText(value, 25)}
              </div>
            </Tooltip>
          );
        },
      },

      {
        Header: 'CHANNEL NAME',
        accessor: 'channelName',
        width: '240',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common float-left table-font-style-common">{value ? value : 'NA'}</div>
          );
        },
      },
    ],
    []
  );

  function createFilterData(treeData) {
    if (treeData) {
      let tempArray = [];
      let scriptArray = [];
      let tempNamesArray = [];
      let tempTypeArray = [];
      treeData.forEach((treeVal) => {
        if (treeVal) {
          if (treeVal.title) {
            tempArray.push(treeVal.title);
          }
          if (treeVal.createdByUname) {
            tempNamesArray.push(treeVal.createdByUname);
          }
          if (treeVal && treeVal.children) {
            scriptArray = treeVal.children;
            scriptArray.forEach((scriptVal) => {
              if (scriptVal.title) {
                tempArray.push(scriptVal.title);
              }
              if (scriptVal.createdByUname) {
                tempNamesArray.push(treeVal.createdByUname);
              }
              if (scriptVal.scriptType) {
                tempTypeArray.push(scriptVal.scriptType);
              }
            });
          }
        }
      });
    }
  }

  const [showModal, setShowModal] = React.useState(false);
  const [selectedTypeForMaximize, setSelectedTypeForMaximize] = React.useState();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage] = React.useState(5);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  let currentEmailPosts;
  let currentDeviceData;
  let currentSlackPosts;
  if (
    suiteObj &&
    suiteObj?.emailData &&
    suiteObj?.emailData?.emailRecipient &&
    suiteObj?.emailData?.emailRecipient.length
  ) {
    currentEmailPosts = suiteObj?.emailData?.emailRecipient.slice(indexOfFirstPost, indexOfLastPost);
  }
  if (
    suiteObj &&
    suiteObj?.machines &&
    suiteObj?.machines?.selectedMachines &&
    suiteObj?.machines?.selectedMachines.length
  ) {
    currentDeviceData = suiteObj.machines.selectedMachines.slice(indexOfFirstPost, indexOfLastPost);
  }

  function MaximizeFun(selectedType) {
    if (selectedType === 'module') {
      setSelectedTypeForMaximize(selectedType);
      setShowModal(true);
    } else if (selectedType === 'email') {
      setSelectedTypeForMaximize(selectedType);
      setShowModal(true);
    } else if (selectedType === 'device') {
      setSelectedTypeForMaximize(selectedType);
      setShowModal(true);
    } else if (selectedType === 'slack') {
      setSelectedTypeForMaximize(selectedType);
      setShowModal(true);
    }
  }

  return (
    <>
      <Modal open={showModal} className="modal-dialog">
        <div className="modal-container max-popup-style">
          <div className="modal-header ">
            {selectedTypeForMaximize === 'module' ? (
              <label className="Popup-header-common">Modules / Scripts</label>
            ) : selectedTypeForMaximize === 'email' ? (
              <label className="Popup-header-common">Email Recipients</label>
            ) : selectedTypeForMaximize === 'device' ? (
              <label className="Popup-header-common">Test Environments</label>
            ) : selectedTypeForMaximize === 'slack' ? (
              <label className="Popup-header-common">Slack Recipients</label>
            ) : null}
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                setShowModal(false);
                setSelectedTypeForMaximize();
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>

          <div className="modal-body-1" id="">
            {selectedTypeForMaximize === 'module' ? (
              <div className="details-page-popup-height">{renderTreeForMaximize}</div>
            ) : selectedTypeForMaximize === 'email' ? (
              <div className="table_height overflow-auto" id="journal-scroll">
                <Table columns={columnsEmail} data={emailTableData} />
              </div>
            ) : selectedTypeForMaximize === 'device' ? (
              <div className="table_height overflow-auto" id="journal-scroll">
                <Table columns={columns} data={suiteObj.machines.selectedMachines}></Table>
              </div>
            ) : selectedTypeForMaximize === 'slack' ? (
              <div className="table_height overflow-auto" id="journal-scroll">
                <Table columns={columnsSlack} data={slackTableData} />
              </div>
            ) : null}
          </div>
        </div>
      </Modal>

      <div className="content_area_style overflow-auto" id="journal-scroll">
        <div className="details-main-header">
          <label className="page-header-common float-left">{selExeId}</label>
        </div>
        <div className="details-body" id="journal-scroll">
          <div className="grid grid-cols-3 gap-6">
            <div>
              <label className="details-label-style">Name</label>
              <br></br>
              <Tooltip title={`${suiteObj?.name}`}>
                <label className="block truncate set-details-value-style">{suiteObj?.name}</label>
              </Tooltip>
            </div>
            <div className="flex flex-col truncate">
              <label className="details-label-style">Description</label>
              <Tooltip className="fontPoppinsRegular" title={suiteObj?.description}>
                <label className="set-details-value-style truncate">
                  {suiteObj?.description ? suiteObj?.description : '--'}
                </label>
              </Tooltip>
            </div>
            <div>
              <label className="details-label-style">Suite Type</label>
              <br></br>
              <label className="set-details-value-style">{suiteObj?.suiteType}</label>
            </div>
          </div>

          <div className="details-content-area-style">
            <div className="details_content_area_header_style">
              <label className="details-header-style float-left ">Modules / Scripts</label>
              <div className="flex flex-row float-right">
                <FiMaximize2
                  className=" maximize-btn-style cursor-hand mt-1 mr-3"
                  title="Maximize"
                  onClick={() => MaximizeFun('module')}
                />
              </div>
            </div>
            <div className="h-80">{renderTree}</div>
          </div>

          <div className="single-dotted-border"></div>

          {suiteObj?.suiteType === 'Automation' ? (
            <div className="variable-details-area-style mt-5">
              <label className="section-header-style">Variables</label>
              <div className="grid grid-cols-2 mt-5">
                <div>
                  <label className="details-label-style">Project Environment Variables</label>
                  <br></br>
                  <label className="set-details-value-style">
                    {suiteObj && suiteObj.usePeVariableFrom === 'SET'
                      ? `Use variables from Project Variable Set (${suiteObj.selectedPESetName})`
                      : 'Use variables from Test Development'}
                  </label>
                </div>
                <div>
                  <label className="details-label-style">Global Variables</label>
                  <br></br>
                  <label className="set-details-value-style">
                    {suiteObj && suiteObj.useGlobalVariableFrom === 'SET'
                      ? `Use variables from Project Variable Set (${suiteObj.selectedGVSetName})`
                      : 'Use variables from Test Development'}
                  </label>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {suiteObj?.suiteType === 'Automation' ? <div className="single-dotted-border"></div> : <></>}

          {suiteObj?.suiteType === 'Automation' ? (
            <div className="variable-details-area-style mt-5">
              <label className="section-header-style">Test Data</label>
              <div className="grid grid-cols-2 mt-5">
                <div>
                  <label className="details-label-style">Test Data</label>
                  <br></br>
                  <label className="set-details-value-style">
                    {suiteObj && suiteObj.useTestDataFrom === 'SET'
                      ? `Use Test Data from Test Data set (${suiteObj.selectedTDSetName})`
                      : 'Use test data from Test Development'}
                  </label>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {suiteObj?.suiteType === 'Automation' ? <div className="single-dotted-border"></div> : <></>}

          <div className="variable-details-area-style mt-5">
            <label className="section-header-style">
              {suiteObj?.suiteType !== 'Manual' ? 'Run Settings' : 'User Settings'}
            </label>
            <div className="mt-5">
              <label className="section-header-style ">{suiteObj?.suiteType !== 'Manual' ? 'Machine' : null}</label>
            </div>
            {suiteObj?.suiteType !== 'Manual' ? (
              <div className="grid grid-cols-2 mt-5">
                <div>
                  <label className="details-label-style">Run In</label>
                  <br></br>
                  <label className="details-value-style">
                    {suiteObj?.machines?.multiple === 'false' ? 'Single Machine' : 'Multiple Machine'}
                  </label>
                </div>
                <div>
                  <label className="details-label-style">Execution Type</label>
                  <br></br>
                  <label className="details-value-style">
                    {suiteObj?.machines?.multiple === 'false' || !suiteObj?.machines?.multiple
                      ? 'Sequential'
                      : suiteObj?.machines?.executionType === 'SEND_SUITE_TO_ALL'
                      ? 'Parallel'
                      : 'Distribute'}
                  </label>
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-2 mt-5">
                <div>
                  <label className="details-label-style">Execution Type</label>
                  <br></br>
                  <label className="details-value-style">
                    {suiteObj?.machines?.multiple === 'false' ? 'Single User' : 'Multiple USer'}
                  </label>
                </div>
              </div>
            )}

            <div
              className={
                suiteObj?.machines?.multiple === 'false'
                  ? 'single-machine-details-content-area-style pt-2'
                  : 'details-content-area-style pt-2'
              }
            >
              {suiteObj?.machines?.multiple === 'false' ? (
                <MachineEnvironmentLayout disableHeaderBtn={true}>
                  <MachineEnvironmentTableLayout
                    headerLabel={suiteObj.machines.selectedMachines?.[0]?.machineInstances?.[0]?.machineInfo?.hostName}
                    accessDetails={suiteObj.machines.selectedMachines?.[0]?.machineInstances?.[0]?.access}
                    columns={suiteObj?.suiteType === 'Manual' ? columnsManual : columns}
                    data={suiteObj.machines.selectedMachines?.[0]?.machineInstances}
                    placement="bottom"
                  />
                </MachineEnvironmentLayout>
              ) : suiteObj?.machines?.multiple === 'true' &&
                suiteObj?.machines?.executionType === 'SEND_SUITE_TO_ALL' ? (
                <div className="table_height overflow-auto" id="journal-scroll">
                  <MachineEnvironmentLayout disableHeaderBtn={true}>
                    {currentDeviceData.map((currentMachine) => (
                      <MachineEnvironmentTableLayout
                        headerLabel={currentMachine?.machineInstances[0].machineInfo.hostName}
                        accessDetails={currentMachine?.machineInstances[0]?.access}
                        columns={suiteObj?.suiteType === 'Manual' ? columnsManual : columns}
                        data={currentMachine?.machineInstances}
                        placement="bottom"
                      />
                    ))}
                  </MachineEnvironmentLayout>
                </div>
              ) : (
                <>
                  {treeArrayForMultipleTree.map((obj) => {
                    return (
                      <div className="details-content-area-style">
                        <div className="grid grid-cols-2 mt-2 p-3 ">
                          <div className="suite-header-style">
                            <label className="module-script-label-style float-left">
                              {obj?.deviceInfo?.machineInfo?.hostName}
                            </label>
                          </div>

                          <div>
                            <div className="flex flex-row float-right mb-3">
                              <FiMaximize2 className=" cursor-hand mt-1 mr-3" onClick={() => MaximizeFun('Machine1')} />
                            </div>
                          </div>
                        </div>
                        <div className="h-80">{obj?.treeName}</div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>

            {suiteObj?.suiteType === 'Automation' ? (
              <div className="mt-5">
                <label className="section-header-style ">Wait Time</label>
                <div className="grid grid-cols-3 mt-5">
                  <div>
                    <label className="details-label-style">Implicit Wait</label>
                    <br></br>
                    <label className="set-details-value-style">
                      {suiteObj?.waitTime?.implicitWait} {suiteObj?.waitTime?.implicitWaitUnit}
                    </label>
                    <br></br>
                    <label className="set-details-value-style">
                      {' '}
                      {suiteObj?.waitTime?.implicitWaitUnit === 'true' ? 'Send Mail' : null}
                    </label>
                  </div>
                  <div>
                    <label className="details-label-style">Explicitly Wait</label>
                    <br></br>
                    <label className="set-details-value-style">
                      {suiteObj?.waitTime?.explicitlyWait} {suiteObj?.waitTime?.explicitlyWaitUnit}
                    </label>
                    <br></br>
                    <label className="set-details-value-style">
                      {' '}
                      {suiteObj?.waitTime?.explicitlyWaitUnit === 'true' ? 'Send Mail' : null}
                    </label>
                  </div>
                  <div>
                    <label className="details-label-style">Delay Between Steps</label>
                    <br></br>
                    <label className="set-details-value-style">
                      {suiteObj?.waitTime?.delayBetweenSteps} {suiteObj?.waitTime?.delayBetweenStepsUnit}
                    </label>
                    <br></br>
                    <label className="set-details-value-style">
                      {' '}
                      {suiteObj?.waitTime?.delayBetweenStepsUnit === 'true' ? 'Send Mail' : null}
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            {suiteObj?.suiteType === 'Automation' ? (
              <div className="mt-5">
                <label className="section-header-style ">Result Setings</label>
                <div className="grid grid-cols-3 mt-5">
                  <div>
                    <label className="details-label-style">Capture Screenshots</label>
                    <br></br>
                    <label className="set-details-value-style">
                      {suiteObj && suiteObj?.resultSetting?.captureScreenshots}
                    </label>
                  </div>
                  <div>
                    <label className="details-label-style">Capture Execution Video</label>
                    <br></br>
                    <label className="set-details-value-style">
                      {suiteObj && suiteObj?.resultSetting?.captureVideoForFailedTestScript}
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            {suiteObj?.suiteType === 'Automation' ? (
              <div className="mt-5">
                <label className="section-header-style ">Execution Termination</label>
                <div className="grid grid-cols-3 mt-5">
                  <div>
                    <label className="details-label-style">
                      Terminate Script if any script execution takes more than
                    </label>
                    <br></br>
                    <label className="set-details-value-style">
                      {suiteObj?.executionTermination?.terminateScriptIfTakesMoreTime}
                      &nbsp;
                      {suiteObj?.executionTermination?.terminateScriptUnit}
                    </label>
                    <br></br>
                  </div>
                  <div>
                    <label className="details-label-style">Terminate Suite if suite execution takes more than</label>
                    <br></br>
                    <label className="set-details-value-style">
                      {suiteObj?.executionTermination?.terminateSuiteIfTakesMoreTime}
                      &nbsp;
                      {suiteObj?.executionTermination?.terminateSuitetUnit}
                    </label>
                    <br></br>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className=""> </div>

          {suiteObj && suiteObj?.emailData && suiteObj?.emailData?.emailTheReportAfterExecution === 'true' ? (
            <div className="variable-details-area-style mt-5">
              <label className="section-header-style">Email</label>
              <div className="mt-5">
                <label className="section-header-style ">Email Recipients</label>
              </div>
              <div className="grid grid-cols-2 mt-5">
                <div>
                  <label className="details-label-style">Recipient Group</label>
                  <br></br>
                  {suiteObj &&
                  suiteObj?.emailData &&
                  suiteObj?.emailData?.recipientGroup &&
                  suiteObj?.emailData?.recipientGroup.length
                    ? suiteObj?.emailData?.recipientGroup.map((groupName, index) => (
                        <label className="set-details-value-style">
                          {groupName} {index > suiteObj?.emailData?.recipientGroup.length ? ',' : null}
                        </label>
                      ))
                    : null}
                </div>
              </div>

              <div className="details-content-area-style">
                <div className="details_content_area_header_style">
                  <label className="details-header-style float-left ">Email Recipients</label>
                  <div className="flex flex-row float-right">
                    <FiMaximize2 className="mt-1 mr-3" onClick={() => MaximizeFun('email')} />
                  </div>
                </div>
                <div className="table_height overflow-auto" id="journal-scroll">
                  <Table columns={columnsEmail} data={emailTableData} />
                </div>
              </div>

              <div className="mt-5">
                <label className="section-header-style ">Email Reports</label>
              </div>
              <div className="grid grid-cols-2 mt-5">
                <div>
                  <label className="details-label-style">Email Reports</label>
                  <br></br>
                  <label className="set-details-value-style">
                    {suiteObj?.emailData?.emailOnSuiteExecutionStart === 'true'
                      ? EMAIL_NOTIFICATION_CONSTANTS.ON_SUITE_EXECUTION_START
                      : null}
                    {suiteObj?.emailData?.emailOnSuiteExecutionCompletion === 'true'
                      ? EMAIL_NOTIFICATION_CONSTANTS.ON_SUITE_EXECUTION_COMPLETION_OR_TERMINATION
                      : null}
                    {suiteObj?.emailData?.emailManuallyTriggerEmailNotification === 'true'
                      ? EMAIL_NOTIFICATION_CONSTANTS.MANUALLY_TRIGGER_EMAIL_NOTIFICATION
                      : null}
                  </label>
                </div>
                <div>
                  <label className="details-label-style">Attach Execution Report</label>
                  <br></br>
                  <label className="set-details-value-style">{getReportTypes(suiteObj?.emailData)}</label>
                </div>
              </div>
            </div>
          ) : null}

          {suiteObj?.slackData?.selectedSlackInstances?.length > 0 ? (
            <div className="variable-details-area-style mt-5">
              <label className="section-header-style">Slack</label>
              <div className="mt-5">
                <label className="section-header-style ">Slack Recipients</label>
              </div>
              <div className="grid grid-cols-2 mt-5">
                <div>
                  <label className="details-label-style">Slack Instances</label>
                  <br></br>
                  {suiteObj?.slackData?.selectedSlackInstances.length
                    ? suiteObj?.slackData?.selectedSlackInstances.map((groupName, index) => (
                        <label className="details-value-style" key={index}>
                          {groupName.instanceName}
                          {index < suiteObj?.slackData?.selectedSlackInstances.length - 1 ? ',' : null}
                        </label>
                      ))
                    : null}
                </div>
              </div>

              <div className="details-content-area-style">
                <div className="details_content_area_header_style">
                  <label className="details-header-style float-left ">Slack Recipients</label>
                  <div className="flex flex-row float-right">
                    <FiMaximize2 className="mt-1 mr-3" onClick={() => MaximizeFun('slack')} />
                  </div>
                </div>
                <div className="table_height overflow-auto" id="journal-scroll">
                  <Table columns={columnsSlack} data={slackTableData} />
                </div>
              </div>

              <div className="mt-5">
                <label className="section-header-style ">Slack Reports</label>
              </div>
              <div className="grid grid-cols-2 mt-5">
                <div>
                  <label className="details-label-style">Slack Reports</label>
                  <br></br>
                  <label className="set-details-value-style">
                    {suiteObj?.slackData?.on_Suite_Execution_Starts ? SLACK_CONSTANTS.ON_SUITE_EXECUTION_START : null}
                    {suiteObj?.slackData?.on_Suite_Execution_Starts && suiteObj?.slackData?.on_Suite_Execution_Ends
                      ? ','
                      : null}
                    {suiteObj?.slackData?.on_Suite_Execution_Ends
                      ? SLACK_CONSTANTS.ON_SUITE_EXECUTION_COMPLETION_OR_TERMINATION
                      : null}
                    {suiteObj?.slackData?.slackManuallyTriggerNotification
                      ? SLACK_CONSTANTS.MANUALLY_TRIGGER_EMAIL_NOTIFICATION
                      : null}
                  </label>
                </div>
                <div>
                  <label className="details-label-style">Attach Execution Report</label>
                  <br></br>
                  <label className="set-details-value-style">{getReportTypes(suiteObj?.slackData)}</label>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ExecutionIdDetailsPage;

import React, { useEffect, useRef, useState } from 'react';
import './Chatbot.scss';
import { Icon, Loader } from 'fireflink-ui';
import { getScrollPosition } from '@src/pages/analytics/common/util';
import { getUserName, scrollToBottom } from '@util/common_utils';
import { getChatbotMessage } from '@src/api/api_services';
import { CHATBOT_CONSTANTS, CHATBOT_QUESTIONS_CONSTANTS } from '@src/common/ui-constants';
import DOMPurify from 'dompurify';
import { colors } from './../../css_config/colorConstants';
const Chatbot = (props) => {
  const [botMessages, setbotMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [userName, setUserName] = useState('');
  const [scrollPosition, setScrollPosition] = useState(false);
  const [exitIcon, setExitIcon] = useState(false);

  let chatBox = useRef();
  let textField = useRef();
  const scrollingDivRef = useRef(null);

  useEffect(() => {
    setUserName(getUserName());
  }, []);

  const handleChange = (e) => {
    let { value } = e.target;
    setMessage(value);
  };

  const onSendButton = () => {
    setMessage('');
    const requestFromUser = textField.current.value;
    if (requestFromUser === '') {
      return;
    }
    let requestMessage = { request: requestFromUser, message: requestFromUser };
    setbotMessages([...botMessages, requestMessage]);

    getChatbotMessage({ message: requestFromUser })
      .then((response) => {
        let responseMessage = {
          request: requestFromUser,
          message: response?.data?.answer,
        };
        setbotMessages([...botMessages, responseMessage]);
        textField.current.value = '';
      })
      .catch((error) => {
        console.error('Error', error);
        textField.current.value = '';
      });
  };

  useEffect(() => {
    scrollToBottom(scrollingDivRef);
    setScrollPosition(true);
  }, [botMessages]);

  return (
    <div className={`${props.showChatbot ? 'container-data w-300px' : 'container-data-hidden'}`}>
      <div className="chatbox">
        <div className={`chatbox-support`} ref={chatBox}>
          <div className="chatbox-header">
            <div className="mr-2.5">
              <Icon name="logo_icon" height={30} width={31} />
            </div>
            <div className="chatbox-content-header">
              <h5 className="chatbox-heading-header">{CHATBOT_CONSTANTS.FIREFLINK_SUPPORT}</h5>
              <p
                className="exit-icon"
                onClick={() => {
                  props.setShowChatbot(false);
                  props.setHide(false);
                }}
                onMouseOver={() => {
                  setExitIcon(true);
                }}
                onMouseLeave={() => {
                  setExitIcon(false);
                }}
              >
                <Icon name={exitIcon ? 'shrink' : 'shrink_white'} height={14} width={14} />
              </p>
            </div>
          </div>

          <div
            className="chatbox-messages"
            ref={scrollingDivRef}
            onScroll={() => getScrollPosition(scrollingDivRef, setScrollPosition)}
          >
            <div>
              <p className="messages-default">
                {CHATBOT_CONSTANTS.HI} <b>{userName}</b> {CHATBOT_CONSTANTS.HELP_MESSAGE}
                <ul>
                  {CHATBOT_QUESTIONS_CONSTANTS.QUESTIONS.map((question) => (
                    <li>{question}</li>
                  ))}
                </ul>
              </p>
              {botMessages?.map((msg) => (
                <>
                  <section className="flex">
                    <p className="messages-item--operator">{msg.request}</p>
                    <div className="mt-1">
                      <div className="user-icon">
                        <p className="user-icon-text">{userName[0]}</p>
                      </div>
                    </div>
                  </section>
                  <section className="flex">
                    {msg.request === msg.message ? (
                      <Loader variant="BouncingDots" />
                    ) : (
                      <>
                        <Icon
                          name="fireflink_mini_logo"
                          color={colors.rs_primary}
                          height={24}
                          width={24}
                          className="mt-3"
                        />

                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(msg.message),
                          }}
                          className="messages-item-visitor"
                        />
                      </>
                    )}
                  </section>
                </>
              ))}
            </div>{' '}
            {!scrollPosition && (
              <button
                className="arrow-down-icon-style"
                onClick={() => {
                  scrollToBottom(scrollingDivRef);
                }}
              >
                <Icon
                  color={colors.rs_primary}
                  name="back_arrow_btn"
                  height={14}
                  width={14}
                  className="down-arrow-btn"
                />
              </button>
            )}
          </div>

          {/* Footer */}
          <div className="chatbox-footer">
            <div className="chatbot-footer-innerdiv">
              <input
                type="text"
                placeholder="Enter the question"
                value={message}
                onChange={handleChange}
                ref={textField}
                onKeyDown={({ key }) => {
                  if (key === 'Enter') {
                    onSendButton(chatBox);
                  }
                }}
              />
              <button
                className="chatbox-send-footer send-button"
                onClick={() => {
                  onSendButton(chatBox.current);
                }}
              >
                <Icon name="send_icon" className="h-4 w-4" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;

import React from 'react';

const DatabaseTD = () => {
  return (
    <div className="mt-10 project_label">
      <h1>Database</h1>
    </div>
  );
};

export default DatabaseTD;

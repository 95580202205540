import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { getProjectListDropdownReq, getDefectsStatistic, getSingleUserRequest } from '@api/api_services';
import ProjectDetailsDrawer from '@src/common/atoms/CommonDrawer/ProjectDetailsDrawer';
import DefectEditPage from '@pages/configuration/DefectConfig/UpdateDefectPlatform';
import { useAlert } from '@pages/common/alert_service/useAlert';
import { DEFECT_CONSTANTS, ACTIONS, noDataMessage } from '@src/common/ui-constants';
import EditIcon from '@mui/icons-material/Edit';
import MoreVerticalActionIcon from '@src/common/atoms/MoreVerticalAction';
import UserDetailsDrawer from '@src/common/atoms/CommonDrawer/UserDetailsDrawer';
import CommonTable from '@src/common/atoms/TableComponent';
import SearchBar from '@pages/analytics/common/search-bar';
import { HighlightText, isEmptyValue, isValueIncludes } from '@src/util/common_utils';
import cx from 'classnames';
import styles from '@pages/configuration/DefectConfig/defect-config.module.scss';
import { TooltipPoppin } from '@pages/analytics/common/util';
import NoResultsFound from '@pages/common/Table/NoResultFound';

const DefectManagement = (props) => {
  const { AlertContatiner, MyAlert } = useAlert();
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [isLoading, setIsLoading] = useState(false);
  const [viewDetailsModal, setViewDetailsModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedProject, setSelectedProject] = React.useState({});
  const [data, setData] = useState([]);
  const [defectData, setDefectData] = useState([]);
  const [viewUserModal, setViewUserModal] = useState(false);
  const [userData, setUserData] = useState({});
  const [openSearch, setOpenSearch] = useState(false);
  const [userSearched, setuserSearched] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [searchTextHighlight, setSearchTextHighlight] = useState('');
  const [isHighlight, setIsHighlight] = useState(false);

  useEffect(() => {
    setSearchData(data);
    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [data]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setSearchData(data);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  const handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setSearchData(data);
    setIsHighlight(!isHighlight);
    setSearchTextHighlight('');
  };

  const handleSearchOpen = () => {
    setOpenSearch(true);
  };

  const handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (!isEmptyValue(e?.target?.value?.trim()) || (typeof e === 'string' && !isEmptyValue(e))) {
        const filtredProjectsList = data.filter(({ name, defectPlatform }) => {
          const modifiedByUname = defectPlatform ? defectPlatform?.modifiedByUname : undefined;
          return isValueIncludes(e, name) || isValueIncludes(e, modifiedByUname);
        });
        setSearchData(filtredProjectsList);
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  async function getDefectStatistic() {
    try {
      const {
        data: { responseObject },
      } = await getDefectsStatistic();
      if (responseObject) {
        setDefectData(responseObject);
      }
    } catch (error) {
      console.error('GET_DEFECT_STATISTIC : ', error);
    }
  }

  async function getAllProjects() {
    try {
      const user = localStorage.getItem('test-optimize-user');
      const { id } = user ? JSON.parse(user) : {};
      resetProgress();
      setIsLoading(true);
      startOrResumeProgress({
        stopAt: 90,
      });
      const response = await getProjectListDropdownReq('status=Open');
      const {
        data: { responseObject },
      } = response || {};
      stopProgress();
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 10,
      });
      if (responseObject?.length) {
        setData([...responseObject]);
      } else {
        setData([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('GET_ALL_PROJECTS_REQ :', error);
      resetProgress();
      setIsLoading(false);
    }
  }

  const openDefectModal = (menu, projectDetails) => {
    if (menu === DEFECT_CONSTANTS.DETAILS) {
      setSelectedProject(projectDetails);
      handleDetailsModal(true);
    }
  };

  const editPlatform = (editModal, projectDetails) => {
    setSelectedProject(projectDetails);
    handleEditModal(editModal);
  };

  const handleDetailsModal = (viewModal) => {
    setViewDetailsModal(viewModal);
  };

  const handleEditModal = (editModal) => {
    setEditModal(editModal);
  };

  const handleUserModal = (viewUserModal) => {
    setViewUserModal(viewUserModal);
  };

  const viewUserDetails = async (projectData) => {
    try {
      const response = await getSingleUserRequest(projectData?.defectPlatform?.modifiedBy);
      if (response.data?.responseObject) {
        setUserData(response);
        setViewUserModal(true);
      }
    } catch (error) {
      console.error('VIEW_USER_DETAILS : ', error);
    }
  };

  const getDefaultDefectPlatform = (row) => {
    if (row.original?.defectPlatform?.platform) return row.original.defectPlatform.platform;
    else {
      const fireflinkDefect = defectData?.find((defect) => defect?.defectPlatformName?.toLowerCase() === 'fireflink');
      if (fireflinkDefect) {
        return fireflinkDefect.defectPlatformName;
      }
    }
    return DEFECT_CONSTANTS.NA;
  };

  useEffect(() => {
    getAllProjects();
    getDefectStatistic();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'NAME',
        accessor: 'name',
        width: '370px',
        disableFilters: true,
        Cell: ({ value, row }) => (
          <span className="float-left truncate w-80">
            <HighlightText text={value} highlight={searchTextHighlight} />
          </span>
        ),
      },
      {
        Header: 'TYPE',
        accessor: 'type',
        width: '190px',
        disableFilters: true,
        Cell: ({ value }) => {
          return <span className="float-left">{value}</span>;
        },
      },
      {
        Header: 'PLATFORM',
        accessor: 'defectPlatform.platform',
        width: '225px',
        disableFilters: true,
        Cell: ({ row }) => {
          return <span className="float-left w-56 truncate">{getDefaultDefectPlatform(row)}</span>;
        },
      },
      {
        Header: 'MODIFIED BY',
        accessor: 'defectPlatform.modifiedByUname',
        width: '235px',
        disableFilters: true,
        Cell: ({ value, row }) => {
          const clickable = !!value;
          return (
            <span
              className={cx('float-left w-56 truncate', {
                [styles['clickable-text']]: clickable,
              })}
            >
              <span
                className="cursor-pointer"
                onClick={() => {
                  if (clickable) {
                    viewUserDetails(row.original);
                  }
                }}
              >
                <HighlightText text={value || '--'} highlight={searchTextHighlight} />
              </span>
            </span>
          );
        },
      },
      {
        Header: 'ACTIONS',
        accessor: 'actions',
        allignRight: true,
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <div className="flex flex-row float-right table-content">
              <span
                className="project-action action-button cursor-pointer"
                onClick={() => editPlatform(true, row.original)}
              >
                <TooltipPoppin title={ACTIONS.EDIT} placement="bottom">
                  <EditIcon fontSize="medium" className="p-1" />
                </TooltipPoppin>
              </span>
              <span className="project-action action-button cursor-pointer ml-2">
                <MoreVerticalActionIcon
                  options={[{ label: DEFECT_CONSTANTS.DETAILS }]}
                  optionHandler={openDefectModal}
                  data={row.original}
                />
              </span>
            </div>
          );
        },
      },
    ],
    [searchTextHighlight]
  );

  return (
    <>
      <div className="page-list-body">
        <div className="alert-position-style">
          <AlertContatiner></AlertContatiner>
        </div>
        <div className="page-table-body">
          <div className={styles['defect-config-section']} id="journal-scroll">
            <div className="p-4 flex flex-wrap items-center">
              <label className="flex-auto fontPoppinsSemiboldMd">{DEFECT_CONSTANTS.DEFECT_MANAGEMENT}</label>
              <div className="float-right ml-auto">
                <SearchBar
                  open={openSearch}
                  searchValue={userSearched}
                  handleSearch={handleSearch}
                  onSearchClose={handleSearchClose}
                  handleSearchOpen={handleSearchOpen}
                  enterKeyHandler={handleSearchSubmit}
                  disable={isEmptyValue(data)}
                />
              </div>
            </div>
            <div className="mx-4">
              <CommonTable
                columns={columns}
                data={searchData}
                noDataMessage={noDataMessage.projectNotAvailable}
                compSpecificClassName={'table-height overflow-x-scroll h-80v'}
                isLoading={isLoading}
                noDataContent={userSearched && isEmptyValue(searchData) && <NoResultsFound className="mt-32" />}
              />
            </div>
          </div>
        </div>
      </div>
      {viewDetailsModal && (
        <ProjectDetailsDrawer
          viewDetailsModal={viewDetailsModal}
          handleDetailsModal={handleDetailsModal}
          projectDetails={selectedProject}
        />
      )}
      {editModal && (
        <DefectEditPage
          editModal={editModal}
          handleEditModal={handleEditModal}
          projectDetails={selectedProject}
          defectData={defectData}
          MyAlert={MyAlert}
          getAllProjects={getAllProjects}
        />
      )}
      {viewUserModal && (
        <UserDetailsDrawer viewUserModal={viewUserModal} handleUserModal={handleUserModal} userDetails={userData} />
      )}
    </>
  );
};

export default withRouter(DefectManagement);

import React, { useState } from 'react';
import Modal from 'react-modal';
import '../../../config_menu.scss';
import { Link } from 'react-router-dom';
import CommonButton from '@src/common/button/Button';
import ENVIRONMENT_URL_CONFIG from '@src/common/environment-config';
import useEscapeKeyCloseModal from '@src/hooks/useEscapeKeyCloseModal';
import { getClientsForOS } from '@src/api/api_services';

const DownloadClientModal = (props) => {
  const [openDownloadClient, setopenDownloadClient] = useState(true);
  const [downloadPath, setDownloadPath] = useState();
  const [isDownloadDisabled, setIsDownloadDisabled] = useState(true);
  const [selectedOs, setSelectedOs] = useState();
  const [cachedOsData, setCachedOsData] = useState({});

  const handleClose = () => {
    setopenDownloadClient(false);
    props.closeModal(false);
  };
  let customStylesElement = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      right: '25%',
      bottom: '5%',
      height: '700px',
      width: '640px',
      padding: '0px',
      boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
    },
  };

  const getOsData = async (osName) => {
    setSelectedOs(osName);
    if (cachedOsData[osName]) {
      setDownloadPath(cachedOsData[osName]);
      setIsDownloadDisabled(false);
    } else {
      try {
        const clientOsData = await getClientsForOS(osName);
        if (clientOsData?.data?.responseObject && clientOsData?.data?.responseCode === 200) {
          const response = clientOsData.data.responseObject;
          if (response[0]?.os === osName) {
            setDownloadPath(response[0].url);
            setIsDownloadDisabled(false);
            setCachedOsData((prevData) => ({ ...prevData, [osName]: response[0].url }));
          } else {
            setIsDownloadDisabled(true);
          }
        }
      } catch (err) {
        console.error('Error fetching OS data:', err);
        setIsDownloadDisabled(true);
      }
    }
  };

  useEscapeKeyCloseModal(openDownloadClient, handleClose);

  return (
    <div>
      <Modal isOpen={openDownloadClient} onRequestClose={handleClose} style={customStylesElement}>
        <div className="pb-4 sepratorLine">
          <h2 className="Popup-header-common mb-2 text-15 pt-4 pl-4">
            <b>
              <span> Download Client</span>
            </b>
          </h2>
          <button
            onClick={handleClose}
            type="button"
            className="float-right -mt-6 mr-3.5  text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="#525252">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div className="pt-4 pl-8 pb-8 sepratorLine">
          <div className="fontPoppinsRegularMd">Select your OS to download client.</div>
          <div className=" grid grid-cols-3 pl-6 pt-6">
            <div
              className={`w-32 h-20 selectOsClass ${selectedOs === 'windows' ? 'selectedbgcolor' : null}`}
              id="windows"
              onClick={() => {
                getOsData('windows');
              }}
            >
              <div className="pl-12">
                <img
                  src="/assets/images/windows-10-icon-logo-5BC5C69712-seeklogo.com.svg"
                  alt="windows"
                  className="text-center download-modal-logo"
                />
              </div>
              <div className="fontPoppinsSemiboldMd text-center"> Windows</div>
            </div>
            <div
              className={`w-32 h-20 selectOsClass ${selectedOs === 'mac' ? 'selectedbgcolor' : null}`}
              id="mac"
              onClick={() => {
                getOsData('mac');
              }}
            >
              <div className="pl-12">
                <img
                  src="/assets/images/apple-853-675472.svg"
                  alt="apple"
                  className="text-center download-modal-logo"
                />
              </div>
              <div className="fontPoppinsSemiboldMd text-center"> Mac</div>
            </div>
            <div
              className={`w-32 h-20 selectOsClass ${selectedOs === 'linux' ? 'selectedbgcolor' : null}`}
              id="linux"
              onClick={() => {
                getOsData('linux');
              }}
            >
              <div className="pl-12">
                <img src="/assets/images/LINUX-LOGO.svg" alt="linux" className="text-center download-modal-logo" />
              </div>
              <div className="fontPoppinsSemiboldMd text-center"> Linux</div>
            </div>
          </div>
        </div>
        <div className="mt-4 text-right mb-4 mr-4 flex justify-between">
          <div className="fontPoppinsRegularMd float-left pl-9 breadcrumb_link_color hover:text-blue-600">
            {/* <Link
                            to={{
                                pathname:
                                    "https://fireflink.com/knowledge-base-2/environment-configuration/"
                            }}
                            target="_blank"
                        >
                            About Fire Flink Client
                        </Link> */}
          </div>

          <div className="float-right flex">
            <CommonButton label="Cancel" btnType="secondary" type="button" onClick={handleClose} />
            <a href={downloadPath} download>
              <CommonButton
                className="ml-3"
                label="Download"
                disabled={isDownloadDisabled}
                btnType="primary"
                onClick={() => {
                  if (selectedOs) {
                    props.closeModal(false);
                  }
                }}
              />
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DownloadClientModal;

import React from 'react';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
function ContextMenuOption(props) {
  return (
    <Menu as="div" className="relative inline-block text-left ml-1.5">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="rounded-full items-center text-gray-400 hover:text-gray-600 focus:outline-none w-5 h-5">
              <span className="sr-only ">Open options</span>
              <img
                src="/assets/images/arrow_drop_down_black.svg"
                alt="DropDownArrow"
                aria-hidden="true"
                className="ml-1 -pb-px font-bold text-sm"
              />
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 transform opacity-100 scale-100"
            >
              <div className="py-1">
                {props.contextMenuData.map((data, index) => (
                  <Menu.Item key={`context-menu-item-${index}`}>
                    {({ active }) => (
                      <a
                        onClick={props.func}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                          'hover:text-blue-700'
                        )}
                      >
                        <label className="fontPoppinsRegularMd cursor-pointer">{data.option}</label>
                      </a>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}

export default ContextMenuOption;

import React, { useState, useEffect, useRef } from 'react';
import { ArrowDropDown, ArrowRight } from '@material-ui/icons';
import { useFormik } from 'formik';
import Modal from 'react-modal';
import MachineLandingPage from '../create-edit-child-pages/run-settings-child-pages/machine-landing-page';
import WaitTime from '../create-edit-child-pages/run-settings-child-pages/wait-time';
import ResultSettings from '../create-edit-child-pages/run-settings-child-pages/result-settings';
import ExecutionTermination from '../create-edit-child-pages/run-settings-child-pages/execution-termination';
import {
  executeSuitePostReq,
  executeManualSuite,
  createRunSettingsPostReq,
  schedulePostReq,
  getWaitConfigReq,
  getScreenVideoReq,
} from '@api/api_services';
import { useHistory } from 'react-router-dom';
import { encodeSpecialCharater } from '@src/pages/test-development/shared/common-methods';
import UserMachinePage from '../create-edit-child-pages/run-settings-child-pages/user-page';
import { useAlert } from '@pages/common/alert_service/useAlert';
import * as Yup from 'yup';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Tooltip, TextField, Select, MenuItem, Grid, Input, makeStyles } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import DateFnsUtils from '@date-io/date-fns';
import {
  compareDates,
  DATE_FORMATS,
  getCurrentProjectTimeZone,
  getCurrentProjectTimeZoneName,
  getFinalMachineObject,
  getTruncatedText,
  getWaitTimeValidationCondition,
  isEmptyValue,TimeZoneDates,
  convertGMTTimeToUserGivenTime,
} from '@src/util/common_utils';
import ParallelRunExecutionStatus from '@src/pages/common/parallelRunExecutionStatus/parallel_run_executionStatus';
import { colors } from '@src/css_config/colorConstants';
import '@src/css_config/machine-environment-table.scss';
import { getManualDistributionData } from '../suite.utils';
import ScriptTreeSection from '../../common/script-tree-section';
import { EXECUTION_CONSTANTS, TIMEZONE_CONSTANTS, TOGGLE_BUTTON_NAME } from '@src/common/ui-constants';
import { Label } from '@src/common/atoms/LabelComponent';
import moment from 'moment';
import MemoryWarningPopup from '@src/pages/configuration/Memory_Config/MemoryWarningPopup';
import { Toggle } from 'fireflink-ui';

Modal.setAppElement('#root');

let runSettingsGlobalObj = {
  executionTermination: {
    terminateScriptIfTakesMoreTime: 0,
    terminateScriptUnit: 'Hrs.',
    terminateScriptSendEmail: false,
    terminateSuiteIfTakesMoreTime: 0,
    terminateSuitetUnit: 'Hrs.',
    terminateSuiteSendEmail: false,
  },
};

const useStyles = makeStyles({
  selectedItem: {
    '&:hover ,  &.Mui-selected , &.Mui-selected:hover': {
      backgroundColor: colors.rs_primary,
      color: colors.text_white,
    },
  },
});

const RunSettingsModal = (props) => {
  const customStylesPage = {
    overlay: {
      height: 'fit-content !important',
      maxHeight: 'fit-content !important',
    },
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      height: 'fit-content !important',
      maxHeight: '100% !important',
      background: colors.text_white,
      boxShadow: '0px 0px 11px #000000a1',
      borderRadius: '4px',
      opacity: '1',
    },
  };

  const formik = useFormik({
    initialValues: {
      Name: '',
    },
    validationSchema: Yup.object({
      Name: Yup.string()
        .min(3, 'Minimum 3 characters')
        .max(25, 'Maximum 25 characters')
        .required('Required!')
        .matches(/^[-_A-Za-z0-9 ]*$|^[-_A-Za-z0-9 ][A-Za-z0-9 ]*[-_A-Za-z-_]$/, 'Name should be alphanumeric')
        .matches(/^[-a-zA-Z0-9-()_]+(\s+[-a-zA-Z0-9-()_]+)*$/, 'Space is not allowed in the beginning and at the end'),
    }),
  });
  dayjs.extend(utc);
  dayjs.extend(timezone);
  let scheduleRepeatArray = ['Once', 'Daily', 'Weekly', 'Monthly'];
  let initiallySelectedTimeZone = getCurrentProjectTimeZone();
  let initialTimeZoneName = getCurrentProjectTimeZoneName();
  let timeZoneArrayValues = props.timezonedata;
  const [toggleState, setToggleState] = useState(true);
  var today = new Date();
  const [timezonedataValue, setTimeZoneDataValue] = useState(initiallySelectedTimeZone);
  const [scheduleNameClicked, setScheduleNameClicked] = useState(false);
  const [validationMinTime, setValidationMinTime] = useState();
  const [minMinute, setMinMinute] = useState();
  const [minHour, setMinHour] = useState();
  const [scheduleBtnDisable, setScheduleBtnDisable] = useState(false);
  const [expireBtnDisable, setExpireBtnDisable] = useState(false);
  const [expiryDateDisable, setExpiryDateDisable] = useState(true);
  const [expiryDate, setExpiryDate] = useState();
  const [memoryWarningPopup, setMemoryWarningPopup] = useState(false);
  let date = new Date();
  let options = { timeZone: initialTimeZoneName };
  let eastCoastTime = date.toLocaleString(TIMEZONE_CONSTANTS.US_LOCALE_TIME_ZONE, options);
  const [timeZoneName, setTimeZoneName] = useState(initialTimeZoneName);

  const timeZoneInfo = {
    timeZone: initialTimeZoneName,
  };
  const minDate = new Date().toLocaleString(TIMEZONE_CONSTANTS.US_LOCALE_TIME_ZONE, {
    timeZone: timeZoneName,
  });

  function convertToIST(dateString) {
    // Parse the input date string using moment
    const parsedDate = moment(dateString, 'M/DD/YYYY, h:mm:ss A');
  
    // Convert the parsed date to IST timezone (GMT+0530)
    const istDate = parsedDate.tz('Asia/Kolkata');
  
    // Format the date to the desired format
    const formattedDate = istDate.format('ddd MMM DD YYYY HH:mm:ss [GMT]Z');
  
    return formattedDate;
  }
  const classes = useStyles();
  const [sheduledDate, setSheduledDate] = useState();
  const [expiryTime, setExpiryTime] = useState(eastCoastTime);
  const [scheduleTime, setScheduleTime] = useState(convertToIST(eastCoastTime));
  const [repeatData, setRepeat] = useState(scheduleRepeatArray[0]);
  const [scheduleBtnClicked, setScheduleBtnClicked] = useState(false);
  const [makeSettingsDefault, setMakeSettingsDefault] = useState(false);
  const { AlertContatiner, MyAlert } = useAlert();
  const [showScheduleSection, setShowScheduleSection] = useState(true);
  const [showSystemSection, setShowSystemSection] = useState(true);
  const [showWaitSection, setShowWaitSection] = useState(false);
  const [showResSection, setShowResSection] = useState(false);
  const [showExecutionSection, setShowExecutionSection] = useState(false);
  const [showScriptsSection, setShowScriptsSection] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [singleJsonData, setSingleJsonData] = useState(
    props?.suiteType !== 'Manual'
      ? props?.runSettingsObject?.machines?.multiple === 'false'
        ? [...props?.runSettingsObject?.machines?.selectedMachines]
        : []
      : []
  );
  const [multipleJsonData, setMultipleJsonData] = useState(
    props?.suiteType !== 'Manual'
      ? props?.runSettingsObject?.machines?.multiple === 'true' &&
        props?.runSettingsObject?.machines?.executionType === 'SEND_SUITE_TO_ALL'
        ? [...props?.runSettingsObject?.machines?.selectedMachines]
        : []
      : []
  );
  const [automaticJsonData, setAutomaticJsonData] = useState([]);
  const [modifiedSelectedArray, setModifiedSelectedArray] = useState(
    props?.suiteObjMain?.selectedModulesAndScripts || []
  );
  const [allSelectedNodes, setAllSelectedNodes] = useState(
    props?.selectedSuiteObjForExecution?.selectedModulesAndScripts?.[0]?.children || []
  );
  const [manualJsonData, setManualJsonData] = useState(
    props?.runSettingsObject?.machines?.multiple === 'true' &&
      props?.runSettingsObject?.machines?.executionType === 'DISTRIBUTE' &&
      props?.runSettingsObject?.machines?.distributionMechanism === 'MANUAL'
      ? [...props?.runSettingsObject?.machines?.selectedMachines]
      : []
  );
  const [manualDistribution, setManualDistribution] = useState(
    getManualDistributionData(props.runSettingsObject.machines)
  );
  const [distributionMechanism, setDistributionMechanism] = useState(
    props?.runSettingsObject?.machines?.multiple === 'true'
      ? props?.runSettingsObject?.machines?.distributionMechanism === 'AUTOMATIC'
        ? 'AUTOMATIC'
        : props?.runSettingsObject?.machines?.distributionMechanism === 'MANUAL'
        ? 'MANUAL'
        : 'MULTIPLE'
      : null
  );
  const [waitTimeData, setWaitTimeData] = useState(
    props && props.runSettingsObject && props.runSettingsObject.waitTime ? props.runSettingsObject.waitTime : null
  );
  const [resultSettingsData, setResultSettingsData] = useState(props?.runSettingsObject?.resultSetting || null);

  const [multiple, setMultiple] = useState(
    props?.suiteObjMain?.suiteType !== 'Manual' && props.runSettingsObject.machines?.multiple === 'true'
      ? 'true'
      : 'false'
  );

  const [executionTerminationData, setExecutionTerminationData] = useState(
    props?.runSettingsObject?.executionTermination || null
  );

  const getTimeZoneDates = () => {
    let shedule = TimeZoneDates(TIMEZONE_CONSTANTS.US_LOCALE_TIME_ZONE, timeZoneName);
    setSheduledDate(shedule.$d);
    setExpiryDate(shedule.$d);
  };

  useEffect(() => {
    getTimeZoneDates();
  }, []);

  const [runSettingsObj, setRunSettingsObj] = useState({
    waitTime: {},
    resultSetting: {
      captureScreenshots: 'For Failed steps only',
      captureVideoForFailedTestScript: false,
      displayLogsInScriptResult: 'On Suite Execution Completion / Termination',
    },
    executionTermination: {
      terminateScriptIfTakesMoreTime: 0,
      terminateScriptUnit: 'Hrs.',
      terminateScriptSendEmail: false,
      terminateSuiteIfTakesMoreTime: 0,
      terminateSuitetUnit: 'Hrs.',
      terminateSuiteSendEmail: false,
    },
    machines: {},
  });
  const runSettingsObjRef = useRef({});
  runSettingsObjRef.current = runSettingsObj;
  const [runSettingsManualObj, setRunSettingsManualObj] = useState({ ...props.runSettingsManualObject });
  const [suiteObjectMain, setSuiteObjectMain] = useState({ ...props.suiteObjMain });
  const [selectedModulesStatus, setSelectedModulesStatus] = useState(props?.suiteObjMain?.selectedModulesStatus);
  const [isTreeLoading, setIsTreeLoading] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (props?.runSettingsObject && props?.runSettingsObject.hasOwnProperty('noData')) {
      let projectId = JSON.parse(localStorage.getItem('selected-project')).id;
      getWaitConfigReq(projectId).then((results) => {
        let waitTimeData = results?.data?.responseObject;
        let tempRunSettingsObj = { ...runSettingsObjRef.current };
        let waitTime = {
          implicitWait: JSON.stringify(waitTimeData?.implicitWait),
          explicitlyWait: JSON.stringify(waitTimeData?.explicitWait),
          delayBetweenSteps: JSON.stringify(waitTimeData?.delayBetweenSteps),
          implicitWaitUnit: waitTimeData?.implicitWaitUnit === 'seconds' ? 'Secs' : 'Msecs',
          explicitlyWaitUnit: waitTimeData?.explicitWaitUnit === 'seconds' ? 'Secs' : 'Msecs',
          delayBetweenStepsUnit: waitTimeData?.delayBetweenStepsUnit === 'seconds' ? 'Secs' : 'Msecs',
        };
        tempRunSettingsObj['waitTime'] = waitTime;
        runSettingsGlobalObj.waitTime = waitTime;
        setWaitTimeData(waitTime);
        setRunSettingsObj(tempRunSettingsObj);
      });
      getScreenVideoReq(projectId).then((results) => {
        let runSettingsData = results?.data?.responseObject;
        let tempRunSettingsObj = { ...runSettingsObjRef.current };
        let resultData = {
          captureScreenshots:
            runSettingsData?.captureScreenShotFor === 'allSteps'
              ? TIMEZONE_CONSTANTS.FOR_ALL_STEPS
              : runSettingsData?.captureScreenShotFor === 'failedSteps'
              ? TIMEZONE_CONSTANTS.FOR_FAILED_STEPS_ONLY
              : TIMEZONE_CONSTANTS.DO_NOT_CAPTURE,
          captureVideoForFailedTestScript:
            runSettingsData?.captureVideosFor === 'allSteps'
              ? TIMEZONE_CONSTANTS.FOR_ALL_STEPS
              : runSettingsData?.captureVideosFor === 'failedSteps'
              ? TIMEZONE_CONSTANTS.FOR_FAILED_STEPS_ONLY
              : TIMEZONE_CONSTANTS.DO_NOT_CAPTURE,
          displayLogsInScriptResult: 'On Suite Execution Completion / Termination',
        };
        tempRunSettingsObj.resultSetting = resultData;
        runSettingsGlobalObj.resultSetting = resultData;
        setResultSettingsData(resultData);
        setRunSettingsObj(tempRunSettingsObj);
      });
    } else {
      setRunSettingsObj({ ...props?.runSettingsObject });
      runSettingsGlobalObj = props?.runSettingsObject;
      if (props?.runSettingsObject?.machines?.multiple === 'true') {
        if (props?.runSettingsObject?.machines?.executionType === 'DISTRIBUTE') {
          if (props?.runSettingsObject?.machines?.distributionMechanism === 'AUTOMATIC') {
            setAutomaticJsonData([...props?.runSettingsObject?.machines?.selectedMachines]);
          }
        }
      }
    }
    if (props.modalOperation === 'schedule') {
      getTimeForValidation();
      formik.resetForm();
      checkExpiryValidation();
      checkScheduleValidation();
      checkExpiryDateValidation();
      let htmlEleForErrorExpiry = document.getElementById('date-picker-inline-expiredate-helper-text');
      if (htmlEleForErrorExpiry) {
        htmlEleForErrorExpiry.innerHTML = 'Expiry Date should not be less than Schedule Date';
      }
    }
  }, []);
  const hasTreeLoaded = () => {
    setIsTreeLoading(false);
  };
  const viewSchedule = () => {
    setShowScheduleSection(!showScheduleSection);
  };

  const viewWaitTime = () => {
    setShowWaitSection(!showWaitSection);
  };

  const viewSystem = () => {
    setShowSystemSection(!showSystemSection);
  };

  const viewResult = () => {
    setShowResSection(!showResSection);
  };

  const viewExecution = () => {
    setShowExecutionSection(!showExecutionSection);
  };

  function singleMachineAllData(jsonObjArray) {
    setSingleJsonData(jsonObjArray);
    let runSettingTempObj = { ...runSettingsObjRef.current };
    let Obj = {
      multiple: multiple,
      selectedMachines: jsonObjArray,
    };
    runSettingTempObj.machines = Obj;
    runSettingTempObj.selectedModulesAndScripts = modifiedSelectedArray;
    runSettingsGlobalObj.machines = Obj;
    runSettingsGlobalObj.selectedModulesAndScripts = modifiedSelectedArray;
    setRunSettingsObj({ ...runSettingTempObj });
  }

  function multipleMachineAllData(jsonObjArray) {
    setMultipleJsonData([...jsonObjArray]);
    let runSettingTempObj = { ...runSettingsObjRef.current };
    let Obj = {
      multiple: multiple,
      selectedMachines: jsonObjArray,
      executionType: 'SEND_SUITE_TO_ALL',
    };
    runSettingTempObj.machines = Obj;
    runSettingTempObj.selectedModulesAndScripts = modifiedSelectedArray;
    runSettingsGlobalObj.machines = Obj;
    runSettingsGlobalObj.selectedModulesAndScripts = modifiedSelectedArray;
    setRunSettingsObj({ ...runSettingTempObj });
  }

  function automaticDistributionAllData(jsonObjArray) {
    setAutomaticJsonData([...jsonObjArray]);
    let runSettingTempObj = { ...runSettingsObjRef.current };
    let Obj = {
      multiple: multiple,
      selectedMachines: jsonObjArray,
      executionType: 'DISTRIBUTE',
      distributionMechanism: 'AUTOMATIC',
    };
    runSettingTempObj.machines = Obj;
    runSettingTempObj.selectedModulesAndScripts = modifiedSelectedArray;
    runSettingsGlobalObj.machines = Obj;
    runSettingsGlobalObj.selectedModulesAndScripts = modifiedSelectedArray;
    setRunSettingsObj({ ...runSettingTempObj });
  }

  function manualDistributionAllData(jsonObjArray) {
    setManualJsonData([...jsonObjArray]);
    setManualDistribution([...jsonObjArray]);
    let runSettingTempObj = { ...runSettingsObjRef.current };
    let Obj = {
      multiple: multiple,
      selectedMachines: jsonObjArray,
      executionType: 'DISTRIBUTE',
      distributionMechanism: 'MANUAL',
    };
    runSettingTempObj.machines = Obj;
    runSettingTempObj.selectedModulesAndScripts = modifiedSelectedArray;
    runSettingsGlobalObj.machines = Obj;
    runSettingsGlobalObj.selectedModulesAndScripts = modifiedSelectedArray;
    setRunSettingsObj({ ...runSettingTempObj });
  }

  function changeRadioFunction(selOption) {
    let runSettingTempObj = { ...runSettingsObjRef.current };
    if (selOption === 'single') {
      let Obj = {
        multiple: 'false',
        selectedMachines: singleJsonData,
      };
      runSettingTempObj.machines = Obj;
      setMultiple('false');
    } else if (selOption === 'multiple') {
      let Obj = {
        multiple: 'true',
        selectedMachines: multipleJsonData,
        executionType: 'SEND_SUITE_TO_ALL',
      };
      runSettingTempObj.machines = Obj;
      setMultiple('true');
    } else if (selOption === 'AUTOMATIC') {
      let Obj = {
        multiple: 'true',
        selectedMachines: automaticJsonData,
        executionType: 'DISTRIBUTE',
        distributionMechanism: 'AUTOMATIC',
      };
      runSettingTempObj.machines = Obj;
      setMultiple('true');
    } else if (selOption === 'MANUAL') {
      let Obj = {
        multiple: 'true',
        selectedMachines: manualJsonData,
        executionType: 'DISTRIBUTE',
        distributionMechanism: 'MANUAL',
      };
      runSettingTempObj.machines = Obj;
      setMultiple('true');
    }
    runSettingsGlobalObj = runSettingTempObj;
    setRunSettingsObj({ ...runSettingTempObj });
  }

  function getMachineDetails(data) {
    let obj = {
      multiple: data?.multiple,
    };
    if (data.multiple) {
      obj.executionType = data?.executionType;
      if (data.executionType === 'DISTRIBUTE') {
        obj.distributionMechanism = data?.distributionMechanism;
      }
    }
    obj.selectedMachines = data?.selectedMachines;
    let runSettingTempObj = { ...runSettingsObjRef.current };
    runSettingTempObj.machines = obj;
    setRunSettingsObj({ ...runSettingTempObj });
  }

  function getWaitTimeData(data) {
    let runSettingTempObj = { ...runSettingsObjRef.current };
    runSettingTempObj['waitTime'] = data;
    setWaitTimeData(data);
    runSettingsGlobalObj = runSettingTempObj;
    setRunSettingsObj({ ...runSettingTempObj });
  }

  function getResultSettingsData(data) {
    let runSettingTempObj = { ...runSettingsObjRef.current };
    runSettingTempObj.resultSetting = data;
    setResultSettingsData(data);
    runSettingsGlobalObj = runSettingTempObj;
    setRunSettingsObj({ ...runSettingTempObj });
  }

  function getExecutionTerminationData(data) {
    let runSettingTempObj = { ...runSettingsObjRef.current };
    runSettingTempObj.executionTermination = data;
    setExecutionTerminationData(data);
    runSettingsGlobalObj = runSettingTempObj;
    setRunSettingsObj({ ...runSettingTempObj });
  }

  function getSelectedScripts(moduleTree) {
    let selectedScripts = [];
    if (moduleTree?.children?.length) {
      moduleTree?.children?.forEach((nodeObj) => {
        if (nodeObj.folder) {
          selectedScripts = selectedScripts.concat(getSelectedScripts(nodeObj));
        } else if (!['PRE', 'POST'].includes(nodeObj?.type)) {
          selectedScripts.push(nodeObj.searchKey);
        }
      });
    }
    return selectedScripts;
  }
  const updateRunSettingObject = (selectedModuleAndScript, _machineObj) => {
    _machineObj?.machines?.selectedMachines.forEach((machine, index) => {
      machine?.machineInstances.forEach((machineInstance, miIndex) => {
        const _selectedModules = machineInstance?.distribution?.selectedModules;
        _selectedModules?.forEach((_selectMod, smIndex) => {
          let splittedArray = _selectMod.split('/');
          let selectedScript = splittedArray[splittedArray.length - 1];
          if (!Object.keys(selectedModuleAndScript).includes(selectedScript)) {
            _selectedModules.splice(smIndex, 1);
          }
        });
        if (_selectedModules?.length === 0) machine?.machineInstances.splice(miIndex, 1);
      });
      if (isEmptyValue(machine?.machineInstances)) _machineObj?.machines?.selectedMachines.splice(index, 1);
    });
  };

  function storeRunSettingsDataAndRun(deleteSpillOverMemory = false) {
    let selectedSuite = props?.selectedSuiteObjForExecution;
    if (props?.suiteType !== 'Manual') {
      setRunSettingsManualObj({});
      if (
        runSettingsGlobalObj.selectedModulesAndScripts?.[0]?.children?.length !==
        modifiedSelectedArray?.[0]?.children?.length
      ) {
        runSettingsGlobalObj.selectedModulesAndScripts = modifiedSelectedArray;
      }
      if (
        runSettingsGlobalObj?.machines?.multiple === 'true' &&
        ['DISTRIBUTE', 'SEND_SUITE_TO_ALL'].includes(runSettingsGlobalObj?.machines?.executionType)
      ) {
        updateRunSettingObject(suiteObjectMain.selectedModulesStatus, runSettingsGlobalObj);
      }
    } else {
      if (
        runSettingsManualObj.selectedModulesAndScripts?.[0]?.children?.length !==
        modifiedSelectedArray?.[0]?.children?.length
      ) {
        runSettingsManualObj.selectedModulesAndScripts = modifiedSelectedArray;
      }
      updateRunSettingObject(suiteObjectMain.selectedModulesStatus, runSettingsManualObj);
    }
    if (
      runSettingsManualObj?.machines?.selectedMachines?.length ||
      runSettingsGlobalObj?.machines?.selectedMachines?.length
    ) {
      if (selectedSuite?.suiteType && selectedSuite?.suiteType.toLowerCase() === 'manual') {
        if (getMachineValidation(runSettingsManualObj)) {
          try {
            runSettingsManualObj.machines = getFinalMachineObject(runSettingsManualObj.machines);
            executeManualSuite(selectedSuite?._id, runSettingsManualObj).then((results) => {
              if (results?.data?.responseObject) {
                let payLoadObj = { ...runSettingsManualObj };
                payLoadObj['suiteType'] = 'manual';
                payLoadObj['action'] = 'run';
                payLoadObj['updateSuite'] = makeSettingsDefault;
                createRunSettingsPostReq(selectedSuite?._id, payLoadObj).then((results) => {
                  localStorage.setItem('selectedSuiteObj', JSON.stringify(selectedSuite));
                  localStorage.setItem('urlSuiteId', selectedSuite?._id);
                  history.push(
                    `/execution/suite/${encodeSpecialCharater(selectedSuite?.name)}/suite-dashboard?suiteId=${
                      selectedSuite?._id
                    }&suiteType=${selectedSuite?.suiteType}`
                  );
                });
              } else if (results?.data?.message && results?.data?.message) {
                props.createAlertFun('info', `${results?.data?.message}`);
              }
            });
          } catch (err) {
            console.error(err);
          }
        } else {
          MyAlert.danger('please assign user/s for all the modules');
        }
      } else {
        if (props.modalOperation !== 'schedule') {
          let wrongWaitTimeData = false;
          let wrongExeTerminationData = false;
          let waitTimeValidation = true;
          Object.keys(runSettingsGlobalObj.waitTime).forEach((key) => {
            if (runSettingsGlobalObj.waitTime[key] === '') {
              wrongWaitTimeData = true;
            }
          });
          if (!wrongWaitTimeData) {
            if (getWaitTimeValidationCondition(runSettingsGlobalObj.waitTime)) {
              waitTimeValidation = false;
            }
          }
          Object.keys(runSettingsGlobalObj?.executionTermination).forEach((key) => {
            if (runSettingsGlobalObj?.executionTermination[key] === '') {
              wrongExeTerminationData = true;
            }
          });
          if (getMachineValidation(runSettingsGlobalObj)) {
            if (!wrongWaitTimeData) {
              if (!wrongExeTerminationData) {
                if (waitTimeValidation) {
                  runSettingsGlobalObj['deleteSpillOverMemory'] = deleteSpillOverMemory;
                  executeSuitePostReq(selectedSuite?._id, runSettingsGlobalObj).then((results) => {
                    if (results?.data?.responseObject) {
                      let payLoadObj = { ...runSettingsGlobalObj };
                      payLoadObj['suiteType'] = 'automation';
                      payLoadObj['action'] = 'run';
                      payLoadObj['updateSuite'] = makeSettingsDefault;
                      createRunSettingsPostReq(selectedSuite?._id, payLoadObj).then((results) => {
                        localStorage.setItem('selectedSuiteObj', JSON.stringify(selectedSuite));
                        localStorage.setItem('urlSuiteId', selectedSuite?._id);
                        history.push(
                          `/execution/suite/${encodeSpecialCharater(selectedSuite?.name)}/suite-dashboard?suiteId=${
                            selectedSuite?._id
                          }&suiteType=${selectedSuite?.suiteType}`
                        );
                      });
                    } else if (results?.data?.message === 'Consumed Memory is above 85%') {
                      setMemoryWarningPopup(true);
                    } else {
                      MyAlert.info(results?.data?.message);
                    }
                  });
                }
              } else {
                MyAlert.info(EXECUTION_CONSTANTS.EXECUTION_TERMINATE_DATA_REQUIRED_MSG);
              }
            } else {
              MyAlert.info(EXECUTION_CONSTANTS.WAIT_TIME_DATA_REQUIRED_MSG);
            }
          } else {
            MyAlert.danger(EXECUTION_CONSTANTS.ASSIGN_MACHINE_ALL_MODULE_MSG);
          }
        } else {
          let waitTimeValidation = true;
          let waitTimEmpltyvaluevalidation = true;
          let execitionEmptyValuevalidation = true;
          if (!formik?.values?.Name) {
            setScheduleNameClicked(true);
          }
          if (!runSettingsGlobalObj?.waitTime?.implicitWait) {
            getWaitConfigReq(props?.suiteObjMain?.projectId).then((results) => {
              let waitTimeData = results.data.responseObject;
              let waitTime = {
                implicitWait: JSON.stringify(waitTimeData?.implicitWait),
                explicitlyWait: JSON.stringify(waitTimeData?.explicitWait),
                delayBetweenSteps: JSON.stringify(waitTimeData?.delayBetweenSteps),
                implicitWaitUnit: waitTimeData?.implicitWaitUnit === 'seconds' ? 'Secs' : 'Msecs',
                explicitlyWaitUnit: waitTimeData?.explicitWaitUnit === 'seconds' ? 'Secs' : 'Msecs',
                delayBetweenStepsUnit: waitTimeData?.delayBetweenStepsUnit === 'seconds' ? 'Secs' : 'Msecs',
              };
              runSettingsGlobalObj.waitTime = waitTime;
            });
          }
          if (getWaitTimeValidationCondition(runSettingsGlobalObj.waitTime)) {
            waitTimeValidation = false;
          }
          let tempRunSettingsObj = { ...runSettingsObjRef.current };
          const { implicitWait, explicitlyWait, delayBetweenSteps } = { ...tempRunSettingsObj.waitTime };
          const { terminateScriptIfTakesMoreTime, terminateSuiteIfTakesMoreTime } = {
            ...tempRunSettingsObj.executionTermination,
          };
          if (isEmptyValue(implicitWait) || isEmptyValue(explicitlyWait) || isEmptyValue(delayBetweenSteps)) {
            waitTimEmpltyvaluevalidation = false;
          }
          if (isEmptyValue(terminateScriptIfTakesMoreTime) || isEmptyValue(terminateSuiteIfTakesMoreTime)) {
            execitionEmptyValuevalidation = false;
          }
          setRunSettingsObj(tempRunSettingsObj);

          let scheduleObj = {
            projectId: props.suiteObjMain ? props.suiteObjMain?.projectId : null,
            type: 'SUITE',
            suiteId: props.suiteObjMain ? props.suiteObjMain?.id : null,
            name: formik?.values?.Name,
            scheduleConfiguration: toggleState,
            schedule: {
              suiteName: props.suiteObjMain ? props.suiteObjMain?.name : null,
              repeat: repeatData,
              scheduleDate: convertGMTTimeToUserGivenTime(sheduledDate),
              scheduleTime: convertGMTTimeToUserGivenTime(scheduleTime),
              expireDate: convertGMTTimeToUserGivenTime(expiryDate),
              expireTime: convertGMTTimeToUserGivenTime(expiryTime),
              timezone: timeZoneName,
              estimatedDuration: '',
            },
          };
          if (props?.suiteType !== 'Manual') {
            scheduleObj['suite'] = runSettingsGlobalObj;
          }
          let scheduleAreaInvalid;
          let expiryAreaInvalid;
          setTimeout(() => {
            scheduleAreaInvalid = document?.getElementById('scheduleId')?.getAttribute('aria-invalid');
            expiryAreaInvalid = document?.getElementById('expiryId')?.getAttribute('aria-invalid');
            if (scheduleAreaInvalid === 'true') {
              setScheduleBtnDisable(true);
            } else {
              setScheduleBtnDisable(false);
            }
            if (expiryAreaInvalid === 'true') {
              setExpireBtnDisable(true);
            } else {
              setExpireBtnDisable(false);
            }
            if (isFormValid(scheduleAreaInvalid, expiryAreaInvalid, waitTimeValidation)) {
              if (getMachineValidation(runSettingsGlobalObj)) {
                if (waitTimEmpltyvaluevalidation) {
                  if (execitionEmptyValuevalidation) {
                    setScheduleBtnClicked(true);
                    schedulePostReq(scheduleObj)
                      .then((res) => {
                        if (res.data && res.data.responseObject) {
                          let payLoadObj = { ...runSettingsObjRef.current };
                          payLoadObj['suiteType'] = 'automation';
                          payLoadObj['action'] = 'schedule';
                          createRunSettingsPostReq(selectedSuite?._id, payLoadObj).then((results) => {
                            setRepeat('Once');
                            setScheduleNameClicked(false);
                            props.createAlertFun(
                              'success',
                              `${getTruncatedText(props.suiteObjMain?.name, 25)} scheduled successfully`
                            );
                            history.push(
                              `/execution/suite/${encodeSpecialCharater(
                                props.suiteObjMain?.name
                              )}/suite-scheduled-instance?suiteId=${props.suiteObjMain?._id}`
                            );
                          });
                        } else {
                          if (
                            res?.data?.message &&
                            res?.data?.message.toLowerCase().includes('please connect a device')
                          ) {
                            MyAlert.info(res?.data?.message);
                          } else if (
                            res?.data?.message &&
                            res?.data?.message.toLowerCase().includes('please install a browser')
                          ) {
                            MyAlert.info(res?.data?.message);
                          } else if (
                            res?.data?.message &&
                            res?.data?.message.startsWith('Client') &&
                            res?.data?.message.toLowerCase().includes('is not available')
                          ) {
                            MyAlert.info(res?.data?.message);
                          } else {
                            MyAlert.danger(res?.data?.message);
                          }
                          setScheduleNameClicked(true);
                          setScheduleBtnClicked(false);
                        }
                      })
                      .catch((error) => {
                        setScheduleNameClicked(true);
                        setScheduleBtnClicked(false);
                        console.error('error', error);
                      });
                  } else {
                    MyAlert.info(EXECUTION_CONSTANTS.EXECUTION_TERMINATE_DATA_REQUIRED_MSG);
                  }
                } else {
                  MyAlert.info(EXECUTION_CONSTANTS.WAIT_TIME_DATA_REQUIRED_MSG);
                }
              } else {
                MyAlert.danger(EXECUTION_CONSTANTS.ASSIGN_MACHINE_ALL_MODULE_MSG);
              }
            }
          }, 100);
        }
      }
    } else {
      selectedSuite?.suiteType.toLowerCase() === 'manual'
        ? MyAlert.danger(EXECUTION_CONSTANTS.SELECT_USER_MSG)
        : MyAlert.danger(EXECUTION_CONSTANTS.SELECT_MACHINE_MSG);
    }
    var today = new Date();
    if (
      props.modalOperation === 'schedule' &&
      today.getTime() > sheduledDate.getTime() &&
      scheduleTime < new Date().getTime()
    ) {
      MyAlert.danger(EXECUTION_CONSTANTS.SCHEDULE_DATE_TIME_VALIDATION_MSG);
    }
  }

  const isFormValid = (scheduleAreaInvalid, expiryAreaInvalid, waitTimeValidation) => {
    return (
      scheduleAreaInvalid !== 'true' &&
      expiryAreaInvalid !== 'true' &&
      formik?.values?.Name &&
      formik?.values?.Name?.length > 2 &&
      formik?.values?.Name?.length < 26 &&
      !formik?.errors?.Name &&
      waitTimeValidation
    );
  };

  const scheduleSuiteExecution = () => {
    if (!props.suiteObjMain.hasOwnProperty('machines')) {
      //NOTE : machines details required to create schedule instance
      MyAlert.danger(EXECUTION_CONSTANTS.SELECT_MACHINE_MSG);
      return;
    }

    let scheduleObj = {
      projectId: props.suiteObjMain ? props.suiteObjMain?.projectId : null,
      type: 'SUITE',
      suiteId: props.suiteObjMain ? props.suiteObjMain?.id : null,
      scheduleConfiguration: toggleState,
      name: formik?.values?.Name,
      schedule: {
        suiteName: props.suiteObjMain ? props.suiteObjMain?.name : null,
        repeat: repeatData,
        scheduleDate: convertGMTTimeToUserGivenTime(sheduledDate),
        scheduleTime: convertGMTTimeToUserGivenTime(scheduleTime),
        expireDate: convertGMTTimeToUserGivenTime(expiryDate),
        expireTime: convertGMTTimeToUserGivenTime(expiryTime),
        timezone: timeZoneName,
        estimatedDuration: '',
      },
    };
    let scheduleAreaInvalid;
    let expiryAreaInvalid;
    let waitTimeValidation = true;
    if (!formik?.values?.Name) {
      setScheduleNameClicked(true);
    }
    scheduleAreaInvalid = document?.getElementById('scheduleId')?.getAttribute('aria-invalid');
    expiryAreaInvalid = document?.getElementById('expiryId')?.getAttribute('aria-invalid');
    if (scheduleAreaInvalid === 'true') {
      setScheduleBtnDisable(true);
    } else {
      setScheduleBtnDisable(false);
    }
    if (expiryAreaInvalid === 'true') {
      setExpireBtnDisable(true);
    } else {
      setExpireBtnDisable(false);
    }
    if (isFormValid(scheduleAreaInvalid, expiryAreaInvalid, waitTimeValidation)) {
      if (!isEmptyValue(scheduleTime) && !isEmptyValue(expiryTime)) {
        if (sheduledDate.toISOString() <= expiryDate.toISOString()) {
          schedulePostReq(scheduleObj).then((res) => {
            if (res.data && res.data.responseObject) {
              setOpenModal(false);
              setScheduleNameClicked(false);
              props.createAlertFun(
                'success',
                `${getTruncatedText(props.suiteObjMain?.name, 25)} scheduled successfully`
              );
              history.push(
                `/execution/suite/${encodeSpecialCharater(props.suiteObjMain?.name)}/suite-scheduled-instance?suiteId=${
                  props.suiteObjMain?._id
                }`
              );
            } else {
              MyAlert.danger(res.data.message);
            }
          });
        } else {
          MyAlert.danger(EXECUTION_CONSTANTS.SCHEDULE_DATE_TIME_VALIDATION_MSG);
        }
      } else if (isEmptyValue(scheduleTime) || isEmptyValue(expiryTime)) {
        MyAlert.danger(EXECUTION_CONSTANTS.SCHEDULE_TIME_VALIDATION_CHECK);
      }
    }
  };

  const getMachineValidation = (_machineObj) => {
    if (_machineObj?.machines?.multiple === 'true') {
      if (_machineObj?.machines?.executionType === 'DISTRIBUTE') {
        const selectedScripts = getSelectedScripts({ ...modifiedSelectedArray[0], folder: true });
        let assignedScripts = [];
        _machineObj?.machines?.selectedMachines.forEach((machine) => {
          machine.machineInstances.forEach((machineInstance) => {
            assignedScripts = assignedScripts.concat(machineInstance.distribution.selectedModules);
          });
        });
        if (assignedScripts?.length < selectedScripts?.length) {
          return false;
        }
      } else if (_machineObj?.machines?.executionType === 'SEND_SUITE_TO_ALL') {
        if (_machineObj?.machines?.selectedMachines.length < 1) {
          return false;
        }
      }
    } else {
      if (_machineObj?.machines?.selectedMachines[0]?.machineInstances.length < 1) {
        return false;
      }
    }
    return true;
  };

  const handleManualUserData = (machineData, userMachineObj) => {
    let machineObj = userMachineObj ? { ...userMachineObj } : {};
    machineObj['selectedMachines'] = machineData.map((tempMachine) => {
      let machineObj = {
        clientId: tempMachine.clientId,
      };
      if (userMachineObj?.executionType === 'DISTRIBUTE') {
        let [distribution] = userMachineObj?.distribution?.filter(
          (dist) => dist.clientSystemId === tempMachine.clientId
        );
        tempMachine.distribution = distribution;
      }
      let machineInstances = tempMachine?.machineInstances;
      if (machineInstances?.length) {
        machineInstances.forEach((_mac) => {
          _mac.distribution = tempMachine.distribution;
        });
      } else {
        machineInstances = [{ ...tempMachine }];
      }
      machineObj.machineInstances = machineInstances;
      return machineObj;
    });

    if (userMachineObj?.multiple) {
      machineObj['multiple'] = 'true';
      if (userMachineObj?.executionType !== 'DISTRIBUTE') {
        machineObj['executionType'] = 'SEND_SUITE_TO_ALL';
      }
    } else {
      machineObj['multiple'] = 'false';
      machineObj['executionType'] = 'Sequential';
    }
    if (userMachineObj?.userExecutionType) {
      machineObj['userExecutionType'] = userMachineObj?.userExecutionType;
    }
    let tempObj = {
      machines: machineObj,
    };
    setRunSettingsManualObj({ ...tempObj });
  };

  const handleChange = (event) => {
    setRepeat(event.target.value);
  };

  const handleTimeZoneChange = (event) => {
    let valueTrim = event.nativeEvent.target.innerText.replace(/[\u200B-\u200D\uFEFF]/g, '');
    setTimeZoneDataValue(valueTrim);
    let timeZoneKeyName = Object.keys(props.timeZoneObject).find(
      (key) => props.timeZoneObject[key] === event.nativeEvent.target.innerText
    );
    let sheduleTime = TimeZoneDates(TIMEZONE_CONSTANTS.US_LOCALE_TIME_ZONE, timeZoneKeyName);
    const currentTimeInSelectedTimezone = dayjs().tz(timeZoneKeyName);
    setSheduledDate(sheduleTime.$d);
    setExpiryDate(sheduleTime.$d);
    setTimeZoneName(timeZoneKeyName);
    setScheduleTime(currentTimeInSelectedTimezone.$d);
    setExpiryTime(currentTimeInSelectedTimezone.$d);
  };

  function getTimeForValidation() {
    let d = new Date();
    let time = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
    setValidationMinTime(time);
    setMinHour(d.getHours());
    setMinMinute(d.getMinutes());
  }

  const changeExpiryTime = (newValue) => {
    setExpiryTime(newValue);
  };

  const changeScheduleTime = (newValue) => {
    setScheduleTime(newValue);
  };

  const changeExpiryDate = (date) => {
    setExpiryDate(date);
    setExpiryTime(date);
    checkExpiryValidation();
    checkScheduleValidation();
    checkExpiryDateValidation();
  };
  const changeScheduleDate = (date) => {
    setSheduledDate(date);
    setScheduleTime(date);
    checkExpiryValidation();
    checkScheduleValidation();
    checkExpiryDateValidation();
    setTimeout(() => {
      let htmlEleForError = document.getElementById('date-picker-inline-expiredate-helper-text');
      if (htmlEleForError) {
        htmlEleForError.innerHTML = EXECUTION_CONSTANTS.SCHEDULE_EXPIRY_DATE_VALIDATION_WARNING;
      }
    }, 100);
  };
  const toggleChange = () => {
    setToggleState(!toggleState);
  };

  function checkExpiryValidation() {
    setTimeout(() => {
      let expiry = document?.getElementById('expiryId');
      let expiryAreaInvalid = expiry?.getAttribute('aria-invalid');
      expiry && expiry?.value > '23:59' ? setScheduleBtnClicked(true) : setScheduleBtnClicked(false);
      if (expiryAreaInvalid === 'true') {
        setExpireBtnDisable(true);
      } else {
        setExpireBtnDisable(false);
      }
    }, 100);
  }

  function checkExpiryDateValidation() {
    setTimeout(() => {
      let expiryDateInvalid = document?.getElementById('date-picker-inline-expiredate-helper-text');
      if (expiryDateInvalid) {
        setExpiryDateDisable(true);
      } else {
        setExpiryDateDisable(false);
      }
    }, 100);
  }

  function checkScheduleValidation() {
    setTimeout(() => {
      let schedule = document?.getElementById('scheduleId');
      let scheduleAreaInvalid = schedule?.getAttribute('aria-invalid');
      schedule && schedule?.value > '23:59' ? setScheduleBtnClicked(true) : setScheduleBtnClicked(false);
      if (scheduleAreaInvalid === 'true') {
        setScheduleBtnDisable(true);
      } else {
        setScheduleBtnDisable(false);
      }
    }, 100);
  }

  const runModalAction = () => {
    if (makeSettingsDefault) {
      return 'Save and Run';
    } else if (props.modalOperation === 'schedule') {
      return 'Schedule';
    }
    return 'Run';
  };

  const viewScripts = () => {
    if (!showScriptsSection) {
      setIsTreeLoading(true);
    }
    setTimeout(() => {
      setShowScriptsSection(!showScriptsSection);
    }, 1000);
  };

  const handleClickSchedule = () => {
    if (runModalAction() === 'Schedule' && !toggleState) {
      scheduleSuiteExecution();
    } else {
      storeRunSettingsDataAndRun();
    }
  };
  const renderScriptTreeSection = () => {
    return (
      <div className="border border-white">
        <div className={`bg-gray-100 p-2 ${props.modalOperation ? '' : 'mt-3'}`}>
          <span className="run-sttings-modal-sub-header-style ml-3">Scripts</span>
          {isTreeLoading ? (
            <span onClick={viewScripts}>
              <ArrowDropDown color="primary" fontSize="large" />
            </span>
          ) : showScriptsSection ? (
            <span onClick={viewScripts}>
              <ArrowDropDown color="primary" fontSize="large" />
            </span>
          ) : (
            <span onClick={viewScripts}>
              <ArrowRight color="primary" fontSize="large" />
            </span>
          )}
        </div>
        {isTreeLoading && (
          <>
            <div className="content_area_header_style">
              <div className="flex items-center">
                <label className="main-header-label float-left ">{EXECUTION_CONSTANTS.MODULES_SCRIPTS_LABLE}</label>
              </div>
            </div>
            <div className="grid h-80">
              <div className="flex flex-1 justify-center items-center">Loading...</div>
            </div>
          </>
        )}

        {showScriptsSection && (
          <ScriptTreeSection
            runSettingsObj={runSettingsObj}
            setRunSettingsObj={setRunSettingsObj}
            suiteObjectMain={suiteObjectMain}
            setSuiteObjectMain={setSuiteObjectMain}
            runSettingsManualObj={runSettingsManualObj}
            setRunSettingsManualObject={setRunSettingsManualObj}
            runSettingsGlobalObj={runSettingsGlobalObj}
            allSelectedNodes={allSelectedNodes}
            setAllSelectedNodes={setAllSelectedNodes}
            setModifiedSelectedArray={setModifiedSelectedArray}
            selectedModulesStatus={selectedModulesStatus}
            setSelectedModulesStatus={setSelectedModulesStatus}
            suiteType={props?.suiteType}
            hasTreeLoaded={hasTreeLoaded}
          />
        )}
      </div>
    );
  };
  const getDisableStatus = () => {
    return (
      scheduleBtnClicked ||
      (showScriptsSection && allSelectedNodes?.length === 0) ||
      (showScriptsSection === false && allSelectedNodes?.length === 0)
    );
  };
  let showMachineDetails = () => {
    return((!showSystemSection && props.modalOperation === 'schedule') ||
    (isEmptyValue(props.modalOperation) && showSystemSection)) 
  }
  return (
    <Modal
      isOpen={openModal}
      className="popup-height-runsettings focus:outline-none"
      style={customStylesPage}
      onRequestClose={() => {
        setOpenModal(false);
        props.closeDeviceModal(false);
      }}
    >
      {memoryWarningPopup && (
        <MemoryWarningPopup
          handleCancel={() => setMemoryWarningPopup(false)}
          handleContinue={storeRunSettingsDataAndRun}
          isOpen={memoryWarningPopup}
        />
      )}
      <div className={`testEnv-header-section`}>
        {props.modalOperation !== 'schedule' ? (
          <span className={`ml-3 fontPoppinsSemiboldMd`}>
            {' '}
            {props?.suiteType.toLowerCase() !== 'manual' ? 'Execution Run Settings' : 'User Settings'}
          </span>
        ) : (
          <Tooltip title={`${props.suiteObjMain.name}`}>
            <span className="text-white text-left tracking-normal opacity-100 fontPoppinsSemiboldMd ml-2">
              {' '}
              Schedule Details- {getTruncatedText(props?.suiteObjMain.name, 25)}
            </span>
          </Tooltip>
        )}
        <span className="float-right flex flex-row ">
          <span className="parallel_run_status_style_execution">
            {props.modalOperation !== 'schedule' && <ParallelRunExecutionStatus fromModal={true} />}
          </span>

          <button
            onClick={() => {
              setOpenModal(false);
              props.closeDeviceModal(false);
            }}
            type="button"
            className="float-right mr-4 text-white hover:ring-2 ring-white focus:ring-2 rounded-full"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </span>
      </div>
      {props?.suiteType.toLowerCase() !== 'manual' ? (
        <div
          id="journal-scroll"
          className="machine_specific overflow-scroll border-b mb-4 fontPoppinsRegularLg qrs_modal_body bg-white"
        >
          <div className="alert-modal-position-style">
            <AlertContatiner />
          </div>
          {props?.modalOperation === 'schedule' && (
            <div className="border border-white">
              {showScheduleSection && (
                <form onSubmit={formik.handleSubmit}>
                  <div className="grid grid-cols-2  mt-2 pb-3 left-border-style">
                    <div>
                      <Label required={true} label={TIMEZONE_CONSTANTS.NAME} />
                      <br></br>
                      <Input
                        className="w-1/2 fontPoppinsRegularLg ml-2 schedule-name-style"
                        type="text"
                        name="Name"
                        id="Name"
                        autoComplete="off"
                        placeholder={`Enter the name of the scheduler`}
                        onChange={formik.handleChange}
                        onClick={() => setScheduleNameClicked(true)}
                      />
                      {formik.errors.Name && scheduleNameClicked ? (
                        <div className="error-message md:mt-1 ml-2 fontPoppinsRegularSm">{formik.errors.Name}</div>
                      ) : scheduleNameClicked && !formik.values.Name ? (
                        <div className="error-message md:mt-1 ml-2 fontPoppinsRegularSm">Required</div>
                      ) : null}
                    </div>
                    <div>
                      <div>
                        <Label required={true} label={TIMEZONE_CONSTANTS.RECURRENCE} />
                        <div className="ml-2">
                          <Select
                            MenuProps={{
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                            }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={repeatData}
                            onChange={handleChange}
                            className="repeat-field-width"
                          >
                            {scheduleRepeatArray.map((scheduleVal) => (
                              <MenuItem value={scheduleVal} className={classes.selectedItem}>{scheduleVal}</MenuItem>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2  mt-2 pb-3 left-border-style">
                    <div className="schedule-style">
                      <Label required={true} label={TIMEZONE_CONSTANTS.SCHEDULE_DATE_TIME} />
                      <div className="flex flex-row mr-3">
                        <MuiPickersUtilsProvider utils={DateFnsUtils} timeZone={timeZoneInfo.timeZone}>
                          <Grid container className="ml-7">
                            <KeyboardDatePicker
                              disableToolbar
                              InputProps={{ readOnly: true }}
                              variant="inline"
                              format={DATE_FORMATS.DATE_WITH_MONTH_NAME}
                              margin="normal"
                              minDate={minDate}
                              id="date-picker-inline"
                              value={sheduledDate}
                              onChange={changeScheduleDate}
                              autoOk={true}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Stack spacing={3}>
                            <TimePicker
                              ampm={false}
                              openTo="hours"
                              views={['hours', 'minutes']}
                              mask="__:__"
                              inputFormat="HH:mm"
                              minTime={compareDates(expiryDate, sheduledDate, scheduleTime)}
                              shouldDisableTime={(timeValue, clockType) => {
                                if (
                                  JSON.stringify(sheduledDate)?.substring(0, 11) ===
                                  JSON?.stringify(today)?.substring(0, 11)
                                ) {
                                  let scheduledHour = moment(new Date(scheduleTime)).format('HH');
                                  if (JSON.stringify(minHour) === scheduledHour) {
                                    if (clockType === 'minutes') {
                                      return timeValue <= minMinute;
                                    }
                                  }
                                }
                              }}
                              value={scheduleTime}
                              onChange={(newValue) => {
                                changeScheduleTime(newValue);
                                checkScheduleValidation();
                              }}
                              renderInput={(params) => <TextField id="scheduleId" {...params} />}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>
                      {!scheduleTime ? <div className="schedule-no-data-style">Select Time</div> : null}
                    </div>
                    <div className="expire-style">
                      <Label required={true} label={TIMEZONE_CONSTANTS.EXPIRE_DATE_TIME} />
                      <div className="flex flex-row mr-3 ml-2">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container className=" ml-4">
                            <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format={DATE_FORMATS.DATE_WITH_MONTH_NAME}
                              margin="normal"
                              InputProps={{ readOnly: true }}
                              minDate={new Date(sheduledDate)}
                              id="date-picker-inline-expiredate"
                              value={expiryDate}
                              onChange={changeExpiryDate}
                              autoOk={true}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Stack spacing={3}>
                            <TimePicker
                              ampm={false}
                              openTo="hours"
                              views={['hours', 'minutes']}
                              inputFormat="HH:mm"
                              minTime={compareDates(expiryDate, sheduledDate, scheduleTime)}
                              shouldDisableTime={(timeValue, clockType) => {
                                if (
                                  JSON.stringify(sheduledDate)?.substring(0, 11) ===
                                  JSON.stringify(expiryDate)?.substring(0, 11)
                                ) {
                                  let scheduledHour = moment(new Date(scheduleTime)).format('HH');
                                  let expiredHour = moment(new Date(expiryTime)).format('HH');
                                  let minExpMinute = moment(new Date(scheduleTime)).format('mm');
                                  if (expiredHour === scheduledHour) {
                                    if (clockType === 'minutes') {
                                      return timeValue <= minExpMinute;
                                    }
                                  }
                                }
                              }}
                              mask="__:__"
                              value={expiryTime}
                              onChange={(newValue) => {
                                changeExpiryTime(newValue);
                                checkExpiryValidation();
                              }}
                              renderInput={(params) => <TextField id="expiryId" {...params} />}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>
                      {!expiryTime ? <div className="expiry-no-data-style">Select Time</div> : null}
                    </div>
                  </div>
                  <div className="grid grid-cols-2  mt-2 pb-3 left-border-style">
                    <div>
                      <Label required={true} label={TIMEZONE_CONSTANTS.TIME_ZONE} />
                      <div className="className={classes.formControl} ml-2">
                        <Select
                          MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                          }}
                          labelId="demo-simple-select-timezone"
                          id="demo-simple-select-timezone"
                          value={timezonedataValue}
                          onChange={handleTimeZoneChange}
                          className="w-77 max-h-10"
                          renderValue={(timeZoneData) => timeZoneData}
                        >
                          <div className="timezone-height">
                            {timeZoneArrayValues?.map((timezoneVal) => (
                              <MenuItem value={timezoneVal}>{timezoneVal}</MenuItem>
                            ))}
                          </div>
                        </Select>
                      </div>
                    </div>
                    <div>
                      <Label required={true} label={TIMEZONE_CONSTANTS.SCHEDULE_CONFIGURATION} />
                      <div className="flex gap-2">
                        <div className="mt-2">
                          <Toggle
                            checked={toggleState}
                            id="toggle"
                            type="default"
                            variant="primary"
                            onChange={toggleChange}
                          />
                        </div>
                        <div className="mt-2 fontPoppinsMediumSm text-color">
                          {toggleState
                            ? TOGGLE_BUTTON_NAME.TRUE_TOGGLE_BUTTON_NAME
                            : TOGGLE_BUTTON_NAME.FALSE_TOGGLE_BUTTON_NAME}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          )}
          {(isEmptyValue(props.modalOperation) || toggleState) && renderScriptTreeSection()}
          {(isEmptyValue(props.modalOperation) || toggleState) && (
            <>
              <div className="border border-white">
                <div className="bg-gray-100 p-2">
                  <span className="run-sttings-modal-sub-header-style ml-3">Execution Mode</span>
                  {showMachineDetails() ? (
                    <span onClick={viewSystem}>
                      <ArrowDropDown color="primary" fontSize="large" />
                    </span>
                  ) : (
                    <span onClick={viewSystem}>
                      <ArrowRight color="primary" fontSize="large" />
                    </span>
                  )}
                </div>
                {showMachineDetails() && (
                  <MachineLandingPage
                    getMachineDetails={getMachineDetails}
                    suiteObjMain={props.suiteObjMain}
                    multiple={multiple}
                    setMultiple={setMultiple}
                    rootData={props?.rootData}
                    distributionMechanism={distributionMechanism}
                    setDistributionMechanism={setDistributionMechanism}
                    createAlertFun={props.createAlertFun}
                    singleMachineAllData={singleMachineAllData}
                    singleJsonData={singleJsonData}
                    multipleMachineAllData={multipleMachineAllData}
                    multipleJsonData={multipleJsonData}
                    automaticDistributionAllData={automaticDistributionAllData}
                    automaticJsonData={automaticJsonData}
                    manualJsonData={manualJsonData}
                    manualDistributionAllData={manualDistributionAllData}
                    manualDistribution={manualDistribution}
                    changeRadioFunction={changeRadioFunction}
                    moduleArray={allSelectedNodes}
                    numberOfParallelRuns={props?.numberOfParallelRuns}
                    type={'execution'}
                  />
                )}
              </div>
              <div className="border border-white">
                <div className="bg-gray-100 p-1">
                  <span className="run-sttings-modal-sub-header-style ml-3">Wait Time</span>
                  {showWaitSection ? (
                    <span onClick={viewWaitTime}>
                      <ArrowDropDown color="primary" fontSize="large" />
                    </span>
                  ) : (
                    <span onClick={viewWaitTime}>
                      <ArrowRight color="primary" fontSize="large" />
                    </span>
                  )}
                </div>
                {showWaitSection ? (
                  <WaitTime
                    getWaitTimeData={getWaitTimeData}
                    waitTimeData={waitTimeData}
                    rerunMode={props?.rerunMode}
                  />
                ) : null}
              </div>
              <div className="border border-white">
                <div className="bg-gray-100 p-1">
                  <span className="run-sttings-modal-sub-header-style ml-3">Result Settings</span>
                  {showResSection ? (
                    <span onClick={viewResult}>
                      <ArrowDropDown color="primary" fontSize="large" />
                    </span>
                  ) : (
                    <span onClick={viewResult}>
                      <ArrowRight color="primary" fontSize="large" />
                    </span>
                  )}
                </div>
                {showResSection ? (
                  <ResultSettings
                    getResultSettingsData={getResultSettingsData}
                    resultSettingsData={resultSettingsData}
                    rerunMode={props?.rerunMode}
                  />
                ) : null}
              </div>
              <div className="border border-white">
                <div className="bg-gray-100 p-1 mb-3">
                  <span className="run-sttings-modal-sub-header-style ml-3">Execution Termination</span>
                  {showExecutionSection ? (
                    <span onClick={viewExecution}>
                      <ArrowDropDown color="primary" fontSize="large" />
                    </span>
                  ) : (
                    <span onClick={viewExecution}>
                      <ArrowRight color="primary" fontSize="large" />
                    </span>
                  )}
                </div>
                {showExecutionSection ? (
                  <ExecutionTermination
                    getExecutionTerminationData={getExecutionTerminationData}
                    executionTerminationData={executionTerminationData}
                    rerunMode={props?.rerunMode}
                  />
                ) : null}
              </div>
            </>
          )}
        </div>
      ) : (
        <div
          id="journal-scroll"
          className="machine_specific border-b overflow-scroll mt-3 mb-4 fontPoppinsRegularLg qrs_modal_body"
        >
          <div className="alert-variable-modal-position-style">
            <AlertContatiner />
          </div>
          {renderScriptTreeSection()}
          <div className="border border-white">
            <UserMachinePage
              handleManualUserData={handleManualUserData}
              suiteObjMain={props.suiteObjMain}
              multiple={multiple}
              rerunMode={props?.rerunMode}
              moduleArray={allSelectedNodes}
              rootData={props?.rootData}
              runSettingsManualObject={props.runSettingsManualObject}
            ></UserMachinePage>
          </div>
        </div>
      )}
      <div className="float-right mr-4 pb-3">
        <div className="flex justify-between items-center px-3">
          {props.modalOperation !== 'schedule' && (
            <>
              <input
                type="checkbox"
                name="defaultSettings"
                className={!props.nodeSelected ? ' text-xs rounded' : 'text-xs rounded'}
                checked={makeSettingsDefault}
                defaultChecked={makeSettingsDefault}
                onChange={(e) => setMakeSettingsDefault(e.target.checked)}
              />
              <label htmlFor="default-settings" className="font-sans text-sm text-black fontPoppinsRegularSm mx-2">
                {EXECUTION_CONSTANTS.MAKE_EXEC_SETTINGS_DEFAULT}
              </label>
            </>
          )}
          <div className="mx-2">
            <button
              onClick={() => {
                setOpenModal(false);
                props.closeDeviceModal(false);
              }}
              className="gray-btn mr-3 ml-3"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="primary-btn"
              onClick={(e) => {
                e.preventDefault();
                handleClickSchedule();
              }}
              disabled={getDisableStatus()}
            >
              {runModalAction()}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RunSettingsModal;

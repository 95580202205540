import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import { MenuItem, Select, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { addParameter, getDataTypes, updateParameter } from '../../../../../api/api_services';
import { fontPoppins } from '@src/css_config/fontConstants';
import { colors } from '@src/css_config/colorConstants.js';

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: 149,
    borderRadius: 8,
    ...fontPoppins.rMd,
    '&:focus': {
      borderRadius: 12,
      background: 'none',
    },
  },
  menuPaper: {
    maxHeight: 150,
    ...fontPoppins.rMd,
  },
  menulist: {
    overflowY: 'auto',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    height: '120px',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colors.soft_blue,
    },
    paddingTop: 15,
    paddingBottom: 0,
    ...fontPoppins.rMd,
    background: 'none',
    '& li': {
      ...fontPoppins.rMd,
      color: colors.grey_dark,
    },
    '& li:hover': {
      background: colors.sky_blue_dark,
      color: colors.blue_dark,
    },
    '& li.Mui-selected': {
      color: colors.blue_dark,
      background: colors.sky_blue_dark,
    },
    '& li.Mui-selected:hover': {
      background: colors.sky_blue_dark,
      color: colors.blue_dark,
    },
  },
}));

const usePlaceholderStyles = makeStyles((theme) => ({
  placeholder: {
    color: colors.light_gray_100,
    fontSize: 12,
  },
}));

function ParamterModal(props) {
  const classes = useStyles();
  const search = window.location.search;
  const id = new URLSearchParams(search).get('id');
  const parentId = new URLSearchParams(search).get('parentId');
  const [openModal, setOpenModal] = useState(true);
  const [dataTypes, setDataTypes] = useState([]);
  let initialValues;
  useEffect(() => {
    getDataTypes().then((res) => {
      if (res.data.responseObject[0]) {
        let dataTypesArray = [];
        let dataTypes = res.data.responseObject[0].dataTypes;
        for (var key in dataTypes) {
          if (dataTypes.hasOwnProperty(key)) {
            dataTypesArray.push(dataTypes[key].charAt(0).toUpperCase() + dataTypes[key].slice(1));
          }
        }
        dataTypesArray.sort();
        setDataTypes(dataTypesArray);
      }
    });
  }, []);
  if (props.mode !== 'ADD') {
    initialValues = {
      name: props.data.name,
      type: props.data.type,
    };
  } else {
    initialValues = {
      name: '',
      type: '',
    };
  }
  const validationSchema = yup.object({
    name: yup
      .string()
      .required('Name is required')
      .matches(/^[-_A-Za-z0-9 ]*$|^[-_A-Za-z0-9 ][A-Za-z0-9 ]*[-_A-Za-z-_]$/, 'Parameter name should be alphanumeric')
      .min(3, 'Parameter name must be between 3 and 25 characters')
      .max(25, 'Parameter name must be between 3 and 25 characters'),

    type: yup.string().ensure().required('Type is required'),
  });
  const formikDetails = useFormik({
    initialValues,
    validationSchema,
  });

  let errorExistForType = false;
  if (formikDetails.values.type === '' && formikDetails.touched.type) {
    errorExistForType = true;
  }
  const updateParameters = () => {
    let requestBody = {
      id: props.data._id,
      name: formikDetails.values.name,
    };
    updateParameter(parentId, id, requestBody).then((response) => {
      if (response?.data?.responseCode === 200) {
        setOpenModal(false);
        props.closeModal(false);
        if (props.reloadTree) {
          props.reloadTree(true);
          props.MyAlert.success(`${requestBody.name} Parameter updated successfully`);
        }
        if (props.onUpdateUpdateValue) {
          props.onUpdateUpdateValue(response.data.responseObject, 'STEPGROUPINPUT');
        }
      } else if (response?.data?.responseCode === 400) {
        formikDetails.setFieldError('name', response.data.message);
      }
    });
  };
  const createParameter = () => {
    let requestBody = {
      name: formikDetails.values.name,
      type: formikDetails.values.type,
    };
    addParameter(parentId, id, requestBody).then((response) => {
      if (response.data.responseCode === 200) {
        setOpenModal(false);
        props.closeModal(false);
        props.reloadTree(true);
        props.MyAlert.success(`${requestBody.name} Parameter created successfully`);
      } else if (response.data.responseCode === 400) {
        formikDetails.setFieldError('name', response.data.message);
      }
    });
  };
  const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
  };
  return (
    <div>
      <Modal
        open={openModal}
        onClose={() => {
          props.closeModal(false);
          setOpenModal(false);
        }}
        className="modal-dialog"
      >
        <div className="modal-container parameter-modal">
          <div className="modal-header">
            {props.mode === 'ADD' || props.mode === 'ADD_SUB' ? (
              <h2 className="title"> Create Parameter</h2>
            ) : (
              <div className="title title-trim" title={props.data.name}>
                <h2 className="title"> Edit Parameter - {props.data.name}</h2>
              </div>
            )}
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                props.closeModal(false);
                setOpenModal(false);
              }}
            >
              <img
                style={{
                  cursor: 'pointer',
                }}
                src="/assets/images/close_black.svg"
                alt=""
                height="25px"
                width="25px"
              />{' '}
            </IconButton>
          </div>
          <div className="modal-body mt-2">
            <form onSubmit={formikDetails.handleSubmit}>
              <div className="grid grid-cols-2">
                <div className="">
                  <div className="">
                    <div className="rs-user-input-container">
                      <TextField
                        error={formikDetails.errors.name && formikDetails.touched.name}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label={
                          <>
                            {' '}
                            <span className="text-red-400 mr-1">&#42;</span>Name
                          </>
                        }
                        autoComplete="off"
                        className="w-11/12"
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Enter parameter name"
                        onBlur={formikDetails.handleBlur}
                        onChange={formikDetails.handleChange}
                        value={formikDetails.values.name}
                      />
                    </div>
                    {formikDetails.errors.name && formikDetails.touched.name ? (
                      <div className="errorMessage">{formikDetails.errors.name}</div>
                    ) : null}
                  </div>
                </div>
                <div className="">
                  <div className="-mt-3 ml-6">
                    <label
                      htmlFor="type"
                      className={
                        !errorExistForType
                          ? 'input-filed-label-style-common '
                          : 'input-filed-label-style-common-withError'
                      }
                    >
                      <span className="text-red-400">&#42;</span>Type
                    </label>
                    <div className="">
                      <Select
                        classes={{ root: classes.select }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          classes: {
                            paper: classes.menuPaper,
                            list: classes.menulist,
                          },
                          getContentAnchorEl: null,
                        }}
                        error={errorExistForType}
                        name="type"
                        displayEmpty
                        value={formikDetails.values.type}
                        disabled={props.mode !== 'ADD' ? true : false}
                        onBlur={formikDetails.handleBlur}
                        onChange={formikDetails.handleChange}
                        onSelect={formikDetails.handleChange}
                        className="w-full"
                        renderValue={
                          formikDetails.values.type !== ''
                            ? undefined
                            : () => <Placeholder>Search and select type</Placeholder>
                        }
                      >
                        {dataTypes.map((data, index) => (
                          <MenuItem className="fontPoppinsRegularMd" value={data} key={index}>
                            {data}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    {formikDetails.errors.type && formikDetails.touched.type ? (
                      <div className="errorMessage">{formikDetails.errors.type}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              className="gray-btn mr-3"
              onClick={() => {
                props.closeModal(false);
                setOpenModal(false);
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="primary-btn"
              onClick={() => {
                formikDetails.setTouched({
                  name: true,
                  type: true,
                });
                if (
                  formikDetails.values.name !== '' &&
                  formikDetails.values.type !== '' &&
                  formikDetails.errors.name !== 'Parameter name should be alphanumeric' &&
                  formikDetails.errors.name !== 'Parameter name must be between 3 and 25 characters'
                ) {
                  if (props.mode === 'ADD') {
                    createParameter();
                  } else {
                    updateParameters();
                  }
                }
              }}
            >
              {props.mode === 'ADD' ? 'Create' : 'Update'}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ParamterModal;

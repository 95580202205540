const CUSTOM_GRAPH_ELEMENTS = [
  {
    label: 'Link',
    name: 'link',
  },
  {
    label: 'Text Field',
    name: 'textfield',
  },
  {
    label: 'Icon',
    name: 'icon',
  },
  {
    label: 'Button',
    name: 'button',
  },
  {
    label: 'Radio Button',
    name: 'radioButton',
  },
  {
    label: 'Text',
    name: 'text',
  },
  {
    label: 'Textarea',
    name: 'textarea',
  },
  {
    label: 'Image',
    name: 'image',
  },
  {
    label: 'Dropdown',
    name: 'dropdown',
  },
  {
    label: 'Checkbox',
    name: 'checkbox',
  },
  {
    label: 'Tab',
    name: 'tab',
  },
  {
    label: 'Action Overflow button',
    name: 'action overflow button',
  },
  {
    label: 'Hamburger menu',
    name: 'hamburger icon',
  },
  {
    label: 'Toggle Button',
    name: 'toggle button',
  },
  {
    label: 'Suggestion',
    name: 'suggestion',
  },
  {
    label: 'Time Picker',
    name: 'time picker',
  },
  {
    label: 'Date Picker',
    name: 'date picker',
  },
  {
    label: 'Toaster Message',
    name: 'toaster message',
  },
  {
    label: 'Card',
    name: 'card',
  },
  {
    label: 'Tooltip',
    name: 'tooltip',
  },
  {
    label: 'Option',
    name: 'option',
  },
  {
    label: 'Calender',
    name: 'calender',
  },
  {
    label: 'Sliders',
    name: 'sliders',
  },
];

export const CUSTOM_GRAPH_WEB_PLATFORM = [
  {
    value: 'Web',
    name: 'Web',
  },
];
export const CUSTOM_GRAPH_MOBILE_PLATFORM = [
  {
    value: 'Android',
    name: 'Android',
  },
  {
    value: 'iOS',
    name: 'iOS',
  },
  {
    value: 'Mobile',
    name: 'Mobile',
  },
];
export const CUSTOM_GRAPH_PLATFORM = [
  {
    value: 'Web',
    name: 'Web',
  },
  {
    value: 'Android',
    name: 'Android',
  },
  {
    value: 'iOS',
    name: 'iOS',
  },
  {
    value: 'Mobile',
    name: 'Mobile',
  },
  {
    value: 'MobileWeb',
    name: 'MobileWeb',
  },
];

export const getCustomGraphElementType = (projectType) => {
  if (['Web', 'Web & Mobile', 'MS Dynamics', 'Salesforce'].includes(projectType)) {
    return [
      ...CUSTOM_GRAPH_ELEMENTS,
      {
        label: 'Popup',
        name: 'popup',
      },
      {
        label: 'Frame',
        name: 'frame',
      },
    ];
  }

  if (['Mobile', 'Web & Mobile', 'MS Dynamics', 'Salesforce'].includes(projectType)) {
    return [
      ...CUSTOM_GRAPH_ELEMENTS,
      {
        label: 'Steppers',
        name: 'steppers',
      },
    ];
  }
};

import React, { useEffect, useState, useContext } from 'react';
import ReactTable from '@src/pages/common/Table/ReactTable';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Tooltip } from '@material-ui/core';
import { deleteJiraBasedOnID, getAllJiraList } from '@api/api_services';
import DeleteModal from '@pages/common/delete_modal';
import JiraIntegrationModal from './jira_integration_modal';
import JiraIntegrationDetails from './jira_integration_details';
import { CommonLoader } from '@src/pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import ContextMenu from '@pages/common/context_menu';
import SearchBar from '@pages/analytics/common/search-bar';
import { HighlightText, isEmptyValue, isValueIncludes } from '@util/common_utils';
import NoResultsFound from '@pages/common/Table/NoResultFound';

function JiraIntegrationTable(props) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [message, setMessage] = useState('');
  const [reloadTree, setReloadTree] = useState(true);
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  let [isLoading, setIsLoading] = useState(false);
  const [account, setAccount] = useState([]);
  const [accountData, setAccountData] = useState();
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [mode, setMode] = useState('');
  let [isHighlight, setIsHighlight] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');

  const contextMenuData = [
    {
      option: 'Details',
    },
  ];

  function contextMenuFunction(event, ele) {
    if (event.target.textContent === 'Details') {
      setAccountData(ele);
      setOpenDetailsModal(true);
    }
  }

  let reloadpage = () => {
    setReloadTree(true);
  };
  useEffect(() => {
    if (reloadTree) {
      getAllJira();
    }
    setReloadTree(false);
  }, [reloadTree]);

  const getAllJira = () => {
    setIsLoading(true);
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    getAllJiraList()
      .then((response) => {
        stopProgress();
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 10,
        });
        if (response.data.responseObject) {
          response.data.responseObject.map((res, i) => {
            res.Actions = [];
            res.Actions.push(
              <div className="actionIcons">
                <Tooltip title="Edit">
                  <EditOutlinedIcon
                    className="float-left cursor-pointer text-base mr-3 text-blue-700"
                    onClick={() => {
                      setMode('EDIT');
                      setAccountData(res);
                      setOpenAccountModal(true);
                    }}
                  />
                </Tooltip>
                <Tooltip title="Delete">
                  <DeleteOutlinedIcon
                    className="float-left cursor-pointer text-base mr-3 text-blue-700"
                    onClick={() => {
                      setAccountData(res);
                      setMessage(`${res.domain} account`);
                      setOpenDeleteModal(true);
                    }}
                  />
                </Tooltip>
                <div className="float-left project-action text-blue-700 text-base cursor-pointer">
                  <ContextMenu
                    contextMenuData={res ? contextMenuData : [...contextMenuData]}
                    func={(e) => {
                      contextMenuFunction(e, res);
                    }}
                    configSection={true}
                  />
                </div>
              </div>
            );
            res.slNo = i + 1;
          });
          setAccount((d) => response.data.responseObject);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setAccount([]);
        }
      })
      .catch((err) => {
        resetProgress();
        console.error('GET_JIRA_LIST :', err);
      });
  };

  const closeModal = (value) => {
    if (openAccountModal) {
      setOpenAccountModal(false);
    } else if (openDeleteModal) {
      setOpenDeleteModal(false);
    } else if (openDetailsModal) {
      setOpenDetailsModal(false);
    }
  };

  // TODO :: handle Search filter :: START
  const [userSearched, setuserSearched] = useState('');
  const [data, setData] = useState([]);
  let [openSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    setData(account);
    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [account]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(account);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setData(account);
    setSearchTextHighlight('');
    setIsHighlight(!isHighlight);
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = account?.filter(({ accountId, serverUrl, userName, domain, createdByUname }) => {
          return (
            isValueIncludes(e, accountId) ||
            isValueIncludes(e, serverUrl) ||
            isValueIncludes(e, domain) ||
            isValueIncludes(e, userName) ||
            isValueIncludes(e, createdByUname)
          );
        });
        setData(newData);
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  // TODO :: handle Search filter :: END

  const columns = React.useMemo(
    () => [
      {
        Header: 'ACCOUNT ID',
        accessor: 'accountId',
        disableFilters: true,
        Cell: (e) => (
          <div className=" float-left name-trimmer" title={e.value}>
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'DOMAIN',
        accessor: 'domain',
        disableFilters: true,
        Cell: (e) => (
          <div
            className=" float-left name-trimmer  overflow-hidden overflow-ellipsis whitespace-nowrap w-20"
            title={e.value}
          >
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'SERVER URL',
        accessor: 'serverUrl',
        disableFilters: true,
        Cell: ({ value }) => (
          <div className="table-non-link-color-common float-left name-trimmer" title={value}>
            <HighlightText text={value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'USERNAME',
        accessor: 'userName',
        disableFilters: true,
        Cell: ({ value }) => (
          <div className="table-non-link-color-common name-trimmer " title={value}>
            <HighlightText text={value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'API TOKEN',
        accessor: 'apiToken',
        disableFilters: true,
        Cell: (e) => (
          <span className="float-left encode">{e.value.length > 16 ? e.value.substring(0, 16) + '...' : e.value}</span>
        ),
      },
      {
        Header: 'CREATED BY',
        accessor: 'createdByUname',
        disableFilters: true,
        Cell: ({ value }) => (
          <Tooltip title={value} placement="top">
            <div className="table-non-link-color-common name-trimmer overflow-hidden overflow-ellipsis whitespace-nowrap w-20">
              <HighlightText text={value} highlight={searchTextHighlight} />
            </div>
          </Tooltip>
        ),
      },
      {
        Header: 'CREATED ON',
        accessor: 'createdOn',
        disableFilters: true,
        Cell: ({ value }) => (
          <div className="table-non-link-color-common float-left" title={value}>
            {value.length > 16 ? value.substring(0, 16) + '...' : value}
          </div>
        ),
      },
      {
        Header: 'ACTIONS',
        accessor: 'Actions',
        disableFilters: true,
        Cell: ({ value }) => <div className="w-28 table-non-link-color-common table-font-style-common">{value}</div>,
      },
    ],
    [isHighlight]
  );

  const deleteAccount = () => {
    deleteJiraBasedOnID(accountData._id).then((response) => {
      if (response.data.responseCode === 200) {
        props.MyAlert.success(`Account is deleted successfully`);
        closeModal();
        reloadpage();
      }
    });
  };

  return (
    <div className="page-list-body">
      <div className="page-table-body">
        <div className="content_area_style_Int">
          <div className="content_area_style_tcTemplate" id="journal-scroll">
            <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
              <label className="project_label text-sm flex-auto">Jira configuration</label>
              <div className="pr-4">
                <div className="flex flex-row items-center">
                  <SearchBar
                    open={openSearch}
                    searchValue={userSearched}
                    handleSearch={handleSearch}
                    onSearchClose={handleSearchClose}
                    handleSearchOpen={handleSearchOpen}
                    enterKeyHandler={handleSearchSubmit}
                    disable={isEmptyValue(account)}
                  />

                  <div className="ml-auto ">
                    <button
                      className="ml-3 primary-btn"
                      onClick={() => {
                        setOpenAccountModal(true);
                        setMode('ADD');
                      }}
                    >
                      + Instance
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-height overflow-auto" id="journal-scroll">
              <ReactTable data={data} columns={columns} tableHeightClass={'main-table-height'} />
              {isLoading ? (
                <CommonLoader />
              ) : isEmptyValue(account) ? (
                <div className="fontPoppinsRegularMd text-sm no-steps">
                  <div className="">
                    <p className="font-bold mb-4">Quick Start</p>
                    <p className="mb-4">
                      <span className="font-bold">Step 1 :</span> Click
                      <button
                        onClick={() => {
                          setOpenAccountModal(true);
                          setMode('ADD');
                        }}
                        className="ml-3 mr-3 primary-btn"
                      >
                        + Instance
                      </button>
                      to start adding Account
                    </p>
                  </div>
                </div>
              ) : (
                <>{openSearch && isEmptyValue(data) && <NoResultsFound className="mt-14" />}</>
              )}
            </div>
          </div>
        </div>
      </div>
      {openAccountModal ? (
        <JiraIntegrationModal
          closeModal={closeModal}
          reloadTree={reloadpage}
          mode={mode}
          MyAlert={props.MyAlert}
          data={accountData}
        />
      ) : null}
      {openDeleteModal ? <DeleteModal message={message} closeModal={closeModal} method={deleteAccount} /> : null}
      {openDetailsModal ? <JiraIntegrationDetails data={accountData} closeModal={closeModal} /> : null}
    </div>
  );
}

export default JiraIntegrationTable;

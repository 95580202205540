import React, { useEffect, useState, useContext } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import ResultInfoPanel from '../../shared/result-info/result_info_panel';
import ResultCharts from '../../shared/result_charts';
import ResultStatusTree from './result-status-tree/result_status_tree';
import PlatFormDetails from '../../shared/platform-details';
import { useHistory } from 'react-router-dom';
import { getModuleResultTreeReq } from '../../../../api/api_services';
import MoreButtonMenu from '../../shared/more_button_menu';
import { CommonLoader } from '@src/pages/common/common-loader';
import { getSuiteType } from '../../result';
import { useAlert } from '@pages/common/alert_service/useAlert';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { getIDB, removeIDB, setIDB } from '@src/util/localForage_idb_controller';

function ResultBase(props) {
  const [resultType, setResultType] = useState('root');
  const { AlertContatiner, MyAlert } = useAlert();
  const [toggleMoreDetails, setToggleMoreDetails] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const [rootData, setRootData] = useState();
  const [infoData, setInfoData] = useState();
  const [chartData, setChartData] = useState();
  const history = useHistory();
  const [platformData, setPlatformData] = useState();
  const [selectedProject, setSelectedProject] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);

  const moreDetails = (val) => {
    setToggleMoreDetails(val);
  };

  const isEditable = React.useMemo(() => window.permission?.isEditAccess('testDevelopment'), []);

  const search = window.location.search;
  const id = new URLSearchParams(search).get('id');
  const executionId = new URLSearchParams(search).get('executionId');
  const [suiteType, setSuiteType] = useState(getSuiteType(executionId));

  useEffect(() => {
    if (id) {
      localStorage.setItem('moduleId', id);
    }
    getResultData(id);
  }, [id]);

  const getResultData = (id) => {
    setInfoData('');
    getModuleResultTree(id);
  };

  function getModuleResultTree(id) {
    let res;
    setTreeData([]);
    removeIDB('dependent-data');
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    setIsLoading(true);
    getModuleResultTreeReq(executionId)
      .then((response) => {
        localStorage.setItem('runId', response?.data?.responseObject?.executionResponses[0]?.runId);
        stopProgress();
        if (response.data.responseObject) {
          if (id) {
            getTreeData(response.data, id);
          } else {
            getTreeData(response.data);
          }
        }
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 10,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        resetProgress();
        setIsLoading(false);
        console.log('error', error);
      });
  }

  const getTreeData = async (response, id) => {
    let returned_data = response?.responseObject?.executionResponses[0]?.children;
    let res;
    let _rootDataForCharts;
    setTreeData([]);
    setRootData({});
    let dependentData = response?.responseObject?.executionResponses[0]?.dependantExecutionEntityResponses;
    if (dependentData && dependentData.length) {
      setIDB('dependent-data', dependentData);
    }
    if (response.responseObject.executionResponses[0].executionId) {
      returned_data[0]['executionId'] = response?.responseObject?.executionResponses[0]?.executionId;
    }
    if (response.responseObject.executionResponses[0].storageDetails) {
      returned_data[0]['storageDetails'] = response?.responseObject?.executionResponses[0]?.storageDetails;
    }
    setIDB('tree-data', returned_data[0]);
    localStorage.setItem('moduleId', response?.responseObject?.executionResponses[0]?.children[0]?.id);
    const subTreeData = await getIDB('sub-tree-data');
    let _selectedProject = localStorage.getItem('selected-project');
    const selectedProjectParsed = JSON.parse(_selectedProject);
    setIDB('breadcrumb-data', returned_data[0]);
    if (selectedProjectParsed) {
      setSelectedProject(selectedProjectParsed);
    }
    if (!id) {
      res = returned_data[0].children;
      const { children, ...root_data } = returned_data[0];
      root_data['type'] = 'root';
      setRootData(root_data);
      _rootDataForCharts = Object.assign(root_data);
      if (_rootDataForCharts.platformStatistics) {
        setPlatformData(_rootDataForCharts?.platformStatistics);
      }
      setTreeData(res);
      setInfoAndChartData(_rootDataForCharts);
    } else {
      if (!subTreeData) {
        history.push(`/testdevelopment/Execution%20Dashboard`);
      } else {
        const parsedTreeObject = subTreeData;
        res = [parsedTreeObject];
        const { children, ...root_data } = parsedTreeObject;
        setRootData(root_data);
        _rootDataForCharts = Object.assign(root_data);
        setTreeData(res);
        if (_rootDataForCharts && _rootDataForCharts.status && _rootDataForCharts.platformStatistics) {
          setPlatformData(_rootDataForCharts.platformStatistics);
          setInfoAndChartData(_rootDataForCharts);
        }
      }
    }
  };

  const setInfoAndChartData = async (data) => {
    let _info;
    let localData = await getIDB('tree-data');
    const { children, ...rootModuleData } = localData;
    setChartData({});
    if (data.type.toLowerCase() !== 'root' && !data.title.toLowerCase().includes('root')) {
      _info = {
        runNumber: 8,
        runId: rootModuleData?.executionId,
        status: data?.status,
        moduleName: data?.title,
        duration: data?.executionDurationInHourMinSecFormat,
        execution: data?.selectedSystem?.executionEnv,
        executedOn: data?.executedOn,
      };
      if (data.platformStatistics && data.platformStatistics.length) {
        _info['machine'] = data?.platformStatistics[0]?.machine;
        _info['os'] = [data?.platformStatistics[0]?.os];
      } else if (data.selectedSystem && data.selectedSystem.machineInfo) {
        _info['machine'] = data?.selectedSystem.machineInfo.hostName;
        _info['os'] = [data?.selectedSystem?.machineInfo.osName];
      }
      if (data.selectedSystem.browserName) {
        _info['browser'] = [data.selectedSystem.browserName];
      }
      if (data.selectedSystem.version) {
        _info['browserVersion'] = [data.selectedSystem.version];
      }
      if (data.platformStatistics) {
        let device = '';
        let browser = '';
        data.platformStatistics.forEach((ps) => {
          if (ps.deviceName) {
            if (device) {
              let str1 = device.concat(',');
              device = str1.concat(ps.deviceName);
            } else {
              device = ps.deviceName;
            }
          }
          if (ps.browser) {
            if (!browser) {
              browser = ps.browser;
            }
          }
        });
        if (device) {
          _info['deviceName'] = [device];
        }
      }

      setGraphChartData(data);
    } else {
      //for root level info
      _info = {
        runNumber: 8,
        runId: rootModuleData?.executionId,
        status: rootModuleData?.status,
        moduleName: rootModuleData?.title,
        duration: rootModuleData?.executionDurationInHourMinSecFormat,
        executedOn: rootModuleData?.executedOn,
        execution: data?.selectedSystem?.executionEnv,
      };
      if (rootModuleData.selectedSystem.browserName) {
        _info['browser'] = rootModuleData?.selectedSystem?.browserName;
        _info['browserVersion'] = rootModuleData?.selectedSystem?.version;
      } else if (rootModuleData.selectedSystem.browserInfo) {
        _info['browser'] = rootModuleData?.selectedSystem?.browserInfo.name;
        _info['browserVersion'] = rootModuleData?.selectedSystem?.browserInfo.version;
      }
      if (rootModuleData?.platformStatistics && rootModuleData?.platformStatistics.length) {
        _info['machine'] = rootModuleData.platformStatistics[0].machine;
        _info['os'] = [rootModuleData.platformStatistics[0].os];
      }
      // _info.os.push("Linux");
      if (rootModuleData.platformStatistics) {
        let device = '';
        let browser = '';
        rootModuleData.platformStatistics.forEach((ps) => {
          if (ps.deviceName) {
            if (device) {
              let str1 = device.concat(',');
              device = str1.concat(ps.deviceName);
            } else {
              device = ps.deviceName;
            }
          }
          // if(ps.browser){
          // 	if(!browser){
          // 		browser = ps.browser;
          // 	}
          // }
        });
        if (device) {
          _info['deviceName'] = [device];
        }
        // if(browser) {
        // 	_info["browser"] = [browser];
        // }
      }
      setGraphChartData(rootModuleData);
    }
    setInfoData(_info);
  };

  const setGraphChartData = (data) => {
    let _defaultStats = {
      total: 0,
      totalWarning: 0,
      totalFailed: 0,
      totalSkipped: 0,
      totalTerminated: 0,
      totalPassed: 0,
    };
    let _chart = {
      moduleStats: _defaultStats,
      scriptStats: _defaultStats,
      preConditionStats: _defaultStats,
      postConditionStats: _defaultStats,
    };

    if (data.moduleStats) {
      _chart['moduleStats'] = data.moduleStats;
    }
    if (data.scriptStats) {
      _chart['scriptStats'] = data.scriptStats;
    }
    if (data.preConditionStats) {
      _chart['preConditionStats'] = data.preConditionStats;
    }
    if (data.postConditionStats) {
      _chart['postConditionStats'] = data.postConditionStats;
    }
    setChartData(_chart);
  };

  const handleStatusChange = () => {
    if (props.handleStatusChange) {
      props.handleStatusChange();
    }
  };

  const onMenuItemClick = (val) => {
    console.log('menu clicked::', val);
  };
  return (
    <>
      <div className="grid grid-cols-3 sm:pb-2 md:pb-3 lg:pb-4 xl:pb-4">
        <div className="fontPoppinsSemiboldXlg">Results</div>
        <div className="col-span-2">
          <div className="alert-position-style">
            <AlertContatiner></AlertContatiner>
          </div>
        </div>
      </div>
      <div className=" flex" id="journal-scroll">
        <div className="flex-1 focus:outline-none">
          <main className="flex-1 relative pb-2 z-0 base-card">
            {isLoading ? (
              <CommonLoader />
            ) : (
              <>
                <div className="grid grid-cols-2 gap-3">
                  <div className="col-end-2 col-span-12">
                    <div key="result-info" className="bg-white h-44 flex items-center card-box-shadow overflow-hidden">
                      {infoData && infoData.runId && selectedProject && (
                        <ResultInfoPanel
                          data={infoData}
                          projectType={selectedProject.type}
                          focusMoreDetails={setToggleMoreDetails}
                          resultType={resultType}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-start-2">
                    <div key="result-chart" className="bg-white h-44 overflow-hidden card-box-shadow">
                      {chartData ? <ResultCharts chartData={chartData} type="Module" suiteType={suiteType} /> : ''}
                    </div>
                  </div>
                </div>
                {toggleMoreDetails && platformData && selectedProject ? (
                  <div className="bg-white card-box-shadow mt-2">
                    <PlatFormDetails data={platformData} projectType={selectedProject.type} moreDetails={moreDetails} />
                  </div>
                ) : (
                  <div className="platform-details my-3" onClick={() => moreDetails(true)}>
                    <div className="grid grid-cols-1">
                      <div className="more-details mx-3 my-2">
                        More Details
                        <FaChevronDown className="platform-chevron float-right mt-1 mx-2" />
                      </div>
                    </div>
                  </div>
                )}
                <div className="grid grid-cols-1 mt-2 ">
                  <div key="result-status" className="bg-white card-box-shadow ">
                    {treeData && treeData.length && rootData ? (
                      <ResultStatusTree
                        {...props}
                        treeData={treeData}
                        rootData={rootData}
                        handleStatusChange={handleStatusChange}
                        isEditable={isEditable}
                        MyAlert={MyAlert}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </>
            )}
          </main>
        </div>
      </div>
    </>
  );
}

export default ResultBase;

import { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import Button from '../../Button/Button';
import CommentBoxContainer from '../../CommentBoxContainer/CommentBoxContainer';
import CommentForm from '../../CommentForm/CommentForm';
import DeleteModal from '@src/pages/common/delete_modal';
import { editCommentstyle } from '../Comment';

const ThreadComment = (props) => {
  const {
    threadData,
    onUpdateThreadComment,
    onDeleteThreadComment,
    onRespond,
    commentsArray,
    parentData,
    detailsView,
    projectUsers,
    analyticsClasses = false,
  } = props;

  const { parentCommentId, parentCommentUserId, parentCommentLength } = parentData;

  const {
    id: threadCommentId,
    createdByUname: threadCommentUserName,
    description: threadCommentText,
    userId: threadUserId,
    isThreadCommentReply: isCommentReply,
    createdOn: threadCommentCreated,
    modifiedOn: threadCommentModified,
  } = threadData;

  let loginUserName = JSON.parse(localStorage.getItem('test-optimize-user')).name;

  const [isOpen, setIsOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [reset, setReset] = useState(false);

  const [showThreadEditComment, setShowThreadEditComment] = useState(false);
  const [editedThreadComment, SetEditedThreadComment] = useState('');
  const [showThreadReply, setShowThreadReply] = useState(false);

  const [showUserName, setShowUserName] = useState(false);
  const [threadText, setThreadText] = useState('');
  const [replyToUserName, setReplyToUserName] = useState('');

  const handleThreadCommentEdit = () => {
    onUpdateThreadComment(parentCommentId, threadCommentId, editedThreadComment);
    setReset(true);
    setShowThreadEditComment(false);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDelete = () => {
    onDeleteThreadComment(parentCommentId, threadCommentId);
    setIsOpen(false);
  };

  const closeCommentBox = () => {
    setShowThreadReply(false);
  };

  useEffect(() => {
    const userName = threadCommentText;
    setReplyToUserName(userName);
    const text = threadCommentText;
    setThreadText(text);
    if (isCommentReply) {
      setShowUserName(true);
    }
  }, [threadCommentText, threadCommentUserName, isCommentReply]);

  useEffect(() => {
    if (editedThreadComment && editedThreadComment !== threadCommentText && editedThreadComment.trim().length !== 0) {
      setIsDisabled(false);
    }
    return () => {
      setIsDisabled(true);
    };
  }, [editedThreadComment, threadCommentText, isDisabled]);

  return (
    <>
      <div className="threadComments">
        <div className="comment-col1">
          <Avatar size={30} name={threadCommentUserName} title={threadCommentUserName} round={true}></Avatar>
          <div className="dividercomments">
            <div className="threadline"></div>
          </div>
        </div>
        <div className="comment-col2">
          <h6 className={analyticsClasses ? 'fontPoppinsRegularSm' : 'fontPoppinsRegularLg'}>
            {threadCommentUserName.replace(/^./, threadCommentUserName[0].toUpperCase())}{' '}
            {threadCommentModified !== '--' ? threadCommentModified : threadCommentCreated}
          </h6>
          {showThreadEditComment ? (
            <div style={editCommentstyle}>
              <CommentForm
                id={threadCommentId}
                getData={SetEditedThreadComment}
                isEdit={true}
                setData={threadCommentText}
                isReset={reset}
                setReset={setReset}
                setShowEditComment={setShowThreadEditComment}
                projectUsers={projectUsers}
              ></CommentForm>
              <div className="edit-comment-footer">
                <button
                  type="button"
                  className="primary-btn"
                  onClick={() => setShowThreadEditComment(!showThreadEditComment)}
                >
                  {' '}
                  Cancel{' '}
                </button>
                <button
                  type="button"
                  className="ml-3 primary-btn"
                  onClick={handleThreadCommentEdit}
                  {...(isDisabled ? { disabled: 'disabled' } : '')}
                >
                  {' '}
                  Update{' '}
                </button>
              </div>
            </div>
          ) : (
            <>
              <p className={analyticsClasses ? 'fontPoppinsRegularXs' : 'fontPoppinsRegularMd'}>
                {showUserName ? (
                  <>
                    <a className="reply-to-user-tag" href="/">
                      {replyToUserName}
                    </a>
                    {threadText}
                  </>
                ) : (
                  threadCommentText
                )}
              </p>
              {detailsView ? (
                <div className="comment-footer fontPoppinsRegularSm"></div>
              ) : (
                <div className="comment-footer fontPoppinsRegularSm">
                  {loginUserName === threadCommentUserName && (
                    <>
                      <Button type="gost" size="sm" className={'margin-left-4'} onClick={() => setIsOpen(true)}>
                        {' '}
                        Delete{' '}
                      </Button>
                      <Button
                        type="gost"
                        size="sm"
                        onClick={() => setShowThreadEditComment(!showThreadEditComment)}
                        className={'margin-left-4'}
                      >
                        {' '}
                        Edit{' '}
                      </Button>
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
        {isOpen && <DeleteModal closeModal={handleClose} method={handleDelete} message={`Comments`} />}
      </div>
      {showThreadReply && (
        <CommentBoxContainer
          id={parentCommentUserId}
          replyTo={threadCommentUserName}
          replyCommentsLength={parentCommentLength}
          commentId={parentCommentId}
          userId={threadUserId}
          onCancel={closeCommentBox}
          commentsArray={commentsArray}
          onRespond={onRespond}
          isThreadCommentReply={true}
          projectUsers={projectUsers}
        />
      )}
    </>
  );
};
export default ThreadComment;

import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import TableTree from '@src/pages/common/table_tree/table_tree';
import {
  getAllModuleTreeReq,
  moveResourceReq,
  getLibraryStepGroupMoveReq,
  getFirstLevelAllModulesTreeReq,
  getExpandModuleTreeReq,
  getLibraryTreeReq,
  getPageTreeReq,
  getPageElementMoveReq,
} from '@src/api/api_services';
import CommonModalComponent from '@src/pages/common/modal/CommonModalComponent';
import Tooltip from '@material-ui/core/Tooltip';
import { MOVE_RESOURCE } from '../utils/constants';
import { LABELS, SCRIPT_LABELS, PAGE_LABEL } from '@src/common/ui-constants';
import { UI_VALIDATIONS } from '@src/util/validations';
import { getCurrentProjectType } from '@src/util/common_utils';

function MoveResource({
  handleClose = () => {},
  moveResourceModalData = {},
  MyAlert,
  reloadTree,
  stepGroupLibraryMove,
  pageOrElementMove,
  popupdata,
  page,
  platForm,
}) {
  let projectType = getCurrentProjectType();
  const [moduleSelection, setModuleSelection] = useState(false);
  const [moveResourceTreeData, setMoveResourceTreeData] = useState([]);
  const [destinationNodeData, setDestinationNodeData] = useState([]);
  const [pageType, setPageType] = useState('');
  const [seleteRoot, setSelectRoot] = useState(false);
  const { open, srcNodeData } = moveResourceModalData;
  const { MOVE_BELOW, MOVE_INSIDE, MOVE_PAGE, MOVE_ELEMENT, MOVE_SCRIPT, MOVE_MODULE, MOVE_LIBRARY, MOVE_STEPGROUP } =
    MOVE_RESOURCE;
  const timeoutRef = useRef(null);
  const { MAX_CHARACTER_COUNT } = UI_VALIDATIONS;

  const stepGroupcolDefs = useMemo(
    () => [
      {
        field: 'title',
        title: 'LIBRARY',
        class: 'title',
        isTitle: true,
        width: '220px',
        render: (nodeObj) => {
          return (
            <>
              {nodeObj.isRootNode ? (
                <Tooltip title={`Libraries- ${nodeObj.data.subLibraryCount}`} placement="bottom">
                  <span className="count-badge folder-count ml-2 cursor-pointer">L {nodeObj.data.subLibraryCount}</span>
                </Tooltip>
              ) : null}
              {nodeObj.node ? (
                nodeObj.node && nodeObj.node.data && nodeObj.node.folder && !nodeObj.data.isMockNode ? (
                  <>
                    <Tooltip title={`Sub Libraries - ${nodeObj?.node?.data?.subLibraryCount}`} placement="bottom">
                      <span className="count-badge file-count cursor-pointer">
                        {' '}
                        SL {nodeObj?.node?.data?.subLibraryCount || 0}{' '}
                      </span>
                    </Tooltip>
                    <Tooltip title={`Step Groups - ${nodeObj.data.stepGroupCount}`} placement="bottom">
                      <span className="count-badge file-count cursor-pointer">
                        {' '}
                        SG {nodeObj.node.data.stepGroupCount || 0}{' '}
                      </span>
                    </Tooltip>
                  </>
                ) : nodeObj.isRootNode ? (
                  <Tooltip title={`Step Groups - ${nodeObj.data.stepGroupCount}`} placement="bottom">
                    <span className="count-badge file-count cursor-pointer"> SG {nodeObj.data.stepGroupCount}</span>
                  </Tooltip>
                ) : null
              ) : null}
            </>
          );
        },
      },
      {
        field: 'stepGroupType',
        title: 'TYPE',
        class: 'state ',
        width: '100px',
        render: (nodeObj) => {
          return (
            <>
              <Tooltip title={`${nodeObj?.data?.type}`} placement="bottom">
                <span className=" table-non-link-color-common fontPoppinsRegularMd" name="element-type">
                  {nodeObj?.data?.type?.length > 10
                    ? nodeObj?.data?.type?.substring(0, 10) + '...'
                    : nodeObj?.data?.type}
                </span>
              </Tooltip>
            </>
          );
        },
      },
    ],
    []
  );

  const pageOrElementcolDefs = useMemo(
    () => [
      {
        field: 'title',
        title: 'Page',
        class: 'title',
        isTitle: true,
        width: '220px',
        render: (nodeObj) => {
          return (
            <>
              {nodeObj.isRootNode ? (
                <Tooltip title={`Libraries- ${nodeObj.data.subPageCount}`} placement="bottom">
                  <span className="count-badge folder-count ml-2 cursor-pointer">L {nodeObj.data.subPageCount}</span>
                </Tooltip>
              ) : null}
              {nodeObj.node ? (
                nodeObj.node && nodeObj.node.data && nodeObj.node.folder && !nodeObj.data.isMockNode ? (
                  <>
                    <Tooltip title={`Sub Libraries - ${nodeObj?.node?.data?.subPageCount}`} placement="bottom">
                      <span className="count-badge file-count cursor-pointer">
                        {' '}
                        SP {nodeObj?.node?.data?.subPageCount || 0}{' '}
                      </span>
                    </Tooltip>
                    <Tooltip title={`Step Groups - ${nodeObj.data.stepGroupCount}`} placement="bottom">
                      <span className="count-badge file-count cursor-pointer">
                        {' '}
                        P {nodeObj.node.data.stepGroupCount || 0}{' '}
                      </span>
                    </Tooltip>
                  </>
                ) : nodeObj.isRootNode ? (
                  <Tooltip title={`Step Groups - ${nodeObj.data.stepGroupCount}`} placement="bottom">
                    <span className="count-badge file-count cursor-pointer"> SG {nodeObj.data.stepGroupCount}</span>
                  </Tooltip>
                ) : null
              ) : null}
            </>
          );
        },
      },
      {
        field: 'stepGroupType',
        title: 'TYPE',
        class: 'state ',
        width: '100px',
        render: (nodeObj) => {
          return (
            <>
              <Tooltip title={`${nodeObj?.data?.type}`} placement="bottom">
                <span className=" table-non-link-color-common fontPoppinsRegularMd" name="element-type">
                  {nodeObj?.data?.type?.length > 10
                    ? nodeObj?.data?.type?.substring(0, 10) + '...'
                    : nodeObj?.data?.type}
                </span>
              </Tooltip>
            </>
          );
        },
      },
    ],
    []
  );

  const colDefs = useMemo(
    () => [
      {
        field: 'title',
        title: 'Module',
        class: 'title',
        isTitle: true,
        width: `calc(100% - 100px)`,
        render: (nodeObj) => {
          const { isRootNode, data: { subModuleCount = '', scriptCount = '' } = {} } = nodeObj || {};
          return (
            <>
              {isRootNode && (
                <>
                  <span className="folder-count count-badge"> M{subModuleCount} </span>
                  {!moduleSelection && <span className="file-count count-badge"> S{scriptCount} </span>}
                </>
              )}
            </>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (open && !stepGroupLibraryMove && !pageOrElementMove) {
      const isModule = srcNodeData?.data?.folder;
      setModuleSelection(isModule);
      getModuleTreeData(!isModule, srcNodeData?.node?.key);
    }
  }, []);

  useEffect(() => {
    if (open && stepGroupLibraryMove && !pageOrElementMove) {
      const isFolder = srcNodeData?.folder;
      setModuleSelection(isFolder);
      getStepGroupLibraryData(true, popupdata?.id);
    }
  }, []);

  useEffect(() => {
    if (page?.toLowerCase() === 'stepgroup') {
      setPageType('Library');
    } else {
      if (page?.toLowerCase() === 'page') {
        if (platForm === 'Web') {
          setPageType('Page');
        } else {
          setPageType('Screen');
        }
      }
    }
  }, [page]);

  useEffect(() => {
    return () => {
      removeTimeout();
    };
  }, [moveResourceTreeData]);

  const getStepGroupLibraryData = async (resourceRequired, stepGroupOrModuleId) => {
    try {
      const response = await getLibraryStepGroupMoveReq(resourceRequired, stepGroupOrModuleId);
      if (response?.data?.responseObject?.libraryTree?.length) {
        setMoveResourceTreeData([...response.data.responseObject.libraryTree]);
      } else {
        setMoveResourceTreeData([]);
      }
    } catch (err) {
      console.error('GET_ONLY_LIBRARY_TREE : ', err);
    }
  };

  useEffect(() => {
    if (open && pageOrElementMove && !stepGroupLibraryMove) {
      const isFolder = srcNodeData?.folder;
      setModuleSelection(isFolder);
      const headerData = projectType !== 'Web'
      ? { projectType, platForm, sourcePageId: popupdata?.parentId, sourceName: popupdata?.name, sourceType: popupdata?.type }
      : { projectType , sourcePageId: popupdata?.parentId, sourceName: popupdata?.name, sourceType: popupdata?.type };
      getPageOrElementData(true, popupdata?.id, headerData);
    }
  }, []);

  const getPageOrElementData = async (resourceRequired, pageOrElementId, headerData) => {
    try {
      const response = await getPageElementMoveReq(resourceRequired, pageOrElementId, headerData);
      if (response?.data?.responseObject?.pageTree?.length) {
        let unselectable = response?.data?.responseObject?.pageTree[0].unselectable;
        setSelectRoot(unselectable);
        setMoveResourceTreeData([...response.data.responseObject.pageTree]);
      } else {
        setMoveResourceTreeData([]);
      }
    } catch (err) {
      console.error('GET_ONLY_LIBRARY_TREE : ', err);
    }
  };

  const getModuleTreeData = async (resourceRequired) => {
    const scriptOrModuleId = srcNodeData?.node?.key;
    try {
      const response = await getFirstLevelAllModulesTreeReq(scriptOrModuleId);
      if (response?.data?.responseObject?.moduleTree?.length) {
        let unselectable = response?.data?.responseObject?.moduleTree[0].unselectable;
        setSelectRoot(unselectable);
        setMoveResourceTreeData([...response.data.responseObject.moduleTree]);
      } else {
        setMoveResourceTreeData([]);
      }
    } catch (err) {
      console.error('GET_ONLY_MODULES_TREE : ', err);
    }
  };

  const moveSelectedResource = async (action) => {
    const srcId = srcNodeData?.node?.key || srcNodeData?.key;
    const destId = destinationNodeData?.[0]?.node?.key || destinationNodeData?.[0]?.data?.key;
    let destinationPageId = '';
    let sourcePageId = '';
    let destinationName = '';
    let sourceName = '';
    let destinationType = '';
    let sourceType = '';
    
    if(destId && destId.includes("ELE")){
      destinationPageId  = destinationNodeData?.[0]?.node?.parentId || destinationNodeData?.[0]?.data?.parentId;
      destinationName  = destinationNodeData?.[0]?.node?.name || destinationNodeData?.[0]?.data?.name;
      destinationType  = destinationNodeData?.[0]?.node?.type || destinationNodeData?.[0]?.data?.type;
    }
    if(srcId && srcId.startsWith("ELE")){
      sourcePageId  = srcNodeData?.node?.parentId || srcNodeData?.data?.parentId;
      sourceName  = srcNodeData?.node?.data?.name || srcNodeData?.data?.name;
      sourceType  = srcNodeData?.node?.type || srcNodeData?.type;
    }

    const headerData = { sourcePageId, destinationPageId, sourceName, destinationName, sourceType, destinationType};

    try {
      const response = await moveResourceReq(srcId, destId, action, headerData);
      const { responseCode, message } = response?.data;
      if (responseCode === 200) {
        reloadTree(true);
        handleClose();
        MyAlert.success(message);
      } else if (message.includes('already exists')) {
        handleClose();
        MyAlert.info(message);
      } else if (responseCode === 400) {
        handleClose();
        MyAlert.danger(message);
      }
    } catch (err) {
      console.error('MOVE_RESOURCE_API : ', err);
    }
  };

  const loadMoreDataStepGroup = async (_libraryId) => {
    let _libraryTree = [];
    try {
      let res;
      if (_libraryId) {
        res = await getLibraryTreeReq(_libraryId);
      } else {
        res = await getLibraryTreeReq();
      }
      if (res?.data?.responseCode === 200 && res?.data?.responseObject) {
        _libraryTree = res.data.responseObject.libraryTree;
        timeoutRef.current = setTimeout(() => {
          setMoveResourceTreeData(res.data.responseObject.libraryTree);
        }, 300);
      }
      return _libraryTree;
    } catch (error) {
      console.error('error during load more library data:', error);
      setMoveResourceTreeData(_libraryTree);
      return _libraryTree;
    }
  };

  const loadMoreDataPageElement = async (_pageId) => {
    let _pageTree = [];
    try {
      let res;
      if (_pageId) {
        res = await getPageTreeReq(projectType, platForm, false);
      } else {
        res = await getPageTreeReq(projectType, platForm);
      }
      if (res?.data?.responseCode === 200 && res?.data?.responseObject) {
        _pageTree = res.data.responseObject.pageTree;
        timeoutRef.current = setTimeout(() => {
          setMoveResourceTreeData(res.data.responseObject.pageTree);
        }, 300);
      }
      return _pageTree;
    } catch (error) {
      console.error('error during load more library data:', error);
      setMoveResourceTreeData(_pageTree);
    }
  };

  const loadMoreDataScripts = async (_modId, rootMod_id, conditionType) => {
    const scriptOrModuleId = srcNodeData?.node?.key;
    let _result_tree = [];
    try {
      let res;
      if (!conditionType) {
        if (_modId) {
          res = await getExpandModuleTreeReq(_modId, scriptOrModuleId);
        } else {
          res = await getAllModuleTreeReq(true, scriptOrModuleId);
        }
      }
      if (['PRE', 'POST'].includes(conditionType)) {
        res = await getExpandModuleTreeReq(rootMod_id, scriptOrModuleId, true, conditionType);
      }
      if (res && res.data && res.data.responseCode === 200 && res.data.responseObject) {
        _result_tree = res.data.responseObject.moduleTree[0].children;
      }
      return _result_tree;
    } catch (error) {
      console.error('error during load more data:', error);
      return _result_tree;
    }
  };
  const onCheckedNodes = useCallback((node) => {
    setDestinationNodeData(node);
  }, []);

  const disableMoveBelowBtn = useMemo(() => {
    const { data = {}, node = {} } = destinationNodeData?.[0] || {};
    if (
      data?.isRootNode ||
      data?.disableMoveBelow ||
      !destinationNodeData?.length ||
      node?.getNextSibling()?.key === (srcNodeData?.node?.key || srcNodeData?.key) ||
      (!moduleSelection && moveResourceTreeData?.[0]?.key === data?.parentId)
    ) {
      return true;
    }
    return false;
  }, [moduleSelection, destinationNodeData]);

  const disableMoveInsideBtn = useMemo(() => {
    const { node = {} } = destinationNodeData?.[0] || {};
    const nodeParentId = srcNodeData?.node?.data?.parentId || srcNodeData?.data?.parentId;
    const nodeId = srcNodeData?.node?.key || srcNodeData?.key;
    const nodeName = srcNodeData?.node?.name || srcNodeData?.name;
    const nodeType = srcNodeData?.node?.type || srcNodeData?.type;
    const firstChildNodeData = node?.children?.[0]?.data;
    let isDisableMoveInside = nodeId?.startsWith("ELE") ?
    (nodeId === node?.children?.[0]?.key && 
      nodeParentId === firstChildNodeData?.parentId && 
      nodeName === firstChildNodeData?.name && 
      nodeType === firstChildNodeData?.type) : 
    nodeId === node?.children?.[0]?.key;
    if (
      !destinationNodeData?.length ||
      node?.key?.startsWith('SCR') ||
      node?.key?.startsWith('STP_GRP') ||
      isDisableMoveInside ||
      destinationNodeData?.[0]?.data?.disableMoveInside
    ) {
      return true;
    }
    return false;
  }, [moduleSelection, destinationNodeData]);

  const renderFooterContent = useMemo(() => {
    return (
      <div className="float-right">
        <button className="gray-btn mr-3" onClick={handleClose}>
          Cancel
        </button>
        <button
          type="button"
          className="primary-btn"
          disabled={disableMoveInsideBtn}
          onClick={() => {
            moveSelectedResource(MOVE_INSIDE);
          }}
        >
          {MOVE_INSIDE}
        </button>
        <button
          type="button"
          className="primary-btn"
          disabled={disableMoveBelowBtn}
          onClick={() => {
            moveSelectedResource(MOVE_BELOW);
          }}
        >
          {MOVE_BELOW}
        </button>
      </div>
    );
  }, [disableMoveInsideBtn, disableMoveBelowBtn]);

  const removeTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    } else {
      console.error('No timeout to clear');
    }
  };

  function getTitleText(popupdata, stepGroupLibraryMove, pageOrElementMove) {
    const name =
      popupdata?.name?.length > MAX_CHARACTER_COUNT
        ? popupdata?.name?.substring(0, MAX_CHARACTER_COUNT) + '...'
        : popupdata?.name;
    if (stepGroupLibraryMove) {
      return popupdata?.folder === true ? `${MOVE_LIBRARY} - ${name}` : `${MOVE_STEPGROUP} - ${name}`;
    } else if (pageOrElementMove) {
      return popupdata?.folder === true ? `${MOVE_PAGE} - ${name}` : `${MOVE_ELEMENT} - ${name}`;
    } else {
      return popupdata?.folder === true ? `${MOVE_MODULE} - ${name}` : `${MOVE_SCRIPT} - ${name}`;
    }
  }

  return (
    <div>
      <CommonModalComponent
        isModalOpen={open}
        titleText={getTitleText(popupdata, stepGroupLibraryMove, pageOrElementMove)}
        footerContent={renderFooterContent}
        handleClose={handleClose}
      >
        {moveResourceTreeData?.length > 0 && (
          <TableTree
            showOnlyFolders={moduleSelection}
            defaultDisabled={[]}
            defaultChecked={[]}
            disableRootRadioButton={seleteRoot || !moduleSelection}
            id={'moveResourceRadioBtnTree'}
            data={moveResourceTreeData[0]?.children || []}
            rootData={moveResourceTreeData[0] || []}
            colDefs={stepGroupLibraryMove ? stepGroupcolDefs : pageOrElementMove ? pageOrElementcolDefs : colDefs}
            filter={''}
            expandAll={false}
            hideElements={false}
            hideElementsBtn={moduleSelection}
            hideRootRadioBtn={!moduleSelection || stepGroupLibraryMove}
            hideConditions={true}
            showCheckbox={false}
            showRadioButton={true}
            conditions={[
              { key: 'title', value: 'Preconditions' },
              { key: 'title', value: 'Postconditions' },
            ]}
            hideConditionBtn={true}
            onCheckedNodes={onCheckedNodes}
            labels={stepGroupLibraryMove ? LABELS : pageOrElementMove ? PAGE_LABEL : SCRIPT_LABELS}
            onRootNodeClicked={() => {}}
            onNodeSelected={() => {}}
            childrenCountKeysArray={[
              'moduleLevelScriptCount',
              'subModuleCount',
              'postCondtionCount',
              'preCondtionCount',
            ]}
            expandAllApi={
              page === 'StepGroup'
                ? loadMoreDataStepGroup
                : page === 'Page'
                ? loadMoreDataPageElement
                : loadMoreDataScripts
            }
            loadMoreData={
              page === 'StepGroup'
                ? loadMoreDataStepGroup
                : page === 'Page'
                ? loadMoreDataPageElement
                : loadMoreDataScripts
            }
            pageType={pageType}
          />
        )}
      </CommonModalComponent>
    </div>
  );
}

export default MoveResource;

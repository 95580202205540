import Modal from 'react-modal';
import { colors } from '@src/css_config/colorConstants';
import { VncScreen } from 'react-vnc';
import React, { useEffect, useRef, useState } from 'react';
import { Icon, Tooltip } from 'fireflink-ui';
import { getWSData } from '@src/api/api_services';
import './video_modal.scss';
function VideoModal(props) {
  const [openModal, setOpenModal] = useState(true);
  const [isMaximize, setIsMaximize] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [wsUrl, setWSUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const ref = useRef();
  const timerId = useRef();

  const fetchWSData = (isOneTimeCall) => {
    getWSData(props.liveExecutionPayload)
      .then((res) => {
        if (isOneTimeCall) {
          setWSUrl(res.data.responseObject);
        }
        if (res.data.responseCode === 404) {
          setErrorMsg(`Something went wrong`);
          if (!isOneTimeCall) {
            clearInterval(timerId.current);
          }
        }
        if (res.data.status === 'Execution Completed' || res.data.status === 'Execution Failed') {
          setErrorMsg(`Execution completed`);
          if (!isOneTimeCall) {
            clearInterval(timerId.current);
          }
          props.closeDeviceModal();
          setOpenModal(false);
        }
        if (res.data.status === 'FAILURE') {
          if (!isOneTimeCall) {
            clearInterval(timerId.current);
          }
          setErrorMsg('Script execution not yet started');
        }
      })
      .catch((err) => {
        setErrorMsg('something went wrong');
        if (!isOneTimeCall) {
          clearInterval(timerId.current);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchWSData('oneTimeCall');
    timerId.current = setInterval(fetchWSData, 2000); // to check if execution is completed
    return () => {
      clearInterval(timerId.current);
    };
  }, [props.liveExecutionPayload]);

  useEffect(() => {
    const escFunction = (e) => {
      if (e.key === 'Escape') {
        setIsMaximize(false);
      }
    };
    document.addEventListener('keydown', escFunction, false);
    return () => window.removeEventListener('keydown', escFunction, false);
  }, []);

  return (
    <>
      <Modal
        shouldCloseOnEsc={false}
        isOpen={openModal}
        className=" focus:outline-none vnc-modal-content"
        onRequestClose={() => {
          setOpenModal(false);
          props.closeDeviceModal();
        }}
      >
        <div className="vnc-modal-header">
          <Tooltip title={isMaximize ? 'minimize' : 'maximize'} placement="bottom">
            <Icon
              key={isMaximize + ''}
              name={isMaximize ? 'minimize' : 'maximize'}
              className="cursor-pointer"
              onClick={() => {
                setIsMaximize((prev) => !prev);
              }}
              height={14}
              width={14}
            />
          </Tooltip>
          <Tooltip title="Close" placement="bottom">
            <Icon
              className="cursor-pointer"
              onClick={() => {
                setOpenModal(false);
                props.closeDeviceModal();
              }}
              name={'toast_close_icon_defult'}
              height={14}
              width={14}
            />
          </Tooltip>
        </div>

        {loading ? (
          <p className="text-center">Loading...</p>
        ) : wsUrl ? (
          <VncScreen
            resizeSession
            url={wsUrl}
            viewOnly
            scaleViewport
            background={colors.video_play_background}
            className={isMaximize ? 'maximize-vnc-viewer' : 'minimize-vnc-viewer'}
            ref={ref}
            rfbOptions={{
              credentials: { password: 'secret' },
            }}
          />
        ) : (
          <p className="fontPoppinsRegularSm text-center">{errorMsg}</p>
        )}
      </Modal>
    </>
  );
}

export default VideoModal;

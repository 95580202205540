import { Switch } from '@mui/material';
import PropTypes from 'prop-types';
import cx from 'classnames';
import style from '@pages/common/LicensePurchaseComponents/styles/auto-renew-license-card.module.scss';

function AutoRenewLicenseCard({ isChecked, onToggle, name }) {
  return (
    <div className={cx(style['auto-renew-license-card'], 'fontPoppinsRegularSm')}>
      <p className="mb-2">Auto renew the license at the end of billing cycle</p>

      <span className={style['swtich-wrapper']}>
        <Switch checked={isChecked} onChange={onToggle} name={name} />
        <span
          className={cx(style['label'], {
            [style['checked']]: isChecked,
          })}
        >
          {isChecked ? 'ON' : 'OFF'}
        </span>
      </span>

      <span className="mt-2 block">
        Read the <span className={style['link']}>Terms & Conditions</span> carefully before proceeding.
      </span>
    </div>
  );
}

AutoRenewLicenseCard.propTypes = {
  isChecked: PropTypes.bool,
  onToggle: PropTypes.func,
  name: PropTypes.string.isRequired,
};

AutoRenewLicenseCard.defaultProps = {
  isChecked: false,
  onToggle: () => {},
};

export default AutoRenewLicenseCard;

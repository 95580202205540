import { DeleteOutlined, EditOutlined } from '@material-ui/icons';
import React, { useEffect, useState, useContext } from 'react';
import ReactTable from '@src/pages/common/Table/ReactTable';
import { deleteLambdaTestInstance, getAllLambdaTestsList } from '@api/api_services';
import DeleteModal from '@pages/common/delete_modal';
import CreateEditLambdaTestInstance from './create-edit-lambda-test-instance';
import LambdaTestDetailsModal from './lambda-test-details-modal';
import { useHistory } from 'react-router-dom';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { CommonLoader } from '@src/pages/common/common-loader';
import { Tooltip } from '@material-ui/core';
import ContextMenu from '@pages/common/context_menu';
import SearchBar from '@pages/analytics/common/search-bar';
import { HighlightText, isEmptyValue, isValueIncludes } from '@util/common_utils';
import NoResultsFound from '@pages/common/Table/NoResultFound';

const LambdaTestTable = (props) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [message, setMessage] = useState('');
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [reloadTable, setReloadTable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [account, setAccount] = useState([]);
  const [accountData, setAccountData] = useState();
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [mode, setMode] = useState('');
  const reloadpage = () => {
    setReloadTable(true);
  };
  const history = useHistory();

  const contextMenuData = [
    {
      option: 'Details',
    },
  ];

  function contextMenuFunction(event, ele) {
    if (event.target.textContent === 'Details') {
      setAccountData(ele);
      setOpenDetailsModal(true);
    }
  }

  const getLambdaTestInstances = async () => {
    try {
      resetProgress();
      startOrResumeProgress({
        stopAt: 90,
      });
      const response = await getAllLambdaTestsList();
      stopProgress();
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 10,
      });
      if (response.data.responseObject) {
        response.data.responseObject.forEach((res, i) => {
          res.Actions = [];
          res.Actions.push(
            <div className="actionIcons">
              <Tooltip title="Edit">
                <EditOutlined
                  className="float-left cursor-pointer text-base mr-3 text-blue-700"
                  onClick={() => {
                    setMode('EDIT');
                    setAccountData(res);
                    setOpenAccountModal(true);
                  }}
                />
              </Tooltip>
              <Tooltip title="Delete">
                <DeleteOutlined
                  className="float-left cursor-pointer text-base mr-3 text-blue-700"
                  onClick={() => {
                    setAccountData(res);
                    setMessage(`${res.instanceName} account`);
                    setOpenDeleteModal(true);
                  }}
                />
              </Tooltip>
              <div className="float-left project-action text-blue-700 text-base cursor-pointer">
                <ContextMenu
                  contextMenuData={res ? contextMenuData : [...contextMenuData]}
                  func={(e) => {
                    contextMenuFunction(e, res);
                  }}
                  configSection={true}
                />
              </div>
            </div>
          );
          res.slNo = i + 1;
        });
        setAccount((d) => response.data.responseObject);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setAccount([]);
      }
    } catch (err) {
      resetProgress();
      console.error('LAMBDATEST_LISTS : ', err);
    }
  };

  const closeModal = (value) => {
    if (openAccountModal) {
      setOpenAccountModal(false);
    } else if (openDeleteModal) {
      setOpenDeleteModal(false);
    } else if (openDetailsModal) {
      setOpenDetailsModal(false);
    }
  };
  const deleteAccount = () => {
    try {
      deleteLambdaTestInstance(accountData.id).then((response) => {
        if (response.data.responseCode === 200) {
          props.MyAlert.success(
            `${accountData.instanceName}`.length >= 22
              ? `${accountData.instanceName}`.substring(0, 22) + '...' + ' instance is created successfully'
              : `${accountData.instanceName} instance is deleted successfully`
          );
          closeModal();
          reloadpage();
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  // TODO :: handle Search filter :: START
  const [userSearched, setuserSearched] = useState('');
  const [data, setData] = useState([]);
  let [isHighlight, setIsHighlight] = useState(false);
  let [openSearch, setOpenSearch] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');

  useEffect(() => {
    setData(account);

    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [account]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(account);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setData(account);
    setSearchTextHighlight('');
    setIsHighlight(!isHighlight);
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e == 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = account.filter(({ instanceName, username, createdByUname }) => {
          return isValueIncludes(e, instanceName) || isValueIncludes(e, username) || isValueIncludes(e, createdByUname);
        });
        setData(newData);
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  // TODO :: handle Search filter :: END

  const columns = React.useMemo(
    () => [
      {
        Header: 'INSTANCE NAME',
        accessor: 'instanceName',
        disableFilters: true,
        Cell: (e) => (
          <div
            className=" float-left cursor-pointer text-blue-700 name-trimmer"
            title={e.value}
            onClick={() => {
              props.handelInstanceData(e.row.values);
              localStorage.setItem('instanceLocal', e?.row?.values?.instanceName);
              localStorage.setItem('userLocal', e?.row?.values?.username);
              localStorage.setItem('accessKeyLocal', e?.row?.values?.accessKey);
              history.push(`/configuration/integration/plugin/LambdaTest/instanceDetails?instanceName=${e.value}`);
            }}
          >
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'USERNAME',
        accessor: 'username',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <Tooltip title={value} placement="top">
              <div className="table-non-link-color-common float-left name-trimmer">
                <HighlightText text={value} highlight={searchTextHighlight} />
              </div>
            </Tooltip>
          );
        },
      },
      {
        Header: 'ACCESS KEY',
        accessor: 'accessKey',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left  encode overflow-hidden w-24">{value}</div>;
        },
      },
      {
        Header: 'CREATED ON',
        accessor: 'createdOn',
        disableFilters: true,
        Cell: (e) => <span className="float-left ">{e.value}</span>,
      },
      {
        Header: 'CREATED BY',
        accessor: 'createdByUname',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <Tooltip title={value} placement="top">
              <div className=" float-left overflow-hidden overflow-ellipsis whitespace-nowrap w-20">
                {' '}
                <HighlightText text={value} highlight={searchTextHighlight} />
              </div>
            </Tooltip>
          );
        },
      },
      {
        Header: 'ACTIONS',
        accessor: 'Actions',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common ">{value}</div>;
        },
      },
    ],
    [isHighlight]
  );

  useEffect(() => {
    if (reloadTable) {
      setIsLoading(true);
      getLambdaTestInstances();
    }
    setReloadTable(false);
  }, [reloadTable]);
  return (
    <div className="page-table-body">
      <div className="content_area_style_TCM">
        <div className="content_area_style_tcTemplate" id="journal-scroll">
          <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
            <label className="project_label text-sm flex-auto">Lambda Test</label>

            <div className="flex flex-row pr-4 items-center">
              <SearchBar
                open={openSearch}
                searchValue={userSearched}
                handleSearch={handleSearch}
                onSearchClose={handleSearchClose}
                handleSearchOpen={handleSearchOpen}
                enterKeyHandler={handleSearchSubmit}
                disable={isEmptyValue(account)}
              />

              <div className="ml-auto ">
                <button
                  className="ml-3 primary-btn"
                  onClick={() => {
                    setOpenAccountModal(true);
                    setMode('ADD');
                  }}
                >
                  + Instance
                </button>
              </div>
            </div>
          </div>
          <div className="table-height overflow-auto" id="journal-scroll">
            <ReactTable data={data} columns={columns} tableHeightClass={'main-table-height'} />
            {isLoading ? (
              <CommonLoader />
            ) : isEmptyValue(account) ? (
              <div className="fontPoppinsRegularMd no-steps">
                <div className="">
                  <p className="font-bold mb-4">Quick Start</p>
                  <p className="mb-4">
                    <span className="font-bold">Step 1 :</span> Click
                    <button
                      onClick={() => {
                        setOpenAccountModal(true);
                        setMode('ADD');
                      }}
                      className="ml-3 mr-3 primary-btn"
                    >
                      + Instance
                    </button>
                    to start adding Account
                  </p>
                </div>
              </div>
            ) : (
              <>{openSearch && isEmptyValue(data) && <NoResultsFound className="mt-14" />}</>
            )}
          </div>
        </div>
      </div>
      {openAccountModal ? (
        <CreateEditLambdaTestInstance
          closeModal={closeModal}
          reloadTable={reloadpage}
          mode={mode}
          MyAlert={props.MyAlert}
          data={accountData}
        />
      ) : null}
      {openDeleteModal ? <DeleteModal message={message} closeModal={closeModal} method={deleteAccount} /> : null}
      {openDetailsModal ? <LambdaTestDetailsModal data={accountData} closeModal={closeModal} /> : null}
    </div>
  );
};

export default LambdaTestTable;

import { forwardRef, useContext, useEffect, useMemo } from 'react';
import Stepper from '@pages/common/Stepper/Stepper';
import style from '@pages/licenses/styles/license-form.module.scss';
import SelectPlanStep from '@pages/licenses/components/SelectPlanStep';
import PaymentDetailsStep from '@pages/licenses/components/PaymentDetailsStep';
import { LicenseWizardContext } from '@pages/licenses/components/LicenseProvider';
import { LICENSE_FORM_TYPES } from '@util/licenseUtils';
import { getUSDFormatAmountString, isEmptyValue } from '@util/common_utils';
import { LicenseFormHeader } from './LicenseFormHeader';
import style_1 from '@pages/licenses/styles/license-form.module.scss';
export const LICENSE_WIZARD_STEPS = [
  {
    key: 'selectPlan',
    label: 'Select Plan',
    content: SelectPlanStep,
  },
  // {
  //   key: "paymentDetails",
  //   label: "Payment Details",
  //   content: PaymentDetailsStep,
  // },
];

const LicenseWizard = forwardRef((props, ref) => {
  const {
    isPaidPlan,
    handleNextNavigation,
    handleBackNavigation,
    handleFinish,
    formType,
    formik,
    activeStepKey,
    isLicenseDataChanged,
    licenseData,
    parallelRunLimits,
    storageLimits,
  } = useContext(LicenseWizardContext);

  const parallelRunsLimitsCheck = () => {
    if (
      formik?.values?.numberOfParallelRuns >= parallelRunLimits?.min &&
      formik?.values?.numberOfParallelRuns <= parallelRunLimits?.max
    ) {
      return true;
    } else {
      return false;
    }
  };

  const storageLimitsCheck = () => {
    if (formik?.values?.storage >= storageLimits?.min && formik?.values?.storage <= storageLimits?.max) {
      return true;
    } else {
      return false;
    }
  };

  const finishButtonText = useMemo(() => {
    if (isPaidPlan) {
      if (formType === LICENSE_FORM_TYPES.downgrade.type) {
        return 'Downgrade';
      } else if (
        formType === LICENSE_FORM_TYPES.upgrade.type &&
        isLicenseDataChanged &&
        getUSDFormatAmountString(formik.values.grandTotal) <= 0
      ) {
        return 'Upgrade';
      }
      return 'Pay Now';
    }
    return 'Buy At Free';
  }, [isPaidPlan, formType, isLicenseDataChanged, formik.values.grandTotal]);

  const steps = useMemo(() => {
    if (
      !isPaidPlan ||
      formType === LICENSE_FORM_TYPES.downgrade.type ||
      (formType === LICENSE_FORM_TYPES.upgrade.type &&
        isLicenseDataChanged &&
        getUSDFormatAmountString(formik.values.grandTotal) <= 0)
    ) {
      return LICENSE_WIZARD_STEPS.slice(0, 1);
    }
    return LICENSE_WIZARD_STEPS;
  }, [isPaidPlan, formType, isLicenseDataChanged, formik.values.grandTotal]);

  useEffect(() => {
    const pageWrapperElement = ref.current;
    if (pageWrapperElement) {
      const pageWrapperElementScrollTop = pageWrapperElement.scrollTop;
      if (pageWrapperElementScrollTop > 50) {
        pageWrapperElement.scroll({
          top: 50,
          behavior: 'smooth',
        });
      }
    }
  }, [activeStepKey, formik.isSubmitting]);

  const disableNextStep = () => {
    if (
      formType === LICENSE_FORM_TYPES.buy.type &&
      !isEmptyValue(formik.values?.licenseFeatures) &&
      parallelRunsLimitsCheck() &&
      storageLimitsCheck()
    ) {
      return false;
    } else if (
      [LICENSE_FORM_TYPES.renew.type, LICENSE_FORM_TYPES.upgrade.type].includes(formType) &&
      !isEmptyValue(formik.values?.licenseFeatures) &&
      formik.values.grandTotal > 0 &&
      parallelRunsLimitsCheck() &&
      storageLimitsCheck()
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      {LicenseFormHeader(licenseData.licenseName)}
      <div className={style_1['form-outer-card-container']}>
        <Stepper
          steps={steps}
          stepForwardValidator={handleNextNavigation}
          handleFinish={handleFinish}
          finishButtonText={finishButtonText}
          onBack={handleBackNavigation}
          stepButtonClassName={style['step-button-wrapper']}
          stepNavigatorWrapperClassName="text-right"
          isNextDisabled={disableNextStep()}
        />
      </div>
    </>
  );
});

export default LicenseWizard;

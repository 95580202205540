import React, { useState, useEffect } from 'react';
import './filters.css';
import { Checkbox } from 'fireflink-ui';

export const FilterItem = (props) => {
  const [buttonChecked, setButtonChecked] = useState(false);

  useEffect(() => {
    setButtonChecked(props.checked);
  }, [props.checked]);

  const onCheck = (e, label) => {
    if (e.checked) {
      if (!props.checkedExecutionIds.includes(label)) {
        props.checkedExecutionIds.push(label);
      }
      if (!props.checkedLabelsIds.includes(label)) {
        props.checkedLabelsIds.push(label);
      }
    } else {
      const execIndex = props.checkedExecutionIds.findIndex((ind) => ind === label);
      if (execIndex !== -1) {
        props.checkedExecutionIds.splice(execIndex, 1);
      }
      const labelIndex = props.checkedLabelsIds.findIndex((ind) => ind === label);
      if (labelIndex !== -1) {
        props.checkedLabelsIds.splice(labelIndex, 1);
      }
    }
  };


  return (
    <>
      <div className="item">
        <div className='m-4'>
        <Checkbox
          label={props.label}
          name={props.parentName}
          onChange={(e) => {
            setButtonChecked(buttonChecked ? false : true);
            props.handleFilterValues(e.target, props.parentGroup);
            onCheck(e.target, props.label);
          }}
          value={props.label}
          checked={props.checkedExecutionIds.includes(props.label) || props.checkedLabelsIds.includes(props.label) ? true :false}
        />
        </div>
      </div>
    </>
  );
};

import React from 'react';
import '../../auth.scss';
import PrivateKeyAlgorithm from './PrivateKeyAlgorithm';
import AdvancedAlgorithmData from './AdvancedAlgorithmData';
import SecretKeyAlgorithm from './SecretKeyAlgorithm';

const AlgorithmDetails = ({ title, ...props }) => {
  return (
    <div className="jwt-algo-container">
      <h1 className="algo-heading fontPoppinsMediumSm text-center">{title}</h1>
      <div className="payload-container">
        {title?.includes('HMAC') ? <SecretKeyAlgorithm {...props} /> : <PrivateKeyAlgorithm {...props} />}
        <AdvancedAlgorithmData {...props} />
      </div>
    </div>
  );
};

export default AlgorithmDetails;

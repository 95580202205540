import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import StepGroupTree from './step_group_tree';

function LibraryChild(props) {
  const [data, setData] = useState(false);
  const search = window.location.search;
  const id = new URLSearchParams(search).get('id');

  const test = () => {
    setData(true);
  };
  useEffect(() => {
    console.log('inside child library comp');
    test();
  }, [id]);

  return (
    <>
      <StepGroupTree dat={data} pageType="child" MyAlert={props.MyAlert} />
    </>
  );
}

export default withRouter(LibraryChild);

import { useContext } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { LicenseWizardContext } from '@pages/licenses/components/LicenseProvider';
import LabeledInput from '@pages/common/Inputs/LabeledInput';
import LabeledSelect from '@pages/common/Inputs/LabeledSelect';
import TotalAmountCard from '@pages/common/LicensePurchaseComponents/TotalAmountCard';
import AutoRenewLicenseCard from '@pages/common/LicensePurchaseComponents/AutoRenewLicenseCard';
import CreditCardForm from '@pages/common/LicensePurchaseComponents/CreditCardForm';
import cx from 'classnames';
import style from '@pages/licenses/styles/license-form.module.scss';

function PaymentDetailsStep() {
  const { formik, countries, states } = useContext(LicenseWizardContext);
  return (
    <>
      <div className={cx('flex flex-col -mt-2 p-5', style['payment-form-card'])}>
        <div className="flex">
          <div className="flex-initial w-1/3">
            <LabeledInput
              label="Full Name"
              required
              placeholder="Enter full name"
              error={formik.errors.fullName && formik.touched.fullName}
              value={formik.values.fullName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              autoComplete="off"
              name="fullName"
              helperText={<>{formik.touched.fullName && formik.errors.fullName}</>}
              wrapperClassName="w-4/5"
            />
          </div>
          <div className="flex-initial w-1/3">
            <LabeledSelect
              label="Country"
              wrapperClassName="w-4/5"
              required
              error={formik.errors.country && formik.touched.country}
              value={formik.values.country}
              defaultValue=""
              placeholder="Select Country"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              name="country"
              helperText={<>{formik.touched.country && formik.errors.country}</>}
              options={countries}
              labelField="name"
            />
          </div>
          <div className="flex-initial w-1/3">
            <LabeledInput
              label="Address"
              required
              placeholder="Enter address"
              error={formik.errors.address && formik.touched.address}
              value={formik.values.address}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              autoComplete="off"
              name="address"
              helperText={<>{formik.touched.address && formik.errors.address}</>}
              wrapperClassName="w-4/5"
            />
          </div>
        </div>
        <div className="flex">
          <div className="flex-initial w-1/3">
            <LabeledSelect
              label="State"
              wrapperClassName="w-4/5"
              required
              placeholder="Select state"
              error={formik.errors.state && formik.touched.state}
              value={formik.values.state}
              defaultValue=""
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              name="state"
              helperText={<>{formik.touched.state && formik.errors.state}</>}
              options={states}
              labelField="name"
            />
          </div>
          <div className="flex-initial w-1/3">
            <LabeledInput
              label="Apt. Suite"
              placeholder="Enter apartment name"
              error={formik.errors.aptSuite && formik.touched.aptSuite}
              value={formik.values.aptSuite}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              autoComplete="off"
              name="aptSuite"
              helperText={<>{formik.touched.aptSuite && formik.errors.aptSuite}</>}
              wrapperClassName="w-4/5"
            />
          </div>
          <div className="flex-initial w-1/3">
            <div className="flex w-4/5">
              <div className="flex-initial w-1/2">
                <LabeledInput
                  label="City"
                  required
                  placeholder="Enter city name"
                  error={formik.errors.city && formik.touched.city}
                  value={formik.values.city}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  autoComplete="off"
                  name="city"
                  helperText={<>{formik.touched.city && formik.errors.city}</>}
                />
              </div>
              <div className="flex-initial w-1/2 pl-4">
                <LabeledInput
                  label="Zip"
                  required
                  placeholder="Enter zip code"
                  error={formik.errors.zip && formik.touched.zip}
                  value={formik.values.zip}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  autoComplete="off"
                  name="zip"
                  helperText={<>{formik.touched.zip && formik.errors.zip}</>}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex">
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={formik.values.saveAddress}
                onChange={formik.handleChange}
                name="saveAddress"
              />
            }
            className="pl-2.5"
            label={<span className="fontPoppinsRegularMd ml-2">Save this address for future any purchase</span>}
          />
        </div>
      </div>
      <div className="flex flex-wrap my-5 h-full">
        <div className="flex-initial w-8/12 pr-2 flex flex-col justify-between">
          <div className="flex flex-col">
            <CreditCardForm formikObject={formik} />
            <div className="ml-auto mt-3">
              <FormControlLabel
                control={<Checkbox checked={formik.values.saveCard} onChange={formik.handleChange} name="saveCard" />}
                label={<span className="fontPoppinsRegularMd ml-2">Save this card for future any purchase</span>}
              />
            </div>
          </div>
          <AutoRenewLicenseCard name="autoRenew" isChecked={formik.values.autoRenew} onToggle={formik.handleChange} />
        </div>
        <div className="flex-initial w-4/12 pl-2">
          <TotalAmountCard
            netTotal={formik.values.totalCost}
            tax={formik.values.tax}
            grandTotal={formik.values.grandTotal}
            discountLabel="Remaining Usage"
            discount={formik.values.discount}
            className="h-full"
          />
        </div>
      </div>
    </>
  );
}

export default PaymentDetailsStep;

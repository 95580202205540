import React from 'react';
import {
  cancelExecutionReq,
  getAllModuleTreeReq,
  getSingleExecutionDataReq,
  getSingleExecutionDetailsReq,
  reExecuteSuiteReq,
  terminateExecutionSuiteReq,
  getModuleResultTreeReq,
} from '../../../../api/api_services';
import TableTree from '../../../common/table_tree/table_tree';
import { HighlightOffRounded, Sync } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import { getFormattedTextStatus, textColor } from '../../../results/result';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { CommonLoader } from '@src/pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { setIDB } from '@src/util/localForage_idb_controller';

let machineNameArray;
let selectedMechianObj;
const ExecutionSpecificPageSos = () => {
  const selectedExecutionId = window.location.pathname.split('/')[5];
  console.log(`selectedExecutionId`, selectedExecutionId);

  const [executionData, setExecutionData] = React.useState([]);
  const [suiteExecutions, setSuiteExecutions] = React.useState([]);
  const [selectedExecution, setSelectedExecution] = React.useState([]);

  const { resetProgress, startOrResumeProgress } = React.useContext(ProgressContainerContext);
  const [allTreeRootData, setAllTreeRootData] = React.useState({});
  let [isLoading, setIsLoading] = React.useState(false);
  let [treeData, setTreeData] = React.useState([]);
  let [execResponseData, setExecResponseData] = React.useState();
  console.log('treeData', treeData);
  let [rootData, setRootData] = React.useState([]);
  let [isExpandAll, setIsExpandAll] = React.useState(false);
  let renderTree;
  const history = useHistory();
  let [resultStatus, setResultStatus] = React.useState({});
  const [value, setValue] = React.useState(0);

  console.log('machineNameArray', machineNameArray);
  React.useEffect(() => {
    getExecutionData();
    getModuleTree();
  }, []);

  function getExecutionData() {
    console.log('selectedMechianObj', selectedMechianObj);
    getSingleExecutionDataReq(selectedExecutionId)
      .then((results) => {
        const response = results?.data?.responseObject;
        console.log('response', response);
        setExecutionData((d) => response);
        setSuiteExecutions((d) => response?.suiteExecutions);
        if (response?.suiteExecutions && response?.suiteExecutions?.length) {
          if (
            response?.suiteExecutions[0]?.executionRunType === 'Sequential' &&
            response?.suiteExecutions[0]?.executionStatus === 'Pending'
          ) {
            setSelectedExecution(response?.suiteExecutions[0]);
            setDataForSequential(response?.suiteExecutions[0]);
            machineNameArray = [];
          } else {
          }
          if (
            response?.suiteExecutions[0]?.executionRunType === 'Parallel' &&
            response?.suiteExecutions[0]?.executionStatus === 'Pending'
          ) {
            setSelectedExecution(response?.suiteExecutions[0]);
            setDataForParallel(response?.suiteExecutions[0]);
            machineNameArray = response?.suiteExecutions[0]?.suite?.machines?.selectedMachines;
          } else {
          }
          if (
            response?.suiteExecutions[0]?.suiteExecutionRunType === 'Distribute' &&
            response?.suiteExecutions[0]?.executionStatus === 'Pending'
          ) {
            setSelectedExecution(response?.suiteExecutions[0]);
            setDataForDistribute(response?.suiteExecutions[0]);
            machineNameArray = response?.suiteExecutions[0]?.suite?.machines?.selectedMachines;
          } else {
          }
        }

        setExecResponseData(response);
        console.log(`response`, response);
      })
      .catch((error) => {
        console.log(error);
      });
    return [];
  }

  function setDataForSequential(data) {
    resetProgress();
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 80,
    });
    setIsLoading(true);
    if (data) {
      console.log('data', data);
      if (data?.suite.selectedModulesAndScripts[0]?.children?.length) {
        let tempArray = data?.suite.selectedModulesAndScripts[0]?.children;
        tempArray.forEach((obj) => {
          if (obj?.checkbox) {
            obj.checkbox = false;
          }
        });
        console.log('tempArray', tempArray);
        setTreeData(tempArray);
      }
    }
    setTimeout(() => {
      resetProgress();
      setIsLoading(false);
    }, 100);
  }

  function setDataForParallel(data) {
    resetProgress();
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 80,
    });
    setIsLoading(true);
    if (data?.suite?.selectedModulesAndScripts?.[0]?.children?.length) {
      let tempArray = data?.suite?.selectedModulesAndScripts?.[0]?.children;
      tempArray.forEach((obj) => {
        if (obj?.checkbox) {
          obj.checkbox = false;
        }
      });
      setTreeData(tempArray);
    }
    setTimeout(() => {
      resetProgress();
      setIsLoading(false);
    }, 100);
  }

  function setDataForDistribute(data) {
    resetProgress();
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 80,
    });
    setIsLoading(true);
    if (data?.suite?.machines?.distribution.length) {
      let selectedModulesAndScripts = data?.suite?.selectedModulesAndScripts?.[0]?.children;
      console.log('selectedMechianObj,', selectedMechianObj, data?.suite?.machines?.distribution);
      let tempDistributionArray = [];
      if (selectedMechianObj) {
        const clientExist = data?.suite?.machines?.distribution.find(
          (tempObj) => tempObj?.clientSystemId === selectedMechianObj?.clientId
        );
        tempDistributionArray = clientExist?.selectedModules;
      } else {
        tempDistributionArray = data?.suite?.machines?.distribution[0]?.selectedModules;
      }

      console.log('tempDistributionArray', tempDistributionArray);
      let selectedModArray = [];
      tempDistributionArray?.forEach((modObj) => {
        let selModObj = selectedModulesAndScripts.find((treeData) => treeData['key'] === modObj);
        console.log('selModObj', selModObj);
        selectedModArray.push(selModObj);
      });
      console.log('selectedModArray', selectedModArray);
      if (selectedModArray) {
        selectedModArray.forEach((obj) => {
          if (obj?.checkbox) {
            obj.checkbox = false;
          }
        });
        setTreeData(selectedModArray);
      }
    }

    setTimeout(() => {
      resetProgress();
      setIsLoading(false);
    }, 100);
  }

  function getSingleExecutionData() {
    console.log('selectedExecutionId', selectedExecutionId);
    getSingleExecutionDataReq(selectedExecutionId)
      .then((results) => {
        const response = results?.data?.responseObject;
        setExecutionData((d) => response);
        console.log(`response`, response);
        console.log('allTreeRootData', allTreeRootData);
        if (response) {
          if (response?.executionStatus === 'Completed') {
            if (response?.fancyTreeData) {
              if (response?.fancyTreeData.responseObject.executionResponses?.length) {
                const clientExist = response?.fancyTreeData.responseObject.executionResponses.find(
                  (tempObj) => tempObj?.clientId === selectedMechianObj?.clientId
                );
                if (clientExist) {
                  let tempArray = clientExist?.children[0]?.children;
                  tempArray.forEach((obj) => {
                    if (obj?.checkbox) {
                      obj.checkbox = false;
                    }
                  });
                  setTreeData(tempArray);
                  if (clientExist.storageDetails)
                    clientExist.children[0]['storageDetails'] = clientExist.storageDetails;
                }
                if(clientExist?.children[0]) {
                  clientExist.children[0]['executionId'] = selectedExecutionId;
                }
                setAllTreeRootData(clientExist?.children[0]);
                setIDB('tree-data', clientExist?.children[0]);
              }
            } else {
              resetProgress();
              startOrResumeProgress({
                stopAt: 100,
                intervalDuration: 80,
              });
              setIsLoading(true);
              getModuleResultTreeReq(selectedExecutionId)
                .then((res) => {
                  const resp = res?.data?.responseObject;
                  console.log(`resp`, resp);
                  if (resp) {
                    if (resp?.executionResponses?.length) {
                      // resp?.executionResponses?.map(obj=>{
                      const clientExist = resp?.executionResponses.find(
                        (tempObj) => tempObj?.clientId === selectedMechianObj?.clientId
                      );
                      if (clientExist) {
                        if (clientExist?.children[0]?.children) {
                          let tempArray = clientExist?.children[0]?.children;
                          tempArray.forEach((obj) => {
                            if (obj?.checkbox) {
                              obj.checkbox = false;
                            }
                          });
                          setTreeData(tempArray);
                          if (clientExist.storageDetails)
                            clientExist.children[0]['storageDetails'] = clientExist.storageDetails;
                        }
                        if(clientExist?.children[0]) {
                          clientExist.children[0]['executionId'] = selectedExecutionId;
                        }
                        setAllTreeRootData(clientExist?.children[0]);
                        setIDB('tree-data', clientExist?.children[0]);
                      }
                      // })
                    }
                    resetProgress();
                    setIsLoading(false);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          } else if (response?.executionStatus === 'Running') {
            resetProgress();
            startOrResumeProgress({
              stopAt: 100,
              intervalDuration: 80,
            });
            setIsLoading(true);
            getModuleResultTreeReq(selectedExecutionId)
              .then((res) => {
                const resp = res?.data?.responseObject;
                console.log(`resp`, resp, selectedMechianObj);

                if (resp?.executionResponses?.length) {
                  const clientExist = resp?.executionResponses.find(
                    (tempObj) => tempObj?.clientId === selectedMechianObj?.clientId
                  );
                  if (clientExist) {
                    console.log(' obj?.executionStatusObject', clientExist);
                    setResultStatus(clientExist?.executionStatusObject);
                  } else {
                    setResultStatus();
                  }
                }

                if (resp) {
                  if (response?.suite.selectedModulesAndScripts[0]?.children) {
                    let tempArray = response?.suite.selectedModulesAndScripts[0]?.children;
                    tempArray.forEach((obj) => {
                      if (obj?.checkbox) {
                        obj.checkbox = false;
                      }
                    });
                    setTreeData(tempArray);
                  }
                  resetProgress();
                  setIsLoading(false);
                }
              })
              .catch((error) => {
                console.log(error);
                // setIsLoaded(true);
                // setError(error);
              });
          } else {
            console.log('response', response, selectedExecution);
            if (selectedExecution) {
              const suiteExist = suiteExecutions?.find((tempObj) => tempObj?.name === selectedExecution?.suite?.name);
              if (suiteExist) {
                if (suiteExist?.suiteExecutionRunType === 'Sequential' && suiteExist?.executionStatus === 'Pending') {
                  setSelectedExecution(suiteExist);
                  setDataForSequential(suiteExist);
                  machineNameArray = [];
                } else {
                }
                if (suiteExist?.suiteExecutionRunType === 'Parallel' && suiteExist?.executionStatus === 'Pending') {
                  setSelectedExecution(suiteExist);
                  setDataForParallel(suiteExist);
                  machineNameArray = suiteExist?.suite?.machines?.selectedMachines;
                } else {
                }
                if (suiteExist?.suiteExecutionRunType === 'Distribute' && suiteExist?.executionStatus === 'Pending') {
                  setSelectedExecution(suiteExist);
                  setDataForDistribute(suiteExist);
                  machineNameArray = suiteExist?.suite?.machines?.selectedMachines;
                } else {
                }
              }
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return [];
  }

  const onCheckedNodes = (nodes) => {};

  function onNodeSelected() {
    console.log(`clicked`);
  }
  const onStatusClick = (nodeObj) => {
    const _resTreeData = execResponseData?.fancyTreeData?.responseObject?.executionResponses[0]?.children[0]?.children;
    const exec_name = execResponseData.suite.name;
    const exec_id = execResponseData._id;
    let multiple = execResponseData.suite.machines.multiple;
    if (_resTreeData) {
      _resTreeData['executionId'] = exec_id;
      setIDB('tree-data', _resTreeData);
    }
    if (!nodeObj.isRootNode) {
      if (nodeObj.node && nodeObj.node.data.status !== 'SKIP') {
        onNodeSelected(nodeObj);
        if (multiple === 'true') {
          history.push(
            `/execution/suite/${exec_name}/execution dashboard/overview/machines/Result?executionId=${exec_id}&multiple=${multiple}`
          );
        } else {
          history.push(
            `/execution/suite/${exec_name}/execution dashboard/Root Module/results?executionId=${exec_id}&multiple=${multiple}`
          );
        }
      }
    } else {
      if (nodeObj.data && nodeObj.data.status !== 'SKIP') {
        onNodeSelected(nodeObj);
        if (multiple === 'true') {
          history.push(
            `/execution/suite/${exec_name}/execution dashboard/overview/machines/Result?executionId=${exec_id}&multiple=${multiple}`
          );
        } else {
          history.push(
            `/execution/suite/${exec_name}/execution dashboard/Root Module/results?executionId=${exec_id}&multiple=${multiple}`
          );
        }
      }
    }
  };
  async function getModuleTree() {
    setIsLoading(true);
    let getdata;
    let root;
    try {
      getdata = await getAllModuleTreeReq();
      localStorage.setItem('getdata', JSON.stringify(getdata));
      let rootDetails = getdata.data.responseObject.moduleTree[0];
      root = {
        subModuleCount: rootDetails.subModuleCount,
        title: rootDetails.title,
        scriptCount: rootDetails.scriptCount,
        key: rootDetails.key,
        ver: rootDetails.ver,
        modifiedByUname: rootDetails.modifiedByUname,
      };

      // setPreselectedValue(getdata.data.responseObject.moduleTree)

      setAllTreeRootData({ ...root });
      setRootData(root);
      setIsExpandAll(rootDetails.expanded);
      // setTreeData([])
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  const handleChange = (event, newValue) => {
    console.log(newValue, event);
    setValue(newValue);
    console.log('machineNameArray?.[newValue]', machineNameArray?.[newValue]);
    selectedMechianObj = machineNameArray?.[newValue];
    getSingleExecutionData();
  };

  const LABELS = {
    EXPAND_ALL: 'Expand All',
    COLLAPSE_ALL: 'Collapse All',
    SHOW_ALL_ELEMENTS: 'Show all scripts',
    HIDE_ALL_ELEMENTS: 'Hide all scripts',
    SHOW_PRE_POST_CONDITION: 'Show Pre/Post Conditions',
    HIDE_PRE_POST_CONDITION: 'Hide Pre/Post Conditions',
  };

  const colDefs = [
    {
      field: 'title',
      title: 'Module',
      class: 'title',
      isTitle: true,
      width: `calc(100% - 550px)`,
      folderTitleWidth: `calc(100% - 670px)`,
      render: (nodeObj) => {
        console.log(`nodeObj`, nodeObj);

        return <></>;
      },
    },

    {
      field: 'scriptType',
      title: 'Type',
      class: 'Type',
      width: '120px',
      render: (nodeObj) => {
        return <span className="text-xs table-non-link-color-common">{nodeObj.data.scriptType}</span>;
      },
    },
    {
      field: 'status',
      title: 'Status',
      class: 'status',
      width: '100px',
      render: (nodeObj) => {
        console.log('nodeObj', nodeObj);
        const _status =
          nodeObj?.data?.status && nodeObj?.data?.status !== 'disabled' && nodeObj?.data?.status !== 'enabled'
            ? nodeObj?.data?.status
            : resultStatus?.[nodeObj?.data?._key]
              ? resultStatus?.[nodeObj?.data?._key]
              : resultStatus?.[nodeObj?.data?.key];
        return (
          <>
            {_status ? (
              <span
                className={`${
                  nodeObj?.data?.status === 'N/A' ||
                  (nodeObj?.data.type === 'Script' && nodeObj?.data?.status === 'Aborted')
                    ? ''
                    : 'cursor-pointer'
                }  ${textColor(_status)}`}
                name="status"
                onClick={
                  _status.toLowerCase() !== 'skip' || _status.toLowerCase() !== 'n/a'
                    ? () => {
                        if (nodeObj?.data.type === 'Script' && nodeObj?.data?.status === 'Aborted') {
                        } else {
                          onStatusClick(nodeObj);
                        }
                      }
                    : ''
                }
              >
                {getFormattedTextStatus(_status)}
              </span>
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      field: 'modifiedByUname',
      title: 'Modified By',
      class: 'modified-by',
      width: '200px',
      render: (nodeObj) => {
        return (
          <div>
            {!nodeObj.isRootNode && <span className="table-non-link-color-common">{nodeObj.data.modifiedByUname}</span>}
          </div>
        );
      },
    },
  ];

  if (isLoading) {
    renderTree = <CommonLoader />;
  } else if (treeData && treeData.length > 0) {
    renderTree = (
      <TableTree
        isExecutionTree={true}
        data={treeData}
        rootData={allTreeRootData}
        colDefs={colDefs}
        // filter={treeValue}
        expandAll={isExpandAll}
        hideElements={false}
        hideConditions={true}
        // showCheckbox={false}
        //defaultDisabled={executionData?.executionStatus === 'Running' || executionData?.executionStatus === 'Pending' ? [{ key: 'folder', value: true }, { key: 'folder', value: undefined }] : []}
        // conditions={[{ key: 'isPreCondition', value: true }]}
        conditions={[
          { key: 'title', value: 'Preconditions' },
          { key: 'title', value: 'Postconditions' },
        ]}
        hideConditionBtn={false}
        // onCheckedNodes={onCheckedNodes}
        labels={LABELS}
        onNodeSelected={onNodeSelected}
        id="execution-specific-page-sos"
        // treeType="execution"
      />
    );
  } else {
    renderTree = (
      <div>
        <div>
          <TableTree
            data={[]}
            rootData={rootData}
            colDefs={colDefs}
            // filter={treeValue}
            showCheckbox={false}
            onCheckedNodes={onCheckedNodes}
            expandAll={isExpandAll}
            hideConditions={true}
            // hideConditions={true}
            // onRootNodeClicked={rootNodeClicked}
            // conditions={[{ key: "isPreCondition", value: true }]}
            conditions={[
              { key: 'title', value: 'Preconditions' },
              { key: 'title', value: 'Postconditions' },
            ]}
            hideConditionBtn={false}
            labels={LABELS}
            onNodeSelected={onNodeSelected}
            id="execution-specific-page-sos"
          />
        </div>
        <div className="overflow-y-auto h-82">
          <div className="open-sans-regular text-sm mt-28 no-steps">
            <div classname="create-set-layout">
              <div className="qucBtn">
                <label className="quick-start-style px-1  ">No Data Available </label>
                <br></br>
              </div>
              {/* <label className=" quick-start-style mx-1 ">Step1 : click  </label>
                        <button type="button" onClick={() => selectModule("parentTree")} className="select-mod-script-buttton-style mx-1">
                            + Select Modules/Scripts
                        </button><label className="quick-start-label-style">  to start adding the Modules/Scripts</label> */}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function TerminateExecution(execution) {
    console.log('execution', execution);
    terminateExecutionSuiteReq(execution?._id).then((results) => {
      const response = results?.data?.message;
      if (response) {
        console.log('response', response, results);
        getSingleExecutionData();
      } else {
        console.error(results?.data?.message);
      }
    });
  }

  function executeFunction(executionData) {
    reExecuteSuiteReq(executionData?._id).then((results) => {
      const response = results?.data?.responseObject;
      if (response) {
        console.log('response', response);
        history.push(`/execution/suite/${executionData?.name}/suite-dashboard?suiteId=${executionData?.suiteId}`);
      } else if (results?.data?.message && results?.data?.message.toLowerCase().includes('please connect a device')) {
        // MyAlert.info("Device is not available. Please connect a device");
      } else if (results?.data?.message && results?.data?.message.toLowerCase().includes('please install a browser')) {
        //MyAlert.info(`${results?.data?.message}`);
      } else if (
        results?.data?.message &&
        results?.data?.message.startsWith('Client') &&
        results?.data?.message.toLowerCase().includes('is not available')
      ) {
        // MyAlert.info(`${results?.data?.message}`);
      } else {
        console.error(results?.data?.message);
      }
    });
  }

  function cancelExecutionFunction(obj) {
    console.log('obj', obj);
    let statusObj = {
      executionStatus: 'Cancelled',
    };
    cancelExecutionReq(obj?._id, statusObj).then((results) => {
      const response = results?.data?.responseObject;
      if (response) {
        history.push(`/execution/suite/${obj?.name}/suite-dashboard?suiteId=${obj?.suiteId}`);
        //MyAlert.success(`${obj?._id} Suite Cancelled successfully`)
      } else {
        console.error(results?.data?.message);
      }
    });
  }

  function reRunFailed(execution) {
    console.log('execution', execution);
    // if (selectedObj?.fancyTreeData) {

    // } else {
    getSingleExecutionDetailsReq(execution?._id).then((results) => {
      const response = results.data.responseObject;
      if (response) {
        let treeData = response?.executionResponses[0].executionStatusObject;
        let selectedModuleData = execution?.suite?.selectedModulesAndScripts;
        // if (treeData[key] === "FAIL") {
        //     console.log(`[treeObj.key]`, [treeData.key])
        // }
        setPreselectedValue(selectedModuleData[0].children, treeData, execution);
      }
    });
    // }
  }

  const onStatus = (nodeObj) => {
    if (nodeObj && nodeObj.suite.name && nodeObj._id) {
      history.push(
        `/execution/suite/${nodeObj.suite.name}/execution dashboard/Root Module/results?executionId=${nodeObj._id}&multiple=false`
      );
    }
  };

  function setPreselectedValue(selectedModuleData, treeData, selectedObj) {
    if (selectedModuleData.length) {
      selectedModuleData.forEach((treeObj, index) => {
        if (treeObj.folder) {
          if (treeData[treeObj.key] === 'FAIL') {
            let scriptArray = filterFailedScripts(treeObj?.children, treeData, selectedObj);
            treeObj.children = scriptArray;
          } else {
            selectedModuleData.splice(index, 1);
            if (selectedObj?.suite?.selectedModulesStatus) {
              delete selectedObj?.suite?.selectedModulesStatus[treeObj.key];
            }
          }
        }
      });

      selectedObj.suite.selectedModulesAndScripts[0].children = selectedModuleData;
      localStorage.setItem('selectedSuite', JSON.stringify(selectedObj?.suite));
      localStorage.setItem('rerun', JSON.stringify(true));
      history.push('/execution/suite/create-suite');
    }
  }

  function filterFailedScripts(scriptArray, treeData, selectedObj) {
    if (scriptArray && scriptArray.length) {
      scriptArray.forEach((obj, index) => {
        if (obj?.folder) {
          setPreselectedValue(obj, treeData, selectedObj);
        } else {
          if (treeData[obj.key] === 'PASS') {
            // failedModArray.push(treeObj);

            scriptArray.splice(index, 1);
            if (selectedObj?.suite?.selectedModulesStatus) {
              delete selectedObj?.suite?.selectedModulesStatus[obj.key];
            }
          }
        }
      });
      return scriptArray;
    }
  }

  function changeSuiteFunction(e) {
    console.log('e', e);
    if (e?.target?.value) {
      const suiteExist = suiteExecutions?.find((tempObj) => tempObj?.name === e?.target?.value);
      console.log('suiteExist', suiteExist);
      if (suiteExist) {
        if (suiteExist?.suiteExecutionRunType === 'Sequential' && suiteExist?.executionStatus === 'Pending') {
          setSelectedExecution(suiteExist);
          setDataForSequential(suiteExist);
          machineNameArray = [];
        } else {
        }
        if (suiteExist?.suiteExecutionRunType === 'Parallel' && suiteExist?.executionStatus === 'Pending') {
          setSelectedExecution(suiteExist);
          setDataForParallel(suiteExist);
          machineNameArray = suiteExist?.suite?.machines?.selectedMachines;
        } else {
        }
        if (suiteExist?.suiteExecutionRunType === 'Distribute' && suiteExist?.executionStatus === 'Pending') {
          setSelectedExecution(suiteExist);
          setDataForDistribute(suiteExist);
          machineNameArray = suiteExist?.suite?.machines?.selectedMachines;
        } else {
        }
      }
    }
  }

  return (
    <div>
      <div className="content_area_style overflow-auto" id="journal-scroll">
        <div>
          <div className="mt-3 pb-2 suite-of-suite-header-style  flex flex-wrap items-center">
            {/* <div className=" flex flex-wrap items-center"> */}
            <label className="project_label pb-2 text-sm flex-auto">
              {executionData?.suite?.name}
              {executionData?.executionStatus === 'Running' ? (
                <span className="rounded-xl text-xs bg-blue-100 text-blue-700 px-2 py-0.5 ml-3">
                  {executionData?.executionStatus}
                </span>
              ) : executionData?.executionStatus === 'Pending' ? (
                <span className="text-xs rounded-xl bg-yellow-400 text-white px-2 py-0.5 ml-3">Queued</span>
              ) : executionData?.executionStatus === 'Terminated' ? (
                <span
                  className="text-xs rounded-xl bg-red-600 text-white px-2 py-0.5 ml-3"
                  onClick={() => onStatus(executionData)}
                >
                  {executionData?.executionStatus}
                </span>
              ) : executionData?.executionStatus === 'Completed' && executionData?.resultStatus === 'PASS' ? (
                <span
                  className="text-xs rounded-xl bg-green-600 text-white px-2 py-0.5 ml-3 cursor-pointer"
                  onClick={() => onStatus(executionData)}
                >
                  Passed
                </span>
              ) : executionData?.executionStatus === 'Completed' && executionData?.resultStatus === 'FAIL' ? (
                <span
                  className="text-xs rounded-xl bg-red-600 text-white px-2 py-0.5 ml-3 cursor-pointer"
                  onClick={() => onStatus(executionData)}
                >
                  Failed
                </span>
              ) : executionData?.executionStatus === 'Cancelled' ? (
                <span className="text-xs rounded-xl bg-gray-100 text-gray-800 px-2 py-0.5 ml-3">
                  {executionData?.executionStatus}
                </span>
              ) : executionData?.resultStatus === 'WARNING' ? (
                <span
                  className="text-xs rounded-xl warning-status-exe text-white px-2 py-0.5 ml-3 cursor-pointer"
                  onClick={() => onStatus(executionData)}
                >
                  Warning
                </span>
              ) : executionData?.resultStatus === 'Aborted' ? (
                <span
                  className="text-xs rounded-xl bg-red-600 text-white px-2 py-0.5 ml-3 cursor-pointer"
                  onClick={() => onStatus(executionData)}
                >
                  Aborted
                </span>
              ) : executionData?.resultStatus === 'N/A' && executionData?.executionStatus !== 'Terminated' ? (
                <span className="text-xs rounded-xl bg-red-600 text-white px-2 py-0.5 ml-3 cursor-pointer">N/A</span>
              ) : null}
            </label>
            <div className="pr-4">
              <div className="flex flex-row">
                <select className="execution-specific-selectTag" onChange={(e) => changeSuiteFunction(e)}>
                  {suiteExecutions?.map((obj) => {
                    return <option>{obj?.suite?.name}</option>;
                  })}
                </select>
                {executionData?.executionStatus === 'Pending' ? (
                  <>
                    <Tooltip title="Cancel" placement="top">
                      <span className="text-sm text-red-600">
                        {' '}
                        <HighlightOffRounded
                          fontSize="small"
                          className="text-base hyper_link_color-common -mt-1"
                          onClick={() => cancelExecutionFunction(executionData)}
                        />
                      </span>
                    </Tooltip>
                    <Tooltip title="Sync Status" placement="top">
                      <Sync fontSize="small" className="mr-3 ml-3" onClick={getSingleExecutionData} />
                    </Tooltip>
                  </>
                ) : executionData?.executionStatus === 'Running' ? (
                  <>
                    <Tooltip title="Sync Status" placement="top">
                      <Sync fontSize="small" className="mr-3" onClick={getSingleExecutionData} />
                    </Tooltip>
                    <Tooltip title="Terminate" placement="top">
                      <img
                        className="mr-3"
                        src="\assets\execution_logos\terminate.svg"
                        alt="Terminate Execution"
                        onClick={() => TerminateExecution(executionData)}
                      />
                    </Tooltip>
                    <Tooltip title="Run" placement="top">
                      <img
                        className="disable_icon mr-3"
                        src="\assets\execution_logos\execution-run.svg"
                        disabled
                        height="20px"
                        width="20px"
                        alt="Terminate Execution"
                        onClick={() => executeFunction(executionData)}
                      />
                    </Tooltip>
                  </>
                ) : executionData?.executionStatus === 'Terminated' ? (
                  <>
                    <Tooltip title="Sync Status" placement="top">
                      <Sync fontSize="small" className="mr-3" onClick={getSingleExecutionData} />
                    </Tooltip>

                    <Tooltip title="Run" placement="top">
                      <img
                        className="mr-3"
                        src="\assets\execution_logos\execution.svg"
                        height="20px"
                        width="20px"
                        alt="Terminate Execution"
                        onClick={() => executeFunction(executionData)}
                      />
                    </Tooltip>
                  </>
                ) : executionData?.executionStatus === 'Completed' && executionData?.resultStatus === 'PASS' ? (
                  <>
                    <Tooltip title="Sync Status" placement="top">
                      <Sync fontSize="small" className="mr-3" onClick={getSingleExecutionData} />
                    </Tooltip>

                    <Tooltip title="Run" placement="top">
                      <img
                        className="mr-3"
                        src="\assets\execution_logos\execution.svg"
                        height="20px"
                        width="20px"
                        alt="Terminate Execution"
                        onClick={() => executeFunction(executionData)}
                      />
                    </Tooltip>
                  </>
                ) : executionData?.executionStatus === 'Completed' && executionData?.resultStatus === 'FAIL' ? (
                  <>
                    <Tooltip title="Sync Status" placement="top">
                      <Sync fontSize="small" className="mr-3" onClick={getSingleExecutionData} />
                    </Tooltip>
                    <Tooltip title="Run only Failed" placement="top">
                      <img
                        src="\assets\execution_logos\Rerun.svg"
                        className=" cursor-hand mr-3"
                        height="20px"
                        width="20px"
                        onClick={() => reRunFailed(executionData)}
                        alt=""
                      ></img>
                    </Tooltip>
                    <Tooltip title="Run" placement="top">
                      <img
                        className="mr-3"
                        src="\assets\execution_logos\execution.svg"
                        height="20px"
                        width="20px"
                        alt="Terminate Execution"
                        onClick={() => executeFunction(executionData)}
                      />
                    </Tooltip>
                  </>
                ) : executionData?.resultStatus === 'WARNING' ||
                  executionData?.resultStatus === 'Aborted' ||
                  (executionData?.executionStatus !== 'Terminated' && executionData?.resultStatus === 'N/A') ? (
                  <>
                    <Tooltip title="Sync Status" placement="top">
                      <Sync fontSize="small" className="mr-3" onClick={getSingleExecutionData} />
                    </Tooltip>

                    <Tooltip title="Run" placement="top">
                      <img
                        className="mr-3"
                        src="\assets\execution_logos\execution.svg"
                        height="20px"
                        width="20px"
                        alt="Terminate Execution"
                        onClick={() => executeFunction(executionData)}
                      />
                    </Tooltip>
                  </>
                ) : null}
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>

        <div className="table-height-executionDashboard overflow-auto dashboard-table-height" id="journal-scroll">
          {machineNameArray?.length ? (
            <Box sx={{ bgcolor: '#f0f3f9', paddingLeft: '9px' }} className="mb-1">
              <Tabs
                value={value}
                onChange={('handle', handleChange)}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    '&.Mui-disabled': { opacity: 0.3 },
                  },
                }}
              >
                {machineNameArray?.map((obj) => {
                  return <Tab className="tab-font-style fontPoppinsRegularMd" label={obj?.machineInfo?.hostName} />;
                })}
              </Tabs>
            </Box>
          ) : null}

          {renderTree}
        </div>
      </div>
    </div>
  );
};

export default ExecutionSpecificPageSos;

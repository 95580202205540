import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import DefectTab from './defect-tab';

function DefectRouter(props) {
  return (
    <div className="page-list-body">
      <Switch>
        <Route path={['/defect', '/defect/:id/details']} exact={true} strict={true} component={DefectTab}></Route>
      </Switch>
    </div>
  );
}

export default withRouter(DefectRouter);

import ChartCard from '../components/ChartCard';
import ProgressLine from '../components/ProgressLine';
import cx from 'classnames';
import DashboardStyle from '../styles/dashboard.module.scss';
import { _getKBFromStorageString } from './CommonMethods';
import { Tooltip } from '@material-ui/core';

function IndividualProjectDetail({ heading, occupied, total }) {
  const totalInKB = _getKBFromStorageString(total);
  const occupiedInKB = _getKBFromStorageString(occupied);
  return (
    <ChartCard className="text-center p-1">
      <Tooltip title={heading}>
        <h6
          className={cx(
            'fontPoppinsRegularMd text-left mt-1.5 name-trimmer',
            DashboardStyle['individual-project-heading']
          )}
        >
          {heading}
        </h6>
      </Tooltip>
      <div className="grid grid-cols-12 ">
        <div className="col-span-8 mt-2.5 ">
          <ProgressLine total={totalInKB} filled={occupiedInKB} />
        </div>
        <span className="col-span-4 mt-1 fontPoppinsSemiboldXs">{total} </span>
      </div>
      <div className="mt-2"></div>
      <hr />
      <h2 className={cx('mt-2', DashboardStyle['individual-project-footer'])}>
        Total {occupied} occupied out of {total}
      </h2>
    </ChartCard>
  );
}
export default IndividualProjectDetail;

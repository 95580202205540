import React, { useMemo } from 'react';
import { encode, toPascalCase } from '@util/common_utils';
import { ReactComponent as FutureLicenseIcon } from '@assets/svg-imports/license2.0_grey.svg';
import {
  getLicenseFeatures,
  getLicensePeriodStatus,
  hasPaidFuturePlan,
  getRemainingDays,
  showOnlyDate,
  getSuperAdmins,
  FREE_LICENSE_TYPE,
} from '@src/util/licenseUtils';
import styles from './licensedetails.module.scss';
import style from '../styles/licenses.module.scss';
import { ReactComponent as CloseIcon } from '@assets/analytics/ShrinkIconWhite.svg';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import FeaturesTree from './FeaturesTree';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { LICENSE_DETAILS_CONSTANTS } from '@src/common/ui-constants';
import { Tooltip } from '@mui/material';
import LicenseRowCardStyle from '@pages/licenses/styles/license-row-card.module.scss';
import { getNewLicenseTypes } from '@util/licenseUtils';

const LicenseDetails = (props) => {
  let licenseTransactionAllowed = JSON.parse(localStorage.getItem('allowedProjects'))?.features?.licenses;
  const featuresList = getLicenseFeatures(props?.data?.licenseFeatures);

  const handleUpcomingPlanDetails = () => {
    props.setIsLicenseDetailsDrawerOpen(false);
    props.setUpcomingPlanDetails(true);
  };

  let licensePeriodStatus = getLicensePeriodStatus(props?.data);

  const futureLicensePlanDetails = () => {
    let futurePlan = props.data?.futureLicensePlan;
    let futureFeaturesList = getLicenseFeatures(props.data?.futureLicensePlan?.licenseFeatures);
    return (
      <div className={styles.futurePlanDetails}>
        <p className="fontPoppinsRegularSm text-black">{LICENSE_DETAILS_CONSTANTS?.LICENSE_DETAILS_CONSTANTS}</p>
        <div className={styles.futureLicensePlanDetails}>
          <div className={styles.privilegeBlock}>
            <div className={styles.leftBlock}>
              <FutureLicenseIcon className="h-3 w-3" />
              <span className="fontPoppinsRegularSm ml-2">{LICENSE_DETAILS_CONSTANTS?.TYPE} </span>
            </div>
            <div className={styles.rightBLock}>
              <span className="fontPoppinsSemiboldSm">{getNewLicenseTypes(futurePlan?.licenseType)}</span>
            </div>
          </div>
          <div className={styles.featureBlock}>
            <div className={cx(styles.leftBlock, 'pl-2')}>
              <span className="fontPoppinsRegularSm ml-2">{LICENSE_DETAILS_CONSTANTS?.FEATURES} </span>
            </div>
            <div className={styles.rightBLock}>
              <FeaturesTree data={futureFeaturesList} disableClick={true} />
            </div>
          </div>
          <div className={styles.privilegeBlock}>
            <div className={cx(styles.leftBlock, 'pl-6')}>
              <span className="fontPoppinsRegularSm ml-2">{LICENSE_DETAILS_CONSTANTS?.STATUS} </span>
            </div>
            <div className={styles.rightBLock}>
              <span className="fontPoppinsSemiboldSm">{LICENSE_DETAILS_CONSTANTS?.NOT_ACTIVE}</span>
            </div>
          </div>
          <div className={styles.priviligeBlock}>
            <div onClick={handleUpcomingPlanDetails} className="flex items-center justify-end gap-2 cursor-pointer">
              <span className={styles.email}>{LICENSE_DETAILS_CONSTANTS?.MORE_DETAILS}</span>
              <ArrowRightAltIcon sx={{ fill: '#434db8' }} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderDetailsView = (licenseType, privilege) => {
    const {
      licenseStatus,
      remainingDays,
      licenseName,
      licenseTypeDisplay,
      numberOfParallelRuns,
      storage,
      storageUnit,
      paymentDetails,
      startDate,
      createdOn,
      expiryDate,
      futureLicensePlan,
      licenseUsers,
      willBeDeletedIn,
    } = props?.data;

    return (
      <section className={styles.licenseDetails}>
        <div className={styles.fixed}>
          <div className={styles.header}>
            <p className={cx(styles.headerTitle, 'ml-2')}>{LICENSE_DETAILS_CONSTANTS?.TITLE}</p>
            <div
              onClick={() => {
                props.setIsLicenseDetailsDrawerOpen(false);
              }}
              className={styles.closeIcon}
            >
              <CloseIcon className="w2 h-2" />
            </div>
          </div>
          <div className={styles.licenseNameBlock}>
            <div className={cx(styles.leftBlock, 'ml-2')}>
              {props?.licenseIconRenderer('h-4 w-4')}
              <p className="fontPoppinsRegularSm">{LICENSE_DETAILS_CONSTANTS?.LICENSE_NAME} </p>
            </div>
            <div className={styles.rightBlock}>
              <Tooltip
                fontSize="fontPoppinsSemiboldXs"
                placement="left"
                title={licenseName}
                classes={{
                  popper: cx(LicenseRowCardStyle['ToolTipRenderer']),
                }}
              >
                <span className="fontPoppinsSemiboldSm">
                  {licenseName?.length > 30 ? licenseName?.slice(0, 30) + '...' : licenseName}
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={cx(styles.detailsBlock, style['license-scroll'])}>
          <div className={styles.privilegeBlock}>
            <div className={styles.leftBlock}>
              <span className="fontPoppinsRegularSm ml-2">{LICENSE_DETAILS_CONSTANTS?.MY_PRIVILEGE} </span>
            </div>
            <div className={styles.rightBlock}>
              <span className="fontPoppinsSemiboldSm">{userDetails.privilege}</span>
            </div>
          </div>
          <div className={styles.privilegeBlock}>
            <div className={styles.leftBlock}>
              <span className="fontPoppinsRegularSm ml-2">{LICENSE_DETAILS_CONSTANTS?.TYPE} </span>
            </div>
            <div className={styles.rightBlock}>
              <span className="fontPoppinsSemiboldSm">{licenseTypeDisplay || licenseType}</span>
            </div>
          </div>
          <div className={styles.featureBlock}>
            <div className={styles.leftBlock}>
              <span className="fontPoppinsRegularSm ml-2">{LICENSE_DETAILS_CONSTANTS?.FEATURES} </span>
            </div>
            <div className={styles.rightBlock}>
              <FeaturesTree data={featuresList} disableClick={true} />
            </div>
          </div>
          <div className={styles.privilegeBlock}>
            <div className={styles.leftBlock}>
              <span className="fontPoppinsRegularSm ml-2">{LICENSE_DETAILS_CONSTANTS?.STATUS} </span>
            </div>
            <div className={styles.rightBlock}>
              <span
                className={cx(
                  'fontPoppinsSemiboldSm',
                  licenseStatus === 'ACTIVE' ? styles.active : licenseStatus === 'EXPIRED' ? styles.expired : ''
                )}
              >
                {toPascalCase(licenseStatus)}
              </span>
            </div>
          </div>
          <div className={styles.privilegeBlock}>
            <div className={styles.leftBlock}>
              <span className="fontPoppinsRegularSm ml-2">
                {LICENSE_DETAILS_CONSTANTS?.PARALLEL_RUNS_DISPLAY(numberOfParallelRuns, true)}{' '}
              </span>
            </div>
            <div className={styles.rightBlock}>
              <span className="fontPoppinsSemiboldSm">{props?.hasAutomation ? numberOfParallelRuns : '--'}</span>
            </div>
          </div>
          <div className={styles.privilegeBlock}>
            <div className={styles.leftBlock}>
              <span className="fontPoppinsRegularSm ml-2">{LICENSE_DETAILS_CONSTANTS?.STORAGE} </span>
            </div>
            <div className={styles.rightBlock}>
              <span className="fontPoppinsSemiboldSm">
                {storage} {storageUnit || 'GB'}
              </span>
            </div>
          </div>
          {userDetails?.privilege === 'Super Admin' ? (
            <div className={styles.privilegeBlock}>
              <div className={styles.leftBlock}>
                <span className="fontPoppinsRegularSm ml-2">{LICENSE_DETAILS_CONSTANTS?.TOTAL_AMOUNT_PAID} </span>
              </div>
              <div className={styles.rightBlock}>
                <span className="fontPoppinsSemiboldSm">${paymentDetails?.grandTotal || 0} </span>
              </div>
            </div>
          ) : null}
          <div className={styles.privilegeBlock}>
            <div className={styles.leftBlock}>
              <span className="fontPoppinsRegularSm ml-2">{LICENSE_DETAILS_CONSTANTS?.PURCHASED_BY} </span>
            </div>
            <div className={styles.rightBlock}>
              <span className="fontPoppinsSemiboldSm">{ownerDetails.name || '--'} </span>
            </div>
          </div>
          <div className={styles.privilegeBlock}>
            <div className={styles.leftBlock}>
              <span className="fontPoppinsRegularSm ml-2">{LICENSE_DETAILS_CONSTANTS?.PURCHASED_ON} </span>
            </div>
            <div className={styles.rightBlock}>
              <span className="fontPoppinsSemiboldSm">{showOnlyDate(startDate) || showOnlyDate(createdOn)} </span>
            </div>
          </div>
          <div className={styles.privilegeBlock}>
            <div className={styles.leftBlock}>
              <span className="fontPoppinsRegularSm ml-2">{LICENSE_DETAILS_CONSTANTS?.ACTIVATED_ON} </span>
            </div>
            <div className={styles.rightBlock}>
              <span className="fontPoppinsSemiboldSm">{showOnlyDate(startDate) || showOnlyDate(createdOn)} </span>
            </div>
          </div>
          <div className={cx(styles.privilegeBlock, 'border-b-2 border-dashed border-gray-500')}>
            <div className={styles.leftBlock}>
              <span className="fontPoppinsRegularSm ml-2">
                {licenseStatus === 'ACTIVE' ? 'Expiring On' : licenseStatus === 'EXPIRED' ? 'Expired On' : ''}{' '}
              </span>
            </div>
            <div className={styles.rightBlock}>
              <span className="fontPoppinsSemiboldSm">{showOnlyDate(expiryDate) || '--'} </span>
            </div>
          </div>
          {['C-Professional', 'C-Basic'].includes(licenseType) && licenseStatus === 'ACTIVE' ? (
            <div className={styles.expiryDetails}>
              <p className="fontPoppinsSemiboldSm">
                {remainingDays > 0 ? (
                  <>
                    {LICENSE_DETAILS_CONSTANTS?.SUBSCRIPTION_EXPIRES_IN}{' '}
                    <span
                      className={
                        licensePeriodStatus === 'success'
                          ? styles.active
                          : licensePeriodStatus === 'warning'
                          ? styles.expiringSoon
                          : styles.expired
                      }
                    >
                      {remainingDays}&nbsp;{getRemainingDays(remainingDays)}
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      className={
                        licensePeriodStatus === 'success'
                          ? styles.active
                          : licensePeriodStatus === 'warning'
                          ? styles.expiringSoon
                          : styles.expired
                      }
                    >
                      {LICENSE_DETAILS_CONSTANTS?.SUBSCRIPTION_EXPIRING_TODAY}
                    </span>
                  </>
                )}
              </p>
            </div>
          ) : ['C-Professional', 'C-Basic'].includes(licenseType) && licenseStatus === 'EXPIRED' ? (
            <div className={styles.expiryDetails}>
              <p className="fontPoppinsSemiboldSm">
                {LICENSE_DETAILS_CONSTANTS?.SUBSCRIPTION_HAS_EXPIRED}{' '}
                {licenseType === FREE_LICENSE_TYPE ? 'upgraded' : 'renewed'} before{' '}
                <span className={styles.expired}>
                  {willBeDeletedIn}&nbsp;{getRemainingDays(willBeDeletedIn)}&nbsp;
                </span>
                {LICENSE_DETAILS_CONSTANTS?.LICENSE_DELETED_PERMANENTLY}
              </p>
            </div>
          ) : (
            ''
          )}
          {userDetails.privilege === 'Super Admin' ? (
            ''
          ) : (
            <div className={styles.contactBlock}>
              <p className="fontPoppinsRegularSm ml-2">{LICENSE_DETAILS_CONSTANTS?.CONTACT_SUPER_ADMIN}</p>
              {getSuperAdmins(licenseUsers)?.map((user) => {
                let { userId, emailId } = user;
                return (
                  <span className={styles.email} key={userId}>
                    {emailId}{' '}
                  </span>
                );
              })}
            </div>
          )}
          {futureLicensePlan && (
            <span className="ml-2 fontPoppinsSemiboldSm mt-2">{LICENSE_DETAILS_CONSTANTS.UPCOMING_PLAN}</span>
          )}
          {futureLicensePlan && futureLicensePlanDetails()}
        </div>
        {userDetails.privilege === 'Super Admin' && licenseTransactionAllowed ? (
          <div className={styles.buttonBlock}>
            {props?.showRenewButton() && (
              <Link
                to={`/licenses/${encode(props?.data?.id)}/FireFlink License/renew`}
                className={cx(
                  style.primaryBtn,
                  'fontPoppinsRegularXs px-6 py-2.5 text-white rounded-md ml-2 inline-flex items-center'
                )}
              >
                {LICENSE_DETAILS_CONSTANTS?.RENEW}
              </Link>
            )}
            {!hasPaidFuturePlan(props?.data) ? (
              <Link
                to={`/licenses/${encode(props?.data?.id)}/FireFlink License/upgrade`}
                className={cx(
                  style.primaryBtn,
                  'fontPoppinsRegularXs px-6 py-2.5 text-white rounded-md ml-2 inline-flex items-center'
                )}
              >
                {LICENSE_DETAILS_CONSTANTS?.UPGRADE}
              </Link>
            ) : null}
          </div>
        ) : null}
      </section>
    );
  };
  const { ownerDetails, userDetails } = useMemo(() => {
    const user = JSON.parse(localStorage.getItem('test-optimize-user') || '{}');
    return {
      ownerDetails: props.data.licenseUsers.find(({ emailId }) => emailId === props.data.licenseOwner) || {},
      userDetails: props.data.licenseUsers.find(({ emailId }) => user.userName === emailId) || {},
    };
  }, [props.data]);

  if (props?.data?.licenseType && userDetails?.privilege) {
    return renderDetailsView(props.data.licenseType, userDetails.privilege);
  } else {
    return null;
  }
};
export default LicenseDetails;

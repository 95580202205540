export const commonCustomTooltip = ({ series, seriesIndex, w }) => {
  const { labels, colors } = w.globals;
  return labels?.[seriesIndex] !== 'nodata'
    ? `
      <div class="px-4 py-2 float-right border-2 rounded-lg" style="border-color: ${colors?.[seriesIndex]}">
        <span class='bg-inherit'>${labels?.[seriesIndex]} : ${series?.[seriesIndex]}</span>
      </div>
    `
    : null;
};

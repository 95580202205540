import { Tooltip, FormControl } from '@mui/material';
import { TextareaAutosize } from '@mui/base';
import { CalendarTodayOutlined, AddCircle, DeleteOutline } from '@mui/icons-material';
import { Field, FormikProvider } from 'formik';
import DatePickerView from 'react-datepicker';
import { Label } from '@src/common/atoms/LabelComponent';
import InputBox from '@src/common/atoms/InputBoxComponent';
import SelectBox from '@src/common/atoms/SelectComponent';
import cx from 'classnames';
import styles from '@pages/configuration/DefectConfig/defect-config.module.scss';
import IconWithLabelledInput from '@src/common/atoms/icon-with-labelled-input/IconWithLabelledInput';
import { ReactComponent as LinkIcon } from '@assets/svg-imports/ionic-ios-link.svg';
import { ReactComponent as LinkIconBlue } from '@assets/svg-imports/ionic-ios-link-blue.svg';
import { ReactComponent as AttachmentIconBlue } from '@assets/svg-imports/attachment_blue_24dp.svg';
import RichTextEditor from '@pages/common/rich-text-editor';
import { FORM_FIELDS, MESSAGES } from '@pages/defects/defectMgmtConstants';
import TreeWithRadioButton from '@pages/common/table_tree/table-tree-with-radio-button';
import { DEFECT_ATTACTMENT_FILE_ALLOWED } from '@src/util/validations';

export const DefectIssueFields = ({
  data,
  formikProps,
  error,
  formik,
  deleteFile,
  onLoad,
  drawerType,
  moduleTree,
  selectedModule,
  defaultSelectedModule,
  moduleLevelScriptCount,
}) => {
  const { TEXTFIELD, TEXTBOX, DROPDOWN, CHECKBOX, RADIOBUTTON, LINK, ATTACHMENT, DATE, TEXTEDITOR } = FORM_FIELDS;
  const { YES, MAX_NO_OF_FILES_ALLOWED, NO, EDIT } = MESSAGES;
  const handleDataChange = (e, formikProps) => {
    formikProps.handleChange(e);
  };
  const handleClickDatepickerIcon = (id) => {
    if (id) {
      document.getElementById(id).focus();
    }
  };
  const handleSearchableSelectOnChange = (value, label, isSearchable) => {
    formik.setFieldTouched(label, true);
    if (value.length) {
      const fieldValueToUpdate = isSearchable ? value[0].id : value[0].value;
      formik.setFieldValue(label, fieldValueToUpdate);
    } else {
      formik.setFieldValue(label, '');
    }
  };
  const isReadOnly = (label) => {
    return ['Created On', 'Modified On'].includes(label) ? true : false;
  };

  const getSearchableSelectedOption = (data, value) => {
    if (value) {
      if (data.label === 'Assign to') {
        const selectedOption = data?.options?.find((option) => option?.id === value);
        return [
          {
            ...selectedOption,
            label: selectedOption?.name,
            value: selectedOption?.name,
          },
        ];
      } else {
        return [
          {
            label: value,
            value: value,
          },
        ];
      }
    } else {
      return [];
    }
  };

  const formatDate = (dateVal) => {
    return dateVal?.replaceAll('mm', 'MM');
  };
  const navigateToUrl = (url, error) => {
    if (!error && url) {
      window.open(url, '_blank');
    }
  };
  const handleClickBtn = (id) => {
    let htmlEle = document.getElementById(id);
    if (htmlEle) {
      htmlEle.click();
    }
  };
  const moduleButtonField = (data, formikProps, error) => {
    const treeNodeSelected = (nodeObj) => {
      formikProps.setFieldTouched(data.label, true);
      if (nodeObj?.length) {
        formikProps.setFieldValue(data.label, `${nodeObj[0]?.data?.path}:${nodeObj[0]?.data?._key}`, true);
      } else {
        formikProps.setFieldValue(data.label, '', true);
      }
    };

    return (
      <div className="-mt-2">
        <div>
          <TreeWithRadioButton
            editDefect={drawerType === EDIT || selectedModule}
            moduleName={formikProps?.values?.Module}
            moduleSelection={true}
            individualTree={false}
            hideRootRadioBtn={true}
            data={moduleTree}
            operation={null}
            defaultCheckedNode={defaultSelectedModule}
            placeholder={'Select module'}
            nodeSelected={treeNodeSelected.bind(this)}
            hideElements={true}
            hideElementsBtn={true}
            buttonLabel="Module"
            moduleLevelScriptCount={moduleLevelScriptCount}
            defect={true}
          />
        </div>

        {error && formikProps.touched.Module && formikProps.values.Module === '' && (
          <p className={styles['errorText']}>{error}</p>
        )}
      </div>
    );
  };

  const defectMgmtComponents = (data, formikProps, error) => {
    if (data && formikProps) {
      const value = formikProps?.values[data.id];
      const { id, type, enable, placeholder, maxLength, label } = data;
      if (label === 'Module') {
        return moduleButtonField(data, formikProps, error);
      }
      switch (type) {
        case TEXTFIELD:
          return enable === YES ? (
            <div className="">
              <InputBox
                autoComplete="off"
                name={id}
                id={id}
                placeholder={placeholder}
                showLabel={false}
                className="focus:outline-none"
                onBlur={(e) => {
                  formik.handleBlur(e);
                }}
                onInputChange={(e) => handleDataChange(e, formikProps)}
                value={value}
                error={formik.errors[id] && formik.touched[id] ? true : false}
                errMsg={error}
              />
            </div>
          ) : (
            <IconWithLabelledInput
              showLabel={false}
              text={['Created By', 'Modified By'].includes(label) ? value?.name || '--' : value || '--'}
            />
          );

        case TEXTBOX:
          return (
            <>
              {enable === YES ? (
                <div className="">
                  <TextareaAutosize
                    name={id}
                    id={id}
                    onChange={(e) => handleDataChange(e, formikProps)}
                    maxLength={maxLength}
                    value={value}
                    title={!value && placeholder?.length > 30 ? placeholder : value}
                    placeholder={placeholder?.length > 30 ? placeholder?.substr(0, 30) + '...' : placeholder}
                    className="w-full popup-input-bg pl-px mt-1 input-field-color textarea-content-style rs-input-style-textarea"
                    onBlur={(e) => {
                      formik.handleBlur(e);
                    }}
                  />
                  {maxLength ? (
                    <div className="flex justify-between">
                      <div>
                        {formik.errors[id] && formik.touched[id] && <p className={styles['errorText']}>{error}</p>}
                      </div>
                      <div className="mt-1 fontPoppinsRegularSm text-gray-500 text-right mr-2">
                        {value ? value.length : 0}/{maxLength}
                      </div>
                    </div>
                  ) : (
                    <div>
                      {formik.errors[id] && formik.touched[id] && <p className={styles['errorText']}>{error}</p>}
                    </div>
                  )}
                </div>
              ) : (
                <IconWithLabelledInput showLabel={false} text={value || '--'} />
              )}
            </>
          );

        case TEXTEDITOR:
          return (
            <>
              <div className="">
                <FormikProvider value={formik}>
                  <Field name={id}>
                    {({ form, field }) => {
                      const { setFieldValue } = form;
                      const { value } = field;
                      return (
                        <div>
                          {value && (
                            <RichTextEditor
                              readOnly={enable === 'no' ? true : false}
                              toolbarHidden={enable === 'no' ? true : false}
                              convertedContent={value || ''}
                              descriptionContentNotEditable={true}
                              setConvertedContent={(content) => {
                                if (content) {
                                  setFieldValue(id, content);
                                }
                              }}
                            />
                          )}
                          <div>
                            {formik.errors[id] && formik.touched[id] && <p className={styles['errorText']}>{error}</p>}
                          </div>
                        </div>
                      );
                    }}
                  </Field>
                </FormikProvider>
              </div>
            </>
          );

        case RADIOBUTTON:
          return (
            <>
              <div className="w-100 rs-user-input-container">
                <div className="grid grid-cols-2 gap-2">
                  {data?.options?.map((option, index) => (
                    <div key={index} className="flex" title={option}>
                      <input
                        type="radio"
                        onChange={(e) => handleDataChange(e, formikProps)}
                        className="mr-2 mt-0.5"
                        name={id}
                        id={id}
                        value={option}
                        checked={option === value}
                      />
                      <Label label={option} required={false} fontClass={'fontPoppinsRegularMd'} className="truncate" />
                    </div>
                  ))}
                </div>
                {formik.errors[id] && formik.touched[id] && <p className={styles['errorText']}>{error}</p>}
              </div>
            </>
          );

        case LINK:
          return (
            <>
              <div className="flex">
                <Tooltip title="Click here to navigate" placement="top">
                  <div
                    onClick={() => {
                      navigateToUrl(value, formik.errors[id]);
                    }}
                  >
                    {value && !formik.errors[id] ? (
                      <LinkIconBlue className="h-4 w-4 mt-1 mr-2 origin-center cursor-pointer" />
                    ) : (
                      <LinkIcon className="h-4 w-4 mt-1 mr-2 origin-center cursor-pointer" />
                    )}
                  </div>
                </Tooltip>
                <InputBox
                  autoComplete="off"
                  name={id}
                  id={id}
                  placeholder={'Attached Link will be shown here if any URL is added'}
                  showLabel={false}
                  className="focus:outline-none"
                  onBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                  onInputChange={(e) => handleDataChange(e, formikProps)}
                  value={value}
                  error={formik.errors[id] && formik.touched[id] ? true : false}
                  errMsg={error}
                />
              </div>
            </>
          );

        case DROPDOWN:
          const options = data?.options?.map((option) => {
            if (label === 'Assign to') {
              return {
                ...option,
                label: option.name,
                value: option.name,
              };
            } else {
              return {
                label: option,
                value: option,
              };
            }
          });
          const isSearchable = label === 'Assign to';
          return (
            <>
              <div className="">
                <FormControl variant="standard" className="w-full">
                  <div className="">
                    <SelectBox
                      showLabel={false}
                      name={id}
                      options={options}
                      searchable={isSearchable}
                      backspaceDelete={isSearchable}
                      disabled={enable && enable === NO ? true : false}
                      error={formik.errors[id] && formik.touched[id] ? true : false}
                      errMsg={error}
                      placeholder={placeholder ? placeholder : ''}
                      handleOptionChange={(value) => {
                        handleSearchableSelectOnChange(value, label, isSearchable);
                      }}
                      selectedOption={
                        getSearchableSelectedOption(data, value)[0]?.label
                          ? getSearchableSelectedOption(data, value)
                          : []
                      }
                    />
                  </div>
                  {formik.errors[id] && formik.touched[id] && <p className={styles['errorText']}>{error}</p>}
                </FormControl>
              </div>
            </>
          );

        case DATE:
          return (
            <>
              <div className="flex">
                <div className=" h-8 border-2 border-gray-300">
                  <FormikProvider value={formik}>
                    <Field name={id}>
                      {({ form, field }) => {
                        const { setFieldValue } = form;
                        const { value } = field;
                        return (
                          <DatePickerView
                          popperPlacement="bottom"
                            readOnly={isReadOnly(label)}
                            className="h-7"
                            id={id}
                            {...field}
                            selected={value}
                            onChange={(val) => {
                              setFieldValue(id, val);
                            }}
                            onKeyDown={(e) => e.preventDefault()}
                            dateFormat={
                              data?.dateFormat && typeof data.dateFormat === 'string'
                                ? formatDate(data.dateFormat)
                                : 'dd/MM/yyyy'
                            }
                            maxDate={new Date()}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                            }}
                          />
                        );
                      }}
                    </Field>
                  </FormikProvider>
                </div>
                <div className=" bg-gray-200" onClick={() => handleClickDatepickerIcon(id)}>
                  <CalendarTodayOutlined className="mt-1" />
                </div>
              </div>
              <div className="fontPoppinsRegularSm opacity-50">
                [{data?.dateFormat && Array.isArray(data.dateFormat) ? data.dateFormat.join(',') : data?.dateFormat}]
              </div>
              {formik.errors[id] && formik.touched[id] && <p className={styles['errorText']}>{error}</p>}
            </>
          );

        case CHECKBOX:
          return (
            <>
              <div className="w-100">
                <div className="grid grid-cols-2 gap-2">
                  {data?.options?.map((option, index) => (
                    <div key={index} className="flex" title={option}>
                      <input
                        type="checkbox"
                        className="mr-2"
                        name={id}
                        id={id}
                        value={option}
                        onChange={(e) => {
                          handleDataChange(e, formikProps);
                        }}
                        checked={value && value?.length > 0 && value.includes(option)}
                      />
                      <Label label={option} required={false} fontClass={'fontPoppinsRegularMd'} className="truncate" />
                    </div>
                  ))}
                </div>
                {formik.errors[id] && formik.touched[id] && <p className={styles['errorText']}>{error}</p>}
              </div>
            </>
          );

        case ATTACHMENT:
          return (
            <>
              <div className="">
                <FormikProvider value={formik}>
                  <Field name={id}>
                    {({ form, field }) => {
                      const { setFieldValue } = form;
                      const { value } = field;
                      return (
                        <>
                          <button onChange={(event) => onLoad(event, data, setFieldValue, value)} className="hidden">
                            <input type="file" id={id} multiple accept={Object.keys(DEFECT_ATTACTMENT_FILE_ALLOWED)} />
                          </button>
                          {value?.length > 0 ? (
                            value?.map((option, index) => (
                              <div key={index} className="flex flex-row">
                                <div className="flex items-center fontPoppinsRegularMd">
                                  <Tooltip title={option?.name} placement="top">
                                    <p className={cx('fontPoppinsRegularSm truncate w-5/6', styles['clickable-text'])}>
                                      {option?.name}
                                    </p>
                                  </Tooltip>
                                  <Tooltip title="Delete" placement="top">
                                    <DeleteOutline
                                      color="primary"
                                      className="h-6 w-6 mt-1 mr-2 origin-center cursor-pointer"
                                      onClick={() => {
                                        deleteFile(option);
                                        const filteredValue = value?.filter((file) => {
                                          return file?.name !== option?.name;
                                        });
                                        setFieldValue(id, filteredValue);
                                      }}
                                    />
                                  </Tooltip>
                                  {index === value?.length - 1 && (
                                    <Tooltip
                                      title={value?.length >= 5 ? `${MAX_NO_OF_FILES_ALLOWED}` : 'Add File'}
                                      placement="top"
                                    >
                                      <button
                                        type="button"
                                        disabled={value?.length >= 5}
                                        onClick={() => handleClickBtn(id)}
                                      >
                                        <AddCircle
                                          className={
                                            value?.length >= 5
                                              ? ' text-gray-400'
                                              : 'text-blue-700 h-6 w-6 mt-1 mr-2 origin-center cursor-pointer'
                                          }
                                        />
                                      </button>
                                    </Tooltip>
                                  )}
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="flex flex-row">
                              <div className="flex items-center cursor-pointer" onClick={() => handleClickBtn(id)}>
                                <Tooltip title="Attach file" placement="top">
                                  <div className="flex">
                                    <AttachmentIconBlue className="h-6 w-6 mr-2 origin-center" />

                                    <p
                                      aria-disabled={true}
                                      className={cx('fontPoppinsRegularMd', styles['clickable-text'])}
                                    >
                                      Attach
                                    </p>
                                  </div>
                                </Tooltip>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    }}
                  </Field>
                </FormikProvider>
              </div>
              {formik.errors[id] && formik.touched[id] && <p className={styles['errorText']}>{error}</p>}
            </>
          );

        default:
          return <Label required={false} fontClass={'fontPoppinsRegularSm'} className="truncate" />;
      }
    }
  };

  return <>{defectMgmtComponents(data, formikProps, error)}</>;
};

import React, { useState, useEffect, useRef } from 'react';
import { RiArrowDownSFill, RiArrowRightSFill } from 'react-icons/ri';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { getFormattedTextStatus, textColor } from '@pages/results/result';
import DataSetModal from '@pages/results/components/modals/data_set_modal';
import StepResultInnerModal from '../components/modals/step-result-inner-modal';
import { getStatus, getOverallStatus, getDependsOnScriptStepResult, accordionFilterClass, TooltipPoppin } from './util';
import { noScriptDataFoundMsg } from '@common/ui-constants';
import { getStepId } from '@src/pages/test-development/shared/common-methods';
import { getStepResult } from '@api/api_services';
import { Icon } from 'fireflink-ui';

const ScriptResultAccordion = (props) => {
  let { selectedScript } = props;
  const [executionId, setExecutionId] = useState(selectedScript?.executionId || '');
  let [scriptData, setScriptData] = useState(props['content']);
  let [openStepModal, setOpenStepModal] = useState(false);
  let [openDataSetModal, setOpenDataSetModal] = useState(false);
  let [stepResultData, setStepResultData] = useState({});
  let [dataSetData, setDataSetData] = useState({});
  let [filteredStatus, setFilteredStatus] = useState('ALL');
  let [displayFilterOption, setDisplayFilterOption] = useState(false);
  let [filterIcon, setFilterIcon] = useState(false);
  let [hasIteration, setHasIteration] = useState(false);
  let [elementHover, setElementHover] = useState(false);

  const ifElseConditionsList = [
    'If Condition',
    'Else If Condition',
    'End If Condition',
    'End Else If Condition',
    'Else Condition',
    'End Else Condition',
  ];

  const [stepPathQueue, setStepPathQueue] = useState([]);

  useEffect(() => {
    let stepPath = localStorage.getItem('stepPath');
    if (stepPath && stepPath.length) setStepPathQueue(JSON.parse(stepPath));
  }, []);

  useEffect(() => {
    setScriptData(props.content);
  }, [props.content, props.reloadAccordion]);

  const [showOptions, setShowOptions] = useState(false);

  // if click outside of dropdown also it will close
  let menuRef = useRef();
  useEffect(() => {
    let handeler = (event) => {
      if (menuRef.current && !menuRef?.current?.contains(event.target)) {
        setShowOptions(false);
      }
    };
    document.addEventListener('mousedown', handeler);
    return () => {
      document.removeEventListener('mouseleave', handeler);
    };
  }, []);

  const closeStepModal = (val) => {
    setOpenStepModal(val);
  };
  const closeDataSetModal = (val) => {
    setOpenDataSetModal(val);
  };

  let accordionIcon = (elementId, stepGroupid) => {
    let data = elementId;
    if (elementId === '00:00:00:00') {
      data = stepGroupid;
    }
    return (
      <span className="cursor-pointer">
        <RiArrowRightSFill className="expand-icon" id={`${data}-expand`} />
        <RiArrowDownSFill className="collapse-icon" id={`${data}-collapse`} />
      </span>
    );
  };

  let toggleAccordionIcon = (event, elementId, min, stepGroupResult) => {
    if (!event?.target?.id.includes('viewdataset_')) {
      let data = elementId;
      if (stepGroupResult) {
        if (
          stepGroupResult.executionDurationInHourMinSecFormat &&
          stepGroupResult.executionDurationInHourMinSecFormat !== '00:00:00:00'
        ) {
          data = stepGroupResult.executionDurationInHourMinSecFormat;
        }
      }
      let expandElement = document.getElementById(data + '-expand');
      let collapseElement = document.getElementById(data + '-collapse');
      if (expandElement && expandElement.style) {
        let accordionHtmlElement = expandElement.closest('.accordion');
        const panelHtmlElement = accordionHtmlElement.nextSibling;
        accordionHtmlElement.classList.toggle('active');
        if (expandElement.style.display === 'none') {
          expandElement.style.display = 'inline-block';
          if (panelHtmlElement) panelHtmlElement.style.display = 'none';
        } else if (expandElement.style.display !== 'none') {
          expandElement.style.display = 'none';
          if (panelHtmlElement) panelHtmlElement.style.display = 'block';
        }
      }
      if (collapseElement && collapseElement.style) {
        const accordionHtmlElement = collapseElement.parentElement.parentElement.parentElement.parentElement;
        const panelHtmlElement = accordionHtmlElement.nextSibling;
        accordionHtmlElement.classList.toggle('active');
        if (collapseElement.style.display === 'none' || collapseElement.style.display === '') {
          collapseElement.style.display = 'inline-block';
          if (panelHtmlElement) panelHtmlElement.style.display = 'block';
        } else if (collapseElement.style.display !== 'none') {
          collapseElement.style.display = 'none';
          if (panelHtmlElement) panelHtmlElement.style.display = 'none';
        }
      }
    }
  };

  let viewStepResultModal = (stepResult) => {
    if (stepResult.status && stepResult.status !== 'SKIP') {
      if (stepResult?.uniqueId) {
        updateStepResult(stepResult.uniqueId);
      } else {
        setOpenStepModal(true);
        setStepResultData(stepResult);
      }
    }
  };

  const updateStepResult = async (uniqueId) => {
    const response = await getStepResult(uniqueId);
    if (response?.data?.responseObject) {
      setStepResultData(response?.data?.responseObject);
      setOpenStepModal(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const stepHTMLElement = document.getElementById('steps');
      if (stepHTMLElement) {
        toggleAccordionIcon(null, 'steps');
      }
    }, 100);
  }, []);

  let stepsResult = (stepResult, stepStatistics, marginLeftSize, iterationMarginLeft) => {
    return (
      <div>
        <div
          className="accordion"
          onClick={(e) => {
            toggleAccordionIcon(e, 'steps');
          }}
        >
          <div className="grid grid-cols-5">
            <div className="result-subHeader col-span-3" id="steps" style={{ marginLeft: marginLeftSize + 'rem' }}>
              Steps
              {accordionIcon('steps')}
              {executionStatistics(stepStatistics)}
              {hasIteration ? (
                <TooltipPoppin title={'Aggregated count of steps from all iteration is '} placement="right">
                  <InfoOutlinedIcon
                    className="ml-2 cursor-pointer"
                    color="#525050"
                    style={{ fontSize: '14px' }}
                  ></InfoOutlinedIcon>
                </TooltipPoppin>
              ) : (
                ''
              )}
            </div>
            <div className={`result-status flex items-center cursor-pointer ${textColor(getStatus(stepStatistics))}`}>
              {filteredStatus === 'ALL' ? getFormattedTextStatus(getStatus(stepStatistics)) : ''}
            </div>
            <div className="result-duration flex items-center">
              {stepStatistics?.executionDurationInHourMinSecFormat}
            </div>
          </div>
        </div>
        <div className="panel ">
          {stepResult !== undefined
            ? marginLeftSize
              ? steps(
                  stepResult,
                  'steps',
                  Number(marginLeftSize) + Number(1.3),
                  Number(iterationMarginLeft) + Number(1.3)
                )
              : steps(stepResult, 'steps', '1.30', '')
            : ''}
        </div>
      </div>
    );
  };

  let conditions = (conditionResult, conditionType, conditionStatistics, marginLeftSize) => {
    return (
      <div>
        <div
          className="accordion"
          onClick={(e) => {
            toggleAccordionIcon(e, conditionType);
          }}
        >
          <div className="grid grid-cols-5">
            <div
              className="result-subHeader col-span-3"
              id={conditionType}
              style={{ marginLeft: marginLeftSize + 'rem' }}
            >
              {conditionType}
              {accordionIcon(conditionType)}
              {executionStatistics(conditionStatistics)}
            </div>
            <div className={`result-status flex items-center cursor-pointer ${textColor(conditionResult?.status)}`}>
              {filteredStatus === 'ALL' ? getFormattedTextStatus(conditionResult?.status) : ''}
            </div>
            <div className="result-duration flex items-center">
              {conditionStatistics?.executionDurationInHourMinSecFormat}
            </div>
          </div>
        </div>
        <div className="panel ">
          {conditionResult?.stepResults.map((stepGroup, index) => (
            <div className={`${accordionFilterClass(stepGroup?.stepResultStats, filteredStatus)}`}>
              {
                <div
                  className="accordion"
                  key={`precondition${index}`}
                  onClick={(e) => {
                    toggleAccordionIcon(e, `${conditionType}${index}`);
                  }}
                >
                  <div className="grid grid-cols-5">
                    <div
                      className={`step-result flex items-center cursor-pointer col-span-3 pt-1`}
                      style={{ marginLeft: marginLeftSize ? Number(marginLeftSize) + Number(1.3) + 'rem' : '1.30rem' }}
                    >
                      <span title={stepGroup?.name} className="max-w-xs truncate">
                        {index + 1}.{stepGroup?.name}
                      </span>
                      {accordionIcon(`${conditionType}${index}`)}
                      {executionStatistics(stepGroup?.stepResultStats)}
                    </div>
                    <div className="flex-wrapper">
                      <div className={`result-status flex items-center cursor-pointer ${textColor(stepGroup?.status)}`}>
                        {filteredStatus === 'ALL' ? getFormattedTextStatus(stepGroup?.status) : ''}
                      </div>
                    </div>
                    <div className="result-duration flex items-center">
                      {stepGroup?.executionDurationInHourMinSecFormat}
                    </div>
                  </div>
                </div>
              }
              <div className="panel ">
                {stepGroup?.stepResults !== undefined &&
                  steps(
                    stepGroup?.stepResults,
                    conditionType,
                    Number(marginLeftSize ? marginLeftSize : '') + Number(1.3) + Number(1.3)
                  )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  function statusFilter() {
    return (
      <div
        className="status-filter absolute flex flex-col mt-6 step-result cursor-pointer mx-7 z-10"
        onClick={() => {
          setDisplayFilterOption(!displayFilterOption);
        }}
      >
        <div
          className={`result-primary filter mt-1 ${filteredStatus === 'ALL' ? 'selected-filter' : ''}`}
          onClick={() => {
            setFilteredStatus('ALL');
            setFilterIcon(false);
            setOpenStepModal(false);
          }}
        >
          <div className=" mx-4">All</div>
        </div>
        <div
          className={`result-passed filter ${filteredStatus === 'PASS' ? 'selected-filter' : ''}`}
          onClick={() => {
            setFilteredStatus('PASS');
            setFilterIcon(true);
            setOpenStepModal(false);
          }}
        >
          <div className=" mx-4">Passed</div>
        </div>
        <div
          className={`result-failed filter ${filteredStatus === 'FAIL' ? 'selected-filter' : ''}`}
          onClick={() => {
            setFilteredStatus('FAIL');
            setFilterIcon(true);
            setOpenStepModal(false);
          }}
        >
          <div className=" mx-4">Failed</div>
        </div>
        <div
          className={`result-warning filter ${filteredStatus === 'WARNING' ? 'selected-filter' : ''}`}
          onClick={() => {
            setFilteredStatus('WARNING');
            setFilterIcon(true);
            setOpenStepModal(false);
          }}
        >
          <div className=" mx-4">Warning</div>
        </div>
        <div
          className={`result-skipped filter mb-1 ${filteredStatus === 'SKIP' ? 'selected-filter' : ''}`}
          onClick={() => {
            setFilteredStatus('SKIP');
            setFilterIcon(true);
            setOpenStepModal(false);
          }}
        >
          <div className=" mx-4">Skipped</div>
        </div>
      </div>
    );
  }

  function scriptResult() {
    return (
      <div
        className="p-4 overflow-auto result-body result-body-big-modal relative"
        onClick={() => setDisplayFilterOption(false)}
      >
        {scriptData?.dependantExecutionEntityResponses &&
          dependsOnScripts(scriptData?.dependantExecutionEntityResponses, scriptData?.dependantScriptStats)}
        {/* Iteration starts */}
        {stepCondition()}
        {/* Iteration ends */}
        {openStepModal && (
          <StepResultInnerModal
            stepResult={stepResultData}
            closeStepModal={closeStepModal}
            OpenExeCompInnerModalStyle={false}
          />
        )}
        {openDataSetModal && <DataSetModal dataSetData={dataSetData} closeDataSetModal={closeDataSetModal} />}
      </div>
    );
  }

  const stepCondition = () => {
    let returnStepCondition;
    if (scriptData?.executionEntityResponses) {
      returnStepCondition = iterationIncludingPrePostCondition(scriptData);
    } else {
      returnStepCondition = (
        <>
          {scriptData?.preConditions !== undefined &&
            conditions(scriptData?.preConditions, 'Preconditions', scriptData?.preConditionStats)}
          {scriptData?.stepResults !== undefined && stepsResult(scriptData?.stepResults, scriptData?.stepResultStats)}
          {scriptData?.postConditions !== undefined &&
            conditions(scriptData?.postConditions, 'Postconditions', scriptData?.postConditionStats)}
        </>
      );
    }
    return returnStepCondition;
  };

  function dependsOnScripts(dependantScriptResult, dependantScriptStatistics) {
    return (
      <div>
        <div
          className="accordion"
          onClick={(e) => {
            toggleAccordionIcon(e, 'dependsOnScripts');
          }}
        >
          <div className="grid grid-cols-5">
            <div className="result-subHeader col-span-3" id="dependsOnScripts">
              Depends On Scripts
              {accordionIcon('dependsOnScripts')}
              {executionStatistics(dependantScriptStatistics)}
            </div>
            <div
              className={`result-status flex items-center cursor-pointer ${textColor(getStatus(dependantScriptStatistics))}`}
            >
              {filteredStatus === 'ALL' ? getFormattedTextStatus(getStatus(dependantScriptStatistics)) : ''}
            </div>
            <div className="result-duration flex items-center">
              {dependantScriptStatistics?.executionDurationInHourMinSecFormat}
            </div>
          </div>
        </div>
        <div className="panel ">
          {dependantScriptResult.map(
            (dependant, index) =>
              (dependant?.status === filteredStatus || filteredStatus === 'ALL') && (
                <div className="grid grid-cols-5 step-header" key={`dependantScript${index}`}>
                  <div
                    className={`col-span-3 ml-5 step-result flex items-center cursor-pointer`}
                    onClick={() => getDependsOnScriptStepResult(dependant, executionId, scriptData.selectedSystem.clientId, scriptData.runId, setScriptData)}
                  >
                    <span title={dependant?.name} className="truncate pr-2">
                      {' '}
                      {index + 1}.{dependant?.name}{' '}
                    </span>
                  </div>
                  <div className="flex-wrapper">
                    <div
                      className={`result-status flex items-center cursor-pointer  ${textColor(dependant?.status)}`}
                      onClick={() => getDependsOnScriptStepResult(dependant, executionId, scriptData.selectedSystem.clientId, scriptData.runId , setScriptData)}
                    >
                      {' '}
                      {filteredStatus === 'ALL' ? getFormattedTextStatus(dependant?.status) : ''}
                    </div>
                  </div>
                  <div className="result-duration flex items-center">
                    {dependantScriptStatistics?.executionDurationInHourMinSecFormat}
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    );
  }
  /**
   * grouping the iteration steps based on iteration numbers
   * @param {*} objectArray the array of objects from which we have to group all
   * @param {*} property property/key by which has to be grouped
   * @param {*} type if its including or excluding pre/post conditions
   * @returns
   */
  function groupBy(objectArray, property, type) {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (key !== undefined) {
        if (!acc[key]) {
          acc[key] = [];
        }
        // Add object to list for given key's value
        acc[key].push(obj);
      }
      return acc;
    }, {});
  }

  function viewDataSet(iteArr) {
    let dataSetArr = [];
    let serialNumber = 0;
    iteArr.itr.forEach((_ite, index) => {
      if (_ite?.elementDetails?.length) {
        _ite.elementDetails.forEach((element) => {
          if (element?.locators?.length) {
            element.locators.forEach((locator) => {
              if (locator?.dynamicLocatorArray?.length) {
                locator.dynamicLocatorArray.forEach((dynamicLocator) => {
                  if (dynamicLocator?.reference === 'DATAPROVIDER') {
                    let dataSet = Object.assign(dynamicLocator);
                    dataSet['step'] = ++serialNumber;
                    dataSet['actualValue'] = locator.actualValue;
                    dataSetArr.push(dataSet);
                  }
                });
              }
            });
          }
        });
      }

      _ite.stepInputs &&
        _ite.stepInputs.forEach((_stp) => {
          if (_stp.reference === 'DATAPROVIDER') {
            let _eachDataSet = Object.assign(_stp);
            _eachDataSet['step'] = ++serialNumber;
            dataSetArr.push(_eachDataSet);
          }
        });
    });
    setDataSetData(dataSetArr);
    setOpenDataSetModal(true);
  }

  function iterationDataIncludingPrePostCondition(iterationSteps, iterationType, marginLeftSize, iterationMarginLeft) {
    let _ite_arr = groupBy(iterationSteps, 'iteration', 'including_iteration');
    let iterations = Object.keys(_ite_arr);
    return (
      <div>
        {iterations.map((key, ite_index) => (
          <div>
            <div
              className="accordion"
              onClick={(e) => {
                toggleAccordionIcon(e, `Iteration${ite_index + 1}`);
              }}
            >
              <div className="grid grid-cols-5">
                <div
                  className={`iteration-step col-span-3 ${textColor(iterationSteps?.status)}`}
                  id={`Iteration${ite_index + 1}`}
                  style={{ marginLeft: marginLeftSize + 'rem' }}
                >
                  Iteration {ite_index + 1}
                  {accordionIcon(`Iteration${ite_index + 1}`)}
                  {iterationType === 'DATAPROVIDER' ? (
                    <span
                      className="ml-1 iteration-data-set cursor-pointer"
                      onClick={() => viewDataSet(iterationSteps)}
                      id={`viewdataset_${ite_index + 1}`}
                    >
                      View Dataset
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div className="flex-wrapper">
                  <div
                    className={`result-status flex items-center cursor-pointer ${textColor(iterationSteps?.status)}`}
                  >
                    {getFormattedTextStatus(iterationSteps?.status)}
                  </div>
                </div>
                <div className="result-duration flex items-center">
                  {iterationSteps?.executionDurationInHourMinSecFormat}
                </div>
              </div>
            </div>
            <div className="panel ">
              {_ite_arr[key][0]?.preConditions &&
                conditions(
                  _ite_arr[key][0]?.preConditions,
                  'Preconditions',
                  _ite_arr[key][0]?.preConditionStats,
                  Number(marginLeftSize) + Number(1.3)
                )}
              {_ite_arr[key][0]?.stepResults !== undefined &&
                stepsResult(
                  _ite_arr[key][0]?.stepResults,
                  _ite_arr[key][0]?.stepResultStats,
                  Number(marginLeftSize) + Number(1.3),
                  Number(iterationMarginLeft) + Number(1.3)
                )}
              {_ite_arr[key][0]?.postConditions &&
                conditions(
                  _ite_arr[key][0]?.postConditions,
                  'Postconditions',
                  _ite_arr[key][0]?.postConditionStats,
                  Number(marginLeftSize) + Number(1.3)
                )}
            </div>
          </div>
        ))}
      </div>
    );
  }

  function iterationIncludingPrePostCondition(step) {
    return (
      <div>
        <div
          className="accordion"
          onClick={(e) => {
            toggleAccordionIcon(e, 'start_Iteration');
          }}
        >
          <div className="grid grid-cols-5">
            <div className="iteration-header col-span-3">
              Start Iteration using Dataprovider
              {accordionIcon(`start_Iteration`)}
              {executionStatistics(step.stepResultStats)}
            </div>
            <div></div>
            <div className="result-duration flex items-center"></div>
          </div>
        </div>
        <div className="panel ">
          {iterationDataIncludingPrePostCondition(step.executionEntityResponses, 'DATAPROVIDER', '1.30', '1.30')}
        </div>
        <div
          className="iteration-header"
          onMouseOver={(e) => {
            onIterationHeaderFocus(e);
          }}
          id={`end_Iteration`}
        >
          End Iteration using dataprovider
        </div>
      </div>
    );
  }

  const getIterationList = (_iteArr, step, stepindex) => {
    const arr = Object.values(_iteArr);
    const iterationList = [];
    let _stepId = 'Iteration_';
    if (step.hasOwnProperty('iteration') && step.iteration !== null) {
      _stepId = _stepId + step.iteration + stepindex + '_' + step.stepId;
    } else {
      _stepId = _stepId + stepindex + '_' + step.stepId;
    }
    arr.forEach((_eachArr, index) => {
      let _status = getOverallStatus(_eachArr);
      iterationList.push({ itr: _eachArr, status: _status, ite_id: _stepId + '_' + index });
    });
    return iterationList;
  };

  function iterationData(step, iterationType, stepGroupIndex, marginLeftSize, iterationMarginLeft) {
    let _ite_arr = groupBy(step.stepResults, 'iteration');
    if (!hasIteration) {
      setHasIteration(true);
    }
    let iterationList = getIterationList(_ite_arr, step, stepGroupIndex);
    return (
      <div>
        {iterationList &&
          iterationList.map((_itr, ite_index) => {
            const _id = getUniqueId();
            return (
              <div>
                <div
                  className="accordion"
                  onClick={(e) => {
                    toggleAccordionIcon(e, _itr.ite_id + _id);
                  }}
                >
                  <div className="grid grid-cols-5 pt-2">
                    <div
                      className={`iteration-step col-span-3 `}
                      id={_itr.ite_id + _id}
                      style={{ marginLeft: marginLeftSize ? marginLeftSize + 'rem' : iterationMarginLeft + 'rem' }}
                    >
                      Iteration {ite_index + 1}
                      {accordionIcon(_itr.ite_id + _id)}
                      {iterationType === 'DATAPROVIDER' ? (
                        <span
                          className="ml-1 iteration-data-set cursor-pointer"
                          onClick={() => viewDataSet(_itr)}
                          id={`viewdataset_${ite_index + 1}`}
                        >
                          View Dataset
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="flex-wrapper">
                      <div className={`result-status flex items-center cursor-pointer ${textColor(_itr?.status)}`}>
                        {getFormattedTextStatus(_itr?.status)}
                      </div>
                    </div>
                    <div className="result-duration flex items-center">00:00:00:00</div>
                  </div>
                </div>
                <div className="panel ">
                  {_itr.itr &&
                    steps(
                      _itr.itr,
                      iterationType,
                      Number(marginLeftSize) + Number(1.3),
                      Number(iterationMarginLeft) + Number(1.3),
                      `${stepGroupIndex}${ite_index + 1}`
                    )}
                </div>
              </div>
            );
          })}
      </div>
    );
  }

  function stepGroup(stepGroupResult, stepGroupIndex, marginLeftSize, iterationId) {
    const elementId = stepGroupResult?.stepId + stepGroupIndex + (iterationId || '') + getStepId();
    return (
      <div>
        <div
          className="accordion"
          onClick={(e) => {
            toggleAccordionIcon(e, elementId, stepGroupResult.executionDurationInHourMinSecFormat);
          }}
        >
          <div className="grid grid-cols-5 items-center accordion-row-min-h">
            <div
              className={`step-result cursor-pointer col-span-3`}
              style={{ marginLeft: marginLeftSize ? marginLeftSize + 'rem' : '1.30rem' }}
            >
              {stepGroupIndex}.{stepGroupResult?.name}
              {accordionIcon(elementId, stepGroupResult?.stepId)}
              {executionStatistics(stepGroupResult?.stepResultStats)}
            </div>
            <div className={`result-status flex items-center cursor-pointer ${textColor(stepGroupResult?.status)}`}>
              {filteredStatus === 'ALL' ? getFormattedTextStatus(stepGroupResult?.status) : ''}
            </div>
            <div className="result-duration flex items-center">
              {stepGroupResult?.executionDurationInHourMinSecFormat}
            </div>
          </div>
        </div>
        <div className="panel ">
          {stepGroupResult?.stepResults !== undefined &&
            steps(stepGroupResult?.stepResults, 'stepgroup', Number(marginLeftSize) + Number(1.3), '', iterationId)}
        </div>
      </div>
    );
  }

  /*will need this in future */
  function forLoopIteration(data) {
    /*if(data?.stepResults){
      let _ite_arr = groupBy(data?.stepResults, 'iteration');
      let iterations = Object.keys(_ite_arr);
      return '(int i=0, i<' + iterations.length + ', i++)';
    }*/
    return '';
  }

  const getUniqueId = (iteId) => {
    let _id;
    _id = Date.now().toString(36) + Math.random().toString(36);
    if (iteId) {
      _id = _id + iteId;
    }
    return _id;
  };

  const getformatedResultMessage = (message, row, marginLeftSize) => {
    if (
      row?.nlpName === 'IfCondition' ||
      row?.nlpName === 'EndIfCondition' ||
      row?.nlpName === 'EndElseIfCondition' ||
      row?.nlpName === 'EndElseCondition' ||
      row?.nlpName === 'ElseIfCondition' ||
      row?.nlpName === 'ElseCondition'
    ) {
      if (row?.nlpName === 'IfCondition')
        return (message = (
          <span
            className="accordion fontPoppinsSemiboldSm"
            onMouseOver={(e) => {
              onMouseOverIfHeader(e);
            }}
            onMouseLeave={(e) => {
              onMouseLeaveIfHeader(e);
            }}
          >
            {row?.message}
          </span>
        ));
      if (row?.nlpName === 'EndIfCondition') return (message = '');
      if (row?.nlpName === 'ElseIfCondition')
        return (message = (
          <span
            className="accordion fontPoppinsSemiboldSm"
            onMouseOver={(e) => {
              onMouseOverIfHeader(e);
            }}
            onMouseLeave={(e) => {
              onMouseLeaveIfHeader(e);
            }}
          >
            {row?.message}
          </span>
        ));
      if (row?.nlpName === 'EndElseIfCondition') return (message = '');
      if (row?.nlpName === 'ElseCondition')
        return (message = (
          <span
            className="accordion fontPoppinsSemiboldSm"
            onMouseOver={(e) => {
              onMouseOverIfHeader(e);
            }}
            onMouseLeave={(e) => {
              onMouseLeaveIfHeader(e);
            }}
          >{`Else ${row.status === 'PASS' ? '- Executed' : `is ${getFormattedTextStatus(row.status)}`}`}</span>
        ));
      if (row?.nlpName === 'EndElseCondition') return (message = '');
    } else {
      return message;
    }
  };

  const getFormattedStatusIfElse = (status, row) => {
    if (
      row?.nlpName === 'IfCondition' ||
      row?.nlpName === 'EndIfCondition' ||
      row?.nlpName === 'EndElseIfCondition' ||
      row?.nlpName === 'EndElseCondition' ||
      row?.nlpName === 'ElseIfCondition' ||
      row?.nlpName === 'ElseCondition'
    ) {
      return (status = '');
    } else {
      return status;
    }
  };

  const getFormattedDurationIfElse = (status, row) => {
    if (
      row?.nlpName === 'IfCondition' ||
      row?.nlpName === 'EndIfCondition' ||
      row?.nlpName === 'EndElseIfCondition' ||
      row?.nlpName === 'EndElseCondition' ||
      row?.nlpName === 'ElseIfCondition' ||
      row?.nlpName === 'ElseCondition'
    ) {
      return (status = '');
    } else {
      return status;
    }
  };

  /**
   *
   * @param {*} steps
   * @param {*} type | type is exclusive to distinguish between pre/post condtition
   * @param {*} marginLeftSize
   * @param {*} iterationMarginLeft
   * @param {*} iteId
   * @returns
   */
  function steps(steps, type, marginLeftSize, iterationMarginLeft, iteId) {
    return (
      <div>
        {steps &&
          steps.map((_step, index) => {
            const _id = getUniqueId(iteId);
            const _idStep = getUniqueId(_step.stepId);
            const ifStepTagged = _step.tagDetailsDTOs && _step.tagDetailsDTOs.length ? true : false;
            return (
              <div>
                {_step?.type === 'step' && !ifElseConditionsList.includes(_step?.name) ? (
                  stepRenderer(steps, _step, _idStep, type, index, marginLeftSize, ifStepTagged)
                ) : ['Iteration', 'EndIteration'].includes(_step?.type) ? (
                  dpIterationRenderer(_step, _id, index, marginLeftSize, iterationMarginLeft)
                ) : ['ForCondition', 'EndForCondition', '_startforloop', '_endforloop'].includes(_step?.type) ? (
                  forConditionRenderer(_step, _id, index, marginLeftSize, iterationMarginLeft)
                ) : ifElseConditionsList.includes(_step?.type) ? (
                  <div>{ifElseCondition(_step, index, marginLeftSize ? Number(marginLeftSize) : '')}</div>
                ) : (
                  <div>{stepGroup(_step, index + 1, marginLeftSize ? marginLeftSize : '', iteId)}</div>
                )}
              </div>
            );
          })}
      </div>
    );
  }
  /*
   * for loop ui renderer
   */
  const forConditionRenderer = (_step, _id, index, marginLeftSize, iterationMarginLeft) => {
    return _step.name === 'Start for loop' ? (
      <div>
        <div
          className="accordion"
          onClick={(e) => {
            toggleAccordionIcon(e, `start_forloop${_id}`);
          }}
        >
          <div className="grid grid-cols-5 step-header" key={`step${index}`}>
            <div className={`col-span-3 step-result items-center cursor-pointer mt-3`}>
              <div
                className="iteration-header"
                id={`start_forloop${_id}`}
                style={{ marginLeft: marginLeftSize !== undefined ? marginLeftSize + 'rem' : '' }}
              >
                Start for loop {forLoopIteration(_step)}
                {accordionIcon(`start_forloop${_id}`)}
                {executionStatistics(_step.stepResultStats)}
              </div>
            </div>
            <div className="flex-wrapper">
              <div className={`result-status flex items-center cursor-pointer ${textColor(_step?.status)}`}>
                {getFormattedTextStatus(_step?.status)}
              </div>
            </div>
            <div className="result-duration flex items-center"></div>
          </div>
        </div>
        <div className="panel ">
          {_step?.stepResults &&
            iterationData(
              _step,
              'FORLOOP',
              index,
              Number(marginLeftSize) + Number(1.3),
              Number(iterationMarginLeft) + Number(1.3)
            )}
        </div>
      </div>
    ) : _step.name === 'End for loop' ? (
      <div className="grid grid-cols-5">
        <div
          className="col-span-3 iteration-header"
          onMouseOver={(e) => {
            onIterationHeaderFocus(e);
          }}
          id={`end_forloop${_id}`}
          style={{ marginLeft: marginLeftSize !== undefined ? marginLeftSize + 'rem' : '1.30rem' }}
        >
          End for loop
        </div>
        <div className="flex-wrapper">
          <div className={`result-status flex items-center cursor-pointer ${textColor(_step?.status)}`}>
            {getFormattedTextStatus(_step?.status)}
          </div>
        </div>
        <div className="result-duration flex items-center"></div>
      </div>
    ) : (
      ''
    );
  };
  const dpIterationRenderer = (_step, _id, index, marginLeftSize, iterationMarginLeft) => {
    return _step.type === 'Iteration' ? (
      <div>
        <div className="grid grid-cols-5 step-header" key={`step${index}`}>
          <div className={`col-span-5 step-result items-center cursor-pointer mt-3`}>
            <div
              className="accordion iteration-header"
              id={'start_Iteration' + _id}
              onClick={(e) => {
                toggleAccordionIcon(e, 'start_Iteration' + _id);
              }}
              style={{ marginLeft: marginLeftSize !== undefined ? marginLeftSize + 'rem' : '' }}
            >
              {_step.name}
              {accordionIcon('start_Iteration' + _id)}
              {executionStatistics(_step.stepResultStats)}
            </div>
            <div className="panel ">
              {_step?.stepResults &&
                iterationData(
                  _step,
                  'DATAPROVIDER',
                  index,
                  Number(marginLeftSize) + Number(1.3),
                  Number(iterationMarginLeft) + Number(1.3)
                )}
            </div>
          </div>
          <div id={`msg_Iteration${index}`} className="ite-msg text-primary" style={{ display: 'none' }}>
            End focused
          </div>
        </div>
      </div>
    ) : _step?.type === 'EndIteration' ? (
      <div
        className="iteration-header"
        onMouseOver={(e) => {
          onIterationHeaderFocus(e);
        }}
        id={`end_Iteration${_id}`}
        style={{ marginLeft: marginLeftSize !== undefined ? marginLeftSize + 'rem' : '' }}
      >
        {_step.name.replace('Start', 'End')}
      </div>
    ) : (
      ''
    );
  };

  const stepRenderer = (allSteps, _step, _idStep, type, index, marginLeftSize, ifStepTagged) => {
    return (
      (_step?.status === filteredStatus || filteredStatus === 'ALL') && (
        <div className="grid grid-cols-5 step-header" key={`step${index}`}>
          <TooltipPoppin title={_step?.message ? _step?.message : _step?.name} placement="top-start">
            <div
              className={`col-span-3 step-result cursor-pointer truncate pr-3 mt-2`}
              style={{ marginLeft: marginLeftSize ? marginLeftSize + 'rem' : '2.30rem' }}
              id={`${type}_step_${_idStep}`}
              onClick={() => viewStepResultModal(_step)}
            >
              {index + 1}.{' '}
              {getformatedResultMessage(_step?.message ? _step?.message : _step?.name, _step, marginLeftSize)}
            </div>
          </TooltipPoppin>
          <div className="flex items-center">
            {_step?.status === 'N/A' ? (
              <TooltipPoppin
                title="This step is not executed since one of the condition statements is executed"
                placement="top"
              >
                <div
                  className={`result-status flex items-center cursor-pointer ${textColor(_step?.status)}`}
                  onClick={() => viewStepResultModal(_step)}
                >
                  {getFormattedTextStatus(getFormattedStatusIfElse(_step.status, _step))}
                </div>
              </TooltipPoppin>
            ) : (
              <div
                className={`result-status flex items-center cursor-pointer ${textColor(_step?.status)}`}
                onClick={() => viewStepResultModal(_step)}
              >
                {getFormattedTextStatus(getFormattedStatusIfElse(_step.status, _step))}
              </div>
            )}
          </div>
          <div className="result-duration flex items-center">
            {getFormattedDurationIfElse(_step?.executionDurationInHourMinSecFormat, _step)}
          </div>
        </div>
      )
    );
  };

  function ifElseCondition(step, index, marginLeftSize) {
    return (
      <div>
        {['If Condition', 'End If Condition'].includes(step?.name) ? (
          step.name === 'If Condition' ? (
            <div>
              {ifElseJsx(
                step,
                index,
                step.name,
                'start_If',
                marginLeftSize ? Number(marginLeftSize) + Number(1.3) : ''
              )}
            </div>
          ) : step.name === 'End If Condition' ? (
            <div
              className={elementHover ? 'accordion section-hover' : 'accordion section'}
              onMouseOver={(e) => {
                onMouseOverIfHeader(e);
              }}
              onMouseLeave={(e) => {
                onMouseLeaveIfHeader(e);
              }}
              id={`end_If${index}`}
              style={{ marginLeft: marginLeftSize !== undefined ? Number(marginLeftSize) - Number(1.3) + 'rem' : '' }}
            >
              End If
            </div>
          ) : (
            ''
          )
        ) : ['Else If Condition', 'End Else If Condition'].includes(step?.name) ? (
          step.name === 'Else If Condition' ? (
            <div>
              {ifElseJsx(
                step,
                index,
                step.name,
                'start_elseIf',
                marginLeftSize ? Number(marginLeftSize) + Number(1.3) : ''
              )}
            </div>
          ) : step.name === 'End Else If Condition' ? (
            <div
              className={elementHover ? 'accordion section-hover' : 'accordion section'}
              onMouseOver={(e) => {
                onMouseOverIfHeader(e);
              }}
              onMouseLeave={(e) => {
                onMouseLeaveIfHeader(e);
              }}
              id={`end_elseIf${index}`}
              style={{ marginLeft: marginLeftSize !== undefined ? Number(marginLeftSize) - Number(1.3) + 'rem' : '' }}
            >
              End Else If
            </div>
          ) : (
            ''
          )
        ) : ['Else Condition', 'End Else Condition'].includes(step?.name) ? (
          step.name === 'Else Condition' ? (
            <div>
              {ifElseJsx(
                step,
                index,
                step.name,
                'start_else',
                marginLeftSize ? Number(marginLeftSize) + Number(1.3) : ''
              )}
            </div>
          ) : step.name === 'End Else Condition' ? (
            <div
              className={elementHover ? 'accordion section-hover' : 'accordion section'}
              onMouseOver={(e) => {
                onMouseOverIfHeader(e);
              }}
              onMouseLeave={(e) => {
                onMouseLeaveIfHeader(e);
              }}
              id={`end_else${index}`}
              style={{ marginLeft: marginLeftSize !== undefined ? Number(marginLeftSize) - Number(1.3) + 'rem' : '' }}
            >
              End Else
            </div>
          ) : (
            ''
          )
        ) : (
          ''
        )}
      </div>
    );
  }

  function ifElseJsx(step, index, conditionHeader, idPrefix, marginLeftSize) {
    return (
      <div className="grid grid-cols-5 step-header" key={`step${index}`}>
        <div className={`col-span-5 step-result flex items-center cursor-pointer`}>
          <div
            className={elementHover ? 'accordion section-hover' : 'accordion section'}
            id={`${idPrefix}start_If${index}`}
            onMouseOver={(e) => {
              onMouseOverIfHeader(e);
            }}
            onMouseLeave={(e) => {
              onMouseLeaveIfHeader(e);
            }}
            style={{ marginLeft: marginLeftSize !== undefined ? marginLeftSize + 'rem' : '' }}
          >
            {conditionHeader.replace('Condition', '')}
            {conditionStatus(step.stepInputs, idPrefix)}
            {executionStatistics(step.stepResultStats)}
          </div>
        </div>
      </div>
    );
  }

  function onMouseOverIfHeader() {
    setElementHover(true);
  }
  function onMouseLeaveIfHeader() {
    setElementHover(false);
  }

  function conditionStatus(scriptInputs, cond_type) {
    let condition = '';
    let status;
    let conditionValue = scriptInputs[0].value;
    status = scriptInputs[1].value;
    if (cond_type !== 'start_else' && cond_type !== 'end_else') {
      condition = conditionValue.replace('if', '');
    }
    return condition + ' - condition is ' + status;
  }

  function executionStatistics(statistics) {
    return (
      <section className="inline-block ">
        {statistics?.totalFailed > 0 && (
          <span className="result-failed">
            {statistics?.totalFailed} / {statistics?.total} Failed
          </span>
        )}
        {statistics?.totalWarning > 0 && (
          <span className="result-warning ml-2">
            {statistics?.totalWarning} / {statistics?.total} Warning
          </span>
        )}
        {statistics?.totalSkipped > 0 && (
          <span className="result-skipped ml-2">
            {statistics?.totalSkipped} / {statistics?.total} Skipped
          </span>
        )}
        {statistics?.totalPassed > 0 && (
          <span className="result-passed ml-2">
            {statistics?.totalPassed} / {statistics?.total} Passed
          </span>
        )}
      </section>
    );
  }

  function onIterationHeaderFocus(event) {
    let _id = event.target.id;
    let _searchID = '';
    let msgID = '';
    if (_id) {
      let sourceHtmlElement = document.getElementById(_id);
      if (sourceHtmlElement) {
        let splittedIdArr = _id.split('_');
        if (splittedIdArr[0].toLowerCase() === 'start') {
          _searchID = 'end_'.concat(splittedIdArr[1]);
        } else {
          _searchID = 'start_'.concat(splittedIdArr[1]);
          msgID = 'msg_'.concat(splittedIdArr[1]);
        }
        if (_searchID || msgID) {
          let targetHtmlElement = document.getElementById(_searchID);
          let targetMSGHtmlElement = document.getElementById(msgID);
          if (targetHtmlElement) {
            let style = targetHtmlElement.style;
          }
          if (targetMSGHtmlElement) {
            let _style = targetMSGHtmlElement.style;
            _style.display = 'block';
          }
        }
      }
    }
  }

  return (
    <div>
      {scriptData?.status === 'SKIP' ? (
        <div className="testscript-table-message fontPoppinsMediumSm border-t">
          {noScriptDataFoundMsg('Steps result for this')}
        </div>
      ) : (
        <>
          <div className="grid grid-cols-5 result-header uppercase p-2 border-b sm:p-2 md:p-3 lg:p-3 xl:p-4">
            <div className="col-span-3">Result</div>
            <div className="flex relative">
              Status
              {displayFilterOption || filterIcon ? (
                <TooltipPoppin title="Filter" placement="top">
                  <div>
                  <Icon
                    name="rs_filter_filled"
                    height={12}
                    width={12}
                    className="ml-2 mt-0.5 cursor-pointer "                      onClick={() => setDisplayFilterOption(!displayFilterOption)}
                      onMouseLeave={() => {
                        if (filterIcon && !['PASS', 'FAIL', 'WARNING', 'SKIP'].includes(filteredStatus)) {
                          setFilterIcon(false);
                        }
                      }}
                    />
                  </div>
                </TooltipPoppin>
              ) : (
                <TooltipPoppin title="Filter" placement="top">
                  <div>
                  <Icon
                      name="filter"
                      height={15}
                      width={15}
                      className="ml-2 mt-0.5 cursor-pointer "
                      onClick={() => setDisplayFilterOption(!displayFilterOption)}
                      onMouseOver={() => {
                        if (!filterIcon && !['PASS', 'FAIL', 'WARNING', 'SKIP'].includes(filteredStatus)) {
                          setFilterIcon(true);
                        }
                      }}
                      onMouseLeave={() => {
                        if (filterIcon && !['PASS', 'FAIL', 'WARNING', 'SKIP'].includes(filteredStatus)) {
                          setFilterIcon(false);
                        }
                      }}
                    />
                  </div>
                </TooltipPoppin>
              )}
              {displayFilterOption && statusFilter()}
            </div>
            <div>Execution Duration</div>
          </div>
          {scriptResult()}
        </>
      )}
    </div>
  );
};

export default ScriptResultAccordion;

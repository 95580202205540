import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import { useDropzone } from 'react-dropzone';
import { usePagination, useTable } from 'react-table';
import { getFolderTreeReq, programElementJarFiles } from '../../../../../api/api_services';
import { useAlert } from '../../../../common/alert_service/useAlert';

function UploadJarModal(props) {
  const { AlertContatiner, MyAlert } = useAlert();

  const [showModal, setShowModal] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [reload, setReload] = useState(false);
  let { getRootProps, getInputProps } = useDropzone({
    accept: '.jar',
    onDrop: (acceptedFiles) => {
      const file = new FormData();
      acceptedFiles.forEach((data, index) => {
        file.append('file', data);
      });
      programElementJarFiles(folderId, file).then((response) => {
        if (response?.data?.responseCode === 200 && response?.data?.message) {
          let length = acceptedFiles.length;
          acceptedFiles.forEach((file, index) => {
            selectedFiles.forEach((selected, index) => {
              if (selected.title === file.name) {
                length = length - 1;
              }
            });
          });
          MyAlert.info(`${length} files uploaded successfully`);
          setReload(false);
        } else if (response?.data?.responseCode === 200 && response?.data?.message === null) {
          props.MyAlert.success(`Selected Files are uploaded`);
          props.closeModal(false);
          setShowModal(false);
        } else if (response?.data?.responseCode === 400) {
          MyAlert.info(` ${response.data.message.slice(19, -15)} jar file already exist as a default jar`);
        } else if (response?.data?.responseCode === 404) {
          MyAlert.warning(`Upload only Jar files `);
        }
      });
    },
  });
  const [folderId, setFolderId] = useState();
  useEffect(() => {
    setReload(true);
    getFolderTreeReq().then((response) => {
      let responseObject = response.data.responseObject;
      let children = responseObject.folderTree[0].children;
      children.forEach((data, index) => {
        if (data.name === 'Custom-JAR' && data.defaultFolder === true) {
          setFolderId(data.key);
          if (data.children) {
            setSelectedFiles(data.children);
          } else {
            setSelectedFiles([]);
          }
        }
      });
    });
  }, [reload]);

  const data = React.useMemo(() => selectedFiles, [selectedFiles]);
  const columns = React.useMemo(
    () => [
      {
        Header: 'Jar Name',
        accessor: 'name',
        disableFilters: true,
        Cell: (e) => (
          <div className="table-font-style-common truncate  w-40" title={e.value}>
            {' '}
            {e.value}{' '}
          </div>
        ),
      },
      {
        Header: 'Uploaded By',
        accessor: 'createdByUname',
        disableFilters: true,
        Cell: (e) => <div className="table-font-style-common"> {e.value} </div>,
      },
      {
        Header: 'Uploaded On',
        accessor: 'createdOn',
        disableFilters: true,
        Cell: (e) => <div className="table-font-style-common"> {e.value} </div>,
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 4 },
    },
    usePagination
  );
  return (
    <div>
      <Modal
        open={showModal}
        onClose={() => {
          props.closeModal(false);
          setShowModal(false);
        }}
        className="modal-dialog"
      >
        <div className="modal-container import-jar-modal">
          <div className="modal-header">
            <h2 className="title">Import Jar</h2>
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                props.closeModal(false);
                setShowModal(false);
              }}
            >
              <img
                style={{
                  cursor: 'pointer',
                }}
                src="/assets/images/close_black.svg"
                alt=""
                height="25px"
                width="25px"
              />
            </IconButton>
          </div>
          <div className="modal-body" id="journal-scroll">
            <AlertContatiner />
            <p className="UploadJarBoxHeader">Upload jar(s)</p>
            <div className="">
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <div className="UploadJarBox">
                  <p className="draganddrop">
                    Drag and Drop here <br />
                    <span className="mt-2">or</span>
                    <br />
                    <span className="browseText">Browse files</span>
                  </p>
                </div>
              </div>
            </div>{' '}
            <hr className="divider mt-5" />
            <div className="shadow-2xl mt-2">
              <div className="mt-2">
                <p className="locatorHeader pt-4 mb-4">Jar List</p>
                <div className="table-height overflow-auto" id="journal-scroll">
                  <div>
                    <div className="jar-table-height">
                      <table {...getTableProps()} className="">
                        <thead>
                          {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()} className="text-sm h-11 ml-4 jar-header">
                              {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()} width={column.width} className="pl-7 text-left">
                                  {column.render('Header')}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody {...getTableBodyProps()} className="text text-xs">
                          {page.map((row) => {
                            prepareRow(row);
                            return (
                              <tr {...row.getRowProps()} className=" h-10 ">
                                {row.cells.map((cell) => {
                                  return (
                                    <td {...cell.getCellProps()} className="p-0 pl-7 text-left text-sm ">
                                      {cell.render('Cell')}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {data && data.length === 0 ? (
                        <div className="fontPoppinsRegularMd text-sm text-center mt-8">
                          <div className="">
                            <p className="font-bold">No Jar Files Found</p>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    {data && data.length > 0 ? (
                      <div className="pagecountborder ">
                        <div className="float-right text-sm mr-3 pt-0.5 pb-0.5">
                          <span className="pagecount">
                            {page[0].index + 1} - {page[page.length - 1].index + 1} of {data.length}{' '}
                          </span>
                          <span className="pageborderleft ml-2 pl-2">
                            <button onClick={() => previousPage()} disabled={!canPreviousPage} className="pageArrow">
                              {'<'}
                            </button>{' '}
                            <span className="currentpagecount text-sm ml-2 mr-2">
                              {' '}
                              <span className="pl-2 pr-2">{pageIndex + 1}</span>
                            </span>
                            <button onClick={() => nextPage()} disabled={!canNextPage} className="pageArrow">
                              {'>'}
                            </button>{' '}
                          </span>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="gray-btn mr-3"
              onClick={() => {
                props.closeModal(false);
                setShowModal(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default UploadJarModal;

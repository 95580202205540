import { MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import * as yup from 'yup';
import {
  addStepGroupVariable,
  updateStepGroupVariable,
  createGlobalVariable,
  createLocalVariableReq,
  createProjectEnvironmentVariable,
  updateGloabalVariable,
  updateLocalVariableReq,
  updateProjectEnvironmentVairable,
  getAllTestDataFiles,
} from '@api/api_services';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/styles';
import { useAlert } from '../../common/alert_service/useAlert';
import { colors } from '@src/css_config/colorConstants';
import MyInput from '@pagescommon/Inputs/MyInput';
import MySelect from '@pagescommon/Inputs/MySelect';
import { Checkbox } from '@mui/material';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Tooltip } from '@material-ui/core';
import { pathName, renderFilePath, searchFilePathBasedOnHash } from '@src/util/common_utils';
import { HASH_LABELS } from '@common/ui-constants';
import { ClickAwayListener } from '@mui/base';

Modal.setAppElement('#root');
const useStyles = makeStyles((theme) => ({
  select: {
    paddingBottom: 3,
    paddingTop: 10,
    borderRadius: 8,
    fontSize: '12px',
    '&:focus': {
      borderRadius: 12,
      background: 'none',
    },
  },
  menuPaper: {
    maxHeight: 200,
    fontSize: '12px',
  },
  menulist: {
    paddingTop: 10,
    paddingBottom: 0,
    fontSize: '12px',
    background: 'none',
    '& li': {
      fontSize: '12px',
      color: colors.grey_dark,
    },
    '& li:hover': {
      background: colors.sky_blue_dark,
      color: colors.blue_dark,
    },
    '& li.Mui-selected': {
      paddingTop: '5 !important',
      color: colors.blue_dark,
      background: colors.sky_blue_dark,
    },
    '& li.Mui-selected:hover': {
      background: colors.sky_blue_dark,
      color: colors.blue_dark,
    },
  },
}));

const usePlaceholderStyles = makeStyles((theme) => ({
  placeholder: {
    color: colors.light_gray_100,
    fontSize: 12,
  },
}));
function CreateVariable(props) {
  const { MyAlert } = useAlert();
  const variableTypeOptions = [
    { name: 'Global Variable', value: 'GLOBAL' },
    { name: 'Project Environment Variable', value: 'PROJECT_ENVIRONMENT' },
  ];
  const classes = useStyles();
  const project = JSON.parse(localStorage.getItem('selected-project'));
  let editData = {};
  if (props?.data) {
    editData = props.data;
  }
  const [openModal, setOpenModal] = useState(true);
  const [createCalled, setCreateCalled] = useState(false);
  const [isValueMasked, setIsValueMasked] = useState(props?.data?.masked ? true : false);
  const typeOfModal = HASH_LABELS.DRAWER_TYPE_DROPDOWN;
  const customStylesPage = {
    content: {
      position: 'relative',
      minWidth: '450px',
      minHeight: '100%',
      opacity: '1',
      float: 'right',
      border: 'none',
      padding: 0,
      borderRadius: '10px 0px 0px 10px',
      overflow: 'hidden',
      background: `${colors.text_white} 0% 0% no-repeat padding-box`,
      boxShadow: 'none',
    },
  };
  const validationSchema = yup.object({
    variableName: yup
      .string()
      .trim('Space is not allowed at starting and at the end')
      .strict(true)
      .required('Name is required!!')
      .matches(/^(\s*\w+)(\s*\w*)*$/, 'Name should be alphanumeric')
      .matches(/^[-a-zA-Z0-9-()_]+(\s+[-a-zA-Z0-9-()_]+)*$/, 'Space is not allowed in the beginning and at the end')
      .min(3, 'Name should contain atleast 3 characters')
      .max(25, 'Name can contain atmost 25 characters'),
    variableType: yup.string().required('Type is required'),
    variableValue: yup.string(),
    variableValueMasked: yup.boolean(),
  });

  let initialValues;
  if (props?.data) {
    initialValues = {
      variableName: editData?.name,
      variableType: editData?.type,
      variableValue: editData?.value,
      variableValueMasked: editData?.masked,
    };
  } else {
    initialValues = {
      variableName: '',
      variableType: props?.variableType ? props.variableType : 'GLOBAL',
      variableValue: '',
      variableValueMasked: false,
    };
  }
  const [showFileSuggestion, setShowFileSuggestion] = useState(false);
  const [fileArray, setFileArray] = useState([]);
  const [allFileArray, setAllFileArray] = useState([]);

  const showVariables = () => {
    setShowFileSuggestion(true);
  };
  const hideVariables = () => {
    setShowFileSuggestion(false);
  };

  useEffect(() => {
    getFolderPath();
  }, []);

  const getFolderPath = async () => {
    try {
      const response = await getAllTestDataFiles();
      const {
        data: { responseObject, responseCode },
      } = response || {};

      if (responseObject?.length > 0 && responseCode === 200) {
        const folderPaths = responseObject.map((folder) => ({
          ...folder,
          path: pathName(folder.actualPath),
          actualPath: folder.actualPath,
        }));
        setFileArray([...folderPaths]);
        setAllFileArray([...folderPaths]);
      } else {
        setFileArray([]);
      }
    } catch (err) {
      console.error('GET_FOLDER_PATH :', err);
    }
  };

  const handleOptionSelect = (option) => {
    formikDetails.setFieldValue('variableValue', option.actualPath);
    hideVariables();
  };

  const handleInputChange = (e) => {
    const { inputValue, fileArrayOption, filteredArray } = searchFilePathBasedOnHash(e, fileArray, allFileArray);
    if (fileArrayOption) {
      formikDetails.setFieldValue('variableValue', inputValue);
      hideVariables();
    } else if (!fileArrayOption) {
      if (inputValue.startsWith('#') && inputValue.length > 1) {
        showVariables();
        setFileArray(filteredArray);
      } else if (inputValue.startsWith('#')) {
        showVariables();
        getFolderPath();
      } else {
        hideVariables();
      }
      formikDetails.handleChange(e);
    }
  };

  function validateVariableValue(str) {
    if (str.trim().length === 0) {
      return true;
    }
    return false;
  }
  const onSubmit = (values) => {
    let data;
    if (values) {
      if (values.variableValue.length && validateVariableValue(values.variableValue)) {
        formikDetails.setFieldError('variableValue', 'White spaces are not allowed');
      } else {
        if (!createCalled) {
          setCreateCalled(true);
          if (props?.data) {
            data = {
              name: values.variableName,
              type: values.variableType,
              value: values.variableValue,
              masked: values.variableValueMasked,
            };

            if (
              data?.name === editData?.name &&
              data?.value === editData?.value &&
              data?.type === editData?.type &&
              data?.masked === editData?.masked
            ) {
              setCreateCalled(false);
              props.MyAlert.info('No changes were done');
              return;
            } else {
              updateVariable(editData.id, data);
            }
          } else {
            data = {
              name: values.variableName,
              type: values.variableType,
              value: values.variableValue,
              masked: values.variableValueMasked,
            };
            createVariable(data);
          }
        }
      }
    }
  };
  const formikDetails = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  async function createVariable(data) {
    let response;
    try {
      if (data.type === 'Step') {
        const search = window.location.search;
        const id = new URLSearchParams(search).get('id');
        const parentId = new URLSearchParams(search).get('parentId');
        let requestbody = {
          name: data.name,
          value: data.value,
          type: 'LOCAL',
          returningStep: '',
          projectId: project.id,
          parentVariableType: 'STEPGROUP',
          parentVariableId: parentId,
          subParentVariableId: id,
          masked: data.variableValueMasked,
        };
        response = await addStepGroupVariable(requestbody);
      } else if (data.type === 'LOCAL') {
        let requestBody = {
          ...data,
          parentVariableType: 'MODULE',
          parentVariableId: props.id.moduleId,
          subParentVariableId: props.id.scriptId,
          // "projectId": project.id
        };
        response = await createLocalVariableReq(requestBody);
      } else if (data.type === 'GLOBAL') {
        response = await createGlobalVariable(data);
      } else {
        response = await createProjectEnvironmentVariable(data);
      }
      if (response && response.data && response.data.responseObject) {
        props.MyAlert.success(`${data.name} variable created successfully`);
        /************for steps*************/
        if (props.variableClick) {
          props.variableClick(response.data.responseObject, data.type);
        }
        /************for steps*************/
        if (props.reload) {
          props.reload(true);
        }
        props.closeModal(false);
      } else if (response.data.status === 'FAILURE') {
        formikDetails.setFieldError('variableName', 'Name already exists');
        setCreateCalled(false);
      } else {
        MyAlert.warning(`${response.data.message}`);
        props.closeModal(false);
      }
    } catch (err) {
      console.log(err);
    }
  }
  async function updateVariable(id, data) {
    let response;
    try {
      if (data.type === 'LOCAL') {
        let requestBody = {
          ...data,
          parentVariableType: editData.parentVariableType,
          parentVariableId: editData.parentVariableId,
          subParentVariableId: editData.subParentVariableId,
          // "projectId": editData.projectId
        };
        response = await updateLocalVariableReq(id, requestBody);
      } else if (data.type === 'GLOBAL') {
        response = await updateGloabalVariable(id, data);
      } else if (data.type === 'Step') {
        props.data.name = data.name;
        props.data.value = data.value;
        response = await updateStepGroupVariable(id, props.data);
      } else {
        response = await updateProjectEnvironmentVairable(id, data);
      }
      if (response && response.data && response.data.responseObject) {
        props.MyAlert.success(`${data.name} variable updated successfully`);
        /************for steps*************/
        if (props.variableClick) {
          props.data.name = data.name;
          props.variableClick(response.data.responseObject, data.type);
        }
        /************for steps*************/
        if (props.reload) {
          props.reload(true);
        }
        props.closeModal(false);
      } else if (response.data.status === 'FAILURE') {
        formikDetails.setFieldError('variableName', 'Name already exists');
        setCreateCalled(false);
      } else {
        MyAlert.warning(`${response.data.status}`);
        props.closeModal(false);
      }
    } catch (err) {
      console.log(err);
    }
  }
  const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
  };

  const handleEscapeClose = () => {
    setOpenModal(false);
    props.closeModal(false);
  };
  return (
    <Modal isOpen={openModal} style={customStylesPage} onRequestClose={handleEscapeClose}>
      <div className="modal-header-api">
        <div className="pl-2">
          <h2 className="title white fontPoppinsMediumSm">
            {' '}
            {props?.data ? `Edit Variable - ${editData?.name}` : 'Create Variable'}
          </h2>
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="create-edit-close"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
      <form onSubmit={formikDetails.handleSubmit}>
        <div className="pt-5">
          <div className="fontPoppinsMediumSm select-none mb-1 pl-5 pr-5">
            <span className="errorMessage mr-0.5">*</span>
            Variable Name
          </div>
          <div className="w-full mb-4 pl-5 pr-5">
            <MyInput
              error={formikDetails.errors.variableName && formikDetails.touched.variableName}
              type="text"
              name="variableName"
              id="variableName"
              autocomplete="off"
              placeholder="Enter variable name"
              onBlur={formikDetails.handleBlur}
              onChange={formikDetails.handleChange}
              value={formikDetails.values.variableName}
              className="common-modal-input"
              autoFocus
              variableInput={false}
              inputValue={'input'}
            />
            {formikDetails.errors.variableName && formikDetails.touched.variableName ? (
              <div className="errorMessage absolute">{formikDetails.errors.variableName}</div>
            ) : null}
          </div>
          <div className="fontPoppinsMediumSm select-none mb-2 pl-4 pr-4">
            <span className="text-red-400 mr-0.5">*</span>
            Variable Type
          </div>
          <div className="w-full mb-4 pl-5 pr-5">
            <MySelect
              variableInput={'restApi'}
              sxPadding={'1px 10px 1px 10px'}
              boxShadow={`0px 0px 1px grey`}
              verticalAnchor={'bottom'}
              verticalTransform={'top'}
              marginTop={'3px'}
              borderRadius={'4px'}
              marginRight={'3px'}
              classSelect={'fontPoppinsRegularMd'}
              name="variableType"
              value={formikDetails.values.variableType}
              onChange={formikDetails.handleChange}
              minWidth={`417px`}
              disabled={
                (props?.variableType && !props.variableClick) || props?.data || props?.returnVariable ? true : false
              }
              onBlur={formikDetails.handleBlur}
              onSelect={formikDetails.handleChange}
              renderValue={
                formikDetails.values.variableType !== ''
                  ? undefined
                  : () => <Placeholder className="fontPoppinsRegularSm">Select script type</Placeholder>
              }
            >
              {variableTypeOptions.map((data, key) => {
                if ((props?.inputFieldName && data.name !== 'Project Environment Variable') || !props?.inputFieldName) {
                  return (
                    <MenuItem key={key} value={data.value}>
                      <span className="fontPoppinsRegularMd">{data.name}</span>
                    </MenuItem>
                  );
                }
              })}
            </MySelect>
            {formikDetails.errors.variableType && formikDetails.touched.variableType ? (
              <div className="errorMessage md:mt-1">{formikDetails.errors.variableType}</div>
            ) : null}
          </div>
          <div className="fontPoppinsMediumSm select-none mb-1 pl-6 pr-5">Variable Value</div>
          <div className="w-full pl-5 pr-5">
            <MyInput
              error={formikDetails.errors.variableValue && formikDetails.touched.variableValue}
              className="common-modal-input"
              autoComplete="off"
              type={isValueMasked ? 'password' : 'text'}
              name="variableValue"
              id="variableValue"
              placeholder={HASH_LABELS?.ENTER_VALUE_OR_SELECT_PATH}
              onBlur={formikDetails.handleBlur}
              onChange={(e) => handleInputChange(e)}
              value={formikDetails.values.variableValue}
              inputValue={'input'}
              variableInput={false}
            />
            {showFileSuggestion && (
              <ClickAwayListener onClickAway={hideVariables}>
                <div className="absolute">{renderFilePath(fileArray, handleOptionSelect, typeOfModal)}</div>
              </ClickAwayListener>
            )}
            {formikDetails.errors.variableValue && formikDetails.touched.variableValue ? (
              <div className="errorMessage md:mt-1">{formikDetails.errors.variableValue}</div>
            ) : null}
            <div className="flex -ml-2 variable-input-value">
              <Checkbox
                name="variableValueMasked"
                id="variableValueMasked"
                size="small"
                className="mr-0.5 masked-checkbox"
                onChange={(e) => {
                  setIsValueMasked(e?.target?.checked);
                  formikDetails.handleChange(e);
                }}
                disabled={props?.variableName?.masked || props?.data?.masked}
                defaultChecked={isValueMasked}
                value={isValueMasked}
              />
              <InputLabel shrink htmlFor="variableValueMasked" className="fontPoppinsRegularMd mt-3">
                <span> Hide value</span>
              </InputLabel>
              <Tooltip title="Once the value is hidden, it cannot be undone" placement="right">
                <InfoOutlinedIcon fontSize="small" className="text-blue-600 mt-2 text-xs" />
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="modal-footer-section modal-footer-container">
          <div className="float-right">
            <button onClick={() => props.closeModal(false)} type="button" className=" modal-close-btn">
              Cancel
            </button>
            <button type="submit" className=" modal-save-btn">
              {props?.data ? 'Update' : 'Create'}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default CreateVariable;

import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { CommonLoader } from '@src/pages/common/common-loader';
import React, { useState, useEffect, useContext } from 'react';
import { getProgramElementReq } from '@api/api_services';
import { trimPath } from '@src/common/ui-constants';
// import OnShowEditor from "@pages/repository/components/program_elements/code_Editor/Js/Ace_Editor";

function ProgramElementDetailsPage() {
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [details, setDetails] = useState(true);
  const [screenType] = useState('viewScreen');
  const [codeviewValue, setcodeValue] = useState('');
  const [path, setPath] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const search = window.location.search;
  const [id, setId] = useState(new URLSearchParams(search).get('id'));
  if (id !== new URLSearchParams(search).get('id')) {
    setId(new URLSearchParams(search).get('id'));
  }
  const parentId = new URLSearchParams(search).get('parentId');
  const project = JSON.parse(localStorage.getItem('selected-project'));
  const getDetailsOfProgramElement = async () => {
    try {
      const elementId = id;
      const packageId = parentId;
      setIsLoading(true);
      resetProgress();
      startOrResumeProgress({
        stopAt: 90,
      });
      const res = await getProgramElementReq(packageId, elementId);
      stopProgress();
      if (res.data.responseCode === 200 && res.data.responseObject) {
        setDetails(res.data.responseObject);
        setcodeValue(res.data.responseObject.code);
        setPath(trimPath(res.data.responseObject.path));
      }
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 10,
      });
      setIsLoading(false);
    } catch (err) {
      resetProgress();
      console.error('GET_PROGRAM_ELEMENT :', err);
    }
  };

  useEffect(() => {
    getDetailsOfProgramElement();
  }, [id]);

  if (isLoading) {
    return <CommonLoader />;
  }

  return (
    <div className="page-table-body">
      <div className="content-wrapper">
        <div className="content_area_style">
          <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
            <div className="project_label text-sm flex-auto title-trim" title={details.desc}>
              {details.desc}
            </div>
          </div>
          <hr />
          <div className="table-height overflow-auto grid-flex-wrp" id="journal-scroll">
            <div className="grid-details">
              <div className=" grid grid-cols-3 gap-4 mt-4">
                <div className="">
                  <div className="grid-rows-4 ml-10">
                    <div className="">
                      <div className="">
                        <div className=" mt-4">
                          <h1 className="details-key-style-common">NLP Description</h1>
                          <p className="pt-2 text-gray-700">
                            <div
                              className="details-data-style-common name-trimmer"
                              title={details.desc ? details.desc : '--'}
                            >
                              {details.desc ? details.desc : '--'}
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className=""></div>
                    <div className="">
                      <div className=" mt-10">
                        <h1 className="details-key-style-common">Path</h1>
                        <p className="pt-2 text-gray-700">
                          <div className="details-data-style-common path-trimmer" title={path}>
                            {path ? path : '--'}
                          </div>
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <div className=" mt-10 ">
                        <h1 className="details-key-style-common">Modified By</h1>
                        <p className="pt-2 text-gray-700">
                          <span className="details-data-style-common">
                            {details.modifiedByUname ? details.modifiedByUname : '--'}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="grid-rows-3 ml-14">
                    <div className="">
                      <div className="mt-4">
                        <h1 className="details-key-style-common">Class Name</h1>
                        <p className="pt-2 text-gray-700">
                          <div
                            className="details-data-style-common name-trimmer"
                            title={details.name ? details.name : '--'}
                          >
                            {details.name ? details.name : '--'}
                          </div>
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <div className="">
                        {' '}
                        <div className="mt-10">
                          <h1 className="details-key-style-common">Created By</h1>
                          <p className="pt-2 text-gray-700">
                            <span className="details-data-style-common">
                              {details.createdByUname ? details.createdByUname : '--'}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="mt-10">
                        <h1 className="details-key-style-common">Modified On</h1>
                        <p className="pt-2 text-gray-700">
                          <span className="details-data-style-common">
                            {details.modifiedOn ? details.modifiedOn : '--'}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="grid-rows-3 ml-20">
                    <div className="">
                      <div className=" mt-4">
                        <h1 className="details-key-style-common">Project Name</h1>
                        <p className="pt-2 text-gray-700">
                          <div className="details-data-style-common project-name-trimmer" title={project.name}>
                            {project.name}
                          </div>
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <div className=" mt-10 ">
                        <h1 className="details-key-style-common">Created On</h1>
                        <p className="pt-2 text-gray-700">
                          <span className="details-data-style-common">
                            {details.createdOn ? details.createdOn : '--'}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <hr className="divider-program-editor mt-5 mb-5 ml-10 mr-10" />
            <div className="editorRole">
              <OnShowEditor
                codeviewValue={codeviewValue}
                screenType={screenType}
              />
            </div>
            <hr className="divider-program-editor mt-5 mb-5 ml-10 mr-10" /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgramElementDetailsPage;

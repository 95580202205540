import React, { memo } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { styled, useTheme } from '@mui/material/styles';

import './common-drawer.scss';
import CommonButton from '@src/common/button/Button';
import { colors } from '@src/css_config/colorConstants';
import { CloseIcon } from '@src/common/SvgIcon';
import { Tooltip } from '@material-ui/core';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import { UI_VALIDATIONS } from '@src/util/validations';
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  // padding: theme.spacing(0, 1.5),
  justifyContent: 'space-between',
  position: 'sticky',
  width: '100%',
  zIndex: 999,
  color: colors.text_white,
  backgroundColor: colors.rs_primary,
  minHeight: '32px',
  top: 0,
}));

function CommonDrawer({
  headerContent = '',
  titleText = '',
  isDrawerOpen = false,
  rightButtonText = 'Save',
  leftButtonText = 'Cancel',
  drawerWidth = '',
  isRightButtonVisible = true,
  isLeftButtonVisible = true,
  disableRightButton = false,
  disableLeftButton = false,
  disableCloseButton = false,
  onRightButtonClick = () => {},
  onLeftButtonClick = () => {},
  onDrawerClose = () => {},
  children = null,
  footerContent = null,
  showToolTip = true,
  backButton = false,
  onBackButtonClick = () => {},
  additionalheaderButtons = '',
}) {
  const { MAX_DRAWER_TITLE_CHAR_COUNT } = UI_VALIDATIONS;
  const theme = useTheme();
  const headerTitle = (
    <span className="fontPoppinsMediumSm truncate block">
      {titleText.length > MAX_DRAWER_TITLE_CHAR_COUNT
        ? titleText.substring(0, MAX_DRAWER_TITLE_CHAR_COUNT) + '...'
        : titleText}
    </span>
  );
  const content = (anchor) => (
    <Box
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : `${drawerWidth ? drawerWidth : '65vw'} `,
        overflow: 'hidden',
      }}
      role="presentation"
    >
      <DrawerHeader theme={theme} className="flex justify-between fontPoppinsMediumSm pl-4 pr-2">
        <div className="flex items-center">
          {backButton && (
            <button onClick={onBackButtonClick} type="button" className="-ml-1 mr-2">
              <ArrowBackSharpIcon fontSize="small" />
            </button>
          )}

          {headerContent ? (
            <span>{headerContent}</span>
          ) : (
            <span className="max-w-39v">
              {showToolTip ? (
                <Tooltip title={titleText} placement="top">
                  {headerTitle}
                </Tooltip>
              ) : (
                { headerTitle }
              )}
            </span>
          )}
        </div>
        <div className="float-right flex justify-between items-center">
          {additionalheaderButtons && <>{additionalheaderButtons}</>}
          <button
            disabled={disableCloseButton}
            onClick={onDrawerClose}
            type="button"
            className="text-white hover:ring-1 ring-white focus:ring-1 rounded-full ml-3"
          >
            <CloseIcon />
          </button>
        </div>
      </DrawerHeader>
      <div className="dialog-content">{children}</div>
      <div className="dialog-footer">
        {footerContent ? (
          footerContent
        ) : (
          <div className="flex flex-row justify-end -mt-3">
            <div>
              {isLeftButtonVisible && (
                <CommonButton
                  disabled={disableLeftButton}
                  btnType="secondary"
                  label={leftButtonText}
                  onClick={onLeftButtonClick}
                />
              )}
            </div>
            <div className={isRightButtonVisible ? 'ml-4' : ''}>
              {isRightButtonVisible && (
                <CommonButton disabled={disableRightButton} label={rightButtonText} onClick={onRightButtonClick} />
              )}
            </div>
          </div>
        )}
      </div>
    </Box>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer anchor={anchor} open={isDrawerOpen} onClose={onDrawerClose} className="dialog-container">
            <div variant="h3" component="div" sx={{ flexGrow: 1 }}>
              {content(anchor)}
            </div>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}

export default memo(CommonDrawer);

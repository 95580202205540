import React, { useEffect, useState, useContext, useRef } from 'react';
import { RiArrowDownSFill, RiArrowRightSFill } from 'react-icons/ri';
import { getFormattedDate, getFormattedTextStatus, textColor } from '../../result';
import ExecutionResultInfoPanel from '../../shared/result-info/execution_info_panel';
import ResultCharts from '../../shared/result_charts';
import { useHistory } from 'react-router';
import { getExecutionTreeExpandAllResultReq } from '@api/api_services';
import { CommonLoader } from '@src/pages/common/common-loader';
import { Tooltip } from '@mui/material';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { setIDB } from '@src/util/localForage_idb_controller';
import SearchBar from '@src/pages/analytics/common/search-bar';
import NoResultsFound from '@src/pages/common/Table/NoResultFound';
import { HighlightText, isEmptyValue, isValueIncludes } from '@util/common_utils';
import { defectAnalyticsLabel, EXECUTION_OVERVIEW_CONSTANT } from '@src/common/ui-constants';
import FilterDropDownList from '@pagescommon/filters/FilterDropDownList';

function ExecutionOverview(props) {
  const search = window.location.search;
  const id = new URLSearchParams(search).get('id');
  const executionId = new URLSearchParams(search).get('executionId');
  const exec_name = window.location.pathname.split('/')[3];

  const { resetProgress, startOrResumeProgress } = useContext(ProgressContainerContext);
  const [machineList, setMachineList] = useState([]);
  const [machineListArray, setMachineListArray] = useState();
  const [infoData, setInfoData] = useState();
  const [chartData, setChartData] = useState();
  const [selectedProject, setSelectedProject] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isHighlighted, setHighlighted] = useState(false);
  const [resultType, setResultType] = useState('root');
  const [suiteType, setSuiteType] = useState('');
  let history = useHistory();
  const suiteName = window.location.pathname.split('/')[3];

  const [userSearched, setuserSearched] = useState('');
  const [data, setData] = useState([]);
  let [isHighlight, setIsHighlight] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');
  let [openSearch, setOpenSearch] = useState(false);
  let [filterStatus, setFilterStatus] = useState('ALL'); // to display in ui selected status
  let selectedFilterStatus = useRef('ALL'); // to keep selectedFilter Value logic
  const [displayFilterOption, setDisplayFilterOption] = useState(false);

  useEffect(() => {
    setData(machineList);
  }, [machineList]);

  useEffect(() => {
    if (isEmptyValue(userSearched)) {
      if (selectedFilterStatus?.current !== 'ALL') {
        handleSearchSubmit('', 'RESET');
      } else {
        setData(machineList);
      }
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  let handleSearchSubmit = async (e, action) => {
    let filteredResult = machineList;

    if (action === defectAnalyticsLabel.btnLabel.apply || selectedFilterStatus?.current !== 'ALL') {
      if (!isEmptyValue(filteredResult) && selectedFilterStatus?.current) {
        filteredResult = filteredResult.filter(({ status }) => {
          return isValueIncludes(selectedFilterStatus?.current, status);
        });
      }

      let searchText = String(e?.target?.value?.trim() || e?.trim())?.toLowerCase();
      if (!isEmptyValue(filteredResult) && e) {
        if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
          if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
            filteredResult = filteredResult.filter(({ machine, clientId }) => {
              if (suiteType === 'Manual') {
                return isValueIncludes(e, clientId);
              } else {
                return isValueIncludes(e, machine);
              }
            });
          }
        }
      }
      setSearchTextHighlight(searchText);
      setIsHighlight(!isHighlight);
      setData(filteredResult);
    } else if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        filteredResult = filteredResult.filter(({ machine, clientId }) => {
          if (suiteType === 'Manual') {
            return isValueIncludes(e, clientId);
          } else {
            return isValueIncludes(e, machine);
          }
        });
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
        setData(filteredResult);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  let handleApplyFilter = (status) => {
    setFilterStatus(status);
    selectedFilterStatus.current = status;
    if (status === 'ALL') {
      setDisplayFilterOption(false);
      if (searchTextHighlight) {
        handleSearchSubmit(searchTextHighlight);
      } else {
        setData(machineList);
      }
    } else {
      setDisplayFilterOption(false);
      handleSearchSubmit(searchTextHighlight, defectAnalyticsLabel.btnLabel.apply);
    }
  };

  useEffect(() => {
    getResultData();
  }, [id]);

  const getResultData = (id) => {
    setInfoData('');
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    setIsLoading(true);
    getExecutionTreeExpandAllResultReq(executionId)
      .then((response) => {
        resetProgress();
        if (response?.data?.responseObject) {
          let typeOfSuite;
          if (
            response.data.responseObject?.suiteType &&
            response.data.responseObject.suiteType.toLowerCase() === 'manual'
          ) {
            typeOfSuite = response.data.responseObject.suiteType;
            setSuiteType(typeOfSuite);
          }
          setIDB('execResult', response.data);
          if (id) {
            getTreeData(response.data, typeOfSuite);
          } else {
            getTreeData(response.data, typeOfSuite);
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        resetProgress();
        setIsLoading(false);
        console.error('EXECUTION_RESULT_TREE : ', error);
      });
  };
  const getTreeData = async (responseData, typeOfSuite) => {
    let _selectedProject = localStorage.getItem('selected-project');
    const selectedProjectParsed = JSON.parse(_selectedProject);
    if (selectedProjectParsed) {
      setSelectedProject(selectedProjectParsed);
    }
    let _machineList = [];
    if (responseData?.responseObject?.suiteType && responseData.responseObject.suiteType.toLowerCase() === 'manual') {
      _machineList = responseData.responseObject.executionResponses.map((value, index) => ({
        platforms: value?.platformStatistics,
        machine: value?.machine,
        clientId: value?.clientId,
        totalScript: value?.scriptStats?.total,
        totalPassed: value?.scriptStats?.totalPassed,
        totalFailed: value?.scriptStats?.totalFailed,
        totalWarning: value?.scriptStats?.totalWarning,
        totalSkipped: value?.scriptStats?.totalSkipped,
        duration: value?.scriptStats?.executionDurationInHourMinSecFormat,
        executedOn: value?.executedOn,
        status: value?.status,
      }));
    } else {
      _machineList = responseData?.responseObject?.platformStatistics?.map((value, index) => {
        return {
          platforms: value?.platform.slice(1),
          machine: value?.machine,
          runId: value?.runId,
          clientId: value?.clientId,
          totalScript: value?.platform[0]?.total,
          totalPassed: value?.platform[0]?.totalPassed,
          totalFailed: value?.platform[0]?.totalFailed,
          totalWarning: value?.platform[0]?.totalWarning,
          totalSkipped: value?.platform[0]?.totalSkipped,
          executionResponses: responseData?.responseObject?.executionResponses,
          executedOn: value?.platform[0]?.executedOn,
          status: value?.platform[0]?.status,
        };
      });
    }
    if (_machineList) setMachineList(_machineList);
    const _machineListarray = responseData?.responseObject?.executionResponses.map((value, index) => ({
      children: value?.platformStatistics?.map((value) => ({
        executionDurationInHourMinSecFormat: value.executionDurationInHourMinSecFormat,
        title: value?.browser,
        type: 'Script',
        executedOn: value?.executedOn,
        stepResultStats: {
          total: value?.total,
          totalWarning: value?.totalWarning,
          totalFailed: value?.totalFailed,
          totalSkipped: value?.totalSkipped,
          totalTerminated: value?.totalTerminated,
          totalPassed: value?.totalPassed,
          executionDurationInHourMinSecFormat: value?.executedOn,
          executionDuration: value?.executionDuration,
        },
        folder: false,
        status: value.status ? value.status : '',
      })),
      parentId: 'rootModule',
      folder: true,
      type: 'Module',
      title: value?.machine,
      clientId: value?.clientId,
      totalScript: value?.moduleStats?.totalSkipped,
      totalPassed: value?.moduleStats?.totalPassed,
      totalFailed: value?.moduleStats?.totalFailed,
      totalWarning: value?.moduleStats?.totalWarning,
      totalSkipped: value?.moduleStats?.totalSkipped,
      duration: value?.moduleStats?.executionDuration,
      executedOn: value?.executedOn,
      status: value?.status ? value.status : '',
      name: value?.children ? value?.children[0]?.children[0]?.name : '',
      id: value?.children ? value?.children[0]?.children[0]?.id : '',
      executionId: value?.executionId,
      runId: value?.runId,
      storageDetails: value.storageDetails,
    }));
    setIDB('tree-data', _machineListarray);

    if (_machineListarray) setMachineListArray(_machineListarray);
    const responseObject = responseData?.responseObject;
    const executionResponses = responseData?.responseObject?.executionResponses;
    const execResultExecutionResponses = responseData?.responseObject?.executionResponses;
    const getMachinesCount = (machines) => {
      return [...new Set(machines.map((machine) => machine.clientId))]?.length;
    };
    if (responseData.responseObject.moduleStats === undefined || !responseObject?.platformStatistics) {
      let graphInfoData = {
        graphData: {
          moduleStats: execResultExecutionResponses && execResultExecutionResponses[0].moduleStats,
          postConditionStats: executionResponses && executionResponses[0].postConditionStats,
          preConditionStats: executionResponses && executionResponses[0].preConditionStats,
          scriptStats: executionResponses && executionResponses[0].scriptStats,
        },
        infoData: {
          executionDurationInHourMinSecFormat:
            execResultExecutionResponses && getMaxExecutionDuration(execResultExecutionResponses),
          executedOn: execResultExecutionResponses && execResultExecutionResponses[0]?.executedOn,
          totalMachine: execResultExecutionResponses && getMachinesCount(execResultExecutionResponses),
          status: status(responseData, execResultExecutionResponses && execResultExecutionResponses),
          runId: execResultExecutionResponses && execResultExecutionResponses[0]?.executionId,
          moduleName: suiteName,
        },
      };
      if (typeOfSuite && typeOfSuite.toLowerCase() === 'manual') {
        graphInfoData['graphData'] = {
          moduleStats: responseObject?.moduleStats,
          postConditionStats: responseObject?.postConditionStats,
          preConditionStats: responseObject?.preConditionStats,
          scriptStats: responseObject?.scriptStats,
        };
      }
      setInfoAndChartData(graphInfoData);
    } else {
      let graphInfoData = {
        graphData: {
          moduleStats: responseObject?.moduleStats,
          postConditionStats: responseObject?.postConditionStats,
          preConditionStats: responseObject?.preConditionStats,
          scriptStats: responseObject?.scriptStats,
        },
        infoData: {
          executionDurationInHourMinSecFormat: executionResponses && getMaxExecutionDuration(executionResponses),
          executedOn: responseObject?.platformStatistics[0]?.platform[0]?.executedOn,
          totalMachine: getMachinesCount(responseObject?.executionResponses),
          status: status(responseData, responseObject?.executionResponses),
          runId: executionResponses && executionResponses[0]?.executionId,
          moduleName: suiteName,
        },
      };
      setInfoAndChartData(graphInfoData);
    }
  };

  const getMaxExecutionDuration = (allExecutions) => {
    const allDurations = allExecutions.map((_exec) => _exec?.executionDuration);
    const maxVal = Math.max(...allDurations);
    return allExecutions[allDurations?.indexOf(maxVal)]?.executionDurationInHourMinSecFormat;
  };

  const status = (responseData, machines) => {
    if (responseData?.responseObject?.suiteStatus) {
      return responseData?.responseObject?.suiteStatus;
    }
    let status = machines.map((mac) => {
      if (mac.status === 'Terminated') return 'Terminated';
      if (mac.status === 'FAIL') return 'FAIL';
      if (mac.status === 'WARNING') return 'WARNING';
      if (mac.status === 'PASS') return 'PASS';
      if (mac.status === 'Aborted') return 'Aborted';
      if (mac.status === 'SKIPPED') return 'SKIP';
      if (mac.status === 'Partially Executed') return 'Partially Executed';
      if (mac.status === 'Running') return 'Running';
      return '';
    });
    if (status.includes('Partially Executed')) {
      return 'Partially Executed';
    } else if (status.includes('Running')) {
      return 'Running';
    } else if (status.includes('Terminated')) {
      return 'Terminated';
    } else if (status.includes('Aborted')) {
      return 'Aborted';
    } else if (status.includes('FAIL')) {
      return 'FAIL';
    } else if (status.includes('WARNING')) {
      return 'WARNING';
    } else if (status.includes('SKIP')) {
      return 'SKIP';
    } else if (status.includes('PASS')) {
      return 'PASS';
    } else return 'N/A';
  };

  const setInfoAndChartData = (_data) => {
    let data = _data.infoData;
    if (data) {
      let _selectedProject = localStorage.getItem('selected-project');
      const selectedProjectParsed = JSON.parse(_selectedProject ? _selectedProject : '');
      if (selectedProjectParsed) {
        setSelectedProject(selectedProjectParsed);
      }
      let _info;
      setChartData();
      _info = {
        runNumber: 8,
        runId: data?.runId,
        status: data?.status,
        totalMachine: data?.totalMachine,
        duration: data?.executionDurationInHourMinSecFormat,
        executedOn: data?.executedOn,
        moduleName: data?.moduleName.replace(/%20/g, ' '),
      };

      setGraphChartData(_data?.graphData);
      setInfoData(_info);
    }
  };

  const setGraphChartData = (data) => {
    let _defaultStats = {
      total: 0,
      totalWarning: 0,
      totalFailed: 0,
      totalSkipped: 0,
      totalTerminated: 0,
      totalPassed: 0,
    };
    let _chart = {
      moduleStats: _defaultStats,
      scriptStats: _defaultStats,
      preConditionStats: _defaultStats,
      postConditionStats: _defaultStats,
    };
    if (data) {
      if (data?.moduleStats) {
        _chart['moduleStats'] = data?.moduleStats;
      }
      if (data?.scriptStats) {
        _chart['scriptStats'] = data?.scriptStats;
      }
      if (data?.preConditionStats) {
        _chart['preConditionStats'] = data?.preConditionStats;
      }
      if (data?.postConditionStats) {
        _chart['postConditionStats'] = data?.postConditionStats;
      }
    }
    setChartData(_chart);
  };

  const toggleMoreDetailsHighlight = () => {
    if (isHighlighted) {
      setHighlighted(false);
    } else {
      setHighlighted(true);
    }
  };

  const onMachineClick = (event, data) => {
    // event.preventDefault();
    console.log('machine clicked=', data.machine);
  };

  let toggleAccordionIcon = (event, elementId) => {
    let expandElement = document.getElementById(elementId + '-expand');
    let collapseElement = document.getElementById(elementId + '-collapse');
    if (expandElement && expandElement.style) {
      //panelHtmlElement path may need to change based on the number of elements added/removed
      const panelHtmlElement = collapseElement.parentElement.parentElement.parentElement.parentElement.nextSibling;
      if (expandElement.style.display === 'none') {
        expandElement.style.display = 'inline-block';
        panelHtmlElement.style.display = 'block';
      } else if (expandElement.style.display !== 'none') {
        expandElement.style.display = 'none';
        panelHtmlElement.style.display = 'none';
      }
    }
    if (collapseElement && collapseElement.style) {
      const panelHtmlElement = collapseElement.parentElement.parentElement.parentElement.parentElement.nextSibling;
      if (collapseElement.style.display === 'none' || collapseElement.style.display === '') {
        collapseElement.style.display = 'inline-block';
        panelHtmlElement.style.display = 'block';
      } else if (collapseElement.style.display !== 'none') {
        collapseElement.style.display = 'none';
        panelHtmlElement.style.display = 'none';
      }
    }
  };
  let accordionIcon = (elementId) => {
    return (
      <span>
        <RiArrowRightSFill className="expand-icon" id={`${elementId}-expand`} />
        <RiArrowDownSFill className="collapse-icon" id={`${elementId}-collapse`} />
      </span>
    );
  };

  const machineRoute = (machineName, status, runId, clientId) => {
    if (status !== 'N/A') {
      if (machineListArray) {
        let machin_basic_info = machineListArray.map((data) => ({
          title: data?.title,
          name: data?.name,
          executionId: data?.executionId,
          runId: data?.runId,
          clientId: data?.clientId,
        }));
        machineName = suiteType === 'Manual' ? clientId : machineName;
        localStorage.setItem('machineInfo', JSON.stringify(machin_basic_info));
        /* NOTE  :: runId store in local to handle breadcrumbs and navigation form test dev execution dashboard and test execution status click navigation
  */
        localStorage.setItem('runId',runId);
        history.push(
          `/execution/suite/${exec_name}/execution dashboard/overview/machines/${machineName}/Result?executionId=${executionId}${
            runId ? `&runId=${runId}` : ''
          }&multiple=true`
        );
      }
    }
  };

  let { MACHINE, PLATFORM, TOTAL_SCRIPT, TOTAL_PASSED, TOTAL_FAILED, TOTAL_WARNING, TOTAL_SKIPPED, STATUS } =
    EXECUTION_OVERVIEW_CONSTANT;
  let tableHeader = [
    {
      label: MACHINE,
      class: 'col-span-2',
    },
    { label: PLATFORM },
    { label: TOTAL_SCRIPT },
    { label: TOTAL_PASSED },
    { label: TOTAL_FAILED },
    { label: TOTAL_WARNING },
    { label: TOTAL_SKIPPED },
    { label: STATUS, class: `${suiteType ? 'col-span-2' : 'col-span-1'}` },
  ];

  return (
    <div>
      {isLoading ? (
        <CommonLoader />
      ) : (
        <>
          <div className="grid grid-cols-5 gap-3">
            <div className="col-end-3 col-span-12">
              <div key="result-info" className="bg-white items-center h-44 card-box-shadow overflow-hidden">
                {infoData ? (
                  <ExecutionResultInfoPanel
                    data={infoData}
                    projectType={selectedProject?.type}
                    focusMoreDetails={toggleMoreDetailsHighlight}
                    resultType={resultType}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="col-span-3">
              <div key="result-chart" className="bg-white h-44 overflow-hidden card-box-shadow">
                {chartData && <ResultCharts chartData={chartData} type="Module" suiteType={suiteType} />}
              </div>
            </div>
          </div>
          <div className="mt-2 bg-white card-box-shadow overflow-hidden">
            <div className="grid grid-cols-12 result-header-overView gap-2 px-2 py-4" key="machine-list-header">
              <div className="col-span-12 ">
                <div className="float-right flex gap-1 pr-1">
                  <SearchBar
                    open={openSearch}
                    searchValue={userSearched}
                    handleSearch={handleSearch}
                    onSearchClose={handleSearchClose}
                    handleSearchOpen={handleSearchOpen}
                    enterKeyHandler={handleSearchSubmit}
                    disable={isEmptyValue(machineList)}
                  />

                  <FilterDropDownList
                    data={machineList}
                    selectedStatus={filterStatus}
                    open={displayFilterOption}
                    onApply={handleApplyFilter}
                    onClickFilter={setDisplayFilterOption}
                  />
                </div>
              </div>
              {tableHeader.map((headObj) => (
                <div className={headObj?.class ? headObj?.class : 'col-span-1'}>{headObj?.label}</div>
              ))}
              {suiteType ? (
                <div className="col-span-2">{EXECUTION_OVERVIEW_CONSTANT.EXECUTED_ON}</div>
              ) : (
                <div className="col-span-3">
                  <div className="grid gap-2 grid-cols-6">
                    <div className="col-span-2">{EXECUTION_OVERVIEW_CONSTANT.DURATION}</div>
                    <div className="col-span-4">{EXECUTION_OVERVIEW_CONSTANT.EXECUTED_ON}</div>
                  </div>
                </div>
              )}
            </div>
            {!isEmptyValue(data) && (
              <div className="px-2 py-4 overflow-auto result-body" id="journal-scroll">
                {data.map((mac, index) => (
                  <div key={`header${mac.machine + index}`}>
                    <div
                      className="accordion"
                      onClick={(e) => {
                        toggleAccordionIcon(e, mac.machine + index);
                      }}
                    >
                      <div className="grid grid-cols-12 h-8 items-center gap-2">
                        <div
                          className="col-span-2 machine-subHeader truncate"
                          key={mac.machine + index}
                          id="dependsOnScripts"
                        >
                          {accordionIcon(mac.machine + index)}
                          <Tooltip title={suiteType ? mac.clientId : mac.machine}>
                            <span
                              title={suiteType ? mac.clientId : mac.machine}
                              onClick={(e) => onMachineClick(e, mac)}
                            >
                              <HighlightText
                                text={suiteType ? mac.clientId : mac.machine}
                                highlight={searchTextHighlight}
                              />
                            </span>
                          </Tooltip>
                        </div>
                        <div></div>
                        <div className="platform-title">{mac.totalScript}</div>
                        <div className="platform-title">{mac.totalPassed}</div>
                        <div className="platform-title">{mac.totalFailed}</div>
                        <div className="platform-title">{mac.totalWarning}</div>
                        <div className="platform-title">{mac.totalSkipped}</div>
                        <div
                          className={`platform-title ${suiteType ? 'col-span-2' : 'col-span-1'}`}
                          onClick={() => machineRoute(mac.machine, mac.status, mac?.runId, mac.clientId)}
                        >
                          <span className={`cursor-pointer ${textColor(mac.status)}`}>
                            {getFormattedTextStatus(mac.status)}
                          </span>
                        </div>
                        {suiteType ? (
                          <div className="platform-title col-span-2">{getFormattedDate(mac.executedOn, 'full')}</div>
                        ) : (
                          <div className="col-span-3">
                            <div className="grid gap-2 grid-cols-6">
                              <div className="platform-title col-span-2">
                                {mac?.executionResponses[index]?.moduleStats?.executionDurationInHourMinSecFormat}
                              </div>
                              <div className="platform-title col-span-4">
                                {getFormattedDate(mac.executedOn, 'full')}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="panel">
                      {mac.platforms.map((pf, ind) => (
                        <div className={`grid grid-cols-12 h-8 items-center gap-2`} key={`pf${index}${ind}`}>
                          <div className={`col-span-2`}></div>
                          <div className="platform-title truncate">
                            <Tooltip title={pf?.platform} placement="top">
                              <span>{pf?.platform}</span>
                            </Tooltip>
                          </div>
                          <div className="platform-title">
                            <span>{pf?.totalScript}</span>
                          </div>
                          <div className="platform-title">
                            <span>{pf?.totalPassed}</span>
                          </div>
                          <div className="platform-title">
                            <span>{pf?.totalFailed}</span>
                          </div>
                          <div className="platform-title">{pf?.totalWarning}</div>
                          <div className="platform-title">{pf?.totalSkipped}</div>
                          <div className={`platform-title ${suiteType ? 'col-span-2' : 'col-span-1'}`}>
                            <span className={`${textColor(pf.status)}`}>{getFormattedTextStatus(pf.status)}</span>
                          </div>
                          {suiteType ? (
                            <div className="platform-title col-span-2">{pf?.duration}</div>
                          ) : (
                            <div className="col-span-3">
                              <div className="grid gap-2 grid-cols-6">
                                <div className="platform-title col-span-2">{pf?.duration}</div>
                                <div class="platform-title col-span-4">{getFormattedDate(pf?.executedOn, 'full')}</div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}

            {isEmptyValue(data) && (searchTextHighlight || filterStatus !== 'ALL') && (
              <NoResultsFound className="my-10" />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default ExecutionOverview;

export const LAYOUT_CONSTANTS = {
  APP_TITLE: 'FIREFLINK',
  FIREFLINK_RIGHTS: '© Fireflink all rights reserved',
  CLOSE_VIEW_RIGHTS: '© Fireflink',
  PRIMARY_COLOR: '#434db8',
  WHITE_COLOR: '#ffff',
  ALL_PROJECT_LIST_BG_COLOR: '#303aa2',
  NO_PROJECTS: '-No Projects-',
  SETTINGS: 'Settings',
  SIGN_OUT: 'Sign out',
  NOTIFICATIONS: 'Notifications',
  USER_PROFILE: 'User Profile',
  BLACK_COLOR: '#00000080',
};

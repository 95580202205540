import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import '@pages/licenses/styles/license-alert-popup.scss';
import { ReactComponent as YellowAlert } from '@assets/svg-imports/yellow_alert.svg';
import { BUTTON_CONSTANTS, LICENSE_DETAILS_CONSTANTS } from '@src/common/ui-constants';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import LabeledInput from '@pages/common/Inputs/LabeledInput';
import LabeledPasswordInput from '@pages/common/Inputs/LabeledPasswordInput';
import { signInRequest, updateUserData } from '@api/api_services';
import { useAlert } from '@pages/common/alert_service/useAlert';
import style from '@pages/licenses/styles/licenses.module.scss';
import { Button } from 'fireflink-ui';
import { getEncryptData } from '@common/security';

const ChangeLicenseModal = (props) => {
  const { AlertContatiner, MyAlert } = useAlert();
  const user = JSON.parse(localStorage.getItem('test-optimize-user') || '');

  const formik = useFormik({
    initialValues: {
      currentLicenseId: '',
      emailId: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().trim().required('Password is required'),
    }),
    onSubmit: async (values) => {
      const payload = {
        ...values,
        password: getEncryptData(values.password),
      };
      await handleSubmit(payload);
    },
  });

  const handleSubmit = async (values) => {
    try {
      let userDataResult = await updateUserData(user?.id, {
        defaultLicenseId: props?.data?.id,
      });
      try {
        if (userDataResult.data.responseCode === 200) {
          let reLoginRequestWithUpdatedResult = await signInRequest(values);
          if (reLoginRequestWithUpdatedResult?.data?.responseCode === 200) {
            MyAlert?.success(userDataResult?.data?.message);
            onSwitchLicenseSuccess(reLoginRequestWithUpdatedResult?.data);
          } else {
            MyAlert?.danger(reLoginRequestWithUpdatedResult?.data?.message);
          }
        }
      } catch (error) {
        console.error('SIGN IN API FAILED', error);
      }
    } catch (error) {
      console.error('UPDATE USER DATA API FAILED', error);
    }
  };

  useEffect(() => {
    formik.resetForm();
    const user = JSON.parse(localStorage.getItem('test-optimize-user') || '');
    formik.setFieldValue('emailId', user.userName);
    formik.setFieldValue('currentLicenseId', props?.data?.id);
  }, [props?.data?.id]);

  const onSwitchLicenseSuccess = (response) => {
    localStorage.setItem('license-level-timezone', response?.responseMap?.licenseTimeZone);
    localStorage.setItem('test-optimize-user', JSON.stringify(response?.responseObject || {}));
    localStorage.setItem('selected-project', JSON.stringify({ id: '', name: 'All Projects' }));
    window.location = '/licenses/fireflink-license';
  };
  return (
    <>
      <div className={style['default-license-alert']}>
        <AlertContatiner />
      </div>
      <Modal
        open={props.openForLicenseChange}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="default-license-modal">
          <form onSubmit={formik.handleSubmit}>
            <div className="header-default-license-modal">
              <div className="successImg">
                <YellowAlert className="svgImgStyle" />
              </div>
              <div className="mt-4">
                <p className="svgContentYellow fontPoppinsMediumXX2">
                  Warning! <span className="text-black">Default License</span>
                </p>
                <br />
                <p className="default-license-text -mt-4 ml-6">
                  <span className="fontPoppinsRegularMd">
                    Hi {user?.name},<br /> {LICENSE_DETAILS_CONSTANTS?.PLEASE_ENTER_YOUR_PASSWORD_TO_DEFAULT_LICENSE}
                  </span>
                  <span className="fontPoppinsSemiboldMd">"{props.data.licenseName}".</span>
                </p>
              </div>
            </div>
            <div className="login-content">
              <LabeledInput
                label="Email"
                required
                placeholder="Enter your email"
                value={formik.values.emailId}
                disabled
                autoComplete="off"
                name="emailId"
                wrapperClassName="w-64 mt-2"
                className="label-text"
              />

              <LabeledPasswordInput
                label="Password"
                className="label-text"
                required
                placeholder="Enter your password"
                wrapperClassName="w-64 mt-2"
                error={formik.errors.password && formik.touched.password}
                value={formik.values.password}
                onBlur={formik.handleBlur}
                autoComplete="off"
                onChange={formik.handleChange}
                name="password"
                helperText={<span className="-ml-3">{formik.touched.password && formik.errors.password}</span>}
              />
            </div>
            <div className="box-footer flex ">
              <Button
                label="Button"
                variant="secondary"
                transparentBackground
                size="small"
                onClick={() => {
                  props.handleCloseForLicenseChange();
                }}
              >
                {BUTTON_CONSTANTS?.CANCEL}
              </Button>
              <Button
                variant="primary"
                size="small"
                type="submit"
                disabled={formik.errors.password && formik.touched.password}
              >
                {BUTTON_CONSTANTS?.CONFIRM}
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
};
export default ChangeLicenseModal;

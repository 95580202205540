import { getCloudPlatformDataReq } from '@api/api_services';
import { getBrowserLabel, getOsLabel } from './sys-config-common-methods';
import { FIRECLOUD_DATA } from '@src/common/ui-constants';

export async function getCloudPlatformOSData(
  selectedInstance,
  selectedSystem,
  defaultSystem,
  update = false,
  cloudPlatformType = '',
  type = ''
) {
  try {
    cloudPlatformType = cloudPlatformType?.toLowerCase();
    cloudPlatformType =
      cloudPlatformType === 'browserstack'
        ? 'BrowserStack'
        : cloudPlatformType === 'lambdatest'
          ? 'LambdaTest'
          : cloudPlatformType === 'saucelabs'
            ? 'SauceLab'
            : cloudPlatformType === FIRECLOUD_DATA.FIRE_CLOUD
            ? FIRECLOUD_DATA.FIRECLOUD : '';

    const requestBody = {
      type: cloudPlatformType,
      username: selectedInstance?.username,
      accessKey: selectedInstance?.accessKey,
      dataCenter: selectedInstance?.defaultDataCenter,
    };
    const response = await getCloudPlatformDataReq(requestBody);
    const browserData = response?.data?.responseObject;
    if (browserData) {
      let selectedOS;
      let osArray = [];
      let data = browserData;
      const { browsers } = browserData || {};
      for (const os in browsers) {
        osArray.push({
          label: getOsLabel(os),
          value: os,
        });
      }
      let machine;
      if (selectedSystem.clientId === defaultSystem.clientId && !update) {
        if (integratedEnvironment(defaultSystem.executionEnvironment)) {
          machine = defaultSystem.machine;
          if(type && type != "" && machine?.osName?.toLowerCase() === FIRECLOUD_DATA.FIRE_CLOUD) {
            selectedOS = osArray[0];
          } else {
            let obj = {
              label: getOsLabel(machine?.osVersion ? machine.osName + '-' + machine.osVersion : machine.osName),
              value: machine?.osVersion ? machine.osName + '-' + machine.osVersion : machine.osName,
            };
            selectedOS = obj;
          }
        }
      } else {
        selectedOS = osArray[0];
      }
      const retObj = await getCloudPlatformBrowserAndDeviceData(
        data,
        selectedOS,
        selectedSystem,
        defaultSystem,
        update,
        type
      );
      const { options: androidAppOptions, selectedOption: androidAppSelectedOption } = await getInstanceAppData(
        data,
        'android',
        selectedSystem,
        defaultSystem,
        update
      );
      const { options: iosAppOptions, selectedOption: iosAppSelectedOption } = await getInstanceAppData(
        data,
        'ios',
        selectedSystem,
        defaultSystem,
        update
      );
      return {
        ...retObj,
        osObj: { options: osArray, selectedOption: selectedOS },
        apiData: data,
        appDataObj: { options: androidAppOptions, selectedOption: androidAppSelectedOption },
        appDataObjIos: { options: iosAppOptions, selectedOption: iosAppSelectedOption },
      };
    }
  } catch (err) {
    console.error('GET_CLOUD_PLATFORM_LIST : ', err);
  }
}
export async function getInstanceAppData(data, osType = 'android', selectedSystem, defaultSystem, update = false) {
  let appDataArray = [];
  let appData = data?.[`${osType}App`];
  for (const appKey in appData) {
    let appDataObject = {
      appName: appData?.[appKey],
      appId: appKey,
      label: appData?.[appKey],
      value: appKey,
    };
    appDataArray.push(appDataObject);
  }
  let selectedAppData = getSelectedOptionData(defaultSystem, selectedSystem, update, osType, 'appName');

  return {
    options: appDataArray,
    selectedOption: selectedAppData,
  };
}

export async function getCloudPlatformBrowserAndDeviceData(
  data,
  selectedOs,
  selectedSystem,
  defaultSystem,
  update = false,
  type
) {
  try {
    const { deviceObj, osVersionObj: androidOsVersionObj } = getCloudPlatformDevices(
      data,
      selectedSystem,
      defaultSystem,
      update,
      'android'
    );
    const { deviceObj: deviceObjIOS, osVersionObj: iosOsVersionObj } = getCloudPlatformDevices(
      data,
      selectedSystem,
      defaultSystem,
      update,
      'ios'
    );
    const retObj = getCloudPlatformBrowserNameData(data, selectedOs, selectedSystem, defaultSystem, update, type);
    return {
      ...retObj,
      deviceObj,
      androidOsVersionObj,
      deviceObjIOS,
      iosOsVersionObj,
    };
  } catch (err) {
    console.error('GET_CLOUD_PLATFORM_BROWSER_AND_DEVICE : ', err);
  }
}
export function getCloudPlatformDevices(data, selectedSystem, defaultSystem, update = false, osType = 'android') {
  try {
    let deviceArray = [];
    for (const osName in data?.[osType]) {
      deviceArray.push({
        label: osName,
        value: osName,
      });
    }
    let selectedDevice = getSelectedOptionData(defaultSystem, selectedSystem, update, osType, 'name');

    if(!selectedDevice) {
      selectedDevice = {};
  }
  let osVersionObj = getMobileOSVersion(data, selectedDevice, selectedSystem, defaultSystem, update, osType)
  if(!osVersionObj) {
      osVersionObj = {};
  }
    return {
      deviceObj: {
        options: deviceArray,
        selectedOption: selectedDevice,
      },
      osVersionObj: osVersionObj,
    };
  } catch (err) {
    console.error('GET_CLOUD_PLATFORM_DEVICE : ', err);
  }
}

export const getMobileOSVersion = (
  data,
  selectedOs,
  selectedSystem,
  defaultSystem,
  update = false,
  osType = 'android'
) => {
  try {
    let deviceVersionArray = [];
    if(data?.[osType]?.[selectedOs?.value]){
    for (const osName of data?.[osType]?.[selectedOs?.value]) {
      deviceVersionArray.push({
        label: osName,
        value: osName,
      });
    }}
    let selectedDevice = getSelectedOptionData(defaultSystem, selectedSystem, update, osType, 'version');
    return { options: deviceVersionArray, selectedOption: selectedDevice };
  } catch (err) {
    console.error('GET_CLOUD_PLATFORM_DEVICE : ', err);
  }
};

export function getCloudPlatformBrowserNameData(data, selectedOs, selectedSystem, defaultSystem, update = false, type) {
  try {
    let selectedBrowser;
    let browserArray = [];
    for (const browserName in data?.browsers[selectedOs?.value]) {
      if (browserName !== 'electron') {
        browserArray.push({
          label: getBrowserLabel(browserName),
          value: browserName,
        });
      }
    }
    if (selectedSystem.clientId === defaultSystem.clientId && !update) {
      if (integratedEnvironment(defaultSystem.executionEnvironment)) {
        let systemConfigDetails;
        defaultSystem?.systemConfigDetails?.forEach((ele) => {
          if (ele.subType === 'browser') {
            systemConfigDetails = { ...ele };
          }
        });
        if (systemConfigDetails) {
          let obj = {
            value: systemConfigDetails.name,
            label: getBrowserLabel(systemConfigDetails.name),
          };
          selectedBrowser = obj;
        } else {
          if(type && type !== "") {
            selectedBrowser = browserArray[0];
          }
        }
      }
    } else {
      selectedBrowser = browserArray[0];
    }
    const retObj = getCloudPlatformBrowserVerData(
      data,
      selectedOs,
      selectedBrowser,
      selectedSystem,
      defaultSystem,
      update
    );
    return {
      browserObj: { options: browserArray, selectedOption: selectedBrowser },
      browserVerObj: { ...retObj },
    };
  } catch (err) {
    console.error('GET_CLOUD_PLATFORM_BROWSER : ', err);
  }
}

export function getCloudPlatformBrowserVerData(
  data,
  selectedOs,
  selectedBrowser,
  selectedSystem,
  defaultSystem,
  update = false
) {
  try {
    let browserVersionArray = [];
    let selectedBrowserVersion;

    if( data?.browsers[selectedOs?.value]) {
      for (const browserVersion of data?.browsers?.[selectedOs?.value]?.[selectedBrowser?.value]) {
        browserVersionArray.push({
          label: browserVersion,
          value: browserVersion,
        });
      }
    }
    
    if (defaultSystem.clientId === selectedSystem.clientId && !update) {
      if(defaultSystem.systemConfigDetails) {
        defaultSystem?.systemConfigDetails?.forEach((ele) => {
          if (ele.subType === 'browser' && ele.name === selectedBrowser.value) {
            let systemConfigDetails = { ...ele };
            let obj = {
              label: systemConfigDetails.version,
              value: systemConfigDetails.version,
            };
            selectedBrowserVersion = obj;
          }
        });
        if(!selectedBrowserVersion) {
          selectedBrowserVersion = browserVersionArray[0];
        }
      } 
    } else {
      selectedBrowserVersion = browserVersionArray[0];
    }
    return { options: browserVersionArray, selectedOption: selectedBrowserVersion };
  } catch (err) {
    console.error('GET_CLOUD_PLATFORM_BROWSER_VER : ', err);
  }
}

export function getSelectedOptionData(defaultSystem, selectedSystem, update, osType, label) {
  let selectedOption = null;
  if (defaultSystem.clientId === selectedSystem.clientId && !update) {
    let systemConfigDetails = defaultSystem.systemConfigDetails;
    if (systemConfigDetails?.length) {
      systemConfigDetails.forEach((ele) => {
        const { subType, platform, appId, appName, long_name } = ele || {};
        if (subType === 'device' && platform === osType) {
          selectedOption = {
            label: ele[label],
            value: ele[label],
            platform: platform,
            appId: appId,
            appName: appName,
            fullName: long_name,
          };
        }
      });
    }
  }
  return selectedOption;
}

function checkEnv(env) {
  return env?.includes('lambdatest-') ||
         env?.includes('browserstack-') ||
         env?.includes('saucelabs-') ||
         env?.includes('firecloud');
}
export function integratedEnvironment(environment) {
  const env = environment?.toLowerCase();
 return checkEnv(env)
}

import cx from 'classnames';
import LicenseRowCardStyle from '@pages/licenses/styles/license-row-card.module.scss';
import { ReactComponent as ShieldUserIcon } from '@assets/svg-imports/user-license-shield.svg';
import { Skeleton } from '@mui/material';

function PlaceholderLicenseRowCard() {
  return (
    <div className={cx(LicenseRowCardStyle['wrapper'], LicenseRowCardStyle['placeholder'])}>
      <div className="flex items-center h-full">
        <div
          className={cx(
            'flex justify-center items-center border-r h-full',
            LicenseRowCardStyle['radio-control-wrapper'],
            LicenseRowCardStyle['col-0.5']
          )}
        >
          <Skeleton variant="circular" width={20} height={20} />
        </div>
        <div className={cx('flex items-center justify-between px-4', LicenseRowCardStyle['col-11.5'])}>
          <div className="flex items-center w-3/12">
            <ShieldUserIcon className={cx(LicenseRowCardStyle['shield-icon'])} />

            <div className="flex flex-col ml-3">
              <h2 className={cx(LicenseRowCardStyle['title'], 'fontPoppinsSemiboldSm')}>Name</h2>
              <Skeleton width="90%" />
            </div>
          </div>
          <div className="flex flex-col w-2/12">
            <h2 className={cx(LicenseRowCardStyle['title'], 'fontPoppinsSemiboldSm')}>My Privilege</h2>
            <Skeleton width="90%" />
          </div>
          <div className="flex flex-col w-2/12 pl-3">
            <h2 className={cx(LicenseRowCardStyle['title'], 'fontPoppinsSemiboldSm')}>Type</h2>
            <Skeleton width="70%" />
          </div>
          <div className="flex flex-col w-2/12">
            <h2 className={cx(LicenseRowCardStyle['title'], 'fontPoppinsSemiboldSm')}>Parallel Runs</h2>
            <Skeleton width="90%" />
          </div>
          <div className="flex flex-col w-2/12">
            <h2 className={cx(LicenseRowCardStyle['title'], 'fontPoppinsSemiboldSm')}>Status</h2>
            <Skeleton width="90%" />
          </div>
          <div className="flex flex-col w-2/12">
            <h2 className={cx(LicenseRowCardStyle['title'], 'fontPoppinsSemiboldSm')}>Purchsed By</h2>
            <Skeleton width="90%" />
          </div>
          <div className="w-2/12 flex justify-end">
            <Skeleton width="60%" variant="rectangular" />
          </div>
          <div className="w-2/12 flex justify-end">
            <Skeleton width="60%" variant="rectangular" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlaceholderLicenseRowCard;

import React, { useEffect } from 'react';

const Pagination = ({
  postsPerPage,
  totalPosts,
  paginate,
  setImmediatePrevPage,
  indexOfFirstPost,
  indexOfLastPost,
  setImmediateNextPage,
  goToFirstPage,
  goToLastPage,
  goToSpecificPage,
  currentPage,
}) => {
  const pageNumbers = [];
  const [selectedPageNum, setSelectedPageNum] = React.useState(1);

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    setSelectedPageNum(currentPage);
  }, [currentPage]);

  return (
    <>
      <div className="float-right mr-3 pb-3 ">
        <div></div>
        <div>
          <ul className="pagination flex flex-row float-right">
            <p className="page-count-style ">
              {indexOfFirstPost + 1} - {indexOfLastPost > totalPosts ? totalPosts : indexOfLastPost} of {totalPosts}
            </p>
            <span className="pageborderleft ml-2 pl-2 "></span>
            <span
              className={`${selectedPageNum === 1 || totalPosts === 0 ? 'disable_icon' : 'pageArrowEnable'} pagination-icon-style pageArrow -mt-1.5`}
              onClick={() => {
                setImmediatePrevPage();
                setSelectedPageNum(selectedPageNum - 1);
              }}
            >
              {'<'}{' '}
            </span>
            <div className="currentpagecount-1 ml-3 mr-2">
              <span className="pl-2 pr-2 pageCount ">{selectedPageNum}</span>
            </div>
            <span className=" ml-1 -mt-1.5">
              <span
                className={`${selectedPageNum === pageNumbers.length || totalPosts === 0 ? 'disable_icon' : 'pageArrowEnable'} pagination-icon-style pageArrow -mt-1.5`}
                onClick={() => {
                  setImmediateNextPage();
                  setSelectedPageNum(selectedPageNum + 1);
                }}
              >
                {'>'}{' '}
              </span>
            </span>
          </ul>
        </div>
      </div>

      <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"></nav>
    </>
  );
};

export default Pagination;

import React, { useRef, useState, useEffect } from 'react';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ConfigMemoryStyle from '@pages/configuration/assests/styles/configmemory.module.scss';
import cx from 'classnames';
import {
  _labelPositionPreviousRow,
  _labelPositionNextRow,
} from '@pages/configuration/EmailConfig/CommonMethodOfEmailConfig';
import { isEmailValid } from '@util/common_utils';
import { REGEX, UI_VALIDATIONS } from '@src/util/validations';
import { EMAIL_VALIDATION_ERRORS } from '../../../common/ui-constants';

function CreateRecipientInputFiled({ handleCloseGroup, createGroup, errorName, errorTouchedName }) {
  const [recipient, setRecipient] = useState([{ name: '', emailId: '' }]);
  const [touched, setTouched] = useState([{ name: false, emailId: false }]);
  const [errors, setErrors] = useState([]);
  const whiteSpaceRegex = REGEX.WHITE_SPACE_REGEX;
  const [emailDuplicateArray, setEmailDuplicateArray] = useState([]);
  const [emailUniqueValidate, setEmailUniqueValidate] = useState('');
  const scrollableDivRef = useRef(null);
  const [shouldScroll, setShouldScroll] = useState(false);

  const ref = useRef();
  useEffect(() => {
    _labelPositionNextRow(ref);
    _labelPositionPreviousRow(recipient);
    if (scrollableDivRef.current && shouldScroll) {
      scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
      setShouldScroll(false);
    }
  }, [recipient,shouldScroll]);

  const _addRecipientNameAndEmail = () => {
    const touchedMap = touched.map(() => ({ name: true, emailId: true }));
    setTouched(touchedMap);
    if (isInputsValueCheckEmpty()) {
      setRecipient([...recipient, { name: '', emailId: '' }]);
      _labelPositionNextRow(ref);
      setShouldScroll(true);
    }
  };

  const _handleRemoveRecipientFiled = (i) => {
    setEmailUniqueValidate('');
    let result = recipient.filter((element, index) => {
      return index !== i;
    });
    if (!result.length) {
      result = [{ name: '', emailId: '' }];
    }
    const updatedTouched = [...touched.slice(0, i), ...touched.slice(i + 1)];
    setTouched(updatedTouched);
    const updatedErrors = [...errors.slice(0, i), ...errors.slice(i + 1)];
    setErrors(updatedErrors);
    setRecipient(result);
    _labelPositionPreviousRow(result);
  };

  const _emailChangeHandler = (e, index) => {
    setEmailUniqueValidate('');
    const oneAlphabetRegex = /^(?=.*[A-Za-z])/;
    const { name, value } = e.target;
    let error = '';
    const newInputValue = [...recipient];
    newInputValue[index][name] = value;
    if (name === 'name' && !whiteSpaceRegex.test(value)) {
      error = EMAIL_VALIDATION_ERRORS.NAME_ERROR_VARIABLE_WHITESPACE;
    }
    else if (value.length < UI_VALIDATIONS.NAME_MIN_CHARACTER && name === 'name') {
      error = EMAIL_VALIDATION_ERRORS.NAME_ERROR_VARIABLE_MIN_CHAR;
    }
    if (value.length > UI_VALIDATIONS.NAME_MAX_CHARACTER && name === 'name') {
      error = EMAIL_VALIDATION_ERRORS.NAME_ERROR_VARIABLE_MAX_CHAR;
    }
    if (name === 'emailId' && !isEmailValid(value)) {
      error = EMAIL_VALIDATION_ERRORS.EMAIL_ERROR_RECEIPT_NOT_VALID;
    }

    if (!value) {
      error = name === 'name' ? EMAIL_VALIDATION_ERRORS.NAME_ERROR_VARIABLE_REQUIRED : EMAIL_VALIDATION_ERRORS.EMAIL_ERROR_VARIABLE_REQUIRED;
    }
    const updatedErrors = [...errors];
    updatedErrors[index] = { ...updatedErrors[index], [name]: error };
    setRecipient(newInputValue);
    setErrors(updatedErrors);
  };
  const indexOfAll = (arr, val) => arr.reduce((acc, el, i) => (el === val ? [...acc, i] : acc), []);

  function findDuplicates(arr) {
    const filtered = arr.filter((item, index) => arr.indexOf(item) !== index);
    return [...new Set(filtered)];
  }

  const getDuplicateOptions = (a) => {
    let tempArray = a.map((v) => v.toLowerCase());
    let b = findDuplicates(tempArray);
    let c = [];
    b.map((d) => {
      c.push([...indexOfAll(tempArray, d)]);
    });
    return c;
  };
  const checkDuplicateOptions = (a) => {
    let b = Array.from(new Set(a.map((e) => e)));
    return a.length !== b.length;
  };
  const _emailBlurHandler = (e, index, fieldName = '') => {
    const { name } = e.target;
    const value = e.target?.value?.toLowerCase();
    let error = '';
    const newInputValue = [...recipient];
    if (!['recipientName'].includes(fieldName)) {
      newInputValue[index][name] = value;
    }
    if (name === 'name' && !whiteSpaceRegex.test(value)) {
      error = EMAIL_VALIDATION_ERRORS.NAME_ERROR_VARIABLE_WHITESPACE;
    }
    else if (value.length < UI_VALIDATIONS.NAME_MIN_CHARACTER && name === 'name') {
      error = EMAIL_VALIDATION_ERRORS.NAME_ERROR_VARIABLE_MIN_CHAR;
    }
    if (value.length > UI_VALIDATIONS.NAME_MAX_CHARACTER && name === 'name') {
      error = EMAIL_VALIDATION_ERRORS.NAME_ERROR_VARIABLE_MAX_CHAR;
    }
    if (name === 'emailId' && !isEmailValid(value)) {
      error = EMAIL_VALIDATION_ERRORS.EMAIL_ERROR_RECEIPT_NOT_VALID;
    }
    if (!value) {
      error = name === 'name' ? EMAIL_VALIDATION_ERRORS.NAME_ERROR_VARIABLE_REQUIRED : EMAIL_VALIDATION_ERRORS.EMAIL_ERROR_VARIABLE_REQUIRED;
    }
    const updatedErrors = [...errors];
    updatedErrors[index] = { ...updatedErrors[index], [name]: error };
    setRecipient(newInputValue);
    setErrors(updatedErrors);
    const updatedTouched = [...touched];
    updatedTouched[index] = { ...updatedTouched[index], [name]: true };
    setTouched(updatedTouched);
    if (checkDuplicateOptions(recipient.map((emailField) => emailField.emailId))) {
      setEmailDuplicateArray(getDuplicateOptions(recipient.map((emailField) => emailField.emailId)));
      setEmailUniqueValidate(EMAIL_VALIDATION_ERRORS.EMAIL_ERROR_UNIQUE_ID);
    }
  };

  const isInputsValueCheckEmpty = () => {
    let isValid = true;
    let updateError = [];
    recipient.forEach((individualRecipient, index) => {
      updateError[index] = {};
      if (!whiteSpaceRegex.test(individualRecipient?.name)) {
        updateError[index].name = EMAIL_VALIDATION_ERRORS.NAME_ERROR_VARIABLE_WHITESPACE;
        isValid = false;
      }
      else if (individualRecipient?.name.length < UI_VALIDATIONS.NAME_MIN_CHARACTER) {
        updateError[index].name = EMAIL_VALIDATION_ERRORS.NAME_ERROR_VARIABLE_MIN_CHAR;
        isValid = false;
      }
      if (individualRecipient?.name.length > UI_VALIDATIONS.NAME_MAX_CHARACTER) {
        updateError[index].name = EMAIL_VALIDATION_ERRORS.NAME_ERROR_VARIABLE_MAX_CHAR;;
        isValid = false;
      }

      if (!isEmailValid(individualRecipient?.emailId)) {
        updateError[index].emailId = EMAIL_VALIDATION_ERRORS.EMAIL_ERROR_RECEIPT_NOT_VALID;
        isValid = false;
      }
      if (!individualRecipient?.name) {
        updateError[index].name = EMAIL_VALIDATION_ERRORS.NAME_ERROR_VARIABLE_REQUIRED;
        isValid = false;
      }
      if (!individualRecipient?.emailId) {
        updateError[index].emailId = EMAIL_VALIDATION_ERRORS.EMAIL_ERROR_VARIABLE_REQUIRED;
        isValid = false;
      }
      const updatedTouched = [...touched];
      updatedTouched[index] = { ...updatedTouched[index], name: true, emailId: true };
      setTouched(updatedTouched);
    });
    setErrors(updateError);
    return isValid;
  };

  const _onFocusHandle = (name) => {
    let error = '';
    if (recipient[0].name === '') {
      error = EMAIL_VALIDATION_ERRORS.NAME_ERROR_VARIABLE_REQUIRED;
    }
    else if (recipient[0].name !== recipient[0].name.trim()) {
      error = EMAIL_VALIDATION_ERRORS.NAME_ERROR_VARIABLE_WHITESPACE;
    }
    else if (recipient[0].name.length < UI_VALIDATIONS.NAME_MIN_CHARACTER) {
      error = EMAIL_VALIDATION_ERRORS.NAME_ERROR_VARIABLE_MIN_CHAR;
    }
    else if (recipient[0].length > UI_VALIDATIONS.NAME_MAX_CHARACTER) {
      error = EMAIL_VALIDATION_ERRORS.NAME_ERROR_VARIABLE_MAX_CHAR;;
    }
    const updatedErrors = [...errors];
    const updatedTouched = [...touched];
    updatedErrors[0] = { ...updatedErrors[0], [name]: error };
    updatedTouched[0] = { ...updatedTouched[0], name: true };
    setTouched(updatedTouched);
    setErrors(updatedErrors);
  };
  return (
    <>
      <div className="flex justify-between mt-4">
         <label className={cx('text-sm label-email fontPoppinsRegularMd relative left-4')}>
           <spna className="text-red-400  mr-1">*</spna>Recipient Name
         </label>
         <label  className={cx('text-sm label-email fontPoppinsRegularMd relative right-40')}>
           <spna className="text-red-400 mr-1">*</spna>Recipient Email
         </label>
      </div>
      <div ref={scrollableDivRef} className="h-28 overflow-y-scroll fixed">
        {recipient.map((element, index) => (
          <div className={cx('flex justify-around', ConfigMemoryStyle['addrow-maxheight'])} key={index} ref={ref}>
            <div className="ml-7 mt-4">
              <input
                type="text"
                className={cx(
                  'w-56',
                  ConfigMemoryStyle['email-config-input-placeholder'],
                  errors[index]?.name && touched[index]?.name
                    ? ConfigMemoryStyle['error-input-border']
                    : ConfigMemoryStyle['email-config-input-border']
                )}
                autoComplete="off"
                placeholder="Enter recipient name"
                value={element?.name || ''}
                name="name"
                onChange={(e) => _emailChangeHandler(e, index)}
                onBlur={(e) => _emailBlurHandler(e, index, 'recipientName')}
              />
              {errors[index]?.name && touched[index]?.name && (
                <p className={cx(ConfigMemoryStyle['error-msg-fontsize'])}>{errors[index]?.name}</p>
              )}
            </div>
            <div className={cx('ml-16 mt-4')}>
              <input
                type="text"
                className={cx(
                  'w-56',
                  ConfigMemoryStyle['email-config-input-placeholder'],
                  (errors[index]?.emailId && touched[index]?.emailId) ||
                    (emailUniqueValidate && emailDuplicateArray[0]?.includes(index))
                    ? ConfigMemoryStyle['error-input-border']
                    : ConfigMemoryStyle['email-config-input-border']
                )}
                autoComplete="off"
                placeholder="Enter recipient email address"
                value={element?.emailId || ''}
                name="emailId"
                onFocus={() => _onFocusHandle('name')}
                onChange={(e) => _emailChangeHandler(e, index)}
                onBlur={(e) => _emailBlurHandler(e, index)}
              />
              {errors[index]?.emailId && touched[index]?.emailId && (
                <p className={cx(ConfigMemoryStyle['error-msg-fontsize'])}>{errors[index]?.emailId}</p>
              )}
              {emailUniqueValidate && emailDuplicateArray[0]?.includes(index) && (
                <p className="errorMessage absolute fontPoppinsRegularSm">{emailUniqueValidate}</p>
              )}
            </div>
            <div className={cx('svg-id', ConfigMemoryStyle['delete-outline-icon-line-height'], 'mt-4')}>
              <DeleteOutlineIcon onClick={() => _handleRemoveRecipientFiled(index)} className="text-blue-600 ml-3" />
            </div>
          </div>
        ))}
      </div>
      <button
        className="mx-8 mt-32 text-blue-700 cursor-pointer fixed"
        onClick={() => {
          _addRecipientNameAndEmail();
        }}
        disabled={emailUniqueValidate}
      >
        <AddToPhotosIcon />
        &nbsp;<span className="text-blue-700 fontPoppinsRegularLg">Add Recipient</span>
      </button>
      <hr className="line fixed mt-44" />
      <div className={errorName && errorTouchedName ? 'flex justify-end mt-44 pb-2' : 'flex justify-end mt-44 pb-2'}>
        <button type="button" className="mt-3 mr-3 gray-btn ml-1 text-sm" onClick={handleCloseGroup}>
          Cancel
        </button>
        <button
          type="button"
          className="mt-3 ml-4 primary-btn mr-3 text-sm"
          onClick={() => isInputsValueCheckEmpty() && createGroup(recipient)}
        >
          {' '}
          Create
        </button>
      </div>
    </>
  );
}
export default CreateRecipientInputFiled;

import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Tooltip } from '@material-ui/core';
import DropdownTree from '@pages/common/dropdown_tree';
import FileUploadButton from '@pages/common/fileUpload_button';
import '@pages/test-development/script/scripts/webservice/restapi/css/common_Webservice.scss';
import '../css/binary.css';

export default function Binary(props) {
  const [showModal, setShowModal] = useState(false);
  const [selectedApkFile, setselectedApkFile] = useState('');
  const [choosenFile, setChoosenFile] = useState('');
  const [showBtn, setShowbtn] = useState(true);

  const handleOpen = () => {
    setShowModal(true);
  };

  const setSelectedFile = async () => {
    setShowModal(false);
    setChoosenFile(selectedApkFile?.name);
  };

  const changeHandler = (e) => {
    setselectedApkFile(e.target.files[0]);
    setShowbtn(true);
    props.handleSaveAsSteps(false);
  };

  return (
    <>
      <Modal open={showModal} className="modal-dialog">
        <div className="modal-container page-modal-size">
          <div className="modal-header">
            <h2 className="title"> Choose File</h2>

            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                setShowModal(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="modal-body" id="journal-scroll">
            <div>
              <div className="grid ">
                <div className=" col-span-2 sm:col-span-2 md:col-span-2">
                  <div className="ml-6">
                    <form>
                      <div className="mt-2">
                        <div>
                          <div className="mt-2 text-sm text-blue-700">
                            <DropdownTree
                              placeholder="Search and select file from test data"
                              labelValue="File From Test Data"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-2">
                        <div>
                          <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            File From Local Drive
                          </label>
                          <FileUploadButton
                            name="file"
                            id="file"
                            onChange={changeHandler}
                            value={selectedApkFile?.name}
                            showBtn={showBtn}
                            setShowBtn={setShowbtn}
                            className="max-w-lg block w-28 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 cursor-pointer"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="cancel-button-common cancel-button-layout-style-project mr-3"
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="create-button-style-common text-white create-button-layout-style-project"
              onClick={setSelectedFile}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
      <div className="pt-3 pb-3 border_style flex flex-wrap items-center gap-of-request-form-data-header common-background-color-request-body h-20">
        <span className="ml-5"></span>
      </div>

      <div className="background-body-type-none">
        <label className=" relative top-10 ml-5 block text-gray-700 sm:mt-px sm:pt-2 fontPoppinsRegularMd">
          Select File
        </label>
        <div className=" relative top-10 grid grid-cols-2 ml-5">
          <div onClick={handleOpen} className="select-file-primary-button fontPoppinsRegularMd mt-1">
            {choosenFile ? 'Replace file' : 'Choose file'}
          </div>
          {!choosenFile ? (
            <span className="input-filed-label noFileChosen relative left-8 fontPoppinsRegularXs">
              {'No file chosen'}
            </span>
          ) : (
            <Tooltip title={choosenFile} placement="bottom-end">
              <span className="input-filed-label fontPoppinsRegularLg noFileChosen relative top-10 -left-32 truncate w-48">
                {choosenFile}
              </span>
            </Tooltip>
          )}
          {choosenFile && choosenFile.length > 10 && (
            <span
              className="auto-cols-min col-start-12 relative text-blue-700 text-xl top-10 cursor-pointer cross-icon-binary"
              onClick={() => {
                setChoosenFile('');
              }}
            >
              x
            </span>
          )}
          {choosenFile && choosenFile.length < 10 && (
            <span
              className="auto-cols-min col-start-12 relative right-20 text-blue-700 text-xl top-10 cursor-pointer cross-icon-binary-less"
              onClick={() => {
                setChoosenFile('');
              }}
            >
              x
            </span>
          )}
        </div>
      </div>
    </>
  );
}

import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

function JiraIntegrationDetails(props) {
  const [openModal, setOpenModal] = useState(true);
  const handleClose = () => {
    props.closeModal(false);
    setOpenModal(false);
  };
  return (
    <div>
      <Modal
        open={openModal}
        onClose={() => {
          props.closeModal(false);
          setOpenModal(false);
        }}
        className="modal-dialog outline-none"
      >
        <div className="modal-container parameter-details-modal outline-none">
          <div className="modal-header">
            <div className="title-trim Popup-header-common" title={props.data.domain}>
              {`Account Details -${props.data.domain}`}
            </div>
            <IconButton color="primary" component="span" className="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="modal-body">
            <div className="grid grid-cols-2">
              <div className="">
                <div className="grid grid-rows-4 ml-4">
                  <div className="mt-1">
                    <h1 className="details-key-style-common">Server URL</h1>
                    <p className="pt-2 text-gray-700">
                      <div className="details-data-style-common name-trimmer" title={props.data.serverUrl}>
                        {props.data.serverUrl}
                      </div>
                    </p>
                  </div>
                  <div className="mt-4">
                    <h1 className="details-key-style-common">API Token</h1>
                    <p className="pt-2 text-gray-700">
                      <div className="details-data-style-common encode JIRA-name-trimmer ">{props.data.apiToken}</div>
                    </p>
                  </div>
                  <div className="mt-4">
                    <h1 className="details-key-style-common">Created By</h1>
                    <p className="pt-2 text-gray-700">
                      <div className="details-data-style-common name-trimmer" title={props.data.createdByUname}>
                        {props.data.createdByUname}
                      </div>
                    </p>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="grid grid-rows-4 ml-14">
                  <div className="mt-1">
                    <h1 className="details-key-style-common">Username</h1>
                    <p className="pt-2 text-gray-700 ">
                      <div className="details-data-style-common name-trimmer" title={props.data.userName}>
                        {props.data.userName}
                      </div>
                    </p>
                  </div>
                  <div className="mt-4">
                    <h1 className="details-key-style-common">Domain</h1>
                    <p className="text-gray-700 pt-2 ">
                      <div className="details-data-style-common name-trimmer" title={props.data.domain}>
                        {props.data.domain}
                      </div>
                    </p>
                  </div>
                  <div className="mt-4">
                    <h1 className="details-key-style-common">Created On</h1>
                    <p className="text-gray-700 pt-2 ">
                      <div className="details-data-style-common name-trimmer" title={props.data.createdOn}>
                        {props.data.createdOn}
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="">
              <button
                onClick={() => {
                  props.closeModal(false);
                  setOpenModal(false);
                }}
                type="button"
                className="gray-btn"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default JiraIntegrationDetails;

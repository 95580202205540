import React from 'react';

function PageHeader({ title, button }) {
  return (
    <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
      <label className="pl-3 fontPoppinsRegularLg flex-auto " style={{ color: '#1648c6' }}>
        {title}
      </label>
      <div className="pr-4">
        <div className="flex flex-row ml-32">{button}</div>
      </div>
    </div>
  );
}

export default PageHeader;

import React, { useMemo, useEffect, useState } from 'react';
import { Drawer, Box } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '@assets/analytics/ShrinkIconWhite.svg';
import { AZURE_DEVOPS_CONSTANTS } from '../plugin_constants';
import CommonDrawerJs from '../CommonIntegrationDrawer';

const AzureInstanceDetails = (props) => {
  const result = props?.projectDetails?.projects;
  const selectedInstance = props?.data;
  return (
    <CommonDrawerJs
      isDrawerOpen={true}
      headerContent={`${AZURE_DEVOPS_CONSTANTS.INSTANCE_DETAILS} - ${props?.data?.name}`}
      drawerWidth="549.95px"
      leftButtonText={AZURE_DEVOPS_CONSTANTS.CLOSE}
      isLeftButtonVisible={true}
      isRightButtonVisible={false}
      onDrawerOpen={props.onClose}
      onLeftButtonClick={props.onClose}
    >
      <div className="w-full h-full flex flex-col ">
        <div id="Journal-scroll" className="grid grid-cols-3 mt-4 pb-4 ml-6 overflow-y-auto max-h-72">
          <div className="overflow-hidden-common">
            <label
              htmlFor="userName"
              className="block label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {AZURE_DEVOPS_CONSTANTS.INSTANCE_NAME}
            </label>
            <label
              className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1"
              title={result?.name}
            >
              {selectedInstance?.name}
            </label>
          </div>
          <div className="overflow-hidden-common">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {AZURE_DEVOPS_CONSTANTS.PROJECT_NAME}
            </label>
            <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
              {selectedInstance?.projectName}
            </label>
          </div>
          <div className="overflow-hidden-common">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {AZURE_DEVOPS_CONSTANTS.SUITE_NAME}
            </label>
            <label className="instance-details-style overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
              {selectedInstance?.suiteName}
            </label>
          </div>
          <div className="mt-6">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {AZURE_DEVOPS_CONSTANTS.CREATED_BY}
            </label>
            <label className="details-data-style-common fontPoppinsRegularSm">{selectedInstance?.createdByUname}</label>
          </div>
          <div className="mt-6 ">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {AZURE_DEVOPS_CONSTANTS.CREATED_ON}
            </label>
            <label className="details-data-style-common fontPoppinsRegularSm">{selectedInstance?.createdOn}</label>
          </div>
          <div className="mt-6">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {AZURE_DEVOPS_CONSTANTS.MODIFIED_BY}
            </label>
            <label className="instance-details-style fontPoppinsRegularSm">{selectedInstance?.modifiedByUname}</label>
          </div>
          <div className="mt-6">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {AZURE_DEVOPS_CONSTANTS.MODIFIED_ON}
            </label>
            <label className="instance-details-style fontPoppinsRegularSm">{selectedInstance?.modifiedOn}</label>
          </div>
        </div>
      </div>
    </CommonDrawerJs>
  );
};

export default AzureInstanceDetails;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';
import { useAlert } from '../../common/alert_service/useAlert';
import '../../common/dropdown.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { ExclamationIcon } from '@heroicons/react/outline';
import { getScreenVideoReq, putScreenVideoReq, deleteScreenShotReq, deleteVideoReq } from '@api/api_services';
import { colors } from '@src/css_config/colorConstants';

const Screen_video_config = (props) => {
  const [open, setOpen] = React.useState(false);
  const [openVideo, setOpenVideo] = React.useState(false);
  const [screen, setScreen] = React.useState(true);

  const [screenDays, setScreenDays] = React.useState();
  const [videoDays, setVideoDays] = React.useState();
  const [captureScreenShot, setCaptureScreenShot] = React.useState();
  const [captureVideo, setCaptureVideo] = React.useState();
  const [userId, setId] = React.useState('');
  const [disablebtn, setDisableBtn] = useState(true);
  const [disableCancelbtn, setDisableCancelBtn] = useState(true);
  const [getDltData, setGetDltData] = useState();
  const [screenShortErrorMsg, setScreenShortErrorMsg] = useState('');
  const [videoErrorMsg, setVideoErrorMsg] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenVideo = () => setOpenVideo(true);
  const handleCloseVideo = () => setOpenVideo(false);

  const { AlertContatiner, MyAlert } = useAlert();
  let activeTab = props.location.pathname.split('/')[3];

  const [tabsList, setTabsList] = useState([
    {
      name: 'Screenshot & Video Configuration',
      pathUrl: `/configuration/RunConfiguration/Screenshot&Videoconfiguration`,
      selectedName: 'Screenshot&Videoconfiguration',
    },

    // {
    //   name: "History",
    //   pathUrl: `/configuration/RunConfiguration/Screenshot&Videoconfiguration/History`,
    //   selectedName: "history",
    // },
  ]);

  const renderTabs = () => {
    return (
      <div>
        <ul className="navBar text-blue-600 flex flex-row primary-tab">
          {tabsList.map((tab, index) => {
            return (
              <li key={tab.name}>
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    props.history.push(tab.pathUrl);
                  }}
                  className={`${activeTab === tab.selectedName ? 'selected_tab_style' : ''}`}
                >
                  {tab.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  let projectId = JSON.parse(localStorage.getItem('selected-project')).id;
  React.useEffect(() => {
    const search = window.location.search;
    let projectId = new URLSearchParams(search).get('id') || JSON.parse(localStorage.getItem('selected-project'));

    if (new URLSearchParams(search).get('id')) {
      projectId = new URLSearchParams(search).get('id');
    } else {
      projectId = JSON.parse(localStorage.getItem('selected-project')).id;
    }

    if (projectId) {
      getScreenVideoReq(projectId).then((results) => {
        localStorage.setItem('configReq', false);
        if (results && results.data && results.data.responseObject) {
          setScreenDays(results.data.responseObject.noOfDaysForScreenShot);
          setVideoDays(results.data.responseObject.noOfDaysForVideo);
          setCaptureScreenShot(results.data.responseObject.captureScreenShotFor);
          setCaptureVideo(results.data.responseObject.captureVideosFor);
          setId(results.data.responseObject.id);
        }
      });
    } else {
      localStorage.setItem('configReq', true);
      getScreenVideoReq(projectId).then((results) => {
        localStorage.setItem('configReq', false);
        if (results && results.data && results.data.responseObject) {
          setScreenDays(results.data.responseObject.noOfDaysForScreenShot);
          setVideoDays(results.data.responseObject.noOfDaysForVideo);
          setCaptureScreenShot(results.data.responseObject.captureScreenShotFor);
          setCaptureVideo(results.data.responseObject.captureVideosFor);
          setId(results.data.responseObject.id);
        }
      });
    }
  }, [projectId, getDltData]);

  const incScreen = () => {
    setScreenDays(parseInt(screenDays) + 1);
    // if(screenDays>=365){
    //   setScreenDays(365)

    // }
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let screenshot = document.querySelector('.screenshot').value;
    if (videoErrorMsg?.includes('No of days should be between 1 to 365')) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
    }

    if (screenshot >= 365) {
      setScreenShortErrorMsg('No of days should be between 1 to 365');
      setDisableBtn(true);
    } else if (screenshot === '') {
      setScreenDays(1);
      setScreenShortErrorMsg('');
    } else {
      setScreenShortErrorMsg('');
    }
  };
  const decrScreen = () => {
    setScreenDays(screenDays - 1);
    // if(screenDays<=1){
    //   setScreenDays(1)

    // }
    setDisableBtn(false);
    setDisableCancelBtn(false);
    if (videoErrorMsg?.includes('No of days should be between 1 to 365')) {
      setDisableBtn(true);
    }

    let screenshot = document.querySelector('.screenshot').value;
    if (screenshot <= 1) {
      setScreenShortErrorMsg('No of days should be between 1 to 365');
      setScreenDays(1);
      setDisableBtn(true);
    } else if (screenshot >= 367) {
      setScreenShortErrorMsg('No of days should be between 1 to 365');
      setDisableBtn(true);
    } else {
      setScreenShortErrorMsg('');
    }
  };
  const onChangeScreen = (e) => {
    setScreenDays(e.target.value);
    let screenshot = document.querySelector('.screenshot').value;
    var regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;
    const regExp_alpha = /\D/g;
    const value = e.target.value.replace(/\D/g, '');
    setScreenDays(value);

    if (screenshot < 1) {
      setScreenShortErrorMsg('No of days should be between 1 to 365');
      setDisableBtn(true);
    } else if (screenshot > 365) {
      setScreenShortErrorMsg('No of days should be between 1 to 365');
      setDisableBtn(true);
    } else if (regex_symbols.test(screenshot)) {
      setDisableBtn(true);
    } else if (regExp_alpha.test(screenshot)) {
      setDisableBtn(true);
    } else {
      setScreenShortErrorMsg('');
      setDisableBtn(false);
      setDisableCancelBtn(false);
    }
  };
  // functions for videos

  const incVideo = () => {
    setVideoDays(parseInt(videoDays) + 1);
    // if(videoDays>=365){
    //   setVideoDays(365)

    // }
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let exexution = document.querySelector('.exexution').value;
    if (screenShortErrorMsg?.includes('No of days should be between 1 to 365')) {
      setDisableBtn(true);
    }
    if (exexution >= 365) {
      setVideoErrorMsg('No of days should be between 1 to 365');
      setVideoDays(365);
      setDisableBtn(true);
      setDisableCancelBtn(true);
    } else if (exexution === '') {
      setVideoDays(1);
      setVideoErrorMsg('');
    } else {
      setVideoErrorMsg('');
    }
  };
  const decrVideo = () => {
    setVideoDays(videoDays - 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let exexution = document.querySelector('.exexution').value;
    if (screenShortErrorMsg?.includes('No of days should be between 1 to 365')) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
    }
    if (exexution <= 1) {
      setVideoErrorMsg('No of days should be between 1 to 365');
      setVideoDays(1);
      setDisableBtn(true);
      setDisableCancelBtn(true);
    } else if (exexution >= 367) {
      setVideoDays(365);
      setVideoErrorMsg('No of days should be between 1 to 365');
      setDisableBtn(true);
      setDisableCancelBtn(true);
    } else {
      setVideoErrorMsg('');
    }
  };
  const onChangeVideo = (e) => {
    setVideoDays(e.target.value);
    var regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;
    const regExp_alpha = /\D/g;
    const value = e.target.value.replace(/\D/g, '');
    setVideoDays(value);

    let exexution = document.querySelector('.exexution').value;
    if (exexution === '') {
      setDisableBtn(true);
      setDisableCancelBtn(true);
    }
    if (exexution < 1) {
      setVideoErrorMsg('No of days should be between 1 to 365');
      setDisableBtn(true);
      setDisableCancelBtn(true);
    } else if (exexution > 365) {
      setVideoErrorMsg('No of days should be between 1 to 365');
      setDisableBtn(true);
      setDisableCancelBtn(true);
    } else if (regex_symbols.test(exexution)) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
    } else if (regExp_alpha.test(exexution)) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
    } else {
      setVideoErrorMsg('');
      setDisableBtn(false);
      setDisableCancelBtn(false);
    }
  };
  const updateVal = (e) => {
    setDisableBtn(true);
    localStorage.setItem('resultGetReq', true);
    let data = {
      captureScreenShotFor: captureScreenShot,
      noOfDaysForScreenShot: screenDays,
      captureVideosFor: captureVideo,
      id: userId,
      noOfDaysForVideo: videoDays,
    };
    putScreenVideoReq(userId, data)
      .then((results) => {
        localStorage.setItem('resultGetReq', false);
        if (results.data.responseCode === 200) {
          MyAlert.success(`Screenshot & video configuration updated successfully`);
        }
        console.error(results);
      })
      .catch((error) => {
        console.log(error);
      });
    let projectId = JSON.parse(localStorage.getItem('selected-project')).id;
    if (projectId) {
      putScreenVideoReq(userId, data, projectId)
        .then((results) => {
          localStorage.setItem('configPutReq', false);
          if (results.data.responseCode === 200) {
            MyAlert.success(`Screenshot & video configuration updated successfully`);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const cancelValue = () => {
    setScreenShortErrorMsg('');
    setVideoErrorMsg('');
    setDisableBtn(true);
    let projectId = JSON.parse(localStorage.getItem('selected-project')).id;
    if (projectId) {
      getScreenVideoReq(projectId).then((results) => {
        localStorage.setItem('configReq', false);
        if (results && results.data && results.data.responseObject) {
          setScreenDays(results.data.responseObject.noOfDaysForScreenShot);
          setVideoDays(results.data.responseObject.noOfDaysForVideo);
          setCaptureScreenShot(results.data.responseObject.captureScreenShotFor);
          setCaptureVideo(results.data.responseObject.captureVideosFor);
          setId(results.data.responseObject.id);
        }
      });
    } else {
      localStorage.setItem('configReq', true);
      getScreenVideoReq(projectId).then((results) => {
        localStorage.setItem('configReq', false);
        if (results && results.data && results.data.responseObject) {
          setScreenDays(results.data.responseObject.noOfDaysForScreenShot);
          setVideoDays(results.data.responseObject.noOfDaysForVideo);
          setCaptureScreenShot(results.data.responseObject.captureScreenShotFor);
          setCaptureVideo(results.data.responseObject.captureVideosFor);
          setId(results.data.responseObject.id);
        }
      });
    }
  };
  // delete
  const deleteData = () => {
    const licenseId = JSON.parse(localStorage.getItem('test-optimize-user')).licenseId;
    localStorage.setItem('resultPutReq', true);
    if (projectId) {
      deleteScreenShotReq(projectId, null)
        .then((res) => {
          localStorage.setItem('resultPutReq', false);
          setGetDltData(res.data);
          setScreenShortErrorMsg('');
          if (res.data.responseCode === 200) {
            MyAlert.success(`Screenshots deleted successfully`);
          }
          if (res.data.message === 'FAILURE') {
            MyAlert.info(`There are no screenshots to be deleted`);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      localStorage.setItem('resultPutReq', true);
      deleteScreenShotReq(null, licenseId)
        .then((res) => {
          localStorage.setItem('resultPutReq', false);
          setGetDltData(res.data);
          setScreenShortErrorMsg('');
          if (res.data.responseCode === 200) {
            MyAlert.success(`Screenshots deleted successfully`);
          }
          if (res.data.message === 'FAILURE') {
            MyAlert.info(`There are no screenshots to be deleted`);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    handleClose();
  };

  const deleteVideoData = () => {
    const licenseId = JSON.parse(localStorage.getItem('test-optimize-user')).licenseId;
    localStorage.setItem('resultPutReq', true);
    if (projectId) {
      deleteVideoReq(projectId, null)
        .then((res) => {
          localStorage.setItem('resultPutReq', false);
          setGetDltData(res.data);
          if (res.data.responseCode === 200) {
            MyAlert.success(`Videos deleted successfully`);
          }
          if (res.data.message === 'FAILURE') {
            MyAlert.info(`There are no Videos to be deleted`);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      localStorage.setItem('resultPutReq', true);
      deleteVideoReq(null, licenseId)
        .then((res) => {
          localStorage.setItem('resultPutReq', false);
          setGetDltData(res.data);
          if (res.data.responseCode === 200) {
            MyAlert.success(`Videos deleted successfully`);
          }
          if (res.data.message === 'FAILURE') {
            MyAlert.info(`There are no Videos to be deleted`);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    handleCloseVideo();
  };
  const { isEditable, hasFullAccess } = React.useMemo(
    () => ({
      isEditable: window.permission?.isEditAccess('configuration'),
      hasFullAccess: window.permission?.isFullAccess('configuration'),
    }),
    []
  );
  const handleNumberValueChange = React.useCallback(
    (changeHandlerFunction) => (isEditable ? changeHandlerFunction : () => {}),
    [isEditable]
  );

  return (
    <div className="page-container">
      <div className="page-list-body">
        {renderTabs()}
        <div className="alert-position-style">
          <AlertContatiner></AlertContatiner>
        </div>
        <div className="page-table-body">
          <div className="configPage pr-0 content_area_style" id="journal-scroll">
            <div className="grid grid-cols-2 mb-4 ">
              <span style={{ width: '44.3rem' }} className=" grid grid-cols-1  mt-4 project_label configPageHeading">
                Screenshot & Video Configuration
              </span>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginRight: '15px',
                }}
              >
                {/* <Tooltip title="Search" placement='top'>
                        <SearchOutlined className="text-gray-500 -mr-4 mt-5 cursor-pointer" />
                      </Tooltip> */}
                {isEditable && (
                  <>
                    <button
                      disabled={disableCancelbtn}
                      onClick={cancelValue}
                      className={disableCancelbtn ? 'gray-btn mt-4' : 'gray-btn mt-4'}
                    >
                      Cancel
                    </button>
                    <button
                      disabled={disablebtn || screenShortErrorMsg || videoErrorMsg}
                      onClick={updateVal}
                      className={disablebtn ? 'primary-btn mt-4 ml-7' : 'primary-btn mt-4 ml-7'}
                    >
                      Save
                    </button>
                  </>
                )}
              </div>
            </div>
            <hr></hr>

            <div className="screenShotConfig grid grid-cols-3 " style={{ height: '160px' }}>
              <div className="screenSection">
                <span className="screenShot mt-4 mx-4">
                  <h6 className=" mt-4 fontPoppinsSemiboldLg" style={{ marginLeft: '34px' }}>
                    Screenshot Configuration
                  </h6>
                </span>

                <span>
                  <h6
                    className="ml-5 fontPoppinsRegularMd"
                    style={{
                      color: 'grey',
                      marginLeft: '45px',
                    }}
                  >
                    Capture Screenshot In Result
                  </h6>
                </span>

                <div className="radioGroup" style={{ marginLeft: '45px', width: '480px', display: 'flex' }}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="steps"
                      name="row-radio-buttons-group"
                      style={{ fontSize: '10px' }}
                      value={captureScreenShot || ''}
                      className="grid grid-cols-3"
                    >
                      <FormControlLabel
                        value="failedSteps"
                        className="fontPoppinsSemiboldMd"
                        onChange={(e) => {
                          setCaptureScreenShot(e.target.value);
                          setDisableCancelBtn(false);
                          setDisableBtn(false);
                        }}
                        control={<Radio />}
                        label={<span className="fontPoppinsRegularMd">For Failed Steps Only</span>}
                        disabled={!isEditable}
                      />
                      <FormControlLabel
                        value="allSteps"
                        className="fontPoppinsSemiboldMd"
                        onChange={(e) => {
                          setCaptureScreenShot(e.target.value);
                          setDisableCancelBtn(false);
                          setDisableBtn(false);
                        }}
                        control={<Radio />}
                        label={<span className="fontPoppinsRegularMd">For All Steps</span>}
                        disabled={!isEditable}
                      />
                      <FormControlLabel
                        value="notCapture"
                        className="fontPoppinsSemiboldMd ml-2.5"
                        onChange={(e) => {
                          setCaptureScreenShot(e.target.value);
                          setDisableCancelBtn(false);
                          setDisableBtn(false);
                        }}
                        control={<Radio />}
                        label={<span className="fontPoppinsRegularMd">Don't Capture</span>}
                        disabled={!isEditable}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>

              <div className="selectSection mt-20 pl-16">
                <h6 className="fontPoppinsRegularMd text-gray-500 ml-14 text-sm">
                  Delete Screenshots From Server After
                </h6>

                <form
                  className="ml-8"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div
                    style={{
                      marginLeft: '2rem',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'stretch',
                    }}
                  >
                    <input
                      disabled={!isEditable}
                      value={screenDays}
                      onChange={onChangeScreen}
                      type="text"
                      className="screenshot fontPoppinsRegularMd"
                      style={{
                        background: colors.text_white,
                        width: '8.6rem',
                        marginTop: '3.9px',
                        height: '32px',
                        textAlign: 'right',
                        borderBottom: `2px solid ${colors.rs_border}`,
                      }}
                    ></input>
                    <span className="flex flex-col -mt-1">
                      <span className=" justify-center items-center">
                        <ArrowDropUpIcon onClick={handleNumberValueChange(incScreen)} />
                      </span>
                      <span className=" justify-center items-center -mt-1">
                        <ArrowDropDownIcon onClick={handleNumberValueChange(decrScreen)} />
                      </span>
                    </span>
                    <label className="fontPoppinsRegularMd mx-1" style={{ color: 'grey', marginTop: '0.6rem' }}>
                      days
                    </label>
                  </div>
                </form>
                {screenShortErrorMsg && <p className="errorMessage ml-12">{screenShortErrorMsg}</p>}
              </div>
              {hasFullAccess && (
                <div className="btn" style={{ marginTop: '5rem', paddingLeft: '5rem' }}>
                  <Button
                    variant="outlined"
                    onClick={handleOpen}
                    style={{
                      width: '15rem',
                      border: '2px solid gray',
                      textTransform: 'capitalize',
                      color: 'gray',
                    }}
                    className="fontPoppinsRegularMd opacity-50"
                    disabled={true}
                  >
                    Delete all Screenshots
                  </Button>

                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box>
                      <div
                        style={{
                          width: '40rem',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                        className=" marginupper pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/4"
                      >
                        <div className="inline-block">
                          <div className="flex flex-start ml-2">
                            <span className="pt-5 pl-3">
                              <ExclamationIcon className="h-6 w-6 text-yellow-600 " />
                            </span>

                            <span className="font-bold fontPoppinsSemiboldLg mt-4 mx-3 block">Warning </span>
                            <span
                              style={{
                                marginLeft: '-80px',
                                marginTop: '35px',
                              }}
                              className="text-sm fontPoppinsRegularLg"
                            >
                              Delete{' '}
                            </span>
                          </div>
                        </div>

                        <hr className="line mt-px" />
                        <div className="fontPoppinsRegularMd mt-3 text-gray-500 ml-16 mr-8">
                          <p className="" style={{ color: 'black' }}>
                            Are you sure you want to delete all Screenshots? Once deleted Cannot be recovered.
                          </p>
                          <p className="mt-2" style={{ color: 'black' }}>
                            How do You want to proceed?
                          </p>
                        </div>

                        <hr className="line mt-3" />
                        <div className="mt-2 pb-2 flex flex-row float-right">
                          <button
                            type="button"
                            className="mt-3 gray-btn ml-1"
                            style={{
                              fontSize: '14px',
                              fontFamily: 'Poppins-SemiBold',
                              marginRight: '12px',
                            }}
                            onClick={deleteData}
                          >
                            Delete
                          </button>
                          <button
                            type="button"
                            onClick={handleClose}
                            className="mt-3 ml-4 primary-btn "
                            style={{
                              fontSize: '14px',
                              fontFamily: 'Poppins-SemiBold',
                              marginRight: '12px',
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Box>
                  </Modal>

                  <h6 className="fontPoppinsRegularMd" style={{ color: 'grey', marginTop: '7px' }}>
                    (Screenshot Memory Consumed-2GB)
                  </h6>
                </div>
              )}
            </div>
            <hr style={{ borderStyle: 'dashed', marginTop: '5px' }}></hr>

            <div className="screenShotConfig grid grid-cols-3 ">
              <div className="screenSection">
                <span className="screenShot mt-4 mx-4">
                  <h6 className=" mt-4 fontPoppinsSemiboldLg" style={{ marginLeft: '34px' }}>
                    Video Configuration
                  </h6>
                </span>

                <span>
                  <h6
                    className="ml-5 fontPoppinsRegularMd"
                    style={{
                      color: 'grey',
                      marginLeft: '45px',
                      fontSize: '14px',
                    }}
                  >
                    Capture Video In Result
                  </h6>
                </span>

                <div className="radioGroup  " style={{ marginLeft: '45px', width: '480px', display: 'flex' }}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="steps"
                      name="row-radio-buttons-group"
                      style={{ fontSize: '10px' }}
                      value={captureVideo || ''}
                    >
                      <FormControlLabel
                        value="failedSteps"
                        className="fontPoppinsSemiboldMd -ml-3.5"
                        onChange={(e) => {
                          setCaptureVideo(e.target.value);
                          setDisableCancelBtn(false);
                          setDisableBtn(false);
                        }}
                        control={<Radio />}
                        label={<span className="fontPoppinsRegularMd">For Failed Scripts</span>}
                        disabled={!isEditable}
                      />
                      <FormControlLabel
                        value="allSteps"
                        className="fontPoppinsSemiboldMd -ml-2.5"
                        onChange={(e) => {
                          setCaptureVideo(e.target.value);
                          setDisableCancelBtn(false);
                          setDisableBtn(false);
                        }}
                        control={<Radio />}
                        label={<span className="fontPoppinsRegularMd">For All Scripts</span>}
                        disabled={!isEditable}
                      />
                      <FormControlLabel
                        value="notCapture"
                        className="fontPoppinsSemiboldMd "
                        onChange={(e) => {
                          setCaptureVideo(e.target.value);
                          setDisableCancelBtn(false);
                          setDisableBtn(false);
                        }}
                        control={<Radio />}
                        label={<span className="fontPoppinsRegularMd">Don't Capture</span>}
                        disabled={!isEditable}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>

              <div className="selectSection mt-20 pl-16">
                <h6 className="fontPoppinsRegularMd text-gray-500 ml-14 text-sm">
                  Delete Execution Videos From Server After
                </h6>

                <form
                  className="ml-8"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div
                    style={{
                      marginLeft: '2rem',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'stretch',
                    }}
                  >
                    <input
                      disabled={!isEditable}
                      value={videoDays}
                      onChange={onChangeVideo}
                      type="text"
                      className="exexution fontPoppinsRegularMd"
                      style={{
                        background: colors.text_white,
                        width: '8.6rem',
                        marginTop: '3.9px',
                        height: '32px',
                        textAlign: 'right',
                        borderBottom: `2px solid ${colors.rs_border}`,
                      }}
                    ></input>
                    <ArrowDropUpIcon
                      style={{
                        color: colors.black_border,
                        marginLeft: '4px',
                        marginTop: '0.4rem',
                      }}
                      onClick={handleNumberValueChange(incVideo)}
                    />
                    <ArrowDropDownIcon
                      style={{
                        color: colors.black_border,
                        marginLeft: '-24px',
                        marginTop: '1.3rem',
                      }}
                      onClick={handleNumberValueChange(decrVideo)}
                    />
                    <label className="mx-1 fontPoppinsRegularMd" style={{ color: 'grey', marginTop: '0.6rem' }}>
                      days
                    </label>
                  </div>
                </form>
                {videoErrorMsg && <p className="errorMessage ml-12">{videoErrorMsg}</p>}
              </div>
              {hasFullAccess && (
                <div className="btn" style={{ marginTop: '5rem', paddingLeft: '5rem' }}>
                  <Button
                    disabled={true}
                    variant="outlined"
                    onClick={handleOpenVideo}
                    className="fontPoppinsRegularMd opacity-50"
                    style={{
                      width: '15rem',
                      border: '2px solid gray',
                      textTransform: 'capitalize',
                      color: 'gray',
                    }}
                  >
                    Delete all Execution Videos
                  </Button>

                  <Modal
                    open={openVideo}
                    onClose={handleCloseVideo}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box>
                      <div
                        style={{
                          width: '40rem',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                        className=" marginupper pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/4"
                      >
                        <div className="inline-block">
                          <div className="flex flex-start ml-2">
                            <span className="pt-5 pl-3">
                              <ExclamationIcon className="h-6 w-6 text-yellow-600 " />
                            </span>

                            <span className="fontPoppinsSemiboldLg mt-4 mx-3 text-sm block">Warning </span>
                            <span
                              style={{
                                marginLeft: '-80px',
                                marginTop: '35px',
                              }}
                              className="fontPoppinsRegularLg"
                            >
                              Delete{' '}
                            </span>
                          </div>
                        </div>

                        <hr className="line mt-px" />
                        <div className="fontPoppinsRegularMd mt-3 text-gray-500 ml-16 mr-8">
                          <p className="" style={{ color: 'black' }}>
                            Are you sure you want to delete all videos? Once deleted Cannot be recovered.
                          </p>
                          <p className="mt-2" style={{ color: 'black' }}>
                            How do you want to proceed?
                          </p>
                        </div>

                        <hr className="line mt-3" />
                        <div className="mt-2 pb-2 flex flex-row float-right">
                          <button
                            type="button"
                            className="mt-3 gray-btn ml-1"
                            style={{
                              fontSize: '14px',
                              fontFamily: 'Poppins-SemiBold',
                              marginRight: '12px',
                            }}
                            onClick={deleteVideoData}
                          >
                            Delete
                          </button>
                          <button
                            type="button"
                            className="mt-3 ml-4 primary-btn "
                            onClick={handleCloseVideo}
                            style={{
                              fontSize: '14px',
                              fontFamily: 'Poppins-SemiBold',
                              marginRight: '12px',
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Box>
                  </Modal>

                  <div className="fontPoppinsRegularMd" style={{ color: 'grey', marginTop: '7px' }}>
                    (Execution Videos Memory Consumed-5GB)
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Screen_video_config);

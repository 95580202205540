import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';
import { useAlert } from '../../common/alert_service/useAlert';
import * as Yup from 'yup';
import { SearchIcon } from '@heroicons/react/solid';
import '../../common/dropdown.css';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import HomeIcon from '@material-ui/icons/Home';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import '../ElementConfig/elementConfig.css';
import { MdEdit } from 'react-icons/md';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import '../View&Custom/ViewConfig.css';
import CloseIcon from '@material-ui/icons/Close';
import MyInput from '../../common/Inputs/MyInput';
import { useFormik } from 'formik';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
    color: 'rgb(22,72,198)',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height: 600,
  bgcolor: 'background.paper',

  boxShadow: 12,
};

const ViewHistory = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showOptions, setShowOptions] = useState(false);

  const [openUserDetails, setOpenUserDetails] = React.useState(false);
  const handleOpenUser = () => setOpenUserDetails(true);
  const handleCloseUser = () => setOpenUserDetails(false);

  const handelDropdown = () => {
    setShowOptions(!showOptions);
  };
  // if click outside of dropdown also it will close
  let menuRef = useRef();
  useEffect(() => {
    let handeler = (event) => {
      if (menuRef.current && !menuRef?.current?.contains(event.target)) {
        setShowOptions(false);
      }
    };
    document.addEventListener('mousedown', handeler);
    return () => {
      document.removeEventListener('mousedown', handeler);
    };
  }, []);

  const classes = useStyles();
  return (
    <div className="page-container">
      <div className={classes.root} style={{ marginTop: '-2px', display: 'flex' }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" href="/" onClick={handleClick}>
            <HomeIcon className={classes.icon} />
          </Link>
          <Link className="breadcrumb" href="/getting-started/installation/" onClick={handleClick}>
            Configuration
          </Link>
          <Link className="breadcrumb" href="/getting-started/installation/" onClick={handleClick}>
            View & Custom field Configuration
          </Link>
          <Link className="breadcrumb" href="/getting-started/installation/" onClick={handleClick}>
            History
          </Link>
        </Breadcrumbs>
      </div>

      <div class="h-screen flex ">
        <div ref={menuRef} class="relative inline-block text-left">
          <div>
            <span class=" text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center">
              <span class="mr-1  headDataHistory" onClick={handelDropdown}>
                Create View
              </span>

              <ArrowDropDownIcon onClick={handelDropdown} class="fill-current h-7 w-11 mt-1 -ml-6 p-1" />

              <Link
                to="/configuration/view&CustomfieldConfiguration/createView/projectElement/history"
                className="historyCon"
              >
                History
              </Link>
            </span>
          </div>

          {showOptions && (
            <div
              style={{ width: '10rem', marginTop: '-5px' }}
              class="origin-top-left absolute right-70 mt-2 w-75 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabindex="-1"
            >
              <div class="py-1" role="none">
                <span
                  class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-0"
                >
                  Project Elements
                </span>
                <span
                  class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-1"
                >
                  Program Elements
                </span>
                <span
                  class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-2"
                >
                  Step Group
                </span>
                <span
                  class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-2"
                >
                  Test Data
                </span>
                <span
                  class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-2"
                >
                  Test Development
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="page-list-body">
        <div className="page-table-body">
          <div className="configPage pr-0 content_area_style" id="journal-scroll">
            <div className="grid grid-cols-2 mb-4 ">
              <span className=" ml-3 mt-4 text-sm flex-auto  configPageHeading">History</span>
              <div className="icon">
                <SearchIcon className="search mt-4 w-15 h-5   items-center float-left" />
              </div>
            </div>

            <hr></hr>

            <div className=" grid cols-grid-6">
              <span className="text-sm text-gray-700 hover:bg-gray-100 h-10 p-3">
                Manjunath has created a view Default view on 28 jan 2021 13:20
              </span>
              <span className="text-sm text-gray-700 hover:bg-gray-100 h-10 p-3">
                Manjunath has created a view Default view on 28 jan 2021 13:20
              </span>
              <span className="text-sm text-gray-700 hover:bg-gray-100 h-10 p-3">
                Manjunath has created a view Default view on 28 jan 2021 13:20
              </span>
            </div>

            <div className="pagecountborder-set mb-3" id="footer-notfixed">
              <div className="float-right pr-4">
                <span className="pagecount">1-5 of 5</span>
                <span className="pageborderleft ml-2 pl-2">
                  <button className="pageArrow">{'<'}</button>{' '}
                  <span className="currentpagecount ml-2 mr-2">
                    {' '}
                    <span className="pl-2 pr-2">1</span>
                  </span>
                  <button className="pageArrow">{'>'}</button>{' '}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default withRouter(ViewHistory);

import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';

import { SearchIcon } from '@heroicons/react/solid';
import '../../common/dropdown.css';
import { makeStyles } from '@material-ui/core/styles';
import '../ElementConfig/elementConfig.css';
import { useAlert } from '../../common/alert_service/useAlert';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
    color: 'rgb(22,72,198)',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const VariablesHistory = (props) => {
  const classes = useStyles();

  let activeTab = props.location.pathname.split('/')[3];

  const [tabsList, setTabsList] = useState([
    {
      name: 'Project Environment Variables',
      pathUrl: `/configuration/Configurationvariables/Projectenvironmentvariable`,
      selectedName: 'ProjectEnvironmentVariable',
    },
    {
      name: 'Global Variables',
      pathUrl: `/configuration/variables/Globalvariables`,
      selectedName: 'Globalvariables',
    },

    // {
    //     "name": "History",
    //     "pathUrl": `/configuration/variables/history`,
    //     "selectedName": "history"
    // }
  ]);

  const renderTabs = () => {
    return (
      <div>
        <ul className="navBar  flex flex-row primary-tab">
          {tabsList.map((tab, index) => {
            return (
              <li key={tab.name}>
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    props.history.push(tab.pathUrl);
                  }}
                  className={`${activeTab === tab.selectedName ? 'selected_tab_style' : ''}`}
                >
                  {tab.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <div className="page-container">
      <div className={classes.root} style={{ display: 'flex' }}></div>

      <div className=" flex ">{renderTabs()}</div>

      <div className="page-list-body">
        <div className="page-table-body">
          <div className="content_area_style" id="journal-scroll">
            <div className="grid grid-cols-2 mb-4 ">
              <span className=" ml-3 mt-4 text-sm flex-auto  configPageHeading">History</span>
              <div className="icon">
                <SearchIcon className="search mt-4 w-15 h-5   items-center float-left" />
              </div>
            </div>

            <hr></hr>

            <div className=" grid cols-grid-6"></div>

            <div className="pagecountborder-set mb-3" id="footer-notfixed">
              <div className="float-right pr-4">
                <span className="pagecount">1-5 of 5</span>
                <span className="pageborderleft ml-2 pl-2">
                  <button className="pageArrow">{'<'}</button>{' '}
                  <span className="currentpagecount ml-2 mr-2">
                    {' '}
                    <span className="pl-2 pr-2">1</span>
                  </span>
                  <button className="pageArrow">{'>'}</button>{' '}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default withRouter(VariablesHistory);

import { useEffect } from 'react';

const useEscapeKeyCloseModal = (isOpen, closeModalFunction) => {
  useEffect(() => {
    const handleEscapeKeyCloseModal = (event) => {
      if (event.key === 'Escape' && isOpen) {
        closeModalFunction();
      }
    };

    document.addEventListener('keydown', handleEscapeKeyCloseModal);

    return () => {
      document.removeEventListener('keydown', handleEscapeKeyCloseModal);
    };
  }, [isOpen, closeModalFunction]);
};

export default useEscapeKeyCloseModal;

import React, { useEffect, useRef, useState } from 'react';
import DashboardStyle from '../styles/dashboard.module.scss';
import StripedList from './StripedList';
import Select from 'react-dropdown-select';
import { ReactComponent as ScheduleClockIcon } from '../assets/svg/schedule-clock.svg';
import { ReactComponent as WindowsIcon } from '../assets/svg/windows-logo.svg';
import { ReactComponent as LinuxIcon } from '../assets/svg/linux.svg';
import { ReactComponent as AppleIcon } from '../assets/svg/apple.svg';
import ReactDOMServer from 'react-dom/server';
import cx from 'classnames';

const LIST_PROPERTIES_DATA = [
  {
    key: 'recurrences',
    label: 'Recurrence',
  },
  {
    key: 'timeZoneOffSet',
    label: 'Time Zone',
  },
  {
    key: 'scheduledOn',
    label: 'Scheduled On',
  },
  {
    key: 'expiresOn',
    label: 'Expires On',
  },
  {
    key: 'machineInfo',
    label: 'Machine Name',
    customTitle: (DataComponent) => {
      return `Machine Name : ${ReactDOMServer.renderToString(DataComponent).replace(/<[^>]*>?/gm, '')}`;
    },
  },
  {
    key: 'executionEnv',
    label: 'Execution Environments',
  },
  {
    key: 'executionType',
    label: 'Executed Type',
  },
  {
    key: 'scheduledBy',
    label: 'Scheduled By',
  },
];

function CustomContentRenderer({ label }) {
  return (
    <div className="flex items-center justify-evenly text-ellipsis overflow-hidden">
      <ScheduleClockIcon className="flex-shrink-0" />
      <span className="ml-2 text-ellipsis overflow-hidden">{label}</span>
    </div>
  );
}

function formatSuiteInfo(suite) {
  return LIST_PROPERTIES_DATA.reduce((formattedSuite, { key }) => {
    let formattedValue = suite?.[key] || '';
    if (formattedValue) {
      if (key === 'scheduledOn') {
        formattedValue = `${suite?.scheduledOn || ``} ${suite?.scheduledTime || ``}`;
      } else if (key === 'expiresOn') {
        formattedValue = `${suite?.expiresOn || ``} ${suite?.expireTime || ``}`;
      } else if (key === 'timeZoneOffSet') {
        formattedValue = `${suite?.timeZoneOffSet || ``}`;
      }
    } else if (key === 'executionEnv' && suite?.selectedSystems?.length) {
      formattedValue = suite?.selectedSystems?.map((system) =>
        system?.machineInstances?.map(({ executionEnv }) => executionEnv).join(', ')
      );
    } else if (key === 'machineInfo' && suite?.selectedSystems?.length) {
      formattedValue = (
        <span className="flex overflow-hidden overflow-ellipsis whitespace-nowrap">
          {suite?.selectedSystems?.map(({ machineInstances }, systemIndex) => {
            if (machineInstances?.[0]?.machineInfo) {
              const osName = machineInstances?.[0]?.machineInfo?.osName?.toLowerCase() || '';
              let osNameLabel = '';
              let Icon = <></>;
              if (osName.includes('windows')) {
                Icon = WindowsIcon;
                osNameLabel = 'Windows';
              } else if (osName.includes('linux')) {
                Icon = LinuxIcon;
                osNameLabel = 'Linux';
              } else if (osName.includes('mac')) {
                Icon = AppleIcon;
                osNameLabel = 'Mac';
              }
              let IconRenderer = () =>
                osName?.includes('windows') ? (
                  <WindowsIcon className="w-5 mr-1 flex-shrink-0" />
                ) : osName?.includes('linux') ? (
                  <LinuxIcon className="w-5 mr-1 flex-shrink-0" />
                ) : (
                  <AppleIcon className="w-5 mr-1 flex-shrink-0" />
                );
              return (
                <React.Fragment key={`${machineInstances[0]?.machineInfo?.osName}-${systemIndex}`}>
                  <span className="mx-1">{systemIndex > 0 ? ' | ' : ''}</span>
                  <span className="flex items-center overflow-hidden overflow-ellipsis">
                    {IconRenderer()}
                    <div className="hidden">{osNameLabel} : </div>
                    <span className="overflow-hidden overflow-ellipsis">
                      {machineInstances[0]?.machineInfo?.hostName || ''}
                    </span>
                  </span>
                </React.Fragment>
              );
            } else {
              return '---';
            }
          })}
        </span>
      );
    }
    return { ...formattedSuite, [key]: formattedValue };
  }, {});
}

function getAllSchedules(suites) {
  return (
    suites
      ?.map((suite) => {
        const suiteName = suite?.suiteName;
        return suite?.executionSchedules?.map((schedule) => ({
          ...schedule,
          name: `${suiteName || ''}${suiteName ? ' - ' : ''}${schedule?.name || ''}`,
        }));
      })
      .flat() || []
  );
}

function ExecutionSchedulesInfo({ schedules }) {
  const [selectedSuite, setSelectedSuite] = useState(null);
  const [allSchedules, setAllSchedules] = useState([]);
  const ref = useRef();
  if (ref?.current) {
    let refContainer = ref?.current
      ?.getElementsByClassName('react-dropdown-select-dropdown-handle')[0]
      ?.querySelector('svg');
    let path = refContainer?.querySelector('path');
    path?.setAttribute('d', 'M12 14l-4-4h8z');
    refContainer?.setAttribute('viewBox', '0 0 23 23');
  }

  useEffect(() => {
    const mergedSchedules = getAllSchedules(schedules);
    setAllSchedules(mergedSchedules);
    setSelectedSuite(mergedSchedules?.[0]);
  }, [schedules]);

  return (
    <div className="h-full flex flex-col" ref={ref}>
      <div className={DashboardStyle['info-select-box']}>
        <div
          className={cx(DashboardStyle['dropdown'], {
            [DashboardStyle['disabled']]: !schedules?.length,
          })}
        >
          <Select
            options={allSchedules}
            values={[selectedSuite || { name: 'No Schedules' }]}
            searchable={false}
            direction="auto"
            dropdownPosition="auto"
            labelField="name"
            valueField="name"
            disabled={!schedules?.length}
            contentRenderer={() => <CustomContentRenderer label={selectedSuite?.name || 'No Schedules'} />}
            onChange={(values) => setSelectedSuite(values[0])}
          />
        </div>
      </div>
      <StripedList data={formatSuiteInfo(selectedSuite)} listProperties={LIST_PROPERTIES_DATA} />
    </div>
  );
}

export default ExecutionSchedulesInfo;

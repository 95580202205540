import React, { useContext, useState } from 'react';
import { Icon, Tooltip } from 'fireflink-ui';
import { ASSERT_CONSTANT } from '../../../assert/jsx/constant';
import { colors } from '@src/css_config/colorConstants';
import MyInput from '@src/pages/common/Inputs/MyInput';
import Checkbox from '@material-ui/core/Checkbox';
import { RestContext } from '../../../../restapi';
import JWTPayloadEditor from './JWTEditor';

const SecretKeyAlgorithm = ({ formik, jwtBearerChange, ...props }) => {
  const context = useContext(RestContext);
  const {
    SECRET_KEY_TOOLTIP,
    SECRET_KEY_PLACEHOLDER,
    SECRET,
    SECRET_KEY_NAME,
    PAYLOAD_EDITOR_NAME,
    PAYLOAD_TOOLTIP,
    PAYLOAD,
    ENCODED,
    PRIMARY_COLOR,
    ENCODED_TEXT,
  } = ASSERT_CONSTANT;
  const { secret, payload } = formik.values;
  let { encoded } = context.values.jwtAuth;

  const [secretKeyData, setSecretKeyData] = useState({
    secretKeyValue: secret || '',
    payloadValue: payload || '',
    encodedValue: encoded || false,
  });

  const { secretKeyValue, encodedValue, payloadValue } = secretKeyData;

  const onSecretChange = (e) => {
    jwtBearerChange(e);
    const { value } = e.target;
    setSecretKeyData({ ...secretKeyData, secretKeyValue: value });
  };

  const onPayloadChange = (value, variableData) => {
    if (value) {
      value = props.getUserInputValue(variableData, false);
    }
    const event = {
      target: {
        value,
        name: PAYLOAD_EDITOR_NAME,
      },
    };

    jwtBearerChange(event);
    setSecretKeyData({ ...secretKeyData, payloadValue: variableData });
  };

  const onChangeEncoded = (e) => {
    const { checked } = e.target;
    console.log('checked', checked)
    jwtBearerChange(e);
    encoded = checked;
    setSecretKeyData({ ...secretKeyData, encodedValue: checked });
  };

  return (
    <>
      <div className="p-2 pb-0 flex hs-algo-container">
        <div className="relative">
          <div className="tooltip-icon">
            <Tooltip placement="right" title={SECRET_KEY_TOOLTIP}>
              <span>
                <Icon name="info_grey" width={14} height={14} color={colors.gray} />
              </span>
            </Tooltip>
          </div>
          <p className="input-filed-label fontPoppinsRegularSm text-left mb-2" onDragStart={props.handleDragStart}>
            <span className="common-auth-star-symbol">*</span> {SECRET}
          </p>
          <MyInput
            canCreateVariable={false}
            type="text"
            variableInput={true}
            placeholder={SECRET_KEY_PLACEHOLDER}
            value={props?.getUserInputValue(secretKeyValue, true)}
            className="my-input-style-for-authorization jwt-advance-input"
            inputValue={'input'}
            name={SECRET_KEY_NAME}
            onChange={onSecretChange}
            error={formik.errors.secret && formik.touched.secret}
            onMouseDown={formik.handleBlur}
            currentValue={context.values.currentJwtBearerToken?.secret}
            autocomplete="off"
            {...props}
          />
          <div className="flex items-center p-1">
            <Checkbox
              onChange={onChangeEncoded}
              name={ENCODED}
              color={PRIMARY_COLOR}
              checked={encodedValue}
              style={{ padding: '0px' }}
            />
            <p className="fontPoppinsRegularSm ml-2 heading-text">{ENCODED_TEXT}</p>
          </div>
        </div>
        <div className="w-full">
          <div className="flex mb-1 items-center pl-4">
            <div className="flex gap-2 items-center">
              <div className="fontPoppinsRegularSm jwt-select-title">
                <span className="common-auth-star-symbol">*</span> {PAYLOAD}
              </div>
              <Tooltip placement="right" title={PAYLOAD_TOOLTIP}>
                <span>
                  <Icon name="info_grey" width={14} height={14} color={colors.gray} />
                </span>
              </Tooltip>
            </div>
          </div>
          <div className="ace-container">
            <JWTPayloadEditor
              getCodeValue={onPayloadChange}
              value={props.getUserInputValue(payloadValue, true)}
              {...props}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SecretKeyAlgorithm;

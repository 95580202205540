import ColumnChart from './ColumnChart';
import DashboardStyle from '../styles/dashboard.module.scss';
import { useContext } from 'react';
import { ClientsContext } from '../components/ClientsProvider';

const chartProperties = {
  text: 'Phone OS',
  titleOffsetX: 0,
  strokeWidth: 5,
  yaxisTitle: 'No. of devices',
  xaxisTitle: 'Version',
  xaxisShow: true,
  xaxisLabelsShow: true,
  tooltipEnabled: true,
  classNameDashboardStyle: false,
};
function ClientPhoneOs() {
  const { phoneOsData } = useContext(ClientsContext);
  const { labels, colors, series, categories } = phoneOsData;

  function customTooltipFormatter({ dataPointIndex, series: seriesData, seriesIndex, w }) {
    const { colors } = w.globals;
    return `
      <div class="${DashboardStyle['column-tooltip']} ${DashboardStyle['column-custom-phoneOS-tooltip']} border-2 rounded-lg" style="border-color: ${colors?.[seriesIndex]}">
        <span> No. of devices : ${Math.floor(seriesData[seriesIndex][dataPointIndex])} </span>
      </div>
    `;
  }

  return (
    <ColumnChart
      chartProperties={chartProperties}
      colors={colors}
      labels={labels}
      categories={categories}
      series={series}
      customTooltipFormatter={customTooltipFormatter}
      roundedBorders
      roundedDirection="br"
    />
  );
}

export default ClientPhoneOs;

import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { ReactComponent as YellowAlert } from '@assets/svg-imports/ionic-ios-warning.svg';
import '@pages/configuration/plugin/bitBucket-integration/bitBucket.scss';
import '@pages/configuration/config-label.scss';
import { TIME_ZONE } from '@src/common/ui-constants';
import '@pages/common/alert_pop_ups/alert_pop_ups.module.scss';
import './time-zone.scss';

function TimeZoneUpdate(props) {
  return (
    <>
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="focus:outline-none outline-none"
      >
        <Box className="warning-modal focus:outline-none outline-none">
          <div className="headerwarning">
            <div className="successImg">
              <YellowAlert className="svgImgWarning" />
            </div>
            <div className="mt-5">
              <div className="flex flex-row">
                <p className="warning-color fontPoppinsMediumXX2 ml-5">{TIME_ZONE.WARNING}</p>
                <p className="fontPoppinsMediumXX2 ml-2 text-color">{TIME_ZONE.CHANGE_IN_TIMEZONE}</p>
              </div>
              <br />
              <div className="fontPoppinsRegularLg ml-7 -mt-3  flex flex-col">
                <span className="mt-4 fontPoppinsRegularMd text-color">
                  {TIME_ZONE.DATA_LOST}
                  <br />
                  <span className="fontPoppinsSemiboldMd">{TIME_ZONE.CONFIGURED} </span> {TIME_ZONE.PROJECT_LEVEL}
                </span>
                <span className="mt-4 fontPoppinsRegularMd text-color">{TIME_ZONE.CONFIRM_MESSAGE}</span>
              </div>
              <span className="ml-7 mb-2"> </span>
              <div className="ml-7"></div>
            </div>
          </div>
          <div className="delete-footer flex -mx-1">
            <button
              className="button-cancel cancel-instance cursor-pointer fontPoppinsRegularXs"
              onClick={props.closeModal}
            >
              {TIME_ZONE.CANCEL}
            </button>
            <button
              className="delete-button success-instance  cursor-pointer fontPoppinsRegularXs"
              onClick={props.updateAction}
            >
              {TIME_ZONE.CONFIRM}
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default TimeZoneUpdate;

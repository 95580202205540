import React, { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
function DisableModal(props) {
  const [open, setOpenModal] = useState(true);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={open}
        onClose={() => {
          props.closeModal(false);
          setOpenModal(false);
        }}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block border-t-4 border-red-700  pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle cautionDimention">
              <div className="flex flex-start ml-2">
                <span className="pt-5 pl-3">
                  <ExclamationIcon className="h-6 w-6 text-red-700 " />
                </span>
                <Dialog.Title as="h3" className="text-sm leading-6 ml-4 pt-3 font-small text-gray-900">
                  <span className="fontPoppinsSemiboldLg block">Caution </span>
                  <span className="fontPoppinsMediumMd">Disable</span>
                </Dialog.Title>
              </div>
              <hr className="line mt-px" />
              <div className="fontPoppinsMediumMd mt-3 text-gray-500 ml-16 mr-8">
                <p className="mb-4">
                  Are you sure you want to disable client machine {props.machine.machine.hostName} ?{' '}
                </p>
                <p className="mt-2">How do you want to proceed ?</p>
              </div>
              <hr className="line mt-3" />
              <div className="mt-5 pb-2 flex flex-row float-right">
                <button
                  type="button"
                  className=" gray-btn"
                  onClick={() => {
                    props.closeModal(false);
                    setOpenModal(false);
                    props.disableMachine(props.machine, props.user, props.defaultMachine);
                  }}
                >
                  Disable
                </button>
                <button
                  type="button"
                  className="ml-4 primary-btn"
                  onClick={() => {
                    setOpenModal(false);
                    props.closeModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default DisableModal;

import React, { useRef, useEffect, useState, Fragment } from 'react';
import { Popover } from '@headlessui/react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SimpleTree from './simple_tree';
import 'jquery.fancytree/dist/modules/jquery.fancytree.filter';
import { TextField } from '@material-ui/core';

const DropdownTree = (props) => {
  const popSearchInputRef = useRef(null);
  const dropdownPanelRef = useRef(null);

  const [treeValue, setTreeValue] = useState(props.initalValue ? props.initalValue : '');
  const [isOpenDropdown, setOpenDropdown] = useState(false);

  const treeData = props.treeData ? props.treeData : [];
  const nodeSelected = (data) => {
    popSearchInputRef.current.focus();
    props.nodeSelected(data);
    setTreeValue(data.title);
    showHideDropdown(false);
  };

  const serachChange = (e) => {
    //add by ramana
    if (props.onTyping) {
      props?.onTyping();
    }
    //end
    setTreeValue(e.target.value);
    if (!isOpenDropdown) {
      showHideDropdown(true);
    }
  };

  const showHideDropdown = (isShow) => {
    const dropdown = document.querySelector('.dropdown-tree-popup');
    setOpenDropdown(isShow);
    if (isShow) {
      dropdown.style.display = 'block';
    } else {
      if (dropdown) {
        dropdown.style.display = 'none';
      }
    }
  };
  // Added By Ramana
  if (props?.operation === 'edit') {
    const dropdown = document.querySelector('.dropdown-tree-popup');
    if (dropdown) {
      dropdown.style.display = 'none';
    }
  }

  useEffect(() => {
    const handleClickOutsideDropdown = (event) => {
      if (dropdownPanelRef.current && !dropdownPanelRef.current.contains(event.target)) {
        showHideDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDropdown);
    };
  }, [dropdownPanelRef]);

  useEffect(() => {
    setTreeValue(props.initalValue ? props.initalValue : '');
  }, [props.initalValue]);

  return (
    <div className={`dropdown-tree-wrapper ${props.overlay ? 'overlay-dropdown-tree' : ''}`}>
      <Popover className="relative">
        <>
          <div className="dropdown-tree-search-input-wrapper">
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              error={props.errorExist}
              type="text"
              className="dropdown-tree-search-input text-opacity-10"
              value={treeValue && treeValue.length >= 32 ? treeValue.substring(0, 30) + '...' : treeValue}
              ref={popSearchInputRef}
              //changes related to recorded elements
              placeholder={
                props.isRecordedPlaceholder === true
                  ? ' '
                  : props.placeholder.length >= 32
                    ? props.placeholder.substring(0, 37) + '...'
                    : props.placeholder
              }
              InputProps={{
                style: { opacity: 0.7 },
              }}
              onChange={serachChange}
              disabled={
                props.isFromRecorded
                  ? false //used for recorded Elements added by shivakumar
                  : props.operation === 'edit'
                    ? true
                    : !treeData.length
                      ? true
                      : !props.initalValue
                        ? false
                        : !props.initalValue.startsWith('Root')
                          ? true
                          : false
              }
              label={
                <>
                  {' '}
                  <span className="fontPoppinsRegularXLg">{props.labelValue}</span>
                </>
              }
            />
            <ArrowDropDownIcon
              onClick={() => {
                showHideDropdown(true);
              }}
              className={`${isOpenDropdown ? '' : 'text-opacity-70'} ${props.isRecordedPlaceholder === true ? 'dropdown-tree-button-arrow-recorded' : ''}
              dropdown-tree-button-arrow ml-2  h-5 w-5 text-orange-300 group-hover:text-opacity-80 transition ease-in-out duration-150`}
            />
          </div>
          <Popover.Panel static className="dropdown-tree-popup" ref={dropdownPanelRef}>
            <div className={`${props.overlay ? 'mt-4' : ''}`} id="journal-scroll">
              <div className="relative w-full z-10 grid gap-8 bg-white p-3 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 ">
                <SimpleTree
                  treeType="Element"
                  filter={treeValue}
                  data={treeData}
                  noDataMessage={props.noDataMessage}
                  showOnlyFolders={props.showOnlyFolders}
                  nodeSelected={nodeSelected.bind(this)}
                />
              </div>
            </div>
          </Popover.Panel>
        </>
      </Popover>
    </div>
  );
};

export default DropdownTree;

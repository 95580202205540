import React, { useEffect, useState } from 'react';
import { DASHBOARD_CONSTANTS } from '@src/common/ui-constants';
import { getSingleUserRequest } from '@src/api/api_services';
import { Drawer } from 'fireflink-ui';
const UserDetailsDashBoard = (props) => {
  const [userDetails, setUserDetails] = useState([]);
  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const response = await getSingleUserRequest(props?.selectedDashBoard?.createdBy);
        if (response?.data?.responseCode === 200) {
          setUserDetails(response.data.responseObject);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    getUserDetails();
  }, []);
  const getUserPrivilege = (userDetails) => {
    const licenses = userDetails.licenses;
    if (licenses && licenses.length) {
      const userLicense = licenses.find((lic) => lic.id === userDetails.defaultLicenseId);
      return userLicense.privilege;
    }
    return '';
  };

  const secondaryButtonProps = {
    label: DASHBOARD_CONSTANTS.CLOSE,
    onClick: props.onClose,
  };
  return (
    <Drawer
      isOpen={true}
      onClose={props.onClose}
      overlay={true}
      title={`User Details - ${userDetails.createdByUname}`}
      secondaryButtonProps={secondaryButtonProps}
    >
      <section className="dashboard-detail">
        <div className="w-full h-full flex flex-col ">
          <div className="grid grid-cols-3  pb-4  overflow-y-auto max-h-72">
            <div className="overflow-hidden-common">
              <label
                htmlFor="userName"
                className="block label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
              >
                {DASHBOARD_CONSTANTS.NAME}
              </label>
              <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
                {userDetails?.createdByUname}
              </label>
            </div>
            <div className="overflow-hidden-common ml-4">
              <label
                htmlFor="userName"
                className="block label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
              >
                {DASHBOARD_CONSTANTS.EMAIL_ADDRESS}
              </label>
              <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
                {userDetails?.emailId}
              </label>
            </div>
            <div className="ml-20">
              <label
                htmlFor="userName"
                className="block label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
              >
                {DASHBOARD_CONSTANTS.PRIVILEGE}
              </label>
              <label className="instance-details-style overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
                {getUserPrivilege(userDetails)}
              </label>
            </div>
            <div className="mt-6">
              <label
                htmlFor="userName"
                className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
              >
                {DASHBOARD_CONSTANTS.CREATED_BY}
              </label>
              <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
                {userDetails?.createdByUname}
              </label>
            </div>
            <div className="mt-6 ml-4">
              <label
                htmlFor="userName"
                className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
              >
                {DASHBOARD_CONSTANTS.CREATED_ON}
              </label>
              <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
                {' '}
                <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
                  {userDetails?.createdOn}
                </label>
              </label>
            </div>
            <div className="mt-6 ml-20">
              <label
                htmlFor="userName"
                className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
              >
                {DASHBOARD_CONSTANTS.STATUS}
              </label>
              <label className="instance-details-style fontPoppinsRegularSm">{userDetails?.activationStatus}</label>
            </div>
            <div className="mt-6">
              <label
                htmlFor="userName"
                className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
              >
                {DASHBOARD_CONSTANTS.MODIFIED_BY}
              </label>
              <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
                {userDetails?.modifiedByUname}
              </label>
            </div>
            <div className="mt-6 ml-4">
              <label
                htmlFor="userName"
                className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
              >
                {DASHBOARD_CONSTANTS.MODIFIED_ON}
              </label>
              <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
                {userDetails?.modifiedOn}
              </label>
            </div>
          </div>
        </div>
      </section>
    </Drawer>
  );
};

export default UserDetailsDashBoard;

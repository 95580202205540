import React, { useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { ReactComponent as CalenderIcon } from '@assets/analytics/Calender.svg';
import 'react-datepicker/dist/react-datepicker.css';
import { DATE_FORMATS, getCurrentProjectTimeZoneName } from '@src/util/common_utils';
import { TIMEZONE_CONSTANTS } from '@src/common/ui-constants';

const DateTimePicker = (props) => {

  let initialTimeZoneName = getCurrentProjectTimeZoneName();
  const minDate = new Date().toLocaleString(TIMEZONE_CONSTANTS.US_LOCALE_TIME_ZONE, {
    timeZone: initialTimeZoneName,
  });
  
  const datepickerRef = useRef('');
  function handleClickDatepickerIcon() {
    const datepickerElement = datepickerRef.current;
    datepickerElement.setFocus(true);
  }
  return (
    <div className="datePickerBlock flex items-center justify-between">
      <div>
        <DatePicker
          dateFormat= {DATE_FORMATS.DATE_WITH_MONTH_NAME}
          selected={props.selectedDate}
          id={props.dateId}
          maxDate={props?.maxDate || new Date(minDate)}
          minDate={props?.minDate ? props?.minDate : ''}
          onChange={(date) => {
            props.setDateRange(props.dateId, date);
          }}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          autoComplete="off"
          ref={datepickerRef}
          className={`${props.className} fontPoppinsRegularSm bg-transparent`}
          placeholderText={props.placeholder}
          popperPlacement={`${props.popperPlacement}`}
        />
      </div>
      <div onClick={handleClickDatepickerIcon}>
        <CalenderIcon className="mt-1 mr-2" />
      </div>
    </div>
  );
};

export default DateTimePicker;

import React from 'react';
import { useHistory } from 'react-router-dom';
import './plugin.scss';
import { downloadjenkinsFileReq } from '@api/api_services';
import { pluginData } from './plugin.utils';
import { getConfigurationTabs } from '@src/util/common_utils';
function PluginTable() {
  const history = useHistory();
  const configurationTabs = getConfigurationTabs()?.map((config) => config?.name);

  function saveFileFromBlob(blob, filename) {
    if (blob) {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }, 0);
      }
    }
  }

  const downloadjenkins = async () => {
    let response;
    const executionBaseUrl = process.env.REACT_APP_EXECUTION_BASE_URL.toString();
    const isDockerEnv = executionBaseUrl.slice(executionBaseUrl.lastIndexOf('/') + 1) === 'dashboardexecution';
    let payload = {
      filePath: ['ProductUtility/Jenkins/fire-flink.hpi'],
      type: 'cloudS3',
    };
    if (isDockerEnv) {
      payload.filePath[0] = 'ProductUtility/Jenkins/Docker/fire-flink.hpi';
    }
    try {
      response = await downloadjenkinsFileReq(payload);
      saveFileFromBlob(response.data, 'fire-flink.hpi');
    } catch (err) {
      console.error('DOWNLOAD_JENKINS : ', err);
    }
  };
  const navigationMethod = (path) => {
    if (path === 'JENKINS') {
      downloadjenkins();
    } else if (path) {
      history.push(path);
    }
  };
  return (
    <div className="overflow-y-auto">
      <div className="content-wrapper">
        <div className="table-height">
          {pluginData.map(
            (plugin) =>
              configurationTabs?.includes(plugin?.name) && (
                <div key={plugin.name} className="pluginRowHeight">
                  <div className="pluginHeader">
                    <label className="fontPoppinsSemiboldMd ml-2">{plugin.label}</label>
                  </div>
                  <div className=" mt-2 ">
                    {plugin.options.map((option, index) => (
                      <div
                        key={option.label}
                        className={`ml-2 card-container  float-left cursor-pointer grid grid-col`}
                        onClick={() => {
                          navigationMethod(option.path);
                        }}
                      >
                        <div className="flex flex-row justify-between">
                          <div className="flex flex-col w-44">
                            <div className="fontPoppinsSemiboldSm">{option.label}</div>
                            <div className="fontPoppinsMediumXs mt-1  plugin-Content">{option.content}</div>
                          </div>
                          <span className="flex items-center">
                            <img src={option.image} alt={option.label} className="plugin-img-container" />
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
}

export default PluginTable;

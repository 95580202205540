import React from 'react';
import { withRouter } from 'react-router';
import { useAlert } from '../common/alert_service/useAlert';
import Breadcrumbs from '../common/breadcrumbs';
import RepositoryRouter from './repository_router';
const Repository = () => {
  const { AlertContatiner, MyAlert } = useAlert();
  return (
    <div>
      <div className="alert-position-style">
        <AlertContatiner></AlertContatiner>
      </div>
      <RepositoryRouter MyAlert={MyAlert} />
    </div>
  );
};

export default withRouter(Repository);

import React, { useState, useEffect, useContext } from 'react';
import { useAlert } from '@pages/common/alert_service/useAlert';
import { useHistory } from 'react-router-dom';
import { noDataMessage, TIME_ZONE } from '@src/common/ui-constants';
import { useTable } from 'react-table';
import { Tooltip } from '@material-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@material-ui/icons/Clear';
import { ArrowDropDown } from '@material-ui/icons';
import { HighlightText, isEmptyValue, isValueIncludes, getTruncatedText } from '@util/common_utils';
import SearchBar from '@src/pages/analytics/common/search-bar';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import '../TimeZone/time-zone.scss';
import NoResultsFound from '@src/pages/common/Table/NoResultFound';
import SearchDropdown from '@src/pages/common/search_dropdown/SearchDropdown';
import {
  getAllTimeZone,
  updateLicenseLevelTimeZone,
  getTimeZoneList,
  getSingleUserRequest,
} from '@src/api/api_services';
import TimeZoneUpdate from './TimeZoneUpdate';
import DataNotAvailable from '@src/pages/common/Table/DataNotAvailable';
import UserDetailsModal from '@pages/test-development/shared/user-details-modal';
import { Icon } from 'fireflink-ui';
import { colors } from '@src/css_config/colorConstants';

function TimeZoneConfig() {
  const history = useHistory();
  const [tabsList] = useState([
    {
      name: 'Time Zone',
      pathUrl: `/configuration/TimeZone`,
      selectedName: 'TimeZone',
    },
  ]);
  const { AlertContatiner, MyAlert } = useAlert();
  const [labelTabelData, setLabelTableData] = useState([]);
  const [userSearched, setuserSearched] = useState('');
  let [isHighlight, setIsHighlight] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');
  const [openSearch, setOpenSearch] = useState(false);
  const [data, setData] = useState([]);
  const [changeIcons, setChangeIcons] = useState(false);
  const [timezonelicenseData, setTimeZoneLicenseData] = useState([]);
  const [timeZoneData, setTimeZoneData] = useState([]);
  const [timeZoneDataValue, setTimeZoneDataValue] = useState([]);
  const [rowIndex, setRowIndex] = useState();
  const [selectedTimeZoneValue, setSelectedTimeZoneValue] = useState('');
  const [changeAllIcons, setChangeAllIcons] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [timeZoneObject, setTimeZoneObject] = useState({});
  const [timeZoneName, setTimeZoneName] = useState();
  const [showDropdown, setShowDropdown] = useState(false);
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  let timeZoneArray = [];
  const isLicenseAndProjectLevelTimeZonePresent = timeZoneData?.length >= 2;

  const [openDetailsModal, setOpenDetailsModal] = useState({
    isDropDownDispaly: false,
    openUserDetailsModal: false,
    userDetails: [],
  });

  const closeUserDetailsModal = (value) => {
    setOpenDetailsModal({
      isDropDownDispaly: false,
      openUserDetailsModal: value,
      userDetails: {},
    });
  };

  useEffect(() => {
    setData(labelTabelData);

    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [labelTabelData]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(labelTabelData);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setData(labelTabelData);
    setIsHighlight(!isHighlight);
    setSearchTextHighlight('');
  };
  let handleSearchOpen = () => {
    setOpenSearch(true);
  };
  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = labelTabelData.filter(({ entityName, name, modifiedByUname, value }) => {
          return (
            isValueIncludes(e, name) ||
            isValueIncludes(e, entityName) ||
            isValueIncludes(e, modifiedByUname) ||
            isValueIncludes(e, value)
          );
        });
        setData(newData);
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  const getAllTimeZoneAPI = () => {
    setChangeAllIcons(false);
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    getAllTimeZone()
      .then((res) => {
        if (!isEmptyValue(res?.data?.responseObject?.projectDetails)) {
          setTimeZoneData(res?.data?.responseObject?.projectDetails);
          let filterLabelTableData = res?.data?.responseObject?.projectDetails.filter((values) =>
            values.hasOwnProperty('projectId')
          );
          setLabelTableData(filterLabelTableData);
          setTimeZoneLicenseData([res?.data?.responseObject?.projectDetails?.[0]]);
          setSelectedTimeZoneValue(res?.data?.responseObject?.projectDetails?.[0]?.value);
          setTimeZoneName(res?.data?.responseObject?.projectDetails?.[0]?.value);
        }
        stopProgress();
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 10,
        });
      })
      .catch((err) => {
        resetProgress();
        console.error(err);
      });
  };

  useEffect(() => {
    getAllTimeZoneAPI();
  }, []);

  const handleClickState = (row, rows, type) => {
    getTimeZoneList().then((res) => {
      let resObject = res.data.responseObject;
      setTimeZoneObject(resObject);
      timeZoneArray.push(Object.values(resObject));
      setTimeZoneDataValue(timeZoneArray);
    });

    if (type === 'all') {
      setTimeZoneName(row?.name);
      setSelectedTimeZoneValue(row?.value);
      setRowIndex(row.id);
      setChangeAllIcons(true);
      setShowDropdown(true);
    } else {
      setTimeZoneName(row?.original?.name);
      setSelectedTimeZoneValue(row?.original?.value);
      setRowIndex(row?.original?.id);
      setChangeIcons(true);
      setShowDropdown(true);
      getElement(`timeZone+${row.id}`, type);
    }
  };

  const handleWarningModal = () => {
    setOpenWarningModal(true);
  };

  const handleClose = () => {
    setOpenWarningModal(false);
  };

  let timeZoneUpdate = (rowData) => {
    let timeZoneKeyName = Object.keys(timeZoneObject).find((key) => timeZoneObject[key] === selectedTimeZoneValue);
    setTimeZoneName(timeZoneKeyName);
    let updateLicensePayload = {
      id: timezonelicenseData?.[0]?.id,
      name: timeZoneKeyName,
    };

    let projectLevelTimeZone = {
      id: rowData?.id,
      name: timeZoneKeyName,
    };

    if (rowData?.projectId) {
      updateLicenseLevelTimeZone(projectLevelTimeZone, rowData?.projectId)
        .then((res) => {
          setChangeIcons(false);
          setTimeZoneData(res?.data?.responseObject?.projectDetails);
          let filterLabelTableData = res?.data?.responseObject?.projectDetails.filter((values) =>
            values.hasOwnProperty('projectId')
          );
          setLabelTableData(filterLabelTableData);
          MyAlert.success(res.data.message);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      updateLicenseLevelTimeZone(updateLicensePayload)
        .then((res) => {
          if (res?.data?.responseCode === 200) {
            localStorage.setItem('license-level-timezone', updateLicensePayload.name);
            setChangeAllIcons(false);
            setTimeZoneLicenseData([res?.data?.responseObject?.projectDetails?.[0]]);
            let filterLabelTableData = res?.data?.responseObject?.projectDetails.filter((values) =>
              values.hasOwnProperty('projectId')
            );
            setLabelTableData(filterLabelTableData);
            setSelectedTimeZoneValue(res?.data?.responseObject?.projectDetails?.[0]?.value);
            MyAlert.success(res.data.message);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    setOpenWarningModal(false);
  };

  const getElement = (id, type) => {
    if (type !== 'all') {
      let ele = document.getElementById(id);
      let eleHeight = ele?.getBoundingClientRect();
      let bottomheight = window?.innerHeight - eleHeight?.bottom;
      if (bottomheight < 200) {
        setOpenDetailsModal({
          isDropDownDispaly: true,
          openUserDetailsModal: false,
          userDetails: {},
        });
      } else {
        setOpenDetailsModal({
          isDropDownDispaly: false,
          openUserDetailsModal: false,
          userDetails: {},
        });
      }
    }
  };

  const getUserDetails = async (userID) => {
    try {
      const res = await getSingleUserRequest(userID);
      if (res?.data?.responseCode === 200 && res?.data?.responseObject) {
        setOpenDetailsModal({
          isDropDownDispaly: false,
          openUserDetailsModal: true,
          userDetails: res.data.responseObject,
        });
      }
    } catch (err) {
      console.error('GET_USER_DETAILS : ', err);
    }
  };

  const EditIconAdd = (row, rows, type) => {
    return (
      <Tooltip title={`Edit`} placement="bottom">
        <Icon
          name="edit_icon"
          height={14}
          width={14}
          onClick={() => {
            handleClickState(row, rows, type);
          }}
          hoverEffect={true}
          color={colors.gray}
        />
      </Tooltip>
    );
  };

  const changeIcon = (rowdata, row, type) => {
    return (
      <div className="flex">
        <Tooltip title={`Save`} placement="bottom">
          <span className="mr-4">
            <Icon
              name="check"
              height={14}
              width={14}
              onClick={() => (rowdata?.projectId ? timeZoneUpdate(rowdata) : handleWarningModal())}
              hoverEffect={true}
              color={colors.gray}
            />
          </span>
        </Tooltip>
        <Tooltip title={`Cancel`} placement="bottom">
          <span>
            <Icon
              name="close"
              height={14}
              width={14}
              onClick={() => handleClickState('', '', type)}
              hoverEffect={true}
              color={colors.gray}
            />
          </span>
        </Tooltip>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'PROJECTS',
        accessor: 'entityName',
        disableFilters: true,
        width: '30%',
        Cell: ({ value }) => (
          <div className="label-color float-left table-font-style-common cursor-default fontPoppinsRegularSm pl-2">
            <HighlightText text={getTruncatedText(value, 20)} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'TIME ZONE',
        accessor: 'value',
        disableFilters: true,
        width: '35%',
        Cell: ({ value, row }) => (
          <div className="float-left cursor-default fontPoppinsRegularSm pl-2 label-color">
            {changeIcons && row?.original?.id === rowIndex ? (
              returnSearchDropdown(timeZoneDataValue)
            ) : (
              <HighlightText text={getTruncatedText(value, 60)} highlight={searchTextHighlight} />
            )}
          </div>
        ),
      },
      {
        Header: 'MODIFIED BY',
        accessor: 'modifiedByUname',
        disableFilters: true,
        width: '10%',
        Cell: ({ value, row }) => (
          <div
            className="label-name float-left table-font-style-common cursor-pointer fontPoppinsRegularSm pl-2"
            onClick={(event) => {
              event.preventDefault();
              if (row?.original?.userId) {
                getUserDetails(row?.original?.userId);
              }
            }}
          >
            <HighlightText text={getTruncatedText(value, 15)} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'ACTIONS',
        accessor: 'actions',
        disableFilters: true,
        width: '15%',
        Cell: ({ row, rows }) => {
          return (
            <div>
              <span className="flex flex-row float-left table-font-style-common pl-2">
                <span className={`show_action cursor-pointer`}>
                  <button>
                    {changeIcons && row?.original?.id === rowIndex
                      ? changeIcon(row?.original, row, 'individual')
                      : EditIconAdd(row, rows, 'individual')}
                  </button>
                </span>
              </span>
            </div>
          );
        },
      },
    ],
    [isHighlight, rowIndex, timeZoneDataValue, selectedTimeZoneValue, changeIcons]
  );

  const handleItemSelectedValue = (value) => {
    setTimeZoneName(value);
    setSelectedTimeZoneValue(value);
  };

  const returnSearchDropdown = (timeZoneDataValue) => {
    let timezoneValues = timeZoneDataValue[0];
    return (
      <div className={`${changeAllIcons ? 'search-width' : ''} h-10`}>
        <SearchDropdown
          searchLabel="Search"
          optionKey="licenseName"
          fontSize="fontPoppinsRegularSm"
          list={timezoneValues}
          EndIcon={() => <ArrowDropDown fontSize="medium" />}
          handleItemClick={handleItemSelectedValue}
          enableSearch={true}
          selectedValue={selectedTimeZoneValue}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          isDropDownDispaly={openDetailsModal?.isDropDownDispaly}
        />
      </div>
    );
  };

  const licenseColums = React.useMemo(
    () => [
      {
        Header: 'TIME ZONE',
        accessor: 'value',
        disableFilters: true,
        width: '42%',
        Cell: ({ value, row }) => (
          <div className="float-left cursor-default fontPoppinsRegularSm pl-2 label-color">
            {changeAllIcons && row?.original?.id === rowIndex ? returnSearchDropdown(timeZoneDataValue) : value}
          </div>
        ),
      },
      {
        Header: 'MODIFIED BY',
        accessor: 'modifiedByUname',
        disableFilters: true,
        width: '42%',
        Cell: ({ value, row }) => (
          <div
            className="label-name float-left table-font-style-common cursor-pointer fontPoppinsRegularSm pl-2"
            onClick={(event) => {
              event.preventDefault();
              if (row?.original?.userId) {
                getUserDetails(row?.original?.userId);
              }
            }}
          >
            {value}
          </div>
        ),
      },
      {
        Header: 'ACTIONS',
        accessor: 'Actions',
        disableFilters: true,
        width: '16%',
        className: 'text-left',
        Cell: ({ row }) => {
          return (
            <div>
              <span className="flex flex-row float-left pl-6">
                <span className={` cursor-pointer`}>
                  <button>
                    {changeAllIcons && row?.original?.id === rowIndex
                      ? changeIcon(row?.original, row, 'all')
                      : EditIconAdd(row?.original, row, 'all')}
                  </button>
                </span>
              </span>
            </div>
          );
        },
      },
    ],
    [isHighlight, rowIndex, timeZoneDataValue, selectedTimeZoneValue, changeAllIcons]
  );

  function Table({ columns, data }) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });
    return (
      <>
        <table {...getTableProps()} className="h-7 mr-3">
          <thead className="projectDetailsDataa">
            {headerGroups?.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className={`text-sm h-9 ml-4 top-0 ${headerGroup?.headers.length === 3 ? 'hover:bg-transparent' : ''}`}
              >
                {headerGroup?.headers?.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    width={column.width}
                    className={`text-left mx-2 fontPoppinsSemiboldSm pl-3 ${
                      headerGroup?.headers.length > 3 ? 'table-line' : ''
                    }`}
                  >
                    <span className="table-header-text pl-2">{column.render('Header')}</span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows?.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={`${
                    row.length > 1 ? 'configHover hover:bg-gray-100' : ''
                  } h-11 mt-10 show-action-style relative`}
                  id={`timeZone+${index}`}
                >
                  {row?.cells?.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className={` p-0  text-left fontPoppinsRegularSm pl-3 project-row`}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {openSearch && isEmptyValue(data) && <NoResultsFound className="mt-14" />}
      </>
    );
  }

  const renderTabs = () => {
    return (
      <>
        <div>
          <ul className="navBar table-header-text flex flex-row tab-style ml-6 h-8">
            {tabsList.map((tab, index) => {
              return (
                <li key={tab.name}>
                  <a
                    href="#"
                    className="tab-color"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(tab.pathUrl);
                    }}
                  >
                    {tab.name}{' '}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="page-table-body">
        <div className="alert-position-style">
          <AlertContatiner></AlertContatiner>
        </div>
        {renderTabs()}
      </div>
      <div className="content_area_style_Int overflow-scroll margin-top-style content-area">
        <div className="pb-3 border_style flex flex-wrap items-center sticky">
          <label className="project-label text-sm flex-auto fontPoppinsSemiboldMd mt-4">
            {TIME_ZONE.CONFIG_TIME_ZONE}
          </label>
        </div>
        <div className="m-4 license-config">
          <div className="table-height license_lev_timezone" id="journal-scroll">
            <Table columns={licenseColums} data={timezonelicenseData} />
          </div>
        </div>
        <div className="pb-3 flex flex-wrap items-center sticky">
          <label className="project-label text-sm flex-auto fontPoppinsSemiboldMd">
            {TIME_ZONE.CONFIG_TIME_ZONE_PROJECT}
          </label>
          <div className="flex items-center justify-end mr-1 w-2/5 mt-1 pr-2">
            <SearchBar
              open={openSearch}
              searchValue={userSearched}
              handleSearch={handleSearch}
              onSearchClose={handleSearchClose}
              handleSearchOpen={handleSearchOpen}
              enterKeyHandler={handleSearchSubmit}
              disable={isEmptyValue(labelTabelData)}
            />
          </div>
        </div>
        <div className="table-height" id="journal-scroll">
          {isLicenseAndProjectLevelTimeZonePresent ? (
            <Table columns={columns} data={data} />
          ) : (
            <DataNotAvailable message={noDataMessage.projectNotAvailable} className="h-64" />
          )}
        </div>
      </div>

      {openWarningModal && (
        <TimeZoneUpdate
          openModal={handleWarningModal}
          closeModal={handleClose}
          updateAction={timeZoneUpdate}
          openWarningModal={setOpenWarningModal}
        />
      )}
      {openDetailsModal?.openUserDetailsModal && (
        <UserDetailsModal data={openDetailsModal?.userDetails} closeModal={closeUserDetailsModal} />
      )}
    </>
  );
}

export default TimeZoneConfig;

export const REGEX = {
  SPECIAL_CHARACTER: '^[s@"@"@A-Za-z]+$',
  USER_SETTINGS_SPECIAL_CHARACTERS: /[{}|\\^~[\]`]/,
  ALPHANUMERIC_VALIDATION: /[^a-zA-Z0-9 _-]/,
  XML_FILE_TYPE_VALIDATION: /<([A-Za-z_:][A-Za-z0-9_.:-]*)\b[^>]*>(.*?)<\/\1>/s,
  HTML_FILE_TYPE_VALIDATION: /<html\b[^>]*>[\s\S]*<\/html>/i,
  BODY_TAG_TYPE_VALIDATION: /<body\b[^>]*>[\s\S]*<\/body>/i,
  JAVASCRIPT_FILE_TYPE_VALIDATION: /^\s*(function|const|let|var)\b|\b(return|if|for|while|switch|case)\b.*[{}]/s,
  ESCAPE_SPECIAL_CHARACTERS: /([.?*+^$[\]\\(){}|-])/g,
  REQUEST_URL_REGEX: /^(?<!{[^}]*)(\s+)(?![^{]*})|(?<!{[^}]*)(\s+)(?![^{]*})$/,
  WHITE_SPACE_REGEX: /^\S(?:.*\S)?$/,
  GRAPHQL_FORMAT_REGEX: /"query"\s*:\s*".*?"\s*,\s*"variables"\s*:\s*\{.*?\}/,
  VALIDATE_TEXT_EXECUTION_TEXTFIELD: /^\s*$|[^a-zA-Z0-9_(.)\- ]|^( |.* $)|^.{26,}$/,
  REMOVE_PLUS: /\+/g,
  REPLACE_SPACE: / /g,
  EMAIL_PATTERN: /^[a-zA-Z0-9](?:[a-zA-Z0-9._-]*[a-zA-Z0-9])?@[a-zA-Z0-9]+(?:[.-][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/,
  VALID_SPACE: /  +/g,
  PASSWORD_PATTERN: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^&*()+=]).{8,20}$',
  PASSWORD_WEAK_MATCH: /^\S*$/,
  ORGANIZATION_NAME: /^[A-Za-z0-9 ]*$/,
  NAME_PATTERN: /^[A-Za-z0-9 ]*$/,
  JOB_TITLE: /^[A-Za-z ]*$/,
  SIGNUP_EMAIL_PATTERN: /[A-Za-z0-9]+@[a-zA-Z]+\.[a-zA-Z]{2,3}/,
};

export const UI_VALIDATIONS = {
  BASE_COUNT: 4,
  MAX_CHARACTER_COUNT: 22,
  MAX_FILE_UPLOAD_COUNT: 5,
  MAX_DRAWER_TITLE_CHAR_COUNT: 70,
  MAX_EMAIL_CHAR_COUNT: 20,
  MAX_EMAIL_GROUP_NAME_COUNT: 10,
  MAX_NLP_NAME_CHAR_COUNT: 30,
  NAME_MIN_CHARACTER: 3,
  NAME_MAX_CHARACTER: 25,
  WAIT_TIME_CONFIGURATION: 0,
  CAPTURE_DATA_LENGTH: 18,
  FILTER_OPTION_SEARCHBAR_LIMIT: 15,
  FILTERS_OPTION_SCROLLBAR_LIMIT: 5,
  DEFECTS_VALUES_MAX_CHAR_COUNT: 30,
  ARRAY_LENGTH_ZERO: 0,
  MAX_NUMBER_OF_RUNS: 35,
  DOC_COUNT_MAX_TEST_DEV: 200,
  DOC_COUNT_MAX_SUITE: 25,
  MAX_DESCRIPTION_CHARACTER_COUNT: 200,
};

export const DEFECT_ATTACTMENT_FILE_ALLOWED = {
  '.png': 'image/png',
  '.jpg': 'image/jpeg',
  '.jpeg': 'image/jpeg',
  '.svg': 'image/svg+xml',
  '.mp4': 'video/mp4',
};

export const IMPORT_EXPORT_FILE_ALLOWED = {
  '.zip': 'application/x-zip-compressed',
};

export const INTEGRATION_FILE_ALLOWED = {
  '.zip': 'application/x-zip-compressed',
  '.apk': 'application/vnd.android.package-archive',
  '.ipa': 'application/octet-stream',
};

export const IMPORT_MTC_FILE_ALLOWED = {
  '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};
export const WEBSERVICE_FORMDATA_FILE_ALLOWED = {
  '.json': 'application/json',
  '.js': 'text/javascript',
  '.txt': 'text/plain',
  '.html': 'text/html',
  '.xml': 'text/xml',
  '.jpg': 'image/jpeg',
  '.jpeg': 'image/jpeg',
  '.png': 'image/png',
  '.pdf': 'application/pdf',
  '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.xls': 'application/vnd.ms-excel',
  '.apk': 'application/vnd.android.package-archive',
  '.ipa': 'application/octet-stream',
  '.md': 'text/markdown',
  '.csv': 'text/csv',
  '.gif': 'image/gif',
  '.pem': '',
};

export const WEBSERVICE_RAW_FILE_ALLOWED = {
  '.json': 'application/json',
  '.js': 'text/javascript',
  '.txt': 'text/plain',
  '.html': 'text/html',
  '.xml': 'text/xml',
};

export const MANUAL_TEST_CASE_ALLOWED_EXTENSIONS_MIME = {
  '.jpeg': 'image/jpeg',
  '.jpg': 'image/jpeg',
  '.png': 'image/png',
  '.svg': 'image/svg+xml',
  '.mp4': 'video/mp4',
};

// NOTE :: All allowed file upload types should be included in the test data.
export const TEST_DATA_FILE_ALLOWED = {
  '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.xls': 'application/vnd.ms-excel',
  '.pdf': 'application/pdf',
  '.json': 'application/json',
  '.csv': 'text/csv',
  '.png': 'image/png',
  '.jpg': 'image/jpeg',
  '.jpeg': 'image/jpeg',
  '.svg': 'image/svg+xml',
  '.yml': 'application/x-yaml',
  '.txt': 'text/plain',
  '.wav': 'audio/wav',
  '.doc': 'application/msword',
  '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.y4m': 'video/x-y4m',
  '.html': 'text/html',
  '.apk': 'application/vnd.android.package-archive',
  '.ipa': 'application/octet-stream',
  '.zip': 'application/x-zip-compressed',
  '.gif': 'image/gif',
  '.md': 'text/markdown',
  '.js': 'text/javascript',
  '.mp4': 'video/mp4',
  '.properties': 'text/x-java-properties',
  '.ppt': 'application/vnd.ms-powerpoint',
  '.pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  '.tar': 'application/x-tar',
  ...DEFECT_ATTACTMENT_FILE_ALLOWED,
  ...IMPORT_EXPORT_FILE_ALLOWED,
  ...INTEGRATION_FILE_ALLOWED,
  ...IMPORT_MTC_FILE_ALLOWED,
  ...WEBSERVICE_FORMDATA_FILE_ALLOWED,
  ...WEBSERVICE_RAW_FILE_ALLOWED,
  ...MANUAL_TEST_CASE_ALLOWED_EXTENSIONS_MIME,
};

export const VISUAL_TESTING_IMAGE_MIME_TYPE = ['image/jpeg', 'image/png', 'image/svg+xml'];

import MyInput from '@src/pages/common/Inputs/MyInput';
import React, { useContext, useState } from 'react';
import '../../auth.scss';
import '@pages/test-development/script/scripts/webservice/restapi/css/common_Webservice.scss';
import { ASSERT_CONSTANT } from '../../../assert/jsx/constant';
import { Icon, Tooltip } from 'fireflink-ui';
import { colors } from '@src/css_config/colorConstants';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/theme-github';
import 'brace/mode/javascript';
import { RestContext } from '../../../../restapi';

const AdvancedAlgorithmData = ({ jwtBearerChange, formik, ...props }) => {
  const { tokenPrefix, jwtHeaders, action } = formik.values;
  const context = useContext(RestContext);
  const [tokenPrefixValue, setTokenPrefixValue] = useState(tokenPrefix || '');
  const [jwtEditorValue, setJwtEditorValue] = useState(jwtHeaders || '');

  const {
    REQUEST_HEADER_PREFIX,
    QUERY_PARAM_NAME,
    QUERY_PARAM_NAME_PLACEHOLDER,
    REQUEST_HEADER,
    REQUEST_HEADER_PREFIX_PLACEHOLDER,
    REQUEST_HEADER_PREFIX_TOOLTIP,
    TOKEN_PREFIX,
    JWT_HEADER_EDITOR_NAME,
    ADVANCE_EDITOR_TEXT,
    JWT_HEADERS,
    JWT_HEADER_TOOLTIP,
    ADVANCE_TEXT,
  } = ASSERT_CONSTANT;

  const tokenPrefixChange = (e) => {
    const { value } = e.target;
    jwtBearerChange(e);
    setTokenPrefixValue(value);
  };

  const jwtEditorChange = (value) => {
    const event = {
      target: {
        name: JWT_HEADER_EDITOR_NAME,
        value,
      },
    };
    jwtBearerChange(event);
    setJwtEditorValue(value);
  };

  const headerBooleanValue = action?.includes(REQUEST_HEADER);

  return (
    <>
      <p className="fontPoppinsRegularSm mt-3 text-left pt- pl-2 heading-text">{ADVANCE_TEXT}</p>
      <div className="advance-underline"></div>

      <div className="p-2 flex hs-algo-container">
        <div className="relative">
          <div className="advance-input-tooltip">
            <Tooltip placement="right" title={REQUEST_HEADER_PREFIX_TOOLTIP}>
              <span>
                <Icon name="info_grey" width={14} height={14} color={colors.gray} />
              </span>
            </Tooltip>
          </div>
          <p className="input-filed-label fontPoppinsRegularSm text-left mb-2" onDragStart={props.handleDragStart}>
            {headerBooleanValue ? REQUEST_HEADER_PREFIX : QUERY_PARAM_NAME}
          </p>
          <MyInput
            canCreateVariable={false}
            type="text"
            variableInput={true}
            value={props?.getUserInputValue(tokenPrefixValue, true)}
            placeholder={headerBooleanValue ? REQUEST_HEADER_PREFIX_PLACEHOLDER: QUERY_PARAM_NAME_PLACEHOLDER}
            className="my-input-style-for-authorization jwt-advance-input"
            inputValue={'input'}
            name={TOKEN_PREFIX}
            onChange={tokenPrefixChange}
            error={formik.errors.secret && formik.touched.secret}
            onMouseDown={formik.handleBlur}
            currentValue={context.values.currentJwtBearerToken?.tokenPrefix}
            autocomplete="off"
            {...props}
          />
        </div>
        <div className="w-full">
          <div className="flex mb-1 items-center pl-4">
            <div className="flex gap-2 items-center">
              <span className="fontPoppinsRegularSm jwt-select-title">{JWT_HEADERS}</span>
              <Tooltip placement="right" title={JWT_HEADER_TOOLTIP}>
                <span>
                  <Icon name="info_grey" width={14} height={14} color={colors.gray} />
                </span>
              </Tooltip>
            </div>
          </div>
          <div className="ace-container">
            <AceEditor
              className="ace-editor"
              height="112px"
              width={'100%'}
              value={jwtEditorValue}
              name={JWT_HEADER_EDITOR_NAME}
              fontSize={14}
              onChange={jwtEditorChange}
              setOptions={{
                useWorker: false,
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2,
              }}
              wrapEnabled={true}
              highlightActiveLine={false}
            />
          </div>
          <p className="advance-header-text">{ADVANCE_EDITOR_TEXT}</p>
        </div>
      </div>
    </>
  );
};

export default AdvancedAlgorithmData;

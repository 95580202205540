import React, { useState, useEffect, useContext, useRef } from 'react';
import styles from './analytics.module.scss';
import cx from 'classnames';
import { FilterSection } from '../common/filters/filter-section';
import {
  getAnalyticsReq,
  getExecutionScriptsResult,
  getAutomationSuiteExecutionIds,
  getScriptResultReq,
} from '@api/api_services';
import moment from 'moment';
import { CommonLoader } from '../common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { ReactComponent as FilterOutline } from '@assets/analytics/FilterOutline.svg';
import { ReactComponent as FilterFilled } from '@assets/analytics/FilterFilled.svg';
import TestScriptDetails from './common/TestScriptDetails';
import { chartLabels, noDataMessage } from '@common/ui-constants';
import TestScriptTable from './common/testscriptTable';
import dateFormat from 'dateformat';
import LineChart from '../common/charts/linechart';
import ScriptTrendChart from './../common/charts/scriptTrend_chart';
import TopFailures from './common/TopFailures';
import { colors } from '@src/css_config/colorConstants';
import FailureAnalysisComp from './FailureAnalysisComp';
import FailureReasonsComp from './FailureReasonsComp';
import ExecutionComparisonSuite from './execution-comparison-suite';
import { isEmptyValue } from '@util/common_utils';
import { defaultDate, ANALYTICS_MESSAGE } from '@common/ui-constants';
import { getTableData, getTableName, TooltipPoppin } from './common/util';
import { Tooltip } from '@mui/material';
import '../results/result.scss';
import NoOfExecutionDetails from './common/NoOfExecutionDetails';
import { Chip } from 'fireflink-ui';
import { getCurrentProjectTimeZoneName } from '@src/util/common_utils';

function Analytics(props) {
  // TODO :: select analyticts suite to display by analyticsMode name
  const suiteMode = window.location.pathname.split('/')[2];
  let analyticsMode;
  if (suiteMode === 'automation-suite') {
    analyticsMode = 'Automation';
  } else if (suiteMode === 'manual-suite') {
    analyticsMode = 'Manual';
  }

  let [showFiltersList, setShowFiltersList] = useState(false);
  let [scriptsData, setScriptsData] = useState('');
  let [tagScripts, setTagScripts] = useState([]);
  let [showScripts, setShowScripts] = useState(false);
  let [scriptStatusList, setScriptStatusList] = useState([]);
  let [showFilterMenu, setShowFilterMenu] = useState(false);
  let [filterMenuData, setFilterMenuData] = useState([]);
  let [responseFilterData, setResponseFilterData] = useState([]);
  let [filteredSuiteList, setFilteredSuiteList] = useState([]);
  const [failures, setFailures] = useState([]);
  let [callApiFlag, setCallApiFlag] = useState(false);
  let [dataFound, setDataFound] = useState(false);
  let [isLoading, setIsLoading] = useState(true);
  const { resetProgress, startOrResumeProgress } = useContext(ProgressContainerContext);
  let timeZoneName = getCurrentProjectTimeZoneName();
  const [currentTimeRange, setCurrentTimeRange] = useState({
    to: moment().tz(timeZoneName)?.subtract(7, 'days').add(1, 'day').format('DD/MM/YYYY'),
    from: moment().tz(timeZoneName)?.format('DD/MM/YYYY'),
  });
  let [filterValues, setFilterValues] = useState({});
  let [checkedExecutionIds, setCheckedExecutionIds] = useState([]);
  let [checkedLabelsIds, setCheckedLabelsIds] = useState([]);
  let [suiteArray, setSuiteArray] = useState([]);
  let [callAnalytics, setCallAnalytics] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState({
    isSelected: false,
    selectedData: [],
    color: colors.rs_sussess,
    name: `PASSED Scripts`,
    count: 0,
  });
  let [reqBody, setReqBody] = useState(null);
  const [callTableData, setCallTableData] = useState(false);

  const [stopScrollApiCall, setStopScrollApiCall] = useState(0); //? to stop scroll api on count of totalScripts
  const testScriptTableRef = useRef();
  const [scrollPos, setScrollPos] = useState(false);
  const [initTable, setInitTable] = useState({
    from: 0,
    to: 20,
  });
  const [isFlakyScripts, setIsFlakyScripts] = useState(false);

  // NOTE :: Bleow state are used for MultiFrom Execution page
  const [executionResutlData, setExecutionResutlData] = useState([]);

  useEffect(() => {
    scriptStatusList.forEach((script) => {
      if (script.name === 'FLAKY' && script.count > 0) {
        setIsFlakyScripts(true);
      }
    });
  }, [scriptStatusList]);
  useEffect(() => {
    setReqBody(Object.keys(filterValues).length === 0 ? requestbody : filterValues);
  }, [filterValues]);

  useEffect(() => {
    if (scrollPos === true) {
      setInitTable({
        from: initTable.to,
        to: initTable.to + 10,
      });
    }
  }, [scrollPos]);

  const handleStatusClick = async (title, selected, color, count) => {
    setInitTable({
      from: 0,
      to: 20,
    });
    let header = getTableName(`${title} Scripts`);
    if (selectedStatus?.name !== `${title} Scripts`) {
      let data = await getTableData(reqBody, header, 0, 20);
      setStopScrollApiCall(data?.totalScripts);
      setSelectedStatus({
        isSelected: selected,
        selectedData: data?.scripts,
        color,
        name: `${title} Scripts`,
        count,
      });
    }
  };

  let checkboxLabel = 'checkbox';
  let filterHeaderData = {
    Suite: {
      Header: 'Suite',
      MenuType: 'menu',
      RequestKey: 'environment',
    },
    Environment: {
      Header: 'Environment',
      MenuType: 'checkbox',
      RequestKey: 'environment',
    },
    OS: {
      Header: 'Operating System',
      MenuType: 'checkbox',
      RequestKey: 'operatingSystem',
    },
    Browser: {
      Header: 'Browser',
      MenuType: 'checkbox',
      RequestKey: 'browser',
    },
    Device: {
      Header: 'Devices',
      MenuType: 'checkbox',
      RequestKey: 'deviceName',
    },
    Labels: {
      Header: 'Labels',
      MenuType: 'checkbox',
      RequestKey: 'labels',
    },
  };

  let requestbody = {
    module: 'analytics1',
    searchText: '',
    requestFilterRequired: 'true',
    responseFilterRequired: 'true',
    facetQueries: [
      {
        name: 'Date',
        values: [currentTimeRange.to, currentTimeRange.from],
      },
      {
        name: 'type',
        values: ['Script'],
      },
      {
        name: 'projectId',
        values: [JSON.parse(localStorage['selected-project']).id],
      },
      {
        name: 'licenseId',
        values: [JSON.parse(localStorage['test-optimize-user']).licenseId],
      },
      {
        name: 'userId',
        values: [JSON.parse(localStorage['test-optimize-user']).id],
      },
      {
        name: 'Mode',
        values: [analyticsMode],
      },
    ],
  };

  let resetAllStates = () => {
    setShowScripts(false);
    setResponseFilterData([]);
    setFilterMenuData([]);
    setFilteredSuiteList([]);
  };

  const handleTimePeriod = (to, from) => {
    resetAllStates(); // NOTE :: to reset Filter Section states

    let req = Object.keys(filterValues).length === 0 ? requestbody : filterValues;
    requestbody = req;
    req.facetQueries[0].values = [to, from];
    setCurrentTimeRange({
      to: to,
      from: from,
    });
    setFilterValues(req);
    if (to !== defaultDate && from !== defaultDate) {
      setCallAnalytics(true);
    }
  };

  const handleAll = (e, data) => {
    let filterData = {
      name: data.label,
      value: data?.categories?.map((x) => x.label),
      checked: e.target.checked,
    };
    handleFilterValues(filterData, data.parentGroup);
  };

  const handleFilterValues = (filterData, filterGroup) => {
    if (filterData.checked) {
      if (Array.isArray(filterData.value)) {
        filterData.value.forEach(() => {
          suiteArray.push(filterData.name);
        });
      } else {
        suiteArray.push(filterData.name);
      }
    } else {
      if (Array.isArray(filterData.value)) {
        filterData.value.forEach(() => {
          suiteArray.splice(
            suiteArray.findIndex((suite) => suite === filterData.name),
            1
          );
        });
      } else {
        suiteArray.splice(
          suiteArray.findIndex((suite) => suite === filterData.name),
          1
        );
      }
    }

    let filterAvailable = false;
    let filterValueIndex = '';
    let tempName = filterData.name;
    let tempValue = Array.isArray(filterData.value) ? filterData.value : [filterData.value];
    let req = Object.keys(filterValues).length === 0 ? requestbody : filterValues;
    requestbody = req;
    req.facetQueries.forEach((obj, index) => {
      if (obj.values.length > 0) {
        obj.values.forEach((obj1, index1) => {
          if (obj1 === filterData.value) {
            filterValueIndex = index1;
          }
        });
      }

      if (obj.name === 'executionId') {
        if (filterGroup !== undefined && filterGroup !== '') {
          filterAvailable = true;
          if (filterData.checked) {
            req.facetQueries[index].values = req.facetQueries[index].values.concat(tempValue);
          } else {
            let tempValueArray = req.facetQueries[index].values;
            req.facetQueries[index].values = tempValueArray.filter((val) => tempValue.indexOf(val) === -1);
          }
        }
      } else if (obj.name === 'suiteName') {
        if (filterGroup !== undefined && filterGroup !== '') {
          filterAvailable = true;
          if (filterData.checked) {
            if (req.facetQueries[index].values.indexOf(tempName) === -1) {
              req.facetQueries[index].values = req.facetQueries[index].values.concat(tempName);
            }
          } else {
            let tempSuiteArray = responseFilterData.facetSuiteData.filter((val) => val.name === tempName);
            let isFounded = req.facetQueries[index - 1].values.some((ai) => tempSuiteArray[0].values.includes(ai));
            if (!isFounded) {
              let tempValueArray = req.facetQueries[index].values;
              req.facetQueries[index].values = tempValueArray.filter((val) => val !== tempName);
            }
          }
        }
      } else {
        if (!filterAvailable) {
          if (obj.name === filterData.name) {
            filterAvailable = true;
            if (filterData.checked) {
              req.facetQueries[index].values = req.facetQueries[index].values.concat(tempValue);
            } else {
              req.facetQueries[index].values.splice(filterValueIndex, 1);
            }
          }
        }
      }
    });
    if (filterGroup !== undefined && filterGroup !== '') {
      if (!filterAvailable) {
        req.facetQueries.push({
          name: 'executionId',
          values: tempValue,
        });
        req.facetQueries.push({
          name: 'suiteName',
          values: [tempName],
        });
      }
    } else {
      if (!filterAvailable) {
        req.facetQueries.push({
          name: tempName,
          values: tempValue,
        });
      }
    }
    if (req.facetQueries) {
      let checkedIndices = req.facetQueries.find((facet) => facet['name'] === 'executionId');
      let checkedLabelIndices = req.facetQueries.find((facet) => facet['name'] === 'projectLabel');

      if (checkedIndices && checkedIndices['values']) {
        setCheckedExecutionIds(checkedIndices['values']);
      }
      if (checkedLabelIndices && checkedLabelIndices['values']) {
        setCheckedLabelsIds(checkedLabelIndices['values']);
      }
    }

    // NOTE :: Remove filter object who's Values are empty array on uncheck filter checkbox
    let getRemoveEmptyFilterValues = () =>
      req?.facetQueries?.filter((fiterValueObj) => !isEmptyValue(fiterValueObj?.values));
    req['facetQueries'] = getRemoveEmptyFilterValues();

    setCallAnalytics(true);
    setFilterValues(req);
    setCallApiFlag(true);
  };

  const getAnalyticsData = async () => {
    let req = Object.keys(filterValues).length === 0 ? requestbody : filterValues;
    requestbody = req;
    setReqBody(requestbody);
    resetProgress();
    startOrResumeProgress({
      stopAt: 95,
      intervalDuration: 10,
    });
    setInitTable({ from: 0, to: 20 });
    testScriptTableRef?.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    getAnalyticsReq(req)
      .then(async (response) => {
        if (response && response.data) {
          setScriptsData(response.data);
          setDataFound(response.data.dataFound);
          if (response.data.dataFound) {
            setCallTableData(true);
            setScriptStatusList(
              response.data?.resultSummary?.map((res, index) => {
                return { ...res, ...chartLabels[index] };
              })
            );

            setFailures(response.data.failures);
            setTagScripts(response.data.tagScripts);
            if (response.data.resultsFound) {
              if (response.data.resultSummary[0]?.count > 0) {
                let passedRes = await getTableData(req, 'PASSED SCRIPTS', 0, 20);
                setStopScrollApiCall(passedRes?.totalScripts); // setting the count to stop scrollApiCall when limit exists of totalScripts
                let newData = passedRes?.scripts ? passedRes?.scripts : [];
                setSelectedStatus({
                  isSelected: true,
                  selectedData: newData,
                  name: `PASSED Scripts`,
                  color: colors.rs_sussess,
                  count: response.data.resultSummary[0]?.count,
                });
              } else {
                setSelectedStatus({
                  isSelected: true,
                  selectedData: [],
                  name: `PASSED Scripts`,
                  color: colors.rs_sussess,
                  count: 0,
                });
              }
            }
            // TODO :: stop loader if dataFound: true
            setIsLoading(false);
            resetProgress();
            if (response.data.facetData.facetSuiteData !== null) {
              setShowScripts(true);
              setResponseFilterData(response.data.facetData);
              setFilterMenuData(createFiltersData(response.data.facetData));
              setFilteredSuiteList(response.data.facetData.facetSuiteData);
            } else {
              setFilterMenuData([]);
            }
            setShowFilterMenu(true);
            setShowFiltersList(true);
          } else {
            // TODO :: stop loader if dataFound :false (when no suite executed)
            setIsLoading(false);
            resetProgress();
          }

          setCallAnalytics(false);
          setCallApiFlag(false);
          getFilteredSuiteList();
          resetProgress();
        }
      })
      .catch((err) => {
        resetProgress();
        console.error('GET_ANALYTICS_REQ :', err);
      });
  };

  const createFiltersData = (data) => {
    let tempFilterData = [];
    tempFilterData[0] = {
      id: 'Menu1',
      label: filterHeaderData['Suite'].Header,
      type: filterHeaderData['Suite'].MenuType,
      categories: formSuiteData(data.facetSuiteData),
      uiLabel: filterHeaderData['Suite'].Header,
    };
    tempFilterData[1] = filtersDataHandler(
      `Menu2`,
      '',
      data?.facetEnvironmentData?.name,
      '',
      filterHeaderData['Environment'].MenuType,
      data?.facetEnvironmentData?.values,
      filterHeaderData['Environment'].Header
    );
    tempFilterData[2] = filtersDataHandler(
      `Menu3`,
      '',
      data?.facetOSData?.name,
      '',
      filterHeaderData['OS'].MenuType,
      data?.facetOSData?.values,
      filterHeaderData['OS'].Header
    );
    tempFilterData[3] = filtersDataHandler(
      `Menu4`,
      '',
      data?.facetBrowserData?.name,
      '',
      filterHeaderData['Browser'].MenuType,
      data?.facetBrowserData?.values,
      filterHeaderData['Browser'].Header
    );
    tempFilterData[4] = filtersDataHandler(
      `Menu5`,
      '',
      filterHeaderData['Device'].RequestKey,
      '',
      filterHeaderData['Device'].MenuType,
      data?.facetDeviceData?.values,
      filterHeaderData['Device'].Header
    );
    tempFilterData[5] = filtersDataHandler(
      `Menu6`,
      '',
      data?.facetProjectLabelData?.name,
      '',
      filterHeaderData['Labels'].MenuType,
      data?.facetProjectLabelData?.values,
      filterHeaderData['Labels'].Header
    );

    return tempFilterData;
  };

  const filtersDataHandler = (id, parentName, label, value, type, items, uiLabel, parentGroup) => {
    let tempObj = {};
    tempObj.id = id;
    tempObj.parentName = parentName;
    tempObj.label = label;
    tempObj.value = value !== undefined || value !== null ? value : '';
    tempObj.type = type !== undefined || type !== null ? type : '';
    tempObj.parentGroup = parentGroup !== undefined || parentGroup !== '' ? parentGroup : '';
    tempObj.uiLabel = uiLabel;
    if (items !== undefined && items.length > 0 && items[0] !== null) {
      tempObj.categories = items.map((obj, index) => {
        return filtersDataHandler(`${label}-${obj}`, label, obj, false, '', [], uiLabel, parentGroup);
      });
    }
    return tempObj;
  };

  const formSuiteData = (data) => {
    let tempSuiteData = [];
    tempSuiteData = data.map((obj, index) => {
      return filtersDataHandler(
        `SubMenu${index}`,
        filterHeaderData['Suite'].Header,
        obj.name,
        '',
        checkboxLabel,
        obj.values,
        filterHeaderData['Suite'].Header,
        filterHeaderData['Suite'].Header
      );
    });
    return tempSuiteData;
  };
  useEffect(() => {
    if (callAnalytics) {
      setCallTableData(false);
      getAnalyticsData();
    }
  }, [currentTimeRange, filterValues, callApiFlag]);

  useEffect(() => {
    let getScrollData = async () => {
      if (initTable.from !== 0 && initTable.to !== 20) {
        try {
          let tableName = getTableName(selectedStatus.name);

          if (stopScrollApiCall > initTable.from && selectedStatus?.count > 0) {
            let data = await getTableData(reqBody, tableName, initTable.from, initTable.to);
            setStopScrollApiCall(data?.totalScripts);
            let newData = data?.scripts ? data?.scripts : [];
            setSelectedStatus({
              ...selectedStatus,
              selectedData: [...selectedStatus.selectedData, ...newData],
            });
          }
        } catch (error) {
          console.error(error);
        }
      }
    };
    getScrollData();
  }, [initTable]);
  const [filteredStatus, setFilteredStatus] = useState('ALL');
  const [displayFilterOption, setDisplayFilterOption] = useState(false);

  const [dataList, setDataList] = useState([]);
  useEffect(() => {
    if (selectedStatus && selectedStatus.name === 'FLAKY Scripts') {
      if (Array.isArray(selectedStatus.selectedData)) {
        setDataList(selectedStatus.selectedData);
      }
    } else {
      setDataList([]);
    }
  }, [selectedStatus]);

  useEffect(() => {
    const updatedData = dataList.map((item) => {
      if (!isEmptyValue(item.result)) {
        const failObj =
          (filteredStatus === 'ALL' && item.status === 'FAIL' && item.result.find((resultObj) => resultObj.FAIL)) ||
          (filteredStatus === 'FAIL' && item.result.find((resultObj) => resultObj.FAIL));
        const failCount =
          (filteredStatus === 'ALL' && item.status === 'FAIL' && failObj ? failObj.FAIL.count : 0) ||
          (filteredStatus === 'FAIL' && failObj ? failObj.FAIL.count : 0);
        const failpercentage =
          (filteredStatus === 'ALL' && item.status === 'FAIL' && failObj ? failObj.FAIL.percent : 0) ||
          (filteredStatus === 'FAIL' && failObj ? failObj.FAIL.percent : 0);

        const warningObj =
          (filteredStatus === 'ALL' &&
            item.status === 'WARNING' &&
            item.result.find((resultObj) => resultObj.WARNING)) ||
          (filteredStatus === 'WARNING' && item.result.find((resultObj) => resultObj.WARNING));
        const warningCount =
          (filteredStatus === 'ALL' && item.status === 'WARNING' && warningObj ? warningObj.WARNING.count : 0) ||
          (filteredStatus === 'WARNING' && warningObj ? warningObj.WARNING.count : 0);
        const warningPercentage =
          (filteredStatus === 'ALL' && item.status === 'WARNING' && warningObj ? warningObj.WARNING.percent : 0) ||
          (filteredStatus === 'WARNING' && warningObj ? warningObj.WARNING.percent : 0);

        const passObj =
          (filteredStatus === 'ALL' && item.status === 'PASS' && item.result.find((resultObj) => resultObj.PASS)) ||
          (filteredStatus === 'PASS' && item.result.find((resultObj) => resultObj.PASS));
        const passCount =
          (filteredStatus === 'ALL' && item.status === 'PASS' && passObj ? passObj.PASS.count : 0) ||
          (filteredStatus === 'PASS' && passObj ? passObj.PASS.count : 0);
        const passPercentage =
          (filteredStatus === 'ALL' && item.status === 'PASS' && passObj ? passObj.PASS.percent : 0) ||
          (filteredStatus === 'PASS' && passObj ? passObj.PASS.percent : 0);
        const skippObj =
          (filteredStatus === 'ALL' && item.status === 'SKIP' && item.result.find((resultObj) => resultObj.SKIP)) ||
          (filteredStatus === 'SKIP' && item.result.find((resultObj) => resultObj.SKIP));
        const skipCount =
          (filteredStatus === 'ALL' && item.status === 'SKIP' && skippObj ? skippObj.SKIP.count : 0) ||
          (filteredStatus === 'SKIP' && skippObj ? skippObj.SKIP.count : 0);
        const skipPercentage =
          (filteredStatus === 'ALL' && item.status === 'SKIP' && skippObj ? skippObj.SKIP.percent : 0) ||
          (filteredStatus === 'SKIP' && skippObj ? skippObj.SKIP.percent : 0);

        const totalExecutions = item.noOfExecutions || 0;

        return {
          ...item,
          priority: failObj
            ? `${failCount}/${totalExecutions} FAIL`
            : warningObj
            ? `${warningCount}/${totalExecutions} WARNING`
            : skippObj
            ? `${skipCount}/${totalExecutions} SKIPP`
            : passObj
            ? `${passCount}/${totalExecutions} PASS`
            : '',
          percentage: failObj
            ? `${failpercentage}`
            : warningObj
            ? `${warningPercentage}`
            : passObj
            ? `${passPercentage}`
            : skippObj
            ? `${skipPercentage}`
            : '',
        };
      }
      return item;
    });
    if (JSON.stringify(updatedData) !== JSON.stringify(dataList)) {
      setDataList(updatedData);
    }
  }, [dataList, filteredStatus]);

  const sortedArray = [...dataList];
  const sorted = sortedArray.sort((a, b) => {
    const percentageA = parseFloat(a.percentage);
    const percentageB = parseFloat(b.percentage);
    return percentageB - percentageA;
  });
  function statusFilter() {
    const filterOptions = [
      { label: 'All', status: 'ALL', className: 'result-primary' },
      { label: 'Passed', status: 'PASS', className: 'result-passed' },
      { label: 'Failed', status: 'FAIL', className: 'result-failed' },
      { label: 'Warning', status: 'WARNING', className: 'result-warning' },
      { label: 'Skipped', status: 'SKIP', className: 'result-skipped' },
    ];

    return (
      <div
        className="status-filter-box absolute flex flex-col step-result mx-3 py-2 h-30"
        onClick={() => {
          setDisplayFilterOption(!displayFilterOption);
        }}
      >
        {filterOptions.map((option) => (
          <div
            key={option.status}
            className={`mt-1 ${option.className} filter-box ${
              filteredStatus === option.status ? 'selected-filter-box' : ''
            }`}
            onClick={() => {
              setFilteredStatus(option.status);
            }}
          >
            <div className="filter-label-box">{option.label}</div>
          </div>
        ))}
      </div>
    );
  }

  let testcasecolumns = React.useMemo(() => {
    let ListCol = [
      {
        Header: 'Name',
        accessor: 'scriptName',
        width: '30%',
        Cell: ({ row, value }) => {
          let { scriptId, executionId, scriptName, clientId, runId } = row.original;
          return (
            <div
              className={`overflow-hidden overflow-ellipsis whitespace-nowrap pr-3 rs-blue ${
                runId ? 'cursor-pointer' : 'cursor-default rs-skipped'
              }`}
              onClick={() => {
                if (runId && suiteMode === 'automation-suite') {
                  getScriptResultData(scriptId, executionId, clientId, runId, 'testScriptResultModal');
                  setSelectedScript({ scriptId, executionId });
                  setSelectedScriptName(scriptName);
                }
              }}
              title={value}
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Module',
        accessor: 'pathData',
        width: '20%',
        disableGlobalFilter: true,
        Cell: ({ value }) => {
          return (
            <div className="overflow-hidden overflow-ellipsis whitespace-nowrap pr-3" title={value?.path}>
              {value?.module}
            </div>
          );
        },
      },
      {
        Header: 'Last Executed On',
        accessor: 'executedOn',
        width: '20%',
        disableGlobalFilter: true,
        Cell: ({ value }) => {
          return <div className="overflow-hidden overflow-ellipsis whitespace-nowrap pr-1">{value}</div>;
        },
      },
      {
        Header: 'No of Execution',
        accessor: 'noOfExecutions',
        width: '20%',
        disableGlobalFilter: true,
        Cell: ({ row, value }) => {
          let { scriptId, scriptName } = row.original;
          return (
            <div
              className="overflow-hidden overflow-ellipsis whitespace-nowrap pr-1 rs-blue cursor-pointer"
              onClick={() => {
                if (suiteMode === 'automation-suite') {
                  setSelectedScriptName(scriptName);
                  openNoOFExecutionModal(scriptId);
                }
              }}
            >
              {value}
            </div>
          );
        },
      },
    ];
    if (selectedStatus?.name?.toLocaleLowerCase()?.includes('flaky scripts')) {
      const result = {
        Header: (
          <>
            <div className="flex flex-row relative">
              <span className="">Results</span>
              {isFlakyScripts && (
                <span>
                  {filteredStatus !== 'ALL' ? (
                    <Tooltip title="Filter" placement="top">
                      <div>
                        <FilterFilled
                          className="ml-3 mt-1 text-lg cursor-pointer filter-icon h-3 w-3"
                          onClick={() => setDisplayFilterOption(!displayFilterOption)}
                        />
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Filter" placement="top">
                      <div>
                        <FilterOutline
                          className="ml-3 mt-1 text-lg cursor-pointer filter-icon h-3 w-3"
                          onClick={() => setDisplayFilterOption(!displayFilterOption)}
                        />
                      </div>
                    </Tooltip>
                  )}
                  {displayFilterOption && statusFilter()}
                </span>
              )}
            </div>
          </>
        ),
        accessor: 'priority',
        width: '15%',
        disableGlobalFilter: true,
        Cell: ({ value }) => {
          return (
            <div
              className={`overflow-hidden overflow-ellipsis whitespace-nowrap pr-1  ${
                value?.includes('FAIL')
                  ? 'result-failed'
                  : value?.includes('WARNING')
                  ? 'result-warning'
                  : value?.includes('SKIPP')
                  ? 'result-skipped'
                  : value?.includes('PASS')
                  ? 'result-passed'
                  : ''
              }`}
            >
              {value}
            </div>
          );
        },
      };
      const percentage = {
        Header: 'Percentage',
        accessor: 'percentage',
        width: '15%',
        disableGlobalFilter: true,
        Cell: ({ value }) => {
          return <div style={{ marginLeft: '5px', height: '10px', width: '10px' }}>{value}</div>;
        },
      };

      ListCol.push(result);
      ListCol.push(percentage);
      return ListCol;
    } else {
      return ListCol;
    }
  }, [selectedStatus, displayFilterOption]);

  let categories = [];
  let finalcategories = [];
  let finalseries = [
    { name: 'Passed', data: [] },
    { name: 'Failed', data: [] },
    { name: 'Warning', data: [] },
    { name: 'Skipped', data: [] },
    { name: 'Flaky', data: [] },
  ];
  let series = [
    { name: 'Passed', data: [] },
    { name: 'Failed', data: [] },
    { name: 'Warning', data: [] },
    { name: 'Skipped', data: [] },
    { name: 'Flaky', data: [] },
  ];

  function linechart(testscriptdata) {
    let i, j;
    for (i = 0; i < testscriptdata?.dailyResultSummary?.length; i++) {
      categories.push(dateFormat(testscriptdata.dailyResultSummary[i]?.resultName, 'dd mmm yyyy'));

      for (j = 0; j < 5; j++) {
        if (testscriptdata.dailyResultSummary[i]?.resultItems[j].name === 'PASS Scripts') {
          series[0].data.push(testscriptdata.dailyResultSummary[i]?.resultItems[j].count);
        } else if (testscriptdata.dailyResultSummary[i]?.resultItems[j].name === 'FAIL Scripts') {
          series[1].data.push(testscriptdata.dailyResultSummary[i]?.resultItems[j].count);
        } else if (testscriptdata.dailyResultSummary[i]?.resultItems[j].name === 'WARNING Scripts') {
          series[2].data.push(testscriptdata.dailyResultSummary[i]?.resultItems[j].count);
        } else if (testscriptdata.dailyResultSummary[i]?.resultItems[j].name === 'SKIP Scripts') {
          series[3].data.push(testscriptdata.dailyResultSummary[i]?.resultItems[j].count);
        } else if (testscriptdata.dailyResultSummary[i]?.resultItems[j].name === 'FLAKY Scripts') {
          series[4].data.push(testscriptdata.dailyResultSummary[i]?.resultItems[j].count);
        }
      }
    }
  }

  linechart(scriptsData);

  let finalseriesOneDay = [
    { name: 'Passed', data: [0] },
    { name: 'Failed', data: [0] },
    { name: 'Warning', data: [0] },
    { name: 'Skipped', data: [0] },
    { name: 'Flaky', data: [0] },
  ];

  function linechartData(categories, series) {
    if (categories.length === 1) {
      finalcategories.push('');
      finalcategories.push(categories);
      finalseriesOneDay[0].data.push(series[0].data);
      finalseriesOneDay[1].data.push(series[1].data);
      finalseriesOneDay[2].data.push(series[2].data);
      finalseriesOneDay[3].data.push(series[3].data);
      finalseriesOneDay[4].data.push(series[4].data);
      finalseries = finalseriesOneDay;
    } else {
      finalcategories = categories;
      finalseries = series;
    }
  }
  linechartData(categories, series);

  // TODO :: Modal Operations & Scripts Step Result Modal Table :: START
  const [scriptResultData, setScriptResultData] = useState([]);
  let [openScriptResultModal, setOpenScriptResultModal] = useState(false);
  let [openFailureReasonResultModal, setOpenFailureReasonResultModal] = useState(false);
  let [openTopFailureRateModal, setOpenTopFailureRateResultModal] = useState(false);
  let [openFailureAnalysisResultModal, setOpenFailureAnalysisResultModal] = useState(false);
  let [scriptExecutionResultModal, setScriptExecutionResultModal] = useState(false);

  const [selectedScript, setSelectedScript] = useState({
    scriptId: '',
    executionId: '',
  });
  const [selectedScriptName, setSelectedScriptName] = useState('');

  let closeResultModal = () => {
    setOpenScriptResultModal(false);
    setOpenFailureReasonResultModal(false);
    setOpenTopFailureRateResultModal(false);
    setOpenFailureAnalysisResultModal(false);
    setScriptResultData([]);
    setSelectedScript({ scriptId: '', executionId: '' });
    setSelectedScriptName('');
    setScriptExecutionResultModal(false);
    setScriptExecutionResultModal(false);
  };

  // TODO :: Modal Operations & Scripts Step Result Modal Table :: END

  //TODO ::  api call to get Exexution scripts Result :: START

  const getScriptResultData = (scriptId, executionId, clientId, runId, openModalfor) => {
    if (executionId && scriptId && clientId && runId) {
      getScriptResultReq(executionId, clientId, runId, scriptId)
        .then((response) => {
          setScriptResultData(response.data.responseObject);
          if (openModalfor === 'testScriptResultModal') {
            setOpenScriptResultModal(true);
          } else if (openModalfor === 'failureReasonResultModal') {
            setOpenFailureReasonResultModal(true);
          } else if (openModalfor === 'failureAnalysisModal') {
            setOpenFailureAnalysisResultModal(true);
          } else if (openModalfor === 'topFailureRate') {
            setOpenTopFailureRateResultModal(true);
          }
        })
        .catch((error) => {
          console.error('Error during script result api call:', error);
        });
    }
  };
  //TODO ::  api call to get Exexution scripts Result :: END

  // TODO :: show filteredSuiteList in execuiton comparison dropdown based on selected suitename or ExecutionId in filter section
  let getFilteredSuiteList = () => {
    let suiteList = [];
    let checkData = requestbody?.facetQueries?.filter((val) => val.name === 'suiteName');
    if (checkData?.length > 0) {
      checkData[0]?.values?.forEach((selectedSuiteName) =>
        scriptsData.facetData.facetSuiteData.forEach((suite) => {
          if (suite.name === selectedSuiteName) {
            suiteList.push(suite);
          }
        })
      );
      setFilteredSuiteList(suiteList);
    }
  };

  let executionResultColumn = [
    {
      Header: 'Execution ID',
      accessor: 'executionId',
      width: '30%',
      Cell: ({ row, value }) => {
        let { executionId, runId, count } = row.original;
        let [withWhiteBackground, setWitWhiteBackground] = useState(false);
        const handleMouseEnter = () => {
          setWitWhiteBackground(true);
        };
        const handleMouseLeave = () => {
          setWitWhiteBackground(false);
        };
        return (
          <div className="grid grid-cols-2 items-center gap-1">
            <div
              className={`overflow-hidden overflow-ellipsis whitespace-nowrap pr-3 rs-blue ${
                runId ? 'cursor-pointer' : 'cursor-default rs-skipped'
              }`}
              onClick={() => {
                if (runId) {
                  setSelectedScript({ ...selectedScript, executionId: executionId });
                }
              }}
            >
              {value}
            </div>
            <div>
              <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <Chip label={count} size="single-count" variant="primary" withBackground={withWhiteBackground} />
              </div>
            </div>
          </div>
        );
      },
    },
    {
      Header: 'Suite Name',
      accessor: 'suiteName',
      width: '30%',
      Cell: ({ value }) => {
        return (
          <TooltipPoppin placement="top" title={value}>
            <div className="overflow-hidden overflow-ellipsis whitespace-nowrap pr-2">{value}</div>
          </TooltipPoppin>
        );
      },
    },
    {
      Header: 'Execution Type',
      accessor: 'executionType',
      width: '30%',
    },
    {
      Header: 'Machine',
      accessor: 'machineArray',
      width: '30%',
      Cell: ({ row }) => {
        let { machines } = row.original;
        let dispalyMachineName = [...new Set(machines)];
        let displayText =
          isEmptyValue(dispalyMachineName) || dispalyMachineName.every((name) => name === '')
            ? '---'
            : dispalyMachineName.toString();
        return (
          <TooltipPoppin placement="top" title={dispalyMachineName?.toString()}>
            <div className="overflow-hidden overflow-ellipsis whitespace-nowrap pr-2">{displayText}</div>
          </TooltipPoppin>
        );
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: '30%',
    },
    {
      Header: 'Execution Duration',
      accessor: 'executionDuration',
      width: '30%',
    },
  ];

  let openNoOFExecutionModal = async (scriptId) => {
    setSelectedScript({ ...selectedScript, scriptId: scriptId });
    setScriptExecutionResultModal(true);
    try {
      let executionIdsPayload = {
        ...reqBody,
        facetQueries: [
          ...reqBody.facetQueries,
          {
            name: 'scriptID',
            values: [scriptId],
          },
        ],
      };

      let resOne = await getAutomationSuiteExecutionIds(executionIdsPayload);
      if (resOne.data.responseObject.length) {
        setExecutionResutlData(resOne.data.responseObject);
      }
    } catch (err) {
      console.error('ERROR IN GET NO OF EXECUTION ID API', err);
    }
  };

  return (
    <section className={styles['analyticsPage']}>
      {isLoading ? (
        <CommonLoader />
      ) : dataFound ? (
        <article className={styles['analyticsBody']}>
          {/* Result section starts */}
          <div className={cx(showFiltersList ? 'w-9/12' : 'w-full', styles['scriptsBody'])}>
            {
              showScripts && scriptsData?.resultsFound > 0 ? (
                <>
                  {/* Status card starts */}
                  <div className={styles['statusBlock']}>
                    {scriptStatusList?.map((status) => {
                      let { name, count, color, content, iconBg } = status;

                      return (
                        <TestScriptDetails
                          key={`${name}-${count}`}
                          name={name}
                          count={count}
                          color={color}
                          content={content}
                          selectedStatus={selectedStatus}
                          iconBg={iconBg}
                          handleStatusClick={handleStatusClick}
                        />
                      );
                    })}
                  </div>
                  {/* Status card Block ends */}

                  {/* Test Script table starts */}
                  <div className={styles['testscriptTable']}>
                    <TestScriptTable
                      setScrollPos={setScrollPos}
                      testScriptTableRef={testScriptTableRef}
                      reqBody={reqBody}
                      columns={testcasecolumns}
                      data={
                        selectedStatus.name === 'FLAKY Scripts'
                          ? filteredStatus !== 'ALL'
                            ? sorted
                            : dataList
                          : selectedStatus?.selectedData
                          ? selectedStatus?.selectedData
                          : []
                      }
                      header={selectedStatus?.name}
                      color={selectedStatus?.color}
                      isShow={selectedStatus?.isSelected}
                      showfilter={showFiltersList}
                      scriptResultData={scriptResultData}
                      openScriptResultModal={openScriptResultModal}
                      closeResultModal={closeResultModal}
                      selectedScript={selectedScript}
                      selectedScriptName={selectedScriptName}
                      setSelectedStatus={setSelectedStatus}
                      filteredStatus={filteredStatus}
                    />
                  </div>
                  {scriptExecutionResultModal && (
                    <NoOfExecutionDetails
                      open={scriptExecutionResultModal}
                      onClose={closeResultModal}
                      columns={executionResultColumn}
                      data={executionResutlData}
                      showFilterOnFlaky={selectedStatus?.name?.toLocaleLowerCase()?.includes('flaky scripts')}
                      selectedScript={selectedScript}
                      setSelectedScript={setSelectedScript}
                      header={selectedStatus?.name}
                      selectedScriptName={selectedScriptName}
                      reqBody={reqBody}
                    />
                  )}

                  {/* Test script table ends */}

                  {/* Script trend chart starts */}
                  <div div className="ScriptTrendParentClass w-full">
                    <div className={styles['scriptTrendChartBlock']}>
                      <div className={styles['scriptTrendHeader']}>
                        <span className={cx('fontPoppinsSemiboldSm uppercase', styles['leftHeader'])}>
                          Scripts Trend
                        </span>
                        <span className={cx('fontPoppinsSemiboldXs', styles['rightHeader'])}>
                          {ANALYTICS_MESSAGE.scriptsTrendLabel}
                        </span>
                      </div>
                      <div>
                        {categories.length > 7 ? (
                          <LineChart series={finalseries} categories={finalcategories} showfilter={showFiltersList} />
                        ) : (
                          <ScriptTrendChart
                            series={finalseries}
                            categories={finalcategories}
                            showfilter={showFiltersList}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Script trend chart ends */}

                  {/* Top Failure starts */}
                  <TopFailures
                    callTableData={callTableData}
                    topFailuredata={scriptsData}
                    scriptResultData={scriptResultData}
                    openTopFailureRateModal={openTopFailureRateModal}
                    closeResultModal={closeResultModal}
                    selectedScript={selectedScript}
                    selectedScriptName={selectedScriptName}
                    getScriptResultData={getScriptResultData}
                    setSelectedScript={setSelectedScript}
                    setSelectedScriptName={setSelectedScriptName}
                    suiteMode={suiteMode}
                    reqBody={reqBody}
                  />
                  {/* top Failure ends */}

                  {suiteMode === 'manual-suite' ? null : (
                    <>
                      {/* Failure analysis starts  */}

                      <FailureAnalysisComp
                        callTableData={callTableData}
                        reqBody={reqBody}
                        failures={failures}
                        scriptResultData={scriptResultData}
                        openFailureAnalysisResultModal={openFailureAnalysisResultModal}
                        closeResultModal={closeResultModal}
                        selectedScript={selectedScript}
                        selectedScriptName={selectedScriptName}
                        getScriptResultData={getScriptResultData}
                        setSelectedScript={setSelectedScript}
                        setSelectedScriptName={setSelectedScriptName}
                        suiteMode={suiteMode}
                      />

                      {/* Failure analysis ends  */}

                      {/* Failure reasons start  */}

                      <FailureReasonsComp
                        callTableData={callTableData}
                        tagScripts={tagScripts}
                        scriptResultData={scriptResultData}
                        openFailureReasonResultModal={openFailureReasonResultModal}
                        closeResultModal={closeResultModal}
                        selectedScript={selectedScript}
                        selectedScriptName={selectedScriptName}
                        getScriptResultData={getScriptResultData}
                        setSelectedScript={setSelectedScript}
                        setSelectedScriptName={setSelectedScriptName}
                        suiteMode={suiteMode}
                        reqBody={reqBody}
                      />

                      {/* Failure reasons ends */}
                    </>
                  )}

                  {/* Execution comparison :: BEGIN */}
                  <ExecutionComparisonSuite data={filteredSuiteList} />
                  {/* Execution comparison :: BEGIN */}
                </>
              ) : (
                // Script not found for selected filter
                <div className={styles['flex-ana-container']}>
                  <div>
                    <div className={cx('fontPoppinsSemiboldLg', styles['flex-ana-item'])}>
                      {noDataMessage.testScriptNotFound}
                    </div>
                    <div className={cx('fontPoppinsSemiboldLg', styles['flex-ana-item'])}>
                      {noDataMessage.scriptsNotFoundForFilter}
                    </div>
                  </div>
                </div>
              )
              // Script not found for selected filter
            }
          </div>
          {/* Result section ends */}

          {/* Filter section starts */}
          <div className={cx(showFiltersList ? styles['checkedFilter'] : styles['uncheckedFilter'])}>
            <div
              className={cx(
                `fontPoppinsSemiboldLg flex flex-col items-center justify-between  ${
                  showFiltersList
                    ? styles['analytics_filters_header_open']
                    : styles[' analytics_filters_header_closed ']
                }`
              )}
            >
              <div className="flex justify-between items-center w-full">
                {showFiltersList ? <div className=" fontPoppinsSemiboldSm rs-blue ">Filters</div> : null}

                <button
                  className={styles['analytics_filter_icon']}
                  onClick={() => {
                    showFiltersList ? setShowFiltersList(false) : setShowFiltersList(true);
                  }}
                >
                  {showFiltersList ? (
                    <FilterOutline className={styles['analytics_filter_icon']} />
                  ) : (
                    <TooltipPoppin placement="top" title="Filter">
                      <FilterFilled className={styles['analytics_filter_icon']} />
                    </TooltipPoppin>
                  )}
                </button>
              </div>

              <div className="w-full overflow-scroll" style={{ display: `${showFiltersList ? 'Block' : 'none'}` }}>
                <div
                  className={
                    filterMenuData.length > 0
                      ? `rounded-lg  ${
                          scriptsData?.tagScripts?.[0]?.totalTaggedScripts !== 0 ? 'tag_filtermenu' : 'filtermenu'
                        }`
                      : 'rounded-lg '
                  }
                >
                  {showFilterMenu && (
                    <FilterSection
                      data={filterMenuData}
                      handleTimePeriod={handleTimePeriod}
                      handleFilterValues={handleFilterValues}
                      checkedExecutionIds={checkedExecutionIds}
                      checkedLabelsIds={checkedLabelsIds}
                      handleAll={handleAll}
                      suiteArray={suiteArray}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Filter section ends */}
        </article>
      ) : (
        <div className={styles['flex-ana-container']}>
          <div>
            <div className={cx('fontPoppinsSemiboldLg', styles['flex-ana-item'])}>
              {noDataMessage.testScriptNotFound}
            </div>
            <div className={cx('fontPoppinsSemiboldLg', styles['flex-ana-item'])}>
              {noDataMessage.scriptsNotCreated}
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default Analytics;

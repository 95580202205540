export const BITBUCKET_CONSTANTS = {
  BITBUCKET: 'Bitbucket',
  INSTANCE: 'Instance',
  QUICK_START: 'Quick Start',
  STEP_1_CLICK: 'Step1 : Click',
  ADDING_INSTANCE: 'to start adding the instance.',
  CREATE_INSTANCE: 'Create Instance',
  EDIT_INSTANCE: 'Edit Instance',
  SYNCHRONIZE_INSTANCE: 'Synchronize Instance',
  CREATE: 'Create',
  VERIFY: 'Verify',
  CANCEL: 'Cancel',
  SYNC: 'Sync',
  VERIFICATION: 'Verification',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  INSTANCE_NAME: 'Instance Name',
  INSTANCE: 'Instance',
  INSTANCE_DETAILS: 'Instance Details',
  USERNAME: 'UserName',
  WORKSPACE: 'Workspace',
  PROJECT: 'Project',
  REPOSITORY: 'Repository',
  FIRE_FLINK_PROJECT: 'Fireflink Project',
  SUITE_NAME: 'Suite Name',
  CREATED_ON: 'Created On',
  MODIFIED_ON: 'Modified On',
  CLOSE: 'Close',
  DELETE: 'Delete',
  EDIT: 'Edit',
  UPDATE: 'Update',
  DETAILS: ' Details',
  WARNING: 'Warning!',
  INSTANCE_ERROR: 'Instance with same name already exist.',
  REPOSITORY_ERROR: 'Repository with same name already exist.',
  DATA_LOST: 'Your data will be lost. Are you sure you want to delete',
  THIS_PROJECT: ' project?',
  PROJECT_NAME: 'Project Name',
  PROCEED: 'How do you want to proceed?',
  BUCKET_USERNAME: 'Bitbucket UserName',
  BUCKET_APP_PASSWORD: 'Bitbucket App Password',
  REPOSITORY_NAME: 'Repository Name',
  INVALID_USERNAME_APP_PASSWORD: 'Invalid Username Or App Password',
  SYNCHRONIZED_SUCCESSFULLY: 'Instance synchronized successfully',
  INSTANCE_UPDATED_SUCCESSFULLY: 'instance  updated succesfully',
  INSTANCE_CREATED_SUCCESSFULLY: 'instance  created succesfully',
  DELETED_SUCCESSFULLY: 'Instance  deleted succesfully',
  REQUIRED_ACCESS: ' Required Access:',
  ACCOUNT_READ: 'Account - Read',
  PROJECT_READ: ' Project - Read',
  REPOSITORY_ADMIN: ' Repository - Admin,write & Delete',
  PIPELINE_EDIT_VARIABLE: 'pipeline - Edit Variable',
  SELECT_FIREFLINK_PROJECT: 'Select Fireflink Project',
  SELECT_SUITE_NAME: 'Select Suite Name',
  SELECT_PROJECT: 'Select Project',
  SELECT_WORKSPACE: 'Select Workspace',
  ENTER_BITBUCKET_USER_NAME: 'Enter Bitbucket Username',
  ENTER_BITBUCKET_APP_PASSWORD: 'Enter Bitbucket App Password',
  ENTER_INSTANCE_NAME: 'Enter Instance Name',
  ENTER_REPOSITORY_NAME: 'Enter Repository Name',
  NO_INSTANCE_FOUND: 'No Instance found!!',
  INVALID_ACCESS: 'Invalid Access!!!',
  INVALID_APP_PASSWORD: 'Invalid App Password',
  REPOSITORY_NOT_FOUND: 'Repository not found, please create new instance',
  NO_OPTIONS: 'No options',
};
export const GITHUB_CONSTANTS = {
  GITHUB: 'Github',
  INSTANCE: 'Instance',
  QUICK_START: 'Quick Start',
  STEP_1_CLICK: 'Step1 : Click',
  ADDING_INSTANCE: 'to start adding the instance.',
  CREATE_INSTANCE: 'Create Instance',
  EDIT_INSTANCE: 'Edit Instance',
  SYNCHRONIZE_INSTANCE: 'Synchronize Instance',
  CREATE: 'Create',
  VERIFY: 'Verify',
  CANCEL: 'Cancel',
  SYNC: 'Sync',
  VERIFICATION: 'Verification',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  INSTANCE_NAME: 'Instance Name',
  INSTANCE: 'Instance',
  INSTANCE_DETAILS: 'Instance Details',
  USERNAME: 'UserName',
  WORKSPACE: 'Workspace',
  PROJECT: 'Project',
  REPOSITORY: 'Repository',
  FIRE_FLINK_PROJECT: 'Fireflink Project',
  SUITE_NAME: 'Suite Name',
  CREATED_ON: 'Created On',
  MODIFIED_ON: 'Modified On',
  CLOSE: 'Close',
  DELETE: 'Delete',
  EDIT: 'Edit',
  UPDATE: 'Update',
  DETAILS: ' Details',
  WARNING: 'Warning!',
  INSTANCE_ERROR: 'Instance with same name already exist.',
  REPOSITORY_ERROR: 'Repository with same name already exist.',
  DATA_LOST: 'Your data will be lost. Are you sure you want to delete',
  THIS_PROJECT: ' project ?',
  PROJECT_NAME: 'Project Name',
  PROCEED: 'How do you want to proceed?',
  GITHUB_USERNAME: 'Github Username',
  GITHUB_ACCESS_TOKEN: 'Github Access Token',
  REPOSITORY_NAME: 'Repository Name',
  INVALID_USERNAME_ACCESS_TOKEN: 'Invalid Username Or Access Token',
  SYNCHRONIZED_SUCCESSFULLY: 'Instance synchronized successfully',
  INSTANCE_UPDATED_SUCCESSFULLY: 'instance  updated succesfully',
  INSTANCE_CREATED_SUCCESSFULLY: 'instance  created succesfully',
  DELETED_SUCCESSFULLY: 'Instance  deleted succesfully',
  REQUIRED_ACCESS: ' Required Access:',
  ACCOUNT_READ: 'Account - Read',
  PROJECT_READ: ' Project - Read',
  REPOSITORY_ADMIN: ' Repository - Admin,write & Delete',
  PIPELINE_EDIT_VARIABLE: 'pipeline - Edit Variable',
  SELECT_FIREFLINK_PROJECT: 'Select Fireflink Project',
  SELECT_SUITE_NAME: 'Select Suite Name',
  SELECT_PROJECT: 'Select Project',
  SELECT_WORKSPACE: 'Select Workspace',
  ENTER_GITHUB_USER_NAME: 'Enter Github Username',
  ENTER_GITHUB_ACCESS_TOKEN: 'Enter GitHub access token',
  ENTER_INSTANCE_NAME: 'Enter Instance Name',
  ENTER_REPOSITORY_NAME: 'Enter Repository Name',
  NO_INSTANCE_FOUND: 'No Instance found!!',
  INVALID_ACCESS: 'Invalid Access!!!',
  INVALID_APP_PASSWORD: 'Invalid App Password',
  REPOSITORY_NOT_FOUND: 'Repository not found, please create a new instance',
  NO_OPTIONS: 'No options',
  REPOSITORY_FULL_ACCESS: 'Repository - Full Access',
  WORKFLOW_FULL_ACCESS: 'Workflow - Full Access',
  INVALID_ACCESS_TOKEN: 'Invalid access token',
  ORGANIZATION_FULL_ACCESS: 'Organization - Full Access',
  INVALID_ACCESS_TOKEN: 'Invalid access token',
  ORGANIZATION_NAME: 'Organization Name',
  SELECT_ORGANIZATIONS: 'Select Organizations',
  INVALID_ORGANIZATION: 'Unable to fetch organization',
};
export const AZURE_DEVOPS_CONSTANTS = {
  AZURE_DEVOPS: 'Azure DevOps',
  INSTANCE: 'Instance',
  ADDING_INSTANCE: 'to start adding the instance.',
  QUICK_START: 'Quick Start',
  STEP_1_CLICK: 'Step1 : Click',
  CLICK: 'Click',
  DETAILS: 'Details',
  INSTANCE_NAME: 'Instance Name',
  CREATE_INSTANCE: 'Create instance',
  EDIT_INSTANCE: 'Edit instance',
  SUITE_NAME: 'Suite Name',
  PROJECT_NAME: 'Project Name',
  CREATED_BY: 'Created by',
  CREATED_ON: 'Created On',
  MODIFIED_BY: 'Modified By',
  MODIFIED_ON: 'Modified On',
  DATA_CENTER: 'Data Center',
  CLOSE: 'Close',
  CANCEL: 'Cancel',
  CREATE: 'Create',
  DELETE: 'Delete',
  WARNING: 'Warning!',
  EDIT: 'Edit',
  UPDATE: 'Update',
  ENTER_INSTANCE_NAME: 'Enter Instance Name',
  SELECT_SUITE_NAME: 'Select Suite Name',
  SELECT_PROJECT_NAME: 'Select Project Name',
  INSTANCE_DETAILS: 'Instance Details',
  PROCEED: 'How do you want to proceed?',
  DATA_LOST: 'Your data will be lost. Are you sure you want to delete',
  CONTENT: 'Analytics, virtual, storage, computing, networking, and much more.',
  NO_OPTIONS: 'No options',
};
export const SLACK_CONSTANTS = {
  WORKSPACE_NAME: 'Workspace Name',
  CHANNEL_NAME: 'Channel Name',
  WEBHOOK_URL: 'Webhook URL',
  SLACK_CONFIGURATION: 'Slack Configuration',
  INSTANCE: 'Instance',
  QUICK_START: 'Quick Start',
  CHANEL_ID: 'Channel ID',
  CHANEL_NAME: 'Channel Name',
  CREATED_BY: 'Created By',
  CREATED_ON: 'Created On',
  ENTER_OAUTH: 'Enter Oauth Tokens(User/Bot)',
  ENTER_CHANEL_ID: 'Enter Channel Id',
  ENTER_CHANNEL_NAME: 'Enter Channel Name',
  STEP_1_CLICK: 'Step1 : Click',
  ADDING_INSTANCE: 'to start adding the instance.',
  CREATE_INSTANCE: 'Create Instance',
  EDIT_INSTANCE: 'Edit Instance',
  SYNCHRONIZE_INSTANCE: 'Synchronize Instance',
  CREATE: 'Create',
  VERIFY: 'Verify',
  CANCEL: 'Cancel',
  NEXT: 'Next',
  SYNC: 'Sync',
  VERIFICATION: 'Verification',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  INSTANCE_NAME: 'Instance Name',
  INSTANCE_DETAILS: 'Instance Details',
  USERNAME: 'UserName',
  OAUTH_TOKEN: 'Oauth Tokens(User/Bot)',
  PROJECT: 'Project',
  REPOSITORY: 'Repository',
  FIRE_FLINK_PROJECT: 'Fireflink Project',
  SUITE_NAME: 'Suite Name',
  MODIFIED_ON: 'Modified On',
  MODIFIED_BY: 'Modified By',
  VERIFIED: 'Verified',
  CLOSE: 'Close',
  DELETE: 'Delete',
  EDIT: 'Edit',
  UPDATE: 'Update',
  DETAILS: ' Details',
  WARNING: 'Warning!',
  INSTANCE_ERROR: 'Instance with same name already exist.',
  REPOSITORY_ERROR: 'Repository with same name already exist.',
  DATA_LOST: 'Your data will be lost. Are you sure you want to delete',
  THIS_PROJECT: ' project?',
  PROJECT_NAME: 'Project Name',
  PROCEED: 'How do you want to proceed?',
  BUCKET_USERNAME: 'Bitbucket UserName',
  BUCKET_APP_PASSWORD: 'Bitbucket App Password',
  REPOSITORY_NAME: 'Repository Name',
  INVALID_USERNAME_APP_PASSWORD: 'Invalid Username Or App Password',
  SYNCHRONIZED_SUCCESSFULLY: 'Instance synchronized successfully',
  INSTANCE_UPDATED_SUCCESSFULLY: 'instance  updated succesfully',
  INSTANCE_CREATED_SUCCESSFULLY: 'instance  created succesfully',
  DELETED_SUCCESSFULLY: 'Instance  deleted succesfully',
  REQUIRED_ACCESS: ' Required Access:',
  ACCOUNT_READ: 'Account - Read',
  PROJECT_READ: ' Project - Read',
  REPOSITORY_ADMIN: ' Repository - Admin,write & Delete',
  PIPELINE_EDIT_VARIABLE: 'pipeline - Edit Variable',
  SELECT_FIREFLINK_PROJECT: 'Select Fireflink Project',
  SELECT_SUITE_NAME: 'Select Suite Name',
  SELECT_PROJECT: 'Select Project',
  SELECT_WORKSPACE: 'Select Workspace',
  ENTER_BITBUCKET_USER_NAME: 'Enter Bitbucket Username',
  ENTER_BITBUCKET_APP_PASSWORD: 'Enter Bitbucket App Password',
  ENTER_INSTANCE_NAME: 'Enter Instance Name',
  ENTER_REPOSITORY_NAME: 'Enter Repository Name',
  NO_INSTANCE_FOUND: 'No Instance found!!',
  INVALID_ACCESS: 'Invalid Access!!!',
  INVALID_APP_PASSWORD: 'Invalid App Password',
  REPOSITORY_NOT_FOUND: 'Repository not found, please create new instance',
  NO_OPTIONS: 'No options',
  ON_SUITE_EXECUTION_START: 'On Suite Execution Start',
  ON_SUITE_EXECUTION_COMPLETION_OR_TERMINATION: 'On Suite Execution Completion / Termination',
  MANUALLY_TRIGGER_EMAIL_NOTIFICATION: 'Manually trigger email notification',
};

export const EMAIL_NOTIFICATION_CONSTANTS={
  ON_SUITE_EXECUTION_START: 'On Suite Execution Start',
  ON_SUITE_EXECUTION_COMPLETION_OR_TERMINATION: 'On Suite Execution Completion / Termination',
  MANUALLY_TRIGGER_EMAIL_NOTIFICATION: 'Manually trigger email notification',
}
import { Tooltip } from '@material-ui/core';
import { Sync } from '@material-ui/icons';
import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-modal';
import TableTree from './table_tree';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { envData } from '@src/common/ui-constants';
import { Menu, MenuItem } from '@mui/material';
import cx from 'classnames';
import { getPageTreeReq } from '@api/api_services';
import useEscapeKeyCloseModal from '@src/hooks/useEscapeKeyCloseModal';

const getContainerPillValue = (label, data) => {
  switch (label) {
    case 'Module':
      return 'M ' + data?.subModuleCount;
    case 'Script':
      return 'M ' + (data?.subModuleCount > 0 ? data?.subModuleCount : data?.moduleCountWithScript);
    case 'Folder':
      return 'F ' + data?.subFolderCount;
    case 'Page':
      return 'P ' + data?.subPageCount;
    case 'Library':
      return 'L ' + data?.subLibraryCount;
    case 'Screen':
      return 'S ' + data?.subPageCount;
    case 'Test Case':
      return 'M ' + (data?.subModuleCount > 0 ? data?.subModuleCount : data?.moduleCountWithScript);
    default:
      return '';
  }
};

const testCaseScriptCount = (label, data) => {
  if (label === 'Test Case') {
    if (data?.manualScriptCount) {
      return data?.manualScriptCount - data?.manualAndAutomationScriptCount;
    } else {
      const totalScriptCount = data?.children?.reduce((total, child) => {
          return total + (child?.children?.length || 0);
      }, 0);
      return totalScriptCount;
    }
  } else if (label === 'Script') {
    if (data?.automationScriptCount) {
      return data?.automationScriptCount - data?.manualAndAutomationScriptCount;
    } else {
      const totalScriptCount = data?.children?.reduce((total, child) => {
        return total + (child?.children?.length || 0);
      }, 0);
      return totalScriptCount;
    }
  } else {
    return data.scriptCount;
  }
};

const TreeModal = (props) => {
  const customStylesPage = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: 'fit-content',
      width: '645px',
      background: '#fbfcfd 0% 0% no-repeat padding-box',
      boxShadow: '0px 0px 11px #000000a1',
      borderRadius: '4px',
      opacity: '1',
    },
  };
  const [openModal, setOpenModal] = useState(true);
  const [selectedNode, setSelectedNode] = useState(props?.selectedNode ? props.selectedNode : []);
  const colDefs = [
    {
      field: 'title',
      title: ['Module', 'Script'].includes(props.label) ? 'Module' : props.label,
      class: 'title',
      isTitle: true,
      width: `calc(100% - 100px)`,
      render: (nodeObj) => {
        return (
          <>
            {nodeObj.isRootNode && (
              <>
                <span className="folder-count count-badge"> {getContainerPillValue(props.label, nodeObj.data)} </span>
                {!props.moduleSelection && (
                  <span className="file-count count-badge">S{testCaseScriptCount(props?.label, nodeObj?.data)}</span>
                )}
              </>
            )}
          </>
        );
      },
    },
    {
      field: 'scriptType',
      title: 'Type',
      class: 'Type  text-overflow-style',
      width: '70px',
      render: (nodeObj) => {
        return (
          <Tooltip placement="top-start" title={nodeObj.data.scriptType}>
            <span className="text-xs table-non-link-color-common capitalize">
              {nodeObj.data.scriptType?.toLowerCase()}
            </span>
          </Tooltip>
        );
      },
    },
  ];

  const onCheckedNodes = (node) => {
    setSelectedNode([...node]);
  };
  const handleSubmit = () => {
    props.handleNodeSelect(selectedNode);
    setOpenModal(false);
    props.closeModal(false);
  };

  useEscapeKeyCloseModal(openModal, () => {
    props.closeModal(false);
    setOpenModal(false);
  });

  return (
    <Modal
      isOpen={openModal}
      style={customStylesPage}
      onRequestClose={() => {
        props.closeModal(false);
        setOpenModal(false);
      }}
      className="p-px"
    >
      <div className="mt-2 p-1">
        <span className="Popup-header-common pl-3">Select {props.label}</span>
        <div className="float-right">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="ml-5 mr-5 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
      <div>
        <div className="border-b border-t border-blue-100 mt-px mb-px pb-3">
          <div className="mx-6 mt-3">
            {!props?.isLoading ? (
              !props.defect ? (
                <div className="overflow-auto modal-tree-height" id="journal-scroll">
                  <TableTree
                    showOnlyFolders={props.moduleSelection ? true : false}
                    defaultDisabled={props.defaultDisabled ? props.defaultDisabled : []}
                    defaultChecked={props.defaultCheckedNode ? props.defaultCheckedNode : []}
                    disableRootRadioButton={props.disableRootRadioButton ? props.disableRootRadioButton : false}
                    id={'radioBtnTree'}
                    data={props.data ? props.data : []}
                    rootData={props.rootData ? props.rootData : []}
                    colDefs={props.moduleSelection ? colDefs.slice(0, 1) : colDefs}
                    filter={''}
                    expandAll={false}
                    hideElements={props.hideElements}
                    hideElementsBtn={props.hideElementsBtn}
                    hideRootRadioBtn={props.hideRootRadioBtn}
                    hideConditions={true}
                    showCheckbox={false}
                    showRadioButton={true}
                    conditions={[
                      { key: 'type', value: 'PRE' },
                      { key: 'type', value: 'POST' },
                    ]}
                    hideConditionBtn={true}
                    onCheckedNodes={onCheckedNodes}
                    labels={props.labels}
                    onRootNodeClicked={() => {}}
                    onNodeSelected={() => {}}
                    scriptType={props?.scriptType}
                    toReturnEnvData={props.toReturnEnvData}
                    pageType={props.pageType}
                    location="tableTreeWithRadioBtn"
                    dataExist={props.dataExist}
                    noDataMsg={props?.noDataMsg}
                  />
                </div>
              ) : props?.data?.length > 0 || props?.rootData?.children ? (
                <div className="overflow-auto modal-tree-height" id="journal-scroll">
                  <TableTree
                    showOnlyFolders={props.moduleSelection ? true : false}
                    defaultDisabled={props.defaultDisabled ? props.defaultDisabled : []}
                    defaultChecked={props.defaultCheckedNode ? props.defaultCheckedNode : []}
                    disableRootRadioButton={props.disableRootRadioButton ? props.disableRootRadioButton : false}
                    id={'radioBtnTree'}
                    data={props.data ? props.data : []}
                    rootData={props.rootData ? props.rootData : []}
                    colDefs={props.moduleSelection ? colDefs.slice(0, 1) : colDefs}
                    filter={''}
                    expandAll={false}
                    hideElements={props.hideElements}
                    hideElementsBtn={props.hideElementsBtn}
                    hideRootRadioBtn={props.hideRootRadioBtn}
                    hideConditions={true}
                    showCheckbox={false}
                    showRadioButton={true}
                    conditions={[
                      { key: 'type', value: 'PRE' },
                      { key: 'type', value: 'POST' },
                    ]}
                    hideConditionBtn={true}
                    onCheckedNodes={onCheckedNodes}
                    labels={props.labels}
                    onRootNodeClicked={() => {}}
                    onNodeSelected={() => {}}
                    location="tableTreeWithRadioBtn"
                    dataExist={props.dataExist}
                    noDataMsg={props?.noDataMsg}
                  />
                </div>
              ) : (
                <div className="overflow-auto modal-tree-height flex justify-center align-middle fontPoppinsRegularXLg">
                  There is no module to select
                </div>
              )
            ) : (
              <div className="overflow-auto modal-tree-height"></div>
            )}
          </div>
        </div>
        <div className="float-right my-3 mx-5">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="gray-btn"
          >
            Cancel
          </button>
          <button
            type="button"
            className="primary-btn ml-3"
            onClick={handleSubmit}
            disabled={selectedNode?.length === 0}
          >
            Select
          </button>
        </div>
      </div>
    </Modal>
  );
};

const TreeWithRadioButton = (props) => {
  const scriptTypesForDropdown = [
    'iOS',
    'Android',
    'Web & Mobile',
    'Web & Mobile (Android & iOS)',
    'Web & Mobile (Android or iOS)',
    'Mobile (Android & iOS)',
    'Mobile (Android or iOS)',
  ];
  const [treeData, setTreeData] = useState([]);
  const [rootData, setRootData] = useState([]);
  const [selectedNode, setSelectedNode] = useState();
  const [selectedNodeName, setSelectedNodeName] = useState(
    props.operation === 'edit'
      ? props.placeholder
      : props.editDefect
      ? props.moduleName
      : props.rootLibrary
      ? 'Root Library'
      : ''
  );
  const [selectedNodePath, setSelectedNodePath] = useState('');
  const [isNodeSelected, setIsNodeSelected] = useState(
    props.operation === 'edit' || props.editDefect || props.rootLibrary ? true : false
  );
  const [defaultCheckedNode, setDefaultCheckedNode] = useState(props.defaultCheckedNode || []);
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [selectedEnv, setSelectedEnv] = useState({});
  const [open, setOpen] = useState(false);
  const anchorRef = useRef();
  const [isLoading, setisLoading] = useState(false);
  const [hideRootRadioBtn, setHideRootRadioBtn] = useState(props.hideRootRadioBtn ? props.hideRootRadioBtn : false);
  const handleNodeSelect = (nodeObj) => {
    if (nodeObj.length) {
      setIsNodeSelected(true);
      setSelectedNode(nodeObj);
      setDefaultCheckedNode([{ key: 'key', value: nodeObj[0].data._key }]);
      setSelectedNodeName(nodeObj[0].data.title);
      setSelectedNodePath(nodeObj[0].data.path);
      props.nodeSelected(nodeObj);
    }
  };
  const handleChooseNode = () => {
    setOpenSelectModal(true);
  };
  const closeModal = (value) => {
    setOpenSelectModal(value);
  };
  function getDefectName(props, selectedNodePath, selectedNodeName) {
    if (props.editDefect) {
      if (selectedNodePath) {
        return selectedNodePath?.split('/').pop()?.split(':').shift();
      }
    } else {
      return selectedNodeName;
    }
  }
  function getNameAndIcon() {
    return (
      <div className="table-non-link-color-common font-style-common flex">
        <Tooltip title={getDefectName(props, selectedNodePath, selectedNodeName)} placement="top">
          <p
            className={
              'ml-2 mr-3  cursor-pointer text-overflow-style  max-w-250' +
              (props?.parentSpecificClass?.overflowClass ? props?.parentSpecificClass?.overflowClass : 'widthSet')
            }
          >
            {getDefectName(props, selectedNodePath, selectedNodeName)}
          </p>
        </Tooltip>

        {props.operation !== 'edit' && !props.rootLibrary && (
          <button
            type="button"
            onClick={handleChooseNode}
            disabled={props.operation === 'edit'}
            className={`${props.operation === 'edit' ? 'opacity-50' : ''}`}
          >
            <Tooltip title="Replace" placement="top">
              <Sync color="primary" fontSize="small" />
            </Tooltip>
          </button>
        )}
      </div>
    );
  }

  useEffect(() => {
    if (props.moduleName === '') {
      setIsNodeSelected(false);
      setDefaultCheckedNode(props?.defaultCheckedNode);
    }
    if (props.editDefect) {
      setSelectedNodeName(props.moduleName);
      setSelectedNodePath(props.moduleName);
    }
  }, [props.moduleName, props.editDefect]);

  const getTreeData = (data) => {
    const tree = [...data];
    if (tree.length) {
      setRootData({ ...tree[0] });
      if ((props.individualTree && (!tree[0].title?.startsWith('Root') || !!tree[0].parentName))) {
        setTreeData([...tree]);
        setHideRootRadioBtn(true);
      } else {
        if (tree[0].children) {
          setTreeData([...tree[0].children]);
        } else {
          setTreeData([]);
        }
      }
    } else {
      setTreeData([]);
      setRootData([]);
    }
  };

  useEffect(() => {
    getTreeData(props.data);
  }, [props.data, props.individualTree]);

  const handleEnvChange = (data) => {
    getTreeData(data);
  };
  const projectDetails = JSON.parse(localStorage.getItem('selected-project'));
  useEffect(() => {
    if (['Web & Mobile', 'Web & Mobile (Android & iOS)', 'Web & Mobile (Android or iOS)'].includes(props.scriptType)) {
      setSelectedEnv({
        value: 'Web',
        label: 'Web',
      });
    } else if (['Mobile (Android & iOS)'].includes(props.scriptType)) {
      setSelectedEnv({
        value: 'Android',
        label: 'Android',
      });
    } else if (['Mobile (Android or iOS)'].includes(props.scriptType)) {
      setSelectedEnv({
        value: 'Mobile',
        label: 'Mobile',
      });
    } else {
      setSelectedEnv(envItems.find((ele) => ele.value === props.scriptType));
    }
  }, []);

  const handleSelectEnv = (env) => {
    setisLoading(true);
    getPageTreeReq(projectDetails.type, env.value).then((data) => {
      let treeData = data?.data?.responseObject?.pageTree;
      if (treeData) {
        handleEnvChange(treeData);
      } else {
        handleEnvChange([]);
      }
      setisLoading(false);
    });
    setSelectedEnv(env);
    props?.selectedPlatform(env);
    setOpen(false);
  };
  let pageType = props?.buttonLabel === 'Page' ? 'Page' : 'Screen';
  let envDisabled = false;
  if (projectDetails.type === 'Mobile' && props.scriptType !== 'Mobile (Android & iOS)') {
    envDisabled = ['iOS', 'Android', 'Mobile'].includes(selectedEnv?.value);
  } else if (
    ['Salesforce', 'Web & Mobile'].includes(projectDetails.type) &&
    !['Web & Mobile (Android & iOS)', 'Web & Mobile (Android or iOS)'].includes(props.scriptType)
  ) {
    envDisabled = ['Web'].includes(selectedEnv?.value);
  }
  let envItems = envData;
  if (props.scriptType === 'Web & Mobile' && projectDetails.platform === 'Android')
    envItems = envData.filter((ele) => ele.value !== 'iOS');
  else if (props.scriptType === 'Web & Mobile' && projectDetails.platform === 'iOS')
    envItems = envData.filter((ele) => ele.value !== 'Android');
  else if (props.scriptType === 'Android' && ['Web & Mobile', 'Salesforce'].includes(projectDetails.type))
    envItems = envData.filter((ele) => ['Android', 'MobileWeb'].includes(ele.value));
  else if (props.scriptType === 'iOS' && ['Web & Mobile', 'Salesforce'].includes(projectDetails.type))
    envItems = envData.filter((ele) => ['iOS', 'MobileWeb'].includes(ele.value));
  else if (
    props.scriptType === 'Mobile (Android & iOS)' &&
    ['Web & Mobile', 'Salesforce'].includes(projectDetails.type)
  )
    envItems = envData.filter((ele) => ['Android', 'iOS', 'MobileWeb'].includes(ele.value));
  else if (
    props.scriptType === 'Mobile (Android or iOS)' &&
    ['Web & Mobile', 'Salesforce'].includes(projectDetails.type)
  )
    envItems = envData.filter((ele) => ['Mobile', 'MobileWeb'].includes(ele.value));
  else if (props.scriptType === 'Web & Mobile (Android & iOS)')
    envItems = envData.filter((ele) => ['Web', 'Android', 'iOS', 'MobileWeb'].includes(ele.value));
  else if (props.scriptType === 'Web & Mobile (Android or iOS)')
    envItems = envData.filter((ele) => ['Web', 'Mobile', 'MobileWeb'].includes(ele.value));
  else if (props.scriptType === 'Mobile (Android & iOS)' && projectDetails.type === 'Mobile')
    envItems = envData.filter((ele) => ['Android', 'iOS'].includes(ele.value));

  let label = props.buttonLabel;
  if (props?.scriptType) {
    label = props?.buttonLabel === 'Page' ? 'Page' : 'Screen';
  }
  const toReturnEnvData = () => {
    return (
      <div className="parent-dropdown invisible">
        {!scriptTypesForDropdown.includes(props.scriptType) ||
        (props.scriptType !== 'Mobile (Android & iOS)' && ['Mobile'].includes(projectDetails.type)) ? (
          <p className="project-drop-button-web fontPoppinsRegularMd">{selectedEnv.value}</p>
        ) : (
          <button
            type="button"
            className={`project-drop-button fontPoppinsRegularMd open ${envDisabled ? 'btn_disabled' : ''}`}
            disabled={envDisabled}
            onClick={() => setOpen(!open)}
            ref={anchorRef}
          >
            <span className="flex items-center w-5/6">
              <Tooltip disableInteractive title={selectedEnv?.value || ''}>
                <span className="overflow-ellipsis overflow-hidden whitespace-nowrap ml-2">
                  {selectedEnv?.value || ''}
                </span>
              </Tooltip>
            </span>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </button>
        )}
        <Menu
          anchorEl={anchorRef.current}
          open={open}
          onClose={() => setOpen(false)}
          className="project-drop-box fontPoppinsRegularMd"
          disableEnforceFocus
        >
          {envItems.map((env, index) => (
            <Tooltip disableInteractive title={env.label} key={`switch-license-list-${env.label}-${index}`}>
              <MenuItem onClick={() => handleSelectEnv(env)} selected={env.value === selectedEnv?.value}>
                {env.label}
              </MenuItem>
            </Tooltip>
          ))}
        </Menu>
      </div>
    );
  };

  return (
    <div className="py-2">
      {isNodeSelected ? (
        getNameAndIcon()
      ) : (
        <button type="button" className="gray-btn" onClick={handleChooseNode}>
          Choose {props.buttonLabel}
        </button>
      )}
      {openSelectModal && (
        <TreeModal
          moduleSelection={props.moduleSelection}
          label={label}
          data={treeData}
          rootData={rootData}
          disableRootRadioButton={props.disableRootRadioButton}
          hideRootRadioBtn={hideRootRadioBtn}
          defaultCheckedNode={defaultCheckedNode}
          defaultDisabled={props.defaultDisabled}
          hideElements={props.hideElements}
          hideElementsBtn={props.hideElementsBtn}
          handleNodeSelect={handleNodeSelect}
          closeModal={closeModal}
          selectedNode={selectedNode}
          scriptType={props?.scriptType}
          toReturnEnvData={toReturnEnvData}
          isLoading={isLoading}
          moduleLevelScriptCount={props?.moduleLevelScriptCount}
          defect={props?.defect}
          pageType={pageType}
          dataExist={props.dataExist}
          noDataMsg={props?.noDataMsg}
        />
      )}
    </div>
  );
};

export default TreeWithRadioButton;

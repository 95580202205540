import React, { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ReactComponent as InfoIcon } from '@assets/svg-imports/info-icon-info-modal.svg';
import { useHistory } from 'react-router-dom';
import styles from './info-modal.module.scss';
import cx from 'classnames';

/*  title:String - Modal Title (optional field)
 body:Array[:String/:JSX.Element]  - Accepts array of strings/JSX elements rendered as body row   
 buttonLabel:String - primary button label
 closeModal:Function - secondary button/ close icon handler
 requestedPath:String  - on click of primary button redirects to this path */

const InformationModal = ({ title, body, buttonLabel, closeModal, requestedPath }) => {
  const [open, setOpenModal] = useState(true);
  const history = useHistory();
  const handlePrimaryBtnClick = () => {
    if (requestedPath !== '/defect') {
      localStorage.setItem('selected-project', JSON.stringify({ id: '', name: 'All Projects' }));
      setTimeout(() => {
        history.push(requestedPath);
      }, 100);
    } else {
      history.push(requestedPath);
    }
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={open}
        onClose={() => {
          closeModal(false);
          setOpenModal(false);
        }}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={cx(
                'inline-block  align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/4',
                styles['infoMargin']
              )}
            >
              <div className="inline-block float-left pl-16 pt-14 h-44 w-1/3">
                <div className=" ml-2">
                  <span className="pr-8">
                    <InfoIcon />
                  </span>
                </div>
              </div>
              <div className="inline-block h-44 w-2/3 pr-10">
                <div className="text-sm pt-14 font-sans text-gray-500">
                  <div className="text-3xl pb-4 fontPoppinsMediumPageSubHeader">
                    <span className={styles['infoMadalTitleInfoColour']}>Info! </span>
                    {title && <span className={styles['infoMadalTitleColour']}>{title}</span>}
                  </div>
                  {body.map((row) => (
                    <p className="pb-4 fontPoppinsRegularLg"> {row} </p>
                  ))}
                  <p className="fontPoppinsRegularLg"> How do you want to proceed?</p>
                </div>
              </div>
              <div>
                <div className={cx('mt-4 h-20', styles['infoModalFooter'])}>
                  <div className="pb-2 flex items-center mr-4 float-right h-20">
                    <button
                      type="button"
                      className=" gray-btn ml-1 float-right"
                      onClick={() => {
                        closeModal(false);
                        setOpenModal(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="ml-4 primary-btn float-right"
                      onClick={() => {
                        handlePrimaryBtnClick();
                        closeModal(false);
                        setOpenModal(false);
                      }}
                    >
                      {buttonLabel}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default InformationModal;

import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import AnalyticsTabRouter from './analytics-tab-router';
import {
  getLicenseFeatures,
  getCurrentProjectType,
  getAutomationProjectTypes,
  getPrivilege,
} from '@src/util/common_utils';

const AnalyticsRouter = (props) => {
  const history = useHistory();
  if (history.location.pathname === '/dashboard') {
    history.push({
      pathname: '/dashboard/project-dashboard',
      state: history?.location?.state,
    });
  }

  const licenseFeatures = getLicenseFeatures();
  const hasDefects = window.privilege.hasDefects(licenseFeatures);
  const hasAutomation = window.privilege.hasAutomation(licenseFeatures);
  const hasManualTestCase = window.privilege.hasManualTestCase(licenseFeatures);
  const hasCBasic = window.privilege.hasCBasic(licenseFeatures);
  let projectType = getCurrentProjectType();
  let isAutomationProject = getAutomationProjectTypes(getPrivilege())?.includes(projectType);

  return (
    <Switch>
      {
        <Route
          path="/dashboard/project-dashboard"
          exact={true}
          strict={true}
          key="project-dashboard"
          component={AnalyticsTabRouter}
        ></Route>
      }
      {((hasAutomation && isAutomationProject) || hasCBasic) && (
        <Route
          path="/dashboard/automation-suite"
          exact={true}
          strict={true}
          key="dashboard-default-page"
          component={AnalyticsTabRouter}
        ></Route>
      )}
      {(hasManualTestCase || hasCBasic) && (
        <Route
          path="/dashboard/manual-suite"
          exact={true}
          strict={true}
          key="manual-suite-page"
          component={AnalyticsTabRouter}
        ></Route>
      )}

      {(hasDefects || hasCBasic) && (
        <Route
          path="/dashboard/defects"
          exact={true}
          strict={true}
          key="defects-suite-page"
          component={AnalyticsTabRouter}
        ></Route>
      )}
      {<Route path="/dashboard/custom-dashboard" exact={true} strict={true} component={AnalyticsTabRouter}></Route>}
      {
        <Route
          path={`/dashboard/custom-dashboard/:graphName/customiseGraph`}
          exact={true}
          strict={true}
          component={AnalyticsTabRouter}
        ></Route>
      }
    </Switch>
  );
};
export default AnalyticsRouter;

import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { styled } from '@mui/material/styles';

const ClientStatusModal = (props) => {
  const [clientStatusMessage, setClientStatusMessage] = React.useState('');

  React.useEffect(() => {
    let clientStatus = props?.clientStatus;
    if (props?.executionStatus === 'Running') {
      if (props?.headless) {
        setClientStatusMessage('Running on headless');
      }
    } else if (props?.executionStatus === 'Terminated') {
      setClientStatusMessage('Waiting for the response');
    } else if (clientStatus) {
      if (clientStatus['clientMessage']) {
        setClientStatusMessage(clientStatus['clientMessage']?.message);
      } else {
        let tempClientMessage = '';
        if (Object.entries(clientStatus).length) {
          Object.entries(clientStatus).map(([key, value]) => {
            if (key != 'clientMessage' && value?.message) {
              tempClientMessage = tempClientMessage + value?.hostName + ':' + value?.message + '\n';
            }
          });
          setClientStatusMessage(tempClientMessage);
        }
        if (!tempClientMessage) {
          if (props.executionStatus === 'Terminated') {
            setClientStatusMessage('Waiting for the response');
          } else if (props?.executionStatus === 'Pending') {
            setClientStatusMessage('Checking for client status');
          }
        }
      }
    } else if (props?.executionStatus === 'Pending') {
      setClientStatusMessage('Checking for client status');
    }
  });

  const StatusTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} disableInteractive />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 250,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const statusTitle = () => {
    return (
      <>
        <div className="whitespace-pre-line">{clientStatusMessage}</div>
      </>
    );
  };

  return (
    clientStatusMessage && (
      <span>
        <StatusTooltip title={statusTitle()} placement="bottom">
          <InfoOutlinedIcon fontSize="small" className="text-blue-600 ml-3 text-xs" />
        </StatusTooltip>
      </span>
    )
  );
};

export default ClientStatusModal;

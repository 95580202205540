import React, { useState, useEffect } from 'react';
import { getSingleUserRequest } from '../../../api/api_services';
import { getCurrentLicenseId } from '@src/util/common_utils';

const UserDetailsPage = (props) => {
  const [userObj, setUserObj] = useState();
  const [userLicenseData, setUserLicenseData] = useState({});
  useEffect(() => {
    if (!userObj) {
      getSingleUserRequest(props.modifiedBy).then((results) => {
        setUserObj(results.data?.responseObject);
        let [data] = results.data?.responseObject?.licenses?.filter((value) => value.id === getCurrentLicenseId());
        setUserLicenseData(data);
      });
    }
  }, []);

  const getUserPrivilege = (userObj) => {
    const licenses = userObj.licenses;
    if (licenses && licenses.length) {
      const userLicense = licenses.find((lic) => lic.id === getCurrentLicenseId());
      return userLicense?.privilege || '--';
    }
    return '';
  };

  return (
    <div>
      <div className="grid grid-cols-2  mt-2 pb-3 ">
        <div>
          <label htmlFor="userName" className="block details-key-style-common">
            Name
          </label>
          <label
            className="details-data-style-common"
            title={userObj?.name}
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              maxWidth: '150px',
              float: 'left',
            }}
          >
            {userObj?.name}
          </label>
        </div>
        <div>
          <label htmlFor="emailId" className="block details-key-style-common">
            Email
          </label>
          <label className="details-data-style-common">{userObj && userObj?.emailId}</label>
        </div>
      </div>
      <div className="grid grid-cols-2  mt-2 pb-3 ">
        <div>
          <label htmlFor="privilege" className="block  details-key-style-common">
            Privilege
          </label>
          <label className="details-data-style-common">{userObj && getUserPrivilege(userObj)}</label>
        </div>

        <div>
          <label htmlFor="phoneNumber" className="block  details-key-style-common">
            Phone Number
          </label>
          <label className="details-data-style-common">
            {userObj && userObj.phoneNumbers?.primaryPhone ? userObj.phoneNumbers?.primaryPhone : <span>--</span>}
          </label>
        </div>
      </div>
      <div className="grid grid-cols-2  mt-2 pb-3 ">
        <div>
          <label htmlFor="activationStatus" className="block  details-key-style-common">
            Status
          </label>
          {userLicenseData ? (
            userObj && userObj?.activationStatus === 'ACTIVE' ? (
              <label className="label-success fontPoppinsRegularMd">{'Active'}</label>
            ) : userObj?.activationStatus === 'PENDING' ? (
              <label className="label-error fontPoppinsRegularMd">{'Activation Pending'}</label>
            ) : (
              <label className="text-gray-500 fontPoppinsRegularMd">{'Disabled'}</label>
            )
          ) : (
            <label className="fontPoppinsRegularMd">--</label>
          )}
        </div>
        <div>
          <label htmlFor="createdByUname" className="block  details-key-style-common">
            Created By
          </label>
          <label className="details-data-style-common">{userObj && userObj.createdByUname}</label>
        </div>
      </div>
      <div className="grid grid-cols-2  mt-2 pb-3 ">
        <div>
          <label htmlFor="createdOn" className="block  details-key-style-common">
            Created On
          </label>
          <label className="details-data-style-common">{userObj && userObj.createdOn}</label>
        </div>
        <div>
          <label htmlFor="modifiedByUname" className="block  details-key-style-common">
            Modified By
          </label>
          <label className="details-data-style-common">
            {userObj && userObj.modifiedByUname ? userObj.modifiedByUname : <span>--</span>}
          </label>
        </div>
      </div>
      <div className="grid grid-cols-2  mt-2 pb-3 ">
        <div>
          <label htmlFor="modifiedOn" className="block  details-key-style-common">
            Modified On
          </label>
          <label className="details-data-style-common">{userObj && userObj.modifiedOn}</label>
        </div>
      </div>
    </div>
  );
};

export default UserDetailsPage;

import React, { useContext, useState, useEffect, useMemo } from 'react';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FileUploadButton from '@pagescommon/fileUpload_button';
import { RestContext } from '@pages/test-development/script/scripts/webservice/restapi/restapi';
import '../css/table.css';
import '@pages/test-development/script/scripts/webservice/restapi/css/common_Webservice.scss';
import '@pages/test-development/script/scripts/webservice/restapi/css/restApi.scss';
import { TextField } from '@material-ui/core';
import '@pages/test-development/script/scripts/webservice/restapi/request/request_body/css/request_body.css';
import FileIcon from '@src/assets/FileIconRequestFormData.svg';
import MyInput from '@pagescommon/Inputs/MyInput';
import { getAllTestDataFileReq, getAllTestDataFiles } from '@api/api_services';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { colors } from '@src/css_config/colorConstants.js';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';
import CustomCheckBox from '@pages/test-development/script/scripts/webservice/CustomCheckBox';
import classNames from 'classnames';
import {
  isEmptyValue,
  renderFilePath,
  searchFilePathBasedOnHash,
  pathName,
  validateFileExtension,
} from '@util/common_utils';
import { WEBSERVICE } from '@src/pages/test-development/script/scripts/webservice/restapi/constants/constants';
import { HASH_LABELS } from '@common/ui-constants';
import { ClickAwayListener } from '@mui/base';
import { WEBSERVICE_FORMDATA_FILE_ALLOWED } from '@util/validations';

const useStyles = makeStyles({
  customModal: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    border: 'none',
  },
  paper: {
    width: 450,
  },
  customModalBodyContainer: {
    width: '100%',
    whiteSpace: 'normal',
    boxShadow: `0px 0px 4px ${colors.shadow_black}`,
    overflow: 'hidden',
    height: '89%',
    backgroundColor: colors.text_white,
  },
  customAutocomplete: {
    width: '100%',
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
      border: 'none',
    },
    '& .MuiAutocomplete-input': {
      border: 'none',
      cursor: 'pointer',
      maxWidth: '300px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '& .MuiInputBase-root': {
      '& input': {
        padding: '10px 10px',
      },
    },
  },
  radioOne: {
    marginTop: '25px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  radioTwo: {
    marginTop: '10px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

export default function DataRequestFormData(props) {
  const context = useContext(RestContext);
  const [formValues, setFormValues] = useState([...(context.values?.formData || [])]);
  const [isAllSelected, setAllSelected] = useState(false);
  const isSomeSelected = formValues.filter((item) => item.isEnabled);
  const [isFormUpdated, isSetFormUpdated] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [formValuesLength, setFormValuesLength] = useState(0);
  const [testData, setTestData] = useState([]);
  const [FromDataCrosshover, setFromDataCrosshover] = useState(false);
  const [onMouseHoverRow, setonMouseHoverRow] = useState(-1);
  const isIndeterminate = isSomeSelected.length && isSomeSelected.length < formValues.length;
  const isEditable = useMemo(() => window.permission?.isEditAccess('testDevelopment'), []);
  const { DEFAULT_FORM_DATA } = WEBSERVICE;

  useEffect(() => {
    const canAddButtonEnabled = !formValues.every((item) => item.name && item.value);
    const modifiedFormValues = formValues.filter((item) => item.name && item.value);
    const modifiedFormValuesLength = modifiedFormValues.length;
    setDisabled(canAddButtonEnabled);
    setFormValuesLength(modifiedFormValuesLength);
  }, [formValues]);

  useEffect(() => {
    getStepsUpdate();
  }, [props.selectedStepData]);

  const getStepsUpdate = () => {
    if (Object.keys(props?.selectedStepData)?.length > 0) {
      const tempParmvalue = JSON.parse(JSON.stringify(props.selectedStepData));
      if (tempParmvalue.bodyType === 'form-data') {
        if (tempParmvalue.fileParamMap && Object.keys(tempParmvalue.fileParamMap).length > 0) {
          const fileParamMapArr = [];
          for (const key in tempParmvalue.fileParamMap) {
            tempParmvalue.fileParamMap[key].map((item) => {
              if (item.type === 'text') {
                item.name = key;
              } else {
                item.value = item.name;
                item.name = key;
              }
              fileParamMapArr.push(item);
            });
          }
          const isSelected = fileParamMapArr.every((item) => item.isEnabled);
          setFormValues(fileParamMapArr);
          context.setFieldValue('formData', fileParamMapArr);
          setAllSelected(isSelected);
          isSetFormUpdated(true);
        }
      } else {
        context.values.formData = DEFAULT_FORM_DATA;
        setFormValues(DEFAULT_FORM_DATA);
      }
    }
  };

  const handleArrayValues = (fieldName, fieldValue, index) => {
    const myvalues = formValues.map((value, i) => {
      if (i === index && fieldName === 'value' && value?.value && value?.type === 'file' && isFormUpdated) {
        value.isModified = true;
        value.folderId = '';
        value.id = '';
      }

      if (i === index && fieldName === 'type') {
        value.value = '';
      }
      if (i === index && typeof fieldValue === 'object' && fieldValue?.parentId && fieldValue?.id) {
        const { parentId, id, name } = fieldValue;
        value.folderId = parentId;
        value.id = id;
        fieldValue = name;
        value.isModified = false;
      }
      return i === index ? { ...value, [fieldName]: fieldValue } : value;
    });
    setFormValues(myvalues);
    context.setFieldValue('formData', myvalues);
    props.handleSaveAsSteps(false);
  };
  let select = !isAllSelected;
  const selectAll = () => {
    const myvalues = formValues.map((item) => ({ ...item, isEnabled: select }));
    setFormValues(myvalues);
    context.setFieldValue('formData', myvalues);
    setAllSelected(select);
    props.handleSaveAsSteps(false);
  };
  const handleRemove = (id) => {
    let myvalues = [
      { isEnabled: false, isModified: false, isSelected: false, name: '', value: '', type: formValues[id]?.type },
    ];
    if (formValues.length > 1) {
      myvalues = formValues.filter((item, idx) => idx !== id);
    }
    setFormValues(myvalues);
    context.setFieldValue('formData', myvalues);
    props.handleSaveAsSteps(false);
  };
  const handleAdd = (row) => {
    if (row.name && (row?.value?.name || row.value)) {
      const myvalues = [
        ...formValues,
        { isEnabled: true, isModified: false, isSelected: false, name: '', value: '', type: 'text' },
      ];
      setFormValues(myvalues);
      context.setFieldValue('formData', myvalues);
      props.handleSaveAsSteps(false);
    }
  };

  const getAllTestDataFile = async () => {
    try {
      const selctedProjectDetails = JSON.parse(localStorage.getItem('selected-project'));
      const projectId = selctedProjectDetails.id;
      const response = await getAllTestDataFileReq(projectId);
      const getTestDataResponse = response.data.responseObject;
      if (getTestDataResponse && getTestDataResponse.length > 0) {
        setTestData(getTestDataResponse);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAllTestDataFile();
    return () => context.setFieldValue('currentFormDataValue', {});
  }, []);
  const handleMouseOver = (i) => {
    setonMouseHoverRow(i);
  };
  const handleMouseOut = () => {
    setonMouseHoverRow(-1);
  };
  return (
    <>
      <div className="page-table-body w-full request-url-form-height" id="journal-scroll">
        <div className="" id="journal-scroll">
          <table className=" relative w-full">
            <thead className="request-form-table-head  fontPoppinsSemiboldMd">
              <tr>
                <th className="w-1/6 text-left pl-4">
                  <span>
                    <CustomCheckBox
                      checked={isSomeSelected.length}
                      onChange={selectAll}
                      className="mr-2 request-parameter-checkbox"
                      indeterminate={isIndeterminate}
                    />
                  </span>
                  <span className="table-header-for-webservice ml-2" onDragStart={props.handleDragStart}>
                    Key Type
                  </span>
                </th>
                <th
                  className="w-2/6 pl-2 table-header-for-webservice text-left table-header-for-webservice"
                  onDragStart={props.handleDragStart}
                >
                  Key
                </th>
                <th
                  className="w-2/5 pl-3 table-header-for-webservice text-left table-header-for-webservice"
                  onDragStart={props.handleDragStart}
                >
                  Value
                </th>

                <th
                  className="table-header-for-webservice action-items-column-header table-header-for-webservice"
                  onDragStart={props.handleDragStart}
                >
                  <span>Action</span>
                </th>

                <th className="w-1/5 table-header-for-webservice" onDragStart={props.handleDragStart}>
                  <span>|</span>
                  <span className="ml-3 mr-1">Total:{formValuesLength}</span>
                </th>
              </tr>
            </thead>
            <tbody className="fromData-url-table-body-scroll">
              {formValues.length > 0 &&
                formValues.map((item, idx) => (
                  <TableRow
                    handleArrayValues={handleArrayValues}
                    values={item}
                    key={'form' + idx}
                    id={idx}
                    handleRemove={handleRemove}
                    handleAdd={handleAdd}
                    props={props}
                    testData={testData}
                    isEditable={isEditable}
                    setFromDataCrosshover={setFromDataCrosshover}
                    formValues={formValues}
                    isDisabled={isDisabled}
                    onMouseHoverRow={onMouseHoverRow}
                    handleMouseOver={handleMouseOver}
                    handleMouseOut={handleMouseOut}
                  />
                ))}
              <div className="h-4"></div>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

const TableRow = ({
  values,
  id,
  handleRemove,
  handleAdd,
  handleArrayValues,
  props,
  testData,
  isEditable,
  setFromDataCrosshover,
  formValues,
  onMouseHoverRow,
  handleMouseOver,
  handleMouseOut,
}) => {
  const context = useContext(RestContext);
  const [selectedType, setSelectedType] = useState(values.type || 'text');
  useEffect(() => {
    setSelectedType(values.type);
  }, [values]);
  const handleToggleChange = (e) => {
    if (selectedType !== e?.target?.value) {
      handleArrayValues('type', e.target.value, id);
      setSelectedType(e.target.value);
    }
  };

  const handleChange = (e) => {
    const { value, data, action, name, files } = e.target || 'unknown';
    if (files) {
      handleArrayValues(name, files[0], id);
    } else {
      const requestFormDataInput = name === 'name' ? value : props.getUserInputValue(value, false, data, action);
      handleArrayValues(name, requestFormDataInput, id);
    }
    const currentFormDataValue = { ...(context.values.currentFormDataValue || {}), [name]: e };
    context.setFieldValue('currentFormDataValue', currentFormDataValue);
  };
  const handleOnPasteInRequestFormData = (e) => {
    const requestFormData = e.clipboardData.getData('text');
    if (requestFormData === values.name) {
      props.handleSaveAsSteps(false);
    }
  };

  const isEnabledStyles = () => !values?.isEnabled && (!!values?.name || !!values?.value || formValues?.length > 1);

  return (
    <div>
      <tr
        className={`flex items-center common-input-hover-height ${
          onMouseHoverRow === id ? 'common-input-mouse-over' : 'common-input-mouse-out'
        } `}
        onMouseOver={() => handleMouseOver(id)}
        onMouseOut={handleMouseOut}
      >
        <td className="request-body-formdata-checkbox mt-1 mb-1 flex items-center pl-2">
          <span className="relative right-1">
            <CustomCheckBox
              checked={values?.isEnabled}
              onChange={() => handleArrayValues('isEnabled', !values.isEnabled, id)}
              className="checkBoxForm request-parameter-checkbox"
            />
          </span>
        </td>
        <td className="toggle-button-slider-type  w-1/6 pl-2">
          <ToggleButtonGroup
            value={selectedType}
            exclusive
            name="type"
            onChange={handleToggleChange}
            aria-label="Toggle Slider"
          >
            <ToggleButton
              value="text"
              aria-label="Text"
              style={{
                backgroundColor: selectedType === 'text' ? colors.button_blue_50 : undefined,
                color: selectedType === 'text' ? colors.text_white : undefined,
                fontFamily: 'Poppins-Regular',
                fontSize: '11px',
              }}
            >
              Text
            </ToggleButton>
            <ToggleButton
              value="file"
              aria-label="File"
              style={{
                backgroundColor: selectedType === 'file' ? colors.button_blue_50 : undefined,
                color: selectedType === 'file' ? colors.text_white : undefined,
                fontFamily: 'Poppins-Regular',
                fontSize: '11px',
              }}
            >
              File
            </ToggleButton>
          </ToggleButtonGroup>
        </td>
        <td className="request-body-form-data-key w-1/3">
          {' '}
          <MyInput
            className="request-name-textfield input custom-margin "
            type="text"
            autoComplete="off"
            name="name"
            value={values.name}
            inputValue={'input'}
            onChange={handleChange}
            placeholder="Enter form data key"
            onPaste={handleOnPasteInRequestFormData}
          />
        </td>
        <td className="request-body-form-data-value w-1/3">
          {selectedType === 'file' ? (
            <ChooseFile
              className="request-name-textfield input custom-margin"
              selectedType={selectedType}
              handleChange={handleChange}
              name="value"
              value={values}
              handleArrayValues={handleArrayValues}
              id={id}
              testData={testData}
              MyAlert={props?.MyAlert}
            />
          ) : (
            <MyInput
              canCreateVariable={isEditable}
              type="text"
              placeholder={'Enter form data value'}
              variableInput={true}
              value={!values?.value?.name ? props.getUserInputValue(values?.value, true) : ''}
              onChange={handleChange}
              autoComplete="off"
              name="value"
              fullWidth
              className={'request-name-textfield input custom-margin'}
              inputValue={'input'}
              currentValue={context.values.currentFormDataValue?.value}
              {...props}
            />
          )}
        </td>
        <td className="w-1/10 action-delete-add-icon-form-data">
          <span className="flex action-delete-button-form-data">
            <span className="mr-3">
              {values.isEnabled ? (
                <CustomTooltip title={'Delete'} placement="bottom">
                  <DeleteIcon
                    onMouseEnter={() => setFromDataCrosshover(true)}
                    onMouseLeave={() => setFromDataCrosshover(false)}
                    className={classNames('cursor-pointer', {
                      'opacity-100 text-gray-500 delete-icon-hover-effect delete-enable-effect': isEnabledStyles(),
                      'opacity-50 text-gray-400 p-0.5': !isEnabledStyles(),
                    })}
                    alt="showmore"
                    draggable="false"
                  />
                </CustomTooltip>
              ) : (
                <CustomTooltip title={'Delete'} placement="bottom">
                  <DeleteIcon
                    onMouseEnter={() => setFromDataCrosshover(true)}
                    onMouseLeave={() => setFromDataCrosshover(false)}
                    className={classNames('cursor-pointer', {
                      'opacity-100 text-gray-500 delete-icon-hover-effect delete-enable-effect': isEnabledStyles(),
                      'opacity-50 text-gray-400 p-0.5': !isEnabledStyles(),
                    })}
                    alt="showmore"
                    onClick={() => handleRemove(id)}
                    draggable="false"
                  />
                </CustomTooltip>
              )}
            </span>
            <span className="mr-4 w-4">
              {formValues.length - 1 === id && (
                <>
                  <CustomTooltip title="Add">
                    <AddIcon
                      onMouseEnter={() => setFromDataCrosshover(true)}
                      onClick={() => handleAdd(values)}
                      onMouseLeave={() => setFromDataCrosshover(false)}
                      className={`${values?.value && values?.name ? 'plus-enable-effect' : 'light-gray'}`}
                    />
                  </CustomTooltip>
                </>
              )}
            </span>
          </span>
        </td>
        <td className="w-1/6"></td>
      </tr>
    </div>
  );
};

const ChooseFile = ({ handleChange, value, handleArrayValues, id, testData, MyAlert }) => {
  let fileName = value?.value?.name || value?.value;
  const [showModal, setShowModal] = useState(false);
  const [showBtn, setShowbtn] = useState(true);
  const [testFileName, setTestFileName] = useState('');
  const [localFileName, setLocalFileName] = useState('');
  const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState('test-data');
  const [showFileSuggestion, setShowFileSuggestion] = useState(false);
  const [filePathArray, setFilePathArray] = useState([]);
  const [allFilePathArray, setAllFilePathArray] = useState([]);
  const typeOfModal = HASH_LABELS.WEB_SERVICE_TYPE_DROPDOWN;
  const [selectedContentType, setSelectedContentType] = useState('application/json');

  const showFilePaths = () => {
    setShowFileSuggestion(true);
  };
  const hideFilePaths = () => {
    setShowFileSuggestion(false);
  };
  const checkStartsWithHash = (e) => {
    const { inputValue, fileArrayOption, filteredArray } = searchFilePathBasedOnHash(
      e,
      filePathArray,
      allFilePathArray
    );
    if (fileArrayOption) {
      setSelectedOption(fileArrayOption.actualPath);
      hideFilePaths();
    } else if (!fileArrayOption) {
      if (inputValue.startsWith('#') && inputValue.length > 1) {
        showFilePaths();
        setFilePathArray(filteredArray);
      } else if (inputValue.startsWith('#')) {
        getAllFilePaths();
        showFilePaths();
      } else {
        setTestFileName(inputValue);
        hideFilePaths();
      }
    }
  };

  const getAllFilePaths = async (type) => {
    try {
      const response = await getAllTestDataFiles();
      const {
        data: { responseObject },
      } = response || {};
      if (responseObject?.length) {
        const folderPaths = responseObject.map((folder) => ({
          ...folder,
          path: pathName(folder.actualPath),
        }));
        setFilePathArray([...folderPaths]);
        setAllFilePathArray([...folderPaths]);
      } else {
        setFilePathArray([]);
      }
    } catch (error) {
      console.error('Get all file path :', error);
    }
  };

  const handleChooseFile = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  const setSelectedFile = async () => {
    setShowModal(false);
    if (selectedOption === 'test-data') {
      handleArrayValues('value', testFileName, id);
    } else {
      if (localFileName) {
        handleChange(localFileName);
      } else {
        handleChange({ target: { name: 'value', value: '' } });
      }
    }
  };
  const changeHandler = (e) => {
    if (e?.target?.name) {
      if (e?.target?.value) {
        const isValid = validateFileExtension(e, WEBSERVICE_FORMDATA_FILE_ALLOWED);
        if (isValid) {
          setLocalFileName(e);
          setShowbtn(true);
        } else {
          MyAlert.warning(`${e.target.files[0].name} is unsupported.`);
          setLocalFileName('');

        }
      }
    } else {
      setLocalFileName('');
      handleChange({ target: { name: 'value', value: '' } });
    }
  };

  const handleAutocompleteChange = (value) => {
    setTestFileName(value);
    hideFilePaths();
  };

  const onCancelChooseFile = () => {
    if (!fileName) {
      setTestFileName('');
      setLocalFileName('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <>
      <Modal open={showModal} className={`${classes.customModal} modal-dialog`}>
        <div className={`${classes.paper} page-modal-size1`}>
          <div className="modal-header-api">
            <div className="pl-2">
              <h2 className="title white fontPoppinsMediumSm">Choose File</h2>
            </div>
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                setShowModal(false);
                onCancelChooseFile();
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className={`${classes.customModalBodyContainer} modal-body`} id="journal-scroll">
            <form className="pl-4">
              <RadioGroup value={selectedOption} onChange={handleChooseFile} className="smaller-radio">
                <FormControlLabel
                  value="test-data"
                  control={<Radio className={classes.radioOne} size="small" color="primary" />}
                  label={
                    <div className="mt-2 width-380">
                      <div className="mt-2 text-sm text-blue-700">
                        <span className="fontPoppinsMediumSm choose-file-header">
                          {HASH_LABELS?.FILE_FROM_TEST_DATA}
                        </span>
                        <TextField
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => checkStartsWithHash(e)}
                          className={classes.customAutocomplete}
                          placeholder={HASH_LABELS?.SELECT_PATH}
                          variant="outlined"
                          value={selectedOption === 'local-drive' ? '' : testFileName?.name}
                          disabled={localFileName?.target?.files[0]?.name || selectedOption === 'local-drive'}
                          onKeyDown={handleKeyDown}
                        />
                        {showFileSuggestion && (
                          <ClickAwayListener onClickAway={hideFilePaths}>
                            <div className="absolute">
                              {renderFilePath(filePathArray, handleAutocompleteChange, typeOfModal)}
                            </div>
                          </ClickAwayListener>
                        )}
                      </div>
                    </div>
                  }
                />
                <FormControlLabel
                  value="local-drive"
                  control={<Radio className={classes.radioTwo} size="small" color="primary" />}
                  label={
                    <div className="fontPoppinsMediumMd flex items-center">
                      <span className="fontPoppinsRegularSm choose-file-header">File From Local Drive</span>
                      <FileUploadButton
                        name="value"
                        id="value"
                        onChange={changeHandler}
                        showBtn={showBtn}
                        setShowBtn={setShowbtn}
                        value={localFileName?.target?.files[0]?.name || (testFileName === '' ? fileName : '')}
                        disabled={testFileName || selectedOption === 'test-data'}
                        setLocalFileName={setLocalFileName}
                        webServiceUploadBtn={true}
                        localFileEvent={localFileName}
                      />
                    </div>
                  }
                />
              </RadioGroup>
            </form>
          </div>
          <div className="modal-footer-section">
            <div className="float-right">
              <button
                className=" modal-close-btn"
                onClick={() => {
                  setShowModal(false);
                  onCancelChooseFile();
                }}
              >
                Cancel
              </button>
              <button type="submit" className="fontMontserratMediumXs modal-save-btn" onClick={setSelectedFile}>
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div
        className={`choose-file-request-form-data relative group cursor-pointer fontPoppinsRegularSm w-full flex items-center hover-item flex-wrap`}
      >
        {fileName && (
          <img src={FileIcon} alt="fileIcon" className="form-data-choose-file-image w-2.5 mr-4 relative left-2" />
        )}
        <span
          onClick={handleOpen}
          className={classNames(
            {
              'select-file-primary-button-request-body-choose-file': isEmptyValue(fileName),
              'select-file-text': !isEmptyValue(fileName),
            },
            'flex justify-between w-full'
          )}
        >
          {fileName ? (
            <CustomTooltip title={fileName} placement="right">
              <span className="text-gray-500 pl-2 no-select-file-span fontPoppinsRegularLg">{fileName}</span>
            </CustomTooltip>
          ) : (
            'Choose file'
          )}
          {!fileName ? <AttachFileIcon className="choose-file-icon"></AttachFileIcon> : ''}
        </span>
        {fileName && fileName.length > 15 && (
          <CustomTooltip title={'Remove File'} placement="bottom">
            <span className="form-data-choose-remove-image auto-cols-min col-start-12 relative text-blue-700 text-xl cursor-pointer">
              <img
                className="Data"
                src="/assets/images/close_black1.svg"
                alt=""
                height="15px"
                width="15px"
                onClick={changeHandler}
              />
            </span>
          </CustomTooltip>
        )}
        {fileName && fileName.length < 15 && (
          <CustomTooltip title={'Remove File'} placement="bottom">
            <span className="form-data-choose-remove-image auto-cols-min col-start-12 relative text-blue-700 text-xl  cursor-pointer">
              <img
                className="Data"
                src="/assets/images/close_black1.svg"
                alt=""
                height="15px"
                width="15px"
                onClick={changeHandler}
              />
            </span>
          </CustomTooltip>
        )}
      </div>
    </>
  );
};

import { Field } from 'formik';
import { useState, useEffect, useContext } from 'react';
import CreateVariable from '../test-development/shared/variables/create-edit-variables';
import VariablesDetailsModal from '../test-development/shared/variables/variables-details';
import { makeStyles } from '@material-ui/core/styles';
import MyInput from './Inputs/MyInput';
import { ReactComponent as AddVariableIcon } from '../test-development/script/scripts/webservice/restapi/images/add-variable-icon.svg';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';
import { isEmptyValue } from '@src/util/common_utils';
import classNames from 'classnames';
import { colors } from '@src/css_config/colorConstants';
import { RestContext } from '../test-development/script/scripts/webservice/restapi/restapi';
import { UI_VALIDATIONS } from '@src/util/validations';
import { WEBSERVICE } from '../test-development/script/scripts/webservice/restapi/constants/constants';

const useStyles = makeStyles((theme) => ({
  tooltipAlignment: {
    marginTop: 5,
    overflow: 'hidden',
  },
}));

const VariableDropdown = ({ canCreateVariable = true, ...props }) => {
  const context = useContext(RestContext);
  let [selectedVariableType, setSelectedVariable] = useState(props?.variableDetails?.reference);
  let [variableName, setVariableName] = useState('');
  let [openCreateVariableModal, setOpenCreateVariableModal] = useState(false);
  let [editVariableData, setEditVariableData] = useState(null);
  let [openLocalVariableDetailsModal, setOpenLocalVariableDetailsModal] = useState(false);
  let [openGlobalProjectVariableDetailsModal, setOpenGlobalProjectVariableDetailsModal] = useState(false);
  let [openProjectEnvDetailsModal, setOpenProjectEnvDetailsModal] = useState(false);
  let [openStepGroupDetailsModal, setOpenStepGroupDetailsModal] = useState(false);
  let filterText = '';
  let isFilterText = '';
  const search = window.location.search;
  const libraryId = new URLSearchParams(search).get('parentId');
  const selectedProjectDetails = JSON.parse(localStorage.getItem('selected-project'));
  const selectedProjectStatus = selectedProjectDetails?.status;
  const classes = useStyles();
  const dataReturnValue = '${' + props?.dataValue + '}';
  const variableReturnValue = '${' + variableName + '}';
  const currentDollarPosition = localStorage.getItem('dollarPosition');
  const isVariableExistWithValue = props?.isExistVariable && props?.dataValue && props?.fieldLhs;
  const isUncheckedWithValue = props?.dataValue && !props.isEnabled && props?.fieldLhs;
  const isUncheckedWithEmptyValue = !props?.dataValue && !props.isEnabled;
  const isReturnDataExistWithValue =
    props?.dataValue &&
    props?.fieldLhs &&
    dataReturnValue !== variableReturnValue &&
    props?.getUserInputValue(dataReturnValue) !== dataReturnValue;
  const isNameWithStatusExists = props.data?.status && props.data?.name;
  const isUncheckedWithName = props.data?.name && !props.isEnabled;
  const isNameFieldUncheckedAndEmpty = !props.data?.name && !props.isEnabled;
  const ischeckedWithVariableData = props?.isExistVariable && props?.dataVal && props.isEnabled;
  const isEditVariableDataExists =
    typeof editVariableData === 'object' &&
    editVariableData &&
    isEmptyValue(variableName) &&
    isEmptyValue(props.data?.name);
  const { CAPTURE_DATA_LENGTH } = UI_VALIDATIONS;
  const { LOCAL_VAR_LABEL, GLOBAL_VAR_LABEL, PROJECT_ENV_VAR_LABEL, STEP_GROUP_VAR_LABEL, NO_VARIABLE_FOUND } =
    WEBSERVICE;

  const renderVariableNameTootlip = (data, index, variableType, classes) => {
    return (
      <div
        onMouseDown={() => {
          variableClick(data, variableType);
        }}
        id={'element' + index.toString()}
        key={index}
        className="cursor-pointer pl-1 pr-1 pt-1 pb-1 text-black hover:bg-rs-primary hover:text-white"
      >
        {data?.name?.length > CAPTURE_DATA_LENGTH ? (
          <CustomTooltip
            className="overflow-hidden"
            title={data?.name}
            key={index}
            placement="bottom"
            classes={{ tooltip: classes.tooltipAlignment }}
          >
            <p className="ml-3 truncate">{data?.name}</p>
          </CustomTooltip>
        ) : (
          <p className="ml-3 truncate">{data?.name}</p>
        )}
      </div>
    );
  };

  const renderFilteredByText = (filteredData, filterText, type) => {
    let filteredVariableDataRes = filteredData.map((data, index) => {
      if (filterText) {
        isFilterText = data?.name.toLowerCase().includes(filterText.toLowerCase());
      }
      if (isFilterText) {
        return renderVariableNameTootlip(data, index, type, classes);
      }
    });

    const filteredAllVariable = [
      ...props.localVariable,
      ...props.globalVariable,
      ...props.projectEnvVariable,
      ...props.stepGroupVariable,
    ];
    const isFilteredAllVariableExits = filteredAllVariable.filter((item) =>
      item?.name.toLowerCase().includes(filterText.toLowerCase())
    );
    if (isFilteredAllVariableExits.length === 0) {
      if (document.getElementById(props.id)) {
        document.getElementById(props.id).style.display = 'none';
      }
    }

    if (filteredVariableDataRes.length > 0 && filteredVariableDataRes.some((item) => typeof item === 'object')) {
      return filteredVariableDataRes;
    } else {
      return (
        <div className="ml-4 fontPoppinsRegularSm pointer-events-none common-color-variable-selector">
          {NO_VARIABLE_FOUND}
        </div>
      );
    }
  };
  const checkStartWithDollar = (e) => {
    setVariableName(e.target.value);
    if (e?.target?.value.length === 1 && e?.target?.value[0] === '$') {
      let inputFilterTextLen = e?.target?.selectionStart;
      localStorage.setItem('dollarPosition', inputFilterTextLen);
      showVariables();
    } else {
      if (document.getElementById(`create-${props.id}`)) {
        document.getElementById(`create-${props.id}`).style.display = 'inline-block';
      }
    }
    if (!e.target.value) {
      if (props.stepInputName) {
        props.removeStepRef();
      }
    }
  };

  const onSetInputValue = (name, type, data) => {
    if ((type === 'LOCAL' && data?.parentVariableType === 'STEPGROUP') || type === 'Step') {
      return '${SGV_' + name + '}';
    }
    switch (type) {
      case 'LOCAL':
        return '${LV_' + name + '}';
      case 'GLOBAL':
        return '${GV_' + name + '}';
      default:
        return '${PEV_' + name + '}';
    }
  };

  const variableClick = (data, type) => {
    const tempValue = onSetInputValue(data.name, type, data);
    const event = {
      target: {
        value: tempValue,
      },
    };
    props.onChange(event, true);
    setVariableName(data.name);
    props.handleCaptureError(false);
    setEditVariableData(data);
    props.setFieldValue(`${props?.stepInputName}`, data.name);
    if (data?.type === 'LOCAL' && data?.parentVariableType === 'STEPGROUP') {
      setSelectedVariable('STEP_GROUP');
    } else {
      setSelectedVariable(type);
    }
    document.getElementById(props.id).style.display = 'none';
    if (document.getElementById(`create-${props.id}`)) {
      document.getElementById(`create-${props.id}`).style.display = 'none';
    }
    if (type) {
      data['reference'] = type;
      if (props.setReturnValue) {
        props.setReturnValue(data, props?.stepInputName, type, props?.idx);
      }
    }
    if (props.setVariableValue) {
      props.setVariableValue(data, props?.stepInputName, 'variable');
    }
  };

  const showVariables = (e) => {
    document.getElementById(props.id).style.display = 'block';
  };

  const closeModal = (value) => {
    if (openLocalVariableDetailsModal) {
      setOpenLocalVariableDetailsModal(false);
    }
    if (openCreateVariableModal) {
      setOpenCreateVariableModal(value);
    }
    if (openGlobalProjectVariableDetailsModal) {
      setOpenGlobalProjectVariableDetailsModal(false);
    }
    if (openProjectEnvDetailsModal) {
      setOpenProjectEnvDetailsModal(false);
    }
    if (openStepGroupDetailsModal) {
      setOpenStepGroupDetailsModal(false);
    }
  };

  let _variableType = '';
  const viewEditVariableDetails = (type) => {
    if (
      !editVariableData ||
      !props?.dataValue?.includes(editVariableData?.name) ||
      !props?.dataVal?.includes(editVariableData?.name)
    ) {
      updateEditVariableData();
    }
    let _selectedVariableType = selectedVariableType || _variableType;
    if (type === 'view' && _selectedVariableType) {
      if (_selectedVariableType === 'LOCAL') {
        setOpenLocalVariableDetailsModal(true);
      } else if (_selectedVariableType === 'GLOBAL') {
        setOpenGlobalProjectVariableDetailsModal(true);
      } else if (_selectedVariableType === 'PROJECT_ENVIRONMENT') {
        setOpenProjectEnvDetailsModal(true);
      } else {
        setOpenStepGroupDetailsModal(true);
      }
    } else if (type === 'edit') {
      if (!props?.variableDetails.isSystemVariable) {
        setOpenCreateVariableModal(true);
      }
    }
  };
  const updateEditVariableData = () => {
    let data = props?.data?.name || props?.dataValue;
    if (props?.isExistVariable || data) {
      let name = data?.substring(5, data?.length - 1);
      let type = data?.substring(2, 4);
      let tempVar = null;
      if (type === 'LV') {
        tempVar = findVariableName(props.localVariable, name);
      } else if (type === 'GV') {
        tempVar = findVariableName(props.globalVariable, name);
      } else if (data?.type === 'LOCAL' && data?.parentVariableType === 'STEPGROUP') {
        setSelectedVariable('STEP_GROUP');
      } else {
        name = data?.substring(6, data?.length - 1);
        if (type === 'SG') {
          tempVar = findVariableName(props.stepGroupVariable, name);
        } else {
          tempVar = findVariableName(props.projectEnvVariable, name);
        }
      }
      _variableType = tempVar?.type;
      setEditVariableData(tempVar);
      setSelectedVariable(tempVar?.type);
      setVariableName(data);
    }
  };
  const createVariable = (e) => {
    setOpenCreateVariableModal(true);
    setEditVariableData(null);
  };

  const onChangeVariable = (data) => {
    let name = data.substring(5, data.length - 1);
    let type = data.substring(2, 4);
    let tempVar = null;
    if (type === 'LV') {
      tempVar = findVariableName(props.localVariable, name);
    } else if (type === 'GV') {
      tempVar = findVariableName(props.globalVariable, name);
    } else if (type === 'SG') {
      name = data.substring(6, data.length - 1);
      tempVar = findVariableName(props.stepGroupVariable, name);
    } else {
      name = data.substring(6, data.length - 1);
      tempVar = findVariableName(props.projectEnvVariable, name);
    }
    if (tempVar) {
      setVariableName(tempVar?.name);
      setEditVariableData(tempVar);
      props.setFieldValue(`${props?.stepInputName}`, tempVar?.name);
      if (tempVar?.type === 'LOCAL' && tempVar?.parentVariableType === 'STEPGROUP') {
        setSelectedVariable('STEP_GROUP');
      } else {
        setSelectedVariable(tempVar?.type);
      }
      variableClick(tempVar, tempVar?.type);
    }
  };

  const findVariableName = (variableData, name) => {
    return variableData.find((obj) => obj?.name === name);
  };

  const handleUserInputValue = (e) => {
    const { value, data, action } = e.target || 'unknown';
    props.handleChange(e);
    props.handleCaptureError(props.isEnabled);
    let isVariableExist = props.getUserInputValue(value, false, data, action);
    if (!isVariableExist?.includes('VAR')) {
      setEditVariableData(null);
    }
    if (isVariableExist !== value) {
      onChangeVariable(value);
    }
    checkStartWithDollar(e);
    props.onChange(e, false);
    if (props.removeStepRef && !props?.stepInputType.includes('com.tyss.optimize.nlp.util')) {
      props.removeStepRef();
    }

    if (props.setVariableValue && !props?.stepInputType.includes('com.tyss.optimize.nlp.util')) {
      props.setVariableValue(value, props?.stepInputName, 'hardcoded');
    }
  };

  if (props?.data?.name) {
    filterText = props.data?.name.substring(currentDollarPosition);
  }

  if (props?.dataValue) {
    filterText = props?.dataValue.substring(currentDollarPosition);
  }

  useEffect(() => {
    updateEditVariableData();
  }, [props?.dataValue]);

  return (
    <div
      className="text-xs text-blue-700 fontPoppinsRegularMd relative w-64 default-assert-field-response-time ml-4"
      id="hovered"
    >
      <Field name={props?.stepInputName}>
        {({ field, meta }) => {
          meta.touched && (context.values.captureResponseMetaError[`${props.metaError}`] = meta.touched);
          if (props?.isImportType !== 'import') {
            if (
              isVariableExistWithValue ||
              isUncheckedWithValue ||
              isUncheckedWithEmptyValue ||
              isReturnDataExistWithValue
            ) {
              props.handleCaptureError(false);
            } else if (
              isNameWithStatusExists ||
              isUncheckedWithName ||
              isNameFieldUncheckedAndEmpty ||
              ischeckedWithVariableData
            ) {
              props.handleCaptureError(false);
            } else if (isEditVariableDataExists) {
              props.handleCaptureError(false);
            } else {
              props.handleCaptureError(true);
            }
          }
          return (
            <div>
              <MyInput
                {...field}
                error={meta.touched && meta.error}
                autoFocus={false}
                autoComplete="off"
                onBlur={(e) => {
                  props.handleBlur(e);
                  document.getElementById(props.id).style.display = 'none';
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                label={
                  <div className="fontPoppinsMediumSm capture-assign-step-label">
                    <span className="text-red-400 mr-1">&#42;</span>
                    {props?.stepInputName
                      ? 'Assign Step Return Value To'
                      : props?.stepInputName.charAt(0).toUpperCase() + props?.stepInputName.slice(1)}
                  </div>
                }
                type="text"
                id={props?.stepInputName}
                className=" text-xs text-blue-700 w-full fontPoppinsRegularSm variable-dropdown-placeholder"
                value={props.data?.name || props?.dataValue}
                onChange={(e) => {
                  if (!e.target.value) {
                    if (document.getElementById(props.id)) {
                      document.getElementById(props.id).style.display = 'none';
                    }
                  }
                  handleUserInputValue(e);
                }}
                onPaste={(e) => {
                  handleUserInputValue(e);
                }}
                onFocus={(e) => {
                  if (!e?.target?.value) {
                    props.handleCaptureError(true);
                  }
                  if (e?.target?.value[0] === '$' && e?.target?.value.length === 1) {
                    document.getElementById(props.id).style.display = 'block';
                  }
                }}
                placeholder={'Search For Variable Using $'}
              />
              {(meta.touched || context.values.captureResponseMetaError[`${props.metaError}`]) &&
                !(props?.data?.name || props?.dataValue) && (
                  <div className="errorMessage">Return value is required</div>
                )}
              {!editVariableData?.id?.includes('VAR') &&
                (props?.data?.name || props?.dataValue) &&
                !props?.isExistVariable && <div className="errorMessage">Always use variable for return</div>}
            </div>
          );
        }}
      </Field>

      <div>
        {(props?.data?.name || props?.dataValue) &&
          !props?.isExistVariable &&
          canCreateVariable &&
          (!props?.isExistVariable ?? !editVariableData) &&
          selectedProjectStatus !== 'Closed' && (
            <CustomTooltip title="Create as variable">
              <AddVariableIcon
                id={`create-${props.id}`}
                className={`float-right cursor-pointer create-variable-icon-cdfr absolute top-7 -right-7 text-blue-700`}
                onClick={createVariable}
              />
            </CustomTooltip>
          )}
      </div>

      <div
        className=" relative border shadow rounded text-sm bg-white  text-left suggestion-dropdown hidden"
        id={props.id}
      >
        {filterText.length > 0 ? (
          <div className="cdfr-dropdown">
            {libraryId ? (
              <div className="mt-2">
                {<p className="ml-4 text-rs-primary fontPoppinsSemiboldMd">{STEP_GROUP_VAR_LABEL}</p>}
                {props?.stepGroupVariable.length > 0 ? (
                  renderFilteredByText(props.stepGroupVariable, filterText, 'LOCAL')
                ) : (
                  <div className="ml-4 fontPoppinsRegularSm pointer-events-none common-color-variable-selector">
                    {NO_VARIABLE_FOUND}
                  </div>
                )}
              </div>
            ) : (
              <div className="mt-2">
                {<p className="ml-4 text-rs-primary fontPoppinsSemiboldMd">{LOCAL_VAR_LABEL}</p>}
                {props?.localVariable.length > 0 ? (
                  renderFilteredByText(props.localVariable, filterText, 'LOCAL')
                ) : (
                  <div className="ml-4 fontPoppinsRegularSm pointer-events-none common-color-variable-selector">
                    {NO_VARIABLE_FOUND}
                  </div>
                )}
              </div>
            )}
            <div className="mt-2">
              {<p className="ml-4 text-rs-primary fontPoppinsSemiboldMd">{GLOBAL_VAR_LABEL}</p>}
              {props?.globalVariable.length > 0 ? (
                renderFilteredByText(props.globalVariable, filterText, 'GLOBAL')
              ) : (
                <div className="ml-4 fontPoppinsRegularSm pointer-events-none common-color-variable-selector">
                  {NO_VARIABLE_FOUND}
                </div>
              )}
            </div>
            <div className="mt-2">
              {<p className="ml-4 text-rs-primary fontPoppinsSemiboldMd">{PROJECT_ENV_VAR_LABEL}</p>}
              {props?.projectEnvVariable.length > 0 ? (
                renderFilteredByText(props.projectEnvVariable, filterText, 'PROJECT_ENVIRONMENT')
              ) : (
                <div className="ml-4 fontPoppinsRegularSm pointer-events-none common-color-variable-selector">
                  {NO_VARIABLE_FOUND}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="cdfr-dropdown">
            {libraryId ? (
              <div className="mt-2">
                <p className="ml-4 text-rs-primary fontPoppinsSemiboldMd">{STEP_GROUP_VAR_LABEL}</p>
                {props?.stepGroupVariable.length > 0 ? (
                  props?.stepGroupVariable.map((data, index) => {
                    return renderVariableNameTootlip(data, index, 'LOCAL', classes);
                  })
                ) : (
                  <div className="ml-4 fontPoppinsRegularSm pointer-events-none common-color-variable-selector">
                    {NO_VARIABLE_FOUND}
                  </div>
                )}
              </div>
            ) : (
              <div className="mt-2">
                <p className="ml-4 text-rs-primary fontPoppinsSemiboldMd">{LOCAL_VAR_LABEL}</p>
                {props?.localVariable.length > 0 ? (
                  props?.localVariable.map((data, index) => {
                    return renderVariableNameTootlip(data, index, 'LOCAL', classes);
                  })
                ) : (
                  <div className="ml-4 fontPoppinsRegularSm pointer-events-none common-color-variable-selector">
                    {NO_VARIABLE_FOUND}
                  </div>
                )}
              </div>
            )}
            <div className="mt-2">
              <p className="ml-4 text-rs-primary fontPoppinsSemiboldMd">{GLOBAL_VAR_LABEL}</p>
              {props?.globalVariable.length > 0 ? (
                props?.globalVariable.map((data, index) => {
                  return renderVariableNameTootlip(data, index, 'GLOBAL', classes);
                })
              ) : (
                <div className="ml-4 fontPoppinsRegularSm pointer-events-none common-color-variable-selector">
                  {NO_VARIABLE_FOUND}
                </div>
              )}
            </div>
            <div className="mt-2">
              <p className="ml-4 text-rs-primary fontPoppinsSemiboldMd">{PROJECT_ENV_VAR_LABEL}</p>
              {props?.projectEnvVariable.length > 0 ? (
                props?.projectEnvVariable.map((data, index) => {
                  return renderVariableNameTootlip(data, index, 'PROJECT_ENVIRONMENT', classes);
                })
              ) : (
                <div className="ml-4 fontPoppinsRegularSm pointer-events-none common-color-variable-selector">
                  {NO_VARIABLE_FOUND}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div>
        {(props?.isExistVariable || !!editVariableData) &&
          props?.variableDetails &&
          (props?.data?.name || props?.dataValue) && (
            <div>
              <span
                className={classNames('text-xs cursor-pointer float-left', {
                  [colors.blue_bright]: !props?.variableDetails.isSystemVariable,
                })}
                onClick={() => {
                  viewEditVariableDetails('view');
                }}
              >
                View variable details
              </span>
              <span
                className={classNames('text-xs cursor-pointer float-right', {
                  [colors.soft_grey]: props?.variableDetails.isSystemVariable,
                  [colors.blue_bright]: !props?.variableDetails.isSystemVariable,
                })}
                onClick={() => {
                  viewEditVariableDetails('edit');
                }}
              >
                Edit Variable
              </span>
            </div>
          )}
      </div>

      {/* modal to create and edit variable */}
      {openCreateVariableModal && (
        <CreateVariable
          returnValueScript={props?.setReturnValue ? true : null}
          data={editVariableData}
          closeModal={closeModal}
          variableName={variableName}
          variableType={libraryId ? 'Step' : 'LOCAL'}
          id={{ moduleId: props.moduleId, scriptId: props.scriptId }}
          variableClick={variableClick}
          type={libraryId ? 'Step Group' : props.type}
          onSetAllVariableName={props.onSetAllVariableName}
          MyAlert={props.MyAlert}
          dataValue={props.dataValue}
          variableDetails={props.variableDetails}
          isExistVariable={props.isExistVariable}
          cdfrResponseVariableUpdate={props?.cdfrResponseVariableUpdate}
        />
      )}

      {/* modal for variable details */}
      {openLocalVariableDetailsModal && <VariablesDetailsModal closeModal={closeModal} data={editVariableData} />}
      {openGlobalProjectVariableDetailsModal && (
        <VariablesDetailsModal closeModal={closeModal} data={editVariableData} />
      )}
      {openProjectEnvDetailsModal && <VariablesDetailsModal closeModal={closeModal} data={editVariableData} />}
      {openStepGroupDetailsModal && <VariablesDetailsModal closeModal={closeModal} data={editVariableData} />}
    </div>
  );
};

export default VariableDropdown;

import { FormHelperText, MenuItem, Select } from '@mui/material';
import style from '@pages/common/Inputs/inputs.module.scss';
import PropTypes from 'prop-types';
import { Label } from '@pages/common/Inputs/LabeledInput';
import { useEffect } from 'react';
import cx from 'classnames';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from '@material-ui/core';
import { colors } from '@src/css_config/colorConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    '&.Mui-selected': {
      backgroundColor: `${colors.rs_primary} !important`,
      color: 'white !important',
    },
  },
}));

function LabeledSelect({
  label,
  required,
  error,
  className,
  inputProps,
  wrapperClassName,
  placeholder,
  options,
  labelField,
  helperText,
  value,
  onChange,
  showLabelError,
  disabledMenuItemFunc,
  fontSize,
  ...props
}) {
  useEffect(() => {
    if (!placeholder && !value && options?.length) {
      onChange({ target: { value: options[0] } });
    }
  }, []);
  let classes = useStyles();

  return (
    <div
      className={cx(
        style['select-box-wrapper'],
        props?.page === 'signup' && style['signup_select_box'],
        fontSize,
        wrapperClassName
      )}
    >
      <Label label={label} required={required} error={error} showLabelError={showLabelError} />
      <Select
        className={fontSize}
        displayEmpty
        error={!!error}
        fullWidth
        sx={{ height: 32 }}
        IconComponent={KeyboardArrowDownIcon}
        MenuProps={{
          classes: {
            root: cx(style['select-box-menu-list-wrapper'], fontSize),

            ...props.MenuProps?.classes,
          },
          ...props.MenuProps,
          PaperProps: { sx: { width: '15%', padding: '16px 0' } },
        }}
        value={value || ''}
        onChange={onChange}
        renderValue={(selected) =>
          <span className={fontSize}>{selected?.[labelField]}</span> || (
            <span className={`opacity-25 ${fontSize}`}>{placeholder}</span>
          )
        }
        {...props}
      >
        {options.map((option, optionIndex) => (
          <MenuItem
            className={classes.root}
            key={`labeled-select-${label}-${optionIndex}`}
            value={option}
            disabled={disabledMenuItemFunc(option)}
          >
            <p className={cx(style['textWrap'], fontSize)} title={option[labelField]}>
              {option[labelField]}
            </p>
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={!!error} className={fontSize}>
        {helperText}
      </FormHelperText>
    </div>
  );
}

LabeledSelect.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  required: PropTypes.bool,
  error: PropTypes.any,
  className: PropTypes.string,
  inputProps: PropTypes.object,
  wrapperClassName: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  labelField: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  showLabelError: PropTypes.bool,
  disabledMenuItemFunc: PropTypes.func,
  fontSize: PropTypes.string,
};

LabeledSelect.defaultProps = {
  label: '',
  required: false,
  error: '',
  className: '',
  inputProps: {},
  wrapperClassName: '',
  placeholder: 'Select',
  options: [],
  labelField: 'label',
  helperText: '',
  showLabelError: false,
  value: null,
  disabledMenuItemFunc: () => false,
  fontSize: 'fontPoppinsRegularMd',
};

export default LabeledSelect;

import React from 'react';
import ReactTable from '@src/pages/common/Table/ReactTable';

function MyResultTable({ data, columns, analyticsClass, dataSetCheck }) {
  return (
    <ReactTable
      data={data}
      columns={columns}
      tableHeightClass={dataSetCheck ? 'overflow-auto' : 'modal-table-height'}
      analyticsClass={analyticsClass}
    />
  );
}

export default MyResultTable;

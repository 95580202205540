import cx from 'classnames';
import DashboardStyle from '../styles/dashboard.module.scss';

function StripedList({ listProperties, data }) {
  return (
    <div className={cx(DashboardStyle['striped-list'], 'flex flex-col h-full')}>
      {listProperties?.map((item, itemIndex) => (
        <div
          className={cx(
            'pl-6 whitespace-nowrap h-full flex flex-row items-center',
            DashboardStyle['label'],
            item?.itemClassName
          )}
          key={`striped-list-item-${item?.key}-${itemIndex}`}
          title={
            typeof item?.customTitle === 'function'
              ? item?.customTitle(data[item?.key], item)
              : `${item?.label} : ${data[item?.key]}`
          }
        >
          {item?.label} :
          <span className={cx('ml-1 flex-grow', DashboardStyle['value'], item?.valuesClassName)}>
            {' '}
            {data[item?.key] || '---'}
          </span>
        </div>
      ))}
    </div>
  );
}

export default StripedList;

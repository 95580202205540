/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, Fragment, useEffect } from 'react';
import { Route, Switch, useHistory, withRouter } from 'react-router';
import PackageParent from './components/program_elements/parent';
import PageParent from './components/project_elements/parent_page';
import LibraryParent from './components/step_groups/library_parent';
import { Menu, Transition } from '@headlessui/react';
import { MenuItem } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import { getAllRecordedElementReq, getPageTreeReq } from '@api/api_services';
import SharedElements from './components/project_elements/shared_recorded_elements/shared_elements/shared_elements';
import RecordedaElementPage from './components/project_elements/shared_recorded_elements/recorded_elements/recorded-element-page';
import { fontPoppins } from '@src/css_config/fontConstants';
import { colors } from '@src/css_config/colorConstants.js';
const useStyles = (platForm) =>
  makeStyles((theme) => ({
    select: {
      minWidth:
        platForm === 'Web'
          ? 30
          : platForm === 'Android' || platForm === 'Mobile'
            ? 40
            : platForm === 'Ios' || platForm === 'iOS'
              ? 23
              : 82,
      maxWidth: 82,
      borderRadius: 8,
      paddingRight: 0,
      cursor: 'pointer',
      color: colors.blue_dark,
      ...fontPoppins.rLg,
      '&:focus': {
        borderRadius: 12,
        background: 'none',
      },
    },
    menuPaper: {
      maxHeight: 200,
      ...fontPoppins.rMd,
      marginRight: '20px',
    },
    menulist: {
      ...fontPoppins.rMd,
      cursor: 'pointer',
      color: colors.blue_dark,
      '& .Mui-selected': {
        color: colors.blue_dark,
        background: colors.sky_blue_dark,
      },
      '& .Mui-selected:hover': {
        background: colors.sky_blue_dark,
        color: colors.blue_dark,
      },
    },
  }));
function RepositoryTabs(props) {
  const [platForm, setplatForm] = useState();
  const classes = useStyles(platForm);
  const [projectType, setProjectType] = useState('');
  const [dropDownData, setDropDownData] = useState([]);
  const [project, setProject] = useState(JSON.parse(localStorage.getItem('selected-project')));
  const [currentProjectType, setCurrentProjectType] = useState('');
  let [sharedElements, setSharedElements] = useState([]);
  let [recordedElements, setRecordedElements] = useState([]);
  let [projEleCount, setProjEleCount] = useState(0);
  let [projectElementCount, setProjectElementCount] = useState();
  const contextMenuData = [
    {
      option: 'Eclipse Plugin',
    },
  ];
  if (props.location.pathname.lastIndexOf('shared-element') > 25) {
    contextMenuData.splice(0, 3);
  }
  if (props.location.pathname.lastIndexOf('recorded-elements') > 0) {
    contextMenuData.splice(0, 4);
  }
  const search = window.location.search;
  const elementKey = new URLSearchParams(search).get('eleId');
  const eleName = new URLSearchParams(search).get('pageName');
  const history = useHistory();
  if (props.location.pathname === '/repository') {
    history.push('/repository/element/project_elements');
  }
  let activeTab = history.location.pathname.split('/')[2];
  let currentTab = history.location.pathname.split('/');
  if (!activeTab) {
    activeTab = 'element';
  }
  const paramsPath = props.match.params[0];
  let [elementName, setElementName] = useState();

  useEffect(() => {
    if (paramsPath?.split('/')[5] !== undefined && paramsPath?.split('/')[5] !== 'undefined') {
      setElementName(paramsPath?.split('/')[5]);
    }
    setProjectTypeFunction();
  }, [elementName]);

  function setProjectTypeFunction() {
    if (project.type === 'Web Service') {
      setLogoArray([
        {
          name: 'Program Elements',
          pathUrl: '/repository/program_elements',
          key: 'program-elements-root',
          selectedName: 'program_elements',
        },
        {
          name: 'Step Groups',
          pathUrl: '/repository/step_group',
          key: 'step-group-root',
          selectedName: 'step_group',
        },
      ]);
      history.push('/repository/program_elements');
    }
    let DropDownData = [];
    if (project.type === 'Mobile') {
      DropDownData.push({
        name: 'Android',
        value: 'Android',
      });
      DropDownData.push({
        name: 'iOS',
        value: 'iOS',
      });
      DropDownData.push({
        name: 'Mobile',
        value: 'Mobile',
      });
      setProjectType('Mobile');
      setplatForm('Android');
    } else if (project.type === 'Web') {
      DropDownData.push({
        name: 'Web',
        value: 'Web',
      });
      setProjectType('Web');
      setCurrentProjectType('Web');
      setplatForm('Web');
    } else if (
      ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(project?.type) &&
      project.platform.toLowerCase() === 'Android & Ios'.toLowerCase()
    ) {
      DropDownData.push({
        name: 'Web',
        value: 'Web',
      });
      DropDownData.push({
        name: 'Android',
        value: 'Android',
      });
      DropDownData.push({
        name: 'iOS',
        value: 'iOS',
      });
      DropDownData.push({
        name: 'Mobile',
        value: 'Mobile',
      });
      DropDownData.push({
        name: 'MobileWeb',
        value: 'MobileWeb',
      });
      if (project.type === 'Salesforce') {
        setProjectType('Salesforce');
      } else if (project.type === 'MS Dynamics') {
        setProjectType('MS Dynamics');
      } else {
        setProjectType('Web & Mobile');
      }
      setplatForm('Web');
    }
    setDropDownData(DropDownData);
  }
  const handeChange = (event) => {
    if (dropDownData.length > 1) {
    }
    if (event.target.value === 'Ios') {
      setplatForm('Ios');
    } else if (event.target.value === 'iOS') {
      setplatForm('iOS');
    } else if (event.target.value === 'Android') {
      setplatForm('Android');
    } else if (event.target.value === 'Web') {
      setplatForm('Web');
    } else if (event.target.value === 'MobileWeb') {
      setplatForm('MobileWeb');
    } else if (event.target.value === 'Mobile') {
      setplatForm(event.target.value);
    }
  };
  const [logoArray, setLogoArray] = useState([
    {
      name: 'Elements',
      pathUrl: `/repository/element/project_elements`,
      key: 'element-root',
      selectedName: 'element',
    },
    {
      name: 'Program Elements',
      pathUrl: '/repository/program_elements',
      key: 'program-elements-root',
      selectedName: 'program_elements',
    },
    {
      name: 'Step Groups',
      pathUrl: '/repository/step_group',
      key: 'step-group-root',
      selectedName: 'step_group',
    },
    {
      name: 'Shared Element',
      pathUrl: `/repository/element/shared-elements/${eleName}/shared-element?pageName=${eleName}&eleId=${elementKey}`,
      key: 'shared-element',
      selectedName: 'shared-element',
    },
    // {
    //   name: "History",
    //   pathUrl: `/repository/element/shared-elements/${elementName}/history`,
    //   key: "shared-history",
    //   selectedName: "history",
    // },
  ]);
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  useEffect(() => {
    if ((platForm !== undefined || projectType === 'Web') && projectType !== undefined) {
      if (
        history.location.pathname === '/repository/element/project_elements' ||
        history.location.pathname === '/repository/element' ||
        history.location.pathname === '/repository/element/shared-elements'
      ) {
        getAllElemtsReq(projectType, platForm);
      }
    }
  }, [platForm, projectType]);

  let getAllElemtsReq = async (projectType, platForm) => {
    let key;
    if (projectType !== 'Web') {
      key = platForm?.toLowerCase();
    } else {
      key = 'pages';
    }
    try {
      let response = await getPageTreeReq(projectType, platForm);
      setProjEleCount(response?.data?.responseObject?.totalElementCount);
      setSharedElements(response.data.responseObject?.totalSharedElementCount);
      setRecordedElements(response?.data?.responseObject?.totalRecordedElementCount);
    } catch (err) {
      console.log(err);
    }
  };

  const setProjectElementsCount = (count) => {
    setProjectElementCount(count);
  };
  const contextMenuVariableTabContent = [
    {
      name: 'Project Elements',
      count: projEleCount ? projEleCount : 0,
      path: `/repository/element/project_elements`,
    },
    {
      name: 'Shared Elements',
      count: sharedElements ? sharedElements : 0,
      path: `/repository/element/shared-elements`,
    },
    // COMMENTED FOR THIS RELEASE. FROM CLIENT SECTON THE RECORDED ELEMENTS WILL BE SENT SO AFTER COMPLITION OF THAT WILL COMPLETELY REMOVE REC ELEMENT
    // {
    //   name: "Recorded Elements",
    //   count: recordedElements ? recordedElements : 0,
    //   path: `/repository/element/recorded-elements`,
    // },
  ];

  const contextMenuTab = (optionList) => {
    return (
      <span className="inline-block">
        <Menu as="div" className="relative text-left fontPoppinsRegularMd">
          {({ open }) => (
            <>
              <span>
                <Menu.Button>
                  <span className="sr-only">Open options</span>
                  <img
                    src="/assets/images/arrow_drop_down_black.svg"
                    alt="DropDownArrow"
                    aria-hidden="true"
                    className="border-none font-bold text-sm"
                  />
                </Menu.Button>
              </span>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute -left-16 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none  z-10">
                  <div className="py-1">
                    {optionList.map((data) => (
                      <Menu.Item>
                        {({ active }) => (
                          <label
                            onClick={(event) => {
                              props.history.push(data.path);
                            }}
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-600' : 'text-gray-600',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            {data.name}
                            <span class="proj-count-style  px-2 py-0.5 ml-2 font-bold rounded-lg">{data.count}</span>
                          </label>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </span>
    );
  };

  const renderTabs = () => {
    return (
      <div className="page-tab-with-actions">
        <ul className="navBar text-blue-600 flex flex-row -ml-1 repo-dev-tab-height">
          {logoArray.map((tab, index) => {
            if (
              history.location.pathname.split('/')[5] === 'shared-element' ||
              history.location.pathname.split('/')[6] === 'history'
            ) {
              if (index !== 3 && index !== 4) {
                return null;
              }
            } else if (
              history.location.pathname.split('/')[5] !== 'shared-element' ||
              history.location.pathname.split('/')[6] !== 'history'
            ) {
              if (index === 3 || index === 4) {
                return null;
              }
            }
            return (
              <span key={tab.selectedName}>
                <span
                  className={
                    activeTab === tab.selectedName
                      ? 'flex flex-row p-2 pb-3  selected-repo-testdev-tab-style mr-4'
                      : 'flex flex-row p-2 pb-3  repo-testdev-tab-style mr-4'
                  }
                >
                  <a
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      props.history.push(tab.pathUrl);
                    }}
                  >
                    {tab.name}
                  </a>
                  {(currentTab[currentTab.length - 1] === 'repository' ||
                    currentTab[currentTab.length - 1] === 'shared-elements' ||
                    currentTab[currentTab.length - 1] === 'element' ||
                    currentTab[currentTab.length - 1] === 'recorded-elements' ||
                    currentTab[currentTab.length - 1] === 'project_elements') &&
                  tab.selectedName === 'element'
                    ? contextMenuTab(contextMenuVariableTabContent)
                    : null}
                </span>
                {tab.selectedName === 'element' && activeTab === tab.selectedName ? (
                  <hr className="bg-blue-700 ml-2 mr-7  selected-repo-tab-style -mt-5" />
                ) : null}
                {activeTab === tab.selectedName && activeTab !== 'element' ? (
                  <hr className="bg-blue-700 ml-2 mr-5  selected-repo-tab-style -mt-3" />
                ) : null}
              </span>
            );
          })}
        </ul>
      </div>
    );
  };
  return (
    <div className="page-list-body">
      <div className="page-tab-with-actions">
        {renderTabs()}
        <div className="ml-auto mt-1">
          {activeTab === 'element' ? (
            <>
              {dropDownData.length > 1 ? (
                <Select
                  classes={{ root: classes.select }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    classes: {
                      paper: classes.menuPaper,
                      list: classes.menulist,
                    },
                    getContentAnchorEl: null,
                  }}
                  value={platForm}
                  disableUnderline
                  onChange={handeChange}
                  style={{ color: colors.blue_dark }}
                >
                  {dropDownData.map((data, index) => {
                    return (
                      <MenuItem
                        key={index}
                        classes={classes.menuItem}
                        value={data.value}
                        sx={{ color: colors.blue_dark }}
                      >
                        {data.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              ) : (
                <span>
                  <p className="project-type">{currentProjectType || projectType}</p>
                </span>
              )}
            </>
          ) : null}
          <div className="float-right ml-5 mt-2 mr-1" style={{ marginTop: '3px' }}>
            <Menu as="div" className="relative inline-block text-left">
              {({ open }) => (
                <>
                  <div>
                    <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none">
                      <span className="sr-only">Open options</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        fill="#000000"
                      >
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                      </svg>
                    </Menu.Button>
                  </div>

                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="z-10 origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <div className="py-1">
                        {contextMenuData.map((element, index) => (
                          <Menu.Item key={index}>
                            {({ active }) => (
                              <button
                                type="button"
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'block w-full text-left px-4 py-2 fontPoppinsRegularMd'
                                )}
                              >
                                {element.option === 'Web Element Locator Plugin' ? (
                                  <a href="/assets/ele_loc_plugin/Web_Element_Locator_Plugin.zip" type="download">
                                    {element.option}
                                  </a>
                                ) : element.option === 'Mobile Element Locator Plugin' ? (
                                  <a href="/assets/ele_loc_plugin/Mobile_Element_Locator_Plugin.zip" type="download">
                                    {element.option}
                                  </a>
                                ) : element.option === 'Eclipse Plugin' ? (
                                  <a
                                    href="https://product-utility.s3.ap-south-1.amazonaws.com/eclipsePlugin/EclipsePlugin.zip"
                                    type="download"
                                  >
                                    {element.option}
                                  </a>
                                ) : (
                                  element.option
                                )}
                              </button>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        </div>
      </div>
      <div className="page-table-body">
        <Switch>
          <Route path="/repository" exact={true} strict={true} key="repo-page">
            <PageParent
              projectType={projectType}
              platForm={platForm}
              getAllElemtsReq={getAllElemtsReq}
              setProjectElementsCount={setProjectElementsCount}
              MyAlert={props.MyAlert}
            />
          </Route>
          <Route path="/repository/element" exact={true} strict={true} key="element-page">
            <PageParent
              projectType={projectType}
              platForm={platForm}
              getAllElemtsReq={getAllElemtsReq}
              setProjectElementsCount={setProjectElementsCount}
              MyAlert={props.MyAlert}
            />{' '}
          </Route>
          <Route path="/repository/element" exact={true} strict={true} key="ele-type-page">
            <PageParent
              projectType={projectType}
              platForm={platForm}
              getAllElemtsReq={getAllElemtsReq}
              setProjectElementsCount={setProjectElementsCount}
              MyAlert={props.MyAlert}
            />{' '}
          </Route>
          <Route path="/repository/element/project_elements" exact={true} strict={true} key="results-page">
            <PageParent
              projectType={projectType}
              platForm={platForm}
              getAllElemtsReq={getAllElemtsReq}
              MyAlert={props.MyAlert}
              setProjectElementsCount={setProjectElementsCount}
            />{' '}
          </Route>
          <Route path="/repository/program_elements" exact={true} strict={true} key="results-page">
            <PackageParent MyAlert={props.MyAlert} />
          </Route>
          <Route path="/repository/step_group" exact={true} strict={true} key="results-page">
            <LibraryParent MyAlert={props.MyAlert} />
          </Route>
          <Route path="/repository/element/shared-elements" exact={true} strict={true}>
            <SharedElements platForm={platForm} MyAlert={props.MyAlert} />
          </Route>
          {/* COMMENTED FOR THIS RELEASE. FROM CLIENT SECTON THE RECORDED ELEMENTS WILL BE SENT SO AFTER COMPLITION OF THAT WILL COMPLETELY REMOVE REC ELEMENT */}
          {/* <Route
            path="/repository/element/recorded-elements"
            exact={true}
            strict={true}
          >
            <RecordedaElementPage
              platForm={platForm}
              getAllElemtsReq={getAllElemtsReq}
              projectType={projectType}
              MyAlert={props.MyAlert}
            />
          </Route> */}
        </Switch>
      </div>
    </div>
  );
}

export default withRouter(RepositoryTabs);

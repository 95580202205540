import DashboardStyle from '../styles/dashboard.module.scss';
import cx from 'classnames';

function SectionHeader({ children, className }) {
  return (
    <div
      className={cx(
        'bg-white p-3 rounded-t-lg drop-shadow uppercase',
        DashboardStyle['fontPoppinsSemiboldLg'],
        DashboardStyle['shadow'],
        DashboardStyle['section-header'],
        className
      )}
    >
      {children}
    </div>
  );
}

export default SectionHeader;

import { Tooltip } from '@material-ui/core';
import React, { useState, Fragment, useEffect } from 'react';
import { Menu } from '@headlessui/react';
import { Drawer, Icon, Label, Table } from 'fireflink-ui';
import { colors } from '@src/css_config/colorConstants';
import { isEmptyValue } from '@src/util/common_utils';

const Tag = ({
  _step,
  steps,
  setOpenTagModal,
  setTagData,
  setMessage,
  setOpenDeleteModal,
  setIsResolve,
  index,
  isEditable = true,
  hasFullAccess = true,
  getPathController,
  setopenPopup,
  taggedByAI,
  taggedManually,
  setResolveAndDeleteText,
  setResolveStep,
}) => {
  const [topValue, setTopValue] = useState(0);
  const [top, setTop] = useState(0);
  const [margin, setMargin] = useState(0);
  const [openListOfTags, setOpenListOfTags] = useState(false);

  useEffect(() => {
    if (topValue > 550) {
      setTop(-250);
      setMargin(150);
    } else {
      setTop(100);
      setMargin(-478);
    }
  }, [topValue]);
  const handleDeleteAndResolve = (action, index, _step, tagDTObj, _tagindex) => {
    const deleteAndResolveSingleStep = _step?.tagDetailsDTOs[_tagindex]?.updateAllSteps;
    if (_tagindex) {
      //for multiple
      setTagData({
        index: index,
        data: _step,
        tagDTO: _step?.tagDetailsDTOs[_tagindex],
      });
    } else {
      //for single tag
      setTagData({
        index: index,
        data: _step,
        tagDTO: tagDTObj,
      });
    }
    if (action === 'resolve') {
      setResolveStep(!deleteAndResolveSingleStep && 'resolve-step');
      setMessage(`this tag`);
      getPathController(_step);
      setIsResolve(true);
      setOpenDeleteModal(true);
    } else {
      //for delete
      setMessage(`this tag`);
      getPathController(_step);
      setOpenDeleteModal(true);
    }
  };
  const handleEdit = (type, index, _step, tagDTObj, _tagindex) => {
    if (type === 'multiple') {
      setOpenTagModal({
        openModal: true,
        data: steps,
        index: index,
        _tagindex: _tagindex,
        tagDetailsDTOs: _step?.tagDetailsDTOs,
        edit: true,
        tagDTO: tagDTObj,
        stepId: _step?.stepId,
      });
    } else {
      setOpenTagModal({
        openModal: true,
        data: steps,
        index: index,
        edit: true,
        _tagindex: 0,
        tagDetailsDTOs: _step?.tagDetailsDTOs,
        tagDTO: _step?.tagDetailsDTOs[0],
        stepId: _step?.stepId,
      });
    }
    getPathController(_step);
  };
  const deleteResolveTag = (action, index, _step, row, _tagindex) => {
    setResolveAndDeleteText(action);
    handleDeleteAndResolve(action, index, _step, row, _tagindex);
    setopenPopup(true);
  };
  const tableMenuData = (row, tableData) => {
    const _tagindex = tableData.findIndex(({ _id }) => _id === row?._id);
    const statusCondition = row?.status === 'Active' || !row?.status;
    return (
      <div className="action-col-min-width flex items-center w-77">
        {isEditable && statusCondition && (
          <Tooltip title={`Edit tag`}>
            <span
              className="cursor-pointer"
              onClick={() => {
                handleEdit('multiple', index, _step, row, _tagindex);
              }}
            >
              <Icon color={colors.grey_dark} name="edit_icon" />
            </span>
          </Tooltip>
        )}
        {hasFullAccess && (
          <Tooltip title={`Delete tag`} className="ml-5">
            <span
              className="cursor-pointer"
              onClick={() => {
                deleteResolveTag('delete', index, _step, row, _tagindex);
              }}
            >
              <Icon color={colors.grey_dark} name="delete" />
            </span>
          </Tooltip>
        )}
        {isEditable && statusCondition && (
          <Tooltip title={`Resolve tag`} className="ml-5">
            <span
              className="cursor-pointer"
              onClick={() => {
                deleteResolveTag('resolve', index, _step, row, _tagindex);
              }}
            >
              <Icon color={colors.grey_dark} name="check" />
            </span>
          </Tooltip>
        )}
      </div>
    );
  };
  const columns = [
    {
      accessor: 'name',
      header: 'Name',
      width: 150,
      cell: ({ row }) => <span>{row?.name}</span>,
    },
    {
      accessor: 'taggedBy',
      header: 'Tag',
      cell: ({ row }) => (
        <span className="flex">
          <Icon color={colors.rs_primary} name={row?.taggedBy === 'AI' ? 'ai_tagged' : 'manual_tagged'} />
        </span>
      ),
    },
    {
      accessor: 'status',
      header: 'status',
      width: 150,
      cell: ({ row }) => (
        <Label
          label={row?.status}
          color={row?.status === 'Active' ? 'success' : 'warning'}
          className="fontPoppinsRegularSm"
        />
      ),
    },
    {
      accessor: 'Actions',
      className: 'action-column',
      header: 'Actions',
      type: 'actions',
      cell: ({ row }) => {
        return tableMenuData(row, _step?.tagDetailsDTOs);
      },
    },
  ];
  const secondaryButtonProps = {
    disabled: false,
    label: 'Close',
    onClick: () => {
      setOpenListOfTags(false);
    },
    variant: 'secondary',
  };
  return (
    <div className="step-tag flex items-center w-3/4 tag-icon">
      <div className="flex items-center">
        {!isEmptyValue(taggedManually) && (
          <Tooltip title="Manually Tagged">
            <span className="flex justify-center center">
              <Icon color={colors.rs_primary} name={'manual_tagged'} />
            </span>
          </Tooltip>
        )}
        {!isEmptyValue(taggedByAI) && (
          <Tooltip title="AI Tagged">
            <span className="flex justify-center center">
              <Icon color={colors.rs_primary} name="ai_tagged" />
            </span>
          </Tooltip>
        )}
      </div>
      {!isEmptyValue(_step?.tagDetailsDTOs) && (
        <div>
          <Menu as="div" className="relative inline-block text-left ml-6">
            {({ open }) => (
              <>
                <div>
                  <div
                    onClick={() => {
                      if (!openListOfTags) {
                        setOpenListOfTags(true);
                      }
                    }}
                    className="flex items-center focus:outline-none mt-2 cursor-pointer"
                    onMouseOver={(e) => {
                      let mouse = e.clientY;
                      setTopValue(mouse);
                    }}
                  >
                    <Tooltip title={open ? 'Hide all tags' : 'Show all tags'}>
                      <span>
                        <Icon color={colors.grey_dark} name="view_more" />
                      </span>
                    </Tooltip>
                  </div>
                </div>
                <Drawer
                  isOpen={openListOfTags}
                  onClose={() => {
                    setOpenListOfTags(false);
                  }}
                  overlay={true}
                  footerContent={null}
                  headerType="default"
                  size="medium"
                  title="List of Tags"
                  secondaryButtonProps={secondaryButtonProps}
                >
                  <Table columns={columns} data={_step?.tagDetailsDTOs} />
                </Drawer>
              </>
            )}
          </Menu>
        </div>
      )}
    </div>
  );
};

export default Tag;

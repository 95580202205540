import righticon from '@assets/right-icon.svg';
import { ReactComponent as BackArrow } from '@assets/svg-imports/BackArrowOrange.svg';
import commonStyles from '@pages/auth/common.module.scss';
import '@pages/auth/login.css';
import { GLOBAL_CONSTANTS, PASSWORD_CONSTANTS } from '@src/common/ui-constants';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import CommonPage from '../common/CommonPage';

export default function SetPasswordSuccessfullly(props) {
  const history = useHistory();
  const redirectToSignIn = () => {
    history.push('/signin');
  };

  return (
    <>
      <CommonPage marginTop={'mt-32'} wrapperClassname={`common-padding`}>
        <div className="flex flex-col justify-center gap-4 items-center ">
          <img alt="right icon" src={righticon} className="ml-auto mr-auto" />
          <div className={cx('text-center fontPoppinsSemibold-size-26', commonStyles['text-green'])}>
            {PASSWORD_CONSTANTS.PASSWORD_CHANGED_SUCCESSFULLY}
          </div>
          <div className="text-center fontPoppinsRegularMd">{PASSWORD_CONSTANTS.NEW_PASSWORD_SET_SUCCESSFULLY}</div>
          <div className="text-center fontPoppinsRegularMd">{PASSWORD_CONSTANTS.PASSWORD_CHANGED_CONFIRM_MESSAGE}</div>
          <button className={cx(commonStyles['btn-bg-white'], commonStyles['common-btn'])} onClick={redirectToSignIn}>
            <BackArrow />
            <span className="fontPoppinsSemiboldLg pl-2">{GLOBAL_CONSTANTS.BACK_TO_SIGN_IN}</span>
          </button>
        </div>
      </CommonPage>
    </>
  );
}

import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import IconButton from '@material-ui/core/IconButton';
import { MenuItem, Modal, Select, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createGlobalVariable, createProjectEnvironmentVariable } from '../../../../../api/api_services';
import { colors } from '@src/css_config/colorConstants.js';

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: 249,
    borderRadius: 8,
    fontSize: '14px',
    '&:focus': {
      borderRadius: 12,
      background: 'none',
    },
  },
  menuPaper: {
    maxHeight: 200,
    fontSize: '14px',
  },
  menulist: {
    paddingTop: 15,
    paddingBottom: 0,
    fontSize: '14px',
    background: 'none',
    '& li': {
      fontSize: '14px',
      color: colors.grey_dark,
    },
    '& li:hover': {
      background: colors.sky_blue_dark,
      color: colors.blue_dark,
    },
    '& li.Mui-selected': {
      color: colors.blue_dark,
      background: colors.sky_blue_dark,
    },
    '& li.Mui-selected:hover': {
      background: colors.sky_blue_dark,
      color: colors.blue_dark,
    },
  },
}));
function Variable(props) {
  const classes = useStyles();
  let editData = {};
  if (props?.data) {
    editData = props.data;
  }
  const [openModal, setOpenModal] = useState(true);

  let initialValues;
  if (props?.data) {
    initialValues = {
      variableName: editData?.name,
      variableType: editData?.type,
      variableValue: editData?.value,
    };
  } else {
    initialValues = {
      variableName: '',
      variableType: '',
      variableValue: '',
    };
  }

  const createVariable = (data) => {
    let requestBody = {
      name: data.variableName,
      type: data.variableType,
      value: data.variableValue,
    };
    if (requestBody.type === 'GLOBAL') {
      createGlobalVariable(requestBody).then((response) => {
        if (response && response.data && response.data.responseObject) {
          props.MyAlert.success(`${data.variableName} variable created successfully`);
          props.closeModal(false);
        } else if (response.data.message === `Resource with name ${data.variableName} already exists`) {
          formikDetails.setFieldError('variableName', 'Name already exists');
        } else {
          props.MyAlert.warning(`${response.data.message}`);
          props.closeModal(false);
        }
      });
    } else {
      createProjectEnvironmentVariable(requestBody).then((response) => {
        if (response && response.data && response.data.responseObject) {
          props.MyAlert.success(`${data.variableName} variable created successfully`);
          props.closeModal(false);
        } else if (response.data.message === `Resource with name ${data.variableName} already exists`) {
          formikDetails.setFieldError('variableName', 'Name already exists');
        } else {
          props.MyAlert.warning(`${response.data.message}`);
          props.closeModal(false);
        }
      });
    }
  };

  const validationSchema = yup.object({
    variableName: yup
      .string()
      .required('Name is required!!')
      .matches(/^[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*$/, 'Name should be alphanumeric')
      .min(3, 'Name must be between 3 and 25 characters')
      .max(25, 'Name must be between 3 and 25 characters'),
    variableType: yup.string().required('Type is required'),
  });

  const formikDetails = useFormik({
    initialValues,
    validationSchema,
  });

  let errorExistForType = false;
  if (formikDetails.values.variableType === '' && formikDetails.touched.variableType) {
    errorExistForType = true;
  }

  return (
    <div className="overflow">
      <Modal
        open={openModal}
        onClose={() => {
          props.closeModal(false);
          setOpenModal(false);
        }}
        className="modal-dialog"
      >
        <div className="modal-container">
          <div className="modal-header">
            <div className="">
              {props?.data ? (
                <span className="Popup-header-common"> Edit Variable - {editData?.name}</span>
              ) : (
                <span className="Popup-header-common"> Create Variable</span>
              )}
              <IconButton
                color="primary"
                component="span"
                className="close"
                onClick={() => {
                  setOpenModal(false);
                  props.closeModal(false);
                }}
              >
                <img
                  style={{
                    cursor: 'pointer',
                  }}
                  src="/assets/images/close_black.svg"
                  alt=""
                  height="25px"
                  width="25px"
                />
              </IconButton>
            </div>
          </div>
          <div className="modal-body">
            <form onSubmit={formikDetails.handleSubmit}>
              <div className="ml-2">
                <div className="grid grid-cols-2">
                  <div className="mt-2">
                    <div className="rs-user-input-container">
                      <TextField
                        error={formikDetails.errors.variableName && formikDetails.touched.variableName}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label={
                          <>
                            {' '}
                            <span className="text-red-400 mr-1 pb-1">&#42;</span>
                            Name
                          </>
                        }
                        className="w-11/12 popup-input-bg mt-1"
                        type="text"
                        name="variableName"
                        id="variableName"
                        autoComplete="off"
                        placeholder="Enter variable name"
                        onBlur={formikDetails.handleBlur}
                        onChange={formikDetails.handleChange}
                        value={formikDetails.values.variableName}
                      />
                    </div>
                    {formikDetails.errors.variableName && formikDetails.touched.variableName ? (
                      <div className="errorMessage md:mt-1">{formikDetails.errors.variableName}</div>
                    ) : null}
                  </div>
                  <div className="-mt-2 ml-6">
                    <label
                      htmlFor="variableType"
                      className={
                        !errorExistForType
                          ? 'input-filed-label-style-common '
                          : 'input-filed-label-style-common-withError'
                      }
                    >
                      <span className="text-red-400">&#42;</span> Type
                    </label>
                    <div className="text-xs">
                      <Select
                        classes={{ root: classes.select }}
                        error={errorExistForType}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          classes: {
                            paper: classes.menuPaper,
                            list: classes.menulist,
                          },
                          getContentAnchorEl: null,
                        }}
                        name="variableType"
                        value={formikDetails.values.variableType}
                        onBlur={formikDetails.handleBlur}
                        onChange={formikDetails.handleChange}
                        onSelect={formikDetails.handleChange}
                        displayEmpty
                        renderValue={(selected) => {
                          if (!selected) return <span className="fontPoppinsRegularSm">Select variable type</span>;
                          else return <span className="fontPoppinsRegularMd">{selected}</span>;
                        }}
                      >
                        <MenuItem className="fontPoppinsRegularMd" value="GLOBAL">
                          {' '}
                          Global Variable
                        </MenuItem>
                        <MenuItem className="fontPoppinsRegularMd" value="PROJECT_ENVIRONMENT">
                          {' '}
                          Project Environment Variable
                        </MenuItem>
                      </Select>
                    </div>
                    {formikDetails.errors.variableType && formikDetails.touched.variableType ? (
                      <div className="errorMessage md:mt-1">{formikDetails.errors.variableType}</div>
                    ) : null}
                  </div>
                </div>
                <div className="mt-6">
                  <div className="rs-user-input-container">
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label={
                        <>
                          {' '}
                          <span className="text-red-400 mr-1 pb-1"></span>
                          Value
                        </>
                      }
                      className="w-2/4 popup-input-bg mt-1"
                      type="text"
                      name="variableValue"
                      id="variableValue"
                      autoComplete="off"
                      placeholder="Enter variable value"
                      onBlur={formikDetails.handleBlur}
                      onChange={formikDetails.handleChange}
                      value={formikDetails.values.variableValue}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button onClick={() => props.closeModal(false)} type="button" className="gray-btn">
              Cancel
            </button>
            <button
              type="submit"
              className="primary-btn ml-3"
              onClick={() => {
                formikDetails.setTouched({
                  variableName: true,
                  variableType: true,
                });
                if (
                  formikDetails.values.variableName !== '' &&
                  formikDetails.values.variableType !== '' &&
                  formikDetails.values.variableName.length >= 3 &&
                  formikDetails.values.variableName.length <= 25 &&
                  formikDetails.errors.variableName !== 'Name should be alphanumeric'
                ) {
                  createVariable(formikDetails.values);
                }
              }}
            >
              {props?.data ? 'Update' : 'Create'}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Variable;

import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  PlayCircleOutline,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  AddCircleOutlineOutlined,
} from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import '@src/index.css';
import '@pages/repository/repository.scss';
import '@pages/test-development/test-development.scss';
import TableTree from '@pages/common/table_tree/table_tree';
import MoreInfoButton from '@pages/common/more_info_button';
import DeleteModal from '@pages/common/delete_modal';
import { CommonLoader } from '@pages/common/common-loader';
import QuickRunSettings from '@pages/test-development/shared/quick-run-settings';
import { setExecutionTreeWithSelectedNodes } from '@pages/test-development/shared/create-execution-tree';
import {
  getBrowserName,
  exportScript,
  encodeSpecialCharater,
  getExecutionName,
  getAutomationScriptId,
} from '@pages/test-development/shared/common-methods';
import {
  deleteModulePrePostConditionReq,
  DeleteModuleReq,
  DeleteScriptReq,
  getScriptReq,
  getSingleModuleReq,
  getSingleModuleTreeReq,
  getStepGroupReq,
  getAllModuleTreeReq,
  getUserAllAvailableMachineReq,
  createExecutionReq,
  cascadeConditionToScriptReq,
  getBrowserStackList,
  getSauceLabsList,
  getAllLambdaTestsList,
  cloneReq,
  getAnalyticsReq,
  getAllJiraList,
  getMappedJira,
  exportManualTestCaseFiles,
} from '@api/api_services';
import ModuleModal from './modals/create-edit-module';
import ScriptModal from './modals/edit-script';
import DetailsModal from './modals/details-modal';
import EditPrePostCondition from './modals/edit-pre-post-condition';
import CreateManualScript from './modals/create-manual-script';
import CreateAutomationScript from './modals/create-automation-script';
import DeleteScript from './modals/delete-script';
import TestCaseTypeCol from './utils/test-case-type-col';
import {
  actionMenuFile,
  actionMenuFolder,
  actionMenuRoot,
  LABELS,
  plusIconArray,
  actionMenuFileNoClone,
} from './utils/constants';
import CloneInfoModal from '@src/pages/common/cloneInfo_modal';
import LoaderModal from './modals/loader-modal';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import {
  checkForScriptTypes,
  containerNavigation,
  getPlusIconArray,
} from '@pages/test-development/script/modules/module/utils/common-functions';
import MoveResource from './modals/MoveResource';
import {
  getLicenseFeatures,
  getTestDevmenuItems,
  purchasedFeatureTypeOfAutomation,
  convertDateHyphenReverse,
  isEmptyValue,
  getVerifiedDateAuto,
  isSearchFilterEnabled,
  getTimeStampForFileName,
} from '@src/util/common_utils';
import TreeSearchBar from '@src/pages/common/table_tree/tree_searchbar';
import MapToJiraModal from './modals/map-jira-modal';
import InformationModal from '@src/pages/common/info-modal';
import {
  NAVIGATE_TO_INSTANCE_MODAL_CONSTANTS,
  trimPath,
  defectAnalyticsLabel,
  getSelectedFilterLableName,
  BULK_LABELING,
} from '@src/common/ui-constants';

import FilterModal from '@pagescommon/filters/filter-modal';
import MultipleSelectCheckmarks from '@pagescommon/multi_select_checkbox_dropdown/multiple-select-checkmarks.js';
import Chip from '@pagescommon/chip/chip.js';
import FormControl from '@mui/material/FormControl';
import DefectList from '@pages/configuration/DefectConfig/DefectListDrawer';
import { saveFileFromBlob } from '@src/pages/test-development/shared/common-methods';
import MemoryWarningPopup from '@src/pages/configuration/Memory_Config/MemoryWarningPopup';

const SingleModuleTree = (props) => {
  const { isEditable, hasFullAccess, isProjectOpen } = React.useMemo(
    () => ({
      isProjectOpen: window.permission?.isProjectOpen(),
      isEditable: window.permission?.isEditAccess('testDevelopment'),
      hasFullAccess: window.permission?.isFullAccess('testDevelopment'),
    }),
    []
  );
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  let hasCloneOption = isProjectOpen && (isEditable || hasFullAccess);
  let search = window.location.search;
  const [moduleId, setModuleId] = useState(new URLSearchParams(search).get('moduleId'));
  if (moduleId !== new URLSearchParams(search).get('moduleId')) {
    setModuleId(new URLSearchParams(search).get('moduleId'));
  }
  const user = JSON.parse(localStorage.getItem('test-optimize-user'));
  const project = JSON.parse(localStorage.getItem('selected-project'));
  let keyAndNameArray = [];
  let newUpdatedPath = '';
  let [selectedDefaultMachine, setselectedDefaultMachine] = useState();
  const [memoryWarningPopup, setMemoryWarningPopup] = useState(false);
  const [quickRunSettingsApiData, setQuickRunSettingsApiData] = useState();
  const [disableHeadlessSwitch, setDisableHeadlessSwitch] = useState(false);
  let [nodeSelected, setNodeSelected] = useState(false);
  let [conditionsVisible, setConditionsVisible] = useState(false);
  const [selectedNodes, setSelectedNodes] = useState([]);
  let [isLoading, setIsLoading] = React.useState(false);
  let renderTree;
  let [treeHeader, setTreeHeader] = useState('');
  let [rootData, setRootData] = useState([]);
  let [treeData, setTreeData] = useState([]);
  let [moduleTree, setModuleTree] = useState('');
  const [dropDownFolderTree, setDropDownFolderTree] = useState([]);
  let [node, setNode] = useState({});
  let [message, setMessage] = useState();
  let [deleteData, setDeleteData] = useState();
  let [openDeleteModal, setOpenDeleteModal] = useState(false);
  let [browserInstanceArray, setBrowserInstanceArray] = useState([]);
  let [sauceLabInstanceArray, setSauceLabInstanceArray] = useState([]);
  const [runApiCalled, setRunApiCalled] = useState(false);
  const [lambdaTestsInstanceArray, setLambdaTestsInstanceArray] = useState([]);
  const [selectedDefaultValue, setselectedDefaultValue] = useState();
  const [hideElements, setHideElements] = useState(false);
  const [licenseLevScriptCout, setLicenseLevScriptCout] = useState(false);
  const [moveResourceModalData, setMoveResourceModalData] = useState({ open: false, srcNodeData: {} });
  let [treeValue, setTreeValue] = useState('');
  let [isExpandAll, setIsExpandAll] = useState(false);
  let [openRunSettings, setOpenRunSettings] = useState({ openModal: false, title: '' });
  let [cloningContainerData, setCloningContainerData] = useState();
  let [openCloneInfoModal, setOpenCloneInfoModal] = useState(false);
  const [showLoaderModal, setShowLoaderModal] = useState(false);
  const licenseFeatures = getLicenseFeatures();
  const hasManualTestCase = window.privilege.hasManualTestCase(licenseFeatures);
  const hasAutomation = window.privilege.hasAutomation(licenseFeatures);
  const hasAutomationBasedOnProjType = purchasedFeatureTypeOfAutomation(project.type);
  const hasCBasic = window.privilege.hasCBasic(licenseFeatures);
  const hasDefects = window.privilege.hasDefects(licenseFeatures);
  let menuFeatures = getTestDevmenuItems();
  const [searchedText, setSearchedText] = useState('');
  const [searchedTextQuery, setSearchedTextQuery] = useState('');
  const [openMapToJira, setOpenMapToJira] = useState(false);
  const [scriptData, setScriptData] = useState();
  const [domainDetails, setDomainDetails] = useState();
  const [mappedDetails, setMappedDetails] = useState();
  const [navigateToInstanceModal, setNavigateToInstanceModal] = useState(false);
  const timerRef = useRef(null);
  const displaySearchFilter = isSearchFilterEnabled('testDev');
  const [openLinkedDefectList, setOpenLinkedDefectList] = useState(false);
  const [defectScriptData, setDefectScriptData] = useState('');
  const [isManualTestcaseAvaliable, setIsManualTestcaseAvaliable] = useState(false);
  const [selectedScripts, setSelectedScripts] = useState([]);
  const [isToggled, setIsToggled] = useState(true);
  const [checkedScripts, setCheckedScripts] = useState([]);
  const isOnlyDefects = !hasAutomation && !hasManualTestCase && hasDefects;
  const getHideCondtionBtn = () => {
    if ((hasAutomation && hasAutomationBasedOnProjType) || hasCBasic) {
      return false;
    } else {
      return true;
    }
  };
  const showCheckBox = (hasAutomation && hasAutomationBasedOnProjType) || hasCBasic;

  let scriptTypeMaxLength = 12;
  const handelClickForJira = async (nodeObj) => {
    const isScriptName = nodeObj.data.title.length > 255 ? false : true;
    try {
      if (isScriptName === true) {
        const response = await getAllJiraList();
        const mappedJiraResponse = await getMappedJira(nodeObj?.data?.name, nodeObj?.data?.parentId);
        if (mappedJiraResponse?.data?.responseCode === 204) {
          setMappedDetails(null);
        }
        if (mappedJiraResponse?.data?.responseObject) {
          setMappedDetails(mappedJiraResponse?.data?.responseObject);
        }
        const mappedDetailsData = mappedJiraResponse?.data?.responseObject;
        const JiraResponse = response?.data?.responseObject;
        const isDomainAvaible = JiraResponse.some((data) => data.domain === mappedDetailsData?.jiraDomain);
        const isMapped = mappedJiraResponse?.data?.message === 'Mapped Jira';
        const isNotMapped = mappedJiraResponse?.data?.message === 'Script not mapped to jira';
        if (response?.data?.responseObject?.length) {
          if (isNotMapped) {
            setOpenMapToJira(true);
            setDomainDetails(response?.data?.responseObject);
          } else if (isMapped && isDomainAvaible) {
            setOpenMapToJira(true);
            setDomainDetails(response?.data?.responseObject);
          } else if (isMapped && !isDomainAvaible) {
            props.MyAlert.warning(
              `The domain for which the script is already mapped is not available for your Jira instances.`
            );
          }
        } else {
          const infoModalData = {
            title: NAVIGATE_TO_INSTANCE_MODAL_CONSTANTS.HEADER_TITLE,
            body: NAVIGATE_TO_INSTANCE_MODAL_CONSTANTS.BODY_TEXT,
            label: NAVIGATE_TO_INSTANCE_MODAL_CONSTANTS.BUTTON_LABEL,
            path: '/configuration/integration/plugin/JIRA',
          };
          setNavigateToInstanceModal({ openModal: true, data: infoModalData });
        }
      } else {
        props.MyAlert.warning(`The script name length is more than 255, which prevents it from mapping to Jira`);
      }
    } catch (err) {
      console.error('GET_JIRA_INSTANCES : ', err);
    }
  };
  const closeGitAndNavigateModal = (val) => {
    if (navigateToInstanceModal.openModal) {
      setNavigateToInstanceModal({ openModal: val, data: null });
    }
  };
  const moduleObject = (title, key, children) => {
    const obj = {
      title: title,
      folder: true,
      type: 'Module',
      key: key,
      children: [children],
    };
    return obj;
  };
  const createParentTree = (data, tree) => {
    const parent = data.path.slice(1, data.path.lastIndexOf(`/${tree.title}`)).split('/');
    const parentKey = data.searchKey.slice(1, data.searchKey.indexOf(`/${tree.key}`)).split('/');
    let completeTree = tree;
    for (let i = parent.length - 1; i >= 0; i--) {
      if (i === 0) {
        completeTree = moduleObject('Root Module', parentKey[i], completeTree);
      } else {
        completeTree = moduleObject(parent[i], parentKey[i], completeTree);
      }
    }
    return [completeTree];
  };

  const getBothAutomationAndManualScripts = (selectedNodes) => {
    const automation_manual_node = selectedNodes.filter((node) => {
      if (node?.data?.testCaseType) {
        const testCaseTypes = node?.data?.testCaseType || [];
        const hasAutomation = testCaseTypes.some((testCase) => testCase?.type === 'Automation');
        const hasManual = testCaseTypes.some((testCase) => testCase?.type === 'manual');
        return hasAutomation && hasManual;
      }
      return false;
    });

    return automation_manual_node;
  };
  const getOnlyAutomationScripts = (selectedNodes) => {
    const automationNode = selectedNodes.filter((node) => {
      if (node?.data?.testCaseType) {
        const testCaseTypes = node?.data?.testCaseType || [];
        const hasAutomation = testCaseTypes.some((testCase) => testCase?.type === 'Automation');
        const hasManual = testCaseTypes.some((testCase) => testCase?.type === 'manual');
        return hasAutomation && !hasManual;
      }
      return false;
    });

    return automationNode;
  };
  const checkScriptTypes = (selectedNodes) => {
    let hasAutomationScripts = false;
    let hasManualScripts = false;
    let hasAutomationManualScripts = false;

    selectedNodes.forEach((node) => {
      if (node?.data?.testCaseType) {
        const testCaseTypes = node.data.testCaseType;
        const hasAutomation = testCaseTypes.some((testCase) => testCase?.type === 'Automation');
        const hasManual = testCaseTypes.some((testCase) => testCase?.type === 'manual');

        if (hasAutomation && hasManual) {
          hasAutomationManualScripts = true;
        }
        if (hasAutomation) {
          hasAutomationScripts = true;
        }
        if (hasManual && !hasAutomation) {
          hasManualScripts = true;
        }
      }
    });

    return { hasAutomationScripts, hasManualScripts, hasAutomationManualScripts };
  };

  const { hasAutomationScripts, hasManualScripts, hasAutomationManualScripts } = checkScriptTypes(selectedNodes);

  useEffect(() => {
    const getAllAutomationScripts = getOnlyAutomationScripts(selectedNodes);
    const getAllAutomationManualScripts = getBothAutomationAndManualScripts(selectedNodes);
    if (hasAutomationScripts && hasAutomationManualScripts) {
      let scriptsList = [...getAllAutomationScripts, ...getAllAutomationManualScripts];
      setSelectedScripts(scriptsList);
    } else if (hasAutomationScripts) {
      let scriptsList = getAllAutomationScripts;
      setSelectedScripts(scriptsList);
    }
  }, [selectedNodes]);

  const run = async (deleteSpillOverMemory) => {
    try {
      if (
        selectedDefaultMachine &&
        !isEmptyValue(selectedNodes) &&
        (hasAutomationScripts || hasAutomationManualScripts)
      ) {
        setRunApiCalled(true);
        const moduleDetailsResponse = await getSingleModuleReq(moduleId);
        let execution_json = setExecutionTreeWithSelectedNodes(selectedScripts);
        let rootModule = createParentTree(moduleDetailsResponse.data.responseObject, execution_json[0]);

        let executionDataJson = {};
        let browserInfo = selectedDefaultMachine?.systemConfigDetails.filter((data) => data.subType == 'browser');
        let deviceInfo = selectedDefaultMachine?.systemConfigDetails.filter((data) => data.subType == 'device');
        let screenshot = quickRunSettingsApiData?.results?.captureScreenShotStatus;
        let screenshotStatus = 'false';
        if (screenshot === 'failedSteps' || screenshot === 'allSteps') {
          screenshotStatus = 'true';
        }
        const {
          implicitWait,
          explicitWait,
          delayBetweenSteps,
          implicitWaitUnit,
          explicitWaitUnit,
          delayBetweenStepsUnit,
        } = quickRunSettingsApiData.waitConfiguration;
        executionDataJson = {
          name: getExecutionName([...rootModule]),
          executionTree: rootModule,
          installedDir: 'D:\\resources\\macine_config',
          screenshotRequired: screenshotStatus,
          failedStepsScreenshot: screenshot === 'failedSteps',
          videoRequired: ['failedSteps', 'allSteps'].includes(quickRunSettingsApiData?.results?.captureVideoStatus)
            ? true
            : false,
          failedScriptVideoRequired: ['notCapture', 'allSteps'].includes(
            quickRunSettingsApiData?.results?.captureVideoStatus
          )
            ? false
            : true,
          waitTime: {
            implicitWait,
            explicitlyWait: explicitWait,
            delayBetweenSteps,
            implicitWaitUnit,
            explicitlyWaitUnit: explicitWaitUnit,
            delayBetweenStepsUnit,
          },
          selectedSystem: [
            {
              clientId: selectedDefaultMachine?.clientId,
              machineInstances: [
                {
                  clientId: selectedDefaultMachine.clientId,
                  executionEnv: selectedDefaultMachine.executionEnvironment,
                  browserName: browserInfo?.name ? getBrowserName(browserInfo.name) : null,
                  numberOfRuns: 0,
                  version: browserInfo?.version ? browserInfo.version : null,
                  systemUrl: selectedDefaultMachine?.machine?.hostName,
                  browserInfo: browserInfo.length ? { ...browserInfo[0] } : null,
                  deviceInfo: [...deviceInfo],
                  headless: props.disableHeadless ? false : selectedDefaultMachine?.headless || false,
                  machineInfo: {
                    osName: selectedDefaultMachine?.machine?.osName,
                    osVersion: selectedDefaultMachine?.machine?.osVersion,
                    hostName: selectedDefaultMachine?.machine?.hostName,
                  },
                },
              ],
              executionEnv: selectedDefaultMachine?.executionEnvironment,
              browserName: browserInfo.length ? getBrowserName(browserInfo[0]?.name) : null,
              version: browserInfo.length ? browserInfo[0]?.version : null,
              systemUrl: selectedDefaultMachine?.machine?.hostName,
              browserInfo: browserInfo.length ? { ...browserInfo[0] } : null,
              deviceInfo: [...deviceInfo],
              headless: disableHeadlessSwitch ? false : selectedDefaultMachine?.headless || false,
              machineInfo: {
                osName: selectedDefaultMachine?.machine?.osName,
                osVersion: selectedDefaultMachine?.machine?.osVersion,
                hostName: selectedDefaultMachine?.machine?.hostName,
              },
            },
          ],
          projectName: project.name,
          projectId: project.id,
          type: 'INDIVIDUAL',
          deleteSpillOverMemory,
        };
        const executionResponse = await createExecutionReq(executionDataJson);
        setRunApiCalled(false);
        const message = executionResponse?.data?.message?.toLowerCase();
        if (executionResponse && executionResponse.data && executionResponse.data.responseObject) {
          const newPath = props.location.pathname.split('/');
          newPath.pop();
          if (hasAutomationScripts && hasManualScripts) {
            props.history.push(`${newPath.join('/')}/Execution Dashboard?moduleId=${moduleId}/true`);
          } else {
            props.history.push(`${newPath.join('/')}/Execution Dashboard?moduleId=${moduleId}`);
          }
        } else if (
          message.includes('please connect a device') ||
          message.includes('please install a browser') ||
          (message.startsWith('client') && message.includes('is not available'))
        ) {
          props.MyAlert.info(executionResponse.data.message);
        } else if (executionResponse.data && executionResponse.data.responseCode === 400) {
          props.MyAlert.warning(`${executionResponse.data.message}`);
        } else if (executionResponse.data && executionResponse.data.status === 'FAILURE') {
          props.MyAlert.info(executionResponse?.data?.message);
        } else if (executionResponse?.data?.message === 'Consumed Memory is above 85%') {
          setMemoryWarningPopup(true);
        }
      } else if (hasManualScripts && !hasAutomationManualScripts && !hasAutomationScripts) {
        props.MyAlert.danger(`${BULK_LABELING.MANUAL_TESTCASES_ERROR}`);
      } else {
        if (selectedNodes.length === 0) {
          props.MyAlert.info(`Select at least 1 pre condition/post condition/module/script to execute`);
        } else if (selectedDefaultValue) {
          props.MyAlert.info(
            `There are no machines associated with user ${user.name}.  Download and install the client from Environment configuration.`
          );
        } else if (!selectedDefaultMachine) {
          runSettings('Select System');
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const runSettings = (modalTitle = 'Quick Run Settings') => {
    if (selectedDefaultValue) {
      props.MyAlert.info(
        `There are no machines associated with user ${user.name}.  Download and install the client from Environment configuration.`
      );
    } else if (hasManualScripts && !hasAutomationManualScripts && !hasAutomationScripts) {
      props.MyAlert.danger(`${BULK_LABELING.MANUAL_TESTCASES_ERROR}`);
    } else {
      getBrowserStackList().then((response) => {
        if (response.data.responseCode === 200) {
          setBrowserInstanceArray(response.data.responseObject);
        }
        getSauceLabsList().then((res) => {
          if (res.data.responseCode === 200) {
            setSauceLabInstanceArray(res.data.responseObject);
          }
          getAllLambdaTestsList().then((res) => {
            if (res.data.responseCode === 200) {
              setLambdaTestsInstanceArray(res.data.responseObject);
              setOpenRunSettings({ openModal: true, title: modalTitle });
            }
          });
        });
      });
    }
  };
  const closeRunSettings = () => {
    setOpenRunSettings({ openModal: false, title: '' });
  };
  // const closeMapToJiraModal=(value)=>{
  //     setOpenMapToJira(value)
  //  }
  let [reloadTree, setReloadTree] = useState(true);
  const reloadTreeData = (value) => {
    setReloadTree(value);
  };

  let [openConditionModal, setOpenConditionModal] = useState(false);
  const closeConditionEditModal = (value) => {
    setOpenConditionModal(value);
    setEditConditionData(null);
    setModuleNameForCascade(null);
  };
  let [moduleIdForAdd, setModuleIdForAdd] = useState(null);
  let [moduleNameForAdd, setModuleNameForAdd] = useState(null);
  let [nodeToAdd, setNodeToAdd] = useState(null);
  let [openScriptModal, setScriptModal] = useState(false);
  const [openManualScriptModal, setOpenManualScriptModal] = useState(false);
  const [openEditScriptModal, setOpenEditScriptModal] = useState(false);
  let licenseLevelMaxScriptCount = JSON.parse(localStorage.getItem('allowedProjects'));

  const createScript = () => {
    if (licenseLevelMaxScriptCount?.features.testDev.restrictions.numberOfScripts > licenseLevScriptCout) {
      setIsToggled(true);
      setScriptModal(true);
      setHideElements(null);
    } else {
      props.MyAlert.warning(`Exceed the allocated limit. Please upgrade the license or buy a new license`);
    }
  };
  const createManualTestCase = () => {
    if (licenseLevelMaxScriptCount?.features.testDev.restrictions.numberOfScripts > licenseLevScriptCout) {
      setIsToggled(true);
      setOpenManualScriptModal(true);
      setHideElements(null);
    } else {
      props.MyAlert.warning(`Exceed the allocated limit. Please upgrade the license or buy a new license`);
    }
  };
  const closeScriptModal = (value) => {
    setHideElements(value);
    setOpenManualScriptModal(value);
    setOpenEditScriptModal(value);
    setScriptModal(value);
    setModuleNameForAdd(null);
    setModuleIdForAdd(null);
    setNodeToAdd(null);
    setEditScriptData(null);
  };
  let [openCreateModal, setOpenCreateModal] = useState(false);
  const closeModal = (value) => {
    setOpenCreateModal(value);
    setHideElements(value);
    if (
      moduleNameForAdd !== 'Root Module' ||
      (moduleNameForAdd === 'Root Module' && rootData?.subModuleCount > 0 && rootData?.hierarchy === 0)
    ) {
      setModuleNameForAdd(null);
      setModuleIdForAdd(null);
      setNodeToAdd(null);
    }
    setEditModuleData(null);
  };
  const createModule = () => {
    setOpenCreateModal(true);
    setHideElements(null);
  };
  const closeMapToJiraModal = (value) => {
    setOpenMapToJira(value);
  };
  let [openDetailsModal, setOpenDetailsModal] = useState(false);
  let [detailsType, setDetailsType] = useState('folder');
  let [details, setDetails] = useState({});
  const closeDetailsModal = (value) => {
    setOpenDetailsModal(value);
  };

  let [editModuleData, setEditModuleData] = useState(null);
  async function getModule(moduleId) {
    try {
      let getdata = await getSingleModuleReq(moduleId);
      setEditModuleData(getdata.data.responseObject);
    } catch (err) {
      console.error(err);
    }
  }
  let [editScriptData, setEditScriptData] = useState(null);
  async function getScript(moduleId, scriptId) {
    try {
      let getdata = await getScriptReq(moduleId, scriptId);
      if (getdata.data.responseObject.testCaseType === 'manual') {
        const pair = getdata.data.responseObject?.pair === false ? false : true;
        setIsToggled(pair);
      }
      setEditScriptData(getdata.data.responseObject);
    } catch (err) {
      console.error(err);
    }
  }

  let [editConditionData, setEditConditionData] = useState(null);
  const [moduleNameForCascade, setModuleNameForCascade] = useState(null);

  const closeDeleteModal = (value) => {
    setOpenDeleteModal(value);
  };

  const DeleteFunction = async (selectedOption = {}) => {
    try {
      if (deleteData.folder) {
        let response = await DeleteModuleReq(deleteData.key);
        if (response.data.responseCode === 200) {
          closeDeleteModal(false);
          setDeleteData();
          props.MyAlert.success(
            deleteData.title.length >= 20 ? (
              <>
                <div className="tosterMsg">
                  <div title={deleteData.title} className="lengthyName">{`${deleteData.title}`}</div>{' '}
                  <div> module deleted successfully</div>
                </div>
              </>
            ) : (
              <>
                <span title={deleteData.title}>{`${deleteData.title}`}</span> <span> module deleted successfully</span>
              </>
            )
          );
          reloadTreeData(true);
        }
      } else {
        if (['PRE', 'POST'].includes(deleteData?.parent?.type)) {
          if (deleteData.data.cascaded === 'cascaded') {
            const removeCascadeData = {
              ...deleteData.data,
              type: deleteData?.data?.type ? deleteData?.data?.type : deleteData?.type,
              id: deleteData.key,
              cascaded: 'not_cascaded',
              cascadedFrom: '',
            };
            await cascadeConditionToScriptReq(
              deleteData.data.parentId.slice(deleteData.data.parentId.indexOf('_') + 1),
              removeCascadeData
            );
          }
          let response = await deleteModulePrePostConditionReq(
            deleteData.data.parentId.slice(deleteData.data.parentId.indexOf('_') + 1),
            deleteData.key
          );
          if (response.data.responseCode === 200) {
            props.MyAlert.success(`${deleteData.title} deleted successfully`);
            reloadTreeData(true);
          }
          closeDeleteModal(false);
          setDeleteData();
        } else {
          const testCaseData = [...deleteData.data.testCaseType];
          testCaseData.forEach(async (tcd, index) => {
            if (tcd.type?.toLowerCase() === 'automation' && selectedOption.automationScript) {
              await DeleteScriptReq(deleteData.data.parentId, tcd.id);
            }
            if (tcd.type?.toLowerCase() === 'manual' && selectedOption.manualScript) {
              await DeleteScriptReq(deleteData.data.parentId, tcd.id);
            }
            if (index === testCaseData.length - 1) {
              setTimeout(() => {
                props.MyAlert.success(
                  deleteData.title.length >= 20 ? (
                    <>
                      <div className="tosterMsg">
                        <div title={deleteData.title} className="lengthyName">{`${deleteData.title}`}</div>{' '}
                        <div> script deleted successfully</div>
                      </div>
                    </>
                  ) : (
                    <>
                      <span title={deleteData.title}>{`${deleteData.title}`}</span>{' '}
                      <span> script deleted successfully</span>
                    </>
                  )
                );
                closeDeleteModal(false);
                setDeleteData();
                reloadTreeData(true);
              }, 1000);
            }
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const keyAndName = (parentName, treeData, path) => {
    treeData.forEach((data, index) => {
      if (data.title === parentName && data.folder) {
        let object = {
          Name: data.title,
          id: data.key,
        };
        newUpdatedPath += encodeSpecialCharater(data.title) + '/';
        keyAndNameArray.push(object);
        if (data.children) {
          let index = path.indexOf('/');
          let parentName;
          let alterPath;
          if (index === -1) {
            parentName = path;
            alterPath = path;
          } else {
            parentName = path.slice(0, index);
            alterPath = path.slice(index + 1, path.length);
          }
          keyAndName(parentName, data.children, alterPath);
        }
      }
    });
    return { parentAndSonJson: keyAndNameArray, newPath: newUpdatedPath };
  };

  const onNodeSelected = async (nodeObj, onTitleClick = true) => {
    const getUrlPath = (json) => {
      let urlPath = '';
      for (const { Name, id, manualId } of json) {
        const scriptId =
          id === undefined && onTitleClick === true
            ? manualId
            : manualId !== undefined && onTitleClick === false
            ? manualId
            : id;
        urlPath += `${encodeSpecialCharater(Name)}_${scriptId}/`;
      }
      return urlPath;
    };
    let node = nodeObj.node;
    if (node.key === moduleId) {
      return;
    }
    const { parent: { data: { parentName, parentId } = {} } = {} } = node || {};
    if (node.folder || ['PRE', 'POST'].includes(node?.parent?.type)) {
      {
        let searchKeyArray = '';
        let nameArray = '';
        let parentSonJson;
        if (['PRE', 'POST'].includes(nodeObj?.node?.type) && node.children) {
          const { searchKey, path } = node?.parent?.data || {};
          if (searchKey) {
            searchKeyArray = searchKey.slice(1).split('/');
            nameArray = path.slice(1).split('/');
          }
        } else if (['PRE', 'POST'].includes(nodeObj?.node?.parent?.type) && !node.children) {
          const { searchKey, path } = node?.parent?.parent?.data || {};
          if (searchKey) {
            searchKeyArray = searchKey.slice(1).split('/');
            nameArray = path.slice(1).split('/');
          }
        } else {
          const { searchKey, path } = node?.data || {};
          if (searchKey) {
            searchKeyArray = searchKey.slice(1).split('/');
            nameArray = path.slice(1).split('/');
          }
        }
        if (searchKeyArray === '' && nameArray === '') {
          parentSonJson = [
            {
              Name: node?.data?.parentName || parentName,
              id: parentName ? parentId : node.data.parentId,
            },
          ];
        } else {
          const { json } = containerNavigation(searchKeyArray, nameArray);
          parentSonJson = json;
        }
        const modIdForReq = parentSonJson[parentSonJson.length - 1].id;
        localStorage.setItem('parentAndSonJson', JSON.stringify(parentSonJson));
        const newPath = getUrlPath(parentSonJson);
        if ([node?.type].includes('PRE')) {
          props.history.push(`/testdevelopment/Script/${newPath}Settings/Pre Condition?moduleId=${modIdForReq}`);
        } else if ([node?.type].includes('POST')) {
          props.history.push(`/testdevelopment/Script/${newPath}Settings/Post Condition?moduleId=${modIdForReq}`);
        } else {
          props.history.push(`/testdevelopment/Script/${newPath}Module?moduleId=${modIdForReq}`);
        }
      }
    } else {
      const searchKeyArray = nodeObj.data.searchKey.slice(1).split('/');
      const nameArray = nodeObj.data.path.slice(1).split('/');
      const { json } = containerNavigation(searchKeyArray, nameArray);
      const autoScriptId = nodeObj.data.testCaseType.find((scr) => scr.type === 'Automation')?.id;
      const manualScriptId = nodeObj.data.testCaseType.find((scr) => scr.type === 'manual')?.id;
      json[json.length - 1] = {
        Name: `${nodeObj.data.prefix} - ${nodeObj.data.name}`,
        id: autoScriptId,
        manualId: manualScriptId,
        parentId: nodeObj.data.parentId,
      };
      localStorage.setItem('parentAndSonJson', JSON.stringify(json));
      if (autoScriptId && onTitleClick) {
        props.history.push(
          `/testdevelopment/Script/${getUrlPath(json)}Steps?scriptId=${autoScriptId}&moduleId=${nodeObj.data.parentId}${
            manualScriptId ? '&manualScriptId=' + manualScriptId : ''
          }`
        );
      } else {
        props.history.push(
          `/testdevelopment/Script/${getUrlPath(json)}Manual Test Case?manualScriptId=${manualScriptId}&moduleId=${
            nodeObj.data.parentId
          }${autoScriptId ? '&scriptId=' + autoScriptId : ''}`
        );
      }
    }
  };

  const removePrePostCondition = (value) => {
    const selectedNode = node.node;
    if (value.status === 'enabled') {
      updatedValue(value, 'file');
    } else {
      if (selectedNode.getParent().hasChildren()) {
        if (
          selectedNode.getParent().getLastChild().title === selectedNode.getParent().getFirstChild().title &&
          selectedNode.getParent().children.length === 1
        ) {
          updateDropDown();
          selectedNode.getParent().remove();
        } else {
          updateDropDown();
          selectedNode.remove();
        }
      }
    }
  };

  const handleConditionsVisible = (conditionCheck) => {
    setConditionsVisible(conditionCheck);
  };

  const displayQuickStartButtons = () => {
    if (
      !isEditable ||
      conditionsVisible ||
      rootData.parentId ||
      rootData.subModuleCount > 0 ||
      isFilterApplied ||
      searchedTextQuery
    ) {
      return false;
    }
    return true;
  };

  const updatedValue = (value, type) => {
    const selectedNode = node.node;
    if (type === 'folder') {
      if (editModuleData) {
        const oldData = { ...selectedNode.data };
        selectedNode.title = value.name;
        selectedNode.data = { ...oldData, ...value };
        if (selectedNode.expanded === true) {
          selectedNode.setExpanded(true);
        }
        selectedNode.render(true, true);
        updateDropDown();
      } else {
        if (selectedNode.hasChildren()) {
          if (selectedNode.getLastChild().type === 'POST') {
            selectedNode.addChildren(value, selectedNode.getLastChild());
          } else {
            selectedNode.addChildren(value);
          }
        } else {
          selectedNode.addChildren(value);
        }
        if (selectedNode.expanded === true) {
          selectedNode.setExpanded(true);
        }
        updateDropDown();
      }
    } else {
      const oldData = { ...selectedNode.data };
      selectedNode.title = value.name;
      selectedNode.data = { ...oldData, ...value };
      if (selectedNode.expanded === true) {
        selectedNode.setExpanded(true);
      }
      selectedNode.render(true, true);
      updateDropDown();
    }
  };

  const onCheckedNodes = (nodes) => {
    const scriptList = nodes.filter((node) => {
      if (node?.data?.testCaseType) {
        return node;
      }
    });
    setCheckedScripts([...scriptList]);
    if (nodes.length > 0) {
      setNodeSelected(true);
      setSelectedNodes(nodes);
      setDisableHeadlessSwitch(!checkForScriptTypes(nodes));
    } else {
      setNodeSelected(false);
      setSelectedNodes(nodes);
      setDisableHeadlessSwitch(false);
    }
  };

  useEffect(() => {
    props.setSelectedScripts(checkedScripts);
  }, [checkedScripts]);

  const onEditClicked = async (ev, nodeObj) => {
    setNode(nodeObj);
    if (nodeObj.data.folder) {
      await getModule(nodeObj.data._key);
      setOpenCreateModal(true);
      setHideElements(null);
    } else {
      if (['PRE', 'POST'].includes(nodeObj?.node?.parent?.type)) {
        setEditConditionData(nodeObj.data);
        setModuleNameForCascade(nodeObj.node.parent.parent.title);
        // await getCondition(nodeObj.node.parent.data.parentId, nodeObj.data._key)
        setOpenConditionModal(true);
      } else {
        await getScript(nodeObj.data.parentId, nodeObj.data._key);
        setOpenEditScriptModal(true);
      }
    }
  };

  const onDeleteClicked = (ev, nodeObj) => {
    if (nodeObj.node.folder) {
      if (nodeObj.node.children) {
        props.MyAlert.warning(
          nodeObj.data.title.length >= 20 ? (
            <>
              <div className="tosterMsg">
                <div>You are not allowed to delete &nbsp;</div>{' '}
                <div title={nodeObj.data.title} className="lengthyName">{`${nodeObj.data.title},`}</div>{' '}
                <div>since it contains resources in it.</div>
              </div>
            </>
          ) : (
            <>
              <span>{`You are not allowed to delete ${nodeObj.data.title}, since it contains resources in it.`}</span>
            </>
          )
        );
      } else {
        setNode(nodeObj);
        let title = `${nodeObj.data.title?.substring(0, 50)}${nodeObj.data.title?.length > 50 ? '...' : ''}${' '}`;
        setMessage(`${title} Module`);
        setDeleteData(nodeObj.node);
        setOpenDeleteModal(true);
      }
    } else {
      if (['PRE', 'POST'].includes(nodeObj?.node?.parent?.type)) {
        setMessage(`${nodeObj.data.title} Step group`);
      } else {
        let title = `${nodeObj.data.title?.substring(0, 50)}${nodeObj.data.title?.length > 50 ? '...' : ''}${' '}`;
        setMessage(`${title} Script`);
      }
      setNode(nodeObj);
      setDeleteData(nodeObj.node);
      setOpenDeleteModal(true);
    }
  };

  const detailsModal = (type, nodeObj) => {
    if (type === 'folder') {
      if (nodeObj.isRootNode) {
        getModuleDetails(nodeObj.data.key);
      } else {
        getModuleDetails(nodeObj.data._key);
      }
    } else {
      if (type === 'file') {
        getScriptDetails(nodeObj.data.parentId, nodeObj.data._key);
      } else {
        getStepGroupDetails(nodeObj.data.libraryId, nodeObj.data.stepGroupId);
      }
    }
  };

  const navigateToHistory = async (nodeObj) => {
    return '';
    // this method is required for future,
    // curently parked (Functionality depended on Publish Journey Implementation)

    let node = nodeObj.node;
    let path;
    if (node.folder) {
      if (nodeObj.data.parentName === 'Root Module') {
        let libraryData = [
          {
            Name: node.title,
            id: node.key,
          },
        ];
        localStorage.setItem('parentAndSonJson', JSON.stringify(libraryData));
        props.history.push(`/testdevelopment/Script/${encodeSpecialCharater(node.title)}/History?moduleId=${node.key}`);
      } else {
        keyAndNameArray = [];
        path = trimPath(node.data.path);
        let index = path.indexOf('/');
        let parentName = index !== -1 ? path.slice(0, index) : path;
        let alterPath = index !== -1 ? path.slice(index + 1, path.length) : path;
        let treeForURLSearch;
        if (treeHeader !== 'Root Module') {
          treeForURLSearch = await getAllModuleTree();
        }
        const treeForSearch = treeHeader === 'Root Module' ? moduleTree[0].children : treeForURLSearch[0].children;
        let { parentAndSonJson, newPath } = keyAndName(parentName, treeForSearch, alterPath);
        let testObj = {};
        if (
          node.title !== 'Preconditions' ||
          node.title !== 'Postconditions' ||
          nodeObj.node.parent.title !== 'Preconditions' ||
          nodeObj.node.parent.title !== 'Postconditions'
        ) {
          testObj = {
            Name: node.title,
            id: node.key,
          };
        }
        if (testObj.Name && parentAndSonJson[parentAndSonJson.length - 1].Name !== testObj.Name) {
          parentAndSonJson.push(testObj);
          newPath += encodeSpecialCharater(testObj.Name) + '/';
        }
        localStorage.setItem('parentAndSonJson', JSON.stringify(parentAndSonJson));
        props.history.push(`/testdevelopment/Script/${newPath}History?moduleId=${node.key}`);
      }
    } else {
      let elementPath = nodeObj.data.path;
      keyAndNameArray = [];
      let index = elementPath.indexOf('Root');
      let alterPath = elementPath.slice(index + 5, elementPath.length);
      let parentName = alterPath.slice(0, alterPath.indexOf('/'));
      let withoutFirstName = alterPath.slice(alterPath.indexOf('/') + 1, alterPath.length);
      let actualPath = withoutFirstName.slice(0, withoutFirstName.lastIndexOf('/'));
      let treeForURLSearch;
      if (treeHeader !== 'Root Module') {
        treeForURLSearch = await getAllModuleTree();
      }
      const treeForSearch = treeHeader === 'Root Module' ? moduleTree[0].children : treeForURLSearch[0].children;
      let { parentAndSonJson, newPath } = keyAndName(parentName, treeForSearch, actualPath);
      let testObj = {
        Name: nodeObj.node.parent.title,
        id: nodeObj.node.parent.id,
      };
      if (testObj.Name && parentAndSonJson[parentAndSonJson.length - 1].Name !== testObj.Name) {
        parentAndSonJson.push(testObj);
        newPath += encodeSpecialCharater(testObj.Name) + '/';
      }
      const autoScriptId = nodeObj.data.testCaseType.find((scr) => scr.type === 'Automation')?.id;
      const manualScriptId = nodeObj.data.testCaseType.find((scr) => scr.type === 'manual')?.id;
      let stepGroupData = {
        Name: nodeObj.data.name,
        id: autoScriptId,
        manualId: manualScriptId,
        parentId: nodeObj.data.parentId,
      };
      newPath += encodeSpecialCharater(nodeObj.data.name);
      parentAndSonJson.push(stepGroupData);
      localStorage.setItem('parentAndSonJson', JSON.stringify(parentAndSonJson));
      props.history.push(
        `/testdevelopment/Script/${newPath}/History?moduleId=${nodeObj.data.parentId}${
          autoScriptId ? '&scriptId=' + autoScriptId : ''
        }${manualScriptId ? '&manualScriptId=' + manualScriptId : ''}`
      );
    }
  };

  const closeCloneModal = (val) => {
    setOpenCloneInfoModal(val);
  };

  const handleClickForDefects = async (nodeObj) => {
    try {
      const { data } = nodeObj || {};
      setDefectScriptData(data);
      setOpenLinkedDefectList(true);
    } catch (error) {
      console.error('GET_DEFECTS_LIST_REQ : ', error);
    }
  };

  const methodName = async (name, nodeObj) => {
    if (name === 'Details') {
      if (nodeObj.isRootNode) {
        detailsModal('folder', nodeObj);
      }
      if (nodeObj.node.folder) {
        detailsModal('folder', nodeObj);
      } else {
        if (nodeObj.node.parent) {
          if (['PRE', 'POST'].includes(nodeObj?.node?.parent?.type)) {
            detailsModal('stepgroup', nodeObj);
          } else {
            detailsModal('file', nodeObj);
          }
        }
      }
    } else if (name === 'History') {
      if (nodeObj.isRootNode) {
        const parentPath = props.location.pathname.slice(0, props.location.pathname.lastIndexOf('/'));
        props.history.push(`${parentPath}/History?moduleId=${moduleId}`);
      } else {
        navigateToHistory(nodeObj);
        // props.history.push(`/testdevelopment/Script/${nodeObj.node.parent.title}/${nodeObj.data.title}/History?moduleId=${nodeObj.data.parentId}&scriptId=${nodeObj.data._key}`);
      }
    } else if (name === 'Export in Java') {
      exportScript(getAutomationScriptId(nodeObj.data._key, nodeObj.data.testCaseType), nodeObj.data.parentId);
    } else if (name === 'folder') {
      setNode(nodeObj);
      setModuleIdForAdd(nodeObj.data._key);
      setModuleNameForAdd(nodeObj.data.title);
      setNodeToAdd(nodeObj.node);
      setOpenCreateModal(true);
      setHideElements(null);
    } else if (name === 'file auto' || name === 'file manual') {
      let checkLicenseValidation = false;
      if (licenseLevelMaxScriptCount?.features.testDev.restrictions.numberOfScripts > licenseLevScriptCout) {
        if (
          licenseLevelMaxScriptCount?.features.testDev.restrictions.numberOfScriptsForModule >
          nodeObj.data.moduleLevelScriptCount
        ) {
          checkLicenseValidation = false;
        } else {
          checkLicenseValidation = true;
        }
      } else {
        checkLicenseValidation = true;
      }
      if (!checkLicenseValidation) {
        setNode(nodeObj);
        setModuleIdForAdd(nodeObj.data._key);
        setModuleNameForAdd(nodeObj.data.title);
        setNodeToAdd(nodeObj.node);
        if (name === 'file auto') {
          setScriptModal(true);
          setIsToggled(true);
        } else {
          setIsToggled(true);
          setOpenManualScriptModal(true);
        }
        setHideElements(null);
      } else {
        props.MyAlert.warning(`Exceed the allocated limit. Please upgrade the license or buy a new license`);
      }
    } else if (name === 'Clone') {
      let checkLicenseValidation = false;
      if (licenseLevelMaxScriptCount?.features.testDev.restrictions.numberOfScripts > licenseLevScriptCout) {
        if (nodeObj.data.folder) {
          if (
            licenseLevelMaxScriptCount?.features.testDev.restrictions.numberOfScriptsForModule >
            nodeObj.data.moduleLevelScriptCount
          ) {
            checkLicenseValidation = false;
          } else {
            checkLicenseValidation = true;
          }
        } else {
          if (
            licenseLevelMaxScriptCount?.features.testDev.restrictions.numberOfScriptsForModule >
            nodeObj.node.parent.data.moduleLevelScriptCount
          ) {
            checkLicenseValidation = false;
          } else {
            checkLicenseValidation = true;
          }
        }
      } else {
        checkLicenseValidation = true;
      }
      if (!checkLicenseValidation) {
        if (nodeObj.data.folder) {
          setCloningContainerData(nodeObj.data);
          setOpenCloneInfoModal(true);
        } else {
          cloneScrpt(nodeObj.data);
        }
      } else {
        props.MyAlert.warning(`Exceed the allocated limit. Please upgrade the license or buy a new license`);
      }
    } else if (name === 'Move') {
      setMoveResourceModalData({
        ...moveResourceModalData,
        open: true,
        srcNodeData: nodeObj,
      });
    } else if (name === 'Map to Jira') {
      handelClickForJira(nodeObj);
      setScriptData(nodeObj);
    } else if (name === 'Linked Defects') {
      handleClickForDefects(nodeObj);
    } else if (name === 'Export Manual Test cases') {
      try {
        let response = await exportManualTestCaseFiles(nodeObj?.node?.key);
        if (response.status === 200) {
          saveFileFromBlob(response.data, `${project?.name}_Manual_Test_Case_Export_${getTimeStampForFileName()}.xlsx`);
          props.MyAlert.success(`Manual Test Cases Exported Successfully`);
        } else {
          props.MyAlert.info(`Manual Test Cases Not found`);
        }
      } catch (error) {
        console.error('ERROR IN MANAUL TESTCASE DATA EXPORT API', error);
      }
    }
  };

  const cloneScrpt = async (scrptData) => {
    setShowLoaderModal(false);
    let response;
    try {
      response = await cloneReq(scrptData._key, scrptData.parentId, 'scripts', project.id, null);
      if (response?.data?.status === 'SUCCESS' || response?.data?.message === 'SUCCESS') {
        setShowLoaderModal(false);
        setReloadTree(true);
      }
      bannerMessage(scrptData.title, 'script');
    } catch (err) {
      console.error('clone script api error', err);
    }
  };

  const cloneContainer = async (mduleData) => {
    setShowLoaderModal(false);
    let response;
    try {
      response = await cloneReq(mduleData._key, mduleData.parentId, 'modules', project.id, null);
      if (response?.data?.message === 'SUCCESS' || response?.data?.status === 'SUCCESS') {
        setShowLoaderModal(false);
        setReloadTree(true);
      }
      bannerMessage(mduleData.title, 'module');
    } catch (err) {
      console.error('clone module api error', err);
    }
  };

  const bannerMessage = (title, key) => {
    props.MyAlert.success(`${title?.length > 22 ? title?.substring(0, 22) + '...' : title} ${key} cloned successfully`);
  };
  //   const  cloneToJiraModal=(type,nodeObj)=>{
  //     setScriptData(nodeObj)
  //     setOpenMapToJira(true)
  // }
  let getModuleDetails = async (key) => {
    let res = await getSingleModuleReq(key);
    if (res.data.responseCode === 200 && res.data.responseObject) {
      setDetails(res.data.responseObject);
      setDetailsType('folder');
      setOpenDetailsModal(true);
    }
  };

  let getScriptDetails = async (module, key) => {
    let res = await getScriptReq(module, key);
    if (res.data.responseCode === 200 && res.data.responseObject) {
      setDetails(res.data.responseObject);
      setDetailsType('file');
      setOpenDetailsModal(true);
    }
  };
  const getStepGroupDetails = async (module, key) => {
    let res = await getStepGroupReq(module, key);
    if (res.data.responseCode === 200 && res.data.responseObject) {
      setDetails(res.data.responseObject);
      setDetailsType('stepgroup');
      setOpenDetailsModal(true);
    }
  };
  const stateColumnHtml = (nodeObj) => {
    if (
      !['PRE', 'POST'].includes(nodeObj?.node?.type) ||
      !(nodeObj.data.folder || nodeObj.isRootNode || ['PRE', 'POST'].includes(nodeObj?.data?.type))
    ) {
      return (
        <div className="state-col-wrp">
          <button className="state-button py-0 px-1 rounded-l">New</button>
          <button className="state-active py-0 px-1 rounded-l">Commit</button>
          <button className="state-button py-0 px-1 rounded-l">Publish</button>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const actionColumnHtml = (nodeObj) => {
    let type;
    if (nodeObj.isRootNode) {
      type = 'root';
    } else if (nodeObj.node.folder) {
      type = 'folder';
    } else {
      type = 'nonFolder';
    }

    let actionMenuFolderList = actionMenuFolder;
    if (isManualTestcaseAvaliable === false) {
      actionMenuFolderList = actionMenuFolder?.filter((val) => val.name !== 'Export Manual Test cases');
    }

    return (
      <div className="action-col-wrp">
        {/* commenting the code for future use */}
        {/* {!(
              nodeObj.data.folder ||
              nodeObj.isRootNode ||
              nodeObj.data.type === "PRE" ||
              nodeObj.data.type === "POST"
            ) ? (
              <button className="state-button py-0 px-1 rounded-l">
                <ImpactListActionMenu
                  optionList={[
                    { name: "Depends on script" },
                    { name: "Suite" },
                  ]}
                  nodeObj={nodeObj}
                  methodName={impactListHandler}
                />
              </button>
            ) : null} */}

        {isEditable && (!['PRE', 'POST'].includes(nodeObj.data.type) || !(nodeObj.data.folder || nodeObj.isRootNode))
          ? !nodeObj.isRootNode && (
              <button
                disabled={nodeObj.isRootNode}
                className="state-button py-0 px-1 rounded-l"
                onClick={(event) => {
                  onEditClicked(event, nodeObj);
                }}
              >
                <Tooltip title="Edit" placement="top">
                  <EditOutlined />
                </Tooltip>
              </button>
            )
          : null}
        {hasFullAccess && (!['PRE', 'POST'].includes(nodeObj.data.type) || !(nodeObj.data.folder || nodeObj.isRootNode))
          ? !nodeObj.isRootNode && (
              <button
                disabled={nodeObj.isRootNode}
                className="state-button py-0 px-1 rounded-l"
                onClick={(event) => {
                  onDeleteClicked(event, nodeObj);
                }}
              >
                <Tooltip title="Delete" placement="top">
                  <DeleteOutlined />
                </Tooltip>
              </button>
            )
          : null}
        {!['PRE', 'POST'].includes(nodeObj.data.type) || !(nodeObj.data.folder || nodeObj.isRootNode)
          ? !nodeObj.isRootNode && (
              <MoreInfoButton
                methodListOfFolder={hasCloneOption ? actionMenuFolderList : actionMenuRoot}
                methodListOfNonFolder={
                  hasCloneOption && !['PRE', 'POST'].includes(nodeObj.data.type)
                    ? actionMenuFile
                    : actionMenuFileNoClone
                }
                methodListForRoot={actionMenuRoot}
                nodeObj={nodeObj}
                methodName={methodName}
                typeToDisplay="more"
                type={type}
              />
            )
          : null}
      </div>
    );
  };

  const colDefs = [
    {
      field: 'title',
      title: 'MODULE',
      class: 'title',
      width: `550px`,
      folderTitleWidth: `calc(100% - 670px)`,
      paddingRight: '20px',
      isTitle: true,
      render: (nodeObj) => {
        return (
          <>
            {nodeObj.data.cascaded === 'cascaded' && (
              <span
                onClick={() => {
                  onNodeSelected(nodeObj);
                }}
                className="mt-1 text-blue-700 cursor-pointer hover:text-underline"
              >
                {' '}
                - Cascaded{' '}
              </span>
            )}
            {nodeObj.isRootNode && (
              <>
                <Tooltip title={`Modules - ${nodeObj.data.subModuleCount}`} placement="bottom">
                  <span className="folder-count count-badge cursor-pointer"> M{nodeObj.data.subModuleCount} </span>
                </Tooltip>
                <Tooltip title={`Script - ${nodeObj.data.scriptCount}`} placement="bottom">
                  <span className="file-count count-badge cursor-pointer"> S{nodeObj.data.scriptCount} </span>
                </Tooltip>
                {isEditable && nodeObj?.data?.hierarchy === 0 ? (
                  <button className="add-btn -mt-1.5">
                    <span
                      onClick={() => {
                        methodName('folder', {
                          ...nodeObj,
                          node: {
                            data: nodeObj.data,
                            title: nodeObj.data.title,
                            _key: nodeObj.data.key,
                            key: nodeObj.data.key,
                          },
                        });
                      }}
                    >
                      <Tooltip title="Add Module" placement="right">
                        <AddCircleOutlineOutlined className="text-gray-400 hover:text-gray-600" />
                      </Tooltip>
                    </span>
                  </button>
                ) : null}
              </>
            )}
            {nodeObj.data.folder && !['PRE', 'POST'].includes(nodeObj?.data?.type) && (
              <>
                <Tooltip title={`Sub Modules - ${nodeObj.data.subModuleCount}`} placement="bottom">
                  <span className="file-count count-badge cursor-pointer"> SM{nodeObj.data.subModuleCount} </span>
                </Tooltip>
                <Tooltip title={`Scripts - ${nodeObj.data.scriptCount}`} placement="bottom">
                  <span className="file-count count-badge cursor-pointer"> S{nodeObj.data.scriptCount} </span>
                </Tooltip>
              </>
            )}
            {nodeObj.data.folder && ['PRE', 'POST'].includes(nodeObj?.data?.type) && (
              <span className="file-count count-badge"> SG{nodeObj.data.scriptCount} </span>
            )}
            {isEditable && nodeObj.data.folder && !['PRE', 'POST'].includes(nodeObj?.data?.type) && (
              <Tooltip title="Add" placement="right">
                <button className="add-btn">
                  <MoreInfoButton
                    type="add"
                    nodeObj={nodeObj}
                    plusIconArray={getPlusIconArray(menuFeatures, hasAutomationBasedOnProjType)}
                    methodName={methodName}
                  />
                </button>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      field: 'testCaseType',
      title: 'Test Case Type',
      class: 'uppercase',
      width: '215px',
      render: (nodeObj) => {
        return (
          <TestCaseTypeCol
            MyAlert={props.MyAlert}
            nodeObj={nodeObj}
            onNodeSelected={onNodeSelected}
            history={props.history}
            hasEditAccess={isEditable}
            isToggled={isToggled}
            setIsToggled={setIsToggled}
          />
        );
      },
    },
    {
      field: 'scriptType',
      title: 'Type',
      class: 'Type uppercase',
      width: '135px',
      render: (nodeObj) => {
        return (
          <Tooltip placement="top-start" title={nodeObj.data.scriptType}>
            <span className=" text-xs table-non-link-color-common capitalize fontPoppinsRegularMd">
              {nodeObj.data.scriptType?.length > scriptTypeMaxLength
                ? nodeObj.data.scriptType?.toLowerCase().slice(0, scriptTypeMaxLength) + '...'
                : nodeObj.data.scriptType?.toLowerCase()}
            </span>
          </Tooltip>
        ); //{nodeObj.data.scriptType?.toLowerCase()}
      },
    },
    // {
    //     field: "State",
    //     title: "State",
    //     class: "state uppercase",
    //     width: "162px",
    //     render: stateColumnHtml,
    // },
    // {
    //     field: "version",
    //     title: "Version",
    //     class: "version uppercase",
    //     width: "60px",
    //     render: (nodeObj) => {
    //         return <span span className="version-text-color cursor-pointer"
    //             onClick={() => {
    //                     navigateToHistory(nodeObj)}
    //             }> {nodeObj.data.ver ? nodeObj.data.ver.toFixed(1) : ""}</span >
    //     },
    // },

    {
      field: 'action',
      title: 'Actions',
      class: 'action uppercase',
      width: '110px',
      render: actionColumnHtml,
    },
  ];
  function updateDropDown() {
    getSingleModuleTreeReq(moduleId, false).then((response) => {
      setDropDownFolderTree(response.data.responseObject.moduleTree);
      if (response.data.responseObject.moduleTree.length === 0) {
        props.history.push(`/testdevelopment/Script`);
      } else if (
        response.data.responseObject.moduleTree[0].title === 'Root Module' &&
        response.data.responseObject.moduleTree[0].subModuleCount === 0
      ) {
        reloadTreeData(true);
      } else if (
        response.data.responseObject.moduleTree[0].subModuleCount === 0 &&
        response.data.responseObject.moduleTree[0].scriptCount === 0
      ) {
        reloadTreeData(true);
      }
    });
  }

  async function getOnlyContainersTree(id) {
    try {
      const response = await getSingleModuleTreeReq(id, false);
      if (response?.data?.responseObject?.moduleTree?.length) {
        setDropDownFolderTree(response.data.responseObject.moduleTree);
        setModuleTree(response.data.responseObject.moduleTree);
      } else {
        setDropDownFolderTree([]);
      }
    } catch (err) {
      console.error('GET_ONLY_MODULES_TREE : ', err);
    }
  }

  async function getModuleTree(mode, _searchText, action) {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
      isBulkLoad: true,
    });
    setIsLoading(true);
    let getdata;
    let root;
    let rootChildren;
    try {
      const requestBody = {
        module: 'module',
        collectionName: 'modules',
        searchText: _searchText,
        additionalSearchText: moduleId,
        facetQueries: [
          { name: 'testCaseType', values: caseType },
          { name: 'type', values: scriptType },
          { name: 'labels', values: labels },
          { name: 'createdBy', values: createdBy },
          { name: 'modifiedBy', values: modifiedBy },
          { name: 'createdOn', values: getVerifiedDateAuto(createdOn) },
          { name: 'modifiedOn', values: getVerifiedDateAuto(modifiedOn) },
        ],
      };

      if (mode === 'search') {
        setSearchedTextQuery(_searchText);
        if (action === defectAnalyticsLabel.btnLabel.clearAll) {
          requestBody['facetQueries']?.map((obj) => (obj.values = []));
          setIsFilterApplied(false);
        }

        requestBody['facetQueries'] = requestBody['facetQueries']?.filter((obj) => {
          if (obj?.values?.length) return obj;
        });

        getdata = await getAnalyticsReq(requestBody);

        if (action === defectAnalyticsLabel.btnLabel.apply) {
          setIsFilterApplied(true);
          let selectedFilterList = {
            caseType,
            scriptType,
            labels,
            createdBy,
            modifiedBy,
            createdOn: { from: getVerifiedDateAuto(createdOn)?.at(0), to: getVerifiedDateAuto(createdOn)?.at(1) },
            modifiedOn: { from: getVerifiedDateAuto(modifiedOn)?.at(0), to: getVerifiedDateAuto(modifiedOn)?.at(1) },
          };

          sessionStorage.setItem('singleLevelModuleTreeFilters', JSON.stringify(selectedFilterList));
          setAnchorEl(null);
          openFilterModal = false;
        }
      } else {
        setSearchedText('');
        setSearchedTextQuery('');
        setCheckedScripts([]);
        if (isFilterApplied && action !== defectAnalyticsLabel.btnLabel.clearAll) {
          getModuleTree('search', '', defectAnalyticsLabel.btnLabel.apply);
        } else {
          getOnlyContainersTree(moduleId);
          getdata = await getSingleModuleTreeReq(moduleId);
        }
      }

      resetProgress();
      if (getdata.data.responseObject) {
        let licenseLevScriptCout = getdata.data.responseObject.licenseLevelScriptCount;
        setIsManualTestcaseAvaliable(getdata?.data?.responseObject?.manualTestCaseExists);
        setLicenseLevScriptCout(licenseLevScriptCout);
        setModuleTree(getdata.data.responseObject.moduleTree);
        let rootDetails = getdata.data.responseObject.moduleTree[0];
        root = {
          subModuleCount: rootDetails.subModuleCount,
          title: rootDetails.title === 'Root' ? 'Root Module' : rootDetails.title,
          scriptCount: rootDetails.scriptCount,
          key: rootDetails.key,
          parentId: rootDetails?.parentId,
        };
        if (rootDetails.title === 'Root Module' && !rootDetails.parentName) {
          const { children: childrenData, ...rootNodeDetails } = rootDetails || {};
          root = {
            ...rootNodeDetails,
            folder: null,
            _key: rootNodeDetails.key,
          };
          if (rootDetails.children) {
            rootChildren = rootDetails.children;
            const filteredRootChildrenArray = rootDetails.children.filter(
              (child) => child.type !== 'PRE' && child.type !== 'POST'
            );
            if (filteredRootChildrenArray.length) {
              setModuleNameForAdd(null);
              setModuleIdForAdd(null);
              setNodeToAdd(null);
            } else {
              setModuleIdForAdd(rootDetails.key);
              setModuleNameForAdd(rootDetails.title);
              setNodeToAdd(0);
            }
          } else {
            rootChildren = [];
            setModuleIdForAdd(rootDetails.key);
            setModuleNameForAdd(rootDetails.title);
            setNodeToAdd(0);
          }
        } else {
          rootChildren = getdata.data.responseObject.moduleTree;
        }
        resetProgress();
        setRootData(root);
        setIsExpandAll(rootDetails.expanded);
        setTreeHeader(rootDetails.title);
        setTreeData(rootChildren);
        setIsLoading(false);
      } else {
        resetProgress();
        if (getdata?.data?.message === 'Not found') {
          setRootData([]);
          setTreeData([]);
          setIsLoading(false);
          resetProgress();
        } else {
          props.history.push(`/testdevelopment/Script`);
        }
      }
    } catch (err) {
      resetProgress();
      console.error('GET_SINGLE_MODULE_TREE : ', err);
    }
  }

  const searchTreeApi = async (_searchText) => {
    getModuleTree('search', _searchText);
    setCheckedScripts([]);
  };

  // TODO :: HANDLE FILTERS ::START

  let [isFilterApplied, setIsFilterApplied] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  let openFilterModal = Boolean(anchorEl);
  const [caseType, setCaseType] = React.useState([]);
  const [scriptType, setScriptType] = React.useState([]);
  const [createdBy, setCreatedBy] = React.useState([]);
  const [labels, setLabels] = React.useState([]);
  const [modifiedBy, setModifiedBy] = useState([]);

  const [createdOn, setCreatedOn] = React.useState({
    from: null,
    to: null,
  });
  const [modifiedOn, setModifiedOn] = React.useState({
    from: null,
    to: null,
  });

  const [disableApplyClearBtn, setDisableApplyClearBtn] = useState(true);

  const [optionsListItem, setOptionsListItem] = useState({
    caseType: [],
    scriptType: [],
    createdBy: [],
    labels: [],
    modifiedBy: [],
  });

  let showSelectedList = [{ caseType }, { scriptType }, { createdBy }, { labels }, { modifiedBy }];

  let renderChips = (listName) => {
    switch (listName) {
      case 'caseType':
        return caseType;
      case 'scriptType':
        return scriptType;
      case 'createdBy':
        return createdBy;
      case 'labels':
        return labels;
      case 'modifiedBy':
        return modifiedBy;
    }
  };

  const handleFilterOpen = async (event) => {
    setAnchorEl(event.currentTarget);

    let payload = {
      module: 'module',
      collectionName: 'modules',
      searchText: '',
      additionalSearchText: moduleId,
      facetQueries: [],
    };
    try {
      let response = await getAnalyticsReq(payload);
      let filterResponse = response?.data?.filterResponse || [];

      filterResponse?.filter((val) => {
        if (Object?.keys(val?.values)?.length) {
          return (val.values = Object?.keys(val?.values));
        } else {
          return (val.values = []);
        }
      });

      let getValues = (data, type) => {
        let values = [];
        data?.forEach((val) => {
          if (val?.name === type) {
            values = val?.values;
          }
        });
        return values;
      };

      setOptionsListItem({
        caseType: getValues(filterResponse, 'testCaseType'),
        scriptType: getValues(filterResponse, 'type'),
        createdBy: getValues(filterResponse, 'createdBy'),
        labels: getValues(filterResponse, 'labels'),
        modifiedBy: getValues(filterResponse, 'modifiedBy'),
      });

      if (isFilterApplied) {
        let retriveData = JSON.parse(sessionStorage.getItem('singleLevelModuleTreeFilters') || null);
        setCaseType(retriveData?.caseType);
        setScriptType(retriveData?.scriptType);
        setCreatedBy(retriveData?.createdBy);
        setLabels(retriveData?.labels);
        setModifiedBy(retriveData?.modifiedBy);

        setCreatedOn({
          from: convertDateHyphenReverse(retriveData?.createdOn?.from),
          to: convertDateHyphenReverse(retriveData?.createdOn?.to),
        });
        setModifiedOn({
          from: convertDateHyphenReverse(retriveData?.modifiedOn?.from),
          to: convertDateHyphenReverse(retriveData?.modifiedOn?.to),
        });
      }
    } catch (err) {
      console.error('ERROR IN GET OPTION LIST', err);
    }
  };

  const handleFilterClose = () => {
    if (!isFilterApplied) {
      setCaseType([]);
      setScriptType([]);
      setCreatedBy([]);
      setLabels([]);
      setModifiedBy([]);
      setCreatedOn({ from: null, to: null });
      setModifiedOn({ from: null, to: null });
    }
    setAnchorEl(null);
  };

  const handleSelectDropdown = (event, action, perfromOn) => {
    if (action === 'add') {
      const selectedValue = typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
      switch (perfromOn) {
        case 'caseType':
          return setCaseType(selectedValue);
        case 'scriptType':
          return setScriptType(selectedValue);
        case 'createdBy':
          return setCreatedBy(selectedValue);
        case 'labels':
          return setLabels(selectedValue);
        case 'modifiedBy':
          return setModifiedBy(selectedValue);
      }
    } else if (action === 'delete') {
      let valuetoDelete = typeof event === 'string' ? event : '';
      switch (perfromOn) {
        case 'caseType':
          return setCaseType(caseType.filter((val) => val !== valuetoDelete));
        case 'scriptType':
          return setScriptType(scriptType.filter((val) => val !== valuetoDelete));
        case 'createdBy':
          return setCreatedBy(createdBy.filter((val) => val !== valuetoDelete));
        case 'labels':
          return setLabels(labels.filter((val) => val !== valuetoDelete));
        case 'modifiedBy':
          return setModifiedBy(modifiedBy.filter((val) => val !== valuetoDelete));
      }
    }
  };

  let handleCreatedOnDate = (e, action) => {
    if (action == 'from') {
      setCreatedOn((perVal) => ({ ...perVal, from: e }));
    } else if (action == 'to') {
      setCreatedOn((perVal) => ({ ...perVal, to: e }));
    }
  };

  let handleModifiedOnDate = (e, action) => {
    if (action == 'from') {
      setModifiedOn((perVal) => ({ ...perVal, from: e }));
    } else if (action == 'to') {
      setModifiedOn((perVal) => ({ ...perVal, to: e }));
    }
  };

  let handleApplyFilter = async (action) => {
    if (action === defectAnalyticsLabel.btnLabel.apply) {
      getModuleTree('search', searchedTextQuery, action);
    } else if (action === defectAnalyticsLabel.btnLabel.clearAll) {
      setCaseType([]);
      setScriptType([]);
      setCreatedBy([]);
      setLabels([]);
      setModifiedBy([]);
      setCreatedOn({ from: null, to: null });
      setModifiedOn({ from: null, to: null });

      if (isFilterApplied) {
        setIsFilterApplied(false);

        let mode = searchedTextQuery ? 'search' : 'RESET';

        getModuleTree(mode, searchedTextQuery, action);
        sessionStorage.removeItem('singleLevelModuleTreeFilters');
      }
    }
    setCheckedScripts([]);
  };

  // TODO :: disable apply and clear all button
  useEffect(() => {
    if (!isEmptyValue([...caseType, ...scriptType, ...createdBy, ...labels, ...modifiedBy])) {
      setDisableApplyClearBtn(false);
    } else if ((createdOn.from && createdOn.to) || (modifiedOn.from && modifiedOn.to)) {
      setDisableApplyClearBtn(false);
    } else {
      setDisableApplyClearBtn(true);
    }
  }, [caseType, scriptType, createdBy, labels, modifiedBy, createdOn, modifiedOn]);

  // TODO :: HANDLE FILTERS :: END

  if (isLoading) {
    renderTree = <CommonLoader />;
  } else if (treeHeader !== 'Root Module' || (treeData && treeData.length > 0 && !(rootData.subModuleCount === 0))) {
    renderTree = (
      <TableTree
        data={treeData}
        rootData={rootData}
        colDefs={colDefs}
        filter={treeValue}
        expandAll={isExpandAll}
        hideElements={hideElements}
        id="moduleTreeTD"
        hideConditions={true}
        hideElementsBtn={isOnlyDefects}
        handleConditionsVisible={handleConditionsVisible}
        showCheckbox={showCheckBox}
        conditions={[
          { key: 'type', value: 'PRE' },
          { key: 'type', value: 'POST' },
        ]}
        hideConditionBtn={getHideCondtionBtn()}
        onCheckedNodes={onCheckedNodes}
        labels={LABELS}
        onNodeSelected={onNodeSelected}
        userSearched={searchedTextQuery}
        rootClass={'root-module-width-style'}
        isFilterApplied={isFilterApplied}
      />
    );
  } else if (treeHeader !== 'Root Module' || (treeData && treeData.length > 0 && rootData.subModuleCount === 0)) {
    renderTree = (
      <TableTree
        data={treeData}
        rootData={rootData}
        colDefs={colDefs}
        filter={treeValue}
        expandAll={isExpandAll}
        hideElements={hideElements}
        id="moduleTreeTD"
        hideConditions={true}
        handleConditionsVisible={handleConditionsVisible}
        showCheckbox={showCheckBox}
        defaultDisabled={[{ key: 'data.containsAutomationScript', value: false }]}
        conditions={[
          { key: 'type', value: 'PRE' },
          { key: 'type', value: 'POST' },
        ]}
        hideConditionBtn={getHideCondtionBtn()}
        hideElementsBtn={isOnlyDefects}
        onCheckedNodes={onCheckedNodes}
        labels={LABELS}
        onNodeSelected={onNodeSelected}
        userSearched={searchedTextQuery}
        rootClass={'root-module-width-style'}
        isFilterApplied={isFilterApplied}
      />
    );
  } else if (treeData?.length === 0 && searchedText?.length) {
    renderTree = (
      <div className="">
        <TableTree
          data={treeData}
          rootData={rootData}
          colDefs={colDefs}
          filter={treeValue}
          expandAll={isExpandAll}
          hideElements={hideElements}
          id="moduleTreeTD"
          hideConditions={true}
          showCheckbox={showCheckBox}
          conditions={[
            { key: 'type', value: 'PRE' },
            { key: 'type', value: 'POST' },
          ]}
          defaultDisabled={[{ key: 'data.containsAutomationScript', value: false }]}
          hideConditionBtn={getHideCondtionBtn()}
          hideElementsBtn={isOnlyDefects}
          onCheckedNodes={onCheckedNodes}
          labels={LABELS}
          onNodeSelected={onNodeSelected}
          userSearched={searchedTextQuery}
          isFilterApplied={isFilterApplied}
        />
      </div>
    );
  } else if (treeHeader === 'Root Module' && treeData.parentName === undefined && treeData.length === 0) {
    renderTree = (
      <div className="">
        <TableTree
          data={[]}
          rootData={rootData}
          colDefs={colDefs}
          filter={treeValue}
          expandAll={isExpandAll}
          hideElements={hideElements}
          id="moduleTreeTD"
          hideConditions={true}
          showCheckbox={showCheckBox}
          defaultDisabled={[{ key: 'data.containsAutomationScript', value: false }]}
          conditions={[
            { key: 'type', value: 'PRE' },
            { key: 'type', value: 'POST' },
          ]}
          hideConditionBtn={getHideCondtionBtn()}
          hideElementsBtn={isOnlyDefects}
          onCheckedNodes={onCheckedNodes}
          labels={LABELS}
          onNodeSelected={onNodeSelected}
          userSearched={searchedTextQuery}
          handleConditionsVisible={handleConditionsVisible}
          isFilterApplied={isFilterApplied}
        />
      </div>
    );
  }
  async function getUserAllAvailableMachine() {
    try {
      const response = await getUserAllAvailableMachineReq(user.id, project.id);
      const qrsResponse = response?.data?.responseMap?.projectQuickRunSetting;
      if (qrsResponse) {
        let defaultMachine = qrsResponse?.defaultMachine;
        setselectedDefaultMachine(defaultMachine);
        const runSetApiData = qrsResponse;
        setQuickRunSettingsApiData(runSetApiData);
      } else if (response?.data?.responseCode === 404) {
        let defaultValue = response.data.responseCode;
        setselectedDefaultValue(defaultValue);
      }
    } catch (err) {
      console.error('GET_QRS_CONFIG : ', err);
    }
  }

  async function getAllModuleTree() {
    try {
      const res = await getAllModuleTreeReq();
      if (res.data?.responseObject) {
        return res.data.responseObject.moduleTree;
      }
    } catch (err) {
      console.error(err);
    }
  }
  useEffect(() => {
    setReloadTree(true);
  }, [moduleId]);

  useEffect(() => {
    if (reloadTree) {
      setTreeData([]);
      getUserAllAvailableMachine();
      if (searchedTextQuery) {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => getModuleTree('search', searchedTextQuery), 800);
      } else {
        getModuleTree();
      }
    }
    setReloadTree(false);
  }, [reloadTree]);

  return (
    <div className="page-table-body">
      <div className="content_area_style" id="journal-scroll">
        {memoryWarningPopup && (
          <MemoryWarningPopup
            handleCancel={() => setMemoryWarningPopup(false)}
            handleContinue={() => run(true)}
            isOpen={memoryWarningPopup}
          />
        )}
        <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
          <label className="project_label text-sm flex-auto">
            <div title={treeHeader}>
              {treeHeader.length >= 52
                ? treeHeader.substring(0, 50) + '...'
                : treeHeader.toLowerCase().search('module') !== -1
                ? treeHeader
                : treeHeader + ' Module'}
            </div>
          </label>
          <div className="pr-4">
            <div className="flex flex-row items-center ml-32">
              {displaySearchFilter && (
                <TreeSearchBar
                  treeData={treeData}
                  searchTree={searchTreeApi}
                  resetTree={getModuleTree}
                  setSearchedText={setSearchedText}
                />
              )}

              {/* TODO :: FILTER BLOCK :: START */}

              {displaySearchFilter && !isOnlyDefects && (
                <FilterModal
                  open={openFilterModal}
                  anchorEl={anchorEl}
                  onClose={handleFilterClose}
                  handleFilterOpen={handleFilterOpen}
                  onApply={handleApplyFilter}
                  isFilterApplied={isFilterApplied}
                  disableFilters={false}
                  disableClearAllBtn={disableApplyClearBtn}
                  disableApplyBtn={disableApplyClearBtn}
                >
                  <div
                    className={
                      showSelectedList?.some((val) => val[Object?.keys(val)]?.length > 0)
                        ? 'border-2 rounded mb-2 max-h-40 overflow-auto'
                        : 'hidden'
                    }
                  >
                    {showSelectedList?.map((obj, index) => {
                      let itemDisplay = obj[Object?.keys(obj)];
                      if (!isEmptyValue(itemDisplay)) {
                        return (
                          <div className="p-2" key={index + 1}>
                            <div className="rs-blue capitalize fontPoppinsSemiboldSm border-b-2">
                              {getSelectedFilterLableName(Object?.keys(obj)?.[0])}
                            </div>
                            <div className="chips-section flex flex-wrap p-1">
                              {renderChips(Object?.keys(obj)?.[0])?.map((val, chipIndex) => {
                                return (
                                  <Chip
                                    key={chipIndex + 2}
                                    text={val}
                                    title={val}
                                    onClick={(e) => handleSelectDropdown(e, 'delete', Object.keys(obj)?.[0])}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>

                  <div className="grid grid-rows-1 grid-flow-col gap-2">
                    <div className=" flex gap-2 mt-1 ">
                      <FormControl sx={{ width: 173 }}>
                        <label className="fontPoppinsRegularSm mb-1"> Test Case Type</label>
                        <MultipleSelectCheckmarks
                          options={optionsListItem['caseType'] || []}
                          onChange={(e) => handleSelectDropdown(e, 'add', 'caseType')}
                          value={caseType}
                        />
                      </FormControl>
                      <FormControl sx={{ width: 173 }}>
                        <label className="fontPoppinsRegularSm mb-1"> Script Type</label>
                        <MultipleSelectCheckmarks
                          options={optionsListItem['scriptType'] || []}
                          onChange={(e) => handleSelectDropdown(e, 'add', 'scriptType')}
                          value={scriptType}
                        />
                      </FormControl>

                      <FormControl sx={{ width: 173 }}>
                        <label className="fontPoppinsRegularSm mb-1"> Labels </label>
                        <MultipleSelectCheckmarks
                          options={optionsListItem['labels'] || []}
                          onChange={(e) => handleSelectDropdown(e, 'add', 'labels')}
                          value={labels}
                        />
                      </FormControl>
                    </div>

                    {/* <div className=" flex gap-2 mt-1 " >
                                            
                                            <FormControl sx={{ width: 173 }}>
                                            <label className="fontPoppinsRegularSm mb-1">Created By</label>
                                            <MultipleSelectCheckmarks
                                                options={optionsListItem["createdBy"] || []}
                                                onChange={(e) => handleSelectDropdown(e, "add", "createdBy")}
                                                value={createdBy}
                                            />
                                            </FormControl>

                                            <FormControl sx={{ width: 173 }}>
                                            <label className="fontPoppinsRegularSm mb-1">Created On</label>
                                            <DateSelector
                                                inputFormat={filterDisplayDateFormat}
                                                value={createdOn.from}
                                                onChange={(e) => handleCreatedOnDate(e, "from")}
                                                maxDate={createdOn?.to || new Date()} 
                                                label="From Date"
                                                
                                            />
                                            </FormControl>

                                            <FormControl sx={{ width: 173 }}>
                                            <label className="fontPoppinsRegularSm mb-1">&nbsp;</label>
                                            <DateSelector
                                                inputFormat={filterDisplayDateFormat}
                                                value={createdOn.to}
                                                onChange={(e) => handleCreatedOnDate(e, "to")}
                                                minDate={createdOn?.from}
                                                maxDate={new Date()}
                                                label="To Date"
                                                disabled={!createdOn.from}
                                            />
                                            </FormControl>

                        
                                        </div> */}

                    {/* <div className=" flex gap-2 mt-1 " >
                                            <FormControl sx={{ width: 173 }}>
                                            <label className="fontPoppinsRegularSm mb-1">Modified By</label>
                                            <MultipleSelectCheckmarks
                                                options={optionsListItem["modifiedBy"] || []}
                                                onChange={(e) => handleSelectDropdown(e, "add", "modifiedBy")}
                                                value={modifiedBy}
                                            />
                                            </FormControl>

                                            <FormControl sx={{ width: 173 }}>
                                            <label className="fontPoppinsRegularSm mb-1">Modified On</label>
                                            <DateSelector
                                                inputFormat={filterDisplayDateFormat}
                                                value={modifiedOn.from}
                                                onChange={(e) => handleModifiedOnDate(e, "from")}
                                                maxDate={modifiedOn?.to || new Date()} 
                                                label="From Date"
                                                
                                            />
                                            </FormControl>

                                            <FormControl sx={{ width: 173 }}>
                                            <label className="fontPoppinsRegularSm mb-1">&nbsp;</label>
                                            <DateSelector
                                                inputFormat={filterDisplayDateFormat}
                                                value={modifiedOn.to}
                                                onChange={(e) => handleModifiedOnDate(e, "to")}
                                                minDate={modifiedOn?.from}
                                                maxDate={new Date()}
                                                label="To Date"
                                                disabled={!modifiedOn.from}

                                            />
                                            </FormControl>

                                            
                                        </div> */}
                  </div>
                </FilterModal>
              )}

              {/* TODO :: FILTER BLOCK:: END*/}

              {isProjectOpen && hasAutomation && hasAutomationBasedOnProjType && (
                <button
                  onClick={() => run(false)}
                  disabled={
                    runApiCalled || isLoading || (treeHeader === 'Root Module' && treeData && treeData?.length === 0)
                  }
                >
                  <span
                    className={
                      isLoading || (treeHeader === 'Root Module' && treeData && treeData?.length === 0)
                        ? 'opacity-30'
                        : 'actiontooltip float-left'
                    }
                  >
                    <PlayCircleOutline className="text-green-700 ml-3 mt-1 cursor-pointer" />
                  </span>
                </button>
              )}
              {isEditable && hasAutomation && hasAutomationBasedOnProjType && (
                <button
                  disabled={isLoading || (treeHeader === 'Root Module' && treeData && treeData?.length === 0)}
                  onClick={() => {
                    runSettings();
                  }}
                  className={
                    isLoading || (treeHeader === 'Root Module' && treeData && treeData?.length === 0)
                      ? 'opacity-30'
                      : 'float-left'
                  }
                >
                  <Tooltip title="Quick Run Settings">
                    <img
                      className="ml-3 mt-1 cursor-pointer"
                      src="/assets/images/run-settings-gray.svg"
                      alt="search"
                      width="24"
                      height="24"
                    />
                  </Tooltip>
                </button>
              )}
              {isEditable && (
                <>
                  <button disabled={isLoading} onClick={createModule} className="primary-btn ml-6">
                    + Module
                  </button>
                  {hasAutomation && hasAutomationBasedOnProjType && (
                    <button
                      disabled={
                        isLoading ||
                        (treeHeader === 'Root Module' && moduleTree && !moduleTree[0].parentName
                          ? moduleTree[0].subModuleCount === 0 && moduleTree[0].parentName !== 'Root Module'
                          : '')
                      }
                      onClick={createScript}
                      className="primary-btn ml-3"
                    >
                      + Automation Script
                    </button>
                  )}
                  {(hasManualTestCase || hasCBasic) && (
                    <button
                      onClick={createManualTestCase}
                      disabled={
                        isLoading ||
                        (treeHeader === 'Root Module' && moduleTree && !moduleTree[0].parentName
                          ? moduleTree[0].subModuleCount === 0 && moduleTree[0].parentName !== 'Root Module'
                          : '')
                      }
                      className="ml-3 primary-btn"
                    >
                      + Manual Test Case
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="table-height overflow-auto grid">
          {renderTree}
          {displayQuickStartButtons() && (
            <div className="fontPoppinsRegularMd no-steps-content">
              <div>
                <p className="font-bold mb-4">Quick Start</p>
                <p className="mb-4">
                  <span className="font-bold">Step 1 :</span> Click
                  <button onClick={createModule} className="ml-2 mr-2 primary-btn">
                    <span className="text-base">+</span> Module
                  </button>
                  to start adding the Modules
                </p>
                <p className="mb-4">
                  <span className="font-bold">Step 2 :</span> Click
                  <button disabled={true} className="ml-2 mr-2 primary-btn">
                    <span className="text-base">+</span> Automation Script
                  </button>
                  to start adding the Scripts inside the Module
                </p>
              </div>
            </div>
          )}
        </div>
        {/* modal for creating module */}
        {openCreateModal ? (
          <ModuleModal
            MyAlert={props.MyAlert}
            individualTreeId={moduleId}
            data={editModuleData}
            refDataTree={moduleTree}
            treeData={dropDownFolderTree}
            closeModal={closeModal}
            reloadTree={reloadTreeData}
            nodeToAdd={nodeToAdd}
            moduleIdForAdd={moduleIdForAdd}
            moduleNameForAdd={moduleNameForAdd}
            updatedValue={updatedValue}
            parentNode={node.node}
          />
        ) : null}
        {/* modal for updating script */}
        {openEditScriptModal && (
          <ScriptModal
            MyAlert={props.MyAlert}
            data={editScriptData}
            treeData={
              moduleTree[0].title === 'Root Module'
                ? moduleTree[0].children
                  ? moduleTree[0].children
                  : []
                : moduleTree
            }
            closeModal={closeScriptModal}
            reloadTree={reloadTreeData}
            nodeToAdd={nodeToAdd}
            moduleIdForAdd={moduleIdForAdd}
            moduleNameForAdd={moduleNameForAdd}
            updatedValue={updatedValue}
            parentNode={node.node}
            hasEditAccess={isEditable}
            hasAutomation={hasAutomation}
            checkAutomationWithManual={node.data}
            isToggled={isToggled}
            setIsToggled={setIsToggled}
          />
        )}

        {/* Modal for creating automation script */}
        {openScriptModal && (
          <CreateAutomationScript
            individualTreeId={moduleId}
            MyAlert={props.MyAlert}
            data={editScriptData}
            refDataTree={moduleTree}
            treeData={dropDownFolderTree}
            closeModal={closeScriptModal}
            reloadTree={reloadTreeData}
            nodeToAdd={nodeToAdd}
            moduleIdForAdd={moduleIdForAdd}
            moduleNameForAdd={moduleNameForAdd}
            updatedValue={updatedValue}
            parentNode={node.node}
          />
        )}

        {/* Modal for creating manual scripts */}
        {openManualScriptModal && (
          <CreateManualScript
            individualTreeId={moduleId}
            MyAlert={props.MyAlert}
            data={editScriptData}
            refDataTree={moduleTree}
            treeData={dropDownFolderTree}
            closeModal={closeScriptModal}
            reloadTree={reloadTreeData}
            nodeToAdd={nodeToAdd}
            moduleIdForAdd={moduleIdForAdd}
            moduleNameForAdd={moduleNameForAdd}
            updatedValue={updatedValue}
            parentNode={node.node}
            isToggled={isToggled}
            setIsToggled={setIsToggled}
            hasManualOrBoth={hasAutomation && hasAutomationBasedOnProjType}
          />
        )}

        {/* Modalfor editing pre-post condition */}
        {openConditionModal ? (
          <EditPrePostCondition
            moduleName={moduleNameForCascade}
            MyAlert={props.MyAlert}
            data={editConditionData}
            closeModal={closeConditionEditModal}
            reload={reloadTreeData}
            updatedValue={removePrePostCondition}
          />
        ) : null}

        {/* modal for details */}
        {openDetailsModal ? <DetailsModal type={detailsType} data={details} closeModal={closeDetailsModal} /> : null}
        {/* modal for delete */}
        {openDeleteModal && (deleteData?.folder || ['PRE', 'POST'].includes(deleteData.parent.type)) ? (
          <DeleteModal nodeToDel={deleteData} message={message} closeModal={closeDeleteModal} method={DeleteFunction} />
        ) : null}
        {openDeleteModal && !deleteData?.folder && !['PRE', 'POST'].includes(deleteData.parent.type) ? (
          <DeleteScript
            nodeToDel={deleteData}
            message={message}
            closeModal={closeDeleteModal}
            method={DeleteFunction}
          />
        ) : null}
        {/* modal for run settings */}
        {openRunSettings.openModal ? (
          <QuickRunSettings
            disableHeadless={disableHeadlessSwitch}
            title={openRunSettings.title}
            closeModal={closeRunSettings}
            MyAlert={props.MyAlert}
            nodeSelected={nodeSelected}
            selectedNodes={selectedNodes}
            rootData={rootData}
            updateDefaultMachine={getUserAllAvailableMachine}
            browserInstanceArray={browserInstanceArray}
            sauceLabInstanceArray={sauceLabInstanceArray}
            lambdaTestsInstanceArray={lambdaTestsInstanceArray}
            hasAutomationManual={hasAutomationScripts && hasManualScripts}
          />
        ) : null}
        {openCloneInfoModal ? (
          <CloneInfoModal
            cloningContainerData={cloningContainerData}
            container={'Module'}
            cloneContainer={cloneContainer}
            closeModal={closeCloneModal}
          />
        ) : null}
        {showLoaderModal && <LoaderModal />}
        {moveResourceModalData?.open && (
          <MoveResource
            MyAlert={props?.MyAlert}
            handleClose={() => {
              setMoveResourceModalData({ ...moveResourceModalData, open: false });
            }}
            moveResourceModalData={moveResourceModalData}
            reloadTree={reloadTreeData}
          />
        )}

        {openMapToJira && (
          <MapToJiraModal
            type={detailsType}
            scriptData={scriptData}
            closeModal={closeMapToJiraModal}
            domainDetails={domainDetails}
            MyAlert={props?.MyAlert}
            reloadTree={reloadTreeData}
            mappedDetails={mappedDetails}
          />
        )}
        {openLinkedDefectList && (
          <DefectList
            openDrawer={openLinkedDefectList}
            closeDrawer={setOpenLinkedDefectList}
            scriptData={defectScriptData}
          />
        )}
        {navigateToInstanceModal?.openModal && (
          <InformationModal
            closeModal={closeGitAndNavigateModal}
            title={navigateToInstanceModal.data.title}
            body={navigateToInstanceModal.data.body}
            buttonLabel={navigateToInstanceModal.data.label}
            requestedPath={navigateToInstanceModal.data.path}
          />
        )}
      </div>
    </div>
  );
};

export default SingleModuleTree;

import React from 'react';
import ResultRouter from './result_router';
import './result.scss';
import { makeStyles } from '@material-ui/core/styles';
import { getIDB } from '@src/util/localForage_idb_controller';
import { getLicenseFeatures } from '@src/util/common_utils';

const Result = () => {
  const [privilegeObject, setPrivilegeObject] = React.useState({});
  React.useEffect(() => {
    getUserPrivilegeAccess();
  }, []);

  function getUserPrivilegeAccess() {
    const licenseFeatures = getLicenseFeatures();
    const hasManualTestCase = window.privilege.hasManualTestCase(licenseFeatures);
    const hasAutomation = window.privilege.hasAutomation(licenseFeatures);
    const hasDefects = window.privilege.hasDefects(licenseFeatures);

    const privilegeObj = {
      hasManualTestCase,
      hasAutomation,
      hasDefects,
    };
    setPrivilegeObject(privilegeObj);
  }

  return (
    <div className="result-base screen-width">
      <ResultRouter privilegeObject={privilegeObject}></ResultRouter>
    </div>
  );
};

export const getFormattedDate = (date, mode) => {
  let formattedDate = '';
  if (date) {
    if (mode === 'full') {
      formattedDate = date.replace('T', ', ');
    } else {
      let splittedArray = date.split('T');
      if (mode === 'date') {
        formattedDate = splittedArray[0];
      } else {
        // mode=='time'
        formattedDate = splittedArray[1];
      }
    }
  }
  return formattedDate;
};

export const getFormattedTextStatus = (status) => {
  switch (status) {
    case 'PASS':
      return 'Passed';
    case 'FAIL':
      return 'Failed';
    case 'WARNING':
      return 'Warning';
    case 'SKIP':
      return 'Skipped';
    case 'TERMINATED':
      return 'Terminated';
    case 'Aborted':
      return 'Aborted';
    case 'Running':
      return 'Not Executed';
    default:
      return status;
  }
};
export const textColor = (status) => {
  switch (status) {
    case 'PASS':
      return 'result-passed';
    case 'FAIL':
      return 'result-failed';
    case 'WARNING':
      return 'result-warning';
    case 'SKIP':
      return 'result-skipped';
    case 'Terminate':
      return 'result-failed';
    case 'Terminated':
      return 'result-failed';
    case 'Aborted':
      return 'result-failed';
    default:
      return '';
  }
};

export const actualStatusData = (status) => {
  switch (status) {
    case 'Passed':
      return 'PASS';
    case 'Failed':
      return 'FAIL';
    case 'Warning':
      return 'WARNING';
    case 'Skipped':
      return 'SKIP';
    case 'Terminated':
      return 'TERMINATED';
    case 'Aborted':
      return 'Aborted';
    default:
      return status;
  }
};

export const useStyles = makeStyles((theme) => ({
  select: {
    paddingBottom: 3,
    paddingTop: 10,
    borderRadius: 8,
    fontSize: '12px',
    '&:focus': {
      borderRadius: 12,
      background: 'none',
    },
  },
  menuPaper: {
    maxHeight: 200,
    fontSize: '12px',
  },
  menulist: {
    paddingTop: 10,
    paddingBottom: 0,
    fontSize: '12px',
    background: 'none',
    '& li': {
      fontSize: '12px',
      color: '#727171',
    },
    '& li:hover': {
      background: '#F0F3F9',
      color: '#1648C6',
    },
    '& li.Mui-selected': {
      paddingTop: '5 !important',
      color: '#1648C6',
      background: '#F0F3F9',
    },
    '& li.Mui-selected:hover': {
      background: '#F0F3F9',
      color: '#1648C6',
    },
  },
}));

export const getSuiteType = async (executionId) => {
  let suiteType;
  const _executionResult = await getIDB('execResult');
  if (_executionResult && _executionResult?.responseObject?.executionResponses?.length) {
    let actualid = _executionResult.responseObject.executionResponses[0].executionId;
    if (actualid === executionId && _executionResult?.responseObject?.suiteType) {
      suiteType = _executionResult.responseObject.suiteType;
    }
    return suiteType;
  }
};

export default Result;

import { getIDB } from '@src/util/localForage_idb_controller';
import { getCurrentProjectId, getCurrentLicenseId } from '@util/common_utils';
import { getAnalyticsTableData, getDefectTableDetailsReq, getScriptResultReq } from '@api/api_services';
import { chartFontFamily } from '@common/ui-constants';
import { Tooltip } from '@material-ui/core';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const checkValidationForAlphanumericSpecialCharacters = /^[A-Za-z0-9-_`~!@#$%^&*()+={}[\]\\|;:'",<.>\?/\s+]*$/;

export const isValidDateFormatDDMMYYYY = (inputDate) => {
  var date_regex =
    /^(?:(?:31(\/)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[6-9]|[2-9]\d)?\d{4})$/;
  return date_regex.test(inputDate);
};
export const convertDate = (inputFormat) => {
  function pad(s) {
    return s < 10 ? '0' + s : s;
  }
  var d = new Date(inputFormat);
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
};

/* 
Allows only alphabets, numbers and special characters.
Emojis are not allowed
*/
export const isValidCharacter = (value) => {
  return checkValidationForAlphanumericSpecialCharacters.test(value);
};

export const getAnalyticsTreeData = (executionId, isWebServiceRequest, getModuleResultTree, setSelectedTab) => {
  let treeData;
  const getTreeData = async () => {
    treeData = await getIDB('tree-data');
  };
  getTreeData();
  if (!treeData && executionId) {
    getModuleResultTree(executionId);
  }
  if (isWebServiceRequest) {
    setSelectedTab('Input');
  }
};

// get status
export function getStatus(stepResultStats) {
  if (stepResultStats.totalTerminated > 0) {
    return 'Terminated';
  } else if (stepResultStats.totalAborted > 0) {
    return 'Aborted';
  } else if (stepResultStats.totalFailed > 0) {
    return 'FAIL';
  } else if (stepResultStats.totalWarning > 0) {
    return 'WARNING';
  } else if (stepResultStats.totalSkipped > 0) {
    return 'SKIP';
  } else if (stepResultStats.totalPassed > 0) {
    return 'PASS';
  } else if (stepResultStats.totalNA > 0) {
    return 'NA';
  }
}

// get overall status
export const getOverallStatus = (eachItrArray) => {
  let failedStatus = eachItrArray.find((_itr) => _itr.status === 'FAIL');
  let warningStatus = eachItrArray.find((_itr) => _itr.status === 'WARNING');
  let skippedStatus = eachItrArray.find((_itr) => _itr.status === 'SKIP');
  let passedStatus = eachItrArray.find((_itr) => _itr.status === 'PASS');
  if (failedStatus) {
    return 'FAIL';
  }
  if (warningStatus) {
    return 'WARNING';
  }
  if (skippedStatus) {
    return 'SKIP';
  }
  if (passedStatus) {
    return 'PASS';
  }
  return 'NA';
};

// DependsOnScript Step Result Api call :: BEGIN   this function is used in script-result-accordion

export function getDependsOnScriptStepResult(dependant, executionId, clientId, runId, setScriptData) {
  const licenseId = getCurrentLicenseId();
  const projectId = getCurrentProjectId();
  if (licenseId && projectId) {
    getScriptResultReq(executionId , clientId, runId, dependant?.key)
      .then((response) => {
        setScriptData(response.data.responseObject);
      })
      .catch((error) => {
        console.error('Error during depends on script result api call:', error);
      });
  }
}

// DependsOnScript Step Result Api call :: END

export function accordionFilterClass(statistic, filteredStatus) {
  if (
    filteredStatus === 'ALL' ||
    (filteredStatus === 'FAIL' && statistic?.totalFailed > 0) ||
    (filteredStatus === 'PASS' && statistic?.totalPassed > 0) ||
    (filteredStatus === 'SKIP' && statistic?.totalSkipped > 0) ||
    (filteredStatus === 'WARNING' && statistic?.totalWarning > 0)
  ) {
    return '';
  }
  return 'accordion-inactive';
}

export const getTableName = (val) => {
  let name = val?.toLowerCase();

  if (name === 'passed scripts') {
    return 'PASSED SCRIPTS';
  } else if (name === 'fail scripts') {
    return 'FAIL SCRIPTS';
  } else if (name === 'warning scripts') {
    return 'WARNING SCRIPTS';
  } else if (name === 'skipped scripts') {
    return 'SKIPPED SCRIPTS';
  } else if (name === 'flaky scripts') {
    return 'FLAKY SCRIPTS';
  } else if (name === 'failure rate [1%-25%]') {
    return 'FAILURE RATE1-25';
  } else if (name === 'failure rate [26%-50%]') {
    return 'FAILURE RATE26-50';
  } else if (name === 'failure rate [51%-75%]') {
    return 'FAILURE RATE51-75';
  } else if (name === 'failure rate [76%-100%]') {
    return 'FAILURE RATE76-100';
  } else if (name === 'known failures') {
    return 'KNOWN FAILURES';
  } else if (name === 'unknown failures') {
    return 'UNKNOWN FAILURES';
  } else if (name?.includes('partially analysed failures')) {
    return 'PARTIALLY ANALYSED FAILURES';
  }
};

// TODO :: trail for scroll api
export const getTableData = async (payload, tableName, from, to) => {
  try {
    let { data = {} } = await getAnalyticsTableData(payload, tableName, from, to);
    return data;
  } catch (error) {
    console.error(error);
  }
};

// TODO :: taril for scroll api

export const getScrollPosition = (tableRef, setScrollPosition) => {
  if (tableRef && setScrollPosition) {
    parseInt(tableRef.current.scrollHeight) - parseInt(tableRef.current.scrollTop) - 2 <= tableRef.current.clientHeight
      ? setScrollPosition(true)
      : setScrollPosition(false);
  }
};

//  default tooltip Poppine FontFamily

export const TooltipPoppin = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} disableInteractive />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
    fontFamily: chartFontFamily,
    fontSize: 10,
  },
});

// TODO :: to genrate count and additional text in card block

export const countMessageRenderer = (count = 0, name = '') => {
  switch (name) {
    case 'defect Density':
      return `${count} / Module`;

    case 'Quality Score':
      return `${count.toFixed(2)} %`;

    default:
      return count;
  }
};

//  TODO :: to set data in defects statistics table on click event :: START
export const getDefectStatisticTableData = (params) => {
  return getDefectTableDetailsReq(params.reqBody, params.from, params.to, params.tableName, params.tableContent)
    .then((response) => {
      if (response.data && response.status === 200) {
        return response.data.defectList;
      } else if (response.status === 204) {
        return 'no-content';
      }
    })
    .catch((err) => {
      console.error('Error in getDefectTableDetailsReq Failed', err);
    });
};

//  TODO :: to set data in defects statistics table on click event :: END

// NOTE ::  this function is used in defectsAnalyticsTableHeader to trim lenghty module names
export let defectsAnalyticsTableHeaderTrim = (header, tableSize) => {
  if (tableSize === 'bigTable') {
    return header?.split('/')?.at(-1).length > 60 ? header.split('/').at(-1).slice(0, 60) + '..' : header;
  } else {
    return header?.split('/')?.at(-1).length > 40 ? header.split('/').at(-1).slice(0, 40) + '..' : header;
  }
};

// TODO ::  Donut chart custom tooltip
export let defectsDonutChartCustomTooltip = ({ series, seriesIndex, w }) => {
  const { labels } = w.globals;
  let seriesColor = w.globals?.colors;
  return labels?.[seriesIndex] !== 'nodata'
    ? `
    <div class="defects-donut-chart-custom-tooltip border-2 rounded-md " style="border-color: ${seriesColor?.[seriesIndex]}">
      ${labels?.[seriesIndex]?.split(':')?.at(0)}
    </div>
  `
    : null;
};

import React, { useState } from 'react';
import { SLACK_CONSTANTS } from '../plugin_constants';
import CommonDrawerJs from '../CommonIntegrationDrawer';

function SlackDetails(props) {
  const selectedInstance = props?.seletedInstance;
  return (
    <CommonDrawerJs
      isDrawerOpen={true}
      headerContent={`${SLACK_CONSTANTS.INSTANCE_DETAILS} - ${props?.seletedInstance?.instanceName}`}
      drawerWidth="450px"
      leftButtonText={SLACK_CONSTANTS.CLOSE}
      isLeftButtonVisible={true}
      isRightButtonVisible={false}
      onDrawerOpen={props.onClose}
      onLeftButtonClick={props.onClose}
    >
      <div className="w-full h-full flex flex-col ">
        <div id="Journal-scroll" className="grid grid-cols-3 mt-4 pb-4 ml-6 overflow-y-auto max-h-72">
          <div className="overflow-hidden-common">
            <label
              htmlFor="userName"
              className="block label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {SLACK_CONSTANTS.INSTANCE_NAME}
            </label>
            <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-2">
              {selectedInstance?.instanceName}
            </label>
          </div>
          <div className="overflow-hidden-common ml-6">
            <label
              htmlFor="userName"
              className="block label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {SLACK_CONSTANTS.CHANEL_NAME}
            </label>
            <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-2">
              {selectedInstance?.createdByUname}
            </label>
          </div>
          <div className="ml-10">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {SLACK_CONSTANTS.CHANEL_ID}
            </label>
            <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsMediumSm mt-1">
              {' '}
              <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
                {selectedInstance?.channelId}
              </label>
            </label>
          </div>
          <div className="mt-8">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {SLACK_CONSTANTS.CREATED_BY}
            </label>
            <label className="instance-details-style fontPoppinsRegularSm mt-2">{selectedInstance?.createdBy}</label>
          </div>
          <div className="mt-8 ml-6">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {SLACK_CONSTANTS.CREATED_ON}
            </label>
            <label className="instance-details-style fontPoppinsRegularSm mt-2">{selectedInstance?.createdOn}</label>
          </div>
          <div className="mt-8 ml-10"></div>
          <div className="mt-8">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {SLACK_CONSTANTS.MODIFIED_BY}
            </label>
            <label className="instance-details-style fontPoppinsRegularSm mt-2">{selectedInstance?.modifiedOn}</label>
          </div>
          <div className="mt-8 ml-6">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {SLACK_CONSTANTS.MODIFIED_ON}
            </label>
            <label className="instance-details-style fontPoppinsRegularSm mt-2">{selectedInstance?.modifiedOn}</label>
          </div>
        </div>
      </div>
    </CommonDrawerJs>
  );
}

export default SlackDetails;

import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import CreateVariable from './create-edit-variables';
import VariablesDetailsModal from './variables-details';
import DeleteModal from '../delete-modal';
import { Tooltip } from '@material-ui/core';
import { deleteGloabalVariable, getAllGlobalVariableListReq, getSingleUserRequest } from '@api/api_services';
import { DeleteOutlined, EditOutlined } from '@material-ui/icons';
import UserDetailsModal from '../user-details-modal';
import { CommonLoader } from '@src/pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import ReactTable from '@src/pages/common/Table/ReactTable';
import SearchBar from '@src/pages/analytics/common/search-bar';
import {
  HighlightText,
  isEmptyValue,
  isValueIncludes,
  convertDateHyphenReverse,
  findMatchingQuery,
  getDateFilteredByRange,
  getVerifiedDateAuto,
  getMaskedString,
  delay,
} from '@util/common_utils';
import NoResultsFound from '@src/pages/common/Table/NoResultFound';
import FilterModal from '@pagescommon/filters/filter-modal';
import MultipleSelectCheckmarks from '@pagescommon/multi_select_checkbox_dropdown/multiple-select-checkmarks.js';
import FormControl from '@mui/material/FormControl';
import Chip from '@pagescommon/chip/chip.js';
import { defectAnalyticsLabel, filterDisplayDateFormat } from '@common/ui-constants';
import DateSelector from '@pagescommon/date/date-selector';

function GlobalVariables(props) {
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);

  const search = window.location.search;
  const defaultStepGroup = new URLSearchParams(search).get('defaultStepGroup');
  let [reload, setReload] = useState(true);
  let [variablesData, setVariablesData] = useState([]);
  let [openCreateVariableModal, setOpenCreateVariableModal] = useState(false);
  let [openVariableDetailsModal, setOpenVariableDetailsModal] = useState(false);
  let [openVariableDeleteModal, setOpenVariableDeleteModal] = useState(false);
  let [editVariableData, setEditVariableData] = useState(null);
  let [isLoading, setIsLoading] = useState(false);
  let [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);
  let [userDetails, setUserDetails] = useState({});
  let [globalvariablelength, setGlobalVariableLength] = useState(0);
  // Search filter States
  let [isHighlight, setIsHighlight] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');
  const [userSearched, setuserSearched] = useState('');
  const [data, setData] = useState([]);
  let [openSearch, setOpenSearch] = useState(false);
  let [isFilterApplied, setIsFilterApplied] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  let openFilterModal = Boolean(anchorEl);
  const [createdBy, setCreatedBy] = React.useState([]);
  const [modifiedBy, setModifiedBy] = useState([]);
  const [createdOn, setCreatedOn] = React.useState({
    from: null,
    to: null,
  });
  const [modifiedOn, setModifiedOn] = React.useState({
    from: null,
    to: null,
  });

  const [disableApplyClearBtn, setDisableApplyClearBtn] = useState(true);
  const [optionsListItem, setOptionsListItem] = useState({
    createdBy: [],
    modifiedBy: [],
  });
  const showSelectedList = [{ createdBy }, { modifiedBy }];

  useEffect(() => {
    setData(variablesData);
    if ((openSearch && userSearched) || isFilterApplied) {
      if (isFilterApplied) {
        handleSearchSubmit(searchTextHighlight, defectAnalyticsLabel.btnLabel.apply);
      } else {
        handleSearchSubmit(searchTextHighlight);
      }
    }
  }, [variablesData]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      if (isFilterApplied) {
        handleSearchSubmit('', 'RESET');
      } else {
        setData(variablesData);
        setSearchTextHighlight('');
        setIsHighlight(!isHighlight);
      }
    }
  }, [userSearched]);

  // TODO :: disable apply and clear all button
  useEffect(() => {
    if (!isEmptyValue([...createdBy, ...modifiedBy])) {
      setDisableApplyClearBtn(false);
    } else if ((createdOn.from && createdOn.to) || (modifiedOn.from && modifiedOn.to)) {
      setDisableApplyClearBtn(false);
    } else {
      setDisableApplyClearBtn(true);
    }
  }, [createdBy, modifiedBy, createdOn, modifiedOn]);

  const reloadVariables = (val) => {
    setReload(val);
  };

  const getUserDetails = async (key) => {
    if (key) {
      let res = await getSingleUserRequest(key);
      if (res.data.responseCode === 200 && res.data.responseObject) {
        setUserDetails(res.data.responseObject);
        setOpenUserDetailsModal(true);
      }
    }
  };

  const closeUserDetailsModal = (value) => {
    setOpenUserDetailsModal(value);
    setUserDetails({});
  };

  const updateGlobalVariable = async (variablesData, previousVariableData, initialCount = 0) => {
    const loadCount = 15;
    let end = initialCount + loadCount;
    let newVariableData = variablesData.slice(initialCount, end);
    setVariablesData([...previousVariableData, ...newVariableData]);
    if (end < variablesData.length) {
      await delay(2);
      updateGlobalVariable(variablesData, [...previousVariableData, ...newVariableData], end);
    }
  };

  const getVariables = async () => {
    try {
      resetProgress();
      startOrResumeProgress({
        stopAt: 90,  
      });
      setIsLoading(true);
      let varData = [];
      let res = await getAllGlobalVariableListReq();
      stopProgress();
     startOrResumeProgress({
      stopAt:100,
      intervalDuration:10
     })
      
      if (res.data && res.data.responseObject && res.data.responseObject?.length > 0) {
        setGlobalVariableLength(res.data.responseObject.length);
        updateGlobalVariable(res.data.responseObject, [], 0);
      } else {
        setVariablesData([]);
      }
      setIsLoading(false);
    } catch (err) {
      resetProgress();
      setIsLoading(false);
      console.error('GET_GLOBAL_VARIABLES : ', err);
    }
  };

  const editVariable = (varData) => {
    setOpenCreateVariableModal(true);
    setEditVariableData(varData);
  };
  const deleteVariable = (varData) => {
    setOpenVariableDeleteModal(true);
    setEditVariableData(varData);
  };
  const deleteProjectEnvVariable = async (data) => {
    try {
      const response = await deleteGloabalVariable(data.id, data.name);
      if (response.data.responseCode === 200 && response.data.status === 'SUCCESS') {
        reloadVariables(true);
        props.MyAlert.success(response.data.message);
      } else {
        props.MyAlert.warning(`${response.data.message}`);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const getVariableDetails = (varData) => {
    setOpenVariableDetailsModal(true);
    setEditVariableData(varData);
  };
  let menuData = (variable) => {
    return (
      <div className="actionIcons">
        {(isRepoEditable || isTestDevEditable) && (
          <button
            disabled={variable.isSystemVariable}
            className={`float-left  text-base mr-3 text-blue-700 ${
              variable.isSystemVariable ? 'opacity-50' : 'cursor-pointer'
            }`}
            onClick={() => {
              editVariable(variable);
            }}
          >
            <Tooltip title="Edit">
              <EditOutlined />
            </Tooltip>
          </button>
        )}
        {(hasRepoFullAccess || hasTestDevFullAccess) && (
          <button
            disabled={variable.isSystemVariable}
            className={`float-left  mr-3 text-blue-700 text-base ${
              variable.isSystemVariable ? 'opacity-50' : 'cursor-pointer'
            }`}
            onClick={() => {
              deleteVariable(variable);
            }}
          >
            <Tooltip title="Delete">
              <DeleteOutlined />
            </Tooltip>
          </button>
        )}
        <Menu as="div" className="relative inline-block text-left mt-px">
          {({ open }) => (
            <>
              <div>
                <Menu.Button className="bg-gray-100 rounded-full flex items-center  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 focus:ring-indigo-500">
                  <span className="sr-only">Open options</span>
                  <Tooltip title="More">
                    <span>
                      <DotsVerticalIcon className="h-5 w-5 configmoreIcon" aria-hidden="true" />
                    </span>
                  </Tooltip>
                </Menu.Button>
              </div>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm w-full text-left'
                          )}
                          onClick={() => {
                            getVariableDetails(variable);
                          }}
                        >
                          Details
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    );
  };

  const closeModal = (value) => {
    if (openVariableDetailsModal) {
      setOpenVariableDetailsModal(false);
    }
    if (openCreateVariableModal) {
      setOpenCreateVariableModal(value);
    }
    if (openVariableDeleteModal) {
      setOpenVariableDeleteModal(false);
    }
    setEditVariableData(null);
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  // TODO :: handle Search filter functions:: START

  let newData;

  let initFilterQuery = {
    createdByUname: createdBy,
    modifiedByUname: modifiedBy,
  };

  let initFilterQueryDate = {
    createdOn: getVerifiedDateAuto(createdOn),
    modifiedOn: getVerifiedDateAuto(modifiedOn),
  };

  let isQuerySelected = Object.values(initFilterQuery)?.some((val) => val?.length); // NOTE :: to check any of filter is selected

  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    if (isFilterApplied) {
      handleSearchSubmit('', 'RESET');
    } else {
      setSearchTextHighlight('');
      setData(variablesData);
      setIsHighlight(!isHighlight);
    }
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  // TODO :: to get Date filter by modifiedOn , createdOn
  let getDateFilterByQuery = () => {
    if (initFilterQueryDate.createdOn.length && initFilterQueryDate.modifiedOn.length) {
      for (let dateFieldName in initFilterQueryDate) {
        if (dateFieldName == 'createdOn') {
          newData = getDateFilteredByRange(
            newData,
            'createdOn',
            initFilterQueryDate['createdOn'][0],
            initFilterQueryDate['createdOn'][1]
          );
        } else if (!isEmptyValue(newData)) {
          newData = getDateFilteredByRange(
            newData,
            'modifiedOn',
            initFilterQueryDate['modifiedOn'][0],
            initFilterQueryDate['modifiedOn'][1]
          );
        }
      }
    } else if (initFilterQueryDate.createdOn.length) {
      newData = getDateFilteredByRange(
        newData,
        'createdOn',
        initFilterQueryDate.createdOn[0],
        initFilterQueryDate.createdOn[1]
      );
    } else if (initFilterQueryDate.modifiedOn.length) {
      newData = getDateFilteredByRange(
        newData,
        'modifiedOn',
        initFilterQueryDate.modifiedOn[0],
        initFilterQueryDate.modifiedOn[1]
      );
    }
  };

  // TODO :: to perfrom search action
  let handleOnlySearch = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (!e?.target?.value?.trim() == '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = variablesData.filter(({ name, modifiedByUname }) => {
          return isValueIncludes(e, name) || isValueIncludes(e, modifiedByUname);
        });
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
        setData(newData);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  let handleSearchSubmit = async (e, action) => {
    if (
      action === defectAnalyticsLabel.btnLabel.apply ||
      (isFilterApplied && action !== defectAnalyticsLabel.btnLabel.clearAll)
    ) {
      if ((e?.key === 'Enter' && e?.target?.value?.trim() !== '') || searchTextHighlight) {
        // TODO :: when search and Filter both is Applied
        let searchValue = e?.target?.value?.trim() || searchTextHighlight;

        if (action !== 'RESET') {
          newData = variablesData.filter(({ name, modifiedByUname }) => {
            return isValueIncludes(searchValue, name) || isValueIncludes(searchValue, modifiedByUname);
          });
        }

        let dataToFilterFrom = action === 'RESET' ? variablesData : newData;

        if (isQuerySelected) {
          newData = await findMatchingQuery(dataToFilterFrom, initFilterQuery);
        } else {
          newData = dataToFilterFrom;
        }

        getDateFilterByQuery(initFilterQueryDate, newData);

        let searchkey = action === 'RESET' ? '' : e?.target?.value?.trim() || searchTextHighlight;
        setSearchTextHighlight(searchkey);
        setIsHighlight(!isHighlight);
      } else {
        // TODO :: only when Filter is Applied
        if (isQuerySelected) {
          newData = await findMatchingQuery(variablesData, initFilterQuery);
        } else {
          newData = variablesData;
        }
        getDateFilterByQuery(initFilterQueryDate, newData);
      }

      let selectedFilterList = {
        createdBy,
        modifiedBy,
        createdOn: {
          from: getVerifiedDateAuto(createdOn)?.at(0),
          to: getVerifiedDateAuto(createdOn)?.at(1),
        },
        modifiedOn: {
          from: getVerifiedDateAuto(modifiedOn)?.at(0),
          to: getVerifiedDateAuto(modifiedOn)?.at(1),
        },
      };

      sessionStorage.setItem('globalVariableFiltres', JSON.stringify(selectedFilterList));
      setIsFilterApplied(true);
      setAnchorEl(null);
      setData(newData);
    } else {
      // TODO :: only Search is Applied
      handleOnlySearch(e);
    }
  };

  const handleSelectDropdown = (event, action, perfromOn) => {
    if (action === 'add') {
      const selectedValue = typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
      switch (perfromOn) {
        case 'createdBy':
          return setCreatedBy(selectedValue);
        case 'modifiedBy':
          return setModifiedBy(selectedValue);
        default:
      }
    } else if (action === 'delete') {
      let valuetoDelete = typeof event === 'string' ? event : '';
      switch (perfromOn) {
        case 'createdBy':
          return setCreatedBy(createdBy.filter((val) => val !== valuetoDelete));
        case 'modifiedBy':
          return setModifiedBy(modifiedBy.filter((val) => val !== valuetoDelete));
        default:
      }
    }
  };

  let renderChips = (listName) => {
    switch (listName) {
      case 'createdBy':
        return createdBy;
      case 'modifiedBy':
        return modifiedBy;
      default:
    }
  };

  let handleCreatedOnDate = (e, action) => {
    if (action == 'from') {
      setCreatedOn((perVal) => ({ ...perVal, from: e }));
    } else if (action == 'to') {
      setCreatedOn((perVal) => ({ ...perVal, to: e }));
    }
  };

  let handleModifiedOnDate = (e, action) => {
    if (action == 'from') {
      setModifiedOn((perVal) => ({ ...perVal, from: e }));
    } else if (action == 'to') {
      setModifiedOn((perVal) => ({ ...perVal, to: e }));
    }
  };

  const handleFilterOpen = async (event) => {
    setAnchorEl(event.currentTarget);

    let getUniqueList = (option) => {
      let itemList = [];
      variablesData?.forEach((val) => (val?.[option] && val?.[option] !== '--' ? itemList?.push(val?.[option]) : null));
      return [...new Set(itemList)];
    };

    setOptionsListItem({
      createdBy: getUniqueList('createdByUname'),
      modifiedBy: getUniqueList('modifiedByUname'),
    });

    if (isFilterApplied) {
      let retriveData = JSON.parse(sessionStorage.getItem('globalVariableFiltres') || null);
      setCreatedBy(retriveData?.createdBy);
      setModifiedBy(retriveData?.modifiedBy);
      setCreatedOn({
        from: convertDateHyphenReverse(retriveData?.createdOn?.from),
        to: convertDateHyphenReverse(retriveData?.createdOn?.to),
      });
      setModifiedOn({
        from: convertDateHyphenReverse(retriveData?.modifiedOn?.from),
        to: convertDateHyphenReverse(retriveData?.modifiedOn?.to),
      });
    }
  };

  const handleFilterClose = () => {
    if (!isFilterApplied) {
      setCreatedBy([]);
      setModifiedBy([]);
      setCreatedOn({ from: null, to: null });
      setModifiedOn({ from: null, to: null });
    }
    setAnchorEl(null);
  };

  let handleApplyFilter = async (action) => {
    if (action === defectAnalyticsLabel.btnLabel.apply) {
      handleSearchSubmit(searchTextHighlight, defectAnalyticsLabel.btnLabel.apply);
    } else if (action === defectAnalyticsLabel.btnLabel.clearAll) {
      setCreatedBy([]);
      setModifiedBy([]);
      setCreatedOn({ from: null, to: null });
      setModifiedOn({ from: null, to: null });
      if (isFilterApplied) {
        setIsFilterApplied(false);
        sessionStorage.removeItem('globalVariableFiltres');
        if (searchTextHighlight) {
          handleSearchSubmit(searchTextHighlight, defectAnalyticsLabel.btnLabel.clearAll);
        } else {
          setData(variablesData);
        }
      }
    }
  };

  // TODO :: handle Search filter functions :: END

  const columns = React.useMemo(
    () => [
      {
        Header: 'NAME',
        accessor: 'name',
        width: '20%',
        minWidth: '170px',
        Cell: (e) => (
          <span
            className="text-blue-700 cursor-pointer fontPoppinsRegularMd"
            onClick={() => {
              getVariableDetails(e.row.original);
            }}
          >
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </span>
        ),
      },
      {
        Header: 'VALUE',
        accessor: 'value',
        width: '15%',
        minWidth: '180px',
        Cell: (e) => (
          <Tooltip title={e?.row?.original?.masked ? '' : e.value} placement="top">
            <span className="text-gray-700 fontPoppinsRegularMd lengthyChar">
              {isEmptyValue(e.value) ? 'NULL' : e.row.original?.masked ? getMaskedString(e.value) : e.value}
            </span>
          </Tooltip>
        ),
      },
      {
        Header: 'RETURNING STEP',
        accessor: 'returningStep',
        width: '15%',
        minWidth: '80px',
        Cell: (e) => <span className="text-gray-700 fontPoppinsRegularMd">{e.value ? e.value : 'N/A'} </span>,
      },
      // {
      //   Header: "STATE",
      //   accessor: "state",
      //   width: "15%",
      //   minWidth: '185px',
      // },
      // {
      //   Header: "VERSION",
      //   accessor: "version",
      //   width: "10%",
      // },
      {
        Header: 'MODIFIED BY',
        accessor: 'modifiedByUname',
        width: '15%',
        minWidth: '120px',
        Cell: (e) => (
          <span
            onClick={() => {
              if (e.value !== '--') {
                getUserDetails(e.row.original.modifiedBy);
              }
            }}
            className="text-blue-700 cursor-pointer fontPoppinsRegularMd"
          >
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </span>
        ),
      },
      {
        Header: 'ACTIONS',
        accessor: 'actions',
        width: '10%',
        minWidth: '115px',
        Cell: (e) => menuData(e.row.original),
      },
    ],
    [isHighlight]
  );

  useEffect(() => {
    if (reload) {
      getVariables();
    }
    setReload(false);
  }, [reload]);

  const { isRepoEditable, hasRepoFullAccess, isTestDevEditable, hasTestDevFullAccess } = React.useMemo(
    () => ({
      isRepoEditable: window.permission?.isEditAccess('repository'),
      hasRepoFullAccess: window.permission?.isFullAccess('repository'),
      isTestDevEditable: window.permission?.isEditAccess('testDevelopment'),
      hasTestDevFullAccess: window.permission?.isFullAccess('testDevelopment'),
    }),
    []
  );
  return (
    <div className="page-table-body">
      <div className="content_area_style">
        <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
          <label className="project_label text-sm flex-auto">
            Global Variables
            <span className="proj-count-style px-2.5 py-1 ml-2 font-bold rounded-lg">
              {isEmptyValue(data) ? 0 : data?.length}
            </span>
          </label>
          <div className="pr-4">
            <div className="flex flex-row items-center">
              <SearchBar
                open={openSearch}
                searchValue={userSearched}
                handleSearch={handleSearch}
                onSearchClose={handleSearchClose}
                handleSearchOpen={handleSearchOpen}
                enterKeyHandler={handleSearchSubmit}
                disable={isEmptyValue(variablesData)}
              />

              <FilterModal
                open={openFilterModal}
                anchorEl={anchorEl}
                onClose={handleFilterClose}
                handleFilterOpen={handleFilterOpen}
                onApply={handleApplyFilter}
                isFilterApplied={isFilterApplied}
                disableFilters={isEmptyValue(variablesData)}
                disableClearAllBtn={disableApplyClearBtn}
                disableApplyBtn={disableApplyClearBtn}
              >
                <div
                  className={
                    showSelectedList.some((val) => val[Object.keys(val)].length > 0)
                      ? 'border-2 rounded mb-2 max-h-40 overflow-auto'
                      : 'hidden'
                  }
                >
                  {showSelectedList?.map((obj, index) => {
                    let itemDisplay = obj[Object.keys(obj)];
                    if (!isEmptyValue(itemDisplay)) {
                      return (
                        <div className="p-2" key={index + 1}>
                          <div className="rs-blue capitalize fontPoppinsSemiboldSm border-b-2">
                            {Object.keys(obj)?.[0]}
                          </div>
                          <div className="chips-section flex flex-wrap p-1">
                            {renderChips(Object.keys(obj)?.[0])?.map((val, chipIndex) => {
                              return (
                                <Chip
                                  key={chipIndex + 2}
                                  text={val}
                                  title={val}
                                  onClick={(e) => handleSelectDropdown(e, 'delete', Object.keys(obj)?.[0])}
                                />
                              );
                            })}
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>

                <div className="grid grid-rows-2 grid-flow-col gap-2">
                  <div className=" flex gap-2 mt-1 ">
                    <FormControl sx={{ width: 170 }}>
                      <label className="fontPoppinsRegularSm mb-1">Created By</label>
                      <MultipleSelectCheckmarks
                        options={optionsListItem['createdBy'] || []}
                        onChange={(e) => handleSelectDropdown(e, 'add', 'createdBy')}
                        value={createdBy}
                      />
                    </FormControl>

                    <FormControl sx={{ width: 170 }}>
                      <label className="fontPoppinsRegularSm mb-1">Created On</label>
                      <DateSelector
                        inputFormat={filterDisplayDateFormat}
                        value={createdOn.from}
                        onChange={(e) => handleCreatedOnDate(e, 'from')}
                        maxDate={createdOn?.to || new Date()}
                        label="From Date"
                      />
                    </FormControl>

                    <FormControl sx={{ width: 170 }}>
                      <label className="fontPoppinsRegularSm mb-1">&nbsp;</label>
                      <DateSelector
                        inputFormat={filterDisplayDateFormat}
                        value={createdOn.to}
                        onChange={(e) => handleCreatedOnDate(e, 'to')}
                        minDate={createdOn?.from}
                        maxDate={new Date()}
                        label="To Date"
                        disabled={!createdOn.from}
                      />
                    </FormControl>
                  </div>

                  <div className=" flex gap-2 mt-1 ">
                    <FormControl sx={{ width: 170 }}>
                      <label className="fontPoppinsRegularSm mb-1">Modified By</label>
                      <MultipleSelectCheckmarks
                        options={optionsListItem['modifiedBy'] || []}
                        onChange={(e) => handleSelectDropdown(e, 'add', 'modifiedBy')}
                        value={modifiedBy}
                      />
                    </FormControl>

                    <FormControl sx={{ width: 170 }}>
                      <label className="fontPoppinsRegularSm mb-1">Modified On</label>
                      <DateSelector
                        inputFormat={filterDisplayDateFormat}
                        value={modifiedOn.from}
                        onChange={(e) => handleModifiedOnDate(e, 'from')}
                        maxDate={modifiedOn?.to || new Date()}
                        label="From Date"
                      />
                    </FormControl>

                    <FormControl sx={{ width: 170 }}>
                      <label className="fontPoppinsRegularSm mb-1">&nbsp;</label>
                      <DateSelector
                        inputFormat={filterDisplayDateFormat}
                        value={modifiedOn.to}
                        onChange={(e) => handleModifiedOnDate(e, 'to')}
                        minDate={modifiedOn?.from}
                        maxDate={new Date()}
                        label="To Date"
                        disabled={!modifiedOn.from}
                      />
                    </FormControl>
                  </div>
                </div>
              </FilterModal>

              <div className="ml-auto pl-2">
                {(isRepoEditable || hasRepoFullAccess || isTestDevEditable || hasTestDevFullAccess) && (
                  <button
                    onClick={() => {
                      setOpenCreateVariableModal(true);
                    }}
                    className="ml-3 primary-btn"
                    disabled={
                      defaultStepGroup
                        ? defaultStepGroup === 'false' || defaultStepGroup === 'null'
                          ? false
                          : true
                        : false
                    }
                  >
                    + Variable
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="table-height overflow-auto" id="journal-scroll">
          <ReactTable data={data} columns={columns} tableHeightClass={'main-table-height'} />
          {isLoading ? (
            <CommonLoader />
          ) : isEmptyValue(variablesData) ? (
            <div className="fontPoppinsRegularMd no-steps">
              {(isRepoEditable || hasRepoFullAccess || isTestDevEditable || hasTestDevFullAccess) && (
                <div className="">
                  <p className="font-bold mb-4">Quick Start</p>
                  <p className="mb-4">
                    <span className="font-bold">Step 1 :</span> Click
                    <button
                      onClick={() => {
                        setOpenCreateVariableModal(true);
                      }}
                      className="ml-3 mr-3 primary-btn"
                    >
                      + Variable
                    </button>
                    to start adding the Variables
                  </p>
                </div>
              )}
            </div>
          ) : (
            <>{(openSearch || isFilterApplied) && isEmptyValue(data) && <NoResultsFound className="mt-14" />}</>
          )}
        </div>

        {/* modal to create and edit variable */}
        {openCreateVariableModal ? (
          <CreateVariable
            MyAlert={props.MyAlert}
            data={editVariableData}
            closeModal={closeModal}
            reload={reloadVariables}
            variableType={'GLOBAL'}
          />
        ) : null}
        {/* modal for delete variable */}
        {openVariableDeleteModal ? (
          <DeleteModal
            closeModal={closeModal}
            handleDelete={deleteProjectEnvVariable}
            reload={reloadVariables}
            dataObj={{ type: 'Variable', data: editVariableData }}
          />
        ) : null}
        {/* modal for user details */}
        {openUserDetailsModal ? <UserDetailsModal data={userDetails} closeModal={closeUserDetailsModal} /> : null}
        {/* modal for variable details */}
        {openVariableDetailsModal ? <VariablesDetailsModal closeModal={closeModal} data={editVariableData} /> : null}
      </div>
    </div>
  );
}

export default GlobalVariables;

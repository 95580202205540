import React, { useState, useEffect } from 'react';
import { getSingleUserRequest } from '@api/api_services';
import './parallel_run_status.scss';

const UserDetailsPage = (props) => {
  const [userObj, setUserObj] = useState({});

  useEffect(() => {
    getSingleUserRequest(props?.userObj?.userId).then((results) => {
      setUserObj(results.data?.responseObject);
    });
  }, []);

  const getUserPrivilege = (userObj) => {
    const licenses = userObj.licenses;
    if (licenses && licenses.length) {
      const userLicense = licenses.find((lic) => lic.id === userObj.defaultLicenseId);
      return userLicense.privilege;
    }
    return '';
  };

  return (
    <>
      <div class="grid grid-cols-4 gap-4 user_details_main_style">
        <div>
          <label className="user_key_text_style">Name</label>
          <label className="user_data_text_style">{userObj?.name}</label>
        </div>
        <div>
          <label className="user_key_text_style">Email</label>
          <label className="user_data_text_style">{userObj?.emailId}</label>
        </div>
        <div>
          <label className="user_key_text_style">Privilege</label>
          <label className="user_data_text_style">{getUserPrivilege(userObj)}</label>
        </div>
        <div>
          <label className="user_key_text_style">Status</label>
          <label className="user_data_text_style">{userObj?.activationStatus}</label>
        </div>
      </div>
      <div class="grid grid-cols-4 gap-4 user_details_main_style">
        <div>
          <label className="user_key_text_style">Created By</label>
          <label className="user_data_text_style">{userObj?.createdByUname}</label>
        </div>
        <div>
          <label className="user_key_text_style">Created On</label>
          <label className="user_data_text_style">{userObj?.createdOn}</label>
        </div>
        <div>
          <label className="user_key_text_style">Modified By</label>
          <label className="user_data_text_style">{userObj?.modifiedByUname || ''}</label>
        </div>
        <div>
          <label className="user_key_text_style">Modified On</label>
          <label className="user_data_text_style">{userObj?.modifiedOn || ''}</label>
        </div>
      </div>

      <div className="border-style"></div>
    </>
  );
};

export default UserDetailsPage;

import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import { DASHBOARD_CONSTANTS } from '@src/common/ui-constants';
import { Drawer } from 'fireflink-ui';

const DashBoardDetails = (props) => {
  const dashBoardDetails = props?.selectedDashBoard;
  const data = props?.selectedDashBoard?.users;
  const columns = useMemo(
    () => [
      {
        Header: 'USERS',
        accessor: 'name',
        width: '70%',
      },
      {
        Header: 'ACCESS',
        accessor: 'access',
        width: '30%',
        className: 'access-header',
      },
    ],
    []
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });
  const secondaryButtonProps = {
    label: DASHBOARD_CONSTANTS.CLOSE,
    onClick: props.onClose,
  };
  const primaryButtonProps = {};
  return (
    <Drawer
      isOpen={true}
      onClose={props.onClose}
      overlay={true}
      title={`${DASHBOARD_CONSTANTS.DASHBOARD_DETAILS} - ${dashBoardDetails.name}`}
      secondaryButtonProps={secondaryButtonProps}
      primaryButtonProps={primaryButtonProps}
    >
      <div className="dashboard-detail">
        <div className="dashboard-top-content grid grid-cols-3  pb-4  max-h-72 ">
          <div className="dashboard-name">
            <label className="header-label ">{DASHBOARD_CONSTANTS.NAME}</label>
            <label className="label-value">{dashBoardDetails?.name}</label>
          </div>
          <div className="ml-4">
            <label className="header-label">{DASHBOARD_CONSTANTS.CREATED_BY}</label>
            <label className="label-value">{dashBoardDetails?.createdByUname}</label>
          </div>
          <div className="dashboard-createdOn">
            <label className="header-label">{DASHBOARD_CONSTANTS.CREATED_ON}</label>
            <label className="label-value">{dashBoardDetails?.createdOn}</label>
          </div>
          <div className="mt-6">
            <label className="header-label">{DASHBOARD_CONSTANTS.MODIFIED_BY}</label>
            <label className="label-value">{dashBoardDetails?.modifiedByUname}</label>
          </div>
          <div className="mt-6 ml-4">
            <label className="header-label">{DASHBOARD_CONSTANTS.MODIFIED_ON}</label>
            <label className="label-value ">{dashBoardDetails?.modifiedOn}</label>
          </div>
        </div>
        <div>
          <label className="header-label">{DASHBOARD_CONSTANTS.DESCRIPTION}</label>
          <p className="description-val">{dashBoardDetails?.description}</p>
        </div>
        <div className="mt-3">
          <label className="header-label permission-label">{DASHBOARD_CONSTANTS.DASHBOARD_PERMISSION}</label>
        </div>
        <div className=" mt-2 userRoleMapping overflow-y-auto max-h-48">
          <table {...getTableProps()} className="">
            <thead className="projectDetailsDataa ">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="text-sm h-9  sticky top-0">
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      width={column.width}
                      className=" text-left  fontPoppinsSemiboldSm pl-4 table-line "
                    >
                      {' '}
                      <span className="project-details-header ml-2">{column.render('Header')}</span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="configHover h-8 hover:bg-gray-100">
                    {row.cells.map((cell) => {
                      return (
                        <td
                          width={cell.column.width}
                          {...cell.getCellProps()}
                          className="  text-left fontPoppinsRegularSm pl-4  project-row "
                        >
                          {' '}
                          <span className="project-details-row ml-2">{cell.render('Cell')}</span>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Drawer>
  );
};

export default DashBoardDetails;

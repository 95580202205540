import React, { useState } from 'react';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';
import { useAlert } from '../../common/alert_service/useAlert';

import { SearchOutlined } from '@material-ui/icons';
import '../../common/dropdown.css';
const WaitConfigHistory = (props) => {
  const { AlertContatiner, MyAlert } = useAlert();
  let activeTab = props.location.pathname.split('/')[3];

  const [tabsList, setTabsList] = useState([
    {
      name: 'Wait Configuration',
      pathUrl: `/configuration/RunConfiguration/Waitconfiguration`,
      selectedName: 'waitconfiguration',
    },

    {
      name: 'History',
      pathUrl: `/configuration/RunConfiguration/History`,
      selectedName: 'History',
    },
  ]);

  const renderTabs = () => {
    return (
      <div>
        <ul className="navBar text-blue-600 flex flex-row primary-tab">
          {tabsList.map((tab, index) => {
            return (
              <li key={tab.name}>
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    props.history.push(tab.pathUrl);
                  }}
                  className={`${activeTab === tab.selectedName ? 'selected_tab_style' : ''}`}
                >
                  {tab.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <div className="page-container">
      <div className="page-list-body">
        {renderTabs()}
        <AlertContatiner />
        <div className="page-table-body">
          <div className="configPage pr-0 content_area_style" id="journal-scroll">
            <div className="grid grid-cols-2 mb-4 ">
              <span className=" ml-3 mt-4 configPageHeading">History</span>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "15px",
                }}
              >
                <SearchOutlined className="text-gray-500 -ml-3 mt-5 cursor-pointer" />
              </div> */}
            </div>
            <hr></hr>

            <div className=" grid cols-grid-6">
              <span className="text-sm text-gray-700 hover:bg-gray-100 h-10 p-3">
                Manjunath has updated Explit wait to 1000 Milliseconds on 28 jan 2021 13:20
              </span>
              <span className="text-sm text-gray-700 hover:bg-gray-100 h-10 p-3">
                Manjunath has updated Implict wait to 1000 Milliseconds on 28 jan 2021 13:20
              </span>
              <span className="text-sm text-gray-700 hover:bg-gray-100 h-10 p-3">
                Manjunath has updated Delay between steps to 2000 Milliseconds on 28 jan 2021 13:20
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(WaitConfigHistory);

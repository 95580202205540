import { forwardRef, useEffect, useRef, useState } from 'react';
import './CommentForm.scss';
import { Mention } from 'primereact/mention';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';

const CommentForm = forwardRef((props, ref) => {
  const { placeholder, getData, setData, isReset, setReset, id, isEdit, projectUsers } = props;

  const [currentValue, setCurrentValue] = useState(setData ? setData : '');
  const [projectUserName, setProjectUserName] = useState([]);
  const [currentPos, setcurrentPos] = useState(0);
  const inputRef = useRef('');

  const handleData = (e) => {
    let element = e.target;
    if (e.nativeEvent.data === '@' || e.target.value === '@  ' || e.target.value === '@' || e.type === 'change') {
      setcurrentPos(e.target.selectionStart);
    }
    if (e.target.value === undefined && e.target.innerText && currentPos > -1) {
      const caretPosition = inputRef?.current?.substr(0, currentPos);
      let endPosition = '';
      endPosition = inputRef?.current?.substr(currentPos, inputRef.current.length);
      const newVal = caretPosition + e.target.innerText + ' ';
      const val = `${newVal}${endPosition} `;
      inputRef.current = val;
      getData(val);
      setCurrentValue(val);
      setcurrentPos(0);
    } else {
      getData(e.target.value);
      setCurrentValue(e.target.value);
      inputRef.current = e.target.value;
    }
    element.style.height = 'auto';
    element.style.height = `${element.scrollHeight}px`;
  };

  useEffect(() => {
    if (!isEdit) {
      const textAreaElement = document.getElementById(id);
      if (textAreaElement) {
        textAreaElement.focus();
        textAreaElement.value = '';
      }
      getData('');
      setCurrentValue('');
    }

    return () => {
      setReset(false);
    };
  }, [isReset, isEdit, setReset, id, getData]);

  const onSearch = (event) => {
    setTimeout(() => {
      const query = event.query;
      let suggestion;

      if (!query.trim().length) {
        suggestion = [...projectUsers];
      } else {
        suggestion = projectUsers.filter((projectuser) => {
          return projectuser.name.toLowerCase().startsWith(query.toLowerCase());
        });
      }
      setProjectUserName(suggestion);
    }, 250);
  };

  const itemTemplate = (suggestion) => {
    return (
      <div className="flex align-items-center">
        <span className="flex flex-column">{suggestion.name}</span>
      </div>
    );
  };

  return (
    <>
      <Mention
        id={id}
        value={currentValue}
        onChange={handleData}
        suggestions={projectUserName}
        onSearch={onSearch}
        field="name"
        placeholder={placeholder}
        rows={3}
        itemTemplate={itemTemplate}
        autoResize
      />
    </>
  );
});

export default CommentForm;

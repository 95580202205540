import React, { useState, useContext } from 'react';
import { useTable, usePagination, useFilters, useGlobalFilter, useSortBy } from 'react-table';
import matchSorter from 'match-sorter';
import { useHistory, useLocation } from 'react-router-dom';
import ContextMenu from '../../common/context_menu';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import '../commons/template.scss';
import { useEffect } from 'react';
import { getAllTestCaseTemplate, exportManualTestCaseTemplate } from '@api/api_services';
import { deleteTemplateRequest, updateDefaultTemplateRequest } from '@api/api_services';
import { useAlert } from '@pages/common/alert_service/useAlert';
import ChangeDefaultTemplateModal from '../commons/ChangeDefaultTemplateModal';
import DeleteTemplateModal from '../commons/DeleteTemplateModal';
import { getSingleUserRequest } from '@api/api_services';
import UserDetailsModal from '../../test-development/shared/user-details-modal';
import DetailsModal from '../commons/TemplateDetailsModal';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { CommonLoader } from '@src/pages/common/common-loader';
import SearchBar from '@pages/analytics/common/search-bar';
import { HighlightText, isEmptyValue, isValueIncludes } from '@util/common_utils';
import NoResultsFound from '@pages/common/Table/NoResultFound';
import { saveFileFromBlob } from '@src/pages/test-development/shared/common-methods';
import { getTimeStampForFileName } from '@src/util/common_utils';
import { ReactComponent as DownloadExportIcon } from '@assets/file_download.svg';
import { getScriptOptions } from '@src/pages/test-development/script/modules/module/utils/common-functions';

function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search...`}
    />
  );
}

export default function TCTemplatePage() {
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [showModal, setShowModal] = React.useState(false);
  const [errorContent, setErrorContent] = React.useState();
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reloadTree, setReloadTree] = useState(true);
  const [templates, setTemplates] = React.useState([]);
  const [seletedTemplate, setSelectedTemplate] = React.useState({});
  const history = useHistory();
  const [pageIndexValue, setPageIndex] = React.useState(0);
  const [testCaseTemplateData, setTestCaseTemplateData] = useState([]);
  const [defaultTemplate, setDefaultTemplate] = useState({});
  const projectId = JSON.parse(localStorage.getItem('selected-project')).id;
  const [disableBtn, setDisableBtn] = useState(true);
  const [disableTemplateRadioButton, setDisableTemplateRadioButton] = useState(false);
  const [OpenDefaultTempModal, setOpenDefaulTemptModal] = useState(false);
  const [openDeleteTempModal, setOpenDeleteTempModal] = useState(false);
  const { AlertContatiner, MyAlert } = useAlert();
  const location = useLocation();
  const actiont = new URLSearchParams(location.search).get('actiont');
  const [deleteTempId, setDeleteTempId] = useState('');
  const [deleteTprojectId, setDeleteTprojectIdId] = useState('');
  const [deleteTempName, setDeleteTempName] = useState('');
  const [defaultTempEvent, setDefaultTemplateEvent] = useState({});
  const [defaultTempName, setDefaultTempName] = useState('');
  const [userName, setUserName] = useState('');
  const [userDetails, setUserDetails] = useState({});
  const [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);
  const [showDetails, setShowDetails] = useState({});
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  let currentTimeStamp = getTimeStampForFileName();

  const getUserDetails = async (userId) => {
    if (userId) {
      let res = await getSingleUserRequest(userId);
      if (res.data.responseCode === 200 && res.data.responseObject) {
        setUserDetails(res.data.responseObject);
        setOpenUserDetailsModal(true);
      }
    }
  };

  const closeUserDetailsModal = (value) => {
    setOpenUserDetailsModal(value);
    setUserDetails({});
  };

  function fuzzyTextFilterFn(page, id, filterValue) {
    return matchSorter(page, filterValue, { keys: [(page) => page.values[id]] });
  }

  function Table({ columns, data }) {
    const filterTypes = React.useMemo(
      () => ({
        // Add a new fuzzyTextFilterFn filter type.
        fuzzyText: fuzzyTextFilterFn,
        // Or, override the default text filter to use
        // "startWith"
        text: (page, id, filterValue) => {
          return page.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      [testCaseTemplateData, defaultTemplate]
    );

    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      nextPage,
      previousPage,
      state,
      visibleColumns,
      preGlobalFilteredRows,
      setGlobalFilter,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
      },
      useFilters, // useFilters!
      useGlobalFilter, // useGlobalFilter!
      useSortBy,
      usePagination
    );

    // We don't want to render all of the rows for this example, so cap
    // it for this use case
    // const firstPageRows = rows.slice(0,10)
    // const firstPageRows = rows;
    return (
      <>
        <table className="relative w-full h-12" {...getTableProps()}>
          <thead className=" sticky top-0 table_header_color h-12">
            {headerGroups.map((headerGroup, i) => (
              <tr key={`project-list-tr-${i}`} {...headerGroup.getHeaderGroupProps()} className="h-12">
                {headerGroup.headers.map((column, colIndex) => (
                  <th
                    key={`project-list-head-${colIndex}`}
                    className="mb-4 px-6 py-4 text-sm  ml-4 z-10 h-12"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <span className="flex flex-row mr-12 border-none text-gray-700 decoration-[#3C3838] text-xs fontPoppinsSemiboldMd">
                      {column.render('Header')}
                      {/* Render the columns filter UI */}

                      <div>
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <AiOutlineArrowDown className="mt-1 ml-1" />
                            ) : (
                              <AiOutlineArrowUp className="mt-1 ml-1" />
                            )
                          ) : (
                            ''
                          )}
                        </span>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="divide-y z-10" {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  key={`project-list-row-${i}`}
                  className="project-row border-none h-12  configHover"
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <td
                        key={`project-list-td-${cellIndex}`}
                        className="px-3 py-0 mr-2 text-sm fontFamilyOpenRegular pl-[17px]"
                        {...cell.getCellProps({})}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }

  const defaulTemplateRadioHandler = () => {
    const templateId = defaultTempEvent.row.original._id;
    let { Actions, ...tempTemplateData } = defaultTempEvent.row.original;
    setDefaultTemplate({ ...tempTemplateData });
    updateDefaultTemplateRequest(projectId, tempTemplateData, templateId)
      .then((results) => {
        if (results.data && results.data.responseCode === 200) {
          reloadpage();
          MyAlert.success(`Default Template updated successfully`);
          setDisableBtn(true);
        } else {
          setErrorContent(results.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error('Update Default Template Error', error.response.message);
        }
      });
  };

  // TODO :: handle Search filter :: START
  // TODO :: handle Search filter :: START
  const [userSearched, setuserSearched] = useState('');
  let [openSearch, setOpenSearch] = useState(false);
  const [data, setData] = useState([]);
  let [isHighlight, setIsHighlight] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');

  useEffect(() => {
    setData(testCaseTemplateData);
    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [testCaseTemplateData, defaultTemplate]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(testCaseTemplateData);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setSearchTextHighlight('');
    setData(testCaseTemplateData);
    setIsHighlight(!isHighlight);
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = testCaseTemplateData.filter(({ name, modifiedByUname }) => {
          return isValueIncludes(e, name) || isValueIncludes(e, modifiedByUname);
        });
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setData(newData);
        setIsHighlight(!isHighlight);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  // TODO :: handle Search filter :: END

  const columns = React.useMemo(
    () => [
      {
        Header: ' ',
        Cell: (e) =>
          projectId ? (
            <input
              type="radio"
              disabled={!isEditable}
              id="template-name"
              name="template-name"
              onChange={() => {
                setDefaultTemplateEvent(e);
                setUserName(e.row.original.createdByUname);
                setDefaultTempName(e.row.original.name);
                setOpenDefaulTemptModal(true);
              }}
              value={e.row.original._id}
              defaultChecked={e.row.original._id === defaultTemplate._id ? true : false}
            ></input>
          ) : null,
        columns: !projectId && [],
      },
      {
        Header: 'Name',
        columns: [],
      },

      {
        Header: 'NAME',
        accessor: 'name',
        disableFilters: true,

        Cell: (e) => (
          <Tooltip title={e.value} placement="top">
            <div
              href=""
              onClick={() => {
                history.push(
                  `/configuration/template/templatecreation?mode=view`,
                  JSON.stringify({ data: e.row.original })
                );
              }}
              className="hyper_link_color-common fontPoppinsRegularMd float-left ml-2 collumnCell cursor-pointer"
            >
              {' '}
              {e.cell.row.values.status !== 'Archive' ? (
                <span>
                  <HighlightText text={e.value} highlight={searchTextHighlight} />
                </span>
              ) : (
                <span className="collumnCellSpan">
                  <HighlightText text={e.value} highlight={searchTextHighlight} />
                </span>
              )}{' '}
            </div>
          </Tooltip>
        ),
      },

      {
        Header: 'TEMPLATE TYPE',
        accessor: 'templateType',
        // Use our custom `fuzzyText` filter on this column
        filter: 'fuzzyText',
        disableFilters: true,
        Cell: ({ value }) => (
          <div className="table-non-link-color-common fontPoppinsRegularMd float-left ml-2">{value}</div>
        ),
      },
      {
        Header: 'FROM',
        Cell: (e) =>
          e.row.original.projectId ? (
            <div className="table-non-link-color-common fontPoppinsRegularMd float-left ml-2">Project</div>
          ) : (
            <div className="table-non-link-color-common fontPoppinsRegularMd float-left ml-2">License</div>
          ),
        columns: !projectId && [],
      },
      {
        Header: 'MODIFIED BY',
        accessor: 'modifiedByUname',
        disableFilters: true,
        Cell: (e) =>
          e.value === '--' ? (
            <div className="table-non-link-color-common fontPoppinsRegularMd float-left ml-2" title={e.value}>
              <HighlightText
                text={e.value.length > 15 ? e.value.substr(0, 15) + '...' : e.value}
                highlight={searchTextHighlight}
              />
            </div>
          ) : (
            <div
              className="hyper_link_color-common cursor-pointer fontPoppinsRegularMd float-left ml-2 border-0 outline-none"
              title={e.value}
              onClick={() => {
                getUserDetails(e?.row.original?.modifiedBy);
              }}
            >
              <HighlightText
                text={e.value.length > 15 ? e.value.substr(0, 15) + '...' : e.value}
                highlight={searchTextHighlight}
              />
            </div>
          ),
      },
      {
        Header: 'MODIFIED ON',
        accessor: 'modifiedOn',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common fontPoppinsRegularMd float-left ml-2">{value}</div>;
        },
      },
      {
        Header: 'ACTIONS',
        accessor: 'Actions',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common rs-icon-alignment-font-style">{value}</div>;
        },
      },
    ],
    [testCaseTemplateData, defaultTemplate, isHighlight]
  );

  useEffect(() => {
    if (reloadTree) {
      setIsLoading(true);
      getTestCaseTemplate();
    }
    if (actiont) {
      const tempName = new URLSearchParams(location.search).get('tempName');
      switch (actiont) {
        case 'create':
          MyAlert.success(`${tempName} template created successfully`);
          setTimeout(() => {
            history.push(`/configuration/template`);
          }, 10);
          break;
        case 'edit':
          MyAlert.success(`${tempName} template updated successfully`);
          setTimeout(() => {
            history.push(`/configuration/template`);
          }, 10);
          break;
      }
    }
    setReloadTree(false);
  }, [reloadTree, testCaseTemplateData, defaultTemplate]);

  const reloadpage = () => {
    setReloadTree(true);
  };

  const deleteTemplate = () => {
    if (deleteTempId) {
      deleteTemplateRequest(deleteTempId, !!deleteTprojectId)
        .then((results) => {
          if (results.data && results.data.responseCode === 200) {
            reloadpage();
            MyAlert.success(`${deleteTempName} Manual test case template deleted successfully`);
          } else if (
            results.data &&
            results.data.responseCode === 400 &&
            results.data.message.includes("Default template can't be deleted")
          ) {
            MyAlert.info(
              `You are not allowed to delete ${deleteTempName},  as it is being used as the default template`
            );
          } else {
            setErrorContent(results.data.message);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.error('Delete Template Error', error.response.message);
          }
        });
    }
  };

  const contextMenuData = [
    {
      option: 'Details',
    },
  ];

  function contextMenuFunction(event, resObj) {
    if (event.target.textContent === 'Details') {
      setOpenDetailsModal(true);
      setShowDetails(resObj);
    }
  }

  const closeDetailsModal = (value) => {
    setOpenDetailsModal(value);
  };

  useEffect(() => {
    if (testCaseTemplateData) {
      testCaseTemplateData.map((res, i) => {
        res.Actions = [];
        res.Actions.push(
          <div className="actionIcons flex justify-start align-middle">
            {isEditable && (
              <>
                <button
                  disabled={(!res.hasOwnProperty('projectId') && !!projectId) || res.templateType === 'Predefined'}
                  onClick={() => {
                    history.push(`/configuration/template/templatecreation?mode=edit`, JSON.stringify({ data: res }));
                  }}
                >
                  <Tooltip
                    title={
                      !res.hasOwnProperty('projectId') && !!projectId && res.templateType === 'Userdefined'
                        ? 'Template from all projects cannot be edited'
                        : res.templateType === 'Predefined'
                          ? 'Predefined template cannot be edited'
                          : 'Edit'
                    }
                    placement="top"
                  >
                    <EditOutlinedIcon
                      className={
                        (!res.hasOwnProperty('projectId') && !!projectId) || res.templateType === 'Predefined'
                          ? ' opacity-50 mr-3'
                          : 'float-left cursor-pointer mr-3 text-blue-700 opacity-100'
                      }
                    />
                  </Tooltip>
                </button>

                {hasFullAccess && (
                  <button
                    disabled={(!res.hasOwnProperty('projectId') && !!projectId) || res.templateType === 'Predefined'}
                    onClick={() => {
                      setUserName(res?.createdByUname);
                      setDeleteTempName(res.name);
                      setDeleteTempId(res._id);
                      setDeleteTprojectIdId(res.projectId);
                      setOpenDeleteTempModal(true);
                    }}
                  >
                    <Tooltip
                      title={
                        !res.hasOwnProperty('projectId') && !!projectId && res.templateType === 'Userdefined'
                          ? 'Template from all projects cannot be deleted'
                          : res.templateType === 'Predefined'
                            ? 'Predefined template cannot be deleted'
                            : 'Delete'
                      }
                      placement="top"
                    >
                      <DeleteOutlinedIcon
                        className={
                          (!res.hasOwnProperty('projectId') && !!projectId) || res.templateType === 'Predefined'
                            ? 'opacity-50 mr-3 '
                            : 'float-left cursor-pointer  mr-3 text-blue-700 opacity-100'
                        }
                      />
                    </Tooltip>
                  </button>
                )}
              </>
            )}

            <div className="cursor-pointer project-action mr-3 mt-1">
              <Tooltip title="Download" placement="top">
                <DownloadExportIcon
                  className=" cursor-pointer"
                  onClick={() => handleExportTemplates(res?._id, res.name)}
                />
              </Tooltip>
            </div>
            <div className="project-action cursor-pointer mr-3">
              <ContextMenu
                contextMenuData={res ? contextMenuData : [...contextMenuData]}
                func={(e) => {
                  contextMenuFunction(e, res);
                }}
                configSection={true}
              />
            </div>
          </div>
        );
      });
    }
  }, [testCaseTemplateData, defaultTemplate]);

  async function getTestCaseTemplate(moduleId) {
    try {
      resetProgress();
      startOrResumeProgress({
        stopAt: 90,
      });
      const getdata = await getAllTestCaseTemplate('config');
      stopProgress();
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 10,
      });
      if (getdata?.data?.responseObject?.manualScriptPresent) {
        setDisableTemplateRadioButton(getdata.data.responseObject.manualScriptPresent);
      } else {
        setDisableTemplateRadioButton(false);
      }
      if (getdata.data.responseObject.defaultTemplate) {
        setDefaultTemplate({ ...getdata.data.responseObject.defaultTemplate });
      }
      if (getdata.data.responseObject.templates) {
        const templates = getdata.data.responseObject.templates;
        const exists =
          templates.filter(function (o) {
            return o.hasOwnProperty('projectId');
          }).length > 0;

        if (exists) {
          setDisableBtn(true);
        } else {
          setDisableBtn(false);
        }
      }
      if (getdata.data.responseObject.templates) {
        setTestCaseTemplateData(getdata.data.responseObject.templates);
      } else {
        setIsLoading(false);
        setTestCaseTemplateData([]);
      }
      setIsLoading(false);
    } catch (err) {
      resetProgress();
      console.error('GET_TESTCASE_TEMPLATE : ', err);
    }
  }

  const { isEditable, hasFullAccess } = React.useMemo(
    () => ({
      isEditable: window.permission?.isEditAccess('configuration'),
      hasFullAccess: window.permission?.isFullAccess('configuration'),
    }),
    []
  );

  // TODO :: handle Export Manual Template :: START

  let allTypesProjects = [
    { Web: ['Web', 'Webservice', 'Database'] },
    { Mobile: ['Android', 'iOS', 'Mobile (Android & iOS)', 'Mobile (Android or iOS)', 'Webservice', 'Database'] },
    {
      'Web & Mobile': [
        'Web',
        'Android',
        'iOS',
        'Mobile (Android & iOS)',
        'Mobile (Android or iOS)',
        'Web & Mobile (Android & iOS)',
        'Web & Mobile (Android or iOS)',
        'Webservice',
        'Database',
      ],
    },
    { 'Web Services': ['Webservice', 'Database'] },
    {
      Salesforce: [
        'Web',
        'Android',
        'iOS',
        'Mobile (Android & iOS)',
        'Mobile (Android or iOS)',
        'Web & Mobile (Android & iOS)',
        'Web & Mobile (Android or iOS)',
        'Webservice',
        'Database',
      ],
    },
    {
      'MS Dynamics': [
        'Web',
        'Android',
        'iOS',
        'Mobile (Android & iOS)',
        'Mobile (Android or iOS)',
        'Web & Mobile (Android & iOS)',
        'Web & Mobile (Android or iOS)',
        'Webservice',
        'Database',
      ],
    },
  ];

  let handleExportTemplates = async (templateId, templateName) => {
    const selectedProject = JSON.parse(localStorage.getItem('selected-project'));
    let scriptOptions = getScriptOptions()?.map((data) => data?.value);
    let payloadObj = {};
    payloadObj[selectedProject?.type] = scriptOptions;
    let payloadArr = [payloadObj];

    let finalPayload = {
      templateId,
      payload: !projectId ? allTypesProjects : payloadArr,
    };

    try {
      let response;
      if (projectId) {
        response = await exportManualTestCaseTemplate(finalPayload);
        saveFileFromBlob(response.data, `${templateName}_${currentTimeStamp}.xlsx`);
      } else {
        response = await exportManualTestCaseTemplate(finalPayload);
        saveFileFromBlob(response.data, `${templateName}_${currentTimeStamp}.xlsx`);
      }
      MyAlert.success(`Manual Test Cases Exported Successfully`);
    } catch (err) {
      console.error('ERROR IN EXPORT MANUAL TEST CASE API ', err);
    }
  };

  // TODO :: handle Export Manual Template :: END

  return (
    <div className="page-list-body">
      <>
        <div className="page-table-body">
          <div className="content_area_style_TCM">
            <div className="content_area_style_tcTemplate">
              <div className="alert-position-style">
                <AlertContatiner></AlertContatiner>
              </div>
              <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
                <label className="project_label text-sm flex-auto">Manual Test Case Templates</label>
                <div className="pr-4">
                  <div className="flex flex-row ml-32">
                    <SearchBar
                      open={openSearch}
                      searchValue={userSearched}
                      handleSearch={handleSearch}
                      onSearchClose={handleSearchClose}
                      handleSearchOpen={handleSearchOpen}
                      enterKeyHandler={handleSearchSubmit}
                      disable={isEmptyValue(testCaseTemplateData)}
                    />

                    {isEditable && (
                      <button
                        className="primary-btn ml-4"
                        disabled={disableBtn}
                        onClick={() => {
                          history.push(`/configuration/template/templatecreation?mode=create`);
                        }}
                      >
                        + Template
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="table-height overflow-auto" id="journal-scroll">
                <Table columns={columns} data={data} />
                {isLoading ? (
                  <CommonLoader />
                ) : isEmptyValue(testCaseTemplateData) ? (
                  <div className="fontPoppinsRegularMd text-sm no-steps">
                    <div className="">
                      <p className="font-bold mb-4">{isEditable ? 'Quick Start' : 'No Templates!!'}</p>
                      {isEditable && (
                        <p className="mb-4">
                          <span className="font-bold">Step 1 :</span> Click
                          <button
                            className="primary-btn ml-4"
                            onClick={() => {
                              history.push(`/configuration/template/templatecreation?mode=create`);
                            }}
                          >
                            + Template
                          </button>
                          {`  to start adding the Templates`}
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  <>{openSearch && isEmptyValue(data) && <NoResultsFound className="mt-14" />}</>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
      {OpenDefaultTempModal && (
        <ChangeDefaultTemplateModal
          defaulTemplateRadioHandler={defaulTemplateRadioHandler}
          openForDefaultTempChange
          handleCloseForDefaultTempChange={() => {
            setOpenDefaulTemptModal(false);
          }}
          defaultTempName={defaultTempName}
          userName={userName}
        />
      )}
      {openDeleteTempModal && (
        <DeleteTemplateModal
          openForDeleteTempChange
          handleCloseForDeleteTempChange={() => {
            setOpenDeleteTempModal(false);
          }}
          deleteTemplate={deleteTemplate}
          deleteTempName={deleteTempName}
          userName={userName}
        />
      )}
      {openUserDetailsModal ? <UserDetailsModal data={userDetails} closeModal={closeUserDetailsModal} /> : null}
      {openDetailsModal ? <DetailsModal showDetails={showDetails} closeModal={closeDetailsModal} /> : null}
    </div>
  );
}

import React, { useState, useEffect, useContext, Fragment } from 'react';
import { CommonLoader } from '@pages/common/common-loader';
import { Menu, Transition } from '@headlessui/react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ReactComponent as AddIcon } from '@assets/svg-imports/plus.svg';
import { ReactComponent as QuickStart } from '@assets/svg-imports/QuickStartLanding.svg';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import './dashBoard.scss';
import DeleteDashBoard from './DashBoardDeleteModal';
import { useAlert } from '@pages/common/alert_service/useAlert';
import { HighlightText, isEmptyValue, isValueIncludes, getCurrentProjectStatus } from '@src/util/common_utils';
import CreateEditDashboard from './CreateEditDashboard';
import DashBoardDetails from './DashBoardDetails';
import { useHistory } from 'react-router-dom';
import CustomGraphTable from './custom_graph/CustomGraphTable';
import { DASHBOARD_CONSTANTS } from '@src/common/ui-constants';
import { getAllDashBoard } from '@src/api/api_services';
import { getCurrentProjectId } from '@util/common_utils';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import UserDetailsDashBoard from './DshBoardUserDetails';
import SearchBar from '@src/pages/analytics/common/search-bar';
import { Table, Tooltip, Icon } from 'fireflink-ui';

function DashBoardTable(props) {
  const { AlertContatiner, MyAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDashBoard, setSelectedDashBoard] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openCustomGraphTable, setOpenCustomGraphTable] = useState(false);
  const [reloadTree, setReloadTree] = useState(true);
  const [actionType, setActionType] = useState();
  const [openDashBoard, setOpenDashBoard] = useState(false);
  const [dashBoardDetails, setDashBoardDetails] = useState(false);
  const [pageIndexValue, setPageIndex] = useState(0);
  const [openUserList, setOpenUserList] = useState(false);
  const [dashBoardTable, setDashBoardTable] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  const [userSearched, setuserSearched] = useState('');
  let [isHighlight, setIsHighlight] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const history = useHistory();
  const projectStatus = getCurrentProjectStatus();
  const reloadpage = () => {
    setReloadTree(true);
  };
  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const openDashBoardDetails = (value) => {
    setDashBoardDetails(true);
    setSelectedDashBoard(value);
  };
  const closeDashBoardDetails = () => {
    setDashBoardDetails(false);
  };

  const openModal = (value, actionType) => {
    setOpenDashBoard(true);
    setSelectedDashBoard(value);
    setActionType(actionType);
  };
  const closeModal = () => {
    setOpenDashBoard(false);
  };
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(dashBoardTable);
    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [dashBoardTable]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(dashBoardTable);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  const handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setData(dashBoardTable);
    setSearchTextHighlight('');
    setIsHighlight(!isHighlight);
  };

  const handleSearchOpen = () => {
    setOpenSearch(true);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = dashBoardTable.filter(({ name, createdByUname, modifiedByUname, modifiedOn }) => {
          return (
            isValueIncludes(e, name) ||
            isValueIncludes(e, createdByUname) ||
            isValueIncludes(e, modifiedByUname) ||
            isValueIncludes(e, modifiedOn)
          );
        });
        setData(newData);
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  const openUserModal = (value) => {
    setSelectedDashBoard(value);
    setOpenUserList(true);
  };
  const navigateToCustomGraph = (value) => {
    history.push(
      `/dashboard/custom-dashboard/${value.name}/customiseGraph?id=${value.id}&access=${value.access}&name=${value.name}`
    );
  };
  const closeUserModal = () => {
    setOpenUserList(false);
  };
  const openModalDelete = (value) => {
    setOpenDeleteModal(true);
    setSelectedDashBoard(value);
  };

  const projectId = getCurrentProjectId();
  const getDashBoard = () => {
    try {
      setIsLoading(true);
      resetProgress();
      startOrResumeProgress({
        stopAt: 90,
      });
      getAllDashBoard(projectId).then((response) => {
        stopProgress();
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 10,
        });
        if (response?.data?.responseCode === 200) {
          let dashBoardData = response?.data?.responseObject.filter((data) => {
            if (data.access !== 'NoAccess') {
              return data;
            }
          });
          setDashBoardTable(dashBoardData);
          setIsLoading(false);
        } else if (response?.data?.responseCode === 404) {
          setDashBoardTable([]);
          setIsLoading(false);
        }
      });
    } catch (error) {
      console.error('An error occurred:', error);
      setDashBoardTable([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (reloadTree) {
      setIsLoading(true);
      getDashBoard();
    }
    setReloadTree(false);
  }, [reloadTree]);

  const actionDetails = [
    {
      className: '-ml-1',
      onClick: (row) => openModal(row, 'Edit'),
      tooltipTitle: DASHBOARD_CONSTANTS.EDIT,
      icon: <Icon name="edit_icon" hoverEffect={true} />,
      name: 'Edit',
    },
    {
      className: 'ml-2',
      onClick: (row) => openModalDelete(row, 'Delete'),
      tooltipTitle: DASHBOARD_CONSTANTS.DELETE,
      icon: <Icon name="delete" hoverEffect={true} />,
      name: 'Delete',
    },
  ];

  const columns = React.useMemo(
    () => [
      {
        header: 'NAME',
        accessor: 'name',
        width: '30%',
        cell: (e) => {
          return (
            <div className=" cursor-pointer table_link " title={e.value} onClick={() => navigateToCustomGraph(e?.row)}>
              <HighlightText text={e?.value} highlight={searchTextHighlight} />
            </div>
          );
        },
      },
      {
        header: 'CREATED BY',
        accessor: 'createdByUname',
        width: '20%',
        cell: (e) => {
          return (
            <div title={e.value} className="cursor-pointer table_link " onClick={() => openUserModal(e?.row)}>
              <span>
                <HighlightText text={e.value} highlight={searchTextHighlight} />
              </span>
            </div>
          );
        },
      },
      {
        header: 'MODIFIED BY',
        accessor: 'modifiedByUname',
        width: '20%',
        cell: (e) => {
          return (
            <div title={e.value} className="cursor-pointer table_link " onClick={() => openUserModal(e?.row)}>
              <span>
                <HighlightText text={e.value} highlight={searchTextHighlight} />
              </span>
            </div>
          );
        },
      },
      {
        header: 'MODIFIED ON',
        accessor: 'modifiedOn',
        width: '20%',
        cell: (e) => (
          <div title={e.value} className="selected-value-field ">
            <span className="">
              <HighlightText text={e.value} highlight={searchTextHighlight} />
            </span>
          </div>
        ),
      },

      {
        header: 'ACTIONS',
        accessor: 'actions',
        className: 'action-column',
        cell: ({ row }) => {
          return (
            <div>
              <span className="flex flex-row table-non-link-color-common float-left table-font-style-common ">
                {projectStatus === 'Open' &&
                  actionDetails.map((data, index) =>
                    (data.name === 'Edit' && !(row?.access === 'FullAccess' || row?.access === 'Edit')) ||
                      (data.name === 'Delete' && row?.access !== 'FullAccess') ? null : (
                      <span key={index} className={data?.className} onClick={() => data.onClick(row)}>
                        <Tooltip title={data?.tooltipTitle}>{data?.icon}</Tooltip>
                      </span>
                    )
                  )}
                <span className=" text-base  cursor-hand memory-state-button ml-2 ">
                  <Tooltip title="More">
                    <Menu as="div" className="relative inline-block text-left" key="user-menu">
                      {({ open }) => (
                        <>
                          <div>
                            <Menu.Button
                              className="flex items-center  focus:outline-none "
                              onMouseOver={(e) => {
                                let mouse = e.clientY;
                                setPageIndex(mouse);
                              }}
                            >
                              <Icon name="more_icon" hoverEffect={true} />,
                            </Menu.Button>
                          </div>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className={`${pageIndexValue > 520 ? '-mt-24 ' : 'ml-2'} menu-items-details`}
                            >
                              <div className="py-3">
                                <Menu.Item>
                                  <div
                                    onClick={() => openDashBoardDetails(row, 'more')}
                                    className="block px-4 py-1 text-sm details-background"
                                  >
                                    Details
                                  </div>
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </Tooltip>
                </span>
                <span className=" pt-1  text-base cursor-pointer state-button ml-2"></span>
              </span>
            </div>
          );
        },
      },
    ],
    [isHighlight]
  );

  return (
    <>
      <div className="page-list-body">
        <div className="alert-position-style">
          <AlertContatiner></AlertContatiner>
        </div>
        <div className="page-table-body mt-2">
          <div className="content_area_style_Int">
            <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
              <label className=" flex-auto fontPoppinsSemiboldMd label-header-style ml-2">
                {DASHBOARD_CONSTANTS.CUSTOM_DASHBOARD_LIST}
              </label>
              <div className=" flex flex-row items-center">
                <div className={`${projectStatus === 'Open' ? 'mr-32' : 'mr-5'}  mt-1`}>
                  <SearchBar
                    open={openSearch}
                    searchValue={userSearched || ''}
                    handleSearch={(e) => {
                      setuserSearched(e.target.value);
                    }}
                    onSearchClose={handleSearchClose}
                    handleSearchOpen={handleSearchOpen}
                    enterKeyHandler={handleSearchSubmit}
                    disable={isEmptyValue(dashBoardTable)}
                  />
                </div>
                {projectStatus === 'Open' && (
                  <div className="custom-graph-common custom-graph-Btn" onClick={openModal}>
                    <span>
                      <AddIcon className="custom-Graph-Add-DefectIcon" />
                    </span>
                    <span className="customGraph-title">{DASHBOARD_CONSTANTS.DASHBOARD}</span>
                  </div>
                )}
              </div>
            </div>
            <div className={!isEmptyValue(dashBoardTable) && 'dashBoard-table-height'}>
              <Table
                columns={columns}
                data={data}
                headerType="secondary"
                className="fontPoppinsSemiboldXs"
                noDataText=""
                withFixedHeader={true}
                borderWithRadius={false}
                height={!isEmptyValue(dashBoardTable) ? '480px' : '380px'}
              />
            </div>
            {isLoading ? (
              <CommonLoader />
            ) : isEmptyValue(data) ? (
              <div className="fontPoppinsRegularMd dashBoard-no-data">
                <div className="">
                  <div className="  flex flex-row">
                    <div className="fontPoppinsSemiboldSm mt-2 rs-text-black">
                      {DASHBOARD_CONSTANTS.STEP_1}
                      <span className="fontPoppinsRegularSm rs-text-black"> {DASHBOARD_CONSTANTS.CLICK}</span>
                    </div>
                    <div className="custom-graph-common  QuickStartCustomGraphAddBtn " onClick={openModal}>
                      <span>
                        <AddIcon className="custom-Graph-Add-DefectIcon" />
                      </span>
                      <span className="customGraph-title">{DASHBOARD_CONSTANTS.DASHBOARD}</span>
                    </div>

                    <div className="rs-text-black fontPoppinsRegularSm mt-2 ml-1">
                      {DASHBOARD_CONSTANTS.START_CREATING}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {openDashBoard && (
        <CreateEditDashboard
          isOpen={openDashBoard}
          onClose={closeModal}
          selectedDashBoard={selectedDashBoard}
          actionType={actionType}
          reloadTree={reloadpage}
          MyAlert={MyAlert}
        />
      )}
      {openUserList && (
        <UserDetailsDashBoard
          isOpen={openUserList}
          onClose={closeUserModal}
          selectedDashBoard={selectedDashBoard}
          actionType={actionType}
          reloadTree={reloadpage}
          MyAlert={MyAlert}
        />
      )}
      {dashBoardDetails && (
        <DashBoardDetails
          isOpen={dashBoardDetails}
          onClose={closeDashBoardDetails}
          selectedDashBoard={selectedDashBoard}
          actionType={actionType}
          reloadTree={reloadpage}
          MyAlert={MyAlert}
        />
      )}
      {openDeleteModal && (
        <DeleteDashBoard
          openModal={openModalDelete}
          closeModal={closeDeleteModal}
          selectedDashBoard={selectedDashBoard}
          actionType={actionType}
          reloadTree={reloadpage}
          MyAlert={MyAlert}
        />
      )}
      {openCustomGraphTable && <CustomGraphTable />}
    </>
  );
}

export default DashBoardTable;

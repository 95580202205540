import React, { useEffect, useContext, useState, useMemo, useRef } from 'react';
import TableTree from '@pagescommon/table_tree/table_tree';
import MoreInfoButton from '@pagescommon/more_info_button';
import { ReactComponent as ExpandArrow } from '@assets/analytics/ExpandArrow.svg';
import { useTable, usePagination, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy } from 'react-table';
import matchSorter from 'match-sorter';
import Modal from '@material-ui/core/Modal';
import { ReactComponent as ShrinkIcon } from '@assets/analytics/ShrinkIcon.svg';
import {
  getAllModuleTreeReq,
  getSingleSuiteReq,
  getAllEmailGroupListReq,
  getRunSettingsDataReq,
  getAllSlackInstance,
} from '@api/api_services';
import { CommonLoader } from '@pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { Tooltip } from '@material-ui/core';
import { TEST_ENV_TABLE_WIDTH_SET, EXECUTION_CONSTANTS } from '@common/ui-constants';
import { renderBrowserCell } from '@src/pages/execution/execution.js';
import { getTruncatedText, calculatedScriptNumber, getReportTypes } from '@src/util/common_utils';
import { getOsLabel } from '@src/pages/execution/suites/create-edit-child-pages/run-settings-child-pages/machine-child-pages/run-settings-machines-common/common-methods';
import { getModuleAndScriptData } from '@src/pages/test-development/shared/create-execution-tree';
import MachineEnvironmentLayout from '@src/common/MachineEnvironmentLayout';
import MachineEnvironmentTableLayout from '@src/common/MachineEnvironmentTableLayout';
import { SLACK_CONSTANTS } from '@src/pages/configuration/plugin/plugin_constants';
import { isEmptyValue } from '@src/util/common_utils';
let treeArrayForMultipleTree = [];
let totalScriptCount = 0;
const SuiteDetails = (props) => {
  const selectedSuite = window.location.pathname.split('/')[3];
  let [isLoading, setIsLoading] = useState(false);
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  let [treeData, setTreeData] = useState([]);
  let [rootData, setRootData] = useState([]);
  let [isExpandAll, setIsExpandAll] = useState(false);
  let [treeValue, setTreeValue] = useState('');
  let [reloadTree, setReloadTree] = useState(false);
  let [resetFilter, setResetFilter] = useState(false);
  let [suites, setSuites] = useState([]);
  let [emailTableData, setEmailData] = useState([]);
  const [slackTableData, setSlackTableData] = useState([]);
  let [singleMachine, setSingleMachine] = useState([]);
  let [runSettingsData, setRunSettingsData] = useState({});
  const selectedProject = JSON.parse(localStorage.getItem('selected-project'));
  const [searchedText, setSearchedText] = useState('');
  const [searchedTextQuery, setSearchedTextQuery] = useState('');
  const [userSearchInput, setUserSearchInput] = useState('');
  const [hasUserSearched, setHasUserSearched] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const totalModCount = useRef(0);

  const plusIconArray = [
    {
      name: 'Add Module',
      value: 'folder',
    },
    {
      name: 'Add Script',
      value: 'file',
    },
  ];

  const LABELS = {
    EXPAND_ALL: 'Expand All',
    COLLAPSE_ALL: 'Collapse All',
    SHOW_ALL_ELEMENTS: 'Show all scripts',
    HIDE_ALL_ELEMENTS: 'Hide all scripts',
    SHOW_PRE_POST_CONDITION: 'Show Pre/Post Conditions',
    HIDE_PRE_POST_CONDITION: 'Hide Pre/Post Conditions',
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  let handleSearchClose = () => {
    setOpenSearch(false);
    setUserSearchInput('');
    if (hasUserSearched) {
      setHasUserSearched(false);
      resetTreeFilter();
    }
  };

  const handleSearch = (e) => {
    const _value = e.target.value;
    setUserSearchInput(_value);
    if (typeof setSearchedText !== 'undefined') {
      setSearchedText(_value);
      if (_value === '' && hasUserSearched) {
        setHasUserSearched(false);
        resetTreeFilter();
      }
    }
  };

  const enterKeyHandler = (e) => {
    const searchQueryText = typeof e === 'string' ? e : e?.target?.value;
    const key = e?.key;
    setSearchedText(searchQueryText);
    if ('Escape' === key) {
      handleSearchClose();
    } else if (userSearchInput?.length) {
      setHasUserSearched(true);
      if (searchQueryText?.trim().length >= 1) {
        searchTree(searchQueryText.trim());
      }
    } else {
      if (hasUserSearched) {
        setUserSearchInput(searchQueryText.trim());
        setHasUserSearched(false);
        resetTreeFilter();
      }
    }
  };

  async function getModuleTree() {
    setIsLoading(true);
    let getdata;
    let root;
    try {
      getdata = await getAllModuleTreeReq();
      let rootDetails = getdata.data.responseObject.moduleTree[0];
      root = {
        subModuleCount: rootDetails.subModuleCount,
        title: rootDetails.title,
        scriptCount: rootDetails.scriptCount.$numberLong,
        key: rootDetails.key,
        ver: rootDetails.ver,
        modifiedByUname: rootDetails.modifiedByUname,
      };
      if (getdata) {
        getAllSuite();
        setTreeData([]);
      }
      setRootData(root);
      setIsExpandAll(rootDetails.expanded);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  const searchTree = async (_searchText) => {
    setResetFilter(false);
    setTreeValue(_searchText);
    setSearchedTextQuery(searchedText);
  };

  const resetTreeFilter = () => {
    setResetFilter(true);
    setTreeValue('');
    setSearchedText('');
    setSearchedTextQuery('');
  };

  function getAllSuite() {
    let search = window.location.search;
    let urlSuiteId = new URLSearchParams(search).get('suiteId');
    treeArrayForMultipleTree = [];
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    getSingleSuiteReq(urlSuiteId)
      .then((results) => {
        stopProgress();
        const response = results.data.responseObject;
        let runSettingsData = response;
        if (runSettingsData) {
          setSingleMachine(runSettingsData?.selectedMachines);
          if (response && response?.selectedModulesAndScripts) {
            if (response?.selectedModulesAndScripts.length) {
              response?.selectedModulesAndScripts[0].children.forEach((obj) => {
                obj.checkbox = false;
              });
            }
            setTreeData(response.selectedModulesAndScripts[0].children);
            let calculatedScriptCount;
            let calculatedModuleCount;
            if (
              runSettingsData?.machines?.multiple === 'true' &&
              runSettingsData?.machines?.executionType === 'DISTRIBUTE'
            ) {
              if (['MANUAL', 'AUTOMATIC'].includes(runSettingsData?.machines?.distributionMechanism)) {
                if (runSettingsData?.machines?.selectedMachines?.length) {
                  let selectedModulesAndScripts = response?.selectedModulesAndScripts?.[0]?.children;
                  runSettingsData.machines.selectedMachines.forEach((machine) => {
                    machine?.machineInstances?.forEach((disObj) => {
                      let tempSelModArray = disObj?.distribution?.selectedModules;
                      if (tempSelModArray?.length) {
                        let modules = tempSelModArray.map((name) => name).join(', ');
                        let tempSelectedModulesAndScripts = JSON.parse(JSON.stringify([...selectedModulesAndScripts]));
                        let filteredModules = JSON.parse(
                          JSON.stringify(getModuleAndScriptData(tempSelectedModulesAndScripts, modules))
                        );
                        totalModCount.current = 0;
                        if (filteredModules?.length) {
                          calculatedScriptCount = calculateScriptCount(filteredModules);
                          calculatedModuleCount = calculateModuleCount(filteredModules);
                          totalScriptCount = 0;
                          let renderTreeObj = {
                            treeId: disObj?.distribution?.distributionId || disObj?.distribution?.clientSystemId,
                            tableTreeId:
                              disObj?.distribution?.distributionId?.replaceAll(' ', '-') ||
                              disObj?.distribution?.clientSystemId?.replaceAll(' ', '-') ||
                              '',
                            treeArray: filteredModules,
                            rootData: {
                              key: filteredModules[0]?.parent?.key || filteredModules[0]?.parentId,
                              title: filteredModules[0]?.parent?.title || filteredModules[0]?.parentName,
                              calculatedScriptCount: calculatedScriptCount,
                              calculatedModuleCount: calculatedModuleCount,
                            },
                            deviceInfo: disObj,
                          };
                          if (renderTreeObj?.rootData?.title === 'Root') {
                            renderTreeObj.rootData.title = 'Root Module';
                          }
                          treeArrayForMultipleTree.push(renderTreeObj);
                        }
                      }
                    });
                  });
                }
              }
            } else {
              calculateScriptCount(response.selectedModulesAndScripts[0].children);
              setTreeData(response.selectedModulesAndScripts[0].children);
            }
          }
          if (treeArrayForMultipleTree?.length) {
            let colDefsChild = [];
            treeArrayForMultipleTree.forEach((obj) => {
              if (selectedProject.type.toLowerCase() === 'web' || selectedProject.type.toLowerCase() === 'mobile') {
                colDefsChild = [
                  {
                    field: 'title',
                    title: 'MODULE',
                    class: 'title',
                    isTitle: true,
                    width: `calc(100% - 796px)`,
                    folderTitleWidth: `calc(100% - 770px)`,
                    render: (nodeObj) => {
                      createFilterData(treeData);
                      return (
                        <>
                          {nodeObj.isRootNode && (
                            <>
                              <span class="count-badge folder-count ml-2">
                                M {obj?.rootData?.calculatedModuleCount}{' '}
                              </span>
                              <span class="count-badge file-count">
                                {' '}
                                S{' '}
                                {obj?.rootData?.calculatedScriptCount
                                  ? obj.rootData.calculatedScriptCount
                                  : obj?.treeArray[0].calculatedScriptCount}{' '}
                              </span>
                            </>
                          )}

                          {nodeObj.data.scriptCount > 0 && (
                            <span class="count-badge file-count"> S {nodeObj.data.calculatedScriptCount} </span>
                          )}
                          {nodeObj.folder && (
                            <button class="add-btn">
                              <MoreInfoButton type="add" nodeObj={nodeObj} plusIconArray={plusIconArray} />
                            </button>
                          )}
                        </>
                      );
                    },
                  },
                  {
                    field: 'scriptType',
                    title: 'TYPE',
                    class: 'Type truncate',
                    width: '74px',
                    render: (nodeObj) => {
                      return (
                        <Tooltip title={nodeObj.data.scriptType}>
                          <span className="text-xs table-non-link-color-common fontPoppinsRegularMd">
                            {nodeObj.data.scriptType}
                          </span>
                        </Tooltip>
                      );
                    },
                  },
                  {
                    field: 'Execution Environment',
                    title: 'EXECUTION ENVIRONMENT',
                    class: 'executionEnvironment truncate',
                    width: '170px',
                    render: (nodeObj) => {
                      const executionEnv = obj?.deviceInfo?.executionEnv;
                      if (nodeObj.isRootNode || nodeObj.data.scriptCount > 0) return null;
                      return (
                        <Tooltip title={executionEnv}>
                          <span className="text-xs table-non-link-color-common fontPoppinsRegularMd cursor-pointer">
                            {executionEnv}
                          </span>
                        </Tooltip>
                      );
                    },
                  },
                  {
                    field: 'Os',
                    title: 'OS & OS Ver.',
                    class: 'os',
                    width: '90px',
                    render: (nodeObj) => {
                      const osName = `${obj?.deviceInfo?.machineInfo?.osName}-${obj?.deviceInfo?.machineInfo?.osVersion}`;
                      if (nodeObj.isRootNode || nodeObj.data.scriptCount > 0) return null;
                      return <span>{osName ? getOsLabel(osName) : ''}</span>;
                    },
                  },

                  {
                    field: selectedProject.type.toLowerCase() === 'web' ? 'Browser' : 'Device',
                    title: selectedProject.type.toLowerCase() === 'web' ? 'BROWSER' : 'DEVICE',
                    class: selectedProject.type.toLowerCase() === 'web' ? 'browser truncate' : 'device truncate',
                    width: selectedProject.type.toLowerCase() === 'web' ? '70px' : '80px',
                    render: (nodeObj) => {
                      const browserName = obj?.deviceInfo?.browserName;
                      const deviceArrayCopy = obj?.deviceInfo?.deviceInfo;
                      if (nodeObj.isRootNode || nodeObj.data.scriptCount > 0) return null;
                      if (selectedProject?.type?.toLowerCase() !== 'web') {
                        return <span className="">{deviceArrayCopy?.length ? deviceArrayCopy[0].name : ''}</span>;
                      } else {
                        return browserName ? (
                          <Tooltip title={browserName} placement="top">
                            {renderBrowserCell(browserName)}
                          </Tooltip>
                        ) : (
                          ''
                        );
                      }
                    },
                  },
                  {
                    field: 'numberOfRuns',
                    title: 'No. of Runs',
                    width: '80px',
                    render: (nodeObj) => {
                      if (nodeObj?.isRootNode || nodeObj?.data?.scriptCount > 0) return null;
                      return (
                        <span className="text-xs table-non-link-color-common">{obj?.deviceInfo?.numberOfRuns}</span>
                      );
                    },
                  },
                ];
              } else if (
                ['web & mobile', 'salesforce', 'ms dynamics', 'web service']?.includes(
                  selectedProject.type.toLowerCase()
                )
              ) {
                colDefsChild = [
                  {
                    field: 'title',
                    title: 'MODULE',
                    class: 'title',
                    isTitle: true,
                    width: `calc(100% - 650px)`,
                    folderTitleWidth: `calc(100% - 770px)`,
                    render: (nodeObj) => {
                      createFilterData(treeData);
                      return (
                        <>
                          {nodeObj.isRootNode && (
                            <>
                              <span class="count-badge folder-count ml-2">
                                M {obj?.rootData?.calculatedModuleCount}{' '}
                              </span>
                              <span class="count-badge file-count">
                                {' '}
                                S{' '}
                                {obj?.rootData?.calculatedScriptCount
                                  ? obj.rootData.calculatedScriptCount
                                  : obj?.treeArray[0].calculatedScriptCount}{' '}
                              </span>
                            </>
                          )}

                          {nodeObj.data.scriptCount > 0 && (
                            <span class="count-badge file-count"> S {nodeObj.data.calculatedScriptCount} </span>
                          )}
                          {nodeObj.folder && (
                            <button class="add-btn">
                              <MoreInfoButton type="add" nodeObj={nodeObj} plusIconArray={plusIconArray} />
                            </button>
                          )}
                        </>
                      );
                    },
                  },
                  {
                    field: 'scriptType',
                    title: 'TYPE',
                    class: 'Type truncate',
                    width: '74px',
                    render: (nodeObj) => {
                      return (
                        <Tooltip title={nodeObj.data.scriptType}>
                          <span className="text-xs table-non-link-color-common fontPoppinsRegularMd">
                            {nodeObj.data.scriptType}
                          </span>
                        </Tooltip>
                      );
                    },
                  },
                  {
                    field: 'Execution Environment',
                    title: 'EXECUTION ENVIRONMENT',
                    class: 'executionEnvironment truncate',
                    width: '185px',
                    render: (nodeObj) => {
                      const executionEnv = obj?.deviceInfo?.executionEnv;
                      if (nodeObj.isRootNode || nodeObj.data.scriptCount > 0) return null;
                      return (
                        <Tooltip title={executionEnv}>
                          <span className="text-xs table-non-link-color-common fontPoppinsRegularMd">
                            {executionEnv}
                          </span>
                        </Tooltip>
                      );
                    },
                  },
                  {
                    field: 'Os',
                    title: 'OS & OS Ver.',
                    class: 'os truncate',
                    width: '90px',
                    render: (nodeObj) => {
                      const osName = `${obj?.deviceInfo?.machineInfo?.osName}-${obj?.deviceInfo?.machineInfo?.osVersion}`;
                      if (nodeObj.isRootNode || nodeObj.data.scriptCount > 0) return null;
                      return <span className="version-text-color">{osName ? getOsLabel(osName) : ''}</span>;
                    },
                  },

                  {
                    field: 'Browser',
                    title: 'BROWSER',
                    class: 'browser',
                    width: '68px',
                    render: (nodeObj) => {
                      const browserName = obj?.deviceInfo?.browserName;
                      if (nodeObj.isRootNode || nodeObj.data.scriptCount > 0) return null;
                      return browserName ? (
                        <Tooltip title={browserName} placement="top">
                          {renderBrowserCell(browserName)}
                        </Tooltip>
                      ) : (
                        ''
                      );
                    },
                  },
                  {
                    field: 'Device',
                    title: 'DEVICE',
                    class: 'device truncate',
                    width: '80px',
                    render: (nodeObj) => {
                      const deviceArrayCopy = obj?.deviceInfo?.deviceInfo || [];
                      if (nodeObj.isRootNode || nodeObj.data.scriptCount > 0) return null;
                      return <span className="">{deviceArrayCopy?.length ? deviceArrayCopy[0].name : ''}</span>;
                    },
                  },
                  {
                    field: 'numberOfRuns',
                    title: 'No. of Runs',
                    width: '90px',
                    render: (nodeObj) => {
                      if (nodeObj?.isRootNode || nodeObj?.data?.scriptCount > 0) return null;
                      return (
                        <span className="text-xs table-non-link-color-common">{obj?.deviceInfo?.numberOfRuns}</span>
                      );
                    },
                  },
                ];
              }
              if (isLoading) {
                obj.treeName = <CommonLoader />;
              } else if (obj?.treeArray && obj?.treeArray.length > 0) {
                obj.treeName = (
                  <TableTree
                    data={obj?.treeArray}
                    rootData={obj?.rootData}
                    colDefs={colDefsChild}
                    filter={treeValue}
                    resetFilter={resetFilter}
                    expandAll={isExpandAll}
                    hideElements={false}
                    hideConditions={true}
                    showCheckbox={false}
                    conditions={[
                      { key: 'title', value: 'Preconditions' },
                      { key: 'title', value: 'Postconditions' },
                    ]}
                    hideConditionBtn={false}
                    onCheckedNodes={onCheckedNodes}
                    labels={LABELS}
                    onNodeSelected={onNodeSelected}
                    id={obj.tableTreeId}
                    userSearched={searchedTextQuery}
                  />
                );
              }
            });
          }
          setRunSettingsData({ ...runSettingsData });
          setIsLoading(true);
          setSuites(response);

          if (response?.emailData?.emailTheReportAfterExecution === 'true') {
            if (response?.emailData?.recipientGroup?.length) {
              getAllEmailGroupListReq().then((res) => {
                let responseEmail = res.data.responseObject ? res.data.responseObject : [];
                let emailGroupArray = [];
                if (responseEmail) {
                  if (responseEmail.length && response?.emailData?.recipientGroup?.length) {
                    response?.emailData?.recipientGroup?.forEach((grouepNameObj) => {
                      let found = responseEmail.find((syelement) => syelement?.name === grouepNameObj);
                      if (found) {
                        found.emailUserList.forEach((userData) => {
                          userData.groupName = found?.name;
                          emailGroupArray.push(userData);
                        });
                      }
                    });
                  }
                  currentEmailPosts = emailGroupArray.concat(response?.emailData?.emailRecipient);
                  setEmailData(currentEmailPosts);
                }
              });
            } else {
              if (response?.emailData?.emailRecipient?.length) {
                setEmailData(response?.emailData?.emailRecipient);
              }
            }
          }
          if (response?.slackData?.selectedSlackInstances?.length) {
            getAllSlackInstance().then((res) => {
              let responseEmail = res?.data?.responseObject ? res.data.responseObject : [];

              let slackGroupArray = [];
              if (responseEmail) {
                if (responseEmail?.length && response?.slackData?.selectedSlackInstances?.length) {
                  response?.slackData?.selectedSlackInstances?.forEach((grouepNameObj) => {
                    let found = responseEmail.find(
                      (syelement) => syelement?.instanceName === grouepNameObj?.instanceName
                    );
                    slackGroupArray.push(found?.instanceName);
                  });
                }
                currentSlackPosts = response?.slackData?.selectedSlackInstances;
                setSlackTableData(currentSlackPosts);
              }
            });
          }
          startOrResumeProgress({
            stopAt: 100,
            intervalDuration: 10,
          });
          setIsLoading(false);
          return [];
        }
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 10,
        });
      })
      .catch((error) => {
        resetProgress();
        console.error('GET_SINGLE_SUITE : ', error);
      });

    return [];
  }

  useEffect(() => {
    return () => {
      resetProgress();
    };
  }, []);

  useEffect(() => {
    getModuleTree();
  }, [reloadTree]);

  function calculateModuleCount(treeData) {
    if (treeData?.length) {
      treeData?.forEach((obj) => {
        if (obj?.folder) {
          totalModCount.current++;
          calculateModuleCount(obj?.children);
        }
      });
    }
    return totalModCount.current;
  }

  function calculateScriptCount(treeData) {
    if (treeData?.length) {
      treeData?.forEach((obj) => {
        if (obj?.folder) {
          filterScriptData(obj?.children);
          calculatedScriptNumber(obj);
        } else {
          if (obj?.type === 'Script') {
            totalScriptCount++;
          }
        }
      });
    }
    return totalScriptCount;
  }

  function filterScriptData(childData) {
    if (childData?.length) {
      childData?.forEach((obj) => {
        if (obj?.folder) {
          filterScriptData(obj?.children);
          calculatedScriptNumber(obj);
        } else {
          if (obj?.type === 'Script') {
            totalScriptCount++;
          }
        }
      });
    }
  }

  const onCheckedNodes = (nodes) => {
    console.log(nodes);
  };

  function onNodeSelected() {
    console.log(`clicked`);
  }

  let renderTree;
  let renderTreeForMaximize;
  const colDefs = [
    {
      field: 'title',
      title: 'MODULE',
      class: 'title',
      isTitle: true,
      width: `calc(100% - 550px)`,
      folderTitleWidth: `calc(100% - 670px)`,
      render: (nodeObj) => {
        createFilterData(treeData);
        return (
          <>
            {nodeObj.isRootNode && (
              <>
                <span class="count-badge folder-count ml-2">
                  M {treeData[0]?.totalModuleCount ? treeData[0]?.totalModuleCount : 0}{' '}
                </span>
                <span class="count-badge file-count">
                  {' '}
                  S {treeData[0]?.totalScriptCount ? treeData[0]?.totalScriptCount : 0}{' '}
                </span>
              </>
            )}
            {nodeObj?.data?.calculatedScriptCount > 0 && (
              <span class="count-badge file-count"> S {nodeObj.data.calculatedScriptCount} </span>
            )}
            {nodeObj.folder && (
              <button class="add-btn">
                <MoreInfoButton type="add" nodeObj={nodeObj} plusIconArray={plusIconArray} />
              </button>
            )}
          </>
        );
      },
    },
    {
      field: 'scriptType',
      title: 'TYPE',
      class: 'Type truncate',
      width: '80px',
      render: (nodeObj) => {
        return (
          <Tooltip title={nodeObj.data.scriptType}>
            <span className="text-xs table-non-link-color-common fontPoppinsRegularMd">{nodeObj.data.scriptType}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'modifiedByUname',
      title: 'MODIFIED BY',
      class: 'modified-by',
      width: '200px',
      render: (nodeObj) => {
        return (
          <span
            title={nodeObj.data.modifiedByUname === '--' ? nodeObj.data.createdByUname : nodeObj.data.modifiedByUname}
            className="tooltip"
          >
            <span className="table-non-link-color-common modified">
              {nodeObj.data.modifiedByUname === '--' ? nodeObj.data.createdByUname : nodeObj.data.modifiedByUname}
            </span>
          </span>
        );
      },
    },
  ];

  if (isLoading) {
    renderTree = <CommonLoader />;
  } else if (treeData && treeData.length > 0) {
    renderTree = (
      <TableTree
        data={treeData}
        rootData={rootData}
        colDefs={colDefs}
        filter={treeValue}
        resetFilter={resetFilter}
        expandAll={isExpandAll}
        hideElements={false}
        hideConditions={true}
        showCheckbox={false}
        conditions={[
          { key: 'title', value: 'Preconditions' },
          { key: 'title', value: 'Postconditions' },
        ]}
        hideConditionBtn={false}
        onCheckedNodes={onCheckedNodes}
        labels={LABELS}
        onNodeSelected={onNodeSelected}
        id="systemTree"
        userSearched={searchedTextQuery}
      />
    );
  }

  if (isLoading) {
    renderTreeForMaximize = <CommonLoader />;
  } else if (treeData && treeData.length > 0) {
    renderTreeForMaximize = (
      <TableTree
        data={treeData}
        rootData={rootData}
        colDefs={colDefs}
        filter={treeValue}
        resetFilter={resetFilter}
        expandAll={isExpandAll}
        hideElements={false}
        hideConditions={true}
        showCheckbox={false}
        conditions={[
          { key: 'title', value: 'Preconditions' },
          { key: 'title', value: 'Postconditions' },
        ]}
        hideConditionBtn={false}
        onCheckedNodes={onCheckedNodes}
        labels={LABELS}
        onNodeSelected={onNodeSelected}
        id="renderTreeForMaximize"
        userSearched={searchedTextQuery}
      />
    );
  }

  function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    return (
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`Search...`}
      />
    );
  }

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }
  fuzzyTextFilterFn.autoRemove = (val) => !val;

  function Table({ columns, data }) {
    const filterTypes = useMemo(
      () => ({
        fuzzyText: fuzzyTextFilterFn,
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      []
    );

    const defaultColumn = useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        filterTypes,
        initialState: { pageIndex: 0, pageSize: 7 },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination
    );
    return (
      <>
        <table class="relative  " {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr class="mt-4" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    class={`sticky top-0 px-3 py-3 text-current table-header_font-style text-sm bg-gray-100 ${
                      TEST_ENV_TABLE_WIDTH_SET.includes(column.totalWidth) ? 'w-' + column.width + 'px' : ''
                    }`}
                    {...column.getHeaderProps()}
                  >
                    <span className="flex flex-row">
                      {column.render('Header')}
                      <div>
                        <span></span>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {selectedTypeForMaximize && (selectedTypeForMaximize === 'email' || selectedTypeForMaximize === 'device') ? (
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr className="project-row" {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td class="px-3 py-3 text-center" {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr className="project-row" {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td class="px-3 py-3 text-center" {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </>
    );
  }

  const columns = useMemo(
    () =>
      selectedProject.type.toLowerCase() === 'web'
        ? [
            {
              Header: 'NAME',
              columns: [],
            },
            {
              Header: 'EXECUTION ENVIRONMENT',
              accessor: 'executionEnv',
              width: '180',
              disableFilters: true,
              Cell: ({ value, row }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-180px truncate">
                      {value}
                      {`${row?.original?.headless === true ? '(headless)' : ''}`}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'OS & OS VERSION',
              accessor: 'machineInfo.osName',
              width: '140',
              disableFilters: true,
              Cell: ({ value, row }) => {
                return (
                  <div className=" table-non-link-color-common float-left table-font-style-common w-140px truncate version-text-color">
                    {value ? getOsLabel(value) : ''}
                    {`${row?.original?.machineInfo?.osVersion}`}
                  </div>
                );
              },
            },
            {
              Header: 'BROWSER',
              accessor: 'browserName',
              width: '80',
              disableFilters: true,
              Cell: ({ value }) => {
                return <div className="w-80px version-text-color">{value ? renderBrowserCell(value) : ''}</div>;
              },
            },
            {
              Header: 'BROWSER VERSION',
              accessor: 'browserVersion',
              width: '100',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="w-100px table-non-link-color-common float-left table-font-style-common truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'NO.OF RUNS',
              accessor: 'numberOfRuns',
              disableFilters: true,
              width: '80',
              Cell: ({ value }) => {
                return (
                  <div className="table-display-common table-non-link-color-common  w-80px table-font-style-common">
                    {value}
                  </div>
                );
              },
            },
          ]
        : selectedProject.type.toLowerCase() === 'mobile'
        ? [
            {
              Header: 'NAME',
              columns: [],
            },
            {
              Header: 'EXECUTION ENVIRONMENT',
              accessor: 'executionEnv',
              width: '180',
              disableFilters: true,
              Cell: ({ value, row }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-180px truncate">
                      {value}
                      {`${row?.original?.headless === true ? '(headless)' : ''}`}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'DEVICE',
              accessor: 'deviceInfo[0].name',
              width: '100',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="w-100px table-non-link-color-common float-left table-font-style-common truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'NO. OF RUNS',
              accessor: 'numberOfRuns',
              width: '100',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <div className="table-display-common table-non-link-color-common  w-100px table-font-style-common">
                    {value}
                  </div>
                );
              },
            },
          ]
        : [
            {
              Header: 'NAME',
              columns: [],
            },
            {
              Header: 'EXECUTION ENVIRONMENT',
              accessor: 'executionEnv',
              width: '180',
              disableFilters: true,
              Cell: ({ value, row }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-180px truncate">
                      {value}
                      {`${row?.original?.headless === true ? '(headless)' : ''}`}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'OS & OS VERSION',
              accessor: 'machineInfo.osName',
              width: '140',
              disableFilters: true,
              Cell: ({ value, row }) => {
                return (
                  <div className="table-non-link-color-common float-left table-font-style-common w-140px truncate version-text-color">
                    {value ? getOsLabel(value) : ''}
                    {`${row?.original?.machineInfo?.osVersion}`}
                  </div>
                );
              },
            },
            {
              Header: 'BROWSER',
              accessor: 'browserName',
              width: '80',
              disableFilters: true,
              Cell: ({ value }) => {
                return <div className="w-80px version-text-color">{value ? renderBrowserCell(value) : ''}</div>;
              },
            },
            {
              Header: 'BROWSER VERSION',
              accessor: 'browserVersion',
              width: '140',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="w-140px table-non-link-color-common float-left table-font-style-common truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'DEVICE',
              accessor: 'deviceInfo[0].name',
              width: '80',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="w-80px table-non-link-color-common float-left table-font-style-common truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'NO. OF RUNS',
              accessor: 'numberOfRuns',
              disableFilters: true,
              width: '80',
              Cell: ({ value }) => {
                return (
                  <div className="table-display-common table-non-link-color-common w-80px table-font-style-common">
                    {value}
                  </div>
                );
              },
            },
          ],
    []
  );

  const columnsManual = useMemo(
    () =>
      selectedProject.type.toLowerCase() === 'web'
        ? [
            {
              Header: 'NAME',
              columns: [],
            },
            {
              Header: 'USER',
              accessor: 'clientId',
              width: '140',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common table-font-style-common float-left w-140px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'EXECUTION ENVIRONMENT',
              accessor: 'executionEnv',
              disableFilters: true,
              width: '200',
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-200px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'OS',
              accessor: 'machineInfo.osName',
              width: '140',
              disableFilters: true,
              Cell: ({ value }) => {
                return <span className="version-text-color">{value ? getOsLabel(value) : ''}</span>;
              },
            },
            {
              Header: 'OS VERSION',
              accessor: 'machineInfo.osVersion',
              width: '140',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-140px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'BROWSER',
              accessor: 'browserName',
              width: '140',
              disableFilters: true,
              Cell: ({ value }) => {
                return <span className="version-text-color">{value ? renderBrowserCell(value) : ''}</span>;
              },
            },
            {
              Header: 'BROWSER VERSION',
              accessor: 'browserVersion',
              width: '180',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-180px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
          ]
        : selectedProject.type.toLowerCase() === 'mobile'
        ? [
            {
              Header: 'NAME',
              columns: [],
            },
            {
              Header: 'USER',
              accessor: 'clientId',
              width: '160',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-160px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'EXECUTION ENVIRONMENT',
              accessor: 'executionEnv',
              disableFilters: true,
              width: '200',
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-200px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'OS',
              accessor: 'machineInfo.osName',
              width: '140',
              disableFilters: true,
              Cell: ({ value }) => {
                return <span className="version-text-color">{value ? getOsLabel(value) : ''}</span>;
              },
            },
            {
              Header: 'OS VERSION',
              accessor: 'machineInfo.osVersion',
              width: '140',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-140px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'DEVICE',
              accessor: 'deviceInfo[0].name',
              width: '180',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-120px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
          ]
        : [
            {
              Header: 'NAME',
              columns: [],
            },
            {
              Header: 'USER',
              accessor: 'clientId',
              width: '120',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-120px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'EXECUTION ENVIRONMENT',
              accessor: 'executionEnv',
              disableFilters: true,
              width: '200',
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-200px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'OS',
              accessor: 'machineInfo.osName',
              width: '140',
              disableFilters: true,
              Cell: ({ value }) => {
                return <span className="version-text-color">{value ? getOsLabel(value) : ''}</span>;
              },
            },
            {
              Header: 'OS VERSION',
              accessor: 'machineInfo.osVersion',
              width: '100',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-100px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'BROWSER',
              accessor: 'browserName',
              width: '140',
              disableFilters: true,
              Cell: ({ value }) => {
                return <span className="version-text-color">{value ? renderBrowserCell(value) : ''}</span>;
              },
            },
            {
              Header: 'BROWSER VERSION',
              accessor: 'browserVersion',
              width: '140',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-140px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'DEVICE',
              accessor: 'deviceInfo[0].name',
              width: '100',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-100px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
          ],
    []
  );

  const columnsEmail = useMemo(
    () => [
      {
        Header: 'Name',
        columns: [],
      },

      {
        Header: 'EMAIL ID',
        accessor: 'emailId',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <Tooltip title={value}>
              <div className="table-non-link-color-common float-left table-font-style-common">
                {getTruncatedText(value, 25)}
              </div>
            </Tooltip>
          );
        },
      },

      {
        Header: 'RECIPIENT NAME',
        accessor: 'name',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common float-left table-font-style-common">{value ? value : 'NA'}</div>
          );
        },
      },
      {
        Header: 'RECIPIENTS GROUP',
        accessor: 'groupName',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common float-left table-font-style-common">{value ? value : 'NA'}</div>
          );
        },
      },
    ],
    []
  );
  const columnsSlack = useMemo(
    () => [
      {
        Header: 'INSTANCE NAME',
        accessor: 'instanceName',
        width: '400',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <Tooltip title={value}>
              <div className="table-non-link-color-common float-left table-font-style-common">{value}</div>
            </Tooltip>
          );
        },
      },

      {
        Header: 'CHANNEL ID',
        accessor: 'channelId',
        width: '240',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <Tooltip title={value}>
              <div className="table-non-link-color-common float-left table-font-style-common">
                {getTruncatedText(value, 25)}
              </div>
            </Tooltip>
          );
        },
      },

      {
        Header: 'CHANNEL NAME',
        accessor: 'channelName',
        width: '240',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common float-left table-font-style-common">{value ? value : 'NA'}</div>
          );
        },
      },
    ],
    []
  );

  function createFilterData(treeData) {
    if (treeData) {
      let tempArray = [];
      let scriptArray = [];
      let tempNamesArray = [];
      let tempTypeArray = [];
      treeData.forEach((treeVal) => {
        if (treeVal) {
          if (treeVal.title) {
            tempArray.push(treeVal.title);
          }
          if (treeVal.createdByUname) {
            tempNamesArray.push(treeVal.createdByUname);
          }
          if (treeVal && treeVal.children) {
            scriptArray = treeVal.children;
            scriptArray.forEach((scriptVal) => {
              if (scriptVal.title) {
                tempArray.push(scriptVal.title);
              }
              if (scriptVal.createdByUname) {
                tempNamesArray.push(treeVal.createdByUname);
              }
              if (scriptVal.scriptType) {
                tempTypeArray.push(scriptVal.scriptType);
              }
            });
          }
        }
      });
    }
  }

  const [showModal, setShowModal] = useState(false);
  const [selectedTypeForMaximize, setSelectedTypeForMaximize] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  const indexOfLastPost = currentPage * postsPerPage;
  let currentPosts = [];
  let currentEmailPosts = [];
  let currentSlackPosts = [];
  if (
    runSettingsData &&
    runSettingsData?.machines &&
    runSettingsData?.machines?.selectedMachines &&
    runSettingsData?.machines?.selectedMachines.length
  ) {
    currentPosts = runSettingsData?.machines?.selectedMachines;
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  function setImmediatePrevPage() {
    let prevPageNo = currentPage - 1;
    setCurrentPage(prevPageNo);
  }

  function setImmediateNextPage() {
    let prevPageNo = currentPage + 1;
    setCurrentPage(prevPageNo);
  }

  function MaximizeFun(selectedType) {
    if (selectedType === 'module') {
      setSelectedTypeForMaximize(selectedType);
      setShowModal(true);
    } else if (selectedType === 'email') {
      setSelectedTypeForMaximize(selectedType);
      setShowModal(true);
    } else if (selectedType === 'device') {
      setSelectedTypeForMaximize(selectedType);
      setShowModal(true);
    } else if (selectedType === 'slack') {
      setSelectedTypeForMaximize(selectedType);
      setShowModal(true);
    }
  }

  return (
    <>
      <Modal open={showModal} className="modal-dialog">
        <div className="modal-container max-popup-style">
          <div className="modal-header  flex justify-between ">
            {selectedTypeForMaximize === 'module' ? (
              <label className="Popup-header-common">Modules / Scripts</label>
            ) : selectedTypeForMaximize === 'email' ? (
              <label className="Popup-header-common">Email Recipients</label>
            ) : selectedTypeForMaximize === 'device' ? (
              <label className="Popup-header-common">Test Environments</label>
            ) : selectedTypeForMaximize === 'slack' ? (
              <label className="Popup-header-common">Slack Recipients</label>
            ) : null}

            <div className="flex gap-2">
              {/* this code is commented for future requirement */}
              {/* {selectedTypeForMaximize === 'module' && (
                            <SearchBar
                                open={openSearch}
                                searchValue={userSearchInput}
                                handleSearch={handleSearch}
                                enterKeyHandler={enterKeyHandler}
                                onSearchClose={handleSearchClose}
                                handleSearchOpen={handleSearchOpen}
                            />)} */}

              <div
                className=" expandIconContainer cursor-pointer"
                onClick={() => {
                  setShowModal(false);
                  setSelectedTypeForMaximize();
                }}
              >
                <ShrinkIcon />
              </div>
            </div>
          </div>

          <div className="modal-body-1" id="journal-scroll">
            {selectedTypeForMaximize === 'module' ? (
              <div className="details-page-popup-height">{renderTreeForMaximize}</div>
            ) : selectedTypeForMaximize === 'email' ? (
              <div className="table_height overflow-auto" id="journal-scroll">
                <Table columns={columnsEmail} data={emailTableData} />
              </div>
            ) : selectedTypeForMaximize === 'device' ? (
              <div className="table_height overflow-auto" id="journal-scroll">
                <Table columns={columns} data={suites?.machines?.selectedMachines}></Table>
              </div>
            ) : selectedTypeForMaximize === 'slack' ? (
              <div className="table_height overflow-auto" id="journal-scroll">
                <Table columns={columnsSlack} data={slackTableData} />
              </div>
            ) : null}
          </div>
        </div>
      </Modal>

      <div className="content_area_style overflow-auto" id="journal-scroll">
        <div className="details-main-header">
          <label className="page-header-common float-left">Suite Details</label>
        </div>
        <div className="details-body">
          <div className="grid grid-cols-3 gap-6">
            <div className="text-overflow-style mr-4">
              <label className="details-label-style">Name</label>
              <Tooltip title={`${suites?.name}`}>
                <label className="details-value-style block">{getTruncatedText(suites?.name, 25)}</label>
              </Tooltip>
            </div>
            <div>
              <label className="details-label-style">Description</label>
              <Tooltip title={suites?.description ? suites?.description : '--'}>
                <label className="details-value-style block truncate">
                  {suites?.description ? suites?.description : '--'}
                </label>
              </Tooltip>
            </div>
            <div>
              <label className="details-label-style">Suite Type</label>
              <label className="details-value-style block">{suites?.suiteType}</label>
            </div>
          </div>

          <div className="details-content-area-style">
            <div className="details_content_area_header_style">
              <label className="details-header-style float-left ">Modules / Scripts</label>
              <div className="flex flex-row float-right gap-2">
                {/* this code is commented for future requirement */}

                {/* <SearchBar
                                open={openSearch}
                                searchValue={userSearchInput}
                                handleSearch={handleSearch}
                                enterKeyHandler={enterKeyHandler}
                                onSearchClose={handleSearchClose}
                                handleSearchOpen={handleSearchOpen}
                            /> */}
                <div className="expandIconContainer" title="Maximize" onClick={() => MaximizeFun('module')}>
                  <ExpandArrow className="expIcon" />
                </div>
              </div>
            </div>

            <div className="h-80">{renderTree}</div>
          </div>

          <div className="single-dotted-border"></div>

          {suites?.suiteType === 'Automation' ? (
            <div className="variable-details-area-style mt-5">
              <label className="section-header-style">Variables</label>
              <div className="grid grid-cols-2 mt-5">
                <div>
                  <label className="details-label-style">Project Environment Variables</label>
                  <label className="details-value-style block">
                    {suites.usePeVariableFrom === 'SET'
                      ? `Use variables from Project Variable Set (${suites.selectedPESetName})`
                      : 'Use variables from Test Development'}
                  </label>
                </div>
                <div>
                  <label className="details-label-style">Global Variables</label>
                  <label className="details-value-style block">
                    {suites.useGlobalVariableFrom === 'SET'
                      ? `Use variables from Global Variable Set (${suites.selectedGVSetName})`
                      : 'Use variables from Test Development'}
                  </label>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {suites?.suiteType === 'Automation' ? <div className="single-dotted-border"> </div> : <></>}

          {suites?.suiteType === 'Automation' ? (
            <div className="variable-details-area-style mt-5">
              <label className="section-header-style">Test Data</label>
              <div className="grid grid-cols-2 mt-5">
                <div>
                  <label className="details-label-style">Test Data</label>
                  <label className="details-value-style block">
                    {suites.useTestDataFrom === 'SET'
                      ? `Use Test Data from Test Data set (${suites.selectedTDSetName})`
                      : 'Use test data from Test Development'}
                  </label>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="variable-details-area-style">
            {runSettingsData?.machines ? (
              <>
                {suites?.suiteType === 'Automation' ? <div className="single-dotted-border"> </div> : <></>}
                <div className="mt-5">
                  <label className="section-header-style">
                    {suites?.suiteType !== 'Manual' ? 'Run Settings' : 'User Settings'}
                  </label>
                </div>
                <div className="variable-details-area-style ">
                  <div className="mt-5">
                    <label className="section-header-style ">{suites?.suiteType !== 'Manual' ? 'Machine' : null}</label>
                  </div>
                  {suites?.suiteType !== 'Manual' ? (
                    <div className="grid grid-cols-2 mt-5">
                      <div>
                        <label className="details-label-style">Run In</label>
                        <label className="details-value-style block">
                          {runSettingsData?.machines?.multiple === 'false' ? 'Single Machine' : 'Multiple Machine'}
                        </label>
                      </div>
                      <div>
                        <label className="details-label-style">Execution Type</label>
                        <label className="details-value-style block">
                          {runSettingsData?.machines?.multiple === 'false'
                            ? 'Sequential'
                            : runSettingsData?.machines?.executionType === 'SEND_SUITE_TO_ALL'
                            ? 'Parallel'
                            : 'Distribute'}
                        </label>
                      </div>
                    </div>
                  ) : (
                    <div className="grid grid-cols-2 mt-5">
                      <div>
                        <label className="details-label-style">Execution Type</label>
                        <label className="details-value-style block">
                          {runSettingsData?.machines?.multiple === 'false' ? 'Single User' : 'Multiple User'}
                        </label>
                      </div>
                    </div>
                  )}

                  <div
                    className={
                      runSettingsData?.machines?.multiple === 'false'
                        ? 'single-machine-details-content-area-style'
                        : 'details-content-area-style'
                    }
                  >
                    {runSettingsData?.machines?.multiple === 'false' ? (
                      <MachineEnvironmentLayout disableHeaderBtn={true}>
                        <MachineEnvironmentTableLayout
                          headerLabel={
                            runSettingsData?.machines?.selectedMachines?.[0]?.machineInstances?.[0]?.machineInfo
                              ?.hostName
                          }
                          accessDetails={
                            runSettingsData?.machines?.selectedMachines?.[0]?.machineInstances?.[0]?.access
                          }
                          columns={suites?.suiteType === 'Manual' ? columnsManual : columns}
                          data={runSettingsData?.machines?.selectedMachines?.[0]?.machineInstances}
                          placement="bottom"
                        />
                      </MachineEnvironmentLayout>
                    ) : runSettingsData?.machines?.multiple === 'true' &&
                      runSettingsData?.machines?.executionType === EXECUTION_CONSTANTS.SEND_SUITE_TO_ALL ? (
                      <div className="table_height overflow-auto" id="journal-scroll">
                        <MachineEnvironmentLayout disableHeaderBtn={true}>
                          {runSettingsData?.machines?.selectedMachines.map((currentMachine) => (
                            <MachineEnvironmentTableLayout
                              headerLabel={currentMachine?.machineInstances[0].machineInfo.hostName}
                              accessDetails={currentMachine?.machineInstances[0]?.access}
                              columns={suites?.suiteType === 'Manual' ? columnsManual : columns}
                              data={currentMachine?.machineInstances}
                              placement="bottom"
                            />
                          ))}
                        </MachineEnvironmentLayout>
                      </div>
                    ) : (
                      <>
                        {treeArrayForMultipleTree.map((obj) => {
                          return (
                            <div className="details-content-area-style">
                              <div className="details_content_area_header_style">
                                <label className="details-header-style float-left ">
                                  {obj?.deviceInfo?.machineInfo?.hostName}
                                </label>
                                <div className="flex flex-row float-right">
                                  <div
                                    className="expandIconContainer"
                                    title="Maximize"
                                    onClick={() => MaximizeFun('Machine1')}
                                  >
                                    <ExpandArrow className="expIcon" />
                                  </div>
                                </div>
                              </div>
                              <div className="h-80">{obj?.treeName}</div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : null}
            <>
              {suites?.suiteType === 'Automation' ? (
                <div className="mt-5">
                  <label className="section-header-style ">Wait Time</label>
                  <div className="grid grid-cols-3 mt-5">
                    <div>
                      <label className="details-label-style">Implicit Wait</label>
                      <label className="details-value-style block">
                        {runSettingsData?.waitTime?.implicitWait} {runSettingsData?.waitTime?.implicitWaitUnit}
                      </label>
                      <label className="details-value-style block">
                        {' '}
                        {runSettingsData?.waitTime?.implicitWaitUnit === 'true' ? 'Send Mail' : null}
                      </label>
                    </div>
                    <div>
                      <label className="details-label-style">Explicitly Wait</label>
                      <label className="details-value-style block">
                        {runSettingsData?.waitTime?.explicitlyWait} {runSettingsData?.waitTime?.explicitlyWaitUnit}
                      </label>
                      <label className="details-value-style block">
                        {' '}
                        {runSettingsData?.waitTime?.explicitlyWaitUnit === 'true' ? 'Send Mail' : null}
                      </label>
                    </div>
                    <div>
                      <label className="details-label-style">Delay Between Steps</label>
                      <label className="details-value-style block">
                        {runSettingsData?.waitTime?.delayBetweenSteps}{' '}
                        {runSettingsData?.waitTime?.delayBetweenStepsUnit}
                      </label>
                      <label className="details-value-style block">
                        {' '}
                        {runSettingsData?.waitTime?.delayBetweenStepsUnit === 'true' ? 'Send Mail' : null}
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {suites?.suiteType === 'Automation' ? (
                <div className="mt-5">
                  <label className="section-header-style ">Result Settings</label>
                  <div className="grid grid-cols-3 mt-5">
                    <div>
                      <label className="details-label-style">Capture Screenshots</label>
                      <label className="details-value-style block">
                        {runSettingsData?.resultSetting?.captureScreenshots}
                      </label>
                    </div>
                    <div>
                      <label className="details-label-style">Capture Execution Video</label>
                      <label className="details-value-style block">
                        {runSettingsData?.resultSetting?.captureVideoForFailedTestScript}
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {suites?.suiteType === 'Automation' ? (
                <div className="mt-5">
                  <label className="section-header-style ">Execution Termination</label>
                  <div className="grid grid-cols-2 mt-5">
                    <div>
                      <label className="details-label-style">
                        Terminate Script if any script execution takes more than
                      </label>
                      <label className="details-value-style block">
                        {runSettingsData?.executionTermination?.terminateScriptIfTakesMoreTime}{' '}
                        {runSettingsData?.executionTermination?.terminateScriptUnit}
                      </label>
                      <label className="details-value-style block">
                        {' '}
                        {runSettingsData?.executionTermination?.terminateSuiteSendEmail === 'true' ? 'Send Mail' : null}
                      </label>
                    </div>
                    <div>
                      <label className="details-label-style">Terminate Suite if suite execution takes more than</label>
                      <label className="details-value-style block">
                        {runSettingsData?.executionTermination?.terminateSuiteIfTakesMoreTime}{' '}
                        {runSettingsData?.executionTermination?.terminateSuitetUnit}
                      </label>
                      <label className="details-value-style block">
                        {runSettingsData?.executionTermination?.terminateScriptSendEmail === 'true'
                          ? 'Send Mail'
                          : null}
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
          </div>
          {(suites?.emailData?.emailTheReportAfterExecution === 'true' ||
            !isEmptyValue(suites?.emailData?.recipientGroup)) && (
            <div className="variable-details-area-style mt-5">
              <label className="section-header-style">Email</label>
              <div className="mt-5">
                <label className="section-header-style ">Email Recipients</label>
              </div>
              <div className="grid grid-cols-2 mt-5">
                <div>
                  <label className="details-label-style">Recipient Group</label>
                  <br></br>
                  {suites &&
                  suites?.emailData &&
                  suites?.emailData?.recipientGroup &&
                  suites?.emailData?.recipientGroup.length
                    ? suites?.emailData?.recipientGroup.map((groupName, index) => (
                        <label className="details-value-style">
                          {groupName} {index > suites?.emailData?.recipientGroup.length ? ',' : null}
                        </label>
                      ))
                    : null}
                </div>
              </div>

              <div className="details-content-area-style">
                <div className="details_content_area_header_style">
                  <label className="details-header-style float-left ">Email Recipients</label>
                  <div className="flex flex-row float-right">
                    <div className="expandIconContainer" title="Maximize" onClick={() => MaximizeFun('email')}>
                      <ExpandArrow className="expIcon" />
                    </div>
                  </div>
                </div>
                <div className="table_height overflow-auto" id="journal-scroll">
                  <Table columns={columnsEmail} data={emailTableData} />
                </div>
              </div>

              <div className="mt-5">
                <label className="section-header-style ">Email Reports</label>
              </div>
              <div className="grid grid-cols-2 mt-5">
                <div>
                  <label className="details-label-style">Email Reports</label>
                  <label className="details-value-style block">
                    {suites?.emailData?.emailOnSuiteExecutionStart === 'true' ? 'On Suite Execution Start,' : null}
                    {suites?.emailData?.emailOnSuiteExecutionCompletion === 'true'
                      ? 'On Suite Execution Completion / Termination'
                      : null}
                    {suites?.emailData?.emailManuallyTriggerEmailNotification === 'true'
                      ? 'Manually trigger email notification'
                      : null}
                  </label>
                </div>
                <div>
                  <label className="details-label-style">Attach Execution Report</label>
                  <label className="details-value-style block">{getReportTypes(suites?.emailData)}</label>
                </div>
              </div>
            </div>
          )}
          {suites?.slackData?.selectedSlackInstances?.length > 0 ? (
            <div className="variable-details-area-style mt-5">
              <label className="section-header-style">Slack</label>
              <div className="mt-5">
                <label className="section-header-style ">Slack Recipients</label>
              </div>
              <div className="grid grid-cols-2 mt-5">
                <div>
                  <label className="details-label-style">Slack Instances</label>
                  <br></br>
                  {suites?.slackData?.selectedSlackInstances
                    ? suites?.slackData?.selectedSlackInstances.map((groupName, index) => (
                        <label className="details-value-style" key={index}>
                          {groupName.instanceName}
                          {index < suites?.slackData?.selectedSlackInstances.length - 1 ? ',' : null}
                        </label>
                      ))
                    : null}
                </div>
              </div>

              <div className="details-content-area-style">
                <div className="details_content_area_header_style">
                  <label className="details-header-style float-left ">Slack Recipients</label>
                  <div className="flex flex-row float-right">
                    <div className="expandIconContainer" title="Maximize" onClick={() => MaximizeFun('slack')}>
                      <ExpandArrow className="expIcon" />
                    </div>
                  </div>
                </div>
                <div className="table_height overflow-auto" id="journal-scroll">
                  <Table columns={columnsSlack} data={slackTableData} />
                </div>
              </div>

              <div className="mt-5">
                <label className="section-header-style ">Slack Reports</label>
              </div>
              <div className="grid grid-cols-2 mt-5">
                <div>
                  <label className="details-label-style">Slack Reports</label>
                  <label className="details-value-style block">
                    {suites?.slackData?.on_Suite_Execution_Starts ? SLACK_CONSTANTS.ON_SUITE_EXECUTION_START : null}
                    {suites?.slackData?.on_Suite_Execution_Starts && suites?.slackData?.on_Suite_Execution_Ends
                      ? ','
                      : null}
                    {suites?.slackData?.on_Suite_Execution_Ends
                      ? SLACK_CONSTANTS.ON_SUITE_EXECUTION_COMPLETION_OR_TERMINATION
                      : null}
                    {suites?.slackData?.slackManuallyTriggerNotification
                      ? SLACK_CONSTANTS.MANUALLY_TRIGGER_EMAIL_NOTIFICATION
                      : null}
                  </label>
                </div>
                <div>
                  <label className="details-label-style">Attach Execution Report</label>
                  <label className="details-value-style block">{getReportTypes(suites?.slackData)}</label>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default SuiteDetails;

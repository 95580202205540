import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useAlert } from '../../common/alert_service/useAlert';
import './Result.css';
import { getResultConfigReq, putResultConfigReq } from '@src/api/api_services';
import { getLicenseFeatures, isEmptyValue } from '@src/util/common_utils';
import { DISPLAY_MESSAGES } from '@src/common/ui-constants';
import { UI_VALIDATIONS } from '@src/util/validations';

const Result_config = (props) => {
  const [noOfScriptsResults, setNoOfScriptsResults] = useState('');
  const [noOfModulesResults, setNoOfModulesResults] = useState('');
  const [noOfSuitesResults, setNoOfSuitesResults] = useState('');
  const [noOfSuitesOfSuiteResults, setNoOfSuitesOfSuiteResults] = useState('');
  const [userId, setUserId] = useState('');
  const [disablebtn, setDisableBtn] = useState(true);
  const [disableCancelbtn, setDisableCancelBtn] = useState(true);
  const [values, setValues] = useState('');
  const [oldScript, setOldScript] = useState('');
  const [oldModule, setOldModule] = useState('');
  const [oldSuite, setOldSuite] = useState('');
  const [oldSuiteOfSuite, setOldSuiteOfSuite] = useState('');
  const [testDev, setTestDev] = useState();
  const [oldTestDev, setoldTestDev] = useState('');
  const [testDevErrorMsg, setTestDevErrorMsg] = useState('');
  const [suitErrorMsg, setSuitErrorMsg] = useState('');
  const [suitOfSuitErrorMsg, setSuitOfSuitErrorMsg] = useState('');
  const hasAutomation = global.privilege.hasAutomation(getLicenseFeatures());
  const hasManual = global.privilege.hasManualTestCase(getLicenseFeatures());

  const updateVal = (e) => {
    setDisableCancelBtn(true);
    setDisableBtn(true);
    localStorage.setItem('resultPutReq', true);
    let data = {
      noOfSuitesResults: noOfSuitesResults,
      noOfSuitesOfSuiteResults: noOfSuitesOfSuiteResults,
      noOfTestDevResults: testDev,
      id: userId,
    };

    let projectId = JSON.parse(localStorage.getItem('selected-project')).id;
    if (projectId) {
      if (noOfSuitesResults == oldSuite && noOfSuitesOfSuiteResults == oldSuiteOfSuite && testDev == oldTestDev) {
        MyAlert.info(`No Changes Were Done . `);
        localStorage.setItem('resultPutReq', false);
        return;
      }
      putResultConfigReq(userId, data, projectId)
        .then((results) => {
          setOldScript(noOfScriptsResults);
          setOldModule(noOfModulesResults);
          setOldSuite(noOfSuitesResults);
          setOldSuiteOfSuite(noOfSuitesOfSuiteResults);
          setoldTestDev(testDev);
          localStorage.setItem('resultPutReq', false);
          if (results.data.responseCode === 200) {
            MyAlert.success(`Result configuration updated successfully`);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      localStorage.setItem('resultPutReq', true);
      if (noOfSuitesResults == oldSuite && noOfSuitesOfSuiteResults == oldSuiteOfSuite && testDev == oldTestDev) {
        MyAlert.info(`No Changes Were Done . `);
        localStorage.setItem('resultPutReq', false);
        return;
      }
      putResultConfigReq(userId, data)
        .then((results) => {
          localStorage.setItem('resultPutReq', false);
          setOldScript(noOfScriptsResults);
          setOldModule(noOfModulesResults);
          setOldSuite(noOfSuitesResults);
          setOldSuiteOfSuite(noOfSuitesOfSuiteResults);
          setoldTestDev(testDev);

          if (results.data.responseCode === 200) {
            MyAlert.success(`Result configuration updated successfully`);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // individual project
  let projectId = JSON.parse(localStorage.getItem('selected-project')).id;
  React.useEffect(() => {
    const search = window.location.search;
    let projectId = new URLSearchParams(search).get('id') || JSON.parse(localStorage.getItem('selected-project'));

    if (new URLSearchParams(search).get('id')) {
      projectId = new URLSearchParams(search).get('id');
    } else {
      projectId = JSON.parse(localStorage.getItem('selected-project')).id;
    }

    if (projectId) {
      getResultConfigReq(projectId).then((results) => {
        localStorage.setItem('resultGetReq', false);
        if (results && results.data && results.data.responseObject) {
          setNoOfSuitesResults(results.data.responseObject.noOfSuitesResults);
          setNoOfSuitesOfSuiteResults(results.data.responseObject.noOfSuitesOfSuiteResults);
          setUserId(results.data.responseObject.id);
          setTestDev(results.data.responseObject.noOfTestDevResults);
          setOldSuite(results.data.responseObject.noOfSuitesResults);
          setOldSuiteOfSuite(results.data.responseObject.noOfSuitesOfSuiteResults);
          setoldTestDev(results.data.responseObject.noOfTestDevResults);
        }
      });
    } else {
      localStorage.setItem('resultGetReq', true);
      getResultConfigReq(projectId).then((results) => {
        localStorage.setItem('resultGetReq', false);
        if (results && results.data && results.data.responseObject) {
          setNoOfSuitesResults(results.data.responseObject.noOfSuitesResults);
          setNoOfSuitesOfSuiteResults(results.data.responseObject.noOfSuitesOfSuiteResults);
          setUserId(results.data.responseObject.id);
          setValues(results.data.responseObject);
          setOldScript(results.data.responseObject.noOfScriptsResults);
          setOldModule(results.data.responseObject.noOfModulesResults);
          setOldSuite(results.data.responseObject.noOfSuitesResults);
          setOldSuiteOfSuite(results.data.responseObject.noOfSuitesOfSuiteResults);
          setTestDev(results.data.responseObject.noOfTestDevResults);
          setoldTestDev(results.data.responseObject.noOfTestDevResults);
        }
      });
    }
  }, [projectId]);

  const checkValidation = (value, type) => {
    if (isEmptyValue(value)) {
      setDisableBtn(true);
      return;
    }
    const updateDisableBtnState = (currentFieldValue, nextFieldValue) =>
      !(
        Number(value || currentFieldValue) > 4 &&
        Number(value || currentFieldValue) <= 999999 &&
        Number(nextFieldValue) > 4 &&
        Number(nextFieldValue) <= 999999
      );
    if (type === 'suite') {
      setDisableBtn(updateDisableBtnState(noOfSuitesResults, testDev));
    } else {
      setDisableBtn(updateDisableBtnState(testDev, noOfSuitesResults));
    }
  };

  const suitHandelChange = (e) => {
    setNoOfSuitesResults(e.target.value);
    let suit = document.querySelector('.suit').value;
    const value = e.target.value.replace(/\D/g, '');
    setNoOfSuitesResults(value);
    checkValidation(value, 'suite');
    if (suit <= 4) {
      setSuitErrorMsg(DISPLAY_MESSAGES.ERROR.MIN_VALUE_SHOULD_5);
    } else if (suit > UI_VALIDATIONS.DOC_COUNT_MAX_SUITE) {
      setSuitErrorMsg(`Value greater than ${UI_VALIDATIONS.DOC_COUNT_MAX_SUITE} not allowed`);
      setNoOfSuitesResults(UI_VALIDATIONS.DOC_COUNT_MAX_SUITE);
    } else {
      setSuitErrorMsg('');
    }
  };

  const testDevOnchange = (e) => {
    setTestDev(e.target.value);
    let testDevelopment = document.querySelector('.testDevelopment').value;
    const value = e.target.value.replace(/\D/g, '');
    checkValidation(value, 'testDev');
    setTestDev(value);
    if (testDevelopment <= 4) {
      setTestDevErrorMsg(DISPLAY_MESSAGES.ERROR.MIN_VALUE_SHOULD_5);
    } else if (testDevelopment > UI_VALIDATIONS.DOC_COUNT_MAX_TEST_DEV) {
      setTestDevErrorMsg(`Value greater than ${UI_VALIDATIONS.DOC_COUNT_MAX_TEST_DEV} not allowed`);
      setTestDev(UI_VALIDATIONS.DOC_COUNT_MAX_TEST_DEV);
    } else {
      setTestDevErrorMsg('');
    }
  };

  const suitOfSuitHandelChange = (e) => {
    setNoOfSuitesOfSuiteResults(e.target.value);
    let suitOfsuit = document.querySelector('.suitOfsuit').value;
    var regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;
    let regExp_alpha = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/;
    var regExp_alphaVal = /\D/g;

    const value = e.target.value.replace(/\D/g, '');
    setNoOfSuitesOfSuiteResults(value);

    if (suitOfsuit <= 4) {
      setSuitOfSuitErrorMsg(DISPLAY_MESSAGES.ERROR.MIN_VALUE_SHOULD_5);
      setDisableBtn(true);
      setDisableCancelBtn(true);
    } else if (regex_symbols.test(suitOfsuit)) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
    } else if (regExp_alpha.test(suitOfsuit)) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
    } else if (regExp_alphaVal.test(suitOfsuit)) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
    } else if (suitOfsuit > 999999) {
      setSuitOfSuitErrorMsg('Value greater than 999999 not allowed');
      setDisableBtn(true);
      setDisableCancelBtn(true);
    } else {
      setSuitOfSuitErrorMsg('');
      setDisableBtn(false);
      setDisableCancelBtn(false);
    }
  };

  // for suite incr
  const incSuitNo = () => {
    setNoOfSuitesResults(parseInt(noOfSuitesResults) + 1);
    let suit = document.querySelector('.suit').value;
    if (suit === '') {
      setNoOfSuitesResults(1);
      setSuitErrorMsg('');
    }
    if (suit <= 3) {
      setSuitErrorMsg(DISPLAY_MESSAGES.ERROR.MIN_VALUE_SHOULD_5);
    } else if (suit >= UI_VALIDATIONS.DOC_COUNT_MAX_SUITE) {
      setNoOfSuitesResults(UI_VALIDATIONS.DOC_COUNT_MAX_SUITE);
      setSuitErrorMsg(`Value greater than ${UI_VALIDATIONS.DOC_COUNT_MAX_SUITE} not allowed`);
      checkValidation(UI_VALIDATIONS.DOC_COUNT_MAX_SUITE, 'suite');
    } else {
      setSuitErrorMsg('');
      checkValidation(Number(suit) + 1, 'suite');
    }
  };
  // for suit decrease
  const decrSuiteNo = () => {
    setNoOfSuitesResults(noOfSuitesResults - 1);

    let suit = document.querySelector('.suit').value;
    if (suit <= 5) {
      setNoOfSuitesResults(5);
      checkValidation(5, 'suite');
      setSuitErrorMsg(DISPLAY_MESSAGES.ERROR.MIN_VALUE_SHOULD_5);
    } else if (suit > UI_VALIDATIONS.DOC_COUNT_MAX_SUITE) {
      setNoOfSuitesResults(UI_VALIDATIONS.DOC_COUNT_MAX_SUITE);
      checkValidation(UI_VALIDATIONS.DOC_COUNT_MAX_SUITE, 'suite');
    } else {
      setSuitErrorMsg('');
      checkValidation(Number(suit) - 1, 'suite');
    }
  };

  // decrease testDev
  const decrTestDev = () => {
    setTestDev(testDev - 1);
    let testDevelopment = document.querySelector('.testDevelopment').value;

    if (testDevelopment <= 5) {
      setTestDev(5);
      setTestDevErrorMsg(DISPLAY_MESSAGES.ERROR.MIN_VALUE_SHOULD_5);
      checkValidation(5, 'testDev');
    } else if (testDevelopment > UI_VALIDATIONS.DOC_COUNT_MAX_TEST_DEV) {
      setTestDev(UI_VALIDATIONS.DOC_COUNT_MAX_TEST_DEV);
      checkValidation(UI_VALIDATIONS.DOC_COUNT_MAX_TEST_DEV, 'testDev');
    } else {
      setTestDevErrorMsg('');
      checkValidation(Number(testDevelopment) - 1, 'testDev');
    }
  };

  // increase testDev

  const incrTestDev = () => {
    setTestDev(parseInt(testDev) + 1);
    let testDevelopment = document.querySelector('.testDevelopment').value;
    if (testDevelopment === '') {
      setTestDev(1);
      setTestDevErrorMsg('');
    }
    if (testDevelopment <= 4) {
      setTestDevErrorMsg(DISPLAY_MESSAGES.ERROR.MIN_VALUE_SHOULD_5);
    } else if (testDevelopment >= UI_VALIDATIONS.DOC_COUNT_MAX_TEST_DEV) {
      setTestDev(UI_VALIDATIONS.DOC_COUNT_MAX_TEST_DEV);
      setTestDevErrorMsg(`Value greater than ${UI_VALIDATIONS.DOC_COUNT_MAX_TEST_DEV} not allowed`);
      checkValidation(UI_VALIDATIONS.DOC_COUNT_MAX_TEST_DEV, 'testDev');
    } else {
      setTestDevErrorMsg('');
      checkValidation(Number(testDevelopment) + 1, 'testDev');
    }
  };
  // for suite of suit incr
  const incSuitOfsuitNo = () => {
    setNoOfSuitesOfSuiteResults(parseInt(noOfSuitesOfSuiteResults) + 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let suitOfsuit = document.querySelector('.suitOfsuit').value;
    if (suitOfsuit === '') {
      setNoOfSuitesOfSuiteResults(1);
      setDisableBtn(false);
      setDisableCancelBtn(false);
      setSuitOfSuitErrorMsg('');
    }
    if (suitOfsuit <= 3) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setSuitOfSuitErrorMsg(DISPLAY_MESSAGES.ERROR.MIN_VALUE_SHOULD_5);
    } else if (suitOfsuit >= 999999) {
      setNoOfSuitesOfSuiteResults(999999);
      setSuitOfSuitErrorMsg('Value greater than 999999 not allowed');
      setDisableBtn(true);
      setDisableCancelBtn(true);
    } else {
      setSuitOfSuitErrorMsg('');
    }
  };
  // for suitOfSuit decrease
  const decrSuitOfSuiteNo = () => {
    setNoOfSuitesOfSuiteResults(noOfSuitesOfSuiteResults - 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let suitOfsuit = document.querySelector('.suitOfsuit').value;
    if (suitOfsuit <= 5) {
      setNoOfSuitesOfSuiteResults(5);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setSuitOfSuitErrorMsg(DISPLAY_MESSAGES.ERROR.MIN_VALUE_SHOULD_5);
    } else if (suitOfsuit > 999999) {
      setNoOfSuitesOfSuiteResults(999999);
      setDisableBtn(true);
      setDisableCancelBtn(true);
    } else {
      setSuitOfSuitErrorMsg('');
    }
  };

  // cancel function
  const cancelValue = () => {
    setDisableCancelBtn(true);
    setDisableBtn(true);
    let projectId = JSON.parse(localStorage.getItem('selected-project')).id;
    if (projectId) {
      setNoOfSuitesResults(oldSuite);
      setNoOfSuitesOfSuiteResults(oldSuiteOfSuite);
      setTestDev(oldTestDev);
      setSuitOfSuitErrorMsg('');
      setTestDevErrorMsg('');
      setSuitErrorMsg('');
    } else {
      localStorage.setItem('resultGetReq', true);
      setNoOfSuitesResults(oldSuite);
      setNoOfSuitesOfSuiteResults(oldSuiteOfSuite);
      setTestDev(oldTestDev);
      setSuitOfSuitErrorMsg('');
      setTestDevErrorMsg('');
      setSuitErrorMsg('');
    }
  };

  const { AlertContatiner, MyAlert } = useAlert();
  let activeTab = props.location.pathname.split('/')[2];

  const [tabsList, setTabsList] = useState([
    {
      name: 'Result Configuration',
      pathUrl: `/configuration/ResultConfiguration`,
      selectedName: 'ResultConfiguration',
    },

    // {
    //   name: "History",
    //   pathUrl: `/configuration/Result&HistoryConfiguration/History`,
    //   selectedName: "History",
    // },
  ]);

  const renderTabs = () => {
    return (
      <div>
        <ul className="navBar text-blue-600 flex flex-row primary-tab">
          {tabsList.map((tab, index) => {
            return (
              <li key={tab.name}>
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    props.history.push(tab.pathUrl);
                  }}
                  className={`${activeTab === tab.selectedName ? 'selected_tab_style' : ''}`}
                >
                  {tab.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const isEditable = React.useMemo(() => window.permission?.isEditAccess('configuration'), []);
  const handleNumberValueChange = React.useCallback(
    (changeHandlerFunction) => (isEditable ? changeHandlerFunction : () => {}),
    [isEditable]
  );

  return (
    <div className="page-container">
      <div className="page-list-body">
        {renderTabs()}
        <div className="alert-position-style">
          <AlertContatiner></AlertContatiner>
        </div>
        <div className="page-table-body">
          <div className="configPage pr-0 content_area_style" id="journal-scroll">
            <div className="grid grid-cols-2 mb-4 ">
              <span className=" ml-3 mt-4 fontPoppinsSemiboldXlg">Result Configuration</span>
              {isEditable && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginRight: '15px',
                  }}
                >
                  <button
                    onClick={cancelValue}
                    className={disableCancelbtn ? 'gray-btn mt-4' : 'gray-btn mt-4'}
                    disabled={disableCancelbtn}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={updateVal}
                    disabled={disablebtn}
                    className={disablebtn ? 'primary-btn mt-4 ml-7' : 'primary-btn mt-4 ml-7'}
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
            <div className="tableStyle grid grid-cols-4  ">
              <div className="ml-7 mt-2">
                <span className="fontPoppinsSemiboldMd uppercase">Result of</span>
                <span className="fontPoppinsSemiboldMd ml-52 uppercase">No of Results</span>
              </div>
            </div>

            {(hasAutomation || !hasManual) && (
              <div className=" grid grid-cols-4" style={{ display: 'flex' }}>
                <label className="headingData fontPoppinsRegularMd">
                  <span className="text-red-400 mr-1">*</span> Test Dev
                </label>
                <div style={{ marginLeft: '14.3rem' }}>
                  <form
                    className="flex"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div class="relative inline-block ml-[-21]">
                      <input
                        disabled={!isEditable}
                        value={testDev}
                        onChange={testDevOnchange}
                        type="text"
                        className="testDevelopment font-poppins-regular-md bg-gray-100 w-20 h-8 text-right absolute bottom-0 left--18 border-b-2 border-gray-300 pb-0"
                        style={{ borderBottom: '2px solid #ccc' }}
                      ></input>
                      <span class="relative left-20 ml-1 ">
                        <ArrowDropUpIcon onClick={handleNumberValueChange(incrTestDev)} className="arrowUp" />
                        <ArrowDropDownIcon onClick={handleNumberValueChange(decrTestDev)} className="arrowDown" />
                      </span>

                      {testDevErrorMsg && <p className="errorMessage absolute w-64">{testDevErrorMsg}</p>}
                    </div>
                  </form>
                </div>
              </div>
            )}

            {/*-------3rd---------------- */}

            <div className=" grid grid-cols-4 my-4" style={{ display: 'flex' }}>
              <label className="headingData fontPoppinsRegularMd">
                <span className="text-red-400 mr-1">*</span> Suite
              </label>
              <div style={{ marginLeft: '14.3rem' }}>
                <form
                  className="flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div class="relative inline-block ">
                    <input
                      disabled={!isEditable}
                      value={noOfSuitesResults}
                      onChange={suitHandelChange}
                      type="text"
                      className="suit font-poppins-regular-md ml-1.5 bg-gray-100 w-20 h-8 text-right  absolute bottom-0 left-5 pb-0"
                      style={{ borderBottom: '2px solid #ccc' }}
                    ></input>
                    <span class="relative left-20 ml-7">
                      <ArrowDropUpIcon onClick={handleNumberValueChange(incSuitNo)} className="arrowUp" />
                      <ArrowDropDownIcon onClick={handleNumberValueChange(decrSuiteNo)} className="arrowDown" />
                    </span>
                    {suitErrorMsg && <p className="errorMessage absolute w-64 ml-6">{suitErrorMsg}</p>}
                  </div>
                </form>
              </div>
            </div>

            {/*-------4th---------------- */}
            {/* COMMENTED BECAUSE, THIS SUITE OF SUITE SECTION IS NOT NEED FOR THIS RELEASE */}
            {/* <div className=" grid grid-cols-4 mt-2" style={{ display: "flex" }}>
              <label
                className="headingData fontPoppinsRegularMd"
              >
                <span className="text-red-400 mr-1">*</span> Suite of Suite
              </label>
              <div style={{ marginLeft: "11.2rem" }}>
                <form className='flex' onSubmit={(e) => { e.preventDefault() }}>
                  <div>
                    <input
                      disabled={!isEditable}
                      value={noOfSuitesOfSuiteResults}
                      onChange={suitOfSuitHandelChange}

                      type="text"
                      className="suitOfsuit fontPoppinsRegularMd"
                      style={{
                        background: "rgb(251,252,253)",
                        width: "5rem",

                        height: "30px",
                        textAlign: "right",
                      }}
                    ></input>
                    <span>
                      <ArrowDropUpIcon
                        onClick={handleNumberValueChange(incSuitOfsuitNo)}
                        className="arrowUp"
                      />
                      <ArrowDropDownIcon
                        onClick={handleNumberValueChange(decrSuitOfSuiteNo)}
                        className="arrowDown"
                      />
                    </span>
                    {suitOfSuitErrorMsg && <p className="errorMessage absolute">{suitOfSuitErrorMsg}</p>}
                  </div>
                </form>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Result_config);

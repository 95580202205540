import { useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import cx from 'classnames';
import DashboardStyle from '@pages/dashboard/styles/dashboard.module.scss';
import { getIndividualDashboardConsumeStatistics } from '@api/api_services';
import {
  _getKBFromStorageString,
  _getStorageWithUnitString,
  _showNumberAfterFirstDecimal,
} from '@pages/dashboard/charts/CommonMethods';
import { fontPoppins } from '@src/css_config/fontConstants';
const btnTypes = {
  weekly: 'This Week',
  monthly: 'Month',
  yearly: 'Year',
  all: 'All',
};

function MemoryConsumeStatisticsIndividual({ projectId }) {
  const [activeBtn, setActiveBtn] = useState(Object.keys(btnTypes)[0]);
  const [projectDetails, setProjectDetails] = useState([]);
  const [simpledateArray, setSimpleDateArray] = useState([]);
  const [dateArrayTooltip, setDateArrayTooltip] = useState([]);
  const [chartSeries, setChartSeries] = useState([]);

  const _dateofArray = () => {
    return projectDetails?.map((val) => val?.date);
  };
  const _simpleofArray = () => {
    const result = projectDetails?.map((val) => val?.date);
    setSimpleDateArray(result);
  };

  const _dateformatterForToolTip = () => {
    if (activeBtn === 'weekly' || activeBtn === 'monthly') {
      const result = _dateofArray()?.map((date) => {
        const splitDate = date.split('-');
        const mergeDate = [splitDate[0], splitDate[1]];
        return mergeDate.join(' ');
      });
      setDateArrayTooltip([...result]);
      return;
    }
    if (activeBtn === 'yearly' || activeBtn === 'all') {
      const result = _dateofArray()?.map((date) => {
        const splitDate = date.split('-');
        const mergeDate = [splitDate[0], splitDate[1], splitDate[2]];
        return mergeDate.join(' ');
      });
      setDateArrayTooltip([...result]);
      return;
    }
  };

  const _convertTotalMemoryMbToKb = (chartSeries) => {
    return chartSeries.map((memory) => _getKBFromStorageString(memory));
  };

  const _totalMemoryArray = () => {
    const result = projectDetails?.map((val) => val?.totalMemory);
    setChartSeries(result);
    const converMemoryData = _convertTotalMemoryMbToKb(result || []);
    const convertKbToGb = converMemoryData.map((val) => val / (1024 * 1024));
    setSeries([{ data: convertKbToGb }]);
  };

  const _getApiMemoryConsumeStatistics = async () => {
    try {
      const result = await getIndividualDashboardConsumeStatistics(projectId, activeBtn);
      if (result?.data?.responseObject) {
        setProjectDetails(result.data?.responseObject?.projectDetails);
      }
    } catch (error) {
      console.error('Get Api Memory Consume Statistics Data With Params', error);
    }
  };

  useEffect(() => {
    _totalMemoryArray();
    _simpleofArray();
    _dateformatterForToolTip();
  }, [projectDetails]);

  useEffect(() => {
    _getApiMemoryConsumeStatistics();
  }, [projectId, activeBtn]);

  const [series, setSeries] = useState([{ data: [] }]);

  const _commonCustomTooltip = ({ series, ...w }) => {
    const { dataPointIndex } = w;
    const totalMemory = w?.w?.config?._totalMemoryArray;
    const date = w?.w?.config?._dateformatter;
    const tooltipData = _showNumberAfterFirstDecimal(totalMemory?.[dataPointIndex]);
    const tooltipDataInKb = _getKBFromStorageString(tooltipData);
    const unitToStringMemoryValue = _getStorageWithUnitString(tooltipDataInKb);
    return `
      <div class="px-4 py-2 float-right border-1 rounded-lg"">
        <span class='bg-inherit'>${unitToStringMemoryValue} ${date?.[dataPointIndex]}</span>
      </div>
    `;
  };

  const chartOptions = useMemo(
    () => ({
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      chart: {
        offsetY: -7,
        width: '100%',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },

      title: {
        text: '.',
        align: 'center',
        style: {
          fontSize: '1px',
          color: '#FBFCFD',
        },
      },
      stroke: {
        width: 3,
        curve: 'smooth',
      },
      yaxis: {
        show: true,
        tickAmount: 5,
        decimalsInFloat: 1,
        labels: {
          show: true,
          style: {
            fontFamily: fontPoppins.rXs.fontFamily,
            colors: '#B3B8BD',
          },
        },
        min: 0,
        max: (Math.max(...series.map((item) => Math.max(...item.data))) || 5) + 1,
        title: {
          text: 'Total Memory (GB)',
          offsetX: 0,
          offsetY: 0,
          style: {
            ...fontPoppins.rSm,
            color: '#7C828A',
          },
        },
      },
      xaxis: {
        type: 'categories',
        categories: simpledateArray,
        tickAmount: 6,
        offsetX: 10,
        labels: {
          showDuplicates: false,
          format: 'dd MMM yyyy',
          rotate: 0,
          hideOverlappingLabels: true,
          style: {
            fontFamily: fontPoppins.rXs.fontFamily,
            fontSize: '10px',
            colors: '#B3B8BD',
          },
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      tooltip: {
        enabled: true,
        custom: _commonCustomTooltip,
      },

      _totalMemoryArray: chartSeries,
      _dateformatter: dateArrayTooltip,
    }),
    [chartSeries, projectDetails]
  );
  return (
    <>
      <div className={DashboardStyle['consume-statistics-container']}>
        <div>
          <div className="flex">
            <h3 className={DashboardStyle['heading-consume']}>Consume Statistics</h3>
          </div>
        </div>
        <div>
          {Object.entries(btnTypes).map(([btnKey, btnLabel]) => (
            <button
              className={cx(DashboardStyle['toggle-btn'], {
                [DashboardStyle['active']]: btnKey === activeBtn,
              })}
              onClick={() => {
                setActiveBtn(btnKey);
              }}
              key={btnKey}
            >
              {btnLabel}
            </button>
          ))}
        </div>
      </div>
      <Chart options={chartOptions} series={series} type="line" height="91%" />
    </>
  );
}
export default MemoryConsumeStatisticsIndividual;

import React, { Fragment, useState, useEffect, useRef, useContext } from 'react';
import AssertEditor from './Assert_Editor';
import '../css/Assert_Editor.css';
import '../../prerequisite/css/Prerequisite_Editor.css';
import useToggle from '@src/hooks/useToggle';
import { getPostSnippet, postCompileAPI } from '@api/api_services';
import '@pages/test-development/script/scripts/webservice/restapi/css/common_Webservice.scss';
import { ASSERT_CONSTANT } from '../jsx/constant';
import { PREREQUISITE_CONSTANT } from '@pages/test-development/script/scripts/webservice/restapi/request/prerequisite/jsx/constant';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { RestContext } from '@pages/test-development/script/scripts/webservice/restapi/restapi';
import ArrowExpandWhite from '../images/arrow-expand-white.svg';
import ArrowCollapseWhite from '../images/arrow-collapased-white.svg';
import ExpandIcon from '@pages/test-development/script/scripts/webservice/restapi/request/assert/images/expand-icon-1.svg';
import CollapseIcon from '@pages/test-development/script/scripts/webservice/restapi/request/assert/images/expand-icon-2.svg';
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ArrowExpand from '../images/arrow_expand.svg';
import ArrowCollapse from '../images/arrow_collapased.svg';
import { colors } from '@src/css_config/colorConstants';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';

const CustomAssert = (props) => {
  const [isOpen, toggle] = useToggle(ASSERT_CONSTANT.TRUE);
  const [projectTypeList, setProjectTypeList] = useState(ASSERT_CONSTANT.EMPTY_ARRAY);
  const [IsConsoleVisible, setConsoleVisible] = useState(ASSERT_CONSTANT.FALSE);
  const [message, setMessage] = useState(ASSERT_CONSTANT.NO_LOG_YET);
  const childRef = useRef();
  const [aceValue, setAceValue] = useState(ASSERT_CONSTANT.EMPTY_STRING);
  const [disableButton, setDisableButton] = useState(true);
  const [openValue, setOpenValue] = useState(PREREQUISITE_CONSTANT.FALSE);
  const context = useContext(RestContext);
  let [expandClick, setExpandClick] = useState(false);

  useEffect(() => {
    getPostSnippetValue();
    if (context.initialValues.assertCodeValue === context.values.assertCodeValue) {
      context.setFieldValue('assertCodeValue', ASSERT_CONSTANT.ONlOAD_CHANGE);
    }
  }, []);

  useEffect(() => {
    getStepsUpdate();
  }, [props.selectedStepData]);
  useEffect(() => {
    setExpandClick(false);
  }, [props.customAssertStatus]);

  const getStepsUpdate = () => {
    const tempParmvalue = props.selectedStepData;
    const code = tempParmvalue?.postStep?.program?.codeSnippet?.code;
    if (Object.keys(props.selectedStepData).length > 0 && code) {
      const assertCodeValue = props.getUserInputValue(code, true);
      childRef.current.showAlert(assertCodeValue, true);
      context.values.assertCodeValue = code;
      setDisableButton(false);
    } else if (context.initialValues.assertCodeValue === context.values.assertCodeValue) {
      context.values.assertCodeValue = ASSERT_CONSTANT.ONlOAD_CHANGE;
      context.values.customSnippetValue = [];
    }
  };

  const getCodeValue = (codeUI, value) => {
    setAceValue(codeUI);
    const assertCodeValue = props.getUserInputValue(value, false);
    const getCustomSnippetValue = onCheckCustomSnippetValue(codeUI);
    context.setFieldValue('assertCodeValue', assertCodeValue);
    context.setFieldValue('customSnippetValue', getCustomSnippetValue);
  };

  const onCheckCustomSnippetValue = (code) => {
    let newCustomSnippetValue = [];
    if (projectTypeList.length > 0) {
      projectTypeList?.forEach((value) => {
        if (value?.customSnippetDefinition) {
          if (code.includes(value?.customSnippet)) {
            newCustomSnippetValue.push(value?.customSnippetDefinition);
          }
        }
      });
    }
    return newCustomSnippetValue;
  };

  const compileAndSave = (value) => {
    value ? setDisableButton(false) : setDisableButton(true);
  };
  const toggleAccordionInsideRequest = () => {
    setConsoleVisible(ASSERT_CONSTANT.FALSE);
  };

  const toggleAccordionRequest = () => {
    setConsoleVisible(ASSERT_CONSTANT.TRUE);
  };
  const getPostSnippetValue = async () => {
    getPostSnippet().then((response) => {
      let snippetValue = response.data.success;
      setProjectTypeList(snippetValue);
    });
  };

  const handlelogic = (row) => {
    let defaultSnippet = row?.defaultSnippet?.trim();
    let customSnippet = row?.customSnippet?.trim();
    if (defaultSnippet) {
      childRef.current.showAlert(defaultSnippet, false);
    } else {
      childRef.current.showAlert(customSnippet, false);
      let updateCustomSnippetValue = [];
      if (context?.values?.customSnippetValue && context?.values?.customSnippetValue.length > 0) {
        updateCustomSnippetValue = [...context.values.customSnippetValue, row?.customSnippetDefinition];
      } else {
        updateCustomSnippetValue.push(row?.customSnippetDefinition);
      }
      const uniqueCustomSnippetValue = [...new Set(updateCustomSnippetValue)];
      context.setFieldValue('customSnippetValue', uniqueCustomSnippetValue);
    }
    setDisableButton(false);
  };

  const CompileAPI = () => {
    const data = {
      code: context.values.assertCodeValue,
      customSnippetDefinitions: context.values.customSnippetValue,
    };
    postCompileAPI(data)
      .then((response) => {
        setConsoleVisible(ASSERT_CONSTANT.TRUE);
        setMessage(`Loading...`);
        if (response.data.success === ASSERT_CONSTANT.NULL && response.data.errors !== ASSERT_CONSTANT.NULL) {
          props.MyAlert.danger(`There has been an error detected in the code .Do clear it before proceed`);
          setTimeout(function () {
            setMessage(response.data.errors.details);
          }, 3000);
        } else if (response.data.success === ASSERT_CONSTANT.NULL && response.data.errors === ASSERT_CONSTANT.NULL) {
          setTimeout(function () {
            setMessage(ASSERT_CONSTANT.COMPILATION_SUCCESS);
          }, 3000);
        }
      })
      .catch((error) => { });
  };

  const toggleClearClick = () => {
    setMessage(ASSERT_CONSTANT.EMPTY_STRING);
  };

  return (
    <Fragment>
      <Modal open={openValue} className="modal-dialog">
        <div className="modal-container page-modal-size1">
          <div className="modal-header">
            <h2 className="title">{`PreRequisite`}</h2>
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                setOpenValue(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="modal-body" id="journal-scroll">
            <div
              className="console_output_container_Response fontPoppinsRegularMd overflow-y-auto shadow"
              id="journal-scroll"
            >
              {aceValue}
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="cancel-button-common cancel-button-layout-style-project mr-3"
              onClick={() => {
                setOpenValue(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
      <div className={PREREQUISITE_CONSTANT.PREREQUISITE_LEFT_SIDEBAR}>
        <div className="compile-save-expand-section-container">
          <div
            style={{ width: !isOpen && '42px' }}
            className={`${isOpen && 'assert_left_sidebar_section_one'} compile-save-expand-sub-container`}
          >
            {isOpen ? (
              <>
                <div className={PREREQUISITE_CONSTANT.CLASSNAME_SNIPPET_FIRST}>
                  <label className={'fontPoppinsSemiboldSm ml-3'}>{PREREQUISITE_CONSTANT.LABEL_SNIPPETS}</label>
                </div>
                <div className={`${PREREQUISITE_CONSTANT.SNIPPET_INSIDE_DIV}`}>
                  <MenuIcon onClick={toggle} />
                </div>
              </>
            ) : (
              <div className="relative">
                <div className="cursor-pointer rotate-toggle-menu -mt-2.5">
                  <MenuOpenIcon onClick={toggle} />
                </div>
              </div>
            )}
          </div>
          <button
            disabled={disableButton}
            className={`compile-save-section fontMontserratMediumXs ${disableButton ? 'disabled-btn-custom-assert ' : 'pre-enabled-btn'
              }`}
            onClick={() => {
              CompileAPI();
            }}
          >
            {PREREQUISITE_CONSTANT.COMPILE_AND_SAVE}
          </button>
          {
            <CustomTooltip title={expandClick ? 'collapse' : 'expand'} placement="bottom">
              <div className="expand-icon-custom-assert">
                <img
                  className=" w-full prerequisite-group-icon"
                  src={expandClick ? CollapseIcon : ExpandIcon}
                  alt="showmore"
                  width="12"
                  onClick={(e) => {
                    setExpandClick(!expandClick);
                  }}
                />
              </div>
            </CustomTooltip>
          }
        </div>
      </div>
      <div className={`flex custom-assert-main-container-height custom-assert-main-container-width`}>
        {isOpen && (
          <div
            className={`${PREREQUISITE_CONSTANT.PREREQUISITE_MAIN_CONTAINER_SECTION_ONE} custom-assert-vertical-scroll custom-assert-main-container-height`}
          >
            <div className="mt-2 ml-2">
              <div stickyHeader aria-label={PREREQUISITE_CONSTANT.STICKY_TABLE}>
                <div>
                  {projectTypeList?.length > 0 &&
                    projectTypeList.map((row, index) => (
                      <>
                        <div
                          className={`cursor-pointer assert-left-side-panel`}
                          key={`${row.id}`}
                          onClick={() => {
                            handlelogic(row);
                          }}
                        >
                          <CustomTooltip
                            responseTooltip={true}
                            title={row.name}
                            placement="right"
                          >
                            <span className="select-none fontPoppinsRegularSm truncate assert-left-side-panel-text-customize">
                              <span className="mr-2 ml-2">
                                <AddIcon />
                              </span>
                              {row.name}
                            </span>
                          </CustomTooltip>
                        </div>
                        <span className="h-2 flex"></span>
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          className={`${isOpen
            ? `${PREREQUISITE_CONSTANT.PREREQUISITE_MAIN_CONTAINER_SECTION_TWO} assert-auto-scroll-bar custom-assert-editor`
            : `${PREREQUISITE_CONSTANT.PREREQUISITE_MAIN_CONTAINER_FULL_SECTION}`
            }`}
        >
          <div
            className={`prerequisite-main-container-sub ${isOpen ? 'prerequisite-ace-container-open-height' : 'prerequisite-ace-container-close-height'
              } ${IsConsoleVisible ? 'custom-assert-console-visibility' : 'custom-assert-console-hide'}`}
          >
            <div
              className={`${expandClick ? 'expand-editor-alignment-custom-editor' : 'wrapper-of-assert-editor'} ${IsConsoleVisible && !expandClick
                ? 'assertEditor-height-with-console-expand'
                : expandClick
                  ? 'assertEditor-height-with-expand'
                  : 'assertEditor-height'
                }`}
            >
              <AssertEditor
                ref={childRef}
                isOpen={isOpen}
                getCodeValue={getCodeValue}
                compileAndSave={compileAndSave}
                getUserInputValue={props.getUserInputValue}
                context={context}
                filteredStepGroupVariable={props.stepGroupVariable}
                assertCodeValue={context.values?.assertCodeValue}
                {...props}
              />
            </div>
            <div className={expandClick ? 'h-60' : 'h-0'}></div>

            {IsConsoleVisible === PREREQUISITE_CONSTANT.TRUE ? (
              <>
                <div className={`${PREREQUISITE_CONSTANT.CONSOLE_DIV_TWO}`}>
                  <label style={{ marginTop: '-2px' }} className={`mt-3 ${PREREQUISITE_CONSTANT.CONSOLE_LABEL_ONE}`}>
                    {PREREQUISITE_CONSTANT.CONSOLE_LABEL}
                  </label>
                  <div className={PREREQUISITE_CONSTANT.PR_2}>
                    <div className="flex flex-row fontPoppinsSemiboldSm mr-5">
                      <button
                        className={`ml-3 text-white`}
                        onClick={() => {
                          toggleClearClick();
                        }}
                      >
                        {PREREQUISITE_CONSTANT.CLEAR_LABEL}
                      </button>
                      <CustomTooltip title="collapse" placement="bottom">
                        <img
                          onMouseEnter={(e) => {
                            e.target.style.backgroundColor = `${colors.text_white}`;
                            e.target.style.borderRadius = '50px';
                            e.target.src = ArrowExpand;
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = 'initial';
                            e.target.src = ArrowExpandWhite;
                          }}
                          className={`ml-2 cursor-pointer arrow-expand-icon pre-requisite-isVisible-icon-rotate`}
                          src={ArrowExpandWhite}
                          onClick={toggleAccordionInsideRequest}
                          alt="showmore"
                          width="20"
                        />
                      </CustomTooltip>
                    </div>
                  </div>
                </div>
                <div
                  className={`${PREREQUISITE_CONSTANT.CONSOLE_OUTPUT_CONTAINER}`}
                  id={PREREQUISITE_CONSTANT.ID_JOURNAL}
                >
                  <div id="loading" className="console-output fontPoppinsRegularMd mt-1.5 ml-4 text-left">
                    {message}
                  </div>
                </div>
              </>
            ) : (
              <div className={`${PREREQUISITE_CONSTANT.CONSOLE_DIV}`}>
                <label className={PREREQUISITE_CONSTANT.CONSOLE_CLASSNAME}>{PREREQUISITE_CONSTANT.CONSOLE_LABEL}</label>
                <div className={PREREQUISITE_CONSTANT.PR_2}>
                  <CustomTooltip title="expand" placement="bottom">
                    <img
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = `${colors.text_white}`;
                        e.target.style.borderRadius = '50px';
                        e.target.src = ArrowCollapse;
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = 'initial';
                        e.target.src = ArrowCollapseWhite;
                      }}
                      className={`arrow-collapse-icon mt-1 cursor-pointer`}
                      src={ArrowCollapseWhite}
                      onClick={toggleAccordionRequest}
                      alt="showmore"
                      width="20"
                    />
                  </CustomTooltip>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CustomAssert;

import React, { useEffect, useState, useContext } from 'react';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { useTable } from 'react-table';
import UserDetailsModal from '@pages/test-development/shared/user-details-modal';
import { getAllLocalHistory, deleteHistoryReq, getSingleUserRequest } from '@api/api_services';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';
import {
  getFromUrl,
  getUpdatedHistoryPath,
  historyDetails,
  replaceVarIdToVarName,
  truncateRequestNameForHistory,
  truncateURLForHistory,
} from '@src/util/common_utils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { WEBSERVICE } from '../../restapi/constants/constants';
import { RestContext } from '../../restapi/restapi';

function Executed_requests(props) {
  let [reload, setReload] = useState(true);
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  let [variablesData, setVariablesData] = useState([]);
  let [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);
  let [userDetails, setUserDetails] = useState({});
  const {
    HISTORY_SCRIPT_ID,
    HISTORY_MODULE_ID,
    REQUEST_NAME,
    REQUEST_TYPE,
    URL,
    STATUS,
    MODIFIED_BY,
    TIME_STAMP,
    ACTION,
  } = WEBSERVICE;
  const reloadVariables = (val) => {
    setReload(val);
  };
  const history = useHistory();
  const scriptId = getFromUrl(HISTORY_SCRIPT_ID);
  const moduleId = getFromUrl(HISTORY_MODULE_ID);
  const path = getUpdatedHistoryPath(history);
  const hasFullAccess = React.useMemo(() => window.permission?.isFullAccess('testDevelopment'), []);
  const contextData = useContext(RestContext);

  let menuData = (variable) => {
    return (
      <div className="actionIcons pl-3">
        <CustomTooltip title="Delete" placement="bottom">
          <DeleteIcon
            className="float-left cursor-pointer mr-3 text-gray-500 hover-effect-on-history"
            onClick={() => {
              deleteLocalVariable(variable);
            }}
          />
        </CustomTooltip>
      </div>
    );
  };
  function pushToHistory(execution_id) {
    contextData.values.isHistoryStep = true;
    history.push(path, historyDetails(execution_id));
  }
  const getExecuteHistory = async () => {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    const varData = [];
    const res = await getAllLocalHistory(moduleId, scriptId);
    stopProgress();
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 10,
    });
    res.data.success.apiExecutionMinimals.map((item, index) => {
      const execution_id = res?.data?.success?.apiExecutionMinimals[index]?.executionId;
      return varData.push({
        name: (
          <CustomTooltip title={item.name}>
            <span className="text-rs-primary">
              {item.name === '' ? (
                'NULL'
              ) : (
                <span
                  className="lengthyCharName cursor-pointer"
                  onClick={() => {
                    pushToHistory(execution_id);
                  }}
                >
                  {truncateRequestNameForHistory(item.name)}
                </span>
              )}
            </span>
          </CustomTooltip>
        ),
        method: <span className="text-black"> {item.method} </span>,
        url: (
          <CustomTooltip title={replaceVarIdToVarName(item.url, contextData)}>
            <span className="text-rs-primary">
              {item.url === '' ? (
                'NULL'
              ) : (
                <span
                  className="lengthyCharUrl cursor-pointer"
                  onClick={() => {
                    pushToHistory(execution_id);
                  }}
                >
                  {truncateURLForHistory(replaceVarIdToVarName(item.url, contextData))}{' '}
                </span>
              )}{' '}
            </span>
          </CustomTooltip>
        ),
        statusCode: <span className="text-black"> {item.statusCode}</span>,
        addedAsStep: (
          <span className="text-black">
            {' '}
            {item.addedAsStep.toString() === 'false' ? 'No' : item.addedAsStep.toString() === 'true' ? 'Yes' : ''}
          </span>
        ),
        userName: (
          <span
            onClick={() => {
              if (item.userName !== '--') {
                getUserDetails(item.userId);
              }
            }}
            className="text-black  lengthyCharTimeStamp"
          >
            {item.userName.includes('@') ? item.userName.split('@')[0] : item.userName}
          </span>
        ),
        executiontime: (
          <CustomTooltip title={item.executiontime}>
            <span className="text-black">
              {' '}
              {item.executiontime === '' ? (
                'NULL'
              ) : item.executiontime.length >= 15 ? (
                <span className="lengthyCharTimeStamp">{item.executiontime}</span>
              ) : (
                <span>{item.executiontime}</span>
              )}{' '}
            </span>
          </CustomTooltip>
        ),
        ...(hasFullAccess && { delete: menuData(item) }),
      });
    });
    setVariablesData(varData);
  };
  const deleteLocalVariable = async (data) => {
    try {
      const response = await deleteHistoryReq(data.executionId);
      if (response.status === 200) {
        reloadVariables(true);
        props.MyAlert.success(`${data.name} history deleted successfully `);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getUserDetails = async (key) => {
    if (key) {
      let res = await getSingleUserRequest(key);
      if (res.data.responseCode === 200 && res.data.responseObject) {
        setUserDetails(res.data.responseObject);
        setOpenUserDetailsModal(true);
      }
    }
  };

  const closeUserDetailsModal = (value) => {
    setOpenUserDetailsModal(value);
    setUserDetails({});
  };
  const getHeaderName = (headerName) => (
    <div className="text-left fontPoppinsSemiboldSm text-rs-primary pl-2">{headerName}</div>
  );

  const getCellName = (cellName) => (
    <div className="text-left fontPoppinsRegularSm p-0 select-none pl-2">{cellName}</div>
  );
  const data = React.useMemo(() => variablesData, [variablesData]);
  const columns = React.useMemo(
    () => [
      {
        Header: getHeaderName(REQUEST_NAME),
        accessor: 'name',
        width: '13%',
        Cell: ({ value }) => getCellName(value),
      },
      {
        Header: getHeaderName(REQUEST_TYPE),
        accessor: 'method',
        width: '13%',
        Cell: ({ value }) => getCellName(value),
      },
      {
        Header: getHeaderName(URL),
        accessor: 'url',
        width: '17%',
        Cell: ({ value }) => getCellName(value),
      },
      {
        Header: getHeaderName(STATUS),
        accessor: 'statusCode',
        width: '12%',
        Cell: ({ value }) => getCellName(value),
      },
      {
        Header: getHeaderName(MODIFIED_BY),
        accessor: 'userName',
        width: '12%',
        Cell: ({ value }) => getCellName(value),
      },
      {
        Header: getHeaderName(TIME_STAMP),
        accessor: 'executiontime',
        width: '12%',
        Cell: ({ value }) => getCellName(value),
      },
      ...(hasFullAccess
        ? [
            {
              Header: getHeaderName(ACTION),
              accessor: 'delete',
              width: '13%',
              Cell: ({ value }) => getCellName(value),
            },
          ]
        : []),
    ],
    []
  );
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data,
  });
  useEffect(() => {
    if (reload) {
      getExecuteHistory();
    }
    setReload(false);
  }, [reload]);
  return (
    <>
      <div>
        {openUserDetailsModal ? <UserDetailsModal data={userDetails} closeModal={closeUserDetailsModal} /> : null}
        <div className="table-height overflow-auto h-65v" id="journal-scroll">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="text-sm h-8 ml-4 sticky top-0 zIndex-1 select-none common-table-header-row-for-snippet-history"
                >
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} width={column.width}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="fontPoppinsRegularMd text-red-500">
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className="configHover h-10 content-start select-none common-table-body-row-for-snippet-history"
                  >
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default Executed_requests;

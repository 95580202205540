import React, { useEffect, useState, memo, useCallback, createContext, Fragment, useRef } from 'react';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { SidebarData, getDefaultPath } from '@pages/common/sidebar_data';
import { useTheme } from '@mui/material/styles';
import { DrawerHeader, Drawer, ListItem, ListItemIconAlignment } from '../styles/drawer_style_config';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { LogoComponent } from './LogoComponent';
import SearchProjectsComponent from './SearchProjectsComponent';
import { LAYOUT_CONSTANTS } from '../constants/LayoutConstants';
import styles from '@pages/common/Layout/styles/side_navigation_style.module.scss';
import SideMenuTitleIconsRenderer from '../IconsComponents/SideMenuTitleIconsRenderer';
import ProtectedRoute from '@common/routes/ProtectedRoute';
import SystemConfigRouter from '@src/pages/configuration/system_config/system_config_router';
import LicenseForm from '@pages/licenses/license-form';
import useSelectedProjectListener from '@src/hooks/useSelectedProjectListener';
import { getPermissionsReq, getProjectListData } from '@api/api_services';
import { Menu, Transition } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/solid';
import SideMenuRouterStyle from '@pages/common/sidemenurouter.module.scss';
import cx from 'classnames';
import { Tooltip } from '@mui/material';
import BreadcrumbsComponent from '@pages/common/breadcrumbs';
import {
  getUserStatus,
  getLicenseStatus,
  getCurrentProjectId,
  getCurrentProjectName,
  getCurrentProjectType,
  getAutomationProjectTypes,
  getLicenseFeatures,
  getPrivilege,
  isImportExportEnabled,
  getCurrentPrivilage,
} from '@util/common_utils';
import WebserviceWarningModal from '@pages/test-development/script/scripts/webservice-warning-modal';
import { SIDE_NAVIGATION_CONSTANTS, LICENSE_EXPIRED_CONSTANTS, LICENSE_FEATURES } from '@src/common/ui-constants';
import Chatbot from '@src/common/components/Chatbot';
import ChatbotOpener from '@src/common/components/ChatbotOpener';

export const DataContext = createContext();

function SideNavigationComponent({ navOpen, setNavOpen }) {
  const [open, setOpen] = useState(true);
  const [selectedMenu, setSelectedMenu] = useState(SIDE_NAVIGATION_CONSTANTS?.PROJECTS_MENU);
  const location = useLocation();
  const [isPremissionLoading, setPremissionLoading] = useState(false);
  let [hideMenus, setHideMenus] = useState(SIDE_NAVIGATION_CONSTANTS?.HIDE_MENU_LIST);
  const [tabPermissionsList, setTabPermissionList] = useState({});
  const history = useHistory();
  const theme = useTheme();
  const userStatus = getUserStatus();
  const licenseStatus = getLicenseStatus();
  const isDisabled =
    licenseStatus === LICENSE_EXPIRED_CONSTANTS?.EXPIRED ||
    userStatus === LICENSE_EXPIRED_CONSTANTS?.DISABLED ||
    getLicenseStatus() === null ||
    userStatus === null;
  const [webserviceData, setWebserviceData] = useState(null);
  const [showWebserviceWarningModal, setShowWebserviceWarningModal] = useState(false);
  const [clickedTabPath, setClickedPathTab] = useState(null);

  const projectName = getCurrentProjectName();
  const projectType = getCurrentProjectType();
  const currentPrivilege = getCurrentPrivilage();
  const isAllProject = projectName === SIDE_NAVIGATION_CONSTANTS?.ALL_PROJECTS;
  const isProjectSelected = getCurrentProjectId();
  const search = window.location.search;
  const scriptId = new URLSearchParams(search).get('scriptId');
  const activePathName = location.pathname;
  let webserviceTab = false;
  if (
    (scriptId &&
      (activePathName.includes(SIDE_NAVIGATION_CONSTANTS?.WEB_SERVICES) ||
        activePathName.includes(SIDE_NAVIGATION_CONSTANTS.SETTINGS))) ||
    activePathName.includes(SIDE_NAVIGATION_CONSTANTS.SETTINGS)
  ) {
    webserviceTab = true;
  }
  localStorage.setItem(SIDE_NAVIGATION_CONSTANTS?.WEB_SERVICE_DATA, webserviceData);
  localStorage.setItem(SIDE_NAVIGATION_CONSTANTS?.WEB_SERIVCE_TAB, webserviceTab);
  const [licenseFeatures, setLicenseFeatures] = useState([]);
  const [hasDefects, setHasDefects] = useState(false);
  const [hasAutomation, setHasAutomation] = useState(false);
  const [hasManualTestCase, setHasManualTestCase] = useState(false);
  const [automationTypes, setAutomationTypes] = useState([]);
  const displayImportExport = isImportExportEnabled();
  const [showChatbot, setShowChatbot] = useState(false);
  const [hide, setHide] = useState(false);
  const [chatbotPrivilege, setChatbotPrivilege] = useState();
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const menuTimeoutRef = useRef(null);
  const submenuTimeoutRef = useRef(null);

  const showDefect = () => {
    let show;
    if (!window.privilege.hasDefects(getLicenseFeatures())) {
      show = false;
    } else {
      show = true;
    }
    return show;
  };
  const isAdminOrSuperAdmin = ['Admin', 'Super Admin'].includes(currentPrivilege);

  useEffect(() => {
    if (licenseFeatures?.includes(LICENSE_FEATURES?.ALL)) {
      setHasAutomation(true);
      setHasManualTestCase(true);
      setHasDefects(true);
    } else {
      setHasAutomation(window?.privilege?.hasAutomation(licenseFeatures));
      setHasManualTestCase(window?.privilege?.hasManualTestCase(licenseFeatures));
      setHasDefects(showDefect());
    }
  }, [licenseFeatures, showDefect()]);

  const showExecution = () => {
    let show;
    if (
      (window.privilege.hasAutomation(getLicenseFeatures()) &&
        window.privilege.hasDefects(getLicenseFeatures()) &&
        !window.privilege.hasManualTestCase(getLicenseFeatures()) &&
        !getAutomationProjectTypes(getPrivilege())?.includes(getCurrentProjectType())) ||
      (!window.privilege.hasAutomation(getLicenseFeatures()) &&
        window.privilege.hasDefects(getLicenseFeatures()) &&
        !window.privilege.hasManualTestCase(getLicenseFeatures()))
    ) {
      show = false;
    } else {
      show = true;
    }
    return show;
  };

  const showResultConfig = () => {
    if (
      isAllProject &&
      window.privilege.hasAutomation(getLicenseFeatures()) &&
      window.privilege.hasDefects(getLicenseFeatures()) &&
      !window?.privlege?.hasManualTestCase(getLicenseFeatures())
    ) {
      return true;
    } else {
      return showExecution();
    }
  };

  //TODO Need to handle routes form privilege API
  const subMenuItems = [
    {
      text: SIDE_NAVIGATION_CONSTANTS?.RUN_CONFIGURATION,
      path: '',
      isVisible: isAllProject && hasAutomation ? true : hasAutomation && automationTypes?.includes(projectType),
      type: SIDE_NAVIGATION_CONSTANTS?.CONFIG,
      subMenuList: [
        {
          text: SIDE_NAVIGATION_CONSTANTS?.ENVIRONMENT_CONFIGURATION,
          path: '/configuration/environmentconfiguration/systemconfiguration',
          isVisible: true,
        },
        {
          text: SIDE_NAVIGATION_CONSTANTS?.WAIT_CONFIGURATION,
          path: '/configuration/RunConfiguration/Waitconfiguration',
          isVisible: true,
        },
        {
          text: SIDE_NAVIGATION_CONSTANTS?.SCREENTSHOT_AND_VIDEO_CONFIG,
          path: '/configuration/RunConfiguration/Screenshot&Videoconfiguration',
          isVisible: true,
        },
      ],
    },
    {
      text: SIDE_NAVIGATION_CONSTANTS?.RESULT_CONFIGURATION,
      path: '/configuration/ResultConfiguration',
      isVisible: showResultConfig(),
      type: '',
      subMenuList: [],
    },
    {
      text: SIDE_NAVIGATION_CONSTANTS?.VARIABLES,
      path: '/configuration/Configurationvariables/Projectenvironmentvariable',
      isVisible: isProjectSelected && hasAutomation && automationTypes?.includes(projectType),
      type: '',
      subMenuList: [],
    },
    {
      text: SIDE_NAVIGATION_CONSTANTS?.LABELS,
      path: '/configuration/Label/Labels',
      isVisible: isAllProject ? false : true,
      type: '',
      subMenuList: [],
    },
    {
      text: SIDE_NAVIGATION_CONSTANTS?.INTEGRATIONS,
      path: '/configuration/integration/plugin',
      isVisible: isAllProject && (hasAutomation || hasManualTestCase) ? true : false,
      type: '',
      subMenuList: [],
    },
    {
      text: SIDE_NAVIGATION_CONSTANTS?.EMAIL_CONFIGURATION,
      path: '/configuration/emailConfiguration/emailGroup',
      isVisible: true,
      type: '',
      subMenuList: [],
    },
    {
      text: SIDE_NAVIGATION_CONSTANTS?.MEMORY_CONFIGURATION,
      path: '/configuration/memoryConfiguration',
      isVisible: isAllProject ? true : false,
      type: '',
      subMenuList: [],
    },
    {
      text: SIDE_NAVIGATION_CONSTANTS?.DEFECT_MANAGEMENT,
      path: '/configuration/defectManagement',
      isVisible: isAllProject && ['Super Admin', 'Admin'].includes(currentPrivilege) ? true : false,
      type: '',
      subMenuList: [],
    },
    {
      text: SIDE_NAVIGATION_CONSTANTS?.IMPORT_AND_EXPORT,
      path: '/configuration/import-export/Import',
      isVisible: displayImportExport && isAllProject ? true : false,
      type: '',
      subMenuList: [],
    },
    {
      text: SIDE_NAVIGATION_CONSTANTS?.TIME_ZONE,
      path: '/configuration/TimeZone',
      isVisible: isAllProject && isAdminOrSuperAdmin ? true : false,
      type: '',
      subMenuList: [],
    },
    {
      text: SIDE_NAVIGATION_CONSTANTS?.TEMPLATES,
      path: '',
      isVisible: hasManualTestCase || hasDefects,
      type: SIDE_NAVIGATION_CONSTANTS?.TYPE_TEMPLATE,
      subMenuList: [
        {
          text: SIDE_NAVIGATION_CONSTANTS?.MANUAL_TEMPLATE,
          path: '/configuration/template?mode=manual',
          isVisible: hasManualTestCase,
        },
        {
          text: SIDE_NAVIGATION_CONSTANTS?.DEFECT_TEMPLATE,
          path: '/configuration/template/defecttemplate/defectdetails?dmode=defect',
          isVisible: hasDefects,
        },
      ],
    },
  ];

  const handleWebServiceModal = (action) => {
    if (action === SIDE_NAVIGATION_CONSTANTS?.ACTION_EXIT) {
      setShowWebserviceWarningModal(false);
      history.push(clickedTabPath);
    } else {
      setShowWebserviceWarningModal(false);
      setClickedPathTab(null);
    }
  };

  const handleListItemClick = (route) => {
    if (openMenuIndex !== null) {
      setOpenMenuIndex(null);
    }
    const isWebserviceData = localStorage.getItem(SIDE_NAVIGATION_CONSTANTS?.WEB_SERVICE_DATA);
    const isWebserviceTab = localStorage.getItem(SIDE_NAVIGATION_CONSTANTS?.WEB_SERIVCE_TAB);
    if (isWebserviceData === SIDE_NAVIGATION_CONSTANTS?.TRUE && isWebserviceTab === SIDE_NAVIGATION_CONSTANTS?.TRUE) {
      setClickedPathTab(route);
      setShowWebserviceWarningModal(true);
    } else {
      history.push(route);
    }
  };

  const handleDrawerClose = () => {
    setOpen(!open);
    setNavOpen(!navOpen);
  };
  const [, startListeningSelectedProject] = useSelectedProjectListener();

  useEffect(() => {
    startListeningSelectedProject();
  }, []);

  const initProjectPage = (project) => {
    let intialRoute = '';
    if (global?.permission?.isNoAccess(SIDE_NAVIGATION_CONSTANTS?.TEST_DEV_ACCESS)) {
      intialRoute = '/projectmenu/project-config';
      handleListItemClick(intialRoute);
    } else if (project?.id === '') {
      intialRoute = '/projectmenu/project';
      handleListItemClick(intialRoute);
    } else {
      intialRoute = '/testdevelopment/Script';
      handleListItemClick(intialRoute);
    }

    if (project?.id === '') {
      setHideMenus(SIDE_NAVIGATION_CONSTANTS?.HIDE_MENU_LIST);
      setSelectedMenu(SIDE_NAVIGATION_CONSTANTS?.PROJECTS_MENU);
    } else {
      let menuListToHide = [SIDE_NAVIGATION_CONSTANTS?.PROJECTS_MENU, SIDE_NAVIGATION_CONSTANTS?.LICENSES];

      if (
        !window.privilege.hasAutomation(getLicenseFeatures()) ||
        !getAutomationProjectTypes(getPrivilege())?.includes(getCurrentProjectType())
      ) {
        menuListToHide.push(SIDE_NAVIGATION_CONSTANTS?.REPOSITORY);
      }
      if (!showDefect() && !window.privilege.hasCBasic(getLicenseFeatures())) {
        menuListToHide.push(SIDE_NAVIGATION_CONSTANTS?.DEFECTS);
      }
      if (!showExecution()) {
        menuListToHide.push(SIDE_NAVIGATION_CONSTANTS?.EXECUTION);
      }
      setHideMenus(menuListToHide);
      setSelectedMenu(
        global.permission.isNoAccess(SIDE_NAVIGATION_CONSTANTS?.TEST_DEV_ACCESS)
          ? SIDE_NAVIGATION_CONSTANTS?.PROJECT_MENU
          : SIDE_NAVIGATION_CONSTANTS?.TEST_DEVELOPMENT
      );
    }
  };

  useEffect(() => {
    const getPrivilegeData = async () => {
      const allowedProject = await getProjectListData();
      const response = allowedProject.data.responseObject;
      let allowedAutomationProjects = getAutomationProjectTypes(response);
      setAutomationTypes(allowedAutomationProjects);
      setLicenseFeatures(response?.licenseFeatures);
      localStorage.setItem(SIDE_NAVIGATION_CONSTANTS?.ALLOWED_PROJECTS, JSON.stringify(response));
      if (allowedProject.data.responseObject?.chatBotEnabled) {
        setChatbotPrivilege(allowedProject.data.responseObject.chatBotEnabled);
      }
    };
    getPrivilegeData();
  }, []);
  React.useEffect(() => {
    const selectedProject = JSON.parse(localStorage.getItem(SIDE_NAVIGATION_CONSTANTS?.SELECTED_PROJECT));
    let locationPathArray = location?.pathname?.split('/');
    const selectedPath = locationPathArray.find(function (el) {
      return SIDE_NAVIGATION_CONSTANTS?.PATHS.includes(el);
    });
    switch (selectedPath) {
      case SIDE_NAVIGATION_CONSTANTS?.PATH_PROJECT_CONFIG:
        setHideMenus([SIDE_NAVIGATION_CONSTANTS?.PROJECTS_MENU, SIDE_NAVIGATION_CONSTANTS?.LICENSES]);
        setSelectedMenu(SIDE_NAVIGATION_CONSTANTS?.PROJECT_MENU);
        break;

      case SIDE_NAVIGATION_CONSTANTS?.PATH_TEST_DEV:
        setHideMenus([SIDE_NAVIGATION_CONSTANTS?.PROJECTS_MENU, SIDE_NAVIGATION_CONSTANTS?.LICENSES]);
        setSelectedMenu(SIDE_NAVIGATION_CONSTANTS?.TEST_DEVELOPMENT);
        break;

      case SIDE_NAVIGATION_CONSTANTS?.PATH_REPOSITORY:
        setHideMenus([SIDE_NAVIGATION_CONSTANTS?.PROJECTS_MENU, SIDE_NAVIGATION_CONSTANTS?.LICENSES]);
        setSelectedMenu(SIDE_NAVIGATION_CONSTANTS?.REPOSITORY);
        break;

      case SIDE_NAVIGATION_CONSTANTS?.PATH_USER_INDIVIDUAL:
        setHideMenus([SIDE_NAVIGATION_CONSTANTS?.PROJECTS_MENU, SIDE_NAVIGATION_CONSTANTS?.LICENSES]);
        setSelectedMenu(SIDE_NAVIGATION_CONSTANTS?.PROJECT_MENU);
        break;

      case SIDE_NAVIGATION_CONSTANTS?.PATH_ROLE_INDIVIDUAL:
        setHideMenus([SIDE_NAVIGATION_CONSTANTS?.PROJECTS_MENU, SIDE_NAVIGATION_CONSTANTS?.LICENSES]);
        setSelectedMenu(SIDE_NAVIGATION_CONSTANTS?.PROJECT_MENU);
        break;

      case SIDE_NAVIGATION_CONSTANTS?.PATH_EXECUTION:
        setHideMenus([SIDE_NAVIGATION_CONSTANTS?.PROJECTS_MENU, SIDE_NAVIGATION_CONSTANTS?.LICENSES]);
        setSelectedMenu(SIDE_NAVIGATION_CONSTANTS?.EXECUTION);
        break;

      case SIDE_NAVIGATION_CONSTANTS?.PATH_TEST_DATA:
        setHideMenus([SIDE_NAVIGATION_CONSTANTS?.PROJECTS_MENU, SIDE_NAVIGATION_CONSTANTS?.LICENSES]);
        setSelectedMenu(SIDE_NAVIGATION_CONSTANTS?.TEST_DATA);
        break;

      case SIDE_NAVIGATION_CONSTANTS?.PATH_DEFECT:
        setHideMenus([SIDE_NAVIGATION_CONSTANTS?.PROJECTS_MENU]);
        setSelectedMenu(SIDE_NAVIGATION_CONSTANTS?.DEFECTS);
        break;

      case SIDE_NAVIGATION_CONSTANTS?.PATH_ANALYTICS_MENU:
        setHideMenus([SIDE_NAVIGATION_CONSTANTS?.PROJECTS_MENU, SIDE_NAVIGATION_CONSTANTS?.LICENSES]);
        setSelectedMenu(SIDE_NAVIGATION_CONSTANTS?.ANALYTICS);
        break;

      case SIDE_NAVIGATION_CONSTANTS?.PATH_CONFIGURATION:
        if (selectedProject.id !== '') {
          setHideMenus([SIDE_NAVIGATION_CONSTANTS?.PROJECTS_MENU, SIDE_NAVIGATION_CONSTANTS?.LICENSES]);
        } else {
          setHideMenus(SIDE_NAVIGATION_CONSTANTS?.HIDE_MENU_LIST);
        }
        setSelectedMenu(SIDE_NAVIGATION_CONSTANTS?.CONFIGURATION);
        break;

      case SIDE_NAVIGATION_CONSTANTS?.PATH_DASHBOARD:
        if (location?.pathname?.includes(SIDE_NAVIGATION_CONSTANTS?.PATH_EXECUTION)) return;
        if (selectedProject.id !== '') {
          setHideMenus([SIDE_NAVIGATION_CONSTANTS?.PROJECTS_MENU, SIDE_NAVIGATION_CONSTANTS?.LICENSES]);
        }
        setSelectedMenu(SIDE_NAVIGATION_CONSTANTS?.DASHBOARD);
        break;

      case SIDE_NAVIGATION_CONSTANTS?.PATH_LICENSES:
        setSelectedMenu(SIDE_NAVIGATION_CONSTANTS?.LICENSES);
        break;
    }
    if (['/projectmenu/project', '/projectmenu/user', '/projectmenu/role']?.includes(activePathName)) {
      setSelectedMenu(SIDE_NAVIGATION_CONSTANTS?.PROJECTS_MENU);
    }
    if (selectedProject?.id) {
      setHideMenus((prevState) => [...prevState, SIDE_NAVIGATION_CONSTANTS?.LICENSES]);
      if (!hasAutomation || !automationTypes?.includes(projectType)) {
        setHideMenus((prevState) => [...prevState, SIDE_NAVIGATION_CONSTANTS?.REPOSITORY]);
      }
      if (!showDefect() && !window.privilege.hasCBasic(getLicenseFeatures())) {
        setHideMenus((prevState) => [...prevState, SIDE_NAVIGATION_CONSTANTS?.DEFECTS]);
      }
      if (!showExecution()) {
        setHideMenus((prevState) => [...prevState, SIDE_NAVIGATION_CONSTANTS?.EXECUTION]);
      }
    }
  }, [activePathName, hasAutomation, hasManualTestCase, hasDefects]);

  const onProjectSelected = (project = '') => {
    const userRole = global.permission.getUserRole();
    if (['Super Admin', 'Admin'].includes(userRole)) {
      global.permission.initPermissions();
      if (project) {
        initProjectPage(project);
      }
      return;
    }

    setPremissionLoading(true);
    getPermissionsReq()
      .then((results) => {
        const tabPermissions = results?.data?.responseObject ? results.data.responseObject.tabPermissions : {};
        global.permission.updatePermissions(tabPermissions);

        setTabPermissionList(tabPermissions);
      })
      .catch((error) => {
        console.error('Failed to fetch role permissions', { error });
        global.permission.updatePermissions({});
      })
      .finally(() => {
        if (project) {
          initProjectPage(project);
        }
        setPremissionLoading(false);
      });
  };

  const handleSelectedProjectChangedEvent = useCallback((event) => {
    onProjectSelected(event.detail?.selectedProject);
  }, []);

  const newTabPermission =
    (tabPermissionsList &&
      Object.values(tabPermissionsList).filter((values) => values !== SIDE_NAVIGATION_CONSTANTS?.NO_ACCESS)) ||
    {};

  useEffect(() => {
    onProjectSelected();
    document.addEventListener('selectedProjectChanged', handleSelectedProjectChangedEvent);
    return () => document.removeEventListener('selectedProjectChanged', handleSelectedProjectChangedEvent);
  }, []);

  useEffect(() => {
    const currentProjectId = getCurrentProjectId();
    if (!currentProjectId || isAllProject) {
      setHideMenus(SIDE_NAVIGATION_CONSTANTS?.HIDE_MENU_LIST);
      // setSelectedMenu(SIDE_NAVIGATION_CONSTANTS?.PROJECTS_MENU);
      startListeningSelectedProject();
    }
  }, [isAllProject, activePathName]);

  const handleMouseEnterMenu = (index) => {
    clearTimeout(menuTimeoutRef.current);
    clearTimeout(submenuTimeoutRef.current);
    setOpenMenuIndex(index);
  };

  const handleMouseLeaveMenu = (index) => {
    menuTimeoutRef.current = setTimeout(() => {
      setOpenMenuIndex(null);
    }, 300);
  };

  const handleMouseEnterSubmenu = () => {
    clearTimeout(submenuTimeoutRef.current);
  };

  const handleMouseLeaveSubmenu = (index) => {
    submenuTimeoutRef.current = setTimeout(() => {
      if (openMenuIndex !== index) {
        setOpenMenuIndex(null);
      }
    }, 300);
  };
  const renderMenuListItem = (route) => {
    return (
      <ul className="left_navigation rounded-md">
        {subMenuItems?.map((menuData, index) => {
          return (
            menuData?.isVisible && (
              <li
                key={index}
                className="drop group flex w-18rem items-center px-2 py-3 text-sm font-normal"
                onClick={() => {
                  if (!menuData?.subMenuList?.length > 0) {
                    handleListItemClick(menuData?.path);
                  }
                }}
                onMouseEnter={() => handleMouseEnterMenu(index)}
                onMouseLeave={handleMouseLeaveMenu}
              >
                <span className="inline-block w-full">
                  <label className="ml-4 fontPoppinsRegularLg">{menuData?.text}</label>
                  {menuData?.subMenuList?.length > 0 && (
                    <ChevronRightIcon className="float-right h-5 w-5 mr-2 sub-menu-icon" aria-hidden="true" />
                  )}
                </span>
                {menuData?.subMenuList?.length > 0 &&
                  openMenuIndex === index &&
                  renderSubMenuListItem(menuData?.type, menuData?.subMenuList, route, index)}
              </li>
            )
          );
        })}
      </ul>
    );
  };
  const renderSubMenuListItem = (type, subMenuData, route, menuId) => {
    return (
      <ul
        className={cx(
          'inner origin-top-right py-2 absolute -top-2 left-0.5 w-64 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none  z-10',
          SideMenuRouterStyle['templete-conatiner']
        )}
        onMouseEnter={handleMouseEnterSubmenu}
        onMouseLeave={() => handleMouseLeaveSubmenu(menuId)}
      >
        {subMenuData?.map((data, index) => {
          return (
            data?.isVisible && (
              <li
                key={index}
                onClick={() => handleListItemClick(data.path)}
                className="group left_navigation flex items-center px-5 py-3 fontPoppinsRegularLg"
              >
                {data.text}
              </li>
            )
          );
        })}
      </ul>
    );
  };

  const applyConfigMenuStyle = () => {
    const individualProjectConfigMenu = JSON?.parse(localStorage?.getItem('allowedProjects'))?.sections
      ?.individualProjects?.subFeatures;
    if (individualProjectConfigMenu?.length < 9) {
      return `bottom-${individualProjectConfigMenu?.length * 4}`;
    } else if (individualProjectConfigMenu?.length > 8) {
      return `bottom-24`;
    }
  };

  const contextMenuTab = (route, routeIndex) => {
    return (
      <div className={route.isHidden || hideMenus.includes(route.title) ? 'hidden' : ''} key={route.title}>
        <Menu as="div">
          {(props) => (
            <>
              <div>
                <Menu.Button className={`w-full ${isDisabled ? 'cursor-default' : 'cursor-pointer'}`}>
                  <span className="sr-only">{SIDE_NAVIGATION_CONSTANTS?.OPEN_OPTIONS}</span>
                  <div key={`context-menu-${route.key}`}>
                    <Tooltip title={open ? '' : route.selectedText} placement="right">
                      <ListItemIconAlignment
                        disableGutters={true}
                        className={`
                        ${!open && selectedMenu === route?.title && styles['active-drawer-width-close']}
                        ${
                          selectedMenu === route?.title
                            ? open
                              ? styles['active-drawer-open']
                              : styles['active-drawer-close']
                            : ''
                        }
                        ${isDisabled ? styles['side-menu-list-disable'] : styles['side-menu-list-enable']}
                        ${open && selectedMenu !== route?.title ? styles['side-menu-list-animation'] : ''}`}
                        key={route.key}
                        selected={selectedMenu === route?.title}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            justifyContent: 'center',
                            marginLeft: '16px',
                          }}
                        >
                          {
                            <SideMenuTitleIconsRenderer
                              isSelected={selectedMenu === route?.title}
                              title={route.selectedText}
                            />
                          }
                        </ListItemIcon>
                        {open && <ListItemText primary={route.selectedText} sx={{ opacity: open ? 1 : 0 }} />}
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            justifyContent: 'center',
                            marginLeft: 0,
                          }}
                        >
                          <ChevronRightIcon className="float-right h-5 w-5 mr-4" aria-hidden="true" />
                        </ListItemIcon>
                      </ListItemIconAlignment>
                    </Tooltip>
                  </div>
                </Menu.Button>
              </div>

              <Transition
                show={licenseStatus === LICENSE_EXPIRED_CONSTANTS?.ACTIVE && props.open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className={`rounded-md fixed w-18rem bg-rs-primary ${
                    isProjectSelected ? applyConfigMenuStyle() : 'top-40 mt-11'
                  } 
                  ${isProjectSelected && applyConfigMenuStyle()?.includes('32') ? '-mt-28' : ''}
                  ${open ? 'mx-52' : 'mx-20'}`}
                >
                  <div className={`py-1`}>
                    <Menu.Item>
                      {({ active }) => (
                        <div className={route.isHidden ? 'hidden' : ''}>
                          <div className="configMenu py-1">{renderMenuListItem(route)}</div>
                        </div>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    );
  };

  return (
    <>
      {showWebserviceWarningModal && <WebserviceWarningModal handleWebserviceModal={handleWebServiceModal} />}
      <DataContext.Provider value={{ webserviceData, setWebserviceData }}>
        <div className="page-body margin-top-px49 fixed w-full">
          <CssBaseline />
          <Drawer theme={theme} variant="permanent" open={open}>
            <BreadcrumbsComponent isDrawerCollapsed={open} />
            <DrawerHeader theme={theme}>
              <LogoComponent open={open} handleDrawerClose={handleDrawerClose} />
            </DrawerHeader>

            <List>
              <SearchProjectsComponent isDrawerOpen={open} />
              {!isPremissionLoading &&
                SidebarData.map((route, index) => {
                  if (
                    route.title === SIDE_NAVIGATION_CONSTANTS?.CONFIGURATION &&
                    !global.permission.isNoAccess(route.moduleName)
                  ) {
                    return contextMenuTab(route, index);
                  } else {
                    if (!global.permission.isNoAccess(route.moduleName)) {
                      return (
                        <Tooltip key={route.key} title={open ? '' : route.selectedText} placement="right">
                          <div
                            className={`
                        ${route.isHidden || hideMenus.includes(route.title) ? 'hidden' : 'block'}`}
                          >
                            <ListItem
                              disableGutters={true}
                              className={`
                          ${
                            selectedMenu === route?.title
                              ? open
                                ? styles['active-drawer-open']
                                : styles['active-drawer-close']
                              : ''
                          }
                          ${!open && selectedMenu === route?.title && styles['active-drawer-width-close']}
                        ${open && selectedMenu !== route?.title ? styles['side-menu-list-animation'] : ''}
                           ${
                             isDisabled && route.selectedText !== 'Licenses'
                               ? styles['side-menu-list-disable']
                               : styles['side-menu-list-enable']
                           }
                        `}
                              key={route.key}
                              selected={selectedMenu === route?.title}
                              onClick={() => handleListItemClick(getDefaultPath(route?.key) || route.path)}
                            >
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  justifyContent: 'center',
                                }}
                              >
                                {
                                  <SideMenuTitleIconsRenderer
                                    isSelected={selectedMenu === route?.title}
                                    title={route.selectedText}
                                  />
                                }
                              </ListItemIcon>
                              <ListItemText primary={route.selectedText} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItem>
                          </div>
                        </Tooltip>
                      );
                    }
                  }
                })}
            </List>
            <Typography>
              <span className={open ? styles['fireflink_rights'] : styles['docked-drawer']}>
                {open ? LAYOUT_CONSTANTS.FIREFLINK_RIGHTS : LAYOUT_CONSTANTS.CLOSE_VIEW_RIGHTS}
              </span>
            </Typography>
            <Switch>
              {!isPremissionLoading &&
                SidebarData.map((route, index) => {
                  if (!global.permission.isNoAccess(route.moduleName)) {
                    return <Route key={`router0-${index}-${route.key}`} path={route.path} exact={route.exact} />;
                  }
                })}
            </Switch>
          </Drawer>
          <div className={`sidebar_menu_padding ${navOpen ? 'w-84per' : 'w-94per'} main-container`}>
            <Switch>
              <ProtectedRoute
                path={[
                  '/licenses/fireflink-license/buy',
                  '/licenses/:licenseId/FireFlink License/upgrade-and-renew',
                  '/licenses/:licenseId/FireFlink License/renew',
                  '/licenses/:licenseId/FireFlink License/upgrade',
                ]}
              >
                <LicenseForm />
              </ProtectedRoute>
              {!isPremissionLoading &&
                SidebarData.map((route, index) => {
                  if (!global.permission.isNoAccess(route.moduleName)) {
                    return (
                      <Route
                        key={`router0-${index}-${route.key}`}
                        path={route.path}
                        exact={route.exact}
                        children={<route.main />}
                      />
                    );
                  }
                })}
              <Route path="/configuration/environmentconfiguration" exact={true} strict={true} key="system-config">
                <SystemConfigRouter />
              </Route>
              <Route path="/configuration" exact={true} strict={true} key="system-config">
                <SystemConfigRouter />
              </Route>
            </Switch>
          </div>
        </div>
      </DataContext.Provider>
      {/* Chatbot component */}
      {chatbotPrivilege && (
        <>
          <ChatbotOpener setShowChatbot={setShowChatbot} showChatbot={showChatbot} hide={hide} setHide={setHide} />
          <Chatbot setShowChatbot={setShowChatbot} showChatbot={showChatbot} hide={hide} setHide={setHide} />
        </>
      )}
    </>
  );
}

export default memo(SideNavigationComponent);

import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';
import SystemConfigRouter from '../configuration/system_config/system_config_router';
import PluginRouter from '../configuration/plugin/plugin_router';
import WaitConfigRouter from '../configuration/wait_config/wait_config_router';
import WaitConfigHistory from '../configuration/wait_config/WaitHistory';
import Screen_video_config from '../configuration/Screen_video_config/Screen_video_config';
import Screen_history from '../configuration/Screen_video_config/Screen_history';
import ElementConfig from '../configuration/ElementConfig/ElementConfig';
import Android_config from '../configuration/ElementConfig/Android_config';
import IosConfig from '../configuration/ElementConfig/IosConfig';
import SfdcConfig from '../configuration/ElementConfig/SfdcConfig';
import Web from '../configuration/ElementConfig/Locators/web';
import Locator_android from '../configuration/ElementConfig/Locators/Locator_android';
import Locator_ios from '../configuration/ElementConfig/Locators/Locator_ios';
import Result_config from '../configuration/Result_config/Result_config';
import ResultHistory from '../configuration/Result_config/ResultHistory';
import HistoryConfig from '../configuration/Result_config/ResultHistory/HistoryConfig';
import Re_History from '../configuration/Result_config/ResultHistory/Re_History';
import ViewConfig from '../configuration/View&Custom/ViewConfig';
import ViewHistory from '../configuration/View&Custom/ViewHistory';
import Date_Time_config from '../configuration/Date&TimeConfig/Date_Time_config';
import DateHistory from '../configuration/Date&TimeConfig/DateHistory';
import EmailHistory from '../configuration/EmailConfig/EmailHistory';
import Email_group from './EmailConfig/Email_group';
import MemoryConfig from '../configuration/Memory_Config/MemoryConfig';
import Memory_history from '../configuration/Memory_Config/Memory_history';
import ProjectEnv from '../configuration/Variables/ProjectEnv';
import ConfigVariable from '../configuration/Variables/ConfigVariable';
import ConfigExtLibrary from '../configuration/ExternalLibrary/ExternalLibrary';
import ConfigLibraryHistory from '../configuration/ExternalLibrary/ConfigLibraryHistory';
import SfdcLocator from '../configuration/ElementConfig/Locators/SfdcLocator';
import VariablesHistory from '../configuration/Variables/VariablesHistory';
import TCTemplatePage from '../template/testcase_management/tc_template_page';
import ElementCreation from '../template/element_creation_page';
import ViewTemplate from '../template/view_template';
import TemplateCreation from '../template/testcase_management/template_creation';
import DefectTemplate from '../template/defect_management/defect_template';
import ImportAndExportLandingPage from '../configuration/ImportAndExport/ImportAndExportLandingPage';
import ConfigLabels from '../configuration/Variables/ConfigLabels';
import TimeZoneConfig from './TimeZone/TimeZoneConfig';
import DefectManagement from '@pages/configuration/DefectConfig/DefectManagement';

function ConfigRouter(props) {
  let location = props.location.pathname;
  if (
    ['/configuration', '/configuration/environmentconfiguration', '/configuration/RunConfiguration'].includes(location)
  ) {
    props.history.push('/configuration/environmentconfiguration/systemconfiguration');
  } else if (location === '/configuration/emailConfiguration') {
    props.history.push('/configuration/emailConfiguration/emailGroup');
  } else if (location === '/configuration/Configurationvariables') {
    props.history.push('/configuration/Configurationvariables/Projectenvironmentvariable');
  } else if (location === '/configuration/Label') {
    props.history.push('/configuration/Label/Labels');
  }

  return (
    <div>
      {location.includes('systemconfiguration') || location.includes('environmentconfiguration') ? (
        <SystemConfigRouter />
      ) : location.includes('integration') ? (
        <PluginRouter />
      ) : null}

      <Switch>
        <Route exact path="/configuration/RunConfiguration/Waitconfiguration" component={WaitConfigRouter} />
        <Route
          exact
          path="/configuration/RunConfiguration/Screenshot&Videoconfiguration"
          component={Screen_video_config}
        />
        <Route exact path="/configuration/ResultConfiguration" component={Result_config} />
        <Route exact path="/configuration/emailConfiguration/emailGroup" component={Email_group} />
        <Route exact path="/configuration/memoryConfiguration" component={MemoryConfig} />
        <Route exact path="/configuration/defectManagement" component={DefectManagement} />
        <Route exact path="/configuration/Configurationvariables/Projectenvironmentvariable" component={ProjectEnv} />
        <Route exact path="/configuration/Label/Labels" component={ConfigLabels} />
        <Route exact path="/configuration/TimeZone" component={TimeZoneConfig} />
        <Route exact path="/configuration/Configurationvariables/Global Variables" component={ConfigVariable} />
        <Route exact path="/configuration/template" component={TCTemplatePage} />
        <Route exact path="/configuration/template/elementcreation" component={ElementCreation} />
        <Route exact path="/configuration/template/viewtemplate" component={ViewTemplate} />
        <Route exact path="/configuration/template/templatecreation" component={TemplateCreation} />
        <Route path="/configuration/template/defecttemplate" component={DefectTemplate} />
        <Route path="/configuration/import-export/" component={ImportAndExportLandingPage} />
        {/* <Route exact path="/configuration/import-export/Export" component={ImportAndExportLandingPage} /> */}
        {/* Below routes are required for future
        <Route exact path="/configuration/RunConfiguration/History" component={WaitConfigHistory} />
        <Route exact path="/configuration/RunConfiguration/Screenshot&Videoconfiguration/History" component={Screen_history} />
        <Route exact path="/configuration/ElementConfiguration/ElementType/Web" component={ElementConfig} />
        <Route exact path="/configuration/ElementConfiguration/ElementType/android" component={Android_config} />
        <Route exact path="/configuration/ElementConfiguration/ElementType/ios" component={IosConfig} />
        <Route exact path="/configuration/ElementConfiguration/Locators/web" component={Web} />
        <Route exact path="/configuration/ElementConfiguration/Locators/android" component={Locator_android} />
        <Route exact path="/configuration/ElementConfiguration/ElementType/sfdc" component={SfdcConfig} />
        <Route exact path="/configuration/ElementConfiguration/Locators/ios" component={Locator_ios} />
        <Route exact path="/configuration/Result&HistoryConfiguration/History" component={ResultHistory} />
        <Route exact path="/configuration/Result&HistoryConfiguration/HistoryConfiguration" component={HistoryConfig} />
        <Route exact path="/configuration/Result&HistoryConfiguration/HistoryConfiguration/History" component={Re_History} />
        <Route exact path="/configuration/view&CustomfieldConfiguration/createView/projectElement" component={ViewConfig} />
        <Route exact path="/configuration/view&CustomfieldConfiguration/createView/projectElement/history" component={ViewHistory} />
        <Route exact path="/configuration/date&TimeConfiguration" component={Date_Time_config} />
        <Route exact path="/configuration/date&TimeConfiguration/history" component={DateHistory} />
        <Route exact path="/configuration/emailConfiguration/history" component={EmailHistory} />
        <Route exact path="/configuration/memoryConfiguration/history" component={Memory_history} />
        <Route exact path="/configuration/Externallibraries/ExternalLibraries" component={ConfigExtLibrary} />
        <Route exact path="/configuration/Externallibraries/history" component={ConfigLibraryHistory} />
        <Route exact path="/configuration/ElementConfiguration/Locators/Sfdc" component={SfdcLocator} />
        <Route exact path="/configuration/variables/history" component={VariablesHistory} />
        */}
      </Switch>
    </div>
  );
}

export default withRouter(ConfigRouter);

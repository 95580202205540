import React, { useEffect, useState, useContext, memo } from 'react';
import { downloadVisualTestingElementReq, getElementReq, getSharedElementReq } from '@api/api_services';
import ReactTable from '@src/pages/common/Table/ReactTable';
import {
  LOCATOR_DETAILS_TABLE_CONSTANTS,
  trimPath,
  VISUAL_TESTING_IMAGE_FILE_EXTENTION,
} from '@src/common/ui-constants';
import { Tooltip } from '@material-ui/core';
import { CommonLoader } from '@src/pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { saveFileFromBlob } from '@src/pages/test-development/shared/common-methods';
import { getFileExtension, getLocatorsType } from '@src/util/common_utils';

function ElementDetailsPage(props) {
  const [elementData, setElementData] = useState();
  const [locatorsDetails, setLocatorsDetails] = useState([]);
  const [selectedLocatorTab, setSelectedLocatorTab] = useState('');
  const [iosLocators, setIosLocators] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCopyToClipBoard, setIsCopyToClipBoard] = useState(false);
  const { resetProgress, startOrResumeProgress } = useContext(ProgressContainerContext);
  const search = window.location.search;
  let project = JSON.parse(localStorage.getItem('selected-project'));
  const pageKey = new URLSearchParams(search).get('pageId');
  const elementKey = new URLSearchParams(search).get('eleId');
  const isSharedEle = new URLSearchParams(search).get('isShared');
  const mobilePlatforms = ['Android', 'iOS', 'Mobile'];
  useEffect(() => {
    if (props?.isShared || isSharedEle === 'Y') {
      getSharedElementDetails(elementKey);
    } else {
      getElementDetails(pageKey, elementKey);
    }
  }, [elementKey]);

  let path;
  if (elementData) {
    path = trimPath(elementData?.path);
  }
  const getElementDetails = (pageKey, elementKey) => {
    resetProgress();
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 80,
    });
    setIsLoading(true);
    getElementReq(pageKey, elementKey).then((result) => {
      if (result.data.responseObject) {
        let responseObject = result.data.responseObject;
        if (responseObject) {
          const responseObject = result.data.responseObject;
          const { locators } = responseObject;
          let updatedLocators = [];
          locators?.forEach((locator) => {
            if (['static', 'Static'].includes(locator.type)) {
              updatedLocators.push(locator);
            } else if (VISUAL_TESTING_IMAGE_FILE_EXTENTION.includes(locator.type)) {
              locator.type = getFileExtension(locator.name);
              updatedLocators.push(locator);
            } else if (['dynamic', 'Dynamic'].includes(locator.type)) {
              let value = locator.value;
              locator.value = value.replaceAll('dynamic', 'Dynamic Val');
              updatedLocators.push(locator);
            }
          });
          responseObject.locators = updatedLocators;
        }
        setElementData(responseObject);
        setSelectedLocatorTab(responseObject?.platform === 'Mobile' ? 'Android' : '');
        assignLocators(responseObject.locators, responseObject.platform, responseObject);
        resetProgress();
        setIsLoading(false);
      }
    });
  };
  const getSharedElementDetails = (elementKey) => {
    resetProgress();
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 80,
    });
    setIsLoading(true);
    getSharedElementReq(elementKey).then((result) => {
      if (result.data?.responseObject) {
        setElementData(result.data.responseObject);
        setSelectedLocatorTab(result.data.responseObject?.platform === 'Mobile' ? 'Android' : '');
        assignLocators(
          result.data.responseObject.locators,
          result.data.responseObject.platform,
          result.data?.responseObject
        );
        resetProgress();
        setIsLoading(false);
      }
    });
  };
  const assignLocators = (locators, platform, allLocatorData) => {
    let array = [];
    let iosList = [];
    let extension;
    let LocatorType;
    locators.forEach((data) => {
      extension = getFileExtension(data.name);
      if (VISUAL_TESTING_IMAGE_FILE_EXTENTION.includes(extension)) {
        LocatorType = getLocatorsType(extension);
      } else {
        LocatorType = getLocatorsType(data?.type);
      }
      if (platform === 'Mobile' && data?.os?.toLowerCase() === 'ios') {
        iosList.push({
          locatorType: data.name,
          dataType: LocatorType,
          value: data.value,
          identified: data?.isRecorded === 'N' ? 'Manual' : 'plugingenerated',
          defaultAddedFirst: data.defaultAddedFirst,
          defaultAddedSecond: data.defaultAddedSecond,
          imagePath: data?.testData ? 'From Test Data' : 'From Local Path',
          filePath: data.filePath,
          os: data.os,
        });
      } else {
        array.push({
          locatorType: data.name,
          dataType: LocatorType,
          value: data.value,
          identified: data?.isRecorded === 'N' ? 'Manual' : 'plugingenerated',
          defaultAddedFirst: data.defaultAddedFirst,
          defaultAddedSecond: data.defaultAddedSecond,
          imagePath: allLocatorData?.testData ? 'From Test Data' : 'From Local Path',
          filePath: data.filePath,
        });
      }
    });
    setLocatorsDetails(array);
    setIosLocators(iosList);
  };
  const renderCopyIcon = (value) => {
    const toolTipForCopied = () => {
      setIsCopyToClipBoard(true);
      setTimeout(() => {
        setIsCopyToClipBoard(false);
      }, 1000);
    };
    return (
      <Tooltip title={isCopyToClipBoard ? 'Copied!' : 'Copy'}>
        <ContentCopyIcon
          className="cursor-pointer"
          onClick={async () => {
            if (navigator?.clipboard) {
              toolTipForCopied();
              await navigator.clipboard?.writeText(value);
            } else {
              toolTipForCopied();
              await window?.navigator?.clipboard?.writeText(value);
            }
          }}
        />
      </Tooltip>
    );
  };
  const androidLocators = React.useMemo(() => locatorsDetails, [locatorsDetails]);
  const columns = React.useMemo(
    () => [
      {
        Header: 'Locator Type',
        accessor: 'locatorType',
        width: '15%',
        Cell: (e) => {
          let data = e.row.original;
          let value = e.value;
          if (data.defaultAddedFirst === true) {
            value = 'Flinko Id';
          } else if (data.defaultAddedSecond === true) {
            value = 'Flinko Id';
          }
          return (
            <div className="name-trimmer" title={value}>
              {' '}
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Value Type',
        accessor: 'dataType',
        width: '15%',
      },
      {
        Header: 'Locator Value',
        accessor: 'value',
        width: '60%',
        Cell: (e) => {
          let value1 = Math.floor(100000 + Math.random() * 900000);
          let value2 = Math.floor(100000 + Math.random() * 900000);
          let data = e.row.original;
          let value = e.value;
          if (data.defaultAddedFirst === true) {
            value = `FLK-ID-${value1}`;
          } else if (data.defaultAddedSecond === true) {
            value = `FLK-ID-${value2}`;
          }
          return (
            <div className="max-w-md truncate" title={value}>
              {' '}
              {value}
            </div>
          );
        },
      },
      {
        Header: '',
        accessor: 'identified',
        width: '10%',
        Cell: ({ value, row }) => {
          if (value === 'Manual') {
            return (
              <div className="flex gap-6 flex-row mr-3">
                <Tooltip title="Manually added locator" placement="bottom">
                  <svg
                    id="perm_identity_black_24dp"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path id="Path_444" data-name="Path 444" d="M0,0H24V24H0Z" fill="none" />
                    <path
                      id="Path_445"
                      data-name="Path 445"
                      d="M12,6a2,2,0,1,1-2,2,2.006,2.006,0,0,1,2-2m0,9c2.7,0,5.8,1.29,6,2v1H6v-.99C6.2,16.29,9.3,15,12,15M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,9c-2.67,0-8,1.34-8,4v3H20V17C20,14.34,14.67,13,12,13Z"
                      fill="#3c3838"
                    />
                  </svg>
                </Tooltip>
                {renderCopyIcon(row?.original?.value)}
              </div>
            );
          } else {
            return (
              <div className="flex gap-6 flex-row mr-3">
                <Tooltip title="Locator added through plugin">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26.783" viewBox="0 0 24 26.783">
                    <g id="extension_black_24dp" transform="translate(0 2.783)">
                      <path id="Path_442" data-name="Path 442" d="M0,0H24V24H0Z" fill="none" />
                      <g id="dvr_black_24dp" transform="translate(0 -2.783)">
                        <path id="Path_458" data-name="Path 458" d="M0,0H24V24H0Z" fill="none" />
                        <path
                          id="Path_459"
                          data-name="Path 459"
                          d="M21,3H3A2.006,2.006,0,0,0,1,5V17a2.006,2.006,0,0,0,2,2H8v2h8V19h5a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,21,3Zm0,14H3V5H21ZM19,8H8v2H19Zm0,4H8v2H19ZM7,8H5v2H7Zm0,4H5v2H7Z"
                          fill="#3c3838"
                        />
                      </g>
                    </g>
                  </svg>
                </Tooltip>
                {renderCopyIcon(row?.original?.value)}
              </div>
            );
          }
        },
      },
    ],
    [isCopyToClipBoard]
  );

  const columnsForImage = React.useMemo(
    () => [
      {
        Header: 'Image Name',
        accessor: 'locatorType',
        width: '20%',
        Cell: (e) => {
          let data = e.row.original;
          let value = e.value;
          if (data.defaultAddedFirst === true) {
            value = 'Flinko Id';
          } else if (data.defaultAddedSecond === true) {
            value = 'Flinko Id';
          }
          return (
            <div className="name-trimmer" title={value}>
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Image Type',
        accessor: 'dataType',
        width: '15%',
      },
      {
        Header: 'Image Path',
        accessor: 'imagePath',
        width: '50%',
      },
      {
        Header: '',
        accessor: 'filePath',
        width: '7%',
        Cell: (e) => {
          let filePath = e.value;
          let data = e.row.original;
          let fileName = data.locatorType;
          return (
            <div className="flex flex-row text-right">
              <Tooltip title="Download" placement="left-end">
                <button className="add-btn ">
                  <img
                    onClick={() => {
                      downloadVisualLocatorImage(filePath, fileName);
                    }}
                    className="more-button"
                    src="/assets/images/file_download.svg"
                    alt="DownloadImage"
                    height="25px"
                    width="25px"
                  />
                </button>
              </Tooltip>
            </div>
          );
        },
      },
      {
        Header: '',
        accessor: 'identified',
        width: '8%',
        Cell: ({ value }) => {
          if (value === 'Manual') {
            return (
              <div className="flex flex-row mr-10">
                <Tooltip title="Manually added locator" placement="top">
                  <svg
                    id="perm_identity_black_24dp"
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 24 24"
                  >
                    <path id="Path_444" data-name="Path 444" d="M0,0H24V24H0Z" fill="none" />
                    <path id="Path_444" data-name="Path 444" d="M0,0H24V24H0Z" fill="none" />
                    <path
                      id="Path_445"
                      data-name="Path 445"
                      d="M12,6a2,2,0,1,1-2,2,2.006,2.006,0,0,1,2-2m0,9c2.7,0,5.8,1.29,6,2v1H6v-.99C6.2,16.29,9.3,15,12,15M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,9c-2.67,0-8,1.34-8,4v3H20V17C20,14.34,14.67,13,12,13Z"
                      fill="#3c3838"
                    />
                  </svg>
                </Tooltip>
              </div>
            );
          } else {
            return (
              <div className="flex flex-row ml-10">
                <Tooltip title="Locator added through plugin" placement="top">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26.783" viewBox="0 0 24 26.783">
                    <g id="extension_black_24dp" transform="translate(0 2.783)">
                      <path id="Path_442" data-name="Path 442" d="M0,0H24V24H0Z" fill="none" />
                      <path id="Path_442" data-name="Path 442" d="M0,0H24V24H0Z" fill="none" />
                      <g id="dvr_black_24dp" transform="translate(0 -2.783)">
                        <path id="Path_458" data-name="Path 458" d="M0,0H24V24H0Z" fill="none" />
                        <path id="Path_458" data-name="Path 458" d="M0,0H24V24H0Z" fill="none" />
                        <path
                          id="Path_459"
                          data-name="Path 459"
                          d="M21,3H3A2.006,2.006,0,0,0,1,5V17a2.006,2.006,0,0,0,2,2H8v2h8V19h5a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,21,3Zm0,14H3V5H21ZM19,8H8v2H19Zm0,4H8v2H19ZM7,8H5v2H7Zm0,4H5v2H7Z"
                          fill="#3c3838"
                        />
                      </g>
                    </g>
                  </svg>
                </Tooltip>
              </div>
            );
          }
        },
      },
    ],
    []
  );

  const downloadVisualLocatorImage = (filepath, filename) => {
    let payload = {
      contentType: '',
      filePath: [],
    };
    payload.filePath.push(filepath);

    downloadVisualTestingElementReq(payload)
      .then((response) => {
        if (response && response.data) {
          saveFileFromBlob(response.data, `${filename}`);
        } else {
          console.error('Error: Empty or invalid response data');
        }
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  };

  let returnname = (nameArray) => {
    let names = [];
    for (let i = 0; i < nameArray.length; i++) {
      names.push(nameArray[i].name);
    }
    return names.toString();
  };

  if (isLoading) {
    return <CommonLoader />;
  }

  return (
    <div className="page-table-body">
      <div className="content-wrapper">
        <div className="content_area_style" id="journal-scroll">
          {elementData ? (
            <>
              <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
                <div className="project_label text-sm flex-auto title-trim" title={elementData.name}>
                  {elementData.name}
                </div>
              </div>
              <hr />
              <div className="table-height overflow-auto " id="journal-scroll">
                <div className="grid grid-cols-3">
                  <div className="ml-5">
                    <div className="grid-rows-4 ml-4">
                      <div className="">
                        <div className="mt-8">
                          <h1 className="details-key-style-common">{LOCATOR_DETAILS_TABLE_CONSTANTS.MODAL_NAME}</h1>
                          <Tooltip title={elementData.name ? elementData.name : '--'}>
                            <p className="pt-2 text-gray-700">
                              <div className="details-data-style-common name-trimmer">
                                {elementData.name ? elementData.name : '--'}
                              </div>
                            </p>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="">
                        <div className="mt-10">
                          <h1 className="details-key-style-common">{LOCATOR_DETAILS_TABLE_CONSTANTS.PROJECT_NAME}</h1>
                          <div className="pt-2 text-gray-700">
                            <Tooltip title={project.name}>
                              <div className="details-data-style-common project-name-trimmer">{project.name}</div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      <div className=""></div>
                      <div className=""></div>
                    </div>
                  </div>
                  <div className="ml-24">
                    <div className="grid-rows-4 ">
                      <div className="">
                        <div className="mt-8 ">
                          <h1 className="details-key-style-common">{LOCATOR_DETAILS_TABLE_CONSTANTS.MODAL_TYPE}</h1>
                          <p className="pt-2 text-gray-700">
                            <span className="details-data-style-common capitalize">
                              {elementData.type ? elementData.type : '--'}
                            </span>
                          </p>
                        </div>
                      </div>
                      {props?.isShared ? (
                        <div className="">
                          <div className="mt-10">
                            <h1 className="details-key-style-common">
                              {LOCATOR_DETAILS_TABLE_CONSTANTS.SHARED_BW_PAGES}
                            </h1>
                            <div className="pt-2 text-gray-700">
                              <div
                                className="details-data-style-common path-trimmer"
                                title={elementData.pageIdsNames ? returnname(elementData.pageIdsNames) : '--'}
                              >
                                {path ? returnname(elementData.pageIdsNames) : '--'}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="">
                          <div className="mt-10">
                            <h1 className="details-key-style-common">{LOCATOR_DETAILS_TABLE_CONSTANTS.PATH}</h1>
                            <Tooltip title={path ? path : '--'}>
                              <div className="pt-2 text-gray-700">
                                <div className="details-data-style-common path-trimmer">{path ? path : '--'}</div>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      )}
                      <div className=""> </div>
                    </div>
                  </div>
                  <div className="ml-24">
                    <div className="grid-rows-3 ml-20">
                      <div className="">
                        <div className="mt-8 ">
                          <h1 className="details-key-style-common">{LOCATOR_DETAILS_TABLE_CONSTANTS.DESCRIPTION}</h1>
                          <div className="pt-2 text-gray-700">
                            <div
                              className="details-data-style-common desc"
                              title={elementData.desc ? elementData.desc : '--'}
                            >
                              {elementData.desc ? elementData.desc : '--'}{' '}
                            </div>
                          </div>
                        </div>
                      </div>
                      {props?.isShared ? (
                        <div className="">
                          <div className="mt-10">
                            <h1 className="details-key-style-common">{LOCATOR_DETAILS_TABLE_CONSTANTS.PATH}</h1>
                            <Tooltip title={path ? path : '--'}>
                              <div className="pt-2 text-gray-700">
                                <div className="details-data-style-common path-trimmer">{path ? path : '--'}</div>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      ) : (
                        <div className="">
                          <div className="mt-10 ">
                            <h1 className="details-key-style-common">
                              {LOCATOR_DETAILS_TABLE_CONSTANTS.LOCATORS_COUNT}
                            </h1>
                            <div className="pt-2 text-gray-700">
                              <div className="details-data-style-common desc">
                                {elementData.locatorsCount ? elementData.locatorsCount : '--'}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className=""> </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-3">
                  <div className="ml-5">
                    <div className="grid-rows-4 ml-4">
                      {props?.isShared ? (
                        <div className="">
                          <div className="mt-10 ">
                            <h1 className="details-key-style-common">
                              {LOCATOR_DETAILS_TABLE_CONSTANTS.LOCATORS_COUNT}
                            </h1>
                            <div className="pt-2 text-gray-700">
                              <div className="details-data-style-common desc">
                                {elementData.locatorsCount ? elementData.locatorsCount : '--'}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="">
                          <div className="mt-8">
                            <h1 className="details-key-style-common">{LOCATOR_DETAILS_TABLE_CONSTANTS.CREATED_BY}</h1>
                            <Tooltip title={elementData.createdByUname ? elementData.createdByUname : '--'}>
                              <p className="pt-2 text-gray-700">
                                <div className="details-data-style-common name-trimmer">
                                  {elementData.createdByUname ? elementData.createdByUname : '--'}
                                </div>
                              </p>
                            </Tooltip>
                          </div>
                        </div>
                      )}
                      {props?.isShared ? (
                        <>
                          <div className="">
                            <div className="mt-8 ">
                              <h1 className="details-key-style-common">
                                {LOCATOR_DETAILS_TABLE_CONSTANTS.MODIFIED_BY}
                              </h1>
                              <Tooltip title={elementData.modifiedByUname ? elementData.modifiedByUname : '--'}>
                                <div className="pt-2 text-gray-700">
                                  <div className="details-data-style-common desc">
                                    {elementData.modifiedByUname ? elementData.modifiedByUname : '--'}{' '}
                                  </div>
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                          {mobilePlatforms?.includes(elementData?.platform) ? (
                            <div className="">
                              <div className="mt-8 ">
                                <h1 className="details-key-style-common">
                                  {LOCATOR_DETAILS_TABLE_CONSTANTS.IOS_LOCATORS_COUNT}
                                </h1>
                                <p className="pt-2 text-gray-700">
                                  <span className="details-data-style-common">
                                    {elementData?.ioscount ? elementData?.ioscount : '--'}
                                  </span>
                                </p>
                              </div>
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <div className="">
                          <div className="mt-10">
                            <h1 className="details-key-style-common">{LOCATOR_DETAILS_TABLE_CONSTANTS.MODIFIED_ON}</h1>
                            <div className="pt-2 text-gray-700">
                              <div className="details-data-style-common " title={elementData.modifiedOn}>
                                {elementData.modifiedOn}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className=""></div>
                      <div className=""></div>
                    </div>
                  </div>
                  <div className="ml-24">
                    <div className="grid-rows-3 ">
                      {props?.isShared ? (
                        <div className="">
                          <div className="mt-8">
                            <h1 className="details-key-style-common">{LOCATOR_DETAILS_TABLE_CONSTANTS.CREATED_BY}</h1>
                            <p className="pt-2 text-gray-700">
                              <div
                                className="details-data-style-common name-trimmer"
                                title={elementData.createdByUname ? elementData.createdByUname : '--'}
                              >
                                {elementData.createdByUname ? elementData.createdByUname : '--'}
                              </div>
                            </p>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="">
                            <div className="mt-8 ">
                              <h1 className="details-key-style-common">{LOCATOR_DETAILS_TABLE_CONSTANTS.CREATED_ON}</h1>
                              <p className="pt-2 text-gray-700">
                                <span className="details-data-style-common">
                                  {elementData.createdOn ? elementData.createdOn : '--'}
                                </span>
                              </p>
                            </div>
                          </div>
                          {mobilePlatforms?.includes(elementData?.platform) ? (
                            <div className="">
                              <div className="mt-8 ">
                                <h1 className="details-key-style-common">
                                  {LOCATOR_DETAILS_TABLE_CONSTANTS.ANDROID_LOCATORS_COUNT}
                                </h1>
                                <p className="pt-2 text-gray-700">
                                  <span className="details-data-style-common">
                                    {elementData?.androidCount ? elementData?.androidCount : '--'}
                                  </span>
                                </p>
                              </div>
                            </div>
                          ) : null}
                        </>
                      )}
                      {props?.isShared ? (
                        <div className="">
                          <div className="mt-10">
                            <h1 className="details-key-style-common">{LOCATOR_DETAILS_TABLE_CONSTANTS.MODIFIED_ON}</h1>
                            <div className="pt-2 text-gray-700">
                              <div className="details-data-style-common " title={elementData.modifiedOn}>
                                {elementData.modifiedOn}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className="">
                        <div className="mt-10"></div>
                      </div>
                      <div className=""> </div>
                    </div>
                  </div>
                  <div className="ml-24">
                    <div className="grid-rows-3 ml-20">
                      {props?.isShared ? (
                        <>
                          <div className="">
                            <div className="mt-8 ">
                              <h1 className="details-key-style-common">{LOCATOR_DETAILS_TABLE_CONSTANTS.CREATED_ON}</h1>
                              <p className="pt-2 text-gray-700">
                                <span className="details-data-style-common">
                                  {elementData.createdOn ? elementData.createdOn : '--'}
                                </span>
                              </p>
                            </div>
                          </div>
                          {mobilePlatforms?.includes(elementData?.platform) ? (
                            <div className="">
                              <div className="mt-8 ">
                                <h1 className="details-key-style-common">
                                  {LOCATOR_DETAILS_TABLE_CONSTANTS.ANDROID_LOCATORS_COUNT}
                                </h1>
                                <p className="pt-2 text-gray-700">
                                  <span className="details-data-style-common">
                                    {elementData?.androidCount ? elementData?.androidCount : '--'}
                                  </span>
                                </p>
                              </div>
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <div className="">
                            <div className="mt-8 ">
                              <h1 className="details-key-style-common">
                                {LOCATOR_DETAILS_TABLE_CONSTANTS.MODIFIED_BY}
                              </h1>
                              <div className="pt-2 text-gray-700">
                                <Tooltip title={elementData.modifiedByUname ? elementData.modifiedByUname : '--'}>
                                  <div className="details-data-style-common desc">
                                    {elementData.modifiedByUname ? elementData.modifiedByUname : '--'}{' '}
                                  </div>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                          {mobilePlatforms?.includes(elementData?.platform) ? (
                            <div className="">
                              <div className="mt-8 ">
                                <h1 className="details-key-style-common">
                                  {LOCATOR_DETAILS_TABLE_CONSTANTS.IOS_LOCATORS_COUNT}
                                </h1>
                                <p className="pt-2 text-gray-700">
                                  <span className="details-data-style-common">
                                    {elementData?.ioscount ? elementData?.ioscount : '--'}
                                  </span>
                                </p>
                              </div>
                            </div>
                          ) : null}
                        </>
                      )}
                      <div className="">
                        <div className="mt-10 "></div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="divider mt-5" />
                <div className="loactors-table mt-4 mb-5 ml-8 mr-8">
                  <div className="mt-1 tableShadow mt-4">
                    <div className="mt-4 locatorsarray-size">
                      <p
                        className={`locatorHeader pt-4 mb-4 inline-block ${
                          selectedLocatorTab === 'Android' ||
                          (elementData.platform === 'Mobile' && selectedLocatorTab === '')
                            ? 'text-blue-700 underline'
                            : 'text-gray-700'
                        }`}
                        onClick={() => {
                          setSelectedLocatorTab(elementData.platform === 'Mobile' ? 'Android' : '');
                        }}
                      >
                        {elementData.platform === 'Mobile' ? 'Android Locators List' : 'Locators List'}
                      </p>
                      {elementData.platform === 'Mobile' && (
                        <p
                          className={`locatorHeader pt-4 mb-4 ml-6 inline-block ${
                            selectedLocatorTab === 'iOS' ? 'text-blue-700 underline' : 'text-gray-700'
                          }`}
                          onClick={() => {
                            setSelectedLocatorTab('iOS');
                          }}
                        >
                          {LOCATOR_DETAILS_TABLE_CONSTANTS.IOS_LOCATORS_LIST}
                        </p>
                      )}
                      <div className="table-height overflow-auto" id="journal-scroll">
                        <ReactTable
                          data={selectedLocatorTab === 'iOS' ? iosLocators : androidLocators}
                          columns={elementData?.type === 'visual testing' ? columnsForImage : columns}
                          tableHeightClass={'locator-table-height'}
                        />
                        {selectedLocatorTab === 'iOS' && iosLocators.length === 0 && (
                          <div className="no-locators-text">{LOCATOR_DETAILS_TABLE_CONSTANTS.NO_IOS_LOCATORS}</div>
                        )}
                        {selectedLocatorTab === 'Android' && androidLocators.length === 0 && (
                          <div className="no-locators-text">{LOCATOR_DETAILS_TABLE_CONSTANTS.NO_ANDROID_LOCATORS}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default memo(ElementDetailsPage);

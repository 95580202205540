import React from 'react';
import { LABELS_CONSTANTS } from '@src/common/ui-constants';
import CommonDrawerJs from '@pages/configuration/plugin/CommonIntegrationDrawer';
import { getTruncatedText } from '@src/util/common_utils';
import { Tooltip } from '@material-ui/core';

const ConfigUserDetails = (props) => {
  const rowUserDetails = props?.userDetails;
  return (
    <CommonDrawerJs
      isDrawerOpen={true}
      headerContent={`${LABELS_CONSTANTS.USER_DETAILS} - ${rowUserDetails?.name}`}
      drawerWidth="500px"
      leftButtonText={LABELS_CONSTANTS.CLOSE}
      isLeftButtonVisible={true}
      isRightButtonVisible={false}
      onDrawerOpen={props.onClose}
      onLeftButtonClick={props.onClose}
    >
      <div className="w-full h-full flex flex-col ">
        <div id="Journal-scroll" className="grid grid-cols-3 mt-4 pb-4 ml-6 overflow-y-auto max-h-72">
          <div className="overflow-hidden-common">
            <label
              htmlFor="userName"
              className="block label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {LABELS_CONSTANTS.NAME}
            </label>
            <Tooltip title={rowUserDetails?.name}>
              <label
                className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1"
                title={rowUserDetails?.name}
              >
                {getTruncatedText(rowUserDetails?.name, 15) || '--'}
              </label>
            </Tooltip>
          </div>
          <div className="overflow-hidden-common ml-6">
            <label
              htmlFor="userName"
              className="block label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {LABELS_CONSTANTS.EMAIL_ADDRESS}
            </label>
            <Tooltip title={rowUserDetails?.emailId}>
              <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
                {getTruncatedText(rowUserDetails?.emailId, 15) || '--'}
              </label>
            </Tooltip>
          </div>
          <div className="ml-12">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {LABELS_CONSTANTS.PRIVILEGE}
            </label>
            <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
              {props?.licenseUserDetails?.privilege || '--'}
            </label>
          </div>
          <div className="mt-6">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {LABELS_CONSTANTS.PHONE_NUMBER}
            </label>
            <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
              {' '}
              <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-200 fontPoppinsRegularSm mt-1">
                {rowUserDetails?.phoneNumbers?.primaryPhone || '--'}
              </label>
            </label>
          </div>
          <div className="mt-6 ml-6">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {LABELS_CONSTANTS.CREATED_BY}
            </label>
            <Tooltip title={rowUserDetails?.createdByUname}>
              <label className="instance-details-style fontPoppinsRegularSm">
                {rowUserDetails?.createdByUname || '--'}
              </label>
            </Tooltip>
          </div>
          <div className="mt-6 ml-12">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {LABELS_CONSTANTS.CREATED_ON}
            </label>
            <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
              {rowUserDetails?.createdOn || '--'}
            </label>
          </div>
          <div className="mt-6">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {LABELS_CONSTANTS.STATUS}
            </label>
            <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
              {' '}
              <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-200 fontPoppinsRegularSm mt-1">
                {(props?.licenseUserDetails && rowUserDetails?.activationStatus) || '--'}
              </label>
            </label>
          </div>
          <div className="mt-6 ml-6">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {LABELS_CONSTANTS.MODIFIED_BY}
            </label>
            <Tooltip title={rowUserDetails?.modifiedByUname}>
              <label className="instance-details-style fontPoppinsRegularSm">
                {rowUserDetails?.modifiedByUname || '--'}
              </label>
            </Tooltip>
          </div>
          <div className="mt-6 ml-12">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {LABELS_CONSTANTS.MODIFIED_ON}
            </label>
            <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
              {rowUserDetails?.modifiedOn || '--'}
            </label>
          </div>
        </div>
      </div>
    </CommonDrawerJs>
  );
};

export default ConfigUserDetails;

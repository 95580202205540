import React, { useContext, useEffect, useMemo, useState } from 'react';
import MyInput from '@pagescommon/Inputs/MyInput';
import MyPassword from '@pagescommon/Inputs/MyPassword';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import '../auth.scss';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { postAuth2Value, updateLocalVariableReq, updateGloabalVariable } from '@api/api_services';
import '@pages/test-development/script/scripts/webservice/restapi/css/common_Webservice.scss';
import { RestContext } from '@pages/test-development/script/scripts/webservice/restapi/restapi';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Info from '../../request_parameter/images/info-gray-24dp.svg';
import InfoIconHover from '@pages/test-development/script/scripts/webservice/restapi/request/request_parameter/images/info_black_24dp.svg';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';
import useSyncContextValues from '@src/hooks/useSyncContextValues';
import { WEBSERVICE } from '../../../constants/constants';
import classNames from 'classnames';
import { Fragment } from 'react';

export default function Oauth2(props) {
  const { onAuthIconHover, authIconHover } = props;
  const context = useContext(RestContext);
  const formik = useFormik({
    initialValues: {
      userName: '',
      password: '',
      accessToken: '',
      headerPrefix: '',
      grantType: 'authorization_code',
      accessTokenURl: '',
      redirectURL: '',
      authorizationURL: '',
      clientID: '',
      clientSecret: '',
      scope: '',
      state: '',
      codeVerifier: null,
      codeChallengeMethod: 'S256',
      audience: '',
      resource: '',
      usePkce: false,
      identityToken: '',
      ...(context?.values?.oauth2Data || {}),
    },
    validationSchema: Yup.object({
      tokenName: Yup.string().required('Token Name is required'),
      grantType: Yup.string().required('grantType ID is required'),
    }),
  });
  useSyncContextValues(formik, context, 'oauth2Data');
  let tempField = [{ value: null }];
  const isEditable = useMemo(() => window.permission?.isEditAccess('testDevelopment'), []);
  const redirectUrlImplicit = process.env.REACT_APP_OAUTH_REDIRECT_URL_IMPLICIT;
  const redirectUrlAuthoriztion = process.env.REACT_APP_OAUTH_REDIRECT_URL_AUTORIZATION;
  const currentUrl = window.location.href;
  const { origin } = new URL(currentUrl);
  const baseURLRegex = /\/(?=[^/]*$)/;
  const baseUrl = process.env.REACT_APP_MANAGEMENT_BASE_URL.split(baseURLRegex)[0];
  const [grantType, setGrantType] = useState('authorization_code');
  const [fields, setFields] = useState(tempField);
  const [fields1, setFields1] = useState([{ value: null }]);
  const [externalPopup, setExternalPopup] = useState(null);
  const [isvalue2, isSetValue2] = useState('S256');
  const [oauthAccessToken, setOauthAccessToken] = useState();
  const [accessTokenURl, setAccessTokenURl] = useState();
  const [clientSecretVal, setClientSecretVal] = useState('');
  const [scopeVal, setScopeVal] = useState();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [authorizationURL, setAuthorizationURL] = useState('');
  const [clientId, setClientId] = useState('');
  const [state, setState] = useState('');

  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  const [isVariable, setIsVariable] = useState(false);
  const [type, setType] = useState('');
  const [activeTab, setActiveTab] = useState('authorization_code');
  let isUpdateVariable = false;
  const currentOrigin = window.location.origin;

  useEffect(() => {
    setOauthAccessToken(props.getUserInputValue(formik.values.accessToken, true));
    if (formik.values.usePkce) {
      setGrantType('authorization_code_pkce');
      setActiveTab('authorization_code_pkce');
    } else {
      setGrantType(formik.values.grantType);
      setActiveTab(formik.values.grantType);
    }
    return () => context.setFieldValue('currentOath2Value', {});
  }, []);

  const onSetVariableType = (type) => {
    setType(type);
  };

  function handleChange11(i, event) {
    if (event) {
      const values = [...fields];
      values[i].value = event.target.value;
      setFields(values);
    }
  }

  const handleChange2a = (e) => {
    const { value, data, action, name, variableId } = e.target || 'unknown';
    if (e.type !== 'change' && !variableId && name !== 'accessToken') {
      setActiveTab(value);
    }
    let grantTypeValue = '';
    if (name === 'grantType') {
      setGrantType(value);
      grantTypeValue = value;
    } else {
      grantTypeValue = grantType;
    }
    context.setFieldValue('authType', 'Oauth2');
    const updatedOauth2Data = { ...formik.values, ...context.values.oauth2Data };
    const values = { ...updatedOauth2Data, [name]: props.getUserInputValue(value, false, data, action) };
    values.usePkce = false;

    if (grantTypeValue === 'authorization_code_pkce') {
      values.grantType = 'authorization_code';
      values.usePkce = true;
      var { identityToken, userName, password, ...updatedValues } = values;
    } else if (grantTypeValue === 'implicit') {
      var {
        identityToken,
        userName,
        password,
        codeVerifier,
        codeChallengeMethod,
        accessTokenURl,
        resource,
        clientSecret,
        ...updatedValues
      } = values;
    } else if (grantTypeValue === 'client_credentials') {
      var {
        identityToken,
        userName,
        password,
        codeVerifier,
        codeChallengeMethod,
        authorizationURL,
        redirectURL,
        state,
        ...updatedValues
      } = values;
    } else if (grantTypeValue === 'password') {
      var { codeVerifier, codeChallengeMethod, resource, authorizationURL, redirectURL, state, ...updatedValues } =
        values;
    } else {
      var { identityToken, userName, password, codeVerifier, codeChallengeMethod, ...updatedValues } = values;
    }

    context.setFieldValue('oauth2Data', updatedValues);
    const values2 = { ...(context?.values?.auth2Data || {}), [name]: value };
    context.setFieldValue('auth2Data', values2);
    formik.handleChange(e);
    const currentOath2Value = { ...(context.values.currentOath2Value || {}), [name]: e };
    context.setFieldValue('currentOath2Value', currentOath2Value);
    props.handleSaveAsSteps(false);
  };

  if (formik.values.grantType === 'implicit') {
    formik.values.redirectURL = redirectUrlImplicit;
  } else if (
    formik.values.grantType === 'authorization_code' ||
    formik.values.grantType === 'authorization_code_pkce'
  ) {
    formik.values.redirectURL = redirectUrlAuthoriztion;
  }

  function handleAdd() {
    const values = [...fields];
    values.push({ value: null });
    setFields(values);
  }

  function handleRemove(i) {
    const values = [...fields];
    if (values.length > 1) {
      values.splice(i, 1);
      setFields(values);
    } else {
      values[0].value = '';
      setFields(values);
    }
  }
  function handleChange1(i, event) {
    if (event) {
      const values = [...fields1];
      values[i].value = event.target.value;
      setFields1(values);
    }
  }

  function handleAdd1() {
    const values = [...fields1];
    values.push({ value: null });
    setFields1(values);
  }

  function handleRemove1(i) {
    const values = [...fields1];
    if (values.length > 1) {
      values.splice(i, 1);
      setFields1(values);
    } else {
      values[0].value = '';
      setFields1(values);
    }
  }

  const handleChange4 = (event) => {
    isSetValue2(event.target.value);
  };

  const handleRequestToken = () => {
    const data = {
      authType: 'Oauth2',
      auth: {
        grantType: formik.values.grantType,
        authorizationURL: props.getUserInputValue(context.values.oauth2Data.authorizationURL, false),
        accessTokenURl: props.getUserInputValue(context.values.oauth2Data.accessTokenURl, false),
        clientID: props.getUserInputValue(context.values.oauth2Data.clientID, false),
        clientSecret: props.getUserInputValue(context.values.oauth2Data.clientSecret, false),
        userName: props.getUserInputValue(context.values.oauth2Data.userName, false),
        password: props.getUserInputValue(context.values.oauth2Data.password, false),
        scope: props.getUserInputValue(context.values.oauth2Data.scope, false),
        state: '',
        credentials: '',
        headerPrefix: '',
        audience: '',
        resource: '',
        accessToken: '',
        usePkce: false,
        codeVerifier: props.getUserInputValue(context.values.oauth2Data.codeVerifier, false),
        codeChallengeMethod: context.values.oauth2Data.codeChallengeMethod,
      },
    };
    if (
      data.auth.grantType === 'authorization_code' ||
      data.auth.grantType === 'authorization_code_pkce' ||
      data.auth.grantType === 'implicit'
    ) {
      data.auth.redirectURL = completeRedirectURL;
    }
    if (formik.values.grantType === 'authorization_code_pkce') {
      data.auth.usePkce = true;
      data.auth.grantType = 'authorization_code';
    }

    if (data.auth.grantType === 'password') {
      data.auth.identityToken = '';
    }

    let isVariableExist = props.getUserInputValue(oauthAccessToken, false);
    if (isVariableExist !== oauthAccessToken && oauthAccessToken) {
      setIsVariable(true);
    } else {
      setIsVariable(false);
    }

    postAuth2Value(data)
      .then((response) => {
        if (response.data.success) {
          if (['authorization_code', 'implicit'].includes(data.auth.grantType)) {
            const popup = document.open(response.data.success, '', `width=600px,height=300px,left=250px,top=200px`);
            setExternalPopup(popup);
          }

          if (
            (data.auth.grantType === 'client_credentials' || data.auth.grantType === 'password') &&
            isVariableExist !== oauthAccessToken
          ) {
            onUpdateVariable(response.data.success.accessToken);
          } else {
            if (response.data.success.accessToken) {
              if (data.auth.grantType === 'client_credentials' || data.auth.grantType === 'password') {
                const rawResponse = JSON.parse(response.data.success.rawResponse);
                oAuthTokenChange('accessToken', response.data.success.accessToken);
                if (data.auth.grantType === 'password') {
                  oAuthTokenChange('identityToken', rawResponse?.identityToken);
                }
              }
              formik.values.accessToken = response.data.success.accessToken;
              setOauthAccessToken(response.data.success.accessToken);
            }
          }
        } else {
          props.MyAlert?.alertbanner(response.data.errors.message);
        }
      })
      .catch((error) => {
        console.error('error', error);
      });
  };

  const onUpdateVariable = (accessTokenData) => {
    if (accessTokenData) {
      const isVariableExist = props.getUserInputValue(oauthAccessToken, false);
      const variableName = oauthAccessToken.slice(5, -1);
      const variableId = isVariableExist.slice(2, -1);
      let variableType = type;
      if (!variableType) {
        variableType = onGetVariableTypeByVariableId(variableId);
      }
      if (variableType === 'LOCAL') {
        let requestBody = {
          name: variableName,
          type: variableType,
          value: accessTokenData,
          parentVariableType: 'MODULE',
          parentVariableId: moduleId,
          subParentVariableId: scriptId,
        };
        updateLocalVariableReq(variableId, requestBody);
      } else {
        let globalRequestBody = {
          name: variableName,
          type: variableType,
          value: accessTokenData,
        };
        updateGloabalVariable(variableId, globalRequestBody);
      }
    }
  };

  useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const timer = setInterval(() => {
      if (!externalPopup) {
        timer && clearInterval(timer);
        return;
      }

      try {
        const currentUrl = externalPopup.location.href;

        if (!currentUrl) {
          return;
        }

        if (formik.values.grantType === 'implicit') {
          let searchParams = new URL(currentUrl).hash;
          if (searchParams.includes('#access_token')) {
            searchParams = searchParams.substring(1, searchParams.length - 2);
            let arrayHash = searchParams.split('&');
            const getAccessTokenArr = arrayHash.filter((value) => value.startsWith('access_token='));
            if (getAccessTokenArr.length > 0) {
              const getAccessToken = getAccessTokenArr[0].split('=');
              if (getAccessToken[0] === 'access_token') {
                if (isVariable) {
                  if (!isUpdateVariable) {
                    onUpdateVariable(getAccessToken[1]);
                    isUpdateVariable = true;
                  }
                } else {
                  formik.values.accessToken = getAccessToken[1];
                  oAuthTokenChange('accessToken', getAccessToken[1]);
                }
              }
            }

            if (formik.values.accessToken) {
              setTimeout(() => {
                isUpdateVariable = false;
                externalPopup.close();
                setExternalPopup(null);
                timer && clearInterval(timer);
              }, 5000);
            }
          }
        } else if (
          formik.values.grantType === 'authorization_code' ||
          formik.values.grantType === 'authorization_code_pkce'
        ) {
          const tempParmvalue = externalPopup.document.body;
          let setAccessToken = '';
          try {
            setAccessToken = JSON.parse(tempParmvalue.innerText);
          } catch (e) {
            console.error(e);
          }
          if (setAccessToken) {
            if (isVariable) {
              onUpdateVariable(setAccessToken.success.accessToken);
              oAuthTokenChange('accessToken', oauthAccessToken);
              externalPopup.close();
              setExternalPopup(null);
              timer && clearInterval(timer);
            } else {
              formik.values.accessToken = setAccessToken.success.accessToken;
              oAuthTokenChange('accessToken', setAccessToken.success.accessToken);
            }
          }

          if (externalPopup.location.href) {
            setTimeout(() => {
              externalPopup.close();
              setExternalPopup(null);
              timer && clearInterval(timer);
            }, 5000);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }, 500);
  }, [externalPopup]);

  useEffect(() => {
    if (
      formik?.values?.grantType === 'authorization_code_pkce' ||
      formik?.values?.grantType === 'implicit' ||
      formik?.values?.grantType === 'authorization_code'
    ) {
      context.setFieldValue('oauth2Data', { ...(context?.values?.oauth2Data || {}), redirectURL: completeRedirectURL });
      context.setFieldValue('auth2Data', { ...(context?.values?.auth2Data || {}), redirectURL: completeRedirectURL });
    }
  }, [formik?.values?.grantType]);

  const getCallbackUrl =
    formik?.values?.grantType === 'implicit'
      ? origin + '/callback-url'
      : baseUrl + '/webserviceworkbench/optimize/v1/public/auth/callback';
  const completeRedirectURL =
    formik?.values?.grantType === 'implicit'
      ? getCallbackUrl
      : currentOrigin + '/webserviceworkbench/optimize/v1/public/auth/callback';

  const oAuthTokenChange = (tokenName, value) => {
    handleChange2a({ target: { name: tokenName, value: value } });
  };

  const oAuthDataChange = (e, callback) => {
    handleChange2a(e);
    callback(e.target.value);
  };

  const onGetVariableTypeByVariableId = (variableId) => {
    const getAllVariable = [
      ...props.filteredLocalVariable,
      ...props.filteredGlobalVariable,
      ...props.filteredProjectEnvironmentalVariable,
    ];
    const getVariableData = getAllVariable.find((e) => e.id === variableId);
    return getVariableData.type;
  };

  const { filteredForLoopVariable, filteredDataProviderVariable, ...restProps } = props;

  const getTabClassName = (isActive, isAuthCodePKCE) => {
    if (isActive && isAuthCodePKCE) {
      return 'active-tab-in-auth2 active-auth2-left-side-panel-Auth-code';
    } else if (isActive) {
      return 'active-tab-in-auth1 active-auth1-left-side-panel';
    } else if (isAuthCodePKCE) {
      return 'auth2-left-side-panel-Auth-code';
    } else {
      return 'auth1-left-side-panel';
    }
  };

  return (
    <div className="mt-2.5 oauth2-container auth2-container-layout">
      <form onSubmit={formik.handleSubmit} className="space-y-6" noValidate>
        <div className="grid grid-cols-3 gap-x-8">
          <div className="ml-7 pt-2 pb-2">
            <div
              className="fontPoppinsMediumSm  text-left flex common-text-color-in-request"
              onDragStart={props.handleDragStart}
            >
              {' '}
              <span class="text-red-400"></span> Token Name
            </div>
            <MyInput
              canCreateVariable={isEditable}
              type="text"
              placeholder="Enter token name"
              variableInput={false}
              value={formik.values.tokenName}
              className="input-style-for-auth2-common"
              inputValue={'input'}
              autoComplete="off"
              name="tokenName"
              currentValue={context.values.currentOath2Value?.tokenName}
              MyAlert={props.MyAlert}
            />
          </div>
          <div className="ml-3.5 pt-2 pb-2">
            <div
              className="fontPoppinsRegularSm text-left common-text-color-in-request"
              onDragStart={props.handleDragStart}
            >
              {' '}
              Access Token
            </div>
            <MyInput
              canCreateVariable={isEditable}
              type="text"
              placeholder="Enter access token"
              variableInput={true}
              className="input-style-for-auth2-common"
              inputValue={'input'}
              value={
                isVariable
                  ? props.getUserInputValue(oauthAccessToken, true)
                  : props.getUserInputValue(formik.values.accessToken, true)
              }
              onMouseDown={formik.handleBlur}
              onChange={(e) => {
                oAuthDataChange(e, setOauthAccessToken);
              }}
              autoComplete="off"
              name="accessToken"
              currentValue={context.values.currentOath2Value?.accessToken}
              onSetVariableType={onSetVariableType}
              {...restProps}
            />
          </div>
          <div className="ml-2 pt-2 pb-2">
            <div
              className="fontPoppinsMediumSm  text-left flex common-text-color-in-request"
              onDragStart={props.handleDragStart}
            >
              {' '}
              Header Prefix{' '}
              <CustomTooltip
                title={<span className="fontPoppinsRegularXs">Added to the authorization header before the token</span>}
                placement="right"
              >
                <img
                  src={authIconHover?.header_prefix ? InfoIconHover : Info}
                  id="header_prefix"
                  onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                  onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                  alt=""
                  className="ml-2 w-4 h-4 object-contain"
                  height="15px"
                  width="15px"
                  draggable="false"
                />
              </CustomTooltip>
            </div>
            <MyInput
              canCreateVariable={isEditable}
              type="text"
              placeholder="Enter header prefix"
              variableInput={false}
              className="input-style-for-auth2-common"
              inputValue={'input'}
              value={formik.values.headerPrefix}
              onMouseDown={formik.handleBlur}
              onChange={(e) => {
                handleChange2a(e);
              }}
              autoComplete="off"
              name="headerPrefix"
              currentValue={context.values.currentOath2Value?.headerPrefix}
              MyAlert={props.MyAlert}
            />
          </div>
          <div className="borderStyle3 borderStyle3-oath2"></div>
        </div>
        <div className="flex">
          <div className="left-side-pane-in-auth2">
            <div className="text-left ml-4 fontPoppinsRegularLg -mt-1.5 font-bold">
              {' '}
              <span class="common-auth-star-symbol text-xs fontPoppinsSemiboldSm" onDragStart={props.handleDragStart}>
                *
              </span>{' '}
              <span className="text-xs fontPoppinsSemiboldSm" onDragStart={props.handleDragStart}>
                Grant Type
              </span>
            </div>
            <div className="auth2-left-side-margin">
              {WEBSERVICE.OAUTH_2_LEFT_SIDE_MENU.map(({ tab, value }) => {
                const isActive = activeTab === value;
                const isAuthCodePKCE = value === 'authorization_code_pkce';

                return (
                  <React.Fragment key={value}>
                    <div
                      className={classNames('cursor-pointer text-left', getTabClassName(isActive, isAuthCodePKCE))}
                      onClick={() => handleChange2a({ target: { value, name: 'grantType' } })}
                    >
                      <span className="select-none fontPoppinsRegularSm pl-2">
                        {tab} {isAuthCodePKCE && <span className="ml-2">(with PKCE)</span>}
                      </span>
                    </div>
                    <span className="h-2 flex"></span>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          <div className="flex1-div-in-auth2">
            <div className="header-in-authorization fontPoppinsSemiboldSm11">
              {formik.values.grantType === 'authorization_code'
                ? 'Authorization Code'
                : formik.values.grantType === 'authorization_code_pkce'
                ? 'Authorization Code (with PKCE)'
                : formik.values.grantType === 'implicit'
                ? 'Implicit'
                : formik.values.grantType === 'client_credentials'
                ? 'Client Credentials'
                : formik.values.grantType === 'password' && 'Password Credentials'}
            </div>
            <div className="auth2-white-background-container">
              <div className="grid grid-cols-3 gap-3 relative">
                {formik?.values?.grantType === 'authorization_code' ||
                grantType === 'authorization_code' ||
                formik?.values?.grantType === 'client_credentials' ||
                grantType === 'client_credentials' ||
                formik?.values?.grantType === 'password' ||
                grantType === 'password' ||
                formik?.values?.grantType === 'authorization_code_pkce' ||
                grantType === 'authorization_code_pkce' ? (
                  <div className="mt-2.5 ">
                    <div
                      className=" fontPoppinsMediumSm  text-left flex common-text-color-in-request"
                      onDragStart={props.handleDragStart}
                    >
                      {' '}
                      Access Token URL{' '}
                      <CustomTooltip
                        title={
                          <span className="fontPoppinsRegularXs">
                            This is the end point for authorization server and it is used to exchange the authorization
                            code for an access token.
                          </span>
                        }
                        placement="right"
                      >
                        <img
                          src={authIconHover?.token_url ? InfoIconHover : Info}
                          id="token_url"
                          onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                          onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                          alt=""
                          className="ml-2 w-4 h-4 object-contain"
                          height="15px"
                          width="15px"
                          draggable="false"
                        />
                      </CustomTooltip>
                    </div>
                    <MyInput
                      canCreateVariable={isEditable}
                      type="text"
                      placeholder="Enter Access Token URL"
                      variableInput={true}
                      value={
                        accessTokenURl ? accessTokenURl : props.getUserInputValue(formik.values.accessTokenURl, true)
                      }
                      onMouseDown={formik.handleBlur}
                      onChange={(e) => {
                        oAuthDataChange(e, setAccessTokenURl);
                      }}
                      autoComplete="off"
                      name="accessTokenURl"
                      helperText={formik.touched.accessTokenURl && formik.errors.accessTokenURl}
                      currentValue={context.values.currentOath2Value?.accessTokenURl}
                      className="my-input-style-for-authorization-auth2"
                      inputValue={'input'}
                      {...props}
                    />
                  </div>
                ) : null}
                {formik?.values?.grantType === 'authorization_code_pkce' ||
                grantType === 'authorization_code_pkce' ||
                formik?.values?.grantType === 'implicit' ||
                grantType === 'implicit' ||
                formik?.values?.grantType === 'authorization_code' ||
                grantType === 'authorization_code' ? (
                  <div className="mt-2.5">
                    <div
                      className=" fontPoppinsMediumSm text-left flex common-text-color-in-request"
                      onDragStart={props.handleDragStart}
                    >
                      {' '}
                      Callback URL{' '}
                      <CustomTooltip
                        title={
                          <span className="fontPoppinsRegularXs">
                            This is the callback URL used by FireFlink that you will be redirected to, after your
                            application is authorized. FireFlink uses this to extract the authorization code or access
                            token. The callback URL should match the one you use during the application registration
                            process.
                          </span>
                        }
                        placement="right"
                      >
                        <img
                          src={authIconHover?.callback_url ? InfoIconHover : Info}
                          id="callback_url"
                          onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                          onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                          alt=""
                          className="ml-2 w-4 h-4 object-contain"
                          height="15px"
                          width="15px"
                          draggable="false"
                        />
                      </CustomTooltip>
                    </div>
                    <MyInput
                      canCreateVariable={isEditable}
                      type="text"
                      placeholder="http://your-application.com/callback"
                      variableInput={false}
                      value={getCallbackUrl}
                      onBlur={formik.handleBlur}
                      onChange={handleChange2a}
                      autoComplete="off"
                      name="redirectURL"
                      disabled={formik.touched.redirectURL}
                      helperText={formik.touched.redirectURL && formik.errors.redirectURL}
                      className="my-input-style-for-authorization-auth2"
                      inputValue={'input'}
                      currentValue={context.values.currentOath2Value?.redirectURL}
                      {...props}
                    />
                  </div>
                ) : null}
                {formik?.values?.grantType === 'authorization_code_pkce' ||
                grantType === 'authorization_code_pkce' ||
                formik?.values?.grantType === 'implicit' ||
                grantType === 'implicit' ||
                formik?.values?.grantType === 'authorization_code' ||
                grantType === 'authorization_code' ? (
                  <div className="mt-2.5">
                    <div
                      className="fontPoppinsMediumSm text-left flex common-text-color-in-request"
                      onDragStart={props.handleDragStart}
                    >
                      {' '}
                      Auth URL{' '}
                      <CustomTooltip
                        title={
                          <span className="fontPoppinsRegularXs">
                            This is the endpoint for authorization server and it is used to get the authorizaton code.
                          </span>
                        }
                        placement="right"
                      >
                        <img
                          src={authIconHover?.auth_url ? InfoIconHover : Info}
                          id="auth_url"
                          onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                          onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                          alt=""
                          className="ml-2 w-4 h-4 object-contain"
                          height="15px"
                          width="15px"
                          draggable="false"
                        />
                      </CustomTooltip>
                    </div>
                    <MyInput
                      canCreateVariable={isEditable}
                      type="text"
                      placeholder="Enter Access Token URL"
                      variableInput={true}
                      value={
                        authorizationURL
                          ? authorizationURL
                          : props.getUserInputValue(formik.values.authorizationURL, true)
                      }
                      onMouseDown={formik.handleBlur}
                      className="my-input-style-for-authorization-auth2"
                      inputValue={'input'}
                      onChange={(e) => {
                        oAuthDataChange(e, setAuthorizationURL);
                      }}
                      autoComplete="off"
                      name="authorizationURL"
                      helperText={formik.touched.authorizationURL && formik.errors.authorizationURL}
                      currentValue={context.values.currentOath2Value?.authorizationURL}
                      {...props}
                    />
                  </div>
                ) : null}
                {formik?.values?.grantType === 'password' && grantType === 'password' ? (
                  <div className="mt-2.5">
                    <div
                      className="fontPoppinsMediumSm text-left flex common-text-color-in-request"
                      onDragStart={props.handleDragStart}
                    >
                      {' '}
                      Username
                    </div>
                    <MyInput
                      canCreateVariable={isEditable}
                      type="text"
                      placeholder="Enter username"
                      variableInput={true}
                      value={userName ? userName : props.getUserInputValue(formik.values.userName, true)}
                      onMouseDown={formik.handleBlur}
                      className="my-input-style-for-authorization-auth2"
                      inputValue={'input'}
                      onChange={(e) => {
                        oAuthDataChange(e, setUserName);
                      }}
                      autoComplete="off"
                      name="userName"
                      helperText={formik.touched.userName && formik.errors.userName}
                      currentValue={context.values.currentOath2Value?.userName}
                      {...props}
                    />
                  </div>
                ) : null}
                {formik?.values?.grantType === 'password' && grantType === 'password' ? (
                  <div className="mt-2.5">
                    <div
                      className="fontPoppinsMediumSm text-left flex common-text-color-in-request"
                      onDragStart={props.handleDragStart}
                    >
                      {' '}
                      Password
                    </div>
                    <MyPassword
                      placeholder="Enter password"
                      value={password ? password : props.getUserInputValue(formik.values.password, true)}
                      onBlur={formik.handleBlur}
                      autoComplete="off"
                      onChange={(e) => {
                        oAuthDataChange(e, setPassword);
                      }}
                      name="password"
                      className="my-input-style-for-authorization-auth2 placeholderpass"
                      inputValue={'input'}
                      helperText={formik.touched.password && formik.errors.password}
                      {...props}
                    />
                  </div>
                ) : null}

                {formik?.values?.grantType === 'authorization_code_pkce' ||
                grantType === 'authorization_code_pkce' ||
                formik?.values?.grantType === 'implicit' ||
                grantType === 'implicit' ||
                formik?.values?.grantType === 'client_credentials' ||
                grantType === 'client_credentials' ||
                formik?.values?.grantType === 'password' ||
                grantType === 'password' ||
                formik?.values?.grantType === 'authorization_code' ||
                grantType === 'authorization_code' ? (
                  <div className="mt-2.5">
                    <div
                      className="fontPoppinsMediumSm text-left flex common-text-color-in-request"
                      onDragStart={props.handleDragStart}
                    >
                      {' '}
                      Client ID{' '}
                      <CustomTooltip
                        title={
                          <span className="fontPoppinsRegularXs">
                            This is the client identifier which is issued to the client during the application
                            registration process.
                          </span>
                        }
                        placement="right"
                      >
                        <img
                          src={authIconHover?.client_id ? InfoIconHover : Info}
                          id="client_id"
                          onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                          onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                          alt=""
                          className="ml-2 w-4 h-4 object-contain"
                          height="15px"
                          width="15px"
                          draggable="false"
                        />
                      </CustomTooltip>
                    </div>
                    <MyInput
                      canCreateVariable={isEditable}
                      type="text"
                      placeholder="Enter client ID"
                      variableInput={true}
                      value={clientId ? clientId : props.getUserInputValue(formik.values.clientID, true)}
                      onMouseDown={formik.handleBlur}
                      onChange={(e) => {
                        oAuthDataChange(e, setClientId);
                      }}
                      autoComplete="off"
                      name="clientID"
                      helperText={formik.touched.clientID && formik.errors.clientID}
                      className="my-input-style-for-authorization-auth2"
                      inputValue={'input'}
                      currentValue={context.values.currentOath2Value?.clientID}
                      {...props}
                    />
                  </div>
                ) : null}
                {formik?.values?.grantType === 'authorization_code_pkce' ||
                grantType === 'authorization_code_pkce' ||
                formik?.values?.grantType === 'client_credentials' ||
                grantType === 'client_credentials' ||
                formik?.values?.grantType === 'password' ||
                grantType === 'password' ||
                formik?.values?.grantType === 'authorization_code' ||
                grantType === 'authorization_code' ? (
                  <div className="mt-2.5">
                    <div
                      className="fontPoppinsMediumSm text-left flex common-text-color-in-request"
                      onDragStart={props.handleDragStart}
                    >
                      {' '}
                      Client Secret{' '}
                      <CustomTooltip
                        title={
                          <span className="fontPoppinsRegularXs">
                            This is the client secret issued to the client during the application registration process.
                          </span>
                        }
                        placement="right"
                      >
                        <img
                          src={authIconHover?.client_secret ? InfoIconHover : Info}
                          id="client_secret"
                          onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                          onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                          alt=""
                          className="ml-2 w-4 h-4 object-contain"
                          height="15px"
                          width="15px"
                          draggable="false"
                        />
                      </CustomTooltip>
                    </div>
                    <MyInput
                      canCreateVariable={isEditable}
                      type="text"
                      placeholder="Enter client secret"
                      variableInput={true}
                      value={
                        clientSecretVal ? clientSecretVal : props.getUserInputValue(formik.values.clientSecret, true)
                      }
                      className="my-input-style-for-authorization-auth2"
                      inputValue={'input'}
                      onMouseDown={formik.handleBlur}
                      onChange={(e) => {
                        oAuthDataChange(e, setClientSecretVal);
                      }}
                      autoComplete="off"
                      name="clientSecret"
                      helperText={formik.touched.clientSecret && formik.errors.clientSecret}
                      currentValue={context.values.currentOath2Value?.clientSecret}
                      {...props}
                    />
                  </div>
                ) : null}

                {formik?.values?.grantType === 'authorization_code_pkce' ||
                grantType === 'authorization_code_pkce' ||
                formik?.values?.grantType === 'implicit' ||
                grantType === 'implicit' ||
                formik?.values?.grantType === 'client_credentials' ||
                grantType === 'client_credentials' ||
                formik?.values?.grantType === 'password' ||
                grantType === 'password' ||
                formik?.values?.grantType === 'authorization_code' ||
                grantType === 'authorization_code' ? (
                  <div className="mt-2.5">
                    <div
                      className="fontPoppinsMediumSm text-left flex common-text-color-in-request"
                      onDragStart={props.handleDragStart}
                    >
                      {' '}
                      Scope{' '}
                      <CustomTooltip
                        title={
                          <span className="fontPoppinsRegularXs">
                            This is the scope of the access request(It may have multiple space-delimited values).
                          </span>
                        }
                        placement="right"
                      >
                        <img
                          src={authIconHover?.Scope ? InfoIconHover : Info}
                          id="Scope"
                          onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                          onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                          alt=""
                          className="ml-2 w-4 h-4 object-contain"
                          height="15px"
                          width="15px"
                          draggable="false"
                        />
                      </CustomTooltip>
                    </div>
                    <MyInput
                      canCreateVariable={isEditable}
                      type="text"
                      placeholder="Enter Scope"
                      variableInput={true}
                      value={scopeVal ? scopeVal : props.getUserInputValue(formik.values.scope, true)}
                      onMouseDown={formik.handleBlur}
                      onChange={(e) => {
                        oAuthDataChange(e, setScopeVal);
                      }}
                      autoComplete="off"
                      name="scope"
                      className="my-input-style-for-authorization-auth2"
                      inputValue={'input'}
                      currentValue={context.values.currentOath2Value?.scope}
                      {...props}
                    />
                  </div>
                ) : null}

                {formik?.values?.grantType === 'authorization_code_pkce' ||
                grantType === 'authorization_code_pkce' ||
                formik?.values?.grantType === 'implicit' ||
                grantType === 'implicit' ||
                formik?.values?.grantType === 'authorization_code' ||
                grantType === 'authorization_code' ? (
                  <div className="mt-2.5">
                    <div
                      className="fontPoppinsMediumSm text-left flex common-text-color-in-request"
                      onDragStart={props.handleDragStart}
                    >
                      State{' '}
                      <CustomTooltip
                        title={
                          <span className="fontPoppinsRegularXs">
                            This is an opaque value that is used for preventing cross-site request forgery.
                          </span>
                        }
                        placement="right"
                      >
                        <img
                          src={authIconHover?.State ? InfoIconHover : Info}
                          id="State"
                          onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                          onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                          alt=""
                          className="ml-2 w-4 h-4 object-contain"
                          height="15px"
                          width="15px"
                          draggable="false"
                        />
                      </CustomTooltip>
                    </div>
                    <MyInput
                      canCreateVariable={isEditable}
                      type="text"
                      placeholder="State"
                      variableInput={true}
                      className="my-input-style-for-authorization-auth2"
                      inputValue={'input'}
                      value={state ? state : props.getUserInputValue(formik.values.state, true)}
                      onMouseDown={formik.handleBlur}
                      onChange={(e) => {
                        oAuthDataChange(e, setState);
                      }}
                      autoComplete="off"
                      name="state"
                      currentValue={context.values.currentOath2Value?.state}
                      {...props}
                    />
                  </div>
                ) : null}
                {formik?.values?.grantType === 'authorization_code_pkce' || grantType === 'authorization_code_pkce' ? (
                  <div className="mt-2.5">
                    <div
                      className="fontPoppinsMediumSm text-left flex common-text-color-in-request"
                      onDragStart={props.handleDragStart}
                    >
                      Code Verifier
                    </div>
                    <MyInput
                      canCreateVariable={isEditable}
                      type="text"
                      placeholder="Automatically generated if left blank"
                      variableInput={true}
                      value={context?.values?.auth2Data?.codeVerifier}
                      className="my-input-style-for-authorization-auth2"
                      inputValue={'input'}
                      onMouseDown={formik.handleBlur}
                      onChange={handleChange2a}
                      autoComplete="off"
                      name="codeVerifier"
                      currentValue={context.values.currentOath2Value?.codeVerifier}
                      {...props}
                    />
                  </div>
                ) : null}
                {formik?.values?.grantType === 'authorization_code_pkce' || grantType === 'authorization_code_pkce' ? (
                  <div className="mt-2.5 flex gap-2">
                    <FormLabel className="mt-4" component="legend">
                      {' '}
                      <div className="input-filed-label fontPoppinsRegularSm">
                        <span class="common-auth-star-symbol">*</span> Code Challenge Method
                      </div>
                    </FormLabel>

                    <RadioGroup
                      value={isvalue2}
                      onChange={(e) => {
                        handleChange2a(e);
                        handleChange4(e);
                      }}
                      autoComplete="off"
                      name="codeChallengeMethod"
                    >
                      <FormControlLabel
                        value="S256"
                        control={<Radio color={'primary'} />}
                        label={<Typography className="fontPoppinsRegularMd">SHA-256</Typography>}
                      />
                      <FormControlLabel
                        value="PLAIN"
                        control={<Radio color={'primary'} />}
                        label={<Typography className="fontPoppinsRegularMd">Plain</Typography>}
                      />
                    </RadioGroup>
                  </div>
                ) : null}
                <div className="borderStyle3-oath2">
                  <h5
                    className="font-bold text-left relative top-3 advanced-text-style"
                    onDragStart={props.handleDragStart}
                  >
                    Advanced
                  </h5>
                </div>
                <div className="borderStyle3 borderStyle3-oath2"></div>

                <div className="grid grid-cols-1 auth2-row-gap">
                  {formik?.values?.grantType === 'authorization_code_pkce' ||
                  grantType === 'authorization_code_pkce' ||
                  formik?.values?.grantType === 'implicit' ||
                  grantType === 'implicit' ||
                  formik?.values?.grantType === 'client_credentials' ||
                  grantType === 'client_credentials' ||
                  formik?.values?.grantType === 'password' ||
                  grantType === 'password' ||
                  formik?.values?.grantType === 'authorization_code' ||
                  grantType === 'authorization_code' ? (
                    <>
                      {fields.map((field, idx) => {
                        return (
                          <>
                            {' '}
                            <div key={`${field}-${idx}`}>
                              <div
                                className="fontPoppinsMediumSm text-left flex common-text-color-in-request"
                                onDragStart={props.handleDragStart}
                              >
                                {' '}
                                Audience{' '}
                                <CustomTooltip
                                  title={
                                    <span className="fontPoppinsRegularXs">
                                      This is a URL that indicates the target audience/services where the token is
                                      intended to be used.
                                    </span>
                                  }
                                  placement="right"
                                >
                                  <img
                                    src={authIconHover?.Audience ? InfoIconHover : Info}
                                    id="Audience"
                                    onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                                    onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                                    alt=""
                                    className="ml-2 w-4 h-4 object-contain"
                                    height="15px"
                                    width="15px"
                                    draggable="false"
                                  />
                                </CustomTooltip>
                              </div>
                              <div className="flex-alignment-of-auth2-input">
                                <MyInput
                                  canCreateVariable={isEditable}
                                  type="text"
                                  placeholder="Enter audience"
                                  value={field.value || ''}
                                  className="my-input-style-for-authorization-auth2"
                                  inputValue={'input'}
                                  onBlur={formik.handleBlur}
                                  onChange={(e) => handleChange11(idx, e)}
                                  autoComplete="off"
                                  name="audience"
                                  {...props}
                                />
                                <button
                                  type="button"
                                  className="ml-4"
                                  hidden={fields.length <= 0}
                                  onClick={() => handleRemove(idx)}
                                >
                                  <CustomTooltip title={'Delete'}>
                                    <DeleteIcon
                                      className={`cursor-pointer ${
                                        field.value || fields?.length > 1
                                          ? ' opacity-100 text-gray-500 delete-icon-hover-effect'
                                          : 'opacity-50 text-gray-400'
                                      } h-5 w-5`}
                                      draggable="false"
                                    />
                                  </CustomTooltip>
                                </button>
                                {fields.length - 1 === idx && (
                                  <button
                                    type="button"
                                    disabled={!field.value}
                                    className="ml-4"
                                    onClick={() => handleAdd()}
                                  >
                                    <CustomTooltip title={'Add'}>
                                      <AddIcon
                                        className={`w-5 h-5 text-black- ${
                                          field.value?.length > 0
                                            ? 'text-gray-500 delete-icon-hover-effect'
                                            : 'text-gray-300'
                                        }`}
                                        draggable="false"
                                      />
                                    </CustomTooltip>
                                  </button>
                                )}
                              </div>
                            </div>{' '}
                          </>
                        );
                      })}
                    </>
                  ) : null}
                  {formik?.values?.grantType === 'authorization_code_pkce' ||
                  grantType === 'authorization_code_pkce' ||
                  formik?.values?.grantType === 'client_credentials' ||
                  grantType === 'client_credentials' ||
                  formik?.values?.grantType === 'authorization_code' ||
                  grantType === 'authorization_code' ? (
                    <>
                      {fields1.map((field, idx) => {
                        return (
                          <>
                            {' '}
                            <div key={`${field}-${idx}`}>
                              <div
                                className="fontPoppinsMediumSm  text-left flex common-text-color-in-request"
                                onDragStart={props.handleDragStart}
                              >
                                Resource{' '}
                                <CustomTooltip
                                  title={
                                    <span className="fontPoppinsRegularXs">
                                      This is a URL that indicates the resource/target service where the token is
                                      intended to be used.
                                    </span>
                                  }
                                  placement="right"
                                >
                                  <img
                                    src={authIconHover?.Resource ? InfoIconHover : Info}
                                    id="Resource"
                                    onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                                    onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                                    alt=""
                                    className="ml-2 w-4 h-4 object-contain"
                                    height="15px"
                                    width="15px"
                                    draggable="false"
                                  />
                                </CustomTooltip>
                              </div>
                              <div className="flex-alignment-of-auth2-input">
                                <MyInput
                                  canCreateVariable={isEditable}
                                  type="text"
                                  placeholder="Enter resource"
                                  value={field.value || ''}
                                  className="my-input-style-for-authorization-auth2"
                                  inputValue={'input'}
                                  onBlur={formik.handleBlur}
                                  onChange={(e) => handleChange1(idx, e)}
                                  autoComplete="off"
                                  name="resource"
                                />
                                <button
                                  type="button"
                                  className="ml-4"
                                  hidden={fields1.length <= 0}
                                  onClick={() => handleRemove1(idx)}
                                >
                                  <CustomTooltip title={'Delete'}>
                                    <DeleteIcon
                                      className={`cursor-pointer ${
                                        field.value || fields1?.length > 1
                                          ? ' opacity-100 text-gray-500 delete-icon-hover-effect'
                                          : 'opacity-50 text-gray-400'
                                      } h-5 w-5`}
                                      draggable="false"
                                    />
                                  </CustomTooltip>
                                </button>
                                {fields1.length - 1 === idx && (
                                  <button
                                    type="button"
                                    disabled={!field.value}
                                    className="ml-4 mt-2"
                                    onClick={() => handleAdd1()}
                                  >
                                    <CustomTooltip title={'Add'}>
                                      <AddIcon
                                        className={`w-5 h-5  ${
                                          field.value?.length > 0
                                            ? 'text-gray-500 delete-icon-hover-effect'
                                            : 'text-gray-300'
                                        }`}
                                        draggable="false"
                                      />
                                    </CustomTooltip>
                                  </button>
                                )}
                              </div>
                            </div>{' '}
                          </>
                        );
                      })}
                    </>
                  ) : null}
                </div>
                <div className="alignment-of-request-token-button">
                  {' '}
                  <button onClick={handleRequestToken} className="request_token_btn">
                    <label className="cursor-pointer fontPoppinsRegularSm">Request Token</label>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

import { useFormik } from 'formik';
import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import * as yup from 'yup';
import { FormControl, InputLabel, MenuItem, Select, TextareaAutosize, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAlert } from '@src/pages/common/alert_service/useAlert';
import '@pages/test-development/test-development.scss';
import { updateBothScriptReq } from '@api/api_services';
import TreeWithRadioButton from '@pages/common/table_tree/table-tree-with-radio-button';
import { Close } from '@mui/icons-material';
import MultiAutocomplete from './labels-multiselect-dropdown';
import { getScriptOptions } from '@pages/test-development/script/modules/module/utils/common-functions';
import { colors } from '@src/css_config/colorConstants.js';
import ManualToggleButton from './ManualToggleButton';
import { TEST_DEV } from '@common/ui-constants';
Modal.setAppElement('#root');
const useStyles = makeStyles((theme) => ({
  select: {
    paddingBottom: 3,
    paddingTop: 10,
    borderRadius: 8,
    fontSize: '12px',
    '&:focus': {
      borderRadius: 12,
      background: 'none',
    },
  },
  menuPaper: {
    maxHeight: 200,
    fontSize: '12px',
  },
  menulist: {
    paddingTop: 10,
    paddingBottom: 0,
    fontSize: '12px',
    background: 'none',
    '& li': {
      fontSize: '12px',
      color: colors.grey_dark,
    },
    '& li:hover': {
      background: colors.sky_blue_dark,
      color: colors.blue_dark,
    },
    '& li.Mui-selected': {
      paddingTop: '5 !important',
      color: colors.blue_dark,
      background: colors.sky_blue_dark,
    },
    '& li.Mui-selected:hover': {
      background: colors.sky_blue_dark,
      color: colors.blue_dark,
    },
  },
}));

const usePlaceholderStyles = makeStyles((theme) => ({
  placeholder: {
    color: colors.light_gray_100,
    fontSize: 12,
  },
}));
function ScriptModal(props) {
  const { isToggled, setIsToggled, checkAutomationWithManual } = props;

  const toggleEditRef = useRef(isToggled);
  const { AlertContatiner, MyAlert } = useAlert();
  const [createCalled, setCreateCalled] = useState(false);
  const classes = useStyles();
  let editData = {};
  if (props?.data) {
    editData = props.data;
  }

  const [scriptTypeOptions] = useState(getScriptOptions());
  const [descCount, setdescCount] = useState(props.data ? editData?.desc?.length : 0);
  let [noOfRows, setNoOfRows] = useState(1);
  const [openModal, setOpenModal] = useState(true);
  const [labelSelectedOptions, setLabelSelectedOptions] = useState([]);
  const [labelResponseObject, setLabelResponseObject] = useState([]);
  const projectLabelsObject = [];
  const [disableEditBtn, setDisableEditBtn] = useState(true);
  const customStylesPage = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '640px',
      height: 'fit-content',
      background: '#fbfcfd 0% 0% no-repeat padding-box',
      boxShadow: '0px 0px 11px #000000a1',
      borderRadius: '4px',
      opacity: '1',
    },
  };

  const [selectedParentNode, setSelectedParentNode] = useState(
    props?.parentNode ? (props?.data ? props.parentNode.parent : props.parentNode) : undefined
  );

  const treeNodeSelected = (data) => {
    if (data && data.data.key) {
      setSelectedParentNode(data.node);
      formikDetails.setFieldValue('parentModule', data.data.key);
    }
  };

  let initialValues;
  if (props?.data) {
    initialValues = {
      scriptName: editData?.name,
      description: editData?.desc,
      type: editData?.scriptType,
      parentModule: editData?.parentId,
    };
  } else {
    initialValues = {
      scriptName: '',
      type: '',
      description: '',
      parentModule: props.moduleIdForAdd ? props.moduleIdForAdd : '',
    };
  }

  async function updateScript(moduleId, oldScriptName, data) {
    try {
      const response = await updateBothScriptReq(moduleId, oldScriptName, data);
      if (response?.data?.responseCode === 200) {
        props.closeModal(false);
        props.reloadTree(true);
        props.MyAlert.success(response?.data?.message);
      } else if (response.data.status === `Script :${data.name} and Type :${data.scriptType} already exist`) {
        props.MyAlert.info(`${response.data.status}`);
        props.closeModal(false);
      } else {
        props.MyAlert.warning(`${response.data.status}`);
        props.closeModal(false);
      }
    } catch (err) {
      console.log(err);
    }
  }
  const checkForDuplicates = (node, enteredScriptName, enteredScriptType) => {
    if (node?.children?.length) {
      let index = 0;
      for (const childNode of node.children) {
        if (
          childNode.title.toLowerCase().trim() === enteredScriptName.toLowerCase().trim() &&
          childNode.data?.scriptType?.toLowerCase() === enteredScriptType.toLowerCase() &&
          editData?.id !== (childNode._key ? childNode._key : childNode.key)
        ) {
          return true;
        }
        index++;
      }
      return false;
    } else {
      return false;
    }
  };

  labelSelectedOptions?.forEach((labelValue) => {
    labelResponseObject?.forEach((objectValue) => {
      if (objectValue.name === labelValue) {
        projectLabelsObject.push(objectValue);
      }
    });
  });

  const onSubmit = (values) => {
    let requestBody;
    const { scriptName, type, description } = values || {};
    const { executionOrder, hierarchy, path, testCaseType, parentId, name, id } = editData || {};
    if (values && !createCalled) {
      if (!checkForDuplicates(selectedParentNode, scriptName, type)) {
        setCreateCalled(true);
        if (props?.data) {
          requestBody = {
            name: scriptName,
            scriptType: type,
            desc: description,
            executionOrder,
            hierarchy,
            path,
            testCaseType,
            projectLabels: projectLabelsObject,
            id,
            pair: isToggled,
          };
          updateScript(parentId, name, requestBody);
        }
      } else {
        props.MyAlert.info(`Script ${scriptName} with ${type} type already exists`);
        props.closeModal(false);
      }
    }
  };

  const validationSchema = yup.object({
    scriptName: yup
      .string()
      .trim('Space is not allowed at starting and at the end')
      .strict(true)
      .required('Name is required')
      .matches(/^[a-zA-Z0-9-_() ]*$/, 'Name should be alphanumeric'),
    description: yup.string(),
    type: yup.string().required('Type is required'),
    parentModule: yup.string().required('Parent Module is required'),
  });

  const formikDetails = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  let prevLabels = props?.data?.projectLabels?.map((label) => label.name);

  const getDisableBtn = () => {
    if (
      formikDetails.values.scriptName === initialValues?.scriptName &&
      formikDetails?.values?.description === initialValues?.description &&
      labelSelectedOptions?.every((label) => prevLabels?.includes(label)) &&
      prevLabels?.length === labelSelectedOptions?.length &&
      toggleEditRef.current === isToggled
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setDisableEditBtn(getDisableBtn());
  }, [formikDetails?.values, labelSelectedOptions, isToggled]);

  const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
  };

  function projectLabelsArray() {
    let projectOptionValues = [];
    props?.data?.projectLabels?.forEach((labelName) => {
      projectOptionValues.push(labelName?.name);
    });
    return projectOptionValues;
  }

  const labelDataPassToParent = (labelData) => {
    setLabelSelectedOptions(labelData);
  };

  return (
    <Modal
      isOpen={openModal}
      style={customStylesPage}
      className="p-px"
      onRequestClose={() => {
        props.closeModal(false);
        setOpenModal(false);
      }}
    >
      <div className="mt-2 p-1">
        <span className="Popup-header-common pl-3 inline-block w-10/12 text-overflow-style">
          {/* <div className="script-edit-alert-style">
                        <AlertContatiner/>
                    </div> */}
          {props?.data ? (
            <span title={editData?.prefix} className="">
              {' '}
              Edit Script - {editData?.prefix}
            </span>
          ) : (
            <span className=""> Create Script</span>
          )}
        </span>
        <div className="float-right">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="ml-5 mr-5 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <Close color="action" />
          </button>
        </div>
      </div>
      <form onSubmit={formikDetails.handleSubmit}>
        <div className="test-dev-modal script-modal">
          <div className="step-alert-position-style ml-32 mt-3">
            <AlertContatiner></AlertContatiner>
          </div>
          <div className="border-b border-t border-blue-100 mt-px mb-px pb-3">
            <div className="overflow-y-auto edit-script-popup" id="journal-scroll">
              <div className="ml-6 mr-6 mt-3 grid grid-cols-2 gap-4">
                <div
                  className={`${formikDetails.errors.scriptName && formikDetails.touched.scriptName && '-mb-8'} w-4/5`}
                >
                  <TextField
                    error={formikDetails.errors.scriptName && formikDetails.touched.scriptName}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="text"
                    autoComplete="off"
                    className="mui-input-text-field"
                    name="scriptName"
                    id="scriptName"
                    autoFocus={true}
                    placeholder="Enter script name"
                    onBlur={formikDetails.handleBlur}
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.scriptName}
                    label={
                      <>
                        {' '}
                        <span className="text-red-500">*</span> Name
                      </>
                    }
                  />
                  {formikDetails.errors.scriptName && formikDetails.touched.scriptName ? (
                    <div className="text-red-500 text-xs md:mt-1 fontPoppinsRegularSm whitespace-nowrap">
                      {formikDetails.errors.scriptName}
                    </div>
                  ) : null}
                </div>

                <div className="w-4/5 float-right ml-auto p-1">
                  <FormControl className="w-full">
                    <InputLabel shrink htmlFor="type">
                      <span className="text-red-500">&#42;</span>{' '}
                      <span className={formikDetails.errors.type && formikDetails.touched.type ? 'text-red-500' : null}>
                        Type
                      </span>
                    </InputLabel>
                    <div className=" mt-1.5">
                      <Select
                        classes={{ root: classes.select }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          classes: {
                            paper: classes.menuPaper,
                            list: classes.menulist,
                          },
                          getContentAnchorEl: null,
                        }}
                        error={formikDetails.errors.type && formikDetails.touched.type}
                        name="type"
                        fullWidth
                        displayEmpty
                        value={formikDetails.values.type}
                        disabled={editData && editData.scriptType ? true : false}
                        onBlur={formikDetails.handleBlur}
                        onChange={formikDetails.handleChange}
                        onSelect={formikDetails.handleChange}
                        // className="w-full"
                        renderValue={
                          formikDetails.values.type !== ''
                            ? undefined
                            : () => <Placeholder>Select script type</Placeholder>
                        }
                      >
                        {scriptTypeOptions.map((data, index) => {
                          return <MenuItem value={data.value}>{data.name}</MenuItem>;
                        })}
                      </Select>
                    </div>
                  </FormControl>
                  {formikDetails.errors.type && formikDetails.touched.type ? (
                    <div className="text-red-500 text-xs md:mt-1">{formikDetails.errors.type}</div>
                  ) : null}
                </div>

                <div className="col-span-2">
                  <label id="descriptionLabel" htmlFor="description" className="input-filed-label-style-common">
                    Description
                  </label>
                  <TextareaAutosize
                    maxRows={noOfRows}
                    onBlur={(e) => {
                      setNoOfRows(1);
                      document.getElementById('descriptionLabel').style.color = '#727171';
                      e.target.classList.add('descriptionStyle');
                    }}
                    onFocus={(e) => {
                      setNoOfRows(null);
                      document.getElementById('descriptionLabel').style.color = '#1648C6';
                      e.target.classList.remove('descriptionStyle');
                    }}
                    id="description"
                    name="description"
                    maxLength="200"
                    className="block w-full input-style-textarea popup-input-bg pl-px  mt-1  border-0 border-b input-field-color descriptionStyle"
                    onChange={formikDetails.handleChange}
                    onKeyUp={(e) => setdescCount(e.target.value.length)}
                    value={formikDetails.values.description}
                    placeholder="Your description goes here..."
                  />
                  <div className="mt-1 text-sm text-gray-500 text-right fontPoppinsRegularMd">{descCount}/200</div>
                </div>

                <div className="w-4/5 p-1">
                  <label
                    htmlFor="parentModule"
                    className={
                      formikDetails.errors.parentModule && formikDetails.touched.parentModule
                        ? 'text-xs text-red-500'
                        : 'input-filed-label-style-common'
                    }
                  >
                    <span className="text-red-400">&#42;</span>Parent Module
                  </label>
                  <TreeWithRadioButton
                    disableRootRadioButton={true}
                    hideElements={true}
                    hideElementsBtn={true}
                    data={props?.treeData ? props.treeData : []}
                    operation={props.data ? 'edit' : props.moduleIdForAdd ? 'edit' : null}
                    placeholder={
                      props.data
                        ? props.data.parentName
                        : props.moduleIdForAdd
                        ? props.moduleNameForAdd
                        : TEST_DEV.PARENT_MODULE_PLACEHOLDER
                    }
                    nodeSelected={treeNodeSelected.bind(this)}
                    buttonLabel="Module"
                  />
                  {formikDetails.errors.parentModule && formikDetails.touched.parentModule ? (
                    <div className="text-red-500 text-xs md:mt-1">{formikDetails.errors.parentModule}</div>
                  ) : null}
                </div>

                <div className="col-span-2 w-full">
                  <MultiAutocomplete
                    selectedOptionsValue={projectLabelsArray()}
                    labelSelectedOptions={labelDataPassToParent}
                    labelResponse={setLabelResponseObject}
                    MyAlert={MyAlert}
                  />
                </div>
                <div>
                  {checkAutomationWithManual?.testCaseType.some((obj) => obj.type === 'manual') && (
                    <ManualToggleButton
                      isToggled={isToggled}
                      disabled={checkAutomationWithManual?.containsAutomationScript}
                      handleToggle={() => {
                        setIsToggled(!isToggled);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="float-right my-3 mx-5">
            <button onClick={() => props.closeModal(false)} type="button" className="gray-btn">
              Cancel
            </button>
            <button disabled={disableEditBtn} type="submit" className="primary-btn ml-3">
              {props?.data ? 'Update' : 'Create'}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default ScriptModal;

import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const FailureProgressBar = (props) => {
  const { data, handleClick, failureRateResultItems, isFetchingData } = props;

  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      width: '35px',
      height: '30px',
      borderRadius: '50%',
      backgroundColor: data?.color,
      border: `1px solid ${data?.color}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '5px',
    },
    arrow: {
      color: data?.color,
    },
  }))(Tooltip);

  const eleWidth = `${data?.resultPercentage}%`;
  const percentRoundValue = Math.round(data?.resultPercentage);
  return (
    <div className="my-2 flex flex-col gap-3">
      <CustomTooltip
        arrow
        title={<span className="fontPoppinsRegularXs p-2">{percentRoundValue || 0}%</span>}
        placement="top"
        disableInteractive
      >
        <div
          onClick={handleClick}
          className="progressContainer"
          style={{
            borderColor: data?.color,
            boxShadow:
              failureRateResultItems?.isSelected && failureRateResultItems?.header === data?.resultName
                ? `0px 0px 10px 2px ${data?.color}`
                : null,
            pointerEvents: isFetchingData ? 'none' : 'auto', // Disable pointer events while fetching
            opacity: isFetchingData ? 0.5 : 1, // Reduce opacity while fetching
          }}
        >
          <div className="progressValue" style={{ width: eleWidth, background: `${data?.color}` }}></div>
        </div>
      </CustomTooltip>
      <p className="fontPoppinsSemiboldXs capitalize" style={{ color: data?.color }}>
        {data?.resultName}
      </p>
    </div>
  );
};

export default FailureProgressBar;

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import '@src/css_config/common-button-style.scss';

const CommonButton = ({
  label = '',
  onClick = () => {},
  size = 'small',
  startIconProp = '',
  iconOrintation = 'start',
  showIcon = false,
  btnType = 'primary',
  className = '',
  disabled = false,
  ...props
}) => {
  const getVariantClass = () => {
    switch (btnType) {
      case 'primary':
        return 'rs_primary_btn';
      case 'secondary':
        return 'rs_secondary_btn';
      case 'destructive':
        return 'rs_destructive_btn';
      case 'upload':
        return 'ff-upload-btn';
      case 'modal-header':
        return 'ff-modal-header-btn';
      case 'webServiceUpload':
        if (disabled) return 'web_service_disable_upload_btn';
        return 'web_service_upload_btn';
      default:
        return 'rs_primary_btn';
    }
  };
  return (
    <div className={`${getVariantClass()} ${className}`}>
      <Button
        disabled={disabled}
        onClick={onClick}
        startIcon={showIcon && iconOrintation === 'start' && startIconProp}
        {...props}
      >
        {label}
      </Button>
    </div>
  );
};

CommonButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  iconOrintation: PropTypes.string,
  startIconProp: PropTypes.string,
  btnType: PropTypes.string,
  showIcon: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'large', 'medium']),
};

export default CommonButton;

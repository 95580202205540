import React, { useEffect, useState, useContext } from 'react';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { DragIndicatorOutlined } from '@material-ui/icons';
import { FormControl, NativeSelect, Tooltip } from '@material-ui/core';
import CreateDependsOnScripts from './create-depends-on-scripts';
import DeleteModal from '@pages/test-development/shared/delete-modal';
import {
  deleteDependsOnScriptReq,
  getAllDependsOnScriptsReq,
  getScriptReq,
  updateDependsOnScriptReq,
} from '@api/api_services';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import { createParentSonJson } from '@pages/test-development/script/modules/module/utils/script-create-api';
import TableWithDragAndDrop from '@pages/test-development/shared/table/conditions-table';
import { CommonLoader } from '@src/pages/common/common-loader';
import DependsOnScript from '@pages/test-development/script/scripts/settings/depends-on-scripts/depends-on-script.module.scss';
import cx from 'classnames';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { getUrlPath } from '../../../modules/module/utils/common-functions';
const useStyles = makeStyles({
  select: {
    '& ul': {
      fontSize: 12,
    },
    '& li': {
      fontSize: 12,
    },
  },
});

export const EditDependsOnScripts = (props) => {
  const classes = useStyles();
  const search = window.location.search;
  const crModuleId = new URLSearchParams(search).get('moduleId');
  const crScriptId = new URLSearchParams(search).get('scriptId');
  const history = useHistory();
  const editDivStyle = {
    position: 'absolute',
    // left: "46.5%",
    // right: "20%",
    // top: "33%",
    // height: "65%",
    // width: "50.9%",
    zIndex: '1',
    backgroundColor: ' #fff',
    boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
  };
  const data = props.data;
  const ifFailedOptions = [
    {
      label: 'Mark this script as Failed and continue dependant script execution',
      value: 'MARK_THIS_SCRIPT_AS_FAILED_AND_CONTINUE_DEPENDANT_SCRIPT_EXECUTION',
    },
    {
      label: 'Mark this script as Warning and continue dependant script execution',
      value: 'MARK_THIS_SCRIPT_AS_WARNING_AND_CONTINUE_DEPENDANT_SCRIPT_EXECUTION',
    },
    {
      label: 'Mark this script as Failed and stop dependant script execution',
      value: 'MARK_THIS_SCRIPT_AS_FAILED_AND_STOP_DEPENDANT_SCRIPT_EXECUTION',
    },
    {
      label: 'Mark this script as Failed and stop current module execution',
      value: 'MARK_THIS_SCRIPT_AS_FAILED_AND_STOP_CURRENT_MODULE_EXECUTION',
    },
    {
      label: 'Mark this script as Failed and stop complete execution',
      value: 'MARK_THIS_SCRIPT_AS_FAILED_AND_STOP_COMPLETE_EXECUTION',
    },
  ];
  let [ifFailedStatus, setIfFailedStatus] = React.useState(
    data && data.ifCheckPointIsFailed
      ? data.ifCheckPointIsFailed
      : 'MARK_THIS_SCRIPT_AS_FAILED_AND_CONTINUE_DEPENDANT_SCRIPT_EXECUTION'
  );
  let [steps, setSteps] = useState([]);
  const getSteps = async () => {
    try {
      const response = await getScriptReq(data.moduleId, data.scriptId);
      if (response.data && response.data.responseObject && response.data.responseObject.steps) {
        if (response.data.responseObject.steps?.length) {
          let tempArray = response.data.responseObject.steps;
          let tempIndex = 1;
          tempArray.forEach((obj) => {
            if (['_startiteration', '_enditeration'].includes(obj.type)) {
            } else {
              obj['stepTempIndex'] = tempIndex++;
            }
          });
          setSteps(tempArray);
        } else {
          setSteps(response.data.responseObject.steps);
        }
      } else {
        setSteps([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateDependentScript = async (moduleId, scriptId, payload) => {
    try {
      const response = await updateDependsOnScriptReq(moduleId, scriptId, payload);
      if (response.data && response.data.responseCode === 200) {
        props.closeModal(false);
        props.reload(true);
        props.MyAlert.success(`${data.name} depends on script updated successfully`);
      } else {
        props.closeModal(false);
        props.MyAlert.warning(`${response.data.message}`);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleChange = (event) => {
    setIfFailedStatus(event.target.value);
  };
  const handleSubmit = () => {
    let requestBody = {
      id: data._id ? data._id : data.id,
      moduleId: data.moduleId,
      scriptId: data.scriptId,
      name: data.name,
      ifCheckPointIsFailed: ifFailedStatus,
      executionOrder: data.executionOrder,
      stepId: data.stepId,
    };
    // updateDependentScript(data.moduleId, data.scriptId, requestBody)
    updateDependentScript(crModuleId, crScriptId, requestBody);
  };
  async function goToScript() {
    try {
      const res = await getScriptReq(data.moduleId, data.scriptId, true);
      if (res?.data?.responseObject?.automationScript) {
        const autoScript = res.data.responseObject.automationScript;
        const keyArray = autoScript.searchKey.slice(1).split('/');
        const nameArray = autoScript.path.slice(1).split('/');
        const parentId = autoScript.parentId;
        const autoScriptId = keyArray[keyArray.length - 1];
        let manualScriptId = res.data.responseObject?.manualScriptId;
        const { json } = createParentSonJson(keyArray, nameArray);
        if (manualScriptId) {
          json[json.length - 1].manualId = manualScriptId;
        } else {
          delete json[json.length - 1].manualId;
        }
        json[json.length - 1].Name = `${autoScript?.prefix} - ${autoScript?.name}`;
        localStorage.setItem('parentAndSonJson', JSON.stringify(json));
        window.open(
          `/testdevelopment/Script/${getUrlPath(
            json
          )}Steps?moduleId=${parentId}&scriptId=${autoScriptId}&manualScriptId=${
            manualScriptId ? manualScriptId : 'null'
          }`,
          '_blank'
        );
        props.closeModal();
      } else {
        props.closeModal(false);
        props.MyAlert.info(`${res.data.message}`);
      }
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getSteps();
  }, [props.data]);
  return (
    <div id="editDependsOnScripts" className="shadow-md responsive-parameter-div" style={editDivStyle}>
      <div className="mt-2">
        <span className="Popup-header-common pl-5"> Depends on Scripts</span>
      </div>
      <form
        className=""
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div
          className="border-b border-t border-blue-100 mt-2 mb-2 overflow-y-auto responsive-parameter-div-height"
          id="journal-scroll"
        >
          <div className="input-filed-label-style-common mt-3 ml-6 mr-px">
            <div className="py-2">
              <div className="inline-block fontPoppinsRegularMd">
                <span className="">Script</span>
                <p className="text-blue-700">{data.name}</p>
              </div>
              <span
                onClick={goToScript}
                className="text-blue-700 mt-4 mr-3 fontPoppinsRegularSm ml-auto float-right cursor-pointer"
              >
                Go to script
              </span>
            </div>
            <div className="p-2 h-20 overflow-y-auto" id="journal-scroll">
              {steps.length > 0 ? (
                <ol className="fontPoppinsRegularMd">
                  {steps.map((step, index) => (
                    <li key={step.nlpID + index} className="p-1 fontPoppinsRegularMd">
                      {step?.stepTempIndex} {step?.stepTempIndex ? '.' : ''} {step.displayName}
                    </li>
                  ))}
                </ol>
              ) : (
                <p className="text-sm mt-5">No steps found</p>
              )}
            </div>
          </div>

          <div className="border-t border-dashed fontPoppinsRegularMd border-blue-100 mt-6 p-2">
            <div className="ml-6">
              <div>
                <FormControl className={classes.formControl}>
                  <span id="ifFailedStatus-label" className="">
                    {' '}
                    <span className="text-base font-medium">
                      <span className="text-red-400">&#42;</span>
                    </span>
                    <span style={{ color: '#525252' }}>If Failed</span>
                  </span>
                  <NativeSelect
                    style={{ fontSize: '12px' }}
                    MenuProps={{ classes: { paper: classes.select } }}
                    labelId="ifFailedStatus-label"
                    id="ifFailedStatus"
                    name="ifFailedStatus"
                    className="fontPoppinsRegularMd mt-1"
                    value={ifFailedStatus}
                    onChange={handleChange}
                  >
                    {ifFailedOptions.map((opt) => (
                      <option key={opt.label} value={opt.value}>
                        {opt.label}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <div className="float-right mt-2 mr-4 footer ">
          <button
            onClick={() => props.closeModal(false)}
            type="button"
            className="sm:mr-10 md:mr-1 md:ml-8 bg-gray-200 py-1.5 rounded-sm px-3.5 border border-gray-300 shadow-sm text-sm font-semibold text-black hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-4 inline-flex justify-center py-1.5 px-3.5 border rounded-sm border-transparent shadow-sm text-sm font-medium text-white btn-primary hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

const DependsOnScripts = (props) => {
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  let [reload, setReload] = useState(true);
  let [isLoading, setIsLoading] = useState(false);
  let [openCreateDependsOnScriptsModal, setOpenCreateDependsOnScriptsModal] = useState(false);
  let [openEditDependsOnScriptsModal, setOpenEditDependsOnScriptsModal] = useState(false);
  let [openDeleteDependsOnScriptsModal, setOpenDeleteDependsOnScriptsModal] = useState(false);
  let [editDependsOnScriptsData, setEditDependsOnScriptsData] = useState('');
  let [data, setData] = useState([]);
  const reloadDependsOnScripts = (val) => {
    setReload(val);
  };

  const { isEditable, hasFullAccess } = React.useMemo(
    () => ({
      isEditable: window.permission?.isEditAccess('testDevelopment'),
      hasFullAccess: window.permission?.isFullAccess('testDevelopment'),
    }),
    []
  );

  const columns = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'dragIcon',
        width: '1%',
      },
      {
        Header: 'Scripts',
        accessor: 'name',
        width: '45%',
      },
      {
        Header: 'If Failed',
        accessor: 'ifFailed',
        width: '42%',
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        width: '12%',
      },
    ],
    []
  );

  const myCssMethod = (index) => {
    const myClass = document.getElementsByClassName('myRow');
    for (let i = 0; i < myClass.length; i++) {
      if (index >= 0) {
        myClass[i].classList.remove('configHover');
      } else {
        myClass[i].classList.add('configHover');
      }
      if (i === index) {
        myClass[i].classList.add('shadow-md', 'bg-white');
        myClass[i].style.boxShadow = '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)';
      } else {
        myClass[i].classList.remove('shadow-md', 'bg-white');
        myClass[i].style.boxShadow = 'none';
      }
    }
  };
  const editDependentScripts = (data, index) => {
    setOpenEditDependsOnScriptsModal(true);
    setEditDependsOnScriptsData(data);
    myCssMethod(index);
  };
  const deleteDependentScripts = async (data) => {
    try {
      const response = await deleteDependsOnScriptReq(moduleId, scriptId, data._id);
      if (response.data.responseCode === 200 && response.data.status === 'SUCCESS') {
        reloadDependsOnScripts(true);
        props.MyAlert.success(`${data.name} depends on script deleted successfully`);
      } else {
        props.MyAlert.warning(`${response.data.message}`);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const closeModal = (value) => {
    if (openCreateDependsOnScriptsModal) {
      setOpenCreateDependsOnScriptsModal(value);
    }
    if (openEditDependsOnScriptsModal) {
      setOpenEditDependsOnScriptsModal(value);
      myCssMethod(-1);
      setEditDependsOnScriptsData('');
    }
    if (openDeleteDependsOnScriptsModal) {
      setOpenDeleteDependsOnScriptsModal(value);
    }
  };

  const tableData = React.useMemo(() => {
    if (data.length) {
      let tabData = [];
      data.forEach((row, index) => {
        tabData.push({
          ...(isEditable && {
            dragIcon: (
              <span className="actionIcons">
                <DragIndicatorOutlined fontSize="small" className="text-blue-600" />{' '}
              </span>
            ),
          }),
          name: (
            <Tooltip title={row.name} placement="bottom">
              <span
                className={cx(
                  'pt-1.5 text-blue-700 cursor-pointer fontPoppinsRegularMd',
                  DependsOnScript['length-script']
                )}
                onClick={() => {
                  isEditable && editDependentScripts(row, index);
                }}
              >
                {' '}
                {index + 1}.{row.name}{' '}
              </span>
            </Tooltip>
          ),
          ifFailed: (
            <span className="text-gray-700 fontPoppinsRegularMd"> {ifFailedText(row.ifCheckPointIsFailed)}</span>
          ),
          ...(hasFullAccess && {
            actions: (
              <span className="actionIcons">
                <Tooltip title="Delete">
                  <DeleteOutlinedIcon
                    className="float-left cursor-pointer mr-3 text-blue-700 text-base"
                    onClick={() => {
                      setEditDependsOnScriptsData(row);
                      setOpenDeleteDependsOnScriptsModal(true);
                    }}
                  />
                </Tooltip>
              </span>
            ),
          }),
          rowValue: { value: row, index: index },
        });
      });
      return tabData;
    } else {
      return [];
    }
  }, [data]);

  const getDependsOnScripts = async () => {
    try {
      resetProgress();
      startOrResumeProgress({
        stopAt: 90,
      });
      setIsLoading(true);
      const res = await getAllDependsOnScriptsReq(moduleId, scriptId);
      stopProgress();
      if (res.data && res.data.responseObject && res.data.responseObject.length > 0) {
        setData(res.data.responseObject);
      } else {
        setData([]);
      }
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 10,
      });
      setIsLoading(false);
    } catch (err) {
      resetProgress();
      setIsLoading(false);
      console.error('GET_ALL_DEPENDS_ON_SCRIPTS : ', err);
    }
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(data);
    let execOrder;
    if (items[result.destination.index + 1] === undefined) {
      execOrder = items[result.destination.index].executionOrder + 1;
    } else if (items[result.destination.index - 1] === undefined) {
      execOrder = items[result.destination.index].executionOrder / 2;
    } else {
      if (result.destination.index > result.source.index) {
        execOrder =
          (items[result.destination.index].executionOrder + items[result.destination.index + 1].executionOrder) / 2;
      } else {
        execOrder =
          (items[result.destination.index].executionOrder + items[result.destination.index - 1].executionOrder) / 2;
      }
    }
    const reorderedItem = items[result.source.index];
    const requestBody = {
      ...reorderedItem,
      id: reorderedItem._id,
      executionOrder: execOrder,
    };
    if (result.source.index !== result.destination.index) {
      const [x] = items.splice(result.source.index, 1);
      x.executionOrder = execOrder;
      items.splice(result.destination.index, 0, x);
      setData(items);
      updateDependsOnScriptReq(moduleId, scriptId, requestBody).then((response) => {
        if (!(response.data && response.data.responseObject)) {
          props.MyAlert.info(`Last reordering failed`);
        }
      });
    }

    // jchvbsdnkcmsd,l
  }
  function ifFailedText(status) {
    switch (status) {
      case 'MARK_THIS_SCRIPT_AS_FAILED_AND_STOP_COMPLETE_EXECUTION':
        return 'Mark this script as Failed and stop complete execution';
      case 'MARK_THIS_SCRIPT_AS_FAILED_AND_STOP_CURRENT_MODULE_EXECUTION':
        return 'Mark this script as Failed and stop current module execution';
      case 'MARK_THIS_SCRIPT_AS_FAILED_AND_STOP_DEPENDANT_SCRIPT_EXECUTION':
        return 'Mark this script as Failed and stop dependant script execution';
      case 'MARK_THIS_SCRIPT_AS_WARNING_AND_CONTINUE_DEPENDANT_SCRIPT_EXECUTION':
        return 'Mark this script as Warning and continue dependant script execution';
      case 'MARK_THIS_SCRIPT_AS_FAILED_AND_CONTINUE_DEPENDANT_SCRIPT_EXECUTION':
        return 'Mark this script as Failed and continue dependant script execution';
      default:
        return '--';
    }
  }
  useEffect(() => {
    if (reload) {
      getDependsOnScripts();
    }
    setReload(false);
  }, [reload]);
  return (
    <div className="page-table-body">
      <div className="content_area_style">
        <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
          <label className="project_label flex-auto">
            Depends on Scripts
            <span className="proj-count-style px-2.5 py-1 ml-2 font-bold rounded-lg">{data.length}</span>
          </label>
          {isEditable && (
            <button
              disabled={openEditDependsOnScriptsModal}
              onClick={() => {
                setOpenCreateDependsOnScriptsModal(true);
              }}
              className="ml-3 mr-4 primary-btn"
            >
              + Depends on Script
            </button>
          )}
        </div>
        <div className="table-height overflow-y-auto" id="journal-scroll">
          {/* Depends on script Table */}
          <TableWithDragAndDrop
            isDragDisabled={!isEditable}
            columns={columns}
            data={tableData}
            handleOnDragEnd={handleOnDragEnd}
          />
          {isLoading ? (
            <CommonLoader />
          ) : (
            data.length === 0 && (
              <div className="fontPoppinsRegularMd no-steps">
                <div className="">
                  <p className="font-bold mb-4">{isEditable ? 'Quick Start' : 'No Depend on Scripts!!'}</p>
                  {isEditable && (
                    <p className="mb-4">
                      <span className="font-bold">Step 1 :</span> Click
                      <button
                        onClick={() => {
                          setOpenCreateDependsOnScriptsModal(true);
                        }}
                        className="ml-3 mr-3 primary-btn"
                      >
                        + Depends on Scripts
                      </button>
                      to start adding the Depends on scripts
                    </p>
                  )}
                </div>
              </div>
            )
          )}
          {/* modal to edit depend on scripts */}
          {openEditDependsOnScriptsModal ? (
            <div>
              <EditDependsOnScripts
                MyAlert={props.MyAlert}
                reload={reloadDependsOnScripts}
                closeModal={closeModal}
                data={editDependsOnScriptsData}
              />
            </div>
          ) : null}
        </div>
        {/* modal to create depend on scripts */}
        {openCreateDependsOnScriptsModal ? (
          <CreateDependsOnScripts MyAlert={props.MyAlert} reload={reloadDependsOnScripts} closeModal={closeModal} />
        ) : null}

        {/* modal to delete depend on scriipts*/}
        {openDeleteDependsOnScriptsModal ? (
          <DeleteModal
            MyAlert={props.MyAlert}
            closeModal={closeModal}
            handleDelete={deleteDependentScripts}
            dataObj={{
              type: 'Depends on Scripts',
              data: editDependsOnScriptsData,
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default DependsOnScripts;

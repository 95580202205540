import { memo } from 'react';

import { getLicenseFeatures } from '@src/util/common_utils';

import Clients from './sections/Clients';
import MemorySection from './sections/Memory';
import Projects from './sections/Projects';
import Users from './sections/Users';

function AllProjectDashboardPage() {
  // NOTE :: SHOW COMPONENTS BASED ON LICENSE PRIVIEGE
  const licenseFeatures = getLicenseFeatures();
  const hasAutomation = window.privilege.hasAutomation(licenseFeatures);

  return (
    <>
      <div className="grid grid-cols-2 gap-1 mb-3">
        <Projects />
        <Users />
      </div>
      {hasAutomation && <Clients />}
      <div>
        <MemorySection />
      </div>
    </>
  );
}

export default memo(AllProjectDashboardPage);

import React from 'react';
import { withRouter } from 'react-router';
import AnalyticsRouter from './analytics-router';
import AllProjectDashboardPage from '../dashboard/AllProjectDashboardPage';

import { getCurrentProjectId } from '@util/common_utils';
function AnalyticsDashboard(props) {
  const projectId = getCurrentProjectId();

  return (
    <div className="page-container analytics-dashboard">
      {projectId ? (
        <div className="overflow-y-hidden">
          <AnalyticsRouter />
        </div>
      ) : (
        <AllProjectDashboardPage />
      )}
    </div>
  );
}

export default withRouter(AnalyticsDashboard);

import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import { DEFECT_CONSTANTS } from '@src/common/ui-constants';
import CommonDrawerJs from '@src/common/atoms/CommonDrawer';
import { Label } from '@src/common/atoms/LabelComponent';
import styles from '@pages/configuration/DefectConfig/defect-config.module.scss';
import {
  getAllProjectsForDomain,
  getAllAssignableUsers,
  postJiraIssue,
  getAllIssueTypeForDomain,
  getAllJiraLinks,
  getAllModuleTreeReq,
} from '@api/api_services';
import { JIRA_ISSUE_DRAWER } from '@pages/configuration/DefectConfig/defect-constants';
import CommonButton from '@src/common/button/Button';
import SelectBox from '@src/common/atoms/SelectComponent';
import { MultiSelectSearchableDropdown } from '@src/pages/configuration/plugin/gitlab/select-dropdown';
import { scrollIntoView } from '@src/pages/test-development/script/modules/module/utils/common-functions';
import { getCurrentProjectId, isEmptyValue, validateFileExtension } from '@src/util/common_utils';
import { DefectIssueFields } from '@pages/configuration/DefectConfig/defect-drawers/FireFlinkIssueDrawerFields';
import { Grid } from '@mui/material';
import { FORM_FIELDS, FORM_FIELDS_ARRAY, MESSAGES } from '@pages/defects/defectMgmtConstants';
import * as yup from 'yup';
import moment from 'moment';
import { getDefectDescription } from '@pages/defects/defect-utils';
import { checkValidationForAlphanumericSpecialCharacters } from '@pages/analytics/common/util';
import { useAlert } from '@pages/common/alert_service/useAlert';
import MultiAutocomplete from '@pages/test-development/script/modules/module/modals/labels-multiselect-dropdown';
import { DEFECT_ATTACTMENT_FILE_ALLOWED, UI_VALIDATIONS } from '@src/util/validations';

const JiraIssueDrawer = (props) => {
  const {
    closeDrawer,
    openDrawer,
    allInstances,
    reloadTable,
    failedStepsData,
    drawerType,
    selectedModule,
    defectMgmtData,
    selectedDefectData,
    setSelectedDefectData,
    reloadHandler = () => {},
    initDefectData = () => {},
    missMatchModalHandler,
    history,
    cleanUpFunction,
  } = props;
  const { AlertContatiner, MyAlert } = useAlert();
  const { steps, envDetails, isFromSteps } = failedStepsData || {};
  const [isLoading, setIsLoading] = useState(false);
  const [domain, setDomain] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState([]);
  const [project, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState([]);
  const [issueType, setIssueType] = useState([]);
  const [selectedIssueType, setSelectedIssueType] = useState([]);
  const [assignee, setAssignee] = useState([]);
  const [selectedAssignee, setselectedAssignee] = useState([]);
  const [linkIssue, setLinkIssue] = useState([]);
  const [selectedLinkIssue, setSelectedLinkIssue] = useState([]);
  const [pageSize, setPageSize] = useState(0);
  const [jiraScrollPos, setJiraScrollPos] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [searchedText, setSearchedText] = useState('');
  const [stopScrollApiCall, setStopScrollApiCall] = useState(false);
  const [searchReset, setSearchReset] = useState(false);
  const [jiraAuthPayload, setJiraAuthPayload] = useState({
    userName: '',
    apiToken: '',
    domain: '',
  });
  const [editMode, setEditMode] = useState(false);
  const [defectDetails, setDefectDetails] = useState([]);
  const [moduleTree, setModuleTree] = useState([]);
  const [moduleLevelScriptCount, setModuleLevelScriptCount] = useState(0);
  const [initialValues, setInitialValues] = useState({});
  const [apiToken, setAPIToken] = useState();
  const { TEXTFIELD, TEXTBOX, CHECKBOX, LINK, ATTACHMENT, DATE } = FORM_FIELDS;
  const {
    CREATE_SUCCESS,
    ALPHA_ERR_MSG,
    CREATE_DEFECT_ERR,
    NO_SPACE_AT_START_AND_END_MSG,
    YES,
    VALID_URL,
    MAX_NO_OF_FILES_ALLOWED,
    DUPLICATE_FILE,
    MAX_FILE_SIZE_ALLOWED,
    ADD,
    NO,
    MAX_FILE_SIZE,
  } = MESSAGES;
  const [defaultSelectedModule, setDefaultSelectedModule] = useState([]);
  const [validateSchema, setValidateSchema] = useState({});
  const [projectUsers, setProjectUsers] = useState([]);
  const [tempUploadFiles, setTempUploadFiles] = useState([]);
  const [newFilesToBeUploaded, setNewFilesToBeUploaded] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [labelResponseObject, setLabelResponseObject] = useState([]);
  const [resetLabels, setResetLabels] = useState(false);
  const [labelSelectedOptions, setLabelSelectedOptions] = useState([]);
  const projectLabelsObject = [];
  let tempInitialValues = {};
  let tempValidateSchema = '';
  let selectedProjectId = getCurrentProjectId();

  const { MAX_FILE_UPLOAD_COUNT } = UI_VALIDATIONS;

  labelSelectedOptions?.forEach((labelValue) => {
    labelResponseObject?.forEach((objectValue) => {
      if (objectValue.name === labelValue) {
        projectLabelsObject.push(objectValue);
      }
    });
  });

  const sortBasedOnOrder = (defectTemplateData) => {
    return defectTemplateData?.sort((a, b) => {
      return a?.order - b?.order;
    });
  };
  function createYupSchema(schema, config) {
    const { id, validationType, validations = [] } = config;
    if (!yup[validationType]) {
      return schema;
    }
    let validator = yup[validationType]();
    validations.forEach((validation) => {
      const { params, type } = validation;
      if (!validator[type]) {
        return;
      }
      validator = validator[type](...params);
    });
    schema[id] = validator;
    return schema;
  }

  useEffect(() => {
    createValidationSchema();
  }, []);

  const applyValidationType = (element) => {
    if (FORM_FIELDS_ARRAY.includes(element.type)) {
      if ([CHECKBOX, ATTACHMENT].includes(element.type)) {
        element.validationType = 'array';
      } else if (element.type === DATE) {
        element.validationType = ['Created On', 'Modified On'].includes(element.label) ? 'string' : DATE;
      } else if (['Modified By', 'Created By'].includes(element.label)) {
        element.validationType = 'object';
      } else {
        element.validationType = 'string';
      }
    }
  };

  const applyFieldValidations = (element, validations) => {
    if ([TEXTFIELD, TEXTBOX].includes(element.type)) {
      validations.push({ type: 'trim', params: [NO_SPACE_AT_START_AND_END_MSG] }, { type: 'strict', params: [true] });

      if (element.type === TEXTFIELD) {
        validations.push({ type: 'matches', params: [checkValidationForAlphanumericSpecialCharacters, ALPHA_ERR_MSG] });
      }

      if (element.label === 'Summary') {
        validations.push(
          { type: 'max', params: [250, `${element.label} ${DEFECT_CONSTANTS.MAX_CHARACTER_VALIDATION}`] },
          { type: 'min', params: [3, `${element.label} ${DEFECT_CONSTANTS.MIN_CHARACTER_VALIDATION}`] }
        );
      }
    }

    if (element.minLength) {
      validations.push({
        type: 'min',
        params: [element.minLength, `${DEFECT_CONSTANTS.MIN_LENGTH_ERR_MSG(element.label, element.minLength)}`],
      });
    }

    if (element.maxLength) {
      validations.push({
        type: 'max',
        params: [element.maxLength, `${DEFECT_CONSTANTS.MAX_LENGTH_ERR_MSG(element.label, element.maxLength)}`],
      });
    }

    if (element.mandatory === YES) {
      validations.push({ type: 'required', params: [MESSAGES.REQUIRED_ERR_MSG(element.label)] });

      if ([CHECKBOX, ATTACHMENT].includes(element.type)) {
        validations.push({ type: 'min', params: [1, MESSAGES.REQUIRED_ERR_MSG(element.label)] });
      }
    }

    if (element.type === LINK) {
      validations.push({ type: 'url', params: [VALID_URL] });
    }
  };

  const createValidationSchema = async () => {
    const defectDetailsTemplate = defectMgmtData?.defect_details_templates[0]?.defectDetails || [];
    const defectTemplateData = sortBasedOnOrder(defectDetailsTemplate).map((element) => {
      let validations = [];
      if (element?.label === 'State' && drawerType === ADD) {
        element.enable = NO;
      }

      applyValidationType(element);

      applyFieldValidations(element, validations);

      element.validations = validations;
      element.id = element.label;

      return element;
    });

    createFormikSchema(defectTemplateData);
  };

  function handleOtherFields(item, tempInitialValues, defectDetails) {
    const label = item.label?.toLowerCase();
    if (label === 'description') {
      if (defectDetails && defectDetails[item.label]) {
        tempInitialValues[item.label] = defectDetails[item.label];
      } else if (steps || envDetails) {
        const descTemp = `{"blocks":${getDefectDescription(steps, envDetails, isFromSteps)},"entityMap":{}}`;
        tempInitialValues[item.label] = descTemp;
      } else {
        tempInitialValues[item.label] = '';
      }
    } else if (label === 'module') {
      const selectedModuleValue = selectedModule || (defectDetails && defectDetails[item.label]) || '';
      const [defaultCheckedNodeName, defaultCheckedNodeId] = selectedModuleValue.split(':');
      setDefaultSelectedModule([{ key: 'key', value: defaultCheckedNodeId || '' }]);
      tempInitialValues[item.label] = selectedModuleValue;
    } else if ([CHECKBOX, ATTACHMENT].includes(item.type)) {
      tempInitialValues[item.label] = (defectDetails && defectDetails[item.label]) || [];
    } else if ([TEXTFIELD, TEXTBOX].includes(item.type)) {
      tempInitialValues[item.label] = (defectDetails && defectDetails[item.label]) || item.defaultValue || '';
    } else if (item.type === DATE) {
      const dateFormat =
        item.dateFormat && typeof item.dateFormat === 'string' ? item.dateFormat.toUpperCase() : 'DD-MM-YYYY';
      const myDate = moment(defectDetails && defectDetails[item.label], dateFormat).toDate();
      tempInitialValues[item.label] = myDate instanceof Date && !isNaN(myDate) ? myDate : '';
    } else {
      tempInitialValues[item.label] = (defectDetails && defectDetails[item.label]) || '';
    }
  }

  function createFormikSchema(defectTemplateData) {
    const { defectDetails } = selectedDefectData;
    defectTemplateData.forEach((item) => {
      const label = item.label?.toLowerCase();
      if (label === 'state') {
        if (drawerType === ADD) {
          item.options = defectMgmtData?.defect_life_cycle_templates[0]?.states || [];
          tempInitialValues[item.label] = item?.options?.[0] || '';
        }
      } else if (['domain', 'project'].includes(label)) {
        item.options = [...(label === 'domain' ? domain : project)];
        tempInitialValues[item.label] = '';
      } else {
        handleOtherFields(item, tempInitialValues, defectDetails);
      }
    });

    setInitialValues(tempInitialValues);
    setDefectDetails(defectTemplateData);
    const yepSchema = defectTemplateData.reduce(createYupSchema, {});
    tempValidateSchema = yup.object().shape(yepSchema);
    setValidateSchema(tempValidateSchema);
  }

  useEffect(() => {
    fetchModuleTree();
  }, []);

  async function fetchModuleTree() {
    try {
      const response = await getAllModuleTreeReq(false);
      if (response?.data?.responseObject?.moduleTree?.length) {
        setModuleTree(response.data.responseObject.moduleTree);
        setModuleLevelScriptCount(response?.data?.responseObject?.moduleLevelScriptCount);
      } else {
        setModuleTree([]);
      }
    } catch (err) {
      console.error('GET_MODULE_TREE :', err);
    }
  }

  const validateFileAlreadyExists = (newUploadedFiles, files) => {
    for (let i = 0; i < newUploadedFiles.length; i++) {
      for (let j = 0; j < files.length; j++) {
        if (files[j].name === newUploadedFiles[i].name) {
          return true;
        }
      }
    }
    return false;
  };

  const validateFilesSize = (existingFiles, newUploadedFiles) => {
    let fileSizeInBytes = 0;
    for (let i = 0; i < existingFiles?.length; i++) {
      fileSizeInBytes += existingFiles[i]?.sizeInBytes;
    }
    for (let i = 0; i < newUploadedFiles?.length; i++) {
      fileSizeInBytes += newUploadedFiles[i]?.size;
    }
    return fileSizeInBytes <= MAX_FILE_SIZE;
  };
  const onLoad = (event, data, setFieldValue, value) => {
    let allAtachmentValue = [];
    defectDetails.forEach((el) => {
      if (el.type === 'attachment') {
        allAtachmentValue.push(...formik?.values[el.id]);
      }
    });
    const allFiles = tempUploadFiles;
    const existingFiles = [...value];
    const newUploadedFiles = event?.target?.files;
    const newFilesToBeUploadedCopy = [...newFilesToBeUploaded];
    if (existingFiles?.length + newUploadedFiles?.length > MAX_FILE_UPLOAD_COUNT) {
      MyAlert.info(MAX_NO_OF_FILES_ALLOWED);
      event.target.value = '';
      return;
    }

    const existPlusNew = [...allAtachmentValue, ...existingFiles];
    if (validateFileAlreadyExists(newUploadedFiles, existPlusNew)) {
      MyAlert.info(DUPLICATE_FILE);
      event.target.value = '';
      return;
    }

    if (!validateFilesSize(existingFiles, newUploadedFiles)) {
      MyAlert.info(MAX_FILE_SIZE_ALLOWED);
      event.target.value = '';
      return;
    }
    let file;
    let unsupportedFile = [];
    for (let i = 0; i < newUploadedFiles.length; i++) {
      file = newUploadedFiles[i];
      if (file && validateFileExtension(newUploadedFiles[i], DEFECT_ATTACTMENT_FILE_ALLOWED)) {
        const obj = {
          name: file?.name,
          sizeInBytes: file?.size,
        };
        for (let j = 0; j < existPlusNew.length; j++) {
          if (existPlusNew[j].name === file?.name) {
            MyAlert.info(DUPLICATE_FILE);
            return;
          }
        }
        allFiles.push(obj);
        existingFiles.push(obj);
        newFilesToBeUploadedCopy.push({
          name: file?.name,
          sizeInBytes: file?.size,
          file: file,
        });
      } else {
        unsupportedFile.push(newUploadedFiles[i]);
      }
    }
    if (!isEmptyValue(unsupportedFile)) {
      MyAlert.warning(`${unsupportedFile?.length} unsupported file. Please check the file format.`);
    }
    setFieldValue(data?.label, existingFiles);
    setTempUploadFiles(allFiles);
    setNewFilesToBeUploaded(newFilesToBeUploadedCopy);

    event.target.value = '';
  };

  const deleteFile = (file) => {
    let files = tempUploadFiles;
    files = files.filter((fileVal) => {
      return file?.name !== fileVal?.name;
    });
    setTempUploadFiles(files);
    let newFilesToBeUploadedCopy = [...newFilesToBeUploaded];
    newFilesToBeUploadedCopy = newFilesToBeUploadedCopy.filter((fileVal) => {
      return file?.name !== fileVal?.name;
    });
    setNewFilesToBeUploaded(newFilesToBeUploadedCopy);
    const tempDeletedFiles = [...deletedFiles];
    tempDeletedFiles.push(file);
    setDeletedFiles(tempDeletedFiles);
  };

  const getHeaderData = (selectedValue, failedStepsData) => {
    const { scriptName, moduleId, scriptType: testCaseType } = failedStepsData?.scriptRunDetails || {};
    return {
      pageSize: 0,
      scriptName,
      moduleId,
      testCaseType: testCaseType || 'automation',
      searchedText: '',
      userName: selectedValue.userName,
      apiToken: selectedValue.apiToken,
      domain: selectedValue.domain,
    };
  };

  const updateFormAndState = (selectedValue, label) => {
    const { label: fieldValueToUpdate, domain: _name, userName: _userName, apiToken: _apiToken } = selectedValue;
    formik.setFieldValue(label, fieldValueToUpdate);
    setSelectedDomain(selectedValue);
    setAPIToken(_apiToken);
    setJiraAuthPayload({
      userName: _userName,
      domain: _name,
      apiToken: _apiToken,
    });
  };

  const handleOnChangeForDomain = async (value, label) => {
    setSelectedProject([]);
    setSelectedLinkIssue([]);
    setLinkIssue([]);
    setStopScrollApiCall(false);
    setSearchedText('');
    setIsSearch(false);
    setSearchReset(false);
    formik.setFieldValue('Project', '');
    formik.setFieldValue('linkIds', '');
    formik.setFieldTouched(label, true);

    if (value?.length) {
      const selectedValue = value[0];

      updateFormAndState(selectedValue, label);

      getProjectsForDomain(selectedValue?.domain, selectedValue?.userName, selectedValue?.apiToken);

      const headerData = getHeaderData(selectedValue, failedStepsData);

      getAllJiraLinkDefect(headerData);
    } else {
      formik.setFieldValue(label, '');
    }
  };

  const handleOnChangeForProject = async (value, label) => {
    setselectedAssignee([]);
    setSelectedIssueType([]);
    setSelectedProject(value ? value : []);
    formik.setFieldTouched(label, true);
    if (value?.length) {
      const fieldValueToUpdate = value[0]?.label;
      formik.setFieldValue(label, fieldValueToUpdate);
    } else {
      formik.setFieldValue(label, '');
    }
    const { name, userName, apiToken } = selectedDomain;
    if (value?.[0]?.id && name && userName && apiToken) {
      getProjectAssignee(value?.[0]?.id, name, userName, apiToken);
    }
    if (name && userName && apiToken && value[0]?.key) {
      getProjectIssueTypes(name, userName, apiToken, value[0]?.key);
    }
  };

  const handleOnChangeForAssignee = (value, label) => {
    setselectedAssignee(value ? value : []);
    formik.setFieldTouched(label, true);
    if (value?.length) {
      const fieldValueToUpdate = value[0]?.accountId;
      formik.setFieldValue(label, fieldValueToUpdate);
    } else {
      formik.setFieldValue(label, '');
    }
  };

  const handleOnChangeForIssueType = (value, label) => {
    setSelectedIssueType(value ? value : []);
    formik.setFieldTouched(label, true);
    if (value?.length) {
      const fieldValueToUpdate = value[0]?.value;
      formik.setFieldValue(label, fieldValueToUpdate);
    } else {
      formik.setFieldValue(label, '');
    }
  };

  const labelDataPassToParent = (labelData) => {
    setLabelSelectedOptions(labelData);
  };
  const shouldRenderData = (data) => {
    const forbiddenLabels = ['domain', 'project', 'assign to', 'link issue', 'issue type', 'labels'];
    return !forbiddenLabels.includes(data?.label.toLowerCase()) && data;
  };

  const defectMgmtDetailElements = (data, props, error, index) => {
    return (
      <>
        <div className="flex flex-col justify-center">
          <div className="flex">
            {data?.label && (
              <Label
                label={data?.label}
                required={data?.mandatory === 'yes'}
                className={
                  data?.label.toLowerCase() === 'labels'
                    ? 'ml-1 mt-4'
                    : data?.label.toLowerCase() !== 'domain'
                    ? 'truncate mt-4'
                    : ''
                }
              />
            )}
          </div>
          <div className="contentDataStyle mt-1 relative">
            {data?.label.toLowerCase() === 'domain' ? (
              <>
                <div className="">
                  <div variant="standard" className="w-full">
                    <div className="">
                      <SelectBox
                        showLabel={false}
                        name={data.id}
                        options={domain}
                        disabled={(data.enable && data.enable === NO) || isLoading ? true : false}
                        error={formik.errors[data.id] && formik.touched[data.id] ? true : false}
                        errMsg={error}
                        placeholder={data.placeholder ? data.placeholder : ''}
                        handleOptionChange={(value) => {
                          handleOnChangeForDomain(value, data?.label);
                        }}
                        selectedOption={[]}
                      />
                    </div>
                    {formik.errors[data.id] && formik.touched[data.id] && (
                      <p className={styles['errorText']}>{error}</p>
                    )}
                  </div>
                </div>
              </>
            ) : data?.label.toLowerCase() === 'project' ? (
              <>
                <div className="">
                  <div variant="standard" className="w-full">
                    <div className="">
                      <SelectBox
                        showLabel={false}
                        name={data.id}
                        options={project}
                        disabled={(data.enable && data.enable === NO) || isLoading ? true : false}
                        error={formik.errors[data.id] && formik.touched[data.id] ? true : false}
                        errMsg={error}
                        placeholder={data.placeholder ? data.placeholder : ''}
                        handleOptionChange={(value) => {
                          handleOnChangeForProject(value, data?.label);
                        }}
                        selectedOption={selectedProject}
                      />
                    </div>
                    {formik.errors[data.id] && formik.touched[data.id] && (
                      <p className={styles['errorText']}>{error}</p>
                    )}
                  </div>
                </div>
              </>
            ) : data?.label.toLowerCase() === 'assign to' ? (
              <>
                <div className="">
                  <div variant="standard" className="w-full">
                    <div className="">
                      <SelectBox
                        showLabel={false}
                        name={data.id}
                        options={assignee}
                        disabled={(data.enable && data.enable === NO) || isLoading ? true : false}
                        error={formik.errors[data.id] && formik.touched[data.id] ? true : false}
                        errMsg={error}
                        placeholder={data.placeholder ? data.placeholder : ''}
                        handleOptionChange={(value) => {
                          handleOnChangeForAssignee(value, data?.label);
                        }}
                        selectedOption={selectedAssignee}
                      />
                    </div>
                    {formik.errors[data.id] && formik.touched[data.id] && (
                      <p className={styles['errorText']}>{error}</p>
                    )}
                  </div>
                </div>
              </>
            ) : data?.label.toLowerCase() === 'link issue' ? (
              <>
                <div className="">
                  <div variant="standard" className="w-full">
                    <div className="">
                      <MultiSelectSearchableDropdown
                        placeholder={data.placeholder ? data.placeholder : ''}
                        data={linkIssue}
                        ref={linkIssueDropdownRef}
                        selectedData={selectedLinkIssue}
                        name="linkIds"
                        id="linkIds"
                        handleSelectChange={handleMultiSelectChange}
                        onDropdownOpen={() => {
                          scrollIntoView();
                        }}
                        setScrollPos={setJiraScrollPos}
                        onSearch={handleJiraIdSearch}
                        isSearch={isSearch}
                        setIsSearch={setIsSearch}
                        setSearchReset={setSearchReset}
                        error={checkFieldError('linkIds')}
                        editMode={editMode}
                        setEditMode={setEditMode}
                      />
                    </div>
                    {formik.errors[data.id] && formik.touched[data.id] && (
                      <p className={styles['errorText']}>{error}</p>
                    )}
                  </div>
                </div>
              </>
            ) : data?.label.toLowerCase() === 'issue type' ? (
              <>
                <div className="">
                  <div variant="standard" className="w-full">
                    <div className="">
                      <SelectBox
                        showLabel={false}
                        name={data.id}
                        options={issueType}
                        disabled={(data.enable && data.enable === NO) || isLoading ? true : false}
                        error={formik.errors[data.id] && formik.touched[data.id] ? true : false}
                        errMsg={error}
                        placeholder={data.placeholder ? data.placeholder : ''}
                        handleOptionChange={(value) => {
                          handleOnChangeForIssueType(value, data?.label);
                        }}
                        selectedOption={selectedIssueType}
                      />
                    </div>
                    {formik.errors[data.id] && formik.touched[data.id] && (
                      <p className={styles['errorText']}>{error}</p>
                    )}
                  </div>
                </div>
              </>
            ) : data?.label.toLowerCase() === 'labels' ? (
              <div className="-ml-1">
                <MultiAutocomplete
                  labelSelectedOptions={labelDataPassToParent}
                  labelResponse={setLabelResponseObject}
                  defects={true}
                  MyAlert={MyAlert}
                  resetLabels={resetLabels}
                  history={history}
                  cleanUpFunction={cleanUpFunction}
                />
              </div>
            ) : (
              <></>
            )}
            <DefectIssueFields
              data={shouldRenderData(data)}
              formikProps={props}
              error={error}
              formik={formik}
              deleteFile={deleteFile}
              onLoad={onLoad}
              drawerType={drawerType}
              moduleTree={moduleTree}
              selectedModule={selectedModule}
              defaultSelectedModule={defaultSelectedModule}
              moduleLevelScriptCount={moduleLevelScriptCount}
            />
          </div>
        </div>
      </>
    );
  };

  const linkIssueDropdownRef = useRef();

  function formatInput(input) {
    const parsedInput = JSON.parse(input);
    let output = '';
    parsedInput.blocks.forEach((block, index) => {
      const text = block.text.replace(/:/g, '');
      output += `${text}\n`;
    });

    return output;
  }

  const updatedLinkIssueData = selectedLinkIssue.reduce((acc, { key, id }) => {
    acc.push({
      [key]: id,
    });
    return acc;
  }, []);

  const createDataObject = (values, defectMgmtData, failedStepsData, selectedProject, apiToken, selectedDomain) => {
    return {
      defectTemplateId: defectMgmtData?.defect_details_templates[0]?._id,
      defectLifeCycleTemplateId: defectMgmtData?.defect_life_cycle_templates[0]?._id,
      scriptRunDetails: failedStepsData?.scriptRunDetails,
      domain: values?.Domain,
      project: selectedProject[0]?.key,
      apiToken: apiToken,
      userName: selectedDomain?.userName,
    };
  };

  const setStateTransitions = (defectMgmtData) => {
    return defectMgmtData?.defect_life_cycle_templates[0]?.state_transitions;
  };

  const formatDatesInDefectDetails = (defectDetails, formValues) => {
    defectDetails.forEach((data) => {
      if (
        data &&
        formValues.hasOwnProperty(data.label) &&
        formValues[data.label] &&
        data.type === 'date' &&
        !['Created On', 'Modified On'].includes(data.label)
      ) {
        formValues[data.label] = moment(formValues[data.label]).format(
          data.dateFormat && typeof data.dateFormat === 'string' ? data.dateFormat.toUpperCase() : 'DD-MM-YYYY'
        );
      }
    });
  };

  const handleOnSubmit = async (values) => {
    const formData = new FormData();

    const data = createDataObject(values, defectMgmtData, failedStepsData, selectedProject, apiToken, selectedDomain);

    const formValues = { ...values };

    delete formValues.Domain;
    delete formValues.Project;
    delete formValues.Comments;
    delete formValues.Labels;

    if (drawerType === ADD) {
      formValues['Created By'] = {};
      formValues['Modified By'] = {};
    }

    data.state_transitions = setStateTransitions(defectMgmtData);
    const defectDetails = defectMgmtData?.defect_details_templates[0]?.defectDetails;
    formatDatesInDefectDetails(defectDetails, formValues);

    data.defectDetails = formValues;
    data.defectDetails.linkIds = updatedLinkIssueData;
    data.defectDetails.Description = formatInput(formValues.Description);
    data.defectDetails.labels = projectLabelsObject.map((label) => label.name.replace(/\s/g, '_'));
    data.state = formValues['State'];
    formData.append('data', JSON.stringify(data));
    newFilesToBeUploaded.forEach((data) => {
      formData.append('file', data?.file);
    });

    try {
      let response;
      setIsLoading(true);
      if (drawerType === ADD) {
        try {
          response = await postJiraIssue(selectedProjectId, formData);
        } catch (error) {
          console.error('Post Jira Issue', error);
        }
      } else {
        const fileIds = [];
        deletedFiles.forEach((fileData) => {
          if (fileData?.id) {
            fileIds.push(fileData.id);
          }
        });
      }

      if (response?.data?.responseCode === 201) {
        if (drawerType === ADD) {
          props.MyAlert.success(`${response?.data?.responseObject?.defectId} ${CREATE_SUCCESS}`);
          closeDrawer(true);
          reloadTable(true);
        }
        tempInitialValues = {};
        tempValidateSchema = '';
        setSelectedDefectData({});
        initDefectData();
        reloadHandler(
          response?.data?.responseMap?.totalDefectsCount,
          response?.data?.responseObject,
          selectedDefectData?.defectDetails?.ID
        );
      } else if (
        response?.data?.responseCode === 200 &&
        response?.data?.message.includes('DefectTemplate validation Failure')
      ) {
        if (drawerType === ADD) {
          missMatchModalHandler(response?.data?.responseObject);
        }
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error(CREATE_DEFECT_ERR, err);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validateSchema,
    onSubmit: (values) => {
      handleOnSubmit(values);
    },
  });

  const handleMultiSelectChange = (values) => {
    if (values.length) {
      formik.setFieldValue('linkIds', values[0].key, true);
    } else {
      formik.setFieldValue('linkIds', '', true);
    }
    setSelectedLinkIssue([...values]);
  };

  //  TODO ::  ON CLICK SEARCH
  let handleJiraIdSearch = (e) => {
    if (e.trim() !== '') {
      setIsSearch(true);
      setSearchedText(e.trim());
      setPageSize(0);
      setStopScrollApiCall(false);
      linkIssueDropdownRef?.current?.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (!isSearch && searchReset) {
      setStopScrollApiCall(false);
      setPageSize(0);
      setLinkIssue([]);
      setSearchedText('');
      setSearchReset(false);
      linkIssueDropdownRef?.current?.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });

      getAllJiraLinkDefect({ pageSize: 0, searchVal: '', stopApiCall: false }); // NOTE :: RESET ON SEARCH TEXT CLEAR FROM DROPDOWN
    }
    if (isSearch && searchedText) {
      getAllJiraLinkDefect({ pageSize: 0, searchVal: searchedText });
    }
  }, [searchReset, isSearch, searchedText]);

  useEffect(() => {
    if (jiraScrollPos) {
      setPageSize((prevVal) => prevVal + 1);
    }
  }, [jiraScrollPos]);

  useEffect(() => {
    if (pageSize && isSearch && !stopScrollApiCall && searchedText) {
      getAllJiraLinkDefect({ pageSize, searchVal: searchedText });
    } else if (pageSize && !stopScrollApiCall && !isSearch) {
      getAllJiraLinkDefect({ pageSize });
    }
  }, [pageSize]);

  useEffect(() => {
    if (allInstances?.length) {
      setDomain([
        ...allInstances.map((ele) => ({
          ...ele,
          name: ele.domain,
          label: ele.domain,
          value: ele.domain,
        })),
      ]);
    } else {
      setDomain([]);
    }
  }, [allInstances]);

  const getProjectIssueTypes = async (name, userName, apiToken, key) => {
    try {
      setIsLoading(true);
      const res = await getAllIssueTypeForDomain(name, userName, apiToken, key);
      if (res?.data?.responseCode === 200 && res?.data?.responseObject?.length) {
        setIssueType([
          ...res.data.responseObject.map((issue) => ({
            ...issue,
            label: issue.name,
            value: issue.name,
          })),
        ]);
      } else {
        setIssueType([]);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error('GET_PROJECT_ISSUE_TYPES: ', err);
    }
  };

  const getProjectAssignee = async (id, name, userName, apiToken) => {
    try {
      setIsLoading(true);
      const res = await getAllAssignableUsers(id, name, userName, apiToken);
      if (res?.data?.responseCode === 200 && res?.data?.responseObject?.length) {
        setAssignee([
          ...res.data.responseObject.map((assigneeObj) => ({
            ...assigneeObj,
            name: assigneeObj.displayName,
            label: assigneeObj.displayName,
            value: assigneeObj.displayName,
          })),
        ]);
      } else {
        setAssignee([]);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error('GET_PROJECT_ASSIGNEE: ', err);
    }
  };

  const getProjectsForDomain = async (name, userName, apiToken) => {
    try {
      setIsLoading(true);
      const res = await getAllProjectsForDomain(name, userName, apiToken);
      if (res?.data?.responseCode === 200 && res?.data?.responseObject?.length) {
        setProjects([
          ...res.data.responseObject.map((project) => ({
            ...project,
            label: project.name,
            value: project.name,
          })),
        ]);
      } else {
        setProjects([]);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error('GET_PROJECTS_FOR_DOMAIN: ', err);
    }
  };

  const getAllJiraLinkDefect = async (propData) => {
    try {
      const { scriptName, moduleId, scriptType: testCaseType } = failedStepsData?.scriptRunDetails || {};
      let headerData = {
        scriptName,
        moduleId,
        testCaseType: testCaseType?.toLowerCase() || 'automation',
        pageSize: propData.pageSize,
        searchedText: propData?.searchVal || '',
        userName: propData.userName || jiraAuthPayload.userName,
        apiToken: propData.apiToken || jiraAuthPayload.apiToken,
        domain: propData.domain || jiraAuthPayload.domain,
      };
      setIsLoading(true);
      const res = await getAllJiraLinks(headerData);
      if (res?.data?.responseCode === 200 && res?.data?.responseObject?.length) {
        let JiralinkedIssueList = res.data.responseObject.map((linkedIssue) => ({
          ...linkedIssue,
          label: `${linkedIssue.key}  ${linkedIssue.summery}`,
          value: linkedIssue.key,
        }));

        let exsitingMappedIds = res.data.responseObject?.filter((linkedIssue) => {
          if (linkedIssue?.mappedScript) {
            return {
              ...linkedIssue,
              label: `${linkedIssue.key}  ${linkedIssue.summery}`,
              value: linkedIssue.key,
            };
          }
        });

        if (!isEmptyValue(exsitingMappedIds)) {
          setEditMode(true);
          setSelectedLinkIssue(exsitingMappedIds);
          formik.setFieldValue('linkIds', exsitingMappedIds[0].key, true);
        }

        if (headerData?.pageSize === 0) {
          setLinkIssue(JiralinkedIssueList);
        } else {
          setLinkIssue([...linkIssue, ...JiralinkedIssueList]);
        }
      } else if (res?.data?.responseCode === 204 && res?.data?.status === 'NO_CONTENT') {
        setStopScrollApiCall(true);
        if (headerData.pageSize === 0) {
          setLinkIssue([]);
        }
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error('GET_LINKJIRA_DEFECT_FOR_DOMAIN_ERROR: ', err);
    }
  };

  const checkFieldError = (fieldName) => (formik.errors?.[fieldName] && formik.touched?.[fieldName] ? true : false);

  const renderFooterContent = () => {
    return (
      <div className="flex flex-row justify-end -mt-3">
        <div>
          <CommonButton btnType="secondary" label={DEFECT_CONSTANTS.CANCEL} onClick={closeDrawer} />
        </div>
        <div className="ml-4">
          <CommonButton
            disabled={isLoading}
            label={DEFECT_CONSTANTS.CREATE_ISSUE}
            type="submit"
            form={JIRA_ISSUE_DRAWER.FORM_ID}
          />
        </div>
      </div>
    );
  };

  return (
    <CommonDrawerJs
      isDrawerOpen={openDrawer}
      titleText={JIRA_ISSUE_DRAWER.FORM_LABEL}
      drawerWidth={JIRA_ISSUE_DRAWER.DRAWER_WIDTH}
      footerContent={renderFooterContent()}
      onDrawerClose={closeDrawer}
      backButton={props?.backButtonRequired}
      onBackButtonClick={closeDrawer}
    >
      <form id={JIRA_ISSUE_DRAWER.FORM_ID} onSubmit={formik.handleSubmit}>
        {defectMgmtData?.defect_details_templates[0]?.defectDetails && (
          <div className={styles['drawer-body-height']}>
            <div className={styles['alert-custom-style']}>
              <AlertContatiner></AlertContatiner>
            </div>
            <div className="p-4 justify-center items-center">
              {defectDetails?.map((data, index) => {
                let error = formik.errors.hasOwnProperty(data.id) && formik.errors[data.id];
                return ['id', 'modified by', 'created by', 'created on', 'modified on', 'comments'].includes(
                  data?.label.toLowerCase()
                ) ? (
                  <></>
                ) : (
                  <Grid item xs={4} sm={8} md={12} key={data?.id}>
                    {defectMgmtDetailElements(data, formik, error, index)}
                  </Grid>
                );
              })}
            </div>
          </div>
        )}
      </form>
    </CommonDrawerJs>
  );
};

export default JiraIssueDrawer;

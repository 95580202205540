import { createScriptZipFile, downloadScriptZipFile } from '../../../api/api_services';

export const getBrowserName = (browserName) => {
  if (browserName.toLowerCase().includes('chrome')) {
    return 'chrome';
  } else if (
    browserName.toLowerCase().includes('explorer') ||
    browserName.toLowerCase().includes('internet') ||
    browserName.toLowerCase().includes('ie')
  ) {
    return 'Internet Explorer';
  } else if (browserName.toLowerCase().includes('firefox')) {
    return 'firefox';
  } else if (browserName.toLowerCase().includes('edge') || browserName.toLowerCase().includes('microsoft')) {
    return 'MicrosoftEdge';
  } else if (browserName.toLowerCase().includes('safari')) {
    return 'safari';
  } else if (browserName.toLowerCase().includes('opera')) {
    return 'opera';
  } else {
    return browserName;
  }
};

export const getPagesName = (elementData) => {
  let newPageName = '';
  if (elementData.isShared?.toLowerCase() === 'yes' || elementData.isShared?.toLowerCase() === 'y') {
    elementData.path.split(',/Root/').forEach((path, index) => {
      if (index === 0) {
        newPageName += path.slice(6, path.length - 1);
      } else {
        newPageName += ', ' + path.slice(0, path.length - 1);
      }
    });
  } else {
    newPageName = elementData.pageName || elementData.parentName;
  }
  return newPageName;
};

export const getStepId = () => {
  const currentTime = new Date();
  return `STP${Math.floor(
    Math.random() * (99999 - 100 + 1) + 100
  )}M${currentTime.getMonth()}D${currentTime.getDate()}H${currentTime.getHours()}M${currentTime.getMinutes()}S${currentTime.getSeconds()}M${currentTime.getMilliseconds()}R${Math.floor(
    Math.random() * (99 - 1 + 1) + 1
  )}`;
};

export const getUniqueId = (prefix) => {
  const currentTime = new Date();
  const _uniqueID = `${prefix}${Math.floor(
    Math.random() * (999 - 100 + 1) + 100
  )}${currentTime.getMonth()}${currentTime.getDate()}${currentTime.getHours()}${currentTime.getMinutes()}${currentTime.getSeconds()}${currentTime.getMilliseconds()}`;
  return _uniqueID;
};

export const encodeSpecialCharater = (stringToEncode) => {
  if (stringToEncode?.includes('#')) {
    stringToEncode = stringToEncode.replaceAll('#', '#H#S');
  }
  if (stringToEncode?.includes('%')) {
    stringToEncode = stringToEncode.replaceAll('%', '#R#E');
  }
  if (stringToEncode?.includes('*')) {
    stringToEncode = stringToEncode.replaceAll('*', '#P#H');
  }
  if (stringToEncode?.includes('\\')) {
    stringToEncode = stringToEncode.replaceAll('\\', '&#92');
  }
  if (stringToEncode?.includes('(')) {
    stringToEncode = stringToEncode.replaceAll('(', '#P#I');
  }
  return encodeURIComponent(stringToEncode);
};

export function decodeSpecialCharacter(stringToDecode) {
  stringToDecode = decodeURIComponent(stringToDecode);
  if (stringToDecode.includes('#R#E')) {
    stringToDecode = stringToDecode.replaceAll('#R#E', '%');
  }
  if (stringToDecode.includes('#P#H')) {
    stringToDecode = stringToDecode.replaceAll('#P#H', '*');
  }
  if (stringToDecode.includes('&#92')) {
    stringToDecode = stringToDecode.replaceAll('&#92', '\\');
  }
  if (stringToDecode.includes('#P#I')) {
    stringToDecode = stringToDecode.replaceAll('#P#I', '(');
  }
  if (stringToDecode.includes('#H#S')) {
    stringToDecode = stringToDecode.replaceAll('#H#S', '#');
  }
  return stringToDecode;
}

export const exportScript = async (scriptId, moduleID) => {
  try {
    const zipFileResponse = await createScriptZipFile(moduleID, scriptId);
    if (zipFileResponse.data && zipFileResponse.data.responseObject) {
      const downloadResponse = await downloadScriptZipFile(zipFileResponse.data.responseObject.id);
      if (downloadResponse && downloadResponse.data) {
        saveFileFromBlob(downloadResponse.data, zipFileResponse.data.responseObject.name);
      }
    }
  } catch (err) {
    console.error(err);
  }
};

export function saveFileFromBlob(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }
}

export function getExecutionName(executionTree) {
  const executionTreeChildren = executionTree[0]?.children;
  if (executionTreeChildren?.length) {
    if (executionTreeChildren?.length > 1) {
      return executionTree[0]?.title;
    } else {
      const childrenArray = executionTreeChildren[0];
      if (['PRE', 'POST'].includes(childrenArray.type)) {
        return executionTree[0]?.title;
      } else {
        return getExecutionName([childrenArray]);
      }
    }
  } else {
    return executionTree[0]?.title;
  }
}

export const getAutomationScriptId = (scriptId, testCaseType) => {
  let scriptObj = testCaseType?.find((o) => o.type.toLowerCase() === 'automation');
  return scriptObj ? scriptObj.id : scriptId;
};

export const FORM_FIELDS = {
  TEXTFIELD: 'textField',
  TEXTBOX: 'textArea',
  DROPDOWN: 'dropdown',
  CHECKBOX: 'checkbox',
  RADIOBUTTON: 'radioButton',
  LINK: 'link',
  ATTACHMENT: 'attachment',
  DATE: 'date',
  TEXTEDITOR: 'textEditor',
};

export const FORM_FIELDS_ARRAY = [
  FORM_FIELDS.TEXTFIELD,
  FORM_FIELDS.TEXTBOX,
  FORM_FIELDS.DROPDOWN,
  FORM_FIELDS.CHECKBOX,
  FORM_FIELDS.RADIOBUTTON,
  FORM_FIELDS.LINK,
  FORM_FIELDS.ATTACHMENT,
  FORM_FIELDS.DATE,
  FORM_FIELDS.TEXTEDITOR,
];

export const MESSAGES = {
  CREATE_SUCCESS: 'Defect created successfully',
  UPDATE_SUCCESS: 'Defect updated successfully',
  ALPHA_ERR_MSG: 'Input should be alphanumeric',
  CREATE_DEFECT_ERR: 'Create Defect error',
  REQUIRED_ERR_MSG: (fieldName) => `${fieldName} is required`,
  NO_SPACE_AT_START_AND_END_MSG: 'Space is not allowed at starting and at the end',
  YES: 'yes',
  VALID_URL: 'Enter valid url',
  MAX_NO_OF_FILES_ALLOWED: 'Maximun file(s) can be uploaded: 5',
  DUPLICATE_FILE: 'This file is already selected for this defect',
  MAX_FILE_SIZE_ALLOWED: 'Maximun file(s) size allowed: 30 mb',
  CREATE_DEFECT: 'Create Defect',
  EDIT_DEFECT: 'Edit Defect',
  CREATE_ANOTHER_DEFECT: 'Create another defect',
  CREATE: 'Create',
  UPDATE: 'Update',
  ADD: 'add',
  EDIT: 'edit',
  NO: 'no',
  MAX_FILE_SIZE: 31457280,
};

export const DEFECT_DATE_FIELD = {
  CREATED_ON: 'Created On',
  MODIFIED_ON: 'Modified On',
};

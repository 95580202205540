import React from 'react';
import { Popup, Button } from 'fireflink-ui';
import { WARNING_POPUP_CONSTANTS, MEMORYCONFIG_CONSTANTS } from '@src/common/ui-constants';
import styles from './memory-warning.module.scss';
import { getCurrentPrivilage, getUserName } from '@src/util/common_utils';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';

const MemoryWarningPopup = ({ isOpen, handleCancel, handleContinue }) => {
  const history = useHistory();

  const CustomFooter = () => {
    const handleLinkClick = () => {
      localStorage.setItem('selected-project', JSON.stringify({ id: '', name: 'All Projects' }));
      setTimeout(() => {
        history.push('/configuration/memoryConfiguration');
      }, 500);
    };
    return (
      <div className={styles['custom-footer']}>
        <div className={styles['footer-left']}>
          <span onClick={handleLinkClick} className={cx('fontPoppinsRegularMd', styles['link'])}>
            {MEMORYCONFIG_CONSTANTS?.MANAGE_MEMORY}
          </span>
        </div>
        <div className={styles['footer-right']}>
          <Button size="small" variant="secondary" onClick={handleCancel} label="Cancel" />
          <Button size="small" variant="primary" onClick={() => handleContinue(true)} label="Continue" />
        </div>
      </div>
    );
  };

  return (
    isOpen && (
      <Popup
        footerContent={getCurrentPrivilage() !== 'User' && <CustomFooter />}
        onContinueClick={() => handleContinue(true)}
        onCancelClick={handleCancel}
        isOpen={isOpen}
        headerTitle={WARNING_POPUP_CONSTANTS?.WARNING}
        headerText={WARNING_POPUP_CONSTANTS?.DELETE}
        type="warning"
      >
        <div>
          <p className="fontPoppinsRegularMd">Hi {getUserName()},</p>
          {getCurrentPrivilage() === 'User' ? (
            <>
              <p className="fontPoppinsRegularMd mt-4">{MEMORYCONFIG_CONSTANTS?.MEMORY_FULL_USER}</p>
              <p className="fontPoppinsRegularMd mt-4">
                {MEMORYCONFIG_CONSTANTS?.CONTACT_YOUR} <span className="fontPoppinsSemiboldMd">Super Admin</span> or{' '}
                <span className="fontPoppinsSemiboldMd">Admin</span> {MEMORYCONFIG_CONSTANTS?.TO_MANAGE}{' '}
                <span className="fontPoppinsSemiboldMd">{MEMORYCONFIG_CONSTANTS?.MEMORY_LIMIT}</span>.
              </p>
              <p className="fontPoppinsRegularMd mt-4">{MEMORYCONFIG_CONSTANTS?.HOW_DO_YOU_WANT_TO_PROCEED}</p>
            </>
          ) : (
            <>
              <p className="fontPoppinsRegularMd mt-4">
                {MEMORYCONFIG_CONSTANTS?.MEMORY_FULL}{' '}
                <span className="fontPoppinsSemiboldMd">{MEMORYCONFIG_CONSTANTS?.SCREENSHOTS_OR_VIDEOS}</span>
              </p>
              <p className="fontPoppinsRegularMd mt-4">{MEMORYCONFIG_CONSTANTS?.HOW_DO_YOU_WANT_TO_PROCEED}</p>
            </>
          )}
        </div>
      </Popup>
    )
  );
};

export default MemoryWarningPopup;

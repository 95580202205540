import React from 'react';
import Checkboxes from '../../../common/checkbox_page';
import EmailRecipients from './email-child-pages/email-recipients';
import EmailReport from './email-child-pages/email-report';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { FiMaximize2 } from 'react-icons/fi';
import { FaSearch } from 'react-icons/fa';
import { useTable, usePagination, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy } from 'react-table';
import matchSorter from 'match-sorter';
import Pagination from '../../../common/pagination';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import TagFacesIcon from '@material-ui/icons/TagFaces';
import ChipInput from 'material-ui-chip-input';
import validator from 'validator';
import { getAllEmailGroupListReq } from '../../../../api/api_services';
import { FormikConsumer, useFormik } from 'formik';
import * as Yup from 'yup';
import OutsideAlerter from '../../../common/OutsideAlerter';
import EmailDropdown from '@src/pages/defects/email-configuration-Dropdown';
import { scrollIntoView } from '@pages/test-development/script/modules/module/utils/common-functions';
import { split } from 'react-ace';
import { useEffect } from 'react';
import { Tooltip } from '@material-ui/core';
import { TEST_ENV_TABLE_WIDTH_SET } from '@src/common/ui-constants';
import { EMAIL_NOTIFICATION_CONSTANTS } from '@src/pages/configuration/plugin/plugin_constants';
import { booleanToString } from '@src/util/common_utils';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

let selectedArrayForNotification = [];
let selectedArrayForAttachments = [];
let selectedEmailGroupArray = [];
let EmailTableConcateArray = [];
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));
const EmailPage = (props) => {
  let automationSuiteNotificationType = ['On Suite Execution Start', 'On Suite Execution Completion / Termination'];
  let manualSuiteNotificationType = ['Manually trigger email notification'];
  let notificationTypes = props?.suiteType
    ? props.suiteType === 'Manual'
      ? manualSuiteNotificationType
      : automationSuiteNotificationType
    : automationSuiteNotificationType;
  let attachmentTypes = ['PDF', 'HTML'];
  let emailData = ['Email the report after Execution'];
  const [goInsideProps, setGoInsideProps] = React.useState(
    props && props.suiteObjMain && props.suiteObjMain.name ? true : false
  );
  const [selectedTab, setSelectedTab] = React.useState('email-recipients');
  const [emailReportSelected, setEmailReportSelected] = React.useState(
    props.suiteObjMain &&
      props.suiteObjMain.emailData &&
      props.suiteObjMain.emailData.emailTheReportAfterExecution === 'true'
      ? 'true'
      : props.suiteObjForPreview &&
        props.suiteObjForPreview.emailData &&
        props.suiteObjForPreview.emailData.emailTheReportAfterExecution === 'true'
      ? 'true'
      : 'false'
  );
  const [emailObj, setEmailObj] = React.useState({
    emailManuallyTriggerEmailNotification: props?.suiteObjMain?.emailData?.emailManuallyTriggerEmailNotification
      ? props.suiteObjMain.emailData.emailManuallyTriggerEmailNotification
      : props?.suiteObjForPreview?.emailData?.emailManuallyTriggerEmailNotification
      ? props.suiteObjForPreview.emailData.emailManuallyTriggerEmailNotification
      : 'false',
    emailTheReportAfterExecution: emailReportSelected,
    recipientGroup: props?.suiteObjMain?.emailData?.recipientGroup ? props.suiteObjMain.emailData.recipientGroup : [],
    emailRecipient: props?.suiteObjMain?.emailData?.emailRecipient ? props.suiteObjMain.emailData.emailRecipient : [],
    emailOnSuiteExecutionStart: props?.suiteObjForPreview?.emailData?.emailOnSuiteExecutionStart
      ? props.suiteObjForPreview.emailData.emailOnSuiteExecutionStart
      : props?.suiteObjMain?.emailData?.emailOnSuiteExecutionStart
      ? props.suiteObjMain.emailData.emailOnSuiteExecutionStart
      : 'false',
    emailOnSuiteExecutionCompletion: props?.suiteObjMain?.emailData?.emailOnSuiteExecutionCompletion
      ? props.suiteObjMain.emailData.emailOnSuiteExecutionCompletion
      : props?.suiteObjForPreview?.emailData?.emailOnSuiteExecutionCompletion
      ? props.suiteObjForPreview.emailData.emailOnSuiteExecutionCompletion
      : 'false',
    attachPdfReport: props?.suiteObjMain?.emailData?.attachPdfReport
      ? props.suiteObjMain.emailData.attachPdfReport
      : props?.suiteObjForPreview?.emailData?.attachPdfReport
      ? props.suiteObjForPreview.emailData.attachPdfReport
      : 'false',
    attachHtmlReport: props?.suiteObjMain?.emailData?.attachHtmlReport
      ? props.suiteObjMain.emailData.attachHtmlReport
      : props?.suiteObjForPreview?.emailData?.attachHtmlReport
      ? props.suiteObjForPreview.emailData.attachHtmlReport
      : 'false',
    attachExecutionLogs: props?.suiteObjMain?.emailData?.attachExecutionLogs
      ? props.suiteObjMain.emailData.attachExecutionLogs
      : props?.suiteObjForPreview?.emailData?.attachExecutionLogs
      ? props.suiteObjForPreview.emailData.attachExecutionLogs
      : 'false',
  });
  let {
    emailOnSuiteExecutionStart,
    emailOnSuiteExecutionCompletion,
    attachHtmlReport,
    attachPdfReport,
    emailManuallyTriggerEmailNotification,
  } = emailObj;
  const [actionPerformed, setActionPerformed] = React.useState(false);
  const [pageSizeValue, setPageSize] = React.useState(5);
  const [pageIndexValue, setPageIndexValue] = React.useState(0);
  const [chipObj, setChipObj] = React.useState();
  const [chipInput, setChipInput] = React.useState(false);
  const [userEmailData, setUserEmailData] = React.useState([]);
  const [selectedConfigEmailData, setSelectedConfigEmailData] = React.useState([]);

  const [updateClicked, setUpdateClicked] = React.useState(false);
  const [emailGroupBackUpArray, setEmailGroupBackUpArray] = React.useState([]);
  const emailDependency = [
    emailOnSuiteExecutionStart,
    emailOnSuiteExecutionCompletion,
    attachHtmlReport,
    attachPdfReport,
    emailManuallyTriggerEmailNotification,
  ];
  const emailCollpaseCheck = emailDependency.every((emailData) => emailData == 'false');
  useEffect(() => {
    if (emailCollpaseCheck) {
      setEmailReportSelected('false');
      setSelectedConfigEmailData([]);
      setUserEmailData([]);
      EmailTableConcateArray = [];
      setEmailObj({ ...emailObj, recipientGroup: [], emailRecipient: [], emailTheReportAfterExecution: 'false' });
    }
  }, [...emailDependency]);

  function getAllEmailGroup() {
    getAllEmailGroupListReq()
      .then((results) => {
        let response = results.data.responseObject ? results.data.responseObject : [];
        if (response) {
          const fetchLatestresponse = response.map((obj) => {
            return { ...obj, label: obj.name, value: obj.name };
          });

          setEmailGroupBackUpArray([...fetchLatestresponse]);

          let emailGroupTempArray = [];
          let tempEditGroupArray = [];
          let emailEditRecipient = [];
          let userEmailEditData = [];
          let userEditIndividualData = [];
          let userTempEmailData = [];
          let tempEmail = [];
          if (props.operation === 'create') {
            if (props && props.suiteObjForPreview && props.suiteObjForPreview.emailData) {
              if (props.suiteObjForPreview.emailData.recipientGroup?.length) {
                let emailData = props.suiteObjForPreview.emailData.recipientGroup;
                let tempGroupArray = [];
                if (emailData.length) {
                  emailData?.map((obj) => {
                    const emailGroupObj = response.find((syelement) => syelement.name === obj.groupName);
                    if (emailGroupObj) {
                      emailGroupObj['label'] = emailGroupObj.name;
                      tempGroupArray.push(emailGroupObj);
                    }
                  });
                }
                emailObj.recipientGroup = props.suiteObjForPreview.emailData.recipientGroup;
                emailGroupTempArray = [...new Set(tempGroupArray)];
              }

              if (props && props.suiteObjForPreview && props.suiteObjForPreview.emailData.emailRecipient.length) {
                emailObj.emailRecipient = props.suiteObjForPreview.emailData.emailRecipient;
              }
              let userTempEmail = emailGroupTempArray.concat(emailObj.emailRecipient);
              setUserEmailData([...userTempEmail]);
              EmailTableConcateArray = emailObj.recipientGroup.concat(emailObj.emailRecipient);
              let tempEmailGroupBackupArray = fetchLatestresponse.concat(emailObj.emailRecipient);
              setEmailGroupBackUpArray([...tempEmailGroupBackupArray]);
              props.createSuiteMainFunction(emailObj);
            }
          }

          if (props.operation === 'update' || props.rerunMode) {
            if (props?.suiteObjForPreview?.emailData) {
              if (props.suiteObjForPreview.emailData.recipientGroup?.length) {
                let emailData = props.suiteObjForPreview.emailData.recipientGroup;
                let tempGroupArray = [];
                if (emailData.length) {
                  emailData?.map((obj) => {
                    const emailGroupObj = response.find((syelement) => syelement.name === obj.groupName);
                    if (emailGroupObj) {
                      emailGroupObj['label'] = emailGroupObj.name;
                      tempGroupArray.push(emailGroupObj);
                    }
                  });
                }
                emailObj.recipientGroup = props.suiteObjForPreview.emailData.recipientGroup;
                emailGroupTempArray = [...new Set(tempGroupArray)];
              }

              if (props && props.suiteObjForPreview && props.suiteObjForPreview.emailData.emailRecipient.length) {
                emailObj.emailRecipient = props.suiteObjForPreview.emailData.emailRecipient;
              }

              userEmailEditData = props.suiteObjForPreview.emailData.emailRecipient.map((obj) => {
                return { ...obj, label: obj.emailId, value: obj.emailId };
              });
              userEditIndividualData = userEmailEditData.map((values) => values);
              let userTempEmail = emailGroupTempArray.concat(userEditIndividualData);
              setUserEmailData([...userTempEmail]);
              EmailTableConcateArray = emailObj.recipientGroup.concat(emailObj.emailRecipient);
              let tempEmailGroupBackupArray = fetchLatestresponse.concat(userEditIndividualData);
              setEmailGroupBackUpArray([...tempEmailGroupBackupArray]);
              emailObj['emailOnSuiteExecutionStart'] = props?.suiteObjForPreview?.emailData?.emailOnSuiteExecutionStart;
              emailObj['emailOnSuiteExecutionCompletion'] =
                props?.suiteObjForPreview?.emailData?.emailOnSuiteExecutionCompletion;
              emailObj['attachHtmlReport'] = props?.suiteObjForPreview?.emailData?.attachHtmlReport;
              emailObj['attachPdfReport'] = props?.suiteObjForPreview?.emailData?.attachPdfReport;
              props.createSuiteMainFunction(emailObj);
            } else if (props?.suiteObjMain?.emailData) {
              if (props?.suiteObjMain?.emailData?.recipientGroup?.length) {
                const isStringsArray = (arr) => arr.every((i) => typeof i === 'string');
                let stringArrayOrNot = isStringsArray(props?.suiteObjMain?.emailData?.recipientGroup);
                let emailData = props.suiteObjMain.emailData.recipientGroup;
                if (!stringArrayOrNot) {
                  let tempEmailObj = props.suiteObjMain.emailData.recipientGroup;
                  const tempEmailRecArray = [];
                  tempEmailObj?.map((obj) => {
                    tempEmailRecArray?.push(obj?.groupName);
                  });
                  emailData = [...new Set(tempEmailRecArray)];
                }
                if (emailData.length) {
                  emailData?.map((obj) => {
                    const emailGroup = response.find((syelement) => syelement.name === obj);
                    if (emailGroup) {
                      emailGroup['label'] = emailGroup.name;
                      tempEditGroupArray.push(emailGroup);
                    }
                  });
                  if (tempEditGroupArray && tempEditGroupArray.length) {
                    tempEditGroupArray.map((selData, index) => {
                      if (selData && selData.emailUserList) {
                        selData.emailUserList.map((userData, index) => {
                          userData.groupName = selData.name;
                          emailEditRecipient.push(userData);
                        });
                      }
                    });
                  }
                }
                emailObj.recipientGroup = emailEditRecipient;
              }

              if (props.suiteObjMain.emailData?.emailRecipient && props.suiteObjMain.emailData?.emailRecipient.length) {
                emailObj.emailRecipient = props.suiteObjMain.emailData.emailRecipient;
                userEmailEditData = props.suiteObjMain.emailData.emailRecipient.map((obj) => {
                  return { ...obj, label: obj.emailId, value: obj.emailId };
                });
              }

              userEditIndividualData = userEmailEditData.map((values) => values);
              userTempEmailData = tempEditGroupArray.concat(userEditIndividualData);
              emailData['emailOnSuiteExecutionStart'] = props?.suiteObjMain?.emailData?.emailOnSuiteExecutionStart;
              emailData['emailOnSuiteExecutionCompletion'] =
                props?.suiteObjMain?.emailData?.emailOnSuiteExecutionCompletion;
              emailData['emailTheReportAfterExecution'] = 'true';
              emailData['emailManuallyTriggerEmailNotification'] = 'true';
              setUserEmailData([...userTempEmailData]);
              EmailTableConcateArray = emailObj.recipientGroup.concat(emailObj.emailRecipient);
              tempEmail = fetchLatestresponse.concat(userEditIndividualData);
              setEmailGroupBackUpArray([...tempEmail]);
              props.createSuiteMainFunction(emailObj);
            }
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
    return [];
  }

  React.useEffect(() => {
    if (props?.suiteObjMain?.emailData) {
      EmailTableConcateArray = emailObj.recipientGroup.concat(emailObj.emailRecipient);
      props.createSuiteMainFunction(emailObj);
      let emailGroupValues = emailGroupBackUpArray;
      setEmailGroupBackUpArray([...emailGroupValues]);
    } else {
      selectedArrayForNotification = [];
      selectedArrayForAttachments = [];
      selectedEmailGroupArray = [];
      EmailTableConcateArray = [];
    }
    getAllEmailGroup();
  }, []);

  const formik = useFormik({
    initialValues: {
      emailId: '',
      description: '',
      syncScriptOption: 'No Sync',
    },
  });

  function getValues(e) {
    console.error(`e`, e);
  }

  function handleChange(chip) {
    let obj = chip?.target?.value;
    setChipObj(obj);
  }

  function closeEmailGroup() {
    if (emailObj?.recipientGroup?.length) {
      let emailGroupTempArray = [];
      emailObj?.recipientGroup?.map((obj) => {
        if (obj?.groupName) {
          emailGroupTempArray.push(obj?.groupName);
        }
      });
      if (emailGroupTempArray?.length) {
        let uniqueChars = [...new Set(emailGroupTempArray)];
        selectedEmailGroupArray = [...uniqueChars];
      }
    } else {
      selectedEmailGroupArray = [];
    }
    let htmlEle = document.getElementById('groupCount');
    if (htmlEle) {
      htmlEle.value = '';
    }
  }

  function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);

    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);

    return (
      <span>
        Search:{' '}
        <input
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`Type here...`}
          style={{
            fontSize: '1.1rem',
            border: '0',
          }}
        />
      </span>
    );
  }

  // Define a default UI for filtering
  function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;
    return (
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search...`}
      />
    );
  }

  // This is a custom filter UI for selecting
  // a unique option from a list
  function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  // This is a custom filter UI that uses a
  // slider to set the filter value between a column's
  // min and max values
  function SliderColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    // Calculate the min and max
    // using the preFilteredRows
    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      preFilteredRows.forEach((row) => {
        min = Math.min(row.values[id], min);
        max = Math.max(row.values[id], max);
      });
      return [min, max];
    }, [id, preFilteredRows]);

    return (
      <>
        <input
          type="range"
          min={min}
          max={max}
          value={filterValue || min}
          onChange={(e) => {
            setFilter(parseInt(e.target.value, 10));
          }}
        />
        <button onClick={() => setFilter(undefined)}>Off</button>
      </>
    );
  }

  // This is a custom UI for our 'between' or number range
  // filter. It uses two number boxes and filters rows to
  // ones that have values between the two
  function NumberRangeColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id } }) {
    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      preFilteredRows.forEach((row) => {
        min = Math.min(row.values[id], min);
        max = Math.max(row.values[id], max);
      });
      return [min, max];
    }, [id, preFilteredRows]);

    return (
      <div
        style={{
          display: 'flex',
        }}
      >
        <input
          value={filterValue[0] || ''}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
          }}
          placeholder={`Min (${min})`}
          style={{
            width: '70px',
            marginRight: '0.5rem',
          }}
        />
        to
        <input
          value={filterValue[1] || ''}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
          }}
          placeholder={`Max (${max})`}
          style={{
            width: '70px',
            marginLeft: '0.5rem',
          }}
        />
      </div>
    );
  }

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val) => !val;

  // Our table component
  function Table({ columns, data }) {
    const filterTypes = React.useMemo(
      () => ({
        // Add a new fuzzyTextFilterFn filter type.
        fuzzyText: fuzzyTextFilterFn,
        // Or, override the default text filter to use
        // "startWith"
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      []
    );

    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      nextPage,
      previousPage,
      state,
      visibleColumns,
      preGlobalFilteredRows,
      setGlobalFilter,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
        // initialState: { pageIndex: 0, pageSize: pageSizeValue },
        // initialState: { pageIndex: pageIndexValue, pageSize: pageSizeValue },
      },
      useFilters, // useFilters!
      useGlobalFilter, // useGlobalFilter!
      useSortBy,
      usePagination
    );
    return (
      <>
        <table class="relative w-full " {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr class="mt-4" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    class={`sticky top-0 px-4 py-3 text-current table-header_font-style bg-gray-100 ${
                      TEST_ENV_TABLE_WIDTH_SET.includes(column.totalWidth) ? 'w-' + column.width + 'px' : ''
                    }`}
                    {...column.getHeaderProps()}
                  >
                    <span className="flex flex-row">
                      {column.render('Header')}
                      {/* Render the columns filter UI */}
                      <div>
                        <span>
                          {/* {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <AiOutlineArrowDown className="mt-1 ml-1" />
                                                            : <AiOutlineArrowUp className="mt-1 ml-1" />
                                                        : ''} */}
                        </span>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr className="project-row" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td class="px-4 py-3 text-center" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <br />
      </>
    );
  }

  // We don't want to render all of the rows for this example, so cap
  // it for this use case

  function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue >= filterValue;
    });
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        columns: [],
      },

      {
        Header: 'EMAIL ID',
        accessor: 'emailId',
        width: '400',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <Tooltip title={value}>
              <div className="table-non-link-color-common float-left font-style-common w-400px truncate">{value}</div>
            </Tooltip>
          );
        },
      },

      {
        Header: 'RECIPIENT NAME',
        accessor: 'name',
        width: '240',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <Tooltip title={value ? value : 'NA'}>
              <div className="table-non-link-color-common float-left font-style-common w-240px truncate">
                {value ? value : 'NA'}
              </div>
            </Tooltip>
          );
        },
      },
      {
        Header: 'RECIPIENTS GROUP',
        accessor: 'groupName',
        width: '240',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <Tooltip title={value ? value : 'NA'}>
              <div className="table-non-link-color-common float-left font-style-common w-240px truncate">
                {value ? value : 'NA'}
              </div>
            </Tooltip>
          );
        },
      },
    ],
    []
  );

  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage] = React.useState(2);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const [operation, setOperation] = React.useState('Create');
  const [showModal, setShowModal] = React.useState(false);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  function setImmediatePrevPage() {
    let prevPageNo = currentPage - 1;
    setCurrentPage(prevPageNo);
  }

  function setImmediateNextPage() {
    let prevPageNo = currentPage + 1;
    setCurrentPage(prevPageNo);
  }

  const handleChangeValues = (values) => {
    let emails = [];
    let emailGroups = [];
    let emailValues = [];
    var addUserEmailId = [];
    let splitGroupMail = [];
    let emailTableArray = [];
    let groupMail = [];
    let individualMailCopy = [];
    let groupMailCopy = [];

    values.map((individualMail) => {
      if (!individualMail?.id) {
        emails.push(individualMail);
      }
    });

    if (values?.length) {
      if (emails?.length) {
        values.map((obj) => {
          if (obj?.emailUserList) {
            let tempArray = obj?.emailUserList;
            tempArray.map((tempObj) => {
              let index = emails.findIndex((optionValue) => optionValue.emailId === tempObj.emailId);
              if (index > -1) {
                let childIndex = values.findIndex((recEmailObj) => recEmailObj?.emailId === emails[index].emailId);
                if (childIndex > -1) {
                  values.splice(childIndex, 1);
                }
              }
            });
          }
        });
      }
    }

    setUserEmailData([...values]);

    values.map((data, index) => {
      if (data?.id) {
        emailGroups.push(data.name);
        data.emailUserList.map((emailIdValues) => {
          addUserEmailId.push(emailIdValues.emailId);
        });
      } else {
        emailValues.push(data.label);
      }
    });

    values.map((value) => {
      if (value?.id) {
        groupMail.push(value);
      }
    });

    groupMail.map((fetchname) => {
      let found = emailGroupBackUpArray.find((syelement) => syelement.name === fetchname.name);
      if (found) {
        found.emailUserList.map((namevalues) => {
          namevalues.groupName = found.name;
          groupMailCopy.push(namevalues);
        });
      }
    });

    values.map((individualMail) => {
      if (!individualMail?.id) {
        individualMailCopy.push(individualMail);
      }
    });

    emailObj.recipientGroup = groupMailCopy;
    emailObj.emailRecipient = individualMailCopy;
    EmailTableConcateArray = groupMailCopy.concat(individualMailCopy);
    props.createSuiteMainFunction(emailObj);
    setSelectedConfigEmailData([...addUserEmailId, ...emailValues]);
  };

  const handleEmailOptions = (value, type) => {
    if (type === 'add') {
      let options = [...emailGroupBackUpArray];
      options.push(value);
      setSelectedConfigEmailData((selectedConfigEmailData) => [...selectedConfigEmailData, value.label]);
      props.createSuiteMainFunction(emailObj);
      setEmailGroupBackUpArray([...options]);
    } else if (type === 'remove') {
      if (value || value?.id) {
        let options = [...emailGroupBackUpArray];
        let index = options.findIndex((optionValue) => optionValue.label === value.label);
        options.splice(index, 1);
        let anotherEmailUser = [...userEmailData];
        let tempEmailIndex = anotherEmailUser.findIndex((emaillabel) => emaillabel.name === value.label);
        if (tempEmailIndex > -1) {
          anotherEmailUser.slice(tempEmailIndex, 1);
        }
        setUserEmailData(anotherEmailUser);
        props.createSuiteMainFunction(emailObj);
      }
    }
  };
  const selectEmailReport = (isChecked) => {
    const emailValue = booleanToString(isChecked);

    setEmailObj((prevState) => ({
      ...prevState,
      emailTheReportAfterExecution: emailValue,
      emailManuallyTriggerEmailNotification:
        props.suiteType === 'Manual' ? emailValue : prevState.emailManuallyTriggerEmailNotification,
      attachPdfReport: isChecked ? emailValue : prevState.attachPdfReport,
      attachHtmlReport: isChecked ? emailValue : prevState.attachHtmlReport,
      emailOnSuiteExecutionStart: props.suiteType !== 'Manual' ? emailValue : prevState.emailOnSuiteExecutionStart,
      emailOnSuiteExecutionCompletion:
        props.suiteType !== 'Manual' ? emailValue : prevState.emailOnSuiteExecutionCompletion,
    }));

    setEmailReportSelected(emailValue);
  };

  const emailCheckBoxAction = (e) => {
    const { value, checked } = e.target;
    setEmailObj((prevEmailObj) => {
      let newEmailObj = { ...prevEmailObj };
      if (value === EMAIL_NOTIFICATION_CONSTANTS.ON_SUITE_EXECUTION_COMPLETION_OR_TERMINATION) {
        // Handle the main completion checkbox
        if (checked) {
          // Enable PDF and HTML checkboxes
          document.querySelectorAll('#emailPdf, #emailHtml').forEach((checkbox) => {
            checkbox.disabled = false; // Enable checkboxes when completion is checked
          });
          newEmailObj.emailOnSuiteExecutionCompletion = 'true';
        } else {
          // Disable and uncheck PDF and HTML checkboxes when the main completion is unchecked
          document.querySelectorAll('#emailPdf, #emailHtml').forEach((checkbox) => {
            checkbox.disabled = true;
            checkbox.checked = false;
          });
          newEmailObj.emailOnSuiteExecutionCompletion = 'false';
          newEmailObj.attachPdfReport = 'false';
          newEmailObj.attachHtmlReport = 'false';
        }
      } else if (value === 'PDF' || value === 'HTML') {
        // Handle individual report type selections
        if (newEmailObj.emailOnSuiteExecutionCompletion) {
          if (checked) {
            if (value === 'PDF') {
              newEmailObj.attachPdfReport = 'true';
            } else if (value === 'HTML') {
              newEmailObj.attachHtmlReport = 'true';
            }
          } else {
            if (value === 'PDF') {
              newEmailObj.attachPdfReport = 'false';
            } else if (value === 'HTML') {
              newEmailObj.attachHtmlReport = 'false';
            }
          }
        }
      } else if (value === EMAIL_NOTIFICATION_CONSTANTS.ON_SUITE_EXECUTION_START) {
        newEmailObj.emailOnSuiteExecutionStart = checked ? 'true' : 'false';
      }

      return newEmailObj;
    });

    props.createSuiteMainFunction({
      ...emailObj,
      attachPdfReport: document.querySelector('#emailPdf')?.checked || 'false',
      attachHtmlReport: document.querySelector('#emailHtml')?.checked || 'false',
      emailOnSuiteExecutionStart:
        document.getElementById(EMAIL_NOTIFICATION_CONSTANTS.ON_SUITE_EXECUTION_START)?.checked || 'false',
    });
  };

  useEffect(() => {
    props.createSuiteMainFunction(emailObj);
  }, [emailObj]);

  return (
    <>
      <Modal open={showModal} className="modal-dialog">
        {EmailTableConcateArray?.length ? (
          <div className="modal-container max-popup-style">
            <div className="modal-header ">
              <label className="Popup-header-common Popup-header-layout-style-user">Email Recipients</label>
              <div className="float-right flex flex-row">
                {/* <FaSearch className="search-icon-maximize-style searchBtn" /> */}
                <CloseIcon
                  className="cursor-hand"
                  onClick={() => {
                    setShowModal(false);
                    setOperation();
                    setPageSize(4);
                  }}
                />
              </div>
            </div>

            <div className="modal-body-1" id="journal-scroll">
              <div className="table_height overflow-auto" id="journal-scroll">
                <Table columns={columns} data={EmailTableConcateArray} />
              </div>
            </div>
          </div>
        ) : null}
      </Modal>

      <div className="email-style mt-5">
        <div className="email-checkbox-value-style left-border-style mb-3">
          <label className={props?.rerunMode ? 'opacity-50' : ''}>
            <input
              type="checkbox"
              disabled={props?.rerunMode}
              checked={emailReportSelected === 'true'}
              onChange={(e) => {
                selectEmailReport(e.target.checked);
              }}
            />
            {emailData[0]}
          </label>
        </div>
        {emailReportSelected === 'true' ? (
          <div>
            <div className="emailGroup-class">
              <label className="pl-3"> Email Reports</label>
              <div className="p-3">
                <div className="flex">
                  <div className="w-4/6">
                    <label className="email-notification-header pl-4">Email Notifications</label>
                    <div className="email-checkbox-value-style">
                      {notificationTypes.map((checktxt, optindex) => {
                        // Note: The use of "==" here is intentional due to specific requirements.
                        // Do not change "==" to "===" as it may affect the logic.
                        const isNotificationChecked =
                          checktxt === EMAIL_NOTIFICATION_CONSTANTS.ON_SUITE_EXECUTION_START
                            ? emailOnSuiteExecutionStart == 'true'
                            : checktxt === EMAIL_NOTIFICATION_CONSTANTS.ON_SUITE_EXECUTION_COMPLETION_OR_TERMINATION
                            ? emailOnSuiteExecutionCompletion == 'true'
                            : checktxt === EMAIL_NOTIFICATION_CONSTANTS.MANUALLY_TRIGGER_EMAIL_NOTIFICATION
                            ? true
                            : false;
                        return (
                          <label className="m-2" key={optindex}>
                            <input
                              type="checkbox"
                              name={checktxt}
                              checked={isNotificationChecked}
                              id={checktxt}
                              className="appearance-auto checked:bg-green-600 m-2 checked:border-transparent ..."
                              value={checktxt}
                              onChange={(e) => emailCheckBoxAction(e)}
                            />
                            {checktxt}
                          </label>
                        );
                      })}
                    </div>
                  </div>
                  <div className="w-2/6">
                    <label className="email-notification-header pl-5">Attach Execution Report</label>
                    <div className="email-checkbox-value-style">
                      {attachmentTypes.map((checktxt, optindex) => {
                        const pdfReport = emailObj.attachPdfReport == 'true';
                        const htmlReport = emailObj.attachHtmlReport == 'true';
                        const isChecked = checktxt === 'PDF' ? pdfReport : checktxt === 'HTML' ? htmlReport : false;
                        const isDisabled = !(
                          emailOnSuiteExecutionCompletion == 'true' || emailManuallyTriggerEmailNotification == 'true'
                        );
                        return (
                          <label className="m-2" key={optindex}>
                            <input
                              type="checkbox"
                              name={checktxt}
                              checked={isChecked}
                              id={checktxt === 'PDF' ? 'emailPdf' : 'emailHtml'}
                              className="appearance-auto checked:bg-green-600 m-2 checked:border-transparent ..."
                              value={checktxt}
                              onChange={(e) => emailCheckBoxAction(e)}
                              disabled={isDisabled}
                            />
                            {checktxt}
                          </label>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="pl-3">
                <label> Email Recipients </label>
                <form onSubmit={formik.handleSubmit} className="space-y-8 ">
                  <div class="grid grid-cols-2  mt-2 pb-3 ml-2 ">
                    <div>
                      <OutsideAlerter function={closeEmailGroup}>
                        <div>
                          <label className="input-filed-label-style-common">
                            <span class="text-red-400">*</span> Add Email Group / Email{' '}
                          </label>
                          <br></br>
                          <div>
                            <div>
                              <div className="">
                                <EmailDropdown
                                  setIsEmailDropdownOpen={props.setIsEmailDropdownOpen}
                                  data={emailGroupBackUpArray}
                                  disabled={props?.rerunMode || false}
                                  placeholder={'Enter email or email group name'}
                                  handleSelectChange={handleChangeValues}
                                  valueNumbers={3}
                                  selectedData={userEmailData}
                                  onDropdownOpen={scrollIntoView}
                                  handelOptions={handleEmailOptions}
                                  execution={true}
                                  targetData={selectedConfigEmailData}
                                  createAlertFun={props.createAlertFun}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </OutsideAlerter>
                    </div>
                  </div>

                  {EmailTableConcateArray?.length ? (
                    <div className="module-area-style">
                      <div className="content_area_header_style">
                        <div>
                          <label className="main-header-label float-left ">Email Recipients</label>
                        </div>
                        <div className="flex flex-row float-right">
                          {/* <FaSearch className="mt-1 mr-3 searchBtn" /> */}
                          <FiMaximize2
                            title="Maximize"
                            onClick={() => {
                              setShowModal(true);
                              setOperation('maximize');
                              setPageSize(8);
                            }}
                            className={`${
                              EmailTableConcateArray?.length ? ' maximize-btn-style cursor-hand' : 'disabled-action '
                            } mt-1 mr-2 `}
                          />
                        </div>
                      </div>
                      <div className="table_height overflow-auto" id="journal-scroll">
                        <Table columns={columns} data={EmailTableConcateArray} />
                      </div>
                    </div>
                  ) : null}
                </form>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default EmailPage;

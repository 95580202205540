import React, { useRef, useEffect } from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import Styles from './defects-donut-chart.module.scss';

const DefectsDonutChart = (props) => {
  let { donutOptions, series, setTableContent } = props;

  const chartRef = useRef(null);
  const selectedSectorRef = useRef(null);

  useEffect(() => {
    if (chartRef.current && selectedSectorRef.current === null) {
      chartRef.current.chart.w.globals.selectedDataPoints = [];
      chartRef.current.chart.updateOptions({
        labels: donutOptions.labels,
        plotOptions: {
          pie: {
            customScale: 1,
          },
        },
      });
    }
  }, [donutOptions.labels]);

  const handleViewAllClick = () => {
    selectedSectorRef.current = null;
    if (chartRef.current) {
      setTableContent('ALL');
      props.setCallOnViewAll(true);
      chartRef.current.chart.w.globals.selectedDataPoints = [];
      chartRef.current.chart.updateOptions({
        labels: donutOptions.labels,
        plotOptions: {
          pie: {
            customScale: 1,
            dataLabels: {
              offset: -20,
            },
          },
        },
      });
    }
  };

  const handleClick = (event, chartContext, config) => {
    if (config && config.seriesIndex >= 0 && config.dataPointIndex >= 0) {
      selectedSectorRef.current = config.dataPointIndex;
    }
  };

  const mainSeries = series.slice(0, series.length);
  const mainLabels = donutOptions.labels.slice(0, donutOptions.labels.length);
  const modifiedOptions = {
    ...donutOptions,
    labels: [...mainLabels, 'Total Defects'],
    plotOptions: {
      ...donutOptions.plotOptions,
      pie: {
        ...donutOptions.plotOptions.pie,
        customScale: 0.8,
        dataLabels: {
          ...donutOptions.plotOptions.pie.dataLabels,
          offset: -10,
        },
        title: {
          ...donutOptions.plotOptions.pie.title,
          text: series.reduce((total, value) => total + value, 0).toString(),
        },
      },
    },
  };

  return (
    <div className="py-1 donut-chart-scroll">
      <div className={Styles['legend-container']}>
        <div className={Styles['legend-item']} onClick={handleViewAllClick}>
          <div className={Styles['legend-label']}>View All</div>
          {/*NOTE:: If Required tolal count uncomment below line :: NOTE */}
          {/* <div className={Styles["legend-value"]}>{series.reduce((total, value) => total + value, 0)}</div>    */}
        </div>
      </div>
      <Chart
        ref={chartRef}
        type="donut"
        options={modifiedOptions}
        series={mainSeries}
        width="310px"
        height="280"
        events={{
          dataPointSelection: handleClick,
        }}
      />
    </div>
  );
};

DefectsDonutChart.propTypes = {
  donutOptions: PropTypes.object.isRequired,
  series: PropTypes.array.isRequired,
};

export default DefectsDonutChart;

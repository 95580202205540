import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useTable } from 'react-table';
import StatusDropdown from '@pages/results/shared/status-dropdown';
import { updateManualSuiteExecution } from '@api/api_services';
import { setIDB } from '@src/util/localForage_idb_controller';
import {
  _formatDate,
  getTruncatedText,
  isEmptyValue,
  splitedScriptNameAndID,
  validateFileExtension,
} from '@util/common_utils';
import RichTextEditor from '@src/pages/common/rich-text-editor';
import { TextField, TextareaAutosize, Select } from '@material-ui/core';
import { getLabel, getOptionLabel } from '@pages/template/utils/common-functions';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import Grid from '@mui/material/Grid';
import { Field, useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';
import { FormHelperText } from '@material-ui/core';
import DatePickerView from 'react-datepicker';
import {
  getFolderByProjectId,
  addFilesToCloudFolder,
  deleteImagesFromCloudFolder,
  downloadImagesFromCloudFolder,
  getAllFiles,
} from '@api/api_services';
import { getCurrentProjectId } from '@util/common_utils';
import { TooltipPoppin } from '@src/pages/analytics/common/util';
import Modal from 'react-modal';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import { colors } from '@src/css_config/colorConstants';
import { MANUAL_RESULT_TABLE_CONSTANTS } from '@src/common/ui-constants';
import { DATE_FORMATS } from '@src/util/common_utils';
import { MANUAL_TEST_CASE_ALLOWED_EXTENSIONS_MIME, UI_VALIDATIONS } from '@util/validations';

const customStyles = {
  overlay: {
    backgroundColor: `${colors.grey_light_alpha}`,
    zIndex: '1000',
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'none',
    border: 'none',
    padding: '0.002px',
    height: 'fit-content',
    width: 'fit-content',
  },
};

const moment = require('moment');
const emptyTextEditorValue = [
  '{"blocks":[{"key":"b949t","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
];
const nonEditableField = [
  'Test Case Name',
  'Requirement Id',
  'Status',
  'Severity',
  'Priority',
  'Pre Conditions',
  'Labels',
];

const ManualResult = (props) => {
  let initialValues = {};
  props?.content?.manualTestCase?.testCaseDetails?.forEach((detail) => {
    if (['textField', 'textArea', 'dropdown', 'link', 'radioButton']?.includes(detail?.type)) {
      let [temp] = detail?.value;
      if (!isEmptyValue(temp)) {
        initialValues[detail?.label] = temp;
      } else {
        initialValues[detail?.label] = '';
      }
    } else if (['date']?.includes(detail?.type)) {
      if (!isEmptyValue(detail?.value)) {
        let [dateValue] = detail?.value;
        initialValues[detail?.label] = moment(
          dateValue,
          detail.dateFormat && typeof detail.dateFormat === 'string'
            ? _formatDate(detail?.dateFormat)?.toUpperCase()
            : DATE_FORMATS.DATE_WITH_MONTH_NUMBER
        ).toDate();
      } else {
        initialValues[detail?.label] = '';
      }
    } else if (['textEditor']?.includes(detail?.type)) {
      if (detail?.value?.length > 0) {
        initialValues[detail?.label] = detail.value;
      } else {
        initialValues[detail?.label] = emptyTextEditorValue;
      }
    } else {
      initialValues[detail.label] = detail.value;
    }
  });

  const search = window.location.search;
  const executionId = new URLSearchParams(search).get('executionId');
  const [id, setId] = useState(new URLSearchParams(search).get('id'));
  const [machineStatusObject, setMachineStatusObject] = useState({});
  let [actualResultObject, setActualResultObject] = useState({});
  let [commentObject, setCommentObject] = useState({});
  const [manualTestCaseData, setManualTestCaseData] = useState(
    props?.manualTestCase?.testSteps?.rows ? props.manualTestCase.testSteps.rows : []
  );
  const [columnHeader, setColumnHeader] = useState(
    props?.manualTestCase?.testSteps?.headers ? props.manualTestCase.testSteps.headers : []
  );
  let editableRowIndex = -1;
  const [enableButton, setEnableButton] = useState(false);

  const [testCaseDetailsDataObj, setTestCaseDetailsDataObj] = useState(props?.content?.manualTestCase?.testCaseDetails);
  let [manualObj, setManualObj] = useState({
    ...props?.manualTestCase?.testSteps?.rows,
  });
  let [blobResponse, setBlobResponse] = useState(null);
  let [fileData, setFileData] = useState([]);
  const [fileNameOptions, setFileNameOptions] = useState([]);
  const [totalAttachments, setTotalAttachments] = useState([]);
  const project = JSON.parse(localStorage.getItem('selected-project'));
  const [dataTouched, setDataTouched] = useState(false);
  let [deleteAttachmentFileList, setDeleteAttachmentFileList] = useState([]);
  const [deleteIconClicked, setDeleteIconClicked] = useState(false);

  const formikError = useRef();

  useEffect(() => {
    getFileNames();
  }, []);

  useEffect(() => {
    setTotalAttachments(getAllAttachments());
  }, [props?.manualTestCase?.testSteps?.rows]);

  let getAllAttachments = () => {
    let tempArr = [];
    props?.manualTestCase?.testSteps?.rows?.map((row) => {
      if (row?.Attachment?.length) {
        tempArr = [...tempArr, ...row?.Attachment];
      }
    });
    return tempArr;
  };

  const getFileNames = async () => {
    try {
      let opt = [];
      const response = await getAllFiles(project.id, 'testData');
      if (response.data && response.data.responseObject) {
        response.data.responseObject.forEach((file) => {
          opt.push({
            id: file.id,
            label: file.actualPath.substring(file.actualPath.indexOf('Root') + 5),
          });
        });
        setFileNameOptions(opt);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getValidationSchema = () => {
    let validationObj = {};
    testCaseDetailsDataObj?.forEach((data) => {
      let temp;
      if (['textField']?.includes(data?.type) && !nonEditableField?.includes(data?.label)) {
        if (data?.mandatory === 'yes') {
          temp = yup
            .string()
            .min(data?.minLength, `${data?.label} must have at least ${data?.minLength} characters`)
            .max(data?.maxLength, `${data?.label} must have less than ${data?.maxLength} characters`)
            .required(`${data?.label} field cannot be empty`);
        }
      } else if (['textArea']?.includes(data?.type) && !['Description']?.includes(data?.label)) {
        if (data?.mandatory === 'yes') {
          temp = yup
            .string()
            .min(data?.minLength, `${data?.label} must have at least ${data?.minLength} characters`)
            .max(data?.maxLength, `${data?.label} must have less than ${data?.maxLength} characters`)
            .required(`${data?.label} field cannot be empty`);
        }
      } else if (['checkbox']?.includes(data?.type)) {
        if (data?.mandatory === 'yes') {
          temp = yup.array().min(1).required(`${data?.label} cannot be empty`);
        }
      } else if (['radioButton', 'dropdown']?.includes(data?.type)) {
        if (data?.mandatory === 'yes') {
          temp = yup.string().required(`${data?.label} cannot be empty`);
        }
      } else if (['link']?.includes(data?.type)) {
        if (data?.mandatory === 'yes') {
          temp = yup.string().required(`${data?.label} cannot be empty`).url('Enter valid URL');
        } else {
          temp = yup.string().url('Enter valid URL');
        }
      }
      validationObj[data?.label] = temp;
    });
    return validationObj;
  };
  let formik = useFormik({
    initialValues,
    validationSchema: yup.object(getValidationSchema()),
  });

  const handleAttachmentDropdownChange = (label, value) => {
    formik.setFieldValue(label, [value]);
  };

  const checkValidation = () => {
    if (!Object.keys(formikError?.current).length) {
      setEnableButton(true);
    } else {
      setEnableButton(false);
    }
  };

  useEffect(() => {
    if (dataTouched || deleteIconClicked) {
      setEnableButton(true);
    } else {
      setEnableButton(false);
    }
  }, [totalAttachments, machineStatusObject, commentObject, actualResultObject]);

  useEffect(() => {
    if (Object.keys(formik.errors).length === 0) {
      formikError.current = { ...formik.errors };
      setEnableButton(true);
    } else {
      formikError.current = { ...formik.errors };
      setEnableButton(false);
    }
  }, [formik.errors]);

  const handleChangeForCheckBox = (e, label) => {
    if (e.target.checked) {
      formik.setFieldValue(label, [...formik.values[label], e.target.name]);
    } else {
      formik.setFieldValue(
        label,
        formik?.values[label]?.filter((option) => option !== e.target.name)
      );
    }
  };

  const handleClickDatepickerIcon = (id) => {
    if (id) {
      document.getElementById(id).focus();
    }
  };
  const [folderId, setFolderId] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null);
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    setManualObj((prevManualObj) => {
      let updatedManualObj = { ...prevManualObj };
      Object.keys(machineStatusObject).forEach((i) => {
        let rowObj = { ...updatedManualObj[i] };
        rowObj['Status'] = machineStatusObject[i];
        updatedManualObj[i] = rowObj;
      });
      return updatedManualObj;
    });
  }, [machineStatusObject]);

  const hasDuplicates = (array, property) => {
    const seen = new Set();

    for (const obj of array) {
      const key = property ? obj[property] : JSON.stringify(obj);

      if (seen.has(key)) {
        return true;
      }

      seen.add(key);
    }

    return false;
  };

  const openImagePreview = async (image, rowIndex) => {
    if (image?.id || image?.fileId) {
      const { data } = await downloadImagesFromCloudFolder(image?.id || image?.fileId);
      setBlobResponse(data);
    } else {
      let [data] = manualObj[rowIndex]['Attachment']?.filter((file) => file?.name === image?.name);
      setBlobResponse(data);
    }
    setSelectedImage(image);
    setShowImage(true);
  };

  const closeImagePreview = () => {
    setShowImage(false);
    setSelectedImage(null);
  };

  const projectId = getCurrentProjectId();

  const getFolderForSelectedProject = async () => {
    try {
      const response = await getFolderByProjectId(projectId);
      if (response?.data?.responseObject) {
        let folderId = response?.data?.responseObject?.id;
        setFolderId(folderId);
      }
    } catch (error) {
      console.error('error :>> ', error);
    }
  };

  useEffect(() => {
    getFolderForSelectedProject();
  }, []);

  const createColumns = () => {
    let columnsData = [];
    Object.values(columnHeader).map((header) => {
      let headerObj = {
        Header: formatHeader(header),
        accessor: header,
        Cell: (e) => {
          let value = e?.value;
          if (header === 'status' || header === 'Status') {
            return statusSelect(e.row?.original, e.row?.id);
          } else {
            return Array.isArray(value) ? (
              <>
                {value.map((data) => {
                  return <div>{data}</div>;
                })}
              </>
            ) : typeof value === 'object' ? (
              <>
                {Object.keys(value).map(function (key) {
                  return (
                    <div>
                      {' '}
                      key = <span>{value[key]}</span>
                    </div>
                  );
                })}
              </>
            ) : (
              <div>{value}</div>
            );
          }
        },
      };
      columnsData.push(headerObj);
      return null;
    });
    return columnsData;
  };

  const handleStatusClick = (nodeObj) => {};

  const handleMachineStatus = (statusObject) => {
    setDataTouched(true);
    setMachineStatusObject((prevStatusObject) => ({
      ...prevStatusObject,
      ...statusObject,
    }));
    checkValidation();
  };

  const handleActualResult = (event, cell) => {
    setDataTouched(true);
    let rowObj = { ...manualObj[cell?.row?.id] };
    rowObj['Actual Result'] = event.target.innerText;
    let tempObj = { ...manualObj };
    tempObj[cell?.row?.id] = rowObj;
    setManualObj(tempObj);

    let key = cell?.row?.id;
    if (key) {
      if (Object.keys(actualResultObject).length > 0 && actualResultObject[key]) {
        actualResultObject[key]['Actual Result'] = event.target.innerText;
      } else {
        let tempObj = {
          'Actual Result': event.target.innerText,
        };
        actualResultObject[key] = tempObj;
      }
      setActualResultObject(actualResultObject);
      checkValidation();
    }
  };
  const handleComment = (event, cell) => {
    setDataTouched(true);
    let rowObj = { ...manualObj[cell?.row?.id] };
    rowObj['Comment'] = event.target.innerText;
    let tempObj = { ...manualObj };
    tempObj[cell?.row?.id] = rowObj;
    setManualObj(tempObj);
    let key = cell?.row?.id;
    if (key) {
      if (Object.keys(commentObject).length > 0 && commentObject[key]) {
        commentObject[key]['Comment'] = event.target.innerText;
      } else {
        let tempObj = {
          Comment: event.target.innerText,
        };
        commentObject[key] = tempObj;
      }
      setCommentObject(commentObject);
      checkValidation();
    }
  };

  const formatFileDetails = (fileDataArray, fileArr) => {
    let fileObj = [];
    if (fileDataArray?.length > 0) {
      fileObj = fileDataArray.filter((file) => {
        const tempObj = {};

        for (let i = 0; i < fileArr.length; i++) {
          if (fileArr[i].name === file.name.split('*')[0]) {
            tempObj['name'] = file?.name;
            tempObj['fileId'] = file?.id;
            break;
          }
        }

        if (Object.keys(tempObj).length > 0) {
          return tempObj;
        }
        return null;
      });
    }
    return fileObj;
  };

  const updateFilesToS3 = async () => {
    try {
      const uniqueFiles = {};
      const formData = new FormData();
      fileData.forEach((file) => {
        const fileName = file.name;
        if (!uniqueFiles[fileName]) {
          formData.append('file', file);
          uniqueFiles[fileName] = file;
        }
      });
      const uploadResponse = await addFilesToCloudFolder(folderId, formData, executionId);
      let { moduleId, scriptId, ...fileObj } = { ...manualObj };
      if (uploadResponse?.data?.responseCode === 200) {
        setDataTouched(false);
        const uploadedFilesData = uploadResponse.data.responseObject;
        Object.keys(fileObj).forEach((i) => {
          let tempArray = fileObj[i]['Attachment'];
          fileObj[i]['Attachment'] = [...tempArray, ...formatFileDetails(uploadedFilesData, tempArray)];
          fileObj[i]['Attachment'] = fileObj[i]['Attachment']?.filter((file) => Object.keys(file).length > 0);
        });

        setManualObj({ ...fileObj });
        updateAPI(fileObj);
      } else {
        setFileData([]);
        createAlertFun('warning', uploadResponse?.data?.message + '. Please delete that file');
      }
    } catch (err) {
      console.error('FILE UPLOAD TO S3 FAILED!', err);
    }
  };

  const updateAPI = async (fileObj = null) => {
    // TODO :: Delete multiple file from S3
    if (!isEmptyValue(deleteAttachmentFileList)) {
      deleteAttachmentFileList?.forEach(async (image) => {
        await deleteImagesFromCloudFolder(image?.id || image?.fileId, folderId);
      });
    }

    let execId = executionId;
    let content = props.content;
    let testCaseDetails = props?.content?.manualTestCase?.testCaseDetails?.map((data) => {
      let tempObj = data;
      if (data?.type === 'date') {
        if (
          formik.values[data?.label] === '' ||
          formik.values[data?.label] === undefined ||
          formik.values[data?.label] === null
        ) {
          formik.values[data?.label] = [];
        } else {
          formik.values[data?.label] = moment(formik?.values[data?.label]).format(
            data.dateFormat && typeof data.dateFormat === 'string'
              ? _formatDate(data.dateFormat).toUpperCase()
              : 'DD-MM-YYYY'
          );
        }
      }
      if (data.label === 'Test Case Name') {
        const [testCaseNameTemp] = (tempObj.value = Array.isArray(formik?.values[data?.label])
          ? formik?.values[data?.label]
          : [formik?.values[data?.label]]);
        const [, testCaseName] = splitedScriptNameAndID(testCaseNameTemp);
        tempObj.value = [testCaseName];
      } else {
        tempObj.value = Array.isArray(formik?.values[data?.label])
          ? formik?.values[data?.label]
          : [formik?.values[data?.label]];
      }
      return tempObj;
    });

    const manualObjData = fileObj || { ...manualObj };
    let payload = {
      updateType: 'script',
      manualUpdateObject: {
        ...manualObjData,
        scriptId: id,
        moduleId: content?.parentId,
      },
      testCaseDetails,
      clientId: content?.selectedSystem?.clientId,
      machine: content?.selectedSystem?.machineInfo?.hostName,
    };

    await updateManualSuiteExecution(execId, payload)
      .then((response) => {
        if (response?.data?.responseObject?.fancyTreeData) {
          let fancyTreeData = response.data.responseObject.fancyTreeData;
          setIDB('execResult', fancyTreeData);
        }
        if (response?.data?.message && response.data.message === 'SUCCESS') {
          props?.getScriptResult(id);
          setMachineStatusObject({});
          actualResultObject = {};
          commentObject = {};
          checkValidation();
          createAlertFun('success', `${getTruncatedText(content?.title, 25)} updated successfully.`);
        }
      })
      .catch((error) => {
        console.error('Exception while updating manual testcase:', error);
      });
  };

  const updateManualSuite = async () => {
    if (fileData.some((file) => !file.id) && fileData?.length > 0) {
      await updateFilesToS3();
    } else {
      updateAPI();
    }
  };

  const generateUnsupportedFilesMessage = (unsupportedFileCount, supportedFileCount, uploadedAttachment) => {
    if (uploadedAttachment?.length === 1 && unsupportedFileCount === 1) {
      const fileExtension = uploadedAttachment[0]?.name?.split('.')?.pop()?.toLowerCase();
      return props.MyAlert.info(`${fileExtension} is unsupported.`);
    } else if (unsupportedFileCount === 0) {
      return props.MyAlert.success(MANUAL_RESULT_TABLE_CONSTANTS.FILE_UPLOAD_MESSAGE);
    } else if (unsupportedFileCount === 1) {
      return props.MyAlert.info(`${unsupportedFileCount} unsupported file. Please check the file format.`);
    } else if (supportedFileCount === 0) {
      return props.MyAlert.info(`${unsupportedFileCount} unsupported files.`);
    } else {
      return props.MyAlert.info(`${unsupportedFileCount} unsupported files. Please check the file formats.`);
    }
  };

  const handleFileChange = async (event, rowIndex) => {
    const maxAllowedAttachments = UI_VALIDATIONS.MAX_FILE_UPLOAD_COUNT;
    let files = Array.from(event.target.files);
    let existingRowAttachments = manualObj[rowIndex]?.Attachment || [];
    let newRowAttachment = [...existingRowAttachments];
    let validFiles = 0;
    let invalidFileCount = 0;

    const rowAttachmentNames = new Set(
      existingRowAttachments.map((file) => (file.name.includes('*') && file.id ? file.name.split('*')[0] : file.name))
    );

    for (let file of files) {
      const fileName = file.name.includes('*') && file.id ? file.name.split('*')[0] : file.name;
      if (rowAttachmentNames.has(fileName)) {
        props.MyAlert.info(MANUAL_RESULT_TABLE_CONSTANTS.DUPLICATE_ATTACHMENTS_MESSAGE);
        event.target.value = '';
        return;
      }

      if (validateFileExtension(file, MANUAL_TEST_CASE_ALLOWED_EXTENSIONS_MIME)) {
        validFiles++;
        rowAttachmentNames.add(fileName);
        if (existingRowAttachments?.length > maxAllowedAttachments || files?.length > maxAllowedAttachments) {
          event.target.value = '';
          return props.MyAlert.info(MANUAL_RESULT_TABLE_CONSTANTS.TOTAL_FILES_IN_A_ROW_MESSAGE);
        }
        if (newRowAttachment?.length < maxAllowedAttachments) {
          newRowAttachment.push(file);
          setFileData((prevFiles) => [...prevFiles, file]);
          let rowObj = { ...manualObj[rowIndex] };
          rowObj['Attachment'] = newRowAttachment;
          setManualObj({ ...manualObj, [rowIndex]: rowObj });
          setTotalAttachments((prevAttachments) => [...prevAttachments, file]);
          setDataTouched(true);
        } else {
          event.target.value = '';
          return props.MyAlert.info(MANUAL_RESULT_TABLE_CONSTANTS.TOTAL_FILES_IN_A_ROW_MESSAGE);
        }
      } else {
        invalidFileCount++;
      }
    }

    const FilesUploadMessage = generateUnsupportedFilesMessage(invalidFileCount, validFiles, files);
    if (FilesUploadMessage) {
      return props.MyAlert.info(FilesUploadMessage);
    }
    event.target.value = '';
  };

  const statusSelect = (data, key) => {
    return (
      <StatusDropdown
        machineStatusObject={machineStatusObject}
        nodeKey={key}
        handleMachineStatus={handleMachineStatus}
        handleStatusClick={handleStatusClick}
        status={data?.status ? data.status : data?.Status}
        width={4}
        nodeObj={data}
      ></StatusDropdown>
    );
  };

  const formatHeader = (columnHeader) => {
    if (columnHeader) {
      const header = columnHeader.charAt(0).toUpperCase() + columnHeader.slice(1);
      const result = header.replace(/[A-Z]/g, ' $&').trim();
      return result;
    }
    return columnHeader;
  };

  function createAlertFun(type, msg) {
    if (type === 'success') {
      props.MyAlert.success(msg);
    } else if (type === 'warning') {
      props.MyAlert.warning(msg);
    } else if (type === 'info') {
      props.MyAlert.info(msg);
    }
  }

  const formatDate = (dateVal) => {
    return dateVal.replaceAll('-', '/').replaceAll('mm', 'MM');
  };

  const columns = React.useMemo(() => {
    return createColumns();
  }, []);

  const data = useMemo(() => {
    const filteredData = manualTestCaseData.filter((testCase) =>
      Object.values(testCase).some((v) => v !== null && v !== '')
    );
    return filteredData;
  }, [manualTestCaseData]);

  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    editableRowIndex,
  });

  const handleInputChange = (e, data, formik, setEnableButton) => {
    if (data?.editInExecution === 'yes') setEnableButton(true);
    const inputValue = e.target.value;
    const maxLength = parseInt(data.maxLength);
    if (inputValue.length > maxLength) {
      formik.setFieldError(data.label, `Input cannot be more than ${maxLength} characters`);
    } else {
      formik.handleChange(e);
    }
  };

  const handleInputBlur = (e, data, formik) => {
    const inputValue = e.target.value;
    const minLength = parseInt(data.minLength);
    if (inputValue.startsWith(' ') || inputValue.endsWith(' ')) {
      formik.setFieldError(data.label, 'Space is not allowed at the beginning or end');
    } else if (inputValue.length < minLength && inputValue.length > 0) {
      formik.setFieldError(data.label, `Input cannot be less than ${minLength} characters`);
    }
  };

  const selectedDate = (formikValues, data) => {
    let dateValue = formikValues[data?.label];
    if (isEmptyValue(dateValue)) {
      return '';
    } else if (dateValue instanceof Date) {
      return dateValue;
    } else {
      const dateFormat =
        data.dateFormat && typeof data.dateFormat === 'string'
          ? data.dateFormat.toUpperCase()
          : DATE_FORMATS.DATE_WITH_MONTH_NUMBER;
      const parsedDate = moment(dateValue, dateFormat);
      return parsedDate.isValid() ? parsedDate.toDate() : '';
    }
  };

  const testCaseDetailDescElements = (data, id) => {
    switch (data.type) {
      case 'textField':
        return (
          <div className="rs-user-input-container">
            <TextField
              required={data?.mandatory === 'yes' ? true : false}
              id={data?.label}
              name={data?.label}
              disabled={data?.editInExecution !== 'yes'}
              maxLength={data.maxLength}
              onChange={(e) => handleInputChange(e, data, formik, setEnableButton)}
              onBlur={(e) => handleInputBlur(e, data, formik)}
              InputProps={{
                disableUnderline: data?.editInExecution !== 'yes' && true,
              }}
              className="lg:w-72"
              title={data.placeholder.length > 30 ? data.placeholder : null}
              placeholder={data.placeholder.length > 30 ? data.placeholder.substr(0, 30) + '...' : data.placeholder}
              value={data?.editInExecution !== 'yes' ? data?.value[0] : formik.values[data?.label]}
              error={formik.errors[data?.label]}
              helperText={<span className="errorMessage">{formik.errors[data?.label]}</span>}
            />
          </div>
        );

      case 'textArea':
        return (
          <>
            <div className="">
              <TextareaAutosize
                error={formik.errors[data?.label]}
                helperText={<span className="errorMessage">{formik.errors[data?.label]}</span>}
                required={data?.mandatory === 'yes' ? true : false}
                disabled={data?.editInExecution !== 'yes'}
                onChange={(e) => handleInputChange(e, data, formik, setEnableButton)}
                onBlur={(e) => handleInputBlur(e, data, formik)}
                InputProps={{ disableUnderline: true }}
                title={data.placeholder.length > 30 ? data.placeholder : null}
                id={data?.label}
                name={data?.label}
                maxLength="200"
                maxRows={0}
                className=" bg-transparent block lg:w-72 border-0 border-b-2 rs-input-style-textarea popup-input-bg  -mt-1 pl-px  input-field-color descriptionStyle textarea-resize"
                placeholder={data.placeholder.length > 30 ? data.placeholder.substr(0, 30) + '...' : data.placeholder}
                value={data?.editInExecution !== 'yes' ? data?.value[0] : formik.values[data?.label]}
              />
              {formik.errors[data?.label] && <span className="errorMessage">{formik.errors[data?.label]}</span>}
              {data?.maxLength && (
                <div className="mt-1 text-sm text-gray-500 text-right  lg:mr-32 md:mr-10 sm:mr-16 ">
                  {formik.values[data?.label]?.length}/{data?.maxLength}
                </div>
              )}
            </div>
          </>
        );

      case 'radioButton':
        return (
          <>
            <div className="mt-3">
              <FormControl
                error={formik.errors[data?.label]}
                helperText={<span className="errorMessage">{formik.errors[data?.label]}</span>}
                required={data?.mandatory === 'yes' ? true : false}
              >
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name={data?.label}
                  value={formik?.values[data?.label]}
                  onChange={(e) => handleInputChange(e, data, formik, setEnableButton)}
                >
                  <div className="grid grid-cols-2 gap-1">
                    {data?.options?.map((option, index) => {
                      return (
                        <FormControlLabel
                          key={`${option}-${index}`}
                          value={option}
                          control={<Radio disabled={data?.editInExecution === 'no'} />}
                          label={option}
                        />
                      );
                    })}
                  </div>
                </RadioGroup>
              </FormControl>
            </div>
          </>
        );

      case 'link':
        return (
          <>
            <div className=" flex mt-3">
              <div>
                <InsertLinkOutlinedIcon className="-mt-2 mr-2 origin-center rotate-90 opacity-60" />
              </div>
              <div className="details-data-style-common opacity-50 w-72">
                <TextField
                  error={formik.errors[data?.label]}
                  className="w-56 link-placeholder-text"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="text"
                  autoComplete="off"
                  name={data?.label}
                  id={data?.label}
                  placeholder="Attached Link will be shown here if any URL is added"
                  onChange={(e) => handleInputChange(e, data, formik, setEnableButton)}
                  value={data?.editInExecution !== 'yes' ? data?.value[0] : formik.values[data?.label]}
                  disabled={data?.editInExecution === 'no'}
                  helperText={<span className="errorMessage">{formik.errors[data?.label]}</span>}
                />
              </div>
            </div>
          </>
        );

      case 'dropdown':
        return (
          <>
            <FormControl
              error={formik.errors[data?.label] && formik.touched[data?.label]}
              helperText={<span className="errorMessage">{formik.errors[data?.label]}</span>}
              required={data?.mandatory === 'yes' ? true : false}
              variant="standard"
            >
              <Select
                required={data?.mandatory === 'yes' ? true : false}
                variant="standard"
                disabled={data?.editInExecution !== 'yes'}
                value={data?.editInExecution !== 'yes' ? data?.value[0] : formik?.values[data?.label]}
                onChange={(e) => handleInputChange(e, data, formik, setEnableButton)}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                renderValue={(selected) => {
                  if (!selected) return data?.placeholder;
                  else return selected;
                }}
                name={data?.label}
                id={data?.label}
                className="lg:w-72 lg:h-8 sm:w-40 fontPoppinsRegularMd"
              >
                {data.options.map((option, index) => (
                  <option
                    key={`${option} - ${index}`}
                    className="hover:text-blue-700 cursor-pointer hover:bg-blue-100 details-data-style-common lg:w-72 sm:w-40 fontPoppinsRegularMd"
                    value={option}
                  >
                    {option}
                  </option>
                ))}
              </Select>
            </FormControl>
          </>
        );

      case 'date': {
        return (
          <>
            <div className="flex">
              <div className=" h-8 border-2 border-gray-300 w-60">
                <DatePickerView
                  className="h-7"
                  id={data?.label}
                  disabled={data?.editInExecution === 'no'}
                  selected={selectedDate(formik?.values, data)}
                  onChange={(val) => {
                    if (data?.editInExecution === 'yes') setEnableButton(true);
                    formik.setFieldValue(data?.label, val);
                  }}
                  onKeyDown={(e) => e.preventDefault()}
                  dateFormat={
                    data.dateFormat && typeof data.dateFormat === 'string'
                      ? _formatDate(data?.dateFormat)
                      : DATE_FORMATS.DATE_WITH_MONTH_NAME
                  }
                  maxDate={new Date()}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                />
              </div>
              <div
                className=" bg-gray-200"
                onClick={() => data?.editInExecution === 'yes' && handleClickDatepickerIcon(data?.label)}
              >
                <CalendarTodayOutlinedIcon className="mt-1" />
              </div>
            </div>
            <div className="text-xs opacity-50">
              [{data.dateFormat && Array.isArray(data.dateFormat) ? data.dateFormat.join(',') : data.dateFormat}]
            </div>
            {formik.errors[data?.label] && (
              <p className="MuiFormHelperText-root Mui-error">{formik?.errors[data?.label]}</p>
            )}
          </>
        );
      }
      case 'checkbox':
        return (
          <>
            <div className="lg:w-72">
              <FormGroup
                error={formik.errors[data?.label] && formik.touched[data?.label]}
                helperText={<span className="errorMessage">{formik.errors[data?.label]}</span>}
                required={data?.mandatory === 'yes' ? true : false}
              >
                <div className="grid grid-cols-2 gap-1">
                  {data?.options?.map((option, index) => {
                    return (
                      <FormControlLabel
                        id={data?.label}
                        name={data?.label}
                        key={`${data?.label}-${index}`}
                        control={
                          <Checkbox
                            disabled={data?.editInExecution !== 'yes'}
                            checked={formik?.values[data?.label]?.includes(option)}
                            onChange={(e) => {
                              handleInputChange(e, data, formik, setEnableButton);
                              handleChangeForCheckBox(e, data?.label);
                            }}
                            name={option}
                          />
                        }
                        label={getOptionLabel(option)}
                      />
                    );
                  })}
                </div>
                {formik.errors[data?.label] && (
                  <FormHelperText className="fontPoppinsRegularSm" error>
                    {formik.errors[data?.label]}
                  </FormHelperText>
                )}
              </FormGroup>
            </div>
          </>
        );

      case 'attachment':
        return (
          <div className="rs-user-input-container">
            <FormControl
              error={formik.errors[data?.label] && formik.touched[data?.label]}
              helperText={<span className="errorMessage">{formik.errors[data?.label]}</span>}
              required={data?.mandatory === 'yes' ? true : false}
              variant="standard"
            >
              <Select
                required={data?.mandatory === 'yes' ? true : false}
                variant="standard"
                disabled={data?.editInExecution !== 'yes'}
                value={data?.editInExecution !== 'yes' ? data?.value[0]?.label : formik?.values[data?.label][0]?.label}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                renderValue={(selected) => {
                  if (!selected) return data?.placeholder;
                  else return selected;
                }}
                name={data?.label}
                id={data?.label}
                className="lg:w-72 lg:h-8 sm:w-40 fontPoppinsRegularMd"
              >
                {fileNameOptions.map((option, index) => (
                  <option
                    onClick={() => {
                      if (data?.editInExecution === 'yes') setEnableButton(true);
                      handleAttachmentDropdownChange(data?.label, option);
                    }}
                    key={`${option} - ${index}`}
                    className="hover:text-blue-700 cursor-pointer hover:bg-blue-100 details-data-style-common lg:w-72 sm:w-40 fontPoppinsRegularMd"
                    value={option?.label}
                  >
                    {option?.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>
        );
      case 'textEditor':
        return (
          <>
            <FormikProvider value={formik}>
              <Field name={data?.label}>
                {({ form, field }) => {
                  const { setFieldValue } = form;
                  const { value } = field;
                  return (
                    <div>
                      <RichTextEditor
                        setConvertedContent={(content) => {
                          if (content) {
                            setFieldValue(data?.label, content);
                            if (data?.editInExecution === 'yes') setEnableButton(true);
                          }
                        }}
                        readOnly={data?.editInExecution === 'no'}
                        toolbarHidden={data?.editInExecution === 'no'}
                        convertedContent={value || ''}
                      />
                    </div>
                  );
                }}
              </Field>
            </FormikProvider>
          </>
        );

      default:
        return (
          <>
            <TextField className="w-80" />
          </>
        );
    }
  };

  const testCaseDetailElements = (props) => {
    return (
      <>
        <div className="flex flex-col justify-center">
          <div className="">
            <h6 className="fontPoppinsMediumMd">
              {props.data.mandatory === 'yes' ? <span className="text-red-400 mr-1">&#42;</span> : null}
              {getLabel(props.data.label)}
            </h6>
          </div>
          <div className="contentDataStyleMTC">{testCaseDetailDescElements(props.data)}</div>
        </div>
      </>
    );
  };

  const testCaseDetailSection = () => {
    return (
      <>
        <div className=" mt-3 mx-3 my-5">
          <div className="w-90v responsiveTestDetails">
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} className="mb-7">
              {props?.manualTestCase?.testCaseDetails.map((data) =>
                data?.type === 'textEditor' ? (
                  <Grid item xs={12} sm={12} md={12}>
                    {testCaseDetailElements({ data })}
                  </Grid>
                ) : (
                  <Grid item xs={2} sm={4} md={4}>
                    {testCaseDetailElements({ data })}
                  </Grid>
                )
              )}
            </Grid>
          </div>
        </div>
      </>
    );
  };

  const [selectedImages, setSelectedImages] = useState(Array.from({ length: rows.length }, () => []));
  useEffect(() => {
    let temp = rows?.map((row) => {
      if (Array?.isArray(row.original?.Attachment)) {
        return row.original?.Attachment;
      } else {
        return [];
      }
    });
    setSelectedImages(temp);
  }, [rows]);

  //   Delete Image API

  const removeImageFromCloud = async (image, rowIndex) => {
    setDataTouched(true);
    const updatedImages = [...selectedImages];
    if ((image?.id || image?.fileId) && folderId) {
      try {
        let response = await deleteImagesFromCloudFolder(image?.id || image?.fileId, folderId);
        const remainingImages = updatedImages[rowIndex].filter((img) => img !== image);
        if (response?.data?.responseCode === 200) {
          let rowObj = { ...manualObj[rowIndex] };
          rowObj['Attachment'] = rowObj['Attachment']?.filter(
            (file) => (file?.fileId || file?.id) !== (image?.fileId || image?.id)
          );
          let tempObj = { ...manualObj };
          tempObj[rowIndex] = rowObj;
          setManualObj(tempObj);
          setSelectedImages((prevImages) => {
            const updatedSelectedImages = [...prevImages];
            updatedSelectedImages[rowIndex] = remainingImages;
            return updatedSelectedImages;
          });
          let temp = totalAttachments.filter((file) => (file.fileId || file.id) !== (image.fileId || image.id));
          setTotalAttachments(temp);
        } else if (response.data.responseCode === 404) {
          createAlertFun('warning', response?.data?.message);
        }
      } catch (error) {
        console.error('Error deleting image:', error);
      }
    } else {
      let temp =
        manualObj[rowIndex]['Attachment']?.filter((file) => (file?.name || file?.id) !== (image?.name || image?.id)) ||
        [];
      let tempObj = { ...manualObj };
      tempObj[rowIndex]['Attachment'] = temp;
      setManualObj(tempObj);

      let tempArr = totalAttachments.filter((file) => (file.name || file.id) !== (image.name || image.id));
      setTotalAttachments(tempArr);
    }
  };

  let removeImageFromUI = (image, rowIndex) => {
    image.rowIndex = rowIndex;
    let listofCloudfiledeletion = manualObj[rowIndex]['Attachment']?.filter((file) => file?.id === image?.id) || [];
    setDeleteAttachmentFileList([...deleteAttachmentFileList, ...listofCloudfiledeletion]?.filter((file) => file?.id));

    let temp =
      manualObj[rowIndex]['Attachment']?.filter((file) => (file?.name || file?.id) !== (image?.name || image?.id)) ||
      [];
    let tempObj = { ...manualObj };
    tempObj[rowIndex]['Attachment'] = temp;
    setManualObj(tempObj);

    let tempArr = totalAttachments.filter((file) => (file.name || file.id) !== (image.name || image.id));
    setTotalAttachments(tempArr);
    setFileData(tempArr);
  };

  const downloadImage = async () => {
    try {
      const url = window.URL.createObjectURL(new Blob([blobResponse]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', selectedImage.name || 'image'); // Set the filename for the downloaded image
      document.body.appendChild(link);
      link.click();

      // Clean up after the download
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading image:', error);
      // Handle error scenario here
    }
  };

  return (
    <>
      {Object.keys(props?.manualTestCase).length ? (
        <div className="bg-white card-box-shadow">
          <div className="grid grid-cols-4 p-2 sm:p-2 md:p-3 lg:p-3 xl:p-4 items-center sticky top-0 header-testcase-details">
            <div className="col-span-3 script-name text-value truncate">Test Steps</div>
            <div className="text-right">
              <button
                className={enableButton ? 'primary-btn' : 'disabled-update-btn'}
                onClick={() => updateManualSuite()}
              >
                Update
              </button>
            </div>
          </div>
          <div>{testCaseDetailSection()}</div>
          <div className="" id="journal-scroll">
            <div className="shadow">
              <div className="w-auto overflow-scroll relative" id="journal-scroll">
                {rows && rows.length ? (
                  <table className="testStepTable mt-8">
                    <thead className="test-case-table-row" key={'manualtcthead'}>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="p-2 testStepTable">
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps()}
                              key={column.Header}
                              width={column.width}
                              className="p-2 testStepTable common-label-text fontPoppinsMediumMd"
                            >
                              {column.render('Header')}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()} key={'manualtctbody'}>
                      {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                          <tr
                            key={i}
                            {...row.getRowProps()}
                            className={`h-6 testStepTable ${
                              (i + 1) % 2 === 0 ? 'test-case-table-even-row' : 'test-case-table-odd-row'
                            }`}
                          >
                            {row.cells.map((cell, cellIndex) => {
                              if (cell?.column?.Header === 'Attachment') {
                                return (
                                  <td
                                    {...cell.getCellProps()}
                                    key={i + cellIndex}
                                    className={`testStepTable fontPoppinsRegularSm text-left pl-2 table-cell-text-attachment focus:border-opacity-0 focus:outline-none ${
                                      ['actualResult', 'Actual Result', 'expectedResult', 'Expected Result'].includes(
                                        cell?.column?.id
                                      )
                                        ? 'break-all'
                                        : ''
                                    }`}
                                  >
                                    <label htmlFor={`fileInput-${i}`} className="mb-2 cursor-pointer">
                                      <AttachFileIcon fontSize="small" /> Attachment
                                    </label>
                                    <input
                                      type="file"
                                      id={`fileInput-${i}`}
                                      accept={Object.keys(MANUAL_TEST_CASE_ALLOWED_EXTENSIONS_MIME)}
                                      onChange={(e) => {
                                        if (data?.editInExecution === 'yes') setEnableButton(true);
                                        handleFileChange(e, i);
                                      }}
                                      ref={fileInputRef}
                                      style={{ display: 'none' }}
                                      multiple
                                      max={5}
                                    />
                                    {manualObj[cell?.row?.id]['Attachment']?.length > 0 && (
                                      <>
                                        <div className="my-1">
                                          {manualObj[cell?.row?.id]['Attachment']?.map((image, index) => (
                                            <div key={index} className="flex justify-between items-center">
                                              <TooltipPoppin title={image?.name?.split('*')[0]}>
                                                <div
                                                  onClick={() => openImagePreview(image, cell?.row?.id)}
                                                  className="my-2 cursor-pointer truncate image-name-width"
                                                >
                                                  {image?.name?.split('*')[0]}
                                                </div>
                                              </TooltipPoppin>
                                              {image?.name?.split('*')[0] && (
                                                <TooltipPoppin title="Delete" placement="bottom">
                                                  <div className="delete-icon-style-MTC-table">
                                                    <DeleteIcon
                                                      fontSize="small"
                                                      className="cursor-pointer"
                                                      onClick={() => {
                                                        removeImageFromUI(image, i);
                                                        setDeleteIconClicked(true);
                                                      }}
                                                    />
                                                  </div>
                                                </TooltipPoppin>
                                              )}
                                              <div>
                                                <Modal
                                                  isOpen={showImage}
                                                  onRequestClose={closeImagePreview}
                                                  style={customStyles}
                                                  contentLabel="Image Preview Modal"
                                                >
                                                  <div className="image-overlay">
                                                    <TooltipPoppin title="Download">
                                                      <button
                                                        className="close-icon"
                                                        onClick={() => downloadImage(image?.fileId)}
                                                      >
                                                        <FileDownloadOutlinedIcon fontSize="medium" />
                                                      </button>
                                                    </TooltipPoppin>
                                                    <TooltipPoppin title="Cancel">
                                                      <button onClick={closeImagePreview} className="close-icon">
                                                        <CloseOutlinedIcon fontSize="medium" />
                                                      </button>
                                                    </TooltipPoppin>
                                                  </div>
                                                  <div className="modal-content">
                                                    <img
                                                      src={blobResponse && URL?.createObjectURL(blobResponse)}
                                                      alt={image?.name?.split('*')[0]}
                                                      className="preview-image-style"
                                                    />
                                                  </div>
                                                </Modal>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </>
                                    )}
                                  </td>
                                );
                              } else {
                                return (
                                  <td
                                    {...cell.getCellProps()}
                                    onInput={(e) => {
                                      if (['actualResult', 'Actual Result'].includes(cell?.column?.id)) {
                                        handleActualResult(e, cell);
                                      } else if (['comment', 'Comment'].includes(cell?.column?.id)) {
                                        handleComment(e, cell);
                                      }
                                    }}
                                    key={i + cellIndex}
                                    className={`testStepTable fontPoppinsRegularSm text-left pl-2 table-cell-text focus:border-opacity-0 focus:outline-none ${
                                      ['actualResult', 'Actual Result', 'expectedResult', 'Expected Result'].includes(
                                        cell?.column?.id
                                      )
                                        ? 'break-all'
                                        : ''
                                    }`}
                                    width={columns[cellIndex].width}
                                    contentEditable={
                                      ['actualResult', 'Actual Result', 'Comment', 'comment'].includes(cell?.column?.id)
                                        ? true
                                        : false
                                    }
                                    suppressContentEditableWarning={true}
                                  >
                                    {cell.render('Cell')}
                                  </td>
                                );
                              }
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div className="flex flex-row justify-center h3 no-mtc-info fontPoppinsRegularLg">
                    No manual test cases found
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ height: '78vh' }} className="flex justify-center items-center fontPoppinsRegularLg">
          No manual test cases found
        </div>
      )}
    </>
  );
};

export default ManualResult;

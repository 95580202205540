import React, { Component, Fragment } from 'react';
import AceEditor from 'react-ace-builds';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-beautify';
import 'react-ace-builds/webpack-resolver-min';
import 'brace/ext/searchbox';
import 'brace/keybinding/emacs';
import 'brace/keybinding/vim';
import 'brace/ext/language_tools';
import 'brace/ext/settings_menu';
import 'ace-builds/src-noconflict/ext-beautify';
import '../css/snippet_Editor.scss';

import VariableSelector from '@pagescommon/variable-selector-myinput';
export default class Snippet_Editor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorWidth: '100%',
      readOnly: false,
      change: '',
      showDropDown: false,
      variableDetails: [],
    };
    this.editorRef = React.createRef(null);
  }

  componentDidMount() {
    this.onLoadAlert();
    this.editor = this.editorRef.current.editor;
  }

  variableObj = {};
  markers = [];
  onLoadAlert = (row) => {
    this.setState({
      readOnly: false,
      change: '',
    });
  };

  showAlert = (rowDefaultSnippet) => {
    const editor = this.editorRef.current.editor;
    const session = editor.getSession();
    const value = rowDefaultSnippet.trimStart();
    const cursorPosition = editor.getCursorPosition();
    const currentRow = cursorPosition?.row;
    const text = session.getDocument().getLine(currentRow);
    const column = editor.getSession().getLine(currentRow).length;
    const position = editor.getSession().documentToScreenPosition(currentRow, column);

    let newRow = '';
    if (text.length > 0) {
      newRow = '\n';
    }
    session.insert(position, newRow + value);
    const appendValueItem = editor.getValue();
    this.editorRef.current.editor.focus();
    this.setState({
      change: appendValueItem,
    });
    this.props.getCodeValue(appendValueItem);
  };

  onChangeValue = (value, event) => {
    this.setState({
      change: value,
    });
    if (event.lines[0] === '$' && event.action === 'insert') {
      this.setState({
        showDropDown: false,
      });
      this.state.variableDetails.push(event.start);
    } else {
      this.setState({
        showDropDown: false,
      });
    }
    if (value !== '' && value !== ' ') {
      let objKeys = Object.keys(this.variableObj).join('|');
      let newObjStr = new RegExp(`${objKeys}`, 'g');
      this.apiDataCode = this.addVariable(value, newObjStr, this.variableObj);
    } else {
      this.apiDataCode = '';
    }
    this.props.getCodeValue(this.apiDataCode);
    this.forceUpdate();
  };
  onClickVariableSelector = (name, data) => {
    let tempRow = this.state.variableDetails[this.state.variableDetails.length - 1].row;
    let tempColumn = this.state.variableDetails[this.state.variableDetails.length - 1].column;
    let tempStateArray = this.state.change.split('\n');
    tempStateArray[tempRow] =
      tempStateArray[tempRow].slice(0, tempColumn) +
      tempStateArray[tempRow].slice(tempColumn + 1, tempStateArray[tempRow].length);

    tempStateArray[tempRow] = this.addStr(tempStateArray[tempRow], tempColumn, name);
    let tempStateStr = tempStateArray.join('\n');
    this.variableObj[name] = '${' + data.id + '}';
    let objKeys = Object.keys(this.variableObj).join('|');
    let newObjStr = new RegExp(`${objKeys}`, 'g');

    this.setState({
      showDropDown: false,
      change: tempStateStr,
    });
    this.apiDataCode = this.addVariable(tempStateStr, newObjStr, this.variableObj);
    this.props.getCodeValue(this.apiDataCode, this.state.change);
    this.forceUpdate();
  };
  addStr = (str, index, stringToAdd) => {
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  };

  addVariable = (str, objKeys, varObj) => {
    return str.replace(objKeys, function (matched, index) {
      if (matched !== '') {
        return varObj[matched];
      } else {
        return '';
      }
    });
  };

  render() {
    return (
      <Fragment>
        <AceEditor
          mode="java"
          ref={this.editorRef}
          theme="tomorrow"
          name="UNIQUE_ID_OF_DIV"
          className="ml-0 ace-common-styles"
          height={this.props.expandClick ? 600 : this.props.displayConsole ? 232 : 400}
          width={this.state.editorWidth}
          showGutter={true}
          readOnly={false}
          onChange={this.onChangeValue}
          editorProps={{ $blockScrolling: true }}
          style={{ marginLeft: '0%' }}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true,
          }}
          value={this.state.change}
          {...this.props}
        />
        {this.state.showDropDown ? (
          <VariableSelector ShowDropDown={this.state.showDropDown} onClickGetValue={this.onClickVariableSelector} />
        ) : null}
      </Fragment>
    );
  }
}

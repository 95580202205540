import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import {
  LOCATOR_DETAILS_TABLE_CONSTANTS,
  trimPath,
  VISUAL_TESTING_IMAGE_FILE_EXTENTION,
} from '@src/common/ui-constants';
import ReactTable from '@src/pages/common/Table/ReactTable';
import { Tooltip } from '@material-ui/core';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { downloadVisualTestingElementReq } from '@src/api/api_services';
import { saveFileFromBlob } from '@src/pages/test-development/shared/common-methods';
import { LOCATOR_DETAILS_MODAL_CONSTANT } from '@src/common/ui-constants';
import { getFileExtension, getLocatorsType } from '@src/util/common_utils';

const LocatorDetailsModal = (props) => {
  const [showModal, setShowModal] = useState(true);
  const [selectedLocatorTab, setSelectedLocatorTab] = useState(props.data?.platform === 'Mobile' ? 'Android' : '');
  let iosLocatorsList = [];
  let androidLocatorsList = [];
  const [isCopyToClipBoard, setIsCopyToClipBoard] = useState(false);
  let array = [];
  let path = trimPath(props.data.path);
  let project = JSON.parse(localStorage.getItem('selected-project'));

  let locatorList = [];
  if (props.data?.platform === 'Mobile') {
    if (props?.data?.locators) {
      props?.data?.locators?.forEach((data, id) => {
        let locator;
        let dynamic = 'Static';
        let recorded = 'plugingenerated';
        let imagePath = 'From Local Path';
        if (data.type === 'dynamic') {
          dynamic = 'Dynamic';
        } else if (VISUAL_TESTING_IMAGE_FILE_EXTENTION.includes(data.type)) {
          let extension = getFileExtension(data.name);
          dynamic = getLocatorsType(extension);
        }
        if (data.isRecorded === 'N') {
          recorded = 'Manual';
        }
        if (data.testData) {
          imagePath = 'From Test Data';
        }

        locator = {
          locatorType: data.name,
          dataType: dynamic,
          value: data.value,
          identified: recorded,
          defaultAddedFirst: data.defaultAddedFirst,
          defaultAddedSecond: data.defaultAddedSecond,
          imagePath: imagePath,
          filePath: data.filePath,
          os: data.os,
        };
        locatorList.push(locator);
      });
    }
  } else {
    let locatorType;
    let imagePath = 'From Local Path';
    if (props?.data?.testData) {
      imagePath = 'From Test Data';
    }
    if (props?.data?.locators) {
      props.data.locators.forEach((data) => {
        let extension = getFileExtension(data.name);
        if (VISUAL_TESTING_IMAGE_FILE_EXTENTION.includes(extension)) {
          locatorType = getLocatorsType(extension);
        } else {
          locatorType = getLocatorsType(data.type);
        }
        array.push({
          locatorType: data.name,
          dataType: locatorType,
          value: data.value,
          identified: data.isRecorded === 'N' ? 'Manual' : 'plugingenerated',
          defaultAddedFirst: data.defaultAddedFirst,
          defaultAddedSecond: data.defaultAddedSecond,
          imagePath: imagePath,
          filePath: data.filePath,
        });
      });
    }
  }

  locatorList.forEach((locator) => {
    if (locator.os === 'iOS') {
      iosLocatorsList.push(locator);
    } else {
      androidLocatorsList.push(locator);
    }
  });

  const androidData = React.useMemo(() => (props?.data?.platform === 'Mobile' ? androidLocatorsList : array), []);
  const renderCopyIcon = (value) => {
    const toolTipForCopied = () => {
      setIsCopyToClipBoard(true);
      setTimeout(() => {
        setIsCopyToClipBoard(false);
      }, 1000);
    };
    return (
      <Tooltip title={isCopyToClipBoard ? 'Copied!' : 'Copy'}>
        <ContentCopyIcon
          className="cursor-pointer"
          onClick={async () => {
            if (navigator?.clipboard) {
              toolTipForCopied();
              await navigator.clipboard?.writeText(value);
            } else {
              toolTipForCopied();
              await window?.navigator?.clipboard?.writeText(value);
            }
          }}
        />
      </Tooltip>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Locator Type',
        accessor: 'locatorType',
        width: '15%',
        Cell: (e) => {
          let data = e.row.original;
          let value = e.value;
          if (data.defaultAddedFirst === true) {
            value = 'Flinko Id';
          } else if (data.defaultAddedSecond === true) {
            value = 'Flinko Id';
          }
          return (
            <div className="name-trimmer" title={value}>
              {' '}
              {value}{' '}
            </div>
          );
        },
      },
      {
        Header: 'Value Type',
        accessor: 'dataType',
        width: '15%',
      },
      {
        Header: 'Locator Value',
        accessor: 'value',
        width: '60%',
        Cell: (e) => {
          let value1 = Math.floor(100000 + Math.random() * 900000);
          let value2 = Math.floor(100000 + Math.random() * 900000);
          let data = e.row.original;
          let value = e.value;
          if (data.defaultAddedFirst === true) {
            value = `FLK-ID-${value1}`;
          } else if (data.defaultAddedSecond === true) {
            value = `FLK-ID-${value2}`;
          }
          return (
            <div className="max-w-md truncate" title={value}>
              {' '}
              {value}{' '}
            </div>
          );
        },
      },
      {
        Header: '',
        accessor: 'identified',
        width: '10%',
        Cell: ({ value, row }) => {
          if (value === 'Manual') {
            return (
              <div className="flex gap-6 flex-row mr-3">
                <Tooltip title="Manually added locator" placement="bottom">
                  <svg
                    id="perm_identity_black_24dp"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path id="Path_444" data-name="Path 444" d="M0,0H24V24H0Z" fill="none" />
                    <path
                      id="Path_445"
                      data-name="Path 445"
                      d="M12,6a2,2,0,1,1-2,2,2.006,2.006,0,0,1,2-2m0,9c2.7,0,5.8,1.29,6,2v1H6v-.99C6.2,16.29,9.3,15,12,15M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,9c-2.67,0-8,1.34-8,4v3H20V17C20,14.34,14.67,13,12,13Z"
                      fill="#3c3838"
                    />
                  </svg>
                </Tooltip>
                {renderCopyIcon(row?.original?.value)}
              </div>
            );
          } else {
            return (
              <div className="flex gap-6 flex-row mr-3">
                <Tooltip title="Locator added through plugin" placement="bottom">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26.783" viewBox="0 0 24 26.783">
                    <g id="extension_black_24dp" transform="translate(0 2.783)">
                      <path id="Path_442" data-name="Path 442" d="M0,0H24V24H0Z" fill="none" />
                      <g id="dvr_black_24dp" transform="translate(0 -2.783)">
                        <path id="Path_458" data-name="Path 458" d="M0,0H24V24H0Z" fill="none" />
                        <path
                          id="Path_459"
                          data-name="Path 459"
                          d="M21,3H3A2.006,2.006,0,0,0,1,5V17a2.006,2.006,0,0,0,2,2H8v2h8V19h5a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,21,3Zm0,14H3V5H21ZM19,8H8v2H19Zm0,4H8v2H19ZM7,8H5v2H7Zm0,4H5v2H7Z"
                          fill="#3c3838"
                        />
                      </g>
                    </g>
                  </svg>
                </Tooltip>
                {renderCopyIcon(row?.original?.value)}
              </div>
            );
          }
        },
      },
    ],
    [isCopyToClipBoard]
  );
  let returnname = (nameArray) => {
    let names = [];
    for (let i = 0; i < nameArray.length; i++) {
      names.push(nameArray[i].name);
    }
    return names.toString();
  };

  const downloadVisualLocatorImage = (filepath, filename) => {
    let payload = {
      contentType: '',
      filePath: [],
    };
    payload.filePath.push(filepath);

    downloadVisualTestingElementReq(payload)
      .then((response) => {
        if (response && response.data) {
          saveFileFromBlob(response.data, `${filename}`);
        } else {
          console.error('Error: Empty or invalid response data');
        }
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  };

  const columnsForImage = React.useMemo(
    () => [
      {
        Header: 'Image Name',
        accessor: 'locatorType',
        width: '20%',
        Cell: (e) => {
          let data = e.row.original;
          let value = e.value;
          if (data.defaultAddedFirst === true) {
            value = 'Flinko Id';
          } else if (data.defaultAddedSecond === true) {
            value = 'Flinko Id';
          }
          return (
            <div className="name-trimmer" title={value}>
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Image Type',
        accessor: 'dataType',
        width: '15%',
      },
      {
        Header: 'Image Path',
        accessor: 'imagePath',
        width: '50%',
      },
      {
        Header: '',
        accessor: 'filePath',
        width: '8%',
        Cell: (e) => {
          let filePath = e.value;
          let data = e.row.original;
          let fileName = data.locatorType;
          return (
            <div className="flex flex-row text-right">
              <Tooltip title="Download" placement="left-end">
                <button className="add-btn ">
                  <img
                    onClick={() => {
                      downloadVisualLocatorImage(filePath, fileName);
                    }}
                    className="more-button"
                    src="/assets/images/file_download.svg"
                    alt="DownloadImage"
                    height="25px"
                    width="25px"
                  />
                </button>
              </Tooltip>
            </div>
          );
        },
      },
      {
        Header: '',
        accessor: 'identified',
        width: '7%',
        Cell: ({ value }) => {
          if (value === 'Manual') {
            return (
              <div className="flex flex-row mr-10">
                <Tooltip title="Manually added locator" placement="top">
                  <svg
                    id="perm_identity_black_24dp"
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 24 24"
                  >
                    <path id="Path_444" data-name="Path 444" d="M0,0H24V24H0Z" fill="none" />
                    <path
                      id="Path_445"
                      data-name="Path 445"
                      d="M12,6a2,2,0,1,1-2,2,2.006,2.006,0,0,1,2-2m0,9c2.7,0,5.8,1.29,6,2v1H6v-.99C6.2,16.29,9.3,15,12,15M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,9c-2.67,0-8,1.34-8,4v3H20V17C20,14.34,14.67,13,12,13Z"
                      fill="#3c3838"
                    />
                  </svg>
                </Tooltip>
              </div>
            );
          } else {
            return (
              <div className="flex flex-row mr-10">
                <Tooltip title="Locator added through plugin" placement="top">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26.783" viewBox="0 0 24 26.783">
                    <g id="extension_black_24dp" transform="translate(0 2.783)">
                      <path id="Path_442" data-name="Path 442" d="M0,0H24V24H0Z" fill="none" />
                      <g id="dvr_black_24dp" transform="translate(0 -2.783)">
                        <path id="Path_458" data-name="Path 458" d="M0,0H24V24H0Z" fill="none" />
                        <path
                          id="Path_459"
                          data-name="Path 459"
                          d="M21,3H3A2.006,2.006,0,0,0,1,5V17a2.006,2.006,0,0,0,2,2H8v2h8V19h5a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,21,3Zm0,14H3V5H21ZM19,8H8v2H19Zm0,4H8v2H19ZM7,8H5v2H7Zm0,4H5v2H7Z"
                          fill="#3c3838"
                        />
                      </g>
                    </g>
                  </svg>
                </Tooltip>
              </div>
            );
          }
        },
      },
    ],
    []
  );

  return (
    <Modal
      open={showModal}
      onClose={() => {
        props.closeModal(false);
        setShowModal(false);
      }}
      className="modal-dialog"
    >
      <div
        className={`modal-container ${
          props.mode === 'LOCATORS_DETAILS' ? 'loactor-details-modal-size' : 'element-details-modal-size '
        }`}
      >
        <div className="modal-header">
          {props.mode === 'ELEMENT_DETAILS' ? (
            <div className="title title-trim fontPoppinsMediumLg" title={props.data.name}>
              {LOCATOR_DETAILS_MODAL_CONSTANT.ELEMENT_DETAILS} - {props.data.name}
            </div>
          ) : (
            <div className="title title-trim fontPoppinsMediumLg" title={props.data.name}>
              {LOCATOR_DETAILS_MODAL_CONSTANT.LOCATOR_DETAILS} - {props.data.name}
            </div>
          )}
          <IconButton
            color="primary"
            component="span"
            className="close"
            onClick={() => {
              props.closeModal(false);
              setShowModal(false);
            }}
          >
            <img
              style={{
                cursor: 'pointer',
              }}
              src="/assets/images/close_black.svg"
              alt=""
              height="25px"
              width="25px"
            />{' '}
          </IconButton>
        </div>
        <div className={`modal-body  `} id="journal-scroll">
          <div>
            <div>
              <div className="mt-2">
                <div className="grid grid-cols-3 gap-4 ml-3">
                  <div className="pb-2">
                    <h1 className="details-key-style-common">
                      {LOCATOR_DETAILS_TABLE_CONSTANTS.MODAL_NAME}
                      <p>
                        <div className="pt-2 details-data-style-common name-trimmer" title={props.data.name}>
                          {props.data.name}
                        </div>
                      </p>
                    </h1>
                  </div>
                  <div className=" ml-14">
                    <h1 className="details-key-style-common">
                      {LOCATOR_DETAILS_TABLE_CONSTANTS.MODAL_TYPE}
                      <p>
                        <div className="pt-2 details-data-style-common capitalize">
                          {props?.data?.type ? props.data.type : ' -- '}
                        </div>
                      </p>
                    </h1>
                  </div>

                  <div className=" ml-14">
                    <h1 className="details-key-style-common">
                      {LOCATOR_DETAILS_TABLE_CONSTANTS.DESCRIPTION}
                      <div className="details-data-style-common desc" title={props.data.desc ? props.data.desc : '--'}>
                        {props.data.desc ? props.data.desc : '--'}
                      </div>
                    </h1>
                  </div>
                  {props.mode === 'ELEMENT_DETAILS' ? (
                    <>
                      <div className="mt-6">
                        <h1 className="details-key-style-common">
                          {LOCATOR_DETAILS_TABLE_CONSTANTS.PROJECT_NAME}
                          <p>
                            <div className="pt-2 details-data-style-common project-name-trimmer">{project.name}</div>
                          </p>
                        </h1>
                      </div>
                      {props?.isShared ? (
                        <div className="mt-6 ml-14">
                          <h1 className="details-key-style-common">
                            {LOCATOR_DETAILS_TABLE_CONSTANTS.SHARED_BW_PAGES}
                          </h1>
                          <p className="pt-2 details-data-style-common">
                            <div
                              className="path-trimmer"
                              title={props.data.pageIdsNames ? returnname(props.data.pageIdsNames) : '--'}
                            >
                              {path ? returnname(props.data.pageIdsNames) : '--'}
                            </div>
                          </p>
                        </div>
                      ) : null}

                      <div className="mt-6 ml-14">
                        <h1 className="details-key-style-common">{LOCATOR_DETAILS_TABLE_CONSTANTS.PATH}</h1>
                        <p className="pt-2 details-data-style-common">
                          <div className="path-trimmer" title={path ? path : '--'}>
                            {path ? path : '--'}
                          </div>
                        </p>
                      </div>
                      <div className={`mt-6 ${props?.isShared ? null : 'ml-14'}`}>
                        <h1 className="details-key-style-common">Locators Count</h1>
                        <p className="pt-2 details-data-style-common">
                          <span>{props.data.locatorsCount ? props.data.locatorsCount : 0}</span>
                        </p>
                      </div>
                      <div className={`mt-6 ${props?.isShared ? 'ml-14' : null}`}>
                        <h1 className="details-key-style-common">
                          {LOCATOR_DETAILS_TABLE_CONSTANTS.CREATED_BY}
                          <p>
                            <div className="pt-2 details-data-style-common project-name-trimmer">
                              {props.data.createdByUname}
                            </div>
                          </p>
                        </h1>
                      </div>

                      <div className="mt-6 ml-14">
                        <h1 className="details-key-style-common">{LOCATOR_DETAILS_TABLE_CONSTANTS.CREATED_ON}</h1>
                        <p className="pt-2 details-data-style-common">
                          <div className="path-trimmer" title={props.data.createdOn ? props.data.createdOn : '--'}>
                            {props.data.createdOn ? props.data.createdOn : '--'}
                          </div>
                        </p>
                      </div>
                      <div className={`mt-6 ${props?.isShared ? null : 'ml-14'}`}>
                        <h1 className="details-key-style-common">{LOCATOR_DETAILS_TABLE_CONSTANTS.MODIFIED_BY}</h1>
                        <p className="pt-2 details-data-style-common">
                          <span className="">{props.data.modifiedByUname ? props.data.modifiedByUname : 0}</span>
                        </p>
                      </div>
                      <div className={`mt-6 ${props?.isShared ? 'ml-14' : null}`}>
                        <h1 className="details-key-style-common">
                          {LOCATOR_DETAILS_TABLE_CONSTANTS.MODIFIED_ON}
                          <p>
                            <div className="pt-2 details-data-style-common project-name-trimmer">
                              {props.data.modifiedOn}
                            </div>
                          </p>
                        </h1>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <hr className="divider mt-5" />
            {props.data.locators ? (
              <div className="tableShadow  mt-4">
                <div className="mt-4 locatorsarray-size">
                  <p
                    className={`locatorHeader pt-4 mb-4 inline-block ${
                      selectedLocatorTab === 'Android' ||
                      (props.data?.platform === 'Mobile' && selectedLocatorTab === '')
                        ? 'text-blue-700 underline'
                        : 'text-gray-700'
                    }`}
                    onClick={() => {
                      setSelectedLocatorTab(props.data?.platform === 'Mobile' ? 'Android' : '');
                    }}
                  >
                    {' '}
                    {props.data?.platform === 'Mobile' ? 'Android Locators List' : 'Locators List'}
                  </p>
                  {props.data?.platform === 'Mobile' && (
                    <p
                      className={`locatorHeader pt-4 mb-4 inline-block ml-6 ${
                        selectedLocatorTab === 'iOS' ? 'text-blue-700 underline' : 'text-gray-700'
                      }`}
                      onClick={() => {
                        setSelectedLocatorTab('iOS');
                      }}
                    >
                      {LOCATOR_DETAILS_TABLE_CONSTANTS.IOS_LOCATORS_LIST}
                    </p>
                  )}
                  <div className="table-height overflow-auto" id="journal-scroll">
                    <ReactTable
                      data={selectedLocatorTab === 'iOS' ? iosLocatorsList : androidData}
                      columns={props?.data?.type === 'visual testing' ? columnsForImage : columns}
                      tableHeightClass={'locator-table-height'}
                    />
                    {selectedLocatorTab === 'iOS' && iosLocatorsList.length === 0 && (
                      <div className="no-locators-text">{LOCATOR_DETAILS_TABLE_CONSTANTS.NO_IOS_LOCATORS}</div>
                    )}
                    {selectedLocatorTab === 'Android' && androidData.length === 0 && (
                      <div className="no-locators-text">{LOCATOR_DETAILS_TABLE_CONSTANTS.NO_ANDROID_LOCATORS}</div>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="modal-footer">
          <button
            className="gray-btn mr-3"
            onClick={() => {
              props.closeModal(false);
              setShowModal(false);
            }}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default LocatorDetailsModal;

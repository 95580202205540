import React from 'react';
import { defectAnalyticsLabel } from '@common/ui-constants';
import { ReactComponent as RsFilleddFilterIcon } from '@assets/analytics/rs-filter-filled.svg';
import { ReactComponent as FilterIcon } from '@assets/analytics/filter-icon.svg';
import { ReactComponent as FilterIconDisabled } from '@assets/analytics/disabled-filter.svg';
import { ReactComponent as CloseIcon } from '@assets/analytics/ShrinkIconWhite.svg';
import { TooltipPoppin } from '@pages/analytics/common/util';
import Menu from '@mui/material/Menu';
import cx from 'classnames';
import Styles from '@pagescommon/filters/filter-modal.module.scss';
import PropTypes from 'prop-types';
import LicenseRowCardStyle from '@pages/licenses/styles/license-row-card.module.scss';

const FilterModal = (props) => {
  const { onApply, isFilterApplied, anchorEl, open, disableClearAllBtn, disableApplyBtn, disableFilters } = props;

  return (
    <>
      <span
        className="expandIconContainer ml-2 relative"
        id="common-filter-button"
        aria-controls={open ? 'common-filter-menu-section' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(e) => (disableFilters ? e.preventDefault() : props?.handleFilterOpen(e))}
      >
        <TooltipPoppin
          title={disableFilters ? 'Filter Disabled' : isFilterApplied ? 'Change Filter' : 'Filter'}
          placement="top"
          className={cx(LicenseRowCardStyle['ToolTipRenderer'], 'fontPoppinsRegularXs')}
        >
          {disableFilters ? (
            <FilterIconDisabled className="cursor-pointer filter-icon " />
          ) : isFilterApplied ? (
            <RsFilleddFilterIcon className="cursor-pointer filter-icon " />
          ) : (
            <FilterIcon className="cursor-pointer filter-icon " />
          )}
        </TooltipPoppin>
        {Boolean(anchorEl) && (
          <span className={cx(Styles['modal-arrow-icon'], "inline-block absolute content-['']")}></span>
        )}
      </span>
      <Menu
        id="common-filter-menu-section"
        anchorEl={anchorEl}
        open={open || false}
        onClose={props.onClose}
        MenuListProps={{
          'aria-labelledby': 'common-filter-menu-section',
        }}
        className="mt-2"
      >
        <div className={cx(Styles['common-filter-modal'])}>
          <div className={cx(Styles['common-filter-header'])}>
            <div> Filter</div>
            <div onClick={props.onClose} className={Styles['close-icon']}>
              <CloseIcon />
            </div>
          </div>
          <div className={cx(Styles['common-filter-section'])}>{props.children}</div>
        </div>
        <div className={cx(Styles['common-filter-footer'])}>
          {props?.renderCheckbox}
          <button
            disabled={disableClearAllBtn}
            className="button-secondary"
            onClick={() => onApply(defectAnalyticsLabel.btnLabel.clearAll)}
          >
            {defectAnalyticsLabel.btnLabel.clearAll}
          </button>
          <button
            disabled={disableApplyBtn}
            className="button-primary"
            onClick={() => onApply(defectAnalyticsLabel.btnLabel.apply)}
          >
            {defectAnalyticsLabel.btnLabel.apply}
          </button>
        </div>
      </Menu>
    </>
  );
};

FilterModal.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.bool.isRequired,
  isFilterApplied: PropTypes.bool.isRequired,
  onApply: PropTypes.func.isRequired,
  disableClearAllBtn: PropTypes.bool,
  disableApplyBtn: PropTypes.bool,
  disableFilters: PropTypes.bool,
};

export default FilterModal;

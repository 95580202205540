import React, { Fragment } from 'react';
import Button from '../Button/Button';
import '../Comment/Comment.scss';
import { useContext, useEffect, useState } from 'react';
import CommentBoxContainer from '../CommentBoxContainer/CommentBoxContainer';
import CommentForm from '../CommentForm/CommentForm';
import { ReplyComment } from './ReplyComment/ReplyComment';
import { commentContext } from '@src/pages/defects/defect-create-modal';
import { Modal } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Avatar from 'react-avatar';
import DeleteModal from '@src/pages/common/delete_modal';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';

export const editCommentstyle = {
  margin: '8px 0 0 0',
};

const Comment = (prop) => {
  const {
    data,
    onDelete,
    onUpdateComment,
    onAddComment,
    commentsArray,
    onUpdateReplyComment,
    onDelteReplyComment,
    onDeleteThreadComment,
    onUpdateThreadComment,
    projectUsers,
    detailsView,
    defectId,
    userChain,
    dataPassToParent,
    loginUserName,
    analyticsClasses = false,
  } = prop;

  let userId = data.createdBy;
  let commentText = data.description;
  let userName = data.createdByUname;
  let commentId = data.id;
  let userCreated = data.createdOn;
  let userModified = data.modifiedOn;
  let replyComments = data.comments;
  let replyCommentsLength = data?.comments?.length;

  const currentUser = userName;
  const [showReply, setShowReply] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showEditComment, setShowEditComment] = useState(false);
  const [editedComment, SetEditedComment] = useState('');
  const [reset, setReset] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('Do you want to delete your Comment ?');
  const [open, setOpen] = React.useState(false);

  const showCommentBox = () => {
    setShowReply(!showReply);
  };

  const closeCommentBox = () => {
    setShowReply(false);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDelete = () => {
    onDelete(commentId);
    setIsOpen(false);
  };

  const handleEdit = () => {
    onUpdateComment(commentId, editedComment);
    setReset(true);
    setShowEditComment(false);
  };

  useEffect(() => {
    setShowReply(false);
  }, [data]);

  useEffect(() => {
    setIsDisabled(true);
    if (currentUser === userName) {
      if (editedComment && editedComment !== commentText && editedComment.trim().length !== 0) {
        setIsDisabled(false);
      }
    }
    return () => {
      setIsDisabled(true);
    };
  }, [editedComment, userId, commentText, isDisabled]);

  return (
    <>
      <div className="comment">
        <div className="comment-col1">
          <Avatar size={30} name={userName} title={userName} round={true}></Avatar>
          <div className="dividercomments">
            <div className="threadline"></div>
          </div>
        </div>
        <div className="comment-col2">
          <h6 className={analyticsClasses ? 'fontPoppinsRegularSm' : 'fontPoppinsRegularLg'}>
            {userName.replace(/^./, userName[0].toUpperCase())} {userModified !== '--' ? userModified : userCreated}
          </h6>
          {showEditComment ? (
            <div style={editCommentstyle}>
              <CommentForm
                id={commentId}
                getData={SetEditedComment}
                isEdit={true}
                setData={commentText}
                isReset={reset}
                setReset={setReset}
                setShowEditComment={setShowEditComment}
                projectUsers={projectUsers}
                defectId={defectId}
              ></CommentForm>
              <div className="edit-comment-footer">
                <button type="button" className="primary-btn" onClick={() => setShowEditComment(!showEditComment)}>
                  {' '}
                  Cancel{' '}
                </button>
                <button
                  type="button"
                  className="ml-3 primary-btn"
                  onClick={handleEdit}
                  {...(isDisabled ? { disabled: 'disabled' } : '')}
                >
                  {' '}
                  Update{' '}
                </button>
              </div>
            </div>
          ) : (
            <>
              <p className={analyticsClasses ? 'fontPoppinsRegularXs' : 'fontPoppinsRegularMd'}>{commentText}</p>
              {detailsView ? (
                <div className="comment-footer fontPoppinsRegularSm"></div>
              ) : (
                <div className="comment-footer fontPoppinsRegularSm">
                  <Button type="gost" size="sm" onClick={showCommentBox}>
                    {' '}
                    Reply{' '}
                  </Button>
                  {loginUserName === userName && (
                    <>
                      <Button
                        type="gost"
                        size="sm"
                        className={'margin-left-4'}
                        onClick={() => {
                          setIsOpen(true);
                        }}
                        disabled={showReply}
                      >
                        Delete{' '}
                      </Button>
                      <Button
                        type="gost"
                        size="sm"
                        onClick={() => setShowEditComment(!showEditComment)}
                        className={'margin-left-4'}
                        disabled={showReply}
                      >
                        {' '}
                        Edit{' '}
                      </Button>
                    </>
                  )}
                </div>
              )}
            </>
          )}
          {showReply && (
            <CommentBoxContainer
              id={userId}
              replyCommentsLength={replyCommentsLength}
              commentId={commentId}
              userId={currentUser}
              onCancel={closeCommentBox}
              commentsArray={commentsArray}
              onRespond={onAddComment}
              defectId={defectId}
              dataPassToParent={dataPassToParent}
              projectUsers={projectUsers}
            />
          )}
          {replyComments?.length > 0
            ? replyComments.map((replyComment, index) => {
                return (
                  <ReplyComment
                    key={index}
                    commentsArray={commentsArray}
                    parentCommentId={commentId}
                    onReplyUpdate={onUpdateReplyComment}
                    onReplyDelete={onDelteReplyComment}
                    replyCommentData={replyComment}
                    onRespond={onAddComment}
                    onUpdateThreadComment={onUpdateThreadComment}
                    onDeleteThreadComment={onDeleteThreadComment}
                    detailsReplyView={true}
                    dataPassToParent={dataPassToParent}
                    defectId={defectId}
                    detailsView={detailsView}
                    projectUsers={projectUsers}
                    analyticsClasses={analyticsClasses}
                  ></ReplyComment>
                );
              })
            : ''}
        </div>
      </div>
      {isOpen && <DeleteModal closeModal={handleClose} method={handleDelete} message={`Comments`} />}
    </>
  );
};

export default Comment;

import React, { useState } from 'react';
import Modal from 'react-modal';
import '../../test-development.scss';
import { colors } from '@src/css_config/colorConstants';
import { Tooltip } from 'fireflink-ui';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';
import { MASK_DATA } from '@src/common/ui-constants';
function VariablesDetailsModal(props) {
  const [openModal, setOpenModal] = useState(true);

  const customStylesFolder = {
    content: {
      position: 'relative',
      minHeight: '100%',
      minWidth: '450px',
      background: `${colors.text_white} 0% 0% no-repeat padding-box`,
      borderRadius: '10px 0px 0px 10px',
      opacity: '1',
      float: 'right',
      padding: 0,
      border: 'none',
      overflow: 'hidden',
      width: '450px',
    },
  };

  const handleEscapeClose = () => {
    setOpenModal(false);
    props.closeModal(false);
  };

  return (
    <Modal isOpen={openModal} onRequestClose={handleEscapeClose} style={customStylesFolder}>
      <div className="modal-header-api">
        <div className="pl-2">
          <h2 className="title white fontPoppinsMediumSm">Variable Details - {props.data?.name} </h2>
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="variable-details-close"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="border-b border-t border-blue-100 mt-px mb-px pb-3">
        <div className="ml-6 mr-6 mt-3 grid grid-cols-2 gap-4">
          <div className="">
            <h1 className="variable-details-key-style-common">Name</h1>
            <CustomTooltip title={props.data?.name?.length > 13 ? props.data?.name : ''}>
              <p className="text-gray-900 truncate" style={{ width: '170px' }}>
                <span className="variable-details-data-style-common">{props.data?.name}</span>
              </p>
            </CustomTooltip>
          </div>
          <div className="">
            <h1 className="variable-details-key-style-common">Value</h1>
            <Tooltip title={!props.data?.masked ? props.data?.value : ''} placement="bottom">
              <p className="text-gray-900 text-overflow-style">
                <span className="variable-details-data-style-common">
                  {props.data?.value === '' ? 'NULL' : !props.data?.masked ? props.data?.value : MASK_DATA.VARIABLE_MASK_DATA}
                </span>
              </p>
            </Tooltip>
          </div>
          {props.data?.type === 'LOCAL' ? (
            <div className="">
              <h1 className="variable-details-key-style-common">Returning Step</h1>
              <p className="text-gray-900">
                <span className="variable-details-data-style-common">
                  {props.data?.returningStep ? props.data?.returningStep : 'N/A'}
                </span>
              </p>
            </div>
          ) : null}
          <hr className="border-dashed border-blue-100 col-span-2" />
          <div className="">
            <h1 className="variable-details-key-style-common">Created By </h1>
            <p className="text-gray-900">
              <span className="variable-details-data-style-common">{props.data?.createdByUname || '--'}</span>
            </p>
          </div>
          <div className="">
            <h1 className="variable-details-key-style-common">Created On</h1>
            <p className="text-gray-900">
              <span className="variable-details-data-style-common">{props.data?.createdOn || '--'}</span>
            </p>
          </div>
          <div className="">
            <h1 className="variable-details-key-style-common">Modified By </h1>
            <p className="text-gray-900">
              <span className="variable-details-data-style-common">{props.data?.modifiedByUname || '--'}</span>
            </p>
          </div>
          <div className="">
            <h1 className="variable-details-key-style-common">Modified On</h1>
            <p className="text-gray-900">
              <span className="variable-details-data-style-common">{props.data?.modifiedOn || '--'}</span>
            </p>
          </div>
        </div>
      </div>
      <div className="modal-footer-section modal-footer-container">
        <div className="float-right">
          <button
            onClick={() => {
              props.closeModal(false);
              setOpenModal(false);
            }}
            type="button"
            className="modal-close-btn"
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default VariablesDetailsModal;

import React from 'react';

import { useTable } from 'react-table';
import { isEmptyValue } from '@src/util/common_utils';
import { NO_DATA_FOUND } from '@common/ui-constants';

function MyTable({ data, columns, tableHeightClass, analyticsClass = false }) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data,
  });
  return (
    <div className="table-height overflow-auto" id="journal-scroll">
      <div className={tableHeightClass ? tableHeightClass : ''}>
        <table {...getTableProps()} className="">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className={`${analyticsClass ? 'h-8' : 'h-11'} bg-gray-100 hover:bg-gray-100 sticky top-0 zIndex-1`}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    width={column.width}
                    className={`${analyticsClass ? 'fontPoppinsSemiboldSm' : 'fontPoppinsSemiboldMd pl-7'}  uppercase text-left`}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="configHover h-10 content-start">
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`${analyticsClass ? 'fontPoppinsRegularSm ' : 'fontPoppinsRegularMd pl-7 '}  p-0  text-left `}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {isEmptyValue(data) && (
          <div className={`${analyticsClass ? 'fontPoppinsRegularSm' : 'fontPoppinsRegularMd'} p-2 m-2 text-center `}>
            {NO_DATA_FOUND}
          </div>
        )}
      </div>
    </div>
  );
}

export default MyTable;

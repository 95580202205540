import React, { useState, useEffect } from 'react';
import Project_page from '../user_config/project_page';
import Role_page from '../user_config/role_page';
import User_page from '../user_config/user_page';
import ProjectConfigPage from '../user_config/project_config_page';
import Suites from '../execution/suites/suites';
import SuiteOfSuite from '../execution/suite-of-suite/suite-of-suite';
import ScheduledInstance from '../execution/suite-of-suite/detailsChildPages/scheduled-instance';
import SuiteOfSuiteDashboard from '../execution/suite-of-suite/detailsChildPages/suite-of-suite-dashboard';
import Details from '../execution/suite-of-suite/detailsChildPages/details';
import ExecutionDashboard from '../execution/Dashboard/execution-dashboard';
import SuiteDetails from '../execution/suites/details-child-pages/suite-details';
import SuiteDashboard from '../execution/suites/details-child-pages/suite-dashboard';
import SuiteScheduledInstance from '../execution/suites/details-child-pages/SuiteScheduledInstance';
import { Link } from 'react-router-dom';
import { getTimeZoneList } from '@api/api_services';
let selectedIndex = '';
var logoArray = [
  {
    name: 'Projects',
    logo: '/assets/images/project_rec_logo.svg',
    selectedLogo: '/assets/images/project_rec_logo_blue.svg',
    pathUrl: '/projectmenu/project',
    selectedName: 'project',
  },
  {
    name: 'Users',
    logo: '/assets/images/user_logo.svg',
    selectedLogo: '/assets/images/user_logo_blue.svg',
    pathUrl: '/projectmenu/user',
    selectedName: 'user',
  },
  {
    name: 'Roles',
    logo: '/assets/images/role_logo.svg',
    selectedLogo: '/assets/images/role_logo_blue.svg',
    pathUrl: '/projectmenu/role',
    selectedName: 'role',
  },
];
let urlSuiteId;
let urlSuiteType;

const Tab = ({ MyAlert, ...props }) => {
  const [timezonedata, setTimeZoneData] = useState();
  const [timeZoneObject, setTimeZoneObject] = useState();
  let search = window.location.search;
  urlSuiteId = new URLSearchParams(search).get('suiteId');
  urlSuiteType = new URLSearchParams(search).get('suiteType');
  let activeTab = props.props.location.pathname.split('/')[2];
  let parentUrl = props.props.match.url;
  let tab;
  if (parentUrl === '/testData') {
    tab = props.props.location.pathname.split('/')[3];
  } else if (parentUrl === '/repository') {
    tab = props.props.location.pathname.split('/')[4];
  } else if (parentUrl === '/execution/suite') {
    let detailsExist = props.props.location.pathname.split('/')[4];
    if (detailsExist && detailsExist !== 'preview') {
      tab = props.props.location.pathname.split('/')[4];
    } else {
      let landingPage = props.props.location.pathname.split('/')[3];
      if (
        landingPage &&
        landingPage != 'create-suite' &&
        landingPage !== 'edit-suite' &&
        landingPage !== 'create-suite-of-suites' &&
        landingPage !== 'edit-suite-of-suites'
      ) {
        props.props.history.push(
          `/execution/suite/${landingPage}/suite-dashboard?suiteId=${urlSuiteId}&suiteType=${urlSuiteType} `
        );
      }
      tab = props.props.location.pathname.split('/')[2];
    }
  } else {
    tab = props.props.location.pathname.split('/')[2];
  }

  const getTimeZoneListfunc = () => {
    if (tab === 'suite-scheduled-instance') {
      getTimeZoneList().then((res) => {
        let resObject = res.data.responseObject;
        const timeZoneOptions = Object.keys(resObject).map((timeZoneValue) => ({
          label: resObject[timeZoneValue],
          value: timeZoneValue,
        }));
        setTimeZoneObject(resObject);
        setTimeZoneData(timeZoneOptions);
      });
    }
  };
  useEffect(() => {
    getTimeZoneListfunc();
  }, [tab]);

  const renderAboutContent = (tab) => {
    if (parentUrl === '/projectmenu' && !tab)
      return (selectedIndex = logoArray[0].selectedName), (<Project_page MyAlert={MyAlert} />);
    else if (parentUrl === '/execution' && !tab) return (selectedIndex = 'suite'), (<Suites MyAlert={MyAlert} />);
    switch (tab) {
      case 'project':
        return (selectedIndex = tab), (<Project_page MyAlert={MyAlert} />);
      case 'user':
        return (selectedIndex = tab), (<User_page MyAlert={MyAlert} />);
      case 'role':
        return (selectedIndex = tab), (<Role_page MyAlert={MyAlert} />);
      case 'role-individual':
        return (
          (selectedIndex = tab),
          (props.logoArray[2].selectedName = 'role-individual'),
          (props.logoArray[1].selectedName = 'user-individual'),
          (props.logoArray[2].pathUrl = '/projectmenu/role-individual'),
          (props.logoArray[1].pathUrl = '/projectmenu/user-individual'),
          (<Role_page MyAlert={MyAlert} />)
        );
      case 'user-individual':
        return (
          (selectedIndex = tab),
          (props.logoArray[1].selectedName = 'user-individual'),
          (props.logoArray[2].selectedName = 'role-individual'),
          (props.logoArray[1].pathUrl = '/projectmenu/user-individual'),
          (props.logoArray[2].pathUrl = '/projectmenu/role-individual'),
          (<User_page MyAlert={MyAlert} />)
        );
      case 'project-config':
        return (selectedIndex = tab), (<ProjectConfigPage MyAlert={MyAlert} />);
      case 'history':
        return (selectedIndex = tab), (<h1> history</h1>);
      case 'suite':
        return (selectedIndex = tab), (<Suites MyAlert={MyAlert} />);
      case 'suiteofsuite':
        return (selectedIndex = tab), (<SuiteOfSuite MyAlert={MyAlert} />);
      case 'execution-dashboard':
        return (selectedIndex = tab), (<ExecutionDashboard />);
      case 'details':
        return (selectedIndex = tab), (<Details />);
      case 'dashboard':
        return (selectedIndex = tab), (<SuiteOfSuiteDashboard MyAlert={MyAlert} />);
      case 'scheduled-instance':
        return (selectedIndex = tab), (<ScheduledInstance MyAlert={MyAlert} />);
      case 'suite-details':
        return (selectedIndex = tab), (<SuiteDetails />);
      case 'suite-dashboard':
        return (selectedIndex = tab), (<SuiteDashboard MyAlert={MyAlert} />);
      case 'suite-scheduled-instance':
        return (
          (selectedIndex = tab),
          (
            <SuiteScheduledInstance
              MyAlert={MyAlert}
              timezonedatalist={timezonedata || []}
              timeZoneObject={timeZoneObject}
            />
          )
        );
      default:
        return null;
    }
  };
  return (
    <div className="page-list-body">
      {['project', 'user', 'role'].includes(activeTab) ? (
        <ul className="navBar flex flex-row bottom-space-sm -ml-2 repo-dev-tab-height">
          {props?.logoArray?.map((log, index) => {
            return (
              <span key={log.selectedName}>
                <span
                  className={
                    activeTab === log.selectedName
                      ? 'flex flex-row p-2 pb-3 selected-repo-testdev-tab-style mr-2'
                      : 'flex flex-row p-2 pb-3 repo-testdev-tab-style mr-2'
                  }
                >
                  <Link className={activeTab === log.selectedName ? 'border-b-2 border-blue-700' : ''} to={log.pathUrl}>
                    {log.name}
                  </Link>
                </span>
              </span>
            );
          })}
        </ul>
      ) : (
        <ul className="navBar flex flex-row primary-tab">
          {props.logoArray.map((log, index) => {
            return (
              <li key={`tab-main-${index}`}>
                <a
                  href=""
                  key={`dashboard-tab-${index}`}
                  onClick={(event) => {
                    event.preventDefault();
                    props.props.history.push(log.pathUrl);
                  }}
                  className={`${selectedIndex === log.selectedName ? 'selected_tab_style' : ''} tab-style-common`}
                >
                  {log.name}
                </a>
              </li>
            );
          })}
        </ul>
      )}
      <div className="page-table-body">{renderAboutContent(tab)}</div>
    </div>
  );
};

export default Tab;

import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ReactComponent as InfoIcon } from '@assets/svg-imports/info-icon-info-modal.svg';
import { ReactComponent as WarningIcon } from '@assets/svg-imports/ionic-ios-warning.svg';
import { ReactComponent as Alert } from '@assets/svg-imports/Red_warning.svg';
import { ReactComponent as SuccessIcon } from '@assets/svg-imports/Success.svg';
import cx from 'classnames';
import CommonButton from '@src/common/button/Button';
import styles from './alert_pop_ups.module.scss';
import ButtonComponent from '../button/ButtonComponent';
import { ALERT_MODAL_CONSTANTS } from '@src/common/ui-constants';
import { colors } from '@src/css_config/colorConstants';

const { INFO, ALERT, SUCCESS, WARNING } = ALERT_MODAL_CONSTANTS;

const AlertPopUps = ({
  alertTitle = '',
  content = [],
  open,
  alertType = INFO,
  showCancelBtn = true,
  showSaveBtn = true,
  defectTemplateMismatchSection = false,
  showHowToProceed = true,
  saveBtnText = 'Save',
  cancelBtnText = defectTemplateMismatchSection ? 'Close' : 'Cancel',
  onSaveBtnClick = () => {},
  onCancelBtnClick = () => {},
  dialogClass = '',
  execution = false,
}) => {
  const renderIcon = () => {
    switch (alertType) {
      case INFO:
        return <InfoIcon />;
      case WARNING:
        return <WarningIcon />;
      case SUCCESS:
        return <SuccessIcon />;
      case ALERT:
        return <Alert />;
      default:
        return <InfoIcon />;
    }
  };
  const renderAlertTitle = () => {
    switch (alertType) {
      case INFO:
        return 'Info! ';
      case WARNING:
        return defectTemplateMismatchSection ? 'Alert!  ' : 'Warning! ';
      case SUCCESS:
        return 'Success! ';
      case ALERT:
        return execution === false ? 'Alert! ' : 'Caution ';
      default:
        return 'Info! ';
    }
  };
  const renderTopBorderColor = () => {
    switch (alertType) {
      case INFO:
        return 'infoBorder';
      case WARNING:
        return 'warningBorder';
      case SUCCESS:
        return 'successBorder';
      case ALERT:
        return 'errorBorder';
      default:
        return 'infoBorder';
    }
  };

  const renderTitleColor = () => {
    switch (alertType) {
      case INFO:
        return 'titleInfoColor';
      case WARNING:
        return 'titleWarningColor';
      case SUCCESS:
        return 'titleSuccessColor';
      case ALERT:
        return 'titleErrorColor';
      default:
        return 'titleInfoColor';
    }
  };

  const handleEscapeClose = (event) => {
    event?.stopPropagation();
    if (event?.key === 'Escape') {
      onCancelBtnClick();
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className={`fixed inset-0 overflow-y-auto ${dialogClass ? dialogClass : 'z-10'}`}
        open={open}
        onClose={onCancelBtnClick}
        onKeyDown={handleEscapeClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={cx(
                'inline-block align-bottom bg-white rounded-sm text-left shadow-xl transform transition-all sm:align-middle',
                styles[renderTopBorderColor()],
                styles['popUpSize']
              )}
            >
              <div className="inline-block float-left pl-2 pt-2 h-44 w-1/4">
                <div className="ml-8 mt-6">
                  <span className="pr-2">{renderIcon()}</span>
                </div>
              </div>
              <div
                className={cx('inline-block w-3/4 pr-10', {
                  'h-44 overflow-y-scroll': !defectTemplateMismatchSection,
                  'h-56': defectTemplateMismatchSection,
                })}
              >
                <div className="text-sm pt-2 text-gray-500 mt-6">
                  <div className="text-3xl pb-4 fontPoppinsMediumXXlg">
                    <span className={styles[renderTitleColor()]}>{renderAlertTitle()}</span>
                    {alertTitle && <span className="ff-text">{alertTitle}</span>}
                  </div>
                  {content.map((row, index) => (
                    <div className="text-rs-md-gray fontPoppinsRegularMd" key={index}>
                      {row}
                    </div>
                  ))}
                  {showHowToProceed && <p className="fontPoppinsRegularMd"> How do you want to proceed?</p>}
                </div>
              </div>
              <div>
                <div className={cx('mt-2', styles['popUpFooter'])}>
                  {execution === false ? (
                    <div className="pb-6 flex items-center mr-4 float-right h-20 ">
                      {showCancelBtn && (
                        <ButtonComponent
                          bgColor={colors.grey_light}
                          muiProps="ml-1 float-right"
                          label={cancelBtnText}
                          onBtnClick={onCancelBtnClick}
                          textTransform="capitalize"
                        />
                      )}
                      {showSaveBtn && (
                        <ButtonComponent
                          label={saveBtnText}
                          muiProps="ml-4 float-right"
                          textTransform="capitalize"
                          onBtnClick={onSaveBtnClick}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="pb-6 flex items-center mr-4 float-right h-20 ">
                      <div className="mx-2">
                        {showCancelBtn && (
                          <CommonButton label={cancelBtnText} btnType="secondary" onClick={onCancelBtnClick} />
                        )}
                      </div>
                      <div>
                        {showSaveBtn && <CommonButton type="submit" onClick={onSaveBtnClick} label={saveBtnText} />}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AlertPopUps;

import React from 'react';

function History() {
  return (
    <div className="page-table-body">
      <h1>Repo History</h1>
    </div>
  );
}

export default History;

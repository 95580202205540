import React, { useEffect, useState } from 'react';
import SearchBar from '@src/pages/analytics/common/search-bar';
import PropTypes from 'prop-types';

function TreeSearchBar({ searchTree, resetTree, setSearchedText, closeSearchbox, disabled, ...props }) {
  const [userSearchInput, setUserSearchInput] = useState('');
  const [hasUserSearched, setHasUserSearched] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  // TODO :: this condition is to handle platform change in project element repository
  useEffect(() => {
    if (props?.platForm) {
      setOpenSearch(false);
      setUserSearchInput('');
      setHasUserSearched(false);
    }
  }, [props?.platForm]);

  useEffect(() => {
    if (closeSearchbox) {
      setUserSearchInput('');
      setOpenSearch(false);
    }
  }, [closeSearchbox]);

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  let handleSearchClose = () => {
    setOpenSearch(false);
    setUserSearchInput('');
    if (hasUserSearched) {
      setHasUserSearched(false);
      resetTree('RESET');
    }
  };

  const handleSearch = (e) => {
    const _value = e.target.value;
    setUserSearchInput(_value);
    if (typeof setSearchedText !== 'undefined') {
      setSearchedText(_value);
      if (_value === '' && hasUserSearched) {
        setHasUserSearched(false);
        resetTree('RESET');
      }
    }
  };

  const enterKeyHandler = (e) => {
    const searchQueryText = typeof e === 'string' ? e : e?.target?.value;
    const key = e?.key;
    setSearchedText(searchQueryText);
    if ('Escape' === key) {
      handleSearchClose();
    } else if (userSearchInput?.length) {
      setHasUserSearched(true);
      if (searchQueryText?.trim().length >= 1) {
        searchTree(searchQueryText.trim());
      }
    } else {
      if (hasUserSearched) {
        setUserSearchInput(searchQueryText.trim());
        setHasUserSearched(false);
        resetTree('RESET');
      }
    }
  };

  return (
    <SearchBar
      open={openSearch}
      disable={disabled}
      searchValue={userSearchInput}
      handleSearch={handleSearch}
      enterKeyHandler={enterKeyHandler}
      onSearchClose={handleSearchClose}
      handleSearchOpen={handleSearchOpen}
    />
  );
}

TreeSearchBar.propTypes = {
  treeData: PropTypes.object.isRequired,
  searchTreeApi: PropTypes.func.isRequired,
  resetTreeApi: PropTypes.func.isRequired,
};

export default TreeSearchBar;

import React, { Fragment, useEffect, useState, useContext } from 'react';
import '../../repository.scss';
import TableTree from '../../../common/table_tree/table_tree';
import MoreInfoButton from '../../../common/more_info_button';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DetailsModal from '../../shared/details_modal';

import TreeToolbar from '../../shared/tree_toolbar';
import {
  getLibraryTreeReq,
  delLibraryReq,
  DeleteStepGroupReq,
  getStepGroupReq,
  getLibraryReq,
  getSingleUserRequest,
  cloneReq,
  getFirstLevelLibraryTreeReq,
  getAnalyticsReq,
} from '@api/api_services';
import { useHistory } from 'react-router-dom';
import UserDetailsModal from '../../../test-development/shared/user-details-modal';
import DeleteModal from '../../../common/delete_modal';
import StepGroupModal from './modals/step_group_modal';
import LibraryModal from './modals/library_modal';
import { REPO, trimPath } from '../../../../common/ui-constants';
import Tooltip from '@material-ui/core/Tooltip';
import { encodeSpecialCharater } from '@src/pages/test-development/shared/common-methods';
import CloneInfoModal from '@src/pages/common/cloneInfo_modal';
import LoaderModal from '@src/pages/test-development/script/modules/module/modals/loader-modal';
import { CommonLoader } from '@src/pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import {
  getUrlPath,
  packageElementNavigation,
} from '@src/pages/test-development/script/modules/module/utils/common-functions';
import MoveResource from '@src/pages/test-development/script/modules/module/modals/MoveResource';
import { HighlightText } from '@src/util/common_utils';
import { AddCircleOutlineOutlined } from '@material-ui/icons';

const StepGroupTree = (props) => {
  const { isEditable, hasFullAccess, isProjectOpen } = React.useMemo(
    () => ({
      isProjectOpen: window.permission?.isProjectOpen(),
      isEditable: window.permission?.isEditAccess('repository'),
      hasFullAccess: window.permission?.isFullAccess('repository'),
    }),
    []
  );

  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  let hasCloneOption = isProjectOpen && (isEditable || hasFullAccess);

  let renderTree;
  let [libraries, setLibraries] = useState(5);
  let [stepgroups, setStepgroups] = useState(0);
  const countData = JSON.parse(localStorage.getItem('allowedProjects'))?.features?.repository?.restrictions;
  const [licenseLevelStepGroupCount, setLicenseLevelStepGroupCount] = useState(0);
  let [treeData, setTreeData] = useState([]);
  let [dropdownTreeData, setDropdownTreeData] = useState([]);
  const [libraryTree, setLibraryTree] = useState([]);
  let [rootData, setRootData] = useState({});
  let [treeHeader, setTreeHeader] = useState('Step Groups');
  let [type, setType] = useState('');
  let [popupData, setPopupData] = useState([]);
  let [openEditModal, setOpenEditModal] = useState(false);
  let [details, setDetails] = useState(false);
  let [stepGroupDetails, setStepGroupDetails] = useState(false);
  const [message, setMessage] = useState('');
  const [nodeToDel, setNodeToDel] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [reloadTree, setReloadTree] = useState(false);
  const history = useHistory();
  let [nodeToAdd, setNodeToAdd] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [userDetails, setUserDetails] = React.useState([]);
  const [libraryId, setLibraryId] = useState();
  const [libraryName, setLibraryName] = useState();
  const [showLibraryModal, setShowLibraryModal] = useState(false);
  const [showSubLibraryModal, setShowSubLibraryModal] = useState(false);
  const [openStepGroupModal, setOpenStepGroupModal] = useState(false);
  let [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);
  let [cloningContainerData, setCloningContainerData] = useState();
  let [openCloneInfoModal, setOpenCloneInfoModal] = useState(false);
  const [showLoaderModal, setShowLoaderModal] = useState(false);
  const [searchedText, setSearchedText] = useState('');
  const [searchedTextQuery, setSearchedTextQuery] = useState('');
  const [moveResourceModalData, setMoveResourceModalData] = useState({ open: false, srcNodeData: {} });
  const [childResourcesAvailable, setChildResourcesAvailable] = useState(false);
  let project = JSON.parse(localStorage.getItem('selected-project'));
  let parentName = [];
  let prentsData = [];
  if (history.location.pathname !== '/repository/step_group') {
    prentsData = JSON.parse(localStorage.getItem('parentAndSonJson'));
  }
  const reloadTreeData = (value) => {
    setReloadTree(!reloadTree);
  };

  const closeModal = (val) => {
    if (openEditModal) {
      setOpenEditModal(val);
    } else if (openDeleteModal) {
      setOpenDeleteModal(val);
    } else if (showUserModal) {
      setShowUserModal(val);
    } else if (stepGroupDetails) {
      setStepGroupDetails(val);
    } else if (details) {
      setDetails(val);
    } else if (showLibraryModal) {
      setShowLibraryModal(val);
    } else if (showSubLibraryModal) {
      setShowSubLibraryModal(val);
    } else if (openStepGroupModal) {
      setOpenStepGroupModal(val);
    } else if (openCloneInfoModal) {
      setOpenCloneInfoModal(val);
    }
  };
  let openDetails = (val) => {
    setDetails(val);
  };

  let openStepGroupDetails = (val) => {
    setStepGroupDetails(val);
  };

  const rootNodeClicked = (node) => {
    if (node.folder && treeData.length) {
      if (node.name === 'Root') {
        let pageData = {
          Name: node.title,
          id: node.key,
          type: 'page',
        };
        localStorage.setItem('rootJson', JSON.stringify(pageData));
        history.push(`/repository/step_group/${encodeSpecialCharater(node.title)}/Library?id=${node.key}&type=Library`);
      }
    }
  };
  const onNodeSelected = (nodeObj) => {
    let node = nodeObj.node;
    if (node.folder) {
      getLibraryReq(node.key).then((response) => {
        if (response.data.responseCode === 200) {
          let searchKeyArray = node.data.searchKey.slice(1).split('/');
          searchKeyArray = searchKeyArray.slice(1);
          let nameArray = trimPath(response.data.responseObject.path).split('/');
          const { json } = packageElementNavigation(searchKeyArray, nameArray, null);
          localStorage.setItem('parentAndSonJson', JSON.stringify(json));
          history.push(`/repository/step_group/${getUrlPath(json)}Library?id=${node.key}&type=Library`);
        }
      });
    } else {
      getStepGroupReq(nodeObj.data.parentId, nodeObj.data._key).then((response) => {
        if (response.data.responseCode === 200) {
          let defaultStepGroup;
          if (response.data.responseObject.defaultStepGroup) {
            defaultStepGroup = true;
          } else {
            defaultStepGroup = false;
          }
          let searchKeyArray = node.data.searchKey.slice(1).split('/');
          searchKeyArray = searchKeyArray.slice(1);
          let nameArray = trimPath(response.data.responseObject.path).split('/');
          const { json } = packageElementNavigation(searchKeyArray, nameArray, nodeObj.data.parentId);
          localStorage.setItem('parentAndSonJson', JSON.stringify(json));
          history.push(
            `/repository/step_group/${getUrlPath(json)}Steps?id=${nodeObj.data._key}&parentId=${
              nodeObj.data.parentId
            }&stepGroup=${nodeObj.data.title}&defaultStepGroup=${defaultStepGroup}`
          );
        }
      });
    }
  };
  const onCheckedNodes = (nodes) => {};
  const search = window.location.search;
  const id = new URLSearchParams(search).get('id');

  useEffect(() => {
    setIsLoading(true)
    let timerState = setTimeout(() => getLibraryTree(), 800);
    return () => clearTimeout(timerState);
  }, [reloadTree, id]);

  function getLibraryTree(mode) {
    let res;
    setTreeData([]);
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
      isBulkLoad: true,
    });
    setIsLoading(true);
    if (mode === 'RESET') {
      setSearchedText('');
      setSearchedTextQuery('');
    }
    if (mode !== 'RESET' && searchedTextQuery?.length) {
      searchTreeApi(searchedTextQuery);
    } else {
      if (id) {
        getSingleLibraryTreeData();
      } else {
        getFirstLevelLibraryTreeReq()
          .then((response) => {
            if (response?.data?.responseObject?.licenseLevelStepGroupCount) {
              setLicenseLevelStepGroupCount(response.data.responseObject.licenseLevelStepGroupCount);
            } else {
              setLicenseLevelStepGroupCount(0);
            }
            setDropdownTreeData(JSON.parse(JSON.stringify(response.data.responseObject.libraryTree)));
            getLibraryTreeReq(null, false)
              .then((folderResponse) => {
                if (folderResponse?.data?.responseObject?.libraryTree?.length) {
                  setLibraryTree(folderResponse.data.responseObject.libraryTree);
                } else {
                  setLibraryTree([]);
                }
                setLibraries(response.data.responseObject?.libraryTree[0].subLibraryCount);
                if (response.data.responseObject?.libraryTree[0].subLibraryCount === 0) {
                  setNodeToAdd(0);
                } else {
                  setNodeToAdd(null);
                }
                if (response.data.responseObject.libraryTree[0]) {
                  res = createTreeData(response.data.responseObject.libraryTree, 'root');
                  setTreeData(res.treeData);
                  setRootData(res.rootData);
                  if (res?.rootData?.subLibraryCount || res?.rootData?.stepGroupCount) {
                    setChildResourcesAvailable(true);
                  } else {
                    setChildResourcesAvailable(false);
                  }
                }
                resetProgress();
                setIsLoading(false);
              })
              .catch((error) => {
                resetProgress();
                setIsLoading(false);
                console.error('GET_LIBRARY_TREE_WITHOUT_RESOURCE :', error);
              });
          })
          .catch((error) => {
            resetProgress();
            setIsLoading(false);
            console.error('GET_LIBRARY_TREE :', error);
          });
      }
    }
  }
  const getSingleLibraryTreeData = () => {
    let individualData = [];
    let res;

    getLibraryTreeReq(id)
      .then((childResponse) => {
        Object.assign(individualData, childResponse.data.responseObject.libraryTree);
        setLibraries(10);
        const { name: nodeTitle, parentName } = individualData[0];
        setTreeHeader(nodeTitle);
        if (nodeTitle === 'Root' && parentName === undefined) {
          res = createTreeData(individualData, 'root');
        } else {
          res = createTreeData(individualData, 'child');
        }
        getLibraryTreeReq(id, false)
          .then((folderResponse) => {
            let libraryTreeTemp = folderResponse?.data?.responseObject?.libraryTree;
            if (libraryTreeTemp?.length) {
              if (!libraryTreeTemp[0].children) {
                libraryTreeTemp[0]['children'] = [];
              }
              setLibraryTree(libraryTreeTemp);
            } else {
              setLibraryTree([]);
            }
          })
          .catch((error) => {
            resetProgress();
            setIsLoading(false);
            console.error('GET_SINGLE_LIBRARY_TREE_WITHOUT_RESOURCE :', error);
            history.push(`/repository/step_group`);
          });
        setTreeData(res.treeData);
        setRootData(res.rootData);
        setDropdownTreeData(childResponse.data.responseObject.libraryTree);
        if (res?.rootData?.subLibraryCount || res?.rootData?.stepGroupCount) {
          setChildResourcesAvailable(true);
        } else {
          setChildResourcesAvailable(false);
        }
        resetProgress();
        setIsLoading(false);
      })
      .catch((error) => {
        resetProgress();
        setIsLoading(false);
        console.error('GET_SINGLE_LIBRARY_TREE :', error);
      });
  };

  const loadMoreData = async (_libraryId) => {
    let _libraryTree = [];
    try {
      let res;
      if (_libraryId) {
        res = await getLibraryTreeReq(_libraryId);
      } else {
        res = await getLibraryTreeReq();
      }
      if (res?.data?.responseCode === 200 && res?.data?.responseObject) {
        _libraryTree = res.data.responseObject.libraryTree[0].children;
      }
      return _libraryTree;
    } catch (error) {
      console.error('error during load more library data:', error);
      return _libraryTree;
    }
  };

  const searchTreeApi = async (_searchText) => {
    const requestBody = {
      module: 'stepGroups',
      collectionName: 'libraries',
      responseFilterRequired: 'true',
      searchText: _searchText,
      additionalSearchText: id ? id : '',
      facetQueries: [],
    };
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
      isBulkLoad: true,
    });
    setIsLoading(true);
    try {
      setSearchedTextQuery(_searchText);
      const response = await getAnalyticsReq(requestBody);
      if (response?.data?.responseObject) {
        const responseObject = response.data.responseObject;
        if (responseObject?.licenseLevelStepGroupCount) {
          setLicenseLevelStepGroupCount(responseObject.licenseLevelStepGroupCount);
        } else {
          setLicenseLevelStepGroupCount(0);
        }
        if (responseObject?.libraryTree.length) {
          setDropdownTreeData(responseObject.libraryTree[0]);
          setLibraries(responseObject?.libraryTree[0]?.subLibraryCount);
          setStepgroups(responseObject?.libraryTree[0]?.stepGroupCount);
        }
        if (responseObject?.libraryTree[0].subLibraryCount === 0) {
          setNodeToAdd(0);
        } else {
          setNodeToAdd(null);
        }
        if (responseObject?.libraryTree) {
          let res;
          let nodeTitle = responseObject.libraryTree[0].name;
          if (nodeTitle === 'Root') {
            res = createTreeData(responseObject.libraryTree, 'root');
          } else {
            res = createTreeData(responseObject.libraryTree, 'child');
          }
          setTreeData(res.treeData);
          setRootData(res.rootData);
          if (res?.rootData?.subLibraryCount || res?.rootData?.stepGroupCount) {
            setChildResourcesAvailable(true);
          } else {
            setChildResourcesAvailable(false);
          }
        }
        resetProgress();
        setIsLoading(false);
      } else {
        if (response?.data?.errorCode === 404) {
          setRootData([]);
          setTreeData([]);
        }
        setIsLoading(false);
        resetProgress();
        console.warn('GET_FIRST_LEVEL_ALL_LIBRARY_TREE : ', response?.data?.message);
      }
    } catch (err) {
      resetProgress();
      setIsLoading(false);
      console.error('GET_ALL_LIBRARY_TREE : ', err);
    }
  };

  const createTreeData = (response, type) => {
    let res_obj = {
      treeData: [],
      rootData: {},
    };

    if (!response[0].children) {
      response[0]['children'] = [];
    }

    const { children, ...root_data } = response[0];
    if (type === 'root') {
      res_obj.treeData = response[0].children;
    } else {
      res_obj.treeData = response;
    }
    let title = root_data.name;
    if (!title.toLowerCase().includes('library') && type === 'root') {
      root_data['title'] = title.concat(' Library');
    }
    res_obj.rootData = root_data;
    return res_obj;
  };

  const onEditClicked = (ev, nodeObj) => {
    if (nodeObj.data.folder) {
      setType('library');
      getApiData('library', nodeObj.node, 'edit');
    } else {
      setType('stepgroup');
      getApiData('stepgroup', nodeObj.node, 'edit');
    }
  };

  const onDeleteClicked = (ev, nodeObj) => {
    setNodeToDel(nodeObj);
    if (nodeObj.node.children) {
      let stepGroupCount = nodeObj.data.stepGroupCount;
      let subLibraryCount = nodeObj.data.subLibraryCount;
      if (stepGroupCount === 0) {
        props.MyAlert.info(
          `You are not allowed to delete the ${nodeObj.data.title} library as the library contains some sub libraries  `
        );
      } else if (subLibraryCount === 0) {
        props.MyAlert.info(
          `You are not allowed to delete the ${nodeObj.data.title} library as the library contains some step groups`
        );
      } else {
        props.MyAlert.info(
          `You are not allowed to delete ${nodeObj.data.title} library since it contains step groups & sub libraries`
        );
      }
    } else if (nodeObj.data.folder === false) {
      setMessage(`${nodeObj.data.title} ${nodeObj.data.type}`);
      setOpenDeleteModal(true);
    } else {
      setMessage(`${nodeObj.data.title} ${nodeObj.data.title.toLowerCase().includes('library') ? '' : 'Library'}`);
      setOpenDeleteModal(true);
    }
  };

  const updateDropDown = async () => {
    try {
      const folderResponse = await getLibraryTreeReq(id, false);
      const templibraryTree = folderResponse?.data?.responseObject?.libraryTree;
      if (
        templibraryTree?.length === 0 ||
        (templibraryTree[0].subLibraryCount === 0 && templibraryTree[0].stepGroupCount === 0)
      ) {
        if (history.location.pathname === `/repository/step_group`) {
          reloadTreeData(true);
        } else {
          history.push(`/repository/step_group`);
        }
      } else {
        const allTreeResponse = await getLibraryTreeReq();
        setDropdownTreeData(allTreeResponse.data.responseObject.libraryTree);
        setLibraryTree([...templibraryTree]);
      }
    } catch (err) {
      console.error('GET_ONLY_LIBRARIES : ', err);
    }
  };
  async function deleteNode() {
    let response;
    const key = nodeToDel.data._key;
    if (nodeToDel.node.folder) {
      try {
        response = await delLibraryReq(key);
        if (response?.data.responseObject) {
          setOpenDeleteModal(false);
          setReloadTree(true);
          nodeToDel.node.remove();
          props.MyAlert.success(
            `${
              nodeToDel.data.title.length > 22 ? nodeToDel.data.title.substring(0, 22) + '...' : nodeToDel.data.title
            }  ${nodeToDel.data.title.toUpperCase().includes('library') ? '' : 'Library'} deleted successfully`
          );
          history.push(`/repository/step_group`);
        } else {
          console.warn('DELETE_LIBRARY : ', response.data.message);
        }
      } catch (error) {
        console.error('DELETE_LIBRARY : ', error);
      }
    } else {
      try {
        response = await DeleteStepGroupReq(nodeToDel.data.parentId, key);
        if (response?.data.responseObject) {
          setOpenDeleteModal(false);
          nodeToDel.node.remove();
          setReloadTree(true);
          props.MyAlert.success(
            `${
              nodeToDel.data.title.length > 22 ? nodeToDel.data.title.substring(0, 22) + '...' : nodeToDel.data.title
            } ${nodeToDel.data.type} deleted successfully`
          );
        } else {
          console.warn('DELETE_STEPGROUP :', response.data.message);
        }
      } catch (error) {
        console.error('DELETE_STEPGROUP :', error);
      }
    }
    updateDropDown();
  }

  const onMoreButtonClicked = (name, nodeObj) => {
    if (name === 'Details') {
      if (nodeObj.data.folder) {
        setType('library');
        if (nodeObj.isRootNode) {
          getApiData('library', nodeObj.data, 'details');
        } else {
          getApiData('library', nodeObj.node, 'details');
        }
      } else {
        setType('stepgroup');
        getApiData('stepgroup', nodeObj.node, 'details');
      }
    } else if (name === 'library') {
      setLibraryId(nodeObj.data._key);
      setLibraryName(nodeObj.data.title);
      setNodeToAdd(nodeObj.node);
      setShowSubLibraryModal(true);
    } else if (name === 'stepgroup') {
      if (licenseLevelStepGroupCount < countData.numberOfStepGroups) {
        setLibraryId(nodeObj.data._key);
        setNodeToAdd(nodeObj.node);
        setLibraryName(nodeObj.data.title);
        setOpenStepGroupModal(true);
      } else {
        props.MyAlert.info('Exceeded allocated limit, please upgrade or buy new license');
      }
    } else if (name === 'Clone') {
      if (nodeObj.data.folder) {
        setCloningContainerData(nodeObj.data);
        setOpenCloneInfoModal(true);
      } else {
        cloneStepGrp(nodeObj.data);
      }
    } else if (name === 'Move') {
      if (nodeObj.data.folder) {
        setType('library');
        if (nodeObj.isRootNode) {
          getApiData('library', nodeObj.data, 'Move');
        } else {
          getApiData('library', nodeObj.node, 'Move');
        }
      } else {
        setType('stepgroup');
        getApiData('stepgroup', nodeObj.node, 'Move');
      }
    } else {
    }
  };

  const cloneStepGrp = async (stpGrpData) => {
    startOrResumeProgress({
      stopAt: 90,
      intervalDuration: 20,
    });

    let response;
    try {
      response = await cloneReq(stpGrpData._key, stpGrpData.parentId, 'stepgroups', project.id, null);
      if (response?.data?.status === 'SUCCESS' || response?.data?.message === 'SUCCESS') {
        resetProgress();
        setReloadTree(!reloadTree);
        let str = response?.data?.responseObject.name;
        let lastIndex = str.lastIndexOf('-');
        str = str.substring(0, lastIndex);
        props.MyAlert.success(`${str.length > 25 ? str.substring(0, 25) + '...' : str} Stepgroup cloned successfully`);
      } else if (response?.data?.status?.toUpperCase() === 'FAILURE') {
        props.MyAlert.info('Exceeded allocated limit, please upgrade or buy new license');
      }
    } catch (err) {
      console.error('clone stepgroup api error', err);
      stopProgress();
    }
  };
  const cloneContainer = async (libraryData) => {
    startOrResumeProgress({
      stopAt: 90,
      intervalDuration: 20,
    });
    let response;
    try {
      response = await cloneReq(libraryData._key, libraryData.parentId, 'libraries', project.id, null);
      if (response?.data?.message === 'SUCCESS' || response?.data?.status === 'SUCCESS') {
        resetProgress();
        setReloadTree(!reloadTree);
        let str = response?.data?.responseObject.name;
        let lastIndex = str.lastIndexOf('-');
        str = str.substring(0, lastIndex);
        props.MyAlert.success(`${str.length > 25 ? str.substring(0, 25) + '...' : str}  Library cloned successfully`);
      } else if (response?.data?.responseCode === 400) {
        props.MyAlert.info(response?.data?.message);
      }
    } catch (err) {
      resetProgress();
      console.error('clone library api error', err);
    }
  };

  async function getApiData(type, nodeObj, operation) {
    let response;
    try {
      if (type === 'stepgroup') {
        response = await getStepGroupReq(nodeObj.data.parentId, nodeObj.key);
      } else {
        response = await getLibraryReq(nodeObj.key);
      }

      if (response.data.responseObject) {
        setPopupData(response.data.responseObject);
        setType(type);
        if (operation === 'Move') {
          setMoveResourceModalData({
            ...moveResourceModalData,
            open: true,
            srcNodeData: nodeObj,
          });
        }
        if (operation !== 'Move') {
          if (operation === 'edit') {
            setOpenEditModal(true);
          } else {
            if (type === 'stepgroup') {
              setDetails(false);
              setStepGroupDetails(true);
            } else {
              setDetails(true);
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  const closeUserDetailsModal = (value) => {
    setOpenUserDetailsModal(value);
    setUserDetails({});
  };
  function getUserDetails(userNode) {
    getSingleUserRequest(userNode.modifiedBy).then((results) => {
      if (results.data.responseCode === 200 && results.data.responseObject) {
        setUserDetails(results.data.responseObject);
        setOpenUserDetailsModal(true);
      }
    });
  }

  const createLibraryModal = () => {
    setShowLibraryModal(true);
  };

  const stateColumnHtml = (node) => {
    return (
      <div className="state-col-wrp">
        <button className="state-button py-0 px-1 rounded-l">New</button>
        <button className="state-active py-0 px-1 rounded-l">Commit</button>
        <button className="state-button py-0 px-1 rounded-l">Publish</button>
      </div>
    );
  };

  const actionColumnHtml = (nodeObj) => {
    let type;
    if (nodeObj.isRootNode) {
      type = 'root';
    } else if (nodeObj.node.folder) {
      type = 'folder';
    } else {
      type = 'nonFolder';
    }

    const methodListOfFolder = () => {
      if (nodeObj.data?.defaultLibrary) {
        return REPO.FOLDER_ACTION_MENU;
      } else if (hasCloneOption) {
        return REPO.LIBRARY_ACTION_MENU;
      } else {
        return REPO.ROOT_ACTION_MENU;
      }
    };

    return (
      <div className="action-col-wrp">
        {global.permission.isEditAccess('repository') && !nodeObj.isRootNode && (
          <Tooltip title={nodeObj.data?.defaultStepGroup || nodeObj.data?.defaultLibrary ? '' : 'Edit'} placement="top">
            <button
              disabled={nodeObj.data?.defaultLibrary || nodeObj.data?.defaultStepGroup}
              className="state-button py-0 px-1 rounded-l"
              onClick={(event) => {
                onEditClicked(event, nodeObj);
              }}
            >
              <EditOutlinedIcon />
            </button>
          </Tooltip>
        )}

        {global.permission.isFullAccess('repository') && !nodeObj.isRootNode && (
          <Tooltip
            title={nodeObj.isRootNode || nodeObj.data?.defaultStepGroup || nodeObj.data?.defaultLibrary ? '' : 'Delete'}
            placement="top"
          >
            <button
              disabled={nodeObj.isRootNode || nodeObj.data?.defaultLibrary || nodeObj.data?.defaultStepGroup}
              className="state-button py-0 px-1 rounded-l"
              onClick={(event) => {
                onDeleteClicked(event, nodeObj);
              }}
            >
              <DeleteOutlinedIcon />
            </button>
          </Tooltip>
        )}

        {!nodeObj.isRootNode && (
          <MoreInfoButton
            methodListOfFolder={methodListOfFolder()}
            methodListOfNonFolder={
              nodeObj.data.defaultStepGroup
                ? REPO.ROOT_ACTION_MENU
                : hasCloneOption
                ? REPO.STEPGROUP_ACTION_MENU
                : REPO.ROOT_ACTION_MENU
            }
            methodListForRoot={REPO.ROOT_ACTION_MENU}
            nodeObj={nodeObj}
            methodName={onMoreButtonClicked}
            type={type}
            typeToDisplay="more"
          />
        )}
      </div>
    );
  };

  const addLibRootLevel = (name, nodeObj) => {
    onMoreButtonClicked(name, {
      ...nodeObj,
      node: { data: nodeObj.data, title: nodeObj.data.title, _key: nodeObj.data.key, key: nodeObj.data.key },
    });
  };

  const colDefs = [
    {
      field: 'title',
      title: 'LIBRARY',
      class: 'title',
      isTitle: true,
      width: '220px',
      render: (nodeObj) => {
        return (
          <>
            {nodeObj.isRootNode ? (
              <Tooltip title={`Libraries- ${nodeObj.data.subLibraryCount}`} placement="bottom">
                <span className="count-badge folder-count ml-2 cursor-pointer">L {nodeObj.data.subLibraryCount}</span>
              </Tooltip>
            ) : null}
            {nodeObj.node ? (
              nodeObj.node && nodeObj.node.data && nodeObj.node.folder && !nodeObj.data.isMockNode ? (
                <>
                  <Tooltip title={`Sub Libraries - ${nodeObj?.node?.data?.subLibraryCount}`} placement="bottom">
                    <span className="count-badge file-count cursor-pointer">
                      {' '}
                      SL {nodeObj?.node?.data?.subLibraryCount || 0}{' '}
                    </span>
                  </Tooltip>
                  <Tooltip title={`Step Groups - ${nodeObj.data.stepGroupCount}`} placement="bottom">
                    <span className="count-badge file-count cursor-pointer">
                      {' '}
                      SG {nodeObj.node.data.stepGroupCount || 0}{' '}
                    </span>
                  </Tooltip>
                </>
              ) : nodeObj.isRootNode ? (
                <>
                  <Tooltip title={`Step Groups - ${nodeObj.data.stepGroupCount}`} placement="bottom">
                    <span className="count-badge file-count cursor-pointer"> SG {nodeObj.data.stepGroupCount}</span>
                  </Tooltip>
                  {isEditable && nodeObj.data.name === 'Root' && (
                    <button className="add-btn">
                      <span
                        onClick={() => {
                          addLibRootLevel('library', nodeObj);
                        }}
                      >
                        <Tooltip title="Add Library" placement="right">
                          <AddCircleOutlineOutlined className="text-gray-400 hover:text-gray-600" />
                        </Tooltip>
                      </span>
                    </button>
                  )}
                </>
              ) : null
            ) : null}
            {global.permission.isEditAccess('repository') && nodeObj.node.folder && !nodeObj.data.isMockNode && (
              <Tooltip title="Add" placement="right">
                <button className="add-btn" hidden={nodeObj.data.defaultLibrary}>
                  <MoreInfoButton
                    type="add"
                    nodeObj={nodeObj}
                    plusIconArray={REPO.LIBRARY_PLUS_ICON_MENU}
                    methodName={onMoreButtonClicked}
                  />
                </button>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      field: 'stepGroupType',
      title: 'TYPE',
      class: 'state text-overflow-style',
      width: '100px',
      render: (nodeObj) => {
        return (
          <Tooltip placement="top-start" title={nodeObj.data.type}>
            <span className=" text-xs table-non-link-color-common capitalize">{nodeObj.data.type?.toLowerCase()}</span>
          </Tooltip>
        );
      },
    },
    // {
    //   field: "state",
    //   title: "STATE",
    //   class: "state ",
    //   width: "170px",
    //   render: (nodeObj) => {
    //     return (
    //       !nodeObj.data.isMockNode && stateColumnHtml(nodeObj)
    //     )
    //   },
    // },
    // {
    //   field: "ver",
    //   title: "VERSION",
    //   class: "version ",
    //   width: "50px",
    //   render: (nodeObj) => {
    //     return (
    //       <div>
    //         {!nodeObj.isRootNode && (
    //           <span className="version-text-color fontPoppinsRegularMd">
    //             {nodeObj.data.ver?.toFixed(1)}
    //           </span>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      field: 'modifiedBy',
      title: 'MODIFIED BY',
      class: 'modified-by ',
      width: '160px',
      render: (nodeObj) => {
        return (
          <>
            {!nodeObj.isRootNode && !nodeObj.data.isMockNode && (
              <div
                className="hyper_link_color-common  cursor-pointer fontPoppinsRegularMd overflow-hidden overflow-ellipsis whitespace-nowrap w-24"
                onClick={() => getUserDetails(nodeObj.data)}
                name="modifiedBy"
              >
                <HighlightText text={nodeObj.data.modifiedByUname} highlight={searchedTextQuery} />
              </div>
            )}
          </>
        );
      },
    },
    {
      field: 'action',
      title: 'ACTIONS',
      class: 'action ',
      width: '150px',
      render: (nodeObj) => {
        return !nodeObj.data.isMockNode && actionColumnHtml(nodeObj);
      },
    },
  ];

  const LABELS = {
    EXPAND_ALL: 'Expand All Libraries',
    COLLAPSE_ALL: 'Collapse All Libraries',
    SHOW_ALL_ELEMENTS: 'Show all Step Groups',
    HIDE_ALL_ELEMENTS: 'Hide all Step Groups',
    SHOW_PRE_POST_CONDITION: 'Show Pre/Post Conditions',
    HIDE_PRE_POST_CONDITION: 'Hide Pre/Post Conditions',
  };

  if (isLoading) {
    renderTree = <CommonLoader />;
  } else if (treeData && treeData.length) {
    if (props.pageType === 'parent') {
      renderTree = (
        <TableTree
          data={treeData}
          rootData={rootData}
          colDefs={colDefs}
          showCheckbox={false}
          onCheckedNodes={onCheckedNodes}
          hideElements={false}
          hideConditions={true}
          onRootNodeClicked={rootNodeClicked}
          conditions={[{ key: 'isPreCondition', value: false }]}
          hideConditionBtn={true}
          labels={LABELS}
          onNodeSelected={onNodeSelected}
          id="step-group-tree"
          expandAllApi={loadMoreData}
          loadMoreData={loadMoreData}
          childrenCountKeysArray={['stepGroupCount', 'subLibraryCount']}
          userSearched={searchedTextQuery}
        />
      );
    } else {
      renderTree = (
        <TableTree
          data={treeData}
          rootData={rootData}
          colDefs={colDefs}
          showCheckbox={false}
          onCheckedNodes={onCheckedNodes}
          hideElements={false}
          hideConditions={true}
          onRootNodeClicked={rootNodeClicked}
          conditions={[{ key: 'isPreCondition', value: false }]}
          hideConditionBtn={true}
          labels={LABELS}
          onNodeSelected={onNodeSelected}
          id="step-group-tree"
          userSearched={searchedTextQuery}
        />
      );
    }
  } else if (treeData?.length === 0 && searchedText?.length) {
    renderTree = (
      <div className="">
        <TableTree
          data={treeData}
          rootData={rootData}
          colDefs={colDefs}
          showCheckbox={false}
          onCheckedNodes={onCheckedNodes}
          hideElements={false}
          hideConditions={true}
          onRootNodeClicked={rootNodeClicked}
          conditions={[{ key: 'isPreCondition', value: false }]}
          hideConditionBtn={true}
          labels={LABELS}
          onNodeSelected={onNodeSelected}
          id="step-group-tree"
          userSearched={searchedTextQuery}
        />
      </div>
    );
  } else {
    renderTree = (
      <div>
        <div>
          <TableTree
            data={treeData}
            rootData={rootData}
            colDefs={colDefs}
            showCheckbox={false}
            onCheckedNodes={onCheckedNodes}
            hideElements={false}
            hideConditions={true}
            onRootNodeClicked={rootNodeClicked}
            conditions={[{ key: 'isPreCondition', value: false }]}
            hideConditionBtn={true}
            labels={LABELS}
            onNodeSelected={onNodeSelected}
            id="step-group-tree"
            userSearched={searchedTextQuery}
          />
        </div>
        {global.permission.isEditAccess('repository') && (
          <div className="fontPoppinsRegularMd no-steps">
            <div className="">
              <p className="font-bold mb-4">Quick Start</p>
              <p className="mb-4">
                <span className="font-bold">Step 1 :</span> Click
                <button
                  onClick={() => {
                    createLibraryModal();
                  }}
                  className="ml-2 mr-2 primary-btn"
                >
                  <span className="text-base">+</span> Library
                </button>
                to start adding the Libraries
              </p>
              <p className="mb-4">
                <span className="font-bold">Step 2 :</span> Click
                <button disabled={true} className="ml-2 mr-2 primary-btn">
                  <span className="text-base">+</span> Step Group
                </button>
                to start adding the Step Groups inside Libraries
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="content-wrapper">
      <div className="content_area_style" id="journal-scroll">
        <div className="">
          <TreeToolbar
            countValue={licenseLevelStepGroupCount}
            childResources={childResourcesAvailable}
            header={treeHeader}
            treeData={libraryTree}
            page="Step Group"
            containerCount={libraries}
            resourceCount={stepgroups}
            reloadTree={reloadTreeData}
            MyAlert={props.MyAlert}
            nodeToAdd={nodeToAdd}
            isLoading={isLoading}
            searchTree={searchTreeApi}
            resetTree={getLibraryTree}
            setSearchedText={setSearchedText}
          />
        </div>
        <div className="table-height overflow-auto" id="journal-scroll">
          {renderTree}
        </div>
      </div>

      {stepGroupDetails ? (
        <div>
          <DetailsModal
            popupType={type}
            data={popupData}
            closeModal={closeModal}
            openDetails={openStepGroupDetails}
            containerName="Library"
          />
        </div>
      ) : null}

      {details ? (
        <div>
          <DetailsModal data={popupData} openDetails={openDetails} closeModal={closeModal} containerName="Library" />
        </div>
      ) : null}

      {openEditModal && type === 'library' ? (
        <LibraryModal
          treeData={libraryTree}
          type={type}
          data={popupData}
          nodeToAdd={nodeToAdd}
          closeModal={closeModal}
          containerName="Library"
          reloadTree={reloadTreeData}
          mode="EDIT"
          MyAlert={props.MyAlert}
        />
      ) : openEditModal && type === 'stepgroup' ? (
        <StepGroupModal
          treeData={libraryTree}
          parentId={libraryId}
          parentName={libraryName}
          data={popupData}
          reloadTree={reloadTreeData}
          closeModal={closeModal}
          mode="EDIT"
          nodeToAdd={nodeToAdd}
          MyAlert={props.MyAlert}
        />
      ) : null}
      {/* to add sub library by clicking plus button on the node */}
      {showSubLibraryModal ? (
        <LibraryModal
          parentId={libraryId}
          parentName={libraryName}
          reloadTree={reloadTreeData}
          treeData={libraryTree}
          closeModal={closeModal}
          mode="ADD_SUB"
          nodeToAdd={nodeToAdd}
          MyAlert={props.MyAlert}
        />
      ) : null}
      {showLibraryModal ? (
        <LibraryModal
          pageName={props.pageName}
          reloadTree={reloadTreeData}
          treeData={libraryTree}
          closeModal={closeModal}
          mode="ADD"
          nodeToAdd={nodeToAdd}
          MyAlert={props.MyAlert}
        />
      ) : null}
      {showUserModal ? <UserDetailsModal userDetails={userDetails} closeModal={closeModal} /> : null}
      {openStepGroupModal ? (
        <StepGroupModal
          treeData={libraryTree}
          parentId={libraryId}
          parentName={libraryName}
          reloadTree={reloadTreeData}
          closeModal={closeModal}
          mode="ADD_SUB"
          nodeToAdd={nodeToAdd}
          MyAlert={props.MyAlert}
        />
      ) : null}
      {openDeleteModal ? (
        <DeleteModal nodeToDel={nodeToDel} message={message} closeModal={closeModal} method={deleteNode} />
      ) : null}
      {openUserDetailsModal ? <UserDetailsModal data={userDetails} closeModal={closeUserDetailsModal} /> : null}
      {openCloneInfoModal ? (
        <CloneInfoModal
          cloningContainerData={cloningContainerData}
          container={'Library'}
          cloneContainer={cloneContainer}
          closeModal={closeModal}
        />
      ) : null}

      {moveResourceModalData?.open ? (
        <MoveResource
          MyAlert={props?.MyAlert}
          handleClose={() => {
            setMoveResourceModalData({ ...moveResourceModalData, open: false });
          }}
          moveResourceModalData={moveResourceModalData}
          reloadTree={reloadTreeData}
          stepGroupLibraryMove={true}
          popupdata={popupData}
          page="StepGroup"
        />
      ) : null}
    </div>
  );
};

export default StepGroupTree;

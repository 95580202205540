import { Checkbox } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import React from 'react';

const CheckboxComp = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  if (rest.disabled) {
    return (
      <>
        <Checkbox
          color="primary"
          indeterminate={indeterminate}
          ref={resolvedRef}
          value={rest.value}
          disabled={rest.disabled}
          checked={rest.disabled}
          onClick={(e) => {
            if (e.target.value === 'all') {
              rest.addOrRemoveElement(e.target.value, e.target.checked);
            } else {
              rest.addOrRemoveElement(rest.eleData, e.target.checked);
            }
          }}
          {...rest}
        />
      </>
    );
  } else {
    return (
      <>
        <Checkbox
          color="primary"
          indeterminate={indeterminate}
          ref={resolvedRef}
          value={rest.value}
          disabled={rest.disabled}
          onClick={(e) => {
            if (e.target.value === 'all') {
              rest.addOrRemoveElement(e.target.value, e.target.checked);
            } else {
              rest.addOrRemoveElement(rest.eleData, e.target.checked);
            }
          }}
          {...rest}
        />
      </>
    );
  }
});

export default CheckboxComp;

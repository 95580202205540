import React, { Component, Fragment } from 'react';
import AceEditor from 'react-ace-builds';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-beautify';
import 'react-ace-builds/webpack-resolver-min';
import 'brace/ext/searchbox';
import 'brace/keybinding/emacs';
import 'brace/keybinding/vim';
import 'brace/ext/language_tools';
import 'brace/ext/settings_menu';
import '../css/snippet_Editor.scss';
import VariableSelector from '@pagescommon/variable-selector-myinput';
import cx from 'classnames';
export default class Snippet_Editor_Update extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorWidth: '100%',
      readOnly: false,
      change: '',
      showDropDown: false,
      variableDetails: [],
    };
  }

  componentDidMount() {
    this.onLoadAlert();
  }

  variableObj = {};
  markers = [];
  onLoadAlert = (row) => {
    this.setState({
      readOnly: false,
      change: this.props.valueofAce,
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.change != this.state.change) {
      this.props.getCodeValue(nextState.change);
      return true;
    } else {
      return false;
    }
  }

  showAlert = (rowDefaultSnippet) => {
    const appendValueItem = this.state.change + `\n` + rowDefaultSnippet;
    this.setState({
      change: appendValueItem,
    });
    this.props.getCodeValue(appendValueItem);
  };
  onChangeValue = (value, event) => {
    this.setState({
      change: value,
    });
    if (event.lines[0] === '$' && event.action === 'insert') {
      this.setState({
        showDropDown: true,
      });
      this.state.variableDetails.push(event.start);
    } else {
      this.setState({
        showDropDown: false,
      });
    }
    if (value !== '' && value !== ' ') {
      let objKeys = Object.keys(this.variableObj).join('|');
      let newObjStr = new RegExp(`${objKeys}`, 'g');
      this.apiDataCode = this.addVariable(value, newObjStr, this.variableObj);
    } else {
      this.apiDataCode = '';
    }
    this.props.getCodeValue(this.apiDataCode);
    this.forceUpdate();
  };
  onClickVariableSelector = (name, data) => {
    let tempRow = this.state.variableDetails[this.state.variableDetails.length - 1].row;
    let tempColumn = this.state.variableDetails[this.state.variableDetails.length - 1].column;
    let tempStateArray = this.state.change.split('\n');
    tempStateArray[tempRow] =
      tempStateArray[tempRow].slice(0, tempColumn) +
      tempStateArray[tempRow].slice(tempColumn + 1, tempStateArray[tempRow].length);

    tempStateArray[tempRow] = this.addStr(tempStateArray[tempRow], tempColumn, name);
    let tempStateStr = tempStateArray.join('\n');
    this.variableObj[name] = '${' + data.id + '}';
    let objKeys = Object.keys(this.variableObj).join('|');
    let newObjStr = new RegExp(`${objKeys}`, 'g');

    this.setState({
      showDropDown: false,
      change: tempStateStr,
    });
    this.apiDataCode = this.addVariable(tempStateStr, newObjStr, this.variableObj);
    this.props.getCodeValue(this.apiDataCode, this.state.change);
    this.forceUpdate();
  };
  addStr = (str, index, stringToAdd) => {
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  };

  addVariable = (str, objKeys, varObj) => {
    return str.replace(objKeys, function (matched, index) {
      if (matched !== '') {
        return varObj[matched];
      } else {
        return '';
      }
    });
  };

  render() {
    return (
      <Fragment>
        <AceEditor
          mode="java"
          theme="tomorrow"
          name={this.props?.snippetPopUpDetails === 'snippetPopUpDetails' ? 'UNIQUE_ID_OF_DETAILS' : 'UNIQUE_ID_OF_DIV'}
          className={cx(`ace-common-styles
            ${this.props?.snippetPopUpDetails === 'snippetPopUpDetails' ? 'UNIQUE_ID_OF_DETAILS ml-0' : 'ml-0'}`)}
          height={this.props.snippetPopUpDetails === 'snippetPopUpDetails' ? 270 : 470}
          width={this.state.editorWidth}
          showGutter={true}
          readOnly={this.props.snippetPopUpDetails === 'snippetPopUpDetails' ? true : false}
          style={{ marginLeft: '0%' }}
          onChange={this.onChangeValue}
          editorProps={{ $blockScrolling: true }}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true,
          }}
          value={this.state.change}
        />
        {this.state.showDropDown ? (
          <VariableSelector ShowDropDown={this.state.showDropDown} onClickGetValue={this.onClickVariableSelector} />
        ) : null}
      </Fragment>
    );
  }
}

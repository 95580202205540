import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { useAlert } from '@pages/common/alert_service/useAlert';
import { useHistory } from 'react-router-dom';
import SearchBar from '@src/pages/analytics/common/search-bar';
import { LABELS_CONSTANTS } from '@src/common/ui-constants';
import { ReactComponent as AddIcon } from '@assets/svg-imports/addIcon.svg';
import { CommonLoader } from '@src/pages/common/common-loader';
import { Tooltip } from '@material-ui/core';
import { useTable } from 'react-table';
import '@pages/configuration/plugin/bitBucket-integration/bitBucket.scss';
import '@pages/configuration/config-label.scss';
import CommonDrawerJS from '@pages/configuration/plugin/CommonIntegrationDrawer';
import { TextField, TextareaAutosize } from '@material-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { Menu, Transition } from '@headlessui/react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ConfigLabelDelete from './ConfigLabelDelete';
import {
  getCurrentProjectId,
  getTruncatedText,
  getCurrentProjectStatus,
  getCurrentLicenseId,
} from '@util/common_utils';
import ConfigDetails from './ConfigDetails';
import ConfigUserDetails from './ConfigUserDetails';
import { HighlightText, isEmptyValue, isValueIncludes } from '@util/common_utils';
import NoResultsFound from '@src/pages/common/Table/NoResultFound';

//api import
import { getAllLabelsByProjectId, createLabel, updateLabel, getSingleUserRequest } from '@src/api/api_services';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ConfigLabels = () => {
  const { AlertContatiner, MyAlert } = useAlert();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [openLabelDetails, setOpenLabelDetails] = useState(false);
  const [labelTabelData, setLabelTableData] = useState([]);
  const [seletedLabel, setSelectedLabel] = useState();
  const [openLabel, setOpenLabel] = useState(false);
  const [actionType, setActionType] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [labelNameUpdate, setLabelNameUpdate] = useState();
  const [descUpdate, setDescUpdate] = useState();
  const [openUserDetailsModal, setUserDetailsModal] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [descCount, setdescCount] = useState(0);
  const projectId = getCurrentProjectId();
  const projectStatus = getCurrentProjectStatus();
  const [licenseUserDetails, setLicenseUserDetails] = useState({});
  const [tabsList] = useState([
    {
      name: 'Labels',
      pathUrl: `/configuration/Label/Labels`,
      selectedName: 'Label',
    },
  ]);

  const [userSearched, setuserSearched] = useState('');
  let [isHighlight, setIsHighlight] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');

  const initialValues = {
    name: seletedLabel?.name,
    description: seletedLabel?.description || '',
  };

  useEffect(() => {
    getAllLabels();
  }, []);

  let getAllLabels = () => {
    getAllLabelsByProjectId(projectId).then((responsedata) => {
      setLabelTableData(responsedata?.data?.responseObject);
    });
  };
  let existingLabelNames = labelTabelData.map((label) => label.name);
  const validationSchema = yup.object({
    name: yup
      .string()
      .min(3, 'Minimum 3 characters')
      .max(25, 'Maximum 25 characters')
      .required('Label name is required')
      .strict(true)
      .trim('Space is not allowed at starting and at the end')
      .matches(/^[a-zA-Z0-9-_() ]*$/, 'Name should be alphanumeric')
      .test(function (value) {
        const isNameExist = existingLabelNames.includes(value);
        if (this.parent.currentLabelName && this.parent.currentLabelName === value) {
          return true;
        }
        if (isNameExist) {
          throw this.createError({ message: 'Label name already exists' });
        } else {
          return true;
        }
      }),
    description: yup.string(),
  });

  const formikDetails = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      if (actionType === 'Edit') {
        let createLabelData = {
          id: seletedLabel?.id,
          name: formikDetails.values.name.replace(/  +/g, ' '),
          description: formikDetails.values.description,
          projectId: projectId,
        };
        updateLabel(createLabelData, seletedLabel?.id, projectId)
          .then((response) => {
            if (response?.data.responseCode === 200 && response?.data?.status === 'SUCCESS') {
              getAllLabels();
              closeModal();
              MyAlert.success(`${getTruncatedText(createLabelData?.name, 15)} label updated successfully`);
            } else {
              closeModal();
              MyAlert.warning(response?.data?.message);
            }
          })
          .catch((error) => {
            console.error('Update api Failed!:', error);
          });
      } else {
        let labelData = {
          name: formikDetails.values.name.replace(/  +/g, ' '),
          description: formikDetails.values.description,
        };
        createLabel(labelData, projectId)
          .then((response) => {
            if (response?.data.responseCode === 201 && response?.data?.status === 'SUCCESS') {
              getAllLabels();
              closeModal();
              MyAlert.success(`${getTruncatedText(labelData?.name, 15)} label created successfully`);
            } else {
              closeModal();
              MyAlert.warning(response?.data?.message);
            }
          })
          .catch((error) => {
            console.error('Create api Failed!:', error);
          });
      }
    },
  });

  const { hasEditAccess, hasFullAccess } = React.useMemo(
    () => ({
      hasEditAccess: window.permission?.isEditAccess('configuration'),
      hasFullAccess: window.permission?.isFullAccess('configuration'),
    }),
    []
  );

  // TODO ::  handle action button on user Access
  let isAccessFor = (buttonType) => {
    if (projectStatus === 'Open') {
      if (hasFullAccess && ['edit', 'delete'].includes(buttonType)) {
        return true;
      } else if (hasEditAccess && ['edit'].includes(buttonType)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleOpenDetailsModal = (row) => {
    setSelectedLabel(row?.original);
    setUserDetailsModal(true);
  };

  const handleDelete = () => {
    setOpenDeleteModal(true);
  };

  const handleClose = () => {
    setOpenDeleteModal(false);
  };

  const handleUserDetailsClose = () => {
    setUserDetailsModal(false);
  };

  const openLabelModal = (value, actionType) => {
    setOpenLabelDetails(true);
    setSelectedLabel(value);
  };
  const closeLabelModal = () => {
    setOpenLabelDetails(false);
  };

  const openModal = (value, actionType) => {
    setOpenLabel(true);
    setSelectedLabel(value);
    setActionType(actionType);
    if (actionType === 'Edit') {
      const currentLabelName = value?.name;
      setLabelNameUpdate(currentLabelName);
      formikDetails.setValues({
        ...formikDetails.values,
        name: value?.name,
        description: value?.description,
        currentLabelName: currentLabelName,
      });
      setLabelNameUpdate(value?.name);
      setDescUpdate(value?.description);
      setdescCount(value?.description?.length);
    }
  };

  const closeModal = () => {
    setOpenLabel(false);
    formikDetails.resetForm();
  };

  const openModalDelete = (value, actionType) => {
    setOpenDeleteModal(true);
    setSelectedLabel(value);
    setActionType(actionType);
  };
  const getUserDetails = async () => {
    try {
      const response = await getSingleUserRequest(seletedLabel?.createdBy);
      setUserDetails(response?.data?.responseObject);
      const data = response?.data?.responseObject?.licenses?.filter((value) => value.id === getCurrentLicenseId());
      setLicenseUserDetails(data[0]);
    } catch (error) {
      console.error('GET_USER_DETAILS : ', error);
    }
  };

  useEffect(() => {
    if (openUserDetailsModal) {
      getUserDetails();
    }
  }, [seletedLabel?.createdBy]);

  const renderTabs = () => {
    return (
      <div>
        <ul className="navBar table-header-text flex flex-row primary-tab-style ml-6">
          {tabsList.map((tab, index) => {
            return (
              <li key={tab.name}>
                <a
                  href="#"
                  className="tab-color"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(tab.pathUrl);
                  }}
                >
                  {tab.name}{' '}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'LABEL NAME',
        accessor: 'name',
        disableFilters: true,
        width: '30%',
        Cell: ({ value, row }) => (
          <div
            className="label-name float-left table-font-style-common cursor-pointer fontPoppinsRegularSm path-trimmer"
            title={value}
            onClick={() => openLabelModal(row?.original)}
          >
            <HighlightText text={value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'LABEL TYPE',
        accessor: 'type',
        disableFilters: true,
        width: '20%',
        Cell: ({ value }) => (
          <div className="float-left table-font-style-common fontPoppinsRegularSm" title={value}>
            {value}
          </div>
        ),
      },
      {
        Header: 'MODIFIED BY',
        accessor: 'modifiedByUname',
        disableFilters: true,
        width: '20%',
        Cell: ({ value, row }) => {
          return (
            <div
              title={value}
              className={`label-name float-left table-font-style-common cursor-pointer fontPoppinsRegularSm path-trimmer`}
              onClick={() => handleOpenDetailsModal(row)}
            >
              <HighlightText text={value || '--'} highlight={searchTextHighlight} />
            </div>
          );
        },
      },
      {
        Header: 'MODIFIED ON',
        accessor: 'modifiedOn',
        disableFilters: true,
        width: '20%',
        Cell: ({ value, row }) => {
          return (
            <div title={value} className="float-left table-font-style-common fontPoppinsRegularSm">
              {value || '--'}
            </div>
          );
        },
      },
      {
        Header: 'ACTIONS',
        accessor: 'Actions',
        disableFilters: true,
        width: '20%',
        Cell: ({ row }) => {
          return (
            <div>
              <span className="flex flex-row table-non-link-color-common float-left table-font-style-common">
                {isAccessFor('edit') ? (
                  <span
                    className={`show_action  ${
                      row?.original?.type === 'Predefined'
                        ? ' opacity-40 '
                        : ' action-button cursor-pointer opacity-100'
                    } `}
                    onClick={() => openModal(row.original, 'Edit')}
                  >
                    <Tooltip
                      title={
                        row?.original?.type === 'Predefined'
                          ? LABELS_CONSTANTS.PREDEFINED_LABEL_EDIT
                          : LABELS_CONSTANTS.EDIT
                      }
                      placement="bottom"
                    >
                      <button disabled={row?.original?.type === 'Predefined'}>
                        <EditIcon />
                      </button>
                    </Tooltip>
                  </span>
                ) : (
                  <span></span>
                )}
                {isAccessFor('delete') ? (
                  <span
                    className={`show_action ${
                      row?.original?.type === 'Predefined'
                        ? ' opacity-40 '
                        : ' action-button cursor-pointer opacity-100'
                    } `}
                    onClick={() => openModalDelete(row.original, 'Delete')}
                  >
                    <Tooltip
                      title={
                        row?.original?.type === 'Predefined'
                          ? LABELS_CONSTANTS.PREDEFINED_LABEL_DELETE
                          : LABELS_CONSTANTS.DELETE
                      }
                      placement="bottom"
                    >
                      <button disabled={row?.original?.type === 'Predefined'}>
                        <DeleteOutlinedIcon />
                      </button>
                    </Tooltip>
                  </span>
                ) : (
                  <span></span>
                )}
                <span className="show_action action-button">
                  <Tooltip title="More" placement="bottom">
                    <Menu as="div" className="relative show_action" key="user-menu">
                      {({ open }) => (
                        <>
                          <div>
                            <Menu.Button className="bg-gray-100  flex items-center text-gray-400 hover:text-gray-600 focus:outline-none ">
                              <span className="sr-only z-10">Open options</span>
                              <MoreVertIcon />
                            </Menu.Button>
                          </div>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="origin-top-right absolute right-0 z-40 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <div
                                      className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'px-4 py-2 text-sm'
                                      )}
                                      onClick={() => openLabelModal(row.original, 'more')}
                                    >
                                      {LABELS_CONSTANTS.DETAILS}
                                    </div>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </Tooltip>
                </span>
                <span className="project-action pt-1 text-blue-700 text-base cursor-pointer state-button ml-2"></span>
              </span>
            </div>
          );
        },
      },
    ],
    [isHighlight]
  );

  function Table({ columns, data }) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });
    return (
      <>
        <table {...getTableProps()} className="h-3 mr-3">
          <thead className="projectDetailsDataa">
            {headerGroups?.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="text-sm h-9 ml-4  top-0">
                {headerGroup?.headers?.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    width={column.width}
                    className=" text-left mx-2 fontPoppinsSemiboldSm pl-3 table-line"
                  >
                    <span className="table-header-text">{column.render('Header')}</span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="text text-gray-700">
            {rows?.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="configHover h-10 hover:bg-gray-100 mt-10 show-action-style">
                  {row?.cells?.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="p-0  text-left text-gray-700 fontPoppinsRegularSm pl-3 project-row"
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {openSearch && isEmptyValue(data) && <NoResultsFound className="mt-14" />}
      </>
    );
  }

  // TODO :: handle Search filter :: START

  const [openSearch, setOpenSearch] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(labelTabelData);

    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [labelTabelData]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(labelTabelData);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setData(labelTabelData);
    setIsHighlight(!isHighlight);
    setSearchTextHighlight('');
  };
  let handleSearchOpen = () => {
    setOpenSearch(true);
  };
  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = labelTabelData.filter(({ name, createdByUname }) => {
          return isValueIncludes(e, name) || isValueIncludes(e, createdByUname);
        });
        setData(newData);
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };
  // TODO :: handle Search filter :: END

  return (
    <>
      <form onSubmit={formikDetails.handleSubmit}>
        {openLabel && (
          <CommonDrawerJS
            isDrawerOpen={true}
            headerContent={
              actionType === 'Edit'
                ? `${LABELS_CONSTANTS.EDIT_LABEL} - ${formikDetails.values.name}`
                : LABELS_CONSTANTS.CREATE_LABEL
            }
            drawerWidth="500px"
            rightButtonText={actionType === 'Edit' ? LABELS_CONSTANTS.UPDATE : LABELS_CONSTANTS.CREATE}
            leftButtonText="Cancel"
            onDrawerOpen={closeModal}
            onRightButtonClick={formikDetails.handleSubmit}
            onLeftButtonClick={closeModal}
            disableRightButton={
              actionType === 'Edit'
                ? formikDetails.values.name !== labelNameUpdate ||
                  (descUpdate !== undefined && descCount?.length > 0) ||
                  formikDetails?.values?.description?.length !== descUpdate?.length
                  ? false
                  : true
                : false
            }
          >
            <section className="w-full  flex flex-col justify-center items-center overflow-hidden">
              <div className="w-full flex flex-col mt-2 ml-8">
                <div className="fontPoppinsMediumSm text-color">
                  {
                    <>
                      {' '}
                      <span className="text-red-600">*</span> {LABELS_CONSTANTS.NAME}
                    </>
                  }{' '}
                </div>
                <div className="mt-2">
                  <TextField
                    className={`instance-input-field input-field-width`}
                    variant="outlined"
                    name="name"
                    autoComplete="off"
                    id="labelName"
                    placeholder={LABELS_CONSTANTS.NAME_PLACEHOLDER}
                    value={formikDetails.values.name}
                    onChange={formikDetails.handleChange}
                    error={formikDetails.errors.name && formikDetails.touched.name}
                  />
                </div>
                {formikDetails.errors.name && formikDetails.touched.name ? (
                  <div className="error-message mt-2 fontPoppinsRegularXs ml-2">{formikDetails.errors.name}</div>
                ) : null}
              </div>
              <div className="w-full flex flex-col mt-2 ml-8">
                <div className="fontPoppinsMediumSm text-color">{LABELS_CONSTANTS.DESCRIPTION}</div>
                <div className="mt-2">
                  <TextareaAutosize
                    aria-label="minimum height"
                    className="instance-input-field input-field-width description-area-style fontPoppinsMediumSm"
                    minRows={3}
                    maxLength="200"
                    name="description"
                    placeholder={LABELS_CONSTANTS.DESCRIPTION_PLACEHOLDER}
                    onKeyUp={(e) => setdescCount(e.target.value.length)}
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.description}
                  />
                  <div className="mt-1 text-sm text-color fontPoppinsRegularSm text-right pr-12">{descCount}/200</div>
                </div>
              </div>
            </section>
          </CommonDrawerJS>
        )}
      </form>
      <div className="page-table-body">
        <div className="alert-position-style">
          <AlertContatiner></AlertContatiner>
        </div>
        {renderTabs()}
      </div>
      <div className="content_area_style_Int overflow-scroll">
        <div className="pt-3 pb-3 border_style flex flex-wrap items-center sticky">
          <label className="project-label text-sm flex-auto fontPoppinsSemiboldMd">{LABELS_CONSTANTS.LABELS}</label>
          <div className={`flex items-center justify-end w-2/5 mt-1 pr-2 ${hasEditAccess ? 'mr-32' : ''}`}>
            <SearchBar
              open={openSearch}
              searchValue={userSearched}
              handleSearch={handleSearch}
              onSearchClose={handleSearchClose}
              handleSearchOpen={handleSearchOpen}
              enterKeyHandler={handleSearchSubmit}
              disable={isEmptyValue(labelTabelData)}
            />
          </div>
          {projectStatus === 'Open' && hasEditAccess ? (
            <div
              className="grid grid-cols-12 col-span-11 config-label-button flex-row cursor-pointer mr-5 w-24"
              onClick={() => {
                openModal();
              }}
            >
              <span className="col-span-2">
                <AddIcon className="addDefectIcon" />
              </span>
              <span className=" col-span-10 absolute mt-2 ml-10 fontPoppinsMediumSm">{LABELS_CONSTANTS.LABELS}</span>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="table-height" id="journal-scroll">
          {isLoading ? <CommonLoader /> : <Table columns={columns} data={data || []} />}
        </div>
      </div>

      {openLabelDetails && (
        <ConfigDetails isOpen={openLabelDetails} onClose={closeLabelModal} labelDetails={seletedLabel} />
      )}

      {openDeleteModal && (
        <ConfigLabelDelete
          openModal={handleDelete}
          closeModal={handleClose}
          seletedLabel={seletedLabel}
          actionType={actionType}
          MyAlert={MyAlert}
          getAllLabels={getAllLabels}
          openDeleteModal={setOpenDeleteModal}
        />
      )}

      {openUserDetailsModal && (
        <ConfigUserDetails
          isOpen={openUserDetailsModal}
          onClose={handleUserDetailsClose}
          seletedLabel={seletedLabel}
          userDetails={userDetails}
          licenseUserDetails={licenseUserDetails}
        />
      )}
    </>
  );
};

export default withRouter(ConfigLabels);

export const LABELS = {
  EXPAND_ALL: 'Expand All',
  COLLAPSE_ALL: 'Collapse All',
  SHOW_ALL_ELEMENTS: 'Show all scripts',
  HIDE_ALL_ELEMENTS: 'Hide all scripts',
  SHOW_PRE_POST_CONDITION: 'Show Pre/Post Conditions',
  HIDE_PRE_POST_CONDITION: 'Hide Pre/Post Conditions',
};

export const actionMenuFolder = [
  {
    name: 'Details',
  },
  // {
  //     name: "History",
  // },
  {
    name: 'Clone',
  },
  {
    name: 'Move',
  },
  {
    name: 'Export Manual Test cases',
  },
];
export const actionMenuFile = [
  {
    name: 'Details',
  },
  // {
  //     name: "History",
  // },
  {
    name: 'Clone',
  },
  //NEEDED in future
  // {
  //     name: "Export in Java",
  // },
  {
    name: 'Map to Jira',
  },
  {
    name: 'Move',
  },
  {
    name: 'Linked Defects',
  },
];

export const actionMenuFileNoClone = [
  {
    name: 'Details',
  },
  // {
  //   name: "History",
  // },
  //NEEDED in future
  // {
  //   name: "Export in Java",
  // },
];
export const actionMenuRoot = [
  {
    name: 'Details',
  },
  // {
  //   name: 'Export Manual Test cases',
  // },
  // {
  //     name: "History",
  // }
];

export const plusIconArray = [
  {
    name: 'Add Module',
    value: 'folder',
  },
  {
    name: 'Add Automation Script',
    value: 'file auto',
  },
  {
    name: 'Add Manual Test Case',
    value: 'file manual',
  },
];

export const ifFailedOptionsModuleLevel = [
  {
    label: 'Mark this step as Failed and continue module execution',
    value: 'MARK_THIS_STEP_AS_FAILED_AND_CONTINUE_MODULE_EXECUTION',
  },
  {
    label: 'Mark this step as Warning and continue module execution',
    value: 'MARK_THIS_STEP_AS_WARNING_AND_CONTINUE_MODULE_EXECUTION',
  },
  {
    label: 'Mark this step as Failed and stop current module execution',
    value: 'MARK_THIS_STEP_AS_FAILED_AND_STOP_CURRENT_MODULE_EXECUTION',
  },
  {
    label: 'Mark this step as Failed and stop complete execution',
    value: 'MARK_THIS_STEP_AS_FAILED_AND_STOP_COMPLETE_EXECUTION',
  },
];

export const machineJson = {
  createdBy: '',
  modifiedBy: '',
  createdByUname: '',
  modifiedByUname: '',
  createdOn: '',
  modifiedOn: '',
  id: 'SCDRSAMP01',
  clientId: '',
  machineId: 'SCDRSAMP01',
  installedDir: '',
  port: '',
  status: '',
  executionStatus: 'Available',
  disabled: false,
  licenseId: '',
  userId: '',
  machine: {
    osName: '',
    osVersion: '',
    hostName: '',
  },
  systemConfigDetails: [],
  externalPlugins: [],
  executionEnvironment: '',
  access: '',
  network: '',
  topic: '',
  clientPort: '',
  driverSynced: true,
  isDriverSyncFirstTimeSuccessfully: true,
};
export const MOVE_RESOURCE = {
  MOVE_INSIDE: 'Move Inside',
  MOVE_BELOW: 'Move Below',
  MOVE_PAGE: 'Move Page',
  MOVE_ELEMENT: 'Move Element',
  MOVE_SCRIPT: 'Move Script',
  MOVE_MODULE: 'Move Module',
  MOVE_LIBRARY: 'Move Library',
  MOVE_STEPGROUP: 'Move Step Group ',
};

import ChartCard from '../components/ChartCard';
import Chart from 'react-apexcharts';
import DashboardStyle from '../styles/dashboard.module.scss';
import cx from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { fontPoppins } from '@src/css_config/fontConstants';
import { commonCustomTooltip } from '@src/common/CommonCustomTooltip';
async function handleChartElementsStyle(element) {
  if (element) {
    const chartElement = element.querySelector('.apexcharts-canvas');
    if (chartElement) {
      const chartHeight = chartElement.style.height;
      const totalHeight = Number(chartHeight?.replace('px', '') || 0) + 7;
      chartElement.style.height = `${totalHeight}px`;
      const svgElement = chartElement.querySelector('svg');
      if (svgElement) {
        svgElement.setAttribute('height', totalHeight);
      }
    }

    const restSeriesLegendElement = element.querySelector(`.apexcharts-legend-series[seriesname="${noData.label}"]`);

    if (restSeriesLegendElement) {
      restSeriesLegendElement.style.visibility = 'hidden';
      restSeriesLegendElement.style.display = 'none';
    }

    const totalValueElement = element.querySelector('.apexcharts-datalabel-value');

    if (totalValueElement) {
      const offsetYOfTotalValueElement = totalValueElement?.getAttribute('y');
      const totalValueLabelElement = element.querySelector('.apexcharts-datalabel-label');

      totalValueLabelElement?.setAttribute('y', Number(offsetYOfTotalValueElement) + 15);
    }

    const donutChartCircleElement = element.querySelector('.apexcharts-pie circle');
    if (donutChartCircleElement) {
      donutChartCircleElement.setAttribute('stroke', '#EBF2F7');
      donutChartCircleElement.setAttribute('stroke-width', '10');
    }
  }
}

const noData = {
  color: '#EBF2F7',
  label: 'nodata',
};

function DonutChartWithDescriptionAndFooter({
  colors,
  labels,
  totalLabel,
  title,
  series,
  icons,
  customTooltipFormatter = commonCustomTooltip,
  roundedDirection = 'bl',
  typeOfData = '',
}) {
  const ref = useRef();
  const [isLoading, setIsLoading] = useState(true);

  const handleLoading = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    window.addEventListener('load', handleLoading);
    return () => window.removeEventListener('load', handleLoading);
  }, []);

  const chartOptions = useMemo(
    () => ({
      chart: {
        redrawOnWindowResize: true,
        redrawOnParentResize: true,
        events: {
          mounted: () => {
            handleChartElementsStyle(ref?.current);
          },
          updated: () => {
            handleChartElementsStyle(ref?.current);
          },
          mouseMove: () => {
            const element = ref.current;
            if (element) {
              const tooltipElement = element.querySelector('.apexcharts-tooltip');
              if (tooltipElement?.style?.left) {
                tooltipElement.style.left = 0;
                tooltipElement.style['margin-left'] = '10px';
              }
            }
          },
        },
      },
      colors: [...colors, noData.color],
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (value, { seriesIndex, w, ...rest }) => {
          const { seriesNames, series } = w.globals;
          return seriesNames[seriesIndex] !== noData.label
            ? `${series[seriesIndex].toLocaleString('en-US', {
                minimumIntegerDigits: 2,
              })}`
            : '';
        },
        style: {
          fontSize: '12px',
          fontWeight: '400',
          fontFamily: fontPoppins.rXs.fontFamily,
          color: '#3C3838',
        },
        background: {
          enabled: true,
          foreColor: '#000',
          padding: 2,
          borderRadius: 30,
          borderWidth: 30,
          borderColor: '#fff',
          opacity: 1,
          offsetX: 310,
          dropShadow: {
            enabled: true,
            top: 0,
            left: 0,
            blur: 17,
            color: '#0000002A',
            opacity: 1,
          },
        },
      },
      labels: [...labels, noData.label],
      legend: {
        onItemClick: {
          toggleDataSeries: false,
        },
        formatter: (seriesName, { seriesIndex, w }) => {
          const { series: seriesData } = w.globals;
          const seriesDataSum = seriesData
            .slice(0, -1)
            .reduce((previousValue, currentValue) => (previousValue += currentValue), 0);
          if (seriesDataSum === 0 && title === 'Versions' && w?.config?.typeOfData) {
            return `
            <div>
              <h1>No Client has been registered for ${typeOfData.charAt(0).toUpperCase() + typeOfData.slice(1)}! </h1>
            </div>`;
          }
          return `
            <span>
                <strong>${seriesName}</strong>
                <p>In ${seriesName} currently ${w.config.series[seriesIndex]} systems are allocated which are in use</p>
            </span>
          `;
        },
        fontFamily: fontPoppins.rXs.fontFamily,
        horizontalAlign: 'center',
        fontSize: '12px',
        onItemHover: {
          highlightDataSeries: false,
        },
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          dataLabels: {
            offset: 25,
          },
          donut: {
            size: '60%',
            labels: {
              show: true,
              value: {
                show: true,
                offsetY: -15,
                fontFamily: fontPoppins.sSm.fontFamily,
                fontSize: '22px',
                fontWeight: '700',
              },
              total: {
                show: true,
                showAlways: true,
                label: totalLabel,
                offsetY: 30,
                ...fontPoppins.rXs,
                color: '#B3B8BD',
                formatter: function (w) {
                  return w.globals.seriesTotals.slice(0, -1).reduce((a, b) => {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },
      title: {
        text: title,
        align: 'left',
        margin: 10,
        offsetX: 10,
        offsetY: 10,
        floating: false,
        style: {
          ...fontPoppins.rLg,
          color: '#3C3838',
        },
      },
      tooltip: {
        custom: customTooltipFormatter,
      },
      typeOfData,
    }),
    [labels, colors, title, totalLabel, customTooltipFormatter, typeOfData]
  );

  useEffect(() => {
    ref.current && handleChartElementsStyle(ref.current);
  });

  const noDataSeries = series.some((number) => number > 0) ? 0 : 1;
  return (
    <div ref={ref}>
      <ChartCard
        className={cx(DashboardStyle['donut-chart-with-description-and-footer'], 'flex flex-col justify-between')}
        roundedDirection={roundedDirection}
      >
        <Chart options={chartOptions} series={[...series, noDataSeries]} type="donut" height="85%" />
        <div className={cx('mx-1 mb-1 py-2 flex items-center rounded-lg', DashboardStyle['footer'])}>
          <div className="flex px-2 border-r border-black justify-start ">
            {icons?.map((IconComponent, iconIndex) => (
              <IconComponent width={20} className="mx-1 " key={`client-donut-footer-icon-${iconIndex}`} />
            ))}
          </div>
          <div className="flex flex-wrap justify-start px-2">
            {labels?.map((label, labelIndex) =>
              label !== 'nodata' ? (
                <span className="mx-3 my-0.5 fontPoppinsRegularMd" key={`client-donut-footer-label-${labelIndex}`}>
                  {label}
                </span>
              ) : (
                ''
              )
            )}
          </div>
        </div>
      </ChartCard>
    </div>
  );
}

export default DonutChartWithDescriptionAndFooter;

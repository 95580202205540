import React, { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
function CloneInfoModal(props) {
  const [open, setOpenModal] = useState(true);
  const user = JSON.parse(localStorage.getItem('test-optimize-user'));
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={open}
        onClose={() => {
          props.closeModal(false);
          setOpenModal(false);
        }}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block cloneInfoMargin align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/4">
              <div className="inline-block float-left pl-16 pt-14 h-44 w-1/3">
                <div className=" ml-2">
                  <span className="pr-8">
                    <svg
                      id="info_black_48dp"
                      xmlns="http://www.w3.org/2000/svg"
                      width="96"
                      height="96"
                      viewBox="0 0 96 96"
                    >
                      <path id="Path_175" data-name="Path 175" d="M0,0H96V96H0Z" fill="none" />
                      <path
                        id="Path_176"
                        data-name="Path 176"
                        d="M48,2A46,46,0,1,0,94,48,46.017,46.017,0,0,0,48,2Zm0,69a4.614,4.614,0,0,1-4.6-4.6V48a4.6,4.6,0,0,1,9.2,0V66.4A4.614,4.614,0,0,1,48,71Zm4.6-36.8H43.4V25h9.2Z"
                        fill="#2b71f7"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <div className="inline-block h-44 w-2/3">
                <div className="pt-14 text-gray-500">
                  <div className="pb-4 fontPoppinsMediumPageSubHeader">
                    <span className="cloneInfoMadalTitleInfoColour">Info! </span>
                    <span className="cloneInfoMadalTitleColour">Clone</span>
                  </div>
                  <p className="pb-4 fontPoppinsRegularLg"> Hi {user.name},</p>
                  <p className="fontPoppinsRegularLg">
                    <span title={props.cloningContainerData.title}>
                      {props.cloningContainerData.title.length > 25
                        ? 'The ' + props.cloningContainerData.title.slice(0, 22) + '... '
                        : 'The ' + props.cloningContainerData.title}
                    </span>
                    - Copy {props?.container ? props?.container : 'Page'} will get created. Are you sure you want to
                    clone?{' '}
                  </p>
                  <p className="fontPoppinsRegularLg"> How do you want to proceed?</p>
                </div>
              </div>
              <div>
                <div className="cloneInfoModalFooter mt-4 h-20">
                  <div className="pb-2 flex items-center mr-4 float-right h-20">
                    <button
                      type="button"
                      className=" gray-btn ml-1 float-right"
                      onClick={() => {
                        props.closeModal(false);
                        setOpenModal(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="ml-4 primary-btn float-right"
                      onClick={() => {
                        props.cloneContainer(props.cloningContainerData);
                        props.closeModal(false);
                        setOpenModal(false);
                      }}
                    >
                      Clone
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default CloneInfoModal;

import React, { useEffect, useState, useContext } from 'react';
import ReactTable from '@src/pages/common/Table/ReactTable';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import BrowserStackModal from './browser_sauce_modal';
import { deleteBrowserStack, deleteSauceLab, getBrowserStackList, getSauceLabsList } from '@api/api_services';
import DeleteModal from '@pages/common/delete_modal';
import BrowserStackDetails from './browser_sauce_details';
import { useHistory } from 'react-router-dom';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { CommonLoader } from '@src/pages/common/common-loader';
import { Tooltip } from '@material-ui/core';
import ContextMenu from '@pages/common/context_menu';
import SearchBar from '@pages/analytics/common/search-bar';
import { HighlightText, isEmptyValue, isValueIncludes } from '@util/common_utils';
import NoResultsFound from '@pages/common/Table/NoResultFound';

function BrowserStackTable(props) {
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [message, setMessage] = useState('');
  const [reloadTree, setReloadTree] = useState(true);
  let [isLoading, setIsLoading] = useState(false);
  const [account, setAccount] = useState([]);
  const [accountData, setAccountData] = useState();
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [mode, setMode] = useState('');

  const contextMenuData = [
    {
      option: 'Details',
    },
  ];

  function contextMenuFunction(event, ele) {
    if (event.target.textContent === 'Details') {
      setAccountData(ele);
      setOpenDetailsModal(true);
    }
  }
  const history = useHistory();
  let reloadpage = () => {
    setReloadTree(true);
  };

  const getSauceLabs = () => {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    getSauceLabsList()
      .then((response) => {
        stopProgress();
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 10,
        });
        if (response.data.responseObject) {
          response.data.responseObject.map((res, i) => {
            res.Actions = [];
            res.Actions.push(
              <div className="actionIcons">
                <Tooltip title="Edit">
                  <EditOutlinedIcon
                    className="float-left cursor-pointer text-base mr-3 text-blue-700"
                    onClick={() => {
                      setMode('EDIT');
                      setAccountData(res);
                      setOpenAccountModal(true);
                    }}
                  />
                </Tooltip>
                <Tooltip title="Delete">
                  <DeleteOutlinedIcon
                    className="float-left cursor-pointer text-base mr-3 text-blue-700"
                    onClick={() => {
                      setAccountData(res);
                      setMessage(`${res.instanceName} account`);
                      setOpenDeleteModal(true);
                    }}
                  />
                </Tooltip>
                <div className="float-left project-action text-blue-700 text-base cursor-pointer">
                  <ContextMenu
                    contextMenuData={res ? contextMenuData : [...contextMenuData]}
                    func={(e) => {
                      contextMenuFunction(e, res);
                    }}
                    configSection={true}
                  />
                </div>
              </div>
            );
            res.slNo = i + 1;
          });
          setAccount((d) => response.data.responseObject);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setAccount([]);
        }
      })
      .catch((err) => {
        resetProgress();
        console.error('GET_SAUCELAB_LIST : ', err);
      });
  };
  const getBrowserStack = () => {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    getBrowserStackList()
      .then((response) => {
        stopProgress();
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 10,
        });
        if (response.data.responseObject) {
          response.data.responseObject.map((res, i) => {
            res.Actions = [];
            res.Actions.push(
              <div className="actionIcons">
                <Tooltip title="Edit">
                  <EditOutlinedIcon
                    className="float-left cursor-pointer text-base mr-3 text-blue-700"
                    onClick={() => {
                      setMode('EDIT');
                      setAccountData(res);
                      setOpenAccountModal(true);
                    }}
                  />
                </Tooltip>
                <Tooltip title="Delete">
                  <DeleteOutlinedIcon
                    className="float-left cursor-pointer text-base mr-3 text-blue-700"
                    onClick={() => {
                      setAccountData(res);
                      setMessage(`${res.instanceName} account`);
                      setOpenDeleteModal(true);
                    }}
                  />
                </Tooltip>
                <div className="float-left project-action text-blue-700 text-base cursor-pointer">
                  <ContextMenu
                    contextMenuData={res ? contextMenuData : [...contextMenuData]}
                    func={(e) => {
                      contextMenuFunction(e, res);
                    }}
                    configSection={true}
                  />
                </div>
              </div>
            );
            res.slNo = i + 1;
          });
          setAccount((d) => response.data.responseObject);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setAccount([]);
        }
      })
      .catch((err) => {
        resetProgress();
        console.error('GET_BROWSERSTACK_LIST :', err);
      });
  };

  const closeModal = (value) => {
    if (openAccountModal) {
      setOpenAccountModal(false);
    } else if (openDeleteModal) {
      setOpenDeleteModal(false);
    } else if (openDetailsModal) {
      setOpenDetailsModal(false);
    }
  };

  // TODO :: handle Search filter :: START
  const [userSearched, setuserSearched] = useState('');
  const [data, setData] = useState([]);
  let [isHighlight, setIsHighlight] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');
  let [openSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    setData(account);

    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [account]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(account);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setData(account);
    setIsHighlight(!isHighlight);
    setSearchTextHighlight('');
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = account.filter(({ instanceName, username, createdByUname }) => {
          return isValueIncludes(e, instanceName) || isValueIncludes(e, username) || isValueIncludes(e, createdByUname);
        });
        setData(newData);
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  // TODO :: handle Search filter :: END

  const columns = React.useMemo(
    () => [
      {
        Header: 'INSTANCE NAME',
        accessor: 'instanceName',
        disableFilters: true,
        Cell: (e) => (
          <div
            className=" float-left  cursor-pointer text-blue-700  fontPoppinsRegularMd name-trimmer"
            title={e.value}
            onClick={() => {
              props.handelInstanceData(e?.row?.values);
              localStorage.setItem('instanceLocal', e?.row?.values?.instanceName);
              localStorage.setItem('userLocal', e?.row?.values?.username);
              localStorage.setItem('accessKeyLocal', e?.row?.values?.accessKey);
              history.push(
                `/configuration/integration/plugin/${props.mode === 'BrowserStack' ? 'BrowserStack' : 'SauceLab'}/instanceDetails?instanceName=${e.value}`
              );
            }}
          >
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'USERNAME',
        accessor: 'username',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <Tooltip title={value} placement="top">
              <div className=" float-left fontPoppinsRegularMd overflow-hidden overflow-ellipsis whitespace-nowrap w-24">
                <HighlightText text={value} highlight={searchTextHighlight} />
              </div>
            </Tooltip>
          );
        },
      },
      {
        Header: 'ACCESS KEY',
        accessor: 'accessKey',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className=" float-left fontPoppinsRegularMd encode">
              {value && value.length > 10 ? value.substring(0, 10) : value}
            </div>
          );
        },
      },
      {
        Header: 'CREATED ON',
        accessor: 'createdOn',
        disableFilters: true,
        Cell: (e) => (
          <span onClick={() => {}} className="float-left fontPoppinsRegularMd">
            {e.value}
          </span>
        ),
      },
      {
        Header: 'CREATED BY',
        accessor: 'createdByUname',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <Tooltip title={value} placement="top">
              <div className=" float-left fontPoppinsRegularMd overflow-hidden overflow-ellipsis whitespace-nowrap w-24">
                <HighlightText text={value} highlight={searchTextHighlight} />
              </div>
            </Tooltip>
          );
        },
      },
      {
        Header: 'ACTIONS',
        accessor: 'Actions',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common fontPoppinsRegularMd">{value}</div>;
        },
      },
    ],
    [isHighlight]
  );

  const deleteAccount = () => {
    if (props.mode === 'BrowserStack') {
      deleteBrowserStack(accountData.id).then((response) => {
        if (response.data.responseCode === 200) {
          props.MyAlert.success(
            `${accountData.instanceName}`.length >= 22
              ? `${accountData.instanceName}`.substring(0, 22) + '...' + ' instance is deleted successfully'
              : `${accountData.instanceName} instance is deleted successfully`
          );
          closeModal();
          reloadpage();
        }
      });
    } else {
      deleteSauceLab(accountData.id).then((response) => {
        if (response.data.responseCode === 200) {
          props.MyAlert.success(
            `${accountData.instanceName}`.length >= 22
              ? `${accountData.instanceName}`.substring(0, 22) + '...' + ' instance is deleted successfully'
              : `${accountData.instanceName} instance is deleted successfully`
          );
          closeModal();
          reloadpage();
        }
      });
    }
  };

  useEffect(() => {
    if (reloadTree) {
      if (props.mode === 'BrowserStack') {
        setIsLoading(true);
        getBrowserStack();
      } else {
        setIsLoading(true);
        getSauceLabs();
      }
    }
    setReloadTree(false);
  }, [reloadTree]);

  return (
    <div className="page-table-body">
      <div className="content_area_style_TCM">
        <div className="content_area_style_tcTemplate" id="journal-scroll">
          <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
            <label className="project_label flex-auto">
              {props.mode === 'BrowserStack' ? 'Browser Stack' : 'Sauce Labs'}
            </label>
            <div className="pr-4">
              <div className="flex flex-row ml-32">
                <SearchBar
                  open={openSearch}
                  searchValue={userSearched}
                  handleSearch={handleSearch}
                  onSearchClose={handleSearchClose}
                  handleSearchOpen={handleSearchOpen}
                  enterKeyHandler={handleSearchSubmit}
                  disable={isEmptyValue(account)}
                />

                <div className="ml-auto pl-4">
                  <button
                    className="ml-3 primary-btn"
                    onClick={() => {
                      setOpenAccountModal(true);
                      setMode('ADD');
                    }}
                  >
                    + Instance
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="table-height overflow-auto" id="journal-scroll">
            <ReactTable data={data} columns={columns} tableHeightClass={'main-table-height'} />
            {isLoading ? (
              <CommonLoader />
            ) : isEmptyValue(account) ? (
              <div className="fontPoppinsRegularMd no-steps">
                <div className="">
                  <p className="font-bold mb-4">Quick Start</p>
                  <p className="mb-4">
                    <span className="font-bold">Step 1 :</span> Click
                    <button
                      onClick={() => {
                        setOpenAccountModal(true);
                        setMode('ADD');
                      }}
                      className="ml-3 mr-3 primary-btn"
                    >
                      + Instance
                    </button>
                    to start adding Account
                  </p>
                </div>
              </div>
            ) : (
              <> {openSearch && isEmptyValue(data) && <NoResultsFound className="mt-14" />}</>
            )}
          </div>
        </div>
      </div>
      {openAccountModal ? (
        <BrowserStackModal
          closeModal={closeModal}
          reloadTree={reloadpage}
          mode={mode}
          MyAlert={props.MyAlert}
          data={accountData}
          type={props.mode}
          instanceList={account}
        />
      ) : null}
      {openDeleteModal ? <DeleteModal message={message} closeModal={closeModal} method={deleteAccount} /> : null}
      {openDetailsModal ? <BrowserStackDetails data={accountData} closeModal={closeModal} /> : null}
    </div>
  );
}

export default BrowserStackTable;

import React, { useState } from 'react';
import styles from '../analytics.module.scss';
import { ReactComponent as PassedIcon } from '@assets/analytics/PassedIcon.svg';
import { ReactComponent as FailedIcon } from '@assets/analytics/FailedIcon.svg';
import { ReactComponent as WarningIcon } from '@assets/analytics/WarningIcon.svg';
import { ReactComponent as SkippedIcon } from '@assets/analytics/SkippedIcon.svg';
import { ReactComponent as FlakyIcon } from '@assets/analytics/FlakyIcon.svg';
import cx from 'classnames';

const TestScriptDetails = ({ count, name, color, content, selectedStatus, handleStatusClick, iconBg }) => {
  const [hover, setHover] = useState({
    isSelected: false,
    title: '',
  });

  return (
    <section
      className={cx(
        styles['testDetailsCard'],
        (selectedStatus?.isSelected && selectedStatus?.name?.includes(name)) ||
          (hover?.isSelected && name === hover?.title)
          ? styles[name]
          : null
      )}
      onClick={(e) => {
        e.stopPropagation();
        handleStatusClick(name, true, color, count);
      }}
      onMouseOver={(e) => {
        e.stopPropagation();
        setHover({
          isSelected: true,
          title: name,
        });
      }}
      onMouseOut={() => setHover(null)}
    >
      <div className={styles['testDetailsHeader']}>
        <div className={cx('fontPoppinsSemiboldSm', styles['testDetailsHeaderContent'])}>
          <div style={{ color }}>{name}</div>
          <div>{count}</div>
        </div>
        <div className={styles['iconBlock']} style={{ background: iconBg }}>
          {name === 'PASSED' ? (
            <PassedIcon />
          ) : name === 'FAIL' ? (
            <FailedIcon />
          ) : name === 'WARNING' ? (
            <WarningIcon />
          ) : name === 'SKIPPED' ? (
            <SkippedIcon />
          ) : (
            <FlakyIcon />
          )}
        </div>
      </div>
      <div className={cx('fontPoppinsRegularXs', styles['textContent'])}>{content}</div>
    </section>
  );
};
export default TestScriptDetails;

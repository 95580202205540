import React, { useEffect, useState, Fragment } from 'react';
import { Route, Switch, useHistory, withRouter } from 'react-router';
import PageChild from './child_page';
import ElementDetailsPage from './element_details_page';
import History from '../../shared/history/history';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function ProjectElementRouter(props) {
  const { history, location } = props;
  let historyLocation = history.location.pathname;
  let path;
  let index = historyLocation.lastIndexOf('/');
  path = historyLocation.slice(0, index);
  let count = path.split('/').length;

  let activeTab;
  const search = window.location.search;
  const id = new URLSearchParams(search).get('id');
  const eleId = new URLSearchParams(search).get('eleId');
  const pageId = new URLSearchParams(search).get('pageId');
  const projectType = new URLSearchParams(search).get('projectType');
  const platForm = new URLSearchParams(search).get('platForm');
  const isSharedEle = new URLSearchParams(search).get('isShared');
  if (pageId) {
    activeTab = history.location.pathname.split('/')[count];
  } else {
    activeTab = history.location.pathname.split('/')[count];
  }
  useEffect(() => {
    if (pageId) {
      setTabsList([
        {
          name: 'Element',
          logo: '/assets/images/project_rec_logo.svg',
          selectedLogo: '/assets/images/project_rec_logo_blue.svg',
          pathUrl: `${path}/Element?pageId=${pageId}&eleId=${eleId}&isShared=${isSharedEle ? isSharedEle : 'n'}`,
          key: 'element-tab',
          selectedName: 'Element',
        },
        // {
        //   name: "History",
        //   logo: "/assets/images/user_logo.svg",
        //   selectedLogo: "/assets/images/history_logo_blue.svg",
        //   pathUrl: `${path}/history?pageId=${pageId}&eleId=${eleId}&isShared=${isSharedEle?isSharedEle:"n"}`,
        //   key: "history-tab",
        //   selectedName: "history",
        // },
      ]);
    } else {
      setTabsList([
        {
          name: `${
            projectType === 'Web'
              ? 'Page'
              : ['web_mobile', 'Salesforce', 'MS Dynamics']?.includes(projectType) && platForm === 'Web'
                ? 'Page'
                : ['web_mobile', 'Salesforce', 'MS Dynamics']?.includes(projectType) &&
                    (platForm === 'Android' || platForm === 'iOS' || platForm === 'Ios' || platForm === 'MobileWeb')
                  ? 'Screen'
                  : 'Screen'
          }`,
          logo: '/assets/images/project_rec_logo.svg',
          selectedLogo: '/assets/images/project_rec_logo_blue.svg',
          pathUrl: `${path}/${
            projectType === 'Web'
              ? 'Page'
              : ['web_mobile', 'Salesforce', 'MS Dynamics']?.includes(projectType) && platForm === 'Web'
                ? 'Page'
                : ['web_mobile', 'Salesforce', 'MS Dynamics']?.includes(projectType) &&
                    (platForm === 'Android' || platForm === 'iOS' || platForm === 'Ios' || platForm === 'MobileWeb')
                  ? 'Screen'
                  : 'Screen'
          }?id=${id}&type=page&projectType=${projectType}&platForm=${platForm}`,
          key: 'page-tab',
          selectedName: `${
            projectType === 'Web'
              ? 'Page'
              : ['web_mobile', 'Salesforce', 'MS Dynamics']?.includes(projectType) && platForm === 'Web'
                ? 'Page'
                : ['web_mobile', 'Salesforce', 'MS Dynamics']?.includes(projectType) &&
                    (platForm === 'Android' || platForm === 'iOS' || platForm === 'Ios' || platForm === 'MobileWeb')
                  ? 'Screen'
                  : 'Screen'
          }`,
        },
        // {
        //   name: "History",
        //   logo: "/assets/images/user_logo.svg",
        //   selectedLogo: "/assets/images/history_logo_blue.svg",
        //   pathUrl: `${path}/history?id=${id}&type=page&projectType=${projectType}&platForm=${platForm}`,
        //   key: "history-tab",
        //   selectedName: "history",
        // },
      ]);
    }
  }, [pageId]);
  const [tabsList, setTabsList] = useState([
    {
      name: `${
        projectType === 'Web'
          ? 'Page'
          : ['web_mobile', 'Salesforce', 'MS Dynamics']?.includes(projectType) && platForm === 'Web'
            ? 'Page'
            : ['web_mobile', 'Salesforce', 'MS Dynamics']?.includes(projectType) &&
                (platForm === 'Android' || platForm === 'iOS' || platForm === 'Ios' || platForm === 'MobileWeb')
              ? 'Screen'
              : 'Screen'
      }`,
      logo: '/assets/images/project_rec_logo.svg',
      selectedLogo: '/assets/images/project_rec_logo_blue.svg',
      pathUrl: `${path}/${
        projectType === 'Web'
          ? 'Page'
          : ['web_mobile', 'Salesforce', 'MS Dynamics']?.includes(projectType) && platForm === 'Web'
            ? 'Page'
            : ['web_mobile', 'Salesforce', 'MS Dynamics']?.includes(projectType) &&
                (platForm === 'Android' || platForm === 'iOS' || platForm === 'Ios' || platForm === 'MobileWeb')
              ? 'Screen'
              : 'Screen'
      }?id=${id}&type=page&projectType=${projectType}&platForm=${platForm}`,
      key: 'page-tab',
      selectedName: `${
        projectType === 'Web'
          ? 'Page'
          : ['web_mobile', 'Salesforce', 'MS Dynamics']?.includes(projectType) && platForm === 'Web'
            ? 'Page'
            : ['web_mobile', 'Salesforce', 'MS Dynamics']?.includes(projectType) &&
                (platForm === 'Android' || platForm === 'iOS' || platForm === 'Ios' || platForm === 'MobileWeb')
              ? 'Screen'
              : 'Screen'
      }`,
    },
    // {
    //   name: "History",
    //   logo: "/assets/images/user_logo.svg",
    //   selectedLogo: "/assets/images/history_logo_blue.svg",
    //   pathUrl: `${path}/history?id=${id}&type=page&projectType=${projectType}&platForm=${platForm}`,
    //   key: "history-tab",
    //   selectedName: "history",
    // },
  ]);
  let renderTabs = () => {
    return (
      <div>
        <ul className="navBar text-blue-600 flex flex-row primary-tab">
          {tabsList.map((tab, index) => {
            return (
              <li>
                <a
                  href="#"
                  key={tab.key}
                  onClick={(event) => {
                    event.preventDefault();
                    props.history.push(tab.pathUrl);
                  }}
                  className={`${activeTab === tab.selectedName ? 'selected_tab_style' : ''}`}
                >
                  {tab.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };
  return (
    <div className="page-list-body">
      <div className="page-tab-with-actions">
        {renderTabs()}
        {/* <Menu as="div" className="ml-auto">
          {({ open }) => (
            <>
              <div>
                <Menu.Button className="bg-gray-100  rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                  <span className="sr-only">Open options</span>
                  <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          Help
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu> */}
      </div>
      {pageId ? (
        <Switch>
          <Route
            path={`${path}/Element`}
            exact={true}
            strict={true}
            key="project-elem-detail-page"
            component={ElementDetailsPage}
          ></Route>
          <Route
            path={`${path}/history`}
            exact={true}
            strict={true}
            key="project-elem-history-page"
            component={History}
          ></Route>
        </Switch>
      ) : (
        <Switch>
          <Route
            path={`${path}/${
              projectType === 'Web'
                ? 'Page'
                : ['web_mobile', 'Salesforce', 'MS Dynamics']?.includes(projectType) && platForm === 'Web'
                  ? 'Page'
                  : ['web_mobile', 'Salesforce', 'MS Dynamics']?.includes(projectType) &&
                      (platForm === 'Android' || platForm === 'iOS' || platForm === 'Ios' || platForm === 'MobileWeb')
                    ? 'Screen'
                    : 'Screen'
            }`}
            exact={true}
            strict={true}
            key="element-page"
          >
            <PageChild MyAlert={props.MyAlert} platForm={platForm} />
          </Route>
          <Route path={`${path}/history`} exact={true} strict={true} key="history-page" component={History}></Route>
        </Switch>
      )}
    </div>
  );
}

export default withRouter(ProjectElementRouter);

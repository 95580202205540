import { encodeSpecialCharater } from '@src/pages/test-development/shared/common-methods';
import { isWebServiceRequired } from '@src/util/common_utils';

export const scrollIntoView = () => {
  const element = document.getElementsByClassName('react-dropdown-select-dropdown');
  if (element) {
    element[0].scrollIntoView();
  }
};

export const containerNavigation = (keyArray, nameArray, rootName) => {
  let parentSonJson = [];
  nameArray[0] = rootName ? rootName : 'Root Module';
  keyArray.forEach((key, index) => {
    parentSonJson.push({
      Name: nameArray[index],
      id: key,
    });
  });
  return { json: parentSonJson };
};

export const pageElementNavigation = (keyArray, nameArray, projectTypeName, platform) => {
  let parentSonJson = [];
  keyArray.forEach((key, index) => {
    let parentId = '';
    if (key.includes('ELE') || key.includes('SH')) {
      parentId = keyArray[index - 1];
    }
    parentSonJson.push({
      Name: nameArray[index],
      id: key,
      parentId: parentId,
      type: 'Page',
      ProjectType: projectTypeName,
      PlatForm: platform,
    });
  });
  return { json: parentSonJson };
};

export const packageElementNavigation = (keyArray, nameArray, elementParentId) => {
  let parentSonJson = [];
  keyArray.forEach((key, index) => {
    let parentId = '';
    if (key.includes('PRG_ELE') || key.includes('STP_GRP')) {
      parentId = elementParentId;
    }
    parentSonJson.push({
      Name: nameArray[index],
      id: key,
      parentId: parentId,
    });
  });
  return { json: parentSonJson };
};

export const getUrlPath = (json) => {
  let urlPath = '';
  for (const entry of json) {
    urlPath += `${encodeSpecialCharater(entry?.Name ? entry?.Name : entry?.name)}_${entry?.id ? entry?.id : entry.key}/`;
  }
  return urlPath;
};

export const resourceNavigation = ({ searchKeyArray, nameArray, rootName, isTestDev }) => {
  let parentSonJson = [];
  nameArray[0] = rootName ? rootName : 'Root Module';
  searchKeyArray.forEach((key, index) => {
    if (index === searchKeyArray.length - 1 && !isTestDev) {
      parentSonJson.push({
        Name: nameArray[index],
        id: key,
        parentId: searchKeyArray[index - 1],
      });
    } else {
      parentSonJson.push({
        Name: nameArray[index],
        id: key,
      });
    }
  });
  return { json: parentSonJson };
};

export const checkForScriptTypes = (selectedNodes) => {
  const scriptTypes = ['Web', 'Web & Mobile'];
  return selectedNodes.some((node) => node?.data?.scriptType && scriptTypes.includes(node?.data?.scriptType));
};
export const getPlusIconArray = (subfeatures, hasAutomationBasedOnProjType) => {
  const iconMappings = {
    module: { name: 'Add Module', value: 'folder' },
    automationScript: { name: 'Add Automation Script', value: 'file auto' },
    manualTestCase: { name: 'Add Manual Test Case', value: 'file manual' },
  };
  let plusIconArray = subfeatures
    .map((feature) =>
      (feature.name === 'automationScript' && hasAutomationBasedOnProjType) || feature.name !== 'automationScript'
        ? iconMappings[feature.name]
        : undefined
    )
    .filter(Boolean);
  return plusIconArray;
};

export const getScriptOptions = () => {
  const projectDetails = JSON.parse(localStorage.getItem('selected-project'));
  let arr = [];
  if (projectDetails.type === 'Web') {
    arr.push({ name: 'Web', value: 'Web' }, { name: 'Database', value: 'Database' });
  } else if (projectDetails.type === 'Mobile') {
    arr.push(
      { name: 'Android', value: 'Android' },
      { name: 'iOS', value: 'iOS' },
      { name: 'Mobile (Android & iOS)', value: 'Mobile (Android & iOS)' },
      { name: 'Mobile (Android or iOS)', value: 'Mobile (Android or iOS)' },
      { name: 'Database', value: 'Database' }
    );
  } else if (['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectDetails.type)) {
    arr.push(
      { name: 'Web', value: 'Web' },
      { name: 'Android', value: 'Android' },
      { name: 'iOS', value: 'iOS' },
      { name: 'Mobile (Android & iOS)', value: 'Mobile (Android & iOS)' },
      { name: 'Mobile (Android or iOS)', value: 'Mobile (Android or iOS)' },
      { name: 'Web & Mobile (Android & iOS)', value: 'Web & Mobile (Android & iOS)' },
      { name: 'Web & Mobile (Android or iOS)', value: 'Web & Mobile (Android or iOS)' },
      { name: 'Database', value: 'Database' }
    );
  } else {
    arr.push({ name: 'Database', value: 'Database' });
  }
  if (isWebServiceRequired(projectDetails.type)) {
    arr.push({ name: 'Webservice', value: 'Webservice' });
  }
  return arr;
};

import cx from 'classnames';
import styles from './label.module.scss';

export function Label({
  label = '',
  required = false,
  error = '',
  showLabelError = false,
  className = '',
  fontClass = 'fontPoppinsMediumSm',
  ...props
}) {
  return (
    <label
      className={cx(styles['custom_label'], fontClass, className, {
        [styles['required']]: required,
        [styles['error']]: error && showLabelError,
      })}
      {...props}
    >
      {label}
    </label>
  );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import '@pages/licenses/styles/license-alert-popup.scss';
import { ReactComponent as YellowAlert } from '@assets/svg-imports/yellow_alert.svg';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import { Tooltip } from '@mui/material';
import { LICENSE_EXPIRED_CONSTANTS, LICENSE_DETAILS_CONSTANTS } from '@src/common/ui-constants';
import { getUserName, getAdminPrivilege, getUserPrivilege, getCurrentLicenseId, encode } from '@util/common_utils';
import { getRemainingDays, showOnlyDate, getSuperAdmins, FREE_LICENSE_TYPE } from '@src/util/licenseUtils';
import { useHistory } from 'react-router-dom';

const LicenseExpAlert = ({ data, openExpAlert, handleClosExpAlert, setExpAlertLicense, onRenew }) => {
  let licenseTransactionAllowed = JSON.parse(localStorage.getItem('allowedProjects'))?.features?.licenses;
  const userName = getUserName();
  const isAdmin = getAdminPrivilege();
  const isUser = getUserPrivilege();
  const currentLicenseId = getCurrentLicenseId();
  const history = useHistory();
  let superAdminsList = getSuperAdmins(data?.licenseUsers);
  return (
    <>
      <Modal
        open={openExpAlert}
        className={'blur-background'}
        aria-labelledby="license-expired-modal-title"
        aria-describedby="license-expired-modal-description"
      >
        <Box
          sx={{ height: isUser || isAdmin ? '420px' : '330px', width: '540px' }}
          className={`${isAdmin || isUser ? ' boxModal-user' : 'boxModal'} focus:outline-none`}
        >
          <div className="colorHeaderYellow">
            <div className="successImg">
              <YellowAlert className="svgImgStyle" />
            </div>
            <div className="mt-8 pr-3">
              <p className="svgContentYellow mb-4 fontPoppinsMediumXX2 AlertHeader">
                {LICENSE_EXPIRED_CONSTANTS?.ALERT}!{' '}
                <span className="text-black fontPoppinsMediumXX2">{LICENSE_EXPIRED_CONSTANTS?.TITLE} </span>
              </p>
              {isAdmin || isUser ? (
                <div className="svgContentTwoRed fontPoppinsRegularMd pl-2">
                  {data?.licenseType !== FREE_LICENSE_TYPE ? (
                    <>
                      <p>Attention {userName}!</p>
                      {LICENSE_EXPIRED_CONSTANTS?.LICENSE_ABOUT_TO_EXPIRE}{' '}
                      {LICENSE_EXPIRED_CONSTANTS?.CONTACT_YOUR_SUPER_ADMIN_TO}{' '}
                      <span className="text-black tracking-wide fontPoppinsSemiboldMd">
                        {LICENSE_DETAILS_CONSTANTS?.RENEW}
                      </span>{' '}
                      {LICENSE_EXPIRED_CONSTANTS?.LICENSE_FOR_UNINTERRUPTED_SERVICES}
                    </>
                  ) : (
                    <>
                      <p>Attention {userName}!</p>
                      {LICENSE_EXPIRED_CONSTANTS?.FREE_TRIAL_EXPIRY_TEXT_ADMIN}{' '}
                      <span className="fontPoppinsSemiboldMd">{LICENSE_DETAILS_CONSTANTS?.UPGRADE}</span>{' '}
                      {LICENSE_EXPIRED_CONSTANTS?.LICENSE_FOR_UNINTERRUPTED_SERVICES}
                    </>
                  )}
                </div>
              ) : (
                <div className="svgContentTwoRed fontPoppinsRegularMd pl-2">
                  <p>Hi {userName}, </p>
                  {data?.licenseType !== FREE_LICENSE_TYPE
                    ? LICENSE_EXPIRED_CONSTANTS?.LICENSE_ABOUT_TO_EXPIRE
                    : LICENSE_EXPIRED_CONSTANTS?.FREE_TRIAL_ABOUT_TO_EXPIRE}
                  <span className="mx-1 tracking-wide fontPoppinsSemiboldMd">
                    {data?.licenseType !== FREE_LICENSE_TYPE
                      ? LICENSE_DETAILS_CONSTANTS?.RENEW
                      : LICENSE_DETAILS_CONSTANTS?.UPGRADE}
                  </span>{' '}
                  {LICENSE_EXPIRED_CONSTANTS?.ENJOY_UNINTERRUPTED_SERVICE}
                </div>
              )}
            </div>
          </div>
          <div className="px-2">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container columns={16} className="my-2 ml-20">
                <Grid item xs={6}>
                  <p className="ml-8 text-black  fontPoppinsRegularSm">{LICENSE_DETAILS_CONSTANTS?.LICENSE_NAME} :</p>
                </Grid>
                <Grid item>
                  <Tooltip title={data.licenseName} placement="top">
                    <p className="text-black  tracking-wide font-semibold fontPoppinsSemiboldSm">
                      {data && data?.licenseName?.length > 10
                        ? data.licenseName.substring(0, 14) + '...'
                        : data.licenseName}
                    </p>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container columns={16} className="my-2 ml-20">
                <Grid item xs={6}>
                  <p className="ml-8 text-black fontPoppinsRegularSm">{LICENSE_DETAILS_CONSTANTS?.PURCHASED_ON} :</p>
                </Grid>
                <Grid item>
                  <p className="text-black fontPoppinsSemiboldSm">
                    {showOnlyDate(data?.startDate||data?.createdOn)}
                  </p>
                </Grid>
              </Grid>
              <Grid container columns={16} className="my-2 ml-20">
                <Grid item xs={6}>
                  <p className="ml-8 text-black fontPoppinsRegularSm">{LICENSE_DETAILS_CONSTANTS?.EXPIRING_ON} :</p>
                </Grid>
                <Grid item>
                  <p className="text-black fontPoppinsSemiboldSm">{showOnlyDate(data?.expiryDate)}</p>
                </Grid>
              </Grid>
            </Box>
          </div>
          <div className={isAdmin || isUser ? 'renew-dashed-border-admin-user' : 'renew-dashed-border-super-admin'}>
            {data?.remainingDays > 0 ? (
              <p className="fontPoppinsSemiboldSm text-black my-4">
                {LICENSE_DETAILS_CONSTANTS?.SUBSCRIPTION_EXPIRES_IN}{' '}
                <span className="license-warning-color">
                  {data?.remainingDays} {getRemainingDays(data?.remainingDays)}
                </span>
              </p>
            ) : (
              <p className="fontPoppinsSemiboldSm license-warning-color">
                {LICENSE_DETAILS_CONSTANTS?.SUBSCRIPTION_EXPIRING_TODAY}
              </p>
            )}
          </div>
          {isAdmin || isUser ? (
            <div className="warning-user-content">
              <p className="fontPoppinsRegularSm text-black">
                {LICENSE_EXPIRED_CONSTANTS?.FOR_SUPPORT_CONTACT_SUPER_ADMINS}
              </p>
              {superAdminsList?.map((user) => {
                return (
                  <p key={user?.userId} className="fontPoppinsSemiboldSm primary-color">
                    {user?.emailId}
                  </p>
                );
              })}
            </div>
          ) : null}
          {isUser || isAdmin ? (
            <div className="footerNewLic flex ">
              <div className="w-9/12 flex flex-col mt-4 mx-2 "></div>
              <div className="w-3/12 align-middle">
                <button
                  className="secondaryBtnUser -ml-2.5 fontPoppinsMediumXs"
                  onClick={() => {
                    setExpAlertLicense(false);
                  }}
                >
                  {LICENSE_DETAILS_CONSTANTS?.REMIND_ME_LATER}
                </button>
              </div>
            </div>
          ) : (
            <div className="footerNewLic flex ">
              <button
                className="secondaryBtnUser fontMontserratMediumXs"
                onClick={() => {
                  setExpAlertLicense(false);
                }}
              >
                {LICENSE_DETAILS_CONSTANTS?.REMIND_ME_LATER}
              </button>
              {licenseTransactionAllowed && (
                <>
                  {data?.licenseType === FREE_LICENSE_TYPE ? (
                    <button
                      className="primaryBtnStyleForRed opacity-90 hover:opacity-100 fontMontserratMediumXs"
                      onClick={() => {
                        setExpAlertLicense(false);
                        history.push(`/licenses/${encode(currentLicenseId)}/FireFlink License/upgrade`);
                      }}
                    >
                      {LICENSE_DETAILS_CONSTANTS?.UPGRADE}
                    </button>
                  ) : (
                    <button
                      className="primaryBtnStyleForRed opacity-90 hover:opacity-100 fontMontserratMediumXs"
                      onClick={() => {
                        setExpAlertLicense(false);
                        history.push(`/licenses/${encode(currentLicenseId)}/FireFlink License/renew`);
                      }}
                    >
                      {LICENSE_DETAILS_CONSTANTS?.RENEW}
                    </button>
                  )}
                </>
              )}
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
};

LicenseExpAlert.propTypes = {
  openExpAlert: PropTypes.bool,
  handleClosExpAlert: PropTypes.func,
  data: PropTypes.object,
  setExpAlertLicense: PropTypes.func,
  onRenew: PropTypes.func.isRequired,
};
export default LicenseExpAlert;

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { ReactComponent as YellowAlert } from '@assets/svg-imports/Icon-warning.svg';
import { deleteSlackInstance } from '@api/api_services';
import { GITHUB_CONSTANTS } from '../plugin_constants';

function DeleteInstanceSlack(props) {
  const deleteSlack = async () => {
    try {
      const response = await deleteSlackInstance(props?.seletedInstance?.instanceName, props?.seletedInstance?.id);
      if (response?.data?.responseCode == 200) {
        props.closeModal();
        props.reloadTree();
        props.MyAlert.success(`${props?.seletedInstance?.instanceName} ${response?.data?.message}`);
      } else {
        props.MyAlert.warning(response?.data?.message);
      }
    } catch (err) {
      console.error('Api error ', err);
    }
  };
  return (
    <>
      <Modal
        open={props.openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="focus:outline-none outline-none"
      >
        <Box className="deleteModal focus:outline-none outline-none">
          <div className="headerdelete">
            <div className="successImg">
              <YellowAlert className="svgImgWarning" />
            </div>
            <div className="mt-5 ">
              <div className="flex flex-row">
                <p className="svgYellow fontPoppinsMediumXX2 ml-5">{GITHUB_CONSTANTS.WARNING}</p>
                <p className="fontPoppinsMediumXX2 ml-2 delete-content">{GITHUB_CONSTANTS.DELETE}</p>
              </div>
              <div className="fontPoppinsRegularLg ml-6 mt-4  flex flex-col">
                <span className=" fontPoppinsRegularMd delete-content">
                  {GITHUB_CONSTANTS.DATA_LOST}
                  <br />
                  <span className="fontPoppinsSemiboldMd delete-content">
                    {props?.seletedInstance?.instanceName}
                  </span>{' '}
                  {GITHUB_CONSTANTS.INSTANCE} ?
                </span>
                <span className="mt-4 fontPoppinsRegularMd delete-content">{GITHUB_CONSTANTS.PROCEED}</span>
              </div>
            </div>
          </div>
          <div className="delete-footer flex -mx-1">
            <button
              className="button-cancel success-instance cursor-pointer fontMontserratMediumXs"
              onClick={props.closeModal}
            >
              {GITHUB_CONSTANTS.CANCEL}
            </button>
            <button
              className="delete-button cancel-instance  cursor-pointer fontMontserratMediumXs"
              onClick={deleteSlack}
            >
              {GITHUB_CONSTANTS.DELETE}
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default DeleteInstanceSlack;

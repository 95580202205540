import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useMemo } from 'react';
import Modal from '@mui/material/Modal';
import '@pages/licenses/styles/license-alert-popup.scss';
import { ReactComponent as BlueShield } from '@assets/svg-imports/blue_shield.svg';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import { MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LICENSE_PERIOD_STATUS, getLicensePeriodStatus, getRemainingDays, showOnlyDate } from '@util/licenseUtils';
import { LICENSE_EXPIRED_CONSTANTS, LICENSE_DETAILS_CONSTANTS, MEMORYCONFIG_CONSTANTS } from '@src/common/ui-constants';
import SwitchLicensePopup from '@pages/licenses/components/SwitchLicensePopup';
import { colors } from '@src/css_config/colorConstants.js';
import { useHistory } from 'react-router';
import { encode, getCurrentLicenseId } from '@src/util/common_utils';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FREE_LICENSE_TYPE } from '@src/util/licenseUtils';
import cx from 'classnames';
import { Button } from 'fireflink-ui';

const SelectLicense = ({
  openSelectLicense,
  handleCloseSelectLicense,
  dataValue,
  handleClose,
  onRenew,
  initialLicenseDetails,
  licenses,
  licenseTransactionAllowed,
}) => {
  const [selectedLicense, setSelectedLicense] = useState(null);
  const handleChange = (event) => {
    setSelectedLicense(event.target.value);
  };
  const [isExpired, setIsExpired] = useState(false);
  const [openSwitchPopup, setOpenSwitchPopup] = useState(false);
  const [previousLicense, setPreviousLicense] = useState(null);
  const userName = JSON.parse(localStorage.getItem('test-optimize-user') || '').name;
  const userPrivilege = JSON.parse(localStorage.getItem('test-optimize-user') || '').privilege;

  const useStyles = makeStyles((theme) => ({
    select: {
      width: userPrivilege === 'Super Admin' ? '230px' : '256px',
      padding: ' 8px 0px 8px 8px',
      border: `1px solid ${colors.rs_border}`,
      borderRadius: 2,
      '&:focus': {
        borderRadius: 2,
      },
    },
    menuPaper: {
      maxHeight: 200,
      width: userPrivilege === 'Super Admin' ? '230px' : '256px',
      background: `${colors.ff_cream} 0% 0% no-repeat padding-box`,
      boxShadow: `0px 0px 6px ${colors.shadow_black}`,
      border: `0.5px solid ${colors.ff_light_blue}`,
      borderRadius: '5px',
    },
    menulist: {
      paddingTop: 15,
      paddingBottom: 15,
      background: 'none',
      height: 110,
      '& li': {
        color: colors.grey_dark,
        background: colors.ff_cream,
      },
      '& li:hover': {
        background: colors.rs_primary,
        color: colors.text_white,
      },
      '& li.Mui-selected': {
        color: colors.text_white,
        background: colors.rs_primary,
      },
      '& li.Mui-selected:hover': {
        color: colors.grey_dark,
        background: colors.ff_cream,
      },
    },
  }));
  const classes = useStyles();
  let history = useHistory();
  const currentLicenseId = getCurrentLicenseId();

  useEffect(() => {
    const licensePeriodStatus = getLicensePeriodStatus(selectedLicense);
    setIsExpired(licensePeriodStatus === LICENSE_PERIOD_STATUS.EXPIRED);
  }, [selectedLicense]);

  useEffect(() => {
    initialLicenseDetails && setSelectedLicense(initialLicenseDetails);
  }, [initialLicenseDetails]);

  const { userDetails } = useMemo(() => {
    const user = JSON.parse(localStorage.getItem('test-optimize-user') || '{}');
    return {
      userDetails: dataValue.find(({ id }) => user.licenseId === id) || {},
    };
  }, [dataValue]);

  const closePopup = () => {
    handleCloseSelectLicense(false);
    handleClose(false);
  };

  function handleSelectLicense(license) {
    const currentLicense = selectedLicense;
    setPreviousLicense(currentLicense);
    setSelectedLicense(license);
    setOpenSwitchPopup(true);
  }

  function handleSwitchLicensePopupClose(isChanged = false) {
    setOpenSwitchPopup(false);
    if (!isChanged) {
      const license = previousLicense;
      setSelectedLicense(license);
      setPreviousLicense(null);
    }
  }

  const licensePeriodStatus = useMemo(() => getLicensePeriodStatus(selectedLicense), [selectedLicense]);
  const willExpire = licensePeriodStatus === LICENSE_PERIOD_STATUS.WILL_EXPIRE;

  useEffect(() => {
    setOpenSwitchPopup(false);
  }, [history]);

  return (
    <>
      <Modal
        open={openSelectLicense}
        aria-labelledby="select-license-modal-title"
        aria-describedby="select-license-modal-description"
        BackdropProps={{
          style: {
            backdropFilter: !openSwitchPopup ? 'blur(8px)' : 'blur(0px)',
          },
        }}
      >
        <Box
          sx={{ height: isExpired ? '353px' : willExpire ? '305px' : '272px', width: '540px' }}
          className="boxModal-select-user focus:outline-none"
        >
          <SwitchLicensePopup
            open={openSwitchPopup}
            onClose={handleSwitchLicensePopupClose}
            licenseId={selectedLicense?.id}
            licenses={licenses}
          />
          <div className="colorHeaderBlue">
            <div className="">
              <BlueShield className="w-16 h-16 ml-5 mt-4" />
            </div>
            <div className="mt-4">
              <p className="svgContentBlue fontPoppinsMediumXX2">{LICENSE_DETAILS_CONSTANTS?.SELECT_LICENSE}</p>
              <p className="ml-6 fontPoppinsRegularMd">Hi {userName} ,</p>
              <p className="ml-6 fontPoppinsRegularMd">{LICENSE_EXPIRED_CONSTANTS?.TO_CONTINUE_USING}</p>
            </div>
          </div>
          <div className="ml-20">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container columns={16} className="my-2">
                <Grid item xs={5}>
                  <p className="ml-7 fontPoppinsRegularSm">{LICENSE_DETAILS_CONSTANTS?.LICENSE_NAME} :</p>
                </Grid>
                <Grid item className="flex">
                  <FormControl
                    variant="outlined"
                    className={cx(
                      userPrivilege === 'Super Admin' ? 'select-field-super-admin' : 'select-field-user-admin'
                    )}
                  >
                    <Select
                      className="fontPoppinsRegularSm"
                      classes={{ root: classes.select }}
                      id="selectBox"
                      error={isExpired}
                      value={selectedLicense}
                      onChange={handleChange}
                      renderValue={(selected) => selected?.licenseName.slice(0, 24) + '...'}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        classes: {
                          paper: classes.menuPaper,
                          list: classes.menulist,
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {dataValue.map((option, optionIndex) => (
                        <MenuItem
                          id="sideLeft"
                          key={`labeled-select-${option.licenseName}-${optionIndex}`}
                          value={option}
                          defaultValue={option}
                        >
                          <span className="fontPoppinsSemiboldSm"> {option.licenseName}</span>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {isExpired &&
                    userPrivilege === 'Super Admin' &&
                    licenseTransactionAllowed &&
                    (selectedLicense?.licenseType === 'C-Professional' ? (
                      <button
                        className="renew-btn ml-6 fontMontserratMediumXs"
                        onClick={() => {
                          closePopup();
                          history.push(`/licenses/${encode(currentLicenseId)}/FireFlink License/renew`);
                        }}
                      >
                        {LICENSE_DETAILS_CONSTANTS?.RENEW}
                      </button>
                    ) : (
                      <button
                        className="renew-btn ml-6 fontMontserratMediumXs"
                        onClick={() => {
                          closePopup();
                          history.push(`/licenses/${encode(currentLicenseId)}/FireFlink License/upgrade`);
                        }}
                      >
                        {LICENSE_DETAILS_CONSTANTS?.UPGRADE}
                      </button>
                    ))}
                </Grid>
                <Grid className="select-Validation">
                  {isExpired && (
                    <FormHelperText error className="fontPoppinsRegularSm">
                      <p className="">{LICENSE_EXPIRED_CONSTANTS?.THIS_LICENSE_HAS_EXPIRED}</p>
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid container columns={16} className="my-2">
                <Grid item xs={5}>
                  <p className="ml-8 fontPoppinsRegularSm">{LICENSE_DETAILS_CONSTANTS?.PURCHASED_ON} :</p>
                </Grid>
                <Grid item>
                  <p className="text-black fontPoppinsSemiboldSm">
                    {selectedLicense && selectedLicense.licenseType === FREE_LICENSE_TYPE
                      ? showOnlyDate(selectedLicense.createdOn)
                      : selectedLicense && selectedLicense.licenseType === 'C-Professional'
                      ? showOnlyDate(selectedLicense.startDate)
                      : 'NA'}
                  </p>
                </Grid>
              </Grid>
              <Grid container columns={16} className="my-2">
                <Grid item xs={5}>
                  <p className="ml-8 fontPoppinsRegularSm">{LICENSE_DETAILS_CONSTANTS?.EXPIRING_ON} :</p>
                </Grid>
                <Grid item>
                  <p className="text-black fontPoppinsSemiboldSm">{showOnlyDate(selectedLicense?.expiryDate)}</p>
                </Grid>
              </Grid>
            </Box>
          </div>
          {selectedLicense?.licenseStatus === 'EXPIRED' && (
            <div className="border-t-2 border-dashed p-4 pl-28 flex items-center">
              {
                <div className="fontPoppinsSemiboldSm">
                  {LICENSE_EXPIRED_CONSTANTS?.SUBSCRIPTION_EXPIRED_IF_NOT_RENEWED(
                    selectedLicense?.licenseType === 'C-Professional' ? 'renewed' : 'upgraded'
                  )}{' '}
                  <span className="expired-text">
                    {selectedLicense?.willBeDeletedIn}&nbsp;{getRemainingDays(selectedLicense?.willBeDeletedIn)}
                  </span>
                  ,<br />
                  {LICENSE_EXPIRED_CONSTANTS?.YOUR_LICENSE_WILL_BE_DELETED}
                </div>
              }
            </div>
          )}
          {willExpire && (
            <>
              <div className="border-t-2 border-dashed pl-28 pt-3 fontPoppinsSemiboldSm">
                {LICENSE_DETAILS_CONSTANTS?.SUBSCRIPTION_EXPIRING_IN}{' '}
                <span className="expiring-text">
                  {selectedLicense?.remainingDays} {getRemainingDays(selectedLicense?.remainingDays)}
                </span>
              </div>
            </>
          )}

          <div className="box-footer">
            <Button
              label="Button"
              variant="secondary"
              transparentBackground
              onClick={() => {
                handleCloseSelectLicense(false);
              }}
            >
              {MEMORYCONFIG_CONSTANTS?.CANCEL}
            </Button>
            <Button
              label="Button"
              variant="primary"
              onClick={() => {
                handleSelectLicense(selectedLicense);
              }}
            >
              {LICENSE_DETAILS_CONSTANTS?.CONTINUE}
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};
SelectLicense.propTypes = {
  openSelectLicense: PropTypes.bool,
  handleCloseSelectLicense: PropTypes.func,
  data: PropTypes.object,
  handleClose: PropTypes.func,
  onRenew: PropTypes.func.isRequired,
  initialLicenseDetails: PropTypes.object,
};
export default SelectLicense;

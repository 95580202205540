import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { trimPath } from '../../../common/ui-constants';
const Details_modal = (props) => {
  let count;
  let project = JSON.parse(localStorage.getItem('selected-project'));

  if (props.containerName === 'Page') {
    count = props.data.elementCount;
  } else if (props.containerName === 'Package') {
    count = props.data.programElementCount;
  } else if (props.containerName === 'Library') {
    count = props.data.stepGroupCount;
  }
  let TotalCount;
  if (props.containerName === 'Page') {
    TotalCount = props.data.subPageCount;
  } else if (props.containerName === 'Package') {
    TotalCount = props.data.subPackageCount;
  } else if (props.containerName === 'Library') {
    TotalCount = props.data.subLibraryCount;
  }
  let containerName;
  if (props.containerName === 'Library') {
    containerName = 'Libraries';
  } else {
    containerName = props.containerName.concat('s');
  }
  let path = trimPath(props.data.path);
  const [details, setDetails] = useState(true);

  return (
    <Modal
      open={details}
      onClose={() => {
        setDetails(false);
        props.closeModal(false);
      }}
      className="modal-dialog"
    >
      <div className="modal-container details-modal">
        <div className="modal-header">
          <div className="fontPoppinsMediumLg title-trim">
            {props.data.folder === true
              ? props.containerName === 'Page'
                ? (props.projectType === 'Mobile'
                    ? 'Screen'
                    : props.projectType === 'Web'
                    ? 'Page'
                    : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) &&
                      props.platForm === 'Web'
                    ? 'Page'
                    : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) &&
                      ['Android', 'iOS', 'Ios', 'MobileWeb', 'Mobile'].includes(props.platForm)
                    ? 'Screen'
                    : 'Page') + ' Details -'
                : props.containerName + ' Details -'
              : props.containerName === 'Library'
              ? 'Step Group Details - '
              : 'Program Elements Details - '}
            <div className="inline" title={props.data.name}>
              {' '}
              {props.data.name === 'Root'
                ? props.data.name.concat(
                    '' + props.containerName === 'Page'
                      ? props.projectType === 'Mobile'
                        ? ' Screen'
                        : props.projectType === 'Web'
                        ? ' Page'
                        : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) &&
                          props.platForm === 'Web'
                        ? ' Page'
                        : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) &&
                          ['Android', 'iOS', 'Ios', 'MobileWeb', 'Mobile'].includes(props.platForm)
                        ? ' Screen'
                        : ' Page'
                      : containerName
                  )
                : props.data.name}
            </div>
          </div>
          <IconButton
            color="primary"
            component="span"
            className="close"
            onClick={() => {
              props.closeModal(false);
              setDetails(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="modal-body" id="journal-scroll">
          {props.data.folder === true ? (
            <div className="">
              <div class="grid grid-cols-2 gap-4 ml-4">
                <div class="">
                  <div className="grid grid-rows-3">
                    <div className="">
                      <div className="">
                        <h1 className="details-key-style-common">Name</h1>
                        <p className="pt-2 text-gray-700">
                          <div
                            className="details-data-style-common name-trimmer"
                            title={
                              props.data.name === 'Root'
                                ? props.data.name.concat(
                                    '' + props.containerName === 'Page'
                                      ? props.projectType === 'Mobile'
                                        ? ' Screen'
                                        : props.projectType === 'Web'
                                        ? ' Page'
                                        : props.projectType === 'Web & Mobile' && props.platForm === 'Web'
                                        ? ' Page'
                                        : props.projectType === 'Web & Mobile' &&
                                          ['Android', 'iOS', 'Ios', 'MobileWeb', 'Mobile'].includes(props.platForm)
                                        ? ' Screen'
                                        : ' Page'
                                      : containerName
                                  )
                                : props.data.name
                            }
                          >
                            {props.data.name === 'Root'
                              ? props.data.name.concat(
                                  '' + props.containerName === 'Page'
                                    ? props.projectType === 'Mobile'
                                      ? ' Screen'
                                      : props.projectType === 'Web'
                                      ? ' Page'
                                      : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) &&
                                        props.platForm === 'Web'
                                      ? ' Page'
                                      : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) &&
                                        ['Android', 'iOS', 'Ios', 'MobileWeb', 'Mobile'].includes(props.platForm)
                                      ? ' Screen'
                                      : ' Page'
                                    : containerName
                                )
                              : props.data.name}
                          </div>
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <div className="mt-5">
                        <h1 className="details-key-style-common">Project Name</h1>
                        <p className="pt-2 text-gray-700">
                          <div className="details-data-style-common project-name-trimmer" title={project.name}>
                            {project.name}
                          </div>
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <div className="mt-5">
                        <h1 className="details-key-style-common">
                          Total{' '}
                          {props.containerName === 'Page'
                            ? props.projectType === 'Mobile'
                              ? 'Screens'
                              : props.projectType === 'Web'
                              ? 'Pages'
                              : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) &&
                                props.platForm === 'Web'
                              ? 'Pages'
                              : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) &&
                                ['Android', 'iOS', 'Ios', 'MobileWeb', 'Mobile'].includes(props.platForm)
                              ? 'Screens'
                              : 'Pages'
                            : containerName}
                        </h1>
                        <p className="pt-2 text-gray-700">
                          <span className="details-data-style-common ">{TotalCount ? TotalCount : '0'}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="">
                  <div className="grid grid-rows-3 ml-10">
                    <div className="">
                      <div className="">
                        <h1 className="details-key-style-common">Description</h1>
                        <p className="pt-2 text-gray-700">
                          <div
                            className="details-data-style-common desc"
                            title={props.data.name === 'Root' ? '--' : props.data.desc ? props.data.desc : '--'}
                          >
                            {props.data.name === 'Root' ? '--' : props.data.desc ? props.data.desc : '--'}
                          </div>
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <div className="mt-5">
                        <h1 className="details-key-style-common">Path</h1>
                        <p className="pt-2 text-gray-700">
                          <div
                            className="details-data-style-common path-trimmer"
                            title={
                              props.data.name === 'Root'
                                ? props.data.name.concat(
                                    '' + props.containerName === 'Page'
                                      ? props.projectType === 'Mobile'
                                        ? ' Screen'
                                        : props.projectType === 'Web'
                                        ? ' Page'
                                        : props.projectType === 'Web & Mobile' && props.platForm === 'Web'
                                        ? ' Page'
                                        : props.projectType === 'Web & Mobile' &&
                                          ['Android', 'iOS', 'Ios', 'MobileWeb', 'Mobile'].includes(props.platForm)
                                        ? ' Screen'
                                        : ' Page'
                                      : containerName
                                  )
                                : path
                            }
                          >
                            {props.data.name === 'Root'
                              ? props.data.name.concat(
                                  '' + props.containerName === 'Page'
                                    ? props.projectType === 'Mobile'
                                      ? ' Screen'
                                      : props.projectType === 'Web'
                                      ? ' Page'
                                      : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) &&
                                        props.platForm === 'Web'
                                      ? ' Page'
                                      : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) &&
                                        ['Android', 'iOS', 'Ios', 'MobileWeb', 'Mobile'].includes(props.platForm)
                                      ? ' Screen'
                                      : ' Page'
                                    : containerName
                                )
                              : path}
                          </div>
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <div className="mt-5">
                        {props.containerName.toLowerCase() === 'page' ? (
                          <h1 className="details-key-style-common">Total Elements</h1>
                        ) : props.containerName.toLowerCase() === 'package' ? (
                          <h1 className="details-key-style-common">Total Program Elements</h1>
                        ) : (
                          <h1 className="details-key-style-common">Total Step Groups</h1>
                        )}
                        <p className="pt-2 text-gray-700">
                          <span className="details-data-style-common">{count}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-2 gap-4 ml-4">
                <div className="">
                  <div className="grid grid-rows-2 mt-8">
                    <div>
                      <div className="">
                        <h1 className="details-key-style-common">Created By</h1>
                        <p className="pt-2 text-gray-700">
                          <span className="details-data-style-common">
                            {props.data.createdByUname ? props.data.createdByUname : '--'}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="mt-5">
                        <h1 className="text-sm details-key-style-common">Modified By</h1>
                        <p className="pt-2 text-gray-700">
                          <span className="details-data-style-common">
                            {props.data.name === 'Root'
                              ? 'N/A'
                              : props.data.modifiedByUname
                                ? props.data.modifiedByUname
                                : '--'}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="grid grid-rows-2 mt-8 ml-10">
                    <div>
                      <div className="">
                        <h1 className="details-key-style-common">Created On</h1>
                        <p className="pt-2 text-gray-700">
                          <span className="details-data-style-common">
                            {props.data.createdOn ? props.data.createdOn : '--'}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="mt-5">
                        <h1 className="details-key-style-common">Modified On</h1>
                        <p className="pt-2 text-gray-700">
                          <span className="details-data-style-common">
                            {props.data.name === 'Root' ? 'N/A' : props.data.modifiedOn ? props.data.modifiedOn : '--'}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div class="grid grid-cols-2 gap-4 ml-7">
                <div className="">
                  <div className="grid grid-rows-3">
                    <div className="">
                      <div className="">
                        <h1 className="details-key-style-common">Name</h1>
                        <p className="pt-2 text-gray-700">
                          <div className="details-data-style-common name-trimmer" title={props.data.name}>
                            {props.data.name}
                          </div>
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <div className="mt-5">
                        <h1 className="details-key-style-common">Description</h1>
                        <p className="pt-2 text-gray-700">
                          <div
                            className="details-data-style-common desc"
                            title={props.data.desc ? props.data.desc : '--'}
                          >
                            {props.data.desc ? props.data.desc : '--'}
                          </div>
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <div className="mt-5">
                        {props.containerName.toLowerCase() === 'page' ? (
                          <h1 className="details-key-style-common">Total Elements</h1>
                        ) : props.containerName.toLowerCase() === 'package' ? (
                          <h1 className="details-key-style-common">Total Program Elements</h1>
                        ) : (
                          <h1 className="details-key-style-common">Total Steps</h1>
                        )}
                        <p className="pt-2 text-gray-700">
                          <span className="details-data-style-common">
                            {props.data.TotalELements
                              ? props.data.TotalELements
                              : props?.data?.steps?.length > 0
                                ? props.data.steps.length
                                : '--'}
                          </span>
                        </p>
                      </div>{' '}
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="grid grid-rows-3">
                    <div className="">
                      <div className="">
                        <h1 className="details-key-style-common">Type</h1>
                        <p className="pt-2 text-gray-700">
                          <span className="details-data-style-common">{props.data.type ? props.data.type : '--'}</span>
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <div className="mt-5">
                        <h1 className="details-key-style-common">Project Name</h1>
                        <p className="pt-2 text-gray-700">
                          <div className="details-data-style-common project-name-trimmer" title={project.name}>
                            {project.name}
                          </div>
                        </p>
                      </div>{' '}
                    </div>
                    <div className="">
                      <div className="mt-5">
                        <h1 className="details-key-style-common">Path</h1>
                        <p className="pt-2 text-gray-700">
                          <div className="details-data-style-common path-trimmer" title={path ? path : '--'}>
                            {path ? path : '--'}
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="divider mt-3" />
              <div class="grid grid-cols-2 gap-4 ml-7">
                <div className="">
                  <div className="grid grid-rows-2 mt-8">
                    <div>
                      <div className="">
                        <h1 className="details-key-style-common">Created By</h1>
                        <p className="pt-2 text-gray-700">
                          <span className="details-data-style-common">
                            {props.data.name === 'Root'
                              ? 'N/A'
                              : props.data.createdByUname
                                ? props.data.createdByUname
                                : '--'}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="mt-5">
                        <h1 className="text-sm details-key-style-common">Modified By</h1>
                        <p className="pt-2 text-gray-700">
                          <span className="details-data-style-common">
                            {props.data.name === 'Root'
                              ? 'N/A'
                              : props.data.modifiedByUname
                                ? props.data.modifiedByUname
                                : '--'}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="grid grid-rows-2 mt-8">
                    <div>
                      <div className="">
                        <h1 className="details-key-style-common">Created On</h1>
                        <p className="pt-2 text-gray-700">
                          <span className="details-data-style-common">
                            {props.data.name === 'Root' ? 'N/A' : props.data.createdOn ? props.data.createdOn : '--'}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="mt-5">
                        <h1 className="details-key-style-common">Modified On</h1>
                        <p className="pt-2 text-gray-700">
                          <span className="details-data-style-common">
                            {props.data.name === 'Root' ? 'N/A' : props.data.modifiedOn ? props.data.modifiedOn : '--'}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="modal-footer">
          <div className="">
            <button
              onClick={() => {
                setDetails(false);
                props.openDetails(false);
              }}
              type="button"
              className="gray-btn"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Details_modal;

export const ENVIRONMENT_URL_CONFIG = {
  LOCAL_HOST: 'localhost',
  DEV_URL: 'http://web.fireflink.com',
  TEST_URL: 'http://test.fireflink.com',
  PRE_PROD_URL: 'https://preprod.fireflink.com',
  PRODUCTION1_URL: 'https://prod.fireflink.com',
  PRODUCTION_URL: 'https://app.fireflink.com',
  FIREFLINK_SUBSCRIPTION_URL: 'https://fireflink.com/subscription',
  DEMO_URL: 'https://demo.fireflink.com',
  ALPHA_URL: 'https://alpha.fireflink.com',
  BETA_URL: 'https://beta.fireflink.com',
  PRE_PROD_ON_PREM_URL: 'https://preprodonprem.fireflink.com',
  DEV_WEB_SERVICE_URL: 'http://devwswb.fireflink.com',
  CONNECTURE_URL: 'http://connecture.fireflink.com',
  CB_CP: "http://37.61.218.35"
};

export default ENVIRONMENT_URL_CONFIG;

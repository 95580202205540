import React, { Fragment, useContext, useEffect, useState } from 'react';
import { DefaultAssert } from './default_assert';
import CustomAssert from './custom_assert';
import '@pages/test-development/script/scripts/webservice/restapi/request/assert/css/default_assert.scss';
import '@pages/test-development/script/scripts/webservice/restapi/css/common_Webservice.scss';
import { ASSERT_CONSTANT } from './constant';
import { PREREQUISITE_CONSTANT } from '@pages/test-development/script/scripts/webservice/restapi/request/prerequisite/jsx/constant';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircleIcon from '@mui/icons-material/Circle';
import { RestContext } from '../../../restapi';

const Assert = (props) => {
  const context = useContext(RestContext);
  let { values: { selectAssertTab } } = context;
  const {
    ENABLE_DEFAULT_ASSERT,
    DISABLE_CUSTOM_ASSERT,
    EMPTY_STRING,
    DISABLE_DEFAULT_ASSERT,
    ENABLE_CUSTOM_ASSERT,
    MAIN_SECOND_DIV_ASSERT_MAIN,
    DEFAULT_ASSERT_LABEL,
    CUSTOM_ASSERT_LABEL,
    DEFAULT_ASSERT_ID,
    CUSTOM_ASSERT_ID,
    BORDER_CUSTOM,
  } = ASSERT_CONSTANT;
  const [selectedTabDefaultAssert, setSelectedTabDefaultAssert] = useState(ENABLE_DEFAULT_ASSERT);

  const [selectedTabCustomAssert, setSelectedTabCustomAssert] = useState(DISABLE_CUSTOM_ASSERT);

  const [openValue, setOpenValue] = useState(PREREQUISITE_CONSTANT.FALSE);

  const setDefaultAssert = () => {
    setSelectedTabDefaultAssert(ENABLE_DEFAULT_ASSERT);
    setSelectedTabCustomAssert(DISABLE_CUSTOM_ASSERT);
    context.values.selectAssertTab = ENABLE_DEFAULT_ASSERT;
  };

  const setCustomAssert = () => {
    setSelectedTabDefaultAssert(DISABLE_DEFAULT_ASSERT);
    setSelectedTabCustomAssert(ENABLE_CUSTOM_ASSERT);
    context.values.selectAssertTab = ENABLE_CUSTOM_ASSERT;
  };

  useEffect(() => {
    if (selectAssertTab === ENABLE_DEFAULT_ASSERT) {
      setSelectedTabDefaultAssert(ENABLE_DEFAULT_ASSERT);
      setSelectedTabCustomAssert(DISABLE_CUSTOM_ASSERT);
    } else {
      setSelectedTabDefaultAssert(DISABLE_DEFAULT_ASSERT);
      setSelectedTabCustomAssert(ENABLE_CUSTOM_ASSERT);
    }
  }, []);

  return (
    <Fragment>
      <Modal open={openValue} className="modal-dialog">
        <div className="modal-container page-modal-size1">
          <div className="modal-header">
            <h2 className="title">{`PreRequisite`}</h2>
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                setOpenValue(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="modal-body" id="journal-scroll">
            <div
              className="console_output_container_Response fontPoppinsRegularMd overflow-y-auto shadow"
              id="journal-scroll"
            >
              {EMPTY_STRING}
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="cancel-button-common cancel-button-layout-style-project mr-3"
              onClick={() => {
                setOpenValue(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
      <div className="assert-main-div-background-color">
        <div className={`${MAIN_SECOND_DIV_ASSERT_MAIN} assert-main-second-div-padding`}>
          <div className="flex relative left-2 bottom-2 assert-tab-container-height">
            <div
              className={`assert-headers relative assert-tab-width ${
                selectedTabDefaultAssert !== 'DisableDefaultAssert' ? 'active' : 'hover-effect-response-header'
              }`}
              onClick={() => setDefaultAssert()}
              variableInput={'assert'}
            >
              <span className="absolute left-3 bottom-1">{DEFAULT_ASSERT_LABEL}</span>
              {props.checkDefaultAssertValue?.length ? <CircleIcon className="assert-circle-icon" /> : ''}
            </div>
            <div
              className={`assert-headers relative assert-tab-width ${
                selectedTabCustomAssert !== 'DisableCustomAssert' ? 'active' : 'hover-effect-response-header'
              }`}
              onClick={() => setCustomAssert()}
              variableInput={'assert'}
            >
              <span className="absolute left-3 bottom-1">{CUSTOM_ASSERT_LABEL}</span>
              {props.checkCustomAssertValue === true ? <CircleIcon className="assert-circle-icon" /> : ''}
            </div>
          </div>
        </div>
        <main className="assert-scrollbar-customize relative">
          <div id={DEFAULT_ASSERT_ID} className={`${selectedTabDefaultAssert}`}>
            <DefaultAssert {...props} />
          </div>
          <div id={CUSTOM_ASSERT_ID} className={`${selectedTabCustomAssert} ${BORDER_CUSTOM}`}>
            <CustomAssert {...props} />
          </div>
        </main>
      </div>
    </Fragment>
  );
};

export default Assert;

import React, { useEffect, useState, Fragment, useRef, useContext } from 'react';
import { useHistory } from 'react-router';
import { ChevronRightOutlined, KeyboardArrowDownOutlined } from '@material-ui/icons';
import * as yup from 'yup';
import { Formik, Form as BaseForm } from 'formik';
import { Select, FormControl, TextField } from '@material-ui/core';
import { ReactComponent as Web } from '@assets/Web_GroupNLP.svg';
import { ReactComponent as Android } from '@assets/Android_GroupNLP.svg';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { ReactComponent as Ios } from '@assets/IOS-GroupNLP.svg';
import {
  NLP_AUTO_FILLED_INPUT_EXCLUDE,
  NLP_RADIO_BUTTON,
  scriptIfFailedCheckpoints,
  stepGroupIfFailedCheckpoints,
} from '@src/common/ui-constants';
import {
  getAllLocalVariablesReq,
  updateScriptReq,
  gellAllParameter,
  getAllGlobalVariableListReq,
  getAllProjectVariableListReq,
  getAllStepGroupVariable,
  updateStepGroupReq,
  getStepGroupReq,
} from '@src/api/api_services';
import { getUrlPath, resourceNavigation } from '@pages/test-development/script/modules/module/utils/common-functions';
import { getPagesName, getStepId, getUniqueId } from '@pages/test-development/shared/common-methods';
import ElementSuggestion from './ElementSuggestion';
import VariableSuggestion from './VariableSuggestion';
import DataproviderSuggestion from './dataprovider-suggestion';
import { isEmptyValue } from '@src/util/common_utils';
import { ReactComponent as AppleIcon } from '@assets/apple_icon.svg';
import { ReactComponent as AndroidIcon } from '@assets/android_icon.svg';
import useEscapeKeyCloseModal from '@src/hooks/useEscapeKeyCloseModal';
const divStyle = {
  position: 'absolute',
  left: '46.5%',
  right: '20%',
  top: '31%',
  height: 'fit-content',
  width: '52.75%',
  backgroundColor: ' #fff',
  boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
};

const StepsNlpInputParameter = (props) => {
  const search = window.location.search;
  const moduleId = props.scripts.parentId;
  const scriptId = props.scripts.id;
  const defaultStepGroup = new URLSearchParams(search).get('defaultStepGroup');
  const history = useHistory();
  const formRef = useRef();
  const isRepositoryEditable = React.useMemo(() => window.permission.isEditAccess('repository'), []);
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [isLoading, setIsLoading] = useState(true);
  const [stepData, setStepData] = useState();
  const [oldStepData, setOldStepData] = useState();
  const [createUpdateCalled, setCreateUpdateCalled] = useState(true);
  const [onCancelReloadRequired, setOnCancelReloadRequired] = useState(false);
  const onCancelReloadStateHandler = () => {
    setOnCancelReloadRequired(true);
  };
  const [stepsOpen, setStepsOpen] = useState(true);
  const [ifCheckPointIsFailed, setIfCheckPointIsFailed] = useState(
    props.type === 'Step Group'
      ? 'MARK_THIS_STEP_AS_FAILED_AND_CONTINUE_STEP_GROUP_EXECUTION'
      : 'MARK_THIS_STEP_AS_FAILED_AND_CONTINUE_SCRIPT_EXECUTION'
  );
  const [ifFailedArray, setIfFaiedArray] = useState([]);
  const endConditionObject = ['EndIfCondition', 'EndElseIfCondition', 'EndElseCondition'];
  const startConditionObject = ['IfCondition', 'ElseIfCondition', 'ElseCondition'];
  const startNlpList = ['StartForLoop', 'StartIteration'];
  const endNlpList = ['EndForLoop', 'EndIteration'];
  const operatorsList = ['<', '<=', '>', '>=', '==', '!='];
  const operationsList = ['Increment', 'Decrement'];
  const [localVariableData, setLocalVariableData] = useState([]);
  const [stepGroupParameterData, setStepGroupParameterData] = useState([]);
  const [stepGroupVariableData, setStepGroupVariableData] = useState([]);
  const [globalVariableData, setGlobalVariableData] = useState([]);
  const [projectEnvironmentVariableData, setProjectEnvironmentVariableData] = useState([]);
  const [dataProviderVariableData, setDataProviderVariableData] = useState([]);
  const [stepgroupSteps, setStepGroupSteps] = useState([]);
  const [stepGroup, setStepGroup] = useState('');
  const [target, setTarget] = useState({});
  let [elementData, setElementData] = useState([]);
  const [dataProviderName, setDataProviderName] = useState();
  const [isDataProviderModal, setIsDataProviderModal] = useState(false);
  const [elementPlatForm, setElementPlatForm] = useState('');
  const [fileType, setFileType] = useState('');

  let [selectedDataProviderData, setSelectedDataProviderData] = useState();
  const [stepsDataObject, setStepsDataObject] = useState(
    props.data.defaultDisplayName ? props.data : props.stepsDataObject
  );
  let [data, setData] = useState(props.data);
  let [stepsEdit, setStepsEdit] = useState(0);
  let [stepIndex, setStepIndex] = useState(props.orderOfInsertion - 1);
  let [elementLocators, setElementLocators] = useState([]);
  let [activeElementTab, setActiveElementTab] = useState('');
  const [tableData, setTableData] = useState({});
  const [elementDetails, setElementDetails] = useState({});
  const [initialValues, setInitialValues] = useState();
  const [initialValuesField, setInitialValuesField] = useState();
  const [validationSchema, setValidationSchema] = useState();
  const [validationField, setValidationField] = useState();
  let [returnTypeReferemce, setReturnTypeReference] = useState();
  const [stepGroupLink, setStepGroupLink] = useState(true);
  let locatorVal = [];
  const { isSpecialNlp, parentSpecialNlpId } = props;

  const updateElementDetails = ({ data, fieldName, dynamicInput, locatorIdx, inputIndex }) => {
    let tempElementDetails = JSON.parse(JSON.stringify(elementDetails));
    Object.keys(tempElementDetails).map((field) => {
      if (
        field === fieldName &&
        tempElementDetails[field].locators[locatorIdx].dynamicLocatorArray[inputIndex].name === dynamicInput.name
      ) {
        tempElementDetails[field].locators[locatorIdx].dynamicLocatorArray[inputIndex].value = data.value;
        tempElementDetails[field].locators[locatorIdx].dynamicLocatorArray[inputIndex]['reference'] = data.reference;
      }
    });
    setElementDetails(tempElementDetails);
  };
  let updateVariableDetail = (data, stepInputName, dynamicInput, locatorIdx, inputIndex, type) => {
    const ref = data?.reference || null;
    const val =
      type === 'hardcoded' ? data : ref === 'DATAPROVIDER' ? `${data.dpName}:${data.varname}` : data?.name || null;
    const inputData = {
      data: { value: val, reference: ref },
      fieldName: stepInputName,
      dynamicInput: dynamicInput,
      locatorIdx: locatorIdx,
      inputIndex: inputIndex,
    };
    updateElementDetails(inputData);
  };

  const getLocatorInput = ({
    locatorIdx,
    inputIndex,
    dynamicInput,
    element,
    handleBlur,
    setFieldValue,
    handleChange,
  }) => {
    return (
      <div className="my-6 mb-2 w-80">
        <VariableSuggestion
          reloadStepsTable={onCancelReloadStateHandler}
          identity="dynamicInputField"
          dynamicInputFieldName={dynamicInput?.name}
          localVariableData={localVariableData}
          moduleId={moduleId}
          scriptId={scriptId}
          defaultStepGroup={defaultStepGroup}
          stepId={dynamicInput?.value ? data.stepId : ''}
          id={`${element.stepInputName}dynamicHello${locatorIdx}${inputIndex}`}
          stepInputName={`${element.stepInputName}dynamic${locatorIdx}${inputIndex}`}
          stepInputType={''}
          setVariableValue={(data, stepInputName, type) => {
            updateVariableDetail(data, element?.stepInputName, dynamicInput, locatorIdx, inputIndex, type);
          }}
          editData={dynamicInput?.value}
          type={props.type}
          reference={dynamicInput?.reference}
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          parentStartIfStep={props?.parentStartIfStep}
          parentStartDataproviderStep={getParentDataProviderStep()}
        />
      </div>
    );
  };

  const getLocatorTable = ({ element, handleChange, setFieldValue, handleBlur, setFieldError }) => {
    return (
      <div
        className="shadow max-h-52 overflow-scroll border-b border-gray-200 border-2 sm:rounded-lg"
        id="journal-scroll"
      >
        <table className="min-w-full overflow-x-hidden divide-y divide-gray-200 overflow-scroll">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left fontPoppinsMediumMd text-gray-500 uppercase tracking-wider"
              >
                Locator Type
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left fontPoppinsMediumMd text-gray-500 uppercase tracking-wider"
              >
                Locator Value
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left fontPoppinsMediumMd text-gray-500 uppercase tracking-wider"
              >
                Platform
              </th>
            </tr>
          </thead>
          <tbody>
            {element?.locators?.map((locator, locatorIdx) => {
              if (['dynamic', 'Dynamic']?.includes(locator.type)) {
                return (
                  <tr className={`text-left ${locatorIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{locator.name}</td>
                    <td className="px-6 py-4 mb-1 whitespace-nowrap text-sm text-gray-500">
                      {locator?.dynamicLocatorArray?.map((dynamicInput, inputIndex) => {
                        return getLocatorInput({
                          locator,
                          locatorIdx,
                          inputIndex,
                          dynamicInput,
                          handleChange,
                          setFieldValue,
                          element,
                          handleBlur,
                        });
                      })}
                    </td>
                    <td className="pl-12 pt-6">
                      {locator.os === 'Android' ? <AndroidIcon /> : locator?.os === 'iOS' ? <AppleIcon /> : '--'}
                    </td>
                  </tr>
                );
              } else {
                return '';
              }
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const getAllLocalVariables = async () => {
    try {
      const response = await getAllLocalVariablesReq(props.scripts?.parentId, props.scripts?.id);
      if (response?.data?.responseObject) {
        setLocalVariableData(response.data.responseObject);
      } else {
        setLocalVariableData([]);
      }
    } catch (err) {
      setLocalVariableData([]);
      console.error('GET_LOCAL_VARIABLES : ', err);
    }
  };

  const getStepGroupParameter = async () => {
    try {
      const search = window.location.search;
      const id = new URLSearchParams(search).get('id');
      const parentId = new URLSearchParams(search).get('parentId');
      const response = await gellAllParameter(parentId, id);
      if (response?.data?.responseObject) {
        setStepGroupParameterData(response.data.responseObject);
      } else {
        setStepGroupParameterData([]);
      }
    } catch (err) {
      setStepGroupParameterData([]);
      console.error('GET_STEPGROUP_PARAMETER : ', err);
    }
  };

  const getStepGroupVariable = async () => {
    try {
      const search = window.location.search;
      const id = new URLSearchParams(search).get('id');
      const parentId = new URLSearchParams(search).get('parentId');
      const response = await getAllStepGroupVariable(parentId, id);
      if (response?.data?.responseObject) {
        setStepGroupVariableData(response.data.responseObject);
      } else {
        setStepGroupVariableData([]);
      }
    } catch (err) {
      setStepGroupVariableData([]);
      console.error('GET_STEPGROUP_VARIABLE : ', err);
    }
  };

  const getGlobalVariable = async () => {
    try {
      const response = await getAllGlobalVariableListReq();
      if (response?.data?.responseObject) {
        setGlobalVariableData(response.data.responseObject);
      } else {
        setGlobalVariableData([]);
      }
    } catch (err) {
      setGlobalVariableData([]);
      console.error('GET_GLOBAL_VARIABLES : ', err);
    }
  };

  const getProjectEnvironmentVariable = async () => {
    try {
      const response = await getAllProjectVariableListReq();
      if (response?.data?.responseObject) {
        setProjectEnvironmentVariableData(response.data.responseObject);
      } else {
        setProjectEnvironmentVariableData([]);
      }
    } catch (err) {
      setProjectEnvironmentVariableData([]);
      console.error('GET_PROJECT_ENV_VARIABLES : ', err);
    }
  };

  const getDataProviderVariableData = () => {
    if (props?.parentStartDataproviderStep?.dataProviderVariables) {
      setDataProviderVariableData(props.parentStartDataproviderStep.dataProviderVariables);
    } else if (props?.data?.isIterationStep && props?.data?.parentSpecialNlpId) {
      const _parentStartDpStep = props.scripts.steps.find(
        (step) => step.specialNlpId === props.data.parentSpecialNlpId
      );
      if (_parentStartDpStep?.dataProviderVariables) {
        setDataProviderVariableData(_parentStartDpStep?.dataProviderVariables);
      }
    } else if (props.edit && props?.editData?.isIterationStep && props?.editData?.parentSpecialNlpId) {
      const _parentStartDpStep = props.scripts.steps.find(
        (step) => step.specialNlpId === props.editData.parentSpecialNlpId
      );
      if (_parentStartDpStep?.dataProviderVariables) {
        setDataProviderVariableData(_parentStartDpStep?.dataProviderVariables);
      }
    }
  };

  const getStepGroup = async (libraryId, stepgroupId) => {
    try {
      resetProgress();
      const response = await getStepGroupReq(libraryId, stepgroupId);
      if (response?.data?.responseObject) {
        setStepGroup(response.data.responseObject);
        setStepGroupLink(false);
        if (response.data.responseObject?.steps?.length) {
          const steps = response.data.responseObject.steps.map((data) => data.name);
          setStepGroupSteps(steps);
        } else {
          setStepGroupSteps([]);
        }
      }
      resetProgress();
    } catch (err) {
      setStepGroupSteps([]);
      console.error('GET_STEPGROUP_REQ : ', err);
    }
  };

  const updateNlpDescription = (data, stepInputName, mode, emptyElementData) => {
    setElementLocators([]);
    let valuesForDynamic = { ...formRef?.current?.values, [stepInputName]: data.name };
    let validationfield = validationField.slice(0);
    for (var key in valuesForDynamic) {
      if (key.includes('dynamic', 'Dynamic') && key.includes(stepInputName)) {
        if (valuesForDynamic.hasOwnProperty(key)) {
          delete valuesForDynamic[key];
        }
      }
    }
    validationfield.forEach((field, index) => {
      if (field.id.includes('dynamic', 'Dynamic') && field.id.includes(stepInputName)) {
        validationfield.splice(index, 1);
      }
    });
    let updatedStepsDataObject;
    if (emptyElementData) {
      updatedStepsDataObject = emptyElementData;
    } else if (stepsDataObject === '') {
      updatedStepsDataObject = { ...props.data };
    } else {
      updatedStepsDataObject = { ...stepsDataObject };
    }
    const newPageName = getPagesName(data);
    let nlpStepName = updatedStepsDataObject?.defaultDisplayName;
    let newToolTip = updatedStepsDataObject?.defaultToolTip;
    let nlpStepDynamicName = nlpStepName.replace('*elementName*', data.name);
    let nlpStepDynamicPage = nlpStepDynamicName.replace('*elementScreen*', newPageName);
    let nlpStepDynamicType = nlpStepDynamicPage.replace('*elementType*', data.type);
    newToolTip = newToolTip.replace('*elementName*', data.name);
    newToolTip = newToolTip.replace('*elementScreen*', newPageName);
    newToolTip = newToolTip.replace('*elementType*', data.type);
    newToolTip = newToolTip.replace('*scrollable ContainerElementName*', data.name);
    newToolTip = newToolTip.replace('*scrollable ContainerElementType*', data.type);
    updatedStepsDataObject?.stepInputs.forEach((inputObj, index) => {
      if (inputObj.type !== 'org.openqa.selenium.WebElement' && inputObj.value) {
        nlpStepDynamicType = nlpStepDynamicType.replace('*' + inputObj.name + '*', inputObj.value);
        newToolTip = newToolTip.replace('*' + inputObj.name + '*', inputObj.value);
      } else {
        nlpStepDynamicType = nlpStepDynamicType.replace('*element*', data.name);
        newToolTip = newToolTip.replace('*elementName*', data.name);
        newToolTip = newToolTip.replace('*elementPage*', newPageName);
        newToolTip = newToolTip.replace('*scrollable ContainerElementName*', data.name);
        newToolTip = newToolTip.replace('*scrollable ContainerElementType*', data.type);
      }
      if (inputObj.name === 'elementType') {
        inputObj.value = data?.type;
      }
      if (inputObj.name === stepInputName) {
        let elementId;
        inputObj.locators = data.locators;
        setActiveElementTab(data.name);
        if (inputObj.type === 'org.openqa.selenium.WebElement') {
          if (inputObj.value) {
            elementId = inputObj.value.split(':')[1];
          }
          inputObj.reference = 'ELEMENT';
          inputObj.value = data?.pageId + ':' + data?.id;
        }
        if (!updatedStepsDataObject?.elementDetails) {
          updatedStepsDataObject.elementDetails = [];
        }
        if (updatedStepsDataObject.elementDetails) {
          let elementIndex = updatedStepsDataObject.elementDetails.findIndex((ele) => ele.elementId === elementId);
          if (elementIndex > -1) {
            updatedStepsDataObject.elementDetails.splice(elementIndex, 1);
          }
          let elementObject = {
            name: data?.name,
            stepInputName: stepInputName,
            elementId: data?.id,
            parentId: data?.pageId,
            type: data?.type,
            locators: data?.locators,
            isShared: data?.isShared,
            platform: data?.platform,
          };
          updatedStepsDataObject.elementDetails.push(elementObject);
          if (data?.platform) {
            setElementPlatForm(data?.platform);
          }
        }
      }
      if (
        [
          'elementName',
          'allElementsName',
          'toDropElementName',
          'toElementName',
          'toElementType',
          'scrollable ContainerElementName',
          'scrollable ContainerElementType',
        ].includes(inputObj.name)
      ) {
        inputObj.value = newPageName + ':' + data?.name;
      }
    });
    updatedStepsDataObject.displayName = nlpStepDynamicType;
    updatedStepsDataObject.toolTip = newToolTip;
    setStepsDataObject(updatedStepsDataObject);
    setData({ ...updatedStepsDataObject });

    let isDynamicLocators = false;
    if (data.locators.length) {
      setTimeout(() => {
        data?.locators?.map((e, i) => {
          if (['Dynamic', 'dynamic']?.includes(e.type)) {
            locatorVal.push(data.locators[i]);
          }
        });
        if (target.placeholder === 'Search for elements') {
          tableData[target.name] = locatorVal;
        }
        setElementLocators(data.locators);
      }, 1000);
      let countofDynamiclocator = 0;
      let dynamicLocObj = {};
      data.locators.forEach((locator, i) => {
        if (locator.type !== 'png') {
          let tempValue;
          if (['dynamic', 'Dynamic']?.includes(locator?.type)) {
            let splitWord = locator?.value
              ?.replaceAll('}', '{')
              ?.split('{')
              ?.filter((x, i) => i % 2 !== 0)[0]
              ?.split('');
            tempValue = splitWord?.splice(0, splitWord?.length - 1)?.join('');
          }
          var count = countOccurences(locator.value, tempValue);
          if (count && ['dynamic', 'Dynamic']?.includes(locator.type)) {
            //code written by abhirami
            let tempDynamicLocArray = [];
            elementDetails?.element?.locators.map((eleLocObj) => {
              if (locator?.value === eleLocObj?.value) {
                tempDynamicLocArray = eleLocObj?.dynamicLocatorArray;
              }
            });
            let dynamicLocatorArray = [];
            for (let index = 0; index < count; index++) {
              const dynamicName =
                locator?.value
                  ?.replaceAll('}', '{')
                  .split('{')
                  .filter((x, i) => i % 2 !== 0)[index] || `dynamic${i}${index}`;
              let tempObj = {};
              if (tempDynamicLocArray?.length) {
                tempObj = tempDynamicLocArray.find((childObj) => childObj?.name === dynamicName);
              }
              dynamicLocatorArray.push({
                name: dynamicName,
                value: tempObj?.value || '',
                type: '',
                reference: tempObj?.reference,
              });
              valuesForDynamic[`${stepInputName}dynamic${i}${index}`] = '';
              let obj = {
                id: `${stepInputName}dynamic${i}${index}`,
                type: 'text',
                validationType: 'string',
                validations: [
                  {
                    type: 'required',
                    params: [`Value is required`],
                  },
                ],
              };
              validationfield.push(obj);
              dynamicLocObj[`${stepInputName}dynamic${i}${index}`] = tempObj?.value || '';
            }
            countofDynamiclocator = countofDynamiclocator + 1;
            locator.dynamicLocatorArray = dynamicLocatorArray;
            isDynamicLocators = true;
          }
          setTimeout(() => {
            let tempFormValue = {};
            Object.keys(formRef.current.values).forEach((key) => {
              if (!(key.includes('dynamic', 'Dynamic') && key?.includes(stepInputName))) {
                tempFormValue[key] = formRef.current.values[key];
              }
            });
          });
        }
      });
    }

    if (isDynamicLocators) {
      setValidationField(validationfield);
      const yepSchema = validationfield.reduce(createYupSchema, {});
      setInitialValues(valuesForDynamic);
      formRef?.current?.setValues(valuesForDynamic);
      data.stepInputName = stepInputName;
      let tempElementDetails = JSON.parse(JSON.stringify(elementDetails));
      tempElementDetails[stepInputName] = data;
      setElementDetails(tempElementDetails);
      setValidationSchema(yup.object().shape(yepSchema));
      getAllLocalVariables();
      getStepGroupParameter();
      getStepGroupVariable();
      getGlobalVariable();
      getProjectEnvironmentVariable();
      getDataProviderVariableData();
    } else {
      let tempElementDetails = JSON.parse(JSON.stringify(elementDetails));
      delete tempElementDetails[stepInputName];
      setElementDetails(tempElementDetails);
    }
  };

  function countOccurences(string, word) {
    return string.split(word).length - 1;
  }

  const setVariableValue = (data, stepInputName, type) => {
    let updatedStepsDataObject;
    if (stepsDataObject !== '') {
      updatedStepsDataObject = { ...stepsDataObject };
    } else {
      updatedStepsDataObject = { ...props.data };
    }
    let newDesc = updatedStepsDataObject?.defaultDisplayName;
    let newToolTip = updatedStepsDataObject?.defaultToolTip
      ? updatedStepsDataObject?.defaultToolTip
      : updatedStepsDataObject?.name;
    const stpInp = [...updatedStepsDataObject.stepInputs];
    if (props.data.type === 'Group' || props.data.nlpType === 'STEP_GROUP') {
      newToolTip = props.data.displayName;
      updatedStepsDataObject?.stepInputs?.forEach((inputObj) => {
        if (inputObj.name === stepInputName) {
          if (type === 'variable') {
            inputObj.value = data?.type === 'DATAPROVIDER' ? `${data.dpName}:${data.varname}` : data.name;
            inputObj.reference = data?.reference;
          } else {
            inputObj.value = data;
            inputObj.reference = null;
          }
        }
      });
    } else {
      if (newDesc) {
        if (type !== 'file') {
          updatedStepsDataObject?.stepInputs?.forEach((inputObj) => {
            if (inputObj.value) {
              if (inputObj.name === stepInputName) {
                if (type === 'variable') {
                  newDesc = newDesc.replace(
                    '*' + inputObj.name + '*',
                    data?.type === 'DATAPROVIDER' ? `${data.dpName}:${data.varname}` : data.name
                  );
                  newToolTip = newToolTip.replace(
                    '*' + inputObj.name + '*',
                    data?.type === 'DATAPROVIDER' ? `${data.dpName}:${data.varname}` : data.name
                  );
                } else if (type === 'startIteration' || type === 'endIteration') {
                  let _retObj = iterationTooltipBuilder(data, type, newDesc, newToolTip, inputObj);
                  if (_retObj) {
                    newToolTip = _retObj.newToolTip;
                    newDesc = _retObj.newDesc;
                  }
                } else if (data === '') {
                  newDesc = newDesc.replace(data, '');
                  newToolTip = newToolTip.replace(data, '');
                } else {
                  if (
                    ['all', 'local'].includes(inputObj.fileOrigin) ||
                    ['all', 'local'].includes(inputObj.folderOrigin)
                  ) {
                    inputObj['fileType'] = fileType;
                    if (inputObj.hasOwnProperty('label')) {
                      delete inputObj['label'];
                    }
                  }
                  newDesc = newDesc.replace('*' + inputObj.name + '*', data);
                  newToolTip = newToolTip.replace('*' + inputObj.name + '*', data);
                }
              } else if (inputObj.type === 'org.openqa.selenium.WebElement' && inputObj.value) {
                stpInp?.forEach((stpObj) => {
                  if (stpObj.name === 'elementName') {
                    const val = stpObj.value.slice(stpObj.value.indexOf(':') + 1);
                    const pag = stpObj.value.slice(0, stpObj.value.indexOf(':'));
                    newDesc = newDesc.replace('*element*', val);
                    newToolTip = newToolTip.replace('*elementName*', val);
                    newToolTip = newToolTip.replace('*elementPage*', pag);
                    newToolTip = newToolTip.replace('*elementScreen*', pag);
                  }
                });
              } else {
                if (inputObj.name === 'elementName') {
                  newDesc = newDesc.replace('*elementName*', inputObj.value.slice(inputObj.value.indexOf(':') + 1));
                  newToolTip = newToolTip.replace(
                    '*elementName*',
                    inputObj.value.slice(inputObj.value.indexOf(':') + 1)
                  );
                  newToolTip = newToolTip.replace(
                    '*elementPage*',
                    inputObj.value.slice(0, inputObj.value.indexOf(':'))
                  );
                  newToolTip = newToolTip.replace(
                    '*elementScreen*',
                    inputObj.value.slice(0, inputObj.value.indexOf(':'))
                  );
                } else {
                  newDesc = newDesc.replace('*' + inputObj.name + '*', inputObj.value);
                  newToolTip = newToolTip.replace('*' + inputObj.name + '*', inputObj.value);
                }
              }
            } else if (inputObj.name === stepInputName) {
              if (type === 'variable') {
                newDesc = newDesc.replace(
                  '*' + inputObj.name + '*',
                  data?.type === 'DATAPROVIDER' ? `${data.dpName}:${data.varname}` : data.name
                );
                newToolTip = newToolTip.replace(
                  '*' + inputObj.name + '*',
                  data?.type === 'DATAPROVIDER' ? `${data.dpName}:${data.varname}` : data.name
                );
              } else if (type === 'startIteration' || type === 'endIteration') {
                let _retObj = iterationTooltipBuilder(data, type, newDesc, newToolTip, inputObj);
                if (_retObj) {
                  newToolTip = _retObj.newToolTip;
                  newDesc = _retObj.newDesc;
                }
              } else {
                newDesc = newDesc.replace('*' + inputObj.name + '*', data);
                newToolTip = newToolTip.replace('*' + inputObj.name + '*', data);
              }
            }
            if (inputObj.name === stepInputName) {
              if (type === 'variable') {
                inputObj.value = data?.type === 'DATAPROVIDER' ? `${data.dpName}:${data.varname}` : data.name;
                inputObj.reference = data?.reference;
              } else {
                inputObj.value = data;
                if (
                  !(['all', 'local'].includes(inputObj.fileOrigin) || ['all', 'local'].includes(inputObj.folderOrigin))
                ) {
                  if (
                    ['operand1', 'input1'].includes(stepInputName) &&
                    updatedStepsDataObject.nlpName === 'StartForLoop'
                  ) {
                    inputObj.reference = type;
                  } else {
                    inputObj.reference = null;
                  }
                }
              }
              if (['all', 'local'].includes(inputObj.fileOrigin) || ['all', 'local'].includes(inputObj.folderOrigin)) {
                inputObj['fileType'] = data?.fileType || fileType;
                if (inputObj.hasOwnProperty('label')) {
                  delete inputObj['label'];
                }
              }
            }
          });
        } else {
          updatedStepsDataObject?.stepInputs?.forEach((inputObj) => {
            if (
              (['all', 'testdata'].includes(inputObj.fileOrigin) ||
                ['all', 'testdata'].includes(inputObj.folderOrigin)) &&
              inputObj.name === stepInputName
            ) {
              inputObj['fileType'] = 'TESTDATA';
              inputObj['fileId'] = data?._id;
              if (inputObj.hasOwnProperty('reference')) {
                delete inputObj['reference'];
              }
            }
            if (inputObj.name === stepInputName) {
              if (data?.path !== '' && data?.name !== '') {
                newDesc = newDesc.replace('*' + inputObj.name + '*', data.name);
                newToolTip = newDesc.replace('*' + inputObj.name + '*', data.name);
                inputObj.value = data.actualPath;
                inputObj.label = data.name;
                inputObj.fileType = 'TESTDATA';
                inputObj.fileId = data?._id;
              } else {
                inputObj.value = data.actualPath;
                inputObj.label = data.name;
              }
            } else {
              if (inputObj.label) {
                newDesc = newDesc.replace('*' + inputObj.name + '*', inputObj.label);
                newToolTip = newDesc.replace('*' + inputObj.name + '*', inputObj.label);
              } else {
                if (newDesc?.includes('*' + inputObj.name + '*')) {
                  newDesc = newDesc.replace('*' + inputObj.name + '*', inputObj.value);
                  newToolTip = newDesc.replace('*' + inputObj.name + '*', inputObj.value);
                }
              }
            }
          });
        }
      }
    }
    updatedStepsDataObject.displayName = newDesc;
    updatedStepsDataObject.toolTip = newToolTip;
    setStepsDataObject(updatedStepsDataObject);
    setData({ ...updatedStepsDataObject });
  };
  /**
   *
   * @param {*} data | actual dataprovider value
   * @param {*} inputObj | iterating step input object
   * @param {*} type | type of data provider iteration
   * @param {*} newDisplayName
   * @param {*} newToolTip
   * @returns
   */
  const iterationTooltipBuilder = (data, type, newDisplayName, newToolTip, inputObj) => {
    if (type === 'startIteration') {
      newDisplayName = newDisplayName.replace('iteration', 'iteration using');
      newDisplayName = newDisplayName.replace('*' + inputObj.name + '*', data);
      newToolTip = newDisplayName.concat(' Dataprovider');
    } else if (type === 'endIteration') {
      newDisplayName = newDisplayName.replace('iteration', 'iteration for ');
      newToolTip = newDisplayName.concat(data + ' Dataprovider');
    } else if (type === 'startFor') {
      newDisplayName = newDisplayName.replace('forLoop', 'for loop for ');
      newToolTip = newDisplayName.concat(data + ' Loop');
    } else if (type === 'endFor') {
      newDisplayName = newDisplayName.replace('iteration', 'iteration for ');
      newToolTip = newDisplayName.concat(data + ' End Loop');
    }
    return { newToolTip: newToolTip, newDesc: newToolTip };
  };
  const setReturnValue = (data, stepInputName, type) => {
    stepsDataObject.stepReferenceInfo = {
      type: type,
      name: data.name,
      masked: data.masked,
    };
    setReturnTypeReference(stepsDataObject.stepReferenceInfo?.type);
  };

  const removeStepRef = () => {
    if (stepsDataObject.stepReferenceInfo) {
      delete stepsDataObject.stepReferenceInfo;
      setReturnTypeReference();
    }
  };

  const setReferenceToStepInputs = (stepInputs) => {
    stepInputs.forEach((inputObj) => {
      if (['WebDriver', 'IOSDriver', 'AndroidDriver'].includes(inputObj?.type)) {
        inputObj.reference = 'DRIVER';
      }
    });
    return stepInputs;
  };

  const setCheckPointForFailed = (stepsObj) => {
    let obj = {
      value: ifCheckPointIsFailed,
      name: 'ifCheckPointIsFailed',
      type: 'java.lang.String',
    };
    if (stepsObj.stepInputs) {
      let exist = false;
      let stepInputs = stepsObj.stepInputs;
      stepInputs.forEach((data, index) => {
        if (data.name === 'ifCheckPointIsFailed') {
          data.value = ifCheckPointIsFailed;
          exist = true;
        }
      });
      if (exist === false) {
        stepsObj.stepInputs.push(obj);
      }
    } else {
      stepsObj.stepInputs = [];
      stepsObj.stepInputs.push(obj);
    }
  };

  const saveStepGroupSteps = () => {
    let stepsObj = {};
    let stepReferenceInfoExist = false;
    let returnstepExeId;
    let returnTypeStepNo;
    if (props.stepGroup?.returnType !== 'void' && props?.stepGroup) {
      let retrurnType = props.stepGroup?.returnType?.split('Step');
      returnTypeStepNo = Math.floor(retrurnType?.[retrurnType?.length - 1]);
      returnstepExeId = props.stepGroup.steps?.[returnTypeStepNo - 1]?.executionOrder;
    }
    if (stepsDataObject.returnType !== 'void') {
      if (stepsDataObject.stepReferenceInfo) {
      } else {
        stepReferenceInfoExist = true;
        formRef.current.setFieldError('returnValue', 'Always use Variable for Return');
        setCreateUpdateCalled(true);
      }
    }
    stepsDataObject.stepInputs.forEach((data) => {
      if (data.type === 'org.openqa.selenium.WebElement') {
        if (data.reference) {
        } else {
          stepReferenceInfoExist = true;
          formRef.current.setFieldError('element', 'Select elements from drop down ');
          setCreateUpdateCalled(true);
        }
      }
      if (data?.type?.includes('com.tyss.optimize.nlp.util')) {
        if (!data.value || data.value === '') {
          stepReferenceInfoExist = true;
          formRef.current.setFieldError(data.name, 'Select file path from drop down ');
          setCreateUpdateCalled(true);
        }
      }
    });
    if (stepReferenceInfoExist === false) {
      const { actualFailedResult } = stepsDataObject || {};
      const libraryId = new URLSearchParams(search).get('parentId');
      const stepGroupId = new URLSearchParams(search).get('id');
      setStaticValue();
      const getNLpId = (type) => {
        if (type === 'step') {
          if (props.edit) {
            return props.mode === 'update' ? stepData?.nlpId : stepData?._id;
          } else {
            return stepData?._id;
          }
        } else if (type === 'Group') {
          if (props.edit) {
            return props.mode === 'update' ? stepData?.nlpId : stepData?.parentId;
          } else {
            return stepData?.parentId;
          }
        }
      };
      const getStepGroupId = (type) => {
        if (type === 'Group') {
          if (props.edit) {
            return stepsDataObject?.stepGroupId ? stepsDataObject?.stepGroupId : stepsDataObject?.parentId;
          } else {
            return stepsDataObject?.parentId;
          }
        }
      };
      const getAddEditExecutionOrder = (type) => {
        if (type === 'step') {
          if (props.edit) {
            return props?.editData?.executionOrder ? props?.editData?.executionOrder : data.executionOrder;
          } else {
            return props?.executionOrder;
          }
        } else if (type === 'Group') {
          if (props.edit) {
            return props?.editData?.executionOrder ? props?.editData?.executionOrder : data.executionOrder;
          } else {
            return props?.executionOrder;
          }
        }
      };
      //TODO :: TO UPADTE OR REPLACE OR CREATE STEP OR STEP_GROUP STEP   :: START
      let conditionId = stepsDataObject.conditionId || getUniqueId('CON');
      let object = evaluateMarginLeft();
      let conditionSearchKey = conditionId;

      const getCommonProps = (type) => {
        return {
          nlpName: stepsDataObject?.nlpName,
          actualFailedResult: stepsDataObject?.actualFailedResult,
          stepInputs: setReferenceToStepInputs(stepsDataObject?.stepInputs),
          stepReferenceInfo: stepsDataObject?.stepReferenceInfo,
          defaultDisplayName: stepsDataObject?.defaultDisplayName,
          toolTip: stepsDataObject?.toolTip,
          defaultToolTip: stepsDataObject?.defaultToolTip,
          name: type === 'Group' ? stepsDataObject?.name : stepsDataObject?.displayName,
          displayName: type === 'Group' ? stepsDataObject?.name : stepsDataObject?.displayName,
          returnType: type === 'Group' ? data?.returnType : stepsDataObject?.returnType,
          nlpId: getNLpId(type),
          type: type,
          executionOrder: getAddEditExecutionOrder(type),
          marginLeft: data?.marginLeft,
          isDisabled: data?.isDisabled ? data?.isDisabled : false,
        };
      };
      const commonPropsStepGroup = {
        ...getCommonProps('Group'),
        libraryId: stepsDataObject?.libraryId,
        stepGroupId: getStepGroupId('Group'),
      };
      const commonPropsStep = {
        ...getCommonProps('step'),
        passMessage: data?.passMessage,
        failMessage: data?.failMessage,
        platform: data?.platform,
        elementDetails: stepsDataObject?.elementDetails,
      };
      if (props.edit) {
        const additionalStepGroupProps = {
          stepId: props.edit ? data?.stepId || props?.editData?.stepId : getStepId(),
          marginLeft: data?.marginLeft,
          conditionSearchKey: props.edit
            ? data.conditionSearchKey || props.editData?.conditionSearchKey || stepsDataObject.conditionSearchKey
            : conditionSearchKey,
        };
        if (stepsDataObject.type === 'Group' || stepsDataObject.nlpType === 'STEP_GROUP') {
          stepsObj = {
            ...commonPropsStepGroup,
            ...additionalStepGroupProps,
          };

          setCheckPointForFailed(stepsObj);
        } else {
          stepsObj = {
            ...commonPropsStep,
            ...additionalStepGroupProps,
          };
          if (stepsObj?.nlpId?.includes('PE_NLP')) {
            stepsObj['packageName'] = stepsDataObject?.packageName;
          }
          addLocators(stepsObj);
          setCheckPointForFailed(stepsObj);
        }
      } else {
        if (stepsDataObject.type === 'Group' || stepsDataObject.nlpType === 'STEP_GROUP') {
          stepsObj = {
            ...commonPropsStepGroup,
            stepId: getStepId(),
          };

          setCheckPointForFailed(stepsObj);
        } else {
          stepsObj = {
            ...commonPropsStep,
            stepId: getStepId(),
          };

          if (stepsObj?.nlpId?.includes('PE_NLP')) {
            stepsObj['packageName'] = stepsDataObject?.packageName;
          }
          addLocators(stepsObj);
          setCheckPointForFailed(stepsObj);
        }
        if (props.isSpecialNlp) {
          if (props?.parentStartDataproviderStep?.nlpName === 'StartForLoop') {
            stepsObj['isForLoopStep'] = true;
          } else {
            stepsObj['isIterationStep'] = true;
          }
          stepsObj['parentSpecialNlpId'] = props?.parentSpecialNlpId;
        }
        if (props.parentStartDataproviderStep && props.parentStartDataproviderStep.hasOwnProperty('marginLeft')) {
          stepsObj['marginLeft'] = Number(props.parentStartDataproviderStep.marginLeft + 1.7);
        }
        if (props?.parentStartIfStep) {
          stepsObj['conditionId'] = props.parentStartIfStep?.conditionId;
        }
        if (!stepsObj?.marginLeft || stepsObj['marginLeft'] > 0) {
          stepsObj['marginLeft'] = object?.marginLeft;
          stepsObj['conditionHierarchy'] = Number(object?.conditionHierarchy);
          if (object?.conditionId) {
            stepsObj['conditionId'] = object?.conditionId;
          }
          if (object?.conditionSearchKey) {
            stepsObj['conditionSearchKey'] = object?.conditionSearchKey;
          }
        }
      }
      if (props.edit) {
        if (data?.nlpId?.includes('PE_NLP') && data?.description) {
          stepsObj['name'] = data?.description;
          stepsObj['description'] = data?.description;
          stepsObj['displayName'] = data?.description;
          stepsObj['defaultTooltip'] = `default_package :${data?.description}`;
          stepsObj['toolTip'] = `default_package :${data?.description}`;
        }

        props.stepGroup.steps = [stepsObj];
      } else {
        if (isEmptyValue(props?.stepGroup?.steps)) {
          props.stepGroup.steps = [stepsObj];
        } else {
          if (props.addStepAtIndex) {
            props.stepGroup.steps = [stepsObj]; //NOTE :: to new step at an index
          } else {
            props.stepGroup.steps = [stepsObj]; //NOTE  :: to add new step at last position
          }
        }
      }

      //TODO :: TO UPADTE OR REPLACE OR CREATE STEP OR STEP_GROUP STEP   :: END
      props.stepGroup.parameters = null;
      const action = props.edit || props?.mode === 'replace' ? 'UPDATE' : 'ADD';
      if (action === 'ADD' && props.stepGroup?.steps?.[0]?.executionOrder < returnstepExeId) {
        let retrurnType = props.stepGroup?.returnType?.split('Step');
        retrurnType[retrurnType?.length - 1] =
          Number(retrurnType[retrurnType?.length - 1]) + props.stepGroup?.steps.length;
        props.stepGroup.returnType = retrurnType.join('Step');
      } else if (props.stepGroup?.steps?.[0]?.executionOrder === returnstepExeId) {
        props.stepGroup.returnType = 'void';
      }
      updateStepGroupReq(libraryId, stepGroupId, action, props.stepGroup).then((response) => {
        if (response?.data?.responseObject) {
          props.reload(true);
          setStepsEdit(0);
          props.closeModal(true);
          props.setAddStepAtIndex();
          props.MyAlert.success(`${props.stepGroup.name} script updated successfully`);
          if (stepsDataObject.nlpType !== 'STEP_GROUP' && stepsDataObject?.type !== 'Group') {
            props?.setOfStepsWithStepGroup(response.data.responseObject);
          }
        } else if (response.data && response.data.responseCode === 400) {
          props.MyAlert.warning(`${response.data.status}`);
        }
        props.closeModal(true);
      });
    }
  };

  const saveStep = async () => {
    let stepsObj;
    let stepReferenceInfoExist = false;
    if (stepsDataObject.returnType !== 'void') {
      if (stepsDataObject.stepReferenceInfo) {
      } else {
        stepReferenceInfoExist = true;
        formRef.current.setFieldError('returnValue', 'Always use Variable for Return');
        setCreateUpdateCalled(true);
      }
    }

    stepsDataObject.stepInputs.forEach((data) => {
      if (data.type === 'org.openqa.selenium.WebElement') {
        if (data.reference) {
        } else {
          stepReferenceInfoExist = true;
          formRef.current.setFieldError('element', 'Select elements from drop down ');
          setCreateUpdateCalled(true);
        }
      }
      if (data?.type?.includes('com.tyss.optimize.nlp.util')) {
        if (!data.value || data.value === '') {
          stepReferenceInfoExist = true;
          formRef.current.setFieldError(data.name, 'Select file path from drop down ');
          setCreateUpdateCalled(true);
        }
      }
    });

    if (stepReferenceInfoExist === false) {
      const { actualFailedResult } = stepsDataObject || {};
      setStaticValue();
      if (props.edit) {
        if (stepsDataObject.type === 'Group' || stepsDataObject.nlpType === 'STEP_GROUP') {
          stepsObj = {
            name: stepsDataObject?.name,
            nlpName: stepsDataObject?.nlpName,
            displayName: stepsDataObject?.name,
            nlpId: props.mode === 'update' ? stepData?.nlpId : stepData?.parentId,
            actualFailedResult: stepsDataObject?.actualFailedResult,
            type: 'Group',
            returnType: data?.returnType,
            stepInputs: setReferenceToStepInputs(stepsDataObject?.stepInputs),
            stepReferenceInfo: stepsDataObject?.stepReferenceInfo,
            libraryId: stepsDataObject?.libraryId,
            stepGroupId: stepsDataObject?.stepGroupId ? stepsDataObject.stepGroupId : stepsDataObject?.parentId,
            executionOrder: props?.editData?.executionOrder ? props?.editData?.executionOrder : data.executionOrder,
            defaultDisplayName: stepsDataObject?.defaultDisplayName,
            toolTip: stepsDataObject?.toolTip,
            defaultToolTip: stepsDataObject?.defaultToolTip,
            marginLeft: props?.editData?.marginLeft || data.marginLeft || 0,
            slNo: stepsDataObject?.slNo,
            isDisabled: props?.editData?.isDisabled ? props?.editData?.isDisabled : false,
          };
          if (!['Break', 'Continue'].includes(data.nlpName)) {
            setCheckPointForFailed(stepsObj);
          }
        } else {
          stepsObj = {
            name: stepsDataObject?.displayName,
            nlpName: data?.nlpName,
            passMessage: data?.passMessage,
            failMessage: data?.failMessage,
            nlpId: props.mode === 'update' ? stepData?.nlpId : stepData?._id,
            actualFailedResult: stepsDataObject?.actualFailedResult,
            type: 'step',
            platform: data?.platform,
            returnType: data?.returnType,
            stepInputs: setReferenceToStepInputs(stepsDataObject?.stepInputs),
            stepReferenceInfo: stepsDataObject?.stepReferenceInfo,
            elementDetails: stepsDataObject?.elementDetails,
            toolTip: stepsDataObject?.toolTip,
            searchName: stepsDataObject?.searchName,
            displayName: stepsDataObject?.displayName,
            slNo: stepsDataObject?.slNo,
            executionOrder: props?.editData?.executionOrder || data.executionOrder,
            defaultDisplayName: stepsDataObject?.defaultDisplayName,
            defaultToolTip: stepsDataObject?.defaultToolTip,
            marginLeft: props?.editData?.marginLeft || data?.marginLeft || 0,
            isDisabled: props?.editData?.isDisabled ? props?.editData?.isDisabled : false,
          };

          if (stepsObj?.nlpId?.includes('PE_NLP')) {
            stepsObj['packageName'] = stepsDataObject?.packageName;
          }

          if (stepsDataObject?.nlpId?.includes('PE_NLP') && stepsDataObject?.description) {
            stepsObj['name'] = stepsDataObject?.description;
            stepsObj['description'] = stepsDataObject?.description;
            stepsObj['displayName'] = stepsDataObject?.description;
            stepsObj['defaultTooltip'] = `default_package :${stepsDataObject?.description}`;
            stepsObj['toolTip'] = `default_package :${stepsDataObject?.description}`;
          }

          addLocators(stepsObj);

          if (!['Break', 'Continue'].includes(data.nlpName)) {
            setCheckPointForFailed(stepsObj);
          }
        }
        stepsObj['actualFailedResult'] = actualFailedResult;
        stepsObj.stepId = data?.stepId || props?.editData?.stepId || getStepId();

        if (props.mode === 'update') {
          if (props?.editData?.isIterationStep) {
            stepsObj['isIterationStep'] = props?.editData?.isIterationStep;
          } else if (props?.editData?.isForLoopStep) {
            stepsObj['isForLoopStep'] = props?.editData?.isForLoopStep;
          }
          if (props?.editData?.conditionId) {
            stepsObj['conditionId'] = props?.editData?.conditionId;
          }
          stepsObj['parentSpecialNlpId'] = props?.editData?.parentSpecialNlpId;
        } else if (props.mode === 'replace') {
          if (oldStepData?.isIterationStep) {
            stepsObj['isIterationStep'] = oldStepData?.isIterationStep;
          } else if (oldStepData?.isForLoopStep) {
            stepsObj['isForLoopStep'] = oldStepData?.isForLoopStep;
          }
          if (oldStepData?.conditionId) {
            stepsObj['conditionId'] = oldStepData?.conditionId;
          }
          stepsObj['parentSpecialNlpId'] = oldStepData?.parentSpecialNlpId;
        }
        props.scripts.steps[stepIndex] = stepsObj;
      } else {
        if (stepsDataObject.nlpType === 'STEP_GROUP') {
          stepsObj = {
            name: stepsDataObject?.name,
            displayName: stepsDataObject?.name,
            nlpName: stepsDataObject?.nlpName,
            nlpId: stepData?.parentId,
            actualFailedResult: stepsDataObject?.actualFailedResult,
            type: 'Group',
            returnType: data?.returnType,
            stepInputs: setReferenceToStepInputs(stepsDataObject?.stepInputs),
            stepReferenceInfo: stepsDataObject?.stepReferenceInfo,
            libraryId: stepsDataObject?.libraryId,
            stepGroupId: stepsDataObject?.parentId,
            executionOrder: props?.executionOrder,
            defaultDisplayName: stepsDataObject?.defaultDisplayName,
            toolTip: stepsDataObject?.toolTip,
            stepId: getStepId(),
            slNo: props.addStepAtIndex ? props.addStepAtIndex + 1 : props?.scriptRowIndex,
            defaultToolTip: stepsDataObject?.defaultToolTip,
            marginLeft: props?.editData?.marginLeft || stepsDataObject?.marginLeft || 0,
            isDisabled: props?.editData?.isDisabled ? props?.editData?.isDisabled : false,
          };
        } else {
          stepsObj = {
            name: stepsDataObject?.displayName,
            nlpName: stepsDataObject?.nlpName,
            nlpId: stepData?._id,
            passMessage: data?.passMessage,
            failMessage: data?.failMessage,
            actualFailedResult: stepsDataObject?.actualFailedResult,
            type: 'step',
            platform: data?.platform,
            returnType: stepsDataObject?.returnType,
            stepInputs: setReferenceToStepInputs(stepsDataObject?.stepInputs),
            stepReferenceInfo: stepsDataObject?.stepReferenceInfo,
            elementDetails: stepsDataObject?.elementDetails,
            toolTip: stepsDataObject?.toolTip,
            searchName: stepsDataObject?.searchName,
            displayName: stepsDataObject?.displayName,
            executionOrder: props?.executionOrder,
            defaultDisplayName: stepsDataObject?.defaultDisplayName,
            defaultToolTip: stepsDataObject?.defaultToolTip,
            slNo: props.addStepAtIndex ? props.addStepAtIndex + 1 : props?.scriptRowIndex,
            stepId: getStepId(),
            marginLeft: props?.editData?.marginLeft || data.marginLeft || 0,
            isDisabled: props?.editData?.isDisabled ? props?.editData?.isDisabled : false,
          };
          if (stepsObj?.nlpId?.includes('PE_NLP')) {
            stepsObj['packageName'] = stepsDataObject?.packageName;
          }
          addLocators(stepsObj);
        }
        stepsObj['actualFailedResult'] = actualFailedResult;
        //if the step is part of an iteration
        if (props.isSpecialNlp) {
          if (props?.parentStartDataproviderStep?.nlpName === 'StartForLoop') {
            stepsObj['isForLoopStep'] = true;
          } else {
            stepsObj['isIterationStep'] = true;
          }
          stepsObj['parentSpecialNlpId'] = props?.parentSpecialNlpId;
        }
        if (props?.parentStartDataproviderStep?.hasOwnProperty('marginLeft')) {
          stepsObj['marginLeft'] = Number(props.parentStartDataproviderStep.marginLeft + 1.7);
        }
        if (props?.parentStartIfStep) {
          stepsObj['conditionId'] = props.parentStartIfStep?.conditionId;
          stepsObj['conditionSearchKey'] = props.parentStartIfStep?.conditionSearchKey;
        }

        stepsObj['marginLeft'] = getIterationMarginLeft(props);

        if (!stepsObj?.marginLeft || stepsObj['marginLeft'] <= 0) {
          let object = evaluateMarginLeft();
          stepsObj['marginLeft'] = object?.marginLeft;
          stepsObj['conditionHierarchy'] = Number(object?.conditionHierarchy);
        }
        if (!['Break', 'Continue'].includes(data.nlpName)) {
          setCheckPointForFailed(stepsObj);
        }
        if (isEmptyValue(props?.scripts?.steps)) {
          props.scripts.steps = [stepsObj]; //for first step
        } else {
          if (props.addStepAtIndex) {
            props.scripts.steps.splice(props.addStepAtIndex, 0, stepsObj); //for new step at an index
          } else {
            props.scripts.steps.push(stepsObj); //for new step
          }
        }
      }
      //Add and Update Step Call
      try {
        const payload = { ...props.scripts, steps: [stepsObj] };
        const response = await updateScriptReq(moduleId, scriptId, props.edit ? 'UPDATE' : 'ADD', payload);
        if (response?.data?.responseObject) {
          props.updateStepsDataOnAdd(response.data.responseObject);
          props.MyAlert.success(response.data.message);
          setStepsEdit(0);
        } else if (response.data && response.data.responseCode === 400) {
          props.MyAlert.warning(`${response.data.status}`);
          props.closeModal(true);
        } else if (response.data.responseCode === 302) {
          props.MyAlert.success(`${response.data.message}`);
          props.closeModal(true);
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  const setStaticValue = async () => {
    for (let index = 0; index < stepsDataObject?.stepInputs?.length; index++) {
      const { name: stepInputName, type: stepInputType, folderOrigin } = stepsDataObject?.stepInputs[index] || {};
      if (
        !['element', 'elementType', 'elementName'].includes(stepInputName) &&
        !(stepInputType.includes('com.tyss.optimize.nlp.util') || folderOrigin)
      ) {
        let elementName = stepsDataObject?.stepInputs[index].name;
        if (document.getElementById(elementName)) {
          let elementValue = document.getElementById(elementName).value;
          stepsDataObject.stepInputs[index].value = elementValue;
        }
      }
    }
  };

  const addLocators = async (stepsObj) => {
    /****************add dynamic locators value to elementdetails*************/
    Object.keys(elementDetails).forEach((treeEleDet) => {
      stepsObj.elementDetails.forEach((element) => {
        if (element.name === elementDetails[treeEleDet].name && element.stepInputName === treeEleDet) {
          element.locators.forEach((locator) => {
            let tempLocator = elementDetails[treeEleDet].locators.find(
              (eleLocator) => eleLocator.name === locator.name && eleLocator.value === locator.value
            );
            if (tempLocator) {
              locator.dynamicLocatorArray = tempLocator.dynamicLocatorArray;
            }
          });
        }
      });
    });
    return stepsObj;
    /****************add dynamic locators value to elementdetails*************/
  };

  const setIfFailed = (e) => {
    setIfCheckPointIsFailed(e.target.value);
  };

  const createYupSchema = (schema, config) => {
    const { id, validationType, validations = [] } = config;
    if (!yup[validationType]) {
      return schema;
    }
    let validator = yup[validationType]();
    validations.forEach((validation) => {
      const { params, type } = validation;
      if (!validator[type]) {
        return;
      }
      validator = validator[type](...params);
    });
    schema[id] = validator;
    return schema;
  };

  const initizationForFormik = (data, type) => {
    let valuesForInitilization = {};
    let validationfield = [];
    const stepInputs = data.stepInputs;
    if (stepInputs && stepInputs.length > 0) {
      stepInputs.forEach((stepInput) => {
        if (!NLP_AUTO_FILLED_INPUT_EXCLUDE.includes(stepInput?.name)) {
          if (type === 'create') {
            valuesForInitilization[stepInput.name] = '';
            if (stepInput?.value && stepInput?.reference) {
              valuesForInitilization[stepInput.name] = stepInput.value;
            }
          } else if (type === 'update') {
            if (
              ['element', 'toElement'].includes(stepInput.name) ||
              'org.openqa.selenium.WebElement' === stepInput.type
            ) {
              let value;
              if ('org.openqa.selenium.WebElement' === stepInput.type) {
                value = stepInput?.value;
                if (value) {
                  value = value.slice(value.indexOf(':') + 1, value.length);
                  value =
                    ':' +
                    data.elementDetails?.find((element) => {
                      if (element.elementId === value) {
                        element['stepInputName'] = stepInput.name;
                        return true;
                      }
                      return false;
                    })?.name;
                }
              }
              if (value) {
                value = value.slice(value.indexOf(':') + 1, value.length);
                valuesForInitilization[stepInput.name] = value;
              } else {
                valuesForInitilization[stepInput.name] = '';
              }
            } else if (stepInput.value) {
              if (['TESTDATA'].includes(stepInput?.fileType)) {
                valuesForInitilization[stepInput.name] = stepInput?.value;
              } else {
                valuesForInitilization[stepInput.name] = stepInput.value;
              }
            } else {
              valuesForInitilization[stepInput.name] = '';
            }
          }
          let obj = {
            id: stepInput.name,
            type: 'text',
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: [`${stepInput.name} is required`],
              },
            ],
          };
          validationfield.push(obj);
        }
      });
    }
    //return value is not required for start and end iteration
    if (!['StartIteration', 'EndIteration'].includes(data.nlpName)) {
      if (data.returnType && data.returnType !== 'void' && type === 'create') {
        valuesForInitilization['returnValue'] = '';
      } else if (data.stepReferenceInfo && type === 'update') {
        let stepReferenceInfo = data.stepReferenceInfo;
        setReturnTypeReference(stepReferenceInfo.type);
        valuesForInitilization['returnValue'] = stepReferenceInfo.name;
      } else if (data.returnType && data.returnType !== 'void' && type === 'update') {
        valuesForInitilization['returnValue'] = '';
      }
      if (data.returnType && data.returnType !== 'void') {
        const validationObj = {
          id: 'returnValue',
          type: 'text',
          validationType: 'string',
          validations: [
            {
              type: 'required',
              params: ['Return Value is required'],
            },
          ],
        };
        validationfield.push(validationObj);
      }
    }
    // validation schema for dynamic element locators field
    if (type === 'update' && data?.elementDetails?.length) {
      let tempElementDetails = {};
      data.elementDetails.forEach((element, elementIndex) => {
        if (element?.locators?.length) {
          element.locators.forEach((locator, locatorIndex) => {
            if (['dynamic', 'Dynamic']?.includes(locator.type) && locator?.dynamicLocatorArray?.length) {
              tempElementDetails[element.stepInputName] = JSON.parse(JSON.stringify(element));
              locator.dynamicLocatorArray.forEach((dynamicLocator, dynamicLocatorIndex) => {
                valuesForInitilization[`${element.stepInputName}dynamic${locatorIndex}${dynamicLocatorIndex}`] =
                  dynamicLocator.value;
                validationfield.push({
                  id: `${element.stepInputName}dynamic${locatorIndex}${dynamicLocatorIndex}`,
                  type: 'text',
                  validationType: 'string',
                  validations: [
                    {
                      type: 'required',
                      params: [`Value is required`],
                    },
                  ],
                });
              });
            }
          });
        }
      });

      setElementPlatForm(tempElementDetails?.element?.platform);
      setElementDetails(tempElementDetails);
    }
    if (valuesForInitilization && validationfield.length > 0) {
      setValidationField(validationfield);
      setInitialValuesField(valuesForInitilization);
      const yepSchema = validationfield.reduce(createYupSchema, {});
      setInitialValues(valuesForInitilization);
      setValidationSchema(yup.object().shape(yepSchema));
    } else {
      setInitialValues({});
      setValidationSchema({});
    }
  };

  const navigateToStepGroupPage = () => {
    const { name, id, parentId, defaultStepGroup, path, searchKey } = stepGroup || {};
    const nameArray = path?.split('/')?.slice(1) || [];
    const searchKeyArray = searchKey?.split('/')?.slice(1) || [];
    const { json } = resourceNavigation({ searchKeyArray, nameArray, isTestDev: false, rootName: 'Root Library' });
    json.splice(0, 1);
    localStorage.setItem('parentAndSonJson', JSON.stringify(json));
    history.push(
      `/repository/step_group/${getUrlPath(
        json
      )}Steps?id=${id}&parentId=${parentId}&stepGroup=${name}&defaultStepGroup=${defaultStepGroup}`
    );
    if (props.type === 'Step Group') {
      props.reload(true);
      setStepsEdit(0);
      props.closeModal(true);
    }
  };

  let returnTouchedField = (values) => {
    let touchedField = {};
    for (var key in values) {
      if (values.hasOwnProperty(key)) {
        if (!values[key]) {
          touchedField[key] = true;
        }
      }
    }
    return touchedField;
  };

  /**
   * Method to return parent dataprovider step if any
   * @returns object
   */
  const getParentDataProviderStep = () => {
    if (props.parentStartDataproviderStep) {
      return props.parentStartDataproviderStep;
    } else if (props.data.isIterationStep || props.data?.isForLoopStep || props.data?.dataProviderVariables) {
      if (props.data.parentSpecialNlpId) {
        const _parentStartDpStep = props.scripts.steps.find(
          (step) => step.specialNlpId === props.data.parentSpecialNlpId
        );
        if (_parentStartDpStep) return _parentStartDpStep;
        else return '';
      }
    } else if (props.edit && (props.editData?.isIterationStep || props.editData?.isForLoopStep)) {
      if (props.editData.parentSpecialNlpId) {
        const _parentStartDpStep = props.scripts.steps.find(
          (step) => step.specialNlpId === props.editData.parentSpecialNlpId
        );
        if (_parentStartDpStep) return _parentStartDpStep;
        else return '';
      }
    }
    return '';
  };

  /**
   *
   * @param {*} stepType | type of iteration step
   */
  const saveStartEndIteration = async (stepType) => {
    let scriptsObject = JSON.parse(JSON.stringify(props.scripts));
    let parentDataProviderVariables = [];
    let parentForLoopVariables = [];
    let parentDPStep;
    if (props.parentSpecialNlpId) {
      parentDPStep = getParentDataProviderStep();
      if (parentDPStep?.dataProviderVariables?.length) {
        parentDataProviderVariables = parentDPStep.dataProviderVariables;
      }
      if (parentDPStep?.forLoopVariables?.length) {
        parentForLoopVariables = parentDPStep.forLoopVariables;
      }
      if (parentDPStep?.nlpName === 'StartForLoop') {
        if (parentDPStep?.forLoopVariables?.length) {
          parentForLoopVariables = parentDPStep?.forLoopVariables;
        } else {
          parentForLoopVariables = [
            {
              name: 'for:initialValue',
              reference: 'FORLOOP',
              value: '',
              type: 'string',
            },
          ];
        }
      }
    }
    const _commonId_forIteration = Math.floor(Math.random() * (999 - 100 + 1) + 100);
    let startIteration_stepsObj = {
      name: stepsDataObject?.displayName,
      nlpName: stepsDataObject?.nlpName,
      nlpId: props?.editData?.nlpId || stepType?.nlpId || stepsDataObject?._id,
      passMessage: data?.passMessage,
      failMessage: data?.failMessage,
      actualFailedResult: stepsDataObject?.actualFailedResult,
      type: '_startiteration',
      returnType: stepsDataObject?.returnType,
      stepInputs: setReferenceToStepInputs(stepsDataObject?.stepInputs),
      stepReferenceInfo: stepsDataObject?.stepReferenceInfo,
      elementDetails: stepsDataObject?.elementDetails,
      toolTip: stepsDataObject?.toolTip,
      searchName: stepsDataObject?.searchName,
      displayName: stepsDataObject?.displayName,
      executionOrder: props?.editData?.executionOrder || stepType?.executionOrder || props?.executionOrder,
      defaultDisplayName: stepsDataObject?.defaultDisplayName,
      defaultToolTip: stepsDataObject?.defaultToolTip,
      stepId: props?.editData?.stepId || stepType?.stepId || getStepId(),
      specialNlpId: props?.editData?.specialNlpId || stepType?.specialNlpId || 'itr_' + _commonId_forIteration,
      isSpecialNlp: true,
      marginLeft: props?.editData?.marginLeft || stepType?.marginLeft || 0.0,
      skip: props?.editData?.skip || stepType?.skip || false,
      imported: props?.editData?.imported || stepType?.imported || false,
      afterBreakStep: props?.editData?.afterBreakStep || stepType?.afterBreakStep || false,
      afterContinueStep: props?.editData?.afterContinueStep || stepType?.afterContinueStep || false,
    };
    if (selectedDataProviderData) {
      if (parentDataProviderVariables.length) {
        startIteration_stepsObj['dataProviderVariables'] = parentDataProviderVariables.concat(
          selectedDataProviderData.variables
        );
      } else {
        startIteration_stepsObj['dataProviderVariables'] = selectedDataProviderData.variables;
      }
    }

    const tooltipForEndIteration = iterationTooltipBuilder(
      stepsDataObject?.stepInputs[0].value,
      'endIteration',
      'End iteration',
      'End iteration'
    );
    let endIteration_stepsObj = {
      name: tooltipForEndIteration.newDesc,
      nlpId: 'NLP1393',
      stepInputs: [],
      nlpName: 'EndIteration',
      nlpType: 'NLP',
      searchName: 'End iteration',
      actualFailedResult: 'N/A',
      displayName: tooltipForEndIteration.newDesc,
      toolTip: tooltipForEndIteration.newToolTip,
      description: 'This nlp is used to end iteration',
      returnType: 'Void',
      passMessage: '',
      failMessage: '',
      platform: 'Web',
      stepId: getStepId(),
      type: '_enditeration',
      specialNlpId: 'itr_' + _commonId_forIteration,
      isSpecialNlp: true,
      marginLeft: props?.editData?.marginLeft || stepType?.marginLeft || 0.0,
      executionOrder: startIteration_stepsObj.executionOrder + 1,
    };

    if (props.parentSpecialNlpId) {
      startIteration_stepsObj['parentSpecialNlpId'] = endIteration_stepsObj['parentSpecialNlpId'] =
        props.parentSpecialNlpId;
    }

    startIteration_stepsObj['marginLeft'] = getIterationMarginLeft(props);
    endIteration_stepsObj['marginLeft'] = getIterationMarginLeft(props);
    if (parentForLoopVariables) {
      startIteration_stepsObj['forLoopVariables'] = endIteration_stepsObj['forLoopVariables'] = parentForLoopVariables;
    }
    if (props?.parentStartIfStep) {
      startIteration_stepsObj['conditionId'] = props.parentStartIfStep?.conditionId;
      endIteration_stepsObj['conditionId'] = props.parentStartIfStep?.conditionId;
    }
    if ('update' === props.mode) {
      const startIteStep = {
        ...stepData,
        name: startIteration_stepsObj.name,
        displayName: startIteration_stepsObj.displayName,
        toolTip: startIteration_stepsObj.toolTip,
        dataProviderVariables: startIteration_stepsObj?.dataProviderVariables,
      };
      const endIteStep = {
        name: endIteration_stepsObj.name,
        displayName: endIteration_stepsObj.displayName,
        toolTip: endIteration_stepsObj.toolTip,
      };
      for (let i = props.selectedStepIndex + 1; i < scriptsObject.steps.length; i++) {
        if (scriptsObject.steps[i].specialNlpId === startIteStep.specialNlpId) {
          scriptsObject.steps.splice(props.selectedStepIndex, 1, startIteStep);
          scriptsObject.steps.splice(i, 1, { ...scriptsObject.steps[i], ...endIteStep });
          break;
        }
      }

      let UpdateEndItreationDPName = scriptsObject.steps.filter(
        (step) => step.specialNlpId === stepType?.specialNlpId && step.type === '_enditeration'
      );

      scriptsObject.steps = [startIteration_stepsObj, ...UpdateEndItreationDPName]; // to update DataProvider Name & Values
    } else if ('replace' === props.mode) {
      scriptsObject.steps.splice(props.addStepAtIndex, 0, startIteration_stepsObj);
      endIteration_stepsObj['executionOrder'] = getExecutionOrder(props.addStepAtIndex + 1, scriptsObject.steps); // NOTE :: create executionOrder if step add at index position fro Normal to special Nlp
    } else {
      if (!scriptsObject?.steps?.length) {
        // if steps are empty
        scriptsObject['steps'] = [{ ...startIteration_stepsObj }, { ...endIteration_stepsObj }];
      } else {
        if (props.addStepAtIndex >= 0) {
          // if step add between tow steps
          scriptsObject.steps.splice(props.addStepAtIndex, 0, startIteration_stepsObj);
          endIteration_stepsObj.executionOrder = props.getExecutionOrder(props.addStepAtIndex, scriptsObject.steps);
          scriptsObject.steps = [startIteration_stepsObj, endIteration_stepsObj];
        } else {
          scriptsObject.steps = [startIteration_stepsObj, endIteration_stepsObj]; //for new step add default in last
        }
      }
    }
    try {
      let action = props?.edit ? 'UPDATE' : 'ADD';
      let deleteResponse;
      if (props.mode === 'replace') {
        action = 'ADD';
        scriptsObject.steps = [startIteration_stepsObj, endIteration_stepsObj];
        const deleteStepData = {
          stepId: props.editData.stepId,
          name: props.editData.name,
          elementDetails: props.editData?.elementDetails || [],
        };
        deleteResponse = await updateScriptReq(moduleId, scriptId, 'DELETE', {
          ...scriptsObject,
          steps: [deleteStepData],
        });
      }

      const response = await updateScriptReq(moduleId, scriptId, action, scriptsObject);
      if (response?.data?.responseObject) {
        props.updateStepsDataOnAdd(response?.data?.responseObject);
        setStepsEdit(0);
      } else if (response.data && response.data.responseCode === 400) {
        props.MyAlert.warning(`${response.data.status}`);
        props.closeModal(true);
      }
    } catch (err) {
      console.error('UPDATE_SCRIPT | ITERATION :', err);
    }
  };

  const getExecutionOrder = (index, data) => {
    if (index === data.length - 1) {
      return Number(data[index].executionOrder) + 1;
    } else {
      return (Number(data[index]?.executionOrder) + Number(data[index + 1]?.executionOrder)) / 2;
    }
  };

  const getIterationMarginLeft = (props) => {
    if (props?.parentStartIfStep?.executionOrder && props.parentStartDataproviderStep?.executionOrder) {
      if (props?.parentStartIfStep?.executionOrder < props?.parentStartDataproviderStep?.executionOrder) {
        if (
          endConditionObject.includes(props?.previousStepData.nlpName) ||
          endNlpList.includes(props?.previousStepData.nlpName)
        ) {
          return Number(props?.previousStepData?.marginLeft);
        } else {
          return Number(props?.parentStartDataproviderStep?.marginLeft) + 1.7;
        }
      } else {
        return Number(props?.parentStartIfStep?.marginLeft) + 1.7;
      }
    } else if (props.parentStartDataproviderStep?.marginLeft > -1) {
      if (props?.parentSpecialNlpId) {
        return Number(props?.parentStartDataproviderStep?.marginLeft) + 1.7;
      } else if (props?.previousStepData?.marginLeft > 0) {
        return Number(props?.previousStepData?.marginLeft);
      }
      else {
        return Number(0);
      }
    } else if (props?.parentStartIfStep?.marginLeft > -1) {
      if (
        endConditionObject.includes(props?.previousStepData.nlpName) ||
        endNlpList.includes(props?.previousStepData.nlpName)
      ) {
        return Number(props?.parentStartIfStep?.marginLeft);
      } else {
        return Number(props?.parentStartIfStep?.marginLeft) + 1.7;
      }
    } else if (!props.parentStartDataproviderStep?.marginLeft) {
      return Number(0);
    } else if (!props?.parentStartIfStep?.marginLeft > -1) {
      return Number(0);
    }
  };

  /**
   *
   * @param {*} stepType | type of iteration step
   */
  const saveStartEndForLoop = async (stepType) => {
    let scriptsObject = JSON.parse(JSON.stringify(props.scripts));
    let parentDataProviderVariables = [];
    let parentDPStep;
    if (props.parentSpecialNlpId) {
      parentDPStep = getParentDataProviderStep();
      if (parentDPStep?.dataProviderVariables?.length) {
        parentDataProviderVariables = parentDPStep.dataProviderVariables;
      }
    }

    const _commonId_forIteration = Math.floor(Math.random() * (999 - 100 + 1) + 100);
    let returnstepExeId;
    let returnTypeStepNo;
    if (scriptsObject?.returnType !== 'void' && props?.stepGroup) {
      let retrurnType = scriptsObject?.returnType?.split('Step');
      returnTypeStepNo = Math.floor(retrurnType?.[retrurnType?.length - 1]);
      returnstepExeId = scriptsObject.steps?.[returnTypeStepNo - 1]?.executionOrder;
    }
    let startFor_stepsObj = {
      name: stepsDataObject?.displayName,
      nlpName: stepsDataObject?.nlpName,
      nlpId: stepType?.nlpId || stepsDataObject?._id,
      passMessage: data?.passMessage,
      failMessage: data?.failMessage,
      type: '_startforloop',
      returnType: stepsDataObject?.returnType,
      stepInputs: setReferenceToStepInputs(stepsDataObject?.stepInputs),
      stepReferenceInfo: stepsDataObject?.stepReferenceInfo,
      elementDetails: stepsDataObject?.elementDetails,
      toolTip: stepsDataObject?.toolTip,
      searchName: stepsDataObject?.searchName,
      displayName: stepsDataObject?.displayName,
      executionOrder: props?.editData?.executionOrder || stepType?.executionOrder || props?.executionOrder,
      defaultDisplayName: stepsDataObject?.defaultDisplayName,
      defaultToolTip: stepsDataObject?.defaultToolTip,
      stepId: props?.editData?.stepId || stepType?.stepId || getStepId(),
      specialNlpId: props?.editData?.specialNlpId || stepType?.specialNlpId || 'forloop_' + _commonId_forIteration,
      isSpecialNlp: true,
      marginLeft: props?.editData?.marginLeft || stepType?.marginLeft || 0.0,
      imported: props?.editData?.imported || stepType?.imported || false,
      afterBreakStep: props?.editData?.afterBreakStep || stepType?.afterBreakStep || false,
      afterContinueStep: props?.editData?.afterContinueStep || stepType?.afterContinueStep || false,
    };
    setCheckPointForFailed(startFor_stepsObj);

    if (props.parentStartDataproviderStep) {
      if (parentDataProviderVariables.length) {
        startFor_stepsObj['dataProviderVariables'] = parentDataProviderVariables;
      } else {
        startFor_stepsObj['dataProviderVariables'] = props.parentStartDataproviderStep?.dataProviderVariables;
      }
    }
    let endFor_stepsObj = {
      name: 'End for loop',
      nlpId: 'NLP1702',
      stepInputs: [],
      nlpName: 'EndForLoop',
      nlpType: 'NLP',
      searchName: 'End for loop',
      displayName: 'End for loop',
      toolTip: 'End for loop',
      description: 'This NLP is used to end for loop',
      returnType: 'Void',
      passMessage: 'End for loop passed',
      failMessage: 'End for loop failed',
      platform: 'Web',
      stepId: getStepId(),
      type: '_endforloop',
      specialNlpId: 'forloop_' + _commonId_forIteration,
      isSpecialNlp: true,
      marginLeft: props?.editData?.marginLeft || stepType?.marginLeft || 0.0,
      executionOrder: startFor_stepsObj.executionOrder + 1,
    };
    if (props.parentSpecialNlpId) {
      startFor_stepsObj['parentSpecialNlpId'] = props.parentSpecialNlpId;
      endFor_stepsObj['parentSpecialNlpId'] = props.parentSpecialNlpId;
    }
    startFor_stepsObj['marginLeft'] = getIterationMarginLeft(props);
    endFor_stepsObj['marginLeft'] = getIterationMarginLeft(props);

    if (props?.parentStartIfStep) {
      startFor_stepsObj['conditionId'] = props.parentStartIfStep?.conditionId;
      endFor_stepsObj['conditionId'] = props.parentStartIfStep?.conditionId;
    }

    if ('update' === props.mode) {
      const startForStep = {
        ...stepData,
        name: startFor_stepsObj.name,
        displayName: startFor_stepsObj.displayName,
        toolTip: startFor_stepsObj.toolTip,
      };
      const endForStep = {
        name: endFor_stepsObj.name,
        displayName: endFor_stepsObj.displayName,
        toolTip: endFor_stepsObj.toolTip,
      };
      for (let i = props.selectedStepIndex + 1; i < scriptsObject.steps.length; i++) {
        if (scriptsObject.steps[i].specialNlpId === startForStep.specialNlpId) {
          scriptsObject.steps.splice(props.selectedStepIndex, 1, startForStep);
          scriptsObject.steps.splice(i, 1, { ...scriptsObject.steps[i], ...endForStep });
          break;
        }
      }

      scriptsObject.steps = [startFor_stepsObj];
    } else if ('replace' === props.mode) {
      scriptsObject.steps.splice(props.selectedStepIndex, 0, startFor_stepsObj);
      endFor_stepsObj['executionOrder'] = getExecutionOrder(props.addStepAtIndex + 1, scriptsObject.steps); // NOTE :: create executionOrder if step add at index position fro Normal to special Nlp
    } else {
      if (!scriptsObject?.steps?.length) {
        scriptsObject['steps'] = [{ ...startFor_stepsObj }, { ...endFor_stepsObj }];
      } else {
        if (props.addStepAtIndex >= 0) {
          scriptsObject.steps.splice(props.addStepAtIndex, 0, startFor_stepsObj);
          endFor_stepsObj.executionOrder = props.getExecutionOrder(props.addStepAtIndex, scriptsObject.steps);
          scriptsObject.steps = [startFor_stepsObj, endFor_stepsObj];
        } else {
          scriptsObject.steps = [startFor_stepsObj, endFor_stepsObj]; //for new step
        }
      }
    }
    let action = props?.edit ? 'UPDATE' : 'ADD';
    let deleteResponse;
    if (props.mode === 'replace') {
      action = 'ADD';
      scriptsObject.steps = [startFor_stepsObj, endFor_stepsObj];
      const deleteStepData = {
        stepId: props.editData.stepId,
        name: props.editData.name,
        elementDetails: props.editData?.elementDetails || [],
      };
      deleteResponse = props?.stepGroup
        ? updateStepGroupReq(moduleId, scriptId, 'DELETE', { ...scriptsObject, steps: [deleteStepData] })
        : await updateScriptReq(moduleId, scriptId, 'DELETE', { ...scriptsObject, steps: [deleteStepData] });
    }

    try {
      let response;
      if (props?.stepGroup) {
        if (action === 'ADD' && scriptsObject?.steps?.[0]?.executionOrder < returnstepExeId) {
          let retrurnType = scriptsObject?.returnType?.split('Step');
          retrurnType[retrurnType?.length - 1] = scriptsObject?.steps.length;
          scriptsObject.returnType = retrurnType.join('Step');
        } else if (scriptsObject?.steps?.[0]?.executionOrder === returnstepExeId) {
          scriptsObject.returnType = 'void';
        }
        response = await updateStepGroupReq(moduleId, scriptId, action, scriptsObject);
      } else {
        response = await updateScriptReq(moduleId, scriptId, action, scriptsObject);
      }
      if (response?.data?.responseObject) {
        props.updateStepsDataOnAdd(response?.data?.responseObject);
        setStepsEdit(0);
      } else {
        if (response?.data?.responseCode === 400) {
          props.MyAlert.warning(`${response.data.status}`);
          props.closeModal(true);
        }
        if (response?.data?.responseCode === 404) {
          props.MyAlert.success(`${props.stepGroup.name} script updated successfully`);
          props.closeModal(true);
          props.reload(true);
        }
        if (response?.data?.message === 'ForLoop:Invalid_input3') {
          const _currentForm = formRef.current;
          _currentForm.setFieldError('input3', 'Input must be a variable or greater than zero');
          setCreateUpdateCalled(true);
        }
      }
    } catch (err) {
      console.error('UPDATE_SCRIPT | ITERATION :', err);
    }
  };

  const saveCondition = async (stepObj) => {
    if (!stepObj) {
      stepObj = {};
    }
    let parentDataProviderVariables = [];
    let parentForLoopVariables = [];
    let parentDPStep;
    if (props.parentSpecialNlpId) {
      parentDPStep = getParentDataProviderStep();
      if (parentDPStep?.dataProviderVariables?.length) {
        parentDataProviderVariables = parentDPStep.dataProviderVariables;
      }
      if (parentDPStep?.forLoopVariables?.length) {
        parentForLoopVariables = parentDPStep?.forLoopVariables;
      }
    }
    let conditionId = stepObj.conditionId || getUniqueId('CON');
    let object = evaluateMarginLeft();
    let conditionSearchKey = conditionId;
    let marginLeft = object?.marginLeft;
    let conditionHierarchy = object?.conditionHierarchy;
    if (marginLeft !== 0) {
      conditionHierarchy = Number(conditionHierarchy + 1);
    } else {
      conditionHierarchy = Number(1);
    }
    if ((marginLeft > 0 || stepObj?.nlpName !== 'IfCondition') && object?.conditionSearchKey) {
      conditionSearchKey = object?.conditionSearchKey + '/' + conditionId;
    }
    let conditionObj = {
      name: stepObj.displayName,
      nlpName: stepObj.nlpName,
      nlpId: stepObj._id,
      passMessage: stepObj.passMessage,
      failMessage: stepObj.failMessage,
      actualFailedResult: stepObj?.actualFailedResult,
      type: 'step',
      returnType: stepObj.returnType,
      stepInputs: setReferenceToStepInputs(stepObj.stepInputs),
      stepReferenceInfo: stepObj.stepReferenceInfo,
      elementDetails: stepObj.elementDetails,
      toolTip: stepObj.toolTip,
      searchName: stepObj.searchName,
      displayName: stepObj.displayName,
      executionOrder: props.editData?.executionOrder || stepObj.executionOrder || props.executionOrder,
      defaultDisplayName: stepObj.defaultDisplayName,
      defaultToolTip: stepObj.defaultToolTip,
      stepId: stepObj.stepId || getStepId(),
      conditionId: conditionId,
      conditionSearchKey: conditionSearchKey,
      marginLeft: props.editData?.marginLeft || props.data?.marginLeft || stepObj?.marginLeft || marginLeft,
      conditionHierarchy: conditionHierarchy,
      isSpecialNlp: true,
    };
    setCheckPointForFailed(conditionObj);
    let endConditionObject = {};
    let conditionNlpObject = props?.conditionNlpObject;
    if (conditionNlpObject && conditionNlpObject['End' + stepObj.nlpName]) {
      endConditionObject = conditionNlpObject['End' + stepObj.nlpName];
      endConditionObject['type'] = 'step';
      endConditionObject['actualFailedResult'] = 'N/A';
      endConditionObject['stepId'] = getStepId();
      endConditionObject['marginLeft'] = props.editData?.marginLeft || marginLeft || 0;
      endConditionObject['name'] = endConditionObject['displayName'];
      endConditionObject['conditionHierarchy'] = conditionHierarchy;
      endConditionObject['conditionId'] = conditionId;
      endConditionObject['conditionSearchKey'] = conditionSearchKey;
      endConditionObject['executionOrder'] = conditionObj.executionOrder + 1;
    }

    if (props.mode === 'replace') {
      props.scripts.steps.splice(props.addStepAtIndex, 0, conditionObj);
      endConditionObject['executionOrder'] = getExecutionOrder(props.addStepAtIndex + 1, props.scripts.steps); // NOTE :: create executionOrder if step add at index position fro Normal to special Nlp
    }

    //if the step is part of an iteration
    if (isSpecialNlp || parentSpecialNlpId) {
      if (parentSpecialNlpId?.includes('forloop')) {
        conditionObj['isForLoopStep'] = true;
        endConditionObject['isForLoopStep'] = true;
      } else {
        conditionObj['isIterationStep'] = true;
        endConditionObject['isIterationStep'] = true;
      }
      conditionObj['parentSpecialNlpId'] = props?.parentSpecialNlpId;
      endConditionObject['parentSpecialNlpId'] = props?.parentSpecialNlpId;
    }

    if (parentDataProviderVariables) {
      conditionObj['dataProviderVariables'] = endConditionObject['dataProviderVariables'] = parentDataProviderVariables;
    }
    if (parentForLoopVariables) {
      conditionObj['forLoopVariables'] = endConditionObject['forLoopVariables'] = parentForLoopVariables;
    }
    setCheckPointForFailed(endConditionObject);
    if (parentDPStep?.type === '_startforloop') {
      const _forLoopVarObj = {
        name: 'for:initialValue',
        reference: 'FORLOOP',
        value: '',
        type: 'string',
        parentSpecialNlpId: props?.parentSpecialNlpId,
      };
      conditionObj['forLoopVariables'] = endConditionObject['forLoopVariables'] = [_forLoopVarObj];
    }
    if (props?.parentStartIfStep?.hasOwnProperty('forLoopVariables')) {
      conditionObj['forLoopVariables'] = endConditionObject['forLoopVariables'] =
        props.parentStartIfStep.forLoopVariables;
    }
    if (props?.parentStartIfStep?.hasOwnProperty('dataProviderVariables')) {
      conditionObj['dataProviderVariables'] = endConditionObject['dataProviderVariables'] =
        props.parentStartIfStep.dataProviderVariables;
    }
    // To handle test development section
    if (isEmptyValue(props?.scripts?.steps) && !props?.stepGroup) {
      props.scripts.steps = [conditionObj, endConditionObject]; // NOTE :: if steps are empty
    } else {
      if (props.edit && !props?.stepGroup) {
        props.scripts.steps = [conditionObj];
      } else if (props.addStepAtIndex && !props?.stepGroup) {
        //NOTE :: if step add between in two steps
        props.scripts.steps.splice(props.addStepAtIndex, 0, conditionObj);
        endConditionObject.executionOrder = getExecutionOrder(props.addStepAtIndex, props.scripts.steps); // NOTE :: create executionOrder if step add at index position
        props.scripts.steps = [conditionObj, endConditionObject];
      } else if (!props?.stepGroup) {
        props.scripts.steps = [conditionObj, endConditionObject]; //NOTE:: if steps not empty and not atIndex position add at last default
      }
    }
    // TO handle repository section
    if (props?.stepGroup) {
      if (isEmptyValue(props?.stepGroup?.steps)) {
        props.stepGroup.steps = [conditionObj];
        props.stepGroup.steps.push(endConditionObject);
      } else {
        if (props.edit && props?.stepGroup) {
          props.stepGroup.steps = [conditionObj];
        } else if (props.addStepAtIndex && props?.stepGroup) {
          props?.scripts?.steps.splice(props.addStepAtIndex, 0, conditionObj);
          endConditionObject.executionOrder = getExecutionOrder(props?.addStepAtIndex, props?.scripts?.steps); // NOTE :: create executionOrder if step add at index position
          props.stepGroup.steps = [conditionObj, endConditionObject];
        } else if (props?.stepGroup) {
          props.stepGroup.steps = [conditionObj, endConditionObject];
        }
      }
    }

    try {
      const libraryId = new URLSearchParams(search).get('parentId');
      const stepGroupId = new URLSearchParams(search).get('id');
      let action = props.edit ? 'UPDATE' : 'ADD';
      let deleteResponse;
      if (props.mode === 'replace') {
        action = 'ADD';
        props.scripts.steps = [conditionObj, endConditionObject];
        const deleteStepData = {
          stepId: props.editData.stepId,
          name: props.editData.name,
          elementDetails: props.editData?.elementDetails || [],
        };
        deleteResponse = props?.stepGroup
          ? updateStepGroupReq(libraryId, stepGroupId, 'DELETE', { ...props.scripts, steps: [deleteStepData] })
          : await updateScriptReq(moduleId, scriptId, 'DELETE', { ...props.scripts, steps: [deleteStepData] });
      }

      const response =
        props.type === 'Step Group'
          ? await updateStepGroupReq(libraryId, stepGroupId, action, props.stepGroup)
          : await updateScriptReq(moduleId, scriptId, action, props.scripts);
      if (response && response.data && response.data.responseObject) {
        props.updateStepsDataOnAdd(response.data.responseObject);
        setStepsEdit(0);
      } else if (response.data && response.data.responseCode === 400) {
        props.MyAlert.warning(`${response.data.status}`);
        props.closeModal(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEscapeKeyCloseModal(true, () => props.closeModal(true));

  const evaluateMarginLeft = () => {
    let previousData = {};
    let returnObject = {};
    const resource = props?.scripts?.steps;
    if (resource && resource?.length) {
      let steps = resource;
      let stepLength = resource?.length - 1;
      if (props.addStepAtIndex) {
        stepLength = props?.addStepAtIndex - 1;
      }
      if (steps[stepLength]) {
        previousData = steps[stepLength];
        returnObject['conditionHierarchy'] = previousData?.conditionHierarchy;
        if (previousData?.conditionId) {
          returnObject['conditionId'] = previousData?.conditionId;
        }
        if (previousData?.conditionSearchKey) {
          returnObject['conditionSearchKey'] = previousData?.conditionSearchKey;
        }
        if ([...startConditionObject, ...startNlpList].includes(previousData?.nlpName)) {
          if (previousData.hasOwnProperty('marginLeft')) {
            returnObject['marginLeft'] = Number(previousData?.marginLeft + 1.7);
            return returnObject;
          }
        } else if (endConditionObject.includes(previousData?.nlpName) && previousData?.nlpName !== 'EndIfCondition') {
          if (previousData?.conditionSearchKey && previousData?.conditionSearchKey.includes('/')) {
            let index = previousData?.conditionSearchKey.lastIndexOf('/');
            returnObject['conditionSearchKey'] = previousData?.conditionSearchKey.substring(0, index);
          }
        }
      }
      returnObject['marginLeft'] = Number(previousData?.marginLeft);
      return returnObject;
    } else {
      returnObject['marginLeft'] = Number(0);
      return returnObject;
    }
  };

  const getInputParameterDivHeader = () => {
    const getPlatformIcon = () => {
      let platformIcon = <></>;
      if ([props?.scripts?.scriptType, props?.stepGroup?.type].includes('Web & Mobile')) {
        if (stepData?.platform === 'Web') {
          platformIcon = <Web className={'inline-block h-9 w-9'} />;
        } else if (stepData?.platform === 'iOS') {
          platformIcon = <Ios className={'inline-block h-9 w-9'} />;
        } else if (stepData?.platform === 'Android') {
          platformIcon = <Android className={'inline-block h-11 w-9'} />;
        }
      }
      return platformIcon;
    };
    if (stepData?.nlpType === 'STEP_GROUP' || stepData?.type === 'Group') {
      return <span>Step Group Input Parameters</span>;
    } else if (stepData?.nlpType === 'PROGRAM_ELEMENTS' || stepData?.nlpId?.includes('PE_NLP')) {
      return <span>Program Element Input Parameters</span>;
    } else {
      return <span>NLP Input Parameters {getPlatformIcon()}</span>;
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setElementDetails({});
    const getStepData = () => {
      if (['create', 'replace'].includes(props.mode)) {
        return JSON.parse(JSON.stringify(props.stepsDataObject));
      } else {
        return JSON.parse(JSON.stringify(props.data));
      }
    };
    const getOldStepData = () => {
      if ('replace' === props.mode) {
        return JSON.parse(JSON.stringify(props.editData));
      } else if ('update' === props.mode) {
        return JSON.parse(JSON.stringify(props.stepsDataObject));
      } else {
        return {};
      }
    };
    const stepDataCurrentValue = getStepData();
    const stepDataOldValue = getOldStepData();
    if (props.type === 'Step Group') {
      setIfFaiedArray([...stepGroupIfFailedCheckpoints]);
      getStepGroupParameter();
      getStepGroupVariable();
    } else {
      setIfFaiedArray([...scriptIfFailedCheckpoints]);
      getAllLocalVariables();
    }
    getGlobalVariable();
    getProjectEnvironmentVariable();
    getDataProviderVariableData();

    if (stepDataCurrentValue?.nlpType === 'STEP_GROUP' || stepDataCurrentValue?.type === 'Group') {
      const libraryId = stepDataCurrentValue?.libraryId;
      const stepGroupId =
        stepDataCurrentValue?.nlpType === 'STEP_GROUP'
          ? stepDataCurrentValue?.parentId
          : stepDataCurrentValue?.stepGroupId;
      getStepGroup(libraryId, stepGroupId);
    }

    if (['update', 'replace'].includes(props.mode)) {
      const stepInputs = props.mode === 'update' ? props.data.stepInputs : props.editData.stepInputs;
      stepInputs.forEach((data) => {
        if (data.name === 'ifCheckPointIsFailed') {
          if (props.type === 'Step Group') {
            const checkPointTemp = data.value.replace(/_/g, ' ').toLowerCase();
            if (!checkPointTemp.includes('step group execution')) {
              setIfFaiedArray([
                {
                  name: `M${checkPointTemp.slice(1, 18)}${checkPointTemp[18].toUpperCase()}${checkPointTemp.slice(19)}`,
                  value: data.value,
                },
                ...stepGroupIfFailedCheckpoints,
              ]);
            }
          }
          setIfCheckPointIsFailed(data.value);
        }
      });
      initizationForFormik(stepDataCurrentValue, 'update');
    } else {
      initizationForFormik(stepDataCurrentValue, 'create');
    }
    if (['update', 'replace'].includes(props.mode)) {
      setData(props.data);
      setStepIndex(props.orderOfInsertion - 1);
    }
    setStepData(stepDataCurrentValue);
    setOldStepData(stepDataOldValue);
    setStepsDataObject(stepDataCurrentValue);
    setIsLoading(false);
  }, [props.data, props.stepsDataObject, props.mode]);

  const handleInitValueChange = (e, data, _mode) => {
    let updateValue = '';
    let _reference = null;
    const _currentForm = formRef.current;
    if (['path-select', 'variable-select']?.includes(_mode)) {
      updateValue = e?.name;
      _reference = e?.reference ? e?.reference : e?.type;
    } else {
      _currentForm.handleChange(e);
      const val = e?.target?.value;
      updateValue = val;
    }
    _currentForm.values['input1'] = updateValue;
    _currentForm.values['operand1'] = updateValue;
    _currentForm.values['initialValue'] = updateValue;
    setVariableValue(updateValue, 'input1', _reference);
    setVariableValue(updateValue, 'operand1', _reference);
    _currentForm.setFieldValue('input1', updateValue);
    _currentForm.setFieldValue('operand1', updateValue);
  };

  const handleForStepInputDDChange = (e, stepInputName, stepInputObjects) => {
    const _currentForm = formRef.current;
    _currentForm.handleChange(e);
    setVariableValue(e.target.value, stepInputName);
    if (stepInputName === 'operator') stepInputObjects.condition.operator.value = e.target.value;
    if (stepInputName === 'input2') stepInputObjects.incrDecr.input2.value = e.target.value;
  };

  const forConditionRendrer = (data, handleChange, handleBlur, setFieldValue, values, errors, touched) => {
    const stepInputObjects = { initialization: {}, condition: {}, incrDecr: {} };
    data.stepInputs.forEach((input) => {
      if (input.name === 'initialValue') {
        stepInputObjects.initialization[input.name] = input;
      } else if (['operand1', 'operand2', 'operator'].includes(input.name)) {
        stepInputObjects.condition[input.name] = input;
      } else if (['input1', 'input2', 'input3'].includes(input.name)) {
        stepInputObjects.incrDecr[input.name] = input;
      }
    });
    const { name, value, type, reference } = stepInputObjects?.initialization?.initialValue || {};
    const { operand2, operator } = stepInputObjects.condition || {};
    const { input2, input3 } = stepInputObjects.incrDecr || {};

    const beforeSetFieldValue = (stepInputName, value) => {
      if (stepInputName === 'initialValue' && value === '') {
        let obj = {
          target: { value: value },
        };
        handleInitValueChange(obj);
      }
      setFieldValue(stepInputName, value);
    };

    return (
      <div className="relative">
        <div className="mt-3">
          <div className="fontPoppinsSemiboldMd">Initialization</div>
          <div className="mt-3 grid grid-cols-2 gap-4">
            <VariableSuggestion
              MyAlert={props.MyAlert}
              reloadStepsTable={onCancelReloadStateHandler}
              identity={'from element'}
              localVariableData={localVariableData}
              moduleId={moduleId}
              scriptId={scriptId}
              defaultStepGroup={defaultStepGroup}
              stepId={value ? data.stepId : ''}
              widthClass={`w-10/12`}
              id={'variables-initialization'}
              stepInputName={name}
              stepInputType={type}
              setVariableValue={setVariableValue}
              editData={value}
              type={props.type}
              reference={reference}
              handleChange={(e) => handleInitValueChange(e, data)}
              onVariableSelect={(e) => handleInitValueChange(e, data, 'variable-select')}
              onPathSelect={(e) => handleInitValueChange(e, data, 'path-select')}
              handleBlur={handleBlur}
              setFieldValue={beforeSetFieldValue}
              parentStartDataproviderStep={getParentDataProviderStep()}
              parentStartIfStep={props?.parentStartIfStep}
            />
          </div>
        </div>
        <div className="mt-3">
          <div className="fontPoppinsSemiboldMd">Condition</div>
          <div className="mt-3 grid grid-cols-3 gap-4 pb-2">
            <div>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                label={
                  <div className="fontPoppinsRegularXlg">
                    {' '}
                    <span className="text-red-400 mr-1">&#42;</span> Operand1
                  </div>
                }
                size="40"
                className="w-9/12"
                type="text"
                autoComplete="off"
                name="operand1"
                id="operand1"
                placeholder={`Operand 1`}
                onBlur={handleBlur}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                value={values.operand1}
                disabled={true}
              />
            </div>
            <div className="-mb-1.5">
              <div htmlFor="operator" className="input-filed-label-style-common mb-0">
                <span className="text-red-400">&#42;</span>
                <span className={`fontPoppinsRegularMd ${errors?.operator && touched?.operator ? 'text-red-400' : ''}`}>
                  Operator
                </span>
              </div>
              <div className="dropdowm">
                <Select
                  inputProps={{
                    color: 'primary',
                  }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                  }}
                  name="operator"
                  id="operator"
                  onChange={(e) => handleForStepInputDDChange(e, 'operator', stepInputObjects)}
                  value={operator?.value}
                  className="text-gray-500 fontPoppinsRegularMd w-4/5"
                >
                  {operatorsList?.map((data, dataIndex) => (
                    <option
                      className="hover:text-blue-700 fontPoppinsRegularSm cursor-pointer hover:bg-blue-100"
                      value={data}
                    >
                      {data}
                    </option>
                  ))}
                </Select>
              </div>
              {touched?.operator && errors?.operator && (
                <div className="errorMessage">
                  {errors?.operator.charAt(0).toUpperCase() + errors?.operator.slice(1).toLowerCase()}
                </div>
              )}
            </div>
            <div>
              <VariableSuggestion
                MyAlert={props.MyAlert}
                reloadStepsTable={onCancelReloadStateHandler}
                identity={'from element'}
                localVariableData={localVariableData}
                moduleId={moduleId}
                scriptId={scriptId}
                defaultStepGroup={defaultStepGroup}
                stepId={operand2?.value ? data.stepId : ''}
                widthClass={`w-9/12`}
                id={'variables-condition-operand2'}
                stepInputName={operand2?.name}
                stepInputType={operand2?.type}
                setVariableValue={setVariableValue}
                editData={operand2?.value}
                type={props.type}
                reference={operand2?.reference}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                parentStartDataproviderStep={getParentDataProviderStep()}
                parentStartIfStep={props?.parentStartIfStep}
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="fontPoppinsSemiboldMd">Increment/Decrement</div>
          <div className="my-3 grid grid-cols-3 gap-3">
            <div>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                label={
                  <div className="fontPoppinsRegularXlg">
                    {' '}
                    <span className="text-red-400 mr-1">&#42;</span> Input 1
                  </div>
                }
                size="40"
                className="w-9/12"
                type="text"
                autoComplete="off"
                name="input1"
                id="input1"
                placeholder={`Input 1`}
                onBlur={handleBlur}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                value={values.input1}
                disabled={true}
              />
            </div>
            <div className="">
              <div htmlFor="input2" className="-mb-1.5 input-filed-label-style-common">
                <span className="text-red-400 mr-1 fontPoppinsRegularXLg">&#42;</span>
                <span className={`fontPoppinsRegularMd ${errors?.input2 && touched?.input2 ? 'text-red-400' : ''}`}>
                  Input 2
                </span>
              </div>
              <div>
                <Select
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                  }}
                  name="input2"
                  id="input2"
                  onChange={(e) => handleForStepInputDDChange(e, 'input2', stepInputObjects)}
                  value={input2?.value}
                  className="text-gray-500 fontPoppinsRegularMd w-4/5"
                >
                  {operationsList?.map((data, dataIndex) => (
                    <option
                      className="hover:text-blue-700 fontPoppinsRegularSm cursor-pointer hover:bg-blue-100"
                      value={data}
                    >
                      {data}
                    </option>
                  ))}
                </Select>
              </div>
              {errors?.input2 && touched?.input2 && (
                <div className="errorMessage">
                  {errors?.input2.charAt(0).toUpperCase() + errors?.input2.slice(1).toLowerCase()}
                </div>
              )}
            </div>
            <div>
              <VariableSuggestion
                MyAlert={props.MyAlert}
                reloadStepsTable={onCancelReloadStateHandler}
                identity={'from element'}
                localVariableData={localVariableData}
                moduleId={moduleId}
                scriptId={scriptId}
                defaultStepGroup={defaultStepGroup}
                stepId={input3?.value ? data.stepId : ''}
                widthClass={`w-9/12`}
                id={'variables-condition-input3'}
                stepInputName={input3?.name}
                stepInputType={input3?.type}
                setVariableValue={setVariableValue}
                editData={input3?.value}
                type={props.type}
                reference={input3?.reference}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                parentStartDataproviderStep={getParentDataProviderStep()}
                parentStartIfStep={props?.parentStartIfStep}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleEscapeClose = () => {
    if (onCancelReloadRequired) {
      props.reload(true);
    }
    props.closeModal(false);
  };

  useEscapeKeyCloseModal(true, handleEscapeClose);

  return (
    <>
      <div
        ref={props.clickOutsideRef}
        id="step-group-input-parameter"
        className="shadow-md responsive-parameter-div relative"
        style={divStyle}
      >
        <div className="mt-2">
          <span className="Popup-header-common pl-5">{getInputParameterDivHeader()}</span>
        </div>
        <div
          className="border-b border-t border-blue-150 my-3 overflow-y-auto responsive-parameter-div-height"
          id="journal-scroll"
        >
          <div className="input-filed-label-style-common px-5 py-3  mr-px relative" id="journal-scroll">
            <div className="">
              <div className="inline-block">
                {data.nlpType === 'STEP_GROUP' || data.type === 'Group' ? (
                  <span>Step Group Description</span>
                ) : data.nlpType === 'PROGRAM_ELEMENTS' || data?.nlpId?.includes('PE_NLP') ? (
                  <span>Program Element Description</span>
                ) : (
                  <span style={{ color: '#000000' }}>NLP Description</span>
                )}
                {data.nlpType === 'STEP_GROUP' || data.type === 'Group' ? (
                  <p id="nlp-description" className="break-all text-blue-700">
                    {stepGroup?.name}
                  </p>
                ) : data.nlpType === 'PROGRAM_ELEMENTS' || data?.nlpId?.includes('PE_NLP') ? (
                  <p id="nlp-description" className="break-all text-blue-700">
                    {data?.description || data?.displayName} in {data?.toolTip.split(':').splice(0, 1)} page
                  </p>
                ) : (
                  <p id="nlp-description" className="break-all text-blue-700">
                    {data?.toolTip}
                  </p>
                )}
              </div>
              {(data.nlpType === 'STEP_GROUP' || data.type === 'Group') && isRepositoryEditable ? (
                <span
                  className={`text-blue-700 mt-4 mr-1.5 ml-auto float-right ${
                    stepGroupLink ? 'pointer-events-none opacity-50' : 'cursor-pointer'
                  }`}
                  onClick={() => {
                    if (!stepGroupLink) {
                      navigateToStepGroupPage();
                    }
                  }}
                >
                  Go to step group
                </span>
              ) : null}
            </div>
            <div>
              {initialValues && validationSchema ? (
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  innerRef={formRef}
                  enableReinitialize={true}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleReset,
                    setTouched,
                    setFieldTouched,
                    setFieldValue,
                    setFieldError,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div>
                          <div className="">
                            {data.stepInputs && data.stepInputs.length > 0 ? (
                              data.nlpName === 'StartForLoop' ? (
                                forConditionRendrer(
                                  data,
                                  handleChange,
                                  handleBlur,
                                  setFieldValue,
                                  values,
                                  errors,
                                  touched
                                )
                              ) : (
                                <div className="mt-3 grid grid-cols-2 gap-4">
                                  {data.stepInputs.map((input, inputIndex) =>
                                    data.nlpName === 'StartIteration' ? (
                                      <DataproviderSuggestion
                                        dataProviderData={props?.scripts?.dataProvider}
                                        moduleId={moduleId}
                                        scriptId={scriptId}
                                        id={'StartIteration' + inputIndex}
                                        stepInputName={input?.name}
                                        stepInputType={input?.type}
                                        setVariableValue={setVariableValue}
                                        editData={input?.value}
                                        type={props.type}
                                        reference={input?.reference}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        setFieldValue={setFieldValue}
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        setSelectedDataProviderData={setSelectedDataProviderData}
                                        setDataProviderName={setDataProviderName}
                                        setIsDataProviderModal={setIsDataProviderModal}
                                        edit={props.edit}
                                      />
                                    ) : (
                                      <Fragment>
                                        {!NLP_AUTO_FILLED_INPUT_EXCLUDE.includes(input?.name) ? (
                                          <div className="mt-2">
                                            <div>
                                              {input?.name === 'element' ||
                                              input?.type === 'org.openqa.selenium.WebElement' ? (
                                                <ElementSuggestion
                                                  tableData={tableData}
                                                  elementDetails={elementDetails}
                                                  setElementDetails={setElementDetails}
                                                  setTableData={setTableData}
                                                  setTarget={setTarget}
                                                  elementData={elementData}
                                                  reloadStepsTable={onCancelReloadStateHandler}
                                                  updateNlpDescription={updateNlpDescription}
                                                  stepsDataObject={stepsDataObject}
                                                  stepInputName={input?.name}
                                                  id={'elements' + inputIndex}
                                                  editData={input?.value}
                                                  script={props.scripts}
                                                  stepGroup={props.stepGroup}
                                                  handleChange={handleChange}
                                                  handleBlur={handleBlur}
                                                  setFieldValue={setFieldValue}
                                                  type={props.type}
                                                  nlpType={data.platform}
                                                  setFieldError={setFieldError}
                                                  className={'fontPoppinsRegularMd'}
                                                  MyAlert={props?.MyAlert}
                                                />
                                              ) : (
                                                <Fragment>
                                                  {[input?.fileOrigin, input?.folderOrigin].includes('all') ? (
                                                    <FilePathComponent
                                                      input={input}
                                                      inputIndex={inputIndex}
                                                      MyAlert={props.MyAlert}
                                                      reloadStepsTable={onCancelReloadStateHandler}
                                                      identity={'from element'}
                                                      localVariableData={localVariableData}
                                                      moduleId={moduleId}
                                                      scriptId={scriptId}
                                                      defaultStepGroup={defaultStepGroup}
                                                      stepId={input?.value ? data.stepId : ''}
                                                      id={'variables' + inputIndex}
                                                      stepInputName={input?.name}
                                                      stepInputType={input?.type}
                                                      setVariableValue={setVariableValue}
                                                      initialValues={initialValues}
                                                      values={values}
                                                      editData={input?.value}
                                                      type={props.type}
                                                      reference={input?.reference}
                                                      handleChange={handleChange}
                                                      handleBlur={handleBlur}
                                                      setFieldValue={setFieldValue}
                                                      parentStartDataproviderStep={getParentDataProviderStep()}
                                                      parentStartIfStep={props?.parentStartIfStep}
                                                      setFileType={setFileType}
                                                      fileType={fileType}
                                                      stepsDataObject={stepsDataObject}
                                                      setStepsDataObject={setStepsDataObject}
                                                    />
                                                  ) : [input?.folderOrigin].includes('testdata') ? (
                                                    <FilePathComponent
                                                      input={input}
                                                      inputIndex={inputIndex}
                                                      MyAlert={props.MyAlert}
                                                      reloadStepsTable={onCancelReloadStateHandler}
                                                      identity={'from element'}
                                                      localVariableData={localVariableData}
                                                      moduleId={moduleId}
                                                      scriptId={scriptId}
                                                      defaultStepGroup={defaultStepGroup}
                                                      stepId={input?.value ? data.stepId : ''}
                                                      id={'variables' + inputIndex}
                                                      stepInputName={input?.name}
                                                      stepInputType={input?.type}
                                                      setVariableValue={setVariableValue}
                                                      initialValues={initialValues}
                                                      values={values}
                                                      editData={input?.value}
                                                      type={props.type}
                                                      reference={input?.reference}
                                                      handleChange={handleChange}
                                                      handleBlur={handleBlur}
                                                      setFieldValue={setFieldValue}
                                                      parentStartDataproviderStep={getParentDataProviderStep()}
                                                      parentStartIfStep={props?.parentStartIfStep}
                                                      setFileType={setFileType}
                                                      fileType={fileType}
                                                      stepsDataObject={stepsDataObject}
                                                      setStepsDataObject={setStepsDataObject}
                                                    />
                                                  ) : (
                                                    <VariableSuggestion
                                                      MyAlert={props.MyAlert}
                                                      reloadStepsTable={onCancelReloadStateHandler}
                                                      identity={'from element'}
                                                      localVariableData={localVariableData}
                                                      moduleId={moduleId}
                                                      scriptId={scriptId}
                                                      defaultStepGroup={defaultStepGroup}
                                                      stepId={input?.value ? data.stepId : ''}
                                                      id={'variables' + inputIndex}
                                                      stepInputName={input?.name}
                                                      stepInputType={input?.type}
                                                      setVariableValue={setVariableValue}
                                                      editData={input?.value}
                                                      type={props.type}
                                                      reference={input?.reference}
                                                      handleChange={handleChange}
                                                      handleBlur={handleBlur}
                                                      setFieldValue={setFieldValue}
                                                      parentStartDataproviderStep={getParentDataProviderStep()}
                                                      parentStartIfStep={props?.parentStartIfStep}
                                                      selectedNlpData={data}
                                                      stepInputs={input}
                                                      stepsDataObject={stepsDataObject}
                                                      setStepsDataObject={setStepsDataObject}
                                                    />
                                                  )}
                                                </Fragment>
                                              )}
                                            </div>
                                          </div>
                                        ) : null}
                                      </Fragment>
                                    )
                                  )}
                                </div>
                              )
                            ) : null}
                          </div>

                          {data.nlpName !== 'StartIteration' && data.returnType && data.returnType !== 'void' ? (
                            <div className="mt-2">
                              <div className="" style={{ width: '50%' }}>
                                {
                                  <VariableSuggestion
                                    MyAlert={props.MyAlert}
                                    reloadStepsTable={onCancelReloadStateHandler}
                                    stepInputType={data.returnType}
                                    localVariableData={localVariableData}
                                    moduleId={moduleId}
                                    scriptId={scriptId}
                                    id={'return-value'}
                                    stepInputName={'returnValue'}
                                    defaultStepGroup={defaultStepGroup}
                                    setReturnValue={setReturnValue}
                                    editData={data?.stepReferenceInfo?.name}
                                    type={props.type}
                                    reference={returnTypeReferemce}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                    setFieldError={setFieldError}
                                    removeStepRef={removeStepRef}
                                    identity={'from start'}
                                    parentStartDataproviderStep={props.parentStartDataproviderStep}
                                    parentStartIfStep={props?.parentStartIfStep}
                                    selectedNlpData={data}
                                  />
                                }
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                          {!['StartIteration', 'Break', 'Continue'].includes(data.nlpName) ? (
                            <div className="mt-4">
                              <div htmlFor="ifFailedStatus" className="input-filed-label-style-common mb-3">
                                <span className="text-red-400">&#42;</span>
                                <span style={{ color: '#525252' }}>If Failed</span>
                              </div>
                              <div className="">
                                <FormControl>
                                  <Select
                                    MenuProps={{
                                      getContentAnchorEl: null,
                                      anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                      },
                                    }}
                                    name="ifFailedStatus"
                                    id="ifFailedStatus"
                                    onChange={setIfFailed}
                                    value={ifCheckPointIsFailed}
                                    className="text-gray-500 fontPoppinsRegularMd"
                                  >
                                    {ifFailedArray?.map((data, dataIndex) => (
                                      <option
                                        className="hover:text-blue-700 fontPoppinsRegularSm cursor-pointer hover:bg-blue-100"
                                        value={data?.value}
                                      >
                                        {data?.name}
                                      </option>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                          ) : (
                            ''
                          )}

                          {data.nlpType === 'STEP_GROUP' || data.type === 'Group' ? (
                            <div>
                              <div className="border-t border-b border-dashed mt-2.5 mb-2.5 mr-6 border-blue-100 p-2">
                                <span>
                                  Steps
                                  {stepsOpen ? (
                                    <ChevronRightOutlined
                                      onClick={() => {
                                        setStepsOpen(false);
                                      }}
                                      fontSize="small"
                                      className="text-blue-700 cursor-pointer"
                                    />
                                  ) : (
                                    <KeyboardArrowDownOutlined
                                      onClick={() => {
                                        setStepsOpen(true);
                                      }}
                                      fontSize="small"
                                      className="text-blue-700 cursor-pointer"
                                    />
                                  )}
                                  {!stepsOpen ? (
                                    stepgroupSteps && stepgroupSteps.length > 0 ? (
                                      <div className="mt-2 ml-5">
                                        {stepgroupSteps.map((data, index) => {
                                          return (
                                            <p className="h-8 text-overflow-style w-full">
                                              <span className="mr-1">{index + 1}.</span>
                                              {data}
                                            </p>
                                          );
                                        })}
                                      </div>
                                    ) : (
                                      <p style={{ marginLeft: '0.5rem' }}>No steps</p>
                                    )
                                  ) : null}
                                </span>
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="h-10v">
                          {Object?.keys(elementDetails)?.length
                            ? Object?.values(elementDetails)?.map((element) => {
                                return (
                                  <>
                                    <ul className="navBar text-blue-600 flex mt-2 flex-row">
                                      <span id="dynamic-element" className="mb-3" key="element1">
                                        <a
                                          href="#"
                                          className="fontPoppinsSemiboldMd p-2 pb-2 flex flex-row color_blue mr-5"
                                        >
                                          <span className="mb-3 text-red-400">&#42;</span> {element.name}
                                        </a>
                                        <hr className="bg-blue-700 ml-1 mr-5 p-px -mt-3" />
                                      </span>
                                    </ul>
                                    {getLocatorTable({
                                      element,
                                      handleChange,
                                      setFieldValue,
                                      handleBlur,
                                      setFieldError,
                                    })}
                                  </>
                                );
                              })
                            : ''}
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              ) : null}
            </div>
          </div>
        </div>

        <div className="float-right mr-4 mb-3 ">
          <button
            type="button"
            className="sm:mr-2 md:mr-1 md:ml-8 bg-gray-200 py-1.5 rounded-sm px-3.5 border border-gray-300 shadow-sm fontPoppinsMediumMd text-black hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => {
              if (onCancelReloadRequired) {
                props.reload(true);
              }
              props.closeModal(false);
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            disabled={
              isLoading ||
              (defaultStepGroup && defaultStepGroup === 'true') ||
              (dataProviderName === '' && isDataProviderModal)
                ? true
                : false
            }
            onClick={() => {
              if (stepData?.nlpName === 'StartIteration') {
                if (initialValues && validationSchema) {
                  let obj = returnTouchedField(formRef.current.values);
                  if (createUpdateCalled) {
                    setCreateUpdateCalled(false);
                    saveStartEndIteration(stepData);
                  }
                }
              } else if (stepData?.nlpName === 'StartForLoop') {
                if (initialValues && validationSchema) {
                  let obj = returnTouchedField(formRef.current.values);
                  if (Object.keys(obj).length !== 0) {
                    formRef.current.setTouched(obj);
                  } else {
                    if (formRef.current.isValid) {
                      if (createUpdateCalled) {
                        setCreateUpdateCalled(false);
                        saveStartEndForLoop(stepData);
                      }
                    }
                  }
                }
              } else if (startConditionObject.includes(props?.stepsDataObject?.nlpName)) {
                if (initialValues && validationSchema) {
                  let obj = returnTouchedField(formRef?.current?.values);
                  if (Object.keys(obj).length !== 0) {
                    formRef.current.setTouched(obj);
                  } else {
                    if (createUpdateCalled) {
                      setCreateUpdateCalled(false);
                      saveCondition(stepsDataObject);
                    }
                  }
                }
              } else {
                if (initialValues && validationSchema) {
                  let obj = returnTouchedField(formRef.current.values);
                  if (Object.keys(obj).length !== 0) {
                    formRef.current.setTouched(obj);
                    let wrongData = false;
                    if (Object.values(formRef.current.values).every((v) => v !== '')) {
                      wrongData = false;
                    } else {
                      wrongData = true;
                    }
                    if (!wrongData) {
                      if (props.type === 'Step Group') {
                        if (createUpdateCalled) {
                          setCreateUpdateCalled(false);
                          saveStepGroupSteps();
                        }
                      } else {
                        if (createUpdateCalled) {
                          setCreateUpdateCalled(false);
                          saveStep();
                        }
                      }
                    }
                  } else {
                    if (props.type === 'Step Group') {
                      if (createUpdateCalled) {
                        setCreateUpdateCalled(false);
                        saveStepGroupSteps();
                      }
                    } else {
                      if (createUpdateCalled) {
                        setCreateUpdateCalled(false);
                        saveStep();
                      }
                    }
                  }
                } else {
                  if (props.type === 'Step Group') {
                    if (createUpdateCalled) {
                      setCreateUpdateCalled(false);
                      saveStepGroupSteps();
                    }
                  } else {
                    if (createUpdateCalled) {
                      setCreateUpdateCalled(false);
                      saveStep();
                    }
                  }
                }
              }
            }}
            className="ml-2 py-1.5 px-3.5 primary-btn fontPoppinsMediumMd"
          >
            {props.edit ? 'Update' : 'Add'}
          </button>
        </div>
      </div>
    </>
  );
};
export const FilePathComponent = ({
  inputIndex,
  input,
  setVariableValue,
  handleChange,
  handleBlur,
  setFieldValue,
  getParentDataProviderStep,
  setFileType,
  fileType,
  stepsDataObject,
  setStepsDataObject,
  ...props
}) => {
  const [localPathRadio, setLocalPathRadio] = useState(false);
  const [clearField, setClearField] = useState(false);
  let _reference = input?.reference;
  let _fileType = input?.fileType || '';
  useEffect(() => {
    if (input?.fileType === 'LOCAL') {
      setLocalPathRadio(true);
    }
  }, []);

  useEffect(() => {
    if (localPathRadio) {
      setFileType('LOCAL');
    } else {
      setFileType('TESTDATA');
    }
  }, [localPathRadio]);

  const radioButtonSelector = (event) => {
    setVariableValue('');
    setFieldValue('');
    setClearField(true);
    if (event.target.id.includes('radio-path-local')) {
      setLocalPathRadio(true);
      _reference = input?.reference;
      _fileType = 'LOCAL';
      setFileType('LOCAL');
    } else {
      setLocalPathRadio(false);
      _reference = '';
      _fileType = 'TESTDATA';
      setFileType('TESTDATA');
    }
  };

  return (
    <>
      {input?.folderOrigin !== 'testdata' && (
        <div>
          <div className="fontPoppinsRegularXlg">
            {' '}
            <span className="text-red-400 mr-1">&#42;</span> Choose {input?.fileOrigin ? 'file' : 'folder'}
          </div>
          <div className="mt-2 mb-4 pb-2">
            <input
              type="radio"
              className="radio mr-2 cursor-pointer"
              name={`local-path-radio-${input.name}`}
              id={`radio-path-testdata-${input.name}${inputIndex}`}
              onChange={(event) => radioButtonSelector(event)}
              value="radio-path-testdata"
              checked={!localPathRadio}
            />
            <label
              className="text-xs overflow-hidden mr-4 overflow-ellipsis whitespace-nowrap fontPoppinsRegularMd cursor-pointer"
              htmlFor={`radio-path-testdata-${input.name}${inputIndex}`}
            >
              {NLP_RADIO_BUTTON.RADIO_BUTTON_TEST_DATA}
            </label>
            <input
              type="radio"
              className="radio mx-2 cursor-pointer"
              name={`local-path-radio-${input.name}`}
              id={`radio-path-local-${input.name}${inputIndex}`}
              onChange={(event) => radioButtonSelector(event)}
              value="radio-path-local"
              checked={localPathRadio}
            />
            <label
              className="text-xs overflow-hidden overflow-ellipsis whitespace-nowrap fontPoppinsRegularMd cursor-pointer"
              htmlFor={`radio-path-local-${input.name}${inputIndex}`}
            >
              {NLP_RADIO_BUTTON.RADIO_BUTTON_LOCAL_PATH}
            </label>
          </div>
        </div>
      )}
      {localPathRadio ? (
        <VariableSuggestion
          MyAlert={props.MyAlert}
          reloadStepsTable={props?.reloadStepsTable}
          identity={'from_local'}
          localVariableData={props?.localVariableData}
          moduleId={props?.moduleId}
          scriptId={props?.scriptId}
          defaultStepGroup={props?.defaultStepGroup}
          stepId={props.stepId}
          id={'variables' + inputIndex}
          stepInputName={input?.name}
          stepInputType={'localpath'}
          setVariableValue={setVariableValue}
          editData={input?.value}
          type={props.type}
          reference={_reference}
          fileType="LOCAL"
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          parentStartDataproviderStep={getParentDataProviderStep}
          parentStartIfStep={props?.parentStartIfStep}
          clearField={clearField}
          setClearField={setClearField}
          stepsDataObject={stepsDataObject}
          setStepsDataObject={setStepsDataObject}
          setFiletype={setFileType}
        />
      ) : (
        <VariableSuggestion
          isFolderDropdown={!!input.folderOrigin}
          MyAlert={props.MyAlert}
          reloadStepsTable={props?.reloadStepsTable}
          identity={'testdata'}
          localVariableData={props?.localVariableData}
          moduleId={props?.moduleId}
          scriptId={props?.scriptId}
          defaultStepGroup={props?.defaultStepGroup}
          stepId={props.stepId}
          id={'testdatafile' + inputIndex}
          stepInputName={input?.name}
          stepInputType={input?.type}
          setVariableValue={setVariableValue}
          editData={input?.value}
          type={props.type}
          reference={_reference}
          fileType="TESTDATA"
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          parentStartDataproviderStep={getParentDataProviderStep}
          parentStartIfStep={props?.parentStartIfStep}
          clearField={clearField}
          setClearField={setClearField}
          stepsDataObject={stepsDataObject}
          setStepsDataObject={setStepsDataObject}
          setFileType={setFileType}
        />
      )}
    </>
  );
};
export default StepsNlpInputParameter;

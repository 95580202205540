import React, { useMemo } from 'react';
import { ReactComponent as CircularDp } from '@assets/svg-imports/User_circle_black.svg';
import styles from './licensedetails.module.scss';
import { ReactComponent as CloseIcon } from '@assets/analytics/ShrinkIconWhite.svg';
import { Tooltip } from '@mui/material';
import { isEmptyPhoneNumber } from '@util/licenseUtils';
import LicenseRowCardStyle from '@pages/licenses/styles/license-row-card.module.scss';
import cx from 'classnames';

const OwnerDetails = (props) => {
  const { ownerDetails } = useMemo(() => {
    return {
      ownerDetails: props?.data?.licenseUsers.find(({ emailId }) => emailId === props.data.licenseOwner) || {},
    };
  }, [props.data]);

  return (
    <section className="w-full flex flex-col gap-2">
      <div className={styles.fixed}>
        <div className={cx(styles.header, 'h-35')}>
          <p className={styles.headerTitle}>User Details</p>
          <div
            onClick={() => {
              props.setIsOwnerDetailsDrawerOpen(false);
            }}
            className={styles.closeIcon}
          >
            <CloseIcon className="w-2 h-2" />
          </div>
        </div>
        <div className={cx(styles.licenseNameBlock, 'my-2')}>
          <div className={styles.leftBlock}>
            <CircularDp className={`h-4 w-4 text-black`} />
            <p className="fontPoppinsRegularSm"> Name </p>
          </div>
          <div className={cx(styles.rightBlock, 'ml-5')}>
            <Tooltip
              fontSize="fontPoppinsSemiboldXs"
              placement="left"
              title={ownerDetails?.name}
              classes={{
                popper: cx(LicenseRowCardStyle['ToolTipRenderer']),
              }}
            >
              <span className="fontPoppinsSemiboldSm">{ownerDetails?.name}</span>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col gap-2">
        <div className={cx(styles.privilegeBlock, 'my-2')}>
          <div className={styles.leftBlock}>
            <span className="fontPoppinsRegularSm">Email Id </span>
          </div>
          <div className={cx(styles.rightBlock, 'ml-5')}>
            <Tooltip
              fontSize="fontPoppinsSemiboldXs"
              placement="left"
              title={ownerDetails?.emailId}
              classes={{
                popper: cx(LicenseRowCardStyle['ToolTipRenderer']),
              }}
            >
              <span className="fontPoppinsSemiboldSm">
                {ownerDetails.emailId?.length > 25 ? `${ownerDetails.emailId?.slice(0, 20)}...` : ownerDetails?.emailId}
              </span>
            </Tooltip>
          </div>
        </div>
        <div className={cx(styles.privilegeBlock, 'my-2')}>
          <div className={styles.leftBlock}>
            <span className="fontPoppinsRegularSm">Organisation Name </span>
          </div>
          <div className={cx(styles.rightBlock, 'ml-5')}>
            <span className="fontPoppinsSemiboldSm">{props.data.organisationName || '--'}</span>
          </div>
        </div>
        <div className={cx(styles.privilegeBlock, 'my-2')}>
          <div className={styles.leftBlock}>
            <span className="fontPoppinsRegularSm">Job Title </span>
          </div>
          <div className={cx(styles.rightBlock, 'ml-5')}>
            <span className="fontPoppinsSemiboldSm">{props.data.jobTitle || '--'}</span>
          </div>
        </div>
        <div className={cx(styles.privilegeBlock, 'my-2')}>
          <div className={styles.leftBlock}>
            <span className="fontPoppinsRegularSm">Phone Number </span>
          </div>
          <div className={cx(styles.rightBlock, 'ml-5')}>
            <span className="fontPoppinsSemiboldSm">{isEmptyPhoneNumber(props.data.ownerPhoneNumber)}</span>
          </div>
        </div>
      </div>
    </section>
  );
  // }
};

export default OwnerDetails;

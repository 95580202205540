import { Tooltip } from '@material-ui/core';

import { PlayCircleOutlineOutlined, Sync } from '@material-ui/icons';
import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useTable } from 'react-table';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import EditExecutionNameModal from './modals/edit-execution-name';
import {
  createExecutionReq,
  getExecutionMachineDetailsReq,
  getExecutionTableDataReq,
  getModuleResultTreeReq,
  getSingleExecutionDataReq,
  getSingleUserRequest,
  reRunExecutionReq,
  terminateExecutionReq,
} from '../../../api/api_services';
import UserDetailsModal from '../shared/user-details-modal';
import MachineDetails from './modals/machine-details';
import DeleteExecutionMachine from './modals/delete-execution-machine';
import { setPreselectedValue } from '../shared/create-execution-tree';
import { CommonLoader } from '@src/pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import ClientStatusModal from '@pagescommon/execution/client-status.jsx';
import { getExecutionName } from '../shared/common-methods';
import SearchBar from '@src/pages/analytics/common/search-bar';
import { archive, getCurrentProjectId, HighlightText, isEmptyValue } from '@util/common_utils';
import NoResultsFound from '@src/pages/common/Table/NoResultFound';
import { suiteResultStatistics } from '@src/pages/test-development/execution-dashboard/execution-dashboard-util';
import '@pages/results/result.scss';
import { partialResultPageStatuses, beforeRunningStatuses } from './execution-dashboard-util';
import TerminateWarningComponent from './modals/terminate-warning-component';
import { getTruncatedText } from '@src/util/common_utils';
import { CircularProgressWithLabel, useCircularProgress } from '@src/common/CircularProgressContainer';
import MemoryWarningPopup from '@src/pages/configuration/Memory_Config/MemoryWarningPopup';
import { Icon, Toggle } from 'fireflink-ui';
import { DASHBOARD_CONSTANTS, PROJECT_TABPERMISSION_SECTIONS } from '@src/common/ui-constants';
import { FREE_LICENSE_TYPE } from '@src/util/licenseUtils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const ExecutionDashboard = (props) => {
  const { isEditable, isProjectOpen, hasFullAccess } = React.useMemo(
    () => ({
      isProjectOpen: window.permission?.isProjectOpen(),
      isEditable: window.permission?.isEditAccess('testDevelopment'),
      hasFullAccess: window.permission?.isFullAccess('testDevelopment'),
    }),
    []
  );
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);

  const { progress, startCircularProgress } = useCircularProgress('execution-dashboard');
  const project = JSON.parse(localStorage.getItem('selected-project'));
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(true);
  const [executionTableData, setExecutionTableData] = useState([]);
  const [editExecutionNameData, setEditExecutionNameData] = useState([]);
  const [memoryWarningPopup, setMemoryWarningPopup] = useState(false);
  const [memorySpillData, setMemorySpillData] = useState({});
  const [openEditExecutionNameModal, setOpenEditExecutionNameModal] = useState(false);
  const reloadExecTab = (val) => {
    setReload(val);
  };
  const [openDeleteExecutionMachineModal, setDeleteExecutionMachineModal] = useState(false);
  const [deleteExecutionMachineModalData, setDeleteExecutionMachineModalData] = useState(null);
  const [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [openMachineDetailsModal, setOpenMachineDetailsModal] = useState(false);
  const [machineDetails, setMachineDetails] = useState({});
  const [isSyncDisabled, setIsSyncDisabled] = useState(false);
  let [showWarningModal, setShowWarningModal] = useState(false);
  let [selectedExeObject, setSelectedExeObject] = useState({});
  let [pageno, setpageNo] = useState(0);
  let [lastPage, setLastpage] = useState(false);
  let [lastPageNo, setLastpageNo] = useState(0);

  let size = 20;
  const projectId = getCurrentProjectId();
  const [checked, setChecked] = useState(archive.testDevFlags?.[projectId] || false);

  useEffect(() => {
    let timerId = null;
    if (isSyncDisabled) {
      timerId = setTimeout(() => {
        setIsSyncDisabled(false);
      }, process.env.REACT_APP_SUITE_EXECUTION_INTERVAL || 10000);
    }
    return () => clearTimeout(timerId);
  }, [isSyncDisabled]);

  const getUserDetails = async (key) => {
    if (key) {
      const res = await getSingleUserRequest(key);
      if (res.data.responseCode === 200 && res.data.responseObject) {
        setUserDetails(res.data.responseObject);
        setOpenUserDetailsModal(true);
      }
    }
  };
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const parsedModuleId = moduleId ? moduleId.split('/')[0] : null;

  const history = useHistory();
  const scriptLocation = useLocation();
  const moduleLocation = useLocation();

  useEffect(() => {
    if (scriptLocation.pathname.includes('true')) {
      props.MyAlert.info('Only Automation scripts will be executed');
      history.push('/testdevelopment/Execution Dashboard');
    }
  }, [scriptLocation]);

  useEffect(() => {
    const newPath = moduleLocation.pathname.split('/');
    if (moduleLocation.search.includes('true')) {
      history.push(`${newPath.join('/')}?moduleId=${parsedModuleId}`);
      props.MyAlert.info('Only Automation scripts will be executed');
    }
  }, [moduleLocation, parsedModuleId]);

  const getMachineDetails = async (data, execStatus, resultStatus) => {
    try {
      let reqData;
      if (data) {
        let dataPath = data?.executionData?.preExecution?.selectedSystem[0]?.machineInstances;
        let deviceName = '';
        dataPath[0]?.deviceInfo?.forEach((system, index) => {
          deviceName +=
            (system?.name ? system?.name : '') + `${index < dataPath[0]?.deviceInfo?.length - 1 ? ',' : ' '}`;
        });
        if (dataPath) {
          dataPath.forEach((dataPath) => {
            reqData = {
              machineName: dataPath?.machineInfo?.hostName,
              os: dataPath?.machineInfo?.osName + '-' + dataPath?.machineInfo?.osVersion,
              browser: dataPath.browserInfo.name,
              device: deviceName || '--',
              status: data?.status,
              executionData: dataPath?.executionData,
              owner: data?.owner,
            };
          });
        } else {
          reqData = {
            machineName: data.sysData.machineInfo.hostName,
            os: data.sysData.machineInfo.osName,
            browser: data.sysData.browserName,
            device: data.sysData.deviceInfo[0]?.name ? data.sysData.deviceInfo[0]?.name : '--',
            status: data.status,
            owner: data.owner,
            executionData: data.executionData,
          };
        }
        if (execStatus === 'Completed') {
          if (resultStatus === 'PASS') {
            reqData.status = 'Passed';
          } else if (resultStatus === 'FAIL') {
            reqData.status = 'Failed';
          } else if (resultStatus === 'SKIP') {
            reqData.status = 'Skipped';
          } else if (resultStatus === 'WARNING') {
            reqData.status = 'Warning';
          } else {
            reqData.status = resultStatus;
          }
        } else {
          reqData.status = execStatus;
        }
        getExecutionMachineDetailsReq(data.sysData.clientId).then((res) => {
          if (res.data && res.data.responseObject) {
            reqData.executions = res.data.responseObject;
          } else {
            reqData.executions = [];
          }
          setMachineDetails(reqData);
          setOpenMachineDetailsModal(true);
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const closeDeleteExecutionModal = (value) => {
    setDeleteExecutionMachineModal(value);
    setDeleteExecutionMachineModalData(null);
  };
  const closeUserDetailsModal = (value) => {
    setOpenUserDetailsModal(value);
    setUserDetails({});
  };
  const closeMachineDetailsModal = (value) => {
    setOpenMachineDetailsModal(value);
    setMachineDetails({});
  };
  const editExecutionName = (varData) => {
    setOpenEditExecutionNameModal(true);
    setEditExecutionNameData(varData);
  };
  const closeEditExecutionNameModal = (value) => {
    setOpenEditExecutionNameModal(false);
    setEditExecutionNameData([]);
  };
  const menuData = (execution, index) => {
    return (
      <div className="actionIcons flex flex-1 items-center test-dev-exe-dashboard-action">
        {isProjectOpen && (
          <>
            {execution.executionStatus === 'Completed' && execution.resultStatus === 'FAIL' && (
              <button
                onClick={() => {
                  handleRunOnlyFailed(execution.id, false);
                }}
                className="cursor-pointer mr-3"
              >
                <Tooltip title="Run only failed">
                  <span className="text-sm h-8 text-red-600">
                    {' '}
                    <img className="mt-0.5 text-xs" src="/assets/images/RunOnlyFailedBlue.svg" alt="Run only failed" />
                  </span>
                </Tooltip>
              </button>
            )}
            {execution.executionStatus === 'Running' && (
              <button
                onClick={() => {
                  setShowWarningModal(true);
                  setSelectedExeObject(execution);
                }}
                className="cursor-pointer mr-3"
              >
                <Tooltip title="Terminate" placement="top">
                  <span className="pt-0.5 text-sm">
                    <img className="mt-1" src="/assets/images/terminateExecution.svg" alt="Terminate Execution" />
                  </span>
                </Tooltip>
              </button>
            )}
            {beforeRunningStatuses.includes(execution.executionStatus) && (
              <button
                onClick={() => {
                  setShowWarningModal(true);
                  setSelectedExeObject(execution);
                }}
                className="mr-3"
              >
                <Tooltip title="Terminate" placement="top">
                  <span className="pt-0.5 text-sm">
                    <img className="mt-1" src="/assets/images/terminateExecution.svg" alt="Terminate Execution" />
                  </span>
                </Tooltip>
              </button>
            )}
            {['Cancelled', 'Terminated', 'Completed'].includes(execution.executionStatus) ? (
              <>
                {!execution?.requestFormed || execution?.requestFormed === 'false' ? (
                  <button className="mr-3 opacity-50" disabled={true}>
                    <Tooltip title="Run">
                      <span className="text-sm">
                        {' '}
                        <PlayCircleOutlineOutlined fontSize="small" className="text-base text-blue-700" />
                      </span>
                    </Tooltip>
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      reRunExecution(execution.id, execution.name, false);
                      setChecked(false);
                    }}
                    className="cursor-pointer mr-3"
                  >
                    <Tooltip title="Run">
                      <span className="text-sm">
                        {' '}
                        <PlayCircleOutlineOutlined fontSize="small" className="text-base text-blue-700" />
                      </span>
                    </Tooltip>
                  </button>
                )}
              </>
            ) : null}
          </>
        )}
        {isEditable && (
          <button className="cursor-pointer mr-3">
            <Tooltip title="Edit">
              <span>
                <EditOutlinedIcon
                  fontSize="small"
                  className="text-base text-blue-700"
                  onClick={() => {
                    editExecutionName({
                      executionName: execution.name,
                      id: execution.id,
                      index: index,
                    });
                  }}
                />
              </span>
            </Tooltip>
          </button>
        )}

        {hasFullAccess && (
          <button
            disabled={['Running', ...beforeRunningStatuses].includes(execution.executionStatus)}
            className=""
            onClick={() => {
              setDeleteExecutionMachineModalData(execution);
              setDeleteExecutionMachineModal(true);
            }}
          >
            <Tooltip title="Delete">
              <span>
                <DeleteOutlinedIcon
                  fontSize="small"
                  className={
                    ['Running', ...beforeRunningStatuses].includes(execution.executionStatus)
                      ? 'text-gray-400 text-base'
                      : 'text-blue-700 text-base cursor-pointer'
                  }
                />
              </span>
            </Tooltip>
          </button>
        )}
      </div>
    );
  };

  // TODO :: handle Search filter :: START

  const [userSearched, setuserSearched] = useState('');
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(executionTableData);
  }, [executionTableData]);

  const handleSearch = (e) => {
    setuserSearched(e.target.value);
    if (e.target.value === '') {
      setData(executionTableData);
      return;
    }
    const newData = executionTableData.filter(({ executionId, executionName, machine, runInitiatedBy }) => {
      return (
        executionId?.toLowerCase()?.includes(e.target.value?.toLowerCase()) ||
        executionName?.toLowerCase()?.includes(e.target.value?.toLowerCase()) ||
        machine?.toLowerCase()?.includes(e.target.value?.toLowerCase()) ||
        runInitiatedBy['createdByUname']?.toLowerCase()?.includes(e.target.value?.toLowerCase())
      );
    });
    setData(newData);
  };

  let [openSearch, setOpenSearch] = useState(false);
  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setData(executionTableData);
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  // TODO :: handle Search filter :: END

  const columns = React.useMemo(
    () => [
      {
        Header: 'Execution ID',
        accessor: 'executionId',
        width: '10%',
        Cell: (e) => (
          <span className="execution-font-style-common">
            <HighlightText text={e.value} highlight={userSearched} />{' '}
          </span>
        ),
      },
      {
        Header: 'Execution Name',
        accessor: 'executionName',
        width: '13%',
        Cell: (e) => {
          return (
            <span
              className="hyper_link_color-common cursor-pointer fontPoppinsRegularSm"
              onClick={() => {
                if (![...beforeRunningStatuses, 'Cancelled', 'Aborted'].includes(e.row.original?.executionStatus)) {
                  if (
                    !(
                      e.row.original?.executionStatus === 'Completed' &&
                      ['aborted', 'n/a'].includes(e.row.original?.executionStatus?.toLowerCase())
                    )
                  ) {
                    props.history.push(
                      `/testdevelopment/Execution Dashboard/${e.value}?executionId=${e.row.original.executionId}`
                    );
                  }
                }
              }}
            >
              <Tooltip title={e.value} placement="bottom">
                <div className="w-24 text-overflow-style">
                  <HighlightText text={e?.value} highlight={userSearched} />
                </div>
              </Tooltip>
            </span>
          );
        },
      },
      {
        Header: 'Machine',
        accessor: 'machine',
        width: '12%',
        Cell: (e) => {
          return (
            <span
              className="hyper_link_color-common cursor-pointer fontPoppinsRegularSm"
              onClick={() => {
                getMachineDetails(
                  {
                    sysData: e.row.original?.preExecution?.selectedSystem[0],
                    status: e.row.original?.executionStatus,
                    owner: e.row.original?.runInitiatedBy?.createdByUname,
                    executionData: e.row.original?.executionDataCopy,
                  },
                  e.row.original?.executionStatus,
                  e.row.original?.resultStatus
                );
              }}
            >
              {e?.value}
            </span>
          );
        },
      },
      {
        Header: 'Total Script',
        accessor: 'totalScript',
        width: '8%',
      },

      {
        Header: 'Run Initiated By',
        accessor: 'runInitiatedBy',
        width: '11%',
        Cell: (e) => (
          <span
            onClick={() => {
              getUserDetails(e?.value?.createdBy);
            }}
            className="hyper_link_color-common cursor-pointer fontPoppinsRegularSm"
          >
            <HighlightText text={e?.value?.createdByUname} highlight={userSearched} />
          </span>
        ),
      },
      {
        Header: 'Execution Start Time',
        accessor: 'executionStartTime',
        width: '10%',
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: '6%',
      },
      {
        Header: 'RESULT',
        accessor: 'result',
        width: '6%',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left table-font-style-common cursor">{value}</div>;
        },
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        width: '10%',
      },
    ],
    [userSearched]
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data,
  });

  const navigateToResults = (ele) => {
    localStorage.setItem('screenShotsDeleted', ele?.screenShotsDeleted ? ele?.screenShotsDeleted : false);
    props.history.push(`/testdevelopment/Execution Dashboard/${ele.name}/Root Module/result?executionId=${ele.id}`);
  };

  const statusSpan = (ele) => {
    if (ele.executionStatus === 'Completed') {
      if (ele.resultStatus === 'PASS') {
        return (
          <span
            onClick={() => {
              navigateToResults(ele);
            }}
            className="result-passed cursor-pointer fontPoppinsRegularSm"
          >
            Passed
          </span>
        );
      } else if (ele.resultStatus === 'FAIL') {
        return (
          <span
            onClick={() => {
              navigateToResults(ele);
            }}
            className="result-failed cursor-pointer fontPoppinsRegularSm"
          >
            Failed
          </span>
        );
      } else if (ele.resultStatus === 'Aborted') {
        return <span className="result-failed cursor-pointer fontPoppinsRegularSm">Aborted</span>;
      } else if (ele.resultStatus === 'WARNING') {
        return (
          <span
            onClick={() => {
              navigateToResults(ele);
            }}
            className="cursor-pointer fontPoppinsRegularSm text-yellow-400"
          >
            Warning
          </span>
        );
      } else if (ele.resultStatus === 'N/A') {
        return <span className="result-skipped  text-xs fontPoppinsRegularSm">N/A</span>;
      } else if (ele.resultStatus === 'SKIP') {
        return <span className="result-skipped  text-xs fontPoppinsRegularSm">Skipped</span>;
      } else {
        return <span className="text-gray-600 cursor-pointer fontPoppinsRegularSm">{ele.resultStatus}</span>;
      }
    } else {
      if (ele.executionStatus === 'Cancelled') {
        let executionStatus = ele?.currentStatus ? ele?.currentStatus : ele.executionStatus;
        return (
          <div class="flex flex-row">
            <span className="result-skipped cursor-pointer fontPoppinsRegularSm">{executionStatus}</span>
            {executionInfo(ele)}
          </div>
        );
      } else if (ele.executionStatus === 'Aborted') {
        return <span className="result-failed text-xs fontPoppinsRegularSm">{ele.executionStatus}</span>;
      } else if (['Fetching Results', 'Running'].includes(ele.executionStatus)) {
        return (
          <div class="flex flex-row">
            <span
              onClick={() => {
                props.history.push(`/testdevelopment/Execution Dashboard/${ele.name}?executionId=${ele.id}`);
              }}
              className="text-blue-600 cursor-pointer fontPoppinsRegularSm"
            >
              {ele.executionStatus}
            </span>
            {['Running'].includes(ele.executionStatus) && executionInfo(ele)}
          </div>
        );
      } else if (ele.executionStatus === 'Terminated') {
        return ele?.resultStatus ? (
          <span
            onClick={() => {
              navigateToResults(ele);
            }}
            className="result-failed cursor-pointer fontPoppinsRegularSm"
          >
            {ele.executionStatus}
          </span>
        ) : (
          <div class="flex flex-row">
            <span className="result-failed opacity-50 fontPoppinsRegularSm">{ele.executionStatus}</span>
            {executionInfo(ele)}
          </div>
        );
      } else if (beforeRunningStatuses.includes(ele.executionStatus)) {
        return (
          <div class="flex flex-row">
            <span
              onClick={() => {
                if (partialResultPageStatuses.includes(ele.executionStatus)) {
                  props.history.push(`/testdevelopment/Execution Dashboard/${ele.name}?executionId=${ele.id}`);
                }
              }}
              className="text-yellow-400 cursor-pointer fontPoppinsRegularSm status_message"
            >
              {ele.executionStatus}
            </span>
            {executionInfo(ele)}
          </div>
        );
      } else {
        return (
          <div class="flex flex-row">
            <span
              onClick={() => {
                props.history.push(`/testdevelopment/Execution Dashboard/${ele.name}?executionId=${ele.id}`);
              }}
              className="text-blue-600 cursor-pointer fontPoppinsRegularSm"
            >
              {ele.executionStatus}
            </span>
          </div>
        );
      }
    }
  };

  const executionInfo = (execData) => {
    return ['Pending', 'Cancelled', 'Terminated', 'Running'].includes(execData.executionStatus) ? (
      <ClientStatusModal
        clientStatus={execData?.clientStatus}
        executionStatus={execData?.executionStatus}
        headless={execData?.headless || false}
      />
    ) : (
      ''
    );
  };

  async function reRunExecution(id, name, deleteSpillOverMemory = false) {
    try {
      const res = await reRunExecutionReq(id, {}, deleteSpillOverMemory);
      if (res.data && res.data.responseObject) {
        let rerun = `${name?.substring(0, 30)}${name?.length > 30 ? '...' : ''}`;
        props.MyAlert.success(`${rerun} execution started successfully`);
        if (res.data.responseObject.executionStatus === 'Running') {
          props.history.push(
            `/testdevelopment/Execution Dashboard/${res.data.responseObject.name}?executionId=${res.data.responseObject.id}`
          );
        } else {
          reloadExecTab(true);
        }
        setChecked(false);
      } else if (res?.data?.message && res?.data?.message.toLowerCase().includes('please connect a device')) {
        props.MyAlert.info(res?.data?.message);
      } else if (res?.data?.message && res?.data?.message.toLowerCase().includes('please install a browser')) {
        props.MyAlert.info(res?.data?.message);
      } else if (
        res?.data?.message &&
        res?.data?.message.startsWith('Client') &&
        res?.data?.message.toLowerCase().includes('is not available')
      ) {
        props.MyAlert.info(res?.data?.message);
      } else if (res?.data?.message === 'Consumed Memory is above 85%') {
        setMemorySpillData({
          type: 're-run',
          id,
          name,
        });
        setMemoryWarningPopup(true);
      } else {
        props.MyAlert.warning(`${res.data.message}`);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function handleRunOnlyFailed(execId, deleteSpillOverMemory = false) {
    try {
      let root;
      let completeTree;
      const res = await getSingleExecutionDataReq(execId);
      if (res.data && res.data.responseObject && res.data.responseObject.preExecution) {
        let fancyTree;
        let rootDetails;
        if (res.data.responseObject.executionStatus === 'Completed') {
          if (res.data.responseObject.fancyTreeData) {
            rootDetails = res.data.responseObject.fancyTreeData.responseObject.executionResponses[0].children[0];
          } else {
            const fancyTreeResponse = await getModuleResultTreeReq(execId);
            fancyTree = fancyTreeResponse.data.responseObject.executionResponses[0].children;
            rootDetails = fancyTree[0];
          }
          completeTree = { ...rootDetails };
          root = {
            subModuleCount: rootDetails.subModuleCount,
            title: rootDetails.title,
            scriptCount: rootDetails.scriptCount,
            key: rootDetails.key,
          };
        }
        const runSettingsApiData = { ...res.data.responseObject.preExecution };
        let exeJson = setPreselectedValue(completeTree.children);
        let rootModule = {
          key: root?.key,
          title: root?.title,
          type: 'Module',
          children: exeJson,
        };
        let executionDataJson = {};
        executionDataJson = {
          name: getExecutionName([rootModule]),
          executionTree: [rootModule],
          installedDir: 'D:\\resources\\macine_config',
          screenshotRequired: runSettingsApiData?.screenshotRequired,
          failedStepsScreenshot: runSettingsApiData?.failedStepsScreenshot,
          videoRequired: runSettingsApiData?.videoRequired,
          waitTime: { ...runSettingsApiData?.waitTime },
          selectedSystem: runSettingsApiData?.selectedSystem,
          projectName: project.name,
          projectId: project.id,
          type: 'INDIVIDUAL',
          deleteSpillOverMemory,
        };
        const executionResponse = await createExecutionReq(executionDataJson);
        if (executionResponse && executionResponse.data && executionResponse.data.responseObject) {
          // if (executionResponse.data.responseObject.executionStatus === 'Running') {
          //     props.history.push(`/testdevelopment/Execution Dashboard/${executionResponse.data.responseObject.name}?executionId=${executionResponse.data.responseObject.id}`)
          // } else if (executionResponse.data.responseObject.executionStatus === 'Pending') {
          //     props.history.push(`/testdevelopment/Execution Dashboard`)
          // }
          // props.history.push(`/testdevelopment/Execution Dashboard`);
          reloadExecTab(true);
          setChecked(false);
        } else if (
          executionResponse?.data?.message &&
          executionResponse?.data?.message.toLowerCase().includes('please connect a device')
        ) {
          props.MyAlert.info(executionResponse?.data?.message);
        } else if (
          executionResponse?.data?.message &&
          executionResponse?.data?.message.toLowerCase().includes('please install a browser')
        ) {
          props.MyAlert.info(executionResponse?.data?.message);
        } else if (
          executionResponse?.data?.message &&
          executionResponse?.data?.message.startsWith('Client') &&
          executionResponse?.data?.message.toLowerCase().includes('is not available')
        ) {
          props.MyAlert.info(executionResponse?.data?.message);
        } else if (executionResponse?.data?.message === 'Consumed Memory is above 85%') {
          setMemorySpillData({
            type: 'run-only-failed',
            execId,
          });
          setMemoryWarningPopup(true);
        } else if (executionResponse.data && executionResponse.data.responseCode === 400) {
          props.MyAlert.warning(`${executionResponse.data.message}`);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }
  async function terminateExecution(id, name) {
    try {
      const res = await terminateExecutionReq(id, {});
      if (res?.data?.status === 'SUCCESS') {
        const execName = getTruncatedText(name, 30);
        props.MyAlert.success(`${execName} execution terminated successfully`);
        reloadExecTab(true);
      } else {
        props.MyAlert.warning(`${res.data.message}`);
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handlescroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event?.target || {};
    const actualScrollHeight = Math.ceil(scrollTop + clientHeight);
    if (scrollTop && [-1, 1, 0].includes(actualScrollHeight - scrollHeight)) {
      if (!lastPage && pageno === lastPageNo + 1) {
        setLastpageNo(pageno);
        getExecutionData(pageno);
      }
    }
  };
  async function getExecutionData(page) {
    try {
      let exeDashboard = true;
      resetProgress();
      startOrResumeProgress({
        stopAt: 90,
      });
      if (page === 0) {
        setIsLoading(true);
        setLastpageNo(0);
      }
      let execDataTab = executionTableData.length && page !== 0 ? [...executionTableData] : [];
      const projectDetails = JSON.parse(localStorage.getItem('selected-project'));
      const res = await getExecutionTableDataReq(projectDetails.id, page, size);
      stopProgress();
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 10,
      });
      if (res.data && res.data.responseObject && res.data.responseObject.length) {
        setpageNo(page + 1);
        res.data.responseObject.forEach((ele, index) => {
          ele.id = ele?._id;
          execDataTab.push({
            executionId: ele?.id,
            executionName: ele?.name,
            executionStatus: ele?.executionStatus,
            preExecution: ele?.preExecution,
            machine: ele?.preExecution?.selectedSystem?.[0]?.machineInfo?.hostName,
            totalScript: (
              <span className="hyper_link_color-common fontPoppinsRegularSm"> {ele?.totalScriptCount} </span>
            ),
            resultStatus: ele?.resultStatus,
            runInitiatedBy: {
              createdBy: ele?.createdBy,
              createdByUname: ele?.createdByUname,
            },
            runInitiatedOn: ele?.createdOn,
            executionStartTime: ele?.executionStartTime,
            status: statusSpan(ele),
            actions: menuData(ele, index),
            executionDataCopy: ele,
            result: suiteResultStatistics(ele, exeDashboard),
          });
        });

        let modifiedDashboardTableData = [];
        const noOfSuiteresult = res?.data?.responseMap?.totalCountOfResults;

        if (executionTableData.length && page !== 0) {
          modifiedDashboardTableData = [...executionTableData, ...execDataTab.slice(0, noOfSuiteresult - executionTableData?.length)];
        } else {
          modifiedDashboardTableData = [...execDataTab];
        }

        setLastpage(false);
        setExecutionTableData(modifiedDashboardTableData);
      } else if (res.data.responseCode === 404 && res.data.message === 'FAILURE') {
        setLastpage(true);
        if (page === 0 && isEmptyValue(res.data.responseObject)) {
          setExecutionTableData([]);
        }
      } else {
        setExecutionTableData([]);
        resetProgress();
      }
      setIsLoading(false);
      setChecked(archive.testDevFlags[projectId]);
    } catch (err) {
      resetProgress();
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 10,
      });
      console.error('GET_EXECUTION_TABLE_LIST : ', err);
    }
  }
  useEffect(() => {
    if (reload) {
      getExecutionData(0);
    }
    setReload(false);
  }, [reload]);
  useEffect(() => {
    archive.testDevFlags = archive.testDevFlags
      ? { ...archive.testDevFlags, [projectId]: checked }
      : { [projectId]: checked };
    localStorage.setItem('testDevFlags', JSON.stringify(archive.testDevFlags));
    const timeoutId = setTimeout(() => {
      getExecutionData(0);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [checked]);
  const isNotCBasicLicense = () =>
    JSON.parse(localStorage.getItem('test-optimize-user'))?.licenseType !== FREE_LICENSE_TYPE;
  const handleChange = (e) => {
    setChecked(!checked);
    archive.testDevFlags[projectId] = !archive.testDevFlags[projectId];
  };
  return (
    <div className="page-table-body">
      <div className="content_area_style" id="journal-scroll">
        <div className="relative pt-3 pb-3 border_style flex flex-wrap items-center">
          <label className="project_label text-sm flex-auto">Execution Dashboard</label>
          <div className="pr-4">
            <div className="flex flex-row ">
              {memoryWarningPopup && (
                <MemoryWarningPopup
                  handleCancel={() => setMemoryWarningPopup(false)}
                  handleContinue={() => {
                    if (memorySpillData?.type === 'run-only-failed') {
                      handleRunOnlyFailed(memorySpillData?.execId, true);
                    } else if (memorySpillData?.type === 're-run') {
                      reRunExecution(memorySpillData?.id, memorySpillData?.name, true);
                    }
                    setMemoryWarningPopup(false);
                    setChecked(false);
                  }}
                  isOpen={memoryWarningPopup}
                />
              )}
              {/* commented for future use */}
              {/* {
                !isEmptyValue(executionTableData) && (
                  <SearchBar
                  open={openSearch}
                  searchValue={userSearched}
                  handleSearch={handleSearch}
                  onSearchClose={handleSearchClose}
                  handleSearchOpen={handleSearchOpen}
                />
                )
              } */}
              {executionTableData && isNotCBasicLicense() && (
                <Tooltip
                  title={`View ${checked ? DASHBOARD_CONSTANTS.RECENT : DASHBOARD_CONSTANTS.ARCHIVED} Dashboards`}
                  placement="top"
                >
                  <div className="absolute right-16">
                    <Toggle
                      checked={checked}
                      disableLabel="RECENT"
                      enableLabel="ARCHIVED"
                      id="toggle"
                      onChange={handleChange}
                      type="withLabel"
                      variant="primary"
                    />
                  </div>
                </Tooltip>
              )}
              {isProjectOpen &&
                (progress === 0 ? (
                  <button
                    disabled={isLoading || executionTableData.length === 0}
                    onClick={() => {
                      if (!isSyncDisabled) {
                        startCircularProgress();
                        setIsSyncDisabled(true);
                        setReload(true);
                      }
                    }}
                    className="ml-4"
                  >
                    <Tooltip title="Sync Status" placement="top">
                      <Sync className={isLoading || executionTableData.length === 0 ? 'opacity-50' : 'text-gray-700'} />
                    </Tooltip>
                  </button>
                ) : (
                  <CircularProgressWithLabel value={progress} />
                ))}
            </div>
          </div>
        </div>
        <div
          className="table-height overflow-auto"
          onScroll={(e) => {
            handlescroll(e);
          }}
          id="journal-scroll"
        >
          <table {...getTableProps()} className="">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="fontPoppinsRegularSm uppercase h-12 ml-4 bg-gray-100 hover:bg-gray-100 sticky top-0 zIndex-1"
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      width={column.width}
                      className="pl-5 xs:pl-1 sm:pl-2 md:pl-3 lg:pl-4 xl:pl-5 text-left"
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="text fontPoppinsRegularSm">
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="configHover h-10 py-1 content-start">
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="p-0 pl-5 xs:pl-1 sm:pl-2 md:pl-3 lg:pl-4 xl:pl-5 text-left"
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {isLoading ? (
            <CommonLoader />
          ) : isEmptyValue(executionTableData) ? (
            <div className="fontPoppinsRegularSm no-steps">
              <p className="font-bold"> No data found</p>
            </div>
          ) : (
            <>{openSearch && isEmptyValue(data) && <NoResultsFound />}</>
          )}
        </div>

        {showWarningModal && (
          <TerminateWarningComponent
            executionData={selectedExeObject}
            setShowWarningModal={setShowWarningModal}
            reloadExecTab={reloadExecTab}
            terminateExecution={terminateExecution}
            MyAlert={props?.MyAlert}
            actionKey="Terminate"
          />
        )}

        {/* modal to edit execution name */}
        {openEditExecutionNameModal ? (
          <EditExecutionNameModal
            MyAlert={props.MyAlert}
            data={editExecutionNameData}
            closeModal={closeEditExecutionNameModal}
            reload={reloadExecTab}
          />
        ) : null}
        {/* modal for user details */}
        {openUserDetailsModal ? <UserDetailsModal data={userDetails} closeModal={closeUserDetailsModal} /> : null}

        {/* modal for machine details */}
        {openMachineDetailsModal ? (
          <MachineDetails data={machineDetails} closeModal={closeMachineDetailsModal} />
        ) : null}

        {/* modal for delete variable */}
        {openDeleteExecutionMachineModal ? (
          <DeleteExecutionMachine
            MyAlert={props.MyAlert}
            closeModal={closeDeleteExecutionModal}
            deleteData={deleteExecutionMachineModalData}
            reload={reloadExecTab}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ExecutionDashboard;

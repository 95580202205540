import commonStyles from '../auth/common.module.scss';
import cx from 'classnames';
import style from '../auth/SignupPage/signup.module.scss';
import SignUpNavbar from '../auth/SignupPage/SignUpNavbar';
import SignUpText from '../auth/SignupPage/SignUpText';

export default function CommonPage(props) {
  return (
    <>
      <div className={cx('flex flex-col min-h-screen', style['common-background-wrapper'])}>
        <div className={cx(style['SignUp_Container'])}>
          <div>
            <SignUpNavbar />
          </div>
          <div className="flex justify-between">
            <div className="">
              <SignUpText />
            </div>
            <div
              className={cx(
                props.marginTop ? props.marginTop : 'mt-10',
                commonStyles[props?.containerStyle ? props.containerStyle : 'content-container'],
                commonStyles[props?.wrapperClassname]
              )}
            >
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { ArrowDropDown, ArrowRight, ChevronRightOutlined, KeyboardArrowDownOutlined } from '@material-ui/icons';
import { Switch, withStyles, Tooltip, Select } from '@material-ui/core';
import { createPrePostConditionReq, getScriptReq, getStepGroupReq, updatePrePostConditionReq } from '@api/api_services';
import { Formik, Form as BaseForm } from 'formik';
import * as yup from 'yup';
import VariableSuggestion from '@src/pages/test-development/script/scripts/steps/VariableSuggestion';
import { getStepId } from '@src/pages/test-development/shared/common-methods';
import {
  getUrlPath,
  resourceNavigation,
} from '@src/pages/test-development/script/modules/module/utils/common-functions';
import useEscapeKeyCloseModal from '@src/hooks/useEscapeKeyCloseModal';
import { isEmptyValue } from '@util/common_utils';

const AntSwitch = withStyles((theme) => ({
  root: {
    marginRight: 15,
    width: 28,
    height: 16,
    padding: 0,
    display: 'inline-flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

function CreateEditScriptConditions(props) {
  const search = window.location.search;
  const [apiCompleteFlag, setApiCompleteFlag] = useState(false);
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  const formRef = useRef();
  const [createUpdateCalled, setCreateUpdateCalled] = useState(true);
  const [stepGroupResponse, setStepGroupResponse] = useState({});
  const history = useHistory();
  const divStyle = {
    position: 'absolute',
    left: '46.5%',
    right: '20%',
    top: '31%',
    height: 'fit-content',
    width: '52.75%',
    zIndex: '1',
    backgroundColor: ' #fff',
    boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
  };
  const [ifFailedOptions, setIfFailedOptions] = useState([
    {
      label: 'Mark this step as Failed and continue script execution',
      value: 'MARK_THIS_STEP_AS_FAILED_AND_CONTINUE_SCRIPT_EXECUTION',
    },
    {
      label: 'Mark this step as Warning and continue script execution',
      value: 'MARK_THIS_STEP_AS_WARNING_AND_CONTINUE_SCRIPT_EXECUTION',
    },
    {
      label: 'Mark this step as Failed and stop current iteration',
      value: 'MARK_THIS_STEP_AS_FAILED_AND_STOP_CURRENT_ITERATION',
    },
    {
      label: 'Mark this step as Failed and stop all iteration',
      value: 'MARK_THIS_STEP_AS_FAILED_AND_STOP_ALL_ITERATION',
    },
    {
      label: 'Mark this step as Failed and stop script execution',
      value: 'MARK_THIS_STEP_AS_FAILED_AND_STOP_SCRIPT_EXECUTION',
    },
    {
      label: 'Mark this step as Failed and stop current module execution',
      value: 'MARK_THIS_STEP_AS_FAILED_AND_STOP_CURRENT_MODULE_EXECUTION',
    },
    {
      label: 'Mark this step as Failed and stop complete execution',
      value: 'MARK_THIS_STEP_AS_FAILED_AND_STOP_COMPLETE_EXECUTION',
    },
  ]);
  const [ifCheckPointIsFailed, setIfCheckPointIsFailed] = useState(ifFailedOptions[0].value);
  const [returnValuePayload, setReturnValuePayload] = useState();
  const [status, setStatus] = useState(true);
  const [stepsOpen, setStepsOpen] = useState(true);
  const [stepsArr, setStepsArr] = useState([]);
  const [stepInputs, setStepInputs] = useState([]);
  const [initialValues, setInitialValues] = useState();
  const [validationSchema, setValidationSchema] = useState();
  const [returnTypeReference, setReturnTypeReference] = useState();
  const [hierarchy, setHierarchy] = useState();
  const [mustExecute, setMustExecute] = useState(true);
  const [onCancelReloadRequired, setOnCancelReloadRequired] = useState(false);
  const onCancelReloadStateHandler = () => {
    setOnCancelReloadRequired(true);
  };

  const initizationForFormik = (data, type) => {
    let valuesForInitilization = {};
    let validationfield = [];
    let stepInputs = data.stepInputs;
    if (stepInputs && stepInputs.length > 0) {
      stepInputs.forEach((stepInput, index) => {
        if (type === 'create') {
          valuesForInitilization[stepInput.name] = '';
          if (stepInput?.value && stepInput?.reference) {
            valuesForInitilization[stepInput.name] = stepInput.value;
          }
        } else if (type === 'update') {
          if (stepInput.value) {
            valuesForInitilization[stepInput.name] = stepInput.value;
          } else {
            valuesForInitilization[stepInput.name] = '';
          }
        }
        let obj = {
          id: stepInput.name,
          type: 'text',
          validationType: 'string',
          validations: [
            {
              type: 'required',
              params: [`${stepInput.name} is required`],
            },
          ],
        };
        validationfield.push(obj);
      });
    }
    if (data.returnType && data.returnType !== 'void' && type === 'create') {
      // setReturnTypeReference(data.stepGroupReferenceInfo.type);
      valuesForInitilization['returnValue'] = '';
      let obj = {
        id: 'returnValue',
        type: 'text',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Return Value is required'],
          },
        ],
      };
      validationfield.push(obj);
    } else if (data.stepGroupReferenceInfo && type === 'update') {
      let stepReferenceInfo = data.stepGroupReferenceInfo;
      setReturnTypeReference(stepReferenceInfo.type);
      valuesForInitilization['returnValue'] = stepReferenceInfo.name;
      let obj = {
        id: 'returnValue',
        type: 'text',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Return Value is required'],
          },
        ],
      };
      validationfield.push(obj);
    } else if (data.returnType && data.returnType !== 'void' && type === 'update') {
      valuesForInitilization['returnValue'] = '';
      let obj = {
        id: 'returnValue',
        type: 'text',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Return Value is required'],
          },
        ],
      };
      validationfield.push(obj);
    }
    if (valuesForInitilization && validationfield.length > 0) {
      const yepSchema = validationfield.reduce(createYupSchema, {});
      setInitialValues(valuesForInitilization);
      setValidationSchema(yup.object().shape(yepSchema));
    } else {
      setInitialValues({});
      setValidationSchema({});
    }
  };

  const createYupSchema = (schema, config) => {
    const { id, validationType, validations = [] } = config;
    if (!yup[validationType]) {
      return schema;
    }
    let validator = yup[validationType]();
    validations.forEach((validation) => {
      const { params, type } = validation;
      if (!validator[type]) {
        return;
      }
      validator = validator[type](...params);
    });
    schema[id] = validator;
    return schema;
  };

  const setVariableValue = (data, stepInputName, type) => {
    const stpInp = [...stepInputs];
    stpInp?.forEach((inputObj) => {
      if (inputObj.name === stepInputName) {
        if (type === 'variable') {
          inputObj.value = data.name;
          inputObj.reference = data?.reference;
        } else {
          inputObj.value = data;
          inputObj.reference = null;
        }
      }
    });
    setStepInputs(stpInp);
  };

  const setReturnValue = (data, stepInputName, type) => {
    setReturnValuePayload((preVal) => ({
      returnType: preVal.returnType,
      stepGroupReferenceInfo: {
        type: type,
        name: data.name,
      },
    }));
  };

  const removeStepRef = () => {
    if (returnValuePayload.stepGroupReferenceInfo) {
      setReturnValuePayload((preVal) => ({
        returnType: preVal.returnType,
        stepGroupReferenceInfo: null,
      }));
    }
  };

  const createPreCondition = async (data) => {
    try {
      const response = await createPrePostConditionReq(moduleId, scriptId, data);
      if (response.data && response.data.responseObject) {
        props.reload(true);
        bannerMessage(data.stepGroupName, 'created');
      } else {
        props.MyAlert.warning(`${response.data.message}`);
      }
      setCreateUpdateCalled(true);
      props.closeModal(true);
    } catch (err) {
      console.log(err);
    }
  };

  const updatePreCondition = async (data) => {
    try {
      const response = await updatePrePostConditionReq(moduleId, scriptId, data);
      if (response.data && response.data.responseObject) {
        props.reload(true);
        bannerMessage(data.stepGroupName, 'updated');
      } else {
        props.MyAlert.warning(`${response.data.message}`);
      }
      setCreateUpdateCalled(true);
      props.closeModal(true);
    } catch (err) {
      console.error('UPDATE_PRE_POST_CONDITION :', err);
    }
  };

  const bannerMessage = (title, key) => {
    props.MyAlert.success(`${title?.length > 22 ? title?.substring(0, 22) + '...' : title} ${key} successfully`);
  };

  const handleOnSubmit = () => {
    let stepReferenceInfoExist = false;
    if (returnValuePayload.returnType !== 'void') {
      if (returnValuePayload.stepGroupReferenceInfo) {
      } else {
        stepReferenceInfoExist = true;
        formRef.current.setFieldError('returnValue', 'Always use Variable for Return');
        setCreateUpdateCalled(true);
      }
    }
    if (stepReferenceInfoExist === false) {
      let requestBody;
      if (props.edit) {
        if (props.editData !== null) {
          requestBody = {
            id: props.editData?._id ? props.editData?._id : props.editData?.id,
            // "name": '',
            stepGroupId: props.data.parentId,
            stepGroupName: props.data.nlpName,
            stepInputs: stepInputs,
            type: props.type,
            steps: [],
            cascaded: false,
            cascadedFrom: '',
            status: status ? 'enabled' : 'disabled',
            mustExecute: mustExecute,
            ifCheckPointIsFailed: ifCheckPointIsFailed,
            platform: props.data.platform,
            executionOrder: props.editData.executionOrder,
            libraryId: props.data.libraryId,
            stepId: props.editData.stepId,
            returnType: returnValuePayload.returnType,
            stepReferenceInfo: returnValuePayload.stepGroupReferenceInfo,
            cascadedReference: props.editData?.cascadedReference,
            hierarchy: props?.editData?.hierarchy,
          };
        } else {
          requestBody = {
            id: props.data?._id ? props.data?._id : props.data?.id,
            // "name": '',
            stepGroupId: props.data.stepGroupId,
            stepGroupName: props.data.stepGroupName,
            stepInputs: stepInputs,
            type: props.type,
            steps: [],
            cascaded: props.data.cascaded,
            cascadedFrom: props.data.cascadedFrom,
            status: status ? 'enabled' : 'disabled',
            mustExecute: mustExecute,
            ifCheckPointIsFailed: ifCheckPointIsFailed,
            platform: props.data.platform,
            executionOrder: props.data.executionOrder,
            libraryId: props.data.libraryId,
            stepId: props.data.stepId,
            returnType: returnValuePayload.returnType,
            stepReferenceInfo: returnValuePayload.stepGroupReferenceInfo,
            cascadedReference: props.data?.cascadedReference,
            moduleName: props?.data?.moduleName,
            hierarchy: props?.data?.hierarchy,
          };
        }
        updatePreCondition(requestBody);
      } else {
        const stepId = getStepId();
        requestBody = {
          // "name": props.data.nlpName,
          stepGroupId: props.data.parentId,
          stepGroupName: props.data.nlpName,
          stepInputs: stepInputs,
          type: props.type,
          steps: [],
          cascaded: false,
          cascadedFrom: '',
          status: status ? 'enabled' : 'disabled',
          mustExecute: mustExecute,
          ifCheckPointIsFailed: ifCheckPointIsFailed,
          platform: props.data.platform,
          executionOrder: props.orderOfInsertion,
          libraryId: props.data.libraryId,
          stepId: stepId,
          returnType: returnValuePayload.returnType,
          stepReferenceInfo: returnValuePayload.stepGroupReferenceInfo,
          cascadedReference: 'CASDREF' + stepId,
          hierarchy: hierarchy,
        };
        createPreCondition(requestBody);
      }
    }
  };

  let accordionIcon = (elementId) => {
    return (
      <span>
        <ArrowRight className="expand-icon cursor-pointer" id={`${elementId}-expand`} />
        <ArrowDropDown className="collapse-icon cursor-pointer" id={`${elementId}-collapse`} />
      </span>
    );
  };
  let toggleAccordionIcon = (event, elementId) => {
    let expandElement = document.getElementById(elementId + '-expand');
    let collapseElement = document.getElementById(elementId + '-collapse');
    if (expandElement && expandElement.style) {
      if (expandElement.style.display === 'none') {
        expandElement.style.display = 'inline-block';
      } else if (expandElement.style.display !== 'none') {
        expandElement.style.display = 'none';
      }
    }
    if (collapseElement && collapseElement.style) {
      if (collapseElement.style.display === 'none' || collapseElement.style.display === '') {
        collapseElement.style.display = 'inline-block';
      } else if (collapseElement.style.display !== 'none') {
        collapseElement.style.display = 'none';
      }
    }
  };

  function stepGroup(stepGroup, stepGroupIndex, marginLeftSize) {
    return (
      <div>
        <div className="accordion">
          <div className="">
            <div
              className=""
              onClick={(e) => {
                toggleAccordionIcon(e, stepGroup?.name);
              }}
              style={{ marginLeft: marginLeftSize + 'rem' }}
            >
              {stepGroupIndex}.{stepGroup?.name}
              {accordionIcon(stepGroup?.name)}
            </div>
          </div>
        </div>
        <div className="panel">{steps(stepGroup?.steps, Number(marginLeftSize) + Number(1.25))}</div>
      </div>
    );
  }
  function steps(steps, marginLeftSize) {
    return (
      <div>
        {steps.map((step, index) => (
          <div key={'_' + index + '_' + index}>
            {!(step?.steps && step.steps.length > 0) ? (
              <div className="" key={`step${index}`}>
                <div
                  className="py-1"
                  style={{
                    marginLeft: marginLeftSize !== undefined ? marginLeftSize + 'rem' : '0.5rem',
                  }}
                >
                  {index + 1}.{step?.name}
                </div>
              </div>
            ) : (
              <div>{stepGroup(step, index + 1, marginLeftSize ? marginLeftSize : 0.5)}</div>
            )}
          </div>
        ))}
      </div>
    );
  }
  async function getSteps(val) {
    try {
      let libId = val.libraryId;
      let stepGrpId = val.stepGroupId ? val.stepGroupId : val.parentId;
      const res = await getStepGroupReq(libId, stepGrpId);
      if (res.data && res.data.responseObject) {
        setStepGroupResponse(res.data.responseObject);
        setStepsArr(res.data.responseObject.steps);
        setApiCompleteFlag(true);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const isRepositoryEditable = React.useMemo(() => window.permission.isEditAccess('repository'), []);

  function navigateToStepGroupPage() {
    const { name, id, parentId, defaultStepGroup, path, searchKey } = stepGroupResponse || {};
    const nameArray = path?.split('/')?.slice(1) || [];
    const searchKeyArray = searchKey?.split('/')?.slice(1) || [];
    const { json } = resourceNavigation({
      searchKeyArray,
      nameArray,
      isTestDev: false,
      rootName: 'Root Library',
    });
    json.splice(0, 1);
    localStorage.setItem('parentAndSonJson', JSON.stringify(json));
    history.push(
      `/repository/step_group/${getUrlPath(
        json
      )}Steps?id=${id}&parentId=${parentId}&stepGroup=${name}&defaultStepGroup=${defaultStepGroup}`
    );
  }
  function returnTouchedField(values) {
    let touchedField = {};
    for (var key in values) {
      if (values.hasOwnProperty(key)) {
        if (!values[key]) {
          touchedField[key] = true;
        }
      }
    }
    return touchedField;
  }
  async function getScriptData() {
    try {
      // const search = window.location.search;
      // const moduleId = new URLSearchParams(search).get("moduleId");
      // const scriptId = new URLSearchParams(search).get("scriptId");
      const response = await getScriptReq(moduleId, scriptId);
      if (response?.data && response?.data?.responseObject) {
        setHierarchy(Number(response?.data?.responseObject?.hierarchy) + 1);
      }
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    var acc = document.getElementsByClassName('accordion');
    var i;
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener('click', function () {
        this.classList.toggle('active');
        var panel = this.nextElementSibling;
        if (panel) {
          if (panel.style.display === 'block') {
            panel.style.display = 'none';
          } else {
            panel.style.display = 'block';
          }
        }
      });
    }
  }, []);
  useEffect(() => {
    if (props?.edit) {
      if (props.editData !== null) {
        setIfCheckPointIsFailed(props.editData.ifCheckPointIsFailed);
        setStatus(props.editData.status === 'disabled' ? false : true);
        setMustExecute(props.editData.mustExecute);
      } else {
        if (props?.data?.cascaded === 'cascaded') {
          if (!ifFailedOptions.filter((opt) => opt.value === props.data.ifCheckPointIsFailed).length) {
            let ifFailed = props.data.ifCheckPointIsFailed.replace(/_/g, ' ').toLowerCase();
            setIfFailedOptions([
              {
                label: `M${ifFailed.slice(1, 18)}${ifFailed[18].toUpperCase()}${ifFailed.slice(19)}`,
                value: props.data.ifCheckPointIsFailed,
              },
              ...ifFailedOptions,
            ]);
          }
        }
        setIfCheckPointIsFailed(props.data.ifCheckPointIsFailed);
        setStatus(props.data.status === 'disabled' ? false : true);
        setMustExecute(props.data.mustExecute);
      }
    }
    setStepInputs(props.data.stepInputs);
    getSteps(props.data);
  }, [props.data, props.edit, props.editData]);
  useEffect(() => {
    if (apiCompleteFlag) {
      const retType =
        stepGroupResponse.returnType === 'void'
          ? 'void'
          : stepGroupResponse.returnType !== 'void' && props?.data?.returnType !== 'void' && props.edit
            ? props?.data?.returnType
            : stepGroupResponse.returnType;
      const stpRefInf =
        stepGroupResponse.returnType === 'void'
          ? null
          : stepGroupResponse.returnType !== 'void' && props?.data?.returnType !== 'void' && props.edit
            ? props?.data?.stepReferenceInfo
            : null;
      setReturnValuePayload({
        returnType: retType,
        stepGroupReferenceInfo: stpRefInf,
      });
      const val = {
        stepInputs: stepInputs,
        returnType: retType,
        stepGroupReferenceInfo: stpRefInf,
      };
      if (props.edit) {
        initizationForFormik(val, 'update');
      } else {
        initizationForFormik(val, 'create');
      }
    }
    setApiCompleteFlag(false);
  }, [apiCompleteFlag]);
  useEffect(() => {
    getScriptData();
  }, []);

  const handleEscapeClose = () => {
    props.closeModal(false);
    if (onCancelReloadRequired) {
      props.reload(true);
    }
  };
  const getTitleText = (props) => {
    if (props.edit) {
      if (props.editData !== null) {
        return props.data.nlpName;
      }
      return props.data.stepGroupName;
    }
    return props.data.nlpName;
  };
  const getFormattedText = (text) => {
    if (!text) return '';

    const maxLength = 30;
    return `${text.substring(0, maxLength)}${text.length > maxLength ? '...' : ''}`;
  };

  // Define the getTitleText function
  const getTitleTextData = (props) => {
    if (props.edit) {
      if (props.editData !== null) {
        return getFormattedText(props.data.nlpName);
      }
      return getFormattedText(props.data.stepGroupName);
    }
    return getFormattedText(props.data.nlpName);
  };

  useEscapeKeyCloseModal(true, handleEscapeClose);

  return (
    <>
      <div id="step-group-input-parameter" className="shadow-md responsive-parameter-div" style={divStyle}>
        <div className="mt-2">
          <span className="Popup-header-common pl-5">Step Group Input Parameters</span>
        </div>
        <div className="">
          <div
            className="border-b border-t border-blue-100 mt-2 mb-2 overflow-y-auto responsive-parameter-div-height relative"
            id="journal-scroll"
          >
            <div className="input-filed-label-style-common fontPoppinsRegularMd mt-3 ml-6 mr-px" id="journal-scroll">
              <div className="">
                <div className="inline-block">
                  <span>Step Group</span>
                  <Tooltip title={getTitleText(props)} placement="right">
                    <p className="text-blue-700 mt-1">{getTitleTextData(props)}</p>
                  </Tooltip>
                </div>
                {isRepositoryEditable && (
                  <span
                    onClick={navigateToStepGroupPage}
                    className="text-blue-700 mt-4 mr-1.5 ml-auto float-right cursor-pointer"
                  >
                    Go to step group
                  </span>
                )}
              </div>
              <div className=" mt-2 fontPoppinsRegularMd">
                <div className="inline-block">
                  <label htmlFor="status" className={'input-filed-label-style-common block'}>
                    Status
                  </label>
                  <div className="mt-3">
                    <AntSwitch
                      checked={status}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setStatus(true);
                        } else {
                          setStatus(false);
                        }
                      }}
                      name="status"
                      className="inline-block"
                    />
                    <span className="input-filed-label-style-common">{status ? 'Enabled' : 'Disabled'}</span>
                  </div>
                </div>
                {props?.type === 'POST' && (
                  <div className="ml-12 inline-block">
                    <label htmlFor="mustExecute" className={'input-filed-label-style-common block'}>
                      Must Execute
                    </label>
                    <div className="mt-3">
                      <AntSwitch
                        checked={mustExecute}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setMustExecute(true);
                          } else {
                            setMustExecute(false);
                          }
                        }}
                        name="mustExecute"
                        className="inline-block"
                      />
                      <span className="text-gray-700">{mustExecute ? 'Enabled' : 'Disabled'}</span>
                    </div>
                  </div>
                )}
              </div>
              <div>
                {initialValues && validationSchema ? (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    innerRef={formRef}
                    enableReinitialize={true}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleReset,
                      setTouched,
                      setFieldValue,
                      setFieldError,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                    }) => {
                      return (
                        <BaseForm>
                          <div>
                            <div className="ml-6">
                              {!isEmptyValue(stepInputs?.length) && (
                                <div className="mt-3 grid grid-cols-2 gap-4">
                                  {stepInputs.map((input, inputIndex) => (
                                    <div key={input.name} className="mt-2">
                                      <div>
                                        <VariableSuggestion
                                          reloadStepsTable={onCancelReloadStateHandler}
                                          stepInputType={input.type}
                                          localVariableData={[]}
                                          moduleId={moduleId}
                                          scriptId={scriptId}
                                          id={'variables' + inputIndex}
                                          stepInputName={input?.name}
                                          setVariableValue={setVariableValue}
                                          editData={input?.value}
                                          type={props.type}
                                          reference={input?.reference}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          setFieldValue={setFieldValue}
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                            {stepGroupResponse.returnType ? (
                              stepGroupResponse.returnType !== 'void' ? (
                                <div className="mt-2">
                                  <div className="ml-7">
                                    {
                                      <VariableSuggestion
                                        reloadStepsTable={onCancelReloadStateHandler}
                                        stepInputType={stepGroupResponse.returnType}
                                        localVariableData={[]}
                                        moduleId={moduleId}
                                        scriptId={scriptId}
                                        id={'return-value'}
                                        stepInputName={'returnValue'}
                                        setReturnValue={setReturnValue}
                                        editData={stepGroupResponse?.stepGroupReferenceInfo?.name}
                                        type={props.type}
                                        reference={returnTypeReference}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        setFieldValue={setFieldValue}
                                        setFieldError={setFieldError}
                                        removeStepRef={removeStepRef}
                                      />
                                    }
                                  </div>
                                </div>
                              ) : (
                                ''
                              )
                            ) : (
                              ''
                            )}
                          </div>
                        </BaseForm>
                      );
                    }}
                  </Formik>
                ) : null}
              </div>

              <div className="border-t border-b border-dashed mt-2.5 mb-2.5 mr-6 fontPoppinsRegularMd border-blue-100 p-2">
                <span>
                  Steps
                  {stepsOpen ? (
                    <ChevronRightOutlined
                      onClick={() => {
                        setStepsOpen(false);
                      }}
                      fontSize="small"
                      className="text-blue-700 cursor-pointer"
                    />
                  ) : (
                    <KeyboardArrowDownOutlined
                      onClick={() => {
                        setStepsOpen(true);
                      }}
                      fontSize="small"
                      className="text-blue-700 cursor-pointer"
                    />
                  )}
                  {!stepsOpen ? (
                    stepsArr && stepsArr.length > 0 ? (
                      stepsArr !== undefined && steps(stepsArr, '0.5')
                    ) : (
                      <p className="ml-2">No steps</p>
                    )
                  ) : null}
                </span>
              </div>
              <div className="mb-10">
                <label htmlFor="ifFailedStatus" className="input-filed-label-style-common">
                  <span className="text-red-400">&#42;</span>If Failed
                </label>
                <div className='text-sm'>
                  <Select
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                    }}
                    name="ifFailedStatus"
                    id="ifFailedStatus"
                    value={ifCheckPointIsFailed}
                    onChange={(e) => {
                      setIfCheckPointIsFailed(e.target.value);
                    }}
                    className="w-3/5 text-sm text-gray-500 mt-0.5"
                  >
                    {ifFailedOptions.map((opt) => (
                      <option
                        key={opt.label}
                        className="hover:text-blue-700 cursor-pointer hover:bg-blue-100 text-sm"
                        value={opt.value}
                      >
                        {opt.label}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <div className="float-right mt-1 mr-4 mb-3 footer">
            <button
              onClick={() => {
                props.closeModal(false);
                if (onCancelReloadRequired) {
                  props.reload(true);
                }
              }}
              type="button"
              className="py-1.5 px-3.5 gray-btn fontPoppinsMediumMd"
            >
              Cancel
            </button>
            <button
              // disabled={hierarchy >= 0 ? false : true }
              onClick={() => {
                if (returnValuePayload.returnType !== 'void') {
                  if (!returnValuePayload.stepGroupReferenceInfo) {
                    formRef.current.setFieldError('returnValue', 'Always use Variable for Return');
                    setCreateUpdateCalled(true);
                  }
                }
                if (initialValues && validationSchema) {
                  let obj = returnTouchedField(formRef.current.values);
                  if (Object.keys(obj).length !== 0) {
                    formRef.current.setTouched(obj);
                  } else {
                    if (createUpdateCalled) {
                      setCreateUpdateCalled(false);
                      handleOnSubmit();
                    }
                  }
                }
              }}
              type="button"
              className="ml-4 primary-btn fontPoppinsMediumMd"
            >
              {props.edit ? 'Update' : 'Add'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateEditScriptConditions;

import React, { useContext, useMemo } from 'react';
import { FormGroup, FormControlLabel, FormControl, Checkbox } from '@material-ui/core';
import style from '@pages/licenses/styles/license-form.module.scss';
import LabeledInput from '@pages/common/Inputs/LabeledInput';
import { allowWholeNumbersOnlyInNumberInputOnKeyDown } from '@src/util/common_utils';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { colors } from '@src/css_config/colorConstants';
import { LICENSE_DETAILS_CONSTANTS } from '@src/common/ui-constants';
import { LicenseWizardContext } from './LicenseProvider';
import { LICENSE_FORM_TYPES } from '@src/util/licenseUtils';
export default function LicenseFeatures(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    indeterminate: {
      '&.MuiCheckbox-root': {
        color: colors.blue_dark,
      },
    },
  }));
  const classes = useStyles();
  const { formik, formType } = useContext(LicenseWizardContext);
  const isUpgrade = formType === LICENSE_FORM_TYPES?.upgrade?.type;
  const isCProfessional = formik?.values?.licensesType?.type === 'C-Professional';

  const {
    isManualDisabled,
    isWebServiceDisabled,
    isWebDisabled,
    isMobileDisabled,
    isSalesforceDisabled,
    isMsDynamicsDisabled,
    isDefectsDisabled,
  } = useMemo(
    () => ({
      isManualDisabled:
        props.isBasicLicenseType ||
        (Array.isArray(props.isUpgradeType)
          ? props.isUpgradeType.includes('ManualTestcases')
          : props.isUpgradeType === 'ManualTestcases'),

      isWebServiceDisabled:
        props.isBasicLicenseType ||
        (Array.isArray(props.isUpgradeType)
          ? props.isUpgradeType.includes('WebService')
          : props.isUpgradeType === 'WebService'),

      isWebDisabled:
        props.isBasicLicenseType ||
        (Array.isArray(props.isUpgradeType)
          ? props.isUpgradeType.includes(LICENSE_DETAILS_CONSTANTS?.WEB)
          : props.isUpgradeType === LICENSE_DETAILS_CONSTANTS?.WEB),

      isMobileDisabled:
        props.isBasicLicenseType ||
        (Array.isArray(props.isUpgradeType)
          ? props.isUpgradeType.includes(LICENSE_DETAILS_CONSTANTS?.MOBILE)
          : props.isUpgradeType === LICENSE_DETAILS_CONSTANTS?.MOBILE),

      isSalesforceDisabled:
        props.isBasicLicenseType ||
        (Array.isArray(props.isUpgradeType)
          ? props.isUpgradeType.includes(LICENSE_DETAILS_CONSTANTS?.SALESFORCE)
          : props.isUpgradeType === LICENSE_DETAILS_CONSTANTS?.SALESFORCE),

      isMsDynamicsDisabled:
        props.isBasicLicenseType ||
        (Array.isArray(props.isUpgradeType)
          ? props.isUpgradeType.includes('MSDynamics')
          : props.isUpgradeType === 'MSDynamics'),

      isDefectsDisabled:
        props.isBasicLicenseType ||
        (Array.isArray(props.isUpgradeType)
          ? props.isUpgradeType.includes('Defects')
          : props.isUpgradeType === 'Defects'),
    }),
    [props.isBasicLicenseType, props.isUpgradeType]
  );

  const isAutomationDisabled =
    (isWebServiceDisabled && isWebDisabled && isMobileDisabled && isSalesforceDisabled && isMsDynamicsDisabled) ||
    (props?.isIndeterminate && isUpgrade && isCProfessional && !props?.isUpgradeCBasic);

  return (
    <>
      <FormControl className={cx(style['checked-wrapper'], classes.root, 'fontPoppinsRegularSm')}>
        <FormControlLabel
          className={'w-1/2 pointer-events-none fontPoppinsRegularSm'}
          label={
            <span className={cx(style['license-lable-text'], 'fontPoppinsRegularSm')}>
              {LICENSE_DETAILS_CONSTANTS?.AUTOMATION}
            </span>
          }
          control={
            <Checkbox
              id={'1'}
              name={LICENSE_DETAILS_CONSTANTS?.AUTOMATION}
              value={props.value}
              checked={props.isAutomation}
              indeterminate={props.isIndeterminate}
              onChange={props.onFeatureChange}
              style={{ padding: 2, pointerEvents: 'auto' }}
              className={props.isIndeterminate ? classes.indeterminate : ''}
              disabled={props.isBasicLicenseType || isAutomationDisabled}
              size="small"
            />
          }
        />
        <FormGroup
          row
          aria-labelledby="radio-buttons-group-label"
          className="ml-5 mt-2 py-1"
          onChange={props.onServiceChange}
        >
          <FormControlLabel
            value="WebService"
            className="pointer-events-none"
            style={{ marginRight: '30px' }}
            control={
              <Checkbox
                size="small"
                id={'1'}
                name={'WebService'}
                checked={props.servicesState.WebService}
                disabled={isWebServiceDisabled}
                className="pointer-events-auto"
                style={{ padding: 2 }}
              />
            }
            label={
              <div className={cx('fontPoppinsRegularSm', style['license-lable-text'])}>
                {LICENSE_DETAILS_CONSTANTS?.WEBSERVICE}
              </div>
            }
          />
          <FormControlLabel
            value={LICENSE_DETAILS_CONSTANTS?.WEB}
            className="pointer-events-none"
            style={{ marginRight: '30px' }}
            control={
              <Checkbox
                size="small"
                id={'2'}
                name={LICENSE_DETAILS_CONSTANTS?.WEB}
                checked={props.servicesState.Web}
                disabled={isWebDisabled}
                className="pointer-events-auto"
                style={{ padding: 2 }}
              />
            }
            label={
              <div className={cx('fontPoppinsRegularSm', style['license-lable-text'])}>
                {LICENSE_DETAILS_CONSTANTS?.WEB}
              </div>
            }
          />
          <FormControlLabel
            value={LICENSE_DETAILS_CONSTANTS?.MOBILE}
            className="pointer-events-none"
            style={{ marginRight: '30px' }}
            control={
              <Checkbox
                size="small"
                id={'3'}
                name={LICENSE_DETAILS_CONSTANTS?.MOBILE}
                checked={props.servicesState.Mobile}
                disabled={isMobileDisabled}
                className="pointer-events-auto"
                style={{ padding: 2 }}
              />
            }
            label={
              <div className={cx('fontPoppinsRegularSm', style['license-lable-text'])}>
                {LICENSE_DETAILS_CONSTANTS?.MOBILE}
              </div>
            }
          />
          <FormControlLabel
            value={LICENSE_DETAILS_CONSTANTS?.SALESFORCE}
            className="pointer-events-none"
            style={{ marginRight: '30px' }}
            control={
              <Checkbox
                size="small"
                id={'4'}
                name={LICENSE_DETAILS_CONSTANTS?.SALESFORCE}
                style={{ padding: 2 }}
                checked={props.servicesState.Salesforce}
                disabled={isSalesforceDisabled}
                className="pointer-events-auto"
              />
            }
            label={
              <div className={cx('fontPoppinsRegularSm', style['license-lable-text'])}>
                {LICENSE_DETAILS_CONSTANTS?.SALESFORCE}
              </div>
            }
          />
          <FormControlLabel
            value="MSDynamics"
            className="pointer-events-none"
            control={
              <Checkbox
                size="small"
                id={'5'}
                name={'MSDynamics'}
                style={{ padding: 2 }}
                checked={props.servicesState.MSDynamics}
                disabled={isMsDynamicsDisabled}
                className="pointer-events-auto"
              />
            }
            label={
              <div className={cx('fontPoppinsRegularSm', style['license-lable-text'])}>
                {LICENSE_DETAILS_CONSTANTS?.MSDYNAMICS}
              </div>
            }
          />
        </FormGroup>
        {
          <div className={cx(props.isNoAutomationSelected ? style['disabled-parallel-runs'] : '', 'mt-2')}>
            <LabeledInput
              isLicenseBuyPage={true}
              label={
                <span className={cx('fontPoppinsRegularSm', style['license_select_label'])}>
                  {LICENSE_DETAILS_CONSTANTS?.PARALLEL_RUNS}
                </span>
              }
              required
              type="number"
              InputProps={{
                inputProps: {
                  step: '1',
                  min: 0,
                  className: 'default-input fontPoppinsRegularSm',
                },
              }}
              placeholder="Enter number of parallel runs"
              error={props.formikData.errors.numberOfParallelRuns && props.formikData.touched.numberOfParallelRuns}
              value={props.formikData.values.numberOfParallelRuns}
              onBlur={props.formikData.handleBlur}
              onChange={props.handleParallelRunsChange}
              onKeyDown={allowWholeNumbersOnlyInNumberInputOnKeyDown}
              autoComplete="off"
              name="numberOfParallelRuns"
              helperText={
                <div className="w-72 -ml-3 mb-4">
                  {props.formikData.touched.numberOfParallelRuns && props.formikData.errors.numberOfParallelRuns}
                </div>
              }
              wrapperClassName="flex-initial w-60 h-9 fontPoppinsRegularSm"
              disabled={props.parallelRunLimits.min === props.parallelRunLimits.max && props.isBasicLicenseType}
              style={{ marginTop: '5px' }}
            />
          </div>
        }
        <FormControlLabel
          className={'py-1 w-1/2 pointer-events-none mt-6'}
          label={
            <span className={cx(style['license-lable-text'], 'fontPoppinsRegularSm')}>
              {LICENSE_DETAILS_CONSTANTS?.MANUALTESTCASEMANAGEMENT}
            </span>
          }
          control={
            <Checkbox
              id={'2'}
              name={'ManualTestcases'}
              checked={props.isManual ? props.isManual : props.isBasicLicenseType}
              onChange={props.onFeatureChange}
              style={{ padding: 2 }}
              disabled={isManualDisabled}
              size="small"
              className="pointer-events-auto"
            />
          }
        />
        <FormControlLabel
          className={'py-1 w-1/2 mt-1 pointer-events-none'}
          label={
            <span className={cx(style['license-lable-text'], 'fontPoppinsRegularSm')}>
              {LICENSE_DETAILS_CONSTANTS?.DEFECTS_MANAGEMENT}
            </span>
          }
          control={
            <Checkbox
              id={'3'}
              name={'Defects'}
              checked={props.isDefects ? props.isDefects : props.isBasicLicenseType}
              onChange={props.onFeatureChange}
              style={{ padding: 2 }}
              disabled={isDefectsDisabled}
              size="small"
              className="pointer-events-auto"
            />
          }
        />
      </FormControl>
    </>
  );
}

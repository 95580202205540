import React, { useEffect, useState, useContext } from 'react';
import { TextField } from '@material-ui/core';
import { ReactComponent as AddVariableIcon } from './add-variable-icon.svg';
import VariableSelectorMyInput from '../variable-selector-myinput';
import CreateVariable from '../../test-development/shared/variables/create-edit-variables';
import VariablesDetailsModal from '../../test-development/shared/variables/variables-details';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { ASSERT_CONSTANT } from '../../test-development/script/scripts/webservice/restapi/request/assert/jsx/constant';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';
import { WEBSERVICE } from '@src/pages/test-development/script/scripts/webservice/restapi/constants/constants';
import { RestContext } from '@pages/test-development/script/scripts/webservice/restapi/restapi';
import { calculatePosition } from '@util/common_utils';
import '@pages/test-development/script/scripts/webservice/restapi/request/request_body/css/Ace_Editor.css' 

const theme = createTheme({
  overrides: {
    MuiInput: {
      underline: {
        '&:hover:not($disabled):after': {
          borderColor: '#989797 !important',
        },
        '&:hover:not($disabled):before': {
          borderColor: '#989797 !important',
        },
        '&&:hover::before': {
          borderColor: '#989797 !important',
        },
        '&&:hover::after': {
          borderColor: '#989797 !important',
        },
      },
    },
  },
});

function MyInput({ error, canCreateVariable = true, ...props }) {
  const [ShowDropDown, setShowDropDown] = useState(false);
  const [DropDownValue, setDropDownValue] = useState('');
  const [VariableName, setVariableName] = useState('');
  const [ShowEditForm, setShowEditForm] = useState(false);
  const [EditData, setEditData] = useState(null);
  const [data, setData] = useState(null);
  const [ShowDetails, setShowDetails] = useState(false);
  const [ShowAddIcon, setShowAddIcon] = useState(false);
  const [inputValue, setInputValue] = useState('');
  let viewVariableLabel = 'View variable details',
    editVariableLabel = 'Edit variable';
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  const inputRef = React.useRef(null);
  const [inputIndex, setInputIndex] = useState(0);
  const libraryId = new URLSearchParams(search).get('parentId');
  const context = useContext(RestContext);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const { INPUT_CHAR_HEIGHT, URL_INPUT_END } = WEBSERVICE;

  useEffect(() => {
    const currentInputIndex = props?.currentValue?.target?.selectionStart - 1;
    if (
      (props?.currentValue?.nativeEvent?.data === '$' ||
        (props?.value && props?.value[props?.value?.length - 1] === '$')) &&
      props?.currentValue?.type === 'change'
    ) {
      setShowDropDown(true);
      setShowAddIcon(false);
      setInputValue(props.value);
      setInputIndex(currentInputIndex);
      setTimeout(calculateDropdownPosition, 0);
    } else {
      setShowAddIcon(true);
      setVariableName(props.value);
    }
    if (props.value === DropDownValue) {
      setEditData(data);
      setShowAddIcon(false);
    } else {
      if (props?.getUserInputValue) {
        const value = props?.getUserInputValue(props.value, false);
        if ((value && props.value && value !== props.value) || (value.startsWith('${DPV_') && value.includes(':'))) {
          setShowAddIcon(false);
        } else {
          setShowAddIcon(true);
        }
      } else {
        setShowAddIcon(true);
      }
      setEditData(null);
      setVariableName(props.value);
    }
  }, [props.value, data, DropDownValue]);

  const calculateDropdownPosition = () => {
    const inputElement = inputRef.current;
    if (!inputElement) return;
    const { selectionStart, value, scrollLeft } = inputElement;
    const { left, top, height } = inputElement.getBoundingClientRect();
    const { paddingLeft, paddingTop, font } = window.getComputedStyle(inputElement);
    const context = document.createElement('canvas').getContext('2d');
    context.font = font;
    const textWidth = context.measureText(value?.slice(0, selectionStart)).width;
    const dropdownLeft = left + parseFloat(paddingLeft) + textWidth - scrollLeft;
    const dropdownTop = top + height + parseFloat(paddingTop) - INPUT_CHAR_HEIGHT;
    setDropdownPosition({ top: dropdownTop, left: dropdownLeft > URL_INPUT_END ? URL_INPUT_END : dropdownLeft-INPUT_CHAR_HEIGHT });
  };

  const onClickGetDropDownValue = (name, data) => {
    if (props?.onSetVariableType) {
      props?.onSetVariableType(data.type);
    }
    let dollarIndex = inputIndex;
    const variableName = onSetInputValue(name, data.type, data);
    let tempValue =
      inputValue.substring(0, dollarIndex) + variableName + inputValue.substring(dollarIndex + 1, inputValue.length);
    let tempVariable =
      inputValue.substring(0, dollarIndex) +
      '${' +
      data.id +
      '}' +
      inputValue.substring(dollarIndex + 1, inputValue.length);
    const event = {
      target: {
        value: tempValue,
        name: props.name,
        variableId: tempVariable,
      },
    };
    props.onChange(event);
    setShowDropDown(false);
    setShowAddIcon(false);
    setDropDownValue(variableName);
    if (data) {
      setEditData(data);
      setData(data);
    }
    inputRef.current.focus();
  };

  const onClickShowEditForm = () => {
    setShowEditForm(true);
  };

  const onClickCloseEditForm = () => {
    setShowEditForm(false);
  };

  const variableClick = (data, type) => {
    if (props?.onSetVariableType) {
      props?.onSetVariableType(type);
    }
    props.onSetAllVariableName(data, type);
    setEditData(data);
    setData(data);
    let tempValue = onSetInputValue(data.name, type, data);
    setDropDownValue(tempValue);

    const event = {
      target: {
        value: tempValue,
        name: props.name,
        variableId: '${' + data.id + '}',
        data,
        action: ASSERT_CONSTANT.CREATE,
      },
    };
    props.onChange(event);
  };

  const onSetInputValue = (name, type, data) => {
    if (['LOCAL', 'Step'].includes(type) && data?.parentVariableType === 'STEPGROUP') {
      return '${SGV_' + name + '}';
    } else if (type === 'LOCAL') {
      return '${LV_' + name + '}';
    } else if (type === 'GLOBAL') {
      return '${GV_' + name + '}';
    } else if (type === 'PROJECT_ENVIRONMENT') {
      return '${PEV_' + name + '}';
    } else if (ASSERT_CONSTANT.PARAMETER_TYPE.includes(type)) {
      return '${SGP_' + name + '}';
    } else if (name === WEBSERVICE.FOR_LOOP_INITIAL_VALUE) {
      return '${FLV_' + name + '}';
    } else {
      return '${DPV_' + name + '}';
    }
  };

  const onClickShowDetails = () => {
    setShowDetails(true);
  };

  const onClickCloseDetails = () => {
    setShowDetails(false);
  };

  const renderVariableIcon = () => {
    const selectedProjectDetails = JSON.parse(localStorage.getItem('selected-project'));
    const selectedProjectStatus = selectedProjectDetails?.status;
    return (
      <>
        {props.value &&
          props.value !== '$' &&
          ShowAddIcon &&
          canCreateVariable &&
          props.variableInput &&
          !props?.disabled &&
          selectedProjectStatus !== 'Closed' && (
            <CustomTooltip inputVariableIcon="inputVariableIcon" title="Create as variable">
              <AddVariableIcon
                className="float-right cursor-pointer text-rs-primary mt-2 mr-0 ml-2"
                onClick={onClickShowEditForm}
              />
            </CustomTooltip>
          )}
      </>
    );
  };
  const rendorVariableSelectorInput = () => {
    return (
      <>
        {ShowDropDown && props.variableInput && props?.value && (
          <VariableSelectorMyInput
            ShowDropDown={ShowDropDown}
            onClickGetValue={onClickGetDropDownValue}
            setShowDropDown={setShowDropDown}
            label={props?.label}
            positionStyle={props?.name === ASSERT_CONSTANT.REQUEST_URL ? calculatePosition(dropdownPosition, true) : {}}
            {...props}
          />
        )}
      </>
    );
  };

  const renderVariableModal = () => {
    return (
      <>
        {ShowEditForm && (
          <CreateVariable
            data={EditData}
            closeModal={onClickCloseEditForm}
            variableClick={variableClick}
            variableName={VariableName}
            type={libraryId ? 'Step Group' : props.type}
            variableType={libraryId ? 'Step' : 'LOCAL'}
            id={{ moduleId: moduleId, scriptId: scriptId }}
            inputFieldName={props?.inputFieldName}
            MyAlert={props.MyAlert}
            label={props?.label}
          />
        )}
        {ShowDetails && <VariablesDetailsModal closeModal={onClickCloseDetails} data={EditData} />}
      </>
    );
  };
  const handleBlur = async () => {
    setTimeout(() => {
      if (props?.value?.indexOf('$') > -1) {
        setShowDropDown(false);
      }
    }, 500);
    if (props.onRequestUrlFocus) {
      props.onRequestUrlFocus(false);
    }
    if (['requestName', 'requestUrl'].includes(props?.name)) {
      props.onValidateRequestInput();
    }
  };
  const handleFocus = (e) => {
    setTimeout(() => {
      const index = e.target.selectionStart - 1;
      if (index >= 0 && props?.value?.[index] === '$' && props?.value?.[index + 1] !== '{') {
        setShowDropDown(true);
        setInputIndex(index);
        setInputValue(props.value);
      }

      if (props.onRequestUrlFocus) {
        props.onRequestUrlFocus(true);
      }
    }, 0);
  };
  const renderVariableDetails = () => {
    return (
      <>
        {props?.variableViewEdit && props.value && props.value === DropDownValue && (
          <div>
            <div
              className="text-xs text-blue-500 cursor-pointer float-left"
              onClick={onClickShowDetails}
              style={{ cursor: 'pointer' }}
            >
              {viewVariableLabel}
            </div>
            <div
              className="text-xs text-blue-500 cursor-pointer float-right"
              onClick={onClickShowEditForm}
              style={{ cursor: 'pointer' }}
            >
              {editVariableLabel}
            </div>
          </div>
        )}
      </>
    );
  };
  const handleOnPaste = (e) => {
    const requestUrlText = e.clipboardData.getData('text');
    if (props?.value === requestUrlText) {
      props.handleSaveAsSteps(false);
    }
  };
  useEffect(() => {
    if (props?.onRequestUrlValidation && context.values.requestUrl) {
      props?.onRequestUrlValidation(props?.value);
    }

    if (props?.onRequestNameValidation && context.values.requestName) {
      props?.onRequestNameValidation(props?.value);
    }
  }, [context?.values?.requestUrl]);

  return props?.inputValue === 'input' ? (
    <>
      <div className="request-name-textfield">
        <input
          onFocus={handleFocus}
          onBlur={handleBlur}
          ShowDropDown={ShowDropDown}
          className={`input fontPoppinsRegularMd`}
          onPaste={handleOnPaste}
          ref={inputRef}
          {...props}
        />
        {renderVariableIcon()}
        {renderVariableDetails()}
        {renderVariableModal()}
      </div>
      {rendorVariableSelectorInput()}
    </>
  ) : props?.variableInput === undefined || props?.variableInput === '' ? (
    <ThemeProvider theme={theme}>
      <TextField
        onFocus={handleFocus}
        onBlur={handleBlur}
        ShowDropDown={ShowDropDown}
        error={error}
        style={{ width: '100%' }}
        className="input"
        InputLabelProps={{
          shrink: true,
        }}
        onPaste={handleOnPaste}
        ref={inputRef}
        {...props}
      />
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <TextField
        onFocus={handleFocus}
        onBlur={handleBlur}
        ShowDropDown={ShowDropDown}
        error={error}
        className="input"
        InputLabelProps={{
          shrink: true,
        }}
        onPaste={handleOnPaste}
        ref={inputRef}
        {...props}
      />
      {renderVariableIcon()}
      {renderVariableDetails()}
      {rendorVariableSelectorInput()}
      {renderVariableModal()}
    </ThemeProvider>
  );
}

export default MyInput;

import React, { useState, useEffect } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import ExeComparisonTableLeft from './common/ExeComparisonTableLeft';
import ExeComparisonTableRight from './common/ExeComparisonTableRight';
import Chart from 'react-apexcharts';
import { getComparisonSuiteReq, getScriptResultReq } from '@api/api_services';
import { colors } from '@src/css_config/colorConstants.js';
import { Box, Modal, Fade, Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';
import { ReactComponent as ExpandArrow } from '@assets/analytics/ExpandArrow.svg';
import { ReactComponent as ShrinkIcon } from '@assets/analytics/ShrinkIcon.svg';
import {
  ANALYTICS_MESSAGE,
  chartFontFamily,
  recurrentValueLabelRadio,
  exeCompMSgHead,
  execChartLabels,
} from '@common/ui-constants';
import DropDown from './common/DropDown';
import { getCurrentProjectId, getCurrentLicenseId } from '@util/common_utils';

const ExecutionComparisonSuite = ({ data }) => {
  const suiteMode = window.location.pathname.split('/')[2];
  const facetSuiteData = data;
  let [comparionSuite, setcomparisonsuite] = useState('Select Suite');

  let [selectedSuiteIdList, setselectedSuiteIdList] = useState([]);
  let [exeSuiteId1, setExeSuiteId1] = useState('');
  let [exeSuiteId2, setExeSuiteId2] = useState('');
  let [recurrentValue, setRecurrentValue] = useState('recurantPassScripts');
  let [compareSuiteResult, setCompareSuiteResult] = useState(false);
  let [exeSuite1ColumnData, setSuite1ColumnData] = useState([]);
  let [exeSuite2ColumnData, setSuite2ColumnData] = useState([]);
  let [exeComparisonResult1, setexeCompResutl1] = useState({});
  let [exeComparisonResult2, setexeCompResutl2] = useState({});
  let [exeComparisonSerires1, setExeComparisonSerires1] = useState([0, 0, 0, 0]);
  let [exeComparisonSerires2, setExeComparisonSerires2] = useState([0, 0, 0, 0]);
  let [exeModuleSerires1, setExeModuleSerires1] = useState([0, 0, 0, 0]);
  let [exeModuleSerires2, setExeModuleSerires2] = useState([0, 0, 0, 0]);
  let [exe1totalModule, setExe1totalModule] = useState(0);
  let [exe2totalModule, setExe2totalModule] = useState(0);
  let [exe1totalScripts, setExe1totalScripts] = useState(0);
  let [exe2totalScripts, setExe2totalScripts] = useState(0);

  // TODO :: Modal Operations & Scripts Step Result For Both Table :: START
  const [scriptResultLeft, setScriptResultLeft] = useState([]);
  const [scriptResultRight, setScriptResultRight] = useState([]);
  const [isTableExpanded, setTableExpanded] = useState(false);
  const [selectedScriptLeft, setSelectedScriptLeft] = useState({ scriptId: '', executionId: '' });
  const [selectedScriptNameLeft, setSelectedScriptNameLeft] = useState('');
  const [selectedScriptRight, setSelectedScriptRight] = useState({ scriptId: '', executionId: '' });
  const [selectedScriptNameRight, setSelectedScriptNameRight] = useState('');
  const [diableDropDownOnCompare, setDisableDropDownOnCompare] = useState(false);

  const [open, setOpen] = useState(false);
  let [openleftModal, setOpenLeftModal] = useState(false);
  let [openRigthModal, setOpenRigthModal] = useState(false);

  let closeResultModal = (actionOn) => {
    if (actionOn === 'leftModal') {
      setOpenLeftModal(false);
      setScriptResultLeft([]);
      setSelectedScriptLeft({ scriptId: '', executionId: '' });
      setSelectedScriptNameLeft('');
    } else if (actionOn === 'rightModal') {
      setOpenRigthModal(false);
      setScriptResultRight([]);
      setSelectedScriptRight({ scriptId: '', executionId: '' });
      setSelectedScriptNameRight('');
    }
  };

  const handleOpen = () => {
    setOpen(true);
    setTableExpanded(true);
  };
  const handleClose = () => {
    setOpen(false);
    setTableExpanded(false);
    setOpenLeftModal(false);
    setScriptResultLeft([]);
    setOpenRigthModal(false);
    setScriptResultRight([]);
  };
  // TODO :: Modal Operations & Scripts Step Result For Both Table :: END

  useEffect(() => {
    let getSelectedSuiteData = facetSuiteData?.filter((suite) => {
      if (suite?.name === comparionSuite) {
        return suite;
      }
    });
    setExeSuiteId1('Select Execution Id');
    setselectedSuiteIdList(getSelectedSuiteData);
  }, [comparionSuite]);

  useEffect(() => {
    resetCompSuitevalue();
  }, [facetSuiteData]);

  // TODO :: to change data in comparison Table only clik of recurrent radio button
  let handleRecurrentValue = (e) => {
    setRecurrentValue(e.target.value);
    setSuite1ColumnData([...exeComparisonResult1[e.target.value]]);
    setSuite2ColumnData([...exeComparisonResult2[e.target.value]]);
  };

  // TODO :: Reset Selected sute Values
  let resetCompSuitevalue = () => {
    setcomparisonsuite('Select Suite');
    selectedSuiteIdList = facetSuiteData?.filter((suite) => suite?.name === comparionSuite);
    setExeSuiteId1('Select Execution Id');
    setExeSuiteId2('Select Execution Id');
    setExeComparisonSerires1([0, 0, 0, 0]);
    setExeComparisonSerires2([0, 0, 0, 0]);
    setExeModuleSerires1([0, 0, 0, 0]);
    setExeModuleSerires2([0, 0, 0, 0]);
    setCompareSuiteResult(false);
    setRecurrentValue('recurantPassScripts');
    setDisableDropDownOnCompare(false);
    setExe1totalModule(0);
    setExe2totalModule(0);
    setExe1totalScripts(0);
    setExe2totalScripts(0);
  };

  //TODO ::  api call to comapre suite :: START
  let requestPara = {
    licenseId: getCurrentLicenseId(),
    projectId: getCurrentProjectId(),
    suiteName: comparionSuite,
    executionId1: exeSuiteId1,
    executionId2: exeSuiteId2,
  };

  const getComparisonSuiteData = () => {
    if (requestPara.suiteName && requestPara.executionId1 && requestPara.executionId2) {
      getComparisonSuiteReq(requestPara)
        .then((response) => {
          if (response) {
            let { data } = response;
            let { executionCompareResponse1, executionCompareResponse2 } = data;
            let { moduleData, scriptData } = executionCompareResponse1;
            let { pass, fail, warning, skip } = scriptData;
            setExeComparisonSerires1([pass, fail, warning, skip]);
            setExeModuleSerires1([moduleData?.pass, moduleData?.fail, moduleData?.warning, moduleData?.skip]);
            setExe1totalModule(moduleData.total);
            setExe1totalScripts(scriptData.total);
            setExe2totalModule(executionCompareResponse2?.moduleData?.total);
            setExe2totalScripts(executionCompareResponse2?.scriptData.total);
            setExeComparisonSerires2([
              executionCompareResponse2?.scriptData?.pass,
              executionCompareResponse2?.scriptData?.fail,
              executionCompareResponse2?.scriptData?.warning,
              executionCompareResponse2?.scriptData?.skip,
            ]);
            setExeModuleSerires2([
              executionCompareResponse2?.moduleData?.pass,
              executionCompareResponse2?.moduleData?.fail,
              executionCompareResponse2?.moduleData?.warning,
              executionCompareResponse2?.moduleData?.skip,
            ]);
            setexeCompResutl1(response.data.executionCompareResponse1);
            setexeCompResutl2(response.data.executionCompareResponse2);
            setSuite1ColumnData(response.data.executionCompareResponse1[recurrentValue]);
            setSuite2ColumnData(response.data.executionCompareResponse2[recurrentValue]);
          }
          setCompareSuiteResult(true);
          setDisableDropDownOnCompare(true);
        })
        .catch((err) => {
          setCompareSuiteResult(false);
          console.error('GET_COMPARISON_SUITE_REQ :', err);
        });
    }
  };
  //TODO ::  api call to comapre suite :: END

  //TODO ::  api call to get Exexution scripts Result :: START

  const getScriptResultData = (scriptId, executionId , clientId, runId, resultOF) => {
    handleOpen();
    if (executionId && scriptId && clientId && runId) {
      getScriptResultReq( executionId, clientId, runId , scriptId)
        .then((response) => {
          if (resultOF === 'leftTable') {
            setScriptResultLeft(response.data.responseObject);
            setOpenLeftModal(true);
          } else if (resultOF === 'rightTable') {
            setScriptResultRight(response.data.responseObject);
            setOpenRigthModal(true);
          }
        })
        .catch((error) => {
          console.error('Error during script result api call:', error);
        });
    }
  };
  //TODO ::  api call to get Exexution scripts Result :: END

  // Execution Comparison Suite Table :: START
  let exeComparsionLeftcolumns = React.useMemo(
    () => [
      {
        Header: 'Script Id',
        accessor: 'scriptId',
        width: '30%',
        Cell: ({ value }) => {
          return (
            <div
              className="overflow-hidden overflow-ellipsis whitespace-nowrap pr-2 rs-text-black fontPoppinsMediumSm"
              title={value}
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Name',
        accessor: 'title',
        width: '30%',
        Cell: ({ row, value }) => {
          let { scriptId, executionId, scriptName , clientId , runId} = row.original;
          return (
            <div
            className={`overflow-hidden overflow-ellipsis whitespace-nowrap pr-2 rs-blue fontPoppinsMediumSm ${runId ? 'cursor-pointer' : 'cursor-default rs-skipped'}`}
              onClick={() => {
                if (runId && suiteMode === 'automation-suite') {
                  getScriptResultData(scriptId, executionId, clientId , runId , 'leftTable');
                  setSelectedScriptLeft({ scriptId, executionId });
                  setSelectedScriptNameLeft(scriptName);
                }
              }}
              title={value}
            >
              <span>{value}</span>
            </div>
          );
        },
      },
      {
        Header: 'Module',
        accessor: 'pathData',
        width: '30%',
        Cell: ({ value }) => {
          return (
            <div
              className="overflow-hidden overflow-ellipsis whitespace-nowrap pr-2 rs-text-black fontPoppinsMediumSm"
              title={value.path}
            >
              {value.module}
            </div>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: '30%',
        Cell: ({ value }) => {
          let statusValue;
          if (value === 'PASS') statusValue = 'Passed';
          else if (value === 'FAIL') statusValue = 'Failed';
          else if (value === 'WARNING') statusValue = 'Warning';
          else if (value === 'SKIP') statusValue = 'skipped';

          return (
            <div
              className={`overflow-hidden overflow-ellipsis whitespace-nowrap capitalize fontPoppinsMediumSm
                            ${
                              value === 'PASS'
                                ? 'rs-passed'
                                : value === 'FAIL'
                                  ? 'rs-failed'
                                  : value === 'WARNING'
                                    ? 'rs-warning'
                                    : value === 'SKIP'
                                      ? 'rs-skipped'
                                      : null
                            }
                          `}
              title={value}
            >
              {statusValue || value}
            </div>
          );
        },
      },
    ],
    []
  );

  let exeComparsionRightcolumns = React.useMemo(
    () => [
      {
        Header: 'Script Id',
        accessor: 'scriptId',
        width: '30%',
        Cell: ({ value }) => {
          return (
            <div
              className="overflow-hidden overflow-ellipsis whitespace-nowrap pr-2 rs-text-black fontPoppinsMediumSm"
              title={value}
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Name',
        accessor: 'title',
        width: '30%',
        Cell: ({ row, value }) => {
          let { scriptId, executionId, scriptName , clientId , runId} = row.original;
          return (
            <div
            className={`overflow-hidden overflow-ellipsis whitespace-nowrap pr-2 rs-blue fontPoppinsMediumSm ${runId ? 'cursor-pointer' : 'cursor-default rs-skipped'}`}
              onClick={() => {
                if (runId && suiteMode === 'automation-suite') {
                  getScriptResultData(scriptId, executionId, clientId , runId , 'rightTable');
                  setSelectedScriptRight({ scriptId, executionId });
                  setSelectedScriptNameRight(scriptName);
                }
              }}
              title={value}
            >
              <span>{value}</span>
            </div>
          );
        },
      },
      {
        Header: 'Module',
        accessor: 'pathData',
        width: '30%',
        Cell: ({ value }) => {
          return (
            <div
              className="overflow-hidden overflow-ellipsis whitespace-nowrap pr-2 rs-text-black fontPoppinsMediumSm"
              title={value.path}
            >
              {value.module}
            </div>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: '30%',
        Cell: ({ value }) => {
          let statusValue;
          if (value === 'PASS') statusValue = 'Passed';
          else if (value === 'FAIL') statusValue = 'Failed';
          else if (value === 'WARNING') statusValue = 'Warning';
          else if (value === 'SKIP') statusValue = 'skipped';

          return (
            <div
              className={`overflow-hidden overflow-ellipsis whitespace-nowrap pr-1 capitalize fontPoppinsMediumSm
                            ${
                              value === 'PASS'
                                ? 'rs-passed'
                                : value === 'FAIL'
                                  ? 'rs-failed'
                                  : value === 'WARNING'
                                    ? 'rs-warning'
                                    : value === 'SKIP'
                                      ? 'rs-skipped'
                                      : null
                            }
                          `}
              title={value}
            >
              {statusValue || value}
            </div>
          );
        },
      },
    ],
    []
  );
  // Execution Comparison Suite Table :: START

  // Execution comparison Chart Option :: START

  const exeComp1ModuleChartOpt = {
    series: exeModuleSerires1,
    options: {
      chart: {
        type: 'radialBar',
        animations: {
          enabled: true,
          dynamicAnimation: {
            speed: 600,
          },
        },
      },
      fill: { colors: colors.exe_radial_color },
      plotOptions: {
        bar: {
          borderRadius: 10,
        },
        radialBar: {
          startAngle: 0,
          endAngle: 360,
          track: {
            background: colors.bg_track_radial,
            opacity: '0.30',
            margin: 3,
          },
          hollow: {
            margin: 1,
            size: '30%',
            background: 'transparent',
            dropShadow: {},
          },
          dataLabels: {
            enabled: true,
            offsetX: 10,
            name: {
              show: true,
              fontSize: '10px',
              fontFamily: chartFontFamily,
            },
            value: {
              show: true,
              fontSize: '10px',
              offsetY: -5,
              formatter: function (val) {
                return Number(val).toFixed(2) + '%';
              },
            },
            total: {
              show: true,
              label: 'Module',
              fontSize: '10px',
              fontFamily: chartFontFamily,
              color: colors.black,
              dynamicCount: exe1totalModule,
              formatter: function (w) {
                let val = exe1totalModule;
                return val;
              },
            },
          },
        },
      },
      stroke: {
        lineCap: 'round',
        curve: 'smooth',
      },
      colors: colors.exe_radial_color,
      labels: execChartLabels,
    },
  };

  const exeComp1ScriptChartOpt = {
    series: exeComparisonSerires1,
    options: {
      chart: {
        type: 'radialBar',
        animations: {
          enabled: true,
          dynamicAnimation: {
            speed: 600,
          },
        },
      },
      fill: { colors: colors.exe_radial_color },
      plotOptions: {
        bar: {
          borderRadius: 10,
        },
        radialBar: {
          startAngle: 0,
          endAngle: 360,
          track: {
            background: colors.bg_track_radial,
            opacity: '0.38',
            margin: 3,
          },
          hollow: {
            margin: 1,
            size: '30%',
            background: 'transparent',
            dropShadow: {},
          },
          dataLabels: {
            enabled: true,
            name: {
              show: true,
              fontSize: '10px',
              fontFamily: chartFontFamily,
            },
            value: {
              show: true,
              fontSize: '10px',
              offsetY: -5,
              formatter: function (val) {
                return Number(val).toFixed(2) + '%';
              },
            },
            total: {
              show: true,
              label: 'Scripts',
              fontSize: '10px',
              fontFamily: chartFontFamily,
              color: colors.black,
              dynamicCount: exe1totalScripts,
              formatter: function (w) {
                let val = exe1totalScripts;
                return val;
              },
            },
          },
        },
      },
      stroke: {
        lineCap: 'round',
        curve: 'smooth',
      },
      colors: colors.exe_radial_color,
      labels: execChartLabels,
    },
  };

  const exeComp2ModuleChartOpt = {
    series: exeModuleSerires2,
    options: {
      chart: {
        type: 'radialBar',
        animations: {
          enabled: true,
          dynamicAnimation: {
            speed: 600,
          },
        },
      },
      fill: { colors: colors.exe_radial_color },
      plotOptions: {
        bar: {
          borderRadius: 10,
        },
        radialBar: {
          startAngle: 0,
          endAngle: 360,
          track: {
            background: colors.bg_track_radial,
            opacity: '0.30',
            margin: 3,
          },
          hollow: {
            margin: 1,
            size: '30%',
            background: 'transparent',
            dropShadow: {},
          },
          dataLabels: {
            enabled: true,
            offsetX: 10,
            name: {
              show: true,
              fontSize: '10px',
              fontFamily: chartFontFamily,
            },
            value: {
              show: true,
              fontSize: '10px',
              offsetY: -5,
              formatter: function (val) {
                return Number(val).toFixed(2) + '%';
              },
            },
            total: {
              show: true,
              label: 'Module',
              fontSize: '10px',
              fontFamily: chartFontFamily,
              color: colors.black,
              dynamicCount: exe2totalModule,
              formatter: function (w) {
                let val = exe2totalModule;
                return val;
              },
            },
          },
        },
      },
      stroke: {
        lineCap: 'round',
        curve: 'smooth',
      },
      colors: colors.exe_radial_color,
      labels: execChartLabels,
    },
  };

  const exeComp2ScriptChartOpt = {
    series: exeComparisonSerires2,
    options: {
      chart: {
        type: 'radialBar',
        animations: {
          enabled: true,
          dynamicAnimation: {
            speed: 600,
          },
        },
      },
      fill: { colors: colors.exe_radial_color },
      plotOptions: {
        bar: {
          borderRadius: 10,
        },
        radialBar: {
          startAngle: 0,
          endAngle: 360,
          track: {
            background: colors.bg_track_radial,
            opacity: '0.38',
            margin: 3,
          },
          hollow: {
            margin: 1,
            size: '30%',
            background: 'transparent',
            dropShadow: {},
          },
          dataLabels: {
            enabled: true,
            name: {
              show: true,
              fontSize: '10px',
              fontFamily: chartFontFamily,
            },
            value: {
              show: true,
              fontSize: '10px',
              offsetY: -5,
              formatter: function (val) {
                return Number(val).toFixed(2) + '%';
              },
            },
            total: {
              show: true,
              label: 'Scripts',
              fontSize: '10px',
              fontFamily: chartFontFamily,
              color: colors.black,
              dynamicCount: exe2totalScripts,
              formatter: function (w) {
                let val = exe2totalScripts;
                return val;
              },
            },
          },
        },
      },
      stroke: {
        lineCap: 'round',
        curve: 'smooth',
      },
      colors: colors.exe_radial_color,
      labels: execChartLabels,
    },
  };
  // Execution comparison Chart Option :: END

  // TODO :: Auto API call On Change Excution Id
  useEffect(() => {
    if (
      exeSuiteId1 &&
      exeSuiteId2 &&
      comparionSuite !== 'Select Suite' &&
      exeSuiteId1 !== 'Select Execution Id' &&
      exeSuiteId2 !== 'Select Execution Id'
    ) {
      getComparisonSuiteData();
    }
  }, [exeSuiteId1, exeSuiteId2]);

  return (
    <>
      <div className=" section-header section-box w-full">
        <div className="gird col-span-12 p-3 ">
          <div className="w-full flex items-center justify-between">
            <div className="excecution_comparison fontPoppinsSemiboldSm uppercase">{exeCompMSgHead.sectionHead}</div>
            <div className="flex gap-3">
              <DropDown
                data={facetSuiteData}
                value={comparionSuite}
                setValue={setcomparisonsuite}
                diableDropDownOnCompare={diableDropDownOnCompare}
              />
              <div className="fontPoppinsSemiboldSm ml-1 cursor-pointer resetBtn" onClick={resetCompSuitevalue}>
                <RefreshIcon />
                {exeCompMSgHead.btnLabel.reset}
              </div>
            </div>
          </div>
          <div className="col-span-12 mt-2 execution_comparison_body ">
            <div className=" grid grid-cols-12 gap-1">
              <div className="grid  grid-cols-6 col-span-6 ">
                <div className="exe-comparion-section p-4 grid  col-span-6">
                  <div className="exe_comp_hearder flex items-center justify-between">
                    <div className="script_color_indicator flex gap-2 fontPoppinsRegularXs">
                      <div className="indicator_passed">
                        <span></span> Passed
                      </div>
                      <div className="indicator_failed">
                        <span></span> Failed
                      </div>
                      <div className="indicator_warning">
                        <span></span> Warning
                      </div>
                      <div className="indicator_skipped">
                        <span></span> Skipped
                      </div>
                    </div>

                    <DropDown
                      data={selectedSuiteIdList[0]?.values}
                      value={exeSuiteId1}
                      setValue={setExeSuiteId1}
                      disableExecutionId={exeSuiteId2}
                    />
                  </div>
                  <div className="exeCompGraph1 flex mt-4 justify-around w-full ">
                    <div className="exeModuleGraph ">
                      <Chart
                        options={exeComp1ModuleChartOpt.options}
                        series={exeComp1ModuleChartOpt.series}
                        type="radialBar"
                        height={200}
                        width={200}
                      />
                    </div>
                    <div className="exeScriptGraph">
                      <Chart
                        options={exeComp1ScriptChartOpt.options}
                        series={exeComp1ScriptChartOpt.series}
                        type="radialBar"
                        height={200}
                        width={200}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-6 col-span-6 ">
                <div className="exe-comparion-section p-4 grid  col-span-6">
                  {selectedSuiteIdList[0]?.values.length <= 1 ? (
                    <div className="grid  col-span-6  justify-center items-center">
                      <div className="fontPoppinsMediumSm text-center">{exeCompMSgHead.noexeId}</div>
                    </div>
                  ) : (
                    <>
                      <div className="exe_comp_hearder flex items-center justify-between">
                        <div className="script_color_indicator flex gap-2 fontPoppinsRegularXs">
                          <div className="indicator_passed">
                            <span></span> Passed
                          </div>
                          <div className="indicator_failed">
                            <span></span> Failed
                          </div>
                          <div className="indicator_warning">
                            <span></span> Warning
                          </div>
                          <div className="indicator_skipped">
                            <span></span> Skipped
                          </div>
                        </div>

                        <DropDown
                          data={selectedSuiteIdList[0]?.values}
                          value={exeSuiteId2}
                          setValue={setExeSuiteId2}
                          disableExecutionId={exeSuiteId1}
                        />
                      </div>
                      <div className="exeCompGraph1 w-full flex mt-4 justify-around  ">
                        <div className="exeModuleGraph">
                          <Chart
                            options={exeComp2ModuleChartOpt.options}
                            series={exeComp2ModuleChartOpt.series}
                            type="radialBar"
                            height={200}
                            width={200}
                          />
                        </div>
                        <div className="exeScriptGraph">
                          <Chart
                            options={exeComp2ScriptChartOpt.options}
                            series={exeComp2ScriptChartOpt.series}
                            type="radialBar"
                            height={200}
                            width={200}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            {compareSuiteResult ? (
              <>
                <div className="w-full exc_comparison_RadioBar flex items-center justify-center my-2 p-5">
                  <FormControl className="w-full flex items-center justify-center p-5 ">
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      className="w-full flex items-center justify-evenly"
                      onChange={handleRecurrentValue}
                      value={recurrentValue}
                    >
                      {recurrentValueLabelRadio.map((rvl) => (
                        <FormControlLabel
                          value={rvl.value}
                          sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}
                          className="flex items-center"
                          control={<Radio size="small" />}
                          label={<span className="fontPoppinsRegularXs">{rvl.label}</span>}
                          key={rvl.value}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="comparison-section">
                  <div className=" flex items-end justify-end">
                    <div className="expandIconContainer mx-2 mb-1" size="small">
                      <ExpandArrow className="expIcon" onClick={handleOpen} />
                    </div>
                  </div>

                  <div className=" grid grid-cols-12 gap-1  px-2">
                    <div className="comparisonTableBlock grid col-span-6">
                      <ExeComparisonTableLeft
                        columns={exeComparsionLeftcolumns}
                        data={exeSuite1ColumnData}
                        message={ANALYTICS_MESSAGE.NO_SCRIPTS_FOUND}
                        isTableExpanded={isTableExpanded}
                      />
                    </div>
                    <div className="comparisonTableBlock grid col-span-6">
                      <ExeComparisonTableRight
                        columns={exeComparsionRightcolumns}
                        data={exeSuite2ColumnData}
                        message={ANALYTICS_MESSAGE.NO_SCRIPTS_FOUND}
                        isTableExpanded={isTableExpanded}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className="expand-exeComparsion-modalTable">
            <div className="">
              <div className=" flex justify-between ">
                <div className="rs-blue fontPoppinsSemiboldSm pl-4 pt-2 uppercase">{exeCompMSgHead.sectionHead}</div>
                <div className="expandIconContainer mx-2 mb-1" size="small">
                  <span className="expandIconContainer my-1 mx-2" onClick={handleClose}>
                    <ShrinkIcon />
                  </span>
                </div>
              </div>

              <div className=" grid grid-cols-12 gap-1  px-2">
                <div className="comparisonTableBlock grid col-span-6">
                  <ExeComparisonTableLeft
                    columns={exeComparsionLeftcolumns}
                    data={exeSuite1ColumnData}
                    message={ANALYTICS_MESSAGE.NO_SCRIPTS_FOUND}
                    isTableExpanded={isTableExpanded}
                    scriptResultLeft={scriptResultLeft}
                    openleftModal={openleftModal}
                    closeResultModal={closeResultModal}
                    selectedScriptLeft={selectedScriptLeft}
                    selectedScriptNameLeft={selectedScriptNameLeft}
                  />
                </div>
                <div className="comparisonTableBlock grid col-span-6">
                  <ExeComparisonTableRight
                    columns={exeComparsionRightcolumns}
                    data={exeSuite2ColumnData}
                    message={ANALYTICS_MESSAGE.NO_SCRIPTS_FOUND}
                    isTableExpanded={isTableExpanded}
                    scriptResultRight={scriptResultRight}
                    openRigthModal={openRigthModal}
                    closeResultModal={closeResultModal}
                    selectedScriptRight={selectedScriptRight}
                    selectedScriptNameRight={selectedScriptNameRight}
                  />
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ExecutionComparisonSuite;

import { BrowserRouter, BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import useToken from '@pages/auth/useToken';
import LoginPage from '@pages/auth/login-page';
import SignupPage from '@pages/auth/SignupPage';
import LicencePackageForm from '@pages/licence_Package/licence_Package';
import ActivationLink from '@pages/auth/warning-activation-link';
import SetPassword from '@pages/auth/set-password';
import ForgetPassword from '@pages/auth/forget-password';
import VerificationCode from '@pages/auth/verification-code';
import TokenExpired from '@pages/auth/TokenExpired';
import RegistrationSuccess from '@pages/auth/registration-success';
import PasswordEmailSend from '@pages/auth/email-send';
import SetPasswordSuccessfullly from '@pages/auth/password-set-succussfully';
import PhoneNumberValid from '@pages/auth/phone-validation';
import LicenceManagement from '@pages/licence_management/licence_management';
import UserLeaveConfirmation from '@pages/test-development/script/scripts/testcase-template/user-leave-confirmation';
import ActivationSuccess from '@pages/auth/ActivationSuccess';
import '@src/App.scss';
import { LicencePackageProvider } from '@src/hooks/useLicencePackage';
import './css_config/button_style.scss';
import './css_config/breadcrumb_style.scss';
import './css_config/tab_style.scss';
import './css_config/table_style.scss';
import './css_config/page_layout.scss';
import './css_config/execution.scss';
import './css_config/radio_btn_style.scss';
import './css_config/popup_modal.scss';
import ProgressContainer from '@common/ProgressContainer';
import { CircularProgressContainer } from '@common/CircularProgressContainer';
import '@src/service/permissions_service';
import '@src/service/privilege_service';

import { useRef } from 'react';
import AppLayout from '@pages/common/Layout/components/AppLayout';
import Callback from '@pages/auth/callback-page';
import ServiceDownTime from '@pages/auth/ServiceDownTime';
import ToasterContainer from '@common/atoms/Toast/Toast';

function App() {
  const { token, setToken } = useToken();
  const isModalOpenRef = useRef(true);
  const getPathUrl = window.location.pathname;
  const getPathName = getPathUrl.split('/');

  const closeInfoModal = (value) => {
    isModalOpenRef.current = value;
  };

  if (!token) {
    return (
      <LicencePackageProvider>
        <Router>
          <Switch>
            <Route path="/service-downtime">
              <ServiceDownTime />
            </Route>
            <Route path="/licence-management">
              <LicenceManagement />
            </Route>
            <Route path="/signup">
              <SignupPage />
            </Route>
            <Route path="/registration-successful">
              <RegistrationSuccess />
            </Route>
            <Route path="/activation-successful">
              <ActivationSuccess />
            </Route>
            <Route path="/user-activation">
              <ActivationLink />
            </Route>
            <Route path="/otp-verification">
              <VerificationCode />
            </Route>
            <Route path="/token-expired">
              <TokenExpired />
            </Route>
            <Route path="/set-password-successfully">
              <SetPasswordSuccessfullly />
            </Route>
            <Route path="/email-send">
              <PasswordEmailSend />
            </Route>
            <Route path="/phone-validation">
              <PhoneNumberValid />
            </Route>
            <Route path="/forget-password">
              <ForgetPassword />
            </Route>
            <Route path="/change-password">
              <SetPassword />
            </Route>
            <Route path="/licence_Package">
              <LicencePackageForm />
            </Route>
            <Route path="/">
              <LoginPage setToken={setToken} />
            </Route>
          </Switch>
        </Router>
      </LicencePackageProvider>
    );
  }

  if (getPathName[1] === 'callback-url') {
    return (
      <LicencePackageProvider>
        <Router>
          <Switch>
            <Route path="/callback-url">
              <Callback />
            </Route>
          </Switch>
        </Router>
      </LicencePackageProvider>
    );
  } else {
    return (
      <BrowserRouter
        getUserConfirmation={(message, callback) => {
          return UserLeaveConfirmation(message, callback, isModalOpenRef.current, closeInfoModal);
        }}
      >
        <ProgressContainer>
          <ToasterContainer>
            <CircularProgressContainer>
              <AppLayout />
            </CircularProgressContainer>
          </ToasterContainer>
        </ProgressContainer>
      </BrowserRouter>
    );
  }
}

export default App;

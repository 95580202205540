import React, { Fragment, useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Box from '@mui/material/Box';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@mui/material/Modal';
import { useFormik } from 'formik';
import MyInput from '@pages/common/Inputs/MyInput';
import { makeStyles } from '@material-ui/core/styles';
import './emailConfig.css';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { ExclamationIcon } from '@heroicons/react/outline';
import ReactTable from '@src/pages/common/Table/ReactTable';
import { postEmailConfigReq, getEmailConfigReq, deleteEmailConfigReq, putEmailConfigReq } from '@api/api_services';
import { Menu, Transition } from '@headlessui/react';
import { Tooltip } from '@material-ui/core';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { useAlert } from '@pages/common/alert_service/useAlert';
import ConfigMemoryStyle from '@pages/configuration/assests/styles/configmemory.module.scss';
import cx from 'classnames';
import CreateRecipientInputFiled from '@pages/configuration/EmailConfig/CreateRecipientInputFiled';
import EditRecipientInputFiled from '@pages/configuration/EmailConfig/EditRecipientInputFiled';
import ContextMenu from '@pages/common/context_menu';
import SearchBar from '@pages/analytics/common/search-bar';
import { HighlightText, isEmptyValue, isValueIncludes } from '@util/common_utils';
import NoResultsFound from '@pages/common/Table/NoResultFound';
import { UI_VALIDATIONS } from '@src/util/validations';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
    color: 'rgb(22,72,198)',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  height: 570,
  bgcolor: 'background.paper',
  boxShadow: 12,
  outline: 'none',
};

//handle email group name validation common function
Yup.addMethod(Yup.string, 'emailGroupName', strongEmailGroupNameMethod);
function strongEmailGroupNameMethod() {
  return this.test('strongPasswordTest', function (value) {
    const { path, createError } = this;
    switch (Boolean(value)) {
      case !/^(?=.*[A-Za-z])/.test(value):
        return createError({ path, message: 'Must contain atleast one alphabet' });
      case !/^[A-Za-z0-9-_\s+]*$/.test(value):
        return createError({ path, message: 'Special character are not allowed' });
      default:
        return true;
    }
  });
}

const Email_group = (props) => {
  const formik = useFormik({
    initialValues: {
      name: '',
      Recipientname: '',
      RecipientNameOne: '',
      RecipientEmailAddressEdit: '',
      addRecipientname: '',
      addRecipientEmailAddress: '',
      Editname: '',
      RecipientEmailAddressOne: '',
      RecipnameOne: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim('Space is not allowed at starting and at the end')
        .strict(true)
        .max(25, 'Must be 25 characters or less')
        .min(3, 'Must be minimum 3 characters')
        .required('Name is required')
        .emailGroupName(),
      Editname: Yup.string()
        .trim('Space is not allowed at starting and at the end')
        .strict(true)
        .max(25, 'Must be 25 characters or less')
        .min(3, 'Must be minimum 3 characters')
        .required('Name is required')
        .emailGroupName(),
      addRecipientname: Yup.string().required('Name is required'),
      addRecipientEmailAddress: Yup.string().email('Invalid Email').required('Recipient Email address is required '),
    }),
    onSubmit: (values) => {
      console.log(JSON.stringify(values));
    },
  });

  const [openGroup, setOpenGroup] = React.useState(false);
  const [openEditGroup, setOpenEditGroup] = React.useState(false);
  const [openCloneGroup, setOpenCloneGroup] = React.useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [addRecip, setaddRecip] = useState(false);
  const [openUserDetails, setOpenUserDetails] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [groupValue, setGroupValue] = React.useState([]);
  const [groupID, setGroupID] = React.useState({});
  const [getDltData, setGetDltData] = useState();
  const [editnameData, setEditNameData] = useState('');
  const [Editname, setEditname] = useState('');
  const [RecipientEmailAddressEdit, setRecipientEmailAddressEdit] = useState('');
  const [addRecipientname, setAddRecipientname] = useState('');
  const [addRecipientEmailAddress, setAddRecipientEmailAddress] = useState('');
  const [text, setText] = useState('');
  const [suggestion, setSuggestion] = useState([]);
  const [texts, setTexts] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [emailName, setEmailName] = useState('');
  const [eName, setEname] = useState('');
  const [emailData, setEmailData] = useState('');
  const [tableData, setTableData] = React.useState([]);
  const [projectTypeList, setProjectTypeList] = React.useState([]);
  const [projectTypeListFull, setProjectTypeListFull] = React.useState([]);
  const [editEmailList, setEditEmailList] = React.useState([]);
  const [emailId, setEmailId] = React.useState();
  const { AlertContatiner, MyAlert } = useAlert();

  const contextMenuData = [
    {
      option: 'Details',
    },
  ];

  useEffect(() => {
    projectTypeListFull.push({ name: '', emailId: '' });
    setProjectTypeListFull([...projectTypeListFull]);
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenUser = () => setOpenUserDetails(true);
  const handleCloseUser = () => setOpenUserDetails(false);
  const handelAddRecip = () => {
    setaddRecip(true);
  };
  const handelAddRecipClose = () => {
    setaddRecip(!addRecip);
  };

  const closeForEdit = () => {
    setaddRecip(!addRecip);
    setAddRecipientEmailAddress('');
    setAddRecipientname('');
    formik.values.addRecipientname = '';
    formik.values.addRecipientEmailAddress = '';
  };

  const handleOpenGroup = () => setOpenGroup(true);

  const handleCloseGroup = () => {
    setOpenGroup(false);
    setEmailName('');
    setEname('');
    setTexts('');
    setText('');
    setSuggestions([]);
    setSuggestion([]);
    formik.setErrors({});
    formik.values.name = '';
    formik.values.Recipientname = '';
    formik.values.RecipnameOne = '';
    formik.values.RecipientEmailAddressOne = '';
    setProjectTypeList([]);
  };

  const handleEditOpenGroup = () => setOpenEditGroup(true);
  const handleCloseEditGroup = () => {
    setOpenEditGroup(false);
    formik.setErrors({});
    formik.values.Editname = Editname;
    formik.values.addRecipientname = addRecipientname;
    formik.values.addRecipientEmailAddress = addRecipientEmailAddress;
  };
  const handleCloneOpenGroup = () => setOpenCloneGroup(true);
  const handleCloseCloneGroup = () => {
    setOpenCloneGroup(false);
    formik.setErrors({});
    formik.values.Editname = Editname;
    formik.values.addRecipientname = addRecipientname;
    formik.values.addRecipientEmailAddress = addRecipientEmailAddress;
  };

  const handelDropdown = () => {
    setShowOptions(true);
  };

  // if click outside of dropdown also it will close
  let menuRef = useRef();
  useEffect(() => {
    let handeler = (event) => {
      if (menuRef.current && !menuRef?.current?.contains(event.target)) {
        setShowOptions(false);
      }
    };
    document.addEventListener('mousedown', handeler);
    return () => {
      document.removeEventListener('mouseleave', handeler);
    };
  }, []);

  const classes = useStyles();

  // individual project
  let projectId = JSON.parse(localStorage.getItem('selected-project')).id;
  React.useEffect(() => {
    const search = window.location.search;
    let projectId = new URLSearchParams(search).get('id') || JSON.parse(localStorage.getItem('selected-project'));
    if (new URLSearchParams(search).get('id')) {
      projectId = new URLSearchParams(search).get('id');
    } else {
      projectId = JSON.parse(localStorage.getItem('selected-project')).id;
    }
    if (projectId) {
      getEmailConfigReq(projectId).then((results) => {
        localStorage.setItem('resultGetReq', false);
        if (results && results.data && results.data.responseObject) {
          setGroupValue(results.data.responseObject);
          setEmailData(results.data.responseObject);
        }
      });
    } else {
      localStorage.setItem('resultGetReq', true);
      getEmailConfigReq()
        .then((res) => {
          localStorage.setItem('resultGetReq', false);
          setGroupValue(res.data.responseObject);
          setEmailData(res.data.responseObject);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [projectId, getDltData]);

  const createGroup = (list) => {
    const name = formik.values.name;
    if (formik.errors.name) {
      return;
    }
    let data = {
      name: name,
      emailUserList: list,
    };
    if (projectId) {
      postEmailConfigReq(projectId, data)
        .then((res) => {
          localStorage.setItem('resultGetReq', false);
          if (res.data.status === 'SUCCESS') {
            setGetDltData(res);
            MyAlert.success(`${name} Email group Created successfully`);
            handleCloseGroup();
          }
          if (res?.data?.responseCode === 400 && res?.data?.message.includes('Invalid EmailGroup name,')) {
            formik.setFieldError('name', 'Special character are not allowed');
          }
          if (res.data.message === 'Minimum 2 Recipients required,') {
            MyAlert.danger(`Add at least 2 recipients to create a group`);
          }

          if (
            res.data.message ===
            'Recipients name is mandatory,Invalid Recipients name,Recipients EmailId is mandatory,Please Provide valid mail in Email group,'
          ) {
            MyAlert.danger(`Add at least 2 recipients to create a group`);
          }
          if (res.data.message === `email group with name ${name} already exists`) {
            MyAlert.danger(`Email group with name ${name} already exists`);
          }
          const splitErrorMessageEmail = res.data.message.split(' ', 3);
          if (splitErrorMessageEmail[1] + splitErrorMessageEmail[2] === 'EmailId') {
            const splitCommonEmail = res.data.message.split(' ', 4);
            let emailGrpMailId = splitCommonEmail[3].replace(/[\[\]']+/g, '');
            MyAlert.danger(
              <label title={emailGrpMailId}>{`Recipient Email  ${
                emailGrpMailId.length > UI_VALIDATIONS.MAX_EMAIL_CHAR_COUNT
                  ? emailGrpMailId.substring(0, UI_VALIDATIONS.MAX_EMAIL_CHAR_COUNT) + '...'
                  : emailGrpMailId
              } already exists`}</label>
            );
          }
          if (res.data.responseCode === 400 && res.data.message.includes('Please Provide valid mail')) {
            MyAlert.warning(res?.data?.message);
          }
        })
        .catch((err) => {
          console.error('createGroup Individual', err);
        });
    } else {
      localStorage.setItem('resultGetReq', true);
      postEmailConfigReq(projectId, data)
        .then((res) => {
          localStorage.setItem('resultGetReq', false);
          if (res?.data?.status === 'SUCCESS') {
            setGetDltData(res);
            MyAlert.success(`${name} Email group Created successfully`);
            handleCloseGroup();
          }
          if (res.data.message === 'Minimum 2 Recipients required,') {
            MyAlert.danger(`Add at least 2 recipients to create a group`);
          }

          if (res?.data?.responseCode === 400 && res?.data?.message.includes('Invalid EmailGroup name,')) {
            formik.setFieldError('name', 'Special character are not allowed');
          }

          if (
            res.data.message ===
            'Recipients name is mandatory,Invalid Recipients name,Recipients EmailId is mandatory,Please Provide valid mail in Email group,'
          ) {
            MyAlert.danger(`Add at least 2 recipients to create a group`);
          }
          if (res.data.message === `email group with name ${name} already exists`) {
            MyAlert.danger(`Email group with name ${name} already exists`);
          }
          const splitErrorMessageEmail = res.data.message.split(' ', 3);
          if (splitErrorMessageEmail[1] + splitErrorMessageEmail[2] === 'EmailId') {
            const splitCommonEmail = res.data.message.split(' ', 4);
            let emailGrpMailId = splitCommonEmail[3].replace(/[\[\]']+/g, '');
            MyAlert.danger(
              <label title={emailGrpMailId}>{`Recipient Email  ${
                emailGrpMailId.length > UI_VALIDATIONS.MAX_EMAIL_CHAR_COUNT
                  ? emailGrpMailId.substring(0, UI_VALIDATIONS.MAX_EMAIL_CHAR_COUNT) + '...'
                  : emailGrpMailId
              } already exists`}</label>
            );
          }
          if (res.data.responseCode === 400 && res.data.message.includes('Please Provide valid mail')) {
            MyAlert.warning(res?.data?.message);
          }
        })
        .catch((err) => {
          console.error('createGroup all level', err);
        });
    }
  };

  const deleteData = () => {
    deleteEmailConfigReq(groupID.id)
      .then((res) => {
        setGetDltData(res.data);
        if (res.data.responseCode === 200) {
          MyAlert.success(`${groupID.name} Email group deleted successfully`);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    setOpen(false);
  };

  const selectUser = async (emailId) => {
    try {
      const result = await getEmailConfigReq();
      result?.data?.responseObject?.map((res, index) => {
        if (res.id === emailId) {
          const emailsList = res.emailUserList;
          setEditEmailList(emailsList);
          setEditname(res.name);
          handleEditOpenGroup(true);
        }
      });
    } catch (error) {
      console.error('select user get email config req api', error);
    }
  };
  useEffect(() => {
    selectUser();
  }, [emailId]);

  const handelChange = (e) => {
    setEditNameData(e.target.value);
  };

  const handelEditname = (e) => {
    setEditname(e.target.value);
  };

  const handelRecpEmailEdit = (e) => {
    setRecipientEmailAddressEdit(e.target.value);
  };

  const handeladdRecipient = (e) => {
    setAddRecipientname(e.target.value);
  };

  const handeladdRecipientEmail = (e) => {
    setAddRecipientEmailAddress(e.target.value);
  };
  // clone
  const cloneGroup = (e) => {
    localStorage.setItem('resultGetReq', true);
    e.preventDefault();
    let data = {
      name: Editname,
      emailUserList: [
        {
          name: addRecipientname,
          emailId: addRecipientEmailAddress,
        },
        {
          name: editnameData,
          emailId: RecipientEmailAddressEdit,
        },
      ],
    };

    postEmailConfigReq(data)
      .then((res) => {
        localStorage.setItem('resultGetReq', false);
        if (res.data.status === 'SUCCESS') {
          setGetDltData(res);
          MyAlert.success(`${Editname} Email group cloned successfully`);
          handleCloseCloneGroup();
        }
        if (
          res.data.message ===
          'Recipients name is mandatory,Invalid Recipients name,Recipients EmailId is mandatory,Please Provide valid mail in Email group,'
        ) {
          MyAlert.danger(`Add at least 2 recipients to create a group`);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // update
  const updateEditVal = (list) => {
    localStorage.setItem('resultGetReq', true);
    if (formik.errors.Editname) {
      return;
    }
    let data = {
      name: Editname,
      emailUserList: list,
    };

    putEmailConfigReq(groupID.id, data, projectId)
      .then((res) => {
        localStorage.setItem('resultGetReq', false);
        setGetDltData(res.data);
        if (res?.data?.status === 'FAILURE' && res?.data?.message.includes('Invalid EmailGroup name,')) {
          formik.setFieldError('Editname', 'Special character are not allowed');
        }
        if (
          JSON.stringify(groupID?.emailUserList) === JSON.stringify(res.data.responseObject?.emailUserList) &&
          JSON.stringify(groupID?.name) === JSON.stringify(res.data?.responseObject?.name)
        ) {
          MyAlert.info(
            `No Changes were done to the ${
              res.data.responseObject.name.length > UI_VALIDATIONS.MAX_EMAIL_GROUP_NAME_COUNT
                ? res.data.responseObject.name.substring(0, UI_VALIDATIONS.MAX_EMAIL_GROUP_NAME_COUNT) + '...'
                : res.data.responseObject.name
            } Email group`
          );
          return;
        }
        const splitErrorMessageEmail = res.data.message?.split(' ', 3);
        if (res.data.responseCode === 200) {
          setOpenEditGroup(false);
          MyAlert.success(`${data.name} Email group updated successfully`);
        }
        if (res.data.message === 'Minimum 2 Recipients required,') {
          MyAlert.danger(`Add at least 2 recipients to create a group`);
        }
        if (res.data.message === `email group with name ${Editname} already exists`) {
          MyAlert.danger(`email group with name ${Editname} already exists`);
        } else if (splitErrorMessageEmail[1] + splitErrorMessageEmail[2] === 'EmailId') {
          const splitCommonEmail = res.data.message.split(' ', 4);
          let emailGrpMailId = splitCommonEmail[3].replace(/[\[\]']+/g, '');
          MyAlert.danger(
            <label title={emailGrpMailId}>{`Recipient Email  ${
              emailGrpMailId.length > UI_VALIDATIONS.MAX_EMAIL_CHAR_COUNT
                ? emailGrpMailId.substring(0, UI_VALIDATIONS.MAX_EMAIL_CHAR_COUNT) + '...'
                : emailGrpMailId
            } already exists`}</label>
          );
        }
        if (res.data.responseCode === 400 && res.data.message.includes('Please Provide valid mail in Email group,')) {
          MyAlert.warning(res?.data?.message);
        }
      })
      .catch((err) => {
        console.error('updateEditVal', err);
      });
  };

  // suggestions
  const onChangeHandeler = (text) => {
    let matches = [];
    let emailids = [];
    if (text.length > 0) {
      matches = emailData
        .map((v) => v.emailUserList.map((data) => data))
        .filter((user) => {
          const regex = new RegExp(`${text}`, 'gi');
          return user[0].name.match(regex);
        });
    }
    setSuggestion(matches);
    setText(text);
  };

  // 2nd suggestions
  const onChangeHandelers = (texts) => {
    let matches = [];
    if (texts?.length > 0) {
      matches = emailData
        .map((v) => v.emailUserList.map((data) => data))
        .filter((user) => {
          const regex = new RegExp(`${texts}`, 'gi');
          return user[1].name.match(regex);
        });
    }
    setSuggestions(matches);
    setTexts(texts);
  };
  const onSuggestionHandeler = (text) => {
    setText(text);
    let emailList = emailData.map((v) =>
      v.emailUserList.filter((val) => {
        if (val.name === text) {
          setEname(val.emailId);
        }
      })
    );
    setSuggestion([]);
  };

  const onSuggestionHandelerS = (texts) => {
    setTexts(texts);
    let emailList = emailData.map((v) =>
      v.emailUserList.filter((val) => {
        if (val.name === texts) {
          // setTexts(val.emailId)
          setEmailName(val.emailId);
        }
      })
    );
    setSuggestions([]);
  };

  const onChangeEmailId = (e) => {
    setEmailName(e.target.value);
  };
  const onChangeEmail = (e) => {
    setEname(e.target.value);
  };

  const _handleClonePopUp = (ele) => {
    handleCloneOpenGroup();
    setGroupID(ele);
    selectUser(ele?.id);
    setEditname(ele?.name);
    setAddRecipientname(ele?.emailUserList[0]?.name);
    setAddRecipientEmailAddress(ele?.emailUserList[0]?.emailId);
    setEditNameData(ele?.emailUserList[1].name);
    setRecipientEmailAddressEdit(ele?.emailUserList[1]?.emailId);
  };

  let activeTab = props.location.pathname.split('/')[3];

  const [tabsList, setTabsList] = useState([
    {
      name: 'Email Group',
      pathUrl: `/configuration/emailConfiguration/emailGroup`,
      selectedName: 'emailGroup',
    },

    // {
    //   "name": "History",
    //   "pathUrl": `/configuration/emailConfiguration/history`,
    //   "selectedName": "history"
    // }
  ]);

  const renderTabs = () => {
    return (
      <div>
        <ul className="navBar text-blue-600 flex flex-row primary-tab">
          {tabsList.map((tab, index) => {
            return (
              <li key={tab.name}>
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    props.history.push(tab.pathUrl);
                  }}
                  className={`${activeTab === tab.selectedName ? 'selected_tab_style' : ''}`}
                >
                  {tab.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };
  // menuBTN

  function contextMenuFunction(event, ele) {
    if (event.target.textContent === 'Details') {
      handleOpenUser(true);
      setGroupID(ele);
    }
  }

  const menuBtn = (ele) => {
    return (
      <>
        <div className="actionIcons -ml-4  flex justify-start align-middle">
          {isEditable && (
            <>
              <button
                className={`float-left text-blue-700 ${
                  projectId ? (ele?.projectId ? ' opacity-100' : 'opacity-50') : 'opacity-100'
                }`}
                onClick={() => {
                  setEmailId(ele.id);
                  setGroupID(ele);
                  selectUser(ele.id);
                  setEditname(ele.name);
                  setAddRecipientname(ele.emailUserList[0].name);
                  setAddRecipientEmailAddress(ele.emailUserList[0].emailId);
                  setEditNameData(ele.emailUserList[1].name);
                  setRecipientEmailAddressEdit(ele.emailUserList[1].emailId);
                }}
                disabled={projectId ? (ele?.projectId ? false : true) : false}
              >
                <Tooltip title={projectId ? (ele?.projectId ? 'Edit' : 'Cannot be edited') : 'Edit'}>
                  <EditOutlinedIcon />
                </Tooltip>
              </button>

              {hasFullAccess && (
                <button
                  className={`float-left text-blue-700 ml-2 rounded-full flex items-center focus:text-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 ${
                    projectId ? (ele?.projectId ? ' opacity-100' : 'opacity-50') : 'opacity-100'
                  }`}
                  onClick={() => {
                    handleOpen(true);
                    setGroupID(ele);
                  }}
                  disabled={projectId ? (ele?.projectId ? false : true) : false}
                >
                  <Tooltip title={projectId ? (ele?.projectId ? 'Delete' : 'Cannot be deleted') : 'Delete'}>
                    <DeleteOutlinedIcon />
                  </Tooltip>
                </button>
              )}
            </>
          )}

          <div className="float-left project-action pt-1 text-blue-700 text-base cursor-pointer">
            <ContextMenu
              contextMenuData={ele ? contextMenuData : [...contextMenuData]}
              func={(e) => {
                contextMenuFunction(e, ele);
              }}
              configSection={true}
            />
          </div>
        </div>
      </>
    );
  };
  const getVariables = async () => {
    try {
      let res = await getEmailConfigReq();
      if (res.data && res.data.responseObject && res.data.responseObject.length > 0) {
        setTableData(res.data.responseObject);
      } else {
        setTableData([]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getVariables();
  }, [getDltData]);

  // react table

  // TODO :: handle Search filter :: START
  const [userSearched, setuserSearched] = useState('');
  const [data, setData] = useState([]);
  let [isHighlight, setIsHighlight] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');
  let [openSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    setData(tableData);
    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [tableData]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(tableData);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setData(tableData);
    setSearchTextHighlight('');
    setIsHighlight(!isHighlight);
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (!e?.target?.value == '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = tableData.filter(({ name, createdByUname }) => {
          return isValueIncludes(e, name) || isValueIncludes(e, createdByUname);
        });
        setData(newData);
        setSearchTextHighlight(e?.target?.value || e);
        setIsHighlight(!isHighlight);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  // TODO :: handle Search filter :: END

  const headerOfAllProject = [
    {
      Header: 'GROUP NAME',
      accessor: 'name',
      width: '15%',
      Cell: (e) => (
        <Tooltip title={e.value} placement="top">
          <div
            className="text-gray-700 cursor-pointer fontPoppinsRegularMd name-trimmer"
            onClick={() => {
              handleOpenUser(true);
              setGroupID(e.row.original);
            }}
          >
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </div>
        </Tooltip>
      ),
    },
    {
      Header: 'NO OF RECIPIENT',
      accessor: 'emailUserList',
      width: '22%',
      Cell: (e) => (
        <span className="text-gray-700  fontPoppinsRegularMd">
          {e.row.original?.emailUserList ? e.row.original.emailUserList.length : null}
        </span>
      ),
    },
    {
      Header: 'CREATED BY',
      accessor: 'createdByUname',
      width: '23%',
      Cell: (e) => (
        <Tooltip title={e.value} placement="top">
          <div className="text-gray-700  fontPoppinsRegularMd">
            <HighlightText
              text={e.value ? (e.value.length > 15 ? e.value.substring(0, 15) + '...' : e.value) : '--'}
              highlight={searchTextHighlight}
            />
          </div>
        </Tooltip>
      ),
    },
    {
      Header: 'CREATED ON',
      accessor: 'createdOn',
      width: '23%',
      Cell: (e) => <span className="text-gray-700 fontPoppinsRegularMd">{e?.value || '--'}</span>,
    },
    {
      Header: 'ACTIONS',
      accessor: 'actions',
      width: '28%',
      Cell: (e) => menuBtn(e.row.original),
    },
  ];

  const headerOfIndividual = [
    {
      Header: 'GROUP NAME',
      accessor: 'name',
      width: '15%',
      Cell: (e) => (
        <Tooltip title={e.value} placement="top">
          <div
            className="text-gray-700 cursor-pointer fontPoppinsRegularMd name-trimmer"
            onClick={() => {
              handleOpenUser(true);
              setGroupID(e.row.original);
            }}
          >
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </div>
        </Tooltip>
      ),
    },
    {
      Header: 'NO OF RECIPIENT',
      accessor: 'emailUserList',
      width: '15%',
      Cell: (e) => (
        <span className="text-gray-700  fontPoppinsRegularMd">
          {e.row.original?.emailUserList ? e.row.original.emailUserList.length : null}
        </span>
      ),
    },
    {
      Header: 'FROM',
      accessor: 'From',
      width: '15%',
      Cell: (e) => (
        <span className="text-gray-700 cursor-pointer fontPoppinsRegularMd">
          {e.row.original?.projectId ? 'Project' : 'License'}
        </span>
      ),
    },
    {
      Header: 'CREATED BY',
      accessor: 'createdByUname',
      width: '20%',
      Cell: (e) => (
        <Tooltip title={e.value} placement="top">
          <div className="text-gray-700  fontPoppinsRegularMd name-trimmer">
            <HighlightText text={e.value || '--'} highlight={searchTextHighlight} />
          </div>
        </Tooltip>
      ),
    },
    {
      Header: 'CREATED ON',
      accessor: 'createdOn',
      width: '20%',
      Cell: (e) => <span className="text-gray-700 fontPoppinsRegularMd">{e?.value || '--'}</span>,
    },
    {
      Header: 'ACTIONS',
      accessor: 'actions',
      width: '28%',
      Cell: (e) => menuBtn(e.row.original),
    },
  ];

  const columns = React.useMemo(() => {
    if (projectId) {
      return headerOfIndividual;
    } else {
      return headerOfAllProject;
    }
  }, [isHighlight]);

  const { isEditable, hasFullAccess } = React.useMemo(
    () => ({
      isEditable: window.permission?.isEditAccess('configuration'),
      hasFullAccess: window.permission?.isFullAccess('configuration'),
    }),
    []
  );

  return (
    <div className="page-container">
      <div className={classes.root} style={{ marginTop: '-2px', display: 'flex' }}></div>
      <div className="my-1 ">{renderTabs()}</div>

      {openGroup || openEditGroup ? (
        ''
      ) : (
        <div className="alert-position-style">
          <AlertContatiner />
        </div>
      )}
      <div className="page-list-body">
        <div className="page-table-body">
          <div className="configPage pr-0 content_area_style" id="journal-scroll">
            <div className=" flex justify-between items-center h-14">
              <div className=" ml-3 fontPoppinsSemiboldXlg configPageHeading">Email Group</div>
              <div className="flex justify-center items-center gap-3 px-5">
                <SearchBar
                  open={openSearch}
                  searchValue={userSearched}
                  handleSearch={handleSearch}
                  onSearchClose={handleSearchClose}
                  handleSearchOpen={handleSearchOpen}
                  enterKeyHandler={handleSearchSubmit}
                  disable={isEmptyValue(tableData)}
                />

                {isEditable && (
                  <div className=" flex items-center">
                    <button onClick={handleOpenGroup} className="m-2 primary-btn ">
                      <span>+ Group</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
            <hr></hr>

            <div className=" grid  grid-cols-1 fontPoppinsRegularMd">
              <ReactTable data={data} columns={columns} tableHeightClass={'main-table-height'} />
              {isEmptyValue(tableData) && (
                <div className={ConfigMemoryStyle['center-div-group-btn']}>
                  <h1 className="font-semibold  text-left">{isEditable ? 'Quick Start' : 'No Email Groups!'}</h1>
                  {isEditable && (
                    <div className="flex">
                      <p className="mt-4 fontPoppinsRegularMd"> Step 1 :&nbsp;</p>
                      <p className="mt-4 "> Click</p>{' '}
                      <button onClick={handleOpenGroup} className="mt-3 ml-4 primary-btn mx-5 items-center float-right">
                        <span>+ Group</span>
                      </button>
                      <p className="mt-4">to start adding email group</p>
                    </div>
                  )}
                </div>
              )}
              {userSearched && isEmptyValue(data) && <NoResultsFound className="mt-14" />}
            </div>
          </div>

          {/*----------------------Email Group Modal Section-------------------------------- */}

          <Modal
            open={openGroup}
            onClose={handleCloseGroup}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box>
              <div className="fixed top-6 left-1/3 z-10">
                <AlertContatiner />
              </div>
              <div
                id="addHeight"
                className={cx(
                  'shadow-xs transform transition-all sm:align-middle w-2/4',
                  ConfigMemoryStyle['modal-container']
                )}
              >
                <div>
                  <div className="fontPoppinsSemiboldLg mt-4 mx-3  block">
                    Create Email Group
                    <div onClick={handleCloseGroup} style={{ marginLeft: '35rem', color: 'grey', marginTop: '-20px' }}>
                      <CloseIcon />
                    </div>
                  </div>
                </div>
                <hr className="line mt-3" />
                <form onSubmit={formik.handleSubmit} className={ConfigMemoryStyle['addrow-maxheight']}>
                  <div className="text-sm font-sans mt-3 text-gray-500 ml-8 mr-8">
                    <MyInput
                      type="text"
                      error={formik.errors.name && formik.touched.name}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      name="name"
                      id="name"
                      autoComplete="off"
                      style={{ width: '50%' }}
                      values={formik.values.name}
                      autoFocus={true}
                      className={cx('block w-9/12 Apptype pb-0', ConfigMemoryStyle['email-config-input-padding'])}
                      placeholder="Enter email group name"
                      helperText={
                        <div className="fontPoppinsRegularSm  errorMessage absolute whitespace-nowrap">
                          {formik.touched.name && formik.errors.name}
                        </div>
                      }
                      label={
                        <>
                          {' '}
                          <span class="text-red-400" style={{ color: '#EA0322' }}>
                            *
                          </span>
                          <span className="fontPoppinsRegularXLg"> Name</span>
                        </>
                      }
                    />
                  </div>
                  <div id="addHeight " className="mt-4">
                    <div className="mt-7 mx-4">
                      <div className="fontPoppinsSemiboldMd mx-4">Recipient Details</div>
                      <span className="fontPoppinsRegularMd mx-4 text-gray-500">
                        (Add at least 2 recipients to create a group)
                      </span>
                    </div>
                  </div>
                </form>
                <CreateRecipientInputFiled
                  createGroup={createGroup}
                  handleCloseGroup={handleCloseGroup}
                  errorName={formik.errors.name}
                  errorTouchedName={formik.touched.name}
                />
              </div>
            </Box>
          </Modal>

          {/*----------------------Email edit Group Modal Section-------------------------------- */}

          <Modal
            open={openEditGroup}
            onClose={handleCloseEditGroup}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div>
              <div className="alertPositionStyle">
                <AlertContatiner />
              </div>
              <div
                style={{
                  width: '38rem',
                  height:'29rem',
                  position: 'absolute',
                  top: '50%',
                  left: '55%',
                  transform: 'translate(-50%, -50%)',
                }}
                className={cx(
                  'shadow-xs transform transition-all sm:align-middle w-2/4',
                  ConfigMemoryStyle['modal-container']
                )}
              >
                <div className="inline-block">
                  <div className="fontPoppinsSemiboldLg mt-4 mx-3 text-sm block">
                    Edit Email Group
                    <div
                      onClick={handleCloseEditGroup}
                      style={{ marginLeft: '35rem', color: 'grey', marginTop: '-20px' }}
                    >
                      <CloseIcon />
                    </div>
                  </div>
                </div>
                <hr className="line mt-3" />
                <form onSubmit={formik.handleSubmit}>
                  <div className="text-sm font-sans mt-3 text-gray-500 ml-8 mr-8">
                    <MyInput
                      type="text"
                      error={formik.errors.Editname && formik.touched.Editname}
                      value={Editname}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        handelEditname(e);
                        formik.handleChange(e);
                      }}
                      name="Editname"
                      id="Editname"
                      autoComplete="off"
                      style={{ width: '50%' }}
                      autoFocus={false}
                      className={cx('block w-9/12 Apptype', ConfigMemoryStyle['email-config-input-padding'])}
                      placeholder="Enter email group name"
                      helperText={
                        <div className="fontPoppinsRegularSm errorMessage absolute">
                          {formik.touched.Editname && formik.errors.Editname}
                        </div>
                      }
                      label={
                        <>
                          {' '}
                          <span className="text-red-400">*</span>
                          <span className="fontPoppinsRegularXLg"> Name</span>
                        </>
                      }
                    />
                  </div>
                  <div className="mt-7 mx-4">
                    <div className=" fontPoppinsSemiboldMd mx-4">Recipient Details</div>
                    <span className="fontPoppinsRegularMd ml-4 text-gray-500">
                      (Add at least 2 recipients to create a group)
                    </span>
                  </div>
                </form>
                <EditRecipientInputFiled
                  updateEditVal={updateEditVal}
                  handleCloseEditGroup={handleCloseEditGroup}
                  editEmailList={editEmailList}
                />
              </div>
            </div>
          </Modal>
          {/*-----------End edit popup-------------------------------- */}

          {/*---------Details modal popup----------------- */}

          <Modal
            open={openUserDetails}
            onClose={handleCloseUser}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="flex flex-row justify-between mx-5">
                <div className="w-11/12">
                  <span className="fontPoppinsMediumLg block my-4">
                    {emailData &&
                      emailData.map((res) => {
                        if (res.id === groupID.id) {
                          return <p className="truncate"> Email Group Details-{res.name}</p>;
                        }
                      })}
                  </span>
                </div>
                <div onClick={handleCloseUser} className=" mt-4">
                  <CloseIcon />
                </div>
              </div>
              <hr className=""></hr>
              <div className="mt-5 mx-8 flex justify-between">
                <div className="flex flex-col">
                  <div>
                    <label className=" details-key-style-common">Name</label>
                    {emailData &&
                      emailData.map((res) => {
                        if (res.id === groupID.id) {
                          return (
                            <Tooltip title={res.name} placement="top">
                              <p className="details-data-style-common">
                                {res.name.length > 20 ? res.name.substring(0, 20) + '...' : res.name}
                              </p>
                            </Tooltip>
                          );
                        }
                      })}
                  </div>
                  <div className="mt-4">
                    <label className=" details-key-style-common">Created By</label>
                    {emailData &&
                      emailData.map((res) => {
                        if (res.id === groupID.id) {
                          return (
                            <Tooltip title={res.createdByUname} placement="top">
                              <p className="details-data-style-common">
                                {res.createdByUname.length > 20
                                  ? res.createdByUname.substring(0, 20) + '...'
                                  : res.createdByUname}
                              </p>
                            </Tooltip>
                          );
                        }
                      })}
                  </div>
                  <div className="mt-4">
                    <label className="details-key-style-common">Modified By</label>
                    {emailData &&
                      emailData.map((res) => {
                        if (res.id === groupID.id) {
                          return (
                            <Tooltip title={res.modifiedByUname} placement="top">
                              <p className="details-data-style-common">
                                {res.modifiedByUname.length > 20
                                  ? res.modifiedByUname.substring(0, 20) + '...'
                                  : res.modifiedByUname}
                              </p>
                            </Tooltip>
                          );
                        }
                      })}
                  </div>
                </div>

                <div className="flex flex-col">
                  <div>
                    <label className="details-key-style-common">No of Recipients</label>

                    {emailData &&
                      emailData.map((res) => {
                        if (res.id === groupID.id) {
                          return <p className="details-data-style-common ">{res ? res.emailUserList?.length : ''}</p>;
                        }
                      })}
                  </div>
                  <div className="mr-3 mt-4">
                    <label className="details-key-style-common">Created On</label>
                    {emailData &&
                      emailData.map((res) => {
                        if (res.id === groupID.id) {
                          return <p className="details-data-style-common">{res.createdOn}</p>;
                        }
                      })}
                  </div>
                  {emailData &&
                    emailData.map((res) => {
                      if (res.id === groupID.id) {
                        return (
                          <div className={res.modifiedOn === '--' ? 'mr-7 mt-4' : 'mr-3 mt-4'}>
                            <label className="details-key-style-common">Modified On</label>

                            <p className="details-data-style-common">{res.modifiedOn}</p>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>

              <div className="my-2" style={{ borderBottom: '2.5px dashed #F0F3F9' }}></div>
              <div>
                <Box
                  sx={{
                    boxShadow: 2,
                    bgcolor: 'background.paper',
                    m: 2,
                    width: '32rem',
                    height: '15rem',
                  }}
                >
                  <span className=" fontPoppinsSemiboldLg p-3  block">Recipient Details</span>
                  <div className=" boxHead p-2 grid grid-cols-3 gap-4">
                    <span className="mx-6 ricipTableName fontPoppinsSemiboldMd">Name</span>
                    <span className="ml-20 w-24 ricipTableName fontPoppinsSemiboldMd">Email ID </span>
                  </div>

                  <div className={cx('flex flex-col overflow-y-scroll h-36')}>
                    <div>
                      {emailData &&
                        emailData.map((res) => {
                          if (res.id === groupID.id) {
                            return res.emailUserList.map((resName, index) => {
                              return (
                                <div className="flex" key={index}>
                                  <p title={resName.name} className="fontPoppinsRegularMd ricipName">
                                    {resName.name}
                                  </p>
                                  <p title={resName.emailId} className="fontPoppinsRegularMd ricipEmail">
                                    {resName.emailId}
                                  </p>
                                </div>
                              );
                            });
                          }
                        })}
                    </div>
                  </div>
                </Box>
              </div>

              <hr className="mt-3"></hr>
              <div className="mt-2 pb-2 flex flex-row float-right">
                <button
                  onClick={handleCloseUser}
                  type="button"
                  className=" gray-btn ml-1"
                  style={{
                    fontSize: '14px',
                    fontFamily: 'Poppins-SemiBold',
                    marginRight: '12px',
                  }}
                >
                  Close
                </button>
              </div>
            </Box>
          </Modal>

          {/*---------End Details modal popup----------------- */}

          {/*-----------------Clone modal popup------------------------------ */}

          <Modal
            open={openCloneGroup}
            onClose={handleCloseCloneGroup}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box>
              <div
                style={{
                  width: '38rem',
                  height: '27rem',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
                className="  pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xs transform transition-all sm:align-middle w-2/4"
              >
                <div className="inline-block">
                  <div className="font-bold fontPoppinsSemiboldMd   mt-4 mx-3 text-sm block">
                    Clone Email Group
                    <div
                      onClick={handleCloseCloneGroup}
                      style={{ marginLeft: '35rem', color: 'grey', marginTop: '-20px' }}
                    >
                      <CloseIcon />
                    </div>
                  </div>
                </div>

                <hr className="line mt-3" />
                <form>
                  <div className="text-sm font-sans mt-3 text-gray-500 ml-8 mr-8">
                    <MyInput
                      type="text"
                      error={formik.errors.Editname && formik.touched.Editname}
                      value={Editname}
                      // onBlur={formik.handleBlur}
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        handelEditname(e);
                        formik.handleChange(e);
                      }}
                      name="Editname"
                      id="Editname"
                      autoComplete="off"
                      style={{ width: '50%' }}
                      values={formik.values.Editname}
                      autoFocus={true}
                      className=" block w-9/12 Apptype"
                      placeholder="Enter email group name"
                      helperText={formik.touched.Editname && formik.errors.Editname}
                      label={
                        <>
                          {' '}
                          <span
                            class="text-red-400"
                            style={{ color: '#EA0322', fontSize: '12px', fontFamily: 'Poppins-SemiBold' }}
                          >
                            *
                          </span>
                          <span style={{ fontSize: '16px', fontFamily: 'Poppins-Regular' }}> Name</span>
                        </>
                      }
                    />
                  </div>

                  <div className="my-6" style={{ borderBottom: '2.5px dashed #F0F3F9' }}></div>
                  <div className="mt-1 mx-4">
                    <div className=" font-semibold text-sm mx-4">Recipient Details</div>
                    <span className="text-sm mx-4 text-gray-500">(Add at least 2 recipients to create a group)</span>
                  </div>
                  {addRecip && (
                    <div className="" style={{ display: 'flex' }}>
                      <div className="text-sm font-sans  text-gray-500 ml-8" style={{ width: '500px' }}>
                        <MyInput
                          type="text"
                          error={formik.errors.addRecipientname && formik.touched.addRecipientname}
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            handeladdRecipient(e);
                            formik.handleChange(e);
                          }}
                          name="addRecipientname"
                          id="addRecipientname"
                          autoComplete="off"
                          style={{ width: '90%' }}
                          values={formik.values.addRecipientname}
                          value={addRecipientname}
                          autoFocus={false}
                          className=" block w-9/12 Apptype"
                          placeholder="Enter Recipient Name"
                          helperText={formik.touched.addRecipientname && formik.errors.addRecipientname}
                        />
                      </div>

                      <div
                        className="text-sm font-sans  text-gray-500 "
                        style={{ marginRight: '-8rem', width: '900px' }}
                      >
                        <MyInput
                          type="text"
                          error={formik.errors.addRecipientEmailAddress && formik.touched.addRecipientEmailAddress}
                          value={addRecipientEmailAddress}
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            handeladdRecipientEmail(e);
                            formik.handleChange(e);
                          }}
                          name="addRecipientEmailAddress"
                          id="addRecipientEmailAddress"
                          autoComplete="off"
                          style={{ width: '50%' }}
                          values={formik.values.addRecipientEmailAddress}
                          autoFocus={false}
                          className=" block w-9/12 Apptype"
                          placeholder="Enter recipient email address"
                          helperText={formik.touched.addRecipientEmailAddress && formik.errors.addRecipientEmailAddress}
                        />
                      </div>
                      <div>
                        {' '}
                        <DeleteOutlineIcon onClick={handelAddRecipClose} className="h-7 w-15 text-blue-600 -ml-7 " />
                      </div>
                    </div>
                  )}

                  <div className="mt-2" style={{ display: 'flex' }}>
                    <div className="text-sm font-sans mt-3 text-gray-500 ml-8" style={{ width: '500px' }}>
                      <MyInput
                        type="text"
                        error={formik.errors.RecipientNameOne && formik.touched.RecipientNameOne}
                        value={editnameData}
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          handelChange(e);
                        }}
                        name="RecipientNameOne"
                        id="RecipientNameOne"
                        autoComplete="off"
                        style={{ width: '90%' }}
                        values={formik.values.RecipientNameOne}
                        autoFocus={false}
                        className=" block w-9/12 Apptype"
                        placeholder="Enter Recipient Name"
                        helperText={formik.touched.RecipientNameOne && formik.errors.RecipientNameOne}
                      />
                    </div>
                    <div
                      className="text-sm font-sans mt-3 text-gray-500 "
                      style={{ marginRight: '-8rem', width: '900px' }}
                    >
                      <MyInput
                        type="text"
                        error={formik.errors.RecipientEmailAddressEdit && formik.touched.RecipientEmailAddressEdit}
                        value={RecipientEmailAddressEdit}
                        // onBlur={formik.handleBlur}
                        // onChange={formik.handleChange}
                        onChange={(e) => {
                          handelRecpEmailEdit(e);
                        }}
                        name="RecipientEmailAddressEdit"
                        id="RecipientEmailAddressEdit"
                        autoComplete="off"
                        style={{ width: '50%' }}
                        values={formik.values.RecipientEmailAddressEdit}
                        autoFocus={false}
                        className=" block w-9/12 Apptype"
                        placeholder="Enter recipient email address"
                        helperText={formik.touched.RecipientEmailAddressEdit && formik.errors.RecipientEmailAddressEdit}
                      />
                    </div>
                  </div>
                  <div className="mx-8 mt-6" style={{ color: 'blue' }}>
                    <AddToPhotosIcon onClick={handelAddRecip} />
                    &nbsp;
                    <span onClick={handelAddRecip} className="text-blue-700 cursor-pointer">
                      Add Recipient
                    </span>
                  </div>
                </form>
                <hr className="line mt-10" />
                <div className=" flex flex-row float-right ">
                  <button
                    type="button"
                    className="mt-3 gray-btn ml-1"
                    onClick={handleCloseCloneGroup}
                    style={{
                      fontSize: '14px',
                      fontFamily: 'Poppins-SemiBold',
                      marginRight: '12px',
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={cloneGroup}
                    className="mt-3 ml-4 primary-btn "
                    style={{
                      fontSize: '14px',
                      fontFamily: 'Poppins-SemiBold',
                      marginRight: '12px',
                    }}
                  >
                    Clone
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
          {/*----------------------End Clone modal popup------------------ */}
          {/*------delete modal-------------------- */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box>
              <div
                style={{
                  width: '40rem',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
                className=" marginupper pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/4"
              >
                <div className="inline-block">
                  <div className="flex flex-start ml-2">
                    <span className="pt-5 pl-3">
                      <ExclamationIcon className="h-6 w-6 text-yellow-600 " />
                    </span>
                    <span className="fontPoppinsSemiboldLg mt-4 mx-3 block">Warning </span>
                    <span
                      style={{
                        marginLeft: '-80px',
                        marginTop: '35px',
                      }}
                      className="fontPoppinsMediumMd"
                    >
                      Delete{' '}
                    </span>
                  </div>
                </div>

                <hr className="line mt-px" />
                <div className="fontPoppinsMediumMd mt-3 text-gray-500 ml-16 mr-8">
                  <p className="" style={{ color: 'black' }}>
                    Your data will be lost.Are you sure you want to delete Email Group?
                  </p>
                  <p className="mt-2" style={{ color: 'black' }}>
                    How do you want to proceed?
                  </p>
                </div>

                <hr className="line mt-3" />
                <div className="mt-2 pb-2 flex flex-row float-right">
                  <button
                    type="button"
                    className="mt-3 gray-btn ml-1"
                    onClick={deleteData}
                    style={{
                      fontSize: '14px',
                      fontFamily: 'Poppins-SemiBold',
                      marginRight: '12px',
                    }}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    onClick={handleClose}
                    className="mt-3 ml-4 primary-btn "
                    style={{
                      fontSize: '14px',
                      fontFamily: 'Poppins-SemiBold',
                      marginRight: '12px',
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
          {/*-----end delete modal------------------- */}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Email_group);

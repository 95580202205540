import React, { useMemo, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { SLACK_CONSTANTS } from '../plugin_constants';
import {
  validateAuthToken,
  validateChannelId,
  createSlackInstance,
  updateSlackInstance,
  getSlackDetails,
  getAllSuiteReq,
  updateBitBucket,
  validateRepository,
} from '@api/api_services';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CommonDrawerJs from '../CommonIntegrationDrawer';

const SlackCreateEditInstance = (props) => {
  const [showDetails, setShowDetails] = useState(false);
  const [validOauthToken, setValidOauthToken] = useState(true);
  const initialValues = {
    oauthToken: props.actionType === 'Edit' ? props?.seletedInstance?.oauthToken : '',
    instanceName: props.actionType === 'Edit' ? props?.seletedInstance?.instanceName : '',
    channelId: props.actionType === 'Edit' ? props?.seletedInstance?.channelId : '',
    channelName: props.actionType === 'Edit' ? props?.seletedInstance?.channelName : '',
  };
  const validationSchema = yup.object({
    instanceName: yup
      .string()
      .matches(/^[A-Za-z0-9 _]*$/, 'Name can not have special characters')
      .min(3, 'Name should contain at least 3 characters')
      .max(25, 'Name can contain at most 25 characters')
      .matches(/^[^\s].*[^\s]$/, 'Space is not allowed in the beginning and at the end')
      .required('Instance Name is required'),
    oauthToken: yup.string().required('Oauth Token is required').matches(/^\S*$/, 'Space is not allowed'),
    channelId: yup.string().required('Channel ID  is required').matches(/^\S*$/, 'Space is not allowed'),
  });

  const formikDetails = useFormik({
    initialValues,
    validationSchema,
  });

  const oauthToken = formikDetails.values.oauthToken;
  const validateAuth = async () => {
    validateAuthToken(oauthToken)
      .then((response) => {
        if (response?.data?.responseCode === 200) {
          setShowDetails(true);
          if (props.actionType === 'Edit' && compare === false) {
            formikDetails.setFieldValue('channelId', '');
            formikDetails.setFieldValue('channelName', '');
            setVerified(false);
          }
        }
        if (response?.data?.responseCode === 401) {
          formikDetails.setFieldError('oauthToken', response.data.message);
          setShowDetails(false);
          setValidOauthToken(false);
        }
      })
      .catch((error) => {
        console.error('API error:', error);
      });
  };
  const createInstance = async () => {
    const payload = {
      instanceName: formikDetails?.values?.instanceName,
      channelName: formikDetails?.values?.channelName,
      oauthToken: formikDetails?.values?.oauthToken,
      channelId: formikDetails?.values?.channelId,
    };
    createSlackInstance(payload)
      .then((response) => {
        if (response?.data?.responseCode === 201) {
          props.onClose();
          props.reloadTree();
          props.MyAlert.success(response.data.message);
        }
        if (response?.data?.responseCode === 400) {
          formikDetails.setFieldError('instanceName', response.data.message);
        }
      })
      .catch((error) => {
        console.error('API error:', error);
      });
  };

  const updateInstance = async () => {
    const id = props?.seletedInstance?.id;
    const payload = {
      instanceName: formikDetails?.values?.instanceName,
      channelName: formikDetails?.values?.channelName,
      oauthToken: formikDetails?.values?.oauthToken,
      channelId: formikDetails?.values?.channelId,
    };
    updateSlackInstance(payload, id)
      .then((response) => {
        if (response?.data?.responseCode === 201) {
          props.onClose();
          props.reloadTree();
          props.MyAlert.success(response.data.message);
        }
        if (response?.data?.responseCode === 400) {
          formikDetails.setFieldError('instanceName', response.data.message);
        }
      })
      .catch((error) => {
        console.error('API error:', error);
      });
  };

  const currentOauthToken = formikDetails?.values?.oauthToken;
  const exstingOauthToken = props?.seletedInstance?.oauthToken;

  const compare = JSON.stringify(currentOauthToken) === JSON.stringify(exstingOauthToken);
  const [isVerified, setVerified] = useState(props?.actionType === 'Edit' && compare === true ? true : false);

  const handleVerifyClick = () => {
    const channelId = formikDetails.values.channelId;
    if (formikDetails.values.channelId.length !== 0) {
      validateChannelId(oauthToken, channelId).then((response) => {
        if (response?.data?.responseCode === 200) {
          setVerified(true);
          formikDetails.setFieldValue('channelName', response?.data?.responseObject);
        } else {
          setVerified(false);
          formikDetails.setFieldError('channelId', response?.data?.message);
        }
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const currentLength = formikDetails.values.channelId.length;
    formikDetails.handleChange(event);
    if (name === 'channelId' && currentLength > 0 && (value.length < currentLength || value.length > currentLength)) {
      setVerified(false);
    }

    formikDetails.setFieldValue('channelName', '');
  };

  const oldData = {
    instanceName: props.seletedInstance.instanceName,
    oauthToken: props.seletedInstance.oauthToken,
    channelId: props.seletedInstance.channelId,
  };

  const newData = {
    instanceName: formikDetails?.values?.instanceName,
    oauthToken: formikDetails?.values?.oauthToken,
    channelId: formikDetails?.values?.channelId,
  };

  const compareJson = JSON.stringify(oldData) === JSON.stringify(newData);

  function shouldDisableRightButton() {
    const { values } = formikDetails;

    const isInstanceNameInvalid =
      values.instanceName?.length < 3 ||
      values.instanceName?.length >= 25 ||
      values.instanceName?.match(/[!@#$%^&*()+{}\[\]:;<>,.?~\\/-]/) ||
      !values.instanceName.match(/^[^\s].*[^\s]$/);
    const isOauthTokenEmpty = values.oauthToken.length === 0;
    const isInvalidForEdit = props.actionType === 'Edit' && ((showDetails && compareJson) || isVerified === false);
    const isInvalidForAdd = props.actionType !== 'Edit' && showDetails && !isVerified;
    return isInstanceNameInvalid || isOauthTokenEmpty || isInvalidForEdit || isInvalidForAdd;
  }

  return (
    <CommonDrawerJs
      isDrawerOpen={true}
      headerContent={props.actionType === 'Edit' ? `${SLACK_CONSTANTS.EDIT_INSTANCE}` : SLACK_CONSTANTS.CREATE_INSTANCE}
      drawerWidth="450px"
      rightButtonText={
        showDetails
          ? props.actionType === 'Edit'
            ? SLACK_CONSTANTS.UPDATE
            : SLACK_CONSTANTS.CREATE
          : SLACK_CONSTANTS.NEXT
      }
      leftButtonText="Cancel"
      isLeftButtonVisible={true}
      onDrawerOpen={props.onClose}
      onRightButtonClick={() => {
        !showDetails ? validateAuth() : props.actionType === 'Edit' ? updateInstance() : createInstance();
      }}
      onLeftButtonClick={props.onClose}
      disableRightButton={shouldDisableRightButton()}
    >
      <section className="w-full  flex flex-col justify-center items-center overflow-hidden">
        <>
          <div className="w-full flex flex-col mt-2 ml-8">
            <div className="integration-label fontPoppinsMediumSm">
              {
                <>
                  {' '}
                  <span className="text-red-600">*</span> {SLACK_CONSTANTS.INSTANCE_NAME}
                </>
              }
            </div>
            <div className="mt-2">
              <TextField
                className="instance-input-field ml-5"
                variant="outlined"
                name="instanceName"
                style={{ width: '420px' }}
                autoComplete="off"
                placeholder={SLACK_CONSTANTS.ENTER_INSTANCE_NAME}
                onChange={formikDetails.handleChange}
                error={formikDetails.touched.instanceName && formikDetails.errors.instanceName}
                onBlur={formikDetails.handleBlur}
                value={formikDetails.values.instanceName}
              />
            </div>
            {formikDetails.errors.instanceName && formikDetails.touched.instanceName ? (
              <div className="error-message mt-2 fontPoppinsRegularSm ml-1">{formikDetails.errors.instanceName}</div>
            ) : null}
          </div>
          <div className="w-full flex flex-col mt-2 ml-8">
            <div className="integration-label fontPoppinsMediumSm">
              {
                <>
                  {' '}
                  <span className="text-red-600">*</span> {SLACK_CONSTANTS.OAUTH_TOKEN}
                </>
              }
            </div>
            <div className="mt-2">
              <TextField
                label=""
                variant="outlined"
                name="oauthToken"
                className={`instance-input-field ml-5 text-field-Style ${showDetails === true && 'disabled-field'}`}
                autoComplete="off"
                placeholder={SLACK_CONSTANTS.ENTER_OAUTH}
                onChange={formikDetails.handleChange}
                error={
                  (formikDetails.errors.oauthToken && formikDetails.touched.oauthToken) ||
                  (props.actionType === 'Edit' && formikDetails.errors.oauthToken)
                }
                onBlur={formikDetails.handleBlur}
                value={formikDetails.values.oauthToken}
                disabled={showDetails === true}
              />
            </div>
            {(formikDetails.errors.oauthToken && formikDetails.touched.oauthToken) ||
            (props.actionType === 'Edit' && formikDetails.errors.oauthToken) ? (
              <div className="error-message mt-2 fontPoppinsRegularSm ml-1">{formikDetails.errors.oauthToken}</div>
            ) : null}
          </div>
          {showDetails && (
            <>
              <div className="w-full flex flex-col mt-2 ml-8">
                <div className="integration-label fontPoppinsMediumSm">
                  {
                    <>
                      {' '}
                      <span className="text-red-600">*</span> {SLACK_CONSTANTS.CHANEL_ID}
                    </>
                  }
                </div>
                <div className="mt-2">
                  <TextField
                    label=""
                    variant="outlined"
                    name="channelId"
                    className={`instance-input-field ml-5 text-field-Style`}
                    autoComplete="off"
                    placeholder={SLACK_CONSTANTS.ENTER_CHANEL_ID}
                    error={formikDetails.errors.channelId && formikDetails.touched.channelId}
                    onBlur={formikDetails.handleBlur}
                    value={formikDetails.values.channelId}
                    onChange={handleInputChange}
                    onPaste={() => setVerified(false)}
                    InputProps={{
                      endAdornment: (
                        <button
                          variant="outlined"
                          className={` fontPoppinsRegularXs -mr-2 ${isVerified ? 'disable-verify' : 'enable-verify'}`}
                          onClick={handleVerifyClick}
                          disabled={isVerified}
                        >
                          {isVerified ? SLACK_CONSTANTS.VERIFIED : SLACK_CONSTANTS.VERIFY}
                        </button>
                      ),
                    }}
                  />
                </div>
                {formikDetails.errors.channelId && formikDetails.touched.channelId ? (
                  <div className="error-message mt-2 fontPoppinsRegularSm ml-1">{formikDetails.errors.channelId}</div>
                ) : null}
              </div>
              <div className="w-full flex flex-col mt-2 ml-8">
                <div className="integration-label fontPoppinsMediumSm">
                  {
                    <>
                      {' '}
                      <span className="text-red-600">*</span> {SLACK_CONSTANTS.CHANEL_NAME}
                    </>
                  }
                </div>
                <div className="mt-2">
                  <TextField
                    label=""
                    variant="outlined"
                    name="channelName"
                    className={`instance-input-field ml-5 text-field-Style ${'disabled-field'}`}
                    autoComplete="off"
                    onChange={formikDetails.handleChange}
                    error={formikDetails.errors.repositoryName && formikDetails.touched.repositoryName}
                    onBlur={formikDetails.handleBlur}
                    value={formikDetails.values.channelName}
                    disabled={true}
                  />
                </div>
                {formikDetails.errors.fireFlinkProject && formikDetails.touched.fireFlinkProject ? (
                  <div className="error-message mt-2 fontPoppinsRegularSm ml-1">
                    {formikDetails.errors.fireFlinkProject}
                  </div>
                ) : null}
              </div>
            </>
          )}
        </>
      </section>
    </CommonDrawerJs>
  );
};

export default SlackCreateEditInstance;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useAlert } from '../../../common/alert_service/useAlert';
import Breadcrumbs from '../../../common/breadcrumbs';
import { SearchIcon } from '@heroicons/react/solid';
import '../../Result_config/Result.css';
import { getHistoryConfigReq, putHistoryConfigReq } from '../../../../api/api_services';

const HistoryConfig = (props) => {
  const { AlertContatiner, MyAlert } = useAlert();
  let activeTab = props.location.pathname.split('/')[3];

  const [tabsList, setTabsList] = useState([
    {
      name: 'History Configuration',
      pathUrl: `/configuration/Result&HistoryConfiguration/HistoryConfiguration`,
      selectedName: 'HistoryConfiguration',
    },

    {
      name: 'History',
      pathUrl: `/configuration/Result&HistoryConfiguration/HistoryConfiguration/History`,
      selectedName: 'History',
    },
  ]);

  const renderTabs = () => {
    return (
      <div>
        <ul className="navBar text-blue-600 flex flex-row primary-tab">
          {tabsList.map((tab, index) => {
            return (
              <li key={tab.name}>
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    props.history.push(tab.pathUrl);
                  }}
                  className={`${activeTab === tab.selectedName ? 'selected_tab_style' : ''}`}
                >
                  {tab.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };
  const [projectElm, setProjectElm] = useState();
  const [programElm, setProgramElm] = useState();
  const [stepGroupElm, setStepGroupElm] = useState();
  const [testData, setTestData] = useState();
  const [global, setGlobal] = useState();
  const [testScriptValue, setTestScriptValue] = useState();
  const [moduleNo, setModuleNo] = useState();
  const [pageNo, setPageNo] = useState();
  const [packageNo, setPakageNo] = useState();
  const [library, setLibrary] = useState();
  const [folder, setFolder] = useState();
  const [suit, setSuit] = useState();
  const [suitOfSuit, setSuitOfSuit] = useState();
  const [webServiceNo, setWebServiceNo] = useState();
  const [db, setDb] = useState();
  const [historyValue, setHistoryValue] = useState();
  const [userId, setUserId] = useState();
  const [disablebtn, setDisableBtn] = useState(true);
  const [disableCancelbtn, setDisableCancelBtn] = useState(true);
  const [errorPrg, setErrorPrg] = useState('');
  const [errorPctElement, setErrorPctElement] = useState('');
  const [errorSgValue, setErrorSgValue] = useState('');
  const [errorTestData, setErrorTestData] = useState('');
  const [errorGlobalVar, setErrorGlobalVar] = useState('');
  const [errorTestScript, setErrorTestScript] = useState('');
  const [errorModule, setErrorModule] = useState('');
  const [errorPage, setErrorPage] = useState('');
  const [errorPackage, setErrorPackage] = useState('');
  const [errorLibrary, setErrorLibrary] = useState('');
  const [errorFolder, setErrorFolder] = useState('');
  const [errorSuite, setErrorSuite] = useState('');
  const [errorSuiteOfSuite, setErrorSuiteOfSuite] = useState('');
  const [errorWeb, setErrorWeb] = useState('');
  const [errroDatabase, setErrroDatabase] = useState('');
  const [errorHistory, setErrorHistory] = useState('');

  let projectId = JSON.parse(localStorage.getItem('selected-project')).id;
  React.useEffect(() => {
    const search = window.location.search;
    let projectId = new URLSearchParams(search).get('id') || JSON.parse(localStorage.getItem('selected-project'));

    if (new URLSearchParams(search).get('id')) {
      projectId = new URLSearchParams(search).get('id');
    } else {
      projectId = JSON.parse(localStorage.getItem('selected-project')).id;
    }

    if (projectId) {
      getHistoryConfigReq(projectId).then((results) => {
        localStorage.setItem('resultGetReq', false);
        if (results && results.data && results.data.responseObject) {
          setProgramElm(results.data.responseObject.noOfProgramElements);
          setProjectElm(results.data.responseObject.noOfProjectElements);
          setStepGroupElm(results.data.responseObject.noOfStepGroups);
          setTestData(results.data.responseObject.noOfTestData);
          setGlobal(results.data.responseObject.noOfGlobalVariables);
          setTestScriptValue(results.data.responseObject.noOfTestScripts);
          setModuleNo(results.data.responseObject.noOfModules);
          setPageNo(results.data.responseObject.noOfPages);
          setPakageNo(results.data.responseObject.noOfPackages);
          setLibrary(results.data.responseObject.noOfLibraries);
          setFolder(results.data.responseObject.noOfFolders);
          setSuit(results.data.responseObject.noOfSuites);
          setSuitOfSuit(results.data.responseObject.noOfSuiteOfSuites);
          setWebServiceNo(results.data.responseObject.noOfWebServices);
          setDb(results.data.responseObject.noOfDataBases);
          setHistoryValue(results.data.responseObject.noOfHistory);
          setUserId(results.data.responseObject.id);
        }
      });
    } else {
      localStorage.setItem('resultGetReq', true);
      getHistoryConfigReq(projectId).then((results) => {
        localStorage.setItem('resultGetReq', false);
        if (results && results.data && results.data.responseObject) {
          setProgramElm(results.data.responseObject.noOfProgramElements);
          setProjectElm(results.data.responseObject.noOfProjectElements);
          setStepGroupElm(results.data.responseObject.noOfStepGroups);
          setTestData(results.data.responseObject.noOfTestData);
          setGlobal(results.data.responseObject.noOfGlobalVariables);
          setTestScriptValue(results.data.responseObject.noOfTestScripts);
          setModuleNo(results.data.responseObject.noOfModules);
          setPageNo(results.data.responseObject.noOfPages);
          setPakageNo(results.data.responseObject.noOfPackages);
          setLibrary(results.data.responseObject.noOfLibraries);
          setFolder(results.data.responseObject.noOfFolders);
          setSuit(results.data.responseObject.noOfSuites);
          setSuitOfSuit(results.data.responseObject.noOfSuiteOfSuites);
          setWebServiceNo(results.data.responseObject.noOfWebServices);
          setDb(results.data.responseObject.noOfDataBases);
          setHistoryValue(results.data.responseObject.noOfHistory);
          setUserId(results.data.responseObject.id);
        }
      });
    }
  }, [projectId]);
  {
    /*----------------------------Program Elm functions------------------------------- */
  }
  // increase program element
  const incprgElmNo = () => {
    setProgramElm(+programElm + 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let prgElem = document.querySelector('.prgElem').value;
    console.log('rahul', prgElem);
    if (prgElem >= 50) {
      setProgramElm(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorPrg('Number of results entries should be in between 5 to 50');
    } else if (prgElem <= 3) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorPrg('Number of results entries should be in between 5 to 50');
    } else {
      setErrorPrg('');
    }
  };
  // decrease program Element
  const decrPrgElmNo = () => {
    setProgramElm(programElm - 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let prgElem = document.querySelector('.prgElem').value;
    if (prgElem <= 5) {
      setProgramElm(5);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorPrg('Number of results entries should be in between 5 to 50');
    } else if (prgElem >= 52) {
      setProgramElm(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorPrg('Number of results entries should be between in 5 to 50');
    } else {
      setErrorPrg('');
    }
  };
  // onChange prgElement
  const PrgElmHandelChange = (e) => {
    setProgramElm(e.target.value);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let prgElem = document.querySelector('.prgElem').value;
    var regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;
    const value = e.target.value.replace(/\D/g, '');
    setProgramElm(value);
    if (regex_symbols.test(prgElem)) {
      setProgramElm(programElm);
    }

    if (prgElem > 50) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorPrg('Number of results entries should be in between 5 to 50');
    } else if (prgElem <= 4) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorPrg('Number of results entries should be in between 5 to 50');
    } else {
      setErrorPrg('');
    }
  };
  {
    /*-------------------End Program Elm functions------------------------- */
  }

  {
    /*----------------------------Project Elm functions------------------------------- */
  }
  // increase Project element
  const incProjectElmNo = () => {
    setProjectElm(+projectElm + 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let projectElement = document.querySelector('.projectElement').value;
    if (projectElement >= 50) {
      setProjectElm(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorPctElement('Number of results entries should be in between 5 to 50');
    } else if (projectElement <= 3) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorPctElement('Number of results entries should be in between 5 to 50');
    } else {
      setErrorPctElement('');
    }
  };
  // decrease Project Element
  const decrProlectElmNo = () => {
    setProjectElm(projectElm - 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let projectElement = document.querySelector('.projectElement').value;
    if (projectElement <= 5) {
      setProjectElm(5);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorPctElement('Number of results entries should be in between 5 to 50');
    } else if (projectElement >= 52) {
      setProjectElm(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorPctElement('Number of results entries should be in between 5 to 50');
    } else {
      setErrorPctElement('');
    }
  };
  // onChange ProjectElm
  const prolectElmHandelChange = (e) => {
    setProjectElm(e.target.value);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let projectElement = document.querySelector('.projectElement').value;
    var regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;
    const value = e.target.value.replace(/\D/g, '');
    setProjectElm(value);
    if (regex_symbols.test(projectElement)) {
      setProjectElm(projectElm);
    }

    if (projectElement > 50) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorPctElement('Number of results entries should be in between 5 to 50');
    } else if (projectElement <= 4) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorPctElement('Number of results entries should be in between 5 to 50');
    } else {
      setErrorPctElement('');
    }
  };
  {
    /*-------------------End Project Elm functions------------------------- */
  }

  {
    /*----------------------------StepGroup Elm functions------------------------------- */
  }
  // increase StepGroup element
  const incStepGroupNo = () => {
    setStepGroupElm(+stepGroupElm + 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let sgValue = document.querySelector('.sgValue').value;
    if (sgValue >= 50) {
      setStepGroupElm(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorSgValue('Number of results entries should be in between 5 to 50');
    } else if (sgValue <= 3) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorSgValue('Number of results entries should be in between 5 to 50');
    } else {
      setErrorSgValue('');
    }
  };
  // decrease StepGroup Element
  const decrStepGroupNo = () => {
    setDisableBtn(false);
    setDisableCancelBtn(false);
    setStepGroupElm(stepGroupElm - 1);

    let sgValue = document.querySelector('.sgValue').value;
    if (sgValue <= 5) {
      setStepGroupElm(5);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorSgValue('Number of results entries should be in between 5 to 50');
    } else if (sgValue >= 52) {
      setStepGroupElm(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorSgValue('Number of results entries should be in between 5 to 50');
    } else {
      setErrorSgValue('');
    }
  };
  // onChange StepGroupElm
  const StepGroupHandelChange = (e) => {
    setStepGroupElm(e.target.value);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let sgValue = document.querySelector('.sgValue').value;
    var regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;
    const value = e.target.value.replace(/\D/g, '');
    setStepGroupElm(value);
    if (regex_symbols.test(sgValue)) {
      setStepGroupElm(stepGroupElm);
    }
    if (sgValue > 50) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorSgValue('Number of results entries should be in between 5 to 50');
    } else if (sgValue <= 4) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorSgValue('Number of results entries should be in between 5 to 50');
    } else {
      setErrorSgValue('');
    }
  };
  {
    /*-------------------End StepGroup Elm functions------------------------- */
  }

  {
    /*----------------------------TestData Elm functions------------------------------- */
  }
  // increase TestData element
  const incTestDataNo = () => {
    setTestData(+testData + 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let testDataVal = document.querySelector('.testDataVal').value;
    if (testDataVal >= 50) {
      setTestData(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorTestData('Number of results entries should be in between 5 to 50');
    } else if (testDataVal <= 3) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorTestData('Number of results entries should be in between 5 to 50');
    } else {
      setErrorTestData('');
    }
  };
  // decrease TestData Element
  const decrTestDataNo = () => {
    setTestData(testData - 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let testDataVal = document.querySelector('.testDataVal').value;
    if (testDataVal <= 5) {
      setTestData(5);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorTestData('Number of results entries should be in between 5 to 50');
    } else if (testDataVal >= 52) {
      setTestData(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorTestData('Number of results entries should be in between 5 to 50');
    } else {
      setErrorTestData('');
    }
  };
  // onChange TestData
  const TestDataHandelChange = (e) => {
    setTestData(e.target.value);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let testDataVal = document.querySelector('.testDataVal').value;
    var regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;
    const value = e.target.value.replace(/\D/g, '');
    setTestData(value);
    if (regex_symbols.test(testDataVal)) {
      setTestData(testData);
    }

    if (testDataVal > 50) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorTestData('Number of results entries should be in between 5 to 50');
    } else if (testDataVal <= 4) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorTestData('Number of results entries should be in between 5 to 50');
    } else {
      setErrorTestData('');
    }
  };
  {
    /*-------------------End TestData functions------------------------- */
  }

  {
    /*----------------------------globalVar Elm functions------------------------------- */
  }
  // increase globalVar element
  const incglobalVarNo = () => {
    setGlobal(+global + 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let globalVar = document.querySelector('.globalVar').value;
    if (globalVar >= 50) {
      setGlobal(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorGlobalVar('Number of results entries should be in between 5 to 50');
    } else if (globalVar <= 3) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorGlobalVar('Number of results entries should be in between 5 to 50');
    } else {
      setErrorGlobalVar('');
    }
  };
  // decrease globalVar Element
  const decrglobalVarNo = () => {
    setGlobal(global - 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let globalVar = document.querySelector('.globalVar').value;
    if (globalVar <= 5) {
      setGlobal(5);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorGlobalVar('Number of results entries should be in between 5 to 50');
    } else if (globalVar >= 52) {
      setGlobal(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorGlobalVar('Number of results entries should be in between 5 to 50');
    } else {
      setErrorGlobalVar('');
    }
  };
  // onChange globalVar
  const globalVarHandelChange = (e) => {
    setGlobal(e.target.value);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let globalVar = document.querySelector('.globalVar').value;
    var regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;
    const value = e.target.value.replace(/\D/g, '');
    setGlobal(value);
    if (regex_symbols.test(globalVar)) {
      setGlobal(global);
    }

    if (globalVar > 50) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorGlobalVar('Number of results entries should be in between 5 to 50');
    } else if (globalVar <= 4) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorGlobalVar('Number of results entries should be in between 5 to 50');
    } else {
      setErrorGlobalVar('');
    }
  };
  {
    /*-------------------End globalVar functions------------------------- */
  }

  {
    /*----------------------------testScript Elm functions------------------------------- */
  }
  // increase testScript element
  const inctestScriptNo = () => {
    setTestScriptValue(+testScriptValue + 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let testScript = document.querySelector('.testScript').value;
    if (testScript >= 50) {
      setTestScriptValue(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorTestScript('Number of results entries should be in between 5 to 50');
    } else if (testScript <= 3) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorTestScript('Number of results entries should be in between 5 to 50');
    } else {
      setErrorTestScript('');
    }
  };
  // decrease testScript Element
  const decrtestScriptNo = () => {
    setTestScriptValue(testScriptValue - 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let testScript = document.querySelector('.testScript').value;
    if (testScript <= 5) {
      setTestScriptValue(5);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorTestScript('Number of results entries should be in between 5 to 50');
    } else if (testScript >= 52) {
      setTestScriptValue(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorTestScript('Number of results entries should be in between 5 to 50');
    } else {
      setErrorTestScript('');
    }
  };
  // onChange testScript
  const testScriptHandelChange = (e) => {
    setTestScriptValue(e.target.value);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let testScript = document.querySelector('.testScript').value;
    var regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;
    const value = e.target.value.replace(/\D/g, '');
    setTestScriptValue(value);
    if (regex_symbols.test(testScript)) {
      setTestScriptValue(testScriptValue);
    }

    if (testScript > 50) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorTestScript('Number of results entries should be in between 5 to 50');
    } else if (testScript <= 4) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorTestScript('Number of results entries should be in between 5 to 50');
    } else {
      setErrorTestScript('');
    }
  };
  {
    /*-------------------End testScript functions------------------------- */
  }

  {
    /*----------------------------module Elm functions------------------------------- */
  }
  // increase module element
  const incModuleNo = () => {
    setModuleNo(+moduleNo + 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let module = document.querySelector('.module').value;
    if (module >= 50) {
      setModuleNo(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorModule('Number of results entries should be in between 5 to 50');
    } else if (module <= 3) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorModule('Number of results entries should be in between 5 to 50');
    } else {
      setErrorModule('');
    }
  };
  // decrease module Element
  const decrModuleNo = () => {
    setModuleNo(moduleNo - 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let module = document.querySelector('.module').value;
    if (module <= 5) {
      setModuleNo(5);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorModule('Number of results entries should be in between 5 to 50');
    } else if (module >= 52) {
      setModuleNo(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorModule('Number of results entries should be in between 5 to 50');
    } else {
      setErrorModule('');
    }
  };
  // onChange module
  const moduleHandelChange = (e) => {
    setModuleNo(e.target.value);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let module = document.querySelector('.module').value;
    var regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;
    const value = e.target.value.replace(/\D/g, '');
    setModuleNo(value);
    if (regex_symbols.test(module)) {
      setModuleNo(moduleNo);
    }

    if (module > 50) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorModule('Number of results entries should be in between 5 to 50');
    } else if (module <= 4) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorModule('Number of results entries should be in between 5 to 50');
    } else {
      setErrorModule('');
    }
  };
  {
    /*-------------------End module functions------------------------- */
  }

  {
    /*----------------------------page Elm functions------------------------------- */
  }
  // increase page element
  const incPageNo = () => {
    setPageNo(+pageNo + 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let page = document.querySelector('.pages').value;
    if (page >= 50) {
      setPageNo(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorPage('Number of results entries should be in between 5 to 50');
    } else if (page <= 3) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorPage('Number of results entries should be in between 5 to 50');
    } else {
      setErrorPage('');
    }
  };
  // decrease page Element
  const decrPageNo = () => {
    setPageNo(pageNo - 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let page = document.querySelector('.pages').value;
    if (page <= 5) {
      setPageNo(5);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorPage('Number of results entries should be in between 5 to 50');
    } else if (page >= 52) {
      setPageNo(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorPage('Number of results entries should be in between 5 to 50');
    } else {
      setErrorPage('');
    }
  };
  // onChange page
  const pageHandelChange = (e) => {
    setPageNo(e.target.value);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let page = document.querySelector('.pages').value;
    var regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;
    const value = e.target.value.replace(/\D/g, '');
    setPageNo(value);
    if (regex_symbols.test(page)) {
      setPageNo(pageNo);
    }

    if (page > 50) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorPage('Number of results entries should be in between 5 to 50');
    } else if (page <= 4) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorPage('Number of results entries should be in between 5 to 50');
    } else {
      setErrorPage('');
    }
  };
  {
    /*-------------------End page functions------------------------- */
  }

  {
    /*----------------------------pakage Elm functions------------------------------- */
  }
  // increase pakage element
  const incPakageNo = () => {
    setPakageNo(+packageNo + 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let pakage = document.querySelector('.pakage').value;
    if (pakage >= 50) {
      setPakageNo(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorPackage('Number of results entries should be in between 5 to 50');
    } else if (pakage <= 3) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorPackage('Number of results entries should be in between 5 to 50');
    } else {
      setErrorPackage('');
    }
  };
  // decrease pakage Element
  const decrPakageNo = () => {
    setPakageNo(packageNo - 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);

    let pakage = document.querySelector('.pakage').value;
    if (pakage <= 5) {
      setPakageNo(5);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorPackage('Number of results entries should be in between 5 to 50');
    } else if (pakage >= 52) {
      setPakageNo(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorPackage('Number of results entries should be in between 5 to 50');
    } else {
      setErrorPackage('');
    }
  };
  // onChange pakage
  const pakageHandelChange = (e) => {
    setPakageNo(e.target.value);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let pakage = document.querySelector('.pakage').value;
    var regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;
    const value = e.target.value.replace(/\D/g, '');
    setPakageNo(value);
    if (regex_symbols.test(pakage)) {
      setPakageNo(packageNo);
    }

    if (pakage > 50) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorPackage('Number of results entries should be in between 5 to 50');
    } else if (pakage <= 4) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorPackage('Number of results entries should be in between 5 to 50');
    } else {
      setErrorPackage('');
    }
  };
  {
    /*-------------------End pakage functions------------------------- */
  }

  {
    /*----------------------------library Elm functions------------------------------- */
  }
  // increase library element
  const incLibraryNo = () => {
    setLibrary(+library + 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);

    let libraryVal = document.querySelector('.librarys').value;
    if (libraryVal >= 50) {
      setLibrary(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorLibrary('Number of results entries should be in between 5 to 50');
    } else if (libraryVal <= 3) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorLibrary('Number of results entries should be in between 5 to 50');
    } else {
      setErrorLibrary('');
    }
  };
  // decrease library Element
  const decrLibraryNo = () => {
    setLibrary(library - 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);

    let libraryVal = document.querySelector('.librarys').value;
    if (libraryVal <= 5) {
      setLibrary(5);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorLibrary('Number of results entries should be in between 5 to 50');
    } else if (libraryVal >= 52) {
      setLibrary(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorLibrary('Number of results entries should be in between 5 to 50');
    } else {
      setErrorLibrary('');
    }
  };
  // onChange library
  const libraryHandelChange = (e) => {
    setLibrary(e.target.value);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let libraryVal = document.querySelector('.librarys').value;
    var regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;
    const value = e.target.value.replace(/\D/g, '');
    setLibrary(value);
    if (regex_symbols.test(libraryVal)) {
      setLibrary(library);
    }

    if (libraryVal > 50) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorLibrary('Number of results entries should be in between 5 to 50');
    } else if (libraryVal <= 4) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorLibrary('Number of results entries should be in between 5 to 50');
    } else {
      setErrorLibrary('');
    }
  };
  {
    /*-------------------End library functions------------------------- */
  }

  {
    /*----------------------------folder Elm functions------------------------------- */
  }
  // increase folder element
  const incFolderNo = () => {
    setFolder(+folder + 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let folderVal = document.querySelector('.folders').value;
    if (folderVal >= 50) {
      setFolder(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorFolder('Number of results entries should be in between 5 to 50');
    } else if (folderVal <= 3) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorFolder('Number of results entries should be in between 5 to 50');
    } else {
      setErrorFolder('');
    }
  };
  // decrease folder Element
  const decrFolderNo = () => {
    setFolder(folder - 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let folderVal = document.querySelector('.folders').value;
    if (folderVal <= 5) {
      setFolder(5);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorFolder('Number of results entries should be in between 5 to 50');
    } else if (folderVal >= 52) {
      setFolder(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorFolder('Number of results entries should be in between 5 to 50');
    } else {
      setErrorFolder('');
    }
  };
  // onChange folder
  const folderHandelChange = (e) => {
    setFolder(e.target.value);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let folderVal = document.querySelector('.folders').value;
    var regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;
    const value = e.target.value.replace(/\D/g, '');
    setFolder(value);
    if (regex_symbols.test(folderVal)) {
      setFolder(folder);
    }

    if (folderVal > 50) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorFolder('Number of results entries should be in between 5 to 50');
    } else if (folderVal <= 4) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorFolder('Number of results entries should be in between 5 to 50');
    } else {
      setErrorFolder('');
    }
  };
  {
    /*-------------------End folder functions------------------------- */
  }

  {
    /*----------------------------suitData Elm functions------------------------------- */
  }
  // increase suitData element
  const incSuitNo = () => {
    setSuit(+suit + 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let suitData = document.querySelector('.suitData').value;
    if (suitData >= 50) {
      setSuit(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorSuite('Number of results entries should be in between 5 to 50');
    } else if (suitData <= 3) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorSuite('Number of results entries should be in between 5 to 50');
    } else {
      setErrorSuite('');
    }
  };
  // decrease suitData Element
  const decrSuitNo = () => {
    setSuit(suit - 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let suitData = document.querySelector('.suitData').value;
    if (suitData <= 5) {
      setSuit(5);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorSuite('Number of results entries should be in between 5 to 50');
    } else if (suitData >= 52) {
      setSuit(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorSuite('Number of results entries should be in between 5 to 50');
    } else {
      setErrorSuite('');
    }
  };
  // onChange suitData
  const suitHandelChange = (e) => {
    setSuit(e.target.value);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let suitData = document.querySelector('.suitData').value;
    var regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;
    const value = e.target.value.replace(/\D/g, '');
    setSuit(value);
    if (regex_symbols.test(suitData)) {
      setSuit(suit);
    }

    if (suitData > 50) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorSuite('Number of results entries should be in between 5 to 50');
    } else if (suitData <= 4) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorSuite('Number of results entries should be in between 5 to 50');
    } else {
      setErrorSuite('');
    }
  };
  {
    /*-------------------End suitData functions------------------------- */
  }

  {
    /*----------------------------suitOfsuitData Elm functions---------------------- */
  }
  // increase suitOfsuitData element
  const incSuitofSuit = () => {
    setSuitOfSuit(+suitOfSuit + 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);

    let suitOfsuitData = document.querySelector('.suitOfsuitData').value;
    if (suitOfsuitData >= 50) {
      setSuitOfSuit(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorSuiteOfSuite('Number of results entries should be in between 5 to 50');
    } else if (suitOfsuitData <= 3) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorSuiteOfSuite('Number of results entries should be in between 5 to 50');
    } else {
      setErrorSuiteOfSuite('');
    }
  };
  // decrease suitOfsuitData Element
  const decrSuitOfsuitNo = () => {
    setSuitOfSuit(suitOfSuit - 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let suitOfsuitData = document.querySelector('.suitOfsuitData').value;
    if (suitOfsuitData <= 5) {
      setSuitOfSuit(5);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorSuiteOfSuite('Number of results entries should be in between 5 to 50');
    } else if (suitOfsuitData >= 52) {
      setSuitOfSuit(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorSuiteOfSuite('Number of results entries should be in between 5 to 50');
    } else {
      setErrorSuiteOfSuite('');
    }
  };
  // onChange suitOfsuitData
  const suitOfsuitHandelChange = (e) => {
    setSuitOfSuit(e.target.value);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let suitOfsuitData = document.querySelector('.suitOfsuitData').value;
    var regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;
    const value = e.target.value.replace(/\D/g, '');
    setSuitOfSuit(value);
    if (regex_symbols.test(suitOfsuitData)) {
      setSuitOfSuit(suitOfSuit);
    }

    if (suitOfsuitData > 50) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorSuiteOfSuite('Number of results entries should be in between 5 to 50');
    } else if (suitOfsuitData <= 4) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorSuiteOfSuite('Number of results entries should be in between 5 to 50');
    } else {
      setErrorSuiteOfSuite('');
    }
  };
  {
    /*-------------------End suitOfsuitData functions------------------------- */
  }

  {
    /*----------------------------webServ Elm functions---------------------- */
  }
  // increase webServ element
  const incwebServ = () => {
    setWebServiceNo(+webServiceNo + 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let webServ = document.querySelector('.webServ').value;
    if (webServ >= 50) {
      setWebServiceNo(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorWeb('Number of results entries should be in between 5 to 50');
    } else if (webServ <= 3) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorWeb('Number of results entries should be in between 5 to 50');
    } else {
      setErrorWeb('');
    }
  };
  // decrease webServ Element
  const decrWebServ = () => {
    setWebServiceNo(webServiceNo - 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let webServ = document.querySelector('.webServ').value;
    if (webServ <= 5) {
      setWebServiceNo(5);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorWeb('Number of results entries should be in between 5 to 50');
    } else if (webServ >= 52) {
      setWebServiceNo(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorWeb('Number of results entries should be in between 5 to 50');
    } else {
      setErrorWeb('');
    }
  };
  // onChange webServ
  const webServHandelChange = (e) => {
    setWebServiceNo(e.target.value);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let webServ = document.querySelector('.webServ').value;
    var regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;
    const value = e.target.value.replace(/\D/g, '');
    setWebServiceNo(value);
    if (regex_symbols.test(webServ)) {
      setWebServiceNo(webServiceNo);
    }

    if (webServ > 50) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorWeb('Number of results entries should be in between 5 to 50');
    } else if (webServ <= 4) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorWeb('Number of results entries should be in between 5 to 50');
    } else {
      setErrorWeb('');
    }
  };
  {
    /*-------------------End webServ functions------------------------- */
  }

  {
    /*----------------------------database Elm functions---------------------- */
  }
  // increase database element
  const incdatabase = () => {
    setDb(+db + 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let database = document.querySelector('.database').value;
    if (database >= 50) {
      setDb(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrroDatabase('Number of results entries should be in between 5 to 50');
    } else if (database <= 3) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrroDatabase('Number of results entries should be in between 5 to 50');
    } else {
      setErrroDatabase('');
    }
  };
  // decrease database Element
  const decrDatabase = () => {
    setDb(db - 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);

    let database = document.querySelector('.database').value;
    if (database <= 5) {
      setDb(5);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrroDatabase('Number of results entries should be in between 5 to 50');
    } else if (database >= 52) {
      setDb(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrroDatabase('Number of results entries should be in between 5 to 50');
    } else {
      setErrroDatabase('');
    }
  };
  // onChange database
  const databaseHandelChange = (e) => {
    setDb(e.target.value);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let database = document.querySelector('.database').value;
    var regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;
    const value = e.target.value.replace(/\D/g, '');
    setDb(value);
    if (regex_symbols.test(database)) {
      setDb(db);
    }

    if (database > 50) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrroDatabase('Number of results entries should be in between 5 to 50');
    } else if (database <= 4) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrroDatabase('Number of results entries should be in between 5 to 50');
    } else {
      setErrroDatabase('');
    }
  };
  {
    /*-------------------End database functions------------------------- */
  }

  {
    /*----------------------------History Elm functions---------------------- */
  }
  // increase History element
  const incHistory = () => {
    setHistoryValue(+historyValue + 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let historyData = document.querySelector('.historyData').value;
    if (historyData >= 50) {
      setHistoryValue(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorHistory('Number of results entries should be in between 5 to 50');
    } else if (historyData <= 3) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorHistory('Number of results entries should be in between 5 to 50');
    } else {
      setErrorHistory('');
    }
  };
  // decrease History Element
  const decrHistory = () => {
    setHistoryValue(historyValue - 1);
    setDisableBtn(false);
    setDisableCancelBtn(false);

    let historyData = document.querySelector('.historyData').value;
    if (historyData <= 5) {
      setHistoryValue(5);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorHistory('Number of results entries should be in between 5 to 50');
    } else if (historyData >= 52) {
      setHistoryValue(50);
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorHistory('Number of results entries should be in between 5 to 50');
    } else {
      setErrorHistory('');
    }
  };
  // onChange History
  const historyHandelChange = (e) => {
    setHistoryValue(e.target.value);
    setDisableBtn(false);
    setDisableCancelBtn(false);
    let historyData = document.querySelector('.historyData').value;
    var regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;
    const value = e.target.value.replace(/\D/g, '');
    setHistoryValue(value);
    if (regex_symbols.test(historyData)) {
      setHistoryValue(historyValue);
    }

    if (historyData > 50) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorHistory('Number of results entries should be in between 5 to 50');
    } else if (historyData <= 4) {
      setDisableBtn(true);
      setDisableCancelBtn(true);
      setErrorHistory('Number of results entries should be in between 5 to 50');
    } else {
      setErrorHistory('');
    }
  };
  {
    /*-------------------End History functions------------------------- */
  }

  const cancelValue = () => {
    setDisableBtn(true);
    setDisableCancelBtn(true);
    let projectId = JSON.parse(localStorage.getItem('selected-project')).id;
    if (projectId) {
      getHistoryConfigReq(projectId).then((results) => {
        localStorage.setItem('resultGetReq', false);
        if (results && results.data && results.data.responseObject) {
          setProgramElm(results.data.responseObject.noOfProgramElements);
          setProjectElm(results.data.responseObject.noOfProjectElements);
          setStepGroupElm(results.data.responseObject.noOfStepGroups);
          setTestData(results.data.responseObject.noOfTestData);
          setGlobal(results.data.responseObject.noOfGlobalVariables);
          setTestScriptValue(results.data.responseObject.noOfTestScripts);
          setModuleNo(results.data.responseObject.noOfModules);
          setPageNo(results.data.responseObject.noOfPages);
          setPakageNo(results.data.responseObject.noOfPackages);
          setLibrary(results.data.responseObject.noOfLibraries);
          setFolder(results.data.responseObject.noOfFolders);
          setSuit(results.data.responseObject.noOfSuites);
          setSuitOfSuit(results.data.responseObject.noOfSuiteOfSuites);
          setWebServiceNo(results.data.responseObject.noOfWebServices);
          setDb(results.data.responseObject.noOfDataBases);
          setHistoryValue(results.data.responseObject.noOfHistory);
          setUserId(results.data.responseObject.id);
        }
      });
    } else {
      localStorage.setItem('resultGetReq', true);
      getHistoryConfigReq(projectId).then((results) => {
        localStorage.setItem('resultGetReq', false);
        if (results && results.data && results.data.responseObject) {
          setProgramElm(results.data.responseObject.noOfProgramElements);
          setProjectElm(results.data.responseObject.noOfProjectElements);
          setStepGroupElm(results.data.responseObject.noOfStepGroups);
          setTestData(results.data.responseObject.noOfTestData);
          setGlobal(results.data.responseObject.noOfGlobalVariables);
          setTestScriptValue(results.data.responseObject.noOfTestScripts);
          setModuleNo(results.data.responseObject.noOfModules);
          setPageNo(results.data.responseObject.noOfPages);
          setPakageNo(results.data.responseObject.noOfPackages);
          setLibrary(results.data.responseObject.noOfLibraries);
          setFolder(results.data.responseObject.noOfFolders);
          setSuit(results.data.responseObject.noOfSuites);
          setSuitOfSuit(results.data.responseObject.noOfSuiteOfSuites);
          setWebServiceNo(results.data.responseObject.noOfWebServices);
          setDb(results.data.responseObject.noOfDataBases);
          setHistoryValue(results.data.responseObject.noOfHistory);
          setUserId(results.data.responseObject.id);
        }
      });
    }
  };

  const updateVal = (e) => {
    setDisableBtn(true);
    setDisableCancelBtn(true);
    localStorage.setItem('resultPutReq', true);
    let data = {
      noOfProjectElements: projectElm,
      noOfProgramElements: programElm,
      noOfStepGroups: stepGroupElm,
      noOfTestData: testData,
      noOfGlobalVariables: global,
      noOfTestScripts: testScriptValue,
      noOfModules: moduleNo,
      noOfPages: pageNo,
      noOfPackages: packageNo,
      noOfLibraries: library,
      noOfFolders: folder,
      noOfSuites: suit,
      noOfSuiteOfSuites: suitOfSuit,
      noOfWebServices: webServiceNo,
      noOfDataBases: db,
      noOfHistory: historyValue,

      id: userId,
    };
    console.log(data);
    putHistoryConfigReq(userId, data)
      .then((results) => {
        localStorage.setItem('resultPutReq', false);
        if (results.data.responseCode === 200) {
          MyAlert.success(`History configuration updated successfully`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    let projectId = JSON.parse(localStorage.getItem('selected-project')).id;
    if (projectId) {
      putHistoryConfigReq(userId, data, projectId)
        .then((results) => {
          localStorage.setItem('resultPutReq', false);
          if (results.data.responseCode === 200) {
            MyAlert.success(`History configuration updated successfully`);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const isEditable = React.useMemo(() => window.permission?.isEditAccess('configuration'), []);
  const handleNumberValueChange = React.useCallback(
    (changeHandlerFunction) => (isEditable ? changeHandlerFunction : () => {}),
    [isEditable]
  );

  return (
    <div className="page-container">
      <div className="page-list-body">
        <div className="alert-position-style">
          <AlertContatiner></AlertContatiner>
        </div>
        {renderTabs()}

        <div className="page-table-body">
          <div className="configPage pr-0 content_area_style" id="journal-scroll">
            <div className="grid grid-cols-2 mb-4 ">
              <span className=" ml-3 mt-4 configPageHeading">History Configuration</span>
              {isEditable && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginRight: '15px',
                  }}
                >
                  <button
                    onClick={cancelValue}
                    disabled={disableCancelbtn}
                    className={
                      disableCancelbtn
                        ? 'fontPoppinsMediumMd mt-4 w-16 ml-10  h-8 mr-6  items-center float-right border-2 border-gray-200 opacity-1 rounded'
                        : 'fontPoppinsMediumMd mt-4 w-16 ml-10  h-8 mr-6  bg-white  text-sm  text-gray-800  border border-gray-400 opacity-100 rounded'
                    }
                  >
                    <span className={disableCancelbtn ? 'text-sm text-gray-400 ' : 'text-sm text-gray-700 '}>
                      Cancel
                    </span>
                  </button>

                  <button
                    disabled={
                      disablebtn ||
                      errorPrg ||
                      errorPctElement ||
                      errorSgValue ||
                      errorTestData ||
                      errorGlobalVar ||
                      errorTestScript ||
                      errorModule ||
                      errorPage ||
                      errorPackage ||
                      errorLibrary ||
                      errorFolder ||
                      errorSuite ||
                      errorSuiteOfSuite ||
                      errorWeb ||
                      errroDatabase ||
                      errorHistory
                    }
                    onClick={updateVal}
                    className={
                      disablebtn
                        ? 'primary-btn opacity-1 mt-4 w-16 h-8 rounded items-center float-right'
                        : 'primary-btn rounded  mt-4 w-16 h-8  items-center float-right opacity-100'
                    }
                  >
                    <span>Save</span>
                  </button>
                </div>
              )}
            </div>
            <div
              className=" grid grid-cols-2 "
              style={{
                padding: '0.8rem',
                background: 'rgb(240,243,249)',
                display: 'flex',
              }}
            >
              <div>
                <span className="tHeading ml-8">History of</span>
                <b className="tHeading ml-44">No of recent entries in History &nbsp; &nbsp; &nbsp; </b>
              </div>
            </div>

            {/*----------Program Elements Elements------ */}
            <div className="flex grid grid-cols-4">
              <label className="headingData">
                <span style={{ color: 'red' }}>* &nbsp;</span> Program Elements
              </label>
              <div style={{ marginLeft: '-0.8rem' }}>
                <form
                  className="flex w-96"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div>
                    <input
                      disabled={!isEditable}
                      value={programElm}
                      onChange={PrgElmHandelChange}
                      type="text"
                      className="prgElem"
                      style={{
                        background: 'rgb(251,252,253)',
                        width: '5rem',

                        height: '30px',
                        textAlign: 'right',
                      }}
                    ></input>
                    <>
                      <ArrowDropUpIcon
                        className="arrowUp"
                        onClick={handleNumberValueChange(incprgElmNo)}
                        disabled={true}
                      />

                      <ArrowDropDownIcon onClick={handleNumberValueChange(decrPrgElmNo)} className="arrowDown" />
                    </>

                    {errorPrg && <p className="text-sm text-red-500">{errorPrg}</p>}
                  </div>
                </form>
              </div>
            </div>

            {/*----------Project Elements------ */}
            <div className="flex grid grid-cols-4">
              <label className="headingData">
                <span style={{ color: 'red' }}>* &nbsp;</span> Project Elements
              </label>
              <div style={{ marginLeft: '-0.8rem' }}>
                <form
                  className="flex w-96"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div>
                    <input
                      disabled={!isEditable}
                      value={projectElm}
                      onChange={prolectElmHandelChange}
                      type="text"
                      className="projectElement"
                      style={{
                        background: 'rgb(251,252,253)',
                        width: '5rem',

                        height: '30px',
                        textAlign: 'right',
                      }}
                    ></input>
                    <>
                      <ArrowDropUpIcon
                        className="arrowUp"
                        onClick={handleNumberValueChange(incProjectElmNo)}
                        disabled={true}
                      />

                      <ArrowDropDownIcon onClick={handleNumberValueChange(decrProlectElmNo)} className="arrowDown" />
                    </>
                    {errorPctElement && <p className="projectElementErr text-sm text-red-500">{errorPctElement}</p>}
                  </div>
                </form>
              </div>
            </div>
            {/*----------Step Group Elements------ */}
            <div className="flex grid grid-cols-4">
              <label className="headingData">
                <span style={{ color: 'red' }}>* &nbsp;</span> Step Group Elements
              </label>
              <div style={{ marginLeft: '-0.8rem' }}>
                <form
                  className="flex w-96"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div>
                    <input
                      disabled={!isEditable}
                      value={stepGroupElm}
                      onChange={StepGroupHandelChange}
                      type="text"
                      className="sgValue"
                      style={{
                        background: 'rgb(251,252,253)',
                        width: '5rem',

                        height: '30px',
                        textAlign: 'right',
                      }}
                    ></input>
                    <>
                      <ArrowDropUpIcon
                        className="arrowUp"
                        onClick={handleNumberValueChange(incStepGroupNo)}
                        disabled={true}
                      />

                      <ArrowDropDownIcon onClick={handleNumberValueChange(decrStepGroupNo)} className="arrowDown" />
                    </>
                    {errorSgValue && <p className="sgValueErr text-sm text-red-500">{errorSgValue}</p>}
                  </div>
                </form>
              </div>
            </div>

            {/*----------Test Data File------ */}
            <div className="flex grid grid-cols-4">
              <label className="headingData">
                <span style={{ color: 'red' }}>* &nbsp;</span> Test Data File
              </label>
              <div style={{ marginLeft: '-0.8rem' }}>
                <form
                  className="flex w-96"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div>
                    <input
                      disabled={!isEditable}
                      value={testData}
                      onChange={TestDataHandelChange}
                      type="text"
                      className="testDataVal"
                      style={{
                        background: 'rgb(251,252,253)',
                        width: '5rem',

                        height: '30px',
                        textAlign: 'right',
                      }}
                    ></input>
                    <>
                      <ArrowDropUpIcon
                        className="arrowUp"
                        onClick={handleNumberValueChange(incTestDataNo)}
                        disabled={true}
                      />

                      <ArrowDropDownIcon onClick={handleNumberValueChange(decrTestDataNo)} className="arrowDown" />
                    </>
                    {errorTestData && <p className="testDataValErr text-sm text-red-500">{errorTestData}</p>}
                  </div>
                </form>
              </div>
            </div>
            {/*----------Global Variable------ */}
            <div className="flex grid grid-cols-4">
              <label className="headingData">
                <span className="text-red-500">* &nbsp;</span> Global Variable
              </label>
              <div style={{ marginLeft: '-0.8rem' }}>
                <form
                  className="flex w-96"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div>
                    <input
                      disabled={!isEditable}
                      value={global}
                      onChange={globalVarHandelChange}
                      type="text"
                      className="globalVar"
                      style={{
                        background: 'rgb(251,252,253)',
                        width: '5rem',
                        height: '30px',
                        textAlign: 'right',
                      }}
                    ></input>
                    <>
                      <ArrowDropUpIcon
                        className="arrowUp"
                        onClick={handleNumberValueChange(incglobalVarNo)}
                        disabled={true}
                      />

                      <ArrowDropDownIcon onClick={handleNumberValueChange(decrglobalVarNo)} className="arrowDown" />
                    </>
                    {errorGlobalVar && <p className="globalVarErr text-sm text-red-500">{errorGlobalVar}</p>}
                  </div>
                </form>
              </div>
            </div>
            {/*----------Test Script------ */}
            <div className="flex grid grid-cols-4">
              <label className="headingData">
                <span className="text-red-500">* &nbsp;</span> Test Script
              </label>
              <div style={{ marginLeft: '-0.8rem' }}>
                <form
                  className="flex w-96"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div>
                    <input
                      disabled={!isEditable}
                      value={testScriptValue}
                      onChange={testScriptHandelChange}
                      type="text"
                      className="testScript"
                      style={{
                        background: 'rgb(251,252,253)',
                        width: '5rem',

                        height: '30px',
                        textAlign: 'right',
                      }}
                    ></input>
                    <>
                      <ArrowDropUpIcon
                        className="arrowUp"
                        onClick={handleNumberValueChange(inctestScriptNo)}
                        disabled={true}
                      />

                      <ArrowDropDownIcon onClick={handleNumberValueChange(decrtestScriptNo)} className="arrowDown" />
                    </>
                    {errorTestScript && <p className="testScriptErr text-sm text-red-500">{errorTestScript}</p>}
                  </div>
                </form>
              </div>
            </div>
            {/*----------Module ------ */}
            <div className="flex grid grid-cols-4">
              <label className="headingData">
                <span className="text-red-500">* &nbsp;</span> Module
              </label>
              <div style={{ marginLeft: '-0.8rem' }}>
                <form
                  className="flex w-96"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div>
                    <input
                      disabled={!isEditable}
                      value={moduleNo}
                      onChange={moduleHandelChange}
                      type="text"
                      className="module"
                      style={{
                        background: 'rgb(251,252,253)',
                        width: '5rem',

                        height: '30px',
                        textAlign: 'right',
                      }}
                    ></input>
                    <>
                      <ArrowDropUpIcon
                        className="arrowUp"
                        onClick={handleNumberValueChange(incModuleNo)}
                        disabled={true}
                      />

                      <ArrowDropDownIcon onClick={handleNumberValueChange(decrModuleNo)} className="arrowDown" />
                    </>
                    {errorModule && <p className="moduleErr text-sm text-red-500">{errorModule}</p>}
                  </div>
                </form>
              </div>
            </div>

            {/*----------Page ------ */}
            <div className="flex grid grid-cols-4">
              <label className="headingData">
                <span className="text-red-500">* &nbsp;</span> Page
              </label>
              <div style={{ marginLeft: '-0.8rem' }}>
                <form
                  className="flex w-96"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div>
                    <input
                      disabled={!isEditable}
                      value={pageNo}
                      onChange={pageHandelChange}
                      type="text"
                      className="pages"
                      style={{
                        background: 'rgb(251,252,253)',
                        width: '5rem',

                        height: '30px',
                        textAlign: 'right',
                      }}
                    ></input>
                    <>
                      <ArrowDropUpIcon
                        className="arrowUp"
                        onClick={handleNumberValueChange(incPageNo)}
                        disabled={true}
                      />

                      <ArrowDropDownIcon onClick={handleNumberValueChange(decrPageNo)} className="arrowDown" />
                    </>
                    {errorPage && <p className="pageErr text-sm text-red-500">{errorPage}</p>}
                  </div>
                </form>
              </div>
            </div>

            {/*----------Package ------ */}
            <div className="flex grid grid-cols-4">
              <label className="headingData">
                <span className="text-red-500">* &nbsp;</span> Package
              </label>
              <div style={{ marginLeft: '-0.8rem' }}>
                <form
                  className="flex w-96"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div>
                    <input
                      disabled={!isEditable}
                      value={packageNo}
                      onChange={pakageHandelChange}
                      type="text"
                      className="pakage"
                      style={{
                        background: 'rgb(251,252,253)',
                        width: '5rem',

                        height: '30px',
                        textAlign: 'right',
                      }}
                    ></input>
                    <>
                      <ArrowDropUpIcon
                        className="arrowUp"
                        onClick={handleNumberValueChange(incPakageNo)}
                        disabled={true}
                      />

                      <ArrowDropDownIcon onClick={handleNumberValueChange(decrPakageNo)} className="arrowDown" />
                    </>
                    {errorPackage && <p className="pakageErr text-sm text-red-500">{errorPackage}</p>}
                  </div>
                </form>
              </div>
            </div>

            {/*----------Library ------ */}
            <div className="flex grid grid-cols-4">
              <label className="headingData">
                <span className="text-red-500">* &nbsp;</span> Library
              </label>
              <div style={{ marginLeft: '-0.8rem' }}>
                <form
                  className="flex w-96"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div>
                    <input
                      disabled={!isEditable}
                      value={library}
                      onChange={libraryHandelChange}
                      type="text"
                      className="librarys"
                      style={{
                        background: 'rgb(251,252,253)',
                        width: '5rem',

                        height: '30px',
                        textAlign: 'right',
                      }}
                    ></input>
                    <>
                      <ArrowDropUpIcon
                        className="arrowUp"
                        onClick={handleNumberValueChange(incLibraryNo)}
                        disabled={true}
                      />

                      <ArrowDropDownIcon onClick={handleNumberValueChange(decrLibraryNo)} className="arrowDown" />
                    </>
                    {errorLibrary && <p className="libraryErr text-sm text-red-500">{errorLibrary}</p>}
                  </div>
                </form>
              </div>
            </div>

            {/*----------Folder ------ */}
            <div className="flex grid grid-cols-4">
              <label className="headingData">
                <span className="text-red-500">* &nbsp;</span> Folder
              </label>
              <div style={{ marginLeft: '-0.8rem' }}>
                <form
                  className="flex w-96"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div>
                    <input
                      disabled={!isEditable}
                      value={folder}
                      onChange={folderHandelChange}
                      type="text"
                      className="folders"
                      style={{
                        background: 'rgb(251,252,253)',
                        width: '5rem',

                        height: '30px',
                        textAlign: 'right',
                      }}
                    ></input>
                    <>
                      <ArrowDropUpIcon
                        className="arrowUp"
                        onClick={handleNumberValueChange(incFolderNo)}
                        disabled={true}
                      />

                      <ArrowDropDownIcon onClick={handleNumberValueChange(decrFolderNo)} className="arrowDown" />
                    </>
                    {errorFolder && <p className="foldersErr text-sm text-red-500">{errorFolder}</p>}
                  </div>
                </form>
              </div>
            </div>
            {/*----------Suite ------ */}
            <div className="flex grid grid-cols-4">
              <label className="headingData">
                <span className="text-red-500">* &nbsp;</span> Suite
              </label>
              <div style={{ marginLeft: '-0.8rem' }}>
                <form
                  className="flex w-96"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div>
                    <input
                      disabled={!isEditable}
                      value={suit}
                      onChange={suitHandelChange}
                      type="text"
                      className="suitData"
                      style={{
                        background: 'rgb(251,252,253)',
                        width: '5rem',

                        height: '30px',
                        textAlign: 'right',
                      }}
                    ></input>
                    <>
                      <ArrowDropUpIcon
                        className="arrowUp"
                        onClick={handleNumberValueChange(incSuitNo)}
                        disabled={true}
                      />

                      <ArrowDropDownIcon onClick={handleNumberValueChange(decrSuitNo)} className="arrowDown" />
                    </>
                    {errorSuite && <p className="suitDataErr text-sm text-red-500">{errorSuite}</p>}
                  </div>
                </form>
              </div>
            </div>
            {/*----------Suite of Suites ------ */}
            <div className="flex grid grid-cols-4">
              <label className="headingData">
                <span className="text-red-500">* &nbsp;</span> Suite of Suites
              </label>
              <div style={{ marginLeft: '-0.8rem' }}>
                <form
                  className="flex w-96"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div>
                    <input
                      disabled={!isEditable}
                      value={suitOfSuit}
                      onChange={suitOfsuitHandelChange}
                      type="text"
                      className="suitOfsuitData"
                      style={{
                        background: 'rgb(251,252,253)',
                        width: '5rem',

                        height: '30px',
                        textAlign: 'right',
                      }}
                    ></input>
                    <>
                      <ArrowDropUpIcon
                        className="arrowUp"
                        onClick={handleNumberValueChange(incSuitofSuit)}
                        disabled={true}
                      />

                      <ArrowDropDownIcon onClick={handleNumberValueChange(decrSuitOfsuitNo)} className="arrowDown" />
                    </>
                    {errorSuiteOfSuite && <p className="suitOfsuitDataErr text-sm text-red-500">{errorSuiteOfSuite}</p>}
                  </div>
                </form>
              </div>
            </div>
            {/*----------Web Services ------ */}
            <div className="flex grid grid-cols-4">
              <label className="headingData">
                <span className="text-red-500">* &nbsp;</span> Web Services
              </label>
              <div style={{ marginLeft: '-0.8rem' }}>
                <form
                  className="flex w-96"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div>
                    <input
                      disabled={!isEditable}
                      value={webServiceNo}
                      onChange={webServHandelChange}
                      type="text"
                      className="webServ"
                      style={{
                        background: 'rgb(251,252,253)',
                        width: '5rem',

                        height: '30px',
                        textAlign: 'right',
                      }}
                    ></input>
                    <>
                      <ArrowDropUpIcon
                        className="arrowUp"
                        onClick={handleNumberValueChange(incwebServ)}
                        disabled={true}
                      />

                      <ArrowDropDownIcon onClick={handleNumberValueChange(decrWebServ)} className="arrowDown" />
                    </>
                    {errorWeb && <p className="webServErr text-sm text-red-500">{errorWeb}</p>}
                  </div>
                </form>
              </div>
            </div>
            {/*----------Database ------ */}
            <div className="flex grid grid-cols-4">
              <label className="headingData">
                <span className="text-red-500">* &nbsp;</span> Database
              </label>
              <div style={{ marginLeft: '-0.8rem' }}>
                <form
                  className="flex w-96"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div>
                    <input
                      disabled={!isEditable}
                      value={db}
                      onChange={databaseHandelChange}
                      type="text"
                      className="database"
                      style={{
                        background: 'rgb(251,252,253)',
                        width: '5rem',

                        height: '30px',
                        textAlign: 'right',
                      }}
                    ></input>
                    <>
                      <ArrowDropUpIcon
                        className="arrowUp"
                        onClick={handleNumberValueChange(incdatabase)}
                        disabled={true}
                      />

                      <ArrowDropDownIcon onClick={handleNumberValueChange(decrDatabase)} className="arrowDown" />
                    </>
                    {errroDatabase && <p className="databaseErr text-sm text-red-500">{errroDatabase}</p>}
                  </div>
                </form>
              </div>
            </div>

            {/*----------History ------ */}
            <div className="flex grid grid-cols-4">
              <label className="headingData">
                <span className="text-red-500">* &nbsp;</span> History
              </label>
              <div style={{ marginLeft: '-0.8rem' }}>
                <form
                  className="flex w-96"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div>
                    <input
                      disabled={!isEditable}
                      value={historyValue}
                      onChange={historyHandelChange}
                      type="text"
                      className="historyData"
                      style={{
                        background: 'rgb(251,252,253)',
                        width: '5rem',

                        height: '30px',
                        textAlign: 'right',
                      }}
                    ></input>
                    <>
                      <ArrowDropUpIcon
                        className="arrowUp"
                        onClick={handleNumberValueChange(incHistory)}
                        disabled={true}
                      />

                      <ArrowDropDownIcon onClick={handleNumberValueChange(decrHistory)} className="arrowDown" />
                    </>
                    {errorHistory && <p className="historyErr text-sm text-red-500">{errorHistory}</p>}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(HistoryConfig);

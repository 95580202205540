import React, { useState, useMemo, useContext, useEffect } from 'react';
import MyInput from '@pagescommon/Inputs/MyInput';
import FileUploadButton from '@pagescommon/fileUpload_button';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { RestContext } from '../../../restapi';
import { WEBSERVICE } from '../../../constants/constants';
import '@pages/test-development/script/scripts/webservice/restapi/css/common_Webservice.scss';
import '@pages/test-development/script/scripts/webservice/restapi/request/request_body/css/request_body.css';
import Info from '../../request_parameter/images/info-gray-24dp.svg';
import { colors } from '@src/css_config/colorConstants';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { makeStyles } from '@material-ui/core/styles';
import InfoIconHover from '@pages/test-development/script/scripts/webservice/restapi/request/request_parameter/images/info_black_24dp.svg';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';
import useSyncContextValues from '@src/hooks/useSyncContextValues';
import classNames from 'classnames';
import { Fragment } from 'react';
import { validateFileExtension } from '@util/common_utils';
import { WEBSERVICE_FORMDATA_FILE_ALLOWED } from '@util/validations';

const useStyles = makeStyles({
  customModal: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    border: 'none',
  },
  paper: {
    width: 450,
  },
  customModalBodyContainer: {
    width: '100%',
    whiteSpace: 'normal',
    boxShadow: `0px 0px 4px ${colors.shadow_black}`,
    overflow: 'hidden',
    height: '89%',
    backgroundColor: colors.text_white,
  },
});

export default function Oauth1(props) {
  const { onAuthIconHover, authIconHover } = props;
  const isEditable = useMemo(() => window.permission?.isEditAccess('testDevelopment'), []);
  const context = useContext(RestContext);
  const formik = useFormik({
    initialValues: {
      signatureMethod: 'HMAC-SHA1',
      token: '',
      tokenSecret: '',
      consumerKey: '',
      consumerSecret: '',
      callbackURL: '',
      nonce: '',
      timeStamp: '',
      verifier: '',
      version: '',
      realm: '',
      hashBody: false,
      ...(context?.values?.oauth1Data || {}),
    },
    validationSchema: Yup.object({
      signatureMethod: Yup.string().required(WEBSERVICE.OAUTH1_SIGNATURE_METHOD_ID),
      consumerKey: Yup.string().required(WEBSERVICE.OAUTH1_CONSUMER_KEY),
      consumerSecret: Yup.string().required(WEBSERVICE.OAUTH1_CONSUMER_SECRET),
    }),
  });
  useSyncContextValues(formik, context, 'oauth1Data');
  const [showModal, setShowModal] = useState(false);
  const [showBtn, setShowbtn] = useState(true);
  const [selectedApkFile, setselectedApkFile] = useState(context.values?.auth1Data?.file);
  const [choosenFile, setChoosenFile] = useState(context.values?.auth1Data?.file);
  const [Open, setOpen] = useState(false);
  const [dataExpandPopUp, setDataExpandPopUp] = useState('');
  const [consumerKey, setConsumerKey] = useState('');
  const [consumerSecret, setConsumerSecret] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [tokenSecret, setTokenSecret] = useState('');
  const [callbackUrl, setCallbackUrl] = useState('');
  const [verifier, setVerifier] = useState('');
  const [timestamp, setTimestamp] = useState('');
  const [nonce, setNonce] = useState('');
  const [version, setVersion] = useState('');
  const [realm, setRealm] = useState('');

  const classes = useStyles();
  const handleChange2 = (e) => {
    const { value, data, action, name } = e.target || 'unknown';
    context.setFieldValue('authType', 'Oauth1');
    let values = '';
    if (name === 'hashBody') {
      values = { ...formik.values, ...(context?.values?.oauth1Data || {}), [name]: value };
    } else {
      values = {
        ...formik.values,
        ...(context?.values?.oauth1Data || {}),
        [name]: props.getUserInputValue(value, false, data, action),
      };
    }

    context.setFieldValue('oauth1Data', values);
    const updatedAuthData = { ...(context?.values?.auth1Data || {}), [name]: value };
    context.setFieldValue('auth1Data', updatedAuthData);
    formik.handleChange(e);
    formik.validateForm(values).then((errors) => context.setFieldValue('errors', errors));
    const currentOath1Value = { ...(context?.values?.currentOath1Value || {}), [name]: e };
    context.setFieldValue('currentOath1Value', currentOath1Value);
    props.handleSaveAsSteps(false);
  };

  useEffect(() => {
    formik.validateForm().then((errors) => context.setFieldValue('errors', errors));
    return () => context.setFieldValue('currentOath1Value', {});
  }, []);

  const changeHandler = (e) => {
    const { files, name } = e.target;
     if (validateFileExtension(e, WEBSERVICE_FORMDATA_FILE_ALLOWED)) {
      setselectedApkFile(files[0]?.name);
      setShowbtn(true);
      const updatedAuthData = { ...(context?.values?.auth1Data || {}), [name]: files[0]?.name };
      context.setFieldValue('auth1Data', updatedAuthData);
    } else {
      props?.MyAlert.warning(`${e.target.files[0].name} is unsupported.`);
    }
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  const setSelectedFile = () => {
    setShowModal(false);
    setChoosenFile(selectedApkFile);
  };

  const oAuthDataChange = (e, callback) => {
    handleChange2(e);
    callback(e.target.value);
  };

  const onCancelChooseFile = () => {
    if (!choosenFile) {
      setselectedApkFile('');
    } else {
      setselectedApkFile(choosenFile);
    }
  };

  const { signatureMethod } = formik.values;
  const { filteredForLoopVariable, filteredDataProviderVariable, ...restProps } = props;

  const applyTabClass = (algorithm) => {
    return signatureMethod === algorithm;
  };

  return (
    <div className="mt-3.5 width-of-oath1-container relative top-12 -left-4">
      <Modal open={showModal} className={`${classes.customModal} modal-dialog`}>
        <div className={`${classes.paper} page-modal-size1`}>
          <div className="modal-header-api">
            <div className="pl-2">
              <h2 className="title white fontPoppinsMediumSm">Choose File</h2>
            </div>
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                setShowModal(false);
                onCancelChooseFile();
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className={`${classes.customModalBodyContainer} modal-body`} id="journal-scroll">
            <form className="pl-4 flex items-center">
              <span className="fontPoppinsRegularSm choose-file-header">File From Local Drive</span>
              <FileUploadButton
                name="file"
                id="file"
                onChange={changeHandler}
                value={selectedApkFile}
                setLocalFileName={setselectedApkFile}
                showBtn={showBtn}
                setShowBtn={setShowbtn}
                webServiceUploadBtn={true}
              />
            </form>
          </div>
          <div className="modal-footer-section">
            <div className="float-right">
              <button
                className=" modal-close-btn"
                onClick={() => {
                  setShowModal(false);
                  onCancelChooseFile();
                }}
              >
                Cancel
              </button>
              <button type="submit" className=" modal-save-btn" onClick={setSelectedFile}>
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal open={Open} className="modal-dialog">
        <div className="modal-container page-modal-size1">
          <div className="modal-header">
            <h2 className="title">{`RSA private key`}</h2>
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="modal-body" id="journal-scroll">
            <div>
              {dataExpandPopUp ? (
                <div className="flex flex-row mt-2" id="journal-scroll">
                  <div
                    className="console_output_container_Response fontPoppinsRegularMd overflow-y-auto shadow"
                    id="journal-scroll"
                  >
                    {dataExpandPopUp}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="cancel-button-common cancel-button-layout-style-project mr-3 fontPoppinsRegularSm"
              onClick={() => {
                setOpen(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
      <form onSubmit={formik.handleSubmit} className="space-y-6" noValidate>
        <div className="flex auth1-container-layout">
          <div className="auth1-container-sub-layout">
            <div className="fontPoppinsRegularLg flex relative top-3 left-3" onDragStart={props.handleDragStart}>
              <span className="common-auth-star-symbol text-xs fontPoppinsSemiboldSm">*</span>{' '}
              <span className="text-xs font-bold fontPoppinsSemiboldSm">Signature Method </span>
              <CustomTooltip
                title={
                  <span className="fontPoppinsRegularXs">
                    This is the method which consumer used to sign the Request
                  </span>
                }
                placement="right"
              >
                <img
                  src={authIconHover?.sign_method ? InfoIconHover : Info}
                  id="sign_method"
                  onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                  onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                  className="ml-2 w-4 h-4 object-contain float-right"
                  alt=""
                  draggable="false"
                />
              </CustomTooltip>
            </div>

            <div className="auth-left-side-container">
              {WEBSERVICE.OAUTH_1.map((algorithm) => (
                <Fragment key={algorithm}>
                  <div
                    className={classNames('cursor-pointer text-left', {
                      'active-tab-in-auth1 active-auth1-left-side-panel': applyTabClass(algorithm),
                      'auth1-left-side-panel': !applyTabClass(algorithm),
                    })}
                    onClick={() => handleChange2({ target: { name: 'signatureMethod', value: algorithm } })}
                  >
                    <span className="select-none fontPoppinsRegularSm pl-2">{algorithm}</span>
                  </div>
                  <span className="h-2 flex"></span>
                </Fragment>
              ))}
            </div>
          </div>
          <div className="auth-white-background-container">
            <div className="header-in-authorization fontPoppinsSemiboldSm11 mb-4">{formik.values.signatureMethod}</div>
            <div className="auth-white-background-sub-container">
              <div className="grid grid-cols-4 gap-5 relative">
                <div className="auth1-textfield-section-width">
                  <div
                    className="fontPoppinsMediumSm  text-left common-text-color-in-request flex"
                    onDragStart={props.handleDragStart}
                  >
                    <span className="common-auth-star-symbol">*</span> Consumer Key
                    <CustomTooltip
                      title={
                        <span className="fontPoppinsRegularXs">
                          This is a value used by the Consumer to identify itself to the service provider.
                        </span>
                      }
                      placement="right"
                    >
                      <img
                        className="ml-2 infoIcon w-4 h-4 object-contain"
                        src={authIconHover?.service_provider ? InfoIconHover : Info}
                        id="service_provider"
                        onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                        onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                        alt="showMore"
                        width="15"
                        draggable="false"
                      />
                    </CustomTooltip>
                  </div>
                  <MyInput
                    type="text"
                    placeholder="Enter consumer key"
                    variableInput={true}
                    error={formik.errors.consumerKey && formik.touched.consumerKey}
                    value={
                      consumerKey
                        ? consumerKey
                        : props.getUserInputValue(context?.values?.oauth1Data?.consumerKey, true)
                    }
                    onMouseDown={formik.handleBlur}
                    onChange={(e) => {
                      oAuthDataChange(e, setConsumerKey);
                    }}
                    autoComplete="off"
                    name="consumerKey"
                    helperText={formik.touched.consumerKey && formik.errors.consumerKey}
                    currentValue={context.values.currentOath1Value?.consumerKey}
                    className="input-style-for-auth1"
                    inputValue={'input'}
                    {...props}
                  />
                </div>
                {formik?.values?.signatureMethod === 'HMAC-SHA1' ||
                formik?.values?.signatureMethod === 'HMAC-SHA256' ||
                formik?.values?.signatureMethod === 'HMAC-SHA512' ||
                formik?.values?.signatureMethod === 'PLAINTEXT' ? (
                  <div>
                    <div className="auth1-textfield-section-width">
                      <div
                        className=" fontPoppinsMediumSm text-left common-text-color-in-request flex"
                        onDragStart={props.handleDragStart}
                      >
                        {' '}
                        <span className="common-auth-star-symbol">*</span> Consumer Secret
                        <CustomTooltip
                          title={
                            <span className="fontPoppinsRegularXs">
                              This is a secret used by the Consumer to establish ownership of the Consumer Key.
                            </span>
                          }
                          placement="right"
                        >
                          <img
                            className="ml-2 infoIcon w-4 h-4 object-contain"
                            src={authIconHover?.consumer_key ? InfoIconHover : Info}
                            id="consumer_key"
                            onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                            onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                            alt="showMore"
                            width="15"
                            draggable="false"
                          />
                        </CustomTooltip>
                      </div>
                      <MyInput
                        canCreateVariable={isEditable}
                        type="text"
                        placeholder="Enter consumer secret"
                        variableInput={true}
                        error={formik.errors.consumerSecret && formik.touched.consumerSecret}
                        value={
                          consumerSecret
                            ? consumerSecret
                            : props.getUserInputValue(context?.values?.oauth1Data?.consumerSecret, true)
                        }
                        onMouseDown={formik.handleBlur}
                        className="input-style-for-auth1"
                        inputValue={'input'}
                        onChange={(e) => {
                          oAuthDataChange(e, setConsumerSecret);
                        }}
                        currentValue={context.values.currentOath1Value?.consumerSecret}
                        autoComplete="off"
                        name="consumerSecret"
                        helperText={formik.touched.consumerSecret && formik.errors.consumerSecret}
                        {...props}
                      />
                    </div>
                  </div>
                ) : null}
                {formik?.values?.signatureMethod === 'RSA-SHA1' ||
                formik?.values?.signatureMethod === 'RSA-SHA256' ||
                formik?.values?.signatureMethod === 'RSA-SHA512' ? (
                  <div className="">
                    <div className="auth1-textfield-section-width">
                      <div
                        className="fontPoppinsMediumSm text-left common-text-color-in-request"
                        onDragStart={props.handleDragStart}
                      >
                        Access Token
                      </div>
                      <MyInput
                        canCreateVariable={isEditable}
                        type="text"
                        placeholder="Enter access token"
                        variableInput={true}
                        value={
                          accessToken ? accessToken : props.getUserInputValue(context?.values?.oauth1Data?.token, true)
                        }
                        onMouseDown={formik.handleBlur}
                        className="input-style-for-auth1"
                        inputValue={'input'}
                        onChange={(e) => {
                          oAuthDataChange(e, setAccessToken);
                        }}
                        autoComplete="off"
                        name="token"
                        currentValue={context.values.currentOath1Value?.token}
                        {...restProps}
                      />
                    </div>
                  </div>
                ) : null}
                {['HMAC-SHA1', 'HMAC-SHA256', 'HMAC-SHA512', 'PLAINTEXT'].includes(formik.values.signatureMethod) ? (
                  <div className="">
                    <div className="auth1-textfield-section-width">
                      <div
                        className="fontPoppinsMediumSm text-left common-text-color-in-request"
                        onDragStart={props.handleDragStart}
                      >
                        Access Token
                      </div>
                      <MyInput
                        canCreateVariable={isEditable}
                        type="text"
                        placeholder="Enter access token"
                        variableInput={true}
                        value={
                          accessToken ? accessToken : props.getUserInputValue(context?.values?.oauth1Data?.token, true)
                        }
                        onMouseDown={formik.handleBlur}
                        className="input-style-for-auth1"
                        inputValue={'input'}
                        onChange={(e) => {
                          oAuthDataChange(e, setAccessToken);
                        }}
                        autoComplete="off"
                        name="token"
                        currentValue={context.values.currentOath1Value?.token}
                        {...restProps}
                      />
                    </div>
                  </div>
                ) : null}
                {formik?.values?.signatureMethod === 'HMAC-SHA1' ||
                formik?.values?.signatureMethod === 'HMAC-SHA256' ||
                formik?.values?.signatureMethod === 'HMAC-SHA512' ||
                formik?.values?.signatureMethod === 'PLAINTEXT' ? (
                  <div>
                    <div className="auth1-textfield-section-width">
                      <div
                        className="fontPoppinsMediumSm text-left common-text-color-in-request flex"
                        onDragStart={props.handleDragStart}
                      >
                        {' '}
                        Token Secret
                        <CustomTooltip
                          title={
                            <span className="fontPoppinsRegularXs">
                              This is a secret used by the Consumer to establish ownership of a given token.
                            </span>
                          }
                          placement="bottom-start"
                        >
                          <img
                            className="ml-2 infoIcon w-4 h-4 object-contain"
                            src={authIconHover?.secret_token ? InfoIconHover : Info}
                            id="secret_token"
                            onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                            onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                            alt="showMore"
                            width="15"
                            draggable="false"
                          />
                        </CustomTooltip>
                      </div>
                      <MyInput
                        type="text"
                        placeholder="Enter token secret"
                        variableInput={true}
                        value={
                          tokenSecret
                            ? tokenSecret
                            : props.getUserInputValue(context?.values?.oauth1Data?.tokenSecret, true)
                        }
                        onMouseDown={formik.handleBlur}
                        className="input-style-for-auth1"
                        inputValue={'input'}
                        onChange={(e) => {
                          oAuthDataChange(e, setTokenSecret);
                        }}
                        autoComplete="off"
                        name="tokenSecret"
                        currentValue={context.values.currentOath1Value?.tokenSecret}
                        {...props}
                      />
                    </div>
                  </div>
                ) : null}
                {formik?.values?.signatureMethod === 'RSA-SHA1' ||
                formik?.values?.signatureMethod === 'RSA-SHA256' ||
                formik?.values?.signatureMethod === 'RSA-SHA512' ? (
                  <div className="RSA-section-alignment">
                    <div className="mt-3">
                      <label className=" text-gray-500 sm:mt-px sm:pt-2 fontPoppinsRegularSm text-left select-none flex">
                        <span className="common-auth-star-symbol">*</span> Private Key{' '}
                        <CustomTooltip
                          title={
                            <span className="fontPoppinsRegularXs">
                              This is a RSA Private key in PEM format for signing the request.
                            </span>
                          }
                          placement="right"
                        >
                          <img
                            className="ml-2 infoIcon w-4 h-4 object-contain"
                            src={authIconHover?.private_key ? InfoIconHover : Info}
                            id="private_key"
                            onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                            onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                            alt="showMore"
                            width="15"
                            draggable="false"
                          />
                        </CustomTooltip>
                      </label>
                    </div>
                    <div className="mt-3 fill-available-oath1">
                      <div className=" flex ml-5 select-none">
                        <div>
                          <span
                            onClick={handleOpen}
                            className="select-file-primary-button-request-body fontMontserratMediumXs cursor-pointer hover-item "
                          >
                            {choosenFile ? 'Replace file' : 'Choose file'}
                            <AttachFileIcon className="choose-file-icon"></AttachFileIcon>
                          </span>
                        </div>
                        <div className="ml-2 mt-2 flex select-none">
                          {choosenFile && (
                            <CustomTooltip title={choosenFile} placement="right">
                              <span className="no-select-file-span input-filed-label fontPoppinsRegularSm cursor-pointer">
                                {choosenFile}
                              </span>
                            </CustomTooltip>
                          )}
                          {choosenFile && (
                            <CustomTooltip title={'Remove File'} placement="right">
                              <span
                                className="text-blue-700 cursor-pointer ml-1 cross-icon-margin"
                                onClick={() => {
                                  setChoosenFile('');
                                  setselectedApkFile('');
                                  context.setValues((prev) => ({
                                    ...prev,
                                    auth1Data: {
                                      ...prev.auth1Data,
                                      file: '',
                                    },
                                  }));
                                }}
                              >
                                <img className="w-4 h-4" alt="" src="/assets/images/close_black1.svg" />
                              </span>
                            </CustomTooltip>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>

              {formik?.values?.signatureMethod === 'RSA-SHA1' ||
              formik?.values?.signatureMethod === 'RSA-SHA256' ||
              formik?.values?.signatureMethod === 'RSA-SHA512' ? (
                <div className="grid grid-cols-1 gap-5 relative">
                  <TextareaAutosize
                    minRows={3}
                    maxRows={3}
                    aria-label="maximum height"
                    value={context.values?.auth1Data?.rsaPrivateKey}
                    onChange={(e) => oAuthDataChange(e, setDataExpandPopUp)}
                    placeholder=" --Begin RSA private key-- Private key in PEM format --End RSA private key-- "
                    className="border-0 border-none TextareaAutosize-oauth1 fontPoppinsRegularMd auth1-textarea-width"
                    name="rsaPrivateKey"
                  />
                </div>
              ) : null}
              <h5
                className="font-bold text-left advanced-text-style pb-0.5 pt-2 fontPoppinsSemiboldSm11"
                onDragStart={props.handleDragStart}
              >
                Advanced
              </h5>
              <div className="border-style-in-auth1"></div>
              <div>
                <div className="grid grid-cols-4 gap-5 mt-3">
                  <div className="relative auth1-textfield-section-width">
                    <div
                      className="fontPoppinsMediumSm text-left common-text-color-in-request flex"
                      onDragStart={props.handleDragStart}
                    >
                      {' '}
                      Callback URL
                      <CustomTooltip
                        title={
                          <span className="fontPoppinsRegularXs">
                            This is an absolute URL where the service provider will redirected after obtaining user
                            authorization.
                          </span>
                        }
                        placement="right"
                      >
                        <img
                          className="ml-2 infoIcon w-4 h-4 object-contain"
                          src={authIconHover?.callback_url ? InfoIconHover : Info}
                          id="callback_url"
                          onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                          onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                          alt="showMore"
                          width="15"
                          draggable="false"
                        />
                      </CustomTooltip>
                    </div>
                    <MyInput
                      canCreateVariable={isEditable}
                      type="text"
                      placeholder="Enter callback url"
                      variableInput={true}
                      value={
                        callbackUrl
                          ? callbackUrl
                          : props.getUserInputValue(context?.values?.oauth1Data?.callbackURL, true)
                      }
                      onChange={(e) => {
                        oAuthDataChange(e, setCallbackUrl);
                      }}
                      autoComplete="off"
                      name="callbackURL"
                      className="input-style-for-auth1"
                      inputValue={'input'}
                      currentValue={context.values.currentOath1Value?.callbackURL}
                      {...props}
                    />
                  </div>
                  <div className="relative auth1-textfield-section-width">
                    <div
                      className="fontPoppinsMediumSm text-left common-text-color-in-request flex"
                      onDragStart={props.handleDragStart}
                    >
                      {' '}
                      Verifier
                      <CustomTooltip
                        title={
                          <span className="fontPoppinsRegularXs">
                            This is the verification code given by the service provider after obtaining user
                            authorization.
                          </span>
                        }
                        placement="right"
                      >
                        <img
                          className="ml-2 infoIcon w-4 h-4 object-contain"
                          src={authIconHover?.verifier ? InfoIconHover : Info}
                          id="verifier"
                          onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                          onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                          alt="showMore"
                          width="15"
                          draggable="false"
                        />
                      </CustomTooltip>
                    </div>
                    <MyInput
                      canCreateVariable={isEditable}
                      type="text"
                      placeholder="Enter verifier"
                      variableInput={true}
                      value={verifier ? verifier : props.getUserInputValue(context?.values?.oauth1Data?.verifier, true)}
                      onChange={(e) => {
                        oAuthDataChange(e, setVerifier);
                      }}
                      autoComplete="off"
                      name="verifier"
                      className="input-style-for-auth1"
                      inputValue={'input'}
                      currentValue={context.values.currentOath1Value?.verifier}
                      {...props}
                    />
                  </div>
                  <div className="relative auth1-textfield-section-width">
                    <div
                      className="fontPoppinsMediumSm text-left common-text-color-in-request"
                      onDragStart={props.handleDragStart}
                    >
                      {' '}
                      Timestamp
                    </div>
                    <MyInput
                      canCreateVariable={isEditable}
                      type="text"
                      placeholder="Enter timestamp"
                      variableInput={true}
                      value={
                        timestamp ? timestamp : props.getUserInputValue(context?.values?.oauth1Data?.timeStamp, true)
                      }
                      onChange={(e) => {
                        oAuthDataChange(e, setTimestamp);
                      }}
                      autoComplete="off"
                      className="input-style-for-auth1"
                      inputValue={'input'}
                      name="timeStamp"
                      currentValue={context.values.currentOath1Value?.timeStamp}
                      {...props}
                    />
                  </div>
                  <div className="relative auth1-textfield-section-width">
                    <div
                      className="fontPoppinsMediumSm text-left common-text-color-in-request flex"
                      onDragStart={props.handleDragStart}
                    >
                      {' '}
                      Nonce
                      <CustomTooltip
                        title={
                          <span className="fontPoppinsRegularXs">This is a random string generated by the client.</span>
                        }
                        placement="right"
                      >
                        <img
                          className="ml-2 infoIcon w-4 h-4 object-contain"
                          src={authIconHover?.Nonce ? InfoIconHover : Info}
                          id="Nonce"
                          onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                          onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                          alt="showMore"
                          width="15"
                          draggable="false"
                        />
                      </CustomTooltip>
                    </div>
                    <MyInput
                      canCreateVariable={isEditable}
                      type="text"
                      placeholder="Enter nonce"
                      variableInput={true}
                      value={nonce ? nonce : props.getUserInputValue(context?.values?.oauth1Data?.nonce, true)}
                      onChange={(e) => {
                        oAuthDataChange(e, setNonce);
                      }}
                      autoComplete="off"
                      name="nonce"
                      className="input-style-for-auth1"
                      inputValue={'input'}
                      currentValue={context.values.currentOath1Value?.nonce}
                      {...props}
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-4 gap-5 mt-4">
                <div className="relative auth1-textfield-section-width">
                  <div
                    className="fontPoppinsMediumSm text-left common-text-color-in-request"
                    onDragStart={props.handleDragStart}
                  >
                    {' '}
                    Version
                  </div>
                  <MyInput
                    canCreateVariable={isEditable}
                    type="text"
                    placeholder="Enter version"
                    variableInput={true}
                    value={version ? version : props.getUserInputValue(context?.values?.oauth1Data?.version, true)}
                    onChange={(e) => {
                      oAuthDataChange(e, setVersion);
                    }}
                    autoComplete="off"
                    name="version"
                    className="input-style-for-auth1"
                    inputValue={'input'}
                    currentValue={context.values.currentOath1Value?.version}
                    {...props}
                  />
                </div>
                <div className="relative auth1-textfield-section-width">
                  <div
                    className="fontPoppinsMediumSm text-left common-text-color-in-request flex"
                    onDragStart={props.handleDragStart}
                  >
                    {' '}
                    Realm
                    <CustomTooltip
                      title={
                        <span className="fontPoppinsRegularXs">
                          This is a string specified by the server in the WWW-Authenticate response header. It should
                          contain at least name of the host performing the authentication and might additionally
                          indicate the collection of users who might have access.
                        </span>
                      }
                      placement="right"
                    >
                      <img
                        className="ml-2 infoIcon w-4 h-4 object-contain"
                        src={authIconHover?.realm ? InfoIconHover : Info}
                        id="realm"
                        onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                        onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                        alt="showMore"
                        width="15"
                        draggable="false"
                      />
                    </CustomTooltip>
                  </div>
                  <MyInput
                    canCreateVariable={isEditable}
                    type="text"
                    placeholder="Enter Realm"
                    variableInput={true}
                    value={realm ? realm : props.getUserInputValue(context?.values?.oauth1Data?.realm, true)}
                    onChange={(e) => {
                      oAuthDataChange(e, setRealm);
                    }}
                    autoComplete="off"
                    name="realm"
                    className="input-style-for-auth1"
                    inputValue={'input'}
                    currentValue={context.values.currentOath1Value?.realm}
                    {...props}
                  />
                </div>
                <div className="flex items-end w-52">
                  <FormControlLabel
                    fontSize={12}
                    control={
                      <Checkbox
                        onChange={() => handleChange2({ target: { name: 'hashBody', value: !formik.values.hashBody } })}
                        name="hashBody"
                        color="primary"
                        defaultChecked={false}
                        checked={formik.values.hashBody}
                      />
                    }
                    label={
                      <Typography className="text-gray-600 text-xs flex" onDragStart={props.handleDragStart}>
                        Include body hash
                        <CustomTooltip
                          title={
                            <span className="fontPoppinsRegularXs">
                              Oauth Request Body Hash extension provides support for request body intigrity check for
                              content types other than application/x-www-form-urlencoded. If this option is selected,
                              the oauth body hash parameter will be calculated and added to the request.
                            </span>
                          }
                          placement="right"
                        >
                          <img
                            className="ml-2 infoIcon w-4 h-4 object-contain"
                            src={authIconHover?.body_hash ? InfoIconHover : Info}
                            id="body_hash"
                            onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                            onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                            alt="showMore"
                            width="15"
                            draggable="false"
                          />
                        </CustomTooltip>
                      </Typography>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

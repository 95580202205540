import ChartCard from '../components/ChartCard';
import DashboardStyle from '../styles/dashboard.module.scss';
import cx from 'classnames';
import Chart from 'react-apexcharts';
import { useMemo, useRef } from 'react';
import ProgressLine from '../components/ProgressLine';
import { _showNumberAfterFirstDecimal } from './CommonMethods';
import { fontPoppins } from '@src/css_config/fontConstants';

const _getKBFromStorageString = (storageString) => {
  const [storageNumber, storageUnit] = storageString?.split(' ');
  if (storageUnit?.toLowerCase() === 'tb') {
    return +storageNumber * 1024 * 1024 * 1024;
  }
  if (storageUnit?.toLowerCase() === 'gb') {
    return +storageNumber * 1024 * 1024;
  }
  if (storageUnit?.toLowerCase() === 'mb') {
    return +storageNumber * 1024;
  }
  return +storageNumber;
};

function handleChartElementsStyle(element) {
  if (element) {
    const totalValueElement = element.querySelector('.apexcharts-datalabel-value');
    if (totalValueElement) {
      const offsetYOfTotalValueElement = totalValueElement?.getAttribute('y');
      const offsetYOfTotalValueElementSubtract = Number(offsetYOfTotalValueElement) - 32;
      const totalValueLabelElement = element.querySelector('.apexcharts-datalabel-label');
      totalValueLabelElement?.setAttribute('y', offsetYOfTotalValueElementSubtract);
    }

    const donutChartCircleElement = element.querySelector('.apexcharts-pie circle');
    const getDonutChartCircleRValue = donutChartCircleElement.getAttribute('r');
    const setDountChartCirlceRValue = Number(getDonutChartCircleRValue) - 8;
    if (donutChartCircleElement) {
      donutChartCircleElement.setAttribute('stroke', '#81B9D7');
      donutChartCircleElement.setAttribute('r', setDountChartCirlceRValue);
      donutChartCircleElement.setAttribute('stroke-width', '5');
    }
    const titleTextElement = element.querySelector('.apexcharts-title-text');
    const parentElementWidth = element.offsetWidth;

    if (titleTextElement && parentElementWidth) {
      titleTextElement.setAttribute('x', parentElementWidth / 2);
    }
  }
}

function MemoryTotalConsumptionIndividual({ individualConsumption }) {
  const legendMemory = individualConsumption?.assignedMemory;

  const noData = {
    color: '#81B9D7',
    label: 'nodata',
  };
  const ref = useRef();

  const options = useMemo(
    () => ({
      chart: {
        events: {
          mounted: () => {
            handleChartElementsStyle(ref?.current);
          },
          updated: () => {
            handleChartElementsStyle(ref?.current);
          },
        },
        type: 'donut',
      },
      colors: ['#EC6666', '#EBF2F7', noData.color],

      tooltip: {
        enabled: false,
      },
      stroke: {
        width: 0,
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            labels: {
              show: true,
              value: {
                fontFamily: fontPoppins.sSm.fontFamily,
                color: '#333333',
              },
              total: {
                showAlways: true,
                show: true,
                ...fontPoppins.sSm,
                color: '#B3B8BD',
                label: 'Assigned Memory',
                formatter: (w) => {
                  return w?.config?.legendMemory;
                },
              },
            },
          },
        },
      },
      labels: ['Consumped', 'Available', noData.label],
      dataLabels: {
        enabled: false,
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
          },
        },
      },
      legend: {
        show: false,
      },
      fill: {
        type: ['solid', 'pattern'],
        opacity: 1,
        rotate: -90,
        gradient: {
          type: 'horizontal',
          rotate: -90,
        },
        pattern: {
          enabled: true,
          style: 'slantedLines',
          strokeWidth: 1,
          rotate: -90,
        },
      },
      legendMemory: legendMemory,
    }),
    [legendMemory]
  );
  const { assignedMemory = '200 MB', usedMemory = '0 MB' } = individualConsumption || {
    assignedMemory: '200 MB',
    usedMemory: '0 MB',
  };
  const assignedMemoryInKb = _getKBFromStorageString(assignedMemory);
  const usedMemoryInKb = _getKBFromStorageString(usedMemory);
  const totalAvailableMemory = assignedMemoryInKb - usedMemoryInKb;

  return (
    <ChartCard
      className={cx(
        'col-span-5 grid grid-cols-2',
        DashboardStyle['font-open-sans'],
        DashboardStyle['memory-total-consumption']
      )}
    >
      <div ref={ref}>
        <Chart
          options={options}
          series={[usedMemoryInKb, totalAvailableMemory]}
          type="donut"
          height="80%"
          className="py-8"
        />
      </div>
      <div className={cx('px-4 py-3', DashboardStyle['legend-wrapper'])}>
        <h5 className=" fontPoppinsRegularLg mb-2">Individual Consumption</h5>
        <div className="my-4 text-sm">
          <p className="mb-1 fontPoppinsRegularMd">Assigned Memory</p>
          <ProgressLine total={assignedMemoryInKb} filled={assignedMemoryInKb} />
          <span className={DashboardStyle['memory-total-consumption-span']}> Assigned : {assignedMemory}</span>
        </div>
        <div className="my-4 text-sm mt-6">
          <p className="mb-1 fontPoppinsRegularMd">Used Memory</p>
          <ProgressLine total={assignedMemoryInKb} filled={usedMemoryInKb} filledColor="#EC6666" />
          <span className={DashboardStyle['memory-total-consumption-span']}>
            {_showNumberAfterFirstDecimal(usedMemory)} used from {assignedMemory}{' '}
          </span>
        </div>
      </div>
    </ChartCard>
  );
}

export default MemoryTotalConsumptionIndividual;

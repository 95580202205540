import * as React from 'react';
import { useMemo, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import '@pages/licenses/styles/license-alert-popup.scss';
import { ReactComponent as ChangeCircle } from '@assets/svg-imports/change_arrow_blue.svg';
import { ReactComponent as SwitchLicenseWhite } from '@assets/svg-imports/switch_license_white.svg';
import { ReactComponent as DangerAlert } from '@assets/svg-imports/danger-alert.svg';
import { colors } from '@src/css_config/colorConstants.js';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import SelectLicense from '@pages/licenses/components/SelectLicense';
import { signOutReq } from '@src/api/api_services';
import { getUserEmailId, getCurrentLicenseId, getAccessToken, getUserStatus, encode } from '@src/util/common_utils';
import { getRemainingDays, getSuperAdmins, showOnlyDate, FREE_LICENSE_TYPE } from '@util/licenseUtils';
import { LICENSE_DETAILS_CONSTANTS, LICENSE_EXPIRED_CONSTANTS } from '@src/common/ui-constants';
import { getWebSiteHostName } from '@util/common_utils';
import { Tooltip } from '@mui/material';

const ExpiredLicensePopup = ({ openOne, handleClose, data, onRenew, licensesData }) => {
  let licenseTransactionAllowed = JSON.parse(localStorage.getItem('allowedProjects'))?.features?.licenses;
  const emailId = getUserEmailId();
  const currentLicenseId = getCurrentLicenseId();
  const accessToken = getAccessToken();

  const [openSelectLicense, setOpenSelectLicense] = useState(false);
  const [isHoverSwitchLicense, setIsHoverSwitchLicense] = useState(false);
  const handleOpenSelectLicense = () => setOpenSelectLicense(true);
  const handleCloseSelectLicense = () => setOpenSelectLicense(false);
  const location = useLocation();
  const history = useHistory();
  const closePopup = () => {
    handleClose(false);
  };
  const userName = JSON.parse(localStorage.getItem('test-optimize-user')).name;
  const userprivilege = JSON.parse(localStorage.getItem('test-optimize-user')).privilege;
  const licenseStatus = JSON.parse(localStorage.getItem('test-optimize-user')).licenseStatus;
  const { userLicenseDetails } = useMemo(() => {
    const user = JSON.parse(localStorage.getItem('test-optimize-user') || '{}');
    return {
      userLicenseDetails: data.find(({ id }) => user.licenseId === id) || {},
    };
  }, [data]);
  let superAdminsList = getSuperAdmins(userLicenseDetails?.licenseUsers);

  let signOut = () => {
    let payload = {
      emailId,
      currentLicenseId
    };

    signOutReq(payload)
      .then((response) => {
        if (response.data.status === 'Success' && process?.env?.REACT_APP_ENVIRONMENT !== 'onprem') {
          localStorage.clear();
          window.location.replace(getWebSiteHostName() + `/signin?product=fireflink-platform`);
        }
      })
      .catch((error) => {
        console.error('SignOut API Failed!', error);
      });
  };

  const userStatus = getUserStatus();
  const isDisabled = licenseStatus === 'EXPIRED' || userStatus === 'DISABLED';
  useEffect(() => {
    if (location?.pathname === '/licenses/fireflink-license' && isDisabled) {
      history.push('/licenses/fireflink-license');
    }
  }, [location?.pathname, isDisabled, history]);

  return (
    <>
      {data.map((val) => {
        return (
          <Modal
            key={val?.id}
            open={!openSelectLicense}
            aria-labelledby="expired-modal-title"
            aria-describedby="expired-modal-description"
            BackdropProps={{
              style: {
                backgroundColor: colors.modal_backdrop,
                backdropFilter: 'blur(1px)',
              },
            }}
          >
            <Box
              sx={{ width: '540px', height: userprivilege === 'User' || userprivilege === 'Admin' ? '426px' : '333px' }}
              className={`${userprivilege === 'User' || userprivilege === 'Admin' ? ' boxModal-user' : 'boxModal'} focus:outline-none`}
            >
              <div className="flex flex-col justify-between">
                <div className="colorHeaderRed"></div>
                <div className="box-content">
                  <DangerAlert className="expired-svg" />
                  <div className="main-content">
                    <div className="header-content">
                      <div className="flex items-center fontPoppinsMediumXX2">
                        <p className="box-title">{LICENSE_EXPIRED_CONSTANTS?.ALERT}!</p>&nbsp;{' '}
                        <span>{LICENSE_EXPIRED_CONSTANTS?.LICENSE_EXPIRED}</span>
                      </div>
                    </div>
                    <p className="fontPoppinsRegularMd">Attention {userName},</p>
                    {userprivilege === 'User' || userprivilege === 'Admin' ? (
                      <p className="fontPoppinsRegularMd">
                        {userLicenseDetails?.licenseType === 'C-Professional' ? (
                          <>
                            {LICENSE_EXPIRED_CONSTANTS?.LICENSE_EXPIRED_CONTACT_SUPER_ADMIN}{' '}
                            <span className="fontPoppinsSemiboldMd">Renew</span>
                            {LICENSE_EXPIRED_CONSTANTS?.LICENSE_FOR_UNINTERRUPTED_SERVICES}
                          </>
                        ) : (
                          <>
                            {LICENSE_EXPIRED_CONSTANTS?.FREE_TRIAL_EXPIRED_ADMIN}{' '}
                            <span className="fontPoppinsSemiboldMd">Upgrade</span>
                            {LICENSE_EXPIRED_CONSTANTS?.LICENSE_FOR_UNINTERRUPTED_SERVICES}
                          </>
                        )}
                      </p>
                    ) : (
                      <>
                        <p className="fontPoppinsRegularMd">
                          {userLicenseDetails?.licenseType === 'C-Professional' ? (
                            <>
                              {LICENSE_EXPIRED_CONSTANTS?.LICENSE_HAS_EXPIRED}
                              <span className="fontPoppinsSemiboldMd">Renew</span>
                              {LICENSE_EXPIRED_CONSTANTS?.TO_CONTINUE_USING_APP_OR_SWITCH}
                              {data?.length > 1 ? LICENSE_EXPIRED_CONSTANTS?.OR_SWITCH_LICENSE : '.'}{' '}
                            </>
                          ) : (
                            <>
                              {LICENSE_EXPIRED_CONSTANTS?.LICENSE_EXPIRED_PLEASE_UPGRADE}{' '}
                              <span className="fontPoppinsSemiboldMd">Upgrade</span>{' '}
                              {LICENSE_EXPIRED_CONSTANTS?.TO_CONTINUE_USING_APP_OR_SWITCH}
                              {data?.length > 1 ? LICENSE_EXPIRED_CONSTANTS?.OR_SWITCH_LICENSE : '.'}
                            </>
                          )}
                        </p>
                      </>
                    )}
                    <div className="license-details-section">
                      <div className="flex gap-10 items-center">
                        <span className="fontPoppinsRegularSm">{LICENSE_DETAILS_CONSTANTS?.LICENSE_NAME} :</span>
                        <Tooltip title={userLicenseDetails?.licenseName}>
                          <span className="fontPoppinsSemiboldSm">
                            {userLicenseDetails?.licenseName?.length > 15
                              ? userLicenseDetails?.licenseName?.slice(0, 15) + '...'
                              : userLicenseDetails?.licenseName}
                          </span>
                        </Tooltip>
                        {data?.length > 1 ? (
                          <button
                            onClick={handleOpenSelectLicense}
                            onMouseOver={() => setIsHoverSwitchLicense(true)}
                            onMouseOut={() => setIsHoverSwitchLicense(false)}
                            className="switch-license-btn"
                          >
                            <span>
                              {isHoverSwitchLicense ? (
                                <SwitchLicenseWhite className="w-4 h-4" />
                              ) : (
                                <ChangeCircle className="w-4 h-4" />
                              )}
                            </span>{' '}
                            <span>Switch License</span>
                          </button>
                        ) : null}
                      </div>
                      <div className="flex gap-14 items-center">
                        <span className="fontPoppinsRegularSm">{LICENSE_DETAILS_CONSTANTS?.PURCHASED_ON} :</span>
                        <span className="fontPoppinsSemiboldSm">{showOnlyDate(userLicenseDetails?.startDate)}</span>
                      </div>
                      <div className="flex gap-14 items-center">
                        <span className="fontPoppinsRegularSm">{LICENSE_DETAILS_CONSTANTS?.EXPIRING_ON} :</span>
                        <span className="fontPoppinsSemiboldSm ml-4">
                          {showOnlyDate(userLicenseDetails?.expiryDate)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    userprivilege === 'Super Admin' ? 'expired-content-super-admin' : 'expired-content-user-admin'
                  }
                >
                  <p className="fontPoppinsSemiboldSm">
                    {LICENSE_EXPIRED_CONSTANTS?.SUBSCRIPTION_EXPIRED_IF_NOT_RENEWED(
                      userLicenseDetails?.licenseType === 'C-Professional' ? 'renewed' : 'upgraded'
                    )}{' '}
                    <span className="expired-color">
                      {userLicenseDetails?.willBeDeletedIn} {getRemainingDays(userLicenseDetails?.willBeDeletedIn)}
                    </span>
                    {LICENSE_EXPIRED_CONSTANTS?.YOUR_LICENSE_WILL_BE_DELETED}
                  </p>
                </div>
                {userprivilege === 'User' || userprivilege === 'Admin' ? (
                  <div className="contact-super-admin">
                    <p className="fontPoppinsRegularMd">
                      {LICENSE_EXPIRED_CONSTANTS?.FOR_SUPPORT_CONTACT_SUPER_ADMINS}
                    </p>
                    {superAdminsList?.map((user) => {
                      return (
                        <p key={user?.userId} className="fontPoppinsSemiboldMd primary-color">
                          {user?.emailId}
                        </p>
                      );
                    })}
                  </div>
                ) : null}
                <div className="box-footer">
                  {licenseTransactionAllowed && (
                    <>
                      <button
                        className="buy-new-btn fontMontserratMediumXs"
                        onClick={() => {
                          history.push(`/licenses/fireflink-license/buy`);
                          closePopup();
                        }}
                      >
                        Buy New License
                      </button>
                      {userprivilege === 'Super Admin' ? (
                        <>
                          {' '}
                          {userLicenseDetails?.licenseType !== FREE_LICENSE_TYPE ? (
                            <button
                              onClick={() => {
                                closePopup();
                                history.push(`/licenses/${encode(currentLicenseId)}/FireFlink License/renew`);
                              }}
                              className="renew-btn fontMontserratMediumXs"
                            >
                              Renew
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                closePopup();
                                history.push(`/licenses/${encode(currentLicenseId)}/FireFlink License/upgrade`);
                              }}
                              className="renew-btn fontMontserratMediumXs"
                            >
                              Upgrade
                            </button>
                          )}{' '}
                        </>
                      ) : null}
                    </>
                  )}
                  <button onClick={signOut} className="renew-btn fontMontserratMediumXs">
                    Sign out
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        );
      })}
      {openSelectLicense && (
        <SelectLicense
          dataValue={data}
          handleClose={handleClose}
          openSelectLicense={openSelectLicense}
          handleCloseSelectLicense={handleCloseSelectLicense}
          onRenew={(licenseData) => onRenew(licenseData)}
          initialLicenseDetails={userLicenseDetails}
          licenses={data}
          licenseTransactionAllowed={licenseTransactionAllowed}
        />
      )}
    </>
  );
};
ExpiredLicensePopup.propTypes = {
  openOne: PropTypes.bool,
  handleClose: PropTypes.func,
  licenceID: PropTypes.string,
  dataValue: PropTypes.object,
  onRenew: PropTypes.func.isRequired,
};
export default ExpiredLicensePopup;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from '../Routes';
import PageHeader from '../shared/PageHeader';
import MyTable from '../shared/Table';
import ActionButton from '../shared/ActionButton';
import Radio from '@material-ui/core/Radio';

function Index() {
  const history = useHistory();
  const addButton = (
    <button
      onClick={() => {
        history.push(Routes.addlicence);
      }}
      className="primary-btn ml-3 p-1"
    >
      + Licence
    </button>
  );
  const menuData = () => (
    <ActionButton
      MoreAction
      menuoptions={[
        {
          label: 'Manage license',
          action: () => alert('agag'),
        },
        {
          label: 'License History',
        },
        {
          label: 'Billing History',
        },
      ]}
    />
  );

  const data = React.useMemo(
    () => [
      {
        default: <Radio aria-label="default" name="default"></Radio>,
        id: (
          <span className="text-blue-700 cursor-pointer text-xs open-sans-regular font-regular">
            {' '}
            CBWEB150520211434233{' '}
          </span>
        ),
        type: <span className="text-gray-700 text-xs open-sans-regular font-regular"> C Basis </span>,
        plan: <span className="text-gray-700 text-xs open-sans-regular font-regular"> N/A </span>,
        privilege: <span className="text-gray-700 text-xs open-sans-regular font-regular"> Admin</span>,
        owner: <span className="text-blue-700 cursor-pointer text-xs open-sans-regular font-regular">Sheela</span>,
        actions: menuData(),
      },
      {
        default: <Radio aria-label="default" name="default"></Radio>,
        id: (
          <span className="text-blue-700 cursor-pointer text-xs open-sans-regular font-regular">
            {' '}
            CBWEB150520211434233{' '}
          </span>
        ),
        type: <span className="text-gray-700 text-xs open-sans-regular font-regular"> C Basis </span>,
        plan: <span className="text-gray-700 text-xs open-sans-regular font-regular"> N/A </span>,
        privilege: <span className="text-gray-700 text-xs open-sans-regular font-regular"> Admin</span>,
        owner: <span className="text-blue-700 cursor-pointer text-xs open-sans-regular font-regular">Sheela</span>,
        actions: menuData(),
      },
      {
        default: <Radio aria-label="default" name="default"></Radio>,
        id: (
          <span className="text-blue-700 cursor-pointer text-xs open-sans-regular font-regular">
            {' '}
            CBWEB150520211434233{' '}
          </span>
        ),
        type: <span className="text-gray-700 text-xs open-sans-regular font-regular"> C Basis </span>,
        plan: <span className="text-gray-700 text-xs open-sans-regular font-regular"> N/A </span>,
        privilege: <span className="text-gray-700 text-xs open-sans-regular font-regular"> Admin</span>,
        owner: <span className="text-blue-700 cursor-pointer text-xs open-sans-regular font-regular">Sheela</span>,
        actions: menuData(),
      },
      {
        default: <Radio aria-label="default" name="default"></Radio>,
        id: (
          <span className="text-blue-700 cursor-pointer text-xs open-sans-regular font-regular">
            {' '}
            CBWEB150520211434233{' '}
          </span>
        ),
        type: <span className="text-gray-700 text-xs open-sans-regular font-regular"> C Basis </span>,
        plan: <span className="text-gray-700 text-xs open-sans-regular font-regular"> N/A </span>,
        privilege: <span className="text-gray-700 text-xs open-sans-regular font-regular"> Admin</span>,
        owner: <span className="text-blue-700 cursor-pointer text-xs open-sans-regular font-regular">Sheela</span>,
        actions: menuData(),
      },
      {
        default: <Radio aria-label="default" name="default"></Radio>,
        id: (
          <span className="text-blue-700 cursor-pointer text-xs open-sans-regular font-regular">
            {' '}
            CBWEB150520211434233{' '}
          </span>
        ),
        type: <span className="text-gray-700 text-xs open-sans-regular font-regular"> C Basis </span>,
        plan: <span className="text-gray-700 text-xs open-sans-regular font-regular"> N/A </span>,
        privilege: <span className="text-gray-700 text-xs open-sans-regular font-regular"> Admin</span>,
        owner: <span className="text-blue-700 cursor-pointer text-xs open-sans-regular font-regular">Sheela</span>,
        actions: menuData(),
      },
      {
        default: <Radio aria-label="default" name="default"></Radio>,
        id: (
          <span className="text-blue-700 cursor-pointer text-xs open-sans-regular font-regular">
            {' '}
            CBWEB150520211434233{' '}
          </span>
        ),
        type: <span className="text-gray-700 text-xs open-sans-regular font-regular"> C Basis </span>,
        plan: <span className="text-gray-700 text-xs open-sans-regular font-regular"> N/A </span>,
        privilege: <span className="text-gray-700 text-xs open-sans-regular font-regular"> Admin</span>,
        owner: <span className="text-blue-700 cursor-pointer text-xs open-sans-regular font-regular">Sheela</span>,
        actions: menuData(),
      },
      {
        default: <Radio aria-label="default" name="default"></Radio>,
        id: (
          <span className="text-blue-700 cursor-pointer text-xs open-sans-regular font-regular">
            {' '}
            CBWEB150520211434233{' '}
          </span>
        ),
        type: <span className="text-gray-700 text-xs open-sans-regular font-regular"> C Basis </span>,
        plan: <span className="text-gray-700 text-xs open-sans-regular font-regular"> N/A </span>,
        privilege: <span className="text-gray-700 text-xs open-sans-regular font-regular"> Admin</span>,
        owner: <span className="text-blue-700 cursor-pointer text-xs open-sans-regular font-regular">Sheela</span>,
        actions: menuData(),
      },
      {
        default: <Radio aria-label="default" name="default"></Radio>,
        id: (
          <span className="text-blue-700 cursor-pointer text-xs open-sans-regular font-regular">
            {' '}
            CBWEB150520211434233{' '}
          </span>
        ),
        type: <span className="text-gray-700 text-xs open-sans-regular font-regular"> C Basis </span>,
        plan: <span className="text-gray-700 text-xs open-sans-regular font-regular"> N/A </span>,
        privilege: <span className="text-gray-700 text-xs open-sans-regular font-regular"> Admin</span>,
        owner: <span className="text-blue-700 cursor-pointer text-xs open-sans-regular font-regular">Sheela</span>,
        actions: menuData(),
      },
    ],
    []
  );
  const columns = React.useMemo(
    () => [
      {
        Header: 'Default',
        accessor: 'default',
        width: '5%',
      },
      {
        Header: 'ID',
        accessor: 'id',
        width: '20%',
      },
      {
        Header: 'Type',
        accessor: 'type',
        width: '10%',
      },
      {
        Header: 'Plan',
        accessor: 'plan',
        width: '10%',
      },
      {
        Header: 'Privilege',
        accessor: 'privilege',
        width: '13%',
      },
      {
        Header: 'Owner',
        accessor: 'owner',
        width: '15%',
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        width: '10%',
      },
    ],
    []
  );

  return (
    <>
      <PageHeader title="Licence Management" button={addButton} />
      <MyTable data={data} columns={columns} />
    </>
  );
}

export default Index;

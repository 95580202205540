import React, { useState, useEffect, useRef } from 'react';
import * as yup from 'yup';
import { Field, Formik, useFormik } from 'formik';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { FormControl, Input, TextareaAutosize, MenuItem, Select, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { addJiraAccount, updateJiraBasedOnID } from '@api/api_services';
import MyInput from '@src/pages/user_config/Inputs/MyInput';
import { useAlert } from '@src/pages/common/alert_service/useAlert';

function JiraIntegrationModal(props) {
  const [openModal, setOpenModal] = useState(true);
  const { AlertContatiner, MyAlert } = useAlert();
  const domintimeRef = useRef();
  const handleClose = () => {
    props.closeModal(false);
    setOpenModal(false);
  };
  let initialValues;
  if (props.mode !== 'ADD') {
    initialValues = {
      userName: props.data.userName,
      serverUrl: props.data.serverUrl,
      domain: props.data.domain,
      apiToken: props.data.apiToken,
      accountId: props.data.accountId,
    };
  } else {
    initialValues = { userName: '', serverUrl: '', domain: '', apiToken: '', accountId: '' };
  }
  const validationSchema = yup.object({
    userName: yup
      .string()
      .ensure()
      .required('Username is required')
      .matches(/^(?! ).*[^ ]$/, 'White spaces are not allowed at beginning and at end'),
    serverUrl: yup
      .string()
      .ensure()
      .required('Server URL is required')
      .matches(/^(?! ).*[^ ]$/, 'White spaces are not allowed at beginning and at end'),
    apiToken: yup
      .string()
      .ensure()
      .required('API token is required')
      .matches(/^(?! ).*[^ ]$/, 'White spaces are not allowed at beginning and at end'),
    domain: yup
      .string()
      .ensure()
      .required('Domain is required')
      .matches(/^(?! ).*[^ ]$/, 'White spaces are not allowed at beginning and at end'),
    accountId: yup
      .string()
      .ensure()
      .required('Account ID is required')
      .matches(/^(?! ).*[^ ]$/, 'White spaces are not allowed at beginning and at end'),
  });

  const onSubmit = () => {
    if (props.mode === 'ADD' || props.mode === 'ADD_SUB') {
      createAccout();
    } else {
      updateAccount();
    }
  };
  const formikDetails = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const updateAccount = () => {
    if (
      formikDetails.values.apiToken !== '' &&
      formikDetails.values.userName !== '' &&
      formikDetails.values.serverUrl !== '' &&
      formikDetails.values.domain !== '' &&
      formikDetails.values.accountId
    ) {
      let data = {
        serverUrl: formikDetails.values.serverUrl,
        userName: formikDetails.values.userName,
        apiToken: formikDetails.values.apiToken,
        domain: formikDetails.values.domain,
        accountId: formikDetails.values.accountId,
      };
      if (
        props?.data?.serverUrl === data?.serverUrl &&
        props?.data?.apiToken === data?.apiToken &&
        props?.data?.userName === data?.userName &&
        props?.data?.domain === data?.domain &&
        props?.data?.accountId === data?.accountId
      ) {
        MyAlert.info('No changes were done');
        return;
      }
      updateJiraBasedOnID(data, props.data._id).then((response) => {
        if (response.data.responseCode === 400) {
          // formikDetails.setFieldError("domain", response.data.message);
          MyAlert.warning(response.data.message);
        } else if (response.data.responseCode === 200) {
          setOpenModal(false);
          props.MyAlert.success(`Account is updated successfully`);
          props.closeModal(false);
          props.reloadTree();
        }
      });
    }
  };
  const createAccout = () => {
    if (
      formikDetails.values.apiToken !== '' &&
      formikDetails.values.userName !== '' &&
      formikDetails.values.serverUrl !== '' &&
      formikDetails.values.domain !== '' &&
      formikDetails.values.accountId
    ) {
      let data = {
        serverUrl: formikDetails.values.serverUrl,
        userName: formikDetails.values.userName,
        apiToken: formikDetails.values.apiToken,
        domain: formikDetails.values.domain,
        accountId: formikDetails.values.accountId,
      };
      addJiraAccount(data).then((response) => {
        if (response.data.responseCode === 400) {
          MyAlert.warning(response.data.message);
        } else if (response.data.responseCode === 201 && response.data.message === 'SUCCESS') {
          setOpenModal(false);
          props.MyAlert.success(`Account is created successfully`);
          props.closeModal(false);
          props.reloadTree();
        }
      });
    }
  };
  useEffect(() => {
    if (domintimeRef.current) {
      clearTimeout(domintimeRef.current);
    }

    domintimeRef.current = setTimeout(() => {
      try {
        new URL(formikDetails.values.serverUrl);
        const regex = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/\n]+)/gim;
        const domainValue = regex.exec(formikDetails.values.serverUrl);
        if (domainValue && domainValue?.length > 0) {
          formikDetails.setFieldValue('domain', domainValue[1].split('.', 1)[0]);
        } else {
          formikDetails.setFieldValue('domain', '');
        }
      } catch (err) {
        formikDetails.setFieldValue('domain', '');
      }
    }, 500);
    return () => clearTimeout(domintimeRef.current);
  }, [formikDetails.values.serverUrl]);

  return (
    <div>
      <Modal open={openModal} onClose={handleClose} className="modal-dialog">
        <div className="modal-container browserStack-check-modal browserStack_check_modal">
          <div className="alert-position-style">
            <AlertContatiner></AlertContatiner>
          </div>
          <div className="modal-header">
            {props.mode === 'ADD' || props.mode === 'ADD_SUB' ? (
              <h2 className="title"> Create Account</h2>
            ) : (
              <div className="title title-trim" title={props.data.domain}>
                <h2 className="title"> Edit Account - {props.data.domain}</h2>
              </div>
            )}
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                props.closeModal(false);
                setOpenModal(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="modal-body">
            <form onSubmit={formikDetails.handleSubmit}>
              <div className="grid grid-cols-2 gap-4">
                <div className="mb-5">
                  <MyInput
                    error={formikDetails.errors.accountId && formikDetails.touched.accountId}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={
                      <>
                        {' '}
                        <span className="text-red-400 mr-1">&#42;</span> Account ID
                      </>
                    }
                    size="40"
                    autoFocus={false}
                    className=" w-60 "
                    type="text"
                    autoComplete="off"
                    name="accountId"
                    id="accountId"
                    placeholder={`Enter Account ID`}
                    onBlur={formikDetails.handleBlur}
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.accountId}
                  />
                  {formikDetails.errors.accountId && formikDetails.touched.accountId ? (
                    <div className="fontPoppinsRegularSm errorMessage absolute w-60">
                      {formikDetails.errors.accountId}
                    </div>
                  ) : null}
                </div>
                <div>
                  <MyInput
                    error={formikDetails.errors.serverUrl && formikDetails.touched.serverUrl}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={
                      <>
                        {' '}
                        <span className="text-red-400 mr-1">&#42;</span> Server URL
                      </>
                    }
                    autoFocus={false}
                    size="40"
                    className=" w-60 "
                    type="text"
                    autoComplete="off"
                    name="serverUrl"
                    id="serverUrl"
                    placeholder={`Enter Server URL`}
                    onBlur={formikDetails.handleBlur}
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.serverUrl}
                  />
                  {formikDetails.errors.serverUrl && formikDetails.touched.serverUrl ? (
                    <div className="fontPoppinsRegularSm errorMessage absolute w-60">
                      {formikDetails.errors.serverUrl}
                    </div>
                  ) : null}
                </div>
                <div className="mb-5">
                  <MyInput
                    error={formikDetails.errors.userName && formikDetails.touched.userName}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={
                      <>
                        {' '}
                        <span className="text-red-400 mr-1">&#42;</span>
                        User name
                      </>
                    }
                    size="40"
                    className="w-60 "
                    type="text"
                    autoComplete="off"
                    autoFocus={false}
                    name="userName"
                    id="userName"
                    placeholder={`Enter userName`}
                    onBlur={formikDetails.handleBlur}
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.userName}
                  />
                  {formikDetails.errors.userName && formikDetails.touched.userName ? (
                    <div className="fontPoppinsRegularSm errorMessage absolute w-60">
                      {formikDetails.errors.userName}
                    </div>
                  ) : null}
                </div>
                <div className="mb-5">
                  <MyInput
                    disabled={true}
                    error={formikDetails.errors.domain && formikDetails.touched.domain}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={
                      <>
                        {' '}
                        <span className="text-red-400 mr-1">&#42;</span> Domain
                      </>
                    }
                    size="40"
                    className="w-60 "
                    type="text"
                    autoComplete="off"
                    name="domain"
                    id="domain"
                    autoFocus={false}
                    placeholder={`Enter Domain`}
                    onBlur={formikDetails.handleBlur}
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.domain}
                  />
                  {formikDetails.errors.domain && formikDetails.touched.domain ? (
                    <div className="fontPoppinsRegularSm errorMessage absolute w-60">{formikDetails.errors.domain}</div>
                  ) : null}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 mt-4"></div>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <div className="mb-9">
                  <MyInput
                    error={formikDetails.errors.apiToken && formikDetails.touched.apiToken}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={
                      <>
                        {' '}
                        <span className="text-red-400 mr-1">&#42;</span>
                        API Token
                      </>
                    }
                    size="40"
                    className="w-60 "
                    type="password"
                    autoComplete="new-password"
                    autoFocus={false}
                    name="apiToken"
                    id="apiToken"
                    placeholder={`Enter API Token`}
                    onBlur={formikDetails.handleBlur}
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.apiToken}
                  />
                  {formikDetails.errors.apiToken && formikDetails.touched.apiToken ? (
                    <div className="fontPoppinsRegularSm errorMessage absolute w-60">
                      {formikDetails.errors.apiToken}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  className="gray-btn mr-3"
                  type="button"
                  onClick={() => {
                    props.closeModal(false);
                    setOpenModal(false);
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="primary-btn">
                  {props.mode === 'ADD' || props.mode === 'ADD_SUB' ? <span>Create</span> : <span>Update</span>}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default JiraIntegrationModal;

import { useState, createContext, useRef } from 'react';
import { LinearProgress } from '@mui/material';
import cx from 'classnames';
import style from '@styles/progress-container.module.scss';

export const ProgressContainerContext = createContext();

function ProgressContainer({ children }) {
  const [progress, setProgress] = useState(0);
  const progressInterval = useRef();

  function resetProgress() {
    clearInterval(progressInterval.current);
    setProgress(0);
  }

  function startOrResumeProgress({
    stopAt = 100,
    onProgressComplete = () => {},
    intervalDuration = 500,
    isBulkLoad = false,
  }) {
    const endPointNumber = Math.min(stopAt, 100);
    progressInterval.current = setInterval(() => {
      setProgress((oldProgress) => {
        if (isBulkLoad && 65 <= oldProgress && oldProgress <= 85) {
          return oldProgress + Math.random();
        }
        if (oldProgress < endPointNumber) {
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, endPointNumber);
        }
        clearInterval(progressInterval.current);
        onProgressComplete();
        return oldProgress > 99 ? 0 : oldProgress;
      });
    }, intervalDuration);
  }

  function stopProgress() {
    clearInterval(progressInterval.current);
  }

  return (
    <ProgressContainerContext.Provider value={{ resetProgress, startOrResumeProgress, stopProgress }}>
      {progress > 0 && (
        <div className={cx('sticky top-0', style['z-2'])}>
          <LinearProgress variant="determinate" value={progress} />
        </div>
      )}
      <div className={cx({ [style['progress-container']]: progress > 0 })}>{children}</div>
    </ProgressContainerContext.Provider>
  );
}

export default ProgressContainer;

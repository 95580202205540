import React, { useEffect } from 'react';

const MachineDetailsPage = (props) => {
  const [selectedClientData, setSelectedClientData] = React.useState();
  useEffect(() => {
    if (props?.selectedClientData) {
      let tempObj = { ...props?.selectedClientData };
      if (props?.selectedClientData?.deviceInfo?.length) {
        let tempDeviceArray = props?.selectedClientData?.deviceInfo;
        let devName;
        tempDeviceArray?.map((obj) => {
          if (devName) {
            devName = devName + ',' + obj?.name;
          } else {
            devName = obj?.name;
          }
        });
        tempObj['concatenatedDevName'] = devName;
        setSelectedClientData({ ...tempObj });
      } else {
        setSelectedClientData({ ...props?.selectedClientData });
      }
    }
  }, []);

  return (
    <>
      <div class="grid grid-cols-4 gap-4 user_details_main_style">
        <div>
          <label className="user_key_text_style">Os</label>
          <label className="user_data_text_style">{selectedClientData?.machineInfo?.osName}</label>
        </div>
        <div>
          <label className="user_key_text_style">Browser</label>
          <label className="user_data_text_style">{selectedClientData?.browserName}</label>
        </div>
        <div>
          <label className="user_key_text_style">Device</label>
          <label className="user_data_text_style">{selectedClientData?.concatenatedDevName || '--'}</label>
        </div>
        <div>
          <label className="user_key_text_style">Owner</label>
          <label className="user_data_text_style">{props?.ownerName || '--'}</label>
        </div>
      </div>
      <div className="border-style"></div>
    </>
  );
};

export default MachineDetailsPage;

import React, { useState } from 'react';
import Split_Editor_Left from './split_left_editor';
import Split_Editor_Right from './split_right_editor';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import GroupIconPreRequisite from '@pages/test-development/script/scripts/webservice/restapi/request/prerequisite/images/GroupIconPreRequisite.svg';
import { PREREQUISITE_CONSTANT } from '@pages/test-development/script/scripts/webservice/restapi/request/prerequisite/jsx/constant';
import '@pages/test-development/script/scripts/webservice/restapi/request/request_body/css/request_body.css';
export default function GraphQl() {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <div>
      <Modal open={open} className="modal-dialog">
        <div className="modal-container page-modal-size1">
          <div className="modal-header">
            <h2 className="title">Query</h2>

            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="modal-body" id="journal-scroll">
            <div>
              <Split_Editor_Left />
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="cancel-button-common cancel-button-layout-style-project mr-3 fontPoppinsRegularLg"
              onClick={() => {
                setOpen(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
      <div className="pt-3 pb-3 border_style flex flex-wrap items-center gap-of-request-form-data-header h-20 background-graphql-header">
        <span className="ml-5"></span>
      </div>
      <div className="grid grid-cols-2">
        <div className="graphql-header-height">
          <div className={`${PREREQUISITE_CONSTANT.CLASSNAME_PREREQUISITE_TWO} h-7 background-graphql-header relative`}>
            <span className="w-10/12 header-text-in-graphql fontPoppinsSemiboldMd">Query</span>
            <button className="beautify-text-in-graphql fontPoppinsRegularSm">Beautify</button>
            <div className="GroupIconPreRequisiteSection">
              <img
                className="prerequisite-group-icon"
                src={GroupIconPreRequisite}
                alt="showmore"
                width="12"
                onClick={(e) => {
                  handleClickOpen();
                }}
              />
            </div>
          </div>
          <Split_Editor_Left />
        </div>
        <div className="graphql-header-height">
          <div className={`${PREREQUISITE_CONSTANT.CLASSNAME_PREREQUISITE_TWO} h-7 background-graphql-header`}>
            <span className="w-10/12 header-text-in-graphql fontPoppinsSemiboldMd">GraphQL Variables</span>
            <button className="beautify-text-in-graphql fontPoppinsRegularSm">Beautify</button>
            <div className="GroupIconPreRequisiteSection">
              <img
                className="prerequisite-group-icon"
                src={GroupIconPreRequisite}
                alt="showmore"
                width="12"
                onClick={(e) => {
                  handleClickOpen();
                }}
              />
            </div>
          </div>
          <Split_Editor_Right />
        </div>
      </div>
    </div>
  );
}

import React, { Fragment, useState, useEffect, useRef, useContext } from 'react';
import PreRequisiteEditor from './prerequisite_editor';
import '../css/Prerequisite_Editor.css';
import useToggle from '@src/hooks/useToggle';
import { getPreSnippet, postCompileAPI } from '@api/api_services';
import ArrowExpand from '../images/arrow_expand.svg';
import ArrowCollapse from '../images/arrow_collapased.svg';
import ArrowExpandWhite from '../images/arrow-expand-white.svg';
import ArrowCollapseWhite from '../images/arrow-collapased-white.svg';
import '@pages/test-development/script/scripts/webservice/restapi/css/common_Webservice.scss';
import { PREREQUISITE_CONSTANT } from '../jsx/constant';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { RestContext } from '@pages/test-development/script/scripts/webservice/restapi/restapi';
import { colors } from '@src/css_config/colorConstants';
import ExpandIcon from '@pages/test-development/script/scripts/webservice/restapi/request/assert/images/expand-icon-1.svg';
import CollapseIcon from '@pages/test-development/script/scripts/webservice/restapi/request/assert/images/expand-icon-2.svg';
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';

const PreRequisite = (props) => {
  const [isOpen, toggle] = useToggle(PREREQUISITE_CONSTANT.TRUE);
  const [projectTypeList, setProjectTypeList] = useState(PREREQUISITE_CONSTANT.EMPTY_ARRAY);
  const [IsConsoleVisible, setConsoleVisible] = useState(PREREQUISITE_CONSTANT.FALSE);
  const [message, setMessage] = useState(PREREQUISITE_CONSTANT.NO_LOG_YET);
  const childRef = useRef();
  const [aceValue, setAceValue] = useState(PREREQUISITE_CONSTANT.EMPTY_STRING);
  const [disableButton, setDisableButton] = useState(true);
  const [openValue, setOpenValue] = useState(PREREQUISITE_CONSTANT.FALSE);
  let [expandClick, setExpandClick] = useState(false);
  const context = useContext(RestContext);

  useEffect(() => {
    getSnippetValue();
  }, []);

  useEffect(() => {
    getStepsUpdate();
  }, [props.selectedStepData]);
  useEffect(() => {
    setExpandClick(false);
  }, [props.prerequisiteStatus]);

  const getStepsUpdate = () => {
    const tempParmvalue = props.selectedStepData;
    const code = tempParmvalue?.preStep?.program?.codeSnippet?.code;
    if (Object.keys(props.selectedStepData).length > 0 && code) {
      const preRequisiteCodeValue = props.getUserInputValue(code, true);
      childRef.current.showAlert(preRequisiteCodeValue, true);
      context.values.preRequisiteCodeValue = code;
      setDisableButton(false);
    } else if (!(PREREQUISITE_CONSTANT.PREREQUISITECODEVALUE in context.values)) {
      context.values.preRequisiteCodeValue = PREREQUISITE_CONSTANT.ONlOAD_CHANGE;
      context.values.preCustomSnippetValue = [];
    }
  };

  const getCodeValue = (codeUI, value) => {
    setAceValue(codeUI);
    const preRequisiteCodeValue = props.getUserInputValue(value, false);
    const getPreCustomSnippetValue = onCheckCustomSnippetValue(codeUI);
    context.setFieldValue('preRequisiteCodeValue', preRequisiteCodeValue);
    context.setFieldValue('preCustomSnippetValue', getPreCustomSnippetValue);
  };

  const onCheckCustomSnippetValue = (code) => {
    let newCustomSnippetValue = [];
    if (projectTypeList.length > 0) {
      projectTypeList?.map((value) => {
        if (value?.customSnippetDefinition) {
          if (code.includes(value?.customSnippet)) {
            newCustomSnippetValue.push(value?.customSnippetDefinition);
          }
        }
      });
    }
    return newCustomSnippetValue;
  };

  const compileAndSave = (value) => {
    value ? setDisableButton(false) : setDisableButton(true);
  };

  const toggleAccordionInsideRequest = () => {
    setConsoleVisible(PREREQUISITE_CONSTANT.FALSE);
  };

  const toggleAccordionRequest = () => {
    setConsoleVisible(PREREQUISITE_CONSTANT.TRUE);
  };
  const getSnippetValue = async () => {
    getPreSnippet().then((response) => {
      let snippetResponse = response.data.success;
      setProjectTypeList(snippetResponse);
    });
  };

  const handlelogic = (row) => {
    let defaultSnippet = row?.defaultSnippet?.trim();
    let customSnippet = row?.customSnippet?.trim();
    if (defaultSnippet) {
      childRef.current.showAlert(defaultSnippet, false);
    } else {
      childRef.current.showAlert(customSnippet, false);
      let updateCustomSnippetValue = [];
      if (context?.values?.preCustomSnippetValue && context?.values?.preCustomSnippetValue.length > 0) {
        updateCustomSnippetValue = [...context.values.preCustomSnippetValue, row?.customSnippetDefinition];
      } else {
        updateCustomSnippetValue.push(row?.customSnippetDefinition);
      }
      const uniqueCustomSnippetValue = [...new Set(updateCustomSnippetValue)];
      context.setFieldValue('preCustomSnippetValue', uniqueCustomSnippetValue);
    }
    setDisableButton(false);
  };

  const CompileAPI = () => {
    const data = {
      code: context.values.preRequisiteCodeValue,
      customSnippetDefinitions: context.values.preCustomSnippetValue,
    };
    postCompileAPI(data)
      .then((response) => {
        setConsoleVisible(PREREQUISITE_CONSTANT.TRUE);
        if (
          response.data.success === PREREQUISITE_CONSTANT.NULL &&
          response.data.errors != PREREQUISITE_CONSTANT.NULL
        ) {
          props.MyAlert.danger(`There has been an error detected in the code .Do clear it before proceed`);
          setMessage(response.data.errors.details);
        } else if (
          response.data.success === PREREQUISITE_CONSTANT.NULL &&
          response.data.errors === PREREQUISITE_CONSTANT.NULL
        ) {
          setMessage(PREREQUISITE_CONSTANT.COMPILATION_SUCCESS);
        }
      })
      .catch((error) => { });
  };

  const toggleClearClick = () => {
    setMessage(PREREQUISITE_CONSTANT.EMPTY_STRING);
  };

  return (
    <Fragment>
      <Modal open={openValue} className="modal-dialog">
        <div className="modal-container page-modal-size1">
          <div className="modal-header">
            <h2 className="title">{`PreRequisite`}</h2>
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                setOpenValue(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="modal-body" id="journal-scroll">
            <div
              className="console_output_container_Response fontPoppinsRegularMd overflow-y-auto shadow"
              id="journal-scroll"
            >
              {aceValue}
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="cancel-button-common cancel-button-layout-style-project mr-3 fontPoppinsRegularMd"
              onClick={() => {
                setOpenValue(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
      <div className={`${PREREQUISITE_CONSTANT.PREREQUISITE_LEFT_SIDEBAR} prerequisite-left-sidebar-border`}>
        <div className="prerequisite-sub-left-sidebar">
          <div
            className={isOpen ? 'prerequisite-left-side-bar-section-one' : 'prerequisite-left-side-bar-section-hide'}
          >
            <>
              {isOpen ? (
                <div className="snippet-heading-alignments">
                  <div className={'flex justify-start width-50 fontPoppinsMediumMd '}>
                    <label className={`fontPoppinsSemiboldSm ml-3 select-none`}>
                      {PREREQUISITE_CONSTANT.LABEL_SNIPPETS}
                    </label>
                  </div>
                  <div className={PREREQUISITE_CONSTANT.SNIPPET_INSIDE_DIV}>
                    <MenuIcon onClick={toggle} />
                  </div>
                </div>
              ) : (
                <div className="cursor-pointer rotate-toggle-menu">
                  <MenuOpenIcon onClick={toggle} />
                </div>
              )}
            </>
          </div>
          <div className={`${PREREQUISITE_CONSTANT.CLASSNAME_PREREQUISITE_TWO}`}>
            <button
              disabled={disableButton}
              className={`compile-save-button-customization fontMontserratMediumXs ${disableButton ? 'pre-disabled-btn' : 'pre-enabled-btn prerequisite-button-structure-active'
                }`}
              onClick={() => {
                CompileAPI();
              }}
            >
              {PREREQUISITE_CONSTANT.COMPILE_AND_SAVE}
            </button>
            {
              <CustomTooltip title={expandClick ? 'collapse' : 'expand'} placement="bottom">
                <div className="pl-2 pr-2">
                  <img
                    className="prerequisite-group-icon w-full"
                    draggable="false"
                    src={expandClick ? CollapseIcon : ExpandIcon}
                    alt="showmore"
                    width="12"
                    onClick={(e) => {
                      setExpandClick(!expandClick);
                    }}
                  />
                </div>
              </CustomTooltip>
            }
          </div>
        </div>
      </div>
      <div className={`${PREREQUISITE_CONSTANT.PREREQUISITE_MAIN_CONTAINER} relative w-full`} draggable="false">
        {isOpen && (
          <div className={`prerequisite-main-container-section-sub prerequisite--vertical-scroll`}>
            <div className="mt-2 ml-2 mr-1.5 mb-3">
              <div stickyHeader aria-label={PREREQUISITE_CONSTANT.STICKY_TABLE}>
                <div>
                  {projectTypeList?.length > 0 &&
                    projectTypeList.map((row, index) => (
                      <>
                        <div
                          className={`cursor-pointer prerequisite-left-side-panel`}
                          key={`${row.id}`}
                          onClick={() => {
                            handlelogic(row);
                          }}
                        >
                          <CustomTooltip
                            title={row.name}
                            placement="right"
                          >
                            <span className="select-none fontPoppinsRegularSm truncate prerequisite-left-side-text-customization">
                              <span className="mr-2 ml-2">
                                <AddIcon />
                              </span>
                              {row.name}
                            </span>
                          </CustomTooltip>
                        </div>
                        <span className="h-2 flex"></span>
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          className={
            isOpen
              ? `${PREREQUISITE_CONSTANT.PREREQUISITE_MAIN_CONTAINER_SECTION_TWO} prerequisite-auto-scroll-bar pre-requisite-editor`
              : PREREQUISITE_CONSTANT.PREREQUISITE_MAIN_CONTAINER_FULL_SECTION
          }
        >
          <div className="prerequisite-main-container-sub prerequisite-main-container-sub-height">
            <div
              className={`${expandClick ? 'expand-editor-alignment-prerequisite-editor' : 'prerequisite-editor-wrapper'
                } ${IsConsoleVisible && !expandClick
                  ? 'prerequisiteEditor-height-with-console-expand'
                  : expandClick
                    ? 'prerequisiteEditor-height-with-expand'
                    : 'prerequisiteEditor-height'
                } relative`}
            >
              <PreRequisiteEditor
                ref={childRef}
                isOpen={isOpen}
                getCodeValue={getCodeValue}
                compileAndSave={compileAndSave}
                className="prerequisite-editor-customization"
                preRequisiteCodeValue={context.values?.preRequisiteCodeValue}
                {...props}
              />
            </div>

            <div className={expandClick ? 'h-60' : 'h-0'}></div>
            {IsConsoleVisible === PREREQUISITE_CONSTANT.TRUE ? (
              <>
                <div className={`${PREREQUISITE_CONSTANT.CONSOLE_DIV_TWO}`}>
                  <label className={`${PREREQUISITE_CONSTANT.CONSOLE_LABEL_ONE} mb-4`}>
                    {PREREQUISITE_CONSTANT.CONSOLE_LABEL}
                  </label>
                  <div className={PREREQUISITE_CONSTANT.PR_2}>
                    <div className={PREREQUISITE_CONSTANT.CLEAR_BUTTON_DIV}>
                      <button
                        className={PREREQUISITE_CONSTANT.SECONDARY_CLEAR_BUTTON}
                        onClick={() => {
                          toggleClearClick();
                        }}
                      >
                        <span className="text-white">{PREREQUISITE_CONSTANT.CLEAR_LABEL}</span>
                      </button>
                      <CustomTooltip title="Collapse" placement="bottom">
                        <img
                          className={` ml-2 pre-requisite-isVisible-icon-rotate`}
                          src={ArrowExpandWhite}
                          alt={PREREQUISITE_CONSTANT.SHOWMORE}
                          width={PREREQUISITE_CONSTANT.WIDTH_20}
                          onMouseEnter={(e) => {
                            e.target.style.backgroundColor = `${colors.text_white}`;
                            e.target.style.borderRadius = '50px';
                            e.target.src = ArrowExpand;
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = 'initial';
                            e.target.src = ArrowExpandWhite;
                          }}
                          onClick={() => {
                            toggleAccordionInsideRequest();
                          }}
                        />
                      </CustomTooltip>
                    </div>
                  </div>
                </div>
                <div
                  className={`${PREREQUISITE_CONSTANT.CONSOLE_OUTPUT_CONTAINER}`}
                  id={PREREQUISITE_CONSTANT.ID_JOURNAL}
                >
                  <div className="console-output fontPoppinsRegularMd mt-1.5 ml-4 text-left">{message}</div>
                </div>
              </>
            ) : (
              <div className={`${PREREQUISITE_CONSTANT.CONSOLE_DIV}`}>
                <label className={PREREQUISITE_CONSTANT.CONSOLE_CLASSNAME}>{PREREQUISITE_CONSTANT.CONSOLE_LABEL}</label>
                <div className={PREREQUISITE_CONSTANT.PR_2}>
                  <CustomTooltip title="Expand" placement="bottom">
                    <img
                      src={ArrowCollapseWhite}
                      alt={PREREQUISITE_CONSTANT.SHOWMORE}
                      width={PREREQUISITE_CONSTANT.WIDTH_25}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = `${colors.text_white}`;
                        e.target.style.borderRadius = '50px';
                        e.target.src = ArrowCollapse;
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = 'initial';
                        e.target.src = ArrowCollapseWhite;
                      }}
                      onClick={() => {
                        toggleAccordionRequest();
                      }}
                    />
                  </CustomTooltip>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="prerequisite-blank-div"></div>
      </div>
    </Fragment>
  );
};

export default PreRequisite;

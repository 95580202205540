import React from 'react';
import { withRouter } from 'react-router';
import Tab from '../common/tab';
import Result from '../results/result';
import { useAlert } from '../common/alert_service/useAlert';
import ModuleDetailsPage from './suites/details-child-pages/module-details-page';
import ExecutionSpecificPageParallelExecution from './suites/details-child-pages/execution-specific-page-parallel-execution';
import ExecutionSpecificPageDistributeExecution from './suites/details-child-pages/execution-specific-page-distribute-execution';
import ExecutionSpecificPageSos from './suite-of-suite/detailsChildPages/execution-specific-page-sos';
import Tooltip from '@material-ui/core/Tooltip';
let urlSuiteId;
let detailsPage;
let suiteType;
function ExecutionPage(props) {
  const { AlertContatiner, MyAlert } = useAlert();
  let search = window.location.search;

  // const [urlSuiteId, setUrlSuiteId] = React.useState();
  let urlSuiteIdValue = new URLSearchParams(search).get('suiteId');
  urlSuiteIdValue != null
    ? (urlSuiteId = urlSuiteIdValue)
    : (urlSuiteId = JSON.parse(localStorage.getItem('selectedSuiteObj'))?._id);

  let suiteTypeValue = new URLSearchParams(search).get('suiteType');
  suiteTypeValue != null
    ? (suiteType = suiteTypeValue)
    : (suiteType = JSON.parse(localStorage.getItem('selectedSuiteObj'))?.suiteType);

  detailsPage = new URLSearchParams(search).get('detailsPage');
  const selectedSuiteOfSuiteName = window.location.pathname.split('/')[3];
  const detailsExist = window.location.pathname.split('/')[4];
  const splittedPathNameArray = window.location.pathname.split('/');
  const ifResultPath = splittedPathNameArray[splittedPathNameArray.length - 1];

  if (detailsExist === 'details' || detailsExist === 'dashboard' || detailsExist === 'scheduled-instance') {
    var logoArray = [
      {
        name: 'Details',
        selectedLogo: '/assets/images/project_rec_logo_blue.svg',
        selectedName: 'details',
        pathUrl: `/execution/suiteofsuite/${selectedSuiteOfSuiteName}/details?suiteId=${urlSuiteId}`,
      },
      {
        name: 'Execution Dashboard',
        selectedLogo: '/assets/images/user_logo_blue.svg',
        selectedName: 'dashboard',
        pathUrl: `/execution/suiteofsuite/${selectedSuiteOfSuiteName}/dashboard?suiteId=${urlSuiteId}`,
      },
      {
        name: 'Scheduled Instances',
        selectedLogo: '/assets/images/role_logo_blue.svg',
        selectedName: 'scheduled-instance',
        pathUrl: `/execution/suiteofsuite/${selectedSuiteOfSuiteName}/scheduled-instance?suiteId=${urlSuiteId}`,
      },
    ];
  } else if (
    detailsExist === 'suite-details' ||
    detailsExist === 'suite-dashboard' ||
    detailsExist === 'suite-scheduled-instance'
  ) {
    logoArray = [
      {
        name: 'Execution Dashboard',
        selectedLogo: '/assets/images/user_logo_blue.svg',
        selectedName: 'suite-dashboard',
        pathUrl: `/execution/suite/${selectedSuiteOfSuiteName}/suite-dashboard?suiteId=${urlSuiteId}&suiteType=${suiteType}`,
      },
      {
        name: 'Scheduled Instances',
        selectedLogo: '/assets/images/role_logo_blue.svg',
        selectedName: 'suite-scheduled-instance',
        pathUrl: `/execution/suite/${selectedSuiteOfSuiteName}/suite-scheduled-instance?suiteId=${urlSuiteId}&suiteType=${suiteType}`,
      },
      {
        name: 'Details',
        selectedLogo: '/assets/images/project_rec_logo_blue.svg',
        selectedName: 'suite-details',
        pathUrl: `/execution/suite/${selectedSuiteOfSuiteName}/suite-details?suiteId=${urlSuiteId}&suiteType=${suiteType}`,
      },
    ];

    if (suiteType === 'Manual') {
      logoArray.splice(1, 1);
    }
  } else {
    logoArray = [
      {
        name: 'Suites',
        logo: '/assets/images/project_rec_logo.svg',
        selectedLogo: '/assets/images/project_rec_logo_blue.svg',
        pathUrl: '/execution/suite',
        selectedName: 'suite',
      },

      {
        name: '',
        logo: '/assets/images/user_logo.svg',
        selectedLogo: '/assets/images/user_logo_blue.svg',
        pathUrl: '/execution/suite/:executionName/execution dashboard/:parentModule/results',
        selectedName: '',
      },
      {
        name: '',
        logo: '/assets/images/user_logo.svg',
        selectedLogo: '/assets/images/user_logo_blue.svg',
        pathUrl: '/execution/suite/:executionName/execution dashboard/overview/results',
        selectedName: '',
      },
      {
        name: '',
        logo: '/assets/images/user_logo.svg',
        selectedLogo: '/assets/images/user_logo_blue.svg',
        pathUrl: '/execution/suite/:executionName/execution dashboard/:parentModule/:scriptName/script/results',
        selectedName: '',
      },
      {
        name: '',
        logo: '/assets/images/user_logo.svg',
        selectedLogo: '/assets/images/user_logo_blue.svg',
        pathUrl: '/execution/suite/:executionName/execution dashboard/:scriptName/:dependantScript/script/results',
        selectedName: '',
      },
    ];
  }

  return (
    <>
      <div className="alert-div-position-style">
        <AlertContatiner></AlertContatiner>
      </div>
      {ifResultPath === 'results' || ifResultPath === 'machines' || ifResultPath === 'Result' ? (
        <Result />
      ) : detailsPage && detailsPage === 'moduleDetails' ? (
        <ModuleDetailsPage MyAlert={MyAlert} />
      ) : detailsPage && detailsPage === 'specificExePageParallel' ? (
        <ExecutionSpecificPageParallelExecution MyAlert={MyAlert} />
      ) : detailsPage && detailsPage === 'specificExePageDisytribute' ? (
        <ExecutionSpecificPageDistributeExecution MyAlert={MyAlert} />
      ) : detailsPage && detailsPage === 'moduleDetails_sos' ? (
        <ExecutionSpecificPageSos />
      ) : (
        <Tab props={props} logoArray={logoArray} MyAlert={MyAlert}></Tab>
      )}
    </>
  );
}

export default withRouter(ExecutionPage);

export const renderBrowserCell = (value) => {
  value = value ? value.toLowerCase() : '';
  if (value.includes('opera')) {
    return (
      <Tooltip title={value} placement="top">
        <img src="/assets/images/opera.png" className="float-left mt-1 mr-6 inline-block browser-logo" alt="opera" />
      </Tooltip>
    );
  } else if (['google chrome', 'chrome'].includes(value)) {
    return (
      <Tooltip title={value} placement="top">
        <img src="/assets/images/Chrome.svg" className="float-left mt-1 mr-6 inline-block browser-logo" alt="chrome" />
      </Tooltip>
    );
  } else if (['internet explorer', 'ie', 'iexplorer'].includes(value)) {
    return (
      <Tooltip title={value} placement="top">
        <img src="/assets/images/ie.png" className="float-left mt-1 mr-6 inline-block browser-logo" alt="ie" />
      </Tooltip>
    );
  } else if (['mozilla firefox', 'firefox'].includes(value)) {
    return (
      <Tooltip title={value} placement="top">
        <img
          src="/assets/images/Firefox.svg"
          className="float-left mt-1 mr-6 inline-block browser-logo"
          alt="firefox"
        />
      </Tooltip>
    );
  } else if (value.includes('safari')) {
    return (
      <Tooltip title={value} placement="top">
        <img src="/assets/images/safari.png" className="float-left mt-1 mr-6 inline-block browser-logo" alt="safari" />
      </Tooltip>
    );
  } else if (['iphone', 'ipad'].includes(value)) {
    return (
      <Tooltip title={value} placement="top">
        <img
          src="/assets/images/apple_icon.svg"
          className="float-left mt-1 mr-6 inline-block browser-logo"
          alt="apple"
        />
      </Tooltip>
    );
  } else if (value.includes('android')) {
    return (
      <Tooltip title={value} placement="top">
        <img
          src="/assets/images/android.png"
          className="float-left mt-1 mr-6 inline-block browser-logo"
          alt="android"
        />
      </Tooltip>
    );
  } else if (value.includes('samsung')) {
    return (
      <Tooltip title={value} placement="top">
        <img
          src="/assets/images/samsung.png"
          className="float-left mt-1 mr-6 inline-block browser-logo"
          alt="samsung"
        />
      </Tooltip>
    );
  } else if (['microsoft edge', 'edge', 'microsoftedge'].includes(value)) {
    return (
      <Tooltip title={value} placement="top">
        <img
          src="/assets/images/Microsoft_Edge.svg"
          className="float-left mt-1 mr-6 inline-block browser-logo"
          alt="edge"
        />
      </Tooltip>
    );
  } else {
    return '';
  }
};

import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ExecutionResult from './execution-result';
import { getExecutionTreeExpandAllResultReq } from '@api/api_services';
import { CommonLoader } from '@src/pages/common/common-loader';
import { useAlert } from '@pages/common/alert_service/useAlert';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { getIDB, removeIDB, setIDB } from '@src/util/localForage_idb_controller';
import { encodeSpecialCharater } from '@src/pages/test-development/shared/common-methods';

function ExecutionResultBase(props) {
  const { AlertContatiner, MyAlert } = useAlert();
  const [treeData, setTreeData] = useState([]);
  const [rootData, setRootData] = useState();
  const [infoData, setInfoData] = useState();
  const [chartData, setChartData] = useState();
  const history = useHistory();
  const [platformData, setPlatformData] = useState();
  const [selectedProject, setSelectedProject] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);

  const search = window.location.search;
  const id = new URLSearchParams(search).get('id');
  const executionId = new URLSearchParams(search).get('executionId');
  const runId = new URLSearchParams(search).get('runId');
  const suiteName = window.location.pathname.split('/')[3];
  let urlSuiteId = new URLSearchParams(search).get('suiteId');
  let urlSuiteType = new URLSearchParams(search).get('suiteType');
  let multiple = new URLSearchParams(search).get('multiple');
  const { machineNames } = useParams();
  const [suiteType, setSuiteType] = useState('');

  useEffect(() => {
    setInfoAndChartData();
    if (id) {
      localStorage.setItem('moduleId', id);
    }
    if (!id) {
      // localStorage.removeItem('sub-tree-data');
      removeIDB('tree-data');
    }
    getResultData(id);
  }, [id]);

  const updateSuiteType = (_executionResult) => {
    let _suiteTYPE;
    if (_executionResult && _executionResult?.responseObject?.executionResponses?.length) {
      let actualid = _executionResult.responseObject.executionResponses[0].executionId;
      if (actualid === executionId && _executionResult?.responseObject?.suiteType) {
        _suiteTYPE = _executionResult.responseObject.suiteType;
      }
      setSuiteType(_suiteTYPE);
    }
    return _suiteTYPE;
  };

  const handleStatusChange = () => {
    setInfoAndChartData();
    if (id) {
      localStorage.setItem('moduleId', id);
    }
    if (!id) {
      removeIDB('tree-data');
    }
    getResultData(undefined);
  };

  const getResultData = (id) => {
    setInfoData('');
    setTreeData([]);
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    setIsLoading(true);
    getExecutionTreeExpandAllResultReq(executionId)
      .then((response) => {
        stopProgress();
        if (response.data.responseObject) {
          setIDB('execResult', response.data);
          let _suiteType = updateSuiteType(response.data);
          getTreeData(response.data, id, _suiteType);
        }
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 10,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        resetProgress();
        setIsLoading(false);
        console.error('EXECUTION_RESULT_TREE : ', error);
      });
  };

  const getTreeData = async (response, id, _suiteType) => {
    let returned_data;
    let _executionResult;
    if (multiple && machineNames) {
      let selectedMachineResult;
      if (_suiteType === 'Manual') {
        selectedMachineResult = response.responseObject?.executionResponses.find(
          (_res) => _res?.clientId === machineNames
        );
      } else {
        selectedMachineResult = response.responseObject?.executionResponses.find(
          (_res) => _res?.machine === machineNames
        );
      }
      if (selectedMachineResult) {
        returned_data = selectedMachineResult.children;
        _executionResult = selectedMachineResult;
      }
    } else {
      returned_data = response.responseObject?.executionResponses[0].children;
      _executionResult = response.responseObject?.executionResponses[0];
    }
    if (returned_data) {
      let res;
      let _rootDataForCharts;
      setTreeData([]);
      setRootData({});
      if (_executionResult) {
        let dependentData = _executionResult?.dependantExecutionEntityResponses;
        if (dependentData && dependentData.length) {
          setIDB('dependent-data', dependentData);
        }
        if (_executionResult.executionId) {
          returned_data[0]['executionId'] = _executionResult.executionId;
        }
        if (_executionResult.storageDetails) {
          returned_data[0]['storageDetails'] = _executionResult.storageDetails;
        }
      }
      setIDB('tree-data', returned_data[0]);
      const subTreeData = await getIDB('sub-tree-data');
      let _selectedProject = localStorage.getItem('selected-project');
      const selectedProjectParsed = JSON.parse(_selectedProject ? _selectedProject : '');
      if (selectedProjectParsed) {
        setSelectedProject(selectedProjectParsed);
      }
      if (!id) {
        res = returned_data[0]?.children;
        const { children, ...root_data } = returned_data[0];
        setRootData(root_data);
        _rootDataForCharts = Object.assign(root_data);
        if (_rootDataForCharts?.platformStatistics) {
          setPlatformData(_rootDataForCharts?.platformStatistics);
        }
        setTreeData(res);
        setInfoAndChartData(_rootDataForCharts, response?.responseObject?.Duration);
      } else {
        if (!subTreeData) {
          history.push(`/execution/suite`);
        } else {
          let parsedTreeObject = subTreeData;
          if (!parsedTreeObject.platformStatistics && returned_data[0].children) {
            const _subtree = getSubTree(returned_data[0].children, id);
            if (_subtree) setIDB('sub-tree-data', returned_data[0]);
            parsedTreeObject = _subtree;
          }
          res = [parsedTreeObject];
          const { children, ...root_data } = parsedTreeObject;
          setRootData(root_data);
          _rootDataForCharts = Object.assign(root_data);
          setTreeData(res);
          if (_rootDataForCharts && _rootDataForCharts.status && _rootDataForCharts.platformStatistics) {
            setPlatformData(_rootDataForCharts.platformStatistics);
            setInfoAndChartData(_rootDataForCharts);
          }
        }
      }
    }
  };
  /* to find the sub tree data from the all tree data*/
  const getSubTree = (_children, _child_Id) => {
    for (const _child of _children) {
      if (_child.key === _child_Id) {
        return _child;
      }
      if (_child.children) {
        const recursiveResult = getSubTree(_child.children, _child_Id);
        if (recursiveResult) {
          return recursiveResult;
        }
      }
    }
  };

  const onNodeSelected = (nodeObj) => {
    let executionName = window.location.pathname.split('/')[3];
    let machineNames = window.location.pathname.split('/')[6];
    if (nodeObj.data.folder) {
      const node = nodeObj.node;
      var dict = node.toDict(true, function (dict, node) {
        // Remove partsel, selected because they are not required
        // Remove data object from dict to make it like input json object
        const data = { ...dict.data };
        delete dict.partsel;
        delete dict.selected;
        delete dict.data;
        Object.assign(dict, data);
      });

      localStorage.setItem('moduleId', nodeObj?.node?.key);

      setIDB('sub-tree-data', dict);

      if (
        (suiteType === 'Manual' && nodeObj.node.data.status === 'SKIP') ||
        (nodeObj?.node?.data?.status !== 'SKIP' && nodeObj?.node?.data?.status !== 'N/A')
      ) {
        if (multiple === 'true') {
          history.push({
            pathname: `/execution/suite/${executionName}/execution dashboard/overview/${machineNames}/${dict.selectedSystem.machineInfo.hostName}/Root Module/${nodeObj.node.title}/results`,
            search: `?executionId=${executionId}&id=${nodeObj?.node?.key}&multiple=true&suiteId=${urlSuiteId}&suiteType=${urlSuiteType}`,
          });
        } else {
          history.push({
            pathname: `/execution/suite/${executionName}/execution dashboard/Root Module/${nodeObj?.node?.title}/results`,
            search: `?executionId=${executionId}&id=${nodeObj?.node?.key}&multiple=false&suiteId=${urlSuiteId}&suiteType=${urlSuiteType}`,
          });
        }
      }
    } else {
      if (nodeObj?.node?.type === 'Script') {
        const node = nodeObj?.node;
        const scriptData = [nodeObj?.data];
        const clientId = node.parent.data.selectedSystem.clientId;
        const scriptId = node?.key;
        setIDB('script-data', scriptData);
        if (nodeObj?.node?.data?.status !== 'SKIP' || (suiteType === 'Manual' && nodeObj.node.data.status === 'SKIP')) {
          if (nodeObj?.node?.data?.status === 'N/A' || nodeObj?.node?.data?.status === 'Aborted') {
          } else {
            if (multiple === 'true') {
              history.push({
                pathname: `/execution/suite/${executionName}/execution dashboard/overview/machines/${node.parent.data.selectedSystem.machineInfo.hostName}/Root Module/${rootData?.name ? encodeSpecialCharater(rootData.name) : encodeSpecialCharater(rootData?.title)}/${encodeSpecialCharater(nodeObj.node.title)}/Result`,
                search: `?id=${scriptId}&executionId=${executionId}&suiteId=${urlSuiteId}&clientId=${clientId}${runId ? `&runId=${runId}` : ''}&multiple=true&suiteType=${urlSuiteType}&moduleId=${node.parent.key}&moduleName=${encodeSpecialCharater(node.parent.title)}`,
              });
            } else {
              history.push({
                pathname: `/execution/suite/${executionName}/execution dashboard/Root Module/${encodeSpecialCharater(nodeObj?.node?.title)}/script/results`,
                search: `?id=${scriptId}&executionId=${executionId}&suiteId=${urlSuiteId}&clientId=${clientId}&runId=${runId}&multiple=false&suiteType=${urlSuiteType}&moduleId=${node.parent.key}&moduleName=${encodeSpecialCharater(node.parent.title)}`,
              });
            }
          }
        }
      }
    }
  };

  const setInfoAndChartData = (data, sequentialRunDuration) => {
    if (data) {
      let _selectedProject = localStorage.getItem('selected-project');
      const selectedProjectParsed = JSON.parse(_selectedProject);
      if (selectedProjectParsed) {
        setSelectedProject(selectedProjectParsed);
      }
      let _info;
      setChartData({});
      if (true) {
        _info = {
          runNumber: 8,
          runId: executionId,
          status: data?.status,
          suiteName: suiteName,
          machine: data?.platformStatistics[0]?.machine,
          execution: data?.selectedSystem?.executionEnv,
          os: [data?.platformStatistics[0]?.os],
          duration: sequentialRunDuration ? sequentialRunDuration : data?.executionDurationInHourMinSecFormat,
          executedOn: data?.executedOn,
        };
        if (data?.selectedSystem?.browserName) {
          _info['browser'] = [data?.selectedSystem?.browserName];
        }
        if (data && data?.platformStatistics) {
          let device = '';
          let browser = '';
          data.platformStatistics.forEach((ps) => {
            if (ps.deviceName) {
              if (device) {
                let str1 = device.concat(',');
                device = str1.concat(ps.deviceName);
              } else {
                device = ps.deviceName;
              }
            }
            if (ps.browser) {
              if (!browser) {
                browser = ps.browser;
              }
            }
          });
          if (device) {
            _info['deviceName'] = [device];
          }
          if (browser) {
            _info['browser'] = [browser];
          }
        }
        setGraphChartData(data);
      }
      setInfoData(_info);
    }
  };

  const setGraphChartData = (data) => {
    let _defaultStats = {
      total: 0,
      totalWarning: 0,
      totalFailed: 0,
      totalSkipped: 0,
      totalTerminated: 0,
      totalPassed: 0,
    };
    let _chart = {
      moduleStats: _defaultStats,
      scriptStats: _defaultStats,
      preConditionStats: _defaultStats,
      postConditionStats: _defaultStats,
    };
    if (data) {
      if (data?.moduleStats) {
        _chart['moduleStats'] = data?.moduleStats;
      }
      if (data?.scriptStats) {
        _chart['scriptStats'] = data?.scriptStats;
      }
      if (data?.preConditionStats) {
        _chart['preConditionStats'] = data?.preConditionStats;
      }
      if (data?.postConditionStats) {
        _chart['postConditionStats'] = data?.postConditionStats;
      }
    }
    setChartData(_chart);
  };

  return (
    <>
      <div className="grid grid-cols-3 pb-4">
        <div className="fontPoppinsSemiboldXlg">Results</div>
        <div className="col-span-2">
          <div className="alert-position-style">
            <AlertContatiner></AlertContatiner>
          </div>
        </div>
      </div>
      <div className=" flex" id="journal-scroll">
        <div className="flex-1 focus:outline-none">
          <main className="flex-1 relative pb-2 z-0 base-card">
            {isLoading ? (
              <CommonLoader />
            ) : treeData && treeData.length && infoData && chartData && rootData ? (
              <ExecutionResult
                selectedProject={selectedProject}
                {...props}
                infoData={infoData}
                chartData={chartData}
                treeData={treeData}
                rootData={rootData}
                platformData={platformData}
                onNodeSelected={onNodeSelected}
                handleStatusChange={handleStatusChange}
                resultType="suite"
                suiteType={suiteType}
                MyAlert={MyAlert}
              />
            ) : (
              ''
            )}
          </main>
        </div>
      </div>
    </>
  );
}

export default ExecutionResultBase;

import React, { useEffect, useState, useContext, Fragment } from 'react';
import ReactTable from '@src/pages/common/Table/ReactTable';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import DeleteModal from '@pages/common/delete_modal';
import Create_Edit_Slack_Account from './Create_Edit_Slack_Account';
import { getAllSlackInstance, deleteSlackInstance } from '@api/api_services';
import Slack_User_Account_Details_Modal from './Slack_User_Account_Details_Modal';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { CommonLoader } from '@src/pages/common/common-loader';
import { Tooltip } from '@material-ui/core';
import ContextMenu from '@pages/common/context_menu';
import SearchBar from '@pages/analytics/common/search-bar';
import { HighlightText, isEmptyValue, isValueIncludes } from '@util/common_utils';
import NoResultsFound from '@pages/common/Table/NoResultFound';
import { SLACK_CONSTANTS } from '../plugin_constants';
import { useTable } from 'react-table';
import { ReactComponent as QuickStart } from '@assets/svg-imports/QuickStart.svg';
import { ReactComponent as AddIcon } from '@assets/svg-imports/addIcon.svg';
import SlackCreateEditInstance from './slack_create_edit_instance';
import { useAlert } from '@pages/common/alert_service/useAlert';
import EditIcon from '@mui/icons-material/Edit';
import { Menu, Transition } from '@headlessui/react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteInstanceSlack from './delete_slack_instance_modal';
import SlackDetails from './slack_details';

function SlackIntegrationTable(props) {
  const { AlertContatiner, MyAlert } = useAlert();
  const [account, setAccount] = useState([]);
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [reloadTree, setReloadTree] = useState(true);
  let [isLoading, setIsLoading] = useState(false);
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [message, setMessage] = useState('');
  const [mode, setMode] = useState('');
  const [accountData, setAccountData] = useState();
  let [isHighlight, setIsHighlight] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');
  const [slackData, setSlackData] = useState([]);
  const [openInstance, setOpenInstance] = useState(false);
  const [seletedInstance, setSelectedInstance] = useState();
  const [actionType, setActionType] = useState();
  const [openInstanceDetails, setOpenInstanceDetails] = useState(false);
  const [pageIndexValue, setPageIndex] = useState(0);

  useEffect(() => {
    if (reloadTree) {
      setIsLoading(true);
      getAllSlackInstanceFunc();
    }
    setReloadTree(false);
  }, [reloadTree]);

  let reloadpage = () => {
    setReloadTree(true);
  };

  const getAllSlackInstanceFunc = () => {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    getAllSlackInstance()
      .then((response) => {
        stopProgress();
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 10,
        });
        if (response?.data?.responseCode === 200) {
          setSlackData(response.data.responseObject);
          setAccount((d) => response.data.responseObject);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setAccount([]);
        }
      })
      .catch((err) => {
        resetProgress();
        console.error('GET_SLACK_LIST :', err);
      });
  };

  // TODO :: handle Search filter :: START
  const [userSearched, setuserSearched] = useState('');
  let [openSearch, setOpenSearch] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(slackData);
    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [slackData]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(slackData);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setData(account);
    setSearchTextHighlight('');
    setIsHighlight(!isHighlight);
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = slackData.filter(({ instanceName, channelName, channelId, modifiedByUname }) => {
          return (
            isValueIncludes(e, instanceName) ||
            isValueIncludes(e, channelName) ||
            isValueIncludes(e, channelId) ||
            isValueIncludes(e, modifiedByUname)
          );
        });
        setData(newData);
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  // TODO :: handle Search filter :: END

  const columns = React.useMemo(
    () => [
      {
        Header: 'INSTANCE NAME',
        accessor: 'instanceName',
        disableFilters: true,
        Cell: (e) => (
          <div className=" float-left   name-trimmer  table-content fontPoppinsRegularSm" title={e.value}>
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'CHANNEL NAME',
        accessor: 'channelName',
        disableFilters: true,
        Cell: (e) => (
          <div className=" float-left  table-content fontPoppinsRegularSm name-trimmer" title={e.value}>
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'CHANNEL ID',
        accessor: 'channelId',
        disableFilters: true,
        Cell: ({ value }) => (
          <div
            title={value}
            className=" float-left  table-content fontPoppinsRegularSm overflow-hidden overflow-ellipsis whitespace-nowrap w-20"
          >
            <HighlightText text={value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'MODIFIED ON',
        accessor: 'modifiedOn',
        disableFilters: true,
        Cell: ({ value }) => <div className=" float-left  table-content fontPoppinsRegularSm">{value}</div>,
      },
      {
        Header: 'MODIFIED BY',
        accessor: 'modifiedByUname',
        disableFilters: true,
        Cell: (e) => (
          <Tooltip title={e.value} placement="top">
            <div className=" float-left  table-content fontPoppinsRegularSm name-trimmer">
              <HighlightText text={e.value} highlight={searchTextHighlight} />
            </div>
          </Tooltip>
        ),
      },
      {
        Header: 'ACTIONS',
        accessor: 'Actions',
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <div>
              <span className="flex flex-row table-non-link-color-common float-left ">
                <span
                  className="project-action action-button cursor-pointer"
                  onClick={() => openModal(row.original, 'Edit')}
                >
                  {' '}
                  <Tooltip title={SLACK_CONSTANTS.EDIT} placement="bottom">
                    <EditIcon />
                  </Tooltip>
                </span>
                <span
                  className="project-action action-button cursor-pointer ml-2"
                  onClick={() => openModalDelete(row.original, 'Delete')}
                >
                  <Tooltip title={SLACK_CONSTANTS.DELETE} placement="bottom">
                    <DeleteOutlinedIcon />
                  </Tooltip>{' '}
                </span>
                <span className="project-action text-base  cursor-hand memory-state-button ml-2 ">
                  <Tooltip title="More">
                    <Menu as="div" className="relative inline-block text-left" key="user-menu">
                      {({ open }) => (
                        <>
                          <div>
                            <Menu.Button
                              className="flex items-center  focus:outline-none "
                              onMouseOver={(e) => {
                                let mouse = e.clientY;
                                setPageIndex(mouse);
                              }}
                            >
                              <span className="action-button">
                                {' '}
                                <MoreVertIcon className="" />
                              </span>
                            </Menu.Button>
                          </div>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className={`${pageIndexValue > 520 ? '-mt-24 ' : 'ml-2'} origin-top-right absolute right-0 z-40 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                            >
                              <div className="py-5">
                                <Menu.Item>
                                  <div
                                    onClick={() => openInstanceModal(row.original, 'more')}
                                    className="block px-4 py-1 text-sm details-background"
                                  >
                                    {SLACK_CONSTANTS.DETAILS}
                                  </div>
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </Tooltip>
                </span>
                <span className="project-action pt-1 text-blue-700 text-base cursor-pointer state-button ml-2"></span>
              </span>
            </div>
          );
        },
      },
    ],
    [isHighlight]
  );

  const openModalDelete = (value) => {
    setOpenDeleteModal(true);
    setSelectedInstance(value);
  };
  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const closeModal = () => {
    setOpenInstance(false);
  };
  const openModal = (value, actionType) => {
    setOpenInstance(true);
    setSelectedInstance(value);
    setActionType(actionType);
  };
  const openInstanceModal = (value, actionType) => {
    setOpenInstanceDetails(true);
    setSelectedInstance(value);
  };
  const closeInstanceModal = () => {
    setOpenInstanceDetails(false);
  };

  useEffect(() => {
    setAccount(account);
    setSlackData(account);
  }, []);
  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data,
  });
  const [tabsList, setTabsList] = useState([
    {
      name: 'Slack',
      pathUrl: `/configuration/emailConfiguration/emailGroup`,
      selectedName: 'emailGroup',
    },
  ]);
  const renderTabs = () => {
    return (
      <div>
        <ul className="navBar table-header-text flex flex-row primary-tab ml-6">
          {tabsList.map((tab, index) => {
            return (
              <li key={tab.name}>
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    props.history.push(tab.pathUrl);
                  }}
                  className={`selected_tab_style`}
                >
                  {tab.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <>
      <div className="page-list-body">
        <div className="alert-position-style">
          <AlertContatiner></AlertContatiner>
        </div>
        <div>{renderTabs()}</div>
        <div className="page-table-body">
          <div className="content_area_style_Int">
            <div className="content_area_style_tcTemplate" id="journal-scroll">
              <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
                <label className="ml-6 flex-auto fontPoppinsSemiboldMd">{SLACK_CONSTANTS.SLACK_CONFIGURATION}</label>
                <div className="flex items-center justify-end  w-2/5 mr-40 mt-2">
                  <SearchBar
                    open={openSearch}
                    searchValue={userSearched || ''}
                    handleSearch={(e) => {
                      setuserSearched(e.target.value);
                    }}
                    onSearchClose={handleSearchClose}
                    handleSearchOpen={handleSearchOpen}
                    enterKeyHandler={handleSearchSubmit}
                    disable={isEmptyValue(slackData)}
                  />
                </div>
                <div
                  className="grid grid-cols-12 col-span-11 addTempBtn flex-row cursor-pointer mr-5"
                  onClick={openModal}
                >
                  <span className="col-span-2">
                    <AddIcon className="addDefectIcon" />
                  </span>
                  <span className=" col-span-10 absolute mt-2 fontPoppinsMediumSm create-instance-text">
                    {SLACK_CONSTANTS.INSTANCE}
                  </span>
                </div>
              </div>
              <div className="table-height overflow-y-auto overflow-x-hidden mx-3" id="journal-scroll">
                <table {...getTableProps()} className="h-3">
                  <thead className="projectDetailsDataa">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()} className="text-sm h-9   top-0">
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps()}
                            width={column.width}
                            className=" text-left mx-2 fontPoppinsSemiboldSm pl-3 table-line"
                          >
                            <span className="table-header-text">{column.render('Header')}</span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()} className="text text-gray-700">
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} className="configHover h-10 hover:bg-gray-100 mt-10">
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="p-0  text-left  fontPoppinsRegularSm pl-4 project-row"
                              >
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {openSearch && isEmptyValue(data) && <NoResultsFound className="mt-16" />}
                {isLoading ? (
                  <CommonLoader />
                ) : (
                  isEmptyValue(slackData) && (
                    <div className="fontPoppinsRegularMd no-steps">
                      <div>
                        <span className=" ml-10 mb-5">
                          <QuickStart className="quick-start" />
                        </span>
                        <p className="fontPoppinsSemiboldMd mb-4">{SLACK_CONSTANTS.QUICK_START}</p>
                        <div className="flex flex-row">
                          <div className="fontPoppinsRegularSm mt-2">{SLACK_CONSTANTS.STEP_1_CLICK}</div>
                          <div
                            className="grid grid-cols-12 col-span-11 QuickStartAddBtn  cursor-pointer ml-4"
                            onClick={openModal}
                          >
                            <span className="col-span-2">
                              <AddIcon className="addDefectIcon" />
                            </span>
                            <span className=" col-span-10 absolute mt-2 fontPoppinsMediumSm create-instance-text">
                              {SLACK_CONSTANTS.INSTANCE}
                            </span>
                          </div>
                          <div className="fontPoppinsRegularSm mt-2 ml-1">{SLACK_CONSTANTS.ADDING_INSTANCE}</div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {openInstance && (
        <SlackCreateEditInstance
          isOpen={openInstance}
          onClose={closeModal}
          seletedInstance={seletedInstance}
          actionType={actionType}
          reloadTree={reloadpage}
          MyAlert={props.MyAlert}
          data={accountData}
        />
      )}

      {openInstanceDetails && (
        <SlackDetails
          isOpen={openInstanceDetails}
          onClose={closeInstanceModal}
          seletedInstance={seletedInstance}
          actionType={actionType}
          reloadTree={reloadpage}
        />
      )}
      {openDeleteModal && (
        <DeleteInstanceSlack
          openModal={openModalDelete}
          closeModal={closeDeleteModal}
          seletedInstance={seletedInstance}
          actionType={actionType}
          reloadTree={reloadpage}
          MyAlert={props.MyAlert}
          data={accountData}
        />
      )}
    </>
  );
}

export default SlackIntegrationTable;

import React, { Component, Fragment, createRef } from 'react';
import '../css/Ace_Editor.css';
import AceEditor from 'react-ace-builds';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-beautify';
import 'react-ace-builds/webpack-resolver-min';
import 'brace/ext/searchbox';
import 'brace/keybinding/emacs';
import 'brace/keybinding/vim';
import 'brace/ext/language_tools';
import 'brace/ext/settings_menu';
import VariableSelector from '@pagescommon/variable-selector-myinput';
import { calculatePosition, getScreenPosition } from '@util/common_utils';

export default class Ace_Editor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorHeight: 244,
      editorWidth: '100%',
      readOnly: false,
      change: '',
      showDropDown: false,
      variableDetails: null,
      editorValue: null,
      inputValue: '',
      dollarPosition: { top: 0, left: 0 }
    };
    this.editorRef = createRef(null);
  }

  variableObj = {};
  markers = [];

  componentDidUpdate(prevProps, prevState) {
    const editorData = this.state.change;
    if (prevState.change !== editorData) {
      if (this.state.editorValue?.lines[0] === '$' || (editorData && editorData[editorData?.length - 1] === '$')) {
        this.setState({ inputValue: editorData });
      }
    }

    if (prevProps.requestBodyActive !== this.props.requestBodyActive) {
      if (this.editorRef.current) {
        this.editorRef.current.editor.resize();
      }
    }
  }

  onBlurValue = () => {
    setTimeout(() => {
      this.setState({
        showDropDown: false,
      });
      this.forceUpdate();
    }, 500);
  };
  onFocusValue = () => {
    if (this.props?.rawValue?.charAt(this.props?.rawValue?.length - 1) === '$') {
      this.setState({
        showDropDown: true,
      });
    }
    this.forceUpdate();
  };

  onChangeValue = (value, event) => {
    const { pageX, pageY } = getScreenPosition(this.editorRef.current);
    this.setState({
      change: value,
      editorValue: event,
      dollarPosition: { top: pageY, left: pageX },
    });

    if (event.lines[0] === '$' && event.action === 'insert') {
      this.setState({
        showDropDown: true,
        variableDetails: event.start,
      });
    }
    if (event.lines[0] === '$' && event.action === 'remove') {
      this.setState({
        showDropDown: false,
      });
    }
    if (value[value.length - 1] === '$') {
      this.setState({
        showDropDown: true,
      });
    }

    if (value !== '' && value !== ' ') {
      let objKeys = Object.keys(this.variableObj).join('|');
      let newObjStr = new RegExp(`${objKeys}`, 'g');
      this.apiDataCode = this.addVariable(this.state.change, newObjStr, this.variableObj);
    } else {
      this.apiDataCode = '';
    }
    this.props.getCodeValue(this.apiDataCode, value);
    this.forceUpdate();
  };
  onClickVariableSelector = (name, data) => {
    let tempRow = this.state.variableDetails.row;
    let tempColumn = this.state.variableDetails.column;
    const isValueExist = this.state.inputValue.indexOf('\n');
    let tempStateArray = [];
    if (isValueExist !== -1) {
      tempStateArray = this.state.inputValue.split('\n');
    } else {
      tempStateArray = this.state.inputValue.split('\r');
    }
    tempStateArray[tempRow] =
      tempStateArray[tempRow].slice(0, tempColumn) +
      tempStateArray[tempRow].slice(tempColumn + 1, tempStateArray[tempRow].length);
    let tempValue = this.props.onSetValueByType(name, data.type, data);
    tempStateArray[tempRow] = this.addStr(tempStateArray[tempRow], tempColumn, tempValue);
    let tempStateStr = tempStateArray.join('\n');
    this.variableObj[name] = data.id;
    let objKeys = Object.keys(this.variableObj).join('|');
    let newObjStr = new RegExp(`${objKeys}`, 'g');
    this.setState({
      showDropDown: false,
      change: tempStateStr,
    });
    this.apiDataCode = this.addVariable(tempStateStr, newObjStr, this.variableObj);
    this.props.getCodeValue(this.apiDataCode, tempStateStr);
    this.forceUpdate();
  };
  addStr = (str, index, stringToAdd) => {
    if (str[str?.length - 1] === '$' && this.state.editorValue.action === 'remove') {
      return str?.substring(0, index) + stringToAdd.slice(1) + str?.substring(index, str.length);
    }
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  };

  addVariable = (str, objKeys, varObj) => {
    return str.replace(objKeys, function (matched, index) {
      if (matched !== '') {
        return varObj[matched];
      } else {
        return '';
      }
    });
  };
  handleEditorValue = (value) => {
    this.setState({ showDropDown: value });
  };
  render() {
    const { showDropDown, change } = this.state;
    return (
      <Fragment>
        <AceEditor
          ref={this.editorRef}
          className="ace_Gutter"
          theme="tomorrow"
          name="demo"
          height={
            this.props?.requestSectionCollapse
              ? '65vh'
              : this.props?.responseBodyExpandPopUp
                ? 400
                : this.props?.requestBodyExpandPopUp
                  ? 420
                  : this.props?.isModalOpen
                    ? 492
                    : 244
          }
          width={this.props?.isModalOpen ? 425 : this.state.editorWidth}
          showGutter={true}
          readOnly={
            this.props?.isModalOpen
              ? this.props?.isReadOnly
              : this.props?.isReadOnly
                ? !this.state.readOnly
                : this.state.readOnly
          }
          onChange={this.onChangeValue}
          onBlur={this.onBlurValue}
          onFocus={this.onFocusValue}
          editorProps={{ $blockScrolling: true }}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true,
            fontSize: '14px',
          }}
          value={this.props.rawValue}
          style={this.props?.isModalOpen !== 'import' ? { marginTop: '20px' } : {}}
          {...this.props}
        />
        <div className={`dropdown-wrapper ${showDropDown ? 'open' : 'closed'}`}>
        {showDropDown && change ? (
          <VariableSelector
            ShowDropDown={showDropDown}
            onClickGetValue={this.onClickVariableSelector}
            currentValue={this.state.editorValue}
            type="Editor"
            value={change}
            handleEditorValue={this.handleEditorValue}
            editorRef={this.editorRef}
            positionStyle={calculatePosition(this.state.dollarPosition, false)}
            {...this.props}
          />
        ) : null}
        </div>
      </Fragment>
    );
  }
}

import React, { useEffect, useState, useContext, Fragment } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import { getBitBucketsall, getBitBucket } from '@api/api_services';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { CommonLoader } from '@src/pages/common/common-loader';
import { Tooltip } from '@material-ui/core';
import { useTable } from 'react-table';
import { ReactComponent as QuickStart } from '@assets/svg-imports/QuickStart.svg';
import { ReactComponent as AddIcon } from '@assets/svg-imports/addIcon.svg';
import './bitBucket.scss';
import { BITBUCKET_CONSTANTS } from '../plugin_constants';
import { Menu, Transition } from '@headlessui/react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CreateEditInstance from './create_edit_instanceDrawer';
import SyncronizeInstance from './syncronizeDrawer';
import InstanceDetails from './instanceDetails';
import DeleteInstance from './deleteInstance';
import { useAlert } from '@pages/common/alert_service/useAlert';
import SearchBar from '@src/pages/analytics/common/search-bar';
import { HighlightText, isEmptyValue, isValueIncludes } from '@src/util/common_utils';
import NoResultsFound from '@src/pages/common/Table/NoResultFound';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
function BitBucketIntegrationTable(props) {
  const { AlertContatiner, MyAlert } = useAlert();
  const [tableData, setTableData] = useState([]);
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [reloadTree, setReloadTree] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openInstance, setOpenInstance] = useState(false);
  const [openSync, setOpenSync] = useState(false);
  const [openInstanceDetails, setOpenInstanceDetails] = useState(false);
  const [bitbucketsData, setBitBucketsData] = useState([]);
  const [seletedInstance, setSelectedInstance] = useState();
  const [actionType, setActionType] = useState();
  const [openSearch, setOpenSearch] = useState(false);
  const [userSearched, setuserSearched] = useState('');
  const [data, setData] = useState([]);
  let [isHighlight, setIsHighlight] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');
  const [pageIndexValue, setPageIndex] = useState(0);

  const reloadpage = () => {
    setReloadTree(true);
  };

  const getAllBitBuckets = () => {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    getBitBucketsall().then((response) => {
      stopProgress();
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 10,
      });
      if (response?.data?.responseCode === 200) {
        setBitBucketsData(response.data.responseObject);
        setIsLoading(false);
      }
    });
  };
  useEffect(() => {
    if (reloadTree) {
      setIsLoading(true);
      getAllBitBuckets();
    }
    setReloadTree(false);
  }, [reloadTree]);

  const [tabsList, setTabsList] = useState([
    {
      name: 'Bitbucket',
      pathUrl: `/configuration/emailConfiguration/emailGroup`,
      selectedName: 'emailGroup',
    },
  ]);

  const renderTabs = () => {
    return (
      <div>
        <ul className="navBar table-header-text flex flex-row primary-tab ml-6">
          {tabsList.map((tab, index) => {
            return (
              <li key={tab.name}>
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    props.history.push(tab.pathUrl);
                  }}
                  className={`selected_tab_style`}
                >
                  {tab.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  // TODO :: hanlde seactch filter :: START
  useEffect(() => {
    setData(bitbucketsData);
    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [bitbucketsData]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(bitbucketsData);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  const handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setData(bitbucketsData);
    setSearchTextHighlight('');
    setIsHighlight(!isHighlight);
  };

  const handleSearchOpen = () => {
    setOpenSearch(true);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = bitbucketsData.filter(
          ({
            instanceName,
            bitBucketUserName,
            bitBucketWorkspaceName,
            projectName,
            bitBucketRepositoryName,
            fireFlinkProjectName,
            suiteName,
          }) => {
            return (
              isValueIncludes(e, instanceName) ||
              isValueIncludes(e, bitBucketUserName) ||
              isValueIncludes(e, bitBucketWorkspaceName) ||
              isValueIncludes(e, projectName) ||
              isValueIncludes(e, bitBucketRepositoryName) ||
              isValueIncludes(e, fireFlinkProjectName) ||
              isValueIncludes(e, suiteName)
            );
          }
        );
        setData(newData);
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  // TODO :: hanlde seactch filter :: END

  const openModal = (value, actionType) => {
    setOpenInstance(true);
    setSelectedInstance(value);
    setActionType(actionType);
  };
  const closeModal = () => {
    setOpenInstance(false);
  };
  const openSyncModel = (value, actionType) => {
    setOpenSync(true);
    setActionType(actionType);
    setSelectedInstance(value);
  };
  const closeSyncModel = () => {
    setOpenSync(false);
  };

  const openInstanceModal = (value, actionType) => {
    setOpenInstanceDetails(true);
    setSelectedInstance(value);
  };
  const closeInstanceModal = () => {
    setOpenInstanceDetails(false);
  };
  const openModalDelete = (value) => {
    setOpenDeleteModal(true);
    setSelectedInstance(value);
  };
  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'INSTANCENAME',
        accessor: 'instanceName',
        disableFilters: true,
        width: '13%',
        Cell: (e) => (
          <div
            className=" table-content float-left  fontPoppinsRegularSm overflow-hidden overflow-ellipsis whitespace-nowrap w-24"
            title={e.value}
          >
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'USERNAME',
        accessor: 'bitBucketUserName',
        disableFilters: true,
        width: '13%',
        Cell: (e) => (
          <div
            className="  table-content float-left  fontPoppinsRegularSm overflow-hidden overflow-ellipsis whitespace-nowrap w-20"
            title={e.value}
          >
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'WORKSPACE',
        accessor: 'bitBucketWorkspaceName',
        disableFilters: true,
        width: '13%',
        Cell: (e) => {
          return (
            <div
              title={e.value}
              className="table-content float-left fontPoppinsRegularSm overflow-hidden overflow-ellipsis whitespace-nowrap w-24 "
            >
              <HighlightText text={e.value} highlight={searchTextHighlight} />
            </div>
          );
        },
      },
      {
        Header: 'PROJECT',
        accessor: 'projectName',
        disableFilters: true,
        width: '12%',
        Cell: (e) => {
          return (
            <div
              title={e.value}
              className="table-content float-left  fontPoppinsRegularSm overflow-hidden overflow-ellipsis whitespace-nowrap w-24"
            >
              <HighlightText text={e.value} highlight={searchTextHighlight} />
            </div>
          );
        },
      },
      {
        Header: 'REPOSITORY',
        accessor: 'bitBucketRepositoryName',
        disableFilters: true,
        width: '12%',
        Cell: (e) => (
          <div
            title={e.value}
            className="table-content float-left  fontPoppinsRegularSm overflow-hidden overflow-ellipsis whitespace-nowrap w-24"
          >
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'FIREFLINK PROJECT',
        accessor: 'fireFlinkProjectName',
        disableFilters: true,
        width: '12%',
        Cell: (e) => (
          <div
            title={e.value}
            className="table-content float-left  fontPoppinsRegularSm overflow-hidden overflow-ellipsis whitespace-nowrap w-24"
          >
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'SUIT NAME',
        accessor: 'suiteName',
        disableFilters: true,
        width: '12%',
        Cell: (e) => (
          <div
            title={e.value}
            className="table-content float-left  fontPoppinsRegularSm overflow-hidden overflow-ellipsis whitespace-nowrap w-24"
          >
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'ACTIONS',
        accessor: 'Actions',
        disableFilters: true,
        width: '17%',
        Cell: ({ row }) => {
          return (
            <div>
              <span className="flex flex-row table-non-link-color-common float-left table-font-style-common ">
                <span
                  className="project-action action-button cursor-pointer -ml-2"
                  onClick={() => openModal(row.original, 'Edit')}
                >
                  {' '}
                  <Tooltip title={BITBUCKET_CONSTANTS.EDIT} placement="bottom">
                    <EditIcon />
                  </Tooltip>
                </span>
                <span
                  className="project-action action-button cursor-pointer ml-2"
                  onClick={() => openModalDelete(row.original, 'Delete')}
                >
                  <Tooltip title={BITBUCKET_CONSTANTS.DELETE} placement="bottom">
                    <DeleteOutlinedIcon />
                  </Tooltip>{' '}
                </span>
                <span
                  className="project-action action-button cursor-pointer ml-2"
                  onClick={() => openSyncModel(row.original, 'Sync')}
                >
                  <Tooltip title={BITBUCKET_CONSTANTS.SYNC} placement="bottom">
                    <SyncIcon />
                  </Tooltip>{' '}
                </span>
                <span className="project-action text-base  cursor-hand memory-state-button ml-2 ">
                  <Tooltip title="More">
                    <Menu as="div" className="relative inline-block text-left" key="user-menu">
                      {({ open }) => (
                        <>
                          <div>
                            <Menu.Button
                              className="flex items-center  focus:outline-none "
                              onMouseOver={(e) => {
                                let mouse = e.clientY;
                                setPageIndex(mouse);
                              }}
                            >
                              <span className="action-button">
                                {' '}
                                <MoreVertIcon className="" />
                              </span>
                            </Menu.Button>
                          </div>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className={`${
                                pageIndexValue > 520 ? '-mt-24 ' : 'ml-2'
                              } origin-top-right absolute right-0 z-40 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                            >
                              <div className="py-5">
                                <Menu.Item>
                                  <div
                                    onClick={() => openInstanceModal(row.original, 'more')}
                                    className="block px-4 py-1 text-sm details-background"
                                  >
                                    {BITBUCKET_CONSTANTS.DETAILS}
                                  </div>
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </Tooltip>
                </span>
                <span className="project-action pt-1 text-blue-700 text-base cursor-pointer state-button ml-2"></span>
              </span>
            </div>
          );
        },
      },
    ],
    [isHighlight]
  );

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data,
  });

  return (
    <>
      <div className="page-list-body">
        <div className="alert-position-style">
          <AlertContatiner></AlertContatiner>
        </div>
        <div>{renderTabs()}</div>
        <div className="page-table-body">
          <div className="content_area_style_Int">
            <div className="content_area_style_tcTemplate" id="journal-scroll">
              <div className="pt-3 pb-3 border_style flex flex-wrap items-center justify-end">
                <div className="flex items-center justify-end  w-2/5 mr-40 mt-2">
                  <SearchBar
                    open={openSearch}
                    searchValue={userSearched || ''}
                    handleSearch={(e) => {
                      setuserSearched(e.target.value);
                    }}
                    onSearchClose={handleSearchClose}
                    handleSearchOpen={handleSearchOpen}
                    enterKeyHandler={handleSearchSubmit}
                    disable={isEmptyValue(bitbucketsData)}
                  />
                </div>
                <div
                  className="grid grid-cols-12 col-span-11 addTempBtn flex-row cursor-pointer mr-5"
                  onClick={openModal}
                >
                  <span className="col-span-2">
                    <AddIcon className="addDefectIcon" />
                  </span>
                  <span className=" col-span-10 absolute mt-2 fontPoppinsMediumSm create-instance-text">
                    {BITBUCKET_CONSTANTS.INSTANCE}
                  </span>
                </div>
              </div>
              <div className="table-height overflow-y-auto overflow-x-hidden mx-3" id="journal-scroll">
                <table {...getTableProps()} className="h-3">
                  <thead className="projectDetailsDataa">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()} className="text-sm h-9   top-0">
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps()}
                            width={column.width}
                            className=" text-left mx-2 fontPoppinsSemiboldSm pl-3 table-line"
                          >
                            <span className="table-header-text">{column.render('Header')}</span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()} className="text text-gray-700">
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} className="configHover h-10 hover:bg-gray-100 mt-10">
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="p-0  text-left text-gray-700 fontPoppinsRegularMd pl-4 project-row"
                              >
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {openSearch && isEmptyValue(data) && <NoResultsFound className="mt-16" />}
                {isLoading ? (
                  <CommonLoader />
                ) : (
                  isEmptyValue(bitbucketsData) && (
                    <div className="fontPoppinsRegularMd no-steps">
                      <div>
                        <span className=" ml-10 mb-5">
                          <QuickStart className="quick-start" />
                        </span>
                        <p className="fontPoppinsSemiboldMd mb-4">{BITBUCKET_CONSTANTS.QUICK_START}</p>
                        <div className="flex flex-row">
                          <div className="fontPoppinsRegularSm mt-2">{BITBUCKET_CONSTANTS.STEP_1_CLICK}</div>
                          <div
                            className="grid grid-cols-12 col-span-11 QuickStartAddBtn  cursor-pointer ml-4"
                            onClick={openModal}
                          >
                            <span className="col-span-2">
                              <AddIcon className="addDefectIcon" />
                            </span>
                            <span className=" col-span-10 absolute mt-2 fontPoppinsMediumSm create-instance-text">
                              {BITBUCKET_CONSTANTS.INSTANCE}
                            </span>
                          </div>
                          <div className="fontPoppinsRegularSm mt-2 ml-1">{BITBUCKET_CONSTANTS.ADDING_INSTANCE}</div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {openInstance && (
        <CreateEditInstance
          isOpen={openInstance}
          onClose={closeModal}
          seletedInstance={seletedInstance}
          actionType={actionType}
          reloadTree={reloadpage}
          MyAlert={MyAlert}
        />
      )}
      {openSync && (
        <SyncronizeInstance
          isOpen={openSync}
          onClose={closeSyncModel}
          seletedInstance={seletedInstance}
          actionType={actionType}
          reloadTree={reloadpage}
          MyAlert={MyAlert}
        />
      )}
      {openInstanceDetails && (
        <InstanceDetails
          isOpen={openInstanceDetails}
          onClose={closeInstanceModal}
          seletedInstance={seletedInstance}
          actionType={actionType}
          reloadTree={reloadpage}
        />
      )}
      {openDeleteModal && (
        <DeleteInstance
          openModal={openModalDelete}
          closeModal={closeDeleteModal}
          seletedInstance={seletedInstance}
          actionType={actionType}
          reloadTree={reloadpage}
          MyAlert={MyAlert}
        />
      )}
    </>
  );
}

export default BitBucketIntegrationTable;

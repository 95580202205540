import * as React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import DateStyle from './date-selector.module.scss';
import PropTypes from 'prop-types';

const DateSelector = (props) => {
  let [isFocus, setIsFocus] = React.useState(false);
  let [label, setLabel] = React.useState(props?.label);
  let [open, setOpen] = React.useState(false);

  let getFocus = (e) => {
    if (e.type === 'focus') {
      setIsFocus(true);
      setLabel('');
    } else if (e.type === 'blur') {
      setIsFocus(false);
      if (props.value) {
        setLabel('');
      } else {
        setLabel(props?.label);
      }
    }
  };

  React.useEffect(() => {
    if (props.value) {
      setLabel('');
    } else if (props.value === null) {
      setLabel(props?.label);
    }
  }, [props?.value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={undefined}>
      <DatePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        className={isFocus ? DateStyle['custom-date-selector-label-disable'] : DateStyle['custom-date-selector']}
        inputFormat="dd/MM/yyyy"
        renderInput={(params) => (
          <TextField
            {...params}
            onFocus={getFocus}
            onBlur={getFocus}
            onKeyDown={(e) => e.preventDefault()}
            onClick={(e) => setOpen(true)}
          />
        )}
        {...props}
        label={label}
      />
    </LocalizationProvider>
  );
};

DateSelector.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any, // new Date() || Date(yyyy-mm-dd)
  onChange: PropTypes.func,
  minDate: PropTypes.any, // new Date() || Date(yyyy-mm-dd)
  maxDate: PropTypes.any, // new Date() || Date(yyyy-mm-dd)
};

/** 
 * 
// ! NOTE :: date only accept new Date() Object || new Date(yyyy-mm-dd) if set as string accecpt yyyy-mm-dd format
 * 
 */

export default DateSelector;

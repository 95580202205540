import { AddCircleOutlineOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import AddManualTestCase from '@pages/test-development/script/scripts/testcase-template/modals/add-manual-test-case';
import { createScript } from './script-create-api';
import { getAllTestCaseTemplate } from '@api/api_services';
import { getLicenseFeatures, purchasedFeatureTypeOfAutomation } from '@src/util/common_utils';
import { TEST_DEV } from '@common/ui-constants';
import { Tooltip } from 'fireflink-ui';
import cx from 'classnames';

const TestCaseTypeCol = ({ nodeObj, MyAlert, onNodeSelected, history, isToggled, hasEditAccess = true }) => {
  const [openModal, setOpenModal] = useState(false);
  const [showLoaderModal, setShowLoaderModal] = useState(false);
  const [automation, setAutomation] = useState();
  const [manual, setManual] = useState();
  const licenseFeatures = getLicenseFeatures();
  const hasManualTestCase = window.privilege.hasManualTestCase(licenseFeatures);
  const hasAutomation = window.privilege.hasAutomation(licenseFeatures);
  const hasCBasic = window.privilege.hasCBasic(licenseFeatures);
  const project = JSON.parse(localStorage.getItem('selected-project'));
  const hasAutomationBasedOnProjType = purchasedFeatureTypeOfAutomation(project.type);

  let _testCaseType = nodeObj?.data?.testCaseType;

  const getTemplates = async () => {
    try {
      setShowLoaderModal(true);
      const templateResponse = await getAllTestCaseTemplate('config');
      if (templateResponse?.data?.responseObject) {
        const manualScriptPresent = templateResponse.data.responseObject?.manualScriptPresent;
        const defaultTemplate = templateResponse.data.responseObject?.defaultTemplate;
        if (defaultTemplate && manualScriptPresent) {
          createManualScript(defaultTemplate._id);
        } else {
          setShowLoaderModal(false);
          setOpenModal(true);
        }
      } else {
        MyAlert.info(`${templateResponse?.data?.status + ' : ' + templateResponse?.data?.message}`);
      }
    } catch (err) {
      console.error('GET_TEMPLATES : ', err);
    }
  };

  const closeModal = (value) => {
    setOpenModal(value);
  };

  const createAutomationScript = async () => {
    try {
      setShowLoaderModal(true);
      const data = {
        name: nodeObj.data.name,
        scriptType: nodeObj.data.scriptType,
        desc: nodeObj.data.desc,
        parentId: nodeObj.data.parentId,
        parentName: nodeObj.data.parentName,
        executionOrder: nodeObj.data.executionOrder,
        hierarchy: nodeObj.data.hierarchy,
        testCaseType: 'Automation',
      };
      await createScript(data, 'auto', history, MyAlert, _testCaseType);
      setShowLoaderModal(false);
    } catch (err) {
      setShowLoaderModal(false);
      console.error('CREATE_AUTOMATION_SCRIPT', err);
    }
  };
  const createManualScript = async (id) => {
    try {
      const data = {
        name: nodeObj.data.name,
        scriptType: nodeObj.data.scriptType,
        desc: nodeObj.data.desc,
        parentId: nodeObj.data.parentId,
        parentName: nodeObj.data.parentName,
        executionOrder: nodeObj.data.executionOrder,
        hierarchy: nodeObj.data.hierarchy,
        testCaseType: 'manual',
        templateId: id,
        manualTestCase: {},
        pair: isToggled,
      };
      await createScript(data, 'manual', history, MyAlert, _testCaseType);
      setOpenModal(false);
      setShowLoaderModal(false);
    } catch (err) {
      console.error('CREATE_MANUAL_SCRIPT', err);
    }
  };
  const getAutomationRenderer = () => {
    if (automation?.id && hasAutomationBasedOnProjType) {
      return (
        <button
          type="button"
          onClick={() => {
            onNodeSelected(nodeObj, true);
          }}
          className="test-case-type"
        >
          Automation
        </button>
      );
    } else if (hasEditAccess && hasAutomation && hasAutomationBasedOnProjType) {
      return (
        <button
          disabled={showLoaderModal || !nodeObj?.data?.pair}
          className={cx('px-2 text-gray-600 automation-button-tct', { 'opacity-50': !nodeObj?.data?.pair })}
          onClick={createAutomationScript}
        >
          <Tooltip
            title={nodeObj?.data?.pair ? TEST_DEV.ADD_AUTOMATION_TOOLTIP : TEST_DEV.NON_AUTOMATED_TOOLTIP}
            placement="top"
          >
            <AddCircleOutlineOutlined />
          </Tooltip>
        </button>
      );
    }
  };

  const getManualRenderer = () => {
    if (manual?.id) {
      return (
        <button
          type="button"
          onClick={() => {
            onNodeSelected(nodeObj, false);
          }}
          className="test-case-type"
        >
          Manual
        </button>
      );
    } else if (hasEditAccess && (hasManualTestCase || hasCBasic)) {
      return (
        <button disabled={showLoaderModal} className="px-2 text-gray-600 manual-button-tct" onClick={getTemplates}>
          <Tooltip title="Click to add Manual Test Case" placement="top">
            <AddCircleOutlineOutlined />
          </Tooltip>
        </button>
      );
    }
  };

  useEffect(() => {
    const testCaseData = _testCaseType ? [..._testCaseType] : [];
    testCaseData.forEach((tcd) => {
      if (tcd.type === 'Automation') {
        setAutomation(tcd);
      } else {
        setManual(tcd);
      }
    });
  }, []);
  return (
    !(nodeObj.data.folder || nodeObj.isRootNode || nodeObj.data.type === 'PRE' || nodeObj.data.type === 'POST') && (
      <>
        <div className="flex items-center justify-evenly">
          {getAutomationRenderer()}

          {getManualRenderer()}
        </div>
        {openModal && <AddManualTestCase closeModal={closeModal} createManualScript={createManualScript} />}
      </>
    )
  );
};

export default TestCaseTypeCol;

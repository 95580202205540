import React, { useState } from 'react';
import Modal from 'react-modal';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Close } from '@mui/icons-material';
import { TextField } from '@material-ui/core';
import GitlabStyles from '../gitlab-styles.module.scss';
import cx from 'classnames';
import { createGitlabInstanceReq, updateGitlabInstanceReq } from '@api/api_services';
import { useAlert } from '@src/pages/common/alert_service/useAlert';

Modal.setAppElement('#root');
const customStylesPage = {
  content: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '640px',
    height: '327px',
    background: '#fbfcfd 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 11px #000000a1',
    borderRadius: '4px',
    opacity: '1',
  },
};

const CreateEditGitlabInstance = ({ data, closeModal, MyAlert, reload }) => {
  const { AlertContatiner, MyAlert: CustomMyAlert } = useAlert();
  const [openModal, setOpenModal] = useState(true);
  const handleClose = () => {
    setOpenModal(false);
    closeModal(false);
  };

  let initialValues;
  if (data) {
    initialValues = {
      instanceName: data.name,
      url: data.url,
      personalToken: data.personalToken,
    };
  } else {
    initialValues = {
      instanceName: '',
      url: '',
      personalToken: '',
    };
  }

  const createGitlabInstance = async (payload) => {
    try {
      const response = await createGitlabInstanceReq(payload);
      if (response?.data?.responseObject) {
        MyAlert.success(
          `${
            payload.name.length > 20 ? payload.name.substring(0, 20) + '...' : payload.name
          } instance is created successfully`
        );
        closeModal(false);
        reload();
      } else if (response?.data?.message?.toLowerCase()?.includes('invalid url')) {
        formikDetails.setFieldError('url', 'Invalid url');
      } else if (response?.data?.message === 'Invalid Token') {
        formikDetails.setFieldError('personalToken', response.data.message);
      } else if (response?.data?.message?.startsWith('Resource with name')) {
        formikDetails.setFieldError('instanceName', 'Name already exists');
      } else {
        MyAlert.warning(response?.data?.message);
        closeModal(false);
      }
    } catch (err) {
      console.error('CREATE_GITLAB_INSTANCE : ', err);
    }
  };

  const updateGitlabInstance = async (payload) => {
    if (payload?.name === data?.name && payload?.personalToken === data?.personalToken && payload?.url === data?.url) {
      CustomMyAlert.info('No changes were done');
      return;
    }
    try {
      const response = await updateGitlabInstanceReq(data._id, payload);
      if (response?.data?.responseObject) {
        MyAlert.success(
          `${
            payload.name.length > 20 ? payload.name.substring(0, 20) + '...' : payload.name
          } instance is updated successfully`
        );
        closeModal(false);
        reload();
      } else if (response?.data?.message?.toLowerCase()?.includes('invalid url')) {
        formikDetails.setFieldError('url', 'Invalid url');
      } else if (response?.data?.message === 'Invalid Token') {
        formikDetails.setFieldError('personalToken', response.data.message);
      } else if (response?.data?.message?.startsWith('Resource with name')) {
        formikDetails.setFieldError('instanceName', 'Name already exists');
      } else {
        MyAlert.warning(response?.data?.message);
        closeModal(false);
      }
    } catch (err) {
      console.error('UPDATE_GITLAB_INSTANCE : ', err);
    }
  };
  const onSubmit = (values) => {
    if (values) {
      if (values.personalToken?.trim().length) {
        const payload = {
          name: values.instanceName,
          url: values.url,
          personalToken: values.personalToken?.trim(),
        };
        if (data) {
          updateGitlabInstance(payload);
        } else {
          createGitlabInstance(payload);
        }
      } else {
        formikDetails.setFieldError('personalToken', 'Invalid token');
      }
    }
  };

  const validationSchema = yup.object({
    instanceName: yup
      .string()
      .required('Instance name is required')
      .matches(/^[a-zA-Z0-9-_ ]*$/, 'Instance name should be alphanumeric')
      .trim('Space is not allowed in the beginning and at the end')
      .strict(true)
      .min(3, 'Minimum 3 characters')
      .max(25, 'Maximum 25 characters'),
    url: yup
      .string()
      .required('URL is required')
      .trim('Space is not allowed in the beginning and at the end')
      .strict(true)
      .min(2, 'URL must be between 2 and 100 characters')
      .max(100, 'URL must be between 2 and 100 characters'),
    personalToken: yup.string().required('Personal token is required'),
  });

  const formikDetails = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <Modal isOpen={openModal} onRequestClose={handleClose} style={customStylesPage} className="p-px">
      <div className="mt-2 p-1">
        <div className=" ml-52">
          <div className="alert-position-style">
            <AlertContatiner></AlertContatiner>
          </div>
        </div>
        <p className="Popup-header-common inline-block w-10/12 pl-3 text-overflow-style">
          {data ? (
            <span title={data?.name} className="">
              {' '}
              Edit Instance - {data?.name}
            </span>
          ) : (
            <span className=""> Create Instance</span>
          )}
        </p>
        <div className="float-right">
          <button
            onClick={() => {
              setOpenModal(false);
              closeModal(false);
            }}
            type="button"
            className="ml-5 mr-5 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <Close color="action" />
          </button>
        </div>
      </div>
      <form onSubmit={formikDetails.handleSubmit}>
        <div className="border-b border-t border-blue-100 mt-px mb-px">
          <div className="m-6 grid grid-cols-2 gap-y-10">
            <div className={cx('w-10/12', GitlabStyles['feild-height'])}>
              <div>
                <TextField
                  error={formikDetails.errors.instanceName && formikDetails.touched.instanceName}
                  fullWidth
                  className={GitlabStyles['input-field']}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoFocus={true}
                  type="text"
                  name="instanceName"
                  id="instanceName"
                  autoComplete="new-password"
                  placeholder="Enter instance name"
                  onBlur={formikDetails.handleBlur}
                  onChange={formikDetails.handleChange}
                  value={formikDetails.values.instanceName}
                  label={
                    <>
                      {' '}
                      <span className="text-red-600">*</span> Instance Name
                    </>
                  }
                />
              </div>
              {formikDetails.errors.instanceName && formikDetails.touched.instanceName ? (
                <div className="fontPoppinsRegularSm errorMessage absolute w-60">
                  {formikDetails.errors.instanceName}
                </div>
              ) : null}
            </div>
            <div className="float-right ml-auto w-10/12">
              <div>
                <TextField
                  error={formikDetails.errors.url && formikDetails.touched.url}
                  fullWidth
                  className={GitlabStyles['input-field']}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="text"
                  name="url"
                  id="url"
                  autoComplete="new-password"
                  placeholder="https://gitlab.example.com"
                  onBlur={formikDetails.handleBlur}
                  onChange={formikDetails.handleChange}
                  value={formikDetails.values.url}
                  label={
                    <>
                      {' '}
                      <span className="text-red-600">*</span> URL
                    </>
                  }
                />
              </div>
              {formikDetails.errors.url && formikDetails.touched.url ? (
                <div className="fontPoppinsRegularSm errorMessage absolute">{formikDetails.errors.url}</div>
              ) : null}
            </div>
            <div className={cx('w-10/12', GitlabStyles['feild-height'])}>
              <TextField
                error={formikDetails.errors.personalToken && formikDetails.touched.personalToken}
                fullWidth
                className={GitlabStyles['input-field']}
                autoComplete="new-password"
                InputLabelProps={{
                  shrink: true,
                }}
                type="password"
                name="personalToken"
                id="personalToken"
                placeholder="Enter personal token"
                onBlur={formikDetails.handleBlur}
                onChange={formikDetails.handleChange}
                value={formikDetails.values.personalToken}
                label={
                  <>
                    {' '}
                    <span className="text-red-600">*</span> Personal Token
                  </>
                }
              />
              {formikDetails.errors.personalToken && formikDetails.touched.personalToken ? (
                <div className="fontPoppinsRegularSm errorMessage absolute w-60">
                  {formikDetails.errors.personalToken}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="float-right col-span-2 my-3 mx-5">
          <button onClick={() => closeModal(false)} type="button" className="gray-btn">
            Cancel
          </button>
          <button type="submit" className="primary-btn ml-3">
            {data ? 'Update' : 'Create'}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default CreateEditGitlabInstance;

import React from 'react';

import ElementTree from './element_tree';
function ParentPage(props) {
  return (
    <>
      <ElementTree
        pageType="parent"
        MyAlert={props.MyAlert}
        getAllElemtsReq={props.getAllElemtsReq}
        projectType={props.projectType}
        platForm={props.platForm}
        setProjectElementsCount={props.setProjectElementsCount}
      />
    </>
  );
}

export default ParentPage;

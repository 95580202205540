import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ExecutionOverview from './components/execution-result/execution-overview';
import ExecutionResultBase from './components/execution-result/execution_result_base';
import MultiMachineDropdown from './components/execution-result/multi-machine-dropdown';
import ModuleResultBase from './components/module-result/module_result_base';
import ScriptResultBase from './components/script-result/script_result_base';

const ResultRouter = (props) => {
  return (
    <Switch>
      <Route
        path="/testdevelopment/Execution Dashboard/:executionName/Root Module/result"
        exact={true}
        strict={true}
        {...props}
        key="result-base"
        component={ModuleResultBase}
      ></Route>
      <Route
        path="/testdevelopment/Execution Dashboard/:executionName/Root Module/:parentName/result"
        exact={true}
        strict={true}
        {...props}
        key="result-base"
        component={ModuleResultBase}
      ></Route>
      <Route
        path="/testdevelopment/Execution Dashboard/:executionName/Root Module/:moduleName/:scriptName/script/result"
        exact={true}
        strict={true}
        {...props}
        key="script-result-base"
        component={ScriptResultBase}
      ></Route>
      <Route
        path="/testdevelopment/Execution Dashboard/:executionName/Root Module/:scriptName/:dependantScript/script/result"
        exact={true}
        strict={true}
        {...props}
        key="script-result-base-depends"
        component={ScriptResultBase}
      ></Route>
      <Route
        path="/result/module/:moduleName"
        exact={true}
        strict={true}
        {...props}
        key="result-base-child"
        component={ModuleResultBase}
      ></Route>
      <Route
        path="/execution/suite/:executionName/execution dashboard/:parentModule/results"
        exact={true}
        strict={true}
        key="execution-result-base"
        component={ExecutionResultBase}
      ></Route>
      {/* /////////////// */}
      <Route
        path="/execution/suite/:executionName/execution dashboard/Root Module/:parentModule/results"
        exact={true}
        strict={true}
        {...props}
        key="execution-result-base"
        component={ExecutionResultBase}
      ></Route>
      <Route
        path="/execution/suite/:executionName/execution dashboard/overview/:machinename/:parentModule/:module/results"
        exact={true}
        strict={true}
        {...props}
        key="execution-result-base"
        component={ExecutionResultBase}
      ></Route>
      <Route
        path="/execution/suite/:executionName/execution dashboard/:scriptName/script/results"
        exact={true}
        strict={true}
        {...props}
        key="execution-script-result-base"
        component={ScriptResultBase}
      ></Route>
      <Route
        path="/execution/suite/:executionName/execution dashboard/:scriptName/:dependantScript/script/results"
        exact={true}
        strict={true}
        {...props}
        key="execution-dependent-script-result-base"
        component={ScriptResultBase}
      ></Route>
      <Route
        path="/execution/suite/:executionName/execution dashboard/overview/machines/Result"
        exact={true}
        strict={true}
        {...props}
        key="execution-result-base-overview"
        component={ExecutionOverview}
      ></Route>

      <Route
        path="/execution/suite/:executionName/execution dashboard/overview/machines/:machineName/Result"
        exact={true}
        strict={true}
        {...props}
        key="execution-result-base-machine"
        component={MultiMachineDropdown}
      ></Route>

      <Route
        path="/execution/suite/:executionName/execution dashboard/overview/machines/:machineNames/Root Module/:parentModule/:scriptName/Result"
        exact={true}
        strict={true}
        {...props}
        key="execution-script-result-base"
        component={ScriptResultBase}
      ></Route>
      <Route
        path="/execution/suite/:executionName/execution dashboard/overview/machines/:machineNames/:parentModule/:scriptName/Result"
        exact={true}
        strict={true}
        {...props}
        key="execution-script-result-base"
        component={ScriptResultBase}
      ></Route>
      {/* ////////// */}
      <Route
        path="/execution/suite/:executionName/execution dashboard/overview/machines/:machineNames/:parentModule/:moduleName/results"
        exact={true}
        strict={true}
        {...props}
        key="execution-script-result-base"
        component={ExecutionResultBase}
      ></Route>

      {/* <Route path="/execution/suite/:executionName/execution dashboard/:scriptName/script/results"
                exact={true}
                strict={true}
                key='execution-script-result-base'
                component={ScriptResultBase}>
            </Route> */}
    </Switch>
  );
};
export default ResultRouter;

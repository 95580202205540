import React, { Component, Fragment, createRef } from 'react';
import '../css/Prerequisite_Editor.css';
import AceEditor from 'react-ace-builds';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-beautify';
import 'react-ace-builds/webpack-resolver-min';
import 'brace/ext/searchbox';
import 'brace/keybinding/emacs';
import 'brace/keybinding/vim';
import 'brace/ext/language_tools';
import 'brace/ext/settings_menu';
import { PREREQUISITE_CONSTANT } from '../jsx/constant';
import VariableSelector from '@pagescommon/variable-selector-myinput';
import { calculatePosition, getScreenPosition } from '@util/common_utils';
export default class PreRequisite_Editor extends Component {
  constructor(props) {
    super(props);
    if (props.isOpen === PREREQUISITE_CONSTANT.TRUE) {
      this.state = {
        editorHeight: PREREQUISITE_CONSTANT.EDITOR_HEIGHT,
        editorWidth: PREREQUISITE_CONSTANT.EDITOR_WIDTH,
        readOnly: PREREQUISITE_CONSTANT.FALSE,
        change: PREREQUISITE_CONSTANT.ONlOAD_CHANGE,
        showDropDown: false,
        variableDetails: null,
        editorValue: null,
        inputValue: '',
        dollarPosition: { top: 0, left: 0 },
      };
      this.editorRef = createRef(null);
    }
  }

  apiDataCode = PREREQUISITE_CONSTANT.ONlOAD_CHANGE;
  variableObj = {};
  markers = [];

  componentDidUpdate(prevProps, prevState) {
    const editorData = this.state.change;
    if (prevState.change !== editorData) {
      if (this.state.editorValue?.lines[0] === '$' || (editorData && editorData[editorData?.length - 1] === '$')) {
        this.setState({ inputValue: editorData });
      }
    }
  }

  showAlert = (rowDefaultSnippet, isModified) => {
    const editor = this.editorRef.current.editor;
    const session = editor.getSession();
    const Value = rowDefaultSnippet.trimStart();
    const cursorPosition = editor.getCursorPosition();
    const currentRow = cursorPosition?.row;
    const text = session.getDocument().getLine(currentRow);
    const column = editor.getSession().getLine(currentRow).length;
    const position = editor.getSession().documentToScreenPosition(currentRow, column);
    let newRow = '';
    if (text.length > 0) {
      newRow = '\n';
    }
    session.insert(position, newRow + Value);
    let appendValueItem = rowDefaultSnippet;
    if (appendValueItem && isModified) {
      appendValueItem = rowDefaultSnippet;
    } else {
      appendValueItem = editor.getValue();
    }
    this.editorRef.current.editor.focus();
    let value = appendValueItem;
    this.props.getCodeValue(appendValueItem, value);
    this.setState({
      change: appendValueItem,
    });
  };
  onBlurValue = () => {
    setTimeout(() => {
      this.setState({
        showDropDown: false,
      });
      this.forceUpdate();
    }, 500);
  };
  onFocusValue = () => {
    if (this.state.change?.charAt(this.state.change?.length - 1) === '$') {
      this.setState({
        showDropDown: true,
      });
    }
    this.forceUpdate();
  };
  onChangeValue = (value, event) => {
    this.props.compileAndSave(value);
    this.setState({
      change: value,
      editorValue: event,
    });
    if (event.lines[0] === '$' && event.action === 'insert') {
      const { pageX, pageY } = getScreenPosition(this.editorRef.current);
      this.setState({
        showDropDown: true,
        variableDetails: event.start,
        dollarPosition: { top: pageY, left: pageX },
      });
    }

    if (value[value.length - 1] === '$') {
      this.setState({
        showDropDown: true,
      });
    }
    if (event.lines[0] === '$' && event.action === 'remove') {
      this.setState({
        showDropDown: false,
      });
    }
    if (value !== '' && value !== ' ') {
      let objKeys = Object.keys(this.variableObj).join('|');
      let newObjStr = new RegExp(`${objKeys}`, 'g');
      this.apiDataCode = this.addVariable(value, newObjStr, this.variableObj);
    } else {
      this.apiDataCode = '';
    }
    this.props.getCodeValue(this.apiDataCode, value);
    this.forceUpdate();
    if (event?.lines[0].length > 0) {
      this.props.handleSaveAsSteps(false);
    }
  };

  onClickVariableSelector = (name, data) => {
    let tempStateArray = [];
    let tempRow = this.state.variableDetails.row;
    let tempColumn = this.state.variableDetails.column;
    const isValueExist = this.state.inputValue.indexOf('\n');
    if (isValueExist !== -1) {
      tempStateArray = this.state.inputValue.split('\n');
    } else {
      tempStateArray = this.state.inputValue.split('\r');
    }
    tempStateArray[tempRow] =
      tempStateArray[tempRow].slice(0, tempColumn) +
      tempStateArray[tempRow].slice(tempColumn + 1, tempStateArray[tempRow].length);

    let tempValue = this.props.onSetValueByType(name, data.type, data);
    tempStateArray[tempRow] = this.addStr(tempStateArray[tempRow], tempColumn, tempValue);
    let tempStateStr = tempStateArray.join('\n');
    this.variableObj[name] = data.id;
    let objKeys = Object.keys(this.variableObj).join('|');
    let newObjStr = new RegExp(`${objKeys}`, 'g');

    this.setState({
      showDropDown: false,
      change: tempStateStr,
    });
    this.apiDataCode = this.addVariable(tempStateStr, newObjStr, this.variableObj);
    this.props.getCodeValue(this.apiDataCode, tempStateStr);
    this.forceUpdate();
  };
  addStr = (str, index, stringToAdd) => {
    if (str[str?.length - 1] === '$' && this.state.editorValue.action === 'remove') {
      return str?.substring(0, index) + stringToAdd.slice(1) + str?.substring(index, str.length);
    }
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  };

  addVariable = (str, objKeys, varObj) => {
    return str.replace(objKeys, function (matched, index) {
      if (matched !== '') {
        return varObj[matched];
      } else {
        return '';
      }
    });
  };
  handleEditorValue = (value) => {
    this.setState({ showDropDown: value });
  };
  render() {

    const { JAVA, UNIQUE_ID_OF_PREREQUISITE_DIV, TOMMORROW, TRUE, MINILINES, MAXLINES, TABSIZE } = PREREQUISITE_CONSTANT;
    const { editorHeight, editorWidth, readOnly, change, showDropDown, editorValue } = this.state;
    const { preRequisiteCodeValue, MyAlert } = this.props;

    return (
      <Fragment>
        <AceEditor
          ref={this.editorRef}
          mode={JAVA}
          theme={TOMMORROW}
          name={UNIQUE_ID_OF_PREREQUISITE_DIV}
          className="ace-common-styles"
          height={editorHeight}
          width={editorWidth}
          showGutter={TRUE}
          readOnly={readOnly}
          minLines={MINILINES}
          maxLines={MAXLINES}
          onChange={this.onChangeValue}
          onBlur={this.onBlurValue}
          onFocus={this.onFocusValue}
          editorProps={{ $blockScrolling: TRUE }}
          MyAlert={MyAlert}
          setOptions={{
            enableBasicAutocompletion: TRUE,
            enableLiveAutocompletion: TRUE,
            enableSnippets: TRUE,
            showLineNumbers: TRUE,
            tabSize: TABSIZE,
            useSoftTabs: TRUE,
          }}
          value={this.props.getUserInputValue(preRequisiteCodeValue, true) || change}
          {...this.props}
        />
        <div className={`dropdown-wrapper ${showDropDown ? 'open' : 'closed'}`}>
        {showDropDown && change ? (
          <VariableSelector
            ShowDropDown={showDropDown}
            onClickGetValue={this.onClickVariableSelector}
            currentValue={editorValue}
            type="Editor"
            value={change}
            handleEditorValue={this.handleEditorValue}
            editorRef={this.editorRef}
            positionStyle={calculatePosition(this.state.dollarPosition, false)}
            {...this.props}
          />
        ) : null}
        </div>
      </Fragment>
    );
  }
}

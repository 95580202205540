import { useCallback, useEffect, useState } from 'react';

function useSelectedProjectListener() {
  const [selectedProject, setSelectedProject] = useState({});
  const [previousSelectedProject, setPreviousSelectedProject] = useState({});
  const [initProjectChanged, setInitProjectChanged] = useState(false);

  const handleSelectedProjectChangeListinerStart = useCallback(() => {
    let listenerInterval;
    clearInterval(listenerInterval);
    listenerInterval = setInterval(() => {
      setSelectedProject((currentSelectedProject) => {
        const selectedProjectFromStorage = localStorage.getItem('selected-project') || '{}';

        if (JSON.stringify(currentSelectedProject) === selectedProjectFromStorage) {
          return currentSelectedProject;
        }
        setPreviousSelectedProject(currentSelectedProject);
        return JSON.parse(selectedProjectFromStorage);
      });
    }, 100);
  }, []);

  useEffect(() => {
    if (initProjectChanged && previousSelectedProject.id !== selectedProject.id) {
      const selectedProjectChangedEvent = new CustomEvent('selectedProjectChanged', {
        detail: {
          selectedProject,
        },
      });

      document.dispatchEvent(selectedProjectChangedEvent);
    } else if (Object.keys(selectedProject).length) {
      setInitProjectChanged(true);
    }
  }, [selectedProject]);

  return [selectedProject, handleSelectedProjectChangeListinerStart];
}

export default useSelectedProjectListener;

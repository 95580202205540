export const billingCycles = [
  { label: 'Monthly', value: 'Monthly', months: 1 },
  { label: 'Quarterly', value: 'Quarterly', months: 3 },
  { label: 'HalfYearly', value: 'HalfYearly', months: 6 },
  { label: 'Yearly', value: 'Yearly', months: 12 },
];

export const automationFeatures = ['Web Service', 'Web', 'Mobile', 'Salesforce', 'MS Dynamics', 'All'];

const LICENSE_FORM_TYPES = {
  buy: {
    type: 'BUY',
    action: 'Buy',
    label: 'Buy New License',
    endingPath: '/buy',
  },
  renewUpgrade: {
    type: 'RENEW_UPGRADE',
    action: 'Renew',
    label: 'Renew License',
    endingPath: '/upgrade-and-renew',
  },
  renewDowngrade: {
    type: 'RENEW_DOWNGRADE',
    action: 'Renew',
    label: 'Renew License',
    endingPath: '/downgrade-and-renew',
  },
  renew: {
    type: 'RENEW',
    action: 'Renew',
    label: 'Renew License',
    endingPath: '/renew',
  },
  upgrade: {
    type: 'UPGRADE',
    action: 'Upgrade',
    label: 'Upgrade License',
    endingPath: '/upgrade',
  },
  downgrade: {
    type: 'DOWNGRADE',
    action: 'Downgrade',
    label: 'Downgrade License',
    endingPath: '/downgrade',
  },
};

const FREE_LICENSE_TYPE = 'C-Basic';
const PAID_LICENSE_TYPE = 'Paid';
const C_PROFESSIONAL_LICENSE_TYPE = 'C-Professional';
export const CLOUD_BASIC_TYPE = 'Cloud Basic';
export const CLOUD_PROFESSIONAL_TYPE = 'Cloud Professional';

const INITIAL_PARALLEL_RUN_LIMITS = {
  min: 1,
  max: 3000,
};

const INITIAL_STORAGE_LIMITS = {
  min: 2,
  max: 1000,
};

const LICENSE_PERIOD_STATUS = {
  ACTIVE: 'success',
  WILL_EXPIRE: 'warning',
  EXPIRED: 'danger',
};

export const daysInMonth = 30;

const LICENSE_NAME_VALIDATIONS = {
  TOO_SHORT: ['License name is too short.', 'Minimum 3 characters are required'],
  TOO_LONG: ['License name is too long.', 'Maximum 25 characters are allowed'],
  ALREADY_EXISTS: ['License name already exists.', 'Please provide some different name.'],
  MIN_LENGTH: 3,
  MAX_LENGTH: 25,
};

const TAX_PERCENTAGE = 0;

function getLicensePeriodStatus(license) {
  if (license) {
    if (license.remainingDays < 1 && license?.licenseStatus === 'EXPIRED') {
      return LICENSE_PERIOD_STATUS.EXPIRED;
    } else if (license.remainingDays < 8 && license?.licenseType === FREE_LICENSE_TYPE) {
      return LICENSE_PERIOD_STATUS.WILL_EXPIRE;
    } else if (license.remainingDays < 16 && license?.billingCycle === 'Yearly') {
      return LICENSE_PERIOD_STATUS.WILL_EXPIRE;
    } else if (
      license.remainingDays < 8 &&
      license?.billingCycle !== 'Yearly' &&
      license?.licenseType !== FREE_LICENSE_TYPE
    ) {
      return LICENSE_PERIOD_STATUS.WILL_EXPIRE;
    }
  }
  return LICENSE_PERIOD_STATUS.ACTIVE;
}

function getLicensePlanCost({ licenseType, licensePlan, billingCycle, licenseData, formType, numberOfParallelRuns }) {
  let planCost = undefined;
  if (licenseType && licensePlan) {
    switch (billingCycle) {
      case billingCycles[0]:
        planCost = Number(grandTotal);
        break;
      case billingCycles[1]:
        planCost = Number(grandTotal);
        break;
      default:
        planCost = 0;
        break;
    }
  }

  return planCost;
}

export const geStorageCost = (chosenStorage, defaultStorage, cost, months) =>
  (chosenStorage - defaultStorage) * cost * months;

export const getPlatformCost = (cost, months, numOfParallelRuns) => cost * months * numOfParallelRuns;

export const getSelectedAutomationFeaturesCost = (planList, months, numOfParallelRuns) =>
  totalChargesOfAllSelectedPlans(planList) * numOfParallelRuns;

export const getSelectedManualFeaturesCost = (planList, months) => totalChargesOfAllSelectedPlans(planList);

export const getPlatfromCharge = (billingCycle, licensePlan) => {
  if ([1, 'Monthly']?.includes(billingCycle)) {
    return licensePlan?.monthlyPlatformCharges;
  } else if ([3, 'Quarterly']?.includes(billingCycle)) {
    return licensePlan?.quarterlyPlatformCharges;
  } else if ([6, 'HalfYearly']?.includes(billingCycle)) {
    return licensePlan?.halfYearlyPlatformCharges;
  } else {
    return licensePlan?.yearlyPlatformCharges;
  }
};

export const selectedPlansList = (selectedService, cycle, serviceList) => {
  let planDetails = serviceList?.filter((service) => {
    if (service.plan === selectedService) {
      planDetails.name = service.plan;
      planDetails.cost =
        cycle === 1
          ? service?.monthlyCharge
          : cycle === 3
          ? service?.quarterlyCharge
          : cycle === 6
          ? service?.halfYearlyCharge
          : service?.yearlyCharge;
    }
    return planDetails;
  });
  // return planDetails;
};

export const getNetTotal = (paymentCalculationData) => {
  let {
    platformCost,
    months,
    numOfParallelRuns,
    automationList,
    manualList,
    chosenStorage,
    defaultStorage,
    additionalStorageCost,
  } = paymentCalculationData;

  return (
    platformCost * numOfParallelRuns +
    getSelectedAutomationFeaturesCost(automationList, months, numOfParallelRuns) +
    getSelectedManualFeaturesCost(manualList, months) +
    geStorageCost(chosenStorage, defaultStorage, additionalStorageCost, months)
  );
};

export const totalChargesOfAllSelectedPlans = (list) => {
  return list?.reduce((acc, item) => {
    return acc + item?.cost;
  }, 0);
};
export const grandTotal = (tax = 0, netTotal, couponDiscountedValue = 0) => tax + netTotal - couponDiscountedValue;

//Start for upgrade license

// case:1 if parallel runs are not changed

export const getUpgradeAutomationFeaturesCost = (automationObj) => {
  let { listOfAutomation, months, remainingDays, parallelRuns } = automationObj;
  let automationCost =
    (getSelectedAutomationFeaturesCost(listOfAutomation, months, parallelRuns) / (daysInMonth * months)) *
    remainingDays;
  return automationCost;
};

export const getUpgradeManualCost = (manualObj) => {
  let { manualList, months, remainingDays } = manualObj;
  let result = (getSelectedManualFeaturesCost(manualList, months) / (daysInMonth * months)) * remainingDays;
  return result;
};

export const upgradeStorageCost = (storageObj) => {
  let { chosenStorage, existingStorage, storageCost, remainingDays } = storageObj;
  let result;
  if (chosenStorage > existingStorage) {
    result =
      (Number(chosenStorage) - Number(existingStorage)) * ((Number(storageCost) / daysInMonth) * Number(remainingDays));
  } else result = 0;

  return result;
};

export const upgradeNetTotal = (netTotalObj) => {
  let { automationObj, manualObj, storageObj } = netTotalObj;
  let automationCost = getUpgradeAutomationFeaturesCost(automationObj);
  let manualCost = getUpgradeManualCost(manualObj);
  let storageCost = upgradeStorageCost(storageObj);
  let netTotal = automationCost + manualCost + storageCost;
  return netTotal;
};

export const getRemainingDaysCost = (cost, remainingDays, months) => {
  return (cost / (daysInMonth * months)) * (remainingDays == 0 ? 1 : remainingDays);
};

//End for upgrade license

const changeInLicensesEvent = new CustomEvent('changeInLicenses');

function hasPaidFuturePlan(license) {
  return license?.futureLicensePlan?.paymentStatus === 'PAID';
}

export const getLicenseFeatures = (featuresList) => {
  let tempList = [
    {
      name: 'Automation',
      list: [],
    },
    {
      name: 'Manual Test Case Management',
      list: [],
    },
    {
      name: 'Defects Management',
      list: [],
    },
  ];
  if (featuresList?.includes('All')) {
    tempList = [
      {
        name: 'Automation',
        list: ['Web Service', 'Web', 'Mobile', 'Salesforce', 'MS Dynamics'],
      },
      {
        name: 'Manual Test Case Management',
        list: ['Manual Testcases'],
      },
      {
        name: 'Defects Management',
        list: ['Defects'],
      },
    ];
  } else {
    featuresList?.filter((service) => {
      if (service?.toLowerCase() === 'manual testcases') {
        tempList[1]?.list?.push(service);
      } else if (service?.toLowerCase() === 'defects') {
        tempList[2]?.list?.push(service);
      } else {
        tempList[0]?.list?.push(service);
      }
    });
  }
  return tempList;
};

//TODO:: Add global constants
const getSelectedLicenseFeature = (features) => {
  if (features.includes('ManualTestcases')) {
    return 'Manual TestCase Management';
  } else if (features.includes('Defects')) {
    return 'Defects Management';
  } else return '';
};

const getSelectedCloudServices = (services) => {
  if (services.includes('Browsers')) {
    return 'FireCloud - Browsers';
  } else {
    return 'FireCloud - Devices';
  }
};
const selectedServicesTextFormat = (service) => {
  switch (service) {
    case 'MSDynamics':
      return 'MS Dynamics';
    case 'WebService':
      return 'Web Service';
    case 'ManualTestcases':
      return 'Manual Testcases';
    default:
      return service;
  }
};

export const isUpgradeAndCProfessional = (formType, licenseType) => {
  if (formType === LICENSE_FORM_TYPES.upgrade.type && licenseType !== FREE_LICENSE_TYPE) {
    return true;
  } else {
    return false;
  }
};

export const getRemainingDays = (days) => {
  if (days <= 1) {
    return 'day';
  } else {
    return 'days';
  }
};

export const deleteLicenseDataDays = 178;

export const showOnlyDate = (date) => {
  if (date) {
    return date.slice(0, -8);
  }
};

export const showOnlyDateAndTime = (date) => {
  if (date) {
    let formatDate = date.slice(0, -3);
    return formatDate.replace(' ', ', ');
  }
};

export const getSuperAdmins = (licenseUsers) => {
  let superAdminsList = licenseUsers?.filter((user) => {
    if (user?.privilege === 'Super Admin') {
      return user?.emailId;
    }
    return null;
  });
  return superAdminsList;
};

export function getLicenseBillingCycle() {
  return JSON.parse(localStorage.getItem('test-optimize-user'))?.billingCycle;
}

export const alertLicenseExpiredUser = (billingCycle, remainingDays) => {
  if (billingCycle === 'Yearly' && remainingDays <= 15) {
    return true;
  } else if (billingCycle !== 'Yearly' && remainingDays <= 7) {
    return true;
  } else {
    return false;
  }
};

export const isEmptyPhoneNumber = (phoneNumber) => {
  if (phoneNumber === '+91' || phoneNumber === '91' || phoneNumber === undefined) return '--';
  else {
    return phoneNumber;
  }
};

export const getNewLicenseTypes = (licenseType) => {
  if (licenseType === 'C-Basic') {
    return CLOUD_BASIC_TYPE;
  } else if (licenseType === 'C-Professional') {
    return CLOUD_PROFESSIONAL_TYPE;
  }
};

export {
  LICENSE_FORM_TYPES,
  FREE_LICENSE_TYPE,
  PAID_LICENSE_TYPE,
  C_PROFESSIONAL_LICENSE_TYPE,
  INITIAL_PARALLEL_RUN_LIMITS,
  INITIAL_STORAGE_LIMITS,
  LICENSE_PERIOD_STATUS,
  LICENSE_NAME_VALIDATIONS,
  TAX_PERCENTAGE,
  getLicensePeriodStatus,
  getLicensePlanCost,
  changeInLicensesEvent,
  hasPaidFuturePlan,
  getSelectedLicenseFeature,
  getSelectedCloudServices,
  selectedServicesTextFormat,
};

import React, { useState, Fragment, useEffect, useRef, useContext } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import History from '../../shared/history/history';
import LibraryChild from './library_child';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import Parameter from './step_group_steps/parameter';
import ScriptSteps from '../../../test-development/script/scripts/steps/script-steps';
import VariableRouter from './variable_router';
import { getPageTreeReq } from '../../../../api/api_services';
import ElementModal from '../project_elements/modals/ElementModal';
import CreateVariable from '../../../test-development/shared/variables/create-edit-variables';
import ElementConflictModal from '../project_elements/shared_recorded_elements/element_conflict_modal';
import MergeConflictModal from '../project_elements/shared_recorded_elements/shared_elements/merge_conflict_modal';
import { getStepGroupReq } from '../../../../api/api_services';
import ContextMenuOption from './step_group_menu_option';
import { ArrowLeft } from '@material-ui/icons';
import Restapi, { RestApiProvider } from '@pages/test-development/script/scripts/webservice/restapi/restapi';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { getLicenseFeatures } from '@src/util/common_utils';
import { DataContext } from '@pages/common/Layout/components/Navigation';
import WebserviceWarningModal from '@pages/test-development/script/scripts/webservice-warning-modal';
import { WEBSERVICE } from '@pages/test-development/script/scripts/webservice/restapi/constants/constants';
import { ASSERT_CONSTANT } from '@pages/test-development/script/scripts/webservice/restapi/request/assert/jsx/constant';
import '@pages/test-development/script/scripts/webservice/restapi/css/common_Webservice.scss';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function StepGroupRouter(props) {
  const { history } = props;
  let historyLocation = history.location.pathname;
  let index = historyLocation.lastIndexOf('/');
  let path = historyLocation.slice(0, index);
  let count = path.split('/').length;
  let tab = history.location.pathname.split('/')[count];
  let activeTab;
  if (tab === 'Step Group Variables' || tab === 'Global Variables' || tab === 'Project Environment Variables') {
    activeTab = 'Variables';
    let name = historyLocation.slice(0, index);
    let indexUpdated = name.lastIndexOf('/');
    path = name.slice(0, indexUpdated);
    count = path.split('/').length;
  } else {
    activeTab = tab;
    path = historyLocation.slice(0, index);
    count = path.split('/').length;
  }
  const search = window.location.search;
  const projectDetails = JSON.parse(localStorage.getItem('selected-project'));
  const countData = JSON.parse(localStorage.getItem('allowedProjects'))?.features?.repository?.restrictions;
  const id = new URLSearchParams(search).get('id');
  const stepGroupName = new URLSearchParams(search).get('stepGroup');
  const parentId = new URLSearchParams(search).get('parentId');
  const defaultStepGroup = new URLSearchParams(search).get('defaultStepGroup');
  let [openCreateElementModal, setOpenCreateElementModal] = useState(false);
  const [openCreateVariableModal, setOpenCreateVariableModal] = useState(false);
  const [reloadTree, setReloadTree] = useState(false);
  let [pageKeyForEle, setPageKeyForEle] = useState('');
  let [createAsSharedEle, setCreateAsSharedEle] = useState(false);
  let [sharingEle, setSharingELe] = useState();
  let [sharedConflictEles, setSharedConflictEles] = useState();
  let [elementConflictModal, setElementConflictModal] = useState(false);
  const [node, setNode] = useState({});
  let [stepGroupType, setStepGroupType] = useState();
  const elementPlatform = ['Web & Mobile (Android & iOS)', 'Web & Mobile (Android or iOS)'].includes(stepGroupType)
    ? 'Web'
    : ['Mobile (Android & iOS)', 'Mobile (Android or iOS)'].includes(stepGroupType)
    ? 'Android'
    : stepGroupType;
  let [openMergeConflictModal, setopenMergeConflictModal] = useState(false);
  const [selectedEnvironment, setSelectedEnvironment] = useState({});
  const [pageIndexValue, setPageIndex] = React.useState(0);
  const [menuOptions, setMenuOptions] = useState(['Web']);
  const [activeWebserviceData, setActiveWebserviceData] = useState(false);
  const [dataEnteredInWebservice, setDataEnteredInWebservice] = useState(false);
  const [clickedTabPath, setClickedPathTab] = useState(null);
  const [showWebserviceWarningModal, setShowWebserviceWarningModal] = useState(false);
  const inputElement = useRef();
  let [treeData, setTreeData] = useState([]);
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const { setWebserviceData } = useContext(DataContext);
  const { IMPORT_CURL_TEXT, IMPORT, OPEN_OPTIONS_TEXT } = WEBSERVICE;
  const { STEPGROUP } = ASSERT_CONSTANT;
  const contextMenuDataForWebservices = [
    {
      option: IMPORT_CURL_TEXT,
    },
  ];

  useEffect(() => {
    return () => {
      setDataEnteredInWebservice(false);
    };
  }, [dataEnteredInWebservice]);
  const setWebserviceStateValue = (val) => {
    setWebserviceData(val);
    setDataEnteredInWebservice(val);
    setActiveWebserviceData(val);
  };
  const handleWebServiceModal = async (action) => {
    if (action === 'exit') {
      await setWebserviceStateValue(false);
      setShowWebserviceWarningModal(false);
      props.history.push(clickedTabPath);
    } else {
      setShowWebserviceWarningModal(false);
      setClickedPathTab(null);
    }
  };
  const closeModal = (val) => {
    if (openCreateVariableModal) {
      setOpenCreateVariableModal(val);
    }
    if (openCreateElementModal) {
      setOpenCreateElementModal(val);
    }
    if (openMergeConflictModal) {
      setopenMergeConflictModal(val);
    }
    if (elementConflictModal) {
      setElementConflictModal(val);
    }
  };

  const createAsSharedElement = async (dataele, pageKey, createType, respData) => {
    setCreateAsSharedEle(true);
    setPageKeyForEle(pageKey);
    let response;
    setSharingELe(dataele);
    response = respData;
    if (
      response.data?.responseCode === 400 &&
      response.data?.message ===
        'Their is a conflict in Shared element would you like to crete New element or Merge into shared element'
    ) {
      setOpenCreateElementModal(false);
      setSharedConflictEles(response.data?.responseObject);
      setopenMergeConflictModal(true);
    } else {
      props.MyAlert.success(`${dataele.name} ${dataele.type} created successfully`);
      if (props.callGetEleReqs) {
        props.callGetEleReqs();
      }
    }
  };

  let updateSharedData = (value, nodeShared) => {
    let selectedNode = node.node;
    if (nodeShared) {
      selectedNode = nodeShared.node;
    }
    selectedNode.data.isShared = value;
    selectedNode.render(true, true);
  };

  let openElementConflict = (val) => {
    setElementConflictModal(val);
  };

  let unCheckShareElementBtn = (node) => {};
  useEffect(() => {
    if (projectDetails.type === 'Web') {
      let tempArray = [
        {
          Web: ['Web'],
        },
      ];
      setMenuOptions([...tempArray]);
    } else if (projectDetails.type === 'Mobile') {
      let tempArray = [
        {
          Android: ['Android'],
          iOS: ['iOS'],
          'Mobile (Android & iOS)': ['Android', 'iOS'],
          'Mobile (Android or iOS)': ['Mobile'],
        },
      ];
      setMenuOptions([...tempArray]);
    } else if (['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(projectDetails.type)) {
      let tempArray = [
        {
          Web: ['Web'],
          Android: ['Android', 'MobileWeb'],
          iOS: ['iOS', 'MobileWeb'],
          'Mobile (Android & iOS)': ['Android', 'iOS', 'MobileWeb'],
          'Mobile (Android or iOS)': ['Mobile', 'MobileWeb'],
          'Web & Mobile (Android & iOS)': ['Web', 'Android', 'iOS', 'MobileWeb'],
          'Web & Mobile (Android or iOS)': ['Web', 'Mobile', 'MobileWeb'],
        },
      ];
      setMenuOptions([...tempArray]);
    }
  }, [projectDetails?.type]);

  const isEditable = React.useMemo(() => window.permission.isEditAccess('repository'), []);
  const licenseFeatures = getLicenseFeatures();
  const hasWebService = window.privilege.hasWebService(licenseFeatures);
  const hasCBasic = window.privilege.hasCBasic(licenseFeatures);
  const [isImportCurlOpen, setImportCurlOpen] = useState(false);
  const [tabsList, setTabsList] = useState([
    {
      name: 'Library',
      logo: '/assets/images/project_rec_logo.svg',
      selectedLogo: '/assets/images/project_rec_logo_blue.svg',
      pathUrl: `${path}/Library?id=${id}&type=Library`,
      key: 'library-tab',
      selectedName: 'Library',
    },
    {
      name: 'History',
      logo: '/assets/images/user_logo.svg',
      selectedLogo: '/assets/images/history_logo_blue.svg',
      pathUrl: `${path}/history?id=${id}&type=Library`,
      key: 'history-tab',
      selectedName: 'history',
    },
  ]);

  useEffect(() => {
    let tabsArray = [
      {
        name: 'Library',
        logo: '/assets/images/project_rec_logo.svg',
        selectedLogo: '/assets/images/project_rec_logo_blue.svg',
        pathUrl: `${path}/Library?id=${id}&type=Library`,
        key: 'library-tab',
        selectedName: 'Library',
      },
    ];
    if (parentId) {
      tabsArray = [
        {
          name: 'Steps',
          logo: '/assets/images/project_rec_logo.svg',
          selectedLogo: '/assets/images/project_rec_logo_blue.svg',
          pathUrl: `${path}/Steps?id=${id}&parentId=${parentId}&stepGroup=${stepGroupName}&defaultStepGroup=${defaultStepGroup}`,
          key: 'Steps-tab',
          selectedName: 'Steps',
        },
        {
          name: 'Parameters',
          logo: '/assets/images/user_logo.svg',
          selectedLogo: '/assets/images/history_logo_blue.svg',
          pathUrl: `${path}/Parameters?id=${id}&parentId=${parentId}&stepGroup=${stepGroupName}&defaultStepGroup=${defaultStepGroup}`,
          key: 'Parameters-tab',
          selectedName: 'Parameters',
        },
        {
          name: 'Variables',
          logo: '/assets/images/project_rec_logo.svg',
          selectedLogo: '/assets/images/project_rec_logo_blue.svg',
          pathUrl: `${path}/Variables/Step Group Variables?id=${id}&parentId=${parentId}&stepGroup=${stepGroupName}&defaultStepGroup=${defaultStepGroup}`,
          key: 'Variable-tab',
          selectedName: 'Variables',
        },
      ];
    }
    if (parentId && (hasWebService || hasCBasic)) {
      tabsArray.push({
        name: 'Web Service',
        selectedLogo: '/assets/images/project_rec_logo_blue.svg',
        pathUrl: `${path}/webservices?id=${id}&parentId=${parentId}&stepGroup=${stepGroupName}&defaultStepGroup=${defaultStepGroup}`,
        key: 'webservice-tab',
        selectedName: 'Webservices',
      });
    }
    if (parentId && id) {
      getStepGroupReq(parentId, id).then((response) => {
        if (response.data.responseCode === 200 && response.data.responseObject) {
          let responseObject = response.data.responseObject;
          setStepGroupType(responseObject.type);
        }
      });
    }

    setTabsList(tabsArray);
  }, [parentId, id]);

  const createElementModal = async () => {
    try {
      let res;
      if (['Web & Mobile (Android & iOS)', 'Web & Mobile (Android or iOS)'].includes(stepGroupType)) {
        res = await getPageTreeReq(projectDetails.type, 'Web');
      } else if (['Mobile (Android & iOS)'].includes(stepGroupType)) {
        res = await getPageTreeReq(projectDetails.type, 'Android');
      } else if (['Mobile (Android or iOS)'].includes(stepGroupType)) {
        res = await getPageTreeReq(projectDetails.type, 'Mobile');
      } else if (projectDetails.platform) {
        res = await getPageTreeReq(projectDetails.type, stepGroupType);
      } else {
        res = await getPageTreeReq(projectDetails.type);
      }
      if (res?.data?.responseObject?.licenseLevelElementCount < countData.numberOfElements) {
        setTreeData(res.data.responseObject.pageTree);
        setOpenCreateElementModal(true);
      } else {
        props.MyAlert.info('Exceeded allocated limit, please upgrade or buy new license');
      }
    } catch (err) {
      console.error(err);
    }
  };

  const openElementModalFunction = async (e, selOption) => {
    setSelectedEnvironment(selOption);
    try {
      let res;
      res = await getPageTreeReq(projectDetails.type, selOption);
      if (res?.data?.responseObject?.licenseLevelElementCount < countData.numberOfElements) {
        setTreeData(res.data.responseObject.pageTree);
        setOpenCreateElementModal(true);
        inputElement.current.style.visibility = 'hidden';
      } else {
        props.MyAlert.info('Exceeded allocated limit, please upgrade or buy new license');
      }
    } catch (err) {
      console.error('Create Element Modal', err);
    }
  };

  const createVariableModal = () => {
    setOpenCreateVariableModal(true);
  };
  const optionMethodsHandler = (option) => {
    if (option === 'Add Variable') {
      createVariableModal();
    }
    if (option === 'Add Element') {
      if (!(stepGroupType === 'Webservice' || stepGroupType === 'Database')) {
        createElementModal();
      }
    }
    if (option === 'Add Program Element') {
      props.history.push('/repository/program_elements');
    }
    if (option === IMPORT_CURL_TEXT) {
      setImportCurlOpen(IMPORT);
    }
  };

  const contextMenuDataForSteps = [
    ...(isEditable
      ? [
          {
            option: 'Add Element',
          },
          {
            option: 'Add Variable',
          },
        ]
      : []),
    // {
    //   option: "Optimizer",
    // },
    // {
    //   option: "NLP Help",
    // },
  ];
  const contextMenuData = [
    {
      option: 'Optimizer',
    },
    {
      option: 'NLP Help',
    },
  ];

  const contextMenuVariableTabContent = [
    {
      option: 'Step Group Variables',
      path: `${path}/Variables/Step Group Variables?id=${id}&parentId=${parentId}&stepGroup=${stepGroupName}&defaultStepGroup=${defaultStepGroup}`,
    },
    {
      option: 'Global Variables',
      path: `${path}/Variables/Global Variables?id=${id}&parentId=${parentId}&stepGroup=${stepGroupName}&defaultStepGroup=${defaultStepGroup}`,
    },
    {
      option: 'Project Environment Variables',
      path: `${path}/Variables/Project Environment Variables?id=${id}&parentId=${parentId}&stepGroup=${stepGroupName}&defaultStepGroup=${defaultStepGroup}`,
    },
  ];

  const contextMenuFun = (e) => {
    contextMenuVariableTabContent.map((obj) => {
      let tempObj = contextMenuVariableTabContent.find((key) => e?.target?.outerText === key?.option);
      if (tempObj) {
        props.history.push(tempObj.path);
      }
    });
  };

  const handleTabClick = async (url = ' ', selectedTabName) => {
    if (activeWebserviceData && activeTab === 'webservices') {
      if (selectedTabName === 'Web Service') {
        await setDataEnteredInWebservice(false);
        props.history.push(url);
      } else {
        await setDataEnteredInWebservice(true);
        setClickedPathTab(url);
        setShowWebserviceWarningModal(true);
      }
    } else {
      setActiveWebserviceData(false);
      props.history.push(url);
    }
  };
  const renderTabs = () => {
    return (
      <div className="page-tab-with-actions step-child-margin-style">
        <ul className="navBar text-blue-600 flex flex-row mb-2">
          {tabsList.map((tab, index) => {
            return (
              <span key={tab.selectedName}>
                <span
                  className={`cursor-pointer flex flex-row p-2 pb-3 mr-4 ${
                    activeTab === tab.selectedName ? 'selected-repo-testdev-tab-style' : 'repo-testdev-tab-style'
                  }`}
                >
                  <a
                    className={`${
                      tab.name === 'Web Service' && defaultStepGroup === 'true' && 'opacity-50 hover:opacity-50'
                    }`}
                    onClick={(event) => {
                      if (tab.name === 'Web Service' && defaultStepGroup === 'true') {
                        event.preventDefault();
                      } else {
                        event.preventDefault();
                        handleTabClick(tab.pathUrl, tab.name);
                      }
                    }}
                  >
                    {tab.name}
                  </a>
                  {activeTab === tab.selectedName && tab.selectedName === 'Variables' ? (
                    <ContextMenuOption
                      contextMenuData={contextMenuVariableTabContent}
                      func={(e) => {
                        contextMenuFun(e);
                      }}
                    ></ContextMenuOption>
                  ) : null}
                </span>
                {tab.selectedName === activeTab && (activeTab === 'Variables' || activeTab === 'settings') ? (
                  <hr className="bg-blue-700 ml-2 mr-8 p-px -mt-5" />
                ) : null}
                {activeTab === tab.selectedName && activeTab !== 'settings' && activeTab !== 'Variables' ? (
                  <hr className="bg-blue-700 ml-2 mr-6 p-px -mt-3" />
                ) : null}
              </span>
            );
          })}
        </ul>
        <div className="clearfix ml-auto">
          {activeTab === 'Steps'
            ? renderMenuDataForSteps()
            : activeTab === 'webservices'
            ? renderMenuDataForWebservice()
            : null}
        </div>
      </div>
    );
  };
  const renderMenuDataForSteps = () => {
    return (
      <Menu as="div" className="relative text-left">
        {({ open }) => (
          <>
            <div>
              <Menu.Button>
                <span className="sr-only">{OPEN_OPTIONS_TEXT}</span>
                <DotsVerticalIcon className="h-5 w-5 black" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none  z-10"
                ref={inputElement}
              >
                <div className="py-1">
                  {activeTab === 'Steps'
                    ? contextMenuDataForSteps.map((data) => {
                        let childOptionNeed = false;
                        if (data.option === 'Add Element') {
                          childOptionNeed = true;
                        } else {
                          childOptionNeed = false;
                        }
                        return (
                          <div>
                            {!childOptionNeed ? (
                              <Menu.Item
                                key={data.option}
                                disabled={
                                  data.option === 'Optimizer' ||
                                  (data.option === 'Add Element' && ['Webservice', 'Database'].includes(stepGroupType))
                                    ? true
                                    : false
                                }
                              >
                                {({ active }) => (
                                  <label
                                    onClick={() => {
                                      optionMethodsHandler(data.option);
                                    }}
                                    className={classNames(
                                      active ? 'bg-gray-100 text-blue-700' : 'text-gray-700',
                                      `block px-4 py-2 fontPoppinsRegularMd ${
                                        data.option === 'Add Element' &&
                                        (stepGroupType === 'Webservice' || stepGroupType === 'Database')
                                          ? 'opacity-50'
                                          : ''
                                      }`
                                    )}
                                  >
                                    {data.option}
                                  </label>
                                )}
                              </Menu.Item>
                            ) : (
                              <Menu as="div" className="more-button relative inline-block text-left">
                                {({ open }) => (
                                  <>
                                    <div>
                                      <Menu.Button
                                        disabled={['Webservice', 'Database'].includes(stepGroupType)}
                                        className={
                                          'ml-4 text-gray-700 block py-2 text-sm fontPoppinsRegularMd  ' +
                                          (['Webservice', 'Database'].includes(stepGroupType) ? 'opacity-50' : '')
                                        }
                                        onMouseOver={(e) => {
                                          let mouse = e.clientY;
                                          setPageIndex(mouse);
                                        }}
                                      >
                                        <ArrowLeft className="-ml-5" />
                                        Add Element
                                      </Menu.Button>
                                    </div>
                                    <Transition
                                      show={open}
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Menu.Items
                                        static
                                        className="z-50 origin-top-right  absolute  -mt-6 w-68 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none dropdown-child-option-style-stepGroup"
                                      >
                                        <div className="py-1">
                                          {menuOptions[0][stepGroupType]
                                            ?.filter((data) => {
                                              if (
                                                [
                                                  'Web & Mobile (Android or iOS)',
                                                  'Web & Mobile (Android & iOS)',
                                                ].includes(stepGroupType)
                                              ) {
                                                return true;
                                              }
                                              return data !== 'MobileWeb';
                                            })
                                            .map((data) => {
                                              let optionArray = data[stepGroupType];
                                              return (
                                                <Menu.Item key={data}>
                                                  {({ active }) => (
                                                    <label
                                                      onClick={(e) => openElementModalFunction(e, data)}
                                                      className={`${
                                                        active ? 'bg-gray-100 text-blue-700' : 'text-gray-700'
                                                      } block px-4 py-2 text-sm fontPoppinsRegularMd opacity-50"`}
                                                    >
                                                      {data}
                                                    </label>
                                                  )}
                                                </Menu.Item>
                                              );
                                            })}
                                        </div>
                                      </Menu.Items>
                                    </Transition>
                                  </>
                                )}
                              </Menu>
                            )}
                          </div>
                        );
                      })
                    : contextMenuData.map((data) => (
                        <Menu.Item>
                          {({ active }) => (
                            <label
                              onClick={() => {
                                optionMethodsHandler(data.option);
                              }}
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 fontPoppinsRegularMd'
                              )}
                            >
                              {data.option}
                            </label>
                          )}
                        </Menu.Item>
                      ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    );
  };
  const renderMenuDataForWebservice = () => {
    return (
      <Menu as="div" className="relative text-left">
        {({ open }) => (
          <>
            <div>
              <Menu.Button>
                <span className="sr-only">{OPEN_OPTIONS_TEXT}</span>
                <DotsVerticalIcon className="h-5 w-5 black" aria-hidden="true" />
              </Menu.Button>
            </div>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="origin-top-right absolute right-0 py-4 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none  z-10 webservice-context-menu-data"
                ref={inputElement}
              >
                <div className="py-1">
                  {contextMenuDataForWebservices.map((data) => {
                    return (
                      <div>
                        <Menu.Item key={data.option}>
                          {({ active }) => (
                            <label
                              onClick={() => {
                                optionMethodsHandler(data.option);
                              }}
                              className={classNames(
                                active ? 'webservice-context-menu-data-hover' : 'text-gray-700',
                                `block px-4 py-2 fontPoppinsRegularSm ${
                                  data.option === 'Add Element' &&
                                  (stepGroupType === 'Webservice' || stepGroupType === 'Database')
                                    ? 'opacity-50'
                                    : 'webservice-context-menu-data-hover:hover'
                                }`
                              )}
                            >
                              {data.option}
                            </label>
                          )}
                        </Menu.Item>
                      </div>
                    );
                  })}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    );
  };
  return (
    <div className="page-list-body">
      <div className="">
        {renderTabs()}
        {showWebserviceWarningModal && <WebserviceWarningModal handleWebserviceModal={handleWebServiceModal} />}
      </div>
      {parentId ? (
        <Switch>
          <Route path={`${path}/Steps`} exact={true} strict={true} key="Steps-Page">
            <ScriptSteps MyAlert={props.MyAlert} stepGroupName={stepGroupName} type={STEPGROUP} />
          </Route>
          <Route path={`${path}/Parameters`} exact={true} strict={true} key="Parameters-page">
            <Parameter MyAlert={props.MyAlert} />
          </Route>
          <Route path={`${path}/Variables/:variableName`} exact={true} strict={true} key="Steps-page">
            <VariableRouter {...props} type={STEPGROUP} MyAlert={props.MyAlert} />
          </Route>
          <RestApiProvider>
            <Route path={`${path}/webservices`} exact={true} strict={true} key="webservice-router-page">
              <Restapi
                {...props}
                type={STEPGROUP}
                resetProgress={resetProgress}
                startOrResumeProgress={startOrResumeProgress}
                stopProgress={stopProgress}
                MyAlert={props.MyAlert}
                variableData={props.variableData}
                variableType={props.variableType}
                createVariableModal={setWebserviceStateValue}
                setImportCurlOpen={setImportCurlOpen}
                isImportCurlOpen={isImportCurlOpen}
              />
            </Route>
          </RestApiProvider>
        </Switch>
      ) : (
        <Switch>
          <Route path={`${path}/Library`} exact={true} strict={true} key="Library-page">
            <LibraryChild MyAlert={props.MyAlert} />
          </Route>
          <Route
            path={`${path}/history`}
            exact={true}
            strict={true}
            key="stepgroup-history"
            component={History}
          ></Route>
        </Switch>
      )}
      {openCreateElementModal ? (
        <ElementModal
          treeData={treeData}
          mode="ADD"
          reloadTree={() => {}}
          closeModal={closeModal}
          MyAlert={props.MyAlert}
          createAsSharedElement={createAsSharedElement}
          projectType={projectDetails.type}
          platForm={selectedEnvironment}
          subPlatForm={projectDetails.subPlatForm ? projectDetails.subPlatForm : ''}
          scriptType={stepGroupType}
        />
      ) : null}
      {openMergeConflictModal ? (
        <MergeConflictModal
          closeModal={closeModal}
          updateSharedData={updateSharedData}
          openElementConflict={openElementConflict}
          reloadTree={setReloadTree}
          MyAlert={props.MyAlert}
          pageKeyForEle={pageKeyForEle}
          createAsSharedEle={createAsSharedEle}
          callGetEleReqs={props.callGetEleReqs}
          sharingEle={sharingEle}
          unCheckShareElementBtn={unCheckShareElementBtn}
        />
      ) : null}
      {elementConflictModal ? (
        <ElementConflictModal
          closeModal={closeModal}
          sharingEle={sharingEle}
          updateSharedData={updateSharedData}
          projectType={projectDetails.type}
          platForm={projectDetails.platform ? projectDetails.platform : ''}
          MyAlert={props.MyAlert}
          reloadTree={setReloadTree}
          pageKeyForEle={pageKeyForEle}
          createAsSharedEle={createAsSharedEle}
          callGetEleReqs={props.callGetEleReqs}
          unCheckShareElementBtn={unCheckShareElementBtn}
          sharedConflictEles={sharedConflictEles}
        />
      ) : null}
      {openCreateVariableModal ? (
        <CreateVariable MyAlert={props.MyAlert} closeModal={closeModal} type={STEPGROUP} />
      ) : null}
    </div>
  );
}

export default withRouter(StepGroupRouter);

import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import TableTree from '../../common/table_tree/table_tree';
import { getAllModuleTreeReq } from '../../../api/api_services';
import MoreInfoButton from '../../common/more_info_button';
import { FiMaximize2 } from 'react-icons/fi';
import { useTable, usePagination, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy } from 'react-table';
import matchSorter from 'match-sorter';
import { FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { CommonLoader } from '@src/pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { renderBrowserCell } from '../execution';
import { Tooltip } from '@material-ui/core';
import { getFinalMachineObject, getTruncatedText, calculatedScriptNumber, getReportTypes } from '@src/util/common_utils';
import { getModuleAndScriptData } from '@src/pages/test-development/shared/create-execution-tree';
import { getOsLabel } from './create-edit-child-pages/run-settings-child-pages/machine-child-pages/run-settings-machines-common/common-methods';
import MachineEnvironmentLayout from '@src/common/MachineEnvironmentLayout';
import MachineEnvironmentTableLayout from '@src/common/MachineEnvironmentTableLayout';
import { SLACK_CONSTANTS } from '@src/pages/configuration/plugin/plugin_constants';
import { EXECUTION_CONSTANTS } from '@common/ui-constants';
import { isEmptyValue } from '@src/util/common_utils';
let treeArrayForMultipleTree = [];
let totalScriptCount = 0;
const SuitePreview = (props) => {
  const { resetProgress, startOrResumeProgress } = useContext(ProgressContainerContext);
  let [isLoading, setIsLoading] = useState(false);
  const [isMultipleTreeLoading, setIsMultipleTreeLoading] = useState(true);
  let [treeData, setTreeData] = useState([]);
  let [rootData, setRootData] = useState([]);
  let [isExpandAll, setIsExpandAll] = useState(false);
  let [treeValue, setTreeValue] = useState('');
  let [reloadTree, setReloadTree] = useState(false);
  let [emailGroupNameArray, setEmailGroupNameArray] = useState([]);
  let [slackGroupNameArray, setSlackGroupNameArray] = useState([]);
  let [runSettingsData, setRunSettingsData] = useState({});
  const totalModCount = useRef(0);
  const {
    implicitWait,
    implicitWaitUnit,
    explicitlyWait,
    explicitlyWaitUnit,
    delayBetweenSteps,
    delayBetweenStepsUnit,
  } = props?.waitTime;
  const {
    terminateScriptIfTakesMoreTime,
    terminateScriptUnit,
    terminateSuiteSendEmail,
    terminateSuiteIfTakesMoreTime,
    terminateSuitetUnit,
    terminateScriptSendEmail,
  } = props?.suiteObj?.executionTermination;
  const { multiple, executionType, selectedMachines } = props?.machineObj;
  let history = useHistory();

  const plusIconArray = [
    {
      name: 'Add Module',
      value: 'folder',
    },
    {
      name: 'Add Script',
      value: 'file',
    },
  ];

  const LABELS = {
    EXPAND_ALL: 'Expand All',
    COLLAPSE_ALL: 'Collapse All',
    SHOW_ALL_ELEMENTS: 'Show all scripts',
    HIDE_ALL_ELEMENTS: 'Hide all scripts',
    SHOW_PRE_POST_CONDITION: 'Show Pre/Post Conditions',
    HIDE_PRE_POST_CONDITION: 'Hide Pre/Post Conditions',
  };

  async function getModuleTree() {
    resetProgress();
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 80,
    });
    setIsLoading(true);
    let getdata;
    let root;
    treeArrayForMultipleTree = [];
    try {
      getdata = await getAllModuleTreeReq();
      let rootDetails = getdata.data.responseObject.moduleTree[0];
      root = {
        subModuleCount: rootDetails.subModuleCount,
        title: rootDetails.title,
        scriptCount: rootDetails.scriptCount.$numberLong,
        key: rootDetails.key,
        ver: rootDetails.ver,
        modifiedByUname: rootDetails.modifiedByUname,
      };
      setRootData(root);
      setIsExpandAll(rootDetails.expanded);
      if (props.moduleArray) {
        if (props.moduleArray.length) {
          props.moduleArray.forEach((obj) => {
            obj.checkbox = false;
          });
        }

        setTreeData(props.moduleArray);
      } else {
        setTreeData([]);
      }
      resetProgress();
      setIsLoading(false);
    } catch (err) {
      resetProgress();
      setIsLoading(false);
      console.error('GET_MODULE_TREE : ', err);
    }
  }

  useEffect(() => {
    if (props?.emailObj?.recipientGroup?.length) {
      let emailGroupArray = props?.emailObj?.recipientGroup;
      let groupNameArray = [];
      emailGroupArray.forEach((obj) => {
        groupNameArray.push(obj?.groupName);
      });
      let uniqueChars = [...new Set(groupNameArray)];
      setEmailGroupNameArray(uniqueChars);
    }
    if (props?.slacObj?.recipientGroup?.length) {
      let slackGroupArray = props?.slacObj?.recipientGroup;
      let uniqueChars = [...new Set(slackGroupArray)];
      setSlackGroupNameArray(uniqueChars);
    }
    setTreeData([]);
    getModuleTree();
    getSuiteRunSettings();
  }, [reloadTree]);
  function calculateModuleCount(treeData) {
    if (treeData?.length) {
      treeData?.forEach((obj) => {
        if (obj?.folder) {
          totalModCount.current++;
          calculateModuleCount(obj?.children);
        }
      });
    }
    return totalModCount.current;
  }
  function calculateScriptCount(treeData) {
    if (treeData?.length) {
      treeData?.forEach((obj) => {
        if (obj?.folder) {
          filterScriptData(obj?.children);
          calculatedScriptNumber(obj);
        } else {
          if (obj?.type === 'Script') {
            totalScriptCount++;
          }
        }
      });
    }
    return totalScriptCount;
  }


  const getEmailNotificationTypes = (props) => {
    const parseBoolean = (value) => {
      if (typeof value === 'string') {
        return value.toLowerCase() === 'true';
      }
      return Boolean(value);
    };

    const notifications = [
      parseBoolean(props?.emailObj?.emailOnSuiteExecutionStart) && 'On Suite Execution Start',
      parseBoolean(props?.emailObj?.emailOnSuiteExecutionCompletion) && 'On Suite Execution Completion / Termination',
      parseBoolean(props?.emailObj?.emailManuallyTriggerEmailNotification) && 'Manually trigger email notification',
    ];

    return notifications.filter(Boolean).join(', ');
  };
  const getSlackNotificationTypes = (props) => {
    const parseBoolean = (value) => {
      if (typeof value === 'string') {
        return value.toLowerCase() === 'true';
      }
      return Boolean(value);
    };

    const notifications = [
      parseBoolean(props?.slacObj?.on_Suite_Execution_Starts) && SLACK_CONSTANTS.ON_SUITE_EXECUTION_START,
      parseBoolean(props?.slacObj?.on_Suite_Execution_Ends) &&
        SLACK_CONSTANTS.ON_SUITE_EXECUTION_COMPLETION_OR_TERMINATION,
      parseBoolean(props?.slacObj?.slackManuallyTriggerNotification) &&
        SLACK_CONSTANTS.MANUALLY_TRIGGER_EMAIL_NOTIFICATION,
    ];

    return notifications.filter(Boolean).join(', ');
  };
  function filterScriptData(childData) {
    if (childData?.length) {
      childData?.forEach((obj) => {
        if (obj?.folder) {
          filterScriptData(obj?.children);
          calculatedScriptNumber(obj);
        } else {
          if (obj?.type === 'Script') {
            totalScriptCount++;
          }
        }
      });
    }
  }

  const onCheckedNodes = (nodes) => {
    console.log(nodes);
  };

  function onNodeSelected() {
    console.log(`clicked`);
  }

  let renderTree;
  let renderTreeForMaximize;

  const colDefs = [
    {
      field: 'title',
      title: 'Module',
      class: 'title',
      isTitle: true,
      width: `calc(100% - 550px)`,
      folderTitleWidth: `calc(100% - 670px)`,
      render: (nodeObj) => {
        createFilterData(treeData);
        return (
          <>
            {nodeObj.isRootNode && (
              <>
                <span class="count-badge folder-count ml-2">
                  M {treeData[0]?.totalModuleCount ? treeData[0]?.totalModuleCount : 0}{' '}
                </span>
                <span class="count-badge file-count">
                  {' '}
                  S {treeData[0]?.totalScriptCount ? treeData[0]?.totalScriptCount : 0}{' '}
                </span>
              </>
            )}
            {nodeObj.data.calculatedScriptCount > 0 && (
              <span class="count-badge file-count modifiedBy"> S {nodeObj.data.calculatedScriptCount} </span>
            )}
            {nodeObj.folder && (
              <button class="add-btn">
                <MoreInfoButton type="add" nodeObj={nodeObj} plusIconArray={plusIconArray} />
              </button>
            )}
          </>
        );
      },
    },
    {
      field: 'scriptType',
      title: 'Type',
      class: 'Type',
      width: '160px',
      render: (nodeObj) => {
        return (
          <span className="text-xs table-non-link-color-common fontPoppinsRegularMd">
            {getTruncatedText(nodeObj.data.scriptType, 20)}
          </span>
        );
      },
    },
    {
      field: 'modifiedByUname',
      title: 'Modified By',
      class: 'modified-by',
      width: '200px',

      render: (nodeObj) => {
        return (
          <span
            title={nodeObj.data.modifiedByUname === '--' ? nodeObj.data.createdByUname : nodeObj.data.modifiedByUname}
            className="tooltip"
          >
            <span className="table-non-link-color-common modified">
              {nodeObj.data.modifiedByUname === '--' ? nodeObj.data.createdByUname : nodeObj.data.modifiedByUname}
            </span>
          </span>
        );
      },
    },
  ];

  if (isLoading) {
    renderTree = <CommonLoader />;
  } else if (treeData && treeData.length > 0) {
    renderTree = (
      <TableTree
        data={treeData}
        rootData={rootData}
        colDefs={colDefs}
        filter={treeValue}
        expandAll={isExpandAll}
        hideElements={false}
        hideConditions={true}
        showCheckbox={false}
        conditions={[
          { key: 'title', value: 'Preconditions' },
          { key: 'title', value: 'Postconditions' },
        ]}
        hideConditionBtn={false}
        onCheckedNodes={onCheckedNodes}
        labels={LABELS}
        onNodeSelected={onNodeSelected}
        id="systemTree"
      />
    );
  }

  if (isLoading) {
    renderTreeForMaximize = <CommonLoader />;
  } else if (treeData && treeData.length > 0) {
    renderTreeForMaximize = (
      <TableTree
        data={treeData}
        rootData={rootData}
        colDefs={colDefs}
        filter={treeValue}
        expandAll={isExpandAll}
        hideElements={false}
        hideConditions={true}
        showCheckbox={false}
        conditions={[
          { key: 'title', value: 'Preconditions' },
          { key: 'title', value: 'Postconditions' },
        ]}
        hideConditionBtn={false}
        onCheckedNodes={onCheckedNodes}
        labels={LABELS}
        onNodeSelected={onNodeSelected}
        id="renderTreeForMaximize"
      />
    );
  }

  // Define a default UI for filtering
  function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search...`}
      />
    );
  }

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }
  fuzzyTextFilterFn.autoRemove = (val) => !val;
  function Table({ columns, data }) {
    const filterTypes = useMemo(
      () => ({
        fuzzyText: fuzzyTextFilterFn,
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      []
    );

    const defaultColumn = useMemo(
      () => ({
        Filter: DefaultColumnFilter,
      }),
      []
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
      {
        columns,
        data,
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
      },
      useFilters, // useFilters!
      useGlobalFilter, // useGlobalFilter!
      useSortBy,
      usePagination
    );
    return (
      <>
        <table class="relative " {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr class="mt-4" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    class="sticky top-0 px-6 py-3 text-current table-header_font-style text-sm bg-gray-100 ml-5"
                    {...column.getHeaderProps()}
                  >
                    <span className="flex flex-row">
                      {column.render('Header')}
                      {/* Render the columns filter UI */}
                      <div>
                        <span></span>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr className="project-row" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td class="px-4 py-3 text-center" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <br />
      </>
    );
  }

  const selectedProject = JSON.parse(localStorage.getItem('selected-project'));
  const selectedProjectType = selectedProject?.type?.toLowerCase();

  const columnsManual = useMemo(
    () =>
      selectedProjectType === 'web'
        ? [
            {
              Header: 'NAME',
              columns: [],
            },
            {
              Header: 'USER',
              accessor: 'clientId',
              width: '140',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common table-font-style-common float-left w-140px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'EXECUTION ENVIRONMENT',
              accessor: 'executionEnv',
              disableFilters: true,
              width: '200',
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-200px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'OS',
              accessor: 'machineInfo.osName',
              width: '140',
              disableFilters: true,
              Cell: ({ value }) => {
                return <span className="version-text-color">{value ? getOsLabel(value) : ''}</span>;
              },
            },
            {
              Header: 'OS VERSION',
              accessor: 'machineInfo.osVersion',
              width: '120',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-120px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'BROWSER',
              accessor: 'browserName',
              width: '140',
              disableFilters: true,
              Cell: ({ value }) => {
                return <span className="version-text-color">{value ? renderBrowserCell(value) : ''}</span>;
              },
            },
            {
              Header: 'BROWSER VERSION',
              accessor: 'browserVersion',
              width: '180',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-180px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
          ]
        : selectedProject.type.toLowerCase() === 'mobile'
        ? [
            {
              Header: 'NAME',
              columns: [],
            },
            {
              Header: 'USER',
              accessor: 'clientId',
              width: '160',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-160px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'EXECUTION ENVIRONMENT',
              accessor: 'executionEnv',
              disableFilters: true,
              width: '200',
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-200px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'OS',
              accessor: 'machineInfo.osName',
              width: '140',
              disableFilters: true,
              Cell: ({ value }) => {
                return <span className="version-text-color">{value ? getOsLabel(value) : ''}</span>;
              },
            },
            {
              Header: 'OS VERSION',
              accessor: 'machineInfo.osVersion',
              width: '120',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-120px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'DEVICE',
              accessor: 'deviceInfo[0].name',
              width: '180',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-120px truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
          ]
        : [
            {
              Header: 'NAME',
              columns: [],
            },
            {
              Header: 'USER',
              accessor: 'clientId',
              width: '120',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <div className="table-non-link-color-common float-left table-font-style-common w-120px truncate">
                    {value}
                  </div>
                );
              },
            },
            {
              Header: 'EXECUTION ENVIRONMENT',
              accessor: 'executionEnv',
              width: '200',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <div className="table-non-link-color-common float-left table-font-style-common w-200px truncate">
                    {value}
                  </div>
                );
              },
            },
            {
              Header: 'OS',
              accessor: 'machineInfo.osName',
              width: '140',
              disableFilters: true,
              Cell: ({ value }) => {
                return <span className="version-text-color">{value ? getOsLabel(value) : ''}</span>;
              },
            },
            {
              Header: 'OS VERSION',
              accessor: 'machineInfo.osVersion',
              width: '100',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <div className="table-non-link-color-common float-left table-font-style-common w-100px truncate">
                    {value}
                  </div>
                );
              },
            },
            {
              Header: 'BROWSER',
              accessor: 'browserName',
              width: '140',
              disableFilters: true,
              Cell: ({ value }) => {
                return <div className="version-text-color">{value ? renderBrowserCell(value) : ''}</div>;
              },
            },
            {
              Header: 'BROWSER VERSION',
              accessor: 'browserVersion',
              width: '140',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <div className="table-non-link-color-common float-left table-font-style-common w-140px truncate">
                    {value}
                  </div>
                );
              },
            },
            {
              Header: 'DEVICE',
              accessor: 'deviceInfo[0].name',
              width: '100',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <div className="table-non-link-color-common float-left table-font-style-common w-100px truncate">
                    {value}
                  </div>
                );
              },
            },
          ],
    []
  );

  const columns = React.useMemo(
    () =>
      selectedProject.type.toLowerCase() === 'web'
        ? [
            {
              Header: 'NAME',
              columns: [],
            },
            {
              Header: 'EXECUTION ENVIRONMENT',
              accessor: 'executionEnv',
              width: '180',
              disableFilters: true,
              Cell: ({ value, row }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-180px truncate">
                      {value}
                      {`${row?.original?.headless === true ? '(headless)' : ''}`}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'OS & OS VERSION',
              accessor: 'machineInfo.osName',
              width: '140',
              disableFilters: true,
              Cell: ({ value, row }) => {
                return (
                  <div className="table-non-link-color-common float-left table-font-style-common w-140px truncate version-text-color">
                    {value ? getOsLabel(value) : ''}
                    {`${row?.original?.machineInfo?.osVersion}`}
                  </div>
                );
              },
            },
            {
              Header: 'BROWSER',
              accessor: 'browserName',
              width: '80',
              disableFilters: true,
              Cell: ({ value }) => {
                return <div className="w-80px version-text-color">{value ? renderBrowserCell(value) : ''}</div>;
              },
            },
            {
              Header: 'BROWSER VERSION',
              accessor: 'browserVersion',
              width: '100',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="w-100px table-non-link-color-common float-left table-font-style-common truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'NO. OF RUNS',
              accessor: 'numberOfRuns',
              disableFilters: true,
              width: '80',
              Cell: ({ value }) => {
                return (
                  <div className="table-display-common table-non-link-color-common w-80px table-font-style-common">
                    {value}
                  </div>
                );
              },
            },
          ]
        : selectedProject.type.toLowerCase() === 'mobile'
        ? [
            {
              Header: 'NAME',
              columns: [],
            },
            {
              Header: 'EXECUTION ENVIRONMENT',
              accessor: 'executionEnv',
              width: '180',
              disableFilters: true,
              Cell: ({ value, row }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-180px truncate">
                      {value}
                      {`${row?.original?.headless === true ? '(headless)' : ''}`}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'DEVICE',
              accessor: 'deviceInfo[0].name',
              width: '100',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="w-100px table-non-link-color-common float-left table-font-style-common truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'NO. OF RUNS',
              accessor: 'numberOfRuns',
              width: '100',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <div className="table-display-common table-non-link-color-common  w-100px table-font-style-common">
                    {value}
                  </div>
                );
              },
            },
          ]
        : [
            {
              Header: 'NAME',
              columns: [],
            },
            {
              Header: 'EXECUTION ENVIRONMENT',
              accessor: 'executionEnv',
              width: '180',
              disableFilters: true,
              Cell: ({ value, row }) => {
                return (
                  <Tooltip title={value}>
                    <div className="table-non-link-color-common float-left table-font-style-common w-180px truncate">
                      {value}
                      {`${row?.original?.headless === true ? '(headless)' : ''}`}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'OS & OS VERSION',
              accessor: 'machineInfo.osName',
              width: '140',
              disableFilters: true,
              Cell: ({ value, row }) => {
                return (
                  <div className="table-non-link-color-common float-left table-font-style-common w-140px truncate version-text-color">
                    {value ? getOsLabel(value) : ''}
                    {`${row?.original?.machineInfo?.osVersion}`}
                  </div>
                );
              },
            },
            {
              Header: 'BROWSER',
              accessor: 'browserName',
              width: '80',
              disableFilters: true,
              Cell: ({ value }) => {
                return <div className="w-80px version-text-color">{value ? renderBrowserCell(value) : ''}</div>;
              },
            },
            {
              Header: 'BROWSER VERSION',
              accessor: 'browserVersion',
              width: '140',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="w-140px table-non-link-color-common float-left table-font-style-common truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'DEVICE',
              accessor: 'deviceInfo[0].name',
              width: '80',
              disableFilters: true,
              Cell: ({ value }) => {
                return (
                  <Tooltip title={value}>
                    <div className="w-80px table-non-link-color-common float-left table-font-style-common truncate">
                      {value}
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              Header: 'NO. OF RUNS',
              accessor: 'numberOfRuns',
              disableFilters: true,
              width: '80',
              Cell: ({ value }) => {
                return (
                  <div className="table-display-common table-non-link-color-common w-80px table-font-style-common">
                    {value}
                  </div>
                );
              },
            },
          ],
    []
  );

  const columnsEmail = useMemo(
    () => [
      {
        Header: 'Name',
        columns: [],
      },

      {
        Header: 'Email Id',
        accessor: 'emailId',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <Tooltip title={value}>
              <div className="user-machine-max-width-style table-non-link-color-common float-left ml-3 table-font-style-common">
                {getTruncatedText(value, 25)}
              </div>
            </Tooltip>
          );
        },
      },

      {
        Header: 'Recipient Name',
        accessor: 'name',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="user-machine-max-width-style table-non-link-color-common float-left ml-3 table-font-style-common">
              {value ? value : 'NA'}
            </div>
          );
        },
      },
      {
        Header: 'Recipients Group',
        accessor: 'groupName',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="user-machine-max-width-style table-non-link-color-common float-left ml-3 table-font-style-common">
              {value ? value : 'NA'}
            </div>
          );
        },
      },
    ],
    []
  );

  const columnsSlack = useMemo(
    () => [
      {
        Header: 'INSTANCE NAME',
        accessor: 'instanceName',
        disableFilters: true,
        width: '400',
        Cell: ({ value }) => {
          return (
            <Tooltip title={value}>
              <div className="table-non-link-color-common float-left table-font-style-common ml-3">{value}</div>
            </Tooltip>
          );
        },
      },

      {
        Header: 'CHANNEL ID',
        accessor: 'channelId',
        width: '240',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <Tooltip title={value}>
              <div className="table-non-link-color-common float-left table-font-style-common ml-2">
                {getTruncatedText(value, 25)}
              </div>
            </Tooltip>
          );
        },
      },

      {
        Header: 'CHANNEL NAME',
        accessor: 'channelName',
        width: '240',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common float-left table-font-style-common ml-3">
              {value ? value : 'NA'}
            </div>
          );
        },
      },
    ],
    []
  );
  function createFilterData(treeData) {
    if (treeData) {
      let tempArray = [];
      let scriptArray = [];
      let tempNamesArray = [];
      let tempTypeArray = [];
      treeData.forEach((treeVal) => {
        if (treeVal) {
          if (treeVal.title) {
            tempArray.push(treeVal.title);
          }
          if (treeVal.createdByUname) {
            tempNamesArray.push(treeVal.createdByUname);
          }
          if (treeVal && treeVal.children) {
            scriptArray = treeVal.children;
            scriptArray.forEach((scriptVal) => {
              if (scriptVal.title) {
                tempArray.push(scriptVal.title);
              }
              if (scriptVal.createdByUname) {
                tempNamesArray.push(treeVal.createdByUname);
              }
              if (scriptVal.scriptType) {
                tempTypeArray.push(scriptVal.scriptType);
              }
            });
          }
        }
      });
    }
  }

  function navigateToBack() {
    props.func(false);
  }

  const [showModal, setShowModal] = useState(false);
  const [selectedTypeForMaximize, setSelectedTypeForMaximize] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  let currentPosts =
    props && props?.machineObj && props?.machineObj?.selectedMachines
      ? props?.machineObj?.selectedMachines.slice(indexOfFirstPost, indexOfLastPost)
      : null;
  let currentEmailPosts = props?.emailObj?.recipientGroup.concat(props?.emailObj?.emailRecipient);
  let currentSlackPosts = props?.slacObj?.recipientGroup;

  function MaximizeFun(selectedType) {
    if (selectedType === 'module') {
      setSelectedTypeForMaximize(selectedType);
      setShowModal(true);
    } else if (selectedType === 'email') {
      setSelectedTypeForMaximize(selectedType);
      setShowModal(true);
    } else if (selectedType === 'device') {
      setSelectedTypeForMaximize(selectedType);
      setShowModal(true);
    } else if (selectedType === 'slack') {
      setSelectedTypeForMaximize(selectedType);
      setShowModal(true);
    }
  }

  function getSuiteRunSettings() {
    let search = window.location.search;
    let urlSuiteId = new URLSearchParams(search).get('suiteId');
    const suiteObject = props?.suiteObj;
    treeArrayForMultipleTree = [];
    localStorage.setItem('urlSuiteId', urlSuiteId);
    let runSettingsData;
    if (suiteObject) {
      setIsMultipleTreeLoading(true);
      const { exeTerminationObj, machines, resultSettingObj, waitTimeObj, selectedModulesAndScripts } = suiteObject;
      runSettingsData = {
        machines: getFinalMachineObject(machines),
        waitTime: waitTimeObj,
        resultSetting: resultSettingObj,
        executionTermination: exeTerminationObj,
      };
      setRunSettingsData({ ...runSettingsData });
      if (selectedModulesAndScripts) {
        if (selectedModulesAndScripts.length) {
          selectedModulesAndScripts[0]?.children?.forEach((obj) => {
            if (obj?.folder) obj.checkbox = false;
          });
        }
        setTreeData(selectedModulesAndScripts);
        setRootData(suiteObject.root);
        let calculatedScriptCount;
        let calculatedModuleCount;
        if (
          runSettingsData?.machines?.multiple === 'true' &&
          runSettingsData?.machines?.executionType === 'DISTRIBUTE'
        ) {
          if (['MANUAL', 'AUTOMATIC'].includes(runSettingsData?.machines?.distributionMechanism)) {
            if (runSettingsData?.machines?.selectedMachines?.length) {
              runSettingsData.machines.selectedMachines.forEach((machine) => {
                machine?.machineInstances?.forEach((disObj) => {
                  let tempSelModArray = disObj?.distribution?.selectedModules;
                  if (tempSelModArray?.length) {
                    let modules = tempSelModArray.map((name) => name).join(', ');
                    let tempSelectedModulesAndScripts = JSON.parse(JSON.stringify([...selectedModulesAndScripts]));
                    let filteredModules = JSON.parse(
                      JSON.stringify(getModuleAndScriptData(tempSelectedModulesAndScripts, modules))
                    );
                    totalModCount.current = 0;
                    if (filteredModules?.length) {
                      calculatedScriptCount = calculateScriptCount(filteredModules);
                      calculatedModuleCount = calculateModuleCount(filteredModules);
                      totalScriptCount = 0;
                      let renderTreeObj = {
                        treeId: disObj?.distribution?.distributionId || disObj?.distribution?.clientSystemId,
                        tableTreeId:
                          disObj?.distribution?.distributionId?.replaceAll(' ', '-') ||
                          disObj?.distribution?.clientSystemId?.replaceAll(' ', '-') ||
                          '',
                        treeArray: filteredModules,
                        rootData: {
                          key: filteredModules[0]?.parent?.key || filteredModules[0]?.parentId,
                          title: filteredModules[0]?.parent?.title || filteredModules[0]?.parentName,
                          calculatedScriptCount: calculatedScriptCount,
                          calculatedModuleCount: calculatedModuleCount,
                        },
                        deviceInfo: disObj,
                      };
                      treeArrayForMultipleTree.push(renderTreeObj);
                    }
                  }
                });
              });
            }
          }
        }
      }
      if (treeArrayForMultipleTree?.length) {
        let colDefsChild = [];
        treeArrayForMultipleTree.forEach((obj) => {
          if (['web', 'mobile'].includes(selectedProjectType)) {
            colDefsChild = [
              {
                field: 'title',
                title: 'MODULE',
                class: 'title',
                isTitle: true,
                width: '220px',
                render: (nodeObj) => {
                  createFilterData(treeData);
                  return (
                    <>
                      {nodeObj.isRootNode && (
                        <>
                          <span class="count-badge folder-count ml-2">M {obj?.rootData?.calculatedModuleCount} </span>
                          <span class="count-badge file-count">
                            {' '}
                            S{' '}
                            {obj?.rootData?.calculatedScriptCount
                              ? obj.rootData.calculatedScriptCount
                              : obj?.treeArray[0].calculatedScriptCount}{' '}
                          </span>
                        </>
                      )}

                      {nodeObj.data.scriptCount > 0 && (
                        <span class="count-badge file-count"> S {nodeObj.data.calculatedScriptCount} </span>
                      )}
                      {nodeObj.folder && (
                        <button class="add-btn">
                          <MoreInfoButton type="add" nodeObj={nodeObj} plusIconArray={plusIconArray} />
                        </button>
                      )}
                    </>
                  );
                },
              },
              {
                field: 'scriptType',
                title: 'TYPE',
                class: 'Type truncate',
                width: '74px',
                render: (nodeObj) => {
                  return (
                    <Tooltip title={nodeObj.data.scriptType}>
                      <span className="text-xs table-non-link-color-common fontPoppinsRegularMd">
                        {nodeObj.data.scriptType}
                      </span>
                    </Tooltip>
                  );
                },
              },
              {
                field: 'Execution Environment',
                title: 'EXECUTION ENVIRONMENT',
                class: 'executionEnvironment truncate',
                width: '185px',
                render: (nodeObj) => {
                  const executionEnv = obj?.deviceInfo?.executionEnv;
                  if (nodeObj.isRootNode || nodeObj.data.scriptCount > 0) return null;
                  return (
                    <Tooltip title={executionEnv}>
                      <span className="table-non-link-color-common fontPoppinsRegularMd cursor-pointer">
                        {executionEnv}
                      </span>
                    </Tooltip>
                  );
                },
              },
              {
                field: 'Os',
                title: 'OS & OS Ver.',
                class: 'os',
                width: '90px',
                render: (nodeObj) => {
                  const osName = `${obj?.deviceInfo?.machineInfo?.osName}-${obj?.deviceInfo?.machineInfo?.osVersion}`;
                  if (nodeObj.isRootNode || nodeObj.data.scriptCount > 0) return null;
                  return <span className="version-text-color">{osName ? getOsLabel(osName) : ''}</span>;
                },
              },

              {
                field: selectedProjectType === 'web' ? 'Browser' : 'Device',
                title: selectedProjectType === 'web' ? 'BROWSER' : 'DEVICE',
                class: selectedProjectType === 'web' ? 'browser truncate' : 'device truncate',
                width: selectedProjectType === 'web' ? '70px' : '80px',
                render: (nodeObj) => {
                  const browserName = obj?.deviceInfo?.browserName;
                  const deviceArrayCopy = obj?.deviceInfo?.deviceInfo || [];
                  if (nodeObj.isRootNode || nodeObj.data.scriptCount > 0) return null;
                  if (selectedProjectType.toLowerCase() !== 'web') {
                    return <span className="">{deviceArrayCopy?.length ? deviceArrayCopy[0].name : ''}</span>;
                  } else {
                    return browserName ? (
                      <Tooltip title={browserName} placement="top">
                        {renderBrowserCell(browserName)}
                      </Tooltip>
                    ) : (
                      ''
                    );
                  }
                },
              },
              {
                field: 'numberOfRuns',
                title: 'No. of Runs',
                width: '90px',
                render: (nodeObj) => {
                  if (nodeObj?.isRootNode || nodeObj?.data?.scriptCount > 0) return null;
                  return (
                    <span className="text-xs table-non-link-color-common fontPoppinsRegularMd">
                      {obj?.deviceInfo?.numberOfRuns}
                    </span>
                  );
                },
              },
            ];
          } else if (['web & mobile', 'salesforce', 'ms dynamics', 'web service']?.includes(selectedProjectType)) {
            colDefsChild = [
              {
                field: 'title',
                title: 'MODULE',
                class: 'title',
                isTitle: true,
                width: '220px',
                render: (nodeObj) => {
                  createFilterData(treeData);
                  return (
                    <>
                      {nodeObj.isRootNode && (
                        <>
                          <span class="count-badge folder-count ml-2">M {obj?.rootData?.calculatedModuleCount} </span>
                          <span class="count-badge file-count">
                            {' '}
                            S{' '}
                            {obj?.rootData?.calculatedScriptCount
                              ? obj.rootData.calculatedScriptCount
                              : obj?.treeArray[0].calculatedScriptCount}{' '}
                          </span>
                        </>
                      )}

                      {nodeObj.data.scriptCount > 0 && (
                        <span class="count-badge file-count"> S {nodeObj.data.calculatedScriptCount} </span>
                      )}
                      {nodeObj.folder && (
                        <button class="add-btn">
                          <MoreInfoButton type="add" nodeObj={nodeObj} plusIconArray={plusIconArray} />
                        </button>
                      )}
                    </>
                  );
                },
              },
              {
                field: 'scriptType',
                title: 'TYPE',
                class: 'Type  truncate',
                width: '74px',
                render: (nodeObj) => {
                  return (
                    <Tooltip title={nodeObj.data.scriptType}>
                      <span className="text-xs table-non-link-color-common fontPoppinsRegularMd truncate">
                        {nodeObj.data.scriptType}
                      </span>
                    </Tooltip>
                  );
                },
              },
              {
                field: 'Execution Environment',
                title: 'EXECUTION ENVIRONMENT',
                class: 'executionEnvironment truncate',
                width: '185px',
                render: (nodeObj) => {
                  const executionEnv = obj?.deviceInfo?.executionEnv;
                  if (nodeObj.isRootNode || nodeObj.data.scriptCount > 0) return null;
                  return (
                    <Tooltip title={executionEnv}>
                      <span className="table-non-link-color-common fontPoppinsRegularMd cursor-pointer">
                        {executionEnv}
                      </span>
                    </Tooltip>
                  );
                },
              },
              {
                field: 'Os',
                title: 'OS & OS Ver.',
                class: 'os truncate',
                width: '90px',
                render: (nodeObj) => {
                  const osName = `${obj?.deviceInfo?.machineInfo?.osName}-${obj?.deviceInfo?.machineInfo?.osVersion}`;
                  if (nodeObj.isRootNode || nodeObj.data.scriptCount > 0) return null;
                  return <span className="version-text-color">{osName ? getOsLabel(osName) : ''}</span>;
                },
              },

              {
                field: 'Browser',
                title: 'BROWSER',
                class: 'browser',
                width: '68px',
                render: (nodeObj) => {
                  const browserName = obj?.deviceInfo?.browserName;
                  if (nodeObj.isRootNode || nodeObj.data.scriptCount > 0) return null;
                  return browserName ? (
                    <Tooltip title={browserName} placement="top">
                      {renderBrowserCell(browserName)}
                    </Tooltip>
                  ) : (
                    ''
                  );
                },
              },
              {
                field: 'Device',
                title: 'DEVICE',
                class: 'device truncate',
                width: '80px',
                render: (nodeObj) => {
                  const deviceArrayCopy = obj?.deviceInfo?.deviceInfo || [];
                  if (nodeObj.isRootNode || nodeObj.data.scriptCount > 0) return null;
                  return <span className="">{deviceArrayCopy?.length ? deviceArrayCopy[0].name : ''}</span>;
                },
              },
              {
                field: 'numberOfRuns',
                title: 'No. of Runs',
                width: '90px',
                render: (nodeObj) => {
                  if (nodeObj?.isRootNode || nodeObj?.data?.scriptCount > 0) return null;
                  return <span className="text-xs table-non-link-color-common">{obj?.deviceInfo?.numberOfRuns}</span>;
                },
              },
            ];
          }
          if (isLoading) {
            obj.treeName = <CommonLoader />;
          } else if (obj?.treeArray && obj?.treeArray.length > 0) {
            obj.treeName = (
              <TableTree
                data={obj?.treeArray}
                rootData={obj?.rootData}
                colDefs={colDefsChild}
                filter={treeValue}
                expandAll={isExpandAll}
                hideElements={false}
                hideConditions={true}
                showCheckbox={false}
                conditions={[
                  { key: 'title', value: 'Preconditions' },
                  { key: 'title', value: 'Postconditions' },
                ]}
                hideConditionBtn={false}
                onCheckedNodes={onCheckedNodes}
                labels={LABELS}
                onNodeSelected={onNodeSelected}
                id={obj.tableTreeId}
              />
            );
          }
        });
      }
      setIsMultipleTreeLoading(false);
    }
  }

  return (
    <>
      <Modal open={showModal} className="modal-dialog">
        <div className="modal-container max-popup-style">
          <div className="modal-header ">
            {selectedTypeForMaximize === 'module' ? (
              <label className="Popup-header-common Popup-header-layout-style-user">Modules / Scripts</label>
            ) : selectedTypeForMaximize === 'email' ? (
              <label className="Popup-header-common Popup-header-layout-style-user">Email Recipients</label>
            ) : selectedTypeForMaximize === 'device' ? (
              <label className="Popup-header-common Popup-header-layout-style-user">Test Environments</label>
            ) : selectedTypeForMaximize === 'slack' ? (
              <label className="Popup-header-common">Slack Recipients</label>
            ) : null}
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                setShowModal(false);
                setSelectedTypeForMaximize();
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="modal-body-1" id="journal-scroll">
            {selectedTypeForMaximize === 'module' ? (
              <div className="details-page-popup-height">{renderTreeForMaximize}</div>
            ) : selectedTypeForMaximize === 'email' ? (
              <div className="table_height overflow-auto" id="journal-scroll">
                <Table columns={columnsEmail} data={currentEmailPosts} />
              </div>
            ) : selectedTypeForMaximize === 'device' ? (
              <div className="table_height overflow-auto" id="journal-scroll">
                <Table
                  columns={columns}
                  data={
                    props && props?.machineObj && props?.machineObj?.selectedMachines
                      ? props?.machineObj?.selectedMachines
                      : []
                  }
                ></Table>
              </div>
            ) : selectedTypeForMaximize === 'slack' ? (
              <div className="table_height overflow-auto" id="journal-scroll">
                <Table columns={columnsSlack} data={currentSlackPosts} />
              </div>
            ) : null}
          </div>
        </div>
      </Modal>
      <div className="content_area_style overflow-auto" id="journal-scroll">
        <div className="details-main-header">
          <label className="page-header-common float-left">Preview</label>
          <div className="flex flex-row float-right -mt-1">
            <button class="primary-btn mr-3" onClick={navigateToBack}>
              Back
            </button>
          </div>
        </div>

        <div className="details-body">
          <div className="grid grid-cols-3 gap-6">
            <div>
              <label className="details-label-style">Name</label>
              <br></br>
              <Tooltip title={`${props?.name}`}>
                <label className="details-value-style">{getTruncatedText(props?.name, 25)}</label>
              </Tooltip>
            </div>
            <div className="flex flex-col truncate">
              <label className="details-label-style">Description</label>
              <Tooltip title={props?.description}>
                <label className="details-value-style truncate">{props?.description}</label>
              </Tooltip>
            </div>
            <div>
              <label className="details-label-style">Suite Type</label>
              <br></br>
              <label className="details-value-style">{props?.suiteType}</label>
            </div>
          </div>
          <div className="details-content-area-style">
            <div className="details_content_area_header_style">
              <label className="details-header-style float-left ">Modules / Scripts</label>
              <div className="flex flex-row float-right">
                {/* <FaSearch className="searchBtn mr-3" /> */}
                <FiMaximize2
                  className=" maximize-btn-style cursor-hand mt-1 mr-3"
                  title="Maximize"
                  onClick={() => MaximizeFun('module')}
                />
              </div>
            </div>
            <div className="h-80">{renderTree}</div>
          </div>

          <div className="single-dotted-border"></div>

          {props?.suiteType !== 'Manual' ? (
            <div className="variable-details-area-style mt-5">
              <label className="section-header-style">Variables</label>
              <div className="grid grid-cols-2 mt-5">
                <div>
                  <label className="details-label-style">Project Environment Variables </label>
                  <br></br>
                  <label className="details-value-style">
                    {props?.projectGlobalVarData?.projectVarJsonObject?.usePeVariableFrom === 'SET'
                      ? `Use variables from Project Variable Set (${props?.projectGlobalVarData?.projectVarJsonObject?.selectedPESetName})`
                      : 'Use variables from Test Development'}
                  </label>
                </div>
                <div>
                  <label className="details-label-style">Global Variables </label>
                  <br></br>
                  <label className="details-value-style">
                    {props?.projectGlobalVarData?.globalVarJsonObject &&
                    props?.projectGlobalVarData?.globalVarJsonObject?.useGlobalVariableFrom === 'SET'
                      ? `Use variables from Global Variable Set (${props?.projectGlobalVarData?.globalVarJsonObject?.selectedGVSetName})`
                      : 'Use variables from Test Development'}
                  </label>
                </div>
              </div>
            </div>
          ) : null}

          {props?.suiteType !== 'Manual' ? <div className="single-dotted-border"></div> : <></>}

          {props?.suiteType !== 'Manual' ? (
            <div className="variable-details-area-style mt-5">
              <label className="section-header-style">Test Data</label>
              <div className="grid grid-cols-2 mt-5">
                <div>
                  <label className="details-label-style">Test Data</label>
                  <br></br>
                  <label className="details-value-style">
                    {props?.testData?.useTestDataFrom === 'SET'
                      ? `Use Test Data from Test Data set (${props?.testData?.selectedTDSetName})`
                      : 'Use test data from Test Development'}
                  </label>
                </div>
              </div>
            </div>
          ) : null}
          {props?.suiteObj?.machines ? (
            <>
              {props?.suiteType === 'Automation' ? <div className="single-dotted-border"> </div> : <></>}
              <div className="variable-details-area-style mt-5">
                {!isEmptyValue(selectedMachines[0]?.machineInstances) && (
                  <>
                    <label className="section-header-style">
                      {props?.suiteType !== 'Manual' ? 'Run Settings' : 'User Settings'}
                    </label>
                    <div className="mt-5">
                      <label className="section-header-style ">
                        {props?.suiteType !== 'Manual' ? 'Machine' : null}
                      </label>
                    </div>
                    {props?.suiteType !== 'Manual' ? (
                      <div className="grid grid-cols-2 mt-5">
                        <div>
                          <label className="details-label-style">Run In</label>
                          <label className="details-value-style block">
                            {multiple === 'false' ? 'Single Machine' : 'Multiple Machine'}
                          </label>
                        </div>
                        <div>
                          <label className="details-label-style">Execution Type</label>
                          <label className="details-value-style block">
                            {multiple === 'false'
                              ? 'Sequential'
                              : executionType === 'SEND_SUITE_TO_ALL'
                              ? 'Parallel'
                              : 'Distribute'}
                          </label>
                        </div>
                      </div>
                    ) : (
                      <div className="grid grid-cols-2 mt-5">
                        <div>
                          <label className="details-label-style">Execution Type</label>
                          <label className="details-value-style block">
                            {multiple === 'false' ? 'Single User' : 'Multiple User'}
                          </label>
                        </div>
                      </div>
                    )}

                    <div
                      className={
                        multiple === 'false'
                          ? 'single-machine-details-content-area-style'
                          : 'details-content-area-style'
                      }
                    >
                      {multiple === 'false' ? (
                        <MachineEnvironmentLayout disableHeaderBtn={true}>
                          <MachineEnvironmentTableLayout
                            headerLabel={selectedMachines?.[0]?.machineInstances?.[0]?.machineInfo?.hostName}
                            accessDetails={selectedMachines?.[0]?.machineInstances?.[0]?.access}
                            columns={props?.suiteType === 'Manual' ? columnsManual : columns}
                            data={selectedMachines?.[0]?.machineInstances}
                            placement="bottom"
                          />
                        </MachineEnvironmentLayout>
                      ) : multiple === 'true' && executionType === EXECUTION_CONSTANTS.SEND_SUITE_TO_ALL ? (
                        <div className="table_height overflow-auto" id="journal-scroll">
                          <MachineEnvironmentLayout>
                            {selectedMachines.map((currentMachine) => (
                              <MachineEnvironmentTableLayout
                                headerLabel={currentMachine?.machineInstances[0].machineInfo.hostName}
                                accessDetails={currentMachine?.machineInstances[0]?.access}
                                columns={props?.suiteType === 'Manual' ? columnsManual : columns}
                                data={currentMachine?.machineInstances}
                                placement="bottom"
                              />
                            ))}
                          </MachineEnvironmentLayout>
                        </div>
                      ) : (
                        <>
                          {!isMultipleTreeLoading &&
                            treeArrayForMultipleTree.map((obj) => {
                              return (
                                <div className="details-content-area-style">
                                  <div className="details_content_area_header_style">
                                    <label className="details-header-style float-left ">
                                      {obj?.deviceInfo?.machineInfo?.hostName}
                                    </label>
                                    <div className="flex flex-row float-right">
                                      {/* <FaSearch className="searchBtn mr-3" /> */}
                                      <FiMaximize2
                                        className=" cursor-hand mt-1 mr-3"
                                        onClick={() => MaximizeFun('Machine1')}
                                      />
                                    </div>
                                  </div>
                                  <div className="h-80">{obj?.treeName}</div>
                                </div>
                              );
                            })}
                        </>
                      )}
                    </div>
                  </>
                )}
                {props?.suiteType === 'Automation' ? (
                  <div className="mt-5">
                    <label className="section-header-style ">Wait Time</label>
                    <div className="grid grid-cols-3 mt-5">
                      <div>
                        <label className="details-label-style">Implicit Wait</label>
                        <label className="details-value-style block">
                          {implicitWait} {implicitWaitUnit}
                        </label>
                        <label className="details-value-style block">
                          {' '}
                          {implicitWaitUnit === 'true' ? 'Send Mail' : null}
                        </label>
                      </div>
                      <div>
                        <label className="details-label-style">Explicitly Wait</label>
                        <label className="details-value-style block">
                          {explicitlyWait} {explicitlyWaitUnit}
                        </label>
                        <label className="details-value-style block">
                          {' '}
                          {explicitlyWaitUnit === 'true' ? 'Send Mail' : null}
                        </label>
                      </div>
                      <div>
                        <label className="details-label-style">Delay Between Steps</label>
                        <label className="details-value-style block">
                          {delayBetweenSteps} {delayBetweenStepsUnit}
                        </label>
                        <label className="details-value-style block">
                          {' '}
                          {delayBetweenStepsUnit === 'true' ? 'Send Mail' : null}
                        </label>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {props?.suiteType === 'Automation' ? (
                  <div className="mt-5">
                    <label className="section-header-style ">Result Settings</label>
                    <div className="grid grid-cols-3 mt-5">
                      <div>
                        <label className="details-label-style">Capture Screenshots</label>
                        <label className="details-value-style block">
                          {props?.suiteObj?.resultSetting?.captureScreenshots}
                        </label>
                      </div>
                      <div>
                        <label className="details-label-style">Capture Execution Video</label>
                        <label className="details-value-style block">
                          {props?.suiteObj?.resultSetting?.captureVideoForFailedTestScript}
                        </label>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {props?.suiteType === 'Automation' ? (
                  <div className="mt-5">
                    <label className="section-header-style ">Execution Termination</label>
                    <div className="grid grid-cols-2 mt-5">
                      <div>
                        <label className="details-label-style">
                          Terminate Script if any script execution takes more than
                        </label>
                        <label className="details-value-style block">
                          {terminateScriptIfTakesMoreTime} {terminateScriptUnit}
                        </label>
                        <label className="details-value-style block">
                          {' '}
                          {terminateSuiteSendEmail === 'true' ? 'Send Mail' : null}
                        </label>
                      </div>
                      <div>
                        <label className="details-label-style">
                          Terminate Suite if suite execution takes more than
                        </label>
                        <label className="details-value-style block">
                          {terminateSuiteIfTakesMoreTime} {terminateSuitetUnit}
                        </label>
                        <label className="details-value-style block">
                          {terminateScriptSendEmail === 'true' ? 'Send Mail' : null}
                        </label>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : null}
          <div className="single-dotted-border"></div>

          {!isEmptyValue(props?.emailObj?.recipientGroup) ||
          props?.emailObj?.emailTheReportAfterExecution === 'true' ? (
            <div className="variable-details-area-style mt-5">
              <label className="section-header-style">Email</label>
              <div className="mt-5">
                <label className="section-header-style ">Email Recipients</label>
              </div>
              <div className="grid grid-cols-2 mt-5">
                <div>
                  <label className="details-label-style">Recipient Group</label>
                  <br />
                  {emailGroupNameArray && emailGroupNameArray.length
                    ? emailGroupNameArray.map((groupName, index) => (
                        <label className="details-value-style">
                          {groupName} {index < emailGroupNameArray.length - 1 ? ', ' : null}
                        </label>
                      ))
                    : null}
                </div>
              </div>

              <div className="details-content-area-style ">
                <div className="details_content_area_header_style">
                  <label className="details-header-style float-left ">Email Recipients</label>
                  <div className="flex flex-row float-right">
                    {/* <FaSearch className="searchBtn mr-3" /> */}
                    <FiMaximize2 className="cursor-hand mt-1 mr-3" onClick={() => MaximizeFun('email')} />
                  </div>
                </div>
                <div className="table_height overflow-auto" id="journal-scroll">
                  <Table columns={columnsEmail} data={currentEmailPosts} />
                </div>
              </div>
              <div className="mt-5">
                <label className="section-header-style ">Email Reports</label>
              </div>
              <div className="grid grid-cols-2 mt-5">
                <div>
                  <label className="details-label-style">Email Reports</label>
                  <br></br>
                  <label className="details-value-style">{getEmailNotificationTypes(props)}</label>
                </div>
                <div>
                  <label className="details-label-style">Attach Execution Report</label>
                  <br></br>
                  <label className="details-value-style">{getReportTypes(props?.emailObj)}</label>
                </div>
              </div>
            </div>
          ) : null}

          {props.suiteObj.slackData && props?.slacObj?.recipientGroup.length > 0 ? (
            <div className="variable-details-area-style mt-5">
              <label className="section-header-style">Slack</label>
              <div className="mt-5">
                <label className="section-header-style ">Slack Recipients</label>
              </div>
              <div className="grid grid-cols-2 mt-5">
                <div>
                  <label className="details-label-style">Slack Instances</label>
                  <br />
                  {slackGroupNameArray && slackGroupNameArray.length
                    ? slackGroupNameArray.map((groupName, index) => (
                        <label className="details-value-style">
                          {groupName.instanceName} {index < slackGroupNameArray.length - 1 ? ', ' : null}
                        </label>
                      ))
                    : null}
                </div>
              </div>

              <div className="details-content-area-style ">
                <div className="details_content_area_header_style">
                  <label className="details-header-style float-left ">Slack Recipients</label>
                  <div className="flex flex-row float-right">
                    <FiMaximize2 className="cursor-hand mt-1 mr-3" onClick={() => MaximizeFun('slack')} />
                  </div>
                </div>
                <div className="table_height overflow-auto" id="journal-scroll">
                  <Table columns={columnsSlack} data={currentSlackPosts} />
                </div>
              </div>
              <div className="mt-5">
                <label className="section-header-style ">Slack Reports</label>
              </div>
              <div className="grid grid-cols-2 mt-5">
                <div>
                  <label className="details-label-style">Slack Reports</label>
                  <br></br>
                  <label className="details-value-style">{getSlackNotificationTypes(props)}</label>
                </div>
                <div>
                  <label className="details-label-style">Attach Execution Report</label>
                  <br></br>
                  <label className="details-value-style">{getReportTypes(props?.slacObj)}</label>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default SuitePreview;

import React, { useState, useEffect, useRef } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { getFormattedTextStatus, textColor } from '../result';
import { width } from 'tailwindcss/defaultTheme';
import { colors } from '@src/css_config/colorConstants.js';
const useStyles = (width) =>
  makeStyles({
    select: {
      paddingBottom: 3,
      paddingTop: 10,
      borderRadius: 8,
      fontSize: '12px',
      '&:focus': {
        borderRadius: 12,
        background: 'none',
      },
      '&.MuiSelect-select': {
        minWidth: width ? `${width}px !important` : '6px !important',
      },
    },
    menuPaper: {
      maxHeight: 200,
      fontSize: '12px',
    },
    menulist: {
      paddingTop: 10,
      paddingBottom: 0,
      fontSize: '12px',
      background: 'none',
      '& li': {
        fontSize: '12px',
        color: colors.grey_dark,
      },
      '& li:hover': {
        background: colors.sky_blue_dark,
        color: colors.blue_dark,
      },
      '& li.Mui-selected': {
        paddingTop: '5 !important',
        color: colors.blue_dark,
        background: colors.sky_blue_dark,
      },
      '& li.Mui-selected:hover': {
        background: colors.sky_blue_dark,
        color: colors.blue_dark,
      },
    },
  });

const StatusDropdown = (props) => {
  const statusList = ['PASS', 'FAIL', 'WARNING', 'SKIP'];
  const classes = useStyles(props?.width);
  const [nodeStatus, setNodeStatus] = useState(props.machineStatusObject ? props.machineStatusObject : {});
  const [nodeKey, setNodeKey] = useState(props.nodeKey ? props.nodeKey : '');

  const handleStatusClick = () => {
    props.handleStatusClick(props?.nodeObj);
  };

  return (
    <div className="flex justify-center">
      <div className="pt-1.5" id={`status-${props.nodeKey}`} onClick={() => handleStatusClick()}>
        {nodeStatus[nodeKey] ? (
          <span className={`${textColor(nodeStatus[nodeKey])}`}>{getFormattedTextStatus(nodeStatus[nodeKey])}</span>
        ) : (
          <span className={`${textColor(props.status)}`}> {getFormattedTextStatus(props.status)} </span>
        )}
      </div>
      <div>
        <Select
          classes={{ root: classes.select }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            classes: {
              paper: classes.menuPaper,
              list: classes.menulist,
            },
            getContentAnchorEl: null,
          }}
          id={props.nodeKey}
          closeOnSelect={true}
          multi={false}
          defaultValue=""
          value=""
          disableUnderline
          placeholder="Search and Select"
          onChange={(e) => {
            props.machineStatusObject[props.nodeKey] = e.target.value;
            props.handleMachineStatus({ ...props.machineStatusObject }, props?.nodeObj);
            nodeStatus[nodeKey] = e.target.value;
            setNodeStatus({ ...nodeStatus });
          }}
        >
          {statusList.map((stat) => (
            <MenuItem key={stat} value={stat}>
              <span className={`cursor-pointer ${textColor(stat)}`}>{getFormattedTextStatus(stat)}</span>
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default StatusDropdown;

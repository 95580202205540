import React from 'react';
import '../commons/template.scss';
import { useHistory, useLocation } from 'react-router-dom';
import Tempfirst from '@assets/defectTempImg/Temp1.png';
import Tempsecond from '@assets/defectTempImg/Temp2.png';
import Tempthird from '@assets/defectTempImg/Temp3.png';

export default function DefectLifecycleView() {
  const location = useLocation();

  const tempDetails = new URLSearchParams(location.search).get('tempId');
  const DefectTempName = new URLSearchParams(location.search).get('defectTemp');

  const history = useHistory();
  return (
    <>
      <div className="content_area_style_TCM">
        <div className="pt-3 pb-3 border_style flex flex-wrap items-center bg-gray-100">
          <label className="project_label text-sm flex-auto">
            Defect Life Cycle Template Details - {DefectTempName}{' '}
          </label>
          <button
            className="primary-btn mr-4"
            onClick={() => {
              history.push(`/configuration/template/defecttemplate/defectlifecycleTemplate`);
            }}
          >
            Back
          </button>
        </div>
        <div className="parentImgDiv" id="journal-scroll">
          <div className="childImgDiv">
            {tempDetails === 'DEFLIFTEMP1001' ? (
              <img alt="Temp1" src={Tempfirst} />
            ) : tempDetails === 'DEFLIFTEMP1002' ? (
              <img id="secImg" alt="Temp2" src={Tempsecond} />
            ) : tempDetails === 'DEFLIFTEMP1003' ? (
              <img alt="Temp3" src={Tempthird} />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

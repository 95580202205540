import React, { Fragment, useContext, useState, useEffect, useMemo } from 'react';
import { useTable, usePagination, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy } from 'react-table';
import matchSorter from 'match-sorter';
import { Tooltip, IconButton, Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';
import UserDetailsPage from '../../common/user-details-page';
import { useHistory } from 'react-router';
import ExecutionIdDetailsPage from './execution-id-details-page';
import {
  reExecuteSuiteReq,
  sendExecutionMail,
  terminateExecutionSuiteReq,
  cancelExecutionReq,
  deleteExecutionDataReq,
  getSingleExecutionDetailsReq,
  getExecutionMachineDetailsReq,
  getSuiteSpecificExeutionReq,
  getSingleExecutionDataReq,
} from '../../../../api/api_services';
import MachineDetails from '../../../test-development/execution-dashboard/modals/machine-details';
import CreateEditSuite from '../create-edit-suit';
import { Sync } from '@material-ui/icons';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import ClientStatusModal from '@pagescommon/execution/client-status.jsx';
import { CommonLoader } from '@src/pages/common/common-loader';
import {
  getStatusTypeForPending,
  beforeRunningStatuses,
  suiteResultStatistics,
  partialResultPageStatuses,
} from '@src/pages/test-development/execution-dashboard/execution-dashboard-util';
import TerminateWarningComponent from '@src/pages/test-development/execution-dashboard/modals/terminate-warning-component';
import { CircularProgressWithLabel, useCircularProgress } from '@src/common/CircularProgressContainer';
import { LazyDownloadPDFButton } from '@src/pages/results/shared/pdf-renderer/lazy-load-download-link';
import { archive, isEmptyValue } from '@src/util/common_utils';
import MemoryWarningPopup from '@src/pages/configuration/Memory_Config/MemoryWarningPopup';
import { Icon, Toggle } from 'fireflink-ui';
import { FREE_LICENSE_TYPE } from '@src/util/licenseUtils';
import { DASHBOARD_CONSTANTS } from '@src/common/ui-constants';
const SuiteDashboard = ({ MyAlert, ...props }) => {
  const project = JSON.parse(localStorage.getItem('selected-project'));
  let tab = window.location.pathname.split('/')[5];
  let history = useHistory();
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [dashboardData, setDashboardData] = useState([]);
  let initializingConstant = 'Initializing Execution';
  let deleteOption = ['Delete all.', 'Delete only screenshots.', 'Delete only videos.'];
  const [selectedSystem, setSelectedSystem] = useState();
  const [screenMode, setScreenMode] = useState('executionDashboard');
  const [selectedSuite, setSelectedSuite] = useState();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [selectedExeObject, setSelectedExeObject] = useState({});
  let search = window.location.search;
  let urlSuiteId = new URLSearchParams(search).get('suiteId');
  let urlSuiteType = new URLSearchParams(search).get('suiteType');
  const [selectedSuiteObj, setSelectedSuiteObj] = useState();
  const [isSyncDisabled, setIsSyncDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let [pageno, setpageNo] = useState(0);
  let [lastPage, setLastpage] = useState(false);
  const [memoryWarningPopup, setMemoryWarningPopup] = useState(false);
  const [spillOverData, setSpillOverData] = useState({});
  let size = 12;

  const { progress, startCircularProgress } = useCircularProgress('suite-dashboard');

  function createAlertFun(type, msg) {
    if (type === 'success') {
      if (MyAlert) {
        MyAlert.success(msg);
      }
    } else if (type === 'info') {
      if (MyAlert) {
        MyAlert.info(msg);
      }
    }
  }

  const { hasFullAccess, isProjectOpen } = useMemo(
    () => ({
      isProjectOpen: window.permission?.isProjectOpen(),
      hasFullAccess: window.permission?.isFullAccess('execution'),
    }),
    []
  );

  // Define a default UI for filtering
  function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search...`}
      />
    );
  }

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  useEffect(() => {
    let timerId = null;
    if (isSyncDisabled) {
      timerId = setTimeout(() => {
        setIsSyncDisabled(false);
      }, process.env.REACT_APP_SUITE_EXECUTION_INTERVAL || 10000);
    }
    return () => clearTimeout(timerId);
  }, [isSyncDisabled]);

  const handlescroll = (event) => {
    if (
      event?.target?.scrollTop &&
      Math.ceil(event?.target?.scrollTop + event?.target?.clientHeight) === event?.target?.scrollHeight
    ) {
      if (!lastPage) {
        getAllDashboard(pageno);
      }
    }
  };

  function getAllDashboard(page = 0) {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    setIsLoading(true);
    const localSuiteId = localStorage.getItem('urlSuiteId');
    getSuiteSpecificExeutionReq(project.id, urlSuiteId || localSuiteId, 'SUITE', page, size)
      .then((results) => {
        const response = results?.data?.responseObject;
        if (response) {
          setpageNo(page + 1);
          resetProgress();
          response.forEach((res, i) => {
            res.Actions = [];
            if (res?.executionStatus === 'Terminated') {
              res['executionStatusTemp'] = res.executionStatus;
              res.Actions.push(
                <span className="flex flex-row gap-2 suite-action delete-icon-style ">
                  {downloadIcon(res)}
                  {mailIcon(res)}
                  {isProjectOpen && (
                    <Tooltip title="Run">
                      <img
                        alt=""
                        src="\assets\execution_logos\execution.svg"
                        className="flex flex-row icon-blue-style cursor-hand "
                        height="17px"
                        width="17px"
                        onClick={() => executeFunction(res, false)}
                      ></img>
                    </Tooltip>
                  )}
                  {hasFullAccess && (
                    <Tooltip title="Delete">
                      <img
                        alt=""
                        src="\assets\execution_logos\delete.svg"
                        className="flex flex-row icon-blue-style cursor-hand dashboad-action-font-style"
                        onClick={() => {
                          setOpen(true);
                          setSelectedDataObj(res);
                        }}
                      ></img>
                    </Tooltip>
                  )}
                </span>
              );
            } else if (
              ['Fetching Results', 'Running', 'Pending', 'Processing Result', ...beforeRunningStatuses].includes(
                res?.executionStatus
              )
            ) {
              if (res?.resultStatus === 'Partially Executed') {
                res['executionStatusTemp'] = res.resultStatus;
              } else {
                res['executionStatusTemp'] = res.executionStatus;
              }
              res.Actions.push(
                <div className="flex flex-row suite-action delete-icon-style gap-2 ">
                  {res?.suite.suiteType === 'Manual' ? downloadIcon(res) : null}
                  {mailIcon(res)}
                  {isProjectOpen && ['Running', 'Pending', ...beforeRunningStatuses].includes(res.executionStatus) && (
                    <Tooltip title="Terminate">
                      <img
                        alt=""
                        src="\assets\execution_logos\terminate.svg"
                        className="flex flex-row icon-blue-style cursor-hand"
                        height="17px"
                        width="17px"
                        onClick={() => {
                          setShowWarningModal(true);
                          setSelectedExeObject(res);
                        }}
                      ></img>
                    </Tooltip>
                  )}
                  {hasFullAccess && (
                    <Tooltip title="Delete">
                      <img
                        alt=""
                        src="\assets\execution_logos\deleteDisable.svg"
                        className="flex flex-row icon-blue-style cursor-hand dashboad-action-font-style"
                      ></img>
                    </Tooltip>
                  )}
                </div>
              );
            } else if (res?.executionStatus === 'Aborted') {
              res['executionStatusTemp'] = res.executionStatus;
              res.Actions.push(
                <span className="flex flex-row suite-action delete-icon-style gap-2">
                  {res?.suite.suiteType !== 'Manual' ? downloadIcon(res) : null}
                  {res?.suite.suiteType !== 'Manual' && isProjectOpen && (
                    <Tooltip title="Run">
                      <img
                        alt=""
                        src="\assets\execution_logos\execution.svg"
                        className="flex flex-row icon-blue-style cursor-hand"
                        height="17px"
                        width="17px"
                        onClick={() => executeFunction(res, false)}
                      ></img>
                    </Tooltip>
                  )}
                  {hasFullAccess && (
                    <Tooltip title="Delete">
                      <img
                        alt=""
                        src="\assets\execution_logos\delete.svg"
                        className="flex flex-row icon-blue-style cursor-hand dashboad-action-font-style"
                        onClick={() => {
                          setOpen(true);
                          setSelectedDataObj(res);
                        }}
                      ></img>
                    </Tooltip>
                  )}
                </span>
              );
            } else if (res?.executionStatus === 'Cancelled') {
              res['executionStatusTemp'] = res.executionStatus;
              if (!res['requestFormed'] || res['requestFormed'] === 'false') {
                res['executionStatusTemp'] = res?.currentStatus ? res.currentStatus : res.executionStatus;
              }
              res.Actions.push(
                <span className="flex flex-row suite-action delete-icon-style gap-1">
                  {isProjectOpen &&
                    (!res['requestFormed'] || res['requestFormed'] === 'false' ? (
                      <Tooltip title="Run">
                        <img
                          alt=""
                          src="\assets\execution_logos\execution.svg"
                          className="flex flex-row icon-blue-style opacity-50"
                          height="17px"
                          width="17px"
                        ></img>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Run">
                        <img
                          alt=""
                          src="\assets\execution_logos\execution.svg"
                          className="flex flex-row icon-blue-style cursor-hand"
                          height="17px"
                          width="17px"
                          onClick={() => executeFunction(res, false)}
                        ></img>
                      </Tooltip>
                    ))}
                  {hasFullAccess && (
                    <Tooltip title="Delete">
                      <img
                        alt=""
                        src="\assets\execution_logos\delete.svg"
                        className="flex flex-row icon-blue-style cursor-hand dashboad-action-font-style"
                        onClick={() => {
                          setOpen(true);
                          setSelectedDataObj(res);
                        }}
                      ></img>
                    </Tooltip>
                  )}
                </span>
              );
            } else if (res?.executionStatus === 'Completed') {
              if (res.resultStatus === 'WARNING') {
                res['executionStatusTemp'] = 'Warning';
                res.Actions.push(
                  <div className="flex flex-row delete-icon-style gap-2 suite-action">
                    {downloadIcon(res)}
                    {mailIcon(res)}
                    {isProjectOpen && (
                      <Tooltip title="Run">
                        <img
                          alt=""
                          src="\assets\execution_logos\execution.svg"
                          className="flex flex-row icon-blue-style cursor-hand "
                          height="17px"
                          width="17px"
                          onClick={() => executeFunction(res, false)}
                        ></img>
                      </Tooltip>
                    )}
                    {hasFullAccess && (
                      <Tooltip title="Delete">
                        <img
                          alt=""
                          src="\assets\execution_logos\delete.svg"
                          className="flex flex-row icon-blue-style cursor-hand dashboad-action-font-style"
                          onClick={() => {
                            setOpen(true);
                            setSelectedDataObj(res);
                          }}
                        ></img>
                      </Tooltip>
                    )}
                  </div>
                );
              } else if (res?.resultStatus === 'Aborted') {
                res['executionStatusTemp'] = res.resultStatus;
                res.Actions.push(
                  <span className="flex flex-row suite-action gap-2 delete-icon-style">
                    {res?.suite.suiteType !== 'Manual' ? downloadIcon(res) : null}
                    {res?.suite.suiteType !== 'Manual' && isProjectOpen && (
                      <Tooltip title="Run">
                        <img
                          alt=""
                          src="\assets\execution_logos\execution.svg"
                          className="flex flex-row icon-blue-style cursor-hand"
                          height="17px"
                          width="17px"
                          onClick={() => executeFunction(res, false)}
                        ></img>
                      </Tooltip>
                    )}
                    {hasFullAccess && (
                      <Tooltip title="Delete">
                        <img
                          alt=""
                          src="\assets\execution_logos\delete.svg"
                          className="flex flex-row icon-blue-style cursor-hand dashboad-action-font-style"
                          onClick={() => {
                            setOpen(true);
                            setSelectedDataObj(res);
                          }}
                        ></img>
                      </Tooltip>
                    )}
                  </span>
                );
              } else if (res?.resultStatus === 'Terminated') {
                res['executionStatusTemp'] = res.resultStatus;
                res.Actions.push(
                  <span className="flex flex-row suite-action gap-2 delete-icon-style">
                    {res?.suite.suiteType !== 'Manual' ? downloadIcon(res) : null}
                    {res?.suite.suiteType !== 'Manual' && isProjectOpen && (
                      <Tooltip title="Run">
                        <img
                          alt=""
                          src="\assets\execution_logos\execution.svg"
                          className="flex flex-row icon-blue-style cursor-hand"
                          height="17px"
                          width="17px"
                          onClick={() => executeFunction(res, false)}
                        ></img>
                      </Tooltip>
                    )}
                    {hasFullAccess && (
                      <Tooltip title="Delete">
                        <img
                          alt=""
                          src="\assets\execution_logos\delete.svg"
                          className="flex flex-row icon-blue-style cursor-hand dashboad-action-font-style"
                          onClick={() => {
                            setOpen(true);
                            setSelectedDataObj(res);
                          }}
                        ></img>
                      </Tooltip>
                    )}
                  </span>
                );
              } else if (res?.resultStatus === 'FAIL') {
                res['executionStatusTemp'] = 'Failed';
                res.Actions.push(
                  <div className="flex flex-row delete-icon-style gap-2 suite-action">
                    {downloadIcon(res)}
                    {mailIcon(res)}
                    {isProjectOpen && (
                      <Tooltip title="Run only failed">
                        <img
                          alt=""
                          src="\assets\execution_logos\Rerun.svg"
                          className="flex flex-row icon-blue-style cursor-hand"
                          height="17px"
                          width="17px"
                          onClick={() => {
                            reRunFailed(res);
                          }}
                        ></img>
                      </Tooltip>
                    )}
                    {isProjectOpen && (
                      <Tooltip title="Run">
                        <img
                          alt=""
                          src="\assets\execution_logos\execution.svg"
                          className="flex flex-row icon-blue-style cursor-hand"
                          height="17px"
                          width="17px"
                          onClick={() => executeFunction(res, false)}
                        ></img>
                      </Tooltip>
                    )}
                    {hasFullAccess && (
                      <Tooltip title="Delete">
                        <img
                          alt=""
                          src="\assets\execution_logos\delete.svg"
                          className="flex flex-row icon-blue-style cursor-hand dashboad-action-font-style"
                          onClick={() => {
                            setOpen(true);
                            setSelectedDataObj(res);
                          }}
                        ></img>
                      </Tooltip>
                    )}
                  </div>
                );
              } else if (res.resultStatus === 'SKIP') {
                res['executionStatusTemp'] = 'Skipped';
                res.Actions.push(
                  <div className="flex flex-row delete-icon-style gap-2 suite-action">
                    {res?.suite.suiteType === 'Manual' ? downloadIcon(res) : null}
                    {mailIcon(res)}
                    {isProjectOpen && (
                      <Tooltip title="Run">
                        <img
                          alt=""
                          src="\assets\execution_logos\execution.svg"
                          className="flex flex-row icon-blue-style cursor-hand"
                          height="17px"
                          width="17px"
                          onClick={() => executeFunction(res, false)}
                        ></img>
                      </Tooltip>
                    )}
                    {hasFullAccess && (
                      <Tooltip title="Delete">
                        <img
                          alt=""
                          src="\assets\execution_logos\delete.svg"
                          className="flex flex-row icon-blue-style cursor-hand dashboad-action-font-style"
                          onClick={() => {
                            setOpen(true);
                            setSelectedDataObj(res);
                          }}
                        ></img>
                      </Tooltip>
                    )}
                  </div>
                );
              } else if (res?.resultStatus === 'PASS') {
                res['executionStatusTemp'] = 'Passed';
                res.Actions.push(
                  <span className="flex flex-row suite-action gap-2 delete-icon-style">
                    {downloadIcon(res)}
                    {mailIcon(res)}
                    {isProjectOpen && (
                      <Tooltip title="Run">
                        <img
                          alt=""
                          src="\assets\execution_logos\execution.svg"
                          className="flex flex-row icon-blue-style cursor-hand dashboad-action-font-style"
                          onClick={() => executeFunction(res, false)}
                        ></img>
                      </Tooltip>
                    )}
                    {hasFullAccess && (
                      <Tooltip title="Delete">
                        <img
                          alt=""
                          src="\assets\execution_logos\delete.svg"
                          className="flex flex-row icon-blue-style cursor-hand dashboad-action-font-style"
                          onClick={() => {
                            setOpen(true);
                            setSelectedDataObj(res);
                          }}
                        ></img>
                      </Tooltip>
                    )}
                  </span>
                );
              } else if (res?.resultStatus?.toLowerCase() === 'n/a') {
                res['executionStatusTemp'] = 'N/A';
                res.Actions.push(
                  <div className="flex flex-row delete-icon-style gap-1 suite-action">
                    {isProjectOpen && (
                      <Tooltip title="Run">
                        <img
                          alt=""
                          src="\assets\execution_logos\execution.svg"
                          className="flex flex-row icon-blue-style cursor-hand dashboad-action-font-style"
                          onClick={() => executeFunction(res, false)}
                        ></img>
                      </Tooltip>
                    )}
                    {hasFullAccess && (
                      <Tooltip title="Delete">
                        <img
                          alt=""
                          src="\assets\execution_logos\delete.svg"
                          className="flex flex-row icon-blue-style cursor-hand dashboad-action-font-style"
                          onClick={() => {
                            setOpen(true);
                            setSelectedDataObj(res);
                          }}
                        ></img>
                      </Tooltip>
                    )}
                  </div>
                );
              }
            } else {
              res['executionStatusTemp'] = res.executionStatus;
              res.Actions.push(
                <span className="flex flex-row suite-action delete-icon-style gap-1 mr-10">
                  {isProjectOpen &&
                    (!res['requestFormed'] || res['requestFormed'] === 'false' ? (
                      <Tooltip title="Cancel">
                        <img
                          src="\assets\execution_logos\cancel.svg"
                          className="flex flex-row icon-blue-style opacity-50"
                          height="17px"
                          width="17px"
                          alt="Cancel Icon"
                          title="Cancel"
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Cancel">
                        <img
                          src="\assets\execution_logos\cancel.svg"
                          alt="Cancel Icon"
                          className="flex flex-row icon-blue-style cursor-hand"
                          height="17px"
                          width="17px"
                          title="Cancel"
                          onClick={() => cancelExecutionFunction(res)}
                        />
                      </Tooltip>
                    ))}
                  {hasFullAccess && (
                    <Tooltip title="Delete">
                      <img
                        src="\assets\execution_logos\deleteDisable.svg"
                        className="flex flex-row icon-blue-style cursor-hand dashboad-action-font-style"
                        alt="Delete Icon"
                      />
                    </Tooltip>
                  )}
                </span>
              );
            }

            let executionResult = {
              resultStatus: res?.resultStatus,
              scriptStatistics: res?.scriptStatistics,
            };
            res['executionresult'] = suiteResultStatistics(executionResult);

            if (res && res.suite) {
              let suiteResponse = JSON.parse(JSON.stringify(res.suite));
              let selectedMachine = suiteResponse?.machines?.selectedMachines;
              let suiteMachines = suiteResponse?.machines;
              let machineInstancesArray = suiteMachines?.selectedMachines;
              let { createdByUname, executionStatusTemp, resultStatus } = res;
              let { original } = suiteResponse;
              if (machineInstancesArray) {
                machineInstancesArray?.map((machineInstancesValue) => {
                  if (machineInstancesValue?.machineInstances?.length) {
                    let macName;
                    machineInstancesValue?.machineInstances?.map((machineobj, index) => {
                      if (macName) {
                        macName = (
                          <span
                            onClick={() => {
                              getMachineDetails(
                                {
                                  sysData: machineobj,
                                  status: executionStatusTemp || 'N/A',
                                  owner: createdByUname,
                                  executionData: original,
                                },
                                executionStatusTemp,
                                resultStatus
                              );
                            }}
                            className="cursor-pointer hyper_link_color-common float-left table-font-style-common text-overflow-style dashboard-overflow-style"
                          >
                            {' '}
                            {macName} {`${index < machineInstancesValue?.machineInstances?.length - 1 ? ',' : ' '}`}
                          </span>
                        );
                      } else {
                        macName = (
                          <span
                            onClick={() => {
                              getMachineDetails(
                                {
                                  sysData: machineobj,
                                  status: executionStatusTemp || 'N/A',
                                  owner: createdByUname,
                                  executionData: original,
                                },
                                executionStatusTemp,
                                resultStatus
                              );
                            }}
                            className="cursor-pointer hyper_link_color-common float-left table-font-style-common text-overflow-style dashboard-overflow-style"
                          >
                            {machineobj?.machineInfo?.hostName}
                          </span>
                        );
                      }
                    });
                    res['userSystemName'] = macName;
                  } else {
                    let macName;
                    macName = (
                      <span
                        onClick={() => {
                          getMachineDetails(
                            {
                              sysData: machineInstancesArray,
                              status: executionStatusTemp || 'N/A',
                              owner: createdByUname,
                              executionData: original,
                            },
                            executionStatusTemp,
                            resultStatus
                          );
                        }}
                        className="cursor-pointer hyper_link_color-common float-left table-font-style-common text-overflow-style dashboard-overflow-style"
                      >
                        {' '}
                        {machineInstancesValue?.machineInfo?.hostName}{' '}
                      </span>
                    );
                    res['userSystemName'] = macName;
                  }
                });
              }

              if (selectedMachine && selectedMachine.length) {
                res['machineCol'] = JSON.parse(JSON.stringify(res.suite));
                res['machineCol']['resultStatus'] = res?.executionStatusTemp;
                res['machineCol']['id'] = res?._id;
                let machineArray = [];
                res.machine = [];
                selectedMachine?.map((selObj, index) => {
                  if (selObj) {
                    machineArray.push(
                      <span
                        className="flex flex-row"
                        onClick={() => getMachineDetails({ ...selObj, executionData: res }, res)}
                      >
                        {index === selectedMachine.length
                          ? selObj?.machineInstances || ''
                          : selObj?.machineInfo?.hostName + ','}
                      </span>
                    );
                  }
                });
                res.machine = machineArray;
              }
            }
          });

          let execDataTab = [];
          let noOfSuiteresult = results?.data?.responseMap?.totalCountOfResults;

          if (dashboardData.length && page !== 0) {
            execDataTab = [...dashboardData, ...response.slice(0, Number(noOfSuiteresult) - dashboardData.length)];
          } else {
            execDataTab = [...response];
          }
          setDashboardData(execDataTab);
          setLastpage(false);
        } else if (results.data.responseCode === 404 && results.data.message === 'FAILURE') {
          setLastpage(true);
          if (page === 0 && isEmptyValue(response)) {
            setDashboardData([]);
          }
          resetProgress();
        } else {
          setDashboardData([]);
          resetProgress();
        }
        setIsLoading(false);
      })
      .catch((error) => {
        resetProgress();
        console.error('SUITE_SPECIFIC_EXECUTION : ', error);
      });
    return [];
  }

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val) => !val;

  function Table({ columns, data }) {
    const filterTypes = useMemo(
      () => ({
        fuzzyText: fuzzyTextFilterFn,
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      []
    );

    const defaultColumn = useMemo(
      () => ({
        Filter: DefaultColumnFilter,
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        filterTypes,
        initialState: { pageIndex: 0, pageSize: 9 },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination
    );
    return (
      <>
        <table class="relative w-full" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr class="mt-4" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    class="sticky top-0 px-2 py-6 table-header_font-style-exe-dashboard bg-gray-100"
                    {...column.getHeaderProps()}
                    width={column.width}
                  >
                    <span className="flex flex-row">
                      {column.render('Header')}
                      {/* Render the columns filter UI */}
                      <div>
                        <span></span>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr className="project-row" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td class="py-1 px-2 text-center" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <br />
        {isLoading ? (
          <CommonLoader />
        ) : (
          dashboardData?.length === 0 && (
            <div className="fontPoppinsRegularSm no-steps">
              <p className="font-bold"> No data found</p>
            </div>
          )
        )}
      </>
    );
  }

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue >= filterValue;
    });
  }

  const getMachineDetails = async (data, execStatus, resultStatus) => {
    if (urlSuiteType?.toLowerCase() !== 'manual') {
      let reqData;
      try {
        if (data) {
          let dataPath = data?.executionData?.preExecution?.selectedSystem[0]?.machineInstances;
          if (dataPath) {
            dataPath.map((dataPathValues) => {
              let { hostName, osName, osVersion, deviceInfo } = dataPathValues?.machineInfo;
              let { status, owner, executionData } = data;
              reqData = {
                machineName: hostName,
                os: osName + '-' + osVersion,
                browser: dataPath?.browserName,
                device: deviceInfo?.name || '--',
                status: status,
                executionData: executionData,
                owner: owner,
              };
            });
          } else {
            let { hostName, osName } = data?.sysData?.machineInfo;
            let { status, owner, executionData } = data;
            let { browserName, deviceInfo } = data?.sysData;
            let deviceInfoName = ' ';
            deviceInfo?.map((deviceInfoDetails, index) => {
              deviceInfoName +=
                (deviceInfoDetails?.name ? deviceInfoDetails?.name : '') +
                `${index < deviceInfo.length - 1 ? ',' : ' '}`;
            });
            reqData = {
              machineName: hostName,
              os: osName,
              browser: browserName,
              device: deviceInfoName || '--',
              status: status,
              owner: owner,
              executionData: executionData,
            };
          }
          if (execStatus === 'Completed') {
            if (resultStatus === 'PASS') {
              reqData.status = 'Passed';
            } else if (resultStatus === 'FAIL') {
              reqData.status = 'Failed';
            } else if (resultStatus === 'SKIP') {
              reqData.status = 'Skipped';
            } else if (resultStatus === 'WARNING') {
              reqData.status = 'Warning';
            } else {
              reqData.status = resultStatus;
            }
          } else {
            reqData.status = execStatus;
          }
          getExecutionMachineDetailsReq(data.sysData.clientId).then((res) => {
            if (res.data && res.data.responseObject) {
              reqData.executions = res.data.responseObject;
            } else {
              reqData.executions = [];
            }
            setSelectedSystem(reqData);
            openModalfun('systemDetails');
          });
        }
      } catch (err) {
        console.error('Error while fetching machine details', err);
      }
    }
  };

  const execTypeValues = (value) => {
    const {
      suiteType,
      machines: { multiple, executionType },
    } = value;
    if (suiteType === 'Manual') {
      if (multiple === 'true') {
        return 'Multiple Users';
      } else if (multiple === 'false') {
        return 'Single User';
      }
    }
    if (suiteType === 'Automation') {
      return executionType === 'SEND_SUITE_TO_ALL'
        ? 'Parallel'
        : executionType === 'DISTRIBUTE'
        ? 'Distribute'
        : 'Sequential';
    }
  };

  const data = React.useMemo(() => (isLoading ? [] : dashboardData), [dashboardData, isLoading]);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        columns: [],
      },
      {
        Header: 'EXECUTION ID',
        accessor: '_id',
        disableFilters: true,
        width: '9%',
        Cell: (e) => (
          <a
            href=""
            onClick={(event) => {
              event.preventDefault();
              getData(e.cell.row.original, 'details');
            }}
            className="hyper_link_color-common float-left table-font-style-common"
          >
            {' '}
            {e.value}{' '}
          </a>
        ),
      },

      {
        Header: 'PARENT ID',
        accessor: 'parentReferenceId',
        disableFilters: true,
        width: '9.50%',
        Cell: (e, index) => (
          <>
            <span className="table-non-link-color-common float-left table-font-style-common">
              {' '}
              {e?.value ? e?.value : 'N/A'}{' '}
            </span>
            {e?.data[e?.row?.id]?.autoRunExecution ? (
              <img
                src="\assets\execution_logos\autoRun.svg"
                className=" flex flex-row cursor-text  icon-blue-style float-left ml-1 "
                height="17px"
                width="17px"
              ></img>
            ) : null}
          </>
        ),
      },
      {
        Header: 'MACHINE',
        accessor: 'userSystemName',
        disableFilters: true,
        width: '10.50%',
        Cell: ({ value, row }) => {
          let title = ' ';
          row?.original?.suite?.machines?.selectedMachines?.map((macInstances, index) => {
            if (macInstances?.machineInstances) {
              const systemName = macInstances?.machineInstances?.[0];
              title +=
                (systemName?.machineInfo?.hostName ? systemName?.machineInfo?.hostName : '') +
                `${index < row?.original?.suite?.machines?.selectedMachines.length - 1 ? ',' : ' '}`;
            } else {
              title +=
                (macInstances?.machineInfo?.hostName ? macInstances?.machineInfo?.hostName : '') +
                `${index < row?.original?.suite?.machines?.selectedMachines.length - 1 ? ',' : ' '}`;
            }
          });
          return (
            <Tooltip title={title} placement="top">
              <div className="table-non-link-color-common float-left table-font-style-common">{value}</div>
            </Tooltip>
          );
        },
      },
      {
        Header: 'EXECUTION TYPE',
        accessor: 'suite',
        disableFilters: true,
        width: '10.50%',
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common float-left table-font-style-common">
              {execTypeValues(value)}
            </div>
          );
        },
      },
      {
        Header: 'RUN INITIATED BY',
        accessor: 'createdByUname',
        disableFilters: true,
        width: '11%',
        Cell: (e) => (
          <a
            href=""
            onClick={(event) => {
              event.preventDefault();
              openModalfun('createdBy', e.cell.row.original);
            }}
            className="hyper_link_color-common float-left cursor-hand table-font-style-common"
          >
            {' '}
            {e.value}{' '}
          </a>
        ),
      },
      {
        Header: 'EXECUTION START TIME',
        accessor: 'executionStartTime',
        disableFilters: true,
        width: '13.50%',
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common float-left table-font-style-common exe-start-time-width">
              {value}
            </div>
          );
        },
      },
      {
        Header: 'STATUS',
        accessor: 'executionStatusTemp',
        disableFilters: true,
        width: '12.50%',
        Cell: (e) => {
          let suiteType = e?.cell?.row?.original?.suite?.suiteType ? e.cell.row.original.suite.suiteType : 'Automation';
          return e.value === 'Passed' ? (
            <>
              <div
                style={{ color: '#79B62F' }}
                className="float-left mt-1 table-font-style-common cursor-pointer opacity-1 flex flex-row"
                onClick={() => navigateToExecutionResult(e)}
              >
                {e.value}
              </div>
            </>
          ) : ['Fetching Results', 'Running', 'Partially Executed', 'Processing Result'].includes(e.value) ? (
            <div class="flex flex-row">
              <div
                className=" hyper_link_color-common float-left table-font-style-common mt-1  cursor-pointer opacity-1"
                onClick={() => handleStatusClick(e)}
              >
                {e.value}
              </div>
              {executionInfo(e)}
            </div>
          ) : e.value === 'Failed' ? (
            <div
              style={{ color: '#C50303' }}
              className=" float-left mt-1 table-font-style-common cursor-pointer opacity-1 flex flex-row"
              onClick={() => navigateToExecutionResult(e)}
            >
              {e.value}
            </div>
          ) : e.value === 'Terminated' ? (
            e?.row?.original?.resultStatus ? (
              <div
                style={{ color: '#C50303' }}
                className=" float-left mt-1 table-font-style-common cursor-pointer opacity-1 flex flex-row"
                onClick={() => navigateToExecutionResult(e)}
              >
                {e.value}
              </div>
            ) : (
              <div class="flex flex-row">
                <div
                  style={{ color: '#C50303' }}
                  className="float-left mt-1 table-font-style-common opacity-50 flex flex-row"
                >
                  {e.value}
                </div>
                {executionInfo(e)}
              </div>
            )
          ) : e.value === 'Aborted' ? (
            <>
              <div
                style={{ color: '#C50303' }}
                className=" float-left mt-1 table-font-style-common cursor-pointer opacity-1 flex flex-row"
              >
                {e.value}
              </div>
            </>
          ) : e.value === 'Scheduled' ? (
            <div style={{ color: 'yellow' }} className=" float-left mt-1 table-font-style-common">
              {e.value}
            </div>
          ) : e.value === 'Warning' ? (
            <>
              <div
                className=" text-yellow-400 float-left mt-1  table-font-style-common cursor-pointer opacity-1 flex flex-row"
                onClick={() => navigateToExecutionResult(e)}
              >
                {e.value}
              </div>
            </>
          ) : e.value === 'N/A' ? (
            <div className=" text-grey-400 float-left mt-1 table-font-style-common">{e.value}</div>
          ) : ['Pending', ...beforeRunningStatuses].includes(e.value) ? (
            <div class="flex flex-row">
              <div
                className=" text-yellow-500 float-left mt-1 flex flex-row table-font-style-common cursor-pointer opacity-1"
                onClick={() => {
                  if (['Pending', ...partialResultPageStatuses].includes(e.value)) {
                    handleStatusClick(e);
                  }
                }}
              >
                {e.value === 'Pending' ? getStatusTypeForPending(e?.row?.original) : e.value}
              </div>
              {executionInfo(e)}
            </div>
          ) : e.value === 'Cancelled' ? (
            <div class="flex flex-row">
              <div className="table-non-link-color-common  float-left mt-1 table-font-style-common">{e.value}</div>
              {executionInfo(e)}
            </div>
          ) : e.value === 'Skipped' && suiteType === 'Manual' ? (
            <div
              className="table-non-link-color-common  float-left mt-1 table-font-style-common cursor-pointer"
              onClick={() => navigateToExecutionResult(e)}
            >
              {e.value}
            </div>
          ) : (
            <div
              className=" hyper_link_color-common float-left table-font-style-common mt-1  cursor-pointer opacity-1"
              onClick={() => handleStatusClick(e)}
            >
              {e.value}
            </div>
          );
        },
      },
      {
        Header: 'RESULT',
        accessor: 'executionresult',
        disableFilters: true,
        width: '11%',
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left table-font-style-common cursor">{value}</div>;
        },
      },
      {
        Header: 'ACTIONS',
        accessor: 'Actions',
        disableFilters: true,
        width: '13%',
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common fontPoppinsRegularMd mt-2 ">{value}</div>;
        },
      },
    ],
    []
  );

  const handleStatusClick = (e) => {
    let suiteType = e?.data ? e.data[0]?.suite?.suiteType : '';
    if (suiteType && suiteType.toLowerCase() === 'manual') {
      navigateToExecutionResult(e);
    } else {
      navigateToModuleListpage(e);
    }
  };

  const executionInfo = (execData) => {
    return ['Pending', 'Cancelled', 'Terminated', 'Running'].includes(execData.value) ? (
      <ClientStatusModal
        clientStatus={execData?.row?.original?.clientStatus}
        executionStatus={execData.value}
        headless={execData?.row?.original?.headless || false}
      />
    ) : (
      ''
    );
  };

  const mailIcon = (res) => {
    return (
      <>
        {res?.suite.suiteType === 'Manual' &&
          (res?.suite?.emailData?.emailTheReportAfterExecution === 'true' ||
            res?.suite?.slackData?.slackManuallyTriggerNotification) && (
            <Tooltip title="Send Report">
              <img
                id={res?._id}
                onClick={() => {
                  sendMail(res);
                }}
                src="\assets\execution_logos\mail.svg"
                className="flex flex-row icon-blue-style cursor-hand"
                height="17px"
                width="17px"
              ></img>
            </Tooltip>
          )}
      </>
    );
  };

  const downloadIcon = (res) => {
    return (
      <>
        {res?.executionStatus === 'Terminated' && !res?.resultStatus ? (
          ''
        ) : (
          <LazyDownloadPDFButton e={res._id} size={18}></LazyDownloadPDFButton>
        )}
      </>
    );
  };

  function navigateToModuleListpage(e) {
    if ([initializingConstant].includes(e?.value)) {
      // no need to navigate if the execution is in init state
      return;
    }
    const exec_name = window.location.pathname.split('/')[3];
    let exeId = e?.data[e?.row?.id]._id;
    let suiteId = e?.data[e?.row?.id].suiteId;
    let suiteType = e?.data[e?.row?.id].suite.suiteType;
    if (e?.data[e?.row?.id].suite.machines.multiple === 'true') {
      if (e?.data[e?.row?.id].suite.machines.executionType === 'SEND_SUITE_TO_ALL') {
        history.push(
          `/execution/suite/${exec_name}/suite-dashboard/${exeId}?suiteId=${
            e?.data[e?.row?.id].suite.id
          }&detailsPage=specificExePageParallel`
        );
      } else if (e?.data[e?.row?.id].suite.machines.executionType === 'DISTRIBUTE') {
        history.push(
          `/execution/suite/${exec_name}/suite-dashboard/${exeId}?suiteId=${suiteId}&detailsPage=specificExePageDisytribute`
        );
      }
    } else {
      history.push(
        `/execution/suite/${exec_name}/suite-dashboard/${exeId}?suiteId=${suiteId}&suiteType=${suiteType}&detailsPage=moduleDetails`
      );
    }
  }

  const navigateToExecutionResult = (event) => {
    const location = window.location;
    if (event) {
      const row_data = event.cell.row.original;
      const exec_name = window.location.pathname.split('/')[3];
      const exec_id = row_data._id;
      let multiple = false;
      let macDetails = event.cell.row.original.suite.machines;
      localStorage.setItem('screenShotsDeleted', row_data?.screenShotsDeleted ? row_data?.screenShotsDeleted : false);
      localStorage.setItem('videosDeleted', row_data?.videosDeleted ? row_data?.videosDeleted : false);
      if (
        (macDetails &&
          (['SEND_SUITE_TO_ALL', 'DISTRIBUTE'].includes(macDetails['executionType']) ||
            macDetails['userExecutionType'] === 'SINGLE_USER_MULTIPLE_MACHINE')) ||
        event.cell.row.original.suite?.suiteType?.toLowerCase() === 'automation'
      ) {
        multiple = true;
      }
      if (event?.value === 'Terminated') {
        setTimeout(() => {
          if (multiple) {
            history.push(
              `/execution/suite/${exec_name}/execution dashboard/overview/machines/Result?executionId=${exec_id}&multiple=${multiple}`
            );
          } else {
            history.push(
              `/execution/suite/${exec_name}/execution dashboard/Root Module/results?executionId=${exec_id}&multiple=${multiple}&suiteId=${urlSuiteId}&suiteType=${urlSuiteType}`
            );
          }
        }, 1000);
      } else {
        if (multiple) {
          history.push(
            `/execution/suite/${exec_name}/execution dashboard/overview/machines/Result?executionId=${exec_id}&multiple=${multiple}`
          );
        } else {
          history.push(
            `/execution/suite/${exec_name}/execution dashboard/Root Module/results?executionId=${exec_id}&multiple=${multiple}&suiteId=${urlSuiteId}&suiteType=${urlSuiteType}`
          );
        }
      }
    }
  };

  const [open, setOpen] = useState(false);
  const [selectedDataObj, setSelectedDataObj] = useState({});
  const [selectedDeleteOptions, setSelectedDeleteOptions] = useState([]);
  const [operation, setOperation] = useState();
  const [showModal, setShowModal] = useState(false);
  const [deleteAllSelected, setDeleteAllSelected] = useState('');
  const [userData, setModifiedId] = useState();

  function getData(data, type) {
    let pathUrl = window.location.pathname;
    history.push(`${pathUrl}/${data._id}?suiteId=${urlSuiteId}&suiteType=${urlSuiteType}`);
    setSelectedDataObj(data);
  }

  function selectDeleteOption(e, value) {
    let tempArray = [];
    if (e.target.checked) {
      tempArray = selectedDeleteOptions;
      tempArray.push(value);
      setSelectedDeleteOptions([...tempArray]);
      if (value === deleteOption[0]) {
        setDeleteAllSelected('deleteAll');
        let htmlEleScreenshot = document.getElementById(deleteOption[1]);
        let htmlEleVideo = document.getElementById(deleteOption[2]);
        if (htmlEleScreenshot) {
          htmlEleScreenshot.checked = true;
          htmlEleScreenshot.disabled = true;
        }
        if (htmlEleVideo) {
          htmlEleVideo.checked = true;
        }
      }
    } else {
      const ind = tempArray.findIndex((tempObj) => tempObj === value);
      tempArray.splice(ind, 1);
      if (value === deleteOption[0]) {
        setDeleteAllSelected('');
        let htmlEleScreenshot = document.getElementById(deleteOption[1]);
        let htmlEleVideo = document.getElementById(deleteOption[2]);
        if (htmlEleScreenshot) {
          htmlEleScreenshot.checked = false;
          htmlEleScreenshot.disabled = false;
        }
        if (htmlEleVideo) {
          htmlEleVideo.checked = false;
        }
      }

      setSelectedDeleteOptions(tempArray);
    }
  }

  const deleteExecution = (id, toastMsg) => {
    setOpen(false);
    setSelectedDeleteOptions([]);
    MyAlert.success(`${id} ${toastMsg}`);
  };

  let handleDelete = (suiteObj) => {
    let payload = {};
    let screenshotPathKey = '/Screenshots';
    if (suiteObj) {
      if (suiteObj?.suite?.storageDetails?.type) {
        let storageDetails = suiteObj?.suite?.storageDetails;
        if (suiteObj?.suite?.suiteType === 'Manual') {
          payload['type'] = storageDetails?.type;
          payload['bucketName'] = storageDetails?.outputs?.bucketName;
          payload['filePath'] = [
            storageDetails?.outputs?.dirName ? storageDetails?.outputs?.dirName + '/' + suiteObj?._id : suiteObj?._id,
          ];
          payload['updateSrc'] = true;
        } else if (storageDetails?.type === 'sharedDrive') {
          payload['type'] = storageDetails?.type;
          payload['sharedIpAddress'] = storageDetails?.outputs?.bucketName;
          payload['filePath'] = [storageDetails?.outputs?.dirName + '/' + suiteObj?._id + '/'];
          payload['updateSrc'] = selectedDeleteOptions.includes('Delete all.');
        } else {
          payload['type'] = storageDetails?.type;
          payload['bucketName'] = storageDetails?.outputs?.bucketName;
          payload['filePath'] = [
            storageDetails?.outputs?.dirName ? storageDetails?.outputs?.dirName + '/' + suiteObj?._id : suiteObj?._id,
          ];
          payload['updateSrc'] = selectedDeleteOptions.includes('Delete all.');
        }

        if (selectedDeleteOptions.length === 1) {
          if (selectedDeleteOptions.includes('Delete only screenshots.') && storageDetails?.type === 'sharedDrive') {
            payload.filePath = [storageDetails?.outputs?.dirName + '/' + suiteObj?._id + screenshotPathKey + '/'];
            payload.updateSrc = false;
          } else if (
            selectedDeleteOptions.includes('Delete only screenshots.') &&
            storageDetails?.type !== 'sharedDrive'
          ) {
            payload.filePath = [
              storageDetails?.outputs?.dirName
                ? storageDetails?.outputs?.dirName + '/' + suiteObj._id + screenshotPathKey
                : suiteObj._id + screenshotPathKey,
            ];
            payload.updateSrc = false;
          } else if (selectedDeleteOptions.includes('Delete only videos.') && storageDetails?.type === 'sharedDrive') {
            payload.filePath = [storageDetails?.outputs?.dirName + '/' + suiteObj?._id + '/Videos/'];
            payload.updateSrc = false;
          } else if (selectedDeleteOptions.includes('Delete only videos.') && storageDetails?.type !== 'sharedDrive') {
            payload.filePath = [
              storageDetails?.outputs?.dirName
                ? storageDetails?.outputs?.dirName + '/' + suiteObj._id + '/Videos'
                : suiteObj._id + '/Videos',
            ];
            payload.updateSrc = false;
          }
        } else {
          if (selectedDeleteOptions.includes('Delete all.')) {
          } else {
            if (
              selectedDeleteOptions.includes('Delete only screenshots.') &&
              selectedDeleteOptions.includes('Delete only videos.') &&
              storageDetails?.type === 'sharedDrive'
            ) {
              payload.filePath = [
                storageDetails?.outputs?.dirName + '/' + suiteObj._id + screenshotPathKey,
                storageDetails?.outputs?.dirName + '/' + suiteObj?._id + '/Videos/',
              ];
            } else if (
              selectedDeleteOptions.includes('Delete only screenshots.') &&
              selectedDeleteOptions.includes('Delete only videos.') &&
              storageDetails?.type === 'cloudS3'
            ) {
              payload.filePath = [
                storageDetails?.outputs?.dirName
                  ? storageDetails?.outputs?.dirName + '/' + suiteObj._id + screenshotPathKey
                  : suiteObj._id + screenshotPathKey,
                storageDetails?.outputs?.dirName
                  ? storageDetails?.outputs?.dirName + '/' + suiteObj._id + '/Videos'
                  : suiteObj._id + '/Videos',
              ];
            }
          }
        }

        deleteExecutionDataReq(suiteObj?._id, payload).then((res) => {
          if (res?.data?.status === 'SUCCESS') {
            setOpen(false);
            if (selectedDeleteOptions.includes('Delete all.')) {
              deleteExecution(suiteObj?._id, 'suite deleted successfully');
              setDeleteAllSelected('');
              getAllDashboard();
            } else {
              if (selectedDeleteOptions.includes('Delete only screenshots.')) {
                deleteExecution(suiteObj?._id, 'screenshots deleted successfully');
              } else if (selectedDeleteOptions.includes('Delete only videos.')) {
                deleteExecution(suiteObj?._id, 'videos deleted successfully');
              } else {
                deleteExecution(suiteObj?._id, 'suite deleted successfully');
              }
              getAllDashboard();
              setSelectedDeleteOptions([]);
            }
          } else {
            setOpen(false);
            if (selectedDeleteOptions.includes('Delete all.')) {
              MyAlert.info(`${suiteObj._id} deleted all successfully`);
              setSelectedDeleteOptions([]);
            } else {
              if (selectedDeleteOptions.includes('Delete only screenshots.')) {
                MyAlert.info(`${suiteObj._id} screenshots not configured`);
                setSelectedDeleteOptions([]);
              } else if (selectedDeleteOptions.includes('Delete only videos.')) {
                MyAlert.info(`${suiteObj._id} videos not configured`);
                setSelectedDeleteOptions([]);
              }
            }
            getAllDashboard();
            setSelectedDeleteOptions([]);
          }
        });
      }
    }
  };

  function openModalfun(btn, data) {
    if (btn === 'createdBy') {
      setShowModal(true);
      setOperation('createdBy');
      setModifiedId(data);
    } else if (btn === 'systemDetails') {
      // setShowModal(true);
      setOperation('systemDetails');
    }
  }

  function executeFunction(obj, deleteSpillOverMemory) {
    reExecuteSuiteReq(obj?._id, deleteSpillOverMemory).then((results) => {
      const response = results?.data?.responseObject;
      if (response) {
        if (checked) setChecked(false);
        else getAllDashboard();
      } else if (results?.data?.message && results?.data?.message.toLowerCase().includes('please connect a device')) {
        MyAlert.info('Device is not available. Please connect a device');
      } else if (results?.data?.message && results?.data?.message.toLowerCase().includes('please install a browser')) {
        MyAlert.info(`${results?.data?.message}`);
      } else if (
        results?.data?.message &&
        results?.data?.message.startsWith('Client') &&
        results?.data?.message.toLowerCase().includes('is not available')
      ) {
        MyAlert.info(`${results?.data?.message}`);
      } else if (results?.data?.message === 'Consumed Memory is above 85%') {
        setSpillOverData(obj);
        setMemoryWarningPopup(true);
      } else if (results?.data?.message && results?.data?.message) {
        createAlertFun('info', `${results?.data?.message}`);
      } else {
        console.error(results?.data?.message);
      }
    });
  }

  function terminateFunction(id) {
    terminateExecutionSuiteReq(id).then((results) => {
      const { message = '', status = '' } = results?.data;
      if (status === 'SUCCESS') {
        MyAlert.success(`${id} Suite Terminated successfully`);
        getAllDashboard();
      } else {
        if (message.includes('is completed')) {
          MyAlert.info(message);
        } else {
          MyAlert.warning(message);
        }
      }
    });
  }

  const sendMail = (res) => {
    try {
      sendExecutionMail(res?._id).then((response) => {
        if (response?.data?.status && response?.data?.status === 'SUCCESS') {
          MyAlert.success(response?.data?.message);
        } else {
          MyAlert.warning(response?.data?.message);
        }
      });
    } catch (error) {
      console.error('Exception in sendMail:', error);
    }
  };

  function cancelExecutionFunction(obj) {
    let statusObj = {
      executionStatus: 'Cancelled',
    };
    cancelExecutionReq(obj?._id, statusObj).then((results) => {
      const response = results?.data?.responseObject;
      if (response) {
        getAllDashboard();
        MyAlert.success(`${obj?._id} Suite Cancelled successfully`);
      } else {
        console.error(results?.data?.message);
      }
    });
  }

  function closeMachineDetailsModal() {
    setShowModal(false);
    setOperation();
  }

  function reRunFailed(selectedObj) {
    setSelectedSuiteObj(selectedObj);
    if (selectedObj?.fancyTreeData) {
    } else {
      getSingleExecutionDataReq(selectedObj?._id)
        .then((res) => {
          const responseObj = res.data.responseObject;
          let suiteObj = responseObj.suite;
          getSingleExecutionDetailsReq(selectedObj?._id).then((results) => {
            const response = results.data.responseObject;
            let tempArray = [];
            if (response) {
              if (response?.executionResponses?.length) {
                response?.executionResponses?.map((obj) => {
                  tempArray?.push(obj.executionStatusObject);
                });
              }
              let treeData = groupArrayOfObjects(tempArray);
              let selectedModuleData = suiteObj?.selectedModulesAndScripts;
              setPreselectedValue(selectedModuleData[0].children, treeData, suiteObj, selectedObj);
            }
          });
        })
        .catch((error) => {
          console.error('Error while fetching single execution details', error);
        });
    }
  }

  const groupArrayOfObjects = (test) => {
    let tempArray = {};
    test.forEach((element) => {
      Object.keys(element).map((key) => {
        if (element[key] === 'FAIL') {
          tempArray[key] = element[key];
        }
      });
    });
    return tempArray;
  };

  function setPreselectedValue(selectedModuleData, treeData, selectedObj, selectedSuiteObj) {
    let failedObjectList = [];
    if (selectedModuleData.length) {
      selectedModuleData?.map((treeObj, index) => {
        if (treeObj.folder) {
          if (treeData[treeObj.key] === 'FAIL') {
            treeObj['children'] = filterFailedScripts(treeObj?.children, treeData, selectedObj);
            failedObjectList.push(treeObj);
          }
        }
      });

      if (failedObjectList?.length) {
        failedObjectList.map((obj) => {
          if (obj?.checkbox) {
            obj.checkbox = false;
          }
        });
      }
      selectedObj.selectedModulesAndScripts[0].children = failedObjectList;
      localStorage.setItem('selectedSuite', JSON.stringify(selectedObj));
      setSelectedSuiteObj(selectedObj);
      setScreenMode('rerunFailed');
      localStorage.setItem('rerun', JSON.stringify(true));
      history.push(
        `/execution/suite/${selectedObj?.name}/suite-dashboard/${selectedSuiteObj?._id}?executionId=${selectedSuiteObj?._id}&suiteId=${urlSuiteId}&suiteType=${urlSuiteType}`
      );
    }
  }

  function filterFailedScripts(scriptArray, treeData, selectedObj) {
    let moduleData = [];
    if (scriptArray && scriptArray.length) {
      scriptArray.map((obj, index) => {
        if (treeData[obj.key] === 'FAIL') {
          if (obj?.folder) {
            obj['children'] = filterFailedScripts(obj?.children, treeData, selectedObj);
            moduleData.push(obj);
          } else {
            moduleData.push(obj);
          }
        }
      });
      return moduleData;
    }
  }

  const [suiteId, setSuiteId] = useState(localStorage.getItem('urlSuiteId'));
  const [checked, setChecked] = useState(archive.suiteFlags?.[suiteId] || false);
  useEffect(() => {
    archive.suiteFlags = archive.suiteFlags ? { ...archive.suiteFlags, [suiteId]: checked } : { [suiteId]: checked };
    localStorage.setItem('suiteFlags', JSON.stringify(archive.suiteFlags));
    const timeoutId = setTimeout(() => {
      getAllDashboard();
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [checked]);
  useEffect(() => {
    getAllDashboard();
  }, []);
  const isNotCBasicLicense = () =>
    JSON.parse(localStorage.getItem('test-optimize-user'))?.licenseType !== FREE_LICENSE_TYPE;
  const handleChange = (e) => {
    archive.suiteFlags[suiteId] = !archive.suiteFlags[suiteId];
    setChecked(!checked);
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block marginupper pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/4">
                <div className="inline-block">
                  <div className="flex flex-start ml-2">
                    <span className="pt-5 pl-3">
                      <ExclamationIcon className="h-6 w-6 text-yellow-600 " />
                    </span>
                    <Dialog.Title as="h3" className="text-sm leading-6 ml-4 pt-3 font-small text-gray-900">
                      <span className="fontPoppinsSemiboldLg block">Warning </span>
                      <span className="fontPoppinsMediumMd">Delete </span>
                    </Dialog.Title>
                  </div>
                </div>

                <hr className="line mt-px" />
                <div className="fontPoppinsMediumMd mt-3 text-gray-500 ml-16 mr-8">
                  <p className=""> Your data will be lost. Are you sure you want to delete {selectedDataObj?._id}? </p>
                  <p className="mt-2"> How do you want to proceed?</p>
                  {selectedDataObj?.suite?.suiteType != 'Manual' &&
                    deleteOption.map((option, i) => (
                      <div className="mt-1">
                        <label>
                          <input
                            type="checkbox"
                            id={option}
                            className={
                              deleteAllSelected === 'deleteAll' && i > 0
                                ? 'rounded opacity-30 mr-2 disabled-action'
                                : 'rounded mr-2'
                            }
                            onChange={(e) => selectDeleteOption(e, option)}
                          ></input>
                          {option}
                        </label>
                      </div>
                    ))}
                </div>
                <hr className="line mt-3" />
                <div className="mt-2 pb-2 flex flex-row float-right">
                  <button
                    type="button"
                    className="mt-3 gray-btn ml-1"
                    id="deleteBtn"
                    disabled={urlSuiteType === 'Manual' ? false : selectedDeleteOptions.length > 0 ? false : true}
                    onClick={() => handleDelete(selectedDataObj)}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 ml-4 primary-btn "
                    onClick={() => {
                      setOpen(false);
                      setSelectedDeleteOptions([]);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Modal open={showModal} className="modal-dialog">
        {operation === 'createdBy' ? (
          <div className="modal-container page-modal-size">
            <div className="modal-header ">
              <label className="Popup-header-common Popup-header-layout-style-user">
                {' '}
                <label> User Details -{userData?.createdByUname}</label>
              </label>
              <IconButton
                color="primary"
                component="span"
                className="close"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div className="modal-body" id="journal-scroll">
              <UserDetailsPage modifiedBy={userData?.createdBy} />
            </div>
          </div>
        ) : null}
      </Modal>

      {operation === 'systemDetails' ? (
        <MachineDetails data={selectedSystem} closeModal={closeMachineDetailsModal} />
      ) : null}

      {screenMode === 'executionDashboard' ? (
        !tab ? (
          <div className="content_area_style overflow-auto" id="journal-scroll">
            <div className="content_area_header_style">
              <div>
                <label className="main-header-label float-left ">Execution Dashboard</label>
              </div>
              {memoryWarningPopup && (
                <MemoryWarningPopup
                  handleCancel={() => setMemoryWarningPopup(false)}
                  handleContinue={async () => {
                    await executeFunction(spillOverData, true);
                    setMemoryWarningPopup(false);
                    setChecked(false);
                  }}
                  isOpen={memoryWarningPopup}
                />
              )}
              {dashboardData && isNotCBasicLicense() && (
                <Tooltip
                  title={`View ${checked ? DASHBOARD_CONSTANTS.RECENT : DASHBOARD_CONSTANTS.ARCHIVED} Dashboards`}
                  placement="top"
                >
                  <div className="absolute right-16">
                    <Toggle
                      checked={checked}
                      disableLabel={DASHBOARD_CONSTANTS.RECENT}
                      enableLabel={DASHBOARD_CONSTANTS.ARCHIVED}
                      id="toggle"
                      onChange={handleChange}
                      type="withLabel"
                      variant="primary"
                    />
                  </div>
                </Tooltip>
              )}
              <div className="flex flex-row float-right">
                {/* <FaSearch className="searchBtn mr-3 mt-0.5" /> */}
                {isProjectOpen &&
                  (progress === 0 ? (
                    <Sync
                      fontSize="small"
                      className="text-gray-700 mr-3"
                      onClick={() => {
                        if (!isSyncDisabled) {
                          startCircularProgress();
                          setIsSyncDisabled(true);
                          getAllDashboard();
                        }
                      }}
                    />
                  ) : (
                    <CircularProgressWithLabel value={progress} />
                  ))}
              </div>
            </div>
            <div
              onScroll={(e) => {
                handlescroll(e);
              }}
              className="table-height-executionDashboard overflow-auto dashboard-table-height"
              id="journal-scroll"
            >
              <Table columns={columns} data={dashboardData} />
            </div>
          </div>
        ) : (
          <ExecutionIdDetailsPage selectedSuite={selectedDataObj} />
        )
      ) : screenMode === 'rerunFailed' ? (
        <div className="content_area_style overflow-hidden">
          <CreateEditSuite
            setChecked={setChecked}
            suiteObj={selectedSuiteObj}
            rerunMode={true}
            setScreenMode={setScreenMode}
            getAllDashboard={getAllDashboard}
          />
        </div>
      ) : (
        <CreateEditSuite suiteObj={selectedSuite} dashboardData={dashboardData} />
      )}

      {showWarningModal && (
        <TerminateWarningComponent
          executionData={selectedExeObject}
          setShowWarningModal={setShowWarningModal}
          terminateExecution={terminateFunction}
          MyAlert={MyAlert}
          actionKey="Terminate"
        />
      )}
    </>
  );
};

export default SuiteDashboard;

import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import React from 'react';
import { Fragment } from 'react';
import { useAlert } from '../../common/alert_service/useAlert';
import RunTree from './run-tree';

const RunTreePage = (props) => {
  localStorage.removeItem('moduleId');
  const { AlertContatiner, MyAlert } = useAlert();
  const contextMenuData = [
    // {
    //     option: "Optimizer"
    // },
    // {
    //     option: "NLP Help"
    // }
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const renderTabs = () => {
    return (
      <div className="">
        <div className=" inline-block float-right mr-4">
          <Menu as="div" className="relative text-left">
            {({ open }) => (
              <>
                <div>
                  <Menu.Button>
                    <span className="sr-only">Open options</span>
                    <DotsVerticalIcon className="h-5 w-5 black" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none  z-10"
                  >
                    <div className="py-1">
                      {contextMenuData.map((data) => (
                        <Menu.Item>
                          {({ active }) => (
                            <span
                              className={classNames(
                                active ? 'bg-gray-100 text-blue-600' : 'text-gray-700',
                                'block px-4 py-2 text-sm fontPoppinsRegularMd'
                              )}
                            >
                              <label>{data.option}</label>
                            </span>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
    );
  };
  return (
    <div className="page-list-body">
      {renderTabs()}
      <AlertContatiner />
      <div className="page-table-body">
        <RunTree {...props} MyAlert={MyAlert} />
      </div>
    </div>
  );
};

export default RunTreePage;

import { TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import Modal from 'react-modal';
import * as yup from 'yup';
import { updateExecutionNameAndStatusReq } from '../../../../api/api_services';

const EditExecutionNameModal = (props) => {
  const [openModal, setOpenModal] = useState(true);
  const customStylesPage = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      // width: "640px",
      height: 'fit-content',
      background: '#fbfcfd 0% 0% no-repeat padding-box',
      boxShadow: '0px 0px 11px #000000a1',
      borderRadius: '4px',
      opacity: '1',
    },
  };
  let editData = props?.data;
  let initialValues = {
    executionName: editData?.executionName,
  };
  const validationSchema = yup.object({
    executionName: yup.string().required('Execution Name is required!!'),
  });
  const onSubmit = (values) => {
    if (values && values.executionName) {
      if (values.executionName.trim().length === 0) {
        formikDetails.setFieldError('executionName', 'White spaces are not allowed');
      } else {
        const reqBody = {
          name: values.executionName,
        };
        updateExecName(reqBody);
      }
    }
  };
  const formikDetails = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  async function updateExecName(reqBody) {
    try {
      const res = await updateExecutionNameAndStatusReq(editData.id, reqBody);
      if (res.data && res.data.responseObject) {
        props.reload(true);
        let title = `${formikDetails.values.executionName?.substring(0, 30)}${formikDetails.values.executionName?.length > 30 ? '...' : ''}`;
        props.MyAlert.success(`${title} execution name updated successfully`);
      } else {
        props.MyAlert.warning(`${res.data.message}`);
      }
      setOpenModal(false);
      props.closeModal(false);
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <Modal
      isOpen={openModal}
      style={customStylesPage}
      className="p-px"
      onRequestClose={() => {
        setOpenModal(false);
        props.closeModal(false);
      }}
    >
      <div className="mt-2 p-1">
        <span className="Popup-header-common pl-3">
          Edit Execution Name - {editData?.executionName?.substring(0, 30)}
          {editData?.executionName?.length > 30 ? '...' : ''}{' '}
        </span>
        <div className="float-right">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="ml-5 mr-5 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
      <form onSubmit={formikDetails.handleSubmit}>
        <div className="border-b border-t border-blue-100 mt-px mb-px pb-3">
          <div className="ml-6 mr-6 mt-3 grid grid-cols-1 gap-4 h-18">
            <div className="w-2/4 mt-2.5 mb-5">
              <TextField
                error={formikDetails.errors.executionName && formikDetails.touched.executionName}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                type="text"
                autoComplete="off"
                autoFocus={true}
                name="executionName"
                id="executionName"
                placeholder="Enter module name"
                onBlur={formikDetails.handleBlur}
                onChange={formikDetails.handleChange}
                value={formikDetails.values.executionName}
                label={
                  <>
                    {' '}
                    <span className="text-red-400">*</span> Execution Name
                  </>
                }
              />
              {formikDetails.errors.executionName && formikDetails.touched.executionName ? (
                <div className="text-red-400 text-xs fontPoppinsRegularSm">{formikDetails.errors.executionName}</div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="float-right my-3 mx-5">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="gray-btn"
          >
            Cancel
          </button>
          <button type="submit" className="primary-btn ml-3">
            Update
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default EditExecutionNameModal;

import React, { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import './defect-mgmt.scss';
import DefectPopup from './defect-email-popup';
import { getSingleProjectRequest, updateProjectRequest } from '@src/api/api_services';

function DefectEmail({ open, showPopup }) {
  const [showModal, setShowModal] = useState(false);
  let projectDetails = JSON.parse(localStorage.getItem('selected-project'));
  const [defectLifeCycleState, setDefectLifeCycleState] = useState([]);
  let [receivedEmailData, setReceivedEmailData] = useState({});
  const [emailPayload, setEmailPayload] = useState({});
  const [projdata, setProjdata] = useState({});
  const [dataReceived, setDataReceived] = useState(true);
  const [emailData, setEmailData] = useState([]);

  const getEmailPayloadData = (data) => {
    setDataReceived(false);
    setReceivedEmailData({ ...data });
  };
  const saveTemplate = async () => {
    let response;
    let projectData = {
      name: projectDetails.name,
      type: projectDetails.type,
      lifeCycleTemplateToEmail: [...projdata?.lifeCycleTemplateToEmail],
    };
    if (projectDetails.platform) {
      projectData.platform = projectDetails.platform;
    }
    if (projectDetails.appType) {
      projectData.appType = projectDetails.appType;
    }
    let defaultLifecycleIndex = projdata?.lifeCycleTemplateToEmail.findIndex(
      (data) => projdata.defaultDefectLifecycleTemplateId === data.lifeCycleTemplateId
    );
    if (receivedEmailData.emailAllUsers === 'yes') {
      projectData.lifeCycleTemplateToEmail[defaultLifecycleIndex].emailAllUsers = 'yes';
      projectData.lifeCycleTemplateToEmail[defaultLifecycleIndex].additionalRecipients =
        receivedEmailData.additionalRecipients;
      projectData.lifeCycleTemplateToEmail[defaultLifecycleIndex].additionalRecipientGroups =
        receivedEmailData.additionalRecipientGroups;
      projectData.lifeCycleTemplateToEmail[defaultLifecycleIndex].stateTransitions = emailPayload.stateTransitions;
    } else if (receivedEmailData.emailAllUsers === 'no') {
      projectData.lifeCycleTemplateToEmail[defaultLifecycleIndex].emailAllUsers = 'no';
      projectData.lifeCycleTemplateToEmail[defaultLifecycleIndex].additionalRecipients = [];
      projectData.lifeCycleTemplateToEmail[defaultLifecycleIndex].additionalRecipientGroups = [];
      projectData.lifeCycleTemplateToEmail[defaultLifecycleIndex].stateTransitions = receivedEmailData.stateTransitions;
    } else {
      projectData.lifeCycleTemplateToEmail[defaultLifecycleIndex].emailAllUsers = 'yes';
      projectData.lifeCycleTemplateToEmail[defaultLifecycleIndex].additionalRecipients = [];
      projectData.lifeCycleTemplateToEmail[defaultLifecycleIndex].additionalRecipientGroups = [];
      projectData.lifeCycleTemplateToEmail[defaultLifecycleIndex].stateTransitions = emailPayload.stateTransitions;
    }
    const payload = new FormData();
    payload.append('data', JSON.stringify(projectData));
    try {
      response = await updateProjectRequest(payload, projectDetails.id);
      if (response.data.responseObject === 1 || response.data.status === 'SUCCESS') {
        setShowModal(false);
        showPopup(false);
      }
    } catch (err) {
      console.error('save template api error', err);
    }
  };
  const getProjectData = async () => {
    try {
      let states = [];
      let projRes = await getSingleProjectRequest(projectDetails.id);
      let prj = projRes.data.responseObject;
      setProjdata(projRes.data.responseObject);
      let defaultEmailStateTransition = prj?.lifeCycleTemplateToEmail?.filter((data) => {
        if (data?.lifeCycleTemplateId === prj?.defaultDefectLifecycleTemplateId) {
          return data;
        }
        return null;
      });
      let emailstate_transitions = { ...emailPayload };
      emailstate_transitions.stateTransitions = [];
      emailstate_transitions.lifeCycleTemplateId = defaultEmailStateTransition[0].lifeCycleTemplateId;
      emailstate_transitions.additionalRecipientGroups = defaultEmailStateTransition[0].additionalRecipientGroups;
      emailstate_transitions.emailAllUsers = defaultEmailStateTransition[0].emailAllUsers;
      emailstate_transitions.additionalRecipients = defaultEmailStateTransition[0].additionalRecipients
        ? defaultEmailStateTransition[0].additionalRecipients
        : [];
      defaultEmailStateTransition[0]?.stateTransitions?.forEach((data) => {
        states.push(data.state);
        if (defaultEmailStateTransition[0].emailAllUsers === 'yes') {
          emailstate_transitions.stateTransitions?.push({
            state: data.state,
            emailAddresses: [],
            emailGroups: [],
          });
        } else {
          emailstate_transitions.stateTransitions?.push({
            state: data.state,
            emailAddresses: [...data.emailAddresses],
            emailGroups: [...data.emailGroups],
          });
        }
      });
      if (states.length) {
        setDefectLifeCycleState(states);
      }
      setEmailPayload({ ...emailstate_transitions });
    } catch (err) {
      console.error('get project api error', err);
    }
  };

  useEffect(() => {
    getProjectData();
  }, []);

  useEffect(() => {
    setShowModal(open);
  }, [open]);
  return (
    <div>
      <Modal open={showModal} className="modal-dialog">
        <div className="common-var-max-popup-style modal-container defect_popup_width">
          <div className="modal-header ">
            <label className="Popup-header-common">Edit Email configuration</label>
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                setShowModal(false);
                showPopup(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="modal-body defect_popup_width" id="journal-scroll">
            {
              <DefectPopup
                defectLifeCycleState={defectLifeCycleState}
                getEmailPayloadData={getEmailPayloadData}
                emailPayload={emailPayload}
                emailData={emailData}
                mode={'edit'}
              />
            }
          </div>
          <div className="modal-footer defect_popup_width">
            <button
              className="cancel-button-common cancel-button-layout-style-project mr-3 fontPoppinsMediumMd"
              onClick={() => {
                setShowModal(false);
                showPopup(false);
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="primary-btn"
              disabled={dataReceived}
              onClick={() => {
                saveTemplate();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default DefectEmail;

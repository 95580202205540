import { useEffect } from 'react';
import { createCurlReq } from '@api/api_services';
import { colors } from '@src/css_config/colorConstants.js';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  customModalBodyContainer: {
    width: '100%',
    whiteSpace: 'normal',
    boxShadow: `0px 0px 4px ${colors.shadow_black}`,
    overflow: 'hidden',
    height: '89%',
    backgroundColor: colors.text_white,
  },
});

const GenerateCurl = ({
  formData,
  curl,
  setCurl,
  stepResult,
  setWebserviceStepId,
  webserviceStepId,
  curlError,
  setCurlError,
  id,
}) => {
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = id || new URLSearchParams(search).get('scriptId');
  const classes = useStyles();
  const fetchCurlData = async () => {
    if (webserviceStepId !== stepResult?.id) {
      try {
        const selctedProjectData = JSON.parse(localStorage.getItem('selected-project'));
        const selectedProjectId = selctedProjectData.id;
        const response = await createCurlReq(formData, selectedProjectId, moduleId, scriptId);
        if (response.data.success) {
          setCurl(response.data.success);
          setWebserviceStepId(stepResult?.id);
        } else if (response.data?.errors?.message === 'Request Validation Failure') {
          setCurlError('Failed to Generate Curl');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };
  useEffect(() => {
    fetchCurlData();
  }, [stepResult?.id]);

  return (
    <div className={`${classes.customModalBodyContainer} modal-body`} id="journal-scroll">
      <div className="customContentContainer h-52">
        <div className="custom-left-container"></div>
        <div className="custom-right-container max-h-52 fontPoppinsRegularSm" id="journal-scroll">
          {curl ? (
            curl
          ) : curlError ? (
            <span className="flex justify-center text-red-700 fontPoppinsRegularMd">{curlError} </span>
          ) : (
            <span className="curl-loader fontPoppinsMediumMd">cURL is generating...</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default GenerateCurl;

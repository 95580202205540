import { useContext, useEffect, useState } from 'react';
import LabeledInput from '@pages/common/Inputs/LabeledInput';
import LabeledSelect from '@pages/common/Inputs/LabeledSelect';
import PurchaseSummaryCard from '@pages/common/LicensePurchaseComponents/PurchaseSummaryCard';
import cx from 'classnames';
import style from '@pages/licenses/styles/license-form.module.scss';
import { LicenseWizardContext } from '@pages/licenses/components/LicenseProvider';
import {
  FREE_LICENSE_TYPE,
  LICENSE_FORM_TYPES,
  getLicensePlanCost,
  selectedPlansList,
  getNetTotal,
  isUpgradeAndCProfessional,
  billingCycles,
  upgradeNetTotal,
  getUpgradeManualCost,
  getUpgradeAutomationFeaturesCost,
  upgradeStorageCost,
  daysInMonth,
  getPlatfromCharge,
} from '@util/licenseUtils';
import { allowWholeNumbersOnlyInNumberInputOnKeyDown } from '@src/util/common_utils';
import LicenseFeatures from './LicenseFeatures.js';
import { LICENSE_CONSTANTS, CBasicFeatures } from '@common/ui-constants';
import { colors } from '@src/css_config/colorConstants';
import { updateTransactionDetails } from '@api/api_services';
import { useHistory } from 'react-router-dom';

function SelectPlanStep() {
  const {
    formik,
    formType,
    parallelRunLimits,
    storageLimits,
    licenseData,
    licenseTypes,
    isSuperAdminOfCBasicLicense,
    showRetainButton,
    featuresData,
    setFeaturesData,
    isBackToPrevPage,
  } = useContext(LicenseWizardContext);
  let history = useHistory();
  let orderId = JSON?.parse(localStorage?.getItem('orderId'));
  let tryAgainData = JSON.parse(localStorage.getItem('try-again-data'));
  let [plans] = licenseTypes?.filter((license) => license?.type === formik?.values?.licensesType?.type);
  plans = plans?.plans;
  const [totalMonths, setTotalMonths] = useState(0);
  const [previousFeatures, setPreviousFeatures] = useState([]);
  const [newFeatures, setNewFeatures] = useState([]);
  let SelectedFeatures = [];
  let SelectedServices = [];
  let SelectedCloudServices = [];
  const [netTotal, setNetTotal] = useState(0);
  const [features, setFeatures] = useState({
    Automation: false,
    ManualTestcases: false,
    Defects: false,
    FireCloud: false,
  });
  const [services, setServices] = useState({
    WebService: false,
    Web: false,
    Mobile: false,
    Salesforce: false,
    MSDynamics: false,
  });
  const [cloudServices, setCloudServices] = useState({
    Browsers: false,
    Devices: false,
  });
  const manualTypeList = ['ManualTestcases', 'Defects'];
  const isCBasic = licenseData?.licenseType === FREE_LICENSE_TYPE;
  const isFreeLicense = formik.values.licensesType?.type === FREE_LICENSE_TYPE;
  const isUpgrade = formType === LICENSE_FORM_TYPES.upgrade.type;
  const [createCall, setCreateCall] = useState(true);
  const [defaultService, setDefaultService] = useState();
  const [defaultFeature, setDefaultFeature] = useState();
  const [isparallelRunsIncreased, setIsparallelRunsIncreased] = useState(false);
  const { Automation, ManualTestcases, Defects } = features;
  const isDisabled = [LICENSE_FORM_TYPES.renew.type, LICENSE_FORM_TYPES.upgrade.type].includes(formType);

  const [licenseType] = licenseTypes?.filter((license) => license?.type === formik?.values?.licensesType?.type);
  const isUpgradeCBasic = isUpgrade && licenseData.licenseType === 'C-Basic';
  const isDisabledBilling = [LICENSE_FORM_TYPES.upgrade.type].includes(formType);
  const formatData = (obj) => {
    if (obj && typeof obj === 'object' && !Array.isArray(obj)) {
      return Object.keys(obj).filter((key) => obj[key] === true);
    }
    return [];
  };
  const licType = formik?.values?.licensesType?.type;
  let isNoAutomationSelected = Object.values(services).every((service) => service === false);
  let isAllAutomationSelected = Object.values(services).every((service) => service === true);

  // TODO :: SET PREVIOUSLY selected data ON BACK BUTTON :: START
  useEffect(() => {
    if (orderId) {
      udateTransactionStatus();
    }
  }, []);

  let udateTransactionStatus = async () => {
    let payload = {
      status: 'Cancelled',
      orderStatus: 'Cancelled',
      failureMessage: 'Payment terminated by the user',
      state: 'inactive',
    };
    let { data } = await updateTransactionDetails(orderId?.orderId, payload);
    if (data?.responseCode === 200 && data?.responseObject === 'Cancelled') {
      history.push('/licenses/fireflink-license');
    }
  };

  let loadPreviousSelectedDataOnBack = () => {
    let prevSelectedServices = formik.values?.licenseFeatures?.filter(
      (feature) => !['ManualTestcases', 'Defects'].includes(feature)
    );
    prevSelectedServices?.forEach((feature) => {
      services[feature] = true;
    });

    let prevSelectedFeature = formik.values?.licenseFeatures?.filter((feature) =>
      ['ManualTestcases', 'Defects'].includes(feature)
    );
    prevSelectedFeature?.forEach((feature) => {
      features[feature] = true;
    });
  };

  useEffect(() => {
    if (isBackToPrevPage) {
      //NOTE :: when user Press BACK BUTTON from Payments Details Page set Previous selected data
      loadPreviousSelectedDataOnBack();
    }
  }, [isBackToPrevPage]);

  // TODO :: SET PREVIOUSLY selected data ON BACK BUTTON :: END

  useEffect(() => {
    setPreviousFeatures(licenseData?.licenseFeatures);
  }, [licenseData?.licenseFeatures]);

  useEffect(() => {
    if (formik?.values?.numberOfParallelRuns > licenseData?.numberOfParallelRuns) {
      setIsparallelRunsIncreased(true);
    }
  }, [formik?.values?.numberOfParallelRuns]);

  useEffect(() => {
    if (isUpgrade) {
      let newUpgradeFeatures = formik?.values?.licenseFeatures?.filter((feature) => {
        if (!previousFeatures?.includes(feature)) return feature;
      });
      setNewFeatures(newUpgradeFeatures);
    }
  }, [formik?.values?.licenseFeatures]);

  const licenseSelected = licenseData.licenseType?.includes('C-Professional') && licenseData.licenseFeatures;

  const handleMaxMinParallelRuns = () => {
    if (formik?.values?.numberOfParallelRuns <= parallelRunLimits?.min) {
      return parallelRunLimits?.min;
    } else if (formik?.values?.numberOfParallelRuns >= parallelRunLimits?.max) {
      return parallelRunLimits?.max;
    } else {
      return formik?.values?.numberOfParallelRuns;
    }
  };
  const handleMaxMinStorage = () => {
    if (formik?.values?.storage <= storageLimits?.min) {
      return storageLimits?.min;
    } else if (formik?.values?.storage >= storageLimits?.max) {
      return storageLimits?.max;
    } else {
      return formik?.values?.storage;
    }
  };

  useEffect(() => {
    if (['RENEW', 'UPGRADE']?.includes(formType) && !isFreeLicense) {
      if (isBackToPrevPage) {
        const defaultServiceData = licenseSelected?.filter(
          (feature) => !['ManualTestcases', 'Defects'].includes(feature)
        );
        setDefaultFeature(licenseSelected);
        setDefaultService(defaultServiceData);
      } else if (licenseSelected?.length > 0 && createCall) {
        setDefaultFeature(licenseSelected);
        setFeatures((previousData) => {
          let updatedFeatures = { ...previousData };
          licenseSelected.forEach((feature) => {
            if (manualTypeList?.includes(feature)) {
              updatedFeatures[feature] = true;
            }
          });
          return updatedFeatures;
        });

        const defaultServiceData = licenseSelected?.filter(
          (feature) => !['ManualTestcases', 'Defects'].includes(feature)
        );
        setDefaultService(defaultServiceData);

        defaultServiceData?.forEach((feature) => {
          services[feature] = true;
        });
      }
    }
    if (isNoAutomationSelected) {
      formik.setFieldValue('numberOfParallelRuns', 1); // NOTE :: if Automation not select then set  numberOfParallelRuns to 1
    }
  }, [formType, licenseData, services]);

  const isDefaultService = defaultService || '';
  const isDefaultFeatures = defaultFeature || '';

  useEffect(() => {
    if (isUpgrade && isCBasic) {
      setFeatures({
        Automation: true,
        ManualTestcases: true,
        Defects: true,
        FireCloud: false,
      });
    }
  }, [isUpgrade, isCBasic]);

  const handleFeatureSelection = (event) => {
    const { name, checked } = event.target;
    setFeatures((prevFeatures) => ({
      ...prevFeatures,
      [name]: checked,
    }));
    formatData(features);
    if (name === 'Automation') {
      if (checked) {
        setServices({
          WebService: true,
          Web: true,
          Mobile: true,
          Salesforce: true,
          MSDynamics: true,
        });
      } else {
        if (isUpgrade && !isCBasic && !checked) {
          let tempServices = { ...services };
          formik?.values?.licenseFeatures?.forEach((feature) => {
            if (!licenseData?.licenseFeatures?.includes(feature)) {
              tempServices[feature] = false;
            }
          });
          setServices(tempServices);
        } else {
          setServices({
            WebService: false,
            Web: false,
            Mobile: false,
            Salesforce: false,
            MSDynamics: false,
          });
          setCloudServices({
            Browsers: false,
            Devices: false,
          });
        }
      }
    }
    if (event.target.name === 'FireCloud') {
      if (event.target.checked) {
        setCloudServices({
          Browsers: true,
          Devices: true,
        });
      } else {
        setCloudServices({
          Browsers: false,
          Devices: false,
        });
      }
    }
    if (['RENEW', 'UPGRADE']?.includes(formType)) {
      setCreateCall(false);
    }
  };

  const handleServiceSelection = (event) => {
    const { name, checked } = event.target;

    setServices((prevServices) => ({
      ...prevServices,
      [name]: checked,
    }));

    if (['RENEW', 'UPGRADE']?.includes(formType)) {
      setCreateCall(false);
    }
  };

  const SelectedBilling = billingCycles.filter((billingCycle) => {
    return formik?.values?.billingCycle?.label === billingCycle.value && billingCycle.label;
  });
  const selectedBillingLabel = SelectedBilling.length > 0 ? SelectedBilling[0].label : '';

  useEffect(() => {
    const keys = Object.keys(services);
    const isServiceEmpty = keys.every((key) => {
      return services[key] === false;
    });
    if (isServiceEmpty) {
      setCloudServices({
        Browsers: false,
        Devices: false,
      });
      setFeatures((previousData) => {
        return { ...previousData, FireCloud: false };
      });
    }
  }, [services]);

  SelectedFeatures = formatData(features);
  SelectedServices = formatData(services);
  SelectedCloudServices = formatData(cloudServices);

  let SelectedFeaturesAndServices = [...SelectedFeatures, ...SelectedServices, ...SelectedCloudServices];

  const SelectedData = SelectedFeaturesAndServices.filter((item) => !item.includes('Automation'));
  const featureArray =
    formType === LICENSE_FORM_TYPES.upgrade.type && licenseData?.licenseType === 'C-Basic'
      ? CBasicFeatures
      : licenseData?.licenseFeatures;

  useEffect(() => {
    const selectedFeaturesToAdd = featureArray?.filter((element) => !SelectedData?.includes(element)) || [];
    if (selectedFeaturesToAdd?.length !== featuresData?.length) {
      setFeaturesData(selectedFeaturesToAdd);
    }
  }, [SelectedData, featureArray]);

  useEffect(() => {
    if (JSON.stringify(formik.values.licenseFeatures) !== JSON.stringify(SelectedFeaturesAndServices)) {
      formik.setFieldValue('licenseFeatures', SelectedFeaturesAndServices);
    }
  }, [formik.values.licenseFeatures, SelectedFeaturesAndServices]);

  function handleParallelRunsChange(event) {
    const costOfParallelRun = Number(formik.values.licensePlan?.additionalParallelRunsCost) || 0;
    let totalParallelRuns = parseInt(event.target.value);
    formik.setFieldValue('numberOfParallelRuns', totalParallelRuns);
    totalParallelRuns = Math.min(Math.max(totalParallelRuns, parallelRunLimits.min), parallelRunLimits.max);

    if (isUpgradeAndCProfessional(formType, licenseData.licenseType)) {
      const { remainingDays, numberOfParallelRuns } = licenseData;
      const perDayCost = (costOfParallelRun * totalMonths) / (totalMonths * daysInMonth);
      let totalForRemainingDays = 0;
      let totalCostForExtraMonths = 0;
      if (licenseData.licensePlan === formik.values.licensePlan?.plan) {
        totalForRemainingDays =
          (totalParallelRuns - (Number(numberOfParallelRuns) || 0)) *
          (remainingDays || totalMonths * daysInMonth) *
          perDayCost;
      } else {
        totalForRemainingDays =
          (totalParallelRuns - (Number(formik.values.licensesType?.numberOfParallelRuns) || 0)) *
          (remainingDays || totalMonths * daysInMonth) *
          perDayCost;
      }
      if (licenseData.billingCycle !== formik.values.billingCycle?.value) {
        const remainingMonths = (formik.values.billingCycle?.months || 1) - 1;
        totalCostForExtraMonths =
          (totalParallelRuns - (Number(formik.values.licensesType?.numberOfParallelRuns) || 0)) *
          remainingMonths *
          daysInMonth *
          perDayCost;
      }
      const totalParallelRunsCost = totalForRemainingDays + totalCostForExtraMonths;

      formik.setFieldValue('totalCostOfParallelRuns', totalParallelRunsCost);
    } else if (formType === LICENSE_FORM_TYPES.downgrade.type) {
      formik.setFieldValue(
        'totalCostOfParallelRuns',
        (totalParallelRuns - parallelRunLimits.min) * costOfParallelRun * totalMonths
      );
    } else {
      formik.setFieldValue(
        'totalCostOfParallelRuns',
        (totalParallelRuns - (Number(formik.values.licensesType?.numberOfParallelRuns) || 0)) *
          costOfParallelRun *
          totalMonths
      );
    }
  }

  function handleStorageChange(event) {
    let totalGBOfStorage = parseInt(event.target.value);
    formik.setFieldValue('storage', totalGBOfStorage);
  }

  function handleBillingCycleChange(event) {
    const billingCycleData = event.target.value;
    const totalMonthsOfBilling = billingCycleData?.months || 1;
    if (formType === LICENSE_FORM_TYPES.buy.type || isUpgradeCBasic) {
      const defaultBillingCycle = billingCycles.find((billingCycle) => billingCycle.value === 'Monthly');
      const selectedBillingCycle = licType?.includes('C-Professional') && defaultBillingCycle;
      formik.setFieldValue('billingCycle', billingCycleData ? billingCycleData : selectedBillingCycle);
    } else {
      formik.setFieldValue('billingCycle', billingCycleData);
    }
    setTotalMonths(totalMonthsOfBilling);
  }

  useEffect(() => {
    handleBillingCycleChange({ target: { value: formik.values.billingCycle } });
  }, [formik.values.licensesType]);

  useEffect(() => {
    handleParallelRunsChange({
      target: { value: formik.values.numberOfParallelRuns },
    });
    handleStorageChange({
      target: { value: formik.values.storage },
    });
  }, [totalMonths, formType, licenseData, formik.values.licensePlan]);

  useEffect(() => {
    handleParallelRunsChange({
      target: {
        value: Math.max(formik.values.numberOfParallelRuns, parallelRunLimits.min),
      },
    });
  }, [parallelRunLimits]);

  useEffect(() => {
    handleStorageChange({
      target: { value: Math.max(formik.values.storage, storageLimits.min) },
    });
  }, [storageLimits]);

  function getBillingCycleOptions() {
    if (licenseData.licenseType !== FREE_LICENSE_TYPE) {
      if (
        [LICENSE_FORM_TYPES.upgrade.type, LICENSE_FORM_TYPES.renewUpgrade.type].includes(formType) &&
        licenseData.billingCycle === billingCycles.at(1).value
      ) {
        return billingCycles.slice(1);
      } else if (
        [LICENSE_FORM_TYPES.downgrade.type, LICENSE_FORM_TYPES.renewDowngrade.type].includes(formType) &&
        licenseData.billingCycle === billingCycles.at(0).value
      ) {
        return billingCycles.slice(0, -1);
      } else if (formType === LICENSE_FORM_TYPES.upgrade.type) {
        return billingCycles.filter((billingCycle) => billingCycle.value === licenseData.billingCycle);
      }
    }
    return billingCycles;
  }

  function getStoragePuchaseSummary() {
    const actualValidStorage = Math.min(Math.max(formik.values.storage, storageLimits.min), storageLimits.max);
    const summary = {
      quantity: actualValidStorage - (Number(formik.values.licensesType?.storage) || 0),
      unitCost: (formik.values.licensePlan?.additionalStorageCost || 0) * totalMonths,
      totalCost: formik.values.licensesType ? formik.values.totalCostStorage : 0,
    };

    if (
      [LICENSE_FORM_TYPES.upgrade.type, LICENSE_FORM_TYPES.downgrade.type].includes(formType) &&
      formik.values.licensesType &&
      licenseData.licenseType !== FREE_LICENSE_TYPE
    ) {
      let totalQuantity = actualValidStorage;
      if (licenseData.billingCycle !== formik.values.billingCycle?.value) {
        totalQuantity -= Number(formik.values.licensesType.storage);
      } else if (licenseData.licensePlan !== formik.values.licensePlan?.plan) {
        totalQuantity -= Number(formik.values.licensesType?.storage) || 0;
      } else {
        totalQuantity -= storageLimits.min;
      }
      return {
        ...summary,
        quantity: totalQuantity,
      };
    }
    return summary;
  }

  useEffect(() => {
    if ((!isFreeLicense && formType !== 'UPGRADE') || (isUpgrade && isCBasic)) {
      let automationList = [];
      let manualList = [];

      SelectedFeaturesAndServices?.forEach((service) => {
        if (!['Automation', 'FireCloud']?.includes(service)) {
          if (manualTypeList?.includes(service)) {
            manualList.push(selectedPlansList(service, totalMonths, plans));
          } else {
            automationList.push(selectedPlansList(service, totalMonths, plans));
          }
        }
      });
      let paymentCalculationData = {
        platformCost: getPlatfromCharge(totalMonths, licenseType),
        months: totalMonths,
        numOfParallelRuns: handleMaxMinParallelRuns(),
        automationList,
        manualList,
        chosenStorage: handleMaxMinStorage(),
        defaultStorage: licenseType?.storage,
        additionalStorageCost: licenseType?.additionalStorageCost,
      };
      let netTotal = getNetTotal(paymentCalculationData);
      setNetTotal(isFreeLicense ? 0 : netTotal);
    }
  }, [SelectedFeaturesAndServices, totalMonths, formik.values.storage, formik.values.numberOfParallelRuns]);
  useEffect(() => {
    if (isFreeLicense) {
      formik.setFieldValue('totalCost', 0);
    } else {
      formik.setFieldValue('totalCost', netTotal);
    }
  }, [netTotal]);

  function getLicenseTypes() {
    if (
      formType !== LICENSE_FORM_TYPES.buy.type ||
      (isSuperAdminOfCBasicLicense && formType === LICENSE_FORM_TYPES.buy.type)
    ) {
      return licenseTypes.filter((licensesType) => licensesType.type !== FREE_LICENSE_TYPE);
    }
    return licenseTypes;
  }

  useEffect(() => {
    if (tryAgainData) {
      let prevSelectedServices = tryAgainData?.license?.licenseFeatures?.filter(
        (feature) => !manualTypeList.includes(feature)
      );
      prevSelectedServices?.map((feature) => {
        services[feature] = true;
      });

      let prevSelectedFeature = tryAgainData?.license?.licenseFeatures?.filter((feature) =>
        manualTypeList.includes(feature)
      );
      prevSelectedFeature?.map((feature) => {
        features[feature] = true;
      });
    } else if ((isFreeLicense || isUpgradeCBasic) && !isBackToPrevPage) {
      setServices({
        WebService: true,
        Web: true,
        Mobile: true,
        Salesforce: true,
        MSDynamics: true,
      });
    } else if (isUpgradeCBasic && isBackToPrevPage) {
      loadPreviousSelectedDataOnBack();
    } else if (!isBackToPrevPage) {
      setServices({
        WebService: false,
        Web: false,
        Mobile: false,
        Salesforce: false,
        MSDynamics: false,
      });
      setFeatures({
        ManualTestcases: false,
        Defects: false,
      });
    }
  }, [formik.values.licensesType?.type]);

  useEffect(() => {
    const extraParallelRuns = handleMaxMinParallelRuns() - licenseData?.numberOfParallelRuns;
    let automationList = [];
    let manualList = [];
    newFeatures?.filter((feature) => {
      if (!manualTypeList?.includes(feature)) {
        automationList?.push(selectedPlansList(feature, totalMonths, plans));
      } else {
        manualList?.push(selectedPlansList(feature, totalMonths, plans));
      }
      return null;
    });

    const netTotalObj = {
      automationObj: {
        listOfAutomation: automationList?.filter((feature) => Object?.keys(feature)?.length),
        months: totalMonths,
        remainingDays: Number(licenseData?.remainingDays == 0 ? 1 : licenseData?.remainingDays),
        parallelRuns: 1,
      },
      manualObj: {
        manualList,
        months: totalMonths,
        remainingDays: Number(licenseData?.remainingDays == 0 ? 1 : licenseData?.remainingDays),
      },
      storageObj: {
        chosenStorage: Number(handleMaxMinStorage()),
        existingStorage: Number(licenseData?.storage),
        storageCost: licenseType?.additionalStorageCost,
        remainingDays: Number(licenseData?.remainingDays == 0 ? 1 : licenseData?.remainingDays),
      },
    };
    if (!isFreeLicense && isUpgrade && !isparallelRunsIncreased && !isCBasic) {
      let netTotal = upgradeNetTotal(netTotalObj);
      setNetTotal(netTotal);
    } else if (!isFreeLicense && isUpgrade && isparallelRunsIncreased && !isCBasic) {
      let oldFeaturesCost = [];
      let newFeaturesCost = [];
      previousFeatures?.forEach((feature) => {
        oldFeaturesCost?.push(selectedPlansList(feature, totalMonths, plans));
      });
      newFeatures?.forEach((feature) => {
        newFeaturesCost?.push(selectedPlansList(feature, totalMonths, plans));
      });

      oldFeaturesCost = oldFeaturesCost?.filter((feature) => {
        if (!manualTypeList?.includes(feature?.name)) return feature;
        return null;
      });

      newFeaturesCost = newFeaturesCost?.filter((feature) => {
        if (!manualTypeList?.includes(feature?.name)) return feature;
        return null;
      });
      const oldAutomationObj = {
        listOfAutomation: oldFeaturesCost?.filter((feature) => Object?.keys(feature)?.length),
        months: totalMonths,
        remainingDays: licenseData?.remainingDays == 0 ? 1 : licenseData?.remainingDays,
        parallelRuns: extraParallelRuns,
      };
      const newAutomationObj = {
        listOfAutomation: newFeaturesCost?.filter((feature) => Object?.keys(feature)?.length),
        months: totalMonths,
        remainingDays: licenseData?.remainingDays == 0 ? 1 : licenseData?.remainingDays,
        parallelRuns: handleMaxMinParallelRuns(),
      };
      const storageObj = {
        chosenStorage: Number(handleMaxMinStorage()),
        existingStorage: Number(licenseData?.storage),
        storageCost: licenseType?.additionalStorageCost,
        remainingDays: Number(licenseData?.remainingDays == 0 ? 1 : licenseData?.remainingDays),
      };
      let oldParallelRunsCots = getUpgradeAutomationFeaturesCost(oldAutomationObj);
      let newParallelRunsCost = getUpgradeAutomationFeaturesCost(newAutomationObj);
      const platformCharges =
        (getPlatfromCharge(totalMonths, licenseType) / (daysInMonth * totalMonths)) *
        (licenseData?.remainingDays == 0 ? 1 : licenseData?.remainingDays) *
        extraParallelRuns;
      const storageCost = upgradeStorageCost(storageObj);
      let manualList = [];
      formik?.values?.licenseFeatures?.filter((feature) => {
        if (manualTypeList?.includes(feature) && newFeatures?.includes(feature)) {
          manualList?.push(selectedPlansList(feature, totalMonths, plans));
        }
        return null;
      });
      const manualObj = {
        manualList,
        months: totalMonths,
        remainingDays: Number(licenseData?.remainingDays == 0 ? 1 : licenseData?.remainingDays),
      };
      const totalManualCost = getUpgradeManualCost(manualObj);

      const netTotal = oldParallelRunsCots + newParallelRunsCost + totalManualCost + platformCharges + storageCost;
      setNetTotal(netTotal);
    }
  }, [
    newFeatures,
    totalMonths,
    isUpgrade,
    isparallelRunsIncreased,
    formik?.values?.storage,
    formik?.values?.numberOfParallelRuns,
  ]);

  useEffect(() => {
    if (tryAgainData) {
      const defaultBillingCycle = billingCycles.find(
        (billingCycle) => billingCycle.value === tryAgainData?.license?.billingCycle
      );
      formik.setFieldValue('billingCycle', defaultBillingCycle);
      formik.setFieldValue('licenseFeatures', tryAgainData?.license?.licenseFeatures);
      formik.setFieldValue('numberOfParallelRuns', Number(tryAgainData?.license?.numberOfParallelRuns));
      formik.setFieldValue('storage', tryAgainData?.license?.storage?.split(' ')?.[0]);
    }
  }, []);

  return (
    <>
      <div className={style['feature-container']}>
        <div className={cx('flex flex-col p-3 pb-0', style['license-type-billing-card'])}>
          <div className="flex items-center gap-4">
            <div className="flex-initial fontPoppinsRegularSm">
              <LabeledSelect
                fontSize="fontPoppinsRegularSm"
                disabled={isDisabled}
                label={<span className={cx('fontPoppinsRegularSm', style['license_select_label'])}>License Type</span>}
                wrapperClassName="w-60 fontPoppinsRegularSm"
                required
                error={formik.errors.licensesType && formik.touched.licensesType}
                value={formik.values.licensesType}
                defaultValue=""
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                name="licensesType"
                helperText={<>{formik.touched.licensesType && formik.errors.licensesType}</>}
                options={getLicenseTypes()}
                labelField="licenseTypeDisplay"
                placeholder="Select License Type"
                style={{
                  marginTop: '10px',
                  background: isDisabled && colors.rs_disabled_color,
                }}
              />
            </div>
            <div className="flex-initial mt-0.5">
              {formik.values.licensesType?.type !== FREE_LICENSE_TYPE && (
                <LabeledSelect
                  fontSize="fontPoppinsRegularSm"
                  label={
                    <span className={cx('fontPoppinsRegularSm', style['license_select_label'])}>Billing Cycle</span>
                  }
                  wrapperClassName="w-60 fontPoppinsRegularSm"
                  required
                  error={formik.errors.billingCycle && formik.touched.billingCycle}
                  value={formik.values.billingCycle}
                  defaultValue=""
                  onBlur={formik.handleBlur}
                  onChange={handleBillingCycleChange}
                  placeholder="Select Billing Cycle"
                  name="billingCycle"
                  helperText={<>{formik.touched.billingCycle && formik.errors.billingCycle}</>}
                  options={getBillingCycleOptions()}
                  disabled={(isUpgrade && !isCBasic) || getBillingCycleOptions().length < 2}
                  style={{
                    marginTop: '8px',
                    background: isDisabledBilling && licenseData.licenseType !== 'C-Basic' && colors.rs_disabled_color,
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className={cx(style['feature-header'], 'fontPoppinsSemiboldSm')}>Features</div>
        <div className={cx('flex flex-col p-5', formType === LICENSE_FORM_TYPES.buy.type ? style[''] : style[''])}>
          <LicenseFeatures
            showRetainButton={showRetainButton}
            onFeatureChange={handleFeatureSelection}
            isAutomation={isAllAutomationSelected}
            isManual={ManualTestcases}
            isDefects={Defects}
            onServiceChange={handleServiceSelection}
            servicesState={services}
            isIndeterminate={!isAllAutomationSelected && !isNoAutomationSelected}
            isBasicLicenseType={isFreeLicense}
            formikData={formik}
            handleParallelRunsChange={handleParallelRunsChange}
            parallelRunLimits={parallelRunLimits}
            isAllSelected={SelectedServices.length >= 5}
            isUpgradeType={isUpgrade && !isFreeLicense && isDefaultService && isDefaultFeatures}
            licenseSelected={licenseSelected}
            formType={formType}
            isUpgradeCBasic={isUpgradeCBasic}
            isNoAutomationSelected={isNoAutomationSelected}
          />
          <div className="mt-1">
            <div className="flex items-center">
              <LabeledInput
                isLicenseBuyPage={true}
                label={
                  <span
                    className={
                      isFreeLicense
                        ? cx('fontPoppinsRegularSm opacity-50 ', style['license_select_label'])
                        : cx('fontPoppinsRegularSm', style['license_select_label'])
                    }
                  >
                    Storage (GB)
                  </span>
                }
                required
                type="number"
                InputProps={{
                  inputProps: {
                    step: '1',
                    min: 0,
                    className: 'default-input shadow-none fontPoppinsRegularSm',
                  },
                }}
                placeholder="Enter storage"
                disabled={isFreeLicense}
                error={formik.errors.storage && formik.touched.storage}
                value={formik.values.storage}
                onBlur={formik.handleBlur}
                onChange={handleStorageChange}
                onKeyDown={allowWholeNumbersOnlyInNumberInputOnKeyDown}
                autoComplete="off"
                name="storage"
                helperText={
                  <div className="w-72 -ml-3">
                    {(formik.touched.storage && formik.errors.storage) ||
                      (formik.values.licensesType?.storage &&
                        `Up to ${formik.values.licensesType?.storage} GB is free`)}
                  </div>
                }
                wrapperClassName="flex-initial w-60 h-9"
                style={{ marginTop: '10px' }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap my-5 w-full">
        <div className="flex-initial w-full h-full">
          <PurchaseSummaryCard
            planDetails={{
              totalCost: getLicensePlanCost({
                licenseType: formik.values.licensesType,
                licensePlan: formik.values.licensePlan,
                billingCycle: formik.values.billingCycle,
                licenseData,
                formType,
              }),
              storage: formik.values.licensesType?.storage,
              licenseFeatures: SelectedFeatures,
              licenseType: formik.values.licensesType?.type,
              selectedBillingLabel: selectedBillingLabel,
            }}
            parallelRuns={{
              quantity: handleMaxMinParallelRuns(),
              unitCost: 0,
              totalCost: 0,
            }}
            storage={getStoragePuchaseSummary()}
            columnTitles={{
              specifications: LICENSE_CONSTANTS.SPECIFICATIONS,
              perUnitCost: LICENSE_CONSTANTS.COST_PER_QUANTITY,
              quantity: LICENSE_CONSTANTS.TOTAL_QUANTITY,
              totalCost: LICENSE_CONSTANTS.TOTAL_COST,
            }}
            costPerQuantityText={{
              storage: '(For 1GB)',
            }}
            isAutomation={Automation}
            SelectedCloudServices={SelectedCloudServices}
            SelectedServices={SelectedServices}
            licenseTypes={licenseTypes}
            months={totalMonths}
            formType={formType}
            licenseData={licenseData}
            isparallelRunsIncreased={isparallelRunsIncreased}
            previousFeatures={previousFeatures}
            newFeatures={newFeatures}
            isSuperAdminOfCBasicLicense={isSuperAdminOfCBasicLicense}
            isCBasic={isCBasic}
            netTotal={netTotal}
            licensePlanType={licenseType}
          />
        </div>
      </div>
    </>
  );
}

export default SelectPlanStep;

import React, { useEffect, useState, forwardRef } from 'react';
import NavigateToCreateInstanceModal from '@pages/configuration/system_config/system_config v1.1/modals/navigate-to-instance-modal';
import {
  getCloudPlatformBrowserVerData,
  getCloudPlatformOSData,
  getCloudPlatformBrowserAndDeviceData,
  getMobileOSVersion,
  integratedEnvironment,
} from '@src/pages/configuration/system_config/system_config v1.1/utils/cloud-platform-methods';
import {
  getBrowserLabel,
  getOsLabel,
} from '@pages/configuration/system_config/system_config v1.1/utils/sys-config-common-methods';
import { ToggleHeadless } from '@pagescommon/execution/client-machine.js';
import SelectBox from '@src/common/atoms/SelectComponent';
import InputBox from '@src/common/atoms/InputBoxComponent';
import IconWithLabelledInput from '@src/common/atoms/icon-with-labelled-input/IconWithLabelledInput';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CommonButton from '@src/common/button/Button';
import { getNumberOfRuns, maxNumOfRunsAlert, getDeviceData } from '@src/util/common_utils';
import { EXECUTION_CONSTANTS } from '@src/common/ui-constants';
import { UI_VALIDATIONS } from '@src/util/validations';
const MachineDetails = forwardRef((props, ref) => {
  const projectType = props.project.type?.toLowerCase();
  const executionType = props?.executionType || '';
  const [showBrowser] = useState(projectType === 'mobile' ? false : true);
  const [showDevice] = useState(projectType === 'web' ? false : true);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(props?.defaultSystem);
  const [navigateToInstanceModal, setNavigateToInstanceModal] = useState({ openModal: false, data: null });
  const [initialValues, setInitialValues] = useState({});
  let otherTypes = ['Web', 'Webservice', 'Database', 'Web & Mobile (Android & iOS)', 'Web & Mobile (Android or iOS)'];
  let isnotOnlyMobileType = props?.scriptTypes?.some((val) => otherTypes.includes(val));

  const getNumberOfRunsSchema = (noOfRuns) => {
    return {
      numberOfRuns: yup
        .number()
        .required('Number of Runs is required')
        .min(1, 'Number of Runs must be at least 1')
        .max(noOfRuns, `Number of Runs must not exceed ${noOfRuns}`)
        .integer('Number of Runs must be an integer'),
    };
  };

  const initialSchema = yup.object().shape({
    machineInstances: yup.array().of(
      yup.object().shape({
        executionEnvironment: yup.string().required('Execution Environment is required'),
        ...getNumberOfRunsSchema(getNumberOfRuns(executionType, projectType, !isnotOnlyMobileType)),
        ...(showBrowser && {
          osAndOsVersion: yup.string().required('Os is required'),
        }),
      })
    ),
  });
  let initValues = {
    machineInstances: [],
  };

  useEffect(() => {
    props.getUpdatedSelectedMachine(data);
  }, [data]);

  useEffect(() => {
    processData();
  }, [props.selectedSystem]);

  const getFormInitialValues = () => {
    return {
      executionEnvironment: '',
      numberOfRuns: '',
      browserName: '',
      browserVersion: '',
      osAndOsVersion: '',
      androidDeviceName: '',
      androidOsVersion: '',
      androidApp: '',
      iosDeviceName: '',
      iosOsVersion: '',
      iosApp: '',
      headless: false,
      executionEnvData: {},
      osData: {},
      browserData: {},
      browserVersionData: {},
      deviceData: {},
      androidOsVersionData: {},
      appData: {},
      iosDeviceData: {},
      iosOsVersionData: {},
      iosAppData: {},
      browserListsApiData: {},
    };
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: initialSchema,
    onSubmit: (values) => {
      handleOnSubmit(values);
    },
  });

  React.useImperativeHandle(ref, () => ({
    validateForm: async () => {
      const isValid = await formik.validateForm();
      return isValid;
    },
  }));
  const processData = async () => {
    try {
      setIsLoading(true);
      for (let i = 0; i < props.defaultSystem?.length; i++) {
        await dataConstructor(props?.defaultSystem[i], i);
      }
      setIsLoading(false);
    } catch (err) {
      console.error('PROCESS_DATA_ERROR: ', err);
      setIsLoading(false);
    }
  };
  const closeModal = (val) => {
    if (navigateToInstanceModal.openModal) {
      setNavigateToInstanceModal({ openModal: false, data: null });
      setIsLoading(false);
    }
  };

  const handleExecutionEnvChange = async (values, index) => {
    try {
      const machineInstancesData = formik.values?.machineInstances?.[index];
      if (values[0]?.value !== machineInstancesData?.executionEnvData.selectedOption[0]?.value) {
        let osNameOptions = [];
        let selectedOsName = [];
        let browserNameOptions = [];
        let selectedBrowserName = [];
        let browserVersionOptions = [];
        let selectedBrowserVersion = [];
        let devicesOptions = [];
        let selectedDevices = [];
        let androidOsVersionOptions = [];
        let selectedAndroidOsVersion = [];
        let selectedAppData = [];
        let appDataOptions = [];
        let iosDevicesOptions = [];
        let selectedIosDevices = [];
        let iosOsVersionOptions = [];
        let selectedIosOsVersion = [];
        let iosAppDataOptions = [];
        let selectedIosAppData = [];
        const selectedEnvOption = values?.[0]?.value?.toLowerCase();
        if (selectedEnvOption?.includes('local')) {
          selectedOsName = props?.selectedSystem.machine.osName
            ? `${props?.selectedSystem.machine.osName}-${props?.selectedSystem.machine.osVersion}`
            : '--';
          props?.selectedSystem?.systemConfigDetails?.forEach((_ele) => {
            if (_ele.subType === 'browser' && _ele.name) {
              browserNameOptions.push({
                label: getBrowserLabel(_ele.name),
                name: _ele.name,
                value: _ele.name,
                platform: _ele.platform,
                version: _ele.version,
                type: _ele.type,
                subType: _ele.subType,
              });
            } else if (_ele.subType === 'device') {
              const deviceDetails = {
                ..._ele,
                label: _ele.name,
                name: _ele.name,
                value: _ele.name,
              };
              if (_ele.platform === 'android') {
                devicesOptions.push(deviceDetails);
              } else {
                iosDevicesOptions.push(deviceDetails);
              }
            }
          });
          if (
            props?.selectedSystem?.clientId === props?.defaultSystem?.[index]?.clientId &&
            props?.defaultSystem?.[index]?.executionEnvironment.toLowerCase() === 'local'
          ) {
            props?.defaultSystem?.[index]?.systemConfigDetails?.forEach((_defEle) => {
              if (_defEle.subType === 'browser') {
                selectedBrowserName = [{ ...browserNameOptions.find((val) => val.name === _defEle.name) }];
                selectedBrowserVersion = selectedBrowserName[0].version;
              } else if (_defEle.subType === 'device') {
                if (_defEle.platform === 'android') {
                  selectedDevices = [{ ...devicesOptions.find((val) => val.name === _defEle.name) }];
                  selectedAndroidOsVersion = selectedDevices?.[0]?.version;
                } else {
                  selectedIosDevices = [{ ...iosDevicesOptions.find((val) => val.name === _defEle.name) }];
                  selectedIosOsVersion = selectedIosDevices?.[0]?.version;
                }
              }
            });
            const dataCopy = [...data];
            dataCopy[index] = props.defaultSystem[index];
            setData(dataCopy);
            machineInstancesData.headless = props?.defaultSystem?.[index]?.headless;
            props?.setNoCallGetUpdated(true);
          } else {
            selectedBrowserName = [{ ...browserNameOptions[0] }];
            selectedBrowserVersion = selectedBrowserName[0].version;
            machineInstancesData.headless = props?.selectedSystem?.headless;
            setUpdatedData();
          }
        } else if (integratedEnvironment(selectedEnvOption)) {
          setIsLoading(true);
          machineInstancesData.headless = false;
          let retObj;
          let instanceName = selectedEnvOption.slice(selectedEnvOption.indexOf('-') + 1);
          if (
            props.instanceList.browserInstanceArray ||
            props.instanceList.sauceLabInstanceArray ||
            props.instanceList.lambdaTestsInstanceArray ||
           selectedEnvOption?.includes("firecloud")
          ) {
            const selectedEnvOption = values[0]?.value;
            let selectedPlatformTemp = selectedEnvOption.slice(0, selectedEnvOption.indexOf('-'))?.toLowerCase();
            let selectedInstance;
            if (selectedPlatformTemp === 'browserstack') {
              selectedInstance = props.instanceList.browserInstanceArray.find(
                (instance) => instance.instanceName.toLowerCase() === instanceName
              );
            } else if (selectedPlatformTemp === 'lambdatest') {
              selectedInstance = props.instanceList.lambdaTestsInstanceArray.find(
                (instance) => instance.instanceName.toLowerCase() === instanceName
              );
            } else if (selectedPlatformTemp === 'saucelabs') {
              selectedInstance = props.instanceList.sauceLabInstanceArray.find(
                (instance) => instance.instanceName.toLowerCase() === instanceName
              );
            }else if(selectedEnvOption?.includes("firecloud")) {
              selectedPlatformTemp = selectedEnvOption;
            }
            retObj = await getCloudPlatformOSData(
              selectedInstance,
              props.selectedSystem,
              props?.defaultSystem?.[index],
              true,
              selectedEnvOption.split('-')[0],
              "execution"
            );
          } else {
            return;
          }
          const getSelectedOption = (obj) => (obj?.selectedOption ? [obj.selectedOption] : []);

          const {
            osObj,
            browserObj,
            browserVerObj,
            deviceObj,
            androidOsVersionObj,
            appDataObj,
            deviceObjIOS,
            iosOsVersionObj,
            appDataObjIos,
            apiData,
          } = retObj;

          osNameOptions = osObj?.options;
          selectedOsName = getSelectedOption(osObj);
          browserNameOptions = browserObj?.options;
          selectedBrowserName = getSelectedOption(browserObj);
          browserVersionOptions = browserVerObj?.options;
          selectedBrowserVersion = getSelectedOption(browserVerObj);
          devicesOptions = deviceObj?.options;
          selectedDevices = getSelectedOption(deviceObj);
          androidOsVersionOptions = androidOsVersionObj?.options;
          selectedAndroidOsVersion = getSelectedOption(androidOsVersionObj);
          appDataOptions = appDataObj?.options;
          selectedAppData = appDataObj?.selectedOption;
          iosDevicesOptions = deviceObjIOS?.options;
          selectedIosDevices = getSelectedOption(deviceObjIOS);
          iosOsVersionOptions = iosOsVersionObj?.options;
          selectedIosOsVersion = getSelectedOption(iosOsVersionObj);
          iosAppDataOptions = appDataObjIos?.options;
          selectedIosAppData = appDataObjIos?.selectedOption;
          machineInstancesData.browserListsApiData = apiData;
          setUpdatedData();
          setIsLoading(false);
        } else {
          setIsLoading(true);
          if (
            selectedEnvOption === 'browserstack' ||
            selectedEnvOption === 'saucelabs' ||
            selectedEnvOption === 'lambdatest'
          ) {
            setNavigateToInstanceModal({ openModal: true, data: values[0].value });
          } else {
            setIsLoading(false);
          }
        }
        handleFormikValueChange(index, 'executionEnvironment');

        const instancesFormikData = [
          { key: 'osAndOsVersion', source: selectedOsName },
          { key: 'browserName', source: selectedBrowserName },
          { key: 'browserVersion', source: selectedBrowserVersion },
          { key: 'androidDeviceName', source: selectedDevices },
          { key: 'androidOsVersion', source: selectedAndroidOsVersion },
          { key: 'androidApp', source: selectedAppData },
          { key: 'iosDeviceName', source: selectedIosDevices },
          { key: 'iosOsVersion', source: selectedIosOsVersion },
          { key: 'iosApp', source: selectedIosAppData },
        ];

        for (const property of instancesFormikData) {
          const { key, source } = property;
          machineInstancesData[key] = source?.[0]?.value || source;
        }

        if (machineInstancesData) {
          machineInstancesData.executionEnvironment = values?.[0]?.value || '';
          machineInstancesData.executionEnvData = { ...machineInstancesData.executionEnvData, selectedOption: values };
          machineInstancesData.osData = { options: osNameOptions, selectedOption: selectedOsName };
          machineInstancesData.browserData = { options: browserNameOptions, selectedOption: selectedBrowserName };
          machineInstancesData.browserVersionData = {
            options: browserVersionOptions,
            selectedOption: selectedBrowserVersion,
          };
          machineInstancesData.deviceData = { options: devicesOptions, selectedOption: selectedDevices };
          machineInstancesData.androidOsVersionData = {
            options: androidOsVersionOptions,
            selectedOption: selectedAndroidOsVersion,
          };
          machineInstancesData.appData = { options: appDataOptions, selectedOption: selectedAppData };
          machineInstancesData.iosDeviceData = { options: iosDevicesOptions, selectedOption: selectedIosDevices };
          machineInstancesData.iosOsVersionData = {
            options: iosOsVersionOptions,
            selectedOption: selectedIosOsVersion,
          };
          machineInstancesData.iosAppData = { options: iosAppDataOptions, selectedOption: selectedIosAppData };

          await formik.setValues({ ...formik.values });
        }

        function setUpdatedData() {
          let updatedData = JSON.parse(JSON.stringify(props.defaultSystem));
          updatedData[index].numberOfRuns = props?.defaultSystem?.[index]?.numberOfRuns || 1;
          updatedData[index].executionEnvironment = values[0].value;
          updatedData[index].machine.osName = Array.isArray(selectedOsName)
            ? getOsNameAndVersion(selectedOsName[0]?.value, 'name')
            : getOsNameAndVersion(selectedOsName, 'name');
          updatedData[index].machine.osVersion = Array.isArray(selectedOsName)
            ? getOsNameAndVersion(selectedOsName[0]?.value, 'version')
            : getOsNameAndVersion(selectedOsName, 'version');
          updatedData[index].systemConfigDetails = [];
          const browserTemp = {
            version: Array.isArray(selectedBrowserVersion)
              ? selectedBrowserVersion?.[0]?.value
              : selectedBrowserVersion,
            name: Array.isArray(selectedBrowserName) ? selectedBrowserName[0].value : selectedBrowserName,
            type: 'local',
            subType: 'browser',
            platform: Array.isArray(selectedBrowserName) ? selectedBrowserName[0].value : selectedBrowserName,
          };

          const androidDeviceTemp = {
            version: Array.isArray(selectedAndroidOsVersion)
              ? selectedAndroidOsVersion?.[0]?.value
              : selectedAndroidOsVersion,
            name: Array.isArray(selectedDevices) ? selectedDevices[0]?.value : selectedDevices,
            type: 'local',
            subType: 'device',
            fullName: selectedDevices?.[0]?.fullName,
            appId: selectedDevices?.[0]?.appId,
            appName: selectedDevices?.[0]?.appName,
            platform: 'android',
            serial_no: selectedDevices?.[0]?.serial_no,
            deviceUniqueId: selectedDevices?.[0]?.deviceUniqueId,
          };
          const iosDeviceTemp = {
            version: Array.isArray(selectedIosOsVersion) ? selectedIosOsVersion?.[0]?.value : selectedIosOsVersion,
            name: Array.isArray(selectedIosDevices) ? selectedIosDevices[0]?.value : selectedIosDevices,
            type: 'local',
            subType: 'device',
            fullName: selectedIosDevices?.[0]?.fullName,
            appId: selectedIosDevices?.[0]?.appId,
            appName: selectedIosDevices?.[0]?.appName,
            platform: 'ios',
            serial_no: selectedIosDevices?.[0]?.serial_no,
            deviceUniqueId: selectedIosDevices?.[0]?.deviceUniqueId,
          };
          if (browserTemp.name) {
            updatedData[index].systemConfigDetails.push(browserTemp);
          }
          if (androidDeviceTemp?.name) {
            updatedData[index].systemConfigDetails.push(androidDeviceTemp);
          }
          if (iosDeviceTemp?.name) {
            updatedData[index].systemConfigDetails.push(iosDeviceTemp);
          }
          setData(updatedData);
          props?.setNoCallGetUpdated(true);
        }
      }
    } catch (err) {
      console.error('HANDLE_EXE_ENV : ', err);
    }
  };

  const handleOsChange = async (values, index) => {
    try {
      if (values?.[0]?.value) {
        let retObj;
        let selectedExecEnv =
          formik.values?.machineInstances?.[index].executionEnvData?.selectedOption?.[0]?.value?.toLowerCase();
        if (integratedEnvironment(selectedExecEnv)) {
          retObj = await getCloudPlatformBrowserAndDeviceData(
            formik.values?.machineInstances?.[index]?.browserListsApiData,
            values[0],
            props.selectedSystem,
            props?.defaultSystem?.[index],
            true
          );
        }
        const browserNameOptions = retObj?.browserObj?.options;
        const selectedBrowserName = retObj?.browserObj?.selectedOption ? [retObj?.browserObj?.selectedOption] : [];
        const browserVersionOptions = retObj?.browserVerObj?.options;
        const selectedBrowserVersion = retObj?.browserVerObj?.selectedOption
          ? [retObj?.browserVerObj?.selectedOption]
          : [];
        const selectedOsName = getOsNameAndVersion(values[0].value, 'name');
        const selectedOsVersion = getOsNameAndVersion(values[0].value, 'version');
        let updatedData = JSON.parse(JSON.stringify(data));
        updatedData[index].machine.osName = selectedOsName;
        updatedData[index].machine.osVersion = selectedOsVersion;
        updatedData[index].systemConfigDetails = [...updatedData[index].systemConfigDetails];
        let browserFound = false;
        const browserTemp = {
          version: Array.isArray(selectedBrowserVersion) ? selectedBrowserVersion[0]?.value : selectedBrowserVersion,
          name: Array.isArray(selectedBrowserName) ? selectedBrowserName[0]?.value : selectedBrowserName,
          type: 'local',
          subType: 'browser',
          platform: Array.isArray(selectedBrowserName) ? selectedBrowserName[0]?.value : selectedBrowserName,
        };
        if (browserTemp.name) {
          updatedData[index].systemConfigDetails.forEach((configObj) => {
            if (configObj.subType === 'browser') {
              browserFound = true;
              configObj = { ...browserTemp };
            }
          });
          if (!browserFound) {
            updatedData[index].systemConfigDetails.push(browserTemp);
          }
        }
        props?.setNoCallGetUpdated(true);
        setData(updatedData);
        const machineInstancesData = formik.values?.machineInstances?.[index];
        machineInstancesData.osAndOsVersion = values[0].value;
        machineInstancesData.osData = { ...machineInstancesData.osData, selectedOption: values };
        machineInstancesData.browserData = { options: browserNameOptions, selectedOption: selectedBrowserName };
        machineInstancesData.browserVersionData = {
          options: browserVersionOptions,
          selectedOption: selectedBrowserVersion,
        };
        formik.setValues({ ...formik.values });
      } else {
        const machineInstancesData = formik.values?.machineInstances?.[index];
        machineInstancesData.osAndOsVersion = '';
        machineInstancesData.osData = { ...machineInstancesData.osData, selectedOption: [] };
        machineInstancesData.browserData = { options: [], selectedOption: [] };
        machineInstancesData.browserVersionData = { options: [], selectedOption: [] };
        formik.setValues({ ...formik.values });
      }
    } catch (err) {
      console.error('HANDLE_OS : ', err);
    }
  };

  const handleBrowserChange = (values, index) => {
    try {
      const machineInstancesData = formik.values?.machineInstances?.[index];
      let retObj;
      let browserVersionOptions = [];
      let selectedBrowserVersion;
      let selectedExecEnv =
        formik.values?.machineInstances?.[index].executionEnvData?.selectedOption?.[0]?.value?.toLowerCase();
      const { value, version } = values?.[0] || {};
      if (integratedEnvironment(selectedExecEnv)) {
        retObj = getCloudPlatformBrowserVerData(
          machineInstancesData?.browserListsApiData,
          machineInstancesData?.osData.selectedOption[0],
          values[0],
          props.selectedSystem,
          props?.defaultSystem?.[index],
          true
        );
        browserVersionOptions = retObj?.options;
        selectedBrowserVersion = retObj?.selectedOption ? [retObj?.selectedOption] : [];
      } else if (selectedExecEnv.includes('local')) {
        selectedBrowserVersion = version || '';
      }
      let updatedData = JSON.parse(JSON.stringify(data));
      updatedData[index].systemConfigDetails.forEach((_ele, i) => {
        if (_ele.subType === 'browser') {
          if (value) {
            _ele.version = Array.isArray(selectedBrowserVersion)
              ? selectedBrowserVersion?.[0]?.value
              : selectedBrowserVersion;
            _ele.name = value;
            _ele.platform = value;
          } else {
            updatedData[index].systemConfigDetails.splice(i, 1);
          }
        }
      });
      if (value?.toLowerCase()?.includes('internet')) {
        machineInstancesData.headless = false;
        updatedData[index]['headless'] = false;
      }
      setData(updatedData);
      props?.setNoCallGetUpdated(true);

      machineInstancesData.browserData = { ...machineInstancesData.browserData, selectedOption: values };
      machineInstancesData.browserVersionData = {
        options: browserVersionOptions,
        selectedOption: selectedBrowserVersion,
      };

      formik.setValues({ ...formik.values });
    } catch (err) {
      console.error('HANDLE_BROWSER : ', err);
    }
  };

  const handleBrowserVersionChange = (values, index) => {
    let updatedData = JSON.parse(JSON.stringify(data));
    updatedData[index]?.systemConfigDetails?.forEach((_ele) => {
      if (_ele.subType === 'browser') {
        _ele.version = values[0]?.value;
      }
    });
    setData(updatedData);
    props?.setNoCallGetUpdated(true);
    const machineInstancesData = formik.values?.machineInstances?.[index];

    machineInstancesData.browserVersionData = { ...machineInstancesData.browserVersionData, selectedOption: values };
    formik.setValues({ ...formik.values });
  };

  const handleMobileDeviceChange = (values, osType, index) => {
    try {
      let retObj;
      let osVersionOptions = [];
      let selectedOsVersion;
      const machineInstancesData = formik.values?.machineInstances?.[index];
      let selectedExecEnv = machineInstancesData.executionEnvData?.selectedOption?.[0]?.value?.toLowerCase();
      if (integratedEnvironment(selectedExecEnv)) {
        retObj = getMobileOSVersion(
          machineInstancesData?.browserListsApiData,
          values[0],
          props.selectedSystem,
          props?.defaultSystem?.[index],
          true,
          osType
        );
        osVersionOptions = retObj?.options;
        selectedOsVersion = retObj?.selectedOption ? [retObj?.selectedOption] : [];
      } else if (selectedExecEnv.includes('local')) {
        selectedOsVersion = values?.[0]?.version || '';
      }
      let updatedData = JSON.parse(JSON.stringify(data));
      let deviceFound = false;
      const { value: name, version, fullName, appId, appName, serial_no, deviceUniqueId } = values?.[0] || {};
      updatedData[index].systemConfigDetails.forEach((_ele, i) => {
        if (_ele.subType === 'device' && _ele?.platform === osType) {
          deviceFound = true;
          if (values.length > 0) {
            updatedData[index].systemConfigDetails[i] = {
              ..._ele,
              name,
              version,
              fullName,
              appId,
              appName,
              serial_no,
              deviceUniqueId,
            };
          } else {
            updatedData[index].systemConfigDetails.splice(i, 1);
          }
        }
      });
      if (!deviceFound && values.length > 0) {
        updatedData[index].systemConfigDetails.push({
          name,
          version,
          fullName,
          appId,
          appName,
          serial_no,
          deviceUniqueId,
          type: 'local',
          subType: 'device',
          platform: osType,
        });
      }
      setData(updatedData);
      props?.setNoCallGetUpdated(true);
      if (osType === 'android') {
        machineInstancesData.deviceData = { ...machineInstancesData.deviceData, selectedOption: values };
        machineInstancesData.androidOsVersionData = { options: osVersionOptions, selectedOption: selectedOsVersion };
      } else {
        machineInstancesData.iosDeviceData = { ...machineInstancesData.iosDeviceData, selectedOption: values };
        machineInstancesData.iosOsVersionData = { options: osVersionOptions, selectedOption: selectedOsVersion };
      }
      formik.setValues({ ...formik.values });
    } catch (err) {
      console.error('HANDLE_MOBILE_DEVICE_CHANGE : ', err);
    }
  };
  const handleMobileDeviceOSChange = (values, osType, index) => {
    try {
      let updatedData = JSON.parse(JSON.stringify(data));
      updatedData[index].systemConfigDetails.forEach((_ele) => {
        if (_ele.subType === 'device' && _ele?.platform === osType) {
          _ele.version = values[0]?.value;
        }
      });
      setData(updatedData);
      props?.setNoCallGetUpdated(true);
      const machineInstancesData = formik.values?.machineInstances?.[index];

      if (osType === 'android') {
        machineInstancesData.androidOsVersionData = {
          ...machineInstancesData.androidOsVersionData,
          selectedOption: values,
        };
      } else {
        machineInstancesData.iosOsVersionData = { ...machineInstancesData.iosOsVersionData, selectedOption: values };
      }
      formik.setValues({ ...formik.values });
    } catch (err) {
      console.error('HANDLE_MOBILE_DEVICE_OS_CHANGE : ', err);
    }
  };

  const handleDeviceAppPlgChange = (values, osType = 'android', index) => {
    let updatedData = JSON.parse(JSON.stringify(data));
    let deviceFound = false;
    updatedData[index].systemConfigDetails?.forEach((_ele) => {
      if (_ele.subType === 'device' && _ele?.platform === osType) {
        deviceFound = true;
        _ele['appId'] = values[0]?.appId;
        _ele['appName'] = values[0]?.appName;
      }
    });
    if (!deviceFound) {
      updatedData[index].systemConfigDetails.push({
        version: '',
        name: '',
        type: 'local',
        subType: 'device',
        fullName: '',
        appId: values?.[0]?.appId,
        appName: values?.[0]?.appName,
        platform: osType,
      });
    }
    setData(updatedData);
    props?.setNoCallGetUpdated(true);
    const machineInstancesData = formik.values?.machineInstances?.[index];
    if (osType === 'android') {
      machineInstancesData.appData = { ...machineInstancesData.appData, selectedOption: values };
    } else {
      machineInstancesData.iosAppData = { ...machineInstancesData.iosAppData, selectedOption: values };
    }
    formik.setValues({ ...formik.values });
  };

  const getExecutionEnvData = (instanceArray, executionEnvOptions, env) => {
    if (instanceArray?.length > 0) {
      instanceArray.forEach((instance) => {
        const envName = `${env}-${instance.instanceName}`;
        executionEnvOptions.push({ label: envName, value: envName });
      });
    } else {
      executionEnvOptions.push({ label: env, value: env });
    }
    return executionEnvOptions;
  };

  const getOsNameAndVersion = (osName, type) => {
    return type === 'name' ? osName?.split('-')[0] : osName?.split('-')[1];
  };
  const dataConstructor = async (defaultSystem, index, action, updatedDefaultSystemArray) => {
    try {
      setIsLoading(true);
      let initMachineInstances;
      if (action === 'add') {
        initMachineInstances = formik.values.machineInstances;
        initMachineInstances.push(getFormInitialValues());
      } else {
        initValues.machineInstances.push(getFormInitialValues());
        initMachineInstances = initValues.machineInstances;
      }

      let executionEnvOptions = [];
      let selectedExecutionEnv = [];
      let osNameOptions = [];
      let selectedOsName = [];
      let browserNameOptions = [];
      let selectedBrowserName = [];
      let browserVersionOptions = [];
      let selectedBrowserVersion = [];
      let devicesOptions = [];
      let selectedDevices = [];
      let androidOsVersionOptions = [];
      let selectedAndroidOsVersion = [];
      let selectedAppData = [];
      let appDataOptions = [];
      let iosDevicesOptions = [];
      let selectedIosDevices = [];
      let iosOsVersionOptions = [];
      let selectedIosOsVersion = [];
      let iosAppDataOptions = [];
      let selectedIosAppData = [];
      const browserInstanceArray = props.instanceList.browserInstanceArray;
      const sauceLabInstanceArray = props.instanceList.sauceLabInstanceArray;
      const lambdaTestsInstanceArray = props.instanceList.lambdaTestsInstanceArray;
      props?.selectedSystem?.externalPlugins?.forEach((env) => {
        if (env.toLowerCase() === 'browserstack') {
          executionEnvOptions = getExecutionEnvData(browserInstanceArray, executionEnvOptions, env);
        } else if (env.toLowerCase() === 'saucelabs') {
          executionEnvOptions = getExecutionEnvData(sauceLabInstanceArray, executionEnvOptions, env);
        } else if (env.toLowerCase() === 'lambdatest') {
          executionEnvOptions = getExecutionEnvData(lambdaTestsInstanceArray, executionEnvOptions, env);
        } else {
          executionEnvOptions.push({ label: env, value: env });
        }
      });
      if (executionEnvOptions.length) {
        if (props?.selectedSystem?.clientId === defaultSystem?.clientId) {
          selectedExecutionEnv = [
            { label: defaultSystem?.executionEnvironment, value: defaultSystem?.executionEnvironment },
          ];
          initMachineInstances[index].headless = defaultSystem?.headless || false;
        } else {
          selectedExecutionEnv = [executionEnvOptions[0]];
          initMachineInstances[index].headless = props.selectedSystem?.headless || false;
        }
      }
      if (selectedExecutionEnv[0]?.value?.toLowerCase() === 'local') {
        selectedOsName = props?.selectedSystem.machine.osName
          ? `${props?.selectedSystem.machine.osName}-${props?.selectedSystem.machine.osVersion}`
          : '--';
        props?.selectedSystem?.systemConfigDetails?.forEach((_ele) => {
          if (_ele.subType === 'browser' && _ele.name) {
            browserNameOptions.push({
              label: getBrowserLabel(_ele.name),
              name: _ele.name,
              value: _ele.name,
              platform: _ele.platform,
              version: _ele.version,
              type: _ele.type,
              subType: _ele.subType,
            });
          } else if (_ele.subType === 'device') {
            const deviceDetails = {
              ..._ele,
              label: _ele.name,
              name: _ele.name,
              value: _ele.name,
            };
            if (_ele.platform === 'android') {
              devicesOptions.push(deviceDetails);
            } else {
              iosDevicesOptions.push(deviceDetails);
            }
          }
        });
        if (props?.selectedSystem?.clientId === defaultSystem?.clientId) {
          defaultSystem?.systemConfigDetails?.forEach((_defEle) => {
            if (_defEle.subType === 'browser') {
              selectedBrowserName = [{ ...browserNameOptions.find((val) => val.name === _defEle.name) }];
              selectedBrowserVersion = selectedBrowserName[0].version;
            }
            if (_defEle.subType === 'device') {
              if (_defEle.platform === 'android') {
                selectedDevices = [{ ...devicesOptions.find((val) => val.name === _defEle.name) }];
                selectedAndroidOsVersion = selectedDevices?.[0]?.version;
              } else {
                selectedIosDevices = [{ ...iosDevicesOptions.find((val) => val.name === _defEle.name) }];
                selectedIosOsVersion = selectedIosDevices?.[0]?.version;
              }
            }
          });
        } else {
        }
        if (selectedBrowserName.length === 0) {
          selectedBrowserName = [{ ...browserNameOptions[0] }];
          selectedBrowserVersion = selectedBrowserName[0]?.version;
        }
      } else if (integratedEnvironment(selectedExecutionEnv[0].value)) {
        const selectedEnvOpt = selectedExecutionEnv[0]?.value?.toLowerCase();
        let instanceName = selectedEnvOpt.slice(selectedEnvOpt.indexOf('-') + 1);
        if (browserInstanceArray || lambdaTestsInstanceArray || sauceLabInstanceArray ||selectedEnvOpt?.includes("firecloud")) {
          const selectedEnvOption = selectedExecutionEnv[0]?.value;
          let selectedPlatformTemp = selectedEnvOption.slice(0, selectedEnvOption.indexOf('-'))?.toLowerCase();
          let selectedInstance;
          if (selectedPlatformTemp === 'browserstack') {
            selectedInstance = browserInstanceArray.find(
              (instance) => instance.instanceName.toLowerCase() === instanceName
            );
          } else if (selectedPlatformTemp === 'lambdatest') {
            selectedInstance = lambdaTestsInstanceArray.find(
              (instance) => instance.instanceName.toLowerCase() === instanceName
            );
          } else if (selectedPlatformTemp === 'saucelabs') {
            selectedInstance = sauceLabInstanceArray.find(
              (instance) => instance.instanceName.toLowerCase() === instanceName
            );
          }else if (selectedEnvOpt?.includes("firecloud")) {
            selectedPlatformTemp = "firecloud";
          }
          if (selectedInstance || selectedEnvOpt?.includes("firecloud")) {
            let retObj;
            if (props.runData) {
              retObj = await getCloudPlatformOSData(
                selectedInstance,
                props.selectedSystem,
                props.defaultSystem?.[index],
                false,
                selectedEnvOpt.split('-')[0],
                "execution"
              );
            } else {
              retObj = await getCloudPlatformOSData(
                selectedInstance,
                props.selectedSystem,
                defaultSystem,
                false,
                selectedEnvOpt.split('-')[0],
                "execution"
              );
            }
            const {
              osObj,
              browserObj,
              browserVerObj,
              deviceObj,
              androidOsVersionObj,
              appDataObj,
              deviceObjIOS,
              iosOsVersionObj,
              appDataObjIos,
              apiData,
            } = retObj ?? {};

            osNameOptions = osObj?.options ?? [];
            selectedOsName = [osObj?.selectedOption];
            browserNameOptions = browserObj?.options ?? [];
            selectedBrowserName = [browserObj?.selectedOption].filter(Boolean);
            browserVersionOptions = browserVerObj?.options ?? [];
            selectedBrowserVersion = [browserVerObj?.selectedOption].filter(Boolean);
            devicesOptions = deviceObj?.options ?? [];
            selectedDevices = [deviceObj?.selectedOption].filter(Boolean);
            androidOsVersionOptions = androidOsVersionObj?.options ?? [];
            selectedAndroidOsVersion = [androidOsVersionObj?.selectedOption].filter(Boolean);
            appDataOptions = appDataObj?.options ?? [];
            selectedAppData = [appDataObj?.selectedOption].filter(Boolean);
            iosDevicesOptions = deviceObjIOS?.options ?? [];
            selectedIosDevices = [deviceObjIOS?.selectedOption].filter(Boolean);
            iosOsVersionOptions = iosOsVersionObj?.options ?? [];
            selectedIosOsVersion = [iosOsVersionObj?.selectedOption].filter(Boolean);
            iosAppDataOptions = appDataObjIos?.options ?? [];
            selectedIosAppData = [appDataObjIos?.selectedOption].filter(Boolean);

            initMachineInstances[index].browserListsApiData = apiData;
          }
        }
      } else {
        // code for other plugins except (Local, BS, SL, LT)
      }
      let updatedData = JSON.parse(JSON.stringify(action === 'add' ? updatedDefaultSystemArray : props?.defaultSystem));
      updatedData[index].numberOfRuns =
        props?.selectedSystem?.clientId === defaultSystem?.clientId ? defaultSystem?.numberOfRuns || 1 : 1;
      updatedData[index].executionEnvironment = selectedExecutionEnv[0]?.value;
      updatedData[index].machine.osName = Array.isArray(selectedOsName)
        ? getOsNameAndVersion(selectedOsName[0]?.value, 'name')
        : getOsNameAndVersion(selectedOsName, 'name');
      updatedData[index].machine.osVersion = Array.isArray(selectedOsName)
        ? getOsNameAndVersion(selectedOsName[0]?.value, 'version')
        : getOsNameAndVersion(selectedOsName, 'version');
      updatedData[index].headless = initMachineInstances?.[index]?.headless || false;
      updatedData[index].systemConfigDetails = [];
      if (selectedBrowserName?.length) {
        const browserTemp = {
          version: Array.isArray(selectedBrowserVersion) ? selectedBrowserVersion[0]?.value : selectedBrowserVersion,
          name: Array.isArray(selectedBrowserName) ? selectedBrowserName[0]?.value : selectedBrowserName,
          type: 'local',
          subType: 'browser',
          platform: Array.isArray(selectedBrowserName) ? selectedBrowserName[0]?.value : selectedBrowserName,
        };
        updatedData?.[index]?.systemConfigDetails.push(browserTemp);
      }

      if (selectedDevices.length) {
        const androidDeviceTemp = {
          version: Array.isArray(selectedAndroidOsVersion)
            ? selectedAndroidOsVersion[0]?.value
            : selectedAndroidOsVersion,
          name: Array.isArray(selectedDevices) ? selectedDevices[0]?.value : selectedDevices,
          type: 'local',
          subType: 'device',
          fullName: selectedDevices?.[0]?.fullName,
          appId: selectedDevices?.[0]?.appId,
          appName: selectedDevices?.[0]?.appName,
          platform: 'android',
          serial_no: selectedDevices?.[0]?.serial_no,
          deviceUniqueId: selectedDevices?.[0]?.deviceUniqueId,
        };
        updatedData?.[index]?.systemConfigDetails.push(androidDeviceTemp);
      }
      if (selectedIosDevices.length) {
        const iosDeviceTemp = {
          version: Array.isArray(selectedIosOsVersion) ? selectedIosOsVersion[0]?.value : selectedIosOsVersion,
          name: Array.isArray(selectedIosDevices) ? selectedIosDevices[0]?.value : selectedIosDevices,
          type: 'local',
          subType: 'device',
          fullName: selectedIosDevices?.[0]?.fullName,
          appId: selectedIosDevices?.[0]?.appId,
          appName: selectedIosDevices?.[0]?.appName,
          platform: 'ios',
          serial_no: selectedIosDevices?.[0]?.serial_no,
          deviceUniqueId: selectedIosDevices?.[0]?.deviceUniqueId,
        };
        updatedData?.[index]?.systemConfigDetails.push(iosDeviceTemp);
      }
      setData(updatedData);
      props?.setNoCallGetUpdated(true);
      const instancesFormikData = [
        { key: 'executionEnvironment', source: selectedExecutionEnv },
        { key: 'osAndOsVersion', source: selectedOsName },
        { key: 'browserName', source: selectedBrowserName },
        { key: 'browserVersion', source: selectedBrowserVersion },
        { key: 'androidDeviceName', source: selectedDevices },
        { key: 'androidOsVersion', source: selectedAndroidOsVersion },
        { key: 'androidApp', source: selectedAppData },
        { key: 'iosDeviceName', source: selectedIosDevices },
        { key: 'iosOsVersion', source: selectedIosOsVersion },
        { key: 'iosApp', source: selectedIosAppData },
      ];

      for (const property of instancesFormikData) {
        const { key, source } = property;
        initMachineInstances[index][key] = source?.[0]?.value || source;
      }

      initMachineInstances[index].numberOfRuns =
        props?.selectedSystem?.clientId === defaultSystem?.clientId ? defaultSystem?.numberOfRuns || 1 : 1;

      const instancesOptionsData = [
        {
          key: 'executionEnvData',
          options: executionEnvOptions,
          selectedOption: selectedExecutionEnv,
        },
        {
          key: 'osData',
          options: osNameOptions,
          selectedOption: selectedOsName,
        },
        {
          key: 'browserData',
          options: browserNameOptions,
          selectedOption: selectedBrowserName,
        },
        {
          key: 'browserVersionData',
          options: browserVersionOptions,
          selectedOption: selectedBrowserVersion,
        },
        {
          key: 'deviceData',
          options: devicesOptions,
          selectedOption: selectedDevices,
        },
        {
          key: 'androidOsVersionData',
          options: androidOsVersionOptions,
          selectedOption: selectedAndroidOsVersion,
        },
        {
          key: 'appData',
          options: appDataOptions,
          selectedOption: selectedAppData,
        },
        {
          key: 'iosDeviceData',
          options: iosDevicesOptions,
          selectedOption: selectedIosDevices,
        },
        {
          key: 'iosOsVersionData',
          options: iosOsVersionOptions,
          selectedOption: selectedIosOsVersion,
        },
        {
          key: 'iosAppData',
          options: iosAppDataOptions,
          selectedOption: selectedIosAppData,
        },
      ];

      for (const property of instancesOptionsData) {
        const { key, options, selectedOption } = property;
        initMachineInstances[index][key] = { options, selectedOption };
      }
      if (action === 'add') {
        formik.setValues({ ...formik.values });
      } else {
        setInitialValues(initValues);
      }
      setIsLoading(false);
    } catch (err) {
      console.error('DATA_CONSTRUCTOR : ', err);
    }
  };

  const handleOnSubmit = async (values) => {
    console.log('values', values);
  };

  const handleHeadlessSwitch = (e, index) => {
    let updatedData = JSON.parse(JSON.stringify(data));
    const machineInstancesData = formik.values?.machineInstances?.[index];

    machineInstancesData.headless = e.target.checked;
    if (updatedData) {
      updatedData[index]['headless'] = e.target.checked;
    }
    setData(updatedData);
    formik.setValues({ ...formik.values });
    props?.setNoCallGetUpdated(true);
  };

  const handleNoOfRunsChange = (e, index) => {
    let updatedData = JSON.parse(JSON.stringify(data));
    if (updatedData) {
      updatedData[index]['numberOfRuns'] = e.target.value;
    }
    setData(updatedData);
    props?.setNoCallGetUpdated(true);
  };

  const handleFormikValueChange = (index, key, values) => {
    formik.setFieldTouched(`machineInstances[${index}][${key}]`, true);
  };

  const checkFormikError = (machineInstancesErrors, machineInstancesTouched, key) => {
    return machineInstancesErrors?.[key] && machineInstancesTouched?.[key] ? true : false;
  };

  const addMachineInstance = () => {
    if (maxNumOfRunsAlert(data, 34)) {
      const defaultSystemCopy = [...props.defaultSystem];
      defaultSystemCopy.push({ ...props.selectedSystem, numberOfRuns: 1, systemConfigDetails: [] });
      dataConstructor(
        defaultSystemCopy[defaultSystemCopy.length - 1],
        formik?.values?.machineInstances?.length,
        'add',
        defaultSystemCopy
      );
    } else {
      props.MyAlert.info(`${EXECUTION_CONSTANTS.MAXIMUM_RUNS} ${UI_VALIDATIONS.MAX_NUMBER_OF_RUNS}`);
    }
  };
  const deleteMachineInstance = (index) => {
    formik?.values?.machineInstances?.splice(index, 1);
    formik.setValues({ ...formik.values });
    const dataCopy = [...data];
    dataCopy.splice(index, 1);
    setData(dataCopy);
  };



  return (
    <div key={props?.index}>
      <div className="py-2 grid grid-cols-1 gap-4">
        {!isLoading && data ? (
          <div className="divide-y-2 divide-dashed">
            {formik?.values?.machineInstances?.map((item, index) => {
              const machineInstancesErrors =
                (formik.errors.machineInstances?.length && formik.errors.machineInstances[index]) || {};
              const machineInstancesTouched =
                (formik.touched.machineInstances?.length && formik.touched.machineInstances[index]) || {};
              const machineInstances = [...formik?.values?.machineInstances];
              const {
                executionEnvData,
                numberOfRuns,
                osData,
                browserData,
                browserVersionData,
                deviceData,
                androidOsVersionData,
                appData,
                iosDeviceData,
                iosOsVersionData,
                iosAppData,
                headless,
              } = machineInstances[index];
              
              return (
                <div key={index} className="py-2">
                  <div className={`grid grid-cols-1 gap-4`}>
                    <div className="px-4 grid grid-cols-2 gap-6">
                      <div>
                        <SelectBox
                          name={`machineInstances.${index}.executionEnvironment`}
                          labelProps={{
                            label: 'Execution Environment',
                            fontClass: 'fontPoppinsRegularSm',
                          }}
                          tooltipPosition="top"
                          disabled={props?.selectedSystem?.disabled}
                          selectedOption={executionEnvData?.selectedOption}
                          options={executionEnvData?.options}
                          handleOptionChange={(values) => {
                            handleExecutionEnvChange(values, index);
                          }}
                          searchable={true}
                          error={checkFormikError(
                            machineInstancesErrors,
                            machineInstancesTouched,
                            'executionEnvironment'
                          )}
                          errMsg={machineInstancesErrors?.executionEnvironment}
                        />
                      </div>
                      <div>
                        <InputBox
                          type="number"
                          name={`machineInstances.${index}.numberOfRuns`}
                          className="focus:outline-none"
                          labelProps={{
                            label: 'Number of Runs',
                            fontClass: 'fontPoppinsRegularSm',
                          }}
                          onInputChange={(e) => {
                            handleFormikValueChange(index, 'numberOfRuns', e.target.value);
                            formik.setFieldValue(`machineInstances[${index}][numberOfRuns]`, e.target.value);
                            handleNoOfRunsChange(e, index);
                          }}
                          value={numberOfRuns}
                          error={checkFormikError(machineInstancesErrors, machineInstancesTouched, 'numberOfRuns')}
                          errMsg={machineInstancesErrors?.numberOfRuns}
                        />
                      </div>
                    </div>
                    {showBrowser && (
                      <div>
                        <div
                          className={`px-4 system-config-web-section fontPoppinsMediumMd ${props?.selectedSystem?.disabled && 'opacity-50'}`}
                        >
                          <span>Web</span>
                          <span>
                            {!(
                              executionEnvData?.selectedOption?.length === 0 ||
                              integratedEnvironment(executionEnvData?.selectedOption?.[0]?.value)
                            ) && (
                              <ToggleHeadless
                                headlessSwitch={headless}
                                handleHeadlessSwitch={(e) => {
                                  handleHeadlessSwitch(e, index);
                                }}
                                executionEnvData={executionEnvData}
                                browserData={browserData}
                                project={props.project}
                                disabled={props.disableHeadless || props?.selectedSystem?.disabled}
                              />
                            )}
                          </span>
                        </div>
                        <div className="flex items-center px-4 py-2 gap-2">
                          <div className="flex items-center gap-3 w-full">
                            <div className="w-1/3 col-min-max-width-32-per">
                              <div className="">
                                {!Array.isArray(osData.selectedOption) ? (
                                  <IconWithLabelledInput
                                    toolTipTitle={osData?.selectedOption}
                                    placement="top"
                                    showToolTip={true}
                                    className={`${props.disabled ? 'opacity-50' : ''}`}
                                    labelProps={{ label: 'OS & OS Version' }}
                                    text={getOsLabel(osData?.selectedOption, 'inline-block')}
                                  />
                                ) : (
                                  <SelectBox
                                    name={`machineInstances.${index}.osAndOsVersion`}
                                    disabled={props?.selectedSystem?.disabled}
                                    options={osData?.options}
                                    selectedOption={osData?.selectedOption}
                                    handleOptionChange={(values) => {
                                      handleFormikValueChange(index, 'osAndOsVersion', values);
                                      handleOsChange(values, index);
                                    }}
                                    labelProps={{
                                      label: 'OS & OS Version',
                                    }}
                                    error={checkFormikError(
                                      machineInstancesErrors,
                                      machineInstancesTouched,
                                      'osAndOsVersion'
                                    )}
                                    errMsg={machineInstancesErrors?.osAndOsVersion}
                                  />
                                )}
                              </div>
                            </div>
                            <>
                              <div className="w-1/3 col-min-max-width-32-per">
                                <SelectBox
                                  name={`machineInstances.${index}.browserName`}
                                  disabled={props?.selectedSystem?.disabled}
                                  options={browserData?.options}
                                  selectedOption={browserData?.selectedOption}
                                  handleOptionChange={(values) => {
                                    handleFormikValueChange(index, 'browserName', values);
                                    handleBrowserChange(values, index);
                                  }}
                                  labelProps={{
                                    label: 'Browser',
                                  }}
                                  error={checkFormikError(
                                    machineInstancesErrors,
                                    machineInstancesTouched,
                                    'browserName'
                                  )}
                                  errMsg={machineInstancesErrors?.browserName}
                                  isBrowserDropdown={true}
                                />
                              </div>
                              <div className="w-1/3 col-min-max-width-32-per">
                                {!Array.isArray(browserVersionData.selectedOption) ? (
                                  <IconWithLabelledInput
                                    toolTipTitle={browserVersionData?.selectedOption}
                                    placement="top"
                                    showToolTip={true}
                                    className={`table-font-style-common px-1.5 py-0.5 ${props.disabled ? 'opacity-50' : ''}`}
                                    labelProps={{ label: 'Browser Version' }}
                                    text={browserVersionData.selectedOption}
                                  />
                                ) : (
                                  <SelectBox
                                    name={`machineInstances.${index}.browserVersion`}
                                    disabled={props?.selectedSystem?.disabled}
                                    options={browserVersionData?.options}
                                    selectedOption={browserVersionData?.selectedOption}
                                    handleOptionChange={(values) => {
                                      handleFormikValueChange(index, 'browserVersion', values);
                                      handleBrowserVersionChange(values, index);
                                    }}
                                    labelProps={{
                                      label: 'Browser Version',
                                    }}
                                    error={checkFormikError(
                                      machineInstancesErrors,
                                      machineInstancesTouched,
                                      'browserVersion'
                                    )}
                                    errMsg={machineInstancesErrors?.browserVersion}
                                  />
                                )}
                              </div>
                            </>
                          </div>
                        </div>
                      </div>
                    )}
                    {showDevice && (
                      <div>
                        <div className="system-config-web-section px-4">
                          <span className="fontPoppinsMediumMd">Mobile</span>
                        </div>
                        <div className="flex items-center px-4 py-2 gap-2">
                          <div className="flex items-center gap-3 w-full">
                            <div className="w-1/3 col-min-max-width-32-per">
                              <SelectBox
                                name={`machineInstances.${index}.androidDeviceName`}
                                disabled={props?.selectedSystem?.disabled}
                                options={deviceData?.options}
                                selectedOption={getDeviceData(deviceData?.selectedOption)}
                                handleOptionChange={(values) => {
                                  handleFormikValueChange(index, 'androidDeviceName', values);
                                  handleMobileDeviceChange(values, 'android', index);
                                }}
                                labelProps={{
                                  label: 'Android Device',
                                }}
                                error={checkFormikError(
                                  machineInstancesErrors,
                                  machineInstancesTouched,
                                  'androidDeviceName'
                                )}
                                errMsg={machineInstancesErrors?.androidDeviceName}
                              />
                            </div>
                            <div className="w-1/3 col-min-max-width-32-per">
                              {integratedEnvironment(
                                executionEnvData?.selectedOption && executionEnvData?.selectedOption[0]?.value
                              ) ? (
                                <SelectBox
                                  name={`machineInstances.${index}.androidOsVersion`}
                                  disabled={props?.selectedSystem?.disabled}
                                  options={androidOsVersionData?.options}
                                  selectedOption={androidOsVersionData?.selectedOption}
                                  handleOptionChange={(values) => {
                                    handleFormikValueChange(index, 'androidOsVersion', values);
                                    handleMobileDeviceOSChange(values, 'android', index);
                                  }}
                                  labelProps={{
                                    label: 'OS Version',
                                  }}
                                  error={checkFormikError(
                                    machineInstancesErrors,
                                    machineInstancesTouched,
                                    'androidOsVersion'
                                  )}
                                  errMsg={machineInstancesErrors?.androidOsVersion}
                                />
                              ) : (
                                <IconWithLabelledInput
                                  toolTipTitle={androidOsVersionData?.selectedOption}
                                  placement="top"
                                  showToolTip={true}
                                  className={`table-font-style-common px-1.5 py-0.5 ${props.disabled ? 'opacity-50' : ''}`}
                                  labelProps={{ label: 'OS Version' }}
                                  text={androidOsVersionData?.selectedOption}
                                />
                              )}
                            </div>
                            {integratedEnvironment(
                              executionEnvData?.selectedOption && executionEnvData?.selectedOption[0]?.value
                            ) && (
                              <div className="w-1/3 col-min-max-width-32-per">
                                <SelectBox
                                  name={`machineInstances.${index}.androidApp`}
                                  disabled={props?.selectedSystem?.disabled}
                                  options={appData?.options}
                                  selectedOption={appData?.selectedOption}
                                  handleOptionChange={(values) => {
                                    handleFormikValueChange(index, 'androidApp', values);
                                    handleDeviceAppPlgChange(values, 'android', index);
                                  }}
                                  toolTipKey="label"
                                  labelProps={{
                                    label: 'App',
                                  }}
                                  error={checkFormikError(
                                    machineInstancesErrors,
                                    machineInstancesTouched,
                                    'androidApp'
                                  )}
                                  errMsg={machineInstancesErrors?.androidApp}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="flex items-center px-4 py-2 gap-2">
                          <div className="flex items-center gap-3 w-full">
                            <div className="w-1/3 col-min-max-width-32-per">
                              <SelectBox
                                name={`machineInstances.${index}.iosDeviceName`}
                                disabled={props?.selectedSystem?.disabled}
                                options={iosDeviceData?.options}
                                selectedOption={getDeviceData(iosDeviceData?.selectedOption)}
                                handleOptionChange={(values) => {
                                  handleFormikValueChange(index, 'iosDeviceName', values);
                                  handleMobileDeviceChange(values, 'ios', index);
                                }}
                                labelProps={{
                                  label: 'iOS Device',
                                }}
                                error={checkFormikError(
                                  machineInstancesErrors,
                                  machineInstancesTouched,
                                  'iosDeviceName'
                                )}
                                errMsg={machineInstancesErrors?.iosDeviceName}
                              />
                            </div>
                            <div className="w-1/3 col-min-max-width-32-per">
                              {integratedEnvironment(
                                executionEnvData?.selectedOption && executionEnvData?.selectedOption[0]?.value
                              ) ? (
                                <SelectBox
                                  name={`machineInstances.${index}.iosOsVersion`}
                                  disabled={props?.selectedSystem?.disabled}
                                  options={iosOsVersionData?.options}
                                  selectedOption={iosOsVersionData?.selectedOption}
                                  handleOptionChange={(values) => {
                                    handleFormikValueChange(index, 'iosOsVersion', values);
                                    handleMobileDeviceOSChange(values, 'ios', index);
                                  }}
                                  labelProps={{
                                    label: 'OS Version',
                                  }}
                                  error={checkFormikError(
                                    machineInstancesErrors,
                                    machineInstancesTouched,
                                    'iosOsVersion'
                                  )}
                                  errMsg={machineInstancesErrors?.iosOsVersion}
                                />
                              ) : (
                                <IconWithLabelledInput
                                  toolTipTitle={iosOsVersionData?.selectedOption}
                                  placement="top"
                                  showToolTip={true}
                                  className={`table-font-style-common px-1.5 py-0.5 ${props.disabled ? 'opacity-50' : ''}`}
                                  labelProps={{ label: 'OS Version' }}
                                  text={iosOsVersionData?.selectedOption}
                                />
                              )}
                            </div>
                            {integratedEnvironment(
                              executionEnvData?.selectedOption && executionEnvData?.selectedOption[0]?.value
                            ) && (
                              <div className="w-1/3 col-min-max-width-32-per">
                                <SelectBox
                                  name={`machineInstances.${index}.iosApp`}
                                  disabled={props?.selectedSystem?.disabled}
                                  options={iosAppData?.options}
                                  selectedOption={iosAppData?.selectedOption}
                                  handleOptionChange={(values) => {
                                    handleFormikValueChange(index, 'iosApp', values);
                                    handleDeviceAppPlgChange(values, 'ios', index);
                                  }}
                                  labelProps={{
                                    label: 'App',
                                  }}
                                  toolTipKey="label"
                                  error={checkFormikError(machineInstancesErrors, machineInstancesTouched, 'iosApp')}
                                  errMsg={machineInstancesErrors?.iosApp}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {executionType !== 'manual distribution' && (
                    <div className="flex gap-2 justify-end mr-4">
                      {formik?.values?.machineInstances?.length > 1 && (
                        <CommonButton
                          label="Delete"
                          btnType="destructive"
                          onClick={() => {
                            deleteMachineInstance(index);
                          }}
                        />
                      )}
                      {index === formik?.values?.machineInstances?.length - 1 && (
                        <CommonButton
                          label="Add"
                          btnType="primary"
                          onClick={() => {
                            addMachineInstance();
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <div className="allign-div-center qrs-details-div-data-height"></div>
        )}

        {navigateToInstanceModal?.openModal && (
          <NavigateToCreateInstanceModal closeModal={closeModal} data={navigateToInstanceModal.data} />
        )}
      </div>
    </div>
  );
});

export default MachineDetails;

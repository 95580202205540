import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Tooltip } from '@material-ui/core';
import { AddOutlined, DragIndicatorOutlined } from '@material-ui/icons';
import React, { useEffect, useState, useRef, useContext } from 'react';
import {
  deletePrePostConditionReq,
  getNLPReq,
  getScriptPrePostConditionsReq,
  getScriptReq,
  updatePrePostConditionReq,
  getNewNlpReq,
} from '@api/api_services';
import DeleteModal from '../../../../shared/delete-modal';
import CreateEditScriptConditions from './create-edit-script-conditions';
import TableWithDragAndDrop from '../../../../shared/table/conditions-table';
import { CommonLoader } from '@src/pages/common/common-loader';
import Conditions from '@pages/test-development/script/scripts/settings/pre-post-conditions/condition.module.scss';
import cx from 'classnames';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { getTruncatedText, isEmptyValue } from '@src/util/common_utils';
import { UI_VALIDATIONS } from '@src/util/validations';
import { NLP_SUGGESTION_CONDITIONS } from '@src/common/ui-constants';

const StepgroupSuggestion = (props) => {
  let [stepGroupDropDownData, setStepGroupDropDownData] = useState([]);
  let [filterData, setFilterData] = useState(stepGroupDropDownData);
  let [stepGroupName, setStepGroupName] = useState(props.editData ? props.editData : '');
  let [nlpSelected, setNlpSelected] = useState(false);
  let [selectedNLPData, setSelectedNLPData] = useState({});
  const project = JSON.parse(localStorage.getItem('selected-project'));
  const handleChange = (e) => {
    setStepGroupName(e.target.value);
    // search(e.target.value); //!old code
  };
  const handleClick = (data) => {
    setStepGroupName(data.name);
    props.openStepgroupInputDiv(data);
  };
  //!old code
  // let search = (data) => {
  //   let x = [];
  //   x = stepGroupDropDownData.filter((element) => element.nlpName.toLowerCase().includes(data.toLowerCase()));
  //   setFilterData(x);
  // };
  const getStepGroups = async () => {
    try {
      let x = [];
      const search = window.location.search;
      const moduleId = new URLSearchParams(search).get('moduleId');
      const scriptId = new URLSearchParams(search).get('scriptId');
      const scriptResponse = await getScriptReq(moduleId, scriptId);
      const platform = scriptResponse.data.responseObject.scriptType;
      const payload = {
        module: 'nlpSearch',
        collectionName: 'nlps',
        searchText: stepGroupName,
      };
      const headers = {
        projectType: project.type,
        projectId: project.id,
        platform: platform,
      };
      if (scriptResponse.data.responseObject && scriptResponse.data.responseObject.scriptType) {
        const response = await getNewNlpReq(payload, headers, NLP_SUGGESTION_CONDITIONS.CONDITIONS);
        // const response = await getNLPReq('', scriptResponse.data.responseObject.scriptType); //!old code
        if (response.data && response.data.responseObject) {
          //!old code
          // x = response.data.responseObject.filter((ele, index) => ele.nlpType === 'STEP_GROUP');
          // setStepGroupDropDownData(x);
          // setFilterData(x);
          setFilterData(response.data.responseObject);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  //!old code
  // useEffect(() => {
  //   getStepGroups();
  // }, []);

  useEffect(() => {
    const data = setTimeout(() => {
      getStepGroups();
    }, 500);
    return () => clearTimeout(data);
  }, [stepGroupName]);

  const handleEscapeClose = (event) => {
    if (event?.key === 'Escape') {
      if (props.closeBox && !nlpSelected) {
        props.closeBox(false);
        props.closeModal(true);
      }
    }
  };

  const onHoverSelectedNLP = (ele) => {
    setSelectedNLPData(ele);
  };

  return (
    <div className={`relative ml-5 text-sm text-blue-700 `}>
      <div className={`flex`}>
        <input
          autoFocus={true}
          type="search"
          id="textareaTd"
          className={`w-11/12 search-nlp text-sm text-blue-700 ${props.drpPos ? '-ml-7' : 'ml-7'}`}
          value={stepGroupName}
          onChange={handleChange}
          autoComplete="off"
          onBlur={() => {
            if (props.closeBox && !nlpSelected) {
              props.closeBox(false);
              props.closeModal(true);
            }
          }}
          onKeyDown={handleEscapeClose}
          placeholder="Search for stepgroup"
        />
      </div>
      <div className="flex nlpdataloading absolute w-full">
        <div
          style={props.drpPos ? { zIndex: '1', width: '93%' } : null}
          className={`${
            props.drpPos ? '' : 'w-11/12'
          }border mt-px p-px max-h-48 w-full overflow-y-auto overflow-hidden shadow rounded bg-white ${
            props.drpPos ? '-ml-8' : 'ml-7'
          }`}
          id="journal-scroll"
          onMouseEnter={() => {
            setNlpSelected(true);
          }}
          onMouseLeave={() => {
            onHoverSelectedNLP(null);
          }}
        >
          {filterData.map((ele, index) => {
            return (
              <div
                onMouseOver={() => onHoverSelectedNLP(ele)}
                onClick={() => {
                  handleClick(ele);
                }}
                id={'sg' + index.toString()}
                key={index}
                className="fontPoppinsRegularMd cursor-pointer flex item-start ml-3 mb-1 text-gray-500 items-center"
              >
                <span className="ml-5 mr-5 mt-3 sg fontPoppinsRegularMd nlp-type-step-group">SG</span>
                <p className="mt-3 break-all fontPoppinsRegularMd">
                  {getTruncatedText(ele?.nlpName, UI_VALIDATIONS.MAX_NLP_NAME_CHAR_COUNT)}
                </p>
              </div>
            );
          })}
          <div
            className="flex w-full"
            onMouseEnter={() => {
              setNlpSelected(true);
            }}
            onMouseLeave={() => {
              onHoverSelectedNLP(null);
            }}
          >
            {!isEmptyValue(filterData) && selectedNLPData?._id && (
              <div
                className={`absolute h-56 left-full top-0 bottom-8 mr-10 w-full ${
                  props.drpPos ? '-ml-14' : 'ml-2'
                }  border p-2 max-h-48 overflow-y-auto  shadow rounded bg-white z-20`}
                onMouseEnter={() => setNlpSelected(true)}
                onMouseLeave={() => setNlpSelected(false)}
              >
                <div className="nlp-details-header mt-4">Name:</div>
                <div className="nlp-details nlpdiv-check">
                  {selectedNLPData?.displayName ? selectedNLPData?.displayName : '--'}
                </div>
                <div className="nlp-details-header mt-4">Type:</div>
                <div className="nlp-details nlpdiv-check">
                  {selectedNLPData?.displayName ? selectedNLPData?.platform : '--'}
                </div>
                <div className="nlp-details-header mt-4">Description:</div>
                <div className="nlp-details nlpdiv-check">
                  {selectedNLPData?.description ? selectedNLPData?.description : '--'}
                </div>
                {selectedNLPData?.nlpType === 'STEP_GROUP' && (
                  <>
                    <div className="nlp-details-header mt-4">Path:</div>
                    <div className="nlp-details nlpdiv-check">
                      {selectedNLPData?.path ? selectedNLPData?.path?.slice(6) : '--'}
                    </div>
                  </>
                )}
                <div className="nlp-details-header mt-4">Inputs:</div>
                {isEmptyValue(selectedNLPData?.stepInputs) ? (
                  selectedNLPData?.stepInputs.map((stepInput, index) => {
                    const stepInputType = stepInput?.type ? stepInput?.type?.split('.').pop() : '--';
                    return (
                      <div key={index}>
                        <div className="nlp-details-header">Input{index + 1}:</div>
                        <div className="nlp-details">type: {stepInputType}</div>
                        <div className="nlp-details mb-4">name: {stepInput?.name}</div>
                      </div>
                    );
                  })
                ) : (
                  <div className="nlp-details mb-4">--</div>
                )}
                <div className="nlp-details-header">Output:</div>
                <div className="nlp-details mb-4">
                  {selectedNLPData?.returnType ? selectedNLPData?.returnType : '--'}
                </div>
                <div className="w-1/6"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const PreCondition = (props) => {
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  const { resetProgress, startOrResumeProgress } = useContext(ProgressContainerContext);
  const [initialLoad, setInitialLoad] = useState(true);
  let [reload, setReload] = useState(true);
  let [isLoading, setIsLoading] = useState(false);
  let [data, setData] = useState([]);
  let [noPreConditions, setNoPreConditions] = useState(data.length > 0 ? false : true);
  let [openStepGroupInputParameterDiv, setOpenStepGroupInputParameterDiv] = useState(false);
  let [stepGroupInputParameterDivData, setStepGroupInputParameterDivData] = useState('');
  let [editPreCondition, setEditPreCondition] = useState(false);
  let [openDeleteModal, setOpenDeleteModal] = useState(false);
  let [stepIndex, setStepIndex] = useState(1);
  let [lastExecOrder, setLastExecOrder] = useState(1);
  let [editData, setEditData] = useState(null);
  let [openCreatePreCondition, setOpenCreatePreCondition] = useState(false);
  const [lastCascadedConditionIndex, setLastCascadedConditionIndex] = useState(0);
  const [showAddStepButton, setshowAddStepButton] = useState(true);
  const [addStepAtIndex, setAddStepAtIndex] = useState();
  const [midExecutionOrder, setMidExecutionOrder] = useState();
  const defaultStepGroup = new URLSearchParams(search).get('defaultStepGroup');

  const { isEditable, hasFullAccess } = React.useMemo(
    () => ({
      isEditable: window.permission?.isEditAccess('testDevelopment'),
      hasFullAccess: window.permission?.isFullAccess('testDevelopment'),
    }),
    []
  );

  const OpenStepGroupDiv = (data) => {
    props.DisableCreateButton(true);
    setOpenStepGroupInputParameterDiv(true);
    setStepGroupInputParameterDivData(data);
  };
  const closeBox = (val) => {
    setOpenCreatePreCondition(val);
    myCssMethod(-1);
    setEditData(null);
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    if (result.destination.index > lastCascadedConditionIndex) {
      const items = Array.from(data);
      let execOrder;
      if (items[result.destination.index + 1] === undefined) {
        execOrder = items[result.destination.index].executionOrder + 1;
      } else if (items[result.destination.index - 1] === undefined) {
        execOrder = items[result.destination.index].executionOrder / 2;
      } else {
        if (result.destination.index > result.source.index) {
          execOrder =
            (items[result.destination.index].executionOrder + items[result.destination.index + 1].executionOrder) / 2;
        } else {
          execOrder =
            (items[result.destination.index].executionOrder + items[result.destination.index - 1].executionOrder) / 2;
        }
      }
      const reorderedItem = items[result.source.index];
      const requestBody = {
        ...reorderedItem,
        id: reorderedItem?._id ? reorderedItem._id : reorderedItem.id,
        executionOrder: execOrder,
      };
      if (result.source.index !== result.destination.index) {
        const [x] = items.splice(result.source.index, 1);
        x.executionOrder = execOrder;
        items.splice(result.destination.index, 0, x);
        setData(items);
        updatePrePostConditionReq(moduleId, scriptId, requestBody).then((response) => {
          if (!(response.data && response.data.responseObject)) {
            props.MyAlert.info(`Last reordering failed`);
          }
        });
      }
    } else {
      props.MyAlert.info(`Condition can't be draged above cascaded conditions`);
    }
  }
  const deletePreCondition = async (data) => {
    try {
      const id = data._id ? data._id : data.id;
      const type = data?.type;
      const response = await deletePrePostConditionReq(moduleId, scriptId, id, type);
      if (response.data.responseCode === 200 && response.data.status === 'SUCCESS') {
        reloadPreConditions(true);
        let title = `${data.stepGroupName?.substring(0, 50)}${data?.stepGroupName?.length > 50 ? '...' : ''}${' '}`;
        props.MyAlert.success(`${title} deleted successfully`);
      } else {
        props.MyAlert.warning(`${response.data.message}`);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleEditStep = (index, val) => {
    setStepIndex(index + 1);
    setEditPreCondition(true);
    setEditData(val);
    props.DisableCreateButton(true);
  };
  const myCssMethod = (index) => {
    const myClass = document.getElementsByClassName('myRow');
    for (let i = 0; i < myClass.length; i++) {
      if (index >= 0) {
        myClass[i].classList.remove('configHover');
      } else {
        myClass[i].classList.add('configHover');
      }
      if (i === index) {
        myClass[i].classList.add('shadow-md', 'bg-white');
        myClass[i].style.boxShadow = '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)';
      } else {
        myClass[i].classList.remove('shadow-md', 'bg-white');
        myClass[i].style.boxShadow = 'none';
      }
    }
  };

  const reloadPreConditions = (val) => {
    setReload(val);
  };

  const closeModal = (value) => {
    myCssMethod(-1);
    if (openDeleteModal) {
      setOpenDeleteModal(value);
      setStepGroupInputParameterDivData('');
    }
    if (openStepGroupInputParameterDiv) {
      setOpenStepGroupInputParameterDiv(false);
      setStepGroupInputParameterDivData('');
      setOpenCreatePreCondition(false);
    }
    if (editPreCondition) {
      setEditPreCondition(false);
    }
    setStepIndex(data.length + 1);
    if (data.length === 0) {
      setNoPreConditions(true);
    }
    setEditData(null);
    myCssMethod(-1);
    props.DisableCreateButton(false);
  };

  const handleNameClick = (row, index, addStepAtIndex) => {
    if (isEditable) {
      if (addStepAtIndex) {
        removeEmptyStep(null, addStepAtIndex);
        if (index < addStepAtIndex) {
          setStepIndex(index + 1);
          myCssMethod(index);
        } else {
          setStepIndex(index);
          myCssMethod(index - 1);
        }
      } else {
        setStepIndex(index + 1);
        myCssMethod(index);
      }
      OpenStepGroupDiv(row);
      setEditPreCondition(true);
    }
  };

  const getNameRenderer = (row, index) => {
    return stepIndex - 1 === index && editPreCondition && editData !== null ? (
      openStepGroupInputParameterDiv ? (
        <span className=" pt-2 w-full fontPoppinsRegularMd text-blue-700 ">
          {index + 1}. {stepGroupInputParameterDivData.nlpName}
        </span>
      ) : (
        <div className="inline-block w-full">
          {myCssMethod(index)}
          <StepgroupSuggestion
            editData={row.stepGroupName}
            // handleBlur={handleBlur}
            drpPos={true}
            openStepgroupInputDiv={OpenStepGroupDiv}
            closeBox={closeBox}
            closeModal={closeModal}
          />
        </div>
      )
    ) : (
      <div className="flex flex-1 items-center">
        <Tooltip title={row.stepGroupName} placement="top">
          <span
            className={cx('text-blue-700 mr-1 cursor-pointer', Conditions['length-script'])}
            onClick={() => handleNameClick(row, index, addStepAtIndex)}
          >
            {index + 1}. {`${row.stepGroupName?.substring(0, 30)}${row.stepGroupName?.length > 30 ? '...' : ''}`}{' '}
            {row.cascaded === 'cascaded' ? ` - Cascaded from ${row.moduleName}` : null}
          </span>
        </Tooltip>
        {isEditable && row.cascaded !== 'cascaded' && showAddStepButton && (
          <button
            disabled={openStepGroupInputParameterDiv}
            className={openStepGroupInputParameterDiv ? 'HideAddStepBelow' : ' ShowAddStepBelow actionIcons'}
            onClick={(ev) => {
              if (defaultStepGroup) {
                if (defaultStepGroup === 'false') {
                  addStepBelow(ev, row, index);
                }
              } else {
                addStepBelow(ev, row, index);
              }
            }}
          >
            {!openCreatePreCondition && (
              <Tooltip title="Add" placement="top">
                <AddOutlined fontSize="small" className="cursor-pointer text-xs ml-1 text-blue-700 actionIcons" />
              </Tooltip>
            )}
          </button>
        )}
      </div>
    );
  };
  const getActionIcons = (row, index) => {
    return (
      <span className="actionIcons">
        {isEditable && (
          <button
            className="float-left mr-3 mt-1.5"
            disabled={row.cascaded === 'cascaded'}
            onClick={() => {
              handleEditStep(index, row);
            }}
          >
            <Tooltip title={row.cascaded === 'cascaded' ? 'Cannot edit cascaded pre condition' : 'Edit'}>
              <EditOutlinedIcon
                className={`${row.cascaded === 'cascaded' ? 'opacity-50' : ''} cursor-pointer text-base text-blue-700`}
              />
            </Tooltip>
          </button>
        )}
        {hasFullAccess && (
          <Tooltip title="Delete">
            <DeleteOutlinedIcon
              className="float-left cursor-pointer mr-3 mt-1.5 text-blue-700 text-base"
              onClick={() => {
                setOpenDeleteModal(true);
                setStepGroupInputParameterDivData(row);
              }}
            />
          </Tooltip>
        )}
      </span>
    );
  };
  const getPreConditions = async () => {
    try {
      setIsLoading(true);
      const res = await getScriptPrePostConditionsReq(moduleId, scriptId);
      if (res.data && res.data.responseObject && res.data.responseObject.length > 0) {
        const filteredConditions = res.data.responseObject.filter((val, index) => val.type === 'PRE');
        setLastCascadedConditionIndex(
          filteredConditions.filter((condition) => condition.cascaded === 'cascaded').length - 1
        );
        // props.setCountPill({ type: 'PreCondition', value: x.length })
        setStepIndex(filteredConditions.length + 1);
        setData(filteredConditions);
      } else {
        setData([]);
        setStepIndex(1);
        setNoPreConditions(true);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      setAddStepAtIndex();
      setMidExecutionOrder();
      setshowAddStepButton(true);
    }
  };
  const addInBetweenConditionRenderer = () => {
    return (
      <div className="mt-2">
        {openStepGroupInputParameterDiv ? (
          <p
            style={{
              boxShadow: 'none',
            }}
            className="pt-2 text-left w-full text-xs text-blue-700 shadow-md bg-white h-8 pl-8 -ml-5"
            title={stepGroupInputParameterDivData?.name}
          >
            {stepGroupInputParameterDivData.nlpName}
          </p>
        ) : (
          <div className="">
            <StepgroupSuggestion
              drpPos={true}
              openStepgroupInputDiv={OpenStepGroupDiv}
              closeBox={closeBox}
              closeModal={closeModal}
            />
          </div>
        )}
      </div>
    );
  };
  function ifFailedText(status) {
    switch (status) {
      case 'MARK_THIS_STEP_AS_FAILED_AND_CONTINUE_SCRIPT_EXECUTION':
        return 'Mark this step as Failed and continue script execution';
      case 'MARK_THIS_STEP_AS_WARNING_AND_CONTINUE_SCRIPT_EXECUTION':
        return 'Mark this step as Warning and continue script execution';
      case 'MARK_THIS_STEP_AS_FAILED_AND_STOP_CURRENT_ITERATION':
        return 'Mark this step as Failed and stop current iteration';
      case 'MARK_THIS_STEP_AS_FAILED_AND_STOP_ALL_ITERATION':
        return 'Mark this step as Failed and stop all iteration';
      case 'MARK_THIS_STEP_AS_FAILED_AND_STOP_SCRIPT_EXECUTION':
        return 'Mark this step as Failed and stop script execution';
      case 'MARK_THIS_STEP_AS_FAILED_AND_STOP_CURRENT_MODULE_EXECUTION':
        return 'Mark this step as Failed and stop current module execution';
      case 'MARK_THIS_STEP_AS_FAILED_AND_STOP_COMPLETE_EXECUTION':
        return 'Mark this step as Failed and stop complete execution';
      case 'MARK_THIS_STEP_AS_FAILED_AND_CONTINUE_MODULE_EXECUTION':
        return 'Mark this step as Failed and continue module execution';
      case 'MARK_THIS_STEP_AS_WARNING_AND_CONTINUE_MODULE_EXECUTION':
        return 'Mark this step as Warning and continue module execution';
      default:
        return '--';
    }
  }

  function getIfFailed(status) {
    let ifFailedMessage = ifFailedText(status);
    return (
      <span
        className={cx('text-gray-700 fontPoppinsRegularMd', Conditions['if-failed-length-pre-post'])}
        title={`${ifFailedMessage}`}
      >
        {`${ifFailedMessage?.substring(0, 40)}${'...'}`}
      </span>
    );
  }

  const tableData = React.useMemo(() => {
    if (data.length) {
      let tabData = [];
      data.forEach((row, index) => {
        if (row.type !== 'stepGroup') {
          tabData.push({
            ...(isEditable &&
              data.length > 1 && {
                dragIcon: row.cascaded !== 'cascaded' && (
                  <span className="actionIcons">
                    <DragIndicatorOutlined fontSize="small" className="text-blue-600" />{' '}
                  </span>
                ),
              }),
            name: getNameRenderer(row, index),
            ifFailed: getIfFailed(row.ifCheckPointIsFailed),
            status: (
              <span style={{ textTransform: 'capitalize' }} className="text-gray-700 fontPoppinsRegularMd">
                {row.status}
              </span>
            ),
            actions: getActionIcons(row, index),
            rowValue: { value: row, index: index },
          });
        } else {
          tabData.push({
            name: addInBetweenConditionRenderer(),
            rowValue: { value: row, index: index },
          });
        }
      });
      return tabData;
    } else {
      return [];
    }
  }, [data, addInBetweenConditionRenderer, getNameRenderer, getActionIcons]);

  const columns = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'dragIcon',
        width: '1%',
      },
      {
        Header: 'StepGroup',
        accessor: 'name',
        width: '40%',
      },
      {
        Header: 'If Failed',
        accessor: 'ifFailed',
        width: '39%',
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: '5%',
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        width: '10%',
      },
    ],
    []
  );
  let _DOMNode = useClickOutSide((event) => {
    if (addStepAtIndex && !openStepGroupInputParameterDiv) {
      removeEmptyStep(event, addStepAtIndex);
    }
  });

  const findExecutionOrder = (index, data) => {
    if (index === data.length - 1) {
      return data[index].executionOrder + 1;
    } else {
      return (data[index].executionOrder + data[index + 1].executionOrder) / 2;
    }
  };
  const addStepBelow = (event, row, index) => {
    let _currentData = [];
    Object.assign(_currentData, data);
    if (_currentData && _currentData.length) {
      setMidExecutionOrder(findExecutionOrder(index, data));
      _currentData.splice(index + 1, 0, { type: 'stepGroup' });
      setData(_currentData);
      setAddStepAtIndex(index + 1);
      setshowAddStepButton(false);
    }
  };
  const removeEmptyStep = (ev, index) => {
    let _currentData = [];
    Object.assign(_currentData, data);
    if (_currentData && _currentData.length) {
      _currentData.splice(index, 1);
      setData(_currentData);
      setAddStepAtIndex(null);
      setMidExecutionOrder();
      setshowAddStepButton(true);
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      const cascadedCdn = data.filter((cdn) => cdn.cascaded === 'cascaded');
      if (data.length === cascadedCdn.length) {
        setLastExecOrder(1);
      } else {
        setLastExecOrder(data[data.length - 1].executionOrder + 1);
      }
    } else {
      setLastExecOrder(1);
    }
  }, [data]);
  useEffect(() => {
    if (reload) {
      if (initialLoad) {
        resetProgress();
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 20,
        });
      }
      getPreConditions();
      setInitialLoad(false);
    }
    setReload(false);
  }, [reload]);
  useEffect(() => {
    if (props.createCalled) {
      setOpenCreatePreCondition(true);
      if (noPreConditions) {
        setNoPreConditions(false);
      }
    }
  }, [props.createCalled, noPreConditions]);

  return (
    <div className={`h-90 overflow-auto min-h-full`} id="journal-scroll">
      <TableWithDragAndDrop
        isDragDisabled={!isEditable || data?.length === 1}
        columns={columns}
        data={tableData}
        handleOnDragEnd={handleOnDragEnd}
        rowRef={_DOMNode}
      />

      {openCreatePreCondition ? (
        <div className="mt-2">
          {openStepGroupInputParameterDiv ? (
            <span
              style={{
                boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
              }}
              className="inline-block pt-2 w-full fontPoppinsRegularMd text-blue-700 shadow-md bg-white h-8 pl-16"
            >
              {stepGroupInputParameterDivData.nlpName}
            </span>
          ) : (
            <div className="w-2/5 inline-block">
              <StepgroupSuggestion
                openStepgroupInputDiv={OpenStepGroupDiv}
                closeBox={closeBox}
                closeModal={closeModal}
              />
            </div>
          )}
        </div>
      ) : null}
      {/* step group input parameter div */}
      {openStepGroupInputParameterDiv ? (
        <CreateEditScriptConditions
          orderOfInsertion={addStepAtIndex ? midExecutionOrder : lastExecOrder}
          edit={editPreCondition}
          editData={editData}
          reload={reloadPreConditions}
          closeModal={closeModal}
          MyAlert={props.MyAlert}
          data={stepGroupInputParameterDivData}
          type={'PRE'}
        />
      ) : null}
      {isLoading ? (
        <CommonLoader />
      ) : noPreConditions && data.length === 0 ? (
        <div className="fontPoppinsRegularMd no-steps">
          {isEditable && (
            <div className="">
              <p className="font-bold mb-2">Quick Start</p>
              <p className="mb-2">
                <span className="font-bold">Step 1 :</span> Click
                <button
                  onClick={() => {
                    setNoPreConditions(false);
                    setOpenCreatePreCondition(true);
                  }}
                  className="ml-3 mr-3 primary-btn"
                >
                  + Pre Condition
                </button>
                to start adding the Pre Conditions
              </p>
            </div>
          )}
        </div>
      ) : null}

      {/* modal for delete pre-condition */}
      {openDeleteModal ? (
        <DeleteModal
          closeModal={closeModal}
          handleDelete={deletePreCondition}
          reload={reloadPreConditions}
          dataObj={{
            type: 'PreCondition',
            data: stepGroupInputParameterDivData,
          }}
        />
      ) : null}
    </div>
  );
};
const useClickOutSide = (handler) => {
  const _genericDOMNode = useRef();
  useEffect(() => {
    const _handler = (event) => {
      if (_genericDOMNode.current && !_genericDOMNode.current.contains(event.target)) {
        handler();
      }
    };
    document.addEventListener('mousedown', _handler);
    return () => {
      document.removeEventListener('mousedown', _handler);
    };
  });
  return _genericDOMNode;
};
export default PreCondition;

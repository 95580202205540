import { useEffect, useState } from 'react';
import { getUsersSectionChartData } from '../../../api/api_services';
import PieChart from '../charts/PieChart';
import SmallDonutChart from '../charts/SmallDonutChart';
import SectionHeader from '../components/SectionHeader';

const initialUserStatusesChartData = {
  series: [0, 0],
  labels: ['Activation Pending', 'Active'],
  colors: ['#C50303', '#399365'],
};

const initialUserPrivilegesChartData = {
  title: 'Privileges',
  colors: ['#2862FF', '#E57300', '#5289C9'],
  fillColor: ['#2862FF', '#E57300', '#5289C9'],
  labels: ['Super Admin', 'Admin', 'Users'],
  titleOffsetX: -76,
  strokeWidth: 0,
  series: [0, 0, 0],
};

function Users() {
  const [userStatusesChartData, setUserStatusesChartData] = useState(initialUserStatusesChartData);

  const [userPrivilegesChartData, setUserPrivilegesChartData] = useState(initialUserPrivilegesChartData);
  useEffect(() => {
    getUsersSectionChartData().then((response) => {
      const data = response?.data?.responseObject;
      if (data?.userStatus) {
        const { pending, active } = data.userStatus;
        const userStatusSeries = [pending, active];
        setUserStatusesChartData({
          ...userStatusesChartData,
          series: userStatusSeries,
        });
      }

      if (data?.userPrivilege) {
        const { admin, superAdmin, users } = data.userPrivilege;
        const userPrivilegeSeries = [superAdmin, admin, users];
        setUserPrivilegesChartData({
          ...userPrivilegesChartData,
          series: userPrivilegeSeries,
        });
      }
    });
  }, []);
  return (
    <div>
      <SectionHeader className="select-none fontPoppinsSemiboldLg	">Users</SectionHeader>
      <div className="grid grid-cols-2 gap-1 my-2">
        <SmallDonutChart
          series={userStatusesChartData.series}
          labels={userStatusesChartData.labels}
          colors={userStatusesChartData.colors}
          title="Status"
          totalLabel="All Users"
        />
        <PieChart
          colors={userPrivilegesChartData.colors}
          fillColor={userPrivilegesChartData.fillColor}
          title={userPrivilegesChartData.title}
          titleOffsetX={userPrivilegesChartData.titleOffsetX}
          labels={userPrivilegesChartData.labels}
          strokeWidth={userPrivilegesChartData.strokeWidth}
          series={userPrivilegesChartData.series}
        />
      </div>
    </div>
  );
}

export default Users;

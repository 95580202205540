import { IconButton, Modal } from '@material-ui/core';
import MyInput from '@src/pages/user_config/Inputs/MyInput';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { useAlert } from '@src/pages/common/alert_service/useAlert';
import CloseIcon from '@material-ui/icons/Close';
import { addLambdaTestInstance, updateLambdaTestInstance } from '@api/api_services';

const CreateEditLambdaTestInstance = (props) => {
  const { AlertContatiner, MyAlert } = useAlert();

  const [openModal, setOpenModal] = useState(true);
  const [createCalled, setCreateCalled] = useState(false);
  const handleClose = () => {
    props.closeModal(false);
    setOpenModal(false);
  };
  let initialValues;
  if (props.mode !== 'ADD') {
    initialValues = {
      instance: props.data.instanceName,
      username: props.data.username,
      accessKey: props.data.accessKey,
    };
  } else {
    initialValues = {
      instance: '',
      username: '',
      accessKey: '',
    };
  }

  const validationSchema = yup.object({
    instance: yup
      .string()
      .required('Instance is required')
      .min(3, 'Minimum 3 characters')
      .max(25, 'Maximum 25 characters')
      .matches(/^[-_A-Za-z0-9 ]*$|^[-_A-Za-z0-9 ][A-Za-z0-9 ]*[-_A-Za-z-_]$/, 'Name should be alphanumeric')
      .matches(/^[-a-zA-Z0-9-()_]+(\s+[-a-zA-Z0-9-()_]+)*$/, 'Space is not allowed in the beginning and at the end'),
    username: yup
      .string()
      .ensure()
      .required('Username is required')
      .matches(/.*\S.*/, 'Only whitespaces are not allowed'),
    accessKey: yup
      .string()
      .ensure()
      .required('AccessKey is required')
      .matches(/.*\S.*/, 'Only whitespaces are not allowed'),
  });

  const createOrEditInstance = async (payload, instanceId) => {
    try {
      const response = ['ADD'].includes(props.mode)
        ? await addLambdaTestInstance(payload)
        : await updateLambdaTestInstance(payload, instanceId);
      setCreateCalled(false);
      const { instanceName } = payload;
      const { message, responseCode, status } = response.data || {};
      if (status === 'FAILURE' || [400].includes(responseCode)) {
        if (message?.toLowerCase()?.includes('name already exists')) {
          formikDetails.setFieldError('instance', message);
        } else {
          MyAlert.warning(message);
        }
      } else if (status === 'SUCCESS' || [200, 201].includes(responseCode)) {
        let alertMessage = '';
        if (['ADD'].includes(props.mode)) {
          alertMessage =
            instanceName.length >= 22
              ? `${instanceName.substring(0, 19)}... instance is created successfully`
              : `${instanceName} instance is created successfully`;
        } else {
          alertMessage = 'Account is updated successfully';
        }
        props.MyAlert.success(alertMessage);
        setOpenModal(false);
        props.closeModal(false);
        props.reloadTable();
      } else {
        props.MyAlert.info(message);
        setOpenModal(false);
        props.closeModal(false);
        props.reloadTable();
      }
    } catch (error) {
      console.error('CREATE_OR_EDIT_LAMBDA_TEST_INSTANCE : ', error);
    }
  };

  const onSubmit = (values) => {
    if (values && !createCalled) {
      setCreateCalled(true);
      const payload = {
        instanceName: values.instance,
        username: values.username,
        accessKey: values.accessKey,
      };
      const { data: oldData } = props;
      if (
        ['EDIT'].includes(props.mode) &&
        oldData?.accessKey === payload?.accessKey &&
        oldData?.instanceName === payload?.instanceName &&
        oldData?.username === payload?.username
      ) {
        MyAlert.info('No changes were done');
        setCreateCalled(false);
        return;
      } else {
        createOrEditInstance(payload, oldData?.id);
      }
    }
  };
  const formikDetails = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div>
      <Modal open={openModal} onClose={handleClose} className="modal_dialog">
        <div className="modal_container browserStack-check-modal browserStack_check_modal">
          <div className="alert-position-style">
            <AlertContatiner></AlertContatiner>
          </div>
          <div className="modal_header">
            {['ADD'].includes(props.mode) ? (
              <h2 className="title"> Create instance</h2>
            ) : (
              <div className="title title-trim" title={props.data.instanceName}>
                <h2 className="title"> Edit instance - {props.data.instanceName}</h2>
              </div>
            )}
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                props.closeModal(false);
                setOpenModal(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="modal_body">
            <form onSubmit={formikDetails.handleSubmit}>
              <div className="grid grid-cols-2 gap-4 mb-9">
                <div>
                  <MyInput
                    error={formikDetails.errors.instance && formikDetails.touched.instance}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={
                      <>
                        {' '}
                        <span className="text-red-400 mr-1">&#42;</span> <span className="">Instance Name</span>
                      </>
                    }
                    autoFocus={false}
                    size="40"
                    className=" w-60 fontPoppinsRegularMd"
                    type="text"
                    autoComplete="off"
                    name="instance"
                    id="instance"
                    placeholder={`Enter instance name`}
                    onBlur={formikDetails.handleBlur}
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.instance}
                  />
                  {formikDetails.errors.instance && formikDetails.touched.instance ? (
                    <div className="fontPoppinsRegularSm errorMessage absolute w-60">
                      {formikDetails.errors.instance}
                    </div>
                  ) : null}
                </div>
                <div>
                  <MyInput
                    error={formikDetails.errors.username && formikDetails.touched.username}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={
                      <>
                        {' '}
                        <span className="text-red-400 mr-1">&#42;</span> <span className="">UserName</span>
                      </>
                    }
                    autoFocus={false}
                    size="40"
                    className="w-60 fontPoppinsRegularMd"
                    type="text"
                    autoComplete="new-password"
                    name="username"
                    id="username"
                    placeholder={`Enter username name`}
                    onBlur={formikDetails.handleBlur}
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.username}
                  />
                  {formikDetails.errors.username && formikDetails.touched.username ? (
                    <div className="fontPoppinsRegularSm errorMessage absolute">{formikDetails.errors.username}</div>
                  ) : null}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <div>
                  <MyInput
                    error={formikDetails.errors.accessKey && formikDetails.touched.accessKey}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={
                      <>
                        {' '}
                        <span className="text-red-400 mr-1">&#42;</span>
                        <span className="">Access Key</span>
                      </>
                    }
                    autoFocus={false}
                    size="40"
                    className="w-60 fontPoppinsRegularMd"
                    type="password"
                    placeholder={`Enter access key`}
                    autoComplete="new-password"
                    id="accessKey"
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.accessKey}
                  />
                  {formikDetails.errors.accessKey && formikDetails.touched.accessKey ? (
                    <div className="fontPoppinsRegularSm errorMessage absolute">{formikDetails.errors.accessKey}</div>
                  ) : null}
                </div>
              </div>
              <div className="modal_footer">
                <button
                  type="button"
                  className="gray-btn mr-3"
                  onClick={() => {
                    props.closeModal(false);
                    setOpenModal(false);
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="primary-btn">
                  {['ADD'].includes(props.mode) ? <span>Create</span> : <span>Update</span>}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreateEditLambdaTestInstance;

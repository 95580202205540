import React, { Suspense, useEffect, useState } from 'react';
import SmallDonutChart from './SmallDonutChart';

const fixedSeries = {
  pass: {
    label: 'Pass',
    color: '#79B62F',
  },
  fail: {
    label: 'Fail',
    color: '#C50303',
  },
  // notRun: {
  //   label: "Not Run",
  //   color: "#727171",
  // },
  // tagged: {
  //   label: "Tagged",
  //   color: "#15D1D1",
  // },
  // untagged: {
  //   label: "Untagged",
  //   color: "#147AD6",
  // },
};

const dynamicSeries = {
  skip: {
    label: 'Skip',
    color: '#727171',
  },
  warning: {
    label: 'Warning',
    color: '#EFBB07',
  },
  terminated: {
    label: 'Terminated',
    color: '#C50303',
  },
};

function getSeriesData(data = {}) {
  const applicableDynamicKeys = Object.keys(dynamicSeries).filter((key) => key in (data || {}));

  const allSeriesData = { ...fixedSeries, ...dynamicSeries };
  return [...new Set([...Object.keys(fixedSeries), ...applicableDynamicKeys])].reduce(
    (returnData, key) => {
      return {
        labels: [...returnData.labels, allSeriesData[key].label],
        colors: [...returnData.colors, allSeriesData[key].color],
        data: [...returnData.data, data?.[key] || 0],
      };
    },
    {
      labels: [],
      colors: [],
      data: [],
    }
  );
}

function ExecutionSuiteScripts({ scriptsData }) {
  const [series, setSeries] = useState(getSeriesData());

  useEffect(() => {
    const seriesData = getSeriesData(scriptsData);
    setSeries(seriesData);
  }, [scriptsData]);
  return (
    <div className="h-full">
      <Suspense fallback={<div></div>}>
        <SmallDonutChart
          series={series.data}
          labels={series.labels}
          colors={series.colors}
          title="Scripts"
          totalLabel="All Scripts"
          roundedDirection="br"
        />
      </Suspense>
    </div>
  );
}

export default ExecutionSuiteScripts;

import React, { useMemo } from 'react';
import MultiSelectAll from './MultiSelectAll';
import MultiSelect from './MultiSelect';

export const ColumnFilter = ({ column }) => {
  const { setFilter, preFilteredRows, id } = column;
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = [];
    preFilteredRows.forEach((row) => {
      let val = row.values[id];
      if (typeof val === 'string') {
        options.push({ value: val, label: val });
      } else {
        val = row?.values[id]?.name;
        if (id?.toLowerCase() === 'projectid') {
          val = (row?.values[id] === undefined && 'Licence') || 'Project';
        }
        options.push({ value: val, label: val });
      }
    });

    var result = options.reduce((unique, o) => {
      if (!unique.some((obj) => obj.label === o.label && obj.value === o.value)) {
        unique.push(o);
      }
      return unique;
    }, []);
    return result;
  }, [id, preFilteredRows]);

  const handleSetFilterValue = (value) => {
    const filterValues = value.map((element) => element.value);
    setFilter(filterValues);
  };

  return (
    <div className="App">
      {/* <MultiSelect options={options} setFilterValue={handleSetFilterValue}/> */}
      <MultiSelectAll options={options} setFilterValue={handleSetFilterValue} />
    </div>
  );
};

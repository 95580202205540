import React, { useState } from 'react';

import { Switch, Route, withRouter, Link } from 'react-router-dom';
import { addOrChangeDefaultMachineReq } from '@api/api_services';
import { useAlert } from '@pages/common/alert_service/useAlert';

import SystemConfigurationBase from './system_config v1.1/system-config-base';

const SystemConfigRouter = (props) => {
  const { AlertContatiner, MyAlert } = useAlert();
  const activeTab = props.location.pathname.split('/')[3];
  const user = JSON.parse(localStorage.getItem('test-optimize-user'));
  const project = JSON.parse(localStorage.getItem('selected-project'));
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [changedMachineData, setChangedMachineData] = useState(null);
  const [disabledButton, setDisabledButton] = useState(false);
  const handleDataChangedState = (isdataModfied, modifiedData) => {
    setIsDataChanged(isdataModfied);
    setChangedMachineData(isdataModfied ? modifiedData : null);
  };
  const [reloadScreen, setReloadScreen] = useState(false);
  const handleReloadScreen = (val) => {
    setReloadScreen(val);
  };
  const tabsList = [
    {
      name: 'Environment Configuration',
      pathUrl: `/configuration/environmentconfiguration/systemconfiguration`,
      selectedName: 'systemconfiguration',
    },
    // {
    //   name: "Browser Configuration",
    //   pathUrl: `/configuration/environmentconfiguration/browserconfiguration`,
    //   selectedName: "browserconfiguration",
    // },
    // {
    //   name: "History",
    //   pathUrl: `/configuration/environmentconfiguration/history`,
    //   selectedName: "history",
    // },
  ];
  const handleSave = async () => {
    setDisabledButton(true);
    try {
      let response;
      if (project.id === '') {
        response = await addOrChangeDefaultMachineReq(user?.id, changedMachineData);
      } else {
        response = await addOrChangeDefaultMachineReq(user?.id, changedMachineData, project?.id);
      }
      if (response.data.responseCode === 200 && response.data.status === 'SUCCESS') {
        MyAlert.success(`Default Machine updated successfully`);
      } else if (response.data && response.data.responseCode === 400) {
        MyAlert.warning(`${response.data.status} ${response.data.message}`);
      }
      handleDataChangedState(false, null);
      handleReloadScreen(true);
      setDisabledButton(false);
    } catch (err) {
      console.error(err);
      setDisabledButton(false);
    }
  };

  const handleCancel = () => {
    handleDataChangedState(false, null);
    handleReloadScreen(true);
  };
  const renderTabs = () => {
    return (
      <div className="page-tab-with-actions bg-white border-b-2 items-center">
        <ul className="navBar text-blue-600 flex flex-row primary-tab -mb-0.5">
          {tabsList.map((tab, index) => {
            return (
              <li key={tab.name}>
                <Link to={tab.pathUrl} className={`${activeTab === tab.selectedName ? 'selected_tab_style' : ''}`}>
                  {tab.name}
                </Link>
              </li>
            );
          })}
        </ul>
        <div className="clearfix ml-auto"></div>
      </div>
    );
  };

  const configProps = {
    MyAlert,
    reloadScreen,
    handleReloadScreen,
    handleDataChangedState,
    handleSave,
    handleCancel,
    isDataChanged,
    disabledButton,
  };

  let renderPage = () => {
    return (
      <Switch>
        <Route
          path="/configuration/environmentconfiguration/systemconfiguration"
          exact={true}
          strict={true}
          key="system-config"
        >
          <SystemConfigurationBase {...configProps} />
        </Route>
        <Route path="/configuration/environmentconfiguration" exact={true} strict={true} key="system-config">
          <SystemConfigurationBase {...configProps} />
        </Route>
        <Route path="/configuration" exact={true} strict={true} key="system-config">
          <SystemConfigurationBase {...configProps} />
        </Route>
      </Switch>
    );
  };
  return (
    <div className="page-container">
      <div className="alert-position-style">
        <AlertContatiner></AlertContatiner>
      </div>
      <div className="page-list-body">
        {renderTabs()}
        <div className="page-table-body">{renderPage()}</div>
      </div>
    </div>
  );
};

export default withRouter(SystemConfigRouter);

import React, { Fragment } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router';
import { FiMaximize2 } from 'react-icons/fi';
import { FaSearch } from 'react-icons/fa';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DragIndicatorOutlined } from '@material-ui/icons';
import { FormControl, InputLabel, MenuItem, Select, Tooltip } from '@material-ui/core';
import { AiOutlineDelete } from 'react-icons/ai';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useTable, usePagination, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy } from 'react-table';
import matchSorter from 'match-sorter';
import Pagination from '../../common/pagination';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';
import { getAllSuiteReq, postSuiteOfSuiteReq, updateSuiteOfSuiteReq } from '../../../api/api_services';
import SuiteDetails from '../suites/details-child-pages/suite-details';
import { CommonLoader } from '@src/pages/common/common-loader';

let tempSelecteSuiteArray = [];
let suiteMainArray = [];
// let selectedSuiteStoreArray = [];

const CreateEditSuiteOfSuites = (props) => {
  let selectedSuiteStoreArray = [];
  const [suiteArray, setSuiteArrayData] = React.useState([]);
  let [data, setData] = React.useState([]);
  let [isLoading, setIsLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [operation, setOperation] = React.useState('Create');
  const [checkboxClicked, setCheckBtnClicked] = React.useState(false);
  const [selectedSuiteArray, setSelectedSuiteArray] = React.useState([]);
  tempSelecteSuiteArray = [...selectedSuiteArray];
  const [open, setOpen] = React.useState(false);
  const [selectedName, setDeleteName] = React.useState();
  const [screenMode, setScreenMode] = React.useState('createEditSuiteOfSuite');

  const [currentPage, setCurrentPage] = React.useState(1);

  const [postsPerPage] = React.useState(10);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const [createUpdateCalled, setCreateUpdateCalled] = React.useState(false);
  let currentPosts = selectedSuiteArray.slice(indexOfFirstPost, indexOfLastPost);
  let currentSuitePosts = suiteArray.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  function setImmediatePrevPage() {
    let prevPageNo = currentPage - 1;
    setCurrentPage(prevPageNo);
  }

  function setImmediateNextPage() {
    let prevPageNo = currentPage + 1;
    setCurrentPage(prevPageNo);
  }

  const formik = useFormik({
    initialValues: {
      Name: props?.suiteofSuiteObj?.name ? props?.suiteofSuiteObj?.name : '',
      description: props?.suiteofSuiteObj?.description ? props?.suiteofSuiteObj?.description : null,
      // selectedSuiteStoreArray: props?.suiteofSuiteObj?.description ? props?.suiteofSuiteObj?.description : null,
    },

    validationSchema: Yup.object({
      Name: Yup.string().min(3, 'Minimum 3 characters').max(25, 'Maximum 25 characters').required('Required!'),
    }),

    onSubmit: () => {
      createSuite(props.operation === 'update' ? 'update' : 'create');
    },
  });

  function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);

    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);

    return (
      <span>
        Search:{' '}
        <input
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`Type here...`}
          style={{
            fontSize: '1.1rem',
            border: '0',
          }}
        />
      </span>
    );
  }

  // Define a default UI for filtering
  function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search...`}
      />
    );
  }

  // This is a custom filter UI for selecting
  // a unique option from a list
  function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  // This is a custom filter UI that uses a
  // slider to set the filter value between a column's
  // min and max values
  function SliderColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    // Calculate the min and max
    // using the preFilteredRows

    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      preFilteredRows.forEach((row) => {
        min = Math.min(row.values[id], min);
        max = Math.max(row.values[id], max);
      });
      return [min, max];
    }, [id, preFilteredRows]);

    return (
      <>
        <input
          type="range"
          min={min}
          max={max}
          value={filterValue || min}
          onChange={(e) => {
            setFilter(parseInt(e.target.value, 10));
          }}
        />
        <button onClick={() => setFilter(undefined)}>Off</button>
      </>
    );
  }

  // This is a custom UI for our 'between' or number range
  // filter. It uses two number boxes and filters rows to
  // ones that have values between the two
  function NumberRangeColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id } }) {
    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      preFilteredRows.forEach((row) => {
        min = Math.min(row.values[id], min);
        max = Math.max(row.values[id], max);
      });
      return [min, max];
    }, [id, preFilteredRows]);

    return (
      <div
        style={{
          display: 'flex',
        }}
      >
        <input
          value={filterValue[0] || ''}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
          }}
          placeholder={`Min (${min})`}
          style={{
            width: '70px',
            marginRight: '0.5rem',
          }}
        />
        to
        <input
          value={filterValue[1] || ''}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
          }}
          placeholder={`Max (${max})`}
          style={{
            width: '70px',
            marginLeft: '0.5rem',
          }}
        />
      </div>
    );
  }

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val) => !val;

  // Our table component
  function Table({ columns, data }) {
    const filterTypes = React.useMemo(
      () => ({
        // Add a new fuzzyTextFilterFn filter type.
        fuzzyText: fuzzyTextFilterFn,
        // Or, override the default text filter to use
        // "startWith"
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      []
    );

    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      nextPage,
      previousPage,
      state,
      visibleColumns,
      preGlobalFilteredRows,
      setGlobalFilter,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
        initialState: { pageIndex: 0, pageSize: 9 },
      },
      useFilters, // useFilters!
      useGlobalFilter, // useGlobalFilter!
      useSortBy,
      usePagination
    );
    return (
      <>
        <table class="relative w-full" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr class="mt-4" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th class="sticky top-0 px-6 py-3 text-current text-sm bg-gray-100 ml-5" {...column.getHeaderProps()}>
                    <span className="flex flex-row">
                      {column.render('Header')}
                      {/* Render the columns filter UI */}
                      <div>
                        <span>
                          {/* {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <AiOutlineArrowDown className="mt-1 ml-1" />
                                                            : <AiOutlineArrowUp className="mt-1 ml-1" />
                                                        : ''} */}
                        </span>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr className="project-row" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td class="px-4 py-3 text-center" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagecountborder" id="footer-suite-of-suite">
          <div className="float-right pr-4">
            <span className="pagecount">
              {page[0]?.index + 1} - {page[page.length - 1]?.index + 1} of {suiteArray.length}
            </span>
            <span className="pageborderleft ml-2 pl-2">
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className={canPreviousPage ? 'pagination-clickable-style ' : 'pageArrow'}
              >
                {'<'}
              </button>{' '}
              <span className="currentpagecount ml-2 mr-2">
                {' '}
                <span className="pl-2 pr-2">{pageIndex + 1}</span>
              </span>
              <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className={canNextPage ? 'pagination-clickable-style ' : 'pageArrow'}
              >
                {'>'}
              </button>{' '}
            </span>
          </div>
        </div>
        <br />
      </>
    );
  }

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue >= filterValue;
    });
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        columns: [],
      },

      {
        Header: (
          <input type="checkbox" className="float-left ml-3" id="selectAll" onClick={(e) => getSelectAllFun(e)} />
        ),
        accessor: 'checkValue',
        disableFilters: true,
        Cell: (e) => {
          return (
            <input
              type="checkbox"
              className="float-left ml-5"
              name="selectSet"
              id={e.cell.row.original._id}
              defaultChecked={e.cell.row.original.checkboxValue}
              onChange={(event) => {
                getSelectedDevice(e.cell.row.original, event);
              }}
            />
          );
        },
      },

      {
        Header: 'Name',
        accessor: 'name',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left ml-3 table-font-style-common">{value}</div>;
        },
      },
      {
        Header: 'No.of Scripts',
        accessor: 'noOfScripts',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left ml-3 table-font-style-common">{value}</div>;
        },
      },
      {
        Header: 'Access',
        accessor: 'access',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left ml-3 table-font-style-common">{value}</div>;
        },
      },
      {
        Header: 'Run in',
        accessor: 'machines.multiple',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common float-left ml-3 table-font-style-common">
              {value === 'false' ? 'Single machine' : 'Multiple Machine'}
            </div>
          );
        },
      },
      {
        Header: 'Execution Type',
        accessor: 'machines.executionType',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common float-left ml-3 table-font-style-common">
              {value === 'SEND_SUITE_TO_ALL' ? 'Parallel' : value === 'DISTRIBUTE' ? 'Distribute' : 'Sequential'}
            </div>
          );
        },
      },
    ],
    []
  );

  function getAllSuite() {
    getAllSuiteReq()
      .then((results) => {
        const response = results.data.responseObject;
        if (response) {
          setSuiteArrayData((d) => response);
          suiteMainArray = response;

          if (props) {
            if (props?.suiteofSuiteObj) {
              if (props?.suiteofSuiteObj?.suites && props?.suiteofSuiteObj?.suites.length) {
                if (response && response.length) {
                  selectedSuiteStoreArray = props?.suiteofSuiteObj?.suites;
                  // let tempArray = [];
                  // response.map(suiteObj => {
                  //     const found = suiteNameArray.find(syelement => syelement === suiteObj.id);
                  //     if (found) {
                  //         tempArray.push(suiteObj)
                  //     }

                  // })
                  // console.log(`tempArray`, tempArray)
                  // selectedSuiteStoreArray = tempArray;
                  setSelectedSuiteArray(props?.suiteofSuiteObj?.suites);
                }
              }
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return [];
  }

  React.useEffect(() => {
    if (props) {
      if (props?.suiteofSuiteObj) {
        if (props?.suiteofSuiteObj?.suites && props?.suiteofSuiteObj?.suites.length) {
          selectedSuiteStoreArray = props?.suiteofSuiteObj?.suites;
          setSelectedSuiteArray(props?.suiteofSuiteObj?.suites);
        }
      }
    }
    getAllSuite();
  }, []);

  let history = useHistory();
  const [descCount, setdescCount] = React.useState(0);

  function goToSuiteTable() {
    props.setSuiteofSuiteObj();
    history.push('/execution/suiteofsuite');
  }

  function createSuite(type) {
    if (type === 'create') {
      let suiteIdArray = [];
      if (selectedSuiteArray && selectedSuiteArray.length) {
        selectedSuiteArray.map((suiteObj) => {
          suiteIdArray.push(suiteObj.id);
        });
      }
      let suiteOfSuiteJson = {
        name: formik.values.Name,
        description: formik.values.description,
        access: 'Private',
        suites: selectedSuiteArray,
      };

      setCreateUpdateCalled(true);
      postSuiteOfSuiteReq(suiteOfSuiteJson)
        .then((res) => {
          if (res.data && res.data.responseObject) {
            history.push('/execution/suiteofsuite');
            props.getAllSuiteOfSuite();
            props.createAlertFun('success', `${suiteOfSuiteJson.name} created successfully.`);
            props.setSuiteofSuiteObj();
            setCreateUpdateCalled(false);
          } else {
            props.createAlertFun('danger', `${res?.data?.message} `);
            setCreateUpdateCalled(false);
            // childalert.danger(res.data.message);
          }
        })
        .catch((error) => {
          console.log('error');
          setCreateUpdateCalled(false);
        });
    } else if (type === 'update') {
      let suiteOfSuiteJson = {
        name: formik.values.Name,
        description: formik.values.description,
        access: props?.suiteofSuiteObj.access,
        suites: selectedSuiteArray,
      };

      updateSuiteOfSuiteReq(props?.suiteofSuiteObj?.id, suiteOfSuiteJson)
        .then((res) => {
          if (res.data && res.data.responseObject) {
            history.push('/execution/suiteofsuite');
            props.getAllSuiteOfSuite();
            props.createAlertFun('success', `${suiteOfSuiteJson.name}  updated successfully.`);
            props.setSuiteofSuiteObj();
          } else {
            console.log('error', res.data.message);
            props.createAlertFun('danger', `${res?.data?.message} `);
            // childalert.danger(res.data.message);
          }
        })
        .catch((error) => {
          console.log('error');
        });
    }
  }

  function handleOnDragEnd(result) {
    console.log('result', result);
    if (!result.destination) return;
    const items = Array.from(data);
    console.log('items', items);
    const [reorderedItem] = selectedSuiteArray.splice(result.source.index, 1);
    selectedSuiteArray.splice(result.destination.index, 0, reorderedItem);
    console.log('items', items);
    console.log('selectedSuiteArray', selectedSuiteArray);
    setData(items);
  }

  const navigateToDetailsPage = (data, index) => {
    console.log('data,index', data, index);
    setScreenMode('suiteDetails');
    history.push(`/execution/suiteofsuite/edit-suite-of-suites?suiteId=${data?.id}`);
  };

  function openModalfun(btn) {
    if (btn === 'create') {
      setShowModal(true);
      console.log('selectedSuiteArray', selectedSuiteArray);
      if (selectedSuiteArray && selectedSuiteArray.length) {
        suiteArray.map((suiteObj) => {
          const found = selectedSuiteArray.find((syelement) => syelement.name === suiteObj.name);
          if (found) {
            suiteObj.checkboxValue = true;
          } else {
            suiteObj.checkboxValue = false;
          }
        });
      }

      if (tempSelecteSuiteArray.length) {
        let htmlElementForSelectBtn = document.getElementById('selectBtn');
        console.log(`htmlElementForSelectBtn`, htmlElementForSelectBtn);
        if (htmlElementForSelectBtn) {
          htmlElementForSelectBtn.classList.remove('disabled-update-btn');
          htmlElementForSelectBtn.classList.add('primary-btn');
        }
        if (tempSelecteSuiteArray.length === suiteMainArray.length) {
          setTimeout(() => {
            let htmlElementForSelectAll = document.getElementById('selectAll');
            if (htmlElementForSelectAll) {
              htmlElementForSelectAll.indeterminate = false;
              htmlElementForSelectAll.checked = true;
            }
          });
        } else {
          setTimeout(() => {
            let htmlElementForSelectAll = document.getElementById('selectAll');
            if (htmlElementForSelectAll) {
              htmlElementForSelectAll.checked = false;
              htmlElementForSelectAll.indeterminate = true;
            }
          });
        }
      } else {
        // setCheckBtnClicked(false);
        // htmlEle.classList.replace('primary-btn', 'disabled-update-btn')
        let htmlElementForSelectBtn = document.getElementById('selectBtn');
        if (htmlElementForSelectBtn) {
          htmlElementForSelectBtn.classList.add('disabled-update-btn');
          htmlElementForSelectBtn.classList.remove('primary-btn');
        }
        setTimeout(() => {
          let htmlElementForSelectAll = document.getElementById('selectAll');
          if (htmlElementForSelectAll) {
            htmlElementForSelectAll.checked = false;
            htmlElementForSelectAll.indeterminate = false;
          }
        });
      }

      setOperation('create');
    } else if (btn === 'maximize') {
      setShowModal(true);
      setOperation('maximize');
    }
  }

  function deleteFunction() {
    console.log(`AAAAAAAAAAAAAAAAAAAAAAAAAAAAAA`);
  }

  function getSelectAllFun(e) {
    console.log('e', e);
    if (e?.target?.checked) {
      tempSelecteSuiteArray = [];
      if (suiteMainArray?.length) {
        suiteMainArray.map((obj) => {
          let htmlEle = document.getElementById(obj?._id);
          if (htmlEle) {
            htmlEle.checked = true;
          }
          obj.checkboxValue = true;
          tempSelecteSuiteArray.push(obj);
        });
      }
    } else {
      if (suiteMainArray?.length) {
        suiteMainArray.map((obj) => {
          let htmlEle = document.getElementById(obj?._id);
          if (htmlEle) {
            htmlEle.checked = false;
          }
          obj.checkboxValue = false;
          tempSelecteSuiteArray = [];
        });
      }
    }
    let htmlEleSelectBtn = document.getElementById('selectBtn');
    if (tempSelecteSuiteArray.length) {
      htmlEleSelectBtn.classList.replace('disabled-update-btn', 'primary-btn');
    } else {
      htmlEleSelectBtn.classList.replace('primary-btn', 'disabled-update-btn');
    }
    console.log('tempSelecteSuiteArray', tempSelecteSuiteArray);
  }

  function getSelectedDevice(data, event) {
    console.log(`event`, event);
    console.log(`data`, data);
    console.log(`selectedSuiteArray`, selectedSuiteArray);
    if (event.target.checked) {
      // tempSelecteSuiteArray = selectedSuiteArray
      const found = suiteMainArray.find((syelement) => syelement.name === data.name);
      console.log('found', found);
      if (found) {
        found.checkboxValue = true;
      }
      tempSelecteSuiteArray.push(data);
      console.log(`tempSelecteSuiteArray`, tempSelecteSuiteArray);
      console.log(`selectedSuiteArray`, selectedSuiteArray);
    } else {
      const found = suiteMainArray.find((syelement) => syelement.name === data.name);
      if (found) {
        found.checkboxValue = false;
      }
      setTemSelectedDeviceArray();
    }

    if (tempSelecteSuiteArray.length) {
      let htmlElementForSelectBtn = document.getElementById('selectBtn');
      console.log(`htmlElementForSelectBtn`, htmlElementForSelectBtn);
      if (htmlElementForSelectBtn) {
        htmlElementForSelectBtn.classList.remove('disabled-update-btn');
        htmlElementForSelectBtn.classList.add('primary-btn');
      }
      if (tempSelecteSuiteArray.length === suiteMainArray.length) {
        setTimeout(() => {
          let htmlElementForSelectAll = document.getElementById('selectAll');
          if (htmlElementForSelectAll) {
            htmlElementForSelectAll.indeterminate = false;
            htmlElementForSelectAll.checked = true;
          }
        });
      } else {
        setTimeout(() => {
          let htmlElementForSelectAll = document.getElementById('selectAll');
          if (htmlElementForSelectAll) {
            htmlElementForSelectAll.checked = false;
            htmlElementForSelectAll.indeterminate = true;
          }
        });
      }
    } else {
      // setCheckBtnClicked(false);
      // htmlEle.classList.replace('primary-btn', 'disabled-update-btn')
      let htmlElementForSelectBtn = document.getElementById('selectBtn');
      if (htmlElementForSelectBtn) {
        htmlElementForSelectBtn.classList.add('disabled-update-btn');
        htmlElementForSelectBtn.classList.remove('primary-btn');
      }
      setTimeout(() => {
        let htmlElementForSelectAll = document.getElementById('selectAll');
        if (htmlElementForSelectAll) {
          htmlElementForSelectAll.checked = false;
          htmlElementForSelectAll.indeterminate = false;
        }
      });
    }
  }

  function setTemSelectedDeviceArray() {
    tempSelecteSuiteArray = [];
    console.log(`suiteMainArray`, suiteMainArray);
    if (suiteMainArray.length) {
      suiteMainArray.map((machine) => {
        if (machine.checkboxValue) {
          tempSelecteSuiteArray.push(machine);
        }
      });
      console.log(`tempSelecteSuiteArray`, tempSelecteSuiteArray);
    }
  }

  function getSelectedSuiteData() {
    setSelectedSuiteArray(tempSelecteSuiteArray);
    setShowModal(false);
    setCurrentPage(1);
  }

  let handelDlt = (selectedName) => {
    const found = selectedSuiteArray.find((syelement) => syelement.name === selectedName);
    if (found) {
      found.checkboxValue = false;
      tempSelecteSuiteArray = selectedSuiteArray;
      const removeDevicIndex = tempSelecteSuiteArray.findIndex((develement) => develement.name === selectedName);
      if (removeDevicIndex > -1) {
        tempSelecteSuiteArray.splice(removeDevicIndex, 1);
      }
    }
    setSelectedSuiteArray(tempSelecteSuiteArray);
    setSuiteArrayData(suiteArray);
    setOpen(false);
  };

  function setTabContent() {
    if (suiteMainArray.length) {
      if (tempSelecteSuiteArray.length) {
        tempSelecteSuiteArray.map((machine) => {
          const selectedIndex = suiteMainArray.find((develement) => develement.name === machine.name);
          if (selectedIndex > -1) {
            console.log(`selectedIndex`, selectedIndex);
            selectedIndex.checkboxValue = true;
          }
        });
      }
    }
  }

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={setOpen}>
          <div className="flex  items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block marginupper pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/4">
                <div className="inline-block">
                  <div className="flex flex-start ml-2">
                    <span className="pt-5 pl-3">
                      <ExclamationIcon className="h-6 w-6 text-yellow-600 " />
                    </span>
                    <Dialog.Title as="h3" className="text-sm leading-6 ml-4 pt-3 font-small text-gray-900">
                      <span className="fontPoppinsSemiboldLg block">Warning </span>
                      <span className="fontPoppinsMediumMd">Delete </span>
                    </Dialog.Title>
                  </div>
                </div>
                <hr className="line mt-px" />
                <div className="text-sm font-sans mt-3 text-gray-500 ml-16 mr-8">
                  <p className="">
                    Your Suite of Suite Data will be lost.Are you sure you want to delete {selectedName}?{' '}
                  </p>
                  <p className="mt-2"> How do you want to proceed?</p>
                </div>
                <hr className="line mt-3" />
                <div className="mt-2 pb-2 flex flex-row float-right">
                  <button type="button" className="mt-3 gray-btn ml-1" onClick={() => handelDlt(selectedName)}>
                    Delete
                  </button>
                  <button type="button" className="mt-3 ml-4 primary-btn " onClick={() => setOpen(false)}>
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed z-10 inset-0 overflow-y-auto"
                    open={open}
                   onClose={setOpen}
                >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <span
                            className="hidden sm:inline-block sm:align-middle sm:h-screen"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block marginupper pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/4">
                                <div className="inline-block">
                                    <div className="flex flex-start ml-2">
                                        <span className="pt-5 pl-3">
                                            <ExclamationIcon
                                                className="h-6 w-6 text-yellow-600 "
                                            />
                                        </span>
                                        <Dialog.Title
                                            as="h3"
                                            className="text-sm leading-6 ml-4 pt-3 font-small text-gray-900"
                                        >
                                            <span className="fontPoppinsSemiboldLg block">Warning </span>
                                            <span className="fontPoppinsMediumMd">Delete </span>
                                        </Dialog.Title>
                                    </div>
                                </div>
                                  <hr className="line mt-px" />
                                <div className="text-sm font-sans mt-3 text-gray-500 ml-16 mr-8">
                                    <p className="">Your Suite of Suite Data will be lost.Are you sure you want to delete {selectedName ? selectedName.name : null}? </p>
                                    <p className="mt-2"> How do you want to proceed?</p>
                                </div>
                                <hr className="line mt-3" />
                                <div className="mt-2 pb-2 flex flex-row float-right">

                                    <button
                                        type="button"
                                        className="mt-3 gray-btn ml-1"
                                        onClick={() => handelDlt(selectedName)}
                                       
                                    >
                                        Delete
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 ml-4 primary-btn "
					                    onClick={() => setOpen(false)}
                                        
                                        
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root> */}

      <Modal open={showModal} className="modal-dialog">
        {operation === 'create' ? (
          <div className="modal-container edit-tes-data-style">
            <div className="modal-header">
              <label className="Popup-header-common Popup-header-layout-style-user">Select Suites</label>
              <IconButton
                color="primary"
                component="span"
                className="close"
                onClick={() => {
                  setShowModal(false);
                  setCurrentPage(1);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div id="journal-scroll" style={{ height: '75vh' }}>
              {/* <div className="selected-single-machine-content-area-style" > */}
              {/* <div className="p-3">
                                    <label className="test-envi-label-style float-left">Select Suites</label>
                                    <FaSearch className="float-right searchBtn searchBtn" />
                                </div> */}
              <Table columns={columns} data={suiteArray} />
              {/* {suiteArray.length ? (
                                    <div className='crete-pagination-style'>
                                    <Pagination postsPerPage={postsPerPage}
                                        totalPosts={suiteArray.length}
                                        paginate={paginate} setImmediatePrevPage={setImmediatePrevPage} indexOfFirstPost={indexOfFirstPost}
                                        indexOfLastPost={indexOfLastPost} setImmediateNextPage={setImmediateNextPage}
                                        currentPage={currentPage}
                                    />
                                    </div>
                                ) : null
                                } */}

              {/* </div> */}
            </div>
            <div className="modal-footer">
              <button
                className="gray-btn mr-3"
                onClick={() => {
                  setShowModal(false);
                  setCurrentPage(1);
                }}
              >
                Close
              </button>
              <button type="button" id="selectBtn" className="primary-btn" onClick={getSelectedSuiteData}>
                Select
              </button>
            </div>
          </div>
        ) : (
          <div className="modal-container edit-tes-data-style">
            <div className="modal-header">
              <label className="Popup-header-common Popup-header-layout-style-user">Suites</label>
              <IconButton
                color="primary"
                component="span"
                className="close"
                onClick={() => {
                  setShowModal(false);
                  setCurrentPage(1);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div className="modal-body" id="journal-scroll">
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <table className="">
                  <thead>
                    <tr className="">
                      <th
                        style={{ width: '3%' }}
                        className="sticky top-0 px-6 py-3 text-current text-sm bg-gray-100 ml-5 text-left"
                      ></th>
                      <th
                        style={{ width: '27%' }}
                        className="sticky top-0 px-6 py-3 text-current text-sm bg-gray-100 ml-5 text-left"
                      >
                        Name
                      </th>
                      <th
                        style={{ width: '20%' }}
                        className="sticky top-0 px-6 py-3 text-current text-sm bg-gray-100 ml-5 text-left"
                      >
                        No.of Scripts
                      </th>
                      <th
                        style={{ width: '10%' }}
                        className="sticky top-0 px-6 py-3 text-current text-sm bg-gray-100 ml-5 text-left"
                      >
                        Access
                      </th>
                      <th
                        style={{ width: '20%' }}
                        className="sticky top-0 px-6 py-3 text-current text-sm bg-gray-100 ml-5 text-left"
                      >
                        Run In
                      </th>
                      <th
                        style={{ width: '20%' }}
                        className="sticky top-0 px-6 py-3 text-current text-sm bg-gray-100 ml-5 text-left"
                      >
                        Execution Type
                      </th>
                      <th
                        style={{ width: '10%' }}
                        className="sticky top-0 px-6 py-3 text-current text-sm bg-gray-100 ml-5 text-left"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>

                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <tbody className="text text-xs" {...provided.droppableProps} ref={provided.innerRef}>
                        {currentPosts.map((row, index) => {
                          return (
                            <Draggable
                              key={'dragRow' + index}
                              draggableId={'dragRow' + index}
                              index={index}
                              className=" width-tr"
                            >
                              {(provided) => (
                                <tr
                                  key={`tableRow${index}`}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  className="configHover h-8 content-start myRow"
                                >
                                  <td className="p-0 pl-5 text-left text-sm suite-table-td-height">
                                    <span className="actionIcons" {...provided.dragHandleProps}>
                                      <DragIndicatorOutlined fontSize="small" className="text-blue-600" />{' '}
                                    </span>
                                  </td>
                                  <td className="p-0 pl-5 text-left text-sm suite-table-td-height">
                                    <span
                                      className="text-blue-700 cursor-pointer table-font-style-common fontPoppinsRegularMd font-regular"
                                      onClick={() => {
                                        navigateToDetailsPage(row, index);
                                      }}
                                    >
                                      {' '}
                                      {row.name}{' '}
                                    </span>
                                  </td>
                                  <td className="p-0 pl-5 text-left text-sm suite-table-td-height">
                                    <span className="text-gray-700 table-font-style-common fontPoppinsRegularMd font-regular">
                                      {' '}
                                      {row.noOfScripts}
                                    </span>
                                  </td>
                                  <td className="p-0 pl-5 text-left text-sm suite-table-td-height">
                                    <span className="text-gray-700 table-font-style-common fontPoppinsRegularMd font-regular">
                                      {' '}
                                      {row.access}
                                    </span>
                                  </td>
                                  <td className="p-0 pl-5 text-left table-font-style-common suite-table-td-height">
                                    <span className="text-gray-700 table-font-style-common fontPoppinsRegularMd font-regular">
                                      {' '}
                                      {row.machines.multiple === 'false' ? 'Single machine' : 'Multiple Machine'}
                                    </span>
                                  </td>
                                  <td className="p-0 pl-5 text-left table-font-style-common suite-table-td-height">
                                    <span className="text-gray-700 table-font-style-common fontPoppinsRegularMd font-regular">
                                      {' '}
                                      {row.machines.executionType === 'SEND_SUITE_TO_ALL'
                                        ? 'Parallel'
                                        : row.machines.executionType === 'DISTRIBUTE'
                                          ? 'Distribute'
                                          : 'Sequential'}
                                    </span>
                                  </td>
                                  <td className="p-0 pl-5 text-left table-font-style-common suite-table-td-height">
                                    <span className="actionIcons">
                                      <Tooltip title="Delete">
                                        <AiOutlineDelete
                                          className="float-left cursor-pointer mr-3 text-blue-700 text-base"
                                          onClick={() => {
                                            setOpen(true);
                                            setDeleteName(row.name);
                                          }}
                                        />
                                      </Tooltip>
                                    </span>
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          );
                        })}
                      </tbody>
                    )}
                  </Droppable>
                </table>
                {isLoading ? (
                  <CommonLoader />
                ) : selectedSuiteArray.length === 0 ? (
                  <div className="fontPoppinsRegularMd no-step-exe">
                    <div className="">
                      <p className="font-bold mb-4">Quick Start</p>
                      <p className="mb-4">
                        <span className="font-bold">Step 1 :</span> Click
                        <button
                          onClick={() => {
                            openModalfun('create');
                            setTabContent();
                          }}
                          className="ml-3 mr-3 primary-btn"
                        >
                          + Select Suites
                        </button>
                        to start adding the Suites
                      </p>
                    </div>
                  </div>
                ) : null}
              </DragDropContext>

              {selectedSuiteArray.length ? (
                <div className="pagination-padding-suiteofsuite">
                  <Pagination
                    postsPerPage={postsPerPage}
                    totalPosts={selectedSuiteArray.length}
                    paginate={paginate}
                    setImmediatePrevPage={setImmediatePrevPage}
                    indexOfFirstPost={indexOfFirstPost}
                    indexOfLastPost={indexOfLastPost}
                    setImmediateNextPage={setImmediateNextPage}
                    currentPage={currentPage}
                  />
                </div>
              ) : null}
            </div>
          </div>
        )}
      </Modal>
      {screenMode === 'createEditSuiteOfSuite' ? (
        <div>
          <form onSubmit={formik.handleSubmit} className="space-y-8 ">
            <div className="create-suite-header-style">
              <label className="create-suite-label-style  page-header-common float-left">
                {props.operation === 'update' ? 'Update Suite of Suites' : 'Create Suite of Suites'}
              </label>
              <span className="flex flex-row float-right">
                <button className="gray-btn mr-3" onClick={goToSuiteTable}>
                  <span className="clear-btn-label-style">Cancel</span>
                </button>

                <button
                  type="submit"
                  disabled={(formik.errors.Name && formik.values.Name) || createUpdateCalled}
                  className="primary-btn"
                >
                  <span className="save-btn-label-style">{props.operation === 'update' ? 'Update' : 'Create'}</span>
                </button>
              </span>
            </div>

            <div className="ml-2">
              <div className="mt-5"></div>
              <div>
                <div id="Suite">
                  <a href="Suite "></a>
                  {/* <div className="border_style"> */}
                  <div className="flex flex-row">
                    <div className="w-2/5">
                      <label htmlFor="Name" className="input-filed-label-style-common">
                        <span class="text-red-400">*</span> Name
                      </label>

                      <input
                        type="text"
                        value={formik.values.Name}
                        placeholder="Enter suite name"
                        onChange={formik.handleChange}
                        name="Name"
                        id="Name"
                        autocomplete="off"
                        className="max-w-lg block w-11/12 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 input-field-style "
                      />
                      {(formik.errors.Name && formik.touched.Name) ||
                        (formik.values.Name && <p style={{ color: 'red' }}>{formik.errors.Name}</p>)}
                    </div>
                    <div className="w-3/5">
                      <label htmlFor="Description" className="input-filed-label-style-common">
                        Description
                      </label>
                      <textarea
                        id="description"
                        name="description"
                        value={formik.values.description}
                        placeholder="Your description goes here..."
                        onChange={formik.handleChange}
                        onKeyUp={(e) => setdescCount(e.target.value.length)}
                        rows="1"
                        maxLength="200"
                        className="input-style-textarea  shadow-sm block  mt-1  border-0 border-b  input-field-style-textarea "
                      ></textarea>
                      <div className="mt-1 mr-5 text-sm text-gray-500 text-right">{descCount}/200</div>
                    </div>
                  </div>

                  {/* </div> */}
                  <div className="suite-of-suite-area-style">
                    <div className="grid grid-cols-2 p-2 ">
                      <div className="suite-header-style">
                        <label className="module-script-label-style float-left"> Suites</label>
                      </div>

                      <div>
                        <div className="flex flex-row float-right">
                          {/* <div className=" search_btn_style">
                                                    <FaSearch className="searchBtn" />
                                                </div> */}
                          <button
                            type="button"
                            class="secondary-btn mr-3"
                            onClick={() => {
                              openModalfun('create');
                              setTabContent();
                            }}
                          >
                            Select Suites
                          </button>
                          <FiMaximize2
                            title="Maximize"
                            className={`${selectedSuiteArray.length ? 'maximize-btn-style  cursor-hand' : 'disabled-action'} mt-2 mr-3`}
                            onClick={() => openModalfun('maximize')}
                          />
                        </div>
                      </div>
                    </div>

                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <table className="">
                        <thead>
                          <tr className="">
                            <th
                              style={{ width: '3%' }}
                              className="sticky top-0 px-6 py-3 text-current text-sm bg-gray-100 ml-5 text-left"
                            ></th>
                            <th
                              style={{ width: '27%' }}
                              className="sticky top-0 px-6 py-3 text-current text-sm bg-gray-100 ml-5 text-left"
                            >
                              Name
                            </th>
                            <th
                              style={{ width: '20%' }}
                              className="sticky top-0 px-6 py-3 text-current text-sm bg-gray-100 ml-5 text-left"
                            >
                              No.of Scripts
                            </th>
                            <th
                              style={{ width: '10%' }}
                              className="sticky top-0 px-6 py-3 text-current text-sm bg-gray-100 ml-5 text-left"
                            >
                              Access
                            </th>
                            <th
                              style={{ width: '20%' }}
                              className="sticky top-0 px-6 py-3 text-current text-sm bg-gray-100 ml-5 text-left"
                            >
                              Run In
                            </th>
                            <th
                              style={{ width: '20%' }}
                              className="sticky top-0 px-6 py-3 text-current text-sm bg-gray-100 ml-5 text-left"
                            >
                              Execution Type
                            </th>
                            <th
                              style={{ width: '10%' }}
                              className="sticky top-0 px-6 py-3 text-current text-sm bg-gray-100 ml-5 text-left"
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>

                        <Droppable droppableId="droppable">
                          {(provided) => (
                            <tbody className="text text-xs" {...provided.droppableProps} ref={provided.innerRef}>
                              {currentPosts.map((row, index) => {
                                return (
                                  <Draggable
                                    key={'dragRow' + index}
                                    draggableId={'dragRow' + index}
                                    index={index}
                                    className=" width-tr"
                                  >
                                    {(provided) => (
                                      <tr
                                        key={`tableRow${index}`}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        className="configHover h-8 content-start myRow"
                                      >
                                        <td className="p-0 pl-5 text-left text-sm suite-table-td-height">
                                          <span className="actionIcons" {...provided.dragHandleProps}>
                                            <DragIndicatorOutlined fontSize="small" className="text-blue-600" />{' '}
                                          </span>
                                        </td>
                                        <td className="p-0 pl-5 text-left text-sm suite-table-td-height">
                                          <span
                                            className="text-blue-700 cursor-pointer table-font-style-common fontPoppinsRegularMd font-regular"
                                            onClick={() => {
                                              navigateToDetailsPage(row, index);
                                            }}
                                          >
                                            {' '}
                                            {row.name}{' '}
                                          </span>
                                        </td>
                                        <td className="p-0 pl-5 text-left text-sm suite-table-td-height">
                                          <span className="text-gray-700 table-font-style-common fontPoppinsRegularMd font-regular">
                                            {' '}
                                            {row.noOfScripts}
                                          </span>
                                        </td>
                                        <td className="p-0 pl-5 text-left text-sm suite-table-td-height">
                                          <span className="text-gray-700 table-font-style-common fontPoppinsRegularMd font-regular">
                                            {' '}
                                            {row.access}
                                          </span>
                                        </td>
                                        <td className="p-0 pl-5 text-left table-font-style-common suite-table-td-height">
                                          <span className="text-gray-700 table-font-style-common fontPoppinsRegularMd font-regular">
                                            {' '}
                                            {row.machines.multiple === 'false' ? 'Single machine' : 'Multiple Machine'}
                                          </span>
                                        </td>
                                        <td className="p-0 pl-5 text-left table-font-style-common suite-table-td-height">
                                          <span className="text-gray-700 table-font-style-common fontPoppinsRegularMd font-regular">
                                            {' '}
                                            {row.machines.executionType === 'SEND_SUITE_TO_ALL'
                                              ? 'Parallel'
                                              : row.machines.executionType === 'DISTRIBUTE'
                                                ? 'Distribute'
                                                : 'Sequential'}
                                          </span>
                                        </td>
                                        <td className="p-0 pl-5 text-left table-font-style-common suite-table-td-height">
                                          <span className="actionIcons">
                                            <Tooltip title="Delete">
                                              <AiOutlineDelete
                                                className="float-left cursor-pointer mr-3 text-blue-700 text-base"
                                                onClick={() => {
                                                  setOpen(true);
                                                  setDeleteName(row.name);
                                                }}
                                              />
                                            </Tooltip>
                                          </span>
                                        </td>
                                      </tr>
                                    )}
                                  </Draggable>
                                );
                              })}
                            </tbody>
                          )}
                        </Droppable>
                      </table>
                      {isLoading ? (
                        <CommonLoader />
                      ) : selectedSuiteArray.length === 0 ? (
                        <div className="fontPoppinsRegularMd no-step-exe">
                          <div className="">
                            <p className="font-bold mb-4">Quick Start</p>
                            <p className="mb-4">
                              <span className="font-bold">Step 1 :</span> Click
                              <button
                                type="button"
                                onClick={() => {
                                  openModalfun('create');
                                  setTabContent();
                                }}
                                className="ml-3 mr-3 primary-btn"
                              >
                                + Select Suites
                              </button>
                              to start adding the Suites
                            </p>
                          </div>
                        </div>
                      ) : null}
                    </DragDropContext>
                    <div className="pagination-padding">
                      {selectedSuiteArray.length ? (
                        <Pagination
                          postsPerPage={postsPerPage}
                          totalPosts={selectedSuiteArray.length}
                          paginate={paginate}
                          setImmediatePrevPage={setImmediatePrevPage}
                          indexOfFirstPost={indexOfFirstPost}
                          indexOfLastPost={indexOfLastPost}
                          setImmediateNextPage={setImmediateNextPage}
                          currentPage={currentPage}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <SuiteDetails />
      )}
    </div>
  );
};

export default CreateEditSuiteOfSuites;

import React from 'react';
import Modal from '@material-ui/core/Modal';
import { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { useDropzone } from 'react-dropzone';
import { useFilters, usePagination, useTable } from 'react-table';
import { Tooltip } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { isEmptyValue, validateFileExtension, validateZipFileExtension } from '@src/util/common_utils';
import { INTEGRATION_FILE_ALLOWED } from '@util/validations';
import ReactTable from '@src/pages/common/Table/ReactTable';

let fileNames = [];
let originData = [];
const UploadAppFile = (props) => {
  const { MyAlert } = props;

  const search = window.location.search;
  const instanceUrl = new URLSearchParams(search).get('instanceName');

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState(false);

  const acceptFormat = Object.keys(INTEGRATION_FILE_ALLOWED);

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptFormat,
    multiple: false,
    onDrop: (acceptedFiles) => {
      let dragedFiles = [...selectedFiles];
      if (isEmptyValue(acceptedFiles)) {
        MyAlert.warning('unsupported File');
        return;
      }

      acceptedFiles.forEach((data, index) => {
        if (validateFileExtension(data, INTEGRATION_FILE_ALLOWED) || validateZipFileExtension(data)) {
          if (!fileNames.includes(data.name)) {
            originData.push(data);
            dragedFiles.push({
              app_name: (
                <Tooltip title={data.name}>
                  <div className="max-w-xs truncate">{data.name}</div>
                </Tooltip>
              ),
              action: (
                <button
                  className={`float-left blue configmoreIcon pt-0.5 mr-4 cursor-pointer`}
                  onClick={() => {
                    deletedragedFile(index, data.name);
                  }}
                >
                  <Tooltip title="Delete" placement="top">
                    <DeleteOutlinedIcon className="blue" />
                  </Tooltip>
                </button>
              ),
            });
            fileNames.push(data.name);
          }
        } else {
          setSelectedFiles(null);
          MyAlert.warning(`${data.name} is unsupported`);
        }
      });
      setSelectedFiles(dragedFiles);
    },
  });

  let deletedragedFile = (index, name) => {
    let rawData = [...originData];
    let dragedFiles = [];

    rawData.splice(fileNames.indexOf(name), 1);
    fileNames = [];
    rawData.forEach((data, index) => {
      fileNames.push(data.name);
      dragedFiles.push({
        app_name: data.name,
        action: (
          <button
            className={`float-left blue configmoreIcon pt-0.5 mr-4 cursor-pointer`}
            onClick={() => {
              deletedragedFile(index, data.name);
            }}
          >
            <Tooltip title="Delete" placement="top">
              <DeleteOutlinedIcon className="blue" />
            </Tooltip>
          </button>
        ),
      });
    });

    originData = rawData;
    setSelectedFiles(dragedFiles);
  };

  const handelUploadFiles = () => {
    try {
      setLoadingMessage(true);
      props.uploadFile(originData, instanceUrl);
    } catch (err) {
      console.log(err);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'App Name',
        accessor: 'app_name',
        width: '90%',
      },
      {
        Header: 'Action',
        accessor: 'action',
        width: '10%',
      },
    ],
    []
  );

  const data = React.useMemo(() => selectedFiles, [selectedFiles]);

  const {
    state: {},
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useFilters,
    usePagination
  );

  useEffect(() => {
    fileNames = [];
    originData = [];
  }, []);

  return (
    <>
      <Modal open={showModal} className="modal-dialog">
        <div className="modal-container import_jar_modal">
          <div className="modal-header">
            <h2 className="fontPoppinsMediumLg">Upload App file Here</h2>
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                props.closeModal(false);
                setShowModal(false);
                fileNames = [];
              }}
            >
              <img className="cursor-pointer" src="/assets/images/close_black.svg" alt="" height="25px" width="25px" />
            </IconButton>
          </div>

          <div className="modal-body" id="journal-scroll">
            <div className="flex">
              <span {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <div className="flex-end">
                  <span className="">
                    <button disabled={fileNames.length >= 1 || loadingMessage} className="primary-btn">
                      Select file
                    </button>
                  </span>
                </div>
              </span>
            </div>{' '}
            {loadingMessage ? (
              <div className="base_h flex items-center justify-center">
                <div className="text-center font-bold align-middle empty_page_info -mt-20">
                  <div className="lds-roller">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="page-table-body mt-4 ">
                <div className="configPage upload_file_area" id="journal-scroll">
                  <div className=" grid grid-cols-3 tableRow">
                    <ReactTable data={data} columns={columns} tableHeightClass={'main-table-height'} />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button
              className="gray-btn mr-3"
              onClick={() => {
                props.closeModal(false);
                setShowModal(false);
                fileNames = [];
              }}
            >
              Close
            </button>
            <button
              className="primary-btn  w-25  ml-10 items-center float-right"
              disabled={!fileNames.length}
              onClick={() => {
                handelUploadFiles();
                fileNames = [];
              }}
            >
              Upload
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UploadAppFile;

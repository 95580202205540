import { ReactComponent as AllProjectIcon } from '@assets/side-menu-icons/projects_menu.svg';
import { ReactComponent as DashboardIcon } from '@assets/side-menu-icons/dashboard_menu.svg';
import { ReactComponent as LicenseIcon } from '@assets/side-menu-icons/license_menu.svg';
import { ReactComponent as ConfigIcon } from '@assets/side-menu-icons/config_menu.svg';
import { ReactComponent as ProjectIcon } from '@assets/side-menu-icons/project_menu.svg';
import { ReactComponent as AnalyticsIcon } from '@assets/side-menu-icons/analytics_menu.svg';
import { ReactComponent as DefectIcon } from '@assets/side-menu-icons/defect_menu.svg';
import { ReactComponent as ExecutionIcon } from '@assets/side-menu-icons/execution_menu.svg';
import { ReactComponent as RepositoryIcon } from '@assets/side-menu-icons/repository_menu.svg';
import { ReactComponent as TestDataIcon } from '@assets/side-menu-icons/test_data_menu.svg';
import { ReactComponent as TestDevIcon } from '@assets/side-menu-icons/test_dev_menu.svg';

function SideMenuTitleIconsRenderer(props) {
  switch (props.title) {
    case 'Projects':
      return <AllProjectIcon />;

    case 'Project':
      return <ProjectIcon />;

    case 'Dashboard':
      return <DashboardIcon />;

    case 'Analytics':
      return <AnalyticsIcon />;

    case 'Repository':
      return <RepositoryIcon />;

    case 'Test Data':
      return <TestDataIcon />;

    case 'Test Development':
      return <TestDevIcon />;

    case 'Test Execution':
      return <ExecutionIcon />;

    case 'Defects':
      return <DefectIcon />;

    case 'Licenses':
      return <LicenseIcon />;

    case 'Configuration':
      return <ConfigIcon />;

    default:
      return <></>;
  }
}

export default SideMenuTitleIconsRenderer;

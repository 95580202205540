import { Modal } from '@material-ui/core';
import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { MASK_DATA } from '@src/common/ui-constants';

function VariableDetailsModal(props) {
  const [openModal, setOpenModal] = useState(true);
  return (
    <div>
      <Modal
        open={openModal}
        onClose={() => {
          props.closeModal(false);
          setOpenModal(false);
        }}
        className="modal-dialog"
      >
        <div className="modal-container parameter-details-modal">
          <div className="modal-header">
            <div className="fontPoppinsMediumLg title-trim" title={props?.data?.name}>
              {props.type === 'Parameter'
                ? `Parameter Details - ${props?.data?.name}`
                : `Step Group Variable Details -${props?.data?.name}`}
            </div>
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                props.closeModal(false);
                setOpenModal(false);
              }}
            >
              <img
                style={{
                  cursor: 'pointer',
                }}
                src="/assets/images/close_black.svg"
                alt=""
                height="25px"
                width="25px"
              />{' '}
            </IconButton>
          </div>
          <div className="modal-body">
            <div className="grid grid-cols-2">
              <div className="">
                <div className="grid grid-rows-4 ml-4">
                  <div className="mt-1">
                    <h1 className="details-key-style-common">Name</h1>
                    <p className="pt-2 text-gray-700">
                      <div className="details-data-style-common name-trimmer" title={props?.data?.name}>
                        {props?.data?.name}
                      </div>
                    </p>
                  </div>
                  <div className="mt-4">
                    <h1 className="details-key-style-common">
                      {props?.type === 'Parameter' ? 'Used in Step' : 'Returning Step'}
                    </h1>
                    <p className="pt-2 text-gray-700">
                      <div className="details-data-style-common name-trimmer">
                        {props.type === 'Parameter'
                          ? props?.data?.usedInStep?.length > 0
                            ? props?.data?.usedInStep?.join(', ')
                            : '--'
                          : props?.data?.returningStep
                            ? props?.data?.returningStep
                            : 'N/A'}
                      </div>
                    </p>
                  </div>
                  <div className="mt-5">
                    <h1 className="details-key-style-common">Created On</h1>
                    <p className="text-gray-700 pt-2 ">
                      <span className="details-data-style-common">{props?.data?.createdOn}</span>
                    </p>
                  </div>
                  <div className="mt-8">
                    <h1 className="details-key-style-common">Modified On</h1>
                    <p className="text-gray-700 pt-2 ">
                      <span className="details-data-style-common">{props?.data?.modifiedOn}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="grid grid-rows-4 ml-14">
                  <div className="mt-1">
                    <h1 className="details-key-style-common">{props?.type === 'Parameter' ? 'Type' : 'Value'}</h1>
                    <p className="pt-2 text-gray-700 ">
                      <div
                        className="details-data-style-common name-trimmer"
                        title={
                          props.type === 'Parameter'
                            ? props?.data?.type
                            : props?.data?.value
                              ? !props.data.masked
                                ? props.data.value
                                : ''
                              : 'null'
                        }
                      >
                        {props.type === 'Parameter'
                          ? props?.data?.type
                          : props?.data?.value
                            ? !props.data.masked
                              ? props.data.value
                              :MASK_DATA.VARIABLE_MASK_DATA
                            : 'null'}
                      </div>
                    </p>
                  </div>
                  <div className="mt-4">
                    <h1 className="details-key-style-common">Created by</h1>
                    <p className="text-gray-700 pt-2 ">
                      <div className="details-data-style-common name-trimmer" title={props?.data?.createdByUname}>
                        {props?.data?.createdByUname}
                      </div>
                    </p>
                  </div>
                  <div className="mt-7">
                    <h1 className="details-key-style-common">Modified By</h1>
                    <p className="text-gray-700 pt-2 ">
                      <span className="details-data-style-common">{props?.data?.modifiedByUname}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="">
              <button
                onClick={() => {
                  props.closeModal(false);
                  setOpenModal(false);
                }}
                type="button"
                className="gray-btn"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default VariableDetailsModal;

import { ArrowDropDown, ArrowRight } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import React, { useMemo, useState } from 'react';
import { useFormik } from 'formik';
import Modal from 'react-modal';
import '../test-development.scss';
import Radio from '@mui/material/Radio';
import {
  createExecutionReq,
  createQuickRunSettingsReq,
  getAllMachineReq,
  updateQuickRunSettingsReq,
} from '@api/api_services';
import { machineJson } from '@pages/test-development/script/modules/module/utils/constants';
import { withRouter } from 'react-router';
import DownloadClientModal from '@pages/configuration/system_config/system_config v1.1/modals/download_client_modal';
import { setExecutionTreeWithSelectedNodes } from './create-execution-tree';
import { Select } from '@material-ui/core';
import { getBrowserName, getExecutionName } from './common-methods';
import SystemBaseUI from './quick-run-settings/qrs-system-base';
import { CommonLoader } from '@pages/common/common-loader';
import { useContext } from 'react';
import ProgressContainer, { ProgressContainerContext } from '@src/common/ProgressContainer';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { FormControlLabel, FormControl } from '@material-ui/core';
import CommonButton from '@src/common/button/Button';
import * as yup from 'yup';
import MemoryWarningPopup from '@src/pages/configuration/Memory_Config/MemoryWarningPopup';
import { UI_VALIDATIONS } from '@src/util/validations';
import { FIRECLOUD_DATA} from '@src/common/ui-constants';

Modal.setAppElement('#root');
function QuickRunSettings(props) {
  const theme = createTheme({
    overrides: {
      MuiRadio: {
        root: {
          padding: 4,
        },
      },
    },
  });
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const customStylesPage = {
    overlay: {
      height: 'fit-content !important',
      maxHeight: 'fit-content !important',
    },
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      height: 'fit-content !important',
      maxHeight: '100% !important',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 0px 11px #000000a1',
      borderRadius: '4px',
      opacity: '1',
    },
  };
  const [openModal, setOpenModal] = useState(true);
  const [myAllSystems, setMyAllSystems] = useState([]);
  const [defaultSystem, setDefaultSystem] = useState({});
  const [isSystemLoading, setIsSystemLoading] = useState(true);
  const [runSettingsApiData, setRunSettingsApiData] = useState({});
  const [selectedMachine, setSelectedMachine] = useState(defaultSystem);
  const project = JSON.parse(localStorage.getItem('selected-project'));
  const user = JSON.parse(localStorage.getItem('test-optimize-user'));
  const [memoryWarningPopup, setMemoryWarningPopup] = useState(false);
  const [memorySpillOverData, setMemorySpillOverData] = useState({});

  const [infoBannerVisible, setInfoBannerVisible] = useState(false);
  const viewInfoBanner = (value = false) => {
    setInfoBannerVisible(value);
  };
  const [showSystemSection, setShowSystemSection] = useState(true);
  const [showStepSection, setShowStepSection] = useState(false);
  const [showResultSection, setShowResultSection] = useState(false);
  const [downloadClientModal, setDownloadClientModal] = useState(false);
  const [webOsDataValue, setWebOsDataValue] = useState();
  const [androidDeviceValue, setAndroidDeviceData] = useState();
  const [iosDeviceValue, setIosDeviceValue] = useState();
  const [runbuttonClicked, setRunButtonClicked] = useState(false);
  const [runButtonDisabled, setRunButtonDisabled] = useState(false);
  const [quickRunButtonDisabled, setQuickRunButtonDisabled] = useState(false);
  const viewStep = () => {
    setShowStepSection(!showStepSection);
  };
  const viewResult = () => {
    setShowResultSection(!showResultSection);
  };
  const viewSystem = () => {
    setShowSystemSection(!showSystemSection);
  };
  const getMachineData = async () => {
    try {
      resetProgress();
      startOrResumeProgress({
        stopAt: 90,
      });
      const responseData = await getAllMachineReq(user.id);
         const response = responseData.data.responseMap.userSystems.filter(
          (resp) =>(resp?.executionEnvironment?.toLowerCase() === FIRECLOUD_DATA.FIRE_CLOUD && responseData.data.responseMap.firecloudEnabled === true)|| resp?.executionEnvironment?.toLowerCase() !== FIRECLOUD_DATA.FIRE_CLOUD
        );
        responseData.data.responseMap['userSystems'] = response;
      stopProgress();
      const {
        data: {
        responseMap: {userSystems, projectQuickRunSetting },
        },
      } = responseData || {};
      if (userSystems) {
        if (projectQuickRunSetting) {
          const { waitConfiguration, results, defaultMachine } = projectQuickRunSetting;
          setRunSettingsApiData(projectQuickRunSetting);
          const {
            delayBetweenSteps,
            delayBetweenStepsUnit,
            implicitWait,
            implicitWaitUnit,
            explicitWait,
            explicitWaitUnit,
          } = waitConfiguration || {};
          formikDetails.setFieldValue('delayBetweenSteps', delayBetweenSteps);
          formikDetails.setFieldValue('delayBetweenStepsType', delayBetweenStepsUnit);
          formikDetails.setFieldValue('implicitWait', implicitWait);
          formikDetails.setFieldValue('implicitWaitType', implicitWaitUnit);
          formikDetails.setFieldValue('explicitWait', explicitWait);
          formikDetails.setFieldValue('explicitWaitType', explicitWaitUnit);
          formikDetails.setFieldValue('captureScreenshots', results?.captureScreenShotStatus);
          formikDetails.setFieldValue('captureVideo', results?.captureVideoStatus);
          formikDetails.setFieldValue('defaultSettings', !(defaultMachine && props.nodeSelected));
          setDefaultSystem(defaultMachine ? { ...defaultMachine } : { ...machineJson });
          setSelectedMachine({ ...defaultMachine });
          if (defaultMachine && props.nodeSelected) {
            viewInfoBanner(true);
          }
        }
        const a = Array.from(userSystems);
        setMyAllSystems(a);
      }
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 10,
      });
      setIsSystemLoading(false);
    } catch (err) {
      resetProgress();
      console.error('GET_ALL_CLIENT_SYSTEMS :', err);
    }
  };

  let initialValues = {
    delayBetweenSteps: 0,
    delayBetweenStepsType: 'milliseconds',
    implicitWait: UI_VALIDATIONS.WAIT_TIME_CONFIGURATION,
    implicitWaitType: 'milliseconds',
    explicitWait: UI_VALIDATIONS.WAIT_TIME_CONFIGURATION,
    explicitWaitType: 'milliseconds',
    captureScreenshots: 'failedSteps',
    captureVideo: 'notCapture',
    displayInfoLogs: true,
    defaultSettings: props.nodeSelected ? false : true,
  };
  const onSubmit = (values) => {
    const {
      delayBetweenSteps,
      delayBetweenStepsType,
      implicitWait,
      implicitWaitType,
      explicitWait,
      explicitWaitType,
      captureScreenshots,
      captureVideo,
      displayInfoLogs,
      defaultSettings,
    } = values || {};
    // if(((selectedMachine?.executionEnvironment !== "Local") && (webOsDataValue?.selectedOption?.length === 0 || androidDeviceValue?.selectedOption?.length === 0 || iosDeviceValue?.selectedOption?.length === 0)) || (selectedMachine?.executionEnvironment === "Local" && project?.type === 'Web & Mobile' && (androidDeviceValue?.selectedOption?.length === 0 || iosDeviceValue?.selectedOption?.length === 0))) {
    //     setOpenModal(true);
    //   } else {
    if (values) {
      let requestBody = {
        projectId: project.id,
        defaultMachine: selectedMachine,
        waitConfiguration: {
          delayBetweenSteps: delayBetweenSteps,
          delayBetweenStepsUnit: delayBetweenStepsType,
          implicitWait: implicitWait,
          implicitWaitUnit: implicitWaitType,
          explicitWait: explicitWait,
          explicitWaitUnit: explicitWaitType,
        },
        results: {
          captureScreenShotStatus: captureScreenshots,
          captureVideoStatus: captureVideo,
          showErrorLogs: true,
          showInfoLogs: displayInfoLogs,
          makeSettingsDefault: defaultSettings,
        },
      };

      if (defaultSettings) {
        if (props.nodeSelected) {
          if (runSettingsApiData.qId) {
            requestBody = {
              ...requestBody,
              createdBy: runSettingsApiData.createdBy,
              createdByUname: runSettingsApiData.createdByUname,
              createdOn: runSettingsApiData.createdOn,
              qId: runSettingsApiData.qId,
            };
            updateQuickRunSettingsReq(user.id, runSettingsApiData.qId, requestBody);
          } else {
            createQuickRunSettingsReq(user.id, requestBody);
          }

          if (props.qrsRun) {
            props.clearAllResults();
            props.qrsRun(false, [], [], [], [], requestBody);
            props.updateDefaultMachine(requestBody);
            setOpenModal(false);
            props.closeModal(false);
          } else {
            run(values, false);
            props.updateDefaultMachine();
          }
        } else {
          if (runSettingsApiData.qId) {
            requestBody = {
              ...requestBody,
              createdBy: runSettingsApiData.createdBy,
              createdByUname: runSettingsApiData.createdByUname,
              createdOn: runSettingsApiData.createdOn,
              qId: runSettingsApiData.qId,
            };
            updateRunSettings(user.id, runSettingsApiData.qId, requestBody);
          } else {
            createRunSettings(user.id, requestBody);
          }
        }
      } else {
        if (props.qrsRun) {
          props.clearAllResults();
          props.qrsRun(false, [], [], [], [], requestBody);
          setOpenModal(false);
          props.closeModal(false);
        } else {
          run(values, false);
        }
      }
    }
    //    }
  };
  const validationSchema = yup.object({
    explicitWait: yup.number().when('explicitWaitType', {
      is: (v) => v === 'milliseconds',
      then: yup
        .number()
        .required('explicit wait is required')
        .min(0, 'Value less than 0 not allowed')
        .max(999000, 'Value greater than 999000 not allowed'),
      otherwise: yup
        .number()
        .required('explicit wait is required')
        .min(0, 'Value less than 0 not allowed')
        .max(999, 'Value greater than 999 not allowed'),
    }),
    implicitWait: yup.number().when('implicitWaitType', {
      is: (v) => v === 'milliseconds',
      then: yup
        .number()
        .required('implicit wait is required')
        .min(0, 'Value less than 0 not allowed')
        .max(999000, 'Value greater than 999000 not allowed'),
      otherwise: yup
        .number()
        .required('implicit wait is required')
        .min(0, 'Value less than 0 not allowed')
        .max(999, 'Value greater than 999 not allowed'),
    }),
    delayBetweenSteps: yup.number().when('delayBetweenStepsType', {
      is: (v) => v === 'milliseconds',
      then: yup
        .number()
        .required('delay between steps  is required')
        .min(0, 'Value less than 0 not allowed')
        .max(99000, 'Value greater than 99000 not allowed'),
      otherwise: yup
        .number()
        .required('delay between steps  is required')
        .min(0, 'Value less than 0 not allowed')
        .max(99, 'Value greater than 99 not allowed'),
    }),
  });

  const handleUnit = (unit) => {
    if (unit === 'implicit') {
      formikDetails.setFieldValue('implicitWait', UI_VALIDATIONS.WAIT_TIME_CONFIGURATION);
    } else if (unit === 'explicit') {
      formikDetails.setFieldValue('explicitWait', UI_VALIDATIONS.WAIT_TIME_CONFIGURATION);
    } else {
      formikDetails.setFieldValue('delayBetweenSteps', 0);
    }
  };

  const formikDetails = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  function handleSelectedMachine(machine) {
    setSelectedMachine(machine);
    setDefaultSystem(machine);
  }
  function closeModal(val) {
    setDownloadClientModal(val);
  }
  async function run(ipVal, deleteSpillOverMemory = false) {
    let exeJson = setExecutionTreeWithSelectedNodes(props.selectedNodes);
    let rootModule = {
      key: props.rootData?.key,
      title: props.rootData?.title,
      type: 'Module',
      children: exeJson,
    };
    let executionDataJson = {};
    const {
      captureScreenshots,
      captureVideo,
      delayBetweenSteps,
      delayBetweenStepsType: delayBetweenStepsUnit,
      implicitWait,
      implicitWaitType: implicitWaitUnit,
      explicitWaitType,
      explicitWait,
    } = ipVal || {};
    let browserInfo = selectedMachine.systemConfigDetails.find((item) => item.subType === 'browser');
    let deviceInfo = selectedMachine.systemConfigDetails.filter((item) => item.subType === 'device');
    let sshotStatus = captureScreenshots === 'notCapture' ? false : true;
    executionDataJson = {
      name: getExecutionName([rootModule]),
      executionTree: [rootModule],
      installedDir: selectedMachine.installedDir,
      screenshotRequired: sshotStatus,
      failedStepsScreenshot: captureScreenshots === 'failedSteps',
      videoRequired: captureVideo === 'notCapture' ? false : true,
      failedScriptVideoRequired: ['notCapture', 'allSteps'].includes(captureVideo) ? false : true,
      waitTime: {
        implicitWait,
        implicitWaitUnit,
        explicitlyWait: explicitWait,
        explicitlyWaitUnit: explicitWaitType,
        delayBetweenSteps,
        delayBetweenStepsUnit,
      },
      selectedSystem: [
        {
          clientId: selectedMachine.clientId,
          machineInstances: [
            {
              clientId: selectedMachine.clientId,
              executionEnv: selectedMachine.executionEnvironment,
              browserName: browserInfo?.name ? getBrowserName(browserInfo.name) : null,
              numberOfRuns: 0,
              version: browserInfo?.version ? browserInfo.version : null,
              systemUrl: selectedMachine?.machine?.hostName,
              browserInfo: browserInfo ? { ...browserInfo } : null,
              deviceInfo: [...deviceInfo],
              headless: props.disableHeadless ? false : selectedMachine?.headless || false,
              machineInfo: {
                osName: selectedMachine?.machine?.osName,
                osVersion: selectedMachine?.machine?.osVersion,
                hostName: selectedMachine?.machine?.hostName,
              },
            },
          ],
          executionEnv: selectedMachine.executionEnvironment,
          browserName: browserInfo?.name ? getBrowserName(browserInfo.name) : null,
          version: browserInfo?.version ? browserInfo.version : null,
          systemUrl: selectedMachine?.machine?.hostName,
          browserInfo: browserInfo ? { ...browserInfo } : null,
          deviceInfo: [...deviceInfo],
          headless: props.disableHeadless ? false : selectedMachine?.headless || false,
          machineInfo: {
            osName: selectedMachine?.machine?.osName,
            osVersion: selectedMachine?.machine?.osVersion,
            hostName: selectedMachine?.machine?.hostName,
          },
        },
      ],
      projectName: project.name,
      projectId: project.id,
      type: 'INDIVIDUAL',
      deleteSpillOverMemory,
    };
    const executionResponse = await createExecutionReq(executionDataJson);
    const message = executionResponse?.data?.message?.toLowerCase();
    if (executionResponse && executionResponse.data && executionResponse.data.responseObject) {
      if (props.hasAutomationManual) {
        props.history.push(`/testdevelopment/Execution Dashboard/true`);
      } else {
        props.history.push(`/testdevelopment/Execution Dashboard`);
      }
    } else if (
      message.includes('please connect a device') ||
      message.includes('please install a browser') ||
      (message.startsWith('client') && message.includes('is not available'))
    ) {
      props.MyAlert.info(executionResponse.data.message);
    } else if (executionResponse.data && executionResponse.data.responseCode === 400) {
      props.MyAlert.warning(`${executionResponse.data.message}`);
    } else if (executionResponse.data && executionResponse.data.status === 'FAILURE') {
      props.MyAlert.info(executionResponse?.data?.message);
    } else if (executionResponse.data && executionResponse.data.responseCode === 400) {
      props.MyAlert.warning(`${executionResponse.data.message}`);
    } else if (executionResponse?.data?.message === 'Consumed Memory is above 85%') {
      setMemorySpillOverData(ipVal);
      setMemoryWarningPopup(true);
    } else {
      setOpenModal(false);
      props.closeModal(false);
    }
  }

  async function createRunSettings(usrId, payload) {
    try {
      const response = await createQuickRunSettingsReq(usrId, payload);
      if (response?.data?.responseObject) {
        props.MyAlert.success(`Run settings created successfully`);
        setOpenModal(false);
        props.updateDefaultMachine();
        props.closeModal(false);
      } else {
        props.MyAlert.warning(`${response.data?.message}`);
        setOpenModal(false);
        props.closeModal(false);
      }
    } catch (err) {
      console.error('CREATE_QRS : ', err);
    }
  }

  async function updateRunSettings(usrId, runSetId, payload) {
    try {
      const response = await updateQuickRunSettingsReq(usrId, runSetId, payload);
      if (response?.data?.responseObject) {
        props.MyAlert.success(`Run settings updated successfully`);
        setOpenModal(false);
        props.updateDefaultMachine();
        props.closeModal(false);
      } else {
        props.MyAlert.warning(`${response?.data?.message}`);
        setOpenModal(false);
        props.closeModal(false);
      }
    } catch (err) {
      console.error('UPDATE_QRS : ', err);
    }
  }
  React.useEffect(() => {
    getMachineData();
  }, []);

  const disableRunBtn = useMemo(() => {
    return !(
      selectedMachine?.clientId &&
      parseInt(formikDetails?.values?.delayBetweenSteps) >= 0 &&
      parseInt(formikDetails?.values?.implicitWait) >= 0 &&
      parseInt(formikDetails?.values?.explicitWait) >= 0
    );
  }, [
    selectedMachine?.clientId,
    formikDetails?.values?.delayBetweenSteps,
    formikDetails?.values?.implicitWait,
    formikDetails?.values?.explicitWait,
  ]);
  const captureScreenshotOptions = [
    { value: 'failedSteps', label: 'For failed steps only' },
    { value: 'allSteps', label: 'For all steps' },
    { value: 'notCapture', label: "Don't capture" },
  ];

  const captureVideoOptions = [
    { value: 'failedSteps', label: 'For failed scripts only' },
    { value: 'allSteps', label: 'For all scripts' },
    { value: 'notCapture', label: "Don't capture" },
  ];

  const renderRadioButtons = (options, name) => {
    return options.map((option) => (
      <FormControlLabel
        key={option.value}
        checked={formikDetails.values[name] === option.value}
        onChange={formikDetails.handleChange}
        value={option.value}
        name={name}
        inputProps={{ 'aria-label': option.label }}
        control={<Radio />}
        label={option.label}
      />
    ));
  };

  const handleEscapeClose = () => {
    setOpenModal(false);
    props.closeModal(false);
  };

  return (
    <div className="">
      <Modal
        isOpen={openModal}
        className="test-developement popup-height-runsettings"
        style={customStylesPage}
        onRequestClose={handleEscapeClose}
      >
        {memoryWarningPopup && (
          <MemoryWarningPopup
            handleCancel={() => setMemoryWarningPopup(false)}
            handleContinue={() => run(memorySpillOverData, true)}
            isOpen={memoryWarningPopup}
          />
        )}
        <div className="qrs-modal-header">
          <span className="qrs-header-title ml-3"> {props.title}</span>
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="float-right mr-4 text-white hover:ring-2 ring-white focus:ring-2 rounded-full"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <ProgressContainer>
          <form onSubmit={formikDetails.handleSubmit} noValidate>
            <div id="journal-scroll" className="overflow-y-auto mt-1.5 mb-4 fontPoppinsRegularMd qrs_modal_body">
              {infoBannerVisible && (
                <div className="bg-gray-100 mb-2">
                  <div className=" h-10 p-2 pl-4">
                    <InfoIcon className="text-blue-700" />
                    <span className="pl-3 text-sm">
                      <span className="font-bold text-base">Information.</span> This settings is specific to this
                      execution only
                    </span>
                    <CommonButton
                      btnType="secondary"
                      label="Dismiss"
                      className="float-right mr-3"
                      onClick={() => {
                        viewInfoBanner(false);
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="">
                <div className="qrs-environment-section">
                  <span className="fontPoppinsSemiboldMd qrs-environment-title">
                    Environment
                    {showSystemSection ? (
                      <span onClick={viewSystem}>
                        <ArrowDropDown fontSize="large" color="primary" />
                      </span>
                    ) : (
                      <span onClick={viewSystem}>
                        <ArrowRight fontSize="large" color="primary" />
                      </span>
                    )}
                  </span>
                </div>
                {showSystemSection &&
                  (!isSystemLoading ? (
                    <SystemBaseUI
                      systems={myAllSystems}
                      defaultMachine={defaultSystem}
                      handleSelectedMachine={handleSelectedMachine}
                      project={project}
                      browserInstanceArray={props.browserInstanceArray}
                      sauceLabInstanceArray={props.sauceLabInstanceArray}
                      lambdaTestsInstanceArray={props.lambdaTestsInstanceArray}
                      disableHeadless={props.disableHeadless}
                      webOSDataPassToParent={setWebOsDataValue}
                      androidDataPassToParent={setAndroidDeviceData}
                      iosDataPassToParent={setIosDeviceValue}
                      runButtonClicked={runbuttonClicked}
                      setRunButtonClickedAction={setRunButtonClicked}
                      runButtonDisabled={setRunButtonDisabled}
                      quickRunButtonDisabled={setQuickRunButtonDisabled}
                      setDownloadClientModal={setDownloadClientModal}
                    />
                  ) : (
                    <div className="m-2 qrs-system-grid-container grid grid-cols-4 gap-2.5 px-2 py-1">
                      <div className="qrs-details-div allign-div-center col-span-4">
                        <CommonLoader />
                      </div>
                    </div>
                  ))}
              </div>
              <div className="border border-white">
                <div className="qrs-environment-section p-1">
                  <span className="fontPoppinsSemiboldMd qrs-environment-title">
                    Wait Time
                    {showStepSection ? (
                      <span onClick={viewStep}>
                        <ArrowDropDown fontSize="large" />
                      </span>
                    ) : (
                      <span onClick={viewStep}>
                        <ArrowRight fontSize="large" />
                      </span>
                    )}
                  </span>
                </div>
                {showStepSection ? (
                  <div className="rounded shadow-md white-bg-td p-2 m-4 flex">
                    <div className="flex-auto w-20">
                      <label htmlFor="delayBetweenSteps" className="input-filed-label-style-common block">
                        Delay Between Steps
                      </label>
                      <input
                        autoComplete="off"
                        type="number"
                        value={formikDetails.values.delayBetweenSteps}
                        name="delayBetweenSteps"
                        id="delayBetweenSteps"
                        onBlur={formikDetails.handleBlur}
                        onChange={formikDetails.handleChange}
                        className="h-8 p-px text-xs"
                        min="0"
                        max={formikDetails.values.delayBetweenStepsType === 'milliseconds' ? '99000' : '99'}
                        onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                      />
                      <Select
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                        }}
                        name="delayBetweenStepsType"
                        value={formikDetails.values.delayBetweenStepsType}
                        id="delayBetweenStepsType"
                        onBlur={formikDetails.handleBlur}
                        onChange={(e) => {
                          formikDetails.handleChange(e);
                          handleUnit('delaybetweensteps');
                        }}
                        className="text-sm border-none ml-3"
                      >
                        <option
                          className="text-xs hover:text-blue-700 cursor-pointer hover:bg-blue-100 fontPoppinsRegularSm"
                          value="milliseconds"
                        >
                          MilliSeconds
                        </option>
                        <option
                          className="text-xs hover:text-blue-700 cursor-pointer hover:bg-blue-100 fontPoppinsRegularSm"
                          value="seconds"
                        >
                          Seconds
                        </option>
                      </Select>
                      {formikDetails.errors.delayBetweenSteps && formikDetails.touched.delayBetweenSteps ? (
                        <div className="errorMessage md:mt-1">{formikDetails.errors.delayBetweenSteps}</div>
                      ) : null}
                    </div>
                    <div className="flex-auto w-20">
                      <label htmlFor="implicitWait" className="input-filed-label-style-common block">
                        Implicit Wait
                      </label>
                      <input
                        autoComplete="off"
                        type="number"
                        value={formikDetails.values.implicitWait}
                        name="implicitWait"
                        id="implicitWait"
                        onBlur={formikDetails.handleBlur}
                        onChange={formikDetails.handleChange}
                        className="h-8 p-px text-xs"
                        min="0"
                        max={formikDetails.values.implicitWaitType === 'milliseconds' ? '999000' : '999'}
                        onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                      />
                      <Select
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                        }}
                        name="implicitWaitType"
                        value={formikDetails.values.implicitWaitType}
                        id="implicitWaitType"
                        onBlur={formikDetails.handleBlur}
                        onChange={(e) => {
                          formikDetails.handleChange(e);
                          handleUnit('implicit');
                        }}
                        className="text-sm border-none ml-3"
                      >
                        <option
                          className="text-xs hover:text-blue-700 cursor-pointer hover:bg-blue-100 fontPoppinsRegularSm"
                          value="milliseconds"
                        >
                          MilliSeconds
                        </option>
                        <option
                          className="text-xs hover:text-blue-700 cursor-pointer hover:bg-blue-100 fontPoppinsRegularSm"
                          value="seconds"
                        >
                          Seconds
                        </option>
                      </Select>
                      {formikDetails.errors.implicitWait && formikDetails.touched.implicitWait ? (
                        <div className="errorMessage md:mt-1">{formikDetails.errors.implicitWait}</div>
                      ) : null}
                    </div>
                    <div className="flex-auto w-20">
                      <label htmlFor="explicitWait" className="input-filed-label-style-common block">
                        Explicit Wait
                      </label>
                      <input
                        autoComplete="off"
                        type="number"
                        value={formikDetails.values.explicitWait}
                        name="explicitWait"
                        id="explicitWait"
                        onBlur={formikDetails.handleBlur}
                        onChange={formikDetails.handleChange}
                        className="h-8 p-px text-xs"
                        min="0"
                        max={formikDetails.values.explicitWaitType === 'milliseconds' ? '999000' : '999'}
                        onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                      />
                      <Select
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                        }}
                        name="explicitWaitType"
                        value={formikDetails.values.explicitWaitType}
                        id="explicitWaitType"
                        onBlur={formikDetails.handleBlur}
                        onChange={(e) => {
                          formikDetails.handleChange(e);
                          handleUnit('explicit');
                        }}
                        className="text-sm border-none ml-3"
                      >
                        <option
                          className="text-xs hover:text-blue-700 cursor-pointer hover:bg-blue-100 fontPoppinsRegularSm"
                          value="milliseconds"
                        >
                          MilliSeconds
                        </option>
                        <option
                          className="text-xs hover:text-blue-700 cursor-pointer hover:bg-blue-100 fontPoppinsRegularSm"
                          value="seconds"
                        >
                          Seconds
                        </option>
                      </Select>
                      {formikDetails.errors.explicitWait && formikDetails.touched.explicitWait ? (
                        <div className="errorMessage md:mt-1">{formikDetails.errors.explicitWait}</div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="border border-white mb-1">
                <div className="qrs-environment-section p-1">
                  <span className="qrs-environment-title fontPoppinsSemiboldMd">
                    Result Settings
                    {showResultSection ? (
                      <span onClick={viewResult}>
                        <ArrowDropDown fontSize="large" />
                      </span>
                    ) : (
                      <span onClick={viewResult}>
                        <ArrowRight fontSize="large" />
                      </span>
                    )}
                  </span>
                </div>
                {showResultSection ? (
                  <ThemeProvider theme={theme}>
                    <div className="rounded white-bg-td shadow-md m-2 pl-2 pb-4">
                      <div className="flex flex-row justify-between">
                        <div className="text-sm mt-3">
                          <label className="my-3 input-filed-label-style-common">Capture screenshot in result</label>
                          <div className="mb-2.5"></div>

                          <FormControl>
                            <div className="flex">
                              {renderRadioButtons(captureScreenshotOptions, 'captureScreenshots')}
                            </div>
                          </FormControl>
                        </div>
                        <div className="text-sm  mt-3">
                          <label className="ml-3 input-filed-label-style-common">Capture video in result</label>
                          <div className="mb-2.5"></div>
                          <div className="flex">{renderRadioButtons(captureVideoOptions, 'captureVideo')}</div>
                        </div>
                        <div className="text-sm mt-3 mr-0.5">
                          <label className="input-filed-label-style-common">Display logs in result</label>
                          <div className="mt-4">
                            <input checked={true} className="rounded opacity-50" disabled={true} type="checkbox" />
                            <label className="text-black ml-2 text-xs ">Error logs</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ThemeProvider>
                ) : null}
              </div>
            </div>
            <div className="flex mb-5 float-right">
              <div className="">
                <input
                  type="checkbox"
                  disabled={!props.nodeSelected}
                  onBlur={formikDetails.handleBlur}
                  onChange={(e) => {
                    formikDetails.handleChange(e);
                    viewInfoBanner(!e.target.checked);
                  }}
                  checked={formikDetails.values.defaultSettings}
                  name="defaultSettings"
                  className={!props.nodeSelected ? 'opacity-50 text-xs rounded' : 'text-xs rounded'}
                />
                <label htmlFor="default-settings" className="ml-2 fontPoppinsRegularSm">
                  Make these settings as default
                </label>
              </div>
              <CommonButton
                className="ml-4 inline-flex justify-center"
                label="Cancel"
                btnType="secondary"
                onClick={() => {
                  setOpenModal(false);
                  props.closeModal(false);
                }}
              />
              <CommonButton
                type="submit"
                disabled={runButtonDisabled || !selectedMachine?.clientId || disableRunBtn}
                label={formikDetails.values.defaultSettings ? (props.nodeSelected ? 'Save and Run' : 'Save') : 'Run'}
                // onClick={() => {setRunButtonClicked(true)}}
                btnType="primary"
                className="ml-4 mr-4"
              />
            </div>
          </form>
          {downloadClientModal && <DownloadClientModal closeModal={closeModal} />}
        </ProgressContainer>
      </Modal>
    </div>
  );
}

export default withRouter(QuickRunSettings);

import React, { useState, useEffect, Fragment, useContext } from 'react';
import StepGroupVariableModal from '../modals/step_group_variable_modal';
import ReactTable from '@src/pages/common/Table/ReactTable';
import {
  deleteStepGroupVariable,
  getAllStepGroupVariable,
  getStepGroupVariable,
  getSingleUserRequest,
} from '@api/api_services';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { Tooltip } from '@material-ui/core';
import DeleteModal from '@pages/common/delete_modal';
import VariableDetailsModal from '../modals/variable_details_modal';
import { DeleteOutlined, EditOutlined } from '@material-ui/icons';
import UserDetailsModal from '../../../../test-development/shared/user-details-modal';
import { CommonLoader } from '@src/pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import SearchBar from '@src/pages/analytics/common/search-bar';
import { HighlightText, isEmptyValue, isValueIncludes } from '@util/common_utils';
import NoResultsFound from '@src/pages/common/Table/NoResultFound';
import { MASK_DATA } from '@src/common/ui-constants';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
function StepGroupVariable(props) {
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [openStepGroupVariableModal, setOpenStepGroupVariableModal] = useState(false);
  const [stepGroupVariableData, setStepGroupVariableData] = useState();
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [mode, setMode] = useState('ADD');
  const [variable, setVariable] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [message, setMessage] = useState('');
  const [reloadTree, setReloadTree] = useState(true);
  const [userDetails, setUserDetails] = React.useState([]);
  let [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);
  let [isHighlight, setIsHighlight] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');

  const search = window.location.search;
  const id = new URLSearchParams(search).get('id');
  const parentId = new URLSearchParams(search).get('parentId');
  const defaultStepGroup = new URLSearchParams(search).get('defaultStepGroup');

  useEffect(() => {
    if (reloadTree) {
      getStepGroupVariableList();
    }
    setReloadTree(false);
  }, [reloadTree]);

  const reloadTreeData = (value) => {
    setReloadTree(value);
  };
  const getStepGroupVariableList = () => {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    setIsLoading(true);
    getAllStepGroupVariable(parentId, id)
      .then((response) => {
        stopProgress();
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 10,
        });
        if (response.data.responseObject) {
          setVariable(response.data.responseObject);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setVariable([]);
        }
      })
      .catch((err) => {
        resetProgress();
        console.error('GET_ALL_STEP_GROUP_VARIABLES : ', err);
      });
  };

  const deleteVariable = () => {
    deleteStepGroupVariable(stepGroupVariableData.id, stepGroupVariableData.name)
      .then((response) => {
        if (response.data.responseCode === 200) {
          setOpenDeleteModal(false);
          reloadTreeData(true);
          props.MyAlert.success(response.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const openModifiedBy = (value) => {
    getSingleUserRequest(value.modifiedBy).then((results) => {
      if (results.data.responseCode === 200 && results.data.responseObject) {
        setUserDetails(results.data.responseObject);
        setOpenUserDetailsModal(true);
      }
    });
  };

  // TODO :: handle Search filter :: START

  const [userSearched, setuserSearched] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(variable);
    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [variable]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(variable);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let [openSearch, setOpenSearch] = useState(false);
  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setSearchTextHighlight('');
    setData(variable);
    setIsHighlight(!isHighlight);
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = variable.filter(({ name, modifiedByUname }) => {
          return isValueIncludes(e, name) || isValueIncludes(e, modifiedByUname);
        });
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
        setData(newData);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  // TODO :: handle Search filter :: END

  const columns = React.useMemo(
    () => [
      {
        Header: 'NAME',
        accessor: 'name',
        disableFilters: true,
        Cell: (e) => (
          <div
            title={e.value}
            className="text-blue-700  float-left ml-2 cursor-pointer table-font-style-common name-trimmer"
            onClick={() => {
              getStepGroupVariable(e.row.original.id).then((response) => {
                setStepGroupVariableData(response.data.responseObject);
                setOpenDetailsModal(true);
              });
            }}
          >
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'VALUE',
        accessor: 'value',
        disableFilters: true,
        Cell: ({ value, ...e }) => {
          return (
            <Tooltip title={!e.cell.row.original.masked ? value : ''} placement="top">
              <div className="table-non-link-color-common float-left ml-3 table-font-style-common">
                {value.length >= 1 ? (
                  value.length >= 33 ? (
                    <div className="lengthyChar">{!e.cell.row.original.masked ? value : MASK_DATA.VARIABLE_MASK_DATA}</div>
                  ) : (
                    <div>{!e.cell.row.original.masked ? value : MASK_DATA.VARIABLE_MASK_DATA}</div>
                  )
                ) : (
                  <div>NULL</div>
                )}
              </div>
            </Tooltip>
          );
        },
      },
      {
        Header: 'RETURNING STEP',
        accessor: 'returningStep',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common float-left ml-3 table-font-style-common">
              {isEmptyValue(value) ? 'N/A' : value}
            </div>
          );
        },
      },
      {
        Header: 'MODIFIED BY',
        accessor: 'modifiedByUname',
        disableFilters: true,

        Cell: (e) => (
          <div
            onClick={() => {
              openModifiedBy(e.cell.row.original);
            }}
            className="hyper_link_color fontPoppinsRegularMd cursor-hand  float-left table-font-style-common text-blue-700"
          >
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'ACTIONS',
        accessor: 'Actions',
        disableFilters: true,
        Cell: (e) => {
          return (
            <div className="table-non-link-color-common rs-icon-alignment-font-style">
              <div className="actionIcons">
                {isEditable && (
                  <>
                    <Tooltip title="Edit">
                      <EditOutlined
                        className="float-left cursor-pointer text-base mr-3 text-blue-700"
                        onClick={() => {
                          setMode('Edit');
                          setStepGroupVariableData(e.row.original);
                          setOpenStepGroupVariableModal(true);
                        }}
                      />
                    </Tooltip>
                    {hasFullAccess && (
                      <Tooltip title="Delete">
                        <DeleteOutlined
                          className="float-left cursor-pointer  mr-3 text-blue-700"
                          onClick={() => {
                            setStepGroupVariableData(e.row.original);
                            setMessage(`${e.row.original.name} Step Group Variable`);
                            setOpenDeleteModal(true);
                          }}
                        />
                      </Tooltip>
                    )}
                  </>
                )}
                <Tooltip title="More">
                  <div className="inline-block">
                    <Menu as="div" className="relative inline-block text-left mt-px">
                      {({ open }) => (
                        <>
                          <div>
                            <Menu.Button className="bg-gray-100 rounded-full flex items-center  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 focus:ring-indigo-500">
                              <span className="sr-only">Open options</span>

                              <DotsVerticalIcon className="h-5 w-5 configmoreIcon" aria-hidden="true" />
                            </Menu.Button>
                          </div>

                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'block px-4 py-2 text-sm w-full text-left'
                                      )}
                                      onClick={() => {
                                        getStepGroupVariable(e.row.original.id).then((response) => {
                                          setStepGroupVariableData(response.data.responseObject);
                                          setOpenDetailsModal(true);
                                        });
                                      }}
                                    >
                                      Details
                                    </button>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </div>
                </Tooltip>
              </div>
            </div>
          );
        },
      },
    ],
    [isHighlight]
  );

  const closeModal = (value) => {
    if (openStepGroupVariableModal) {
      setOpenStepGroupVariableModal(value);
    } else if (openDeleteModal) {
      setOpenDeleteModal(false);
    } else if (openDetailsModal) {
      setOpenDetailsModal(false);
    } else if (openUserDetailsModal) {
      setOpenUserDetailsModal(false);
    }
  };

  const { isEditable, hasFullAccess } = React.useMemo(
    () => ({
      isEditable: window.permission?.isEditAccess('repository'),
      hasFullAccess: window.permission?.isFullAccess('repository'),
    }),
    []
  );

  return (
    <div className="content-wrapper">
      <div className="content_area_style" id="journal-scroll">
        <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
          <label className="project_label text-sm flex-auto">
            Step Group Variables
            <span className="proj-count-style px-2.5 py-1 ml-2 font-bold rounded-lg">
              {data?.length ? data.length : 0}
            </span>
          </label>
          {isEditable && (
            <div className="pr-4">
              <div className="flex flex-row items-center">
                <SearchBar
                  open={openSearch}
                  searchValue={userSearched}
                  handleSearch={handleSearch}
                  onSearchClose={handleSearchClose}
                  handleSearchOpen={handleSearchOpen}
                  enterKeyHandler={handleSearchSubmit}
                  disable={isEmptyValue(variable)}
                />

                <div className="ml-auto pl-2">
                  <button
                    className="ml-3 primary-btn"
                    onClick={() => {
                      setOpenStepGroupVariableModal(true);
                      setMode('ADD');
                    }}
                    disabled={defaultStepGroup === 'false' || defaultStepGroup === 'null' ? false : true}
                  >
                    + Variable
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="table-height overflow-auto" id="journal-scroll">
          <ReactTable data={data} columns={columns} tableHeightClass={'main-table-height'} />
          {isLoading ? (
            <CommonLoader />
          ) : isEmptyValue(variable) ? (
            <div className="fontPoppinsRegularMd no-steps">
              <div className="">
                <p className="font-bold mb-4">{isEditable ? 'Quick Start' : 'No Step Group Variables!!'}</p>
                {isEditable && (
                  <p className="mb-4">
                    <span className="font-bold">Step 1 :</span> Click
                    <button
                      onClick={() => {
                        setOpenStepGroupVariableModal(true);
                        setMode('ADD');
                      }}
                      className="ml-3 mr-3 primary-btn"
                      disabled={defaultStepGroup === 'false' || defaultStepGroup === 'null' ? false : true}
                    >
                      + Variable
                    </button>
                    to start adding the Step Group Variables
                  </p>
                )}
              </div>
            </div>
          ) : (
            <>{openSearch && isEmptyValue(data) && <NoResultsFound className="mt-14" />}</>
          )}
        </div>
      </div>
      {openStepGroupVariableModal ? (
        <StepGroupVariableModal
          MyAlert={props.MyAlert}
          closeModal={closeModal}
          mode={mode}
          data={stepGroupVariableData}
          reloadTree={reloadTreeData}
        />
      ) : null}
      {openDeleteModal ? <DeleteModal message={message} closeModal={closeModal} method={deleteVariable} /> : null}
      {openDetailsModal ? (
        <VariableDetailsModal type="StepGroupVariable" closeModal={closeModal} data={stepGroupVariableData} />
      ) : null}
      {openUserDetailsModal ? <UserDetailsModal data={userDetails} closeModal={closeModal} /> : null}
    </div>
  );
}

export default StepGroupVariable;

import React, { Fragment } from 'react';
import { useAlert } from '../common/alert_service/useAlert';
import TestDataPage from './testdata_page';
function Child() {
  const { AlertContatiner, MyAlert } = useAlert();
  return (
    <>
      <div className="alert-position-style">
        <AlertContatiner></AlertContatiner>
      </div>{' '}
      <TestDataPage MyAlert={MyAlert} />
    </>
  );
}

export default Child;

import React from 'react';
import { getAllSuiteOfSuiteReq } from '../../../../api/api_services';
import Pagination from '../../../common/pagination';
import { useTable, usePagination, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy } from 'react-table';
import matchSorter from 'match-sorter';

let selectedSuiteOfSuiteName;
const Details = () => {
  let selectedSuiteOfSuite = window.location.pathname.split('/')[3];

  if (selectedSuiteOfSuite.includes('%20')) {
    selectedSuiteOfSuiteName = unescape(selectedSuiteOfSuite);
    selectedSuiteOfSuite = selectedSuiteOfSuiteName;
  } else {
    selectedSuiteOfSuiteName = selectedSuiteOfSuite;
  }
  console.log(`tab`, selectedSuiteOfSuiteName);
  const [suiteArray, setSuiteArrayData] = React.useState([]);
  console.log(`suiteArray`, suiteArray);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [selectedSuiteOfSuiteObj, setSelectedSuiteOfSuiteObj] = React.useState();
  const [postsPerPage] = React.useState(2);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  let currentPosts = suiteArray.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  function setImmediatePrevPage() {
    let prevPageNo = currentPage - 1;
    setCurrentPage(prevPageNo);
  }

  function setImmediateNextPage() {
    let prevPageNo = currentPage + 1;
    setCurrentPage(prevPageNo);
  }

  function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);

    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);

    return (
      <span>
        Search:{' '}
        <input
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`Type here...`}
          style={{
            fontSize: '1.1rem',
            border: '0',
          }}
        />
      </span>
    );
  }

  // Define a default UI for filtering
  function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search...`}
      />
    );
  }

  // This is a custom filter UI for selecting
  // a unique option from a list
  function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  // This is a custom filter UI that uses a
  // slider to set the filter value between a column's
  // min and max values
  function SliderColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    // Calculate the min and max
    // using the preFilteredRows

    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      preFilteredRows.forEach((row) => {
        min = Math.min(row.values[id], min);
        max = Math.max(row.values[id], max);
      });
      return [min, max];
    }, [id, preFilteredRows]);

    return (
      <>
        <input
          type="range"
          min={min}
          max={max}
          value={filterValue || min}
          onChange={(e) => {
            setFilter(parseInt(e.target.value, 10));
          }}
        />
        <button onClick={() => setFilter(undefined)}>Off</button>
      </>
    );
  }

  // This is a custom UI for our 'between' or number range
  // filter. It uses two number boxes and filters rows to
  // ones that have values between the two
  function NumberRangeColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id } }) {
    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      preFilteredRows.forEach((row) => {
        min = Math.min(row.values[id], min);
        max = Math.max(row.values[id], max);
      });
      return [min, max];
    }, [id, preFilteredRows]);

    return (
      <div
        style={{
          display: 'flex',
        }}
      >
        <input
          value={filterValue[0] || ''}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
          }}
          placeholder={`Min (${min})`}
          style={{
            width: '70px',
            marginRight: '0.5rem',
          }}
        />
        to
        <input
          value={filterValue[1] || ''}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
          }}
          placeholder={`Max (${max})`}
          style={{
            width: '70px',
            marginLeft: '0.5rem',
          }}
        />
      </div>
    );
  }

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  fuzzyTextFilterFn.autoRemove = (val) => !val;

  // Our table component
  function Table({ columns, data }) {
    const filterTypes = React.useMemo(
      () => ({
        // Add a new fuzzyTextFilterFn filter type.
        fuzzyText: fuzzyTextFilterFn,
        // Or, override the default text filter to use
        // "startWith"
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      []
    );

    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      nextPage,
      previousPage,
      state,
      visibleColumns,
      preGlobalFilteredRows,
      setGlobalFilter,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
        initialState: { pageIndex: 0, pageSize: 7 },
      },
      useFilters, // useFilters!
      useGlobalFilter, // useGlobalFilter!
      useSortBy,
      usePagination
    );
    return (
      <>
        <table class="relative w-full" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr class="mt-4" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th class="sticky top-0 px-6 py-3 text-current text-sm bg-gray-100 ml-5" {...column.getHeaderProps()}>
                    <span className="flex flex-row">
                      {column.render('Header')}
                      {/* Render the columns filter UI */}
                      <div>
                        <span>
                          {/* {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <AiOutlineArrowDown className="mt-1 ml-1" />
                                                            : <AiOutlineArrowUp className="mt-1 ml-1" />
                                                        : ''} */}
                        </span>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr className="project-row" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td class="px-4 py-3 text-center" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="pagecountborder" id="footer-details-suiteofsuite">
          <div className="float-right pr-4">
            <span className="pagecount">
              {page[0]?.index + 1} - {page[page.length - 1]?.index + 1} of {suiteArray.length}
            </span>
            <span className="pageborderleft ml-2 pl-2">
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className={canPreviousPage ? 'pagination-clickable-style ' : 'pageArrow'}
              >
                {'<'}
              </button>{' '}
              <span className="currentpagecount ml-2 mr-2">
                {' '}
                <span className="pl-2 pr-2">{pageIndex + 1}</span>
              </span>
              <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className={canNextPage ? 'pagination-clickable-style ' : 'pageArrow'}
              >
                {'>'}
              </button>{' '}
            </span>
          </div>
        </div>
        <br />
      </>
    );
  }

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue >= filterValue;
    });
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        columns: [],
      },
      // {
      //     Header: 'Sl.No',
      //     accessor: 'slNo',
      //     disableFilters: true,
      //     Cell: ({ value }) => {
      //         return (
      //             <div className="table-non-link-color-common float-left ml-3">{value}</div>
      //         )
      //     }
      // },
      {
        Header: 'Name',
        accessor: 'name',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left ml-3 table-font-style-common">{value}</div>;
        },
      },
      {
        Header: 'No.of Scripts',
        accessor: 'noOfScripts',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left ml-3 table-font-style-common">{value}</div>;
        },
      },
      {
        Header: 'Access',
        accessor: 'access',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left ml-3 table-font-style-common">{value}</div>;
        },
      },
      {
        Header: ' Run In',
        accessor: 'machines.multiple',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common float-left ml-3 table-font-style-common">
              {value === 'false' ? 'Single machine' : 'Multiple Machine'}
            </div>
          );
        },
      },
      {
        Header: 'Execution Type',
        accessor: 'machines.executionType',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common float-left ml-3 table-font-style-common">
              {value === 'SEND_SUITE_TO_ALL' ? 'Parallel' : value === 'DISTRIBUTE' ? 'Distribute' : 'sequential'}
            </div>
          );
        },
      },
    ],
    []
  );

  function getAllSuiteOfSuite() {
    getAllSuiteOfSuiteReq()
      .then((results) => {
        const response = results.data.responseObject;
        console.log(`response`, response);
        if (response) {
          response.map((resObj) => {
            if (resObj.name === selectedSuiteOfSuite) {
              setSelectedSuiteOfSuiteObj(resObj);
              setSuiteArrayData(resObj?.suites);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return [];
  }

  React.useEffect(() => {
    getAllSuiteOfSuite();
  }, []);
  return (
    <>
      <div className="content_area_style overflow-auto" id="journal-scroll">
        <div>
          <div className="mt-3 suite-of-suite-header-style">
            <label className="project_label page-header-common float-left">Suites of Suites Details</label>
          </div>
        </div>
        <div className="mt-3">
          <div className="flex flex-row p-4">
            <div className="w-2/5">
              <label htmlFor="suiteName" className="input-filed-label-style-common">
                <span class="text-red-400">*</span> Name
              </label>
              <br></br>

              <label className="create-set-header-label ml-3">
                {selectedSuiteOfSuiteObj ? selectedSuiteOfSuiteObj.name : '--'}
              </label>
            </div>
            <div className="w-3/5">
              <label htmlFor="Description" className="input-filed-label-style-common">
                Description
              </label>
              <br></br>
              <label>
                {' '}
                {selectedSuiteOfSuiteObj && selectedSuiteOfSuiteObj?.description
                  ? selectedSuiteOfSuiteObj.description
                  : '--'}
              </label>
            </div>
          </div>

          <div className="suite-of-suite-deails-area-style-child">
            <div className="grid grid-cols-2 mt-3 p-4 ">
              <div className="suite-header-style">
                <label className="module-script-label-style float-left"> Suites</label>
              </div>
            </div>
            <Table columns={columns} data={suiteArray} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;

import React from 'react';
import Chart from 'react-apexcharts';
import { colors } from '@src/css_config/colorConstants.js';

const FailureReasonChart = ({ reason, failureReasonsBg, setFailureReasonsBg, handleClick }) => {
  let { percentage, tagName, taggedScripts, totalTaggedScripts } = reason;
  let series = [Math.round(percentage)];

  const failureReasonsChartOpt = {
    series,
    options: {
      chart: {
        type: 'radialBar',
      },
      colors: [
        function () {
          return colors.rs_failed;
        },
      ],
      plotOptions: {
        radialBar: {
          track: {
            background: colors.bg_failureReasonTrack,
          },
          hollow: {
            size: '60%',
          },

          dataLabels: {
            showOn: 'always',
            value: {
              color: colors.black,
              fontSize: '12px',
              show: true,
              offsetY: -10,
            },
          },
        },
      },

      stroke: {
        lineCap: 'round',
        colors: [colors.rs_failed],
        curve: 'smooth',
      },
      labels: [''],
    },
  };

  return (
    <div
      key={`${tagName}-${percentage}`}
      className={`fontPoppinsSemiboldMd cursor  overflow-hidden 
            ${
              failureReasonsBg === tagName ? 'failerReason_card_selected' : 'failerReason_card'
            }                          
          `}
      onClick={() => {
        setFailureReasonsBg(tagName);
        handleClick(tagName);
      }}
    >
      <div className="fontPoppinsSemiboldXs failureReaon_CardHeader">{tagName}</div>
      <div className="flex failureReasonsChartBlock items-center justify-evenly">
        <div className="tagChartCircle">
          <Chart options={failureReasonsChartOpt.options} series={failureReasonsChartOpt?.series} type="radialBar" />
        </div>
        <div className="tagFailedCount">
          <p className="fontPoppinsRegularXs8px text-black">Total steps tagged : {totalTaggedScripts}</p>
          <p className="fontPoppinsRegularXs8px text-black">Total steps with this tag : {taggedScripts}</p>
        </div>
      </div>
    </div>
  );
};

export default FailureReasonChart;

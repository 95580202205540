import { Dialog } from '@mui/material';
import PropTypes from 'prop-types';
import cx from 'classnames';
import style from '@pages/licenses/styles/top-border-popup-with-footer.module.scss';

function TopBorderPopupFooterButton({ onClick, buttonText, className, disabled }) {
  return (
    <button
      type="button"
      className={cx(style['button'], 'fontPoppinsRegularMd', className)}
      onClick={onClick}
      disabled={disabled}
    >
      {buttonText}
    </button>
  );
}

function TopBorderPopupFooter({ onCloseClick, closeButtonText, children, isCloseVisible }) {
  return (
    <div className={style['footer']}>
      {isCloseVisible && (
        <TopBorderPopupFooterButton
          onClick={onCloseClick}
          className={cx(style['primary-button'], 'fontPoppinsRegularXs')}
          buttonText={closeButtonText}
        />
      )}
      {children}
    </div>
  );
}

function TopBorderPopup({ open, onClose, children, status, allowOutsideClick, fullWidth, className, maxWidth }) {
  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={() => allowOutsideClick && onClose()}
      className={className}
    >
      <div className={cx(style['top-border'], style[`bg-${status}`])} />
      {children}
    </Dialog>
  );
}

TopBorderPopup.Footer = TopBorderPopupFooter;
TopBorderPopup.FooterButton = TopBorderPopupFooterButton;

TopBorderPopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  status: PropTypes.oneOf(['info', 'warning', 'success', 'danger']),
  allowOutsideClick: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  maxWidth: PropTypes.string,
};

TopBorderPopup.defaultProps = {
  open: true,
  status: 'info',
  allowOutsideClick: true,
  fullWidth: true,
  className: '',
  maxWidth: 'md',
};

TopBorderPopupFooter.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  closeButtonText: PropTypes.string,
  children: PropTypes.node,
};

TopBorderPopupFooter.defaultProps = {
  closeButtonText: 'Close',
  children: <></>,
};

TopBorderPopupFooterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

TopBorderPopupFooterButton.defaultProps = {
  className: '',
  disabled: false,
};

export default TopBorderPopup;

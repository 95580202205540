import { Tooltip } from '@material-ui/core';

export function getDeviceLabel(value) {
  const selectedProjectType = JSON.parse(localStorage.getItem('selected-project'));
  let platformValue;
  let imageArray = [];
  value?.map((platformName) => {
    platformValue = platformName?.platform?.toLowerCase() || '';
    if (platformValue.includes('android') && platformName?.name) {
      imageArray.push(
        <Tooltip title={platformName.name} placement="top">
          <img
            src="/assets/images/android_icon.svg"
            className="float-left mt-1 mr-2 inline-block browser-logo"
            alt="android"
          />
        </Tooltip>
      );
    } else if (platformValue.includes('ios') && platformName?.name) {
      imageArray.push(
        <Tooltip title={platformName.name} placement="top">
          <img
            src="/assets/images/apple_icon.svg"
            className="float-left mt-1 mr-2 inline-block browser-logo"
            alt="apple"
          />
        </Tooltip>
      );
    } else {
      return '';
    }
    return null;
  });
  let returnValue = [
    <span
      className={`flex table-font-style-common ${
        selectedProjectType.type === 'Web & Mobile' ? 'justify-center' : 'justify-start'
      }`}
    >
      {' '}
      {imageArray}
    </span>,
  ];
  return returnValue;
}

export function getBrowserLabel(value) {
  const getBrowserLogo = (value) => {
    value = value ? value.toLowerCase() : '';
    if (value.includes('opera')) {
      return (
        <img src="/assets/images/opera.png" className="float-left mt-1 mr-6 inline-block browser-logo" alt="opera" />
      );
    } else if (value.includes('chrome')) {
      return (
        <img src="/assets/images/Chrome.svg" className="float-left mt-1 mr-6 inline-block browser-logo" alt="chrome" />
      );
    } else if (value.includes('ie') || value.includes('internet explorer')) {
      return <img src="/assets/images/ie.png" className="float-left mt-1 mr-6 inline-block browser-logo" alt="ie" />;
    } else if (value.includes('firefox')) {
      return (
        <img
          src="/assets/images/Firefox.svg"
          className="float-left mt-1 mr-6 inline-block browser-logo"
          alt="firefox"
        />
      );
    } else if (value.includes('safari')) {
      return (
        <img src="/assets/images/safari.png" className="float-left mt-1 mr-6 inline-block browser-logo" alt="safari" />
      );
    } else if (value.includes('iphone') || value.includes('ipad')) {
      return (
        <img
          src="/assets/images/apple_icon.svg"
          className="float-left mt-1 mr-6 inline-block browser-logo"
          alt="apple"
        />
      );
    } else if (value.includes('android')) {
      return (
        <img
          src="/assets/images/android.png"
          className="float-left mt-1 mr-6 inline-block browser-logo"
          alt="android"
        />
      );
    } else if (value.includes('samsung')) {
      return (
        <img
          src="/assets/images/samsung.png"
          className="float-left mt-1 mr-6 inline-block browser-logo"
          alt="samsung"
        />
      );
    } else if (value.includes('edge')) {
      return (
        <img
          src="/assets/images/Microsoft_Edge.svg"
          className="float-left mt-1 mr-6 inline-block browser-logo"
          alt="edge"
        />
      );
    } else {
      return '';
    }
  };
  return [
    <Tooltip title={value} placement="top">
      <span className="flex table-font-style-common justify-center"> {getBrowserLogo(value)}</span>
    </Tooltip>,
  ];
}

export function getOsLabel(value, classList = false) {
  value = value || '--';
  let returnValue;
  if (value.toLowerCase().includes('windows')) {
    returnValue = [
      <Tooltip title={value} placement="top">
        <img
          src="/assets/images/windows-10-icon-logo-5BC5C69712-seeklogo.com.svg"
          className={`${classList === false ? 'float-left mr-2' : classList} os-logo`}
          alt="windows"
        />
      </Tooltip>,
    ];
  } else if (
    value.toLowerCase().includes('mac') ||
    value.toLowerCase().includes('os') ||
    value.toLowerCase().includes('ios') ||
    value.toLowerCase().includes('os x')
  ) {
    returnValue = [
      <Tooltip title={value} placement="top">
        <img
          src="/assets/images/apple_icon.svg"
          className={`${classList === false ? 'float-left mr-6' : classList} os-logo`}
          alt="apple"
        />
      </Tooltip>,
    ];
  } else if (value.toLowerCase().includes('linux')) {
    returnValue = [
      <Tooltip title={value} placement="top">
        <img
          src="/assets/images/LINUX-LOGO.svg"
          className={`${classList === false ? 'float-left mr-6' : classList} os-logo`}
          alt="linux"
        />
      </Tooltip>,
    ];
  } else if (value.toLowerCase().includes('android')) {
    returnValue = [
      <Tooltip title={value} placement="top">
        <img
          src="/assets/images/android_icon.svg"
          className={`${classList === false ? 'float-left mr-6' : classList} os-logo`}
          alt="android"
        />
      </Tooltip>,
    ];
  } else {
    returnValue = [
      <Tooltip title={value} placement="top">
        <span className="table-non-link-color-common table-font-style-common"> {value}</span>
        {/* <img src="/assets/images/LINUX-LOGO.svg" className="float-left mr-2" alt="linux" height="14px !important" width="14px  !important" /> */}
      </Tooltip>,
    ];
  }
  return returnValue;
}

import React, { useState, Fragment } from 'react';
import { useAlert } from '../common/alert_service/useAlert';
import TestDataPage from './testdata_page';

function Parent(props) {
  const { AlertContatiner, MyAlert } = useAlert();
  const [tabsList, setTabsList] = useState([
    {
      name: 'Test Data',
      logo: '/assets/images/project_rec_logo.svg',
      selectedLogo: '/assets/images/project_rec_logo_blue.svg',
      pathUrl: `/testData`,
      selectedName: 'testData',
    },
  ]);
  const { history } = props;

  let activeTab = history.location.pathname.split('/')[1];
  const renderTabs = () => {
    return (
      <ul className="navBar text-blue-600 flex flex-row primary-tab">
        {tabsList.map((tab, index) => {
          return (
            <li>
              <a
                href=""
                key={`dashboard-tab-${index}`}
                onClick={(event) => {
                  event.preventDefault();
                  props.history.push(tab.pathUrl);
                }}
                className={`${activeTab === tab.selectedName ? 'selected_tab_style' : ''}`}
              >
                {tab.name}
              </a>
            </li>
          );
        })}
      </ul>
    );
  };
  return (
    <>
      <div className="alert-position-style">
        <AlertContatiner></AlertContatiner>
      </div>
      {renderTabs()}
      <div className="clearfix ml-auto">
        {/* basavarj changes */}
        {/* <div className="float-right ml-5  mr-1" style={{ marginTop: "-35px" }}>
          <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
              <>
                <div>
                  <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none">
                    <span className="sr-only">Open options</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 0 24 24"
                      width="24px"
                      fill="#000000"
                    >
                      <path d="M0 0h24v24H0V0z" fill="none" />
                      <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                    </svg>
                  </Menu.Button>
                </div>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div className="py-1">
                      {contextMenuData.map((element, index) => (
                        <Menu.Item key={index}>
                          {({ active }) => (
                            <button
                              type="button"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block w-full text-left px-4 py-2 text-sm fontPoppinsRegularMd"
                              )}
                            >
                              {element.option}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div> */}
      </div>
      <TestDataPage pageType="parent" MyAlert={MyAlert} />
    </>
  );
}

export default Parent;

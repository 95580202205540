import { useContext, useEffect, useState } from 'react';
import { SETTINGS } from './constants';
import './../css/settings.scss';
import '@pages/test-development/script/scripts/webservice/restapi/css/common_Webservice.scss';
import { Input, Select, Toggle } from 'fireflink-ui';
import { TLSVersions } from './TLSVersions';
import { RestContext } from '../../../restapi';
import { isEmptyObject, isEmptyValue } from '@src/util/common_utils';

const Settings = ({ selectedStepData, handleSaveAsSteps }) => {
  const {
    values: { settings },
    setValues,
  } = useContext(RestContext);
  const {
    maxNumberOfRedirect,
    requestTimeOut: { time, unit },
  } = settings;
  const [onMouseHoverRow, setOnMouseHoverRow] = useState(-1);

  const {
    DEFAULT_VALUE,
    webserviceSettingsOptions,
    MS,
    MAX_REQUEST_TIME_MS,
    MIN_REQUEST_TIME_MS,
    SEC,
    SETTING,
    MAX_REQUEST_TIME_SEC,
    MIN_REQUEST_TIME_SEC,
    MAX_VALUE,
    MIN_VALUE,
    REQUEST_TIMEOUT,
    TLS_PROTOCOLS_ID,
    TLS_PROTOCOLS,
    TLS_PROTOCOLS_CONTENT,
    TLS_VERSION,
    REQUEST_TIMEOUT_SECS,
    REQUEST_TIMEOUT_MS,
    DISABLE_KEYS,
  } = SETTINGS;

  // default value to set a limit on the maximum number of redirects to follow for any request.
  const [redirectSettingUrl, setRedirectSettingUrl] = useState(maxNumberOfRedirect);

  // default value to set a request time out in milliseconds
  const [requestTimeOutUnit, setRequestTimeOutUnit] = useState(unit);

  // default value to set how long a request should wait for a response before timing out
  const [requestTimeOut, setRequestTimeOut] = useState(time);

  const onSettingToggleChange = (key) => {
    setValues((prevValues) => ({
      ...prevValues,
      settings: {
        ...prevValues.settings,
        [key]: !prevValues.settings[key],
      },
    }));
    handleSaveAsSteps(false);
  };

  const onSettingSelect = (e) => {
    let updatedRequestedTimeOut = e.value === SEC ? REQUEST_TIMEOUT_SECS : REQUEST_TIMEOUT_MS;
    setRequestTimeOutUnit(e.value);
    setRequestTimeOut(updatedRequestedTimeOut);
    handleSaveAsSteps(false);
  };

  const checkRequestTimeOut = (settingRequestTimeOutName, settingRequestTimeOutValue) => {
    if (settingRequestTimeOutValue?.length > 1 && settingRequestTimeOutValue?.charAt(0) === DEFAULT_VALUE) {
      settingRequestTimeOutValue = settingRequestTimeOutValue?.substring(1);
    }
    let isValid = false;
    if (requestTimeOutUnit?.toLowerCase() === MS?.toLowerCase()) {
      isValid = settingRequestTimeOutValue <= MAX_REQUEST_TIME_MS && settingRequestTimeOutValue >= MIN_REQUEST_TIME_MS;
    } else if (requestTimeOutUnit?.toLowerCase() === SEC?.toLowerCase()) {
      isValid =
        settingRequestTimeOutValue <= MAX_REQUEST_TIME_SEC && settingRequestTimeOutValue >= MIN_REQUEST_TIME_SEC;
    }
    if (isValid) {
      setRequestTimeOut(settingRequestTimeOutValue);
      setValues((prevValues) => ({
        ...prevValues,
        settings: {
          ...prevValues.settings,
          [settingRequestTimeOutName]: settingRequestTimeOutValue,
        },
      }));
    }
    if (isEmptyValue(settingRequestTimeOutValue)) setRequestTimeOut(0);
    handleSaveAsSteps(false);
  };

  const onSettingTLSVersionChange = (index) => {
    setValues((prevValues) => {
      const updatedTLSVersions = [...prevValues.settings.tlsVersions];
      updatedTLSVersions[index] = {
        ...updatedTLSVersions[index],
        isEnabled: !updatedTLSVersions[index].isEnabled,
      };
      return {
        ...prevValues,
        settings: {
          ...prevValues.settings,
          tlsVersions: updatedTLSVersions,
        },
      };
    });
    handleSaveAsSteps(false);
  };

  const onSettingMouseOver = (i) => {
    setOnMouseHoverRow(i);
  };

  const onSettingMouseOut = () => {
    setOnMouseHoverRow(-1);
  };

  const setRedirectsFunction = (settingRedirectUrlName, settingRedirectValue) => {
    if (settingRedirectValue?.length > 1)
      while (settingRedirectValue?.charAt(0) === DEFAULT_VALUE)
        settingRedirectValue = settingRedirectValue.substring(1);
    if (settingRedirectValue <= MAX_VALUE && settingRedirectValue >= MIN_VALUE) {
      setRedirectSettingUrl(settingRedirectValue);
      setValues((prevValues) => ({
        ...prevValues,
        settings: {
          ...prevValues.settings,
          [settingRedirectUrlName]: settingRedirectValue,
        },
      }));
    }
    if (isEmptyValue(settingRedirectValue)) setRedirectSettingUrl(0);
    handleSaveAsSteps(false);
  };

  const onSettingKeyDown = (event) => {
    return DISABLE_KEYS.includes(event.key) && event.preventDefault();
  };

  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      settings: {
        ...prevValues.settings,
        requestTimeOut: {
          ...prevValues.settings?.requestTimeOut,
          time: Number(requestTimeOut),
          unit: requestTimeOutUnit,
        },
        maxNumberOfRedirect: Number(redirectSettingUrl),
      },
    }));
  }, [requestTimeOut, requestTimeOutUnit, redirectSettingUrl, setValues]);

  useEffect(() => {
    if (!isEmptyObject(selectedStepData) && SETTING in selectedStepData && !isEmptyValue(selectedStepData.settings)) {
      const {
        maxNumberOfRedirect,
        requestTimeOut: { unit, time },
      } = selectedStepData?.settings || {};
      setValues((preValue) => ({
        ...preValue,
        settings: selectedStepData?.settings,
      }));
      setRequestTimeOutUnit(capitalize(unit));
      setRedirectSettingUrl(maxNumberOfRedirect);
      setRequestTimeOut(time);
    }
  }, [selectedStepData, setValues, SETTING]);

  const capitalize = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const renderSettings = () =>
    webserviceSettingsOptions.map(({ TOGGLE, TITLE, DESCRIPTION, ID }, index) => (
      <div
        key={index}
        className={`flex justify-between align-middle settings-content-data ${onMouseHoverRow === ID && 'mouse-over'}`}
        onMouseEnter={() => onSettingMouseOver(ID)}
        onMouseLeave={onSettingMouseOut}
      >
        <div className="setting-title-des">
          <h3 className="fontPoppinsSemiboldMd text-left setting-font">{TITLE}</h3>
          <p className="fontPoppinsRegularMd setting-font mb-2 mt-2">{DESCRIPTION}</p>
        </div>
        <div className="toggle-button">
          {TOGGLE ? (
            <div className="settings-toggle-button">
              <div
                className={`${
                  settings?.[TOGGLE ?? ''] ? 'settings-toggle-button-enable' : 'settings-toggle-button-disable'
                }`}
              >
                <Toggle
                  id={`toggle${index}`}
                  type="default"
                  variant="primary"
                  checked={settings?.[TOGGLE]}
                  onChange={() => onSettingToggleChange(TOGGLE)}
                  isBackgroundTransparent={false}
                />
              </div>
            </div>
          ) : TITLE === webserviceSettingsOptions[1].TITLE ? (
            <>
              <div className="setting-input-container">
                <Input
                  type="number"
                  variant="primary"
                  disabled={false}
                  minValue={0}
                  maxValue={requestTimeOutUnit === SEC ? MAX_REQUEST_TIME_SEC : MAX_REQUEST_TIME_MS}
                  onKeyDown={onSettingKeyDown}
                  placeholder={requestTimeOut}
                  value={requestTimeOut}
                  onChange={(e) => checkRequestTimeOut(e.target.name, e.target.value)}
                  setValue={setRequestTimeOut}
                  name="time"
                  className="setting-input"
                  isBackgroundTransparent={false}
                />
              </div>
              <Select
                className="setting-select"
                placeholder={MS}
                options={REQUEST_TIMEOUT}
                onChange={onSettingSelect}
                selectedOption={
                  {
                    label: capitalize(requestTimeOutUnit),
                    value: requestTimeOutUnit,
                  } || REQUEST_TIMEOUT[0]
                }
              />
            </>
          ) : (
            <div className="setting-input-container">
              <Input
                type="number"
                variant="primary"
                disabled={false}
                maxValue={10}
                minValue={0}
                placeholder={redirectSettingUrl}
                value={redirectSettingUrl}
                onChange={(e) => setRedirectsFunction(e.target.name, e.target.value)}
                setValue={setRedirectSettingUrl}
                isBackgroundTransparent={false}
                onKeyDown={onSettingKeyDown}
                name="maxNumberOfRedirect"
                className="setting-input"
              />
            </div>
          )}
        </div>
      </div>
    ));

  return (
    <section className="settings-container">
      {renderSettings()}
      <div
        className={`settings-content-data pb-4 block ${onMouseHoverRow === TLS_PROTOCOLS_ID && 'mouse-over'}`}
        onMouseEnter={() => onSettingMouseOver(TLS_PROTOCOLS_ID)}
        onMouseLeave={onSettingMouseOut}
      >
        <div className="setting-title-des">
          <h3 className="fontPoppinsSemiboldMd text-left setting-font">{TLS_PROTOCOLS}</h3>
          <p className="fontPoppinsRegularMd text-left setting-font mt-2">{TLS_PROTOCOLS_CONTENT}</p>
        </div>
        <div className="settings-protocols">
          {TLS_VERSION.map((version, index) => (
            <div key={index} className="flex w-1/2 mt-4 align-middle">
              <TLSVersions
                version={version}
                index={index}
                onSettingTLSVersionChange={() => onSettingTLSVersionChange(index)}
                versionToogle={settings?.tlsVersions[index].isEnabled}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Settings;

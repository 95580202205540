import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';

import * as Yup from 'yup';
import { SearchIcon } from '@heroicons/react/solid';
import '../../common/dropdown.css';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import HomeIcon from '@material-ui/icons/Home';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import '../ElementConfig/elementConfig.css';
import { MdEdit } from 'react-icons/md';
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';
import '../View&Custom/ViewConfig.css';
import CloseIcon from '@material-ui/icons/Close';
import MyInput from '../../common/Inputs/MyInput';
import { useFormik } from 'formik';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { getViewConfigReq } from '../../../api/api_services';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
    color: 'rgb(22,72,198)',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height: 600,
  bgcolor: 'background.paper',

  boxShadow: 12,
};

const ViewConfig = (props) => {
  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()

        .required('Element type name is required'),
    }),
    onSubmit: (values) => {
      // alert(JSON.stringify(props,null,2))
    },
  });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showOptions, setShowOptions] = useState(false);

  const [openUserDetails, setOpenUserDetails] = React.useState(false);
  const handleOpenUser = () => setOpenUserDetails(true);
  const handleCloseUser = () => setOpenUserDetails(false);

  const handelDropdown = () => {
    setShowOptions(!showOptions);
  };
  // if click outside of dropdown also it will close
  let menuRef = useRef();
  useEffect(() => {
    let handeler = (event) => {
      if (menuRef.current && !menuRef?.current?.contains(event.target)) {
        setShowOptions(false);
      }
    };
    document.addEventListener('mousedown', handeler);
    return () => {
      document.removeEventListener('mousedown', handeler);
    };
  }, []);

  const classes = useStyles();

  const [viewName, setViewName] = useState('');
  const [createdByUname, setCreatedByUname] = useState('');
  const [createdOn, setCreatedOn] = useState('');
  const [allColumns, setAllColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);

  useEffect(() => {
    getViewConfigReq()
      .then((results) => {
        console.log(results.data.responseObject[0]);

        setViewName(results.data.responseObject[0].viewName);
        setCreatedByUname(results.data.responseObject[0].createdByUname);
        setCreatedOn(results.data.responseObject[0].createdOn);
        setAllColumns(results.data.responseObject[0].allColumns);
        setSelectedColumns(results.data.responseObject[0].selectedColumns);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="page-container">
      <div className={classes.root} style={{ display: 'flex' }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" href="/" onClick={handleClick}>
            <HomeIcon className={classes.icon} />
          </Link>
          <Link className="breadcrumb" onClick={handleClick}>
            Configuration
          </Link>
          <Link className="breadcrumb" onClick={handleClick}>
            View & Custom field Configuration
          </Link>
          <Link className="breadcrumb" onClick={handleClick}>
            Create View
          </Link>
          <Link className="breadcrumb-active" onClick={handleClick}>
            Project Elements
          </Link>
        </Breadcrumbs>
      </div>

      <div class="h-screen flex ">
        <div ref={menuRef} class="relative inline-block text-left">
          <div>
            <span class=" text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center">
              <span class="mr-1 text-sm pb-5 headData" onClick={handelDropdown}>
                Create View
              </span>

              <ArrowDropDownIcon
                onClick={handelDropdown}
                class="fill-current h-7  "
                style={{ marginLeft: '-26px', width: '36px', height: '29px', color: '#1648C6' }}
              />

              <Link
                to="/configuration/view&CustomfieldConfiguration/createView/projectElement/history"
                style={{ marginLeft: '20px', fontWeight: '500', color: '#1648C6', width: '47px', height: '19px' }}
                className="text-sm fontPoppinsRegularMd"
              >
                History
              </Link>
            </span>
          </div>
          {showOptions && (
            <div
              style={{ width: '10rem', marginTop: '-5px' }}
              class="origin-top-left absolute right-70 mt-2 w-75 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabindex="-1"
            >
              <div class="py-1 fontPoppinsRegularMd" role="none">
                <span
                  class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-0"
                >
                  Project Elements
                </span>
                <span
                  class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-1"
                >
                  Program Elements
                </span>
                <span
                  class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-2"
                >
                  Step Group
                </span>
                <span
                  class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-2"
                >
                  Test Data
                </span>
                <span
                  class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-2"
                >
                  Test Development
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="page-list-body">
        <div className="page-table-body">
          <div className="configPage pr-0 content_area_style" id="journal-scroll">
            <div className="grid grid-cols-2 mb-4 ">
              <span className=" ml-3 mt-4 text-sm flex-auto  configPageHeading">Project Elements</span>
              <div className="icon">
                <SearchIcon className="search mt-4 w-15 h-5   items-center float-left" />
              </div>
            </div>

            <div className=" grid grid-cols-3 tableSection ">
              <table>
                <thead style={{ background: 'rgb(240,243,249)', textAlign: 'left', padding: '0.8rem', height: '40px' }}>
                  <tr>
                    <th>
                      <span className="mx-5 tableHead text-sm ">View Name</span>
                    </th>
                    <th className="text-sm">Created By</th>
                    <th className="text-sm">Created On</th>
                    <th className="text-sm">Actions</th>
                  </tr>
                </thead>
                <tbody style={{ textAlign: 'left' }}>
                  <tr className="hover:bg-gray-300  visibleData" style={{ height: '50px' }}>
                    <td className="text-gray-700">
                      <span className="tableHead" value={viewName}>
                        Default View
                      </span>
                    </td>

                    <td
                      className="text-blue-700"
                      onClick={handleOpenUser}
                      style={{ cursor: 'pointer' }}
                      value={createdByUname}
                    >
                      Manjunath
                    </td>
                    <td value={createdOn}>08-12-2021</td>

                    <td>
                      <MdEdit onClick={handleOpen} className=" editIcon float-left edit-style text-blue-700" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/*   -----modal----- */}

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box>
                <div
                  style={{
                    width: '30rem',

                    height: '38.8rem',

                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                  className="  pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xs transform transition-all sm:align-middle w-2/4"
                >
                  <div className=" mx-3  my-5">
                    <span className="font-bold mt-4  mx-3 text-sm block">Edit View</span>

                    <div onClick={handleClose} style={{ marginLeft: '27rem', color: 'grey', marginTop: '-1.8rem' }}>
                      <CloseIcon />
                    </div>
                  </div>

                  <hr className="line mb-5" />
                  <div className="text-sm font-sans mt-3 text-gray-500 ml-7 mr-8">
                    <MyInput
                      type="text"
                      error={formik.errors.name && formik.touched.name}
                      value={viewName}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      name="name"
                      id="name"
                      autoComplete="off"
                      style={{ width: '50%' }}
                      autoFocus={false}
                      className=" block w-9/12 Apptype"
                      placeholder="Enter element type name"
                      helperText={formik.touched.name && formik.errors.name}
                      label={
                        <>
                          {' '}
                          <span
                            class="text-red-400"
                            style={{ color: '#EA0322', fontSize: '12px', fontFamily: 'Poppins-SemiBold' }}
                          >
                            *
                          </span>
                          <span style={{ fontSize: '16px', fontFamily: 'Poppins-SemiBold' }}> Name</span>
                        </>
                      }
                    />
                  </div>

                  <hr className="line mt-3" />

                  <div className=" ml-5 mt-4">
                    <Box
                      sx={{
                        boxShadow: 2,
                        bgcolor: 'background.paper',
                        m: 1,

                        width: '350',
                        height: '25.3rem',
                      }}
                    >
                      <span className=" p-3 text-sm block">Choose Columns</span>

                      <div>
                        <div className="boxHead" style={{ display: 'flex' }}>
                          <span className="text-sm m-3" style={{ color: '#3C3838', fontWeight: '600' }}>
                            Column Name
                          </span>
                          <span
                            className="text-sm  m-3 "
                            style={{ marginLeft: '5.25rem', color: '#3C3838', fontWeight: '600' }}
                          >
                            Selected Column Name
                          </span>
                        </div>

                        <div
                          className="verticalBar"
                          style={{
                            borderLeft: '2.5px dashed #F0F3F9',
                            marginLeft: '11.5rem',
                            height: '18.5rem',
                            position: 'absolute',
                          }}
                        ></div>

                        <div className="grid grid-cols-2 gap-2">
                          <div className="columnName">
                            {allColumns.map((allColumn, i) => {
                              return (
                                <div>
                                  <ul>
                                    <li>
                                      <span>
                                        <DragIndicatorIcon className="dragInd mx-1 my-1" />{' '}
                                        <label className="text-sm Tdata ">{allColumn}</label>
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              );
                            })}
                          </div>
                          <div className="selectedColumnName">
                            {selectedColumns.map((selectedColumn, i) => {
                              return (
                                <div>
                                  <ul>
                                    <li>
                                      <span>
                                        <DragIndicatorIcon className="dragInd mx-1 my-1" />{' '}
                                        <label className="text-sm Tdata ">{selectedColumn}</label>
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-cols-2 " style={{ marginTop: '2px' }}>
                        {/*
                 <span><DragIndicatorIcon className='dragInd mx-1 my-2'/> <label className='text-sm Tdata '>Created By</label></span>
                 <span><DragIndicatorIcon className='dragInd mx-1 my-2'/> <label className='text-sm Tdata '>Number of Elements</label></span>
                 <span><DragIndicatorIcon className='dragInd mx-1 my-2'/> <label className='text-sm Tdata'>Created On</label></span>
                 <span><DragIndicatorIcon className='dragInd mx-1 my-2'/> <label className='text-sm Tdata'>Shared Elements</label></span>
                 <span><DragIndicatorIcon className='dragInd mx-1 my-2'/> <label className='text-sm Tdata'>Last Modified On</label></span>
                 <span><DragIndicatorIcon className='dragInd mx-1 my-2'/> <label className='text-sm Tdata'>Element Type</label></span>
                 <span><DragIndicatorIcon className='dragInd mx-1 my-2'/> <label className='text-sm Tdata'>Description</label></span>
                 <span><DragIndicatorIcon className='dragInd mx-1 my-2'/> <label className='text-sm Tdata'>Version</label></span>
                 <span></span>
                 <span><DragIndicatorIcon className='dragInd mx-1 my-2'/> <label className='text-sm Tdata'>New Commit Publish</label></span>
                 <span></span>
                 <span><DragIndicatorIcon className='dragInd mx-1 my-2'/> <label className='text-sm Tdata'>Locators</label></span>
                 <span></span>
                 <span><DragIndicatorIcon className='dragInd mx-1 my-2'/> <label className='text-sm Tdata'>Last Modified By</label></span>
                 <span></span>
                 <span><DragIndicatorIcon className='dragInd mx-1 my-2'/> <label className='text-sm Tdata'>Delete</label></span>
                
                */}
                      </div>
                    </Box>
                  </div>

                  <hr className="line mt-5" />
                  <div className="mt-2 pb-2 flex flex-row float-right">
                    <button
                      type="button"
                      className=" ml-1"
                      style={{
                        width: '77px',
                        height: '32px',
                        background: ' #EEEDED 0% 0% no-repeat padding-box',
                        border: '1px solid #DFDFDF',
                        borderRadius: ' 2px',
                        opacity: '1',
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className=" ml-4 primary-btn "
                      style={{
                        width: '82px',
                        height: ' 32px',
                        background: ' #3A62C9 0% 0% no-repeat padding-box',
                        border: '1px solid #1648C6',
                        borderRadius: '2px',
                        opacity: '1',
                      }}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </Box>
            </Modal>
            {/*   -----End modal----- */}

            {/*-------User Modal */}

            <Modal
              open={openUserDetails}
              onClose={handleCloseUser}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div>
                  <span className="font-bold text-sm block my-4 mx-3">User Details-Manjunath</span>

                  <div onClick={handleCloseUser} className="searchIcon">
                    <CloseIcon />
                  </div>
                </div>
                <hr className="mt-3"></hr>
                <div className="mt-5 mx-4">
                  <div style={{ display: 'flex' }}>
                    <div>
                      <label className="grayText text-sm">Name</label>
                      <p className="text-sm  createdByData">Manjunath</p>
                    </div>
                    <div>
                      <label className="grayText text-sm ml9">Email</label>
                      <p className="text-sm createdByData ml9 ">Manjunath@gmail.com</p>
                    </div>
                    <div>
                      <label className="grayText ml-28 text-sm">Privilege</label>
                      <p className="text-sm createdByData ml-28">Admin</p>
                    </div>
                  </div>

                  {/*------- */}
                  <div style={{ display: 'flex', marginTop: '15px' }}>
                    <div>
                      <label className="grayText text-sm ">Phone Number</label>
                      <p className="text-sm createdByData">
                        +91 999-999-9999{' '}
                        <span class="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-black-100 bg-gray-400 rounded-full">
                          P
                        </span>
                      </p>

                      <p className="text-sm createdByData mt-2">
                        +91 888-888-8888 &nbsp;
                        <span class="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-black-100 bg-gray-400 rounded-full">
                          S
                        </span>
                      </p>
                    </div>
                    <div>
                      <label className="grayText text-sm ml-16">Status</label>
                      <p className="text-sm createdByData ml-16">Enabled</p>
                    </div>
                    <div>
                      <label className="grayText text-sm ml13">Created By</label>
                      <p className="text-sm createdByData ml13">Manjunath</p>
                    </div>
                  </div>

                  {/*------- */}
                  <div style={{ display: 'flex', marginTop: '15px' }}>
                    <div>
                      <label className="grayText text-sm">Created On</label>
                      <p className="text-sm createdByData">12/07/21,17:00</p>
                    </div>
                    <div>
                      <label className="grayText text-sm ml-32">Modified By</label>
                      <p className="text-sm createdByData ml-32">Manjunath</p>
                    </div>
                    <div>
                      <label className="grayText text-sm ml11 ">Modified On</label>
                      <p className="text-sm createdByData ml11 ">13/07/21,14:00</p>
                    </div>
                  </div>
                </div>

                <div className="my-4" style={{ borderBottom: '2.5px dashed #F0F3F9' }}></div>
                <div>
                  <Box
                    sx={{
                      boxShadow: 2,
                      bgcolor: 'background.paper',
                      m: 2,

                      width: '42rem',
                      height: '15.3rem',
                    }}
                  >
                    <span className="p-3 text-sm block">Project Role Mapping</span>

                    <div className=" boxHead p-2 grid grid-cols-3 gap-4">
                      <span className="ml-10">Project</span>
                      <span className="ml-28">Role</span>
                    </div>

                    <div className="grid grid-cols-2 " style={{ marginTop: '2px' }}>
                      <span>
                        {' '}
                        <label className="text-sm Tdata ml-12 ">Just Dial</label>
                      </span>
                      <span>
                        {' '}
                        <label className="text-sm Tdata ml-2 ">Test Engineer</label>
                      </span>
                      <span>
                        {' '}
                        <label className="text-sm Tdata ml-12 ">Mango</label>
                      </span>
                      <span>
                        {' '}
                        <label className="text-sm Tdata ml-2 ">Test Lead</label>
                      </span>
                      <span>
                        {' '}
                        <label className="text-sm Tdata ml-12 ">VTiger</label>
                      </span>
                      <span>
                        {' '}
                        <label className="text-sm Tdata ml-2 ">Element Engineer</label>
                      </span>
                      <span>
                        {' '}
                        <label className="text-sm Tdata ml-12 ">Orange</label>
                      </span>
                      <span>
                        {' '}
                        <label className="text-sm Tdata ml-2 ">Program Engineer</label>
                      </span>
                    </div>
                    <hr className="mt-4"></hr>
                    <div className="mt-3" id="footer-notfixed">
                      <div className="float-right pr-4">
                        <span className="pagecount">1-5 of 5</span>
                        <span className="pageborderleft ml-2 pl-2">
                          <button className="pageArrow">{'<'}</button>{' '}
                          <span className="currentpagecount ">
                            {' '}
                            <span className="pl-2 pr-2">1</span>
                          </span>
                          <button className="pageArrow">{'>'}</button>{' '}
                        </span>
                      </div>
                    </div>
                  </Box>
                </div>

                <hr className="mt-3"></hr>
                <div className="mt-2 pb-2 flex flex-row float-right">
                  <button
                    type="button"
                    className=" gray-btn ml-1"
                    style={{
                      fontSize: '14px',
                      fontFamily: 'Poppins-SemiBold',
                      marginRight: '12px',
                    }}
                  >
                    Close
                  </button>
                </div>
              </Box>
            </Modal>

            {/*-------End  User Modal */}

            <div className="pagecountborder-set mb-3" id="footer-notfixed">
              <div className="float-right pr-4">
                <span className="pagecount">1-5 of 5</span>
                <span className="pageborderleft ml-2 pl-2">
                  <button className="pageArrow">{'<'}</button>{' '}
                  <span className="currentpagecount ml-2 mr-2">
                    {' '}
                    <span className="pl-2 pr-2">1</span>
                  </span>
                  <button className="pageArrow">{'>'}</button>{' '}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default withRouter(ViewConfig);

import { Search } from '@material-ui/icons';
import React, { useEffect, useState, useContext } from 'react';
import { useGlobalFilter, useTable } from 'react-table';
import {
  addMachineToAllUsersReq,
  addMachineToMultiUsersReq,
  getAllCommomUserDetailsReq,
  getAllUserDetailsReq,
  removeMachinefromAllUsersReq,
  removeMachineToMultiUsersReq,
} from '@api/api_services';
import { CommonLoader } from '@pages/common/common-loader';
import GlobalFilter from './global_filtering';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import CommonDrawerJs from '@src/common/atoms/CommonDrawer';
import SearchBar from '@src/pages/analytics/common/search-bar';

const ConfigAccessDetails = (props) => {
  const project = JSON.parse(localStorage.getItem('selected-project'));
  const user = JSON.parse(localStorage.getItem('test-optimize-user'));
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [isLoading, setIsLoading] = useState(true);
  const [displaySearch, setdisplaySearch] = useState(true);
  const [userData, setUserData] = useState([]);
  const [selectedAddUserList, setSelectedAddUserList] = useState([]);
  const [selectedRemoveUserList, setSelectedRemoveUserList] = useState([]);
  const [addSingleUser, setaddSingleUser] = useState({});
  const [removeSingleUser, setremoveSingleUser] = useState({});
  const [giveAccessToAll, setGiveAccessToAll] = useState(false);
  const [removeAccessToAll, setRemoveAccessToAll] = useState(false);
  const [disableAccessAllCheck, setDisableAccessAllCheck] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [machineOwnerName, setMachineOwnerName] = useState('');

  let accessedUsers = [];
  let userListLength = 0;
  let totalCheckedCount = 0;

  const displaySearchField = (val) => {
    setdisplaySearch(val);
  };
  const accessToAll = (e) => {
    let adduserlistArray = selectedAddUserList;
    let removeUserlistArray = selectedRemoveUserList;
    if (e.target.checked) {
      let checkbox = document.getElementsByName('userAccess');
      checkbox.forEach((element) => {
        element.checked = true;
        let checkedIndex = removeUserlistArray.findIndex((value, index) => {
          return value === element.value;
        });
        totalCheckedCount = totalCheckedCount + 1;
        if (!accessedUsers.includes(element.value)) {
          adduserlistArray.push(element.value);
          setSelectedAddUserList(adduserlistArray);
          setSelectedRemoveUserList(removeUserlistArray);
        } else if (removeUserlistArray.includes(element.value)) {
          removeUserlistArray.splice(checkedIndex, 1);
        }
      });
      totalCheckedCount = data.length + 1;
      const allChecked = Array.from(checkbox).every((element) => element.checked);
      if (allChecked) {
        document.getElementById('accesstoAll').checked = true;
      }
      setGiveAccessToAll(true);
      setRemoveAccessToAll(false);
    } else {
      let checkbox = document.getElementsByName('userAccess');
      checkbox.forEach((element) => {
        element.checked = false;
        let checkedIndex = adduserlistArray.findIndex((value, index) => {
          return value === element.value;
        });
        totalCheckedCount = totalCheckedCount - 1;
        if (accessedUsers.includes(element.value)) {
          removeUserlistArray.push(element.value);
          setSelectedRemoveUserList(removeUserlistArray);
        } else if (adduserlistArray.includes(element.value)) {
          adduserlistArray.splice(checkedIndex, 1);
          setSelectedAddUserList(adduserlistArray);
        }
      });
      setRemoveAccessToAll(true);
      setGiveAccessToAll(false);
    }
    setSelectedAddUserList(adduserlistArray);
    setSelectedRemoveUserList(removeUserlistArray);
  };

  const giveAccess = (e, selectedUser) => {
    let adduserlistArray = selectedAddUserList;
    let removeUserlistArray = selectedRemoveUserList;
    if (e.target.checked) {
      setaddSingleUser(selectedUser);
      let checkedIndex = removeUserlistArray.findIndex((value, index) => {
        return value === e.target.value;
      });
      totalCheckedCount = totalCheckedCount + 1;
      if (!accessedUsers.includes(e.target.value)) {
        adduserlistArray.push(e.target.value);
        setSelectedAddUserList(adduserlistArray);
      } else if (removeUserlistArray.includes(e.target.value)) {
        removeUserlistArray.splice(checkedIndex, 1);
        setSelectedRemoveUserList(removeUserlistArray);
      }
    } else {
      setremoveSingleUser(selectedUser);
      totalCheckedCount = totalCheckedCount - 1;
      let checkedIndex = adduserlistArray.findIndex((value, index) => {
        return value === e.target.value;
      });
      if (accessedUsers.includes(e.target.value)) {
        removeUserlistArray.push(e.target.value);
        setSelectedRemoveUserList(removeUserlistArray);
      } else if (adduserlistArray.includes(e.target.value)) {
        adduserlistArray.splice(checkedIndex, 1);
        setSelectedAddUserList(adduserlistArray);
      }
    }
    setGiveAccessToAll(false);
    setRemoveAccessToAll(false);
    if (totalCheckedCount === userListLength) {
      document.getElementById('accesstoAll').checked = true;
      setGiveAccessToAll(true);
      setRemoveAccessToAll(false);
    } else {
      document.getElementById('accesstoAll').checked = false;
    }
  };

  const grantAccess = async () => {
    try {
      let addResponse;
      let removeResponse;
      if (giveAccessToAll) {
        if (project.id === '') {
          addResponse = await addMachineToAllUsersReq([props.machine.id]);
        } else if (
          user.privilege === 'Super Admin' ||
          user.privilege === 'Admin' ||
          props?.machine?.createdBy === user.id
        ) {
          addResponse = await addMachineToAllUsersReq([props.machine.id], project.id);
        }
        if (
          addResponse?.data?.status === 'SUCCESS' &&
          addResponse?.data?.responseCode === 200 &&
          addResponse?.data?.responseObject
        ) {
          props.MyAlert.success(
            `Access given for ${addResponse?.data?.responseObject === 1 ? addSingleUser.name : addResponse?.data?.responseObject} users`
          );
          props.handleAccessDetailsModal(false);
          props.handleReload(true);
        }
      } else if (removeAccessToAll) {
        if (project.id === '') {
          removeResponse = await removeMachinefromAllUsersReq([props.machine.id]);
        } else if (
          user.privilege === 'Super Admin' ||
          user.privilege === 'Admin' ||
          props?.machine?.createdBy === user.id
        ) {
          removeResponse = await removeMachinefromAllUsersReq([props.machine.id], project.id);
        }
        if (
          removeResponse?.data?.status === 'SUCCESS' &&
          removeResponse?.data?.responseCode === 200 &&
          removeResponse?.data?.responseCode
        ) {
          props.MyAlert.success(
            `Access removed for ${removeResponse?.data?.responseObject === 1 ? removeSingleUser.name : removeResponse?.data?.responseObject} users`
          );
          props.handleAccessDetailsModal(false);
          props.handleReload(true);
        }
      } else {
        if (selectedAddUserList.length > 0 && selectedRemoveUserList.length > 0) {
          if (project.id === '') {
            addResponse = await addMachineToMultiUsersReq(props.machine.id, selectedAddUserList);
            removeResponse = await removeMachineToMultiUsersReq(props.machine.id, selectedRemoveUserList);
            props.MyAlert.success(
              `Access given for ${selectedAddUserList.length} users Access removed for ${selectedRemoveUserList.length}`
            );
          } else if (
            user.privilege === 'Super Admin' ||
            user.privilege === 'Admin' ||
            props?.machine?.createdBy === user.id
          ) {
            addResponse = await addMachineToMultiUsersReq(props.machine.id, selectedAddUserList, project.id);
            removeResponse = await removeMachineToMultiUsersReq(props.machine.id, selectedRemoveUserList, project.id);
          }
          if (selectedRemoveUserList.length === 1 && selectedAddUserList.length === 1) {
            props.MyAlert.success(
              `Access given for ${addSingleUser.name} , Access removed for ${removeSingleUser.name} .`
            );
          } else {
            props.MyAlert.success(
              `Access given for ${selectedAddUserList.length} users and Access removed for ${selectedRemoveUserList.length} users .`
            );
          }
        } else if (selectedRemoveUserList.length > 0) {
          if (project.id === '') {
            removeResponse = await removeMachineToMultiUsersReq(props.machine.id, selectedRemoveUserList);
          } else if (
            user.privilege === 'Super Admin' ||
            user.privilege === 'Admin' ||
            props?.machine?.createdBy === user.id
          ) {
            removeResponse = await removeMachineToMultiUsersReq(props.machine.id, selectedRemoveUserList, project.id);
          }
          if (removeResponse?.data?.status === 'SUCCESS' && removeResponse?.data?.responseCode === 200) {
            if (selectedRemoveUserList.length === 1) {
              props.MyAlert.success(removeResponse?.data?.message);
            } else {
              props.MyAlert.success(`Access removed for ${selectedRemoveUserList.length} users`);
            }
          }
          if (
            removeResponse?.data?.status === 'FAILURE' &&
            removeResponse?.data?.responseCode === 404 &&
            removeResponse?.data?.responseCode
          ) {
            props.MyAlert.info(removeResponse?.data?.message);
            props.handleAccessDetailsModal(false);
          }
        } else if (selectedAddUserList.length > 0) {
          if (project.id === '') {
            addResponse = await addMachineToMultiUsersReq(props.machine.id, selectedAddUserList);
          } else if (
            user.privilege === 'Super Admin' ||
            user.privilege === 'Admin' ||
            props?.machine?.createdBy === user.id
          ) {
            addResponse = await addMachineToMultiUsersReq(props.machine.id, selectedAddUserList, project.id);
          }
          if (addResponse?.data?.status === 'SUCCESS' && addResponse?.data?.responseCode === 200) {
            if (selectedAddUserList.length === 1) {
              props.MyAlert.success(`Access given for ${addSingleUser.name}.`);
            } else {
              props.MyAlert.success(`Access given for ${selectedAddUserList.length} users`);
            }
          }
        } else {
          props.MyAlert.info('No changes were done');
        }
        props.handleAccessDetailsModal(false);
        props.handleReload(true);
      }
    } catch (err) {
      console.error('GRANT_ACCESS : ', err);
    }
  };

  const getAllUsers = async () => {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    let response;
    let userArray = [];
    try {
      if (
        (project.id === '' || (project.id === undefined && props?.machine.machineId)) &&
        (user.privilege === 'Super Admin' || user.privilege === 'Admin' || props?.machine?.createdBy === user.id)
      ) {
        response = await getAllUserDetailsReq(null, props?.machine.machineId);
      } else if (
        user.privilege === 'Super Admin' ||
        user.privilege === 'Admin' ||
        props?.machine?.createdBy === user.id
      ) {
        response = await getAllUserDetailsReq(project.id, props?.machine.machineId);
      } else if (
        (project.id === '' || project.id === undefined) &&
        (user.privilege === 'User' || props?.machine?.createdBy === user.id)
      ) {
        response = await getAllCommomUserDetailsReq();
      } else if (user.privilege === 'User' || props?.machine?.createdBy === user.id) {
        response = await getAllCommomUserDetailsReq(project.id);
      }
      stopProgress();
      let nonAdminCount = 0;
      let responseData = response.data.responseObject;
      setMachineOwnerName(responseData?.machineOwner?.userName);
      responseData.userDetail.forEach((value, index) => {
        userListLength = userListLength + 1;
        if (value?.clientSystemIds?.includes(props.machine.id)) {
          totalCheckedCount = totalCheckedCount + 1;
          accessedUsers.push(value.id);
        }
        if (value.privilege !== 'Super Admin' && value.privilege !== 'Admin') {
          nonAdminCount++;
        }
        userArray.push({
          index: `${index + 1}`,
          user: value.name,
          privilege: value.privilege,
          id: value.id,
          access: [
            <label className="userswitch">
              <input
                type="checkbox"
                disabled={value.privilege !== 'User' || value.id === props?.machine?.createdBy}
                onClick={(event) => {
                  giveAccess(event, value);
                }}
                value={value.id}
                defaultChecked={value?.clientSystemIds?.includes(props.machine.id)}
                name={`${value.privilege !== 'User' ? 'adminAccess' : 'userAccess'}`}
              />
              <span className="userslider round"></span>
            </label>,
          ],
        });
      });
      setUserData(userArray);
      if (nonAdminCount === 0) {
        setDisableAccessAllCheck(true);
      }
      if (totalCheckedCount === userListLength && userListLength !== 0) {
        document.getElementById('accesstoAll').checked = true;
      } else {
        document.getElementById('accesstoAll').checked = false;
      }
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 10,
      });
      setIsLoading(false);
    } catch (err) {
      resetProgress();
      console.error('GET_ALL_USERS : ', err);
    }
  };
  const data = React.useMemo(() => userData, [userData]);
  const columns = React.useMemo(
    () => [
      {
        Header: <span className="pl-3 fontPoppinsRegularSm text-black">SL.NO</span>,
        accessor: 'index',
        Cell: ({ value }) => {
          return <div className="pl-4">{value}</div>;
        },
        width: '20%',
      },
      {
        Header: <span className="pl-3 fontPoppinsRegularSm text-black">USER</span>,
        accessor: 'user',
        width: '50%',
      },
      {
        Header: <span className="pl-3 fontPoppinsRegularSm text-black">ACCESS</span>,
        accessor: 'access',
        width: '30%',
      },
    ],
    []
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter } = useTable(
    { columns, data },
    useGlobalFilter
  );

  const { globalFilter } = state;

  let handleSearchClose = () => {
    setOpenSearch(false);
    setGlobalFilter('');
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <CommonDrawerJs
      isDrawerOpen={true}
      headerContent="Access Details"
      drawerWidth="360px"
      rightButtonText="Update"
      isLeftButtonVisible={false}
      onDrawerClose={() => {
        props.handleAccessDetailsModal(false);
      }}
      onRightButtonClick={grantAccess}
      disableRightButton={isLoading}
    >
      <div className="">
        <div className=" grid grid-cols-1 gap-1.5">
          <hr className="text-blue-200" />
          <div className="flex flex-col overflow-hidden sticky top-0 bg-white z-10">
            <div className="pb-2 px-3">
              <h1 className="table-non-link-color-common fontPoppinsRegularSm">Machine Owner : {machineOwnerName}</h1>
              <span className="details-data-style-common">{props?.machine?.createdByUname}</span>
            </div>
            <div className="table-non-link-color-common font-style-common flex justify-end">
              <div>
                {
                  <SearchBar
                    open={openSearch}
                    searchValue={globalFilter || ''}
                    handleSearch={(e) => {
                      setGlobalFilter(e.target.value);
                    }}
                    onSearchClose={handleSearchClose}
                    handleSearchOpen={handleSearchOpen}
                  />
                }
              </div>
              <div className=" mx-3">
                <input type="checkbox" id="accesstoAll" onClick={accessToAll} disabled={disableAccessAllCheck} />
                <label className="ml-2 fontPoppinsRegularSm">Give Access To All</label>
              </div>
            </div>
          </div>
          {!isLoading ? (
            <div className={`access-details-table-height grid grid-cols-1 gap-1 mt-6`}>
              <div>
                <table {...getTableProps()} className="">
                  <thead className="sticky top-0 z-20">
                    {headerGroups.map((headerGroup) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className="headerStyle h-12 ml-4 user-table-header fontPoppinsSemiboldMd"
                      >
                        {headerGroup.headers.map((column) => (
                          <th width={column.width} {...column.getHeaderProps()} className="text-left">
                            {column.render('Header')}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()} className="configdataStyle overflow-y-auto">
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          className={`configHover h-12 content-start ${row.original.privilege !== 'User' || row.original.id === props?.machine?.createdBy ? 'disableColor' : null}`}
                        >
                          {row.cells.map((cell, cellIndex) => {
                            return (
                              <td
                                key={cell.column.Header + cellIndex}
                                {...cell.getCellProps()}
                                className="p-0 text-left fontPoppinsRegularSm text-black"
                              >
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="allign-div-center">
              <CommonLoader />
            </div>
          )}
        </div>
      </div>
    </CommonDrawerJs>
  );
};

export default ConfigAccessDetails;

import React, { useState, useEffect, useContext, useMemo } from 'react';
import '@pages/test-development/script/scripts/webservice/restapi/request/assert/css/default_assert.scss';
import '@pages/test-development/script/scripts/webservice/restapi/request/assert/css/captcha_response_data.scss';
import MyInput from '@pagescommon/Inputs/MyInput';
import VariableDropdown from '@pagescommon/variable-dropdown';
import { useFormik, FormikProvider } from 'formik';
import { RestContext } from '@pages/test-development/script/scripts/webservice/restapi/restapi';
import { WEBSERVICE } from '@pages/test-development/script/scripts/webservice/restapi/constants/constants';
import CircleIcon from '@mui/icons-material/Circle';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';
import CustomCheckBox from '@pages/test-development/script/scripts/webservice/CustomCheckBox';
import classNames from 'classnames';

const CheckLayout = ({ children, isOpened, isEnabled, data, handleChange, handleClick, fieldValue }) => {
  const IsDataCheck = data.values[0];
  const context = useContext(RestContext);

  const { TITLE_CONDITION_ARRAY, NAME_CONDITION_ARRAY, LHS_CONDITION_ARRAY } = WEBSERVICE;

  const isCheckedPanel = context?.values?.captureResponse?.currentCheckBoxValue === data?.title;
  const onDisplayCircleIcon = (data, fieldValue, IsDataCheck) => {
    const { name, returnValue, lhs } = IsDataCheck || {};
    const titleCondition = !TITLE_CONDITION_ARRAY.includes(data?.title) && fieldValue;
    const nameCondition = name && !NAME_CONDITION_ARRAY.includes(name);
    const returnValueCondition = returnValue && returnValue !== '';
    const lhsCondition = lhs && !LHS_CONDITION_ARRAY.includes(lhs);
    return titleCondition || nameCondition || returnValueCondition || lhsCondition;
  };

  return (
    data?.title !== 'Get Cookie' &&
    data?.title !== 'Get Cookies' &&
    data?.title !== 'Get Detailed Cookie' &&
    data?.title !== 'Get Detailed Cookies' &&
    data?.title !== 'Get Session ID' &&
    data?.title !== 'Get Status Line' &&
    data?.title !== 'Get Time' && (
      <div className="flex">
        <div
          className="pl-2 flex items-center  capture-response-title-header fontPoppinsRegularMd w-full select-none truncate pb-0 mb-2"
          draggable="false"
        >
          <div
            className={classNames(
              {
                'default-assert-left-checked-panel': isCheckedPanel,
                'default-assert-left-side-panel': !isCheckedPanel,
              },
              ' flex cursor-pointer h-9 items-center'
            )}
            draggable="false"
            onClick={() => {
              handleChange();
              handleClick();
            }}
          >
            <div className="assert-section-checkbox pl-2">
              <CustomCheckBox
                checked={isEnabled}
                onChange={handleChange}
                className="default-assert-check-box"
                assertCapture={true}
              />
            </div>
            <span className="pl-2 select-none fontPoppinsRegularSm truncate default-assert-left-side-panel-text-customize">
              {data?.title}
            </span>
            <div>
              {onDisplayCircleIcon(data, fieldValue, IsDataCheck) && (
                <CircleIcon className="absolute top-1 -right-2 z-10 captcha-response-circle-icon" />
              )}
            </div>
          </div>
        </div>
        {(isEnabled || isOpened) && <>{children}</>}
      </div>
    )
  );
};

export default function CaptureResponseData(props) {
  const isEditable = useMemo(() => window.permission?.isEditAccess('testDevelopment'), []);
  const context = useContext(RestContext);
  const { statusCode, apiTime, responseHeadersValue, responseBodyValue } = props.data;
  const { cdfrTabState } = props;
  const {
    TITLE_CONDITION_ARRAY,
    CAPTURE_GET_STATUS_CODE,
    CAPTURE_GET_RESPONSE_TIME,
    CAPTURE_GET_RESPONSE_BODY,
    CAPTURE_GET_HEADERS,
    CAPTURE_GET_JSON_PATH,
    CAPTURE_GET_HEADER_KEY,
    CAPTURE_GET_CONTENT_TYPE,
    CAPTURE_GET_COOKIE,
    CAPTURE_GET_COOKIES,
    CAPTURE_GET_DETAILED_COOKIE,
    CAPTURE_GET_DETAILED_COOKIES,
    CAPTURE_GET_SESSION_ID,
    CAPTURE_GET_STATUS_LINE,
    CAPTURE_GET_TIME,
  } = WEBSERVICE;

  const statusCodeDefaultValue = { lhs: '', isVariableName: false };
  const verifyStatusCodeInitialvalue = {
    title: CAPTURE_GET_STATUS_CODE,
    isEnabled: false,
    values: [statusCodeDefaultValue],
    ...(context.values.captureResponse[CAPTURE_GET_STATUS_CODE] || {}),
  };
  const [verifyStatusCode, setVerifyStatusCode] = useState(verifyStatusCodeInitialvalue);
  const responseTimeDefaultValue = { lhs: '', isVariableName: false };
  const responseTimeInitialValue = {
    title: CAPTURE_GET_RESPONSE_TIME,
    isEnabled: false,
    values: [responseTimeDefaultValue],
    ...(context.values.captureResponse[CAPTURE_GET_RESPONSE_TIME] || {}),
  };
  const [responseTime, setResponseTime] = useState(responseTimeInitialValue);
  const responseBodyDefaultValue = { lhs: '', isVariableName: false };
  const responseBodyInitialValue = {
    title: CAPTURE_GET_RESPONSE_BODY,
    isEnabled: false,
    values: [responseBodyDefaultValue],
    ...(context.values.captureResponse[CAPTURE_GET_RESPONSE_BODY] || {}),
  };
  const [responseBody, setResponseBody] = useState(responseBodyInitialValue);
  const HeadersDefaultValue = { lhs: '', isVariableName: false };
  const headersInitialValue = {
    title: CAPTURE_GET_HEADERS,
    isEnabled: false,
    values: [HeadersDefaultValue],
    ...(context.values.captureResponse[CAPTURE_GET_HEADERS] || {}),
  };
  const [headers, setHeaders] = useState(headersInitialValue);
  const jsonPathDefaultValue = { name: 'JSON path', lhs: 'responseBody', isVariableName: false, id: 1 };
  const jsonPathInitialValue = {
    title: CAPTURE_GET_JSON_PATH,
    isEnabled: false,
    values: [jsonPathDefaultValue],
    ...(context.values.captureResponse[CAPTURE_GET_JSON_PATH] || {}),
  };

  const [jsonPath, setJsonPath] = useState(jsonPathInitialValue);
  const headerKeyDefaultValue = { name: 'Header Key', lhs: '', isVariableName: false, id: 1 };
  const headerKeyInitialValue = {
    title: CAPTURE_GET_HEADER_KEY,
    isEnabled: false,
    values: [headerKeyDefaultValue],
    ...(context.values.captureResponse[CAPTURE_GET_HEADER_KEY] || {}),
  };
  const [headerKey, setHeaderKey] = useState(headerKeyInitialValue);
  const contentTypeDefaultValue = { lhs: "(headers[?name=='Content-Type'].value)[0]", isVariableName: false };
  const contentTypeInitialValue = {
    title: CAPTURE_GET_CONTENT_TYPE,
    isEnabled: false,
    values: [contentTypeDefaultValue],
    ...(context.values.captureResponse[CAPTURE_GET_CONTENT_TYPE] || {}),
  };
  const [contentType, setContentType] = useState(contentTypeInitialValue);
  const cookieDefaultValue = { lhs: '', rhs: '' };
  const cookieInitialValue = {
    title: CAPTURE_GET_COOKIE,
    isEnabled: false,
    values: [cookieDefaultValue],
    ...(context.values.captureResponse[CAPTURE_GET_COOKIE] || {}),
  };
  const [bcookie, setCookie] = useState(cookieInitialValue);
  const cookiesDefaultValue = { lhs: '', rhs: '' };
  const cookiesInitialValue = {
    title: CAPTURE_GET_COOKIES,
    isEnabled: false,
    values: [cookiesDefaultValue],
    ...(context.values.captureResponse[CAPTURE_GET_COOKIES] || {}),
  };
  const [bcookies, setCookies] = useState(cookiesInitialValue);
  const detailCookieDefaultValue = { lhs: '', rhs: '' };
  const detailCookieInitialValue = {
    title: CAPTURE_GET_DETAILED_COOKIE,
    isEnabled: false,
    values: [detailCookieDefaultValue],
    ...(context.values.captureResponse[CAPTURE_GET_DETAILED_COOKIE] || {}),
  };
  const [detailCookie, setDetailCookie] = useState(detailCookieInitialValue);
  const detailCookiesDefaultValue = { lhs: '', rhs: '' };
  const detailCookiesInitialValue = {
    title: CAPTURE_GET_DETAILED_COOKIES,
    isEnabled: false,
    values: [detailCookiesDefaultValue],
    ...(context.values.captureResponse[CAPTURE_GET_DETAILED_COOKIES] || {}),
  };
  const [detailCookies, setDetailCookies] = useState(detailCookiesInitialValue);
  const sessionIdDefaultValue = { lhs: '', rhs: '' };
  const sessionIdInitialValue = {
    title: CAPTURE_GET_SESSION_ID,
    isEnabled: false,
    values: [sessionIdDefaultValue],
    ...(context.values.captureResponse[CAPTURE_GET_SESSION_ID] || {}),
  };
  const [sessionId, setSessionId] = useState(sessionIdInitialValue);

  const statusLineDefaultValue = { lhs: '', rhs: '' };
  const statusLineInitialValue = {
    title: CAPTURE_GET_STATUS_LINE,
    isEnabled: false,
    values: [statusLineDefaultValue],
    ...(context.values.captureResponse[CAPTURE_GET_STATUS_LINE] || {}),
  };
  const [statusLine, setStatusLine] = useState(statusLineInitialValue);

  const dTimeDefaultValue = { lhs: '', rhs: '' };
  const dTimeInitialValue = {
    title: CAPTURE_GET_TIME,
    isEnabled: false,
    values: [dTimeDefaultValue],
    ...(context.values.captureResponse[CAPTURE_GET_TIME] || {}),
  };
  const [dTime, setDTime] = useState(dTimeInitialValue);
  const handleCheckbox = (values, callback) => {
    const myvalues = { isEnabled: !values.isEnabled };
    callback((values) => ({ ...values, ...myvalues }));
    context.setValues((v) => ({
      ...v,
      captureResponse: {
        ...(v.captureResponse || {}),
        [values.title]: { ...values, ...myvalues },
        currentCheckBoxValue: values.title,
      },
    }));
    props.handleSaveAsSteps(false);
  };
  const onCheckVariableExist = (fieldValue) => {
    if (fieldValue) {
      const isVariableExist = props.getUserInputValue(fieldValue, false);
      if (isVariableExist !== fieldValue) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };
  const handleClick = (values, callback) => {
    const myvalues = {
      isEnabled: true,
      isOpened: true,
    };
    callback((values) => ({ ...values, ...myvalues }));
    context.setValues((v) => ({
      ...v,
      captureResponse: {
        ...(v.captureResponse || {}),
        [values.title]: { ...values, ...myvalues },
        currentCheckBoxValue: values.title,
      },
    }));
    props.handleSaveAsSteps(false);
  };
  const verifyHeaderOrJsonChange = (subTabTitle, fieldValue) => {
    if (subTabTitle === CAPTURE_GET_JSON_PATH) {
      props.handleCdfrUpdate('jsonPath', fieldValue);
    }
    if (subTabTitle === CAPTURE_GET_HEADER_KEY) {
      props.handleCdfrUpdate('headerKey', fieldValue);
    }
  };
  const handleArrayValues = (values, callback, fieldName, fieldValue, index) => {
    let isVariable = false;
    let fieldType = typeof fieldValue;
    let returnFieldValue = fieldValue;
    let myvalues = [];
    isVariable = fieldValue?.name ? true : false;
    if (['jsonPathValue', 'headerKeyValue'].includes(fieldName)) {
      isVariable = true;
      if (
        (fieldValue?.type === 'LOCAL' && fieldValue?.parentVariableType === 'STEPGROUP') ||
        fieldValue?.reference === 'Step'
      ) {
        returnFieldValue = '${SGV_' + fieldValue?.name + '}';
      } else if (fieldValue?.reference === 'LOCAL') {
        returnFieldValue = '${LV_' + fieldValue?.name + '}';
      } else if (fieldValue?.reference === 'GLOBAL') {
        returnFieldValue = '${GV_' + fieldValue?.name + '}';
      } else {
        returnFieldValue = '${PEV_' + fieldValue?.name + '}';
      }
    }
    if (fieldName === 'lhs' || fieldName === 'name') {
      if (fieldName === 'lhs' && values.title === 'Get Value of JSON Path') {
        let fixedValue = 'responseBody';
        if (fieldValue.indexOf(fixedValue) !== 0) {
          fieldValue = fixedValue;
        }
      }
      if (fieldName === 'lhs' && values.title === 'Get Header for a Key') {
        fieldValue = `(headers[?name=='${fieldValue}'].value)[0]`;
      }
      fieldValue = props.getUserInputValue(fieldValue, false);
    }
    if (['jsonPathValue', 'headerKeyValue', 'returnValue' + index].includes(fieldName)) {
      fieldType = typeof fieldValue;
      if (fieldType === 'string') {
        const isVariableExist = props.getUserInputValue(fieldValue, false);
        if (isVariableExist !== fieldValue) {
          isVariable = true;
        } else {
          isVariable = false;
        }
      } else {
        isVariable = true;
      }
    }
    if (
      (isVariable && fieldType !== 'string') ||
      (!isVariable && ['string', Object].includes(fieldType)) ||
      (!isVariable && !fieldValue)
    ) {
      myvalues = values.values.map((value, i) => {
        if (['jsonPathValue', 'headerKeyValue', 'returnValue' + i].includes(fieldName)) {
          return i === index
            ? { ...value, [fieldName]: fieldValue, returnValue: returnFieldValue, isVariableName: isVariable }
            : value;
        } else {
          return i === index ? { ...value, [fieldName]: fieldValue } : value;
        }
      });
      context.setValues((v) => ({
        ...v,
        captureResponse: { ...(v.captureResponse || {}), [values.title]: { ...values, values: myvalues } },
      }));
      props?.cdfrResponseVariableUpdate(values.title, true);
      callback((v) => ({ ...v, values: myvalues }));
    }
    props.handleSaveAsSteps(false);
    verifyHeaderOrJsonChange(values?.title, fieldValue);
    if (TITLE_CONDITION_ARRAY.includes(values.title)) {
      if (fieldName === 'name') {
        props.validateCaptureDataFields(myvalues, fieldValue, index);
      }
    }
  };

  const handleAdd = (callback, value = { id: '' }) => {
    if (value.lhs.includes('responseBody')) {
      jsonPathDefaultValue.id = jsonPath.values.length + 1;
    } else {
      headerKeyDefaultValue.id = headerKey.values.length + 1;
    }
    callback((v) => ({ ...v, values: [...v.values, value] }));
    props.handleSaveAsSteps(false);
    const values = [...jsonPath.values];
    values.push(value);
    props.validateCaptureDataFields(values, value?.name, values?.length - 1);
  };

  const removeList = (
    values,
    callback,
    id,
    initialDefaultValues,
    responseErrorName = '',
    setFieldValue,
    setFieldError,
    setFieldTouched
  ) => {
    const currentValues =
      values.values.length === 1 && id === 0 ? [initialDefaultValues] : values.values.filter((_, idx) => idx !== id);

    props.handleCaptureDataResponseError(`${responseErrorName}${id}`, false, 'delete');
    const updatedCurrentValue = { ...values, values: currentValues };

    context.setValues((v) => ({
      ...v,
      captureResponse: {
        ...(v.captureResponse || {}),
        [values.title]: updatedCurrentValue,
      },
    }));

    callback((v) => ({ ...v, ...updatedCurrentValue }));
    setFieldValue(`returnValue${id}`, '');
    setFieldError(`returnValue${id}`, '');
    setFieldTouched(`returnValue${id}`, false, false);

    props.handleSaveAsSteps(true);
    props.validateCaptureDataFields(currentValues, currentValues[id]?.name, id, 'delete', values.values[id]);
  };

  const [statusCodeData, setStatusCodeData] = useState(context.values.statusCode);
  const [responseTimeData, setResponseTimeData] = useState(context.values.responseTimeData);
  const [responseBodyData, setResponseBodyData] = useState(context.values.responseBodyData);
  const [getHeader, setGetHeader] = useState(context.values.getHeaders);
  const [jsonPathValue, setJsonPathValue] = useState('');
  const [headerKeyValue, setHeaderKeyValue] = useState();
  const [contentTypeValue, setContentTypeValue] = useState(context.values.contentType);

  const getStepsUpdate = () => {
    if (Object.keys(props.selectedStepData).length > 0) {
      const tempParmvalue = props.selectedStepData;
      const getStatusCode = [];
      const getResponseTime = [];
      const getResponseBody = [];
      const getHeaders = [];
      const getContentType = [];
      const getJsonPath = [];
      const getHeaderKey = [];
      const setDefaultCaptureData = {};
      if (tempParmvalue?.updateVariables?.length > 0) {
        let isCaptureResCheck = true;
        let updateVariableName = '';
        tempParmvalue?.updateVariables.map((updateVariable, id) => {
          updateVariableName = props.getUserInputValue('${' + updateVariable.variable.id + '}', true);
          const updatedObject = { name: updateVariableName, status: true, isVariable: true };
          if (updateVariable.group === 'Status code') {
            if (updateVariable.isEnabled && isCaptureResCheck) {
              setDefaultCaptureData['currentCheckBoxValue'] = verifyStatusCode.title;
              isCaptureResCheck = false;
            }
            let getCaptureData = {
              lhs: '',
              isVariableName: true,
              statusCode: {
                ...updateVariable.variable,
                group: updateVariable.group,
                isEnabled: updateVariable.isEnabled,
                title: updateVariable.valueType.path,
              },
            };
            getStatusCode.push(getCaptureData);
            setVerifyStatusCode({
              ...verifyStatusCode,
              ...verifyStatusCode.isEnabled,
              isEnabled: updateVariable.isEnabled,
              values: getStatusCode,
            });

            setDefaultCaptureData[verifyStatusCode.title] = {
              isEnabled: updateVariable.isEnabled,
              isOpened: true,
              title: verifyStatusCode.title,
              values: getStatusCode,
            };
            cdfrTabState.statusCode = updateVariableName;
            setStatusCodeData(updatedObject);
            context.setFieldValue('statusCode', updatedObject);
          }
          if (updateVariable.group === 'Response Time') {
            if (updateVariable.isEnabled && isCaptureResCheck) {
              setDefaultCaptureData['currentCheckBoxValue'] = responseTime.title;
              isCaptureResCheck = false;
            }
            let getCaptureData = {
              lhs: '',
              isVariableName: true,
              responseTime: {
                ...updateVariable.variable,
                group: updateVariable.group,
                isEnabled: updateVariable.isEnabled,
                title: updateVariable.valueType.path,
              },
            };
            getResponseTime.push(getCaptureData);
            setResponseTime({
              ...responseTime,
              ...responseTime.isEnabled,
              isEnabled: updateVariable.isEnabled,
              values: getResponseTime,
            });
            setDefaultCaptureData[responseTime.title] = {
              isEnabled: updateVariable.isEnabled,
              isOpened: true,
              title: responseTime.title,
              values: getResponseTime,
            };
            cdfrTabState.respTime = updateVariableName;
            setResponseTimeData(updatedObject);
          }
          if (updateVariable.group === 'Response Body') {
            if (updateVariable.isEnabled && isCaptureResCheck) {
              setDefaultCaptureData['currentCheckBoxValue'] = responseBody.title;
              isCaptureResCheck = false;
            }
            let getCaptureData = {
              lhs: '',
              isVariableName: true,
              responseBody: {
                ...updateVariable.variable,
                group: updateVariable.group,
                isEnabled: updateVariable.isEnabled,
                title: updateVariable.valueType.path,
              },
            };
            getResponseBody.push(getCaptureData);
            setResponseBody({
              ...responseBody,
              ...responseBody.isEnabled,
              isEnabled: updateVariable.isEnabled,
              values: getResponseBody,
            });
            setDefaultCaptureData[responseBody.title] = {
              isEnabled: updateVariable.isEnabled,
              isOpened: true,
              title: responseBody.title,
              values: getResponseBody,
            };
            cdfrTabState.respBody = updateVariableName;
            setResponseBodyData(updatedObject);
          }
          if (updateVariable.group === 'Headers') {
            if (updateVariable.isEnabled && isCaptureResCheck) {
              setDefaultCaptureData['currentCheckBoxValue'] = headers.title;
              isCaptureResCheck = false;
            }
            let getCaptureData = {
              lhs: '',
              isVariableName: true,
              headers: {
                ...updateVariable.variable,
                group: updateVariable.group,
                isEnabled: updateVariable.isEnabled,
                title: updateVariable.valueType.path,
              },
            };
            getHeaders.push(getCaptureData);
            setHeaders({ ...headers, ...headers.isEnabled, isEnabled: updateVariable.isEnabled, values: getHeaders });
            setDefaultCaptureData[headers.title] = {
              isEnabled: updateVariable.isEnabled,
              isOpened: true,
              title: headers.title,
              values: getHeaders,
            };
            cdfrTabState.getHeader = updateVariableName;
            setGetHeader(updatedObject);
          }
          if (updateVariable.group === 'JSON Path') {
            if (updateVariable.isEnabled && isCaptureResCheck) {
              setDefaultCaptureData['currentCheckBoxValue'] = jsonPath.title;
              isCaptureResCheck = false;
            }
            let getCaptureData = {
              name: updateVariable?.name,
              lhs: updateVariable.valueType.path,
              isVariableName: true,
              id: ++id,
              returnValue: updateVariableName,
              jsonPathValue: {
                ...updateVariable.variable,
                group: updateVariable.group,
                isEnabled: updateVariable.isEnabled,
                title: updateVariable.valueType.path,
              },
            };
            getJsonPath.push(getCaptureData);
            setJsonPath({
              ...jsonPath,
              ...jsonPath.isEnabled,
              isEnabled: updateVariable.isEnabled,
              values: getJsonPath,
            });
            setDefaultCaptureData[jsonPath.title] = {
              isEnabled: updateVariable.isEnabled,
              isOpened: true,
              title: jsonPath.title,
              values: getJsonPath,
            };
            cdfrTabState.jsonPath = updateVariableName;
            setJsonPathValue(updateVariableName);
          }
          if (updateVariable.group === 'Header Key') {
            if (updateVariable.isEnabled && isCaptureResCheck) {
              setDefaultCaptureData['currentCheckBoxValue'] = headerKey.title;
              isCaptureResCheck = false;
            }
            let getCaptureData = {
              name: updateVariable.name,
              lhs: updateVariable.valueType.path,
              isVariableName: true,
              id: ++id,
              returnValue: updateVariableName,
              headerKeyValue: {
                ...updateVariable.variable,
                group: updateVariable.group,
                isEnabled: updateVariable.isEnabled,
                title: updateVariable.valueType.path,
              },
            };
            getHeaderKey.push(getCaptureData);
            setHeaderKey({
              ...headerKey,
              ...headerKey.isEnabled,
              isEnabled: updateVariable.isEnabled,
              values: getHeaderKey,
            });
            setDefaultCaptureData[headerKey.title] = {
              isEnabled: updateVariable.isEnabled,
              isOpened: true,
              title: headerKey.title,
              values: getHeaderKey,
            };
            cdfrTabState.headerKey = updateVariableName;
            setHeaderKeyValue(updateVariableName);
          }
          if (updateVariable.group === 'Content type') {
            if (updateVariable.isEnabled && isCaptureResCheck) {
              setDefaultCaptureData['currentCheckBoxValue'] = contentType.title;
              isCaptureResCheck = false;
            }
            let getCaptureData = {
              lhs: updateVariable.valueType.path,
              isVariableName: true,
              contentType: {
                ...updateVariable.variable,
                group: updateVariable.group,
                isEnabled: updateVariable.isEnabled,
                title: updateVariable.contentType,
              },
            };
            getContentType.push(getCaptureData);
            setContentType({
              ...contentType,
              ...contentType.isEnabled,
              isEnabled: updateVariable.isEnabled,
              values: getContentType,
            });
            setDefaultCaptureData[contentType.title] = {
              isEnabled: updateVariable.isEnabled,
              isOpened: true,
              title: contentType.title,
              values: getContentType,
            };
            cdfrTabState.contentType = updateVariableName;
            setContentTypeValue(updatedObject);
          }
        });
        context.values.captureResponse = setDefaultCaptureData;
      }
    }
  };

  useEffect(() => {
    getStepsUpdate();
  }, [props.selectedStepData]);

  const dataChange = (e, status) => {
    let value = e?.target?.value;
    const isVariable = onCheckVariableExist(value);
    value = isVariable ? value.split('}')[0] + '}' : value;

    const updatedData = {
      ...statusCodeData,
      name: value,
      status,
      isVariable,
    };
    props.handleCdfrUpdate('statusCode', value);
    props.onSelectRequestMethod('Capture Data From Response');
    context.values.statusCode = updatedData;
    setStatusCodeData(updatedData);
  };

  const responseChange = (e, status) => {
    let value = e?.target?.value;
    const isVariable = onCheckVariableExist(value);
    value = isVariable ? value.split('}')[0] + '}' : value;
    const updatedResponseTime = {
      ...responseTimeData,
      name: value,
      status,
      isVariable,
    };
    props.handleCdfrUpdate('respTime', value);
    context.values.isCdfrVariableInputData = value !== '';
    context.values.responseTimeData = updatedResponseTime;
    setResponseTimeData(updatedResponseTime);
  };

  const responseBodyChange = (e, status) => {
    let value = e?.target?.value;
    const isVariable = onCheckVariableExist(value);
    value = isVariable ? value.split('}')[0] + '}' : value;

    const updatedResponseBody = {
      ...responseBodyData,
      name: value,
      status,
      isVariable,
    };
    props.handleCdfrUpdate('respBody', value);
    context.values.isCdfrVariableInputData = value !== '';
    context.values.responseBodyData = updatedResponseBody;
    setResponseBodyData(updatedResponseBody);
  };

  const getHeaderChange = (e, status) => {
    let value = e?.target?.value;
    const isVariable = onCheckVariableExist(value);
    value = isVariable ? value.split('}')[0] + '}' : value;

    const updatedGetHeader = {
      ...getHeader,
      name: value,
      status,
      isVariable,
    };
    props.handleCdfrUpdate('getHeader', value);
    context.values.isCdfrVariableInputData = value !== '';
    context.values.getHeaders = updatedGetHeader;
    setGetHeader(updatedGetHeader);
  };

  const jsonPathChange = (e) => {
    setJsonPathValue(e?.target?.value);
  };

  const headerKeyChange = (e) => {
    setHeaderKeyValue(e?.target?.value);
  };

  const contentTypeChange = (e, status) => {
    let value = e?.target?.value;
    const isVariable = onCheckVariableExist(value);
    value = isVariable ? value.split('}')[0] + '}' : value;

    const updatedContentTypeValue = {
      ...contentTypeValue,
      name: value,
      status,
      isVariable,
    };
    props.handleCdfrUpdate('contentType', value);
    context.values.isCdfrVariableInputData = value !== '';
    context.values.contentType = updatedContentTypeValue;
    setContentTypeValue(updatedContentTypeValue);
  };

  const handleChildElementClick = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <div className="capture-response-header-text h-10 flex items-center justify-start" draggable="false">
        <div className="text-left fontPoppinsSemiboldMd  pl-2.5">Capture Data List</div>
      </div>
      <div className="captcha-data-scrollbar-customize relative flex gap-1">
        <div className="w-1/5 cdfr-left-scrollbar pt-0.5 ">
          <CheckLayout
            data={verifyStatusCode}
            isEnabled={verifyStatusCode.isEnabled}
            isOpened={verifyStatusCode.isOpened}
            handleClick={() => handleClick(verifyStatusCode, setVerifyStatusCode)}
            handleChange={() => handleCheckbox(verifyStatusCode, setVerifyStatusCode)}
            fieldValue={statusCodeData?.name || context.values?.statusCode?.name}
          >
            {context?.values?.captureResponse?.currentCheckBoxValue === 'Get Status Code' && (
              <VerifyStatusCodeComponent
                isEditable={isEditable}
                data={statusCodeData}
                dataChange={dataChange}
                variableName={props?.variableName}
                verifyStatusCode={verifyStatusCode}
                statusCode={statusCode}
                handleArrayValues={(fieldName, fieldValue, index) =>
                  handleArrayValues(verifyStatusCode, setVerifyStatusCode, fieldName, fieldValue, index)
                }
                handleChildElementClick={handleChildElementClick}
                handleCaptureError={props.handleCaptureDataResponseError}
                isEnabled={verifyStatusCode.isEnabled}
                props={props}
              />
            )}
          </CheckLayout>
          <CheckLayout
            data={responseTime}
            isEnabled={responseTime.isEnabled}
            isOpened={responseTime.isOpened}
            handleClick={() => handleClick(responseTime, setResponseTime)}
            handleChange={() => handleCheckbox(responseTime, setResponseTime)}
            fieldValue={responseTimeData?.name || context.values?.responseTime?.name}
          >
            {context?.values?.captureResponse?.currentCheckBoxValue === 'Get Response Time' && (
              <ResponseTime
                isEditable={isEditable}
                responseTimeData={responseTimeData}
                responseChange={responseChange}
                handleArrayValues={(fieldName, fieldValue, index) =>
                  handleArrayValues(responseTime, setResponseTime, fieldName, fieldValue, index)
                }
                responseTime={responseTime}
                apiTime={apiTime}
                handleChildElementClick={handleChildElementClick}
                handleCaptureError={props.handleCaptureDataResponseError}
                isEnabled={responseTime.isEnabled}
                props={props}
              />
            )}
          </CheckLayout>
          <CheckLayout
            data={responseBody}
            isEnabled={responseBody.isEnabled}
            isOpened={responseBody.isOpened}
            handleClick={() => handleClick(responseBody, setResponseBody)}
            handleChange={() => handleCheckbox(responseBody, setResponseBody)}
            fieldValue={responseBodyData?.name || context.values?.responseBodyData?.name}
          >
            {context?.values?.captureResponse?.currentCheckBoxValue === 'Get Response Body' && (
              <ResponseBody
                isEditable={isEditable}
                responseBodyData={responseBodyData}
                responseBodyChange={responseBodyChange}
                responseBodyValue={responseBodyValue}
                handleArrayValues={(fieldName, fieldValue, index) =>
                  handleArrayValues(responseBody, setResponseBody, fieldName, fieldValue, index)
                }
                responseBody={responseBody}
                handleChildElementClick={handleChildElementClick}
                handleCaptureError={props.handleCaptureDataResponseError}
                isEnabled={responseBody.isEnabled}
                props={props}
              />
            )}
          </CheckLayout>
          <CheckLayout
            data={headers}
            isEnabled={headers.isEnabled}
            isOpened={headers.isOpened}
            handleClick={() => handleClick(headers, setHeaders)}
            handleChange={() => handleCheckbox(headers, setHeaders)}
            fieldValue={getHeader?.name || context.values?.getHeaders?.name}
          >
            {context?.values?.captureResponse?.currentCheckBoxValue === 'Get Headers' && (
              <Headers
                isEditable={isEditable}
                getHeader={getHeader}
                getHeaderChange={getHeaderChange}
                responseHeadersValue={responseHeadersValue}
                handleArrayValues={(fieldName, fieldValue, index) =>
                  handleArrayValues(headers, setHeaders, fieldName, fieldValue, index)
                }
                headers={headers}
                handleChildElementClick={handleChildElementClick}
                handleCaptureError={props.handleCaptureDataResponseError}
                isEnabled={headers.isEnabled}
                props={props}
              />
            )}
          </CheckLayout>
          <CheckLayout
            data={jsonPath}
            isEnabled={jsonPath.isEnabled}
            isOpened={jsonPath.isOpened}
            handleClick={() => handleClick(jsonPath, setJsonPath)}
            handleChange={() => handleCheckbox(jsonPath, setJsonPath)}
            fieldValue={jsonPathValue}
          >
            {context?.values?.captureResponse?.currentCheckBoxValue === 'Get Value of JSON Path' && (
              <JsonPath
                isEditable={isEditable}
                jsonPath={jsonPath}
                handleArrayValues={(fieldName, fieldValue, index) =>
                  handleArrayValues(jsonPath, setJsonPath, fieldName, fieldValue, index)
                }
                setJsonPath={setJsonPath}
                jsonPathChange={jsonPathChange}
                jsonPathValue={jsonPathValue}
                handleChildElementClick={handleChildElementClick}
                handleCaptureError={props.handleCaptureDataResponseError}
                isEnabled={jsonPath.isEnabled}
                handleAdd={() => handleAdd(setJsonPath, jsonPathDefaultValue)}
                jsonListRemove={(id, setFieldValue, setFieldError, setFieldTouched) =>
                  removeList(
                    jsonPath,
                    setJsonPath,
                    id,
                    jsonPathDefaultValue,
                    'jsonPathError',
                    setFieldValue,
                    setFieldError,
                    setFieldTouched
                  )
                }
                props={props}
                jsonPathDefaultValue={jsonPathDefaultValue}
              />
            )}
          </CheckLayout>
          <CheckLayout
            data={headerKey}
            isEnabled={headerKey.isEnabled}
            isOpened={headerKey.isOpened}
            handleClick={() => handleClick(headerKey, setHeaderKey)}
            handleChange={() => handleCheckbox(headerKey, setHeaderKey)}
            fieldValue={headerKeyValue}
          >
            {context?.values?.captureResponse?.currentCheckBoxValue === 'Get Header for a Key' && (
              <HeaderKey
                isEditable={isEditable}
                handleArrayValues={(fieldName, fieldValue, index) =>
                  handleArrayValues(headerKey, setHeaderKey, fieldName, fieldValue, index)
                }
                headerKey={headerKey}
                setHeaderKey={setHeaderKey}
                headerKeyChange={headerKeyChange}
                headerKeyValue={headerKeyValue}
                handleChildElementClick={handleChildElementClick}
                handleCaptureError={props.handleCaptureDataResponseError}
                isEnabled={headerKey.isEnabled}
                handleAdd={() => handleAdd(setHeaderKey, headerKeyDefaultValue)}
                headersListRemove={(id, setFieldValue, setFieldError, setFieldTouched) =>
                  removeList(
                    headerKey,
                    setHeaderKey,
                    id,
                    headerKeyDefaultValue,
                    'headerKeyError',
                    setFieldValue,
                    setFieldError,
                    setFieldTouched
                  )
                }
                props={props}
                headerKeyDefaultValue={headerKeyDefaultValue}
              />
            )}
          </CheckLayout>
          <CheckLayout
            data={contentType}
            isEnabled={contentType.isEnabled}
            isOpened={contentType.isOpened}
            handleClick={() => handleClick(contentType, setContentType)}
            handleChange={() => handleCheckbox(contentType, setContentType)}
            fieldValue={contentTypeValue?.name || context.values?.contentType?.name}
          >
            {context?.values?.captureResponse?.currentCheckBoxValue === 'Get Content Type' && (
              <ContentType
                isEditable={isEditable}
                handleArrayValues={(fieldName, fieldValue, index) =>
                  handleArrayValues(contentType, setContentType, fieldName, fieldValue, index)
                }
                contentType={contentType}
                contentTypeChange={contentTypeChange}
                contentTypeValue={contentTypeValue}
                handleChildElementClick={handleChildElementClick}
                handleCaptureError={props.handleCaptureDataResponseError}
                isEnabled={contentType.isEnabled}
                props={props}
              />
            )}
          </CheckLayout>
          <CheckLayout
            data={bcookie}
            isEnabled={bcookie.isEnabled}
            isOpened={bcookie.isOpened}
            handleClick={() => handleClick(bcookie, setCookie)}
            handleChange={() => handleCheckbox(bcookie, setCookie)}
          >
            {context?.values?.captureResponse?.currentCheckBoxValue === 'Get Cookie' && (
              <Bcookie
                isEditable={isEditable}
                handleArrayValues={(fieldName, fieldValue, index) =>
                  handleArrayValues(bcookie, setCookie, fieldName, fieldValue, index)
                }
                bcookie={bcookie}
              />
            )}
          </CheckLayout>
          <CheckLayout
            data={bcookies}
            isEnabled={bcookies.isEnabled}
            isOpened={bcookies.isOpened}
            handleClick={() => handleClick(bcookies, setCookies)}
            handleChange={() => handleCheckbox(bcookies, setCookies)}
          >
            {context?.values?.captureResponse?.currentCheckBoxValue === 'Get Cookies' && (
              <Bcookies
                isEditable={isEditable}
                handleArrayValues={(fieldName, fieldValue, index) =>
                  handleArrayValues(bcookies, setCookies, fieldName, fieldValue, index)
                }
                bcookies={bcookies}
              />
            )}
          </CheckLayout>
          <CheckLayout
            data={detailCookie}
            isEnabled={detailCookie.isEnabled}
            isOpened={detailCookie.isOpened}
            handleClick={() => handleClick(detailCookie, setDetailCookie)}
            handleChange={() => handleCheckbox(detailCookie, setDetailCookie)}
          >
            {context?.values?.captureResponse?.currentCheckBoxValue === 'Get Detailed Cookie' && (
              <Detailscookie
                isEditable={isEditable}
                handleArrayValues={(fieldName, fieldValue, index) =>
                  handleArrayValues(detailCookie, setDetailCookie, fieldName, fieldValue, index)
                }
                detailCookie={detailCookie}
              />
            )}
          </CheckLayout>
          <CheckLayout
            data={detailCookies}
            isEnabled={detailCookies.isEnabled}
            isOpened={detailCookies.isOpened}
            handleClick={() => handleClick(detailCookies, setDetailCookies)}
            handleChange={() => handleCheckbox(detailCookies, setDetailCookies)}
          >
            {context?.values?.captureResponse?.currentCheckBoxValue === 'Get Detailed Cookies' && (
              <Detailscookies
                isEditable={isEditable}
                handleArrayValues={(fieldName, fieldValue, index) =>
                  handleArrayValues(detailCookies, setDetailCookies, fieldName, fieldValue, index)
                }
                detailCookies={detailCookies}
              />
            )}
          </CheckLayout>
          <CheckLayout
            data={sessionId}
            isEnabled={sessionId.isEnabled}
            isOpened={sessionId.isOpened}
            handleClick={() => handleClick(sessionId, setSessionId)}
            handleChange={() => handleCheckbox(sessionId, setSessionId)}
          >
            {context?.values?.captureResponse?.currentCheckBoxValue === 'Get Session ID' && (
              <SessionId
                isEditable={isEditable}
                handleArrayValues={(fieldName, fieldValue, index) =>
                  handleArrayValues(sessionId, setSessionId, fieldName, fieldValue, index)
                }
                sessionId={sessionId}
              />
            )}
          </CheckLayout>
          <CheckLayout
            data={statusLine}
            isEnabled={statusLine.isEnabled}
            isOpened={statusLine.isOpened}
            handleClick={() => handleClick(statusLine, setStatusLine)}
            handleChange={() => handleCheckbox(statusLine, setStatusLine)}
          >
            {context?.values?.captureResponse?.currentCheckBoxValue === 'Get Status Line' && (
              <StatusLine
                isEditable={isEditable}
                handleArrayValues={(fieldName, fieldValue, index) =>
                  handleArrayValues(statusLine, setStatusLine, fieldName, fieldValue, index)
                }
                statusLine={statusLine}
              />
            )}
          </CheckLayout>
          <CheckLayout
            data={dTime}
            isEnabled={dTime.isEnabled}
            isOpened={dTime.isOpened}
            handleClick={() => handleClick(dTime, setDTime)}
            handleChange={() => handleCheckbox(dTime, setDTime)}
          >
            {context?.values?.captureResponse?.currentCheckBoxValue === 'Get Time' && (
              <Btime
                isEditable={isEditable}
                handleArrayValues={(fieldName, fieldValue, index) =>
                  handleArrayValues(dTime, setDTime, fieldName, fieldValue, index)
                }
                dTime={dTime}
              />
            )}
          </CheckLayout>
        </div>
        <div className="w-4/5 flex items-center justify-center default-assert-section-container-main">
          <div className="w-full flex flex-col items-center request-body-none-center fontPoppinsRegularLg relative">
            {!context?.values?.captureResponse?.currentCheckBoxValue && (
              <div className="flex flex-col items-center border border-gray-800 default-template">
                <div className="mt-7">No "Capture Data" From Response is selected!</div>
                <div className="mt-0.5 mr-7 mb-7 ml-7">
                  Please click on any "Capture Data" to add Capture data from the response.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const ResponseTime = ({
  isEditable = true,
  handleArrayValues,
  responseTimeData,
  responseChange,
  responseTime,
  handleChildElementClick,
  handleCaptureError,
  isEnabled,
  props,
}) => {
  const context = useContext(RestContext);
  const [returnVcaluePayload, setReturnValuePayload] = useState({});
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  const formik = useFormik({
    initialValues: {},
  });
  const { handleChange, handleBlur, setFieldValue, setFieldError } = formik;
  const setReturnValue = (data, type) => {
    setReturnValuePayload({
      returnType: data.name,
      stepGroupReferenceInfo: {
        type: type,
        name: data.name,
      },
      data: data,
    });
    handleArrayValues(
      'responseTime',
      { ...data, value: responseTime, title: 'metaData.apiTime', group: 'Response Time', isEnabled: true },
      0
    );
  };

  const removeStepRef = () => {
    if (returnVcaluePayload?.stepGroupReferenceInfo) {
      setReturnValuePayload({ returnType: returnVcaluePayload.returnType, stepGroupReferenceInfo: null });
    }
  };

  return (
    <div className="sections-main-container w-full pr-3.5 ">
      <div className="w-1/5"></div>
      <div onClick={handleChildElementClick} className="capture-response-data">
        <div className="header-default-assert mb-4 pl-2.5 fontPoppinsRegularSm">Get Response Time</div>
        <div className="dropdown-container">
          <FormikProvider value={formik}>
            <VariableDropdown
              canCreateVariable={isEditable}
              localVariableData={[]}
              data={responseTimeData || context.values?.responseTimeData}
              onChange={(e) => {
                responseChange(e);
              }}
              stepInputType="abc"
              id={'return-value1'}
              moduleId={moduleId}
              scriptId={scriptId}
              stepInputName={'returnValue'}
              setReturnValue={setReturnValue}
              type="Test Development"
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              removeStepRef={removeStepRef}
              handleCaptureError={(status) => handleCaptureError('responseTimeError', status)}
              isEnabled={isEnabled}
              isExistVariable={props.getUserInputValue(responseTimeData?.name, false) !== responseTimeData?.name}
              dataVal={responseTimeData || context.values?.responseTimeData}
              localVariable={props.localVariable}
              globalVariable={props.globalVariable}
              onSetAllVariableName={props.onSetAllVariableName}
              getUserInputValue={props.getUserInputValue}
              MyAlert={props.MyAlert}
              handleSaveAsSteps={props.handleSaveAsSteps}
              variableDetails={responseTime?.values?.[0]?.responseTime}
              projectEnvVariable={props.projectEnvVariable}
              stepGroupVariable={props.filteredStepGroupVariable}
              metaError={'ResponseTimeError'}
              isImportType={props?.selectedStepData?.type}
              cdfrResponseVariableUpdate={props?.cdfrResponseVariableUpdate}
            />
          </FormikProvider>
        </div>
      </div>
    </div>
  );
};

const VerifyStatusCodeComponent = ({
  isEditable = true,
  handleArrayValues,
  data,
  dataChange,
  verifyStatusCode,
  handleChildElementClick,
  handleCaptureError,
  isEnabled,
  props,
}) => {
  const context = useContext(RestContext);
  const [returnVcaluePayload, setReturnValuePayload] = useState({});
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  const formik = useFormik({
    initialValues: {},
  });
  const { handleChange, handleBlur, setFieldValue, setFieldError } = formik;

  const setReturnValue = (data, stepInputName, type) => {
    setReturnValuePayload({
      returnType: data.name,
      stepGroupReferenceInfo: {
        type: type,
        name: data.name,
      },
      data: data,
    });
    handleArrayValues(
      'statusCode',
      {
        ...data,
        value: verifyStatusCode,
        title: 'statusCode',
        group: 'Status code',
        isEnabled: true,
      },
      0
    );
  };

  const removeStepRef = () => {
    if (returnVcaluePayload?.stepGroupReferenceInfo) {
      setReturnValuePayload({ returnType: returnVcaluePayload.returnType, stepGroupReferenceInfo: null });
    }
  };

  return (
    <div className="sections-main-container w-full pr-3.5 ">
      <div className="w-1/5"></div>
      <div onClick={handleChildElementClick} className="capture-response-data">
        <div className="header-default-assert mb-4 pl-2.5 fontPoppinsRegularSm">Get Status Code</div>
        <div className="flex">
          <FormikProvider value={formik}>
            <VariableDropdown
              canCreateVariable={isEditable}
              localVariableData={[]}
              data={data || context.values?.statusCode}
              onChange={(e) => {
                dataChange(e);
              }}
              stepInputType="abc"
              id={'return-value'}
              moduleId={moduleId}
              scriptId={scriptId}
              stepInputName={'returnValue'}
              setReturnValue={setReturnValue}
              type="Test Development"
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              removeStepRef={removeStepRef}
              handleCaptureError={(status) => handleCaptureError('statusCodeError', status)}
              isEnabled={isEnabled}
              isExistVariable={props.getUserInputValue(data?.name, false) !== data?.name}
              dataVal={data || context.values?.statusCode}
              localVariable={props.localVariable}
              globalVariable={props.globalVariable}
              onSetAllVariableName={props.onSetAllVariableName}
              getUserInputValue={props.getUserInputValue}
              MyAlert={props.MyAlert}
              handleSaveAsSteps={props.handleSaveAsSteps}
              variableDetails={verifyStatusCode?.values?.[0]?.statusCode}
              projectEnvVariable={props.projectEnvVariable}
              stepGroupVariable={props.filteredStepGroupVariable}
              metaError={'statusCodeError'}
              isImportType={props?.selectedStepData?.type}
              cdfrResponseVariableUpdate={props?.cdfrResponseVariableUpdate}
            />
          </FormikProvider>
        </div>
      </div>
    </div>
  );
};

const ResponseBody = ({
  isEditable = true,
  handleArrayValues,
  responseBodyData,
  responseBodyChange,
  responseBody,
  handleChildElementClick,
  handleCaptureError,
  isEnabled,
  props,
}) => {
  const context = useContext(RestContext);
  const [returnValuePayload, setReturnValuePayload] = useState({});
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  const formik = useFormik({
    initialValues: {},
  });
  const { handleChange, handleBlur, setFieldValue, setFieldError } = formik;
  const setReturnValue = (data, stepInputName, type) => {
    setReturnValuePayload({
      returnType: data.name,
      stepGroupReferenceInfo: {
        type: type,
        name: data.name,
      },
      data: data,
    });
    handleArrayValues(
      'responseBody',
      {
        ...data,
        value: responseBody,
        title: 'responseBody',
        group: 'Response Body',
        isEnabled: responseBody.isEnabled,
      },
      0
    );
  };

  const removeStepRef = () => {
    if (returnValuePayload?.stepGroupReferenceInfo) {
      setReturnValuePayload({ returnType: returnValuePayload.returnType, stepGroupReferenceInfo: null });
    }
  };
  return (
    <div className="sections-main-container w-full pr-3.5 ">
      <div className="w-1/5"></div>
      <div onClick={handleChildElementClick} className="capture-response-data">
        <div className="header-default-assert mb-4 pl-2.5 fontPoppinsRegularSm">Get Response Body</div>
        <div className="dropdown-container">
          <FormikProvider value={formik}>
            <VariableDropdown
              canCreateVariable={isEditable}
              localVariableData={[]}
              data={responseBodyData || context.values?.responseBodyData}
              onChange={(e) => {
                responseBodyChange(e);
              }}
              stepInputType="abc"
              id={'return-value2'}
              moduleId={moduleId}
              scriptId={scriptId}
              stepInputName={'returnValue'}
              setReturnValue={setReturnValue}
              type="Test Development"
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              removeStepRef={removeStepRef}
              handleCaptureError={(status) => handleCaptureError('responseBodyError', status)}
              isEnabled={isEnabled}
              isExistVariable={props.getUserInputValue(responseBodyData?.name, false) !== responseBodyData?.name}
              dataVal={responseBodyData || context.values?.responseBodyData}
              localVariable={props.localVariable}
              globalVariable={props.globalVariable}
              onSetAllVariableName={props.onSetAllVariableName}
              getUserInputValue={props.getUserInputValue}
              MyAlert={props.MyAlert}
              handleSaveAsSteps={props.handleSaveAsSteps}
              variableDetails={responseBody?.values?.[0]?.responseBody}
              projectEnvVariable={props.projectEnvVariable}
              stepGroupVariable={props.filteredStepGroupVariable}
              metaError={'responseBodyError'}
              isImportType={props?.selectedStepData?.type}
              cdfrResponseVariableUpdate={props?.cdfrResponseVariableUpdate}
            />
          </FormikProvider>
        </div>
      </div>
    </div>
  );
};

const Headers = ({
  isEditable = true,
  handleArrayValues,
  getHeader,
  getHeaderChange,
  headers,
  handleChildElementClick,
  handleCaptureError,
  isEnabled,
  props,
}) => {
  const context = useContext(RestContext);
  const [returnValuePayload, setReturnValuePayload] = useState({});
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  const formik = useFormik({
    initialValues: {},
  });
  const { handleChange, handleBlur, setFieldValue, setFieldError } = formik;
  const setReturnValue = (data, stepInputName, type) => {
    setReturnValuePayload({
      returnType: data.name,
      stepGroupReferenceInfo: {
        type: type,
        name: data.name,
      },
      data: data,
    });
    handleArrayValues('headers', { ...data, value: headers, title: 'headers', group: 'Headers', isEnabled: true }, 0);
  };

  const removeStepRef = () => {
    if (returnValuePayload?.stepGroupReferenceInfo) {
      setReturnValuePayload({ returnType: returnValuePayload.returnType, stepGroupReferenceInfo: null });
    }
  };
  return (
    <div className="sections-main-container w-full pr-3.5 ">
      <div className="w-1/5"></div>
      <div onClick={handleChildElementClick} className="capture-response-data">
        <div className="header-default-assert mb-4  pl-2.5 fontPoppinsRegularSm">Get Headers</div>
        <FormikProvider value={formik}>
          <VariableDropdown
            canCreateVariable={isEditable}
            data={getHeader || context.values?.getHeaders}
            onChange={(e) => {
              getHeaderChange(e);
            }}
            localVariableData={[]}
            stepInputType="abc"
            id={'return-value4'}
            moduleId={moduleId}
            scriptId={scriptId}
            stepInputName={'returnValue'}
            setReturnValue={setReturnValue}
            type="Test Development"
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            removeStepRef={removeStepRef}
            handleCaptureError={(status) => handleCaptureError('headerError', status)}
            isEnabled={isEnabled}
            isExistVariable={props.getUserInputValue(getHeader?.name, false) !== getHeader?.name}
            dataVal={getHeader || context.values?.getHeaders}
            localVariable={props.localVariable}
            globalVariable={props.globalVariable}
            onSetAllVariableName={props.onSetAllVariableName}
            getUserInputValue={props.getUserInputValue}
            MyAlert={props.MyAlert}
            handleSaveAsSteps={props.handleSaveAsSteps}
            variableDetails={headers?.values?.[0]?.headers}
            projectEnvVariable={props.projectEnvVariable}
            stepGroupVariable={props.filteredStepGroupVariable}
            metaError={'headersError'}
            isImportType={props?.selectedStepData?.type}
            cdfrResponseVariableUpdate={props?.cdfrResponseVariableUpdate}
          />
        </FormikProvider>
      </div>
    </div>
  );
};

const JsonPath = ({
  isEditable = true,
  jsonPath,
  handleArrayValues,
  handleChildElementClick,
  handleCaptureError,
  isEnabled,
  handleAdd,
  jsonListRemove,
  props,
}) => {
  const context = useContext(RestContext);
  const { CAPTURE_GET_JSON_PATH } = WEBSERVICE;
  const fields = jsonPath.values;
  const [returnValuePayload, setReturnValuePayload] = useState();
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  const [currentCaptureValue, setCurrentCaptureValue] = useState({});
  const formik = useFormik({
    initialValues: {},
  });
  const { handleChange, handleBlur, setFieldValue, setFieldError, setFieldTouched } = formik;
  const setReturnValue = (data, stepInputName, type, id) => {
    setReturnValuePayload({
      returnType: data.name,
      stepGroupReferenceInfo: {
        type: type,
        name: data.name,
      },
      data: data,
    });

    handleArrayValues(
      'jsonPathValue',
      {
        ...data,
        title: context.values.captureResponse[CAPTURE_GET_JSON_PATH].values[id].lhs,
        group: 'JSON Path',
        isEnabled: true,
      },
      id
    );
  };

  const removeStepRef = () => {
    if (returnValuePayload?.stepGroupReferenceInfo) {
      setReturnValuePayload({ returnType: returnValuePayload.returnType, stepGroupReferenceInfo: null });
    }
  };
  const handleKeyPress = (e, defaultJsonPath) => {
    const currentPosition = e.target.selectionStart;
    if (currentPosition < defaultJsonPath.length) {
      e.preventDefault();
    }
  };

  const isDeleteStyle = fields.length === 1 && fields[0]?.lhs === 'responseBody' && !fields[0]?.returnValue?.length > 0;

  const isAddButtonStyle = (field) =>
    field?.returnValue && props?.getUserInputValue(field?.returnValue) !== field?.returnValue && field.name;

  return (
    <div className="sections-main-container w-full pr-3.5 ">
      <div className="w-1/5"></div>
      <div onClick={handleChildElementClick} className="capture-response-data">
        <div className="header-default-assert mb-4  pl-2.5 fontPoppinsRegularSm">Get Value of JSON Path</div>
        {jsonPath.values.map((field, idx) => {
          const jsonPathErrorKey = 'jsonPathName' + idx;
          const jsonPathErrorValue = props?.captureDataError?.[jsonPathErrorKey];

          return (
            <div className="flex relative  mb-3 mr-14" key={`${field}-${idx}`}>
              <div className="default-capture-field-header-key">
                <MyInput
                  type="text"
                  placeholder="Enter name"
                  name="name"
                  id="name"
                  onChange={(e) => handleArrayValues(e.target.name, e.target.value, idx)}
                  label={
                    <div className="fontPoppinsMediumSm capture-name-text-label">
                      <span className="text-red-400 mr-1">&#42;</span>
                      Name
                    </div>
                  }
                  variableInput={false}
                  autoComplete="off"
                  value={field?.name}
                  handleSaveAsSteps={props.handleSaveAsSteps}
                />
                {jsonPathErrorValue && <div className="assert-section-errors">{jsonPathErrorValue}</div>}
              </div>
              <div className="flex default-capture-field-json-custom-input">
                <MyInput
                  type="text"
                  placeholder="Enter JSON path"
                  name="lhs"
                  id="lhs"
                  autoFocus={true}
                  autoComplete="off"
                  onChange={(e) => {
                    setCurrentCaptureValue(e);
                    handleArrayValues(e.target.name, e.target.value, idx);
                  }}
                  value={props.getUserInputValue(field.lhs, true)}
                  handleSaveAsSteps={props.handleSaveAsSteps}
                  onKeyPress={(e) => handleKeyPress(e, 'responseBody')}
                  label={
                    <div className="fontPoppinsMediumSm capture-name-text-label">
                      <span className="text-red-400 mr-1">&#42;</span>
                      JSON Path
                    </div>
                  }
                  canCreateVariable={false}
                  variableInput={true}
                  currentValue={currentCaptureValue}
                  {...props}
                />
              </div>
              <div className="flex default-capture-field-json-path">
                <FormikProvider value={formik}>
                  <VariableDropdown
                    canCreateVariable={isEditable}
                    localVariableData={[]}
                    stepInputType="abc"
                    id={`return-value5${idx}`}
                    moduleId={moduleId}
                    scriptId={scriptId}
                    stepInputName={`returnValue${idx}`}
                    setReturnValue={setReturnValue}
                    type="Test Development"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    setFieldError={setFieldError}
                    removeStepRef={removeStepRef}
                    dataValue={field?.returnValue || ''}
                    onChange={(e) => handleArrayValues(e.target.name, e.target.value, idx)}
                    handleCaptureError={(status) => handleCaptureError('jsonPathError' + idx, status)}
                    isEnabled={isEnabled}
                    isExistVariable={props.getUserInputValue(field?.returnValue, false) !== field?.returnValue}
                    fieldLhs={field.lhs}
                    idx={idx}
                    localVariable={props.localVariable}
                    globalVariable={props.globalVariable}
                    onSetAllVariableName={props.onSetAllVariableName}
                    getUserInputValue={props.getUserInputValue}
                    MyAlert={props.MyAlert}
                    handleSaveAsSteps={props.handleSaveAsSteps}
                    variableDetails={jsonPath?.values?.[idx]?.jsonPathValue}
                    projectEnvVariable={props.projectEnvVariable}
                    stepGroupVariable={props.filteredStepGroupVariable}
                    metaError={`jsonPathError${idx}`}
                    isImportType={props?.selectedStepData?.type}
                    cdfrResponseVariableUpdate={props?.cdfrResponseVariableUpdate}
                  />
                </FormikProvider>
              </div>

              <div className="mt-6 ml-2">
                <CustomTooltip title={'Delete'} placement="bottom">
                  <DeleteIcon
                    type="button"
                    className={classNames(
                      {
                        'text-gray-300': isDeleteStyle,
                        'text-gray-500 assert-cdfr-delete-enable-effect': !isDeleteStyle,
                      },
                      'cursor-pointer ml-6'
                    )}
                    hidden={fields.length <= 1}
                    onClick={() => {
                      jsonListRemove(idx, setFieldValue, setFieldError, setFieldTouched);
                    }}
                  />
                </CustomTooltip>
              </div>
              {fields.length - 1 === idx ? (
                <div className="right-4 mt-6">
                  <CustomTooltip title="Add">
                    <button
                      type="button"
                      className={classNames(
                        {
                          'text-gray-300 p-0.5': !isAddButtonStyle(field),
                          'text-gray-500 assert-cdfr-add-enable-effect': isAddButtonStyle(field),
                        },
                        'cursor-pointer ml-3'
                      )}
                      onClick={handleAdd}
                      disabled={
                        !(
                          field?.returnValue &&
                          props?.getUserInputValue(field?.returnValue) !== field?.returnValue &&
                          field.name
                        )
                      }
                    >
                      <AddIcon />
                    </button>
                  </CustomTooltip>
                </div>
              ) : (
                <div className="w-11"></div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const HeaderKey = ({
  isEditable = true,
  headerKey,
  handleArrayValues,
  handleChildElementClick,
  handleCaptureError,
  isEnabled,
  handleAdd,
  headersListRemove,
  props,
}) => {
  const context = useContext(RestContext);
  const { CAPTURE_GET_HEADER_KEY } = WEBSERVICE;
  const [returnValuePayload, setReturnValuePayload] = useState();
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  const fields = headerKey.values;
  const formik = useFormik({
    initialValues: {},
  });

  const { handleChange, handleBlur, setFieldValue, setFieldError, setFieldTouched } = formik;

  const setReturnValue = (data, stepInputName, type, id) => {
    setReturnValuePayload({
      returnType: data.name,
      stepGroupReferenceInfo: {
        type: type,
        name: data.name,
      },
      data: data,
    });

    handleArrayValues(
      'headerKeyValue',
      {
        ...data,
        title: context.values.captureResponse[CAPTURE_GET_HEADER_KEY].values[id].lhs,
        group: 'Header Key',
        isEnabled: true,
      },
      id
    );
  };

  const removeStepRef = () => {
    if (returnValuePayload?.stepGroupReferenceInfo) {
      setReturnValuePayload({ returnType: returnValuePayload.returnType, stepGroupReferenceInfo: null });
    }
  };

  return (
    <div className="sections-main-container w-full pr-3.5 ">
      <div className="w-1/5"></div>
      <div onClick={handleChildElementClick} className="capture-response-data">
        <div className="header-default-assert mb-4 pl-2.5 fontPoppinsRegularSm">Get Header for a Key</div>
        {headerKey.values.map((field, idx) => {
          const checkLhsField = field.lhs.substring(17, field.lhs.length - 12);
          const headerKeyError = 'headerKeyName' + idx;
          const headerKeyErrorValue = props?.captureDataError?.[headerKeyError];
          return (
            <>
              <div className="flex relative  mb-3 mr-14">
                <div className="default-capture-field-header-key">
                  <MyInput
                    type="text"
                    placeholder="Enter name"
                    name="name"
                    id="name"
                    onChange={(e) => handleArrayValues(e.target.name, e.target.value, idx)}
                    label={
                      <div className="fontPoppinsMediumSm capture-name-text-label">
                        <span className="text-red-400 mr-1">&#42;</span>
                        Name
                      </div>
                    }
                    variableInput={false}
                    autoComplete="off"
                    value={field?.name}
                    handleSaveAsSteps={props.handleSaveAsSteps}
                  />
                  {headerKeyErrorValue && <div className="assert-section-errors">{headerKeyErrorValue}</div>}
                </div>
                <div className="flex default-capture-field-json-custom-input">
                  <MyInput
                    type="text"
                    placeholder="Enter header key"
                    name="lhs"
                    id="lhs"
                    autoFocus={true}
                    onChange={(e) => handleArrayValues(e.target.name, e.target.value, idx)}
                    value={field.lhs.substring(17, field.lhs.length - 12)}
                    handleSaveAsSteps={props.handleSaveAsSteps}
                    label={
                      <div className="fontPoppinsMediumSm capture-name-text-label">
                        <span className="text-red-400 mr-1">&#42;</span>
                        Header Key
                      </div>
                    }
                  />
                </div>
                <div className="flex default-capture-field-json-path">
                  <FormikProvider value={formik}>
                    <VariableDropdown
                      canCreateVariable={isEditable}
                      localVariableData={[]}
                      stepInputType="abc"
                      id={`return-value6${idx}`}
                      moduleId={moduleId}
                      scriptId={scriptId}
                      stepInputName={`returnValue${idx}`}
                      setReturnValue={setReturnValue}
                      type="Test Development"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      removeStepRef={removeStepRef}
                      onChange={(e) => handleArrayValues(e.target.name, e.target.value, idx)}
                      dataValue={field?.returnValue || ''}
                      handleCaptureError={(status) => handleCaptureError('headerKeyError' + idx, status)}
                      isEnabled={isEnabled}
                      isExistVariable={props.getUserInputValue(field?.returnValue, false) !== field?.returnValue}
                      fieldLhs={field.lhs.substring(17, field.lhs.length - 12)}
                      idx={idx}
                      localVariable={props.localVariable}
                      globalVariable={props.globalVariable}
                      onSetAllVariableName={props.onSetAllVariableName}
                      getUserInputValue={props.getUserInputValue}
                      MyAlert={props.MyAlert}
                      handleSaveAsSteps={props.handleSaveAsSteps}
                      variableDetails={headerKey?.values?.[idx]?.headerKeyValue}
                      projectEnvVariable={props.projectEnvVariable}
                      stepGroupVariable={props.filteredStepGroupVariable}
                      metaError={`headerKeyError${idx}`}
                      isImportType={props?.selectedStepData?.type}
                      cdfrResponseVariableUpdate={props?.cdfrResponseVariableUpdate}
                    />
                  </FormikProvider>
                </div>
                <div className="ml-5 mt-6">
                  <CustomTooltip title={'Delete'} placement="bottom">
                    <span
                      className={`${
                        fields.length === 1 &&
                        !fields[0].returnValue?.length &&
                        !fields[0].lhs.substring(17, fields[0].lhs?.length - 12)
                          ? ' text-gray-300 Data'
                          : 'text-gray-500 assert-cdfr-delete-enable-effect'
                      } cursor-pointer   ml-2 `}
                    >
                      <DeleteIcon
                        type="button"
                        hidden={fields.length <= 1}
                        onClick={() => {
                          headersListRemove(idx, setFieldValue, setFieldError, setFieldTouched);
                        }}
                      />
                    </span>
                  </CustomTooltip>
                </div>
                {fields.length - 1 === idx ? (
                  <div className=" right-4 mt-6">
                    <CustomTooltip title="Add">
                      <button
                        type="button"
                        className={`${
                          !(
                            checkLhsField &&
                            field?.returnValue &&
                            props?.getUserInputValue(field?.returnValue) !== field?.returnValue &&
                            field.name
                          )
                            ? 'text-gray-300 p-0.5'
                            : 'Data text-gray-500 assert-cdfr-add-enable-effect'
                        }  cursor-pointer ml-1.5 `}
                        onClick={handleAdd}
                        disabled={
                          !(
                            checkLhsField &&
                            field?.returnValue &&
                            props?.getUserInputValue(field?.returnValue) !== field?.returnValue &&
                            field.name
                          )
                        }
                      >
                        <AddIcon />
                      </button>
                    </CustomTooltip>
                  </div>
                ) : (
                  <div className="w-11"></div>
                )}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

const ContentType = ({
  isEditable = true,
  handleArrayValues,
  contentType,
  contentTypeValue,
  contentTypeChange,
  handleChildElementClick,
  handleCaptureError,
  isEnabled,
  props,
}) => {
  const context = useContext(RestContext);
  const [returnValuePayload, setReturnValuePayload] = useState({});
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  const formik = useFormik({
    initialValues: {},
  });
  const { handleChange, handleBlur, setFieldValue, setFieldError } = formik;
  const setReturnValue = (data, stepInputName, type) => {
    setReturnValuePayload({
      returnType: data.name,
      stepGroupReferenceInfo: {
        type: type,
        name: data.name,
      },
      data: data,
    });
    handleArrayValues(
      'contentType',
      {
        ...data,
        value: contentType,
        title: 'contentType',
        group: 'Content type',
        isEnabled: true,
      },
      0
    );
  };

  const removeStepRef = () => {
    if (returnValuePayload?.stepGroupReferenceInfo) {
      setReturnValuePayload({ returnType: returnValuePayload.returnType, stepGroupReferenceInfo: null });
    }
  };
  return (
    <div className="sections-main-container w-full pr-3.5 ">
      <div className="w-1/5"></div>
      <div onClick={handleChildElementClick} className="capture-response-data">
        <div className="header-default-assert mb-4  pl-2.5 fontPoppinsRegularSm">Get Content Type</div>
        <FormikProvider value={formik}>
          <VariableDropdown
            canCreateVariable={isEditable}
            localVariableData={[]}
            stepInputType="abc"
            id={'return-value7'}
            moduleId={moduleId}
            scriptId={scriptId}
            stepInputName={'returnValue'}
            setReturnValue={setReturnValue}
            type="Test Development"
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            removeStepRef={removeStepRef}
            onChange={(e) => {
              contentTypeChange(e);
            }}
            data={contentTypeValue || context.values?.contentType}
            handleCaptureError={(status) => handleCaptureError('contentTypeError', status)}
            isEnabled={isEnabled}
            isExistVariable={props.getUserInputValue(contentTypeValue?.name, false) !== contentTypeValue?.name}
            dataVal={contentTypeValue || context.values?.contentType}
            localVariable={props.localVariable}
            globalVariable={props.globalVariable}
            onSetAllVariableName={props.onSetAllVariableName}
            getUserInputValue={props.getUserInputValue}
            MyAlert={props.MyAlert}
            handleSaveAsSteps={props.handleSaveAsSteps}
            variableDetails={contentType?.values?.[0]?.contentType}
            projectEnvVariable={props.projectEnvVariable}
            stepGroupVariable={props.filteredStepGroupVariable}
            metaError={`contentTypeError`}
            isImportType={props?.selectedStepData?.type}
            cdfrResponseVariableUpdate={props?.cdfrResponseVariableUpdate}
          />
        </FormikProvider>
      </div>
    </div>
  );
};

const Bcookie = ({ isEditable = true, handleArrayValues }) => {
  const [returnValuePayload, setReturnValuePayload] = useState();
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  const formik = useFormik({
    initialValues: {},
  });
  const { handleChange, handleBlur, setFieldValue, setFieldError } = formik;
  const setReturnValue = (data, stepInputName, type) => {
    setReturnValuePayload({
      returnType: data.name,
      stepGroupReferenceInfo: {
        type: type,
        name: data.name,
      },
    });
  };

  const removeStepRef = () => {
    if (returnValuePayload?.stepGroupReferenceInfo) {
      setReturnValuePayload({ returnType: returnValuePayload.returnType, stepGroupReferenceInfo: null });
    }
  };
  return (
    <div className="flex p-2 -mt-4 capture-cookie">
      {' '}
      <div className="first-inp">
        <FormikProvider value={formik}>
          <VariableDropdown
            canCreateVariable={isEditable}
            localVariableData={[]}
            stepInputType="abc"
            id={'return-value8'}
            moduleId={moduleId}
            scriptId={scriptId}
            stepInputName={'returnValue'}
            setReturnValue={setReturnValue}
            type="Test Development"
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            removeStepRef={removeStepRef}
          />
        </FormikProvider>
      </div>
      <MyInput
        className="w-full"
        type="text"
        placeholder="Enter cookie"
        name="cookie"
        id="cookie"
        autoFocus={true}
        onChange={(e) => handleArrayValues(e.target.name, e.target.value)}
        label={
          <>
            {' '}
            <span className="text-red-400">*</span> Cookie
          </>
        }
      />
    </div>
  );
};
const Bcookies = ({ isEditable = true }) => {
  const [returnValuePayload, setReturnValuePayload] = useState();
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  const formik = useFormik({
    initialValues: {},
  });
  const { handleChange, handleBlur, setFieldValue, setFieldError } = formik;
  const setReturnValue = (data, stepInputName, type) => {
    setReturnValuePayload({
      returnType: data.name,
      stepGroupReferenceInfo: {
        type: type,
        name: data.name,
      },
    });
  };

  const removeStepRef = () => {
    if (returnValuePayload?.stepGroupReferenceInfo) {
      setReturnValuePayload({ returnType: returnValuePayload.returnType, stepGroupReferenceInfo: null });
    }
  };
  return (
    <div className="flex flex-col p-2 -mt-4 ">
      <div className=" capture-cookies pt-5">
        <FormikProvider value={formik}>
          <VariableDropdown
            canCreateVariable={isEditable}
            localVariableData={[]}
            stepInputType="abc"
            id={'return-value9'}
            moduleId={moduleId}
            scriptId={scriptId}
            stepInputName={'returnValue'}
            setReturnValue={setReturnValue}
            type="Test Development"
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            removeStepRef={removeStepRef}
          />
        </FormikProvider>
      </div>
    </div>
  );
};
const Detailscookie = ({ isEditable = true, handleArrayValues }) => {
  const [returnValuePayload, setReturnValuePayload] = useState();
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  const formik = useFormik({
    initialValues: {},
  });
  const { handleChange, handleBlur, setFieldValue, setFieldError } = formik;
  const setReturnValue = (data, stepInputName, type) => {
    setReturnValuePayload({
      returnType: data.name,
      stepGroupReferenceInfo: {
        type: type,
        name: data.name,
      },
    });
  };

  const removeStepRef = () => {
    if (returnValuePayload?.stepGroupReferenceInfo) {
      setReturnValuePayload({ returnType: returnValuePayload.returnType, stepGroupReferenceInfo: null });
    }
  };
  return (
    <div className="flex p-2 -mt-4 capture-detail-cookie">
      {' '}
      <div className="first-inp">
        <FormikProvider value={formik}>
          <VariableDropdown
            canCreateVariable={isEditable}
            localVariableData={[]}
            stepInputType="abc"
            id={'return-value10'}
            moduleId={moduleId}
            scriptId={scriptId}
            stepInputName={'returnValue'}
            setReturnValue={setReturnValue}
            type="Test Development"
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            removeStepRef={removeStepRef}
          />
        </FormikProvider>
      </div>
      <MyInput
        className="w-full"
        type="text"
        placeholder="Enter detailed cookie"
        name="detailCookie"
        id="detailCookie"
        autoFocus={true}
        onChange={(e) => handleArrayValues(e.target.name, e.target.value)}
        label={
          <>
            {' '}
            <span className="text-red-400">*</span> Detailed Cookie
          </>
        }
      />
    </div>
  );
};
const Detailscookies = ({ isEditable = true }) => {
  const [returnValuePayload, setReturnValuePayload] = useState();
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  const formik = useFormik({
    initialValues: {},
  });
  const { handleChange, handleBlur, setFieldValue, setFieldError } = formik;
  const setReturnValue = (data, stepInputName, type) => {
    setReturnValuePayload({
      returnType: data.name,
      stepGroupReferenceInfo: {
        type: type,
        name: data.name,
      },
    });
  };

  const removeStepRef = () => {
    if (returnValuePayload?.stepGroupReferenceInfo) {
      setReturnValuePayload({ returnType: returnValuePayload.returnType, stepGroupReferenceInfo: null });
    }
  };

  return (
    <div className="flex flex-col p-2 -mt-4 ">
      <div className=" capture-detail-cookies pt-5">
        <FormikProvider value={formik}>
          <VariableDropdown
            canCreateVariable={isEditable}
            localVariableData={[]}
            stepInputType="abc"
            id={'return-value11'}
            moduleId={moduleId}
            scriptId={scriptId}
            stepInputName={'returnValue'}
            setReturnValue={setReturnValue}
            type="Test Development"
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            removeStepRef={removeStepRef}
          />
        </FormikProvider>
      </div>
    </div>
  );
};
const SessionId = ({ isEditable = true }) => {
  const [returnValuePayload, setReturnValuePayload] = useState();
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  const formik = useFormik({
    initialValues: {},
  });
  const { handleChange, handleBlur, setFieldValue, setFieldError } = formik;
  const setReturnValue = (data, stepInputName, type) => {
    setReturnValuePayload({
      returnType: data.name,
      stepGroupReferenceInfo: {
        type: type,
        name: data.name,
      },
    });
  };

  const removeStepRef = () => {
    if (returnValuePayload?.stepGroupReferenceInfo) {
      setReturnValuePayload({ returnType: returnValuePayload.returnType, stepGroupReferenceInfo: null });
    }
  };

  return (
    <div className="flex flex-col p-2 -mt-4 ">
      <div className="capture-session-id pt-5">
        <FormikProvider value={formik}>
          <VariableDropdown
            canCreateVariable={isEditable}
            localVariableData={[]}
            stepInputType="abc"
            id={'return-value12'}
            moduleId={moduleId}
            scriptId={scriptId}
            stepInputName={'returnValue'}
            setReturnValue={setReturnValue}
            type="Test Development"
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            removeStepRef={removeStepRef}
          />
        </FormikProvider>
      </div>
    </div>
  );
};
const StatusLine = ({ isEditable = true }) => {
  const [returnValuePayload, setReturnValuePayload] = useState();
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  const formik = useFormik({
    initialValues: {},
  });
  const { handleChange, handleBlur, setFieldValue, setFieldError } = formik;
  const setReturnValue = (data, stepInputName, type) => {
    setReturnValuePayload({
      returnType: data.name,
      stepGroupReferenceInfo: {
        type: type,
        name: data.name,
      },
    });
  };

  const removeStepRef = () => {
    if (returnValuePayload?.stepGroupReferenceInfo) {
      setReturnValuePayload({ returnType: returnValuePayload.returnType, stepGroupReferenceInfo: null });
    }
  };

  return (
    <div className="flex flex-col p-2 -mt-4 ">
      <div className="capture-status-line pt-5">
        <FormikProvider value={formik}>
          <VariableDropdown
            canCreateVariable={isEditable}
            localVariableData={[]}
            stepInputType="abc"
            id={'return-value13'}
            moduleId={moduleId}
            scriptId={scriptId}
            stepInputName={'returnValue'}
            setReturnValue={setReturnValue}
            type="Test Development"
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            removeStepRef={removeStepRef}
          />
        </FormikProvider>
      </div>
    </div>
  );
};
const Btime = ({ isEditable = true }) => {
  const [returnValuePayload, setReturnValuePayload] = useState();
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  const formik = useFormik({
    initialValues: {},
  });
  const { handleChange, handleBlur, setFieldValue, setFieldError } = formik;
  const setReturnValue = (data, stepInputName, type) => {
    setReturnValuePayload({
      returnType: data.name,
      stepGroupReferenceInfo: {
        type: type,
        name: data.name,
      },
    });
  };

  const removeStepRef = () => {
    if (returnValuePayload?.stepGroupReferenceInfo) {
      setReturnValuePayload({ returnType: returnValuePayload.returnType, stepGroupReferenceInfo: null });
    }
  };

  return (
    <div className="flex flex-col p-2 -mt-4 ">
      <div className="capture-b-time pt-5">
        <FormikProvider value={formik}>
          <VariableDropdown
            canCreateVariable={isEditable}
            localVariableData={[]}
            stepInputType="abc"
            id={'return-value14'}
            moduleId={moduleId}
            scriptId={scriptId}
            stepInputName={'returnValue'}
            setReturnValue={setReturnValue}
            type="Test Development"
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            removeStepRef={removeStepRef}
          />
        </FormikProvider>
      </div>
    </div>
  );
};

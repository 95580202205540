import React from 'react';
import Select from 'react-dropdown-select';
import FileUploadButton from '@src/pages/common/fileUpload_button';
import { IMPORT_EXPORT_CONSTANTS } from '@src/common/ui-constants';
import selectDropdownStyle from './ImportExport.module.scss';
import { FormControl, FormControlLabel } from '@material-ui/core';
import { getLicenseFeatures } from '@src/util/common_utils';
import { TEMPLATE_VALUE } from './ImportAndExportConstants';
import { AUTOMATION_SERVICES, LICENSE_FEATURES } from '@src/common/ui-constants';
import { IMPORT_EXPORT_FILE_ALLOWED } from '@src/util/validations';

export function CustomFormControl({ label, id, control }) {
  return (
    <div>
      <FormControlLabel label={label} id={id} control={control}></FormControlLabel>
    </div>
  );
}

export function ImportExportSelectDropdown(props) {
  return (
    <div className="flex">
      <div className="my-3 ml-5 flex flex-col">
        <div className="flex">
          <span className="text-red-400 mr-1 ml-0.5">&#42;</span>
          <label className="text-sm fontPoppinsRegularMd">{IMPORT_EXPORT_CONSTANTS.SELECT_PROJECT}</label>
        </div>
        <FormControl variant="standard">
          <Select
            options={props.options}
            variant="standard"
            searchable={true}
            backspaceDelete={true}
            closeOnSelect={true}
            name="selectProject"
            id="selectProject"
            placeholder={IMPORT_EXPORT_CONSTANTS.SELECT_PLACEHOLDER}
            onChange={props.onChange}
            style={{ width: '305px', height: '2px' }}
            className={selectDropdownStyle['select-project-dropdown-wrapper']}
            searchBy={'value'}
            dropdownHeight={'255px'}
            values={props.value}
          ></Select>
        </FormControl>
      </div>
      {props.uploadFile && (
        <div className="flex">
          <div className="my-3 ml-5 flex-col">
            <div className="flex">
              <span className="text-red-400 mr-1">&#42;</span>
              <label className="text-sm fontPoppinsRegularMd">{IMPORT_EXPORT_CONSTANTS.IMPORT_FROM}</label>
            </div>
            <FormControl variant="standard">
              <FileUploadButton
                disabled={props.disabled}
                id="file"
                name="file"
                value={props.fileValue}
                showBtn={props.showBtn}
                setShowBtn={props.setShowBtn}
                accept={Object.keys(IMPORT_EXPORT_FILE_ALLOWED)}
                onChange={props.onFileChange}
                onClick={props.onFileClick}
                fileRemoved={props.fileRemove}
              />
            </FormControl>
          </div>
        </div>
      )}
    </div>
  );
}

export const displayRepository = () => {
  const hasAutomation = global.privilege.hasAutomation(getLicenseFeatures());
  return hasAutomation;
};

export const displayTestExecution = () => {
  const hasAutomation = global.privilege.hasAutomation(getLicenseFeatures());
  const hasManual = global.privilege.hasManualTestCase(getLicenseFeatures());
  return hasAutomation || hasManual;
};

export const displayDefects = () => {
  const hasCBasic = global.privilege.hasCBasic(getLicenseFeatures());
  const hasDefects = global.privilege.hasDefects(getLicenseFeatures());
  return hasCBasic || hasDefects;
};

export const displayTemplates = () => {
  const hasCBasic = global.privilege.hasCBasic(getLicenseFeatures());
  const hasDefects = global.privilege.hasDefects(getLicenseFeatures());
  const hasManual = global.privilege.hasManualTestCase(getLicenseFeatures());
  return hasCBasic || hasDefects || hasManual;
};

export const displayTemplatesSection = (templateSectionName) => {
  const hasCBasic = global.privilege.hasCBasic(getLicenseFeatures());
  const hasManual = global.privilege.hasManualTestCase(getLicenseFeatures());
  const hasDefects = global.privilege.hasDefects(getLicenseFeatures());
  if (templateSectionName === TEMPLATE_VALUE.MANUAL) {
    return hasManual || hasCBasic;
  } else if (templateSectionName === TEMPLATE_VALUE.DEFECT) {
    return hasDefects || hasCBasic;
  }
};

export const hasAllAutomationFeatures = () => {
  let licenseFeatures = getLicenseFeatures();
  return AUTOMATION_SERVICES.every((licenseFeature) => licenseFeatures.includes(licenseFeature));
};

export const isCombinedLicense = () => {
  const hasManual = global.privilege.hasManualTestCase(getLicenseFeatures());
  const hasDefects = global.privilege.hasDefects(getLicenseFeatures());
  const hasAutomation = global.privilege.hasAutomation(getLicenseFeatures());
  return hasAutomation && (hasManual || hasDefects);
};

export const hasOnlyManual = () => {
  const hasDefects = global.privilege.hasDefects(getLicenseFeatures());
  const hasAutomation = global.privilege.hasAutomation(getLicenseFeatures());
  const hasManual = global.privilege.hasManualTestCase(getLicenseFeatures());
  return (!hasDefects && !hasAutomation) || (hasManual && hasDefects && !hasAutomation);
};

export const hasOnlyWebService = () => {
  const licenseFeatures = getLicenseFeatures();
  return licenseFeatures?.length === 1 && licenseFeatures[0] === LICENSE_FEATURES.WEB_SERVICE;
};

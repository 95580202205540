import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import '../../../../test-development/test-development.scss';
import Modal from 'react-modal';
import IconButton from '@material-ui/core/IconButton';
import {
  createStepGroupReq,
  updateStepGroupReq,
  getAllStepGroupVariable,
  addStepGroupVariableArray,
  getAllLocalVariablesReq,
} from '../../../../../api/api_services';
import { Input, TextareaAutosize, MenuItem, Select, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import {
  getUrlPath,
  getScriptOptions,
  resourceNavigation,
} from '@src/pages/test-development/script/modules/module/utils/common-functions';
import TreeWithRadioButton from '@src/pages/common/table_tree/table-tree-with-radio-button';
import { trimPath } from '@src/common/ui-constants';
import { isWebServiceRequired } from '@src/util/common_utils';
import { colors } from '@src/css_config/colorConstants.js';
const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: 249,
    borderRadius: 8,
    fontSize: '14px',
    '&:focus': {
      borderRadius: 12,
      background: 'none',
    },
  },
  menuPaper: {
    maxHeight: 200,
    fontSize: '14px',
  },
  menulist: {
    paddingTop: 15,
    paddingBottom: 0,
    fontSize: '14px',
    background: 'none',
    '& li': {
      fontSize: '14px',
      color: colors.grey_dark,
    },
    '& li:hover': {
      background: colors.sky_blue_dark,
      color: colors.blue_dark,
    },
    '& li.Mui-selected': {
      color: colors.blue_dark,
      background: colors.sky_blue_dark,
    },
    '& li.Mui-selected:hover': {
      background: colors.sky_blue_dark,
      color: colors.blue_dark,
    },
  },
}));

const usePlaceholderStyles = makeStyles((theme) => ({
  placeholder: {
    color: colors.light_gray_100,
    fontSize: 12,
  },
}));

const StepGroupModal = (props) => {
  const treeData = props?.treeData;
  if (treeData[0].title === 'Root library') {
    treeData[0].title = 'Root Library';
  }
  const classes = useStyles();
  const history = useHistory();
  const [project, setProject] = useState(JSON.parse(localStorage.getItem('selected-project')));
  const [stepGroupType] = useState(getScriptOptions());
  let [noOfRows, setNoOfRows] = useState(1);
  let [createClicked, setCreateClicked] = useState(false);
  const [parentName, setParentName] = useState('');
  let [arrayOfReturnValues, setArrayofReturnValues] = useState([]);
  let [selectTouched, setSelecteTouched] = useState(false);
  let [selectedReturnTypeValue, setSelectedReturnTypeValue] = useState('void');
  let [executionOrder, setExecutionOrder] = useState(
    props.nodeToAdd ? (props.nodeToAdd === 0 ? 1 : findExecutionOrder(props.nodeToAdd)) : 1
  );
  const search = window.location.search;
  const id = new URLSearchParams(search).get('id');
  let keyAndNameArray = [];

  function findExecutionOrder(node) {
    return node.data.stepGroupCount + node.data.subLibraryCount + 1;
  }

  useEffect(() => {
    setStepGroupTypeFunction();
    if (props?.stepsForStepGroup) {
      setOfStepsWithStepGroup(props.stepsForStepGroup);
    }
  }, []);

  const addWebServiceOption = (projectType, stepGroupTypeOption) => {
    if (isWebServiceRequired(projectType)) {
      stepGroupTypeOption.push({ name: 'Webservice', value: 'Webservice' });
    }
  };

  const setStepGroupTypeFunction = () => {
    if (props.mode === 'EDIT') {
      setdescCount(props.data.desc.length);
    }
  };

  let editData = {};
  if (props?.data && props?.mode !== 'ADD') {
    editData = props.data;
  }
  const { children, ...default_node } = props?.treeData[0];

  const [showModal, setShowModal] = useState(true);
  const [selectedPage, setSelectedPage] = useState('');
  const [descCount, setdescCount] = React.useState(0);
  const [stepCount, setstepCount] = React.useState(0);

  let initialValues;

  if (props?.stepsForStepGroup) {
    initialValues = {
      name: '',
      returnType: 'void',
      description: '',
      type: props.type,
      parentModule: '',
      passMessage: '',
      failMessage: '',
    };
  } else if (props?.data) {
    initialValues = {
      name: editData?.name,
      returnType: editData?.returnType,
      description: editData?.desc,
      type: editData?.type,
      parentModule: editData?.parentId,
      passMessage: editData?.passMessage,
      failMessage: editData?.failMessage,
    };
  } else if (props?.parentName) {
    initialValues = {
      name: '',
      type: '',
      description: '',
      parentModule: props.parentName,
      passMessage: '',
      failMessage: '',
    };
  } else {
    initialValues = {
      name: '',
      type: '',
      description: '',
      parentModule: '',
      passMessage: '',
      failMessage: '',
    };
  }

  const setOfStepsWithStepGroup = (response) => {
    let steps = response;
    let array = [];
    if (steps.length > 0) {
      steps.map((step, index) => {
        if (step.returnType && step?.returnType !== 'void' && !step?.isDisabled) {
          let stepReferenceInfo = step.stepReferenceInfo;
          if (stepReferenceInfo) {
            let name = `${stepReferenceInfo.name}: Step${index + 1}`;
            let data = {
              name: name,
              value: name,
            };
            array.push(data);
          }
        }
      });
    }
    if (array.length > 0) {
      setArrayofReturnValues(array);
    }
  };
  const treeNodeSelected = ([data]) => {
    if (!data) {
      formikDetails.setFieldValue('parentModule', '');
    } else if (data && data.data.key) {
      setExecutionOrder(findExecutionOrder(data.node));
      setSelectedPage({ ...data.node, data: data.data });
      formikDetails.setFieldValue('parentModule', data.data.title);
    }
  };
  const keyAndName = (parentName, treeData, path) => {
    treeData.map((data, index) => {
      if (data.title === parentName && data.folder) {
        let object = {
          Name: data.title,
          id: data.key,
        };
        keyAndNameArray.push(object);
        if (data.children) {
          let index = path.indexOf('/');
          let parentName;
          let alterPath;
          if (index === -1) {
            parentName = path;
            alterPath = path;
          } else {
            parentName = path.slice(0, index);
            alterPath = path.slice(index + 1, path.length);
          }
          keyAndName(parentName, data.children, alterPath);
        }
      }
    });
    return keyAndNameArray;
  };

  const createStepGroupVariable = (data) => {
    let stepGroupVariableArray = [];
    let steps = data?.steps;
    if (steps) {
      steps.map((data, index) => {
        let dataInputs = data.stepInputs;
        if (data?.elementDetails) {
          data.elementDetails?.forEach((ele) => {
            ele?.locators?.forEach((locator) => {
              if (locator.type === 'dynamic') {
                locator.dynamicLocatorArray.forEach((dynamicLocator) => {
                  if (dynamicLocator.reference === 'STEPGROUP' || dynamicLocator.reference === 'LOCAL') {
                    if (stepGroupVariableArray.length > 0) {
                      if (
                        stepGroupVariableArray.filter((stepGroup) => stepGroup === dynamicLocator.value).length === 0
                      ) {
                        stepGroupVariableArray.push(dynamicLocator.value);
                      }
                    } else {
                      stepGroupVariableArray.push(dynamicLocator.value);
                    }
                  }
                });
              }
            });
          });
        }
        if (dataInputs) {
          dataInputs.map((dataInput, index) => {
            if (dataInput.reference === 'STEPGROUP' || dataInput.reference === 'LOCAL') {
              if (stepGroupVariableArray.length > 0) {
                if (stepGroupVariableArray.filter((stepGroup) => stepGroup === dataInput.value).length === 0) {
                  stepGroupVariableArray.push(dataInput.value);
                }
              } else {
                stepGroupVariableArray.push(dataInput.value);
              }
            }
          });
        }
        let stepReferenceInfo = data.stepReferenceInfo;
        if (stepReferenceInfo) {
          if (stepGroupVariableArray.length > 0) {
            if (stepGroupVariableArray.filter((stepGroup) => stepGroup === stepReferenceInfo.name).length === 0) {
              stepGroupVariableArray.push(stepReferenceInfo.name);
            }
          } else {
            stepGroupVariableArray.push(stepReferenceInfo.name);
          }
        }
      });
    }
    if (stepGroupVariableArray.length > 0) {
      if (props.section === 'StepGroup') {
        let listOfParVariableCreate = [];
        const search = window.location.search;
        const id = new URLSearchParams(search).get('id');
        const parentId = new URLSearchParams(search).get('parentId');
        getAllStepGroupVariable(parentId, id).then((response) => {
          let listOfParameterArray = response.data.responseObject;
          if (listOfParameterArray.length > 0) {
            stepGroupVariableArray.map((stepGroupVariable, index) => {
              let selectedParameter = listOfParameterArray.filter((parameter) => parameter.name === stepGroupVariable);
              if (selectedParameter.length > 0) {
                let obj = {
                  name: selectedParameter[0].name,
                  value: selectedParameter[0].value ? selectedParameter[0].value : '',
                  type: 'LOCAL',
                  returningStep: '',
                  projectId: project.id,
                  parentVariableType: 'STEPGROUP',
                  parentVariableId: data.parentId,
                  subParentVariableId: data.id,
                  masked: selectedParameter[0].masked,
                };
                listOfParVariableCreate.push(obj);
              }
            });
          }
          if (listOfParVariableCreate.length > 0) {
            addStepGroupVariableArray(listOfParVariableCreate).then((response) => {});
          }
        });
      } else {
        let listOfParVariableCreate = [];
        const search = window.location.search;
        const moduleId = new URLSearchParams(search).get('moduleId');
        const scriptId = new URLSearchParams(search).get('scriptId');
        getAllLocalVariablesReq(moduleId, scriptId, false).then((response) => {
          let listOfParameterArray = response.data.responseObject;
          if (listOfParameterArray.length > 0) {
            stepGroupVariableArray.map((stepGroupVariable, index) => {
              let selectedParameter = listOfParameterArray.filter((parameter) => parameter.name === stepGroupVariable);
              if (selectedParameter.length > 0) {
                let obj = {
                  name: selectedParameter[0].name,
                  value: selectedParameter[0].value ? selectedParameter[0].value : '',
                  type: 'LOCAL',
                  returningStep: '',
                  projectId: project.id,
                  parentVariableType: 'STEPGROUP',
                  parentVariableId: data.parentId,
                  subParentVariableId: data.id,
                  masked: selectedParameter[0].masked,
                };
                listOfParVariableCreate.push(obj);
              }
            });
          }
          if (listOfParVariableCreate.length > 0) {
            addStepGroupVariableArray(listOfParVariableCreate).then((response) => {});
          }
        });
      }
    }
  };

  async function createStepGroup(data) {
    if (data?.returnType !== 'void') {
      if (data?.returnType) {
        let name = data?.returnType;
        let selectedStep = parseInt(name?.charAt(name?.indexOf(':') + 6));
        let variableName = name?.substr(0, name?.indexOf(':'));
        let stepGroupReferenceInfo = {
          stepNumber: selectedStep,
          type: data?.steps[selectedStep - 1]?.stepReferenceInfo?.type,
          name: variableName,
        };
        data.stepGroupReferenceInfo = stepGroupReferenceInfo;
        data.returnType = variableName;
      }
    }
    try {
      let response = await createStepGroupReq(data.parentId, data);
      if (response.data.responseCode === 200) {
        setCreateClicked(false);
        props.closeModal(false);
        if (props.stepsForStepGroup) {
          createStepGroupVariable(response.data.responseObject);
          props.MyAlert.success(`Step Group Created Successfully`);
          if (props.uncheckSelectedCheckbox) {
            props.uncheckSelectedCheckbox();
          }
        } else {
          let responseObject = response.data.responseObject;
          const { id, parentId } = responseObject;
          props.closeModal(false);
          let searchKeyArray = responseObject.searchKey.slice(1).split('/');
          searchKeyArray = searchKeyArray.slice(1);
          let nameArray = trimPath(responseObject.path).split('/');
          let { json } = resourceNavigation({ searchKeyArray, nameArray, isTestDev: false, rootName: 'Root Library' });
          localStorage.setItem('parentAndSonJson', JSON.stringify(json));
          history.push(
            `/repository/step_group/${getUrlPath(json)}Steps?id=${id}&parentId=${parentId}&stepGroup=${
              data.name
            }&defaultStepGroup=false`
          );
        }
      } else if (response.data.responseCode === 400 && response.data.status) {
        setCreateClicked(false);
        props.closeModal(false);
        props.MyAlert.info(`${response.data.message}`);
      }
    } catch (err) {
      console.error('CREATE STEP GROUP :', err);
    }
  }
  async function updateStepGroup(libId, stepgroupId, data) {
    data.steps = null;
    data.parameters = null;
    try {
      let response = await updateStepGroupReq(libId, stepgroupId, 'UPDATE', data);
      if (response.data.responseCode === 200 && response.data.status === 'SUCCESS') {
        props.closeModal(false);
        props.reloadTree(true);
        let title = `${data.name?.substring(0, 50)}${data.name?.length > 50 ? '...' : ''}${' '}`;
        props.MyAlert.success(`${title} ${data?.type} updated successfully`);
      } else if (response.data.responseCode === 400 && response.data.status) {
        props.closeModal(false);
        props.MyAlert.info(`${response.data.message}`);
      }
    } catch (err) {
      console.error('UPDATE STEP GROUP : ', err);
    }
  }
  const checkValuesAreChanged = (values) => {
    let changeExist = false;
    if (values.name !== props.data.name) {
      changeExist = true;
    } else if (values.description !== props.data.desc) {
      changeExist = true;
    } else if (values.passMessage !== props.data.passMessage || values.failMessage !== props.data.failMessage) {
      changeExist = true;
    }
    if (changeExist) {
      return true;
    } else {
      return false;
    }
  };
  const getusedParameterList = () => {
    let usedParameterList = [];
    let parameters = props.data.parameters;
    let steps = props.stepsForStepGroup;
    if (steps) {
      steps.map((step, index) => {
        if (step.stepInputs) {
          let stepInputs = step.stepInputs;
          if (stepInputs) {
            stepInputs.map((stepInput, index) => {
              if (stepInput.reference === 'STEPGROUPINPUT') {
                if (parameters) {
                  parameters.map((parameter, index) => {
                    if (parameter.name === stepInput.value) {
                      if (usedParameterList.length > 0) {
                        if (usedParameterList.filter((parameter) => parameter.name === stepInput.value).length === 0) {
                          usedParameterList.push(parameter);
                        }
                      } else {
                        usedParameterList.push(parameter);
                      }
                    }
                  });
                }
              }
            });
          }
        }
      });
    }
    return usedParameterList;
  };
  const getTypeNameNumberOfReturnType = () => {
    if (selectedReturnTypeValue !== 'void') {
      let stepGroupReferenceInfo;
      let selectedIndex = parseInt(selectedReturnTypeValue.charAt(selectedReturnTypeValue.indexOf(':') + 6));
      let steps = props.stepsForStepGroup;
      if (steps) {
        stepGroupReferenceInfo = {
          stepNumber: selectedIndex,
          type: steps[selectedIndex - 1]?.stepReferenceInfo?.type,
          name: steps[selectedIndex - 1]?.stepReferenceInfo?.name,
        };
      }
      return stepGroupReferenceInfo;
    }
  };
  const submitForm = () => {
    const values = formikDetails.values;
    let requestBody;
    if (values.name !== '' && values.type !== '' && !createClicked) {
      if (props?.data && !props.stepsForStepGroup) {
        if (checkValuesAreChanged(values)) {
          props.data.name = values.name;
          props.data.type = values.type;
          props.data.desc = values.description;
          props.data.passMessage = values.passMessage;
          props.data.failMessage = values.failMessage;
          updateStepGroup(editData.parentId, editData.id, props.data);
        } else {
          props.closeModal(false);
          props.MyAlert.info(`nothing there  to update`);
        }
      } else {
        setCreateClicked(true);
        requestBody = {
          name: values.name,
          desc: values.description,
          type: values.type,
          steps: [],
          localVariable: [],
          parameters: [],
          passMessage: values?.passMessage,
          failMessage: values?.failMessage,
          executionOrder: executionOrder,
        };
        if (props.parentId) {
          requestBody['parentId'] = props.parentId;
          requestBody['parentName'] = props.parentName;
          setParentName(props.parentName);
        } else if (!selectedPage) {
          requestBody['parentId'] = default_node.key;
          requestBody['parentName'] = default_node.title;
          setParentName(default_node.title);
        } else {
          requestBody['parentId'] = selectedPage.data.key;
          requestBody['parentName'] = selectedPage.title;
          setParentName(selectedPage.title);
        }
        if (props.stepsForStepGroup) {
          let steps = props.stepsForStepGroup.filter(
            (data) => data?.isDisabled === undefined || data.isDisabled === false
          );
          if (props.section === 'StepGroup') {
            requestBody['steps'] = steps;
          } else {
            requestBody['steps'] = convertLocalVarIntoStepGroupVar(steps);
          }
          if (props.data) {
            requestBody['returnType'] = formikDetails?.values?.returnType
              ? formikDetails?.values?.returnType
              : props.data.returnType;
            requestBody['parameters'] = getusedParameterList();
            if (selectedReturnTypeValue !== 'void') {
              requestBody.stepGroupReferenceInfo = getTypeNameNumberOfReturnType();
            }
          }
        }
        createStepGroup(requestBody);
      }
    }
  };

  const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
  };

  const convertLocalVarIntoStepGroupVar = (steps) => {
    let updatedSteps = [];
    steps.map((step, index) => {
      let newStep = { ...step };
      let newStepInputs = [];
      if (newStep.stepInputs) {
        if (step?.elementDetails) {
          let newElementDetails = JSON.parse(JSON.stringify(step?.elementDetails));
          newElementDetails?.forEach((ele) => {
            ele?.locators?.forEach((locator) => {
              if (locator.type === 'dynamic') {
                locator.dynamicLocatorArray.forEach((dynamicLocator) => {
                  if (dynamicLocator.reference === 'LOCAL') {
                    dynamicLocator.reference = 'STEPGROUP';
                  }
                });
              }
            });
          });
          newStep.elementDetails = newElementDetails;
        }
        newStep.stepInputs.map((stepInput) => {
          if (stepInput.reference) {
            if (stepInput.reference === 'LOCAL') {
              let newStepInput = { ...stepInput };
              newStepInput.reference = 'STEPGROUP';
              newStepInputs.push(newStepInput);
            } else {
              newStepInputs.push(stepInput);
            }
          } else {
            newStepInputs.push(stepInput);
          }
        });
        newStep.stepInputs = newStepInputs;
      }

      if (newStep.stepReferenceInfo) {
        let stepReferenceInfo = newStep.stepReferenceInfo;
        if (stepReferenceInfo.type) {
          if (stepReferenceInfo.type === 'LOCAL') {
            let newstepReferenceInfo = { ...stepReferenceInfo };
            newstepReferenceInfo.type = 'STEPGROUP';
            newStep.stepReferenceInfo = newstepReferenceInfo;
          }
        }
      }
      if (['PRE', 'POST'].includes(newStep?.type)) {
        newStep['type'] = 'Group';
        newStep['displayName'] = newStep?.stepGroupName;
        newStep['defaultDisplayName'] = newStep?.stepGroupName;
        newStep['name'] = newStep?.stepGroupName;
        newStep['nlpName'] = newStep?.stepGroupName;
      }
      updatedSteps.push(newStep);
    });
    return updatedSteps;
  };
  const updatePassFailMsg = (value) => {
    let name = value ? value : formikDetails.values.name;
    let regexPattern = /\s+/g;
    if (name !== '') {
      formikDetails.setFieldValue('passMessage', `${name.replace(regexPattern, ' ')} is passed`);
      formikDetails.setFieldValue('failMessage', `${name.replace(regexPattern, ' ')} is failed`);
    } else {
      formikDetails.setFieldValue('passMessage', '');
      formikDetails.setFieldValue('failMessage', '');
    }
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .required('Name is required')
      .min(2, 'Name must be minimum 2 characters')
      .matches(/^[a-zA-Z0-9-_() ]*$/, 'Name should accept only ( ,) ,-,_ special characters')
      .matches(/^[-a-zA-Z0-9-()_]+(\s+[-a-zA-Z0-9-()_]+)*$/, 'Space is not allowed in the beginning and at the end'),
    description: yup.string(),
    type: yup.string().ensure().required('Select step group type'),
    parentModule: yup.string().required('Parent library is required'),
    failMessage: yup
      .string()
      .required('Fail message is required')
      .matches(/.*\S.*/, 'Only whitespaces are not allowed')
      .matches(/^[a-zA-Z0-9-_() ]*$/, 'Fail message should accept only ( ,) ,- special characters')
      .min(1, 'Fail message must be minimum 1 character')
      .matches(/^[-a-zA-Z0-9-()_]+(\s+[-a-zA-Z0-9-()_]+)*$/, 'Space is not allowed in the beginning and at the end'),
    passMessage: yup
      .string()
      .required('Pass message is required')
      .matches(/.*\S.*/, 'Only whitespaces are not allowed')
      .matches(/^[a-zA-Z0-9-_() ]*$/, 'Pass message should accept only ( ,) ,- special characters')
      .min(1, 'Pass message must be minimum 1 character')
      .matches(/^[-a-zA-Z0-9-()_]+(\s+[-a-zA-Z0-9-()_]+)*$/, 'Space is not allowed in the beginning and at the end'),
  });

  const formikDetails = useFormik({
    initialValues,
    validationSchema,
  });

  let errorForReturnType = false;
  if (props.stepsForStepGroup) {
    if (formikDetails.values.returnType === '' && formikDetails.touched.returnType) {
      errorForReturnType = true;
    }
  }
  let errorExistForType = false;
  if (formikDetails.values.type === '' && formikDetails.touched.type) {
    errorExistForType = true;
  }
  let errorExistForParentModule = false;
  if (formikDetails.values.parentModule === '' && formikDetails.touched.parentModule) {
    errorExistForParentModule = true;
  } else {
    errorExistForParentModule = false;
  }
  let errorExistForPassMessage = false;
  if (formikDetails.values.passMessage === '' && formikDetails.touched.passMessage) {
    errorExistForPassMessage = true;
  }
  let errorExistForFailMessage = false;
  if (formikDetails.values.failMessage === '' && formikDetails.touched.failMessage) {
    errorExistForFailMessage = true;
  }

  const eliminateMultipleSpace = (event) => {
    let regexPattern = /\s+/g;
    event.target.value = event?.target?.value.replace(regexPattern, ' ');
    formikDetails.handleChange(event);
    formikDetails.handleBlur(event);
  };
  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => {
        setShowModal(false);
        props.closeModal(false);
      }}
      className="p-px modal-dialog"
    >
      <div className="modal-container stepgroup-modal-size modal-position">
        <div className="modal-header">
          {props.mode === 'ADD' || props.mode === 'ADD_SUB' ? (
            <h2 className="title"> Create Step Group</h2>
          ) : (
            <div className="title title-trim fontPoppinsMediumLg" title={props.data.name}>
              Edit Step Group - {props.data.name}
            </div>
          )}
          <IconButton
            color="primary"
            component="span"
            className="close"
            onClick={() => {
              props.closeModal(false);
              setShowModal(false);
            }}
          >
            <img
              style={{
                cursor: 'pointer',
              }}
              src="/assets/images/close_black.svg"
              alt=""
              height="25px"
              width="25px"
            />{' '}
          </IconButton>
        </div>
        <div className="modal-body leading-snug mx-1" id="journal-scroll">
          <form onSubmit={formikDetails.handleSubmit}>
            <div className="grid grid-cols-2 gap-20 relative">
              <div className="mt-2 h-10 rs-user-input-container">
                <TextField
                  error={formikDetails.errors.name && formikDetails.touched.name}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={
                    <>
                      {' '}
                      <span className="text-red-400 mr-1">&#42;</span>Name
                    </>
                  }
                  autoComplete="off"
                  autoFocus={true}
                  className=" w-full mui-input-text-field"
                  type="text"
                  name="name"
                  id="name"
                  size="40"
                  placeholder="Enter step group name"
                  onBlur={eliminateMultipleSpace}
                  onChange={(e) => {
                    formikDetails.handleChange(e);
                    if (props.mode === 'EDIT') {
                      updatePassFailMsg(e.target.value);
                    }
                  }}
                  onSelect={(e) => {
                    if (props.mode !== 'EDIT') {
                      updatePassFailMsg();
                      setstepCount(e.target.value.length);
                    }
                  }}
                  value={formikDetails.values.name}
                />
                {formikDetails.errors.name && formikDetails.touched.name ? (
                  <div className="absolute errorMessage w-64">{formikDetails.errors.name}</div>
                ) : null}
              </div>
              <div className="stepgroup-modal-dropdown mt-1.5">
                <label
                  htmlFor="type"
                  className={
                    selectTouched
                      ? 'input-label-for-select'
                      : !errorExistForType
                      ? 'input-filed-label-style-common'
                      : 'input-filed-label-style-common-withError'
                  }
                >
                  <span className="text-red-400">&#42;</span>Type
                </label>
                <Select
                  classes={{ root: classes.select }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    classes: {
                      paper: classes.menuPaper,
                      list: classes.menulist,
                    },
                    getContentAnchorEl: null,
                  }}
                  onFocus={() => {
                    setSelecteTouched(true);
                  }}
                  error={formikDetails.errors.type && formikDetails.touched.type}
                  name="type"
                  id="type"
                  displayEmpty
                  value={formikDetails.values.type}
                  disabled={props?.data || props?.type ? true : false}
                  onBlur={(e) => {
                    formikDetails.handleBlur(e);
                    setSelecteTouched(false);
                  }}
                  onChange={formikDetails.handleChange}
                  onSelect={formikDetails.handleChange}
                  className="w-full input-label-for-select"
                  renderValue={(selected) => {
                    if (!selected) return <Placeholder>Select step group type</Placeholder>;
                    else return <span className="_input-label-for-select">{selected}</span>;
                  }}
                >
                  {stepGroupType.map((data, index) => {
                    return (
                      <MenuItem className="fontPoppinsRegularMd" value={data.value}>
                        {data.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                {formikDetails.errors.type && formikDetails.touched.type ? (
                  <div className="errorMessage">{formikDetails.errors.type}</div>
                ) : null}
              </div>
            </div>
            <div className=" mt-9">
              <label htmlFor="description" className="input-filed-label-style-common">
                Description
              </label>
              <TextareaAutosize
                rowsMax={noOfRows}
                onBlur={(e) => {
                  setNoOfRows(1);
                  e.target.classList.add('descriptionStyle');
                }}
                onFocus={(e) => {
                  setNoOfRows(null);
                  e.target.classList.remove('descriptionStyle');
                }}
                id="description"
                name="description"
                maxLength="200"
                className="-mt-1 block w-full rs-input-style-textarea popup-input-bg border-0 border-b input-field-color descriptionStyle resize-none"
                onKeyUp={(e) => setdescCount(e.target.value.length)}
                value={formikDetails.values.description}
                placeholder={
                  props.mode === 'ADD' || props.mode === 'ADD_SUB'
                    ? 'Your description goes here...'
                    : 'Your description goes here...'
                }
                onChange={formikDetails.handleChange}
              />
              <div className="mt-1 text-sm text-gray-500 text-right">{descCount}/200</div>
            </div>
            <div className="mt-1">
              <label
                htmlFor="parentModule"
                className={
                  errorExistForParentModule ? 'fontPoppinsRegularMd text-red-500' : 'input-filed-label-style-common'
                }
              >
                <span className="text-red-400">&#42;</span>Parent Library
              </label>
              <TreeWithRadioButton
                moduleSelection={true}
                hideRootRadioBtn={true}
                individualTree={id ? true : false}
                disableRootRadioButton={true}
                hideElements={true}
                hideElementsBtn={true}
                data={treeData ? treeData : []}
                operation={['ADD_SUB', 'EDIT'].includes(props.mode) ? 'edit' : null}
                placeholder={
                  props.data
                    ? props.data.parentName
                    : props.parentName
                    ? props.parentName
                    : 'Search and select parent library'
                }
                nodeSelected={treeNodeSelected.bind(this)}
                buttonLabel="Library"
              />
              {formikDetails.errors.parentModule && formikDetails.touched.parentModule ? (
                <div className="errorMessage">{formikDetails.errors.parentModule}</div>
              ) : null}
            </div>
            {props?.stepsForStepGroup ? (
              <div className="">
                <div className="grid grid-cols-2 mt-5 gap-20">
                  <div className="mt-2">
                    <label htmlFor="type" className={'input-filed-label-style-common '}>
                      <span className="text-red-400">&#42;</span>Step Group Return Value
                    </label>
                    <div className="stepgroup-modal-dropdown">
                      <Select
                        classes={{ root: classes.select }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          classes: {
                            paper: classes.menuPaper,
                            list: classes.menulist,
                          },
                          getContentAnchorEl: null,
                        }}
                        error={errorForReturnType}
                        name="returnType"
                        displayEmpty
                        disabled={arrayOfReturnValues.length > 0 ? false : true}
                        value={formikDetails.values.returnType}
                        onBlur={formikDetails.handleBlur}
                        onChange={formikDetails.handleChange}
                        onSelect={formikDetails.handleChange}
                        className="w-full"
                      >
                        <MenuItem
                          value="void"
                          onClick={() => {
                            setSelectedReturnTypeValue('void');
                          }}
                        >
                          Void
                        </MenuItem>
                        {arrayOfReturnValues.length > 0
                          ? arrayOfReturnValues.map((data, index) => (
                              <MenuItem
                                value={data.name}
                                onClick={() => {
                                  setSelectedReturnTypeValue(data.name);
                                }}
                              >
                                {data.name}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </div>
                    {formikDetails.errors.returnType && formikDetails.touched.returnType ? (
                      <div className="errorMessage">{formikDetails.errors.returnType}</div>
                    ) : null}
                  </div>
                  <div className="mt-2">
                    <label
                      htmlFor="name"
                      className={
                        !errorExistForPassMessage
                          ? 'input-filed-label-style-common '
                          : 'input-filed-label-style-common-withError'
                      }
                    >
                      <span className="text-red-400">&#42;</span>Pass Message
                    </label>
                    <div>
                      <Input
                        error={errorExistForPassMessage}
                        size="40"
                        autoComplete="off"
                        className="w-full"
                        type="text"
                        name="passMessage"
                        id="passMessage"
                        placeholder="Pass message"
                        onBlur={eliminateMultipleSpace}
                        onChange={formikDetails.handleChange}
                        value={formikDetails.values.passMessage}
                      />
                    </div>
                    {formikDetails.errors.passMessage && formikDetails.touched.passMessage ? (
                      <div className="errorMessage">{formikDetails.errors.passMessage}</div>
                    ) : null}
                  </div>
                </div>
                <div className="grid grid-cols-2 mt-5 gap-20">
                  <div className="mt-2">
                    <label
                      htmlFor="name"
                      className={
                        !errorExistForFailMessage
                          ? 'input-filed-label-style-common '
                          : 'input-filed-label-style-common-withError'
                      }
                    >
                      <span className="text-red-400">&#42;</span>Fail Message
                    </label>
                    <div>
                      <Input
                        error={errorExistForFailMessage}
                        size="40"
                        autoComplete="off"
                        className="w-full"
                        type="text"
                        name="failMessage"
                        id="failMessage"
                        placeholder="Fail message"
                        onBlur={eliminateMultipleSpace}
                        onChange={formikDetails.handleChange}
                        value={formikDetails.values.failMessage}
                      />
                    </div>
                    {formikDetails.errors.failMessage && formikDetails.touched.failMessage ? (
                      <div className="errorMessage">{formikDetails.errors.failMessage}</div>
                    ) : null}
                  </div>
                  <div></div>
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-2 mt-5">
                <div className="mt-2 h-12">
                  <label
                    htmlFor="name"
                    className={
                      !errorExistForPassMessage
                        ? 'input-filed-label-style-common '
                        : 'input-filed-label-style-common-withError'
                    }
                  >
                    <span className="text-red-400">&#42;</span>Pass Message
                  </label>
                  <div>
                    <Input
                      error={errorExistForPassMessage}
                      size="40"
                      autoComplete="off"
                      className="w-11/12"
                      type="text"
                      name="passMessage"
                      id="passMessage"
                      placeholder="Pass message"
                      onBlur={eliminateMultipleSpace}
                      onChange={formikDetails.handleChange}
                      value={formikDetails.values.passMessage}
                    />
                  </div>
                  {formikDetails.errors.passMessage && formikDetails.touched.passMessage ? (
                    <div className="errorMessage">{formikDetails.errors.passMessage}</div>
                  ) : null}
                </div>
                <div className="mt-2 ml-6">
                  <label
                    htmlFor="name"
                    className={
                      !errorExistForFailMessage
                        ? 'input-filed-label-style-common '
                        : 'input-filed-label-style-common-withError'
                    }
                  >
                    <span className="text-red-400">&#42;</span>Fail Message
                  </label>
                  <div>
                    <Input
                      error={errorExistForFailMessage}
                      size="40"
                      autoComplete="off"
                      className="w-full"
                      type="text"
                      name="failMessage"
                      id="failMessage"
                      placeholder="Fail message"
                      onBlur={eliminateMultipleSpace}
                      onChange={formikDetails.handleChange}
                      value={formikDetails.values.failMessage}
                    />
                  </div>
                  {formikDetails.errors.failMessage && formikDetails.touched.failMessage ? (
                    <div className="errorMessage">{formikDetails.errors.failMessage}</div>
                  ) : null}
                </div>
              </div>
            )}
          </form>
        </div>
        <div className="modal-footer">
          <button
            className="gray-btn mr-3"
            onClick={() => {
              props.closeModal(false);
              setShowModal(false);
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="primary-btn"
            onClick={() => {
              formikDetails.setTouched({
                name: true,
                type: true,
                parentModule: true,
                passMessage: true,
                failMessage: true,
              });
              if (
                formikDetails.values.name !== '' &&
                formikDetails.values.type !== '' &&
                formikDetails.values.parentModule !== '' &&
                formikDetails.values.passMessage !== '' &&
                formikDetails.values.passMessage.length >= 1 &&
                formikDetails.values.name.length >= 2 &&
                formikDetails.values.failMessage !== '' &&
                formikDetails.values.failMessage.length >= 1 &&
                formikDetails.errors.passMessage !== 'Pass message should accept only ( ,) ,- special characters' &&
                formikDetails.errors.passMessage !== 'Space is not allowed in the beginning and at the end' &&
                formikDetails.errors.failMessage !== 'Fail message should accept only ( ,) ,- special characters' &&
                formikDetails.errors.failMessage !== 'Space is not allowed in the beginning and at the end' &&
                formikDetails.values.name.replace(/\s/g, '').length !== 0 &&
                formikDetails.errors.name !== 'Name should accept only ( ,) ,- special characters' &&
                formikDetails.errors.name !== 'Space is not allowed in the beginning and at the end' &&
                formikDetails.values.passMessage.replace(/\s/g, '').length !== 0 &&
                formikDetails.values.failMessage.replace(/\s/g, '').length !== 0
              ) {
                submitForm();
              }
            }}
          >
            {props.mode === 'ADD' || props.mode === 'ADD_SUB' ? <span>Create</span> : <span>Update</span>}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default StepGroupModal;

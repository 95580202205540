export const ACEEDITOR_CONSTANT = {
  DEFAULT_VALUE: `
  package com.tyss.optimize.programelement;

  import com.tyss.optimize.nlp.util.Nlp;
  import com.tyss.optimize.nlp.util.NlpException;
  import com.tyss.optimize.nlp.util.NlpRequestModel;
  import com.tyss.optimize.nlp.util.NlpResponseModel;
  import com.tyss.optimize.nlp.util.annotation.InputParam;
  import com.tyss.optimize.nlp.util.annotation.InputParams;
  import com.tyss.optimize.nlp.util.annotation.ReturnType;
  import java.util.Map;
  import java.util.ArrayList;
  import java.util.List;

  public class Concat implements Nlp {
      @InputParams({@InputParam(name = "string1", type = "java.lang.String"), @InputParam(name = "string2", type = "java.lang.String")})
      @ReturnType(name = "string3", type = "java.lang.String")

        @Override
      public List<String> getTestParameters() throws NlpException {
        List<String> params = new ArrayList<>();
        return params;
    }
    
     @Override
    public StringBuilder getTestCode() throws NlpException {
        StringBuilder sb = new StringBuilder();
        return sb;
    }
      @Override
      public NlpResponseModel execute(NlpRequestModel nlpRequestModel) throws NlpException {
        
          NlpResponseModel nlpResponseModel = new NlpResponseModel();
          Map<String, Object> attributes = nlpRequestModel.getAttributes();
          String string1 = (String) attributes.get("string1");
          String string2 = (String) attributes.get("string2");

          // Your program element business logic goes here ...

          String string3 = "Return Value";
          nlpResponseModel.getAttributes().put("string3", string3);
          return nlpResponseModel;
      }
  } `,
  MODEVALUE_ADD: 'ADD',
  MODEVALUE_ADD_SUB: 'ADD_SUB',
  MODEVALUE_EDIT: 'EDIT',
  MODEVALUE_DETAILPOPUP: 'Detailpopup',
  MODEVALUE_VIEWSCREEN: 'viewScreen',
  MODEVALUE_VIEWONLY: 'viewOnly',
  EDITOR_HEIGHT_FULL: 400,
  EDITOR_HEIGHT_HALF: 250,
  EDITOR_WIDTH_FULL: '96%',
  EDITOR_WIDTH_HALF: '85%',
  EDITOR_WIDTH_QUATER: '95%',
  EDITOR_FALSE: false,
  EDITOR_TRUE: true,
  UNIQUE_ID_OF_DIV: 'UNIQUE_ID_OF_DIV',
  THEME: 'xcode',
  MODE: 'java',
  FONTSIZE: 17,
  MINILINES: 25,
  MAXLINES: 100,
  DEFAULT_EMPTY: '',
  ANALYTICS_FONTSIZE: 12,
};

import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import { colors } from '@src/css_config/colorConstants.js';

const CustomCheckBox = ({
  id,
  disabled,
  onChange,
  className,
  checked,
  name,
  assertCapture,
  indeterminate,
  ...otherProps
}) => {
  return (
    <Checkbox
      id={id}
      disabled={disabled}
      checked={checked}
      indeterminate={indeterminate}
      size="small"
      onChange={onChange}
      className={`${className}`}
      name={name}
      sx={{
        boxShadow: 'none',
        padding: '0px',
        strokeWidth: 1,
        '&.MuiCheckbox-root': {
          boxShadow: `inset 2px 3px 6px ${colors.slateBlack}`,
          borderRadius: '2px',
          width: '15px',
          height: '15px',
        },
        '&.Mui-checked': {
          color: colors.rs_primary,
        },
        '&.MuiCheckbox-indeterminate': {
          color: colors.rs_primary,
        },
        '& .MuiIconButton-root': {
          boxShadow: 'none',
        },
        '&:not(.Mui-checked):not(.MuiCheckbox-indeterminate)': {
          stroke: colors.rs_border,
        },
      }}
      {...otherProps}
    />
  );
};
CustomCheckBox.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  assertCapture: PropTypes.bool,
};

CustomCheckBox.defaultProps = {
  disabled: false,
  onChange: null,
  className: '',
  assertCapture: false,
};
export default CustomCheckBox;

import React, { useState } from 'react';
import './ChatbotOpener.scss';
import { Icon } from 'fireflink-ui';
import cx from 'classnames';

const ChatbotOpener = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      {!props.hide ? (
        <div
          className={cx('chatbotopener-container', {
            'chatbot-main-div': props.showChatbot,
            'prevent-hover': isHovered,
          })}
        >
          <div className="chatbot-opener-div">
            <div
              className="right-arrow-icon"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => props.setHide(true)}
            >
              <Icon name="right_arrow_blue" height={10} width={10} />
            </div>
            <p className="chatbot-opener-button" onClick={() => props.setShowChatbot(!props.showChatbot)}>
              <Icon name="chat_icon" className="mt-1.5 ml-2.5" height={30} width={30} />
              <p className="chatbot-text">Chat bot</p>
            </p>
          </div>
        </div>
      ) : (
        <div className="hide-chatbotopener" onClick={() => props.setShowChatbot(true)}>
          <Icon name="right-arrow-white" height={15} width={8} />
        </div>
      )}
    </>
  );
};

export default ChatbotOpener;

import { IconButton } from '@mui/material';
import styles from '@pages/common/Layout/styles/logo_style.module.scss';
import cx from 'classnames';
import LeftArrowSvgIcon from '../IconsComponents/LeftArrowSvgIcon';
import RightArrowSvgIcon from '../IconsComponents/RightArrowSvgIcon';
import { ReactComponent as LogoFireflink } from '@assets/side-menu-icons/fireflink_logo.svg';
import { ReactComponent as CollapsedLogo } from '@assets/side-menu-icons/collapsed_fireflink_logo.svg';
import { Tooltip } from '@mui/material';

export const LogoComponent = (props) => {
  const { open, handleDrawerClose } = props;
  return (
    <>
      {open ? (
        <div className={cx(styles['fire-flink-logo'])}>
          <LogoFireflink />
        </div>
      ) : (
        <div className={cx(styles['fire-flink-collapsed-logo'])}>
          <Tooltip title="FIREFLINK" placement="bottom">
            <CollapsedLogo />
          </Tooltip>
        </div>
      )}
      <IconButton
        style={{
          position: 'fixed',
          backgroundColor: 'white',
          left: open ? '186px' : '55px',
        }}
        onClick={handleDrawerClose}
        className={open ? cx(styles['collapsed-circle']) : cx(styles['left-circle'])}
      >
        {!open ? <RightArrowSvgIcon /> : <LeftArrowSvgIcon />}
      </IconButton>
    </>
  );
};

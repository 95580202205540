import React, { useEffect, useState } from 'react';
import { FilterCategoryDefects } from './filter-category-defects';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { ChevronRightIcon } from '@heroicons/react/solid';
import { ReactComponent as SearchIconGreay } from '@assets/analytics/SearchIconGreay.svg';
import './filters.css';
import { Close } from '@mui/icons-material';
import { Icon } from 'fireflink-ui';
import { MULTIFORM_ANALYTICS } from '@src/common/ui-constants';
import { UI_VALIDATIONS } from '@src/util/validations';
export const FilterGroupDefects = (props) => {
  const [expandGroup, setExpandGroup] = useState(true);
  const [menuData, setMenuData] = useState([]);
  let [searchValue, setSearchvalue] = useState('');
  let [showSearchIcon, setShowSearchIcon] = useState(true);

  useEffect(() => {
    if (searchValue === '') {
      setMenuData(props);
    }
  }, [props, searchValue]);

  const handleSearch = (arr, searchInput) => {
    if (!!arr) {
      let data = arr?.categories.flatMap((q) => [
        {
          categories: q.categories.filter((x) => {
            let labelSearchAvailable = x['label'].toLowerCase().includes(searchInput.toString().toLowerCase());
            return labelSearchAvailable;
          }),
          id: q.id,
          label: q.label,
          parentGroup: q.parentGroup,
          parentName: q.parentName,
          type: q.type,
          value: q.value,
        },
      ]);

      const grouped = data
        .map((x) => ({
          categories: x.categories,
          id: x.id,
          label: x.label,
          parentGroup: x.parentGroup,
          parentName: x.parentName,
          type: x.type,
          value: x.value,
        }))
        .filter((x) => x.categories.length);

      setMenuData(grouped);
    }
  };
  
  return props?.categories ? (
    <>
      <div className="group">
        <div className="filtersHeader">
          <div className="grid grid-cols-6  pl-2 pt-1 filters_labels">
            <div className="col-span-5 inline-block fontPoppinsSemiboldSm rs-blue">{props.uiLabel}</div>
            <div className="col-span-1 inline-block">
              {expandGroup ? (
                <ChevronDownIcon
                  className=" down-arrow-icon ChevronIcon cursor-hand"
                  onClick={() => {
                    setExpandGroup(false);
                  }}
                />
              ) : (
                <ChevronRightIcon
                  className="down-arrow-icon ChevronIcon cursor-hand "
                  onClick={() => {
                    setExpandGroup(true);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={`${expandGroup ? 'block suite-filter fontPoppinsMediumSm' : 'hidden suite-filter'} ${props?.categories[0]?.categories?.length > UI_VALIDATIONS.FILTERS_OPTION_SCROLLBAR_LIMIT ? 'scrollable' : ''}`}>
        {(["Labels","Assign To","Created By"].includes(props?.uiLabel)) && (
          props?.categories[0]?.categories?.length > UI_VALIDATIONS.FILTER_OPTION_SEARCHBAR_LIMIT && (
            <div className="search-section ml-2">
              <div className="flex search-btnBlock items-center relative">
                <input
                  placeholder={`Search ${props.uiLabel}`}
                  value={searchValue || ''}
                  onChange={(e) => {
                    handleSearch(props, e.target.value);
                    setSearchvalue(e.target.value);
                  }}
                  onKeyUp={() => {
                    searchValue === '' ? setShowSearchIcon(true) : setShowSearchIcon(false);
                  }}
                  className=""
                />
                {showSearchIcon ? (
                  <Icon name="search_icon" className="absolute right-2" height={22} width={22}/>
                ) : (
                  <Icon
                    name="close"
                    className="cursor-pointer absolute right-2"
                    onClick={(_) => {
                      handleSearch(props, '');
                      setSearchvalue('');
                      setShowSearchIcon(true);
                    }}
                  />
                )}
              </div>
              <div className="mt-1 ml-8">{searchValue && !(menuData.length > 0) && MULTIFORM_ANALYTICS.noResultFound}</div>
            </div>
            )
          )}

        {props?.type === 'search-menu' && (
          <>
            {menuData?.categories !== undefined
              ? menuData?.categories.map((category) => (
                  <FilterCategoryDefects
                    key={category.id}
                    category={props?.type}
                    {...category}
                    handleFilterValues={props.handleFilterValues}
                    handleAll={props.handleAll}
                    checkedAssignToIds={props.checkedAssignToIds}
                    checkedCreatedByIds={props.checkedCreatedByIds}
                    checkedLabelsIds={props.checkedLabelsIds}
                  />
                ))
              : menuData?.map((category) => (
                  <FilterCategoryDefects
                    key={category.id}
                    category={props?.type}
                    {...category}
                    handleFilterValues={props.handleFilterValues}
                    handleAll={props.handleAll}
                    checkedAssignToIds={props.checkedAssignToIds}
                    checkedCreatedByIds={props.checkedCreatedByIds}
                    checkedLabelsIds={props.checkedLabelsIds}
                  />
                ))}
          </>
        )}
      </div>
    </>
  ) : (
    <></>
  );
};

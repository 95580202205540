import React, { memo } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { styled, useTheme } from '@mui/material/styles';

import './commonDrawer.scss';
// import CommonButton from '@src/common/button/Button';
import { colors } from '@src/css_config/colorConstants';
// import { CloseIcon } from '@src/common/SvgIcon';
import { ReactComponent as CloseIcon } from '@assets/analytics/ShrinkIconWhite.svg';
import { Tooltip } from '@material-ui/core';
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1.5),
  justifyContent: 'space-between',
  position: 'sticky',
  width: '100%',
  zIndex: 999,
  color: colors.text_white,
  backgroundColor: colors.rs_primary,
  minHeight: '32px',
  top: 0,
}));

function CommonDrawer({
  headerContent = '',
  titleText = '',
  isDrawerOpen = false,
  rightButtonText = 'Save',
  leftButtonText = 'Cancel',
  drawerWidth = '',
  isRightButtonVisible = true,
  isLeftButtonVisible = true,
  disableRightButton = false,
  disableLeftButton = false,
  disableCloseButton = false,
  onRightButtonClick = () => {},
  onLeftButtonClick = () => {},
  onDrawerOpen = () => {},
  children = null,
  footerContent = null,
  showToolTip = true,
}) {
  const theme = useTheme();
  const headerTitle = (
    <span className="fontPoppinsRegularLg overflow-hidden overflow-ellipsis whitespace-nowrap w-98per">
      {titleText}
    </span>
  );
  const content = (anchor) => (
    <Box
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : `${drawerWidth ? drawerWidth : '65vw'} `,
        overflow: 'hidden',
      }}
      role="presentation"
    >
      <DrawerHeader theme={theme} className="flex justify-between fontPoppinsMediumSm">
        {headerContent ? (
          <>{headerContent}</>
        ) : (
          <>
            {showToolTip ? (
              <Tooltip title={titleText} placement="top">
                {headerTitle}
              </Tooltip>
            ) : (
              { headerTitle }
            )}
          </>
        )}

        <button
          disabled={disableCloseButton}
          onClick={onDrawerOpen}
          type="button"
          className="float-right text-white hover:ring-0.5 ring-white focus:ring-2 rounded-full  common-closeIcon"
        >
          <CloseIcon />
        </button>
      </DrawerHeader>
      <div className="dialog-content">{children}</div>
      <div className="dialog-footer">
        {footerContent ? (
          footerContent
        ) : (
          <div className="flex flex-row justify-end -mt-4">
            <div>
              {isLeftButtonVisible && (
                <button
                  disabled={disableLeftButton}
                  btnType="secondary"
                  onClick={onLeftButtonClick}
                  className="leftButton fontMontserratMediumXs p-1 px-4"
                >
                  {leftButtonText}
                </button>
              )}
            </div>
            <div className="ml-4">
              {isRightButtonVisible && (
                <button
                  disabled={disableRightButton}
                  onClick={onRightButtonClick}
                  btnType="primary"
                  className={
                    disableRightButton
                      ? 'rightButton-disabled fontMontserratMediumXs p-1 px-4'
                      : 'rightButton fontMontserratMediumXs cancel-info p-1 px-4'
                  }
                >
                  {rightButtonText}
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </Box>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={isDrawerOpen}
            onClose={onDrawerOpen}
            onOpen={onDrawerOpen}
            className="dialog-container drawer-radius-style"
          >
            <div variant="h3" component="div" sx={{ flexGrow: 1 }}>
              {content(anchor)}
            </div>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}

export default memo(CommonDrawer);

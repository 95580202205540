import MyInput from '@src/pages/common/Inputs/MyInput';
import { Select } from 'fireflink-ui';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { RestContext } from '../../../restapi';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { WEBSERVICE } from '../../../constants/constants';
import PropTypes from 'prop-types';
import useSyncContextValues from '@src/hooks/useSyncContextValues';

const APIKey = (props) => {
  const context = useContext(RestContext);

  const formik = useFormik({
    initialValues: {
      key: '',
      value: '',
      action: 'Header',
      ...(context.values?.apiKey || {}),
    },
    validationSchema: Yup.object({
      key: Yup.string().required(),
      value: Yup.string().required(),
    }),
  });

  useSyncContextValues(formik, context, 'apiKey');
  const isEditable = useMemo(() => window.permission?.isEditAccess('testDevelopment'), []);

  const { API_KEY_ACTION_LIST, API_KEY_INPUT_NAME, API_VALUE_INPUT_NAME, API_KEY_HEADER, DISABLE_STEP_PARAM_API_KEY, DISABLE_STEP_PARAM_API_VALUE } = WEBSERVICE;

  const { key, value, action } = formik.values;

  const onSelectApiAction = () => (action === API_KEY_HEADER ? API_KEY_ACTION_LIST[0] : API_KEY_ACTION_LIST[1]);

  const [apiKeyActionSelect, setApiKeyActionSelect] = useState(onSelectApiAction());

  // Handle select changes
  const apiKeyValueActionSelect = (e) => {
    setApiKeyActionSelect(e);
    onApiKeyChange({ target: { name: 'action', value: e.value } });
  };

  // useEffect for validation and cleanup
  useEffect(() => {
    formik.validateForm().then((errors) => context.setFieldValue('errors', errors));
    return () => context.setFieldValue('currentApiKeyValue', {});
  }, []);

  const onApiKeyChange = (e) => {
    let { name, value, variableId } = e?.target;
    const apiKey = context?.values?.apiKey || {};
    value = { ...apiKey, [name]: props.getUserInputValue(variableId || value, false) };

    formik.setFieldValue(name, value[name]);
    formik.handleChange(e);
    context.setValues((preValue) => ({
      ...preValue,
      authType: 'APIKey',
      apiKey: value,
      currentApiKeyValue: { ...(context.values.currentApiKeyValue || {}), [name]: e },
    }));

    // Validate the form and update context with errors
    formik.validateForm(value).then((errors) => context.setFieldValue('errors', errors));
    props.handleSaveAsSteps(false);
  };

  return (
    <main className="api-key-container flex mt-6">
      <main className="api-key-group">
        <p className="fontPoppinsMediumSm text-shadow-grey text-left mb-1">
          <span className="common-auth-star-symbol mr-1">*</span>Key
        </p>
        <MyInput
          className="api-key-input"
          canCreateVariable={isEditable}
          type="text"
          variableInput={true}
          placeholder="Enter the Key"
          value={props?.getUserInputValue(key, true)}
          inputValue="input"
          name={API_KEY_INPUT_NAME}
          onChange={onApiKeyChange}
          error={formik.errors.key && formik.touched.key}
          onBlur={formik.handleBlur}
          currentValue={context.values?.currentApiKeyValue?.key}
          autocomplete="off"
          onMouseDown={formik.handleBlur}
          apiKeyValue={DISABLE_STEP_PARAM_API_KEY}
          {...props}
        />
      </main>
      <main className="api-value-group">
        <p className="fontPoppinsMediumSm text-shadow-grey text-left mb-1">
          <span className="common-auth-star-symbol mr-1">*</span>Value
        </p>
        <MyInput
          className="api-key-input"
          canCreateVariable={isEditable}
          type="text"
          variableInput={true}
          placeholder="Enter the Value"
          value={props?.getUserInputValue(value, true)}
          inputValue="input"
          name={API_VALUE_INPUT_NAME}
          onChange={onApiKeyChange}
          error={formik.errors.value && formik.touched.value}
          onBlur={formik.handleBlur}
          currentValue={context.values?.currentApiKeyValue?.value}
          autocomplete="off"
          onMouseDown={formik.handleBlur}
          apiKeyValue={DISABLE_STEP_PARAM_API_VALUE}
          {...props}
        />
      </main>
      <main className="api-add-to-group">
        <p className="fontPoppinsMediumSm text-shadow-grey text-left">
          <span className="common-auth-star-symbol mr-1">*</span>Add to
        </p>
        <Select
          className="text-left"
          options={API_KEY_ACTION_LIST}
          selectedOption={apiKeyActionSelect}
          onChange={apiKeyValueActionSelect}
        />
      </main>
    </main>
  );
};

// Using PropTypes for the Type checking
const variableTypeValidator = PropTypes.oneOf(WEBSERVICE.VARIABLE_TYPE_ARRAY);

const variableArrayValidator = PropTypes.arrayOf(
  PropTypes.shape({
    type: variableTypeValidator.isRequired,
  })
);

APIKey.propTypes = {
  getUserInputValue: PropTypes.func.isRequired,
  handleSaveAsSteps: PropTypes.func.isRequired,
  MyAlert: PropTypes.object.isRequired,
  filteredGlobalVariable: variableArrayValidator.isRequired,
  filteredLocalVariable: variableArrayValidator.isRequired,
  filteredProjectEnvironmentalVariable: variableArrayValidator.isRequired,
  filteredStepGroupVariable: variableArrayValidator.isRequired,
  onSetAllVariableName: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default APIKey;

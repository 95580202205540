export const SETTINGS = {
  webserviceSettingsOptions: [
    {
      TITLE: 'TLS certificate verification',
      DESCRIPTION: 'To Verify TLS certificate when sending any request.',
      TOGGLE: 'tlsCertificateEnabled',
      ID: 0,
    },
    {
      TITLE: 'Request Timeout',
      DESCRIPTION:
        'To set how long a request should wait for a response before timing out.  To never time out, set to 0.',
      TOGGLE: '',
      ID: 1,
    },
    {
      TITLE: 'Maximum number of redirects to follow',
      DESCRIPTION: 'To set a limit on the maximum number of redirects to follow for any request.',
      TOGGLE: '',
      ID: 2,
    },
    {
      TITLE: 'Automatically redirect with default HTTP method',
      DESCRIPTION: 'Follow HTTP 3xx responses as redirects, using default behavior of redirecting with GET method.',
      TOGGLE: 'redirectEnabled',
      ID: 3,
    },
    {
      TITLE: 'Redirect with original HTTP method',
      DESCRIPTION:
        'To redirect with the original HTTP method instead of the default behavior of redirecting with GET method',
      TOGGLE: 'originalHttpMethodEnabled',
      ID: 4,
    },
    {
      TITLE: 'Retain authorization header while redirects',
      DESCRIPTION: 'To retain authorization header even when a redirect happens to a different hostname.',
      TOGGLE: 'retainAuthorizationHeaderEnabled',
      ID: 5,
    },
  ],
  ENABLE: 'Enable',
  DISABLE: 'Disable',
  TLS_PROTOCOLS: 'TLS Protocols enabled / disabled during handshake',
  REQUEST_TIMEOUT: [
    { label: 'MS', value: 'Ms' },
    { label: 'Secs', value: 'Secs' },
  ],
  TLS_PROTOCOLS_CONTENT: 'To enabled / disabled TLS protocols version during handshake',
  TLS_PROTOCOLS_ID: 6,
  TLS_VERSION: [
    { name: 'TLS Version 1.0', isEnabled: true },
    { name: 'TLS Version 1.1', isEnabled: true },
    { name: 'TLS Version 1.2', isEnabled: true },
    { name: 'TLS Version 1.3', isEnabled: true },
  ],
  MAX_VALUE: 10,
  MIN_VALUE: 0,
  MIN_REQUEST_TIME_SEC: 0,
  MAX_REQUEST_TIME_SEC: 100,
  MIN_REQUEST_TIME_MS: 0,
  MAX_REQUEST_TIME_MS: 100000,
  REDIRECTS: 'redirects',
  MS: 'Ms',
  SEC: 'Secs',
  REQUEST_TIMEOUT_MS: 30000,
  REQUEST_TIMEOUT_SECS: 30,
  REDIRECTS_DEFAULT: '2',
  EMPTY_STRING: '',
  DEFAULT_VALUE: '0',
  SETTING: 'settings',
  DISABLE_KEYS: ['e', 'E', '+', '-'],
};

import React, { useState, useEffect } from 'react';
import './service-downtime.scss';
import LogotextImage from '@assets/Flinko_Logo.svg';
import PageBreakImg from '@assets/page-break-image.svg';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { checkServerStatus } from '@src/api/api_services';
import { serverDownTimeMessage } from '@src/common/ui-constants';
import { Link } from 'react-router-dom';

function ServiceDownTime() {
  let [isServiceUp, setServiceStatus] = useState(false);

  const redirectToSignIn = () => {
    checkServerStatus().then((response) => {
      if (response.data.status === 'UP') {
        setServiceStatus(true);
        localStorage.clear();
        window.location.replace('/signin');
      }
    });
  };

  useEffect(() => {
    if (!isServiceUp) {
      setTimeout(() => {
        redirectToSignIn();
      }, 10000);
    }
  }, [isServiceUp]);

  return (
    <div className="main-section">
      <div className="service-down-header">
        <div className="">
          <img alt="bg-img" className="logo-section" src={LogotextImage} />
        </div>
        <Link
          className="contact-us-section"
          to={{ pathname: serverDownTimeMessage.path }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="rounded-border">
            <LocalPhoneRoundedIcon className="contact-us-img" />
          </span>
          <span className="contact-us-text">Contact Us</span>
          <span className="rounded-border-arrow">
            <ArrowForwardIcon className="contact-us-img-arrow" />
          </span>
        </Link>
      </div>

      <div className="service-down-body">
        <div className="left-side-section">
          <div className="service-down-title">
            <span className="fontPoppinsSemibold-size-42">We're Making </span>
            <span className="fontPoppinsSemibold-size-50">Things Better!</span>
          </div>
          <div className="service-down-context">{serverDownTimeMessage.content}</div>
          <div className="service-down-footer-content">{serverDownTimeMessage.footerContent}</div>
        </div>

        <div className="right-side-section">
          <img alt="page-break-img" id="page-break-img" src={PageBreakImg} />
        </div>
      </div>
    </div>
  );
}

export default ServiceDownTime;

import React, { useEffect, useState } from 'react';
import Select from 'react-dropdown-select';
import styled from '@emotion/styled';
import { Checkbox, Tooltip } from '@material-ui/core';
import EmailStyles from './email-dropdown.module.scss';
import cx from 'classnames';
import { isEmailValid } from '@util/common_utils';

const EmailDropdown = ({
  data,
  disabled,
  placeholder,
  handleSelectChange,
  selectedData,
  valueNumbers,
  handelOptions,
  onDropdownOpen,
  execution,
  targetData,
  createAlertFun,
  name,
}) => {
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      backgroundColor: state.isSelected ? '#F0F3F9' : '',
    }),
  };
  let regx = /[a-zA-Z][a-zA-Z0-9-_]+@[a-zA-Z][a-zA-Z0-9-_]+\.[a-z]{2,3}/i;
  const SearchAndToggle = styled.div`
    display: flex;
    flex-direction: column;

    input {
      margin: 10px 10px 0;
      line-height: 30px;
      padding: 0 20px;
      border-width: 0 0 2px #0000004d;
      :focus {
        outline: none;
        border-bottom: 2px solid ${({ color }) => color};
      }
    }
  `;
  const Item = styled.div`
    display: flex;
    margin: 10px;
    align-items: baseline;
    cursor: pointer;
    border-bottom: 1px dotted transparent;

    ${({ disabled }) =>
      disabled
        ? `
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
`
        : ''}
  `;

  const ItemLabel = styled.div`
    margin: 5px 10px;
  `;

  const customContentRenderer = ({ props, state, methods }) => {
    const getCountPill = (values) => {
      if (values?.length > valueNumbers) {
        return (
          <span className={EmailStyles['count-pill-numbers']}>
            {' '}
            +{values.length < 13 ? 0 : ''}
            {values.length - valueNumbers}
          </span>
        );
      } else {
        return null;
      }
    };
    const getValuePills = (values) => {
      return (
        <span className="flex flex-row">
          {values.map((value, index) => {
            if (index < valueNumbers) {
              return (
                <span className={`flex items-center ${EmailStyles['count-pill']}`}>
                  {' '}
                  <Tooltip title={value?.label ? value?.label : value?.name} placement="top">
                    <span className={`${EmailStyles['email-name-trimmer']}`}>
                      {value?.label
                        ? value?.label
                        : value?.name || value?.instanceName
                          ? value.instanceName
                          : value.label}{' '}
                    </span>
                  </Tooltip>
                  <span
                    className="text-2xl ml-2"
                    onClick={() => {
                      methods.removeItem(null, value);
                      handelOptions(value, 'remove');
                    }}
                  >
                    &times;{' '}
                  </span>
                </span>
              );
            }
          })}
        </span>
      );
    };

    return (
      <div className="flex">
        {state?.values?.length ? (
          <span>{getValuePills(state?.values)}</span>
        ) : (
          <span className="fontPoppinsRegularSm">{props.placeholder}</span>
        )}
        {getCountPill(state?.values)}
      </div>
    );
  };

  const customDropdownRenderer = ({ props, state, methods }) => {
    const regexp = new RegExp(state.search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
    const options = props.options.filter((item) => regexp.test(item[props.searchBy] || item[props.labelField]));
    function enterPressAlert(e) {
      var code = e.keyCode ? e.keyCode : e.which;
      let optionEmailindex = options.findIndex((optionValue) => optionValue.label === e.target.value);
      if (code === 13) {
        e.preventDefault();
      }
      if (code === 13 && isEmailValid(e.target.value) && optionEmailindex < 0) {
        if (execution === true) {
          const newTargetData = targetData.map((values) => values.toLowerCase());
          if (!newTargetData.includes(e.target.value)) {
            if (!isEmailValid(e.target.value)) {
              createAlertFun('info', `${e.target.value} is not a valid EmailId`);
            } else {
              methods.addItem({
                label: e.target.value,
                value: e.target.value,
                emailId: e.target.value,
                instanceName: e.target.value,
              });
              handelOptions(
                { label: e.target.value, value: e.target.value, emailId: e.target.value, instanceName: e.target.value },
                'add'
              );
            }
          } else {
            createAlertFun('info', `${e.target.value} EmailId already exists`);
          }
        } else {
          methods.addItem({
            label: e.target.value,
            value: e.target.value,
            emailId: e.target.value,
            instanceName: e.target.value,
          });
          handelOptions(
            { label: e.target.value, value: e.target.value, emailId: e.target.value, instanceName: e.target.value },
            'add'
          );
        }
      } else if (code === 13 && !e.target.value) {
        createAlertFun(
          'info',
          `${e.target.value} Please add at least one email in the “Email” section to send a report after execution`
        );
      } else if (code === 13 && !isEmailValid(e.target.value)) {
        createAlertFun('info', `${e.target.value} is not a valid EmailId`);
      }
    }
    return (
      <>
        <div>
          <SearchAndToggle>
            <input
              autoFocus
              type="text"
              value={state.search}
              onChange={methods.setSearch}
              onKeyPress={enterPressAlert}
              placeholder={
                name === 'slack integration' ? 'Enter slack instance name here...' : 'Start adding email/group here...'
              }
            />
          </SearchAndToggle>
        </div>
        <div className="overflow-y-auto" id="journal-scroll">
          {options.length === 0 ? (
            <div className="noData pl-5 pt-5 pb-5">
              {name === 'slack integration' ? (
                <div className="flex flex-col">
                  {' '}
                  <span className="fontPoppinsSemiboldMd">No Instance Found !</span>{' '}
                  <span className="fontPoppinsRegularMd">
                    Please create At least once Instance to slack the execution Report
                  </span>
                </div>
              ) : (
                'No options'
              )}{' '}
            </div>
          ) : (
            <div className="exe-env-options-dropdown">
              {options.map((option) => {
                return (
                  <Tooltip title={option[props?.labelField]} placement="top">
                    <div key={option[props.labelField]} className="">
                      <Item
                        disabled={option.disabled}
                        key={option[props.valueField]}
                        className=""
                        onClick={option.disabled ? null : () => methods.addItem(option)}
                      >
                        <ItemLabel>
                          {' '}
                          <Checkbox
                            size="small"
                            color="primary"
                            checked={state?.values?.find((value) => value?.label === option?.label)}
                          />{' '}
                          {option[props?.labelField]?.length > 25
                            ? option[props?.labelField]?.substring(0, 25) + '...'
                            : option[props?.labelField]}
                        </ItemLabel>
                      </Item>
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          )}
        </div>
      </>
    );
  };

  const handleChange = (values) => {
    if (JSON.stringify(values) !== JSON.stringify(selectedOptions)) {
      setSelectedOptions([...values]);
      handleSelectChange([...values]);
    }
  };

  useEffect(() => {
    const key = 'label';
    const unique = [...new Map(data && data?.map((item) => [item[key], item])).values()];
    setOptions(unique?.length ? [...unique] : []);
  }, [data]);
  useEffect(() => {
    const selectedDataNN = selectedData?.filter((n) => n);
    setSelectedOptions(selectedDataNN?.length ? [...selectedDataNN] : []);
  }, [selectedData]);
  return (
    <div>
      <Select
        options={options}
        closeOnSelect={false}
        searchable={true}
        backspaceDelete={false}
        create={true}
        className={cx('fontPoppinsRegularMd searchable-dropdown-custom', EmailStyles['select-dropdown'])}
        values={selectedOptions}
        disabled={disabled}
        styles={customStyles}
        dropdownRenderer={(innerProps, innerState, innerMethods) =>
          customDropdownRenderer(innerProps, innerState, innerMethods)
        }
        contentRenderer={customContentRenderer}
        onChange={(values) => {
          handleChange(values);
        }}
        multi={true}
        placeholder={placeholder}
      />
    </div>
  );
};

export default EmailDropdown;

import React, { useEffect, useState, useContext } from 'react';
import { useFormik } from 'formik';
import { postStepTag, updateStepTag, getStepResult, trainAutoTagModel } from '@api/api_services';
import * as yup from 'yup';
import { getIDB } from '@src/util/localForage_idb_controller';
import { TAG_STEP_MODEL_TOOLTIP_CONSTANTS } from '@src/common/ui-constants';
import { Checkbox, Drawer, Icon, Input, Label, RadioButton, TextArea, Tooltip } from 'fireflink-ui';
import { isEmptyValue } from '@src/util/common_utils';
import { UI_VALIDATIONS } from '@src/util/validations';
import { ToasterContainerContext } from '@src/common/atoms/Toast/Toast';
import { TOAST_CONSTANTS } from '@src/common/ui-constants';

const TagStepModal = (props) => {
  const { toastPropsHandler } = useContext(ToasterContainerContext);
  let { setOpenTagModal, stepsData, tagDTO, index, edit, updateStep, clientId, taggedData, discardAutotaggingStep } =
    props;
  let {
    TAG_ALL_STEPS,
    TAG_ONLY_THIS_STEP,
    THIS_STEP_ONLY_UPDATE_OCCURANCE_IF_TRUE_INFO,
    THIS_STEP_ONLY_UPDATE_OCCURANCE_IF_FALSE_INFO,
    ALL_STEPS_UPDATE_OCCURANCE_IF_TRUE_INFO,
    ALL_STEPS_UPDATE_OCCURANCE_IF_FALSE_INFO,
  } = TAG_STEP_MODEL_TOOLTIP_CONSTANTS;
  let data = stepsData[index];
  let listOfAllTags = [];
  const isTagged = taggedData?.tagDetailsDTOs?.[0]?.Tagged === 'YES';
  useEffect(() => {
    getAllTags();
  }, [listOfAllTags]);

  let initialValues;
  let tagName = '';

  const settingInitialValues = () => {
    if (!taggedData || !isTagged) {
      initialValues = {
        name: '',
        description: '',
        tagType: '',
        updateOccurance: false,
        tagScope: '',
      };
    } else {
      initialValues = {
        name: taggedData?.tagDetailsDTOs?.[0]?.tagName,
        description: '',
        tagType: taggedData?.tagDetailsDTOs?.[0]?.bugType,
        updateOccurance: taggedData?.tagDetailsDTOs?.[0]?.scope_of_script,
        tagScope: taggedData?.tagDetailsDTOs[0]?.scope_of_step === 'AS' ? TAG_ALL_STEPS : TAG_ONLY_THIS_STEP,
      };
    }
  };

  if (edit) {
    if (tagDTO) {
      tagName = tagDTO.name;
      initialValues = {
        name: tagDTO.name,
        description: tagDTO.description,
        tagType: tagDTO.type,
        updateOccurance: tagDTO.updateEveryWhere,
        tagScope: tagDTO.updateAllSteps ? TAG_ALL_STEPS : TAG_ONLY_THIS_STEP,
      };
    }
  } else {
    settingInitialValues();
  }
  const getAllTags = () => {
    stepsData.forEach((_stp) => {
      if (_stp.tagDetailsDTOs) {
        _stp.tagDetailsDTOs.forEach((dto) => {
          let isDup = listOfAllTags.find((_tag) => _tag.name === dto.name);
          if (!isDup) {
            if (edit) {
              if (tagName !== dto.name) {
                listOfAllTags.push(dto);
              }
            } else {
              listOfAllTags.push(dto);
            }
          }
        });
      }
    });
  };

  const duplicateTagChecker = (val) => {
    if (listOfAllTags.length && val) {
      let dupObj = listOfAllTags.find((_tag) => {
        return _tag?.name?.toLowerCase()?.trim() === val?.toLowerCase()?.trim();
      });
      return !dupObj;
    }
    return true;
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .trim()
      .required('Name is required')
      .min(3, 'Name must be between 3 and 25 characters')
      .max(25, 'Name must be between 3 and 25 characters')
      .matches(/^[a-zA-Z0-9-_() ]*$/, 'Name should be alphanumeric')
      .test('LegalTagName', 'Cannot create tag with only special characters', (values) => {
        const regex = new RegExp('[a-zA-Z0-9]');
        return regex.test(values);
      })
      .test('Unique', 'Tag name already exists', (values) => {
        return duplicateTagChecker(values);
      }),
    description: yup
      .string()
      .trim()
      .max(UI_VALIDATIONS.MAX_DESCRIPTION_CHARACTER_COUNT, 'Description cannot exceed 200 characters'),
    tagType: yup.string().trim().required('Tag type is required'),
    tagScope: yup.string().trim().required('Tag is required'),
  });
  const formikDetails = useFormik({
    initialValues,
    validationSchema,
  });
  const [descCount, setdescCount] = useState(0);
  useEffect(() => {
    if (edit) {
      setdescCount(tagDTO.description.length);
    }
  }, []);

  const handleTag = async (doneBy) => {
    if (data?.uniqueId) {
      const response = await getStepResult(data.uniqueId);
      if (response?.data?.responseObject) {
        createEditAndApplyTag(response.data.responseObject, 'Optimized', doneBy);
      }
    } else {
      createEditAndApplyTag(data, doneBy);
    }
  };

  const createEditAndApplyTag = async (data, state, doneBy) => {
    let executionId;
    const search = window.location.search;
    let exeIdFromUrl = new URLSearchParams(search).get('executionId');
    const scriptData = await getIDB('script-data');
    const execResult = (await getIDB('execResult'))?.responseObject;
    const variable = data.stepInputs.filter((input) => input?.reference === 'LOCAL' || input?.reference === 'GLOBAL');
    const element = data.stepInputs.filter((input) => input?.reference === 'ELEMENT');
    const utilObj = {
      stepIndex: index,
      stepId: data?.stepId,
    };
    const payload = {
      name: formikDetails.values.name.trim(),
      description: formikDetails.values.description,
      type: formikDetails.values.tagType,
      updateEveryWhere: formikDetails.values.updateOccurance,
      stepId: data.stepId,
      scriptId: scriptData[0].key,
      updateAllSteps: !(formikDetails.values.tagScope === TAG_ONLY_THIS_STEP),
      key: data?.key,
      state: state,
      clientId: clientId,
      status: 'Active',
    };
    if (data.nlpName) {
      payload['nlpName'] = data.nlpName;
    }
    if (data.errorLog) {
      payload['exceptionName'] = data.errorLog.name;
      utilObj['exceptionName'] = data.errorLog.name;
    }
    if (variable.length) {
      payload['variable'] = {
        reference: variable[0].reference,
        actualValue: variable[0].actualValue,
        value: variable[0].value,
      };
    }
    if (element.length) {
      payload['elementId'] = element[0].value;
      utilObj['elementId'] = element[0].value;
    }
    if (execResult && !isEmptyValue(execResult.executionResponses)) {
      const exeSuiteId = execResult?.executionResponses?.[0]?.suiteId;
      const exeProjectId = execResult?.executionResponses?.[0]?.projectId;
      executionId = exeIdFromUrl || execResult.executionResponses?.[0]?.executionId;
      if (exeSuiteId) payload['suiteId'] = exeSuiteId;
      if (exeProjectId) payload['projectId'] = exeProjectId;
    }
    if (isTagged) {
      payload['taggedBy'] = 'AI';
    } else {
      payload['taggedBy'] = 'MANUAL';
    }
    try {
      let res;
      if (edit) {
        let tagId = tagDTO.id ? tagDTO.id : tagDTO._id;
        payload['id'] = tagId;
        res = await updateStepTag(tagId, executionId, payload);
        if (res && res.data.responseCode === 200) {
          if (res?.data?.message?.includes('Tag is already present')) {
            toastPropsHandler({
              variant: TOAST_CONSTANTS.VARIANTS.INFO,
              message: res?.data?.message,
            });
          } else {
            tagDTO['name'] = payload.name;
            tagDTO['description'] = payload.description;
            tagDTO['type'] = payload.type;
            tagDTO['updateEveryWhere'] = payload.updateEveryWhere;
            tagDTO['updateAllSteps'] = payload.updateAllSteps;
            updateStep(data, tagDTO, 'EDIT', utilObj);
          }
        } else {
          //when edit is not successful
          if (res && res.data.message) {
            toastPropsHandler({
              variant: TOAST_CONSTANTS.VARIANTS.DANGER,
              message: res?.data?.message,
            });
          } else {
            toastPropsHandler({
              variant: TOAST_CONSTANTS.VARIANTS.DANGER,
              message: 'Tag update failed. Please try again.',
            });
          }
        }
      } else {
        res = await postStepTag(executionId, payload);
        if (res && res.data.message ? res.data.message.includes('Tag is already present') : '')
          toastPropsHandler({
            variant: TOAST_CONSTANTS.VARIANTS.INFO,
            message: res?.data?.message,
          });
      }
      if (res && res.data.responseCode === 201) {
        const resObj = res.data.responseObject;
        const tagDTO = {
          id: resObj.id,
          name: resObj.name,
          description: resObj.description,
          type: resObj.type,
          updateEveryWhere: resObj.updateEveryWhere,
          updateAllSteps: resObj.updateAllSteps,
        };
        updateStep(data, tagDTO, 'CREATE', utilObj);
        trainingAutoTagAIModel(doneBy); //for sending the data back to AI Lookup Table for learning
      }
    } catch (err) {
      console.error(err.message);
      toastPropsHandler({
        variant: TOAST_CONSTANTS.VARIANTS.DANGER,
        message: err.message,
      });

      setOpenTagModal({
        openModal: false,
        data: null,
        index: null,
        edit: false,
        tagDTO: null,
        _tagindex: null,
        tagDetailsDTOs: null,
        stepId: null,
      });
    }
  };

  const submitTagDetails = () => {
    const { isValid: valid, dirty: isDirty } = formikDetails || {};
    if (edit) {
      if (valid && isDirty) {
        handleTag();
      }
    } else {
      formikDetails.setTouched({
        tagType: true,
        tagScope: true,
        name: true,
        description: true,
      });
      if (taggedData && isTagged && valid) {
        isDirty ? handleTag('AI&MANUAL') : handleTag('AI');
      }
      if (!taggedData && isDirty && valid) {
        handleTag('MANUAL');
      }
    }
  };

  const trainingAutoTagAIModel = (doneBY) => {
    const payload = {
      nlpName: data?.nlpName,
      updateEveryWhere: formikDetails.values.updateOccurance,
      exceptionName: data?.errorLogName,
      tagName: formikDetails.values.name,
      taggedBy: isTagged ? 'AI' : 'MANUAL',
      updateAllSteps: formikDetails.values.tagScope,
      type: formikDetails.values.tagType,
      doneBY: doneBY,
    };
    trainAutoTagModel(payload);
  };
  const drawerTitle = () => {
    if (taggedData || isTagged) {
      return 'Edit Tag - AI Tag';
    } else if (edit) {
      return 'Edit Tag';
    }
    return 'Create Tag';
  };

  const primaryButtonProps = {
    label: taggedData || isTagged ? 'Approve' : edit ? 'Update' : 'Create',
    disabled: false,
    onClick: () => submitTagDetails(),
    variant: 'primary',
  };

  const secondaryButtonProps = {
    disabled: false,
    label: taggedData || isTagged ? 'Discard' : 'Cancel',
    onClick: () => {
      setOpenTagModal({ openModal: false, data: null, index: null, edit: false, stepId: null });
      if (taggedData || isTagged) {
        discardAutotaggingStep('Discard');
      }
    },
    variant: 'secondary',
  };
  return (
    <Drawer
      isOpen={setOpenTagModal.openModal}
      overlay={true}
      footerContent={null}
      isFooterRequired
      onClose={() => setOpenTagModal({ openModal: false, data: null, index: null, edit: false, stepId: null })}
      title={drawerTitle()}
      primaryButtonProps={primaryButtonProps}
      secondaryButtonProps={secondaryButtonProps}
    >
      <div>
        <div className="modal-body relative" class>
          <form onSubmit={formikDetails.handleSubmit}>
            <Input
              label="Name"
              name="name"
              placeholder="Enter tag name"
              type="text"
              value={formikDetails.values.name}
              onBlur={formikDetails.handleBlur}
              onChange={formikDetails.handleChange}
              error={formikDetails.errors && formikDetails.touched}
              id="name"
              helperText={formikDetails.errors.name}
              autoComplete="off"
              required={true}
              variant={formikDetails.errors.name && formikDetails.touched.name ? 'danger' : 'default'}
            />
            <TextArea
              labelProps={{
                label: 'Description',
              }}
              variant="default"
              error={formikDetails.errors.description && formikDetails.touched.description}
              id="description"
              name="description"
              value={formikDetails.values.description}
              placeholder="Enter description"
              onKeyUp={(e) => setdescCount(e.target.value.length)}
              onBlur={formikDetails.handleBlur}
              onChange={formikDetails.handleChange}
              capacity={UI_VALIDATIONS.MAX_DESCRIPTION_CHARACTER_COUNT}
              rows={2}
              requiredMessage={formikDetails.errors.description}
            />
            <div>
              <Label color="info" label="Tag Type" required={true} htmlFor="tagTypeBug_in_application" />
              <div className="flex flex-1 items-center text-sm w-full fontPoppinsRegularSm">
                <RadioButton
                  label="Bug in application"
                  variant="primary"
                  name="tagType"
                  id="tagTypeBug_in_application"
                  value="Bug in application"
                  onChange={formikDetails.handleChange}
                  checked={formikDetails.values?.tagType?.toLowerCase() === 'bug in application'}
                />
                <div className="ml-20">
                  <RadioButton
                    label="Bug in script"
                    variant="primary"
                    name="tagType"
                    id="tagTypeBug_in_script"
                    value="Bug in script"
                    onChange={formikDetails.handleChange}
                    onBlur={formikDetails.handleBlur}
                    checked={formikDetails.values?.tagType?.toLowerCase() === 'bug in script'}
                  />
                </div>
              </div>
              <div>
                {formikDetails.errors.tagType && formikDetails.touched.tagType && (
                  <div className="errorMessage fontPoppinsRegularXs8px">{formikDetails.errors.tagType}</div>
                )}
              </div>
            </div>
            <div className="mt-2 fontPoppinsRegularSm">
              <Label color="info" label="Scope" htmlFor="updateOccurance" />
              <div className="mt-2">
                <Checkbox
                  label="Apply this tag for all the suites of this project"
                  name="updateOccurance"
                  id="updateOccurance"
                  onChange={formikDetails.handleChange}
                  onBlur={formikDetails.handleBlur}
                  checked={formikDetails.values.updateOccurance}
                  disabled={edit}
                />
              </div>
            </div>
            <div className="mt-2 lg:mt-2 xl:mt-3 w-4/5">
              <div className="flex flex-1 items-center w-full">
                <div className="w-full ">
                  <Label color="info" label="Tag" htmlFor="scopeOnlyForThisStep" required />
                  <div className="flex flex-1 items-center">
                    <div className="flex flex-1 items-center fontPoppinsRegularSm">
                      <RadioButton
                        label="This step only"
                        variant="primary"
                        name="tagScope"
                        id="scopeOnlyForThisStep"
                        value="Tag only for this step"
                        disabled={edit}
                        onChange={formikDetails.handleChange}
                        checked={formikDetails.values.tagScope === TAG_ONLY_THIS_STEP}
                      />
                      <div className="fontPoppinsRegularSm">
                        <Tooltip
                          placement="bottom"
                          title={
                            formikDetails.values.updateOccurance
                              ? THIS_STEP_ONLY_UPDATE_OCCURANCE_IF_TRUE_INFO
                              : THIS_STEP_ONLY_UPDATE_OCCURANCE_IF_FALSE_INFO
                          }
                        >
                          <Icon className="ml-2 h-4" name="info" />
                        </Tooltip>
                      </div>
                    </div>
                    <div className="flex flex-1 items-center text-sm fontPoppinsRegularSm">
                      <RadioButton
                        label="All the steps with this issue"
                        variant="primary"
                        name="tagScope"
                        id="scopeAllStepsWithIssue"
                        value="Tag all the steps with this issue"
                        disabled={edit}
                        onChange={formikDetails.handleChange}
                        onBlur={formikDetails.handleBlur}
                        checked={formikDetails.values.tagScope === TAG_ALL_STEPS}
                      />
                      <div className="fontPoppinsRegularSm">
                        <Tooltip
                          placement="bottom"
                          title={
                            formikDetails.values.updateOccurance
                              ? ALL_STEPS_UPDATE_OCCURANCE_IF_TRUE_INFO
                              : ALL_STEPS_UPDATE_OCCURANCE_IF_FALSE_INFO
                          }
                        >
                          <Icon className="ml-2 h-4" name="info" />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {formikDetails.errors.tagScope && formikDetails.touched.tagScope && (
                <div className="errorMessage fontPoppinsRegularXs8px">{formikDetails.errors.tagScope}</div>
              )}
            </div>
          </form>
        </div>
      </div>
    </Drawer>
  );
};

export default TagStepModal;

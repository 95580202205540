import * as localforage from 'localforage';

export const setIDB = (key, data) => {
  localforage
    .setItem(key, data)
    .then((value) => {
      console.info('data saved in indexedDb:', value);
    })
    .catch((error) => {
      console.error('error in indexedDb:', error);
    });
};

export const getIDB = async (key) => {
  let _dbData = await getDbData(key);
  return _dbData;
};

const getDbData = async (key) => {
  if (!localforage.getItem(key)) return;
  return await localforage.getItem(key);
};

export const removeIDB = (key) => {
  if (localforage.getItem(key)) {
    localforage
      .removeItem(key)
      .then((value) => {
        console.info('data removed from indexedDb:', value);
      })
      .catch((error) => {
        console.error('error in removing:', error);
      });
  }
};

import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import * as yup from 'yup';
import { TextareaAutosize, TextField } from '@material-ui/core';
import { useFormik } from 'formik';

import '@pages/test-development/test-development.scss';

import { createModuleReq, updateModuleReq } from '@api/api_services';
import TreeWithRadioButton from '@pages/common/table_tree/table-tree-with-radio-button';
import { Close } from '@mui/icons-material';
import { isEmptyValue } from '@src/util/common_utils';

Modal.setAppElement('#root');
function ModuleModal(props) {
  const nameFieldRef = useRef(null);
  const [createCalled, setCreateCalled] = useState(false);
  const projectDetails = JSON.parse(localStorage.getItem('selected-project'));
  const parentSpecClass = {
    overflowClass: 'w-11/12',
  };
  let editData = {};
  if (props?.data) {
    editData = props.data;
  }
  let [executionOrder, setExecutionOrder] = useState(
    props.nodeToAdd ? (props.nodeToAdd === 0 ? 1 : genarateExecutionOrder(props.nodeToAdd)) : 1
  );
  const [hierarchy, setHierarchy] = useState(
    props.nodeToAdd ? (props.nodeToAdd === 0 ? 1 : findHierarchy(props.nodeToAdd)) : 1
  );
  const [descCount, setdescCount] = useState(props.data ? editData.desc.length : 0);
  let [noOfRows, setNoOfRows] = useState(1);
  const [openModal, setOpenModal] = useState(true);
  const [selectedParentNode, setSelectedParentNode] = useState(
    props?.parentNode ? (props?.data ? props.parentNode.parent : props.parentNode) : undefined
  );
  const [disableSubmit, setDisableSubmit] = useState(true);
  const customStylesPage = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '640px',
      height: 'fit-content',
      background: '#fbfcfd 0% 0% no-repeat padding-box',
      boxShadow: '0px 0px 11px #000000a1',
      borderRadius: '4px',
      opacity: '1',
    },
  };
  let initialValues;
  if (props?.data) {
    initialValues = {
      moduleName: editData?.name,
      description: editData?.desc,
      parentModule: editData?.parentId,
    };
  } else {
    initialValues = {
      moduleName: '',
      description: '',
      parentModule: props.moduleIdForAdd ? props.moduleIdForAdd : '',
    };
  }

  const [selectedModule, setSelectedModule] = useState('root');
  function genarateExecutionOrder(node, rootNode = false) {
    if (rootNode) {
      return node.lastExecutionOrder + 1;
    } else {
      return node.data.lastExecutionOrder + 1;
    }
  }
  function findHierarchy(node) {
    if (node.title.toLowerCase() === 'root module' && node?.data?.hierarchy === 0) {
      return 1;
    } else {
      return node.data.hierarchy + 1;
    }
  }
  const treeNodeSelected = ([data]) => {
    if (!data) {
      formikDetails.setFieldValue('parentModule', '');
    } else if (data && data.data.isRootNode) {
      setSelectedParentNode(data.data);
      setExecutionOrder(genarateExecutionOrder(data.data, true));
      setHierarchy(1);
      setSelectedModule(data.data);
      formikDetails.setFieldValue('parentModule', data.data.key);
    } else if (data && data.data.key) {
      setSelectedParentNode(data.node);
      setExecutionOrder(genarateExecutionOrder(data.node));
      setHierarchy(findHierarchy(data.node));
      setSelectedModule(data.data);
      formikDetails.setFieldValue('parentModule', data.data.key);
    }
    nameFieldRef?.current?.focus();
  };

  const checkForDuplicates = (node, enteredModuleName) => {
    if (node?.children?.length) {
      for (const childNode of node.children) {
        if (
          childNode.title.toLowerCase().trim() === enteredModuleName.toLowerCase().trim() &&
          childNode.folder &&
          editData?.id !== (childNode._key ? childNode._key : childNode.key)
        ) {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  };

  const onSubmit = (values) => {
    let requestBody;
    if (values && !createCalled) {
      if (
        props.data
          ? props.data.name.trim().toLowerCase() !== values.moduleName.trim().toLowerCase() &&
            checkForDuplicates(selectedParentNode, values.moduleName)
          : checkForDuplicates(selectedParentNode, values.moduleName)
      ) {
        formikDetails.setFieldError('moduleName', `Name already exists`);
      } else {
        setCreateCalled(true);
        if (props?.data) {
          requestBody = {
            name: values.moduleName,
            desc: values.description,
            parentId: values.parentModule,
            parentName: editData.parentName,
            projectId: projectDetails.id,
            executionOrder: editData.executionOrder,
            hierarchy: editData.hierarchy,
          };
          updateModule(editData.id, requestBody);
        } else {
          requestBody = {
            name: values.moduleName,
            desc: values.description,
            parentId: values.parentModule,
            parentName: props.moduleNameForAdd ? props.moduleNameForAdd : selectedModule.title,
            projectId: projectDetails.id,
            executionOrder: executionOrder,
            hierarchy: hierarchy,
          };
          createModule(requestBody);
        }
      }
    }
  };

  async function createModule(data) {
    try {
      const response = await createModuleReq(data);
      if (response && response.data && response.data.responseObject) {
        if (props.moduleIdForAdd && props.moduleNameForAdd !== 'Root Module' && false) {
          const updateData = {
            createdBy: response.data.responseObject.createdBy,
            modifiedBy: response.data.responseObject.modifiedBy,
            createdByUname: response.data.responseObject.createdByUname,
            modifiedByUname: response.data.responseObject.modifiedByUname,
            createdOn: response.data.responseObject.createdOn,
            modifiedOn: response.data.responseObject.modifiedOn,
            state: response.data.responseObject.state,
            path: response.data.responseObject.path,
            searchKey: response.data.responseObject.searchKey,
            parentId: response.data.responseObject.parentId,
            parentName: response.data.responseObject.parentName,
            ver: response.data.responseObject.ver,
            _key: response.data.responseObject.id,
            key: response.data.responseObject.id,
            title: response.data.responseObject.name,
            name: response.data.responseObject.name,
            projectId: response.data.responseObject.projectId,
            executionOrder: response.data.responseObject.executionOrder,
            hierarchy: response.data.responseObject.hierarchy,
            scripts: response.data.responseObject.scripts,
            subModuleCount: response.data.responseObject.subModuleCount,
            scriptCount: response.data.responseObject.scriptCount,
            folder: response.data.responseObject.folder,
          };
          props.updatedValue(updateData, 'folder');
        } else {
          props.reloadTree(true);
        }
        props.MyAlert.success(
          `${data.name?.length > 22 ? data.name?.substring(0, 22) + '...' : data.name} module created successfully`
        );
        props.closeModal(false);
      } else if (
        response.data.responseCode === 400 &&
        response.data.message === `Resource with name '${data.name}' already exists`
      ) {
        setCreateCalled(false);
        formikDetails.setFieldError('moduleName', 'Name already exists');
      } else {
        props.MyAlert.warning(`${response.data.message}`);
        props.closeModal(false);
      }
    } catch (err) {
      console.error('CREATE_MODULE : ', err);
    }
  }
  async function updateModule(id, data) {
    try {
      const response = await updateModuleReq(id, data);
      if (response && response.data && response.data.responseObject) {
        props.closeModal(false);
        props.reloadTree(true);
        props.MyAlert.success(
          `${data.name?.length > 22 ? data.name?.substring(0, 22) + '...' : data.name} module updated successfully`
        );
      } else if (response.data.status === `Module :${data.name}  already exist`) {
        setCreateCalled(false);
        formikDetails.setFieldError('moduleName', 'Name already exists');
      } else {
        props.MyAlert.warning(`${response.data.message}`);
        props.closeModal(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const validationSchema = yup.object({
    moduleName: yup
      .string()
      .trim('Space is not allowed at starting and at the end')
      .strict(true)
      .required('Name is required')
      .matches(/^[a-zA-Z0-9-_() ]*$/, 'Name should be alphanumeric'),
    description: yup.string(),
    parentModule: yup.string().required('Parent module is required'),
  });

  const formikDetails = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const disableButton = () => {
    if (
      (formikDetails?.values.moduleName === editData?.name && formikDetails?.values.description === editData?.desc) ||
      (isEmptyValue(formikDetails?.values.moduleName) && isEmptyValue(formikDetails?.values.description))
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    setDisableSubmit(disableButton());
  }, [formikDetails.values]);

  return (
    <Modal
      isOpen={openModal}
      style={customStylesPage}
      onRequestClose={(e) => {
        if (e.key === 'Escape') {
          setOpenModal(false);
          props.closeModal(false);
        }
      }}
      className="p-px"
    >
      <div className="mt-2 p-1">
        <p className="Popup-header-common pl-3 inline-block w-10/12 text-overflow-style">
          {props?.data ? (
            <span title={editData?.name} className="">
              {' '}
              Edit Module - {editData?.name}
            </span>
          ) : (
            <span className=""> Create Module</span>
          )}
        </p>
        <div className="float-right">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="ml-5 mr-5 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <Close color="action" />
          </button>
        </div>
      </div>
      <form onSubmit={formikDetails.handleSubmit}>
        <div className="test-dev-modal module-modal">
          <div className="border-b border-t border-blue-100 mt-px mb-px pb-3">
            <div className="overflow-y-auto test-dev-popup" id="journal-scroll">
              <div className="ml-6 mr-6 mt-3 grid grid-cols-1 gap-4 create-edit-data-providers-input">
                <div className="w-2/5 name-field-test-dev">
                  <TextField
                    error={formikDetails.errors.moduleName && formikDetails.touched.moduleName}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={nameFieldRef}
                    type="text"
                    className="mui-input-text-field"
                    autoFocus={true}
                    autoComplete="off"
                    name="moduleName"
                    id="moduleName"
                    placeholder="Enter module name"
                    onBlur={formikDetails.handleBlur}
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.moduleName}
                    label={
                      <>
                        {' '}
                        <span className="text-red-400">*</span> Name
                      </>
                    }
                  />
                  {formikDetails.errors.moduleName && formikDetails.touched.moduleName ? (
                    <div className="text-red-400 text-xs fontPoppinsRegularSm">{formikDetails.errors.moduleName}</div>
                  ) : null}
                </div>

                <div className="">
                  <label id="descriptionLabel" htmlFor="description" className="input-filed-label-style-common">
                    Description
                  </label>
                  <TextareaAutosize
                    onBlur={(e) => {
                      setNoOfRows(1);
                      document.getElementById('descriptionLabel').style.color = '#727171';
                      e.target.classList.add('descriptionStyle');
                    }}
                    onFocus={(e) => {
                      setNoOfRows(null);
                      document.getElementById('descriptionLabel').style.color = '#1648C6';
                      e.target.classList.remove('descriptionStyle');
                    }}
                    maxRows={noOfRows}
                    id="description"
                    name="description"
                    maxLength="200"
                    className="block w-full input-style-textarea popup-input-bg  mt-1 pl-px  border-0 border-b input-field-color descriptionStyle"
                    onChange={formikDetails.handleChange}
                    onKeyUp={(e) => setdescCount(e.target.value.length)}
                    value={formikDetails.values.description}
                    placeholder="Your description goes here..."
                  />
                  <div className="mt-1 text-sm text-gray-500 text-right">{descCount}/200</div>
                </div>

                <div className="parent-module-field-test-dev">
                  <label
                    htmlFor="parentModule"
                    className={
                      formikDetails.errors.parentModule && formikDetails.touched.parentModule
                        ? 'text-xs text-red-500'
                        : 'input-filed-label-style-common'
                    }
                  >
                    <span className="text-red-400">&#42;</span>Parent Module
                  </label>
                  <div className="popup-input-bg">
                    <TreeWithRadioButton
                      moduleSelection={true}
                      individualTree={props.individualTreeId ? true : false}
                      data={props?.treeData ? props.treeData : []}
                      operation={props.data ? 'edit' : props.moduleIdForAdd ? 'edit' : null}
                      placeholder={
                        props.data
                          ? props.data.parentName
                          : props.moduleIdForAdd
                          ? props.moduleNameForAdd
                          : 'Search and select parent module'
                      }
                      nodeSelected={treeNodeSelected.bind(this)}
                      hideElements={true}
                      hideElementsBtn={true}
                      buttonLabel="Module"
                      parentSpecificClass={parentSpecClass}
                    />
                  </div>
                  {formikDetails.errors.parentModule &&
                  formikDetails.touched.parentModule &&
                  formikDetails.values.parentModule === '' ? (
                    <div className="text-red-400 text-xs">{formikDetails.errors.parentModule}</div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="float-right my-3 mx-5">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="gray-btn"
          >
            Cancel
          </button>
          <button disabled={disableSubmit} type="submit" className="primary-btn ml-3">
            {props?.data ? 'Update' : 'Create'}
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default ModuleModal;

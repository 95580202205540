import React, { useEffect, useRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTable } from 'react-table';

const TableWithDragAndDrop = ({ isDragDisabled = false, ...props }) => {
  const tableRef = useRef();

  useEffect(() => {
    if (props.data && props.hasOwnProperty('scrollHandler')) {
      window.addEventListener(
        'scroll',
        (e) => {
          props?.scrollHandler(e);
        },
        true
      );
      return () => {
        window.removeEventListener('scroll', (e) => {
          props?.scrollHandler(e);
        }); //remove event listener is must on component unmount
      };
    }
  }, [props.data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: props.columns,
    data: props.data,
  });
  return (
    <DragDropContext onDragEnd={props.handleOnDragEnd}>
      <table {...getTableProps()} className="" ref={tableRef}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="fontPoppinsRegularMd uppercase h-12 ml-4 bg-gray-100 hover:bg-gray-100"
            >
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} width={column.width} className="sticky pl-8 text-left">
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <Droppable droppableId="droppable">
          {(provided) => (
            <tbody {...getTableBodyProps()} {...provided.droppableProps} ref={provided.innerRef} className="text">
              {rows.map((row, rowIndex) => {
                prepareRow(row);
                return row?.original?.rowValue?.value?.type !== 'stepGroup' ? (
                  <Draggable
                    isDragDisabled={isDragDisabled}
                    key={'dragRow' + rowIndex}
                    draggableId={'dragRow' + rowIndex}
                    index={rowIndex}
                  >
                    {(provided) => (
                      <tr
                        {...row.getRowProps()}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className="configHover h-10 content-start myRow fontPoppinsRegularMd"
                      >
                        {row.cells.map((cell, columnIndex) => {
                          return columnIndex === 0 ? (
                            <td
                              {...cell.getCellProps()}
                              {...provided.dragHandleProps}
                              width={props.columns[columnIndex].width}
                              className="p-0 pl-30 text-left"
                            >
                              {cell.render('Cell')}
                            </td>
                          ) : (
                            <td
                              {...cell.getCellProps()}
                              width={props.columns[columnIndex].width}
                              className="p-0 pl-8 text-left"
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    )}
                  </Draggable>
                ) : (
                  <tr
                    {...row.getRowProps()}
                    ref={props.rowRef}
                    className="configHover h-10 content-start fontPoppinsRegularMd"
                  >
                    {row.cells.map((cell, columnIndex) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          width={props.columns[columnIndex].width}
                          className="p-0 pl-25 text-left"
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </Droppable>
      </table>
    </DragDropContext>
  );
};

export default TableWithDragAndDrop;

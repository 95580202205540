import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import { trimPath } from '../../../common/ui-constants';
import { downloadFileFromCloud } from '../../../api/api_services';
import { getTruncatedText } from '@src/util/common_utils';
function DetailsModal(props) {
  const [openModal, setOpenModal] = useState(true);
  let project = JSON.parse(localStorage.getItem('selected-project'));
  let { createdBy, createdOn, modifiedBy, modifiedOn } = props?.data;

  let path;
  if (props.type !== 'folder') {
    path = trimPath(props.data.actualPath);
  } else {
    path = trimPath(props.data.path);
  }

  const downloadFile = () => {
    downloadFileFromCloud(props.data.id).then((results) => {
      saveFileFromBlob(results.data, props.data.name);
    });
  };
  function saveFileFromBlob(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  }

  return (
    <div>
      <Modal
        open={openModal}
        onClose={() => {
          props.closeModal(false);
          setOpenModal(false);
        }}
        className="modal-dialog"
      >
        <div className={`modal-container ${props.type === 'folder' ? 'folder-details-modal' : 'file-details-modal'}`}>
          <div className="modal-header">
            <div className="title-trim-folderFile Popup-header-common" title={props.data.name}>
              <b>
                {props.type === 'folder' ? (
                  <span>
                    <div className="ml-2">Folder Details - {getTruncatedText(props.data.name, 30)}</div>
                  </span>
                ) : (
                  <span>
                    <span className="ml-2 ">File Details - {getTruncatedText(props.data.name, 30)}</span>
                  </span>
                )}
              </b>
            </div>
            <IconButton
              component="span"
              className="close"
              onClick={() => {
                props.closeModal(false);
                setOpenModal(false);
              }}
            >
              <img
                style={{
                  cursor: 'pointer',
                }}
                src="/assets/images/close_black.svg"
                alt=""
                height="25px"
                width="25px"
              />
            </IconButton>
          </div>
          <div className="modal-body" id="journal-scroll">
            {props.type === 'folder' ? (
              <div>
                <div className="">
                  <div className="ml-7  grid grid-cols-2 gap-4">
                    <div className="mt-2 col-span-1">
                      <h1 className="details-key-style-common">Name</h1>
                      <p title={props.data.name} className="text-gray-900 text-overflow-style">
                        <span className="details-data-style-common">{props.data.name}</span>
                      </p>
                    </div>
                    <div className="mt-2 col-span-1">
                      <h1 className="details-key-style-common">Description</h1>
                      <p title={props.data.desc} className="text-gray-900 text-overflow-style">
                        <span className="details-data-style-common">{props.data.desc ? props.data.desc : '--'}</span>
                      </p>
                    </div>
                  </div>
                  <div className="mt-1 grid grid-cols-2 gap-4">
                    <div className="">
                      <div className="grid-rows-4">
                        <div className="ml-7 mt-10">
                          <h1 className="details-key-style-common">Project Name</h1>
                          <p className="pt-2 text-gray-900">
                            <span className="details-data-style-common">{project.name}</span>
                          </p>
                        </div>
                        <div className="ml-7 mt-10">
                          <h1 className="details-key-style-common">Total Folders</h1>
                          <p className="pt-2 text-gray-900">
                            <span className="details-data-style-common">
                              {props.data.subFolderCount ? props.data.subFolderCount : '0'}
                            </span>
                          </p>
                        </div>
                        <div className="ml-7 mt-10">
                          <h1 className="details-key-style-common">Created By</h1>
                          <p className="pt-2 text-gray-900">
                            <span className="details-data-style-common">
                              {props.data.name === 'Root' ? createdBy || 'N/A' : props.data.createdByUname}
                            </span>
                          </p>
                        </div>
                        <div className="ml-7 mt-9">
                          <h1 className="details-key-style-common">Modified By</h1>
                          <p className="pt-2 text-gray-900">
                            <span className="details-data-style-common">
                              {props.data.name === 'Root' ? modifiedBy || 'N/A' : props.data.modifiedByUname}
                            </span>
                          </p>
                        </div>{' '}
                      </div>
                    </div>
                    <div className="">
                      <div className="grid-rows-4 -ml-3">
                        <div className="ml-7 mt-10">
                          <h1 className="details-key-style-common">Path</h1>
                          <p className="pt-2 text-gray-900">
                            <div className="details-data-style-common desc-modal" title={props.data.path}>
                              {path ? path : props.data.path ? props.data.path : '--'}
                            </div>
                          </p>
                        </div>
                        <div className="ml-7 mt-10">
                          <h1 className="details-key-style-common">Total Files</h1>
                          <p className="pt-2 text-gray-900">
                            <span className="details-data-style-common">{props.data.fileCount}</span>
                          </p>
                        </div>
                        <div className="ml-7 mt-10">
                          <h1 className="details-key-style-common">Created On</h1>
                          <p className="pt-2 text-gray-900">
                            <span className="details-data-style-common">
                              {props.data.name === 'Root' ? createdOn || 'N/A' : props.data.createdOn}
                            </span>
                          </p>
                        </div>
                        <div className="ml-7 mt-10">
                          <h1 className="details-key-style-common">Modified On</h1>
                          <p className="pt-2 text-gray-900">
                            <span className="details-data-style-common">
                              {props.data.name === 'Root' ? modifiedOn || 'N/A' : '--'}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="">
                  <div className="ml-7 grid grid-cols-2 gap-4">
                    <div className="mt-2 col-span-1">
                      <h1 className="details-key-style-common ">File Name</h1>
                      <p className="pt-2 text-gray-900">
                        <div className="details-data-style-common file-Name" title={props.data.name}>
                          {props.data.name}
                        </div>
                        <img
                          style={{
                            cursor: 'pointer',
                          }}
                          className="inline ml-40 -mt-12 text-lg"
                          onClick={downloadFile}
                          src="/assets/images/file_download.svg"
                          alt=""
                          height="20px"
                          width="20px"
                        />
                      </p>
                    </div>
                    <div className="mt-2 col-span-1">
                      <h1 className="details-key-style-common">Project Name</h1>
                      <p className="pt-2 text-gray-900">
                        <span className="details-data-style-common">{project.name}</span>
                      </p>
                    </div>
                  </div>
                  <div className="mt-2 grid grid-cols-2 gap-4">
                    <div className="">
                      <div className="grid-rows-3">
                        <div className="ml-7 mt-6 md:mt-7">
                          <h1 className="details-key-style-common">Path</h1>
                          <p className="pt-2 text-gray-900">
                            <div className="details-data-style-common desc-modal" title={path}>
                              {path}
                            </div>
                          </p>
                        </div>
                        <div className="ml-7 mt-6 md:mt-7">
                          <h1 className="details-key-style-common">Created on</h1>
                          <p className="pt-2 text-gray-900">
                            <span className="details-data-style-common">{props.data.createdOn}</span>
                          </p>
                        </div>
                        <div className="ml-7 mt-6 md:mt-7">
                          <h1 className="details-key-style-common">Modified On</h1>
                          <p className="pt-2 text-gray-900">
                            <span className="details-data-style-common">{props.data.modifiedOn}</span>
                          </p>
                        </div>{' '}
                      </div>
                    </div>
                    <div className="">
                      <div className="grid-rows-3 -ml-3">
                        <div className="ml-7 mt-6 md:mt-7 ">
                          <h1 className="details-key-style-common">Created by</h1>
                          <p className="pt-2 text-gray-900">
                            <span className="details-data-style-common">{props.data.createdByUname}</span>
                          </p>
                        </div>
                        <div className="ml-7 mt-6 md:mt-7">
                          <h1 className="details-key-style-common">Modified By</h1>
                          <p className="pt-2 text-gray-900">
                            <span className="details-data-style-common">{props.data.modifiedByUname}</span>
                          </p>
                        </div>
                        <div className="ml-7 mt-6 md:mt-7"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer mt-5">
            <div className="">
              <button
                onClick={() => {
                  setOpenModal(false);
                  props.closeModal(false);
                }}
                type="button"
                className="gray-btn"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default DetailsModal;

export const DEFECT_DESCRIPTION_TITLE = {
  key: 'stepsHeader',
  text: 'Steps to reproduce :',
  type: 'unstyled',
  depth: 0,
  inlineStyleRanges: [
    {
      offset: 0,
      length: 20,
      style: 'BOLD',
    },
  ],
  entityRanges: [],
  data: {},
};

export const getDefectDescription = (steps, envData, isFromStep) => {
  const project = localStorage.getItem('selected-project');
  let blocks = [{ ...DEFECT_DESCRIPTION_TITLE }];
  if (steps?.length) {
    steps?.forEach((step, index) => {
      if (isFromStep && step.displayName) {
        step.name = step.displayName;
      }
      blocks.push({
        key: 'stepsCnt' + index,
        text: step?.name ? step.name : step?.stepGroupName ? step?.stepGroupName : '',
        type: 'ordered-list-item',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      });
    });
  }
  blocks.push({
    key: 'EnvDetHeader',
    text: 'Environment Details',
    type: 'unstyled',
    depth: 0,
    inlineStyleRanges: [
      {
        offset: 0,
        length: 19,
        style: 'BOLD',
      },
    ],
    entityRanges: [],
    data: {},
  });
  let tempEnvArray = [];
  if (envData.section === 'steps' && envData?.systemData) {
    const systemInfo = envData.systemData;
    const stepsBrowser = (type) => {
      if (type === 'browser') {
        const browserName = systemInfo.browserInfo.name === 'android null' ? 'android' : systemInfo.browserInfo.name;
        const browserVersion = systemInfo?.browserInfo?.version || '';
        return `Browser: ${browserName} ${browserVersion}`;
      } else if (type === 'device' && systemInfo?.deviceInfo?.length > 0) {
        return `Device:${systemInfo?.deviceInfo[0]?.name} ${systemInfo?.deviceInfo[0]?.version}`;
      } else {
        return '';
      }
    };
    const envBrowserNameData = stepsBrowser('browser');
    const envDeviceNameData = stepsBrowser('device');
    if (envBrowserNameData) {
      tempEnvArray.push({
        key: 'envBroNam',
        text: envBrowserNameData,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      });
    }
    if (envDeviceNameData) {
      tempEnvArray.push({
        key: 'envDeviceNam',
        text: envDeviceNameData,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      });
    }
    tempEnvArray.push(
      {
        key: 'envOsNamVer',
        text: `Operating System: ${systemInfo?.machineInfo?.osName} ${systemInfo?.machineInfo?.osVersion}`,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: 'exeEnvr',
        text: `Execution environment: ${systemInfo.executionEnv}`,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: 'machineInfo',
        text: `Machine: ${systemInfo?.machineInfo?.hostName}`,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      }
    );
  }
  if (envData.section === 'resultTree') {
    const systemInfo = envData.systemData;
    const stepsBrowser = (type) => {
      if (JSON.parse(project).type === 'Web') {
        if (type === 'browser') {
          const browserName = systemInfo.browser === 'android null' ? 'android' : systemInfo.browser;
          const browserVersion = systemInfo?.browserInfo?.version || '';
          return `Browser: ${browserName} ${browserVersion}`;
        } else {
          return '';
        }
      } else if (JSON.parse(project).type === 'Mobile') {
        if (type === 'device') {
          return `Device:${systemInfo?.deviceName}`;
        } else {
          return '';
        }
      } else {
        if (type === 'browser') {
          const browserName = systemInfo.browser === 'android null' ? 'android' : systemInfo.browser;
          const browserVersion = systemInfo?.browserInfo?.version || '';
          return `Browser: ${browserName} ${browserVersion}`;
        } else if (type === 'device') {
          return `Device:${systemInfo?.deviceName}`;
        }
      }
    };
    const envBrowserNameData = stepsBrowser('browser');
    const envDeviceNameData = stepsBrowser('device');
    if (envBrowserNameData) {
      tempEnvArray.push({
        key: 'envBroNam',
        text: envBrowserNameData,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      });
    }
    if (envDeviceNameData) {
      tempEnvArray.push({
        key: 'envDeviceNam',
        text: envDeviceNameData,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      });
    }
    tempEnvArray.push(
      {
        key: 'envOsNamVer',
        text: `Operating System: ${systemInfo.os}`,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: 'exeEnvr',
        text: `Execution environment: ${systemInfo.executionEnv}`,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: 'machineInfo',
        text: `Machine: ${systemInfo?.machine}`,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      }
    );
  }
  if (envData.section === 'resultSteps') {
    const systemInfo = envData.systemData;
    const stepsBrowser = (type) => {
      if (type === 'browser') {
        const browserName = systemInfo.browserInfo.name === 'android null' ? 'android' : systemInfo.browserInfo.name;
        const browserVersion = systemInfo?.browserInfo?.version || '';
        return `Browser: ${browserName} ${browserVersion}`;
      } else if (type === 'device' && systemInfo?.deviceInfo?.length > 0) {
        return `Device:${systemInfo?.deviceInfo[0]?.name} ${systemInfo?.deviceInfo[0]?.version}`;
      } else {
        return '';
      }
    };
    const envBrowserNameData = stepsBrowser('browser');
    const envDeviceNameData = stepsBrowser('device');
    if (envBrowserNameData) {
      tempEnvArray.push({
        key: 'envBroNam',
        text: envBrowserNameData,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      });
    }
    if (envDeviceNameData) {
      tempEnvArray.push({
        key: 'envDeviceNam',
        text: envDeviceNameData,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      });
    }
    tempEnvArray.push(
      {
        key: 'envOsNamVer',
        text: `Operating System: ${systemInfo?.machineInfo?.osName} ${systemInfo?.machineInfo?.osVersion}`,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: 'exeEnvr',
        text: `Execution environment: ${systemInfo.executionEnv}`,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: 'machineInfo',
        text: `Machine: ${systemInfo?.machineInfo?.hostName}`,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      }
    );
  }
  blocks = [...blocks, ...tempEnvArray];
  return JSON.stringify(blocks);
};

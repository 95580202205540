import { useState, useEffect, useCallback, memo } from 'react';

import { allProjectDashboardMemory } from '@api/api_services';
import { getLicenseFeatures, isEmptyObject, isEmptyValue } from '@src/util/common_utils';
import { LICENSE_FEATURES } from '@common/ui-constants';

import MemoryNOProject from '../charts/MemoryNoProject';
import MemoryTotalConsumptionAllProject from '../charts/MemoryTotalConsumptionAllProject';
import ProjectTypeConsumption from '../charts/ProjectTypeConsumption';
import ChartCard from '../components/ChartCard';
import SectionHeader from '../components/SectionHeader';
import { _showNumberAfterFirstDecimal } from '../charts/CommonMethods';
import IndividualProjectDetail from '../charts/IndividualProjectDetail';
import MemoryConsumeStatistics from '../charts/MemoryConsumeStatistics';

function MemorySection() {
  const [memoryData, setMemoryData] = useState();
  const [activeProjectType, setActiveProjectType] = useState();

  const licenseFeatures = getLicenseFeatures();
  const hasAutomation = window.privilege.hasAutomation(licenseFeatures);
  const hasDefects = window.privilege.hasDefects(licenseFeatures);
  const hasManualTestCase = window.privilege.hasManualTestCase(licenseFeatures);
  const hasCBasic = window.privilege.hasCBasic(licenseFeatures);

  let purchasedProjectsType = licenseFeatures?.filter(
    (type) => ![LICENSE_FEATURES.MANUAL_TESTCASES, LICENSE_FEATURES.DEFECTS].includes(type)
  );

  const handleActiveProject = useCallback((value) => {
    setActiveProjectType(value);
  }, []);

  const renderIndividualProjectDetail = useCallback(
    (projectTabName) => {
      let selectedProject = memoryData?.memory?.filter((project) => project.type === projectTabName);

      if (isEmptyValue(selectedProject?.[0]?.projectDetails)) {
        return [];
      } else {
        return selectedProject?.[0]?.projectDetails?.map((project, i) => {
          return (
            <IndividualProjectDetail
              key={i}
              heading={project.name}
              occupied={_showNumberAfterFirstDecimal(project.usedMemory)}
              total={project.assignedMemory}
            />
          );
        });
      }
    },
    [memoryData]
  );

  let getTabBasedOnPurchasedProjectType = useCallback(
    (memoryResults) => {
      let purchaseMemo = memoryResults?.memory.filter((proType) => {
        if (
          purchasedProjectsType.includes(LICENSE_FEATURES.WEB) &&
          purchasedProjectsType.includes(LICENSE_FEATURES.MOBILE) &&
          proType.type === 'Web & Mobile'
        ) {
          // NOTE :: Add if purchase Web Mobile project type
          return proType;
        } else if (purchasedProjectsType.includes(LICENSE_FEATURES.WEB_SERVICE) && proType.type === 'Web Service') {
          // NOTE :: Add if purchase Web Serivce project type
          return proType;
        } else if (purchasedProjectsType.includes(LICENSE_FEATURES.MS_DYNAMICS) && proType.type === 'MS Dynamics') {
          // NOTE :: Add if purchase Web Serivce project type
          return proType;
        } else if (purchasedProjectsType.includes(proType.type)) {
          return proType; // NOTE :: Add if purchase project type
        } else {
          return null;
        }
      });

      return {
        ...memoryResults,
        memory: purchaseMemo,
      };
    },
    [purchasedProjectsType]
  );

  const setMemory = (memoryData) => {
    setMemoryData(memoryData);
    setActiveProjectType(memoryData?.memory[0]?.type);
  };

  const getMemoryData = useCallback(() => {
    allProjectDashboardMemory()
      .then((response) => {
        const data = response?.data?.responseObject;
        if (!isEmptyObject(data)) {
          if (hasAutomation && (hasDefects || hasManualTestCase)) {
            setMemory(data);
          } else if (hasAutomation) {
            if (hasCBasic) {
              // if C-Basic Show All
              setMemory(data);
            } else {
              let onlyPurchasedType = getTabBasedOnPurchasedProjectType(data); // NOTE ::Filter only Aumation specific project type
              setMemory(onlyPurchasedType);
            }
          } else {
            setMemory(data);
          }
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, [hasAutomation, hasCBasic, hasDefects, hasManualTestCase, getTabBasedOnPurchasedProjectType]);

  useEffect(() => {
    getMemoryData();
  }, []);

  return (
    <div className="my-3">
      <SectionHeader className="select-none">Memory</SectionHeader>
      <div className="grid grid-cols-12 gap-3 my-2">
        <MemoryTotalConsumptionAllProject totalConsumption={memoryData?.totalConsumption} />
        <ChartCard className="col-span-7" style={{ height: '310px' }}>
          <MemoryConsumeStatistics />
        </ChartCard>
      </div>
      <div className={`grid grid-cols-6 gap-3 my-2 mt-5`}>
        {!isEmptyValue(memoryData?.memory) &&
          memoryData?.memory?.map((projectType, projectTypeIndex) => (
            <ProjectTypeConsumption
              key={`project-type-memory-consumption-${projectTypeIndex}`}
              type={projectType.type}
              totalProject={projectType.noOfProjects}
              assignedMemory={projectType.assignedMemory}
              usedMemory={projectType.usedMemory}
              handleActiveProject={handleActiveProject}
              activeProjectType={activeProjectType}
            />
          ))}
      </div>
      <div className="grid grid-cols-6 gap-3">
        {!isEmptyValue(activeProjectType) ? (
          renderIndividualProjectDetail(activeProjectType)?.length === 0 ? (
            <MemoryNOProject projectTypeName={activeProjectType} />
          ) : (
            renderIndividualProjectDetail(activeProjectType)
          )
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export default memo(MemorySection);

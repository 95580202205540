import DashboardStyle from '../styles/dashboard.module.scss';

function ProgressLine({ total = 100, filled = 0, filledColor = '#147AD6' }) {
  if (total === 0) {
    total = 100;
  }
  let widthToFill = (100 * Number(filled)) / Number(total);

  return (
    <div className={DashboardStyle['progress-line-wrapper']}>
      <div
        className={DashboardStyle['filled']}
        style={{
          height: '8px',
          width: `${widthToFill > 100 ? 100 : widthToFill}%`,
          backgroundColor: filledColor,
        }}
      />
    </div>
  );
}

export default ProgressLine;

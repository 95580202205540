import React from 'react';
import { withRouter } from 'react-router';
import DefectRouter from './defect-router';

function DefectLandingPage() {
  return (
    <div className="page-container">
      <DefectRouter />
    </div>
  );
}

export default withRouter(DefectLandingPage);

import React, { useState } from 'react';
import Modal from 'react-modal';
import { Close } from '@mui/icons-material';
import GitlabStyles from '../gitlab-styles.module.scss';
import cx from 'classnames';

const customStylesFolder = {
  content: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'fit-content',
    width: '600px',
    boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
    background: '#fbfcfd 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    opacity: '1',
  },
};

const GitlabInstanceDetails = ({ data, closeModal }) => {
  const [openModal, setOpenModal] = useState(true);
  const handleClose = () => {
    setOpenModal(false);
    closeModal(false);
  };
  return (
    <Modal isOpen={openModal} onRequestClose={handleClose} style={customStylesFolder} className="p-px">
      <div className="mt-2 p-1">
        <p title={data?.name} className="Popup-header-common inline-block w-10/12 pl-3 text-overflow-style">
          Instance Details -<span className="ml-2">{data?.name}</span>
        </p>
        <div className="float-right">
          <button
            onClick={() => {
              setOpenModal(false);
              closeModal(false);
            }}
            type="button"
            className="ml-5 mr-5 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <Close color="action" />
          </button>
        </div>
      </div>
      <div className="border-b border-t border-blue-100 mt-px mb-px pb-3">
        <div className="ml-6 mr-6 mt-3 grid grid-cols-2 gap-x-4 gap-y-6">
          <div className="">
            <h1 className="details-key-style-common">Instance Name</h1>
            <p title={data?.name} className="text-gray-900 text-overflow-style">
              <span className="details-data-style-common">{data?.name}</span>
            </p>
          </div>
          <div className="">
            <h1 className="details-key-style-common">URL</h1>
            <p title={data?.url} className="text-gray-900 text-overflow-style">
              <span className="details-data-style-common">{data?.url}</span>
            </p>
          </div>
          <div className="">
            <h1 className="details-key-style-common">Personal Token</h1>
            <p className={cx('text-gray-900 text-overflow-style', GitlabStyles['hide-characters'])}>
              <span className="details-data-style-common">{data?.personalToken}</span>
            </p>
          </div>
          <div className="">
            <h1 className="details-key-style-common">Created By </h1>
            <p className="text-gray-900">
              <span className="details-data-style-common">{data?.createdByUname}</span>
            </p>
          </div>
          <div className="">
            <h1 className="details-key-style-common">Created On</h1>
            <p className="text-gray-900">
              <span className="details-data-style-common">{data?.createdOn}</span>
            </p>
          </div>
          <div className="">
            <h1 className="details-key-style-common">Modified By </h1>
            <p className="text-gray-900">
              <span className="details-data-style-common">{data?.modifiedByUname}</span>
            </p>
          </div>
          <div className="">
            <h1 className="details-key-style-common">Modified On</h1>
            <p className="text-gray-900">
              <span className="details-data-style-common">{data?.modifiedOn}</span>
            </p>
          </div>
        </div>
      </div>
      <div className="">
        <button
          onClick={() => {
            closeModal(false);
            setOpenModal(false);
          }}
          type="button"
          className="gray-btn float-right mt-3.5 mb-3 mr-5"
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default GitlabInstanceDetails;

import '../../css_config/variable_modal.scss';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getTruncatedText } from '@src/util/common_utils';
import { ASSERT_CONSTANT } from '@src/pages/test-development/script/scripts/webservice/restapi/request/assert/jsx/constant';
import { WEBSERVICE } from '../test-development/script/scripts/webservice/restapi/constants/constants';
import { UI_VALIDATIONS } from '../../util/validations';
const useStyles = makeStyles((theme) => ({
  tooltipAlignment: {
    marginTop: 0,
    overflow: 'hidden',
  },
}));
const VariableSelectorMyInput = (props) => {
  const { CAPTURE_DATA_LENGTH, ARRAY_LENGTH_ZERO } = UI_VALIDATIONS;
  const {
    filteredLocalVariable = [],
    filteredForLoopVariable = [],
    filteredStepGroupParam = [],
    filteredStepGroupVariable = [],
    filteredDataProviderVariable = [],
    filteredProjectEnvironmentalVariable = [],
    filteredGlobalVariable = [],
  } = props;
  
  const disableStepGroupParam  = 'apiKeyValue' in props ? 'apiKeyValue' : 'name'

  const {
    LOCAL_VAR_LABEL,
    GLOBAL_VAR_LABEL,
    PROJECT_ENV_VAR_LABEL,
    DATA_PROVIDER_VAR_LABEL,
    STEP_GROUP_VAR_LABEL,
    STEP_GROUP_PARAM_VAR_LABEL,
    FOR_LOOP_VAR_LABEL,
    API_KEY_INPUT_NAME,
    DISABLE_STEP_PARAM_API_VALUE,
    DISABLE_STEP_PARAM_API_KEY
  } = WEBSERVICE;
  let isWebserviceVariable = '';
  let isFilterText = '';
  const search = window.location.search;
  const libraryId = new URLSearchParams(search).get('parentId');
  const classes = useStyles();
  const {
    MODULE,
    ROOT,
    NO_VARIABLES_CREATED_TEXT,
    EXPECTED_VALUE,
    EXPECTED_HEADER_VALUE,
    EXPECTED_JSON_VALUE,
    LHS,
    RHS,
    TOKEN_PREFIX,
    SECRET_KEY_NAME,
    JWT_PAYLOAD,
    REQUEST_URL
  } = ASSERT_CONSTANT;

  const renderFilteredByText = (filteredData, filterText) => {
    let filteredVariableDataRes = filteredData.map((variable, i) => {
      const name = variable?.name || variable?.varName || `${variable?.dpName}:${variable?.varname}`;
      isWebserviceVariable = name?.startsWith('WSWB_');
      if (filterText) {
        isFilterText = name?.toLowerCase().includes(filterText.toLowerCase());
      }
      if (!isWebserviceVariable && isFilterText) {
        return (
          <Tooltip
            title={name?.length > CAPTURE_DATA_LENGTH ? name : ''}
            key={i}
            placement="bottom"
            classes={{ tooltip: classes.tooltipAlignment }}
          >
            <div
              className="pl-3 webservice-variable-dropdown cursor-pointer hover:bg-rs-primary hover:text-white"
              key={i}
              onClick={() => props.onClickGetValue(name, variable)}
            >
              <p className="truncate"> {name}</p>
            </div>
          </Tooltip>
        );
      }
    });

    const filteredAllVariable = [
      ...filteredLocalVariable,
      ...filteredGlobalVariable,
      ...filteredProjectEnvironmentalVariable,
      ...filteredDataProviderVariable,
      ...filteredStepGroupVariable,
      ...filteredStepGroupParam,
      ...filteredForLoopVariable,
    ];
    const isFilteredAllVariableExits = filteredAllVariable.filter((item) => {
      const name = item?.name || `${item?.dpName}:${item?.varname}`;
      return name.toLowerCase().includes(filterText.toLowerCase());
    });
    if (isFilteredAllVariableExits.length === ARRAY_LENGTH_ZERO) {
      if (props?.type === 'Editor') {
        props.handleEditorValue(false);
      } else {
        return props.setShowDropDown(false);
      }
    }

    if (filteredVariableDataRes.length > ARRAY_LENGTH_ZERO && filteredVariableDataRes.some((item) => typeof item === 'object')) {
      return filteredVariableDataRes;
    } else {
      return (
        <div className="ml-3 fontPoppinsRegularSm pointer-events-none common-color-variable-selector">
          {NO_VARIABLES_CREATED_TEXT}
        </div>
      );
    }
  };

  const renderFilteredView = (filteredData) => {
    return filteredData.map((variable, i) => {
      isWebserviceVariable = variable.name?.startsWith('WSWB_');
      if (!isWebserviceVariable) {
        const name = variable?.name || variable?.varName || `${variable?.dpName}:${variable?.varname}`;
        return (
          <Tooltip
            title={
              variable.varName
                ? variable.varName
                : (variable.name ?? `${variable?.dpName}:${variable?.varname}`)?.length > CAPTURE_DATA_LENGTH
                ? variable?.name ?? `${variable?.dpName}:${variable?.varname}`
                : ''
            }
            key={i}
            placement="bottom"
            classes={{ tooltip: classes.tooltipAlignment }}
          >
            <div
              className="pl-3 webservice-variable-dropdown cursor-pointer hover:bg-rs-primary hover:text-white"
              key={i}
              onClick={() => props.onClickGetValue(name, variable)}
            >
              <p className="truncate">{name}</p>
            </div>
          </Tooltip>
        );
      }
    });
  };

  let filterText = '';
  if (props?.type === 'Editor') {
    const editorInstance = props?.editorRef?.current?.editor;
    const session = editorInstance?.getSession();
    let rowText = session?.getDocument()?.getLine(props?.currentValue?.start?.row);

    let editorFilterTextLen = props?.currentValue?.start?.column;

    if (props?.currentValue?.lines[0] === '$') {
      localStorage.setItem('dollarPosition', props?.currentValue?.end?.column);
    }

    if (editorFilterTextLen && editorFilterTextLen - 1 > -1) {
      const currentDollarPosition = localStorage.getItem('dollarPosition');
      if (rowText.length - 1 === props?.currentValue?.end?.column - 1) {
        filterText = rowText.substring(currentDollarPosition);
      }
      if (!props.value.includes('$')) {
        props.handleEditorValue(false);
      }
    }
  } else {
    let inputFilterTextLen = props?.currentValue?.target?.selectionStart;
    let index = inputFilterTextLen - 1;
    if (props?.currentValue?.nativeEvent?.data === '$') {
      localStorage.setItem('dollarPosition', inputFilterTextLen);
    }
    if (inputFilterTextLen && index > -1) {
      const currentDollarPosition = localStorage.getItem('dollarPosition');
      if (props.value.length - 1 === index) {
        filterText = props.value.substring(currentDollarPosition);
      }
      if (!props.value.includes('$') || !props.value.substring(currentDollarPosition - 1).includes('$')) {
        props.setShowDropDown(false);
      }
    }
  }
  const authorizationVariable =
    [
      'redirectURL',
      'codeVerifier',
      'consumerKey',
      'consumerSecret',
      'tokenSecret',
      'callbackURL',
      'verifier',
      'version',
      'realm',
    ].includes(props?.name) ||
    (['timeStamp', 'nonce'].includes(props?.name) &&
      props?.currentValue?.target?.className?.includes('input-style-for-auth1')) ||
    (['token'].includes(props?.name) && props?.placeholder !== 'Enter token');
  const getDropDownWidth = () => {
    return props.editorCheck
      ? 'variable-dropdown-editor'
      : authorizationVariable
      ? 'variable-dropdown-small'
      : ['accessTokenURl', 'authorizationURL', 'clientID', 'clientSecret', 'scope', 'state'].includes(props?.name) ||
        (['userName'].includes(props?.name) &&
          props?.currentValue?.target?.className?.includes('my-input-style-for-authorization-auth2'))
      ? 'variable-dropdown-medium'
      : [EXPECTED_VALUE, EXPECTED_HEADER_VALUE].includes(props?.placeholder) && [RHS].includes(props?.name)
      ? 'variable-dropdown-extra-small'
      : [EXPECTED_JSON_VALUE].includes(props?.placeholder) && [LHS].includes(props?.name)
      ? 'assert-lhs-variable-dropdown'
      : [RHS].includes(props?.name) && ![EXPECTED_VALUE, EXPECTED_HEADER_VALUE].includes(props?.placeholder)
      ? 'assert-variable-dropdown'
      : [LHS].includes(props?.name) && ![EXPECTED_VALUE, EXPECTED_HEADER_VALUE].includes(props?.placeholder)
      ? 'cdfr-lhs-variable-dropdown'
      : props?.name?.includes('value') && props?.placeholder?.includes('Enter form data value')
      ? 'variable-dropdown-form-data'
      : props?.name?.includes('value') && props?.placeholder?.includes('Enter URL encoded value')
      ? 'variable-dropdown-url-encoded'
      : props?.name?.includes('token') && props?.value?.length > 1 && props?.placeholder?.includes('Enter token')
      ? 'variable-dropdown-bearer-token'
      : [SECRET_KEY_NAME, TOKEN_PREFIX].includes(props?.name)
      ? 'variable-dropdown-jwt-bearer'
      : [API_KEY_INPUT_NAME, REQUEST_URL].includes(props?.name)
      ? 'variable-dropdown-api-key'
      : [DISABLE_STEP_PARAM_API_VALUE].includes(props?.apiKeyValue)
      ? 'variable-dropdown-api-key'
      : 'variable-dropdown';
  };
  const VariableTooltip = ({ label, placeholder, name, truncateLength }) => {
    const isExpectedPlaceholder = [EXPECTED_VALUE, EXPECTED_HEADER_VALUE, EXPECTED_JSON_VALUE].includes(placeholder);
    const isRhsName = ['rhs'].includes(name);
    const tooltipTitle = isExpectedPlaceholder && isRhsName ? label : '';
    const displayLabel = isExpectedPlaceholder ? getTruncatedText(label, truncateLength) : label;

    return (
      <Tooltip title={tooltipTitle}>
        <span className="ml-3 webservice-variable-dropdown-container">{displayLabel}</span>
      </Tooltip>
    );
  };
  return (
    <>
      {props.ShowDropDown && filterText.length > ARRAY_LENGTH_ZERO ? (
        <div
          className={`border shadow rounded text-sm  ${getDropDownWidth()}`}
          onClick={props.onBlurValue}
          onMouseOver={(e) => e.stopPropagation()}
          style={props?.positionStyle}
        >
          <div className='variable-inner-container'>
          {libraryId ? (
            <>
              {![SECRET_KEY_NAME, TOKEN_PREFIX, JWT_PAYLOAD, DISABLE_STEP_PARAM_API_KEY, DISABLE_STEP_PARAM_API_VALUE].includes(
                props[disableStepGroupParam ?? '']
              ) && (
                <div className="mt-2 ">
                  <Tooltip
                    title={
                      [EXPECTED_VALUE, EXPECTED_HEADER_VALUE].includes(props?.placeholder) &&
                      [RHS].includes(props?.name)
                        ? STEP_GROUP_PARAM_VAR_LABEL
                        : ''
                    }
                  >
                    <span className="ml-3 webservice-variable-dropdown-container">
                      {[EXPECTED_VALUE, EXPECTED_HEADER_VALUE].includes(props?.placeholder) && [RHS].includes(props?.name)
                        ? getTruncatedText(STEP_GROUP_PARAM_VAR_LABEL, 15)
                        : STEP_GROUP_PARAM_VAR_LABEL}
                    </span>
                  </Tooltip>
                  {filteredStepGroupParam?.length > ARRAY_LENGTH_ZERO ? (
                    renderFilteredByText(filteredStepGroupParam, filterText)
                  ) : (
                    <div className="ml-3 fontPoppinsRegularSm pointer-events-none common-color-variable-selector">
                      {NO_VARIABLES_CREATED_TEXT}
                    </div>
                  )}
                </div>
              )}
              <div className="mt-2 ">
                <Tooltip
                  title={
                    [EXPECTED_VALUE, EXPECTED_HEADER_VALUE].includes(props?.placeholder) && [RHS].includes(props?.name)
                      ? STEP_GROUP_VAR_LABEL
                      : ''
                  }
                >
                  <span className="ml-3 webservice-variable-dropdown-container">
                    {[EXPECTED_VALUE, EXPECTED_HEADER_VALUE].includes(props?.placeholder) && [RHS].includes(props?.name)
                      ? getTruncatedText(STEP_GROUP_VAR_LABEL, 15)
                      : STEP_GROUP_VAR_LABEL}
                  </span>
                </Tooltip>
                {filteredStepGroupVariable?.length > ARRAY_LENGTH_ZERO ? (
                  renderFilteredByText(filteredStepGroupVariable, filterText)
                ) : (
                  <div className="ml-3 fontPoppinsRegularSm pointer-events-none common-color-variable-selector">
                    {NO_VARIABLES_CREATED_TEXT}
                  </div>
                )}
              </div>
            </>
          ) : ![MODULE, ROOT].includes(props?.label) ? (
            <div className="mt-2 ">
              <span className="webservice-variable-dropdown-container ml-3">
                {LOCAL_VAR_LABEL}
              </span>
              {filteredLocalVariable?.length > ARRAY_LENGTH_ZERO ? (
                renderFilteredByText(filteredLocalVariable, filterText)
              ) : (
                <div className="ml-3 fontPoppinsRegularSm pointer-events-none common-color-variable-selector">
                  {NO_VARIABLES_CREATED_TEXT}
                </div>
              )}
            </div>
          ) : null}
          <div className="mt-2 ">
            <span className="ml-3 webservice-variable-dropdown-container">{GLOBAL_VAR_LABEL}</span>
            {filteredGlobalVariable?.length > 0 ? (
              renderFilteredByText(props.filteredGlobalVariable, filterText)
            ) : (
              <div className="ml-3 fontPoppinsRegularSm pointer-events-none common-color-variable-selector">
                {NO_VARIABLES_CREATED_TEXT}
              </div>
            )}
          </div>
          {props?.inputFieldName !== 'accessToken' && (
            <div className="mt-2 ml-3">
              <span className="webservice-variable-dropdown-container">
                {([EXPECTED_VALUE, EXPECTED_HEADER_VALUE].includes(props?.placeholder) &&
                  [RHS].includes(props?.name)) ||
                ([RHS, LHS].includes(props?.name) &&
                  ![EXPECTED_VALUE, EXPECTED_HEADER_VALUE].includes(props?.placeholder)) ||
                (['timeStamp', 'nonce'].includes(props?.name) &&
                  props?.currentValue?.target?.className?.includes('input-style-for-auth1')) ||
                authorizationVariable
                  ? getTruncatedText(PROJECT_ENV_VAR_LABEL, 17)
                  : PROJECT_ENV_VAR_LABEL}
              </span>
              {filteredProjectEnvironmentalVariable?.length > ARRAY_LENGTH_ZERO ? (
                renderFilteredByText(filteredProjectEnvironmentalVariable, filterText)
              ) : (
                <div className="ml-3 fontPoppinsRegularSm pointer-events-none common-color-variable-selector">
                  {NO_VARIABLES_CREATED_TEXT}
                </div>
              )}
            </div>
          )}
          {filteredDataProviderVariable?.length > ARRAY_LENGTH_ZERO && (
            <div className="mt-2">
              <Tooltip
                title={
                  [EXPECTED_VALUE, EXPECTED_HEADER_VALUE].includes(props?.placeholder) && [RHS].includes(props?.name)
                    ? DATA_PROVIDER_VAR_LABEL
                    : ''
                }
              >
                <span className="webservice-variable-dropdown-container">
                  {[EXPECTED_VALUE, EXPECTED_HEADER_VALUE].includes(props?.placeholder) && [RHS].includes(props?.name)
                    ? getTruncatedText(DATA_PROVIDER_VAR_LABEL, 15)
                    : DATA_PROVIDER_VAR_LABEL}
                </span>
              </Tooltip>
              {renderFilteredByText(filteredDataProviderVariable, filterText)}
            </div>
          )}
          {filteredForLoopVariable?.length > ARRAY_LENGTH_ZERO && (
            <div className="mt-2">
              <VariableTooltip
                label={FOR_LOOP_VAR_LABEL}
                placeholder={props?.placeholder}
                name={props?.name}
                truncateLength={15}
              />
              {renderFilteredByText(filteredForLoopVariable, filterText)}
            </div>
          )}
          </div>
        </div>
      ) : (
        <div
          className={`border shadow rounded text-sm variable-container-bg-color ${getDropDownWidth()}`}
          onClick={props.onBlurValue}
          onMouseOver={(e) => e.stopPropagation()}
          style={props?.positionStyle}
        >
          <div className='variable-inner-container'>
          {libraryId ? (
            <>
              {![SECRET_KEY_NAME, TOKEN_PREFIX, JWT_PAYLOAD, DISABLE_STEP_PARAM_API_KEY, DISABLE_STEP_PARAM_API_VALUE].includes(
                props[disableStepGroupParam ?? '']
              ) && (
                <div className="mt-2 ">
                  <Tooltip
                    title={
                      [EXPECTED_VALUE, EXPECTED_HEADER_VALUE, EXPECTED_JSON_VALUE]?.includes(props?.placeholder) &&
                      [RHS, LHS]
                        ?.includes(props?.name)
                        [(EXPECTED_VALUE, EXPECTED_HEADER_VALUE, EXPECTED_JSON_VALUE)]?.includes(props?.placeholder) &&
                      [RHS, LHS]?.includes(props?.name)
                        ? STEP_GROUP_PARAM_VAR_LABEL
                        : ''
                    }
                    classes={{ tooltip: classes.tooltipAlignment }}
                  >
                    <span className="ml-3 webservice-variable-dropdown-container">
                      {[EXPECTED_VALUE, EXPECTED_HEADER_VALUE].includes(props?.placeholder) && [RHS].includes(props?.name)
                        ? getTruncatedText(STEP_GROUP_PARAM_VAR_LABEL, 15)
                        : [EXPECTED_JSON_VALUE].includes(props?.placeholder) && [LHS].includes(props?.name)
                        ? getTruncatedText(STEP_GROUP_PARAM_VAR_LABEL, 15)
                        : STEP_GROUP_PARAM_VAR_LABEL}
                    </span>
                  </Tooltip>
                  {filteredStepGroupParam?.length > ARRAY_LENGTH_ZERO ? (
                    renderFilteredView(filteredStepGroupParam)
                  ) : (
                    <div className="ml-3 fontPoppinsRegularSm pointer-events-none common-color-variable-selector">
                      {NO_VARIABLES_CREATED_TEXT}
                    </div>
                  )}
                </div>
              )}
              <div className="mt-2 ">
                <Tooltip
                  title={
                    [EXPECTED_VALUE, EXPECTED_HEADER_VALUE, EXPECTED_JSON_VALUE].includes(props?.placeholder) &&
                    [RHS, LHS].includes(props?.name)
                      ? STEP_GROUP_VAR_LABEL
                      : ''
                  }
                  classes={{ tooltip: classes.tooltipAlignment }}
                >
                  <span className="ml-3 webservice-variable-dropdown-container">
                    {[EXPECTED_VALUE, EXPECTED_HEADER_VALUE].includes(props?.placeholder) && [RHS].includes(props?.name)
                      ? getTruncatedText(STEP_GROUP_VAR_LABEL, 15)
                      : STEP_GROUP_VAR_LABEL}
                  </span>
                </Tooltip>
                {filteredStepGroupVariable?.length > ARRAY_LENGTH_ZERO ? (
                  renderFilteredView(filteredStepGroupVariable)
                ) : (
                  <div className="ml-3 fontPoppinsRegularSm pointer-events-none common-color-variable-selector">
                    {NO_VARIABLES_CREATED_TEXT}
                  </div>
                )}
              </div>
            </>
          ) : ![MODULE, ROOT].includes(props?.label) ? (
            <div className="mt-2">
              <Tooltip
                title={
                  [EXPECTED_VALUE, EXPECTED_HEADER_VALUE, EXPECTED_JSON_VALUE].includes(props?.placeholder) &&
                  [RHS, LHS].includes(props?.name)
                    ? LOCAL_VAR_LABEL
                    : ''
                }
                classes={{ tooltip: classes.tooltipAlignment }}
              >
                <span className="ml-3 webservice-variable-dropdown-container">
                  {LOCAL_VAR_LABEL}
                </span>
              </Tooltip>
              {filteredLocalVariable?.length > ARRAY_LENGTH_ZERO ? (
                renderFilteredView(filteredLocalVariable)
              ) : (
                <div className="ml-3 fontPoppinsRegularSm pointer-events-none common-color-variable-selector">
                  {NO_VARIABLES_CREATED_TEXT}
                </div>
              )}
            </div>
          ) : null}
          <div className="mt-2 ">
            <Tooltip
              title={
                [EXPECTED_VALUE, EXPECTED_HEADER_VALUE, EXPECTED_JSON_VALUE].includes(props?.placeholder) &&
                [RHS, LHS].includes(props?.name)
                  ? GLOBAL_VAR_LABEL
                  : ''
              }
              classes={{ tooltip: classes.tooltipAlignment }}
            >
              <span className="ml-3 webservice-variable-dropdown-container">
                {GLOBAL_VAR_LABEL}
              </span>
            </Tooltip>
            {filteredGlobalVariable?.length > ARRAY_LENGTH_ZERO ? (
              renderFilteredView(filteredGlobalVariable)
            ) : (
              <div className="ml-3 fontPoppinsRegularSm pointer-events-none common-color-variable-selector">
                {NO_VARIABLES_CREATED_TEXT}
              </div>
            )}
          </div>
          <div className="mt-2 ">
            <Tooltip
              title={props?.name !== 'requestUrl' ? PROJECT_ENV_VAR_LABEL : ''}
              classes={{ tooltip: classes.tooltipAlignment }}
            >
              <span className="ml-3 webservice-variable-dropdown-container flex">
                {[
                  'redirectURL',
                  'authorizationURL',
                  'codeVerifier',
                  'clientID',
                  'clientSecret',
                  'scope',
                  'state',
                  'consumerKey',
                  'consumerSecret',
                  'tokenSecret',
                  'callbackURL',
                  'verifier',
                  'version',
                  'realm',
                  'accessTokenURl',
                  LHS,
                ].includes(props?.name) ||
                (['timeStamp', 'nonce'].includes(props?.name) &&
                  props?.currentValue?.target?.className?.includes('input-style-for-auth1')) ||
                (['userName'].includes(props?.name) &&
                  props?.currentValue?.target?.className?.includes('my-input-style-for-authorization-auth2')) ||
                (![EXPECTED_VALUE, EXPECTED_HEADER_VALUE].includes(props?.placeholder) && [RHS].includes(props?.name))
                  ? getTruncatedText(PROJECT_ENV_VAR_LABEL, 17)
                  : [EXPECTED_VALUE, EXPECTED_HEADER_VALUE].includes(props?.placeholder) && [RHS].includes(props?.name)
                  ? getTruncatedText(PROJECT_ENV_VAR_LABEL, 17)
                  : PROJECT_ENV_VAR_LABEL}
              </span>
            </Tooltip>
            {filteredProjectEnvironmentalVariable?.length > ARRAY_LENGTH_ZERO ? (
              renderFilteredView(filteredProjectEnvironmentalVariable)
            ) : (
              <div className="ml-3 fontPoppinsRegularSm pointer-events-none common-color-variable-selector">
                {NO_VARIABLES_CREATED_TEXT}
              </div>
            )}
          </div>
          {filteredDataProviderVariable?.length > ARRAY_LENGTH_ZERO && (
            <div className="mt-2">
              <Tooltip
                title={
                  [EXPECTED_VALUE, EXPECTED_HEADER_VALUE, EXPECTED_JSON_VALUE].includes(props?.placeholder) &&
                  [RHS, LHS].includes(props?.name)
                    ? DATA_PROVIDER_VAR_LABEL
                    : ''
                }
                classes={{ tooltip: classes.tooltipAlignment }}
              >
                <span className="ml-3 webservice-variable-dropdown-container">
                  {[EXPECTED_VALUE, EXPECTED_HEADER_VALUE, EXPECTED_JSON_VALUE].includes(props?.placeholder)
                    ? getTruncatedText(DATA_PROVIDER_VAR_LABEL, 17)
                    : DATA_PROVIDER_VAR_LABEL}
                </span>
              </Tooltip>
              {renderFilteredView(filteredDataProviderVariable)}
            </div>
          )}
          {filteredForLoopVariable?.length > ARRAY_LENGTH_ZERO && (
            <div className="mt-2">
              <VariableTooltip
                label={FOR_LOOP_VAR_LABEL}
                placeholder={props?.placeholder}
                name={props?.name}
                truncateLength={17}
              />
              {renderFilteredView(filteredForLoopVariable)}
            </div>
          )}
        </div>
        </div>
      )}
    </>
  );
};

export default VariableSelectorMyInput;

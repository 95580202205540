import React, { useEffect, useState, useRef } from 'react';
import { Autocomplete } from '@mui/material';
import { InputLabel, MenuItem, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import CheckIcon from '@mui/icons-material/Check';
import { getAllLabelsByProjectId, createLabel } from '@src/api/api_services';
import { getCurrentProjectId } from '@src/util/common_utils';
import Box from '@mui/material/Box';
import AddLabelButton from '@src/assets/AddLabelButton.svg';
import Tooltip from '@mui/material/Tooltip';
import '@pages/test-development/test-development.scss';
import { styled } from '@mui/system';
import '@pages/configuration/config-label.scss';
import { getTruncatedText } from '@src/util/common_utils';
import { Icon } from 'fireflink-ui';
import { BULK_LABELING } from '@src/common/ui-constants';

const MultiAutocomplete = (props) => {
  let { labelSelectedOptions, labelResponse, selectedOptionsValue, resetLabels, setResetLabels } = props;
  const [responseObject, setResponseObject] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(selectedOptionsValue ? selectedOptionsValue : []);
  const [validation, setValidation] = useState(true);
  const projectId = getCurrentProjectId();
  const labelValue = useRef();
  const getAllLabels = () => {
    getAllLabelsByProjectId(projectId).then((responsedata) => {
      setResponseObject(responsedata?.data?.responseObject || []);
    });
  };
  const [errorMsg, setErrorMsg] = useState([]);
  const [showLabel, setshowLabel] = useState([]);
  const history = useHistory();
  const [hideAddLabelBtn, setHideAddLabelBtn] = useState(false);

  const getLabelValue = (e) => {
    labelValue.current = e?.target?.value;
    checkValidation(labelValue.current);
    setHideAddLabelBtn(false);
  };

  const getValue = (e) => {
    setHideAddLabelBtn(true);
  };

  const checkValidation = (currentValue) => {
    let regexValue = /^[a-zA-Z0-9-_() ]*$/;
    if (currentValue?.length > 25) {
      setErrorMsg('Maximum 25 characters');
      setValidation(true);
    } else if (currentValue?.length < 3) {
      setErrorMsg('Minimum 3 characters');
      setValidation(true);
    } else if (currentValue?.length === 0 || currentValue === '') {
      setErrorMsg('Label name is required');
      setValidation(true);
    } else if (currentValue?.trim()?.length !== currentValue?.length) {
      setErrorMsg('Space is not allowed at starting and at the end');
      setValidation(true);
    } else if (!regexValue.test(currentValue)) {
      setErrorMsg('Name should be alphanumeric');
      setValidation(true);
    } else if (selectedOptions.includes(currentValue)) {
      setValidation(true);
    } else {
      setValidation(false);
      setshowLabel(`${currentValue} ${props.action !== 'Find and Remove Labels' ? ' add label' : 'No result'}`);
    }
  };

  const handleChange = (event, value) => {
    let selectedOptionValue = [];
    value?.map((label) => {
      if (typeof label === 'object') {
        return selectedOptionValue.push(label?.name);
      } else {
        return selectedOptionValue.push(label);
      }
    });
    setSelectedOptions(selectedOptionValue);
  };

  const createLabels = (e) => {
    e.preventDefault();
    let labelData = {
      name: (labelValue?.current).replace(/  +/g, ' '),
      description: '',
    };
    createLabel(labelData, projectId)
      .then((response) => {
        if (response?.data.responseCode === 201 && response?.data?.status === 'SUCCESS') {
          setSelectedOptions(selectedOptions.concat(labelData.name));
          getAllLabels();
          props.MyAlert.success(`${getTruncatedText(labelData?.name, 10)} label created successfully`);
        }
      })
      .catch((error) => {
        console.error('Create api Failed!:', error);
      });
    labelValue.current = '';
  };

  const manageLabelsHandler = (path) => {
    if (props?.cleanUpFunction) {
      props.cleanUpFunction();
    }
    if (props?.history) {
      props.history?.push(path);
    } else {
      history?.push(path);
    }
  };

  useEffect(() => {
    getAllLabels();
  }, []);

  useEffect(() => {
    labelSelectedOptions(selectedOptions);
    labelResponse(responseObject);
  }, [selectedOptions, responseObject]);

  useEffect(() => {
    if (resetLabels) {
      setSelectedOptions([]);
      setResetLabels(false);
    }
  }, [resetLabels, setResetLabels]);

  const GroupHeader = styled('div')(() => ({
    top: '-8px',
    padding: '4px 10px',
    backgroundColor: '#1648c6',
    color: 'white',
  }));

  const GroupItems = styled('ul')({
    padding: 0,
  });

  return (
    <div>
      <div className="autocomplete-search label-dropdown">
        {props.defects !== true && (
          <InputLabel>
            <span className="fontPoppinsMediumMd pl-3 common-label-text">
              {props.type === 'bulkLabel' && <span className="errorMessage">*</span>}
              <span className="ml-1">Labels</span>
            </span>
          </InputLabel>
        )}
        <Autocomplete
          sx={{ m: 1, width: '92%' }}
          multiple
          limitTags={4}
          onOpen={props?.onOpen}
          onClose={props?.onClose}
          id="tags-standard"
          options={responseObject}
          getOptionLabel={(option) => option.name}
          groupBy={(option) => option.type}
          defaultValue={[]}
          onChange={handleChange}
          popupIcon={<Icon name="arrow_down" className="opacity-50" height={14} width={8} />}
          disableCloseOnSelect
          disableClearable={true}
          disabled={props.action === 'Clear Labels' || props?.action === null}
          value={selectedOptions}
          isOptionEqualToValue={(option, value) => option.name === value}
          renderTags={(responseObject, getTagProps) =>
            responseObject.map((option, index) => (
              <Chip className="chip-component-label" variant="outlined" label={option} {...getTagProps({ index })} />
            ))
          }
          clearOnBlur={true}
          renderOption={(props, option, { selected }) => (
            <MenuItem key={option.name} value={option.name} sx={{ justifyContent: 'space-between' }} {...props}>
              {option.name}
              {selected ? <CheckIcon color="info" /> : null}
            </MenuItem>
          )}
          renderGroup={(params) => (
            <li key={params.key}>
              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
          noOptionsText={
            <Box display="flex" justifyContent="space-between" alignItems="center">
              {validation ? (
                <div className="text-red-500 text-xs fontPoppinsRegularSm">{errorMsg}</div>
              ) : (
                <div
                  className="text-xs fontPoppinsRegularSm cursor-pointer"
                  onClick={props.action === BULK_LABELING.FIND_AND_REMOVE_LABELS ? null : createLabels}
                >
                  {showLabel}
                </div>
              )}
            </Box>
          }
          renderInput={(params) => (
            <div>
              <TextField
                {...params}
                id="name"
                variant="outlined"
                label=""
                placeholder="Labels"
                onKeyUp={(e) => {
                  getLabelValue(e);
                }}
                onBlur={(e) => getValue(e)}
              />
              <Tooltip title="Add Label">
                <button
                  disabled={
                    labelValue?.current === undefined || validation || labelValue.current === '' || hideAddLabelBtn
                  }
                  className={`${
                    labelValue?.current === undefined || validation || labelValue.current === '' || hideAddLabelBtn
                      ? 'hidden'
                      : 'opacity-100'
                  } relative -m-3 top-4 left-4`}
                >
                  {props.action !== BULK_LABELING.FIND_AND_REMOVE_LABELS && (
                    <img src={AddLabelButton} alt="labelButton" onClick={createLabels} />
                  )}
                </button>
              </Tooltip>
            </div>
          )}
        />
        <InputLabel>
          <span
            className="fontPoppinsRegularXs float-right pr-9 tab-color cursor-pointer"
            onClick={() => {
              manageLabelsHandler('/configuration/Label');
            }}
          >
            Manage Labels
          </span>
        </InputLabel>
      </div>
    </div>
  );
};

export default MultiAutocomplete;

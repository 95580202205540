import ConfigMemoryStyle from '@pages/configuration/assests/styles/configmemory.module.scss';

function ProgressLineMemoryConfig({ total = 100, filled = 0 }) {
  return (
    <div className={ConfigMemoryStyle['progress-line-wrapper-container']}>
      <div
        className={ConfigMemoryStyle['filled-bar']}
        style={{
          width:
            `${(100 * Number(filled)) / Number(total)}` > 100 ? `100%` : `${(100 * Number(filled)) / Number(total)}%`,
        }}
      />
    </div>
  );
}

export default ProgressLineMemoryConfig;

import React, { useState, useEffect } from 'react';
import './filters.css';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { ChevronRightIcon } from '@heroicons/react/solid';
import DateTimePicker from '../date_time_picker';
import moment from 'moment';
import { isValidDateFormatDDMMYYYY, convertDate } from '../../analytics/common/util';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { ReactComponent as CalenderIcon } from '@assets/analytics/Calender.svg';
import { makeStyles } from '@material-ui/core';
import { colors } from './../../../css_config/colorConstants';
import { getCurrentProjectTimeZoneName } from '@src/util/common_utils';

const useStyles = makeStyles({
  customRadioButton: {
    '& .MuiSvgIcon-root': {
      color: `${colors.button_blue_50}`,
    },
  },
});

let timePeriods = [
  {
    name: 'Date',
    label: 'Last 7 Days',
    value: '7',
  },
  {
    name: 'Date',
    label: 'Last Month',
    value: '30',
  },
  {
    name: 'Date',
    label: 'Last 3 Months',
    value: '90',
  },
  {
    name: 'Date',
    label: 'Last 12 Months',
    value: '365',
  },
];
let startDate = '',
  endDate = '';
export const FilterTimePeriod = (props) => {
  const classes = useStyles();
  const [expandTimePeriod, setExpandTimePeriod] = useState(true);
  const [currentTimePeriod, setCurretTimePeriod] = useState('7');
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: null,
    end: null,
  });
  let timeZoneName = getCurrentProjectTimeZoneName();

  const setTimePeriod = (e) => {
    let tempTimePeriod = e.target.value.toString();
    document.getElementById('rangeFromDate').value = '';
    document.getElementById('rangeToDate').value = '';
    startDate = '';
    endDate = '';
    setCurretTimePeriod(tempTimePeriod);
    switch (tempTimePeriod) {
      case '7':
        return props.handleTimePeriod(
          moment().tz(timeZoneName).subtract(7, 'days').add(1, 'day').format('DD/MM/YYYY'),
          moment().tz(timeZoneName).format('DD/MM/YYYY')
        );

      case '30':
        return props.handleTimePeriod(
          moment().tz(timeZoneName).subtract(1, 'month').add(1, 'day').format('DD/MM/YYYY'),
          moment().tz(timeZoneName).format('DD/MM/YYYY')
        );
      case '90':
        return props.handleTimePeriod(
          moment().tz(timeZoneName).subtract(3, 'months').add(1, 'day').format('DD/MM/YYYY'),
          moment().tz(timeZoneName).format('DD/MM/YYYY')
        );
      case '365':
        return props.handleTimePeriod(
          moment().tz(timeZoneName).subtract(1, 'year').add(1, 'day').format('DD/MM/YYYY'),
          moment().tz(timeZoneName).format('DD/MM/YYYY')
        );
      default:
        return props.handleTimePeriod(
          moment().tz(timeZoneName).subtract(7, 'days').add(1, 'day').format('DD/MM/YYYY'),
          moment().tz(timeZoneName).format('DD/MM/YYYY')
        );
    }
  };

  const setDateRange = (rId, rDate) => {
    if (rId === 'rangeFromDate') {
      startDate = convertDate(rDate);
      setSelectedDateRange({ ...selectedDateRange, start: rDate });
    } else {
      endDate = convertDate(rDate);
      setSelectedDateRange({ ...selectedDateRange, end: rDate });
    }
    if (startDate !== '' && endDate !== '') {
      let validFromDate = isValidDateFormatDDMMYYYY(startDate);
      let validToDate = isValidDateFormatDDMMYYYY(endDate);
      if (validFromDate && validToDate) {
        setCurretTimePeriod('');
        props.handleTimePeriod(startDate, endDate);
      }
    }
  };
  useEffect(() => {
    if (currentTimePeriod !== '') {
      startDate = '';
      endDate = '';
      setSelectedDateRange({
        start: null,
        end: null,
      });
    }
  }, [currentTimePeriod]);
  return (
    <>
      <div className="group">
        <div className="timeperiodheader">
          <div className="grid grid-cols-6  pl-2 pt-1 rounded-t-sm h-7">
            <div className="col-span-5 inline-block fontPoppinsSemiboldSm rs-blue">Time Period</div>
            <div className="col-span-1 inline-block">
              {expandTimePeriod ? (
                <ChevronDownIcon
                  className="ChevronIcon cursor-hand"
                  onClick={() => {
                    setExpandTimePeriod(false);
                  }}
                />
              ) : (
                <ChevronRightIcon
                  className="ChevronIcon cursor-hand "
                  onClick={() => {
                    setExpandTimePeriod(true);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {expandTimePeriod && (
        <div>
          <div className="flex flex-col mx-2 gap-3 ">
            <div className=" time-period-input rounded-md mt-2">
              <DateTimePicker
                dateId="rangeFromDate"
                setDateRange={setDateRange}
                selectedDate={selectedDateRange.start}
                placeholder="From Date"
                maxDate={selectedDateRange.end}
              />
            </div>
            <div className=" time-period-input rounded-md">
              {selectedDateRange.start ? (
                <DateTimePicker
                  dateId="rangeToDate"
                  setDateRange={setDateRange}
                  selectedDate={selectedDateRange.end}
                  minDate={selectedDateRange.start}
                  placeholder="To Date"
                />
              ) : (
                <div
                  className="flex items-center justify-between px-1 cursor-not-allowed"
                  title="Please Select From Date First"
                >
                  <input
                    type="text"
                    placeholder="To Date"
                    disabled
                    id="rangeToDate"
                    className="cursor-not-allowed bg-transparent border-0 fontPoppinsRegularSm"
                    style={{ borderBottom: 'none' }}
                    title="Please Select From Date First"
                  />
                  <span title="Please Select From Date First" className="p-1 pt-2">
                    <CalenderIcon className="cursor-not-allowed " />{' '}
                  </span>
                </div>
              )}
            </div>
          </div>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              className="radioGroupButtons"
              value={currentTimePeriod}
            >
              {timePeriods.map((timePeriod) => (
                <FormControlLabel
                  value={timePeriod.value}
                  onChange={setTimePeriod}
                  control={<Radio size="small" className={classes.customRadioButton} />}
                  label={<span className="rs-text-black fontPoppinsRegularSm">{timePeriod.label}</span>}
                  key={timePeriod.value}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      )}
    </>
  );
};

import Chart from 'react-apexcharts';
import { colors } from '@src/css_config/colorConstants.js';
import { chartFontFamily, chartFontStyle, defectAnalyticsLabel } from '@common/ui-constants';

function DefectsCycleChartSevendays(props) {
  // TODO :: cycle time options for week to year :: START
  let optionsForSevendaysLineChart = {
    series: props.series,
    chart: {
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
        tools: {
          download: false,
        },
      },
    },

    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      fontSize: '10px',
      fontFamily: chartFontFamily,
      offsetY: 8,
    },
    colors: colors.defectscycleTimeColor,

    stroke: {
      curve: 'smooth',
      lineCap: 'round',
      width: 3,
    },
    xaxis: {
      categories: props.categories,
      axisTicks: {
        show: false,
      },
      marker: {
        show: true,
      },
      title: {
        text: defectAnalyticsLabel.label.NUMBER_OF_DAYS,
        offsetY: 0,
        ...chartFontStyle,
      },
      labels: {
        style: {
          color: colors.black,
          fontSize: '10px',
          fontFamily: chartFontFamily,
        },
      },
    },
    yaxis: {
      title: {
        text: defectAnalyticsLabel.label.NUMBER_OF_DEFECTS,
        ...chartFontStyle,
      },
      labels: {
        style: {
          color: colors.black,
          fontSize: '10px',
          fontFamily: chartFontFamily,
        },
      },
    },

    tooltip: {
      shared: true,
      enabled: true,
      onDatasetHover: {
        highlightDataSeries: true,
      },
      style: {
        fontSize: '10px',
        fontFamily: chartFontFamily,
      },
      x: {
        show: true,
        style: {
          fontSize: '10px',
          fontFamily: chartFontFamily,
        },
      },
      y: {
        show: true,
        style: {
          fontSize: '10px',
          fontFamily: chartFontFamily,
        },
      },

      marker: {
        show: true,
      },
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        right: 28,
      },
    },
  };
  // TODO :: cycle time options for week to year :: END

  return (
    <div className={' flex items-center justify-center'}>
      <Chart
        options={optionsForSevendaysLineChart}
        series={optionsForSevendaysLineChart.series}
        type="line"
        height="250px"
        width={props.showFilter ? '755px' : '1040px'}
      />
    </div>
  );
}
export default DefectsCycleChartSevendays;

import React, { useEffect, useState } from 'react';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { Chip, Divider } from '@mui/material';
import { isEmptyValue } from '@src/util/common_utils';
import { defectAnalyticsLabel } from '@common/ui-constants';

const DefectLogFilter = (props) => {
  const {
    setOpenFilterModal,
    dataList,
    defectPayload,
    reqBody,
    setDefectPayload,
    selectedSeverity,
    selectedPriority,
    selectedStatus,
    selectedModule,
    setSelectedSeverity,
    setSelectedPriority,
    setSelectedStatus,
    setSelectedModule,
    onApply,
    isFilterApplied,
  } = props;

  let [filterDorpDownData, setFilterDorpDownData] = useState(dataList || []);
  let [segment, setSegment] = useState([
    ...selectedSeverity,
    ...selectedPriority,
    ...selectedStatus,
    ...selectedModule,
  ]);

  useEffect(() => {
    setSegment([...selectedSeverity, ...selectedPriority, ...selectedStatus, ...selectedModule]);

    setDefectPayload({
      ...defectPayload,
      facetQueries: [
        ...reqBody?.facetQueries,
        {
          name: 'Severity',
          values: selectedSeverity?.map((val) => val.value),
        },
        {
          name: 'Priority',
          values: selectedPriority?.map((val) => val.value),
        },
        {
          name: 'Status',
          values: selectedStatus?.map((val) => val.value),
        },
        {
          name: 'Module',
          values: selectedModule?.map((val) => val.value),
        },
      ],
    });
  }, [selectedSeverity, selectedPriority, selectedStatus, selectedModule]);

  let convertOption = (section) => {
    const Options = section?.map((val) => {
      let moduleLabel;
      let subModuleName = val.name?.split('/')?.at(-1);
      let subModuleNameCopy = val.name?.split('/')?.at(-1);

      if (subModuleName.includes(':')) {
        //NOTE ::  if module name have (:) colon trim and take only subModule name
        moduleLabel = subModuleName.split(':').slice(0, 1).toString();
        moduleLabel = moduleLabel.length > 15 ? moduleLabel.slice(0, 15) + '..' : moduleLabel;
      } //NOTE ::  if module name don't have (:) colon trim only subModule name
      else {
        moduleLabel = subModuleNameCopy.length > 15 ? subModuleNameCopy.slice(0, 15) + '..' : subModuleNameCopy;
      }

      if (val.name.includes('/Root')) {
        return {
          label: <span title={val.name?.split(':')?.at(0)}>{moduleLabel}</span>,
          value: val.name,
        };
      } else {
        return {
          label: <span title={val.name?.split(':')?.at(0)}>{val.name}</span>,
          value: val.name,
        };
      }
    });
    return Options;
  };

  let handleSelectedSeverirty = (value, event) => {
    setSelectedSeverity(value);
  };
  let handleSelectedPriorirty = (value) => {
    setSelectedPriority(value);
  };
  let handleSelectedStatus = (value) => {
    setSelectedStatus(value);
  };
  let handleSelectedModlue = (value) => {
    setSelectedModule(value);
  };

  let handleCloseClick = () => {
    setSelectedSeverity([]);
    setSelectedPriority([]);
    setSelectedStatus([]);
    setSelectedModule([]);
    onApply(defectAnalyticsLabel.btnLabel.clearAll);
  };

  let handleDeleteSegment = (val) => {
    if (selectedSeverity?.map((d) => d.value).includes(val)) {
      setSelectedSeverity(selectedSeverity.filter((d) => d.value !== val));
    } else if (selectedPriority?.map((d) => d.value).includes(val)) {
      setSelectedPriority(selectedPriority.filter((d) => d.value !== val));
    } else if (selectedStatus?.map((d) => d.value).includes(val)) {
      setSelectedStatus(selectedStatus.filter((d) => d.value !== val));
    } else if (selectedModule?.map((d) => d.value).includes(val)) {
      setSelectedModule(selectedModule.filter((d) => d.value !== val));
    }
  };

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (!isEmptyValue(value)) {
      return `${value.length} selected`;
    } else {
      return `${placeholderButtonLabel}`;
    }
  }

  // TODO ::  to show trim segment in span tag with tilte :: START
  let getSpanLabel = (segName) => {
    let moduleLabel;
    if (segName.type !== 'span') {
      let subModuleName = segName?.split('/')?.at(-1);
      let subModuleNameCopy = segName?.split('/')?.at(-1);
      if (subModuleName.includes(':')) {
        //NOTE ::  if module name have (:) colon trim and take only subModule name
        moduleLabel = <span title={segName?.split(':')?.at(0)}>{subModuleName.split(':').slice(0, 1).toString()}</span>;
        moduleLabel = (
          <span title={segName?.split(':')?.at(0)}>
            {moduleLabel.props.children.length > 15
              ? moduleLabel.props.children.slice(0, 15) + '..'
              : moduleLabel.props.children}
          </span>
        );

        return moduleLabel;
      } //NOTE ::  if module name don't have (:) colon trim only subModule name
      else {
        return (moduleLabel = (
          <span title={segName?.split(':')?.at(0)}>
            {subModuleNameCopy.length > 15 ? subModuleNameCopy.slice(0, 15) + '..' : subModuleNameCopy}
          </span>
        ));
      }
    } else {
      return (moduleLabel = segName);
    }
  };

  // TODO ::  to show trim segment in span tag with tilte :: END

  return (
    <div className="defect_log_Modal">
      <div className="filter_Header fontPoppinsSemiboldSm  ">
        <div className="uppercase "> {defectAnalyticsLabel.header.DEFECTS_LOG_FILTER}</div>
        <span className="close-icon-x-white-bule" onClick={(_) => setOpenFilterModal(false)}></span>
      </div>
      <div className="Select_filter  p-3  ">
        <div className="second_title rs-blue fontPoppinsSemiboldSm">{defectAnalyticsLabel.header.SELECT_FILTER}</div>
        {!isEmptyValue(segment) && (
          <div className="segment-section px-3 pt-1 pb-3  mt-1">
            <div className="fontPoppinsRegularXS rs-skipped">{defectAnalyticsLabel.header.SEGMENT} </div>
            <div className="flex gap-1 flex-wrap px-1 py-2 segment-block">
              {segment?.map((seg) => {
                return (
                  <Chip
                    label={getSpanLabel(seg?.label)}
                    className="fontPoppinsRegularXs"
                    size="small"
                    onDelete={(_) => handleDeleteSegment(seg.value)}
                    variant="outlined"
                    key={seg.value}
                  />
                );
              })}
            </div>

            <Divider variant="fullWidth" />
          </div>
        )}

        <div className="Filter_Slectors_Sections  mt-3 pb-10">
          <div className=" selector_Block w-full">
            <div className="fontPoppinsSemiboldSm rs-blue selector_Header ">{defectAnalyticsLabel.label.severity}</div>
            <div className="mt-1 wapper_custom_dropdown">
              <ReactMultiSelectCheckboxes
                options={convertOption(filterDorpDownData?.[0]?.defectData)}
                onChange={handleSelectedSeverirty}
                value={selectedSeverity}
                placeholderButtonLabel="Select Severity"
                hideSearch={true}
                getDropdownButtonLabel={getDropdownButtonLabel}
              />
            </div>
          </div>
          <div className=" selector_Block w-full">
            <div className="fontPoppinsSemiboldSm rs-blue selector_Header ">{defectAnalyticsLabel.label.priority}</div>
            <div className="mt-1 wapper_custom_dropdown">
              <ReactMultiSelectCheckboxes
                options={convertOption(
                  filterDorpDownData?.[1]?.defectData?.filter((val) => val.name !== defectAnalyticsLabel.NO_PRIORITY)
                )} // NOTE :: NO PRIORITY Should NOT Display in Dropdown
                onChange={handleSelectedPriorirty}
                value={selectedPriority}
                placeholderButtonLabel="Select Priority"
                hideSearch={true}
                getDropdownButtonLabel={getDropdownButtonLabel}
              />
            </div>
          </div>
          <div className=" selector_Block w-full">
            <div className="fontPoppinsSemiboldSm rs-blue selector_Header ">{defectAnalyticsLabel.label.status}</div>
            <div className="mt-1 wapper_custom_dropdown">
              <ReactMultiSelectCheckboxes
                options={convertOption(filterDorpDownData?.[2]?.defectData)}
                onChange={handleSelectedStatus}
                value={selectedStatus}
                placeholderButtonLabel="Select Status"
                hideSearch={true}
                getDropdownButtonLabel={getDropdownButtonLabel}
              />
            </div>
          </div>
          <div className=" selector_Block w-full">
            <div className="fontPoppinsSemiboldSm rs-blue selector_Header ">{defectAnalyticsLabel.label.module}</div>
            <div className="mt-1 wapper_custom_dropdown">
              <ReactMultiSelectCheckboxes
                options={convertOption(filterDorpDownData?.[3]?.defectData)}
                onChange={handleSelectedModlue}
                value={selectedModule}
                placeholderButtonLabel="Select Module"
                hideSearch={true}
                getDropdownButtonLabel={getDropdownButtonLabel}
              />
            </div>
          </div>
        </div>
        <div className="Filter_Btn_block flex justify-end gap-2 py-2 mt-4">
          <button
            disabled={isEmptyValue(segment) && !isFilterApplied}
            className={` text-white p-2 rounded fontPoppinsMediumSm ${isEmptyValue(segment) && !isFilterApplied ? 'cursor-not-allowed bg-gray-200' : 'cursor-pointer bg-gray-400'}`}
            onClick={handleCloseClick}
          >
            {defectAnalyticsLabel.btnLabel.clearAll}
          </button>
          <button
            disabled={isEmptyValue(segment) && !isFilterApplied}
            onClick={(_) => onApply(defectAnalyticsLabel.btnLabel.apply, segment)}
            className={`primary-btn fontPoppinsMediumSm ${isEmptyValue(segment) && !isFilterApplied ? 'cursor-not-allowed' : 'cursor-pointer'}`}
          >
            {defectAnalyticsLabel.btnLabel.apply}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DefectLogFilter;

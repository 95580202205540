import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

function BrowserStackDetails(props) {
  const [openModal, setOpenModal] = useState(true);
  const handleClose = () => {
    props.closeModal(false);
    setOpenModal(false);
  };

  return (
    <div>
      <Modal open={openModal} onClose={handleClose} className="modal-dialog">
        <div className="modal-container parameter-details-modal">
          <div className="modal-header">
            <div className="Popup-header-common account-name-trimmer" title={props.data.instanceName}>
              {`Account Details -${props.data.instanceName}`}
            </div>
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                props.closeModal(false);
                setOpenModal(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="modal-body">
            <div className="grid grid-cols-2">
              <div className="">
                <div className="grid grid-rows-4 ml-4">
                  <div className="mt-1">
                    <h1 className="details-key-style-common">Instance Name</h1>
                    <p className="pt-2 text-gray-700">
                      <div className="details-data-style-common name-trimmer" title={props.data.instanceName}>
                        {props.data.instanceName}
                      </div>
                    </p>
                  </div>
                  <div className="mt-4">
                    <h1 className="details-key-style-common">Access Key</h1>
                    <p className="pt-2 text-gray-700">
                      <div className="details-data-style-common encode">{props.data.accessKey}</div>
                    </p>
                  </div>
                  <div className="mt-5">
                    <h1 className="details-key-style-common">Created On</h1>
                    <p className="text-gray-700 pt-2 ">
                      <span className="details-data-style-common">{props.data.createdOn}</span>
                    </p>
                  </div>
                  <div className="mt-8">
                    <h1 className="details-key-style-common">Modified On</h1>
                    <p className="text-gray-700 pt-2 ">
                      <span className="details-data-style-common">{props.data.modifiedOn}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="grid grid-rows-4 ml-14">
                  <div className="mt-1">
                    <h1 className="details-key-style-common">Username</h1>
                    <p className="pt-2 text-gray-700 ">
                      <div className="details-data-style-common name-trimmer" title={props.data.username}>
                        {props.data.username}
                      </div>
                    </p>
                  </div>
                  <div className="mt-4">
                    <h1 className="details-key-style-common">Created by</h1>
                    <p className="text-gray-700 pt-2 ">
                      <div className="details-data-style-common name-trimmer" title={props.data.createdByUname}>
                        {props.data.createdByUname}
                      </div>
                    </p>
                  </div>
                  <div className="mt-7">
                    <h1 className="details-key-style-common">Modified By</h1>
                    <p className="text-gray-700 pt-2 ">
                      <span className="details-data-style-common">{props.data.modifiedByUname}</span>
                    </p>
                  </div>
                  {props?.data?.id.includes('SAUCE') ? (
                    <div className="mt-7">
                      <h1 className="details-key-style-common">Data Center</h1>
                      <p className="text-gray-700 pt-2 ">
                        <span className="details-data-style-common">{props?.data?.defaultDataCenter}</span>
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="">
              <button
                onClick={() => {
                  props.closeModal(false);
                  setOpenModal(false);
                }}
                type="button"
                className="gray-btn"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default BrowserStackDetails;

import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import React, { useState, useMemo, Fragment, useEffect } from 'react';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import { useAlert } from '../common/alert_service/useAlert';
import ExecutionDashboard from './execution-dashboard/execution-dashboard';
import AllModulesTree from './script/modules/module/all-modules-tree';
import DownloadClientModal from '../configuration/system_config/system_config v1.1/modals/download_client_modal';
import {
  getLicenseFeatures,
  purchasedFeatureTypeOfAutomation,
  validateFileExtension,
} from '@src/util/common_utils';
import CommonModalComponent from '@src/pages/common/modal/CommonModalComponent';
import FilesDragAndDrop from '@pagescommon/file_drag_and_drop';
import {
  importTestcase,
  validatedTestcaseImport,
  exportManualTestCaseFiles,
  exportManualTestCaseTemplate,
  getAllTestCaseTemplate,
  getImportProgressPercent,
} from '@api/api_services';
import '../common/file_drag_and_drop.scss';
import { BULK_LABELING, MTC_ERROR_CONSTANTS } from '@src/common/ui-constants';
import { saveFileFromBlob } from '@src/pages/test-development/shared/common-methods';
import { getTimeStampForFileName, isEmptyObject, isEmptyValue } from '@src/util/common_utils';
import { TooltipPoppin } from '../analytics/common/util';
import { ReactComponent as ImportIcon } from '@assets/svg-imports/import_icon.svg';
import { ReactComponent as ExpandIcon } from '@assets/svg-imports/expand-square-icon.svg';
import { ReactComponent as FireflinkLogo } from '@assets/svg-imports/fireflink-mini-logo.svg';
import { colors } from '@src/css_config/colorConstants';
import ProgressBar from '@pagescommon/progress_bar/progress-bar';
import { getScriptOptions } from './script/modules/module/utils/common-functions';
import '@src/pages/test-development/landing-page-router.scss';
import UpdateLabelScripts from './script/modules/module/updateLabelScriptsDrawer';
import { IMPORT_MTC_FILE_ALLOWED } from '@util/validations';
import { Tooltip } from 'fireflink-ui';

const LandingPageRouter = (props) => {
  const [downloadClientModal, setDownloadClientModal] = useState(false);
  const { AlertContatiner, MyAlert } = useAlert();
  const project = JSON.parse(localStorage.getItem('selected-project'));
  const licenseFeatures = getLicenseFeatures();
  const hasAutomation = window.privilege.hasAutomation(licenseFeatures);
  let hasManual = window.privilege.hasManualTestCase(licenseFeatures);
  let hasCBasic = window.privilege.hasCBasic(licenseFeatures);
  const hasAutomationBasedOnProjType = purchasedFeatureTypeOfAutomation(project.type);
  const [showModal, setShowModal] = useState(false);
  const [files, setFiles] = useState([]);
  const history = useHistory();
  let activeTab = history.location.pathname.split('/')[2];
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageForTCName, setErrorMessageForTCName] = useState('');
  const [errorMessageForCellNumber, setErrorMessageForCellNumber] = useState('');
  const [errorMessageErrorCol, setErrorMessageErrorCol] = useState('');
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [responseObjectFlag, setResponseObjectFlag] = useState(false);
  const [importMTCDataReload, setImportMTCDataReload] = useState(false);
  const [templatNameAndId, setTemplatNameAndId] = useState({ templateId: '', templateName: '' });
  const [isManualTestAvaliable, setisManualTestAvaliable] = useState(false);
  const [changeActiveProject, setChangeActiveProject] = useState('');
  const [contextMenuData, setContextMenuData] = useState([{ title: 'Download Client', disabled: false }]);
  const [isImportloader, setImportLoader] = useState(false);
  const [importProgressCount, setImportProgressCount] = useState(0);
  const [progressCall, setProgressCall] = useState(false);
  const [isModalMinimize, setIsModalMinimize] = useState(false);
  const [errorTableData, setErrorTableData] = useState([]);
  const [importId, setImportId] = useState('');
  const [importDisable, setImportDiable] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [openUpdateLabel, setOpenUpdateLabel] = useState(false);
  const [selectedScripts, setSelectedScripts] = useState([]);
  const { isEditable, hasFullAccess, hasViewAccess } = React.useMemo(
    () => ({
      isEditable: window.permission?.isEditAccess('testDevelopment'),
      hasFullAccess: window.permission?.isFullAccess('testDevelopment'),
      hasViewAccess: window.permission.isViewAccess('testDevelopment'),
    }),
    []
  );

  const [unSupportedFile, setUnSupportedFile] = useState(false);

  let selectedProjectStatus = JSON.parse(localStorage.getItem('selected-project'));

  let currentTimeStamp = getTimeStampForFileName();

  useEffect(() => {
    if (activeTab?.includes('Script') && selectedProjectStatus?.status === 'Open' && (isEditable || hasFullAccess)) {
      setContextMenuData([
        hasManual || hasCBasic
          ? {
              title: 'Import Manual TestCases',
              disabled: !(selectedProjectStatus?.status === 'Open' && (isEditable || hasFullAccess)),
            }
          : null,
        hasManual || hasCBasic ? { title: 'Export Manual TestCases', disabled: !isManualTestAvaliable } : null,
        {
          title: BULK_LABELING.UPDATE_LABEL_FOR_SCRIPTS,
          disabled: !(selectedScripts.length > 0),
        },
        hasAutomation && hasAutomationBasedOnProjType ? { title: 'Download Client', disabled: false } : null,
      ]);
    } else if (
      activeTab?.includes('Script') &&
      (selectedProjectStatus?.status !== 'Open' || (selectedProjectStatus?.status === 'Open' && hasViewAccess))
    ) {
      setContextMenuData([
        hasManual || hasCBasic ? { title: 'Export Manual TestCases', disabled: !isManualTestAvaliable } : null,
        hasAutomation && hasAutomationBasedOnProjType ? { title: 'Download Client', disabled: false } : null,
      ]);
    } else if (!activeTab?.includes('Script')) {
      setContextMenuData([
        hasAutomation && hasAutomationBasedOnProjType ? { title: 'Download Client', disabled: false } : null,
      ]);
    }

    let prevProjectId = localStorage.getItem('prevProjectId');
    if (prevProjectId) {
      if (prevProjectId !== selectedProjectStatus?.id) {
        setImportLoader(false);
        setImportProgressCount(0);
        setIsModalMinimize(false);
        setProgressCall(false);
        localStorage.removeItem('importIdValidation');
        localStorage.removeItem('errorJson');
        localStorage.removeItem('mtcImportData');
        localStorage.removeItem('prevProjectId');
      }
    }
  }, [
    isManualTestAvaliable,
    activeTab,
    hasFullAccess,
    isEditable,
    changeActiveProject,
    selectedProjectStatus?.id,
    selectedScripts,
  ]);

  let getManualTestcaseAvaliableStatus = (status) => {
    setisManualTestAvaliable(status);
    setChangeActiveProject(selectedProjectStatus?.id);
  };

  const getScriptTypes = () => {
    const projectDetails = JSON.parse(localStorage.getItem('selected-project'));
    if (projectDetails.type === 'Web') {
      return 'Web, Webservice, Database';
    } else if (projectDetails.type === 'Mobile' && projectDetails.platform !== 'Android & iOS') {
      let scriptTypes = projectDetails.platform === 'iOS' ? 'iOS' : 'Android';
      return scriptTypes + ', Webservice, Database';
    } else if (projectDetails.type === 'Mobile') {
      return 'Android, iOS, Webservice, Database';
    } else if (
      projectDetails.type === 'Web & Mobile' ||
      projectDetails.type === 'Salesforce' ||
      projectDetails.type === 'MS Dynamics'
    ) {
      if (projectDetails.platform === 'Android') {
        return 'Web, Android, Webservice, Database, Web & Mobile';
      } else if (projectDetails.platform === 'iOS') {
        return 'Web, iOS, Webservice, Database, Web & Mobile';
      } else {
        return 'Web, Android, iOS, Webservice, Database, Web & Mobile';
      }
    } else {
      return 'Webservice, Database';
    }
  };

  const onUploadhandler = (files) => {
    if (isEmptyValue(files)) {
      MyAlert.warning('Unsupported file.');
      setUnSupportedFile(true);
      return;
    } else if (validateFileExtension(files[0], IMPORT_MTC_FILE_ALLOWED)) {
      setFiles(files);
      setErrorMessage('');
      setIsTableVisible(false);
      localStorage.removeItem('errorJson');
    } else {
      setUnSupportedFile(true);
      MyAlert.warning(`${files?.[0]?.name} is unsupported.`);
    }
  };

  const importManualTestCases = async () => {
    try {
      const formData = new FormData();
      const scriptTypes = getScriptOptions()
        .map((val) => val?.value)
        .toString();
      formData.append('scriptType', scriptTypes);
      formData.append('file', files[0]);
      const response = await importTestcase(formData);
      const multipleErrorResponse = response.data.responseObject;
      let importFileDetials = response.data.responseMap;
      if (response.data.responseCode === 200) {
        setImportId(importFileDetials?.importId);
        setProgressCall(true);
        setImportLoader(true);
        setImportMTCDataReload(true);
        setResponseObjectFlag(false);
        localStorage.removeItem('errorJson');
        MyAlert.info(response.data.message);
      }
      if ([400, 0, 404].includes(response.data.responseCode) && multipleErrorResponse === null) {
        setErrorMessage(response.data.message);
        setImportMTCDataReload(false);
        setResponseObjectFlag(false);
      } else {
        setErrorMessage('');
      }

      if ([400, 0].includes(response.data.responseCode) && multipleErrorResponse?.length) {
        setErrorMessageForTCName(multipleErrorResponse.map((data) => data?.testCaseName));
        setErrorMessageForCellNumber(multipleErrorResponse.map((data) => data?.errorMessage));
        setErrorMessageErrorCol(multipleErrorResponse.map((data) => data?.cellNumber));
        setResponseObjectFlag(true);
        setImportMTCDataReload(false);
        const tableData = prepareTableData(
          multipleErrorResponse.map((data) => data?.testCaseName),
          multipleErrorResponse.map((data) => data?.errorMessage),
          multipleErrorResponse.map((data) => data?.cellNumber)
        );
        setErrorTableData(tableData);
        setImportDiable(false);
        localStorage.setItem('importIdValidation', JSON.stringify(response.data.responseMap.importId));
      } else {
        setErrorMessageForTCName('');
        setErrorMessageForCellNumber('');
        setErrorMessageErrorCol('');
        setResponseObjectFlag(false);
        setImportMTCDataReload(true);
        setImportDiable(false);
      }
      setIsTableVisible([400, 0].includes(response.data.responseCode) && multipleErrorResponse?.length);
      localStorage.setItem('errorJson', JSON.stringify({ errorJson: multipleErrorResponse }));
      setImportDiable(false);
    } catch (error) {
      console.error('IMPORT MANUAL TEST CASE : ', error);
    }
  };

  const importValidatedMTC = async () => {
    try {
      const formData = new FormData();
      const responseData = localStorage.getItem('errorJson');
      let validationId = JSON.parse(localStorage.getItem('importIdValidation'));
      formData.append('file', files[0]);
      formData.append('data', responseData);
      formData.append('importId', validationId);
      let validatedMTC = await validatedTestcaseImport(formData);
      let importDetalis = validatedMTC.data.responseMap;
      if (validatedMTC.data.responseCode === 200) {
        setImportId(importDetalis?.importId);
        setProgressCall(true);
        setImportLoader(true);
        setImportMTCDataReload(true);
        setResponseObjectFlag(false);
        MyAlert.info(validatedMTC.data.message);
      }
      if (
        validatedMTC.data.responseCode === 200 &&
        validatedMTC.data.message.includes('Failed. No Test Cases Imported')
      ) {
        handleClose();
        MyAlert.info(validatedMTC.data.message);
        setImportMTCDataReload(false);
        setResponseObjectFlag(false);
        localStorage.removeItem('errorJson');
      }
      localStorage.removeItem('importIdValidation');
      setImportDiable(false);
    } catch (error) {
      console.error('IMPORT VALIDATED MTC ERROR : ', error);
    }
  };

  const exportManualData = async () => {
    const selectedProject = JSON.parse(localStorage.getItem('selected-project'));
    let scriptOptions = getScriptOptions()?.map((data) => data?.value);
    let payloadObj = {};
    payloadObj[selectedProject?.type] = scriptOptions;
    let payloadArr = [payloadObj];
    let finalPayload = {
      templateId: templatNameAndId?.templateId,
      payload: payloadArr,
    };
    try {
      let response = await exportManualTestCaseTemplate(finalPayload);
      saveFileFromBlob(
        response.data,
        `${selectedProjectStatus?.name}_${templatNameAndId?.templateName}_${currentTimeStamp}.xlsx`
      );
      MyAlert.success(`Manual Test Cases Exported Successfully`);
    } catch (err) {
      console.error('ERROR IN MANUAL TESTCASE TEMPLATE EXPORT API', err);
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setErrorMessage('');
    setFiles([]);
    setIsTableVisible(false);
    setResponseObjectFlag(false);
    localStorage.removeItem('errorJson');
  };

  let onMinimizeModalSaveDetails = () => {
    setShowModal(false);
    setIsModalMinimize(true);

    let mtcImportData = {
      fileInfo: { name: files?.[0]?.name, size: files?.[0]?.size },
      importId,
    };
    localStorage.setItem('mtcImportData', JSON.stringify({ ...mtcImportData }));
    localStorage.setItem('prevProjectId', `${selectedProjectStatus.id}`);
  };

  let onExpandMiniModal = () => {
    setShowModal(true);
    setIsModalMinimize(false);
  };

  const renderFooterContent = useMemo(() => {
    return (
      <div className={`flex items-center  ${isImportloader ? 'justify-between' : ' justify-end'}`}>
        {isImportloader ? (
          <>
            <div className="fontPoppinsRegularXs">
              <span className="fontPoppinsSemiboldXs">Note:</span> {MTC_ERROR_CONSTANTS.IMPORT_PROGRESS_NOTE}
            </div>
            <button className="close-btn " onClick={onMinimizeModalSaveDetails}>
              {' '}
              Close{' '}
            </button>
          </>
        ) : (
          <>
            <button className="gray-btn " onClick={handleClose}>
              {' '}
              Cancel{' '}
            </button>
            <button
              type="button"
              className="primary-btn flex flex-row justify-center items-center"
              disabled={importDisable || errorMessage?.length || !files.length}
              onClick={() => {
                if (responseObjectFlag) {
                  setImportDiable(true);

                  importValidatedMTC();
                } else {
                  setImportDiable(true);

                  importManualTestCases();
                }
              }}
            >
              <span>
                <ImportIcon className="mr-1 mb-1" />
              </span>
              <span className="">Import</span>
            </button>
          </>
        )}
      </div>
    );
  }, [files, errorMessage?.length, responseObjectFlag, importDisable]);

  let getImportProgress = async (payload) => {
    try {
      const response = await getImportProgressPercent(payload);
      setIsValidating(response.data.isvalidating);
      if (response.data.message?.includes('Failed')) {
        setImportLoader(false);
        setImportProgressCount(0);
        setIsModalMinimize(false);
        handleClose();
        MyAlert.danger(response.data.message);
        setImportMTCDataReload(true);
        localStorage.removeItem('mtcImportData');
        localStorage.removeItem('errorJson');
        setProgressCall(false);
        setIsValidating(response.data.isvalidating);
      } else if (response.data.isvalidating) {
        setIsValidating(response.data.isvalidating);
        setProgressCall(true);
      } else if (Number(response.data.responseObject) === 100) {
        setImportLoader(false);
        setImportProgressCount(0);
        setIsModalMinimize(false);
        handleClose();
        MyAlert.success(response.data.message);
        setImportMTCDataReload(true);
        localStorage.removeItem('mtcImportData');
        localStorage.removeItem('errorJson');
        progressCall(false);
        setIsValidating(response.data.isvalidating);
      } else {
        setIsValidating(response.data.isvalidating);
        setImportProgressCount(response.data.responseObject);
        setImportLoader(true);
        setProgressCall(true);
      }
    } catch (err) {
      console.error('IMPORT PROGRESS API FAILED', err);
    }
  };

  useEffect(() => {
    if (isImportloader && importProgressCount <= 100 && progressCall) {
      setImportMTCDataReload(false);
      setTimeout(() => {
        getImportProgress(importId);
        setProgressCall(false);
      }, 5000);
    }
  }, [isImportloader, progressCall]);

  const handleAsync = async () => {
    let BackOnScriptTab = activeTab?.includes('Script');
    let lastImportFilesData = JSON.parse(localStorage.getItem('errorJson')) || {};
    lastImportFilesData = isEmptyValue(lastImportFilesData['errorJson']) ? [] : lastImportFilesData['errorJson'];
    let PrevFileDetails = JSON.parse(localStorage.getItem('mtcImportData')) || {};
    setImportId(PrevFileDetails.importId);

    if (!isEmptyObject(PrevFileDetails) && BackOnScriptTab) {
      BackOnScriptTab = false;

      let ruuningLastImport = await getImportProgressPercent(PrevFileDetails.importId); //! NOTE ::: check in API if last import files is still inProgress or not with getImportProgress(impotredId)

      let inProgress = true;
      if (ruuningLastImport.data.message?.includes('Failed') || Number(ruuningLastImport.data.responseObject) === 100) {
        inProgress = false; // NOTE :: if import already completed terminted API calls
      }

      if (inProgress && ruuningLastImport.data.responseObject < 100) {
        setImportLoader(true);
        setImportProgressCount(ruuningLastImport.data.responseObject);
        setProgressCall(true);
        setIsModalMinimize(true);
        setFiles(PrevFileDetails.fileInfo); // NOTE ::  take fileinfo data from local mtcImportData

        if (!isEmptyObject(lastImportFilesData)) {
          let tableData = prepareTableData(
            lastImportFilesData?.map((data) => data?.testCaseName),
            lastImportFilesData?.map((data) => data?.errorMessage),
            lastImportFilesData?.map((data) => data?.cellNumber)
          );
          setErrorTableData(tableData);
          setIsTableVisible(true);
        }
      } else {
        setImportLoader(false);
        setImportProgressCount(0);
        setProgressCall(false);
        setIsModalMinimize(false);
        localStorage.removeItem('errorJson');
        localStorage.removeItem('mtcImportData');
      }
    }
  };
  useEffect(() => {
    handleAsync();
  }, [activeTab]);

  const emptyErrorMsg = () => {
    setErrorMessage('');
    setFiles([]);
    setIsTableVisible(false);
    localStorage.removeItem('errorJson');
  };

  function closeModal(val) {
    setDownloadClientModal(val);
  }
  function UpdateLabelsModal() {
    setOpenUpdateLabel(false);
  }
  const optionMethodsHandler = async (option) => {
    if (option === 'Download Client') {
      setDownloadClientModal(true);
    } else if (option === 'Import Manual TestCases') {
      setShowModal(true);
      setImportMTCDataReload(false);
      try {
        let response = await getAllTestCaseTemplate('config');
        setTemplatNameAndId({
          templateId: response.data?.responseObject?.defaultTemplate?._id,
          templateName: response.data?.responseObject?.defaultTemplate?.name,
        });
      } catch (err) {
        console.error('ERROR IN GETTING DEFAULT TEMPLATE DETAILS', err);
      }
    } else if (option === 'Export Manual TestCases') {
      try {
        let response = await exportManualTestCaseFiles();
        saveFileFromBlob(
          response.data,
          `${selectedProjectStatus?.name}_Manual_Test_Case_Export_${currentTimeStamp}.xlsx`
        );
        MyAlert.success(`Manual Test Cases Exported Successfully`);
      } catch (err) {
        console.error('ERROR IN MANAUL TESTCASE DATA EXPORT API', err);
      }
    } else if (option === BULK_LABELING.UPDATE_LABEL_FOR_SCRIPTS) {
      setOpenUpdateLabel(true);
    }
  };

  const onUpload = (files) => {
    console.log(files);
  };
  const tabsList = [
    {
      name: 'Scripts',
      pathUrl: `/testdevelopment/Script`,
      selectedName: 'Script',
    },
    {
      name: 'Execution Dashboard',
      pathUrl: `/testdevelopment/Execution Dashboard`,
      selectedName: 'Execution Dashboard',
    },
  ];

  const prepareTableData = (testCaseNames, cellNumbers, errors) => {
    const data = [];
    const testCaseMap = {};

    for (let i = 0; i < testCaseNames.length; i++) {
      const testCaseName = testCaseNames[i];

      if (!testCaseMap[testCaseName]) {
        testCaseMap[testCaseName] = [];
      }

      testCaseMap[testCaseName].push({ cellNumber: cellNumbers[i], error: errors[i] });
    }

    // Push each testCaseName's values into the data array separately
    Object.keys(testCaseMap).forEach((testCaseName) => {
      let firstRowAdded = false;
      testCaseMap[testCaseName].forEach(({ cellNumber, error }, index) => {
        if ((cellNumber || error) && !firstRowAdded) {
          data.push({ testCaseName, cellNumber, error });
          firstRowAdded = true;
        } else if (index !== 0) {
          data.push({ cellNumber, error });
        }
      });
    });

    return data;
  };

  const renderTabs = () => {
    return (
      <div className="page-tab-with-actions">
        <ul className="navBar text-blue-600 flex flex-row bottom-space-sm -ml-2 repo-dev-tab-height">
          {tabsList.map((tab, index) => {
            if (tab.name === 'Scripts') {
              return (
                <span key={tab.selectedName}>
                  <span
                    className={
                      activeTab === tab.selectedName
                        ? 'flex flex-row p-2 pb-3 selected-repo-testdev-tab-style mr-4'
                        : 'flex flex-row p-2 pb-3 repo-testdev-tab-style mr-4'
                    }
                  >
                    <Link
                      className={
                        activeTab === tab.selectedName ? 'fontPoppinsSemiboldMd border-b-2 border-blue-700' : ''
                      }
                      to={tab.pathUrl}
                    >
                      {tab.name}
                    </Link>
                  </span>
                </span>
              );
            } else if (hasAutomation && hasAutomationBasedOnProjType) {
              return (
                <span key={tab.selectedName}>
                  <span
                    className={
                      activeTab === tab.selectedName
                        ? 'flex flex-row p-2 pb-3 selected-repo-testdev-tab-style mr-4'
                        : 'flex flex-row p-2 pb-3 repo-testdev-tab-style mr-4'
                    }
                  >
                    <Link
                      className={
                        activeTab === tab.selectedName ? 'fontPoppinsSemiboldMd border-b-2 border-blue-700' : ''
                      }
                      to={tab.pathUrl}
                    >
                      {tab.name}
                    </Link>
                  </span>
                </span>
              );
            }
          })}
        </ul>
        {isModalMinimize && isImportloader && (
          <div className=" import-progress-minimze-block  flex gap-4 justify-around items-center p-2 ml-auto -mr-96">
            <TooltipPoppin title="Manual test case import in progress">
              <div className="w-56">
                <ProgressBar
                  total={100}
                  filled={importProgressCount}
                  filledColor={colors?.rs_primary}
                  showPrecent={true}
                />
              </div>
            </TooltipPoppin>
            <div>
              <FireflinkLogo />
            </div>
            <div>
              <TooltipPoppin title="Expand">
                <ExpandIcon className="cursor-pointer" onClick={onExpandMiniModal} />
              </TooltipPoppin>
            </div>
          </div>
        )}

        {(hasAutomation || hasManual) && !contextMenuData?.every((value) => value === null) && (
          <div className="clearfix ml-auto">
            <Menu as="div" className="relative text-left">
              {({ open }) => (
                <>
                  <div className="ml-5 mr-1">
                    <Menu.Button>
                      <span className="sr-only">Open options</span>
                      <DotsVerticalIcon className="h-5 w-5 black" aria-hidden="true" />
                    </Menu.Button>
                  </div>

                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none  z-10"
                    >
                      <div className="py-1">
                        {contextMenuData.map(
                          (data) =>
                            data && (
                              <Menu.Item key={data.title}>
                                {({ active }) => (
                                  <label
                                    className={`block px-4 py-2 fontPoppinsRegularMd
                                                      ${active ? 'bg-gray-100 text-blue-700 ' : 'text-gray-700'}
                                                      ${
                                                        data.disabled
                                                          ? 'opacity-30 cursor-none text-gray-700'
                                                          : 'cursor-pointer'
                                                      }
                                                      `}
                                    onClick={() => {
                                      if (!data.disabled) {
                                        optionMethodsHandler(data.title);
                                      }
                                    }}
                                  >
                                    <Tooltip
                                      title={
                                        data.title === BULK_LABELING.UPDATE_LABEL_FOR_SCRIPTS && data.disabled
                                          ? BULK_LABELING.PLEASE_SELECT_SCRIPTS
                                          : ''
                                      }
                                    >
                                      {data.title}
                                    </Tooltip>
                                  </label>
                                )}
                              </Menu.Item>
                            )
                        )}
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="page-list-body">
      <div className="alert-position-style">
        <AlertContatiner></AlertContatiner>
      </div>
      {renderTabs()}
      <Switch>
        <Route path="/testdevelopment/Script" exact={true} strict={true} key="test-development-all-modules-page">
          <AllModulesTree
            {...props}
            importMTCData={importMTCDataReload}
            MyAlert={MyAlert}
            getManualTestcaseAvaliableStatus={getManualTestcaseAvaliableStatus}
            setImportMTCDataReload={setImportMTCDataReload}
            setSelectedScripts={setSelectedScripts}
          />
        </Route>
        <Route path="/testdevelopment/Execution Dashboard" exact={true} strict={true} key="execution-dashboard-page">
          <ExecutionDashboard {...props} MyAlert={MyAlert} />
        </Route>
        <Route
          path="/testdevelopment/Execution Dashboard/true"
          exact={true}
          strict={true}
          key="execution-dashboard-page"
        >
          <ExecutionDashboard {...props} MyAlert={MyAlert} />
        </Route>
      </Switch>
      {openUpdateLabel === true && (
        <UpdateLabelScripts
          isOpen={true}
          onClose={UpdateLabelsModal}
          selectedScripts={selectedScripts}
          MyAlert={MyAlert}
        />
      )}
      {downloadClientModal && <DownloadClientModal closeModal={closeModal} />}
      {showModal && (
        <CommonModalComponent
          isModalOpen={true}
          titleText="Import Manual Test Case"
          footerContent={renderFooterContent}
          handleClose={handleClose}
          classNames="p-3"
          removeCloseIcon={isImportloader}
        >
          {
            <div className="flex flex-col items-center justify-center">
              <div className="download-btn-style w-full text-right pb-2">
                <TooltipPoppin title={templatNameAndId?.templateName}>
                  <button
                    type="button"
                    className="primary-btn mt-2 mb-2 "
                    onClick={exportManualData}
                    disabled={isImportloader}
                  >
                    Download Template
                  </button>
                </TooltipPoppin>
              </div>

              <div className="w-full">
                {isImportloader && (
                  <div>
                    {isValidating ? (
                      <div className="flex flex-col items-center justify-center w-2/3 mx-auto gap-2">
                        <div className="flex gap-2 justify-center items-center fontPoppinsRegularXs animate-pulse">
                          Validation in Progress {isValidating}
                          <FireflinkLogo />
                        </div>
                        <div className="rs-primary fontPoppinsRegularSm">{files?.[0]?.name || files?.name}</div>
                      </div>
                    ) : (
                      <div className="flex flex-col items-center justify-center w-2/3 mx-auto gap-2">
                        <div className="fontPoppinsRegularXs">{importProgressCount} %</div>
                        <ProgressBar total={100} filled={importProgressCount} filledColor={colors?.rs_primary} />
                        <div className="flex gap-2 justify-center items-center fontPoppinsRegularXs">
                          Import in Progress {isValidating}
                          <FireflinkLogo />
                        </div>
                        <div className="rs-primary fontPoppinsRegularSm">{files?.[0]?.name || files?.name}</div>
                      </div>
                    )}
                  </div>
                )}
                {!isImportloader && !isValidating && (
                  <FilesDragAndDrop
                    onUpload={onUploadhandler}
                    count={1}
                    formats={Object.keys(IMPORT_MTC_FILE_ALLOWED)}
                    errorMessage={errorMessage}
                    emptyErrorMsg={emptyErrorMsg}
                    setResponseObjectFlag={setResponseObjectFlag}
                    clearData={unSupportedFile}
                    setUnSupportedFile={setUnSupportedFile}
                  />
                )}
              </div>

              {((!isEmptyValue(errorTableData) && files.length) > 0 ||
                (!isEmptyValue(errorTableData) && !isEmptyObject(files))) &&
                isTableVisible && (
                  <div className="MTC-table-wrapper w-full">
                    <div className="fontPoppinsRegularXs my-3 error-text">{MTC_ERROR_CONSTANTS.ERROR_NOTE_HEADER}</div>
                    <div className="table-section">
                      <table>
                        <thead className="fontPoppinsSemiboldXs thead-bg-color">
                          <tr align="left" className="table-header-text-color">
                            <td width="20%">Test Case Name</td>
                            <td width="50%">Error</td>
                            <td width="30%">Excel Sheet Cell Number</td>
                          </tr>
                        </thead>
                        <tbody className="fontPoppinsMediumXs">
                          {errorTableData.map((rowData, index) => (
                            <tr key={index} align="left">
                              <td className={rowData.testCaseName?.length && 'td-border-top-style'}>
                                {rowData.testCaseName}
                              </td>
                              <td className="td-border-style">{rowData.cellNumber}</td>
                              <td className="td-border-style">{rowData.error}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="fontPoppinsRegularXs mt-3 error-text">{MTC_ERROR_CONSTANTS.ERROR_NOTE_FOOTER}</div>
                  </div>
                )}
            </div>
          }
        </CommonModalComponent>
      )}
    </div>
  );
};

export default LandingPageRouter;

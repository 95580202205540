import React, { useState, useEffect, useRef, Fragment } from 'react';
import { usePagination, useTable, useGlobalFilter } from 'react-table';
import { NO_DATA_FOUND, defectAnalyticsLabel } from '@common/ui-constants';
import DefectLogFilter from './defect-log-filter';
import { isEmptyValue, scrollTop } from '@src/util/common_utils';
import SearchBar from '@pages/analytics/common/search-bar';
import { getAllDefectsList } from '@api/api_services';
import { TooltipPoppin, getScrollPosition } from '@pages/analytics/common/util';
import { ReactComponent as RsFilleddFilterIcon } from '@assets/analytics/rs-filter-filled.svg';
import { ReactComponent as FilterIcon } from '@assets/analytics/filter-icon.svg';
import BugSummaryDetails from '../modals/bug-summary-details';

import '@pages/analytics/Analytics.scss';

const AllDefectsTable = (props) => {
  const { defectsDb, reqBody, callAllApiOnFilterChange } = props;

  let [defectPayload, setDefectPayload] = useState({
    ...reqBody,
    facetQueries: [...reqBody.facetQueries],
  });
  let [data, setData] = useState([]);
  let [openFilterModal, setOpenFilterModal] = useState(false);
  let [selectedSeverity, setSelectedSeverity] = useState([]);
  let [selectedPriority, setSelectedPriority] = useState([]);
  let [selectedStatus, setSelectedStatus] = useState([]);
  let [selectedModule, setSelectedModule] = useState([]);
  let [isFilterApplied, setIsFilterApplied] = useState(false);

  //? States for scroll APi :: START
  let [initTable, setInitTable] = useState({
    from: 0,
    to: 20,
  });

  const allDefectsTableRef = useRef();
  const [tableScrollPos, setTableScrollPos] = useState(false);
  const [stopScrollApiCall, setStopScrollApiCall] = useState(0); //? to stop scroll api on no-content

  // TODO :: TO OPEN Defect Details MODAL BUG SUMMARY :: START

  let [selectedDefectID, setSelectedDefectID] = useState('');
  let [openBugSummaryModal, setOpenBugSummaryModal] = useState(false);
  let getBugSummary = (id) => {
    setSelectedDefectID(id);
    setOpenBugSummaryModal(true);
  };
  let closeBugSummaryModal = () => {
    setSelectedDefectID('');
    setOpenBugSummaryModal(false);
  };

  // TODO :: TO OPEN Defect Details MODAL BUG SUMMARY :: END

  let getDefectFilterData = async (params = { from: initTable.from, to: initTable.to }, firstCallPayload) => {
    try {
      let paylodParams = {
        defectPayload: firstCallPayload || defectPayload,
        from: params.from,
        to: params.to,
      };

      if (paylodParams.from === 0 && paylodParams.to === 20) {
        let Response = await getAllDefectsList(paylodParams.defectPayload, paylodParams.from, paylodParams.to);
        setData(Response?.data?.defectDetails);
        setStopScrollApiCall(Response?.data.totalDefects);
      } else if (paylodParams.from !== 0 && paylodParams.to !== 20 && stopScrollApiCall > paylodParams.from) {
        let Response = await getAllDefectsList(paylodParams.defectPayload, paylodParams.from, paylodParams.to);
        setData([...data, ...Response.data.defectDetails]);
        setStopScrollApiCall(Response?.data?.totalDefects);
      }
    } catch (err) {
      console.error('API ERROR IN LIST OF ALL DEFECTS ', err);
    }
  };

  // TODO ::NOTE:: when request body change based on side filters

  useEffect(() => {
    if (callAllApiOnFilterChange) {
      // TODO ::  reset State when filter are change ::  START
      setSelectedSeverity([]);
      setSelectedPriority([]);
      setSelectedStatus([]);
      setSelectedModule([]);
      localStorage.removeItem('defectFilter');
      setIsFilterApplied(false);
      setOpenFilterModal(false);
      setInitTable({
        from: 0,
        to: 20,
      });
      scrollTop(allDefectsTableRef);
      closeBugSummaryModal();
      // TODO ::  reset State when filter are change ::  END

      let params = {
        from: 0,
        to: 20,
      };
      getDefectFilterData(params, reqBody);
    }

    setDefectPayload(reqBody);
  }, [callAllApiOnFilterChange]);

  useEffect(() => {
    if (tableScrollPos) {
      setInitTable({
        from: initTable.to,
        to: initTable.to + 10,
      });
    }
  }, [tableScrollPos]);

  useEffect(() => {
    if (initTable.from !== 0 && initTable.to !== 20) {
      getDefectFilterData();
    }
  }, [initTable]);

  //? States for scroll APi :: END

  let columns = React.useMemo(
    () => [
      {
        Header: 'BUG ID',
        accessor: 'defectId',
        width: '20%',
        Cell: ({ row, value }) => {
          return (
            <div
              className="tabledata-trimmer rs-blue cursor-pointer"
              title={value}
              onClick={(_) => getBugSummary(value)}
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: 'BUG SUMMARY',
        accessor: 'summary',
        width: '60%',
        Cell: ({ value }) => {
          return (
            <div className="overflow-hidden overflow-ellipsis whitespace-nowrap w-11/12 " title={value}>
              {value}
            </div>
          );
        },
      },
      {
        Header: 'CREATED BY',
        accessor: 'createdBy',
        width: '20%',
        Cell: ({ value }) => {
          return <div className="overflow-hidden overflow-ellipsis whitespace-nowrap">{value}</div>;
        },
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, state, setGlobalFilter } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  );
  const { globalFilter } = state;

  // TODO :: Get List of defects by filter :: START

  let convertSpanLabelToText = (selectedList) => {
    return selectedList?.map((val) => {
      return { label: val.value, value: val.value };
    });
  };

  const handleApplyFilter = async (action = defectAnalyticsLabel.btnLabel.apply, segments) => {
    scrollTop(allDefectsTableRef);

    let params = {
      from: 0,
      to: 20,
    };

    if (action == defectAnalyticsLabel.btnLabel.apply) {
      // call ON APPLY BUTTON
      await getDefectFilterData(params);
      if (isFilterApplied && isEmptyValue(segments)) {
        localStorage.removeItem('defectFilter');
        setInitTable(params);
        setIsFilterApplied(false);
      } else {
        localStorage.setItem(
          'defectFilter',
          JSON.stringify([
            { name: 'Severity', values: convertSpanLabelToText(selectedSeverity) },
            { name: 'Priority', values: convertSpanLabelToText(selectedPriority) },
            { name: 'Status', values: convertSpanLabelToText(selectedStatus) },
            { name: 'Module', values: convertSpanLabelToText(selectedModule) },
          ])
        );
        setIsFilterApplied(true);
      }
    }

    if (action === defectAnalyticsLabel.btnLabel.clearAll) {
      // call ON CLEAR ALL BUTTON
      await getDefectFilterData(params, reqBody);
      localStorage.removeItem('defectFilter');
      setInitTable(params);
      setIsFilterApplied(false);
    }

    setOpenFilterModal(false);
    setOpenBugSummaryModal(false);
  };

  // TODO :: Get List of defects by filter :: END

  // TODO :: handle Search filter :: START
  let [openSearch, setOpenSearch] = useState(false);
  let handleSearchClose = () => {
    setOpenSearch(false);
    setGlobalFilter('');
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  // TODO :: handle Search filter :: END

  let openFilterWithAppliedItem = () => {
    if (isFilterApplied) {
      let getSelectedOptions = (paraName) => {
        const alreadySelectedFilters = JSON.parse(localStorage.getItem('defectFilter'));
        return alreadySelectedFilters.filter((val) => val.name == paraName);
      };
      setSelectedSeverity(getSelectedOptions('Severity')?.[0]?.values || []);
      setSelectedPriority(getSelectedOptions('Priority')?.[0]?.values || []);
      setSelectedStatus(getSelectedOptions('Status')?.[0]?.values || []);
      setSelectedModule(getSelectedOptions('Module')?.[0]?.values || []);

      setOpenFilterModal(true);
    } else {
      setOpenFilterModal(true);
    }
  };

  return (
    <div className="col-span-12 analytics-defects-table p-1">
      <div className="col-span-12 analytics-table-header fontPoppinsSemiboldSm px-3">
        <span title="List of All Defects" className="rs-blue fontPoppinsSemiboldSm uppercase ">
          {defectAnalyticsLabel.header.LIST_OF_ALL_DEFECTS}
        </span>
        <span className="float-right ">
          <span className="flex justify-center gap-1">
            <SearchBar
              open={openSearch}
              searchValue={globalFilter || ''}
              handleSearch={(e) => {
                setGlobalFilter(e.target.value);
              }}
              onSearchClose={handleSearchClose}
              handleSearchOpen={handleSearchOpen}
              disable={isEmptyValue(data)}
            />
            <div className="FilterAllDefectIcon relative ">
              <span className="expandIconContainer" onClick={openFilterWithAppliedItem}>
                <TooltipPoppin title={isFilterApplied ? 'Change Filters' : 'Filters'} placement="top">
                  {isFilterApplied ? (
                    <RsFilleddFilterIcon className="cursor-pointer filter-icon " />
                  ) : (
                    <FilterIcon className="cursor-pointer filter-icon " />
                  )}
                </TooltipPoppin>
              </span>
              {openFilterModal && (
                <DefectLogFilter
                  dataList={defectsDb?.defectChart}
                  defectPayload={defectPayload}
                  reqBody={reqBody}
                  setDefectPayload={setDefectPayload}
                  setOpenFilterModal={setOpenFilterModal}
                  selectedSeverity={selectedSeverity}
                  setSelectedSeverity={setSelectedSeverity}
                  selectedPriority={selectedPriority}
                  setSelectedPriority={setSelectedPriority}
                  selectedStatus={selectedStatus}
                  setSelectedStatus={setSelectedStatus}
                  selectedModule={selectedModule}
                  setSelectedModule={setSelectedModule}
                  onApply={handleApplyFilter}
                  setIsFilterApplied={setIsFilterApplied}
                  isFilterApplied={isFilterApplied}
                />
              )}
            </div>
          </span>
        </span>
      </div>

      <div className="col-span-12 analytics-table-container">
        <table {...getTableProps()} className="col-span-12 all-defects-list-table analytics-table ">
          <thead>
            {headerGroups?.map((headerGroup, index) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="fontPoppinsSemiboldSm analyticsTableHeaderText h-8 ml-4 uppercase"
                key={index}
              >
                {headerGroup.headers?.map((column, index) => (
                  <th
                    width={column.width}
                    {...column.getHeaderProps()}
                    className="text-left"
                    key={column.width + index}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody
            ref={allDefectsTableRef}
            onScroll={() => getScrollPosition(allDefectsTableRef, setTableScrollPos)}
            {...getTableBodyProps()}
            className="analytics-table-body"
          >
            {rows?.map((row) => {
              prepareRow(row);
              return (
                <Fragment key={row?.original?.defectId}>
                  <tr {...row.getRowProps()} className=" h-8">
                    {row.cells.map((cell) => {
                      return (
                        <td
                          width={cell.column.width}
                          {...cell.getCellProps()}
                          className="pl-1 text-left  analytics-table-text fontPoppinsMediumSm rs-text-black"
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                  {row?.original?.defectId === selectedDefectID && openBugSummaryModal && (
                    <tr>
                      <td colSpan={4}>
                        <div className="flex justify-between p-1 items-center accodrion-bug-summary-header">
                          <div className="fontPoppinsSemiboldSm  uppercase tableHead-trimmer " title={selectedDefectID}>
                            {defectAnalyticsLabel.header.BUG_SUMMARY}
                          </div>

                          <span
                            className="close-icon-x-white-bule"
                            onClick={(_) => setOpenBugSummaryModal(false)}
                          ></span>
                        </div>
                        <BugSummaryDetails selectedDefectID={selectedDefectID} isAccordion={true} />
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
        {(isEmptyValue(data) || isEmptyValue(rows)) && (
          <div className="analyticstable_message fontPoppinsMediumSm mt-10">{props?.message || NO_DATA_FOUND}</div>
        )}
      </div>
    </div>
  );
};

export default AllDefectsTable;

import React from 'react';

export default function Callback() {
  return (
    <div className="callback-container">
      <div className="fontPoppinsSemiboldXlg">Authentication Successful</div>
      <div className="fontPoppinsRegularMd">Redirecting...</div>
    </div>
  );
}

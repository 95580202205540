import righticon from '@assets/right-icon.svg';
import { ReactComponent as BackArrow } from '@assets/svg-imports/BackArrowOrange.svg';
import commonStyles from '@pages/auth/common.module.scss';
import '@pages/auth/login.css';
import { GLOBAL_CONSTANTS, EMAIL_SEND_CONSTANTS } from '@src/common/ui-constants';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import CommonPage from '../common/CommonPage';

export default function PasswordEmailSend(props) {
  const history = useHistory();
  const redirectToSignIn = () => {
    history.push('/signin');
  };
  return (
    <CommonPage marginTop={'mt-20'} wrapperClassname={`common-padding`}>
      <div className="flex justify-center flex-col items-center gap-4">
        <img alt="right icon" src={righticon} className="ml-auto mr-auto" />
        <div className={cx('text-center fontPoppinsSemibold-size-26 ', commonStyles['text-green'])}>
          {EMAIL_SEND_CONSTANTS.RESET_SENT_SUCCESSFULLY}
        </div>
        <div className="text-center fontPoppinsRegularMd width-97-per">
          {EMAIL_SEND_CONSTANTS.RESET_LINK_SENT_MESSAGE}
        </div>
        <button className={cx(commonStyles['btn-bg-white'], commonStyles['common-btn'])} onClick={redirectToSignIn}>
          <BackArrow />
          <span className="fontPoppinsSemiboldSm pl-4">{GLOBAL_CONSTANTS.BACK_TO_SIGN_IN}</span>
        </button>
      </div>
    </CommonPage>
  );
}

import { Tooltip } from '@material-ui/core';
import { SearchOutlined, InfoOutlined } from '@material-ui/icons';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { CommonLoader } from '@src/pages/common/common-loader';
import React, { useEffect, useState, useContext } from 'react';
import Modal from 'react-modal';
import {
  createDependsOnScriptsReq,
  getAllDependsOnScriptsReq,
  getDependsOnScriptsTreeReq,
  getSingleModuleTreeReq,
} from '../../../../../../api/api_services';
import TableTree from '../../../../../common/table_tree/table_tree';
import { getStepId } from '../../../../shared/common-methods';
import '../../../../test-development.scss';

Modal.setAppElement('#root');
function CreateDependsOnScripts(props) {
  let [selectedNodes, setSelectedNodes] = useState([]);
  let [orderOfInsertion, setOrderOfInsertion] = useState(1);
  let [dependsOnNodes, setDependsOnNodes] = useState([]);
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  const customStylesPage = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      height: '595px',
      background: '#fbfcfd 0% 0% no-repeat padding-box',
      boxShadow: '0px 0px 11px #000000a1',
      borderRadius: '4px',
      opacity: '1',
    },
  };
  const [openModal, setOpenModal] = useState(true);
  let [isLoading, setIsLoading] = React.useState(false);
  let renderTree;
  let [rootData, setRootData] = useState([]);
  let [treeData, setTreeData] = useState(null);
  let [isExpandAll, setIsExpandAll] = useState(false);
  let [treeValue, setTreeValue] = useState('');

  const colDefs = [
    {
      field: 'title',
      title: 'Modules',
      class: 'title',
      width: `calc(100% - 550px)`,
      folderTitleWidth: `calc(100% - 670px)`,
      isTitle: true,
      render: (nodeObj) => {
        return (
          <>
            {nodeObj.isRootNode && (
              <>
                <Tooltip title={`Modules - ${nodeObj.data.subModuleCount}`} placement="bottom">
                  <span className="folder-count count-badge"> M{nodeObj.data.subModuleCount} </span>
                </Tooltip>
                <Tooltip title={`Modules - ${nodeObj.data.subModuleCount}`} placement="bottom">
                  <span className="file-count count-badge"> S{nodeObj.data.scriptCount} </span>
                </Tooltip>
              </>
            )}
            {nodeObj.data.folder && (
              <>
                <Tooltip title={`Sub Modules - ${nodeObj.data.subModuleCount}`} placement="bottom">
                  <span className="file-count count-badge cursor-pointer"> SM{nodeObj.data.subModuleCount} </span>
                </Tooltip>
                <Tooltip title={`Scripts - ${nodeObj.data.scriptCount}`} placement="bottom">
                  <span className="file-count count-badge cursor-pointer"> S{nodeObj.data.scriptCount} </span>
                </Tooltip>
              </>
            )}
            {nodeObj.data._key === scriptId ? (
              <Tooltip title="You cannot add same script as dependency">
                <InfoOutlined fontSize="small" className="text-xs text-gray-500 hover:text-blue-700" />
              </Tooltip>
            ) : null}
          </>
        );
      },
    },
    {
      field: 'scriptType',
      title: 'Type',
      class: 'Type',
      width: '150px',
      render: (nodeObj) => {
        return (
          <Tooltip title={nodeObj?.data?.scriptType} placement="bottom">
            <span className="text-xs table-non-link-color-common">
              {nodeObj?.data?.scriptType?.length > 12
                ? nodeObj?.data?.scriptType?.substring(0, 12) + '...'
                : nodeObj?.data?.scriptType}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: 'State',
      title: 'State',
      class: 'state',
      width: '200px',
      render: (nodeObj) => {
        return <span className="text-xs table-non-link-color-common">Commit</span>;
      },
    },
  ];
  const LABELS = {
    EXPAND_ALL: 'Expand All',
    COLLAPSE_ALL: 'Collapse All',
    SHOW_ALL_ELEMENTS: 'Show all scripts',
    HIDE_ALL_ELEMENTS: 'Hide all scripts',
  };
  const handleCreate = () => {
    let requestBody = [];
    let i = orderOfInsertion;
    selectedNodes.forEach((ele, index) => {
      if (ele.node.folder === undefined) {
        requestBody.push({
          moduleId: ele.data.parentId,
          scriptId: ele.data._key,
          name: ele.data.title,
          executionOrder: i++,
          stepId: getStepId(),
          ifCheckPointIsFailed: 'MARK_THIS_SCRIPT_AS_FAILED_AND_CONTINUE_DEPENDANT_SCRIPT_EXECUTION',
        });
      }
    });
    createDependentScript(requestBody);
  };
  const createDependentScript = async (data) => {
    try {
      const response = await createDependsOnScriptsReq(moduleId, scriptId, data);
      if (response.data && response.data.responseObject && response.data.status === 'SUCCESS') {
        props.reload(true);
        props.closeModal(false);
        props.MyAlert.success(`${data[0].name} depends on script added successfully`);
      } else {
        props.closeModal(false);
        props.MyAlert.warning(`${response.data.message}`);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onCheckedNodes = (nodes) => {
    setSelectedNodes(nodes);
  };
  async function getDependsScripts(modId, scrId) {
    try {
      setIsLoading(true);
      const res = await getAllDependsOnScriptsReq(modId, scrId);
      if (res.data && res.data.responseObject && res.data.responseObject.length > 0) {
        let disableScripts = [];
        const insertionPoint = Number(res.data.responseObject[res.data.responseObject.length - 1].executionOrder) + 1;
        setOrderOfInsertion(insertionPoint);
        res.data.responseObject.forEach((depScripts, index) => {
          disableScripts.push({ key: 'key', value: depScripts.scriptId });
        });
        setDependsOnNodes(disableScripts);
      } else {
        setDependsOnNodes([]);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }
  async function getModuleTree(moduleId, scriptId) {
    setIsLoading(true);
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    let getdata;
    let root;

    try {
      let rootChildren = [];
      getdata = await getDependsOnScriptsTreeReq(moduleId, scriptId);
      stopProgress();
      let rootDetails = getdata.data.responseObject.moduleTree[0];
      root = {
        subModuleCount: rootDetails.subModuleCount,
        title: rootDetails.title,
        scriptCount: rootDetails.scriptCount,
        key: rootDetails.key,
      };
      if (rootDetails.children !== undefined && rootDetails.children && rootDetails.children) {
        rootChildren = rootDetails.children;
      } else {
        rootChildren = [];
      }
      setRootData(root);
      setIsExpandAll(rootDetails.expanded);
      setTreeData(rootChildren);
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 10,
      });
      setIsLoading(false);
    } catch (err) {
      resetProgress();
      console.error('GET_DEPENDS_ON_SCRIPT_TREE : ', err);
    }
  }
  if (isLoading) {
    renderTree = <CommonLoader />;
  } else if (treeData && treeData.length > 0) {
    renderTree = (
      <TableTree
        disableRootCheckbox={true}
        data={treeData}
        rootData={rootData}
        colDefs={colDefs}
        filter={treeValue}
        expandAll={isExpandAll}
        hideElements={false}
        hideConditions={false}
        showCheckbox={true}
        hideConditionBtn={true}
        defaultDisabled={[{ key: 'folder', value: true }, ...dependsOnNodes]}
        conditions={[]}
        onCheckedNodes={onCheckedNodes}
        onNodeSelected={() => {}}
        labels={LABELS}
        id="create-depends-on-scripts"
      />
    );
  } else {
    renderTree = (
      <div className="">
        <TableTree
          data={[]}
          rootData={rootData}
          colDefs={colDefs}
          filter={treeValue}
          expandAll={isExpandAll}
          hideElements={false}
          hideConditions={false}
          showCheckbox={true}
          hideConditionBtn={true}
          defaultDisabled={dependsOnNodes}
          conditions={[]}
          onCheckedNodes={onCheckedNodes}
          onNodeSelected={() => {}}
          labels={LABELS}
          id="create-depends-on-scripts"
        />
        <div className="fontPoppinsRegularMd no-steps">
          <p className="">There are no scripts which can be added as depends on script</p>
        </div>
      </div>
    );
  }
  useEffect(() => {
    setTreeData([]);
    getModuleTree(moduleId, scriptId);
    getDependsScripts(moduleId, scriptId);
  }, [moduleId, scriptId]);
  return (
    <Modal
      isOpen={openModal}
      style={customStylesPage}
      className="p-px"
      onRequestClose={() => {
        props.closeModal(false);
        setOpenModal(false);
      }}
    >
      <div className="mt-2 p-1">
        <span className="Popup-header-common pl-5"> Add Depends On Scripts</span>
        <div className="float-right">
          {/* <Tooltip title="Search">
                        <SearchOutlined className="text-gray-500 pb-px" />
                    </Tooltip> */}
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="ml-5 mr-5 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="border-b border-t border-blue-100 mt-2 mb-2">
        <div className="ml-5 mr-5 mt-10">
          <div className="overflow-y-auto" style={{ height: '366px', minWidth: '800px' }} id="journal-scroll">
            {renderTree}
          </div>
        </div>
        <div className="float-right mt-3.5 mb-3 mr-5">
          <button onClick={() => props.closeModal(false)} type="button" className="secondary-btn">
            Cancel
          </button>
          <button
            disabled={selectedNodes.length === 0}
            type="button"
            onClick={handleCreate}
            className="ml-4 primary-btn"
          >
            Add
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default CreateDependsOnScripts;

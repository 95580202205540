import React, { useState, useEffect, useContext } from 'react';
import './parallel_run_status.scss';
import CommonDrawerJs from '@src/common/atoms/CommonDrawer';
import CommonTableWithSearch from '../common_table_with_search/common_table_with_search';
import MachineDetailsLandingPage from './machine_details_landing_page';
import syncIcon from '@assets/syncIcon.svg';
import { getOnGoingExecutionReq, terminateExecutionSuiteReq } from '@api/api_services';
import { Tooltip } from '@mui/material';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { useLocation } from 'react-router-dom';
import { beforeRunningStatuses } from '@pages/test-development/execution-dashboard/execution-dashboard-util';
import TerminateWarningComponent from '@pages/test-development/execution-dashboard/modals/terminate-warning-component';

const ParallelRunExecutionStatus = (props) => {
  const location = useLocation();
  const [showParentModal, setShowParentModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedModalType, setSelectedModalType] = useState('');
  const [executionData, setExecutionData] = useState([]);
  const [selectedClientObj, setSelectedClientObj] = useState();
  const [loggedInUser, setLoggedInUser] = useState();
  const [userObject, setUserObject] = useState();
  const [licenseName, setLicenseName] = useState();
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const [parallelRunOccupied, setParallelRunOccupied] = useState(0);
  const [numberOfParallelRuns, setNumberOfParallelRuns] = useState(0);
  const [beforeRunningStatusLocal, setBeforeRunningStatusLocal] = useState([...beforeRunningStatuses, 'Running']);
  let [showTerminateModal, setshowTerminateModal] = useState(false);
  let [selectedExecutionObj, setSelectedExecutionObj] = useState();

  const columns = React.useMemo(
    () => [
      {
        Header: <span className="pl-3">EXECUTION ID</span>,
        accessor: '_id',

        width: '20%',
      },
      {
        Header: <span className="pl-3 ">EXECUTION NAME</span>,
        accessor: 'name',
        width: '20%',
        Cell: (e) => {
          return (
            <div className="flex flex-row">
              <Tooltip title={e?.data[e?.cell?.row?.id]?.name || ''} placement="top">
                <span className="suite_badge_width"> {e?.data[e?.cell?.row?.id]?.name || ''} </span>
              </Tooltip>
              {e?.data[e?.cell?.row?.id]?.type?.toLowerCase() === 'suite' && (
                <Tooltip title={`Suite : ${e?.data[e?.cell?.row?.id]?.name}`} placement="right">
                  <span className="badge-font-style inline-flex items-center justify-center w-4 h-4 mr-2 ml-2  bg-blue-700 rounded-full">
                    s
                  </span>
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        Header: <span className="pl-3 ">PROJECT</span>,
        accessor: 'projectName',
        width: '20%',
        Cell: ({ value }) => {
          return (
            <Tooltip title={value} placement="top">
              <div className="suite_badge_width">{value}</div>
            </Tooltip>
          );
        },
      },
      {
        Header: <span className="pl-3 ">MACHINE NAME</span>,
        accessor: 'selectedMachines',
        width: '20%',
      },
      {
        Header: <span className="pl-3 ">INITIATED BY</span>,
        accessor: 'createdByUname',
        width: '25%',
        Cell: (e) => {
          return (
            <Tooltip title={e?.cell?.row?.original?.createdByUname} placement="top">
              <span
                className="block table_content_text_style_hyperlink cursor suite_badge_width"
                onClick={(event) => {
                  event.preventDefault();
                  ShowRunInitiatedByDetails(e?.cell?.row?.original?.createdByUname, e?.cell?.row?.original?.createdBy);
                }}
              >
                {e?.cell?.row?.original?.createdByUname}
              </span>
            </Tooltip>
          );
        },
      },
      {
        Header: <span className="pl-3 ">STATUS</span>,
        accessor: 'executionStatus',
        width: '20%',
        Cell: ({ value }) => {
          return (
            <div className="block table_content_text_style ">
              {value?.toLowerCase() === 'pending' ? 'Queued' : value}
            </div>
          );
        },
      },
      {
        Header: <span className="pl-3 ">ACTIONS</span>,
        accessor: 'actions',
        width: '20%',
      },
    ],
    []
  );

  useEffect(() => {
    setShowParentModal(false);
    setShowDetailsModal(false);
    setSelectedModalType('');
  }, [location.pathname]);

  useEffect(() => {
    getOngoingExecutionReqFunction();
  }, []);

  function terminateExecution(exeId, name) {
    terminateExecutionSuiteReq(exeId).then((results) => {
      const response = results?.data?.message;
      selectedExecutionObj.executionStatus = 'Terminated';
      let tempExeArray = [...executionData];
      if (tempExeArray?.length) {
        tempExeArray?.map((obj) => {
          if (obj?._id === exeId) {
            obj.executionStatus = 'Terminated';
            delete obj['actions'];
          }
        });
      }
      setExecutionData([...tempExeArray]);
    });
  }

  const getOngoingExecutionReqFunction = () => {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    setIsLoading(true);
    getOnGoingExecutionReq()
      .then((res) => {
        stopProgress();
        let tempUserObj = JSON.parse(localStorage.getItem('test-optimize-user'));
        setLoggedInUser(tempUserObj?.id);
        setLicenseName(tempUserObj?.licenseName);

        if (res.data.responseObject) {
          if (res?.data?.responseObject?.length) {
            res?.data?.responseObject?.map((resObj) => {
              let tempArray = resObj?.preExecution?.selectedSystem;
              let tempArraySpanArray = [];
              tempArray?.map((obj) => {
                obj?.machineInstances?.map((values, index) => {
                  tempArraySpanArray.push(
                    <Tooltip title={values?.machineInfo?.hostName} placement="top">
                      <span
                        className={`block table_content_text_style_hyperlink cursor suite_badge_width`}
                        onClick={() => ShowMachineDetails(resObj, obj, index)}
                      >
                        {values?.machineInfo?.hostName}
                      </span>
                    </Tooltip>
                  );
                });
              });

              resObj['selectedMachines'] = [...tempArraySpanArray];
              resObj['executionEitableStatus'] = <span>{resObj?.executionStatus}</span>;
              if (tempUserObj?.id === resObj?.createdBy && beforeRunningStatusLocal.includes(resObj?.executionStatus)) {
                resObj['actions'] = (
                  <span>
                    <Tooltip title="Terminate" placement="top">
                      <img
                        className="mr-3 show_action cursor"
                        src="\assets\execution_logos\terminate.svg"
                        alt="Terminate Execution"
                        onClick={() => {
                          setshowTerminateModal(true);
                          setSelectedExecutionObj(resObj);
                        }}
                      />
                    </Tooltip>
                  </span>
                );
              }
            });
            setExecutionData([...res?.data?.responseObject]);
          }
        } else {
          setExecutionData([]);
        }
        if (res?.data?.responseMap) {
          setParallelRunOccupied(res.data?.responseMap.parallelRunsOccupied);
          setNumberOfParallelRuns(res.data?.responseMap.numberOfParallelRuns);
        }
        resetProgress();
        setIsLoading(false);
      })
      .catch((error) => {
        resetProgress();
        setIsLoading(false);
      });
  };

  const showDetails = () => {
    setShowParentModal(true);
  };

  const ShowRunInitiatedByDetails = (userName, userId) => {
    let tempObj = {
      userName: userName,
      userId: userId,
    };
    setUserObject({ ...tempObj });
    setShowDetailsModal(true);
    setSelectedModalType('user');
  };

  const ShowMachineDetails = (exeObj, clientObj, index) => {
    setShowDetailsModal(true);
    setSelectedModalType('device');
    setSelectedClientObj(clientObj?.machineInstances[index]);
    let tempObj = {
      userName: exeObj?.createdByUname,
      userId: exeObj?.createdBy,
    };
    setUserObject({ ...tempObj });
  };

  return (
    <>
      {showParentModal && (
        <CommonDrawerJs
          isDrawerOpen={true}
          headerContent={`Ongoing executions - ${licenseName}`}
          drawerWidth="850px"
          rightButtonText="Close"
          isLeftButtonVisible={false}
          onDrawerClose={() => {
            setShowParentModal(false);
          }}
          onRightButtonClick={() => {
            setShowParentModal(false);
          }}
        >
          <CommonTableWithSearch
            columns={columns}
            data={executionData}
            onSyncBtnClick={getOngoingExecutionReqFunction}
            header="EXECUTION DETAILS"
            message="No Ongoing Executions"
            compSpecificClassName="exe-table-height"
          />
        </CommonDrawerJs>
      )}
      <div className="mt-1.5">
        <label className="parallel_run_status_label_style">
          <div className="inline-flex rounded-md shadow-sm " role="group">
            <button
              type="button"
              onClick={getOngoingExecutionReqFunction}
              className={`${
                props?.fromModal ? 'button_group_btn_style_above_modal' : 'button_group_btn_style '
              } rounded-l-lg cursor`}
            >
              <img src={syncIcon} alt="Sync" width="24px" height="24px" />
            </button>
            <button
              type="button"
              className={`${
                props?.fromModal ? 'button_group_btn_style_above_modal' : 'button_group_btn_style '
              } rounded-r-md cursor`}
              onClick={showDetails}
            >
              {`${parallelRunOccupied} Running Of ${numberOfParallelRuns} Parallel Runs`}
            </button>
          </div>
        </label>
      </div>

      {showDetailsModal && (
        <MachineDetailsLandingPage
          selectedModalType={selectedModalType}
          setShowDetailsModal={setShowDetailsModal}
          showDetailsModal={showDetailsModal}
          setShowParentModal={setShowParentModal}
          setSelectedModalType={setSelectedModalType}
          selectedClientObj={selectedClientObj}
          userObject={userObject}
          loggedInUser={loggedInUser}
          executionData={executionData}
          licenseName={licenseName}
        />
      )}
      {showTerminateModal && (
        <TerminateWarningComponent
          executionData={selectedExecutionObj}
          setShowWarningModal={setshowTerminateModal}
          terminateExecution={terminateExecution}
          actionKey="Terminate"
        />
      )}
    </>
  );
};

export default ParallelRunExecutionStatus;

import React, { useState } from 'react';
import { Drawer, Select } from 'fireflink-ui';
import MultiAutocomplete from './modals/labels-multiselect-dropdown';
import './updateLabel.scss';
import { useFormik } from 'formik';
import { updateBulkLabeling } from '@src/api/api_services';
import { BULK_LABELING } from '@src/common/ui-constants';

const UpdateLabelScripts = (props) => {
  const initialValues = {
    action: '',
  };
  const formikDetails = useFormik({
    initialValues,
  });

  const [labelState, setLabelState] = useState({
    selectedOption: null,
    labelSelectedOptions: [],
    resetLabels: false,
  });
  const [labelResponseObject, setLabelResponseObject] = useState([]);
  const actionList = [
    {
      name: BULK_LABELING.ADD_LABEL,
    },
    {
      name: BULK_LABELING.REPLACE_ALL,
    },
    {
      name: BULK_LABELING.CLEAR_LABELS,
    },
    {
      name: BULK_LABELING.FIND_AND_REMOVE_LABELS,
    },
  ];

  const handlePermission = (event) => {
    setLabelState((prevState) => ({
      ...prevState,
      resetLabels: true,
      selectedOption: event.value,
    }));
    formikDetails.setFieldValue('action', event.value);
  };

  const labelDataPassToParent = (labelData) => {
    setLabelState((prevState) => ({
      ...prevState,
      labelSelectedOptions: labelData,
    }));
  };

  const projectLabelsObject = [];
  labelState.labelSelectedOptions?.forEach((labelValue) => {
    labelResponseObject?.forEach((objectValue) => {
      if (objectValue.name === labelValue) {
        projectLabelsObject.push(objectValue);
      }
    });
  });

  const selectedScripts = props.selectedScripts;
  const getSelectedScriptsId = (selectedScripts) => {
    const ids = [];
    for (const data of selectedScripts) {
      let testCasesIds = data?.data?.testCaseType;
      if (testCasesIds) {
        for (const dta of testCasesIds) {
          ids.push(dta.id);
        }
      }
    }
    return ids;
  };
  const scriptIds = getSelectedScriptsId(selectedScripts);
  const handleUpdateLabel = () => {
    let payload = {
      scriptIds: scriptIds,
      projectLabels: projectLabelsObject,
    };
    const action = labelState.selectedOption;
    updateBulkLabeling(payload, action)
      .then((response) => {
        if (response?.data?.responseCode === 200) {
          props.onClose();
          props.MyAlert.success(response.data.message);
          props.reloadTree();
        }
      })
      .catch((error) => {
        console.error('BULK LABELING UPDATE FAILED!', error);
      });
  };
  const isDisabled =
    formikDetails?.values?.action === '' ||
    (labelState.selectedOption !== 'Clear Labels' && projectLabelsObject && projectLabelsObject.length === 0);

  const updateButtonProps = {
    label: BULK_LABELING.UPDATE,
    disabled: isDisabled,
    onClick: handleUpdateLabel,
  };
  const cancelButtonProps = {
    label: BULK_LABELING.CANCEL,
    onClick: props.onClose,
  };
  return (
    <Drawer
      isOpen={true}
      onClose={props.onClose}
      overlay={true}
      title={BULK_LABELING.UPDATE_LABEL_FOR_SCRIPTS}
      primaryButtonProps={updateButtonProps}
      secondaryButtonProps={cancelButtonProps}
    >
      <div className="w-full flex flex-col ">
        <div className="w-full ">
          <Select
            name="action"
            onChange={handlePermission}
            options={actionList.map((data) => ({
              label: data.name,
              value: data.name,
            }))}
            labelProps={{
              label: 'Actions',
              required: true,
            }}
            value={formikDetails.values.action}
            selectedOption={
              labelState.selectedOption ? { label: labelState.selectedOption, value: labelState.selectedOption } : null
            }
            placeholder={BULK_LABELING.SELECT_THE_ACTIONS}
            className="fontPoppinsRegularSm"
          />
        </div>
        <span className="mt-5 update-label-script-field">
          <MultiAutocomplete
            labelSelectedOptions={labelDataPassToParent}
            labelResponse={setLabelResponseObject}
            type="bulkLabel"
            action={labelState.selectedOption}
            resetLabels={labelState.resetLabels}
            setResetLabels={(reset) => setLabelState((prevState) => ({ ...prevState, resetLabels: reset }))}
          />
        </span>
      </div>
    </Drawer>
  );
};
export default UpdateLabelScripts;

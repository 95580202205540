import React from 'react';
import MyTable from '../../licence_management/shared/Table';
import {
  getProjectPermissionSections,
  getUpdatedRoleName,
  getCurrentProjectType,
  getLicenseFeatures,
  getAutomationProjectTypes,
  getPrivilege,
} from '@util/common_utils';

const RoleDetailsPage = (props) => {
  const licenseFeatures = getLicenseFeatures();
  const hasCBasic = window.privilege.hasCBasic(licenseFeatures);
  const hasDefects = window.privilege.hasDefects(licenseFeatures);
  const hasManualTestCase = window.privilege.hasManualTestCase(licenseFeatures);

  let purchasedProjectsType = getAutomationProjectTypes(getPrivilege());
  let Individualprojectype = getCurrentProjectType();
  let isProjectPurchaseInAutomation = purchasedProjectsType?.includes(Individualprojectype);

  let permissionFieldsData = [];
  const displayProjectPermissionSections = getProjectPermissionSections();
  displayProjectPermissionSections?.filter((allowedRole) => {
    if (Individualprojectype) {
      if (
        (!isProjectPurchaseInAutomation && allowedRole?.name !== 'repository') ||
        (hasManualTestCase && allowedRole?.name !== 'repository') ||
        (hasDefects && allowedRole?.name !== 'repository')
      ) {
        permissionFieldsData.push({
          repoName: getUpdatedRoleName(allowedRole?.name),
          value: allowedRole?.name,
        });
      } else if (isProjectPurchaseInAutomation || hasCBasic) {
        permissionFieldsData.push({
          repoName: getUpdatedRoleName(allowedRole?.name),
          value: allowedRole?.name,
        });
      }
    } else {
      permissionFieldsData.push({
        repoName: getUpdatedRoleName(allowedRole?.name),
        value: allowedRole?.name,
      });
    }
    return null;
  });

  const result = props.roleDetails;
  let permissionFieldArrayForDisplay = [];
  permissionFieldsData.map((perVal) => {
    if (perVal.repoName !== 'Analytics') {
      const tempObj = {
        repoName: perVal.repoName,
        value: result?.tabPermissions[perVal.value],
      };
      permissionFieldArrayForDisplay.push(tempObj);
    }

    return null;
  });

  const data = React.useMemo(
    () =>
      permissionFieldArrayForDisplay.map((val) => ({
        repoName: (
          <span className="text-gray-700 cursor-pointer" style={{ color: '#525252' }}>
            {' '}
            {val.repoName}{' '}
          </span>
        ),
        value: (
          <span className="text-gray-700" style={{ color: '#525252' }}>
            {' '}
            {val.value}{' '}
          </span>
        ),
      })),
    []
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Sections',
        accessor: 'repoName',
        width: '30%',
      },
      {
        Header: 'Permission',
        accessor: 'value',
        width: '20%',
      },
    ],
    []
  );
  return (
    <>
      <div
        id="journal-scroll"
        style={{
          maxHeight: '400px',
          overflowY: 'auto',
          background: '#FBFCFD;',
        }}
      >
        <div className="dashed_border pb-4 m-3.5">
          <div className="grid grid-cols-3  mt-2 pb-3 ">
            <div>
              <label htmlFor="userName" className="block text-gray-700 sm:mt-px sm:pt-2 details-key-style-common">
                Name
              </label>
              <label
                className="details-data-style-common"
                title={result?.name}
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  maxWidth: '150px',
                  float: 'left',
                }}
              >
                {result?.name}
              </label>
            </div>
            <div className="overflow-hidden-common">
              <label htmlFor="emailId" className="block text-gray-700 sm:mt-px sm:pt-2 details-key-style-common">
                Description
              </label>
              {result?.desc ? (
                <label
                  className="details-data-style-common"
                  title={result?.desc}
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    maxWidth: '150px',
                    float: 'left',
                  }}
                >
                  {result?.desc ? result?.desc : <span>--</span>}
                </label>
              ) : (
                <label className="details-data-style-common">--</label>
              )}
            </div>
            <div>
              <label htmlFor="userName" className="block text-gray-700 sm:mt-px sm:pt-2 details-key-style-common">
                Created By
              </label>
              <label
                className="details-data-style-common"
                title={result?.createdByUname}
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  maxWidth: '150px',
                  float: 'left',
                }}
              >
                {result?.createdByUname}
              </label>
            </div>
          </div>
          <div className="grid grid-cols-3  mt-2 pb-3 ">
            <div>
              <label htmlFor="userName" className="block text-gray-700 sm:mt-px sm:pt-2 details-key-style-common">
                Created On
              </label>
              <label className="details-data-style-common">{result?.createdOn}</label>
            </div>
            <div>
              <label htmlFor="userName" className="block text-gray-700 sm:mt-px sm:pt-2 details-key-style-common">
                Modified By
              </label>
              <label
                className="details-data-style-common"
                title={result?.modifiedByUname}
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  maxWidth: '150px',
                  float: 'left',
                }}
              >
                {result?.modifiedByUname}
              </label>
            </div>
            <div>
              <label htmlFor="userName" className="block text-gray-700 sm:mt-px sm:pt-2 details-key-style-common">
                Modified On
              </label>
              <label className="details-data-style-common">{result?.modifiedOn}</label>
            </div>
          </div>
        </div>
        <div className="m-3.5 role_permission_style2" style={{ marginLeft: '18px' }}>
          <label className="mt-4 ml-2 Popup-header-common text-blue-600">Project Permission</label>
          <div className="mt-2 userRoleMapping">
            <MyTable data={data} columns={columns} tableHeightClass={'modal-table-height'} />
          </div>
        </div>
      </div>
    </>
  );
};

export default RoleDetailsPage;

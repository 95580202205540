import { Modal, TextField, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import DropdownTree from '../../common/dropdown_tree';
import { downloadFileFromCloud, renameFileCloudReq } from '../../../api/api_services';
import { useAlert } from '../../common/alert_service/useAlert';

function EditFile(props) {
  const { AlertContatiner, MyAlert } = useAlert();
  let initialValues;

  if (props.data) {
    initialValues = {
      //fileName: props.data.name.slice(0, props.data.name.indexOf(".")),
      fileName: props.data.name,
      parentName: props.data?.parentName,
    };
  }

  const validationSchema = yup.object({
    fileName: yup.string().required('Name is required').min(2, 'Name must be minimun 2 character'),
  });
  const [open, setModal] = useState(true);
  const formikDetails = useFormik({
    initialValues,
    validationSchema,
  });

  const download = () => {
    downloadFileFromCloud(props.data.id).then((d) => {
      saveFileFromBlob(d.data, props.data.name);
    });
  };

  function saveFileFromBlob(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  }

  const renameFile = () => {
    const data = {
      name: formikDetails.values.fileName,
    };
    renameFileCloudReq(props.data.id, data).then((results) => {
      if (results.data) {
        if (results.data.responseCode === 200) {
          props.closeModal(false);
          setModal(false);
          props.updatedValue(data.name, 'file');
          props.MyAlert.success(`${props.data.name} file Updated successfully`);
        } else if (results.data.responseCode === 400) {
          MyAlert.info(`${results.data.message}`);
        }
      }
    });
  };
  return (
    <div>
      <Modal open={open} className="modal-dialog">
        <div className="modal-container editFileModal">
          <div className="modal-header">
            <h1 className="">
              <div className="title title-trim">Edit File - {props.data.name}</div>
            </h1>
            <IconButton
              component="span"
              className="close"
              onClick={() => {
                props.closeModal(false);
                setModal(false);
              }}
            >
              <img
                style={{
                  cursor: 'pointer',
                }}
                src="/assets/images/close_black.svg"
                alt=""
                height="25px"
                width="25px"
              />
            </IconButton>
          </div>
          <div className="modal-body">
            <AlertContatiner />
            <div>
              <div className="grid ">
                <div className=" col-span-2 sm:col-span-2 md:col-span-2">
                  <div className="">
                    <div className="">
                      <div>
                        <TextField
                          size="40"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={formikDetails.errors.fileName && formikDetails.touched.fileName}
                          className="w-1/2"
                          type="text"
                          name="fileName"
                          id="fileName"
                          placeholder={`Enter File name`}
                          onBlur={formikDetails.handleBlur}
                          onChange={formikDetails.handleChange}
                          value={formikDetails.values.fileName}
                          label={
                            <>
                              {' '}
                              <span className="text-red-400">&#42;</span>Name
                            </>
                          }
                        />
                        {formikDetails.errors.fileName && formikDetails.touched.fileName ? (
                          <div className="errorMessage">{formikDetails.errors.fileName}</div>
                        ) : null}
                        <div className="inline pt-2 ml-4">
                          <Tooltip title="Download File" placement="bottom-start">
                            <img
                              onClick={download}
                              className="inline"
                              style={{
                                cursor: 'pointer',
                                marginTop: '10px',
                              }}
                              src="/assets/images/file_download.svg"
                              alt=""
                              height="25px"
                              width="25px"
                            />
                          </Tooltip>
                          <Tooltip title="Replace File" placement="bottom-start">
                            <img
                              style={{
                                cursor: 'pointer',
                              }}
                              className="inline ml-7 mt-3"
                              src="/assets/images/replace_File.svg"
                              alt=""
                              height="25px"
                              width="25px"
                            />
                          </Tooltip>
                        </div>
                      </div>
                      {formikDetails.errors.fileName && formikDetails.touched.fileName ? (
                        <div className="errorMessage">{formikDetails.errors.fileName}</div>
                      ) : null}
                    </div>
                    <div className="mt-8">
                      <div>
                        <div className="mt-2 text-sm text-blue-700">
                          <DropdownTree
                            initalValue={props.parentName}
                            showOnlyFolders={true}
                            operation={'edit'}
                            noDataMessage="Folder not found."
                            placeholder="Search and select parent Folder"
                            labelValue={'Parent Folder'}
                            treeData={props.treeData ? props.treeData : []}
                          />
                        </div>
                      </div>
                      {formikDetails.errors.parentPage &&
                      formikDetails.touched.parentPage &&
                      formikDetails.values.parentPage === '' ? (
                        <div className="errorMessage">{formikDetails.errors.parentPage}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="cancel-button-common cancel-button-layout-style-project mr-3"
              onClick={() => {
                props.closeModal(false);
                setModal(false);
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="create-button-style-common text-white create-button-layout-style-project"
              onClick={() => {
                if (formikDetails.values.fileName !== '' && formikDetails.values.fileName.length >= 2) {
                  renameFile();
                }
              }}
            >
              <span>Update</span>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default EditFile;

import { Modal } from '@material-ui/core';
import React from 'react';
import { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// import OnShowEditor from "../code_Editor/Js/Ace_Editor";
import { trimPath } from '../../../../../common/ui-constants';

function DetailsModalProgramElement(props) {
  const [showModal, setShowModal] = useState(true);
  const [screenType, SetscreenType] = useState('Detailpopup');
  const [codeModalValue, setcodeValue] = useState('');
  useEffect(() => {
    setcodeValue(props.data.code);
  }, [props.data.code]);
  let project = JSON.parse(localStorage.getItem('selected-project'));
  let path = trimPath(props.data?.path);

  return (
    <div>
      <Modal
        open={showModal}
        onRequestClose={() => {
          setShowModal(false);
          props.closeModal(false);
        }}
        className="modal-dialog"
      >
        <div className="modal-container program-element-details-size">
          <div className="modal-header ">
            <h2 className="title program-element-title-trim" title={props.data.desc}>
              Program Element Details - {props.data.desc}
            </h2>
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                props.closeModal(false);
                setShowModal(false);
              }}
            >
              <img
                style={{
                  cursor: 'pointer',
                }}
                src="/assets/images/close_black.svg"
                alt=""
                height="25px"
                width="25px"
              />
            </IconButton>
          </div>
          <div className="modal-body " id="journal-scroll">
            <div className="grid-flex-wrp grid-full-height">
              <div className="grid-details">
                <div className="grid grid-cols-3 gap-4">
                  <div className="">
                    <div className=" mt-2 ml-5">
                      <h1 className="details-key-style-common">NLP Description</h1>
                      <p className="pt-2 text-gray-700">
                        <div
                          className="details-data-style-common name-trimmer"
                          title={props.data.desc ? props.data.desc : '--'}
                        >
                          {props.data.desc ? props.data.desc : '--'}
                        </div>
                      </p>
                    </div>
                  </div>
                  <div className="">
                    <div className="mt-2 ml-12">
                      <h1 className="details-key-style-common">Class Name</h1>
                      <p className="pt-2 text-gray-700">
                        <div
                          className="details-data-style-common name-trimmer"
                          title={props.data.name ? props.data.name : '--'}
                        >
                          {props.data.name ? props.data.name : '--'}
                        </div>
                      </p>
                    </div>
                  </div>{' '}
                  <div className="">
                    <div className=" mt-2 ml-12">
                      <h1 className="details-key-style-common">Project Name</h1>
                      <p className="pt-2 text-gray-700">
                        <div className="details-data-style-common project-name-trimmer" title={project.name}>
                          {project.name}
                        </div>
                      </p>
                    </div>
                  </div>{' '}
                </div>
                <div className="grid grid-cols-3">
                  <div className="ml-5 mt-10">
                    <div className=" ">
                      <h1 className="details-key-style-common">Path</h1>
                      <p className="pt-2 text-gray-700">
                        <div className="details-data-style-common path-trimmer" title={path ? path : '--'}>
                          {path ? path : '--'}
                        </div>
                      </p>
                    </div>
                  </div>
                  <div className="">
                    <div className="">
                      <div className="mt-8 ml-14 ">
                        <h1 className="details-key-style-common">Created By</h1>
                        <p className="pt-2 text-gray-700">
                          <span className="details-data-style-common">
                            {props.data.createdByUname ? props.data.createdByUname : '--'}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="mt-8 ml-14">
                      <h1 className="details-key-style-common">Created On</h1>
                      <p className="pt-2 text-gray-700">
                        <span className="details-data-style-common">
                          {props.data.createdOn ? props.data.createdOn : '--'}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-3">
                  <div className="">
                    <div className="mt-8 ml-5 ">
                      <h1 className="details-key-style-common">Modified By</h1>
                      <p className="pt-2 text-gray-700">
                        <span className="details-data-style-common">
                          {props.data.modifiedByUname ? props.data.modifiedByUname : '--'}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="">
                    <div className="mt-8 ml-14 ">
                      <h1 className="details-key-style-common">Modified On</h1>
                      <p className="pt-2 text-gray-700">
                        <span className="details-data-style-common">
                          {props.data.modifiedOn ? props.data.modifiedOn : '--'}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <hr className="divider-program-editor mt-5 mb-5 " />
              <div className="editorRole">
                <OnShowEditor
                  codeModalValue={codeModalValue}
                  screenType={screenType}
                />
              </div> */}
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="gray-btn mr-4"
              onClick={() => {
                props.closeModal(false);
                setShowModal(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default DetailsModalProgramElement;

import React from 'react';
import { withRouter } from 'react-router';
import TestDevelopmentRouter from './test-development-router';

function TestDevelopmentRoot(props) {
  return (
    <div className="page-container test-developement">
      <TestDevelopmentRouter />
    </div>
  );
}

export default withRouter(TestDevelopmentRoot);

import React from 'react';
import { Toggle } from 'fireflink-ui';
import { TEST_DEV } from '@common/ui-constants';

const ManualToggleButton = ({ isToggled, handleToggle, disabled }) => {
  return (
    <div>
      <section className="h-16 ">
        <div className="fontPoppinsMediumMd pl-3 common-label-text w-max pb-2">
          <span className="text-red-400">*</span>
          <span>{TEST_DEV.MANUAL_TOGGLE_BUTTON}</span>
        </div>
        <div className="pl-2">
          <Toggle
            id={`toggles`}
            type="withLabel"
            variant="primary"
            checked={isToggled}
            disabled={disabled}
            enableLabel="Yes"
            disableLabel="No"
            onChange={() => handleToggle()}
            isBackgroundTransparent={true}
          />
        </div>
      </section>
    </div>
  );
};

export default ManualToggleButton;

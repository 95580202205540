import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import { fontPoppins } from '@src/css_config/fontConstants';
import { colors } from '@src/css_config/colorConstants';
import PropTypes from 'prop-types';

const CustomTooltip = styled(({ className, title, responseTooltip, ...props }) => {
  return (
    <Tooltip
      PopperProps={{
        style: {
          marginTop: props?.inputVariableIcon === 'inputVariableIcon' ? 0 : -10,
        },
      }}
      title={<span>{title}</span>}
      classes={{ popper: className }}
      {...props}
    />
  );
})(({ responseTooltip, checkPosition }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.black_border,
    color: colors.text_white,
    boxShadow: `0px 0px 7px ${colors.blue_light_50}`,
    display: !responseTooltip && 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    placement: checkPosition && responseTooltip ? 'top-start' : 'bottom',
    ...(responseTooltip ? {} : fontPoppins.rSm),
  },
}));

CustomTooltip.propTypes = {
  title: PropTypes.string,
  placement: PropTypes.string,
  responseTooltip: PropTypes.bool
};

CustomTooltip.defaultProps = {
  title: 'Tooltip',
  placement: 'bottom',
  responseTooltip: true,
};

export default CustomTooltip;

import React, { useState } from 'react';
import Modal from 'react-modal';
import NewAutomationScript from './modal-components/new-automation-script';
import ExistingAutomationScript from './modal-components/existing-automation-script';
import { Close } from '@mui/icons-material';
import { Radio } from '@mui/material';
import { getLicenseFeatures } from '@src/util/common_utils';
import { useAlert } from '@pages/common/alert_service/useAlert';

Modal.setAppElement('#root');
const CreateAutomationScript = (props) => {
  const { AlertContatiner, MyAlert } = useAlert();
  const [openModal, setOpenModal] = useState(true);
  const customStylesPage = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '640px',
      height: 'fit-content',
      background: '#fbfcfd 0% 0% no-repeat padding-box',
      boxShadow: '0px 0px 11px #000000a1',
      borderRadius: '4px',
      opacity: '1',
    },
  };
  const [newScript, setNewScript] = useState(true);
  const licenseFeatures = getLicenseFeatures();
  const hasManualTestCase = window.privilege.hasManualTestCase(licenseFeatures);
  const hasCBasic = window.privilege.hasCBasic(licenseFeatures);
  const handleRadioChange = (e) => {
    if (e.target.value === 'newScript') {
      setNewScript(true);
    } else {
      setNewScript(false);
    }
  };

  const handleEscapeClose = (e) => {
    if (e.key === "Escape"){
      setOpenModal(false);
      props.closeModal(false);
    }
  };

  return (
    <Modal isOpen={openModal} style={customStylesPage} className="p-px" onRequestClose={handleEscapeClose}>
      <div className="mt-2 p-1">
        <div className="step-alert-position-style ml-32 mt-10">
          <AlertContatiner></AlertContatiner>
        </div>
        <span className="Popup-header-common pl-3 inline-block w-10/12 text-overflow-style">
          <span className=""> Create Automation Script</span>
        </span>
        <div className="float-right">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="ml-5 mr-5 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <Close color="action" />
          </button>
        </div>
      </div>
      <div
        className="border-b border-t border-blue-100 my-px pb-1 modal-body-testdevheight overflow-scroll"
        id="journal-scroll"
      >
        <div className="mx-6 my-3 fontPoppinsRegularMd" id="journal-scroll">
          <div className="grid">
            {(hasManualTestCase || hasCBasic) && (
              <>
                <p className="pr-4 my-1">
                  Do you want to create a new Automation script or add to existing Manual test case ?
                </p>
                <p className="my-1">
                  <span>
                    <Radio
                      color="primary"
                      name="addType"
                      checked={newScript}
                      value="newScript"
                      onChange={handleRadioChange}
                    />{' '}
                    Create new Automation script
                  </span>
                </p>
                <p className="my-1">
                  <span>
                    <Radio
                      color="primary"
                      name="addType"
                      checked={!newScript}
                      value="existingScript"
                      onChange={handleRadioChange}
                    />{' '}
                    Add Automation script to existing Manual test case
                  </span>
                </p>
              </>
            )}
            <div className="">
              {newScript ? (
                <NewAutomationScript {...props} MyAlert={props.MyAlert} labelAlert={MyAlert} />
              ) : (
                <ExistingAutomationScript {...props} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="float-right my-3 mx-5">
        <button onClick={() => props.closeModal(false)} type="button" className="gray-btn">
          Cancel
        </button>
        <button form={newScript ? 'newScriptForm' : 'existingScriptForm'} type="submit" className="primary-btn ml-3">
          Create
        </button>
      </div>
    </Modal>
  );
};

export default CreateAutomationScript;

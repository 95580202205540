import React, { Fragment, useEffect, useState, useContext } from 'react';
import ReactTable from '@src/pages/common/Table/ReactTable';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import CreateEditDataproviders from './create-edit-dataproviders';
import DataproviderDetailsModal from './dataproviders-details';
import DeleteModal from '@pages/test-development/shared/delete-modal';
import ViewDataprovider from './view-dataprovider';
import { Tooltip } from '@material-ui/core';
import { deleteDataproviderReq, getAllDataprovidersReq } from '@api/api_services';
import { CommonLoader } from '@src/pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import SearchBar from '@src/pages/analytics/common/search-bar';
import { HighlightText, isEmptyValue, isValueIncludes } from '@util/common_utils';
import NoResultsFound from '@src/pages/common/Table/NoResultFound';

const Dataproviders = (props) => {
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  let [reload, setReload] = useState(true);
  let [isLoading, setIsLoading] = useState(false);
  let [disableIncludePrePostCondition, setDisableIncludePrePostCondition] = useState(false);
  let [dataproviderWithPrePostCondition, setDataproviderWithPrePostCondition] = useState('');
  let [dataprovidersData, setDataprovidersData] = useState([]);
  let [openCreateDataproviderModal, setOpenCreateDataproviderModal] = useState(false);
  let [openDataproviderDetailsModal, setOpenDataproviderDetailsModal] = useState(false);
  let [openDataproviderDeleteModal, setOpenDataproviderDeleteModal] = useState(false);
  let [openDataproviderViewModal, setOpenDataproviderViewModal] = useState(false);
  let [editDataproviderData, setEditDataproviderData] = useState(null);
  let [isHighlight, setIsHighlight] = useState(false);

  const reloadDataproviders = (val) => {
    setReload(val);
  };
  const closeModal = (value) => {
    if (openDataproviderViewModal) {
      setOpenDataproviderViewModal(value);
    }
    if (openDataproviderDeleteModal) {
      setOpenDataproviderDeleteModal(value);
    }
    if (openDataproviderDetailsModal) {
      setOpenDataproviderDetailsModal(false);
    }
    if (openCreateDataproviderModal) {
      setOpenCreateDataproviderModal(value);
    }
    setEditDataproviderData(null);
  };
  const getDataproviderDetails = (dpData) => {
    setOpenDataproviderDetailsModal(true);
    setEditDataproviderData(dpData);
  };
  const deleteDataprovider = async (data) => {
    try {
      const response = await deleteDataproviderReq(moduleId, scriptId, data._id);
      if (response.data.responseCode === 200 && response.data.status === 'SUCCESS') {
        reloadDataproviders(true);
        props.MyAlert.success(`${data.name} dataprovider deleted successfully`);
      } else {
        props.MyAlert.warning(`${response.data.message}`);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getDataproviders = async () => {
    try {
      resetProgress();
      startOrResumeProgress({
        stopAt: 90,
      });
      setDisableIncludePrePostCondition(false);
      setDataproviderWithPrePostCondition('');
      setIsLoading(true);
      let res = await getAllDataprovidersReq(moduleId, scriptId);
      stopProgress();
      if (res.data && res.data.responseObject && res.data.responseObject.length > 0) {
        res.data.responseObject.forEach((ele) => {
          if (ele.type === 'Including pre post condition') {
            setDisableIncludePrePostCondition(true);
            setDataproviderWithPrePostCondition(ele.name);
          }
        });
        setDataprovidersData(res.data.responseObject);
      } else {
        setDataprovidersData([]);
      }
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 10,
      });
      setIsLoading(false);
    } catch (err) {
      resetProgress();
      setIsLoading(false);
      console.error('GET_ALL_DATAPROVIDER_LIST : ', err);
    }
  };
  let menuData = (dpData) => {
    return (
      <div className="actionIcons">
        {isEditable && (
          <>
            <Tooltip title="Edit">
              <EditOutlinedIcon
                className="float-left cursor-pointer text-base mr-3 text-blue-700"
                onClick={() => {
                  setEditDataproviderData(dpData);
                  setOpenCreateDataproviderModal(true);
                }}
              />
            </Tooltip>
            {hasFullAccess && (
              <Tooltip title="Delete">
                <DeleteOutlinedIcon
                  className="float-left cursor-pointer mr-3 text-blue-700 text-base"
                  onClick={() => {
                    setEditDataproviderData(dpData);
                    setOpenDataproviderDeleteModal(true);
                  }}
                />
              </Tooltip>
            )}
          </>
        )}

        <div className="inline-block">
          <Menu as="div" className="relative inline-block text-left mt-px">
            {({ open }) => (
              <>
                <div>
                  <Menu.Button className="bg-gray-100 rounded-full flex items-center  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 focus:ring-indigo-500">
                    <span className="sr-only">Open options</span>
                    <Tooltip title="More">
                      <span>
                        <DotsVerticalIcon className="h-5 w-5 configmoreIcon" aria-hidden="true" />
                      </span>
                    </Tooltip>
                  </Menu.Button>
                </div>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 fontPoppinsRegularMd w-full text-left'
                            )}
                            onClick={() => {
                              setEditDataproviderData(dpData);
                              setOpenDataproviderViewModal(true);
                            }}
                          >
                            View Data
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 fontPoppinsRegularMd w-full text-left'
                            )}
                            onClick={() => {
                              getDataproviderDetails(dpData);
                            }}
                          >
                            Details
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: (e) => (
          <span
            onClick={() => getDataproviderDetails(e.cell.row.original)}
            className="text-blue-700 cursor-pointer fontPoppinsRegularMd overflow-hidden-common"
          >
            <HighlightText text={e?.cell?.row?.original?.name} highlight={searchTextHighlight} />
          </span>
        ),
      },
      {
        Header: 'File Name',
        accessor: 'fileName',
        Cell: ({ value }) => (
          <span className="text-gray-700 fontPoppinsRegularMd overflow-hidden-common">
            <HighlightText text={value} highlight={searchTextHighlight} />
          </span>
        ),
      },
      {
        Header: 'Sheet Name',
        accessor: 'sheetName',
        Cell: ({ value }) => (
          <span className="text-gray-700 fontPoppinsRegularMd overflow-hidden-common">
            <HighlightText text={value} highlight={searchTextHighlight} />{' '}
          </span>
        ),
      },
      {
        Header: 'From Row',
        accessor: 'fromRow',
        Cell: ({ value }) => (
          <span className="text-gray-700 fontPoppinsRegularMd overflow-hidden-common"> {value}</span>
        ),
      },
      {
        Header: 'To Row',
        accessor: 'toRow',
        Cell: ({ value }) => (
          <span className="text-gray-700 fontPoppinsRegularMd overflow-hidden-common"> {value}</span>
        ),
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: (e) => menuData(e.cell.row.original),
      },
    ],
    [isHighlight]
  );

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  useEffect(() => {
    if (reload) {
      getDataproviders();
    }
    setReload(false);
  }, [reload]);

  const { isEditable, hasFullAccess } = React.useMemo(
    () => ({
      isEditable: window.permission?.isEditAccess('testDevelopment'),
      hasFullAccess: window.permission?.isFullAccess('testDevelopment'),
    }),
    []
  );

  // TODO :: handle Search filter :: START

  const [openSearch, setOpenSearch] = useState(false);
  const [userSearched, setuserSearched] = useState('');
  let [searchTextHighlight, setSearchTextHighlight] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(dataprovidersData);

    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [dataprovidersData]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(dataprovidersData);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setData(dataprovidersData);
    setIsHighlight(!isHighlight);
    setSearchTextHighlight('');
  };
  let handleSearchOpen = () => {
    setOpenSearch(true);
  };
  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = dataprovidersData.filter(({ name, sheetName, fileName }) => {
          return isValueIncludes(e, name) || isValueIncludes(e, sheetName) || isValueIncludes(e, fileName);
        });
        setData(newData);
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };
  // TODO :: handle Search filter :: END

  return (
    <div className="page-table-body">
      <div className="content_area_style">
        <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
          <label className="project_label fontPoppinsRegularMd flex-auto">
            Dataproviders
            <span className="proj-count-style px-2.5 py-1 ml-2 font-bold rounded-lg">
              {data?.length ? data.length : 0}
            </span>
          </label>
          <SearchBar
            open={openSearch}
            searchValue={userSearched}
            handleSearch={handleSearch}
            onSearchClose={handleSearchClose}
            handleSearchOpen={handleSearchOpen}
            enterKeyHandler={handleSearchSubmit}
            disable={isEmptyValue(dataprovidersData)}
          />
          {isEditable && (
            <button
              onClick={() => {
                setOpenCreateDataproviderModal(true);
              }}
              className="ml-3 mr-4 primary-btn"
            >
              + Dataprovider
            </button>
          )}
        </div>
        <div className="table-height overflow-y-auto" id="journal-scroll">
          <ReactTable data={data} columns={columns} tableHeightClass={'main-table-height'} />
          {isLoading ? (
            <CommonLoader />
          ) : dataprovidersData && dataprovidersData.length === 0 ? (
            <div className="fontPoppinsRegularMd no-steps">
              <div className="">
                <p className="font-bold mb-4">{isEditable ? 'Quick Start' : 'No Dataproviders!!'}</p>
                {isEditable && (
                  <p className="mb-4">
                    <span className="font-bold">Step 1 :</span> Click
                    <button
                      onClick={() => {
                        setOpenCreateDataproviderModal(true);
                      }}
                      className="ml-3 mr-3 primary-btn"
                    >
                      + Dataprovider
                    </button>
                    to start adding the Dataproviders
                  </p>
                )}
              </div>
            </div>
          ) : (
            <>{openSearch && isEmptyValue(data) && <NoResultsFound className="mt-14" />}</>
          )}
        </div>

        {/* modal for creating dataproviders */}
        {openCreateDataproviderModal ? (
          <CreateEditDataproviders
            dataproviderWithPrePostCondition={dataproviderWithPrePostCondition}
            disableIncludePrePostCondition={disableIncludePrePostCondition}
            MyAlert={props.MyAlert}
            reload={reloadDataproviders}
            data={editDataproviderData}
            closeModal={closeModal}
          />
        ) : null}
        {/* modal for dataprovider details */}
        {openDataproviderDetailsModal ? (
          <DataproviderDetailsModal closeModal={closeModal} data={editDataproviderData} />
        ) : null}
        {/* modal for delete */}
        {openDataproviderDeleteModal ? (
          <DeleteModal
            MyAlert={props.MyAlert}
            closeModal={closeModal}
            handleDelete={deleteDataprovider}
            dataObj={{ type: 'Dataprovider', data: editDataproviderData }}
          />
        ) : null}
        {/* modal for view dataprovider */}
        {openDataproviderViewModal ? <ViewDataprovider closeModal={closeModal} data={editDataproviderData} /> : null}
      </div>
    </div>
  );
};

export default Dataproviders;

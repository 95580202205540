import React from 'react';
import ElementTree from './element_tree';

function ChildPage(props) {
  return (
    <>
      <ElementTree pageType="child" platForm={props.platForm} MyAlert={props.MyAlert} />
    </>
  );
}

export default ChildPage;

import { createContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LICENSE_WIZARD_STEPS } from '@pages/licenses/components/LicenseWizard';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  FREE_LICENSE_TYPE,
  INITIAL_PARALLEL_RUN_LIMITS,
  INITIAL_STORAGE_LIMITS,
  LICENSE_FORM_TYPES,
  PAID_LICENSE_TYPE,
  LICENSE_PERIOD_STATUS,
  getLicensePeriodStatus,
  TAX_PERCENTAGE,
  hasPaidFuturePlan,
  billingCycles,
  selectedServicesTextFormat,
} from '@util/licenseUtils';
import countriesStateData from '@data/countries-and-states.json';
import { decode, cardValidationsYupMethodConfig, getUserId } from '@util/common_utils';
import {
  addLicense,
  getLicenseByLicenseId,
  getLicenseTypes,
  getPaymentURL,
  getSingleUserRequest,
} from '@api/api_services';
import { useAlert } from '@pages/common/alert_service/useAlert';
export const LicenseWizardContext = createContext(null);

cardValidationsYupMethodConfig();

const INITIAL_FORM_VALUES = {
  licensesType: null,
  numberOfParallelRuns: 1,
  totalCostOfParallelRuns: 0,
  storage: ' ',
  totalCostStorage: 0,
  billingCycle: null,
  totalCost: 0,
  discount: 0,
  tax: 0,
  grandTotal: 0,
  fullName: '',
  country: null,
  address: '',
  aptSuite: '',
  state: null,
  city: '',
  zip: '',
  saveAddress: false,
  cardNumber: '',
  cardExpiry: '',
  cardCvv: '',
  saveCard: false,
  autoRenew: false,
  licenseFeatures: [],
};

function LicenseWizardProvider({ children }) {
  let tryAgainData = JSON.parse(localStorage.getItem('try-again-data'));
  const [statesOfSelectedCountry, setStatesOfSelectedCountry] = useState([]);
  const [licenseTypes, setLicenseTypes] = useState([]);
  const [activeStepKey, setActiveStepKey] = useState(LICENSE_WIZARD_STEPS[0].key);
  const [isPaidPlan, setIsPaidPlan] = useState(false);
  const [formType, setFormType] = useState('');
  const [licenseData, setLicenseData] = useState({});
  const [parallelRunLimits, setParallelRunLimits] = useState(INITIAL_PARALLEL_RUN_LIMITS);
  const [storageLimits, setStorageLimits] = useState(INITIAL_STORAGE_LIMITS);
  const [isSuperAdminOfCBasicLicense, setIsSuperAdminOfCBasicLicense] = useState(false);
  const [showRetainButton, setShowRetainButton] = useState(false);
  const [featuresData, setFeaturesData] = useState([]);
  let [isBackToPrevPage, setIsBackToPrevPage] = useState(false);
  const { AlertContatiner, MyAlert } = useAlert();

  const history = useHistory();
  const location = useLocation();
  const { licenseId } = useParams();
  const isUpgradeCbasic = LICENSE_FORM_TYPES.upgrade.type && licenseData.licenseType === FREE_LICENSE_TYPE;

  useEffect(() => {
    getLicenseTypes()
      .then((response) => {
        const fetchedLicenseTypes = response?.data?.responseObject;
        setLicenseTypes(fetchedLicenseTypes || []);
      })
      .catch((error) => {
        console.error('Failed while fetching license types', error);
      });
  }, []);

  useEffect(() => {
    const decodedLicenseId = decode(licenseId);
    if (decodedLicenseId) {
      getLicenseByLicenseId(decodedLicenseId)
        .then((response) => {
          const license = response.data?.responseObject;
          const user = JSON.parse(localStorage.getItem('test-optimize-user') || '{}');
          if (
            license &&
            license.licenseUsers.find(
              (licenseUser) => licenseUser.emailId === user.userName && licenseUser.privilege === 'Super Admin'
            ) &&
            !hasPaidFuturePlan(license)
          ) {
            setLicenseData(license);
          } else {
            history.push('/licenses');
          }
        })
        .catch((error) => {
          console.error('Failed to fetch license details', error);
          history.push('/licenses');
        });
    }
  }, [licenseId]);

  useEffect(() => {
    const formTypeData = Object.values(LICENSE_FORM_TYPES).find((formType) =>
      location.pathname.endsWith(formType.endingPath)
    );
    setFormType(formTypeData?.type);
    if (formTypeData === LICENSE_FORM_TYPES.buy) {
      getSingleUserRequest(getUserId())
        .then(({ data: response }) => {
          if (response && response.responseCode === 200 && response.responseObject) {
            setIsSuperAdminOfCBasicLicense(response.responseObject.hasPurchasedCloudBasic);
          }
        })
        .catch((error) => {
          console.error('Failed while checking if the user is super admin of C-Basic License', error);
        });
    }
  }, [location]);

  const formik = useFormik({
    initialValues: INITIAL_FORM_VALUES,
    validationSchema: Yup.object().shape({
      ...(activeStepKey === LICENSE_WIZARD_STEPS[0].key && {
        licensesType: Yup.mixed().required('Please select a License Type'),
        numberOfParallelRuns: Yup.string()
          .testIsNumberInRange({
            from: parallelRunLimits.min,
            to: parallelRunLimits.max,
            item: 'Parallel runs',
          })
          .required('Please enter valid number of Parallel Runs'),
        storage: Yup.string()
          .testIsNumberInRange({
            from: storageLimits.min,
            to: storageLimits.max,
            unit: 'GB',
            item: 'Storage',
          })
          .required('Please enter valid storage'),
        ...(isPaidPlan && {
          billingCycle: Yup.mixed().required('Please select a Billing Cycle'),
        }),
      }),
    }),
  });

  useEffect(() => {
    let purchasedLicensedData = licenseData;
    if (
      [
        LICENSE_FORM_TYPES.renew.type,
        LICENSE_FORM_TYPES.renewUpgrade.type,
        LICENSE_FORM_TYPES.renewDowngrade.type,
      ].includes(formType)
    ) {
      if (
        LICENSE_PERIOD_STATUS.ACTIVE === getLicensePeriodStatus(purchasedLicensedData) &&
        Object.keys(purchasedLicensedData).length
      ) {
        history.push('/licenses');
      } else if (!purchasedLicensedData.isModifiedForRenew && purchasedLicensedData.futureLicensePlan) {
        const { futureLicensePlan } = purchasedLicensedData;
        purchasedLicensedData = {
          ...purchasedLicensedData,
          licensePlan: futureLicensePlan.licensePlan,
          licenseType: futureLicensePlan?.licenseType,
          billingCycle: futureLicensePlan.billingCycle,
          numberOfParallelRuns: futureLicensePlan.numberOfParallelRuns,
          storage: futureLicensePlan.storage,
          isModifiedForRenew: true,
        };
        setLicenseData(purchasedLicensedData);
        return;
      }
    }
    const occupiedStorageNumber = Number((purchasedLicensedData.storage || '').split(' ')[0]);
    const selectedLicenseType = formik.values.licensesType || {};
    if ([LICENSE_FORM_TYPES.upgrade.type, LICENSE_FORM_TYPES.renewUpgrade.type].includes(formType)) {
      handleLicenseTypeChange({
        target: {
          value: licenseTypes.find((licenseType) => licenseType?.paymentType === PAID_LICENSE_TYPE),
        },
      });
      if (formik.values.licensesType && purchasedLicensedData.licenseType === formik.values.licensesType.type) {
        setParallelRunLimits({
          min: Number(purchasedLicensedData.numberOfParallelRuns) || 1,
          max: INITIAL_PARALLEL_RUN_LIMITS.max,
        });
        setStorageLimits({
          min: occupiedStorageNumber > 1 ? occupiedStorageNumber : 2,
          max: INITIAL_STORAGE_LIMITS.max,
        });
      } else {
        setParallelRunLimits({
          min: Number(selectedLicenseType.numberOfParallelRuns) || INITIAL_PARALLEL_RUN_LIMITS.min,
          max:
            selectedLicenseType.type === FREE_LICENSE_TYPE
              ? Number(selectedLicenseType.numberOfParallelRuns) || INITIAL_PARALLEL_RUN_LIMITS.min
              : INITIAL_PARALLEL_RUN_LIMITS.max,
        });
        setStorageLimits({
          min: Number(selectedLicenseType.storage) || INITIAL_STORAGE_LIMITS.min,
          max:
            selectedLicenseType.type === FREE_LICENSE_TYPE
              ? Number(selectedLicenseType.storage) || INITIAL_STORAGE_LIMITS.min
              : INITIAL_STORAGE_LIMITS.max,
        });
      }
    } else if ([LICENSE_FORM_TYPES.downgrade.type, LICENSE_FORM_TYPES.renewDowngrade.type].includes(formType)) {
      setParallelRunLimits({
        min: Number(selectedLicenseType.numberOfParallelRuns) || INITIAL_PARALLEL_RUN_LIMITS.min,
        max: Number(purchasedLicensedData.numberOfParallelRuns) || 1,
      });
      setStorageLimits({
        min: Number(selectedLicenseType.storage) || INITIAL_STORAGE_LIMITS.min,
        max: occupiedStorageNumber > 1 ? occupiedStorageNumber : 2,
      });
    } else if ([LICENSE_FORM_TYPES.buy.type, LICENSE_FORM_TYPES?.renew?.type]?.includes(formType)) {
      setParallelRunLimits({
        min: Number(selectedLicenseType.numberOfParallelRuns) || INITIAL_PARALLEL_RUN_LIMITS.min,
        max:
          selectedLicenseType.type === FREE_LICENSE_TYPE
            ? Number(selectedLicenseType.numberOfParallelRuns) || INITIAL_PARALLEL_RUN_LIMITS.min
            : INITIAL_PARALLEL_RUN_LIMITS.max,
      });
      setStorageLimits({
        min: Number(selectedLicenseType.storage) || INITIAL_STORAGE_LIMITS.min,
        max:
          selectedLicenseType.type === FREE_LICENSE_TYPE
            ? Number(selectedLicenseType.storage) || INITIAL_STORAGE_LIMITS.min
            : INITIAL_STORAGE_LIMITS.max,
      });
    }
    if (
      [
        LICENSE_FORM_TYPES.renew.type,
        LICENSE_FORM_TYPES.downgrade.type,
        LICENSE_FORM_TYPES.upgrade.type,
        LICENSE_FORM_TYPES.renewUpgrade.type,
        LICENSE_FORM_TYPES.renewDowngrade.type,
      ].includes(formType) &&
      !isUpgradeCbasic
    ) {
      formik.setFieldValue(
        'billingCycle',
        billingCycles.find((billingCycle) => billingCycle.value === purchasedLicensedData.billingCycle)
      );

      if (purchasedLicensedData.licenseType !== FREE_LICENSE_TYPE) {
        formik.setFieldValue(
          'licensesType',
          licenseTypes.find((licenseType) => licenseType.type === purchasedLicensedData.licenseType)
        );

        formik.setFieldValue('numberOfParallelRuns', Number(purchasedLicensedData.numberOfParallelRuns) || 1);

        formik.setFieldValue('storage', Number((purchasedLicensedData.storage || '').split(' ')[0]) || 2);
        formik.setFieldValue('licenseFeatures', purchasedLicensedData.licenseFeatures);
      } else if (![LICENSE_FORM_TYPES.upgrade.type, LICENSE_FORM_TYPES.renewUpgrade.type].includes(formType)) {
        history.push('/licenses');
      }
    }
  }, [formType, licenseData, licenseTypes, formik.values.licensesType]);

  useEffect(() => {
    if (tryAgainData) {
      formik.setFieldValue(
        'licensesType',
        licenseTypes.find((licenseType) => licenseType.type === tryAgainData?.license?.licenseType)
      );
    } else if ([LICENSE_FORM_TYPES.buy.type].includes(formType)) {
      formik.setFieldValue(
        'licensesType',
        licenseTypes.find((licenseType) => {
          if (isSuperAdminOfCBasicLicense) {
            return licenseType.paymentType === 'Paid' && licenseType.type;
          } else {
            return licenseType.paymentType === 'Free' && licenseType.type;
          }
        })
      );
    }
  }, [isSuperAdminOfCBasicLicense, licenseTypes]);

  useEffect(() => {
    let isFreeLicenseType = false;
    let newSelectedLicensePlan = null;

    if (formik.values.licensesType) {
      isFreeLicenseType = formik.values.licensesType.type === FREE_LICENSE_TYPE;
      const licensePlans = formik.values.licensesType.plans;
      if (
        licenseData.licenseType === formik.values.licensesType.type ||
        ([
          LICENSE_FORM_TYPES.renew.type,
          LICENSE_FORM_TYPES.downgrade.type,
          LICENSE_FORM_TYPES.upgrade.type,
          LICENSE_FORM_TYPES.renewUpgrade.type,
          LICENSE_FORM_TYPES.renewDowngrade.type,
        ].includes(formType) &&
          licenseData.licenseType !== FREE_LICENSE_TYPE)
      ) {
        newSelectedLicensePlan = licensePlans?.find((licensePlan) => licensePlan.plan === licenseData.licensePlan);
      } else {
        newSelectedLicensePlan = licensePlans.at(isFreeLicenseType ? 0 : -1);
      }

      if (isFreeLicenseType) {
        formik.setFieldValue('numberOfParallelRuns', 1);
        formik.setFieldValue('storage', 2);
        formik.setFieldValue('licenseFeatures', ['All']);
      }
    }
    setIsPaidPlan(!isFreeLicenseType);
    formik.setFieldValue('licensePlan', newSelectedLicensePlan);
  }, [formik.values.licensesType]);

  function handleLicenseTypeChange(event) {
    let isFreeLicenseType = false;
    let licensePlans = [];
    if (event.target.value) {
      isFreeLicenseType = event.target.value.type === FREE_LICENSE_TYPE;
      licensePlans = event.target.value.plans;
    }

    setIsPaidPlan(!isFreeLicenseType);
    formik.setFieldValue('licensePlan', licensePlans.at(isFreeLicenseType ? 0 : -1));
    formik.setFieldValue('licensesType', event.target.value);
  }

  function handleBackNavigation(stepsDetails) {
    setIsBackToPrevPage(true);

    stepsDetails.previousStep?.key && setActiveStepKey(stepsDetails.previousStep?.key);
  }
  let isFreeLicenseType = formik.values?.licensesType?.type === FREE_LICENSE_TYPE;

  async function handleNextNavigation(stepsDetails) {
    setIsBackToPrevPage(false);
    const result = await formik
      .validateForm()
      .then(async (errors) => {
        if (Object.keys(errors).length) {
          formik.setErrors(errors);
          formik.setTouched(
            Object.keys(errors).reduce(
              (touchedFields, fieldName) => ({
                ...touchedFields,
                [fieldName]: true,
              }),
              {}
            )
          );
          return false;
        }

        stepsDetails.nextStep?.key && setActiveStepKey(stepsDetails.nextStep?.key);
        return true;
      })
      .catch((error) => {
        console.error('Failed to validate the License form', error);
        return false;
      });
    return result;
  }

  const handlePayment = async (paymentPayload, formType) => {
    try {
      let response = await getPaymentURL(
        paymentPayload,
        formType === LICENSE_FORM_TYPES.buy.type ? 'buy' : licenseData.id
      );
      if (response.data && response.data.status === 'OK') {
        localStorage.setItem('orderId', JSON.stringify(response.data.responseObject));
        sessionStorage.setItem('orderId', JSON.stringify(response.data.responseObject));
        navigateToPaymentPage(response.data.responseObject.URL);
      }
    } catch (error) {
      console.error('Failed to purchase or update license through API', error);
      MyAlert.danger('Something went wrong!!');
      formik.setSubmitting(false);
      return false;
    }
  };

  useEffect(() => {
    const totalCost = Number(formik.values.totalCost) || 0;
    let discount = 0;
    if (
      formType === LICENSE_FORM_TYPES.upgrade.type &&
      licenseData.licenseType !== FREE_LICENSE_TYPE &&
      licenseData.licensePlan !== formik.values.licensePlan?.plan &&
      licenseData.paymentDetails
    ) {
      const { netTotal, paidForDays } = licenseData.paymentDetails;
      discount = (Number(netTotal) / Number(paidForDays)) * (Number(licenseData.remainingDays) || 0);
    }
    const tax = ((totalCost - discount) * TAX_PERCENTAGE) / 100;
    const grandTotal = totalCost - discount + tax;

    formik.setFieldValue('discount', discount);
    formik.setFieldValue('tax', tax);
    formik.setFieldValue('grandTotal', grandTotal);
  }, [formik.values.totalCost]);

  useEffect(() => {
    formik.setFieldValue('state', null);
    const states = formik.values.country?.states || [];
    setStatesOfSelectedCountry(states);
  }, [formik.values.country]);

  async function handleFinish() {
    if (formik.isValid) {
      const { values } = formik;
      let licenseFeatures;
      if (isFreeLicenseType) {
        licenseFeatures = ['All'];
      } else {
        licenseFeatures = formik?.values?.licenseFeatures
          ?.filter((service) => {
            if (service !== 'Automation') {
              return service;
            }
          })
          ?.map((service) => {
            return selectedServicesTextFormat(service);
          });
      }

      let payload = {
        licenses: [
          {
            licenseType: values.licensesType?.type,
            numberOfParallelRuns: values.numberOfParallelRuns,
            storage: values.storage ? values.storage + ' GB' : '',
            licenseFeatures,
            licenseStatus: 'PENDING',
          },
        ],
      };

      let getPaymentURLPayload = {
        licenseType: values.licensesType?.type,
        billingCycle: values.billingCycle?.value,
        numberOfParallelRuns: values.numberOfParallelRuns,
        storage: values.storage ? values.storage + ' GB' : '',
        licenseFeatures,
        retainData: values?.retainData,
        paymentDetails: {
          fullName: values.fullName,
          state: values.state?.name,
          aptSuite: values.aptSuite,
          city: values.city,
          zip: values.zip,
          address: values.address,
          country: values.country?.name,
          netTotal: values.totalCost,
          tax: values.tax,
          grandTotal: values.grandTotal,
          currency: 'INR',
        },
        action: Object.values(LICENSE_FORM_TYPES).find(({ type }) => type === formType).action,
      };

      if (isPaidPlan) {
        if (formType !== LICENSE_FORM_TYPES.downgrade.type) {
          payload.licenses[0] = {
            ...payload.licenses[0],
            paymentDetails: {
              fullName: values.fullName,
              state: values.state?.name,
              aptSuite: values.aptSuite,
              city: values.city,
              zip: values.zip,
              address: values.address,
              country: values.country?.name,
              netTotal: values.totalCost,
              tax: values.tax,
              grandTotal: values.grandTotal,
            },
          };
        }

        if (
          formType === LICENSE_FORM_TYPES.upgrade.type &&
          licenseData.licenseType !== FREE_LICENSE_TYPE &&
          formik.values.licensePlan &&
          licenseData.licensePlan === formik.values.licensePlan.plan
        ) {
          payload.licenses[0] = {
            ...payload.licenses[0],
            paymentDetails: {
              ...payload.licenses[0].paymentDetails,
              netTotal:
                (Number(licenseData.paymentDetails.netTotal) / Number(licenseData.paymentDetails.paidForDays)) *
                  Number(licenseData.remainingDays) +
                (Number(values.totalCost) || 0),
            },
          };
        }
      } else {
        payload.licenses[0] = {
          ...payload.licenses[0],
          numberOfParallelRuns: 1,
          storage: '2 GB',
        };
      }

      if (formType === LICENSE_FORM_TYPES.buy.type) {
        payload.licenses[0] = {
          ...payload.licenses[0],
          licenseStatus: 'ACTIVE',
        };
      } else {
        payload.licenses[0] = {
          ...payload.licenses[0],
          action: Object.values(LICENSE_FORM_TYPES).find(({ type }) => type === formType).action,
        };
      }
      formik.setSubmitting(true);
      if (formik?.values?.licensesType?.type === 'C-Basic') {
        await handleBuy(payload);
      } else {
        return await handlePayment(getPaymentURLPayload, formType);
      }
    }
  }
  const handleBuy = async (payload) => {
    try {
      let { data } = await addLicense(payload);
      localStorage?.setItem('free-trial-data', JSON.stringify(data?.responseObject));
      history?.push('/licenses/fireflink-license');
    } catch (error) {
      console.error(error);
    }
  };
  const licenseFeature = formik.values.licenseFeatures;
  const isLicenseDataChanged = useMemo(() => {
    const { licensesType, licensePlan, numberOfParallelRuns, storage, billingCycle } = formik.values;
    return (
      licenseData.licenseType !== licensesType?.type ||
      licenseData.licensePlan !== licensePlan?.plan ||
      Number(licenseData.numberOfParallelRuns) !== numberOfParallelRuns ||
      Number(licenseData.storage) !== storage ||
      licenseData.billingCycle !== billingCycle?.value ||
      formik.values.licenseFeatures !== licenseData.licenseFeatures
    );
  }, [
    licenseData,
    formik.values.licensesType,
    formik.values.licensePlan,
    formik.values.numberOfParallelRuns,
    formik.values.storage,
    formik.values.billingCycle,
    formik.values.licenseFeatures,
  ]);

  const navigateToPaymentPage = (url) => {
    if (url) {
      window.open(url, '_self');
    }
  };

  return (
    <LicenseWizardContext.Provider
      value={{
        formik,
        formType,
        isPaidPlan,
        handleNextNavigation,
        handleBackNavigation,
        activeStepKey,
        countries: countriesStateData,
        states: statesOfSelectedCountry,
        handleFinish,
        parallelRunLimits,
        storageLimits,
        licenseData,
        licenseTypes,
        isSuperAdminOfCBasicLicense,
        isLicenseDataChanged,
        licenseFeature,
        showRetainButton,
        setShowRetainButton,
        featuresData,
        setFeaturesData,
        isBackToPrevPage,
        setIsBackToPrevPage,
      }}
    >
      <div className="relative">
        <div className="absolute w-full flex -mt-12">
          <AlertContatiner />
        </div>
      </div>
      {children}
    </LicenseWizardContext.Provider>
  );
}

LicenseWizardProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LicenseWizardProvider;

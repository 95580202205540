import React from 'react';
import Select from 'react-dropdown-select';
import styled from '@emotion/styled';
import '../styles/dropdown.scss';

const Item = styled.div`
  display: flex;
  margin: 10px;
  align-items: baseline;
  cursor: pointer;
  border-bottom: 1px dotted transparent;

  ${({ disabled }) =>
    disabled
      ? `
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
`
      : ''}
`;

const ItemLabel = styled.div`
  margin: 5px 10px;
`;
const commonDropdownRenderer = ({ props, state, methods }) => {
  const regexp = new RegExp(state.search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
  const options = props.options.filter((item) => regexp.test(item[props.searchBy] || item[props.labelField]));
  return options.length === 0 ? (
    <div className="noData pl-5 pt-5 pb-5">No options</div>
  ) : (
    <div className="overflow-y-auto" id="journal-scroll">
      <div className="template-options-dropdown">
        {options.map((option) => {
          return (
            <div key={option[props.labelField]} className="fontPoppinsRegularMd">
              <Item
                disabled={option.disabled}
                key={option[props.valueField]}
                className=""
                onClick={option.disabled ? null : () => methods.addItem(option)}
              >
                <ItemLabel>{option[props.labelField]}</ItemLabel>
              </Item>
            </div>
          );
        })}
      </div>
    </div>
  );
};
const TemplateSelectDropdown = (props) => {
  return (
    <Select
      options={props.options}
      closeOnSelect={true}
      searchable={false}
      backspaceDelete={false}
      className={`${props.disable ? 'opacity-70-imp ' : ''} text-sm template-dropdown fontPoppinsRegularMd`}
      values={props.value}
      disabled={props.disable}
      onDropdownOpen={props.onDropdownOpen}
      dropdownRenderer={(innerProps, innerState, innerMethods) =>
        commonDropdownRenderer(innerProps, innerState, innerMethods)
      }
      onChange={(values) => {
        props.templateOnChange(values);
      }}
      multi={false}
      placeholder=""
    />
  );
};

export default TemplateSelectDropdown;

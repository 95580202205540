import '@pages/auth/login.css';
import { useAlert } from '@pages/common/alert_service/useAlert';
import 'react-phone-input-2/lib/material.css';
import style from '@pages/auth/SignupPage/signup.module.scss';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { GLOBAL_CONSTANTS , REGISTRATION_CONSTANTS } from '@src/common/ui-constants';
import React, { Fragment, useEffect, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { getWebSiteHostName, isEmailValid } from '@util/common_utils';
import CommonPage from '@src/pages/common/CommonPage';
import CommonBorder from '@src/pages/common/common-border';
import commonStyles from '@pages/auth/common.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import LabeledInput from '@pages/common/Inputs/LabeledInput';
import LabeledPasswordInput from '@pages/common/Inputs/LabeledPasswordInput';
import { useHistory } from 'react-router';
import { signUpRequest, isEmailExistRequest } from '@api/api_services';
import CheckIcon from '@material-ui/icons/Check';
import { InputAdornment } from '@mui/material';
import { ReactComponent as LeftArrow } from '@assets/svg-imports/Icon-open-arrow-left.svg';
import warningicon from '@assets/warning-icon.svg';
import Alert from '@material-ui/lab/Alert';
import { ReactComponent as InfoIcon } from '@assets/info-icon.svg';
import { Icon } from 'fireflink-ui';
import { REGEX } from '@src/util/validations';

export default function SignUpPage() {
  const URLPathname = window.location.pathname;
  const isAzureSignInType = process.env.REACT_APP_SIGN_IN_TYPE === 'azure';
  const history = useHistory();
  const [userAlreadyExist, setUserAlreadyExist] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(true);
  const [emailError, setEmailError] = useState();
  const [isUserLimitExceded, setIsUserLimitExceded] = useState(false);

  //TODO: methods and validations :: start

  function verifyCaptcha(response) {
    formik.setFieldValue('recaptcha', response);
    setIsCaptchaVerified(!!response);
  }

  function resetCaptcha() {
    formik.setFieldValue('recaptcha', '');
    window?.grecaptcha?.reset();
    setIsCaptchaVerified(false);
  }

  function handleTrimmedBlur(event) {
    event.target.value = event.target.value.trim();
    formik.handleChange(event);
    formik.handleBlur(event);
  }

  const checkIfEmailExists = async () => {
    const data = {
      emailId: formik.values.emailId,
    };
    return await isEmailExistRequest(data)
      .then((response) => {
        let isExists = false;
        if (response.data?.responseObject) {
          isExists = true;
          setUserAlreadyExist(true);
          setEmailError(response.data?.message);
        } else {
          isExists = false;
          setUserAlreadyExist(false);
          setEmailError('');
        }
        return isExists;
      })
      .catch(() => true);
  };

  function checkValidEmail() {
    if (formik.values.emailId) {
      isEmailValid(formik.values.emailId) && checkIfEmailExists();
    } else {
      setEmailError('');
    }
  }
  //TODO :: some methods are common once after review from vishwanath sir, need to change
  const STRONG_PASSWORD_PATTERNS = [
    {
      pattern: /^.{8,20}$/,
      message: 'Should contains at least 8 characters and at most 20 characters',
    },
    {
      pattern: /.*[0-9].*/,
      message: 'Should contains at least one digit',
    },
    {
      pattern: /.*[A-Z].*/,
      message: 'Should contains at least one upper case alphabet',
    },
    {
      pattern: /.*[a-z].*/,
      message: 'Should contains at least one lower case alphabet',
    },
    {
      pattern: /.*[!@#$%&*()+=^].*/,
      message: 'Should contains at least one special character which includes !@#$%&*()+=^',
    },
    {
      pattern: /^\S*$/,
      message: "Should doesn't contain any white space",
    },
  ];

  function handleKeyDown(event) {
    if (event.key === ' ') {
      event.preventDefault();
    }
  }

  useEffect(() => {
    if (URLPathname === '/signup' && process.env.REACT_APP_ENVIRONMENT !== 'onprem') {
      localStorage.clear();
      window.location.replace(getWebSiteHostName() + `/signup`);
    }
  }, []);

  const validationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .min(3, 'Minimum 3 characters')
      .max(25, 'Maximum 25 characters')
      .matches(REGEX.NAME_PATTERN, 'Name can not have special characters')
      .required('Name is required'),
    emailId: Yup.string()
      .email('Enter valid email Id')
      .matches(REGEX.SIGNUP_EMAIL_PATTERN, 'Enter valid email Id')
      .required('Email is required'),
    password:
      !isAzureSignInType &&
      Yup.string()
        .matches(REGEX.PASSWORD_WEAK_MATCH, `Weak password, Match the password criteria`)
        .matches(
          REGEX.PASSWORD_PATTERN,
          `Weak password, Match the passworsd criteria`
        )
        .required('Password is required'),
    confirmPassword:
      !isAzureSignInType &&
      Yup.string()
        .required('Confirm password is required')
        .when('password', {
          is: (val) => val,
          then: Yup.string().oneOf([Yup.ref('password')], 'Confirm password not matched with new password'),
        }),
    //recaptcha: Yup.string().trim().required("Recaptcha is required"),
  });

  const formik = useFormik({
    initialValues: {
      emailId: '',
      password: '',
      confirmPassword: '',
      licensesType: 'C-Basic',
      licensePlan: 'All',
      numberOfParallelRuns: 1,
      storage: 2,
    },
    validationSchema: validationSchema,

    onSubmit: (values) => {
      if (formik.isValid && !userAlreadyExist && isCaptchaVerified) {
        let payload = {
          name: values.name,
          emailId: values.emailId,
          licenses: [
            {
              licenseFeatures: [formik.values.licensePlan],
              licenseType: formik.values.licensesType,
              licenseTypeDisplay: 'Free Trial',
              licenseStatus: 'PENDING',
              billingCycle: 'Yeasrly',
              numberOfParallelRuns: values.numberOfParallelRuns,
              // storage: values.storage ? values.storage + " GB": "",
            },
          ],
        };

        if (!isAzureSignInType) {
          payload.password = values.password;
        }
        signUpRequest(payload)
          .then((response) => {
            if (response.data && response.data.responseObject) {
              history.push(`/registration-successful?plan=${formik.values.licensePlan}&email=${values.emailId}`);
              return true;
            } else if (response?.data?.responseCode === 400 || response?.data?.status === 'FAILURE'||response?.data?.message==="License limit exceeded") {
              setIsUserLimitExceded(true);
            }
            return false;
          })
          .catch((error) => {
            console.error('Failed while signing up API call', error);
            return false;
          });
      }
    },
  });

  function redirectToSignIn() {
    history.push('/signin');
  }

  //TODO: methods and validatins :: end

  return (
    <CommonPage
      wrapperClassname={isUserLimitExceded ? `common-padding` : `signup-padding`}
      marginTop={isUserLimitExceded ? 'mt-5' : isAzureSignInType ? 'mt-16' : ''}
    >
      {isUserLimitExceded ? (
        <Fragment>
          <div className="mt-11">
            <img src={warningicon} alt="warning icon" className="m-auto" />
            <div className={cx('text-center fontPoppinsMediumLg mt-9 mb-8', commonStyles['expired-text'])}>
              {REGISTRATION_CONSTANTS.REGISTRATION_RESTRICTED}
            </div>
            <Alert
              severity="info"
              className="fontPoppinsRegularLg"
              iconMapping={{
                info: <InfoIcon fontSize="inherit" className="mt-1" />,
              }}
            >
              {REGISTRATION_CONSTANTS.DEAR_USER}
            </Alert>
            <div className="mt-9 mb-6 flex items-center justify-center">
              <button
                className={cx('cursor-pointer', commonStyles['btn-bg-orange'], commonStyles['common-btn'])}
                onClick={redirectToSignIn}
              >
                <span className="fontPoppinsSemiboldLg">&nbsp;{GLOBAL_CONSTANTS.PROCEED_TO_SIGN_IN}</span>
              </button>
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className={cx('flex justify-center ', commonStyles['content-header'])}>Create an Account</div>
          <CommonBorder />
          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-col mt-4 mb-6">
              <div className="flex gap-34 justify-center">
                <LabeledInput
                  label="Name"
                  required
                  className={cx(commonStyles['common-input-label'])}
                  placeholder="Enter your name"
                  error={formik.errors.name && formik.touched.name}
                  value={formik.values.name}
                  onBlur={handleTrimmedBlur}
                  onChange={formik.handleChange}
                  autoComplete="off"
                  name="name"
                  autoFocus
                  helperText={<>{formik.touched.name && formik.errors.name}</>}
                  wrapperClassName="fontPoppinsRegularSm"
                  page="signup"
                />

                <LabeledInput
                  label="Email"
                  required
                  className={cx(commonStyles['common-input-label'])}
                  placeholder="Enter your email"
                  error={(formik.errors.emailId && formik.touched.emailId) || !!emailError}
                  value={formik.values.emailId}
                  onBlur={(event) => {
                    handleTrimmedBlur(event);
                    checkValidEmail(event);
                  }}
                  onChange={formik.handleChange}
                  autoComplete="new-password"
                  name="emailId"
                  onFocus={() => setEmailError('')}
                  helperText={
                    <div className="flex items-center justify-between mt-0.5">
                      {(formik.touched.emailId && formik.errors.emailId) || emailError}
                      {emailError && (
                        <span className="ml-14 inline fontPoppinsRegularSm">
                          <Link to="/signin" className="fontPoppinsSemiboldXXs text-blue-700 flex items-center">
                            <span className="pr-2">Sign In</span>
                            <LeftArrow />
                          </Link>
                        </span>
                      )}
                    </div>
                  }
                  wrapperClassName="w-4/5 fontPoppinsRegularSm"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {formik.errors.emailId || !formik.values.emailId ? (
                          <></>
                        ) : (
                          <span className="text-green-500 fontPoppinsRegularSm">
                            <CheckIcon />
                          </span>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  page="signup"
                />
              </div>
              {!isAzureSignInType && (
                <div className="flex gap-34 justify-center">
                  <div className="">
                    <LabeledPasswordInput
                      label="Password"
                      required
                      className={cx(commonStyles['common-input-label'])}
                      placeholder="Enter your password"
                      error={formik.errors.password && formik.touched.password}
                      value={formik.values.password}
                      onKeyDown={handleKeyDown}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      autoComplete="new-password"
                      name="password"
                      wrapperClassName="w-4/5 fontPoppinsRegularSm"
                      helperText={
                        <span className="fontPoppinsRegularXs8px flex justify-between">
                          {formik.touched.password && formik.errors.password}
                          {!formik.errors.password && formik.values.password ? (
                            <p className="goodPass fontPoppinsRegularXs8px -ml-3">Strong password, good</p>
                          ) : null}
                          {STRONG_PASSWORD_PATTERNS.some(({ pattern }) => !pattern.test(formik.values.password)) &&
                            (formik.values.password || formik.touched.password) && (
                              <Tooltip
                                data-html="true"
                                title={
                                  <div>
                                    {STRONG_PASSWORD_PATTERNS.map((passwordPattern) => {
                                      return (
                                        <Fragment key={passwordPattern.message}>
                                          {!passwordPattern.pattern.test(formik.values.password) && (
                                            <p>{passwordPattern.message}</p>
                                          )}
                                        </Fragment>
                                      );
                                    })}
                                  </div>
                                }
                                placement="bottom"
                              >
                                <span
                                  className={cx(
                                    'fontPoppinsRegularXs8px cursor-pointer w-0 mr-4',
                                    {
                                      'text-red-500': formik.touched.password && formik.errors.password,
                                      'text-blue-700 ml-auto': !formik.touched.password || !formik.errors.password,
                                    }
                                  )}
                                >
                                  <Icon name='info' />
                                </span>
                              </Tooltip>
                            )}
                        </span>
                      }
                      page="signup"
                    />
                  </div>
                  <div className="">
                    <LabeledPasswordInput
                      label="Confirm Password"
                      className={cx(commonStyles['common-input-label'])}
                      required
                      placeholder="Confirm your password"
                      wrapperClassName="w-4/5 fontPoppinsRegularSm"
                      error={formik.errors.confirmPassword && formik.touched.confirmPassword}
                      value={formik.values.confirmPassword}
                      onBlur={formik.handleBlur}
                      autoComplete="off"
                      onKeyDown={handleKeyDown}
                      onChange={formik.handleChange}
                      name="confirmPassword"
                      helperText={
                        <span className="fontPoppinsRegularXs8px">
                          {formik.touched.confirmPassword && formik.errors.confirmPassword}
                          {!formik.errors.confirmPassword &&
                          formik.values.confirmPassword === formik.values.password &&
                          formik.values.confirmPassword.length > 7 ? (
                            <span className="goodPass fontPoppinsRegularXs8px -ml-3">Password matched</span>
                          ) : null}
                        </span>
                      }
                      page="signup"
                    />
                  </div>
                </div>
              )}
              {/* <div className={cx("teygsjhgcj", style["signup_recaptch_container"])}>
                  <RecaptchaDeclaration
                    handleVerifyCaptacha={verifyCaptcha}
                    handleCaptchaExpiry={resetCaptcha}
                    handleCaptchaLoad={resetCaptcha}
                    error={formik.touched.recaptcha && formik.errors.recaptcha}
                  />
                </div> */}
            </div>
            <div className="flex justify-center flex-col items-center gap-4">
              <button
                type="submit"
                className={cx('fontPoppinsSemiboldLg', commonStyles['common-btn'], commonStyles['btn-bg-orange'])}
              >
                {GLOBAL_CONSTANTS.REGISTER}
              </button>
              <div className={cx('fontPoppinsSemiboldSm')}>
                <span>{GLOBAL_CONSTANTS.ALREADY_HAVE_AN_ACCOUNT}</span>
                <Link to="/signin" className={cx('ml-2', 'text-rs-primary')}>
                  Sign in
                </Link>
              </div>
            </div>
          </form>
        </Fragment>
      )}
    </CommonPage>
  );
}

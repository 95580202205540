import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';
import { terminateExecutionReq } from '@api/api_services';
import { getTruncatedText } from '@src/util/common_utils';

const TerminateWarningComponent = (props) => {
  const [open, setOpen] = React.useState(true);

  const handelTerminate = async () => {
    const { _id, name } = props?.executionData || {};
    if (!props?.terminateExecution) {
      try {
        const res = await terminateExecutionReq(_id, {});
        if (res?.data?.status === 'SUCCESS') {
          let terminate = getTruncatedText(name, 30);
          props.MyAlert.success(`${terminate} execution terminated successfully`);
        } else {
          props.MyAlert.warning(`${res?.data?.message}`);
        }
        props?.setShowWarningModal(false);
        props?.reloadExecTab(true);
        setOpen(false);
      } catch (err) {
        console.error('Terminate Execution Req', err);
      }
    } else {
      props?.terminateExecution(_id, name);
      props?.setShowWarningModal(false);
      setOpen(false);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed  terminate_zIndex inset-0 overflow-y-auto"
        open={open}
        //ADDED EMPTY FUNCTION TO AVOID BACKDROP CLICK
        onClose={() => {}}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block marginupper pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/4">
              <div className="inline-block">
                <div className="flex flex-start ml-2">
                  <span className="pt-5 pl-3">
                    <ExclamationIcon className="h-6 w-6 text-yellow-600 " />
                  </span>
                  <Dialog.Title as="h3" className="text-sm leading-6 ml-4 pt-3 font-small text-gray-900">
                    <span className="fontPoppinsSemiboldLg block">Warning </span>
                    <span className="fontPoppinsMediumMd">{props?.actionKey} </span>
                  </Dialog.Title>
                </div>
              </div>
              <hr className="line mt-px" />
              <div className="fontPoppinsMediumMd mt-3 text-gray-500 ml-16 mr-8">
                <p className="">Are you sure that you want to terminate {props?.executionData?.name} Execution?</p>
                <p className="mt-2"> How do you want to proceed?</p>
              </div>
              <hr className="line mt-3" />
              <div className="mt-2 pb-2 flex flex-row float-right">
                <button
                  type="button"
                  className="mt-3 gray-btn ml-1"
                  onClick={() => {
                    setOpen(false);
                    props?.setShowWarningModal(false);
                  }}
                >
                  Close
                </button>
                <button type="button" className="mt-3 ml-4 primary-btn " onClick={() => handelTerminate()}>
                  Confirm
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default TerminateWarningComponent;

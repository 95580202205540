import React, { useEffect, useState } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import EmailDropdown from './email-configuration-Dropdown';
import { getAllEmailGroupListReq } from '@src/api/api_services';
import { scrollIntoView } from '@pages/test-development/script/modules/module/utils/common-functions';
import { Checkbox } from '@mui/material';

function DefectPopup(props) {
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [selectedEmailData, setSelectedEmailData] = useState([]);
  const [selectedConfigEmailData, setSelectedConfugEmailData] = useState({});
  const [emailData, setEmailData] = useState(props?.emailData ? [...props.emailData] : []);
  const [alteringPayload, setAlteringPayload] = useState({
    ...props?.emailPayload,
  });
  const [stateArray, setStateArray] = useState([]);

  const setEmailValues = () => {
    let allEmails = [];
    let emailsForOptions = [];
    setAlteringPayload({ ...props?.emailPayload });
    if (props?.mode === 'edit') {
      props?.emailPayload?.additionalRecipientGroups?.forEach((data) => {
        let index = emailData.findIndex((optionValue) => optionValue.name === data);
        allEmails.push(emailData[index]);
      });
      props?.emailPayload?.additionalRecipients?.forEach((data) => {
        allEmails.push({ label: data, value: data });
        emailsForOptions.push({ label: data, value: data });
      });
    }
    if (props?.emailPayload.emailAllUsers === 'yes') {
      setShowCheckbox(false);
      if (allEmails.length) {
        setSelectedEmailData([...allEmails]);
      } else {
      }
    } else {
      if (allEmails.length) {
        setSelectedEmailData([...allEmails]);
      }

      setShowCheckbox(true);
    }
    let configEmails = {};
    props?.emailPayload?.stateTransitions?.forEach((data) => {
      configEmails[data.state] = [];
      data?.emailGroups?.forEach((emailgrpdata) => {
        let index = emailData.findIndex((optionValue) => optionValue.name === emailgrpdata);
        configEmails[data.state].push(emailData[index]);
      });
      data?.emailAddresses?.forEach((emaildata) => {
        configEmails[data.state].push({ label: emaildata, value: emaildata });
        emailsForOptions.push({ label: emaildata, value: emaildata });
      });
    });
    if (emailsForOptions.length) {
      let options = [...emailData];
      options.push(...emailsForOptions);
      setEmailData([...options]);
    }
    setSelectedConfugEmailData({ ...configEmails });
  };

  const handleSelectChange = (type, index) => (values) => {
    let allemails = { ...alteringPayload };
    let configEmails = { ...selectedConfigEmailData };
    if (type === 'allEmail') {
      let emails = [];
      let emailGroups = [];

      setSelectedEmailData([...values]);
      values.forEach((data, index) => {
        if (data?.id) {
          emailGroups.push(data.name);
        } else {
          emails.push(data.label);
        }
      });
      allemails.additionalRecipients = [...emails];
      allemails.additionalRecipientGroups = [...emailGroups];
      setAlteringPayload({ ...allemails });
      props?.getEmailPayloadData({ ...allemails });
    } else {
      let emails = [];
      let emailGroups = [];
      configEmails[type] = [...values];
      setSelectedConfugEmailData({ ...configEmails });
      values.forEach((data, index) => {
        if (data?.id) {
          emailGroups.push(data.name);
        } else {
          emails.push(data.label);
        }
      });
      allemails.stateTransitions[index].emailAddresses = [...emails];
      allemails.stateTransitions[index].emailGroups = [...emailGroups];
      setAlteringPayload({ ...allemails });
      props?.getEmailPayloadData({ ...allemails });
    }
  };

  const handleEmailOptions = (state, index) => (value, type) => {
    if (type === 'add') {
      if (state && state !== 'all') {
        let selectedOptions = { ...selectedConfigEmailData };
        let allemails = { ...alteringPayload };
        selectedOptions[state].push(value);
        allemails.stateTransitions[index].emailAddresses.push(value.label);
        setAlteringPayload({ ...allemails });
        props?.getEmailPayloadData({ ...allemails });
        setSelectedConfugEmailData({ ...selectedOptions });
      }
      let options = [...emailData];
      options.push(value);
      setEmailData([...options]);
    } else if (type === 'remove') {
      if (state) {
        let selectedOptions = { ...selectedConfigEmailData };
        let allemails = { ...alteringPayload };
        let i = selectedOptions[state]?.findIndex((optionValue) => optionValue?.label === value?.label);
        selectedOptions[state]?.splice(i, 1);
        let emailindex = allemails?.stateTransitions[index]?.emailGroups?.indexOf(value?.label);
        if (emailindex === -1) {
          emailindex = allemails?.stateTransitions[index]?.emailAddresses?.indexOf(value?.label);
          allemails?.stateTransitions[index]?.emailAddresses?.splice(emailindex, 1);
        } else {
          allemails?.stateTransitions[index]?.emailGroups?.splice(emailindex, 1);
        }
        setAlteringPayload({ ...allemails });
        props?.getEmailPayloadData({ ...allemails });
        setSelectedConfugEmailData({ ...selectedOptions });
      }
      if (!value?.id) {
        let options = [...emailData];
        let index = options.findIndex((optionValue) => optionValue.label === value.label);
        options.splice(index, 1);
        setEmailData([...options]);
      }
    }
  };
  const getEmailData = async () => {
    let response;
    try {
      response = await getAllEmailGroupListReq();
      if (response?.data?.responseObject?.length) {
        setEmailData([
          ...response?.data?.responseObject.map((user) => ({
            ...user,
            label: user.name,
            value: user.name,
          })),
        ]);
      }
    } catch (err) {
      console.error('get all email group api error', err);
    }
  };

  useEffect(() => {
    setStateArray([...props.defectLifeCycleState]);
  }, [props?.defectLifeCycleState]);
  useEffect(() => {
    if (props.mode !== 'edit') {
      setAlteringPayload({ ...props?.emailPayload });
      let configEmails = {};
      props?.emailPayload.stateTransitions?.forEach((data) => {
        configEmails[data.state] = [];
      });

      setShowCheckbox(false);
      setSelectedConfugEmailData({ ...configEmails });
      setSelectedEmailData([]);
    } else if (props.mode === 'edit' && props?.emailPayload) {
      setEmailValues();
    }
  }, [props?.emailPayload]);

  useEffect(() => {
    getEmailData();
  }, []);

  const EmailForEachState = ({ data, index }) => {
    return (
      <div className="w-30v fontPoppinsRegularMd">
        <div className="mx-2 -mt-1">
          <EmailDropdown
            data={emailData}
            disabled={false}
            placeholder={'Enter email or email group name'}
            handleSelectChange={handleSelectChange(data, index)}
            selectedData={selectedConfigEmailData[data]}
            valueNumbers={2}
            handelOptions={handleEmailOptions(data, index)}
            onDropdownOpen={scrollIntoView}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="">
      <div>
        <div className="grid">
          <FormControl>
            <div className="flex">
              <div>
                <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                  <div className="flex">
                    <div>
                      <FormControlLabel
                        onClick={() => {
                          let allemails = { ...alteringPayload };
                          allemails.emailAllUsers = 'yes';
                          if (
                            alteringPayload?.additionalRecipients?.length > 0 ||
                            alteringPayload?.additionalRecipientGroups?.length > 0
                          ) {
                            setShowCheckbox(false);
                          } else {
                            setShowCheckbox(false);
                          }
                          setAlteringPayload({ ...allemails });
                          props?.getEmailPayloadData({ ...allemails });
                        }}
                        value="all"
                        control={<Radio color="primary" checked={alteringPayload.emailAllUsers === 'yes'} />}
                      />
                    </div>
                    <div className="-ml-4 mt-1.5 fontPoppinsRegularMd">
                      <label className="mr-5 text-sm">
                        Send email to everyone in the project for <br></br> each state change
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className="mt-5 mb-5 w-40v fontPoppinsRegularMd">
                      <EmailDropdown
                        data={emailData}
                        disabled={false}
                        placeholder={'Enter email or email group name'}
                        handleSelectChange={handleSelectChange('allEmail')}
                        selectedData={selectedEmailData}
                        valueNumbers={3}
                        handelOptions={handleEmailOptions('all', -1)}
                        onDropdownOpen={scrollIntoView}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row">
                    <div>
                      <FormControlLabel
                        onClick={() => {
                          let allemails = { ...alteringPayload };
                          allemails.emailAllUsers = 'no';

                          setShowCheckbox(true);
                          setAlteringPayload({ ...allemails });
                          props?.getEmailPayloadData({ ...allemails });
                        }}
                        value="configure"
                        control={<Radio color="primary" checked={alteringPayload.emailAllUsers === 'no'} />}
                      />
                    </div>
                    <div className="-ml-4 mt-2 fontPoppinsRegularMd">
                      <label>
                        Configure email to everyone in the project for <br></br>
                        each state change
                      </label>
                    </div>
                  </div>

                  {showCheckbox && (
                    <div className="">
                      <div className=" flex flex-col mt-2 justify-items-start">
                        {stateArray.map((data, index) => {
                          return (
                            <div className="mr-1 flex mt-4">
                              <div>
                                {' '}
                                <Checkbox size="small" color="primary" checked={true} className="opacity-70" />
                              </div>
                              <label
                                className=" w-20 path-trimmer mt-1 mx-2 text-blue-700 fontPoppinsRegularMd"
                                title={data}
                              >
                                {data}
                              </label>
                              <EmailForEachState data={data} index={index} />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </RadioGroup>
              </div>
            </div>
          </FormControl>
        </div>
      </div>
    </div>
  );
}

export default DefectPopup;

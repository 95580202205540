import React, { useState, useEffect, useRef } from 'react';
import styles from './analytics.module.scss';
import cx from 'classnames';
import FailureReasonChart from './common/FailureReasonChart';
import AnalyticTable from './common/analyticTable';
import { colors } from '@src/css_config/colorConstants';
import { noDataMessage } from '@common/ui-constants';
import { ReactComponent as LeftArrow } from '@assets/analytics/CarouselLeftArrow.svg';
import { ReactComponent as RightArrow } from '@assets/analytics/CarouselRightArrow.svg';
import { getTableData } from './common/util';
import CardSkeleton from './CardSkeleton';

import 'slick-carousel/slick/slick.css'; // require for Slider
import 'slick-carousel/slick/slick-theme.css'; // require for Slider
import Slider from 'react-slick';

const FailureReasonsComp = (props) => {
  let {
    tagScripts,
    scriptResultData,
    openFailureReasonResultModal,
    closeResultModal,
    selectedScript,
    selectedScriptName,
    getScriptResultData,
    setSelectedScript,
    setSelectedScriptName,
    suiteMode,
    reqBody,
    callTableData,
  } = props;

  const [failureReasonsBg, setFailureReasonsBg] = useState(tagScripts?.[0]?.tagName || '');
  const [failureReasonsData, setFailureReasonsData] = useState([]);

  const [stopScrollApiCall, setStopScrollApiCall] = useState(0); //? to stop scroll api on count of totalScripts
  const [failureReasonScrollPos, setFailureReasonsScrollPos] = useState(false);
  const [initTable, setInitTable] = useState({
    from: 0,
    to: 20,
  });
  const [initTableCall, setInitTableCall] = useState(true);
  const failureReasonsRef = useRef();

  const handleFailureReasonsData = async (tableTagName) => {
    setFailureReasonsBg(tableTagName);
    setInitTable({ from: 0, to: 20 });
    failureReasonsRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const getFailureReasonsData = async (from, to, tagName) => {
    let tableHeader = `tag-${tagName}`;
    try {
      if (from === 0 && to === 20) {
        let data = await getTableData(reqBody, tableHeader, from, to);
        setStopScrollApiCall(data?.totalScripts);
        setFailureReasonsData(data?.scripts);
      } else {
        if (stopScrollApiCall > from && failureReasonsBg !== '') {
          let data = await getTableData(reqBody, tagName, from, to);
          setStopScrollApiCall(data?.totalScripts);
          setFailureReasonsData([...failureReasonsData, ...data?.scripts]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (tagScripts?.length > 0) {
      getFailureReasonsData(0, 20, tagScripts?.[0]?.tagName);
    } else {
      setFailureReasonsBg('');
      setFailureReasonsData([]);
    }
    setInitTableCall(false);
  }, []);
  useEffect(() => {
    if (tagScripts?.length === 0) {
      setFailureReasonsBg('');
      setFailureReasonsData([]);
    } else {
      setFailureReasonsBg(tagScripts?.[0]?.tagName);
      setInitTable({
        from: 0,
        to: 20,
      });
    }
  }, [tagScripts]);

  useEffect(() => {
    if (callTableData && initTableCall === false && tagScripts?.[0]?.tagName) {
      setFailureReasonsBg(tagScripts?.[0]?.tagName);
      setInitTable({
        from: 0,
        to: 20,
      });
      failureReasonsRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      getFailureReasonsData(0, 20, tagScripts?.[0]?.tagName);
    }
  }, [callTableData]);

  useEffect(() => {
    if (failureReasonsBg !== '' && initTableCall === false) {
      setTimeout(() => {
        getFailureReasonsData(initTable.from, initTable.to, failureReasonsBg);
      }, 500);
    }
  }, [failureReasonsBg]);

  useEffect(() => {
    if (failureReasonScrollPos) {
      setInitTable({
        from: initTable.to,
        to: initTable.to + 10,
      });
    }
  }, [failureReasonScrollPos]);

  useEffect(() => {
    if (initTable.from !== 0 && initTable.to !== 20) {
      getFailureReasonsData(initTable.from, initTable.to, `tag-${failureReasonsBg}`);
    }
  }, [initTable]);

  let failureReasonsColumn = [
    {
      Header: 'Name',
      accessor: 'name',
      width: '20%',
      Cell: ({ row, value }) => {
        let { scriptId, executionId, name , clientId , runId} = row.original;
        return (
          <div
          className={`overflow-hidden overflow-ellipsis whitespace-nowrap pr-2 rs-blue ${runId ? 'cursor-pointer' : 'cursor-default rs-skipped'}`}
            onClick={() => {
              if (runId && suiteMode === 'automation-suite') {
                getScriptResultData(scriptId, executionId, clientId , runId , 'failureAnalysisModal');
                setSelectedScript({ scriptId, executionId });
                setSelectedScriptName(name);
              }
            }}
            title={value}
          >
            {value}
          </div>
        );
      },
    },
    {
      Header: 'Module',
      accessor: 'pathData',
      width: '20%',
      disableGlobalFilter: true,
      Cell: ({ value }) => {
        return (
          <div className="overflow-hidden overflow-ellipsis whitespace-nowrap pr-2" title={value.path}>
            {value.module}
          </div>
        );
      },
    },
    {
      Header: 'Execution Id',
      accessor: 'executionId',
      width: '20%',
      Cell: ({ value }) => {
        return <div className="overflow-hidden overflow-ellipsis whitespace-nowrap pr-2">{value}</div>;
      },
    },
    {
      Header: 'Tagged Steps',
      accessor: 'count',
      width: '20%',
      disableGlobalFilter: true,
      Cell: ({ value }) => {
        return <div className="overflow-hidden overflow-ellipsis whitespace-nowrap pr-2">{value}</div>;
      },
    },
  ];

  function CarouselPrevArrow(props) {
    const { className, style, onClick, currentSlide } = props;
    const showLeftArrow = currentSlide !== 0;
    return (
      showLeftArrow && <LeftArrow className={`carouselArrow ${className}`} onClick={onClick} style={{ ...style }} />
    );
  }

  function CarouselNextArrow(props) {
    const { className, style, onClick, currentSlide, slideCount } = props;
    const disbalerightArrow = !className.includes('slick-disabled');
    return (
      disbalerightArrow && (
        <RightArrow className={`carouselArrow ${className}`} onClick={onClick} style={{ ...style }} />
      )
    );
  }

  return (
    <section className={styles['failureReasonsBlock']}>
      <div className={styles['failureReasonsCarouselBlock']}>
        <div className={cx(styles['failureReasonsHeader'], 'fontPoppinsSemiboldSm uppercase')}>Failure Reasons</div>

        {tagScripts?.length === 1 && (
          <div className="flex items-center justify-evenly">
            {tagScripts?.map((reason) => {
              let { tagName, percentage } = tagScripts;
              return (
                <FailureReasonChart
                  key={`${tagName}-${percentage}`}
                  reason={reason}
                  failureReasonsBg={failureReasonsBg}
                  setFailureReasonsBg={setFailureReasonsBg}
                  handleClick={handleFailureReasonsData}
                />
              );
            })}
            <CardSkeleton />
            <CardSkeleton />
          </div>
        )}

        {tagScripts?.length === 2 && (
          <div className="flex items-center justify-evenly">
            {tagScripts?.map((reason, index) => {
              let { tagName, percentage } = tagScripts;
              return (
                <FailureReasonChart
                  key={`tag-${index}`}
                  reason={reason}
                  failureReasonsBg={failureReasonsBg}
                  setFailureReasonsBg={setFailureReasonsBg}
                  handleClick={handleFailureReasonsData}
                />
              );
            })}
            <CardSkeleton />
          </div>
        )}

        {tagScripts?.length >= 3 && (
          <div className="tagged_carosal px-2">
            <Slider
              dots={false}
              slidesToShow={3}
              slidesToScroll={3}
              autoplay={false}
              autoplaySpeed={3000}
              infinite={false}
              nextArrow={<CarouselNextArrow />}
              prevArrow={<CarouselPrevArrow />}
            >
              {tagScripts?.map((reason) => {
                let { tagName, percentage } = tagScripts;
                return (
                  <FailureReasonChart
                    key={`${tagName}-${percentage}`}
                    reason={reason}
                    failureReasonsBg={failureReasonsBg}
                    setFailureReasonsBg={setFailureReasonsBg}
                    handleClick={handleFailureReasonsData}
                  />
                );
              })}
            </Slider>
          </div>
        )}
      </div>

      <div className={styles['failureReasonsTable']}>
        <AnalyticTable
          ref={failureReasonsRef}
          setScrollPos={setFailureReasonsScrollPos}
          color={colors.rs_failed}
          header={failureReasonsBg || tagScripts?.[0]?.tagName}
          columns={failureReasonsColumn}
          data={failureReasonsData || []}
          message={noDataMessage.failureReason}
          scriptResultData={scriptResultData}
          openScriptResultModal={openFailureReasonResultModal}
          closeResultModal={closeResultModal}
          selectedScript={selectedScript}
          selectedScriptName={selectedScriptName}
          headertextTransFrom="none"
        />
      </div>
    </section>
  );
};

export default FailureReasonsComp;

import React, { useState } from 'react';
import Modal from 'react-modal';
import { isEmptyValue } from '@src/util/common_utils';
import '@pages/test-development/test-development.scss';

function DetailsModal(props) {
  const [openModal, setOpenModal] = useState(true);
  const projectDetails = JSON.parse(localStorage.getItem('selected-project'));
  let labelTitle = '';
  props?.data?.projectLabels?.forEach((system, index) => {
    labelTitle += (system?.name ? system?.name : '') + `${index < props?.data?.projectLabels?.length - 1 ? ',' : ' '}`;
  });
  const scriptResourceId = props?.data?.id.includes('STP_GRP') ? props?.data?.displayName : props?.data?.prefix;
  const customStylesFolder = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: '510px',
      width: '600px',
      boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
      background: '#fbfcfd 0% 0% no-repeat padding-box',
      borderRadius: '4px',
      opacity: '1',
    },
  };
  return (
    <Modal
      isOpen={openModal}
      style={customStylesFolder}
      className="p-px"
      onRequestClose={() => {
        props.closeModal(false);
        setOpenModal(false);
      }}
    >
      <div className="mt-2 p-1">
        <p title={scriptResourceId} className="Popup-header-common inline-block w-10/12 pl-3 text-overflow-style">
          {props.type === 'folder' ? (
            <span>Module</span>
          ) : props.type === 'file' ? (
            <span>Script</span>
          ) : (
            <span>Step Group</span>
          )}{' '}
          Details - <span className="">{props.type === 'folder' ? props.data.name : scriptResourceId}</span>
        </p>
        <div className="float-right">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="ml-5 mr-5 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="border-b border-t border-blue-100 mt-px mb-px pb-1">
        {props.type === 'folder' ? (
          <div className="ml-6 mr-6 mt-3 grid grid-cols-2 gap-4">
            <div className="">
              <h1 className="details-key-style-common">Name</h1>
              <p title={props.data.name} className="text-gray-900 text-overflow-style">
                <span className="details-data-style-common">{props.data.name}</span>
              </p>
            </div>
            <div className="">
              <h1 className="details-key-style-common">Description</h1>
              <p title={props.data.desc} className="text-gray-900 text-overflow-style">
                <span className="details-data-style-common">{props.data.desc ? props.data.desc : '--'}</span>
              </p>
            </div>
            <div className="">
              <h1 className="details-key-style-common">Project Name</h1>
              <p title={projectDetails.name} className="text-gray-900 text-overflow-style">
                <span className="details-data-style-common">{projectDetails.name}</span>
              </p>
            </div>
            <div className="">
              <h1 className="details-key-style-common">Path</h1>
              <p title={props.data.path} className="text-gray-900 text-overflow-style">
                <span className="details-data-style-common">{props.data.path}</span>
              </p>
            </div>
            <div className="">
              <h1 className="details-key-style-common">Total Modules</h1>
              <p>
                <span className="details-data-style-common">{props.data.subModuleCount}</span>
              </p>
            </div>
            <div className="">
              <h1 className="details-key-style-common">Total Scripts</h1>
              <p>
                <span className="details-data-style-common">{props.data.scriptCount}</span>
              </p>
            </div>
            <div className="">
              <h1 className="details-key-style-common">Total Pre Conditions</h1>
              <p>
                {props.data ? (
                  <span className="details-data-style-common">{props.data.preConditionCount}</span>
                ) : (
                  <span className="details-data-style-common">--</span>
                )}
              </p>
            </div>
            <div className="">
              <h1 className="details-key-style-common">Total Post Conditions</h1>
              <p>
                {props.data ? (
                  <span className="details-data-style-common">{props.data.postConditionCount}</span>
                ) : (
                  <span className="details-data-style-common">--</span>
                )}
              </p>
            </div>
            <hr className="border-dashed border-blue-100 col-span-2" />
            <div className="">
              <h1 className="details-key-style-common">Created By </h1>
              <p>
                <span className="details-data-style-common">{props.data.createdByUname}</span>
              </p>
            </div>
            <div className="">
              <h1 className="details-key-style-common">Created On</h1>
              <p>
                <span className="details-data-style-common">{props.data.createdOn}</span>
              </p>
            </div>
            <div className="">
              <h1 className="details-key-style-common">Modified By </h1>
              <p>
                <span className="details-data-style-common">{props.data.modifiedByUname}</span>
              </p>
            </div>
            <div className="">
              <h1 className="details-key-style-common">Modified On</h1>
              <p>
                <span className="details-data-style-common">{props.data.modifiedOn}</span>
              </p>
            </div>
          </div>
        ) : (
          <div className="ml-6 mr-6 mt-3 mb-3 grid grid-cols-2 gap-4">
            <div className="">
              <h1 className="details-key-style-common">Name</h1>
              <p title={props.data.name} className="text-gray-900 text-overflow-style">
                <span className="details-data-style-common">{props.data.name}</span>
              </p>
            </div>
            <div className="">
              <h1 className="details-key-style-common">Description</h1>
              <p title={props.data.desc} className="text-gray-900 text-overflow-style">
                <span className="details-data-style-common">{props.data.desc ? props.data.desc : '--'}</span>
              </p>
            </div>
            <div className="">
              <h1 className="details-key-style-common">Type</h1>
              <p>
                <span className="details-data-style-common">
                  {props.type === 'file' ? props.data.scriptType : props.data.type}
                </span>
              </p>
            </div>
            <div className="">
              <h1 className="details-key-style-common">Project Name</h1>
              <p title={projectDetails.name} className="text-gray-900 text-overflow-style">
                <span className="details-data-style-common">{projectDetails.name}</span>
              </p>
            </div>
            <div className="">
              <h1 className="details-key-style-common">Path</h1>
              <p title={props.data.path} className="text-gray-900 text-overflow-style">
                <span className="details-data-style-common">{props.data.path}</span>
              </p>
            </div>
            <div className="">
              <h1 className="details-key-style-common">Labels</h1>
              <p title={labelTitle} className="text-gray-900 text-overflow-style">
                <span className="details-data-style-common">{!isEmptyValue(labelTitle) ? labelTitle : '--'}</span>
              </p>
            </div>

            <hr className="border-dashed border-blue-100 col-span-2" />
            <div className="">
              <h1 className="details-key-style-common">Created By </h1>
              <p>
                <span className="details-data-style-common">{props.data.createdByUname}</span>
              </p>
            </div>
            <div className="">
              <h1 className="details-key-style-common">Created On</h1>
              <p>
                <span className="details-data-style-common">{props.data.createdOn}</span>
              </p>
            </div>
            <div className="">
              <h1 className="details-key-style-common">Modified By </h1>
              <p>
                <span className="details-data-style-common">{props.data.modifiedByUname}</span>
              </p>
            </div>
            <div className="">
              <h1 className="details-key-style-common">Modified On</h1>
              <p>
                <span className="details-data-style-common">{props.data.modifiedOn}</span>
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="">
        <button
          onClick={() => {
            props.closeModal(false);
            setOpenModal(false);
          }}
          type="button"
          className="gray-btn float-right mt-3.5 mb-3 mr-5"
        >
          Close
        </button>
      </div>
    </Modal>
  );
}

export default DetailsModal;

import React, { useEffect, useState, useContext, Fragment } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { getAzureList, getAzurefileDownload } from '@api/api_services';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { CommonLoader } from '@src/pages/common/common-loader';
import { Tooltip } from '@material-ui/core';
import { useTable } from 'react-table';
import { ReactComponent as QuickStart } from '@assets/svg-imports/QuickStart.svg';
import { ReactComponent as AddIcon } from '@assets/svg-imports/addIcon.svg';
import DownloadIcon from '@mui/icons-material/Download';
import { AZURE_DEVOPS_CONSTANTS } from '../plugin_constants';
import { Menu, Transition } from '@headlessui/react';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useAlert } from '@pages/common/alert_service/useAlert';
import SearchBar from '@src/pages/analytics/common/search-bar';
import { HighlightText, isEmptyValue, isValueIncludes } from '@src/util/common_utils';
import NoResultsFound from '@src/pages/common/Table/NoResultFound';
import AzureCreateEditInstance from './azure-Create-Edit-Instance';
import AzureInstanceDetails from './azure-details';
import AzureDeleteInstance from './azureDeleteInstance';
import IconButton from '@material-ui/core/IconButton';
import { getHostname } from '@util/common_utils';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function AzureTable(props) {
  const { AlertContatiner, MyAlert } = useAlert();
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [message, setMessage] = useState('');
  const [reloadTree, setReloadTree] = useState(true);
  let [isLoading, setIsLoading] = useState(false);
  const [account, setAccount] = useState([]);
  const [accountData, setAccountData] = useState();
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [mode, setMode] = useState('');
  let [isHighlight, setIsHighlight] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');
  const [openInstance, setOpenInstance] = useState(false);
  const [selectedInstance, setSelectedInstance] = useState();
  const [actionType, setActionType] = useState();
  const [openInstanceDetails, setOpenInstanceDetails] = useState(false);
  const [pageIndexValue, setPageIndex] = useState(0);

  let reloadpage = () => {
    setReloadTree(true);
  };
  const [tabsList, setTabsList] = useState([
    {
      name: 'Azure DevOps',
      pathUrl: `/configuration/emailConfiguration/emailGroup`,
      selectedName: 'emailGroup',
    },
  ]);

  const renderTabs = () => {
    return (
      <div>
        <ul className="navBar table-header-text flex flex-row primary-tab ml-6">
          {tabsList.map((tab, index) => {
            return (
              <li key={tab.name}>
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    props.history.push(tab.pathUrl);
                  }}
                  className={`selected_tab_style`}
                >
                  {tab.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };
  const getAzureData = () => {
    if (props.data == 'azure') {
      resetProgress();
      startOrResumeProgress({
        stopAt: 90,
      });
      getAzureList()
        .then((response) => {
          stopProgress();
          startOrResumeProgress({
            stopAt: 100,
            intervalDuration: 10,
          });
          if (response?.data?.responseObject) {
            response?.data?.responseObject.map((res, i) => {
              res.Actions = [];
              res.Actions.push(
                <div className="actionIcons">
                  <Tooltip title="Edit">
                    <EditIcon
                      className="float-left  project-action action-button mr-3 mt-1 cursor-pointer"
                      onClick={() => {
                        setMode('EDIT');
                        openModal();
                        setAccountData(res);
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Delete">
                    <DeleteOutlinedIcon
                      className="float-left  project-action action-button mr-3 mt-1 cursor-pointer"
                      onClick={() => {
                        setAccountData(res);
                        setMessage(`${res.name} Instance`);
                        setOpenDeleteModal(true);
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Download">
                    <DownloadIcon
                      className="float-left  project-action action-button mr-3 mt-1 cursor-pointer"
                      onClick={() => {
                        getAzurefileDownloader(res.id, res);
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="More">
                    <Menu as="div" className="relative inline-block text-left mt-1" key="user-menu">
                      {({ open }) => (
                        <>
                          <div>
                            <Menu.Button
                              className="flex items-center  focus:outline-none "
                              onMouseOver={(e) => {
                                let mouse = e.clientY;
                                setPageIndex(mouse);
                              }}
                            >
                              <span className="action-button">
                                {' '}
                                <MoreVertIcon />
                              </span>
                            </Menu.Button>
                          </div>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className={`${pageIndexValue > 520 ? '-mt-24 ' : 'ml-2'} origin-top-right absolute right-0 z-40 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                            >
                              <div className="py-5">
                                <Menu.Item>
                                  <div
                                    onClick={() => {
                                      setAccountData(res);
                                      openInstanceModal();
                                    }}
                                    className="block px-4 py-1 text-sm details-background"
                                  >
                                    {AZURE_DEVOPS_CONSTANTS.DETAILS}
                                  </div>
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </Tooltip>
                </div>
              );
              res.slNo = i + 1;
            });
            setAccount((d) => response.data.responseObject);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setAccount([]);
          }
        })
        .catch((err) => {
          resetProgress();
          console.error('GET_AZURE_LIST :', err);
        });
    }
  };
  const getAzurefileDownloader = (e, result) => {
    const environment = getHostname();
    getAzurefileDownload(e, environment).then((res) => {
      saveFileFromBlob(res.data, `${result.name}.zip`);
    });
  };

  function saveFileFromBlob(blob, filename) {
    if (blob) {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }, 0);
      }
    }
  }

  const openModal = (value, actionType) => {
    setOpenInstance(true);
    setSelectedInstance(value);
    setActionType(actionType);
  };

  const closeModal = (value) => {
    if (openAccountModal) {
      setOpenAccountModal(false);
    } else if (openDeleteModal) {
      setOpenDeleteModal(false);
    } else if (openDetailsModal) {
      setOpenDetailsModal(false);
    } else if (openInstance) {
      setOpenInstance(false);
    }
  };
  const openInstanceModal = (value, actionType) => {
    setOpenInstanceDetails(true);
    setSelectedInstance(value);
  };
  const closeInstanceModal = () => {
    setOpenInstanceDetails(false);
  };

  const openModalDelete = (value) => {
    setOpenDeleteModal(true);
    setSelectedInstance(value);
  };
  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  // TODO :: handle Search filter :: START
  const [userSearched, setuserSearched] = useState('');
  const [data, setData] = useState([]);
  let [openSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    setData(account);
    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [account]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(account);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setData(account);
    setSearchTextHighlight('');
    setIsHighlight(!isHighlight);
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (!e?.target?.value == '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = account.filter(({ name, projectName, modifiedByUname, suiteName }) => {
          return (
            isValueIncludes(e, name) ||
            isValueIncludes(e, projectName) ||
            isValueIncludes(e, modifiedByUname) ||
            isValueIncludes(e, suiteName)
          );
        });
        setData(newData);
        setSearchTextHighlight(e?.target?.value || e);
        setIsHighlight(!isHighlight);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  // TODO :: handle Search filter :: END

  const columns = React.useMemo(
    () => [
      {
        Header: 'INSTANCE NAME',
        accessor: 'name',
        disableFilters: true,
        Cell: (e) => (
          <div className="table-content float-left fontPoppinsMediumSm name-trimmer" title={e.value}>
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'PROJECT NAME',
        accessor: 'projectName',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="table-content float-left fontPoppinsMediumSm name-trimmer" title={value}>
              <HighlightText text={value} highlight={searchTextHighlight} />
            </div>
          );
        },
      },
      {
        Header: 'SUITE NAME',
        accessor: 'suiteName',
        disableFilters: true,
        Cell: (e) => (
          <div className="table-content float-left fontPoppinsMediumSm  name-trimmer" title={e.value}>
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'MODIFIED BY',
        accessor: 'modifiedByUname',
        disableFilters: true,
        width: '18%',
        Cell: (e) => (
          <div title={e.value} className="table-content float-left fontPoppinsMediumSm name-trimmer">
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'ACTIONS',
        accessor: 'Actions',
        disableFilters: true,
        width: '15%',
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common fontPoppinsMediumSm">{value}</div>;
        },
      },
    ],
    [isHighlight]
  );
  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data,
  });

  useEffect(() => {
    if (reloadTree) {
      setIsLoading(true);
      getAzureData();
    }
    setReloadTree(false);
  }, [reloadTree]);

  return (
    <>
      <div className="page-list-body ">
        <div className="alert-position-style">
          <AlertContatiner></AlertContatiner>
        </div>
        <div>{renderTabs()}</div>
        <div className="page-table-body overflow-y-hidden">
          <div className="content_area_style_Int overflow-y-hidden">
            <div className="content_area_style_tcTemplate overflow-y-hidden">
              <div className="pt-3 pb-3 border_style flex flex-wrap items-center justify-end">
                <div className="flex items-center justify-end  w-2/5 mr-40 mt-2">
                  <SearchBar
                    open={openSearch}
                    searchValue={userSearched || ''}
                    handleSearch={(e) => {
                      setuserSearched(e.target.value);
                    }}
                    onSearchClose={handleSearchClose}
                    handleSearchOpen={handleSearchOpen}
                    enterKeyHandler={handleSearchSubmit}
                    disable={isEmptyValue(account)}
                  />
                </div>
                <div
                  className="grid grid-cols-12 col-span-11 addTempBtn flex-row cursor-pointer mr-5"
                  onClick={() => {
                    setMode('ADD');
                    openModal();
                  }}
                >
                  <span className="col-span-2">
                    <AddIcon className="addDefectIcon" />
                  </span>
                  <span className=" col-span-10 absolute mt-2 fontPoppinsMediumSm create-instance-text">
                    {AZURE_DEVOPS_CONSTANTS.INSTANCE}
                  </span>
                </div>
              </div>
              <div className="table-height overflow-y-auto overflow-x-hidden mx-3" id="journal-scroll">
                <table {...getTableProps()} className="h-3">
                  <thead className="projectDetailsDataa">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()} className="text-sm h-9 ml-4  top-0">
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps()}
                            width={column.width}
                            className=" text-left mx-2 fontPoppinsSemiboldSm pl-3 table-line"
                          >
                            <span className="table-header-text">{column.render('Header')}</span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()} className="text text-gray-700">
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          className="configHover table-row-height hover:bg-gray-100 mt-10 h-10"
                        >
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="p-0  text-left text-gray-700 fontPoppinsRegularMd pl-3 project-row"
                              >
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {isLoading ? (
                  <CommonLoader />
                ) : isEmptyValue(account) ? (
                  <div className="fontPoppinsRegularMd no-steps">
                    <div>
                      <span className=" ml-10 mb-5">
                        <QuickStart className="quick-start" />
                      </span>
                      <p className="fontPoppinsSemiboldMd mb-4">{AZURE_DEVOPS_CONSTANTS.QUICK_START}</p>
                      <div className="flex flex-row">
                        <div className="fontPoppinsRegularSm mt-2">{AZURE_DEVOPS_CONSTANTS.STEP_1_CLICK}</div>
                        <div
                          className="grid grid-cols-12 col-span-11 QuickStartAddBtn  cursor-pointer ml-4"
                          onClick={() => {
                            openModal();
                            setMode('ADD');
                          }}
                        >
                          <span className="col-span-2">
                            <AddIcon className="addDefectIcon" />
                          </span>
                          <span className=" col-span-10 absolute mt-2 fontPoppinsMediumSm create-instance-text">
                            {AZURE_DEVOPS_CONSTANTS.INSTANCE}
                          </span>
                        </div>
                        <div className="fontPoppinsRegularSm mt-2 ml-1">{AZURE_DEVOPS_CONSTANTS.ADDING_INSTANCE}</div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>{userSearched && isEmptyValue(data) && <NoResultsFound />}</>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {openInstance && (
        <AzureCreateEditInstance
          isOpen={openInstance}
          onClose={closeModal}
          seletedInstance={selectedInstance}
          actionType={actionType}
          reloadTree={reloadpage}
          MyAlert={MyAlert}
          data={accountData}
          mode={mode}
        />
      )}
      {openInstanceDetails && (
        <AzureInstanceDetails
          isOpen={openInstanceDetails}
          onClose={closeInstanceModal}
          seletedInstance={selectedInstance}
          reloadTree={reloadpage}
          data={accountData}
        />
      )}
      {openDeleteModal && (
        <AzureDeleteInstance
          openModal={openModalDelete}
          closeModal={closeDeleteModal}
          seletedInstance={selectedInstance}
          actionType={actionType}
          reloadTree={reloadpage}
          MyAlert={MyAlert}
          data={accountData}
        />
      )}
    </>
  );
}

export default AzureTable;

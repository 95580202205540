import React, { useEffect, useState, useContext } from 'react';
import { useFormik } from 'formik';
import './auth.scss';
import Basicauth from '../Authorization/AuthType/basic_auth';
import Bearertoken from '../Authorization/AuthType/bearer_token';
import Digestauth from '../Authorization/AuthType/digest_auth';
import Hawk from '../Authorization/AuthType/hawk';
import Oauth1 from '../Authorization/AuthType/oauth1';
import Oauth2 from '../Authorization/AuthType/oauth2';
import Aws from '../Authorization/AuthType/aws';
import MicrosoftNtlm from '../Authorization/AuthType/microsoft_ntlm';
import { RestContext } from '@pages/test-development/script/scripts/webservice/restapi/restapi';
import '@pages/test-development/script/scripts/webservice/restapi/css/common_Webservice.scss';
import { ASSERT_CONSTANT } from '../assert/jsx/constant';
import cx from 'classnames';
import { useHistory } from 'react-router';
import { getAuthTypeName, isEmptyObject, isEmptyValue } from '@src/util/common_utils';
import WebserviceWarningModal from '@pages/test-development/script/scripts/webservice-warning-modal';
import { Select } from 'fireflink-ui';
import JWTBearer from './AuthType/JwtAuth/JWTBearer';
import APIKey from './AuthType/APIKey';

const {
  MODULE,
  SCRIPT,
  ROOT,
  MODULE_PARENT_INHERIT_MSG,
  SCRIPT_PARENT_INHERIT_MSG,
  WEBSERVICE_PARENT_INHERIT_MSG,
  CLICK_HERE,
  TO_SEE,
  NO_AUTH_MSG,
  AUTH_TYPE_TITLE,
  AUTHORIZATION,
  FROM,
  STEP_GROUP_PARENT_INHERIT_MSG,
  STEPGROUP,
  TYPE_TEXT,
  NO_AUTH_TEXT,
  INHERIT_FROM_PARENT_TEXT,
  WEBSERVICE,
} = ASSERT_CONSTANT;
const InheritFromParent = (props) => {
  const { label, parentAuthData, type, moduleName, checkWebserviceData } = props || 'unknown';
  const history = useHistory();
  const parentId = parentAuthData?.id;
  let updatedPath = '';

  if (moduleName === 'Root') {
    updatedPath = `/testdevelopment/Script/Root Module_${parentId}/Settings`;
  } else if (type === WEBSERVICE && moduleName !== 'Root') {
    updatedPath = history.location.pathname.slice(0, history.location.pathname.indexOf('/webservices/')) + '/Settings';
  } else {
    updatedPath = history.location.pathname.slice(0, history.location.pathname.lastIndexOf('/'));
  }

  const path =
    type === WEBSERVICE && parentAuthData?.type === 'Script'
      ? `${updatedPath}/webservice-authorization?moduleId=${parentAuthData?.parentId}&scriptId=${parentId}`
      : `${updatedPath}/webservice-authorization?moduleId=${parentId}`;
  let inheritFromParent = '';
  if (label === MODULE) {
    inheritFromParent = MODULE_PARENT_INHERIT_MSG;
  } else if (label === SCRIPT) {
    inheritFromParent = SCRIPT_PARENT_INHERIT_MSG;
  } else {
    inheritFromParent = WEBSERVICE_PARENT_INHERIT_MSG;
  }

  const [showModal, setShowModal] = useState(false);
  const handleInheritFromParentAuth = () => {
    let isWebserviceData = null;
    if (checkWebserviceData) {
      isWebserviceData = Object.values(checkWebserviceData).includes(true);
    }
    if (type === 'Webservice' && isWebserviceData) {
      setShowModal(true);
    } else {
      history.push(path);
    }
  };
  const handleWebServiceModal = (action) => {
    setShowModal(false);
    if (action === 'exit') {
      history.push(path);
    }
  };

  return (
    <>
      {showModal && <WebserviceWarningModal handleWebserviceModal={handleWebServiceModal} />}
      <div className={cx('bg-white', { 'pt-20': [ROOT, MODULE, SCRIPT].includes(label) })}>
        <div
          className={`text-sm flex flex-col justify-center items-center fontPoppinsRegularLg bg-white ${
            [ROOT, MODULE, SCRIPT].includes(label) ? '' : 'size-of-no-auth-body'
          }`}
        >
          <div className="inherit-from-parent-border authorization-no-auth-body-width text-center">
            {type === STEPGROUP ? (
              <div className="inherit-from-parent-text">{STEP_GROUP_PARENT_INHERIT_MSG}</div>
            ) : (
              <>
                <div className="inherit-from-parent-text ">
                  <p className="cursor-pointer truncate" title={moduleName}>
                    {`${inheritFromParent} ${getAuthTypeName(parentAuthData)} ${FROM} ${moduleName}`}
                  </p>
                </div>
                <div className="inherit-from-parent-text">
                  <span
                    className="underline fontPoppinsMediumLg inherit-from-parent-link cursor-pointer"
                    onClick={handleInheritFromParentAuth}
                  >
                    {CLICK_HERE}
                  </span>{' '}
                  {TO_SEE}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const NoAuth = (label) => {
  return (
    <div className="bg-white">
      <div className="pt-3 pb-3 flex flex-wrap items-center">
        <span className="ml-5"></span>
      </div>

      <div
        className={`m-auto text-sm request-body-none-center fontPoppinsRegularLg bg-white ${
          [ROOT, MODULE, SCRIPT].includes(label) ? '' : 'size-of-no-auth-body'
        }`}
      >
        <div className="none-body-border authorization-no-auth-body-width fontPoppinsRegularLg">
          <div className="ml-2">{NO_AUTH_MSG}</div>
          <div>
            Please change the <span className="body-type-bold fontPoppinsSemiboldLg">{TYPE_TEXT}</span> to add the
            Authorization.
          </div>
        </div>
      </div>
    </div>
  );
};
const Authorization = (props) => {
  const formik = useFormik({
    initialValues: {
      type: '',
    },
  });
  const context = useContext(RestContext);
  const {
    selectedStepData,
    webserviceAuthData,
    label,
    handleDragStart,
    handleSaveAsSteps,
    setDisabled,
    MyAlert,
    filteredGlobalVariable,
    filteredLocalVariable,
    filteredProjectEnvironmentalVariable,
    filteredStepGroupVariable,
    getUserInputValue,
    onSetAllVariableName,
  } = props || 'unknown';

  const handleChange1 = ({ value }) => {
    if (value === 'Raw') {
      formik.setFieldValue('contentType', 'JSON');
    } else {
      formik.setFieldValue('contentType', '');
    }
    formik.setFieldValue('type', value);
    context.setFieldValue('authType', value);
  };
  const [authIconHover, setAuthIconHover] = useState(null);
  const [authOptions, setAuthOptions] = useState(AUTHORIZATION);

  const getStepsUpdate = () => {
    const { authType, basicAuth, basicAuthData, bearerToken } = context.values;
    if (
      (selectedStepData && !isEmptyObject(selectedStepData)) ||
      (webserviceAuthData && !isEmptyObject(webserviceAuthData))
    ) {
      const tempParmvalue = !isEmptyObject(selectedStepData) ? selectedStepData : webserviceAuthData;
      if (tempParmvalue.authType) {
        if (tempParmvalue?.inherited) {
          tempParmvalue.authType = INHERIT_FROM_PARENT_TEXT;
        }
        formik.setFieldValue('type', tempParmvalue.authType);
        context.values.authType = tempParmvalue.authType;
        if (tempParmvalue.authType === 'Basic') {
          context.values.basicAuthData = tempParmvalue?.auth;
        }
        if (tempParmvalue.authType === 'BearerToken') {
          context.values.bearerTokenData = tempParmvalue?.auth;
        }
        if (tempParmvalue.authType === 'Digest') {
          context.values.digestAuthData = tempParmvalue?.auth;
        }
        if (tempParmvalue.authType === 'Oauth2') {
          context.values.oauth2Data = tempParmvalue?.auth;
        }
        if (tempParmvalue.authType === 'Hawk') {
          context.values.hawkAuthData = tempParmvalue?.auth;
        }
        if (tempParmvalue.authType === 'Oauth1') {
          context.values.oauth1Data = tempParmvalue?.auth;
        }
        if (tempParmvalue.authType === 'NTLM') {
          context.values.microsoftNtlmAuthData = tempParmvalue?.auth;
        }
        if (tempParmvalue.authType === 'AWS') {
          context.values.awsAuthData = tempParmvalue?.auth;
        }
        if (tempParmvalue.authType === 'JWTBearer') {
          context.values.jwtAuth = tempParmvalue?.auth;
        }
        if (tempParmvalue.authType === 'APIKey') {
          context.values.apiKey = tempParmvalue?.auth;
        }
        context.setFieldValue('authType', tempParmvalue.authType);
      } else {
        formik.setFieldValue('type', INHERIT_FROM_PARENT_TEXT);
        context.values.authType = INHERIT_FROM_PARENT_TEXT;
      }
    } else if (
      isEmptyValue(authType) &&
      isEmptyValue(basicAuth) &&
      isEmptyValue(basicAuthData) &&
      isEmptyValue(bearerToken)
    ) {
      context.values.authType = '';
      context.values.basicAuth = '';
      context.values.basicAuthData = '';
      context.values.bearerToken = '';
    } else {
      formik.setFieldValue('type', authType);
    }
  };
  useEffect(() => {
    getStepsUpdate();
    const isRoot = [ROOT].includes(label);
    const filteredAuthOptions = isRoot
      ? AUTHORIZATION.filter((option) => option.label !== INHERIT_FROM_PARENT_TEXT)
      : AUTHORIZATION;
    setAuthOptions(filteredAuthOptions);
  }, [selectedStepData, webserviceAuthData, label]);
  const onAuthIconHover = (name, status) => {
    setAuthIconHover((prev) => ({ ...prev, [name]: status }));
  };

  const onSelectAuthType = (e) => {
    handleChange1(e);
    handleSaveAsSteps(false);
    if (setDisabled) {
      setDisabled(false);
    }
  };

  const renderAuthComponent = (type) => {
    switch (type) {
      case 'Basic':
        return <Basicauth {...props} />;
      case 'BearerToken':
        return <Bearertoken {...props} />;
      case 'Oauth1':
        return (
          <div className="authcontent mt-12">
            <Oauth1 authIconHover={authIconHover} onAuthIconHover={onAuthIconHover} {...props} />
          </div>
        );
      case 'Oauth2':
        return (
          <div className="authcontent">
            <Oauth2 authIconHover={authIconHover} onAuthIconHover={onAuthIconHover} {...props} />
          </div>
        );
      case 'Digest':
        return <Digestauth {...props} />;
      case 'AWS':
        return (
          <div className="authcontent">
            <Aws authIconHover={authIconHover} onAuthIconHover={onAuthIconHover} {...props} />
          </div>
        );
      case 'Hawk':
        return (
          <div className="authcontent">
            <Hawk authIconHover={authIconHover} onAuthIconHover={onAuthIconHover} {...props} />
          </div>
        );
      case 'NTLM':
        return <MicrosoftNtlm {...props} />;
      case NO_AUTH_TEXT:
        return <NoAuth label={label} />;
      case INHERIT_FROM_PARENT_TEXT:
      case '':
        return <InheritFromParent {...props} />;
      case 'JWTBearer':
        return <JWTBearer {...props} />;
      case 'APIKey':
        return (
          <APIKey
            MyAlert={MyAlert}
            filteredGlobalVariable={filteredGlobalVariable}
            filteredLocalVariable={filteredLocalVariable}
            filteredProjectEnvironmentalVariable={filteredProjectEnvironmentalVariable}
            filteredStepGroupVariable={filteredStepGroupVariable}
            getUserInputValue={getUserInputValue}
            handleSaveAsSteps={handleSaveAsSteps}
            onSetAllVariableName={onSetAllVariableName}
            type={props?.type}
            label={props?.label}
          />
        );
      default:
        return null;
    }
  };

  const filterSelectOption = () => {
    return AUTHORIZATION.find((option) => option.value === formik.values.type);
  };

  return (
    <div
      className={`mb-2 border-light-red-300 ${
        [ROOT, MODULE, SCRIPT].includes(label)
          ? 'border border-solid border-gray-300 h-96'
          : 'max-height-in-authorization'
      } bg-white`}
    >
      <div className={`${[ROOT, MODULE, SCRIPT].includes(label) ? 'ml-4' : 'mb-48 ml-4'} content-center`}>
        <div>
          <div
            className={`${
              [ROOT, MODULE, SCRIPT].includes(label) ? 'pt-2 pb-2' : ''
            } input-filed-label fontPoppinsRegularLg flex gap-2 justify-start items-center`}
          >
            <div className="mr-1">
              <span className="common-auth-star-symbol relative top-1 mr-1" onDragStart={handleDragStart}>
                *
              </span>
              <span className="text-xs fontPoppinsMediumSm auth-radio-button-control" onDragStart={handleDragStart}>
                {AUTH_TYPE_TITLE}
              </span>
            </div>
            <div className="text-left authorization-select">
              <Select options={authOptions} selectedOption={filterSelectOption()} onChange={onSelectAuthType} />
            </div>
          </div>
          {renderAuthComponent(formik.values.type)}
        </div>
      </div>
    </div>
  );
};

export default Authorization;

import React from 'react';
import { FiMaximize2 } from 'react-icons/fi';
import { useTable, usePagination, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy } from 'react-table';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { getAllSlackInstance } from '../../../../api/api_services';
import { useFormik } from 'formik';
import EmailDropdown from '@src/pages/defects/email-configuration-Dropdown';
import { scrollIntoView } from '@pages/test-development/script/modules/module/utils/common-functions';
import { useEffect, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { TEST_ENV_TABLE_WIDTH_SET } from '@src/common/ui-constants';
import { SLACK_CONSTANTS } from '@src/pages/configuration/plugin/plugin_constants';

let selectedArrayForNotification = [];
let selectedArrayForAttachments = [];
let selectedEmailGroupArray = [];
let EmailTableConcateArray = [];
const SlackPage = (props) => {
  let automationSuiteNotificationType = ['On Suite Execution Start', 'On Suite Execution Completion / Termination'];
  let manualSuiteNotificationType = ['Manually trigger email notification'];
  let notificationTypes = props?.suiteType
    ? props.suiteType === 'Manual'
      ? manualSuiteNotificationType
      : automationSuiteNotificationType
    : automationSuiteNotificationType;
  let attachmentTypes = ['PDF', 'HTML'];
  let slackData = ['Slack the report after Execution'];
  const [slackReportSelected, setSlackReportSelected] = useState(
    props.suiteObjMain &&
      (props?.suiteObjMain?.slackData?.recipientGroup?.length > 0 ||
        props?.suiteObjMain?.slackData?.selectedSlackInstances?.length > 0)
      ? 'true'
      : 'false'
  );

  const [emailObj, setEmailObj] = React.useState({
    slackManuallyTriggerNotification: props?.suiteObjMain?.slackData?.slackManuallyTriggerNotification,
    recipientGroup:
      props?.suiteObjMain?.slackData?.recipientGroup || props?.suiteObjMain?.slackData?.selectedSlackInstances
        ? props?.suiteObjMain?.slackData?.recipientGroup || props?.suiteObjMain?.slackData?.selectedSlackInstances
        : [],
    on_Suite_Execution_Starts: props?.suiteObjMain?.slackData?.on_Suite_Execution_Starts,
    on_Suite_Execution_Ends: props?.suiteObjMain?.slackData?.on_Suite_Execution_Ends,
    attachPdfReport: props?.suiteObjMain?.slackData?.pdfAttachment || props?.suiteObjMain?.slackData?.attachPdfReport,
    attachHtmlReport:
      props?.suiteObjMain?.slackData?.htmlAttachment || props?.suiteObjMain?.slackData?.attachHtmlReport,
    slackReportSelected: slackReportSelected,
  });

  useEffect(() => {
    setEmailObj((prev) => ({
      ...prev,
      slackReportSelected: slackReportSelected,
    }));
  }, [slackReportSelected]);

  const [userEmailData, setUserEmailData] = React.useState([]);
  const [selectedConfigEmailData, setSelectedConfigEmailData] = React.useState([]);
  const [emailReportCheckBox, setEmailReportCheckBox] = React.useState(
    props?.suiteObjMain?.slackData?.recipientGroup?.length > 0 ||
      props?.suiteObjMain?.slackData?.selectedSlackInstances?.length > 0
      ? true
      : false
  );
  const emailObjDependencies = [
    emailObj.on_Suite_Execution_Starts,
    emailObj.on_Suite_Execution_Ends,
    emailObj.attachHtmlReport,
    emailObj.attachPdfReport,
    emailObj.slackManuallyTriggerNotification,
  ];

  useEffect(() => {
    const isAllFalse = emailObjDependencies.every((condition) => !condition);
    if (isAllFalse) {
      setEmailReportCheckBox(false);
      setUserEmailData([]);
      setSelectedConfigEmailData([]);
      EmailTableConcateArray = [];
    }
  }, [...emailObjDependencies]);
  const [emailGroupBackUpArray, setEmailGroupBackUpArray] = React.useState([]);

  function getAllSlack() {
    getAllSlackInstance()
      .then((results) => {
        let response = results.data.responseObject ? results.data.responseObject : [];
        if (response) {
          const fetchLatestresponse = response.map((obj) => {
            return {
              ...obj,
              label: obj.instanceName,
              value: obj.instanceName,
              instanceName: obj.instanceName,
              channelName: obj.channelName,
              channelId: obj.channelId,
            };
          });

          setEmailGroupBackUpArray([...fetchLatestresponse]);

          let userSlackData = [];
          if (props.operation === 'update' || props?.suiteObjMain?.slackData) {
            if (props?.suiteObjMain?.slackData) {
              let data =
                props.suiteObjMain.slackData.recipientGroup || props.suiteObjMain.slackData.selectedSlackInstances;
              userSlackData = data.map((obj) => {
                return {
                  ...obj,
                  label: obj.instanceName,
                  value: obj.instanceName,
                  instanceName: obj.instanceName,
                  channelName: obj.channelName,
                  channelId: obj.channelId,
                };
              });
              setUserEmailData([...userSlackData]);
              props.createSuiteSlackFunction(emailObj);
            }
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
    return [];
  }
  useEffect(() => {
    if (props?.suiteObjMain?.slackData) {
      EmailTableConcateArray =
        props.suiteObjMain.slackData.recipientGroup || props.suiteObjMain.slackData.selectedSlackInstances;
      props.createSuiteSlackFunction(emailObj);
      let emailGroupValues = emailGroupBackUpArray;
      setEmailGroupBackUpArray([...emailGroupValues]);
    } else {
      selectedArrayForNotification = [];
      selectedArrayForAttachments = [];
      selectedEmailGroupArray = [];
      EmailTableConcateArray = [];
    }
    getAllSlack();
  }, []);

  const formik = useFormik({
    initialValues: {
      emailId: '',
      description: '',
      syncScriptOption: 'No Sync',
    },
  });

  function Table({ columns, data }) {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination
    );
    return (
      <>
        <table class="relative w-full " {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr class="mt-4" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    class={`sticky top-0 px-4 py-3 text-current table-header_font-style bg-gray-100 ${
                      TEST_ENV_TABLE_WIDTH_SET.includes(column.totalWidth) ? 'w-' + column.width + 'px' : ''
                    }`}
                    {...column.getHeaderProps()}
                  >
                    <span className="flex flex-row">
                      {column.render('Header')}
                      <div>
                        <span></span>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr className="project-row" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td class="px-4 py-3 text-center" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <br />
      </>
    );
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        columns: [],
      },

      {
        Header: 'INSTANCE NAME',
        accessor: 'instanceName',
        width: '400',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <Tooltip title={value}>
              <div className="table-non-link-color-common float-left font-style-common w-400px truncate">{value}</div>
            </Tooltip>
          );
        },
      },

      {
        Header: 'CHANNEL ID',
        accessor: 'channelId',
        width: '240',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <Tooltip title={value ? value : 'NA'}>
              <div className="table-non-link-color-common float-left font-style-common w-240px truncate">
                {value ? value : 'NA'}
              </div>
            </Tooltip>
          );
        },
      },
      {
        Header: 'CHANNEL NAME',
        accessor: 'channelName',
        width: '240',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <Tooltip title={value ? value : 'NA'}>
              <div className="table-non-link-color-common float-left font-style-common w-240px truncate">
                {value ? value : 'NA'}
              </div>
            </Tooltip>
          );
        },
      },
    ],
    []
  );

  const [operation, setOperation] = React.useState('Create');
  const [showModal, setShowModal] = useState(false);

  const handleChangeValues = (values) => {
    setUserEmailData([...values]);
    emailObj.recipientGroup = values;
    props.createSuiteSlackFunction(emailObj);
  };

  const handleEmailOptions = (value, type) => {
    if (type === 'add') {
      let options = [...emailGroupBackUpArray];
      options.push(value);
      setSelectedConfigEmailData((selectedConfigEmailData) => [...selectedConfigEmailData, value.label]);
      props.createSuiteSlackFunction(emailObj);
      setEmailGroupBackUpArray([...options]);
    } else if (type === 'remove') {
      if (value || value?.id) {
        let options = [...emailGroupBackUpArray];
        let index = options.findIndex((optionValue) => optionValue.label === value.label);
        options.splice(index, 1);
        let anotherEmailUser = [...userEmailData];
        let tempEmailIndex = anotherEmailUser.findIndex((emaillabel) => emaillabel.name === value.label);
        if (tempEmailIndex > -1) {
          anotherEmailUser.slice(tempEmailIndex, 1);
        }
        setUserEmailData(anotherEmailUser);
        props.createSuiteSlackFunction(emailObj);
      }
    }
  };

  useEffect(() => {
    selectEmailReport(props);
  }, [props.suiteType, emailReportCheckBox]);

  function selectEmailReport(props) {
    let exexutionStartStatus = props?.suiteObjMain?.slackData?.on_Suite_Execution_Starts;
    let executionEndStatus = props?.suiteObjMain?.slackData?.on_Suite_Execution_Ends;
    let pdfStatus = props?.suiteObjMain?.slackData?.pdfAttachment || props?.suiteObjMain?.slackData?.attachPdfReport;
    let htmlStatus = props?.suiteObjMain?.slackData?.htmlAttachment || props?.suiteObjMain?.slackData?.attachHtmlReport;
    if (emailReportCheckBox === true) {
      emailObj.attachPdfReport = true;
      emailObj.attachHtmlReport = true;
      setSlackReportSelected('true');
      emailObj.slackReportSelected = 'true';
      if (props.suiteType === 'Automation') {
        emailObj.on_Suite_Execution_Starts = props?.suiteObjMain?.slackData ? exexutionStartStatus : true;
        emailObj.on_Suite_Execution_Ends = props?.suiteObjMain?.slackData ? executionEndStatus : true;
        emailObj.attachPdfReport = props?.suiteObjMain?.slackData ? pdfStatus : true;
        emailObj.attachHtmlReport = props?.suiteObjMain?.slackData ? htmlStatus : true;
        emailObj.slackManuallyTriggerNotification = false;
      } else {
        emailObj.slackManuallyTriggerNotification = true;
        emailObj.attachPdfReport = props?.suiteObjMain?.slackData ? pdfStatus : true;
        emailObj.attachHtmlReport = props?.suiteObjMain?.slackData ? htmlStatus : true;
        emailObj.on_Suite_Execution_Starts = false;
        emailObj.on_Suite_Execution_Ends = false;
      }
    } else {
      setSlackReportSelected('false');
      emailObj.attachPdfReport = false;
      emailObj.attachHtmlReport = false;
      emailObj.slackReportSelected = 'false';
      emailObj.on_Suite_Execution_Starts = false;
      emailObj.on_Suite_Execution_Ends = false;
    }
    props.createSuiteSlackFunction(emailObj);
  }

  const slackcheckboxActionForTypes = (e) => {
    const { value, checked } = e.target;
    setEmailObj((prevEmailObj) => {
      let newEmailObj = { ...prevEmailObj };

      if (props.suiteType === 'Manual') {
        if (checked) {
          if (value === 'PDF') {
            newEmailObj.attachPdfReport = true;
          } else if (value === 'HTML') {
            newEmailObj.attachHtmlReport = true;
          }
        } else {
          if (value === 'PDF') {
            newEmailObj.attachPdfReport = false;
          } else if (value === 'HTML') {
            newEmailObj.attachHtmlReport = false;
          }
        }
      }

      if (value === SLACK_CONSTANTS.ON_SUITE_EXECUTION_COMPLETION_OR_TERMINATION) {
        // Handle the main completion checkbox
        if (checked) {
          // Enable PDF and HTML checkboxes
          document.querySelectorAll('#slackPdf, #slackHtml').forEach((checkbox) => {
            checkbox.disabled = false; // Enable checkboxes when completion is checked
          });
          newEmailObj.on_Suite_Execution_Ends = true;
        } else {
          // Disable and uncheck PDF and HTML checkboxes when the main completion is unchecked
          document.querySelectorAll('#slackPdf, #slackHtml').forEach((checkbox) => {
            checkbox.disabled = true;
            checkbox.checked = false;
          });
          newEmailObj.on_Suite_Execution_Ends = false;
          newEmailObj.attachPdfReport = false;
          newEmailObj.attachHtmlReport = false;
        }
      } else if (value === 'PDF' || value === 'HTML') {
        // Handle individual report type selections
        if (newEmailObj.on_Suite_Execution_Ends) {
          if (checked) {
            if (value === 'PDF') {
              newEmailObj.attachPdfReport = true;
            } else if (value === 'HTML') {
              newEmailObj.attachHtmlReport = true;
            }
          } else {
            if (value === 'PDF') {
              newEmailObj.attachPdfReport = false;
            } else if (value === 'HTML') {
              newEmailObj.attachHtmlReport = false;
            }
          }
        }
      } else if (value === SLACK_CONSTANTS.ON_SUITE_EXECUTION_START) {
        newEmailObj.on_Suite_Execution_Starts = checked;
      }
      return newEmailObj;
    });

    props.createSuiteSlackFunction({
      ...emailObj,
      attachPdfReport: document.querySelector('#slackPdf')?.checked || false,
      attachHtmlReport: document.querySelector('#slackHtml')?.checked || false,
      on_Suite_Execution_Starts: document.getElementById(SLACK_CONSTANTS.ON_SUITE_EXECUTION_START)?.checked || false,
    });
  };

  useEffect(() => {
    if (props.suiteType === 'Automation') {
      props.createSuiteSlackFunction(emailObj);
    }
  }, [props.suiteType, emailObj]);

  function maximizeIcon() {
    if (EmailTableConcateArray?.length || userEmailData?.length) {
      setShowModal(true);
    }
  }
  return (
    <>
      <Modal open={showModal} className="modal-dialog">
        {EmailTableConcateArray?.length || userEmailData?.length ? (
          <div className="modal-container max-popup-style">
            <div className="modal-header ">
              <label className="Popup-header-common Popup-header-layout-style-user">Slack Recipients</label>
              <div className="float-right flex flex-row">
                <CloseIcon
                  className="cursor-hand"
                  onClick={() => {
                    setShowModal(false);
                    setOperation();
                  }}
                />
              </div>
            </div>

            <div className="modal-body-1" id="journal-scroll">
              <div className="table_height overflow-auto" id="journal-scroll">
                <Table columns={columns} data={userEmailData?.length ? userEmailData : EmailTableConcateArray} />
              </div>
            </div>
          </div>
        ) : null}
      </Modal>

      <div className="email-style mt-5" style={{ height: '400px' }}>
        <div className="email-checkbox-value-style left-border-style mb-3">
          <label className={props?.rerunMode ? 'opacity-50' : ''}>
            <input
              type="checkbox"
              disabled={props?.rerunMode}
              checked={slackReportSelected === 'true'}
              onChange={(e) => setEmailReportCheckBox(e.target.checked)}
            />{' '}
            {slackData[0]}
          </label>
        </div>
        {slackReportSelected === 'true' ? (
          <div>
            <div className="emailGroup-class">
              <label className="pl-3"> Slack Reports</label>
              <div className="p-3">
                <div className="flex">
                  <div className="w-4/6">
                    <label className="email-notification-header pl-4">Slack Notifications</label>
                    <div className="email-checkbox-value-style">
                      {notificationTypes.map((checkedtxt, optindex) => {
                        const isNotificationChecked =
                          checkedtxt === SLACK_CONSTANTS.ON_SUITE_EXECUTION_START
                            ? emailObj?.on_Suite_Execution_Starts === true
                            : checkedtxt === SLACK_CONSTANTS.ON_SUITE_EXECUTION_COMPLETION_OR_TERMINATION
                            ? emailObj.on_Suite_Execution_Ends === true
                            : checkedtxt === SLACK_CONSTANTS.MANUALLY_TRIGGER_EMAIL_NOTIFICATION
                            ? true
                            : false;

                        return (
                          <label className="m-2" key={optindex}>
                            <input
                              type="checkbox"
                              name={checkedtxt}
                              checked={isNotificationChecked}
                              id={checkedtxt}
                              label={checkedtxt}
                              className="appearance-auto checked:bg-green-600 m-2 checked:border-transparent ..."
                              value={checkedtxt}
                              onChange={(e) => slackcheckboxActionForTypes(e)}
                            ></input>
                            {checkedtxt}
                          </label>
                        );
                      })}
                    </div>
                  </div>
                  <div className="w-2/6">
                    <label className="email-notification-header pl-5">Attach Execution Report</label>
                    <div className="email-checkbox-value-style">
                      {attachmentTypes.map((checkedtxt, optindex) => {
                        // Note: The use of "==" here is intentional due to specific requirements.
                        // Do not change "==" to "===" as it may affect the logic.
                        const pdfReport = emailObj.attachPdfReport == true;
                        const htmlReport = emailObj.attachHtmlReport == true;
                        const isChecked = checkedtxt === 'PDF' ? pdfReport : checkedtxt === 'HTML' ? htmlReport : false;

                        return (
                          <label className="m-2" key={optindex}>
                            <input
                              type="checkbox"
                              name={checkedtxt}
                              checked={isChecked}
                              id={checkedtxt === 'PDF' ? 'slackPdf' : 'slackHtml'}
                              className="appearance-auto checked:bg-green-600 m-2 checked:border-transparent ..."
                              value={checkedtxt}
                              onChange={(e) => slackcheckboxActionForTypes(e)}
                            />
                            {checkedtxt}
                          </label>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="pl-3">
                <label>Slack Recipients </label>
                <form onSubmit={formik.handleSubmit} className="space-y-8 ">
                  <div class="grid grid-cols-2  mt-2 pb-3 ml-2 ">
                    <div>
                      <div>
                        <label className="input-filed-label-style-common">
                          <span class="text-red-400">*</span> Add Slack Instances{' '}
                        </label>
                        <br></br>
                        <div>
                          <div>
                            <div className="">
                              <EmailDropdown
                                setIsSlackDropdownOpen={props.setIsSlackDropdownOpen}
                                data={emailGroupBackUpArray}
                                disabled={props?.rerunMode || false}
                                placeholder={'Select Slack instances'}
                                handleSelectChange={handleChangeValues}
                                valueNumbers={3}
                                selectedData={userEmailData}
                                onDropdownOpen={scrollIntoView}
                                handelOptions={handleEmailOptions}
                                execution={true}
                                targetData={selectedConfigEmailData}
                                createAlertFun={props.createAlertFun}
                                name={'slack integration'}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {userEmailData.length ? (
                    <div className="module-area-style">
                      <div className="content_area_header_style">
                        <div>
                          <label className="main-header-label float-left ">Slack Recipients</label>
                        </div>
                        <div className="flex flex-row float-right">
                          <FiMaximize2
                            title="Maximize"
                            onClick={maximizeIcon}
                            className={`${
                              userEmailData?.length ? ' maximize-btn-style cursor-hand' : 'disabled-action '
                            } mt-1 mr-2 `}
                          />
                        </div>
                      </div>
                      <div className="table_height overflow-auto" id="journal-scroll">
                        <Table columns={columns} data={userEmailData} />
                      </div>
                    </div>
                  ) : null}
                </form>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default SlackPage;

import React from 'react';
import PageHeader from '../shared/PageHeader';
import '../licence_management.css';
import TextField from '@material-ui/core/TextField';
import MySelect from '../../common/Inputs/MySelect';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    border: 'none',
  },
  head: {
    border: 'none',
  },
});

function createRow(sl, specification, cost, quantity, total) {
  return { sl, specification, cost, quantity, total };
}

const rows = [createRow(1, 'Users', 350.0, 2, 700.0)];

function AddLicenceManagement() {
  const addButton = (
    <>
      <button className="primary-btn ml-3 p-1">Cancel</button>
      <button className="primary-btn ml-3 p-1">next</button>
    </>
  );
  const classes = useStyles();
  return (
    <>
      <PageHeader title=" Add Licence" />
      <Divider />
      <div id="journal-scroll" style={{ maxHeight: '450px', overflowY: 'auto' }}>
        <div className="grid grid-cols-3 pb-4 ml-24 content-center">
          <div className="mt-6  ">
            <MySelect
              type="text"
              placeholder="Enter License Type"
              className="selectButton"
              name=" licenseType"
              label={
                <>
                  {' '}
                  <span class="text-red-400">*</span>License Type
                </>
              }
            >
              <option value="">Select license type</option>
              <option>C-Basic</option>
              <option>C-Professional</option>
              <option>On Premises - Enterprise</option>
            </MySelect>
          </div>
          <div className="mt-6 ml-8">
            <MySelect
              type="text"
              placeholder="Enter Plan"
              className="selectButton"
              name="plan"
              label={
                <>
                  {' '}
                  <span class="text-red-400">*</span>Selected Plan
                </>
              }
            >
              <option value="">Select Plan</option>
              <option>All</option>
              <option>1</option>
              <option>2</option>
            </MySelect>
          </div>
          <div className="mt-6 ml-24">
            <MySelect
              type="text"
              placeholder="Enter Job Title"
              className="selectButton"
              name="billingCycle"
              label={
                <>
                  {' '}
                  <span class="text-red-400">*</span>Billing Cycle
                </>
              }
            >
              <option value="">Select Belling Cycle</option>
              <option>Monthly</option>
              <option>Quarterly</option>
              <option>Half Yearly</option>
              <option>Yearly</option>
            </MySelect>
          </div>
        </div>
        <div className="grid grid-cols-3 pb-4 ml-24 content-center">
          <div className="mt-6 ">
            <TextField
              id="standard-number"
              label={
                <>
                  {' '}
                  <span class="text-red-400">*</span>Users
                </>
              }
              className="selectButton"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="mt-6 ml-8">
            <TextField
              id="standard-number"
              className="selectButton"
              type="number"
              label={
                <>
                  {' '}
                  <span class="text-red-400">*</span>Storage
                  <span className="text-xs pl-2 pb-7">GB</span>
                </>
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
        <div className="mt-6  h-36 borderStyle">
          <div className="text-xs font-light text-gray-600 mt-6 ml-24">Machine Specification</div>
          <div className="ml-24 grid  grid-rows-2 mt-2">
            <input className="" type="radio" value="Male" name="gender" />
            <h6 className="-mt-5 ml-6 text-sm font-light text-gray-600">Use local machine for script execution </h6>
            <input className="" type="radio" value="Male" name="gender" />
            <h6 className="-mt-5 ml-6 text-sm font-light text-gray-600">Use local machine for script execution </h6>
            {/* <RadioGroup  aria-label="quiz" name="quiz" >
          <FormControlLabel className="text-sm" control={<Radio />} label="Use local machine for script execution" />
          <FormControlLabel className="text-sm" control={<Radio />} label="Use cloud machine for script execution" />
        </RadioGroup> */}
          </div>
        </div>
        <div className="ml-24 tableStyle">
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="spanning table">
              <TableHead>
                <TableRow className={classes.head}>
                  <TableCell>Sl.No</TableCell>
                  <TableCell align="right">Specification</TableCell>
                  <TableCell align="right">Cost($)</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">Total Cost($)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.slno}>
                    <TableCell>{row.slno}</TableCell>
                    <TableCell align="right">{row.specification}</TableCell>
                    <TableCell align="right">{row.cost}</TableCell>
                    <TableCell align="right">{row.quantity}</TableCell>
                    <TableCell align="right">{row.total}</TableCell>
                  </TableRow>
                ))}

                <TableRow>
                  <TableCell rowSpan={4} />
                  <TableCell colSpan={2}>Net Total ($):</TableCell>
                  <TableCell align="right">700.00 $</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tax ($):</TableCell>
                  <TableCell align="right">0.00 $</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>Grand Total($):</TableCell>
                  <TableCell align="right">700.00 $</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="ml-24 tableStyle">
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="spanning table">
              <TableHead>
                <TableRow className={classes.head}>
                  <TableCell>Sl.No</TableCell>
                  <TableCell style={{ marginLeft: '20px' }} align="right">
                    Machine Size
                  </TableCell>
                  <TableCell align="right">Machine Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>1</TableCell>
                  <TableCell>
                    <div className="mt-6">
                      <MySelect type="text" placeholder="Enter Job Title" className="selectButton" name="billingCycle">
                        <option value="">Medium</option>
                        <option>Small</option>
                      </MySelect>
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    <div className="mt-6 ml-8">
                      <TextField
                        id="standard-number"
                        className="selectButton"
                        type="number"
                        label={
                          <>
                            {' '}
                            <span class="text-red-400">*</span>Storage
                            <span className="text-xs pl-2 pb-7">GB</span>
                          </>
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <PageHeader button={addButton} />
    </>
  );
}

export default AddLicenceManagement;

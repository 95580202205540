import React from 'react';
import MyTable from '../../licence_management/shared/Table';

const UserDetailsPage = (props) => {
  const result = props.selectedUser?.user;
  const projectResult = props.selectedUser?.projects;
  const data = React.useMemo(
    () =>
      Object?.values(projectResult).map((proVal) => ({
        projectName: (
          <span
            className="text-gray-700 cursor-pointer"
            style={{
              color: '#525252',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              maxWidth: '150px',
              float: 'left',
            }}
            title={proVal?.projectName}
          >
            {' '}
            {proVal.projectName}
          </span>
        ),
        roleName: (
          <span
            className="text-gray-700 cursor-pointer"
            style={{
              color: '#525252',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              maxWidth: '150px',
              float: 'left',
              cursor: 'pointer',
            }}
            title={proVal?.roleName}
          >
            {' '}
            {proVal.roleName}
          </span>
        ),
      })),
    []
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Project',
        accessor: 'projectName',
        width: '30%',
      },
      {
        Header: 'Role',
        accessor: 'roleName',
        width: '20%',
      },
    ],
    []
  );
  let projectLicenseId = JSON.parse(localStorage.getItem('test-optimize-user')).licenseId;
  const [userLicenseData] = result?.licenses?.filter((value) => value?.id === projectLicenseId);

  return (
    <>
      {result && (
        <div className="m-0" style={{ marginLeft: '18px' }}>
          <div
            id="journal-scroll"
            style={{
              maxHeight: '406px',
              width: '935px',
              overflowY: 'auto',
              background: '#FBFCFD;',
            }}
          >
            <div className="pb-4">
              <div className="grid grid-cols-3  mt-2 pb-3 ">
                <div>
                  <label
                    htmlFor="userName"
                    className="block sm:mt-px sm:pt-2 details-key-style-common"
                    style={{ fontSize: '12px' }}
                  >
                    Name
                  </label>
                  <label
                    className="details-data-style-common"
                    title={result?.name}
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      maxWidth: '150px',
                      float: 'left',
                    }}
                  >
                    {result.name}
                  </label>
                </div>
                <div
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '200px',
                  }}
                >
                  <label htmlFor="emailId" className="block sm:mt-px sm:pt-2 details-key-style-common">
                    Email
                  </label>
                  <label className="details-data-style-common" title={result.emailId}>
                    {result.emailId}
                  </label>
                </div>
                <div>
                  <label htmlFor="privilege" className="block sm:mt-px sm:pt-2 details-key-style-common">
                    Privilege
                  </label>
                  <label className="details-data-style-common">{userLicenseData?.privilege || '--'}</label>
                </div>
              </div>
              <div className="grid grid-cols-3  mt-2 pb-3 ">
                {' '}
                <div
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '200px',
                  }}
                >
                  <label htmlFor="phoneNumber" className="block sm:mt-px sm:pt-2 details-key-style-common">
                    Phone Number
                  </label>
                  <label className="details-data-style-common" title={result.phoneNumbers?.primaryPhone}>
                    {result.phoneNumbers?.primaryPhone ? result.phoneNumbers?.primaryPhone : <span>--</span>}
                  </label>
                  {result.phoneNumbers?.primaryPhone ? <span className="primaryNo">P</span> : ''}

                  <br />
                  <label className="details-data-style-common">
                    {result.phoneNumbers?.secondaryPhone ? result.phoneNumbers?.secondaryPhone : <span>--</span>}
                  </label>
                  {result.phoneNumbers?.secondaryPhone ? <span className="primaryNo">S</span> : ''}
                </div>
                <div>
                  <label htmlFor="activationStatus" className="block sm:mt-px sm:pt-2 details-key-style-common">
                    Status
                  </label>
                  <label className="details-data-style-common">
                    {userLicenseData?.licenseStatus === 'ACTIVE' || userLicenseData?.licenseStatus === 'Active' ? (
                      <label className="label-success fontPoppinsRegularMd">{'Active'}</label>
                    ) : userLicenseData?.licenseStatus === 'PENDING' ? (
                      <label className="label-error  fontPoppinsRegularMd">{'Activation Pending'}</label>
                    ) : userLicenseData?.licenseStatus === 'DISABLED' ? (
                      <label className="text-gray-500 fontPoppinsRegularMd">{'Disabled'}</label>
                    ) : (
                      '--'
                    )}
                  </label>
                </div>
                <div>
                  <label htmlFor="createdByUname" className="block sm:mt-px sm:pt-2 details-key-style-common">
                    Created By
                  </label>
                  <label
                    className="details-data-style-common"
                    title={result.createdByUname}
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      maxWidth: '150px',
                      float: 'left',
                    }}
                  >
                    {result.createdByUname}
                  </label>
                </div>
              </div>
              <div className="grid grid-cols-3  mt-2 pb-3 ">
                <div>
                  <label htmlFor="createdOn" className="block sm:mt-px sm:pt-2 details-key-style-common">
                    Created On
                  </label>
                  <label className="details-data-style-common">{result.createdOn}</label>
                </div>
                <div>
                  <label htmlFor="modifiedByUname" className="block sm:mt-px sm:pt-2 details-key-style-common">
                    Modified By
                  </label>
                  <label
                    className="details-data-style-common"
                    title={result?.modifiedByUname}
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      maxWidth: '150px',
                      float: 'left',
                    }}
                  >
                    {result.modifiedByUname ? result.modifiedByUname : <span>--</span>}
                  </label>
                </div>
                <div>
                  <label htmlFor="modifiedOn" className="block sm:mt-px sm:pt-2 details-key-style-common">
                    Modified On
                  </label>
                  <label className="details-data-style-common">{result.modifiedOn}</label>
                </div>
              </div>
            </div>

            <div className="mt-4 role_permission_style2">
              <label className="mt-4 ml-4 Popup-header-common">Project Role Mapping</label>
              <div className=" m-2 userRoleMapping ">
                <MyTable data={data} columns={columns} tableHeightClass={'modal-table-height'} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserDetailsPage;

import React from 'react';
import { FilterGroup } from './filter-group';
import { FilterGroupDefects } from './filter-group-defects';
import { FilterTimePeriod } from './filter-time-period';
import './filters.css';

export const FilterSection = (props) => {
  const { defectSection = false } = props;

  return (
    <>
      <div className={props.data.length > 0 ? 'filter-section' : 'filters_heading'}>
        {/* Date range filter todo */}
        <FilterTimePeriod handleTimePeriod={props.handleTimePeriod} />
        {defectSection
          ? props.data?.map((filterGroup) => (
              <FilterGroupDefects
                key={filterGroup.id}
                {...filterGroup}
                handleFilterValues={props.handleFilterValues}
                handleAll={props.handleAll}
                checkedAssignToIds={props.checkedAssignToIds}
                checkedCreatedByIds={props.checkedCreatedByIds}
                checkedLabelsIds={props.checkedLabelsIds}
              />
            ))
          : props.data?.map((filterGroup) => (
              <FilterGroup
                key={filterGroup.id}
                {...filterGroup}
                handleFilterValues={props.handleFilterValues}
                checkedExecutionIds={props.checkedExecutionIds}
                checkedLabelsIds={props.checkedLabelsIds}
                handleAll={props.handleAll}
                suiteArray={props.suiteArray}
              />
            ))}
      </div>
    </>
  );
};

import React, { useState, useEffect, useCallback, memo, Fragment } from 'react';
import { ReactComponent as AllProjectsIcon } from '@assets/cases_black_24dp.svg';
import { ReactComponent as SearchProjectsIcon } from '@assets/svg-imports/search_project.svg';
import { Tooltip } from '@mui/material';
import OutsideAlerter from '@pages/common/OutsideAlerter';
import { useHistory, useLocation } from 'react-router';
import { getProjectListDropdownReq } from '@api/api_services';
import styles from '@pages/common/Layout/styles/search_projects.style.module.scss';
import { AllProjectsComponent } from './AllProjectsComponent';
import cx from 'classnames';
import { LAYOUT_CONSTANTS } from '../constants/LayoutConstants';
import getProjectTypeIcons from '../IconsComponents/SearchProjectIconsRenderer';

const SearchProjectsComponent = (props) => {
  const { isDrawerOpen } = props;
  let allProjects = [{ id: '', name: 'All Projects' }];
  const location = useLocation();
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState({
    id: '',
    name: 'All Projects',
  });
  const [licenseLevelTimezone, setLicenseLevelTimezone] = useState({});
  const history = useHistory();
  const [showDropDown, setShowDropdown] = useState(false);
  const [projectFilterArray, setProjectFilterArray] = useState([]);

  const getProjectsList = () => {
    getProjectListDropdownReq()
      .then((results) => {
        if (results.data.responseCode === '401' || results.data.message === 'Token is invalid') {
          history.push('/login');
        } else {
          setProjects(results.data.responseObject);
          setProjectFilterArray(results.data.responseObject);
          const timezone = results?.data?.responseMap?.licenseTimeZone;
          setLicenseLevelTimezone(timezone);
          localStorage.setItem('license-level-timezone', timezone);
        }
      })
      .catch((error) => {
        console.error('Error while fetching All Projects Dropdown data', error);
      });
  };

  useEffect(() => {
    const storedTimezone = localStorage.getItem('license-level-timezone');
    if (storedTimezone) {
      setLicenseLevelTimezone(storedTimezone);
    }
  }, []);

  useEffect(() => {
    if (showDropDown) {
      getProjectsList();
    }
  }, [showDropDown]);

  const initSelectedProject = () => {
    const project = localStorage.getItem('selected-project');
    if (!project) {
      localStorage.setItem('selected-project', JSON.stringify(allProjects[0]));
    } else {
      setSelectedProject(JSON.parse(project));
    }
  };

  useEffect(() => {
    const locationStateSelectedProject = location?.state?.selectedProject;
    if (locationStateSelectedProject) {
      localStorage.setItem('selected-project', JSON.stringify(locationStateSelectedProject));
      setSelectedProject(locationStateSelectedProject);
    }
    if (location?.pathname?.includes('project-config')) {
      getProjectsList();
      initSelectedProject();
    } else if (localStorage.getItem('selectProject') !== '') {
      initSelectedProject();
    }
  }, []);

  useEffect(() => {
    getProjectsList();
    const interval = setInterval(() => {
      initSelectedProject();
    }, 100);
    return () => clearInterval(interval);
  }, []);

  const setProjectName = (project) => {
    localStorage.setItem('selected-project', JSON.stringify(project));
    localStorage.setItem('license-level-timezone', licenseLevelTimezone);
    localStorage.setItem('reset-search-testdev', true);
    setShowDropdown(false);
    project.id ? sessionStorage.setItem('projectId', project.id) : history.push('/projectmenu/project');
  };

  function filterProjectNames(e) {
    let searchValue = e.target.value;
    if (searchValue) {
      let tempSimilarArray = [];
      projects.map((proVal) => {
        let name = proVal.name;
        let similarExist = name.match(new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'i'));
        if (similarExist) {
          tempSimilarArray.push(proVal);
          setProjectFilterArray(tempSimilarArray);
        } else {
          setProjectFilterArray(tempSimilarArray);
        }
      });
    } else {
      setProjectFilterArray(projects);
    }
  }

  const handleAllProjectsClick = useCallback(() => {
    setShowDropdown((preValue) => !preValue);
  }, []);

  return (
    <>
      <OutsideAlerter
        function={() => {
          setShowDropdown(false);
        }}
      >
        <Fragment>
          <AllProjectsComponent
            menuLabel={selectedProject.name ? selectedProject.name : 'All Projects'}
            isDrawerOpen={isDrawerOpen}
            isAllProjectClick={showDropDown}
            handleAllProjectsClick={handleAllProjectsClick}
          />
          {showDropDown ? (
            <div
              className={isDrawerOpen ? styles['search-all-project-box'] : styles['search-all-project-box-collapsed']}
            >
              {allProjects.map((project, index) => (
                <>
                  {projectFilterArray.length >= 0 && (
                    <div key={project?.name}>
                      <input
                        className={styles['search-box-only']}
                        id="searchInputField"
                        defaultValue={project?.name === 'All Projects' ? '' : project?.name}
                        onChange={(e) => filterProjectNames(e)}
                        autoComplete="off"
                        placeholder="Search Project"
                        autoFocus={false}
                      />
                      <SearchProjectsIcon className={styles['search-project-icon']} />
                    </div>
                  )}
                  <div
                    key={index}
                    className={styles['rsk-project-row-hover']}
                    id="hoverImg"
                    onClick={() => setProjectName(project)}
                  >
                    {projectFilterArray.length >= 0 ? (
                      <>
                        <AllProjectsIcon className={styles['search-all-project-icon']} />
                        <span className={styles['search-project-project-name']}>{project?.name}</span>
                      </>
                    ) : (
                      <></>
                    )}
                    <label className="project-name-label-style cursor"></label>
                  </div>
                </>
              ))}
              <div className={cx(styles['search-projects-scroll'], 'individual-proj-style ')}>
                {projectFilterArray.map((project, index) => (
                  <div
                    key={index}
                    className={cx(styles['rsk-project-row-hover'], styles['rsk-text-overflow'])}
                    onClick={() => setProjectName(project)}
                  >
                    {getProjectTypeIcons(project?.type)}

                    <Tooltip title={project?.name} placement="right">
                      <span className={styles['search-project-tooltip']}>{project?.name} </span>
                    </Tooltip>
                    <label className={styles['rsk-project-name-label-style']}></label>
                  </div>
                ))}

                {!projectFilterArray.length ? (
                  <div className={cx(styles['rsk-project-name-label-style'], styles['search-project-no-project'])}>
                    {LAYOUT_CONSTANTS.NO_PROJECTS}
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            <></>
          )}
        </Fragment>
      </OutsideAlerter>
    </>
  );
};

export default memo(SearchProjectsComponent);

/* eslint-disable */
import React, { Fragment, useEffect, useState, useContext } from 'react';
import ProjectCreatePage from './create_component_session/project_create_page';
import { useTable, useFilters, useGlobalFilter, useSortBy } from 'react-table';

import { useHistory } from 'react-router-dom';

import Modal from 'react-modal';
import ContextMenu from '../common/context_menu';
import { Dialog, Transition } from '@headlessui/react';
import ProjectDetailsPage from './create_component_session/project_details_page';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import UserDetailsPage from './create_component_session/user_details_page';
import {
  getSingleProjectRequest,
  getProjectListByUserIdReq,
  deleteProjectRequest,
  updateProjectRequest,
  getSingleUserRequest,
} from '@api/api_services';
import { ColumnFilter } from '../common/table_tree/react_table/ColumnFilter';
import { DateColumnFilter } from '../common/table_tree/react_table/DateColumnFilter';
import {
  textFilterFn,
  dateBetweenFilterFn,
  multipleRowFilterFn,
  fuzzyTextFilterFn,
} from '../common/table_tree/react_table/CommonFunctions';
import { CommonLoader } from '@src/pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import SearchBar from '@src/pages/analytics/common/search-bar';
import { HighlightText, isEmptyValue, isValueIncludes } from '@util/common_utils';
import NoResultsFound from '@src/pages/common/Table/NoResultFound';

export default function ProjectPage({ setconfig, MyAlert }) {
  const customStylesProject = {
    content: {
      position: 'absolute',
      top: '8%',
      left: '35%',
      right: '20%',
      width: '640px',
      overflow: 'hidden',
      bottom: '25%',
      maxHeight: 'fit-content',
    },
  };
  const customStylesDetailsProject = {
    content: {
      position: 'absolute',
      top: '0%',
      left: '27%',
      right: '10%',
      overflow: 'hidden',
      width: '960px',
      maxHeight: '1080px',
      background: '#FBFCFD',
    },
  };
  const customStylesUserDetailsProject = {
    content: {
      position: 'absolute',
      top: '1%',
      left: '25%',
      right: '35%',
      width: '960px',
      overflow: 'hidden',
      maxHeight: '1080px',
    },
  };

  const [showModal, setShowModal] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [projects, setProjects] = React.useState([]);
  const [seletedProject, setSelectedProject] = React.useState({});
  const [projectCount, setprojectCount] = React.useState('');
  const history = useHistory();
  const { resetProgress, startOrResumeProgress, stopProgress, setProgress } = useContext(ProgressContainerContext);
  let [userSearched, setuserSearched] = useState('');
  let [isHighlight, setIsHighlight] = useState(false);
  let [openSearch, setOpenSearch] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');
  const [data, setData] = useState([]);

  const gotoHistroy = (id) => {
    history.push(`/projectmenu/project-config?id=${id}`);
  };

  const redirectTo = (projectId, projName) => {
    setShowModal(false);
    MyAlert.success(`${projName} Project created successfully`);
    gotoHistroy(projectId);
    sessionStorage.setItem('projectId', projectId);
    localStorage.setItem('selected-project', JSON.stringify(results.data.responseObject));
    history.push('/testdevelopment');
  };

  function moveToArchive(res) {
    const data = {
      name: res.name,
      desc: res.desc,
      type: res.type,
      status: res.status === 'Archive' ? 'Closed' : 'Archive',
      appType: res.appType,
      platform: res.platform,
    };

    const basudata = new FormData();
    basudata.append('data', JSON.stringify(data));
    updateProjectRequest(basudata, res.id).then((results) => {
      if (results.data.responseObject) {
        MyAlert.success(
          `${res.name} project has been ${res.status === 'Archive' ? 'UnArchived ' : 'Archived '} successfully `
        );
        getAllProjects();
      } else {
        MyAlert.danger(results.data.message);
      }
    });
  }
  function getAllProjects() {
    const user = localStorage.getItem('test-optimize-user');
    const userSession = user ? JSON.parse(user) : {};
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    getProjectListByUserIdReq(userSession.id)
      .then((results) => {
        if (results.status === 200) {
          resetProgress();
        }
        const response = results.data.responseObject;
        setprojectCount(response.length);
        if (response) {
          setTimeout(() => {
            setIsLoaded(true);
            let projArray = [];
            response.map((res, i) => {
              const Actions = [
                <div className="-ml-6 flex items-center" style={{ border: '4px solid transparent' }}>
                  {userSession.privilege !== 'User' && (
                    <>
                      <span
                        data-title={res.project.status === 'Archive' ? 'Edit' : 'Edit'}
                        className=" py-0 px-1  rounded-l float-left text-center"
                        style={{ marginLeft: '3px' }}
                      >
                        <button
                          id="del"
                          style={{ marginRight: '3px' }}
                          key={`button-${i}`}
                          disabled={res.project.status === 'Archive'}
                          className={'float-left project-action state-button py-0 px-1 rounded-l cursor-pointer'}
                          onClick={() => {
                            setShowModal(true);
                            setSelectedProject(res.project);
                            setpopupHeaderAndBtn('Edit Project', 'Update');
                            setdetailsHeader(res.project.name);
                          }}
                        >
                          <img src="/assets/images/edit_blue.svg" alt="" height="20px" width="20px" />
                        </button>
                      </span>
                      <span
                        id="del"
                        data-title={res.project.status === 'Open' ? 'Open project cannot be deleted' : 'Delete'}
                        style={{ marginLeft: '3px' }}
                        className=" py-0 px-1  rounded-l float-left"
                      >
                        <button
                          disabled={res.project.status === 'Open'}
                          style={{ marginRight: '3px' }}
                          className={`${
                            res.project.status === 'Open'
                              ? 'project-action opacity-50 disabled-delete-style'
                              : 'state-button project-action delete-style cursor-pointer '
                          }`}
                          onClick={() => {
                            setOpen(true);
                            setSelectedProject(res.project);
                            setpopupHeaderAndBtn('Delete');
                          }}
                        >
                          <img src="/assets/images/delete_blue.svg" alt="" height="20px" width="20px" />
                        </button>
                      </span>
                    </>
                  )}
                  <div
                    className={`project-action cursor-pointer ${userSession.privilege === 'User' ? 'ml-10' : ''}`}
                    key={`project-action-${i}`}
                  >
                    <ContextMenu
                      contextMenuData={
                        res.project.status === 'Open' || userSession.privilege === 'User'
                          ? contextMenuData
                          : res.project.status === 'Archive'
                            ? [
                                ...contextMenuData,
                                {
                                  active: true,
                                  option: 'Add to UnArchive',
                                },
                              ]
                            : [
                                ...contextMenuData,
                                {
                                  active: true,
                                  option: 'Add to Archive',
                                },
                              ]
                      }
                      func={(e) => {
                        contextMenuFunction(e, res.project);
                      }}
                      onMouseOutz
                    />
                  </div>
                </div>,
              ];
              projArray.push({ ...res.project, Actions: Actions });
            });
            setProjects(projArray);
          }, 100);
        }
      })
      .catch((error) => {
        resetProgress();
        setIsLoaded(true);
        setError(error);
      });
    return [];
  }

  const [userObj, setUserObj] = React.useState();
  let call = true;
  function createObjUser(selectedUser) {
    getSingleUserRequest(selectedUser.modifiedBy).then((results) => {
      const user = results.data.responseObject;
      const projects = results.data.responseMap;
      setUserObj({ user, projects });
      setShowModal(true);
      setpopupHeaderAndBtn('User Details');
      call = true;
    });
  }

  function contextMenuFunction(event, resObj) {
    if (event.target.textContent === 'Details') {
      viewProjects(resObj);
      setdetailsHeader(resObj.name);
    } else if (event.target.textContent === 'Add to UnArchive') {
      moveToArchive(resObj);
    } else if (event.target.textContent === 'Add to Archive') {
      moveToArchive(resObj);
    }
  }

  React.useEffect(() => {
    getAllProjects();
  }, []);

  function closePopup() {
    setSelectedProject({});
    setShowModal(false);
    getAllProjects();
  }

  function closePopupOnCancel() {
    setShowModal(false);
  }

  function Table({ columns, data }) {
    const filterTypes = React.useMemo(
      () => ({
        fuzzyText: fuzzyTextFilterFn,
        text: textFilterFn,
        multiple: multipleRowFilterFn,
        dateBetween: dateBetweenFilterFn,
      }),
      []
    );

    const defaultColumn = React.useMemo(
      () => ({
        Filter: ColumnFilter,
      }),
      []
    );

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
      {
        columns,
        data,
        defaultColumn,
        filterTypes,
      },
      useFilters,
      useGlobalFilter,
      useSortBy
    );

    return (
      <>
        <div className={data.length ? 'primary-table-height' : ''}>
          <table
            className="relative w-full "
            {...getTableProps()}
            onBlur={(e) => {
              setCreateUpdateCalled(true);
            }}
          >
            <thead className="table_header_color z-10 sticky">
              {headerGroups.map((headerGroup, i) => (
                <tr
                  key={`project-list-tr-${i}`}
                  {...headerGroup.getHeaderGroupProps()}
                  className="table_header_color h-12 sticky top-0 zIndex-1"
                >
                  {headerGroup.headers.map((column, colIndex) => (
                    <th
                      key={`project-list-head-${colIndex}`}
                      className="sticky top-0 mb-4 px-6 py-4  ml-4 z-10"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <span
                        className="flex flex-row uppercase mr-12 border-none text-gray-700 fontPoppinsSemiboldMd"
                        style={{
                          color: '#3C3838',
                        }}
                      >
                        {column.render('Header')}
                        <div className="flex">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <AiOutlineArrowDown className="mt-1 ml-1" />
                            ) : (
                              <AiOutlineArrowUp className="mt-1 ml-1" />
                            )
                          ) : (
                            ''
                          )}
                        </div>
                      </span>
                      <div>{column.canFilter ? column.render('Filter') : null}</div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="divide-y z-10" {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr key={`project-list-row-${i}`} className="project-row border-none h-10" {...row.getRowProps()}>
                    {row.cells.map((cell, cellIndex) => {
                      return (
                        <td
                          key={`project-list-td-${cellIndex}`}
                          className="px-4 py-0 mr-2 fontPoppinsRegularMd"
                          style={{
                            paddingLeft: '17px',
                          }}
                          {...cell.getCellProps({})}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
  const viewProjects = (projects) => {
    getSingleProjectRequest(projects.id).then((results) => {
      const projects = results.data.responseObject;
      const user = results.data.responseMap;
      setSelectedProject({ projects, user });
      setShowModal(true);
      setpopupHeaderAndBtn('Project Details');
    });
  };

  // TODO :: handle Search filter :: START

  React.useEffect(() => {
    if (openSearch && !userSearched) {
      setData(projects);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  useEffect(() => {
    setData(projects);

    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [projects]);

  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (!e?.target?.value?.trim() == '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = projects.filter(({ name, modifiedByUname }) => {
          return isValueIncludes(e, name) || isValueIncludes(e, modifiedByUname);
        });
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
        setData(newData);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setData(projects);
    setIsHighlight(!isHighlight);
    setSearchTextHighlight('');
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  // TODO :: handle Search filter :: END

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        columns: [],
      },
      {
        Header: 'Name',
        accessor: 'name',
        disableFilters: true,
        filter: 'multiple',
        Cell: (e) => (
          <a
            href=""
            title={e.value}
            onClick={(event) => {
              event.preventDefault();
              localStorage.setItem('selected-project', JSON.stringify(e.row.original));
              setSelectedProject(e);
              if (e.cell.row.values.status === 'Archive') {
                localStorage.removeItem('selected-project', JSON.stringify(e.row.original));
                history.push('/projectmenu/project');
              }
            }}
            className="hyper_link_color-common fontPoppinsRegularMd float-left ml-2"
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              maxWidth: '155px',
              float: 'left',
              pointerEvents: e.cell.row.values.status === 'Archive' ? 'none' : null,
            }}
          >
            {e.cell.row.values.status !== 'Archive' ? (
              <span>
                {' '}
                <HighlightText text={e.value} highlight={searchTextHighlight} />
              </span>
            ) : (
              <span style={{ color: '#b4c1cc' }}>
                {' '}
                <HighlightText text={e.value} highlight={searchTextHighlight} />
              </span>
            )}
          </a>
        ),
      },
      {
        Header: 'Type',
        accessor: 'type',
        filter: 'multiple',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common fontPoppinsRegularMd float-left ml-2">{value}</div>;
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        filter: 'multiple',
        disableFilters: true,
        Cell: ({ value }) => {
          return value === 'Open' ? (
            <div
              style={{
                color: '#399365',
              }}
              className="float-left ml-4 fontPoppinsRegularMd"
            >
              <span
                style={{
                  fontSize: '36px',
                  position: 'absolute',
                  marginLeft: '-9px',
                  marginTop: '-27px',
                }}
              >
                .
              </span>
              {'Open'}
            </div>
          ) : value === 'Archive' ? (
            <div className="label-archive float-left ml-4 fontPoppinsRegularMd">
              <span
                style={{
                  fontSize: '36px',
                  position: 'absolute',
                  marginLeft: '-9px',
                  marginTop: '-27px',
                }}
              >
                .
              </span>
              {value}
            </div>
          ) : (
            <div
              style={{
                color: '#BE4854',
              }}
              className="float-left ml-4 fontPoppinsRegularMd"
            >
              <span
                style={{
                  fontSize: '36px',
                  position: 'absolute',
                  marginLeft: '-9px',
                  marginTop: '-27px',
                }}
              >
                .
              </span>
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Modified By',
        accessor: 'modifiedByUname',
        filter: 'multiple',
        disableFilters: true,
        Cell: (e) =>
          e.value === '--' ? (
            <div className="table-non-link-color-common fontPoppinsRegularMd float-left ml-2">{e.value}</div>
          ) : (
            <a
              href="#"
              title={e.value}
              onClick={(event) => {
                event.preventDefault();
                if (call) {
                  call = false;
                  createObjUser(e.cell.row.original);
                }
                setdetailsHeader(event.target.innerText);
              }}
              className="hyper_link_color-common fontPoppinsRegularMd float-left ml-2"
              style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: '155px',
                float: 'left',
              }}
            >
              <span>
                {' '}
                <HighlightText text={e.value} highlight={searchTextHighlight} />
              </span>
            </a>
          ),
      },
      {
        Header: 'Modified On',
        accessor: 'modifiedOn',
        filter: 'dateBetween',
        disableFilters: true,
        Filter: DateColumnFilter,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common fontPoppinsRegularMd float-left ml-2">{value}</div>;
        },
      },
      {
        Header: 'Actions',
        accessor: 'Actions',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common rs-icon-alignment-font-style float-left">{value}</div>;
        },
      },
    ],
    [isHighlight]
  );
  const contextMenuData = [
    {
      option: 'Details',
      icon: '/assets/images/eye_logo.svg',
    },
  ];

  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage] = React.useState(4);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const [popupHeader, setpopupHeader] = React.useState();
  const [popupBtn, setpopupbtn] = React.useState();
  const [popupStyle, setpopupStyle] = React.useState();
  const setpopupHeaderAndBtn = (header, btn) => {
    setpopupHeader(header);
    setpopupbtn(btn);
    if (header === 'Create Project' || header === 'Edit Project') {
      setpopupStyle(customStylesProject);
    } else if (header === 'Project Details') {
      setpopupStyle(customStylesDetailsProject);
    } else if (header === 'User Details') {
      setpopupStyle(customStylesUserDetailsProject);
    }
  };
  const [open, setOpen] = React.useState(false);
  const [createUpdateCalled, setCreateUpdateCalled] = React.useState(true);
  let handelDlt = () => {
    if (createUpdateCalled) {
      setCreateUpdateCalled(false);
      deleteProjectRequest(seletedProject.id)
        .then((res) => {
          setTimeout(() => {
            setCreateUpdateCalled(true);
          }, 2000);
          if (res) {
            getAllProjects();
            if (res.data.responseObject) {
              MyAlert.success(`${seletedProject.name} Project deleted successfully`);
              setOpen(false);
            }
          }
        })
        .catch((error) => console.error(error));
    }
  };

  const [detailsHeader, setdetailsHeader] = React.useState();
  const [users, setUsers] = React.useState([]);

  if (!isLoaded) {
    return <CommonLoader />;
  }
  const maximumProject = JSON.parse(localStorage.getItem('allowedProjects'))?.privilege?.project?.count;

  return (
    <div className="content-wrapper ">
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={open}
          onClose={() => setOpen(true)}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className="inline-block marginupper pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/4"
                style={{ minWidth: '640px', height: '217px' }}
              >
                <div className="inline-block">
                  <div className="flex flex-start ml-2">
                    <span className="pt-5 pl-3">
                      <img src="/assets/images/remove-user-icon.svg" alt="" height="25px" width="25px" />
                    </span>
                    <Dialog.Title as="h3" className="leading-6 ml-4 pt-3 text-gray-900">
                      <span className="fontPoppinsSemiboldLg block">Warning </span>
                      <span className="fontPoppinsMediumMd">Delete </span>
                    </Dialog.Title>
                  </div>
                </div>

                <hr className="line mt-px" />
                <div className="fontPoppinsMediumMd mt-3 text-gray-500 ml-14 mr-8">
                  <p className="">
                    {' '}
                    Your data will be lost. Are you sure you want to delete {seletedProject?.name} project?{' '}
                  </p>
                  <p className="mt-2"> How do you want to proceed?</p>
                </div>
                <hr className="line mt-3" />
                {seletedProject?.name?.length < 15 ? (
                  <div className="mt-2 pb-2 flex flex-row float-right">
                    <button
                      type="button"
                      className="mt-3.5 gray-btn ml-1"
                      style={{
                        marginRight: '12px',
                      }}
                      onClick={() => {
                        handelDlt();
                      }}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="mt-3.5 ml-4 primary-btn"
                      style={{
                        marginLeft: '12px',
                      }}
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <div className="mt-2 pb-2 flex flex-row float-right">
                    <button
                      type="button"
                      className="mt-0 gray-btn ml-1"
                      style={{
                        marginRight: '12px',
                      }}
                      onClick={() => {
                        handelDlt();
                      }}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="mt-0 ml-4 primary-btn "
                      style={{
                        marginLeft: '12px',
                      }}
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Modal
        isOpen={showModal}
        onRequestClose={(e) => {
          if (e.key === "Escape"){
            setShowModal(false);
          }
        }}
        style={popupStyle}
      >
        <div>
          <div>
            <div className="Popup-header-common Popup-header-layout-style-project">
              {popupHeader === 'Create Project' ? (
                <label> {popupHeader}</label>
              ) : popupHeader === 'Edit Project' ? (
                <label
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    maxWidth: '250px',
                    float: 'left',
                    fontSize: '16px',
                  }}
                >
                  {' '}
                  {popupHeader} - {detailsHeader}
                </label>
              ) : popupHeader === 'Project Details' ? (
                <label>
                  {' '}
                  {popupHeader} - {detailsHeader}
                </label>
              ) : popupHeader === 'User Details' ? (
                <label>
                  {' '}
                  {popupHeader} - {detailsHeader}
                </label>
              ) : null}
            </div>
            <button
              onClick={() => {
                setShowModal(false);
                setSelectedProject({});
              }}
              type="button"
              className="float-right -mt-6 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 z-40" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
        {popupHeader === 'Create Project' || popupHeader === 'Edit Project' ? (
          <div className="mt-3.5 border-t border-#e2e5ec-200 " style={{ width: '106%', marginLeft: '-18px' }}>
            <ProjectCreatePage
              func={closePopup}
              MyAlert={MyAlert}
              project={seletedProject}
              button={popupBtn}
              popupType={popupHeader}
              projectCount={projectCount}
              getAllProjects={getAllProjects}
              redirectTo={redirectTo}
              setShowModal={setShowModal}
              closePopupOnCancel={closePopupOnCancel}
            />
          </div>
        ) : popupHeader === 'Project Details' ? (
          <div className="m-3.5 border-t   border-#e2e5ec-200" style={{ width: '106%', marginLeft: '-18px' }}>
            <ProjectDetailsPage projectDetails={seletedProject} />

            <div className="border-t mt-10 ">
              <div className="delete_btn_style ">
                <button
                  onClick={() => {
                    setShowModal(false);
                    setSelectedProject({});
                  }}
                  className="mb-2 gray-btn close-btn-style-for-details-layout"
                >
                  <span>Close</span>
                </button>
              </div>
            </div>
          </div>
        ) : popupHeader === 'User Details' ? (
          <div className="m-3.5 border-t border-b border-#e2e5ec-200" style={{ width: '106%', marginLeft: '-18px' }}>
            <UserDetailsPage userDetails={users} selectedUser={userObj} />

            <div className="delete_btn_style">
              <button
                onClick={() => setShowModal(false)}
                className="mt-3 gray-btn close-btn-style-for-details-layout"
                style={{ marginRight: '14px' }}
              >
                <span>Close</span>
              </button>
            </div>
          </div>
        ) : null}
      </Modal>
      <>
        <div className="content_area_style">
          <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
            <label className="project_label flex-auto">Projects List</label>
            <div className="pr-4">
              <div className="flex flex-row ml-32">
                <div className="ml-auto pl-4">
                  <SearchBar
                    open={openSearch}
                    searchValue={userSearched}
                    handleSearch={handleSearch}
                    onSearchClose={handleSearchClose}
                    handleSearchOpen={handleSearchOpen}
                    enterKeyHandler={handleSearchSubmit}
                    disable={isEmptyValue(projects)}
                  />
                </div>

                <button
                  className="primary-btn ml-4"
                  onClick={() => {
                    setShowModal(true);
                    setSelectedProject({});
                    setpopupHeaderAndBtn('Create Project', 'Create');
                  }}
                >
                  + Project
                </button>
              </div>
            </div>
          </div>
          <div className="table-height overflow-auto ">
            <Table columns={columns} data={data} />
            {/* -------------------TODO :: display NoResultsFound on if search doesn't match -------------------*/}
            {openSearch && isEmptyValue(data) && <NoResultsFound className="mt-10" />}

            {/* -------------------- TODO :: display when Project is not created ----------------------  */}
            {isEmptyValue(projects) && (
              <div className="fontPoppinsRegularMd no-steps">
                <div className="">
                  <p className="font-bold mb-4">Quick Start</p>
                  <p className="mb-4">
                    <span className="font-bold">Step 1 :</span> Click
                    <button
                      className="primary-btn ml-4"
                      onClick={() => {
                        setShowModal(true);
                        setSelectedProject({});
                        setpopupHeaderAndBtn('Create Project', 'Create');
                      }}
                    >
                      + Project
                    </button>
                    {`  to start adding the Projects`}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </div>
  );
}

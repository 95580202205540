import React, { useState, useEffect } from 'react';
import './template.scss';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import { TextField, Select, InputLabel } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { styled } from '@mui/material/styles';
import LabeledInput from '@pages/common/Inputs/LabeledInput';
import CloseIcon from '@material-ui/icons/Close';
import { MenuItem } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useAlert } from '@pages/common/alert_service/useAlert';

export default function FieldForm(props) {
  const [openModal, setOpenModal] = useState(true);
  const [createCalled, setCreateCalled] = useState(false);
  const [selectedCustomValue, setSelectedCustomValue] = useState(
    Object.keys(props.editFieldValue).length !== 0 ? props.editFieldValue.type : 'textField'
  );
  const [radioFormCounter, setRadioFormCounter] = useState(
    props.editFieldValue?.type === 'radioButton' ? props.editFieldValue.options.length : 1
  );
  const [checkboxFormCounter, setCheckboxFormCounter] = useState(
    props.editFieldValue?.type === 'checkbox' ? props.editFieldValue.options.length : 1
  );
  const [dropdownFormCounter, setDropdownFormCounter] = useState(
    props.editFieldValue?.type === 'dropdown' ? props.editFieldValue.options.length : 1
  );
  const [initialModalStyle, setInitialModalStyle] = useState('modal_dialog_TC');
  const [disabledDropDown, setDisabledDropDown] = useState(false);
  const [enableTFValue, setEnableTFValue] = useState(
    props.editFieldValue?.type === 'textField' ? props.editFieldValue.enable : 'yes'
  );
  const [mandatoryTFValue, setMandatoryTFValue] = useState(
    props.editFieldValue?.type === 'textField' ? props.editFieldValue.mandatory : 'no'
  );
  const [allowExecutionForTextfield, setAllowExecutionForTextfield] = useState(
    props.editFieldValue?.type === 'textField' ? props.editFieldValue.editInExecution : 'no'
  );
  const [enableTAValue, setEnableTAValue] = useState(
    props.editFieldValue?.type === 'textArea' ? props.editFieldValue.enable : 'yes'
  );
  const [mandatoryTAValue, setMandatoryTAValue] = useState(
    props.editFieldValue?.type === 'textArea' ? props.editFieldValue.mandatory : 'no'
  );
  const [allowExecutionForTextarea, setAllowExecutionForTextarea] = useState(
    props.editFieldValue?.type === 'textArea' ? props.editFieldValue.editInExecution : 'no'
  );
  const [mandatoryRBValue, setMandatoryRBValue] = useState(
    props.editFieldValue?.type === 'radioButton' ? props.editFieldValue.mandatory : 'no'
  );
  const [allowExecutionForRadioType, setAllowExecutionForRadioType] = useState(
    props.editFieldValue?.type === 'radioButton' ? props.editFieldValue.editInExecution : 'no'
  );
  const [mandatoryLFValue, setMandatoryLFValue] = useState(
    props.editFieldValue?.type === 'link' ? props.editFieldValue.mandatory : 'no'
  );
  const [allowExecutionForLinkType, setAllowExecutionForLinkType] = useState(
    props.editFieldValue?.type === 'link' ? props.editFieldValue.editInExecution : 'no'
  );
  const [radioOptions, setRadioOptions] = useState(
    props.editFieldValue?.type === 'radioButton' ? props.editFieldValue.options : ['']
  );
  const [radioOption, setRadioOption] = useState('');
  const [mandatoryDDValue, setMandatoryDDValue] = useState(
    props.editFieldValue?.type === 'dropdown' ? props.editFieldValue.mandatory : 'no'
  );
  const [allowExecutionForDropDown, setAllowExecutionForDropDown] = useState(
    props.editFieldValue?.type === 'dropdown' ? props.editFieldValue.editInExecution : 'no'
  );
  const [ddOptions, setDDOptions] = useState(
    props.editFieldValue?.type === 'dropdown' ? props.editFieldValue.options : ['']
  );
  const [ddOption, setDDOption] = useState('');
  const [mandatoryDFValue, setMandatoryDFValue] = useState(
    props.editFieldValue?.type === 'date' ? props.editFieldValue.mandatory : 'no'
  );
  const [allowExecutionForDate, setAllowExecutionForDate] = useState(
    props.editFieldValue?.type === 'date' ? props.editFieldValue.editInExecution : 'no'
  );
  const [mandatoryCBValue, setMandatoryCBValue] = useState(
    props.editFieldValue?.type === 'checkbox' ? props.editFieldValue.mandatory : 'no'
  );
  const [allowExecutionForCheckbox, setAllowExecutionForCheckbox] = useState(
    props.editFieldValue?.type === 'checkbox' ? props.editFieldValue.editInExecution : 'no'
  );
  const [cbOptions, setCBOptions] = useState(
    props.editFieldValue?.type === 'checkbox' ? props.editFieldValue.options : ['']
  );
  const [cbOption, setCBOption] = useState('');
  const [mandatoryAFValue, setMandatoryAFValue] = useState(
    props.editFieldValue?.type === 'attachment' ? props.editFieldValue.mandatory : 'no'
  );
  const [allowExecutionForAttach, setAllowExecutionForAttach] = useState(
    props.editFieldValue?.type === 'attachment' ? props.editFieldValue.editInExecution : 'no'
  );
  const [mandatoryTextEditorValue, setMandatoryTextEditorValue] = useState(
    props.editFieldValue?.type === 'textEditor' ? props.editFieldValue.mandatory : 'no'
  );
  const [allowExecutionForTextEditor, setAllowExecutionForTextEditor] = useState(
    props.editFieldValue?.type === 'textEditor' ? props.editFieldValue.editInExecution : 'no'
  );
  const [textFieldValidate, settextFieldValidate] = useState('');
  const [textFieldMaxValueValidate, setTextFieldMaxValueValidate] = useState('');
  const [minEmptyValueCheck, setMinEmptyValueCheck] = useState('');
  const [maxEmptyValueCheck, setMaxEmptyValueCheck] = useState('');
  const [textAreaValidate, setTextAreaValidate] = useState('');
  const [texAreadMaxValueValidate, setTextAreadMaxValueValidate] = useState('');
  const [radioFieldValidate, setRadioFieldValidate] = useState('');
  const [radioFieldOptionValidate, setRadioFieldOptionValidate] = useState('');
  const [radioFieldOptionUniqueValidate, setRadioFieldOptionUniqueValidate] = useState('');
  const [radioFieldOptionAlphaNumericValidate, setRadioFieldOptionAlphaNumericValidate] = useState('');
  const [radioFieldOptionDuplicateArray, setRadioFieldOptionDuplicateArray] = useState([]);
  const [linkFieldValidate, setLinkFieldValidate] = useState('');
  const [dropDownFieldValidate, setDropDownFieldValidate] = useState('');
  const [dropDownFieldOptionValidate, setDropDownFieldOptionValidate] = useState('');
  const [dropDownFieldOptionUniqueValidate, setDropDownFieldOptionUniqueValidate] = useState('');
  const [dropDownFieldOptionAlphaNumericValidate, setDropDownFieldOptionAlphaNumericValidate] = useState('');
  const [dropDownFieldOptionDuplicateArray, setDropDownFieldOptionDuplicateArray] = useState([]);
  const [dateFieldValidate, setDateFieldValidate] = useState('');
  const [checkBoxfieldValidate, setCheckBoxfieldValidate] = useState('');
  const [checkBoxFieldOptionValidate, setCheckBoxFieldOptionValidate] = useState('');
  const [checkBoxFieldOptionUniqueValidate, setCheckBoxFieldOptionUniqueValidate] = useState('');
  const [checkBoxFieldOptionAlphaNumericValidate, setCheckBoxFieldOptionAlphaNumericValidate] = useState('');
  const [checkBoxFieldOptionDuplicateArray, setCheckBoxFieldOptionDuplicateArray] = useState([]);
  const [attachmentFieldValidate, setAttachmentFieldValidate] = useState('');
  const [checkForAlphanumeric, setCheckForAlphanumeric] = useState('');
  const [checkForAlphanumericPlaceholderTF, setCheckForAlphanumericPlaceholderTF] = useState('');
  const [checkForAlphanumericDefaultTF, setCheckForAlphanumericDefaultTF] = useState('');
  const [checkLengthValidationDefaultTF, setCheckLengthValidationDefaultTF] = useState('');
  const [checkMinLengthValidationDefaultTF, setCheckMinLengthValidationDefaultTF] = useState('');
  const [checkForAlphanumericPlaceholderTA, setCheckForAlphanumericPlaceholderTA] = useState('');
  const [checkForAlphanumericDefaultTA, setCheckForAlphanumericDefaultTA] = useState('');
  const [checkLengthValidationDefaultTA, setCheckLengthValidationDefaultTA] = useState('');
  const [checkMinLengthValidationDefaultTA, setCheckMinLengthValidationDefaultTA] = useState('');
  const isManualTestCaseTemplate = props.source === 'tcm';

  console.log('isManualTestCaseTemplate', isManualTestCaseTemplate);

  const location = useLocation();
  const dmode = new URLSearchParams(location.search).get('dmode');

  const { AlertContatiner, MyAlert } = useAlert();

  const initialValues = {
    selectCustomField: '',
    fieldName: props.editFieldValue?.type === 'textField' ? props.editFieldValue.label : '',
    textAreaFormLabel: props.editFieldValue?.type === 'textArea' ? props.editFieldValue.label : '',
    placeholder: props.editFieldValue?.type === 'textField' ? props.editFieldValue.placeholder : '',
    maxLength: props.editFieldValue?.type === 'textField' ? props.editFieldValue.maxLength : 25,
    minLength: props.editFieldValue?.type === 'textField' ? props.editFieldValue.minLength : 3,
    defaultValueTF: props.editFieldValue?.type === 'textField' ? props.editFieldValue.defaultValue : '',
    maxLengthTextArea: props.editFieldValue?.type === 'textArea' ? props.editFieldValue.maxLength : 200,
    minLengthTextArea: props.editFieldValue?.type === 'textArea' ? props.editFieldValue.minLength : 0,
    placeholderTextArea: props.editFieldValue?.type === 'textArea' ? props.editFieldValue.placeholder : '',
    defaultValueTA: props.editFieldValue?.type === 'textArea' ? props.editFieldValue.defaultValue : '',
    radiobuttonForm: props.editFieldValue?.type === 'radioButton' ? props.editFieldValue.label : '',
    radioOptionField: '',
    linkForm: props.editFieldValue?.type === 'link' ? props.editFieldValue.label : '',
    dropDown: props.editFieldValue?.type === 'dropdown' ? props.editFieldValue.label : '',
    checkboxForm: props.editFieldValue?.type === 'checkbox' ? props.editFieldValue.label : '',
    attachmentFormLabel: props.editFieldValue?.type === 'attachment' ? props.editFieldValue.label : '',
    checkboxFormOptionField: '',
    dateFormat: props.editFieldValue?.type === 'date' ? props.editFieldValue.dateFormat : 'dd-mm-yyyy',
    dateForm: props.editFieldValue?.type === 'date' ? props.editFieldValue.label : '',
    dropdownFormOptionField: '',
    richTextEditorFormLabel: props.editFieldValue?.type === 'textEditor' ? props.editFieldValue.label : '',
  };

  const selectedCustomFieldArray = [
    {
      name: 'Text Field',
      value: 'textField',
    },
    {
      name: 'Text Area',
      value: 'textArea',
    },
    {
      name: 'Radio Button',
      value: 'radioButton',
    },
    {
      name: 'Link',
      value: 'link',
    },
    {
      name: 'Dropdown',
      value: 'dropdown',
    },
    {
      name: 'Date',
      value: 'date',
    },
    {
      name: 'Checkbox',
      value: 'checkbox',
    },
    {
      name: 'Attachment',
      value: 'attachment',
    },
    {
      name: 'Text Editor',
      value: 'textEditor',
    },
  ];

  useEffect(() => {
    onSelectCustomField('', props?.editFieldValue?.type);
  }, []);

  const closePopUpModal = () => {
    props.closeModal(false);
    setOpenModal(false);
  };

  const handleEnableTFChange = (e) => {
    setEnableTFValue(e.target.value);
  };

  const handelExecutionRadioChangeForTextField = (e) => {
    setAllowExecutionForTextfield(e.target.value);
  };

  const handelExecutionRadioChangeForTextArea = (e) => {
    setAllowExecutionForTextarea(e.target.value);
  };

  const handelExecutionRadioChangeForRadioType = (e) => {
    setAllowExecutionForRadioType(e.target.value);
  };

  const handelExecutionRadioChangeForLinkType = (e) => {
    setAllowExecutionForLinkType(e.target.value);
  };

  const handelExecutionRadioChangeForDropDown = (e) => {
    setAllowExecutionForDropDown(e.target.value);
  };

  const handelExecutionRadioChangeForDate = (e) => {
    setAllowExecutionForDate(e.target.value);
  };

  const handelExecutionRadioChangeForCheckbox = (e) => {
    setAllowExecutionForCheckbox(e.target.value);
  };

  const handelExecutionRadioChangeForAttach = (e) => {
    setAllowExecutionForAttach(e.target.value);
  };

  const handelExecutionRadioChangeForTextEditor = (e) => {
    setAllowExecutionForTextEditor(e.target.value);
  };

  const handleMandatoryTFChange = (e) => {
    setMandatoryTFValue(e.target.value);
    setEnableTFValue('yes');
  };

  const handleEnableTAChange = (e) => {
    setEnableTAValue(e.target.value);
  };

  const handleMandatoryTAChange = (e) => {
    setMandatoryTAValue(e.target.value);
    setEnableTAValue('yes');
  };

  const handleMandatoryRBChange = (e) => {
    setMandatoryRBValue(e.target.value);
  };

  const handleMandatoryLFChange = (e) => {
    setMandatoryLFValue(e.target.value);
  };
  const handleMandatoryDDChange = (e) => {
    setMandatoryDDValue(e.target.value);
  };

  const handleMandatoryDFChange = (e) => {
    setMandatoryDFValue(e.target.value);
  };

  const handleMandatoryCBChange = (e) => {
    setMandatoryCBValue(e.target.value);
  };

  const handleMandatoryAFChange = (e) => {
    setMandatoryAFValue(e.target.value);
  };
  const handleMandatorytextEditorChange = (e) => {
    setMandatoryTextEditorValue(e.target.value);
  };

  const checkDuplicateOptions = (a) => {
    let b = Array.from(new Set(a.map((e) => e.toLowerCase().trim())));
    return a.length !== b.length;
  };

  const indexOfAll = (arr, val) => arr.reduce((acc, el, i) => (el === val ? [...acc, i] : acc), []);

  function findDuplicates(arr) {
    const filtered = arr.filter((item, index) => arr.indexOf(item) !== index);
    return [...new Set(filtered)];
  }

  const getDuplicateOptions = (a) => {
    let tempArray = a.map((v) => v.toLowerCase());
    let b = findDuplicates(tempArray);
    let c = [];
    b.map((d) => {
      c.push([...indexOfAll(tempArray, d)]);
    });
    return c;
  };

  function handleTrimmedBlur(event) {
    event.target.value = event.target.value.trim();
    formikDetails.handleChange(event);
    formikDetails.handleBlur(event);
  }
  const textFieldForm = () => {
    //Text Field Form Code Goes Here
    return (
      <>
        <form onSubmit={handelTextFieldFormSubmit} id="journal-scroll">
          <div className="modal_body" id="journal-scroll">
            <div className="grid grid-cols-2 gap-4">
              <div className="rs-user-input-container">
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={
                    <div className="fontPoppinsRegularXLg">
                      {' '}
                      <span className="text-red-400 mr-1">&#42;</span> <span className="">Label</span>
                    </div>
                  }
                  autoFocus={false}
                  placeholder="Type here"
                  className=" w-60"
                  type="text"
                  autoComplete="off"
                  name="fieldName"
                  id="fieldName"
                  onBlur={handleTrimmedBlur}
                  onChange={formikDetails.handleChange}
                  value={formikDetails.values.fieldName}
                />
                {!formikDetails.values.fieldName && textFieldValidate ? (
                  <p className="errorMessage fontPoppinsRegularSm">{textFieldValidate}</p>
                ) : null}
                {!alphaNumericPattern.test(formikDetails.values.fieldName) ? (
                  <>
                    {' '}
                    <p className="errorMessage fontPoppinsRegularSm fixed">{checkForAlphanumeric}</p>
                  </>
                ) : null}
              </div>
              <div className="rs-user-input-container">
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={<div className="fontPoppinsRegularXLg">Placeholder</div>}
                  size="40"
                  className={'text-gray-700 w-60 '}
                  type="text"
                  autoComplete="off"
                  name="placeholder"
                  id="placeholder"
                  placeholder="Type here"
                  onBlur={handleTrimmedBlur}
                  onChange={formikDetails.handleChange}
                  value={formikDetails.values.placeholder}
                />
                {!alphaNumericPattern.test(formikDetails.values.placeholder) ? (
                  <>
                    {' '}
                    <div className="errorMessage fontPoppinsRegularSm fixed">{checkForAlphanumericPlaceholderTF}</div>
                  </>
                ) : null}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div>
                <LabeledInput
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1,
                      className: 'default-input',
                    },
                  }}
                  label={
                    <div className="fontPoppinsRegularMd">
                      <span className="text-red-400 mr-1">&#42;</span> <span className=" opacity-70"> Max Length</span>
                    </div>
                  }
                  className={'text-gray-700 '}
                  onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                  placeholder="Type here"
                  autoComplete="off"
                  name="maxLength"
                  id="maxLength"
                  onBlur={formikDetails.handleBlur}
                  onChange={formikDetails.handleChange}
                  value={formikDetails.values.maxLength}
                  error={
                    (typeof formikDetails.values.maxLength === 'string' && maxEmptyValueCheck) ||
                    (formikDetails.values.maxLength < formikDetails.values.minLength && textFieldMaxValueValidate)
                      ? true
                      : false
                  }
                  helperText={
                    <div className="errorMessage fontPoppinsRegularSm fixed">
                      {typeof formikDetails.values.maxLength === 'string' ? (
                        maxEmptyValueCheck
                      ) : formikDetails.values.maxLength < formikDetails.values.minLength ? (
                        <div className="errorMessage fontPoppinsRegularSm">{textFieldMaxValueValidate}</div>
                      ) : null}
                    </div>
                  }
                  wrapperClassName="w-60"
                />
              </div>
              <div>
                <LabeledInput
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      className: 'default-input',
                    },
                  }}
                  label={
                    <div className="fontPoppinsRegularMd">
                      <span className="text-red-400 mr-1">&#42;</span> <span className=" opacity-70">Min Length</span>
                    </div>
                  }
                  className={'text-gray-700 w-60'}
                  onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                  autoComplete="off"
                  name="minLength"
                  id="minLength"
                  placeholder="Type here"
                  onBlur={formikDetails.handleBlur}
                  onChange={formikDetails.handleChange}
                  value={formikDetails.values.minLength}
                  wrapperClassName="w-60"
                  error={!formikDetails.values.minLength && minEmptyValueCheck ? true : false}
                  helperText={
                    <div className="errorMessage fontPoppinsRegularSm fixed">
                      {!formikDetails.values.minLength && minEmptyValueCheck}
                    </div>
                  }
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="rs-user-input-container">
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={<div className="fontPoppinsRegularXLg">Default Value</div>}
                  className={'text-gray-700 w-60 fontPoppinsRegularMd'}
                  size="40"
                  placeholder="Type here"
                  type="text"
                  autoComplete="off"
                  name="defaultValueTF"
                  id="defaultValueTF"
                  onBlur={handleTrimmedBlur}
                  onChange={formikDetails.handleChange}
                  value={formikDetails.values.defaultValueTF}
                />
                {!alphaNumericPattern.test(formikDetails.values.defaultValueTF) && (
                  <>
                    <p className="errorMessage fontPoppinsRegularSm fixed ">{checkForAlphanumericDefaultTF}</p>
                  </>
                )}
                {formikDetails.values.maxLength &&
                formikDetails.values.maxLength < formikDetails.values.defaultValueTF.length ? (
                  <>
                    <p className="errorMessage fixed fontPoppinsRegularSm w-60">{checkLengthValidationDefaultTF}</p>
                  </>
                ) : formikDetails.values.minLength &&
                  formikDetails.values.minLength > formikDetails.values.defaultValueTF.length ? (
                  <>
                    <p className="errorMessage fontPoppinsRegularSm w-80">{checkMinLengthValidationDefaultTF}</p>
                  </>
                ) : null}
              </div>
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <span className="fontPoppinsRegularMd">Enable</span>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    value={enableTFValue}
                    onChange={handleEnableTFChange}
                    defaultValue="top"
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio color="primary" />}
                      label={<div className="fontPoppinsRegularMd">Yes</div>}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio color="primary" disabled={mandatoryTFValue === 'yes'} />}
                      label={<div className="fontPoppinsRegularMd">No</div>}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {' '}
                    <span className="text-red-400 mr-1">&#42;</span>
                    <span className="fontPoppinsRegularMd -mb-2">Mandatory</span>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    value={mandatoryTFValue}
                    onChange={handleMandatoryTFChange}
                    defaultValue="top"
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio color="primary" />}
                      label={<div className="fontPoppinsRegularMd">Yes</div>}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio color="primary" />}
                      label={<div className="fontPoppinsRegularMd">No</div>}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {props.editFieldValue?.label !== 'Pre Conditions' && isManualTestCaseTemplate && (
                <div>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      <span className="fontPoppinsRegularMd">Allow Edit in Execution</span>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      value={allowExecutionForTextfield}
                      onChange={handelExecutionRadioChangeForTextField}
                      defaultValue="top"
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio color="primary" />}
                        label={<div className="fontPoppinsRegularMd">Yes</div>}
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio color="primary" />}
                        label={<div className="fontPoppinsRegularMd">No</div>}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="gray-btn mr-3"
              type="button"
              onClick={() => {
                closePopUpModal();
              }}
            >
              Cancel
            </button>
            <button type="submit" className="primary-btn">
              {props.editFieldValue.label ? <span>Update</span> : <span>Add</span>}
            </button>
          </div>
        </form>
      </>
    );
  };

  const textAreaForm = () => {
    //Text Area Form Code Goes Here
    return (
      <>
        <form onSubmit={handelTextAreadFormSubmit} id="journal-scroll">
          <div className="modal_body" id="journal-scroll">
            <div className="grid grid-cols-2 gap-4">
              <div className="rs-user-input-container">
                <TextField
                  error={formikDetails.errors.textAreaFormLabel && formikDetails.touched.textAreaFormLabel}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={
                    <div className="fontPoppinsRegularXLg">
                      <span className="text-red-400 mr-1">&#42;</span> <span className="">Label</span>
                    </div>
                  }
                  className=" w-60 "
                  type="text"
                  autoComplete="off"
                  autoFocus={true}
                  name="textAreaFormLabel"
                  id="textAreaFormLabel"
                  placeholder="Type here"
                  onBlur={handleTrimmedBlur}
                  onChange={formikDetails.handleChange}
                  value={formikDetails.values.textAreaFormLabel}
                />
                {!formikDetails.values.textAreaFormLabel && textAreaValidate ? (
                  <p className="errorMessage fixed fontPoppinsRegularSm">{textAreaValidate}</p>
                ) : null}
                {!alphaNumericPattern.test(formikDetails.values.textAreaFormLabel) ? (
                  <>
                    <p className="errorMessage fixed fontPoppinsRegularSm">{checkForAlphanumeric}</p>
                  </>
                ) : null}
              </div>
              <div className="rs-user-input-container">
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={<div className="fontPoppinsRegularXLg">Placeholder</div>}
                  size="40"
                  placeholder="Type here"
                  className={'text-gray-700 w-60 '}
                  type="text"
                  autoComplete="off"
                  name="placeholderTextArea"
                  id="placeholderTextArea"
                  onBlur={handleTrimmedBlur}
                  onChange={formikDetails.handleChange}
                  value={formikDetails.values.placeholderTextArea}
                />
                {!alphaNumericPattern.test(formikDetails.values.placeholderTextArea) && (
                  <>
                    <p className="errorMessage fixed fontPoppinsRegularSm">{checkForAlphanumericPlaceholderTA}</p>
                  </>
                )}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div>
                <LabeledInput
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1,
                      step: '1',
                      className: 'default-input',
                    },
                  }}
                  label={<div className="fontPoppinsRegularMd opacity-70">Max Length</div>}
                  className={'text-gray-700 w-60 '}
                  placeholder="Type here"
                  autoComplete="off"
                  name="maxLengthTextArea"
                  id="maxLengthTextArea"
                  onBlur={formikDetails.handleBlur}
                  onChange={formikDetails.handleChange}
                  value={formikDetails.values.maxLengthTextArea}
                  error={texAreadMaxValueValidate ? true : false}
                  helperText={<div className="fixed errorMessage">{texAreadMaxValueValidate}</div>}
                  wrapperClassName="w-60"
                  onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                />
              </div>
              <div>
                <LabeledInput
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: '0',
                      step: '1',
                      className: 'default-input',
                    },
                  }}
                  label={<div className="fontPoppinsRegularMd opacity-70">Min Length</div>}
                  className={'text-gray-700 w-60 '}
                  placeholder="Type here"
                  autoComplete="off"
                  name="minLengthTextArea"
                  id="minLengthTextArea"
                  onBlur={formikDetails.handleBlur}
                  onChange={formikDetails.handleChange}
                  value={formikDetails.values.minLengthTextArea}
                  wrapperClassName="w-60"
                  onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="rs-user-input-container">
                <TextField
                  error={formikDetails.errors.defaultValueTA && formikDetails.touched.defaultValueTA}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={<div className="fontPoppinsRegularXLg">Default Value</div>}
                  className={'text-gray-700 w-60 '}
                  size="40"
                  placeholder="Type here"
                  type="text"
                  autoComplete="off"
                  name="defaultValueTA"
                  id="defaultValueTA"
                  onBlur={handleTrimmedBlur}
                  onChange={formikDetails.handleChange}
                  value={formikDetails.values.defaultValueTA}
                />
                {!alphaNumericPattern.test(formikDetails.values.defaultValueTA) && (
                  <>
                    <p className="errorMessage fixed fontPoppinsRegularSm w-60">{checkForAlphanumericDefaultTA}</p>
                  </>
                )}
                {(formikDetails.values.maxLengthTextArea &&
                  formikDetails.values.minLengthTextArea &&
                  formikDetails.values.maxLengthTextArea < formikDetails.values.defaultValueTA.length) ||
                (formikDetails.values.maxLengthTextArea &&
                  formikDetails.values.defaultValueTA &&
                  formikDetails.values.maxLengthTextArea < formikDetails.values.defaultValueTA.length &&
                  !formikDetails.values.minLengthTextArea) ? (
                  <>
                    <p className="errorMessage fixed fontPoppinsRegularSm w-60">{checkLengthValidationDefaultTA}</p>
                  </>
                ) : formikDetails.values.minLengthTextArea > formikDetails.values.defaultValueTA.length ? (
                  <>
                    <p className="errorMessage fixed fontPoppinsRegularSm w-60">{checkMinLengthValidationDefaultTA}</p>
                  </>
                ) : null}
              </div>
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <span className="fontPoppinsRegularMd">Enable</span>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    value={enableTAValue}
                    onChange={handleEnableTAChange}
                    defaultValue="top"
                  >
                    <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel
                      value="no"
                      control={<Radio color="primary" disabled={mandatoryTAValue === 'yes'} />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {' '}
                    <span className="text-red-400 mr-1">&#42;</span>
                    <span className="fontPoppinsRegularMd">Mandatory</span>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    value={mandatoryTAValue}
                    onChange={handleMandatoryTAChange}
                    defaultValue="top"
                  >
                    <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                  </RadioGroup>
                </FormControl>
                {formikDetails.errors.webhooksUrl && formikDetails.touched.webhooksUrl ? (
                  <div className="errorMessage fontPoppinsRegularSm">{formikDetails.errors.webhooksUrl}</div>
                ) : null}
              </div>

              {props.editFieldValue?.label !== 'Description' && isManualTestCaseTemplate && (
                <div>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      <span className="fontPoppinsRegularMd">Allow Edit in Execution</span>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      value={allowExecutionForTextarea}
                      onChange={handelExecutionRadioChangeForTextArea}
                      defaultValue="top"
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio color="primary" />}
                        label={<div className="fontPoppinsRegularMd">Yes</div>}
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio color="primary" />}
                        label={<div className="fontPoppinsRegularMd">No</div>}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="gray-btn mr-3"
              type="button"
              onClick={() => {
                closePopUpModal();
              }}
            >
              Cancel
            </button>
            <button type="submit" className="primary-btn">
              {props.editFieldValue.label ? <span>Update</span> : <span>Add</span>}
            </button>
          </div>
        </form>
      </>
    );
  };

  const radiobuttonForm = () => {
    //radio button Form code here

    const handleAddRadioOption = (e) => {
      if (!radioOptions.map((x) => alphaNumericPattern.test(x)).includes(false)) {
        if (!checkDuplicateOptions(radioOptions)) {
          setRadioOptions((radioOptions) => [...radioOptions, radioOption]);
          setRadioOption('');
          setRadioFieldOptionValidate('');
          setRadioFormCounter(radioFormCounter + 1);
        } else {
          setRadioFieldOptionDuplicateArray(getDuplicateOptions(radioOptions));
          setRadioFieldOptionUniqueValidate('Options should be unique');
        }
      } else {
        setRadioFieldOptionAlphaNumericValidate('Radio options should be alphanumeric');
      }
    };

    const addRadioOptions = (e, index, type) => {
      setRadioFieldOptionValidate('');
      setRadioFieldOptionUniqueValidate('');
      setRadioFieldOptionDuplicateArray([]);
      setRadioFieldOptionAlphaNumericValidate('');
      const radioOptionsCopy = [...radioOptions];
      radioOptionsCopy.forEach((val, i) => {
        if (index === i) {
          type === 'input' ? (radioOptionsCopy[i] = e.target.value) : (radioOptionsCopy[i] = e.target.value.trim());
        }
      });
      setRadioOptions(radioOptionsCopy);
    };

    const deleteRadioOptions = (e, index) => {
      setRadioFieldOptionValidate('');
      setRadioFieldOptionUniqueValidate('');
      setRadioFieldOptionDuplicateArray([]);
      setRadioFieldOptionAlphaNumericValidate('');
      const radioOptionsCopy = [...radioOptions];
      index !== -1 && radioOptionsCopy.splice(index, 1);
      setRadioOptions(radioOptionsCopy);
    };

    return (
      <>
        <form id="journal-scroll">
          <div className="modal_body" id="journal-scroll">
            <div className="grid grid-cols-1 gap-4 mb-5">
              <div className="rs-user-input-container relative">
                <TextField
                  error={formikDetails.errors.radiobuttonForm && formikDetails.touched.radiobuttonForm}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={
                    <div className="fontPoppinsRegularXLg">
                      <span className="text-red-400 mr-1">&#42;</span> Label
                    </div>
                  }
                  className=" w-full "
                  placeholder="Type here"
                  type="text"
                  autoComplete="off"
                  name="radiobuttonForm"
                  id="radiobuttonForm"
                  onBlur={handleTrimmedBlur}
                  onChange={formikDetails.handleChange}
                  value={formikDetails.values.radiobuttonForm}
                />
                {!formikDetails.values.radiobuttonForm && radioFieldValidate ? (
                  <p className="errorMessage absolute fontPoppinsRegularSm">{radioFieldValidate}</p>
                ) : null}
                {!alphaNumericPattern.test(formikDetails.values.radiobuttonForm) && (
                  <>
                    <p className="errorMessage absolute fontPoppinsRegularSm">{checkForAlphanumeric}</p>
                  </>
                )}
              </div>
            </div>
            <div className="grid grid-cols-1">
              <div className="flex justify-between">
                <div className="">
                  {radioOptions.map((c, index) => {
                    return (
                      <div className="mb-7 rs-user-input-container relative">
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label={
                            <div className="fontPoppinsRegularXLg">
                              <span className="text-red-400 mr-1">&#42;</span> Option {index + 1}
                            </div>
                          }
                          key={index}
                          placeholder="Type here"
                          className={'text-gray-700   w-96'}
                          type="text"
                          autoComplete="off"
                          name="radioOptionField"
                          id="radioOptionField"
                          onBlur={(e) => {
                            addRadioOptions(e, index, 'blur');
                          }}
                          onInput={(e) => {
                            addRadioOptions(e, index, 'input');
                          }}
                          value={radioOptions[index]}
                        />
                        {radioOptions.length > 1 && (
                          <button
                            type="button"
                            onClick={(e) => {
                              deleteRadioOptions(e, index);
                            }}
                          >
                            <CloseIcon className="iconStyle cursor-pointer mx-4 mt-4 text-gray-500 " />
                          </button>
                        )}
                        {index + 1 === radioOptions.length && (
                          <button
                            type="button"
                            disabled={!radioOptions[radioOptions.length - 1]}
                            onClick={() => {
                              handleAddRadioOption();
                            }}
                          >
                            <AddCircleOutlineIcon
                              className={
                                !radioOptions[radioOptions.length - 1] ? 'opacity-50 mt-4' : 'opacity-100 mt-4'
                              }
                            />
                          </button>
                        )}
                        {radioFieldOptionValidate ? (
                          <p className="errorMessage absolute fontPoppinsRegularSm">
                            {(radioOptions[index] === '' ||
                              (radioOptions.length === 1 && !radioOptions.includes(''))) &&
                              radioFieldOptionValidate}
                          </p>
                        ) : radioFieldOptionUniqueValidate && radioFieldOptionDuplicateArray[0]?.includes(index) ? (
                          <p className="errorMessage absolute fontPoppinsRegularSm">{radioFieldOptionUniqueValidate}</p>
                        ) : radioFieldOptionAlphaNumericValidate && !alphaNumericPattern.test(radioOptions[index]) ? (
                          <p className="errorMessage absolute fontPoppinsRegularSm">
                            {radioFieldOptionAlphaNumericValidate}
                          </p>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <span className="text-red-400 mr-1">&#42;</span>
                    <span className="fontPoppinsRegularMd">Mandatory</span>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    value={mandatoryRBValue}
                    onChange={handleMandatoryRBChange}
                    defaultValue="top"
                  >
                    <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>

              <div>
                {isManualTestCaseTemplate && (
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      <span className="fontPoppinsRegularMd">Allow Edit in Execution</span>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      value={allowExecutionForRadioType}
                      onChange={handelExecutionRadioChangeForRadioType}
                      defaultValue="top"
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio color="primary" />}
                        label={<div className="fontPoppinsRegularMd">Yes</div>}
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio color="primary" />}
                        label={<div className="fontPoppinsRegularMd">No</div>}
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button
              className="gray-btn mr-3"
              type="button"
              onClick={() => {
                closePopUpModal();
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="primary-btn"
              onClick={(e) => {
                handelRadioFormSubmit(e);
              }}
            >
              {props.editFieldValue.label ? <span>Update</span> : <span>Add</span>}
            </button>
          </div>
        </form>
      </>
    );
  };

  const checkboxForm = () => {
    //checkbox Form code here
    const handleAddCheckboxOption = (e) => {
      if (!cbOptions.map((x) => alphaNumericPattern.test(x)).includes(false)) {
        if (!checkDuplicateOptions(cbOptions)) {
          setCBOptions((cbOptions) => [...cbOptions, cbOption]);
          setCBOption('');
          setCheckboxFormCounter(checkboxFormCounter + 1);
        } else {
          setCheckBoxFieldOptionDuplicateArray(getDuplicateOptions(cbOptions));
          setCheckBoxFieldOptionUniqueValidate('Options should be unique');
        }
      } else {
        setCheckBoxFieldOptionAlphaNumericValidate('Checkbox options should be alphanumeric');
      }
    };

    const addCBOptions = (e, index, type) => {
      setCheckBoxFieldOptionValidate('');
      setCheckBoxFieldOptionUniqueValidate('');
      setCheckBoxFieldOptionUniqueValidate('');
      setCheckBoxFieldOptionDuplicateArray([]);
      setCheckBoxFieldOptionAlphaNumericValidate('');
      const cbOptionsCopy = [...cbOptions];
      cbOptionsCopy.forEach((val, i) => {
        if (index === i) {
          type === 'input' ? (cbOptionsCopy[i] = e.target.value) : (cbOptionsCopy[i] = e.target.value.trim());
        }
      });
      setCBOptions(cbOptionsCopy);
    };

    const deleteCBOptions = (e, index) => {
      setCheckBoxFieldOptionValidate('');
      setCheckBoxFieldOptionUniqueValidate('');
      setCheckBoxFieldOptionUniqueValidate('');
      setCheckBoxFieldOptionDuplicateArray([]);
      setCheckBoxFieldOptionAlphaNumericValidate('');
      const cbOptionsCopy = [...cbOptions];
      index !== -1 && cbOptionsCopy.splice(index, 1);
      setCBOptions(cbOptionsCopy);
    };

    return (
      <>
        <form id="journal-scroll">
          <div className="modal_body" id="journal-scroll">
            <div className="grid grid-cols-1 gap-4 mb-5">
              <div className="rs-user-input-container relative">
                <TextField
                  error={formikDetails.errors.checkboxForm && formikDetails.touched.checkboxForm}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={
                    <div className="fontPoppinsRegularXLg">
                      <span className="text-red-400 mr-1">&#42;</span> Label
                    </div>
                  }
                  className=" w-full "
                  type="text"
                  autoComplete="off"
                  placeholder="Type Here"
                  name="checkboxForm"
                  id="checkboxForm"
                  onBlur={handleTrimmedBlur}
                  onChange={formikDetails.handleChange}
                  value={formikDetails.values.checkboxForm}
                />
                {!formikDetails.values.checkboxForm && checkBoxfieldValidate ? (
                  <p className="errorMessage absolute fontPoppinsRegularSm">{checkBoxfieldValidate}</p>
                ) : null}
                {!alphaNumericPattern.test(formikDetails.values.checkboxForm) && (
                  <>
                    <p className="errorMessage absolute fontPoppinsRegularSm">{checkForAlphanumeric}</p>
                  </>
                )}
              </div>
            </div>
            <div className="grid grid-cols-1">
              <div className="flex justify-between">
                <div className="">
                  {cbOptions.map((c, index) => {
                    return (
                      <div className="mb-7 rs-user-input-container relative">
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label={
                            <div className="fontPoppinsRegularXLg">
                              <span className="text-red-400 mr-1">&#42;</span> Option {index + 1}
                            </div>
                          }
                          key={index}
                          className={'text-gray-700 w-96 '}
                          type="text"
                          placeholder="Type Here"
                          autoComplete="off"
                          name="checkboxFormOptionField"
                          id="checkboxFormOptionField"
                          onBlur={(e) => {
                            addCBOptions(e, index, 'blur');
                          }}
                          onInput={(e) => {
                            addCBOptions(e, index, 'input');
                          }}
                          value={cbOptions[index]}
                        />
                        {cbOptions.length > 1 && (
                          <button
                            type="button"
                            onClick={(e) => {
                              deleteCBOptions(e, index);
                            }}
                          >
                            <CloseIcon className="iconStyle cursor-pointer mx-4 mt-4 text-gray-500" />
                          </button>
                        )}
                        {index + 1 === cbOptions.length && (
                          <button
                            disabled={!cbOptions[cbOptions.length - 1]}
                            type="button"
                            onClick={() => {
                              handleAddCheckboxOption();
                            }}
                          >
                            <AddCircleOutlineIcon
                              className={!cbOptions[cbOptions.length - 1] ? 'opacity-50 mt-4' : 'opacity-100 mt-4'}
                            />
                          </button>
                        )}
                        {checkBoxFieldOptionValidate && !cbOptions[index] ? (
                          <p className="errorMessage absolute fontPoppinsRegularSm">{checkBoxFieldOptionValidate}</p>
                        ) : checkBoxFieldOptionUniqueValidate &&
                          checkBoxFieldOptionDuplicateArray[0]?.includes(index) ? (
                          <p className="errorMessage absolute fontPoppinsRegularSm">
                            {checkBoxFieldOptionUniqueValidate}
                          </p>
                        ) : checkBoxFieldOptionAlphaNumericValidate && !alphaNumericPattern.test(cbOptions[index]) ? (
                          <p className="errorMessage absolute fontPoppinsRegularSm">
                            {checkBoxFieldOptionAlphaNumericValidate}
                          </p>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <span className="text-red-400 mr-1">&#42;</span>
                    <span className="fontPoppinsRegularMd">Mandatory</span>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    value={mandatoryCBValue}
                    onChange={handleMandatoryCBChange}
                    defaultValue="top"
                  >
                    <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>

              <div>
                {isManualTestCaseTemplate && (
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      <span className="fontPoppinsRegularMd">Allow Edit in Execution</span>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      value={allowExecutionForCheckbox}
                      onChange={handelExecutionRadioChangeForCheckbox}
                      defaultValue="top"
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio color="primary" />}
                        label={<div className="fontPoppinsRegularMd">Yes</div>}
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio color="primary" />}
                        label={<div className="fontPoppinsRegularMd">No</div>}
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="gray-btn mr-3"
              type="button"
              onClick={() => {
                closePopUpModal();
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="primary-btn"
              onClick={(e) => {
                handelCheckBoxFormSubmit(e);
              }}
            >
              {props.editFieldValue.label ? <span>Update</span> : <span>Add</span>}
            </button>
          </div>
        </form>
      </>
    );
  };

  const linkForm = () => {
    //link Form code here
    return (
      <>
        <form onSubmit={handelLinkFormSubmit}>
          <div className="modal_body">
            <div className="grid grid-cols-1 gap-4">
              <div className="rs-user-input-container">
                <TextField
                  error={formikDetails.errors.linkForm && formikDetails.touched.linkForm}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={
                    <div className="fontPoppinsRegularXLg">
                      {' '}
                      <span className="text-red-400 mr-1">&#42;</span> Label
                    </div>
                  }
                  className=" w-full "
                  type="text"
                  placeholder="Type here"
                  autoComplete="off"
                  name="linkForm"
                  id="linkForm"
                  onBlur={handleTrimmedBlur}
                  onChange={formikDetails.handleChange}
                  value={formikDetails.values.linkForm}
                />
                {!formikDetails.values.linkForm && linkFieldValidate ? (
                  <p className="errorMessage fixed fontPoppinsRegularSm">{linkFieldValidate}</p>
                ) : null}
                {!alphaNumericPattern.test(formikDetails.values.linkForm) && (
                  <>
                    <p className="errorMessage fixed fontPoppinsRegularSm">{checkForAlphanumeric}</p>
                  </>
                )}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-8">
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <span className="text-red-400 mr-1">&#42;</span>
                    <span className="fontPoppinsRegularMd">Mandatory</span>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    value={mandatoryLFValue}
                    onChange={handleMandatoryLFChange}
                    defaultValue="top"
                  >
                    <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>

              <div>
                {isManualTestCaseTemplate && (
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      <span className="fontPoppinsRegularMd">Allow Edit in Execution</span>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      value={allowExecutionForLinkType}
                      onChange={handelExecutionRadioChangeForLinkType}
                      defaultValue="top"
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio color="primary" />}
                        label={<div className="fontPoppinsRegularMd">Yes</div>}
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio color="primary" />}
                        label={<div className="fontPoppinsRegularMd">No</div>}
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="gray-btn mr-3"
              type="button"
              onClick={() => {
                closePopUpModal();
              }}
            >
              Cancel
            </button>
            <button type="submit" className="primary-btn">
              {props.editFieldValue.label ? <span>Update</span> : <span>Add</span>}
            </button>
          </div>
        </form>
      </>
    );
  };

  const dropdownForm = () => {
    //dropdown Form code here
    const handleAddDropdownOption = () => {
      if (!ddOptions.map((x) => alphaNumericPattern.test(x)).includes(false)) {
        if (!checkDuplicateOptions(ddOptions)) {
          setDDOptions((ddOptions) => [...ddOptions, ddOption]);
          setDDOption('');
          setDropdownFormCounter(dropdownFormCounter + 1);
        } else {
          setDropDownFieldOptionDuplicateArray(getDuplicateOptions(ddOptions));
          setDropDownFieldOptionUniqueValidate('Options should be unique');
        }
      } else {
        setDropDownFieldOptionAlphaNumericValidate('Dropdown options should be alphanumeric');
      }
    };

    const addDDOptions = (e, index, type) => {
      setDropDownFieldOptionValidate('');
      setDropDownFieldOptionUniqueValidate('');
      setDropDownFieldOptionAlphaNumericValidate('');
      setDropDownFieldOptionDuplicateArray([]);
      const ddOptionsCopy = [...ddOptions];
      ddOptionsCopy.forEach((val, i) => {
        if (index === i) {
          type === 'input' ? (ddOptionsCopy[i] = e.target.value) : (ddOptionsCopy[i] = e.target.value.trim());
        }
      });
      setDDOptions(ddOptionsCopy);
    };

    const deleteDDOptions = (e, index) => {
      setDropDownFieldOptionValidate('');
      setDropDownFieldOptionUniqueValidate('');
      setDropDownFieldOptionAlphaNumericValidate('');
      setDropDownFieldOptionDuplicateArray([]);
      const ddOptionsCopy = [...ddOptions];
      index !== -1 && ddOptionsCopy.splice(index, 1);
      setDDOptions(ddOptionsCopy);
    };

    return (
      <>
        <form id="journal-scroll">
          <div className="modal_body" id="journal-scroll">
            <div className="grid grid-cols-1 gap-4 mb-5">
              <div className="rs-user-input-container relative">
                <TextField
                  error={formikDetails.errors.dropDown && formikDetails.touched.dropDown}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={
                    <div className="fontPoppinsRegularXLg">
                      <span className="text-red-400 mr-1">&#42;</span> Label
                    </div>
                  }
                  className=" w-full "
                  type="text"
                  placeholder="Type here"
                  autoComplete="off"
                  name="dropDown"
                  id="dropDown"
                  onBlur={handleTrimmedBlur}
                  onChange={formikDetails.handleChange}
                  value={formikDetails.values.dropDown}
                />
                {!formikDetails.values.dropDown && dropDownFieldValidate ? (
                  <p className="errorMessage absolute fontPoppinsRegularSm">{dropDownFieldValidate}</p>
                ) : null}
                {!alphaNumericPattern.test(formikDetails.values.dropDown) && (
                  <>
                    {' '}
                    <p className="errorMessage absolute fontPoppinsRegularSm">{checkForAlphanumeric}</p>
                  </>
                )}
              </div>
            </div>
            <div className="grid grid-cols-1">
              <div className="flex justify-between">
                <div className="">
                  {ddOptions.map((c, index) => {
                    return (
                      <div className=" mb-7 rs-user-input-container relative">
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label={
                            <div className="fontPoppinsRegularXLg">
                              <span className="text-red-400 mr-1">&#42;</span> Option {index + 1}
                            </div>
                          }
                          key={index}
                          className={'text-gray-700 w-96 '}
                          type="text"
                          autoComplete="off"
                          placeholder="Type here"
                          name="dropdownFormOptionField"
                          id="dropdownFormOptionField"
                          onBlur={(e) => {
                            addDDOptions(e, index, 'blur');
                          }}
                          onInput={(e) => {
                            addDDOptions(e, index, 'input');
                          }}
                          value={ddOptions[index]}
                        />
                        {ddOptions.length > 1 && (
                          <button
                            type="button"
                            onClick={(e) => {
                              deleteDDOptions(e, index);
                            }}
                          >
                            <CloseIcon className="iconStyle cursor-pointer mx-4 mt-3 text-gray-500" />
                          </button>
                        )}
                        {index + 1 === ddOptions.length && (
                          <button
                            disabled={!ddOptions[ddOptions.length - 1]}
                            type="button"
                            onClick={() => {
                              handleAddDropdownOption();
                            }}
                          >
                            <AddCircleOutlineIcon
                              className={!ddOptions[ddOptions.length - 1] ? 'opacity-50 mt-4' : 'opacity-100 mt-4'}
                            />
                          </button>
                        )}
                        {dropDownFieldOptionValidate && !ddOptions[index] ? (
                          <p className="errorMessage absolute fontPoppinsRegularSm">{dropDownFieldOptionValidate}</p>
                        ) : dropDownFieldOptionUniqueValidate &&
                          dropDownFieldOptionDuplicateArray[0]?.includes(index) ? (
                          <p className="errorMessage absolute fontPoppinsRegularSm">
                            {dropDownFieldOptionUniqueValidate}
                          </p>
                        ) : dropDownFieldOptionAlphaNumericValidate && !alphaNumericPattern.test(ddOptions[index]) ? (
                          <p className="errorMessage absolute fontPoppinsRegularSm">
                            {dropDownFieldOptionAlphaNumericValidate}
                          </p>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {' '}
                    <span className="text-red-400 mr-1">&#42;</span>
                    <span className="fontPoppinsRegularMd">Mandatory</span>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    value={mandatoryDDValue}
                    onChange={handleMandatoryDDChange}
                    defaultValue="top"
                  >
                    <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>

              {!['Status', 'Severity', 'Priority'].includes(props.editFieldValue?.label) &&
                isManualTestCaseTemplate && (
                  <div>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        <span className="fontPoppinsRegularMd">Allow Edit in Execution</span>
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        value={allowExecutionForDropDown}
                        onChange={handelExecutionRadioChangeForDropDown}
                        defaultValue="top"
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio color="primary" />}
                          label={<div className="fontPoppinsRegularMd">Yes</div>}
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio color="primary" />}
                          label={<div className="fontPoppinsRegularMd">No</div>}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="gray-btn mr-3"
              type="button"
              onClick={() => {
                closePopUpModal();
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="primary-btn"
              onClick={(e) => {
                handelDropdownFormSubmit(e);
              }}
            >
              {props.editFieldValue.label ? <span>Update</span> : <span>Add</span>}
            </button>
          </div>
        </form>
      </>
    );
  };

  const dateForm = () => {
    //date form code here
    return (
      <>
        <form onSubmit={handelDateFormSubmit}>
          <div className="modal_body">
            <div className="grid grid-cols-1">
              <div className="rs-user-input-container relative">
                <TextField
                  error={formikDetails.errors.dateForm && formikDetails.touched.dateForm}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={
                    <div className="fontPoppinsRegularXLg">
                      <span className="text-red-400 mr-1">&#42;</span> Label
                    </div>
                  }
                  size="40"
                  placeholder="Type here"
                  className=" w-full "
                  type="text"
                  autoComplete="off"
                  name="dateForm"
                  id="dateForm"
                  onBlur={handleTrimmedBlur}
                  onChange={formikDetails.handleChange}
                  value={formikDetails.values.dateForm}
                />
                {!formikDetails.values.dateForm && dateFieldValidate ? (
                  <p className="errorMessage absolute fontPoppinsRegularSm">{dateFieldValidate}</p>
                ) : null}
                {!alphaNumericPattern.test(formikDetails.values.dateForm) && (
                  <>
                    <p className="errorMessage absolute fontPoppinsRegularSm">{checkForAlphanumeric}</p>
                  </>
                )}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-6">
              <div>
                <FormControl>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Date Format
                  </InputLabel>
                  <Select
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={formikDetails.values.dateFormat}
                    name="dateFormat"
                    id="dateFormat"
                    onChange={formikDetails.handleChange}
                    onBlur={formikDetails.handleBlur}
                    value={formikDetails.values.dateFormat}
                    className=" lg:w-64 sm:w-40 text-gray-500 fontPoppinsRegularMd"
                  >
                    <option
                      className="hover:text-blue-700 cursor-pointer hover:bg-blue-100 fontPoppinsRegularMd"
                      value="dd-mm-yyyy"
                    >
                      dd-mm-yyyy
                    </option>
                    <option
                      className="hover:text-blue-700 cursor-pointer hover:bg-blue-100 fontPoppinsRegularMd"
                      value="mm-yyyy-dd"
                    >
                      {' '}
                      mm-yyyy-dd
                    </option>
                    <option
                      className="hover:text-blue-700 cursor-pointer hover:bg-blue-100 fontPoppinsRegularMd"
                      value="yyyy-dd-mm"
                    >
                      yyyy-dd-mm
                    </option>
                  </Select>
                </FormControl>
              </div>

              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {' '}
                    <span className="text-red-400 mr-1">&#42;</span>
                    <span className="fontPoppinsRegularMd">Mandatory</span>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    value={mandatoryDFValue}
                    onChange={handleMandatoryDFChange}
                    defaultValue="top"
                  >
                    <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />

                    <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                  </RadioGroup>
                </FormControl>

                <div>
                  {isManualTestCaseTemplate && (
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        <span className="fontPoppinsRegularMd">Allow Edit in Execution</span>
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        value={allowExecutionForDate}
                        onChange={handelExecutionRadioChangeForDate}
                        defaultValue="top"
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio color="primary" />}
                          label={<div className="fontPoppinsRegularMd">Yes</div>}
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio color="primary" />}
                          label={<div className="fontPoppinsRegularMd">No</div>}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="gray-btn mr-3"
              type="button"
              onClick={() => {
                closePopUpModal();
              }}
            >
              Cancel
            </button>
            <button type="submit" className="primary-btn">
              {props.editFieldValue.label ? <span>Update</span> : <span>Add</span>}
            </button>
          </div>
        </form>
      </>
    );
  };

  const attachmentForm = () => {
    const Input = styled('input')({
      display: 'none',
    });
    //attachment form code here
    return (
      <>
        <form onSubmit={handelAttachmentFormSubmit}>
          <div className="modal_body">
            <div className="grid grid-cols-1 gap-4">
              <div className="rs-user-input-container rs-rs-user-input-container">
                <TextField
                  error={formikDetails.errors.attachmentFormLabel && formikDetails.touched.attachmentFormLabel}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={
                    <div className="fontPoppinsRegularXLg">
                      {' '}
                      <span className="text-red-400 mr-1 fontPoppinsRegularMd">&#42;</span> Label
                    </div>
                  }
                  size="40"
                  className="w-60 text_width_100"
                  type="text"
                  autoComplete="off"
                  name="attachmentFormLabel"
                  id="attachmentFormLabel"
                  placeholder="Type here"
                  onBlur={handleTrimmedBlur}
                  onChange={formikDetails.handleChange}
                  value={formikDetails.values.attachmentFormLabel}
                />
                {!formikDetails.values.attachmentFormLabel && attachmentFieldValidate ? (
                  <p className="errorMessage fontPoppinsRegularSm">{attachmentFieldValidate}</p>
                ) : null}
                {!alphaNumericPattern.test(formikDetails.values.attachmentFormLabel) && (
                  <>
                    <p className="errorMessage fontPoppinsRegularSm">{checkForAlphanumeric}</p>
                  </>
                )}
              </div>
            </div>
            {dmode && (
              <div className="grid grid-cols-1 gap-4 mt-5">
                <div className="flex flex-col text-xs attachmentinfoFontColor">
                  <span className="my-2.5"> Maximum file(s) can be uploaded: 5 </span>
                  <span className="my-2.5">Maximum file(s) size: 30 MB</span>
                </div>
              </div>
            )}
            <div className="grid grid-cols-2 gap-4 mt-8">
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {' '}
                    <span className="text-red-400 mr-1">&#42;</span>
                    <span className="fontPoppinsRegularMd">Mandatory</span>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    value={mandatoryAFValue}
                    onChange={handleMandatoryAFChange}
                    defaultValue="top"
                  >
                    <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>

              <div>
                {isManualTestCaseTemplate && (
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      <span className="fontPoppinsRegularMd">Allow Edit in Execution</span>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      value={allowExecutionForAttach}
                      onChange={handelExecutionRadioChangeForAttach}
                      defaultValue="top"
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio color="primary" />}
                        label={<div className="fontPoppinsRegularMd">Yes</div>}
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio color="primary" />}
                        label={<div className="fontPoppinsRegularMd">No</div>}
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="gray-btn mr-3"
              type="button"
              onClick={() => {
                closePopUpModal();
              }}
            >
              Cancel
            </button>
            <button type="submit" className="primary-btn">
              {props.editFieldValue.label ? <span>Update</span> : <span>Add</span>}
            </button>
          </div>
        </form>
      </>
    );
  };

  const richTextEditorForm = () => {
    const Input = styled('input')({
      display: 'none',
    });
    //richTextEditor form code here
    return (
      <>
        <form onSubmit={handelRichTextEditorFormSubmit}>
          <div className="modal_body">
            <div className="grid grid-cols-1 gap-4">
              <div className="rs-user-input-container">
                <TextField
                  error={formikDetails.errors.richTextEditorFormLabel && formikDetails.touched.richTextEditorFormLabel}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={
                    <div className="fontPoppinsRegularXLg">
                      {' '}
                      <span className="text-red-400 mr-1">&#42;</span> Label
                    </div>
                  }
                  size="40"
                  className=" w-full "
                  type="text"
                  autoComplete="off"
                  name="richTextEditorFormLabel"
                  id="richTextEditorFormLabel"
                  placeholder="Type here"
                  onBlur={handleTrimmedBlur}
                  onChange={formikDetails.handleChange}
                  value={formikDetails.values.richTextEditorFormLabel}
                />
                {!formikDetails.values.richTextEditorFormLabel && attachmentFieldValidate ? (
                  <p className="errorMessage fixed fontPoppinsRegularSm">{attachmentFieldValidate}</p>
                ) : null}
                {!alphaNumericPattern.test(formikDetails.values.richTextEditorFormLabel) && (
                  <>
                    <p className="errorMessage fixed fontPoppinsRegularSm">{checkForAlphanumeric}</p>
                  </>
                )}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {' '}
                    <span className="text-red-400 mr-1">&#42;</span>
                    <span className="fontPoppinsRegularMd">Mandatory</span>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    value={mandatoryTextEditorValue}
                    onChange={handleMandatorytextEditorChange}
                    defaultValue="top"
                  >
                    <FormControlLabel
                      classes="fontPoppinsRegularMd"
                      value="yes"
                      control={<Radio color="primary" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      classes="fontPoppinsRegularMd"
                      value="no"
                      control={<Radio color="primary" />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <div>
                {isManualTestCaseTemplate && (
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      <span className="fontPoppinsRegularMd">Allow Edit in Execution</span>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      value={allowExecutionForTextEditor}
                      onChange={handelExecutionRadioChangeForTextEditor}
                      defaultValue="top"
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio color="primary" />}
                        label={<div className="fontPoppinsRegularMd">Yes</div>}
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio color="primary" />}
                        label={<div className="fontPoppinsRegularMd">No</div>}
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="gray-btn mr-3"
              type="button"
              onClick={() => {
                closePopUpModal();
              }}
            >
              Cancel
            </button>
            <button type="submit" className="primary-btn">
              {props.editFieldValue.label ? <span>Update</span> : <span>Add</span>}
            </button>
          </div>
        </form>
      </>
    );
  };

  const onSelectCustomField = (e, selectVal = '') => {
    if (!!e) e.preventDefault();
    let selectedCValue = selectVal || e?.target?.value;
    props.selectedCF(selectedCValue);
    switch (selectedCValue) {
      case 'textField':
        setSelectedCustomValue('textField');
        setInitialModalStyle('modal_dialog_TC');
        setDisabledDropDown(true);
        break;

      case 'textArea':
        setSelectedCustomValue('textArea');
        setInitialModalStyle('modal_dialog_TC');
        setDisabledDropDown(true);
        break;

      case 'radioButton':
        setSelectedCustomValue('radioButton');
        setInitialModalStyle('modal_dialog_md');
        setDisabledDropDown(true);
        break;

      case 'link':
        setSelectedCustomValue('link');
        setInitialModalStyle('modal_dialog_sm');
        setDisabledDropDown(true);
        break;

      case 'dropdown':
        setSelectedCustomValue('dropdown');
        setInitialModalStyle('modal_dialog_md');
        setDisabledDropDown(true);
        break;

      case 'date':
        setSelectedCustomValue('date');
        setInitialModalStyle('modal_dialog_sm');
        setDisabledDropDown(true);
        break;

      case 'checkbox':
        setSelectedCustomValue('checkbox');
        setInitialModalStyle('modal_dialog_md');
        setDisabledDropDown(true);
        break;

      case 'attachment':
        setSelectedCustomValue('attachment');
        dmode ? setInitialModalStyle('modal_dialog_md') : setInitialModalStyle('modal_dialog_sm');
        setDisabledDropDown(true);
        break;

      case 'textEditor':
        setSelectedCustomValue('textEditor');
        setInitialModalStyle('modal_dialog_sm');
        setDisabledDropDown(true);
        break;
    }
  };

  let pattern = /^(?! ).*[^ ]$/;
  let alphaNumericPattern = /^[-_A-Za-z0-9 ]*$|^[-_A-Za-z0-9 ][A-Za-z0-9 ]*[-_A-Za-z-_]$/;

  const haveSameData = function (obj1, obj2) {
    const obj1Length = Object.keys(obj1).length;
    const obj2Length = Object.keys(obj2).length;
    if (obj1Length === obj2Length) {
      return Object.keys(obj1).every((key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]);
    }
    return false;
  };

  const toReturnTruncateTxt = (text) => {
    return text.length > 15 ? text.substring(0, 15) + '...' : text;
  };

  const duplicateLabel = (currentFormLabel) => {
    if (props.source === 'tcm') {
      return props.data.testCaseDetails.some(
        (labelData) => labelData.label.toLowerCase() === currentFormLabel.toLowerCase()
      );
    } else if (props.source === 'defect') {
      return props.data.defectDetails.some(
        (labelData) => labelData.label.toLowerCase() === currentFormLabel.toLowerCase()
      );
    }
  };

  const isRestrictedLabelName = (labelName) => {
    return ['module path', 'type'].includes(labelName?.toLowerCase());
  };

  const handelTextFieldFormSubmit = (e) => {
    e.preventDefault();
    const textFieldData = {
      label: formikDetails.values.fieldName,
      placeholder: formikDetails.values.placeholder,
      maxLength: formikDetails.values.maxLength,
      minLength: formikDetails.values.minLength,
      defaultValue: formikDetails.values.defaultValueTF,
      enable: enableTFValue,
      order: props.editFieldValue['order'],
      mandatory: mandatoryTFValue,
      type: 'textField',
      editInExecution: allowExecutionForTextfield,
      allowEditInExecution: !['Test Case Name', 'Requirement Id', 'Pre Conditions'].includes(
        formikDetails.values.fieldName
      ),
    };

    if (isRestrictedLabelName(textFieldData.label)) {
      MyAlert.warning(`${textFieldData.label} name can not be used`);
      return;
    }

    if (isRestrictedLabelName(textFieldData.label)) {
      MyAlert.warning(`${textFieldData.label} name can not be used`);
      return;
    }
    if (
      !pattern.test(formikDetails.values.fieldName) &&
      !formikDetails.values.minLength &&
      !formikDetails.values.maxLength
    ) {
      formikDetails.setFieldError('fieldName', 'Label is required');
      settextFieldValidate('Label is required');
      setMinEmptyValueCheck('Min value is required');
      setMaxEmptyValueCheck('Max value is required');
    }
    if (haveSameData(props.editFieldValue, textFieldData)) {
      MyAlert.info(`No changes were done to the ${toReturnTruncateTxt(textFieldData.label)} field`);
      return;
    } else if (duplicateLabel(textFieldData.label) && props.editFieldValue.label !== textFieldData.label) {
      MyAlert.warning(`Label already exists`);
      return;
    } else {
      if (typeof formikDetails.values.maxLength === 'string') {
        setMaxEmptyValueCheck('Max value is required');
      }
      if (
        Number(formikDetails.values.maxLength) === 0 ||
        Number(formikDetails.values.maxLength) < Number(formikDetails.values.minLength)
      ) {
        setTextFieldMaxValueValidate(' Max length should be greater than Min length');
      } else if (!pattern.test(formikDetails.values.fieldName)) {
        settextFieldValidate('Label is required');
      } else if (!alphaNumericPattern.test(formikDetails.values.fieldName)) {
        setCheckForAlphanumeric('Label should be alphanumeric');
      } else if (!alphaNumericPattern.test(formikDetails.values.placeholder)) {
        setCheckForAlphanumericPlaceholderTF('Placeholder should be alphanumeric');
      } else if (
        !(typeof formikDetails.values.minLength === 'number') &&
        (!formikDetails.values.minLength ||
          (formikDetails.values.maxLength && formikDetails.values.defaultValueTF && !formikDetails.values.minLength))
      ) {
        setMinEmptyValueCheck('Min value is required');
      } else if (
        formikDetails.values.defaultValueTF &&
        formikDetails.values.maxLength < formikDetails.values.defaultValueTF.length
      ) {
        setCheckLengthValidationDefaultTF(
          `Default value cannot be more than ${formikDetails.values.maxLength} characters`
        );
      } else if (
        formikDetails.values.defaultValueTF &&
        formikDetails.values.minLength > formikDetails.values.defaultValueTF.length
      ) {
        setCheckMinLengthValidationDefaultTF(
          `Default value cannot be less than ${formikDetails.values.minLength} characters`
        );
      } else if (!alphaNumericPattern.test(formikDetails.values.defaultValueTF)) {
        setCheckForAlphanumericDefaultTF('Default value should be alphanumeric');
      } else if (pattern.test(formikDetails.values.fieldName)) {
        props.addFormField(textFieldData);
        props.closeModal(true);
        setOpenModal(true);
      }
    }
  };

  const handelTextAreadFormSubmit = (e) => {
    e.preventDefault();
    const textAreadData = {
      label: formikDetails.values.textAreaFormLabel,
      placeholder: formikDetails.values.placeholderTextArea,
      maxLength: formikDetails.values.maxLengthTextArea,
      minLength: formikDetails.values.minLengthTextArea,
      defaultValue: formikDetails.values.defaultValueTA,
      enable: enableTAValue,
      mandatory: mandatoryTAValue,
      order: props.editFieldValue['order'],
      type: 'textArea',
      editInExecution: allowExecutionForTextarea,
      allowEditInExecution: !['Description'].includes(formikDetails.values.textAreaFormLabel),
    };
    if (isRestrictedLabelName(textAreadData.label)) {
      MyAlert.warning(`${textAreadData.label} name can not be used`);
      return;
    }
    if (isRestrictedLabelName(textAreadData.label)) {
      MyAlert.warning(`${textAreadData.label} name can not be used`);
      return;
    }
    if (haveSameData(props.editFieldValue, textAreadData)) {
      MyAlert.info(`No changes were done to the ${toReturnTruncateTxt(textAreadData.label)} field`);
      return;
    } else if (duplicateLabel(textAreadData.label) && props.editFieldValue.label !== textAreadData.label) {
      MyAlert.warning(`Label already exists`);
      return;
    } else {
      if (Number(formikDetails.values.maxLengthTextArea) < Number(formikDetails.values.minLengthTextArea)) {
        setTextAreadMaxValueValidate(' Max length should be greater than Min length');
      } else if (!pattern.test(formikDetails.values.textAreaFormLabel)) {
        setTextAreaValidate('Label is required');
      } else if (!alphaNumericPattern.test(formikDetails.values.textAreaFormLabel)) {
        setCheckForAlphanumeric('Label should be alphanumeric');
      } else if (!alphaNumericPattern.test(formikDetails.values.placeholderTextArea)) {
        setCheckForAlphanumericPlaceholderTA('Placeholder should be alphanumeric');
      } else if (
        (formikDetails.values.maxLengthTextArea &&
          formikDetails.values.defaultValueTA &&
          formikDetails.values.maxLengthTextArea < formikDetails.values.defaultValueTA.length) ||
        (formikDetails.values.maxLengthTextArea &&
          formikDetails.values.defaultValueTA &&
          formikDetails.values.maxLengthTextArea < formikDetails.values.defaultValueTA.length &&
          !formikDetails.values.minLengthTextArea)
      ) {
        setCheckLengthValidationDefaultTA(
          `Default value cannot be more than ${formikDetails.values.maxLengthTextArea} characters`
        );
      } else if (
        formikDetails.values.minLengthTextArea &&
        formikDetails.values.defaultValueTA &&
        formikDetails.values.minLengthTextArea > formikDetails.values.defaultValueTA.length
      ) {
        setCheckMinLengthValidationDefaultTA(
          `Default value cannot be less than ${formikDetails.values.minLengthTextArea} characters`
        );
      } else if (!alphaNumericPattern.test(formikDetails.values.defaultValueTA)) {
        setCheckForAlphanumericDefaultTA('Default value should be alphanumeric');
      } else if (pattern.test(formikDetails.values.textAreaFormLabel)) {
        props.addFormField(textAreadData);
        props.closeModal(true);
        setOpenModal(true);
      }
    }
  };

  const handelRadioFormSubmit = (e) => {
    if (isRestrictedLabelName(formikDetails.values.radiobuttonForm)) {
      MyAlert.warning(`${formikDetails.values.radiobuttonForm} name can not be used`);
      return;
    }
    if (!pattern.test(formikDetails.values.radiobuttonForm) && radioOptions.includes('')) {
      setRadioFieldValidate('Label is required');
      setRadioFieldOptionValidate('Option is required');
    }
    if (!pattern.test(formikDetails.values.radiobuttonForm)) {
      setRadioFieldValidate('Label is required');
    } else if (!checkDuplicateOptions(radioOptions)) {
      const RadioFormData = {
        label: formikDetails.values.radiobuttonForm,
        options: radioOptions,
        mandatory: mandatoryRBValue,
        type: 'radioButton',
        order: props.editFieldValue['order'],
        editInExecution: allowExecutionForRadioType,
        allowEditInExecution: true,
      };
      e.preventDefault();
      if (haveSameData(props.editFieldValue, RadioFormData)) {
        MyAlert.info(`No changes were done to the ${toReturnTruncateTxt(RadioFormData.label)} field`);
        return;
      } else if (duplicateLabel(RadioFormData.label) && props.editFieldValue.label !== RadioFormData.label) {
        MyAlert.warning(`Label already exists`);
        return;
      } else {
        if (radioOptions.length === 1 && !radioOptions.includes('')) {
          setRadioFieldOptionValidate('Minimum two options are required');
        } else if (radioOptions.includes('')) {
          setRadioFieldOptionValidate('Option is required');
        } else if (!alphaNumericPattern.test(formikDetails.values.radiobuttonForm)) {
          setCheckForAlphanumeric('Label should be alphanumeric');
        } else if (radioOptions.map((x) => alphaNumericPattern.test(x)).includes(false)) {
          setRadioFieldOptionAlphaNumericValidate('Radio options should be alphanumeric');
        } else if (pattern.test(formikDetails.values.radiobuttonForm)) {
          setRadioFieldOptionValidate('');
          setRadioFieldValidate('');
          setRadioFieldOptionUniqueValidate('');
          setRadioFieldOptionDuplicateArray([]);
          setRadioFieldOptionAlphaNumericValidate('');
          props.addFormField(RadioFormData);
          props.closeModal(true);
          setOpenModal(true);
        }
      }
    } else {
      setRadioFieldOptionDuplicateArray(getDuplicateOptions(radioOptions));
      setRadioFieldOptionUniqueValidate('Options should be unique');
    }
  };

  const handelLinkFormSubmit = (e) => {
    if (isRestrictedLabelName(formikDetails.values.linkForm)) {
      MyAlert.warning(`${formikDetails.values.linkForm} name can not be used`);
      return;
    }
    e.preventDefault();
    const linkFormData = {
      label: formikDetails.values.linkForm,
      mandatory: mandatoryLFValue,
      type: 'link',
      order: props.editFieldValue['order'],
      editInExecution: allowExecutionForLinkType,
      allowEditInExecution: true,
    };

    if (haveSameData(props.editFieldValue, linkFormData)) {
      MyAlert.info(`No changes were done to the ${toReturnTruncateTxt(linkFormData.label)} field`);
      return;
    } else if (duplicateLabel(linkFormData.label) && props.editFieldValue.label !== linkFormData.label) {
      MyAlert.warning(`Label already exists`);
      return;
    } else {
      if (!pattern.test(formikDetails.values.linkForm)) {
        setLinkFieldValidate('Label is required');
      } else if (!alphaNumericPattern.test(formikDetails.values.linkForm)) {
        setCheckForAlphanumeric('Label should be alphanumeric');
      } else if (pattern.test(formikDetails.values.linkForm)) {
        props.addFormField(linkFormData);
        props.closeModal(true);
        setOpenModal(true);
      }
    }
  };

  const handelAttachmentFormSubmit = (e) => {
    const attachmentLabelData = {
      label: formikDetails.values.attachmentFormLabel,
      mandatory: mandatoryAFValue,
      type: 'attachment',
      order: props.editFieldValue['order'],
      editInExecution: allowExecutionForAttach,
      allowEditInExecution: true,
    };
    e.preventDefault();
    if (isRestrictedLabelName(attachmentLabelData.label)) {
      MyAlert.warning(`${attachmentLabelData.label} name can not be used`);
      return;
    }
    if (haveSameData(props.editFieldValue, attachmentLabelData)) {
      MyAlert.info(`No changes were done to the ${toReturnTruncateTxt(attachmentLabelData.label)} field`);
      return;
    } else if (duplicateLabel(attachmentLabelData.label) && props.editFieldValue.label !== attachmentLabelData.label) {
      MyAlert.warning(`Label already exists`);
      return;
    } else {
      if (!pattern.test(formikDetails.values.attachmentFormLabel)) {
        setAttachmentFieldValidate('Label is required');
      } else if (!alphaNumericPattern.test(formikDetails.values.attachmentFormLabel)) {
        setCheckForAlphanumeric('Label should be alphanumeric');
      } else if (pattern.test(formikDetails.values.attachmentFormLabel)) {
        props.addFormField(attachmentLabelData);
        props.closeModal(true);
        setOpenModal(true);
      }
    }
  };

  const handelRichTextEditorFormSubmit = (e) => {
    const richTextEditorFormLabelData = {
      label: formikDetails.values.richTextEditorFormLabel,
      mandatory: mandatoryTextEditorValue,
      type: 'textEditor',
      order: props.editFieldValue['order'],
      editInExecution: allowExecutionForTextEditor,
      allowEditInExecution: true,
    };
    e.preventDefault();
    if (isRestrictedLabelName(richTextEditorFormLabelData.label)) {
      MyAlert.warning(`${richTextEditorFormLabelData.label} name can not be used`);
      return;
    }
    if (haveSameData(props.editFieldValue, richTextEditorFormLabelData)) {
      MyAlert.info(`No changes were done to the ${toReturnTruncateTxt(richTextEditorFormLabelData.label)} field`);
      return;
    } else if (
      duplicateLabel(richTextEditorFormLabelData.label) &&
      props.editFieldValue.label !== richTextEditorFormLabelData.label
    ) {
      MyAlert.warning(`Label already exists`);
      return;
    } else {
      if (!pattern.test(formikDetails.values.richTextEditorFormLabel)) {
        setAttachmentFieldValidate('Label is required');
      } else if (!alphaNumericPattern.test(formikDetails.values.richTextEditorFormLabel)) {
        setCheckForAlphanumeric('Label should be alphanumeric');
      } else if (pattern.test(formikDetails.values.richTextEditorFormLabel)) {
        props.addFormField(richTextEditorFormLabelData);
        props.closeModal(true);
        setOpenModal(true);
      }
    }
  };

  const handelCheckBoxFormSubmit = (e) => {
    if (isRestrictedLabelName(formikDetails.values.checkboxForm)) {
      MyAlert.warning(`${formikDetails.values.checkboxForm} name can not be used`);

      return;
    }
    if (!pattern.test(formikDetails.values.checkboxForm) && cbOptions.includes('')) {
      setCheckBoxfieldValidate('Label is required');
      setCheckBoxFieldOptionValidate('Option is required');
    }

    if (!pattern.test(formikDetails.values.checkboxForm)) {
      setCheckBoxfieldValidate('Label is required');
    } else if (!checkDuplicateOptions(cbOptions)) {
      const checkboxFormData = {
        label: formikDetails.values.checkboxForm,
        options: cbOptions,
        mandatory: mandatoryCBValue,
        type: 'checkbox',
        order: props.editFieldValue['order'],
        editInExecution: allowExecutionForCheckbox,
        allowEditInExecution: true,
      };
      e.preventDefault();
      if (haveSameData(props.editFieldValue, checkboxFormData)) {
        MyAlert.info(`No changes were done to the ${toReturnTruncateTxt(checkboxFormData.label)} field`);
        return;
      } else if (duplicateLabel(checkboxFormData.label) && props.editFieldValue.label !== checkboxFormData.label) {
        MyAlert.warning(`Label already exists`);
        return;
      } else {
        if (cbOptions.includes('')) {
          setCheckBoxFieldOptionValidate('Option is required');
        } else if (!alphaNumericPattern.test(formikDetails.values.checkboxForm)) {
          setCheckForAlphanumeric('Label should be alphanumeric');
        } else if (cbOptions.map((x) => alphaNumericPattern.test(x)).includes(false)) {
          setCheckBoxFieldOptionAlphaNumericValidate('Checkbox options should be alphanumeric');
        } else if (pattern.test(formikDetails.values.checkboxForm)) {
          setCheckBoxFieldOptionValidate('');
          setCheckBoxfieldValidate('');
          setCheckBoxFieldOptionUniqueValidate('');
          setCheckBoxFieldOptionDuplicateArray([]);
          setCheckBoxFieldOptionAlphaNumericValidate('');
          props.addFormField(checkboxFormData);
          props.closeModal(true);
          setOpenModal(true);
        }
      }
    } else {
      setCheckBoxFieldOptionDuplicateArray(getDuplicateOptions(cbOptions));
      setCheckBoxFieldOptionUniqueValidate('Options should be unique');
    }
  };

  const handelDateFormSubmit = (e) => {
    const dateFormData = {
      label: formikDetails.values.dateForm,
      dateFormat: formikDetails.values.dateFormat,
      mandatory: mandatoryDFValue,
      type: 'date',
      order: props.editFieldValue['order'],
      editInExecution: allowExecutionForDate,
      allowEditInExecution: true,
    };
    e.preventDefault();
    if (isRestrictedLabelName(dateFormData.label)) {
      MyAlert.warning(`${dateFormData.label} name can not be used`);
      return;
    }
    if (haveSameData(props.editFieldValue, dateFormData)) {
      MyAlert.info(`No changes were done to the ${toReturnTruncateTxt(dateFormData.label)} field`);
      return;
    } else if (duplicateLabel(dateFormData.label) && props.editFieldValue.label !== dateFormData.label) {
      MyAlert.warning(`Label already exists`);
      return;
    } else {
      if (!pattern.test(formikDetails.values.dateForm)) {
        setDateFieldValidate('Label is required');
      } else if (!alphaNumericPattern.test(formikDetails.values.dateForm)) {
        setCheckForAlphanumeric('Label should be alphanumeric');
      } else if (pattern.test(formikDetails.values.dateForm)) {
        props.addFormField(dateFormData);
        props.closeModal(true);
        setOpenModal(true);
      }
    }
  };

  const handelDropdownFormSubmit = (e) => {
    if (isRestrictedLabelName(formikDetails.values.dropDown)) {
      MyAlert.warning(`${formikDetails.values.dropDown} name can not be used`);
      return;
    }
    if (!pattern.test(formikDetails.values.dropDown) && ddOptions.includes('')) {
      setDropDownFieldValidate('Label is required');
      setDropDownFieldOptionValidate('Option is required');
    }
    if (!pattern.test(formikDetails.values.dropDown)) {
      setDropDownFieldValidate('Label is required');
    } else if (!checkDuplicateOptions(ddOptions)) {
      const dropdownFormData = {
        label: formikDetails.values.dropDown,
        options: ddOptions,
        mandatory: mandatoryDDValue,
        type: 'dropdown',
        order: props.editFieldValue['order'],
        allowEditInExecution: !['Status', 'Severity', 'Priority'].includes(formikDetails.values.dropDown),
        editInExecution: allowExecutionForDropDown,
      };
      e.preventDefault();
      if (haveSameData(props.editFieldValue, dropdownFormData)) {
        MyAlert.info(`No changes were done to the ${toReturnTruncateTxt(dropdownFormData.label)} field`);
        return;
      } else if (duplicateLabel(dropdownFormData.label) && props.editFieldValue.label !== dropdownFormData.label) {
        MyAlert.warning(`Label already exists`);
        return;
      } else {
        if (ddOptions.includes('')) {
          setDropDownFieldOptionValidate('Option is required');
          return;
        } else if (!alphaNumericPattern.test(formikDetails.values.dropDown)) {
          setCheckForAlphanumeric('Label should be alphanumeric');
        } else if (ddOptions.map((x) => alphaNumericPattern.test(x)).includes(false)) {
          setDropDownFieldOptionAlphaNumericValidate('Dropdown options should be alphanumeric');
        } else if (pattern.test(formikDetails.values.dropDown)) {
          setDropDownFieldOptionValidate('');
          setDropDownFieldValidate('');
          setDropDownFieldOptionUniqueValidate('');
          setDropDownFieldOptionAlphaNumericValidate('');
          setDropDownFieldOptionDuplicateArray([]);
          props.addFormField(dropdownFormData);
          props.closeModal(true);
          setOpenModal(true);
        }
      }
    } else {
      setDropDownFieldOptionDuplicateArray(getDuplicateOptions(ddOptions));
      setDropDownFieldOptionUniqueValidate('Options should be unique');
    }
  };

  const validationSchema = yup.object({
    fieldName: yup.string().ensure().required('Label is required'),
  });

  const formikDetails = useFormik({
    validationSchema,
    initialValues,
    handelTextFieldFormSubmit,
    handelTextAreadFormSubmit,
    handelLinkFormSubmit,
    handelAttachmentFormSubmit,
    handelCheckBoxFormSubmit,
    handelDateFormSubmit,
    handelDropdownFormSubmit,
    handelRadioFormSubmit,
    handelRichTextEditorFormSubmit,
    onSelectCustomField,
  });

  return (
    <>
      <Modal open={openModal} className={initialModalStyle}>
        <div className="modal_container browserStack_check_modal">
          <div className="ml-20">
            <div className="left-alert">
              <AlertContatiner></AlertContatiner>
            </div>
          </div>
          <div className="modal_header fontPoppinsSemiboldLg">
            {props.editFieldValue.label ? (
              <h2 className="title"> Edit Field</h2>
            ) : (
              <h2 className="title"> Add Field</h2>
            )}

            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                closePopUpModal();
              }}
            >
              <img className="cursor-pointer" src="/assets/images/close_black.svg" alt="" height="25px" width="25px" />{' '}
            </IconButton>
          </div>
          <div className="modal_body_DropeDown">
            <form className=" border-b-2 border-dashed border-blue-100   mb-2">
              <div className="grid grid-cols-1 mb-5">
                <div htmlFor="ifFailedStatus" className="input-filed-label-style-common mb-3">
                  <span className="text-red-400">&#42;</span>&nbsp;&nbsp;<span>Select Custom Field</span>
                </div>
                <FormControl>
                  <Select
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                    }}
                    error={formikDetails.errors.selectCustomField && formikDetails.touched.selectCustomField}
                    disabled={props.editFieldValue.label}
                    name="selectCustomField"
                    id="selectCustomField"
                    onChange={(e) => {
                      onSelectCustomField(e);
                    }}
                    onBlur={formikDetails.handleBlur}
                    value={selectedCustomValue}
                    className="w-full text-gray-500 fontPoppinsRegularMd"
                  >
                    {selectedCustomFieldArray.map((selectedField) => (
                      <MenuItem
                        className="hover:text-blue-700 cursor-pointer hover:bg-blue-100  test-case-select fontPoppinsRegularMd"
                        value={selectedField.value}
                      >
                        {selectedField.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </form>
          </div>
          <div className=" text-gray-700 ml-7 font-bold fontPoppinsSemiboldLg">
            {selectedCustomFieldArray.map((val) => {
              if (val.value === selectedCustomValue) {
                return val.name;
              }
            })}
          </div>
          {selectedCustomValue === 'textField'
            ? textFieldForm()
            : selectedCustomValue === 'textArea'
              ? textAreaForm()
              : selectedCustomValue === 'radioButton'
                ? radiobuttonForm()
                : selectedCustomValue === 'dropdown'
                  ? dropdownForm()
                  : selectedCustomValue === 'attachment'
                    ? attachmentForm()
                    : selectedCustomValue === 'link'
                      ? linkForm()
                      : selectedCustomValue === 'date'
                        ? dateForm()
                        : selectedCustomValue === 'checkbox'
                          ? checkboxForm()
                          : selectedCustomValue === 'textEditor'
                            ? richTextEditorForm()
                            : null}
        </div>
      </Modal>
    </>
  );
}

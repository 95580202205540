import React, { createContext, useContext, useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography, Box } from '@mui/material';

const CircularProgressContext = createContext();

export const useCircularProgress = (uniqueIdentifier) => {
  const context = useContext(CircularProgressContext);
  if (!context) {
    throw new Error('useCircularProgress must be used within a CircularProgressContainer');
  }
  let initialProgress = 0;
  const storageKey = `progress_${uniqueIdentifier}`;
  const storedProgress = localStorage.getItem(storageKey);
  if (storedProgress) {
    initialProgress = parseInt(storedProgress, 10);
  } else {
    initialProgress = 0;
    localStorage.setItem(storageKey, initialProgress);
  }

  const [progress, setProgress] = useState(initialProgress);

  const startCircularProgress = () => {
    setProgress(1);
  };

  useEffect(() => {
    let timer;
    if (progress !== 0) {
      timer = setTimeout(() => {
        setProgress((prevProgress) => (prevProgress >= 10 ? 0 : prevProgress + 1));
      }, 800);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [progress]);

  useEffect(() => {
    if (progress === 0) {
      localStorage.removeItem(storageKey);
    } else {
      localStorage.setItem(storageKey, progress.toString());
    }
  }, [progress, storageKey]);

  return {
    progress,
    startCircularProgress,
  };
};

export const CircularProgressContainer = ({ children }) => {
  return <CircularProgressContext.Provider value={{}}>{children}</CircularProgressContext.Provider>;
};

export function CircularProgressWithLabel(props) {
  const normalise = (value, MIN, MAX) => {
    return Math.min(Math.max((MAX - value + MIN) * 10, 0), 100);
  };
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        value={normalise(props.value, 1, 10)}
        size="2rem"
        sx={{ scale: '-1 1' }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(11 - props.value)}s`}</Typography>
      </Box>
    </Box>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import styles from '../analytics.module.scss';
import cx from 'classnames';
import ColumnChart from '@src/pages/common/charts/columnChart';
import { colors } from '@src/css_config/colorConstants.js';
import AnalyticTable from './analyticTable';
import dateFormat from 'dateformat';
import { noDataMessage } from '@src/common/ui-constants';
import { getTableData, getTableName } from './util';
import { scrollTop } from '@src/util/common_utils';

const TopFailures = (props) => {
  let {
    topFailuredata,
    scriptResultData,
    openTopFailureRateModal,
    closeResultModal,
    selectedScript,
    selectedScriptName,
    getScriptResultData,
    setSelectedScript,
    setSelectedScriptName,
    suiteMode,
    reqBody,
    callTableData,
  } = props;
  const [initTableCall, setInitTableCall] = useState(true);

  let tScriptsData = {};
  tScriptsData.topfailure = [
    {
      header: 'Failure Rate [1%-25%]',
    },
    {
      header: 'Failure Rate [26%-50%]',
    },
    {
      header: 'Failure Rate [51%-75%]',
    },
    {
      header: 'Failure Rate [76%-100%]',
    },
  ];
  let top_failure_categories = ['1%-25%', '26%-50%', '51%-75%', '76%-100%'];
  let top_failure_seriesdata = [];
  for (let k = 0; k < 4; k++) {
    top_failure_seriesdata.push(topFailuredata?.topTestResults[0]?.topDataItems[k]?.count);
  }
  let getTableHeader = () => {
    let num = 0;
    let headerIndex = 0;
    topFailuredata?.topTestResults[0]?.topDataItems?.forEach((item, index) => {
      if (item.count > num) {
        num = item.count;
        headerIndex = index;
      }
    });
    return headerIndex;
  };
  let [columnChartLabelColor, setColumnChartLabelColor] = useState([
    colors.blue_dark,
    colors.text_gray,
    colors.text_gray,
    colors.text_gray,
  ]);
  let [Failure, setfailure] = useState({
    header: tScriptsData.topfailure[getTableHeader()]?.header,
    data: [],
  });
  let [initTable, setInitTable] = useState({
    from: 0,
    to: 20,
  });

  const topFailTableRef = useRef();
  const [failTableScrollPos, setFailTableScrollPos] = useState(false);
  const [stopScrollApiCall, setStopScrollApiCall] = useState(0); //? to stop scroll api on count of totalScripts

  const getTopFailTable = async (from, to, initTableName) => {
    try {
      if (from === 0 && to === 20) {
        let tableHeader = getTableName(initTableName);
        let tableData = await getTableData(reqBody, tableHeader, from, to);
        setStopScrollApiCall(tableData?.totalScripts);
        setfailure({ ...Failure, data: tableData?.scripts });
      } else {
        if (stopScrollApiCall > initTable.from) {
          let tableHeader = getTableName(initTableName);
          let tableData = await getTableData(reqBody, tableHeader, from, to);
          setfailure({ ...Failure, data: [...Failure.data, ...tableData?.scripts] });
        }
      }
    } catch (error) {
      console.error('TOP FAILURE API FAILED!', error);
    }
  };

  useEffect(() => {
    failurecolumndata(getTableHeader());
    setInitTableCall(false);
  }, []);

  useEffect(() => {
    if (callTableData && initTableCall === false) {
      setInitTable({
        from: 0,
        to: 20,
      });
      setfailure({
        ...Failure,
        data: [],
      });
      scrollTop(topFailTableRef);
      getTopFailTable(0, 20, Failure.header);
    }
  }, [callTableData]);

  useEffect(() => {
    if (callTableData) {
      getTopFailTable(initTable.from, initTable.to, Failure.header);
    }
  }, [Failure.header]);

  useEffect(() => {
    if (failTableScrollPos) {
      setInitTable({
        from: initTable.to,
        to: initTable.to + 10,
      });
    }
  }, [failTableScrollPos]);

  useEffect(() => {
    if (initTable.from !== 0 && initTable.to !== 20 && callTableData === false) {
      getTopFailTable(initTable.from, initTable.to, Failure.header);
    }
  }, [initTable]);

  const failuredata = React.useMemo(() => Failure.data, [Failure.data]);

  async function failurecolumndata(indexs) {
    let columnCount = 4; // no. of column display in TOP FAILURES Chart
    if (indexs < columnCount) {
      setfailure({
        ...Failure,
        header: tScriptsData.topfailure[indexs]?.header,
      });
      setInitTable({
        from: 0,
        to: 20,
      });
      scrollTop(topFailTableRef);
      let activeColor = [colors.text_gray, colors.text_gray, colors.text_gray, colors.text_gray];
      activeColor[indexs] = colors.blue_dark;
      setColumnChartLabelColor(activeColor);
    }
  }

  let failureRateColumn = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'scriptName',
        width: '20%',
        Cell: ({ row, value }) => {
          let { scriptId, executionId, scriptName, clientId, runId } = row.original;
          return (
            <div
              className={`overflow-hidden overflow-ellipsis whitespace-nowrap pr-2 rs-blue ${
                runId ? 'cursor-pointer' : 'cursor-default rs-skipped'
              }`}
              onClick={() => {
                if (runId && suiteMode === 'automation-suite') {
                  getScriptResultData(scriptId, executionId, clientId, runId, 'topFailureRate');
                  setSelectedScript({ scriptId, executionId });
                  setSelectedScriptName(scriptName);
                }
              }}
              title={value}
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Module',
        accessor: 'pathData',
        width: '20%',
        disableGlobalFilter: true,
        Cell: ({ value }) => {
          return (
            <div className="overflow-hidden overflow-ellipsis whitespace-nowrap pr-2" title={value.path}>
              {value.module}
            </div>
          );
        },
      },
      {
        Header: 'Failure Rate',
        accessor: 'percentage',
        width: '20%',
        disableGlobalFilter: true,
        Cell: ({ value }) => {
          return <div className="overflow-hidden overflow-ellipsis whitespace-nowrap pr-2">{value}%</div>;
        },
      },
      {
        Header: 'Last Failure On',
        accessor: 'executedOn',
        width: '20%',
        disableGlobalFilter: true,
        Cell: ({ value }) => {
          return (
            <div className=" overflow-hidden overflow-ellipsis whitespace-nowrap pr-2" title={value}>
              {value}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <section className={styles['topFailuresBlock']}>
      <div className={styles['topFailuresChartBlock']}>
        <div className={styles['failureChartHeader']}>
          <span className={cx('fontPoppinsSemiboldSm uppercase', styles['leftHeaderFailure'])}>Top Failures</span>
          <span className={cx('fontPoppinsRegularXs8px', styles['rightHeaderFailure'])}>
            {noDataMessage.topfailureNote}
          </span>
        </div>
        <ColumnChart
          dataindex={failurecolumndata}
          categories={top_failure_categories}
          seriesdata={top_failure_seriesdata}
          labelColor={columnChartLabelColor}
        />
      </div>
      <div className={styles['topFailureTable']}>
        <AnalyticTable
          setScrollPos={setFailTableScrollPos}
          ref={topFailTableRef}
          color={colors.rs_failed}
          header={Failure.header}
          columns={failureRateColumn}
          data={failuredata ? failuredata : []}
          message={noDataMessage.topfailure}
          scriptResultData={scriptResultData}
          openScriptResultModal={openTopFailureRateModal}
          closeResultModal={closeResultModal}
          selectedScript={selectedScript}
          selectedScriptName={selectedScriptName}
        />
      </div>
    </section>
  );
};

export default TopFailures;

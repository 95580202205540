import { createContext, useEffect, useState } from 'react';
import { getClientsSectionChartData } from '../../../api/api_services';
import {
  clientTypes,
  formatOsChartData,
  getAggregationData,
  getBrowerVersionsData,
  getClientsAccessData,
  getPhoneOsData,
  getStatusesData,
  initialOsChartData,
} from '../utils/clientsSectionUtils';

export const ClientsContext = createContext(null);

function ClientsProvider({ children }) {
  const [selectedClientType, setSelectedClientType] = useState(clientTypes[0]);
  const [apiResponseData, setApiResponseData] = useState(null);
  const [osChartData, setOsChartData] = useState(initialOsChartData[clientTypes[0]]);
  const [browserVersionsData, setBrowserVersionsData] = useState(getBrowerVersionsData(clientTypes[0]));
  const [statusesData, setStatusesData] = useState(getStatusesData());
  const [phoneOsData, setPhoneOsData] = useState(getPhoneOsData());
  const [accessesData, setAccessesData] = useState(getClientsAccessData());

  useEffect(() => {
    getClientsSectionChartData().then((response) => {
      const apiResponseData = response?.data?.responseObject;
      setApiResponseData(apiResponseData);
    });
  }, []);

  useEffect(() => {
    const aggregatedData = getAggregationData(apiResponseData, selectedClientType);
    formatOsChartData(aggregatedData, selectedClientType, setOsChartData);

    const updatedBrowsersAndVersionsData = getBrowerVersionsData(
      selectedClientType,
      aggregatedData?.browserDetails || []
    );
    setBrowserVersionsData(updatedBrowsersAndVersionsData);

    const updatedStatusesData = getStatusesData(aggregatedData?.status || []);
    setStatusesData(updatedStatusesData);

    const updatedPhoneOsData = getPhoneOsData(aggregatedData?.devices || []);
    setPhoneOsData(updatedPhoneOsData);

    const updatedAccessData = getClientsAccessData(aggregatedData?.access || []);
    setAccessesData(updatedAccessData);
  }, [selectedClientType, apiResponseData]);

  return (
    <ClientsContext.Provider
      value={{
        selectedClientType,
        setSelectedClientType,
        osChartData,
        browserVersionsData,
        statusesData,
        phoneOsData,
        accessesData,
      }}
    >
      {children}
    </ClientsContext.Provider>
  );
}

export default ClientsProvider;

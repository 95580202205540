import { useContext, useEffect, useRef, useState } from 'react';
import { CheckCircle } from '@material-ui/icons';
import PropTypes from 'prop-types';
import cx from 'classnames';
import style from '@pages/common/Stepper/stepper.module.scss';
import { Slide } from '@mui/material';
import { ProgressContainerContext } from '@common/ProgressContainer';
import { useHistory } from 'react-router-dom';
import { LicenseWizardContext } from '@src/pages/licenses/components/LicenseProvider';
import { LICENSE_FORM_TYPES } from '@src/util/licenseUtils';

function getStepDetails(steps, activeStep) {
  const firstStep = steps[0];
  const lastStep = steps[steps.length - 1];
  const wasLastStep = activeStep.key === lastStep.key;
  const activeStepIndex = steps.findIndex((step) => step.key === activeStep.key);
  const nextStep = wasLastStep ? null : steps[activeStepIndex + 1];
  const previousStep = firstStep.key === activeStep.key ? null : steps[activeStepIndex - 1];

  return {
    steps,
    activeStep,
    firstStep,
    lastStep,
    nextStep,
    previousStep,
  };
}

function Stepper({
  steps,
  stepForwardValidator,
  handleFinish,
  finishButtonText,
  hideHeader,
  cols,
  onBack,
  isNextDisabled,
  isBackDisabled,
  stepButtonClassName,
  stepNavigatorWrapperClassName,
  MyAlert,
}) {
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const licenseContextData = useContext(LicenseWizardContext);
  const [activeStep, setActiveStepKey] = useState(steps[0]);
  const [completedStepsKeys, setCompletedStepsKeys] = useState([]);
  const stepperWrapperRef = useRef();
  const [invokeFinishHandler, setInvokeFinishHandler] = useState(false);

  async function callHandleFinish() {
    try {
      const response = await handleFinish();
      if (response?.error) {
        MyAlert && MyAlert.danger(response?.message);
      }
    } catch (error) {
      console.error('Failed while executing the last step', error);
    } finally {
      resetProgress();
      setInvokeFinishHandler(false);
    }
  }

  async function handleNextStepNavigation() {
    const stepsDetails = getStepDetails(steps, activeStep);
    const canMoveForward = await stepForwardValidator(stepsDetails);
    resetProgress();
    startOrResumeProgress({ stopAt: 70 });
    localStorage.removeItem('try-again-data');

    stopProgress();
    if (canMoveForward) {
      if (stepsDetails.nextStep) {
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 100,
          onProgressComplete: () => {
            setCompletedStepsKeys([...completedStepsKeys, activeStep.key]);
            setActiveStepKey(stepsDetails.nextStep);
          },
        });
      } else {
        startOrResumeProgress({
          stopAt: 83,
          intervalDuration: 100,
          onProgressComplete: () => {
            setInvokeFinishHandler(true);
          },
        });
      }
    } else {
      resetProgress();
    }
  }

  function handlePreviousStepNavigation() {
    const stepsDetails = getStepDetails(steps, activeStep);
    onBack(stepsDetails);
    setCompletedStepsKeys(completedStepsKeys.filter((stepKey) => stepKey !== stepsDetails.previousStep?.key));
    setActiveStepKey(stepsDetails.previousStep || stepsDetails.firstStep);
  }

  useEffect(() => {
    if (!hideHeader) {
      resetProgress();
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 100,
      });
    }
  }, [hideHeader]);

  useEffect(() => {
    invokeFinishHandler && callHandleFinish();
  }, [invokeFinishHandler]);

  const history = useHistory();
  const isLicensePage = history.location.pathname !== '/signup';
  const handleCancel = () => {
    localStorage.removeItem('try-again-data');
    history.push('/licenses/fireflink-license');
  };
  return (
    <div className={cx('flex w-full flex-col', style['stepper-wrapper'], 'fontPoppinsSemiboldSm')}>
      {!hideHeader && (
        <>
          <div className="flex w-full flex-wrap justify-between">
            {steps.map((step, stepIndex) => {
              const isStepCompleted = completedStepsKeys.includes(step.key);
              return (
                <div
                  className={cx(style['step-button-wrapper'], stepButtonClassName)}
                  style={{
                    flex: `0 0 ${100 / (cols || steps.length)}%`,
                    width: `${100 / (cols || steps.length)}%`,
                  }}
                  key={`steps-label-${step.key}-${stepIndex}`}
                >
                  <span
                    className={cx(style['step-button'], {
                      [style['completed']]: isStepCompleted,
                      [style['active']]: activeStep.key === step.key,
                    })}
                  >
                    <span>{step.label}</span>
                    {isStepCompleted && (
                      <div className="ml-auto">
                        <CheckCircle />
                      </div>
                    )}
                  </span>
                </div>
              );
            })}
          </div>
        </>
      )}

      <div ref={stepperWrapperRef}>
        {steps.map((step, stepIndex) => (
          <Slide
            direction="left"
            in={step.key === activeStep.key}
            key={`step-content-${step.key}-${stepIndex}`}
            mountOnEnter
            unmountOnExit
            exit={false}
            container={stepperWrapperRef.current}
            timeout={500}
          >
            <div className="my-2">{<activeStep.content />}</div>
          </Slide>
        ))}
      </div>
      <div className={cx('my-0', stepNavigatorWrapperClassName)}>
        {activeStep.key !== steps[0].key && (
          <button
            className={cx(style['step-navigator-button'], style['back'], 'fontPoppinsSemiboldSm', {
              'opacity-50': isBackDisabled,
            })}
            onClick={handlePreviousStepNavigation}
            disabled={isBackDisabled}
          >
            Back
          </button>
        )}
        {activeStep.key === steps[0].key && isLicensePage && (
          <button
            className={cx(style['step-navigator-button'], style['back'], 'fontPoppinsSemiboldSm', {
              'opacity-50': isBackDisabled,
            })}
            onClick={handleCancel}
          >
            {'Cancel'}
          </button>
        )}

        <button
          className={cx(
            style['step-navigator-button'],
            !isLicensePage ? style['signup-btn'] : style['next'],
            'fontPoppinsSemiboldSm',
            {
              'opacity-50': isNextDisabled,
            }
          )}
          onClick={handleNextStepNavigation}
          disabled={isNextDisabled}
        >
          {activeStep.key === steps[steps.length - 1].key ? finishButtonText : 'Next'}
        </button>
      </div>
    </div>
  );
}

Stepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      content: PropTypes.func.isRequired,
    })
  ).isRequired,
  stepForwardValidator: PropTypes.func.isRequired, //It must return a boolean
  handleFinish: PropTypes.func.isRequired,
  finishButtonText: PropTypes.string,
  hideHeader: PropTypes.bool,
  cols: PropTypes.number,
  onBack: PropTypes.func,
  isNextDisabled: PropTypes.bool,
  isBackDisabled: PropTypes.bool,
  stepButtonClassName: PropTypes.string,
  stepNavigatorWrapperClassName: PropTypes.string,
};

Stepper.defaultProps = {
  finishButtonText: 'Finish',
  hideHeader: false,
  cols: 0,
  onBack: () => {},
  isNextDisabled: false,
  isBackDisabled: false,
  stepButtonClassName: '',
  stepNavigatorWrapperClassName: '',
};

export default Stepper;

import React from 'react';
import { FaChevronUp } from 'react-icons/fa';
import { PROJECT } from '../../../common/ui-constants';
import { getFormattedDate } from '../result';

const PlatFormDetails = (props) => {
  let platformColumns = [
    { name: 'Browser', class: 'w-1/3' },
    { name: 'Total Script', class: 'w-1/6' },
    { name: 'Total Passed', class: 'w-1/6' },
    { name: 'Total Failed', class: 'w-1/6' },
    { name: 'Total Warning', class: 'w-1/6' },
    { name: 'Total Skipped', class: 'w-1/6' },
    { name: 'Duration', class: 'w-1/5' },
    { name: 'Executed On', class: 'w-1/4' },
  ];
  if (props?.suiteType && props?.suiteType.toLowerCase() === 'manual') {
    platformColumns.splice(6, 1);
  }
  if (props.projectType) {
    if (props.projectType === PROJECT.MOBILE_PROJECT) {
      platformColumns.splice(0, 1, { name: 'Device', class: 'w-1/4' });
    } else if (props.projectType === PROJECT.WEB_AND_MOBILE_PROJECT) {
      platformColumns.splice(0, 1, { name: 'Browser/Device', class: 'w-1/4' });
    }
  }

  return (
    <div className="mt-3.5">
      <table className="mb-3">
        <thead>
          <tr className="table-header h-10 flex w-full px-3 items-center fontPoppinsMediumSm uppercase" key="pf-header">
            {platformColumns.map((col) => (
              <td className={col.class}>
                {' '}
                {col.name}
                {col.name === 'Executed On' && (
                  <FaChevronUp
                    className="platform-chevron float-right my-2 mx-2"
                    onClick={() => {
                      props.moreDetails(false);
                    }}
                  />
                )}
              </td>
            ))}
          </tr>
        </thead>
        <tbody className="flex flex-col overflow-auto table-scroll-height" id="journal-scroll">
          {props.data.map((pf, index) => (
            <tr className="table-body fontPoppinsRegularMd flex px-3" key={`pf${index}`}>
              {/* <td className="w-1/4">{pf.os}</td> */}
              <td className="w-1/3">
                {pf.deviceName ? pf.deviceName : pf.browser.replace(/\d/g, '').replace(/\./g, '')}
              </td>
              {/* <td className="w-1/4">{pf.deviceName ? pf.deviceName : 'N/A'}</td> */}
              <td className="w-1/6">{pf.total}</td>
              <td className="w-1/6">{pf.totalPassed}</td>
              <td className="w-1/6">{pf.totalFailed}</td>
              <td className="w-1/6">{pf.totalWarning}</td>
              <td className="w-1/6">{pf.totalSkipped}</td>
              {(!props?.suiteType || (props?.suiteType && props?.suiteType.toLowerCase() !== 'manual')) && (
                <td className="w-1/5">{pf.executionDurationInHourMinSecFormat}</td>
              )}
              <td className="w-1/4">{getFormattedDate(pf.executedOn, 'full')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PlatFormDetails;

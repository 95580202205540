import React from 'react';
import { FormikConsumer, useFormik } from 'formik';
import * as Yup from 'yup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { FiMaximize2 } from 'react-icons/fi';
import { FaSearch } from 'react-icons/fa';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy } from 'react-table';
import matchSorter from 'match-sorter';
import Pagination from '../../../../common/pagination';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import TagFacesIcon from '@material-ui/icons/TagFaces';
import ChipInput from 'material-ui-chip-input';
import validator from 'validator';
import { getAllEmailGroupListReq } from '../../../../../api/api_services';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
let emailTableArray = [];
let selectedGroupCount = 0;
let tempTableArray = [];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

function EmailRecipients(props) {
  let tableData = JSON.parse(localStorage.getItem('emailTableArray'));
  // let tableData = JSON.parse(localStorage.getItem('emailTableArray'));
  if (!tableData) {
    tableData = [];
  }
  let emailGroupArrayFromLocalStorage = JSON.parse(localStorage.getItem('emailGroupArray'));
  // let tableData = JSON.parse(localStorage.getItem('emailTableArray'));
  if (!emailGroupArrayFromLocalStorage) {
    emailGroupArrayFromLocalStorage = [];
  }
  const [EmailRecipientArray, setEmailRecipientArray] = React.useState([]);

  const classes = useStyles();
  const [chipData, setChipData] = React.useState([]);

  function handleDelete(chipToDelete, index) {
    setChipData((chips) => chips.filter((chip) => chip !== chipToDelete));
  }

  const [showEmailGroup, setShowEmailGroup] = React.useState(false);
  const [emailGroupCopyArray, setEmailGroupCopyArray] = React.useState([]);
  const [emailRecipientTableData, setEmailRecipientTableData] = React.useState(tableData);
  const [selectedEmailGroupArray, setSelectedEmailGroupArray] = React.useState(emailGroupArrayFromLocalStorage);

  function getAllEmailGroup() {
    getAllEmailGroupListReq()
      .then((results) => {
        // setIsLoaded(true);
        selectedGroupCount = 0;
        let response = results.data.responseObject ? results.data.responseObject : [];

        response.map((proVal) => {
          const found = emailRecipientTableData.find((emailObj) => emailObj.groupName === proVal.name);
          if (found) {
            proVal.checked = true;
          }
        });
        let tempArray = [];
        response.map((proVal) => {
          if (proVal.checked) {
            tempArray.push(proVal.name);
            let uniqueChars = [...new Set(tempArray)];
            selectedGroupCount = uniqueChars.length;
          }
        });

        setEmailRecipientArray(response);
        let chiptempArray = [];
        emailRecipientTableData.map((chipData) => {
          if (!chipData.groupName) {
            chiptempArray.push(chipData.emailId);
            console.log(`chiptempArray`, chiptempArray);
          }
        });
        setTimeout(function () {
          setChipData(chiptempArray);
        }, 500);
      })
      .catch((error) => {
        console.log(error);
        // setIsLoaded(true);
        // setError(error);
      });
    return [];
  }

  React.useEffect(() => {
    getAllEmailGroup();
  }, []);

  const formik = useFormik({
    initialValues: {
      emailId: '',
      description: '',
      syncScriptOption: 'No Sync',
    },

    // validationSchema: Yup.object({
    //     groupCount: Yup.string()
    //         .min(2, "Minimum 2 characters")
    //         .max(15, "Maximum 50 characters")
    //         .required("Required!"),
    //     syncScriptOption: Yup.string()
    //         .min(2, "Minimum 2 characters")
    //         .max(15, "Maximum 50 characters")
    //         .required("Required!")
    // }),
  });

  const defaultProps = {
    options: EmailRecipientArray,
    getOptionLabel: (option) => option.name,
  };

  function selectModuleForFilter(e) {
    console.log(`aaaaaaaaaaaaaaaaaa`);
  }

  function getDataForEmailGroup(e) {
    setShowEmailGroup(true);

    let searchValue = e.target.value;
    if (searchValue) {
      let tempSimilarArray = [];
      EmailRecipientArray.map((proVal, index) => {
        let name = proVal.name;
        let similarExist = name.toLowerCase().match(searchValue.toLowerCase());
        if (similarExist) {
          tempSimilarArray.push(proVal);
          setEmailGroupCopyArray(tempSimilarArray);
        } else {
          setEmailGroupCopyArray(tempSimilarArray);
        }
      });
    } else {
      setEmailGroupCopyArray(EmailRecipientArray);
    }
  }

  function selectEmailGroup(event, emailObj) {
    if (event.target.checked) {
      const found = EmailRecipientArray.find((syelement) => syelement.name === emailObj.name);
      found.checked = true;
      let tempArray = selectedEmailGroupArray;
      tempArray.push(emailObj);
      selectedGroupCount = tempArray.length;
      setSelectedEmailGroupArray(tempArray);
      localStorage.setItem('emailGroupArray', JSON.stringify(tempArray));

      createTableObject(tempArray);
    } else {
      const found = EmailRecipientArray.find((syelement) => syelement.name === emailObj.name);
      found.checked = false;
      const removeInd = selectedEmailGroupArray.findIndex((remObj) => remObj.name === emailObj.name);
      if (removeInd > -1) {
        selectedEmailGroupArray.splice(removeInd, 1);
        setSelectedEmailGroupArray(selectedEmailGroupArray);
        selectedGroupCount = selectedEmailGroupArray.length;
        createTableObject(selectedEmailGroupArray);
      }
    }
  }

  function createTableObject(selectedGroupData) {
    emailTableArray = [];
    if (selectedGroupData && selectedGroupData.length) {
      selectedGroupData.map((selData, index) => {
        if (selData && selData.emailUserList) {
          selData.emailUserList.map((userData, index) => {
            userData.groupName = selData.name;
            emailTableArray.push(userData);
          });
        }
      });
      localStorage.setItem('emailTableArray', JSON.stringify(emailTableArray));
      props.getEmailRecipientData(emailTableArray);
    }
  }

  function getValues(e) {
    console.log(`e`, e);
  }

  function handleAddChip(chips) {
    let emailRegx = '^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$';
    if (validator.isEmail(chips)) {
      let tempArray = [...chipData];
      tempArray.push(chips);
      setChipData(tempArray);
    } else {
      alert('Add  Valid Email id');
    }
  }

  function addEmailGroup() {
    tempTableArray = emailRecipientTableData;
    console.log(`chipData`, chipData);
    chipData.map((option, i) => {
      let chipObj = {
        emailId: option,
      };
      tempTableArray.push(chipObj);
    });
    console.log(`tempTableArray`, tempTableArray);
    var dataArr = tempTableArray.map((item) => {
      return [item.emailId, item];
    });
    var maparr = new Map(dataArr);
    var result = [...maparr.values()];
    console.log(`uniqueChars`, result);
    localStorage.setItem('emailTableArray', JSON.stringify(result));
    setEmailRecipientTableData(result);
  }

  function createTableData() {
    if (emailGroupCopyArray.length) {
      setEmailRecipientTableData(emailTableArray);
      setCurrentPage(1);
    }
    let htmlEle = document.getElementById('groupCount');
    if (htmlEle) {
      htmlEle.value = '';
    }
    setShowEmailGroup(false);
  }

  function closeEmailGroup() {
    let htmlEle = document.getElementById('groupCount');
    if (htmlEle) {
      htmlEle.value = '';
    }
    setShowEmailGroup(false);
  }

  function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);

    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);

    return (
      <span>
        Search:{' '}
        <input
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`Type here...`}
          style={{
            fontSize: '1.1rem',
            border: '0',
          }}
        />
      </span>
    );
  }

  // Define a default UI for filtering
  function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search...`}
      />
    );
  }

  // This is a custom filter UI for selecting
  // a unique option from a list
  function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  // This is a custom filter UI that uses a
  // slider to set the filter value between a column's
  // min and max values
  function SliderColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    // Calculate the min and max
    // using the preFilteredRows

    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      preFilteredRows.forEach((row) => {
        min = Math.min(row.values[id], min);
        max = Math.max(row.values[id], max);
      });
      return [min, max];
    }, [id, preFilteredRows]);

    return (
      <>
        <input
          type="range"
          min={min}
          max={max}
          value={filterValue || min}
          onChange={(e) => {
            setFilter(parseInt(e.target.value, 10));
          }}
        />
        <button onClick={() => setFilter(undefined)}>Off</button>
      </>
    );
  }

  // This is a custom UI for our 'between' or number range
  // filter. It uses two number boxes and filters rows to
  // ones that have values between the two
  function NumberRangeColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id } }) {
    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      preFilteredRows.forEach((row) => {
        min = Math.min(row.values[id], min);
        max = Math.max(row.values[id], max);
      });
      return [min, max];
    }, [id, preFilteredRows]);

    return (
      <div
        style={{
          display: 'flex',
        }}
      >
        <input
          value={filterValue[0] || ''}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
          }}
          placeholder={`Min (${min})`}
          style={{
            width: '70px',
            marginRight: '0.5rem',
          }}
        />
        to
        <input
          value={filterValue[1] || ''}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
          }}
          placeholder={`Max (${max})`}
          style={{
            width: '70px',
            marginLeft: '0.5rem',
          }}
        />
      </div>
    );
  }

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val) => !val;

  // Our table component
  function Table({ columns, data }) {
    const filterTypes = React.useMemo(
      () => ({
        // Add a new fuzzyTextFilterFn filter type.
        fuzzyText: fuzzyTextFilterFn,
        // Or, override the default text filter to use
        // "startWith"
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      []
    );

    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      visibleColumns,
      preGlobalFilteredRows,
      setGlobalFilter,
    } = useTable(
      {
        columns,
        data,
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
      },
      useFilters, // useFilters!
      useGlobalFilter, // useGlobalFilter!
      useSortBy
    );
    return (
      <>
        <table class="relative w-full border" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr class="mt-4" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th class="sticky top-0 px-6 py-3 text-current text-sm bg-gray-100 ml-5" {...column.getHeaderProps()}>
                    <span className="flex flex-row">
                      {column.render('Header')}
                      {/* Render the columns filter UI */}
                      <div>
                        <span>
                          {/* {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <AiOutlineArrowDown className="mt-1 ml-1" />
                                                            : <AiOutlineArrowUp className="mt-1 ml-1" />
                                                        : ''} */}
                        </span>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody class="divide-y" {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr className="project-row" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td class="px-4 py-3 text-center" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <br />
      </>
    );
  }

  // We don't want to render all of the rows for this example, so cap
  // it for this use case

  function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue >= filterValue;
    });
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        columns: [],
      },

      {
        Header: 'Email Id',
        accessor: 'emailId',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left ml-3">{value}</div>;
        },
      },

      {
        Header: 'Recipient Name',
        accessor: 'name',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left ml-3">{value ? value : 'NA'}</div>;
        },
      },
      {
        Header: 'Recipients Group',
        accessor: 'groupName',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left ml-3">{value ? value : 'NA'}</div>;
        },
      },
    ],
    []
  );

  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage] = React.useState(2);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const [operation, setOperation] = React.useState('Create');
  let currentPosts;
  if (emailRecipientTableData) {
    currentPosts = emailRecipientTableData.slice(indexOfFirstPost, indexOfLastPost);
  }
  const [showModal, setShowModal] = React.useState(false);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  function setImmediatePrevPage() {
    let prevPageNo = currentPage - 1;
    setCurrentPage(prevPageNo);
  }

  function setImmediateNextPage() {
    let prevPageNo = currentPage + 1;
    setCurrentPage(prevPageNo);
  }

  return (
    <div>
      <Modal open={showModal} className="modal-dialog">
        <div className="modal-container max-popup-style">
          <div className="modal-header ">
            <label className="Popup-header-common Popup-header-layout-style-user">Email Recipients</label>

            <div className="float-right flex flex-row">
              {/* <FaSearch className="search-icon-maximize-style " /> */}
              <CloseIcon
                className="cursor-hand"
                onClick={() => {
                  setShowModal(false);
                }}
              />
            </div>
            {/* <IconButton
                            color="primary"
                            component="span"
                            className="close"
                            onClick={() => {
                                setShowModal(false);
                            }}
                        > */}
            {/* <FaSearch className="search-icon-maximize-style " /> */}
            {/* <CloseIcon />
                        </IconButton> */}
          </div>

          <div className="modal-body" id="journal-scroll">
            <Table columns={columns} data={currentPosts} />
          </div>
          {emailRecipientTableData && emailRecipientTableData.length ? (
            <div className="modal-footer">
              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={emailRecipientTableData.length}
                paginate={paginate}
                setImmediatePrevPage={setImmediatePrevPage}
                indexOfFirstPost={indexOfFirstPost}
                indexOfLastPost={indexOfLastPost}
                setImmediateNextPage={setImmediateNextPage}
                currentPage={currentPage}
              />
            </div>
          ) : null}
        </div>
      </Modal>
      <form onSubmit={formik.handleSubmit} className="space-y-8 ">
        <div class="grid grid-cols-3  mt-2 pb-3 ml-1 ">
          {/* <div><label htmlFor="suiteName" className="input-filed-label-style-common" >
                        <span class="text-red-400">*</span> Name
                    </label></div>
                    <div className="recipient-email-id-style"><label htmlFor="suiteName" className="input-filed-label-style-common" >
                        <span class="text-red-400">*</span> Recipient Email ID
                    </label></div>
                    <div></div> */}
          <div className="">
            <div>
              <label htmlFor="suiteName" className="input-filed-label-style-common">
                <span class="text-red-400">*</span> Recipient Group
              </label>
              <br></br>
              <input
                type="text"
                disabled={!props.emailReportSelected}
                name="groupCount"
                autoComplete="off"
                placeholder={
                  selectedGroupCount ? `${selectedGroupCount} email group selected` : 'Select Recipient Group'
                }
                id="groupCount"
                onChange={(e) => getDataForEmailGroup(e)}
              ></input>
              {showEmailGroup ? (
                <div className="search-box-div-style">
                  <div className="border-botton-style">
                    {emailGroupCopyArray.map((emailObj) => (
                      <>
                        {emailGroupCopyArray.length ? (
                          <div className="p-3">
                            <input
                              type="checkbox"
                              onChange={(e) => selectEmailGroup(e, emailObj)}
                              defaultChecked={emailObj.checked}
                            ></input>
                            <span className="ml-2">{emailObj.name}</span>
                          </div>
                        ) : null}
                      </>
                    ))}
                  </div>

                  {emailGroupCopyArray && emailGroupCopyArray.length ? (
                    <div className="email-search-dropdown-btn-style">
                      <button onClick={closeEmailGroup}>Cancel</button>
                      <button className="secondary-btn ml-3 " onClick={createTableData}>
                        Add
                      </button>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
          <div className="recipient-email-id-style ">
            <label htmlFor="suiteName" className="input-filed-label-style-common">
              <span class="text-red-400">*</span> Recipient Email ID
            </label>
            <br></br>

            <div style={{ display: 'grid' }}>
              <ChipInput
                value={chipData}
                disabled={!props.emailReportSelected}
                onAdd={(chip) => handleAddChip(chip)}
                onDelete={(chipData, index) => handleDelete(chipData, index)}
                // placeholder={"asssssss"}
                // onKeyPress={handleKeypress}
              />
            </div>
          </div>
          <div>
            <button
              className="secondary-btn float-right mt-4 mr-2"
              onClick={(e) => {
                e.preventDefault();
                addEmailGroup();
              }}
            >
              Add
            </button>
          </div>
        </div>

        <div className="email-content-area-style">
          <div className="variable-type-header-style flex flex-wrap ">
            <label className="flex-auto">Email Recipients</label>
            {/* <FaSearch className="mt-1 mr-3" /> */}
            <FiMaximize2
              onClick={() => setShowModal(true)}
              className={`${emailRecipientTableData && emailRecipientTableData.length ? '' : 'disabled-action '} mt-1 mr-2 `}
            />
          </div>

          <Table columns={columns} data={currentPosts} />
          {emailRecipientTableData && emailRecipientTableData.length ? (
            <div className="pb-2">
              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={emailRecipientTableData.length}
                paginate={paginate}
                setImmediatePrevPage={setImmediatePrevPage}
                indexOfFirstPost={indexOfFirstPost}
                indexOfLastPost={indexOfLastPost}
                setImmediateNextPage={setImmediateNextPage}
                currentPage={currentPage}
              />
            </div>
          ) : null}

          {emailRecipientTableData && !emailRecipientTableData.length ? (
            <div className="create-set-layout">
              <label className="quick-start-style ">Quick Start </label>
              <br></br>
              <label className="quick-start-style ">Step 1:</label>
              <label className="quick-start-label-style">
                {' '}
                Click Recipient Group dropdown to start adding the Recipients
              </label>
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
}

export default EmailRecipients;

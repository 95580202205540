import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { useFormik } from 'formik';
import React, { Fragment, useState } from 'react';
import { deleteExecutionReq } from '../../../../api/api_services';

const DeleteExecution = (props) => {
  const a = {
    id: props.deleteData.id ? props.deleteData.id : props.deleteData._id,
    storageDetails: { ...props.deleteData.preExecution.storageDetails },
  };
  const [deleteData, setDeleteData] = useState(a);
  const [disableDeleteBtn, setDisableDeleteBtn] = useState(false);
  const [open, setOpenModal] = useState(true);
  const onSubmit = (values) => {
    let payload = {};
    if (values.deleteOnlyScreenshots || values.deleteOnlyVideos || values.deleteAll) {
      let filePath = [];
      if (values.deleteAll) {
        filePath = deleteData.storageDetails?.outputs?.dirName
          ? [`${deleteData.storageDetails?.outputs?.dirName}/${deleteData.id}`]
          : [`${deleteData.id}`];
      } else if (values.deleteOnlyScreenshots && values.deleteOnlyVideos) {
        filePath = deleteData.storageDetails?.outputs?.dirName
          ? [
              `${deleteData.storageDetails?.outputs?.dirName}/${deleteData.id}/Screenshots`,
              `${deleteData.storageDetails?.outputs?.dirName}/${deleteData.id}/Videos`,
            ]
          : [`${deleteData.id}/Screenshots`, `${deleteData.id}/Videos`];
      } else if (values.deleteOnlyScreenshots) {
        filePath = deleteData.storageDetails?.outputs?.dirName
          ? [`${deleteData.storageDetails?.outputs?.dirName}/${deleteData.id}/Screenshots`]
          : [`${deleteData.id}/Screenshots`];
      } else {
        filePath = deleteData.storageDetails?.outputs?.dirName
          ? [`${deleteData.storageDetails?.outputs?.dirName}/${deleteData.id}/Videos`]
          : [`${deleteData.id}/Videos`];
      }

      if (deleteData.storageDetails.type === 'cloudS3') {
        payload = {
          bucketName: deleteData.storageDetails.outputs.bucketName,
          updateSrc: values.deleteAll,
          filePath: filePath,
          type: 'cloudS3',
        };
      } else {
        payload = {
          bucketName: deleteData.storageDetails?.outputs?.bucketName,
          updateSrc: values.deleteAll,
          filePath: filePath,
          sharedIpAddress: deleteData.storageDetails?.outputs?.bucketName,
          type: 'sharedDrive',
        };
      }
      deleteFunction(deleteData.id, payload);
    }
  };
  let initialValues = {
    deleteAll: false,
    deleteOnlyScreenshots: false,
    deleteOnlyVideos: false,
  };
  const formikDetails = useFormik({
    initialValues,
    onSubmit,
  });
  async function deleteFunction(id, payload) {
    try {
      setDisableDeleteBtn(true);
      const res = await deleteExecutionReq(id, payload);
      if (res.data.responseCode === 200 && res.data.status === 'SUCCESS') {
        props.MyAlert.success(`${res.data.message}`);
        props.reload(true);
      } else {
        props.MyAlert.info(`${res.data.message}`);
      }
      setOpenModal(false);
      props.closeModal(false);
      setDisableDeleteBtn(false);
    } catch (err) {
      setDisableDeleteBtn(false);
      console.log(err);
    }
  }
  function handleDeleteAll(e) {
    if (e.target.checked) {
      formikDetails.setFieldValue('deleteOnlyScreenshots', true);
      formikDetails.setFieldValue('deleteOnlyVideos', true);
    } else {
      formikDetails.setFieldValue('deleteOnlyScreenshots', false);
      formikDetails.setFieldValue('deleteOnlyVideos', false);
    }
    formikDetails.handleChange(e);
  }
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={open}
        onClose={() => {
          props.closeModal(false);
          setOpenModal(false);
        }}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block marginupper pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/4">
              <div className="inline-block">
                <div className="flex flex-start ml-2">
                  <span className="pt-5 pl-3">
                    <ExclamationIcon className="h-6 w-6 text-yellow-600 " />
                  </span>
                  <Dialog.Title as="h3" className="text-sm leading-6 ml-4 pt-3 font-small text-gray-900">
                    <span className="fontPoppinsSemiboldMd block">Warning </span>
                    <span className="fontPoppinsRegularMd ">Delete </span>
                  </Dialog.Title>
                </div>
              </div>
              <form onSubmit={formikDetails.handleSubmit}>
                <hr className="line mt-px" />
                <div className="text-sm font-sans mt-3 text-gray-500 ml-16 mr-8">
                  <p className="fontPoppinsRegularMd">
                    {' '}
                    Your data will be lost. Are you sure you want to delete {props.deleteData.name?.substring(0, 50)}
                    {props.deleteData.name?.length > 50 ? '...' : ''} ?{' '}
                  </p>
                  <p className="mt-2 fontPoppinsRegularMd"> How do you want to proceed?</p>

                  <p className="mt-2 fontPoppinsRegularMd">
                    <input
                      name="deleteAll"
                      onChange={handleDeleteAll}
                      onBlur={formikDetails.handleBlur}
                      checked={formikDetails.values.deleteAll}
                      className="rounded"
                      type="checkbox"
                    />
                    <span className="ml-4 mt-1 fontPoppinsRegularMd">Delete all</span>
                  </p>
                  <p className="mt-2">
                    <input
                      name="deleteOnlyScreenshots"
                      disabled={formikDetails.values.deleteAll}
                      onChange={formikDetails.handleChange}
                      onBlur={formikDetails.handleBlur}
                      checked={formikDetails.values.deleteOnlyScreenshots}
                      className={formikDetails.values.deleteAll ? 'rounded opacity-30' : 'rounded'}
                      type="checkbox"
                    />
                    <span className="ml-4 mt-1 fontPoppinsRegularMd">Delete only screenshots</span>
                  </p>
                  <p className="mt-2">
                    <input
                      name="deleteOnlyVideos"
                      disabled={formikDetails.values.deleteAll}
                      onChange={formikDetails.handleChange}
                      onBlur={formikDetails.handleBlur}
                      checked={formikDetails.values.deleteOnlyVideos}
                      className={formikDetails.values.deleteAll ? 'rounded opacity-30' : 'rounded'}
                      type="checkbox"
                    />
                    <span className="ml-4 mt-1 fontPoppinsRegularMd">Delete only videos</span>
                  </p>
                </div>
                <hr className="line mt-3" />
                <div className="mt-2 pb-2 flex flex-row float-right">
                  <button type="submit" className="mt-3 gray-btn ml-1" disabled={disableDeleteBtn}>
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 ml-4 primary-btn "
                    onClick={() => {
                      props.closeModal(false);
                      setOpenModal(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DeleteExecution;

import DashboardStyle from '../styles/dashboard.module.scss';
import cx from 'classnames';
function MemoryNOProject({ projectTypeName }) {
  const name = projectTypeName;
  const projectName = name.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
  return (
    <div className={cx('col-span-6', DashboardStyle['memory-no-project-container'])}>
      <h1>No Project Found !</h1>
      <p>
        There is no memory assigned for {projectName} project or {projectName} project has not been created yet
      </p>
    </div>
  );
}

export default MemoryNOProject;

import React from 'react';
import { downloadProjectFile } from '@src/api/api_services';
import { Tooltip } from '@material-ui/core';
import MyTable from '../../licence_management/shared/Table';
import '@pages/user_config/userDetails.scss';

const ProjectDetailsPage = (props) => {
  function saveFileFromBlob(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  }
  function downloadFile(fileId, fileName) {
    downloadProjectFile(fileId).then((d) => {
      saveFileFromBlob(d.data, fileName);
    });
  }
  const result = props.projectDetails?.projects;
  const projectResult = props.projectDetails?.user;
  const data = React.useMemo(
    () =>
      Object?.values(projectResult || {}).map((proVal) => ({
        userName: (
          <span
            className="text-gray-700 cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150"
            title={proVal?.userName}
          >
            {' '}
            {proVal.userName}{' '}
          </span>
        ),
        roleName: (
          <span
            className="text-gray-700 cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150"
            title={proVal?.roleName}
          >
            {' '}
            {proVal.roleName}{' '}
          </span>
        ),
      })),
    []
  );
  const columns = React.useMemo(
    () => [
      {
        Header: 'User',
        accessor: 'userName',
        width: '30%',
        background: '#F0F3F9',
      },
      {
        Header: 'Role',
        accessor: 'roleName',
        width: '20%',
        background: '#F0F3F9',
      },
    ],
    []
  );
  return (
    <div className="h-1/2 ml-5">
      <div id="journal-scroll" className="project-details overflow-y-auto">
        <div className="grid grid-cols-3 mt-2 pb-4 ">
          <div className="overflow-hidden-common">
            <label htmlFor="userName" className="block text-gray-700 sm:mt-px sm:pt-2 details-key-style-common">
              Name
            </label>
            <label
              className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150"
              title={result?.name}
            >
              {result?.name ? result?.name : <span>--</span>}
            </label>
          </div>
          <div>
            <label htmlFor="userName" className="block text-gray-700 sm:mt-px sm:pt-2 details-key-style-common">
              Type
            </label>
            <label
              className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150"
              title={result?.type}
            >
              {result?.type ? result?.type : <span>--</span>}
            </label>
          </div>
          <div className="overflow-hidden-common">
            <label htmlFor="emailId" className="block  text-gray-700 sm:mt-px sm:pt-2 details-key-style-common">
              Description
            </label>

            {result?.desc ? (
              <label
                className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150"
                title={result?.desc}
              >
                {result?.desc ? result?.desc : <span>--</span>}
              </label>
            ) : (
              <label className="details-data-style-common">--</label>
            )}
          </div>
          <div className="mt-6">
            <label htmlFor="userName" className="block  text-gray-700 sm:mt-px sm:pt-2 details-key-style-common">
              Status
            </label>
            {result?.status === 'Open' ? (
              <label className="label-success fontPoppinsRegularMd">{result?.status}</label>
            ) : result?.status === 'Closed' ? (
              <label className="label-error fontPoppinsRegularMd">{result?.status}</label>
            ) : (
              <label className="label-archive fontPoppinsRegularMd">{'Archive'}</label>
            )}
          </div>

          {String(result?.type)?.includes('Web Service') && (
            <>
              <div className="mt-6">
                <label htmlFor="emailId" className="block  text-gray-700 sm:mt-px sm:pt-2 details-key-style-common">
                  Base URI
                </label>
                {result?.baseUri ? (
                  <label
                    className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-250"
                    title={result?.baseUri}
                  >
                    {result?.baseUri}
                  </label>
                ) : (
                  <label className="details-data-style-common">--</label>
                )}
              </div>
              <div className="mt-6">
                <label htmlFor="emailId" className="block  text-gray-700 sm:mt-px sm:pt-2 details-key-style-common">
                  Port
                </label>
                {result?.port ? (
                  <label
                    className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-250"
                    title={result?.port}
                  >
                    {result?.port}
                  </label>
                ) : (
                  <label className="details-data-style-common">--</label>
                )}
              </div>
            </>
          )}

          {String(result?.type)?.includes('Mobile') && (
            <>
              {' '}
              <div className="mt-6">
                <label htmlFor="appType" className="block  text-gray-700 sm:mt-px sm:pt-2 details-key-style-common">
                  AppType
                </label>
                <label
                  className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-250"
                  title={result?.appType}
                >
                  {result?.appType}
                </label>
              </div>
            </>
          )}
          {String(result?.platform)?.includes('Android') && (
            <>
              <div className="mt-6">
                <label htmlFor="appActivity" className="block  text-gray-700 sm:mt-px sm:pt-2 details-key-style-common">
                  App Package
                </label>
                {result?.appPackage ? (
                  <label
                    className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-250"
                    title={result?.appPackage}
                  >
                    {result?.appPackage}
                  </label>
                ) : (
                  <label className="details-data-style-common">--</label>
                )}
              </div>
              <div className="mt-6">
                <label htmlFor="appActivity" className="block  text-gray-700 sm:mt-px sm:pt-2 details-key-style-common">
                  App Activity
                </label>
                {result?.appActivity ? (
                  <label
                    className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-250"
                    title={result?.appActivity}
                  >
                    {result?.appActivity}
                  </label>
                ) : (
                  <label className="details-data-style-common">--</label>
                )}
              </div>
            </>
          )}

          {String(result?.platform)?.toLowerCase()?.includes('iOS'.toLowerCase()) && (
            <>
              <div className="mt-6">
                <label htmlFor="bundleId" className="block  text-gray-700 sm:mt-px sm:pt-2 details-key-style-common">
                  Bundle Id
                </label>
                {result?.bundleId ? (
                  <label
                    className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-250"
                    title={result?.bundleId}
                  >
                    {result?.bundleId}
                  </label>
                ) : (
                  <label className="details-data-style-common">--</label>
                )}
              </div>
            </>
          )}

          {String(result?.type)?.includes('Web') && !String(result?.type)?.includes('Web Service') && (
            <div className="mt-6">
              <label htmlFor="url" className="block  text-gray-700 sm:mt-px sm:pt-2 details-key-style-common">
                Web Url
              </label>
              {result?.url ? (
                <label
                  className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150"
                  title={result?.url}
                >
                  {result?.url}
                </label>
              ) : (
                <label className="details-data-style-common">--</label>
              )}
            </div>
          )}

          <div className="mt-6">
            <label htmlFor="userName" className="block  text-gray-700 sm:mt-px sm:pt-2 details-key-style-common">
              Created By
            </label>
            <label
              className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150"
              title={result?.createdByUname}
            >
              {result?.createdByUname}
            </label>
          </div>
          <div className="mt-6">
            <label htmlFor="userName" className="block  text-gray-700 sm:mt-px sm:pt-2 details-key-style-common">
              Created On
            </label>
            <label className="details-data-style-common">{result?.createdOn}</label>
          </div>
          <div className="mt-6">
            <label htmlFor="userName" className="block  text-gray-700 sm:mt-px sm:pt-2 details-key-style-common">
              Modified By
            </label>
            <label
              className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150"
              title={result?.modifiedByUname}
            >
              {result?.modifiedByUname ? result?.modifiedByUname : <span>--</span>}
            </label>
          </div>
          <div className="mt-6">
            <label htmlFor="userName" className="block  text-gray-700 sm:mt-px sm:pt-2 details-key-style-common">
              Modified On
            </label>
            <label className="details-data-style-common">
              {result?.modifiedOn ? result?.modifiedOn : <span>--</span>}
            </label>
          </div>
          <div className="mt-6">
            <label htmlFor="userName" className="block  text-gray-700 sm:mt-px sm:pt-2 details-key-style-common">
              Time Zone
            </label>
            <label className="details-data-style-common">
              {result?.modifiedOn ? result?.timeZoneValue : <span>--</span>}
            </label>
          </div>
        </div>
        <div className="grid grid-cols-3 pb-4 -mt-4 ">
          {String(result?.platform)?.toLowerCase()?.includes('Android'.toLowerCase()) && (
            <div className="mt-6 -ml-4 ">
              <label className="mt-0.5 flex flex-row file-download-label-style inline-block -ml-3 pl-0.5">
                {' '}
                {result?.apkFiles.length && result?.apkFiles[0].name ? (
                  <>
                    {' '}
                    <img alt="" src="/assets/images/Attach_icon.svg" className="fie-icon-style " />
                    <label
                      className="overflow-hidden overflow-ellipsis whitespace-nowrap max-w-150"
                      title={result?.apkFiles[0].name}
                    >
                      {result?.apkFiles[0].name}
                    </label>
                    <Tooltip title="Download" placement="top">
                      <img
                        alt=""
                        src="/assets/images/Download_icon.svg"
                        onClick={() => downloadFile(result?.apkFiles[0]?.id, result?.apkFiles[0].name)}
                        className=" mt-1 download-icon-style inline-block"
                      />
                    </Tooltip>{' '}
                  </>
                ) : (
                  <span></span>
                )}
              </label>
            </div>
          )}
          {String(result?.platform)?.toLowerCase()?.includes('iOS'.toLowerCase()) && (
            <div className="mt-6 -ml-4 ">
              <label className="mt-0.5 flex flex-row file-download-label-style inline-block pl-0.5">
                {' '}
                {result?.ipaFiles.length && result?.ipaFiles[0].name ? (
                  <>
                    {' '}
                    <img alt="" src="/assets/images/Attach_icon.svg" className="fie-icon-style " />{' '}
                    <label
                      className="overflow-hidden overflow-ellipsis whitespace-nowrap max-w-150"
                      title={result?.ipaFiles[0].name}
                    >
                      {result?.ipaFiles[0].name}
                    </label>
                    <img
                      alt=""
                      src="/assets/images/Download_icon.svg"
                      onClick={() => downloadFile(result?.ipaFiles[0]?.id, result?.ipaFiles[0].name)}
                      className="ml-4 mt-1 download-icon-style inline-block"
                    />{' '}
                  </>
                ) : (
                  <span></span>
                )}
              </label>
            </div>
          )}
        </div>

        <div className="mt-6 detailsOfproject">
          <label className="mt-6 ml-4 Popup-header-common">User Role Mapping</label>
          <div className=" mt-2 userRoleMapping">
            <MyTable data={data} columns={columns} tableHeightClass={'modal-table-height'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailsPage;

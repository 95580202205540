import React, { Fragment, useState, useContext, useEffect } from 'react';
import { CommonLoader } from '@src/pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { useTable, useFilters, useGlobalFilter, useSortBy } from 'react-table';

import '../common/react_table.css';
import RoleCreatePage from './create_component_session/role_create_page';
import Modal from 'react-modal';
import ContextMenu from '../common/context_menu';
import { Dialog, Transition } from '@headlessui/react';
import RoleDetailsPage from './create_component_session/role_details_page';
import UserDetailsPage from './create_component_session/user_details_page';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import {
  getAllRoleRequest,
  deleteSingleRoleRequest,
  getRolesByprojectIdReq,
  getSingleUserRequest,
  getSingleRoleRequest,
} from '@api/api_services';

import { getSelectedProject } from '../../service/common_service';
import { useLocation } from 'react-router-dom';
import { ColumnFilter } from '../common/table_tree/react_table/ColumnFilter';
import { DateColumnFilter } from '../common/table_tree/react_table/DateColumnFilter';
import {
  textFilterFn,
  fuzzyTextFilterFn,
  multipleRowFilterFn,
  dateBetweenFilterFn,
} from '../common/table_tree/react_table/CommonFunctions';
import SearchBar from '@src/pages/analytics/common/search-bar';
import { HighlightText, isEmptyValue, isValueIncludes } from '@util/common_utils';
import NoResultsFound from '@src/pages/common/Table/NoResultFound';
import { getUserPrivilege } from '@util/common_utils';
import { ROLES_CONSTANTS, ACTIONS, LICENSE_DETAILS_CONSTANTS } from '@src/common/ui-constants';

export const RolePage = ({ MyAlert }) => {
  fuzzyTextFilterFn.autoRemove = (val) => !val;

  const { isAdminOrSuperAdmin, isProjectOpen } = React.useMemo(
    () => ({
      isAdminOrSuperAdmin: window.permission?.getUserRole() !== 'User',
      isProjectOpen: window.permission?.isProjectOpen() || !window.permission?.getSelectedProject().id,
    }),
    []
  );

  function Table({ columns, data }) {
    const filterTypes = React.useMemo(
      () => ({
        fuzzyText: fuzzyTextFilterFn,
        text: textFilterFn,
        multiple: multipleRowFilterFn,
        dateBetween: dateBetweenFilterFn,
      }),
      []
    );

    const defaultColumn = React.useMemo(
      () => ({
        Filter: ColumnFilter,
      }),
      []
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
      {
        columns,
        data,
        defaultColumn,
        filterTypes,
      },
      useFilters,
      useGlobalFilter,
      useSortBy
    );

    return (
      <>
        <div className="primary-table-height">
          <table className="relative w-full " {...getTableProps()}>
            <thead className="table_header_color stick-table-thead">
              {headerGroups.map((headerGroup) => (
                <tr className="table_header_color h-12 sticky top-0 zIndex-1" {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className="sticky top-0 px-6 py-3 text-current  ml-5"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <span
                        className="uppercase fontPoppinsSemiboldMd flex flex-row mr-12 border-none text-gray-700"
                        style={{
                          color: '#3C3838',
                        }}
                      >
                        {column.render('Header')}
                        <div className="flex">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <AiOutlineArrowDown className="mt-1 ml-1" />
                            ) : (
                              <AiOutlineArrowUp className="mt-1 ml-1" />
                            )
                          ) : (
                            ''
                          )}
                        </div>
                      </span>
                      {column.canFilter ? column.render('Filter') : null}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="divide-y z-10" {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr key={`project-list-row-${i}`} className="project-row  border-none h-auto" {...row.getRowProps()}>
                    {row.cells.map((cell, cellIndex) => {
                      return (
                        <td
                          key={`project-list-td-${cellIndex}`}
                          className="px-4 py-0 fontPoppinsRegularMd text-center"
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {openSearch && isEmptyValue(data) && <NoResultsFound className="mt-14" />}
        </div>
      </>
    );
  }

  function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue >= filterValue;
    });
  }

  filterGreaterThan.autoRemove = (val) => typeof val !== 'number';

  const customStylesProject = {
    content: {
      position: 'absolute',
      top: '1%',
      left: '25%',
      right: '35%',
      overflow: 'hidden',
      width: '640px',
      maxHeight: 'fit-content',
    },
  };
  const customStylesProject1 = {
    content: {
      position: 'absolute',
      top: '1%',
      left: '25%',
      right: '35%',

      width: '960px',
      overflow: 'hidden',
      maxHeight: '1080px',
      background: '#FBFCFD',
    },
  };
  const customStylesProject2 = {
    content: {
      position: 'absolute',
      top: '1%',
      left: '25%',
      right: '35%',
      width: '960px',
      overflow: 'hidden',
      maxHeight: '1080px',
    },
  };

  const [isLoaded, setIsLoaded] = React.useState(false);
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [roles, setRoles] = React.useState([]);
  const [popupStyle, setpopupStyle] = React.useState();
  const [deleteId, setDeleteId] = React.useState();
  const [seletedRole, setSelectedProject] = React.useState({});

  const location = useLocation();
  const id = String(location?.pathname).includes('individual');
  const [selectedRole, setSelectedRole] = React.useState();
  const defaultRoles = [ROLES_CONSTANTS.FULL_ACCESS, ROLES_CONSTANTS.VIEW_ACCESS];

  // TODO :: handle Search filter :: START
  const [userSearched, setuserSearched] = useState('');
  const [data, setData] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  let [isHighlight, setIsHighlight] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');

  useEffect(() => {
    setData(roles);
    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [roles]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(roles);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setData(roles);
    setIsHighlight(!isHighlight);
    setSearchTextHighlight('');
  };
  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = roles.filter(({ name, modifiedByUname }) => {
          return isValueIncludes(e, name) || isValueIncludes(e, modifiedByUname);
        });
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
        setData(newData);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  // TODO :: handle Search filter :: END

  const col = [
    {
      Header: 'Name',
      columns: [],
    },

    {
      Header: 'Name',
      accessor: 'name',
      filter: 'multiple',
      disableFilters: true,
      Cell: (e) => (
        <div
          title={e.value}
          onClick={(event) => {
            event.preventDefault();
            if (call) {
              call = false;
              viewRole(e.cell.row.original);
            }
            setdetailsHeader(event.target.innerText);
          }}
          className="hyper_link_color-common fontPoppinsRegularMd float-left ml-2 cursor-pointer"
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '150px',
            float: 'left',
          }}
        >
          <HighlightText text={e.value} highlight={searchTextHighlight} />
        </div>
      ),
    },

    {
      Header: 'Modified By',
      accessor: 'modifiedByUname',
      disableFilters: true,
      filter: 'multiple',
      Cell: (e) => (
        <div
          title={e.value}
          onClick={(event) => {
            if (!e.value || e.value === '--') {
              event.preventDefault();
            } else {
              if (call) {
                call = false;
                viewCreatedByUser(e.cell.row.original);
              }
              setdetailsHeader(event.target.innerText);
            }
          }}
          className="hyper_link_color-common fontPoppinsRegularMd float-left ml-2 cursor-pointer"
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '155px',
            float: 'left',
          }}
        >
          <HighlightText text={!e.value || e.value === '--' ? '--' : e.value} highlight={searchTextHighlight} />
        </div>
      ),
    },
    {
      Header: 'Modified On',
      accessor: 'modifiedOn',
      filter: 'dateBetween',
      disableFilters: true,
      Filter: DateColumnFilter,
      Cell: ({ value }) => {
        return <div className="table-non-link-color-common float-left fontPoppinsRegularMd ml-2">{value}</div>;
      },
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      disableFilters: true,
      Cell: ({ value }) => {
        return (
          <div
            className="table-non-link-color-common float-left rs-icon-alignment-font-style"
            style={{ marginLeft: '10px' }}
          >
            {value}
          </div>
        );
      },
    },
  ];

  const columns = React.useMemo(() => {
    if (id) {
      col.splice(2, 0, {
        Header: 'From',
        accessor: 'projectId',
        filter: 'multiple',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common font-style-common float-left" style={{ marginLeft: '8px' }}>
              {value ? LICENSE_DETAILS_CONSTANTS.PROJECT : LICENSE_DETAILS_CONSTANTS.LICENSE}
            </div>
          );
        },
      });
      return col;
    } else {
      return col;
    }
  }, [isHighlight]);

  let call = true;
  function viewRole(roleObj) {
    getSingleRoleRequest(roleObj.id).then((results) => {
      const role = results.data.responseObject;
      setSelectedRole(role);
      setShowModal(true);
      setpopupHeaderAndBtn('Role Details');
      call = true;
    });
  }

  const [userObj, setUserObj] = React.useState({});
  const [detailsHeader, setdetailsHeader] = React.useState('');

  function contextMenuFunction(event, resObj) {
    if (event.target.textContent === 'Details') {
      viewRole(resObj);
      setpopupHeaderAndBtn('Role Details');
      setdetailsHeader(resObj.name);
    }
  }

  function viewCreatedByUser(user) {
    getSingleUserRequest(user.createdBy).then((results) => {
      const user = results.data.responseObject;
      const projects = results.data.responseMap;
      setUserObj({ user, projects });
      setShowModal(true);
      setpopupHeaderAndBtn('User Details');
      call = true;
    });
  }

  function callFunction(functionName) {
    if (functionName === 'getAllRoles') {
      initRoles();
    } else if (functionName === 'closePopup') {
      closePopup();
    }
  }

  function getRoleName(res) {
    setSelectedRole(res);
  }

  function getAllRoles() {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    getAllRoleRequest()
      .then((results) => {
        if (results.status === 200) {
          resetProgress();
        }
        setTimeout(() => {
          setIsLoaded(true);
          const userPrivilege = getUserPrivilege();
          const response = results.data?.responseObject ? results.data?.responseObject : [];

          response.map((res, i) => {
            res.actions = [
              <div className="-ml-6 flex items-center" style={{ border: '2px solid transparent' }}>
                {isAdminOrSuperAdmin && isProjectOpen && (
                  <span
                    id="role"
                    data-title={defaultRoles.includes(res.name) ? ROLES_CONSTANTS.EDIT_MESSAGE : ACTIONS.EDIT}
                    className="py-0 px-1 rounded text-center"
                  >
                    <button
                      disabled={defaultRoles.includes(res.name)}
                      style={{ marginRight: '3px', marginLeft: '5px' }}
                      className="project-action  state-button py-0 px-1 rounded-l cursor-pointer"
                      onClick={() => {
                        setShowModal(true);
                        setpopupHeaderAndBtn('Edit Role', 'Update');
                        setSelectedProject(res);
                        setdetailsHeader(res.name);
                      }}
                    >
                      <img src="/assets/images/edit_blue.svg" alt="" height="20px" width="20px" />
                    </button>
                  </span>
                )}
                {isAdminOrSuperAdmin && isProjectOpen && (
                  <span
                    id="role"
                    style={{ marginLeft: '5px' }}
                    data-title={defaultRoles.includes(res.name) ? ROLES_CONSTANTS.DELETE_MESSAGE : ACTIONS.DELETE}
                    className="py-0 px-1 rounded-l"
                  >
                    <button
                      disabled={defaultRoles.includes(res.name)}
                      style={{ marginRight: '3px' }}
                      className=" project-action state-button  cursor-pointer disabled"
                      onClick={() => {
                        setOpen(true);
                        setpopupHeaderAndBtn('Delete');
                        setDeleteId(res.id);
                        getRoleName(res);
                      }}
                    >
                      <img src="/assets/images/delete_blue.svg" alt="" height="20px" width="20px" />
                    </button>
                  </span>
                )}

                <span className={`project-action cursor-pointer ${userPrivilege && 'ml-10'}`}>
                  <ContextMenu
                    contextMenuData={contextMenuData}
                    func={(e) => {
                      contextMenuFunction(e, res);
                    }}
                  />
                </span>
              </div>,
            ];
          });

          setRoles(response);
        }, 100);
      })
      .catch((error) => {
        resetProgress();
        setIsLoaded(true);
      });
    return [];
  }
  function getRolesByProjectId(project) {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    getRolesByprojectIdReq(project.id)
      .then((results) => {
        if (results.status === 200) {
          resetProgress();
        }
        setTimeout(() => {
          setIsLoaded(true);
          const userPrivilegeIndivisual = JSON.parse(localStorage.getItem('test-optimize-user')).privilege;
          const ProjectStatus = JSON.parse(localStorage.getItem('selected-project')).status;
          const response = results.data?.responseObject ? results.data?.responseObject : [];

          response.map((res, i) => {
            res.actions = [
              <div className="-ml-8 flex items-center">
                {isAdminOrSuperAdmin && isProjectOpen && (
                  <span
                    id="role"
                    data-title={
                      defaultRoles.includes(res.name)
                        ? ROLES_CONSTANTS.EDIT_MESSAGE
                        : !res.projectId
                        ? ROLES_CONSTANTS.PROJECT_LEVEL_EDIT_MESSAGE
                        : ACTIONS.EDIT
                    }
                    className="py-0 px-1 rounded-l text-center "
                  >
                    <button
                      disabled={!res?.projectId || defaultRoles.includes(res.name)}
                      style={{ marginRight: '3px' }}
                      className=" project-action  state-button py-0 px-1 rounded-l cursor-pointer"
                      onClick={() => {
                        setShowModal(true);
                        setpopupHeaderAndBtn('Edit Role', 'Update');
                        setSelectedProject(res);
                        setdetailsHeader(res.name);
                      }}
                    >
                      <img src="/assets/images/edit_blue.svg" alt="" height="20px" width="20px" />
                    </button>
                  </span>
                )}

                {isAdminOrSuperAdmin && isProjectOpen && (
                  <span
                    style={{ marginLeft: '3px' }}
                    id="role"
                    data-title={
                      defaultRoles.includes(res.name)
                        ? ROLES_CONSTANTS.DELETE_MESSAGE
                        : !res.projectId
                        ? ROLES_CONSTANTS.PROJECT_LEVEL_DELETE_MESSAGE
                        : ACTIONS.DELETE
                    }
                    className="py-0 px-1 rounded-l "
                  >
                    <button
                      disabled={!res?.projectId || defaultRoles.includes(res.name)}
                      style={{ marginRight: '3px', marginLeft: '-3px' }}
                      className=" project-action  state-button py-0 px-1 rounded-l cursor-pointer"
                      onClick={() => {
                        setOpen(true);
                        setpopupHeaderAndBtn('Delete');
                        setDeleteId(res.id);
                        getRoleName(res);
                      }}
                    >
                      <img src="/assets/images/delete_blue.svg" alt="" height="20px" width="20px" />
                    </button>
                  </span>
                )}

                <span
                  className={`project-action cursor-pointer ${
                    userPrivilegeIndivisual == 'User' || ProjectStatus === 'Closed' ? 'ml-12' : ''
                  }`}
                >
                  <ContextMenu
                    contextMenuData={contextMenuData}
                    func={(e) => {
                      contextMenuFunction(e, res);
                    }}
                  />
                </span>
              </div>,
            ];
          });
          setRoles(response);
        }, 100);
      })
      .catch((err) => {
        resetProgress();
        console.error('GET_ROLES_BY_PROJECT : ', err);
      });
    getRolesByprojectIdReq(project.id)
      .then((results) => {
        if (results.status === 200) {
          stopProgress();
          resetProgress();
        }
        setTimeout(() => {
          setIsLoaded(true);
          const userPrivilegeIndivisual = JSON.parse(localStorage.getItem('test-optimize-user')).privilege;
          const ProjectStatus = JSON.parse(localStorage.getItem('selected-project')).status;
          const response = results.data?.responseObject ? results.data?.responseObject : [];

          response.map((res, i) => {
            res.actions = [
              <div className="-ml-8 flex items-center">
                {isAdminOrSuperAdmin && isProjectOpen && (
                  <span
                    id="role"
                    data-title={
                      defaultRoles.includes(res.name)
                        ? ROLES_CONSTANTS.EDIT_MESSAGE
                        : !res.projectId
                        ? ROLES_CONSTANTS.PROJECT_LEVEL_EDIT_MESSAGE
                        : ACTIONS.EDIT
                    }
                    className="py-0 px-1 rounded-l text-center "
                  >
                    <button
                      disabled={!res?.projectId || defaultRoles.includes(res.name)}
                      style={{ marginRight: '3px' }}
                      className=" project-action  state-button py-0 px-1 rounded-l cursor-pointer"
                      onClick={() => {
                        setShowModal(true);
                        setpopupHeaderAndBtn('Edit Role', 'Update');
                        setSelectedProject(res);
                        setdetailsHeader(res.name);
                      }}
                    >
                      <img src="/assets/images/edit_blue.svg" alt="" height="20px" width="20px" />
                    </button>
                  </span>
                )}

                {isAdminOrSuperAdmin && isProjectOpen && (
                  <span
                    style={{ marginLeft: '3px' }}
                    id="role"
                    data-title={
                      defaultRoles.includes(res.name)
                        ? ROLES_CONSTANTS.DELETE_MESSAGE
                        : !res.projectId
                        ? ROLES_CONSTANTS.PROJECT_LEVEL_DELETE_MESSAGE
                        : ACTIONS.DELETE
                    }
                    className="py-0 px-1 rounded-l "
                  >
                    <button
                      disabled={!res?.projectId || defaultRoles.includes(res.name)}
                      style={{ marginRight: '3px', marginLeft: '-3px' }}
                      className=" project-action  state-button py-0 px-1 rounded-l cursor-pointer"
                      onClick={() => {
                        setOpen(true);
                        setpopupHeaderAndBtn('Delete');
                        setDeleteId(res.id);
                        getRoleName(res);
                      }}
                    >
                      <img src="/assets/images/delete_blue.svg" alt="" height="20px" width="20px" />
                    </button>
                  </span>
                )}

                <span
                  className={`project-action cursor-pointer ${
                    userPrivilegeIndivisual == 'User' || ProjectStatus === 'Closed' ? 'ml-12' : ''
                  }`}
                >
                  <ContextMenu
                    contextMenuData={contextMenuData}
                    func={(e) => {
                      contextMenuFunction(e, res);
                    }}
                  />
                </span>
              </div>,
            ];
          });
          setRoles(response);
        }, 100);
      })
      .catch((err) => {
        resetProgress();
        console.error('GET_ROLES_BY_PROJECT : ', err);
      });
  }

  const initRoles = () => {
    const selectedProject = getSelectedProject();
    if (selectedProject.id === 'all' || selectedProject.id === '') {
      getAllRoles();
    } else {
      getRolesByProjectId(selectedProject);
    }
  };

  React.useEffect(() => {
    initRoles();
  }, []);

  const contextMenuData = [
    {
      option: 'Details',
      icon: '/assets/images/eye_logo.svg',
    },
  ];

  const [showModal, setShowModal] = React.useState(false);
  const [popupHeader, setpopupHeader] = React.useState();
  const [popupBtn, setpopupbtn] = React.useState();
  const [open, setOpen] = React.useState(false);

  const setpopupHeaderAndBtn = (header, btn) => {
    setpopupHeader(header);
    setpopupbtn(btn);
    if (header === 'Create Role' || header === 'Edit Role') {
      setpopupStyle(customStylesProject);
    } else if (header === 'Role Details') {
      setpopupStyle(customStylesProject1);
    } else if (header === 'User Details') {
      setpopupStyle(customStylesProject2);
    }
  };
  function closePopup() {
    setShowModal(false);
  }
  const [createUpdateCalled, setCreateUpdateCalled] = React.useState(true);
  let handelDlt = (delId) => {
    if (createUpdateCalled) {
      setCreateUpdateCalled(false);
      deleteSingleRoleRequest(delId)
        .then((results) => {
          initRoles();

          setTimeout(() => {
            setCreateUpdateCalled(true);
          }, 2000);
          if (results.data.responseObject) {
            MyAlert.success(`${selectedRole.name} Role Deleted successfully`);
            setOpen(false);
          } else {
            MyAlert.info(
              <>
                <label>{selectedRole?.name} can not be deleted as it is associated with a user</label>
              </>
            );
            setOpen(false);
          }
        })
        .catch((error) => {
          console.error('error', error);
        });
    }
  };

  if (!isLoaded) {
    return <CommonLoader />;
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={open}
          onClose={() => setOpen(true)}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className="inline-block marginupper pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/4"
                style={{ width: '640px', height: '217px' }}
              >
                <div className="inline-block">
                  <div className="flex flex-start ml-2">
                    <span className="pt-5 pl-3">
                      <img src="/assets/images/remove-user-icon.svg" alt="" height="25px" width="25px" />
                    </span>
                    <Dialog.Title as="h3" className="leading-6 ml-4 pt-3 text-gray-900">
                      <span className="fontPoppinsSemiboldLg block">Warning </span>
                      <span className="fontPoppinsMediumMd">Delete </span>
                    </Dialog.Title>
                  </div>
                </div>
                <hr className="line mt-px" />
                <div className="fontPoppinsMediumMd mt-3 text-gray-500 ml-16 mr-8">
                  <p> Your data will be lost. Are you sure you want to delete {selectedRole?.name} role? </p>
                  <p className="mt-2"> How do you want to proceed?</p>
                </div>
                <hr className="line mt-3" />
                {selectedRole?.name?.length < 15 ? (
                  <div className="mt-0.5 pb-2 flex flex-row float-right">
                    <button
                      type="button"
                      className="mt-5 gray-btn ml-1"
                      style={{
                        marginRight: '8px',
                      }}
                      onClick={() => handelDlt(deleteId)}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="mt-5 ml-4 primary-btn "
                      style={{
                        marginRight: '12px',
                      }}
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <div className="mt-0.5 pb-2 flex flex-row float-right">
                    <button
                      type="button"
                      className="mt-0 gray-btn ml-1"
                      style={{
                        marginRight: '8px',
                      }}
                      onClick={() => handelDlt(deleteId)}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="mt-0 ml-4 primary-btn"
                      style={{
                        marginRight: '12px',
                        backgroundColor: '#4671D8',
                      }}
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Modal
        isOpen={showModal}
        onRequestClose={(e) => {
          if (e.key === 'Escape') {
            setShowModal(false);
          }
        }}
        style={popupStyle}
      >
        <div>
          <div className="Popup-header-common Popup-header-layout-style-role">
            {popupHeader === 'Create Role' ? (
              <label> {popupHeader}</label>
            ) : popupHeader === 'Edit Role' ? (
              <label
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  maxWidth: '250px',
                  float: 'left',
                }}
              >
                {' '}
                {popupHeader} - {detailsHeader}
              </label>
            ) : popupHeader === 'Role Details' ? (
              <label>
                {' '}
                {popupHeader} - {detailsHeader}
              </label>
            ) : popupHeader === 'User Details' ? (
              <label>
                {' '}
                {popupHeader} - {detailsHeader}
              </label>
            ) : null}
          </div>

          <button
            onClick={() => setShowModal(false)}
            type="button"
            className="float-right -mt-6 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        {popupHeader === 'Create Role' || popupHeader === 'Edit Role' ? (
          <div className="mt-3.5 border-t border-#e2e5ec-200" style={{ width: '105%', marginLeft: '-18px' }}>
            <RoleCreatePage
              MyAlert={MyAlert}
              func={callFunction}
              role={seletedRole}
              button={popupBtn}
              header={popupHeader}
              roleCount={roles}
            />
          </div>
        ) : popupHeader === 'Role Details' ? (
          <div className="mt-2  border-t  border-#e2e5ec-200" style={{ width: '104%', marginLeft: '-18px' }}>
            <RoleDetailsPage roleDetails={selectedRole} />
            <div className="delete_btn_style_role_details border-t mt-1">
              <button onClick={() => setShowModal(false)} className="gray-btn" style={{ marginRight: '23px' }}>
                <span>Close</span>
              </button>
            </div>
          </div>
        ) : popupHeader === 'User Details' ? (
          <div className="mt-2 border-t  border-#e2e5ec-200" style={{ width: '106%', marginLeft: '-18px' }}>
            <UserDetailsPage selectedUser={userObj} />

            <div className="delete_btn_style_user_details border-t mt-10">
              <button onClick={() => setShowModal(false)} className="gray-btn" style={{ marginRight: '23px' }}>
                <span>Close</span>
              </button>
            </div>
          </div>
        ) : null}
      </Modal>
      <div className="content_area_style">
        <div className="table-height overflow-auto">
          <div className="pt-4 pb-3 border_style flex flex-wrap items-center stick-table-parent-header">
            <label className="project_label flex-auto">Roles List</label>
            <div className="pr-4">
              <div className="flex flex-row items-center">
                <SearchBar
                  open={openSearch}
                  searchValue={userSearched}
                  handleSearch={handleSearch}
                  onSearchClose={handleSearchClose}
                  handleSearchOpen={handleSearchOpen}
                  enterKeyHandler={handleSearchSubmit}
                  disable={isEmptyValue(roles)}
                />

                {isAdminOrSuperAdmin && isProjectOpen && (
                  <button
                    className="primary-btn ml-4"
                    onClick={() => {
                      setShowModal(true);
                      setSelectedProject((d) => ({ id: '' }));
                      setpopupHeaderAndBtn('Create Role', 'Create');
                    }}
                  >
                    {' '}
                    + Role
                  </button>
                )}
              </div>
            </div>
          </div>
          <Table columns={columns} data={data} />
        </div>
      </div>
    </>
  );
};
export default RolePage;

import React, { useState, useEffect, useContext } from 'react';
import MyInput from '@pagescommon/Inputs/MyInput';
import { RestContext } from '@pages/test-development/script/scripts/webservice/restapi/restapi';
import { WEBSERVICE } from '@pages/test-development/script/scripts/webservice/restapi/constants/constants';
import '../css/default_assert.scss';
import CircleIcon from '@mui/icons-material/Circle';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MySelect from '@src/pages/common/Inputs/MySelect';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';
import CustomCheckBox from '@pages/test-development/script/scripts/webservice/CustomCheckBox';
import cx from 'classnames';

const CheckLayout = ({ children, isOpened, isEnabled, data, handleChange, handleDefaultAssertTitle }) => {
  const IsDataCheck = data.values[0];
  const context = useContext(RestContext);
  return (
    !['Verify XML path', 'Verify response schema'].includes(data?.title) && (
      <div className="flex">
        <div
          className="pl-2 flex items-center  defaultAssertTitleHeader truncate pb-0 mb-2 fontPoppinsRegularMd w-full select-none"
          draggable="false"
        >
          <div
            className={`${context?.values?.defaultAssert?.currentCheckBoxValue === data?.title ? 'default-assert-left-checked-panel' : 'default-assert-left-side-panel'} flex cursor-pointer h-9 items-center `}
            key={`${data?.id}`}
            onClick={() => {
              handleChange();
              handleDefaultAssertTitle();
            }}
          >
            <div className="assert-section-checkbox pl-2">
              <CustomCheckBox
                checked={isEnabled}
                onChange={handleChange}
                className="default-assert-check-box"
                assertCapture={true}
              />
            </div>
            <span
              className="pl-2 select-none fontPoppinsRegularSm truncate default-assert-left-side-panel-text-customize"
              draggable="false"
            >
              {data?.title}
            </span>
            <div>
              {((IsDataCheck?.lhs && IsDataCheck?.rhs) ||
                (data?.title === 'Verify response body' && IsDataCheck?.rhs) ||
                (data?.title === 'Verify response schema' && IsDataCheck?.rhs)) && (
                  <CircleIcon className="default-assert-circle-icon" />
                )}
            </div>
          </div>
        </div>
        {(isEnabled || isOpened) && <div>{children}</div>}
      </div>
    )
  );
};

function DefaultAssert(props) {
  const context = useContext(RestContext);
  const statusCodeDefaultValue = {
    name: 'Status Code',
    lhs: 'statusCode',
    rhs: '',
    operator: '==',
    group: 'Status Code',
    isEnabled: true,
  };
  const verifyStatusCodeInitialvalue = {
    title: WEBSERVICE.DEFAULT_VERIFY_STATUS_CODE,
    isOpened: false,
    isEnabled: false,
    values: [statusCodeDefaultValue],
    ...(context.values.defaultAssert[WEBSERVICE.DEFAULT_VERIFY_STATUS_CODE] || {}),
  };
  const [verifyStatusCode, setVerifyStatusCode] = useState(verifyStatusCodeInitialvalue);
  const responseTimeDefaultValue = {
    name: 'Response Time',
    lhs: 'metaData.apiTime',
    rhs: '',
    operator: '==',
    group: 'Response Time',
    isEnabled: true,
  };
  const responseTimeInitialValue = {
    title: WEBSERVICE.DEFAULT_RESPONSE_TIME,
    isEnabled: false,
    values: [responseTimeDefaultValue],
    ...(context.values.defaultAssert[WEBSERVICE.DEFAULT_RESPONSE_TIME] || {}),
  };
  const [responseTime, setResponseTime] = useState(responseTimeInitialValue);
  const jsonPathDefaultValue = {
    name: 'JSON path',
    lhs: 'responseBody',
    rhs: '',
    operator: '==',
    group: 'JSON Path',
    isEnabled: true,
  };
  const jsonPathInitialValue = {
    title: WEBSERVICE.DEFAULT_VERIFY_JSON_PATH,
    isEnabled: false,
    values: [jsonPathDefaultValue],
    ...(context.values.defaultAssert[WEBSERVICE.DEFAULT_VERIFY_JSON_PATH] || {}),
  };

  const [jsonPath, setJsonPath] = useState(jsonPathInitialValue);
  const xmlPathDefaultValue = { lhs: '', rhs: '', operator: '==', group: 'XML Path', isEnabled: true };
  const xmlPathInitialValue = {
    title: WEBSERVICE.DEFAULT_VERIFY_XML_PATH,
    isEnabled: false,
    values: [xmlPathDefaultValue],
    ...(context.values.defaultAssert[WEBSERVICE.DEFAULT_VERIFY_XML_PATH] || {}),
  };
  const [xmlPath, setXmlPath] = useState(xmlPathInitialValue);
  const headerKeyDefaultValue = {
    name: 'Header Key',
    lhs: '',
    rhs: '',
    operator: '==',
    group: 'Header Key',
    isEnabled: true,
  };
  const headerKeyInitialValue = {
    title: WEBSERVICE.DEFAULT_VERIFY_HEADER_KEY,
    isEnabled: false,
    values: [headerKeyDefaultValue],
    ...(context.values.defaultAssert[WEBSERVICE.DEFAULT_VERIFY_HEADER_KEY] || {}),
  };
  const [headerKey, setHeaderKey] = useState(headerKeyInitialValue);
  const responseDefaultValue = {
    name: 'Response Body',
    lhs: 'responseBody',
    rhs: '',
    operator: '==',
    group: 'Response Body',
    isEnabled: true,
  };
  const responseInitialValue = {
    title: WEBSERVICE.DEFAULT_VERIFY_RESPONSE_BODY,
    isEnabled: false,
    values: [responseDefaultValue],
    ...(context.values.defaultAssert[WEBSERVICE.DEFAULT_VERIFY_RESPONSE_BODY] || {}),
  };
  const [response, setResponse] = useState(responseInitialValue);
  const responseSchemaDefaultValue = { lhs: '', rhs: '', operator: '==', group: 'Response Schema', isEnabled: true };
  const responseSchemaInitialValue = {
    title: WEBSERVICE.DEFAULT_VERIFY_RESPONSE_SCHEMA,
    isEnabled: false,
    values: [responseSchemaDefaultValue],
    ...(context.values.defaultAssert[WEBSERVICE.DEFAULT_VERIFY_RESPONSE_SCHEMA] || {}),
  };
  const [responseSchema, setResponseSchema] = useState(responseSchemaInitialValue);
  const contentTypeDefaultValue = {
    name: 'Content-Type',
    lhs: "(headers[?name=='Content-Type'].value)[0]",
    rhs: '',
    operator: '==',
    group: 'Content-Type',
    isEnabled: true,
  };
  const contentTypeInitialValue = {
    title: WEBSERVICE.DEFAULT_VERIFY_RESPONSE_TYPE,
    isEnabled: false,
    values: [contentTypeDefaultValue],
    ...(context.values.defaultAssert[WEBSERVICE.DEFAULT_VERIFY_RESPONSE_TYPE] || {}),
  };
  const [contentType, setContentType] = useState(contentTypeInitialValue);
  const headerDefaultValue = {
    name: 'Header Value',
    lhs: '',
    rhs: '',
    operator: '==',
    group: 'Header Value',
    isEnabled: true,
  };
  const headerInitialValue = {
    title: WEBSERVICE.DEFAULT_VERIFY_HEADER,
    isEnabled: false,
    values: [headerDefaultValue],
    ...(context.values.defaultAssert[WEBSERVICE.DEFAULT_VERIFY_HEADER] || {}),
  };
  const [header, setHeader] = useState(headerInitialValue);
  const statusMessageDefaultValue = {
    name: 'Status Message',
    lhs: 'statusCodeValue',
    rhs: '',
    operator: '==',
    group: 'Status Message',
    isEnabled: true,
  };
  const verifyStatusMessageInitialvalue = {
    title: WEBSERVICE.DEFAULT_VERIFY_STATUS_MESSAGE,
    isOpened: false,
    isEnabled: false,
    values: [statusMessageDefaultValue],
    ...(context.values.defaultAssert[WEBSERVICE.DEFAULT_VERIFY_STATUS_MESSAGE] || {}),
  };
  const [verifyStatusMessage, setVerifyStatusMessage] = useState(verifyStatusMessageInitialvalue);
  const operators = [
    { label: 'Equals to', value: '==' },
    { label: 'Less than', value: '<' },
    { label: 'Less than or equals to', value: '<=' },
    { label: 'Greater than', value: '>' },
    { label: 'Greater than or equals to', value: '>=' },
    { label: 'Not equals', value: '!=' },
    { label: 'Contains', value: 'substring' },
    { label: 'Starts with', value: 'startsWith' },
    { label: 'Ends with', value: 'endsWith' },
  ];
  const handleDefaultAssertTitleStatus = (values, callback) => {
    const myvalues = {
      isEnabled: !values.isEnabled,
    };
    const updatedMyValues = values.values.filter(
      (items) => myvalues.isEnabled || (!myvalues.isEnabled && items.lhs && items.rhs)
    );
    callback((values) => ({ ...values, ...myvalues }));
    context.setValues((v) => ({
      ...v,
      defaultAssert: {
        ...(v.defaultAssert || {}),
        [values.title]: { ...values, ...values.isEnabled, isEnabled: !values.isEnabled, values: updatedMyValues },
        currentCheckBoxValue: values.title,
      },
    }));
    props.handleSaveAsSteps(false);
  };

  const handleDefaultAssertTitle = (values, callback) => {
    const myvalues = {
      isEnabled: true,
      isOpened: true,
    };
    callback((values) => ({ ...values, ...myvalues }));
    context.setValues((v) => ({
      ...v,
      defaultAssert: {
        ...(v.defaultAssert || {}),
        [values.title]: { ...values, ...myvalues },
        currentCheckBoxValue: values.title,
      },
    }));
    props.handleSaveAsSteps(false);
  };

  const handleAdd = (callback, value = { id: '' }, values) => {
    const myvalues = [...values.values];
    myvalues.push(value);
    const updatedMyValues = myvalues.filter(
      (items) => values.isEnabled || (!values.isEnabled && items.lhs && items.rhs)
    );
    context.setValues((v) => ({
      ...v,
      defaultAssert: { ...(v.defaultAssert || {}), [values.title]: { ...values, values: updatedMyValues } },
    }));
    callback((v) => ({ ...v, values: [...v.values, value] }));
    props.handleSaveAsSteps(false);
    props.validateNameField(myvalues, value?.name, myvalues?.length - 1);
  };
  const handleArrayValues = (values, callback, fieldName, e, index) => {
    const { value, data, action, variableId } = e.target || 'unknown';
    let fieldValue = '';
    let userValue = value;
    if (e?.target?.variableId?.includes('undefined')) {
      fieldValue = value;
    } else {
      fieldValue = variableId || value;
    }
    fieldValue = props.getUserInputValue(fieldValue, false, data, action);
    if (fieldName === 'lhs' && values.title === 'Verify JSON path') {
      let fixedValue = 'responseBody';
      if (fieldValue.indexOf(fixedValue) !== 0) {
        fieldValue = fixedValue;
      }
    }

    if (fieldName === 'lhs' && values.title === 'Verify header value') {
      fieldValue = userValue;
      fieldValue = `(headers[?name=='${fieldValue}'].value)[0]`;
    }

    const myvalues = values.values.map((value, i) => {
      if (fieldName === 'lhs' && values.title === 'Verify header key') {
        let fieldLhs = `(headers[?name=='${fieldValue}'].name)[0]`;
        return i === index ? { ...value, [fieldName]: fieldLhs, rhs: fieldValue } : value;
      } else {
        return i === index ? { ...value, [fieldName]: fieldValue } : value;
      }
    });
    const updatedMyValues = myvalues.filter(
      (items) => values.isEnabled || (!values.isEnabled && items.lhs && items.rhs)
    );
    context.setValues((v) => ({
      ...v,
      defaultAssert: { ...(v.defaultAssert || {}), [values.title]: { ...values, values: updatedMyValues } },
    }));
    callback((v) => ({ ...v, values: myvalues }));
    props.handleSaveAsSteps(false);
    context.setFieldValue('currentDefaultAssertValue', e);
    if (fieldName === 'name') {
      props.validateNameField(values?.values, fieldValue, index);
    }
  };
  const handleRemove = (values, callback, id, initialValues) => {
    let currentValue = { ...values };
    let myvalues;
    if (values.values.length === 1 && id === 0) {
      myvalues = [initialValues];
    } else {
      myvalues = values.values.filter((item, idx) => idx !== id);
    }
    currentValue['values'] = [...myvalues];
    context.setValues((v) => ({
      ...v,
      defaultAssert: { ...(v.defaultAssert || {}), [values.title]: { ...values, ...currentValue } },
    }));
    callback((v) => ({ ...v, ...currentValue }));
    props.handleSaveAsSteps(false);
    props.validateNameField(myvalues, myvalues[id]?.name, id, 'Delete');
  };

  const getAllOperator = () => {
    let operatorList = operators.map((value, index) => (
      <MenuItem key={index} value={value.value} sx={{ height: '30px' }}>
        {value.label}
      </MenuItem>
    ));
    return operatorList;
  };

  const [responceTime, setResponceTime] = useState();
  const [checkResponce, setCheckResponce] = useState();
  const [jsonPathValue, setJsonPathValue] = useState();
  const [CheckjsonPath, setCheckjsonPath] = useState();
  const [headerKeyValue, setHeaderKeyValue] = useState();
  const [checkheaderKey, setCheckheaderKey] = useState();
  const [contentValue, setContentValue] = useState();
  const [checkContent, setCheckContent] = useState();
  const [headerVerify, setHeaderVerify] = useState();
  const [checkheader, setCheckheader] = useState();
  const [jsonLhs, setJsonLhs] = useState();
  const [hKey, setHkey] = useState();
  const [verifyHeaderLhs, setVerifyHeaderLhs] = useState();
  const [val, setVal] = useState();
  const [selectedDropdown, setSelectedDropdown] = useState(false);
  const handleClickSelectedDropdown = () => {
    setSelectedDropdown(!selectedDropdown);
  };

  const getStepsUpdate = () => {
    if (Object.keys(props.selectedStepData).length > 0) {
      const tempParmvalue = props.selectedStepData;
      const getStatusCode = [];
      const getResponseTimeValue = [];
      const getJsonPathValue = [];
      const getXmlPathValue = [];
      const getHeaderKeyValue = [];
      const getResponseValue = [];
      const getResponseSchemaValue = [];
      const getContentTypeValue = [];
      const getHeaderValue = [];
      const getStatusMessage = [];
      const setDefaultAssertData = {};
      if (tempParmvalue?.basicAssertions?.length > 0) {
        let isDefaultCheck = true;
        tempParmvalue?.basicAssertions.map((basicAssertion) => {
          let getBasicAssertion = {
            name: basicAssertion.name,
            lhs: basicAssertion.lhs,
            rhs: basicAssertion.rhs,
            operator: basicAssertion.operator,
            isEnabled: basicAssertion.isEnabled,
            group: basicAssertion.group,
          };
          if (basicAssertion.group === 'Status Code') {
            if (basicAssertion.isEnabled && isDefaultCheck) {
              setDefaultAssertData['currentCheckBoxValue'] = verifyStatusCode.title;
              isDefaultCheck = false;
            }
            getStatusCode.push(getBasicAssertion);
            setVerifyStatusCode({
              ...verifyStatusCode,
              ...verifyStatusCode.isEnabled,
              isEnabled: basicAssertion.isEnabled,
              values: getStatusCode,
            });

            setDefaultAssertData[verifyStatusCode.title] = {
              isEnabled: basicAssertion.isEnabled,
              isOpened: true,
              title: verifyStatusCode.title,
              values: getStatusCode,
            };
          }
          if (basicAssertion.group === 'Status Message') {
            if (basicAssertion.isEnabled && isDefaultCheck) {
              setDefaultAssertData['currentCheckBoxValue'] = verifyStatusMessage.title;
              isDefaultCheck = false;
            }
            getStatusMessage.push(getBasicAssertion);
            setVerifyStatusMessage({
              ...verifyStatusMessage,
              ...verifyStatusMessage.isEnabled,
              isEnabled: basicAssertion.isEnabled,
              values: getStatusMessage,
            });
            setDefaultAssertData[verifyStatusMessage.title] = {
              isEnabled: basicAssertion.isEnabled,
              isOpened: true,
              title: verifyStatusMessage.title,
              values: getStatusMessage,
            };
          }
          if (basicAssertion.group === 'Response Time') {
            if (basicAssertion.isEnabled && isDefaultCheck) {
              setDefaultAssertData['currentCheckBoxValue'] = responseTime.title;
              isDefaultCheck = false;
            }
            getResponseTimeValue.push(getBasicAssertion);
            setResponseTime({
              ...responseTime,
              ...responseTime.isEnabled,
              isEnabled: basicAssertion.isEnabled,
              values: getResponseTimeValue,
            });
            setDefaultAssertData[responseTime.title] = {
              isEnabled: basicAssertion.isEnabled,
              isOpened: true,
              title: responseTime.title,
              values: getResponseTimeValue,
            };
          }

          if (basicAssertion.group === 'JSON Path') {
            if (basicAssertion.isEnabled && isDefaultCheck) {
              setDefaultAssertData['currentCheckBoxValue'] = jsonPath.title;
              isDefaultCheck = false;
            }
            getJsonPathValue.push(getBasicAssertion);
            setJsonPath({
              ...jsonPath,
              ...jsonPath.isEnabled,
              isEnabled: basicAssertion.isEnabled,
              values: getJsonPathValue,
            });
            setDefaultAssertData[jsonPath.title] = {
              isEnabled: basicAssertion.isEnabled,
              isOpened: true,
              title: jsonPath.title,
              values: getJsonPathValue,
            };
          }

          if (basicAssertion.group === 'XML Path') {
            if (basicAssertion.isEnabled && isDefaultCheck) {
              setDefaultAssertData['currentCheckBoxValue'] = xmlPath.title;
              isDefaultCheck = false;
            }
            getXmlPathValue.push(getBasicAssertion);
            setXmlPath({
              ...xmlPath,
              ...xmlPath.isEnabled,
              isEnabled: basicAssertion.isEnabled,
              values: getXmlPathValue,
            });
            setDefaultAssertData[xmlPath.title] = {
              isEnabled: basicAssertion.isEnabled,
              isOpened: true,
              title: xmlPath.title,
              values: getXmlPathValue,
            };
          }

          if (basicAssertion.group === 'Header Key') {
            if (basicAssertion.isEnabled && isDefaultCheck) {
              setDefaultAssertData['currentCheckBoxValue'] = headerKey.title;
              isDefaultCheck = false;
            }
            getHeaderKeyValue.push(getBasicAssertion);
            setHeaderKey({
              ...headerKey,
              ...headerKey.isEnabled,
              isEnabled: basicAssertion.isEnabled,
              values: getHeaderKeyValue,
            });
            setDefaultAssertData[headerKey.title] = {
              isEnabled: basicAssertion.isEnabled,
              isOpened: true,
              title: headerKey.title,
              values: getHeaderKeyValue,
            };
          }
          if (basicAssertion.group === 'Response Body') {
            if (basicAssertion.isEnabled && isDefaultCheck) {
              setDefaultAssertData['currentCheckBoxValue'] = response.title;
              isDefaultCheck = false;
            }
            getResponseValue.push(getBasicAssertion);
            setResponse({
              ...response,
              ...response.isEnabled,
              isEnabled: basicAssertion.isEnabled,
              values: getResponseValue,
            });
            setDefaultAssertData[response.title] = {
              isEnabled: basicAssertion.isEnabled,
              isOpened: true,
              title: response.title,
              values: getResponseValue,
            };
          }
          if (basicAssertion.group === 'Response Schema') {
            if (basicAssertion.isEnabled && isDefaultCheck) {
              setDefaultAssertData['currentCheckBoxValue'] = responseSchema.title;
              isDefaultCheck = false;
            }
            getResponseSchemaValue.push(getBasicAssertion);
            setResponseSchema({
              ...responseSchema,
              ...responseSchema.isEnabled,
              isEnabled: basicAssertion.isEnabled,
              values: getResponseSchemaValue,
            });
            setDefaultAssertData[responseSchema.title] = {
              isEnabled: basicAssertion.isEnabled,
              isOpened: true,
              title: responseSchema.title,
              values: getResponseSchemaValue,
            };
          }
          if (basicAssertion.group === 'Content-Type') {
            if (basicAssertion.isEnabled && isDefaultCheck) {
              setDefaultAssertData['currentCheckBoxValue'] = contentType.title;
              isDefaultCheck = false;
            }
            getContentTypeValue.push(getBasicAssertion);
            setContentType({
              ...contentType,
              ...contentType.isEnabled,
              isEnabled: basicAssertion.isEnabled,
              values: getContentTypeValue,
            });
            setDefaultAssertData[contentType.title] = {
              isEnabled: basicAssertion.isEnabled,
              isOpened: true,
              title: contentType.title,
              values: getContentTypeValue,
            };
          }
          if (basicAssertion.group === 'Header Value') {
            if (basicAssertion.isEnabled && isDefaultCheck) {
              setDefaultAssertData['currentCheckBoxValue'] = header.title;
              isDefaultCheck = false;
            }
            getHeaderValue.push(getBasicAssertion);
            setHeader({ ...header, ...header.isEnabled, isEnabled: basicAssertion.isEnabled, values: getHeaderValue });
            setDefaultAssertData[header.title] = {
              isEnabled: basicAssertion.isEnabled,
              isOpened: true,
              title: header.title,
              values: getHeaderValue,
            };
          }
        });
        context.values.defaultAssert = setDefaultAssertData;
      }
    }
  };
  useEffect(() => {
    getStepsUpdate();
  }, [props.selectedStepData]);
  const responceTimeOnchange = (e) => {
    if (responceTime) {
      setResponceTime(e.target.value);
      setCheckResponce(true);
    }
  };
  const jsonPathOnchange = (e) => {
    if (jsonPathValue) {
      setJsonPathValue(e.target.value);
      setCheckjsonPath(true);
    }
  };
  const headerOnchange = (e) => {
    if (headerKeyValue) {
      setHeaderKeyValue(e.target.value);
      setCheckheaderKey(true);
    }
  };
  const ContentOnchange = (e) => {
    if (contentValue) {
      setContentValue(e.target.value);
      setCheckContent(true);
    }
  };
  const headerVerifyOnchange = (e) => {
    if (headerVerify) {
      setHeaderVerify(e.target.value);
      setCheckheader(true);
    }
  };
  const jsonLhsOnchange = (e) => {
    if (jsonLhs) {
      setJsonLhs(e.target.value);
      setCheckjsonPath(true);
    }
    if (jsonLhs?.length < 2) {
      jsonPath.isEnabled = true;
    }
  };

  const lhsHeaderOnchange = (e) => {
    if (verifyHeaderLhs) {
      setVerifyHeaderLhs(e.target.value);
      setCheckheader(true);
    }
  };
  const handleChildElementClick = (e) => {
    e.stopPropagation();
  };
  return (
    <div className="assert-data-scrollbar-customize flex gap-1">
      <div className="w-1/5 assert-left-scrollbar  pt-0.5">
        <CheckLayout
          data={verifyStatusCode}
          isEnabled={verifyStatusCode.isEnabled}
          isOpened={verifyStatusCode.isOpened}
          handleDefaultAssertTitle={() => handleDefaultAssertTitle(verifyStatusCode, setVerifyStatusCode)}
          handleChange={() => handleDefaultAssertTitleStatus(verifyStatusCode, setVerifyStatusCode)}
        >
          {context?.values?.defaultAssert?.currentCheckBoxValue === 'Verify status code' && (
            <VerifyStatusCodeComponent
              verifyStatusCode={verifyStatusCode}
              handleArrayValues={(fieldName, fieldValue, index) =>
                handleArrayValues(verifyStatusCode, setVerifyStatusCode, fieldName, fieldValue, index)
              }
              handleChildElementClick={handleChildElementClick}
              props={props}
              operators={operators}
              getAllOperator={getAllOperator}
              selectedDropdown={selectedDropdown}
              setSelectedDropdown={setSelectedDropdown}
              handleClickSelectedDropdown={handleClickSelectedDropdown}
            />
          )}
        </CheckLayout>
        <CheckLayout
          data={verifyStatusMessage}
          isEnabled={verifyStatusMessage.isEnabled}
          isOpened={verifyStatusMessage.isOpened}
          handleDefaultAssertTitle={() => handleDefaultAssertTitle(verifyStatusMessage, setVerifyStatusMessage)}
          handleChange={() => handleDefaultAssertTitleStatus(verifyStatusMessage, setVerifyStatusMessage)}
        >
          {context?.values?.defaultAssert?.currentCheckBoxValue === 'Verify status message' && (
            <VerifyStatusMessageComponent
              verifyStatusMessage={verifyStatusMessage}
              handleArrayValues={(fieldName, fieldValue, index) =>
                handleArrayValues(verifyStatusMessage, setVerifyStatusMessage, fieldName, fieldValue, index)
              }
              handleChildElementClick={handleChildElementClick}
              props={props}
              operators={operators}
              getAllOperator={getAllOperator}
              selectedDropdown={selectedDropdown}
              setSelectedDropdown={setSelectedDropdown}
              handleClickSelectedDropdown={handleClickSelectedDropdown}
            />
          )}
        </CheckLayout>
        <CheckLayout
          data={responseTime}
          isEnabled={responceTime ? !responseTime.isEnabled : responseTime.isEnabled}
          isOpened={responseTime.isOpened || checkResponce}
          handleDefaultAssertTitle={() => handleDefaultAssertTitle(responseTime, setResponseTime)}
          handleChange={() => handleDefaultAssertTitleStatus(responseTime, setResponseTime)}
        >
          {context?.values?.defaultAssert?.currentCheckBoxValue === 'Verify response time' && (
            <ResponseTime
              val={val}
              responceTime={responceTime}
              responceTimeOnchange={responceTimeOnchange}
              handleArrayValues={(fieldName, fieldValue, index) =>
                handleArrayValues(responseTime, setResponseTime, fieldName, fieldValue, index)
              }
              responseTime={responseTime}
              handleAdd={() => handleAdd(setResponseTime, responseTimeDefaultValue, responseTime)}
              handleRemove={(id) => handleRemove(responseTime, setResponseTime, id, responseTimeDefaultValue)}
              handleChildElementClick={handleChildElementClick}
              props={props}
              operators={operators}
              getAllOperator={getAllOperator}
              selectedDropdown={selectedDropdown}
              setSelectedDropdown={setSelectedDropdown}
              handleClickSelectedDropdown={handleClickSelectedDropdown}
              responseTimeDefaultValue={responseTimeDefaultValue}
              setResponseTime={setResponseTime}
            />
          )}
        </CheckLayout>
        <CheckLayout
          data={jsonPath}
          isEnabled={jsonLhs ? !jsonPath.isEnabled : jsonPath.isEnabled}
          isOpened={jsonPath.isOpened || CheckjsonPath}
          handleDefaultAssertTitle={() => handleDefaultAssertTitle(jsonPath, setJsonPath)}
          handleChange={() => handleDefaultAssertTitleStatus(jsonPath, setJsonPath)}
        >
          {context?.values?.defaultAssert?.currentCheckBoxValue === 'Verify JSON path' && (
            <JsonPath
              jsonLhs={jsonLhs}
              setJsonPath={setJsonPath}
              jsonLhsOnchange={jsonLhsOnchange}
              jsonPathValue={jsonPathValue}
              jsonPathOnchange={jsonPathOnchange}
              handleArrayValues={(fieldName, fieldValue, index) =>
                handleArrayValues(jsonPath, setJsonPath, fieldName, fieldValue, index)
              }
              jsonPath={jsonPath}
              handleAdd={() => handleAdd(setJsonPath, jsonPathDefaultValue, jsonPath)}
              handleRemove={(id) => handleRemove(jsonPath, setJsonPath, id, jsonPathDefaultValue)}
              handleChildElementClick={handleChildElementClick}
              props={props}
              operators={operators}
              getAllOperator={getAllOperator}
              selectedDropdown={selectedDropdown}
              setSelectedDropdown={setSelectedDropdown}
              handleClickSelectedDropdown={handleClickSelectedDropdown}
              jsonPathDefaultValue={jsonPathDefaultValue}
            />
          )}
        </CheckLayout>
        <CheckLayout
          data={xmlPath}
          isEnabled={xmlPath.isEnabled}
          isOpened={xmlPath.isOpened}
          handleDefaultAssertTitle={() => handleDefaultAssertTitle(xmlPath, setXmlPath)}
          handleChange={() => handleDefaultAssertTitleStatus(xmlPath, setXmlPath)}
        >
          {context?.values?.defaultAssert?.currentCheckBoxValue === 'Verify XML path' && (
            <XmlPath
              handleArrayValues={(fieldName, fieldValue, index) =>
                handleArrayValues(xmlPath, setXmlPath, fieldName, fieldValue, index)
              }
              xmlPath={xmlPath}
              handleAdd={() => handleAdd(setXmlPath, xmlPathDefaultValue)}
              handleRemove={(id) => handleRemove(xmlPath, setXmlPath, id)}
              handleChildElementClick={handleChildElementClick}
              getAllOperator={getAllOperator}
            />
          )}
        </CheckLayout>
        <CheckLayout
          data={headerKey}
          isEnabled={headerKeyValue ? !headerKey.isEnabled : headerKey.isEnabled}
          isOpened={headerKey.isOpened || checkheaderKey}
          handleDefaultAssertTitle={() => handleDefaultAssertTitle(headerKey, setHeaderKey)}
          handleChange={() => handleDefaultAssertTitleStatus(headerKey, setHeaderKey)}
        >
          {context?.values?.defaultAssert?.currentCheckBoxValue === 'Verify header key' && (
            <HeaderKey
              hKey={hKey}
              headerKeyValue={headerKeyValue}
              headerOnchange={headerOnchange}
              handleArrayValues={(fieldName, fieldValue, index) =>
                handleArrayValues(headerKey, setHeaderKey, fieldName, fieldValue, index)
              }
              headerKey={headerKey}
              setHeaderKey={setHeaderKey}
              handleAdd={() => handleAdd(setHeaderKey, headerKeyDefaultValue, headerKey)}
              handleRemove={(id) => handleRemove(headerKey, setHeaderKey, id, headerKeyDefaultValue)}
              handleChildElementClick={handleChildElementClick}
              props={props}
              operators={operators}
              getAllOperator={getAllOperator}
              selectedDropdown={selectedDropdown}
              setSelectedDropdown={setSelectedDropdown}
              handleClickSelectedDropdown={handleClickSelectedDropdown}
              headerKeyDefaultValue={headerKeyDefaultValue}
            />
          )}
        </CheckLayout>
        <CheckLayout
          data={response}
          isEnabled={response.isEnabled}
          isOpened={response.isOpened}
          handleDefaultAssertTitle={() => handleDefaultAssertTitle(response, setResponse)}
          handleChange={() => handleDefaultAssertTitleStatus(response, setResponse)}
        >
          {context?.values?.defaultAssert?.currentCheckBoxValue === 'Verify response body' && (
            <Response
              handleArrayValues={(fieldName, fieldValue, index) =>
                handleArrayValues(response, setResponse, fieldName, fieldValue, index)
              }
              response={response}
              handleAdd={() => handleAdd(setResponse, responseDefaultValue, response)}
              handleRemove={(id) => handleRemove(response, setResponse, id, responseDefaultValue)}
              handleChildElementClick={handleChildElementClick}
              props={props}
              operators={operators}
              getAllOperator={getAllOperator}
              selectedDropdown={selectedDropdown}
              setSelectedDropdown={setSelectedDropdown}
              handleClickSelectedDropdown={handleClickSelectedDropdown}
              setResponse={setResponse}
              responseDefaultValue={responseDefaultValue}
            />
          )}
        </CheckLayout>
        <CheckLayout
          data={responseSchema}
          isEnabled={responseSchema.isEnabled}
          isOpened={responseSchema.isOpened}
          handleDefaultAssertTitle={() => handleDefaultAssertTitle(responseSchema, setResponseSchema)}
          handleChange={() => handleDefaultAssertTitleStatus(responseSchema, setResponseSchema)}
        >
          {context?.values?.defaultAssert?.currentCheckBoxValue === 'Verify response schema' && (
            <ResponseSchema
              handleArrayValues={(fieldName, fieldValue, index) =>
                handleArrayValues(responseSchema, setResponseSchema, fieldName, fieldValue, index)
              }
              responseSchema={responseSchema}
              handleAdd={() => handleAdd(setResponseSchema, responseSchemaDefaultValue)}
              handleRemove={(id) => handleRemove(responseSchema, setResponseSchema, id)}
              handleChildElementClick={handleChildElementClick}
              getAllOperator={getAllOperator}
            />
          )}
        </CheckLayout>
        <CheckLayout
          data={contentType}
          isEnabled={contentValue ? checkContent : contentType.isEnabled}
          isOpened={contentType.isOpened || checkContent}
          handleDefaultAssertTitle={() => handleDefaultAssertTitle(contentType, setContentType)}
          handleChange={() => handleDefaultAssertTitleStatus(contentType, setContentType)}
        >
          {context?.values?.defaultAssert?.currentCheckBoxValue === 'Verify content type' && (
            <ContentType
              contentValue={contentValue}
              ContentOnchange={ContentOnchange}
              handleArrayValues={(fieldName, fieldValue, index) =>
                handleArrayValues(contentType, setContentType, fieldName, fieldValue, index)
              }
              contentType={contentType}
              handleAdd={() => handleAdd(setContentType, contentTypeDefaultValue, contentType)}
              handleRemove={(id) => handleRemove(contentType, setContentType, id, contentTypeDefaultValue)}
              handleChildElementClick={handleChildElementClick}
              props={props}
              operators={operators}
              getAllOperator={getAllOperator}
              selectedDropdown={selectedDropdown}
              setSelectedDropdown={setSelectedDropdown}
              handleClickSelectedDropdown={handleClickSelectedDropdown}
              setContentType={setContentType}
              contentTypeDefaultValue={contentTypeDefaultValue}
            />
          )}
        </CheckLayout>
        <CheckLayout
          data={header}
          isEnabled={headerVerify ? !header.isEnabled : header.isEnabled}
          isOpened={header.isOpened || checkheader}
          handleDefaultAssertTitle={() => handleDefaultAssertTitle(header, setHeader)}
          handleChange={() => handleDefaultAssertTitleStatus(header, setHeader)}
        >
          {context?.values?.defaultAssert?.currentCheckBoxValue === 'Verify header value' && (
            <Header
              setHeader={setHeader}
              verifyHeaderLhs={verifyHeaderLhs}
              lhsHeaderOnchange={lhsHeaderOnchange}
              headerVerify={headerVerify}
              headerVerifyOnchange={headerVerifyOnchange}
              handleArrayValues={(fieldName, fieldValue, index) =>
                handleArrayValues(header, setHeader, fieldName, fieldValue, index)
              }
              header={header}
              handleAdd={() => handleAdd(setHeader, headerDefaultValue, header)}
              handleRemove={(id) => handleRemove(header, setHeader, id, headerDefaultValue)}
              handleChildElementClick={handleChildElementClick}
              props={props}
              operators={operators}
              getAllOperator={getAllOperator}
              selectedDropdown={selectedDropdown}
              setSelectedDropdown={setSelectedDropdown}
              handleClickSelectedDropdown={handleClickSelectedDropdown}
              headerDefaultValue={headerDefaultValue}
            />
          )}
        </CheckLayout>
      </div>
      <div className="w-4/5 flex items-center justify-center bg-white default-assert-section-container-main">
        <div className=" w-full flex flex-col items-center request-body-none-center   fontPoppinsRegularLg relative ">
          {!context?.values?.defaultAssert?.currentCheckBoxValue && (
            <div className="flex flex-col default-template pr-12 pl-12 items-center border border-gray-600">
              <div className="mt-7">No Default Assert is selected!</div>
              <div className="mt-0.5 mr-7 mb-7 ml-7">Please click on any 'Default Assert' to add the assert.</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
const ResponseTime = ({
  responseTime,
  handleAdd,
  handleRemove,
  handleArrayValues,
  responceTimeOnchange,
  handleChildElementClick,
  props,
  getAllOperator,
}) => {
  const fields = responseTime.values;
  const context = useContext(RestContext);
  return (
    <div className="sections-main-container w-full pr-3 ">
      <div className="w-1/5"></div>
      <div onClick={handleChildElementClick} className="default-assert-data">
        <div className="header-default-assert mb-4 pl-2.5 fontPoppinsRegularSm">Verify response time</div>
        {responseTime.values.map((field, idx) => {
          const disableDeleteIcon = !field.rhs && fields?.length === 1;
          const responseTimeErrorKey = field?.group.split(' ').join('') + idx + 'Name';
          const responseTimeErrorValue = props?.defaultAssertErrors?.[responseTimeErrorKey];
          return (
            <div key={`${field}-${idx}`} className="flex relative items-center h-9 mb-5 mr-14">
              <div className="default-assert-field-header-key">
                <MyInput
                  type="text"
                  className={'verify-status-specific-class-select'}
                  placeholder="*Enter name"
                  name="name"
                  id="name"
                  onChange={(e) => handleArrayValues(e.target.name, e, idx)}
                  variableInput={false}
                  autoComplete="off"
                  value={props.getUserInputValue(field?.name, true)}
                />
                {responseTimeErrorValue && (
                  <div className="assert-section-errors absolute">{responseTimeErrorValue}</div>
                )}
              </div>
              <div className="verify-status-code-header-key">
                <Tooltip title={''} placement="bottom">
                  <MySelect
                    value={field.operator}
                    name="operator"
                    onChange={(e) => handleArrayValues(e.target.name, e, idx)}
                    variableInput={'defaultAssert'}
                    verticalAnchor={'bottom'}
                    verticalTransform={'top'}
                    className={'verify-status-specific-class-select'}
                    horizontalTransform={'left'}
                    horizontalAnchor={'left'}
                    sxPadding={'0px 1px 0px 5px'}
                    marginTop={'3px'}
                    fullWidth
                    minWidth={'240px'}
                    MenuProps={{
                      PaperProps: {
                        className: 'default-assert-operators-list ',
                      },
                    }}
                  >
                    {getAllOperator()}
                  </MySelect>
                </Tooltip>
              </div>
              <div className="flex default-assert-field-header-key relative flex-1">
                <MyInput
                  type="text"
                  placeholder="Enter time in Milliseconds"
                  value={props.getUserInputValue(field?.rhs, true)}
                  autoComplete="off"
                  name="rhs"
                  id="rhs"
                  autoFocus={true}
                  onChange={(e) => {
                    handleArrayValues(e.target.name, e, idx);
                    responceTimeOnchange(e);
                  }}
                  variableInput={true}
                  currentValue={context.values.currentDefaultAssertValue}
                  {...props}
                />
              </div>
              <div className="-ml-5">
                <CustomTooltip title={'Delete'} placement="bottom">
                  <span
                    className={cx('cursor-pointer', '-ml-3', {
                      'text-gray-300': disableDeleteIcon,
                      'Data text-gray-500 assert-cdfr-delete-enable-effect': !disableDeleteIcon,
                    })}
                  >
                    <DeleteIcon
                      type="button"
                      onClick={() => {
                        handleRemove(idx);
                      }}
                      disabled={disableDeleteIcon}
                    />
                  </span>
                </CustomTooltip>
              </div>
              {fields.length - 1 === idx && (
                <div className="">
                  <CustomTooltip title="Add">
                    <button
                      type="button"
                      className={` ${!field.rhs || !field.name ? 'text-gray-300 p-0.5' : 'assert-cdfr-add-enable-effect text-gray-500'} cursor-pointer ml-1`}
                      onClick={handleAdd}
                      disabled={!field.rhs || !field.name}
                    >
                      <AddIcon />
                    </button>
                  </CustomTooltip>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ContentType = ({
  contentType,
  handleAdd,
  handleRemove,
  handleArrayValues,
  contentValue,
  ContentOnchange,
  handleChildElementClick,
  props,
  getAllOperator,
}) => {
  const fields = contentType.values;
  const context = useContext(RestContext);
  return (
    <div className="sections-main-container w-full pr-3 ">
      <div className="w-1/5"></div>
      <div onClick={handleChildElementClick} className="default-assert-data">
        <div className="header-default-assert mb-4 pl-2.5 fontPoppinsRegularSm">Verify content type</div>
        {contentType.values.map((field, idx) => {
          const contentTypeErrorKey = field?.group.split(' ').join('') + idx + 'Name';
          const contentTypeErrorValue = props?.defaultAssertErrors?.[contentTypeErrorKey];
          return (
            <div key={`${field}-${idx}`} className="flex relative items-center h-9 mb-5 mr-14">
              <div className="default-assert-field-header-key">
                <MyInput
                  type="text"
                  className={'verify-status-specific-class-select'}
                  placeholder="*Enter name"
                  name="name"
                  id="name"
                  onChange={(e) => handleArrayValues(e.target.name, e, idx)}
                  variableInput={false}
                  autoComplete="off"
                  value={props.getUserInputValue(field?.name, true)}
                />
                {contentTypeErrorValue && <div className="assert-section-errors absolute">{contentTypeErrorValue}</div>}
              </div>
              <div className="verify-status-code-header-key">
                <Tooltip title={''} placement="bottom">
                  <MySelect
                    variableInput={'defaultAssert'}
                    verticalAnchor={'bottom'}
                    verticalTransform={'top'}
                    horizontalTransform={'left'}
                    horizontalAnchor={'left'}
                    className={'verify-status-specific-class-select'}
                    marginTop={'3px'}
                    fullWidth
                    minWidth={'240px'}
                    sxPadding={'0px 1px 0px 5px'}
                    value={field.operator}
                    name="operator"
                    onChange={(e) => handleArrayValues(e.target.name, e, idx)}
                    MenuProps={{
                      PaperProps: {
                        className: 'default-assert-operators-list ',
                      },
                    }}
                  >
                    {getAllOperator()}
                  </MySelect>
                </Tooltip>
              </div>
              <div className="flex default-assert-field-header-key relative flex-1">
                <MyInput
                  type="text"
                  onChange={(e) => {
                    handleArrayValues(e.target.name, e, idx);
                    ContentOnchange(e);
                  }}
                  value={contentValue ? contentValue : props.getUserInputValue(field.rhs, true)}
                  placeholder="Enter expected content type"
                  name="rhs"
                  autoComplete="off"
                  id="rhs"
                  autoFocus={true}
                  variableInput={true}
                  currentValue={context.values.currentDefaultAssertValue}
                  {...props}
                />
              </div>
              <div className="-ml-5">
                <CustomTooltip title={'Delete'} placement="bottom">
                  <span
                    className={`${!field.rhs && fields?.length === 1 ? 'text-gray-300' : 'Data text-gray-500 assert-cdfr-delete-enable-effect'} cursor-pointer  -ml-3`}
                  >
                    <DeleteIcon
                      type="button"
                      onClick={() => {
                        handleRemove(idx);
                      }}
                      disabled={!field.rhs}
                    />
                  </span>
                </CustomTooltip>
              </div>
              {fields.length - 1 === idx && (
                <div className="">
                  <CustomTooltip title="Add">
                    <button
                      type="button"
                      className={` ${!field.rhs || !field.name ? 'text-gray-300 p-0.5' : 'assert-cdfr-add-enable-effect text-gray-500'} cursor-pointer ml-3`}
                      onClick={handleAdd}
                      disabled={!field.rhs || !field.name}
                    >
                      <AddIcon />
                    </button>
                  </CustomTooltip>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
const ResponseSchema = ({ responseSchema, handleAdd, handleRemove, handleArrayValues, handleChildElementClick }) => {
  const fields = responseSchema.values;

  return (
    <>
      {responseSchema.values.map((field, idx) => {
        return (
          <>
            <div
              onClick={handleChildElementClick}
              key={`${field}-${idx}`}
              className="flex flex-row relative left-72 bottom-56"
            >
              {' '}
              <Tooltip title={''} placement="bottom">
                <select
                  value={field.operator}
                  className={` selectButton w-60 default-assert-common-select-style`}
                  name="operator"
                  onChange={(e) => handleArrayValues(e.target.name, e.target.value, idx)}
                >
                  <option value="==">Equals to</option>
                  <option value="<">Less than </option>
                  <option value="<=">Less than or equals to</option>
                  <option value=">">Greater than</option>
                  <option value=">=">Greater than or equals to</option>
                  <option value="!=">Not equals</option>
                  <option value="substring">Contains</option>
                  <option value="startsWith">Starts with</option>
                  <option value="endsWith">Ends with</option>
                </select>
              </Tooltip>
              <MyInput
                className=" w-60 response-schema-input"
                type="text"
                onChange={(e) => handleArrayValues(e.target.name, e.target.value, idx)}
                value={field.rhs}
                placeholder="Enter expected schema"
                name="rhs"
                autoComplete="off"
                id="rhs"
                autoFocus={true}
              />{' '}
              <button
                type="button"
                className={`${!field.rhs ? 'text-gray-300' : 'Data text-gray-500 assert-cdfr-delete-enable-effect'} cursor-pointer relative left-96 -mt-3`}
                hidden={fields.length <= 1}
                onClick={() => handleRemove(idx)}
              >
                <img src="/assets/images/close_black.svg" alt="" height="20px" width="20px" draggable="false" />
              </button>
              {fields.length - 1 == idx && (
                <button
                  type="button"
                  className={` ${!field.rhs ? 'text-gray-300' : 'assert-cdfr-add-enable-effect text-gray-500'} relative plus-symbol-for-two-fields cursor-pointer -ml-2 mt-2`}
                  onClick={handleAdd}
                  disabled={!field.rhs}
                >
                  <img src="/assets/images/add_black.svg" alt="" height="20px" width="20px" draggable="false" />
                </button>
              )}
            </div>
            <div className="h-8 flex flex-row relative left-72 bottom-16 space-for-two-fields"></div>
          </>
        );
      })}
    </>
  );
};

const Header = ({
  header,
  handleAdd,
  handleRemove,
  handleArrayValues,
  headerVerify,
  headerVerifyOnchange,
  lhsHeaderOnchange,
  handleChildElementClick,
  props,
  getAllOperator,
}) => {
  const fields = header.values;
  const context = useContext(RestContext);
  return (
    <div className="sections-main-container w-full pr-3 ">
      <div className="w-1/5"></div>
      <div onClick={handleChildElementClick} className="default-assert-data">
        <div className="header-default-assert mb-4 pl-2.5 fontPoppinsRegularSm">Verify header value</div>

        {header.values.map((field, idx) => {
          const checkLhsField = !field.lhs.substring(17, field.lhs.length - 12);
          const headerValueErrorKey = field?.group.split(' ').join('') + idx + 'Name';
          const headerValueErrorValue = props?.defaultAssertErrors?.[headerValueErrorKey];
          return (
            <div key={`${field}-${idx}`} className="flex relative items-center h-9 mb-5 mr-14 ml-1.5">
              <div className="default-assert-field-json-name">
                <MyInput
                  type="text"
                  className={'verify-status-specific-class-select'}
                  placeholder="*Enter name"
                  name="name"
                  id="name"
                  onChange={(e) => handleArrayValues(e.target.name, e, idx)}
                  variableInput={false}
                  autoComplete="off"
                  value={props.getUserInputValue(field?.name, true)}
                />
                {headerValueErrorValue && <div className="assert-section-errors absolute">{headerValueErrorValue}</div>}
              </div>
              <div className="verify-status-code-json-path">
                <Tooltip title={''} placement="bottom">
                  <MySelect
                    variableInput={'defaultAssert'}
                    verticalAnchor={'bottom'}
                    verticalTransform={'top'}
                    horizontalTransform={'left'}
                    horizontalAnchor={'left'}
                    className={'verify-status-specific-class-select'}
                    marginTop={'3px'}
                    fullWidth
                    minWidth={'190px'}
                    sxPadding={'0px 1px 0px 5px'}
                    value={field.operator}
                    name="operator"
                    onChange={(e) => handleArrayValues(e.target.name, e, idx)}
                    MenuProps={{
                      PaperProps: {
                        className: 'json-path-operators-list ',
                      },
                    }}
                  >
                    {getAllOperator()}
                  </MySelect>
                </Tooltip>
              </div>
              <div className="flex default-assert-field-json-custom-input">
                <MyInput
                  type="text"
                  onChange={(e) => {
                    handleArrayValues(e.target.name, e, idx);
                    lhsHeaderOnchange(e);
                  }}
                  value={props.getUserInputValue(field.lhs.substring(17, field.lhs.length - 12), true)}
                  placeholder="Enter header key"
                  autoComplete="off"
                  name="lhs"
                  id="lhs"
                  autoFocus={true}
                  handleSaveAsSteps={props.handleSaveAsSteps}
                />
              </div>
              <div className="flex default-assert-field-json-path">
                <MyInput
                  type="text"
                  onChange={(e) => {
                    handleArrayValues(e.target.name, e, idx);
                    headerVerifyOnchange(e);
                  }}
                  value={headerVerify ? headerVerify : props.getUserInputValue(field.rhs, true)}
                  autoComplete="off"
                  placeholder="Enter expected header value"
                  name="rhs"
                  id="rhs"
                  variableInput={true}
                  currentValue={context.values.currentDefaultAssertValue}
                  {...props}
                />
              </div>
              <div className="mb-1 ml-6">
                <CustomTooltip title={'Delete'} placement="bottom">
                  <span
                    className={`${!field.rhs && checkLhsField && fields.length <= 1 ? 'text-gray-300' : 'Data text-gray-500 assert-cdfr-delete-enable-effect'} cursor-pointer mt-2`}
                  >
                    <DeleteIcon
                      type="button"
                      onClick={() => {
                        handleRemove(idx);
                      }}
                      className={`${!field.rhs && checkLhsField && fields.length <= 1 ? 'text-gray-300 mt-2' : 'Data text-gray-500 delete-icon-hover-effect mt-0'} cursor-pointer`}
                      disabled={fields.length <= 1}
                    />
                  </span>
                </CustomTooltip>
              </div>
              {fields.length - 1 === idx && (
                <div className="mb-1">
                  <CustomTooltip title="Add">
                    <button
                      type="button"
                      className={`${checkLhsField || !field.rhs || !field.name ? 'text-gray-300 p-0.5' : 'assert-cdfr-add-enable-effect text-gray-500'}  cursor-pointer mt-2 ml-1`}
                      onClick={handleAdd}
                      disabled={checkLhsField || !field.rhs || !field.name}
                    >
                      <AddIcon />
                    </button>
                  </CustomTooltip>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
const JsonPath = ({
  jsonPath,
  handleAdd,
  handleRemove,
  handleArrayValues,
  jsonPathValue,
  jsonPathOnchange,
  jsonLhs,
  jsonLhsOnchange,
  handleChildElementClick,
  props,
  getAllOperator,
}) => {
  const fields = jsonPath.values;
  const context = useContext(RestContext);

  const handleKeyPress = (e, defaultJsonPath) => {
    const currentPosition = e.target.selectionStart;
    if (currentPosition < defaultJsonPath.length) {
      e.preventDefault();
    }
  };
  return (
    <div className="sections-main-container w-full pr-3 ">
      <div className="w-1/5"></div>
      <div onClick={handleChildElementClick} className="default-assert-data">
        <div className="header-default-assert mb-4 pl-2.5 fontPoppinsRegularSm">Verify JSON path</div>
        {jsonPath.values.map((field, idx) => {
          const jsonPathErrorKey = field?.group.split(' ').join('') + idx + 'Name';
          const jsonPathErrorValue = props?.defaultAssertErrors?.[jsonPathErrorKey];
          return (
            <div key={`${field}-${idx}`} className="flex relative items-center h-9 mb-5 mr-14 ml-1.5">
              <div className="default-assert-field-json-name">
                <MyInput
                  type="text"
                  className={'verify-status-specific-class-select'}
                  placeholder="*Enter name"
                  name="name"
                  id="name"
                  onChange={(e) => handleArrayValues(e.target.name, e, idx)}
                  variableInput={false}
                  autoComplete="off"
                  value={props.getUserInputValue(field?.name, true)}
                />
                {jsonPathErrorValue && <div className="assert-section-errors absolute">{jsonPathErrorValue}</div>}
              </div>
              <div className="verify-status-code-json-path">
                <Tooltip title={''} placement="bottom">
                  <MySelect
                    variableInput={'defaultAssert'}
                    verticalAnchor={'bottom'}
                    verticalTransform={'top'}
                    horizontalTransform={'left'}
                    horizontalAnchor={'left'}
                    className={'verify-status-specific-class-select'}
                    marginTop={'3px'}
                    fullWidth
                    minWidth={'200px'}
                    sxPadding={'0px 1px 0px 5px'}
                    value={field.operator}
                    name="operator"
                    onChange={(e) => handleArrayValues(e.target.name, e, idx)}
                    MenuProps={{
                      PaperProps: {
                        className: 'json-path-operators-list ',
                      },
                    }}
                  >
                    {getAllOperator()}
                  </MySelect>
                </Tooltip>
              </div>
              <div className="flex default-assert-field-json-custom-input">
                <MyInput
                  type="text"
                  onChange={(e) => {
                    handleArrayValues(e.target.name, e, idx);
                    jsonLhsOnchange(e);
                  }}
                  placeholder="Enter json path"
                  name="lhs"
                  autoComplete="off"
                  value={props.getUserInputValue(field.lhs, true) || jsonLhs}
                  id="lhs"
                  autoFocus={true}
                  handleSaveAsSteps={props.handleSaveAsSteps}
                  onKeyPress={(e) => handleKeyPress(e, 'responseBody')}
                  canCreateVariable={false}
                  variableInput={true}
                  currentValue={context.values.currentDefaultAssertValue}
                  {...props}
                />
              </div>
              <div className="flex default-assert-field-json-path">
                <MyInput
                  type="text"
                  autoComplete="off"
                  placeholder="Enter expected value"
                  onChange={(e) => {
                    handleArrayValues(e.target.name, e, idx);
                    jsonPathOnchange(e);
                  }}
                  value={jsonPathValue ? jsonPathValue : props.getUserInputValue(field.rhs, true)}
                  name="rhs"
                  id="rhs"
                  variableInput={true}
                  currentValue={context.values.currentDefaultAssertValue}
                  {...props}
                />
              </div>
              <div className="ml-3">
                <CustomTooltip title={'Delete'} placement="bottom">
                  <span
                    className={`${!field.rhs && (field.lhs === 'responseBody' || jsonLhs === 'responseBody') && fields?.length === 1 ? 'text-gray-300' : 'Data text-gray-500 assert-cdfr-delete-enable-effect'} cursor-pointer ml-2`}
                  >
                    <DeleteIcon
                      type="button"
                      onClick={() => {
                        handleRemove(idx);
                      }}
                      disabled={
                        !field.rhs &&
                        (field.lhs === 'responseBody' || jsonLhs === 'responseBody') &&
                        fields?.length === 1
                      }
                    />
                  </span>
                </CustomTooltip>
              </div>
              {fields.length - 1 === idx && (
                <div className="">
                  <CustomTooltip title="Add">
                    <button
                      type="button"
                      className={` ${!field.lhs || !field.rhs || !field.name ? 'text-gray-300 p-0.5' : 'assert-cdfr-add-enable-effect text-gray-500'} cursor-pointer ml-3`}
                      onClick={handleAdd}
                      disabled={!field.lhs || !field.rhs || !field.name}
                    >
                      <AddIcon />
                    </button>
                  </CustomTooltip>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
const HeaderKey = ({
  headerKey,
  handleAdd,
  handleRemove,
  handleArrayValues,
  headerKeyValue,
  headerOnchange,
  handleChildElementClick,
  props,
  getAllOperator,
}) => {
  const fields = headerKey.values;
  const context = useContext(RestContext);
  return (
    <div className="sections-main-container w-full pr-3 ">
      <div className="w-1/5"></div>
      <div onClick={handleChildElementClick} className="default-assert-data">
        <div className="header-default-assert mb-4 pl-2.5 fontPoppinsRegularSm">Verify header key</div>
        {headerKey.values.map((field, idx) => {
          const checkLhsField = !field.lhs.substring(17, field.lhs.length - 11);
          const headerKeyErrorKey = field?.group.split(' ').join('') + idx + 'Name';
          const headerKeyErrorValue = props?.defaultAssertErrors?.[headerKeyErrorKey];
          return (
            <div key={`${field}-${idx}`} className="flex relative items-center h-9 mb-5 mr-14">
              <div className="default-assert-field-header-key">
                <MyInput
                  type="text"
                  placeholder="*Enter name"
                  name="name"
                  id="name"
                  onChange={(e) => handleArrayValues(e.target.name, e, idx)}
                  variableInput={false}
                  autoComplete="off"
                  value={props.getUserInputValue(field?.name, true)}
                />
                {headerKeyErrorValue && <div className="assert-section-errors absolute">{headerKeyErrorValue}</div>}
              </div>
              <div className="verify-status-code-header-key">
                <Tooltip title={''} placement="bottom">
                  <MySelect
                    variableInput={'defaultAssert'}
                    verticalAnchor={'bottom'}
                    verticalTransform={'top'}
                    className={'verify-status-specific-class-select'}
                    horizontalTransform={'left'}
                    horizontalAnchor={'left'}
                    marginTop={'3px'}
                    fullWidth
                    minWidth={'240px'}
                    sxPadding={'0px 1px 0px 5px'}
                    value={field.operator}
                    name="operator"
                    onChange={(e) => handleArrayValues(e.target.name, e, idx)}
                    MenuProps={{
                      PaperProps: {
                        className: 'default-assert-operators-list ',
                      },
                    }}
                  >
                    {getAllOperator()}
                  </MySelect>
                </Tooltip>
              </div>
              <div className="flex default-assert-field-header-key default-assert-header-key relative flex-1">
                <MyInput
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleArrayValues(e.target.name, e, idx);
                    headerOnchange(e);
                  }}
                  value={
                    headerKeyValue
                      ? headerKeyValue
                      : props.getUserInputValue(field.lhs.substring(17, field.lhs.length - 11), true)
                  }
                  placeholder="Enter expected key"
                  name="lhs"
                  variableInput={true}
                  currentValue={context.values.currentDefaultAssertValue}
                  {...props}
                />
              </div>
              <div className="-ml-5">
                <CustomTooltip title={'Delete'} placement="bottom">
                  <span
                    className={`${checkLhsField && fields?.length === 1 ? 'text-gray-300' : 'Data text-gray-500 assert-cdfr-delete-enable-effect'} cursor-pointer -ml-3`}
                  >
                    <DeleteIcon
                      type="button"
                      onClick={() => {
                        handleRemove(idx);
                      }}
                      disabled={checkLhsField && fields?.length === 1}
                    />
                  </span>
                </CustomTooltip>
              </div>
              {fields.length - 1 === idx && (
                <div className="">
                  <CustomTooltip title="Add">
                    <button
                      type="button"
                      className={` ${checkLhsField || !field.name ? 'text-gray-300 p-0.5' : 'assert-cdfr-add-enable-effect  text-gray-500'} cursor-pointer ml-3`}
                      onClick={handleAdd}
                      disabled={checkLhsField || !field.name}
                    >
                      <AddIcon />
                    </button>
                  </CustomTooltip>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
const XmlPath = ({ xmlPath, handleAdd, handleRemove, handleArrayValues, handleChildElementClick }) => {
  const fields = xmlPath.values;

  return (
    <>
      {xmlPath.values.map((field, idx) => {
        return (
          <>
            <div
              onClick={handleChildElementClick}
              key={`${field}-${idx}`}
              className="flex flex-row relative left-72 bottom-36"
            >
              {' '}
              <Tooltip title={''} placement="bottom">
                <select
                  value={field.operator}
                  className={`selectButton w-60 default-assert-common-select-style`}
                  name="operator"
                  onChange={(e) => handleArrayValues(e.target.name, e.target.value, idx)}
                >
                  <option value="==">Equals to</option>
                  <option value="<">Less than </option>
                  <option value="<=">Less than or equals to</option>
                  <option value=">">Greater than</option>
                  <option value=">=">Greater than or equals to</option>
                  <option value="!=">Not equals</option>
                  <option value="substring">Contains</option>
                  <option value="startsWith">Starts with</option>
                  <option value="endsWith">Ends with</option>
                </select>
              </Tooltip>
              <MyInput
                className="xml-path-input"
                type="text"
                onChange={(e) => handleArrayValues(e.target.name, e.target.value, idx)}
                value={field.lhs}
                autoComplete="off"
                placeholder="Enter xml path"
                name="lhs"
                id="lhs"
              />
              <MyInput
                className="xml-path-input-two"
                type="text"
                autoComplete="off"
                onChange={(e) => handleArrayValues(e.target.name, e.target.value, idx)}
                value={field.rhs}
                placeholder="Enter expected value"
                name="rhs"
                id="rhs"
              />{' '}
              <button
                type="button"
                className=" ml-2 relative mt-5 cross-symbol-for-three-fields"
                hidden={fields.length <= 1}
                onClick={() => handleRemove(idx)}
              >
                <img src="/assets/images/close_black.svg" alt="" height="20px" width="20px" draggable="false" />
              </button>
              {fields.length - 1 === idx && (
                <button
                  type="button"
                  className="mt-5 relative -mr-2 plus-symbol-for-three-fields"
                  onClick={handleAdd}
                  disabled={!field.lhs || !field.rhs}
                >
                  <img src="/assets/images/add_black.svg" alt="" height="20px" width="20px" draggable="false" />
                </button>
              )}
            </div>
            <div className="h-5 flex flex-row relative left-72 bottom-16 space-for-two-fields"></div>
          </>
        );
      })}
    </>
  );
};

const Response = ({
  response,
  handleAdd,
  handleRemove,
  handleArrayValues,
  handleChildElementClick,
  props,
  getAllOperator,
}) => {
  const fields = response.values;
  const context = useContext(RestContext);
  return (
    <div className="sections-main-container w-full pr-3 ">
      <div className="w-1/5"></div>
      <div onClick={handleChildElementClick} className="default-assert-data">
        <div className="header-default-assert mb-4 pl-2.5 fontPoppinsRegularSm">Verify response body</div>
        {response.values.map((field, idx) => {
          const responseBodyErrorKey = field?.group.split(' ').join('') + idx + 'Name';
          const responseBodyErrorValue = props?.defaultAssertErrors?.[responseBodyErrorKey];
          return (
            <div key={`${field}-${idx}`} className="flex relative items-center h-9 mb-5 mr-14">
              <div className="default-assert-field-header-key">
                <MyInput
                  type="text"
                  className={'verify-status-specific-class-select'}
                  placeholder="*Enter name"
                  name="name"
                  id="name"
                  onChange={(e) => handleArrayValues(e.target.name, e, idx)}
                  variableInput={false}
                  autoComplete="off"
                  value={props.getUserInputValue(field?.name, true)}
                />
                {responseBodyErrorValue && (
                  <div className="assert-section-errors absolute">{responseBodyErrorValue}</div>
                )}
              </div>
              <div className="verify-status-code-header-key-response-body">
                <Tooltip title={''} placement="bottom">
                  <MySelect
                    variableInput={'defaultAssert'}
                    verticalAnchor={'bottom'}
                    verticalTransform={'top'}
                    horizontalTransform={'left'}
                    horizontalAnchor={'left'}
                    className={'verify-status-specific-class-select'}
                    marginTop={'3px'}
                    fullWidth
                    minWidth={'240px'}
                    sxPadding={'0px 1px 0px 5px'}
                    value={field.operator}
                    name="operator"
                    onChange={(e) => handleArrayValues(e.target.name, e, idx)}
                    MenuProps={{
                      PaperProps: {
                        className: 'default-assert-operators-list ',
                      },
                    }}
                  >
                    {getAllOperator()}
                  </MySelect>
                </Tooltip>
              </div>
              <div className="flex default-assert-field-header-key relative flex-1">
                <MyInput
                  type="text"
                  onChange={(e) => handleArrayValues(e.target.name, e, idx)}
                  value={props.getUserInputValue(field.rhs, true)}
                  placeholder="Enter expected response body"
                  autoComplete="off"
                  name="rhs"
                  id="rhs"
                  autoFocus={true}
                  variableInput={true}
                  currentValue={context.values.currentDefaultAssertValue}
                  {...props}
                />
              </div>
              <div className="-ml-5">
                <CustomTooltip title={'Delete'} placement="bottom">
                  <span
                    className={`${!field.rhs && fields?.length === 1 ? 'text-gray-300' : 'Data text-gray-500 assert-cdfr-delete-enable-effect'} cursor-pointer -ml-3`}
                  >
                    <DeleteIcon
                      type="button"
                      onClick={() => {
                        handleRemove(idx);
                      }}
                      disabled={!field.rhs && fields?.length === 1}
                    />
                  </span>
                </CustomTooltip>
              </div>
              {fields.length - 1 === idx && (
                <div className="">
                  <CustomTooltip title="Add">
                    <button
                      type="button"
                      className={` ${!field.rhs || !field.name ? 'text-gray-300 p-0.5' : 'assert-cdfr-add-enable-effect text-gray-500'} cursor-pointer ml-3`}
                      onClick={handleAdd}
                      disabled={!field.rhs || !field.name}
                    >
                      <AddIcon />
                    </button>
                  </CustomTooltip>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
const VerifyStatusCodeComponent = ({
  verifyStatusCode,
  handleArrayValues,
  handleChildElementClick,
  props,
  getAllOperator,
}) => {
  const fields = verifyStatusCode.values;
  const context = useContext(RestContext);
  const statusCodeErrorKey = fields[0]?.group.split(' ').join('') + 'Name';
  const statusCodeErrorValue = props?.defaultAssertErrors?.[statusCodeErrorKey];

  return (
    <>
      {fields.map((field, idx) => {
        return (
          <div className="sections-main-container w-full pr-3 ">
            <div className="w-1/5"></div>
            <div onClick={handleChildElementClick} key={`${field}-${idx}`} className="default-assert-data">
              <div className="header-default-assert mb-4 pl-2.5 fontPoppinsRegularSm">Verify Status Code</div>
              <div className=" flex mb-5">
                <div className="flex flex-col">
                  <div className="default-assert-field-header-key">
                    <MyInput
                      type="text"
                      className={'verify-status-specific-class-select'}
                      placeholder="*Enter name"
                      name="name"
                      id="name"
                      onChange={(e) => handleArrayValues(e.target.name, e, idx)}
                      variableInput={false}
                      autoComplete="off"
                      value={props.getUserInputValue(field?.name, true)}
                    />
                    {statusCodeErrorValue && (
                      <div className="assert-section-errors absolute">{statusCodeErrorValue}</div>
                    )}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="verify-status-code-select">
                    <Tooltip title={''} placement="bottom">
                      <MySelect
                        variableInput={'defaultAssert'}
                        padding={'5px 0 5px 8px'}
                        sxPadding={'0px 1px 0px 5px'}
                        verticalAnchor={'bottom'}
                        verticalTransform={'top'}
                        horizontalTransform={'left'}
                        horizontalAnchor={'left'}
                        className={'verify-status-specific-class-select'}
                        marginTop={'3px'}
                        fullWidth
                        minWidth={'200px'}
                        value={field.operator}
                        name="operator"
                        onChange={(e) => handleArrayValues(e.target.name, e, idx)}
                        MenuProps={{
                          PaperProps: {
                            className: 'status-code-operators-list ',
                          },
                        }}
                      >
                        {getAllOperator()}
                      </MySelect>
                    </Tooltip>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="default-assert-field-one">
                    <MyInput
                      type="text"
                      onChange={(e) => handleArrayValues(e.target.name, e, idx)}
                      value={props.getUserInputValue(field.rhs, true)}
                      placeholder="Enter expected status code"
                      autoComplete="off"
                      name="rhs"
                      id="rhs"
                      autoFocus={true}
                      variableInput={true}
                      currentValue={context.values.currentDefaultAssertValue}
                      {...props}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
const VerifyStatusMessageComponent = ({
  verifyStatusMessage,
  handleArrayValues,
  handleChildElementClick,
  props,
  getAllOperator,
}) => {
  const fields = verifyStatusMessage.values;
  const statusMessageErrorKey = fields[0]?.group.split(' ').join('') + 'Name';
  const statusMessageErrorValue = props?.defaultAssertErrors?.[statusMessageErrorKey];
  const context = useContext(RestContext);
  return (
    <>
      {fields.map((field, idx) => {
        return (
          <div className="sections-main-container w-full pr-3 -mt-13">
            <div className="w-1/5"></div>
            <div onClick={handleChildElementClick} key={`${field}-${idx}`} className="default-assert-data">
              <div className="header-default-assert mb-4 pl-2.5 fontPoppinsRegularSm">Verify Status Message</div>
              <div className=" flex mb-5">
                <div className="flex flex-col">
                  <div className="default-assert-field-header-key">
                    <MyInput
                      type="text"
                      className={'verify-status-specific-class-select'}
                      placeholder="*Enter name"
                      name="name"
                      id="name"
                      onChange={(e) => handleArrayValues(e.target.name, e, idx)}
                      variableInput={false}
                      autoComplete="off"
                      value={props.getUserInputValue(field?.name, true)}
                    />
                    {statusMessageErrorValue && (
                      <div className="assert-section-errors absolute">{statusMessageErrorValue}</div>
                    )}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="verify-status-code-select">
                    <Tooltip title={''} placement="bottom">
                      <MySelect
                        variableInput={'defaultAssert'}
                        verticalAnchor={'bottom'}
                        sxPadding={'0px 1px 0px 5px'}
                        verticalTransform={'top'}
                        horizontalTransform={'left'}
                        className={'verify-status-specific-class-select'}
                        horizontalAnchor={'left'}
                        marginTop={'3px'}
                        fullWidth
                        minWidth={'200px'}
                        value={field.operator}
                        name="operator"
                        onChange={(e) => handleArrayValues(e.target.name, e, idx)}
                        MenuProps={{
                          PaperProps: {
                            className: 'status-code-operators-list ',
                          },
                        }}
                      >
                        {getAllOperator()}
                      </MySelect>
                    </Tooltip>
                  </div>
                </div>
                <div className="default-assert-field-one">
                  <MyInput
                    type="text"
                    onChange={(e) => handleArrayValues(e.target.name, e, idx)}
                    value={props.getUserInputValue(field.rhs, true)}
                    placeholder="Enter expected status message"
                    autoComplete="off"
                    name="rhs"
                    id="rhs"
                    autoFocus={true}
                    variableInput={true}
                    currentValue={context.values.currentDefaultAssertValue}
                    {...props}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
export { DefaultAssert, CheckLayout };

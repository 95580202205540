import React, { useState, useEffect, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import cx from 'classnames';
import styles from '@src/common//atoms/atoms.module.scss';
import { TooltipPoppin } from '@pages/analytics/common/util';
import { SIDE_NAVIGATION_CONSTANTS } from '@src/common/ui-constants';

const MoreVerticalActionIcon = ({ options, optionHandler, data }) => {
  const [pageIndexValue, setPageIndex] = useState(0);
  const [pageIndex, setPage] = useState(0);
  const [margin, setMargin] = useState(0);

  useEffect(() => {
    if (pageIndexValue > 550) {
      setPage(-250);
      setMargin(150);
    } else {
      setPage(100);
      setMargin();
    }
  }, [pageIndexValue]);

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <div id="actionIconMenu">
            <Menu.Button
              className="flex items-center"
              onMouseOver={(e) => {
                let mouse = e.clientY;
                setPageIndex(mouse);
              }}
            >
              <span className="sr-only ">{SIDE_NAVIGATION_CONSTANTS.OPEN_OPTIONS}</span>
              <TooltipPoppin title="More">
                <MoreVertIcon fontSize="medium" className="p-0.5 cursor-pointer" />
              </TooltipPoppin>
              <span></span>
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              style={{
                top: `${pageIndex}%`,
                marginRight: `${margin}%`,
                width: '215px',
              }}
              className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white focus:outline-none  z-10 w-48"
            >
              <div className="py-4">
                {options?.map((option) => (
                  <Menu.Item key={option.label}>
                    {({ active }) => (
                      <label
                        onClick={() => {
                          optionHandler(option.label, data);
                        }}
                        className={cx('block px-4 py-2 fontPoppinsRegularSm', {
                          [styles['selected-action-menu-item']]: active,
                          [styles['action-menu-item']]: !active,
                        })}
                      >
                        {option.label}
                      </label>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default MoreVerticalActionIcon;

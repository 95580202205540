import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { ReactComponent as YellowAlert } from '@assets/svg-imports/ionic-ios-warning.svg';
import { deleteLabel } from '@src/api/api_services';
import '@pages/configuration/plugin/bitBucket-integration/bitBucket.scss';
import '@pages/configuration/config-label.scss';
import { LABELS_CONSTANTS } from '@src/common/ui-constants';
import '@pages/common/alert_pop_ups/alert_pop_ups.module.scss';

function ConfigLabelDelete(props) {
  const deleteLabelAction = () => {
    deleteLabel(props?.seletedLabel?.id)
      .then(() => {
        props.getAllLabels();
        props.MyAlert.success(`${props?.seletedLabel?.name} ${LABELS_CONSTANTS.LABEL_DELETED}`);
      })
      .catch((error) => {
        console.error('Delete api Failed!:', error);
      });
    props.openDeleteModal(false);
  };
  return (
    <>
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="focus:outline-none outline-none"
      >
        <Box className="deleteModal focus:outline-none outline-none">
          <div className="headerdelete">
            <div className="successImg">
              <YellowAlert className="svgImgWarning" />
            </div>
            <div className="mt-5">
              <div className="flex flex-row">
                <p className="warning-color fontPoppinsMediumXX2 ml-5">{LABELS_CONSTANTS.WARNING}</p>
                <p className="fontPoppinsMediumXX2 ml-2 text-color">{LABELS_CONSTANTS.DELETE}</p>
              </div>
              <br />
              <div className="fontPoppinsRegularLg ml-7 -mt-3  flex flex-col">
                <span className="mt-4 fontPoppinsRegularMd text-color">
                  {LABELS_CONSTANTS.DATA_LOST}
                  <br />
                  <span className="fontPoppinsSemiboldMd">{props?.seletedLabel?.name} ? </span>{' '}
                  {LABELS_CONSTANTS.DATA_LOST_UNRECOVERED}
                </span>
                <span className="mt-4 fontPoppinsRegularMd text-color">{LABELS_CONSTANTS.PROCEED}</span>
              </div>
              <span className="ml-7 mb-2"> </span>
              <div className="ml-7"></div>
            </div>
          </div>
          <div className="warning-delete-footer flex -mx-1">
            <button
              className="button-cancel cancel-instance cursor-pointer fontPoppinsRegularXs"
              onClick={deleteLabelAction}
            >
              {LABELS_CONSTANTS.DELETE}
            </button>
            <button
              className="delete-button success-instance  cursor-pointer fontPoppinsRegularXs"
              onClick={props.closeModal}
            >
              {LABELS_CONSTANTS.CANCEL}
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ConfigLabelDelete;

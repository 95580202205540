import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';

const ExecutionTermination = (props) => {
  let executionTermination = {};
  let terminateUnitArray = ['Hrs.', 'Mins.', 'Secs.'];

  const formik = useFormik({
    initialValues: {
      terminateScriptIfTakesMoreTime:
        props.executionTerminationData && props.executionTerminationData.terminateScriptIfTakesMoreTime
          ? props.executionTerminationData.terminateScriptIfTakesMoreTime
          : 0,
      terminateSuiteIfTakesMoreTime:
        props.executionTerminationData && props.executionTerminationData.terminateSuiteIfTakesMoreTime
          ? props.executionTerminationData.terminateSuiteIfTakesMoreTime
          : 0,
      terminateScriptUnit:
        props.executionTerminationData && props.executionTerminationData.terminateScriptUnit
          ? props.executionTerminationData.terminateScriptUnit
          : 'Hrs.',
      terminateSuitetUnit:
        props.executionTerminationData && props.executionTerminationData.terminateSuitetUnit
          ? props.executionTerminationData.terminateSuitetUnit
          : 'Hrs.',
      terminateScriptSendEmail:
        props.executionTerminationData && props.executionTerminationData.terminateScriptSendEmail
          ? props.executionTerminationData.terminateScriptSendEmail
          : 'false',
      terminateSuiteSendEmail:
        props.executionTerminationData && props.executionTerminationData.terminateSuiteSendEmail
          ? props.executionTerminationData.terminateSuiteSendEmail
          : 'false',
    },
  });

  function sendMailFunction(e, type) {
    executionTermination = formik.initialValues;
    if (type === 'script_level') {
      if (e.target.checked) {
        executionTermination.terminateScriptSendEmail = 'true';
      } else {
        executionTermination.terminateScriptSendEmail = 'false';
      }
    } else if (type === 'suite_level') {
      if (e.target.checked) {
        executionTermination.terminateSuiteSendEmail = 'true';
      } else {
        executionTermination.terminateSuiteSendEmail = 'false';
      }
    } else if (type === 'terminate_script') {
      executionTermination.terminateScriptIfTakesMoreTime = e.target.value;
    } else if (type === 'terminate_script_unit') {
      executionTermination.terminateScriptUnit = e.target.value;
    } else if (type === 'terminate_suite') {
      executionTermination.terminateSuiteIfTakesMoreTime = e.target.value;
    } else if (type === 'terminate_suite_unit') {
      executionTermination.terminateSuitetUnit = e.target.value;
    }
    props.getExecutionTerminationData(executionTermination);
  }

  React.useEffect(() => {
    if (!props?.executionTerminationData) {
      executionTermination.terminateScriptIfTakesMoreTime = 0;
      executionTermination.terminateSuiteIfTakesMoreTime = 0;
      executionTermination.terminateScriptUnit = 'Hrs';
      executionTermination.terminateSuitetUnit = 'Hrs';
      executionTermination.terminateScriptSendEmail = 'Secs';
      executionTermination.terminateSuiteSendEmail = 'Secs';
      props.getExecutionTerminationData(executionTermination);
    }
  });

  return (
    <div className="left-border-style mb-2">
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-row">
          <div className="implicit-wait-style mt-5">
            <label className="implicit-explicit-label-style">
              <span className="text-red-400">* </span> Terminate Script if any script execution takes more than
            </label>
            <br />
            <TextField
              defaultValue={formik.values.terminateScriptIfTakesMoreTime}
              disabled={props?.rerunMode}
              id="standard-number"
              type="number"
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 9);
              }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              InputProps={{ inputProps: { min: 0 } }}
              onChange={(e) => sendMailFunction(e, 'terminate_script')}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <span className="select-tag-style">
              <select
                disabled={props?.rerunMode}
                defaultValue={formik.values.terminateScriptUnit}
                onChange={(e) => sendMailFunction(e, 'terminate_script_unit')}
              >
                {terminateUnitArray.map((tempObj) => {
                  return <option className="click"> {tempObj}</option>;
                })}
              </select>
            </span>

            <br />
          </div>
          <div className="implicit-wait-style mt-5 ml-10">
            <label className="implicit-explicit-label-style">
              <span className="text-red-400">* </span>Terminate Suite if suite execution takes more than
            </label>
            <br />
            <TextField
              defaultValue={formik.values.terminateSuiteIfTakesMoreTime}
              disabled={props?.rerunMode}
              onChange={(e) => sendMailFunction(e, 'terminate_suite')}
              id="standard-number"
              type="number"
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 9);
              }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              InputProps={{ inputProps: { min: 0 } }}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <span className="select-tag-style click">
              <select
                disabled={props?.rerunMode}
                defaultValue={formik.values.terminateSuitetUnit}
                onChange={(e) => sendMailFunction(e, 'terminate_suite_unit')}
              >
                {terminateUnitArray.map((tempObj) => {
                  return <option> {tempObj}</option>;
                })}
              </select>
            </span>
            <br />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ExecutionTermination;

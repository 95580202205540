import { useEffect, useMemo, useRef } from 'react';
import Chart from 'react-apexcharts';
import DashboardStyle from '../styles/dashboard.module.scss';
import ChartCard from '../components/ChartCard';
import cx from 'classnames';
import { fontPoppins } from '@src/css_config/fontConstants';

function handleChartElementsStyle(element) {
  if (element) {
    const titleTextElement = element.querySelector('.apexcharts-title-text');
    const parentElementWidth = element.offsetWidth;
    if (titleTextElement && parentElementWidth) {
      titleTextElement.setAttribute('x', (parentElementWidth * 55) / 100);
    }
  }
}

function ColumnChart({
  chartProperties,
  labels,
  colors,
  series,
  categories = [],
  customTooltipFormatter,
  roundedDirection = 'b',
  roundedBorders = false,
}) {
  const tooltipEnabled = series.some((value) => {
    if (value.data.length === 1) {
      return value?.data > 0.1;
    } else if (value?.data.length > 1) {
      return true;
    }
  });

  const ref = useRef();
  function handleTooltip(a, b, { seriesIndex }) {
    if (ref.current) {
      const element = ref.current;
      const elementRect = element?.getBoundingClientRect();
      const barSeriesElement = element.querySelector(`.apexcharts-series[rel="${seriesIndex + 1}"]`);
      const innerChartElement = element.querySelector('.apexcharts-inner');
      const xAxisElement = element.querySelector('.apexcharts-xaxis');
      if (barSeriesElement) {
        const barRect = barSeriesElement.getBoundingClientRect();
        let totalBottomOfTooltip;
        if (
          (labels[0] === 'Android' && series?.[0]?.data?.length >= 2) ||
          (labels[1] === 'IOS' && series?.[1]?.data?.length >= 2)
        ) {
          totalBottomOfTooltip =
            elementRect.height -
            innerChartElement.getBoundingClientRect().height +
            xAxisElement.getBoundingClientRect().height +
            (barRect?.height || 0) -
            43;
        } else {
          totalBottomOfTooltip =
            elementRect.height -
            innerChartElement.getBoundingClientRect().height +
            xAxisElement.getBoundingClientRect().height +
            (barRect?.height || 0) -
            30;
        }

        const tooltipElement = element.querySelector('.apexcharts-tooltip');
        const left = barRect.x - elementRect.x;
        if (tooltipElement?.style) {
          if (tooltipElement?.style?.bottom !== `${totalBottomOfTooltip}px`) {
            tooltipElement.style.bottom = `${totalBottomOfTooltip}px`;
          }
          if (tooltipElement?.style?.left !== `${left}px`) {
            tooltipElement.style['margin-left'] = `${left}px`;
          }
        }
      }
    }
  }
  const options = useMemo(
    () => ({
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      chart: {
        width: '100%',
        height: '100',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        redrawOnWindowResize: true,
        redrawOnParentResize: true,
        events: {
          mounted: () => {
            handleChartElementsStyle(ref?.current);
          },
          updated: () => {
            handleChartElementsStyle(ref?.current);
          },
          dataPointMouseEnter: handleTooltip,
          mouseMove: handleTooltip,
        },
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
          },
        },
      },
      labels: labels,
      colors: colors,
      title: {
        text: chartProperties.text,
        offsetY: 0,
        floating: true,
        margin: 10,
        style: {
          ...fontPoppins.sSm,
          color: '#3C3838',
        },
      },
      yaxis: {
        tickAmount: 5,
        decimalsInFloat: 1,
        min: 0,
        max: (Math.max(...series.map((item) => Math.max(...item.data))) || 5) + 1,
        title: {
          text: chartProperties.yaxisTitle,
          style: {
            ...fontPoppins.rXs,
            color: '#7C828A',
          },
        },
      },
      series: series,
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          columnWidth: '100%',
          endingShape: 'rounded',
          borderRadius: 3,
          distributed: false,
        },
      },
      legend: {
        onItemClick: {
          toggleDataSeries: false,
        },
        horizontalAlign: 'left',
        formatter: (seriesName, options) => {
          return `${seriesName} ${
            chartProperties?.showLabelCount
              ? `
        : <span class="fontPoppinsSemiboldXs">
            ${Math.floor(options.w.globals.series[options.seriesIndex])}
          <span>
      `
              : ''
          }`;
        },
        ...fontPoppins.rXs,
        position: 'right',
        markers: {
          radius: 50,
        },
        offsetY: 10,
        itemMargin: {
          horizontal: 5,
          vertical: 1,
        },
      },
      xaxis: {
        show: chartProperties.xaxisShow,
        title: {
          text: chartProperties.xaxisTitle,
          offsetX: 15,
          offsetY: -100,
          style: {
            ...fontPoppins.rXs,
            color: '#7C828A',
          },
        },
        categories: categories?.length ? categories : [''],
        labels: {
          show: chartProperties.xaxisLabelsShow,
          rotate: 0,
          style: {
            marginLeft: '-20px',
          },
        },
        axisTicks: {
          show: false,
        },
        offsetX: -15,
        offsetY: 65,
      },
      stroke: {
        colors: ['transparent'],
        width: chartProperties.strokeWidth,
      },
      tooltip: {
        enabled: tooltipEnabled,
        custom: customTooltipFormatter,
      },
    }),
    [tooltipEnabled, chartProperties, labels, colors, customTooltipFormatter, series, categories]
  );

  useEffect(() => {
    ref.current && handleChartElementsStyle(ref.current);
  });
  return (
    <div ref={ref} className="h-full">
      <ChartCard
        className={cx(DashboardStyle['client-section-small-chart'], DashboardStyle['client-access-chart'])}
        roundedDirection={roundedDirection}
        roundedBorders={roundedBorders}
      >
        <Chart
          options={options}
          series={options.series.map((value) => ({
            ...value,
            data: value.data.map((n) => n || 0.1),
          }))}
          type="bar"
          height="100%"
        />
      </ChartCard>
    </div>
  );
}

export default ColumnChart;

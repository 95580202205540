import React, { useEffect, useRef, useState } from 'react';
import { EditorState, ContentState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './rich-text-editor.scss';
import { DEFECT_DESCRIPTION_TITLE } from '@pages/defects/defect-utils';

const RichTextEditor = ({
  convertedContent,
  setConvertedContent,
  readOnly,
  toolbarHidden,
  toolbarOptions,
  mode,
  analyticsClasses = false,
  descriptionContentNotEditable,
}) => {
  const [editorState, setEditorState] = useState(null);
  const [focusState, setFocusState] = useState(false);
  const defaultToolBarOptions = [
    'inline',
    'blockType',
    'fontSize',
    'fontFamily',
    'list',
    'textAlign',
    'colorPicker',
    'link',
    'emoji',
    'image',
    'remove',
    'history',
  ];
  useEffect(() => {
    let editorState = null;
    let cc = convertedContent ? JSON.parse(convertedContent) : {};
    if (convertedContent.length > 0) {
      if (cc) {
        editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(convertedContent)));
      } else {
        if (mode === 'view') {
          const content = ContentState.createFromText('--');
          editorState = EditorState.createWithContent(content);
        } else {
          editorState = EditorState.createEmpty();
        }
      }
    }
    setEditorState(editorState);
  }, [convertedContent, mode]);

  useEffect(() => {
    let editorState = null;
    if (convertedContent.length === 0) {
      if (mode === 'view') {
        const content = ContentState.createFromText('--');
        editorState = EditorState.createWithContent(content);
      } else {
        editorState = EditorState.createEmpty();
      }
      setEditorState(editorState);
    }
  }, [convertedContent]);

  const handleEditorChange = (state) => {
    setEditorState(state);
  };
  return (
    <div>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName="wrapperClass"
        editorClassName={`heading-TextEditor-style ${analyticsClasses ? 'fontPoppinsRegularSm' : 'editorClass'}`}
        editorStyle={
          mode !== 'view'
            ? !focusState
              ? { border: '1px solid #989797', borderRadius: '5px' }
              : { border: '1px solid #1648c6', borderRadius: '5px' }
            : { border: 'none' }
        }
        readOnly={readOnly}
        onFocus={() => {
          setFocusState(true);
        }}
        onBlur={() => {
          setFocusState(false);
          let currentContentAsRaw = convertToRaw(editorState.getCurrentContent());
          setConvertedContent(JSON.stringify(currentContentAsRaw));
        }}
        toolbarHidden={toolbarHidden}
        toolbarClassName="toolbarClass"
        handlePastedText={() => false}
        toolbar={{
          options: Array.isArray(toolbarOptions) && toolbarOptions?.length > 0 ? toolbarOptions : defaultToolBarOptions,
          fontFamily: {
            options: ['Arial', 'Georgia', 'Impact', 'OpenSans-Regular', 'Tahoma', 'Times New Roman', 'Verdana'],
          },
        }}
      />
    </div>
  );
};
export default RichTextEditor;

import React, { useState, useEffect } from 'react';
import './searchdropdown.scss';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { ClickAwayListener } from '@mui/base';
import { ReactComponent as SearchIconFilled } from '@assets/analytics/searchIcon-rs.svg';
import { isEmptyValue } from '@src/util/common_utils';

const SearchDropdown = ({
  list,
  searchLabel,
  optionKey,
  fontSize,
  selectedValue,
  EndIcon,
  handleItemClick,
  noDataFound,
  enableSearch,
  setShowDropdown,
  showDropdown,
  isDropDownDispaly,
}) => {
  const [searchValue, setSearchValue] = useState(null);
  const [filteredList, setFilteredList] = useState(list);

  useEffect(() => {
    if (isEmptyValue(searchValue)) {
      setFilteredList(list);
    } else {
      let searchkey = searchValue?.toLowerCase();
      let tempArray = list?.filter((item) => item?.toLowerCase()?.includes(searchkey));
      setFilteredList(tempArray);
    }
  }, [searchValue, list]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
      <div className="search-wrap">
        <Tooltip title={selectedValue} placement="left">
          <div
            onClick={() => setShowDropdown(!showDropdown)}
            className={`${fontSize} search-element-box border-none p-0`}
          >
            <div className="title-element">
              {selectedValue}
            </div>
            <EndIcon />
          </div>
        </Tooltip>
        {showDropdown && (
          <div className={`dropdown-box absolute opacity-100 ${isDropDownDispaly ? 'bottom-11' : ''}`}>
            {enableSearch && (
              <>
                <div className="search-block">
                  <input
                    className="fontPoppinsRegularSm search-input"
                    type="text"
                    placeholder={searchLabel}
                    value={searchValue}
                    onChange={(e) => handleSearch(e)}
                  />
                  <SearchIconFilled className="search-icon" />
                </div>
                <hr />
              </>
            )}
            {filteredList?.length ? (
              <div className="dropdown-box-options">
                {filteredList?.map((item, index) => {
                  return (
                    <Tooltip title={item} disableInteractive placement="top">
                      <div
                        onClick={() => handleItemClick(item)}
                        className={`${fontSize} drop-item ${selectedValue === item ? 'selected-item' : ''} mt-2`}
                        key={`item-${index}`}
                      >
                        {item?.slice(0, 35)}
                      </div>
                    </Tooltip>
                  );
                })}
              </div>
            ) : (
              <div className="fontPoppinsRegularSm no-data-found">{noDataFound}</div>
            )}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

SearchDropdown.propTypes = {
  searchLabel: PropTypes.string,
  optionKey: PropTypes.string.isRequired,
  fontSize: PropTypes.string.isRequired,
  selectedValue: PropTypes.string.isRequired,
  handleItemClick: PropTypes.func.isRequired,
  noDataFound: PropTypes.string,
  enableSearch: PropTypes.bool,
};
SearchDropdown.defaultProps = {
  searchLabel: 'Search',
  optionKey: 'value',
  fontSize: 'fontPoppinsRegularSm',
  noDataFound: 'No Results Found',
  enableSearch: true,
};

export default SearchDropdown;

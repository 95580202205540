import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import ScriptsRouter from './script/scripts/scripts-router';
import ModuleRouter from './script/modules/module-router';
import LandingPageRouter from './landing-page-router';
import RunTreePage from './execution-dashboard/run-tree-page';
import Result from '../results/result';

const TestDevelopmentRouter = (props) => {
  const search = window.location.search;
  const scriptId = new URLSearchParams(search).get('scriptId');
  const manualScriptId = new URLSearchParams(search).get('manualScriptId');
  const history = useHistory();
  if (history.location.pathname === '/testdevelopment') {
    history.push({ pathname: '/testdevelopment/Script', state: history?.location?.state });
  }
  return (
    <Switch>
      <Route
        path="/testdevelopment/Script"
        exact={true}
        strict={true}
        key="test-development-default-page"
        component={LandingPageRouter}
      ></Route>
      <Route
        path="/testdevelopment/Execution Dashboard"
        exact={true}
        strict={true}
        key="test-development-default-page"
        component={LandingPageRouter}
      ></Route>
      <Route
        path="/testdevelopment/Execution Dashboard/true"
        exact={true}
        strict={true}
        key="test-development-default-page"
        component={LandingPageRouter}
      ></Route>
      <Route
        path="/testdevelopment/Execution Dashboard/:executionName"
        exact={true}
        strict={true}
        key="run-specific-tree-page"
      >
        <RunTreePage {...props} />
      </Route>
      <Route
        path="/testdevelopment/Execution Dashboard/:executionName/Root Module/:parentName/result"
        exact={true}
        strict={true}
        key="result-page"
        component={Result}
      ></Route>
      <Route
        path="/testdevelopment/Execution Dashboard/:executionName/Root Module/result"
        exact={true}
        strict={true}
        key="result-page"
        component={Result}
      ></Route>
      <Route
        path="/testdevelopment/Execution Dashboard/:executionName/:scriptName/script/result"
        exact={true}
        strict={true}
        key="script-result-base"
        component={Result}
      ></Route>
      <Route
        path="/testdevelopment/Execution Dashboard/:executionName/Root Module/:scriptName/:dependantScript/script/result"
        exact={true}
        strict={true}
        key="script-result-base"
        component={Result}
      ></Route>
      <Route
        path="/testdevelopment/Execution Dashboard/:currentScriptName/Root Module/:executionName/:dependantScript/script/result"
        exact={true}
        strict={true}
        key="script-result-base"
        component={Result}
      ></Route>
      {scriptId || manualScriptId ? (
        <Route path="/testdevelopment/Script" key="script-router-page" component={ScriptsRouter}></Route>
      ) : (
        <Route path="/testdevelopment/Script" key="module-router-page" component={ModuleRouter}></Route>
      )}
      {/* <Route path="*" component={LandingPageRouter} />  */}
    </Switch>
  );
};
export default TestDevelopmentRouter;

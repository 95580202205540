import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import Styles from '@pages/analytics/analytics.module.scss';
import DefectsAnalyticTable from '@pages/analytics/common/defectsAnalyticsTable';
import DefectsDonutChart from '@pages/common/charts/defects-donut-chart';
import { chartFontFamily, NO_DATA_FOUND, defectAnalyticsLabel } from '@common/ui-constants';
import { getDefectStatisticTableData, defectsDonutChartCustomTooltip } from '@pages/analytics/common/util';
import { isEmptyValue, scrollTop } from '@src/util/common_utils';
import { colors } from '@src/css_config/colorConstants';

const PriorityStatistics = (props) => {
  let {
    getBugSummary,
    openPriorityBugSummaryModal,
    closeBugSummaryModal,
    selectedDefectID,
    priorityChartData,
    reqBody,
    callAllApiOnFilterChange,
  } = props;
  let [series, setSeries] = useState([]);
  let [chartLabel, setChartLabel] = useState([]);
  let [seriesColor, setSeriesColor] = useState([]);
  let [tableData, setTableData] = useState([]);
  let [tableContent, setTableContent] = useState('ALL');
  let [callOnViewAll, setCallOnViewAll] = useState(false);

  //? States for scroll APi :: START
  let [initTable, setInitTable] = useState({
    from: 0,
    to: 20,
  });

  const priorityTableRef = useRef();
  const [priorityTableScrollPos, setPriorityTableScrollPos] = useState(false);
  const [stopScrollApiCall, setStopScrollApiCall] = useState(0); //? to stop scroll api on no-content

  let getPriorityTableData = async (params = { from: initTable.from, to: initTable.to }) => {
    try {
      let paylodParams = {
        reqBody,
        from: params.from,
        to: params.to,
        tableName: 'Priority',
        tableContent: params.tableContent || tableContent,
      };

      if (paylodParams.from === 0 && paylodParams.to === 20) {
        let Response = await getDefectStatisticTableData(paylodParams);
        setTableData(Response.defects);
        setStopScrollApiCall(Response.totalDefects);
        setCallOnViewAll(false); //? to reset viewAll API call state
      } else if (paylodParams.from !== 0 && paylodParams.to !== 20 && stopScrollApiCall > paylodParams.from) {
        let Response = await getDefectStatisticTableData(paylodParams);
        setTableData([...tableData, ...Response.defects]);
        setStopScrollApiCall(Response.totalDefects);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (priorityTableScrollPos) {
      setInitTable({
        from: initTable.to,
        to: initTable.to + 10,
      });
    }
  }, [priorityTableScrollPos]);

  useEffect(() => {
    if (initTable.from !== 0 && initTable.to !== 20) {
      getPriorityTableData();
    }
  }, [initTable]);

  //? States for scroll APi :: END

  useEffect(() => {
    setSeries(priorityChartData.series);
    setChartLabel(priorityChartData.label);
    setSeriesColor(priorityChartData.colorCode);
  }, [priorityChartData]);

  // TODO ::NOTE:: when request body change based on side filters

  useEffect(() => {
    if (callAllApiOnFilterChange) {
      setTableContent('ALL');

      let params = {
        from: 0,
        to: 20,
        tableContent: 'ALL',
      };
      getPriorityTableData(params);
    }
  }, [callAllApiOnFilterChange]);

  // TODO :: when chick on view All legend reset initTable value & scroll position :: START
  useEffect(() => {
    if (callOnViewAll) {
      setInitTable({
        from: 0,
        to: 20,
      });

      scrollTop(priorityTableRef);

      let params = {
        from: 0,
        to: 20,
      };
      getPriorityTableData(params);
    }
  }, [callOnViewAll]);

  // TODO :: when chick on view All legend reset initTable value & scroll position :: END

  let handleClickNameByIndexAndApiCall = (index) => {
    setTableContent(chartLabel.at(index));
    scrollTop(priorityTableRef);

    let params = {
      from: 0,
      to: 20,
      tableName: 'Priority',
      tableContent: chartLabel.at(index),
    };
    getPriorityTableData(params);
  };

  let PriorityStatisticsColumn = React.useMemo(
    () => [
      {
        Header: 'BUG ID',
        accessor: 'defectId',
        width: '20%',
        Cell: ({ row, value }) => {
          return (
            <div
              className="overflow-hidden overflow-ellipsis whitespace-nowrap pr-3 rs-blue cursor-pointer"
              title={value}
              onClick={() => getBugSummary(value, 'priorityBugSummary')}
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: 'SUMMARY',
        accessor: 'summary',
        width: '40%',
        Cell: ({ value }) => {
          return (
            <div className="overflow-hidden overflow-ellipsis whitespace-nowrap pr-3" title={value}>
              {value}
            </div>
          );
        },
      },
      {
        Header: 'PRIORITY',
        accessor: 'content',
        width: '20%',
        Cell: ({ value }) => {
          return (
            <div className="overflow-hidden overflow-ellipsis whitespace-nowrap pr-3" title={value}>
              {value}
            </div>
          );
        },
      },
      {
        Header: 'CREATED BY',
        accessor: 'createdBy',
        width: '20%',
        Cell: ({ value }) => {
          return (
            <div className="overflow-hidden overflow-ellipsis whitespace-nowrap pr-3" title={value}>
              {value}
            </div>
          );
        },
      },
    ],
    []
  );

  let donutOptions = {
    series: [0, 0, 0],
    chart: {
      type: 'donut',
      events: {
        dataPointSelection: function (event, chartContext, config) {
          setInitTable({
            from: 0,
            to: 20,
          });
          if (isEmptyValue(config.selectedDataPoints[0])) {
            //NOTE :: if chart protion selected deselect call All API (Reset)
            setTableContent('ALL');
            scrollTop(priorityTableRef);
            getPriorityTableData();
          } else {
            handleClickNameByIndexAndApiCall(config.dataPointIndex);
          }
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
    colors: seriesColor,
    labels: chartLabel,
    plotOptions: {
      bar: {
        borderRadius: 10,
      },
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: '14px',
              fontFamily: chartFontFamily,
              fontWeight: 600,
            },
            total: {
              show: true,
              label: 'Total Defects',
              fontSize: '10px',
              fontFamily: chartFontFamily,
              fontWeight: 600,
              color: colors.black,
            },
          },
        },
      },
    },
    legend: {
      position: 'right',
      fontSize: 10,
      fontFamily: chartFontFamily,
      fontWeight: 500,
      formatter: (seriesName, options) => {
        return `${seriesName.length > 5 ? seriesName.slice(0, 5) + '..' : seriesName} :  <span class="fontPoppinsRegularXs">${options.w.globals.series[options.seriesIndex]}<span>`;
      },
      markers: {
        width: 11,
        height: 8,
        radius: 0,
      },
    },
    tooltip: {
      enabled: true,
      custom: defectsDonutChartCustomTooltip,
    },
  };

  return (
    <section className={cx(Styles['defectStatisticSection'])}>
      <article className={cx(Styles['leftGarpgBlock'])}>
        <div className="graphHeading ml-2 rs-blue uppercase fontPoppinsSemiboldSm pt-1">
          {' '}
          {defectAnalyticsLabel.label.priority}
        </div>
        <DefectsDonutChart
          series={series}
          donutOptions={donutOptions}
          setTableContent={setTableContent}
          setCallOnViewAll={setCallOnViewAll}
        />
      </article>
      <article className={cx(Styles['rightTableBlock'])}>
        <DefectsAnalyticTable
          setScrollPos={setPriorityTableScrollPos}
          ref={priorityTableRef}
          header={`Defects By Priority-${tableContent}`}
          columns={PriorityStatisticsColumn}
          data={tableData}
          message={NO_DATA_FOUND}
          openBugSummaryModal={openPriorityBugSummaryModal}
          closeBugSummaryModal={closeBugSummaryModal}
          selectedDefectID={selectedDefectID}
        />
      </article>
    </section>
  );
};

export default PriorityStatistics;

 export const TREE_CONSTANTS  = {
    SHOW_ALL_CHILD: true,
    SHOW_ALL_CONDITIONS: true,
    LABELS: {
      EXPAND_ALL: 'Expand All',
      COLLAPSE_ALL: 'Collapse All',
      SHOW_ALL_ELEMENTS: 'Show all elements',
      HIDE_ALL_ELEMENTS: 'Hide all elements',
      SHOW_PRE_POST_CONDITION: 'Show Pre/Post Conditions',
      HIDE_PRE_POST_CONDITION: 'Hide Pre/Post Conditions',
      NO_DATA_MSG: 'No data found',
      NO_RESULT_MSG: 'No results found'
    }
  };

  export const SELECTED_MODE={
    SINGLE_SELECTION_MODE:1,
    MULTI_HIERARCHIAL_SELECTION_MODE:3,
  } 
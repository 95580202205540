import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { Tooltip } from '@material-ui/core';
import { DeleteOutlined, DragIndicatorOutlined, EditOutlined } from '@material-ui/icons';
import React, { Fragment } from 'react';
import CommonTable from '../table/react-table';

const HistoryTD = () => {
  const search = window.location.search;
  const displayTable = new URLSearchParams(search).get('displayTable');

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(_data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setData(items);
  }
  let menuData = () => {
    return (
      <div className="actionIcons">
        {global.permission.isEditAccess('testDevelopment') && (
          <button
            // disabled={(defaultStepGroup && defaultStepGroup === "true") || variable.isSystemVariable}
            className={`float-left  text-base mr-3 text-blue-700 ${true ? 'opacity-50' : 'cursor-pointer'}`}
            // onClick={() => { editVariable(variable); }}
          >
            <Tooltip title="Edit">
              <EditOutlined />
            </Tooltip>
          </button>
        )}
        {global.permission.isFullAccess('testDevelopment') && (
          <button
            // disabled={(defaultStepGroup && defaultStepGroup === "true") || variable.isSystemVariable}
            className={`float-left  mr-3 text-blue-700 text-base ${false ? 'opacity-50' : 'cursor-pointer'}`}
            onClick={() => {
              // deleteVariable(variable);
            }}
          >
            <Tooltip title="Delete">
              <DeleteOutlined />
            </Tooltip>
          </button>
        )}
        <Menu as="div" className="relative inline-block text-left mt-px">
          {({ open }) => (
            <>
              <div>
                <Menu.Button className="bg-gray-100 rounded-full flex items-center  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 focus:ring-indigo-500">
                  <span className="sr-only">Open options</span>
                  <Tooltip title="More">
                    <span>
                      <DotsVerticalIcon className="h-5 w-5 configmoreIcon" aria-hidden="true" />
                    </span>
                  </Tooltip>
                </Menu.Button>
              </div>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${active ? 'bg-gray-100 text-gray-900 ' : 'text-gray-700 '}  block px-4 py-2 text-sm w-full text-left`}
                          onClick={() => {
                            // getVariableDetails(variable);
                          }}
                        >
                          Details
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    );
  };
  const [_data, setData] = React.useState(getData());
  const data = React.useMemo(() => [..._data], [_data]);

  const columns = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'slNo',
        width: '10px',
      },
      {
        Header: 'StepGroup',
        accessor: 'name',
        width: '40%',
      },
      {
        Header: 'If Failed',
        accessor: 'ifCheckPointIsFailed',
        width: '39%',
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: '10%',
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        width: '10%',
      },
    ],
    []
  );
  function getData() {
    let tabData = [];
    let i = 0;
    while (i < 5) {
      tabData.push({
        id: 'row' + i,
        slNo: (
          <span className="actionIcons">
            <DragIndicatorOutlined fontSize="small" className="text-blue-600" />{' '}
          </span>
        ),
        name: (
          <span className="text-blue-700 cursor-pointer fontPoppinsRegularMd" onClick={() => {}}>
            {' '}
            {i + '. abcfdh '}{' '}
          </span>
        ),
        ifCheckPointIsFailed: (
          <span className="text-gray-700 cursor-pointer fontPoppinsRegularMd" onClick={() => {}}>
            {' '}
            {i + '. abcfdh '}{' '}
          </span>
        ),
        status: <span className="text-blue-700 fontPoppinsRegularMd"> {i % 2 ? 'Enable' : 'Disable'}</span>,
        actions: menuData({}),
        iterVal: { name: 'ABCd', id: i + 'qw' },
      });
      i++;
    }
    return tabData;
  }

  return (
    <div className="page-table-body">
      <div className="content_area_style" id="journal-scroll">
        <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
          <label className="project_label text-sm flex-auto">History</label>
          {/* <div className="pr-4">
                        <div className="flex flex-row ml-32">
                            <Tooltip title='Search' placement='top'>
                                <SearchOutlined className="text-gray-500 ml-3 mt-1 cursor-pointer hover:text-blue-700" />
                            </Tooltip>
                        </div>
                    </div> */}
        </div>
        <div className="p-3">
          {/* react table testing table */}

          {displayTable === 'true' && (
            <CommonTable columns={columns} data={data} pageIndexValue={0} handleOnDragEnd={handleOnDragEnd} />
          )}
        </div>
      </div>
    </div>
  );
};

export default HistoryTD;

import React, { useEffect, useState, useContext } from 'react';
import RequestFormData from './request_form_data';
import UrlEnCoded from './request_url_form';
import Binary from './Binary';
import GraphQl from './GraphQl';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FileUploadButton from '@pagescommon/fileUpload_button';
import AceEditor from './Ace_Editor';
import { RestContext } from '@pages/test-development/script/scripts/webservice/restapi/restapi';
import '../css/request_body.css';
import '@pages/test-development/script/scripts/webservice/restapi/css/common_Webservice.scss';
import { PREREQUISITE_CONSTANT } from '@pages/test-development/script/scripts/webservice/restapi/request/prerequisite/jsx/constant';
import { Tooltip, TextField } from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MySelect from '@src/pages/common/Inputs/MySelect';
import { ASSERT_CONSTANT } from '../../assert/jsx/constant';
import beautify from 'js-beautify';
import xmlFormat from 'xml-formatter';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import beautifyIcon from '../images/beautify.svg';
import beautyNew from '../images/beautyNew.svg';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@src/css_config/colorConstants.js';
import { getAllFiles, getAllTestDataFiles } from '@api/api_services';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';
import { REGEX, WEBSERVICE_RAW_FILE_ALLOWED } from '@util/validations';
import {
  isEmptyValue,
  renderFilePath,
  searchFilePathBasedOnHash,
  pathName,
  validateFileExtension,
} from '@util/common_utils';
import { WEBSERVICE } from '@pages/test-development/script/scripts/webservice/restapi/constants/constants';
import { HASH_LABELS } from '@common/ui-constants';
import { ClickAwayListener } from '@mui/base';

const useStyles = makeStyles({
  customModal: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    border: 'none',
  },
  paper: {
    width: 450,
  },
  customModalBodyContainer: {
    width: '100%',
    whiteSpace: 'normal',
    boxShadow: `0px 0px 4px ${colors.shadow_black}`,
    overflow: 'hidden',
    height: '89%',
    backgroundColor: colors.text_white,
  },

  smallRadioButton: {
    '& svg': {
      width: '0.6em',
      height: '0.6em',
    },
  },
  customRadioButton: {
    '&.Mui-checked': {
      color: `${colors.button_blue_50}`,
      '& .MuiSvgIcon-root': {
        color: `${colors.button_blue_50}`,
      },
    },
    '&:not(.Mui-checked)': {
      color: colors.gray_radio_button,
    },
    '&:hover': {
      backgroundColor: 'transparent',
      pointerEvents: 'none',
    },
  },
  customAutocomplete: {
    width: '100%',
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
      border: 'none',
    },
    '& .MuiAutocomplete-input': {
      border: 'none',
      cursor: 'pointer',
      maxWidth: '300px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '& .MuiInputBase-root': {
      '& input': {
        padding: '10px 10px',
      },
    },
  },
  radioOne: {
    marginTop: '25px',
    padding: '9px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-checked': {
      color: `${colors.button_blue_50}`,
      '& .MuiSvgIcon-root': {
        color: `${colors.button_blue_50}`,
      },
    },
  },
  radioTwo: {
    marginTop: '10px',
    padding: '9px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-checked': {
      color: `${colors.button_blue_50}`,
      '& .MuiSvgIcon-root': {
        color: `${colors.button_blue_50}`,
      },
    },
  },
  customMenuItem: {
    paddingLeft: '10px',
  },
});
export default function DataRequestBodyGrid(props) {
  const context = useContext(RestContext);
  const selectedBodyType = context?.values?.requestBodyType;
  const [showModal, setShowModal] = useState(false);
  const [selectedApkFile, setselectedApkFile] = useState(
    isEmptyValue(context.values.rawData?.id) ? context.values.rawData : ''
  );
  const [choosenFile, setChoosenFile] = useState(context.values.rawData);
  const [showBtn, setShowbtn] = useState(true);
  const [openValue, setOpenValue] = useState(PREREQUISITE_CONSTANT.FALSE);
  const [selectedContentType, setSelectedContentType] = useState(context.values.rawContentType || 'application/json');
  const [disableButton, setDisableButton] = useState(true);
  const [uiData, setUIData] = useState(context.values.rawData?.value || null);
  const [activeHover, setActiveHover] = useState(false);
  const [selectedOption, setSelectedOption] = useState('test-data');
  const [testFileName, setTestFileName] = useState(
    isEmptyValue(context.values.rawData?.id) ? '' : context.values.rawData
  );

  const [testData, setTestData] = useState([]);
  const [localFileError, setLocalFileError] = useState('');
  const classes = useStyles();
  const [filterType, setFilterType] = useState('json');
  const typeOfModal = HASH_LABELS.WEB_SERVICE_TYPE_DROPDOWN;

  const { DEFAULT_RAW_DATA } = WEBSERVICE;

  const requestBodyRadioButton = [
    { value: 'raw', label: 'Raw' },
    { value: 'form-data', label: 'Form-data' },
    { value: 'x-www-form-urlencoded', label: 'x-www-form-urlencoded' },
    { value: 'none', label: 'None' },
  ];

  const {
    XML_FILE_TYPE_VALIDATION,
    HTML_FILE_TYPE_VALIDATION,
    BODY_TAG_TYPE_VALIDATION,
    JAVASCRIPT_FILE_TYPE_VALIDATION,
  } = REGEX;

  const [showFileSuggestion, setShowFileSuggestion] = useState(false);
  const [filePathArray, setFilePathArray] = useState([]);
  const [allFilePathArray, setAllFilePathArray] = useState([]);

  const showFilePaths = () => {
    setShowFileSuggestion(true);
  };
  const hideFilePaths = () => {
    setShowFileSuggestion(false);
  };
  useEffect(() => {
    onCheckSelectedFormat(selectedContentType, uiData);
  }, [uiData]);

  const changeHandler = (e) => {
    const fileExtension = e.target.files[0]?.name.slice(((e.target.files[0]?.name.lastIndexOf('.') - 1) >>> 0) + 2);
    if (fileExtension === filterType && validateFileExtension(e, WEBSERVICE_RAW_FILE_ALLOWED)) {
      setselectedApkFile(e.target.files[0]);
      setLocalFileError('');
      setShowbtn(true);
    } else {
      setLocalFileError('Invalid File Type');
      props?.MyAlert.warning(`${e.target.files[0].name} is unsupported.`);
    }
  };

  const handleOpen = () => {
    if (selectedApkFile?.id) {
      const fileExtension = selectedApkFile?.name?.slice(((selectedApkFile?.name?.lastIndexOf('.') - 1) >>> 0) + 2);
      onCheckLocalFileType(fileExtension, filterType);
    } else {
      setLocalFileError('');
    }
    setShowModal(true);
  };

  const setSelectedFile = async (e) => {
    setShowModal(false);
    if (showBtn) {
      if (selectedOption === 'local-drive') {
        setChoosenFile(selectedApkFile);
      } else {
        setChoosenFile(testFileName);
      }
    } else {
      setChoosenFile('');
      context.setFieldValue('rawData', DEFAULT_RAW_DATA);
    }
    props.handleSaveAsSteps(false);
    try {
      let fs = new FileReader();
      fs.readAsText(selectedApkFile);
      let result = '';
      fs.onloadend = () => {
        result = fs.result;
        if (showBtn) {
          context.setFieldValue('rawData', { ...context.values.rawData, value: result, name: selectedApkFile.name });
          setUIData(result);
        } else {
          setUIData('');
          setDisableButton(true);
        }
      };
    } catch (err) {
      console.error(err);
    }
  };

  const handleSeletedBodyChange = (e) => {
    context.setFieldValue('requestBodyType', e.target.value);
    if (e.target.value === 'form-data') {
      sessionStorage.setItem('arrayofContenttype', `multipart/form-data`);
    } else if (e.target.value === 'x-www-form-urlencoded') {
      sessionStorage.setItem('arrayofContenttype', `application/x-www-form-urlencoded`);
    } else if (e.target.value === 'binary') {
      sessionStorage.setItem('arrayofContenttype', `application/vnd`);
    } else if (e.target.value === 'none') {
      sessionStorage.setItem('arrayofContenttype', ``);
    } else {
      sessionStorage.setItem('arrayofContenttype', selectedContentType);
    }
    props.handleSaveAsSteps(false);
  };

  const handleSeletedContentChange = (e) => {
    const { value } = e.target;
    setSelectedContentType(value);
    sessionStorage.setItem('arrayofContenttype', value);
    context.values.rawContentType = value;
    props.handleSaveAsSteps(false);
    onCheckSelectedFormat(value, uiData);
  };

  const onCheckSelectedFormat = (selectedType, requestData) => {
    if (requestData) {
      const format = detectFormat(requestData);
      if (format !== ASSERT_CONSTANT.TEXT_FORMAT && selectedType === format) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    }
  };

  const handleRaw = (codeData, uiData) => {
    if (codeData) {
      const rawUIData = props.getUserInputValue(uiData, false);
      context.setFieldValue('rawData', { ...context.values.rawData, value: rawUIData });
    } else {
      context.setFieldValue('rawData', { ...context.values.rawData, value: uiData });
      setDisableButton(true);
    }
    setUIData(uiData);
    props.handleSaveAsSteps(false);
    onCheckSelectedFormat(selectedContentType, uiData);
  };

  const getStepsUpdate = () => {
    if (Object.keys(props.selectedStepData).length > 0) {
      const tempParmvalue = props.selectedStepData;
      context.values.requestBodyType = tempParmvalue.bodyType;
      const contentTypeValue = tempParmvalue?.headers?.find((item) => item?.name === 'Content-Type');
      let requestBodyType = contentTypeValue?.value;
      sessionStorage.setItem('arrayofContenttype', requestBodyType);
      let requestBody = tempParmvalue.requestBody;
      if (['multipart/form-data', 'application/x-www-form-urlencoded'].includes(requestBodyType)) {
        setSelectedContentType('application/json');
      } else {
        setSelectedContentType(requestBodyType);
        if (requestBody?.name) {
          setTestFileName(requestBody);
          setChoosenFile(requestBody);
        } else {
          const rawData = props.getUserInputValue(requestBody?.value || requestBody, true);
          setUIData(rawData);
        }
        onCheckSelectedFormat(requestBodyType, requestBody);
        context.values.rawData = requestBody;
      }
      if (typeof requestBody !== 'string' && !requestBody?.value) {
        setUIData('');
        context.setFieldValue('rawData', DEFAULT_RAW_DATA);
      }
    } else {
      context.values.rawData = DEFAULT_RAW_DATA;
    }
  };

  useEffect(() => {
    getStepsUpdate();
  }, [props.selectedStepData]);

  const getView = () => {
    switch (selectedBodyType) {
      case 'raw': {
        return (
          <div className="flex flex-col relative bg-white">
            <AceEditor
              className="min-h-160 response-body-code-editor-format"
              style={{ height: '293px', width: '98%' }}
              getCodeValue={handleRaw}
              rawValue={uiData ? uiData : props.getUserInputValue(context.values.rawData?.value, true)}
              isReadOnly={testFileName}
              {...props}
            />
          </div>
        );
      }
      case 'form-data': {
        return <RequestFormData {...props} />;
      }
      case 'x-www-form-urlencoded': {
        return <UrlEnCoded {...props} />;
      }
      case 'binary': {
        return <Binary handleSaveAsSteps={props.handleSaveAsSteps} />;
      }
      case 'graphQL': {
        return <GraphQl handleSaveAsSteps={props.handleSaveAsSteps} />;
      }
      case 'none': {
        return <NoneBody />;
      }
      default: {
        return <NoneBody />;
      }
    }
  };

  const verifyJsonUserInput = (code) => {
    try {
      JSON.parse(code);
      return true;
    } catch (error) {
      return false;
    }
  };

  const detectFormat = (code) => {
    if (HTML_FILE_TYPE_VALIDATION.test(code) && BODY_TAG_TYPE_VALIDATION.test(code)) {
      return ASSERT_CONSTANT.HTML_FORMAT;
    } else if (XML_FILE_TYPE_VALIDATION.test(code)) {
      return ASSERT_CONSTANT.XML_FORMAT;
    } else if (verifyJsonUserInput(code)) {
      return ASSERT_CONSTANT.JSON_FORMAT;
    } else if (JAVASCRIPT_FILE_TYPE_VALIDATION.test(code)) {
      return ASSERT_CONSTANT.JAVASCRIPT_FORMAT;
    } else {
      return ASSERT_CONSTANT.TEXT_FORMAT;
    }
  };

  const onBeautifyHandler = () => {
    try {
      let formattedCode = uiData;
      if (selectedContentType === 'application/xml') {
        formattedCode = xmlFormat(uiData, {
          indentation: '  ',
          filter: (node) => node.type !== 'Comment',
          collapseContent: true,
          lineSeparator: '\n',
        });
      } else if (selectedContentType === 'application/json') {
        formattedCode = JSON.stringify(JSON.parse(uiData), null, 2);
      } else if (selectedContentType === 'application/javascript') {
        formattedCode = beautify.js(uiData);
      } else if (selectedContentType === 'text/html') {
        formattedCode = beautify.html(uiData);
      }
      setUIData(formattedCode);
      const rawUIData = props.getUserInputValue(formattedCode, false);
      context.setFieldValue('rawData', { ...context.values.rawData, value: rawUIData });
    } catch (error) {
      console.error('Error beautifying code:', error);
    }
  };

  const handleChooseFile = (e) => {
    setSelectedOption(e.target.value);
  };
  const checkStartsWithHash = (e) => {
    const { inputValue, fileArrayOption, filteredArray } = searchFilePathBasedOnHash(
      e,
      filePathArray,
      allFilePathArray
    );
    if (fileArrayOption) {
      setSelectedOption(fileArrayOption.actualPath);
      hideFilePaths();
    } else if (!fileArrayOption) {
      if (inputValue.startsWith('#') && inputValue.length > 1) {
        showFilePaths();
        setFilePathArray(filteredArray);
      } else if (inputValue.startsWith('#')) {
        getAllFilePaths();
        showFilePaths();
      } else {
        setTestFileName(inputValue);
        hideFilePaths();
      }
    }
  };

  const handleOptionSelect = (option) => {
    hideFilePaths();
  };

  const getAllFilePaths = async (type) => {
    const seletedBodyType = ASSERT_CONSTANT.REQUEST_BODY_CONTENT_TYPE.find((value) => {
      if (value.label === selectedContentType) {
        return value.value;
      }
      return 0;
    });
    const seletedFileType =
      seletedBodyType.value === 'JavaScript'
        ? 'js'
        : seletedBodyType.value === 'Text'
        ? 'txt'
        : seletedBodyType.value.toLowerCase();
    try {
      const response = await getAllTestDataFiles(seletedFileType);
      const {
        data: { responseObject },
      } = response || {};
      if (responseObject?.length) {
        const folderPaths = responseObject.map((folder) => ({
          ...folder,
          path: pathName(folder.actualPath),
        }));
        setFilePathArray([...folderPaths]);
        setAllFilePathArray([...folderPaths]);
      } else {
        setFilePathArray([]);
      }
    } catch (error) {
      console.error('Get all file path :', error);
    }
  };
  const handleAutocompleteChange = (value) => {
    let folderId = value?.searchKey?.split('/');
    folderId = folderId[folderId.length - 2];
    const rawData = {
      ...context.values.rawData,

      name: value?.name,
      id: value?._id,
      folderId,
      value: value?.value,
      type: 'file',
    };
    setTestFileName(value);
    context.setFieldValue('rawData', rawData);
    setUIData('');
    hideFilePaths();
  };

  const getTestDataFileByType = async () => {
    try {
      const selctedProjectDetails = JSON.parse(localStorage.getItem('selected-project'));
      const seletedBodyType = ASSERT_CONSTANT.REQUEST_BODY_CONTENT_TYPE.find((value) => {
        if (value.label === selectedContentType) {
          return value.value;
        }
        return 0;
      });
      const projectId = selctedProjectDetails?.id;
      const seletedFileType =
        seletedBodyType?.value === 'JavaScript'
          ? 'js'
          : seletedBodyType?.value === 'Text'
          ? 'txt'
          : seletedBodyType?.value?.toLowerCase();
      const response = await getAllFiles(projectId, seletedFileType);
      const getTestDataResponse = response.data.responseObject;
      setFilterType(seletedFileType);
      setTestData(getTestDataResponse);
      if (getTestDataResponse && getTestDataResponse.length > 0) {
        setTestData(getTestDataResponse);
      } else {
        setTestData([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTestDataFileByType();
  }, [selectedContentType]);

  const onCancelChooseFile = () => {
    setShowModal(false);
    if (!choosenFile?.name) {
      setTestFileName('');
      setselectedApkFile('');
      setSelectedOption('test-data');
      setLocalFileError('');
    }
    if (choosenFile?.name !== setselectedApkFile?.name) setselectedApkFile(choosenFile);
  };

  const onCheckLocalFileType = (fileExtension, filterType) => {
    if (fileExtension?.toLowerCase() !== filterType) {
      setLocalFileError('Invalid File Type');
    } else {
      setLocalFileError('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <>
      <Modal open={openValue} className="modal-dialog">
        <div className="modal-container page-modal-size1">
          <div className="modal-header">
            <h2 className="title">{`Request-Body`}</h2>
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                setOpenValue(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="modal-body" id="journal-scroll">
            <div
              className="console_output_container_Response fontPoppinsRegularMd overflow-y-auto shadow"
              id="journal-scroll"
            >
              <AceEditor
                className="flex relative h-full  min-h-160 response-body-code-editor-format"
                id="journal-scroll"
                mode={'text'}
                rawValue={uiData ? uiData : props.getUserInputValue(context.values.rawData?.value, true)}
                isReadOnly={true}
                showGutter={false}
                highlightActiveLine={false}
                requestBodyExpandPopUp={'popUp'}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="cancel-button-common cancel-button-layout-style-project mr-3 fontPoppinsRegularMd"
              onClick={() => {
                setOpenValue(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
      <div className="flex flex-wrap overflow-x-auto ml-3 mr-3 h-11 bg-white justify-between items-center">
        <div className="flex items-center ml-4">
          <div className="inline-span fontPoppinsMediumSm select-none mr-3">
            <span className="text-red-400 mr-0.5">*</span>
            Body Type
          </div>
          <div className="inline-span -ml-2 flex">
            <FormControl>
              <RadioGroup
                row
                name="row-radio-buttons-group"
                value={selectedBodyType}
                onChange={handleSeletedBodyChange}
                className="smaller-radio"
              >
                {requestBodyRadioButton.map((radioButton, index) => (
                  <FormControlLabel
                    key={index}
                    className={`mr-4 ${classes.smallRadioButton}`}
                    value={radioButton.value}
                    control={<Radio className={`-ml-1 ${classes.customRadioButton}`} disableRipple={true} />}
                    label={<span className={`-ml-1 mr-4 body-type-bold`}>{radioButton.label}</span>}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className="flex items-center">
          {selectedBodyType === 'raw' && (
            <div className="mr-5">
              <CustomTooltip placement="bottom" title="beautify">
                {disableButton ? (
                  <img
                    className="opacity-50 customize-beautify-disable-button cursor-none"
                    src={beautifyIcon}
                    alt="showmore"
                    draggable="false"
                  />
                ) : (
                  <img
                    className="customize-beautify-button cursor-pointer"
                    src={activeHover ? beautyNew : beautifyIcon}
                    alt="showmore"
                    onClick={onBeautifyHandler}
                    onMouseOver={() => setActiveHover(true)}
                    onMouseLeave={() => setActiveHover(false)}
                    draggable="false"
                  />
                )}
              </CustomTooltip>
            </div>
          )}
          <div className="inline-span">
            {selectedBodyType === 'raw' && (
              <div className="">
                <FormControl>
                  <MySelect
                    variableInput={'requestBodyMain'}
                    background={`0% 0% no-repeat padding-box`}
                    verticalAnchor={'bottom'}
                    verticalTransform={'top'}
                    marginTop={'3px'}
                    marginRight={'3px'}
                    classSelect={'request-body-select-dropdown w-28 h-8'}
                    name="bodyType"
                    value={selectedContentType}
                    onChange={handleSeletedContentChange}
                    borderRadius={'5px'}
                    MenuProps={{
                      PaperProps: {
                        className: 'content-type-dropdown',
                      },
                    }}
                  >
                    {ASSERT_CONSTANT.REQUEST_BODY_CONTENT_TYPE.map((value, index) => {
                      return (
                        <MenuItem className={classes.customMenuItem} key={index} value={value.label}>
                          <span className="text-xs">{value.value}</span>
                        </MenuItem>
                      );
                    })}
                  </MySelect>
                </FormControl>
              </div>
            )}
          </div>
          <div className="inline-span">
            {selectedBodyType === 'raw' && (
              <div className=" flex ml-5 select-none">
                <div>
                  <span
                    onClick={handleOpen}
                    className="select-file-primary-button-request-body fontPoppinsRegularXs cursor-pointer hover-item "
                  >
                    {choosenFile?.name ? 'Replace file' : 'Choose file'}
                    <AttachFileIcon className="choose-file-icon"></AttachFileIcon>
                  </span>
                </div>
                <div className="mt-2 flex select-none">
                  {choosenFile?.name && (
                    <Tooltip title={choosenFile?.name} placement="bottom">
                      <span className="no-select-file-span input-filed-label fontPoppinsRegularSm cursor-pointer pl-2">
                        {choosenFile?.name}
                      </span>
                    </Tooltip>
                  )}
                  {choosenFile?.name && (
                    <Tooltip title={'Remove File'} placement="bottom">
                      <span
                        className="text-blue-700 cursor-pointer ml-1 cross-icon-margin"
                        onClick={() => {
                          setChoosenFile('');
                          setselectedApkFile('');
                          context.setFieldValue('rawData', DEFAULT_RAW_DATA);
                          setUIData('');
                          setTestFileName('');
                        }}
                      >
                        <img className="w-4 h-4" alt="" src="/assets/images/close_black1.svg" />
                      </span>
                    </Tooltip>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>{getView()}</div>
      <div>
        <Modal open={showModal} className={`${classes.customModal} modal-dialog`}>
          <div className={`${classes.paper} page-modal-size1`}>
            <div className="modal-header-api">
              <div className="pl-2">
                <h2 className="title white fontPoppinsMediumSm">Choose File</h2>
              </div>
              <IconButton
                color="primary"
                component="span"
                className="close"
                onClick={() => {
                  setShowModal(false);
                  if (!choosenFile) {
                    setselectedApkFile('');
                    setTestFileName('');
                  }
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className={`${classes.customModalBodyContainer} modal-body`} id="journal-scroll">
              <form className="pl-4">
                <RadioGroup value={selectedOption} onChange={handleChooseFile}>
                  <FormControlLabel
                    value="test-data"
                    control={<Radio className={classes.radioOne} size="small" />}
                    label={
                      <div className="mt-2 width-380">
                        <div className="mt-2 text-sm text-blue-700">
                          <span className="fontPoppinsMediumSm choose-file-header">
                            {HASH_LABELS?.FILE_FROM_TEST_DATA}
                          </span>
                          <TextField
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder={HASH_LABELS?.SELECT_PATH}
                            variant="outlined"
                            onChange={(e) => checkStartsWithHash(e)}
                            className={classes.customAutocomplete}
                            value={testFileName?.name}
                            id="value"
                            disabled={selectedApkFile?.name || selectedOption === 'local-drive'}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                          {showFileSuggestion && (
                            <ClickAwayListener onClickAway={hideFilePaths}>
                              <div className="absolute">
                                {renderFilePath(filePathArray, handleAutocompleteChange, typeOfModal)}
                              </div>
                            </ClickAwayListener>
                          )}
                        </div>
                      </div>
                    }
                  />
                  <FormControlLabel
                    value="local-drive"
                    control={<Radio className={classes.radioTwo} size="small" />}
                    label={
                      <div className="fontPoppinsMediumMd flex items-center">
                        <span className="fontPoppinsRegularSm choose-file-header">File From Local Drive</span>
                        <FileUploadButton
                          name="value"
                          id="value"
                          onChange={changeHandler}
                          showBtn={showBtn}
                          setShowBtn={setShowbtn}
                          value={selectedApkFile?.name}
                          disabled={testFileName || selectedOption === 'test-data'}
                          setLocalFileName={setselectedApkFile}
                          accept={'.' + filterType}
                          setLocalFileError={setLocalFileError}
                          webServiceUploadBtn={true}
                        />
                      </div>
                    }
                  />
                </RadioGroup>
              </form>
            </div>
            <div className="modal-footer-section">
              <div className="float-right">
                <button className="modal-close-btn" onClick={onCancelChooseFile}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className={`fontMontserratMediumXs modal-save-btn ${localFileError && 'opacity-50'}`}
                  onClick={setSelectedFile}
                  disabled={localFileError}
                >
                  Save
                </button>
              </div>
              {/* </form> */}
            </div>
            <div className="modal-footer-section">
              <div className="float-right">
                <button
                  className=" modal-close-btn"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className=" modal-save-btn" onClick={setSelectedFile}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}
const NoneBody = () => {
  return (
    <>
      <div className="flex flex-wrap items-center gap-of-request-form-data-header">
        <span className="ml-5"></span>
      </div>

      <div className="m-auto text-sm background-body-type-none request-body-none-center fontPoppinsRegularLg">
        <div className="none-body-border">
          <div className="none-body-request">This request does not have any body !</div>
          <div>
            Please change the <span className="body-type-bold fontPoppinsSemiboldLg">Body Type</span> to add the request
            body.
          </div>
        </div>
      </div>
    </>
  );
};

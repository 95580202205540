import ChartCard from '../components/ChartCard';
import DashboardStyle from '../styles/dashboard.module.scss';
import cx from 'classnames';
import Chart from 'react-apexcharts';
import { useMemo, useRef } from 'react';
import ProgressLine from '../components/ProgressLine';
import { _getKBFromStorageString } from './CommonMethods';
import { fontPoppins } from '@src/css_config/fontConstants';

function handleChartElementsStyle(element) {
  if (element) {
    const totalValueElement = element.querySelector('.apexcharts-datalabel-value');
    if (totalValueElement) {
      const offsetYOfTotalValueElement = totalValueElement?.getAttribute('y');
      const offsetYOfTotalValueElementSubtract = Number(offsetYOfTotalValueElement) - 35;
      const totalValueLabelElement = element.querySelector('.apexcharts-datalabel-label');
      totalValueLabelElement?.setAttribute('y', offsetYOfTotalValueElementSubtract);
    }

    const donutChartCircleElement = element.querySelector('.apexcharts-pie circle');
    const getDonutChartCircleRValue = donutChartCircleElement.getAttribute('r');
    const setDountChartCirlceRValue = Number(getDonutChartCircleRValue) - 12;
    if (donutChartCircleElement) {
      donutChartCircleElement.setAttribute('stroke', '#81B9D7');
      donutChartCircleElement.setAttribute('r', setDountChartCirlceRValue);
      donutChartCircleElement.setAttribute('stroke-width', 5);
    }
    const titleTextElement = element.querySelector('.apexcharts-title-text');
    const parentElementWidth = element.offsetWidth;

    if (titleTextElement && parentElementWidth) {
      titleTextElement.setAttribute('x', parentElementWidth / 2);
    }
  }
}

const _showNumberAfterFirstDecimal = (value, label) => {
  const [storageNumber, storageUnit] = value?.split(' ');
  if (Number.isInteger(storageNumber)) {
    const number = storageNumber.toFixed();
    return `${number} ${storageUnit}`;
  }
  if (label === 'assigned memory') {
    const number = parseFloat(storageNumber).toFixed(2);
    return `${number} ${storageUnit}`;
  } else {
    const number = parseFloat(storageNumber).toFixed(1);
    return `${number} ${storageUnit}`;
  }
};

function MemoryTotalConsumptionAllProject({ totalConsumption }) {
  const legendMemory = totalConsumption?.totalMemory;
  const noData = {
    color: '#81B9D7',
    label: 'nodata',
  };
  const ref = useRef();
  const options = useMemo(
    () => ({
      chart: {
        events: {
          mounted: () => {
            handleChartElementsStyle(ref?.current);
          },
          updated: () => {
            handleChartElementsStyle(ref?.current);
          },
        },
        type: 'donut',
      },
      colors: ['#EC6666', '#EBF2F7', noData.color],

      tooltip: {
        enabled: false,
      },
      stroke: {
        width: 0,
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            labels: {
              show: true,
              value: {
                fontFamily: fontPoppins.sDm.fontFamily,
                fontWeight: 600,
                color: '#333333',
              },
              total: {
                showAlways: true,
                show: true,
                ...fontPoppins.rMd,
                color: '#B3B8BD',
                label: 'Total Memory',
                formatter: (w) => {
                  return w?.config?.legendMemory || '10 GB';
                },
              },
            },
          },
        },
      },
      labels: ['Consumped', 'Available', noData.label],
      dataLabels: {
        enabled: false,
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
          },
        },
      },
      legend: {
        show: false,
      },
      fill: {
        type: ['solid', 'pattern'],
        opacity: 1,
        rotate: -90,
        gradient: {
          type: 'horizontal',
          rotate: -90,
        },
        pattern: {
          enabled: true,
          style: 'slantedLines',
          strokeWidth: 1,
          rotate: -90,
        },
      },
      legendMemory: legendMemory,
    }),
    [legendMemory]
  );
  const { totalMemory, assignedMemory, usedMemory } = totalConsumption || {
    totalMemory: '10 GB',
    assignedMemory: '0.0001 GB',
    usedMemory: '0 GB',
  };
  const totalMemoryInKb = _getKBFromStorageString(totalMemory);
  const assignedMemoryInKb = _getKBFromStorageString(assignedMemory);
  const usedMemoryInKb = _getKBFromStorageString(usedMemory);
  const totalAvailabelMemoryInKb = totalMemoryInKb - assignedMemoryInKb;
  return (
    <ChartCard
      className={cx('col-span-5 grid grid-cols-2 fontPoppinsSemiboldSm', DashboardStyle['memory-total-consumption'])}
    >
      <div ref={ref}>
        <Chart
          options={options}
          series={[assignedMemoryInKb || 10, totalAvailabelMemoryInKb || 10]}
          type="donut"
          height="80%"
          className="py-8"
        />
      </div>
      <div className={cx('px-4 py-3', DashboardStyle['legend-wrapper'])}>
        <h5 className=" fontPoppinsSemiboldLg mb-6">Total Consumption</h5>
        <div className="my-4 text-sm">
          <p className="mb-1 fontPoppinsRegularMd">Total Memory</p>
          <ProgressLine total={totalMemoryInKb} filled={totalMemoryInKb} filledColor="#81B9D7" />
          <span className={DashboardStyle['memory-total-consumption-span']}> Total : {totalMemory} </span>
        </div>
        <div className="my-4 text-sm">
          <p className="mb-1 fontPoppinsRegularMd">Assigned Memory</p>
          <ProgressLine total={totalMemoryInKb} filled={assignedMemoryInKb} filledColor="#EC6666" />
          <span className={DashboardStyle['memory-total-consumption-span']}>
            {_showNumberAfterFirstDecimal(assignedMemory, 'assigned memory')} assigned from {totalMemory}
          </span>
        </div>
        <div className="my-4 text-sm">
          <p className="mb-1 fontPoppinsRegularMd">Used Memory</p>
          <ProgressLine total={totalMemoryInKb} filled={usedMemoryInKb} filledColor="#147AD6" />
          <span className={DashboardStyle['memory-total-consumption-span']}>
            {_showNumberAfterFirstDecimal(usedMemory, 'used memory')} used from {totalMemory}
          </span>
        </div>
      </div>
    </ChartCard>
  );
}

export default MemoryTotalConsumptionAllProject;

import React, { useRef } from 'react';
import Modal from '@material-ui/core/Modal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Alert from '@mui/material/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import InfoGreyIcon from '@pages/test-development/script/scripts/webservice/restapi/response/assert_results/images/info-icon-grey.svg';
import { Tooltip } from '@material-ui/core';
import InfoBlueIcon from '@pages/test-development/script/scripts/webservice/restapi/response/assert_results/images/info-blue.svg';
import { WEBSERVICE } from '@pages/test-development/script/scripts/webservice/restapi/constants/constants';
import { Button } from 'fireflink-ui';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-github';
import 'brace/mode/javascript';
import 'brace/theme/github';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@src/css_config/colorConstants.js';

const useStyles = makeStyles(() => ({
  tooltip: {
    background: `${colors.black_border} 0% 0% no-repeat padding-box`,
    boxShadow: `0px 0px 6px ${colors.rs_primary}`,
    opacity: 1,
  },
}));

const GenerateCurlModal = ({
  classes,
  isModalOpen,
  isCopied,
  curlData,
  handleCopyClick = () => {},
  handleCurlModalClick = () => {},
  handleMouseOver = () => {},
  handleMouseOut = () => {},
  title,
  iconHovered,
  getCodeValue = () => {},
  isValidImportCurl,
}) => {
  const {
    CLOSE_TEXT,
    COPY_CURL_TEXT,
    CURL_COPY_SUCCESS,
    CURL_TEXT,
    CURL_WARNING_INFO,
    IMPORT_TEXT,
    IMPORT,
    DEFAULT_IMPORT_CURL,
    ONCHANGE,
    ONPASTE,
  } = WEBSERVICE;
  const editorRef = useRef(null);
  const tooltipClasses = useStyles();
  const onImportCurlKeyEnter = (event, editor) => {
    const content = editor.getValue();

    if (event.key === 'Enter' && !event.ctrlKey) {
      getCodeValue(content, ONPASTE);
      return;
    }

    if (event.key === 'Enter' && event.ctrlKey) {
      editor.insert('\n');
      return;
    }
  };

  const onLoad = (editor) => {
    editorRef.current = editor;
    editor.commands.addCommand({
      name: 'customEnter',
      bindKey: { win: 'Enter', mac: 'Enter' },
      exec: (editor) => onImportCurlKeyEnter({ key: 'Enter', ctrlKey: false }, editor),
    });

    editor.commands.addCommand({
      name: 'customCtrlEnter',
      bindKey: { win: 'Ctrl-Enter', mac: 'Command-Enter' },
      exec: (editor) => onImportCurlKeyEnter({ key: 'Enter', ctrlKey: true }, editor),
    });
  };

  return (
    <Modal open={isModalOpen} className={`${classes.customModal} modal-dialog`}>
      <div className={`${classes.paper} page-modal-size1`}>
        <div className="curl-modal-header">
          <div className="pl-2">
            <h2 className="title white fontPoppinsMediumSm">{title}</h2>
          </div>
          {isCopied && (
            <Alert
              classes={{ root: `${classes.root}` }}
              action={
                <IconButton aria-label="close" size="small" color="inherit" onClick={handleCopyClick}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              severity="success"
            >
              <span className="fontPoppinsMediumSm h-1 flex">{CURL_COPY_SUCCESS}</span>
            </Alert>
          )}
          <IconButton color="primary" component="span" className="close" onClick={handleCurlModalClick}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={`${classes.customModalBodyContainer} modal-body`} id="journal-scroll">
          {isModalOpen === IMPORT && (
            <div className="flex">
              <span className="import-curl-text">{CURL_TEXT}</span>
              <Tooltip
                open={iconHovered}
                onClose={handleMouseOut}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={<span>{CURL_WARNING_INFO}</span>}
                placement="right"
                classes={{ tooltip: tooltipClasses.tooltip }}
              >
                <img
                  src={iconHovered ? InfoBlueIcon : InfoGreyIcon}
                  alt=""
                  className="assert-results-tooltip-icon cursor-pointer ml-2"
                  height="12px"
                  width="12px"
                  draggable="false"
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                />
              </Tooltip>
            </div>
          )}
          <AceEditor
            className="ace_Gutter flex relative min-h-160  response-body-code-editor-format curl-code-editor-style mt-4.5"
            theme={isModalOpen === IMPORT ? 'github' : 'tomorrow'}
            name={isModalOpen === IMPORT ? 'code-editor' : 'editor'}
            height={518}
            width={417}
            id="journal-scroll"
            showGutter={true}
            editorProps={{
              $blockScrolling: true,
            }}
            wrapEnabled={true}
            value={curlData}
            readOnly={isModalOpen === IMPORT ? false : true}
            onChange={(event) => {
              getCodeValue(event, ONCHANGE);
            }}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
              showLineNumbers: true,
              useSoftTabs: true,
              indentedSoftWrap: false,
              placeholder: `${DEFAULT_IMPORT_CURL}`,
            }}
            style={isModalOpen !== IMPORT ? { marginTop: '20px' } : {}}
            onPaste={(event) => {
              getCodeValue(event, ONPASTE);
            }}
            onLoad={onLoad}
          />
        </div>
        <div className="curl-modal-footer">
          <div className="float-right">
            <Button label={CLOSE_TEXT} className="modal-close-btn" variant="secondary" onClick={handleCurlModalClick} />
            {isModalOpen === IMPORT ? (
              <Button
                label={IMPORT_TEXT}
                className="fontMontserratMediumXs modal-save-btn"
                onClick={() => getCodeValue(curlData, ONPASTE)}
                disabled={!curlData || !isValidImportCurl}
              />
            ) : (
              <CopyToClipboard text={curlData} onCopy={handleCopyClick}>
                <Button label={COPY_CURL_TEXT} className="fontPoppinsMediumXs modal-save-btn" disabled={!curlData} />
              </CopyToClipboard>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GenerateCurlModal;

import ChartCard from '../components/ChartCard';
import Chart from 'react-apexcharts';
import { useMemo, useRef } from 'react';
import cx from 'classnames';
import DashboardStyle from '../styles/dashboard.module.scss';
import { _showNumberAfterFirstDecimal } from './CommonMethods';

function commonCustomTooltip({ seriesIndex, w }) {
  const { labels, colors } = w.globals;
  const { usedMemory } = w.config;
  const usedMemoryTOFirstDecimal = _showNumberAfterFirstDecimal(usedMemory);
  return labels?.[seriesIndex] !== 'nodata'
    ? `
      <div class='${seriesIndex === 0 ? 'px-4 py-2 float-right  border-2 rounded-lg' : 'hidden'}' style="border-color: ${colors?.[0]}">
        <span class='bg-inherit'>${seriesIndex === 0 && `Used Memory : ${usedMemoryTOFirstDecimal}`}  </span>
      </div>
    `
    : null;
}

function handleChartInsideElementsStyle(element) {
  if (element) {
    const dataLabelLabel = element.querySelector('.apexcharts-datalabel-label');
    if (dataLabelLabel) {
      dataLabelLabel.style['visibility'] = 'hidden';
    }
    const dataLabelValue = element.querySelector('.apexcharts-datalabel-value');
    if (dataLabelValue) {
      dataLabelValue.setAttribute('y', 44);
      dataLabelValue.style['font-size'] = '14px';
      dataLabelValue.style['font-weight'] = '700';
    }
  }
}

const _getStorageWithUnitString = (totalKB) => {
  let storageNumber = totalKB;
  const units = ['KB', 'MB', 'GB', 'TB'];
  let storageunitWithString = '';
  for (let unitIndex = 0; unitIndex <= units.length; unitIndex++) {
    const convertedStorage = storageNumber / 1024;
    if (convertedStorage < 1) {
      storageunitWithString = `${storageNumber.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })} ${units[unitIndex]}`;
      break;
    } else {
      storageNumber = convertedStorage;
    }
  }
  return storageunitWithString;
};

const _getKBFromStorageString = (storageString) => {
  const [storageNumber, storageUnit] = storageString.split(' ');
  if (storageUnit?.toLowerCase() === 'tb') {
    return +storageNumber * 1024 * 1024 * 1024;
  }
  if (storageUnit?.toLowerCase() === 'gb') {
    return +storageNumber * 1024 * 1024;
  }
  if (storageUnit?.toLowerCase() === 'mb') {
    return +storageNumber * 1024;
  }
  if (storageUnit?.toLowerCase() === 'bytes') {
    return +storageNumber / 1000;
  }
  return +storageNumber;
};

export const showNumberAfterFirstDecimal = (value) => {
  const [storageNumber, storageUnit] = value?.split(' ');
  if (Number.isInteger(+storageNumber)) {
    const number = parseInt(storageNumber).toFixed();
    return `${number} ${storageUnit}`;
  }
  const number = parseFloat(storageNumber).toFixed(2);
  return `${number} ${storageUnit}`;
};

function IndividualMemoryTypeConsumption({
  totalRemainingMemory,
  name,
  legendAssignedMemory,
  assignedMemory,
  customTooltipFormatter = commonCustomTooltip,
  usedMemory,
}) {
  const ref = useRef();

  const totalRemainingMemoryInKb = _getKBFromStorageString(totalRemainingMemory);
  const usedMemoryInKb = _getKBFromStorageString(usedMemory);
  const totalAvailableMemory = totalRemainingMemoryInKb - usedMemoryInKb;
  const options = useMemo(
    () => ({
      chart: {
        redrawOnWindowResize: true,
        redrawOnParentResize: true,
        events: {
          mounted: () => {
            handleChartInsideElementsStyle(ref?.current);
          },
          updated: () => {
            handleChartInsideElementsStyle(ref?.current);
          },
          mouseMove: () => {
            const element = ref.current;
            if (element) {
              const tooltipElement = element.querySelector('.apexcharts-tooltip');
              if (tooltipElement?.style?.left) {
                tooltipElement.style['overflow'] = 'hidden';
                tooltipElement.style.left = 0;
                tooltipElement.style.top = 10;
                tooltipElement.style['margin-left'] = '-110px';
                tooltipElement.style['margin-top'] = '25px';
              }
            }
          },
        },
      },
      colors: ['#147AD6', '#7388A95C'],
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: '90%',
            labels: {
              show: true,
              offsetY: -230,
              total: {
                show: true,
                showAlways: true,
                // label: '50 MB',
                fontSize: '12px',
                color: '#000000',
                fontWeight: '700',
                formatter: (w) => {
                  return _showNumberAfterFirstDecimal(w?.config?.legendAssignedMemory);
                },
              },
            },
          },
        },
      },
      labels: ['Used Memory', 'Available Memory'],
      stroke: {
        lineCap: 'round',
        width: 0,
      },
      legend: {
        show: false,
        onItemClick: {
          toggleDataSeries: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
          },
        },
      },
      tooltip: {
        enabled: true,
        custom: customTooltipFormatter,
      },
      totalAvailableMemory: _getStorageWithUnitString(totalAvailableMemory),
      usedMemory: usedMemory,
      assignedMemory: assignedMemory,
      legendAssignedMemory: legendAssignedMemory,
    }),
    [usedMemory, legendAssignedMemory]
  );

  return (
    <>
      <ChartCard>
        <div className="flex w-full whitespace-nowrap overflow-hidden ">
          <div className={cx('flex flex-col', DashboardStyle['small-radical-width-70'])}>
            <h2 className={cx('ml-2.5 mt-2.5', DashboardStyle['individual-memory-consumption'])}>{name}</h2>
            <p className="ml-2 mt-3.5 fontPoppinsSemiboldXs">
              Total Available Memory :{' '}
              <span className="font-semibold">{showNumberAfterFirstDecimal(totalRemainingMemory)}</span>
            </p>
            <p className="ml-2.5 mt-2.5 fontPoppinsSemiboldXs">
              {' '}
              Used Memory : <span className="font-semibold">{_showNumberAfterFirstDecimal(usedMemory)} </span>
            </p>
          </div>
          <div className={cx('mt-5', DashboardStyle['small-radical-width-30'])} ref={ref}>
            <Chart
              type="donut"
              options={options}
              series={[usedMemoryInKb, totalRemainingMemoryInKb || 1]}
              height="70%"
            />
          </div>
        </div>
      </ChartCard>
    </>
  );
}

export default IndividualMemoryTypeConsumption;

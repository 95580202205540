import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import Dataproviders from './dataproviders/dataproviders';
import DependsOnScripts from './depends-on-scripts/depends-on-scripts';
import PrePostConditionRouter from './pre-post-conditions/pre-post-condition-router';
import ModulePrePostConditionRouter from '../../modules/settings/pre-post-condition.js/pre-post-condition-router';
import Restapi, { RestApiProvider } from '../webservice/restapi/restapi';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { ASSERT_CONSTANT } from '@pages/test-development/script/scripts/webservice/restapi/request/assert/jsx/constant';

const SettingsRouter = (props) => {
  const index = props.location.pathname.lastIndexOf('/');
  const path = props.location.pathname.slice(0, index);
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  return (
    <RestApiProvider>
      <Switch>
        {[ASSERT_CONSTANT.ROOT, ASSERT_CONSTANT.MODULE].includes(props?.label) ? (
          <>
            <Route
              path={`${path}/webservice-authorization`}
              exact={true}
              strict={true}
              key="webservice-authorization-page"
            >
              <Restapi
                {...props}
                resetProgress={resetProgress}
                startOrResumeProgress={startOrResumeProgress}
                stopProgress={stopProgress}
                MyAlert={props.MyAlert}
                label={props?.label}
                type={props?.label}
              />
            </Route>
            <Route path={`${path}/Pre Condition`} exact={true} strict={true} key="pre-condition-page">
              <ModulePrePostConditionRouter {...props} MyAlert={props.MyAlert} />
            </Route>
            <Route path={`${path}/Post Condition`} exact={true} strict={true} key="post-condition-page">
              <ModulePrePostConditionRouter {...props} MyAlert={props.MyAlert} />
            </Route>
          </>
        ) : (
          <>
            <Route path={`${path}/Depends on Scripts`} exact={true} strict={true} key="depends-on-script-page">
              <DependsOnScripts {...props} MyAlert={props.MyAlert} />
            </Route>
            <Route path={`${path}/Dataproviders`} exact={true} strict={true} key="dataproviders-page">
              <Dataproviders {...props} MyAlert={props.MyAlert} />
            </Route>
            <Route
              path={`${path}/webservice-authorization`}
              exact={true}
              strict={true}
              key="webservice-authorization-page"
            >
              <Restapi
                {...props}
                resetProgress={resetProgress}
                startOrResumeProgress={startOrResumeProgress}
                stopProgress={stopProgress}
                MyAlert={props.MyAlert}
                label="script"
                type="script"
              />
            </Route>
            <Route path={`${path}/Pre Condition`} exact={true} strict={true} key="pre-post-condition-page">
              <PrePostConditionRouter {...props} MyAlert={props.MyAlert} />
            </Route>
            <Route path={`${path}/Post Condition`} exact={true} strict={true} key="post-condition-page">
              <PrePostConditionRouter {...props} MyAlert={props.MyAlert} />
            </Route>
          </>
        )}
      </Switch>
    </RestApiProvider>
  );
};

export default SettingsRouter;

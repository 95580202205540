import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import MachineLandingPage from './create-edit-child-pages/run-settings-child-pages/machine-landing-page';
import WaitTime from './create-edit-child-pages/run-settings-child-pages/wait-time';
import ExecutionTermination from './create-edit-child-pages/run-settings-child-pages/execution-termination';
import { getWaitConfigReq, getScreenVideoReq } from '@api/api_services';
import UserMachinePage from './create-edit-child-pages/run-settings-child-pages/user-page';
import { useAlert } from '@pages/common/alert_service/useAlert';
import ResultSettings from './create-edit-child-pages/run-settings-child-pages/result-settings';
import { getManualDistributionData } from './suite.utils';

Modal.setAppElement('#root');

let runSettingsGlobalObj = {
  executionTermination: {
    terminateScriptIfTakesMoreTime: 0,
    terminateScriptUnit: 'Hrs.',
    terminateScriptSendEmail: false,
    terminateSuiteIfTakesMoreTime: 0,
    terminateSuitetUnit: 'Hrs.',
    terminateSuiteSendEmail: false,
  },
};
const SuiteRunSettings = (props) => {
  const { AlertContatiner, MyAlert } = useAlert();
  const _machines = props?.suiteObjMain?.machines;
  const [singleJsonData, setSingleJsonData] = useState(
    props?.suiteType !== 'Manual' ? (_machines?.multiple === 'false' ? [..._machines?.selectedMachines] : []) : []
  );
  const [multipleJsonData, setMultipleJsonData] = useState(
    props?.suiteType !== 'Manual'
      ? _machines?.multiple === 'true' && _machines?.executionType === 'SEND_SUITE_TO_ALL'
        ? [..._machines?.selectedMachines]
        : []
      : []
  );
  const [automaticJsonData, setAutomaticJsonData] = useState([]);
  const [manualJsonData, setManualJsonData] = useState(
    _machines?.multiple === 'true' &&
      _machines?.executionType === 'DISTRIBUTE' &&
      _machines?.distributionMechanism === 'MANUAL'
      ? [..._machines?.selectedMachines]
      : []
  );
  const [manualDistribution, setManualDistribution] = useState(getManualDistributionData(props.suiteObjMain.machines));
  const [distributionMechanism, setDistributionMechanism] = useState(
    _machines?.multiple === 'true'
      ? _machines?.distributionMechanism === 'AUTOMATIC'
        ? 'AUTOMATIC'
        : _machines?.distributionMechanism === 'MANUAL'
          ? 'MANUAL'
          : 'MULTIPLE'
      : null
  );
  const [waitTimeData, setWaitTimeData] = useState(
    props && props.suiteObjMain && props.suiteObjMain.waitTime ? props.suiteObjMain.waitTime : null
  );
  const [resultSettingsData, setResultSettingsData] = useState(
    props && props.suiteObjMain && props.suiteObjMain.resultSetting ? props.suiteObjMain.resultSetting : null
  );

  const [multiple, setMultiple] = useState(
    props?.suiteObjMain?.suiteType !== 'Manual' && props.suiteObjMain.machines?.multiple === 'true' ? 'true' : 'false'
  );

  const [executionTerminationData, setExecutionTerminationData] = useState(
    props && props.suiteObjMain && props.suiteObjMain.executionTermination
      ? props.suiteObjMain.executionTermination
      : null
  );

  const [runSettingsObj, setRunSettingsObj] = useState({
    waitTime: {},
    resultSetting: {
      captureScreenshots: 'For Failed steps only',
      captureVideoForFailedTestScript: false,
      displayLogsInScriptResult: 'On Suite Execution Completion / Termination',
    },
    executionTermination: {
      terminateScriptIfTakesMoreTime: 0,
      terminateScriptUnit: 'Hrs.',
      terminateScriptSendEmail: false,
      terminateSuiteIfTakesMoreTime: 0,
      terminateSuitetUnit: 'Hrs.',
      terminateSuiteSendEmail: false,
    },
    machines: {},
  });
  const runSettingsObjRef = useRef({});
  runSettingsObjRef.current = runSettingsObj;
  const [runSettingsManualObj, setRunSettingsManualObj] = useState({ ...props.runSettingsManualObject });
  const runSettingsManualObjRef = useRef({});
  runSettingsManualObjRef.current = runSettingsManualObj;

  React.useEffect(() => {
    if (props.operationForRunSettings === 'create') {
      let projectId = JSON.parse(localStorage.getItem('selected-project')).id;
      getWaitConfigReq(projectId).then((results) => {
        let waitTimeData = results.data.responseObject;
        let tempRunSettingsObj = { ...runSettingsObjRef.current };
        let waitTime = {
          implicitWait: JSON.stringify(waitTimeData?.implicitWait),
          explicitlyWait: JSON.stringify(waitTimeData.explicitWait),
          delayBetweenSteps: JSON.stringify(waitTimeData.delayBetweenSteps),
          implicitWaitUnit: waitTimeData.implicitWaitUnit === 'seconds' ? 'Secs' : 'Msecs',
          explicitlyWaitUnit: (waitTimeData.explicitlyWaitUnit =
            waitTimeData.explicitWaitUnit === 'seconds' ? 'Secs' : 'Msecs'),
          delayBetweenStepsUnit: waitTimeData.delayBetweenStepsUnit === 'seconds' ? 'Secs' : 'Msecs',
        };
        tempRunSettingsObj['waitTime'] = waitTime;
        runSettingsGlobalObj.waitTime = waitTime;
        setWaitTimeData(waitTime);
        props?.setWaitTime(waitTime);
        setRunSettingsObj(tempRunSettingsObj);
      });
      getScreenVideoReq(projectId).then((results) => {
        let runSettingsData = results.data.responseObject;
        let tempRunSettingsObj = { ...runSettingsObjRef.current };
        let resultData = {
          captureScreenshots:
            runSettingsData?.captureScreenShotFor === 'allSteps'
              ? 'For all Steps'
              : runSettingsData?.captureScreenShotFor === 'failedSteps'
                ? 'For Failed steps only'
                : "Don't Capture",
          captureVideoForFailedTestScript:
            runSettingsData?.captureVideosFor === 'allSteps'
              ? 'For all scripts'
              : runSettingsData?.captureVideosFor === 'failedSteps'
                ? 'For Failed scripts only'
                : "Don't Capture",
          displayLogsInScriptResult: 'On Suite Execution Completion / Termination',
        };
        tempRunSettingsObj.resultSetting = resultData;
        runSettingsGlobalObj.resultSetting = resultData;
        setResultSettingsData(resultData);
        setRunSettingsObj(tempRunSettingsObj);
      });
    } else {
      setRunSettingsObj({ ...props?.runSettingsObject });
      runSettingsGlobalObj = props?.runSettingsObject;
      if (props?.runSettingsObject?.machines?.multiple === 'true') {
        if (props?.runSettingsObject?.machines?.executionType === 'DISTRIBUTE') {
          if (props?.runSettingsObject?.machines?.distributionMechanism === 'AUTOMATIC') {
            setAutomaticJsonData([...props?.runSettingsObject?.machines?.selectedMachines]);
          }
        }
      }
    }
  }, [props.runSettingsManualObject, props.runSettingsObject]);

  function singleMachineAllData(jsonObjArray) {
    setSingleJsonData(jsonObjArray);
    let runSettingTempObj = { ...runSettingsObjRef.current };
    let Obj = {
      multiple: multiple,
      selectedMachines: jsonObjArray,
    };
    runSettingTempObj.machines = Obj;
    runSettingsGlobalObj.machines = Obj;
    props.setmachineDetails(Obj);
    setRunSettingsObj({ ...runSettingTempObj });
  }

  function multipleMachineAllData(jsonObjArray) {
    setMultipleJsonData([...jsonObjArray]);
    let runSettingTempObj = { ...runSettingsObjRef.current };
    let Obj = {
      multiple: multiple,
      selectedMachines: jsonObjArray,
      executionType: 'SEND_SUITE_TO_ALL',
    };
    runSettingTempObj.machines = Obj;
    runSettingsGlobalObj.machines = Obj;
    props.setmachineDetails(Obj);
    setRunSettingsObj({ ...runSettingTempObj });
  }

  function automaticDistributionAllData(jsonObjArray) {
    setAutomaticJsonData([...jsonObjArray]);
    let runSettingTempObj = { ...runSettingsObjRef.current };
    let Obj = {
      multiple: multiple,
      selectedMachines: jsonObjArray,
      executionType: 'DISTRIBUTE',
      distributionMechanism: 'AUTOMATIC',
    };
    runSettingTempObj.machines = Obj;
    runSettingsGlobalObj.machines = Obj;
    props.setmachineDetails(Obj);
    setRunSettingsObj({ ...runSettingTempObj });
  }

  function manualDistributionAllData(jsonObjArray, distributionObj) {
    setManualJsonData([...jsonObjArray]);
    setManualDistribution([...distributionObj]);
    let runSettingTempObj = { ...runSettingsObjRef.current };
    let Obj = {
      multiple: multiple,
      selectedMachines: jsonObjArray,
      executionType: 'DISTRIBUTE',
      distributionMechanism: 'MANUAL',
    };
    runSettingTempObj.machines = Obj;
    runSettingsGlobalObj.machines = Obj;
    props.setmachineDetails(Obj);
    setRunSettingsObj({ ...runSettingTempObj });
  }

  function changeRadioFunction(selOption) {
    let runSettingTempObj = { ...runSettingsObjRef.current };
    if (selOption === 'single') {
      let Obj = {
        multiple: 'false',
        selectedMachines: singleJsonData,
      };
      runSettingTempObj.machines = Obj;
      setMultiple('false');
    } else if (selOption === 'multiple') {
      let Obj = {
        multiple: 'true',
        selectedMachines: multipleJsonData,
        executionType: 'SEND_SUITE_TO_ALL',
      };
      runSettingTempObj.machines = Obj;
      setMultiple('true');
    } else if (selOption === 'AUTOMATIC') {
      let Obj = {
        multiple: 'true',
        selectedMachines: automaticJsonData,
        executionType: 'DISTRIBUTE',
        distributionMechanism: 'AUTOMATIC',
      };
      runSettingTempObj.machines = Obj;
      setMultiple('true');
    } else if (selOption === 'MANUAL') {
      let Obj = {
        multiple: 'true',
        selectedMachines: manualJsonData,
        executionType: 'DISTRIBUTE',
        distributionMechanism: 'MANUAL',
      };
      runSettingTempObj.machines = Obj;
      setMultiple('true');
    }
    runSettingsGlobalObj = runSettingTempObj;
    setRunSettingsObj({ ...runSettingTempObj });
  }

  function getMachineDetails(data) {
    let obj = {
      multiple: data?.multiple,
    };
    if (data.multiple) {
      obj.executionType = data?.executionType;
      if (data.executionType === 'DISTRIBUTE') {
        obj.distributionMechanism = data?.distributionMechanism;
        if (obj.distributionMechanism === 'MANUAL') {
          obj.distribution = data?.distribution;
        } else {
          obj.distribution = data?.distribution;
        }
      }
    }
    obj.selectedMachines = data?.selectedMachines;
    props.setmachineDetails(obj);
    let runSettingTempObj = { ...runSettingsObjRef.current };
    runSettingTempObj.machines = obj;
    setRunSettingsObj({ ...runSettingTempObj });
  }

  function getWaitTimeData(data) {
    let runSettingTempObj = { ...runSettingsObjRef.current };
    runSettingTempObj['waitTime'] = data;
    setWaitTimeData(data);
    props.setWaitTime(data);
    runSettingsGlobalObj = runSettingTempObj;
    setRunSettingsObj({ ...runSettingTempObj });
  }

  function getResultSettingsData(data) {
    let runSettingTempObj = { ...runSettingsObjRef.current };
    runSettingTempObj.resultSetting = data;
    setResultSettingsData(data);
    props.setResultSetting(data);
    runSettingsGlobalObj = runSettingTempObj;
    setRunSettingsObj({ ...runSettingTempObj });
  }

  function getExecutionTerminationData(data) {
    let runSettingTempObj = { ...runSettingsObjRef.current };
    runSettingTempObj.executionTermination = data;
    setExecutionTerminationData(data);
    runSettingsGlobalObj = runSettingTempObj;
    props.setExeTermintion(data);
    setRunSettingsObj({ ...runSettingTempObj });
  }

  const handleManualUserData = (machineData, userMachineObj) => {
    let machineObj = userMachineObj ? { ...userMachineObj } : {};
    machineObj['selectedMachines'] = machineData?.map((tempMachine) => {
      if (userMachineObj?.executionType === 'DISTRIBUTE') {
        let [distribution] = userMachineObj?.distribution?.filter(
          (machine) => machine?.clientSystemId === tempMachine?.clientId
        );
        tempMachine.distribution = distribution;
        if (tempMachine?.machineInstances?.length) {
          tempMachine?.machineInstances?.map((instance) => (instance.distribution = distribution));
        }
      }
      return { clientId: tempMachine.clientId, machineInstances: [{ ...tempMachine }] };
    });
    if (userMachineObj?.multiple) {
      machineObj['multiple'] = 'true';
      if (userMachineObj?.executionType !== 'DISTRIBUTE') {
        machineObj['executionType'] = 'SEND_SUITE_TO_ALL';
      }
    } else {
      machineObj['multiple'] = 'false';
      machineObj['executionType'] = 'Sequential';
    }
    if (userMachineObj?.userExecutionType) {
      machineObj['userExecutionType'] = userMachineObj?.userExecutionType;
    }
    let tempObj = {
      machines: machineObj,
    };
    props.setmachineDetails(machineObj);
    setRunSettingsManualObj({ ...tempObj });
  };

  return (
    <>
      {props?.suiteType.toLowerCase() !== 'manual' ? (
        <div id="journal-scroll" className="machine_specific overflow-y-auto mt-3 mb-4 border-blue-100 qrs_modal_body">
          <div className="alert-variable-modal-position-style">
            <AlertContatiner />
          </div>
          <div className="border border-white">
            <div className=" p-2">
              <span className="run-sttings-modal-sub-header-style ml-3">Environments</span>
            </div>

            <MachineLandingPage
              getMachineDetails={getMachineDetails}
              suiteObjMain={props.suiteObjMain}
              multiple={multiple}
              setMultiple={setMultiple}
              rootData={props?.rootData}
              distributionMechanism={distributionMechanism}
              setDistributionMechanism={setDistributionMechanism}
              createAlertFun={props.createAlertFun}
              singleMachineAllData={singleMachineAllData}
              singleJsonData={singleJsonData}
              multipleMachineAllData={multipleMachineAllData}
              multipleJsonData={multipleJsonData}
              automaticDistributionAllData={automaticDistributionAllData}
              automaticJsonData={automaticJsonData}
              manualJsonData={manualJsonData}
              manualDistributionAllData={manualDistributionAllData}
              manualDistribution={manualDistribution}
              changeRadioFunction={changeRadioFunction}
              moduleArray={props?.moduleArray || []}
              operationForRunSettings={props?.operationForRunSettings}
              numberOfParallelRuns={props?.numberOfParallelRuns}
              rerunMode={props?.rerunMode}
            />
          </div>
          <hr />
          <div className="border border-white">
            <div className="p-1">
              <span className="run-sttings-modal-sub-header-style ml-3">Wait Time</span>
            </div>
            <WaitTime getWaitTimeData={getWaitTimeData} waitTimeData={waitTimeData} rerunMode={props?.rerunMode} />
          </div>
          <hr />
          <div className="border border-white">
            <div className=" p-1">
              <span className="run-sttings-modal-sub-header-style ml-3">Result Settings</span>
            </div>
            <ResultSettings
              getResultSettingsData={getResultSettingsData}
              resultSettingsData={resultSettingsData}
              rerunMode={props?.rerunMode}
            />
          </div>
          <hr />
          <div className="border border-white">
            <div className=" p-1">
              <span className="run-sttings-modal-sub-header-style ml-3">Execution Termination</span>
            </div>
            <ExecutionTermination
              getExecutionTerminationData={getExecutionTerminationData}
              executionTerminationData={executionTerminationData}
              rerunMode={props?.rerunMode}
            />
          </div>
        </div>
      ) : (
        <div
          id="journal-scroll"
          className="machine_specific border-b overflow-y-auto border-t mt-3 mb-4 border-blue-100 qrs_modal_body"
        >
          <div className="alert-variable-modal-position-style">
            <AlertContatiner />
          </div>
          <div className="border border-white">
            <UserMachinePage
              handleManualUserData={handleManualUserData}
              suiteObjMain={props.suiteObjMain}
              multiple={multiple}
              rerunMode={props?.rerunMode}
              moduleArray={props?.moduleArray || []}
              rootData={props?.rootData}
              runSettingsManualObject={props.runSettingsManualObject}
            ></UserMachinePage>
          </div>
        </div>
      )}
    </>
  );
};

export default SuiteRunSettings;

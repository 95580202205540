import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { ReactComponent as YellowAlert } from '@assets/svg-imports/Icon-warning.svg';
import { DASHBOARD_CONSTANTS } from '@src/common/ui-constants';
import { deleteGraph } from '@src/api/api_services';

export default function GraphDelete(props) {
  const graphDelete = async () => {
    try {
      const response = await deleteGraph(
        props?.selectedGraph?.id,
        props?.selectedGraph?.name,
        props?.selectedGraph?.graphType
      );
      if (response?.data?.responseCode === 200) {
        props.closeModal();
        props?.handleChange(props?.selectedGraph?.id, 'Delete');
        props.MyAlert.success(response.data.message);
        props.reloadTree();
      }
    } catch (err) {
      console.error('Api error ', err);
    }
  };
  return (
    <>
      <Modal
        open={props.openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="focus:outline-none outline-none"
      >
        <Box className="deleteModal focus:outline-none outline-none">
          <div className="headerdelete">
            <div className="successImg">
              <YellowAlert className="svgImgWarning" />
            </div>
            <div className="mt-5 ">
              <div className="flex flex-row">
                <p className="svgYellow fontPoppinsMediumXX2 ml-5">{DASHBOARD_CONSTANTS.WARNING}</p>
                <p className="fontPoppinsMediumXX2 ml-2 delete-content">{DASHBOARD_CONSTANTS.DELETE}</p>
              </div>
              <div className="fontPoppinsRegularLg ml-6 mt-4 flex flex-col delete-content">
                <span className=" fontPoppinsRegularMd">
                  {DASHBOARD_CONSTANTS.DATA_LOST}
                  <br />
                  <span className="fontPoppinsSemiboldMd">{props?.selectedGraph?.name} </span>{' '}
                  {props?.selectedGraph?.graphType}?
                </span>
                <span className="mt-4 fontPoppinsRegularMd delete-content">{DASHBOARD_CONSTANTS.HOW_TO_PROCEED}</span>
              </div>
            </div>
          </div>
          <div className="delete-footer flex -mx-1">
            <button
              className="button-cancel success-instance cursor-pointer fontMontserratMediumXs"
              onClick={props.closeModal}
            >
              {DASHBOARD_CONSTANTS.CANCEL}
            </button>
            <button
              className="delete-button cancel-instance  cursor-pointer fontMontserratMediumXs"
              onClick={graphDelete}
            >
              {DASHBOARD_CONSTANTS.DELETE}
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

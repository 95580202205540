import DashboardStyle from '../styles/dashboard.module.scss';
import cx from 'classnames';
import {
  getLicenseFeatures,
  getCurrentProjectType,
  getAutomationProjectTypes,
  getPrivilege,
} from '@src/util/common_utils';

let All_RESORCES_DATA = [
  {
    key: 'suites',
    label: 'Suites',
  },
  {
    key: 'scripts',
    label: 'Scripts',
  },
  {
    key: 'projectElements',
    label: 'Project Elements',
  },
  {
    key: 'programElements',
    label: 'Program Elements',
  },
  {
    key: 'stepGroups',
    label: 'Step Groups',
  },
  {
    key: 'testDataFiles',
    label: 'Test Data Files',
  },
  {
    key: 'projectEnvironmentVariables',
    label: 'Project Environment Variables',
  },
  {
    key: 'globalVariables',
    label: 'Global Variables',
  },
];

function ProjectResources({ projectResourcesData }) {
  const licenseFeatures = getLicenseFeatures();
  const hasAutomation = window.privilege.hasAutomation(licenseFeatures);
  const hasManualTestCase = window.privilege.hasManualTestCase(licenseFeatures);
  const hasDefects = window.privilege.hasDefects(licenseFeatures);
  let projectType = getCurrentProjectType();
  let isAutomationProject = getAutomationProjectTypes(getPrivilege())?.includes(projectType);

  let RESOURCES_DATA = [];

  if (hasAutomation && isAutomationProject) {
    RESOURCES_DATA = All_RESORCES_DATA;
  } else if (hasManualTestCase) {
    RESOURCES_DATA.push(
      {
        key: 'suites',
        label: 'Suites',
      },
      {
        key: 'scripts',
        label: 'Scripts',
      },
      {
        key: 'testDataFiles',
        label: 'Test Data Files',
      }
    );
  } else if (hasDefects) {
    RESOURCES_DATA.push({
      key: 'testDataFiles',
      label: 'Test Data Files',
    });
  }

  return (
    <div className={DashboardStyle['resources']}>
      <h6 className={cx(DashboardStyle['section-title'], 'mb-4')}>RESOURCES</h6>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-8 xl:grid-cols-8 gap-5 xl:gap-5">
        {RESOURCES_DATA.map((item) => (
          <div className={cx('mb-1 lg:mb-10', DashboardStyle['card-wrapper'])} key={item.key}>
            <div className={cx('flex items-center flex-col px-4', DashboardStyle['card'])}>
              <p className={cx('mb-3', DashboardStyle['value'])}>
                {Number(projectResourcesData?.[item.key] || 0).toLocaleString('en-IN')}
              </p>
              <p className={cx('text-center p-px', DashboardStyle['label'])}>{item.label}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProjectResources;

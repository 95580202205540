import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import { SelectDropdown } from '@pages/configuration/plugin/gitlab/select-dropdown';
import { updateDefaultdetailsReq, updateDefaultLifeCycleReq, updateProjectRequest } from '@api/api_services';
import './defect-mgmt.scss';
import DefectPopup from './defect-email-popup';
import { Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

function SelectDefectTemplate(props) {
  const [showModal, setShowModal] = React.useState(false);
  const [lifeCycleTemplates, setLifeCycleTemplates] = useState([]);
  const [detailsTemplates, setDetailsTemplates] = useState([]);
  const [selectedDetailsTemplates, setSelectedDetailsTemplates] = useState([]);
  const [defectLifeCycleState, setDefectLifeCycleState] = useState([]);
  const [selectedDefectLifeCycleState, setSelectedDefectLifeCycleState] = useState([]);
  const [emailPayload, setEmailPayload] = useState({
    lifeCycleTemplateId: '',
    emailAllUsers: 'yes',
    additionalRecipients: [],
    additionalRecipientGroups: [],
    stateTransitions: [],
  });
  let receivedEmailData = {};
  let projectDetails = JSON.parse(localStorage.getItem('selected-project'));
  useEffect(() => {
    if (props?.lifeCycleTemplates?.length > 0 && props?.detailsTemplates?.length > 0) {
      let emailstate_transitions = { ...emailPayload };
      emailstate_transitions.stateTransitions = [];
      const detailsoptions = props?.detailsTemplates?.map((data) => ({
        ...data,
        label: data.name,
        value: data._id,
      }));
      const lifecycleoptions = props?.lifeCycleTemplates?.map((data) => ({
        ...data,
        label: data.name,
        value: data._id,
      }));
      props?.lifeCycleTemplates[0].states.forEach((data) => {
        emailstate_transitions.stateTransitions.push({
          state: data,
          emailAddresses: [],
          emailGroups: [],
        });
      });
      emailstate_transitions.lifeCycleTemplateId = lifecycleoptions[0]._id;
      setEmailPayload({ ...emailstate_transitions });
      setDetailsTemplates([...detailsoptions]);
      setLifeCycleTemplates([...lifecycleoptions]);
      setSelectedDefectLifeCycleState([{ ...lifecycleoptions[0] }]);
      setSelectedDetailsTemplates([{ ...detailsoptions[0] }]);
      setDefectLifeCycleState([...props?.lifeCycleTemplates[0].states]);
    }
  }, [props?.lifeCycleTemplates, props?.detailsTemplates]);

  useEffect(() => {
    setShowModal(props?.open);
  }, [props?.open]);

  const handleTemplateChange = (type) => (values) => {
    if (type === 'details') {
      setSelectedDetailsTemplates([...values]);
    } else if (type === 'lifeCycle') {
      setSelectedDefectLifeCycleState([...values]);
      if (values.length) {
        setDefectLifeCycleState([...values[0]?.states]);
      } else {
        setDefectLifeCycleState([]);
      }
      let emailstate_transitions = { ...emailPayload };
      emailstate_transitions.stateTransitions = [];
      values[0]?.states.forEach((data) => {
        emailstate_transitions.stateTransitions.push({
          state: data,
          emailAddresses: [],
          emailGroups: [],
        });
      });
      emailstate_transitions.lifeCycleTemplateId = values[0]?._id;
      setEmailPayload({ ...emailstate_transitions });
    }
  };

  const getEmailPayloadData = (data) => {
    receivedEmailData = data;
  };

  const saveTemplate = async (data) => {
    let response;
    let projectData = {
      name: projectDetails.name,
      type: projectDetails.type,
      defaultDefectTemplateId: selectedDetailsTemplates[0]?.value,
      defaultDefectLifecycleTemplateId: selectedDefectLifeCycleState[0]?.value,
      lifeCycleTemplateToEmail: [{ lifeCycleTemplateId: selectedDefectLifeCycleState[0]?.value }],
    };
    if (projectDetails.platform) {
      projectData.platform = projectDetails.platform;
    }
    if (projectDetails.appType) {
      projectData.appType = projectDetails.appType;
    }
    if (receivedEmailData.emailAllUsers === 'yes') {
      projectData.lifeCycleTemplateToEmail[0].emailAllUsers = 'yes';
      projectData.lifeCycleTemplateToEmail[0].additionalRecipients = receivedEmailData.additionalRecipients;
      projectData.lifeCycleTemplateToEmail[0].additionalRecipientGroups = receivedEmailData.additionalRecipientGroups;
      projectData.lifeCycleTemplateToEmail[0].stateTransitions = emailPayload.stateTransitions;
    } else if (receivedEmailData.emailAllUsers === 'no') {
      projectData.lifeCycleTemplateToEmail[0].emailAllUsers = 'no';
      projectData.lifeCycleTemplateToEmail[0].additionalRecipients = emailPayload.additionalRecipients;
      projectData.lifeCycleTemplateToEmail[0].additionalRecipientGroups = emailPayload.additionalRecipientGroups;
      projectData.lifeCycleTemplateToEmail[0].stateTransitions = receivedEmailData.stateTransitions;
    } else {
      projectData.lifeCycleTemplateToEmail[0].emailAllUsers = 'yes';
      projectData.lifeCycleTemplateToEmail[0].additionalRecipients = [];
      projectData.lifeCycleTemplateToEmail[0].additionalRecipientGroups = [];
      projectData.lifeCycleTemplateToEmail[0].stateTransitions = emailPayload.stateTransitions;
    }
    const payload = new FormData();
    payload.append('data', JSON.stringify(projectData));
    try {
      response = await updateProjectRequest(payload, projectDetails.id);
      if (response.data.responseObject === 1 || response.data.status === 'SUCCESS') {
        props?.getTemplateForEmailConfig();
        setShowModal(false);
        props?.showPopup(false);
      }
    } catch (err) {
      console.error('save template api error', err);
    }
  };
  const updateDefaultLifeCycle = async () => {
    try {
      await updateDefaultLifeCycleReq(selectedDefectLifeCycleState[0]?.value, projectDetails.id);
    } catch (err) {
      console.error('update Default LifeCycle api error', err);
    }
  };
  const updateDefaultdetails = async () => {
    try {
      await updateDefaultdetailsReq(selectedDetailsTemplates[0]?.value, projectDetails.id);
    } catch (err) {
      console.error('update Default LifeCycle api error', err);
    }
  };

  return (
    <div>
      <Modal open={showModal} className="modal-dialog">
        <div className="common-var-max-popup-style defect_popup_width modal-container">
          <div className="modal-header defect_popup_width ">
            <label className="Popup-header-common">Select Template and Configure Email</label>
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                setShowModal(false);
                props?.showPopup(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="modal-body defect_popup_width" id="journal-scroll">
            <div className="grid-rows-2 pb-2 modal-split">
              <div className="grid-rows-2">
                <div>
                  <div className="flex">
                    <span className="input-filed-label-style-common">
                      <span className="text-red-400 mr-1">*</span>Defect Details Template
                    </span>
                    <span className="templateNote ml-2  flex items-center">
                      <span className="float-left pr-1">
                        <Tooltip title="The selected template will be used for this project by default">
                          <svg
                            id="info_black_24dp_1_"
                            data-name="info_black_24dp (1)"
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                          >
                            <path id="Path_393" data-name="Path 393" d="M0,0H18V18H0Z" fill="none" />
                            <path
                              id="Path_394"
                              data-name="Path 394"
                              d="M8.3,5.5H9.7V6.9H8.3Zm0,2.8H9.7v4.2H8.3ZM9,2a7,7,0,1,0,7,7A7,7,0,0,0,9,2ZM9,14.6A5.6,5.6,0,1,1,14.6,9,5.607,5.607,0,0,1,9,14.6Z"
                              fill="#1648c6"
                            />
                          </svg>
                        </Tooltip>
                      </span>
                    </span>
                  </div>
                  <div className="grid">
                    <SelectDropdown
                      data={detailsTemplates}
                      onChange={handleTemplateChange('details')}
                      section={'email'}
                      selectedData={selectedDetailsTemplates}
                    />
                  </div>
                </div>
                <div className="text-right">
                  <button
                    disabled={!selectedDetailsTemplates.length}
                    type="button"
                    className={`${
                      !selectedDetailsTemplates.length ? 'opacity-50 text-gray-700 ' : ' text-blue-700 '
                    }  text-sm mr-9`}
                    onClick={() => {
                      localStorage.setItem(
                        'selectedTemplate',
                        JSON.stringify({
                          data: { ...selectedDetailsTemplates[0] },
                        })
                      );
                    }}
                  >
                    <Link
                      to={{
                        pathname: '/configuration/template/defecttemplate/defectdetails/defecttemplatecreation',
                        search: `?dmode=view&templateId=${selectedDetailsTemplates?.[0]?._id}`,
                        state: JSON.stringify({
                          data: { ...selectedDetailsTemplates[0] },
                        }),
                      }}
                      target="_blank"
                      onClick={(e) => {
                        if (!selectedDetailsTemplates.length) {
                          e.preventDefault();
                        }
                      }}
                    >
                      <Tooltip title="View Template">
                        <VisibilityIcon />
                      </Tooltip>
                    </Link>
                  </button>
                  <button
                    disabled={props.emailConfigApiResponse?.detailsTempCreated}
                    type="button"
                    className={`${
                      props.emailConfigApiResponse?.detailsTempCreated ? 'opacity-50 text-gray-700 ' : ' text-blue-700 '
                    }  text-sm`}
                  >
                    <Link
                      to={'/configuration/template/defecttemplate/defectdetails/defecttemplatecreation?dmode=create'}
                      onClick={(e) => {
                        if (props.emailConfigApiResponse?.detailsTempCreated) {
                          e.preventDefault();
                        }
                      }}
                    >
                      <Tooltip title="Create Template">
                        <AddBoxOutlinedIcon />
                      </Tooltip>
                    </Link>
                  </button>
                </div>
              </div>
              <div className="grid-rows-2 mt-5">
                <div>
                  <div className="flex">
                    <span className="input-filed-label-style-common">
                      <span className="text-red-400 mr-1">*</span>Defect Life Cycle Template
                    </span>
                    <span className="templateNote ml-2 flex items-center">
                      <span className="float-left pr-1">
                        <Tooltip title="The selected template will be used for this project by default">
                          <svg
                            id="info_black_24dp_1_"
                            data-name="info_black_24dp (1)"
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                          >
                            <path id="Path_393" data-name="Path 393" d="M0,0H18V18H0Z" fill="none" />
                            <path
                              id="Path_394"
                              data-name="Path 394"
                              d="M8.3,5.5H9.7V6.9H8.3Zm0,2.8H9.7v4.2H8.3ZM9,2a7,7,0,1,0,7,7A7,7,0,0,0,9,2ZM9,14.6A5.6,5.6,0,1,1,14.6,9,5.607,5.607,0,0,1,9,14.6Z"
                              fill="#1648c6"
                            />
                          </svg>
                        </Tooltip>
                      </span>
                    </span>
                  </div>
                  <div className="grid">
                    <SelectDropdown
                      data={lifeCycleTemplates}
                      section={'email'}
                      onChange={handleTemplateChange('lifeCycle')}
                      selectedData={selectedDefectLifeCycleState}
                    />
                  </div>
                </div>
                <div className="text-right">
                  <button
                    disabled={!selectedDefectLifeCycleState.length}
                    type="button"
                    className={`${
                      !selectedDefectLifeCycleState.length ? 'opacity-50 text-gray-700 ' : ' text-blue-700 '
                    }  text-sm mr-9`}
                  >
                    <Link
                      to={{
                        pathname: '/configuration/template/defecttemplate/defectlifecycleTemplate/defectlifecycleview',
                        search: `?tempId=${selectedDefectLifeCycleState?.[0]?._id}&defectTemp=${selectedDefectLifeCycleState?.[0]?.name}`,
                        state: JSON.stringify({
                          data: { ...selectedDefectLifeCycleState[0] },
                        }),
                      }}
                      target="_blank"
                      onClick={(e) => {
                        if (!selectedDefectLifeCycleState.length) {
                          e.preventDefault();
                        }
                      }}
                    >
                      <Tooltip title="View Template">
                        <VisibilityIcon />
                      </Tooltip>
                    </Link>
                  </button>
                  <button
                    disabled={props.emailConfigApiResponse?.lifecycleTempCreated}
                    type="button"
                    className={`${
                      props.emailConfigApiResponse?.lifecycleTempCreated
                        ? 'opacity-50 text-gray-700 '
                        : ' text-blue-700 '
                    }  text-sm`}
                  >
                    <Link
                      to={'/configuration/template/defecttemplate/defectlifecycleTemplate'}
                      onClick={(e) => {
                        if (props.emailConfigApiResponse?.lifecycleTempCreated) {
                          e.preventDefault();
                        }
                      }}
                    >
                      <Tooltip title="Create Template">
                        <AddBoxOutlinedIcon />
                      </Tooltip>
                    </Link>
                  </button>
                </div>
              </div>
              <div className="flex items-center">
                <span className="Popup-header-common">Email Config</span>
                <span className=" mt-1 ml-2">
                  <Tooltip title="Assignee and Reporter will receive the mail on each state change" placement="bottom">
                    <svg
                      id="info_black_24dp_1_"
                      data-name="info_black_24dp (1)"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                    >
                      <path id="Path_393" data-name="Path 393" d="M0,0H18V18H0Z" fill="none" />
                      <path
                        id="Path_394"
                        data-name="Path 394"
                        d="M8.3,5.5H9.7V6.9H8.3Zm0,2.8H9.7v4.2H8.3ZM9,2a7,7,0,1,0,7,7A7,7,0,0,0,9,2ZM9,14.6A5.6,5.6,0,1,1,14.6,9,5.607,5.607,0,0,1,9,14.6Z"
                        fill="#1648c6"
                      />
                    </svg>
                  </Tooltip>
                </span>
              </div>
            </div>
            {
              <DefectPopup
                defectLifeCycleState={defectLifeCycleState}
                getEmailPayloadData={getEmailPayloadData}
                emailPayload={emailPayload}
              />
            }
          </div>
          <div className="modal-footer defect_popup_width">
            <button
              className="cancel-button-common cancel-button-layout-style-project mr-3 fontPoppinsMediumMd"
              onClick={() => {
                setShowModal(false);
                props?.showPopup(false);
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="primary-btn"
              disabled={selectedDetailsTemplates.length === 0 || selectedDefectLifeCycleState.length === 0}
              onClick={() => {
                updateDefaultLifeCycle();
                updateDefaultdetails();
                saveTemplate();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SelectDefectTemplate;

import '@src/css_config/executionruntable.scss';
import CommonButton from './button/Button';

function MachineEnvironmentLayout({
  headerLabel = 'Test Environments',
  headerBtnLabel = 'Select Machine',
  onHeaderBtnClick = () => {},
  children = '',
  disableHeaderBtn = false,
  headerBtnProps = {},
}) {
  return (
    <div className="execution-table-outer-layer relative">
      <div className="flex justify-between items-center px-3">
        <div className="m-2 ml-2">
          <label className="fontPoppinsSemiboldSm table-label-color">{headerLabel}</label>
        </div>
        <CommonButton
          label={headerBtnLabel}
          className={disableHeaderBtn === false ? `float-right fontPoppinsRegularSm` : 'hidden'}
          btnType="primary"
          onClick={onHeaderBtnClick}
          {...headerBtnProps}
        />
      </div>
      <hr />
      <div className="table-data-view">
        {children ? (
          children
        ) : (
          <div className="button-alignment absolute">
            <CommonButton
              label={headerBtnLabel}
              className="fontPoppinsRegularSm"
              btnType="primary"
              onClick={onHeaderBtnClick}
              {...headerBtnProps}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default MachineEnvironmentLayout;

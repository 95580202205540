import { isEmptyValue } from "@src/util/common_utils";

export const _showNumberAfterFirstDecimal = (value) => {
  const [storageNumber, storageUnit] = value?.split(' ');
  if (Number.isInteger(+storageNumber)) {
    const number = parseInt(storageNumber).toFixed();
    return `${number} ${storageUnit}`;
  }
  const number = parseFloat(storageNumber).toFixed(1);
  return `${number} ${storageUnit}`;
};

export const _showNumberAfterSecondDecimal = (value) => {
  const [storageNumber, storageUnit] = value?.split(' ');
  if (Number.isInteger(+storageNumber)) {
    const number = parseInt(storageNumber).toFixed(2);
    return `${number} ${storageUnit}`;
  }
  const number = parseFloat(storageNumber).toFixed(2);
  return `${number} ${storageUnit}`;
};

export const _isNotNumber = (num) => {
  if (isNaN(num)) {
    return 0;
  }
  return num;
};

export const _getKBFromStorageString = (storageString) => {
  if (!isEmptyValue(storageString)) {
   const [storageNumber, storageUnit] = storageString?.split(' ');
   if (storageUnit?.toLowerCase() === 'tb') {
     return +storageNumber * 1024 * 1024 * 1024;
   }
   if (storageUnit?.toLowerCase() === 'gb') {
     return +storageNumber * 1024 * 1024;
   }
   if (storageUnit?.toLowerCase() === 'mb') {
     return +storageNumber * 1024;
   }
   return +storageNumber; 
  }
};

export const _getStorageWithUnitString = (totalKB) => {
  let storageNumber = totalKB;
  const units = ['KB', 'MB', 'GB', 'TB'];
  let storageunitWithString = '';
  for (let unitIndex = 0; unitIndex <= units.length; unitIndex++) {
    const convertedStorage = storageNumber / 1024;
    if (convertedStorage < 1) {
      storageunitWithString = `${storageNumber.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })} ${units[unitIndex]}`;
      break;
    } else {
      storageNumber = convertedStorage;
    }
  }
  return storageunitWithString;
};

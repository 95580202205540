import DashboardStyle from './styles/dashboard.module.scss';
import cx from 'classnames';
import ExecutionSuitesAndSchedules from './sections/ExecutionSuitesAndSchedules';
import ProjectInfoAndResources from './components/ProjectInfoAndResources';
import MemorySectionIndividualProject from './sections/MerorySectionIndividualProject';
import { getLicenseFeatures } from '@src/util/common_utils';
function IndividualProjectDashboardPage({ project }) {
  const licenseFeatures = getLicenseFeatures();
  const hasAutomation = window.privilege.hasAutomation(licenseFeatures);
  const hasManualTestCase = window.privilege.hasManualTestCase(licenseFeatures);

  return (
    <>
      <div className={cx('w-full mx-auto p-4')}>
        <ProjectInfoAndResources projectId={project?.id} />
        {(hasAutomation || hasManualTestCase) && <ExecutionSuitesAndSchedules projectId={project?.id} />}
        <div className="-mt-7">
          <MemorySectionIndividualProject projectId={project?.id} />
        </div>
      </div>
    </>
  );
}

export default IndividualProjectDashboardPage;

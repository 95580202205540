import React, { useMemo, useEffect, useState } from 'react';
import { Drawer, Box } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '@assets/analytics/ShrinkIconWhite.svg';
import { BITBUCKET_CONSTANTS } from '../plugin_constants';
import CommonDrawerJs from '../CommonIntegrationDrawer';

const InstanceDetails = (props) => {
  const result = props?.projectDetails?.projects;
  const selectedInstance = props.seletedInstance;

  return (
    <CommonDrawerJs
      isDrawerOpen={true}
      headerContent={`${BITBUCKET_CONSTANTS.INSTANCE_DETAILS} - ${props?.seletedInstance?.instanceName}`}
      drawerWidth="549.95px"
      leftButtonText={BITBUCKET_CONSTANTS.CLOSE}
      isLeftButtonVisible={true}
      isRightButtonVisible={false}
      onDrawerOpen={props.onClose}
      onLeftButtonClick={props.onClose}
    >
      <div className="w-full h-full flex flex-col ">
        <div id="Journal-scroll" className="grid grid-cols-3 mt-4 pb-4 ml-6 overflow-y-auto max-h-72">
          <div className="overflow-hidden-common">
            <label
              htmlFor="userName"
              className="block label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {BITBUCKET_CONSTANTS.INSTANCE_NAME}
            </label>
            <label
              className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1"
              title={result?.name}
            >
              {selectedInstance?.instanceName}
            </label>
          </div>
          <div className="overflow-hidden-common ml-6">
            <label
              htmlFor="userName"
              className="block label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {BITBUCKET_CONSTANTS.USERNAME}
            </label>
            <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
              {selectedInstance?.bitBucketUserName}
            </label>
          </div>
          <div className="ml-12">
            <label
              htmlFor="userName"
              className="block label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {BITBUCKET_CONSTANTS.WORKSPACE}
            </label>
            <label className="instance-details-style overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
              {selectedInstance?.bitBucketUserName}
            </label>
          </div>
          <div className="mt-6">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {BITBUCKET_CONSTANTS.PROJECT}
            </label>
            <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
              {selectedInstance?.projectName}
            </label>
          </div>
          <div className="mt-6 ml-6">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {BITBUCKET_CONSTANTS.REPOSITORY}
            </label>
            <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
              {' '}
              <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
                {selectedInstance?.bitBucketRepositoryName}
              </label>
            </label>
          </div>
          <div className="mt-6 ml-12">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {BITBUCKET_CONSTANTS.FIRE_FLINK_PROJECT}
            </label>
            <label className="instance-details-style fontPoppinsRegularSm">
              {selectedInstance?.fireFlinkProjectName}
            </label>
          </div>
          <div className="mt-6">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {BITBUCKET_CONSTANTS.SUITE_NAME}
            </label>
            <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
              {selectedInstance?.suiteName}
            </label>
          </div>
          <div className="mt-6 ml-6">
            <label
              htmlFor="userName"
              className="details-data-style-common   label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm"
            >
              {BITBUCKET_CONSTANTS.CREATED_ON}
            </label>
            <label className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1">
              {selectedInstance?.createdOn}
            </label>
          </div>
          <div className="mt-6 ml-12">
            <label
              htmlFor="userName"
              className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm instance-label"
            >
              {BITBUCKET_CONSTANTS.MODIFIED_ON}
            </label>
            <label className="instance-details-style fontPoppinsRegularSm">{selectedInstance?.modifiedOn}</label>
          </div>
        </div>
      </div>
    </CommonDrawerJs>
  );
};

export default InstanceDetails;

import React, { useEffect, useState } from 'react';
import { MenuItem, Tooltip, FormControl } from '@material-ui/core';
import { Field } from 'formik';
import { getAllFiles } from '@src/api/api_services';
import { useAlert } from '@src/pages/common/alert_service/useAlert';
import FileUploadButton from '@src/pages/common/fileUpload_button';
import {
  IMAGE_LOCATOR,
  REPO,
  LOCATOR_DETAILS_TABLE_CONSTANTS,
  FILE_UPLOAD_BUTTON_CONSTANTS,
  VISUAL_TESTING_IMAGE_FILE_EXTENTION,
} from '@src/common/ui-constants';
import DeleteIcon from '@src/assets/delete_red_icon.svg';
import { getTruncatedText, isEmptyValue } from '@src/util/common_utils';
import { UI_VALIDATIONS, VISUAL_TESTING_IMAGE_MIME_TYPE } from '@src/util/validations';
import { Autocomplete, Chip, TextField } from '@mui/material';

const ImageLocator = (props) => {
  const { MyAlert } = useAlert();
  const [fileNameOptions, setFileNameOptions] = useState([]);
  const [localPathRadio, setLocalPathRadio] = useState('radio-path-local');
  const [locator, setLocator] = useState({ localDataLocator: [], testDataLocator: [] });
  const { tableLabel, setIsDisabled, tableName, values, setFieldValue = () => {} } = props;
  const project = JSON.parse(localStorage.getItem('selected-project'));
  const [errorMessage, setErrorMessage] = useState('');
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);

  useEffect(() => {
    if (props?.platform === 'Mobile') {
      if (
        (!isEmptyValue(values.androidLocatorFromLocalPath) ||
          !isEmptyValue(values.androidLocatorFromTestData) ||
          !isEmptyValue(values.iosLocatorFromLocalPath) ||
          !isEmptyValue(values.iosLocatorFromTestData)) &&
        !isEmptyValue(values.parentName) &&
        values.name?.length > 1
      ) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else {
      if (
        (!isEmptyValue(values.androidLocatorFromLocalPath) || !isEmptyValue(values.androidLocatorFromTestData)) &&
        !isEmptyValue(values.parentName) &&
        values.name.length > 1
      ) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  }, [props?.platform, setIsDisabled, values]);

  useEffect(() => {
    getFileNames();
  }, []);

  const updateLocator = (values, tableName) => {
    setLocalPathRadio(tableName === 'table1Locator' ? values?.radioTypeForAndroid : values?.radioTypeForIOS);
    if (tableName === 'table1Locator') {
      setLocator((prev) => ({
        ...prev,
        localDataLocator: values?.androidLocatorFromLocalPath,
        testDataLocator: values?.androidLocatorFromTestData,
      }));
    } else {
      setLocator((prev) => ({
        ...prev,
        localDataLocator: values?.iosLocatorFromLocalPath,
        testDataLocator: values?.iosLocatorFromTestData,
      }));
    }
  };

  useEffect(() => {
    updateLocator(values, tableName);
  }, [values, tableName]);

  const getFileNames = async () => {
    try {
      let opt = [];
      const response = await getAllFiles(project.id, 'visual testing');
      if (response?.data?.responseObject) {
        response?.data?.responseObject.forEach((file) => {
          opt.push({
            ...file,
            id: file?.id,
            label: file?.actualPath?.substring(file.actualPath.indexOf('Root') + 5),
          });
        });
        setFileNameOptions(opt);
      }
    } catch (err) {
      console.error('Failed to get file', err);
    }
  };

  const hasDuplicateLocalPathOrTestDataFile = (array, property) => {
    const seen = new Set();
    for (const obj of array) {
      const key = property ? obj[property] : JSON.stringify(obj);
      if (seen.has(key)) {
        return true;
      }
      seen.add(key);
    }
    return false;
  };

  const { MAX_FILE_UPLOAD_COUNT } = UI_VALIDATIONS;
  let arrayOfExistingLocatorFiles = [];

  const validateFile = (e, values, setFieldValue, tableName) => {
    const files = Array.from(e.target.files);
    const SelectedFiles = Array.from(e.target.files);
    const allowedTypes = VISUAL_TESTING_IMAGE_MIME_TYPE;
    let rejectedFileCount = 0;
    files.forEach((file, index) => {
      if (!allowedTypes.includes(file.type)) {
        rejectedFileCount++;
        SelectedFiles.splice(index);
      }
    });
    if (files.length === 1 && rejectedFileCount === 1) {
      props.MyAlert.info(REPO.INVALID_FILE_FORMAT);
      return;
    }
    if (rejectedFileCount) {
      props.MyAlert.info(
        `${rejectedFileCount} ${rejectedFileCount === 1 ? `${REPO.FILE_NOT_SUPPORTED}` : `${REPO.FILES_NOT_SUPPORTED}`}`
      );
    }

    SelectedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onloadend = function (event) {
        const arr = new Uint8Array(event.target.result).subarray(0, 4);
        let header = '';
        for (let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }

        let isValid = false;
        switch (header) {
          case '89504e47':
            isValid = true; // PNG
            break;
          case 'ffd8ffe0':
          case 'ffd8ffe1':
          case 'ffd8ffe2':
          case 'ffd8ffe3':
          case 'ffd8ffe8':
            isValid = true; // JPEG
            break;
          case '3c737667': // This is the start of an SVG file (<?xml version="1.0" standalone="no"?>)
            isValid = true;
            break;
          default:
            isValid = false; // Unrecognized
            break;
        }

        if (!isValid) {
          const text = new TextDecoder().decode(arr.subarray(0, 100)).toLowerCase();
          if (text.includes('<svg')) {
            isValid = true; // SVG
          }
        }

        if (!isValid) {
          props.MyAlert.info(REPO.INVALID_FILE_CONTENT);
        } else {
          handleFileChange(e, values, setFieldValue, tableName);
        }
      };
    });
  };

  const handleFileChange = (event, values, setFieldValue, tableName) => {
    setErrorMessage('');
    const fieldName = tableName === 'table1Locator' ? 'androidLocatorFromLocalPath' : 'iosLocatorFromLocalPath';
    const allowedExtensions = VISUAL_TESTING_IMAGE_FILE_EXTENTION;
    let existingLocators =
      tableName === 'table1Locator' ? values?.androidLocatorFromLocalPath : values?.iosLocatorFromLocalPath || [];
    arrayOfExistingLocatorFiles = [...existingLocators];

    let addedLocatorFiles = Array.from(event.target.files);
    if (
      event.target.files.length > MAX_FILE_UPLOAD_COUNT ||
      (addedLocatorFiles?.length && arrayOfExistingLocatorFiles?.length >= MAX_FILE_UPLOAD_COUNT) ||
      arrayOfExistingLocatorFiles.length >= MAX_FILE_UPLOAD_COUNT
    ) {
      props.MyAlert.info(REPO.MAXIMUM_UPLOAD_ERROR);
      return (event.target.value = '');
    }

    let uniqueAddedLocatorFiles = addedLocatorFiles.filter((file) => {
      const isUnique = !arrayOfExistingLocatorFiles.some((existingFile) => existingFile?.name === file?.name);
      if (!isUnique) {
        event.target.value = '';
        props.MyAlert.info(REPO.FILE_ALREADY_EXIST);
      }
      return isUnique;
    });
    if (uniqueAddedLocatorFiles.length === 0 && addedLocatorFiles.length > 0) {
      event.target.value = '';
      return props.MyAlert.info(REPO.FILE_ALREADY_EXIST);
    }

    let uniquePngFiles = uniqueAddedLocatorFiles.filter((file) => {
      const extension = file.name.split('.').pop().toLowerCase();
      return allowedExtensions.includes(extension);
    });

    if (uniquePngFiles.length === 0 && addedLocatorFiles.length > 0) {
      event.target.value = '';
      return props.MyAlert.info(REPO.INVALID_FILE_FORMAT);
    }
    if (
      arrayOfExistingLocatorFiles.length < MAX_FILE_UPLOAD_COUNT &&
      event.target.files.length <= MAX_FILE_UPLOAD_COUNT
    ) {
      for (let i = 0; i < uniquePngFiles?.length; i++) {
        if (arrayOfExistingLocatorFiles?.length >= MAX_FILE_UPLOAD_COUNT) {
          event.target.value = '';
          return props.MyAlert.info(REPO.MAXIMUM_UPLOAD_ERROR);
        } else {
          if (uniquePngFiles[i]?.size > FILE_UPLOAD_BUTTON_CONSTANTS.MAX_FILE_UPLOAD_SIZE) {
            event.target.value = '';
            return setErrorMessage(FILE_UPLOAD_BUTTON_CONSTANTS.IMAGE_UPLOAD_ERROR);
          } else {
            arrayOfExistingLocatorFiles.push(uniquePngFiles[i]);
            setFieldValue(fieldName, arrayOfExistingLocatorFiles);
          }
        }
      }
    } else {
      event.target.value = '';
      return props.MyAlert.info(REPO.MAXIMUM_UPLOAD_ERROR);
    }
    event.target.value = '';
  };

  const handleSelectChange = (newValue, setFieldValue, tableName) => {
    setErrorMessage('');
    const fieldName = tableName === 'table1Locator' ? 'androidLocatorFromTestData' : 'iosLocatorFromTestData';

    if (newValue.length > MAX_FILE_UPLOAD_COUNT) {
      return props.MyAlert.info(REPO.MAXIMUM_UPLOAD_ERROR);
    }
    const exceedsMaxFileSize = newValue.some(
      (newTestDataImage) => newTestDataImage?.fileSize > FILE_UPLOAD_BUTTON_CONSTANTS.MAX_FILE_UPLOAD_SIZE
    );
    if (exceedsMaxFileSize) {
      setAutocompleteOpen(false);
      return setErrorMessage(FILE_UPLOAD_BUTTON_CONSTANTS.SELECT_IMAGE_SIZE_ERROR);
    }

    const isTestDataDuplicate = hasDuplicateLocalPathOrTestDataFile(newValue, 'name');
    if (!isTestDataDuplicate) {
      setFieldValue(fieldName, newValue);
      setErrorMessage('');
    } else {
      return props.MyAlert.info(REPO.FILE_ALREADY_EXIST);
    }
  };

  const handleRadioChange = (value, setFieldValue, tableName) => {
    setErrorMessage('');
    if (tableName === 'table1Locator') {
      setFieldValue('radioTypeForAndroid', value);
      if (value === 'radio-path-local') {
        setFieldValue('androidLocatorFromTestData', []);
      } else {
        setFieldValue('androidLocatorFromLocalPath', []);
      }
    } else {
      setFieldValue('radioTypeForIOS', value);
      if (value === 'radio-path-local') {
        setFieldValue('iosLocatorFromTestData', []);
      } else {
        setFieldValue('iosLocatorFromLocalPath', []);
      }
    }
  };

  const isLocalPathOrTestDataChecked = (values, tableName) => {
    let localPathChecked = false;
    let testDataChecked = false;
    if (tableName === 'table1Locator') {
      if (values?.radioTypeForAndroid === 'radio-path-local') {
        localPathChecked = true;
      } else if (values?.radioTypeForAndroid === 'radio-path-testdata') {
        testDataChecked = true;
      } else {
        localPathChecked = true;
      }
    } else {
      if (values?.radioTypeForIOS === 'radio-path-local') {
        localPathChecked = true;
      } else if (values?.radioTypeForIOS === 'radio-path-testdata') {
        testDataChecked = true;
      } else {
        localPathChecked = true;
      }
    }
    return { localChecked: localPathChecked, testChecked: testDataChecked };
  };
  return (
    <>
      <div className="mx-1 my-4 locator-list-table">
        <div className="grid grid-cols-4 gap-6 my-2">
          <p className="col-span-3 fontPoppinsSemiboldLg">{tableLabel}</p>
        </div>
        <div className="grid grid-cols-2 gap-6">
          <div className="">
            <div className="text-left input-filed-label-style-common fontPoppinsRegularXlg">
              <span className="text-red-400 mr-1">&#42;</span>
              {IMAGE_LOCATOR.CHOOSE_IMAGE}
            </div>
            <div className="mt-2 mb-4 pb-2">
              <Field
                type="radio"
                className="radio mr-2 cursor-pointer"
                name={tableName === 'table1Locator' ? `radioTypeForAndroid` : 'radioTypeForIOS'}
                id={`radio-path-local`}
                value="radio-path-local"
                checked={isLocalPathOrTestDataChecked(values, tableName)?.localChecked}
                onChange={(e) => {
                  handleRadioChange('radio-path-local', setFieldValue, tableName);
                }}
              />
              <label
                className="text-xs overflow-hidden mr-4 overflow-ellipsis whitespace-nowrap fontPoppinsRegularMd cursor-pointer"
                htmlFor={`radio-path-local`}
              >
                {IMAGE_LOCATOR.LOCAL_PATH}
              </label>
              <Field
                type="radio"
                className="radio mx-2 cursor-pointer"
                name={tableName === 'table1Locator' ? `radioTypeForAndroid` : 'radioTypeForIOS'}
                id={`radio-path-testdata`}
                value="radio-path-testdata"
                checked={isLocalPathOrTestDataChecked(values, tableName)?.testChecked}
                onChange={(e) => {
                  handleRadioChange('radio-path-testdata', setFieldValue, tableName);
                }}
              />
              <label
                className="text-xs overflow-hidden overflow-ellipsis whitespace-nowrap fontPoppinsRegularMd cursor-pointer"
                htmlFor={`radio-path-testdata`}
              >
                {IMAGE_LOCATOR.TEST_DATA}
              </label>
            </div>
          </div>
          {localPathRadio === 'radio-path-local' ? (
            <div className="input-filed-label-style-common">
              <span className="text-red-400 mr-1">&#42;</span>
              <label>{IMAGE_LOCATOR.SELECT_IMAGE_LP}</label> <br />
              <button type="button" className="fileButton">
                <FileUploadButton
                  id="localPathLocator"
                  name={tableName === 'table1Locator' ? `androidLocatorFromLocalPath` : 'iosLocatorFromLocalPath'}
                  type="file"
                  multiple
                  accept=".png,.jpg,.jpeg,.svg"
                  max={5}
                  called="image-Data"
                  placeholder={IMAGE_LOCATOR.CHOOSE_IMAGE}
                  onChange={(e) => validateFile(e, values, setFieldValue, tableName)}
                />
                {errorMessage && <p className="errorMessage">{errorMessage}</p>}
              </button>
            </div>
          ) : (
            <div className="input-filed-label-style-common">
              <span className="text-red-400 mr-1">&#42;</span>
              <label>{IMAGE_LOCATOR.SELECT_IMAGE_TD}</label>
              <br />
              <Field name={tableName === 'table1Locator' ? `androidLocatorFromTestData` : 'iosLocatorFromTestData'}>
                {({ field, form, meta }) => {
                  return (
                    <div>
                      <FormControl className="w-full">
                        <Autocomplete
                          disableCloseOnSelect
                          open={autocompleteOpen}
                          onOpen={() => setAutocompleteOpen(true)}
                          onClose={() => setAutocompleteOpen(false)}
                          max={5}
                          multiple
                          fullWidth
                          limitTags={5}
                          {...field}
                          value={form.values[field.name] || []}
                          onChange={(event, newValue) => {
                            handleSelectChange(newValue, form.setFieldValue, tableName);
                          }}
                          isOptionEqualToValue={(option, value) => option.name === value.name}
                          name={tableName === 'table1Locator' ? `androidLocatorFromTestData` : 'iosLocatorFromTestData'}
                          clearOnBlur
                          renderTags={(tags, getTagProps) =>
                            tags.map((item, index) => (
                              <Tooltip title={item?.name}>
                                <Chip
                                  variant="outlined"
                                  label={getTruncatedText(item?.name, 6)}
                                  {...getTagProps({ index })}
                                  sx={{ fontSize: '0.875rem', padding: '0.5px' }}
                                />
                              </Tooltip>
                            ))
                          }
                          options={fileNameOptions}
                          getOptionLabel={(option) => option.name}
                          renderOption={(props, option) => (
                            <MenuItem {...props} key={option.id} value={option}>
                              <Tooltip title={option.label} position="left">
                                <div className="truncate">{option.name}</div>
                              </Tooltip>
                            </MenuItem>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={FILE_UPLOAD_BUTTON_CONSTANTS.SEARCH_AND_SELECT_IMAGES}
                              variant="standard"
                            />
                          )}
                          sx={{ maxWidth: '390px' }}
                        />
                      </FormControl>
                      {errorMessage && <p className="errorMessage">{errorMessage}</p>}
                    </div>
                  );
                }}
              </Field>
            </div>
          )}
        </div>
      </div>
      <div className="mt-4">
        <div className="tableHeadingStyle">
          <span className="mt-2 fontPoppinsSemiboldLg">{IMAGE_LOCATOR.SELECTED_IMAGES}</span>
        </div>
        {locator?.localDataLocator?.length > 0 || locator?.testDataLocator?.length > 0 ? (
          <table className="text-center w-full">
            <thead className="tableheaderbackground">
              <tr className="flex w-full">
                <th className="p-2 pl-5 tableheader w-2/4">{LOCATOR_DETAILS_TABLE_CONSTANTS.NAME}</th>
                <th className="p-2 tableheader w-1/4">{LOCATOR_DETAILS_TABLE_CONSTANTS.TYPE}</th>
                <th className="p-2 tableheader w-1/4">{LOCATOR_DETAILS_TABLE_CONSTANTS.ACTION}</th>
              </tr>
            </thead>
            <tbody className="flex flex-col overflow-y-scroll w-full h-48" id="journal-scroll">
              {locator?.localDataLocator?.map((data, index) => {
                let name = data?.name;
                let id = name?.lastIndexOf('.');
                let type = name?.slice(id + 1, name.length);
                return (
                  <tr className="flex w-full" key={index}>
                    <td className="p-2 tabletext  w-1/2">
                      <div className="fileName" title={data?.name}>
                        {data?.name?.toLowerCase() === '.project' || data?.name?.toLowerCase() === '.gitignore'
                          ? data?.name
                          : data?.name?.replace(/\.[^.]*$/, '')}
                      </div>
                    </td>
                    <td className="p-2 tabletext w-1/4">{type}</td>
                    <td className="p-2 pl-5 tabletext w-1/4">
                      <img
                        className="action-element cursor-pointer "
                        onClick={() => {
                          let fieldValue =
                            tableName === 'table1Locator' ? 'androidLocatorFromLocalPath' : 'iosLocatorFromLocalPath';
                          const updatedFiles = [...locator.localDataLocator];
                          updatedFiles.splice(index, 1);
                          setFieldValue(fieldValue, updatedFiles);
                          MyAlert.info(REPO.FILES_DELETED_SUCCESSFULLY);
                        }}
                        src={DeleteIcon}
                        height="20px"
                        width="20px"
                        alt=""
                      />
                      <Tooltip title="Manually added locator" placement="bottom" className="float-right">
                        <svg
                          id="perm_identity_black_24dp"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          className="inline"
                        >
                          <path id="Path_444" data-name="Path 444" d="M0,0H24V24H0Z" fill="none" />
                          <path
                            id="Path_445"
                            data-name="Path 445"
                            d="M12,6a2,2,0,1,1-2,2,2.006,2.006,0,0,1,2-2m0,9c2.7,0,5.8,1.29,6,2v1H6v-.99C6.2,16.29,9.3,15,12,15M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,9c-2.67,0-8,1.34-8,4v3H20V17C20,14.34,14.67,13,12,13Z"
                            fill="#3c3838"
                          />
                        </svg>
                      </Tooltip>
                    </td>
                  </tr>
                );
              })}
              {locator?.testDataLocator?.map((fileId, index) => {
                const selectedFile = fileNameOptions.find(
                  (file) => file.id === fileId.id || file.actualPath === fileId.filePath
                );
                if (selectedFile) {
                  const name = selectedFile?.label;
                  const type = name?.substring(name.lastIndexOf('.') + 1);
                  return (
                    <tr className="flex w-full" key={index}>
                      <td className="p-2 tabletext w-1/2">
                        <div className="fileName" title={name}>
                          {name?.toLowerCase() === '.project' || name?.toLowerCase() === '.gitignore'
                            ? name
                            : name?.replace(/\.[^.]*$/, '')}
                        </div>
                      </td>
                      <td className="p-2  tabletext w-1/4">{type}</td>
                      <td className="p-2 pl-5 tabletext w-1/4">
                        <img
                          className="action-element cursor-pointer "
                          onClick={() => {
                            let fieldValue =
                              tableName === 'table1Locator' ? 'androidLocatorFromTestData' : 'iosLocatorFromTestData';
                            const updatedFiles = [...values[fieldValue]];
                            updatedFiles.splice(index, 1);
                            setFieldValue(fieldValue, updatedFiles);
                            MyAlert.info(REPO.FILES_DELETED_SUCCESSFULLY);
                          }}
                          src={DeleteIcon}
                          height="20px"
                          width="20px"
                          alt=""
                        />
                        <Tooltip title="Manually added locator" placement="bottom" className="float-right">
                          <svg
                            id="perm_identity_black_24dp"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            className="inline"
                          >
                            <path id="Path_444" data-name="Path 444" d="M0,0H24V24H0Z" fill="none" />
                            <path
                              id="Path_445"
                              data-name="Path 445"
                              d="M12,6a2,2,0,1,1-2,2,2.006,2.006,0,0,1,2-2m0,9c2.7,0,5.8,1.29,6,2v1H6v-.99C6.2,16.29,9.3,15,12,15M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,9c-2.67,0-8,1.34-8,4v3H20V17C20,14.34,14.67,13,12,13Z"
                              fill="#3c3838"
                            />
                          </svg>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        ) : (
          <table className="">
            <thead className="tableheaderbackground">
              <tr className="flex w-full">
                <th className="p-4  tableheader w-2/4">{LOCATOR_DETAILS_TABLE_CONSTANTS.NAME}</th>
                <th className="p-4 tableheader w-1/4">{LOCATOR_DETAILS_TABLE_CONSTANTS.TYPE}</th>
                <th className="p-4 tableheader w-1/4">{LOCATOR_DETAILS_TABLE_CONSTANTS.ACTION}</th>
              </tr>
            </thead>
            <tbody className="h-44 tablebodybackground">
              <tr>
                <th colSpan="3" className="notabledata tablebodybackground">
                  {IMAGE_LOCATOR.NO_IMAGE_SELECTED}
                </th>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default ImageLocator;

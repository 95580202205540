import React, { useEffect, useState, Fragment } from 'react';
import { useHistory, Link } from 'react-router-dom';
import BackArrow from '@assets/svg-imports/BackArrowOrange.svg';
import '@pages/auth/login.css';
import { useFormik } from 'formik';
import { Tooltip } from '@material-ui/core';
import { changePasswordRequest, validateUserToken } from '@api/api_services';
import * as Yup from 'yup';
import cx from 'classnames';
import LabeledPasswordInput from '@pages/common/Inputs/LabeledPasswordInput';
import { GLOBAL_CONSTANTS, PASSWORD_CONSTANTS } from '@src/common/ui-constants';
import CommonPage from '../common/CommonPage';
import CommonBorder from '../common/common-border';
import commonStyles from '@pages/auth/common.module.scss';
import { getEncryptData } from '@common/security';
import { Icon } from 'fireflink-ui';
import { REGEX } from '@src/util/validations';

export default function ForgetPassword(props) {
  const forgotPasswordSchema = Yup.object({
    password: Yup.string()
      .matches(REGEX.PASSWORD_WEAK_MATCH, `Weak password, Match the password criteria`)
      .matches(REGEX.PASSWORD_PATTERN, `Weak password, Match the password criteria`)
      .required('New Password is required'),
    confirmPassword: Yup.string()
      .required('Confirm password is required')
      .when('password', {
        is: (val) => val,
        then: Yup.string().oneOf([Yup.ref('password')], 'Confirm password not matched with new password'),
      }),
  });

  let history = useHistory();
  const STRONG_PASSWORD_PATTERNS = [
    {
      pattern: /^.{8,20}$/,
      message: 'Should contains at least 8 characters and at most 20 characters',
    },
    {
      pattern: /.*[0-9].*/,
      message: 'Should contains at least one digit',
    },
    {
      pattern: /.*[A-Z].*/,
      message: 'Should contains at least one upper case alphabet',
    },
    {
      pattern: /.*[a-z].*/,
      message: 'Should contains at least one lower case alphabet',
    },
    {
      pattern: /.*[!@#$%&*()+=^].*/,
      message: 'Should contains at least one special character which includes !@#$%&*()+=^',
    },
    {
      pattern: /^\S*$/,
      message: "Should doesn't contain any white space",
    },
  ];

  const [data, setData] = useState({ emailId: '', token: '' });
  const [createUpdateCalled, setCreateUpdateCalled] = useState(true);
  const formik = useFormik({
    initialValues: {
      emailId: '',
      password: '',
      confirmPassword: '',
      passwordToken: '',
    },
    validationSchema: forgotPasswordSchema,

    onSubmit: (values) => {
      if (createUpdateCalled) {
        setCreateUpdateCalled(false);
        const payload = {
          emailId: data.emailId,
          password: getEncryptData(values.password),
          passwordToken: data.token,
        };
        if (data.emailId && data.token) {
          changePasswordRequest(payload)
            .then((res) => {
              const response = res.data;
              if (response.status === 'SUCCESS') {
                history.push('/set-password-successfully');
              } else if (response.message === 'Token is invalid/Expired') {
                redirectTo(data.emailId);
              }
            })
            .catch((error) => {});
        }
      }
    },
  });
  const [validToken, setValidToken] = useState(false);
  const [tokenRef, setTokenRef] = useState(false);
  const [activationStatus, setActivationStatus] = useState(false);

  useEffect(() => {
    const search = window.location.search;
    const token = new URLSearchParams(search).get('token');
    const emailId = new URLSearchParams(search).get('emailId');
    const activationStatus = new URLSearchParams(search).get('activationStatus');
    setActivationStatus(activationStatus);
    setData({ emailId: emailId, token: token });
    verifyToken(null, emailId, token);
  }, []);
  useEffect(() => {
    if (tokenRef) {
      const search = window.location.search;
      const emailId = new URLSearchParams(search).get('emailId');
      !validToken && redirectTo(emailId);
    }
  }, [tokenRef]);

  const handleConfirmPassword = (e) => {
    const { name, value } = e.target;
    if (formik.values.password == value) {
      formik.setFieldError(name, '');
    } else {
      formik.setFieldError(name, 'Password does not match');
    }
    formik.setFieldValue(name, value);
  };
  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault();
    }
  };
  function verifyToken(id, emailId, token) {
    let payload = {
      id: id,
      emailId: emailId,
      passwordToken: token,
      fromPage: 'change-password',
    };
    validateUserToken(payload)
      .then((result) => {
        if (result.data && result.data.responseCode === 200) {
          setValidToken(true);
        }
        setTokenRef(true);
      })
      .catch((error) => {
        setTokenRef(true);
        console.error('Verify Token Failed!', error);
      });
  }

  function redirectTo(emailId) {
    history.push(`/token-expired?page=${'change-password'}&emailId=${emailId}&activationStatus=${activationStatus}`);
  }

  return (
    <CommonPage wrapperClassname={`common-padding`}>
      <div className={cx('flex justify-center', commonStyles['content-header'])}>{PASSWORD_CONSTANTS.SET_PASSWORD}</div>
      <CommonBorder />
      <form onSubmit={formik.handleSubmit}>
        {validToken && (
          <div className="">
            <div className=" w-9/12 mx-auto fontPoppinsRegularMd pt-4 pb-4 text-center letter-spacing-25">
              {PASSWORD_CONSTANTS.SET_PASSWORD_MESSAGE}
            </div>
            <div className="flex flex-col justify-center gap-4">
              <div className="h-14">
                <LabeledPasswordInput
                  label="New Password"
                  required
                  placeholder="Enter new password"
                  error={formik.errors.password && formik.touched.password}
                  value={formik.values.password}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    setCreateUpdateCalled(true);
                  }}
                  onKeyDown={handleKeyDown}
                  onChange={formik.handleChange}
                  className={cx(commonStyles['common-input-label'])}
                  name="password"
                  helperText={<div className="relative">{formik.touched.password && formik.errors.password}</div>}
                />
                {formik.values.password.length > 7 &&
                formik.values.password.length < 21 &&
                formik.values.password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^&*()+=]).{8,20}$/) &&
                formik.values.password.match(/^\S*$/) ? (
                  <p className="goodPass fontPoppinsRegularXs8px">Strong password, good</p>
                ) : null}

                {STRONG_PASSWORD_PATTERNS.some(({ pattern }) => !pattern.test(formik.values.password)) &&
                  (formik.values.password || formik.touched.password) && (
                    <Tooltip
                      data-html="true"
                      title={
                        <div>
                          {STRONG_PASSWORD_PATTERNS.map((passwordPattern) => {
                            return (
                              <Fragment key={passwordPattern.message}>
                                {!passwordPattern.pattern.test(formik.values.password) && (
                                  <p>{passwordPattern.message}</p>
                                )}
                              </Fragment>
                            );
                          })}
                        </div>
                      }
                      placement="bottom"
                    >
                      <span
                        className={cx('cursor-pointer set-password-info', {
                          'text-red-500': formik.touched.password && formik.errors.password,
                          'text-blue-700 ml-auto': !formik.touched.password || !formik.errors.password,
                        })}
                      >
                        <Icon name="info" />
                      </span>
                    </Tooltip>
                  )}
              </div>
              <div className="h-20">
                <LabeledPasswordInput
                  label="Confirm Password"
                  required
                  className={cx(commonStyles['common-input-label'])}
                  placeholder="Enter confirm password"
                  error={formik.errors.confirmPassword && formik.touched.confirmPassword}
                  value={formik.values.confirmPassword}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    setCreateUpdateCalled(true);
                  }}
                  onKeyDown={handleKeyDown}
                  onChange={formik.handleChange}
                  name="confirmPassword"
                  helperText={
                    <div className="absolute">{formik.touched.confirmPassword && formik.errors.confirmPassword}</div>
                  }
                />
                {formik.values.confirmPassword === formik.values.password &&
                formik.values.confirmPassword.length > 7 ? (
                  <p className="goodPass fontPoppinsRegularXs8px ">{PASSWORD_CONSTANTS.PASSWORD_MATCHED}</p>
                ) : null}
              </div>
            </div>
            <div className="flex justify-center items-center gap-4 flex-col mt-2">
              <button
                type="submit"
                className={cx('fontPoppinsSemiboldLg', commonStyles['common-btn'], commonStyles['btn-bg-orange'])}
              >
                {PASSWORD_CONSTANTS.SET_PASSWORD}
              </button>

              <Link
                to={`/signin`}
                className={cx('fontPoppinsSemiboldLg', commonStyles['common-btn'], commonStyles['orange-text'])}
              >
                <span>
                  &nbsp;
                  <img alt="back" src={BackArrow} height="22px" width="22px" style={{ display: 'inline' }} />
                </span>
                <span className="pl-2">{GLOBAL_CONSTANTS.BACK_TO_SIGN_IN}</span>
              </Link>
            </div>
          </div>
        )}
      </form>
    </CommonPage>
  );
}

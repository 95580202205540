import React, { useEffect, useState, useContext } from 'react';

import MachineTable from '@src/pages/common/MachineTable/MachineTable';
import ConfigMachineDetails from './components/config-machine-details';
import './styles/systemConfigurationStyles.scss';
import DownloadClientModal from '../system_config v1.1/modals/download_client_modal';
import {
  getBrowserStackList,
  getSauceLabsList,
  getAllLambdaTestsList,
  getAllClientMachineReq,
  getUserAllAvailableMachineReq,
  addOrChangeDefaultMachineReq,
} from '@api/api_services';
import ConfigAccessDetails from './components/config-access-details';

import { CommonLoader } from '@pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { machineJson } from '@src/pages/test-development/script/modules/module/utils/constants';
import { FiDownload } from 'react-icons/fi';
import AlertPopUps from '@src/pages/common/alert_pop_ups/AlertPopUps';
import { ALERT_MODAL_CONSTANTS, FIRECLOUD_DATA } from '@src/common/ui-constants';
import CommonButton from '@src/common/button/Button';
import SearchBar from '@src/pages/analytics/common/search-bar';
import { isEmptyValue, isValueIncludes } from '@util/common_utils';
import NoResultsFound from '@src/pages/common/Table/NoResultFound';

const SystemConfigurationBase = (props) => {
  const { ALERT } = ALERT_MODAL_CONSTANTS;
  const user = JSON.parse(localStorage.getItem('test-optimize-user'));
  const project = JSON.parse(localStorage.getItem('selected-project'));
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [reload, setReload] = useState(true);
  const [isLoading, setIsLoading] = useState(null);
  const [clientSystems, setClientSystems] = useState([]);
  const [defaultSystem, setDefaultSystem] = useState(null);
  const [selectedSystem, setSelectedSystem] = useState(null);
  const [downloadClientModal, setDownloadClientModal] = useState(false);
  const [instanceList, setInstanceList] = useState();
  const [updatedSelectedMachine, setUpdatedSelectedMachine] = useState();
  const [showAccessDetails, setShowAccessDetails] = useState(false);
  const [showDefaultMachineModal, setShowDefaultMachineModal] = useState(false);
  const [defaultAPICalled, setDefaultAPICalled] = useState(false);

  const LoggedInUserName = JSON.parse(localStorage.getItem('test-optimize-user')).name;

  const handleAccessDetailsModal = (value) => {
    setShowAccessDetails(value);
  };
  const handleReload = (value) => {
    setReload(value);
  };
  const handleChangeDefaultMachine = () => {
    setShowDefaultMachineModal(true);
  };
  const defaultMahcineChange = async (changeDefault) => {
    try {
      let response;
      if (changeDefault) {
        if (defaultAPICalled) {
          return;
        }
        setDefaultAPICalled(true);
        if (project.id === '') {
          response = await addOrChangeDefaultMachineReq(user?.id, updatedSelectedMachine);
        } else {
          response = await addOrChangeDefaultMachineReq(user?.id, updatedSelectedMachine, project?.id);
        }
        setDefaultAPICalled(false);
        if (response.data?.responseCode === 200 && response.data?.status === 'SUCCESS') {
          if (defaultSystem?.id) {
            props.MyAlert.success(
              `Default Machine changed from ${defaultSystem.machine.hostName} to ${updatedSelectedMachine.machine.hostName}`
            );
          } else {
            props.MyAlert.success(`${updatedSelectedMachine.machine.hostName} added as Default Machine`);
          }
          closeModal(true);
          setReload(true);
        } else {
          if (document.getElementById(`${updatedSelectedMachine.clientId}`)) {
            document.getElementById(`${updatedSelectedMachine.clientId}`).checked = false;
          }
          if (document.getElementById(`${defaultSystem?.clientId}`)) {
            document.getElementById(`${defaultSystem?.clientId}`).checked = true;
          }
          props.MyAlert.warning(`${response.data?.status} ${response.data?.message}`);
        }
      } else {
        if (document.getElementById(`${updatedSelectedMachine.clientId}`)) {
          document.getElementById(`${updatedSelectedMachine.clientId}`).checked = false;
        }
        if (document.getElementById(`${defaultSystem?.clientId}`)) {
          document.getElementById(`${defaultSystem?.clientId}`).checked = true;
        }
      }
    } catch (err) {
      console.error('CHANGE_DEFAULT : ', err);
      setReload(false);
    }
  };
  const getUpdatedSelectedMachine = (updatedSystem) => {
    setUpdatedSelectedMachine(updatedSystem);
  };
  const handleSelectedSystem = (system) => {
    if (system.clientId !== selectedSystem.clientId) {
      setSelectedSystem(system);
      if (showAccessDetails) {
        setShowAccessDetails(false);
      }
    }
  };
  const closeModal = (val) => {
    if (downloadClientModal) {
      setDownloadClientModal(val);
    }
    if (showDefaultMachineModal) {
      setShowDefaultMachineModal(false);
    }
  };
  const filterSystems = (clientSystems, firecloudEnabled) => {
    return clientSystems?.filter(
      (resp) =>
        (resp?.executionEnvironment?.toLowerCase() === FIRECLOUD_DATA.FIRE_CLOUD && firecloudEnabled) ||
        resp?.executionEnvironment?.toLowerCase() !== FIRECLOUD_DATA.FIRE_CLOUD
    );
  };
  const getSystems = async () => {
    try {
      resetProgress();
      startOrResumeProgress({
        stopAt: 90,
      });
      setIsLoading(true);
      let data;
      let response;
      let defaultMachine;
      let browserInstanceArray = [];
      let sauceLabInstanceArray = [];
      let lambdaTestsInstanceArray = [];
      let browserList = await getBrowserStackList();
      let sauceLabsList = await getSauceLabsList();
      let lambdaTestList = await getAllLambdaTestsList();
      if (isEmptyValue(project.id) && ['Super Admin', 'Admin'].includes(user.privilege)) {
        const responseData = await getAllClientMachineReq();
        const response = responseData.data.responseObject.clientSystems.filter(
          (resp) =>
            (resp?.executionEnvironment?.toLowerCase() === FIRECLOUD_DATA.FIRE_CLOUD &&
              responseData.data.responseObject.firecloudEnabled === true) ||
            resp?.executionEnvironment?.toLowerCase() !== FIRECLOUD_DATA.FIRE_CLOUD
        );
        data = filterSystems(
          responseData?.data?.responseObject?.clientSystems,
          responseData?.data?.responseObject?.firecloudEnabled
        );
        defaultMachine = responseData.data?.responseObject?.licenseDefaultMachine;
        browserInstanceArray = browserList.data.responseObject;
        sauceLabInstanceArray = sauceLabsList.data.responseObject;
        lambdaTestsInstanceArray = lambdaTestList.data.responseObject;
      } else if (!isEmptyValue(project.id) && ['Super Admin', 'Admin'].includes(user.privilege)) {
        response = await getAllClientMachineReq(project.id);
        data = filterSystems(response?.data?.responseObject?.clientSystems, response?.data?.responseObject?.firecloudEnabled);
        defaultMachine = response.data?.responseObject?.projectDefaultMachine;
        browserInstanceArray = browserList.data.responseObject;
        sauceLabInstanceArray = sauceLabsList.data.responseObject;
        lambdaTestsInstanceArray = lambdaTestList.data.responseObject;
      } else if (isEmptyValue(project.id) && user.privilege === 'User') {
        response = await getUserAllAvailableMachineReq(user.id);
        data = filterSystems(response?.data?.responseMap?.userSystems, response?.data?.responseMap?.firecloudEnabled);
        defaultMachine = response.data?.responseMap?.defaultSystems;
        browserInstanceArray = browserList?.data?.responseObject;
        sauceLabInstanceArray = sauceLabsList?.data?.responseObject;
        lambdaTestsInstanceArray = lambdaTestList?.data?.responseObject;
      } else {
        response = await getUserAllAvailableMachineReq(user.id, project.id);
        data = filterSystems(response?.data?.responseMap?.userSystems, response?.data?.responseMap?.firecloudEnabled);
        defaultMachine = response.data?.responseMap?.defaultSystems;
        browserInstanceArray = browserList?.data?.responseObject;
        sauceLabInstanceArray = sauceLabsList?.data?.responseObject;
        lambdaTestsInstanceArray = lambdaTestList?.data?.responseObject;
      }
      stopProgress();
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 10,
      });
      if (data?.length) {
        setClientSystems(data);
        setInstanceList({
          browserInstanceArray,
          sauceLabInstanceArray,
          lambdaTestsInstanceArray,
        });
        setDefaultSystem({ ...defaultMachine });
        if (defaultMachine) {
          let machineSelected = false;
          for (let i = 0; i < data.length; i++) {
            if (data[i]?.clientId === defaultMachine?.clientId) {
              machineSelected = true;
              setSelectedSystem({ ...data[i] });
              break;
            }
          }
          if (!machineSelected) {
            setDefaultSystem({ ...machineJson });
            setSelectedSystem({ ...data[0] });
          }
        } else {
          setSelectedSystem({ ...data[0] });
        }
      } else {
        setClientSystems([]);
        setInstanceList({
          browserInstanceArray: [],
          sauceLabInstanceArray: [],
          lambdaTestsInstanceArray: [],
        });
        setSelectedSystem(null);
        setDefaultSystem();
      }
      setIsLoading(false);
    } catch (err) {
      resetProgress();
      console.error('GET_ALL_CLIENTS : ', err);
    }
  };

  useEffect(() => {
    if (reload) {
      getSystems();
    }
    setReload(false);
  }, [reload]);

  useEffect(() => {
    if (props.reloadScreen) {
      setReload(true);
      props.handleReloadScreen(false);
    }
  }, [props.reloadScreen]);

  // TODO :: handle Search filter :: START

  let [openSearch, setOpenSearch] = useState(false);
  const [userSearched, setuserSearched] = useState('');
  const [data, setData] = useState([]);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');
  let [isHighlight, setIsHighlight] = useState(false);

  useEffect(() => {
    setData(clientSystems);
    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [clientSystems]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(clientSystems);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setData(clientSystems);
    setIsHighlight(!isHighlight);
    setSearchTextHighlight('');
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = clientSystems.filter(({ machine }) => {
          return isValueIncludes(e, machine?.hostName);
        });
        setData(newData);
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  // TODO :: handle Search filter :: END

  return (
    <div className="env-config-bg-color">
      <div className="flex justify-between items-center h-9 px-3">
        <span className="fontPoppinsMediumSm">Client Machines</span>
        <div className="flex gap-2 items-center">
          <SearchBar
            open={openSearch}
            searchValue={userSearched}
            handleSearch={handleSearch}
            onSearchClose={handleSearchClose}
            handleSearchOpen={handleSearchOpen}
            enterKeyHandler={handleSearchSubmit}
            disable={isEmptyValue(clientSystems)}
          />

          <CommonButton
            startIcon={<FiDownload className="inline mb-1 mr-1 w-3 h-3" />}
            label="Download Client"
            type="primary"
            onClick={() => {
              setDownloadClientModal(true);
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-5 gap-4 px-2 py-2">
        {isLoading || isLoading === null ? (
          <div className="col-span-5 details-div allign-div-center config_div_table_height">
            <CommonLoader />
          </div>
        ) : clientSystems.length === 0 ? (
          <div className="col-span-5 details-div allign-div-center config_div_table_height">
            {downloadClientModal ? <DownloadClientModal closeModal={closeModal} /> : null}

            <div className="fontPoppinsRegularMd">
              <div className="">
                <p className="font-bold mb-4">Quick Start</p>
                <p className="mb-4 flex">
                  <span className="font-bold">Step 1 :&nbsp;</span> Click &nbsp;
                  <CommonButton
                    startIcon={<FiDownload className="inline mb-1 mr-1 w-3 h-3" />}
                    label="Download Client"
                    type="primary"
                    onClick={() => {
                      setDownloadClientModal(true);
                    }}
                  />
                  &nbsp;&nbsp; to automatically configure your local machine &nbsp;
                  <span>&#38;&nbsp;</span> Device
                </p>
              </div>
            </div>
          </div>
        ) : (
          <>
            {openSearch && isEmptyValue(data) ? (
              <div className="col-span-5 mt-14 pt-6 pb-7 h-65v  ">
                <NoResultsFound />
              </div>
            ) : (
              <>
                <div className="col-span-2">
                  <MachineTable
                    section="configuration"
                    inputType={'radio'}
                    systems={data}
                    selectedSystems={[defaultSystem]}
                    onRowClickHandler={handleSelectedSystem}
                    classes={'config_div_table_height'}
                    currentVisibleSystem={selectedSystem}
                    radioButtonOnChangeHandler={handleChangeDefaultMachine}
                    searchTextHighlight={searchTextHighlight}
                    isHighlight={isHighlight}
                  />
                </div>
                <div className="col-span-3 details-div">
                  <ConfigMachineDetails
                    btnDisabled={props.disabledButton}
                    selectedSystem={selectedSystem}
                    instanceList={instanceList}
                    defaultSystem={defaultSystem}
                    project={project}
                    user={user}
                    getUpdatedSelectedMachine={getUpdatedSelectedMachine}
                    MyAlert={props.MyAlert}
                    handleReload={handleReload}
                    handleAccessDetailsModal={handleAccessDetailsModal}
                    handleDataChangedState={props.handleDataChangedState}
                    onResetHandler={props.handleCancel}
                    onUpdateHandler={props.handleSave}
                    dataChange={props.isDataChanged}
                  />
                </div>
              </>
            )}

            {showDefaultMachineModal ? (
              <AlertPopUps
                execution={true}
                open={showDefaultMachineModal}
                alertType={ALERT}
                showHowToProceed={true}
                showCancelBtn={true}
                showSaveBtn={true}
                saveBtnText="Set as Default"
                alertTitle="Change Default Machine"
                onCancelBtnClick={closeModal}
                onSaveBtnClick={defaultMahcineChange}
                content={[
                  <div className="mb-2 capitalize">Hi {LoggedInUserName},</div>,
                  <div>Are you sure you want to Change default machine {selectedSystem?.machine?.hostName} ?</div>,
                ]}
              />
            ) : null}
            {showAccessDetails && (
              <ConfigAccessDetails
                machine={selectedSystem}
                MyAlert={props.MyAlert}
                handleAccessDetailsModal={handleAccessDetailsModal}
                handleReload={handleReload}
              />
            )}

            {downloadClientModal ? <DownloadClientModal closeModal={closeModal} /> : null}
          </>
        )}
      </div>
    </div>
  );
};

export default SystemConfigurationBase;

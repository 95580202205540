import React from 'react';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';

let radiobuttonData = [];
let radiobuttonName = 'radiobutton';

const handleCheck = (e) => {
  console.log('data::', e, e.target.value, e.nativeEvent.path[0].checked);
};

const RadioButton = (props, popupType) => {
  const [radioVal, SetRadioVal] = React.useState();
  radiobuttonData = props.radioList;
  radiobuttonName = props.radioName;

  const handleCheck = (e) => {
    let radioVal = e.target.value;
    SetRadioVal(radioVal);
    props.func(radioVal);
  };
  const [edit, setEdit] = React.useState(props.popupType);
  return (
    <div>
      {radiobuttonData.map((radiotxt, optindex) => {
        return (
          <>
            <RadioGroup
              row
              aria-label="position"
              name="position"
              defaultValue="top"
              style={{ display: '-webkit-inline-flex' }}
            >
              <FormControlLabel
                control={
                  <Radio
                    color="primary"
                    name={radiobuttonName}
                    disabled={edit === 'Edit Project' ? true : props?.rerunMode ? props?.rerunMode : false}
                    {...{ checked: radiobuttonData.length === 1 || (props.editData && radiotxt === props.editData) }}
                    id={radiotxt + optindex}
                    label={radiotxt}
                    className=" m-2 cursor-pointer"
                    value={radiotxt}
                    onChange={handleCheck}
                  />
                }
                label={<Typography className="radio-btn-style">{radiotxt}</Typography>}
              />
            </RadioGroup>
          </>
        );
      })}
    </div>
  );
};

export default RadioButton;

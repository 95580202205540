import { styled } from '@mui/material/styles';
import { withStyles } from '@material-ui/core/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiListItem from '@material-ui/core/ListItem';
import { LAYOUT_CONSTANTS } from '../constants/LayoutConstants';
import { fontPoppins } from '@src/css_config/fontConstants';
const { PRIMARY_COLOR, WHITE_COLOR, BLACK_COLOR, ALL_PROJECT_LIST_BG_COLOR } = LAYOUT_CONSTANTS;
const { sLg, rLg } = fontPoppins;

const drawerWidth = 200;
const selected = {
  width: '190px',
  backgroundColor: `${WHITE_COLOR} !important`,
  color: PRIMARY_COLOR,
  borderBottomLeftRadius: '50px',
  borderTopLeftRadius: '50px',
  boxShadow: `0px 0px 2px ${BLACK_COLOR}`,
  left: '10px',
  '& .MuiListItemIcon-root': {
    marginLeft: '8px',
    color: PRIMARY_COLOR,
  },
  '& .MuiTypography-root': {
    ...sLg,
  },
  '& .MuiListItem-gutters': {
    paddingLeft: 'unset',
    paddingRight: 'unset',
  },
  '&:hover': {
    backgroundColor: `${WHITE_COLOR} !important`,
    color: PRIMARY_COLOR,
    boxShadow: `0px 0px 2px ${BLACK_COLOR}`,
    '& .MuiListItemIcon-root': {
      color: PRIMARY_COLOR,
    },
  },
};
const root = {
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: `${ALL_PROJECT_LIST_BG_COLOR} !important`,
    color: WHITE_COLOR,
    boxShadow: `0px 0px 2px ${BLACK_COLOR}`,
    '& .MuiListItemIcon-root': {
      color: WHITE_COLOR,
    },
  },
  '& .MuiListItem-gutters': {
    paddingLeft: 'unset',
    paddingRight: 'unset',
  },
  '& .MuiTypography-root': {
    ...rLg,
  },
};

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: 'hidden',
  backgroundColor: PRIMARY_COLOR,
  color: WHITE_COLOR,
  border: 'none',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflow: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: PRIMARY_COLOR,
  color: WHITE_COLOR,
  border: 'none',
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  minHeight: '48px',
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const ListItem = withStyles({
  root: {
    ...root,
    '& .MuiListItemText-root': {
      marginLeft: '-10px',
    },
    '& .MuiListItemIcon-root': {
      color: WHITE_COLOR,
      marginLeft: '16px',
      marginRight: '24px',
    },
  },
  selected: selected,
})(MuiListItem);

export const ListItemIconAlignment = withStyles({
  root: {
    ...root,
    '& .MuiListItemText-root': {
      marginLeft: '14px',
    },
    '& .MuiListItemIcon-root': {
      color: WHITE_COLOR,
    },
  },
  selected: selected,
})(MuiListItem);

import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAlert } from '@pages/common/alert_service/useAlert';
import SwitchLicenseDropdown from '@pages/licenses/components/SwitchLicenseDropdown';
import style from './styles/licenses.module.scss';
import cx from 'classnames';

const LicenseTabRouter = (props) => {
  const { AlertContatiner } = useAlert();
  const history = useHistory();
  let activeTab = history.location.pathname.split('/')[2];
  let [showSelectLicense, setShowSelectLicense] = useState(false);
  const [tabsList, setTabList] = useState([
    {
      name: 'FireFlink License',
      pathUrl: `/licenses/fireflink-license`,
      selectedName: 'fireflink-license',
    },
  ]);

  useEffect(() => {
    if (props.showHistory) {
      setTabList([
        {
          name: 'FireFlink License',
          pathUrl: `/licenses/fireflink-license`,
          selectedName: 'fireflink-license',
        },
        // {
        //     name: "FireCloud License",
        //     pathUrl: `/licenses/firecloud-license`,
        //     selectedName: "firecloud-license",
        // },
        {
          name: 'Payment History',
          pathUrl: `/licenses/payment-history`,
          selectedName: 'payment-history',
        },
      ]);
    } else {
      setTabList([
        {
          name: 'FireFlink License',
          pathUrl: `/licenses/fireflink-license`,
          selectedName: 'fireflink-license',
        },
      ]);
    }
  }, [props?.showHistory]);

  const renderTabs = () => {
    return (
      <>
        <div className={cx(style['tabs-link-border'], 'license-tab-navbar-section')}>
          <ul className={cx(style['license-tabs'], 'navBar flex flex-row tab-border')}>
            {tabsList.map((tab, index) => {
              return (
                <span key={tab.selectedName}>
                  <span
                    className={
                      activeTab === tab.selectedName
                        ? cx(style['license-selected-tab-style'], 'flex flex-row pt-2 pb-0  mr-4 ')
                        : cx(style['license-default-tab-style'], 'flex flex-row  pt-2 pb-0 mr-4')
                    }
                  >
                    <Link
                      className={
                        activeTab === tab.selectedName
                          ? cx(style['selected-license-tab'], 'fontPoppinsSemiboldMd px-2.5 py-1.5')
                          : cx(' px-2.5 py-1.5 fontPoppinsRegularMd')
                      }
                      to={tab.pathUrl}
                    >
                      {tab.name}
                    </Link>
                  </span>
                </span>
              );
            })}
          </ul>
        </div>
      </>
    );
  };
  return (
    <div className="page-list-body">
      <div className="alert-position-style">
        <AlertContatiner></AlertContatiner>
      </div>
      {renderTabs()}
      {<SwitchLicenseDropdown showSelectLicense={showSelectLicense} setShowSelectLicense={setShowSelectLicense} />}
    </div>
  );
};

export default LicenseTabRouter;

import React, { useEffect, useState, useContext } from 'react';
import Modal from 'react-modal';
import { useTable, useRowSelect, useFilters } from 'react-table';
import { addmultisharedElementReq, getAllSharedElements } from '@api/api_services';
import { GrFormFilter } from 'react-icons/gr';
import '../shared_recorded_elements.scss';
import CheckboxComp from './checkbox-comp';
import { useAlert } from '@pagescommon/alert_service/useAlert';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import SearchBar from '@src/pages/analytics/common/search-bar';
import { HighlightText, isEmptyValue, isValueIncludes } from '@util/common_utils';
import NoResultsFound from '@src/pages/common/Table/NoResultFound';
import CloseIcon from '@material-ui/icons/Close';

export const MultipleFilter = (rows, accessor, filterValue) => {
  const arr = [];
  rows.forEach((val) => {
    if (filterValue.includes(val.original[accessor])) arr.push(val);
  });
  return arr;
};

let duplicateEle = [];

const AddFromSharedModal = (props) => {
  const [showModal, setShowModal] = useState(true);
  const { AlertContatiner, MyAlert } = useAlert();
  let customStylesElement = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      right: '25%',
      bottom: '5%',
      height: '699px',
      width: '932px',
      padding: '0px',
      boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
    },
  };
  let [sharedElements, setSharedElements] = useState([]);
  let [selectedSharedElements, setSelectedSharedElements] = useState([]);

  const [userSearched, setuserSearched] = useState('');
  let [isHighlight, setIsHighlight] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');

  let addedEle = [];
  const project = JSON.parse(localStorage.getItem('selected-project'));
  let allSharedEle = [];
  let elements = [];
  useEffect(() => {
    getAllSharedEle();
  }, []);

  let returnname = (nameArray) => {
    let names = [];
    for (let i = 0; i < nameArray.length; i++) {
      names.push(nameArray[i].name);
    }
    return names.toString();
  };

  let addSharedElements = async () => {
    try {
      let response = await addmultisharedElementReq(props.addSharedEleTo, {
        sharedElementIds: selectedSharedElements,
      });
      props?.getAllElemtsReq(props?.projectType, props?.platForm);
      props.reloadTree(true);
    } catch (err) {
      console.log(err);
    }
  };

  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  let getAllSharedEle = async () => {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    let filteredData = [];
    try {
      let response = await getAllSharedElements(project.id, project.type, props.platForm);
      stopProgress();
      let elementsData = response.data.responseObject;
      elementsData.map((element, index) => {
        let isPresent = false;
        for (let i = 0; i < props.unsharedchildElements.length; i++) {
          if (
            props.unsharedchildElements[i].id === element.id ||
            (props.unsharedchildElements[i]?.name?.toLowerCase() === element?.name.toLowerCase() &&
              props.unsharedchildElements[i].type === element.type)
          ) {
            isPresent = true;
            break;
          }
        }
        filteredData.push({
          sharedBetweenPages: element.pageIdsNames,
          elementId: element.id,
          name: element.name,
          elementType: element.type,
          eleName: element.name,
          isSelected: false,
          disabled: isPresent,
          pageIdsNames: element.pageIdsNames,
        });
      });
      allSharedEle = filteredData;
      setSharedElements(filteredData);
    } catch (err) {
      console.log(err);
    }
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 10,
    });
  };

  let addOrRemoveElement = (ele, add) => {
    if (add && ele !== 'all') {
      elements.push(ele.elementId);
      if (
        addedEle.indexOf(ele?.eleName.toLowerCase() + ele.elementType) >= 0 &&
        duplicateEle.indexOf(ele?.eleName.toLowerCase() + ele.elementType) < 0
      ) {
        duplicateEle.push(ele?.eleName.toLowerCase() + ele.elementType);
      }
      addedEle.push(ele?.eleName.toLowerCase() + ele.elementType);
    } else if (!add && ele !== 'all') {
      let count = 0;
      elements.splice(elements.indexOf(ele.elementId), 1);
      if (addedEle.indexOf(ele?.eleName.toLowerCase() + ele.elementType) >= 0) {
        if (ele?.eleName && ele.elementType) {
          addedEle.splice(addedEle.indexOf(ele?.eleName.toLowerCase() + ele.elementType), 1);
        }
      }
      addedEle.forEach((nameType) => {
        if (nameType === `${ele?.eleName.toLowerCase()}${ele.elementType}`) {
          count = count + 1;
        }
      });
      if (count <= 1) {
        if (duplicateEle.indexOf(ele?.eleName.toLowerCase() + ele.elementType) >= 0) {
          duplicateEle.splice(duplicateEle.indexOf(ele?.eleName.toLowerCase() + ele.elementType), 1);
        }
      }
    } else if (add && ele === 'all') {
      elements = [];
      addedEle = [];
      let duplicate = [];
      allSharedEle.map((ele) => {
        if (!ele.disabled) {
          elements.push(ele.elementId);
          if (
            addedEle.indexOf(ele?.eleName.toLowerCase() + ele.elementType) >= 0 &&
            duplicate.indexOf(ele?.eleName.toLowerCase() + ele.elementType) < 0
          ) {
            duplicate.push(ele?.eleName.toLowerCase() + ele.elementType);
          }
          addedEle.push(ele?.eleName.toLowerCase() + ele.elementType);
        }
      });
      duplicateEle = [...duplicate];
    } else if (!add && ele === 'all') {
      elements = [];
      addedEle = [];
      duplicateEle = [];
    }
    setSelectedSharedElements(elements);
  };
  useEffect(() => {
    if (selectedSharedElements.length === 0) {
      duplicateEle = [];
    }
  }, [selectedSharedElements]);

  function setFilteredParams(filterArr, val) {
    if (filterArr.includes(val)) {
      filterArr = filterArr.filter((n) => {
        return n !== val;
      });
    } else filterArr.push(val);

    if (filterArr.length === 0) filterArr = undefined;
    return filterArr;
  }

  function on() {
    if (document.getElementById('tablefilter').style.display === 'block') {
      document.getElementById('tablefilter').style.display = 'none';
    } else {
      document.getElementById('tablefilter').style.display = 'block';
    }
  }

  function off() {
    document.getElementById('tablefilter').style.display = 'none';
  }

  let contextMenuData = [
    'None',
    'Link',
    'Textbox',
    'Button',
    'Radiobutton',
    'Checkbox',
    'Text',
    'Textarea',
    'image',
    'Dropdown',
    'visual testing'
  ];
  if (
    (project.type === 'Mobile' &&
      (props.platForm === 'Android' || props.platForm === 'iOS' || props.platForm === 'Ios') &&
      (project.appType === 'Hybrid' || project.appType === 'Native')) ||
    (project.type === 'Web & Mobile' &&
      (props.platForm === 'Android' ||
        props.platForm === 'MobileWeb' ||
        props.platForm === 'iOS' ||
        props.platForm === 'Ios'))
  ) {
    contextMenuData = [
      'link',
      'textfield',
      'icon',
      'button',
      'radiobutton',
      'checkbox',
      'tab',
      'action overflow button',
      'hamburger menu',
      'toggle button',
      'steppers',
      'sliders',
      'text',
      'option',
      'text area',
      'suggestion',
      'time picker',
      'date picker',
      'toaster message',
      'card',
      'tooltip',
      'dropdown',
      'image',
      'calendar',
      'visual testing'
    ];
  }
  if ((project.type === 'Web & Mobile' && props.platForm === 'Web') || project.type === 'Web') {
    contextMenuData = [
      'link',
      'textfield',
      'button',
      'radiobutton',
      'checkbox',
      'tab',
      'text',
      'textarea',
      'image',
      'dropdown',
      'frame',
      'suggestion',
      'option',
      'time picker',
      'date picker',
      'popup',
      'toaster message',
      'card',
      'icon',
      'tooltip',
      'slider',
      'hamburger icon',
      'toggle button',
      'action overflow button',
      'visual testing'
    ];
  }
  if (project.type === 'Web') {
    contextMenuData = [
      'link',
      'textfield',
      'button',
      'radiobutton',
      'checkbox',
      'tab',
      'text',
      'textarea',
      'image',
      'dropdown',
      'frame',
      'suggestion',
      'option',
      'time picker',
      'date picker',
      'popup',
      'toaster message',
      'card',
      'icon',
      'tooltip',
      'slider',
      'hamburger icon',
      'toggle button',
      'action overflow button',
      'visual testing'
    ];
  }

  const backgroundStyleFilter = {
    backgroundColor: 'white',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    borderRadius: '5px',
  };

  function SelectColumnFilter({ column: { filterValue = [], setFilter, preFilteredRows, id } }) {
    return (
      <span className="flex">
        <GrFormFilter className="ml-3 mt-1 text-lg cursor-pointer" onClick={() => on()} />
        <div
          className="status-filter absolute flex-col mt-9 step-result mx-7 hidden pt-3 h-80 overflow-auto w-52"
          id="tablefilter"
          onClick={() => {
            off();
          }}
          style={backgroundStyleFilter}
        >
          {contextMenuData.map((type) => {
            return (
              <div className="hoverRow w-48 h-8 ">
                <input
                  type="checkbox"
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 ml-2"
                  id={type}
                  name={type}
                  value={type}
                  onChange={(e) => {
                    if (e.target.value !== 'None') {
                      setFilter(setFilteredParams(filterValue, e.target.value));
                    }
                  }}
                ></input>
                <label htmlFor={type} className="ml-1.5 font-medium text-gray-700 fontPoppinsSemiboldMd">
                  {type}
                </label>
              </div>
            );
          })}
        </div>
      </span>
    );
  }

  // TODO :: handle Search filter :: START

  const [openSearch, setOpenSearch] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(sharedElements);

    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [sharedElements]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(sharedElements);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setData(sharedElements);
    setIsHighlight(!isHighlight);
    setSearchTextHighlight('');
  };
  let handleSearchOpen = () => {
    setOpenSearch(true);
  };
  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = sharedElements.filter(({ name, sharedBetweenPages }) => {
          return isValueIncludes(e, name) || isValueIncludes(e, returnname(sharedBetweenPages));
        });
        setData(newData);
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };
  // TODO :: handle Search filter :: END

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ value }) => {
          return (
            <div className="path-trimmer" title={value ? value : '--'}>
              <HighlightText text={value ? value : '--'} highlight={searchTextHighlight} />{' '}
            </div>
          );
        },
      },
      {
        Header: <span className="float-left">Type</span>,
        accessor: 'elementType',
        Filter: SelectColumnFilter,
        filter: MultipleFilter,
      },
      {
        Header: `Shared Between ${
          project.type === 'Mobile'
            ? 'Screens'
            : project.type === 'Web'
              ? 'Pages'
              : (project.type === 'Web & Mobile' || project.type === 'Salesforce') && props.platForm === 'Web'
                ? 'Pages'
                : (project.type === 'Web & Mobile' || project.type === 'Salesforce') &&
                    (props.platForm === 'Android' ||
                      props.platForm === 'iOS' ||
                      props.platForm === 'Ios' ||
                      props.platForm === 'MobileWeb')
                  ? 'Screens'
                  : 'Pages'
        }`,
        accessor: 'sharedBetweenPages',
        width: '30%',
        Cell: ({ value }) => {
          return (
            <div className="sharedwidth ">
              <div className="path-trimmer" title={value ? returnname(value) : '--'}>
                <HighlightText text={value ? returnname(value) : '--'} highlight={searchTextHighlight} />
              </div>
            </div>
          );
        },
      },
    ],
    [isHighlight]
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: 'selection',
            width: '10%',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <CheckboxComp {...getToggleAllRowsSelectedProps()} addOrRemoveElement={addOrRemoveElement} value="all" />
            ),
            Cell: ({ row }) => {
              let eleData = {
                disabled: row.original.disabled,
                elementId: row.original.elementId,
                elementType: row.original.elementType,
                eleName: row.original.eleName,
                isSelected: row.original.isSelected,
              };
              return (
                <CheckboxComp
                  {...row.getToggleRowSelectedProps()}
                  value={row.original.elementId}
                  eleData={eleData}
                  disabled={row.original.disabled}
                  addOrRemoveElement={addOrRemoveElement}
                />
              );
            },
          },
          ...columns,
        ];
      });
    }
  );

  return (
    <div>
      <Modal
        isOpen={showModal}
        style={customStylesElement}
        onRequestClose={() => {
          setShowModal(false);
          props.closeModal(false);
        }}
      >
        <div className="modal-container">
          <div className="pb-4 sepratorLine ">
            <div className="alert-position-Shared">
              <AlertContatiner></AlertContatiner>
            </div>
            <div className="flex justify-between px-4 pt-4">
              <h2 className="Popup-header-common text-15 ">
                <b>
                  <span> Add from Shared Elements</span>
                </b>
              </h2>
              <div className="flex  gap-2 ">
                <div>
                  <SearchBar
                    open={openSearch}
                    searchValue={userSearched}
                    handleSearch={handleSearch}
                    onSearchClose={handleSearchClose}
                    handleSearchOpen={handleSearchOpen}
                    enterKeyHandler={handleSearchSubmit}
                    disable={isEmptyValue(sharedElements)}
                  />
                </div>
                <button
                  onClick={() => {
                    setShowModal(false);
                    props.closeModal(false);
                  }}
                  type="button"
                  className="text-gray-400 hover:text-gray-500 "
                >
                  <CloseIcon />
                </button>
              </div>
            </div>
          </div>
          <div className="sepratorLine pl-8 pt-6 pb-6 pr-7 page-table-body">
            <div className="tableShadow sharedtableSize pb-1 overflow-auto" id="journal-scroll">
              <table {...getTableProps()} className="">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      className="fontPoppinsSemiboldMd headerStyle h-12 ml-4 sticky top-0 zIndex-1"
                    >
                      {headerGroup.headers.map((column) => {
                        return (
                          <th width={column.width} {...column.getHeaderProps()} className="text-left">
                            {column.render('Header')}
                            {column.id === 'elementType' ? (
                              <span>{column.canFilter ? column.render('Filter') : null}</span>
                            ) : null}
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className="configdataStyle fontPoppinsRegularMd">
                  {rows.map((row, index) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        className={`configHover h-11 content-start ${row.original.disabled ? 'disableColor' : null}`}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()} className="p-0 text-left">
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {openSearch && isEmptyValue(data) && <NoResultsFound className="mt-14" />}
            </div>
          </div>
          <div className="mt-1 float-right mr-4 pb-3">
            <button
              type="button"
              onClick={() => {
                props.closeModal(false);
              }}
              className="gray-btn mr-3"
            >
              Cancel
            </button>
            <button
              type="button"
              className="primary-btn mr-2.5"
              onClick={() => {
                if (duplicateEle.length !== 0) {
                  MyAlert.info(`Multiple Elements with same name and same type cannot be moved into same page `);
                } else {
                  addSharedElements();
                  props.closeModal(false);
                }
              }}
              disabled={selectedSharedElements.length === 0}
            >
              Add
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddFromSharedModal;

import React, { useState, useEffect, useContext } from 'react';
import '@pages/test-development/script/scripts/webservice/restapi/css/common_Webservice.scss';
import MyInput from '@pagescommon/Inputs/MyInput';
import { RestContext } from '@pages/test-development/script/scripts/webservice/restapi/restapi';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';
import CustomCheckBox from '@pages/test-development/script/scripts/webservice/CustomCheckBox';
import { isEmptyValue } from '@src/util/common_utils';
import { WEBSERVICE } from '../../../constants/constants';

export default function StickyHeadTable(props) {
  const context = useContext(RestContext);
  const { queryParams } = context.values;
  const [projectTypeList, setProjectTypeList] = useState(context.values.queryParams);
  const [isSelectAll, setSelectAll] = useState(false);
  const [projectTypeLength, setProjectTypeLength] = useState(0);
  const [onMouseHoverRow, setonMouseHoverRow] = useState(-1);
  const { ENABLED_QUERY_PARAM_ROW, INITIAL_PROJECT_TYPE_LIST } = WEBSERVICE;

  useEffect(() => {
    const modifiedProjectTypeList = projectTypeList.filter((item) => item.name && item.value);
    const modifiedProjectTypeListLength = modifiedProjectTypeList.length;
    setProjectTypeLength(modifiedProjectTypeListLength);
    const isAllChecked = projectTypeList.every((items) => items.isEnabled);
    setSelectAll(isAllChecked);
  }, [projectTypeList]);

  useEffect(() => {
    getStepsUpdate();
  }, [props.selectedStepData]);

  useEffect(() => {
    if (context.values.isStepUpdated) {
      context.values.isStepUpdated = false;
    }
    const getRequestUrl = formatStringToObject(props.onGetRequestURL);
    let getProjectList = [];
    const formatValue = projectTypeList.filter((queryParam) => {
      return (queryParam.name || queryParam.value) && !queryParam.isEnabled;
    });
    if (!isEmptyValue(getRequestUrl)) {
      getProjectList = [...formatValue, ...getRequestUrl];
      getProjectList = getProjectList.sort((queryParam1, queryParam2) => queryParam1.id - queryParam2.id);
      setProjectTypeList(getProjectList);
    } else {
      if (!isEmptyValue(formatValue)) {
        getProjectList = formatValue;
        setProjectTypeList(formatValue);
      } else if (isEmptyValue(getRequestUrl) && isEmptyValue(formatValue)) {
        getProjectList = INITIAL_PROJECT_TYPE_LIST;
        setProjectTypeList(INITIAL_PROJECT_TYPE_LIST);
      } else {
        getProjectList = queryParams;
        setProjectTypeList(queryParams);
      }
    }
    props.getParamValueCode(getProjectList || []);
  }, [props.onGetRequestURL]);

  const formatStringToObject = (queryParam) => {
    if (queryParam === ' ') queryParam = '';
    let queryParamArray = [];
    if (queryParam === '?') {
      return ENABLED_QUERY_PARAM_ROW;
    } else {
      if (queryParam) {
        queryParamArray = queryParam.substring(1).split('&');
      }
    }

    let output = [];
    queryParamArray.map((el, idx) => {
      let el1 = el.split('=');
      let name = el1[0];
      let value = el1[1] || '';
      let index = idx;
      if (projectTypeList.length > 0 && projectTypeList[0].name && projectTypeList[0].value) {
        let filterProjectTypeList = projectTypeList.filter((el) => {
          return (el.name || el.value) && el.isEnabled;
        });
        if (!isEmptyValue(filterProjectTypeList)) {
          index = filterProjectTypeList[idx]?.idx || index;
        } else {
          index = idx;
        }
      }
      output.push({ name: name, value: value, id: index, idx: index, isEnabled: true });
    });
    return output;
  };

  const getStepsUpdate = () => {
    if (Object.keys(props.selectedStepData).length > 0) {
      const tempParmvalue = props.selectedStepData;
      if (tempParmvalue.queryParams && tempParmvalue.queryParams.length > 0) {
        const modifiedQueryParams = tempParmvalue.queryParams.map((items, index) => {
          return { name: items.name, value: items.value, id: index, idx: index, isEnabled: items.isEnabled };
        });
        setProjectTypeList(modifiedQueryParams);
        props.getParamValueCode(modifiedQueryParams);
        context.values.queryParams = modifiedQueryParams;
      } else {
        setProjectTypeList(queryParams);
        props.getParamValueCode(queryParams);
      }
    }
  };

  const deleteQueryParamRow = (row, index) => {
    let projectTypeList1 = queryParams;
    if (index === 0 && projectTypeList1.length === 1 && !row.isEnabled) {
      projectTypeList1 = [...INITIAL_PROJECT_TYPE_LIST];
    } else if (projectTypeList.length > 1 && !row.isEnabled) {
      projectTypeList1 = projectTypeList.filter((item, idx1) => idx1 !== index);
    }
    setProjectTypeList([...projectTypeList1]);
    context.values.queryParams = [...projectTypeList1];
    props.getParamValue([...projectTypeList]);
    props.getParamValueCode([...projectTypeList1]);
  };

  const handleArrayValues = (fieldName, fieldValue, index, e) => {
    const myvalues = projectTypeList.map((value, i) => {
      return i === index ? { ...value, [fieldName]: fieldValue } : value;
    });
    setProjectTypeList(myvalues);
    context.values.queryParams = myvalues;
    props.getParamValue(myvalues, e.target?.data, e.target?.action);
    props.getParamValueCode(myvalues);
    context.setFieldValue('currentQueryParamValue', e);
    props.handleSaveAsSteps(false);
  };

  const selectAll = () => {
    let select = !isSelectAll;
    const myvalues = projectTypeList.map((item) => ({ ...item, isEnabled: select }));
    setProjectTypeList(myvalues);
    context.values.queryParams = myvalues;
    setSelectAll(select);
    props.getParamValue(myvalues);
    props.getParamValueCode(myvalues);
    props.handleSaveAsSteps(false);
  };

  const handleAddQueryParam = (row) => {
    let idxpFisrt = projectTypeList.length;
    if (row.name && row.value) {
      const myvalues = [...projectTypeList, { name: '', value: '', id: idxpFisrt, idx: idxpFisrt, isEnabled: true }];
      context.values.queryParams = myvalues;
      setProjectTypeList(myvalues);
      props.getParamValue(myvalues);
      props.getParamValueCode(myvalues);
    }
  };

  const isChecked = isSelectAll && projectTypeList?.length > 0;
  const isIndeterminate = !isSelectAll && projectTypeList.some((rows) => rows?.isEnabled === true);
  const handleMouseOver = (i) => {
    setonMouseHoverRow(i);
  };
  const handleMouseOut = () => {
    setonMouseHoverRow(-1);
  };

  return (
    <div className="h-96">
      <table>
        <thead className="fontPoppinsSemiboldSm">
          <tr className="text-left table-row-bg query-param-table-head flex pt-1.5">
            <th className="pl-4">
              <CustomCheckBox
                checked={isChecked}
                onChange={selectAll}
                className="request-parameter-checkbox"
                indeterminate={isIndeterminate}
              />
            </th>
            <th className="w-2/5 pl-2 pt-0.5" onDragStart={props.handleDragStart}>
              Key
            </th>
            <th className="w-2/5 pl-5 pt-0.5" onDragStart={props.handleDragStart}>
              Value
            </th>
            <th className="w-1/5 pt-0.5" onDragStart={props.handleDragStart}>
              <span class="flex justify-center">Action</span>
            </th>
            <th className="w-1/5 pt-0.5" onDragStart={props.handleDragStart}>
              <span>|</span>
              <span className="ml-3">Total:{projectTypeLength}</span>
            </th>
          </tr>
        </thead>
        <tbody className="query-param-scroll relative">
          {projectTypeList.length > 0 &&
            projectTypeList.map((row, index) => (
              <>
                <tr
                  key={row.id}
                  id={`${index}`}
                  className={`flex ${onMouseHoverRow === index ? 'common-input-mouse-over' : 'common-input-mouse-out'
                    } `}
                  onMouseOver={() => handleMouseOver(index)}
                  onMouseOut={handleMouseOut}
                >
                  <td className="pl-4 mt-1 mb-1 flex items-center">
                    <CustomCheckBox
                      checked={row.isEnabled}
                      name="isEnabled"
                      className="request-parameter-checkbox"
                      onChange={(e) => handleArrayValues(e.target.name, !row.isEnabled, index, e)}
                    />
                  </td>
                  <td className="pl-2 w-2/5 mt-1 mb-1">
                    <MyInput
                      type="text"
                      className="common-input-field input"
                      placeholder="Enter Query Parameter Key or use $"
                      value={props.getUserInputValue(row.name, true)}
                      name="name"
                      onChange={(e) =>
                        handleArrayValues(
                          e.target.name,
                          props.getUserInputValue(e.target.value, false, e.target?.data, e.target?.action),
                          index,
                          e
                        )
                      }
                      variableInput={true}
                      inputValue={'input'}
                      currentValue={context.values.currentQueryParamValue}
                      {...props}
                    />
                  </td>
                  <td className="w-2/5 ml-7 mt-1 mb-1">
                    <MyInput
                      type="text"
                      className="common-input-field input"
                      placeholder="Enter Query Parameter Value or use $"
                      value={props.getUserInputValue(row.value, true)}
                      name="value"
                      onChange={(e) =>
                        handleArrayValues(
                          e.target.name,
                          props.getUserInputValue(e.target.value, false, e.target?.data, e.target?.action),
                          index,
                          e
                        )
                      }
                      variableInput={true}
                      inputValue={'input'}
                      currentValue={context.values.currentQueryParamValue}
                      {...props}
                    />
                  </td>
                  <td className="w-1/5 mt-2 mb-1" key={`${row.id}-${row.name}`}>
                    <span class={projectTypeList.length - 1 === index ? 'ml-8 pr-6' : 'ml-0 pr-3'}>
                      <CustomTooltip title={'Delete'} placement="bottom">
                        <DeleteIcon
                          className={`cursor-pointer ${!row.isEnabled && (row.name || row.value || projectTypeList?.length > 1)
                            ? 'opacity-100 text-gray-500 delete-enable-effect'
                            : 'text-gray-400 opacity-50'
                            }`}
                          alt="showmore"
                          onClick={row.isEnabled ? null : () => deleteQueryParamRow(row, index)}
                          draggable="false"
                        />
                      </CustomTooltip>
                    </span>
                    {projectTypeList.length - 1 === index && (
                      <span>
                        <CustomTooltip title="Add">
                          <AddIcon
                            className={`${row.name && row.value
                              ? 'opacity-100 plus-enable-effect text-gray-500'
                              : 'text-gray-300 p-0.5'
                              }  -ml-1 cursor-pointer`}
                            onClick={() => handleAddQueryParam(row)}
                          />
                        </CustomTooltip>
                      </span>
                    )}
                  </td>
                  <td className="w-1/5 mt-1 mb-1"></td>
                </tr>
              </>
            ))}
          <div className="h-4"></div>
        </tbody>
      </table>
    </div>
  );
}

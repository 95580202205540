import React, { useEffect, useState } from 'react';
import './step-result-inner-modal.scss';
import { HiBan, HiOutlineDownload } from 'react-icons/hi';
import { MdCheck, MdClose } from 'react-icons/md';
import { resultAccordionMsg } from '@common/ui-constants';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { styled } from '@mui/material/styles';
import { Tooltip } from '@material-ui/core';
import { tooltipClasses } from '@mui/material/Tooltip';
import { downloadFileFromCloud, downloadScreenshotVideoReq, getModuleResultTreeReq } from '@api/api_services';
import MyTable from '@src/pages/licence_management/shared/Table';
import OnShowEditor from '@pages/repository/components/program_elements/code_Editor/Js/Ace_Editor';
import MyResultTable from '@src/pages/results/components/modals/MyResultTable';
import { FiInfo } from 'react-icons/fi';
import { useAlert } from '@src/pages/common/alert_service/useAlert';
import { getIDB, setIDB } from '@src/util/localForage_idb_controller';
import FileDownload from '@assets/file_download.svg';
import { getAnalyticsTreeData, TooltipPoppin } from './../../common/util';
import { chartFontFamily } from '@common/ui-constants';

const ErrorTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
    fontFamily: chartFontFamily,
  },
});

const StepResultInnerModal = (props) => {
  const [showModal, setShowModal] = useState(true);
  let [selectedTab, setSelectedTab] = useState('Elements');
  let [selectedWebServiceSubTab, setSelectedWebServiceSubTab] = useState('Pre Requisite');
  let [selectedOutputSubTab, setSelectedOutputSubTab] = useState('Response Body');
  let [assertResultTab, setAssertResultTab] = useState('All');
  let message, page, pageCheck;

  if (props.stepResult && props.stepResult.message) {
    message = props.stepResult.message.split(' ');
    page = message.length - 3;
    pageCheck = page + 1;
  }

  const [screenType, SetscreenType] = useState('viewOnly');
  const { AlertContatiner, MyAlert } = useAlert();
  let stepResultData = {
    variablesCount: 0,
    totalElements: 0,
    passedElements: 0,
    passed: 0,
  };

  // for Error message view
  function ReadMore({ children, maxCharacterCount = 300 }) {
    const text = children;
    const [isTruncated, setIsTruncated] = useState(true);
    const resultString = text ? (isTruncated ? text.slice(0, 350) : text) : 'None';

    function toggleIsTruncated() {
      setIsTruncated(!isTruncated);
    }
    return (
      <div>
        <p className="has-text-left">
          {resultString}
          {resultString.length > 100 ? (
            <button onClick={toggleIsTruncated} className=" text-primary btn-sm float-right">
              {text ? (isTruncated ? 'view more...' : 'view less') : ''}
            </button>
          ) : (
            ''
          )}
        </p>
      </div>
    );
  }

  function ReadMoreDemo(props) {
    return (
      <div className="text-justify">
        <ReadMore>{props.errorLog?.cause}</ReadMore>
      </div>
    );
  }

  useEffect(() => {
    getAnalyticsTreeData(
      props.executionId,
      props?.stepResult?.isWebServiceRequest,
      getModuleResultTree,
      setSelectedTab
    );
  }, []);

  const textColor = (status) => {
    switch (status) {
      case 'PASS':
      case 'USED':
        return 'result-passed';
      case 'FAIL':
      case 'NOT FOUND':
        return 'result-failed';
      case 'WARNING':
        return 'result-warning';
      case 'SKIP':
      case 'NOT USED':
        return 'result-skipped';
    }
  };

  const elementStatsColor = (status) => {
    switch (status) {
      case 'PASS':
        return 'passed-elements';
      case 'FAIL':
        return 'failed-elements';
      case 'WARNING':
        return 'warned-elements';
      case 'SKIP':
        return 'skipped-elements';
      case 'SKIPPED':
        return 'skipped-elements';
      case 'FAILURE':
        return 'failed-elements';
      case 'SUCCESS':
        return 'passed-elements';
    }
  };

  const stepInputType = (type) => {
    switch (type) {
      case 'LOCAL':
        return 'Local Variable';
      case 'GLOBAL':
        return 'Global Variable';
      case 'PROJECTENVIRONMENT':
      case 'PROJECT_ENVIRONMENT':
        return 'Projectenvironment Variable';
      case 'STEPGROUPINPUT':
      case 'STEPGROUP':
        return 'Step Group Variable';
      default:
        return 'Global Variable';
    }
  };

  let statusIcon = (elementStatus) => {
    return (
      <span>
        {elementStatus === 'USED' ? (
          <span className="result-passed" title="Found">
            <MdCheck className="mt-2.5" />
          </span>
        ) : elementStatus === 'NOT_USED' ? (
          <span className="result-skipped" title="Not Used">
            <HiBan className="mt-2.5" />
          </span>
        ) : (
          <span className="result-failed" title="Not Found">
            <MdClose className="mt-2.5" />
          </span>
        )}
      </span>
    );
  };

  const getIdentifierValue = (isRecorded) => {
    if (isRecorded === 'Y') {
      return 'Recorded';
    } else if (isRecorded === 'N') {
      return 'Manual';
    } else if (isRecorded === undefined) {
      return 'Manual';
    }
    return isRecorded;
  };
  const ecolumns = React.useMemo(
    () => [
      {
        Header: 'Locator Type',
        accessor: 'name',
        width: '20%',
      },
      {
        Header: 'Value Type',
        accessor: 'type',
        width: '20%',
      },
      {
        Header: 'Locator Value',
        accessor: 'locator',
        width: '20%',
      },
      {
        Header: 'Captured',
        accessor: 'identifier',
        width: '20%',
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: '20%',
      },
    ],
    []
  );

  function elementsTab(elementDetails) {
    return (
      <div className="section-style " id="journal-scroll">
        {elementDetails && elementDetails.length ? (
          elementDetails.map((element, index) => (
            <>
              <div>
                <div
                  className={`mb-3 mt-2 mx-2 break-all unselected-tab text-left  ${elementStatus(
                    element?.locators,
                    props?.stepResult?.status
                  )}`}
                  onClick={() => {
                    displayElement(`element${index}`);
                  }}
                  id={`elementData${index}`}
                >
                  {index + 1}.{element?.name} {message[pageCheck] == 'page' ? ` in ${message[page]} page` : ''}{' '}
                  {props?.stepResult?.status !== 'SKIP' && elementStatusText(element?.locators)}
                </div>
                <div className="userRoleMapping" id="journal-scroll">
                  <MyResultTable
                    data={element?.locators.map((locator, index) => ({
                      name: (
                        <span className="text-gray-700 table-Value-truncate fontPoppinsRegularSm">{locator?.name}</span>
                      ),
                      type: (
                        <span className="text-gray-700 fontPoppinsRegularSm table-Value-truncate">{locator?.type}</span>
                      ),
                      locator: (
                        <span className="text-gray-700 fontPoppinsRegularSm ">
                          {getLocatorValue(locator, element?.dynamicLocatorArray)}
                        </span>
                      ),
                      identifier: (
                        <span className="text-gray-700 table-Value-truncate fontPoppinsRegularSm">
                          {getIdentifierValue(locator?.isRecorded)}
                        </span>
                      ),
                      status: <span className="text-gray-700 fontPoppinsRegularMd">{statusIcon(locator?.status)}</span>,
                    }))}
                    columns={ecolumns}
                    analyticsClass={true}
                  />
                </div>
              </div>
            </>
          ))
        ) : (
          <div className="column-value not-used-data">No elements are used in this step.</div>
        )}
      </div>
    );
  }

  function elementStatus(locators, status) {
    if (status !== 'SKIP') {
      let locatorStatus = locators.find((loc) => loc?.status === 'USED');
      if (locatorStatus) {
        return 'result-passed';
      } else {
        return 'result-failed';
      }
    } else {
      return 'result-skipped';
    }
  }

  function elementStatusText(locators) {
    let locatorStatus = locators.find((loc) => loc?.status === 'USED');
    if (locatorStatus) {
      return ' is found';
    } else {
      return ' is not found';
    }
  }

  function getLocatorValue(locator, dynamicLocators) {
    let locatorObj = {};
    locatorObj['locatorAdded'] = locator['value'];
    let updatedLocatorValue = locator['value'];
    let locatorWithVariableValue = locator['value'];
    if (dynamicLocators && dynamicLocators.length > 0 && locator['value'].includes('{')) {
      dynamicLocators.map((locatorData) => {
        let dynamicKey = '{' + locatorData['name'] + '}';
        if (locatorData['name'] && locator['value'].includes(dynamicKey)) {
          if (locatorData['reference']) {
            updatedLocatorValue = updatedLocatorValue.replace(dynamicKey, '/$' + locatorData['value']);
            locatorWithVariableValue = locatorWithVariableValue.replace(dynamicKey, '/' + locatorData['actualValue']);
          } else {
            updatedLocatorValue = updatedLocatorValue.replace(dynamicKey, '/' + locatorData['actualValue']);
            locatorWithVariableValue = locatorWithVariableValue.replace(dynamicKey, '/' + locatorData['actualValue']);
          }
        }
      });
      locatorObj['locatorUsingVariable'] =
        updatedLocatorValue.indexOf('/') === 0 ? updatedLocatorValue.substring(1) : updatedLocatorValue;
      locatorObj['locatorWithActualValue'] =
        locatorWithVariableValue.indexOf('/') === 0 ? locatorWithVariableValue.substring(1) : locatorWithVariableValue;
    }

    return (
      <TooltipPoppin
        title={
          <span>
            <div>{locatorObj?.locatorAdded}</div>
            <div className={textColor(locator?.status)}>{locatorObj?.locatorUsingVariable}</div>
            <div className={textColor(locator?.status)}>{locatorObj?.locatorWithActualValue}</div>
          </span>
        }
        placement="bottom-start"
      >
        <span>
          <div className="overflow-ellipsis whitespace-nowrap overflow-hidden w-16">{locatorObj?.locatorAdded}</div>
          <div className={`${textColor(locator?.status)} overflow-ellipsis whitespace-nowrap overflow-hidden w-16`}>
            {locatorObj?.locatorUsingVariable}
          </div>
          <div className={`${textColor(locator?.status)} overflow-ellipsis whitespace-nowrap overflow-hidden w-16 `}>
            {locatorObj?.locatorWithActualValue}
          </div>
        </span>
      </TooltipPoppin>
    );
  }

  function displayElement(elementId) {
    let elementExpand = document.getElementById(elementId + '-expand');
    let elementCollapse = document.getElementById(elementId + '-collapse');
    let element = document.getElementById(elementId);
    elementStyle(elementExpand, 'inline-block');
    elementStyle(elementCollapse, 'inline-block');
    elementStyle(element, 'block');
  }

  function elementStyle(element, displayStyle) {
    if (element && element.style) {
      if (element.style.display === 'none' || element.style.display == '') {
        element.style.display = displayStyle;
      } else if (element.style.display !== 'none') {
        element.style.display = 'none';
      }
    }
  }

  const vcolumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: '20%',
      },
      {
        Header: 'Type',
        accessor: 'type',
        width: '20%',
      },
      {
        Header: 'Value',
        accessor: 'value',
        width: '20%',
      },
    ],
    []
  );

  let getVariableTblData = (stepInputs, elementDetails, returnObj) => {
    let variables = [];
    if (elementDetails && Array.isArray(elementDetails)) {
      elementDetails?.forEach((element) => {
        element.locators?.forEach((value) => {
          value.dynamicLocatorArray?.forEach((vari) => {
            variables.push(vari);
          });
        });
      });

      if (stepInputs?.length) {
        stepInputs.forEach((vari) => {
          variables.unshift(vari);
        });
      }
    } else if (stepInputs?.length) {
      stepInputs.forEach((vari) => {
        variables.unshift(vari);
      });
    }
    if (returnObj) {
      let tempObj = {
        reference: returnObj?.type,
        value: returnObj?.name,
        actualValue: returnObj?.value,
      };
      variables.push(tempObj);
    }

    let tempVar = variables
      .filter((input) =>
        ['LOCAL', 'GLOBAL', 'PROJECTENVIRONMENT', 'PROJECT_ENVIRONMENT', 'STEPGROUP', 'STEPGROUPINPUT'].includes(
          input?.reference === 'STEPGROUPINPUT' ? input?.inputReference : input?.reference
        )
      )
      .filter(
        (v) =>
          !v.value.includes('WSWB') ||
          !v.value.includes('VAR') ||
          !(!v.inputReference && v.reference === 'STEPGROUPINPUT')
      );
    return tempVar;
  };

  function variablesTab(stepInputs, elementDetails, returnObj) {
    let varData = getVariableTblData(stepInputs, elementDetails, returnObj)?.map((input, index) =>
      input?.reference === 'STEPGROUPINPUT'
        ? input?.inputName || input?.inputReference
          ? {
              name: (
                <span className="text-gray-700 fontPoppinsRegularSm table-Value-truncate">
                  {input.inputName || input?.value}
                </span>
              ),
              type: (
                <span className="text-gray-700 fontPoppinsRegularSm table-Value-truncate">
                  {' '}
                  {stepInputType(input.inputReference || input?.reference)}
                </span>
              ),
              value: (
                <span className="text-gray-700 fontPoppinsRegularSm table-Value-truncate">
                  <Tooltip title={input?.actualValue} placement="bottom-start">
                    <div className="truncate">{input?.actualValue}</div>
                  </Tooltip>
                </span>
              ),
            }
          : {}
        : {
            name: (
              <span className="text-gray-700 fontPoppinsRegularSm table-Value-truncate">
                {input.inputName || input?.value}
              </span>
            ),
            type: (
              <span className="text-gray-700 fontPoppinsRegularSm table-Value-truncate">
                {' '}
                {stepInputType(input.inputReference || input?.reference)}
              </span>
            ),
            value: (
              <span className="text-gray-700 fontPoppinsRegularSm table-Value-truncate">
                <Tooltip title={input?.actualValue} placement="bottom-start">
                  <div className="truncate">{input?.actualValue}</div>
                </Tooltip>
              </span>
            ),
          }
    );

    return (
      <div className="section-style variable_table">
        {varData?.length ? (
          <MyResultTable data={varData} columns={vcolumns} analyticsClass={true} />
        ) : (
          <div className="column-value not-used-data">No variables are used in this step.</div>
        )}
      </div>
    );
  }

  function logs(stepLogs) {
    return (
      <div className="section-style">
        {stepLogs && stepLogs !== undefined ? (
          <div className="mx-4 mt-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <div className="step-columns"> Message </div>
                <div className="column-value mt-2 line-clamp-1">{stepLogs?.name}</div>
              </div>
              <div>
                {stepLogs.suggestions && <div className="step-columns"> Suggestions </div>}
                {stepLogs.suggestions &&
                  stepLogs?.suggestions.map((sugg, index) => {
                    return (
                      <div className="column-value mt-2">
                        {index + 1}. {sugg}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="mt-2">
              <div className="step-columns"> Exception </div>
              <ErrorTooltip title={stepLogs?.cause}>
                <div className="column-value mt-2 line-clamp-5">{stepLogs?.cause}</div>
              </ErrorTooltip>
            </div>
          </div>
        ) : (
          <div className="column-value not-used-data">
            {props?.stepResult?.status === 'SKIP' ? (
              <div>{resultAccordionMsg.skippedSteps}</div>
            ) : (
              <div>{resultAccordionMsg.noLogs}</div>
            )}
          </div>
        )}
      </div>
    );
  }

  function ifCheckPointIsFailed(stepInputs) {
    if (stepInputs) {
      let value = '';
      let ifFailedCheckpoint = stepInputs.find((input) => input?.name === 'ifCheckPointIsFailed');
      if (ifFailedCheckpoint && ifFailedCheckpoint['value']) {
        value = ifFailedCheckpoint.value;
        const replacedStringValue = value.replaceAll('_', ' ');
        return replacedStringValue.charAt(0).toUpperCase() + replacedStringValue.slice(1).toLowerCase();
      }
    }
  }

  function stepReturnInfo(stepReturnData) {
    if (stepReturnData) {
      return (
        <span>
          {stepInputType(stepReturnData?.type)} : {stepReturnData?.name} : {stepReturnData?.value}
        </span>
      );
    } else {
      return <span>none</span>;
    }
  }

  function download(path, type) {
    if (path) {
      return (
        <span
          className="mt-2 text-primary flex cursor-pointer"
          title={`Download ${type}`}
          onClick={() => downloadFile(path, type)}
        >
          <span> Download {type}</span>
          <HiOutlineDownload className="download-icon ml-2" />
        </span>
      );
    } else {
      return <span>Not configured</span>;
    }
  }

  function getModuleResultTree() {
    if (props.executionId) {
      getModuleResultTreeReq(props.executionId)
        .then((response) => {
          if (response.data.responseObject) {
            getTreeData(response.data);
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  }
  const getTreeData = (response) => {
    let returned_data = response?.responseObject?.executionResponses[0]?.children;

    if (response.responseObject.executionResponses[0].executionId) {
      returned_data[0]['executionId'] = response?.responseObject?.executionResponses[0]?.executionId;
    }
    if (response.responseObject.executionResponses[0].storageDetails) {
      returned_data[0]['storageDetails'] = response?.responseObject?.executionResponses[0]?.storageDetails;
    }
    setIDB('tree-data', returned_data[0]);
    localStorage.setItem('moduleId', response?.responseObject?.executionResponses[0]?.children[0]?.id);
  };

  async function downloadFile(path, type) {
    const scriptData = await getIDB('script-data');
    const treeData = await getIDB('tree-data');
    if (treeData) {
      let reqBody = {};
      if (treeData && treeData[0].storageDetails) {
        reqBody = {
          bucketName: treeData?.storageDetails
            ? treeData?.storageDetails?.inputs?.bucketName
            : treeData[0].storageDetails.inputs.bucketName,
          filePath: [path],
          type: treeData?.storageDetails ? treeData?.storageDetails?.type : treeData[0]?.storageDetails?.type,
        };
      } else {
        if (scriptData && scriptData.length && treeData?.length) {
          let obj = treeData?.find((o) => o.title === scriptData[0].selectedSystem.machineInfo.hostName);
          reqBody = {
            bucketName: obj?.storageDetails
              ? obj?.storageDetails?.inputs?.bucketName
              : obj[0].storageDetails.inputs.bucketName,
            filePath: [path],
            type: obj?.storageDetails ? obj?.storageDetails?.type : obj[0]?.storageDetails?.type,
          };
        }
      }
      reqBody['contentType'] = type;
      downloadScreenshotVideoReq(reqBody)
        .then((response) => {
          if (response.data && response.data.size > 0) {
            if (type === 'video') {
              saveFileFromBlob(response.data, 'video.zip');
            } else {
              saveFileFromBlob(response.data, 'screenshot.jpg');
            }
          } else MyAlert.danger('The screenshot cannot be downloaded. It has been deleted from the storage system');
        })
        .catch((error) => {
          MyAlert.danger('The screenshot cannot be downloaded. It has been deleted from the storage system');
          console.error('screenshot download error:', error);
        });
    }
  }

  function saveFileFromBlob(blob, filename) {
    if (blob) {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }, 0);
      }
    }
  }

  function checkIfVariablesUsed(stepInputs, elementDetails, returnObj) {
    let VarDet = getVariableTblData(stepInputs, elementDetails, returnObj);
    stepResultData.variablesCount = VarDet?.length;
    return (
      <div className="h-5 w-8 variables-count ml-1 rounded-3xl unselected-tab">
        <div className="text-center selected-tab">{VarDet?.length}</div>
      </div>
    );
  }

  function returnVariableCountTooltipMessage(stepInputs, elementDetails, returnObj) {
    let varData = getVariableTblData(stepInputs, elementDetails, returnObj);
    return <div>Variables - {varData?.length}</div>;
  }

  function elementsStatistic(elementDetails, status) {
    if (elementDetails?.length) {
      stepResultData.totalElements = elementDetails.length;
      stepResultData.passedElements = stepResultData.passedElements + 1;
    } else {
      if (elementDetails) {
        elementDetails.map((elements) => {
          let usedElements = elements?.locators.some((ele) => ele?.status && ele?.status !== 'USED');
          if (usedElements) {
            stepResultData.totalElements = elementDetails.length;
            stepResultData.passedElements = stepResultData.passedElements + 1;
          }
        });
      }
    }
    return (
      <div className={`h-5 w-8 ml-1  rounded-3xl unselected-tab ${elementStatsColor(status)}`}>
        <div className="text-center elements-stat">
          {stepResultData?.passedElements}/{stepResultData?.totalElements}
        </div>
      </div>
    );
  }

  function elementsStatsCountTooltipMessage(elementDetails, status) {
    if (elementDetails?.length) {
      stepResultData.passed = stepResultData.passedElements;
    } else {
      if (elementDetails) {
        elementDetails.map((elements) => {
          let usedElements = elements?.locators.some((ele) => ele?.status && ele?.status !== 'USED');
          if (usedElements) {
            stepResultData.passed = stepResultData.passedElements;
          }
        });
      }
    }
    return <div>Elements - {stepResultData?.passed}</div>;
  }

  function inputTab(stepInputs) {
    return (
      <>
        <div className="flex gap-3 mt-4 ml-4 ">
          <div
            className={`cursor-pointer ${
              selectedWebServiceSubTab === 'Pre Requisite' ? 'highlight-border  selected-tab' : 'unselected-tab'
            }`}
            onClick={() => setSelectedWebServiceSubTab('Pre Requisite')}
          >
            <div className="info-tab mb-1"> Pre Requisite </div>
          </div>
          <div
            className={`info-tab cursor-pointer ${
              selectedWebServiceSubTab === 'HTTP Request' ? 'highlight-border selected-tab' : 'unselected-tab'
            }`}
            onClick={() => setSelectedWebServiceSubTab('HTTP Request')}
          >
            <div className="mb-1"> HTTP Request - Actual URI </div>
          </div>
          <div
            className={` info-tab cursor-pointer ${
              selectedWebServiceSubTab === 'Request Header' ? 'highlight-border  selected-tab' : 'unselected-tab'
            }`}
            onClick={() => setSelectedWebServiceSubTab('Request Header')}
          >
            <div className="mb-1 "> Request Header </div>
          </div>
          <div
            className={` info-tab cursor-pointer ${
              selectedWebServiceSubTab === 'Request Body' ? 'highlight-border  selected-tab' : 'unselected-tab'
            }`}
            onClick={() => setSelectedWebServiceSubTab('Request Body')}
          >
            <div className="mb-1"> Request Body </div>
          </div>
        </div>
        <div className="tab-border mx-4"></div>
        <div>
          {selectedWebServiceSubTab === 'Pre Requisite' && (
            <div className="px-15px">{preRequisiteTab(props?.stepResult?.elementDetails)}</div>
          )}
          {selectedWebServiceSubTab === 'HTTP Request' && (
            <div className="px-15px">{httpRequestTab(props?.stepResult?.errorLog)}</div>
          )}
          {selectedWebServiceSubTab === 'Request Header' && (
            <div className="px-15px">{requestHeaderTab(props?.stepResult?.errorLog)}</div>
          )}
          {selectedWebServiceSubTab === 'Request Body' && (
            <section className="px-15px">{requestBodyTab(props?.stepResult?.stepInputs)}</section>
          )}
        </div>
      </>
    );
  }
  function preRequisiteTab(stepInputs) {
    return (
      <>
        <div className="mt-2">
          <OnShowEditor
            codeviewValue={props?.stepResult?.webServiceRequest?.preStep?.program?.code}
            screenType={screenType}
            analyticsClass={true}
          />
        </div>
      </>
    );
  }

  function responseBody(stepInputs) {
    return (
      <>
        <div className="mt-2">
          <OnShowEditor
            codeviewValue={props?.stepResult?.webserviceResponse?.responseBody?.replace(/,/gi, ',\n')}
            screenType={screenType}
            analyticsClass={true}
          />
        </div>
      </>
    );
  }

  function responseHeader(stepInputs) {
    return (
      <>
        <div className="ml-4 mr-5">
          <MyTable data={responseHeaderData} columns={columns} analyticsClass={true} />
        </div>
      </>
    );
  }

  function assertResults(stepInputs) {
    const tabResult = props?.stepResult?.webserviceResponse?.responseAssertionResults;
    const tabResultPost = props?.stepResult?.webserviceResponse?.postStepResult?.assertionResult?.assertionResults;

    var AllResponse = tabResult?.map((value) => {
      return (
        <div>
          <div className="flex mt-2 ml-2 items-center">
            <div className={`h-5 mt-1 ml-1 rounded-3xl unselected-tab ${elementStatsColor(`${value.state}`)}`}>
              <div className="text-center elements-stat fontPoppinsRegularSm px-1">{value.state}</div>
            </div>
            <div className="ml-2 fontPoppinsRegularSm">{value.assertionRequestDto.group}</div>
            <TooltipPoppin title={value.resultMessage} placement="right-end">
              <div className="ml-2">
                <FiInfo height="10px" width="10px" />
              </div>
            </TooltipPoppin>
          </div>
        </div>
      );
    });
    var AllResponsePost = tabResultPost?.map((value) => {
      return (
        <div>
          <div className="flex mt-2 ml-2 items-center">
            <div className={`h-5 mt-1 ml-1 rounded-3xl unselected-tab ${elementStatsColor(`${value.state}`)}`}>
              <div className="text-center elements-stat px-1 fontPoppinsRegularSm">{value.state}</div>
            </div>
            <div className="ml-2 fontPoppinsRegularSm">{value.assertionRequestDto.group}</div>
            <TooltipPoppin title={value.resultMessage} placement="right-end">
              <div className="ml-2">
                <FiInfo />
              </div>
            </TooltipPoppin>
          </div>
        </div>
      );
    });
    return (
      <>
        <div className="state-col-wrp mt-3 ml-4 mb-3 assertBackgroung fontPoppinsRegularSm ">
          <button
            className={`${assertResultTab === 'All' ? 'state-active' : 'state-button'} fontSize tabColour`}
            onClick={() => setAssertResultTab('All')}
          >
            All
          </button>
          <button
            className={`${assertResultTab === 'Passed' ? 'state-active' : 'state-button'} py-0 px-1 rounded-l fontSize`}
            onClick={() => setAssertResultTab('Passed')}
          >
            Passed
          </button>
          <button
            className={`${assertResultTab === 'Failed' ? 'state-active' : 'state-button'} py-0 px-1 rounded-l fontSize`}
            onClick={() => setAssertResultTab('Failed')}
          >
            Failed
          </button>
          <button
            className={`${
              assertResultTab === 'Skipped' ? 'state-active' : 'state-button'
            } py-0 px-1 rounded-l fontSize`}
            onClick={() => setAssertResultTab('SKIPPED')}
          >
            Skipped
          </button>
        </div>
        <div>
          {assertResultTab ? (
            assertResultTab === 'All' ? (
              <div>
                {AllResponse}
                {AllResponsePost}
              </div>
            ) : assertResultTab === 'Passed' ? (
              <div>
                {tabResult
                  ?.filter((v) => v.state == 'SUCCESS')
                  .map((value) => {
                    return (
                      <div>
                        <div className="flex mt-2 ml-2 items-center">
                          <div
                            className={`h-5 mt-1 ml-1 rounded-3xl unselected-tab ${elementStatsColor(
                              `${value.state}`
                            )}`}
                          >
                            <div className="text-center elements-stat px-1 fontPoppinsRegularSm">{value.state}</div>
                          </div>
                          <div className="ml-2 fontPoppinsRegularSm">{value.assertionRequestDto.group}</div>
                          <TooltipPoppin title={value.resultMessage} placement="right-end">
                            <div className="ml-2">
                              <FiInfo />
                            </div>
                          </TooltipPoppin>
                        </div>
                      </div>
                    );
                  })}
                {tabResultPost
                  ?.filter((v) => v.state == 'SUCCESS')
                  .map((value) => {
                    return (
                      <div>
                        <div className="flex mt-2 ml-2 items-center">
                          <div
                            className={`h-5 mt-1 ml-1 rounded-3xl unselected-tab ${elementStatsColor(
                              `${value.state}`
                            )}`}
                          >
                            <div className="text-center elements-stat px-1 fontPoppinsRegularSm">{value.state}</div>
                          </div>
                          <div className="ml-2 fontPoppinsRegularSm">{value.assertionRequestDto.group}</div>
                          <TooltipPoppin title={value.resultMessage} placement="right-end">
                            <div className="ml-2">
                              <FiInfo />
                            </div>
                          </TooltipPoppin>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : assertResultTab === 'Failed' ? (
              <div>
                {tabResult
                  ?.filter((v) => v.state === 'FAILURE')
                  .map((value) => {
                    return (
                      <div>
                        <div className="flex mt-2 ml-2 items-center">
                          <div
                            className={`h-5 mt-1 ml-1 rounded-3xl unselected-tab ${elementStatsColor(
                              `${value.state}`
                            )}`}
                          >
                            <div className="text-center elements-stat px-1 fontPoppinsRegularSm">{value.state}</div>
                          </div>
                          <div className="ml-2 fontPoppinsRegularSm">{value.assertionRequestDto.group}</div>
                          <TooltipPoppin title={value.resultMessage} placement="right-end">
                            <div className="ml-2">
                              <FiInfo />
                            </div>
                          </TooltipPoppin>
                        </div>
                      </div>
                    );
                  })}
                {tabResultPost
                  ?.filter((v) => v.state == 'FAILURE')
                  .map((value) => {
                    return (
                      <div>
                        <div className="flex mt-2 ml-2 items-center">
                          <div
                            className={`h-5 mt-1  ml-1 rounded-3xl unselected-tab ${elementStatsColor(
                              `${value.state}`
                            )}`}
                          >
                            <div className="text-center elements-stat px-1 fontPoppinsRegularSm">{value.state}</div>
                          </div>
                          <div className="ml-2 fontPoppinsRegularSm">{value.assertionRequestDto.group}</div>
                          <TooltipPoppin title={value.resultMessage} placement="right-end">
                            <div className="ml-2">
                              <FiInfo />
                            </div>
                          </TooltipPoppin>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : assertResultTab === 'SKIPPED' ? (
              <div>
                {tabResult
                  ?.filter((v) => v.state == 'SKIPPED')
                  .map((value) => {
                    return (
                      <div>
                        <div className="flex mt-2 ml-2 items-center">
                          <div
                            className={`h-5 mt-1  ml-1 rounded-3xl unselected-tab ${elementStatsColor(
                              `${value.state}`
                            )}`}
                          >
                            <div className="text-center elements-stat px-1 fontPoppinsRegularSm">{value.state}</div>
                          </div>
                          <div className="ml-2 fontPoppinsRegularSm">{value.assertionRequestDto.group}</div>
                          <TooltipPoppin title={value.resultMessage} placement="right-end">
                            <div className="ml-2">
                              <FiInfo />
                            </div>
                          </TooltipPoppin>
                        </div>
                      </div>
                    );
                  })}
                {tabResultPost
                  ?.filter((v) => v.state == 'SKIPPED')
                  .map((value) => {
                    return (
                      <div>
                        <div className="flex mt-2 ml-2 items-center">
                          <div
                            className={`h-5 mt-1 ml-1 rounded-3xl unselected-tab ${elementStatsColor(
                              `${value.state}`
                            )}`}
                          >
                            <div className="text-center elements-stat px-1 fontPoppinsRegularSm">{value.state}</div>
                          </div>
                          <div className="ml-2 fontPoppinsRegularSm">{value.assertionRequestDto.group}</div>
                          <TooltipPoppin title={value.resultMessage} placement="right-end">
                            <div className="ml-2">
                              <FiInfo />
                            </div>
                          </TooltipPoppin>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }

  var returnValue = props?.stepResult?.webserviceResponse?.updatedVariables
    ? Object.keys(props?.stepResult?.webserviceResponse?.updatedVariables)
    : [];
  const returndata = React.useMemo(
    () =>
      returnValue.map((proVal) => ({
        name: (
          <span className="text-blue-700 cursor-pointer table-Value-truncate fontPoppinsRegularSm">
            {props?.stepResult?.webserviceResponse?.updatedVariables[proVal].group}
          </span>
        ),
        variableType: (
          <span className="text-gray-700 table-Value-truncate fontPoppinsRegularSm">
            {props?.stepResult?.webserviceResponse?.updatedVariables[proVal].variable.type}
          </span>
        ),
        variableName: (
          <span className="text-gray-700 table-Value-truncate fontPoppinsRegularSm">
            {props?.stepResult?.webserviceResponse?.updatedVariables[proVal].variable.name}
          </span>
        ),
        returnWebValue: (
          <span className="text-gray-700 fontPoppinsRegularSm table-Value-truncate">
            <TooltipPoppin
              title={props?.stepResult?.webserviceResponse?.updatedVariables[proVal]?.variable?.value}
              placement="bottom-start"
            >
              <div className="truncate">
                {props?.stepResult?.webserviceResponse?.updatedVariables[proVal]?.variable?.value}
              </div>
            </TooltipPoppin>
          </span>
        ),
      })),
    []
  );

  const returnColumns = React.useMemo(
    () => [
      {
        Header: 'Lable Name',
        accessor: 'name',
        width: '20%',
      },
      {
        Header: 'Variable Type',
        accessor: 'variableType',
        width: '20%',
      },
      {
        Header: 'Variable Name',
        accessor: 'variableName',
        width: '20%',
      },
      {
        Header: 'Return Value',
        accessor: 'returnWebValue',
        width: '20%',
      },
    ],
    []
  );
  function returnData(stepInputs) {
    return (
      <>
        <div className="ml-4 mr-5">
          <MyTable data={returndata} columns={returnColumns} analyticsClass={true} />
        </div>
      </>
    );
  }

  function httpRequestTab(stepInputs) {
    return (
      <>
        <div className="mt-3 ml-3 fontPoppinsRegularSm">{props?.stepResult?.webServiceRequest?.url}</div>
      </>
    );
  }

  const responseHeaderData = React.useMemo(
    () =>
      (props?.stepResult?.webserviceResponse?.headers ? props?.stepResult?.webserviceResponse?.headers : []).map(
        (proVal) => ({
          name: (
            <span className="text-blue-700 table-Value-truncate cursor-pointer fontPoppinsRegularSm">
              {proVal.name}
            </span>
          ),
          value: (
            <span className="text-gray-700 table-Value-truncate fontPoppinsRegularSm inline-block break-all w-80">
              {proVal.value}
            </span>
          ),
        })
      ),
    []
  );

  const requestHeaderData = React.useMemo(
    () =>
      (props?.stepResult?.webServiceRequest?.headers ? props?.stepResult?.webServiceRequest?.headers : []).map(
        (proVal) => ({
          name: <span className="text-blue-700 cursor-pointer fontPoppinsRegularSm">{proVal.name}</span>,
          value: <span className="text-gray-700 table-Value-truncate fontPoppinsRegularSm">{proVal.value}</span>,
        })
      ),
    []
  );
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: '20%',
      },
      {
        Header: 'Value',
        accessor: 'value',
        width: '20%',
      },
    ],
    []
  );
  function requestHeaderTab(stepInputs) {
    return (
      <>
        <div className="ml-4 mr-5">
          <MyTable data={requestHeaderData} columns={columns} analyticsClass={true} />
        </div>
      </>
    );
  }

  let keyName = props?.stepResult?.webServiceRequest?.fileParamMap
    ? Object.keys(props?.stepResult?.webServiceRequest?.fileParamMap)[0]
    : '';
  const requestBodyData = React.useMemo(
    () =>
      (props?.stepResult?.webServiceRequest?.fileParamMap[keyName]
        ? props?.stepResult?.webServiceRequest?.fileParamMap[keyName]
        : []
      ).map((proVal) => ({
        keytype: <span className="text-gray-700 table-Value-truncate fontPoppinsRegularSm"> File</span>,
        key: <span className="text-gray-700 table-Value-truncate fontPoppinsRegularSm">{keyName}</span>,
        value: (
          <span className="text-gray- table-Value-truncate fontPoppinsRegularSm">
            {proVal.name}
            <span
              onClick={() =>
                downloadFileFromCloud(proVal.id).then((results) => {
                  saveFileFromBlob(results.data, proVal.name);
                })
              }
            >
              <img className="inline text-lg cursor-pointer" src={FileDownload} alt="" height="20px" width="20px" />
            </span>
          </span>
        ),
      })),
    []
  );
  const requestBodyColumns = React.useMemo(
    () => [
      {
        Header: 'Key Type',
        accessor: 'keytype',
        width: '20%',
      },
      {
        Header: 'Key',
        accessor: 'key',
        width: '20%',
      },
      {
        Header: 'Value',
        accessor: 'value',
        width: '20%',
      },
    ],
    []
  );
  function requestBodyTab(stepInputs) {
    return (
      <>
        {props.stepResult.webServiceRequest.fileParamMap.file &&
        props.stepResult.webServiceRequest.fileParamMap.file.length >= 1 ? (
          <>
            <div className="ml-5 mr-5">
              <MyTable data={requestBodyData} columns={requestBodyColumns} analyticsClass={true} />
            </div>
          </>
        ) : (
          <>
            <div className="mt-1">
              <OnShowEditor
                codeviewValue={props.stepResult.webServiceRequest.requestBody}
                screenType={screenType}
                analyticsClass={true}
              />
            </div>
          </>
        )}
      </>
    );
  }

  function outputTab(stepInputs) {
    return (
      <>
        <div className="flex gap-3 mt-4 ml-4 w-3/4">
          <div
            className={`flex cursor-pointer ${
              selectedOutputSubTab === 'Response Body' ? 'highlight-border selected-tab' : 'unselected-tab'
            }`}
            onClick={() => setSelectedOutputSubTab('Response Body')}
          >
            <div className="info-tab mb-1"> Response Body </div>
          </div>
          <div
            className={`flex  cursor-pointer ${
              selectedOutputSubTab === 'Response Header' ? 'highlight-border  selected-tab' : 'unselected-tab'
            }`}
            onClick={() => setSelectedOutputSubTab('Response Header')}
          >
            <div className="info-tab mb-1"> Response Header </div>
          </div>
          <div
            className={`flex cursor-pointer ${
              selectedOutputSubTab === 'Assert Results' ? 'highlight-border  selected-tab' : 'unselected-tab'
            }`}
            onClick={() => setSelectedOutputSubTab('Assert Results')}
          >
            <div className=" info-tab mb-1"> Assert Results </div>
          </div>
          <div
            className={`flex  cursor-pointer ${
              selectedOutputSubTab === 'Return Data' ? 'highlight-border  selected-tab' : 'unselected-tab'
            }`}
            onClick={() => setSelectedOutputSubTab('Return Data')}
          >
            <div className="info-tab mb-1"> Return Data </div>
          </div>
        </div>
        <div className="tab-border mx-4"></div>
        <div>
          {selectedOutputSubTab === 'Response Body' && (
            <div className="px-15px">{responseBody(props?.stepResult?.elementDetails)}</div>
          )}
          {selectedOutputSubTab === 'Response Header' && (
            <div className="px-15px">{responseHeader(props?.stepResult?.errorLog)}</div>
          )}
          {selectedOutputSubTab === 'Assert Results' && (
            <div className="px-15px">{assertResults(props?.stepResult?.errorLog)}</div>
          )}
          {selectedOutputSubTab === 'Return Data' && (
            <div className="px-15px">{returnData(props?.stepResult?.stepInputs)}</div>
          )}
        </div>
      </>
    );
  }

  let tooltipMessage;
  if (message) {
    tooltipMessage =
      message[pageCheck] === 'page'
        ? ` in ${message[page]} page`
        : ''
          ? message[pageCheck] === 'page'
            ? ` in ${message[page]} page`
            : ''
          : '';
  }
  return (
    <div className="step-result-inner-modal-analytics">
      <div className={props?.OpenExeCompInnerModalStyle ? 'execomparison-step-modal-size' : 'step-modal-size'}>
        <div className="modal-header">
          <div className="flex flex-1 justify-between items-center">
            <div className="fontPoppinsSemiboldSm rs-blue">Step Result</div>
            <span className="step-alert-position-style">
              <AlertContatiner></AlertContatiner>
            </span>
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                props.closeStepModal(false);
                setShowModal(false);
              }}
            >
              <CloseIcon className="close-button" />
            </IconButton>
          </div>
        </div>
        <div className="modal-body" id="journal-scroll">
          <div>
            <div className="grid ">
              <div className="col-span-2 sm:col-span-2 md:col-span-2">
                <div className="">
                  <div className="">
                    <div className="grid grid-cols-3  gap-4" id="stepData">
                      {props?.stepResult?.name && (
                        <div>
                          <div className="step-columns"> Description </div>
                          <TooltipPoppin
                            title={`${props?.stepResult?.name} ${tooltipMessage}`}
                            placement="bottom-start"
                          >
                            <div className="column-value" id="wrapId">
                              {props?.stepResult?.name}
                              {message[pageCheck] == 'page' ? ` in ${message[page]} page` : ''}
                            </div>
                          </TooltipPoppin>
                        </div>
                      )}
                      {props?.stepResult?.message && (
                        <div>
                          <div className="step-columns"> Result </div>
                          <div className={` ${textColor(props?.stepResult?.status)}`} id="wrapId">
                            {props?.stepResult?.status !== 'SKIP' ? (
                              <TooltipPoppin title={props?.stepResult?.message} placement="bottom-start">
                                <span className="fontPoppinsMediumSm column-value-err">
                                  {props?.stepResult?.message}
                                </span>
                              </TooltipPoppin>
                            ) : (
                              <div> Skipped </div>
                            )}
                          </div>
                        </div>
                      )}
                      {props?.stepResult?.status !== 'SKIP' && (
                        <div>
                          <div className="step-columns"> Return </div>
                          <div className="column-value mt-2 truncate">
                            {props?.stepResult?.stepReturn ? (
                              <TooltipPoppin title={props?.stepResult?.stepReturn?.value} placement="bottom-start">
                                {stepReturnInfo(props?.stepResult?.stepReturn)}
                              </TooltipPoppin>
                            ) : (
                              stepReturnInfo(props?.stepResult?.stepReturn)
                            )}
                          </div>
                        </div>
                      )}
                      {props?.stepResult?.status !== 'SKIP' && props?.stepResult?.status !== 'PASS' && (
                        <div>
                          <div className="step-columns"> If Failed </div>
                          <TooltipPoppin
                            title={ifCheckPointIsFailed(props?.stepResult?.stepInputs)}
                            placement="bottom-start"
                          >
                            <div className="column-value mt-2 text-overflow-ellipsis">
                              {ifCheckPointIsFailed(props?.stepResult?.stepInputs)}
                            </div>
                          </TooltipPoppin>
                        </div>
                      )}
                      {props?.stepResult?.status !== 'SKIP' && (
                        <div>
                          <div className="step-columns"> Screenshot </div>
                          <div className="column-value mt-2 w-40">
                            {props?.stepResult?.screenshotPath ? (
                              <span
                                className="mt-2 text-primary flex cursor-pointer"
                                title="Download Screenshot"
                                onClick={() => downloadFile(props?.stepResult?.screenshotPath, 'Screenshot')}
                              >
                                <span> Download Screenshot</span>
                                <HiOutlineDownload className="download-icon ml-2" />
                              </span>
                            ) : (
                              <span>Not configured</span>
                            )}
                          </div>
                        </div>
                      )}
                      {/* COMMENTED FOR FUTURE REQUIRMENTS */}
                      {/* {props?.stepResult?.status !== "SKIP" && (
                        <div>
                          <div className="step-columns"> Video </div>
                          <div className="column-value mt-2">
                            {props?.stepResult?.videoPath ? (
                              <span
                                className="mt-2 text-primary flex cursor-pointer"
                                title="Download Video"
                                onClick={() =>
                                  downloadFile(
                                    props?.stepResult?.videoPath,
                                    "Video"
                                  )
                                }
                              >
                                <small> Download Video</small>{" "}
                                <HiOutlineDownload className="download-icon ml-2" />
                              </span>
                            ) : (
                              <span>Not configured</span>
                            )}
                          </div>
                        </div>
                      )} */}
                    </div>
                    <div className="grid grid-cols-1 mt-4 px-4 gap-4" id="stepData">
                      {/* Added Error */}
                      {props?.stepResult?.status !== 'PASS' && (
                        <div>
                          <div className="step-columns"> Error</div>
                          <div className="column-value column-value-errors content-error mt-2 card card-body break-words">
                            <ReadMoreDemo errorLog={props?.stepResult?.errorLog} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex mt-4 gap-3 ml-4">
                    {props?.stepResult?.isWebServiceRequest ? (
                      ''
                    ) : (
                      <div
                        className={`flex cursor-pointer ${
                          selectedTab === 'Elements' ? 'highlight-border  selected-tab' : ' unselected-tab'
                        }`}
                        onClick={() => setSelectedTab('Elements')}
                      >
                        <div className="info-tab mb-1"> Elements </div>

                        <TooltipPoppin
                          title={elementsStatsCountTooltipMessage(props?.stepResult?.stepInputs)}
                          placement="bottom-start"
                        >
                          <div>
                            <div>{elementsStatistic(props?.stepResult?.elementDetails, props?.stepResult?.status)}</div>
                          </div>
                        </TooltipPoppin>
                      </div>
                    )}
                    {props?.stepResult?.isWebServiceRequest ? (
                      <>
                        <div
                          className={`flex info-tab cursor-pointer ${
                            selectedTab === 'Input' ? 'highlight-border  selected-tab' : ' unselected-tab'
                          }`}
                          onClick={() => setSelectedTab('Input')}
                        >
                          <div className="mb-1"> Input </div>
                        </div>
                        <div
                          className={`flex info-tab cursor-pointer ${
                            selectedTab === 'Output' ? 'highlight-border  selected-tab' : 'unselected-tab'
                          }`}
                          onClick={() => setSelectedTab('Output')}
                        >
                          <div className="mb-1"> Output </div>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                    <div
                      className={`flex info-tab cursor-pointer ${
                        selectedTab === 'Variables' ? 'highlight-border  selected-tab' : ' unselected-tab'
                      }`}
                      onClick={() => setSelectedTab('Variables')}
                    >
                      <div className="mb-1"> Variables </div>
                      <TooltipPoppin
                        title={returnVariableCountTooltipMessage(
                          props?.stepResult?.stepInputs,
                          props?.stepResult?.stepReturn
                        )}
                        placement="bottom-start"
                      >
                        <div>
                          <div>
                            {checkIfVariablesUsed(
                              props?.stepResult?.stepInputs,
                              props?.stepResult?.elementDetails,
                              props?.stepResult?.stepReturn
                            )}
                          </div>
                        </div>
                      </TooltipPoppin>
                    </div>
                    {props?.stepResult?.status !== 'PASS' && (
                      <div
                        className={`info-tab cursor-pointer ${
                          selectedTab === 'Logs' ? 'selected-tab highlight-border w-8' : 'unselected-tab'
                        }`}
                        onClick={() => setSelectedTab('Logs')}
                      >
                        <div className="mb-1"> Logs </div>
                      </div>
                    )}
                  </div>
                  <div className="tab-border mx-4"></div>
                  <div>
                    {selectedTab === 'Elements' && <section>{elementsTab(props?.stepResult?.elementDetails)}</section>}
                    {selectedTab === 'Input' && <div>{inputTab(props?.stepResult?.errorLog)}</div>}
                    {selectedTab === 'Output' && <div>{outputTab(props?.stepResult?.errorLog)}</div>}
                    {selectedTab === 'Variables' && (
                      <section>
                        {variablesTab(
                          props?.stepResult?.stepInputs,
                          props?.stepResult?.elementDetails,
                          props?.stepResult?.stepReturn
                        )}
                      </section>
                    )}
                    {selectedTab === 'Logs' && <div>{logs(props?.stepResult?.errorLog)}</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepResultInnerModal;

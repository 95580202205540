import React, { Fragment, useState, useContext } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useTable, usePagination, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy } from 'react-table';
import matchSorter from 'match-sorter';
import '../execution.css';
import { MdEdit } from 'react-icons/md';

import { AiOutlineDelete, AiOutlinePlayCircle } from 'react-icons/ai';
import { FaStopCircle } from 'react-icons/fa';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';
import { useHistory } from 'react-router-dom';
import { Streetview } from '@material-ui/icons';
import ContextMenu from '../../common/context_menu';
// import CreateEditSuite from './create-edit-suit';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import CreateEditSuiteOfSuites from './create-edit-suite-of-suites';
import DetailsLandingPage from './details-landing-page';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  deleteSuiteOfSuiteReq,
  executeSuiteOfSuitePostReq,
  getAllSuiteOfSuiteReq,
  updateSuiteOfSuiteReq,
  schedulePostReq,
  getSingleClientSystemReq,
} from '../../../api/api_services';
import { useAlert } from '../../common/alert_service/useAlert';
import { Tooltip } from '@mui/material';
import Input from '@material-ui/core/Input';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import Stack from '@mui/material/Stack';
import { Sync } from '@material-ui/icons';
import MachineDetails from '@src/pages/test-development/execution-dashboard/modals/machine-details';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { CommonLoader } from '@src/pages/common/common-loader';

let stateValue = 'Private';
let stateIndex;

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SuiteOfSuite = ({ MyAlert, ...props }) => {
  const { isEditable, isProjectOpen } = React.useMemo(
    () => ({
      isProjectOpen: window.permission?.isProjectOpen(),
      isEditable: window.permission?.isEditAccess('execution'),
    }),
    []
  );
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [isLoading, setIsLoading] = useState(true);
  var today = new Date();
  let scheduleRepeatArray = ['Once', 'Daily', 'Weekly', 'Montly'];
  let search = window.location.search;
  let urlSuiteId = new URLSearchParams(search).get('suiteId');
  console.log('urlSuiteId', urlSuiteId);
  const classes = useStyles();
  const [suiteOfSuiteArray, setSuiteOfSuiteArray] = React.useState([]);
  const [operation, setOperation] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [selectedName, setDeleteName] = React.useState();
  const history = useHistory();
  const tab = window.location.pathname.split('/')[3];
  const [age, setAge] = React.useState('');
  const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
  const { AlertContatiner, MyAlert: childalert } = useAlert();
  const [machineNames, setMachineNames] = React.useState('');
  const [validationMinTime, setValidationMinTime] = React.useState();
  const [expiryDate, setExpiryDate] = React.useState(new Date());
  const [sheduledDate, setSheduledDate] = React.useState(new Date());
  const [expiryTime, setExpiryTime] = React.useState(new Date());
  const [scheduleTime, setScheduleTime] = React.useState(new Date());
  const [pageIndexValue, setPageIndexValue] = React.useState(0);
  const [minMinute, setMinMinute] = React.useState();
  const [minHour, setMinHour] = React.useState();
  const [scheduleBtnDisable, setScheduleBtnDisable] = React.useState(false);
  const [expireBtnDisable, setExpireBtnDisable] = React.useState(false);
  const [repeatData, setRepeat] = React.useState(scheduleRepeatArray[0]);
  const [selectedSystem, setSelectedSystem] = React.useState();

  console.log(`tabInside Suite of suite`, tab);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [suiteofSuiteObj, setSuiteofSuiteObj] = React.useState();

  const formik = useFormik({
    initialValues: {
      Name: '',
    },

    validationSchema: Yup.object({
      Name: Yup.string()
        .min(3, 'Minimum 3 characters')
        .max(25, 'Maximum 25 characters')
        .required('Required!')
        .matches(/^[0-9a-zA-Z ]*$/, 'Must Contain alphanumeric characters'),
    }),
    onSubmit: (setVal) => {
      getTimeForValidation();
      scheduleExecutionFun(selectedName);
    },
  });

  function createAlertFun(type, msg) {
    if (type === 'success') {
      MyAlert.success(msg);
    } else if (type === 'danger') {
      if (MyAlert) {
        MyAlert.danger(msg);
      }
    } else if (type === 'info') {
      if (MyAlert) {
        MyAlert.info(msg);
      }
    }
  }

  function getTimeForValidation() {
    let d = new Date();
    let time = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
    setValidationMinTime(time);
    // let xyz =d.getMinutes()
    setMinHour(d.getHours());
    setMinMinute(d.getMinutes());
  }

  function checkExpiryValidation() {
    setTimeout(() => {
      let expiryAreaInvalid = document?.getElementById('expiryId')?.getAttribute('aria-invalid');
      console.log('expiryAreaInvalid', expiryAreaInvalid);
      if (expiryAreaInvalid === 'true') {
        setExpireBtnDisable(true);
      } else {
        setExpireBtnDisable(false);
      }
    }, 100);
  }

  function setFunctionality(value, option) {
    console.log(`value`, value);
    setDeleteName(value);
    if (option && option == 'Delete') {
      setOpen(true);
    } else if (option && option == 'Schedule') {
      formik.resetForm();
      setExpiryTime(new Date());
      setScheduleTime(new Date());
      setExpiryDate(new Date());
      setSheduledDate(new Date());
      setTimeout(() => {
        checkExpiryValidation();
        checkScheduleValidation();
      }, 100);

      let machineName = '';
      if (value && value.machines && value.machines.selectedMachines) {
        value.machines.selectedMachines.map((obj) => {
          {
            machineName =
              machineName +
              obj?.machineInfo.hostName +
              '' +
              `${machineName < value.machines.selectedMachines.length - 1 ? ',' : ' '}`;
          }
        });
        setMachineNames(machineName);
      }
      setShowModal(true);
      getTimeForValidation();
    }
  }

  let handelDlt = () => {
    console.log(selectedName);
    deleteSuiteOfSuiteReq(selectedName.id).then((res) => {
      if (res) {
        getAllSuiteOfSuite();
        setOpen(false);
        createAlertFun('success', `${selectedName.name} suite of suite deleted successfully.`);
      }
    });
  };

  function createSuite(operation) {
    setOperation(operation);
    if (operation === 'create') {
      history.push('/execution/suiteofsuite/create-suite-of-suites');
    } else {
      history.push('/execution/suiteofsuite/edit-suite-of-suites');
    }
  }

  function checkScheduleValidation() {
    setTimeout(() => {
      let scheduleAreaInvalid = document?.getElementById('scheduleId')?.getAttribute('aria-invalid');
      console.log('scheduleAreaInvalid', scheduleAreaInvalid);
      if (scheduleAreaInvalid === 'true') {
        setScheduleBtnDisable(true);
      } else {
        setScheduleBtnDisable(false);
      }
    }, 100);
  }

  function navigateToExecutionDetails(selectedObj) {
    console.log(`selectedName`, selectedObj);
    history.push(`/execution/suiteofsuite/${selectedObj.name}/details?suiteId=${selectedObj?.id}`);
  }

  function getAllSuiteOfSuite() {
    startOrResumeProgress({
      stopAt: 90,
    });
    setIsLoading(true);
    getAllSuiteOfSuiteReq()
      .then((results) => {
        stopProgress();
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 10,
        });
        const response = results.data.responseObject;
        if (response) {
          response.map((res, i) => {
            stateIndex = i;
            res.Actions = [];

            res.Actions.push(
              <span className="flex flex-row">
                {isProjectOpen && (
                  <Tooltip title="Execution" placement="top">
                    <img
                      src="\assets\execution_logos\execution.svg"
                      className="project-action cursor-hand ml-2 mr-3"
                      height="17px"
                      width="17px"
                      onClick={() => executeSuiteOfSuite(res)}
                    ></img>
                  </Tooltip>
                )}
                &nbsp;
                {/* <AiOutlinePlayCircle className="project-action icon-blue-style float-left execute-icon-style" onClick={() => executeSuite(res)} /> */}
                <Tooltip title="Edit" placement="top">
                  <img
                    src="\assets\execution_logos\edit.svg"
                    className="project-action  cursor-hand mr-3"
                    height="17px"
                    width="17px"
                    onClick={() => {
                      setSuiteofSuiteObj(res);
                      createSuite('update', res);
                    }}
                  ></img>
                </Tooltip>
                &nbsp;
                {/* <Tooltip title="Impact List" placement="top" >
                                <img src="\assets\execution_logos\impactlist.png " height="20" width="20" className="project-action cursor-hand mr-3"></img>
                            </Tooltip>&nbsp; */}
                <div className="project-action cursor-hand">
                  <ContextMenu
                    contextMenuData={contextMenuDataWithDelete}
                    func={(e) => {
                      setFunctionality(res, e.target.textContent);
                    }}
                  />
                </div>
              </span>
              // <span className="flex flex-row">
              //     <AiOutlinePlayCircle className="project-action icon-blue-style float-left execute-icon-style" />
              //     < MdEdit className="float-left project-action edit-style cursor-pointer icon-blue-style " onClick={() => { createSuite('update'); setSuiteofSuiteObj(res) }} />
              //     <div className="project-action" ><ContextMenu contextMenuData={contextMenuDataWithDelete}
              //         func={(e) => { setFunctionality(res, e.target.textContent) }} /></div>
              // </span>
            );

            if (res?.createdByUname === 'Completed') {
              // res.executionDashboard.suiteOfSuites.selectedMachines['status']=res?.executionDashboard?.resultStatus
              res.selectedMachines['createdByUname'] = res?.createdByUname;
            } else {
              // res.executionDashboard.suiteOfSuites.selectedMachines['status']=res?.executionDashboard?.executionStatus
              res.selectedMachines['createdByUname'] = res?.createdByUname;
            }
            res.machineHostName = res?.selectedMachines;
            console.log('res', res);
            // }

            res.slNo = i + 1;

            res.state = (
              <div className="border flex flex-row Pub-Pvt-Button-width">
                {console.log(`stateValue`, stateValue, stateIndex)}

                <button
                  value="Private"
                  id={`Private` + i}
                  onClick={() => setStateValue('Private', i, res)}
                  className={`${res.access === 'Private' ? 'selected-state-style' : ''} p-1 m-1 priv-pub-font-style-button`}
                >
                  Private
                </button>
                <button
                  value="Public"
                  id={`Public` + i}
                  onClick={() => setStateValue('Public', i, res)}
                  className={`${res.access === 'Public' ? 'selected-state-style' : ''} p-1 m-1 priv-pub-font-style-button`}
                >
                  Public
                </button>
              </div>
            );
          });
          setSuiteOfSuiteArray((d) => response);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        resetProgress();
        setIsLoading(false);
        console.error('GET_ALL_SUITE_OF_SUITES :', error);
      });
    return [];
  }

  React.useEffect(() => {
    getAllSuiteOfSuite();
  }, []);

  function executeSuiteOfSuite(selectedSuite) {
    console.log('selectedSuite', selectedSuite);

    executeSuiteOfSuitePostReq(selectedSuite?.id).then((results) => {
      console.log('results', results);
      const response = results?.data?.responseObject;
      if (results?.data?.responseObject) {
        history.push(`/execution/suiteofsuite/${selectedSuite?.name}/dashboard?suiteId=${selectedSuite?._id}`);
      } else if (results?.data?.message && results?.data?.message.toLowerCase().includes('please connect a device')) {
        createAlertFun('info', results?.data?.message);
      } else if (results?.data?.message && results?.data?.message.toLowerCase().includes('please install a browser')) {
        createAlertFun('info', `${results?.data?.message}`);
      } else if (
        results?.data?.message &&
        results?.data?.message.startsWith('Client') &&
        results?.data?.message.toLowerCase().includes('is not available')
      ) {
        createAlertFun('info', `${results?.data?.message}`);
      }
    });
  }

  function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);

    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);

    return (
      <span>
        Search:{' '}
        <input
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`Type here...`}
          style={{
            fontSize: '1.1rem',
            border: '0',
          }}
        />
      </span>
    );
  }

  // Define a default UI for filtering
  function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search...`}
      />
    );
  }

  // This is a custom filter UI for selecting
  // a unique option from a list
  function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  // This is a custom filter UI that uses a
  // slider to set the filter value between a column's
  // min and max values
  function SliderColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    // Calculate the min and max
    // using the preFilteredRows

    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      preFilteredRows.forEach((row) => {
        min = Math.min(row.values[id], min);
        max = Math.max(row.values[id], max);
      });
      return [min, max];
    }, [id, preFilteredRows]);

    return (
      <>
        <input
          type="range"
          min={min}
          max={max}
          value={filterValue || min}
          onChange={(e) => {
            setFilter(parseInt(e.target.value, 10));
          }}
        />
        <button onClick={() => setFilter(undefined)}>Off</button>
      </>
    );
  }

  // This is a custom UI for our 'between' or number range
  // filter. It uses two number boxes and filters rows to
  // ones that have values between the two
  function NumberRangeColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id } }) {
    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      preFilteredRows.forEach((row) => {
        min = Math.min(row.values[id], min);
        max = Math.max(row.values[id], max);
      });
      return [min, max];
    }, [id, preFilteredRows]);

    return (
      <div
        style={{
          display: 'flex',
        }}
      >
        <input
          value={filterValue[0] || ''}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
          }}
          placeholder={`Min (${min})`}
          style={{
            width: '70px',
            marginRight: '0.5rem',
          }}
        />
        to
        <input
          value={filterValue[1] || ''}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
          }}
          placeholder={`Max (${max})`}
          style={{
            width: '70px',
            marginLeft: '0.5rem',
          }}
        />
      </div>
    );
  }

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  const contextMenuData = [
    {
      option: 'Schedule',
      // "icon": "/assets/images/eye_logo.svg"
    },
    {
      option: 'Clone',
      // "icon": "/assets/images/clone_logo.svg"
    },
  ];
  const contextMenuDataWithDelete = [
    {
      option: 'Schedule',
      // "icon": "/assets/images/eye_logo.svg"
    },
    // {
    //     "option": "Clone",
    //     // "icon": "/assets/images/clone_logo.svg"
    // },
    {
      option: 'Delete',
      // "icon": "/assets/images/clone_logo.svg"
    },
  ];

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val) => !val;

  // Our table component
  function Table({ columns, data }) {
    const filterTypes = React.useMemo(
      () => ({
        // Add a new fuzzyTextFilterFn filter type.
        fuzzyText: fuzzyTextFilterFn,
        // Or, override the default text filter to use
        // "startWith"
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      []
    );

    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      nextPage,
      previousPage,
      state,
      visibleColumns,
      preGlobalFilteredRows,
      setGlobalFilter,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
        initialState: { pageIndex: 0, pageSize: 9 },
      },
      useFilters, // useFilters!
      useGlobalFilter, // useGlobalFilter!
      useSortBy,
      usePagination
    );

    return (
      <>
        <table class="relative" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr class="mt-4" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th class="sticky top-0 px-6 py-3 text-current text-xs bg-gray-100 ml-5" {...column.getHeaderProps()}>
                    <span className="flex flex-row">
                      {column.render('Header')}
                      {/* Render the columns filter UI */}
                      <div>
                        <span>
                          {/* {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? <AiOutlineArrowDown className="mt-1 ml-1" />
                                                        : <AiOutlineArrowUp className="mt-1 ml-1" />
                                                    : ''} */}
                        </span>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr className="project-row" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td class=" px-0 py-2 text-center" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="pagecountborder" id="footer-suite-of-suite-main-tbl">
          <div className="float-right pr-4">
            <span className="pagecount">
              {page[0]?.index + 1} - {page[page.length - 1]?.index + 1} of {suiteOfSuiteArray.length}
            </span>
            <span className="pageborderleft ml-2 pl-2">
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className={canPreviousPage ? 'pagination-clickable-style ' : 'pageArrow'}
              >
                {'<'}
              </button>{' '}
              <span className="currentpagecount ml-2 mr-2">
                {' '}
                <span className="pl-2 pr-2">{pageIndex + 1}</span>
              </span>
              <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className={canNextPage ? 'pagination-clickable-style ' : 'pageArrow'}
              >
                {'>'}
              </button>{' '}
            </span>
          </div>
        </div>
        <br />
      </>
    );
  }

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue >= filterValue;
    });
  }

  function setStateValue(stateValue, i, obj) {
    if (stateValue) {
      // if (stateValue == 'Private') {
      //     let htmlEementForRemove = document.getElementById('Public' + i);
      //     if (htmlEementForRemove) {
      //         htmlEementForRemove.classList.remove('selected-state-style')
      //     }
      //     let htmlEement = document.getElementById(stateValue + i)
      //     console.log(`htmlElement`, htmlEement)
      //     htmlEement.className += " selected-state-style";
      // } else if (stateValue == 'Public') {
      //     let htmlEementForRemove = document.getElementById('Private' + i);
      //     if (htmlEementForRemove) {
      //         htmlEementForRemove.classList.remove('selected-state-style')
      //     }
      //     let htmlEement = document.getElementById(stateValue + i)
      //     console.log(`htmlElement`, htmlEement)
      //     htmlEement.className += " selected-state-style";
      // }

      if (obj) {
        obj.access = stateValue;
        const clone = (({ Actions, state, ...o }) => o)(obj); // remove b and c
        console.log(`clone`, clone);
        updateSuiteOfSuiteReq(obj.id, clone)
          .then((res) => {
            if (res.data && res.data.responseObject) {
              getAllSuiteOfSuite();
              {
                res.data && res.data.responseObject.access === 'Private'
                  ? createAlertFun('success', `${obj.name} access is changed to private`)
                  : createAlertFun('success', `${obj.name} access is changed to public`);
              }
            } else {
              // childalert.danger(res.data.message);
            }
          })
          .catch((error) => {
            console.log('error');
          });
      }
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        columns: [],
      },
      // {
      //     Header: 'Sl.No',
      //     accessor: 'slNo',
      //     disableFilters: true,
      //     Cell: ({ value }) => {
      //         return (
      //             <div className="table-non-link-color-common float-left ml-3">{value}</div>
      //         )
      //     }
      // },
      {
        Header: 'Name',
        accessor: 'name',
        disableFilters: true,
        Cell: (e) => (
          <a
            href=""
            onClick={(event) => {
              event.preventDefault();
              navigateToExecutionDetails(e.cell.row.original);
            }}
            className="hyper_link_color-common  table-font-style-common float-left ml-6 cursor-hand"
          >
            {' '}
            {e.value}{' '}
          </a>
        ),
      },
      {
        Header: 'No.of Scripts',
        accessor: 'noOfScript',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left ml-6 font-style-common">{value}</div>;
        },
      },
      {
        Header: 'Access',
        accessor: 'state',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common float-left mr-5 ml-3 mb-5 Access-table-font-style-common">
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Machine',
        accessor: 'machineHostName',
        disableFilters: true,

        Cell: ({ value }) => {
          console.log('value', value);
          let title = ' ';
          value.forEach((system, index) => {
            title +=
              (system?.machineInfo?.hostName ? system?.machineInfo?.hostName : '') +
              `${index < value?.length - 1 ? ',' : ' '}`;
          });

          return (
            <Tooltip title={title} placement="top">
              <span className=" hyper_link_color-common float-left ml-4  dashboard-font-style-common text-overflow-style">
                {value.map((system, index) => {
                  console.log('system', system);
                  if (index < 2) {
                    return (
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          getMachineDetails(
                            {
                              sysData: system,
                              status: value?.status ? value?.status : 'N/A',
                              owner: value?.createdByUname,
                            },
                            value?.status,
                            value?.createdByUname
                          );
                        }}
                        className="cursor-pointer"
                      >
                        {system.machineInfo.hostName}
                        {value[index + 1] ? ',' : null}
                      </span>
                    );
                  } else {
                    return null;
                  }
                })}
                {value?.length > 2 ? '...' : null}
              </span>
            </Tooltip>
          );
        },
      },
      {
        Header: 'Result',
        accessor: 'result',
        disableFilters: true,

        Cell: ({ value }) => {
          return <div className="hyper_link_color float-left ml-6 table-font-style-common">result</div>;
        },
      },

      {
        Header: 'Actions',
        accessor: 'Actions',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common Access-table-font-style-common ml-3 mb-2">{value}</div>;
        },
      },
    ],
    []
  );

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  function getMachineDetails(macObj, exeObj) {
    // openModalfun('systemDetails')
    console.log('macObj', macObj);
    console.log('exeobj', exeObj);
    let reqData = {
      machineName: macObj?.sysData?.machineInfo.hostName,
      os: macObj?.sysData?.machineInfo?.osName,
      browser: macObj?.sysData?.browserName,
      device: macObj?.sysData?.deviceInfo?.[0]?.name ? macObj?.sysData?.deviceInfo?.[0]?.name : '--',
      status: macObj?.status,
      owner: macObj?.owner,
    };
    if (
      exeObj?.executionStatus !== 'Cancelled' &&
      exeObj?.executionStatus !== 'Running' &&
      exeObj?.executionStatus !== 'Pending' &&
      exeObj?.executionStatus !== 'Terminated'
    ) {
      if (exeObj?.resultStatus === 'PASS') {
        reqData.status = 'Passed';
      } else if (exeObj?.resultStatus === 'FAIL') {
        reqData.status = 'Failed';
      }
    } else {
      reqData.status = exeObj?.executionStatus;
    }
    console.log('reqData', reqData);
    getSingleClientSystemReq(macObj?.sysData?.clientId).then((res) => {
      if (res.data.responseObject) {
        reqData['executions'] = res.data.responseObject;
      } else {
        reqData['executions'] = [];
      }
      setSelectedSystem(reqData);
      openModalfun('systemDetails');
    });
  }

  function scheduleExecutionFun(suiteObj) {
    console.log('suiteObj', suiteObj);
    let sceduleObj = {
      projectId: suiteObj ? suiteObj?.projectId : null,
      type: 'SUITE_OF_SUITES',
      suiteOfSuiteId: suiteObj ? suiteObj?.id : null,
      name: formik?.values?.Name,
      schedule: {
        suiteName: suiteObj ? suiteObj?.name : null,
        runIn: suiteObj.machines && suiteObj.machines.multiple === 'false' ? 'Single Machine' : 'Multiple Machine',
        machineName: machineNames,
        repeat: repeatData,
        scheduleDate: sheduledDate.toISOString(),
        scheduleTime: scheduleTime.toISOString(),
        expireDate: expiryDate.toISOString(),
        expireTime: expiryTime.toISOString(),
        estimatedDuration: '',
      },
    };
    console.log('sceduleObj', sceduleObj);

    let scheduleAreaInvalid;
    let expiryAreaInvalid;
    setTimeout(() => {
      scheduleAreaInvalid = document?.getElementById('scheduleId')?.getAttribute('aria-invalid');
      expiryAreaInvalid = document?.getElementById('expiryId')?.getAttribute('aria-invalid');
      console.log('scheduleAreaInvalid', scheduleAreaInvalid);
      if (scheduleAreaInvalid === 'true') {
        setScheduleBtnDisable(true);
      } else {
        setScheduleBtnDisable(false);
      }
      console.log('expiryAreaInvalid', expiryAreaInvalid);
      if (expiryAreaInvalid === 'true') {
        setExpireBtnDisable(true);
      } else {
        setExpireBtnDisable(false);
      }
      schedulePostReq(sceduleObj)
        .then((res) => {
          console.log('res', res);
          if (res.data && res.data.responseObject) {
            // getAllSuite();

            setRepeat('Once');
            setShowModal(false);
            createAlertFun('success', `${suiteObj?.name} scheduled successfully`);
            history.push(`/execution/suiteofsuite/${suiteObj?.name}/scheduled-instance?suiteId=${suiteObj?._id}`);
          } else {
            // childalert.danger(res.data.message);

            if (res?.data?.message && res?.data?.message.toLowerCase().includes('please connect a device')) {
              createAlertFun('info', res?.data?.message);
            } else if (res?.data?.message && res?.data?.message.toLowerCase().includes('please install a browser')) {
              createAlertFun('info', `${res?.data?.message}`);
            } else if (
              res?.data?.message &&
              res?.data?.message.startsWith('Client') &&
              res?.data?.message.toLowerCase().includes('is not available')
            ) {
              createAlertFun('info', `${res?.data?.message}`);
            } else {
              createAlertFun('danger', res.data.message);
            }
          }
        })
        .catch((error) => {
          console.log('error');
        });
    }, 100);
  }

  const changeExpiryDate = (date) => {
    setExpiryDate(date);
    checkExpiryValidation();
    checkScheduleValidation();
  };
  const changeScheduleDate = (date) => {
    setSheduledDate(date);
    checkExpiryValidation();
    checkScheduleValidation();
    let htmlEleForError = document.getElementById('date-picker-inline-expiredate-helper-text');
    console.log('htmlEleForError', htmlEleForError);
    if (htmlEleForError) {
      htmlEleForError.innerHTML = 'Expiry Date should not be less than Schedule Date';
    }
  };

  const changeExpiryTime = (newValue) => {
    setExpiryTime(newValue);
  };

  const changeScheduleTime = (newValue) => {
    setScheduleTime(newValue);
  };

  function openModalfun(btn, data) {
    if (btn === 'systemDetails') {
      // setShowModal(true);
      setOperation('systemDetails');
    }
  }

  function closeMachineDetailsModal() {
    setShowModal(false);
    setOperation();
  }

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block marginupper pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/4">
                <div className="inline-block">
                  <div className="flex flex-start ml-2">
                    <span className="pt-5 pl-3">
                      <ExclamationIcon className="h-6 w-6 text-yellow-600 " />
                    </span>
                    <Dialog.Title as="h3" className="text-sm leading-6 ml-4 pt-3 font-small text-gray-900">
                      <span className="fontPoppinsSemiboldLg block">Warning </span>
                      <span className="fontPoppinsMediumMd">Delete </span>
                    </Dialog.Title>
                  </div>
                </div>
                <hr className="line mt-px" />
                <div className="text-sm font-sans mt-3 text-gray-500 ml-16 mr-8">
                  <p className="">
                    Your Suite of Suite Data will be lost.Are you sure you want to delete{' '}
                    {selectedName ? selectedName.name : null}?{' '}
                  </p>
                  <p className="mt-2"> How do you want to proceed?</p>
                </div>
                <hr className="line mt-3" />
                <div className="mt-2 pb-2 flex flex-row float-right">
                  <button type="button" className="mt-3 gray-btn ml-1" onClick={handelDlt}>
                    Delete
                  </button>
                  <button type="button" className="mt-3 ml-4 primary-btn " onClick={() => setOpen(false)}>
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {operation === 'systemDetails' ? (
        <MachineDetails data={selectedSystem} closeModal={closeMachineDetailsModal} />
      ) : null}

      <Modal open={showModal} className="modal-dialog">
        <div className="modal-container page-modal-size">
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-header ">
              <label className="Popup-header-common Popup-header-layout-style-user">Schedule</label>
              <IconButton
                color="primary"
                component="span"
                className="close"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div className="modal-body" id="journal-scroll">
              <div className="grid grid-cols-2  mt-2 pb-3 ml-1 ">
                <div>
                  <label className="schedule-font-style">
                    <span className="text-red-400">* </span>Name
                  </label>
                  <br></br>
                  <div className="ml-2">
                    <Input
                      className="w-1/2 fontPoppinsRegularLg"
                      type="text"
                      name="Name"
                      id="Name"
                      value={formik.values.suiteName}
                      autoComplete="off"
                      placeholder={`Enter the name of the scheduler`}
                      onChange={formik.handleChange}
                      style={{ width: '225px' }}
                    />
                    {formik.errors.Name && formik.touched.Name && <p style={{ color: 'red' }}>{formik.errors.Name}</p>}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2  mt-2 pb-3 ml-1 ">
                <div>
                  <label className="schedule-font-style">
                    <span className="text-red-400">* </span>Suite of Suites Name
                  </label>
                  <TextField
                    id="standard-basic"
                    disabled="true"
                    className={classes.root}
                    value={selectedName && selectedName.name ? selectedName.name : null}
                  />
                </div>
                <div>
                  <label className="schedule-font-style">
                    <span className="text-red-400">* </span>Repeat
                  </label>
                  <div className="className={classes.formControl}">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={repeatData}
                      onChange={handleChange}
                      style={{ width: '80%' }}
                    >
                      {scheduleRepeatArray.map((scheduleVal) => (
                        <MenuItem value={scheduleVal}>{scheduleVal}</MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2  mt-2 pb-3 ml-1 ">
                <div className="schedule-style-suiteofsuite">
                  <label className="schedule-font-style">
                    <span className="text-red-400">* </span>Schedule Date/Time
                  </label>
                  <div className="flex flex-row ml-2 mr-3">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justifyContent="space-around">
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          minDate={new Date()}
                          id="date-picker-inline"
                          value={sheduledDate}
                          onChange={changeScheduleDate}
                          autoOk={true}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Stack spacing={3}>
                        <TimePicker
                          ampm={false}
                          openTo="hours"
                          views={['hours', 'minutes']}
                          inputFormat="HH:mm"
                          minTime={
                            JSON.stringify(sheduledDate)?.substring(0, 11) === JSON.stringify(today)?.substring(0, 11)
                              ? new Date(0, 0, 0, JSON.stringify(validationMinTime)?.substring(1, 3))
                              : null
                          }
                          shouldDisableTime={(timeValue, clockType) => {
                            if (
                              JSON.stringify(sheduledDate)?.substring(0, 11) === JSON.stringify(today)?.substring(0, 11)
                            ) {
                              let scheduledHour = scheduleTime.getHours();
                              if (JSON.stringify(minHour) === JSON.stringify(scheduledHour)) {
                                if (clockType === 'minutes') {
                                  return timeValue <= minMinute;
                                }
                              }
                            }
                          }}
                          mask="__:__:__"
                          // label="With seconds"
                          value={scheduleTime}
                          onChange={(newValue) => {
                            changeScheduleTime(newValue);
                            checkScheduleValidation();
                          }}
                          renderInput={(params) => <TextField id="scheduleId" {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </div>
                </div>

                <div className="expire-style-suiteofsuite">
                  <label className="schedule-font-style">
                    <span className="text-red-400">* </span>Expire Date/Time
                  </label>
                  <div className="flex flex-row mr-3 ">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justifyContent="space-around">
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          minDate={new Date(sheduledDate)}
                          id="date-picker-inline-expiredate"
                          value={expiryDate}
                          onChange={changeExpiryDate}
                          autoOk={true}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Stack spacing={3}>
                        <TimePicker
                          ampm={false}
                          openTo="hours"
                          views={['hours', 'minutes']}
                          inputFormat="HH:mm"
                          minTime={
                            JSON.stringify(expiryDate).substring(0, 11) ===
                            JSON.stringify(sheduledDate).substring(0, 11)
                              ? new Date(0, 0, 0, new Date(scheduleTime).toString().slice(16, 18))
                              : null
                          }
                          shouldDisableTime={(timeValue, clockType) => {
                            if (
                              JSON.stringify(sheduledDate)?.substring(0, 11) ===
                              JSON.stringify(expiryDate)?.substring(0, 11)
                            ) {
                              let scheduledHour = scheduleTime.getHours();
                              let expiredHour = expiryTime.getHours();
                              let minExpMinute = scheduleTime.getMinutes();
                              if (expiredHour === scheduledHour) {
                                if (clockType === 'minutes') {
                                  return timeValue <= minExpMinute;
                                }
                              }
                            }
                          }}
                          mask="__:__:__"
                          // label="With seconds"
                          value={expiryTime}
                          onChange={(newValue) => {
                            changeExpiryTime(newValue);
                            checkExpiryValidation();
                          }}
                          renderInput={(params) => <TextField id="expiryId" {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="fontPoppinsMediumMd cancel-button-common cancel-button-layout-style-project mr-3"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="primary-btn"
                disabled={scheduleBtnDisable || expireBtnDisable}
                // onClick={() => scheduleExecutionFun(selectedName)}
              >
                Schedule
              </button>
            </div>
          </form>
        </div>
      </Modal>

      {!tab ? (
        <div className="content_area_style overflow-auto" id="journal-scroll">
          <AlertContatiner />
          <div>
            <div className="mt-2 suite-header-style">
              <label className="project_label page-header-common p-2 float-left">Suites of Suites</label>
            </div>

            <div className="flex flex-row float-right mb-3">
              {/* <div className=" search_btn_style">
                                <FaSearch className=" searchBtn" />
                            </div> */}
              {isProjectOpen && (
                <button
                  disabled={!suiteOfSuiteArray.length}
                  className={`${!suiteOfSuiteArray.length ? 'opacity-30' : ''} mr-4 -mt-2`}
                  onClick={() => {
                    getAllSuiteOfSuite();
                  }}
                >
                  <Tooltip title="Sync" placement="top">
                    <Sync fontSize="small" className={'text-gray-700'} />
                  </Tooltip>
                </button>
              )}
              {isEditable && (
                <button
                  class="primary-btn mr-3"
                  onClick={() => {
                    createSuite('create');
                    setSuiteofSuiteObj();
                  }}
                >
                  + Suites of Suites
                </button>
              )}
            </div>
          </div>

          <div className="table-height overflow-auto" id="journal-scroll">
            {isLoading ? (
              <CommonLoader />
            ) : (
              <>
                <Table columns={columns} data={suiteOfSuiteArray} />
                {suiteOfSuiteArray && !suiteOfSuiteArray.length ? (
                  <div className="create-set-layout">
                    {isEditable && (
                      <>
                        <label className="quick-start-style ">Quick Start </label>
                        <br></br>
                        <label className="quick-start-style ">Step1 : Click </label>{' '}
                        <button
                          className="primary-btn mr-2 ml-1"
                          onClick={() => {
                            createSuite('create');
                            setSuiteofSuiteObj();
                          }}
                        >
                          + Suite of Suites
                        </button>
                        <label className="quick-start-style">to start adding suites of Suites</label>
                      </>
                    )}
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
      ) : (
        <div className={urlSuiteId ? '' : 'content_area_style p-3'}>
          {tab === 'create-suite-of-suites' || tab === 'edit-suite-of-suites' ? (
            <CreateEditSuiteOfSuites
              suiteofSuiteObj={suiteofSuiteObj}
              setSuiteofSuiteObj={setSuiteofSuiteObj}
              operation={operation}
              getAllSuiteOfSuite={getAllSuiteOfSuite}
              createAlertFun={createAlertFun}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default SuiteOfSuite;

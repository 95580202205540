import React from 'react';
import { withRouter } from 'react-router-dom';
import Breadcrumbs from 'react-router-dynamic-breadcrumbs';
import { decodeSpecialCharacter } from '../test-development/shared/common-methods';
import { routes } from '@common/routes/routes';
import { getIDB, setIDB } from '@src/util/localForage_idb_controller';
import { defaultStepGroupList } from '@src/common/ui-constants';
import { isEmptyValue } from '@src/util/common_utils';

function BreadcrumbsList(props) {
  const { isDrawerCollapsed } = props;
  let search = window.location.search;
  let urlSuiteId = JSON.parse(localStorage.getItem('selectedSuiteObj'))?._id;
  if (isEmptyValue(urlSuiteId)) {
    urlSuiteId = localStorage.getItem('urlSuiteId');
  }
  let runId = localStorage.getItem('runId');
  let urlRunId = runId;
  let scriptRunId = localStorage.getItem('scriptRunId');
  const { history, location } = props;
  let historyLocation = history.location.pathname;
  let index = historyLocation.lastIndexOf('/');
  let clickedBreadcrumb = historyLocation.slice(index + 1, historyLocation.length);
  const urlModuleId= new URLSearchParams(search).get("moduleId");

  const keysToCheck = ['id', 'parentId', 'manualId'];
  const mode = new URLSearchParams(location.search).get('mode');
  if (mode && mode === 'create') {
    routes['/configuration/template/templatecreation'] = 'Create Manual Test Case Template';
  } else if (mode === 'edit') {
    routes['/configuration/template/templatecreation'] = 'Edit Manual Test Case Template';
  } else if (mode === 'view') {
    routes['/configuration/template/templatecreation'] = 'Manual Test Case Template Details';
  }
  const dmode = new URLSearchParams(location.search).get('dmode');
  if (dmode && dmode === 'create') {
    routes['/configuration/template/defecttemplate/defectdetails/defecttemplatecreation'] =
      'Create Defect Details Template';
  } else if (dmode === 'edit') {
    routes['/configuration/template/defecttemplate/defectdetails/defecttemplatecreation'] =
      'Edit Defect Details Template';
  } else if (dmode === 'view') {
    routes['/configuration/template/defecttemplate/defectdetails/defecttemplatecreation'] =
      props?.location?.state && JSON.parse(props.location.state)?.data?.name;
  }

  if (mode) {
    routes['/configuration/template'] = 'Manual Test Case Templates';
  } else if (dmode) {
    routes['/configuration/template'] = 'Templates';
  }

  const checkUrl = async (value) => {
    const { history, location } = props;
    let historyLocation = history.location.pathname;

    if (historyLocation.includes('/testData/')) {
      let id;
      let type = 'folder';
      let parentId;
      let index = historyLocation.lastIndexOf('/');
      let folderId = historyLocation
        .substring(index + 1)
        .split('_')
        .pop();
      let parentAndSonJson = JSON.parse(localStorage.getItem('parentAndSonJson'));
      parentAndSonJson.every((element) => {
        if (element.id === folderId) {
          id = element.id;
          if (element.parentId) {
            type = 'file';
            parentId = element.parentId;
          }
          return false;
        }
        return true;
      });
      if (type === 'folder') {
        history.push(`${historyLocation}/Folder?id=${id}`);
      } else {
        history.push(`${historyLocation}/File?id=${id}&parentId=${parentId}`);
      }
    } else if (historyLocation.includes('/repository/element/project_elements/')) {
      let id;
      let parentId;
      let platForm;
      let type = 'page';
      let projectType;
      let index = historyLocation.lastIndexOf('/');
      let PageName = decodeSpecialCharacter(historyLocation.slice(index + 1, historyLocation.length));
      if (PageName !== 'Root Page') {
        let parentAndSonJson = JSON.parse(localStorage.getItem('parentAndSonJson'));
        let pageEleId = decodeSpecialCharacter(historyLocation.slice(index + 1, historyLocation.length));
        for (let i = 0; i < parentAndSonJson.length; i++) {
          const obj = parentAndSonJson[i];
          if (pageEleId.includes(obj.Name)) {
            pageEleId = pageEleId.replace(obj.Name, '').trim();
            break;
          }
        }
        pageEleId = pageEleId.slice(1);
        const nodeIndexinJson = historyLocation.split('/').length - 5;
        let updatedJson = [...parentAndSonJson];
        parentAndSonJson?.forEach((element, index) => {
          if (element.id === pageEleId && index === nodeIndexinJson) {
            id = element.id;
            if (element.id.includes('ELE') || element.id.includes('SH')) {
              parentId = element.parentId;
              type = 'element';
            } else {
              projectType = element.ProjectType;
              platForm = element.PlatForm;
            }
            updatedJson = parentAndSonJson.slice(0, index + 1);
          }
        });
        localStorage.setItem('parentAndSonJson', JSON.stringify(updatedJson));
      }
      if (PageName === 'Root Page') {
        let rootJson = JSON.parse(localStorage.getItem('rootJson'));
        history.push(
          `${historyLocation}/${
            rootJson.projectType === 'Web'
              ? 'Page'
              : ['web_mobile', 'Salesforce'].includes(rootJson.projectType) && rootJson.platForm === 'Web'
              ? 'Page'
              : ['web_mobile', 'Salesforce'].includes(rootJson.projectType) &&
                ['Android', 'iOS', 'Ios', 'MobileWeb'].includes(rootJson.platForm)
              ? 'Screen'
              : 'Screen'
          }?id=${rootJson.id}&type=page&projectType=${rootJson.projectType}&platForm=${rootJson.platForm}`
        );
      } else if (type === 'page' || (id.includes('PAG') >= 0 && id.includes('ELE') < 0)) {
        history.push(
          `${historyLocation}/${
            projectType === 'Web'
              ? 'Page'
              : ['web_mobile', 'Salesforce'].includes(projectType) && platForm === 'Web'
              ? 'Page'
              : ['web_mobile', 'Salesforce'].includes(projectType) &&
                ['Android', 'iOS', 'Ios', 'MobileWeb'].includes(platForm)
              ? 'Screen'
              : 'Screen'
          }?id=${id}&type=page&projectType=${projectType}&platForm=${platForm}`
        );
      } else if (type === 'element') {
        history.push(`${historyLocation}/Element?pageId=${parentId}&eleId=${id}`);
      }
    } else if (historyLocation.includes('/repository/program_elements/')) {
      let id;
      let parentId;
      let type = 'package';
      let index = historyLocation.lastIndexOf('/');
      let folderName = historyLocation.slice(index + 1, historyLocation.length);
      if (folderName !== 'Root package') {
        let parentAndSonJson = JSON.parse(localStorage.getItem('parentAndSonJson'));
        let folderId = decodeSpecialCharacter(historyLocation.slice(index + 1, historyLocation.length));
        for (let i = 0; i < parentAndSonJson.length; i++) {
          const obj = parentAndSonJson[i];
          if (folderId.includes(obj.Name)) {
            folderId = folderId.replace(obj.Name, '').trim();
            break;
          }
        }
        folderId = folderId.slice(1);
        const nodeIndexinJson = historyLocation.split('/').length - 4;
        let updatedJson = [...parentAndSonJson];
        parentAndSonJson?.forEach((element, index) => {
          if (element.id === folderId && index === nodeIndexinJson) {
            id = element.id;
            if (element.id.includes('ELE')) {
              parentId = element.parentId;
              type = 'programElement';
            }
            updatedJson = parentAndSonJson.slice(0, index + 1);
          }
        });
        localStorage.setItem('parentAndSonJson', JSON.stringify(updatedJson));
      }
      if (folderName === 'Root package') {
        let rootJson = JSON.parse(localStorage.getItem('rootJson'));
        history.push(`${historyLocation}/Package?id=${rootJson.id}&type=package`);
      } else if (type === 'package') {
        history.push(`${historyLocation}/Package?id=${id}&type=package`);
      } else {
        history.push(`${historyLocation}/Program Element?id=${id}&parentId=${parentId}`);
      }
    } else if (historyLocation.includes('/repository/step_group/')) {
      let id;
      let parentId;
      let type = 'Library';
      let index = historyLocation.lastIndexOf('/');
      const clickedBreadcrumbName = decodeSpecialCharacter(historyLocation.slice(index + 1, historyLocation.length));
      let nameWithoutId = clickedBreadcrumbName;
      let libraryId = clickedBreadcrumbName;
      if (clickedBreadcrumbName === 'Root Library') {
        let rootJson = JSON.parse(localStorage.getItem('rootJson'));
        history.push(`${historyLocation}/Library?id=${rootJson.id}&type=Library`);
      } else {
        let parentAndSonJson = JSON.parse(localStorage.getItem('parentAndSonJson'));
        for (let i = 0; i < parentAndSonJson.length; i++) {
          const obj = parentAndSonJson[i];
          if (nameWithoutId.includes(obj.id)) {
            nameWithoutId = nameWithoutId.replace(obj.id, '').trim().slice(0, -1);
            break;
          }
        }
        let decodedName = decodeSpecialCharacter(nameWithoutId);
        for (let i = 0; i < parentAndSonJson.length; i++) {
          const obj = parentAndSonJson[i];
          if (libraryId.includes(obj.Name)) {
            libraryId = libraryId.replace(obj.Name, '').trim().slice(1);
            break;
          }
        }
        const breadcrumbNames = [
          'Library',
          'Steps',
          'Parameters',
          'Variables',
          'Step Group Variables',
          'Global Variables',
          'Project Environment Variables',
        ];
        if (!breadcrumbNames.includes(clickedBreadcrumbName)) {
          let historyindex = parentAndSonJson?.length - (historyLocation.split('/').length - 3);
          if (parentAndSonJson?.length > historyindex && parentAndSonJson[historyindex]?.Name === nameWithoutId) {
            id = parentAndSonJson[historyindex].id;
            if (parentAndSonJson[historyindex].parentId) {
              type = 'Step group';
              parentId = parentAndSonJson[historyindex].parentId;
            }
          } else {
            parentAndSonJson?.forEach((element) => {
              if (element.id === libraryId) {
                id = element.id;
                if (element.parentId) {
                  type = 'Step group';
                  parentId = element.parentId;
                }
              }
            });
            if (id === undefined) {
              id = parentAndSonJson[historyindex].parentId;
            }
          }
          if (type === 'Library') {
            history.push(`${historyLocation}/Library?id=${id}&type=Library`);
          } else {
            if (defaultStepGroupList.includes(decodedName)) {
              history.push(
                `${historyLocation}/Steps?id=${id}&parentId=${parentId}&stepGroup=${decodedName}&defaultStepGroup=true`
              );
            } else {
              history.push(
                `${historyLocation}/Steps?id=${id}&parentId=${parentId}&stepGroup=${decodedName}&defaultStepGroup=false`
              );
            }
          }
        }
        if (clickedBreadcrumbName === 'Variables') {
          let parentAndSonJson = JSON.parse(localStorage.getItem('parentAndSonJson'));
          const jsonLength = parentAndSonJson.length;
          id = parentAndSonJson[jsonLength - 1].id;
          parentId = parentAndSonJson[jsonLength - 1].parentId;
          if (parentAndSonJson[0].parentId) {
            type = 'Step group';
            parentId = parentAndSonJson[0].parentId;
          }
          if (['CloseBrowser', 'OpenBrowser'].includes(decodedName)) {
            history.push(
              `${historyLocation}/Step%20Group%20Variables?id=${id}&parentId=${parentId}&stepGroup=step&defaultStepGroup=true`
            );
          } else {
            history.push(
              `${historyLocation}/Step%20Group%20Variables?id=${id}&parentId=${parentId}&stepGroup=step&defaultStepGroup=false`
            );
          }
        }
      }
    } else if (historyLocation.includes('/testdevelopment/')) {
      let parentId;
      let executionId;
      let type = 'testdevelopment';
      let index = historyLocation.lastIndexOf('/');
      const moduleName = window.location.pathname.split('/')[3];
      const moduleStep = window.location.pathname.split('/')[5];
      const stepName = window.location.pathname.split('/')[6];
      const rootCheck = window.location.pathname.split('/')[3];
      const rootModule = window.location.pathname.split('/')[4];
      const script = window.location.pathname.split('/')[7];

      let scriptType = new URLSearchParams(search).get('type');
      let clickedBreadcrumbName = historyLocation.slice(index + 1, historyLocation.length);
      // const moduleId = new URLSearchParams(search).get("id");
      const moduleId = localStorage.getItem('moduleId');
      let dependentData = await getIDB('dependent-data');

      const fullUrl = window.location.href;
      if (historyLocation.includes('/Execution Dashboard/')) {
        type = 'Execution Dashboard';
        if (clickedBreadcrumbName !== 'Root Module') {
          let _treeData = (await getIDB('breadcrumb-data'))
            ? await getIDB('breadcrumb-data')
            : await getIDB('tree-data');
          let _dependentData = await getIDB('dependent-data');
          let scriptData = await getIDB('script-data');
          let subtreeData = await getIDB('sub-tree-data');
          // let totalModule = _treeData.moduleStats.total
          let index = _treeData?.children?.map((value, index) => value);
          while (true) {
            if (index[0]?.children) {
              index = index[0]?.children;
            } else {
              break;
            }
          }
          var step = moduleStep?.replace(/%20/g, ' ');
          let obj = index?.find((o) => o.name === step);
          if (obj) {
            obj = obj?.children?.find((o) => o.name === stepName);
          } else {
            obj = index?.find((o) => o.name === stepName);
          }
          let indexOfDependent = _dependentData?.map((value, index) => value);
          let objOfDependent = indexOfDependent?.find((o) => o.name === stepName);

          let id = scriptData ? scriptData[0]?.id : '';
          const clientId = scriptData ? scriptData[0]?.selectedSystem.clientId : '';
          var module = moduleName?.replace(/%20/g, ' ');
          var stepNameValue = stepName?.replace(/%20/g, ' ');

          let modId = _treeData.id;
          if (scriptType !== 'dependant') {
            type = 'Module Result';
          }
          if (_treeData) {
            executionId = _treeData?.executionId;
            if (type === 'Execution Dashboard') {
              if (clickedBreadcrumbName === 'script') {
                history.push(`${location.pathname}${location.search}`);
              } else {
                if (scriptType && scriptType === 'dependant') {
                  if (clickedBreadcrumbName === moduleStep) {
                    const pathArray = historyLocation.split('/');
                    const coppiedPathArray = [...pathArray];
                    delete coppiedPathArray[coppiedPathArray.length - 1];
                    const lastScriptName = pathArray[pathArray.length - 1];

                    const path = coppiedPathArray.join('/');
                    history.push(
                      `${path.concat(
                        subtreeData.name
                      )}/${lastScriptName}/script/result?id=${id}&executionId=${executionId}&moduleId=${modId}&clientId=${clientId}&runId=${scriptRunId}`
                    );
                  } else {
                    history.push(`${location.pathname}${location.search}`);
                  }
                } else {
                  if (clickedBreadcrumbName === stepName) {
                    history.push(`${historyLocation}/script/result?id=${id}&executionId=${executionId}`);
                  } else if (clickedBreadcrumbName === step) {
                    history.push(`${historyLocation}/result?executionId=${executionId}`);
                  } else {
                    history.push(`${historyLocation}?executionId=${executionId}`);
                  }
                }
              }
            } else if (type === 'Module Result') {
              if (!script) {
                if (clickedBreadcrumbName === module && step === undefined) {
                  history.push(`${historyLocation}?executionId=${executionId}`);
                } else if (stepName) {
                  if (clickedBreadcrumbName === stepNameValue) {
                    history.push(
                      `${historyLocation}/script/result?id=${
                        obj?.id ? obj?.id : id
                      }&executionId=${executionId}&clientId=${
                        obj?.selectedSystem ? obj.selectedSystem.clientId : clientId
                      }&runId=${urlRunId}&moduleId=${urlModuleId}`
                    );
                  }
                  if (clickedBreadcrumbName === module && stepName) {
                    history.push(
                      `${historyLocation}/script/result?id=${
                        obj?.id ? obj?.id : id
                      }&executionId=${executionId}&clientId=${
                        obj?.selectedSystem ? obj.selectedSystem.clientId : clientId
                      }&runId=${urlRunId}&moduleId=${urlModuleId}`
                    );
                  }
                } else if (clickedBreadcrumbName === module && step === undefined) {
                  history.push(`${historyLocation}?executionId=${executionId}`);
                } else if (clickedBreadcrumbName === module && !stepName && !moduleStep) {
                  history.push(`${historyLocation}?executionId=${executionId}`);
                } else if (clickedBreadcrumbName === module && !stepName) {
                  let _subtreedata = await getIDB('sub-tree-data');
                  let finalObj = _subtreedata.children.filter((e) => e.name == clickedBreadcrumbName);
                  if (finalObj[0]) {
                    setIDB('sub-tree-data', finalObj[0]);
                  }
                  history.push(`${historyLocation}/result?executionId=${executionId}&id=${moduleId}`);
                } else if (clickedBreadcrumbName === stepName) {
                  history.push(`${historyLocation}/result?executionId=${executionId}&id=${moduleId}`);
                } else {
                  let _subtreedata = await getIDB('breadcrumb-data');
                  let finalObj = _subtreedata ? _subtreedata.children : await getIDB('tree-data');
                  while (true) {
                    if (finalObj[0]?.children) {
                      if (finalObj[0]?.children) {
                        if (
                          finalObj?.filter((e) => e.name == clickedBreadcrumbName)[0]?.name == clickedBreadcrumbName
                        ) {
                          finalObj = finalObj.filter((e) => e.name == clickedBreadcrumbName);
                          break;
                        } else {
                          finalObj = finalObj[0].children.filter((e) => e.name == clickedBreadcrumbName);
                          break;
                        }
                      } else {
                        finalObj = finalObj[0]?.children;
                      }
                    } else {
                      break;
                    }
                  }
                  if (finalObj[0]) {
                    setIDB('sub-tree-data', finalObj[0]);
                  }
                  let _scriptData = await getIDB('breadcrumb-data');
                  history.push(
                    `${historyLocation}/result?executionId=${
                      _scriptData?.executionId ? _scriptData?.executionId : executionId
                    }&id=${moduleId}`
                  );
                }
              } else {
                if (clickedBreadcrumbName === 'script') {
                  history.push(
                    `${historyLocation}/result?id=${obj?.id ? obj?.id : id}&executionId=${executionId}&clientId=${
                      obj?.selectedSystem ? obj.selectedSystem.clientId : clientId
                    }`
                  );
                } else {
                  if (clickedBreadcrumbName === module && step === undefined) {
                    history.push(`${historyLocation}?executionId=${executionId}`);
                  } else if (clickedBreadcrumbName === module) {
                    history.push(`${historyLocation}/result?executionId=${executionId}&id=${modId}`);
                  } else {
                    history.push(
                      `${historyLocation}/script/result?id=${
                        obj?.id ? obj?.id : id
                      }&executionId=${executionId}&clientId=${
                        obj?.selectedSystem ? obj.selectedSystem.clientId : clientId
                      }&runId=${urlRunId}`
                    );
                  }
                }
              }
            } else {
              history.push(
                `${historyLocation}/script/Steps?id=${id}&parentId=${parentId}&stepGroup=${clickedBreadcrumbName}`
              );
            }
          }
        } else {
          let _treeData = await getIDB('tree-data');
          executionId = _treeData?.executionId;
          if (type === 'Execution Dashboard') {
            if (rootModule === rootCheck) {
              history.push(`${historyLocation}/result?executionId=${executionId}`);
            } else if (rootCheck === 'Root%20Module') {
              history.push(`${historyLocation}?executionId=${executionId}`);
            } else {
              let _scriptData = await getIDB('breadcrumb-data');
              history.push(
                `${historyLocation}/result?executionId=${
                  _scriptData?.executionId ? _scriptData?.executionId : executionId
                }`
              );
            }
          }
        }
      } else if (historyLocation.includes('/Script/')) {
        const moduleId = new URLSearchParams(search).get('moduleId');
        const scriptId = new URLSearchParams(search).get('scriptId');
        const manualScriptId = new URLSearchParams(search).get('manualScriptId');
        let id;
        let parentId;
        let type = 'Module';
        let index = historyLocation.lastIndexOf('/');
        let clickedBreadcrumb = historyLocation.slice(index + 1, historyLocation.length);
        if (clickedBreadcrumb === 'Settings') {
          if (scriptId) {
            history.push(
              `${historyLocation}/Pre Condition?moduleId=${moduleId}&scriptId=${scriptId}${
                manualScriptId ? '&manualScriptId=' + manualScriptId : ''
              }`
            );
          } else {
            history.push(`${historyLocation}/Pre Condition?moduleId=${moduleId}`);
          }
        } else if (clickedBreadcrumb === 'Variables') {
          if (scriptId) {
            history.push(
              `${historyLocation}/Local Variables?moduleId=${moduleId}&scriptId=${scriptId}${
                manualScriptId ? '&manualScriptId=' + manualScriptId : ''
              }`
            );
          } else {
            history.push(`${historyLocation}/Global Variables?moduleId=${moduleId}`);
          }
        } else if (clickedBreadcrumb === 'webservices') {
          history.push(
            `${historyLocation}/restapi?moduleId=${moduleId}&scriptId=${scriptId}${
              manualScriptId ? '&manualScriptId=' + manualScriptId : ''
            }`
          );
        } else {
          let parentAndSonJson = JSON.parse(localStorage.getItem('parentAndSonJson'));
          let manualId;
          const nodeIndexinJson = historyLocation.split('/').length - 4;
          let updatedJson = [...parentAndSonJson];
          clickedBreadcrumb = decodeSpecialCharacter(clickedBreadcrumb);
          let moduleId = historyLocation
            .substring(index + 1)
            .split('_')
            .pop();
          parentAndSonJson.forEach((element, i) => {
            const { id: moduleScriptId, manualId: manualScriptId, parentId: moduleScriptParentId } = element;
            if ([moduleScriptId, manualScriptId].includes(moduleId) && i === nodeIndexinJson) {
              id = moduleScriptId;
              if (moduleScriptParentId) {
                type = 'Script';
                manualId = manualScriptId;
                parentId = moduleScriptParentId;
              }
              updatedJson = parentAndSonJson.slice(0, i + 1);
            }
          });
          localStorage.setItem('parentAndSonJson', JSON.stringify(updatedJson));
          if (type === 'Module') {
            history.push(`${historyLocation}/Module?moduleId=${id}`);
          } else if (id) {
            history.push(
              `${historyLocation}/Steps?moduleId=${parentId}&scriptId=${id}${
                manualId ? '&manualScriptId=' + manualId : ''
              }`
            );
          } else {
            history.push(`${historyLocation}/Manual Test Case?moduleId=${parentId}&manualScriptId=${manualId}`);
          }
        }
      }
    } else if (historyLocation.includes('/execution/')) {
      let executionId;
      let type = 'Execution Dashboard';
      let index = historyLocation.lastIndexOf('/');
      const suiteName = window.location.pathname.split('/')[3];
      const moduleName = window.location.pathname.split('/')[8];
      const _module_script = window.location.pathname.split('/')[6];
      const rootCheck = window.location.pathname.split('/')[5];
      let clickedBreadcrumbName = historyLocation.slice(index + 1, historyLocation.length);
      if (['results', 'Result'].includes(clickedBreadcrumbName) && ![suiteName].includes(clickedBreadcrumbName)) {
        history.push(`${historyLocation}${search}`);
      } else {
        let urlSuiteType;
        let urlSuiteTypeValue = new URLSearchParams(search).get('suiteType');
        urlSuiteTypeValue != null
          ? (urlSuiteType = urlSuiteTypeValue)
          : (urlSuiteType = JSON.parse(localStorage.getItem('selectedSuiteObj'))?.suiteType);
        if (!urlSuiteId) {
          urlSuiteId = new URLSearchParams(search).get('suiteId');
        }
        if (!urlSuiteType) {
          const selectedSuiteObj = await getIDB('execResult');
          if (selectedSuiteObj?.responseObject?.suiteType) {
            urlSuiteType = selectedSuiteObj?.responseObject?.suiteType;
          }
        }
        // const moduleId = new URLSearchParams(search).get("id");
        const moduleId = localStorage.getItem('moduleId');
        const fullUrl = window.location.href;
        if (clickedBreadcrumbName !== 'Root Module') {
          let _treeData = await getIDB('tree-data');
          let _subtreeData = await getIDB('sub-exec-tree-data');
          if (moduleId) {
            type = 'Module Result';
          }
          if (_treeData) {
            let executionId = _treeData[0]?.executionId ? _treeData[0]?.executionId : _treeData?.executionId;
            let title = _treeData[0]?.name ? _treeData[0]?.name : _treeData?.name;
            let id = _treeData[0]?.id ? _treeData[0]?.id : _treeData?.id;
            let scriptData = await getIDB('script-data');
            let exeid = scriptData ? scriptData[0]?.id : '';
            if (!exeid) {
              exeid = scriptData?.[0]?.key;
            }
            const clientId = scriptData ? scriptData?.[0]?.selectedSystem?.clientId : '';
            if (clickedBreadcrumbName === title) {
              history.push(`${historyLocation}/Result?id=${id}&executionId=${executionId}`);
            } else {
              if (_treeData[0]?.id) {
                if (clickedBreadcrumbName === suiteName) {
                  // history.push(`${historyLocation}/suite-details`)
                  history.push(`${historyLocation}/suite-dashboard?suiteId=${urlSuiteId}&suiteType=${urlSuiteType}`);
                } else {
                  if (moduleName === undefined) {
                    if (clickedBreadcrumbName === 'overview') {
                      history.push(
                        `${historyLocation}/machines/Result?executionId=${executionId}&multiple=true&clientId=${clientId}`
                      );
                    } else if (clickedBreadcrumbName === 'execution dashboard') {
                      let dashboard = historyLocation.replace('execution dashboard', 'suite-dashboard');
                      history.push(`${dashboard}?suiteId=${urlSuiteId}&suiteType=${urlSuiteType}`);
                    } else if (fullUrl.includes('execution%20dashboard')) {
                      history.push(
                        `${historyLocation}/Result?executionId=${executionId}&multiple=true&clientId=${clientId}`
                      );
                    } else {
                      history.push(`${historyLocation}`);
                    }
                  } else {
                    let scriptData = await getIDB('script-data');
                    let scriptId = scriptData[0]?.id || scriptData[0]?.key;
                    if (clickedBreadcrumbName === scriptData[0]?.title) {
                      history.push(
                        `${historyLocation}/Result?id=${scriptId}&executionId=${executionId}&multiple=true&clientId=${clientId}&runId=${scriptData[0]?.runId}&moduleId=${urlModuleId}`
                      );
                    } else {
                      history.push(`${historyLocation}/${title}/Result?id=${id}&executionId=${executionId}`);
                    }
                  }
                }
              } else {
                if (clickedBreadcrumbName === suiteName && _module_script != suiteName) {
                  history.push(`${historyLocation}/suite-dashboard?suiteId=${urlSuiteId}&suiteType=${urlSuiteType}`);
                } else {
                  if (moduleName === undefined) {
                    if (clickedBreadcrumbName === 'overview') {
                      history.push(
                        `${historyLocation}/machines/Result?executionId=${executionId}&multiple=true&clientId=${clientId}`
                      );
                    } else if (clickedBreadcrumbName === 'execution dashboard') {
                      let dashboard = historyLocation.replace('execution dashboard', 'suite-dashboard');
                      history.push(`${dashboard}?suiteId=${urlSuiteId}&suiteType=${urlSuiteType}`);
                    } else if (fullUrl.includes('execution%20dashboard')) {
                      let scriptName = '';
                      if (scriptData) {
                        scriptName = scriptData[0]?.title ? scriptData[0].title : scriptData[0]?.name;
                      }
                      if (scriptData && (clickedBreadcrumbName === scriptName || suiteName === clickedBreadcrumbName)) {
                        const urlParams = new URLSearchParams(props?.location?.search);
                        const executionId = urlParams.get('executionId');
                        const runId = urlParams.get('runId');
                        history.push(
                          `${historyLocation}/script/results?id=${scriptData[0]?.key}&executionId=${executionId}&suiteId=${urlSuiteId}&clientId=${clientId}&runId=${runId}`
                        );
                      } else if (clickedBreadcrumbName === 'script' && scriptName) {
                        history.push(
                          `${historyLocation}/results?id=${
                            scriptData[0]?.id ? scriptData[0]?.id : scriptData[0]?.key
                          }&executionId=${executionId}&suiteId=${urlSuiteId}&clientId=${clientId}`
                        );
                      } else {
                        if (fullUrl.includes('machines')) {
                          const runId = new URLSearchParams(search).get('runId');
                          let clickedMachineName = historyLocation.slice(index + 1, historyLocation.length);
                          let machineName = scriptData[0]?.selectedSystem?.machineInfo?.hostName;
                          if (clickedMachineName === machineName) {
                            history.push(`${historyLocation}/Result?executionId=${executionId}`);
                          } else {
                            history.push(
                              `${historyLocation}/Result?executionId=${executionId}${
                                runId ? `&runId=${runId}` : ''
                              }&multiple=true&clientId=${clientId}`
                            );
                          }
                        } else {
                          let dependentData = JSON.parse(localStorage.getItem('dependent-data'));
                          let dependentDataId;
                          if (dependentData) {
                            dependentDataId = dependentData?.find((o) => o.name === clickedBreadcrumbName);
                          }
                          if (clickedBreadcrumbName == _module_script) {
                            if (!moduleName) {
                              history.push(
                                `${historyLocation}/results?id=${
                                  dependentDataId?.id ? dependentDataId?.id : exeid
                                }&executionId=${executionId}&suiteId=null`
                              );
                            } else {
                              history.push(
                                `${historyLocation}/script/results?id=${
                                  dependentDataId?.id ? dependentDataId?.id : exeid
                                }&executionId=${executionId}&type=dependant`
                              );
                            }
                          } else {
                            if (dependentData) {
                              dependentDataId = dependentData?.find((o) => o.name === _module_script);
                            }
                            history.push(
                              `${historyLocation}/results?id=${
                                dependentDataId?.id ? dependentDataId?.id : exeid
                              }&executionId=${executionId}`
                            );
                          }
                        }
                      }
                    } else {
                      history.push(`${historyLocation}`);
                    }
                  } else {
                    const runId = new URLSearchParams(search).get('runId');
                    if (fullUrl.includes('machines') && clickedBreadcrumbName === scriptData[0].title) {
                      history.push(
                        `${historyLocation}/Result?id=${
                          scriptData[0]?.id ? scriptData[0].id : scriptData[0]?.key
                        }&executionId=${executionId}${
                          runId ? `&runId=${runId}` : ''
                        }&multiple=true&clientId=${clientId}&moduleId=${urlModuleId}`
                      );
                    } else if (
                      clickedBreadcrumbName === _subtreeData?.name ||
                      clickedBreadcrumbName === _subtreeData?.title
                    ) {
                      history.push(`${historyLocation}/results?id=${id}&executionId=${executionId}`);
                    } else if (clickedBreadcrumbName === scriptData[0].title) {
                      history.push(
                        `${historyLocation}/Result?id=${
                          scriptData[0]?.id ? scriptData[0].id : scriptData[0]?.key
                        }&executionId=${executionId}`
                      );
                    } else {
                      history.push(`${historyLocation}/${title}/Result?id=${id}&executionId=${executionId}`);
                    }
                  }
                }
              }
            }
          }
          var suiteNameWithoutSpace = suiteName?.replace(/%20/g, ' ');
          if (clickedBreadcrumbName === 'suite-dashboard') {
            history.push(`${historyLocation}?suiteId=${urlSuiteId}&suiteType=${urlSuiteType}`);
          } else if (clickedBreadcrumbName === 'suite-scheduled-instance') {
            history.push(`${historyLocation}?suiteId=${urlSuiteId}&suiteType=${urlSuiteType}`);
          } else if (clickedBreadcrumbName === suiteNameWithoutSpace && !rootCheck) {
            history.push(`${historyLocation}?suiteId=${urlSuiteId}&suiteType=${urlSuiteType}`);
          }
        } else {
          let _treeData = await getIDB('tree-data');
          executionId = _treeData[0]?.executionId ? _treeData[0]?.executionId : _treeData?.executionId;
          if (type === 'Execution Dashboard') {
            const runId = new URLSearchParams(search).get('runId');
            if (fullUrl.includes('machines')) {
              let dashboard = historyLocation.replace('Root Module', '');
              history.push(
                `${dashboard}Result?executionId=${executionId}&multiple=true&suiteId=${urlSuiteId}&suiteType=${urlSuiteType}&runId=${runId}`
              );
            } else {
              history.push(
                `${historyLocation}/results?executionId=${executionId}&multiple=false&suiteId=${urlSuiteId}&suiteType=${urlSuiteType}&runId=${runId}`
              );
            }
          }
        }
      }
    } else if (historyLocation.includes('/repository/element/shared-elements')) {
      let index = historyLocation.lastIndexOf('/');
      let sharedele = JSON.parse(localStorage.getItem('sharedEle'));
      let breadcrumbLinkName = historyLocation.slice(index + 1, historyLocation.length);
      if (breadcrumbLinkName.includes('_')) {
        keysToCheck.forEach((key) => {
          const value = sharedele[key];
          if (breadcrumbLinkName.includes(value)) {
            breadcrumbLinkName = breadcrumbLinkName.replace(value, '').slice(0, -1);
          }
        });
      }
      let decodedName = decodeSpecialCharacter(breadcrumbLinkName);
      if (historyLocation.split('/')[3] === 'shared-elements' && sharedele.Name === decodedName) {
        history.push(
          `/repository/element/shared-elements/${breadcrumbLinkName}_${sharedele.id}/Shared Element?eleId=${sharedele.id}`
        );
      }
    } else if (historyLocation === '/configuration/template/defecttemplate') {
      history.push('/configuration/template/defecttemplate/defectdetails');
    } else if (historyLocation === '/configuration/import-export') {
      history.push('/configuration/import-export/Import');
    } else if (historyLocation === '/execution') {
      history.push(`/execution/suite`);
    } else if (historyLocation.includes('/dashboard/custom-dashboard/')) {
      let index = historyLocation.lastIndexOf('/');
      let clickedBreadcrumbName = historyLocation.slice(index + 1, historyLocation.length);
      if (!['dashboard', 'custom-dashboard'].includes(clickedBreadcrumbName)) {
        history.push(`/dashboard/custom-dashboard`);
      }
    }
  };

  return (
    <div
      className={
        isDrawerCollapsed
          ? 'breadcrumb-wrapper flex flex-row items-center header-close-breadcrumb'
          : 'breadcrumb-wrapper flex flex-row items-center header-open-breadcrumb'
      }
    >
      <img alt="breadcrumb" src="/assets/images/home_logo.svg" height="18px" width="18px"></img>
      <Breadcrumbs
        mappedRoutes={routes}
        WrapperComponent={(props) => {
          return <ul className="breadcrumb fontPoppinsRegularSm">{props.children}</ul>;
        }}
        ActiveLinkComponent={(props) => (
          <li
            title={props.children.length > 20 ? props.children : null}
            className="active flex flex-row items-center text-xs lg:text-sm xl:text-sm non-clickable-field"
          >
            <img
              src="/assets/images/arrow_right.svg"
              alt="Right Arrow"
              className="flex flex-row mr-1 ml-1"
              height="16px"
              width="16px"
            />
            {clickedBreadcrumb === 'webservices'
              ? 'Web Service'
              : props.children.length > 20
              ? props.children.slice(0, 20) + '...'
              : props.children}
          </li>
        )}
        LinkComponent={(props) => {
          let name = decodeSpecialCharacter(props.children.props.children);
          if (
            ['testData', 'testdevelopment', 'repository'].includes(location.pathname.split('/')[1]) &&
            name.includes('_')
          ) {
            let data;
            if (location.pathname.split('/')[3] === 'shared-elements') {
              data = JSON.parse(localStorage.getItem('sharedEle'));
              keysToCheck.forEach((key) => {
                const value = data[key];
                if (name.includes(value)) {
                  name = name.replace(value, '').slice(0, -1);
                }
              });
            } else {
              data = JSON.parse(localStorage.getItem('parentAndSonJson')) || [];
              data.forEach((obj) => {
                keysToCheck.forEach((key) => {
                  if (obj[key] && name.includes(obj[key])) {
                    if (['testData', 'testdevelopment'].includes(location.pathname.split('/')[1])) {
                      let lastIndex = name.lastIndexOf('_');
                      if (lastIndex === -1) {
                        lastIndex = name.length;
                      }
                      name = name.slice(0, lastIndex);
                    } else {
                      name = name.replace(obj[key], '').slice(0, -1);
                    }
                  }
                });
              });
            }
          }

          let alteredProps = {
            ...props.children,
            props: {
              title: name.length > 25 ? name : null,
              to: props.children.props.to,
              children: name.length > 25 ? name.slice(0, 20) + '...' : name === 'webservices' ? 'Web Service' : name,
            },
          };

          return (
            <li className="flex flex-row items-center breadcrumb_link_color text-xs lg:text-sm xl:text-sm">
              <img
                src="/assets/images/arrow_right.svg"
                alt="Right Arrow"
                className="flex flex-row ml-1 mr-1"
                height="16px"
                width="16px"
              />
              <span
                className="breadcrumb-link"
                onClick={() => {
                  checkUrl(props.children);
                }}
              >
                {' '}
                {alteredProps}{' '}
              </span>
            </li>
          );
        }}
        // Don't create link tag or <Link />. Component will wrapp props.children with <Link />
      />
    </div>
  );
}

export default withRouter(BreadcrumbsList);

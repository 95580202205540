import { colors } from '@src/css_config/colorConstants';

export const TOAST_CONSTANTS = {
  CLOSE_DELAY: 5000,
  TITLE: {
    SUCCESS: 'Success!',
    WARNING: 'Warning!',
    INFO: 'Info!',
    ERROR: 'Error!',
  },
  VARIANTS: {
    SUCCESS: 'success',
    WARNING: 'warning',
    INFO: 'info',
    DANGER: 'danger',
  },
};

export const SIGN_UP = {
  SIGNUP_MESSAGE:
    'Revolutionize your workflow with FireFlink, seamlessly integrating third-party tools and offering versatile cross-platform testing.',
  FF_DESCRIPTION: 'FireFlink: High Speed Testing, Simplified!',
  COPY_RIGHT: 'Copyright © FireFlink Pvt Ltd. All Rights Reserved',
};
export const REPO = {
  WEB_ELEMENT_HEADER: 'Web Elements',
  PROGRAM_ELEMENT_HEADER: 'Program Elements',
  STEP_GROUP_HEADER: 'Step Groups',
  COLLAPSE_ALL_PAGES: 'Collapse All Pages',
  EXPAND_ALL_PAGES: 'Expand All Pages',
  COLLAPSE_ALL_PACKAGES: 'Collapse All Packages',
  EXPAND_ALL_PACKAGES: 'Expand All Packages',
  COLLAPSE_ALL_LIBRARIES: 'Collapse All Libraries',
  EXPAND_ALL_LIBRARIES: 'Expand All Libraries',
  SHOW_ALL_ELEMENTS: 'Show all elements',
  HIDE_ALL_ELEMENTS: 'Hide all elements',
  SHOW_ALL_PROGRAM_ELEMENTS: 'Show all program elements',
  HIDE_ALL_PROGRAM_ELEMENTS: 'Hide all program elements',
  SHOW_ALL_STEP_GROUPS: 'Show all step groups',
  HIDE_ALL_STEP_GROUPS: 'Hide all step groups',
  SHOW_PRE_POST_CONDITION: 'Show Pre/Post Conditions',
  HIDE_PRE_POST_CONDITION: 'Hide Pre/Post Conditions',
  EXPAND_ALL_SCREEN: 'Expand All Screens',
  COLLAPSE_ALL_SCREEN: 'Collapse All Screens',
  NO_PARAMETER_FOUND: 'No Parameter Found',
  MAXIMUM_UPLOAD_ERROR: 'Maximum file(s) can be uploaded: 5',
  FILE_ALREADY_EXIST: 'This file is already selected for this element',
  FILES_DELETED_SUCCESSFULLY: 'Selected file(s) deleted successfully.',
  INVALID_FILE_FORMAT: 'Invalid file type. Only JPG, JPEG, PNG, and SVG are allowed.',
  INVALID_FILE_CONTENT: 'Invalid file content.',
  FILE_NOT_SUPPORTED: 'file not supported.',
  FILES_NOT_SUPPORTED: 'files not supported.',
  PARAMETER: 'Parameter',
  ROOT_ACTION_MENU: [
    {
      name: 'Details',
    },
    // {
    //   name: "History",
    // },
  ],
  PACKAGE_ACTION_MENU: [
    {
      name: 'Details',
    },
    // {
    //   name: "History",
    // },
  ],
  PROGRAM_ACTION_MENU: [
    {
      name: 'Details',
    },
    // {
    //   name: "History",
    // },
  ],
  PACKAGE_PLUS_ICON_MENU: [
    {
      name: 'Add Package',
      value: 'package',
    },
    {
      name: 'Add Program Element',
      value: 'element',
    },
  ],
  PAGE_ACTION_MENU: [
    {
      name: 'Details',
    },
    // {
    //   name: "History",
    // },
    {
      name: 'Clone',
    },
    {
      name: 'Move',
    },
  ],
  ELEMENT_ACTION_MENU: [
    {
      name: 'Details',
    },
    // {
    //   name: "History",
    // },
    {
      name: 'Clone',
    },
    {
      name: 'Move',
    },
  ],
  SHARED_ELEMENT_MENU: [
    {
      name: 'Details',
    },
    {
      name: 'Clone',
    },
  ],
  LIBRARY_ACTION_MENU: [
    {
      name: 'Details',
    },
    // {
    //   name: "History",
    // },
    {
      name: 'Clone',
    },
    {
      name: 'Move',
    },
  ],
  STEPGROUP_ACTION_MENU: [
    {
      name: 'Details',
    },
    // {
    //   name: "History",
    // },
    {
      name: 'Clone',
    },
    {
      name: 'Move',
    },
  ],
  LIBRARY_PLUS_ICON_MENU: [
    {
      name: 'Add Library',
      value: 'library',
    },
    {
      name: 'Add Step Group',
      value: 'stepgroup',
    },
  ],
  FOLDER_ACTION_MENU: [
    {
      name: 'Details',
    },
    // {
    //   name: "History",
    // },
    {
      name: 'Clone',
    },
  ],
  FILE_ACTION_MENU: [
    {
      name: 'Details',
    },
    // {
    //   name: "History",
    // },
    {
      name: 'Clone',
    },
    // {
    //   name: "Replace",
    // },
  ],
  TEST_DATA_PLUS_MENU: [
    {
      name: 'Add Folder',
      value: 'folder',
    },
    {
      name: 'Add File',
      value: 'file',
    },
  ],
};
export const IMAGE_LOCATOR = {
  CHOOSE_IMAGE: 'Choose Image',
  NO_IMAGE_SELECTED: 'No Image Selected',
  LOCAL_PATH: 'Local Path',
  TEST_DATA: 'Test Data',
  SELECT_IMAGE_LP: 'Select Image from Local Path',
  SELECT_IMAGE_TD: 'Select Image from Test Data',
  SELECT_IMAGE: 'Select Image',
  SELECTED_IMAGES: 'Selected Images',
};
export const TEST_DEV = {
  SCRIPT_HEADER: 'Scripts',
  COLLAPSE_ALL: 'Collapse All Modules',
  EXPAND_ALL: 'Expand All Modules',
  SHOW_ALL_SCRIPTS: 'Show all scripts',
  HIDE_ALL_SCRIPTS: 'Hide all scripts',
  SHOW_PRE_POST_CONDITION: 'Show Pre/Post Conditions',
  HIDE_PRE_POST_CONDITION: 'Hide Pre/Post Conditions',
  VALIDATION_VARIABLE_NAME: 'Name is required',
  VALIDATION_VARIABLE_WHITESPACE: 'Space is not allowed at starting and at the end',
  VALIDATION_VARIABLE_ALPHANUMERIC: 'Name should be alphanumeric',
  VALIDATION_VARIABLE_MAX_LENGTH: 'Maximum 25 characters',
  VALIDATION_VARIABLE_MIN_LENGTH: 'Minimum 3 characters',
  VALIDATION_EXISTING_VARIABLE_NAME: 'Name already exists',
  AUTOMATION_BUTTON_LABEL: '+ Automation Script',
  ADD_AUTOMATION_TOOLTIP: 'Click to add Automation Script',
  PARENT_MODULE_PLACEHOLDER: 'Search and select parent module',
  MANUAL_TOGGLE_BUTTON: 'Is this manual test case automatable?',
  NON_AUTOMATED_TOOLTIP: 'This manual test case is marked as non-automatable',
  VARIABLE_BUTTON_TEXT: '+ Variable',
};
export const TEST_DATA = {
  EXPAND_ALL: 'Expand All Folders',
  COLLAPSE_ALL: 'Collapse All Folders',
  SHOW_ALL_ELEMENTS: 'Show all files',
  HIDE_ALL_ELEMENTS: 'Hide all files',
  SHOW_PRE_POST_CONDITION: 'Show Pre/Post Conditions',
  HIDE_PRE_POST_CONDITION: 'Hide Pre/Post Conditions',
};

export const FIRECLOUD_DATA = {
  FIRECLOUD: 'FireCloud',
  FIRE_CLOUD: 'firecloud',
};

let selectedParentPage;
let pageExist = false;
export const checkPageExistParentPage = (tree, parentId, pageName) => {
  pageExist = false;
  if (parentId) {
    if (tree[0].key === parentId) {
      selectedParentPage = tree[0];
    } else {
      checkInChildren(tree[0].children, parentId, pageName);
    }
    return checkPageExistInParent(pageName);
  } else {
    return false;
  }
};
const checkInChildren = (tree, parentId, pageName) => {
  for (var i = 0; i < tree.length; i++) {
    if (tree[i].key === parentId) {
      selectedParentPage = tree[i];
    } else {
      if (tree[i].children) {
        checkInChildren(tree[i].children, parentId);
      }
    }
  }
};
const checkPageExistInParent = (pageName) => {
  if (selectedParentPage && selectedParentPage.children) {
    let children = selectedParentPage.children;
    for (var i = 0; i < children.length; i++) {
      if (children[i].title.toLowerCase() === pageName.toLowerCase() && children[i].folder === true) {
        pageExist = true;
      }
    }
  } else {
    pageExist = false;
  }
  return pageExist;
};

export const trimPath = (path) => {
  let patharray = path.split(',');
  let editedArray = patharray.map((singlepath) => {
    let singleIndex = singlepath.indexOf('t');
    return singlepath.slice(singleIndex + 2, singleIndex.length);
  });
  return editedArray.join(',');
};

export const RESULT = {
  EXPAND_ALL: 'Expand All Modules',
  COLLAPSE_ALL: 'Collapse All Modules',
  SHOW_ALL_SCRIPTS: 'Show all Scripts',
  HIDE_ALL_SCRIPTS: 'Hide all Scripts',
  SHOW_PRE_POST_CONDITION: 'Show Pre/Post Conditions',
  HIDE_PRE_POST_CONDITION: 'Hide Pre/Post Conditions',
};

export const PROJECT = {
  MOBILE_PROJECT: 'Mobile',
  WEB_PROJECT: 'Web',
  WEB_AND_MOBILE_PROJECT: 'Web & Mobile',
  WEBSERVICE_PROJECT: 'Web Service',
  SALESFORCE_PROJECT: 'Salesforce',
  MS_DYNAMICS: 'MS Dynamics',
  STATUS: {
    OPEN: 'Open',
    CLOSE: 'Closed',
    ARCHIVED: 'Archived',
  },
};
export function compare_item_os(a, b) {
  let a1 = Number(a.label);
  let b1 = Number(b.label);
  if (a1 < b1) {
    return -1;
  } else if (a1 > b1) {
    return 1;
  } else {
    return 0;
  }
}
export function compare_item_browser(a, b) {
  let a1 = parseInt(a.label);
  let b1 = parseInt(b.label);
  if (a1 < b1) {
    return -1;
  } else if (a1 > b1) {
    return 1;
  } else {
    return 0;
  }
}

export const WARNING_POPUP_CONSTANTS = {
  HEADER_TITLE: 'Information',
  SUB_HEADER_TITLE: 'Data loss',
  BODY_TEXT: 'Your data will be lost. Are you sure you want to discard the changes?',
  SUB_BODY_TEXT: 'How do you want to proceed?',
  WARNING: 'Warning!',
  REMOVE: 'Remove',
  DELETE: 'Delete',
  ARE_YOU_SURE_YOU_WANT_TO_REMOVE: 'Are you sure you want to remove ',
};

export const DEFECT_INFO_MODAL_CONSTANTS = {
  BODY_TEXT: [
    'Navigate to select and make one template as default.',
    'No template is selected for creating the Defects, so click on Navigate to Defects button and make one template as default.',
  ],
  BUTTON_LABEL: 'Navigate to Defects',
  PATH: '/defect',
};

export const NAVIGATE_TO_INSTANCE_MODAL_CONSTANTS = {
  HEADER_TITLE: 'Navigate to Create Instance',
  BODY_TEXT: ['No instance is been created, click on "Navigate to Create Instance" button to create an instance.'],
  BUTTON_LABEL: 'Navigate to create instance',
};

export const ALERT_MODAL_CONSTANTS = {
  INFO: 'info',
  WARNING: 'warning',
  SUCCESS: 'success',
  ALERT: 'alert',
};

export const GLOBAL_CONSTANTS = {
  APP_TAG_LINE: 'The All-in-One Integrated Testing Platform',
  FORGOT_PASSWORD_MESSAGE: 'Please provide the email address that you used when you  signed up for your account',
  FORGOT_CONFIRM_MESSAGE: 'We will send you an email that will allow you to reset your password',
  PROCEED_TO_SIGN_IN: 'Proceed to Sign In',
  SUCCESS: 'Success',
  USER_ACTIVATED_MESSAGE: 'User is already activated',
  BACK_TO_SIGN_IN: 'Back to Sign in',
  FORGOT_YOUR_PASSWORD: 'Forgot Your Password ?',
  ACTIVATED_MESSAGE:
    'Dear User Your FireFlink account is active. Please visit login page to get started with FireFlink.',
  ACTIVATION_LINK_SENT_SUCCESSFULLY: 'Activation link sent successfully',
  ACTIVATION_LINK_EXPIRED: 'Your activation link has expired!',
  RESEND_ACTIVATION_LINK: 'Resend Activation Link',
  RESEND_ACTIVATION_LINK_SENT_SUCCESSFULLY: 'Resend Activation Link sent successfully',
  CLICK_HERE: 'Click here',
  REGISTER: 'Register',
  ALREADY_HAVE_AN_ACCOUNT: 'Already have an account?',
};

export const ACTIVATION_SUCCESS_CONSTANTS = {
  TITLE: 'The All-in-One Integrated Testing Platform.',
  ACTIVATION_SUCCESS: 'Activation Successful',
  ACTIVATION_MESSAGE: `Welcome aboard upon successful activation of your account, you will receive a confirmation message indicating "${'Activation Successful'}".This message serves as a verification that your account is now active and ready for use.`,
};

export const PASSWORD_CONSTANTS = {
  PASSWORD_CHANGED_SUCCESSFULLY: 'Password changed successfully !',
  NEW_PASSWORD_SET_SUCCESSFULLY: 'New password has been set successfully.',
  PASSWORD_CHANGED_CONFIRM_MESSAGE: 'You can now securely access your account with your new password.',
  SET_PASSWORD: 'Set Password',
  SET_PASSWORD_MESSAGE: 'Enter a new password to reset the password on your account.',
  PASSWORD_MATCHED: 'Password matched',
  SET_PASSWORD_LINK_SENT_SUCCESSFULLY: 'Set Password Link sent successfully',
  SET_PASSWORD_LINK_EXPIRED: 'Your set password link has expired!',
  CHECK_MAIL_FOR_LATEST_LINK: 'Please check your mail box for a latest link or click on',
  RESEND_PASSWORD_LINK: 'Resend Reset Password Link',
};

export const SIGN_IN_CONSTANTS = {
  SIGN_IN: 'Sign In',
  FORGOT_PASSWORD: 'Forgot Password?',
  SIGNING_IN: 'Signing In...',
  DO_YOU_HAVE_ACCOUNT: `Don't have an account?`,
  CREATE_ACCOUNT: 'Create an Account',
  CONTACT_US: 'Contact Us',
  ACCOUNT_LOCKED: 'Account Locked',
};

export const EMAIL_SEND_CONSTANTS = {
  RESET_SENT_SUCCESSFULLY: 'Reset link sent successfully !',
  RESET_LINK_SENT_MESSAGE:
    'An email has been sent to your email address. Kindly check your inbox for reset link and click the reset link provided.',
};

export const ACTIVATION_WARNING_CONSTANTS = {
  NOT_ACTIVATED_MESSAGE:
    'Your account is not yet activated, Click on the activation link which has been sent to your registered email id.',
  WARNING_ACTIVATION: 'Warning Activation',
};
export const PHONE_NUMBER_VALIDATION = {
  MINIMUM_NUMBER: 5,
  MAXIMUM_NUMBER: 15,
  CONFIRM_MESSAGE: 'Please ensure that you have access to your new phone number to complete this process successfully.',
  CHANGE_AND_RESEND_OTP: 'Change & Resend OTP',
  CHANGE_PHONE_NUMBER: 'Change Phone Number',
};

export const VERIFICATION_CODE_CONSTANTS = {
  VERIFICATION_CODE: 'Verification Code',
  VERIFYING: 'Verifying',
  VERIFYING_AND_CONTINUE: 'Verify & Continue',
  RESEND_NEW_CODE: 'Resend a new Code',
  HAVENT_RECEIVED_CODE: "Haven't received your code yet?",
  ENTER_THE_CODE: 'Please enter the code sent on Mobile number to',
};

export const REGISTRATION_CONSTANTS = {
  REGISTRATION_SUCCESSFUL: 'Registration Successful',
  REGISTRATION_RESTRICTED: 'License Limit Exceeded',
  CONGRATULATIONS: 'Congratulations! ',
  REG_SUCCESS_MESSAGE: 'Your registration has been successful. You are now a member of our community',
  WELCOME_ABOARD: 'Welcome aboard!',
  REG_ACTIVATE_MESSAGE: 'To complete your account activation, please check your email and get activated.',
  REG_FAILURE_MESSAGE: 'To create an account, please contact the',
  SUPER_ADMIN: 'Super Admin.',
  THANK_YOU_MESSAGE: 'Thank you for using FireFlink.',
  DEAR_USER:
    'Dear User Your Fireflink account have been reached to the maximum limit. Please visit login page to get started with Fireflink.',
};

export const UNAUTHENTICATED_ROUTES = [
  '/signin',
  '/signup',
  '/forget-password',
  '/otp-verification',
  '/change-password',
];

// Analytics Message
export const NO_DATA_FOUND = 'No data found';
export const ANALYTICS_MESSAGE = {
  NO_DATA_FOUND: 'There are no data found',
  NO_SCRIPTS_FOUND: 'No scripts found',
  scriptsTrendLabel: 'Number of scripts vs Number of days',
};

export const chartFontFamily = 'Poppins-Regular';

export const execChartLabels = ['Passed', 'Fail', 'Warning', 'Skipped'];

export const chartFontStyle = {
  style: {
    fontSize: '10px',
    fontWeight: 500,
    fontFamily: chartFontFamily,
    color: colors.black,
  },
};

export const chartLabels = [
  {
    title: 'Passed',
    color: colors.rs_sussess,
    content: 'Scripts were passed all the time.',
    iconBg: colors.success_iconBg,
  },
  {
    title: 'Failed',
    color: colors.rs_failed,
    content: 'Scripts were failed all the time.',
    iconBg: colors.fail_iconBg,
  },
  {
    title: 'Warning',
    color: colors.rs_waring,
    content: 'Scripts were marked as warning.',
    iconBg: colors.warninig_iconBg,
  },
  {
    title: 'Skipped',
    color: colors.rs_skipped,
    content: 'Scripts were not part of any execution.',
    iconBg: colors.skipped_iconBg,
  },
  {
    title: 'Flaky',
    color: colors.rs_flaky,
    content: 'Scripts were flaky in their behaviour.',
    iconBg: colors.flaky_iconBg,
  },
];

export const noDataMessage = {
  topfailure: 'No scripts found',
  topfailureNote: 'Click on the each bar to get the table data',
  failureAnalysis: 'No scripts found',
  failureReason: 'No scripts found',
  testScriptNotFound: 'No test scripts are found !',
  scriptsNotFoundForFilter:
    'There are no test scripts available for the selected filters, Please select different combination to start reflecting the analytics.',
  scriptsNotCreated: `There are no test scripts has been created, please go to execution
              & create your suite to start reflecting the analytics`,

  defectsNotFound: 'No defect found !',
  defectsNotFoundForFilter:
    'No defects available for the selected filters, Please select different combination to start reflecting the analytics.',
  defectsNotCreated: `No defect has been created, please go to Defects
  & Create your defect to start reflecting the analytics`,
  noResultFound: 'No results found',
  projectNotAvailable: 'No Project Available !',
};

// test Script table constants :: BEGIN

export const noScriptDataFoundMsg = (msgtext) => `There are no ${msgtext} script`;
// test Script table constants :: END

// Execution comaprison constants :: BEGIN
export const recurrentValueLabelRadio = [
  { value: 'recurantPassScripts', label: 'Recurrent Passed' },
  { value: 'recurantFailScripts', label: 'Recurrent Failed' },
  { value: 'recurantWarnScripts', label: 'Recurrent Warning' },
  { value: 'recurantSkipScripts', label: 'Recurrent Skipped' },
  { value: 'flakyScripts', label: 'Flaky' },
  { value: 'unCommonScripts', label: 'Uncommon Scripts' },
];
export const exeCompMSgHead = {
  sectionHead: 'Execution Comparison',
  btnLabel: {
    reset: 'Reset',
  },
  noexeId: 'No Execution Id to Compare',
};

// Execution comaprison constants :: END

export const resultAccordionMsg = {
  skippedSteps: 'No logs are recorded for skipped steps.',
  noLogs: 'No logs are recorded for this step.',
  noLogsHere: 'No logs here.',
  noVarUsed: 'No variables are used in this step.',
  GO_TO_SCRIPT: 'Go to Script',
};

// Defects-Analytics-Module constant and message

export const defectsCardLableStyling = [
  {
    color: colors.blue_text,
    iconBg: colors.blue_bg,
  },
  {
    color: colors.aqua_green,
    iconBg: colors.aqua_green_bg,
  },
  {
    color: colors.rs_failed,
    iconBg: colors.fail_iconBg,
  },
  {
    color: colors.rs_sussess,
    iconBg: colors.green_bg,
  },
  {
    color: colors.rs_flaky,
    iconBg: colors.flaky_iconBg,
  },
];

export const defectAnalyticsLabel = {
  header: {
    LIST_OF_ALL_DEFECTS: 'List of All Defects',
    SEGMENT: 'segment',
    DEFECTS_LOG_FILTER: 'Defect Log Filter',
    SELECT_FILTER: 'Select Filter',
    BUG_SUMMARY: 'BUG SUMMARY',
    DEFECTS_STATISTICS: 'Defects Statistics',
    DEFECTS_CYCLE_TIME: 'Defects Cycle time',
    FILTERS: 'Filters',
    DEFECTS_VS_DAYS: 'Number  of days Vs Number of defects',
  },
  NO_PRIORITY: 'No Priority',
  btnLabel: {
    clearAll: 'Clear All',
    apply: 'Apply',
  },
  label: {
    severity: 'Severity',
    priority: 'Priority',
    module: 'Module',
    status: 'Status',
    NUMBER_OF_DAYS: 'Number of days',
    NUMBER_OF_DEFECTS: 'Number of defects',
  },
};

export const defaultDate = '01/01/1970';
export const lineChartDateFormat = 'dd mmm yyyy';
export const splNlpLength = 2;
export const filterDisplayDateFormat = 'dd MMM yyyy';

export const LICENSE_CONSTANTS = {
  PROFESSIONAL_USER_MSG_TEXT:
    'Buy the Cloud Professional or On Premises - Enterprise plan and get the unlimited features and become a Professional User.',
  EXPLORE_CLOUD_BASIC: 'Explore Cloud Basic Features',
  EXPLORE_CLOUD_PROFESSIONAL: 'Explore Cloud Professional Features',
  PURCHASE_SUMMARY: 'PURCHASE SUMMARY',
  SPECIFICATIONS: 'SPECIFICATIONS',
  COST_PER_QUANTITY: 'COST PER QUANTITY ($)',
  TOTAL_QUANTITY: 'TOTAL QUANTITY',
  TOTAL_COST: 'TOTAL COST ($)',
  TOTAL_AMOUNT: 'TOTAL AMOUNT',
  NET_TOTAL: 'Net Total',
  TAX: 'Tax',
  GRAND_TOTAL: 'Grand Total',
  COUPONS: 'COUPONS',
};
export const LICENSE_MANAGEMENT = {
  PROFESSIONAL_USER_MSG_TEXT:
    'Buy the Cloud Professional or On Premises - Enterprise plan and get the unlimited features and become a Professional User.',
};

export const LICENSE_FEATURES = {
  MANUAL_TESTCASES: 'ManualTestcases',
  DEFECTS: 'Defects',
  WEB_SERVICE: 'WebService',
  WEB: 'Web',
  MOBILE: 'Mobile',
  SALESFORCE: 'Salesforce',
  MS_DYNAMICS: 'MSDynamics',
  ALL: 'All',
};

export const AUTOMATION_SERVICES = ['WebService', 'Web', 'Mobile', 'Salesforce', 'MSDynamics'];
export const PROJECT_TYPES = ['Web Service', 'Web', 'Mobile', 'Web & Mobile', 'Salesforce', 'MS Dynamics'];

export const envData = [
  {
    value: 'Web',
    label: 'Web',
  },
  {
    value: 'Android',
    label: 'Android',
  },
  {
    value: 'iOS',
    label: 'iOS',
  },
  {
    value: 'Mobile',
    label: 'Mobile',
  },
  {
    value: 'MobileWeb',
    label: 'Mobile Web',
  },
];
export const TEST_ENV_TABLE_WIDTH_SET = [20, 40, 60, 80, 100, 120, 140, 160, 180, 200, 220, 240, 300, 360, 400];

export const IMPORT_EXPORT_CONSTANTS = {
  IMPORT_ERROR_MESSAGE: 'Choose at least one entity to import',
  EXPORT_ERROR_MESSAGE: 'Choose at least one entity to export',
  IMPORT_WARNING_MESSAGE: ['The same project cannot be imported.', 'Project does not match'],
  IMPORT: 'Import',
  IMPORTING: 'Importing...',
  EXPORT: 'Export',
  EXPORTING: 'Exporting...',
  EXPORT_COMPLETE_PROJECT: 'Export Complete Project',
  EXPORT_SELECTED_RESOURCES: 'Export Only Selected Resources',
  IMPORT_COMPLETE_PROJECT: 'Import Complete Project',
  IMPORT_SELECTED_RESOURCES: 'Import Only Selected Resources',
  SELECT_PLACEHOLDER: 'Search and Select Project',
  IMPORT_FROM: 'Import From',
  EXPORT_API_FAILURE: 'Export Project API failure',
  IMPORT_API_FAILURE: 'Import Project API failure',
  UPLOAD_FILE_FAILED: 'Failed to Upload File(s)',
  SELECT_PROJECT: 'Select Project',
  IMPORT_MULTI_THREAD_MESSAGE:
    'The selected project is currently initialized under Import operation in other machine or browser',
  PROJECT_EXPORTED_SUCCESSFULLY: 'project exported successfully',
  PLEASE_SELECT_VALID_ZIP_FILE: 'Please select a valid zip file',
};

export const SCRIPT_STEPS = {
  SELECT_STEPS: 'Select Steps',
  DELETE_SELECTED_STEPS: 'Delete Selected Steps',
  SUCCESS_MESSAGE: 'Steps Deleted Successfully',
  DELETE_MESSAGE: 'All the selected steps will be deleted.',
  CONFIRM_MESSAGE: 'Are you sure that you want to delete all the selected steps ?',
  DESCRIPTION: 'Description',
  ACTIONS: 'Actions',
  STEPS: 'Steps',
  RESULT: 'Result',
  STATUS: 'Status',
};

export const scriptIfFailedCheckpoints = [
  {
    name: 'Mark this step as Failed and continue script execution',
    value: 'MARK_THIS_STEP_AS_FAILED_AND_CONTINUE_SCRIPT_EXECUTION',
  },
  {
    name: 'Mark this step as Warning and continue script execution',
    value: 'MARK_THIS_STEP_AS_WARNING_AND_CONTINUE_SCRIPT_EXECUTION',
  },
  {
    name: 'Mark this step as Failed and stop script execution',
    value: 'MARK_THIS_STEP_AS_FAILED_AND_STOP_SCRIPT_EXECUTION',
  },
  {
    name: 'Mark this step as Failed and stop current module execution',
    value: 'MARK_THIS_STEP_AS_FAILED_AND_STOP_CURRENT_MODULE_EXECUTION',
  },
  {
    name: 'Mark this step as Failed and stop complete execution',
    value: 'MARK_THIS_STEP_AS_FAILED_AND_STOP_COMPLETE_EXECUTION',
  },
];
export const stepGroupIfFailedCheckpoints = [
  {
    name: 'Mark this step as Failed and continue Step Group execution',
    value: 'MARK_THIS_STEP_AS_FAILED_AND_CONTINUE_STEP_GROUP_EXECUTION',
  },
  {
    name: 'Mark this step as Warning and continue Step Group execution',
    value: 'MARK_THIS_STEP_AS_WARNING_AND_CONTINUE_STEP_GROUP_EXECUTION',
  },
  {
    name: 'Mark this step as Failed and stop Step Group execution',
    value: 'MARK_THIS_STEP_AS_FAILED_AND_STOP_STEP_GROUP_EXECUTION',
  },
];

export const couponContract = {
  couponCodes: [
    {
      code: 'FF100',
      discount: 100,
      data: 'Coupon Applied',
    },
    {
      code: 'FF200',
      discount: 200,
      data: 'Coupon Applied',
    },
  ],
};

export const LICENSE_DETAILS_CONSTANTS = {
  NAME: 'Name',
  AUTO_ACTIVATION: 'This subscription will be activated automatically once the current subscription expires.',
  TYPE: 'Type',
  FEATURES: 'Features',
  STATUS: 'Status',
  NOT_ACTIVE: 'Not Active',
  MORE_DETAILS: 'More Details',
  TITLE: 'License Details',
  UPCOMING_PLAN: 'Upcoming License Plan',
  UPCOMING_PLAN_DETAILS: 'Upcoming License Plan Details',
  LICENSE_NAME: 'License Name',
  MY_PRIVILEGE: 'My Privilege',
  PARALLEL_RUNS: 'Number of Parallel Runs',
  STORAGE: 'Storage',
  TOTAL_AMOUNT_PAID: 'Total Amount Paid',
  PURCHASED_BY: 'Purchased By',
  PURCHASED_ON: 'Purchased On',
  ACTIVATED_ON: 'Activated On',
  ACTIVATES_ON: 'Activates On',
  EXPIRING_ON: 'Expiring On',
  SUBSCRIPTION_EXPIRES_IN: 'Subscription expires in',
  SUBSCRIPTION_EXPIRED: 'Subscription has expired',
  SUBSCRIPTION_EXPIRING_TODAY: 'License will be expiring today',
  CONTACT_SUPER_ADMIN: 'For support contact Super Admin of the license',
  LICENSE_DELETED_PERMANENTLY: 'license will be deleted permanently.',
  UPGRADE: 'Upgrade',
  RENEW: 'Renew',
  REMIND_ME_LATER: 'Remind Me Later',
  WEBSERVICE: 'Web Service',
  WEB: 'Web',
  MOBILE: 'Mobile',
  SALESFORCE: 'Salesforce',
  MSDYNAMICS: 'MS Dynamics',
  MANUALTESTCASEMANAGEMENT: 'Manual TestCase Management',
  DEFECTS_MANAGEMENT: 'Defects Management',
  AUTOMATION: 'Automation',
  FREE_LICENSE: 'Free License',
  SELECT_LICENSE: 'Select License',
  SELECT_LICENSE_NAME_FROM_OPTIONS: 'select the license name from the below option to keep using this application.',
  SUBSCRIPTION_HAS_EXPIRED: 'Subscription is expired. If not ',
  SUBSCRIPTION_EXPIRING_IN: 'Subscription expiring in',
  BACK: 'Back',
  DONE: 'Done',
  CONTINUE: 'Continue',
  RETAIN_DATA_TEXT: 'data will be retained.',
  DATA_LOSS_TEXT: 'data will be deleted permanently.',
  AUTOMATION_TEXT: 'Automation Data related to',
  RETAIN_FEATURES: 'All your existing',
  AND: 'and',
  HOW_TO_PROCEED: 'How do you want to proceed ?',
  WARNING_TEXT: 'Warning!',
  WEB_MOBILE: 'Web & Mobile',
  PROJECTS: 'Projects',
  PARALLEL_RUNS_DISPLAY: (parallelRuns, hasAutomationServices) =>
    !hasAutomationServices ? '' : parallelRuns <= 1 ? 'Parallel Run' : 'Parallel Runs',
  RENEW_STORAGE_NOTE: (current, used) =>
    `Note : The current plan of ${current} GB storage will include ${used} of storage for existing data`,
  DAYS: 'days',
  FIREFLINK__PLATFORM: 'FireFlink Platform',
  PAYMENT_SUCCESSFUL: 'Payment Successful',
  PAYMENT_FAILED: 'Payment Failed',
  PAYMENT_PENDING: 'Payment Pending',
  THANK_YOU_TEXT_C_BASIC: 'Thank you for choosing FireFlink!',
  START_YOUR_JOURNEY: 'Start your FireFlink journey now with our free license!',
  CONGRATULATIONS_YOUR_FREE_TRIAL:
    'Congratulations, your free trial license is now activated. Dive into our world of testing and start your 90 day trial journey with ',
  FROM_TODAY_CLICK_ON: 'from today.Click on ',
  TO_SWITCH_TO_YOUR: 'to switch to your ',
  THANK_YOU_FOR_CHOOSING: 'Thank you for choosing ',
  FIREFLINK: 'Fireflink',
  THANK_YOU_FOR_PAYMENT: 'Thank you for the payment. Transaction details are included below.',
  FREE: 'Free',
  PAYMENT_MODE: 'Payment Mode',
  CREDIT_DEBIT_CARD: 'Credit Card / Debit Card',
  ORDER_ID: 'Order Id',
  TRANSACTION_TIME: 'Transaction Time',
  NO_LICENSE_FOUND: 'No License Found',
  DEFAULT_AND_SWITCH_LICENSE: 'Please enter your email and password to change the default license to ',
  LICENSE_HISTORY: 'License History',
  TO_CHANGE_YOUR_LICENSE: 'To change your license from',
  PLEASE_ENTER_YOUR_EMAIL_AND_PASSWORD: ' please enter your email address and password below.',
  SWITCH_LICENSE_WITH_EASE: 'Switch Licenses With Ease',
  SEAMLESSLY_SWITCH_LICENSE: 'Seamlessly switch between licenses with your authentication details.',
  DATE_AND_TIME: 'Date And Time',
  TRANSACTION_AMOUNT: 'Transaction Amount',
  TRANSACTION_ID: 'Transaction Id',
  PAYMENT_CURRENTLY: 'Your payment is currently ',
  WE_ARE_PROCESSING_YOUR_TRANSACTION: ' We’re processing your transaction. Thank you for your patience.',
  PAYMENT_FAILED_TEXT: ' It seems the payment didn’t go through.',
  PLEASE_CHECK_YOUR_PAYMENT: ' Please check your payment details and try again.',
  PAYMENT_SUCCESSFUL_TEXT: ' Your payment was successful. ',
  THANK_YOU_FOR_PURCHASE: 'Thank you for your purchase.',
  TO_CHANGE_YOUR_LICENSE_TO: 'To change your license to ',
  PLEASE_ENTER_YOUR_PASSWORD: 'please enter your password below',
  PLEASE_ENTER_YOUR_PASSWORD_TO_DEFAULT_LICENSE: 'Please enter your password to change the default license to ',
  PAYMENT_CANCELLED: 'Payment Cancelled',
  YOUR_PAYMENT_HAS_BEEN_CANCELLED: 'Your payment has been canceled, Please click ',
  TO_CONTINUE_PAYMENT_PROCESS: ' to continue the payment process',
  LICENSE: 'License',
  PROJECT: 'Project',
};

export const LICENSE_EXPIRED_CONSTANTS = {
  TITLE: 'License Expiration',
  ALERT: 'Alert',
  LICENSE_EXPIRING_SOON: 'your license is expiring soon',
  CONTACT_SUPER_ADMIN: 'Please contact your super admin quickly',
  CONTACT_YOUR_SUPER_ADMIN_TO: 'Contact your Super Admin to',
  LICENSE_EXPIRED: 'License Expired',
  LICENSE_WILL_EXPIRE: 'License will be expiring',
  LICENSE_EXPIRED_RENEW: 'The license has Expired.Please renew it.',
  LICENSE_EXPIRED_UPGRADE: 'The license has Expired.Please upgrade it.',
  EXPIRED: 'EXPIRED',
  DISABLED: 'DISABLED',
  ACTIVE: 'ACTIVE',
  LICENSE_ABOUT_TO_EXPIRE: 'Your license is about to expire.',
  FREE_TRIAL_ABOUT_TO_EXPIRE: 'Your Free Trial is about to expire.',
  ENJOY_UNINTERRUPTED_SERVICE: 'now to continue enjoying uninterrupted service.',
  LICENSE_HAS_EXPIRED: 'Your license has expired. Please ',
  TO_CONTINUE_USING_APP_OR_SWITCH: ' it to continue using the application',
  CLICK_ON: `Click on `,
  MULTIPLE_LICENSE: 'as you have multiple license options to choose from',
  CLICK_ON_RENEW_TO_CONTINUE:
    'Your license has expired. Click on Renew and renew the license for continue uses of this application.',
  FOR_SUPPORT_CONTACT_SUPER_ADMINS: 'For support, please reach out to the Super Admins of your license.',
  SUBSCRIPTION_EXPIRED_IF_NOT_RENEWED: (text) => `Your subscription has expired. If not ${text} within `,
  YOUR_LICENSE_WILL_BE_DELETED: ' your license will be permanently deleted.',
  TO_CONTINUE_USING: 'To continue using the application, please select a license from the options provided below.',
  LICENSE_EXPIRED_PLEASE_UPGRADE: 'Your Free Trial has expired. Please',
  LICENSE_EXPIRED_CONTACT_SUPER_ADMIN: 'Your license has expired. Contact your Super Admin to ',
  FREE_TRIAL_EXPIRY_TEXT_ADMIN: 'Your Free Trial is expiring soon. Contact your Super Admin to  ',
  LICENSE_FOR_UNINTERRUPTED_SERVICES: ' license for uninterrupted service.',
  OR_SWITCH_LICENSE: ' or Switch License.',
  FREE_TRIAL_EXPIRED_ADMIN: 'Your Free Trial is expired. Contact your Super Admin to ',
  THIS_LICENSE_HAS_EXPIRED: 'This license is expired.',
};

export const PROJECT_TABPERMISSION_SECTIONS = {
  ANALYTICS: 'Analytics',
  CONFIGURATION: 'Configuration',
  DASHBOARD: 'Dashboard',
  DEFECTS: 'Defects',
  REPOSITORY: 'Repository',
  TEST_DEVELOPMENT: 'TestDevelopment',
  TEST_DATA: 'TestData',
  TEST_EXECUTION: 'TestExecution',
};

// DashBoard Component constants :: START
export let projectTypesChartDataLabelColors = {
  labels: ['Web Service', 'Web', 'Mobile', 'Web & Mobile', 'Salesforce', 'MS Dynamics'],
  colors: [
    colors.red_dark,
    colors.grey_dark,
    colors.yellow_dirty,
    colors.blue_bright,
    colors.blue_saleforce,
    colors.cyan,
  ],
};

// DashBoard Component constants :: END
export const SIDE_NAVIGATION_CONSTANTS = {
  HIDE_MENU_LIST: [
    'Repository',
    'Project Menu',
    'Test Data',
    'Test Development',
    'Results',
    'Execution',
    'Defects',
    'Web Service Workbench',
    'Database Workbench',
    'NLP Help',
    'Analytics',
  ],
  PATHS: [
    'project-config',
    'repository',
    'user-individual',
    'role-individual',
    'execution',
    'testData',
    'defect',
    'analytics-menu',
    'testdevelopment',
    'configuration',
    'dashboard',
    'licenses',
  ],
  PROJECTS_MENU: 'Projects Menu',
  ALL_PROJECTS: 'All Projects',
  WEB_SERVICES: 'webservices',
  WEB_SERVICE_DATA: 'webserviceData',
  WEB_SERIVCE_TAB: 'webserviceTab',
  REPOSITORY: 'Repository',
  PROJECT_MENU: 'Project Menu',
  TEST_DATA: 'Test Data',
  TEST_DEVELOPMENT: 'Test Development',
  RESULTS: 'Results',
  EXECUTION: 'Execution',
  DEFECTS: 'Defects',
  WEB_SERVICE_WORKBENCH: 'Web Service Workbench',
  DATABASE_WORKBENCH: 'Database Workbench',
  NLP_HELP: 'NLP Help',
  ANALYTICS: 'Analytics',
  DASHBOARD: 'Dashboard',
  CONFIGURATION: 'Configuration',
  RUN_CONFIGURATION: 'Run Configuration',
  CONFIG: 'config',
  ENVIRONMENT_CONFIGURATION: 'Environment Configuration',
  WAIT_CONFIGURATION: 'Wait Configuration',
  SCREENTSHOT_AND_VIDEO_CONFIG: 'Screenshot & video Configuration',
  RESULT_CONFIGURATION: 'Result Configuration',
  VARIABLES: 'Variables',
  LABELS: 'Labels',
  INTEGRATIONS: 'Integrations',
  EMAIL_CONFIGURATION: 'Email Configuration',
  MEMORY_CONFIGURATION: 'Memory Configuration',
  DEFECT_MANAGEMENT: 'Defect Management',
  IMPORT_AND_EXPORT: 'Import & Export',
  TIME_ZONE: 'Time Zone',
  TEMPLATES: 'Templates',
  TYPE_TEMPLATE: 'template',
  MANUAL_TEMPLATE: 'Manual Test Case Templates',
  DEFECT_TEMPLATE: 'Defect Template',
  TRUE: 'true',
  FALSE: 'false',
  ACTION_EXIT: 'exit',
  TEST_DEV_ACCESS: 'testDevelopment',
  LICENSES: 'Licenses',
  ALLOWED_PROJECTS: 'allowedProjects',
  SELECTED_PROJECT: 'selected-project',
  PATH_PROJECT_CONFIG: 'project-config',
  PATH_TEST_DEV: 'testdevelopment',
  PATH_REPOSITORY: 'repository',
  PATH_USER_INDIVIDUAL: 'user-individual',
  PATH_ROLE_INDIVIDUAL: 'role-individual',
  PATH_EXECUTION: 'execution',
  PATH_TEST_DATA: 'testData',
  PATH_DEFECT: 'defect',
  PATH_ANALYTICS_MENU: 'analytics-menu',
  PATH_CONFIGURATION: 'configuration',
  PATH_DASHBOARD: 'dashboard',
  PATH_LICENSES: 'licenses',
  NO_ACCESS: 'NoAccess',
  OPEN_OPTIONS: 'Open options',
  SETTINGS: 'Settings',
};

export const MASK_DATA = {
  VARIABLE_MASK_DATA: '*****',
};

export const TIME_ZONE = {
  CONFIG_TIME_ZONE: 'Configure Time Zone For License',
  CONFIG_TIME_ZONE_PROJECT: 'Configure Time Zone For Projects',
  CONFIRM: 'Confirm',
  CANCEL: 'Cancel',
  DATA_LOST: 'The changes will apply to all projects except those',
  CONFIGURED: 'configured',
  PROJECT_LEVEL: 'at the project level.',
  CONFIRM_MESSAGE: 'Are you sure you want to change the TimeZone?',
  CHANGE_IN_TIMEZONE: 'Change in Timezone ',
  WARNING: 'Warning!',
};

export const PLUGIN_CONSTANTS = {
  CLOUD_PLATFORMS: 'cloudPlatform',
  CI_CD_TOOLS: 'CICD',
  MANAGEMENT_TOOLS: 'projectManagement',
  COLLABORATION_TOOLS: 'collaborationTools',
};

export const ROLES_CONSTANTS = {
  FULL_ACCESS: 'Full Access',
  VIEW_ACCESS: 'View Access',
  EDIT_MESSAGE: 'Default role cannot be edited',
  DELETE_MESSAGE: 'Default role cannot be deleted',
  PROJECT_LEVEL_EDIT_MESSAGE: 'Role from all projects cannot be edited',
  PROJECT_LEVEL_DELETE_MESSAGE: 'Role from all projects cannot be deleted',
};

export const ACTIONS = {
  ADD: 'Add',
  EDIT: 'Edit',
  DELETE: 'Delete',
  SCHEDULE: 'Schedule',
  DETAILS: 'Details',
  CLONE: 'Clone',
};
export const MEMORYCONFIG_CONSTANTS = {
  TOTAL_MEMORY: 'Total Memory',
  AVAILABLE_MEMORY: 'Available Memory',
  ASSIGNED_MEMORY: 'Assigned Memory',
  USED_MEMORY: 'Used Memory',
  TYPE: 'Type',
  DISTRIBUTE_MEMORY: 'Distribute Memory',
  CARD_HEADER: 'Project Memory Configuration',
  NO_PROJECTS: ' No Projects Found !',
  WARNING: 'Warning!',
  DELETE: 'Delete',
  DATA_LOST: 'Your data will be lost. Are you sure you want to delete',
  PROJECT: ' project ?',
  PROJECT_NAME: 'Project Name',
  PROCEED: 'How do you want to proceed?',
  HI: 'Hi',
  ASSIGNED_MEMORY_CANNOT:"Assigned Memory must be greater than zero",
  AVAILABLE_MEMORY_VALIDATION: 'Assigned memory cannot be less than used memory',
  USED_MEMORY_VALIDATION: 'Total assigned memory cannot be less than used memory',
  CANCEL: 'Cancel',
  UPDATE: 'Update',
  EDIT: 'Edit',
  OPEN_PROJECTS: 'Open projects can not be deleted',
  DETAILS: 'Details',
  MEMORY_FULL:
    'If the available project memory limit is Exceeded, older Execution ID’s data will be deleted to Accommodate new ',
  MEMORY_FULL_USER:
    'If the Available project memory limit is exceeded, older Execution ID’s data will be Deleted to accommodate news screenshots or videos. ',
  CONTACT_YOUR: 'Contact your ',
  HOW_DO_YOU_WANT_TO_PROCEED: 'How do you want to proceed?',
  TO_MANAGE: 'to manage the',
  SCREENSHOTS_OR_VIDEOS: 'screenshots or videos',
  MEMORY_LIMIT: 'Memory Limit',
  MANAGE_MEMORY: 'Manage Memory',
};
export const PROJECTDETAILS_CONSTANTS = {
  DETAILS: 'ProjectDetails',
  NAME: 'Name',
  TYPE: 'Type',
  DESCRIPTION: 'Description',
  STATUS: 'Status',
  BASEURI: 'Base URI',
  PORT: 'Port',
  APPTYPE: 'AppType',
  PLATFORM: 'Platform',
  APPPACKAGE: 'App Package',
  APPACTIVITY: 'App Activity',
  BUNDLEID: 'Bundle Id',
  WEBURL: 'Web Url',
  CREATEDBY: 'Created By',
  CREATEDON: 'Created On',
  MODIFIEDBY: 'Modified By',
  MODIFIEDON: 'Modified On',
  CLOSE: 'Close',
  USER_ROLE_MAPPING: 'User Role Mapping',
  DEFECT_MANAGEMENT: 'Defect management',
  PROJECT_DETAILS: 'Project Details',
  TIME_ZONE: 'Time Zone',
};
export const CBasicFeatures = ['Web', 'Mobile', 'WebService', 'Salesforce', 'MSDynamics', 'ManualTestcases', 'Defects'];
export const AutomationFeatures = ['Web', 'Mobile', 'WebService', 'Salesforce', 'MSDynamics'];

export const LABELS_CONSTANTS = {
  LABELS: 'Label',
  NAME: 'Name',
  NAME_PLACEHOLDER: 'Enter label name',
  DESCRIPTION: 'Description',
  DESCRIPTION_PLACEHOLDER: 'Your description goes here...',
  DETAILS: 'Details',
  EDIT: 'Edit',
  DELETE: 'Delete',
  PREDEFINED_LABEL_EDIT: 'Predefined Labels Cannot be Edited',
  PREDEFINED_LABEL_DELETE: 'Predefined Labels Cannot be Deleted',
  LABEL_DETAILS: 'Label Details',
  CLOSE: 'Close',
  LABEL_NAME: 'Label Name',
  LABEL_TYPE: 'Label Type',
  FROM: 'From',
  CREATED_BY: 'Created By',
  CREATED_ON: 'Created On',
  MODIFIED_BY: 'Modified By',
  MODIFIED_ON: 'Modified On',
  LABELS_FROM: 'Projects',
  CREATE_LABEL: 'Create Label',
  EDIT_LABEL: 'Edit Label',
  UPDATE: 'Update',
  CREATE: 'Create',
  LABEL_CREATED: 'label created succesfully',
  LABEL_UPDATED: 'label updated succesfully',
  LABEL_DELETED: 'label deleted succesfully',
  WARNING: 'Warning!',
  DATA_LOST: 'Are you sure you want to delete',
  DATA_LOST_UNRECOVERED: 'Once deleted Cannot be recovered',
  PROCEED: 'How do you want to proceed?',
  CANCEL: 'Cancel',
  USER_DETAILS: 'User Details',
  EMAIL_ADDRESS: 'Email Address',
  PRIVILEGE: 'Privilege',
  PHONE_NUMBER: 'Phone Number',
  STATUS: 'Status',
  EMAIL: 'Email',
};
export const defaultStepGroupList = [
  'CloseBrowser',
  'OpenBrowser',
  'Close Android App',
  'Open Android App',
  'Open Android or iOS App',
  'Close Android or iOS App',
  'Close iOS App',
  'Open iOS App',
];
// Server Down-time content

export const serverDownTimeMessage = {
  content:
    'Our server is currently undergoing maintenance to enhance your experience. We apologize for any inconvenience this may cause. Please check back shortly.',
  path: 'https://www.fireflink.com/contactus',
  footerContent: 'Will be Back Soon!',
};

export const PAYMENT_HISTORY_CONSTANTS = {
  TRANSACTION_ID: 'Transaction Id',
  LICENSE_NAME: 'License Name',
  PAYMENT_STATUS: 'Payment Status',
  PAYMENT_MODE: 'Payment Mode',
  TRANSACTION_TIME: 'Transaction Time',
  TRANSACTION_AMOUNT: 'Transaction Amount',
  NO_PAYMENT_HISTORY: 'No Payment History',
  PAYMENT_HISTORY: 'Payment History',
  PAYMENT_MADE_BY: 'Payment Made By',
};
//Integrations

export const AZURE_DEVOPS_CONSTANTS = {
  AZURE_DEVOPS: 'Azure DevOps',
  INSTANCE: ' + Instance',
  TO_START_ADDING_ACCOUNT: 'to start adding Account',
  QUICK_START: 'Quick Start',
  CLICK: 'Click',
  STEP_1: 'Step 1',
  DETAILS: 'Details',
  INSTANCE_NAME: 'Instance Name',
  CREATE_INSTANCE: 'Create instance',
  EDIT_INSTANCE: 'Edit instance',
  SUITE_NAME: 'Suite Name',
  PROJECT_NAME: 'Project Name',
  CREATED_BY: 'Created by',
  CREATED_ON: 'Created On',
  MODIFIED_BY: 'Modified By',
  MODIFIED_ON: 'Modified On',
  DATA_CENTER: 'Data Center',
  CLOSE: 'Close',
  CANCEL: 'Cancel',
  CREATE: 'Create',
  UPDATE: 'Update',
  INSTANCE_DETAILS: 'Instance Details',
};

export const MTC_ERROR_CONSTANTS = {
  ERROR_NOTE_HEADER: 'Errors in Uploaded test case file',
  ERROR_NOTE_FOOTER: 'Note: Test cases that contain errors will not be imported, how do you want to proceed ?',
  IMPORT_PROGRESS_NOTE: 'The import will continue even if the pop-up is closed.',
};

//Map to Jira
export const MAP_TO_JIRA = {
  CLONE_TO_JIRA: 'Map to Jira',
  WANT_TO_MAP: 'What do you want to Map ?',
  MANUAL_TEST_CASE: 'Manual Test Case',
  AUTOMATION_SCRIPT: 'Automation Script',
  DOMAIN: 'Domain',
  PROJECT: 'Project',
  JIRA_ID: 'Jira ID',
  CLONE: 'Clone',
  MAP: 'Map',
  CANCEL: 'Cancel',
};

export const TREE_LABELS = {
  EXPAND_ALL: 'Expand All',
  COLLAPSE_ALL: 'Collapse All',
  SHOW_ALL_ELEMENTS: 'Show all scripts',
  HIDE_ALL_ELEMENTS: 'Hide all scripts',
};

export const getSelectedFilterLableName = (name) => {
  switch (name) {
    case 'testcase':
      return 'Test case';
    case 'createdBy':
      return 'Created By';
    case 'modifiedBy':
      return 'Modified By';
    case 'assignTo':
      return 'Assign To';
    case 'suiteCaseType':
      return 'Suite Type';
    case 'caseType':
      return 'Test Case Type';
    case 'scriptType':
      return 'Script Type';
    default:
      return name;
  }
};
export const EXECUTION_CONSTANTS = {
  SUITE: 'Suite',
  MODULES_SCRIPTS_LABLE: 'Modules / Scripts',
  DISTRIBUTE: 'DISTRIBUTE',
  SEND_SUITE_TO_ALL: 'SEND_SUITE_TO_ALL',
  SCHEDULE_EXPIRY_DATE_VALIDATION_WARNING: 'Expiry Date should not be less than Schedule Date',
  MAKE_EXEC_SETTINGS_DEFAULT: 'Make Only Execution settings as default',
  EXECUTION_TERMINATE_DATA_REQUIRED_MSG: 'Execution Termination data is required',
  WAIT_TIME_DATA_REQUIRED_MSG: 'Wait Time data is required',
  ASSIGN_MACHINE_ALL_MODULE_MSG: 'Please assign machine/s for all the modules',
  SELECT_USER_MSG: 'Please Select User',
  SELECT_MACHINE_MSG: 'Please Select Machine',
  SCHEDULE_DATE_TIME_VALIDATION_MSG: 'Schedule Date/Time should not be less than the current  Date/Time',
  RUN_SETTING_MODAL_SCRIPT_TREE_MODIFICATION_WARNING:
    'Note: The following changes are applicable only for this execution',
  RUN_SETTING_MODAL_NO_SCRIPT_SELECTED_WARNING: 'Note: Please Select Modules / Scripts',
  SUITE_ACCESS_CHANGE: 'The suite with the private access will be changed to public access.',
  IF_UNCHECKED: 'If unchecked, the suite created by',
  WILL_BE_DELETED: 'will be deleted',
  SCHEDULE_TIME_VALIDATION_CHECK: 'Select Schedule Time / Expire Time',
  MAXIMUM_RUNS: `Number of runs should not be more than`,
};
export const DEFECT_CONSTANTS = {
  EDIT_PLATFORM: 'Edit Platform',
  SELECT_PLATFORM: 'Select Platform',
  NA: 'N/A',
  PROJECT_NAME: 'Project Name',
  PROJECT_TYPE: 'Project Type',
  PLATFORM: 'Platform',
  CANCEL: 'Cancel',
  UPDATE: 'Update',
  SELECT: 'Select',
  DETAILS: 'Details',
  CLOSE: 'Close',
  DEFECT_MANAGEMENT: 'Defect Management',
  PROJECT_ROLE_MAPPING: 'Project Role Mapping',
  NO_DEFECTS_MESSAGE: 'No Defects Available !',
  CREATE_DEFECT: 'Create Defect',
  DEFECTS: 'Defects',
  DRAWER_WIDTH: '50vw',
  USER_DETAILS_DRAWER_WIDTH: '40vw',
  PROJECT_DETAILS_DRAWER_WIDTH: '40vw',
  CREATE_ISSUE: 'Create Issue',
  UPDATE_ISSUE: 'Update Issue',
  USER_DETAILS: 'User Details',
  NOT_CONFIGURED: 'Not Configured',
  INFO: 'info',
  WARNING: 'warning',
  CREATE_INSTANCE_BTN_LABEL: 'Navigate to create instance',
  ISSUE_CREATED_SUCCESS: 'Defect created successfully',
  MAX_CHARACTER_VALIDATION: 'cannot be more than 250 characters',
  MIN_CHARACTER_VALIDATION: 'cannot be less than 3 characters',
  MIN_LENGTH_ERR_MSG: (fieldName, minLength) => `${fieldName} cannot be less than ${minLength} characters`,
  MAX_LENGTH_ERR_MSG: (fieldName, maxLength) => `${fieldName} cannot be more than ${maxLength} characters`,
  CUSTOM_MODAL: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    border: 'none',
  },
  ISSUE_DATA_SECTION_RESULT_TREE: 'resultTree',
  ISSUE_DATA_SECTION_RESULT_STEPS: 'resultSteps',
  SLACK: 'Slack',
  GITLAB: 'Gitlab',
};
export const DASHBOARD_CONSTANTS = {
  PROJECT_DASHBOARD: 'Project Dashboard',
  ANALYTICS: 'Analytics',
  DESCRIPTION: 'Description',
  PERMISSION: 'Permission',
  DASHBOARD_PERMISSION: 'Dashboard Permission',
  NO_ACCESS: 'No Access',
  VIEW: 'View',
  EDIT: 'Edit',
  FULL_ACCESS: 'Full Access',
  NAME: 'Name',
  DATA_LOST: 'Your data will be lost. Are you sure you want to delete',
  WARNING: 'Warning',
  DELETE: 'Delete',
  HOW_TO_PROCEED: 'How do you want to proceed ?',
  DASHBOARD_DELETE: 'dashboard?',
  CANCEL: 'Cancel',
  CLOSE: 'Close',
  UPDATE: 'Update',
  CREATE: 'Create',
  EDIT_DASHBOARD: 'Edit DashBoard',
  CREATE_DASHBOARD: 'Create DashBoard',
  QUICK_START: 'Quick Start',
  STEP_1_CLICK: 'step1 click',
  STEP_1: 'step1 :',
  CLICK: 'Click',
  DASHBOARD: 'Dashboard',
  START_CREATING: 'to start creating the Dashboard',
  START_CREATING_GRAPH: 'to start Customize Graph',
  CUSTOM_DASHBOARD_LIST: 'Custom Dashboard List',
  CREATED_BY: 'CreatedBy',
  CREATED_ON: 'CreatedOn',
  MODIFIED_BY: 'ModifiedBy',
  MODIFIED_ON: 'ModifiedOn',
  STATUS: 'Status',
  PRIVILEGE: 'Privilege',
  EMAIL_ADDRESS: 'Email Address',
  ADD_CUSTOMISE_GRAPH: 'Add Customise Graph',
  EDIT_CUSTOM_GRAPH: 'Edit Customise Graph',
  BAR_GRAPH: 'Bar Graph',
  PIE_CHART: 'Pie chart',
  SELECT_OPTION_1: 'Select Option 1',
  SELECT_OPTION_2: 'Select Option 2',
  SELECT_USERS: 'Select Users',
  CUSTOMISE_GRAPH: ' Customise Graph',
  FILTERS: 'Filters',
  TIME_PERIOD: 'Time Period',
  DOWNLOAD: 'Download',
  USER_DETAILS: 'User Details',
  DASHBOARD_DETAILS: 'Dashboard Details',
  REFRESH: 'Refresh',
  NO_RESOURCES: 'No resources available',
  RECENT: 'Recent',
  ARCHIVED: 'Archived',
};

export const TIMEZONE_CONSTANTS = {
  EXPIRE_DATE_TIME: 'Expire Date/Time',
  SCHEDULE_DATE_TIME: 'Schedule Date/Time',
  TIME_ZONE: 'Time Zone',
  RECURRENCE: 'Recurrence',
  NAME: 'Name',
  MACHINE_NAME: 'Machine Name',
  REPEAT: 'Repeat',
  SUITE_NAME: 'Suite Name',
  RUN_IN: 'Run In',
  RESCHEDULE_TEST: 'Reschedule Test',
  FOR_ALL_STEPS: 'For all Steps',
  FOR_FAILED_STEPS_ONLY: 'For Failed steps only',
  DO_NOT_CAPTURE: "Don't Capture",
  US_LOCALE_TIME_ZONE: 'en-US',
  SCHEDULE_CONFIGURATION: 'Schedule Configuration',
};

export const SUITES_CONSTANTS = {
  SCHEDULED_WARNING: 'Exceed the allocated limit. Please upgrade the license or buy a new license',
  DETAILS: 'details',
  SCHEDULE: 'schedule',
  UPDATE: 'update',
  CREATE: 'create',
  DEVICE: 'Device',
  DATE_PICKER_EXPIRY_TEXT: 'date-picker-inline-expiredate-helper-text',
  DATE_PICKER_EXPIRY_SCHEDULE_DATE: 'Expiry Date should not be less than Schedule Date',
  DOWNLOAD_CLIENT: 'Download Client',
  AUTOMATICALLY_CONFIGURE_LOCAL_MACHINE: 'to automatically configure your local machine',
  AUTOMATICALLY_DISTRIBUTE: 'Automatically distribute the suite between machines',
  MANUALLY_DISTRIBUTE: 'Manually distribute the suite between machines',
};

export const REPOSITORY_CONSTANTS = {
  PAGE_UPDATE_SUCCESS_MESSAGE_TEXT: 'Page updated successfully.',
  SCREEN_UPDATE_SUCCESS_MESSAGE_TEXT: 'Screen updated successfully.',
  PAGE_UPDATE_FAILURE_MESSAGE_TEXT: 'Failed to update page. Please try again.',
};

export const BUTTON_CONSTANTS = {
  CONFIRM: 'Confirm',
  CANCEL: 'Cancel',
  CONTINUE: 'Continue',
};

export const MANUAL_RESULT_TABLE_CONSTANTS = {
  FILE_UPLOAD_MESSAGE: 'File uploaded successfully',
  DUPLICATE_ATTACHMENTS_MESSAGE: 'Duplicate attachments not allowed within the same row',
  TOTAL_FILES_IN_A_ROW_MESSAGE: 'Total files in a row should be less than or equal to 5',
};

export const RESULT_INFO_PANEL = {
  EXECUTED_ON: 'Executed On',
  DURATION: 'Duration',
  DEVICE: 'Device',
  MACHINE: 'Machine',
  OS: 'OS',
  BROWSER: 'Browser',
  BROWSER_VERSION: 'Browser Version',
  SCRIPT_NAME: 'Script Name',
  SUITE_NAME: 'Suite Name',
  EXECUTION_ENVIRONMENT: 'Execution Environment',
  EXECUTION_ID: 'Execution ID',
  STATUS: 'Status',
  MODULE_NAME: 'Module Name',
  TOTAL_MACHINES: 'Total Machines',
};

export const TAG_STEP_MODEL_TOOLTIP_CONSTANTS = {
  THIS_STEP_ONLY_UPDATE_OCCURANCE_IF_TRUE_INFO:
    'This tag will be applied across all the suites wherever the same step of this script is failed',
  THIS_STEP_ONLY_UPDATE_OCCURANCE_IF_FALSE_INFO:
    'This tag will only be applied for this step within this script for all executions in the current suite',
  ALL_STEPS_UPDATE_OCCURANCE_IF_TRUE_INFO:
    'This tag will be applied for all the steps with this issue across all the suites',
  ALL_STEPS_UPDATE_OCCURANCE_IF_FALSE_INFO:
    'This tag will be applied for all the steps with this issue in the current suite',
  TAG_ONLY_THIS_STEP: 'Tag only for this step',
  TAG_ALL_STEPS: 'Tag all the steps with this issue',
};

export const FILE_UPLOAD_BUTTON_CONSTANTS = {
  REPLACE_FILE: 'Replace File',
  CHOOSE_FILE: 'Choose File',
  NO_FILE_CHOSEN: 'No file chosen',
  MAX_FILE_UPLOAD_SIZE: 5 * 1024 * 1024,
  IMAGE_UPLOAD_ERROR: 'Upload error: Image must be 5MB or smaller',
  SELECT_IMAGE_SIZE_ERROR: 'Image size must be 5MB or smaller',
  SEARCH_AND_SELECT_IMAGES: 'Search and select images',
};

export const ELEMENT_CONFLICT_PAGE = {
  MAX_ASSIGN_ERROR: 'Maximum of 5 image locators can be assigned to one element.',
};
export const LOCATOR_DETAILS_TABLE_CONSTANTS = {
  NAME: 'NAME',
  MODAL_NAME: 'Name',
  TYPE: 'TYPE',
  MODAL_TYPE: 'Type',
  DESCRIPTION: 'Description',
  PROJECT_NAME: 'Project Name',
  SHARED_BW_PAGES: 'Shared between Pages',
  PATH: 'Path',
  CREATED_BY: 'Created By',
  CREATED_ON: 'Created On',
  MODIFIED_BY: 'Modified By',
  MODIFIED_ON: 'Modified On',
  ACTION: 'ACTION',
  NO_ANDROID_LOCATORS: 'No Android locators added',
  NO_IOS_LOCATORS: 'No iOS locators added',
  IOS_LOCATORS_LIST: 'iOS Locators List',
  ANDROID_LOCATORS_LIST: 'Android Locators List',
  LOCATORS_COUNT: 'Locators Count',
  IOS_LOCATORS_COUNT: 'iOS Locators Count',
  ANDROID_LOCATORS_COUNT: 'Android Locators Count',
};

export const LOCATOR_DETAILS_MODAL_CONSTANT = {
  ELEMENT_DETAILS: 'Element Details',
  LOCATOR_DETAILS: 'Locator Details',
};

export const EXECUTION_OVERVIEW_CONSTANT = {
  MACHINE: 'Machine',
  PLATFORM: 'Platform',
  TOTAL_SCRIPT: 'Total Script',
  TOTAL_PASSED: 'Total Passed',
  TOTAL_FAILED: 'Total Failed',
  TOTAL_WARNING: 'Total Warning',
  TOTAL_SKIPPED: 'Total Skipped',
  STATUS: 'Status',
  EXECUTED_ON: 'Executed On',
  DURATION: 'Duration',
  NO_DATA_AVALIABLE: 'No data available',
};

export const FILTER_STATUS_CONSTANT = {
  ALL: 'All',
  PASSED: 'Passed',
  FAILED: 'Failed',
  WARNING: 'Warning',
  SKIPPED: 'Skipped',
  FILTER: 'Filter',
  FILTER_DISABLED: 'Filter Disabled',
  CHANGE_FILTER: 'Change Filter',
};

export const CHATBOT_CONSTANTS = {
  FIREFLINK_SUPPORT: 'FireFlink Support',
  GREETING: 'How may I help you ?',
  HI: 'Hi',
  HELP_MESSAGE: "We're excited to help you out.Try asking",
};

export const CHATBOT_QUESTIONS_CONSTANTS = {
  QUESTIONS: [
    'How to create a new Project ?',
    'What is the use of Shared Elements ?',
    'How can I view Projects in detail under this License ?',
    'How can I generate locators for a particular Element ?',
    'Can I remove a file which is added in Test Data ?',
  ],
};

export const NLP_CONDITIONAL_STEPS = {
  NLP_CONDITION_START_ITERATION: ['Start If Condition', 'Start Else If Condition', 'Start Else Condition'],
  MARGIN_LEFT: 1.7,
};

export const MULTIFORM_ANALYTICS_CONSTANTS = {
  MACHINE: 'Machine',
  PLATFORM: 'Platform',
  STATUS: 'Status',
  EXECUTION_DURATION: 'Execution Duration',
  ALL: 'ALL',
  PASSED: 'Passed',
  FAILED: 'Failed',
  WARNING: 'Warning',
  SKIPPED: 'Skipped',
  PAGE_NAME: {
    NO_OF_EXECUTION_ID_PAGE: 'NO OF EXECUTION ID',
    MULTI_FORM_MACHINE_PAGE: 'MULTI FORM MACHINE',
    SCRIPT_RESULT_ACCORDION_PAGE: 'SCRIPT RESULT ACCORDION',
  },
  EXECUTION_TYPE_LOWERCASE: 'execution type',
  EXECUTION_DURATION_LOWERCASE: 'execution duration',
};

export const USER_SETTINGS_SPECIAL_CHARACTERS_ERROR = {
  ERROR_MESSAGE: '{ , } , | , \\ , ^ , ~ , [ , ] , ` characters are not allowed.',
  SELECTED_STRING_OPTION: 'Run this suite on multiple machine for compatibility',
};

export const NUMBER_RANGE_ERR_MESSAGE = {
  NUM_ERR_MSG_ATLEAST: 'atleast have',
  NUM_ERR_MSG_BETWEEN: 'must be between',
  NUM_ERR_MSG_ITEM: 'item',
};
export const SUPER_ADMIN_PRIVILEGE = {
  SUPER_ADMIN: 'Super Admin',
};
export const AUTOMATION_SCRIPT_CONSTANT = {
  SCRIPT_PLACEHOLDER: 'Enter script name',
  RADIO_BUTTON_PLACEHOLDER: 'Search and select parent module',
};
export const LABELS = {
  EXPAND_ALL: 'Expand All Libraries',
  COLLAPSE_ALL: 'Collapse All Libraries',
  SHOW_ALL_ELEMENTS: 'Show all Step Groups',
  HIDE_ALL_ELEMENTS: 'Hide all Step Groups',
  SHOW_PRE_POST_CONDITION: 'Show Pre/Post Conditions',
  HIDE_PRE_POST_CONDITION: 'Hide Pre/Post Conditions',
};

export const SCRIPT_LABELS = {
  EXPAND_ALL: 'Expand All',
  COLLAPSE_ALL: 'Collapse All ',
  SHOW_ALL_ELEMENTS: 'Show all Script',
  HIDE_ALL_ELEMENTS: 'Hide all Scripts',
  SHOW_PRE_POST_CONDITION: 'Show Pre/Post Conditions',
  HIDE_PRE_POST_CONDITION: 'Hide Pre/Post Conditions',
};

export const PAGE_LABEL = {
  EXPAND_ALL: 'Expand All Pages',
  COLLAPSE_ALL: 'Collapse All Pages',
  SHOW_ALL_ELEMENTS: 'Show all Elements',
  HIDE_ALL_ELEMENTS: 'Hide all Elements',
};

export const EMAIL_VALIDATION_ERRORS = {
  NAME_ERROR_VARIABLE_REQUIRED: 'Recipient name is required',
  NAME_ERROR_VARIABLE_WHITESPACE: 'Space is not allowed at starting and at the end',
  NAME_ERROR_VARIABLE_MIN_CHAR: 'Must be minimum 3 characters',
  NAME_ERROR_VARIABLE_MAX_CHAR: 'Must be 25 characters or less',
  EMAIL_ERROR_VARIABLE_REQUIRED: 'Recipient email is required',
  EMAIL_ERROR_RECEIPT_NOT_VALID: 'Recipient email is not valid',
  EMAIL_ERROR_UNIQUE_ID: 'Email Id should be unique',
};
export const TOOLTIP_NAME = {
  MODULE_TOOLTIP_NAME: 'This Includes Execution Initialization,Scripts Execution and Result processing time',
  SCRIPT_TOOLTIP_NAME: 'This is the total duration of script execution only',
};

export const BULK_LABELING = {
  UPDATE_LABEL_FOR_SCRIPTS: 'Update Label For Scripts',
  SELECT_THE_ACTIONS: 'Select the Actions',
  UPDATE: 'Update',
  CANCEL: 'Cancel',
  ADD_LABEL: 'Add Label',
  REPLACE_ALL: 'Replace All',
  CLEAR_LABELS: 'Clear Labels',
  FIND_AND_REMOVE_LABELS: 'Find and Remove Labels',
  MANUAL_TESTCASES_ERROR: 'Manual testcases can not be executed in the test development',
  PLEASE_SELECT_SCRIPTS: 'Please Select Scripts',
};
export const HASH_LABELS = {
  NO_OPTION: 'No option',
  FILE_FROM_TEST_DATA: 'File From Test Data',
  SELECT_PATH_OR_VARIABLE: 'Select path using # or variable using $',
  MODAL_TYPE_DROPDOWN: 'modal',
  DRAWER_TYPE_DROPDOWN: 'drawer',
  WEB_SERVICE_TYPE_DROPDOWN: 'web-drawer',
  SELECT_PATH: 'Select path using #',
  ENTER_VALUE_OR_SELECT_PATH: 'Enter variable value or select path using #',
  STEP_INPUT_TYPE_UTIL: 'com.tyss.optimize.nlp.util',
  TEST_DATA: 'testdata',
  FOLDER_PATH: 'folderPath',
};

export const GO_TO_STEP = {
  ERROR_MSG: 'Step not found. It may have been deleted',
  GO_TO_STEP: 'Go to Step',
};

export const MULTIFORM_ANALYTICS = {
  noResultFound: 'No Results Found',
  search: 'Search',
  searchLabels: 'Search Labels',
  searchSuite: 'Search Suite/ExecutionID',
};

export const LIBRARY_MODAL = {
  ROOT_LIBRARY_CONSTANT: 'Root Library',
  REQUIRE_PARENT_MODULE: 'Search and select parent module',
};

export const NLP_SUGGESTION_CONDITIONS = {
  ALL: 'all',
  CONDITIONS: 'conditions',
};
export const NAVIGATING_TO_NLP_HELP = {
  NLP_HELP: 'NLP Help',
};
export const NLP_RADIO_BUTTON = {
  RADIO_BUTTON_TEST_DATA: 'Test Data',
  RADIO_BUTTON_LOCAL_PATH: 'Local Path',
};
export const NLP_AUTO_FILLED_INPUT_EXCLUDE = [
  'elementName',
  'elementType',
  'allElementsName',
  'toDropElementName',
  'toElementName',
  'toElementType',
  'ifCheckPointIsFailed',
  'scrollable ContainerElementName',
  'scrollable ContainerElementType',
];
export const STEP_GROUP_VARIABLE_MODAL = {
  HIDE_VALUE: 'Hide value',
  VALUE_HIDDEN: 'Once the value is hidden, it cannot be undone',
};

export const EDIT_PRE_POST_CONDITION = {
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
  CASCADED: 'Cascaded',
  NOT_CASCADED: 'Not Cascaded',
  STATUS: 'Status',
  MUST_EXECUTE: 'Must Execute',
  CASCADE: 'Cascade',
};

export const TOGGLE_BUTTON_NAME = {
  TRUE_TOGGLE_BUTTON_NAME: 'Customize Schedule',
  FALSE_TOGGLE_BUTTON_NAME: 'Use Suite Configurations',
};

// Result Configuration
export const DISPLAY_MESSAGES = {
  ERROR: {
    MIN_VALUE_SHOULD_5: 'Minimum value should be 5',
  },
};

export const RESULT_ACCORDION_CONSTANTS = {
  RESOLVE_QUESTION: 'Your data will be Resolved. Are you sure you want to Resolve this tag?',
  DELETE_QUESTION: 'Your data will be lost. Are you sure you want to delete this tag?',
  SELECT_OPTION: 'Please select an option',
  PROCEED_QUESTION: 'How do you want to proceed?',
  RADIOBUTTON_OPTIONS: [
    { label: 'Only this step', variant: 'primary', name: 'step', id: 'thisStepOnly', value: 'Only this step' },
    {
      label: 'All the steps',
      variant: 'primary',
      name: 'step',
      id: 'allTheSteps',
      value: 'All the steps',
    },
  ],
  RESOLVE: 'resolve',
  DELETE: 'delete',
};
export const VISUAL_TESTING_IMAGE_FILE_EXTENTION = [
  'png',
  'PNG',
  'SVG+XML',
  'svg+xml',
  'SVG',
  'svg',
  'JPG',
  'jpg',
  'jpeg',
  'JPEG',
];

import React, { Fragment, useState } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import CommonButton from '@src/common/button/Button';
import { getUserName } from '@src/util/common_utils';
import { WEBSERVICE } from '@pages/test-development/script/scripts/webservice/restapi/constants/constants';

const WebserviceWarningModal = (props) => {
  const [open, setOpen] = useState(true);
  const { UPDATE_MODAL_MESSAGE, SAVE_MODAL_MESSAGE } = WEBSERVICE;
  const name = getUserName();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className="fixed  inset-0 overflow-y-auto brezz" open={open} onClose={() => { }}>
        <div className="flex  items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity " />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="statuschange inline-block marginupper mt-40 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle">
              <div className="inline-block">
                <div className="flex flex-start ml-2">
                  <span className="pt-5 pl-3">
                    <img src="/assets/images/remove-user-icon.svg" alt="" height="25px" width="25px" />
                  </span>
                  <Dialog.Title as="h3" className="text-sm leading-6 ml-4 pt-3 font-small text-gray-900">
                    {props?.type === 'Alert' ? (
                      <span className="fontPoppinsSemiboldMd">Alert! Request Can't Be Sent</span>
                    ) : (
                      <>
                        <span className="fontPoppinsSemiboldMd block">Warning</span>
                        <span className="fontPoppinsSemiboldMd">Unsaved Changes </span>
                      </>
                    )}
                  </Dialog.Title>
                </div>
              </div>
              <hr className="line mt-px" />
              <div className="text-sm font-sans mt-3 text-gray-500 ml-14 mr-8">
                {props?.type === 'Alert' ? (
                  <>
                    <p className="fontPoppinsRegularSm">{`Hi ${name},`}</p>
                    <p className="fontPoppinsRegularSm">
                      {' '}
                      Request can't be sent, as step group parameter is used in this API request. So please{' '}
                      {props?.label ? UPDATE_MODAL_MESSAGE : SAVE_MODAL_MESSAGE} to utilize it in the test development.{' '}
                    </p>
                    <p className="mt-2 fontPoppinsRegularSm"> How do you want to proceed?</p>
                  </>
                ) : (
                  <>
                    <p className="fontPoppinsRegularSm">
                      {' '}
                      Your web service data will be lost. Are you sure you want to exit?{' '}
                    </p>
                    <p className="mt-2 fontPoppinsRegularSm"> How do you want to proceed?</p>
                  </>
                )}
              </div>
              <hr className="line mt-3" />
              {props?.type === 'Alert' ? (
                <div className="pb-2 flex flex-row float-right">
                  <CommonButton
                    className="mt-3 ml-1 mr-4"
                    btnType="secondary"
                    onClick={() => {
                      props.onHandleWebserviceModal('cancel');
                      setOpen(false);
                    }}
                    label="Cancel"
                  />
                  <CommonButton
                    className="mt-3 ml-4 mr-4"
                    btnType="primary"
                    onClick={() => {
                      props.onHandleWebserviceModal('exit');
                      setOpen(false);
                    }}
                    label={props?.label ? 'Update Step' : 'Save as Step'}
                    disabled={props.disabled}
                  />
                </div>
              ) : (
                <div className="mt-2 pb-2 flex flex-row float-right">
                  <CommonButton
                    className="mt-3 ml-4 mr-4"
                    btnType="secondary"
                    onClick={() => {
                      props.handleWebserviceModal('exit');
                      setOpen(false);
                    }}
                    label="Exit"
                  />
                  <CommonButton
                    className="mt-3 ml-1 mr-4"
                    btnType="primary"
                    onClick={() => {
                      props.handleWebserviceModal('cancel');
                      setOpen(false);
                    }}
                    label="Cancel"
                  />
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default WebserviceWarningModal;

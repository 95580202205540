import React, { Fragment } from 'react';
import RadioButton from '../../../../common/radio_button';
import { useTable, usePagination, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy } from 'react-table';
import matchSorter from 'match-sorter';
import { FiMaximize2 } from 'react-icons/fi';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import Input from '@material-ui/core/Input';
import UserDetailsPage from '../../../common/user-details-page';
import {
  deleteGlobalSetReq,
  getAllGlobalVariableListReq,
  postGlobalSetReq,
  syncVariableReq,
  updateGlobalVarSetReq,
} from '../../../../../api/api_services';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Tooltip } from '@material-ui/core';
import { useAlert } from '@pages/common/alert_service/useAlert';
import { MASK_DATA } from '@src/common/ui-constants';
import {
  TimeZoneDates,
  getCurrentProjectTimeZoneName,
  getDateFormateForCreateSuite,
  getTimeZoneFormateDate,
} from '@src/util/common_utils';
import { TIMEZONE_CONSTANTS } from '@src/common/ui-constants';

let globalVariableMainObj = {
  useGlobalVariableFrom: 'TEST_DEVELOPMENT',
  globalVariables: [],
  selectedGVSetName: '',
  clonedGlobalVariables: [],
};

let editSetValue = '';

let updatedDataForTempStore = [];
const Globalar = (props) => {
  const { AlertContatiner, MyAlert } = useAlert();
  let selectVariableType = ['Use variables from Test Development', 'Use variables from Global Variable Set'];
  const [variableType, setVariableTypeVal] = React.useState(
    props && props.globalVarJsonObject && props.globalVarJsonObject?.useGlobalVariableFrom
      ? props.globalVarJsonObject?.useGlobalVariableFrom === 'SET'
        ? selectVariableType[1]
        : selectVariableType[0]
      : selectVariableType[0]
  );
  const [userObjMain, setUserObj] = React.useState({});
  const [pageSizeCount, setPageSizeCount] = React.useState(5);
  const [pageIndexVal, setPageIndexVal] = React.useState(0);
  const [createUpdateCalled, setCreateUpdateCalled] = React.useState(false);
  const [enableRadioButton, setEnableRadioButton] = React.useState(true);
  let timeZoneName = getCurrentProjectTimeZoneName();
  let mindate = TimeZoneDates(TIMEZONE_CONSTANTS.US_LOCALE_TIME_ZONE, timeZoneName);
  let todayDate = getDateFormateForCreateSuite(mindate.$d);

  let [userId, setUserId] = React.useState();

  React.useEffect(() => {
    if (props.operation && props.operation === 'create') {
      if (props.globalVarJsonObject) {
        if (props.globalVarJsonObject.selectedGVSetName) {
          globalVariableMainObj.selectedGVSetName = props.globalVarJsonObject.selectedGVSetName;
        }
      }
    } else if (props.globalVarJsonForEditObject) {
      if (props.globalVarJsonForEditObject.selectedGVSetName) {
        globalVariableMainObj.selectedGVSetName = props.globalVarJsonForEditObject.selectedGVSetName;
      }
    }
    getAllGlobalVar();
  }, []);

  const formik = useFormik({
    initialValues: {
      Name: '',
    },

    validationSchema: Yup.object({
      Name: Yup.string()
        .min(3, 'Minimum 3 characters')
        .max(25, 'Maximum 25 characters')
        .required('Required!')
        .matches(/^[-_A-Za-z0-9 ]*$|^[-_A-Za-z0-9 ][A-Za-z0-9 ]*[-_A-Za-z-_]$/, 'Name should be alphanumeric')
        .matches(/^[-a-zA-Z0-9-()_]+(\s+[-a-zA-Z0-9-()_]+)*$/, 'Space is not allowed in the beginning and at the end'),
    }),
    onSubmit: (values) => {
      submitFormData();
    },
  });

  let projectSetTempArray;
  if (
    props.globalVarJsonObject &&
    props.globalVarJsonObject.clonedGlobalVariables &&
    props.globalVarJsonObject.clonedGlobalVariables.length
  ) {
    projectSetTempArray = props.globalVarJsonObject.clonedGlobalVariables;
    globalVariableMainObj.clonedGlobalVariables = projectSetTempArray;
    if (projectSetTempArray) {
      projectSetTempArray?.map((proObj) => {
        proObj.Actions = [];
        proObj.modifiedBy = proObj.modifiedBy;
        proObj.modifiedByUname = proObj.modifiedByUname;
        if (props?.rerunMode) {
          proObj.Actions.push(
            <>
              <img
                alt="Edit icon"
                src="\assets\execution_logos\edit.svg"
                className="project-action"
                height="17px"
                width="17px"
              ></img>
              <img
                alt="Delete icon"
                src="\assets\execution_logos\delete.svg"
                className="flex flex-row icon-blue-style project-action "
                height="17px"
                width="17px"
                title="Delete"
              ></img>
            </>
          );
        } else {
          proObj.Actions.push(
            <>
              <img
                alt="Edit icon"
                src="\assets\execution_logos\edit.svg"
                className="project-action mr-2 cursor-hand"
                height="17px"
                width="17px"
                onClick={() => {
                  openModalfun('updateSet');
                  createSetObj(proObj);
                }}
              ></img>
              <img
                alt="Delete icon"
                src="\assets\execution_logos\delete.svg"
                className="flex flex-row icon-blue-style project-action cursor-hand"
                height="17px"
                width="17px"
                onClick={() => {
                  setOpen(true);
                  createSetObj(proObj);
                }}
                title="Delete"
              ></img>
            </>
          );
        }
      });
    }
  } else if (
    props.globalVarJsonForEditObject &&
    props.globalVarJsonForEditObject.clonedGlobalVariables &&
    props.globalVarJsonForEditObject.clonedGlobalVariables.length
  ) {
    projectSetTempArray = props.globalVarJsonForEditObject.clonedGlobalVariables;
    globalVariableMainObj.clonedGlobalVariables = projectSetTempArray;
    if (projectSetTempArray) {
      projectSetTempArray?.map((proObj) => {
        proObj.Actions = [];
        proObj.modifiedBy = proObj?.modifiedBy;
        proObj.modifiedByUname = proObj?.modifiedByUname;
        if (props?.rerunMode) {
          proObj.Actions.push(
            <>
              <img
                alt="Edit icon"
                src="\assets\execution_logos\edit.svg"
                className="project-action"
                height="17px"
                width="17px"
              ></img>
              <img
                alt="Delete icon"
                src="\assets\execution_logos\delete.svg"
                className="flex flex-row icon-blue-style project-action "
                height="17px"
                width="17px"
                title="Delete"
              ></img>
            </>
          );
        } else {
          proObj.Actions.push(
            <>
              <img
                alt="Edit icon"
                src="\assets\execution_logos\edit.svg"
                className="project-action mr-2 cursor-hand"
                height="17px"
                width="17px"
                onClick={() => {
                  openModalfun('updateSet');
                  createSetObj(proObj);
                }}
              ></img>
              <img
                alt="Delete icon"
                src="\assets\execution_logos\delete.svg"
                className="flex flex-row icon-blue-style project-action cursor-hand"
                height="17px"
                width="17px"
                onClick={() => {
                  setOpen(true);
                  createSetObj(proObj);
                }}
                title="Delete"
              ></img>
            </>
          );
        }
      });
    }
  } else {
    projectSetTempArray = [];
    globalVariableMainObj.clonedGlobalVariables = projectSetTempArray;
    globalVariableMainObj.selectedGVSetName = '';
  }

  if (props.globalVarJsonObject && props.globalVarJsonObject.useGlobalVariableFrom) {
    globalVariableMainObj.useGlobalVariableFrom = props.globalVarJsonObject.useGlobalVariableFrom;
  } else {
    globalVariableMainObj.useGlobalVariableFrom = '';
  }

  function createSetFunction(setObj) {
    if (props.suiteId) {
      let setObjForJsonForSet = {
        name: formik.values.Name,
        variableSets: ProjectVarArray,
      };
      setCreateUpdateCalled(true);
      postGlobalSetReq(props.suiteId, setObjForJsonForSet)
        .then((res) => {
          if (res.data && res.data.responseObject) {
            let setTempArray = ProjectSetArray;
            setObj.Actions = [];
            setCreateUpdateCalled(false);
            if (props?.rerunMode) {
              setObj.Actions.push(
                <>
                  <img
                    alt="Edit icon"
                    src="\assets\execution_logos\edit.svg"
                    className="project-action"
                    height="17px"
                    width="17px"
                  ></img>
                  <img
                    alt="Delete icon"
                    src="\assets\execution_logos\delete.svg"
                    className="flex flex-row icon-blue-style project-action "
                    height="17px"
                    width="17px"
                    title="Delete"
                  ></img>
                </>
              );
            } else {
              setObj.Actions.push(
                <>
                  <img
                    alt="Edit icon"
                    src="\assets\execution_logos\edit.svg"
                    className="project-action mr-2 cursor-hand"
                    height="17px"
                    width="17px"
                    onClick={() => {
                      openModalfun('updateSet');
                      createSetObj(setObj);
                    }}
                  ></img>
                  <img
                    alt="Delete icon"
                    src="\assets\execution_logos\delete.svg"
                    className="flex flex-row icon-blue-style project-action cursor-hand"
                    height="17px"
                    width="17px"
                    onClick={() => {
                      setOpen(true);
                      createSetObj(setObj);
                    }}
                    title="Delete"
                  ></img>
                </>
              );
            }

            setTempArray.push(setObj);
            setProjectSetArray(setTempArray);
            props.createAlertFunction('success', `${setObj.name} created successfully.`);
            setShowModal(false);
            createJsonObject();
          } else {
            if (res.data.message.includes('already exists')) {
              MyAlert.danger(`Set with name ${setObj.name} already exist`);
            }
            setCreateUpdateCalled(false);
          }
        })
        .catch((error) => {
          console.log('error');
          setCreateUpdateCalled(false);
        });
    } else {
      if (ProjectSetArray && ProjectSetArray.length) {
        let noDuplicate = false;
        if (setObj && setObj.name) {
          ProjectSetArray?.map((setObjChild) => {
            if (setObjChild.name.toLowerCase() === setObj.name.toLowerCase()) {
              MyAlert.danger(`Set with name ${setObj.name} already exist`);
              // NEEDED
              // props.createAlertFunction('danger', `Set with name ${setObj.name} already exist`)
              noDuplicate = true;
            }
          });
          if (!noDuplicate) {
            let setTempArray = ProjectSetArray;
            setObj.Actions = [];
            if (props?.rerunMode) {
              setObj.Actions.push(
                <>
                  <img
                    alt="Edit icon"
                    src="\assets\execution_logos\edit.svg"
                    className="project-action"
                    height="17px"
                    width="17px"
                  ></img>
                  <img
                    alt="Delete icon"
                    src="\assets\execution_logos\delete.svg"
                    className="flex flex-row icon-blue-style project-action "
                    height="17px"
                    width="17px"
                    title="Delete"
                  ></img>
                </>
              );
            } else {
              setObj.Actions.push(
                <>
                  <img
                    alt="Edit icon"
                    src="\assets\execution_logos\edit.svg"
                    className="project-action mr-2 cursor-hand"
                    height="17px"
                    width="17px"
                    onClick={() => {
                      openModalfun('updateSet');
                      createSetObj(setObj);
                    }}
                  ></img>
                  <img
                    alt="Delete icon"
                    src="\assets\execution_logos\delete.svg"
                    className="flex flex-row icon-blue-style project-action cursor-hand"
                    height="17px"
                    width="17px"
                    onClick={() => {
                      setOpen(true);
                      createSetObj(setObj);
                    }}
                    title="Delete"
                  ></img>
                </>
              );
            }

            setTempArray.push(setObj);
            setProjectSetArray(setTempArray);
            props.createAlertFunction('success', `${setObj.name} created successfully.`);
            setShowModal(false);
            createJsonObject();
          }
        }
      } else {
        let setTempArray = ProjectSetArray;
        setObj.Actions = [];
        if (props?.rerunMode) {
          setObj.Actions.push(
            <>
              <img
                alt="Edit icon"
                src="\assets\execution_logos\edit.svg"
                className="project-action"
                height="17px"
                width="17px"
              ></img>
              <img
                alt="Delete icon"
                src="\assets\execution_logos\delete.svg"
                className="flex flex-row icon-blue-style project-action "
                height="17px"
                width="17px"
                title="Delete"
              ></img>
            </>
          );
        } else {
          setObj.Actions.push(
            <>
              <img
                alt="Edit icon"
                src="\assets\execution_logos\edit.svg"
                className="project-action mr-2 cursor-hand"
                height="17px"
                width="17px"
                onClick={() => {
                  openModalfun('updateSet');
                  createSetObj(setObj);
                }}
              ></img>
              <img
                alt="Delete icon"
                src="\assets\execution_logos\delete.svg"
                className="flex flex-row icon-blue-style project-action cursor-hand"
                height="17px"
                width="17px"
                onClick={() => {
                  setOpen(true);
                  createSetObj(setObj);
                }}
                title="Delete"
              ></img>
            </>
          );
        }

        setTempArray.push(setObj);
        setProjectSetArray(setTempArray);
        props.createAlertFunction('success', `${setObj.name} created successfully.`);
        setShowModal(false);
        createJsonObject();
      }
    }
  }

  function createJsonObject() {
    let setObjForJson = {};
    globalVariableMainObj.clonedGlobalVariables = [];
    ProjectSetArray?.map((proObj) => {
      setObjForJson = {
        name: proObj.name,
        variableSets: proObj.variableSets,
        createdBy: proObj.createdBy,
        createdOn: proObj.createdOn,
        modifiedBy: proObj.modifiedBy,
        modifiedOn: proObj.modifiedOn,
        modifiedByUname: proObj.modifiedByUname,
        lastSync: proObj?.lastSync,
      };
      globalVariableMainObj.clonedGlobalVariables.push(setObjForJson);
      props.createGlobalVarObj(globalVariableMainObj);
    });
  }

  function getSelectedSet(val) {
    val.checked = true;
    globalVariableMainObj.selectedGVSetName = val.name;
    props.createGlobalVarObj(globalVariableMainObj);
  }

  function setVariableType(radioVal) {
    if (radioVal === selectVariableType[0]) {
      let globalVariableCopy = JSON.parse(localStorage.getItem('globalEnvironmentVariable'));
      setProjectVarArray(globalVariableCopy);
      globalVariableMainObj.useGlobalVariableFrom = 'TEST_DEVELOPMENT';
      setPageIndexVal(0);
      props.createGlobalVarObj(globalVariableMainObj);
    } else {
      globalVariableMainObj.useGlobalVariableFrom = 'SET';
      setPageIndexVal(0);
      props.createGlobalVarObj(globalVariableMainObj);
    }
    setVariableTypeVal(radioVal);
  }

  function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);
    return (
      <span>
        Search:{' '}
        <input
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`Type here...`}
          style={{
            fontSize: '1.1rem',
            border: '0',
          }}
        />
      </span>
    );
  }

  // Define a default UI for filtering
  function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    return (
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search...`}
      />
    );
  }

  function fuzzyTextFilterFn(page, id, filterValue) {
    return matchSorter(page, filterValue, { keys: [(page) => page.values[id]] });
  }

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val) => !val;

  // Our table component
  function Table({ columns, data }) {
    const filterTypes = React.useMemo(
      () => ({
        // Add a new fuzzyTextFilterFn filter type.
        fuzzyText: fuzzyTextFilterFn,
        // Or, override the default text filter to use
        // "startWith"
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      []
    );

    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
        initialState: {
          pageIndex:
            variableType == selectVariableType[1] &&
            setObj &&
            setObj?.variableSets &&
            setObj?.variableSets.length &&
            operation === 'Details'
              ? 0
              : pageIndexVal,
          pageSize: pageSizeCount,
        },
      },
      useFilters, // useFilters!
      useGlobalFilter, // useGlobalFilter!
      useSortBy,
      usePagination
    );
    return (
      <>
        <table class="relative w-full " {...getTableProps()}>
          <thead>
            {headerGroups?.map((headerGroup) => (
              <tr class="mt-4" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers?.map((column) => (
                  <th
                    class="sticky top-0 px-4 py-3 text-current table-header_font-style bg-gray-100"
                    {...column.getHeaderProps()}
                  >
                    <span className="flex flex-row">
                      {column.render('Header')}
                      <div>
                        <span></span>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows?.map((row, i) => {
              prepareRow(row);
              return (
                <tr className="project-row" {...row.getRowProps()}>
                  {row?.cells?.map((cell) => {
                    return (
                      <td class="px-4 text-center" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <br />
      </>
    );
  }

  // We don't want to render all of the rows for this example, so cap
  // it for this use case

  function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue >= filterValue;
    });
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        columns: [],
      },

      {
        Header: 'NAME',
        accessor: 'name',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left ml-2 table-font-style-common">{value}</div>;
        },
      },

      {
        Header: 'VALUE',
        accessor: 'value',
        disableFilters: true,
        Cell: ({ value, ...e }) => {
          return (
            <div className="table-non-link-color-common float-left ml-2 table-font-style-common">
              {!e.cell.row.original.masked ? value : MASK_DATA.VARIABLE_MASK_DATA}
            </div>
          );
        },
      },

      {
        Header: 'MODIFIED BY',
        accessor: 'modifiedByUname',
        disableFilters: true,
        Cell: (e) => (
          <a href="#" className=" float-left ml-2 hyper_link_color-common  table-font-style-common modifiedBy">
            {e.cell.row.original.modifiedByUname && e.cell.row.original.modifiedByUname !== '--' ? (
              <span
                className="hyper_link_color cursor-hand"
                onClick={(event) => {
                  event.preventDefault();
                  openModalfun('createdBy', e.cell.row.original);
                }}
              >
                {e.cell.row.original.modifiedByUname}
              </span>
            ) : (
              <span className="table-non-link-color-common ">--</span>
            )}{' '}
          </a>
        ),
      },
    ],
    []
  );

  const variableMaxcolumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        columns: [],
      },

      {
        Header: 'NAME',
        accessor: 'name',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left table-font-style-common">{value}</div>;
        },
      },

      {
        Header: 'VALUE',
        accessor: 'value',
        disableFilters: true,
        Cell: ({ value, ...e }) => {
          return (
            <div className="table-non-link-color-common float-left table-font-style-common">
              {!e.cell.row.original.masked ? value : MASK_DATA.VARIABLE_MASK_DATA}
            </div>
          );
        },
      },
      {
        Header: 'MODIFIED BY',
        accessor: 'modifiedByUname',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common float-left table-font-style-common modifiedBy">
              {value ? value : '--'}
            </div>
          );
        },
      },
    ],
    []
  );

  const variableSetColumns = React.useMemo(
    () => [
      {
        Header: 'SELECT SET',
        accessor: 'checkValue',
        disableFilters: true,
        Cell: (e) => {
          return (
            <RadioGroup row aria-label="position" name="position" defaultValue="top" className="radioBtn">
              <FormControlLabel
                control={
                  <Radio
                    color="primary"
                    name="captureScreenShot"
                    disabled={props?.rerunMode}
                    {...{ checked: globalVariableMainObj.selectedGVSetName === e.cell.row.original.name }}
                    id={e.cell.row.original.name}
                    className=" m-2 cursor-pointer"
                    value={e.cell.row.original}
                    onClick={() => getSelectedSet(e.cell.row.original)}
                  />
                }
                label={<Typography style={{ fontSize: '12px', color: 'gray' }}></Typography>}
              />
            </RadioGroup>
          );
        },
      },

      {
        Header: 'VARIABLES SET',
        accessor: 'name',
        disableFilters: true,
        Cell: (e) => {
          return (
            <div
              className="hyper_link_color-common float-left cursor-hand table-font-style-common"
              onClick={
                props?.rerunMode
                  ? null
                  : () => {
                      openModalfun('Details');
                      createSetObj(e.cell.row.original);
                    }
              }
            >
              {e.cell.row.original.name}
            </div>
          );
        },
      },
      {
        Header: 'LAST SYNC',
        accessor: 'lastSync',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common float-left table-font-style-common">
              {value ? value : 'N/A'}
            </div>
          );
        },
      },
      {
        Header: 'MODIFIED BY',
        accessor: 'modifiedByUname',
        disableFilters: true,
        Cell: (e) => (
          <a href="#" className=" float-left hyper_link_color-common  table-font-style-common">
            {e.cell.row.original.modifiedByUname && e.cell.row.original.modifiedByUname !== '--' ? (
              <span
                className="hyper_link_color "
                onClick={(event) => {
                  event.preventDefault();
                  openModalfun('createdBy', e.cell.row.original);
                }}
              >
                {e.cell.row.original.modifiedByUname}
              </span>
            ) : (
              <span className="table-non-link-color-common">--</span>
            )}{' '}
          </a>
        ),
      },
      {
        Header: 'ACTIONS',
        accessor: 'Actions',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="flex table-non-link-color-common rs-icon-alignment-font-style">{value}</div>;
        },
      },
    ],
    []
  );

  const variableSetMaxColumns = React.useMemo(
    () => [
      {
        Header: 'Variable Set',
        accessor: 'name',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left font-style-common">{value}</div>;
        },
      },
      {
        Header: 'LAST SYNC',
        accessor: 'lastSync',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common float-left table-font-style-common">{value ? value : '--'}</div>
          );
        },
      },
      {
        Header: 'MODIFIED BY',
        accessor: 'modifiedByUname',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common float-left cursor-hand table-font-style-common">
              {value ? value : '--'}
            </div>
          );
        },
      },
      // {
      //     Header: 'ACTIONS',
      //     accessor: 'Action',
      //     disableFilters: true,
      //     Cell: ({ value }) => {
      //         return (
      //             <div className="actionBtn">
      //                 <img src="\assets\execution_logos\editDisable.svg" className="project-action " height="17px" width="17px"
      //                 ></img>
      //                 <img src="\assets\execution_logos\deleteDisable.svg" className="flex flex-row icon-blue-style project-action ml-2" height="17px" width="17px"
      //                     title="Delete"></img>
      //             </div>
      //         )
      //     }

      // },
    ],
    []
  );
  const detailsColumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left table-font-style-common ">{value}</div>;
        },
      },

      {
        Header: 'Value',
        accessor: 'value',
        disableFilters: true,
        Cell: ({ value, ...e }) => {
          return (
            <div className="table-non-link-color-common float-left table-font-style-common">
              {!e.cell.row.original.masked ? value : MASK_DATA.VARIABLE_MASK_DATA}
            </div>
          );
        },
      },
      {
        Header: 'LAST SYNC',
        accessor: 'lastSync',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common float-left table-font-style-common">{value ? value : '--'}</div>
          );
        },
      },
    ],
    []
  );

  function getAllGlobalVar() {
    let tocken = JSON.parse(localStorage.getItem('test-optimize-user'));
    setUserObj(tocken);
    let projectElementIdArray = [];
    getAllGlobalVariableListReq()
      .then((results) => {
        let response = results.data.responseObject ? results.data.responseObject : [];
        setProjectVarArray(response);
        setEnableRadioButton(false);
        setProjectVarBackupData([...response]);
        localStorage.setItem('globalEnvironmentVariable', JSON.stringify(response));
        response?.map((resObj) => {
          projectElementIdArray.push(resObj.id);
        });

        globalVariableMainObj.globalVariables = projectElementIdArray;
        props.createGlobalVarObj(globalVariableMainObj);
      })
      .catch((error) => {
        console.log(error);
      });
    return [];
  }

  const [ProjectSetArray, setProjectSetArray] = React.useState(projectSetTempArray);
  const [projectVarBackupData, setProjectVarBackupData] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);

  const [setObj, setObjectSet] = React.useState({});
  const [selectAll, setSelectAll] = React.useState(false);
  const [selectedSetArray, setSelectedSetArray] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  let [ProjectVarArray, setProjectVarArray] = React.useState([]);
  const [backupSyncData, setBackupSyncData] = React.useState([]);

  function openModalfun(btn, userVal) {
    if (btn === 'create') {
      setShowModal(true);
      setOperation('Create');
      setShowInputField(false);
      let globalVariableCopy = JSON.parse(localStorage.getItem('globalEnvironmentVariable'));
      setProjectVarArray(globalVariableCopy);
      formik.resetForm();
    } else if (btn === 'updateSet') {
      setShowModal(true);
      setOperation('updateSet');
      setShowInputField(false);
    } else if (btn === 'maximize') {
      if (variableType === selectVariableType[1]) {
        if (ProjectSetArray.length) {
          setShowModal(true);
          setOperation('maximize');
          setPageSizeCount(8);
          setPageIndexVal(0);
        }
      } else {
        setShowModal(true);
        setOperation('maximize');
        setPageSizeCount(8);
        setPageIndexVal(0);
      }
    } else if (btn === 'createdBy') {
      if (userVal) {
        setUserId(userVal);
      }
      setShowModal(true);
      setOperation('createdBy');
    } else if (btn === 'Details') {
      setShowModal(true);
      setOperation('Details');
      setPageSizeCount(5);
      setPageIndexVal(0);
    }
  }

  function createSetObj(obj) {
    setObjectSet(obj);
  }

  const [showInputField, setShowInputField] = React.useState(false);
  const [indexForInput, setIndexForInput] = React.useState();
  const [enableCreateBtn, setEnableCreateBtn] = React.useState('false');
  const [operation, setOperation] = React.useState('Create');
  const [reloadTable, setReloadTable] = React.useState(true);
  let currentDetailsPosts;
  if (setObj && setObj.variableSets && setObj.variableSets.length) {
    currentDetailsPosts = setObj.variableSets;
  }

  let allVariableSets = [];
  if (setObj.variableSets && setObj.variableSets.length) {
    allVariableSets = setObj.variableSets;
  }

  function selectAllUsers(event) {
    let backupArray = [];
    setProjectVarArray([...projectVarBackupData]);
    setObj?.variableSets?.map((val, index) => {
      let htmlInpt = document.getElementById('check' + index);
      var selectAllCheckboxHtmlEle = document.getElementById('selectAllCheckboxId');
      if (htmlInpt) {
        if (event.target.checked) {
          htmlInpt.checked = true;
          val.checked = true;
          setSelectAll(true);
          setSelectedSetArray(setObj.variableSets);
          setEnableCreateBtn('true');
        } else {
          htmlInpt.checked = false;
          val.checked = false;
          setSelectAll(false);
          setSelectedSetArray([]);
          if (selectAllCheckboxHtmlEle) {
            selectAllCheckboxHtmlEle.indeterminate = false;
          }
          setEnableCreateBtn('false');
        }
      }
    });

    if (setObj?.variableSets?.length) {
      setObj.variableSets?.map((tempObj) => {
        let obj = {
          name: tempObj?.name,
          value: tempObj?.value,
          lastSync: tempObj?.lastSync,
        };
        backupArray.push(obj);
      });
    }
    setBackupSyncData(backupArray);
  }

  function onSelectSet(e, selectedSet) {
    var selectAllCheckboxHtmlEle = document.getElementById('selectAllCheckboxId');
    let tempArray = [...selectedSetArray];
    if (e) {
      selectedSet.checked = true;
      tempArray.push(selectedSet);
    } else {
      selectedSet.checked = false;
      const ind = tempArray.findIndex((tempObj) => tempObj.name === selectedSet.name);
      tempArray.splice(ind, 1);
    }
    if (tempArray.length) {
      if (tempArray.length === setObj.variableSets.length) {
        if (selectAllCheckboxHtmlEle) {
          selectAllCheckboxHtmlEle.indeterminate = false;
          selectAllCheckboxHtmlEle.checked = true;
          setEnableCreateBtn('true');
        }
      } else {
        selectAllCheckboxHtmlEle.indeterminate = true;
        setEnableCreateBtn('true');
      }
    } else {
      if (selectAllCheckboxHtmlEle) {
        selectAllCheckboxHtmlEle.indeterminate = false;
        selectAllCheckboxHtmlEle.checked = false;
        setEnableCreateBtn('false');
      }
    }
    tempArray = Array.from(new Set(tempArray));
    if (e) {
      let backupArray = backupSyncData;
      let obj = {
        name: selectedSet?.name,
        value: selectedSet?.value,
        lastSync: selectedSet?.lastSync,
      };
      backupArray.push(obj);
      setBackupSyncData(backupArray);
    }
    setSelectedSetArray(tempArray);
  }

  function syncVariable() {
    setReloadTable(false);
    let nameString;
    selectedSetArray?.map((varObj) => {
      if (nameString) {
        nameString = nameString + varObj?.name + ',';
      } else {
        nameString = varObj?.name + ',';
      }
    });

    syncVariableReq(nameString)
      .then((results) => {
        let updatedData = results?.data?.responseObject;
        if (updatedData.length) {
          if (setObj?.variableSets.length) {
            setObj?.variableSets?.map((upObj) => {
              let newObj = updatedData?.find((obj) => obj.name === upObj.name);
              if (newObj) {
                upObj.value = newObj?.value;
                upObj.lastSync = newObj?.lastSync;
              }
            });
            setReloadTable(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  let handelDlt = (deletObj) => {
    if (props.suiteId) {
      let delObjIndex = ProjectSetArray.findIndex((obj) => obj.name === deletObj.name);
      if (delObjIndex > -1) {
        deleteGlobalSetReq(props.suiteId, deletObj.name).then((res) => {
          if (res) {
            ProjectSetArray.splice(delObjIndex, 1);
            props.createAlertFunction('success', `${deletObj.name} deleted successfully`);
            createJsonObject();
            setOpen(false);
            if (props.globalVarJsonObject.selectedGVSetName === deletObj.name) {
              props.globalVarJsonObject.selectedGVSetName = '';
            }
          }
        });
      }
    } else {
      let delObjIndex = ProjectSetArray.findIndex((obj) => obj.name === deletObj.name);
      if (delObjIndex > -1) {
        ProjectSetArray.splice(delObjIndex, 1);
        props.createAlertFunction('success', `${deletObj.name} deleted successfully`);
        setOpen(false);
        createJsonObject();
        if (props.globalVarJsonObject.selectedGVSetName === deletObj.name) {
          props.globalVarJsonObject.selectedGVSetName = '';
        }
      }
    }
  };

  function showInputFieldFun(index, editObj) {
    let obj = {
      name: editObj?.name,
      value: editObj?.value,
    };
    if (updatedDataForTempStore.length) {
      const ind = updatedDataForTempStore?.find((tempObj) => tempObj?.name === obj?.name);
      if (ind) {
      } else {
        updatedDataForTempStore.push(obj);
      }
    } else {
      updatedDataForTempStore.push(obj);
    }

    setShowInputField(true);
    setIndexForInput(index);
  }

  function getEditedValue(event, obj) {
    ProjectVarArray?.map((varObj) => {
      if (varObj.id === obj.id) {
        varObj.value = event;
      }
    });
  }

  function createEditObject(e, editObj) {
    editSetValue = e.target.value ? e.target.value : '--';
    if (editSetValue) {
      editObj.value = editSetValue;
      setObj.modifiedBy = userObjMain.id;
      setObj.modifiedOn = todayDate;
      setObj.modifiedByUname = userObjMain.name;
    }
    setEnableCreateBtn('true');
  }

  function updateSetFun() {
    if (selectedSetArray?.length) {
      if (setObj?.variableSets?.length) {
        setObj?.variableSets?.map((setObj) => {
          const selObj = selectedSetArray?.find((tempObj) => tempObj?.name === setObj?.name);
          if (selObj) {
            setObj.checked = false;
          } else {
            const childObj = backupSyncData?.find((tempObj) => tempObj?.name === setObj?.name);
            if (childObj) {
              setObj.value = childObj?.value;
              setObj.lastSync = childObj?.lastSync;
            }
          }
        });
      }
    }
    if (props.suiteId) {
      const clone = (({ Actions, ...o }) => o)(setObj);
      setCreateUpdateCalled(true);
      updateGlobalVarSetReq(props.suiteId, clone.name, clone)
        .then((res) => {
          if (res.data && res.data.responseObject) {
            setCreateUpdateCalled(false);
            if (setObj?.variableSets?.length === selectedSetArray?.length) {
              setObj.lastSync = setObj?.variableSets[0]?.lastSync;
            }
            setShowModal(false);
            props.createAlertFunction('success', `${clone.name} updated successfully`);
            createJsonObject();
            updatedDataForTempStore = [];
            setBackupSyncData([]);
            setSelectedSetArray([]);
            if (backupSyncData?.length) {
              backupSyncData?.map((obj) => {
                const ind = setObj?.variableSets?.find((tempObj) => tempObj?.name === obj?.name);
                if (ind) {
                  ind.checked = false;
                }
              });
            }
          } else {
            console.log('error', res.data.message);
            setCreateUpdateCalled(false);
          }
        })
        .catch((error) => {
          console.log('error');
          setCreateUpdateCalled(false);
        });
    } else {
      if (setObj?.variableSets?.length === selectedSetArray?.length) {
        setObj.lastSync = setObj?.variableSets[0]?.lastSync;
      }
      if (backupSyncData.length) {
        backupSyncData?.map((obj) => {
          const ind = setObj?.variableSets?.find((tempObj) => tempObj?.name === obj?.name);
          if (ind) {
            ind.checked = false;
          }
        });
      }
      setEnableCreateBtn('false');
      props.createAlertFunction('success', `${setObj.name} updated successfully`);
      setShowModal(false);
      createJsonObject();
      updatedDataForTempStore = [];
      setBackupSyncData([]);
      setSelectedSetArray([]);
    }
  }

  function resetOldData() {
    if (updatedDataForTempStore.length) {
      updatedDataForTempStore?.map((obj) => {
        const ind = setObj?.variableSets?.find((tempObj) => tempObj?.name === obj?.name);
        if (ind) {
          ind.value = obj?.value;
          ind.lastSync = obj?.lastSync;
        }
      });
      updatedDataForTempStore = [];
      setCreateUpdateCalled(true);
    }

    if (backupSyncData.length) {
      backupSyncData?.map((obj) => {
        const ind = setObj?.variableSets?.find((tempObj) => tempObj?.name === obj?.name);
        if (ind) {
          ind.value = obj?.value;
          ind.checked = false;
          ind.lastSync = obj?.lastSync;
        }
      });

      setBackupSyncData([]);
      setCreateUpdateCalled(true);
    }
  }

  function changeFocusFun() {
    setShowInputField(false);
    setIndexForInput();
  }

  function submitFormData() {
    let data = {
      name: formik.values.Name,
      variableSets: ProjectVarArray,
      createdBy: userObjMain.name,
      createdOn: todayDate,
    };
    createSetFunction(data);
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block marginupper pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/4">
                <div className="inline-block">
                  <div className="flex flex-start ml-2">
                    <span className="pt-5 pl-3">
                      <ExclamationIcon className="h-6 w-6 text-yellow-600 " />
                    </span>
                    <Dialog.Title as="h3" className="text-sm leading-6 ml-4 pt-3 font-small text-gray-900">
                      <span className="fontPoppinsSemiboldLg block">Warning </span>
                      <span className="fontPoppinsMediumMd">Delete </span>
                    </Dialog.Title>
                  </div>
                </div>
                <hr className="line mt-px" />
                <div className="text-sm font-sans mt-3 text-gray-500 ml-16 mr-8">
                  <p className=""> Your variable set will be lost. Are you sure you want to delete {setObj.name}? </p>
                  <p className="mt-2"> How do you want to proceed?</p>
                </div>
                <hr className="line mt-3" />
                <div className="mt-2 pb-2 flex flex-row float-right">
                  <button type="button" className="mt-3 gray-btn ml-1" onClick={() => handelDlt(setObj)}>
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 ml-4 primary-btn "
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Modal open={showModal} className="modal-dialog">
        {operation === 'Create' ? (
          <div className="modal-container common-var-max-popup-style">
            <form onSubmit={formik.handleSubmit}>
              <div className="modal-header ">
                <label className="Popup-header-common">Create Global Variables Set</label>
                <IconButton
                  color="primary"
                  component="span"
                  className="close"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>

              <div className="modal-body" id="journal-scroll">
                <div className="alert-variable-modal-position-style">
                  <AlertContatiner />
                </div>
                <div className="input-field-display">
                  <label htmlFor="Name" className="input-filed-label-style-common">
                    <span class="text-red-400">*</span> Name
                  </label>
                  <Input
                    className="w-1/2 fontPoppinsRegularLg"
                    disabled={props?.rerunMode}
                    type="text"
                    name="Name"
                    id="Name"
                    placeholder={`Enter the name of the set`}
                    autoComplete="off"
                    onChange={formik.handleChange}
                    value={formik.values.Name}
                  />
                  {formik.errors.Name && formik.touched.Name ? (
                    <div className="errorMessage">{formik.errors.Name}</div>
                  ) : null}
                </div>

                <div className="variable-set-create-content-area-style mt-4">
                  <div className="create-set-header">
                    <label className="create-set-header-label">Global Variables</label>
                    {/* <FaSearch className="float-right searchBtn" /> */}
                  </div>
                  <div className="scroll-height-setModal overflow-auto " id="journal-scroll">
                    <table className="mapping-table">
                      <thead className="details-table-header ">
                        <tr>
                          <th className="sticky top-0 fixed z-10 bg-gray-100">NAME</th>
                          <th className="sticky top-0 fixed ml-7 z-10 bg-gray-100">VALUE</th>
                        </tr>
                      </thead>
                      <tbody className="details-table-body">
                        {ProjectVarArray?.map((proVal, index) => (
                          <tr>
                            <td className="z-10">{proVal.name}</td>

                            <td>
                              <input
                                type={proVal.masked ? 'password' : 'text'}
                                disabled={props?.rerunMode}
                                defaultValue={proVal.value && proVal.value !== '--' ? proVal.value : ''}
                                onChange={(e) => getEditedValue(e.target.value, proVal)}
                                className="input-filed-background-color"
                                onClick={() => showInputFieldFun(index)}
                              ></input>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  className="gray-btn mr-4"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  {' '}
                  Close{' '}
                </button>
                <button type="submit" disabled={createUpdateCalled} className="primary-btn">
                  {' '}
                  Create{' '}
                </button>
              </div>
            </form>
          </div>
        ) : operation === 'updateSet' ? (
          <div className="modal-container global-var-max-popup-style">
            <div className="modal-header ">
              <label className="Popup-header-common">Edit Global Variables Set</label>
              <IconButton
                color="primary"
                component="span"
                className="close"
                onClick={() => {
                  setShowModal(false);
                  resetOldData();
                  setSelectedSetArray([]);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div className="modal-body-setup" id="journal-scroll">
              <div className="alert-variable-modal-position-style">
                <AlertContatiner />
              </div>
              <div className="variable-set-edit-content-area-style">
                <div className="create-set-header">
                  <div className="flex flex-wrap ">
                    <label className="create-set-header-label flex-auto">{setObj.name}</label>
                    {/* <FaSearch className="mt-2 mr-3 searchBtn" /> */}
                    <button
                      className={`fontPoppinsMediumMd ${
                        selectedSetArray.length ? 'secondary-btn' : 'disabled-btn '
                      } mr - 3`}
                      onClick={syncVariable}
                    >
                      Sync Variables
                    </button>
                    <Tooltip
                      placement="top"
                      title="Selected variable value's will be updated to latest published state"
                    >
                      <InfoOutlinedIcon className="float-right flex flex-row ml-2 mt-2 mb-1  hyper_link_color-common" />
                    </Tooltip>
                  </div>
                </div>
                {reloadTable ? (
                  <div className="edit-table-height overflow-auto" id="journal-scroll">
                    <table className="mapping-table">
                      <thead className="details-table-header ">
                        <tr>
                          <th className="sticky top-0 fixed z-10 bg-gray-100" onChange={(e) => selectAllUsers(e)}>
                            {' '}
                            <input id="selectAllCheckboxId" type="checkbox" disabled={props?.rerunMode}></input>
                          </th>
                          <th className="sticky top-0 fixed z-10 bg-gray-100">NAME</th>
                          <th className="sticky top-0 fixed ml-7 z-10 bg-gray-100">VALUE</th>
                          <th className="sticky top-0 fixed ml-7 z-10 bg-gray-100">LAST SYNC</th>
                        </tr>
                      </thead>
                      <tbody className="details-table-body">
                        {allVariableSets?.map((proVal, index) => (
                          <tr>
                            <td className="z-10">
                              {' '}
                              <input
                                type="checkbox"
                                defaultChecked={proVal.checked}
                                onChange={(event) => {
                                  onSelectSet(event.target.checked, proVal, index);
                                }}
                                id={'check' + index}
                              ></input>
                            </td>
                            <td className="z-10">{proVal.name}</td>
                            <td className="z-10">
                              {index === indexForInput && showInputField ? (
                                <input
                                  type={proVal.masked ? 'password' : 'text'}
                                  defaultValue={proVal.value}
                                  disabled={props?.rerunMode}
                                  onChange={(e) => createEditObject(e, proVal)}
                                  onBlur={() => changeFocusFun()}
                                ></input>
                              ) : (
                                <input
                                  type={proVal.masked ? 'password' : 'text'}
                                  title={proVal.masked ? '' : proVal.value}
                                  value={proVal.value && proVal.value !== '--' ? proVal.value : ''}
                                  onChange={(e) => createEditObject(e, proVal)}
                                  className="input-filed-background-color"
                                  onFocus={() => showInputFieldFun(index, proVal)}
                                ></input>
                              )}
                            </td>
                            <td className="z-10">{proVal?.lastSync ? proVal?.lastSync : '--'}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="edit-table-height overflow-auto" id="journal-scroll">
                    <table className="mapping-table">
                      <thead className="details-table-header ">
                        <tr>
                          <th className="sticky top-0 fixed z-10 bg-gray-100" onChange={(e) => selectAllUsers(e)}>
                            {' '}
                            <input id="selectAllCheckboxId" type="checkbox"></input>
                          </th>
                          <th className="sticky top-0 fixed z-10 bg-gray-100">Name</th>
                          <th className="sticky top-0 fixed ml-7 z-10 bg-gray-100">Value</th>
                          <th className="sticky top-0 fixed ml-7 z-10 bg-gray-100">Last Sync</th>
                        </tr>
                      </thead>
                      <tbody className="details-table-body">
                        {allVariableSets?.map((proVal, index) => (
                          <tr>
                            <td className="z-10">
                              {' '}
                              <input
                                type="checkbox"
                                defaultChecked={proVal.checked}
                                onChange={(event) => {
                                  onSelectSet(event.target.checked, proVal, index);
                                }}
                                id={'check' + index}
                              ></input>
                            </td>
                            <td className="z-10">{proVal.name}</td>
                            <td className="z-10">
                              {index === indexForInput && showInputField ? (
                                <input
                                  type={proVal.masked ? 'password' : 'text'}
                                  defaultValue={proVal.value}
                                  onChange={(e) => createEditObject(e, proVal)}
                                  onBlur={() => changeFocusFun()}
                                ></input>
                              ) : (
                                <input
                                  type={proVal.masked ? 'password' : 'text'}
                                  title={proVal.masked ? '' : proVal.value}
                                  value={proVal.value && proVal.value !== '--' ? proVal.value : ''}
                                  onChange={(e) => createEditObject(e, proVal)}
                                  className="input-filed-background-color"
                                  onFocus={() => showInputFieldFun(index, proVal)}
                                ></input>
                              )}
                            </td>
                            <td className="z-10">{proVal?.lastSync ? proVal?.lastSync : '--'}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>

            <div className="modal-footer">
              <button
                className="gray-btn mr-3"
                onClick={() => {
                  setShowModal(false);
                  resetOldData();
                  setSelectedSetArray([]);
                }}
              >
                Cancel
              </button>
              <button
                disabled={createUpdateCalled}
                onClick={updateSetFun}
                type="submit"
                className={`fontPoppinsMediumMd ${enableCreateBtn === 'true' ? 'primary-btn' : 'disabled-update-btn '}`}
              >
                Update
              </button>
            </div>
          </div>
        ) : operation === 'maximize' ? (
          <div className=" modal-container max-popup-style">
            <div className="modal-header ">
              {variableType === selectVariableType[0] ? (
                <label className="Popup-header-common">Variables from Test Development</label>
              ) : (
                <label className="Popup-header-common">Variables from Global Variable Set</label>
              )}

              <div className="float-right flex flex-row">
                {/* <FaSearch className="search-icon-maximize-style searchBtn" /> */}
                <CloseIcon
                  className="cursor-hand"
                  onClick={() => {
                    setShowModal(false);
                    setPageSizeCount(5);
                    setOperation('');
                    setPageIndexVal(0);
                  }}
                />
              </div>
            </div>

            <div className="modal-body-1" id="journal-scroll">
              {variableType === selectVariableType[0] ? (
                <div className="table_height overflow-auto" id="journal-scroll">
                  <Table columns={variableMaxcolumns} data={ProjectVarArray} />
                </div>
              ) : (
                <div className="table_height overflow-auto" id="journal-scroll">
                  <Table columns={variableSetMaxColumns} data={ProjectSetArray} />
                </div>
              )}
            </div>
          </div>
        ) : operation === 'Details' ? (
          <div className="modal-container var-set-max-popup-style">
            <div className="modal-header ">
              <label className="Popup-header-common">Variable Set Details - {setObj.name}</label>
              <IconButton
                color="primary"
                component="span"
                className="close"
                onClick={() => {
                  setShowModal(false);
                  setPageSizeCount(5);
                  setOperation('');
                  setPageIndexVal(0);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div className="modal-body-setup" id="journal-scroll">
              <div>
                <div className="grid grid-cols-3">
                  <div>
                    <label className="implicit-explicit-label-style">Name</label>
                    <br />
                    <label className="set-details-value-style">{setObj.name}</label>
                  </div>
                  <div>
                    <label className="implicit-explicit-label-style">No Of Variable</label>
                    <br />
                    <label className="set-details-value-style">{setObj.variableSets.length}</label>
                  </div>
                  <div>
                    <label className="implicit-explicit-label-style">Created By</label>
                    <br />
                    <label className="set-details-value-style">{setObj.createdBy}</label>
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-5">
                  <div>
                    <label className="implicit-explicit-label-style">Created On</label>
                    <br />
                    <label className="set-details-value-style">
                      {getTimeZoneFormateDate(setObj.createdOn, props?.label)}
                    </label>
                  </div>
                  <div>
                    <label className="implicit-explicit-label-style">MODIFIED BY</label>
                    <br />
                    <label className="set-details-value-style">{setObj.modifiedBy ? setObj.modifiedBy : '--'}</label>
                  </div>
                  <div>
                    <label className="implicit-explicit-label-style">modified On</label>
                    <br />
                    <label className="set-details-value-style">
                      {setObj.modifiedOn ? getTimeZoneFormateDate(setObj.modifiedOn, props?.label) : '--'}
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <div className="dotted-border-style mt-2"></div>
                <div className="variable-set-details-content-area-style mt-4">
                  <div className="create-set-header">
                    <label className="create-set-header-label">{setObj.name}</label>
                    {/* <FaSearch className="float-right searchBtn" /> */}
                  </div>

                  <div>
                    <>
                      <div className="table_height overflow-auto" id="journal-scroll">
                        <Table columns={detailsColumns} data={setObj.variableSets} />
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="gray-btn"
                onClick={() => {
                  setShowModal(false);
                  setPageSizeCount(5);
                  setOperation('');
                  setPageIndexVal(0);
                }}
              >
                Close
              </button>
            </div>
          </div>
        ) : operation === 'createdBy' ? (
          <div className="modal-container page-modal-size">
            <div className="modal-header Popup-header-common">
              <label> User Details - {userId.modifiedByUname}</label>

              <IconButton
                color="primary"
                component="span"
                className="close"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div className="modal-body" id="journal-scroll">
              <UserDetailsPage modifiedBy={userId.modifiedBy} />
            </div>
          </div>
        ) : null}
      </Modal>
      <div className="left-border-style">
        <RadioButton
          radioList={selectVariableType}
          rerunMode={props?.rerunMode ? props?.rerunMode : enableRadioButton ? true : false}
          editData={variableType}
          radioName="selectVariableType"
          func={setVariableType}
        />
      </div>
      {variableType === selectVariableType[0] || (variableType === selectVariableType[1] && ProjectSetArray.length) ? (
        <div className="module-area-style">
          <div className="content_area_header_style">
            <div>
              <label className="main-header-label float-left ">
                {variableType === selectVariableType[0] ? 'Variables from Test Development' : 'Global Variables Sets'}
              </label>
            </div>
            <div className="flex flex-row float-right">
              {/* <FaSearch className="mt-2 mr-3 searchBtn" /> */}
              {variableType === selectVariableType[1] ? (
                <button
                  className="secondary-btn mr-3"
                  onClick={() => openModalfun('create')}
                  disabled={!ProjectVarArray?.length || props?.rerunMode}
                >
                  + Set
                </button>
              ) : null}
              {variableType === selectVariableType[0] ? (
                <FiMaximize2
                  title="Maximize"
                  className={`${
                    ProjectVarArray?.length ? 'maximize-btn-style  cursor-hand' : 'disabled-action'
                  } mt-2 mr-3`}
                  onClick={() => {
                    openModalfun('maximize');
                    setVariableTypeVal(selectVariableType[0]);
                  }}
                />
              ) : (
                <FiMaximize2
                  title="Maximize"
                  className={`${
                    ProjectSetArray?.length ? 'maximize-btn-style  cursor-hand' : 'disabled-action'
                  } mt-2 mr-3`}
                  onClick={() => {
                    openModalfun('maximize');
                    setVariableTypeVal(selectVariableType[1]);
                  }}
                />
              )}
            </div>
          </div>
          <div>
            {variableType === selectVariableType[0] ? (
              <>
                <div className="table_height overflow-auto" id="journal-scroll">
                  <Table columns={columns} data={ProjectVarArray} />

                  {!ProjectVarArray.length && (
                    <div className="no-data-msg">
                      <label>No Data Available</label>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="table_height overflow-auto" id="journal-scroll">
                  <Table columns={variableSetColumns} data={ProjectSetArray} />
                </div>
              </>
            )}
          </div>
        </div>
      ) : variableType === selectVariableType[1] ? (
        !ProjectSetArray.length ? (
          <button
            className="secondary-btn mr-3 left-border-style top-border-style"
            onClick={() => openModalfun('create')}
            disabled={!ProjectVarArray.length || props?.rerunMode}
          >
            + Set
          </button>
        ) : null
      ) : null}
      <div></div>
    </>
  );
};

export default Globalar;

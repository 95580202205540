import Style from '@pages/common/LicensePurchaseComponents/styles/total-amount-card.module.scss';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { getUSDFormatAmountString } from '@util/common_utils';
import { LICENSE_CONSTANTS } from '@src/common/ui-constants';
import { grandTotal } from '@src/util/licenseUtils';

function TotalAmountCard({ netTotal, discount, discountLabel, tax, className, licenseType }) {
  return (
    <div className={cx('flex flex-col justify-between', Style['card-wrapper'], className)}>
      <div>
        <h1 className={cx(Style['card-header'], 'fontPoppinsSemiboldSm')}>{LICENSE_CONSTANTS.TOTAL_AMOUNT}</h1>
        <div className={`${licenseType ? Style['card-content'] : Style['card-content-coupon']}`}>
          <div className="flex justify-between">
            <span className="text-sm fontPoppinsRegularSm flex-initial w-1/2 overflow-hidden overflow-ellipsis whitespace-nowrap">
              Net Total :
            </span>
            <span className="text-sm fontPoppinsSemiboldSm flex-initial w-1/2 overflow-hidden overflow-ellipsis whitespace-nowrap  text-right">
              <span className={cx(Style['dollar'], 'mr-1')}>$</span>
              {getUSDFormatAmountString(netTotal)}
            </span>
          </div>
          {!!discount && (
            <div className="flex justify-between">
              <span className="text-sm fontPoppinsRegularSm flex-initial w-1/2 overflow-hidden overflow-ellipsis whitespace-nowrap">
                {discountLabel} :
              </span>
              <span
                className={cx(
                  'text-sm fontPoppinsSemiboldSm flex-initial w-1/2 overflow-hidden overflow-ellipsis whitespace-nowrap  text-right',
                  Style['discounted-value']
                )}
              >
                <span className={cx(Style['dollar'], 'mr-1')}>$</span>
                {getUSDFormatAmountString(discount)}
              </span>
            </div>
          )}
          <div className="flex justify-between">
            <span className="text-sm fontPoppinsRegularSm flex-initial w-1/2 overflow-hidden overflow-ellipsis whitespace-nowrap">
              Tax :
            </span>
            <span className="text-sm fontPoppinsSemiboldSm flex-initial w-1/2 overflow-hidden overflow-ellipsis whitespace-nowrap text-right">
              <span className={cx(Style['dollar'], 'mr-1')}>$</span>
              {getUSDFormatAmountString(tax)}
            </span>
          </div>
        </div>
      </div>
      <div className={cx('flex justify-between items-center', Style['card-footer'])}>
        <span
          className={cx(
            Style['label'],
            'flex-initial w-1/2 overflow-hidden overflow-ellipsis whitespace-nowrap fontPoppinsSemiboldMd'
          )}
        >
          Grand Total ($) :
        </span>
        <span
          className={cx(
            Style['value'],
            'flex-initial w-1/2 overflow-hidden overflow-ellipsis whitespace-nowrap  text-right fontPoppinsSemiboldMd'
          )}
        >
          <span className={cx(Style['dollar'], 'mr-1')}>$</span>
          {getUSDFormatAmountString(grandTotal(0, netTotal, 0))}
        </span>
      </div>
    </div>
  );
}

TotalAmountCard.propTypes = {
  netTotal: PropTypes.number.isRequired,
  discount: PropTypes.number,
  tax: PropTypes.number,
  grandTotal: PropTypes.number,
  className: PropTypes.string,
  discountLabel: PropTypes.string,
};

TotalAmountCard.defaultProps = {
  discount: 0,
  tax: 0,
  grandTotal: 0,
  className: '',
  discountLabel: 'Discount',
};

export default TotalAmountCard;

import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';
import Modal from 'react-modal';
import { Tooltip } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { CommonLoader } from '@src/pages/common/common-loader';
import { getStatusTypeForPending, beforeRunningStatuses } from '../execution-dashboard-util';

const MachineDetails = (props) => {
  const [openModal, setOpenModal] = useState(true);
  const customStylesPage = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '75%',
      height: 'fit-content',
      background: '#fbfcfd 0% 0% no-repeat padding-box',
      boxShadow: '0px 0px 11px #000000a1',
      borderRadius: '4px',
      opacity: '1',
    },
  };
  let [isLoading, setIsLoading] = useState(false);
  let [machineTableData, setMachineTableData] = useState([]);
  const statusSpanRenderer = () => {
    if (beforeRunningStatuses.includes(props.data.status)) {
      return <span className="text-yellow-400">{props.data.status}</span>;
    }
    switch (props.data.status) {
      case 'Running':
        return <span className="text-blue-700">{props.data.status}</span>;
      case 'Initializing...':
        return <span className="text-yellow-400">{props.data.status}</span>;
      case 'Pending':
        return (
          <span
            className={`${getStatusTypeForPending(props.data?.executionData) === 'Checking...' ? 'text-blue-700' : 'text-yellow-400'}`}
          >
            {getStatusTypeForPending(props.data?.executionData)}
          </span>
        );
      case 'Passed':
        return <span className="text-green-600">{props.data.status}</span>;
      case 'Failed':
        return <span className="text-red-600">{props.data.status}</span>;
      case 'Terminated':
        return <span className="text-red-600">{props.data.status}</span>;
      case 'Aborted':
        return <span className="text-red-600">{props.data.status}</span>;
      case 'Warning':
        return <span className="warning-status">{props.data.status}</span>;
      default:
        return <span className="text-gray-600">{props.data.status}</span>;
    }
  };
  const data = React.useMemo(() => machineTableData, [machineTableData]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'EXECUTION ID',
        accessor: 'executionId',
        width: '12%',
      },
      {
        Header: 'EXECUTION NAME',
        accessor: 'executionName',
        width: '12%',
      },
      {
        Header: 'PROJECT',
        accessor: 'project',
        width: '9.50%',
      },
      {
        Header: 'TOTAL SCRIPT',
        accessor: 'totalScript',
        width: '12%',
      },
      {
        Header: 'RUN INITIATED BY',
        accessor: 'runInitiatedBy',
        width: '13%',
      },
      {
        Header: 'RUN INITIATED ON',
        accessor: 'runInitiatedOn',
        width: '14%',
      },
      {
        Header: 'EXECUTION START TIME',
        accessor: 'executionStartTime',
        width: '17.50%',
      },
      {
        Header: 'STATUS',
        accessor: 'status',
        width: '12%',
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const statusSpan = (ele) => {
    if (ele.executionStatus === 'Completed') {
      if (ele.resultStatus === 'PASS') {
        return <span className="text-green-600 cursor-pointer fontPoppinsRegularSm">Passed</span>;
      } else if (ele.resultStatus === 'FAIL') {
        return <span className="text-red-600 cursor-pointer fontPoppinsRegularSm">Failed</span>;
      } else if (ele.resultStatus === 'WARNING') {
        return <span className="warning-status cursor-pointer fontPoppinsRegularSm">Warning</span>;
      } else if (ele.resultStatus === 'SKIP') {
        return <span className="text-red-600 text cursor-pointer fontPoppinsRegularSm">Skipped</span>;
      } else if (ele.resultStatus === 'Terminated') {
        return <span className="text-red-600 text cursor-pointer fontPoppinsRegularSm">Terminated</span>;
      } else {
        return <span className="cursor-pointer fontPoppinsRegularSm">{ele.resultStatus}</span>;
      }
    } else {
      if (ele.executionStatus === 'Cancelled') {
        let executionStatus = ele?.currentStatus ? ele.currentStatus : ele.executionStatus;
        return <span className="text-gray-400 cursor-pointer fontPoppinsRegularSm">{executionStatus}</span>;
      } else if (['Running', 'Fetching Results', 'Processing Result'].includes(ele.executionStatus)) {
        return <span className="text-blue-600 cursor-pointer fontPoppinsRegularSm">{ele.executionStatus}</span>;
      } else if (ele.executionStatus === 'Terminated') {
        return <span className="text-red-600 cursor-pointer fontPoppinsRegularSm">{ele.executionStatus}</span>;
      } else if (ele.executionStatus === 'Aborted') {
        return <span className="text-red-600 cursor-pointer fontPoppinsRegularSm">{ele.executionStatus}</span>;
      } else if (['Pending', ...beforeRunningStatuses].includes(ele.executionStatus)) {
        return (
          <span className="text-yellow-400 cursor-pointer fontPoppinsRegularSm">
            {ele.executionStatus === 'Pending' ? getStatusTypeForPending(ele) : ele.executionStatus}
          </span>
        );
      } else {
        return <span className="text-gray-600 cursor-pointer fontPoppinsRegularSm">{ele.executionStatus}</span>;
      }
    }
  };
  const genrateTableBody = (res) => {
    setIsLoading(true);
    let machTabData = [];
    if (res && res.length) {
      res.map((ele, index) => {
        ele.id = ele._id;
        return machTabData.push({
          executionId: <span className="text-gray-700 fontPoppinsRegularSm"> {ele.id} </span>,
          executionName: (
            <Tooltip title={ele.name} placement="bottom">
              <span onClick={() => {}} className="text-blue-700 cursor-pointer fontPoppinsRegularSm">
                {ele?.name?.length > 20 ? ele.name.substring(0, 20) + '...' : ele.name}
              </span>
            </Tooltip>
          ),
          project: <span className="text-gray-700 fontPoppinsRegularSm"> {ele.projectName} </span>,
          totalScript: <span className="text-gray-700 fontPoppinsRegularSm"> {ele.totalScriptCount} </span>,
          runInitiatedBy: (
            <span onClick={() => {}} className="text-blue-700 cursor-pointer fontPoppinsRegularSm">
              {ele.createdByUname}
            </span>
          ),
          runInitiatedOn: <span className="text-gray-700 fontPoppinsRegularSm"> {ele.createdOn}</span>,
          executionStartTime: <span className="text-gray-700 fontPoppinsRegularSm"> {ele.executionStartTime}</span>,
          status: statusSpan(ele),
        });
      });
      setMachineTableData(machTabData);
    } else {
      setMachineTableData([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    genrateTableBody(props.data.executions);
  }, []);
  return (
    <Modal
      isOpen={openModal}
      style={customStylesPage}
      className="p-px"
      onRequestClose={() => {
        props.closeModal(false);
        setOpenModal(false);
      }}
    >
      <div className="mt-2 p-1">
        <span className="Popup-header-common pl-3">Machine Details - {props.data.machineName}</span>
        <div className="float-right">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="ml-5 mr-5 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="border-b border-t border-blue-100 mt-px mb-px pb-3">
        <div className="ml-6 mr-6 mt-3 grid grid-cols-3 gap-4">
          <div className="">
            <h1 className="details-key-style-common">Name</h1>
            <p title={props.data.name} className="text-gray-900 text-overflow-style">
              <span className="details-data-style-common">{props.data.machineName}</span>
            </p>
          </div>
          <div className="">
            <h1 className="details-key-style-common">OS</h1>
            <p title={props.data.desc} className="text-gray-900 text-overflow-style">
              <span className="details-data-style-common">{props.data.os}</span>
            </p>
          </div>
          <div className="">
            <h1 className="details-key-style-common">Browser</h1>
            <p className="text-gray-900">
              <span className="details-data-style-common">{props.data.browser}</span>
            </p>
          </div>
          <div className="">
            <h1 className="details-key-style-common">Device</h1>
            <p title={props.data.path} className="text-gray-900 text-overflow-style">
              <span className="details-data-style-common">{props.data.device}</span>
            </p>
          </div>
          <div className="">
            <h1 className="details-key-style-common">Status</h1>
            <p className="text-gray-900">
              <span className="details-data-style-common">{statusSpanRenderer()}</span>
            </p>
          </div>
          <div className="">
            <h1 className="details-key-style-common">Owner</h1>
            <p className="text-gray-900">
              <span className="details-data-style-common">{props.data.owner}</span>
            </p>
          </div>
          <hr className="border-dashed border-blue-100 col-span-3" />
          <div className="rounded shadow-md col-span-3 h-68">
            <div className="h-8 pt-1">
              <span className="details-key-style-common pl-2 pt-px">Execution Details</span>
              {/* <Tooltip title='Search' placement='top'>
                                <SearchOutlined fontSize='small' className="float-right text-xs cursor-pointer text-gray-700 mr-4 pt-px" />
                            </Tooltip> */}
            </div>
            <div className="h-52 overflow-auto" id="journal-scroll">
              <table {...getTableProps()} className="">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      className="text-xs h-8 bg-gray-100 hover:bg-gray-100 sticky top-0"
                    >
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps()}
                          width={column.width}
                          className="p-0 pl-3 text-left fontPoppinsSemiboldSm"
                        >
                          {column.render('Header')}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className="text text-xs">
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} className="configHover h-8 content-start">
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()} className="p-0 pl-3 text-left text-xs">
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {isLoading ? (
                <CommonLoader />
              ) : data && data.length === 0 ? (
                <div className="fontPoppinsRegularMd no-steps">
                  <p className="font-bold mb-4">No data available</p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="float-right my-3 mx-5">
        <button
          onClick={() => {
            setOpenModal(false);
            props.closeModal(false);
          }}
          type="button"
          className="gray-btn"
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default MachineDetails;

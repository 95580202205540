import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import DashboardStyle from '../styles/dashboard.module.scss';
import StripedList from './StripedList';
import Select from 'react-dropdown-select';
import moment from 'moment';
import ReactDOMServer from 'react-dom/server';
import { getIndividualProjectSuiteResultDetails } from '@src/api/api_services';

const LIST_PROPERTIES_DATA = [
  {
    key: 'name',
    label: 'Suite name',
  },
  {
    key: 'createdOn',
    label: 'Created On',
  },
  {
    key: 'createdBy',
    label: 'Created By',
  },
  {
    key: 'lastExecutedOn',
    label: 'Last executed on',
  },
  {
    key: 'lastExecutedBy',
    label: 'Last executed by',
  },
  {
    key: 'executionId',
    label: 'Executed ID',
  },
  {
    key: 'executionType',
    label: 'Executed Type',
  },
  {
    key: 'machineName',
    label: 'Machine Name',
    itemClassName: DashboardStyle['execution-suite-client-machines-item'],
    customTitle: (DataComponent) => {
      return `Machine Name : ${ReactDOMServer.renderToString(DataComponent).replace(/<[^>]*>?/gm, '')}`;
    },
  },
  {
    key: 'executionDuration',
    label: 'Executed duration',
  },
  {
    key: 'status',
    label: 'Status',
  },
];

function ExecutionClientMachines({ executionResponses, selectedMachine, setSelectedMachine, executionType }) {
  const ref = useRef();
  useEffect(() => {
    if (ref?.current) {
      let refContainer = ref?.current
        ?.getElementsByClassName('react-dropdown-select-dropdown-handle')[0]
        .querySelector('svg');
      let path = refContainer.querySelector('path');
      path.setAttribute('d', 'M12 14l-4-4h8z');
      refContainer.setAttribute('viewBox', '0 0 23 23');
    }
  }, []);

  return (
    <div
      className={cx(DashboardStyle['dropdown'], {
        [DashboardStyle['disabled']]: !executionResponses?.length || executionType?.toLowerCase() === 'sequential',
      })}
      ref={ref}
    >
      <Select
        options={executionResponses}
        values={[selectedMachine || { hostName: '--' }]}
        searchable={false}
        direction="auto"
        dropdownPosition="auto"
        labelField="hostName"
        valueField="runId"
        disabled={!executionResponses?.length || executionType?.toLowerCase() === 'sequential'}
        onChange={(values) => setSelectedMachine(values[0])}
      />
    </div>
  );
}

function ExecutionSuitesInfo({ suites, handleSuiteChange, setGetMachineSuiteIndex }) {
  const [selectedSuite, setSelectedSuite] = useState(null);
  const [selectedClientMachine, setSelectedClientMachine] = useState(null);
  const [data, setData] = useState({});

  useEffect(() => {
    setData(
      formatSuiteInfo({
        suite: selectedSuite,
        selectedMachine: selectedClientMachine,
        setSelectedMachine: setSelectedClientMachine,
      })
    );
  }, [selectedClientMachine, selectedSuite]);

  useEffect(() => {
    if (suites) {
      getSelectedSuiteData(suites[0]?._id);
    }
  }, [suites]);

  const getSelectedSuiteData = (id) => {
    getIndividualProjectSuiteResultDetails(id).then((res) => {
      const _executionSuites = res?.data?.responseObject.executionSuites;
      if (_executionSuites) {
        setSelectedSuite(_executionSuites[0]);
        setSelectedClientMachine(_executionSuites[0]?.executionResponses[0]);
      }
    });
  };
  function formatSuiteInfo({ suite, selectedMachine, setSelectedMachine }) {
    setGetMachineSuiteIndex(selectedMachine);
    return LIST_PROPERTIES_DATA.reduce((formattedSuite, { key }) => {
      let formattedValue = suite?.[key] || '';
      if (formattedValue) {
        if (key === 'status') {
          formattedValue = selectedMachine?.status;
        }
      } else if (key === 'executionDuration') {
        formattedValue = `${moment(selectedMachine?.duration || '00:00:00:000', 'HH:mm:ss:SSS').format(
          'HH:mm:ss'
        )} Hrs.`;
      } else if (key === 'machineName') {
        formattedValue = (
          <ExecutionClientMachines
            executionResponses={suite?.executionResponses || []}
            selectedMachine={selectedMachine}
            setSelectedMachine={setSelectedMachine}
            executionType={suite?.executionType}
          />
        );
      }
      return { ...formattedSuite, [key]: formattedValue };
    }, {});
  }

  function handleExecutionSuiteChange(suite) {
    if (suite?._id) {
      getSelectedSuiteData(suite?._id);
    } else {
      setSelectedSuite(suite);
    }
    handleSuiteChange(suite);
  }
  const ref = useRef();
  if (ref?.current?.props) {
    let refContainer = document.getElementsByClassName('react-dropdown-select-dropdown-handle')[0].querySelector('svg');
    let path = refContainer.querySelector('path');
    path.setAttribute('d', 'M12 14l-4-4h8z');
    refContainer.setAttribute('viewBox', '0 0 23 23');
  }
  return (
    <div className="h-full flex flex-col">
      <div className={cx('grid grid-cols-5', DashboardStyle['info-select-box'])}>
        <div className={cx('col-span-2 px-2', DashboardStyle['label'])}>Show data for</div>
        <div
          className={cx('col-span-3', DashboardStyle['dropdown'], {
            [DashboardStyle['disabled']]: !suites?.length,
          })}
        >
          <Select
            ref={ref}
            options={suites}
            values={[selectedSuite || { name: '--' }]}
            searchable={false}
            direction="auto"
            dropdownPosition="auto"
            labelField="name"
            valueField="name"
            disabled={!suites?.length}
            onChange={(values) => handleExecutionSuiteChange(values[0])}
          />
        </div>
      </div>
      <StripedList data={data} listProperties={LIST_PROPERTIES_DATA} />
    </div>
  );
}

export default ExecutionSuitesInfo;

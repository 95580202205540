import Keycloak from 'keycloak-js';

export default function Keycloakconfig() {
  const initOptions = {
    url: process?.env?.REACT_APP_KEYCLOAK_URL,
    realm: process?.env?.REACT_APP_KEYCLOAK_REALM,
    clientId: process?.env?.REACT_APP_KEYCLOAK_CLIENT_ID,
    profile: process?.env?.REACT_APP_KEYCLOAK_PROFILE,
    client_secret: process?.env?.REACT_APP_KEYCLOAK_CLIENT_SECRET,
    onLoad: 'login-required',
  };

  let keycloak = new Keycloak(initOptions);

  keycloak
    .init(initOptions)
    .then((auth) => {
      //code
    })
    .catch((e) => {
      console.error('Authenticated Failed catch', e);
    });
}

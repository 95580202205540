import React, { Fragment, Component, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { ArrowRight } from '@material-ui/icons';
import MoreVertIcon from '@mui/icons-material/MoreVert';

function MoreInfoButton(props) {
  const sendvalue = (name, node) => {
    props.methodName(name, node);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const [pageIndexValue, setPageIndex] = React.useState(0);
  const [pageIndex, setPage] = React.useState(0);
  const [margin, setMargin] = React.useState(0);

  useEffect(() => {
    if (props.type === 'nonFolder' && props?.methodListOfNonFolder?.length > 3) {
      if (pageIndexValue > 500) {
        setPage(-680);
        setMargin(150);
      }
    } else {
      if (pageIndexValue > 500) {
        setPage(-250);
        setMargin(150);
      } else {
        setPage(100);
        setMargin();
      }
    }
  }, [pageIndexValue]);

  return (
    <div className="inline">
      {props.typeToDisplay === 'more' ? (
        <>
          <Menu as="div" className="more-button relative inline-block text-left">
            {({ open }) => {
              props.moreButtonClicked && props.moreButtonClicked(open);
              return (
                <>
                  <div>
                    <Tooltip
                      title={props.folderCount === 0 ? 'More' : 'More'}
                      placement="top"
                      // placement="bottom" For v2.1
                    >
                      <Menu.Button
                        disabled={props.folderCount === 0 ? false : false}
                        // className=" flex items-center focus:outline-none ff-icon-btn action-btn" For v2.1
                        className=" flex items-center text-gray-400 focus:outline-none"
                        onMouseOver={(e) => {
                          if (props.repository !== true) {
                            let mouse = e.clientY;
                            setPageIndex(mouse);
                          }
                        }}
                      >
                        <span className="sr-only">Open options</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="#1648C6"
                        >
                          <path d="M0 0h24v24H0V0z" fill="none" />
                          <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                        </svg>
                        {/* For v2.1 */}
                        {/* <MoreVertIcon/> */}
                      </Menu.Button>
                    </Tooltip>
                  </div>

                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="z-50 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                      style={
                        props.repository !== true
                          ? { top: `${pageIndex}%`, marginRight: `${margin}%`, width: '215px' }
                          : {}
                      }
                    >
                      <div className="py-1">
                        {props.type === 'folder'
                          ? props.methodListOfFolder?.map((data, index) => (
                              <Menu.Item>
                                {({ active }) => (
                                  <div
                                    className="menu-item"
                                    onClick={() => {
                                      sendvalue(data.name, props.nodeObj);
                                    }}
                                  >
                                    <button
                                      className={classNames(
                                        active ? 'bg-gray-200 hyper_link_color-common' : '',
                                        'text-gray-700 block w-full text-left px-4 py-2 text-sm'
                                      )}
                                    >
                                      {data.name}
                                    </button>
                                  </div>
                                )}
                              </Menu.Item>
                            ))
                          : props.type === 'root'
                            ? props.methodListForRoot?.map((data, index) => (
                                <Menu.Item>
                                  {({ active }) => (
                                    <div
                                      className="menu-item"
                                      onClick={() => {
                                        sendvalue(data.name, props.nodeObj);
                                      }}
                                    >
                                      <button
                                        className={classNames(
                                          active ? 'bg-gray-200 hyper_link_color-common' : '',
                                          'text-gray-700 block w-full text-left px-4 py-2 text-sm'
                                        )}
                                      >
                                        {data.name}
                                      </button>
                                    </div>
                                  )}
                                </Menu.Item>
                              ))
                            : props.methodListOfNonFolder?.map((data, index) => (
                                <Menu.Item>
                                  {({ active }) => (
                                    <div
                                      className="menu-item"
                                      onClick={() => {
                                        sendvalue(data.name, props.nodeObj);
                                      }}
                                    >
                                      <button
                                        className={classNames(
                                          active ? 'bg-gray-200 hyper_link_color-common' : '',
                                          'text-gray-700 w-full text-left block px-4 py-2 text-sm'
                                        )}
                                      >
                                        {data.name}
                                      </button>

                                      {/* <button
                                      className={classNames(
                                        active ? "bg-gray-200 hyper_link_color-common" : "",
                                        "text-gray-700 block w-full text-left px-4 py-2 text-sm"
                                      )}
                                    >
                                      {data.name}
                                    </button> */}
                                    </div>
                                  )}
                                </Menu.Item>
                              ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              );
            }}
          </Menu>
        </>
      ) : (
        <>
          <Menu as="div" className="more-button relative inline-block text-left">
            {({ open }) => (
              <>
                <div>
                  <Menu.Button
                    className="bg-gray-100 text-gray-400 hover:text-gray-600 "
                    onMouseOver={(e) => {
                      if (props.repository !== true) {
                        let mouse = e.clientY;
                        setPageIndex(mouse);
                      }
                    }}
                  >
                    <span className="sr-only">Open options</span>
                    {props.cTooltip ? (
                      <Tooltip title={props.cTooltip} placement="right">
                        <AddCircleOutlineOutlinedIcon />{' '}
                      </Tooltip>
                    ) : (
                      <AddCircleOutlineOutlinedIcon />
                    )}
                  </Menu.Button>
                </div>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="z-50 origin-top-left absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    style={
                      props.repository !== true
                        ? { top: `${pageIndex}%`, marginRight: `${margin}%`, width: '215px' }
                        : {}
                    }
                  >
                    <div className="py-1">
                      {props.plusIconArray?.map((data, index) => {
                        let value;
                        if (data.name === 'Add Element') {
                          value = true;
                        } else {
                          value = false;
                        }
                        return (
                          <div>
                            {!value ? (
                              <Menu className=" menu-item w-full px-4 py-2 text-sm text-gray-700 hover:text-blue-700">
                                {/* {({ active }) => ( */}

                                <div
                                  className="menu-item"
                                  onClick={() => {
                                    sendvalue(data.value, props.nodeObj);
                                  }}
                                >
                                  <Tooltip title={`${data.name}`} placement="right">
                                    <button>{data.name}</button>
                                  </Tooltip>
                                </div>
                                {/* )
                              } */}
                              </Menu>
                            ) : (
                              <Menu as="div" className="more-button relative inline-block text-left">
                                {({ open }) => (
                                  <>
                                    <div>
                                      <Menu.Button
                                        className="text-gray-900 ml-4"
                                        onMouseOver={(e) => {
                                          let mouse = e.clientY;
                                          setPageIndex(mouse);
                                        }}
                                      >
                                        Add Element
                                        <ArrowRight className="ml-14" />
                                      </Menu.Button>
                                    </div>
                                    <Transition
                                      show={open}
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Menu.Items
                                        static
                                        className="z-50 origin-top-left absolute left-48 -mt-6 w-68 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                        style={
                                          props.repository !== true
                                            ? { top: `${pageIndex}%`, marginRight: `${margin}%`, width: '215px' }
                                            : {}
                                        }
                                      >
                                        <div className="py-1">
                                          <Menu className=" menu-item px-4 py-2 text-sm text-gray-700 hover:text-blue-700">
                                            {/* {({ active }) => ( */}
                                            <div
                                              className="menu-item"
                                              onClick={() => {
                                                sendvalue('element', props.nodeObj);
                                              }}
                                            >
                                              <button
                                                className={
                                                  classNames()
                                                  // active
                                                  //   ? "bg-gray-200 text-gray-900"
                                                  //   : "text-gray-700",
                                                  // " px-4 py-2 text-sm"
                                                }
                                              >
                                                Add Manually
                                              </button>
                                            </div>
                                            {/* )} */}
                                          </Menu>
                                          {/* <Menu.Item>
                                            {({ active }) => (
                                              <div
                                                className="menu-item"
                                              >
                                                <button
                                                  className={classNames(
                                                    active
                                                      ? "bg-gray-200 text-gray-900"
                                                      : "text-gray-700",
                                                    " px-4 py-2 text-sm"
                                                  )}
                                                >
                                                  <a href="/assets/ele_loc_plugin/Elements_Finder_By_Flinko.zip" type="download" >Add using Elements Locator plug in</a>
                                                </button>
                                              </div>
                                            )}
                                          </Menu.Item> */}
                                          <Menu.Item>
                                            {({ active }) => (
                                              <div
                                                className="menu-item"
                                                onClick={() => {
                                                  sendvalue('shared', props.nodeObj);
                                                }}
                                              >
                                                <button
                                                  className={classNames(
                                                    active ? 'bg-gray-200 text-gray-900' : 'text-gray-700',
                                                    ' px-4 py-2 text-sm'
                                                  )}
                                                >
                                                  Add from shared Elements
                                                </button>
                                              </div>
                                            )}
                                          </Menu.Item>
                                        </div>
                                      </Menu.Items>
                                    </Transition>
                                  </>
                                )}
                              </Menu>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </>
      )}
    </div>
  );
}

export default MoreInfoButton;

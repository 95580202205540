import { Dialog } from '@mui/material';
import LabeledInput from '@pages/common/Inputs/LabeledInput';
import LabeledPasswordInput from '@pages/common/Inputs/LabeledPasswordInput';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import style from '@pages/licenses/styles/switch-license-popup.module.scss';
import { ReactComponent as ChecklistingMan } from '@assets/svg-imports/checklisting-man.svg';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { signInRequest } from '@api/api_services';
import cx from 'classnames';
import { ReactComponent as CloseIcon } from '@assets/analytics/ShrinkIconDark.svg';
import { alertLicenseExpiredUser } from '@src/util/licenseUtils';
import { LICENSE_DETAILS_CONSTANTS } from '@src/common/ui-constants';
import { getEncryptData } from '@common/security';

function SwitchLicensePopup({ licenseId, open, onClose, licenses }) {
  const [currentSwitchedLicensedId, setCurrentSwitchedLicensedId] = useState('');
  const isCloseVisible = licenses > 1;
  const formik = useFormik({
    initialValues: {
      currentLicenseId: '',
      emailId: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().trim().required('Password is required'),
    }),
    onSubmit: (values) => {
      const payload = {
        ...values,
        password: getEncryptData(values.password),
      };
      if (formik.isValid) {
        signInRequest(payload)
          .then(({ data: response }) => {
            if (response.responseCode === 200 && response.responseObject) {
              onSwitchLicenseSuccess(response);
            } else {
              formik.setFieldError('password', response.message || 'Something went wrong!!');
              formik.setSubmitting(false);
            }
          })
          .catch((error) => {
            console.error('Failed to switch the license through API', error);
            formik.setFieldError('password', 'Something went wrong!!');
            formik.setSubmitting(false);
          });
      } else {
        formik.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    formik.resetForm();
    const user = JSON.parse(localStorage.getItem('test-optimize-user') || {});
    setCurrentSwitchedLicensedId(user.licenseId);
    formik.setFieldValue('emailId', user.userName);
    formik.setFieldValue('currentLicenseId', licenseId);
  }, [licenseId, open]);

  const onSwitchLicenseSuccess = (response) => {
    const {billingCycle, expires_in} = response?.responseObject || {}
    localStorage.setItem('license-level-timezone', response?.responseMap?.licenseTimeZone);
    localStorage.setItem('test-optimize-user', JSON.stringify(response?.responseObject || {}));
    localStorage.setItem('selected-project', JSON.stringify({ id: '', name: 'All Projects' }));
    
    let isAlertUser = alertLicenseExpiredUser(billingCycle, expires_in);
    onClose();
    if (isAlertUser) {
      window.location = '/licenses/fireflink-license';
    } else {
      window.location = '/projectmenu/project';
    }
  };

  let [switchToLicData] = licenses?.filter((license) => license?.id === licenseId);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      sx={{ '& .MuiDialog-paper': { width: 798, maxHeight: 388 } }}
      open={open && currentSwitchedLicensedId !== licenseId}
    >
      <div className={style['close-icon-wrapper']}>
        <CloseIcon onClick={onClose} className="cursor-pointer" />
      </div>
      <div className={style['wrapper']}>
        <div className={style['left-section']}>
          <div className={style['image']}>
            <ChecklistingMan className="w-56 h-56" />
          </div>
          <div>
            <h1 className={cx(style['title-description'], 'text-center fontPoppinsSemiboldMd')}>
              {LICENSE_DETAILS_CONSTANTS?.SWITCH_LICENSE_WITH_EASE}
            </h1>
            <h2 className={cx(style['title-description'], 'fontPoppinsRegularSm')}>
              {LICENSE_DETAILS_CONSTANTS?.SEAMLESSLY_SWITCH_LICENSE}
            </h2>
          </div>
        </div>
        <div className={style['right-section']}>
          <h1 className="fontPoppinsSemiboldXXlg">Switch License</h1>
          <p className={cx(style['title-description'], 'fontPoppinsRegularXs')}>
            {LICENSE_DETAILS_CONSTANTS?.TO_CHANGE_YOUR_LICENSE_TO}{' '}
            <span className={cx(style['license-name'], 'fontPoppinsSemiboldXs')}>{switchToLicData?.licenseName}</span>{' '}
            {LICENSE_DETAILS_CONSTANTS?.PLEASE_ENTER_YOUR_PASSWORD}
          </p>

          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <LabeledInput
              label="Email"
              required
              placeholder="Enter your email"
              value={formik.values.emailId}
              disabled
              autoComplete="off"
              name="emailId"
              wrapperClassName="w-64"
            />

            <LabeledPasswordInput
              label="Password"
              required
              placeholder="Enter your password"
              wrapperClassName="w-64"
              error={formik.errors.password && formik.touched.password}
              value={formik.values.password}
              onBlur={formik.handleBlur}
              autoComplete="off"
              onChange={formik.handleChange}
              name="password"
              helperText={<>{formik.touched.password && formik.errors.password}</>}
            />

            <div className="flex items-center gap-4 mt-6">
              {isCloseVisible && (
                <button
                  type="button"
                  onClick={onClose}
                  className={cx(style['close-icon-button'], 'fontMontserratMediumXs', {
                    'opacity-50': formik.isSubmitting,
                  })}
                  disabled={formik.isSubmitting}
                >
                  Close
                </button>
              )}

              <button
                type="submit"
                disabled={formik.isSubmitting}
                className={cx(
                  style['switch-license-btn'],
                  {
                    'opacity-50': formik.isSubmitting,
                  },
                  'fontMontserratMediumXs'
                )}
              >
                Switch License
              </button>
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
}

SwitchLicensePopup.propTypes = {
  licenseId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

SwitchLicensePopup.defaultProps = {
  licenseId: '',
};

export default SwitchLicensePopup;

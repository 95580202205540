import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RESULT, defectAnalyticsLabel, EXECUTION_OVERVIEW_CONSTANT } from '@src/common/ui-constants';
import StepModal from '@pages/results/components/modals/step_result_modal';
import { getFormattedDate, getFormattedTextStatus, textColor, getSuiteType } from '@pages/results/result';
import ResultTree from '@pages/results/shared/result-tree/result_tree';
import Tooltip from '@material-ui/core/Tooltip';
import BugIcon from '@pages/configuration/DefectConfig/BugIcon';
import { getStepResult } from '@api/api_services';
import { setIDB } from '@src/util/localForage_idb_controller';
import { getLicenseFeatures, isEmptyValue } from '@src/util/common_utils';
import SearchBar from '@src/pages/analytics/common/search-bar';
import NoResultsFound from '@src/pages/common/Table/NoResultFound';
import { Loader } from 'fireflink-ui';
import FilterDropDownList from '@pagescommon/filters/FilterDropDownList';

function ResultStatusTree({ isEditable = true, ...props }) {
  const licenseFeatures = getLicenseFeatures();
  const hasDefects = window.privilege.hasDefects(licenseFeatures);
  const hasCBasic = window.privilege.hasCBasic(licenseFeatures);
  const { executionName } = useParams();
  const history = useHistory();
  const search = window.location.search;
  const executionId = new URLSearchParams(search).get('executionId');
  const [suiteType, setSuiteType] = useState();
  let [userSearched, setuserSearched] = useState('');
  let [isHighlight, setIsHighlight] = useState(false);
  let [openSearch, setOpenSearch] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');

  let [foundedTreeData, setFoundedTreeData] = useState(props.treeData);
  localStorage.setItem('resultStatusTreeData', JSON.stringify(props.treeData)); // ogrinalTreebackup stored in local
  let [filterStatus, setFilterStatus] = useState('ALL'); // to display in ui selected status
  let selectedFilterStatus = useRef('ALL'); // to keep selectedFilter Value for logic

  const [displayFilterOption, setDisplayFilterOption] = useState(false);
  const [loading, setLoading] = useState(false);

  /* NOTE  :: runId condition is required to handle breadcrumbs and navigation form test dev execution dashboard and test execution status click navigation
   */
  let urlRunId = new URLSearchParams(search).get('runId');
  if (isEmptyValue(urlRunId)) {
    urlRunId = localStorage.getItem('runId');
  }

  const tree_config = {
    checkbox: false,
    icon: false, // Display node icons
  };

  let issueManagementTools = [
    {
      name: 'Jira',
    },
    {
      name: 'Slack',
    },
    {
      name: 'GitLab',
    },
    {
      name: 'FireFlink',
    },
  ];
  if (!hasDefects && !hasCBasic) {
    issueManagementTools = issueManagementTools?.filter((tool) => tool?.name !== 'FireFlink');
  }
  useEffect(() => {
    (() => {
      const _suitetype = getSuiteType(executionId).then((value) => {
        setSuiteType(value);
      });
    })();
  }, []);

  const [openStepModal, setOpenStepModal] = useState(false);
  const [stepResultData, setStepResultData] = useState({});

  const onNodeSelected = (nodeObj) => {
    const search = window.location.search;
    const executionId = new URLSearchParams(search).get('executionId');
    if (nodeObj.node.type !== 'preCondition' && nodeObj.node.type !== 'postCondition') {
      if (nodeObj.node.type === 'step') {
        viewStepResultModal(nodeObj);
      } else {
        if (props.hasOwnProperty('onNodeSelected')) {
          props.onNodeSelected(nodeObj);
        } else {
          if (nodeObj.data.folder) {
            const node = nodeObj.node;
            var dict = node.toDict(true, function (dict, node) {
              // Remove partsel, selected because they are not required
              // Remove data object from dict to make it like input json object
              const data = { ...dict.data };
              delete dict.partsel;
              delete dict.selected;
              delete dict.data;
              Object.assign(dict, data);
            });
            setIDB('sub-tree-data', dict);
            if (
              nodeObj.node.data.status !== 'SKIP' ||
              (suiteType === 'Manual' && nodeObj.node.data.status === 'SKIP')
            ) {
              if (nodeObj?.node?.data?.status === 'N/A' || nodeObj?.node?.data?.status === 'Aborted') return;
              history.push({
                pathname: `/testdevelopment/Execution Dashboard/${executionName}/Root Module/${nodeObj.node.title}/result`,
                search: `?executionId=${executionId}&id=${nodeObj.node.key}`,
              });
            }
          } else {
            if (nodeObj.node.type === 'Script') {
              setIDB('sub-tree-data', props.treeData[0]);
              const node = nodeObj.node;
              const scriptData = [nodeObj.data];
              const clientId = node.parent.data.selectedSystem.clientId;
              setIDB('script-data', scriptData);
              if (
                nodeObj.node.data.status !== 'SKIP' ||
                (suiteType === 'Manual' && nodeObj.node.data.status === 'SKIP')
              ) {
                if (nodeObj?.node?.data?.status === 'N/A' || nodeObj?.node?.data?.status === 'Aborted') return;
                history.push({
                  pathname: `/testdevelopment/Execution Dashboard/${executionName}/Root Module/${nodeObj.node.parent.title}/${nodeObj.node.title}/script/result`,
                  search: `?id=${node.key}&executionId=${executionId}&clientId=${clientId}${
                    urlRunId ? `&runId=${urlRunId}` : ''
                  }&moduleId=${node.parent.key}&moduleName=${node.parent.title}`,
                });
              }
            } else if (nodeObj.node.type === 'step') {
              viewStepResultModal(nodeObj);
            }
          }
        }
      }
    }
  };

  const onCheckedNodes = (nodes) => {
    console.log(nodes);
  };

  const viewStepResultModal = (stepResult) => {
    let _stepdata = stepResult.data;
    if (stepResult.data.status === 'SKIP') {
      setOpenStepModal(false);
      setStepResultData(_stepdata);
    } else if (_stepdata?.uniqueId) {
      updateStepResult(_stepdata?.uniqueId);
    } else {
      setOpenStepModal(true);
      setStepResultData(_stepdata);
    }
  };

  const updateStepResult = async (uniqueId) => {
    const response = await getStepResult(uniqueId);
    if (response?.data?.responseObject) {
      setStepResultData(response?.data?.responseObject);
      setOpenStepModal(true);
    }
  };

  const closeStepModal = (val) => {
    setOpenStepModal(val);
  };
  const statusCountMsg = (nodeObj) => {
    let status_msg;
    const data = getNodeStatsData(nodeObj);
    const _status = nodeObj.data.status;
    if (_status) {
      if (_status?.toLowerCase() === 'pass') {
        status_msg = data.totalPassed + '/' + data.total + ' Passed';
      } else if (_status?.toLowerCase() === 'fail') {
        status_msg = data.totalFailed + '/' + data.total + ' Failed';
      } else if (_status?.toLowerCase() === 'skip') {
        if (data.totalSkipped >= 0) {
          status_msg = data.totalSkipped + '/' + data.total + ' Skipped';
        }
      }
    }
    return status_msg;
  };
  const getNodeStatsData = (nodeObj) => {
    let data = {};
    if (nodeObj.data.type.toLowerCase() === 'root') {
      if (nodeObj.data.moduleStats) {
        data = nodeObj.data.moduleStats;
      }
    } else if (nodeObj.data.type.toLowerCase() === 'module') {
      if (nodeObj.data.scriptStats) {
        data = nodeObj.data.scriptStats;
      }
    } else if (
      nodeObj.data.type.toLowerCase() === 'script' ||
      nodeObj.data.type.toLowerCase() === 'step' ||
      nodeObj.data.type.toLowerCase() === 'group'
    ) {
      if (nodeObj.data.stepResultStats) {
        data = nodeObj.data.stepResultStats;
      }
    } else if (
      nodeObj.data.type.toLowerCase() === 'precondition' ||
      nodeObj.data.type.toLowerCase() === 'postcondition'
    ) {
      if (nodeObj.data.stepResultStats) {
        data = nodeObj.data.stepResultStats;
      }
    }
    return data;
  };

  const getTotalSteps = (nodeObj) => {
    let statData = getNodeStatsData(nodeObj);
    let totalSteps = 'N/A';
    if (nodeObj.data && nodeObj.data.title) {
      if (
        !nodeObj.data.title.includes('root') &&
        !nodeObj.node.folder &&
        nodeObj.data.type !== 'step' &&
        nodeObj.data.type.toLowerCase() !== 'root'
      ) {
        if (statData.total) {
          totalSteps = statData.total;
        } else {
          totalSteps = 0;
        }
      }
      if (nodeObj.data.type === 'step') {
        totalSteps = '';
      }
    }
    return totalSteps;
  };

  const getTotalStepsHTML = (nodeObj) => {
    const _totalSteps = getTotalSteps(nodeObj);
    if (_totalSteps === 'N/A') {
      let _tooltipMsg = '';
      if (nodeObj.data.type.toLowerCase() === 'precondition') {
        _tooltipMsg = 'Preconditions step count is shown in individual Step group';
      } else if (nodeObj.data.type.toLowerCase() === 'postcondition') {
        _tooltipMsg = 'Postconditions step count is shown in individual Step group';
      } else if (nodeObj.data.type.toLowerCase() === 'module' || nodeObj.data.type.toLowerCase() === 'root') {
        _tooltipMsg = 'Modules step count is shown in individual Script';
      }
      return (
        <Tooltip title={_tooltipMsg} placement="top">
          <span className="tree-coldata-style" name="total-steps">
            {_totalSteps}
          </span>
        </Tooltip>
      );
    } else {
      return (
        <span className="tree-coldata-style" name="total-steps">
          {_totalSteps}
        </span>
      );
    }
  };
  const LABELS = {
    EXPAND_ALL: RESULT.EXPAND_ALL,
    COLLAPSE_ALL: RESULT.COLLAPSE_ALL,
    SHOW_ALL_ELEMENTS: RESULT.SHOW_ALL_SCRIPTS,
    HIDE_ALL_ELEMENTS: RESULT.HIDE_ALL_SCRIPTS,
    SHOW_PRE_POST_CONDITION: RESULT.SHOW_PRE_POST_CONDITION,
    HIDE_PRE_POST_CONDITION: RESULT.HIDE_PRE_POST_CONDITION,
  };

  const colDefs = [
    {
      field: 'title',
      title: 'MODULE',
      class: 'title',
      isTitle: true,
      width: `calc(100% - 900px)`,
      folderTitleWidth: `calc(100% - 900px)`,
      paddingRight: '8px',
      render: (nodeObj) => {
        return (
          <>
            {nodeObj.isRootNode && nodeObj.data.subModuleCount >= 0 ? (
              <>
                <span className="count-badge folder-count ml-2">M {nodeObj.data.subModuleCount}</span>
                <span className="count-badge file-count"> S {nodeObj.data.scriptCount}</span>
              </>
            ) : (nodeObj.data.type === 'Group' ||
                nodeObj.data.type.toLowerCase() === 'precondition' ||
                nodeObj.data.type.toLowerCase() === 'postcondition') &&
              nodeObj.data.status &&
              nodeObj.data.status !== 'No Result' ? (
              nodeObj.data.status === 'PASS' ? (
                <span className="status-passed  text-xs font-semibold ml-3">{statusCountMsg(nodeObj)}</span>
              ) : nodeObj.data.status === 'FAIL' ? (
                <span className="status-failed  text-xs font-semibold ml-3">{statusCountMsg(nodeObj)}</span>
              ) : nodeObj.data.status === 'WARNING' ? (
                <span className="status-warning  text-xs font-semibold ml-3">{statusCountMsg(nodeObj)}</span>
              ) : nodeObj.data.status === 'SKIP' ? (
                <span className="status-skipped  text-xs font-semibold ml-3">{statusCountMsg(nodeObj)}</span>
              ) : null
            ) : null}
          </>
        );
      },
    },
    {
      field: 'type',
      title: 'TYPE',
      class: 'version',
      width: '90px',
      render: (nodeObj) => {
        return (
          <>
            {!nodeObj?.isRootNode && (
              <Tooltip placement="top-start" title={nodeObj?.data?.scriptType}>
                <span className="locator-count-style truncate block" name="script-type">
                  {nodeObj?.data?.scriptType}
                </span>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      field: 'totalSteps',
      title: 'STEP',
      class: 'modified-by',
      width: '40px',
      render: (nodeObj) => {
        return <>{nodeObj?.isRootNode ? 'N/A' : getTotalStepsHTML(nodeObj)}</>;
      },
    },
    {
      field: 'state',
      title: 'PASSED',
      class: 'action',
      width: '50px',
      render: (nodeObj) => {
        let statData = getNodeStatsData(nodeObj);
        return (
          <>
            <span className="tree-coldata-style" name="passed">
              {statData.totalPassed ? statData.totalPassed : 0}
            </span>
          </>
        );
      },
    },
    {
      field: 'state',
      title: 'FAILED',
      class: 'action',
      width: '50px',
      render: (nodeObj) => {
        let statData = getNodeStatsData(nodeObj);
        return (
          <>
            <span className="tree-coldata-style" name="failed">
              {statData.totalFailed ? statData.totalFailed : 0}
            </span>
          </>
        );
      },
    },
    {
      field: 'warning',
      title: 'WARNING',
      class: 'action',
      width: '64px',
      render: (nodeObj) => {
        let statData = getNodeStatsData(nodeObj);
        return (
          <>
            <span className="tree-coldata-style" name="warning">
              {statData.totalWarning ? statData.totalWarning : 0}
            </span>
          </>
        );
      },
    },
    {
      field: 'skipped',
      title: 'SKIPPED',
      class: 'skipped',
      width: '45px',
      render: (nodeObj) => {
        let statData = getNodeStatsData(nodeObj);
        return (
          <>
            <span className="tree-coldata-style" name="skipped">
              {statData.totalSkipped ? statData.totalSkipped : 0}
            </span>
          </>
        );
      },
    },
    {
      field: 'status',
      title: 'STATUS',
      class: 'status',
      width: '115px',
      render: (nodeObj) => {
        const _status = nodeObj?.data?.status;
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {_status && !suiteType ? (
              <span
                className={`${
                  nodeObj?.data?.status === 'N/A' || nodeObj?.data?.status === 'Aborted' ? '' : 'cursor-pointer'
                }  ${textColor(_status)}`}
                name="status"
                onClick={
                  _status.toLowerCase() !== 'skip'
                    ? () => {
                        if (
                          (nodeObj?.data.type === 'Script' || nodeObj?.data.type === 'Module') &&
                          nodeObj?.data?.status === 'Aborted'
                        )
                          return;
                        if (
                          (nodeObj?.data.type === 'Script' || nodeObj?.data.type === 'Module') &&
                          nodeObj?.data?.status === 'N/A'
                        )
                          return;
                        onNodeSelected(nodeObj);
                      }
                    : () => {
                        if (nodeObj.node.data.status === 'SKIP') {
                          onNodeSelected(nodeObj);
                        }
                      }
                }
              >
                {getFormattedTextStatus(_status)}
              </span>
            ) : suiteType ? (
              <div
                className="platform-title text-xs xs:text-xs sm:text-xs md:text-xs lg:text-sm xl:text-sm"
                onClick={() => handleStatusClick(nodeObj)}
              >
                <span className={`${textColor(_status)}`}>{getFormattedTextStatus(_status)}</span>
              </div>
            ) : (
              ''
            )}
            {nodeObj?.data.type === 'Script' && (
              <>
                {getFormattedTextStatus(_status) === 'Failed' && isEditable ? (
                  <div className={`m-2.5 cursor-pointer ${textColor(_status)}`}>
                    <BugIcon
                      MyAlert={props?.MyAlert}
                      history={history}
                      issueData={{
                        section: 'resultTree',
                        runId: urlRunId,
                        nodeObj: nodeObj,
                        systemData: props?.rootData?.platformStatistics[0],
                        executionId: executionId,
                        clientId: props?.rootData?.selectedSystem.clientId,
                      }}
                    />
                  </div>
                ) : (
                  ''
                )}
              </>
            )}
          </div>
        );
      },
    },
    {
      field: 'state',
      title: 'START TIME',
      class: 'action ff-text-wrap',
      width: '150px',
      render: (nodeObj) => {
        return (
          <span className="tree-coldata-style " title={nodeObj.data.executedOn} name="startTime">
            {nodeObj.data.executedOn}
          </span>
        );
      },
    },
    {
      field: 'state',
      title: 'DURATION',
      class: 'action',
      width: '90px',
      render: (nodeObj) => {
        return (
          <>
            {props.suiteType !== 'Manual' ? (
              <span className="tree-coldata-style" name="Execution Duration">
                {nodeObj.data.executionDurationInHourMinSecFormat
                  ? nodeObj.data.executionDurationInHourMinSecFormat
                  : '00:00:00:00'}
              </span>
            ) : (
              ''
            )}
          </>
        );
      },
    },
  ];
  if (props.suiteType === 'Manual') {
    colDefs.splice(colDefs.length - 1, 1);
  }
  const handleStatusClick = (nodeObj) => {
    onNodeSelected(nodeObj);
  };

  const headerLabel =
    props.rootData && props.rootData.title.toLowerCase() === 'root'
      ? props.rootData.title.concat(' Module')
      : props.rootData.title;

  // TODO :: handle Search filter :: START

  // TODO :: reset to original data
  let setDefaultData = () => {
    let originalData = JSON.parse(localStorage.getItem('resultStatusTreeData'));
    setLoading(true);
    setFoundedTreeData([]);
    let timer = setTimeout(() => {
      setFoundedTreeData(originalData);
      setLoading(false);
      clearTimeout(timer);
    }, 10);
  };

  function filterArrayByStatus(dataArray, selectedValues, targetkey) {
    return dataArray.filter((obj) => {
      if (obj.folder || !isEmptyValue(obj.children)) {
        obj.expanded = true;
        if (obj.children) {
          obj.children = filterArrayByStatus(obj.children, selectedValues, targetkey);
          return obj.children.length > 0 || selectedValues.includes(obj[targetkey]);
        }
      } else {
        return selectedValues.includes(obj[targetkey]);
      }
      return null;
    });
  }

  function filterArrayByTitle(dataArray, searchkey) {
    return dataArray?.filter((obj) => {
      if (obj.folder || !isEmptyValue(obj.children)) {
        obj.expanded = true;
        if (
          (obj.folder && obj.title?.toLowerCase().includes(searchkey?.toLowerCase())) ||
          (!isEmptyValue(obj.children) && obj.title?.toLowerCase().includes(searchkey?.toLowerCase()))
        ) {
          return true;
        } else if (obj.children) {
          obj.children = filterArrayByTitle(obj.children, searchkey);
          return obj.children.length > 0;
        }
      } else {
        return obj.title?.toLowerCase().includes(searchkey?.toLowerCase());
      }
      return null;
    });
  }

  React.useEffect(() => {
    if (isEmptyValue(userSearched)) {
      if (selectedFilterStatus?.current !== 'ALL') {
        handleSearchSubmit('', 'RESET');
      } else {
        setDefaultData();
      }
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let handleSearchSubmit = (e, action) => {
    let originalData = JSON.parse(localStorage.getItem('resultStatusTreeData'));
    let filteredRes = originalData || [];

    if (action === defectAnalyticsLabel.btnLabel.apply || selectedFilterStatus?.current !== 'ALL') {
      setLoading(true);

      if (!isEmptyValue(filteredRes) && selectedFilterStatus?.current) {
        filteredRes = filterArrayByStatus(filteredRes, [selectedFilterStatus?.current], 'status');
      }

      let searchText = String(e?.target?.value?.trim() || e?.trim())?.toLowerCase() || '';
      if (!isEmptyValue(filteredRes) && e) {
        if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
          if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
            filteredRes = filterArrayByTitle(filteredRes, searchText);
          }
        }
      }
      setSearchTextHighlight(searchText);
      setIsHighlight(!isHighlight);

      setFoundedTreeData([]);
      let timer = setTimeout(() => {
        setFoundedTreeData(filteredRes);
        setLoading(false);
        clearTimeout(timer);
      }, 10);
    } else if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        setLoading(true);
        let searchText = String(e?.target?.value?.trim() || e?.trim())?.toLowerCase();
        filteredRes = filterArrayByTitle(filteredRes, searchText);

        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
        setFoundedTreeData([]);
        setFoundedTreeData([]);
        let timer = setTimeout(() => {
          setFoundedTreeData(filteredRes);
          clearTimeout(timer);
          setLoading(false);
        }, 10);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  let handleApplyFilter = (status) => {
    setFilterStatus(status);
    selectedFilterStatus.current = status;
    if (status === 'ALL') {
      setDisplayFilterOption(false);
      if (searchTextHighlight) {
        handleSearchSubmit(searchTextHighlight);
      } else {
        setDefaultData();
      }
    } else {
      setDisplayFilterOption(false);
      handleSearchSubmit(searchTextHighlight, defectAnalyticsLabel.btnLabel.apply);
    }
  };

  // TODO :: handle Search filter :: END

  let treeConditionValues = [
    { key: 'isPreCondition', value: true },
    { key: 'isPostCondition', value: true },
  ];

  let renderTreeData = null;

  // IF EXECUTION RESULT DATA IS NOT AVAILABLE
  if (isEmptyValue(props.treeData)) {
    renderTreeData = <label>{EXECUTION_OVERVIEW_CONSTANT.NO_DATA_AVALIABLE}</label>;
  } else if (loading) {
    renderTreeData = (
      <div className="flex items-center justify-center my-20 mx-auto">
        <Loader variant="BouncingDots" />
      </div>
    );
  } else if (isEmptyValue(foundedTreeData) && (searchTextHighlight || selectedFilterStatus?.current !== 'ALL')) {
    // IF SEARCH, FILTER DATA NOT FOUND
    renderTreeData = <NoResultsFound />;
  } else if (!isEmptyValue(foundedTreeData) && searchTextHighlight && selectedFilterStatus?.current !== 'ALL') {
    //IF SEARCH AND FILTER DATA FOUND
    renderTreeData = (
      <ResultTree
        id="result-status-tree"
        data={foundedTreeData}
        config={tree_config}
        colDefs={colDefs}
        expandAll={true}
        onNodeSelected={onNodeSelected}
        rootData={props.rootData}
        showCheckbox={false}
        onCheckedNodes={onCheckedNodes}
        hideElements={false}
        suiteType={suiteType}
        hideConditions={false}
        conditions={treeConditionValues}
        hideConditionBtn={false}
        labels={LABELS}
        userSearched={searchTextHighlight}
      />
    );
  } else if (!isEmptyValue(foundedTreeData) && (searchTextHighlight || selectedFilterStatus?.current !== 'ALL')) {
    //IF SEARCH OR FILTER DATA FOUND
    renderTreeData = (
      <ResultTree
        id="result-status-tree"
        data={foundedTreeData}
        config={tree_config}
        colDefs={colDefs}
        expandAll={true}
        onNodeSelected={onNodeSelected}
        rootData={props.rootData}
        showCheckbox={false}
        onCheckedNodes={onCheckedNodes}
        hideElements={false}
        suiteType={suiteType}
        hideConditions={false}
        conditions={treeConditionValues}
        hideConditionBtn={false}
        labels={LABELS}
        userSearched={searchTextHighlight}
      />
    );
  } else if (
    !isEmptyValue(foundedTreeData) &&
    isEmptyValue(searchTextHighlight) &&
    selectedFilterStatus?.current === 'ALL'
  ) {
    // IF NO SEARCH, FILTER APPLIED SHOW ORIGINAL STATE DATA
    renderTreeData = (
      <ResultTree
        id="result-status-tree"
        data={foundedTreeData}
        config={tree_config}
        colDefs={colDefs}
        expandAll={false}
        onNodeSelected={onNodeSelected}
        rootData={props.rootData}
        showCheckbox={false}
        onCheckedNodes={onCheckedNodes}
        hideElements={false}
        suiteType={suiteType}
        hideConditions={true}
        conditions={treeConditionValues}
        hideConditionBtn={false}
        labels={LABELS}
        userSearched={''}
      />
    );
  }

  return (
    <div className="result-tree-bs">
      <div className="search_text">
        <div className="script-name p-3 truncate w-1/2">
          <Tooltip placement="top-start" title={headerLabel}>
            <span>{headerLabel}</span>
          </Tooltip>
        </div>
        <div className="mt-2 mr-2 flex gap-2 ">
          <SearchBar
            open={openSearch}
            searchValue={userSearched}
            handleSearch={handleSearch}
            onSearchClose={handleSearchClose}
            handleSearchOpen={handleSearchOpen}
            enterKeyHandler={handleSearchSubmit}
            disable={isEmptyValue(props?.treeData)}
          />

          <FilterDropDownList
            data={props.treeData}
            selectedStatus={filterStatus}
            open={displayFilterOption}
            onApply={handleApplyFilter}
            onClickFilter={setDisplayFilterOption}
          />
        </div>
      </div>
      {/*TODO :: SHOW EXECUITON RESULT TREE DATA  */}
      {renderTreeData}

      {openStepModal && stepResultData && <StepModal stepResult={stepResultData} closeStepModal={closeStepModal} />}
    </div>
  );
}

export default ResultStatusTree;

import { SearchOutlined } from '@material-ui/icons';
import React, { useState, useContext, useEffect } from 'react';
import Modal from 'react-modal';
import ReactTable from '@src/pages/common/Table/ReactTable';
import { getExcelSheetDataReq } from '@api/api_services';
import { CommonLoader } from '@src/pages/common/common-loader';
import DataProviderStyle from '@pages/test-development/script/scripts/settings/dataproviders/dataprovider.module.scss';
import cx from 'classnames';
import { ProgressContainerContext } from '@src/common/ProgressContainer';

Modal.setAppElement('#root');

const ViewDataprovider = (props) => {
  const [openModal, setOpenModal] = useState(true);
  let [columnHeader, setColumnHeader] = useState([]);
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  let [isLoading, setIsLoading] = useState(false);
  let [sheetData, setSheetData] = useState([]);

  const customStylesPage = {
    overlay: {
      maxHeight: 'fit-content !important',
    },
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '1100px',
      height: 'fit-content',
      background: '#fbfcfd 0% 0% no-repeat padding-box',
      boxShadow: '0px 0px 11px #000000a1',
      borderRadius: '4px',
      opacity: '1',
    },
  };

  const getExcelSheetData = async () => {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    setIsLoading(true);
    try {
      const response = await getExcelSheetDataReq(props.data.fileId, props.data.sheetName);
      stopProgress();
      if (response.data && response.data.responseObject) {
        const responseData = JSON.parse(response.data.responseObject);
        const head = Object.keys(responseData[0]);
        setColumnHeader([...head]);
        setSheetData(responseData);
      }
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 10,
      });
      setIsLoading(false);
    } catch (err) {
      resetProgress();
      console.error('GET_EXCEL_SHEET_DATA : ', err);
    }
  };
  const data = React.useMemo(() => {
    let x = [];
    if (columnHeader.length > 0 && sheetData.length > 0) {
      sheetData.forEach((obj, index) => {
        let newRow = {};
        let i = 0;
        for (const key in obj) {
          newRow[`col${i}`] = <span className="text-gray-700 fontPoppinsRegularMd">{obj[key]} </span>;
          i++;
        }
        x.push(newRow);
      });
      return x;
    } else {
      return [];
    }
  }, [columnHeader, sheetData]);

  const columns = React.useMemo(() => {
    if (columnHeader.length > 0) {
      let x = [];
      columnHeader.forEach((value, index) => {
        x.push({
          Header: value,
          accessor: `col${index}`,
        });
      });
      return x;
    } else {
      return [];
    }
  }, [columnHeader]);
  useEffect(() => {
    getExcelSheetData();
  }, []);
  return (
    <Modal
      isOpen={openModal}
      style={customStylesPage}
      className="p-px"
      onRequestClose={() => {
        props.closeModal(false);
        setOpenModal(false);
      }}
    >
      <div className="mt-2">
        <span className="Popup-header-common pl-5"> View Dataprovider -{props.data.name}</span>
        <button
          onClick={() => {
            setOpenModal(false);
            props.closeModal(false);
          }}
          type="button"
          className="float-right mr-5 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div className="border-b border-t border-blue-100 mt-2 mb-2">
        <div className="ml-8 mt-3 mb-3">
          <div className="mr-8 inline-block">
            <h1 className="details-key-style-common">File Name</h1>
            <p className="text-gray-900">
              <span className="details-data-style-common">{props.data.fileName}</span>
            </p>
          </div>
          <div className="ml-40 inline-block">
            <h1 className="details-key-style-common">Sheet Name</h1>
            <p className="text-gray-900">
              <span className="details-data-style-common">{props.data.sheetName}</span>
            </p>
          </div>
        </div>
        <div className={cx('bg-white mx-8 mb-4 rounded-sm shadow-md', DataProviderStyle['modal-body-height'])}>
          <div className="mt-3 py-2.5 pl-5 pr-3.5">
            <span className="text-blue-600 fontPoppinsSemiboldMd">{props.data.name}</span>
            {/* <span className="actiontooltip float-right">
                            <SearchOutlined className="float-right text-xs cursor-pointer text-gray-700" />
                            <span className="actiontooltiptext">Search</span>
                        </span> */}
          </div>
          <div className={cx(DataProviderStyle['scrollbar'], DataProviderStyle['scrollbar'])}>
            <ReactTable data={data} columns={columns} tableHeightClass={DataProviderStyle['table-height']} />
            {isLoading ? (
              <CommonLoader />
            ) : data && data.length === 0 ? (
              <div className="fontPoppinsRegularMd no-steps">
                <div className="">
                  <p className="font-bold mb-4">No data available</p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <button
        onClick={() => {
          setOpenModal(false);
          props.closeModal(false);
        }}
        type="button"
        className="float-right gray-btn mr-6 mb-2"
      >
        Close
      </button>
    </Modal>
  );
};

export default ViewDataprovider;

import React from 'react';
import Select from 'react-dropdown-select';
import styled from '@emotion/styled';
import { Label } from '@src/common/atoms/LabelComponent';
import './select-component.scss';
import { getBrowserName } from '@src/pages/configuration/system_config/system_config v1.1/utils/sys-config-common-methods';

const Item = styled.div`
  display: flex;
  margin: 10px;
  align-items: baseline;
  cursor: pointer;
  border-bottom: 1px dotted transparent;

  ${({ disabled }) =>
    disabled
      ? `
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
`
      : ''}
`;
const ItemLabel = styled.div`
  margin: 5px 10px;
`;
function SelectBox({
  options = [],
  optionPosition = 'auto',
  showLabel = true,
  disabled = false,
  selectedOption = [],
  handleOptionChange = () => {},
  placeholder = 'Search and Select',
  error = '',
  errMsg = '',
  searchable = true,
  backspaceDelete = true,
  labelProps = {},
  isBrowserDropdown = false,
  toolTipKey = 'value',
  tooltipPosition = 'top-end',
  ...props
}) {
  const commonDropdownRenderer = ({ props, methods, state }) => {
    const regexp = new RegExp(state.search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
    const options = props.options.filter((item) => regexp.test(item[props.searchBy] || item[props.labelField]));
    return options?.length === 0 ? (
      <div className="noData px-2 py-4">No options</div>
    ) : (
      <div className="overflow-y-auto" id="journal-scroll">
        <div className="select-options-dropdown">
          {options.map((option) => {
            const classNames = `px-2 ${methods.isSelected(option) ? 'selected-option' : ''}`;
            return (
              <div
                key={option[props.labelField]}
                className={classNames}
                title={isBrowserDropdown ? getBrowserName(option.value) : option[toolTipKey]}
              >
                <Item
                  disabled={option.disabled}
                  key={option[props.valueField]}
                  className=""
                  onClick={option.disabled ? null : () => methods.addItem(option)}
                >
                  <ItemLabel>{option[props.labelField]}</ItemLabel>
                </Item>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  return (
    <>
      <div className={`select-drop-down ${error ? 'border-red-error' : ''}`}>
        {showLabel && <Label {...labelProps} />}
        <Select
          dropdownPosition={optionPosition}
          options={options}
          closeOnSelect={true}
          searchable={searchable}
          backspaceDelete={backspaceDelete}
          className="details-data-style-common"
          values={selectedOption || []}
          disabled={disabled}
          searchBy="value"
          dropdownRenderer={(innerProps, innerState, innerMethods) =>
            commonDropdownRenderer(innerProps, innerState, innerMethods)
          }
          onChange={(values) => {
            handleOptionChange(values);
          }}
          multi={false}
          placeholder={placeholder}
          {...props}
        />
      </div>
      {error && <p className="overflow-hidden overflow-ellipsis whitespace-nowrap field-error-message">{errMsg}</p>}
    </>
  );
}

export default SelectBox;

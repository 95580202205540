import React, { useState, useEffect, useRef } from 'react';

import useToggle from '@src/hooks/useToggle';
import '../css/snippet.scss';
import SnippetEditorUpdate from '../js/snippet_Editor_Update';
import { getAllSnippet, postCompileSnippetAPI, updateSnippet } from '@api/api_services';
import MyInput from '@pagescommon/Inputs/MyInput';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DownloadIcon from '../images/download.svg';
import CollapseIcon from '../images/expand-icon-2.svg';
import ExpandIcon from '../images/expand-icon-1.svg';
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import CommonButton from '@src/pages/common/button/CommonButton';
import ArrowExpandWhite from '../images/arrow-expand-white.svg';
import ArrowCollapseWhite from '../images/arrow-collapased-white.svg';
import ArrowExpand from '../images/arrow_expand.svg';
import ArrowCollapse from '../images/arrow_collapased.svg';
import { colors } from '@src/css_config/colorConstants.js';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';

const Snippet_Update = (props) => {
  const [projectTypeList, setProjectTypeList] = useState([]);
  const [isOpen, toggle] = useToggle(true);
  const [openValue, setOpenValue] = useState(false);
  const childRef = useRef();
  const [aceValue, setAceValue] = useState('');
  let [valueofAce, setvalueofAce] = useState('');
  let [customSnippet, setCustomSnippet] = useState('');
  let [nameofSnippet, setNameofSnippet] = useState('');
  let [errorContent, setErrorContent] = useState('No Log Yet');
  let [displayConsole, setDisplayConsole] = useState(true);
  let [displayToggle, setDisplayToggle] = useState('toggle_Data');
  const [onBlurhandle, SetOnBlurHandle] = useState(false);
  const [errorRow, setErrorRow] = useState('rowInputFieldSearchInitial');
  const [errorColor, setErrorColor] = useState('color_input');
  const [compileAndSavebtn, setCompileAndSaveBtn] = useState('disabled-btn');
  const [expandClick, setExpandClick] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    props.restrictOfIssue(false);
    getTablevalue();
    let val = props.updateVariableData;
    setNameofSnippet(val.name);
    setvalueofAce(val.customSnippetDefinition);
    setErrorRow('rowInputFieldSearchInitial');
  }, []);

  const getTablevalue = async () => {
    let res = await getAllSnippet(props.projectId, props.scriptId, props.moduleId);
    let finalResult = res.data.success;
    setProjectTypeList([...finalResult]);
  };

  const compileAndSave = async () => {
    const regex = /^[a-zA-Z0-9_() ]+$/;
    if (nameofSnippet === '') {
      props.MyAlert.alertbanner('Snippet Description is Required');
      SetOnBlurHandle(true);
      setErrorRow('rowInputFieldSearchValue');
      setErrorColor('color_error');
    } else if (nameofSnippet.charAt(0) === ' ' || nameofSnippet.charAt(nameofSnippet.length - 1) === ' ') {
      props.MyAlert.alertbanner('Space is not allowed at starting and at the end');
    } else if (!regex.test(nameofSnippet)) {
      props.MyAlert.alertbanner('Snippet Description should be alphanumeric');
    } else if (aceValue === '') {
      props.MyAlert.alertbanner('Code is Required');
    } else {
      if (aceValue !== '' && nameofSnippet !== '') {
        const data = {
          code: '',
          customSnippetDefinitions: [aceValue],
        };
        try {
          const response = await postCompileSnippetAPI(data);
          props.backCallOfMainPageFromEdit(false);
          if (response.data.success === null && response.data.errors === null) {
            createSnippetFor();
            setErrorContent('Snippet Compiled SuccessFully');
            setDisplayConsole(true);
            setDisplayToggle('toggle_Console');
          } else {
            setDisplayConsole(true);
            setDisplayToggle('toggle_Console');
            setErrorContent(response.data.errors.details);
          }
        } catch (error) {
          console.error('Error_in_snippet_update', error);
        }
      }
    }
  };

  const createSnippetFor = async () => {
    props.restrictOfIssue(false);
    let firstLine = aceValue.split('\n')[0];
    let projectDetails = JSON.parse(localStorage.getItem('selected-project'));
    let projectId = projectDetails.id;
    if (firstLine.includes('(')) {
      let firstSpiltBrace = firstLine.split('(');
      let firstSplitSpace = firstSpiltBrace[0];
      let ValueofSpace = firstSplitSpace.split(' ');
      let ValueofPrint = ValueofSpace.pop();

      // After Open Brace
      let beforeReverseSplitBrace = firstSpiltBrace[1].split(')');
      let CallBackFunction = ValueofPrint + '(' + beforeReverseSplitBrace[0] + ');';
      if (nameofSnippet !== '' && aceValue !== '') {
        const payload = {
          id: props.updateVariableData.id,
          name: nameofSnippet,
          description: nameofSnippet,
          customSnippetDefinition: aceValue,
          customSnippet: CallBackFunction,
        };
        await updateSnippet(payload, projectId, props.scriptId, props.moduleId)
          .then((response) => {
            if (props.reload) {
              if (response.data.errors === null) {
                props.reload(true);
              }
            }
            if (response.data.errors === null) {
              props.backCallOfMainPageFromEdit(true);
              props.getPageCallUpdate(true, nameofSnippet);
            }
            if (response.data.errors !== null) {
              SetOnBlurHandle(true);
              setErrorColor('color_error');
              props.MyAlert.alertbanner(`${response.data.errors.message.replace(/\'/g, '')}`);
            }
          })
          .catch((error) => {
            console.error('Update_Snippet_Error', error);
          });
      }
    } else {
      props.backCallOfMainPageFromEdit(false);
      setDisplayConsole(true);
      props.MyAlert.alertbanner('Snippet is in improper format, Please declare the function');
      setErrorContent('Snippet Should be in the Correct Format');
    }
  };

  const getCodeValue = (code) => {
    setAceValue(code);
    if (code !== '') {
      setCompileAndSaveBtn('secondary-btn');
    } else {
      setCompileAndSaveBtn('disable-btn');
    }
  };

  const ClearIconValue = () => {
    setErrorContent('No Log Yet');
  };

  const handlelogic = (row) => {
    let findDefaultSnippet = row.hasOwnProperty('defaultSnippet');
    if (findDefaultSnippet === true) {
      childRef.current.showAlert(row.defaultSnippet);
    } else {
      childRef.current.showAlert(row.customSnippet);
      let val = row.customSnippetDefinition + ',' + customSnippet;
      console.log('Velay', val);
      setCustomSnippet(val);
    }
  };

  const backButtonClicked = () => {
    props.backCallOfMainPageFromEdit(true);
  };

  const ExpandAndContract = () => {
    setDisplayConsole(false);
  };

  const ExpandAndCollapse = () => {
    setDisplayConsole(true);
  };

  const onBlurHandle = () => {
    if (nameofSnippet === '') {
      SetOnBlurHandle(true);
      setErrorRow('rowInputFieldSearchValue');
      setErrorColor('color_error');
    } else {
      SetOnBlurHandle(false);
      setErrorRow('rowInputFieldSearchInitial');
      setErrorColor('color_input');
    }
  };

  const onFocusHandle = () => {
    if (nameofSnippet === '') {
      SetOnBlurHandle(true);
      setErrorRow('rowInputFieldSearchValue');
      setErrorColor('color_error');
    } else {
      SetOnBlurHandle(false);
      setErrorRow('rowInputFieldSearch');
      setErrorColor('color_normal_input');
    }
  };

  const handleEvent = (e) => {
    setNameofSnippet(e.target.value);
    if (e.target.value.length > 3) {
      SetOnBlurHandle(false);
      setErrorRow('rowInputFieldSearch');
      setErrorColor('color_normal_input');
    }
  };
  return (
    <div>
      <Modal open={openValue} className="modal-dialog">
        <div className="modal-container page-modal-size1">
          <div className="modal-header">
            <h2 className="title">{`Update Snippet`}</h2>
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                setOpenValue(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="modal-body" id="journal-scroll">
            <div
              className="console_output_container_Response fontPoppinsRegularMd overflow-y-auto shadow"
              id="journal-scroll"
            >
              {aceValue}
            </div>
          </div>
          <div className="modal-footer">
            <CommonButton
              btnType="secondary"
              onClick={() => {
                setOpenValue(false);
              }}
              label="Close"
            />
          </div>
        </div>
      </Modal>
      <div className="prerequisite" id="journal-scroll">
        <div className="flex pb-2 bg-white">
          <label className=" fontPoppinsSemiboldLg flex-auto project_label -ml-5">Edit Snippet</label>
          <CustomTooltip title="Search Snippets" placement="top">
            <div className="relative left-7 hidden">
              <img
                className={`btn-right mr-4 right-0 snippet-search-svg-icon snippet-page-search-img`}
                src={DownloadIcon}
                alt="download img"
              />
            </div>
          </CustomTooltip>
          <div className="pr-2.5">
            <div className="flex flex-row">
              <CommonButton btnType="primary" onClick={backButtonClicked} label="Back" />
            </div>
          </div>
        </div>
      </div>
      <>
        <div className={`flex flex-row update-snippet-full-section`}>
          {isOpen ? (
            <div className="flex justify-start items-center snippet-update-tab-alignments">
              <label className="font-bold fontPoppinsSemiboldMd">Snippets</label>
              <div className="flex cursor-pointer margin-left-for-toggle-icon" onClick={toggle}>
                {' '}
                <MenuIcon />{' '}
              </div>
            </div>
          ) : (
            <div
              className="flex ml-2 cursor-pointer margin-left-for-toggle-icon-two rotate-toggle-menu snippet-update-toggle-alignments"
              onClick={toggle}
            >
              <MenuOpenIcon className="mt-2 ml-1" />
            </div>
          )}
          <div className={`flex snippet-text-div-alignments`}>
            <div className="snippet-my-input-width pl-3">
              <MyInput
                type="text"
                name="nameofSnippet"
                id={`${errorRow}`}
                placeholder={onBlurhandle === true ? '* Enter snippet description' : 'Enter snippet description'}
                error={onBlurhandle === true}
                onBlur={onBlurHandle}
                onFocus={onFocusHandle}
                onChange={handleEvent}
                autoComplete="off"
                helperText={onBlurhandle === true}
                inputValue={'input'}
                value={nameofSnippet}
              />
            </div>
            <div className="snippet-width-20">
              <div>
                <div className="flex items-center justify-around">
                  <button
                    className={`ml-1.5  customization-of-compile-save-btn hover-of-compile-save-btn`}
                    onClick={() => {
                      compileAndSave();
                    }}
                  >
                    {' '}
                    Compile & Update{' '}
                  </button>
                  <button
                    className={`${expandClick ? 'snippet-expand-btn-customization-in-expand-click' : ''}`}
                    onClick={(e) => {
                      setOpenValue(true);
                    }}
                  >
                    {showTooltip ? (
                      <img
                        alt="update-snippet"
                        src={expandClick ? CollapseIcon : ExpandIcon}
                        className="ExpandIcon-in-snippet-list"
                      />
                    ) : (
                      <CustomTooltip title={expandClick ? 'collapse' : 'expand'} placement="bottom">
                        <img
                          alt="update-snippet"
                          src={expandClick ? CollapseIcon : ExpandIcon}
                          className="ExpandIcon-in-snippet-list"
                        />
                      </CustomTooltip>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`flex flex-row container-height-480 bg-white`}>
          {isOpen && (
            <div className="flex flex-row left-side-panel-width">
              <div className="ml-2 mt-2 mb-2 flex-1">
                {projectTypeList.map((row, index) => (
                  <>
                    <div
                      className={`cursor-pointer snippet-left-side-panel`}
                      key={`${row.id}`}
                      onClick={() => {
                        handlelogic(row);
                      }}
                    >
                      <CustomTooltip title={row.name} placement="right">
                        <span className="select-none fontPoppinsRegularSm truncate prerequisite-left-side-text-customization pl-2">
                          <span className="mr-2">
                            <AddIcon />
                          </span>
                          {row.name}
                        </span>
                      </CustomTooltip>
                    </div>
                    <span className="h-2 flex"></span>
                  </>
                ))}
              </div>
            </div>
          )}
          <div className={`${isOpen ? 'w-4/5' : 'w-full'} p-2.5 snippet-editor-div-customization`}>
            <div
              className={`flex ${
                expandClick
                  ? 'expand-editor-alignment-snippet-editor'
                  : `${
                      displayConsole ? 'maxHeight-display-console-250' : 'maxHeight-display-console-433'
                    } overflow-hidden `
              }  snippet-editor-console-customization`}
            >
              <SnippetEditorUpdate
                ref={childRef}
                getCodeValue={getCodeValue}
                valueofAce={props.updateVariableData.customSnippetDefinition}
                expandClick={expandClick}
              />
            </div>
            <div className={expandClick ? 'h-64' : 'h-0'}></div>
            {displayConsole === true ? (
              <>
                <div
                  className={` pt-1 pb-1 border_style flex flex-wrap items-center w-auto fontPoppinsSemiboldMd console-border-in-snippet mt-3 h-9`}
                >
                  <label className="text-left text-white relative opacity-100 -left-7 top-0.5 fontPoppinsSemiboldMd flex-auto pl-4 ml-8">
                    Console
                  </label>
                  <div className="pr-4">
                    <div className="flex flex-row gap-2">
                      <button onClick={ClearIconValue} className="secondary-btn_new text-left text-white ml-3">
                        Clear
                      </button>
                      <CustomTooltip title="collapse" placement="bottom">
                        <img
                          onMouseEnter={(e) => {
                            e.target.style.backgroundColor = `${colors.text_white}`;
                            e.target.style.borderRadius = '50px';
                            e.target.src = ArrowExpand;
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = 'initial';
                            e.target.src = ArrowExpandWhite;
                          }}
                          className={` mt-1 arrow-collapse-icon cursor-pointer pre-requisite-isVisible-icon-rotate`}
                          src={ArrowExpandWhite}
                          onClick={ExpandAndContract}
                          alt="showmore"
                          width="20"
                        />
                      </CustomTooltip>
                    </div>
                  </div>
                </div>
                <div
                  className="console_output_container_snippet_element overflow-auto  pr-4 flex items-center justify-center"
                  id="journal-scroll"
                >
                  {errorContent}
                </div>
              </>
            ) : (
              <>
                <div className="pt-1 pb-1 border_style flex flex-wrap items-center w-full console-border-in-snippet-new mt-3">
                  <label className="text-left text-white relative -left-3 top-0.5  fontPoppinsSemiboldMd flex-auto pl-4 ml-2">
                    Console
                  </label>
                  <div className="pr-4">
                    <div className="flex flex-row ml-32 relative">
                      <CustomTooltip title="expand" placement="bottom">
                        <img
                          onMouseEnter={(e) => {
                            e.target.style.backgroundColor = `${colors.text_white}`;
                            e.target.style.borderRadius = '50px';
                            e.target.src = ArrowCollapse;
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = 'initial';
                            e.target.src = ArrowCollapseWhite;
                          }}
                          className={` mt-1 arrow-expand-icon cursor-pointer`}
                          src={ArrowCollapseWhite}
                          onClick={ExpandAndCollapse}
                          alt="showmore"
                          width="20"
                        />
                      </CustomTooltip>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    </div>
  );
};

export default Snippet_Update;

import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { getWaitConfigReq } from '@api/api_services';
import * as yup from 'yup';
import { UI_VALIDATIONS } from '@src/util/validations';

let waitTime = {
  implicitWait: 0,
  explicitlyWait: 0,
  delayBetweenSteps: 0,
  implicitWaitUnit: 'Secs',
  explicitlyWaitUnit: 'Secs',
  delayBetweenStepsUnit: 'Secs',
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const WaitTime = (props) => {
  const classes = useStyles();
  let waitTimeUnitArray = ['Secs', 'Msecs'];
  const [implicitmsg, setImplicitMsg] = useState('');
  const [explicitmsg, setExplictMsg] = useState('');
  const [delaybetweenMsg, setDelayBetweenMsg] = useState('');

  const formik = useFormik({
    initialValues: {
      implicitWait: props?.waitTimeData?.implicitWait
        ? props.waitTimeData.implicitWait
        : UI_VALIDATIONS.WAIT_TIME_CONFIGURATION,
      explicitlyWait: props?.waitTimeData?.explicitlyWait
        ? props.waitTimeData.explicitlyWait
        : UI_VALIDATIONS.WAIT_TIME_CONFIGURATION,
      delayBetweenSteps: props?.waitTimeData?.delayBetweenSteps ? props.waitTimeData.delayBetweenSteps : 0,
      implicitWaitUnit:
        props?.waitTimeData?.implicitWaitUnit && props?.waitTimeData?.implicitWaitUnit === 'Msecs' ? 'Msecs' : 'Secs',
      explicitlyWaitUnit:
        props?.waitTimeData?.explicitlyWaitUnit && props.waitTimeData.explicitlyWaitUnit === 'Msecs' ? 'Msecs' : 'Secs',
      delayBetweenStepsUnit:
        props?.waitTimeData?.delayBetweenStepsUnit && props.waitTimeData.delayBetweenStepsUnit === 'Msecs'
          ? 'Msecs'
          : 'Secs',
    },
  });

  function getDataCommonFun(e, type) {
    if (type === 'implicit') {
      if (e.target.value) {
        waitTime.implicitWait = e.target.value;
        if (formik?.values?.implicitWaitUnit === 'Msecs' && e.target.value > 999000) {
          setImplicitMsg('Value greater than 999000 not allowed');
          formik.setFieldValue('implicitWait', e.target.value);
        } else if (formik?.values?.implicitWaitUnit === 'Secs' && e.target.value > 999) {
          setImplicitMsg('Value greater than 999 not allowed');
          formik.setFieldValue('implicitWait', e.target.value);
        } else if (e.target.value < 0) {
          setImplicitMsg('Value less than 0 not allowed');
        } else {
          waitTime.implicitWait = e.target.value;
          formik.setFieldValue('implicitWait', e.target.value);
          setImplicitMsg('');
        }
      } else {
        waitTime.implicitWait = e.target.value;
        formik.setFieldValue('implicitWait', e.target.value);
        setImplicitMsg('implicit wait is required');
      }
    } else if (type === 'explicit') {
      if (e.target.value) {
        waitTime.explicitlyWait = e.target.value;
        if (formik?.values?.explicitlyWaitUnit === 'Msecs' && e.target.value > 999000) {
          setExplictMsg('Value greater than 999000 not allowed');
          formik.setFieldValue('explicitlyWait', e.target.value);
        } else if (formik?.values?.explicitlyWaitUnit === 'Secs' && e.target.value > 999) {
          setExplictMsg('Value greater than 999 not allowed');
          formik.setFieldValue('explicitlyWait', e.target.value);
        } else if (e.target.value < 0) {
          setExplictMsg('Value less than 0 not allowed');
        } else {
          formik.setFieldValue('explicitlyWait', e.target.value);
          setExplictMsg('');
        }
      } else {
        waitTime.explicitlyWait = e.target.value;
        formik.setFieldValue('explicitlyWait', e.target.value);
        setExplictMsg('explicit wait is required');
      }
    } else if (type === 'delayBtn') {
      if (e.target.value) {
        waitTime.delayBetweenSteps = e.target.value;
        if (formik?.values?.delayBetweenStepsUnit === 'Msecs' && e.target.value > 99000) {
          setDelayBetweenMsg('Value greater than 99000 not allowed');
          formik.setFieldValue('delayBetweenSteps', e.target.value);
        } else if (formik?.values?.delayBetweenStepsUnit === 'Secs' && e.target.value > 99) {
          setDelayBetweenMsg('Value greater than 99 not allowed');
          formik.setFieldValue('delayBetweenSteps', e.target.value);
        } else if (e.target.value < 0) {
          setDelayBetweenMsg('Value less than 0 not allowed');
        } else {
          formik.setFieldValue('delayBetweenSteps', e.target.value);
          setDelayBetweenMsg('');
        }
      } else {
        waitTime.delayBetweenSteps = e.target.value;
        setDelayBetweenMsg('delay between steps is required');
        formik.setFieldValue('delayBetweenSteps', e.target.value);
      }
    } else if (type === 'implicit_unit') {
      if (e.target.value === 'Secs') {
        waitTime.implicitWaitUnit = 'Secs';
        formik.setFieldValue('implicitWaitUnit', e.target.value);
        formik.setFieldValue('implicitWait', UI_VALIDATIONS.WAIT_TIME_CONFIGURATION);
        setImplicitMsg('');
      } else {
        waitTime.implicitWaitUnit = 'Msecs';
        formik.setFieldValue('implicitWaitUnit', e.target.value);
        formik.setFieldValue('implicitWait', UI_VALIDATIONS.WAIT_TIME_CONFIGURATION);
        setImplicitMsg('');
      }
    } else if (type === 'explicit_unit') {
      if (e.target.value === 'Secs') {
        waitTime.explicitlyWaitUnit = 'Secs';
        setExplictMsg('');
        formik.setFieldValue('explicitlyWait', UI_VALIDATIONS.WAIT_TIME_CONFIGURATION);
        formik.setFieldValue('explicitlyWaitUnit', e.target.value);
      } else {
        waitTime.explicitlyWaitUnit = 'Msecs';
        setExplictMsg('');
        formik.setFieldValue('explicitlyWait', UI_VALIDATIONS.WAIT_TIME_CONFIGURATION);
        formik.setFieldValue('explicitlyWaitUnit', e.target.value);
      }
    } else if (type === 'delayBtn_unit') {
      if (e.target.value === 'Secs') {
        waitTime.delayBetweenStepsUnit = 'Secs';
        setDelayBetweenMsg('');
        formik.setFieldValue('delayBetweenSteps', 0);
        formik.setFieldValue('delayBetweenStepsUnit', e.target.value);
      } else {
        waitTime.delayBetweenStepsUnit = 'Msecs';
        setDelayBetweenMsg('');
        formik.setFieldValue('delayBetweenSteps', 0);
        formik.setFieldValue('delayBetweenStepsUnit', e.target.value);
      }
    }
    props.getWaitTimeData(waitTime);
  }

  React.useEffect(() => {
    if (!props?.waitTimeData) {
      let projectId = JSON.parse(localStorage.getItem('selected-project')).id;
      getWaitConfigReq(projectId).then((results) => {
        let waitTimeData = results.data.responseObject;
        if (!props?.waitTimeData) {
          waitTime.implicitWait = JSON.stringify(waitTimeData.implicitWait);
          waitTime.explicitlyWait = JSON.stringify(waitTimeData.explicitWait);
          waitTime.delayBetweenSteps = JSON.stringify(waitTimeData.delayBetweenSteps);
          waitTime.explicitlyWaitUnit = props?.waitTimeData?.explicitWaitUnit === 'seconds' ? 'Secs' : 'Msecs';
          waitTime.delayBetweenStepsUnit = props?.waitTimeData?.delayBetweenStepsUnit === 'seconds' ? 'Secs' : 'Msecs';
          waitTime.implicitWaitUnit = props?.waitTimeData?.implicitWaitUnit === 'seconds' ? 'Secs' : 'Msecs';
          props.getWaitTimeData(waitTime);
        }
      });
    } else {
      waitTime = formik.values;
    }
  }, []);

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <div>
        <div className="grid grid-cols-3 left-border-style mt-2 mb-3">
          <div className="implicit-wait-style">
            <label className="implicit-explicit-label-style">
              <span className="text-red-400">* </span>Implicit Wait
            </label>
            <br></br>
            <div className="implicit-explicit-dropdown-style">
              <TextField
                id="impli"
                disabled={props?.rerunMode}
                min="0"
                value={formik.values.implicitWait}
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: formik.values.implicitWaitUnit === 'Secs' ? 999 : 999000,
                  },
                }}
                onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                onBlur={formik.handleBlur}
                onChange={(e) => getDataCommonFun(e, 'implicit')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <span className="select-tag-style">
                <select
                  disabled={props?.rerunMode}
                  defaultValue={formik.values.implicitWaitUnit}
                  onChange={(e) => getDataCommonFun(e, 'implicit_unit')}
                >
                  {waitTimeUnitArray.map((tempObj) => {
                    return <option> {tempObj}</option>;
                  })}
                </select>
              </span>
            </div>
            {implicitmsg && <div className="text-red-400 fontPoppinsRegularSm md:mt-1">{implicitmsg}</div>}
          </div>
          <div className="implicit-wait-style">
            <label className="implicit-explicit-label-style">
              <span className="text-red-400">* </span>Explicitly Wait
            </label>
            <br></br>
            <div className="implicit-explicit-dropdown-style">
              <TextField
                id="standard-number"
                disabled={props?.rerunMode}
                value={formik.values.explicitlyWait}
                onChange={(e) => getDataCommonFun(e, 'explicit')}
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: formik.values.explicitlyWaitUnit === 'Secs' ? 999 : 999000,
                  },
                }}
                onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <span className="select-tag-style">
                <select
                  disabled={props?.rerunMode}
                  defaultValue={formik.values.explicitlyWaitUnit}
                  onChange={(e) => getDataCommonFun(e, 'explicit_unit')}
                >
                  {waitTimeUnitArray.map((tempObj) => {
                    return <option> {tempObj}</option>;
                  })}
                </select>
              </span>
            </div>
            {explicitmsg && <div className="text-red-400 fontPoppinsRegularSm md:mt-1">{explicitmsg}</div>}
          </div>
          <div className="implicit-wait-style ">
            <label className="implicit-explicit-label-style">
              <span className="text-red-400">* </span> Delay Between Steps
            </label>
            <br></br>
            <div className="implicit-explicit-dropdown-style">
              <TextField
                id="standard-number"
                disabled={props?.rerunMode}
                value={formik.values.delayBetweenSteps}
                onChange={(e) => getDataCommonFun(e, 'delayBtn')}
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: formik.values.delayBetweenStepsUnit === 'Secs' ? 99 : 99000,
                  },
                }}
                onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <span className="select-tag-style">
                <select
                  disabled={props?.rerunMode}
                  defaultValue={formik.values.delayBetweenStepsUnit}
                  onChange={(e) => getDataCommonFun(e, 'delayBtn_unit')}
                >
                  {waitTimeUnitArray.map((tempObj) => {
                    return <option> {tempObj}</option>;
                  })}
                </select>
              </span>
            </div>
            {delaybetweenMsg && <div className="text-red-400 fontPoppinsRegularSm md:mt-1">{delaybetweenMsg}</div>}
          </div>
        </div>
      </div>
    </form>
  );
};

export default WaitTime;

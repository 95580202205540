import React, { useState } from 'react';
import Modal from 'react-modal';
import '@pages/test-development/test-development.scss';

function DetailsModal(props) {
  const [openModal, setOpenModal] = useState(true);
  const handleClose = () => {
    setOpenModal(false);
    props.closeModal(false);
  };

  const customStylesFolder = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: '510px',
      width: '600px',
      boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
      background: '#fbfcfd 0% 0% no-repeat padding-box',
      borderRadius: '4px',
      opacity: '1',
    },
  };
  return (
    <Modal
      isOpen={openModal}
      onRequestClose={handleClose}
      style={customStylesFolder}
      className="p-px focus:outline-none"
    >
      <div className="mt-2 p-1">
        <p className="fontPoppinsMediumLg inline-block w-10/12 pl-3 text-overflow-style">
          <span>Template</span> Details - <span className="">{props?.showDetails?.name}</span>
        </p>
        <div className="float-right">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="ml-5 mr-5 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="border-b border-t border-blue-100 mt-px mb-px pb-1">
        <div className="ml-6 mr-6 mt-3 grid grid-cols-2 gap-4">
          <div className="">
            <h1 className="details-key-style-common">Name</h1>
            <p title={props?.showDetails?.name} className="text-gray-900 text-overflow-style">
              <span className="details-data-style-common">{props?.showDetails?.name}</span>
            </p>
          </div>
          <div className="">
            <h1 className="details-key-style-common">Template Type</h1>
            <p title={props?.showDetails?.templateType} className="text-gray-900 text-overflow-style">
              <span className="details-data-style-common">
                {props?.showDetails?.templateType ? props?.showDetails?.templateType : '--'}
              </span>
            </p>
          </div>
          <div className="">
            <h1 className="details-key-style-common">From</h1>
            <p className="text-gray-900 text-overflow-style">
              <span className="details-data-style-common">{props?.showDetails?.projectId ? 'Project' : 'License'}</span>
            </p>
          </div>
          <div className="">
            <h1 className="details-key-style-common">Created By</h1>
            <p title={props?.showDetails?.createdByUname} className="text-gray-900 text-overflow-style">
              <span className="details-data-style-common">{props?.showDetails?.createdByUname}</span>
            </p>
          </div>
          <div className="">
            <h1 className="details-key-style-common">Created On</h1>
            <p className="text-gray-900">
              <span className="details-data-style-common">{props?.showDetails?.createdOn}</span>
            </p>
          </div>
          <div className="">
            <h1 className="details-key-style-common">Modified By</h1>
            <p className="text-gray-900">
              <span className="details-data-style-common">{props?.showDetails?.modifiedByUname}</span>
            </p>
          </div>
          <div className="">
            <h1 className="details-key-style-common">Modified On</h1>
            <p className="text-gray-900">
              <span className="details-data-style-common">{props?.showDetails?.modifiedOn}</span>
            </p>
          </div>
        </div>
      </div>
      <div className="">
        <button
          onClick={() => {
            props.closeModal(false);
            setOpenModal(false);
          }}
          type="button"
          className="gray-btn float-right mt-3.5 mb-3 mr-5"
        >
          Close
        </button>
      </div>
    </Modal>
  );
}

export default DetailsModal;

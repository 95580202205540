import React, { useMemo, useEffect, useState } from 'react';
import { Drawer, Box } from '@material-ui/core';
import styles from '@src/pages/licenses/components/licensedetails.module.scss';
import { ReactComponent as CloseIcon } from '@assets/analytics/ShrinkIconWhite.svg';
import { useTable } from 'react-table';
import { PROJECTDETAILS_CONSTANTS } from '@src/common/ui-constants';

const ProjectDetails = (props) => {
  const result = props?.projectDetails?.projects;
  const projectResult = props?.projectDetails?.user;

  const data = React.useMemo(
    () =>
      Object?.values(projectResult || {}).map((proVal) => ({
        userName: (
          <span
            className="content-style cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150"
            title={proVal?.userName}
          >
            {' '}
            {proVal?.userName}{' '}
          </span>
        ),
        roleName: (
          <span
            className="content-style cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150"
            title={proVal?.roleName}
          >
            {' '}
            {proVal?.roleName}{' '}
          </span>
        ),
      })),
    [projectResult]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'USER',
        accessor: 'userName',
        width: '50%',
      },
      {
        Header: 'ROLE',
        accessor: 'roleName',
        width: '50%',
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });
  return (
    <Drawer anchor="right" open={props.isOpen} onClose={props.onClose} className="memory-dialog-container">
      <Box
        sx={{ width: 577, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
        role="presentation"
        className=" overflow-y-auto"
      >
        <div className="w-full h-full flex flex-col ">
          <div className={styles.header}>
            <div className="fontPoppinsMediumMd ml-3">
              {PROJECTDETAILS_CONSTANTS.DETAILS}-{result?.name ? result?.name : ' '}
            </div>
            <div onClick={props.onClose} className={styles.closeIcon}>
              <CloseIcon />
            </div>
          </div>
          <div id="Journal-scroll" className="grid grid-cols-3 mt-4 pb-4 ml-8 overflow-y-auto max-h-72">
            <div className="overflow-hidden-common">
              <label
                htmlFor="userName"
                className="block label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm"
              >
                {PROJECTDETAILS_CONSTANTS.NAME}
              </label>
              <label
                className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1"
                title={result?.name}
              >
                {result?.name ? result?.name : <span>--</span>}
              </label>
            </div>
            <div>
              <label
                htmlFor="userName"
                className="block label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm"
              >
                {PROJECTDETAILS_CONSTANTS.TYPE}
              </label>
              <label
                className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1"
                title={result?.type}
              >
                {result?.type ? result?.type : <span>--</span>}
              </label>
            </div>
            <div className="overflow-hidden-common ">
              <label
                htmlFor="emailId"
                className="block label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm"
              >
                {PROJECTDETAILS_CONSTANTS.DESCRIPTION}
              </label>

              {result?.desc ? (
                <label
                  className="details-data-style overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm mt-1"
                  title={result?.desc}
                >
                  {result?.desc ? result?.desc : <span>--</span>}
                </label>
              ) : (
                <label className="details-no-data-style fontPoppinsRegularSm">- - - </label>
              )}
            </div>
            <div className="mt-6">
              <label
                htmlFor="userName"
                className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm"
              >
                {PROJECTDETAILS_CONSTANTS.STATUS}
              </label>
              {result?.status === 'Open' ? (
                <label className="label-success fontPoppinsMediumSm">{result?.status}</label>
              ) : result?.status === 'Closed' ? (
                <label className="closed-text fontPoppinsRegularSm">{result?.status}</label>
              ) : (
                <label className="label-archive fontPoppinsMediumSm">{'Archive'}</label>
              )}
            </div>

            {String(result?.type)?.includes('Web Services') && (
              <>
                <div className="mt-6">
                  <label
                    htmlFor="emailId"
                    className="block  label-header sm:mt-px sm:pt-2 details-key-style-common  fontPoppinsMediumSm"
                  >
                    {PROJECTDETAILS_CONSTANTS.BASEURI}
                  </label>
                  {result?.baseUri ? (
                    <label
                      className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-250 fontPoppinsRegularSm"
                      title={result?.baseUri}
                    >
                      {result?.baseUri}
                    </label>
                  ) : (
                    <label className="details-no-data-style fontPoppinsRegularSm">- - -</label>
                  )}
                </div>
                <div className="mt-6">
                  <label
                    htmlFor="emailId"
                    className="block  label-header sm:mt-px sm:pt-2 details-key-style-common  fontPoppinsMediumSm"
                  >
                    {PROJECTDETAILS_CONSTANTS.PORT}
                  </label>
                  {result?.port ? (
                    <label
                      className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-250 fontPoppinsRegularSm"
                      title={result?.port}
                    >
                      {result?.port}
                    </label>
                  ) : (
                    <label className="details-no-data-style fontPoppinsRegularSm">- - -</label>
                  )}
                </div>
              </>
            )}

            {String(result?.type)?.includes('Mobile') && (
              <>
                {' '}
                <div className="mt-6">
                  <label
                    htmlFor="appType"
                    className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm"
                  >
                    {PROJECTDETAILS_CONSTANTS.APPTYPE}
                  </label>
                  <label
                    className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-250 fontPoppinsRegularSm"
                    title={result?.appType}
                  >
                    {result?.appType}
                  </label>
                </div>
                <div className="mt-6">
                  <label
                    htmlFor="platform"
                    className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm"
                  >
                    {PROJECTDETAILS_CONSTANTS.PLATFORM}
                  </label>
                  <label
                    className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-250 fontPoppinsRegularSm"
                    title={result?.platform}
                  >
                    {result?.platform}
                  </label>
                </div>
              </>
            )}
            {String(result?.platform)?.includes('Android') && (
              <>
                <div className="mt-6">
                  <label
                    htmlFor="appActivity"
                    className="block  label-header sm:mt-px sm:pt-2 details-key-style-common  fontPoppinsMediumSm"
                  >
                    {PROJECTDETAILS_CONSTANTS.APPPACKAGE}
                  </label>
                  {result?.appPackage ? (
                    <label
                      className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm"
                      title={result?.appPackage}
                    >
                      {result?.appPackage}
                    </label>
                  ) : (
                    <label className="details-no-data-style fontPoppinsRegularSm">- - -</label>
                  )}
                </div>
                <div className="mt-6">
                  <label
                    htmlFor="appActivity"
                    className="block  label-header sm:mt-px sm:pt-2 details-key-style-common  fontPoppinsMediumSm"
                  >
                    {PROJECTDETAILS_CONSTANTS.APPACTIVITY}
                  </label>
                  {result?.appActivity ? (
                    <label
                      className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm"
                      title={result?.appActivity}
                    >
                      {result?.appActivity}
                    </label>
                  ) : (
                    <label className="details-no-data-style fontPoppinsRegularSm">- - -</label>
                  )}
                </div>
              </>
            )}

            {String(result?.platform)?.toLowerCase()?.includes('iOS'.toLowerCase()) && (
              <>
                <div className="mt-6">
                  <label
                    htmlFor="bundleId"
                    className="block  label-header sm:mt-px sm:pt-2 details-key-style-common  fontPoppinsMediumSm"
                  >
                    {PROJECTDETAILS_CONSTANTS.BUNDLEID}
                  </label>
                  {result?.bundleId ? (
                    <label
                      className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-250 fontPoppinsRegularSm"
                      title={result?.bundleId}
                    >
                      {result?.bundleId}
                    </label>
                  ) : (
                    <label className="details-no-data-style mt-1 fontPoppinsRegularSm">- - -</label>
                  )}
                </div>
              </>
            )}

            {String(result?.type)?.includes('Web') && !String(result?.type)?.includes('Web Services') && (
              <div className="mt-6">
                <label
                  htmlFor="url"
                  className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsRegularSm"
                >
                  {PROJECTDETAILS_CONSTANTS.WEBURL}
                </label>
                {result?.url ? (
                  <label
                    className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsRegularSm"
                    title={result?.url}
                  >
                    {result?.url}
                  </label>
                ) : (
                  <label className="details-no-data-style fontPoppinsRegularSm">- - -</label>
                )}
              </div>
            )}

            <div className="mt-6">
              <label
                htmlFor="userName"
                className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm"
              >
                {PROJECTDETAILS_CONSTANTS.CREATEDBY}
              </label>
              <label
                className="details-data-style-common overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsMediumSm mt-1"
                title={result?.createdByUname}
              >
                {result?.createdByUname}
              </label>
            </div>
            <div className="mt-6">
              <label
                htmlFor="userName"
                className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm"
              >
                {PROJECTDETAILS_CONSTANTS.CREATEDON}
              </label>
              <label className="details-data-style-common fontPoppinsMediumSm">{result?.createdOn}</label>
            </div>
            <div className="mt-6">
              <label
                htmlFor="userName"
                className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm"
              >
                {PROJECTDETAILS_CONSTANTS.MODIFIEDBY}
              </label>
              <label
                className="details-data-style overflow-hidden overflow-ellipsis whitespace-nowrap float-left max-w-150 fontPoppinsMediumSm"
                title={result?.modifiedByUname}
              >
                {result?.modifiedByUname ? result?.modifiedByUname : <span>--</span>}
              </label>
            </div>
            <div className="mt-6">
              <label
                htmlFor="userName"
                className="block  label-header sm:mt-px sm:pt-2 details-key-style-common fontPoppinsMediumSm"
              >
                {PROJECTDETAILS_CONSTANTS.MODIFIEDON}
              </label>
              <label className="details-data-style fontPoppinsMediumSm">
                {result?.modifiedOn ? result?.modifiedOn : <span>--</span>}
              </label>
            </div>
          </div>
          <hr className="border-dashed border-gray-500"></hr>
          <div className="mt-4 ">
            <label className="mt-6 Popup-header-common fontPoppinsMediumMd user-role ml-7">
              {PROJECTDETAILS_CONSTANTS.USER_ROLE_MAPPING}
            </label>
            <div className=" mt-2 userRoleMapping overflow-y-auto max-h-48">
              <table {...getTableProps()} className="">
                <thead className="projectDetailsDataa">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()} className="text-sm h-9 ml-4  sticky top-0">
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()} className=" text-left  fontPoppinsSemiboldSm pl-8 table-line">
                          {' '}
                          <span className="project-details-header">{column.render('Header')}</span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} className="configHover h-8 hover:bg-gray-100">
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="  text-left fontPoppinsRegularSm pl-8 table-line project-row"
                            >
                              {' '}
                              <span className="project-details-row">{cell.render('Cell')}</span>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="memory-drawer-footer flex mt-30 justify-end absolute bottom-0 right-0 w-full bg-white mt-2">
          <button
            className="distribute-cancel cancel-info cursor-pointer fontMontserratMediumXs mr-4 mt-4"
            onClick={props.onClose}
          >
            {PROJECTDETAILS_CONSTANTS.CLOSE}
          </button>
        </div>
      </Box>
    </Drawer>
  );
};

export default ProjectDetails;

import React, { useState, useEffect } from 'react';
import { LABELS_CONSTANTS, DEFECT_CONSTANTS, noDataMessage } from '@src/common/ui-constants';
import CommonTable from '@src/common/atoms/TableComponent';
import CommonDrawer from '@src/common/atoms/CommonDrawer';
import cx from 'classnames';
import styles from '@common/atoms/atoms.module.scss';
import Grid from '@mui/material/Grid';
import { Label } from '@src/common/atoms/LabelComponent';

const UserDetailsDrawer = (props) => {
  const [data, setData] = useState({});
  const [userData] = useState(props?.userDetails?.data?.responseObject ? props.userDetails.data.responseObject : {});
  const [userLicense, setUserLicense] = useState({});

  useEffect(() => {
    if (props?.userDetails?.data?.responseMap) {
      let projectMap = props?.userDetails?.data?.responseMap;
      let projectData = [];
      Object.keys(projectMap).forEach((key) => {
        let obj = {
          project: projectMap[key].projectName,
          role: projectMap[key].roleName,
        };
        projectData.push(obj);
      });
      setData([...projectData]);
    }
    if (props?.userDetails?.data?.responseObject?.licenses?.length) {
      let licenses = props.userDetails.data.responseObject.licenses;
      const user = JSON.parse(localStorage.getItem('test-optimize-user'));
      if (user) {
        setUserLicense(licenses.find((lic) => lic?.id === user.currentLicenseId));
      }
    }
  }, []);

  const getDetailsField = (fieldName, fieldValue, fieldClasses) => {
    return (
      <Grid item xs={4} sm={4} md={4} key={fieldName}>
        <div className="flex flex-col justify-center">
          <div className="flex">
            <Label
              label={fieldName}
              fontClass={'fontPoppinsMediumSm'}
              className={cx('truncate', styles['details-label'])}
            />
          </div>
          <div className="flex">
            {LABELS_CONSTANTS.STATUS === fieldName ? (
              <p className={`truncate fontPoppinsRegularSm ${fieldClasses}`}>{fieldValue}</p>
            ) : (
              <Label
                label={fieldValue || '--'}
                fontClass={'fontPoppinsRegularSm'}
                className={cx(`truncate ${fieldClasses}`, styles['details-value'])}
              />
            )}
          </div>
        </div>
      </Grid>
    );
  };

  const getDeatilsFieldContainer = () => {
    const fieldList = [
      {
        label: LABELS_CONSTANTS.NAME,
        value: userData?.name,
      },
      {
        label: LABELS_CONSTANTS.EMAIL,
        value: userData?.emailId,
      },
      {
        label: LABELS_CONSTANTS.PRIVILEGE,
        value: userLicense?.privilege,
      },
      {
        label: LABELS_CONSTANTS.PHONE_NUMBER,
        value: userData?.phoneNumbers?.primaryPhone,
      },
      {
        label: LABELS_CONSTANTS.STATUS,
        value: userData?.activationStatus,
        className: userData?.activationStatus?.toLowerCase() === 'active' ? 'label-success' : 'label-error',
      },
      {
        label: LABELS_CONSTANTS.CREATED_BY,
        value: userData?.createdByUname,
      },
      {
        label: LABELS_CONSTANTS.CREATED_ON,
        value: userData?.createdOn,
      },
      {
        label: LABELS_CONSTANTS.MODIFIED_BY,
        value: userData?.modifiedByUname,
      },
      {
        label: LABELS_CONSTANTS.MODIFIED_ON,
        value: userData?.modifiedOn,
      },
    ];
    return <>{fieldList.map((field) => getDetailsField(field.label, field.value, field.className || ''))}</>;
  };
  const columns = React.useMemo(
    () => [
      {
        Header: 'PROJECT',
        accessor: 'project',
        width: '370px',
        disableFilters: true,
        Cell: ({ value }) => <div className="cursor-pointer float-left truncate w-80">{value}</div>,
      },
      {
        Header: 'ROLE',
        accessor: 'role',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="float-left">{value}</div>;
        },
      },
    ],
    []
  );

  function RoleMappingTable({ columns, data }) {
    return (
      <div className={styles['user-role-section']}>
        <div className="py-4 flex flex-wrap items-center">
          <label className="fontPoppinsMediumSm">{DEFECT_CONSTANTS.PROJECT_ROLE_MAPPING}</label>
        </div>
        <div className={styles['role-table']}>
          <CommonTable
            columns={columns}
            data={data}
            noDataMessage={noDataMessage.projectNotAvailable}
            compSpecificClassName={cx('table-height', styles['table-height'])}
          />
        </div>
      </div>
    );
  }

  return (
    <CommonDrawer
      isDrawerOpen={props.viewUserModal}
      titleText={`User Details - ${userData?.name}`}
      drawerWidth={DEFECT_CONSTANTS.USER_DETAILS_DRAWER_WIDTH}
      isRightButtonVisible={false}
      leftButtonText={LABELS_CONSTANTS.CLOSE}
      onLeftButtonClick={() => {
        props.handleUserModal(false);
      }}
      onDrawerClose={() => {
        props.handleUserModal(false);
      }}
    >
      <div className="modal-body" id="journal-scroll">
        <div className="m-4">
          <Grid
            container
            rowSpacing={{ xs: 2, md: 2, sm: 2 }}
            columnSpacing={{ xs: 2, md: 4, sm: 2 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {getDeatilsFieldContainer()}
          </Grid>
        </div>
      </div>
      <RoleMappingTable columns={columns} data={data} />
    </CommonDrawer>
  );
};

export default UserDetailsDrawer;

import React from 'react';

const LicencePackageContext = React.createContext();

export const LicencePackageProvider = (props) => {
  const [value, setValue] = React.useState('');
  return <LicencePackageContext.Provider value={[value, setValue]}>{props.children}</LicencePackageContext.Provider>;
};

export const useLicencePackage = () => React.useContext(LicencePackageContext);

import React, { Component, Fragment } from 'react';
import '../css/split_editor_right.css';
// import '../css/common_attribute.css';
import brace from 'brace';
import AceEditor from 'react-ace-builds';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-beautify';
import 'react-ace-builds/webpack-resolver-min';
import 'brace/ext/searchbox';
import 'brace/keybinding/emacs';
import 'brace/keybinding/vim';
import 'brace/ext/language_tools';
import 'brace/ext/settings_menu';

export default class Split_Editor_Right extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorHeight: 267,
      editorWidth: '100%',
      readOnly: false,
      change: '',
    };
  }

  componentDidMount() {
    this.onLoad();
  }

  onLoad = (value, event) => {
    this.setState({
      change: ``,
    });
  };

  render() {
    return (
      <Fragment>
        <AceEditor
          theme="tomorrow"
          name="meme"
          height={this.state.editorHeight}
          width={this.state.editorWidth}
          showGutter={true}
          readOnly={this.state.readOnly}
          fontSize={12}
          editorProps={{ $blockScrolling: true }}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true,
          }}
          value={this.state.change}
        />
      </Fragment>
    );
  }
}

import { TextField } from '@mui/material';
import cx from 'classnames';
import style from '@pages/common/Inputs/inputs.module.scss';
import PropTypes from 'prop-types';
export function Label({ label, required, error, showLabelError, className }) {
  return (
    <label
      className={cx(style['label'], 'mb-3 fontPoppinsMediumSm', className, {
        [style['required']]: required,
        [style['error']]: error && showLabelError,
      })}
    >
      {label}
    </label>
  );
}

function LabeledInput({
  label,
  required,
  error,
  className,
  inputProps,
  wrapperClassName,
  showLabelError,
  isLicenseBuyPage,
  ...props
}) {
  return (
    <div
      className={cx(wrapperClassName, style['labeled-input'], props?.page === 'signup' && style['signup-input-field'], {
        [style['number-input-control']]: props?.type === 'number',
      })}
    >
      <Label label={label} required={required} error={error} showLabelError={showLabelError} className={className} />

      <TextField
        error={!!error}
        fullWidth
        hiddenLabel
        variant="outlined"
        className={cx('fontPoppinsRegularSm', className, style['form-control'])}
        inputProps={{
          className: cx('fontPoppinsRegularSm', props.inputProps?.className),
        }}
        FormHelperTextProps={{
          className: isLicenseBuyPage
            ? 'fontPoppinsMediumXs'
            : props?.page === 'signup'
              ? 'fontPoppinsRegularXs8px'
              : 'fontPoppinsRegularXs8px',
        }}
        {...props}
      />
    </div>
  );
}

LabeledInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  required: PropTypes.bool,
  error: PropTypes.any,
  className: PropTypes.string,
  inputProps: PropTypes.object,
  wrapperClassName: PropTypes.string,
  showLabelError: PropTypes.bool,
  isLicenseBuyPage: PropTypes.bool,
};

LabeledInput.defaultProps = {
  label: '',
  required: false,
  error: '',
  className: '',
  inputProps: {},
  wrapperClassName: '',
  showLabelError: false,
  isLicenseBuyPage: false,
};

Label.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  required: PropTypes.bool,
  error: PropTypes.any,
  showLabelError: PropTypes.bool,
};

Label.defaultProps = {
  label: '',
  required: false,
  error: '',
  showLabelError: false,
};

export default LabeledInput;

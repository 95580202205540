import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AlertPopUps from '@pages/common/alert_pop_ups/AlertPopUps';
import { ALERT_MODAL_CONSTANTS, DEFECT_CONSTANTS, DEFECT_INFO_MODAL_CONSTANTS } from '@src/common/ui-constants';
import { getUserName } from '@src/util/common_utils';
const NavigateToCreateInstanceModal = (props) => {
  const history = useHistory();
  const { INFO } = ALERT_MODAL_CONSTANTS;
  const [open, setOpenModal] = useState(true);
  const alertTitle =
    props.data === 'Fireflink' ? DEFECT_INFO_MODAL_CONSTANTS.BUTTON_LABEL : DEFECT_CONSTANTS.CREATE_INSTANCE_BTN_LABEL;
  const handleNavigate = () => {
    if (props?.cleanUpFunction) {
      props.cleanUpFunction();
    }
    props.closeModal(false);
    setOpenModal(false);
    if (props.data === 'Fireflink') {
      if (props.history) {
        props.history.push(DEFECT_INFO_MODAL_CONSTANTS.PATH);
      } else {
        history.push(DEFECT_INFO_MODAL_CONSTANTS.PATH);
      }
      return;
    }
    let plgName = '';
    switch (props.data?.toLowerCase()) {
      case 'browserstack':
        plgName = 'BrowserStack';
        break;
      case 'saucelabs':
        plgName = 'sauceLab';
        break;
      case 'lambdatest':
        plgName = 'LambdaTest';
        break;
      case 'gitlab':
        plgName = 'Gitlab';
        break;
      default:
        plgName = props.data;
        break;
    }
    localStorage.setItem('selected-project', JSON.stringify({ id: '', name: 'All Projects' }));
    setTimeout(() => {
      const path = `/configuration/integration/plugin/${plgName}`;
      if (props.history) {
        props.history.push(path);
      } else {
        history.push(path);
      }
    }, 600);
  };
  const getModalContent = () => {
    const getBodyText = () => {
      if (props.data === 'Fireflink') {
        return DEFECT_INFO_MODAL_CONSTANTS.BODY_TEXT.map((text) => <p className="fontPoppinsRegularMd">{text}</p>);
      } else {
        return (
          <p className="fontPoppinsRegularMd">
            No instance is been created, click on "{DEFECT_CONSTANTS.CREATE_INSTANCE_BTN_LABEL}" button to create an
            instance.
          </p>
        );
      }
    };
    return [<div className="mb-2 capitalize fontPoppinsRegularMd">Hi {getUserName()},</div>, getBodyText()];
  };
  return (
    <AlertPopUps
      open={open}
      alertType={INFO}
      execution={true}
      showHowToProceed={true}
      showCancelBtn={true}
      showSaveBtn={true}
      onSaveBtnClick={handleNavigate}
      dialogClass={'info-child-modal-z-index'}
      saveBtnText={alertTitle}
      cancelBtnText={DEFECT_CONSTANTS.CANCEL}
      onCancelBtnClick={() => {
        props.closeModal(false);
        setOpenModal(false);
      }}
      content={getModalContent()}
      alertTitle={alertTitle}
    />
  );
};

export default NavigateToCreateInstanceModal;

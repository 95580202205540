import React, { useEffect, useRef } from 'react';
import { ReactComponent as ShrinkIconWhite } from '@assets/analytics/ShrinkIconWhite.svg';
import { ReactComponent as SearchIconFilled } from '@assets/analytics/searchIcon-rs.svg';
import { ReactComponent as SearchIconFilledDisabled } from '@assets/analytics/disabled-search-icon.svg';

import PropTypes from 'prop-types';
import { TooltipPoppin } from './util';
import LicenseRowCardStyle from '@pages/licenses/styles/license-row-card.module.scss';
import cx from 'classnames';

const SearchBar = (props) => {
  let {
    searchValue = '',
    handleSearch,
    onSearchClose,
    handleSearchOpen,
    open = false,
    enterKeyHandler,
    disable,
  } = props;

  const inputRef = useRef(null);

  useEffect(() => {
    if (open) inputRef.current.focus();
  }, [open]);

  return (
    <div className={open ? 'searchDisplay' : 'searchhidden'}>
      <input
        type="search"
        className="expanded-tab-search"
        value={searchValue || ''}
        placeholder={props.placeholder || 'Search'}
        onChange={(e) => typeof handleSearch !== 'undefined' && handleSearch(e)}
        onKeyDown={(e) =>
          typeof enterKeyHandler !== 'undefined' && ['Enter', 'Escape'].includes(e?.key) ? enterKeyHandler(e) : ''
        }
        autoFocus={true}
        ref={inputRef}
      />

      {/* NOTE ::  Code commented if the requirement changes to click on the search icon  */}

      {/* {open && (<div className={searchValue?.length >= 1 ?"bar-searchIcon-active" : "bar-searchIcon-disable"}
            onClick={(e)=> props?.enterKeyHandler ? props?.enterKeyHandler(searchValue) : e.preventDefault()} >
                <SearchIconFilled />   
            </div>)} */}

      {open && (
        <div className="close_search" onClick={onSearchClose}>
          {/* <span className="splitBar"></span> */}
          <ShrinkIconWhite />
        </div>
      )}

      {disable && !searchValue && !open ? (
        <>
          <TooltipPoppin
            title="Search Disabled"
            placement="top"
            className={cx(LicenseRowCardStyle['ToolTipRenderer'], 'fontPoppinsRegularXs')}
          >
            <div className="expandIconContainer">
              <SearchIconFilledDisabled />
            </div>
          </TooltipPoppin>
        </>
      ) : (
        <>
          {!open && (
            <TooltipPoppin
              title="Search"
              placement="top"
              className={cx(LicenseRowCardStyle['ToolTipRenderer'], 'fontPoppinsRegularXs')}
            >
              <div className="open_search" onClick={handleSearchOpen}></div>
            </TooltipPoppin>
          )}
        </>
      )}
    </div>
  );
};

SearchBar.propTypes = {
  searchValue: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired, // for onChange event
  open: PropTypes.bool.isRequired,
  handleSearchOpen: PropTypes.func.isRequired,
  onSearchClose: PropTypes.func.isRequired,
  enterKeyHandler: PropTypes.func, // NOTE :: this func will return searchValue || event
  disable: PropTypes.bool, // to disable search
};

export default SearchBar;

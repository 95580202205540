import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { usePagination, useRowSelect, useTable } from 'react-table';

import '../../script/scripts/steps/steps.scss';
const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});

const CommonTable = (props) => {
  const [tableData, setTableData] = useState(props.data);
  const data = React.useMemo(() => tableData, [tableData]);
  const getRowId = React.useCallback((row) => {
    return row.id;
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    // The rest of these things are super handy, too ;)

    selectedFlatRows,
    state: { selectedRowIds },

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: props.columns,
      data: data,
      getRowId: getRowId,
      initialState: { pageIndex: 0, pageSize: data.length - 1 },
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        let a = [...columns];
        a.splice(1, 0, {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          width: '0.5%',
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        });
        return a;
      });
    }
  );

  useEffect(() => {
    setTableData(props.data);
  }, [props.data]);

  return (
    <DragDropContext onDragEnd={props.handleOnDragEnd}>
      <table {...getTableProps()} className="">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="text-sm h-12 ml-4 hover:bg-gray-100">
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} width={column.width} className="pl-5 text-left">
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <Droppable droppableId="droppable">
          {(provided) => (
            <tbody
              {...getTableBodyProps()}
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="text text-xs"
            >
              {page.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <Draggable key={'dragRow' + rowIndex} draggableId={'dragRow' + rowIndex} index={rowIndex}>
                    {(provided) => (
                      <tr
                        {...row.getRowProps()}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className="configHover h-10 content-start"
                      >
                        {row.cells.map((cell, columnIndex) => {
                          return columnIndex === 0 ? (
                            <td
                              {...cell.getCellProps()}
                              {...provided.dragHandleProps}
                              // width={props.columns[columnIndex].width}
                              className="p-0 pl-5 text-left text-xs"
                            >
                              {cell.render('Cell')}
                            </td>
                          ) : (
                            <td
                              {...cell.getCellProps()}
                              // width={props.columns[columnIndex].width}
                              className="p-0 pl-5 text-left text-xs"
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    )}
                  </Draggable>
                );
              })}
            </tbody>
          )}
        </Droppable>
      </table>
    </DragDropContext>
  );
};

export default CommonTable;

export const GITLAB_ISSUE_DRAWER = {
  FORM_LABEL: 'Create Issue - Gitlab',
  FORM_ID: 'gitlab-issue-form',
  DRAWER_WIDTH: '50vw',
  INSTANCE: {
    LABEL: 'Instance',
    PLACEHOLDER: 'Select instance',
    REQUIRED_ERROR: 'Instance is required',
  },
  PROJECT: {
    LABEL: 'Project',
    PLACEHOLDER: 'Select project',
    REQUIRED_ERROR: 'Project is required',
  },
  TITLE: {
    LABEL: 'Title',
    PLACEHOLDER: 'Enter title',
    REQUIRED_ERROR: 'Title is required',
  },
  DESCRIPTION: {
    LABEL: 'Description',
    PLACEHOLDER: 'Enter description...',
    REQUIRED_ERROR: 'Description is required',
  },
  ASSIGNEE: {
    LABEL: 'Assignee',
    PLACEHOLDER: 'Select assignee',
    REQUIRED_ERROR: 'Assignee is required',
  },
  DUE_DATE: {
    LABEL: 'Due Date',
    PLACEHOLDER: 'Select due date',
  },
  MILESTONE: {
    LABEL: 'Milestone',
    PLACEHOLDER: 'Select milestone',
  },
  LABELS: {
    LABEL: 'Labels',
    PLACEHOLDER: 'Select labels',
  },
};

export const SLACK_ISSUE_DRAWER = {
  FORM_LABEL: 'Create Issue - Slack',
  FORM_ID: 'slack-issue-form',
  DRAWER_WIDTH: '50vw',
  CHANNEL: {
    LABEL: 'Channel',
    PLACEHOLDER: 'Select channel',
    REQUIRED_ERROR: 'Channel name is required',
  },
  SUMMARY: {
    LABEL: 'Summary',
    PLACEHOLDER: 'Enter summary here',
    REQUIRED_ERROR: 'Summary is required',
  },
  DESCRIPTION: {
    LABEL: 'Description',
    PLACEHOLDER: 'Enter description here',
    REQUIRED_ERROR: 'Description is required',
  },
};

export const JIRA_ISSUE_DRAWER = {
    FORM_LABEL: 'Create Issue - Jira',
    FORM_ID: "jira-issue-form",
    DRAWER_WIDTH: '50vw',
    DOMAIN: {
        LABEL: "Domain",
        PLACEHOLDER: "Select domain",
        REQUIRED_ERROR: "Domain name is required",
    },
    PROJECT: {
        LABEL: 'Project',
        PLACEHOLDER: "Select project",
        REQUIRED_ERROR: "Project name is required",
    },
    ISSUE_TYPE: {
        LABEL: 'Issue Type',
        PLACEHOLDER: "Select issue type",
        REQUIRED_ERROR: "Issue type is required",
    },
    SUMMARY: {
        LABEL: 'Summary',
        PLACEHOLDER: "Enter summary here",
        REQUIRED_ERROR: "Summary is required",
    },
    DESCRIPTION: {
        LABEL: 'Description',
        PLACEHOLDER: "Enter description here",
        REQUIRED_ERROR: "Description is required",
    },
    ASSIGNEE: {
        LABEL: 'Assignee',
        PLACEHOLDER: "Select assignee",
        REQUIRED_ERROR: "Assignee is required",
    },
    PRIORITY: {
        LABEL: 'Priority',
        PLACEHOLDER: "Select priority",
        REQUIRED_ERROR: "Priority is required",
    },
    LINK_ISSUE: {
        LABEL: 'Link Issue',
        PLACEHOLDER: "Select link issue",
        REQUIRED_ERROR: "Link issue is required",
    },
    TEMPLATE_MISMATCH:{
        ADMIN_HEADER_CONTENT: "Headers or Headers value does not match. Please update the following field in Jira.",
        NAVIGATE_DEFECT_TEMPLATE: "Navigate to defect Templates.",
        USER_CONTENT_MESSAGE: "Please contact Admin or Super Admin as this project's defect template mismatch with JIRA's template.",
        NAVIGATION_PATH: "/configuration/template/defecttemplate/defectdetails?dmode=defect",
        TITLE_TEMPLTEMPLATE_MISMATCH: "Template Mismatch",
        MISMATCH_FIELDS: "Mismatching Fields",
        GITLAB_TOOLTIP_INFO: "The information in the Summary will be synced to the Title in Gitlab",
    }
}

export const DEFAULT_STATE_VALUE = {
  FIREFLINK: {
    openDrawer: false,
    data: null,
    defectMgmtData: null,
  },
  GITLAB: {
    openDrawer: false,
    data: null,
    instances: [],
  },
  JIRA: {
    openDrawer: false,
    data: null,
    instances: [],
  },
  SLACK: {
    openDrawer: false,
    data: null,
    instances: [],
  },
  UPDATEPLATFORM: {
    openDrawer: false,
    data: null,
    projectDetails: null,
  },
};

export const FIREFLINK_ISSUE_DRAWER = {
  FORM_LABEL: 'Create Issue - Fireflink',
  FORM_ID: 'fireflink-issue-form',
  DRAWER_WIDTH: '50vw',
};

export const UPDTAE_PLATFORM = {
  FORM_ID: 'update-platform-form',
  DRAWER_WIDTH: '30vw',
  NO_PLATFORM_CHANGE: 'No change was done to the platform',
  PLATFORM_UPDATE_SUCCESS: 'Platform changed successfully.',
  PLATFORM_REQUIRED_MSG: 'Platform is required',
  LINK_ISSUE: 'Link Issue',
  AUTOMATION_SCRIPTS: 'Automation Scripts',
  MANUAL_TEST_CASE: 'Manual Test Case',
  JIRA: 'Jira',
};
export const MODAL_BODY = {
  WARNING_TEXT: "Please contact admin or super admin as this project's defect platform is not configured.",
};

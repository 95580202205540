import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';
import { SearchIcon } from '@heroicons/react/solid';
import '../../common/dropdown.css';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import HomeIcon from '@material-ui/icons/Home';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import '../View&Custom/ViewConfig.css';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
    color: 'rgb(22,72,198)',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height: 600,
  bgcolor: 'background.paper',

  boxShadow: 12,
};

const DateHistory = (props) => {
  const classes = useStyles();
  return (
    <div className="page-container">
      <div className={classes.root} style={{ marginTop: '-2px', display: 'flex' }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" href="/">
            <HomeIcon className={classes.icon} />
          </Link>
          <Link className="Headerbreadcrumb" href="/getting-started/installation/">
            Configuration
          </Link>

          <Link className="activeBreadcrumb" href="/getting-started/installation/">
            History
          </Link>
        </Breadcrumbs>
      </div>

      <div className="h-screen flex ">
        <div className="relative inline-block text-left">
          <div>
            <span className=" text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center">
              <Link to="/configuration/date&TimeConfiguration" class="mr-1  text-sm -ml-2 historyHeading font-medium">
                Date & Time Configuration
              </Link>

              <Link
                to="/configuration/date&TimeConfiguration/history"
                className="heading ml-4"
                style={{ marginLeft: '10px' }}
              >
                History
              </Link>
            </span>
          </div>
        </div>
      </div>

      <div className="page-list-body mt-1">
        <div className="page-table-body">
          <div className="configPage pr-0 content_area_style" id="journal-scroll">
            <div className="grid grid-cols-2 mb-4 ">
              <span className=" ml-3 mt-4 text-sm flex-auto  configPageHeading">History</span>
              <div className="icon">
                <SearchIcon className="search mt-4 w-15 h-5   items-center float-left" />
              </div>
            </div>

            <hr></hr>

            <div className=" grid cols-grid-6">
              <span className="text-sm text-gray-700 hover:bg-gray-100 h-10 p-3">
                Manjunath has created a view Default view on 28 jan 2021 13:20
              </span>
              <span className="text-sm text-gray-700 hover:bg-gray-100 h-10 p-3">
                Manjunath has created a view Default view on 28 jan 2021 13:20
              </span>
              <span className="text-sm text-gray-700 hover:bg-gray-100 h-10 p-3">
                Manjunath has created a view Default view on 28 jan 2021 13:20
              </span>
            </div>

            <div className="pagecountborder-set mb-3" id="footer-notfixed">
              <div className="float-right pr-4">
                <span className="pagecount">1-5 of 5</span>
                <span className="pageborderleft ml-2 pl-2">
                  <button className="pageArrow">{'<'}</button>{' '}
                  <span className="currentpagecount ml-2 mr-2">
                    {' '}
                    <span className="pl-2 pr-2">1</span>
                  </span>
                  <button className="pageArrow">{'>'}</button>{' '}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default withRouter(DateHistory);

import cx from 'classnames';

import ChartCard from '../components/ChartCard';
import DashboardStyle from '../styles/dashboard.module.scss';
import { _getKBFromStorageString, _showNumberAfterFirstDecimal } from './CommonMethods';
import ProgressLine from '../components/ProgressLine';

function ProjectTypeConsumption({
  type,
  totalProject = 2,
  assignedMemory,
  usedMemory,
  handleActiveProject,
  activeProjectType,
}) {
  const assignedMemoryInKb = _getKBFromStorageString(assignedMemory);
  const usedMemoryInKb = _getKBFromStorageString(usedMemory);

  return (
    <>
      <ChartCard className={DashboardStyle['project-type-card-height']}>
        <button
          onClick={() => handleActiveProject(type)}
          className={cx(DashboardStyle['memory-project-type-cart'], {
            [DashboardStyle['active']]: type === activeProjectType,
          })}
        >
          <h2
            className={
              type === activeProjectType
                ? DashboardStyle['memory-project-type-heading-active']
                : DashboardStyle['memory-project-type-heading']
            }
          >
            {type}
          </h2>

          <ProgressLine total={assignedMemoryInKb} filled={usedMemoryInKb} />
          <div className="h-full flex flex-col">
            <p className={cx('mt-1', DashboardStyle['memory-project-type-para'])}>
              Total No. Of projects: <span className="fontPoppinsSemiboldXs">{totalProject} </span>
            </p>
            <p className={cx('mt-1', DashboardStyle['memory-project-type-para'])}>
              Assigned Memory :{' '}
              <span className="fontPoppinsSemiboldXs">{_showNumberAfterFirstDecimal(assignedMemory)}</span>
            </p>
            <p className={cx('mt-1', DashboardStyle['memory-project-type-para'])}>
              Used Memory : <span className="fontPoppinsSemiboldXs">{_showNumberAfterFirstDecimal(usedMemory)} </span>
            </p>
          </div>
        </button>
      </ChartCard>
    </>
  );
}

export default ProjectTypeConsumption;

import { Tooltip } from '@material-ui/core';

export const getLabel = (value) => {
  if (value?.length > 25) {
    return (
      <Tooltip title={value} placement="top">
        <span> {value.slice(0, 23)}... </span>
      </Tooltip>
    );
  } else {
    return <span> {value}</span>;
  }
};
export const getOptionLabel = (value) => {
  if (value?.length > 9) {
    return (
      <Tooltip title={value} placement="top">
        <span className="details-data-style-common"> {value.slice(0, 9)}... </span>
      </Tooltip>
    );
  } else {
    return <span> {value}</span>;
  }
};

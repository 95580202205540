import React, { Fragment, useEffect, useState, useContext } from 'react';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import '../../../test-development.scss';
import CreateVariable from '@src/pages/test-development/shared/variables/create-edit-variables';
import VariablesDetailsModal from '@src/pages/test-development/shared/variables/variables-details';
import { Tooltip } from '@material-ui/core';
import { deleteLocalVariableReq, getAllLocalVariablesReq, getSingleUserRequest } from '@api/api_services';
import DeleteModal from '@src/pages/test-development/shared/delete-modal';
import UserDetailsModal from '@src/pages/test-development/shared/user-details-modal';
import { CommonLoader } from '@src/pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import ReactTable from '@src/pages/common/Table/ReactTable';
import SearchBar from '@src/pages/analytics/common/search-bar';
import { HighlightText, getMaskedString, isEmptyValue, isValueIncludes } from '@util/common_utils';
import NoResultsFound from '@src/pages/common/Table/NoResultFound';
import { TEST_DEV } from '@common/ui-constants';

function Variables(props) {
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  let [reload, setReload] = useState(true);
  let [variablesData, setVariablesData] = useState([]);
  let [openCreateVariableModal, setOpenCreateVariableModal] = useState(false);
  let [openVariableDetailsModal, setOpenVariableDetailsModal] = useState(false);
  let [openVariableDeleteModal, setOpenVariableDeleteModal] = useState(false);
  let [editVariableData, setEditVariableData] = useState(null);
  let [isLoading, setIsLoading] = useState(false);
  let [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);
  let [userDetails, setUserDetails] = useState({});
  const reloadVariables = (val) => {
    setReload(val);
  };

  const getUserDetails = async (key) => {
    if (key) {
      let res = await getSingleUserRequest(key);
      if (res.data.responseCode === 200 && res.data.responseObject) {
        setUserDetails(res.data.responseObject);
        setOpenUserDetailsModal(true);
      }
    }
  };

  const closeUserDetailsModal = (value) => {
    setOpenUserDetailsModal(value);
    setUserDetails({});
  };
  const getVariables = async () => {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    setIsLoading(true);
    let res = await getAllLocalVariablesReq(moduleId, scriptId);
    stopProgress();
    if (res.data && res.data.responseObject && res.data.responseObject.length > 0) {
      setVariablesData(res.data.responseObject);
    } else {
      setVariablesData([]);
    }
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 10,
    });
    setIsLoading(false);
  };

  const editVariable = (varData) => {
    setOpenCreateVariableModal(true);
    setEditVariableData(varData);
  };
  const deleteVariable = (varData) => {
    setOpenVariableDeleteModal(true);
    setEditVariableData(varData);
  };
  const deleteLocalVariable = async (data) => {
    try {
      const response = await deleteLocalVariableReq(data.id, data?.name);
      if (response.data.responseCode === 200 && response.data.status === 'SUCCESS') {
        reloadVariables(true);
        props.MyAlert.success(response?.data?.message);
      } else {
        props.MyAlert.warning(`${response.data.message}`);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const getVariableDetails = (varData) => {
    setOpenVariableDetailsModal(true);
    setEditVariableData(varData);
  };
  let menuData = (variable) => {
    return (
      <div className="actionIcons">
        {global.permission.isEditAccess('testDevelopment') && (
          <Tooltip title="Edit">
            <EditOutlinedIcon
              className="float-left cursor-pointer text-base m-1 xl:mx-2 text-blue-700"
              onClick={() => {
                editVariable(variable);
              }}
            />
          </Tooltip>
        )}
        {global.permission.isFullAccess('testDevelopment') && (
          <Tooltip title="Delete">
            <DeleteOutlinedIcon
              className="float-left cursor-pointer m-1 xl:mx-2 text-blue-700 text-base"
              onClick={() => {
                deleteVariable(variable);
              }}
            />
          </Tooltip>
        )}
        <Menu as="div" className="relative inline-block text-left">
          {({ open }) => (
            <>
              <div>
                <Menu.Button className="bg-gray-100 rounded-full flex items-center  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span className="sr-only">Open options</span>
                  <Tooltip title="More">
                    <span>
                      <DotsVerticalIcon className="h-5 w-5 m-1 mt-1.5 xl:mx-2 configmoreIcon" aria-hidden="true" />
                    </span>
                  </Tooltip>
                </Menu.Button>
              </div>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm w-full text-left'
                          )}
                          onClick={() => {
                            getVariableDetails(variable);
                          }}
                        >
                          Details
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    );
  };

  const closeModal = (value) => {
    if (openVariableDetailsModal) {
      setOpenVariableDetailsModal(false);
    }
    if (openCreateVariableModal) {
      setOpenCreateVariableModal(value);
    }
    if (openVariableDeleteModal) {
      setOpenVariableDeleteModal(false);
    }
    setEditVariableData(null);
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  // TODO :: handle Search filter :: START

  let [openSearch, setOpenSearch] = useState(false);
  const [userSearched, setuserSearched] = useState('');
  const [data, setData] = useState([]);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');
  let [isHighlight, setIsHighlight] = useState(false);

  useEffect(() => {
    setData(variablesData);
    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [variablesData]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(variablesData);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setData(variablesData);
    setIsHighlight(!isHighlight);
    setSearchTextHighlight('');
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = variablesData.filter(({ name, modifiedByUname }) => {
          return isValueIncludes(e, name) || isValueIncludes(e, modifiedByUname);
        });
        setData(newData);
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  // TODO :: handle Search filter :: END

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: '25%',
        minWidth: '175px',
        Cell: (e) => (
          <span className="text-blue-700 cursor-pointer" onClick={() => getVariableDetails(e.row.original)}>
            <HighlightText text={e.value} highlight={userSearched} />
          </span>
        ),
      },
      {
        Header: 'Value',
        accessor: 'value',
        width: '25%',
        minWidth: '180px',
        Cell: (e) => (
          <Tooltip title={e?.row?.original?.masked ? '' : e.value} placement="top">
            <span className="text-gray-700 lengthyChar fontPoppinsRegularMd">
              {isEmptyValue(e.value) ? 'NULL' : e.row.original?.masked ? getMaskedString(e.value) : e.value}
            </span>
          </Tooltip>
        ),
      },
      {
        Header: 'Returning Step',
        accessor: 'returningStep',
        width: '25%',
        minWidth: '80px',
        Cell: (e) => <span className="text-gray-700"> {e.value ? e.value : 'N/A'}</span>,
      },
      {
        Header: 'Modified By',
        accessor: 'modifiedBy',
        width: '15%',
        minWidth: '125px',
        Cell: (e) => (
          <span
            onClick={() => {
              if (e.row.original.modifiedByUname !== '--') {
                getUserDetails(e.row.original.modifiedBy);
              }
            }}
            className="text-blue-700 cursor-pointer"
          >
            <HighlightText text={e.row.original.modifiedByUname} highlight={userSearched} />
          </span>
        ),
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        width: '10%',
        minWidth: '115px',
        Cell: (e) => menuData(e.row.original),
      },
    ],
    [isHighlight]
  );

  useEffect(() => {
    if (reload) {
      getVariables();
    }
    setReload(false);
  }, [reload]);
  return (
    <div className="page-table-body">
      <div className="content_area_style">
        <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
          <label className="project_label text-sm flex-auto">
            Local Variables
            <span className="proj-count-style px-2.5 py-1 ml-2 font-bold rounded-lg">
              {data?.length ? data.length : 0}
            </span>
          </label>
          <div className="pr-4">
            <div className="flex flex-row items-center">
              <SearchBar
                open={openSearch}
                searchValue={userSearched}
                handleSearch={handleSearch}
                onSearchClose={handleSearchClose}
                handleSearchOpen={handleSearchOpen}
                enterKeyHandler={handleSearchSubmit}
                disable={isEmptyValue(variablesData)}
              />

              <div className="ml-auto pl-2">
                {global.permission.isEditAccess('testDevelopment') && (
                  <button
                    onClick={() => {
                      setOpenCreateVariableModal(true);
                    }}
                    className="ml-3 primary-btn"
                  >
                    {TEST_DEV.VARIABLE_BUTTON_TEXT}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="table-height overflow-auto" id="journal-scroll">
          <ReactTable data={data} columns={columns} tableHeightClass={'main-table-height'} />
          {isLoading ? (
            <CommonLoader />
          ) : isEmptyValue(variablesData) ? (
            <div className="fontPoppinsRegularMd no-steps">
              {global.permission.isEditAccess('testDevelopment') && (
                <div className="">
                  <p className="font-bold mb-4">Quick Start</p>
                  <p className="mb-4">
                    <span className="font-bold">Step 1 :</span> Click
                    <button
                      onClick={() => {
                        setOpenCreateVariableModal(true);
                      }}
                      className="ml-3 mr-3 primary-btn"
                    >
                      {TEST_DEV.VARIABLE_BUTTON_TEXT}
                    </button>
                    to start adding the Variables
                  </p>
                </div>
              )}
            </div>
          ) : (
            <>{openSearch && isEmptyValue(data) && <NoResultsFound />}</>
          )}
        </div>

        {/* modal to create and edit variable */}
        {openCreateVariableModal ? (
          <CreateVariable
            MyAlert={props.MyAlert}
            data={editVariableData}
            closeModal={closeModal}
            reload={reloadVariables}
            variableType={'LOCAL'}
            id={{ moduleId: moduleId, scriptId: scriptId }}
          />
        ) : null}
        {/* modal for delete variable */}
        {openVariableDeleteModal ? (
          <DeleteModal
            closeModal={closeModal}
            handleDelete={deleteLocalVariable}
            reload={reloadVariables}
            dataObj={{ type: 'Variable', data: editVariableData }}
          />
        ) : null}
        {/* modal for user details */}
        {openUserDetailsModal ? <UserDetailsModal data={userDetails} closeModal={closeUserDetailsModal} /> : null}
        {/* modal for variable details */}
        {openVariableDetailsModal ? <VariablesDetailsModal closeModal={closeModal} data={editVariableData} /> : null}
      </div>
    </div>
  );
}

export default Variables;

export const getManualDistributionData = (machines) => {
  let tempDistributionData = [];
  if (
    machines?.multiple === 'true' &&
    machines?.executionType === 'DISTRIBUTE' &&
    machines?.distributionMechanism === 'MANUAL'
  ) {
    machines?.selectedMachines?.forEach((clientMachine) => {
      const tempDistributionObj = clientMachine?.machineInstances?.[0]?.distribution;
      if (tempDistributionData) {
        tempDistributionData.push(tempDistributionObj);
      }
    });
  }
  return tempDistributionData;
};

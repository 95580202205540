import { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import Chart from 'react-apexcharts';
import ChartCard from '../components/ChartCard';
import cx from 'classnames';
import DashboardStyle from '../styles/dashboard.module.scss';
import { fontPoppins } from '@src/css_config/fontConstants';

function handleChartElementsStyle(element, labelPivot, colors) {
  const pivotLabelSeries = element.querySelector(`.apexcharts-series[seriesName="${labelPivot}"]`);
  if (pivotLabelSeries) {
    const barElements = pivotLabelSeries.querySelectorAll('path');
    if (barElements?.length) {
      barElements.forEach((barElement, barElementIndex) => {
        const barElementColor = colors[barElementIndex];
        barElement.setAttribute('fill', barElementColor);
      });
    }
  }
}

function handleTooltip(a, { ctx }, { seriesIndex, dataPointIndex }) {
  const element = ctx?.el;
  if (element) {
    const elementRect = element?.getBoundingClientRect();
    const barSeriesElement = element.querySelector(`.apexcharts-series[rel="${seriesIndex + 1}"]`);
    const innerChartElement = element.querySelector('.apexcharts-inner');
    const xAxisElement = element.querySelector('.apexcharts-xaxis');
    if (barSeriesElement) {
      const barElement = barSeriesElement.querySelectorAll('path')?.[dataPointIndex];
      if (barElement) {
        const barRect = barElement.getBoundingClientRect();
        const totalBottomOfTooltip =
          elementRect.height -
          innerChartElement.getBoundingClientRect().height +
          xAxisElement.getBoundingClientRect().height +
          (barRect?.height || 0) -
          20;
        const tooltipElement = element.querySelector('.apexcharts-tooltip');
        const left = barRect.x - elementRect.x;
        if (tooltipElement?.style) {
          if (tooltipElement?.style?.bottom !== `${totalBottomOfTooltip}px`) {
            tooltipElement.style.bottom = `${totalBottomOfTooltip}px`;
          }
          if (tooltipElement?.style?.left !== `${left}px`) {
            tooltipElement.style['margin-left'] = `${left}px`;
          }
        }
      }
    }
  }
}

function SeperatedBarChart({ labels, colors, series, pivotIndex, roundedDirection = 'b', roundedBorders = false }) {
  const chartRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);

  const updateChartWidth = useCallback(() => {
    if (chartRef.current) {
      setChartWidth(chartRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    updateChartWidth();
    window.addEventListener('resize', updateChartWidth);

    const observer = new ResizeObserver(updateChartWidth);
    if (chartRef.current) {
      observer.observe(chartRef.current);
    }

    return () => {
      window.removeEventListener('resize', updateChartWidth);
      if (chartRef.current) {
        observer.unobserve(chartRef.current);
      }
    };
  }, [updateChartWidth]);

  const options = useMemo(
    () => ({
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      chart: {
        width: chartWidth,
        toolbar: {
          show: false,
        },
        redrawOnWindowResize: true,
        redrawOnParentResize: true,
        events: {
          mounted: ({ ctx }) => {
            handleChartElementsStyle(ctx.el, labels[pivotIndex], colors);
          },
          updated: ({ ctx }) => {
            handleChartElementsStyle(ctx.el, labels[pivotIndex], colors);
          },
          dataPointMouseEnter: handleTooltip,
          mouseMove: handleTooltip,
        },
      },
      labels: labels,
      colors: colors,
      yaxis: {
        tickAmount: 5,
        min: 0,
        max: (Math.max(...series) || 4) * (120 / 100),
        title: {
          text: 'No. of suites',
          style: {
            ...fontPoppins.rSm,
            color: '#7C828A',
          },
        },
      },
      xaxis: {
        labels: {
          style: {
            colors: '#7C828A',
            ...fontPoppins.sSm,
          },
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          borderRadius: 3,
          columnWidth: '25px',
        },
      },
      tooltip: {
        enabledOnSeries: [pivotIndex],
        custom: ({ dataPointIndex, w }) => {
          return `
              <div class="px-4 py-2 border-2 rounded-lg tooltip-container" style="border-color: ${colors?.[dataPointIndex]}">
                <span>${labels?.[dataPointIndex]} : ${series?.[dataPointIndex]}</span>
              </div>
            `;
        },
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
          },
        },
      },
      seriesList: series,
    }),
    [labels, colors, series, pivotIndex]
  );

  const seriesData = labels.map((label, labelIndex) => {
    let data = Array.from({ length: labels.length }, () => 0);
    if (labelIndex === pivotIndex) {
      if (series.some((number) => number > 0)) {
        data = series.map((number) => number || 0.1);
      } else {
        data = Array.from({ length: labels.length }, () => 0.1);
      }
    }
    return {
      name: label,
      data,
    };
  });

  const totalSuites = series.reduce((total, number) => (total += number), 0);
  return (
    <ChartCard
      className={cx('flex flex-col justify-end h-full', DashboardStyle['seperated-bar-chart'])}
      roundedDirection={roundedDirection}
      roundedBorders={roundedBorders}
    >
      <div ref={chartRef} className={cx('px-4 h-full chart-container')}>
        <Chart type="bar" options={options} series={seriesData} height="100%" />
      </div>
      <div
        className={cx('py-4 ', DashboardStyle['footer'], {
          [`rounded${roundedDirection ? `-${roundedDirection}` : ''}-lg`]: roundedBorders,
        })}
      >
        <p className={cx(DashboardStyle['label'], 'mb-2 px-1')}>
          Total : <span className="fontPoppinsMediumXs">{totalSuites}</span>
        </p>

        <div className="flex flex-wrap">
          {totalSuites === 0 ? (
            <p className={cx('mb-0 px-1 fontPoppinsSemiboldMd text-center whitespace-nowrap', DashboardStyle['label'])}>
              No Suite has been Executed
            </p>
          ) : (
            labels.map((label, labelIndex) => (
              <p
                className={cx('mb-0 px-1 fontPoppinsRegularMd whitespace-nowrap', DashboardStyle['label'], {
                  'border-r': labelIndex !== labels.length - 1,
                })}
                key={`seperated-bar-chart-${label}-${labelIndex}`}
              >
                {label} : <span className="fontPoppinsMediumXs">{series[labelIndex]}</span>
              </p>
            ))
          )}
        </div>
      </div>
    </ChartCard>
  );
}

export default SeperatedBarChart;

import React, { useState } from 'react';
import Modal from 'react-modal';
import PageModal from '../components/project_elements/modals/page_modal';
import PackageModal from '../components/program_elements/modals/package_modal';
import StepGroupModal from '../components/step_groups/modals/step_group_modal';
import LibraryModal from '../components/step_groups/modals/library_modal';
import './tree_toolbar.css';
import ElementModal from '../components/project_elements/modals/ElementModal';
import UploadJarModal from '../components/program_elements/modals/upload-jar-modal';
import TreeSearchBar from '@src/pages/common/table_tree/tree_searchbar';
import { elementPlatformTypes } from '../components/project_elements/modals/element.utils';
import { isSearchFilterEnabled } from '@util/common_utils';

Modal.setAppElement('#root');
const TreeToolbar = ({ searchTree, resetTree, getTree, ...props }) => {
  const countData = JSON.parse(localStorage.getItem('allowedProjects'))?.features?.repository?.restrictions;
  const displaySearchFilter = isSearchFilterEnabled('repository');
  let treeData = props.treeData;
  let disabled = !props?.childResources;
  const currentPageName = props.page;
  let containerName = '';
  if (currentPageName.toLowerCase() === 'element') {
    if (props.projectType === 'Mobile') {
      containerName = 'Screen';
    } else if (props.projectType === 'Web') {
      containerName = 'Page';
    } else if (
      ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) &&
      props.platForm === 'Web'
    ) {
      containerName = 'Page';
    } else if (
      ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) &&
      elementPlatformTypes.includes(props.platForm)
    ) {
      containerName = 'Screen';
    }
  } else if (currentPageName.toLowerCase() === 'program element') {
    containerName = 'Package';
  } else if (currentPageName.toLowerCase() === 'step group') {
    containerName = 'Library';
  } else if (currentPageName.toLowerCase() === 'test data') {
    containerName = 'folder';
  }

  const [openModal, setOpenModal] = useState(false);
  const [openElementModal, setOpenElementModal] = useState(false);
  const [openStepGroupModal, setOpenStepGroupModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showImportJar, setShowImportJar] = useState(false);

  const closeModal = (value) => {
    if (openModal) {
      setOpenModal(value);
    } else if (openElementModal) {
      setOpenElementModal(value);
    } else if (openStepGroupModal) {
      setOpenStepGroupModal(value);
    } else if (showModal) {
      setShowModal(value);
    } else if (showImportJar) {
      setShowImportJar(false);
    }
  };
  const reloadTree = (value) => {
    props.reloadTree(value);
  };
  let handelPage = () => {
    if (props.page === 'Element') {
      if (props.countValue < countData.numberOfElements) {
        setOpenElementModal(true);
      } else {
        props.MyAlert.info('Exceeded allocated limit, please upgrade or buy new license');
      }
    } else if (props.page === 'Program Element') {
      props.programElement(true);
    } else if (props.page === 'Step Group') {
      if (props.countValue < countData.numberOfStepGroups) {
        setOpenStepGroupModal(true);
      } else {
        props.MyAlert.info('Exceeded allocated limit, please upgrade or buy new license');
      }
    }
  };

  let createPage = () => {
    setShowModal(true);
  };

  let returnFormattedHeader = (header) => {
    if (header === 'Project Elements' || header === 'Program Elements' || header === 'Step Groups') {
      return header;
    } else {
      if (containerName === 'Page') {
        if (!header.toLowerCase().includes('page')) {
          header = header.concat(' ' + containerName);
        }
      } else if (containerName === 'Package') {
        if (!header.toLowerCase().includes('package')) {
          header = header.concat(' ' + containerName);
        }
      } else if (containerName === 'Library') {
        if (!header.toLowerCase().includes('library')) {
          header = header.concat(' ' + containerName);
        }
      } else if (containerName === 'Screen') {
        if (!header.toLowerCase().includes('screen')) {
          header = header.concat(' ' + containerName);
        }
      }
      return header;
    }
  };
  if (props?.treeData[0]) {
    treeData[0]['isRootNode'] = true;
    if (containerName === 'Page') {
      if (!treeData[0].title.includes(' page') && treeData[0].title.toLowerCase() === 'root')
        treeData[0].title = treeData[0].title.concat(' page');
    } else if (containerName === 'Package') {
      if (!treeData[0].title.includes(' package')) treeData[0].title = treeData[0].title.concat(' package');
    } else if (containerName === 'Library') {
      if (!treeData[0].title.includes(' library') && treeData[0].title.toLowerCase() === 'root') {
        treeData[0].title = treeData[0].title.concat(' library');
      }
    } else if (containerName === 'Screen') {
      if (!treeData[0].title.includes(' screen') && treeData[0].title.toLowerCase() === 'root')
        treeData[0].title = treeData[0].title.concat(' screen');
    }
  }
  const treeHeader = returnFormattedHeader(props.header);

  const isStepGroupDetails =
    props?.header !== 'Step Groups' && props?.treeData[0]?.defaultLibrary && props?.treeData[0]?.name !== 'Root';

  const isCreateContainerButton = props.isLoading || isStepGroupDetails;
  const isCreateModalButton = props.isLoading || !(props.containerCount > 0) || isStepGroupDetails;

  return (
    <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
      <label className="project_label text-sm flex-auto" title={treeHeader === 'Element' ? 'Web Element' : treeHeader}>
        {treeHeader === 'Element' ? 'Web Element' : treeHeader}
      </label>
      <div className="pr-4">
        <div className="flex flex-row items-center ml-32">
          {displaySearchFilter && (
            <span className="actiontooltip float-left">
              <TreeSearchBar
                disabled={disabled}
                treeData={treeData}
                searchTree={searchTree}
                resetTree={resetTree}
                setSearchedText={props.setSearchedText}
                platForm={props.platForm}
              />
            </span>
          )}
          {global.permission.isEditAccess('repository') && currentPageName.toLowerCase() !== 'program element' && (
            <>
              <button
                id="createcontainerbutton"
                className="ml-3 primary-btn mr-3"
                disabled={isCreateContainerButton}
                onClick={() => {
                  createPage();
                }}
              >
                + {containerName}
              </button>
              <button className={'primary-btn'} onClick={handelPage} disabled={isCreateModalButton}>
                + {props.page !== 'Test Data' ? props.page : 'File'}
              </button>
            </>
          )}
        </div>
      </div>
      {showModal ? (
        containerName === 'Page' || containerName === 'Screen' ? (
          <PageModal
            pageName={props.pageName}
            reloadTree={reloadTree}
            treeData={props.treeData}
            closeModal={closeModal}
            mode="ADD"
            MyAlert={props.MyAlert}
            projectType={props.projectType}
            platForm={props.platForm}
            nodeToAdd={props.nodeToAdd}
          />
        ) : containerName === 'Library' ? (
          <LibraryModal
            pageName={props.pageName}
            reloadTree={reloadTree}
            treeData={props.treeData}
            closeModal={closeModal}
            mode="ADD"
            MyAlert={props.MyAlert}
            nodeToAdd={props.nodeToAdd}
          />
        ) : containerName === 'Package' ? (
          <PackageModal
            pageName={props.pageName}
            reloadTree={reloadTree}
            treeData={props.treeData}
            closeModal={closeModal}
            mode="ADD"
            MyAlert={props.MyAlert}
            nodeToAdd={props.nodeToAdd}
          />
        ) : null
      ) : null}

      {openStepGroupModal ? (
        <StepGroupModal
          treeData={props.treeData}
          reloadTree={reloadTree}
          closeModal={closeModal}
          mode="ADD"
          MyAlert={props.MyAlert}
        />
      ) : null}
      {openElementModal ? (
        <ElementModal
          treeData={props.treeData}
          reloadTree={reloadTree}
          mode="ADD"
          createAsSharedElement={props.createAsSharedElement}
          closeModal={closeModal}
          MyAlert={props.MyAlert}
          projectType={props.projectType}
          platForm={props.platForm}
        />
      ) : null}
      {showImportJar ? <UploadJarModal closeModal={closeModal} MyAlert={props.MyAlert} /> : null}
    </div>
  );
};

export default TreeToolbar;

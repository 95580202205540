import '@src/css_config/executionruntable.scss';
import StatusBadge from './button/StatusBadge';
import MachineEnvironmentTable from './MachineEnvironmentTable';
import Tooltip from '@mui/material/Tooltip';

function MachineEnvironmentTableLayout({ headerLabel = '', data, columns, accessDetails, placement = '', project }) {
  const statusColor = (accessDetails) => {
    switch (accessDetails) {
      case 'Private':
        return 'destructive';
      case 'Public':
        return 'primary';
      case 'Partial Public':
        return 'secondary';
      default:
        return 'primary';
    }
  };
  return(
    <div className="mt-2">
      <div className="client-machine-header flex justify-start items-center">
        <Tooltip title={headerLabel} placement='top'>
          <label className="fontPoppinsSemiboldSm table-label-color ml-3 truncate">
            {headerLabel}
          </label>
        </Tooltip>
        {data[0].executionEnvironment === "Local" ? 
          <StatusBadge
            variant={statusColor(accessDetails)}
            label={accessDetails}
            tooltipTitle={`Access: ${accessDetails}`}
            placement={placement}
          />
         : null}
      </div>
      <MachineEnvironmentTable
        data={data}
        columns={columns}
        project={project}
      />
    </div>
  );
}

export default MachineEnvironmentTableLayout;

import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';

import Input from '@material-ui/core/Input';
import DropdownTree from '../../../common/dropdown_tree';
import { postProgramElementReq, updateProgramElementReq } from '../../../../api/api_services';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Variable from './modals/variable';
import { TextField } from '@material-ui/core';
import UploadJarModal from './modals/upload-jar-modal';
import { colors } from '@src/css_config/colorConstants.js';
const useStyles = makeStyles({
  customTextField: {
    '& input::placeholder': {
      color: colors.grey_dark,
    },
  },
});
function Create_program_element(props) {
  let treeData = props.treeData;
  const classes = useStyles();
  const [selectedFolder, setSelectedFolder] = useState('root');
  const [message, setMessage] = useState('');
  const [aceValue, setAceValue] = useState(props.data.code);
  const [openVariable, setOpenVariable] = useState(false);
  const [showImportJar, setShowImportJar] = useState(false);
  const [createUpdateCalled, setCreateUpdateCalled] = useState(true);

  let [executionOrder, setExecutionOrder] = useState(
    props.nodeToAdd ? (props.nodeToAdd === 0 ? 1 : findExecutionOrder(props.nodeToAdd)) : 1
  );
  // const getCodeValue = (code) => {
  //   setAceValue(code);
  // };

  function findExecutionOrder(node) {
    let x, y;
    if (node.getLastChild() !== null) {
      let myNode;
      if (node.getLastChild().type === 'PRE') {
        myNode = node.getLastChild().getPrevSibling();
        x = node.getLastChild().data.executionOrder;
      } else {
        myNode = node.getLastChild();
      }
      while (myNode.hasChildren()) {
        if (myNode.getLastChild().type === 'POST') {
          myNode = myNode.getLastChild().getPrevSibling();
          x = myNode.getLastChild().data.executionOrder;
        } else {
          myNode = myNode.getLastChild();
        }
      }
      y = myNode.data.executionOrder;
    } else {
      y = node.data.executionOrder;
    }
    if (x === undefined) {
      if (node.getNextSibling() !== null) {
        x = node.getNextSibling().data.executionOrder;
      } else {
        if (node.name === 'Root' && node.title === 'Root package') {
          x = 0;
        } else {
          let parent = node.parent;
          for (let i = node.getLevel(); i >= 1; i--) {
            if (parent.getNextSibling() !== null) {
              x = parent.getNextSibling().data.executionOrder;
              break;
            } else if (i === 1) {
              x = parent.data.executionOrder;
              break;
            } else {
              parent = parent.parent;
            }
          }
        }
      }
    }
    if (x === undefined || (node.name === 'Root' && node.title === 'Root package')) {
      if (props.treeData[0].name === 'Root' && props.treeData[0].title === 'Root package') {
        return y + 1;
      } else {
        return Number((y + 0.0001).toFixed(7));
      }
    } else {
      return (x + y) / 2;
    }
  }
  const closeModal = (val) => {
    if (openVariable) {
      setOpenVariable(false);
    }
    if (showImportJar) {
      setShowImportJar(false);
    }
  };

  const treeNodeSelected = (data) => {
    if (data && data.data.key) {
      setExecutionOrder(findExecutionOrder(data.node));
      setSelectedFolder(data);
      formikDetails.setFieldValue('parentName', data.title);
    }
  };

  let selectedPackage;

  const checkForDuplicateNLPDesc = (treeData, values, parentId, type) => {
    treeData?.forEach((data, index) => {
      if (data.parentId === parentId || data.key === parentId) {
        selectedPackage = data;
      } else if (data.children) {
        checkForDuplicateNLPDesc(data.children, values, parentId, type);
      }
    });
    if (selectedPackage) {
      return checkForDuplicate(selectedPackage, values, parentId, type);
    }
  };
  const checkForDuplicate = (data, values, parentId, type) => {
    if (data.children) {
      let error = true;
      let childrens = data.children;
      if (type === 'update') {
        if (childrens.length > 1) {
          childrens.forEach((children, index) => {
            if (error === true && props.data.id !== children.key && children.folder === false) {
              if (children.desc === values.desc) {
                error = false;
                formikDetails.setFieldError('desc', 'NLP Description already exists');
                setCreateUpdateCalled(true);
              }
              if (children.name === values.name) {
                error = false;
                formikDetails.setFieldError('programElementname', 'Class Name already exists');
                setCreateUpdateCalled(true);
              }
            }
          });
          return error;
        } else {
          return true;
        }
      } else {
        childrens.forEach((children, index) => {
          if (error === true && children.folder === false) {
            if (children.desc === values.desc) {
              error = false;
              formikDetails.setFieldError('desc', 'NLP Description already exists');
              setCreateUpdateCalled(true);
            }
            if (children.name === values.name) {
              error = false;
              formikDetails.setFieldError('programElementname', 'Class Name already exists');
              setCreateUpdateCalled(true);
            }
          }
        });
        return error;
      }
    } else {
      return true;
    }
  };
  const CreateProgramElement = (values) => {
    const words = aceValue.split(' ');
    let className = '';
    if (words.length) {
      const classIndex = words.indexOf('class');
      className = words[classIndex + 1];
    }
    if (className !== values.programElementname) {
      props.MyAlert.warning(`Class Name value does not match with editor class name value`);
      setCreateUpdateCalled(true);
      return;
    }
    if (aceValue === '') {
      setCreateUpdateCalled(true);
      props.MyAlert.warning(`${values.programElementname} Code value cannot be blank please enter code Logic required`);
    } else if (className === values.programElementname) {
      const data = {
        name: values.programElementname,
        nlpName: values.programElementname,
        desc: values.desc,
        packageName: 'com.test.opti',
        code: aceValue,
        language: 'JAVA',
        langVersion: '16',
        passMessage: values.passMessage,
        executionOrder: executionOrder,
        failMessage: values.failMessage,
      };
      let packageid;
      if (props.mode === 'ADD_SUB') {
        packageid = props.parentId;
      } else {
        packageid = selectedFolder.data.key;
      }
      let errorExist = checkForDuplicateNLPDesc(props.treeData[0].children, data, packageid, 'create');
      if (errorExist) {
        postProgramElementReq(data, packageid)
          .then((results) => {
            if (results.data && results.data.responseObject) {
              props.programElement(false);
              props.reloadTree(true);
              props.MyAlert.success(`${data.desc} created successfully`);
            }
            if (results.data.responseCode === 400 && results.data.message.includes('already exist in nlps')) {
              props.MyAlert.warning(
                `Program Elements with class name ${values.programElementname} already exist in default nlp's`
              );
            }
            setCreateUpdateCalled(true);
            setMessage(results.data.message);
          })
          .catch((error) => {
            console.log('error', error);
          });
      }
    }
  };

  const checkValuesAreChanged = (values) => {
    let changeExist = false;
    if (values.desc !== props.data.desc) {
      changeExist = true;
    } else if (values.programElementname !== props.data.name) {
      changeExist = true;
    } else if (values.passMessage !== props.data.passMessage || values.failMessage !== props.data.failMessage) {
      changeExist = true;
    } else if (values.code !== aceValue) {
      changeExist = true;
    }
    if (changeExist) {
      return true;
    } else {
      return false;
    }
  };

  const UpdateProgramElement = (values) => {
    if (checkValuesAreChanged(values)) {
      const words = aceValue.split(' ');
      let className = '';
      if (words.length) {
        const classIndex = words.indexOf('class');
        className = words[classIndex + 1];
      }
      if (className !== values.programElementname) {
        props.MyAlert.warning(`NLP Class name should be same as ${values.programElementname} Program Element Name`);
        setCreateUpdateCalled(true);
        return;
      } else if (className === values.programElementname) {
        const data = {
          name: values.programElementname,
          nlpName: values.programElementname,
          desc: values.desc,
          packageName: 'com.test.opti',
          code: aceValue,
          language: 'JAVA',
          langVersion: '16',
          passMessage: values.passMessage,
          failMessage: values.failMessage,
        };
        let errorExist = checkForDuplicateNLPDesc(props.treeData[0]?.children, data, props.parentId, 'update');
        if (errorExist) {
          updateProgramElementReq(props.parentId, props.data.id, data)
            .then((response) => {
              if (response.data.responseCode === 200) {
                props.programElement(false);
                props.reloadTree(true);
                props.MyAlert.success(`${data.desc} updated successfully`);
              }
              if (response.data.responseCode === 400 && response.data.message.includes('already exist in nlps')) {
                props.MyAlert.warning(
                  `ProgramElements with class name ${values.programElementname} already exist in nlps`
                );
              }
              setMessage(response.data.message);
              setCreateUpdateCalled(true);
            })
            .catch((error) => {
              console.log('error', error);
              setCreateUpdateCalled(true);
            });
        }
      }
    } else {
      props.programElement(false);
      props.MyAlert.info(`nothing there  to update`);
      setCreateUpdateCalled(true);
    }
  };

  let initialValues;
  if (props.mode === 'ADD_SUB') {
    initialValues = {
      programElementname: '',
      NLPDesc: '',
      desc: '',
      packageName: 'PKJ1109',
      code: '',
      passMessage: '',
      language: 'java',
      parentName: props.parentName,
      failMessage: '',
    };
  } else if (props.data) {
    initialValues = {
      programElementname: props.data.name,
      NLPDesc: props.data.name,
      desc: props.data.desc,
      packageName: 'PKJ1109',
      code: props.data.code,
      passMessage: props.data.passMessage,
      language: 'java',
      parentName: props.parentName,
      failMessage: props.data.failMessage,
    };
  } else {
    initialValues = {
      programElementname: '',
      NLPDesc: '',
      desc: '',
      packageName: 'PKJ1109',
      code: '',
      passMessage: '',
      language: 'java',
      failMessage: '',
      parentName: '',
    };
  }
  const validationSchema = yup.object({
    programElementname: yup
      .string()
      .required('Class name is required')
      .matches(/^[a-zA-Z0-9_$]*$/, 'Please provide name as per Java standarad')
      .min(1, 'Class name must contain minimum 1 character'),
    desc: yup
      .string()
      .required('NLP Description is required')
      .matches(/^[a-zA-Z0-9_$\s]*$/, 'NLP Description should allow _ and $ only')
      .matches(/(?!^\s+$)^.*$/m, 'Only whitespaces are not allowed')
      .min(1, 'NLP Description must contain minimum 1 character'),
    passMessage: yup
      .string()
      .required(' Pass message is required')
      .matches(/^[a-zA-Z0-9_$\s]*$/, 'Pass message should accept only ( ,) ,- special characters')
      .min(1, 'Pass message must be minimum 1 character'),
    failMessage: yup
      .string()
      .required(' Fail message is required')
      .matches(/^[a-zA-Z0-9_$\s]*$/, 'Fail message should accept only ( ,) ,- special characters')
      .min(1, 'Fail message must be minimum 1 character'),
    parentName: yup.string().required('Parent package is required'),
  });

  const formikDetails = useFormik({
    initialValues,
    validationSchema,
  });

  const onTyping = () => {
    formikDetails.setFieldValue('parentName', '');
  };
  useEffect(() => {
    updatePassFailMsg();
  }, [formikDetails.values.desc]);
  const updatePassFailMsg = () => {
    let name = formikDetails.values.desc;
    if (name !== '') {
      formikDetails.values.passMessage = `${name} is passed`;
      formikDetails.values.failMessage = `${name} is failed`;
    } else {
      formikDetails.values.passMessage = ``;
      formikDetails.values.failMessage = ``;
    }
  };
  let errorExistForPassMessage = false;
  if (formikDetails.values.passMessage === '' && formikDetails.touched.passMessage) {
    errorExistForPassMessage = true;
  }
  let errorExistForFailMessage = false;
  if (formikDetails.values.failMessage === '' && formikDetails.touched.failMessage) {
    errorExistForFailMessage = true;
  }

  let errorExistForParentPage = false;
  if (formikDetails.values.parentName === '' && formikDetails.touched.parentName) {
    errorExistForParentPage = true;
  } else {
    errorExistForParentPage = false;
  }
  return (
    <div className="content_area_style">
      <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
        <label className="project_label text-sm flex-auto">
          {props.mode === 'ADD' || props.mode === 'ADD_SUB' ? 'Create Program Element' : `Edit Program Element`}
        </label>
        <div className="pr-4">
          <div className="flex flex-row ml-32">
            <button
              id="cancelId"
              className="primary-btn mx-3"
              onClick={() => {
                props.programElement(false);
                props.reloadTree(true);
                props?.setResetOnElementUpdateOrCancel(true);
              }}
            >
              Cancel
            </button>
            <button
              id="createId"
              onClick={() => {
                formikDetails.setTouched({
                  programElementname: true,
                  parentName: true,
                  desc: true,
                  failMessage: true,
                  passMessage: true,
                });
                if (
                  formikDetails.values.programElementname !== '' &&
                  formikDetails.values.programElementname.length >= 1 &&
                  formikDetails.values.parentName !== '' &&
                  formikDetails.values.desc !== '' &&
                  formikDetails.values.failMessage !== '' &&
                  formikDetails.values.failMessage.length >= 1 &&
                  formikDetails.values.passMessage !== '' &&
                  formikDetails.values.passMessage.length >= 1 &&
                  formikDetails.errors.passMessage !== 'Pass message should accept only ( ,) ,- special characters' &&
                  formikDetails.errors.failMessage !== 'Fail message should accept only ( ,) ,- special characters' &&
                  !formikDetails.values.programElementname.replace(/\s/g, '').length !== true &&
                  formikDetails.values.desc.length >= 1 &&
                  formikDetails.errors.desc !== 'NLP Description should allow _ and $ only' &&
                  formikDetails.errors.desc !== 'Only whitespaces are not allowed' &&
                  formikDetails.errors.programElementname !== 'Please provide name as per Java standarad'
                ) {
                  if (createUpdateCalled) {
                    if (props.mode === 'ADD' || props.mode === 'ADD_SUB') {
                      setCreateUpdateCalled(false);
                      CreateProgramElement(formikDetails.values);
                    } else {
                      setCreateUpdateCalled(false);
                      UpdateProgramElement(formikDetails.values);
                      props?.setResetOnElementUpdateOrCancel(true);
                    }
                  }
                }
              }}
              className="primary-btn"
            >
              {props.mode === 'ADD' || props.mode === 'ADD_SUB' ? 'Create' : 'Update'}
            </button>
          </div>
        </div>
      </div>
      <hr />
      <div className="mt-4 content-area table-height overflow-auto grid-flex-wrp" id="journal-scroll">
        <div className="grid-details">
          <form>
            <div className="">
              <div className="grid grid-cols-3">
                <div className="">
                  <div className="grid-rows-2 ml-8">
                    <div className="h-8 rs-user-input-container">
                      <TextField
                        error={formikDetails.errors.desc && formikDetails.touched.desc}
                        className="w-60	block"
                        type="text"
                        autoFocus={true}
                        placeholder="Enter NLP Description "
                        name="desc"
                        id="desc"
                        autoComplete="off"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label={
                          <>
                            {' '}
                            <span className="text-red-400 mr-1">&#42;</span>NLP Description
                          </>
                        }
                        onBlur={formikDetails.handleBlur}
                        onChange={formikDetails.handleChange}
                        onSelect={(e) => {
                          updatePassFailMsg();
                        }}
                        value={formikDetails.values.desc}
                        classes={{ root: classes.customTextField }}
                      />
                      {formikDetails.errors.desc && formikDetails.touched.desc ? (
                        <div className="errorMessage">{formikDetails.errors.desc}</div>
                      ) : null}
                    </div>
                    <div className="mt-9">
                      <div className="rs-user-input-container">
                        <label
                          className={
                            errorExistForPassMessage
                              ? 'input-filed-label-style-common-withError block'
                              : 'input-filed-label-style-common block'
                          }
                        >
                          <span className="text-red-500">&#42;</span>
                          Pass Message
                        </label>
                        <Input
                          error={errorExistForPassMessage}
                          className="w-60	block"
                          type="text"
                          placeholder="Pass message"
                          name="passMessage"
                          id="passMessage"
                          autoComplete="off"
                          onBlur={formikDetails.handleBlur}
                          onChange={formikDetails.handleChange}
                          value={formikDetails.values.passMessage}
                          classes={{ root: classes.customTextField }}
                        />
                        {formikDetails.errors.passMessage && formikDetails.touched.passMessage ? (
                          <div className="errorMessage">{formikDetails.errors.passMessage}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="grid-rows-2 ml-20 mr-40">
                    <div className="h-8 rs-user-input-container">
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={formikDetails.errors.programElementname && formikDetails.touched.programElementname}
                        className="w-60 block"
                        type="text"
                        placeholder="Enter class name"
                        name="programElementname"
                        id="programElementname"
                        autoComplete="off"
                        label={
                          <>
                            {' '}
                            <span className="text-red-400 mr-1">&#42;</span>
                            Class Name
                          </>
                        }
                        onBlur={formikDetails.handleBlur}
                        onChange={formikDetails.handleChange}
                        value={formikDetails.values.programElementname}
                        classes={{ root: classes.customTextField }}
                        disabled={props?.data?.id}
                      />
                      {formikDetails.errors.programElementname && formikDetails.touched.programElementname ? (
                        <div className="errorMessage w-60">{formikDetails.errors.programElementname}</div>
                      ) : null}
                    </div>

                    <div className="mt-9">
                      <div className="rs-user-input-container">
                        <label
                          className={
                            errorExistForFailMessage
                              ? 'input-filed-label-style-common-withError block'
                              : 'input-filed-label-style-common block'
                          }
                        >
                          <span className="text-red-500">&#42;</span>
                          Fail Message
                        </label>
                        <Input
                          error={errorExistForFailMessage}
                          className="w-60	block"
                          type="text"
                          placeholder="Fail message "
                          name="failMessage"
                          id="failMessage"
                          autoComplete="off"
                          onBlur={formikDetails.handleBlur}
                          onChange={formikDetails.handleChange}
                          value={formikDetails.values.failMessage}
                          classes={{ root: classes.customTextField }}
                        />
                        {formikDetails.errors.failMessage && formikDetails.touched.failMessage ? (
                          <div className="errorMessage w-80">{formikDetails.errors.failMessage}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="grid-rows-1 ml-20">
                    <div className="">
                      <div className="mr-20">
                        <DropdownTree
                          errorExist={errorExistForParentPage}
                          onTyping={onTyping}
                          overlay={true}
                          initalValue={
                            props.parentName
                              ? props.parentName
                              : props.data && props.data.parentName
                                ? props.data.parentName
                                : props.treeData
                                  ? ''
                                  : 'Root'
                          }
                          showOnlyFolders={true}
                          noDataMessage="Package not found."
                          operation={props.mode === 'ADD_SUB' || props.mode === 'EDIT' ? 'edit' : ''}
                          placeholder="Search and select parent  package"
                          treeData={treeData ? treeData : []}
                          nodeSelected={treeNodeSelected.bind(this)}
                          labelValue="Parent Package"
                        />
                        {formikDetails.errors.parentName &&
                        formikDetails.touched.parentName &&
                        formikDetails.values.parentName === '' ? (
                          <div className="errorMessage">{formikDetails.errors.parentName}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        {/* <div className="editorRole mt-6">
          <OnShowEditor
            disable={props?.data?.id}
            modeValue={props.mode}
            getCodeValue={getCodeValue}
            Editvalues={props.data.code}
          />
        </div> */}
        {/* <div className="ace-console-wrp">
          <div className="pt-3 pb-3 flex flex-wrap items-center">
            <label className="project_label text-sm flex-auto pl-0">
              Console
            </label>
            <div className="">
              <div className="flex flex-row ml-32">
                <button
                  onClick={() => {
                    setMessage("");
                  }}
                  className="gray-btn"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
          <div
            className="console_output_container_program_element overflow-y-auto shadow"
            id="journal-scroll"
          >
            {message}
          </div>
        </div> */}
      </div>
      {openVariable ? <Variable closeModal={closeModal} MyAlert={props.MyAlert} /> : null}
      {showImportJar ? <UploadJarModal closeModal={closeModal} MyAlert={props.MyAlert} /> : null}
    </div>
  );
}

export default Create_program_element;

import React, { useEffect, useState } from 'react';
import { MenuItem, Select, TextField, Tooltip } from '@material-ui/core';
import { Field } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { fontPoppins } from '@src/css_config/fontConstants';
import { Close, DragIndicatorOutlined, LibraryAddOutlined } from '@material-ui/icons';
import { Placeholder } from './ElementModal';
import { ReactComponent as ManualIcon } from '@src/assets/svg-imports/manualAddedLocator.svg';
import { ReactComponent as RecordedIcon } from '@src/assets/svg-imports/recordedLocator.svg';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { isEmptyValue } from '@src/util/common_utils';

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: 109,
    borderRadius: 8,
    ...fontPoppins.rMd,
    '&:focus': {
      borderRadius: 12,
      background: 'none',
    },
  },
  menuPaper: {
    maxHeight: 200,
    fontSize: '14px',
  },
  menulist: {
    paddingTop: 15,
    paddingBottom: 0,
    fontSize: '14px',
    background: 'none',
    '& li': {
      ...fontPoppins.rMd,
      color: '#727171',
    },
    '& li:hover': {
      background: '#F0F3F9',
      color: '#1648C6',
    },
    '& li.Mui-selected': {
      color: '#1648C6',
      background: '#F0F3F9',
    },
    '& li.Mui-selected:hover': {
      background: '#F0F3F9',
      color: '#1648C6',
    },
  },
}));

const useStylesLocName = makeStyles((theme) => ({
  select: {
    minWidth: 149,
    borderRadius: 8,
    ...fontPoppins.rMd,
    '&:focus': {
      borderRadius: 12,
      background: 'none',
    },
  },
  menuPaper: {
    maxHeight: 200,
    fontSize: '14px',
  },
  menulist: {
    overflowY: 'auto',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    height: '200px',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#a0aec0',
    },
    paddingTop: 15,
    paddingBottom: 0,
    fontSize: '14px',
    background: 'none',
    '& li': {
      ...fontPoppins.rMd,
      color: '#727171',
    },
    '& li:hover': {
      background: '#F0F3F9',
      color: '#1648C6',
    },
    '& li.Mui-selected': {
      color: '#1648C6',
      background: '#F0F3F9',
    },
    '& li.Mui-selected:hover': {
      background: '#F0F3F9',
      color: '#1648C6',
    },
  },
}));

const LocatorName = (props) => {
  const classesLocName = useStylesLocName();
  const { id, dropDownData, tableName, disabledOptions, setDisabledOptions } = props;
  const uniqueLocators = ['name', 'id', 'className'];
  const fieldName = `${tableName}${id}.locName`;
  const disableOption = (option, formikField) => {
    return disabledOptions.includes(option.value) && option?.value !== formikField?.value;
  };
  const onChangeHandler = (e, formikField) => {
    const value = e?.target?.value;
    const formikValue = formikField?.value || '';
    if (uniqueLocators.includes(value) && !disabledOptions.includes(value)) {
      const newOptions = disabledOptions.filter((option) => option !== formikValue);
      setDisabledOptions([...newOptions, value]);
    } else if (uniqueLocators.includes(formikValue) && disabledOptions.includes(formikValue)) {
      setDisabledOptions((options) => options.filter((option) => option !== formikValue));
    }
    formikField.onChange(e);
  };
  return (
    <Field name={fieldName}>
      {({ field, form, meta }) => {
        const errorExists = meta.touched && meta.error;
        return (
          <div key={'name-' + fieldName} className="relative">
            <Select
              classes={{
                root: classesLocName.select,
              }}
              autoFocus={false}
              disabled={false}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                classes: {
                  paper: classesLocName.menuPaper,
                  list: classesLocName.menulist,
                },
                getContentAnchorEl: null,
              }}
              {...field}
              value={field?.value || ''}
              onChange={(e) => {
                onChangeHandler(e, field);
              }}
              displayEmpty
              error={errorExists}
              className=""
              renderValue={
                !isEmptyValue(field?.value) ? undefined : () => <Placeholder>Select locator type</Placeholder>
              }
            >
              {dropDownData.map((data, index) => (
                <MenuItem disabled={disableOption(data, field)} key={data?.value} value={data?.value}>
                  {data.label}
                </MenuItem>
              ))}
            </Select>

            {errorExists && <div className="absolute errorMessage">{meta.error}</div>}
          </div>
        );
      }}
    </Field>
  );
};
const LocatorType = (props) => {
  const { id, tableName } = props;
  const classesLocType = useStyles();
  const fieldName = `${tableName}${id}.locType`;

  const checkValidation = (e, val, form) => {
    if (e.target?.value === 'dynamic') {
      let selField = val?.name.split('.');
      let selFiledLocValue = form?.values[selField[0]]?.locValue;
      let tempArray = selFiledLocValue?.split(/[{}]/);
      if (tempArray?.length) {
        tempArray.forEach((obj) => {
          if (obj?.includes('Dynamic Val')) {
            let dataExist = selFiledLocValue?.includes(`{${obj}}`);
            if (dataExist) {
              props?.resetDynamicValSuggestion(obj);
            }
          }
        });
      }
    }
  };

  return (
    <Field name={fieldName}>
      {({ field, form, meta }) => {
        const errorExist = meta.touched && meta.error;
        return (
          <div key={'type-' + fieldName} className="relative">
            <Select
              classes={{ root: classesLocType.select }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                classes: {
                  paper: classesLocType.menuPaper,
                  list: classesLocType.menulist,
                },
                getContentAnchorEl: null,
              }}
              {...field}
              onChange={(e) => {
                checkValidation(e, field, form);
                field.onChange(e);
              }}
              value={field?.value || ''}
              error={errorExist}
              disabled={false}
              displayEmpty
              renderValue={!isEmptyValue(field.value) ? undefined : () => <Placeholder>Select value type</Placeholder>}
            >
              <MenuItem value="static">Static</MenuItem>
              <MenuItem value="dynamic">Dynamic</MenuItem>
            </Select>
            {meta.touched && meta.error && <div className="errorMessage absolute">{meta.error}</div>}
          </div>
        );
      }}
    </Field>
  );
};

let cursorIndex = 0;
const LocatorValueInput = (props) => {
  const { id, tableName, dynamicValSuggestion } = props;
  const [showSuggestion, setShowSuggestion] = useState(false);
  const fieldName = `${tableName}${id}.locValue`;

  const onChangeHandler = (e, field, form) => {
    const value = e?.target?.value;
    const fieldValue = field?.value || "";
    const isDynamicType = form?.values[`${tableName}${id}`]?.locType === 'dynamic';
    if (!isDynamicType) {
      field.onChange(e);
    } else {
      let cursorPosition = e.target.selectionStart;
      const firstPart = value.slice(0, cursorPosition);
      const secondPart = value.slice(cursorPosition);
      const openFirstPart = firstPart.lastIndexOf('{');
      const closeFirstPart = firstPart.lastIndexOf('}');
      const closeSecondPart = secondPart.indexOf('}');
      const openSecondPart = secondPart.indexOf('{');
      let enteredChar = value.length <= 1 ? value : value.charAt(cursorPosition - 1);
      let isBetweenDynamic =
        openFirstPart > -1 &&
        (closeFirstPart === -1 || closeFirstPart < openFirstPart) &&
        closeSecondPart > -1 &&
        (openSecondPart === -1 || openSecondPart > closeSecondPart);
      let removeWholeDynamic =
        openFirstPart > -1 &&
        (closeFirstPart === -1 || closeFirstPart < openFirstPart) &&
        ((openSecondPart > -1 && closeSecondPart > -1 && openSecondPart < closeSecondPart) ||
          (openSecondPart === -1 && closeSecondPart === -1));
      if (enteredChar && value?.length > fieldValue?.length) {
        const prevfirstPart = fieldValue.slice(0, cursorPosition);
        const prevsecondPart = fieldValue.slice(cursorPosition);
        const prevopenFirstPart = prevfirstPart.lastIndexOf('{');
        const prevcloseFirstPart = prevfirstPart.lastIndexOf('}');
        const prevcloseSecondPart = prevsecondPart.indexOf('}');
        const prevopenSecondPart = prevsecondPart.indexOf('{');
        let isprevBetweenDynamic =
          prevopenFirstPart > -1 &&
          (prevcloseFirstPart === -1 || prevcloseFirstPart < prevopenFirstPart) &&
          prevcloseSecondPart > -1 &&
          (prevopenSecondPart === -1 || prevopenSecondPart > prevcloseSecondPart);
        let partDynamicVal = secondPart.slice(0, closeSecondPart);
        const placeholderPattern = /Dynamic Val[0-9]+/g;
        if (
          enteredChar === '{' &&
          placeholderPattern.test(partDynamicVal) &&
          value.charAt(cursorPosition - 2) !== '{'
        ) {
          if (enteredChar === '{' && isprevBetweenDynamic) {
            cursorIndex = e.target.selectionStart;
            setShowSuggestion(true);
          }
          form.setFieldValue(field.name, value);
        } else if ((value?.length > 1 && value.charAt(value?.length - 2) === '{') || isprevBetweenDynamic) {
          const newValue = firstPart + secondPart;
          cursorIndex = e.target.selectionStart;
          form.setFieldValue(field?.name, newValue);
          setShowSuggestion(true);
        } else if (fieldValue.charAt(cursorPosition - 2) === '{') {
          const newValue = firstPart.slice(0, firstPart?.length - 2) + secondPart;
          setShowSuggestion(false);
          form.setFieldValue(field?.name, newValue);
        } else {
          if (e.key === '{' || enteredChar === '{') {
            cursorIndex = e.target.selectionStart;
            setShowSuggestion(true);
          }
          form.setFieldValue(field?.name, value);
        }
      } else if (isBetweenDynamic) {
        const newValue = firstPart.slice(0, openFirstPart) + secondPart.slice(closeSecondPart + 1);
        setShowSuggestion(false);
        form.setFieldValue(field?.name, newValue);
      } else if (removeWholeDynamic) {
        const newValue = firstPart.slice(0, openFirstPart) + secondPart;
        setShowSuggestion(false);
        form.setFieldValue(field?.name, newValue);
      } else if (
        fieldValue?.charAt(cursorPosition) === '{' &&
        closeSecondPart > -1 &&
        (openSecondPart === -1 || openSecondPart > closeSecondPart)
      ) {
        const newValue = firstPart + secondPart.slice(closeSecondPart + 1);
        setShowSuggestion(false);
        form.setFieldValue(field?.name, newValue);
      } else {
        if (fieldValue?.charAt(fieldValue?.length - 1) === '{' || fieldValue?.charAt(cursorPosition) === '{') {
          setShowSuggestion(false);
        }
        form.setFieldValue(field?.name, value);
      }
    }
  };

  const onSelectHandler = (selectedOption, field, form) => {
    let value = field?.value || '';
    value = value?.slice(0, cursorIndex) + selectedOption + '}' + value?.slice(cursorIndex);
    form.setFieldValue(field?.name, value);
    props.resetDynamicValSuggestion(selectedOption);
    setShowSuggestion(false);
  };

  const hideDropdown = () => {
    setShowSuggestion(false);
  };

  const showDropdown = (e, field, form) => {
    const isDynamicType = form?.values[`${tableName}${id}`]?.locType === 'dynamic';
    let cursorPosition = e.target.selectionStart;
    const prevChar = e.target?.value?.charAt(cursorPosition - 1);
    const nextChar = e.target?.value.charAt(cursorPosition);
    if (isDynamicType && prevChar === '{' && nextChar !== 'D') {
      cursorIndex = e.target?.selectionStart;
      setShowSuggestion(isDynamicType);
    }
  };

  return (
    <Field name={fieldName}>
      {({ field, form, meta }) => {
        let errorExist = meta.touched && meta.error;
        const showHelperText = form?.values[`${tableName}${id}`]?.locType === 'dynamic' && !field?.value?.includes('{');
        const helperText = `use { to make use of Dynamic Val`;
        return (
          <div key={'input-' + fieldName} className="inline relative">
            <TextField
              autoComplete="off"
              id={fieldName}
              className="w-60 mt-1 fontPoppinsRegularMd"
              type="text"
              value={field?.value || ''}
              onChange={(e) => {
                onChangeHandler(e, field, form);
              }}
              disabled={false}
              error={errorExist}
              placeholder="Enter locator value"
              onBlur={hideDropdown}
              onClick={(e) => {
                showDropdown(e, field, form);
              }}
            />

            {showSuggestion && (
              <div
                className="border shadow rounded text-sm bg-white suggestion-dropdown mt-1 dynamicVal-suggestion "
                id={'suggestion-' + fieldName}
              >
                {dynamicValSuggestion.map((data, index) => (
                  <div
                    onMouseDown={() => {
                      onSelectHandler(data, field, form);
                    }}
                    id={data + index}
                    key={data + index}
                    className="flex cursor-pointer ml-3 text-gray-500 my-3 fontPoppinsRegularMd"
                  >
                    <div>
                      <span className="break-all">{data}</span>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {showHelperText && !errorExist && <div className="absolute fontPoppinsRegularSm ">{helperText}</div>}
            {errorExist && <div className="absolute errorMessage">{meta.error}</div>}
          </div>
        );
      }}
    </Field>
  );
};

const ActionColumn = (props) => {
  const { id, tableName, deleteLocatorHandler } = props;
  const fieldName = `${tableName}${id}.data`;
  return (
    <Field name={fieldName}>
      {({ field, form, meta }) => {
        return (
          <div key={'action-' + fieldName} className="flex gap-4">
            <button
              type="button"
              onClick={() => {
                deleteLocatorHandler(id, props.rowIndex, form);
              }}
            >
              <Close fontSize="small" />
            </button>
            {field?.value?.isRecorded?.toLowerCase() === 'y' ? (
              <Tooltip title="Locator added through plugin" placement="bottom">
                <RecordedIcon />
              </Tooltip>
            ) : (
              <Tooltip title="Manually added locator" placement="bottom">
                <ManualIcon />
              </Tooltip>
            )}
          </div>
        );
      }}
    </Field>
  );
};

const LocatorTableForm = (props) => {
  const { locatorTypeList, tableLabel, addLocator, deleteLocator, locatorIndexs, disabledLocatorsList } = props;
  const [disabledOptions, setDisabledOptions] = useState(disabledLocatorsList || []);
  useEffect(() => {
    setDisabledOptions(disabledLocatorsList);
  }, [disabledLocatorsList]);

  const addNewLocator = () => {
    const newIndex = Math.max(...locatorIndexs);
    addLocator(newIndex, '', props.tableName);
  };
  const deleteLocatorHandler = (delData, index, form) => {
    if (locatorIndexs.length === 1) {
      const fieldName = `${props.tableName}${delData}`;
      form.setFieldValue(fieldName + '.locValue', '');
      form.setFieldValue(fieldName + '.locName', '');
      form.setFieldValue(fieldName + '.locType', '');
      const locatorType = form.values[fieldName]?.locName;
      if (['name', 'id', 'className']?.includes(locatorType)) {
        setDisabledOptions([]);
      }
    } else if (locatorIndexs.length > 1) {
      const fieldName = `${props.tableName}${delData}`;
      const locatorType = form.values[fieldName].locName;
      if (['name', 'id', 'className']?.includes(locatorType)) {
        setDisabledOptions((list) => list?.filter((opt) => opt !== locatorType));
      }
      deleteLocator(fieldName, index, props.tableName);
    }
  };
  const handleDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    const tempLocators = [...locatorIndexs];
    const [removed] = tempLocators.splice(source.index, 1);
    tempLocators.splice(destination.index, 0, removed);
    const isLocatorDragged = JSON.stringify(locatorIndexs) !== JSON.stringify(tempLocators);
    if (isLocatorDragged) {
      props.isDisabled(false);
    }
    props.setLocatorIndexsState([...tempLocators]);
  };

  return (
    <div className="mx-1 my-4 locator-list-table">
      <div className="grid grid-cols-4 gap-4 my-2">
        <p className="col-span-3 fontPoppinsSemiboldLg">{tableLabel}</p>
        <button className="cursor-pointer fontPoppinsRegularMd add-locator" type="button" onClick={addNewLocator}>
          <LibraryAddOutlined color="primary" />
          Add locator
        </button>
      </div>
      <table>
        <thead className="text-left">
          <tr className="text-left fontPoppinsRegularMd">
            <th></th>
            <th className="input-filed-label-style-common font-normal">
              <span className="text-red-400">&#42;</span> Locator Type
            </th>
            <th className="input-filed-label-style-common font-normal">
              <span className="text-red-400">&#42;</span> Value Type
            </th>
            <th className="input-filed-label-style-common font-normal">
              <span className="text-red-400">&#42;</span> Locator Value
            </th>
            <th></th>
          </tr>
        </thead>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId={props.tableName + 'table-body'}>
            {(provided) => (
              <tbody className="text-left" {...provided.droppableProps} ref={provided.innerRef}>
                {locatorIndexs.map((data, index) => {
                  return (
                    <Draggable draggableId={props.tableName + data} key={props.tableName + data} index={index}>
                      {(provided) => (
                        <tr
                          key={props.tableName + data + index}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className="h-14 my-1"
                        >
                          <td className="pl-6">
                            {locatorIndexs.length > 1 && (
                              <span {...provided.dragHandleProps}>
                                <DragIndicatorOutlined fontSize="small" color="primary" />
                              </span>
                            )}
                          </td>
                          <td>
                            <LocatorName
                              disabledOptions={disabledOptions}
                              setDisabledOptions={setDisabledOptions}
                              dropDownData={locatorTypeList || []}
                              id={data}
                              {...props}
                            />
                          </td>
                          <td>
                            <LocatorType id={data} {...props} />
                          </td>
                          <td>
                            <LocatorValueInput id={data} {...props} />
                          </td>
                          <td>
                            <ActionColumn
                              id={data}
                              rowIndex={index}
                              deleteLocatorHandler={deleteLocatorHandler}
                              {...props}
                            />
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  );
                })}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </table>
    </div>
  );
};

export default LocatorTableForm;

import { createScriptReq } from '@api/api_services';
import { encodeSpecialCharater } from '@pages/test-development/shared/common-methods';

export function createParentSonJson(keyArray, nameArray) {
  let parentSonJson = [];
  nameArray[0] = 'Root Module';
  for (let i = 0; i < keyArray.length; i++) {
    if (i === keyArray.length - 1) {
      parentSonJson.push({
        Name: nameArray[i],
        id: keyArray[i],
        manualId: keyArray[i],
        parentId: keyArray[i - 1],
      });
    } else {
      parentSonJson.push({
        Name: nameArray[i],
        id: keyArray[i],
      });
    }
  }
  return { json: parentSonJson };
}

export async function createScript(data, tabName, history, MyAlert, testCaseType, scriptType) {
  let scriptTypeVal = scriptType && scriptType === 'newScript' ? true : false;
  try {
    data.type = 'Script';
    let response = await createScriptReq(data.parentId, data, scriptTypeVal);
    let resData = response.data.responseObject;
    if (resData && resData.name) {
      const keyArray = resData.searchKey.slice(1).split('/');
      const nameArray = resData.path.slice(1).split('/');
      const { json } = createParentSonJson(keyArray, nameArray);
      let autoScriptId;
      let manualScriptId;
      if (testCaseType?.length) {
        autoScriptId = testCaseType.find((scr) => scr.type === 'Automation')?.id;
        manualScriptId = testCaseType.find((scr) => scr.type === 'manual')?.id;
      }
      json[json.length - 1].Name = `${resData.prefix} - ${resData.name}`;
      if (tabName === 'auto') {
        if (manualScriptId) {
          json[json.length - 1].manualId = manualScriptId;
        } else {
          delete json[json.length - 1].manualId;
        }
        autoScriptId = keyArray[keyArray.length - 1];
      } else {
        if (autoScriptId) {
          json[json.length - 1].id = autoScriptId;
        } else {
          delete json[json.length - 1].id;
        }
        manualScriptId = keyArray[keyArray.length - 1];
      }
      localStorage.setItem('parentAndSonJson', JSON.stringify(json));
      let urlPath = '';
      for (const { Name, id, manualId } of json) {
        urlPath += `${encodeSpecialCharater(Name)}_${id ? id : manualId}/`;
      }
      if (tabName === 'auto') {
        history.push(
          `/testdevelopment/Script/${urlPath}Steps?scriptId=${autoScriptId}&moduleId=${keyArray[keyArray.length - 2]}${manualScriptId ? '&manualScriptId=' + manualScriptId : ''}`
        );
      } else {
        history.push(
          `/testdevelopment/Script/${urlPath}Manual Test Case?manualScriptId=${keyArray[keyArray.length - 1]}&moduleId=${keyArray[keyArray.length - 2]}${autoScriptId ? '&scriptId=' + autoScriptId : ''}`,
          JSON.stringify({ enableSave: true })
        );
      }
    } else if (response.data.status === `Script :${data.name} and Type :${data.scriptType} already exist`) {
      MyAlert.info(`${response.data.status}`);
    } else {
      MyAlert.info(`${response.data.status}`);
    }
  } catch (err) {
    console.error('CREATE_SCRIPT : ', err);
  }
}

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { encode } from '@util/common_utils';
import InfoIcon from '@mui/icons-material/Info';
import style from '@pages/licenses/styles/renew-license-popup.module.scss';
import cx from 'classnames';
import { LICENSE_PERIOD_STATUS, getLicensePeriodStatus } from '@util/licenseUtils';
import { useMemo } from 'react';
import TopBorderPopup from '@pages/licenses/components/TopBorderPopup';

function RenewLicensePopup({ licenseData, open, onClose, allowOutsideClick }) {
  const user = JSON.parse(localStorage.getItem('test-optimize-user') || '{}');
  const isLicenseExpired = useMemo(
    () => getLicensePeriodStatus(licenseData) === LICENSE_PERIOD_STATUS.EXPIRED,
    [licenseData]
  );
  const history = useHistory();

  function handleNavigation(path) {
    history.push(`/licenses/${encode(licenseData.id)}/FireFlink License/${path}`);
    onClose();
  }

  return (
    <TopBorderPopup open={open} onClose={onClose} allowOutsideClick={allowOutsideClick} className="blur-background">
      {!allowOutsideClick && (
        <button type="button" onClick={() => onClose()} className={style['sign-off-button']}>
          Back
        </button>
      )}
      <div className="flex my-3 px-6">
        <div className={cx('w-2/12', style['info-svg'], style['text-info'])}>
          <InfoIcon className="w-full h-auto" />
        </div>
        <div className="w-10/12 py-3">
          <h1 className={cx(style['popup-title'], 'fontPoppinsSemiboldAlertHeader')}>
            <span className={style['text-info']}>Info!</span> Renew License
          </h1>
          <p className={cx(style['statement'], 'mb-3', 'fontPoppinsRegularLg')}>Hi {user.name},</p>
          <p className={cx(style['statement'], 'fontPoppinsRegularLg')}>
            This license <span className="fontPoppinsSemiboldLg">{licenseData.licenseName}</span> is{' '}
            {isLicenseExpired ? 'expired' : 'about to expire'}. Please renew the license quickly for continuation of
            usage.
          </p>
          <p className={cx(style['statement'], 'fontPoppinsRegularLg')}>How do you want to proceed?</p>
        </div>
      </div>
      <TopBorderPopup.Footer onCloseClick={onClose} closeButtonText="Continue Existing Plan">
        <div className={style['footer']}>
          <TopBorderPopup.FooterButton buttonText="Renew" onClick={() => handleNavigation('renew')} />
        </div>
      </TopBorderPopup.Footer>
    </TopBorderPopup>
  );
}

RenewLicensePopup.propTypes = {
  licenseData: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  allowOutsideClick: PropTypes.bool,
};

RenewLicensePopup.defaultProps = {
  licenseData: {},
  allowOutsideClick: true,
};

export default RenewLicensePopup;

import React, { useState, useEffect, useRef } from 'react';
import useToggle from '@src/hooks/useToggle';
import '../css/snippet.scss';
import SnippetEditor from './snippet_Editor';
import { getAllSnippet, postCompileSnippetAPI, createSnippet } from '@api/api_services';
import MyInput from '@pagescommon/Inputs/MyInput';
import ExpandIcon from '../images/expand-icon-1.svg';
import CollapseIcon from '../images/expand-icon-2.svg';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { colors } from '@src/css_config/colorConstants.js';
import DownloadIcon from '../images/download.svg';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import AddIcon from '@mui/icons-material/Add';
import CommonButton from '@src/pages/common/button/CommonButton';
import ArrowExpandWhite from '../images/arrow-expand-white.svg';
import ArrowCollapseWhite from '../images/arrow-collapased-white.svg';
import ArrowExpand from '../images/arrow_expand.svg';
import ArrowCollapse from '../images/arrow_collapased.svg';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';

const Snippet_Create = (props) => {
  useEffect(() => {
    getTablevalue();
    props.restrictOfIssue(false);
    setErrorRow('rowInputFieldSearchInitial');
  }, []);

  const [projectTypeList, setProjectTypeList] = useState([]);
  const [isOpen, toggle] = useToggle(true);
  const [openValue, setOpenValue] = useState(false);
  const childRef = useRef();
  const [aceValue, setAceValue] = useState('');
  let [customSnippet, setCustomSnippet] = useState('');
  let [nameofSnippet, setNameofSnippet] = useState('');
  let [errorContent, setErrorContent] = useState('No Log Yet');
  let [displayConsole, setDisplayConsole] = useState(true);
  let [expandClick, setExpandClick] = useState(false);
  let [displayToggle, setDisplayToggle] = useState('toggle_Data');
  const [onBlurhandle, SetOnBlurHandle] = useState(false);
  const [errorRow, setErrorRow] = useState('rowInputFieldSearchInitial');
  const [errorColor, setErrorColor] = useState('color_input');
  const [disableButton, setDisableButton] = useState(true);
  const [tabClick, setTabClick] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);

  const getTablevalue = async () => {
    let res = await getAllSnippet(props.projectId, props.scriptId, props.moduleId);
    let finalResult = res.data.success;
    setProjectTypeList([...finalResult]);
  };

  const compileAndSave = async () => {
    const regex = /^[a-zA-Z0-9_() ]+$/;
    if (nameofSnippet === '') {
      props.MyAlert.alertbanner('Snippet Description is Required');
      SetOnBlurHandle(true);
      setErrorRow('rowInputFieldSearchValue');
      setErrorColor('color_error');
    } else if (nameofSnippet.charAt(0) === ' ' || nameofSnippet.charAt(nameofSnippet.length - 1) === ' ') {
      props.MyAlert.alertbanner('Space is not allowed at starting and at the end');
    } else if (!regex.test(nameofSnippet)) {
      props.MyAlert.alertbanner('Snippet Description should be alphanumeric');
    } else if (aceValue === '') {
      props.MyAlert.alertbanner('Code is Required');
    } else {
      if (aceValue !== '' && nameofSnippet !== '') {
        const data = {
          code: '',
          customSnippetDefinitions: [aceValue],
        };
        setDisableButton(true);

        try {
          const response = await postCompileSnippetAPI(data);
          setDisableButton(false);
          props.backCallOfMainPage(false);
          if (response.data.success === null && response.data.errors === null) {
            createSnippetFor();
            setErrorContent('Snippet Compiled SuccessFully');
            setDisplayConsole(true);
            setDisplayToggle('toggle_Console');
          } else {
            setDisplayConsole(true);
            setDisplayToggle('toggle_Console');
            setErrorContent(response.data.errors.details);
          }
        } catch (error) {
          setDisableButton(false);
          console.error('Error_in_snippet_create', error);
        }
      }
    }
  };

  const createSnippetFor = async () => {
    let firstLine = aceValue.split('\n')[0];
    let projectDetails = JSON.parse(localStorage.getItem('selected-project'));
    let projectId = projectDetails.id;
    if (firstLine.includes('(')) {
      //Before Open Brace
      let firstSpiltBrace = firstLine.split('(');
      let firstSplitSpace = firstSpiltBrace[0];
      let ValueofSpace = firstSplitSpace.split(' ');
      let ValueofPrint = ValueofSpace.pop();

      // After Open Brace
      let beforeReverseSplitBrace = firstSpiltBrace[1].split(')');

      // CallBackFunction
      let CallBackFunction = ValueofPrint + '(' + beforeReverseSplitBrace[0] + ');';
      props.restrictOfIssue(false);
      if (nameofSnippet !== '' && aceValue !== '') {
        const payload = {
          customSnippetDefinition: aceValue,
          customSnippet: CallBackFunction,
          name: nameofSnippet,
          description: nameofSnippet,
        };
        await createSnippet(payload, projectId, props.scriptId, props.moduleId)
          .then((response) => {
            if (props.reload) {
              if (response.data.errors === null) {
                props.reload(true);
              }
            }
            if (response.data.errors === null) {
              props.backCallOfMainPage(true);
              props.getPageCall(true, nameofSnippet);
            }
            if (response.data.errors !== null) {
              SetOnBlurHandle(true);
              setErrorColor('color_error');
              props.MyAlert.alertbanner(`${response.data.errors.message.replace(/\'/g, '')}`);
            }
          })
          .catch((error) => {
            console.error('Create_Snippet_Error', error);
          });
      }
    } else {
      props.backCallOfMainPage(false);
      setErrorContent('Snippet Should be in the Correct Format');
      setDisplayConsole(true);
      props.MyAlert.alertbanner('Snippet is in improper format, Please declare the function');
    }
  };

  const getCodeValue = (code) => {
    setAceValue(code);
    if (code) {
      // setCompileAndSaveBtn('secondary-btn');
      setDisableButton(false);
    } else {
      //setCompileAndSaveBtn('disable-btn');
      setDisableButton(true);
    }
  };

  const ClearIconValue = () => {
    setErrorContent('No Log Yet');
  };

  const handlelogic = (row) => {
    setTabClick(row.name);
    let findDefaultSnippet = row.hasOwnProperty('defaultSnippet');
    if (findDefaultSnippet === true) {
      childRef.current.showAlert(row.defaultSnippet);
    } else {
      childRef.current.showAlert(row.customSnippet);
      let val = row.customSnippetDefinition + ',' + customSnippet;
      setCustomSnippet(val);
    }
    setDisableButton(false);
  };

  const backButtonClicked = () => {
    props.backCallOfMainPage(true);
  };

  const ExpandAndContract = () => {
    setDisplayConsole(false);
  };

  const ExpandAndCollapse = () => {
    setDisplayConsole(true);
  };

  const onBlurHandle = () => {
    if (nameofSnippet === '') {
      SetOnBlurHandle(true);
      setErrorRow('rowInputFieldSearchValue');
      setErrorColor('color_error');
    } else {
      SetOnBlurHandle(false);
      setErrorRow('rowInputFieldSearchInitial');
      setErrorColor('color_input');
    }
  };

  const onFocusHandle = () => {
    if (nameofSnippet === '') {
      SetOnBlurHandle(true);
      setErrorRow('rowInputFieldSearchValue');
      setErrorColor('color_error');
    } else {
      SetOnBlurHandle(false);
      setErrorRow('rowInputFieldSearch');
      setErrorColor('color_normal_input');
    }
  };

  const handleEvent = (e) => {
    setNameofSnippet(e.target.value);
    if (e.target.value.length > 3) {
      SetOnBlurHandle(false);
      setErrorRow('rowInputFieldSearch');
      setErrorColor('color_normal_input');
    }
  };
  return (
    <div>
      <Modal open={openValue} className="modal-dialog">
        <div className="modal-container page-modal-size1">
          <div className="modal-header">
            <h2 className="title">{`Create Snippet`}</h2>
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                setOpenValue(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="modal-body" id="journal-scroll">
            <div
              className="console_output_container_Response fontPoppinsRegularMd overflow-y-auto shadow"
              id="journal-scroll"
            >
              {aceValue}
            </div>
          </div>
          <div className="modal-footer">
            <CommonButton
              btnType="secondary"
              onClick={() => {
                setOpenValue(false);
              }}
              label="Close"
            />
          </div>
        </div>
      </Modal>
      <div className="prerequisite" id="journal-scroll">
        <div className="flex pb-2 bg-white">
          <label className=" fontPoppinsSemiboldMd flex-auto project_label -ml-5">Create Snippet</label>
          <div className="relative left-7 hidden">
            <CustomTooltip title="Search Snippets" placement="top">
              <div className="">
                <img
                  className={`btn-right mr-4 right-0 snippet-search-svg-icon snippet-page-search-img`}
                  src={DownloadIcon}
                  alt="download img"
                />
              </div>
            </CustomTooltip>
          </div>
          <CommonButton btnType="primary" onClick={backButtonClicked} label="Back" />
        </div>
      </div>

      <>
        <div className="flex flex-row create-snippet-full-section">
          {isOpen ? (
            <div className="flex justify-start items-center snippet-create-tab-alignments">
              <label className="font-bold  fontPoppinsSemiboldMd">Snippets</label>
              <div className="flex cursor-pointer margin-left-for-toggle-icon" onClick={toggle}>
                {' '}
                <MenuIcon />{' '}
              </div>
            </div>
          ) : (
            <div
              className=" ml-2 cursor-pointer margin-left-for-toggle-icon-two  rotate-toggle-menu snippet-update-toggle-alignments"
              onClick={toggle}
            >
              <MenuOpenIcon className="mt-2 ml-1" />
            </div>
          )}
          <div className={`flex snippet-text-div-alignments`}>
            <div className="snippet-my-input-width pl-3">
              <MyInput
                type="text"
                name="nameofSnippet"
                placeholder={onBlurhandle === true ? '* Enter snippet description' : 'Enter snippet description'}
                error={onBlurhandle}
                onBlur={onBlurHandle}
                onFocus={onFocusHandle}
                onChange={handleEvent}
                autoComplete="off"
                helperText={onBlurhandle}
                inputValue={'input'}
              />
            </div>
            <div className="snippet-width-20">
              <div>
                <div className="flex items-center justify-around">
                  <button
                    className={`${disableButton ? 'border pl-3 pr-3 pt-1 pb-1 rounded text-gray-200 cursor-pointer customization-disable-compile-save-btn' : 'customization-of-compile-save-btn'} ml-1.5  ${!disableButton && 'hover-of-compile-save-btn'}`}
                    onClick={() => {
                      compileAndSave();
                    }}
                    disabled={disableButton}
                  >
                    {' '}
                    Compile & Save{' '}
                  </button>
                  <button
                    className={`${expandClick ? 'snippet-expand-btn-customization-in-expand-click' : ''}`}
                    onClick={(e) => {
                      setOpenValue(true);
                    }}
                  >
                    {showTooltip ? (
                      <img
                        alt="expand-icon"
                        src={expandClick ? CollapseIcon : ExpandIcon}
                        className="ExpandIcon-in-snippet-list"
                      />
                    ) : (
                      <CustomTooltip title={expandClick ? 'collapse' : 'expand'} placement="bottom">
                        <img
                          alt="expand-icon"
                          src={expandClick ? CollapseIcon : ExpandIcon}
                          className="ExpandIcon-in-snippet-list"
                        />
                      </CustomTooltip>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={'flex flex-row container-height-480 background-od-snippet-create'}>
          {isOpen && (
            <div className="flex flex-row left-side-panel-width">
              <div className="ml-2 mt-2 mb-2 flex-1">
                {projectTypeList.map((row, index) => (
                  <>
                    <div
                      className={`cursor-pointer snippet-create-left-side-panel ${tabClick === row.name ? 'active-tab-in-snippet' : ''}`}
                      key={`${row.id}`}
                      onClick={() => {
                        handlelogic(row);
                      }}
                    >
                      <CustomTooltip
                        title={row.name}
                        placement="right"
                      >
                        <span className="select-none fontPoppinsRegularSm truncate prerequisite-left-side-text-customization pl-2">
                          <span className="mr-2">
                            <AddIcon />
                          </span>
                          {row.name?.length > 16 ? <span>{row.name.substring(0, 16) + '...'}</span> : row.name}
                        </span>
                      </CustomTooltip>
                    </div>
                    <span className="h-2 flex"></span>
                  </>
                ))}
              </div>
            </div>
          )}

          <div className={`${isOpen ? 'w-4/5' : 'w-full'} p-2.5 snippet-editor-div-customization`}>
            <div
              className={`${
                expandClick
                  ? 'expand-editor-alignment-snippet-editor'
                  : `${
                      displayConsole ? 'maxHeight-display-console-300 ' : 'maxHeight-display-console-433'
                    } overflow-hidden`
              } flex  snippet-editor-console-customization`}
            >
              <SnippetEditor
                ref={childRef}
                getCodeValue={getCodeValue}
                compileAndSave={compileAndSave}
                displayConsole={displayConsole}
                expandClick={expandClick}
              />
            </div>
            <div className={expandClick ? 'h-60' : 'h-0'}></div>
            {displayConsole ? (
              <>
                <div
                  className={` pt-1 pb-1 flex flex-wrap items-center w-auto fontPoppinsSemiboldMd console-border-in-snippet mt-3 h-9`}
                >
                  <label className="text-left text-white relative opacity-100 -left-7 top-0.5 fontPoppinsSemiboldMd flex-auto pl-4 ml-8">
                    Console
                  </label>
                  <div className="pr-4">
                    <div className="flex flex-row gap-1">
                      <button onClick={ClearIconValue} className="secondary-btn_new text-left text-white ml-3 mr-5m">
                        Clear
                      </button>
                      <CustomTooltip title="collapse" placement="bottom">
                        <img
                          onMouseEnter={(e) => {
                            e.target.style.backgroundColor = `${colors.text_white}`;
                            e.target.style.borderRadius = '50px';
                            e.target.src = ArrowExpand;
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = 'initial';
                            e.target.src = ArrowExpandWhite;
                          }}
                          className={`ml-2 cursor-pointer arrow-expand-icon pre-requisite-isVisible-icon-rotate`}
                          src={ArrowExpandWhite}
                          onClick={ExpandAndContract}
                          alt="showmore"
                          width="20"
                        />
                      </CustomTooltip>
                    </div>
                  </div>
                </div>
                <div
                  className="console_output_container_snippet_element overflow-auto  pr-4 flex items-center justify-center"
                  id="journal-scroll"
                >
                  {errorContent}
                </div>
              </>
            ) : (
              <>
                <div className="pt-1 pb-1 border_style flex flex-wrap items-center w-full console-border-in-snippet-new mt-3">
                  <label className="text-left text-white relative -left-3 top-0.5 fontPoppinsSemiboldMd flex-auto pl-4 ml-2">
                    Console
                  </label>
                  <div className="pr-4">
                    <div className="flex flex-row ml-32 relative">
                      <CustomTooltip title="expand" placement="bottom">
                        <img
                          onMouseEnter={(e) => {
                            e.target.style.backgroundColor = `${colors.text_white}`;
                            e.target.style.borderRadius = '50px';
                            e.target.src = ArrowCollapse;
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = 'initial';
                            e.target.src = ArrowCollapseWhite;
                          }}
                          className={` arrow-collapse-icon mt-1 cursor-pointer`}
                          src={ArrowCollapseWhite}
                          onClick={ExpandAndCollapse}
                          alt="showmore"
                          width="20"
                        />
                      </CustomTooltip>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    </div>
  );
};

export default Snippet_Create;

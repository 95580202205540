import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import { Select, TextField } from '@material-ui/core';
import { addStepGroupVariable, updateStepGroupVariable } from '../../../../../api/api_services';
import { HASH_LABELS } from '@common/ui-constants';
import { renderFilePath, pathName, searchFilePathBasedOnHash } from '@src/util/common_utils';
import { getAllTestDataFiles } from '@api/api_services';
import { ClickAwayListener } from '@mui/base';
import { STEP_GROUP_VARIABLE_MODAL } from '@common/ui-constants';
import { Tooltip, Icon, Checkbox } from 'fireflink-ui';
function StepGroupVariableModal(props) {
  const [openModal, setOpenModal] = useState(true);
  const [project, setProject] = useState(JSON.parse(localStorage.getItem('selected-project')));
  const [isValueMasked, setIsValueMasked] = useState(props?.data?.masked && props?.mode === 'Edit' ? true : false);
  const search = window.location.search;
  const id = new URLSearchParams(search).get('id');
  const parentId = new URLSearchParams(search).get('parentId');
  const [showFileSuggestion, setShowFileSuggestion] = useState(false);
  const [fileArray, setFileArray] = useState([]);
  const [fileSearch, setFileSearch] = useState([]);
  const typeOfModal = HASH_LABELS.MODAL_TYPE_DROPDOWN;

  const showVariables = () => {
    setShowFileSuggestion(true);
  };
  const hideVariables = () => {
    setShowFileSuggestion(false);
  };

  useEffect(() => {
    getFolderPath();
  }, []);

  const getFolderPath = async () => {
    try {
      const response = await getAllTestDataFiles();
      const {
        data: { responseObject, responseCode },
      } = response || {};

      if (responseObject?.length > 0 && responseCode === 200) {
        const folderPaths = responseObject.map((folder) => {
          return {
            ...folder,
            path: pathName(folder.actualPath),
            actualPath: folder.actualPath,
          };
        });
        setFileArray([...folderPaths]);
        setFileSearch([...folderPaths]);
      } else {
        setFileArray([]);
      }
    } catch (err) {
      console.error('GET_FOLDER_PATH :', err);
    }
  };

  const handleOptionSelect = (option) => {
    formikDetails.setFieldValue('value', option.actualPath);
    hideVariables();
  };

  const handleInputChange = (e) => {
    const { inputValue, fileArrayOption, filteredArray } = searchFilePathBasedOnHash(e, fileArray, fileSearch);
    if (fileArrayOption) {
      formikDetails.setFieldValue('variableValue', fileArrayOption.actualPath);
      hideVariables();
    } else if (!fileArrayOption) {
      if (inputValue.startsWith('#') && inputValue.length > 1) {
        showVariables();
        setFileArray(filteredArray);
      } else if (inputValue.startsWith('#')) {
        showVariables();
        getFolderPath();
      } else {
        hideVariables();
      }
      formikDetails.handleChange(e);
    }
  };
  let initialValues;
  if (props?.mode === 'Edit') {
    initialValues = {
      name: props?.data?.name,
      type: 'Step Group Variable',
      value: props?.data?.value,
      variableValueMasked: props?.data?.masked,
    };
  } else {
    initialValues = {
      name: '',
      type: 'Step Group Variable',
      value: '',
      variableValueMasked: false,
    };
  }
  const validationSchema = yup.object({
    name: yup
      .string()
      .required('Name is required')
      .matches(/^[-_A-Za-z0-9 ]*$|^[-_A-Za-z0-9 ][A-Za-z0-9 ]*[-_A-Za-z-_]$/, 'Name should be alphanumeric')
      .min(3, 'Name must be between 3 and 25 characters')
      .max(25, 'Name must be between 3 and 25 characters'),
  });
  const formikDetails = useFormik({
    initialValues,
    validationSchema,
  });
  const createStepGroupVariable = () => {
    let requestbody = {
      name: formikDetails.values.name,
      value: formikDetails.values.value,
      type: 'LOCAL',
      returningStep: '',
      projectId: project.id,
      parentVariableType: 'STEPGROUP',
      parentVariableId: parentId,
      subParentVariableId: id,
      masked: formikDetails.values?.variableValueMasked,
    };
    addStepGroupVariable(requestbody).then((response) => {
      if (response.data.responseCode === 201) {
        setOpenModal(false);
        props.closeModal(false);
        props.reloadTree(true);
        props.MyAlert.success(`${requestbody.name} stepgroup variable created successfully`);
      } else if (response.data.responseCode === 400) {
        formikDetails.setFieldError('name', `${requestbody.name} already exist`);
      }
    });
  };
  const updateVariable = () => {
    let values = formikDetails.values;
    props.data.name = values.name;
    props.data.value = values.value;
    props.data.masked = values.variableValueMasked;
    updateStepGroupVariable(props.data.id, props.data).then((response) => {
      if (response.data.responseCode === 200) {
        setOpenModal(false);
        props.closeModal(false);
        if (props.reloadTree) {
          props.reloadTree(true);
          props.MyAlert.success(`${values.name} stepgroup variable updated successfully`);
        }
        if (props.onUpdateUpdateValue) {
          props.onUpdateUpdateValue(response.data.responseObject, 'Step');
        }
      } else if (response.data.responseCode === 400) {
        formikDetails.setFieldError('name', `${values.name} already exist`);
        props.reloadTree(true);
      }
    });
  };
  return (
    <div>
      <Modal
        open={openModal}
        onClose={() => {
          props.closeModal(false);
          setOpenModal(false);
        }}
        className="modal-dialog"
      >
        <div className="modal-container step_group_variable_modal">
          <div className="fontPoppinsMediumLg modal-header">
            {props.mode === 'ADD' || props.mode === 'ADD_SUB' ? (
              <h2 className="title"> Create Step Group Variable</h2>
            ) : (
              <div className="title title-trim" title={props.data.name}>
                Edit Step Group Variable - {props.data.name}
              </div>
            )}
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                props.closeModal(false);
                setOpenModal(false);
              }}
            >
              <img
                style={{
                  cursor: 'pointer',
                }}
                src="/assets/images/close_black.svg"
                alt=""
                height="25px"
                width="25px"
              />{' '}
            </IconButton>
          </div>
          <div className="modal-body">
            <form onSubmit={formikDetails.handleSubmit}>
              <div className="grid grid-cols-2 ">
                <div className="">
                  <div className="">
                    <div className="rs-user-input-container">
                      <TextField
                        error={formikDetails.errors.name && formikDetails.touched.name}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label={
                          <>
                            {' '}
                            <span className="text-red-400 mr-1">&#42;</span>Name
                          </>
                        }
                        autoComplete="off"
                        className="w-11/12"
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Enter variable name"
                        onBlur={formikDetails.handleBlur}
                        onChange={formikDetails.handleChange}
                        value={formikDetails.values.name}
                      />
                    </div>
                    {formikDetails.errors.name && formikDetails.touched.name ? (
                      <div className="errorMessage">{formikDetails.errors.name}</div>
                    ) : null}
                  </div>
                </div>
                <div className="">
                  <div className="-mt-1 ml-6">
                    <label htmlFor="type" className="input-filed-label-style-common">
                      <span className="text-red-400">&#42;</span>Type
                    </label>
                    <div className="-mt-2">
                      <Select
                        name="type"
                        value={formikDetails.values.type}
                        disabled={true}
                        onBlur={formikDetails.handleBlur}
                        onChange={formikDetails.handleChange}
                        onSelect={formikDetails.handleChange}
                        className="w-full  text-gray-500"
                        renderValue={(selected) => {
                          if (selected) return <span className="fontPoppinsRegularMd">{selected}</span>;
                        }}
                      >
                        <option className="fontPoppinsRegularMd" value="" disabled selected hidden>
                          Select step group type{' '}
                        </option>
                        <option className="fontPoppinsRegularMd" value="Step Group Variable">
                          Step Group Variable
                        </option>
                      </Select>
                    </div>
                    {formikDetails.errors.type && formikDetails.touched.type ? (
                      <div className="errorMessage">{formikDetails.errors.type}</div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 mt-7">
                <div className="">
                  <div className="">
                    <div className="rs-user-input-container">
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label={<> Value</>}
                        autoComplete="off"
                        className="w-11/12"
                        type={`${isValueMasked ? 'password' : 'text'}`}
                        name="value"
                        id="value"
                        placeholder={HASH_LABELS?.ENTER_VALUE_OR_SELECT_PATH}
                        onBlur={formikDetails.handleBlur}
                        onChange={(e) => handleInputChange(e)}
                        value={formikDetails.values.value}
                      />
                      {showFileSuggestion && (
                        <ClickAwayListener onClickAway={hideVariables}>
                          <div className="absolute">{renderFilePath(fileArray, handleOptionSelect, typeOfModal)}</div>
                        </ClickAwayListener>
                      )}
                      <div className="flex variable-input-value items-center mt-5 ml-2">
                        <Checkbox
                          name="variableValueMasked"
                          id="variableValueMasked"
                          label={STEP_GROUP_VARIABLE_MODAL.HIDE_VALUE}
                          onChange={(e) => {
                            setIsValueMasked(e?.target?.checked);
                            formikDetails.handleChange(e);
                          }}
                          disabled={props?.data?.masked && props?.mode === 'Edit'}
                          defaultChecked={isValueMasked}
                          value={isValueMasked}
                        />
                        <Tooltip title={STEP_GROUP_VARIABLE_MODAL.VALUE_HIDDEN} placement="right">
                          <div className="ml-2">
                            <Icon name="info" height={14} width={14} hoverEffect={true} />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              className="gray-btn mr-3"
              onClick={() => {
                props.closeModal(false);
                setOpenModal(false);
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="primary-btn"
              onClick={() => {
                formikDetails.setTouched({
                  name: true,
                });
                if (
                  formikDetails.values.name !== '' &&
                  formikDetails.values.name.length >= 3 &&
                  formikDetails.values.name.length <= 25 &&
                  formikDetails.errors.name !== 'Name should be alphanumeric'
                ) {
                  if (props.mode === 'ADD') {
                    createStepGroupVariable();
                  } else {
                    updateVariable();
                  }
                }
              }}
            >
              {props.mode === 'ADD' ? 'Create' : 'Update'}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default StepGroupVariableModal;

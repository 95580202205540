import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import ResultInfoPanel from '../../shared/result-info/result_info_panel';
import ResultCharts from '../../shared/result_charts';
import ResultStatusTree from '../module-result/result-status-tree/result_status_tree';
import PlatFormDetails from '../../shared/platform-details';

function ExecutionResult(props) {
  const [toggleMoreDetails, setToggleMoreDetails] = useState(false);

  const moreDetails = (val) => {
    setToggleMoreDetails(val);
  };

  const handleStatusChange = () => {
    props.handleStatusChange();
  };

  const isEditable = React.useMemo(() => window.permission?.isEditAccess('execution'), []);

  return (
    <div className=" flex" id="journal-scroll">
      <div className="flex-1 focus:outline-none">
        <main className="flex-1 relative pb-2 z-0 base-card">
          <div className="grid grid-cols-2 gap-3">
            <div className="col-end-2 col-span-12">
              <div key="result-info" className="bg-white h-44 card-box-shadow overflow-hidden">
                {props.infoData && props.infoData.runId && props.selectedProject && (
                  <ResultInfoPanel
                    data={props.infoData}
                    projectType={props.selectedProject.type}
                    focusMoreDetails={setToggleMoreDetails}
                    resultType={props.resultType}
                  />
                )}
              </div>
            </div>
            <div className="col-start-2">
              <div key="result-chart" className="bg-white h-44 overflow-hidden card-box-shadow">
                {props.chartData ? (
                  <ResultCharts chartData={props.chartData} suiteType={props?.suiteType} type="Module" />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          {toggleMoreDetails && props.platformData && props.selectedProject ? (
            <div className="bg-white card-box-shadow mt-2">
              <PlatFormDetails
                data={props.platformData}
                projectType={props.selectedProject.type}
                moreDetails={moreDetails}
                suiteType={props?.suiteType}
              />
            </div>
          ) : (
            <div className="platform-details my-3" onClick={() => moreDetails(true)}>
              <div className="grid grid-cols-1">
                <div className="more-details mx-3 my-2">
                  More Details
                  <FaChevronDown className="platform-chevron float-right mt-1 mx-2" />
                </div>
              </div>
            </div>
          )}
          <div className="grid grid-cols-1 mt-2 ">
            <div key="result-status" className="bg-white card-box-shadow ">
              {props.treeData && props.treeData.length && props.rootData ? (
                <ResultStatusTree
                  {...props}
                  onNodeSelected={props.onNodeSelected}
                  treeData={props.treeData}
                  rootData={props.rootData}
                  handleStatusChange={handleStatusChange}
                  isEditable={isEditable}
                  suiteType={props?.suiteType}
                  MyAlert={props.MyAlert}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default ExecutionResult;

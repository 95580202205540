import React, { useEffect, useState, useContext } from 'react';
import { ScaleIcon } from '@heroicons/react/outline';
import ResultInfoPanel from '../../shared/result-info/result_info_panel';
import ResultCharts from '../../shared/result_charts';
import ResultAccordion from './result_accordion';
import { getScriptResultReq } from '@api/api_services';
import { CommonLoader } from '@src/pages/common/common-loader';
import ManualResult from './manual_result_table';
import { getSuiteType } from '@pages/results/result';
import { useAlert } from '@pages/common/alert_service/useAlert';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { getIDB, setIDB } from '@src/util/localForage_idb_controller';
import { getCurrentLicenseId } from '@src/util/common_utils';

const card = { name: 'Account balance', href: '#', icon: ScaleIcon, amount: '$30,659.45' };

function ScriptResultBase(props) {
  let [isLoading, setIsLoading] = React.useState(false);
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [resultType, setResultType] = useState('module_result');
  const [refreshPage, setrefreshPage] = useState(true);
  const { AlertContatiner, MyAlert } = useAlert();
  const [focusMoreDetails, setFocusMoreDetails] = useState(false);
  const [scriptResultData, setScriptResultData] = useState();
  const [scriptResultTagData, setScriptResultTagData] = useState();
  const [reloadAccordion, setReloadAccordion] = useState(false);
  const [chartData, setChartData] = useState();
  const [infoData, setInfoData] = useState();
  const [scriptType, setScriptType] = useState('');
  const [scriptResultsForStepId, setScriptResultsForStepId] = useState([]);
  const search = window.location.search;
  const [id, setId] = useState(new URLSearchParams(search).get('id'));
  const executionId = new URLSearchParams(search).get('executionId');
  const type = new URLSearchParams(search).get('type');
  const clientId = new URLSearchParams(search).get('clientId')?.trim();
  const runId = new URLSearchParams(search).get('runId');
  if (id !== new URLSearchParams(search).get('id')) {
    setId(new URLSearchParams(search).get('id'));
  }
  const [manualTestCase, setManualTestCase] = useState({});
  const [suiteType, setSuiteType] = useState();
  const [localStorageTreeData, setLocalStorageTreeData] = useState();
  const currentLicenseId = getCurrentLicenseId();

  useEffect(() => {
    getSuiteType(executionId).then((value) => {
      setSuiteType(value);
    });
  }, []);
  useEffect(() => {
    (async () => {
      const tempValue = await getIDB('tree-data');
      setLocalStorageTreeData(tempValue);
    })();
  }, []);
  useEffect(() => {
    setReloadAccordion(false);
    getScriptResult(id);
  }, [id, refreshPage]);

  const getScriptResult = (id) => {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    setIsLoading(true);
    setScriptResultData('');
    if (clientId && id) {
      getScriptResultReq(executionId, clientId, runId, id)
        .then((response) => {
          setScriptResultsForStepId(response?.data?.responseObject?.stepResults);
          setScriptType(response?.data?.responseObject?.type);
          stopProgress();
          if (response.data.responseCode === 200 && response.data.responseObject) {
            let returned_data = response?.data.responseObject;
            returned_data['storageDetails'] = localStorageTreeData?.storageDetails;
            returned_data['executionId'] = localStorageTreeData?.executionId;
            returned_data['id'] = returned_data.key;
            setIDB('script-data', [returned_data]);
            if (type === 'dependant') {
              setIDB('dependent-data', [returned_data]);
            }
            setScriptResultData(returned_data);
            setManualTestCase(returned_data.manualTestCase);
            setInfoAndChartData(returned_data);
            setIsLoading(false);
            setReloadAccordion(true);
          } else {
            setIsLoading(false);
          }
          startOrResumeProgress({
            stopAt: 100,
            intervalDuration: 10,
          });
        })
        .catch((error) => {
          console.error('Error during script result api call:', error);
          resetProgress();
          setIsLoading(false);
        });
    } else {
      stopProgress();
      startOrResumeProgress({
        stopAt: 100,
      });
    }
  };

  const setInfoAndChartData = (data) => {
    let _info;
    let _chart;
    setChartData({});
    if (data) {
      const defaultChartStats = {
        executionDuration: 0,
        executionDurationInHourMinSecFormat: '00:00:00:00',
        total: 0,
        totalFailed: 0,
        totalPassed: 0,
        totalSkipped: 0,
        totalTerminated: 0,
        totalWarning: 0,
      };

      _info = {
        runNumber: 8,
        runId: executionId,
        status: data.status,
        scriptName: data.name ? `${data?.prefix || data?.id} - ${data.name}` : data.title,
        prefix: data.prefix,
        machine: data.selectedSystem.machineInfo.hostName,
        execution: data?.selectedSystem.executionEnv,
        os: [`${data.selectedSystem.machineInfo.osName} ${data.selectedSystem.machineInfo.osVersion}`],
        browser: ['Android'].includes(data.scriptType)
          ? []
          : [`${data?.selectedSystem?.browserInfo?.name} ${data.selectedSystem.version}`],
        browserVersion: data.selectedSystem.version,
        duration: data.executionDurationInHourMinSecFormat,
        executedOn: data.executedOn,
      };
      if (data.deviceInfo) {
        data.deviceInfo.forEach((device) => {
          _info['deviceName'].push(device.name);
        });
      } else if (data?.selectedSystem?.deviceInfo?.length) {
        _info['deviceName'] = data.selectedSystem?.deviceInfo?.map((device) => device?.name);
      }
      if (!_info.os[0] && data.selectedSystem.machineInfo.osName) {
        _info['os'] = [data.selectedSystem.machineInfo.osName];
      }
      if (data.executionEntityResponses) {
        _chart = {
          preConditionStats: defaultChartStats,
          stepResultStats: defaultChartStats,
          postConditionStats: defaultChartStats,
          dependantScriptStats: defaultChartStats,
        };
        if (data.executionEntityResponses[0].preConditionStats) {
          _chart['preConditionStats'] = data.executionEntityResponses[0].preConditionStats;
        }
        if (data.executionEntityResponses[0].stepResultStats) {
          _chart['stepResultStats'] = data.executionEntityResponses[0].stepResultStats;
        }
        if (data.executionEntityResponses[0].postConditionStats) {
          _chart['postConditionStats'] = data.executionEntityResponses[0].postConditionStats;
        }
        if (data.executionEntityResponses[0].dependantScriptStats) {
          _chart['dependantScriptStats'] = data.executionEntityResponses[0].dependantScriptStats;
        }
      } else {
        _chart = {
          preConditionStats: defaultChartStats,
          stepResultStats: defaultChartStats,
          postConditionStats: defaultChartStats,
          dependantScriptStats: defaultChartStats,
        };
        if (data.preConditionStats) {
          _chart['preConditionStats'] = data.preConditionStats;
        }
        if (data.stepResultStats) {
          _chart['stepResultStats'] = data.stepResultStats;
        }
        if (data.postConditionStats) {
          _chart['postConditionStats'] = data.postConditionStats;
        }
        if (data.dependantScriptStats) {
          _chart['dependantScriptStats'] = data.dependantScriptStats;
        }
      }
    }
    setChartData(_chart);
    setInfoData(_info);
  };

  const setMoreDetailsFlag = (val) => {
    setFocusMoreDetails(val);
  };

  const onMenuItemClick = (val) => {
    console.log('menu clicked::', val);
  };
  return (
    <>
      <div className="grid grid-cols-6 pb-4">
        <div className="fontPoppinsSemiboldXlg">Results</div>
        <div className="ml-32">
          <div className="alert-position-style-result">
            <AlertContatiner></AlertContatiner>
          </div>
        </div>
      </div>
      <div className=" flex overflow-scroll h-80v result-content-height">
        <div className="flex-1 focus:outline-none">
          <main className="flex-1 relative pb-2 z-0 base-card">
            {/* previous results */}
            {isLoading || !infoData || !chartData ? (
              <CommonLoader />
            ) : (
              <>
                {!suiteType && (
                  <div className="grid grid-cols-2 gap-3">
                    <div className={suiteType ? 'col-span-12' : 'col-end-2 col-span-12'}>
                      <div
                        key="result-info"
                        className="bg-white flex h-44 card-box-shadow items-center overflow-hidden"
                      >
                        {infoData && infoData.runId && (
                          <ResultInfoPanel
                            data={infoData}
                            focusMoreDetails={setMoreDetailsFlag}
                            resultType={resultType}
                            scriptType={scriptType}
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-start-2">
                      <div key="result-chart" className="bg-white h-44 overflow-hidden card-box-shadow">
                        {chartData ? <ResultCharts card={card} chartData={chartData} type="Script" /> : ''}
                      </div>
                    </div>
                  </div>
                )}
                <div className="grid grid-cols-1 mt-2 ">
                  <div key="result-status" className="bg-white card-box-shadow">
                    {scriptResultData && reloadAccordion && !suiteType ? (
                      <ResultAccordion
                        {...props}
                        reloadAccordion={reloadAccordion}
                        content={scriptResultData}
                        MyAlert={MyAlert}
                        setrefreshPage={setrefreshPage}
                        DiscardAIStepDB={{
                          database_name: currentLicenseId,
                          scriptKey: id,
                          executionId: executionId,
                        }}
                        scriptResultsForStepId={scriptResultsForStepId}
                        setReloadAccordion={setReloadAccordion}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  {manualTestCase && suiteType && (
                    <div key="manual-test-case">
                      <ManualResult
                        getScriptResult={getScriptResult}
                        {...props}
                        MyAlert={MyAlert}
                        manualTestCase={manualTestCase}
                        content={scriptResultData}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </main>
        </div>
      </div>
    </>
  );
}

export default ScriptResultBase;

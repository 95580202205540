/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Icon } from 'fireflink-ui';

import $ from 'jquery';
import 'jquery.fancytree/dist/modules/jquery.fancytree.filter';
import '../skin/ui.fancytree.css';
import '../table_tree.scss';

import { Checkbox, Radio } from '@material-ui/core';
import {
  ExpandAllIcon,
  CollapseAllIcon,
  ShowAllChildsIcon,
  HideAllChildsIcon,
  ShowAllConditionIcon,
  HideAllConditionIcon,
} from '../table_tree_icons';
import Highlighter from 'react-highlight-words';
import { splitedScriptNameAndID } from '@src/util/common_utils';
import { createRoot } from 'react-dom/client';
import { TREE_CONSTANTS, SELECTED_MODE } from '../treeConstants';
import { REGEX } from '@src/util/validations';
import { width } from 'tailwindcss/defaultTheme';

const SuiteTree = ({ setResetFilter = () => {}, ...props }) => {
  const userSearched = props?.userSearched;
  const [showNoData, setShowNoData] = useState(false);
  const [localfilterFlag, setLocalfilterFlag] = useState(false);
  const [isExpandAll, setIsExpandAll] = useState(false);
  const [isShowAllChild, setIsShowAllChild] = useState(true);
  const [isShowAllConditions, setIsAllConditions] = useState(true);
  const [isCheckAll, setIsCheckAll] = useState(props?.rootData?.selected || false);
  const [isCheckAllIndeterminate, setIsCheckAllInterminate] = useState(props?.rootData?.partsel || false);
  const [isSelectRootRB, setIsSelectRootRB] = useState(false);
  const [treeTableId, setTreeTableId] = useState(props?.id ? props?.id : 'table-tree');
  const [disableExpandCollapse, setDisableExpandCollapse] = useState(false);
  const [callExpandApi, setCallExpandApi] = useState(true);
  const [isExpandAllData, setIsExpandAllData] = useState(false);
  const isExpandAllDataRef = useRef({});
  isExpandAllDataRef.current = isExpandAllData;
  const [treeLoading, setTreeLoading] = useState(false);
  const [resetTreeFlag, setResetTreeFlag] = useState(false);
  const { current: GLOBAL } = useRef({ ...TREE_CONSTANTS, LABELS: { ...TREE_CONSTANTS.LABELS } });

  function initSimpleTree(sourceData) {
    const _noDataHTML = $(`#${treeTableId}.no-data-msg`);
    _noDataHTML.hide();
    const showCheckbox = props.showCheckbox ? props.showCheckbox : false;
    const showRadioButton = props.showRadioButton ? props.showRadioButton : false;
    if (!localfilterFlag && (!props?.rootData || Object.keys(props.rootData).length === 0)) {
      setShowNoData(true);
    }
    if (sourceData) {
      $(`#${treeTableId}`).fancytree({
        autoScroll: false,
        selectMode: showRadioButton
          ? SELECTED_MODE.SINGLE_SELECTION_MODE
          : SELECTED_MODE.MULTI_HIERARCHIAL_SELECTION_MODE,
        source: sourceData,
        checkbox: showRadioButton ? 'radio' : showCheckbox,
        expanded: true,
        extensions: ['filter'],
        filter: {
          autoApply: true,
          autoExpand: false,
          fuzzy: false,
          hideExpandedCounter: true,
          hideExpanders: false,
          leavesOnly: false,
          nodata: true,
          counter: false,
          mode: 'hide',
          highlight: true,
        },
        table: {
          indentation: 20,
          nodeColumnIdx: 0,
        },
        click: function (event, data) {
          let targetType = $.ui.fancytree.getEventTargetType(event.originalEvent);
          if (targetType === 'title' && !data.node.data.isMockNode) {
            props.onNodeSelected({
              data: { ...data.node.data, _key: data.node.key, title: data.node.title, folder: data.node.folder },
              node: data.node,
            });
          }
        },
        select: function (event, data) {
          const node = data.node;
          const selectedNodes = [];
          const _currentTree = $.ui.fancytree.getTree(`#${treeTableId}`);
          const totalNodes = _currentTree.count();
          const nodes = _currentTree.getSelectedNodes();
          // if(!data.node.isExpanded()){
          //   data.node.setExpanded(true)
          // }
          const typesToInclude = ['PRE', 'POST'];
          if (node.children && data.node.children.length === 1 && node.children[0].data.isMockNode) {
            // when module is lazyload and not loaded with children
            if (typesToInclude.includes(node.type)) {
              // for pre/post condition expand at root level
              expandContainer(node, true, node.data.parentId, node.type);
            } else {
              // for module expand at root level
              expandContainer(node, true);
            }
          }

          // Root node radio button status
          setIsSelectRootRB(false);
          // Root node checkbox status
          setIsCheckAllInterminate(false);
          setIsCheckAll(totalNodes === nodes.length);

          // Partial check status
          if (nodes.length && totalNodes !== nodes.length) {
            setIsCheckAllInterminate(true);
          }
          nodes.forEach((node) => {
            if (!node.folder && !node.data.isMockNode) {
              selectedNodes.push({
                data: {
                  ...node.data,
                  key: node.key,
                  _key: node.key,
                  title: node.title,
                  folder: node.folder,
                },
                node: node,
              });
            } else if (node.folder) {
              traverseAndAddChildren(node, selectedNodes);
            }
          });

          props.onCheckedNodes(selectedNodes);
        },
        dblclick: function (e, data) {
          e.preventDefault();
          e.stopPropagation();
        },
        init: function () {
          const _currentTree = $.ui.fancytree.getTree(`#${treeTableId}`);
          if (props.hasOwnProperty('initFancyTree')) {
            props.initFancyTree(_currentTree);
          }
        },
        createNode: function (event, data) {
          const node = data.node;
          const nodeData = {
            data: {
              ...node.data,
              _key: node.key,
              title: node.title,
              folder: node.folder,
              type: node.type,
            },
            node: node,
          };
          const _liNode = node.span;
          const hideCBConditions = props.hideCheckboxConditions;
          props.colDefs.forEach((column, index) => {
            const _node = $(node.span);
            // Render Title column logic
            if (index === 0) {
              let titleWidth = node.folder && column.folderTitleWidth ? column.folderTitleWidth : column.width;

              _node.addClass(node.folder ? 'tree-folder-node' : 'tree-not-folder-node');
              if (treeTableId !== 'element-tree') _node.addClass(`type-${node.type}`);

              if (showCheckbox) node.checkbox = true;
              if (showRadioButton) node.checkbox = 'radio';
              if (
                (hideCBConditions && hideCBConditions.key && node[hideCBConditions.key] === hideCBConditions.value) ||
                !showCheckbox
              ) {
                node.checkbox = false;
                _node.find('.fancytree-checkbox').remove();
              }

              const _nodeHtml = _node.html();
              _node
                .html('')
                .append(
                  `<span class="tree-title" style='width:${titleWidth || ''};padding-right:${
                    node.folder && column.paddingRight ? column.paddingRight : ''
                  }'>${_nodeHtml}</span>`
                );

              const _span = _node.find('.fancytree-title');
              const _titleSpan = _node.find('.fancytree-checkbox');
              if (node.data.defaultLibrary || column.unselectable) {
                node.unselectable = true;
                node.unselectableStatus = false;
                _titleSpan.addClass('disable-radio fancytree-unselectable');
              }

              if (
                props.selectedScript?.node?.key === node.key &&
                node.parent?.parent?.title === 'root' &&
                node.data.executionOrder <= 1
              ) {
                const _titleParentSpan = $(node.parent.span).find('.fancytree-checkbox');
                node.parent.unselectable = true;
                node.parent.unselectableStatus = false;
                _titleParentSpan.addClass('disable-radio fancytree-unselectable');
              }

              if (column.render) {
                let title = _span.text();
                const folderClass = node?.folder ? '' : 'tree-folder-title-repo-module-width';
                if (props.titleKey && !node.folder) title = node.data[props.titleKey];
                _span.text('');

                if (!!node.folder || (!node.folder && ['PRE', 'POST'].includes(node?.type))) {
                  _span.append(
                    `<span class="fancytree-title ${folderClass}" title="${title.replace(
                      /  +/g,
                      ' '
                    )}">${getHighlightedTitle(title)}</span>`
                  );
                } else if (['moduleTreeTD', 'systemTree', 'run-tree'].includes(props?.id)) {
                  const [id, name] = splitedScriptNameAndID(title);
                  _span.append(
                    `<span class="fancytree-title ${folderClass} script-id-style" title="${title.replace(
                      /  +/g,
                      ' '
                    )}">${getHighlightedTitle(id)}</span>
                                 <span class="name-id-separator"> - </span>
                                 <span class="fancytree-title ${folderClass}" title="${title.replace(
                      /  +/g,
                      ' '
                    )}">${getHighlightedTitle(name)}</span>`
                  );
                } else {
                  _span.append(
                    `<span class="fancytree-title ${folderClass}" title="${title.replace(
                      /  +/g,
                      ' '
                    )}">${getHighlightedTitle(title)}</span>`
                  );
                }

                _span
                  .append(`<span class="node-title-render"></span>`)
                  .removeClass('fancytree-title')
                  .addClass('flex flex-1 fancytree-title-wrapper tree-title-width');

                if (node.data.isMockNode) _span.addClass('fancytree-mock-node-wrapper');

                const _titleRender = _span.find('.node-title-render')[0];
                createRoot(_titleRender).render(React.createElement(column.render, nodeData));
              }
            }

            // Render other columns logic
            if (index > 0) {
              const _colRenderer = $(
                '<span class="node-col fontPoppinsRegularMd ' +
                  column.class +
                  '" style="width: ' +
                  column.width +
                  '">' +
                  index +
                  '</span>'
              );
              $(_liNode).append(_colRenderer);
              if (column.render) {
                createRoot(_colRenderer[0]).render(React.createElement(column.render, nodeData));
              } else {
                _colRenderer.html(node.data[column.field] || '');
              }
            }
          });
        },
      });
    }
    $('.fancytree-container').addClass('fancytree-connectors');
    $('.fancytree-container').on('mouseenter', '.fancytree-expander', function (e) {
      e.preventDefault();
      $(this).closest('ul').find('> li').addClass('highlight-lines');
    });

    $('.fancytree-container').on('mouseleave', '.fancytree-expander', function (e) {
      e.preventDefault();
      $(this).closest('ul').find('> li').removeClass('highlight-lines');
    });
  }

  function traverseAndAddChildren(node, selectedNodes) {
    const stack = [node];
    while (stack.length > 0) {
      const currentNode = stack.pop();
      if (!currentNode.folder && !currentNode.data.isMockNode) {
        selectedNodes.push({
          data: {
            ...currentNode.data,
            key: currentNode.key,
            _key: currentNode.key,
            title: currentNode.title,
            folder: currentNode.folder,
          },
          node: currentNode,
        });
      }
      if (currentNode.children) {
        currentNode.children.forEach((child) => stack.push(child));
      }
    }
  }

  const checkDuplicate = (list, item, _key) => {
    return list.some((_listItem) => _listItem[_key] === item[_key]);
  };
  const filterTreeData = (query) => {
    const _tree = $.ui.fancytree.getTree(`#${treeTableId}`);
    setLocalfilterFlag(true);
    let replacement = '<mark>$&</mark>';
    query = query.replace(REGEX.ESCAPE_SPECIAL_CHARACTERS);
    let rex = new RegExp(query, 'ig');
    let isMatch = false;
    const _allFolderList = [];
    const _allMatchedList = [];
    const _res = _tree?.filterNodes(
      (node) => {
        node.titleWithHighlight = node?.title?.replace(rex, replacement);
        if (node?.folder) _allFolderList.push(node);
        if (rex.test(node.title) === true) {
          isMatch = true;
          if (!checkDuplicate(_allMatchedList, node, 'key')) {
            _allMatchedList.push(node);
            node.setExpanded(false);
          }
        } else {
          isMatch = false;
        }
        return isMatch;
      },
      { autoExpand: true }
    );
    _allMatchedList?.forEach((_matchNode) => {
      const matchPathArr = _matchNode?.data?.searchKey?.split('/');
      matchPathArr?.shift();
      _allFolderList?.forEach((_node) => {
        if (!['PRE', 'POST'].includes(_node.type)) {
          const folderPathArr = _node?.data?.searchKey?.split('/');
          folderPathArr?.shift();
          const __found = folderPathArr.every((__fpath) => matchPathArr?.includes(__fpath));
          if (__found) {
            isMatch = true;
            _node.setExpanded(true);
          }
        }
      });
    });
    setResetTreeFlag(false);
    const noDataHTML = $(`#${treeTableId} .no-data-msg`);
    if (_res === 0) {
      setShowNoData(true);
      noDataHTML.show();
    }
  };

  const resetFilter = () => {
    var KeyNoData = '__not_found__';
    const _tree = $.ui.fancytree.getTree(`#${treeTableId}`);
    if (!showNoData && props?.resetFilter) {
      setShowNoData(false);
      if (_tree) {
        var $title,
          statusNode = _tree?.getRootNode()?._findDirectChild(KeyNoData),
          prevEnableUpdate = _tree?.enableUpdate(false);
        if (statusNode) {
          statusNode.remove();
        }
        _tree?.visit(function (node) {
          if (node.match && node.span) {
            $title = $(node.span).find('span.fancytree-title');
            $title.text(node.title);
            $title.html(node.title);
          }
          delete node.match;
          delete node.subMatchCount;
          delete node.titleWithHighlight;
          if (node.$subMatchBadge) {
            node.$subMatchBadge.remove();
            delete node.$subMatchBadge;
          }
          if (node._filterAutoExpanded && node.expanded) {
            node.setExpanded(false, {
              noAnimation: true,
              noEvents: true,
              scrollIntoView: false,
            });
          }
          delete node._filterAutoExpanded;
        });
        _tree.enableFilter = false;
        _tree.lastFilterArgs = null;
        _tree.$div.removeClass('fancytree-ext-filter fancytree-ext-filter-dimm fancytree-ext-filter-hide');
        _tree._callHook('treeStructureChanged', _tree, 'clearFilter');
        _tree.enableUpdate(prevEnableUpdate);
        setResetTreeFlag(true);
      }
    } else {
      setResetTreeFlag(true);
      setShowNoData(false);
    }
    _tree?.expandAll(false);
    if (props?.id === 'test-data-page') localStorage.removeItem('test-data-pageexpanded');
    if (props?.id === 'systemTree') localStorage.removeItem('systemTreeexpanded');
  };

  const highlightRootText = (text) => {
    return (
      <Highlighter
        highlightClassName="highlightTheTexts"
        autoEscape={true}
        searchWords={[userSearched]}
        textToHighlight={text || ''}
      />
    );
  };

  const getHighlightedTitle = (_title) => {
    if (userSearched && userSearched.length && _title.length) {
      const startIndex = _title.toLowerCase().indexOf(userSearched.toLowerCase());
      let returnTitle = '';
      const titleArray = _title.split('');
      if (startIndex > -1) {
        titleArray.forEach((_t, ind) => {
          if (ind >= startIndex && ind < startIndex + userSearched.length) {
            if (ind === startIndex) {
              if (userSearched.length === 1) {
                returnTitle += `<mark>${_t}</mark>`;
              } else {
                returnTitle += `<mark>${_t}`;
              }
            } else if (ind === startIndex + userSearched.length - 1) {
              returnTitle += `${_t}</mark>`;
            } else {
              returnTitle += _t;
            }
          } else {
            returnTitle += _t;
          }
        });
        return returnTitle;
      }
      return _title;
    }
    return _title;
  };

  /**
   * Method to check if the given node is child of a lazy loaded parent
   * @param {*} node
   * @returns boolean
   */

  /**
   *
   * @param {*} node
   * @param {*} selectNode Boolean value | true: node is selected
   * @param {*} rootMod_id | on root level Pre/Post condition expand | for root level condition lazy load
   * @param {*} conditionType | type of the condition container | for root level condition lazy load
   * @returns
   */
  const expandContainer = (node, selectNode, rootMod_id, conditionType) => {
    if (props.hasOwnProperty('loadMoreData')) {
      const _li = $(node.li);
      _li.addClass('expand-data-loading'); //to have lazy loading icon on module expand api call
      props.loadMoreData(node.key, rootMod_id, conditionType).then((res) => {
        if (res && res.length) {
          node.removeChildren();
          node.data['lazyLoaded'] = true;
          node.expanded = true;
          node.addChildren(res);
          showHideAllConditions(GLOBAL.SHOW_ALL_CONDITIONS);
          _li.removeClass('expand-data-loading');
          if (props.defaultDisabled && props.defaultDisabled.length) {
            defaultDisableNodes(props.defaultDisabled);
          }
          if (props.defaultChecked && props.defaultChecked.length) {
            defaultCheckNodes(props.defaultChecked);
          }
          if (selectNode) {
            node.setSelected(true);
            node.visit(function (_childNode) {
              _childNode.setSelected(true);
            });
          }
          return res;
        } else {
          //if no response/network call fails
          _li.removeClass('expand-data-loading');
          node.setExpanded(false);
          node.setSelected(false);
          node.visit(function (_childNode) {
            _childNode.setSelected(false);
          });
        }
      });
    }
    return false;
  };
  const expandCollapseAll = (isExpand) => {
    const _currentTree = $.ui.fancytree.getTree(`#${treeTableId}`);
    if (callExpandApi && isExpand && props.hasOwnProperty('expandAllApi')) {
      if (_currentTree) _currentTree.clear();
      setTreeLoading(true);
      props.expandAllApi().then((_treeData) => {
        if (_treeData) {
          setIsExpandAllData(true);
          if (_currentTree) _currentTree.reload(_treeData);
          if (props.defaultDisabled && props.defaultDisabled.length) {
            defaultDisableNodes(props.defaultDisabled);
          }
          if (props.defaultChecked && props.defaultChecked.length) {
            defaultCheckNodes(props.defaultChecked);
          }
          setCallExpandApi(false);
          setTreeLoading(false);
          handleExpandCollapseAllOperation(isExpand);
        }
      });
    } else {
      handleExpandCollapseAllOperation(isExpand);
    }
    setIsExpandAll(isExpand);
  };

  const handleExpandCollapseAllOperation = (isExpand) => {
    if (isExpand) {
      try {
        // to address the performance issue on expand all
        setDisableExpandCollapse(true);
        $.ui.fancytree
          .getTree(`#${treeTableId}`)
          ?.visit(async function (node) {
            await sleep(2);
            node.setExpanded(isExpand);
          })
          .then(() => {
            setDisableExpandCollapse(false);
            setIsExpandAll(isExpand);
          });
      } catch (error) {
        console.error('Error while expanding tree node:', error);
      }
    } else {
      setDisableExpandCollapse(true);
      $.ui.fancytree.getTree(`#${treeTableId}`).expandAll(isExpand);
      setDisableExpandCollapse(false);
      setIsExpandAll(isExpand);
    }
    //need some more clarification
    // const childNodes = $('.fancytree-exp-n:not(.fancytree-folder), .fancytree-exp-nl:not(.fancytree-folder), .tree-not-folder-node').parent('li');
    // childNodes.hide();
  };

  const showHideAllChild = (isShow) => {
    $.ui.fancytree.getTree(`#${treeTableId}`)?.visit(function (node) {
      if (!node.folder) {
        if (isShow) {
          $(node.li).show();
        } else {
          $(node.li).hide();
        }
      }
    });
    GLOBAL.SHOW_ALL_CHILD = isShow;
    setIsShowAllChild(isShow);
  };

  const showHideAllConditions = (isShow) => {
    const conditions = props.conditions;
    const isExecutionTree = props.isExecutionTree;
    $.ui.fancytree.getTree(`#${treeTableId}`)?.visit(function (node) {
      conditions?.map((condition) => {
        if (condition && condition.key && node[condition.key] === condition.value) {
          if (isShow) {
            if (node?.children && isExecutionTree) {
              node?.children.map((nodeData) => {
                nodeData.removeChildren();
              });
            }
            if (node.folder) {
              $(node.li).show();
              if (props?.handleConditionsVisible) {
                props.handleConditionsVisible(true);
              }
            }
          } else {
            if (node.folder) {
              $(node.li).hide();
              if (props?.handleConditionsVisible) {
                props.handleConditionsVisible(false);
              }
            }
          }
        }
      });
    });

    GLOBAL.SHOW_ALL_CONDITIONS = isShow;
    setIsAllConditions(isShow);
  };

  const defaultCheckNodes = (conditions) => {
    $.ui.fancytree.getTree(`#${treeTableId}`)?.visit(function (node) {
      conditions?.map((condition) => {
        if (!isSelectRootRB && condition && condition.key && props.rootData[condition.key] === condition.value) {
          setIsSelectRootRB(true);
        }
        if (condition && condition.key && node[condition.key] === condition.value) {
          node.setSelected(true);
        }
      });
    });
    if (props.hasOwnProperty('ifTreeLoaded')) {
      props.ifTreeLoaded(true);
    }
  };

  const defaultDisableNodes = (conditions) => {
    $.ui.fancytree.getTree(`#${treeTableId}`)?.visit(function (node) {
      const _span = $(node.span);
      conditions?.map((condition) => {
        if (condition && condition.key) {
          if (
            (condition.key.startsWith('data.') && node.data[condition.key.slice(5)] === condition.value) ||
            node[condition.key] === condition.value
          ) {
            node.unselectable = true;
            node.unselectableStatus = false;
            if (_span) _span.addClass('fancytree-unselectable');
          }
        }
      });
    });
  };

  const checkUncheckAll = (e) => {
    if (props.setAllChecked) {
      props.setAllChecked(e.target.checked);
    }
    const isChecked = e.target.checked;
    $.ui.fancytree.getTree(`#${treeTableId}`).selectAll(isChecked);
    setIsCheckAll(isChecked);
  };

  const rootNodeClicked = (e) => {
    e.preventDefault();
    if (props.onRootNodeClicked) {
      props.onRootNodeClicked(props.rootData);
    }
  };

  const selectUnselectRootRB = (e) => {
    e.preventDefault();
    const radioBtnChecked = isSelectRootRB;
    if (radioBtnChecked) {
      setIsSelectRootRB(false);
      props.onCheckedNodes([]);
    } else {
      $.ui.fancytree.getTree(`#${treeTableId}`).selectAll(false);
      setIsSelectRootRB(true);
      props.onCheckedNodes([
        {
          data: {
            ...props.rootData,
            key: props.rootData.key,
            _key: props.rootData.key,
            title: props.rootData.title,
            folder: props.rootData.folder,
            isRootNode: true,
          },
          node: null,
        },
      ]);
    }
  };

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const expandPersistedNodes = () => {
    const _currentTree = $.ui.fancytree.getTree(`#${treeTableId}`);
    if (props.hasOwnProperty('getFancyTree')) {
      props.getFancyTree(_currentTree);
    }
    _currentTree?.visit(async function (node) {
      const localData = localStorage.getItem(`${treeTableId}expanded`);
      if (localData) {
        let persistData = localData.split('~');
        if (persistData.includes(node.key)) {
          if (!node.expanded) {
            node.setExpanded(true);
          }
        }
      }
    });
  };

  useEffect(() => {
    if (props.id) {
      setTreeTableId(props.id);
    }
    initSimpleTree(props?.data);
    if (!props?.filter) {
      expandPersistedNodes();
    }
  }, [treeTableId, props?.data, resetTreeFlag]);

  useEffect(() => {
    GLOBAL.LABELS = { ...GLOBAL.LABELS, ...props.labels };
  }, [props.labels]);

  useEffect(() => {
    showHideAllConditions(!props.hideConditions);
  }, [props.hideConditions]);

  useEffect(() => {
    if (props?.defaultChecked?.length) {
      defaultCheckNodes(props.defaultChecked);
    }
  }, [props.defaultChecked]);

  useEffect(() => {
    if (props?.defaultDisabled?.length) {
      defaultDisableNodes(props.defaultDisabled);
    }
  }, [props.defaultDisabled]);

  useEffect(() => {
    showHideAllChild(!props.hideElements);
  }, [props.hideElements]);

  useEffect(() => {
    if (props.filter) {
      filterTreeData(props.filter);
    }
  }, [props.filter]);
  useEffect(() => {
    if (props?.resetFilter) {
      resetFilter();
    }
  }, [props?.resetFilter]);

  return (
    <div className="table-tree table-height pb-2">
      {/* Header */}
      <div className="tree-header-wrp">
        {props.colDefs.map((column) => {
          if (column.field === 'title') {
            return (
              <>
                <div className={`tree-header ${column.class} flex flex-row relative`}>
                  <div className="">{column.title}</div>
                  <div className="flex flex-row  relative">
                    <div>
                      <span className=" absolute my-0 ml-0">
                        <button
                          type="button"
                          className="root-btn exapnd-all-btn ml-3"
                          onClick={() => {
                            expandCollapseAll(!isExpandAll);
                          }}
                          title={isExpandAll ? GLOBAL.LABELS.COLLAPSE_ALL : GLOBAL.LABELS.EXPAND_ALL}
                          disabled={disableExpandCollapse}
                        >
                          {isExpandAll ? (
                            <CollapseAllIcon disable={disableExpandCollapse} />
                          ) : (
                            <ExpandAllIcon disable={disableExpandCollapse} />
                          )}
                        </button>
                      </span>
                      <span className="absolute my-0 ml-10">
                        {!props.hideElementsBtn && (
                          <button
                            type="button"
                            className="root-btn show-all ml-3"
                            onClick={() => {
                              showHideAllChild(!isShowAllChild);
                            }}
                            title={isShowAllChild ? GLOBAL.LABELS.HIDE_ALL_ELEMENTS : GLOBAL.LABELS.SHOW_ALL_ELEMENTS}
                          >
                            {isShowAllChild ? <HideAllChildsIcon /> : <ShowAllChildsIcon />}
                          </button>
                        )}
                      </span>
                      <span className="absolute my-0 ml-20">
                        {!props.hideConditionBtn && (
                          <button
                            type="button"
                            className="root-btn show-all-condition ml-3"
                            onClick={() => {
                              showHideAllConditions(!isShowAllConditions);
                            }}
                            title={
                              isShowAllConditions
                                ? GLOBAL.LABELS.HIDE_PRE_POST_CONDITION
                                : GLOBAL.LABELS.SHOW_PRE_POST_CONDITION
                            }
                          >
                            {isShowAllConditions ? <HideAllConditionIcon /> : <ShowAllConditionIcon />}
                          </button>
                        )}
                      </span>
                    </div>
                    {props?.scriptType && props.toReturnEnvData()}
                  </div>
                </div>
              </>
            );
          }
          return (
            <span className={`tree-header ${column.class}`} style={{ width: column.width ? column.width : 'unset' }}>
              {column.title}
            </span>
          );
        })}
      </div>
      {/* Root Node */}
      {!showNoData ? (
        <div className="tree-root-wrp">
          {props.colDefs.map((column) => {
            const showCheckbox = props.showCheckbox ? props.showCheckbox : false;
            const showRadioButton = props.showRadioButton ? props.showRadioButton : false;
            const rootValue = props.rootData ? props.rootData[column.field] : props?.rootData?.name;
            const colRender = column.render ? column.render({ data: props.rootData, node: {}, isRootNode: true }) : '';
            if (column.field === 'title') {
              return (
                <span className="root-title-wrp">
                  {showCheckbox && (
                    <Checkbox
                      color="primary"
                      disableRipple
                      disabled={!props.data || (props.data && !props.data.length) || props.disableRootCheckbox}
                      checked={isCheckAll}
                      indeterminate={isCheckAllIndeterminate}
                      onChange={checkUncheckAll}
                      className="root-check-all"
                    />
                  )}
                  {!props.hideRootRadioBtn && showRadioButton && (
                    <Radio
                      color="primary"
                      id="rootRB"
                      name="rootRB"
                      disableRipple
                      disabled={props.disableRootRadioButton}
                      checked={isSelectRootRB}
                      onClick={selectUnselectRootRB}
                    />
                  )}
                  <div className={`root-header ${column.class}`}>
                    {rootValue === 'Root' ? (
                      <span href="#" className="fancytree-title modifiedBy" title={rootValue} onClick={rootNodeClicked}>
                        {highlightRootText(rootValue.concat(` ${props.pageType}`))}
                      </span>
                    ) : (
                      <span href="#" className="fancytree-title modifiedBy" title={rootValue} onClick={rootNodeClicked}>
                        {highlightRootText(rootValue)}
                      </span>
                    )}
                    {colRender ? colRender : ''}
                  </div>
                </span>
              );
            }
            return (
              <span
                className={`root-header ${column.class}`}
                style={{ width: column.field === 'scriptType' ? '0px' : column.width }}
              >
                {colRender ? colRender : rootValue}
              </span>
            );
          })}
        </div>
      ) : null}

      {(showNoData && userSearched) || (showNoData && props?.isFilterApplied) ? (
       <NoResultFound />
      ) : !showNoData && props?.data?.length ? (
        <div className="table-tree-wrp table-height overflow-auto overflow-x-hidden" id="journal-scroll">
          <div id={treeTableId} className="table-tree"></div>
        </div>
      ) : props?.location === 'tableTreeWithRadioBtn' ? (
        <div className="overflow-y-auto h-82">
          <div className="mt-28 no-steps">
            <div classname="create-set-layout">
              <div className="qucBtn">
                <label className="quick-start-style px-1 fontPoppinsRegularLg">
                  {props.noDataMsg ? props.noDataMsg : `No ${props.pageType} Available`}
                </label>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div id={treeTableId} className="no-data-msg">
      <NoResultFound />
      </div>
    </div>
  );
};

export default SuiteTree;
const NoResultFound = () => {
  return (
    <div className={`flex flex-col justify-center items-center mt-12`}>
      <div className=" mt-4 mb-2">
      <Icon name="no_execution" height={250} width={250}/>
      </div>
      <div className="fontPoppinsMediumLg rs-blue">{TREE_CONSTANTS.LABELS.NO_RESULT_MSG}</div>
    </div>
  );
};

import { Toggle } from 'fireflink-ui';
import React, { useEffect, useState } from 'react';

export const TLSVersions = ({ version, index, onSettingTLSVersionChange, versionToogle }) => {
  const [tlsVersion, setTlsVersion] = useState(versionToogle);

  const onSettingVersionChange = () => {
    setTlsVersion(!tlsVersion);
    onSettingTLSVersionChange(index);
  };

  useEffect(() => {
    setTlsVersion(versionToogle);
  }, [versionToogle]);

  return (
    <>
      <p className="fontPoppinsRegularMd text-left pr-2 version-text">{version.name}</p>
      <Toggle
        id={`toggles${index}`}
        type="default"
        variant="primary"
        checked={tlsVersion}
        onChange={onSettingVersionChange}
        isBackgroundTransparent={true}
      />
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { DragIndicatorOutlined } from '@material-ui/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { mergeElementReq, postElementReq } from '@api/api_services';
import { ELEMENT_CONFLICT_PAGE } from '@src/common/ui-constants';
import { UI_VALIDATIONS } from '@src/util/validations';
import cx from 'classnames';

const ElementConflictPage = (props) => {
  let [sharedEleData, setSharedEleData] = useState(props.sharedConflictEles);
  const [projelementData, setProjElementData] = useState(props.sharingEle);
  let [sharingEleAsEle, setSharingEleAsEle] = useState(props.sharingEle);
  let [locForCreatAsEle, setLocForCreatAsEle] = useState(props.sharingEle.locators);
  let [projecteleLoc, setprojecteleLoc] = useState([...props.sharingEle?.locators]);
  let [sharedEleLoc, setSharedEleLoc] = useState([...props.sharedConflictEles[0]?.Locators]);
  let [selectedIndex, setSelectedIndex] = useState(0);
  let project = JSON.parse(localStorage.getItem('selected-project'));
  let isshare = false;
  let [disableAll, setDisableAll] = useState(false);
  let [clearMRPayload, setClearMRPayload] = useState({});
  let [pageId, setPageId] = useState(props.sharingEle.parentId);
  let [eleId, setEleId] = useState(props.sharingEle.id);
  const [maxDragError, setMaxDragError] = useState('');

  let createAsEle = async () => {
    let payload = sharingEleAsEle;
    payload.isShared = 'N';
    payload.locators = locForCreatAsEle;
    let response;
    if (props.createAsSharedEle) {
      try {
        response = await postElementReq(payload, props.pageKeyForEle);
        if (response.data.responseObject) {
          props?.getAllElemtsReq(props?.projectType, props?.platForm);
          props.reloadTree(true);
          props.MyAlert.success(`${payload.name} ${payload.type} created successfully`);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  let handleDelete = (index, eleType) => {
    if (eleType === 'projLoc') {
      let projElementsLoc = [...projecteleLoc];
      let sharedData = { ...sharedEleData[selectedIndex] };
      projElementsLoc.splice(index, 1);
      if (projElementsLoc.length === 0) {
        sharedData.Locators = [...sharedEleLoc];
        setDisableAll(false);
        setClearMRPayload({ ...sharedData });
      }
      setprojecteleLoc([...projElementsLoc]);
    } else if (eleType === 'sharedLoc') {
      let sharedData = { ...sharedEleData[selectedIndex] };
      let sharedelementsLoc = [...sharedEleLoc];
      sharedelementsLoc.splice(index, 1);
      sharedData.Locators = sharedelementsLoc;
      setClearMRPayload({ ...sharedData });
      setSharedEleLoc([...sharedelementsLoc]);
    } else if (eleType === 'Ele') {
      let sharedEles = [...sharedEleData];
      let sharedEle = sharedEles.splice(index, 1);
      if (sharedEles.length === 0) {
        props.getAllElemtsReq(props.projectDetails?.type, props.platForm);
        createAsEle();
        props.closeModal(false);
      } else if (selectedIndex > sharedEles.length - 1) {
        setSelectedIndex(sharedEles.length - 1);
        setSharedEleLoc(sharedEles[sharedEles.length - 1]?.Locators);
      } else {
        if (selectedIndex === index) {
          setSharedEleLoc([...sharedEles[index]?.Locators]);
        }
      }
      props.MyAlert.success(`${projelementData?.name} ${projelementData?.type} element skipped successfully.`);
      setSharedEleData([...sharedEles]);
    }
  };

  let clearMergeConflictReq = async () => {
    let response;
    let payload = clearMRPayload;
    payload.locators = clearMRPayload.Locators;
    payload.parentId = clearMRPayload?.parentId[0];
    payload.projectType = props.projectType;
    payload.projectId = project.id;
    delete payload?.Locators;
    delete payload?.pageIdNames;
    try {
      response = await mergeElementReq(payload, clearMRPayload.id, eleId, pageId);
      if (response.data?.responseCode === 200 && response.data?.status === 'SUCCESS') {
        props?.getAllElemtsReq(props?.projectType, props?.platForm);
        props.reloadTree(true);
        props.updateSharedData('Y');
      } else {
        if (props.createAsSharedEle) {
          createAsEle();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  let onDragEnd = (result) => {
    let sharedData = { ...sharedEleData[selectedIndex] };
    if (!result.destination) return;

    if (sharedData?.type === 'visual testing' && sharedEleLoc.length >= UI_VALIDATIONS.MAX_FILE_UPLOAD_COUNT) {
      setMaxDragError(ELEMENT_CONFLICT_PAGE.MAX_ASSIGN_ERROR);
      return;
    } else {
      setMaxDragError('');
    }

    if (result.destination.droppableId === 'sharedLocdragdrop' && result.source.droppableId === 'projectLocdragdrop') {
      const shareditems = Array.from([...sharedEleLoc]);
      const sharingitems = Array.from([...projecteleLoc]);

      if (isshare === false) {
        const [modifiedSharingItem] = sharingitems.splice(result.source.index, 1);
        modifiedSharingItem.key = 'draged';
        shareditems.splice(result.destination.index, 0, modifiedSharingItem);
        setprojecteleLoc([...sharingitems]);
        setSharedEleLoc([...shareditems]);
        if (disableAll === false) {
          setDisableAll(true);
        }
        if (sharingitems.length === 0) {
          sharedData.Locators = shareditems;
          setDisableAll(false);
          setClearMRPayload({ ...sharedData });
        }
      }
      isshare = false;
    } else if (
      result.destination.droppableId === 'projectLocdragdrop' &&
      result.source.droppableId === 'sharedLocdragdrop'
    ) {
      return;
    }
  };

  let beforStart = (result) => {
    const shareditems = Array.from([...sharedEleLoc]);
    const sharingitems = Array.from([...projecteleLoc]);
    let shareitem = result.source.index;
    let dragitem = sharingitems[shareitem];
    if (result.source?.droppableId === 'projectLocdragdrop') {
      for (let i = 0; i < shareditems.length; i++) {
        if (['id', 'name', 'className', 'tagName', 'linkText', 'accessibilityID'].includes(dragitem?.name)) {
          if (shareditems[i].name === dragitem.name) {
            isshare = true;
            break;
          }
        } else if (props.platForm === 'Mobile' && shareditems[i]?.type?.toLowerCase() === 'png' && dragitem?.type?.toLowerCase() === 'png') {
          isshare = false;
          break;
        } else if (shareditems[i].name === dragitem.name && shareditems[i].value === dragitem.value) {
          isshare = true;
          break;
        }
      }
    }
  };
  let ProjLocatorComponent = (props) => {
    let [isSame, setIsSame] = useState(false);

    useEffect(() => {
      sharedEleLoc?.map((sharLoc) => {
        if (sharLoc?.value === props?.loctor?.value && sharLoc?.name === props?.loctor?.name) {
          setIsSame(true);
        }
      });
    }, []);

    return (
      <Draggable
        key={`${props.loctor?.value ? `${props.loctor.name}${props.loctor.value}` : `${props.loctor.name}`}`}
        draggableId={`${
          props.loctor?.value ? `proj${props.loctor.name}${props.loctor.value}` : `proj${props.loctor.name}`
        }`}
        index={props.index}
      >
        {(provided, snapshot) => (
          <div
            className="conflictBodyLocatorCapsule flex items-center float-left"
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <span
              className={cx('individualLocStyle', 'flex', 'items-center', 'fontPoppinsRegularSm', {
                fontPoppinsRegularSm: isSame,
                conflictBodyDangerText: !isSame,
              })}
            >
              <span {...provided.dragHandleProps}>
                <DragIndicatorOutlined fontSize="small" className="text-blue-600 cursor-grab" />
              </span>
              <div
                className="path-trimmer"
                title={`${props.loctor.value ? props.loctor.name + '-' + props.loctor.value : props.loctor?.name}`}
              >
                {props.loctor.name}-{props.loctor.value}
              </div>
            </span>
            <button
              className="locActionIcon"
              onClick={() => {
                handleDelete(props.index, 'projLoc');
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18px" height="18px" fill="#1648c6">
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" />
              </svg>
            </button>
          </div>
        )}
      </Draggable>
    );
  };

  let SharedLocatorComponent = (props) => {
    let [isProjectloc, setIsProjectloc] = useState(props?.loctor?.key === 'draged' ? false : true);
    let [isSame, setIsSame] = useState(false);

    useEffect(() => {
      projecteleLoc?.map((recLoc) => {
        if (recLoc?.value === props?.loctor?.value && recLoc?.name === props?.loctor?.name) {
          setIsSame(true);
        }
      });
    }, []);

    const getLocatorClass = () => {
      if (isSame) return 'fontPoppinsRegularSm';
      if (props.loctor.key === 'draged') return 'shareloctxt';
      return 'conflictBodyDangerText';
    };

    return (
      <Draggable
        key={`${props.loctor?.value ? `${props.loctor.name}${props.loctor.value}` : `${props.loctor.name}`}`}
        draggableId={`${
          props.loctor?.value ? `shared${props.loctor.name}${props.loctor.value}` : `shared${props.loctor.name}`
        }`}
        index={props.index}
      >
        {(provided, snapshot) => (
          <div
            className={cx('flex', 'items-center', 'float-left', {
              unEditableconflictLocatorCapsule: isProjectloc,
              conflictBodyLocatorCapsule: !isProjectloc,
            })}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <span
              className={cx(getLocatorClass(), 'flex', 'items-center', {
                unEditableindividualLocStyle: isProjectloc,
                individualLocStyle: !isProjectloc,
              })}
            >
              <div
                className="path-trimmer"
                title={`${props.loctor.value ? props.loctor.name + '-' + props.loctor.value : props.loctor?.name}`}
              >
                {props.loctor.name}-{props.loctor.value}
              </div>
            </span>
            {!isProjectloc ? (
              <button
                className="locActionIcon"
                onClick={() => {
                  handleDelete(props.index, 'sharedLoc');
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18px" height="18px" fill="#1648c6">
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" />
                </svg>
              </button>
            ) : null}
          </div>
        )}
      </Draggable>
    );
  };

  return (
    <div className="conflictPage">
      <div className="conflictPageHeader flex items-center mb-5">
        <span className="conflictHeader-style">Element Conflict</span>
        <span className="float-right ml-auto">
          <button
            type="button"
            onClick={() => {
              props.getAllElemtsReq(props.projectDetails?.type, props.platForm);
              props.unCheckShareElementBtn(props.sharingEle);
              createAsEle();
              props.closeModal(false);
            }}
            className="gray-btn mr-4 "
          >
            <span className="cancel-label-common cancel-label-layout-style-project fontPoppinsMediumMd">Close</span>
          </button>
        </span>
      </div>
      <div className="flex items-start conflictBody overflow-y-auto" id="journal-scroll">
        <div className="ConflictEleDisplay overflow-y-auto" id="journal-scroll">
          {sharedEleData?.map((ele, index) => {
            return (
              <div className="elementCapsule mt-2.5 flex items-center">
                <span
                  className="mr-2.5 cursor-pointer"
                  onClick={() => {
                    if (!disableAll) {
                      handleDelete(index, 'Ele');
                    }
                  }}
                >
                  <svg
                    id="cancel_black_24dp"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path id="Path_519" data-name="Path 519" d="M0,0H24V24H0Z" fill="none" />
                    <path
                      id="Path_520"
                      data-name="Path 520"
                      d="M12,2A10,10,0,1,0,22,12,9.991,9.991,0,0,0,12,2Zm5,13.59L15.59,17,12,13.41,8.41,17,7,15.59,10.59,12,7,8.41,8.41,7,12,10.59,15.59,7,17,8.41,13.41,12Z"
                      fill="#c50303"
                    />
                  </svg>
                </span>
                <div
                  className={`${selectedIndex === index ? 'conflictEleCapsuleClicked' : 'conflictEleCapsule'} ${
                    disableAll ? 'opacity-50' : null
                  } flex items-center cursor-default`}
                  onClick={() => {
                    if (selectedIndex !== index && !disableAll) {
                      let prjEle = { ...projelementData };
                      let sharedEle = [...sharedEleData];
                      prjEle.locators = projecteleLoc;
                      sharedEle[selectedIndex].Locators = sharedEleLoc;
                      setProjElementData({ ...prjEle });
                      setSharedEleData([...sharedEle]);
                      setSelectedIndex(index);
                      setprojecteleLoc([...prjEle.locators]);
                      setSharedEleLoc([...sharedEleData[index]?.Locators]);
                    }
                  }}
                >
                  <span className=" ml-4 flex items-center w-full">
                    <div
                      className="path-trimmer elementCapsuleNameStyle fontPoppinsMediumMd"
                      title={`${ele.name && ele.parentName ? ele.name + '-' + ele.parentName : '--'}`}
                    >
                      {ele.name} - {ele.parentName}
                    </div>
                  </span>
                </div>
              </div>
            );
          })}
        </div>
        <div className="ConflictDisplay ml-7 overflow-y-auto" id="journal-scroll">
          <div className="elementConflictBodyHeader  flex items-center">
            <div
              className="path-trimmer fontPoppinsSemiboldLg"
              title={projelementData?.name ? projelementData.name : '--'}
            >
              {projelementData?.name}
            </div>
          </div>
          <div className="overflow-y-auto" id="journal-scroll">
            <div className="overflow-y-auto conflictInnerBody" id="journal-scroll">
              <div className=" float-left recordedEleConflictBody">
                <div className="flex items-center h-8">
                  <span className="conflictBodySubHeader pr-2.5">Element In :</span>
                  <span className="conflictBodyDangerText">Project Elements</span>
                </div>
                <div className="flex items-center h-8">
                  <span className="conflictBodySubHeader pr-2.5">Element Name :</span>
                  <span
                    className="conflictBodyText path-trimmer"
                    title={projelementData.name ? projelementData.name : '--'}
                  >
                    {projelementData?.name}
                  </span>
                </div>
                <div className="flex items-center h-8">
                  <span className="conflictBodySubHeader pr-2.5">Page Name :</span>
                  <span
                    className="conflictBodyText path-trimmer"
                    title={projelementData?.parentName ? projelementData?.parentName : '--'}
                  >
                    {projelementData?.parentName}
                  </span>
                </div>
                <div className="flex items-center h-8">
                  <span className="conflictBodySubHeader pr-2.5">Type :</span>
                  <span
                    className="conflictBodyText path-trimmer "
                    title={projelementData?.type ? projelementData?.type : '--'}
                  >
                    {projelementData?.type}
                  </span>
                </div>
              </div>
              <div className={`float-right projectEleConflictBody`}>
                <div className="flex items-center h-8">
                  <span className="conflictBodySubHeader pr-2.5">Element In :</span>
                  <span className="conflictBodyDangerText pr-2.5">Shared Elements</span>
                </div>
                <div className="flex items-center h-8">
                  <span className="conflictBodySubHeader pr-2.5">Element Name :</span>
                  <span
                    className="conflictBodyText path-trimmer "
                    title={sharedEleData[selectedIndex].name ? sharedEleData[selectedIndex].name : '--'}
                  >
                    {sharedEleData[selectedIndex].name}
                  </span>
                </div>
                <div className="flex items-center h-8">
                  <span className="conflictBodySubHeader pr-2.5">Page Name :</span>
                  <span
                    className="conflictBodyText path-trimmer "
                    title={sharedEleData[selectedIndex].parentName ? sharedEleData[selectedIndex].parentName : '--'}
                  >
                    {sharedEleData[selectedIndex].parentName}
                  </span>
                </div>
                <div className="flex items-center h-8">
                  <span className="conflictBodySubHeader pr-2.5">Type :</span>
                  <span
                    className="conflictBodyText path-trimmer "
                    title={sharedEleData[selectedIndex].type ? sharedEleData[selectedIndex].type : '--'}
                  >
                    {sharedEleData[selectedIndex].type}
                  </span>
                </div>
              </div>
              <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={beforStart}>
                <div className="conflictBodyLocators w-full flex items-center">
                  <Droppable key="projectLocdragdrop" droppableId="projectLocdragdrop">
                    {(provided, snapshot) => (
                      <span
                        className="conflictBodyLocatorStyls overflow-y-auto"
                        id="journal-scroll"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <div className="conflictBodySubHeader">Locators</div>
                        {projecteleLoc?.map((loctor, index) => {
                          return <ProjLocatorComponent loctor={loctor} index={index} />;
                        })}
                      </span>
                    )}
                  </Droppable>
                  <span className={`locatorsMoveIcon flex items-center`}>
                    <svg
                      id="arrow_circle_right_black_24dp"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g id="Group_6218" data-name="Group 6218">
                        <rect id="Rectangle_1515" data-name="Rectangle 1515" width="24" height="24" fill="none" />
                      </g>
                      <g id="Group_6219" data-name="Group 6219">
                        <path
                          id="Path_514"
                          data-name="Path 514"
                          d="M22,12A10,10,0,1,0,12,22,10,10,0,0,0,22,12ZM12,13H8V11h4V8l4,4-4,4Z"
                          fill="#3a62c9"
                        />
                      </g>
                    </svg>
                  </span>
                  <Droppable key="sharedLocdragdrop" droppableId="sharedLocdragdrop">
                    {(provided, snapshot) => (
                      <span
                        className={`conflictBodyLocatorStyls overflow-y-auto`}
                        id="journal-scroll"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <div className="conflictBodySubHeader">Locators</div>
                        {sharedEleLoc?.map((loctor, index) => {
                          return (
                            <SharedLocatorComponent
                              conflictedprojElements={props.conflictedprojElements}
                              loctor={loctor}
                              index={index}
                            />
                          );
                        })}
                      </span>
                    )}
                  </Droppable>
                </div>
                <div className="flex items-center">
                  <span className="errorMessage ml-auto">{maxDragError}</span>
                </div>
              </DragDropContext>
            </div>
            <div className="conflicyBodyFooter flex items-center">
              <span className="ml-auto">
                <button
                  type="button"
                  className="primary-btn mr-4"
                  disabled={projecteleLoc.length !== 0}
                  onClick={() => {
                    clearMergeConflictReq();
                    props.getAllElemtsReq(props.projectDetails?.type, props.platForm);
                    props.closeModal(false);
                  }}
                >
                  <span className="create-label-common create-label-layout-style-project fontPoppinsMediumMd">
                    Save Changes
                  </span>
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElementConflictPage;

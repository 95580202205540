import React from 'react';
import { Route, Switch } from 'react-router';
import GlobalVariables from '../../../test-development/shared/variables/global-variables';
import ProjectEnvironmentVariables from '../../../test-development/shared/variables/project-environment-variables';
import StepGroupVariable from './step_group_steps/step_group_variable';

function VariableRouter(props) {
  const { history } = props;
  let historyLocation = history.location.pathname;
  let path;
  let index = historyLocation.lastIndexOf('/');
  path = historyLocation.slice(0, index);
  return (
    <Switch>
      <Route path={`${path}/Step Group Variables`} exact={true} strict={true} key="step-group-variable-page">
        <StepGroupVariable MyAlert={props.MyAlert} />
      </Route>
      <Route path={`${path}/Global Variables`} exact={true} strict={true} key="global-variable-page">
        <GlobalVariables {...props} MyAlert={props.MyAlert} />
      </Route>
      <Route
        path={`${path}/Project Environment Variables`}
        exact={true}
        strict={true}
        key="project-environment-variable-page"
      >
        <ProjectEnvironmentVariables {...props} MyAlert={props.MyAlert} />
      </Route>
    </Switch>
  );
}

export default VariableRouter;

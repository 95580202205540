import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { Checkbox } from '@mui/material';
import { getLicenseFeatures, purchasedFeatureTypeOfAutomation } from '@src/util/common_utils';
import React, { Fragment, useEffect, useState } from 'react';

const DeleteScript = (props) => {
  const [open, setOpenModal] = useState(true);
  const [selectedOption, setSelectedOption] = useState({ automationScript: false, manualScript: false });
  const [disableCheckbox, setDisableCheckbox] = useState({ auto: false, manual: false });
  const licenseFeatures = getLicenseFeatures();
  const projectDetails = JSON.parse(localStorage.getItem('selected-project'));
  const hasManualTestCase = window.privilege.hasManualTestCase(licenseFeatures);
  const hasAutomation = window.privilege.hasAutomation(licenseFeatures);
  const hasCBasic = window.privilege.hasCBasic(licenseFeatures);
  const hasDefects = window.privilege.hasDefects(licenseFeatures);
  const hasAutomationBasedOnProjType = purchasedFeatureTypeOfAutomation(projectDetails.type);
  const handleChange = (type, value) => {
    const updatedSelectedValues = { ...selectedOption };
    if (type === 'auto') {
      updatedSelectedValues.automationScript = value;
    } else {
      updatedSelectedValues.manualScript = value;
    }
    setSelectedOption({ ...updatedSelectedValues });
  };
  const handleDelete = () => {
    props.method(selectedOption);
    setOpenModal(false);
    props.closeModal(false);
  };

  useEffect(() => {
    if ((hasManualTestCase && !hasAutomation && !hasDefects) || (hasManualTestCase && hasDefects && !hasAutomation)) {
      handleChange('manual', true);
    } else if (
      (hasAutomation && !hasManualTestCase && !hasDefects && !hasCBasic) ||
      (hasAutomation && !hasManualTestCase && hasDefects && !hasCBasic)
    ) {
      handleChange('auto', true);
    }
  }, []);

  useEffect(() => {
    const testCaseData = [...props.nodeToDel.data.testCaseType];
    if (testCaseData.length === 2) {
      setDisableCheckbox({ auto: false, manual: false });
    } else if (testCaseData[0].type === 'Automation') {
      setDisableCheckbox({ auto: false, manual: true });
    } else {
      setDisableCheckbox({ auto: true, manual: false });
    }
  }, []);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={open}
        onClose={() => {
          props.closeModal(false);
          setOpenModal(false);
        }}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block marginupper pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/4">
              <div className="inline-block">
                <div className="flex flex-start ml-2">
                  <span className="pt-5 pl-3">
                    <ExclamationIcon className="h-6 w-6 text-yellow-600 " />
                  </span>
                  <Dialog.Title as="h3" className="text-sm leading-6 ml-4 pt-3 font-small text-gray-900">
                    <span className="fontPoppinsSemiboldMd block">Warning </span>
                    <span className="fontPoppinsRegularMd ">Delete </span>
                  </Dialog.Title>
                </div>
              </div>
              <hr className="line mt-px" />
              <div className="text-sm font-sans mt-3 text-gray-500 ml-16 mr-8">
                <p className="fontPoppinsRegularMd">
                  {' '}
                  Your data will be lost. Are you sure you want to delete {props.message} ?{' '}
                </p>
                <p className="mt-2 fontPoppinsRegularMd"> How do you want to proceed?</p>
                {((hasAutomation && hasAutomationBasedOnProjType && hasManualTestCase) || hasCBasic) && (
                  <p className="mt-2">
                    <Checkbox
                      name="deleteAutomationScript"
                      onChange={(e) => {
                        handleChange('auto', e.target.checked);
                      }}
                      checked={selectedOption.automationScript}
                      disabled={disableCheckbox.auto}
                      sx={{ padding: 0 }}
                    />
                    <span className="mt-4 ml-2 fontPoppinsRegularMd">Automation Scripts</span>
                  </p>
                )}
                {((hasManualTestCase && hasAutomation) || hasCBasic) && (
                  <p className="mt-2">
                    <Checkbox
                      name="deleteManualScript"
                      disabled={disableCheckbox.manual}
                      onChange={(e) => {
                        handleChange('manual', e.target.checked);
                      }}
                      checked={selectedOption.manualScript}
                      sx={{ padding: 0 }}
                    />
                    <span className="mt-4 ml-2 fontPoppinsRegularMd">Manual Test Case</span>
                  </p>
                )}
              </div>
              <hr className="line mt-3" />
              <div className="mt-2 pb-2 flex flex-row float-right">
                <button
                  disabled={!(selectedOption.automationScript || selectedOption.manualScript)}
                  type="button"
                  className="mt-3 gray-btn ml-1"
                  onClick={handleDelete}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="mt-3 ml-4 primary-btn "
                  onClick={() => {
                    props.closeModal(false);
                    setOpenModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DeleteScript;

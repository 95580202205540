import React from 'react';
import { withRouter } from 'react-router';
import TestDataRouter from './testdatarouter';
function TestDataLandingPage() {
  return (
    <div className="page-container">
      <TestDataRouter />
    </div>
  );
}

export default withRouter(TestDataLandingPage);

import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import Styles from './multi-select-checkmarks.module.scss';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@src/css_config/colorConstants';
import { isEmptyValue } from '@src/util/common_utils';

const useStyles = makeStyles((theme) => ({
  root: {
    '&.MuiMenuItem-root': {
      padding: '0 !important',
      '&:hover': {
        backgroundColor: `${colors.rs_primary} !important`,
        color: 'white !important',
      },
      '&:focus': {
        backgroundColor: `none !important`,
      },
    },
    '&.Mui-selected': {
      backgroundColor: `${colors.rs_primary} !important`,
      color: 'white !important',
    },
  },
  check: {
    '&.MuiCheckbox-root': {
      padding: '4px 7px !important',
      '&:hover': {
        color: 'white !important',
      },
    },
    '&.Mui-checked': {
      color: 'white !important',
    },
  },
}));

const MultipleSelectCheckmarks = (props) => {
  const classes = useStyles();
  const { placeholder, options, value, onChange, width, trimName } = props;

  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: width || 170,
        padding: '16px 0px',
        marginTop: 5,
      },
    },
  };

  return (
    <>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        displayEmpty
        value={value}
        onChange={(event) => onChange(event)}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <span className="fontPoppinsRegularSm">{placeholder || 'Select'}</span>;
          }
          return <span className="fontPoppinsRegularSm">{selected.length + ' Selected'}</span>;
        }}
        MenuProps={MenuProps}
        className={Styles['multi-checkmarker-warpper']}
      >
        {options.map((name) => (
          <MenuItem key={name} value={name} className={classes.root} id="test-one-one">
            <Checkbox checked={value.indexOf(name) > -1} className={classes.check} />
            <ListItemText
              primary={
                <span
                  title={typeof trimName === 'function' ? trimName(name, 'path') : name}
                  className="fontPoppinsRegularSm overflow-hidden overflow-ellipsis whitespace-nowrap pr-2 pt-1 inline-block w-full "
                >
                  {typeof trimName === 'function' ? trimName(name) : name}{' '}
                </span>
              }
            />
          </MenuItem>
        ))}

        {isEmptyValue(options) && (
          <ListItemText
            disabled
            primary={<span className="fontPoppinsRegularSm py-1 mx-4 pointer-events-none">No Options</span>}
          />
        )}
      </Select>
    </>
  );
};

MultipleSelectCheckmarks.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.number,
  trimName: PropTypes.func, // NOTE :: to trim name to display in UI eg: moduleName
};

export default MultipleSelectCheckmarks;

function checkStepIdExists(id, data) {
  let returnValue = false;
  for (let i = 0; i < data.length; i++) {
    if (data[i].stepId === id || (data[i]?.stepResults?.length && checkStepIdExists(id, data[i].stepResults))) {
      returnValue = true;
      break;
    }
  }
  return returnValue;
}
export function getFailedSteps(stepsArray, stepId) {
  let failedStepsArray = [];
  for (const step of stepsArray) {
    failedStepsArray.push({
      name: step?.name,
    });
    if (step?.stepId === stepId || (step?.stepResults?.length && checkStepIdExists(stepId, step?.stepResults))) {
      break;
    }
  }
  return failedStepsArray;
}

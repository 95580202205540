export const androidLocators = [
  {
    label: 'id',
    value: 'id',
  },
  {
    label: 'className',
    value: 'className',
  },
  {
    label: 'accessibilityId',
    value: 'accessibilityId',
  },
  {
    label: 'xpath',
    value: 'xpath',
  },
  {
    label: 'androidUiAutomator',
    value: 'androidUiAutomator',
  },
];

export const iosLocators = [
  {
    label: 'Predicate String',
    value: 'iOSNsPredicateString',
  },
  {
    label: 'Class Chain',
    value: 'iOSClassChain',
  },
];

export const elementPlatformTypes = ['Android', 'iOS', 'Ios', 'MobileWeb', 'Mobile'];

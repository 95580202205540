import React, { useState } from 'react';
import * as yup from 'yup';
import '@pages/test-development/test-development.scss';
import { useHistory } from 'react-router-dom';
import { FormControl, InputLabel, MenuItem, Select, TextareaAutosize, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import TreeWithRadioButton from '@pages/common/table_tree/table-tree-with-radio-button';
import { createScript } from '@pages/test-development/script/modules/module/utils/script-create-api';
import MultiAutocomplete from '../labels-multiselect-dropdown';
import { isWebServiceRequired } from '@src/util/common_utils';
import { getScriptOptions } from '@pages/test-development/script/modules/module/utils/common-functions';
import { colors } from '@src/css_config/colorConstants.js';
import { AUTOMATION_SCRIPT_CONSTANT } from '@common/ui-constants';

const useStyles = makeStyles({
  select: {
    paddingBottom: 3,
    paddingTop: 10,
    borderRadius: 8,
    fontSize: '12px',
    '&:focus': {
      borderRadius: 12,
      background: 'none',
    },
  },
  menuPaper: {
    maxHeight: 200,
    fontSize: '12px',
  },
  menulist: {
    paddingTop: 10,
    paddingBottom: 0,
    fontSize: '12px',
    background: 'none',
    '& li': {
      fontSize: '12px',
      color: colors.grey_dark,
    },
    '& li:hover': {
      background: colors.sky_blue_dark,
      color: colors.blue_dark,
    },
    '& li.Mui-selected': {
      paddingTop: '5 !important',
      color: colors.blue_dark,
      background: colors.sky_blue_dark,
    },
    '& li.Mui-selected:hover': {
      background: colors.sky_blue_dark,
      color: colors.blue_dark,
    },
  },
});

const usePlaceholderStyles = makeStyles({
  placeholder: {
    color: colors.light_gray_100,
    fontSize: 12,
  },
});

const NewAutomationScript = (props) => {
  const [createCalled, setCreateCalled] = useState(false);
  const parentSpecClass = {
    overflowClass: 'w-11/12',
  };
  const classes = useStyles();
  let editData = {};
  if (props?.data) {
    editData = props.data;
  }
  let [executionOrder, setExecutionOrder] = useState(
    props.nodeToAdd ? (props.nodeToAdd === 0 ? 1 : genarateExecutionOrder(props.nodeToAdd)) : null
  );
  const [hierarchy, setHierarchy] = useState(
    props.nodeToAdd ? (props.nodeToAdd === 0 ? 1 : findHierarchy(props.nodeToAdd)) : 1
  );

  const [scriptTypeOptions] = useState(getScriptOptions());
  const [descCount, setdescCount] = useState(props.data ? editData.desc.length : 0);
  const [labelSelectedOptions, setLabelSelectedOptions] = useState([]);
  const [labelResponseObject, setLabelResponseObject] = useState([]);
  const projectLabelsObject = [];
  let [noOfRows, setNoOfRows] = useState(1);
  let history = useHistory();

  const [selectedParentNode, setSelectedParentNode] = useState(
    props?.parentNode ? (props?.data ? props.parentNode.parent : props.parentNode) : undefined
  );
  const [selectedModule, setSelectedModule] = useState(props.moduleNameForAdd ? props.moduleNameForAdd : 'root');

  function genarateExecutionOrder(node) {
    return node.data.lastExecutionOrder + 1;
  }
  function findHierarchy(node) {
    return node.data.hierarchy + 1;
  }
  const treeNodeSelected = ([data]) => {
    if (!data) {
      formikDetails.setFieldValue('parentModule', '');
    } else if (data && data.data.key) {
      setSelectedParentNode(data.node);
      setExecutionOrder(genarateExecutionOrder(data.node));
      setHierarchy(findHierarchy(data.node));
      setSelectedModule(data.data);
      formikDetails.setFieldValue('parentModule', data.data.key);
    }
  };

  let initialValues;
  if (props?.data) {
    initialValues = {
      scriptName: editData?.name,
      description: editData?.desc,
      type: editData?.scriptType,
      parentModule: editData?.parentId,
    };
  } else {
    initialValues = {
      scriptName: '',
      type: '',
      description: '',
      parentModule: props.moduleIdForAdd ? props.moduleIdForAdd : '',
    };
  }

  const checkForDuplicates = (node, enteredScriptName, enteredScriptType) => {
    if (node?.children?.length) {
      for (const childNode of node.children) {
        if (
          childNode.title.toLowerCase().trim() === enteredScriptName.toLowerCase().trim() &&
          childNode.data?.scriptType?.toLowerCase() === enteredScriptType.toLowerCase() &&
          editData?.id !== (childNode._key ? childNode._key : childNode.key)
        ) {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  };

  labelSelectedOptions?.map((labelValue) => {
    labelResponseObject?.filter((objectValue) => {
      if (objectValue.name === labelValue) {
        projectLabelsObject.push(objectValue);
      }
    });
  });

  const onSubmit = async (values) => {
    let requestBody;
    if (values && !createCalled) {
      if (!checkForDuplicates(selectedParentNode, values.scriptName, values.type)) {
        setCreateCalled(true);
        requestBody = {
          name: values.scriptName,
          scriptType: values.type,
          desc: values.description,
          parentId: values.parentModule,
          parentName: props.moduleNameForAdd ? props.moduleNameForAdd : selectedModule.title,
          executionOrder: executionOrder,
          hierarchy: hierarchy,
          testCaseType: 'Automation',
          projectLabels: projectLabelsObject,
        };
        await createScript(requestBody, 'auto', history, props.MyAlert, null, 'newScript');
        props.closeModal(false);
      } else {
        props.MyAlert.info(`Script ${values.scriptName} with ${values.type} type already exists`);
        props.closeModal(false);
      }
    }
  };

  const validationSchema = yup.object({
    scriptName: yup
      .string()
      .trim('Space is not allowed at starting and at the end')
      .strict(true)
      .min(3, 'Name should contain at least 3 characters')
      .required('Name is required')
      .matches(/^[a-zA-Z0-9-_() ]*$/, 'Name should be alphanumeric'),
    description: yup.string(),
    type: yup.string().required('Type is required'),
    parentModule: yup.string().required('Parent Module is required'),
  });

  const formikDetails = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
  };

  const labelDataPassToParent = (labelData) => {
    setLabelSelectedOptions(labelData);
  };

  return (
    <form id="newScriptForm" onSubmit={formikDetails.handleSubmit}>
      <div className="mt-3 grid grid-cols-2 gap-4">
        <div className="w-4/5 p-1">
          <TextField
            error={formikDetails.errors.scriptName && formikDetails.touched.scriptName}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            autoComplete="off"
            className="mui-input-text-field"
            name="scriptName"
            id="scriptName"
            placeholder={AUTOMATION_SCRIPT_CONSTANT.SCRIPT_PLACEHOLDER}
            onBlur={formikDetails.handleBlur}
            onChange={formikDetails.handleChange}
            value={formikDetails.values.scriptName}
            label={
              <>
                {' '}
                <span className="text-red-500">*</span> Name
              </>
            }
          />
          {formikDetails.errors.scriptName && formikDetails.touched.scriptName ? (
            <div className="text-red-500 text-xs md:mt-1">{formikDetails.errors.scriptName}</div>
          ) : null}
        </div>

        <div className="w-4/5 float-right ml-auto p-1 script-type-field-test-dev">
          <FormControl className="w-full">
            <InputLabel shrink htmlFor="type">
              <span className="text-red-500">&#42;</span>{' '}
              <span className={formikDetails.errors.type && formikDetails.touched.type ? 'text-red-500' : null}>
                Type
              </span>
            </InputLabel>
            <div className=" mt-1.5">
              <Select
                classes={{ root: classes.select }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  classes: {
                    paper: classes.menuPaper,
                    list: classes.menulist,
                  },
                  getContentAnchorEl: null,
                }}
                error={formikDetails.errors.type && formikDetails.touched.type}
                name="type"
                fullWidth
                displayEmpty
                value={formikDetails.values.type}
                disabled={editData && editData.scriptType ? true : false}
                onBlur={formikDetails.handleBlur}
                onChange={formikDetails.handleChange}
                onSelect={formikDetails.handleChange}
                renderValue={
                  formikDetails.values.type !== '' ? undefined : () => <Placeholder>Select script type</Placeholder>
                }
              >
                {scriptTypeOptions.map((data, index) => {
                  return <MenuItem value={data.value}>{data.name}</MenuItem>;
                })}
              </Select>
            </div>
          </FormControl>
          {formikDetails.errors.type && formikDetails.touched.type ? (
            <div className="text-red-500 text-xs md:mt-1">{formikDetails.errors.type}</div>
          ) : null}
        </div>

        <div className="col-span-2">
          <label id="descriptionLabel" htmlFor="description" className="input-filed-label-style-common">
            Description
          </label>
          <TextareaAutosize
            maxRows={noOfRows}
            onBlur={(e) => {
              setNoOfRows(1);
              document.getElementById('descriptionLabel').style.color = '#727171';
              e.target.classList.add('descriptionStyle');
            }}
            onFocus={(e) => {
              setNoOfRows(null);
              document.getElementById('descriptionLabel').style.color = '#1648C6';
              e.target.classList.remove('descriptionStyle');
            }}
            id="description"
            name="description"
            maxLength="200"
            className="block w-full input-style-textarea popup-input-bg pl-px  mt-1  border-0 border-b input-field-color descriptionStyle"
            onChange={formikDetails.handleChange}
            onKeyUp={(e) => setdescCount(e.target.value.length)}
            value={formikDetails.values.description}
            placeholder={props.data ? '' : 'Your description goes here'}
          />
          <div className="mt-1 text-sm text-gray-500 text-right">{descCount}/200</div>
        </div>

        <div className="w-4/5 p-1 parent-module-field-test-dev">
          <label
            htmlFor="parentModule"
            className={
              formikDetails.errors.parentModule && formikDetails.touched.parentModule
                ? 'text-xs text-red-500'
                : 'input-filed-label-style-common'
            }
          >
            <span className="text-red-400">&#42;</span>Parent Module
          </label>
          <TreeWithRadioButton
            moduleSelection={true}
            hideRootRadioBtn={true}
            individualTree={props.individualTreeId ? true : false}
            disableRootRadioButton={true}
            hideElements={true}
            hideElementsBtn={true}
            data={props?.treeData ? props.treeData : []}
            operation={props.data ? 'edit' : props.moduleIdForAdd ? 'edit' : null}
            placeholder={
              props.data
                ? props.data.parentName
                : props.moduleIdForAdd
                ? props.moduleNameForAdd
                : AUTOMATION_SCRIPT_CONSTANT.RADIO_BUTTON_PLACEHOLDER
            }
            nodeSelected={treeNodeSelected.bind(this)}
            buttonLabel="Module"
            noDataMsg="No manual test cases are available"
            parentSpecificClass={parentSpecClass}
          />
          {formikDetails.errors.parentModule && formikDetails.touched.parentModule ? (
            <div className="text-red-500 text-xs md:mt-1">{formikDetails.errors.parentModule}</div>
          ) : null}
        </div>

        <div className="col-span-2 w-full">
          <MultiAutocomplete
            labelSelectedOptions={labelDataPassToParent}
            labelResponse={setLabelResponseObject}
            MyAlert={props.labelAlert}
          />
        </div>
      </div>
    </form>
  );
};

export default NewAutomationScript;

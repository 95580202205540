import React, { useMemo, useState } from 'react';
import { useTable } from 'react-table';
import RadioButton from '../../../../common/radio_button';
import { AiOutlineDelete } from 'react-icons/ai';
import UserMachineChildPage from './machine-child-pages/user-machine-child';
import { getUserData } from '@api/api_services';
import {
  getBrowserLabel,
  getOsLabel,
} from '@pages/configuration/system_config/system_config v1.1/utils/sys-config-common-methods';
import UserMachineDistribution from './machine-child-pages/user-machine-distribution';

const UserMachinePage = (props) => {
  let machineData = ['Single User', 'Multiple Users'];
  let singleUseroptions = ['Run this suite on single machine', 'Run this suite on multiple machine for compatibility'];
  let multipleUserOptions = [
    'Allow selected users to execute all the test cases',
    'Manually distribute scripts between machines',
  ];
  let userExecutionType = ['SINGLE_USER_SINGLE_MACHINE', 'SINGLE_USER_MULTIPLE_MACHINE'];
  let [selectedUserType, setSelectedUserType] = useState(
    props.runSettingsManualObject?.machines?.multiple === 'true' ? machineData[1] : machineData[0]
  );
  const [openModal, setOpenModal] = useState(false);
  const [userData, setUserData] = useState([]);
  const osDetails = osData();
  const [userTableContent, setUserTableContent] = useState([]);
  const browserDetails = browserData();
  const [userMachineList, setUserMachineList] = useState([]);
  const [displayOptions, setDisplayOptions] = useState(true);
  const [selectedOption, setSelectedOption] = useState(singleUseroptions[0]);
  const [enableAddButton, setEnableAddButton] = useState(false);
  const [allowSelect, setAllowSelect] = useState(true);
  const selectedProject = JSON.parse(localStorage.getItem('selected-project'));
  const [projectType, setProjectType] = useState(selectedProject ? selectedProject.type?.toLowerCase() : 'web');
  const [columnsToHide, setColumnsToHide] = useState([]);
  let deviceColumns = ['deviceInfo[0].version', 'deviceInfo[0].name'];
  let webColumns = ['browserName', 'browserVersion'];
  let distributionOptions = [multipleUserOptions[1]];
  const DISTRIBUTE = 'DISTRIBUTE';
  const MANUAL = 'MANUAL';

  const setMachineFun = (radioData) => {
    setEnableAddButton(false);
    setAllowSelect(true);
    if (radioData === machineData[0]) {
      setDisplayOptions(true);
      setAllowSelect(true);
      setSelectedOption(singleUseroptions[0]);
    } else {
      setDisplayOptions(false);
      setAllowSelect(true);
      setSelectedOption(multipleUserOptions[0]);
    }

    setSelectedUserType(radioData);
    resetUserMachineList(radioData, '');
  };

  const setOptions = (option) => {
    setSelectedOption(option);
    if (option === singleUseroptions[1]) {
      setEnableAddButton(true);
    } else {
      setEnableAddButton(false);
    }
    if (userMachineList.length) {
      setUserMachineList([]);
      setUserTableContent([]);
    }
    setAllowSelect(true);
    resetUserMachineList(selectedUserType, option);
  };

  const resetUserMachineList = (selectedUserData, selectedOptionData) => {
    const _machines = props?.runSettingsManualObject?.machines;
    if (userMachineList.length) {
      setUserMachineList([]);
      setUserTableContent([]);
      updateRunSetting([]);
    } else {
      if (_machines) {
        const tempMachinesList = _machines?.selectedMachines?.map((tempMachine) => ({
          ...tempMachine?.machineInstances?.[0],
        }));
        if (selectedUserData === machineData[1] && _machines.multiple === 'true') {
          if (_machines?.executionType === DISTRIBUTE) {
            setSelectedOption(multipleUserOptions[1]);
          } else {
            setSelectedOption(multipleUserOptions[0]);
            setTableContent(tempMachinesList, selectedUserData, selectedOptionData);
          }
          setUserMachineList(tempMachinesList);
          updateRunSetting(tempMachinesList);
        } else if (selectedUserData === machineData[0]) {
          if (_machines.multiple === 'false' && _machines?.userExecutionType) {
            if (_machines?.userExecutionType === userExecutionType[1]) {
              setSelectedOption(singleUseroptions[1]);
              setEnableAddButton(true);
            } else {
              setSelectedOption(singleUseroptions[0]);
              setEnableAddButton(false);
            }
            setUserMachineList(tempMachinesList);
            setTableContent(tempMachinesList, selectedUserData, selectedOptionData);
            updateRunSetting(tempMachinesList);
          } else {
            setUserMachineList([]);
            setUserTableContent([]);
            updateRunSetting([]);
          }
        }
      }
    }
  };

  const getUserDetails = async () => {
    try {
      const response = await getUserData();
      if (response.data.responseObject) {
        setUserData(response.data.responseObject);
      }
    } catch (error) {
      console.error('Exception in getUserDetails:', error);
    }
  };

  const handleSelecteduser = (userMachineData, distributionObject) => {
    let finalUserList = [];
    if (selectedUserType === machineData[0]) {
      if (userMachineData?.length) {
        if (selectedOption === singleUseroptions[0]) {
          let length = userMachineData.length;
          let latestData = userMachineData[length - 1];
          if (latestData) {
            finalUserList.push(latestData);
          }
        } else {
          userMachineData.forEach((machine) => {
            if (validateObject(machine)) {
              finalUserList.push(machine);
            }
          });
        }
      }
    } else {
      userMachineData?.forEach((machine) => {
        if (validateObject(machine)) {
          finalUserList.push(machine);
        }
      });
    }
    setUserMachineList(finalUserList);
    if (!distributionOptions.includes(selectedOption)) {
      setTableContent(finalUserList, selectedUserType, selectedOption);
      setOpenModal(false);
    }
    updateRunSetting(finalUserList, distributionObject);
  };

  const updateRunSetting = (finalUserList, distributionObject) => {
    let machineObj = {};
    if (selectedUserType === machineData[1]) {
      machineObj['multiple'] = true;
    } else {
      machineObj['multiple'] = false;
    }
    if (selectedOption) {
      if (selectedOption === singleUseroptions[0]) {
        machineObj['userExecutionType'] = userExecutionType[0];
      } else {
        machineObj['userExecutionType'] = userExecutionType[1];
      }
    }
    if (distributionOptions.includes(selectedOption)) {
      machineObj['executionType'] = DISTRIBUTE;
      machineObj['distributionMechanism'] = MANUAL;
      machineObj['distribution'] = distributionObject;
    }
    props.handleManualUserData(finalUserList, machineObj);
  };

  const validateObject = (machineData) => {
    if (machineData && machineData?.machineInfo?.hostName !== '') {
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    if (!projectType.toLowerCase().includes('web & mobile')) {
      if (projectType.toLowerCase().includes('mobile')) {
        columnsToHide.push('browserName', 'browserVersion');
      }
      if (projectType.toLowerCase().includes('web')) {
        columnsToHide.push('deviceInfo[0].version', 'deviceInfo[0].name');
      }
      setColumnsToHide([...columnsToHide]);
    }
    getUserDetails();
    updateUserMachineData();
  }, [props?.runSettingsManualObject]);

  const updateUserMachineData = () => {
    if (props.runSettingsManualObject?.machines) {
      let selectedMachinesCopy = [];
      if (props.runSettingsManualObject?.machines?.selectedMachines) {
        selectedMachinesCopy = JSON.parse(JSON.stringify(props.runSettingsManualObject.machines.selectedMachines));
        selectedMachinesCopy = selectedMachinesCopy?.map((tempMachine) => ({ ...tempMachine?.machineInstances?.[0] }));
      }
      if (props.runSettingsManualObject.machines.multiple === 'true') {
        setSelectedUserType(machineData[1]);
        setDisplayOptions(false);
        if (selectedMachinesCopy) {
          setUserMachineList(selectedMachinesCopy);
          if (props.runSettingsManualObject?.machines?.executionType === DISTRIBUTE) {
            setSelectedOption(multipleUserOptions[1]);
          } else {
            setSelectedOption(multipleUserOptions[0]);
            setTableContent(selectedMachinesCopy, selectedUserType, multipleUserOptions[0]);
          }
        }
      } else {
        setSelectedUserType(machineData[0]);
        setDisplayOptions(true);
        if (props.runSettingsManualObject?.machines?.userExecutionType) {
          if (props.runSettingsManualObject?.machines?.userExecutionType === userExecutionType[0]) {
            setSelectedOption(singleUseroptions[0]);
            setEnableAddButton(false);
            if (selectedMachinesCopy) {
              setUserMachineList(selectedMachinesCopy);
              setTableContent(selectedMachinesCopy, selectedUserType, singleUseroptions[0]);
            }
          } else {
            setSelectedOption(singleUseroptions[1]);
            setEnableAddButton(true);
            if (selectedMachinesCopy) {
              setUserMachineList(selectedMachinesCopy);
              setTableContent(selectedMachinesCopy, selectedUserType, singleUseroptions[1]);
            }
          }
        }
      }
    }
  };

  const data = useMemo(() => {
    return userTableContent;
  }, [userTableContent]);

  const deleteMachineList = (index, userData) => {
    let tempUserData = [...userData];
    tempUserData.splice(index, 1);
    setTableContent(tempUserData, selectedUserType, selectedOption);
    setUserMachineList([...tempUserData]);
    if (!tempUserData || tempUserData.length === 0) {
      setAllowSelect(true);
    }
    updateRunSetting(tempUserData);
  };

  const handleSelectUserModal = () => {
    setOpenModal(true);
    localStorage.setItem('userMachineBackupData', JSON.stringify(userMachineList));
  };

  const replaceHandleSelectUserModal = (userData) => {
    setOpenModal(true);
    localStorage.setItem('userMachineBackupData', JSON.stringify(userData));
  };

  const columns = useMemo(
    () => [
      {
        Header: 'User',
        accessor: 'clientId',
        Cell: ({ value }) => {
          return (
            <div className=" user-machine-max-width-style" title={value}>
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Client Machine',
        accessor: 'machineInfo.hostName',
        Cell: ({ value }) => {
          return (
            <div className=" user-machine-max-width-style" title={value}>
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Execution Environment',
        accessor: 'executionEnv',
        Cell: ({ value }) => {
          return (
            <div className=" user-machine-max-width-style" title={value}>
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Os',
        accessor: 'machineInfo.osName',
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common">{value}</div>;
        },
      },
      {
        Header: 'Os Version',
        accessor: 'machineInfo.osVersion',
        isVisible: true,
        Cell: ({ value }) => {
          return (
            <div className=" user-machine-max-width-style" title={value}>
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Browser',
        accessor: 'browserName',
        isVisible: projectType.toLowerCase().includes('web') ? true : false,
        Cell: ({ value }) => {
          return <div className=" table-non-link-color-common">{value}</div>;
        },
      },
      {
        Header: 'Browser Version',
        accessor: 'browserVersion',
        isVisible: projectType.toLowerCase().includes('web') ? true : false,
        Cell: ({ value }) => {
          return (
            <div className=" user-machine-max-width-style" title={value}>
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Device Name',
        accessor: 'deviceInfo[0].name',
        Cell: ({ value }) => {
          return (
            <div className=" user-machine-max-width-style" title={value}>
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Device Version',
        accessor: 'deviceInfo[0].version',
        Cell: ({ value }) => {
          return (
            <div className=" user-machine-max-width-style" title={value}>
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Action',
        accessor: 'action',
        isVisible: true,
        Cell: ({ value }) => {
          return <div className=" table-non-link-color-common">{value}</div>;
        },
      },
    ],
    []
  );

  const handleModal = (modalStatus) => {
    setOpenModal(modalStatus);
  };

  const setTableContent = (userMachineData, userType, userOption) => {
    let tablebody = [];
    userMachineData.map((data, index) => {
      let tempData = { ...data };
      if (userType === machineData[0] && userOption === singleUseroptions[0]) {
        tempData['action'] = [
          <span
            className={
              props?.rerunMode ? 'disable_icon flex flex-row float-left' : 'cursor-hand flex flex-row float-left'
            }
            onClick={() => {
              setOpenModal(true);
            }}
          >
            <img
              alt="replace"
              src="\assets\execution_logos\replace.svg"
              onClick={() => {
                replaceHandleSelectUserModal([tempData]);
              }}
            ></img>
          </span>,
        ];
      } else {
        tempData['action'] = [
          <span
            className={
              props?.rerunMode ? 'disable_icon flex flex-row float-left' : 'cursor-hand flex flex-row float-left'
            }
          >
            <AiOutlineDelete
              className="float-left cursor-pointer mr-3 text-blue-700 text-base"
              onClick={() => {
                deleteMachineList(index, userMachineData);
              }}
            />
          </span>,
        ];
      }
      tablebody.push(tempData);
    });
    setUserTableContent([...tablebody]);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    initialState: {
      hiddenColumns: projectType != 'web & mobile' ? (projectType.includes('mobile') ? webColumns : deviceColumns) : [],
    },
  });

  const userMachineTable = () => {
    return (
      <div className="module-area-style">
        <div className="content_area_header_style">
          <div>
            <label className="main-header-label float-left ">Test Environments</label>
          </div>
          <div className="flex flex-row float-right">
            <button
              className="secondary-btn left-border-style w-52"
              onClick={() => {
                handleSelectUserModal();
              }}
              disabled={props?.rerunMode}
            >
              + Select User
            </button>
          </div>
        </div>
        <div className="table_height " id="journal-scroll">
          <table class="relative" {...getTableProps()}>
            <thead>
              {headerGroups?.map((headerGroup) => (
                <tr class="mt-4" {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers?.map((column) => (
                    <th
                      class="sticky top-0 px-4 py-3 text-current table-header_font-style bg-gray-100 "
                      {...column.getHeaderProps()}
                    >
                      <span className="flex flex-row">{column.render('Header')}</span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows?.map((row, i) => {
                prepareRow(row);
                return (
                  <tr className="project-row" {...row.getRowProps()}>
                    {row.cells?.map((cell) => {
                      return (
                        <td
                          class="px-4 py-0.5 text-left font-style-common table-non-link-color-common"
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className="device-section">
      <div className="left-border-style">
        <RadioButton
          radioList={machineData}
          editData={selectedUserType}
          radioName="userMachine"
          func={setMachineFun}
          rerunMode={props?.rerunMode}
        />
      </div>
      {displayOptions ? (
        <div className="left-border-style">
          <label className="mt-2 mb-2 select-exe-type-label-style">Select Execution Type</label>
          <RadioButton
            radioList={singleUseroptions}
            editData={selectedOption}
            radioName="useroptions"
            func={setOptions}
            rerunMode={props?.rerunMode}
          />
        </div>
      ) : (
        <div className="left-border-style">
          <label className="mt-2 mb-2 select-exe-type-label-style">Select Execution Type</label>
          <RadioButton
            radioList={multipleUserOptions}
            editData={selectedOption}
            radioName="userMultiOptions"
            func={setOptions}
            rerunMode={props?.rerunMode}
          />
        </div>
      )}
      <div className="p-3">
        {distributionOptions.includes(selectedOption) ? (
          <div className="module-area-style">
            <UserMachineDistribution
              rerunMode={props?.rerunMode}
              moduleArray={props?.moduleArray || []}
              userData={userData}
              selectedUserType={machineData[0]}
              handleSelecteduser={handleSelecteduser}
              userMachineList={userMachineList}
              osDetails={osDetails}
              browserDetails={browserDetails}
              enableAddButton={enableAddButton}
              selectedProject={selectedProject}
              openModal={openModal}
              handleModal={handleModal}
              selectedOption={singleUseroptions[0]}
              runSettingsManualObject={props?.runSettingsManualObject}
            />
          </div>
        ) : userMachineList && userMachineList.length ? (
          <div> {userMachineTable()}</div>
        ) : allowSelect ? (
          <button
            className="secondary-btn left-border-style "
            onClick={() => {
              setOpenModal(true);
            }}
          >
            Select User
          </button>
        ) : (
          ''
        )}
      </div>
      <div>
        {selectedUserType && openModal ? (
          <UserMachineChildPage
            userData={userData}
            selectedUserType={selectedUserType}
            handleSelecteduser={handleSelecteduser}
            userMachineList={userMachineList}
            osDetails={osDetails}
            browserDetails={browserDetails}
            enableAddButton={enableAddButton}
            selectedProject={selectedProject}
            openModal={openModal}
            handleModal={handleModal}
            selectedOption={selectedOption}
          ></UserMachineChildPage>
        ) : (
          <div> </div>
        )}
      </div>
    </div>
  );
};

export const osData = () => {
  const osData = ['windows', 'mac', 'linux', 'android'];
  const osDetails = [];
  osData.map((os) => {
    let map = {};
    map = {
      name: os,
      value: os,
      data: os,
      label: getOsLabel(os),
    };
    osDetails.push(map);
  });
  return osDetails;
};

export const browserData = () => {
  const browserData = ['opera', 'chrome', 'ie', 'firefox', 'safari', 'edge'];
  const browserDetails = [];
  browserData.map((browser) => {
    let map = {};
    map = {
      name: browser,
      value: browser,
      data: browser,
      label: getBrowserLabel(browser),
    };
    browserDetails.push(map);
  });
  return browserDetails;
};

export default UserMachinePage;

import React, { useMemo, useEffect, useState } from 'react';
import { Drawer, Box } from '@material-ui/core';
import styles from '@src/pages/licenses/components/licensedetails.module.scss';
import { ReactComponent as CloseIcon } from '@assets/analytics/ShrinkIconWhite.svg';
import TextField from '@mui/material/TextField';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import LabeledInput from '@pages/common/Inputs/LabeledInput';
import { useFormik } from 'formik';
import { colors } from '@src/css_config/colorConstants';
import { isEmptyObject } from '@src/util/common_utils';
import { putMemoryConfigReq } from '@api/api_services';

import { MEMORYCONFIG_CONSTANTS } from '@src/common/ui-constants';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    marginTop: '15px',
  },
  optionMenu: {
    marginTop: '42px',
  },
}));

const DistributeMemory = (props) => {
  const ProjectName = props?.selectedProject?.name;
  const classes = useStyles();
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);

  const usedMemoryNum = props?.selectedProject?.usedMemory?.toString().split(' ')[0];
  const usedData = props?.selectedProject?.usedMemory?.toString().split(' ')[1];
  const assignedMemoryNum = props?.selectedProject?.assignedMemory?.toString().split(' ')[0];
  const assignedData = props?.selectedProject?.assignedMemory?.toString().split(' ')[1];

  let initialValues = {
    assignedNum: '',
    assignedData: '',
  };
  const validationSchema = Yup.object().shape({
    assignedNum: Yup.number().positive(MEMORYCONFIG_CONSTANTS.ASSIGNED_MEMORY_CANNOT),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
  });

  useEffect(() => {
    formik.setValues({
      id: props?.selectedProject?.id,
      assignedNum: assignedMemoryNum,
      assignedData: assignedData,
    });
  }, [props?.selectedProject]);

  const assigned_Memory = `${formik.values.assignedNum} ${formik.values.assignedData}`;
  const JsonMemory = JSON.stringify(assigned_Memory) === JSON.stringify(props?.selectedProject?.assignedMemory);
  useEffect(() => {
    if (!isEmptyObject(formik.errors) || JsonMemory) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [JsonMemory, formik.errors]);

  const saveData = async () => {
    try {
      const payload = [
        {
          id: formik.values.id,
          assignedMemory: assigned_Memory,
          consumedMemory: props?.selectedProject?.usedMemory,
        },
      ];

      const putData = await putMemoryConfigReq(payload);
      let responseObject = putData?.data;
      if (responseObject?.status === 'SUCCESS') {
        props.onClose();
        props.getVariables();
        props.MyAlert.success(`${responseObject?.message}`);
      } else if (responseObject?.status === 'FAILURE') {
        if (responseObject?.message?.includes('used')) {
          formik.setFieldError('assignedNum', MEMORYCONFIG_CONSTANTS.USED_MEMORY_VALIDATION);
        } else {
          formik.setFieldError('assignedNum', MEMORYCONFIG_CONSTANTS.AVAILABLE_MEMORY_VALIDATION);
        }
      }
    } catch (err) {
      console.error('putData', err);
    }
  };
  const handleFieldValue = (e) => {
    const newValue = e.target.value;
    if (newValue === assignedMemoryNum) {
      setButtonDisabled(true);
    } else if (newValue === '') {
      setButtonDisabled(true);
      formik.setFieldValue('assignedNum', '');
    } else {
      formik.setFieldValue('assignedNum', Number(e.target.value));
    }
  };

  return (
    <Drawer anchor="right" open={props.isOpen} onClose={props.onClose} className="memory-dialog-container">
      <Box
        sx={{
          width: 450,
          overflowX: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        role="presentation"
      >
        <section className="w-full  flex flex-col " style={{ height: '400px' }}>
          <div className={styles.header}>
            <div className="fontPoppinsMediumMd">{MEMORYCONFIG_CONSTANTS.DISTRIBUTE_MEMORY}</div>
            <div onClick={props.onClose} className={styles.closeIcon}>
              <CloseIcon />
            </div>
          </div>
          <div className=" justify-center items-center">
            <div className="w-full flex flex-col mt-4  ml-4">
              <div className="distribute-text fontPoppinsMediumSm">{MEMORYCONFIG_CONSTANTS.PROJECT_NAME}</div>
              <div className="mt-2">
                <TextField
                  className="distribute-input-field distribute-width"
                  variant="outlined"
                  name="name"
                  value={props?.selectedProject?.name}
                  disabled={isDisabled}
                  style={{ background: colors.rs_disabled_color }}
                />
              </div>
            </div>
            <div className="w-full flex flex-col mt-4 ml-4">
              <div className="distribute-text fontPoppinsMediumSm">{MEMORYCONFIG_CONSTANTS.TYPE}</div>
              <div className="mt-2">
                <TextField
                  className="distribute-input-field distribute-width"
                  variant="outlined"
                  name="type"
                  value={props?.selectedProject?.type}
                  disabled={isDisabled}
                  style={{ background: colors.rs_disabled_color }}
                />
              </div>
            </div>
            <div className="w-full flex flex-col mt-4 ml-4">
              <div className="distribute-text fontPoppinsMediumSm">{MEMORYCONFIG_CONSTANTS.USED_MEMORY}</div>
              <div className="flex flex-row mt-2 combined-field">
                <div>
                  <TextField
                    className="distribute-input-field disabledField distribute-memory-width"
                    autoComplete="off"
                    value={usedMemoryNum}
                    disabled={isDisabled}
                    variant="outlined"
                  />
                </div>
                <div>
                  <TextField
                    className="distribute-input-field Select-component disabledField"
                    autoComplete="off"
                    value={usedData}
                    disabled={isDisabled}
                    variant="outlined"
                  >
                    <MenuItem value="KB">
                      <span className="fontPoppinsRegularMd ml-2">KB</span>
                    </MenuItem>
                    <MenuItem value="MB">
                      <span className="fontPoppinsRegularMd ml-2">MB</span>
                    </MenuItem>
                    <MenuItem value="GB">
                      <span className="fontPoppinsRegularMd ml-2">GB</span>
                    </MenuItem>
                  </TextField>
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col  ml-4 mt-4">
              <div className="distribute-text fontPoppinsMediumSm">{MEMORYCONFIG_CONSTANTS.ASSIGNED_MEMORY}</div>
              <div className="flex flex-row mt-2">
                <div>
                  <LabeledInput
                    InputProps={{
                      inputProps: {
                        step: '1',
                        min: 0,
                        className: 'default-input shadow-none',
                        cursor: 'none',
                      },
                      disableUnderline: true,
                    }}
                    type="number"
                    value={formik.values.assignedNum}
                    onChange={handleFieldValue}
                    name="assignedNum"
                    onBlur={formik.handleBlur}
                    style={{
                      width: '305px',
                      border: formik.errors.assignedNum ? `1px solid ${colors.rs_red}` : `1px solid ${colors.rs_grey}`,
                      borderTopLeftRadius: '5px',
                      borderBottomLeftRadius: '5px',
                      outline: 'none',
                    }}
                    variant="standard"
                  />
                </div>
                <div>
                  <Select
                    classes={{
                      root: classes.selectControl,
                      icon: classes.arrowDownIcon,
                    }}
                    displayEmpty
                    className={`Select-component ${formik.errors.assignedNum ? 'border-valid' : 'border-invalid'}`}
                    inputProps={{ 'aria-label': 'Without label' }}
                    variant="standard"
                    value={formik.values.assignedData}
                    name="assignedData"
                    onChange={(e) => {
                      setButtonDisabled(false);
                      formik.setFieldValue('assignedData', e.target.value);
                    }}
                    MenuProps={{
                      classes: {
                        paper: classes.optionMenu,
                      },
                    }}
                    disableUnderline
                  >
                    <MenuItem value="MB">
                      <span className="fontPoppinsRegularMd ml-2">MB</span>
                    </MenuItem>
                    <MenuItem value="GB">
                      <span className="fontPoppinsRegularMd ml-2">GB</span>
                    </MenuItem>
                  </Select>
                </div>
              </div>
              {formik.errors.assignedNum && (
                <div className="error_message fontPoppinsRegularXs8px ">{formik.errors.assignedNum}</div>
              )}
            </div>
          </div>
        </section>
        <div className="memory-drawer-footer flex mt-30 justify-end absolute bottom-0 right-0 w-full bg-white">
          <button className="cancel-info cursor-pointer fontMontserratMediumXs  mr-5 mt-4" onClick={props.onClose}>
            {MEMORYCONFIG_CONSTANTS.CANCEL}
          </button>
          <button
            className={` ${
              isButtonDisabled ? 'distribute-cancel' : 'distribute-update'
            } cursor-pointer fontMontserratMediumXs mr-5 mt-4`}
            disabled={isButtonDisabled}
            onClick={saveData}
          >
            {MEMORYCONFIG_CONSTANTS.UPDATE}
          </button>
        </div>
      </Box>
    </Drawer>
  );
};

export default DistributeMemory;

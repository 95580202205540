import React from 'react';
import { Tooltip } from '@material-ui/core';

import { getFormattedDate, getFormattedTextStatus, textColor } from '../../result';
import { getIDB } from '@src/util/localForage_idb_controller';
import { Icon } from 'fireflink-ui';
import { TOOLTIP_NAME, RESULT_INFO_PANEL } from '@src/common/ui-constants';

function ExecutionResultInfoPanel(props) {
  //temporary fix for file download
  let executionStatus = async () => await getIDB('execResult')();

  return (
    <div className="p-3">
      <div className="grid grid-cols-3 items-center">
        <div className="flex-1">
          <label className=" details-key-style-common">{RESULT_INFO_PANEL.EXECUTION_ID}</label>

          <Tooltip title={props.data.runId} placement="bottom-start">
            <div className="text-value truncate">{props.data.runId}</div>
          </Tooltip>
        </div>
        <div className="flex-1">
          <label className=" details-key-style-common">{RESULT_INFO_PANEL.STATUS}</label>
          {/* temporary fix for file download */}
          <Tooltip
            title={getFormattedTextStatus(
              executionStatus?.responseObject?.executionResponses[0]?.executionStatus === 'Terminated'
                ? 'Terminated'
                : props.data?.status
            )}
            placement="bottom-start"
          >
            <div
              className={`text-value truncate ${textColor(
                executionStatus?.responseObject?.executionResponses[0]?.executionStatus === 'Terminated'
                  ? 'Terminated'
                  : props.data?.status
              )}`}
            >
              {getFormattedTextStatus(
                executionStatus?.responseObject?.executionResponses[0]?.executionStatus === 'Terminated'
                  ? 'Terminated'
                  : props.data?.status
              )}
            </div>
          </Tooltip>
        </div>
        <div className="flex-1">
          {
            <>
              <label className=" details-key-style-common">{RESULT_INFO_PANEL.SUITE_NAME}</label>

              <Tooltip
                title={
                  props.data.moduleName && props.data.moduleName.toLowerCase() === 'root'
                    ? props.data.moduleName.concat(' Module')
                    : props.data.moduleName
                }
                placement="bottom-start"
              >
                <div className="text-value truncate">
                  {props.data.moduleName && props.data.moduleName.toLowerCase() === 'root'
                    ? props.data.moduleName.concat(' Module').replace(/%20/g, ' ')
                    : props.data.moduleName.replace(/%20/g, ' ')}
                </div>
              </Tooltip>
            </>
          }
        </div>
        <div className="flex-1 mt-10">
          <label className=" details-key-style-common">{RESULT_INFO_PANEL.TOTAL_MACHINES}</label>
          <Tooltip title={props.data.totalMachine} placement="bottom-start">
            <div className="text-value truncate">{props.data.totalMachine}</div>
          </Tooltip>
        </div>
        {props.data.duration ? (
          <div className="flex-1 mt-10">
            <div className="flex gap-1 cursor-pointer">
              <label className=" details-key-style-common">{RESULT_INFO_PANEL.DURATION}</label>
              <Tooltip
                title={props.type === 'Script' ? TOOLTIP_NAME.SCRIPT_TOOLTIP_NAME : TOOLTIP_NAME.MODULE_TOOLTIP_NAME}
                placement="top"
              >
                <span className="mt-1">
                  <Icon name="info_icon" width={14} height={14} />
                </span>
              </Tooltip>
            </div>
            <Tooltip title={props.data.duration} placement="bottom-start">
              <div className="text-value truncate">{props.data.duration}</div>
            </Tooltip>
          </div>
        ) : (
          ''
        )}
        <div className="flex-1 mt-10">
          <label className=" details-key-style-common">{RESULT_INFO_PANEL.EXECUTED_ON}</label>
          <Tooltip title={getFormattedDate(props.data.executedOn, 'full')} placement="bottom-start">
            <div className="text-value truncate h-4">
              {props.data.executedOn ? getFormattedDate(props.data.executedOn, 'full') : '--'}
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default ExecutionResultInfoPanel;

import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import React, { useState, Fragment, useContext, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { useAlert } from '../../../common/alert_service/useAlert';
import ExecutionDashboard from '../../execution-dashboard/execution-dashboard';
import HistoryTD from '../../shared/history/history';
import GlobalVariables from '../../shared/variables/global-variables';
import ProjectEnvironmentVariables from '../../shared/variables/project-environment-variables';
import SingleModuleTree from './module/single-module-tree';
import DownloadClientModal from '../../../configuration/system_config/system_config v1.1/modals/download_client_modal';
import { getUrlPath } from './module/utils/common-functions';
import {
  getLicenseFeatures,
  isEmptyValue,
  purchasedFeatureTypeOfAutomation,
  getCurrentProjectStatus,
} from '@src/util/common_utils';
import SettingsRouter from '../scripts/settings/settings-router';
import WebserviceWarningModal from '@pages/test-development/script/scripts/webservice-warning-modal';
import { DataContext } from '@pages/common/Layout/components/Navigation';
import UpdateLabelScripts from './module/updateLabelScriptsDrawer';
import { Tooltip } from 'fireflink-ui';
import { BULK_LABELING } from '@src/common/ui-constants';

const ModuleRouter = (props) => {
  const [downloadClientModal, setDownloadClientModal] = useState(false);
  const [activeWebserviceData, setActiveWebserviceData] = useState(false);
  const { setWebserviceData } = useContext(DataContext);
  const [clickedTabPath, setClickedPathTab] = useState(null);
  const [showWebserviceWarningModal, setShowWebserviceWarningModal] = useState(false);
  const { AlertContatiner, MyAlert } = useAlert();
  const { history, location } = props;
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const index = location.pathname.lastIndexOf('/');
  let path = location.pathname.slice(0, index);
  let count = path.split('/').length;
  const tab = location.pathname.split('/')[count];
  const parentAndSonJson = JSON.parse(localStorage.getItem('parentAndSonJson'));
  const urlPath = getUrlPath(parentAndSonJson);
  const id = parentAndSonJson[parentAndSonJson.length - 1].id;
  const licenseFeatures = getLicenseFeatures();
  const hasAutomation = window.privilege.hasAutomation(licenseFeatures);
  const project = JSON.parse(localStorage.getItem('selected-project'));
  const hasAutomationBasedOnProjType = purchasedFeatureTypeOfAutomation(project.type);
  const [moduleLevel, setModuleLevel] = useState('root');
  const [selectedScripts, setSelectedScripts] = useState([]);
  const [openUpdateLabel, setOpenUpdateLabel] = useState(false);
  const projectStatus = getCurrentProjectStatus();
  let activeTab;
  if (tab === 'Variables') {
    history.push(`/testdevelopment/Script/${urlPath}Variables/Global Variables?moduleId=${id}`);
  } else if (tab === 'Settings') {
    history.push(`/testdevelopment/Script/${urlPath}Settings/Pre Condition?moduleId=${id}`);
  }
  if (tab === 'Global Variables' || tab === 'Project Environment Variables') {
    activeTab = 'Variables';
    const name = location.pathname.slice(0, index);
    const indexUpdated = name.lastIndexOf('/');
    path = name.slice(0, indexUpdated);
    count = path.split('/').length;
  } else if (['Pre Condition', 'Post Condition', 'webservice-authorization'].includes(tab)) {
    activeTab = 'Settings';
    const name = location.pathname.slice(0, index);
    const indexUpdated = name.lastIndexOf('/');
    path = name.slice(0, indexUpdated);
    count = path.split('/').length;
  } else {
    activeTab = tab;
    path = location.pathname.slice(0, index);
    count = path.split('/').length;
  }
  const contextMenuData = [
    // {
    //     option: "Optimizer"
    // },
    // {
    //     option: "NLP Help"
    // },
    {
      option: BULK_LABELING.UPDATE_LABEL_FOR_SCRIPTS,
    },
    {
      option: 'Download Client',
    },
  ];
  const tabsList = [
    {
      name: 'Module',
      logo: '/assets/images/testdata_blue.svg',
      selectedLogo: '/assets/images/modules_blue.svg',
      pathUrl: `${path}/Module?moduleId=${moduleId}`,
      selectedName: 'Module',
    },
    {
      name: 'Variables',
      selectedLogo: '/assets/images/variables_blue.svg',
      pathUrl: `${path}/Variables/Global Variables?moduleId=${moduleId}`,
      selectedName: 'Variables',
    },
    {
      name: 'Settings',
      selectedLogo: '/assets/images/settings_blue.svg',
      pathUrl: `${path}/Settings/Pre Condition?moduleId=${moduleId}`,
      selectedName: 'Settings',
    },
    {
      name: 'Execution Dashboard',
      selectedLogo: '/assets/images/result_blue.svg',
      pathUrl: `${path}/Execution Dashboard?moduleId=${moduleId}`,
      selectedName: 'Execution Dashboard',
    },
    // {
    //     "name": "History",
    //     "selectedLogo": "/assets/images/history_logo_blue.svg",
    //     "pathUrl": `${path}/History?moduleId=${moduleId}`,
    //     "selectedName": "History"
    // }
  ];

  useEffect(() => {
    const getPathArray = path.split('/');
    const pathLevel = getPathArray[getPathArray.length - 1];
    if (pathLevel.includes('Root Module')) {
      setModuleLevel('root');
    } else {
      setModuleLevel('module');
    }
  }, [path]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  function closeModal(val) {
    setDownloadClientModal(val);
  }
  const optionMethodsHandler = (option) => {
    if (option === 'Download Client') {
      setDownloadClientModal(true);
    } else if (option === BULK_LABELING.UPDATE_LABEL_FOR_SCRIPTS && !isEmptyValue(selectedScripts)) {
      setOpenUpdateLabel(true);
    }
  };
  const contextMenuTabContent = [
    {
      name: 'Global Variables',
      path: `${path}/Variables/Global Variables?moduleId=${moduleId}`,
    },
    {
      name: 'Project Environment Variables',
      path: `${path}/Variables/Project Environment Variables?moduleId=${moduleId}`,
    },
  ];
  const contextMenuTab = (optionList) => {
    return (
      <div className="inline-block">
        <Menu as="div" className="relative text-left">
          {({ open }) => (
            <>
              <div>
                <Menu.Button>
                  <span className="sr-only">Open options</span>
                  <img
                    alt="arrow-dropdown"
                    src="/assets/images/arrow_drop_down_black.svg"
                    aria-hidden="true"
                    className="ml-1 -pb-px font-bold text-sm"
                  />
                </Menu.Button>
              </div>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none  z-10"
                >
                  <div className="py-1">
                    {optionList.map((data) => (
                      <Menu.Item>
                        {({ active }) => (
                          <label
                            onClick={() => {
                              handleTabClick(data.path, data.name);
                            }}
                            className={classNames(
                              active ? 'bg-gray-100 text-blue-600' : 'text-gray-600',
                              'block px-3 py-2 text-sm'
                            )}
                          >
                            {data.name}
                          </label>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    );
  };
  const contextMenuSettingsTabContent = [
    {
      name: 'Pre-Post Condition',
      path: `${path}/Settings/Pre Condition?moduleId=${moduleId}`,
    },
    {
      name: 'Web Service Authorization',
      path: `${path}/Settings/webservice-authorization?moduleId=${moduleId}`,
    },
  ];
  function UpdateLabelsModal() {
    setOpenUpdateLabel(false);
  }
  const getContextMenuData = () => {
    if (activeTab === 'Module') {
      return contextMenuData.filter(
        (item) => !(item.option === BULK_LABELING.UPDATE_LABEL_FOR_SCRIPTS && projectStatus === 'Closed')
      );
    }
    return contextMenuData.filter((item) => item.option !== BULK_LABELING.UPDATE_LABEL_FOR_SCRIPTS);
  };
  const renderTabs = () => {
    return (
      <div className="page-tab-with-actions">
        <ul className="navBar text-blue-600 flex flex-row bottom-space-sm -ml-2 repo-dev-tab-height">
          {tabsList.map((tab, index) => {
            if (tab.name === 'Module') {
              return (
                <span key={tab.selectedName}>
                  <span
                    className={
                      activeTab === tab.selectedName
                        ? 'flex flex-row p-2 pb-3 selected-repo-testdev-tab-style mr-4'
                        : 'flex flex-row p-2 pb-3 repo-testdev-tab-style mr-4'
                    }
                  >
                    <a
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        handleTabClick(tab.pathUrl, tab.selectedName);
                      }}
                      className={`${
                        activeTab === tab.selectedName
                          ? ''
                          : activeTab.toLowerCase().search('variables') !== -1 &&
                            tab.selectedName === 'global-variables'
                          ? ''
                          : ''
                      }`}
                    >
                      {tab.name}
                    </a>
                    {activeTab === tab.selectedName && tab.selectedName === 'Variables'
                      ? contextMenuTab(contextMenuTabContent)
                      : null}
                    {tab.selectedName === 'Settings' && activeTab === 'Settings'
                      ? contextMenuTab(contextMenuSettingsTabContent)
                      : null}
                  </span>
                  {activeTab === tab.selectedName ? (
                    <hr
                      className={
                        tab.selectedName === 'Variables'
                          ? 'bg-blue-700 ml-2 mr-7 selected-repo-tab-style -mt-5'
                          : 'bg-blue-700 ml-2 mr-5 selected-repo-tab-style -mt-3'
                      }
                    />
                  ) : null}
                </span>
              );
            } else if (hasAutomation && hasAutomationBasedOnProjType) {
              return (
                <span key={tab.selectedName}>
                  <span
                    className={
                      activeTab === tab.selectedName
                        ? 'flex flex-row p-2 pb-3 selected-repo-testdev-tab-style mr-4'
                        : 'flex flex-row p-2 pb-3 repo-testdev-tab-style mr-4'
                    }
                  >
                    <a
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        handleTabClick(tab.pathUrl, tab.selectedName);
                      }}
                      className={`${
                        activeTab === tab.selectedName
                          ? ''
                          : activeTab.toLowerCase().search('variables') !== -1 &&
                            tab.selectedName === 'global-variables'
                          ? ''
                          : ''
                      }`}
                    >
                      {tab.name}
                    </a>
                    {activeTab === tab.selectedName && tab.selectedName === 'Variables'
                      ? contextMenuTab(contextMenuTabContent)
                      : null}
                    {tab.selectedName === 'Settings' && activeTab === 'Settings'
                      ? contextMenuTab(contextMenuSettingsTabContent)
                      : null}
                  </span>
                  {activeTab === tab.selectedName ? (
                    <hr
                      className={
                        tab.selectedName === 'Variables'
                          ? 'bg-blue-700 ml-2 mr-7 selected-repo-tab-style -mt-5'
                          : 'bg-blue-700 ml-2 mr-5 selected-repo-tab-style -mt-3'
                      }
                    />
                  ) : null}
                </span>
              );
            }

            return null;
          })}
        </ul>
        {hasAutomation && hasAutomationBasedOnProjType && (
          <div className="clearfix ml-auto ">
            <Menu as="div" className="relative text-left">
              {({ open }) => (
                <>
                  <div className="ml-5 mr-1">
                    <Menu.Button>
                      <span className="sr-only">Open options</span>
                      <DotsVerticalIcon className="h-5 w-5 black" aria-hidden="true" />
                    </Menu.Button>
                  </div>

                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none  z-10"
                    >
                      <div className="py-1">
                        {getContextMenuData()?.map((data) => (
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                onClick={() => {
                                  optionMethodsHandler(data.option);
                                }}
                                className={classNames(
                                  data.option === BULK_LABELING.UPDATE_LABEL_FOR_SCRIPTS &&
                                    isEmptyValue(selectedScripts)
                                    ? 'text-gray-700 opacity-30 cursor-none'
                                    : active && 'bg-gray-100 text-rs-primary',
                                  'block px-4 py-2 text-sm fontPoppinsRegularMd'
                                )}
                              >
                                <label>
                                  <Tooltip
                                    title={
                                      data.option === BULK_LABELING.UPDATE_LABEL_FOR_SCRIPTS &&
                                      isEmptyValue(selectedScripts)
                                        ? BULK_LABELING.PLEASE_SELECT_SCRIPTS
                                        : ''
                                    }
                                  >
                                    {data.option}
                                  </Tooltip>
                                </label>
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        )}
      </div>
    );
  };
  const setWebserviceStateValue = (val) => {
    setWebserviceData(val);
    setActiveWebserviceData(val);
  };

  const handleTabClick = async (url = ' ', selectedTabName) => {
    if (activeWebserviceData && ['Settings'].includes(activeTab)) {
      setClickedPathTab(url);
      setShowWebserviceWarningModal(true);
    } else {
      setActiveWebserviceData(false);
      props.history.push(url);
    }
  };
  const handleWebServiceModal = async (action) => {
    if (action === 'exit') {
      await setWebserviceStateValue(false);
      setShowWebserviceWarningModal(false);
      props.history.push(clickedTabPath);
    } else {
      setShowWebserviceWarningModal(false);
      setClickedPathTab(null);
    }
  };
  return (
    <div className="page-list-body">
      {showWebserviceWarningModal && <WebserviceWarningModal handleWebserviceModal={handleWebServiceModal} />}
      <div className="alert-position-style">
        <AlertContatiner></AlertContatiner>
      </div>
      {renderTabs()}
      <Switch>
        <Route path={`${path}/Module`} exact={true} strict={true} key="module-page">
          <SingleModuleTree {...props} MyAlert={MyAlert} setSelectedScripts={setSelectedScripts} />
        </Route>
        <Route path={`${path}/Variables/Global Variables`} exact={true} strict={true} key="global-variables-page">
          <GlobalVariables {...props} MyAlert={MyAlert} />
        </Route>
        <Route
          path={`${path}/Variables/Project Environment Variables`}
          exact={true}
          strict={true}
          key="project-environment-variables"
        >
          <ProjectEnvironmentVariables {...props} MyAlert={MyAlert} />
        </Route>
        <Route path={`${path}/Settings/:w`} exact={true} strict={true} key="settings-router-page">
          <SettingsRouter
            showWebserviceModal={setWebserviceStateValue}
            {...props}
            MyAlert={MyAlert}
            label={moduleLevel}
          />
        </Route>
        <Route path={`${path}/Execution Dashboard`} exact={true} strict={true} key="module-execution-dashboard-page">
          <ExecutionDashboard {...props} MyAlert={MyAlert} />
        </Route>
        <Route path={`${path}/History`} exact={true} strict={true} key="module-history-page">
          <HistoryTD />
        </Route>
      </Switch>
      {openUpdateLabel === true && (
        <UpdateLabelScripts
          isOpen={true}
          onClose={UpdateLabelsModal}
          selectedScripts={selectedScripts}
          MyAlert={MyAlert}
        />
      )}
      {downloadClientModal && <DownloadClientModal closeModal={closeModal} />}
    </div>
  );
};

export default ModuleRouter;

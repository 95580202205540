import { Dialog, Transition } from '@headlessui/react';
import { InfoOutlined } from '@material-ui/icons';
import React, { Fragment, useState, useEffect } from 'react';

const InfoModal = (props) => {
  const [openModal, setOpenModal] = useState(false);

  const { headerTitle, subHeaderTitle, bodyText, subBodyText, onConfirm, closeModal, open } = props;
  const handleContinue = (e) => {
    onConfirm(e);
  };
  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  return (
    <Transition.Root show={openModal} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={openModal}
        onClose={() => {
          closeModal(false);
          setOpenModal(false);
        }}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block info-modal-margin pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/4">
              <div className="inline-block">
                <div className="flex flex-start ml-2">
                  <span className="pt-5 pl-3">
                    <InfoOutlined className="h-6 w-6 text-blue-500 " />
                  </span>
                  <Dialog.Title as="h3" className="text-sm leading-6 ml-4 pt-3 font-small text-gray-900">
                    <span className="fontPoppinsSemiboldLg text-sm block">{headerTitle ? headerTitle : ''}</span>
                    <span className="text-sm fontPoppinsRegularMd ">{subHeaderTitle ? subHeaderTitle : ''}</span>
                  </Dialog.Title>
                </div>
              </div>
              <hr className="line mt-px" />
              <div className="fontPoppinsRegularSm mt-3 text-gray-500 ml-16 mr-8">
                <p className="">{bodyText ? bodyText : ''}</p>
                <p className="mt-2"> {subBodyText ? subBodyText : ''}</p>
              </div>
              <hr className="line mt-3" />
              <div className="mt-2 pb-2 flex flex-row float-right">
                <button
                  type="button"
                  className="mt-3 ml-1 gray-btn"
                  onClick={() => {
                    closeModal(false);
                    setOpenModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="mt-3 ml-4 primary-btn"
                  onClick={(e) => {
                    handleContinue(e);
                    closeModal(false);
                    setOpenModal(false);
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default InfoModal;

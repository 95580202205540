import React, { Fragment, useState } from 'react';
import Executed_requests from './pages/executed_requests';
import Global_History from './pages/global_history';
import '@pages/test-development/script/scripts/webservice/restapi/request/assert/css/default_assert.scss';
import '@pages/test-development/script/scripts/webservice/restapi/css/common_Webservice.scss';
import { ASSERT_CONSTANT } from '@pages/test-development/script/scripts/webservice/restapi/request/assert/jsx/constant';
import '@pages/test-development/script/scripts/webservice/restapi/css/common_Webservice.scss';

const History = (props) => {
  const [selectedTabDefaultAssert, setSelectedTabDefaultAssert] = useState(ASSERT_CONSTANT.ENABLE_DEFAULT_ASSERT);
  const [selectedTabCustomAssert, setSelectedTabCustomAssert] = useState(ASSERT_CONSTANT.DISABLE_CUSTOM_ASSERT);
  const [activeDefaultAssertTab, setActiveDefaultAsserttab] = useState(ASSERT_CONSTANT.ACTIVE_DEFAULT_HISTORY);
  const [activeDefaultCustomTab, setActiveCustomAsserttab] = useState(ASSERT_CONSTANT.INACTIVE_CUSTOM);
  let [selectedTab, setSelectedTab] = useState('Executed Requests');

  const setDefaultAssert = () => {
    setSelectedTab('Executed Requests');
    setSelectedTabDefaultAssert(ASSERT_CONSTANT.ENABLE_DEFAULT_ASSERT);
    setSelectedTabCustomAssert(ASSERT_CONSTANT.DISABLE_CUSTOM_ASSERT);
    setActiveDefaultAsserttab(ASSERT_CONSTANT.ACTIVE_DEFAULT_HISTORY);
    setActiveCustomAsserttab(ASSERT_CONSTANT.INACTIVE_CUSTOM);
  };

  const setCustomAssert = () => {
    setSelectedTab('Global History');
    setSelectedTabDefaultAssert(ASSERT_CONSTANT.DISABLE_DEFAULT_ASSERT);
    setSelectedTabCustomAssert(ASSERT_CONSTANT.ENABLE_CUSTOM_ASSERT);
    setActiveDefaultAsserttab(ASSERT_CONSTANT.IN_ACTIVE_DEFAULT_HISTORY);
    setActiveCustomAsserttab(ASSERT_CONSTANT.ACTIVE_CUSTOM_HISTORY);
  };

  return (
    <Fragment>
      <div className="HeadersCss">
        <div className="">
          <div style={{ borderBottom: '1px', borderColor: '#525252' }}></div>
          <div className="bg-white">
            <div className="flex flex-wrap flex-row tab-links-color history-main-div-height">
              <button
                className={` history-width-47 ${activeDefaultAssertTab} ${ASSERT_CONSTANT.DEFAULT_ASSERT_BUTTON_HISTORY} fontPoppinsSemiboldSm`}
                onClick={setDefaultAssert}
              >
                <span className="history-tab-text-color">{ASSERT_CONSTANT.EXECUTED__REQUESTS}</span>
              </button>
              <button
                className={` history-width-47 ${activeDefaultCustomTab} ${ASSERT_CONSTANT.CUSTOM_ASSERT_BUTTON_HISTORY} fontPoppinsSemiboldSm`}
                onClick={setCustomAssert}
              >
                <span className="history-tab-text-color">{ASSERT_CONSTANT.GLOBAL_HISTORY}</span>
              </button>
            </div>
            <main>
              {selectedTab === 'Executed Requests' ? (
                <div
                  id={ASSERT_CONSTANT.DEFAULT_ASSERT_ID}
                  className={`${selectedTabDefaultAssert} ${ASSERT_CONSTANT.BORDER_CUSTOM}`}
                >
                  <Executed_requests MyAlert={props.MyAlert} />
                </div>
              ) : (
                <div
                  id={ASSERT_CONSTANT.CUSTOM_ASSERT_ID}
                  className={`${selectedTabCustomAssert} ${ASSERT_CONSTANT.BORDER_CUSTOM}`}
                >
                  <Global_History MyAlert={props.MyAlert} />
                </div>
              )}
            </main>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default History;

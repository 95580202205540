import React, { useState } from 'react';
import styles from '../analytics.module.scss';
import { ReactComponent as DropIconDownArrow } from '@assets/analytics/dropdown_arrow_down.svg';
import { ReactComponent as DropIconUpArrow } from '@assets/analytics/dropdown_arrow_up.svg';
import cx from 'classnames';
import Tooltip from '@mui/material/Tooltip';
import OutsideAlerter from '@pages/common/OutsideAlerter';
import { makeStyles } from '@material-ui/core/styles';
import { chartFontFamily } from '@common/ui-constants';

const useStyles = makeStyles((theme) => ({
  toolFont: {
    fontFamily: chartFontFamily,
  },
}));

const DropDown = ({ data, value, setValue, disableExecutionId, diableDropDownOnCompare }) => {
  const [showDropBox, setShowDropBox] = useState(false);
  let classes = useStyles();
  return (
    <section className={styles['dropBlock']}>
      <OutsideAlerter function={() => setShowDropBox(false)}>
        <Tooltip
          classes={{ tooltip: classes.toolFont }}
          title={
            diableDropDownOnCompare
              ? 'Please reset'
              : value == 'Select Suite'
                ? 'Please select Suite'
                : value == 'Select ExecutionId'
                  ? 'Please select ExecutionId'
                  : value
          }
          disableInteractive
          placement="top"
        >
          <div
            onClick={diableDropDownOnCompare ? null : () => setShowDropBox(!showDropBox)}
            className={cx(classes.toolFont, styles[diableDropDownOnCompare ? 'dropInputDisable' : 'dropInput'])}
          >
            <div
              className={cx(
                `fontPoppinsMediumXs ${value == 'Select Suite' || value == 'Select ExecutionId' ? 'rs-skipped' : 'rs-text-black'} `,
                styles['suiteName-trimmer']
              )}
            >
              {value}
            </div>
            {showDropBox ? (
              <div>
                <DropIconUpArrow />
              </div>
            ) : (
              <div>
                <DropIconDownArrow />
              </div>
            )}
          </div>
        </Tooltip>
        {showDropBox && (
          <div className={styles['dropBox']}>
            {data?.map((suite, index) => {
              return (
                <Tooltip
                  classes={{ tooltip: classes.toolFont }}
                  key={`${suite.name ? suite?.name : suite}-${index}`}
                  title={suite.name ? suite?.name : suite}
                  placement="left"
                  disableInteractive
                >
                  <div
                    className={cx(
                      classes.toolFont,
                      styles[disableExecutionId === suite ? 'disabled' : 'option'],
                      'fontPoppinsRegularXs'
                    )}
                    onClick={() => {
                      setValue(suite.name ? suite?.name : suite);
                      setShowDropBox(false);
                    }}
                  >
                    <span className={cx(styles['suiteName-trimmer'])}>{suite.name ? suite?.name : suite}</span>
                  </div>
                </Tooltip>
              );
            })}
          </div>
        )}
      </OutsideAlerter>
    </section>
  );
};

export default DropDown;

import JSEncrypt from 'jsencrypt';
import { REGEX } from '@src/util/validations';

let rsaPublickey = process.env.REACT_APP_RSA_PUBLIC_KEY;
let rsaPrivatekey = process.env.REACT_APP_RSA_PRIVATE_KEY;

// TODO :: use this function to genrate new key pairs
const generateKeys = () => {
  const jsEncrypt = new JSEncrypt({ default_key_size: 2048 });
  const pubilcKey = jsEncrypt.getPublicKey();
  const privateKey = jsEncrypt.getPrivateKey();
  // console.log('pubilcKey pem', pubilcKey); // Output: "-----BEGIN RSA PUBLIC KEY-----..."
  // console.log('privateKey pem', privateKey); // Output: "-----BEGIN RSA PRIVATE KEY
};
// generateKeys()

// TODO ::  use this function to encrypt data using RSA
const getEncryptData = (data, publicKey = rsaPublickey) => {
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  return encrypt.encrypt(data);
};

// TODO ::  use this function to encrypt data using RSA
const getDecryptData = (data, privateKey = rsaPrivatekey) => {
  const encrypt = new JSEncrypt();
  encrypt.setPrivateKey(privateKey);
  return encrypt.decrypt(data);
};

//TODO ::  encryption  and decryption using AES
// Function to convert the AES key from a string to an ArrayBuffer
const importKey = async () => {
  const encodedKey = new TextEncoder().encode(process.env.REACT_APP_ENCRYPT_DECRYPT_AES_KEY);
  return await crypto.subtle.importKey('raw', encodedKey, { name: 'AES-GCM' }, false, ['encrypt', 'decrypt']);
};

const decryptDataWithAES = async (SignInResponse) => {
  try {
    const encryptionKey = await importKey();
    const decryptedData = {};

    for (const [encryptedKey, value] of Object.entries(SignInResponse)) {
      // Decrypt the key
      const [ivKeyBase64, encryptedKeyBase64] = encryptedKey.split(':');
      const ivKey = new Uint8Array(Array.from(atob(ivKeyBase64), (c) => c.charCodeAt(0)));
      const encryptedKeyArray = new Uint8Array(Array.from(atob(encryptedKeyBase64), (c) => c.charCodeAt(0)));
      const decryptedKeyBuffer = await crypto.subtle.decrypt(
        { name: 'AES-GCM', iv: ivKey },
        encryptionKey,
        encryptedKeyArray
      );
      const decryptedKey = new TextDecoder().decode(decryptedKeyBuffer);

      // Decrypt the value
        const [ivBase64, ciphertextBase64] = value.split(':');
        const iv = new Uint8Array(Array.from(atob(ivBase64), (c) => c.charCodeAt(0)));
        const ciphertextArray = new Uint8Array(Array.from(atob(ciphertextBase64), (c) => c.charCodeAt(0)));
        const decryptedBuffer = await crypto.subtle.decrypt(
          { name: 'AES-GCM', iv: iv },
          encryptionKey,
          ciphertextArray
        );
        const decryptedText = new TextDecoder().decode(decryptedBuffer);
        if(decryptedText=='null'){
          decryptedData[decryptedKey] = null;  
        }
        else{
          decryptedData[decryptedKey] = decryptedText;
        }
    }

    return decryptedData;
  } catch (error) {
    console.error('Decryption error:', error);
    return null;
  }
};

const parseAndDecryptData = async (dataParam) => {
  try {
    const sanitizedDataParam = dataParam.replace(REGEX.REMOVE_PLUS, '%2B');
    const decodedData = decodeURIComponent(sanitizedDataParam.replace(REGEX.REPLACE_SPACE, '+'));
    const parsedData = JSON.parse(decodedData);
    return await decryptDataWithAES(parsedData);
  } catch (error) {
    console.error('Failed to parse and decrypt data parameter:', error);
    return null;
  }
};

export { parseAndDecryptData, getEncryptData, getDecryptData };

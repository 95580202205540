import DashboardStyle from '../styles/dashboard.module.scss';
import cx from 'classnames';
import { ReactComponent as BriefcaseIcon } from '../assets/svg/briefcase.svg';
import { ReactComponent as TorchIcon } from '../assets/svg/torch.svg';
import { ReactComponent as UserGroupIcon } from '../assets/svg/user-group.svg';
import { ReactComponent as VerifiedIcon } from '../assets/svg/verified.svg';

const PROJECT_BAR_DATA = [
  {
    key: 'name',
    Icon: BriefcaseIcon,
    title: 'Project Name',
  },
  {
    key: 'type',
    Icon: TorchIcon,
    title: 'Project Type',
  },
  {
    key: 'status',
    Icon: VerifiedIcon,
    title: 'Project Status',
  },
  {
    key: 'numberOfUsers',
    Icon: UserGroupIcon,
    title: 'No. of Users',
  },
];

function ProjectInfoBar({ projectData }) {
  return (
    <div
      className={cx('grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 shadow-md mb-10', DashboardStyle['project-bar'])}
    >
      {PROJECT_BAR_DATA.map((item, itemIndex) => (
        <div
          className={cx(
            'flex pl-10 lg:pl-5 xl:pl-10 mr-4 lg:mr-0 lg:pr-4 xl:pr-0 xl:mr-4 my-6',
            DashboardStyle['card'],
            {
              'lg:border-r': (itemIndex + 1) % 4 !== 0,
              'md:border-r': (itemIndex + 1) % 2 !== 0,
            }
          )}
          key={item.key}
        >
          <item.Icon />
          <div className="ml-6">
            <p className={cx('mb-2', DashboardStyle['title'])}>{item.title}</p>
            <p className={DashboardStyle['text']}>{projectData?.[item.key] || '---'}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProjectInfoBar;

import React, { useState, useEffect, Fragment } from 'react';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { useTable, useRowSelect } from 'react-table';
import MemoryIcon from '@material-ui/icons/Memory';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import { getMemoryConfigReq, getSingleProjectRequest } from '@api/api_services';
import { _getKBFromStorageString, _showNumberAfterSecondDecimal } from '@src/pages/dashboard/charts/CommonMethods';
import ProgressLineMemoryConfig from '@src/pages/configuration/Memory_Config/ProgressLineMemoryConfig';
import { Tooltip } from '@material-ui/core';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { CommonLoader } from '@src/pages/common/common-loader';
import { useAlert } from '@pages/common/alert_service/useAlert';
import SearchBar from '@pages/analytics/common/search-bar';
import { HighlightText, isEmptyValue, getUserPrivilege, isValueIncludes } from '@util/common_utils';
import NoResultsFound from '@pages/common/Table/NoResultFound';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteMemoryModal from './deleteMemoryModal';
import ContextMenu from '@pages/common/context_menu';
import { MEMORYCONFIG_CONSTANTS } from '@src/common/ui-constants';
import { ReactComponent as TotalMemory } from '@assets/svg-imports/totalMemory.svg';
import EditIcon from '@mui/icons-material/Edit';
import DistributeMemory from './distributeMemoryModal';
import ProjectDetails from './projectDetailsDrawer';
import { colors } from '@src/css_config/colorConstants';
import { Menu, Transition } from '@headlessui/react';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const Progress = ({ strokeWidth, percentage, barColor }) => {
  const radius = 50 - strokeWidth / 2;
  const pathDescription = `
      M 50,50 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;

  const diameter = Math.PI * 2 * radius;
  const progressStyle = {
    stroke:
      barColor === colors.golden_Poppy
        ? colors.golden_Poppy
        : barColor === colors.rs_red
          ? colors.rs_red
          : colors.rs_primary,
    strokeLinecap: 'round',
    strokeDasharray: `${diameter}px ${diameter}px`,
    strokeDashoffset: `${((100 - percentage) / 100) * diameter}px`,
  };

  return (
    <svg className={'CircularProgressbar'} viewBox="0 0 100 100" width={100} height={70}>
      <path
        className="CircularProgressbar-trail"
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={0}
        style={{
          stroke: '#d6d6d6',
        }}
      />

      <path
        className="CircularProgressbar-path"
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={0}
        style={progressStyle}
      />

      <text
        className="CircularProgressbar-text fontPoppinsMediumMd card-header"
        x={50}
        y={50}
        style={{
          dominantBaseline: 'central',
          textAnchor: 'middle',
        }}
      >
        {`${percentage}%`}
      </text>
    </svg>
  );
};

const MemoryConfig = (props) => {
  const location = useLocation();
  const { AlertContatiner, MyAlert } = useAlert();
  const { resetProgress, startOrResumeProgress, stopProgress } = React.useContext(ProgressContainerContext);
  const [reloadTable, setReloadTable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const actiont = new URLSearchParams(location.search).get('actiont');
  const [selectedData, setSelectedData] = useState();
  const history = useHistory();
  let activeTab = props.location.pathname.split('/')[2];
  const [projectDetails, setprojectDetails] = React.useState([]);
  const [totalConsumption, setTotalConsumption] = React.useState('');
  const [showProjectDetails, setShowProjectDetails] = useState(false);
  const [tabsList, setTabsList] = useState([
    {
      name: 'Memory Configuration',
      pathUrl: `/configuration/memoryConfiguration`,
      selectedName: 'memoryConfiguration',
    },
  ]);

  const isUserPrivilege = getUserPrivilege();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [seletedProject, setSelectedProject] = React.useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [seletedProjectData, setSelectedProjectData] = useState({});
  const [pageIndexValue, setPageIndex] = useState(0);
  const renderTabs = () => {
    return (
      <div>
        <ul className="navBar header-style flex flex-row primary-tab ml-2 ">
          {tabsList.map((tab, index) => {
            return (
              <li key={tab.name}>
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    props.history.push(tab.pathUrl);
                  }}
                  className={`${activeTab === tab.selectedName ? 'selected_tab_style' : ''}`}
                >
                  {tab.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  // React.useEffect(() => {
  //   resetProgress();
  //   startOrResumeProgress(
  //     {
  //       stopAt: 90,
  //     }
  //   );
  //   getMemoryConfigReq().then((results) => {
  //     stopProgress();
  //     startOrResumeProgress(
  //       {
  //         stopAt: 100,
  //         intervalDuration: 10,
  //       }
  //     );
  //     setprojectDetails(results.data.responseObject.projectDetails)
  //     setTotalConsumption(results.data.responseObject.totalConsumption)
  //   }).catch((err) => {
  //     resetProgress();
  //     console.error(err)
  //   })

  //   if (actiont) {

  //     switch (actiont) {
  //       case "edit":
  //         MyAlert.success(`Memory configuration updated successfully`);
  //         setTimeout(() => {
  //           history.push("/configuration/memoryConfiguration")
  //         }, 10);
  //         break;
  //     }
  //   }
  // }, [])

  const getVariables = async () => {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    let res = await getMemoryConfigReq();
    stopProgress();
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 10,
    });
    if (res.data) {
      setTotalConsumption(res.data.responseObject.totalConsumption);
      setprojectDetails(res.data.responseObject.projectDetails);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (reloadTable) {
      setIsLoading(true);
      getVariables();
    }
    setReloadTable(false);
  }, [reloadTable]);

  // TODO :: handle Search filter :: START
  const [userSearched, setuserSearched] = useState('');
  let [openSearch, setOpenSearch] = useState(false);
  let [isHighlight, setIsHighlight] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(projectDetails);
    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [projectDetails]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(projectDetails);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setData(projectDetails);
    setSearchTextHighlight('');
    setIsHighlight(!isHighlight);
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (!e?.target?.value == '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = projectDetails.filter(({ name }) => {
          return isValueIncludes(e, name);
        });
        setData(newData);
        setSearchTextHighlight(e?.target?.value || e);
        setIsHighlight(!isHighlight);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  const openModal = (value) => {
    setOpenDeleteModal(true);
    setSelectedData(value);
  };
  const closeModal = () => {
    setOpenDeleteModal(false);
  };
  const handleMoreIconOpen = () => {
    setShowProjectDetails(true);
  };
  const handleMoreIconClose = () => {
    setShowProjectDetails(false);
  };

  const handleOpenModal = (value) => {
    setIsModalOpen(true);
    setSelectedProject(value);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  function contextMenuFunction(data) {
    getSingleProjectRequest(data.id).then((results) => {
      const projects = results.data.responseObject;
      const user = results.data.responseMap;
      setSelectedProjectData({ projects, user });
    });
  }
  // TODO :: handle Search filter :: END

  const columns = React.useMemo(
    () => [
      {
        Header: 'PROJECTS',
        accessor: 'name',
        width: '15%',
        Cell: (e) => (
          <span className="content-style cursor-pointer fontPoppinsRegularSm">
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </span>
        ),
      },
      {
        Header: 'TYPE',
        accessor: 'type',
        width: '5%',
        Cell: (e) => <span className="content-style cursor-pointer fontPoppinsRegularSm">{e.value}</span>,
      },
      {
        Header: 'USED MEMORY',
        accessor: 'usedMemory',
        width: '20%',
        Cell: (e) => (
          <span className="cursor-pointer fontPoppinsRegularMd">
            <div className="mt-2 flex justify-between">
              <ProgressLineMemoryConfig
                total={_getKBFromStorageString(e.row?.original?.assignedMemory)}
                filled={_getKBFromStorageString(e.row?.original?.usedMemory)}
              />
              <span className="content-style -mt-1 ml-2 w-3/5 fontPoppinsRegularSm">
                {' '}
                {_showNumberAfterSecondDecimal(e.row?.original?.usedMemory)} Used
              </span>
            </div>
          </span>
        ),
      },
      {
        Header: 'ASSIGNED MEMORY',
        accessor: 'assignedMemory',
        width: '10%',
        Cell: (e) => <span className="content-style cursor-pointer fontPoppinsRegularSm">{e?.value} </span>,
      },
      {
        Header: 'ACTIONS',
        accessor: 'action',
        width: '3%',
        Cell: ({ row }) => {
          return (
            <div>
              <span className="flex flex-row">
                {!isUserPrivilege && (
                  <span
                    className="project-action memory-state-button cursor-pointer py-1 "
                    onClick={() => handleOpenModal(row.original)}
                  >
                    {' '}
                    <Tooltip title={MEMORYCONFIG_CONSTANTS.EDIT}>
                      <EditIcon />
                    </Tooltip>
                  </span>
                )}
                {!isUserPrivilege && (
                  <span
                    className={`project-action cursor-pointer ml-3 py-1 ${row.original.status === 'Open' ? 'disabled_delete ' : 'memory-state-button'}`}
                    onClick={() => row.original.status !== 'Open' && openModal(row.original)}
                  >
                    {' '}
                    <Tooltip
                      title={
                        row.original.status !== 'Open'
                          ? MEMORYCONFIG_CONSTANTS.DELETE
                          : MEMORYCONFIG_CONSTANTS.OPEN_PROJECTS
                      }
                    >
                      <DeleteIcon />
                    </Tooltip>
                  </span>
                )}
                <span className="project-action text-base  cursor-hand memory-state-button ml-2 py-1">
                  <Tooltip title="More">
                    <Menu as="div" className="relative inline-block text-left" key="user-menu">
                      {({ open }) => (
                        <>
                          <div>
                            <Menu.Button
                              className="flex items-center  focus:outline-none "
                              onMouseOver={(e) => {
                                let mouse = e.clientY;
                                setPageIndex(mouse);
                              }}
                            >
                              <span className="mt-0.5">
                                {' '}
                                <MoreVertIcon />
                              </span>
                            </Menu.Button>
                          </div>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className={`${pageIndexValue > 520 ? '-mt-24 ' : 'mt-2 '} origin-top-right absolute right-0 z-40 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                            >
                              <div className="py-5">
                                <Menu.Item>
                                  <div
                                    onClick={() => {
                                      contextMenuFunction(row.original);
                                      handleMoreIconOpen();
                                    }}
                                    className="block px-4 py-1 text-sm details-background"
                                  >
                                    {MEMORYCONFIG_CONSTANTS.DETAILS}
                                  </div>
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </Tooltip>
                </span>
              </span>
            </div>
          );
        },
      },
    ],
    [isHighlight, pageIndexValue]
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
    },

    useRowSelect
  );
  let perValue = (parseFloat(totalConsumption.assignedMemory) / parseFloat(totalConsumption.totalMemory)) * 100;
  const kbToGb = (1 / 1024) * parseFloat(totalConsumption.usedMemory) * (1 / 1024);
  const kbToPercent = (parseFloat(kbToGb) / parseFloat(totalConsumption.totalMemory)) * 100;
  const gBToPercentUsedMemory =
    (parseFloat(totalConsumption.usedMemory) / parseFloat(totalConsumption.totalMemory)) * 100;
  const mbToGb = (1 / 1024) * parseFloat(totalConsumption.usedMemory);
  const mbToPercentUsedMemory = (mbToGb / parseFloat(totalConsumption.totalMemory)) * 100;
  const getUnitValueFromUsedMemory =
    totalConsumption && totalConsumption.usedMemory ? totalConsumption.usedMemory.slice(-2) : '';
  const kbToGbAssignMemory = (1 / 1024) * parseFloat(totalConsumption.assignedMemory) * (1 / 1024);
  const kbToPercentAssignMemory = (parseFloat(kbToGbAssignMemory) / parseFloat(totalConsumption.totalMemory)) * 100;
  const gBToPercentkbToGbAssignMemory =
    (parseFloat(totalConsumption.assignedMemory) / parseFloat(totalConsumption.totalMemory)) * 100;
  const mbToGbAssignMemory = (1 / 1024) * parseFloat(totalConsumption.assignedMemory);
  const mbToPercentAssignMemory = (mbToGbAssignMemory / parseFloat(totalConsumption.totalMemory)) * 100;
  const getUnitValueFromAssignMemory =
    totalConsumption && totalConsumption.assignedMemory ? totalConsumption.assignedMemory.slice(-2) : '';
  const kbToGbAvailableMemory = (1 / 1024) * parseFloat(totalConsumption.remainingMemory) * (1 / 1024);
  const kbToPercentAvailableMemory =
    (parseFloat(kbToGbAvailableMemory) / parseFloat(totalConsumption.totalMemory)) * 100;
  const gBToPercentkbToGbAvailableMemory =
    (parseFloat(totalConsumption.remainingMemory) / parseFloat(totalConsumption.totalMemory)) * 100;
  const mbToGbAvailableMemory = (1 / 1024) * parseFloat(totalConsumption.remainingMemory);
  const mbToPercentAvailableMemory = (mbToGbAvailableMemory / parseFloat(totalConsumption.totalMemory)) * 100;
  const getUnitValueFromAvailableMemory =
    totalConsumption && totalConsumption.remainingMemory ? totalConsumption.remainingMemory.slice(-2) : '';
  const kbToMb = (1 / 1000) * parseFloat(totalConsumption.usedMemory) * 100;
  const finalPercent = kbToMb / parseFloat(totalConsumption.assignedMemory);

  const isEditable = React.useMemo(() => window.permission?.isEditAccess('configuration'), []);

  return isLoading ? (
    <CommonLoader />
  ) : (
    <div className="page-container">
      <div className="alert-position-style">
        <AlertContatiner></AlertContatiner>
      </div>

      <div className="my-1 ">
        {renderTabs()}
        <div className="mb-3 mt-3 mx-2 flex justify-between h-28">
          <div className="">
            <div className=" rounded-lg bg-white h-24 shadow-md total-memory-card ">
              <div className="flex justify-between px-3 ">
                <div className="flex flex-col justify-between ml-2 fontPoppinsSemiboldSm">
                  <span className=" pt-3  total_memory">{MEMORYCONFIG_CONSTANTS.TOTAL_MEMORY}</span>
                  <Tooltip title={totalConsumption.totalMemory}>
                    <span className=" mb-3 fontPoppinsMediumSm card-header">
                      {totalConsumption.totalMemory?.substring(0, totalConsumption.totalMemory?.lastIndexOf(' '))
                        ?.length > 5
                        ? totalConsumption.totalMemory
                            ?.substring(0, totalConsumption.totalMemory?.lastIndexOf(' '))
                            .substring(0, 5) + '...'
                        : totalConsumption.totalMemory?.substring(
                            0,
                            totalConsumption.totalMemory?.lastIndexOf(' ')
                          )}{' '}
                      {totalConsumption?.totalMemory?.slice(-2)}
                    </span>
                  </Tooltip>
                </div>

                <div className="pt-2">
                  <div className="text-center">
                    <TotalMemory className="ml-14 mt-2" style={{ height: '63px', width: '70px' }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*---------2nd box-------------- */}
          <div className="">
            <div className=" rounded-lg bg-white h-24 shadow-md available-memory-card">
              <div className="flex justify-between px-3">
                <div className="flex flex-col justify-between fontPoppinsSemiboldSm">
                  <span className=" pt-3 available-card">{MEMORYCONFIG_CONSTANTS.AVAILABLE_MEMORY}</span>
                  <Tooltip title={totalConsumption.remainingMemory}>
                    <span className="mb-3 fontPoppinsMediumSm card-header">
                      {totalConsumption.remainingMemory?.substring(
                        0,
                        totalConsumption.remainingMemory?.lastIndexOf(' ')
                      )?.length > 5
                        ? totalConsumption.remainingMemory
                            ?.substring(0, totalConsumption.remainingMemory?.lastIndexOf(' '))
                            .substring(0, 5) + '...'
                        : totalConsumption.remainingMemory?.substring(
                            0,
                            totalConsumption.remainingMemory?.lastIndexOf(' ')
                          )}{' '}
                      {totalConsumption?.remainingMemory?.slice(-2)}
                    </span>
                  </Tooltip>
                </div>
                <div className="Configcircle">
                  <Progress
                    strokeWidth={8}
                    percentage={
                      totalConsumption && getUnitValueFromAvailableMemory === 'GB'
                        ? gBToPercentkbToGbAvailableMemory.toString().slice(0, 5)
                        : totalConsumption && getUnitValueFromAvailableMemory === 'MB'
                          ? mbToPercentAvailableMemory.toString().slice(0, 5)
                          : totalConsumption && getUnitValueFromAvailableMemory === 'KB'
                            ? kbToPercentAvailableMemory.toString().slice(0, 5)
                            : '0'
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {/*---------3rd box-------------- */}
          <div className="">
            <div className=" rounded-lg bg-white h-24 shadow-md assigned-memory-card">
              <div className="flex justify-between px-3">
                <div className="flex flex-col justify-between fontPoppinsSemiboldSm">
                  <span className=" pt-3 assigned_memory">{MEMORYCONFIG_CONSTANTS.ASSIGNED_MEMORY}</span>
                  <Tooltip title={totalConsumption.assignedMemory}>
                    <span className="mb-3 fontPoppinsMediumSm card-header">
                      {totalConsumption.assignedMemory?.substring(0, totalConsumption.assignedMemory?.lastIndexOf(' '))
                        ?.length > 5
                        ? totalConsumption.assignedMemory
                            ?.substring(0, totalConsumption.assignedMemory?.lastIndexOf(' '))
                            .substring(0, 5) + '...'
                        : totalConsumption.assignedMemory?.substring(
                            0,
                            totalConsumption.assignedMemory?.lastIndexOf(' ')
                          )}{' '}
                      {totalConsumption?.assignedMemory?.slice(-2)}
                    </span>
                  </Tooltip>
                </div>
                <div className="Configcircle">
                  <Progress
                    strokeWidth={8}
                    percentage={
                      totalConsumption && getUnitValueFromAssignMemory === 'GB'
                        ? gBToPercentkbToGbAssignMemory.toString().slice(0, 5)
                        : totalConsumption && getUnitValueFromAssignMemory === 'MB'
                          ? mbToPercentAssignMemory.toString().slice(0, 5)
                          : totalConsumption && getUnitValueFromAssignMemory === 'KB'
                            ? kbToPercentAssignMemory.toString().slice(0, 5)
                            : '0'
                    }
                    barColor="#FFC600"
                  />
                </div>
              </div>
            </div>
          </div>
          {/*---------4th box-------------- */}
          <div className="">
            <div className=" rounded-lg bg-white transform transition-all  h-24 shadow-md used-memory-card">
              <div className="flex justify-between px-3">
                <div className="flex flex-col justify-between ml-2 fontPoppinsSemiboldSm">
                  <span className="pt-3 used_memory">{MEMORYCONFIG_CONSTANTS.USED_MEMORY}</span>
                  <Tooltip title={totalConsumption.usedMemory}>
                    <span className="mb-4 fontPoppinsMediumSm card-header">
                      {totalConsumption.usedMemory?.substring(0, totalConsumption.usedMemory?.lastIndexOf(' '))
                        ?.length > 5
                        ? totalConsumption.usedMemory
                            ?.substring(0, totalConsumption.usedMemory?.lastIndexOf(' '))
                            .substring(0, 5) + '...'
                        : totalConsumption.usedMemory?.substring(0, totalConsumption.usedMemory?.lastIndexOf(' '))}{' '}
                      {totalConsumption?.usedMemory?.slice(-2)}
                    </span>
                  </Tooltip>
                </div>
                <div className="Configcircle">
                  <Progress
                    strokeWidth={8}
                    percentage={
                      totalConsumption && getUnitValueFromUsedMemory === 'GB'
                        ? gBToPercentUsedMemory.toString().slice(0, 5)
                        : totalConsumption && getUnitValueFromUsedMemory === 'MB'
                          ? mbToPercentUsedMemory.toString().slice(0, 5)
                          : totalConsumption && getUnitValueFromUsedMemory === 'KB'
                            ? kbToPercent.toString().slice(0, 5)
                            : '0'
                    }
                    barColor="#C50303"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-list-body mx-2">
          <div className="page-table-body  rounded-lg bg-white shadow-md transform transition-all">
            <div className="config_Page pr-0">
              <div className="grid grid-cols-2 my-3 mr-10">
                <span className=" t_header mt-2 ml-4 fontPoppinsSemiboldMd card-header">
                  {MEMORYCONFIG_CONSTANTS.CARD_HEADER}
                </span>
                {isEditable && (
                  <div className="flex justify-end items-center ">
                    <SearchBar
                      open={openSearch}
                      searchValue={userSearched}
                      handleSearch={handleSearch}
                      onSearchClose={handleSearchClose}
                      handleSearchOpen={handleSearchOpen}
                      enterKeyHandler={handleSearchSubmit}
                      disable={isEmptyValue(projectDetails)}
                    />
                  </div>
                )}
              </div>
              <div
                className={
                  projectDetails.length === 0
                    ? ' flex justify-center items-center primary-table-height'
                    : 'primary-table-height overflow-y-auto overflow-x-hidden'
                }
              >
                {projectDetails?.length === 0 ? (
                  <div className="fontPoppinsMediumLg justify-center">No Projects Found !</div>
                ) : (
                  <>
                    <table {...getTableProps()} className="h-3 mx-6 table-content-width">
                      <thead className="projectDetailsDataa table_header_color">
                        {headerGroups.map((headerGroup) => (
                          <tr
                            {...headerGroup.getHeaderGroupProps()}
                            className="table_header_color text-sm h-9 ml-4  top-0"
                          >
                            {headerGroup.headers.map((column) => (
                              <th
                                {...column.getHeaderProps()}
                                width={column.width}
                                className=" text-left mx-2 fontPoppinsSemiboldSm pl-3 "
                              >
                                <span className="header-style">{column.render('Header')}</span>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                          prepareRow(row);
                          return (
                            <tr
                              {...row.getRowProps()}
                              className="configHover h-11 hover:bg-gray-100 mt-10 project-row border-none"
                            >
                              {row.cells.map((cell) => {
                                return (
                                  <td
                                    {...cell.getCellProps()}
                                    className="p-0  text-left text-gray-700 fontPoppinsRegularMd pl-3"
                                  >
                                    {cell.render('Cell')}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                )}
                {openSearch && isEmptyValue(data) && <NoResultsFound />}
              </div>
            </div>
          </div>
        </div>
      </div>
      {openDeleteModal && (
        <DeleteMemoryModal
          openModal={openModal}
          closeModal={closeModal}
          selectedData={selectedData}
          isLoading={isLoading}
          MyAlert={MyAlert}
          getVariables={getVariables}
        />
      )}
      {isModalOpen && (
        <DistributeMemory
          isLoading={isLoading}
          isOpen={handleOpenModal}
          onClose={handleCloseModal}
          selectedProject={seletedProject}
          availableMemory={totalConsumption.remainingMemory}
          MyAlert={MyAlert}
          getVariables={getVariables}
        />
      )}
      {showProjectDetails && (
        <ProjectDetails
          isLoading={isLoading}
          isOpen={handleMoreIconOpen}
          onClose={handleMoreIconClose}
          projectDetails={seletedProjectData}
        />
      )}
    </div>
  );
};

export default withRouter(MemoryConfig);

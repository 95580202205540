import React from 'react';
import styles from './FeaturesTree.module.css';

const FeaturesTree = ({ data, disableClick }) => {
  return (
    <div className="">
      <ul className={styles.tree}>
        {data?.map((item, index) => {
          let { name, list } = item;
          if (list?.length > 0) {
            return (
              <li key={`${name}-${index}`}>
                <details open className={disableClick ? styles.disabled : ''}>
                  <summary className="fontPoppinsSemiboldSm list-outside">{name}</summary>
                  {list?.length > 0 && (
                    <ul>
                      {list?.map((feature, idx) => {
                        if (!['Manual Testcases', 'Defects', 'Automation']?.includes(feature)) {
                          return (
                            <li key={`${feature}-${idx}`} className="fontPoppinsRegularSm my-3">
                              {feature}
                            </li>
                          );
                        }
                        return null;
                      })}
                    </ul>
                  )}
                </details>
              </li>
            );
          }
          return null;
        })}
      </ul>
    </div>
  );
};

export default FeaturesTree;

import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import FolderRouter from './folder/folderrouter';
import Parent from './parent';
import TestDataPage from './testdata_page';
function TestDataRouter(props) {
  const { history } = props;
  let historyLocation = history.location.pathname;
  let path;
  if (historyLocation.includes('/testData/')) {
    let index = historyLocation.lastIndexOf('/');
    path = historyLocation.slice(0, index);
  }
  return (
    <div className="page-list-body">
      <Switch>
        <Route path="/testData" exact={true} key="script-page" component={Parent}></Route>
        <Route path={`${path}`} key="folder-page" component={FolderRouter}></Route>
        <Route path="*" component={TestDataPage} />
      </Switch>
    </div>
  );
}

export default withRouter(TestDataRouter);

import React, { useEffect, useState, createContext } from 'react';
import CommonDrawer from '@src/common/atoms/CommonDrawer';
import { DEFECT_CONSTANTS } from '@src/common/ui-constants';
import { Grid, Tooltip } from '@mui/material';
import {
  getSingleDefectReq,
  getTemplateForEmailConfigReq,
  getAllComments,
  downloadFileFromCloud,
} from '@api/api_services';
import Comment from '@pages/defects/defect-comments/components/Comment/Comment';
import { DEFECT_DATE_FIELD, FORM_FIELDS } from '@pages/defects/defectMgmtConstants';
import RichTextEditor from '@pages/common/rich-text-editor';
import { saveFileFromBlob } from '@pages/test-development/shared/common-methods';
import { Download } from '@mui/icons-material';
import { UI_VALIDATIONS } from '@src/util/validations';

function DefectDetailsView(props) {
  const [detailsViewData, setdetailsViewData] = useState([]);
  const [selectedDefectData, setSelectedDefectData] = useState({});
  const [defectMgmtData, setDefectMgmtData] = useState({});
  const [comments, setComments] = useState([]);
  const { DEFECTS_VALUES_MAX_CHAR_COUNT } = UI_VALIDATIONS;
  const { CREATED_ON, MODIFIED_ON } = DEFECT_DATE_FIELD;
  const { DATE } = FORM_FIELDS;

  let project = JSON.parse(localStorage.getItem('selected-project'));
  const defectId = props.id;
  const { TEXTBOX, LINK, ATTACHMENT, TEXTEDITOR } = FORM_FIELDS;
  let labelTitle = ' ';
  selectedDefectData?.projectLabels?.forEach((system, index) => {
    labelTitle +=
      (system?.name ? system?.name : '') + `${index < selectedDefectData?.projectLabels?.length - 1 ? ',' : ' '}`;
  });

  const getTemplateForEmailConfig = async () => {
    try {
      let response = await getTemplateForEmailConfigReq(project.id);
      if (response?.data?.responseCode === 200 && response?.data?.responseObject) {
        let template = response?.data?.responseObject;
        setDefectMgmtData(template);
        if (props?.handelDisableEmailConfig) {
          props?.handelDisableEmailConfig(template.templates_selected);
        }
        return template;
      }
    } catch (err) {
      console.error('get Template For Email Config api error', err);
    }
  };
  const getSingleDefect = async () => {
    try {
      const response = await getSingleDefectReq(project?.id, defectId);
      if (response?.data?.responseCode === 200) {
        setSelectedDefectData(response?.data?.responseObject);
        return response?.data?.responseObject;
      }
    } catch (err) {
      console.error('Get Single defect error', err);
    }
  };

  const initDefectData = async () => {
    setdetailsViewData([]);
    const template = await getTemplateForEmailConfig();
    const singleDefectData = await getSingleDefect();
    getUserComments();
    mapKeyAndValue(template?.defect_details_templates[0]?.defectDetails, singleDefectData?.defectDetails);
  };
  useEffect(() => {
    initDefectData();
    getUserComments();
  }, []);

  const getUserComments = () => {
    getAllComments(defectId).then((data) => {
      setComments(data.data.responseObject);
    });
  };

  function mapKeyAndValue(defectTemplateData, defectDetails) {
    defectTemplateData.forEach((item) => {
      if (['Assign to', 'Created By', 'Modified By'].includes(item?.label)) {
        item.value = defectDetails && defectDetails[item.label]?.name;
      } else {
        item.value = defectDetails && defectDetails[item.label];
      }
    });
    setdetailsViewData(defectTemplateData);
  }

  const downloadFile = (file) => {
    downloadFileFromCloud(file.id)
      .then((response) => {
        saveFileFromBlob(response.data, file.name);
      })
      .catch((err) => {
        console.error('DOWNLOAD FILE API FAILED', err);
      });
  };
  const handleOnClickDefectValues = (data) => {
    if (data?.value) {
      const fieldId = document.getElementById(data?.label);
      if (fieldId?.classList?.contains('wrapContent')) {
        fieldId?.classList?.remove('wrapContent');
        fieldId?.classList?.add('contentDataStyle');
      } else {
        fieldId?.classList?.remove('contentDataStyle');
        fieldId?.classList?.add('wrapContent');
      }
    }
  };
  const getDefectValue = (data, value) => {

    let tooltipTitle = value?.length > DEFECTS_VALUES_MAX_CHAR_COUNT
    
    return (
      <>
        { tooltipTitle? (
          <Tooltip title={value} placement="top">
            <label
              id={data.label}
              className="wrapContent label-overflow cursor-pointer"
              onClick={() => {
                handleOnClickDefectValues(data);
              }}
            >
              {value || '--'}
            </label>
          </Tooltip>
        ) : (
          <label className="label-overflow">{value || '--'}</label>
        )}
      </>
    );
  };
  const getDisplayTimeZoneBasedDate = (data) => {
    let label = data.label.concat(' - onDisplay');
    let value = selectedDefectData?.defectDetails[label];
    return getDefectValue(data, value);
  };
  const detailViewComponents = (data) => {
    let value;
    if (data.type !== ATTACHMENT) {
      value = data.value && Array.isArray(data.value) ? data.value.join(',') : data.value;
    }
    if (data?.label === 'Labels') {
      return (
        <>
          <Tooltip title={labelTitle}>
            <div className="label-overflow fontPoppinsRegularSm">{labelTitle || '--'}</div>
          </Tooltip>
        </>
      );
    }
    if (data.label === 'Module') {
      return (
        <>
          <Tooltip title={data?.value !== '--' && data.value?.split(':')?.shift()} placement="right-end">
            <div
              id={data?.value}
              className="wrapContent label-overflow cursor-pointer fontPoppinsRegularSm"
              onClick={() => {
                if (data?.value) {
                  const fieldId = document.getElementById(data?.value);
                  if (fieldId?.classList?.contains('wrapContent')) {
                    fieldId?.classList?.remove('wrapContent');
                    fieldId?.classList?.add('contentDataStyle');
                  } else {
                    fieldId?.classList?.remove('contentDataStyle');
                    fieldId?.classList?.add('wrapContent');
                  }
                }
              }}
            >
              {data?.value ? data.value?.split('/')?.pop()?.split(':')?.shift() : '--'}
            </div>
          </Tooltip>
        </>
      );
    }
    if (data.type === DATE && [CREATED_ON, MODIFIED_ON].includes(data.label)) {
      return getDisplayTimeZoneBasedDate(data);
    }

    if (data.type === LINK && value) {
      return (
        <>
          <Tooltip title={value !== '--' && value} placement="top">
            <a href={value} target="_blank" className="label-overflow tab-style-common fontPoppinsRegularSm">
              {value?.length > 25 ? `${value.substring(0, 25)}...` : value}
            </a>
          </Tooltip>
        </>
      );
    }
    if (data.type === ATTACHMENT) {
      if (data?.value && Array.isArray(data?.value) && data?.value.length > 0) {
        return (
          <div>
            {data?.value?.map((option, index) => (
              <div key={index}>
                <Tooltip title={option?.name !== '--' && option?.name} placement="top">
                  <label className="label-overflow fontPoppinsRegularSm">
                    {option?.name.length > 25 ? `${option?.name.substring(0, 25)}...` : option?.name || '--'}
                  </label>
                </Tooltip>
                <Download
                  color="primary"
                  className="h-6 w-6 mt-1 mr-2 origin-center cursor-pointer"
                  onClick={() => {
                    downloadFile(option);
                  }}
                />
              </div>
            ))}
          </div>
        );
      } else {
        <label className="label-overflow fontPoppinsRegularSm">--</label>;
      }
    }
    if (data.type === TEXTEDITOR) {
      return (
        <div className="">
          <RichTextEditor mode="view" convertedContent={value || ''} readOnly={true} toolbarHidden={true} />
        </div>
      );
    }
    return (
      <>
        {value !== '--' && value?.length > 30 ? (
          <Tooltip title={value !== '--' && value?.length > 30 && value} placement="top">
            <label
              id={data.label}
              className="wrapContent label-overflow cursor-pointer fontPoppinsRegularSm"
              onClick={() => {
                if (data?.value) {
                  const fieldId = document.getElementById(data?.label);
                  if (fieldId?.classList?.contains('wrapContent')) {
                    fieldId?.classList?.remove('wrapContent');
                    fieldId?.classList?.add('contentDataStyle');
                  } else {
                    fieldId?.classList?.remove('contentDataStyle');
                    fieldId?.classList?.add('wrapContent');
                  }
                }
              }}
            >
              {value || '--'}
            </label>
          </Tooltip>
        ) : (
          <label className="label-overflow fontPoppinsRegularSm">{value || '--'}</label>
        )}
      </>
    );
  };
  const detailViewElements = (data) => {
    return (
      <>
        <div className="flex flex-col justify-center">
          <div className="flex">
            <div className="flex ">
              <label className="fontPoppinsMediumSm">
                {data ? (
                  <Tooltip title={data?.label} placement="top">
                    <div className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                      {data?.label.length > 32 ? `${data?.label.substring(0, 32)}...` : data?.label}
                    </div>
                  </Tooltip>
                ) : null}
              </label>
            </div>
          </div>
          <div className="contentDataStyle">
            {data?.label === 'Comments' ? (
              <>
                <div className="comment-area">
                  <div className="comment-section">
                    <commentContext.Provider>
                      {comments?.length > 0
                        ? comments?.map((comment, index) => {
                            return (
                              <Comment
                                key={index}
                                id={index}
                                data={comment}
                                commentsArray={comments}
                                onAddComment={setComments}
                                detailsView={true}
                              />
                            );
                          })
                        : '--'}
                    </commentContext.Provider>
                  </div>
                </div>
              </>
            ) : (
              detailViewComponents(data)
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div id="journal-scroll">
      <div className="m-4 shadow-none">
        <Grid container columnSpacing={{ xs: 2, md: 3, sm: 2 }} rowSpacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
          {detailsViewData &&
            detailsViewData.length > 0 &&
            detailsViewData.map((data, index) => {
              return [TEXTBOX, TEXTEDITOR].includes(data.type) && data?.label !== 'Comments' ? (
                <Grid item xs={12} sm={12} md={12} key={index}>
                  {detailViewElements(data)}
                </Grid>
              ) : data?.label !== 'Comments' ? (
                <Grid item xs={4} sm={4} md={4} key={index}>
                  {detailViewElements(data)}
                </Grid>
              ) : null;
            })}
          {detailsViewData &&
            detailsViewData.length > 0 &&
            detailsViewData.map((data, index) => {
              return data?.label === 'Comments' ? (
                <Grid item xs={12} sm={12} md={12} key={index}>
                  {detailViewElements(data)}
                </Grid>
              ) : null;
            })}
        </Grid>
      </div>
    </div>
  );
}

const commentContext = createContext();

const DefectDetailsDrawer = (props) => {
  const { closeDrawer, openDrawer, titleText, defectId } = props;
  const handleClose = () => {
    closeDrawer(false);
  };

  return (
    <CommonDrawer
      isDrawerOpen={openDrawer}
      titleText={titleText}
      drawerWidth={DEFECT_CONSTANTS.DRAWER_WIDTH}
      isRightButtonVisible={false}
      leftButtonText={DEFECT_CONSTANTS.CLOSE}
      onLeftButtonClick={handleClose}
      onDrawerClose={handleClose}
      backButton={true}
      onBackButtonClick={handleClose}
    >
      <DefectDetailsView id={defectId} />
    </CommonDrawer>
  );
};

export default DefectDetailsDrawer;

import React, { useEffect, useMemo, useRef } from 'react';
import Chart from 'react-apexcharts';
import DashboardStyle from '../styles/dashboard.module.scss';
import ChartCard from '../components/ChartCard';
import { fontPoppins } from '@src/css_config/fontConstants';
import { commonCustomTooltip } from '@src/common/CommonCustomTooltip';

function checkSeriesData(series) {
  const isAnyZeroData = series.includes(0);
  return isAnyZeroData ? 1 : 0;
}

const noData = {
  color: '#EBF2F7',
  label: 'nodata',
};

function handleChartElementsStyle(element) {
  if (element) {
    const restSeriesLegendElement = element.querySelector(`.apexcharts-legend-series[seriesname="${noData.label}"]`);

    if (restSeriesLegendElement) {
      restSeriesLegendElement.style.visibility = 'hidden';
      restSeriesLegendElement.style.display = 'none';
    }

    const titleTextElement = element.querySelector('.apexcharts-title-text');
    const parentElementWidth = element.offsetWidth;

    if (titleTextElement && parentElementWidth) {
      titleTextElement.setAttribute('x', parentElementWidth / 2);
    }
  }
}

function PieChart({
  colors,
  fillColor,
  title,
  titleOffsetX,
  labels,
  series,
  chartTooltipFormatter = commonCustomTooltip,
  roundedDirection = 'br',
}) {
  const highLightHover = series.some((value) => value >= 1);

  const wrapperRef = useRef();
  const options = useMemo(
    () => ({
      chart: {
        height: 'auto',
        redrawOnWindowResize: true,
        redrawOnParentResize: true,
        events: {
          mounted: () => {
            handleChartElementsStyle(wrapperRef?.current);
          },
          updated: () => {
            handleChartElementsStyle(wrapperRef?.current);
          },
          mouseMove: () => {
            const element = wrapperRef.current;
            if (element) {
              const tooltipElement = element.querySelector('.apexcharts-tooltip');
              if (tooltipElement?.style?.left) {
                tooltipElement.style.left = 0;
                tooltipElement.style['margin-left'] = '24px';
              }
            }
          },
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: '85%',
            stacked: true,
          },
          expandOnClick: false,
          offsetY: 19,
        },
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      background: '#399365',
      colors: [...colors, noData.color],
      fill: {
        colors: [...fillColor, noData.color],
      },
      title: {
        text: title,
        offsetY: 0,
        margin: 10,
        floating: true,
        style: {
          ...fontPoppins.sDm,
          color: '#3C3838',
        },
      },
      labels: [...labels, noData.label],
      legend: {
        onItemHover: {
          highlightDataSeries: highLightHover,
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        formatter: (seriesName, options) => {
          return seriesName === noData.label
            ? ''
            : `${seriesName} : <span class="fontPoppinsSemiboldXs">${options.w.globals.series[options.seriesIndex]}<span>`;
        },
        horizontalAlign: 'center',
        ...fontPoppins.rXs,
        offsetY: 10,
        itemMargin: {
          horizontal: 5,
          vertical: 1,
        },
      },
      stroke: {
        width: checkSeriesData(series),
      },
      series: {
        data: series,
        style: {
          fontWeight: 800,
          fontSize: '20px',
          color: '#399365',
        },
      },
      tooltip: {
        custom: chartTooltipFormatter,
      },
    }),
    [colors, fillColor, title, labels, series, chartTooltipFormatter, highLightHover]
  );

  const noDataSeries = series.some((number) => number > 0) ? 0 : 1;
  useEffect(() => {
    wrapperRef.current && handleChartElementsStyle(wrapperRef.current);
  });
  return (
    <div ref={wrapperRef}>
      <ChartCard className={DashboardStyle['small-donut-chart']} roundedDirection={roundedDirection}>
        <Chart options={options} series={[...series, noDataSeries]} type="pie" />
      </ChartCard>
    </div>
  );
}

export default PieChart;

import React, { useState } from 'react';
import { Field, Formik } from 'formik';
import Modal from 'react-modal';
import IconButton from '@material-ui/core/IconButton';
import * as yup from 'yup';
import '../testData.scss';
import FileUploadButton from '../../common/fileUpload_button';
import { useRef } from 'react';
import { uploadFilesReq } from '@api/api_services';
import { useAlert } from '@pages/common/alert_service/useAlert';
import TreeWithRadioButton from '@src/pages/common/table_tree/table-tree-with-radio-button';
import { isEmptyValue, validateFileExtension } from '@src/util/common_utils';
import { TEST_DATA_FILE_ALLOWED, UI_VALIDATIONS } from '@util/validations';

function FileModal(props) {
  const search = window.location.search;
  const id = new URLSearchParams(search).get('id');
  const { AlertContatiner, MyAlert } = useAlert();
  const [openModal, setOpenModal] = useState(true);
  const [selectedFolder, setSelectedFolder] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isUploadFile, setIsUploadFile] = useState(false);
  const formRef = useRef();
  let [executionOrder, setExecutionOrder] = useState(
    props.nodeToAdd ? (props.nodeToAdd === 0 ? 1 : findExecutionOrder(props.nodeToAdd)) : 1
  );

  const { MAX_FILE_UPLOAD_COUNT, BASE_COUNT } = UI_VALIDATIONS;

  function findExecutionOrder(node, rootNode = false) {
    if (rootNode) {
      const childs = node.children;
      if (childs?.length) {
        return childs[childs.length - 1].executionOrder + 1;
      } else {
        return 1;
      }
    } else if (node.hasChildren()) {
      return Number(node.getLastChild().data.executionOrder) + 1;
    } else {
      return 1;
    }
  }
  const treeNodeSelected = ([data]) => {
    if (!data) {
      formRef.current.setFieldValue('parentFolder', '');
    } else if (data && data.data.isRootNode) {
      setExecutionOrder(findExecutionOrder(data.data, true));
      setSelectedFolder({ ...data.data, data: data.data });
      formRef.current.setFieldValue('parentFolder', data.data.title);
    } else if (data && data.data.key) {
      setExecutionOrder(findExecutionOrder(data.node));
      setSelectedFolder({ ...data.node, data: data.data });
      formRef.current.setFieldValue('parentFolder', data.data.title);
    }
  };
  const validationSchema = yup.object({
    parentFolder: yup.string().required('Parent Folder is requied'),
  });

  let initialValues;
  if (props.parentName) {
    initialValues = {
      file: [],
      parentFolder: props.parentName,
    };
  } else {
    initialValues = {
      file: [],
      parentFolder: '',
    };
  }
  const uploadFiles = () => {
    let count = 0;
    let folderId;
    let name;
    let folderName;
    const file = new FormData();
    if (props?.parentId) {
      folderId = props.parentId;
      folderName = props.parentName;
    } else {
      folderId = selectedFolder.data.key;
      folderName = selectedFolder.title;
    }
    formRef.current.values.file.forEach((data, index) => {
      count = count + 1;
      name = data.name;
      file.append('file', data);
      file.append('executionOrder', executionOrder);
    });
    setIsButtonDisabled(true);
    setIsUploadFile(true);
    uploadFilesReq(folderId, folderName, file)
      .then((response) => {
        setIsButtonDisabled(true);
        if (response.data.responseCode === 200) {
          setIsButtonDisabled(false);
          if (props.mode === 'ADD') {
          } else {
            let updatedValueArray = [];
            let responseObject = response.data.responseObject;
            if (responseObject) {
              responseObject.forEach((element) => {
                const data = {
                  ver: element.ver,
                  searchKey: element.searchKey,
                  title: element.name,
                  modifiedByUname: element.modifiedByUname,
                  createdOn: element.createdOn,
                  createdByUname: element.createdByUname,
                  parentId: element.parentId,
                  modifiedOn: element.modifiedOn,
                  folder: element.folder,
                  createdBy: element.createdBy,
                  modifiedBy: element.modifiedBy,
                  state: element.state,
                  projectId: element.projectId,
                  key: element.id,
                };
                updatedValueArray.push(data);
              });
            }
            props.updatedValue(updatedValueArray, 'file');
          }
          props.closeModal(false);
          if (count > 1) {
            props.reloadTree(true);
            props.MyAlert.success(`Selected Files uploaded successfully.`);
          } else {
            props.reloadTree(true);
            props.MyAlert.success(`${name} uploaded successfully.`);
          }
          setIsUploadFile(false);
        } else if (response.data.responseCode === 400) {
          document.getElementById('uploadTestDataFile').disabled = false;
          MyAlert.info(`${response.data.message}`);
        }
        if (response.data.message === null || response.data.status === 'FAILURE') {
          props.closeModal(false);
          props.MyAlert.info(`${response.data.message}`);
        }
      })
      .catch((err) => {
        console.error(err.message);
        MyAlert.info(`Failed to upload File(s)`);
      });
  };
  let arrayOfFiles = [];

  let handleFileChange = (event, values, setFieldValue) => {
    let fileList = event?.target?.files;
    if (isEmptyValue(fileList)) {
      MyAlert.warning('Unsupported file');
      return;
    } else {
      arrayOfFiles = [...values?.file];
      let unsupportedFile = [];
      for (let i = 0; i < fileList?.length; i++) {
        if (
          fileList?.length > MAX_FILE_UPLOAD_COUNT ||
          values?.file?.length > MAX_FILE_UPLOAD_COUNT ||
          arrayOfFiles?.length > BASE_COUNT
        ) {
          MyAlert.info('More than 5 files are not allowed');
        } else if (validateFileExtension(fileList.item(i), TEST_DATA_FILE_ALLOWED)) {
          arrayOfFiles.push(fileList.item(i));
          setIsButtonDisabled(false);
        } else {
          unsupportedFile.push(fileList?.item(i));
        }
      }
      if (!isEmptyValue(unsupportedFile)) {
        MyAlert.warning(`${unsupportedFile?.length} unsupported file. Please check the file format.`);
      }
      setFieldValue('file', arrayOfFiles);
      event.target.value = '';
    }
  };

  return (
    <div>
      <Modal
        isOpen={openModal}
        onRequestClose={(e) => {
          if (e.key === 'Escape') {
            setOpenModal(false);
            props.closeModal(false);
          }
        }}
        className="p-px modal-dialog"
      >
        <div className="modal-container page-modal-size modal-position">
          <div className="ml-40">
            <div className="alert-position-style-uploadFiles ">
              <AlertContatiner></AlertContatiner>
            </div>
          </div>
          <div className="modal-header">
            {props.mode === 'ADD' || props.mode === 'ADD_SUB' ? (
              <h2 className="title Popup-header-common"> Upload Files</h2>
            ) : (
              <h2 className="title Popup-header-common">Edit File - {props.data.name}</h2>
            )}
            <IconButton
              component="span"
              className="close"
              onClick={() => {
                props.closeModal(false);
                setOpenModal(false);
              }}
            >
              <img
                style={{
                  cursor: 'pointer',
                }}
                src="/assets/images/close_black.svg"
                alt=""
                height="25px"
                width="25px"
              />
            </IconButton>
          </div>
          <div className="modal-body" id="journal-scroll">
            {props.data ? (
              <div></div>
            ) : (
              <div className="">
                <Formik initialValues={initialValues} validationSchema={validationSchema} innerRef={formRef}>
                  {({ values, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                      <Field name="parentFolder">
                        {({ field, form, meta }) => {
                          if (selectedFolder) {
                            form.values.parentFolder = selectedFolder.title;
                          }
                          let errorExist = false;
                          if (form.values.parentFolder === '' && form.touched.parentFolder) {
                            errorExist = true;
                          }
                          return (
                            <div className="">
                              <div className="mt-2 text-sm text-blue-700">
                                <label
                                  htmlFor="parentPage"
                                  className={
                                    errorExist
                                      ? 'text-xs text-red-500 input-filed-label-style-common'
                                      : 'input-filed-label-style-common'
                                  }
                                >
                                  <span className="text-red-400">&#42;</span>
                                  Parent Folder
                                </label>
                                <div className="popup-input-bg">
                                  <TreeWithRadioButton
                                    moduleSelection={true}
                                    individualTree={id ? true : false}
                                    hideRootRadioBtn={true}
                                    data={props?.treeData ? props.treeData : []}
                                    operation={['ADD_SUB', 'EDIT'].includes(props.mode) ? 'edit' : null}
                                    placeholder={
                                      props.data
                                        ? props.data.parentName
                                        : props.parentId
                                        ? props.parentName
                                        : 'Search and select parent folder'
                                    }
                                    nodeSelected={treeNodeSelected.bind(this)}
                                    hideElements={true}
                                    hideElementsBtn={true}
                                    buttonLabel="Folder"
                                  />
                                </div>
                                {meta.touched && meta.error && form.values.parentFolder === '' && (
                                  <div className="errorMessage">{meta.error}</div>
                                )}
                              </div>
                            </div>
                          );
                        }}
                      </Field>
                      <div className="mt-5">
                        <label htmlFor="" className="input-filed-label-style-common">
                          <span className="text-red-400 text-xs	">&#42;</span>
                          Select Files{' '}
                        </label>
                        <button className={`fileButton ${isUploadFile && 'pointer-events-none'}`}>
                          <FileUploadButton
                            type="file"
                            id="file"
                            name="file"
                            testDataFilesLength={values.file.length}
                            multiple="true"
                            called="test-Data"
                            accept={Object.keys(TEST_DATA_FILE_ALLOWED)}
                            onChange={(event) => handleFileChange(event, values, setFieldValue)}
                          />
                        </button>
                      </div>
                      <div className="mt-4 shadow-xl border-2	">
                        <div className="tableHeadingStyle">
                          <span className="ml-4 mt-2 filetableheader">Selected Files</span>
                          {/* <SearchOutlined className="text-gray-500 ml-3  cursor-pointer hover:text-blue-700 float-right mr-2" /> */}
                        </div>
                        <div className="">
                          {values.file.length > 0 ? (
                            <table className="text-center w-full">
                              <thead className="tableheaderbackground">
                                <tr className="flex w-full">
                                  <th className="p-2 pl-5 tableheader w-2/4">NAME</th>
                                  <th className="p-2 tableheader w-1/4">TYPE</th>
                                  <th className="p-2 tableheader w-1/4">ACTION</th>
                                </tr>
                              </thead>
                              <tbody className="flex flex-col overflow-y-scroll w-full h-48" id="journal-scroll">
                                {values.file.map((data, index) => {
                                  let dataValues = values.file;
                                  let name = data.name;
                                  let id = name.lastIndexOf('.');
                                  let type = name.slice(id + 1, name.length);
                                  return (
                                    <tr className="flex w-full" key={index}>
                                      <td className="p-2   tabletext  w-1/2">
                                        <div className="fileName" title={data.name}>
                                          {data.name.toLowerCase() === '.project' ||
                                          data.name.toLowerCase() === '.gitignore'
                                            ? data.name
                                            : data.name.replace(/\.[^.]*$/, '')}
                                        </div>
                                      </td>
                                      <td className="p-2  tabletext w-1/4">{type}</td>
                                      <td className="p-2 pl-5 tabletext w-1/4">
                                        <img
                                          className="action-element cursor-pointer "
                                          onClick={() => {
                                            dataValues.splice(index, 1);
                                            setFieldValue('file', dataValues);
                                            MyAlert.info(`Selected file(s) deleted successfully.`);
                                            setIsButtonDisabled(false);
                                            if (dataValues.length < 1) {
                                              setIsButtonDisabled(true);
                                            } else {
                                              setIsButtonDisabled(false);
                                            }
                                          }}
                                          src="/assets/images/delete_red_icon.svg"
                                          height="20px"
                                          width="20px"
                                          alt=""
                                        />
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          ) : (
                            <table className="">
                              <thead className="tableheaderbackground">
                                <tr className="flex w-full">
                                  <th className="p-4  tableheader w-2/4">NAME</th>
                                  <th className="p-4 tableheader w-1/4">TYPE</th>
                                  <th className="p-4 tableheader w-1/4">ACTION</th>
                                </tr>
                              </thead>
                              <tbody className="h-44 tablebodybackground">
                                <tr>
                                  <th colSpan="3" className="notabledata tablebodybackground">
                                    No Files selected
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button
              onClick={() => {
                setOpenModal(false);
                props.closeModal(false);
              }}
              type="button"
              className="gray-btn"
            >
              Cancel
            </button>

            <button
              type="submit"
              className="ml-4 primary-btn"
              id="uploadTestDataFile"
              disabled={isButtonDisabled}
              onClick={() => {
                if (formRef.current.values.parentFolder === '') {
                  formRef.current.setTouched({
                    parentFolder: true,
                  });
                }
                if (formRef.current.values.parentFolder !== '') {
                  uploadFiles();
                }
              }}
            >
              Upload
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default FileModal;

import { useEffect, useMemo, useState } from 'react';
import { ReactComponent as ShieldUserIcon } from '@assets/header-icons/license.svg';
import style from '../styles/switch-license-dropdown.module.scss';
import cx from 'classnames';
import { LICENSE_PERIOD_STATUS, getLicensePeriodStatus, getRemainingDays } from '@util/licenseUtils';
import { getAllLicensesForUser } from '@api/api_services';
import SwitchLicensePopup from '@pages/licenses/components/SwitchLicensePopup';
import RenewLicensePopup from '@pages/licenses/components/RenewLicensePopup';
import { LICENSE_DETAILS_CONSTANTS } from '@src/common/ui-constants';
import SearchableDropdown from '@src/pages/common/searchable_dropdown/SearchableDropdown';
import { ReactComponent as SearchDropDownArrow } from '@assets/svg-imports/search-dropdown-arrow.svg';

function SwitchLicenseDropdown(props) {
  const [open, setOpen] = useState(false);
  const [openSwitchPopup, setOpenSwitchPopup] = useState(false);
  const [licenses, setLicenses] = useState([]);
  const [selectedLicense, setSelectedLicense] = useState(null);
  const [previousLicense, setPreviousLicense] = useState(null);
  const [isRenewLicensePopupOpen, setIsRenewLicensePopupOpen] = useState(false);
  const [licenseValidityDetails, setLicenseValidityDetails] = useState({
    status: LICENSE_PERIOD_STATUS.ACTIVE,
    isPaid: false,
  });

  function handleSelectLicense(license) {
    const currentLicense = selectedLicense;
    setPreviousLicense(currentLicense);
    setSelectedLicense(license);
    setOpen(false);
    setOpenSwitchPopup(true);
  }

  function handleSwitchLicensePopupClose(isChanged = false) {
    setOpenSwitchPopup(false);
    if (!isChanged) {
      const license = previousLicense;
      setSelectedLicense(license);
      setPreviousLicense(null);
    }
  }

  useEffect(() => {
    if (licenses.length) {
      props?.setShowSelectLicense(true);
    } else {
      props?.setShowSelectLicense(false);
    }
  }, [licenses]);

  useEffect(() => {
    const licensePeriodStatus = getLicensePeriodStatus(selectedLicense);
    setLicenseValidityDetails({
      status: licensePeriodStatus,
      isPaid: selectedLicense?.licenseType === 'C-Professional',
    });
  }, [selectedLicense]);

  function fetchLicensesOfUser() {
    getAllLicensesForUser()
      .then((response) => {
        const user = JSON.parse(localStorage.getItem('test-optimize-user') || {});
        const fetchedLicenses = response.data.responseObject;
        setLicenses(response.data.responseObject);
        setSelectedLicense(
          fetchedLicenses.find((fetchedLicense) => (selectedLicense?.id || user.licenseId) === fetchedLicense.id)
        );
      })
      .catch((error) => console.error('Failed to fetch licenses for Switch License drop down', error));
  }

  useEffect(() => {
    fetchLicensesOfUser();
    document.addEventListener('changeInLicenses', fetchLicensesOfUser);

    return () => {
      document.removeEventListener('changeInLicenses', fetchLicensesOfUser);
    };
  }, []);

  const userDetails = useMemo(() => {
    const user = JSON.parse(localStorage.getItem('test-optimize-user') || '{}');
    return selectedLicense?.licenseUsers.find(({ emailId }) => user.userName === emailId) || {};
  }, [selectedLicense]);

  return (
    <div className={cx('flex', style['wrapper'], style['license-component'])}>
      <SwitchLicensePopup
        licenses={licenses}
        open={openSwitchPopup}
        onClose={() => handleSwitchLicensePopupClose(false)}
        licenseId={selectedLicense?.id}
      />
      {userDetails.privilege === 'Super Admin' && isRenewLicensePopupOpen && (
        <RenewLicensePopup open onClose={() => setIsRenewLicensePopupOpen(false)} licenseData={selectedLicense || {}} />
      )}
      {licenseValidityDetails.isPaid && licenseValidityDetails.status === LICENSE_PERIOD_STATUS.WILL_EXPIRE && (
        <div className={cx(style['renew-information-button'], 'mr-6 flex items-center')}>
          <p
            className={cx(
              'fontPoppinsRegularSm',
              licenseValidityDetails?.status === 'warning'
                ? style.expiringSoon
                : licenseValidityDetails?.status === 'danger'
                ? style.expired
                : ''
            )}
          >
            {!selectedLicense?.futureLicensePlan &&
              (selectedLicense?.remainingDays > 0 ? (
                <>
                  {LICENSE_DETAILS_CONSTANTS?.SUBSCRIPTION_EXPIRES_IN}
                  <span className="expiring-text">
                    {` ${selectedLicense?.remainingDays} ${getRemainingDays(selectedLicense?.remainingDays)}`}
                  </span>
                </>
              ) : (
                <>{LICENSE_DETAILS_CONSTANTS?.SUBSCRIPTION_EXPIRING_TODAY}</>
              ))}
          </p>
        </div>
      )}
      <div className="w-64 h-10">
        {props?.showSelectLicense && (
          <SearchableDropdown
            noDataFound={LICENSE_DETAILS_CONSTANTS?.NO_LICENSE_FOUND}
            list={licenses}
            searchLabel="Search"
            optionKey="licenseName"
            selectedValue={selectedLicense?.licenseName}
            StartIcon={() => <ShieldUserIcon width={16} height={18} />}
            fontSize="fontPoppinsRegularSm"
            EndIcon={() => <SearchDropDownArrow />}
            handleItemClick={handleSelectLicense}
            enableSearch={true}
          />
        )}
      </div>
    </div>
  );
}

export default SwitchLicenseDropdown;

import React from 'react';
import ProjectVar from './project-global-variable-child-pages/project-var';
import { withRouter } from 'react-router';
import Globalar from './project-global-variable-child-pages/global-var';
import { useState } from 'react';

function ProjectGlobalVariable(props) {
  let projectVarJsonForEditObject = {};
  let globalVarJsonForEditObject = {};
  if (props?.suiteObjMain?.name) {
    let suiteObj = props?.suiteObjMain;
    let tempProjectJsonObj = {
      usePeVariableFrom: suiteObj?.usePeVariableFrom,
      projectEnvironmentVariables: suiteObj?.projectEnvironmentVariables,
    };
    tempProjectJsonObj.selectedPESetName = suiteObj?.selectedPESetName;
    tempProjectJsonObj.clonedProjectEnvironmentVariables = suiteObj?.clonedProjectEnvironmentVariables;
    projectVarJsonForEditObject = tempProjectJsonObj;
  }

  // }, [])

  if (props?.suiteObjMain?.name) {
    let suiteObj = props?.suiteObjMain;
    let tempGlobalJsonObj = {
      useGlobalVariableFrom: suiteObj?.useGlobalVariableFrom,
      globalVariables: suiteObj?.globalVariables,
    };
    tempGlobalJsonObj.selectedGVSetName = suiteObj?.selectedGVSetName;
    tempGlobalJsonObj.clonedGlobalVariables = suiteObj?.clonedGlobalVariables;
    globalVarJsonForEditObject = tempGlobalJsonObj;
  }

  const [selectedTab, setSelectedTab] = useState('project-variable');
  const [projectVarJsonObject, setProjectVarJsonObject] = useState(
    props &&
      props?.suiteObjForPreview &&
      props?.suiteObjForPreview?.projectGlobalVarData &&
      props?.suiteObjForPreview?.projectGlobalVarData?.projectVarJsonObject
      ? props?.suiteObjForPreview?.projectGlobalVarData?.projectVarJsonObject
      : projectVarJsonForEditObject
      ? projectVarJsonForEditObject
      : {}
  );
  const [globalVarJsonObject, setGlobalVarJsonObject] = useState(
    props &&
      props?.suiteObjForPreview &&
      props?.suiteObjForPreview?.projectGlobalVarData &&
      props?.suiteObjForPreview?.projectGlobalVarData?.globalVarJsonObject
      ? props?.suiteObjForPreview?.projectGlobalVarData?.globalVarJsonObject
      : globalVarJsonForEditObject
      ? globalVarJsonForEditObject
      : {}
  );

  function createProjectVarObj(data) {
    setProjectVarJsonObject(data);
    let jsonData = {};
    jsonData.projectVarJsonObject = data;
    jsonData.globalVarJsonObject = globalVarJsonObject;
    props.createSuiteMainFunctionForProjectVariable(jsonData);
  }

  function createGlobalVarObj(data) {
    setGlobalVarJsonObject(data);
    let jsonData = {};
    jsonData.projectVarJsonObject = projectVarJsonObject;
    jsonData.globalVarJsonObject = data;
    props.createSuiteMainFunctionForProjectVariable(jsonData);
  }

  function createAlertFunctions(type, msg) {
    props.createAlertFun(type, msg);
  }

  return (
    <div>
      <div className="flex flex-row left-border-style mt-3">
        <ul className="navBar text-blue-600 flex flex-row primary-tab">
          <li>
            <a
              href=""
              onClick={(event) => {
                event.preventDefault();
                setSelectedTab('project-variable');
              }}
              className={`${selectedTab === 'project-variable' ? 'selected_tab_style' : ''} `}
            >
              Project Environment Variables
            </a>
          </li>
          <li>
            <a
              href=""
              onClick={(event) => {
                event.preventDefault();
                setSelectedTab('global-variable');
              }}
              className={`${selectedTab === 'global-variable' ? 'selected_tab_style' : ''}`}
            >
              Global Variables
            </a>
          </li>
        </ul>
      </div>
      <div className="project-global-style">
        {selectedTab === 'project-variable' ? (
          <ProjectVar
            createProjectVarObj={createProjectVarObj}
            projectVarJsonObject={projectVarJsonObject}
            projectVarJsonForEditObject={projectVarJsonForEditObject}
            suiteId={props?.suiteObjMain?.id}
            createAlertFunction={createAlertFunctions}
            rerunMode={props?.rerunMode}
            label={props.operation}
          />
        ) : (
          <Globalar
            createGlobalVarObj={createGlobalVarObj}
            globalVarJsonObject={globalVarJsonObject}
            globalVarJsonForEditObject={globalVarJsonForEditObject}
            suiteId={props?.suiteObjMain?.id}
            createAlertFunction={createAlertFunctions}
            rerunMode={props?.rerunMode}
            label={props.operation}
          />
        )}
      </div>
    </div>
  );
}

export default withRouter(ProjectGlobalVariable);

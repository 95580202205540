import React, { useState, useEffect } from 'react';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import './table.scss';

const MultiSelectAll = ({ options, setFilterValue }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    setSelectedOptions([{ label: 'All', value: '*' }, ...options]);
  }, []);

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === '*')) {
      // return `${placeholderButtonLabel}: All`;
      return `All`;
    } else {
      // return `${placeholderButtonLabel}: ${value.length} selected`;
      return `${value.length} selected`;
    }
  }

  function onChange(value, event) {
    if (event.option.value === '*') {
      if (event.action === 'select-option') {
        this.setState(this.options);
        setFilterValue(this.options);
        return null;
      }

      if (event.action === 'deselect-option') {
        this.setState([]);
        setFilterValue([]);
        return null;
      }
    }

    if (event.action === 'deselect-option') {
      let valueAfterDeSelect = value.filter((o) => o.value !== '*');
      this.setState(valueAfterDeSelect);
      setFilterValue(valueAfterDeSelect);
      return null;
    }

    if (value.length === this.options.length - 1) {
      this.setState(this.options);
      setFilterValue(this.options);
    } else {
      this.setState(value);
      setFilterValue(value);
    }
  }

  return (
    <div style={{ textAlign: 'left' }}>
      <ReactMultiSelectCheckboxes
        options={[{ label: 'All', value: '*' }, ...options]}
        //   placeholderButtonLabel="Colors"
        getDropdownButtonLabel={getDropdownButtonLabel}
        value={selectedOptions}
        onChange={onChange}
        setState={setSelectedOptions}
      />
    </div>
  );
};

export default MultiSelectAll;

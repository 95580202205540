import { Menu, Transition, Dialog } from '@headlessui/react';
import React, { Fragment, useEffect, useState, useMemo, useContext, useRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';
import '../css/snippet.scss';
import { useTable } from 'react-table';
import { getAllSnippet, getSingleUserRequest, deleteSnippetVariable } from '@api/api_services';
import UserDetailsModal from '@pages/test-development/shared/user-details-modal';
import Snippet_Create from '../js/snippet_Create';
import Snippet_Update from './snippet_Update';
import { CommonLoader } from '@src/pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import Modal from 'react-modal';
import CloseIcon from '@material-ui/icons/Close';
import Snippet_Editor_Update from '@pages/test-development/script/scripts/webservice/snippet/js/snippet_Editor_Update';
import { ExclamationIcon } from '@heroicons/react/outline';
import { colors } from '@src/css_config/colorConstants.js';
import { makeStyles } from '@material-ui/core/styles';
import DownloadIcon from '../images/download.svg';
import { ReactComponent as DeleteIcon } from '../images/delete-icon.svg';
import { ReactComponent as EditIcon } from '../images/edit-icon.svg';
import { ReactComponent as ViewIcon } from '../images/view-icon.svg';
import CommonButton from '@src/common/button/Button';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    letterSpacing: '0px',
    color: `${colors.text_white}`,
    fontSize: '11px',
    opacity: 1,
  },
  resizedButton: {
    fontSize: '18px',
    width: '50px',
    height: '29px',
    borderRadius: '5px',
    textTransform: 'capitalize',
    '&:hover': {
      color: `${colors.text_white}`,
      backgroundColor: `${colors.button_blue_50}`,
      border: `2px solid ${colors.button_blue_50}`,
    },
  },
}));
export default function Snippet(props) {
  let [variablesData, setVariablesData] = useState([]);
  let [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);
  let [userDetails, setUserDetails] = useState({});
  const [pageIndexValue, setPageIndex] = React.useState(0);
  let [isLoading, setIsLoading] = useState(false);
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [snippetAppear, setSnippetAppear] = useState('');
  const [alertAdd, setAlertAdd] = useState(false);
  const [openVariableDeleteModal, setOpenVariableDeleteModal] = useState(false);
  const [deleteButtonClick, setDeleteButtonClick] = useState(false);
  let [editVariableData, setEditVariableData] = useState(null);
  const [openEditVariable, setOpenEditVariable] = useState(false);
  let [reload, setReload] = useState(true);
  let [editPopUpOpen, setEditPopUpOpen] = useState(null);
  let [updateVariableData, setUpdateVariableData] = useState(null);
  let [mainPageLoad, setMainPageLoad] = useState(true);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [aceValue, setAceValue] = useState('');
  const [snippetDetails, setSnippetDetails] = useState(null);
  const childRef = useRef();
  const search = window.location.search;
  const projectDetails = JSON.parse(localStorage.getItem('selected-project'));
  const projectId = projectDetails.id;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');

  useEffect(() => {
    setMainPageLoad(true);
    getTablevalue();
    setSnippetAppear('');
  }, []);

  useEffect(() => {
    if (reload && mainPageLoad) {
      setMainPageLoad(true);
      getTablevalue();
    }
    setReload(false);
  }, [reload, mainPageLoad]);

  const customStylesProject = {
    content: {
      position: 'absolute',
      width: '100%',
      bottom: '5%',
      overflow: 'hidden',
      padding: '0',
      display: 'flex',
      justifyContent: 'end',
    },
  };

  const reloadVariables = (val) => {
    setReload(val);
  };

  const editPopUpOpened = (variable) => {
    setEditPopUpOpen('Edit');
    setUpdateVariableData(variable);
    setMainPageLoad(false);
  };

  const doLogin = () => {
    setSnippetAppear('Add');
    setOpenUserDetailsModal(false);
  };

  const getUserDetails = async (key) => {
    if (key) {
      let res = await getSingleUserRequest(key);
      if (res.data.responseCode === 200 && res.data.responseObject) {
        setUserDetails(res.data.responseObject);
        setOpenUserDetailsModal(true);
      }
    }
  };

  const closeUserDetailsModal = (value) => {
    setOpenUserDetailsModal(value);
    setOpenEditVariable(false);
    setUserDetails({});
  };

  const getPageCall = (val, nameofSnippet) => {
    if (val === true) {
      setReload(true);
      setSnippetAppear('');
      setAlertAdd(true);
      props.MyAlert.success(`${nameofSnippet} Snippet added SuccessFully`);
    }
  };

  const { isEditable, hasFullAccess } = useMemo(
    () => ({
      isEditable: window.permission?.isEditAccess('testDevelopment'),
      hasFullAccess: window.permission?.isFullAccess('testDevelopment'),
    }),
    []
  );

  const getPageCallUpdate = (val, nameofSnippet) => {
    if (val === true) {
      setReload(true);
      setEditPopUpOpen('');
      setAlertAdd(true);
      props.MyAlert.success(`${nameofSnippet} Snippet Updated SuccessFully`);
    }
  };

  const backCallOfMainPageFromEdit = (val) => {
    if (val === true) {
      setReload(true);
      setEditPopUpOpen('');
      setMainPageLoad(true);
    } else {
      setMainPageLoad(false);
    }
  };

  const backCallOfMainPage = (val) => {
    if (val === true) {
      setReload(true);
      setSnippetAppear('');
      setMainPageLoad(true);
    } else {
      setMainPageLoad(false);
    }
  };

  const restrictOfIssue = () => {
    setMainPageLoad(false);
  };

  const deleteVariable = (varData) => {
    setDeleteButtonClick(true);
    setOpenVariableDeleteModal(true);
    setEditVariableData(varData);
  };

  const deleteDataFromTable = async () => {
    let data = editVariableData;
    try {
      const response = await deleteSnippetVariable(data.id);
      if (response.data.success !== '' || null) {
        setReload(true);
        reloadVariables(true);
        props.MyAlert.success(`${data.name} variable deleted successfully`);
        setDeleteButtonClick(false);
        setOpenVariableDeleteModal(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCodeValue = (code) => {
    setAceValue(code);
  };
  const onSnippetDetails = (variable) => {
    setSnippetDetails(variable);
  };

  let menuData = (variable) => {
    let findDefaultSnippet = variable.hasOwnProperty('defaultSnippet');
    if (findDefaultSnippet === true) {
      return (
        <div className="actionIcons flex justify-start items-center -ml-2">
          {isEditable && (
            <>
              <button disabled className={`opacity-25 text-blue-700 cursor-pointer float-left  text-base mr-2 `}>
                <CustomTooltip title="Default snippet cannot be edited">
                  <span className="EditIcon-in-snippet-list-span">
                    <EditIcon className="EditIcon-in-snippet-list h-4 w-4" />
                  </span>
                </CustomTooltip>
              </button>
              {hasFullAccess && (
                <button disabled className={`opacity-25 text-blue-700 cursor-pointer float-left  text-base mr-2`}>
                  <CustomTooltip title="Default snippet cannot be deleted">
                    <DeleteIcon className="DeleteIcon-in-snippet-list" />
                  </CustomTooltip>
                </button>
              )}
            </>
          )}
          <Menu as="div" className="relative inline-block text-left mt-px">
            {({ open }) => (
              <>
                <div className="viewIcon-in-snippet-list-header">
                  <Menu.Button className="bg-gray-100 rounded-full flex items-center  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white">
                    <span className="sr-only">Open options</span>
                    <CustomTooltip title="View">
                      <span className="snippet-edit-icon">
                        <ViewIcon className="ViewIcon-in-snippet-list h-5 w-5 configmoreIcon" />
                      </span>
                    </CustomTooltip>
                  </Menu.Button>
                </div>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="z-50 origin-top-right absolute right-0 mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <>
                            <button
                              onClick={() => {
                                setShowDetailsModal(true);
                                getUserDetails(active.userId);
                                onSnippetDetails(variable);
                              }}
                              className={
                                'block px-4 py-2 fontPoppinsRegularMd w-full text-left cursor-pointer hover-active-details hover:bg-gray-200'
                              }
                            >
                              Details
                            </button>
                          </>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      );
    } else if (findDefaultSnippet === false) {
      return (
        <div className="actionIcons flex justify-start items-center -ml-2">
          {isEditable && (
            <>
              <CustomTooltip title="Edit">
                <button
                  className={`text-blue-700 cursor-pointer float-left  text-base mr-2 EditIcon-in-snippet-list-span`}
                  onClick={() => {
                    editPopUpOpened(variable);
                  }}
                >
                  <span>
                    <EditIcon className="EditIcon-in-snippet-list h-4 w-4 " />
                  </span>
                </button>
              </CustomTooltip>
              {hasFullAccess && (
                <button
                  className={`text-blue-700 cursor-pointer float-left  text-base mr-2 snippet-delete-icon`}
                  onClick={() => {
                    deleteVariable(variable);
                  }}
                >
                  <CustomTooltip title="Delete">
                    <DeleteIcon className="DeleteIcon-in-snippet-list" />
                  </CustomTooltip>
                </button>
              )}
            </>
          )}
          <Menu as="div" className="relative inline-block text-left mt-px">
            {({ open }) => (
              <>
                <div className="viewIcon-in-snippet-list-header">
                  <Menu.Button className="bg-gray-100 rounded-full flex items-center  focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-white">
                    <span className="sr-only">Open options</span>
                    <CustomTooltip title="View">
                      <span className="snippet-edit-icon">
                        <ViewIcon className="ViewIcon-in-snippet-list h-5 w-5 configmoreIcon" />
                      </span>
                    </CustomTooltip>
                  </Menu.Button>
                </div>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <>
                            <button
                              disabled={false}
                              className={
                                'block px-4 py-2 fontPoppinsRegularMd w-full text-left cursor-pointer hover-active-details hover:bg-gray-200'
                              }
                              onClick={() => {
                                setShowDetailsModal(true);
                                onSnippetDetails(variable);
                              }}
                            >
                              Details
                            </button>
                          </>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      );
    }
  };

  const getTablevalue = async () => {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    setIsLoading(true);
    let varData = [];
    let res = await getAllSnippet(projectId, scriptId, moduleId);
    stopProgress();
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 10,
    });
    let finalResult = res.data.success;
    if (finalResult && finalResult?.length > 0) {
      finalResult.map((ele) =>
        varData.push({
          name: (
            <CustomTooltip responseTooltip={true} title={<span>{ele.name}</span>} placement="bottom-start">
              <span className="text-black  fontPoppinsRegularSm  w-52 block overflow-hidden text-ellipsis truncate whitespace-nowrap">
                {ele.name}
              </span>
            </CustomTooltip>
          ),
          state: (
            <div className="snippet-page-state">
              <button className="state-button py-0 px-1.5 rounded-l fontPoppinsRegularSm text-blue-500">New</button>
              <button className="state-active py-0 px-1 rounded-l fontPoppinsRegularSm">Commit</button>
              <button className="state-active py-0 px-1 rounded-l fontPoppinsRegularSm">Publish</button>
            </div>
          ),
          version: <span className="text-black fontPoppinsRegularSm"> {'1.0'}</span>,
          modifiedBy: ele.modifiedBy ? (
            <span
              onClick={() => {
                getUserDetails(ele.userId);
              }}
              className="fontPoppinsRegularSm"
            >
              {ele.modifiedBy}
            </span>
          ) : (
            <span className="fontPoppinsRegularMd">--</span>
          ),
          actions: menuData(ele),
        })
      );
      setVariablesData(varData);
    }

    if (res.data.success === 7 * pageIndexValue && pageIndexValue !== 0) {
      setPageIndex(pageIndexValue - 1);
    }
    setIsLoading(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Description',
        accessor: 'name',
        width: '20%',
      },
      {
        Header: 'State',
        accessor: 'state',
        width: '15%',
      },
      {
        Header: 'Version',
        accessor: 'version',
        width: '10%',
      },
      {
        Header: 'Modified By',
        accessor: 'modifiedBy',
        width: '15%',
      },
      {
        Header: 'Action',
        accessor: 'actions',
        width: '10%',
      },
    ],
    []
  );

  const data = useMemo(() => variablesData, [variablesData]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data,
  });
  const date = new Date(snippetDetails?.createdDate);
  const m_date = new Date(snippetDetails?.modifiedDate);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;
  const m_day = m_date.getDate().toString().padStart(2, '0');
  const m_month = (m_date.getMonth() + 1).toString().padStart(2, '0');
  const m_year = m_date.getFullYear();
  const m_hours = m_date.getHours().toString().padStart(2, '0');
  const m_minutes = m_date.getMinutes().toString().padStart(2, '0');
  const m_formattedDate =
    m_day && m_month && m_year && m_hours && m_minutes ? `${m_day}-${m_month}-${m_year} ${m_hours}:${m_minutes}` : '--';
  const { name, defaultSnippet, createdDate, createdBy, modifiedBy, modifiedDate } = snippetDetails || 'unknown';
  const detailsData = {
    Description: name,
    '': null,
    'Created On': defaultSnippet ? createdDate : formattedDate || '--',
    'Created By': createdBy,
    'Modified By': modifiedBy || '--',
    'Modified On': modifiedDate ? modifiedDate : '--',
  };
  return (
    <>
      <div className="bg-white" />
      {editPopUpOpen !== 'Edit' && snippetAppear !== 'Add' && (
        <div className=" flex items-center w-full bg-white pt-1">
          <label className="fontPoppinsSemiboldSm flex-auto text-left mr-4 -ml-2 pl-6 text-gray-800">
            Snippet Lists
          </label>
          {isEditable && (
            <>
              <div className="flex relative flex-auto items-center">
                <Tooltip title="Search Snippets" placement="top">
                  <div className="absolute right-0">
                    <img
                      className="btn-right mr-4 right-0 snippet-search-svg-icon hidden"
                      src={DownloadIcon}
                      alt="download img"
                    />
                  </div>
                </Tooltip>
              </div>
              {/* Commented for the future use */}
              {/* <div className="pr-4 flex items-end justify-end">
                <div className="flex flex-row ">
                  <CommonButton id="sendId" btnType="primary" onClick={doLogin} label="+ Snippet" />
                </div>
              </div> */}
            </>
          )}
        </div>
      )}
      <div className="content_area_flow_snippet overflow-auto snippet-main-div-height border-4 border-white">
        <Modal
          isOpen={showDetailsModal}
          className="modal-dialog snippet-dialog"
          style={{ content: customStylesProject.content }}
          onRequestClose={() => {
            props.closeModal(false);
            setShowDetailsModal(false);
          }}
        >
          <div className="modal-container overflow-hidden snippet-container h-screen rounded-2xl">
            <div className="snippet-header">
              <h2 className="snippet-title fontPoppinsMediumLg">Snippet Details</h2>
              <IconButton
                color="primary"
                component="span"
                className="close"
                onClick={() => {
                  setShowDetailsModal(false);
                }}
              >
                <CloseIcon className="relative text-white snippet-details-close-icon" />
              </IconButton>
            </div>
            <div className="modal-body bg-white" id="journal-scroll">
              <div className="grid grid-cols-2 gap-y-4 ml-0 mt-3 pt-4 pb-4 pl-2 pr-2 -m-1.5">
                {Object.keys(detailsData).map((key) => {
                  return (
                    <div className={`${key === 'Modified By' || key === 'Modified On' ? 'mt-3' : 'mt-0'}`}>
                      <label className="details-key-style-common block fontPoppinsMediumSm"> {key}</label>
                      <label
                        className="details-data-style-common min-length-of-snippet fontPoppinsRegularSm"
                        title={key === 'Description' && detailsData[key]?.length > 22 && detailsData[key]}
                      >
                        {detailsData[key]}
                      </label>
                    </div>
                  );
                })}
              </div>

              <div className="editor-height-in-snippet-details" id="journal-scroll">
                <div
                  className="console_output_container_Response fontPoppinsRegularMd overflow-y-auto shadow"
                  id="journal-scroll"
                >
                  <Snippet_Editor_Update
                    ref={childRef}
                    getCodeValue={getCodeValue}
                    valueofAce={
                      snippetDetails?.customSnippetDefinition
                        ? snippetDetails?.customSnippetDefinition
                        : snippetDetails?.defaultSnippet
                    }
                    snippetPopUpDetails={'snippetPopUpDetails'}
                  />
                </div>
              </div>
            </div>
            <div className="snippet-footer modal-footer bg-f5f6fc">
              <button
                className="cancel-button-common mr-1 fontMontserratMediumXs hover:bg-gray-500 hover:text-white snippet-details-cancel-btn"
                onClick={() => {
                  setShowDetailsModal(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
        {editPopUpOpen === 'Edit' ? (
          <Snippet_Update
            MyAlert={props.MyAlert}
            projectId={projectId}
            moduleId={moduleId}
            scriptId={scriptId}
            restrictOfIssue={restrictOfIssue}
            getPageCallUpdate={getPageCallUpdate}
            backCallOfMainPageFromEdit={backCallOfMainPageFromEdit}
            updateVariableData={updateVariableData}
          />
        ) : (
          ''
        )}
        {deleteButtonClick === true ? (
          <Transition.Root show={openVariableDeleteModal} as={Fragment}>
            <Dialog
              as="div"
              static
              className="fixed  inset-0 overflow-y-auto brezz"
              open={openVariableDeleteModal}
              onClose={() => {}}
            >
              <div className="flex  items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity " />
                </Transition.Child>

                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="statuschange inline-block marginupper align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle mt-52">
                    <div className="inline-block">
                      <div className="flex flex-start ml-2">
                        <span className="pt-5 pl-3">
                          <ExclamationIcon className="h-6 w-6 text-yellow-600 " />
                        </span>
                        <Dialog.Title as="h3" className="text-sm leading-6 ml-4 pt-3 font-small text-gray-900">
                          <span className="font-bold block fontPoppinsSemiboldMd">Warning </span>
                          <span className="text-sm fontPoppinsRegularMd ">Delete </span>
                        </Dialog.Title>
                      </div>
                    </div>

                    <hr className="line mt-px" />
                    <div className="text-sm font-sans mt-3 text-gray-500 ml-16 mr-8">
                      <p className="fontPoppinsRegularMd">
                        {' '}
                        Your snippet data will be lost. Are you sure you want to delete {editVariableData?.name}{' '}
                        snippet?{' '}
                      </p>
                      <p className="mt-2 fontPoppinsRegularMd"> How do you want to proceed?</p>
                    </div>
                    <hr className="line mt-3" />
                    <div className="pb-2 flex flex-row float-right">
                      <CommonButton
                        className="mt-3 ml-4 mr-4"
                        btnType="secondary"
                        onClick={deleteDataFromTable}
                        label="Delete"
                      />
                      <CommonButton
                        className="mt-3 ml-1 mr-4"
                        btnType="primary"
                        onClick={() => {
                          setDeleteButtonClick(false);
                          setOpenVariableDeleteModal(false);
                        }}
                        label="Cancel"
                      />
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        ) : (
          ''
        )}
        {openUserDetailsModal ? <UserDetailsModal data={userDetails} closeModal={closeUserDetailsModal} /> : null}

        {snippetAppear === 'Add' ? (
          <Snippet_Create
            projectId={projectId}
            moduleId={moduleId}
            scriptId={scriptId}
            restrictOfIssue={restrictOfIssue}
            reload={reloadVariables}
            MyAlert={props.MyAlert}
            getPageCall={getPageCall}
            backCallOfMainPage={backCallOfMainPage}
          />
        ) : (
          ''
        )}
        {mainPageLoad ? (
          <div className="snippet-main-page-height">
            <div className="overflow-auto snippet_div_height" id="journal-scroll">
              {!isLoading ? (
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className="text-sm h-8 ml-4 sticky top-0 zIndex-1 snippet-list-header fontPoppinsSemiboldSm"
                      >
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps()}
                            width={column.width}
                            className="pl-5 text-left fontPoppinsSemiboldSm select-none text-gray-800"
                          >
                            {column.render('Header')}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()} className="fontPoppinsRegularMd">
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          className="configHover h-10 content-start select-none bg-white border-solid border-f0f3f9"
                        >
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="p-0 pl-5 text-left select-none fontPoppinsRegularSm"
                              >
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <CommonLoader />
              )}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
}

import React, { useState } from 'react';
import SearchBar from '@src/pages/analytics/common/search-bar';
import { usePagination, useTable, useGlobalFilter } from 'react-table';
import { ReactComponent as ShrinkIcon } from '@assets/analytics/ShrinkIcon.svg';
import './common_table_style.scss';
import no_data_info_img from '@assets/no_data_info_img.png';
import syncIcon from '@assets/syncIcon.svg';

const CommonTableWithSearch = (props) => {
  const [openSearch, setOpenSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, state, setGlobalFilter } = useTable(
    {
      columns: props?.columns,
      data: props?.data || [],
    },
    useGlobalFilter,
    usePagination
  );

  const { globalFilter } = state;

  let handleSearchClose = () => {
    setOpenSearch(false);
    setGlobalFilter('');
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };
  return (
    <div className="col-span-12 ">
      <div className="col-span-12 sub_header_style pl-3.5">
        {props.header}
        <span className="float-right ">
          <span className="flex justify-center  gap-1 ">
            <SearchBar
              open={openSearch}
              searchValue={globalFilter || ''}
              handleSearch={(e) => {
                setGlobalFilter(e.target.value);
              }}
              onSearchClose={handleSearchClose}
              handleSearchOpen={handleSearchOpen}
            />
            <span className="expandIconContainer mr-2" onClick={handleClose}>
              <img src={syncIcon} alt="Sync" width="24px" height="24px" onClick={props?.onSyncBtnClick} />
            </span>
          </span>
        </span>
      </div>
      <div className={'col-span-12 px-3  ' + props?.compSpecificClassName} id="journal-scroll">
        <table {...getTableProps()} className="col-span-12">
          <thead className="sticky-table-header">
            {headerGroups?.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="fontPoppinsSemiboldSm h-8 ml-4 border-b">
                {headerGroup.headers.map((column) => (
                  <th
                    width={column.width}
                    {...column.getHeaderProps()}
                    className="text-left uppercase table_header_text_style"
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows?.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className=" h-8 border-b show-action-style">
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className=" text-left table_content_text_style pl-3 "
                        style={{ width: cell?.column?.width }}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {!props?.data?.length && (
          <div className={props?.noDataClassStyle || 'mt-20 ml-72'}>
            <img src={no_data_info_img} alt="No Data Available" width="162px" height="189px" className="ml-5" />
            <span className="no_data_msg_style">{props?.message}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CommonTableWithSearch;

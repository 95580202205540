import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import '@pages/licenses/styles/license-alert-popup.scss';
import { ReactComponent as YellowAlert } from '@assets/svg-imports/yellow_alert.svg';
import { useHistory, useLocation } from 'react-router-dom';

function CancelCreateTemp(props) {
  const history = useHistory();
  const location = useLocation();
  const dmode = new URLSearchParams(location.search).get('dmode');
  return (
    <>
      <Modal
        open={props.openCancelCreateTemp}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="focus:outline-none outline-none"
      >
        <Box className="changeLicenseBoxModal focus:outline-none outline-none">
          <div className="headerLicenseChange">
            <div className="successImg">
              <YellowAlert className="svgImgStyle" />
            </div>
            <div className="mt-8">
              <p className="svgContentYellow fontPoppinsMediumPageSubHeader">
                Data Loss! <span className="text-black">Alert</span>
              </p>
              <br />
              <p className="fontPoppinsRegularLg ml-7 -mt-3">
                Your Data will be lost. Are you sure you want to cancel creating the template ?<br />
                How do you want to proceed ?
              </p>
            </div>
          </div>
          <div className="footerBlue flex ">
            <button
              className="buttonStyleForChangeLicense cursor-pointer fontPoppinsRegularMd"
              onClick={() => {
                props.handleCloseForCancelCreateTemp();
              }}
            >
              Cancel
            </button>
            <button
              className="primaryBtnStyleForBlue cursor-pointer fontPoppinsRegularMd"
              onClick={() => {
                dmode === 'create'
                  ? history.push(`/configuration/template/defecttemplate/defectdetails`)
                  : history.push(`/configuration/template`);
              }}
            >
              Continue
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default CancelCreateTemp;

import React, { forwardRef, useRef } from 'react';
import { useTable } from 'react-table';
import '@pages/common/common_table_with_search/common_table_style.scss';
import { isEmptyValue } from '@src/util/common_utils';
import DataNotAvailable from '@src/pages/common/Table/DataNotAvailable';
import { noDataMessage } from '@src/common/ui-constants';
import cx from 'classnames';
import styles from './table.module.scss';
import { getScrollPosition } from '@pages/analytics/common/util';

const CommonTable = forwardRef((props, ref) => {
  const {
    data,
    columns,
    setScrollPosition,
    compSpecificClassName,
    noDataContent,
    noDataMessage: propsNoDataMessage,
    isLoading,
  } = props;
  const tableDefaultRef = useRef(null);
  const outerDivClasses = compSpecificClassName ? compSpecificClassName : styles['table-height'];
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data: data || [],
  });
  return (
    <div
      className={cx('overflow-scroll', outerDivClasses)}
      id="journal-scroll"
      ref={ref || tableDefaultRef}
      onScroll={() => getScrollPosition(ref, setScrollPosition)}
    >
      <table {...getTableProps()}>
        <thead className={styles['table-header']}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="top-0 shadow-sm">
              {headerGroup.headers.map((column, colIndex) => (
                <th
                  {...column.getHeaderProps()}
                  width={column.width}
                  className={`${column.allignRight ? 'text-right pr-2' : 'text-left'} mx-2 pl-2`}
                >
                  <span>{column.render('Header')}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className={styles['table-body']}>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={cx('project-row', styles['table-row'])}>
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      width={columns[cellIndex].width}
                      className={`p-0 ${columns[cellIndex].allignRight ? 'text-right pr-2' : 'text-left pl-2'}`}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {isEmptyValue(data) &&
        !isLoading &&
        (noDataContent ? (
          noDataContent
        ) : (
          <DataNotAvailable className="mt-16" message={propsNoDataMessage || noDataMessage.noResultFound} />
        ))}
    </div>
  );
});

export default CommonTable;

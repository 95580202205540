import React, { createContext, useCallback, useRef, useState } from 'react';
import { Toast } from 'fireflink-ui';
import { TOAST_CONSTANTS } from '@src/common/ui-constants';
export const ToasterContainerContext = createContext();
const ToasterContainer = ({ children }) => {
  const toastTimeout = useRef();
  const [toasterProps, setToasterProps] = useState({
    isOpen: false,
    message: '',
    variant: '',
  });
  const closeIconClickHandler = useCallback(() => {
    clearTimeout(toastTimeout.current);
    setToasterProps({
      isOpen: false,
      message: '',
      variant: '',
    });
  }, []);
  const getToastTitle = (variant) => {
    switch (variant) {
      case TOAST_CONSTANTS.VARIANTS.SUCCESS:
        return TOAST_CONSTANTS.TITLE.SUCCESS;
      case TOAST_CONSTANTS.VARIANTS.WARNING:
        return TOAST_CONSTANTS.TITLE.WARNING;
      case TOAST_CONSTANTS.VARIANTS.INFO:
        return TOAST_CONSTANTS.TITLE.INFO;
      case TOAST_CONSTANTS.VARIANTS.DANGER:
        return TOAST_CONSTANTS.TITLE.ERROR;
      default:
        return '';
    }
  };
  const toastPropsHandler = ({ variant, message }) => {
    clearTimeout(toastTimeout.current);
    setToasterProps({
      isOpen: true,
      message: message,
      variant: variant,
    });
    toastTimeout.current = setTimeout(closeIconClickHandler, TOAST_CONSTANTS.CLOSE_DELAY);
  };
  return (
    <ToasterContainerContext.Provider value={{ toastPropsHandler }}>
      {toasterProps.isOpen && (
        <Toast
          isOpen={toasterProps.isOpen}
          displayPosition="bottom-right"
          variant={toasterProps.variant}
          toastTitle={getToastTitle(toasterProps.variant)}
          toastMessage={toasterProps.message}
          onCancelClick={closeIconClickHandler}
        />
      )}
      {children}
    </ToasterContainerContext.Provider>
  );
};
export default ToasterContainer;

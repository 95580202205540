import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';
import { Fragment } from 'react';
import { usePagination, useTable } from 'react-table';
import Breadcrumbs from '../../common/breadcrumbs';
import { Menu, Transition } from '@headlessui/react';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import UploadLibrary from './UploadLibrary';
import { Tooltip } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { deleteextFileReq, getLibFilesReq } from '../../../api/api_services';
import './ExternalLibrary.scss';
import { useAlert } from '../../common/alert_service/useAlert';

const ConfigExtLibrary = (props) => {
  const [showImportJar, setShowImportJar] = useState(false);
  const { AlertContatiner, MyAlert } = useAlert();
  let project = JSON.parse(localStorage.getItem('selected-project'));

  const closeModal = (val) => {
    if (showImportJar) {
      setShowImportJar(false);
    }
  };

  // let async getExtLibrariesData = ()=>{

  // }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const contextMenuVariableTabContent = [
    {
      name: 'Details',
    },
    {
      name: 'History',
    },
  ];

  let menuData = (fileData) => {
    return (
      <div className="actionIcons">
        {global.permission.isEditAccess('repository') && (
          <span>
            {/* <EditOutlinedIcon className="float-left blue configmoreIcon mr-4"  /> */}
            {/* <EditOutlinedIcon className="float-left blue configmoreIcon mr-4" onClick={()=>{}} /> */}
            {hasFullAccess && (
              <button
                className={`float-left blue configmoreIcon pt-0.5 mr-4 cursor-pointer`}
                onClick={() => {
                  deleteExtLib(fileData);
                  // deletedragedFile(index,fileData);
                }}
              >
                <Tooltip title="Delete" placement="top">
                  <DeleteOutlinedIcon className="blue mt-1" />
                </Tooltip>
              </button>
            )}
          </span>
        )}
        <Menu as="div" className="relative inline-block text-left">
          {({ open }) => (
            <>
              <div>
                <Menu.Button className="bg-gray-100 rounded-full flex items-center  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 focus:ring-indigo-500">
                  <span className="sr-only">Open options</span>
                  {/* <MoreVertIcon className="float-left edit-style text-blue-700"/> */}
                  <DotsVerticalIcon className="h-5 w-5 mt-2 configmoreIcon" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div className="py-1">
                    {contextMenuVariableTabContent.map((data) => (
                      <Menu.Item>
                        {({ active }) => (
                          <label
                            onClick={(event) => {
                              event.preventDefault();
                              if (data.name === 'Details') {
                              }
                            }}
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-600' : 'text-gray-600',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            {data.name}
                          </label>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    );
  };

  let [externalLibrariesData, setExternalLibrariesData] = useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: <span className="pl-4">Jar Name</span>,
        accessor: 'jarName',
        width: '30%',
      },
      {
        Header: <span className="float-left">Uploaded By</span>,
        accessor: 'uploadedBy',
      },
      {
        Header: '	Uploaded On',
        accessor: 'uploadedOn',
      },
      {
        Header: 'Actions',
        accessor: 'actions',
      },
    ],
    []
  );

  let deleteExtLib = async (fileData) => {
    try {
      let response = await deleteextFileReq(fileData?.id, project?.id);
      console.log(response);
      if (response?.data?.responseCode === 200 && response?.data?.status === 'SUCCESS') {
        MyAlert.success(`${fileData?.name} deleted successfully`);
        getExtLibdata();
      }
    } catch (err) {
      console.log(err);
    }
  };

  let getExtLibdata = async () => {
    try {
      let response = await getLibFilesReq(project?.id);
      let rawData = [];
      let responseObject = response?.data?.responseObject;
      responseObject?.map((filedata, index) => {
        rawData.push({
          jarName: (
            <div
              className="pl-4 text-blue-700 cursor-pointer extlibPathTrimmer"
              title={filedata?.name ? filedata?.name : '--'}
            >
              {filedata?.name}
            </div>
          ),
          uploadedBy: filedata?.createdByUname,
          uploadedOn: filedata?.createdOn,
          id: filedata?.id,
          actions: menuData(filedata),
        });
      });
      setExternalLibrariesData(rawData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (externalLibrariesData.length === 10 * pageIndexValue && pageIndexValue != 0) {
      setPageIndex(pageIndexValue - 1);
    }
  }, [externalLibrariesData]);

  const [pageIndexValue, setPageIndex] = React.useState(0);

  const data = React.useMemo(() => externalLibrariesData, [externalLibrariesData]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: pageIndexValue, pageSize: 10 },
    },
    usePagination
  );

  let activeTab = props.location.pathname.split('/')[3];

  const [tabsList, setTabsList] = useState([
    {
      name: 'External Libraries',
      pathUrl: `/configuration/Externallibraries/ExternalLibraries`,
      selectedName: 'ExternalLibraries',
    },

    {
      name: 'History',
      pathUrl: `/configuration/Externallibraries/history`,
      selectedName: 'history',
    },
  ]);
  const renderTabs = () => {
    return (
      <div>
        <ul className="navBar text-blue-600 flex flex-row primary-tab">
          {tabsList.map((tab, index) => {
            return (
              <li key={tab.name}>
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    props.history.push(tab.pathUrl);
                  }}
                  className={`${activeTab === tab.selectedName ? 'selected_tab_style' : ''}`}
                >
                  {tab.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  useEffect(() => {
    getExtLibdata();
  }, []);

  const { isEditable, hasFullAccess } = React.useMemo(
    () => ({
      isEditable: window.permission?.isEditAccess('configuration'),
      hasFullAccess: window.permission?.isFullAccess('configuration'),
    }),
    []
  );

  return (
    <div className="page-table-body ">
      {renderTabs()}

      <div
        className="content_area_style "
        id="journal-scroll"
        // style={{height:'24.5rem'}}
      >
        <div className="alert-position-style">
          <AlertContatiner></AlertContatiner>
        </div>
        <div className="grid grid-cols-2 mb-4 ">
          <div className=" t_header ml-3 mt-4 configPageHeading">External Libraries</div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginRight: '15px',
            }}
          >
            {isEditable && (
              <button
                onClick={() => {
                  setShowImportJar(true);
                }}
                className="primary-btn mt-4 w-25 h-8 ml-10 mx-2 items-center float-right"
              >
                <span>+ Library</span>
              </button>
            )}
          </div>
        </div>
        <div
          className=" grid grid-cols-3 "
          style={{
            // background: "rgb(240,243,249)",
            display: 'flex',
          }}
        >
          <table {...getTableProps()} className="">
            <thead className="fontPoppinsSemiboldMd">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="text-xs font-semibold	 headerStyle h-12 ml-4">
                  {headerGroup.headers.map((column) => (
                    <th width={column.width} {...column.getHeaderProps()} className="text-left">
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="configdataStyle">
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className={`configHover h-12 content-start `}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={`p-0 text-left ${row.original?.status === 'Disabled' && cell.column.Header !== 'Actions' ? 'disableColor' : null}`}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="pagecountborder ">
          <div className="float-right pr-4">
            <span className="pagecount">
              {pageSize * pageIndex + 1}-{page.length + pageSize * pageIndex + 1 - 1} of {data.length}{' '}
            </span>
            <span className="pageborderleft ml-2 pl-2">
              <button
                onClick={() => {
                  previousPage();
                  setPageIndex(pageIndexValue - 1);
                }}
                disabled={!canPreviousPage}
                className={`${!canPreviousPage ? 'pageArrow' : 'pageArrowEnable'}`}
              >
                {'<'}
              </button>{' '}
              <span className="currentpagecount ml-2 mr-2">
                {' '}
                <span className="pl-2 pr-2">{pageIndex + 1}</span>
              </span>
              <button
                onClick={() => {
                  nextPage();
                  setPageIndex(pageIndexValue + 1);
                }}
                disabled={!canNextPage}
                className={`${!canNextPage ? 'pageArrow' : 'pageArrowEnable'}`}
              >
                {'>'}
              </button>{' '}
            </span>
          </div>
        </div>

        {/* <div className="pagecountborder-set mb-3" id="footer-notfixed">
<div className="float-right pr-4">
  <span className="pagecount">1-5 of 5</span>
  <span className="pageborderleft ml-2 pl-2">
    <button className="pageArrow">{"<"}</button>{" "}
    <span className="currentpagecount ml-2 mr-2">
      {" "}
      <span className="pl-2 pr-2">1</span>
    </span>
    <button className="pageArrow">{">"}</button>{" "}
  </span>
</div>
</div> */}

        {showImportJar ? (
          <UploadLibrary closeModal={closeModal} getExtLibdata={getExtLibdata} project={project} MyAlert={MyAlert} />
        ) : null}
      </div>
    </div>
  );
};

export default withRouter(ConfigExtLibrary);

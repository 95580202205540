import React, { useEffect, useState, useRef } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Modal from 'react-modal';
import IconButton from '@material-ui/core/IconButton';
import { postPageReq, putPageReq } from '@api/api_services';
import { checkPageExistParentPage, REPOSITORY_CONSTANTS } from '@common/ui-constants';
import { TextField, TextareaAutosize } from '@material-ui/core';
import TreeWithRadioButton from '@src/pages/common/table_tree/table-tree-with-radio-button';
import { elementPlatformTypes } from './element.utils';
const PageModal = (props) => {
  const nameFieldRef = useRef(null);
  const treeData = props?.treeData;
  if (treeData[0]?.title === 'Root page') {
    treeData[0].title = 'Root Page';
  } else if (treeData[0]?.title === 'Root screen') {
    treeData[0].title = 'Root Screen';
  }
  const search = window.location.search;
  const id = new URLSearchParams(search).get('id');

  const { children, ...default_node } = props?.treeData[0] || {};
  const [createUpdateCalled, setCreateUpdateCalled] = useState(true);

  const ROOT_PAGE_CONSTANT = props.projectType.toLowerCase() === 'mobile' ? 'Root Screen' : 'Root Page';
  const [executionOrder, setExecutionOrder] = useState(props.nodeToAdd ? findExecutionOrder(props.nodeToAdd) || 1 : 1);
  const [hierarchy, setHierarchy] = useState(props.nodeToAdd ? findHierarchy(props.nodeToAdd) || 1 : 1);

  const [showModal, setShowModal] = useState(true);
  const [selectedPage, setSelectedPage] = useState('');
  const [descCount, setdescCount] = React.useState(0);
  let [noOfRows, setNoOfRows] = useState(1);

  let initialValues;
  function findExecutionOrder(node, rootNode = false) {
    if (rootNode) {
      return node.lastExecutionOrder + 1;
    } else {
      return node.data.lastExecutionOrder + 1;
    }
  }
  function findHierarchy(node) {
    if (node.title.toLowerCase() === 'root module') {
      return 1;
    } else {
      return node.data.hierarchy + 1;
    }
  }
  const label =
    props.projectType === 'Mobile'
      ? 'Screen'
      : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) && props.platForm === 'web'
      ? 'Page'
      : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) &&
        ['Android', 'iOS', 'Ios', 'MobileWeb', 'Mobile'].includes(props.platForm)
      ? 'Screen'
      : 'Page';
  // For single script changes - Sony
  // const label = [props?.projectType?.toLowerCase(), props?.platForm?.toLowerCase()].includes('web') ? 'Page' : "Screen";
  useEffect(() => {
    if (props.mode === 'EDIT') {
      setdescCount(props.data.desc.length);
    }
  }, []);
  if (props.data) {
    initialValues = {
      pageName: props.data.name,
      description: props.data.desc,
      parentPage: props.data.parentName,
    };
  } else if (props?.parentName) {
    initialValues = {
      pageName: '',
      description: '',
      parentPage: props?.parentName,
    };
  } else if (props.treeData[0]?.children?.length > 0) {
    initialValues = {
      pageName: '',
      description: '',
      parentPage: '',
    };
  } else {
    initialValues = {
      pageName: '',
      description: '',
      parentPage: 'Root',
    };
  }
  const treeNodeSelected = ([data]) => {
    if (!data) {
      formikDetails.setFieldValue('parentPage', '');
    } else if (data && data.data.isRootNode) {
      setExecutionOrder(findExecutionOrder(data.data, true));
      setSelectedPage({ data: data.data, ...data.data });
      formikDetails.setFieldValue('parentPage', data.data.title);
    } else if (data && data.data.key) {
      setExecutionOrder(findExecutionOrder(data.node));
      setHierarchy(findHierarchy(data.node));
      setSelectedPage({ ...data.node, data: data.data });
      formikDetails.setFieldValue('parentPage', data.node.title);
    }
    nameFieldRef?.current?.focus();
  };

  const checkDoesNameContainPageOrScreenName = (pageName) => {
    if (props.projectType === 'Mobile') {
      return pageName.toUpperCase().includes('screen');
    } else if (
      props.projectType === 'Web' ||
      (['Web & Mobile', 'Salesforce'].includes(props.projectType) && props.platForm === 'Web')
    ) {
      return pageName.toUpperCase().includes('page');
    } else if (
      ['Web & Mobile', 'Salesforce'].includes(props.projectType) &&
      elementPlatformTypes.includes(props.platForm)
    ) {
      return pageName.toUpperCase().includes('screen');
    } else {
      return false;
    }
  };
  async function createContainerReq(reqBody) {
    let response;
    try {
      response = await postPageReq(reqBody);
      if (response.data.responseObject && response.data.responseCode === 201) {
        props.closeModal(false);
        if (props.mode === 'ADD') {
          props.reloadTree(true);
        } else {
          const updateData = {
            ver: response.data.responseObject.ver,
            subPageCount: response.data.responseObject.subPageCount,
            title: response.data.responseObject.name,
            modifiedByUname: response.data.responseObject.modifiedByUname,
            createdByUname: response.data.responseObject.createdByUname,
            parentId: response.data.responseObject.parentId,
            modifiedOn: response.data.responseObject.modifiedOn,
            parentName: response.data.responseObject.parentName,
            folder: response.data.responseObject.folder,
            elementCount: response.data.responseObject.elementCount,
            modifiedBy: response.data.responseObject.modifiedBy,
            state: response.data.responseObject.state,
            key: response.data.responseObject.id,
          };
          props.updatedValue(updateData, 'folder');
          props.reloadTree(true);
        }
        let exist = checkDoesNameContainPageOrScreenName(reqBody.name);
        props.MyAlert.success(
          `${reqBody.name.length > 22 ? reqBody.name.substring(0, 22) + '...' : reqBody.name} ${
            exist
              ? ''
              : props.projectType === 'Mobile'
              ? 'Screen'
              : props.projectType === 'Web'
              ? 'Page'
              : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) && props.platForm === 'Web'
              ? 'Page'
              : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) &&
                ['Android', 'iOS', 'Ios', 'MobileWeb', 'Mobile'].includes(props.platForm)
              ? 'Screen'
              : 'Page'
            // For single script changes - Sony
            // : label
          }
          created successfully`
        );
      } else {
        if (response.data.responseCode === 400 && response.data.message.includes('already exists')) {
          formikDetails.setFieldError('pageName', 'Name already exists');
          setCreateUpdateCalled(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function updateContainerReq(reqBody) {
    let response;
    try {
      response = await putPageReq(reqBody.id, reqBody);
      if (response.data.responseObject) {
        props.closeModal(false);
        //this is code is written to maintain the state of tree and passing the update date to tree
        if (id) {
          if (id === reqBody.id) {
            props.reloadTree(true);
          } else {
            const {
              ver,
              subPageCount,
              name: title,
              modifiedByUname,
              createdByUname,
              parentId,
              modifiedOn,
              parentName,
              folder,
              elementCount,
              modifiedBy,
              state,
              id: key,
            } = response.data.responseObject;
            const updateData = {
              ver,
              subPageCount,
              title,
              modifiedByUname,
              createdByUname,
              parentId,
              modifiedOn,
              parentName,
              folder,
              elementCount,
              modifiedBy,
              state,
              key,
            };
            props.updatedValue(updateData, 'folder');
            let exist = checkDoesNameContainPageOrScreenName(reqBody.name);
            props.MyAlert.success(
              `${reqBody.name.length > 22 ? reqBody.name.substring(0, 22) + '...' : reqBody.name} ${
                exist
                  ? ''
                  : props.projectType === 'Mobile'
                  ? 'Screen'
                  : props.projectType === 'Web'
                  ? 'Page'
                  : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) &&
                    props.platForm === 'Web'
                  ? 'Page'
                  : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) &&
                    ['Android', 'iOS', 'Ios', 'MobileWeb', 'Mobile'].includes(props.platForm)
                  ? 'Screen'
                  : 'Page'
                // For single script changes - Sony
                // : label
              } updated successfully`
            );
          }
        } else {
          props.updatedValue(response.data.responseObject, 'folder');
          let exist = checkDoesNameContainPageOrScreenName(reqBody.name);
          props.MyAlert.success(
            `${reqBody.name.length > 22 ? reqBody.name.substring(0, 22) + '...' : reqBody.name} ${
              exist
                ? ''
                : props.projectType === 'Mobile'
                ? 'Screen'
                : props.projectType === 'Web'
                ? 'Page'
                : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) &&
                  props.platForm === 'Web'
                ? 'Page'
                : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) &&
                  ['Android', 'iOS', 'Ios', 'MobileWeb', 'Mobile'].includes(props.platForm)
                ? 'Screen'
                : 'Page'
              // For single script changes - Sony
              // : label
            } updated successfully`
          );
        }
      } else {
        if (response.data.responseCode === 400 && response.data.message.includes('already exists')) {
          formikDetails.setFieldError('pageName', 'Name already exists');
          setCreateUpdateCalled(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  const createPage = () => {
    const values = formikDetails.values;
    let exist = false;
    if (props.treeData[0]?.children?.length > 0) {
      if (props.parentId) {
        exist = checkPageExistParentPage(props.treeData, props.parentId, values.pageName);
      } else if (!selectedPage) {
        exist = checkPageExistParentPage(props.treeData, default_node.key, values.pageName);
      } else {
        exist = checkPageExistParentPage(props.treeData, selectedPage.data.key, values.pageName);
      }
    }
    if (exist) {
      setCreateUpdateCalled(true);
      formikDetails.setFieldError('pageName', 'Name already exists');
    } else {
      let requestBody;
      if (values) {
        requestBody = {
          name: values.pageName,
          desc: values.description,
          executionOrder: executionOrder,
          hierarchy: hierarchy,
        };
        requestBody['elements'] = [];
        requestBody['projectType'] = 'Web';
        if (props.parentId) {
          requestBody['parentId'] = props.parentId;
          requestBody['parentName'] = props.parentName;
        } else if (!selectedPage) {
          requestBody['parentId'] = default_node.key;
          requestBody['parentName'] = default_node.title;
        } else {
          requestBody['parentId'] = selectedPage.data.key;
          requestBody['parentName'] = selectedPage.title;
        }

        if (props.projectType === 'Mobile') {
          requestBody['projectType'] = props.projectType;
          requestBody['platform'] = props.platForm;
        } else if (
          ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) &&
          props.platForm === 'Web'
        ) {
          requestBody['projectType'] = props.projectType;
          requestBody['platform'] = props.platForm;
        } else if (
          ['Web & Mobile', 'Salesforce', 'MS Dynamics'].includes(props?.projectType) &&
          elementPlatformTypes.includes(props.platForm)
        ) {
          requestBody['projectType'] = props.projectType;
          requestBody['platform'] = props.platForm;
        } else {
          requestBody['platform'] = 'Web';
        }
        createContainerReq(requestBody);
      }
    }
  };
  const updatePage = () => {
    const values = formikDetails.values;
    let exist = false;
    let noupdate = false;
    if (values.pageName === props.data.name && values.description === props.data.desc) {
      noupdate = true;
    } else if (values.pageName === props.data.name && values.description !== props.data.desc) {
      exist = false;
    } else if (values.pageName.toLowerCase() === props.data.name.toLowerCase()) {
      exist = false;
    } else if (values.pageName !== props.data.name) {
      exist = checkPageExistParentPage(props.treeData, props.data.parentId, values.pageName);
    }
    if (noupdate) {
      props.closeModal(false);
      props.MyAlert.info(`nothing there  to update`);
    } else if (exist) {
      formikDetails.setFieldError('pageName', 'Name already exists');
      setCreateUpdateCalled(true);
    } else {
      const updatedData = {
        ...props.data,
        name: values.pageName,
        desc: values.description,
      };
      updateContainerReq(updatedData)
        .then(() => {
          props.closeModal(false);
          props.MyAlert.success(
            ['Android', 'iOS', 'Ios', 'MobileWeb', 'Mobile'].includes(props.platForm)
              ? REPOSITORY_CONSTANTS.SCREEN_UPDATE_SUCCESS_MESSAGE_TEXT
              : REPOSITORY_CONSTANTS.PAGE_UPDATE_SUCCESS_MESSAGE_TEXT
          );
          props.reloadTree(true);
        })
        .catch((error) => {
          console.error('Error updating page:', error);
          props.MyAlert.error(REPOSITORY_CONSTANTS.PAGE_UPDATE_FAILURE_MESSAGE_TEXT);
        });
    }
  };

  const validationSchema = yup.object({
    pageName: yup
      .string()
      .required('Name is required')
      .min(3, 'Name must be minimum 3 characters')
      .matches(/.*\S.*/, 'Only whitespaces are not allowed')
      .matches(/^[^\s].+[^\s]$/, 'Space is not allowed in the beginning and at the end'),
    parentPage: yup.string().required(`Parent ${label} is required`),
    description: yup.string(),
  });

  const onSubmit = () => {
    if (
      formikDetails.values.pageName !== '' &&
      formikDetails.values.parentPage !== '' &&
      formikDetails.values.pageName.length >= 2 &&
      formikDetails.errors.pageName !== 'Only whitespaces are not allowed'
    ) {
      if (['ADD', 'ADD_SUB'].includes(props.mode)) {
        if (createUpdateCalled) {
          setCreateUpdateCalled(false);
          createPage();
        }
      } else {
        if (createUpdateCalled) {
          setCreateUpdateCalled(false);
          updatePage();
        }
      }
    }
  };
  const formikDetails = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  let errorExistForParentPage = false;
  if (formikDetails.values.parentPage === '' && formikDetails.touched.parentPage) {
    errorExistForParentPage = true;
  } else {
    errorExistForParentPage = false;
  }

  const eliminateMultipleSpace = (event) => {
    let regexPattern = /\s+/g;
    event.target.value = event.target.value.replace(regexPattern, ' ').replace(/[<>]/g, '');
    formikDetails.handleChange(event);
    formikDetails.handleBlur(event);
  };

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={(e) => {
        if (e.key === 'Escape') {
          setShowModal(false);
          props.closeModal(false);
        }
      }}
      className="p-px modal-dialog"
    >
      <form onSubmit={formikDetails.handleSubmit} className="modal-position">
        <div className="modal-container page_modal_size">
          <div className="modal-header">
            {props.mode === 'ADD' || props.mode === 'ADD_SUB' ? (
              <h2 className="title">
                {' '}
                Create{' '}
                {props.projectType === 'Mobile'
                  ? 'Screen'
                  : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) &&
                    props.platForm === 'Web'
                  ? 'Page'
                  : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) &&
                    ['Android', 'iOS', 'Ios', 'MobileWeb', 'Mobile'].includes(props.platForm)
                  ? 'Screen'
                  : 'Page'}
                {/* For single script changes - Sony                   */}
                {/* {[props?.projectType?.toLowerCase(), props?.platForm?.toLowerCase()].includes('web') ? 'Page' : "Screen"} */}
              </h2>
            ) : (
              <div className="title title-trim fontPoppinsMediumLg" title={props.data.name}>
                Edit{' '}
                {props.projectType === 'Mobile'
                  ? 'Screen'
                  : props.projectType === 'Web'
                  ? 'Page'
                  : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) &&
                    props.platForm === 'Web'
                  ? 'Page'
                  : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) &&
                    ['Android', 'iOS', 'Ios', 'MobileWeb', 'Mobile'].includes(props.platForm)
                  ? 'Screen'
                  : 'Page'}{' '}
                {/* For single script changes - Sony  */}
                {/* {[props?.projectType?.toLowerCase(), props?.platForm?.toLowerCase()].includes('web') ? 'Page' : "Screen"}{" "} */}
                - {props.data.name}
              </div>
            )}
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                props.closeModal(false);
                setShowModal(false);
              }}
            >
              <img
                style={{
                  cursor: 'pointer',
                }}
                src="/assets/images/close_black.svg"
                alt=""
                height="25px"
                width="25px"
              />{' '}
            </IconButton>
          </div>
          <div className="modal-body mx-3" id="journal-scroll">
            <div className="">
              <div className="relative">
                <div className="rs-user-input-container">
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={formikDetails.errors.pageName && formikDetails.touched.pageName}
                    autoComplete="off"
                    className="w-1/2 mui-input-text-field"
                    type="text"
                    name="pageName"
                    id="pageName"
                    placeholder={`Enter ${
                      props.projectType === 'Mobile'
                        ? 'screen'
                        : props.projectType === 'Web'
                        ? 'page'
                        : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) &&
                          props.platForm === 'Web'
                        ? 'page'
                        : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) &&
                          ['Android', 'iOS', 'Ios', 'MobileWeb', 'Mobile'].includes(props.platForm)
                        ? 'screen'
                        : 'page'
                    } name`}
                    // For single script changes - Sony
                    // placeholder={`Enter ${[props?.projectType?.toLowerCase(), props?.platForm?.toLowerCase()].includes('web') ? 'page' : "screen"} name`}
                    label={
                      <>
                        {' '}
                        <span className="text-red-400 mr-1">&#42;</span>Name
                      </>
                    }
                    onBlur={eliminateMultipleSpace}
                    onChange={formikDetails.handleChange}
                    autoFocus={true}
                    inputRef={nameFieldRef}
                    value={formikDetails.values.pageName}
                  />
                </div>
                {formikDetails.errors.pageName && formikDetails.touched.pageName ? (
                  <div className="errorMessage absolute">{formikDetails.errors.pageName}</div>
                ) : null}
              </div>
              <div className="mt-5 w-full">
                <label htmlFor="description" className="input-filed-label-style-common">
                  Description
                </label>
                <TextareaAutosize
                  rowsMax={noOfRows}
                  onBlur={(e) => {
                    setNoOfRows(1);
                    e.target.classList.add('descriptionStyle');
                  }}
                  onFocus={(e) => {
                    setNoOfRows(null);
                    e.target.classList.remove('descriptionStyle');
                  }}
                  id="description"
                  name="description"
                  maxLength="200"
                  className="-mt-1 block w-full rs-input-style-textarea popup-input-bg pl-px  mt-1  border-0 border-b input-field-color descriptionStyle resize-none"
                  onKeyUp={(e) => setdescCount(e.target.value.length)}
                  value={formikDetails.values.description}
                  placeholder={
                    props.mode === 'ADD' || props.mode === 'ADD_SUB'
                      ? 'Your description goes here...'
                      : 'Your description goes here...'
                  }
                  onChange={formikDetails.handleChange}
                />
                <div className="mt-1 text-sm text-gray-500 text-right">{descCount}/200</div>
                {formikDetails.errors.description && formikDetails.touched.description && (
                  <p style={{ color: 'red' }}>{formikDetails.errors.description}</p>
                )}
              </div>
              <div className="mt-2">
                <label
                  htmlFor="parentModule"
                  className={
                    errorExistForParentPage ? 'fontPoppinsRegularMd text-red-500' : 'input-filed-label-style-common'
                  }
                >
                  <span className="text-red-400">&#42;</span>Parent {label}
                </label>
                <div className="popup-input-bg">
                  <TreeWithRadioButton
                    moduleSelection={true}
                    individualTree={id ? true : false}
                    data={treeData ? treeData : []}
                    operation={
                      ['ADD_SUB', 'EDIT'].includes(props.mode) || (!props.treeData[0]?.children?.length && !id)
                        ? 'edit'
                        : null
                    }
                    placeholder={
                      !props.parentId
                        ? props.parentName || ROOT_PAGE_CONSTANT
                        : props.parentId
                        ? props.parentName
                        : 'Search and select parent module'
                    }
                    nodeSelected={treeNodeSelected.bind(this)}
                    hideElements={true}
                    hideElementsBtn={true}
                    buttonLabel={label}
                  />
                </div>
                {formikDetails.errors.parentPage &&
                formikDetails.touched.parentPage &&
                formikDetails.values.parentPage === '' ? (
                  <div className="errorMessage">{formikDetails.errors.parentPage}</div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="gray-btn"
              onClick={() => {
                props.closeModal(false);
                setShowModal(false);
              }}
            >
              Cancel
            </button>
            <button type="submit" className="primary-btn ml-3">
              {props.mode === 'ADD' || props.mode === 'ADD_SUB' ? <span>Create</span> : <span>Update</span>}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default PageModal;

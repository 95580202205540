import { colors } from '@src/css_config/colorConstants';
import { Icon, Select, Tooltip } from 'fireflink-ui';
import React from 'react';
import '../../auth.scss';

const JwtSelectDetails = ({ options, jwtTitle, jwtTooltipTitle, onSelectJwt, selectedOption, contextValue }) => {
  return (
    <>
      <div className="flex">
        <span className="common-auth-star-symbol mr-1 top-1">*</span>
        <div className="flex gap-2 items-center">
          <span className="fontPoppinsRegularSm jwt-select-title">{jwtTitle}</span>
          <Tooltip placement="right" title={jwtTooltipTitle}>
            <span>
              <Icon name="info_grey" width={14} height={14} color={colors.gray} />
            </span>
          </Tooltip>
        </div>
      </div>
      <div>
        <Select
          options={options}
          className="text-left"
          selectedOption={selectedOption}
          onChange={(e) => onSelectJwt(e, contextValue)}
        />
      </div>
    </>
  );
};

export default JwtSelectDetails;

import { Tooltip } from '@material-ui/core';
import { CommonLoader } from '@src/pages/common/common-loader';
import MoreInfoButton from '@src/pages/common/more_info_button';
import TableTree from '@src/pages/common/table_tree/table_tree';
import React, { useEffect, useRef, useState } from 'react';
import { EXECUTION_CONSTANTS, TREE_LABELS } from '@src/common/ui-constants';
import { isEmptyValue } from '@src/util/common_utils';

function ScriptTreeSection({ runSettingsManualObj, setRunSettingsManualObj, runSettingsGlobalObj, ...props }) {
  let [isLoading, setIsLoading] = useState(true);
  let renderTreeModal;
  const [treeData, setTreeData] = useState([]);
  const [allTreeRootData, setAllTreeRootData] = useState([]);
  const [defaultCheckedValue, setDefaultCheckedValue] = useState();
  const [differenceCount, setDifferenceCount] = useState(0);
  const [currentFancytree, setCurrentFancytree] = useState();
  const currentFancytreeRef = useRef();
  currentFancytreeRef.current = currentFancytree;
  let [isExpandAll, setIsExpandAll] = useState(false);
  let selectedModuleAndScript = {};
  let nodeList = [];
  let selectedTreeDataArray = [];
  let allSelectedNodes = [];
  let totalScriptCount = 0;
  const executionType = props?.runSettingsObj?.machines?.executionType;
  const distributionMechanism = props?.runSettingsObj?.machines?.distributionMechanism;

  useEffect(() => {
    if (props?.selectedModulesStatus) {
      setDefaultCheckedValue(
        Object.keys(props?.selectedModulesStatus)?.map(
          (_value) =>
            props?.selectedModulesStatus[_value] === 'selected' && {
              key: 'key',
              value: _value,
            }
        ) || []
      );
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const formattedTree = getFormattedTreeData(props?.suiteObjectMain?.selectedModulesAndScripts[0]?.children);
    setTreeData(formattedTree);
    let rootDetails = props?.suiteObjectMain?.selectedModulesAndScripts[0];
    let root = {
      type: rootDetails?.type,
      subModuleCount: rootDetails?.children[0]?.totalModuleCount,
      title: rootDetails?.title,
      scriptCount: rootDetails?.children[0]?.totalScriptCount,
      key: rootDetails?.key,
      ver: rootDetails?.ver,
      modifiedByUname: rootDetails?.modifiedByUname,
      selected: selectedModuleAndScript[rootDetails?.key] === 'selected',
      partsel: selectedModuleAndScript[rootDetails?.key] === 'partsel',
    };
    setAllTreeRootData(root);
    setIsExpandAll(rootDetails.expanded);
    setIsLoading(false);
  }, [treeData]);

  const getFormattedTreeData = (_treeData) => {
    _treeData?.length &&
      _treeData?.forEach((_treeNode) => {
        if (_treeNode.folder) _treeNode['checkbox'] = true;
      });
    return _treeData;
  };

  const updateSelectedTreeDataArray = () => {
    nodeList = [];
    if (currentFancytreeRef.current) {
      currentFancytreeRef.current.visit(function (node) {
        if (!node.isSelected()) {
          nodeList.push(node);
        }
      });
    }
    if (nodeList.length) {
      selectedModuleAndScript = {};
      nodeList.forEach((_node) => {
        if (selectedTreeDataArray.length) {
          removeUnselectedNodes(_node, selectedTreeDataArray);
        }
      });
    }
  };

  const removeUnselectedNodes = (_node, _selectedTreeDataArray) => {
    const uncheckedNodeIndex = _selectedTreeDataArray.findIndex((_treeNode) => {
      if (_treeNode.node.key === _node.key) {
        return true;
      } else if (_treeNode.node.folder && _treeNode.node.children & _treeNode.node.children.length) {
        return removeUnselectedNodes(_node, _treeNode.node.children);
      }
      return false;
    });
    if (uncheckedNodeIndex > -1) {
      _selectedTreeDataArray.splice(uncheckedNodeIndex, 1);
    }
  };

  const setSelectedTreeDataHandler = (nodes) => {
    if (selectedTreeDataArray.length) {
      nodes.forEach((_node) => {
        const _foundNodeIndex = selectedTreeDataArray.findIndex((_treeNode) => _treeNode.node.key === _node.node.key);
        if (_foundNodeIndex > -1) {
          selectedTreeDataArray[_foundNodeIndex] = _node;
        } else {
          selectedTreeDataArray.push(_node);
        }
      });
    } else {
      selectedTreeDataArray = nodes;
    }
  };

  const onCheckedNodes = (nodes) => {
    updateSelectedTreeDataArray();
    setSelectedTreeDataHandler(nodes);
    selectedModuleAndScript = {};
    totalScriptCount = null;
    allSelectedNodes = [];
    buildTreeData();
  };

  const getCheckboxState = (node) => {
    if (executionType === 'DISTRIBUTE' && distributionMechanism === 'AUTOMATIC') {
      return false;
    } else {
      return node?.checkbox;
    }
  };

  const filterSelectedScripts = (moduleChildren) => {
    let moduleData = [];
    if (moduleChildren) {
      moduleChildren?.forEach((child) => {
        if (child && child?.selected && !child?.folder) {
          let data = {};
          if (child?.data) {
            data = child?.data;
          }
          let treeObject = {
            key: child?.key,
            title: child?.title,
            type: child?.type ? child?.type : 'Script',
          };
          if (child?.folder) {
            treeObject['children'] = child?.children;
            treeObject['folder'] = true;
          }
          selectedModuleAndScript[child.key] = child.selected ? 'selected' : 'partsel';
          const mergedObject = { ...treeObject, ...data };
          moduleData.push(mergedObject);
        } else if (child?.folder && (child?.selected || (!child?.selected && child?.partsel))) {
          moduleData.push(buildModuleObject(child, child?.data));
        }
      });
    }
    return moduleData;
  };

  const buildModuleObject = (moduleData, nodeData) => {
    if (!nodeData) {
      nodeData = {};
    }
    let treeObject = {
      key: moduleData?.key,
      title: moduleData?.title,
      children: filterSelectedScripts(moduleData?.children),
      folder: moduleData?.folder,
      checkbox: getCheckboxState(moduleData),
      type: moduleData?.type ? moduleData?.type : 'Module',
    };
    if (treeObject) {
      let count = 0;
      if (treeObject?.children?.length) {
        treeObject?.children.forEach((obj) => {
          if (obj?.type === 'Script') {
            count++;
          }
        });
      }
      treeObject['selectedScriptCount'] = count;
      if (totalScriptCount) {
        totalScriptCount = totalScriptCount + treeObject?.selectedScriptCount;
      } else {
        totalScriptCount = treeObject?.selectedScriptCount;
      }
    }
    selectedModuleAndScript[moduleData.key] = moduleData.selected ? 'selected' : 'partsel';
    let mergedObject = { ...treeObject, ...nodeData };
    return mergedObject;
  };

  const buildTreeData = () => {
    if (selectedTreeDataArray) {
      selectedTreeDataArray.forEach((selectedNode) => {
        let node = selectedNode?.node;
        let data = selectedNode?.data;
        if (node?.folder && node?.selected && node?.parent?.title === 'root') {
          let ifDataExist = allSelectedNodes.find((treeData) => treeData['key'] === node['key']);
          if (!ifDataExist || allSelectedNodes.length < 1) {
            allSelectedNodes.push(buildModuleObject(node, data));
          }
        } else {
          var keyNode = node;
          if (keyNode) {
            var topNode = keyNode.getParentList()[0];
            if (
              topNode &&
              topNode?.folder &&
              (topNode?.selected || topNode?.partsel) &&
              topNode?.parent?.title === 'root'
            ) {
              let ifDataExist = allSelectedNodes.find((treeData) => treeData['key'] === topNode['key']);
              if (!ifDataExist || allSelectedNodes.length < 1) {
                allSelectedNodes.push(buildModuleObject(topNode, topNode?.data));
              }
            }
          }
        }
      });
      setDifferenceCount(totalScriptCount);
      props?.setAllSelectedNodes(allSelectedNodes);
      const _allTreeRootData = { ...allTreeRootData };
      _allTreeRootData['children'] = allSelectedNodes;
      props?.setModifiedSelectedArray([_allTreeRootData]);
      if (props.suiteType === 'Manual') {
        runSettingsManualObj['selectedModulesAndScripts'] = [_allTreeRootData];
      } else {
        runSettingsGlobalObj['selectedModulesAndScripts'] = [_allTreeRootData];
      }
      let _runSetObj = { ...props.runSettingsObj };
      _runSetObj['selectedModulesAndScripts'] = [_allTreeRootData];
      props.setRunSettingsObj(_runSetObj);
      const _suiteMainObj = { ...props.suiteObjectMain };
      _suiteMainObj['selectedModulesStatus'] = selectedModuleAndScript;
      props.setSuiteObjectMain(_suiteMainObj);
      props.setSelectedModulesStatus(selectedModuleAndScript);
    }
    if (isEmptyValue(selectedModuleAndScript)) {
      if (props.suiteType === 'Manual') {
        runSettingsManualObj['machines'] = [];
        setRunSettingsManualObj({ ...runSettingsManualObj });
      }
    }
  };
  const ifTreeLoaded = () => {
    props.hasTreeLoaded();
  };
  const colDefsModal = [
    {
      field: 'title',
      title: 'MODULE',
      class: 'title',
      isTitle: true,
      width: `calc(100% - 550px)`,
      folderTilleWidth: `calc(100% - 550px)`,
      paddingRight: '12px',
      render: (nodeObj) => {
        return (
          <>
            {nodeObj.isRootNode && (
              <>
                <Tooltip
                  title={`Modules - ${nodeObj.data.scriptCount ? nodeObj.data.scriptCount : 0}`}
                  placement="bottom"
                >
                  <span className="count-badge folder-count ml-2 modifiedBy">
                    M {nodeObj.data.subModuleCount ? nodeObj.data.subModuleCount : 0}{' '}
                  </span>
                </Tooltip>
                <Tooltip
                  title={`Scripts - ${nodeObj.data.scriptCount ? nodeObj.data.scriptCount : 0}`}
                  placement="bottom"
                >
                  <span className="count-badge file-count modifiedBy">
                    {' '}
                    S {nodeObj.data.scriptCount ? nodeObj.data.scriptCount : 0}{' '}
                  </span>
                </Tooltip>
              </>
            )}
            {nodeObj.data.folder && nodeObj.data.calculatedScriptCount > 0 && (
              <>
                <Tooltip title={`Scripts - ${nodeObj.data.calculatedScriptCount}`} placement="bottom">
                  <span className="count-badge file-count modifiedBy"> S {nodeObj.data.calculatedScriptCount} </span>
                </Tooltip>
              </>
            )}

            {nodeObj.folder && (
              <button class="add-btn">
                <MoreInfoButton type="add" nodeObj={nodeObj} />
              </button>
            )}
          </>
        );
      },
    },
    {
      field: 'scriptType',
      title: 'TYPE',
      class: 'Type truncate',
      width: '160px',
      render: (nodeObj) => {
        return (
          <Tooltip title={nodeObj.data.scriptType} placement="bottom">
            <span className="text-xs table-non-link-color-common fontPoppinsRegularMd">{nodeObj.data.scriptType}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'modifiedByUname',
      title: 'MODIFIED BY',
      class: 'modified-by',
      width: '200px',
      render: (nodeObj) => {
        return (
          <div>
            {!nodeObj.isRootNode && (
              <span title={nodeObj.data.modifiedByUname} className="tooltip">
                <span className="table-non-link-color-common modified">{nodeObj.data.modifiedByUname}</span>
              </span>
            )}
          </div>
        );
      },
    },
  ];

  if (isLoading) {
    renderTreeModal = <CommonLoader />;
  } else if (treeData.length > 0) {
    renderTreeModal = (
      <TableTree
        data={treeData}
        rootData={allTreeRootData}
        defaultChecked={defaultCheckedValue}
        colDefs={colDefsModal}
        hideElements={false}
        onCheckedNodes={onCheckedNodes}
        hideConditions={true}
        showCheckbox={true}
        expandAll={isExpandAll}
        getFancyTree={setCurrentFancytree}
        id="run-settings-childTree-scripts"
        hideConditionBtn={true}
        onNodeSelected={() => {}}
        conditions={[
          { key: 'title', value: 'Preconditions' },
          { key: 'title', value: 'Postconditions' },
        ]}
        labels={TREE_LABELS}
        ifTreeLoaded={ifTreeLoaded}
      />
    );
  } else {
    renderTreeModal = (
      <TableTree
        data={[]}
        rootData={allTreeRootData}
        colDefs={colDefsModal}
        hideElements={false}
        onCheckedNodes={onCheckedNodes}
        hideConditions={true}
        showCheckbox={true}
        expandAll={isExpandAll}
        getFancyTree={setCurrentFancytree}
        id="run-settings-childTree-scripts"
        hideConditionBtn={true}
        onNodeSelected={() => {}}
        conditions={[
          { key: 'title', value: 'Preconditions' },
          { key: 'title', value: 'Postconditions' },
        ]}
        labels={TREE_LABELS}
        ifTreeLoaded={ifTreeLoaded}
      />
    );
  }
  return (
    <>
      <div className="content_area_header_style">
        <div className="flex items-center">
          <label className="main-header-label float-left ">{EXECUTION_CONSTANTS.MODULES_SCRIPTS_LABLE}</label>
          {props?.allSelectedNodes.length === 0 ? (
            <label className="fontPoppinsRegularMd float-right pr-2 warning-color">
              {EXECUTION_CONSTANTS.RUN_SETTING_MODAL_NO_SCRIPT_SELECTED_WARNING}
            </label>
          ) : props?.suiteObjectMain?.noOfScripts > differenceCount ? (
            <label className="fontPoppinsRegularMd float-right pr-2 text-red-500 ">
              {' '}
              {EXECUTION_CONSTANTS.RUN_SETTING_MODAL_SCRIPT_TREE_MODIFICATION_WARNING}
            </label>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="grid h-80">
        <div className="module-popup-layout">{renderTreeModal}</div>
      </div>
    </>
  );
}

export default ScriptTreeSection;

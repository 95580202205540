import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FileUploadButton from './fileUpload_button';
import { Tooltip } from '@material-ui/core';
import './file_drag_and_drop.scss';
import WarningIcon from '@mui/icons-material/Warning';
export default function FilesDragAndDrop({
  onUpload,
  count,
  formats,
  errorMessage,
  emptyErrorMsg,
  setResponseObjectFlag,
  ...props
}) {
  const drop = useRef(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [showBtn, setShowbtn] = useState(false);

  useEffect(() => {
    drop.current.addEventListener('dragover', handleDragOver);
    drop.current.addEventListener('drop', handleDrop);
    return () => {
      drop?.current?.removeEventListener('dragover', handleDragOver);
      drop?.current?.removeEventListener('drop', handleDrop);
    };
  }, []);
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;
    handleFileUpload(files);
    if (setResponseObjectFlag) {
      setResponseObjectFlag();
    }
  };
  const handleFileUpload = (files) => {
    onUpload(files);
    setUploadedFile(files[0]);
    localStorage.removeItem('errorJson');
  };
  const changeHandler = (e) => {
    if (setResponseObjectFlag) {
      setResponseObjectFlag();
    }
    let selFile = e.target.files;
    handleFileUpload(selFile);
  };

  const handelClick = () => {
    const fileInput = document.getElementById('file');
    if (fileInput) {
      fileInput.value = '';
    }

    setUploadedFile(null);
    emptyErrorMsg();
    localStorage.removeItem('errorJson');
  };

  useEffect(() => {
    let timeOut;
    if (props.clearData) {
      setUploadedFile(null);
      timeOut = setTimeout(() => props?.setUnSupportedFile(false), 500);
      clearTimeout(timeOut);
    }
    return () => {
      clearTimeout(timeOut);
    };
  }, [props.clearData]);

  return (
    <>
      <div className="files-drag-drop flex justify-center items-center flex-col" ref={drop}>
        <div className="flex justify-center items-center w-28">
          <FileUploadButton
            key={Date.now()}
            fileRemoved={handelClick}
            dragDropFilesLength={uploadedFile?.name}
            fromFileDragAndDrop={true}
            name="file"
            id="file"
            accept={formats}
            onChange={changeHandler}
            value={uploadedFile?.name}
            showBtn={showBtn}
            setShowBtn={setShowbtn}
            importManualDragAndDrop={true}
            className="ml-4 max-w-lg block w-28 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 cursor-pointer"
          />
        </div>
        <label className={uploadedFile ? 'selected-file-name-style flex' : ' file-upload-msg-style'}>
          {uploadedFile ? (
            uploadedFile.name
          ) : (
            <div className="mt-4 ml-4">
              <span>Or</span> <br></br>
              <span>Drag the file here</span>
            </div>
          )}
          {uploadedFile ? (
            <div className="flex flex-col">
              <span className="ml-2 absolute">
                <Tooltip title="Remove file" placement="bottom">
                  <button
                    type="button"
                    onClick={handelClick}
                    className="float-right mt-0.5  rounded-md rs-primary hover:text-red-700 focus:outline-none "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-3 w-3 z-100"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </Tooltip>
              </span>
            </div>
          ) : null}
        </label>
        {errorMessage && (
          <span className="fontPoppinsRegularXs error-message-color">
            <WarningIcon className="warning-icon" />
            {errorMessage}
          </span>
        )}
      </div>
    </>
  );
}
FilesDragAndDrop.propTypes = {
  onUpload: PropTypes.func.isRequired,
};

import React, { useEffect, useState, useContext } from 'react';
import Grid from '@mui/material/Grid';
import { Tooltip } from '@material-ui/core';
import { Download } from '@mui/icons-material';
import {
  downloadFileFromCloud,
  getSingleDefectReq,
  getTemplateForEmailConfigReq,
  getAllComments,
} from '@src/api/api_services';
import { saveFileFromBlob } from '@src/pages/test-development/shared/common-methods';
import { DEFECT_DATE_FIELD, FORM_FIELDS } from '@pages/defects/defectMgmtConstants';
import RichTextEditor from '@pages/common/rich-text-editor';
import { createContext } from 'react';
import Comment from '@pages/defects/defect-comments/components/Comment/Comment';
import BugSummarySkeltonLoader from './bug-summary-skelton-loader';
import { UI_VALIDATIONS } from '@src/util/validations';

function BugSummaryDetails(props) {
  const { DEFECTS_VALUES_MAX_CHAR_COUNT } = UI_VALIDATIONS;
  const { CREATED_ON, MODIFIED_ON } = DEFECT_DATE_FIELD;
  const { DATE } = FORM_FIELDS;
  const [detailsViewData, setdetailsViewData] = useState([]);
  const [selectedDefectData, setSelectedDefectData] = useState({});
  const [defectMgmtData, setDefectMgmtData] = useState({});
  const [comments, setComments] = useState([]);
  let [isModuleEllipsisOpen, setIsModuleEllipsisOpen] = useState(false);
  let [isLoading, setIsLoading] = useState(true);

  let project = JSON.parse(localStorage.getItem('selected-project'));

  let labelTitle = ' ';
  selectedDefectData?.projectLabels?.forEach((system, index) => {
    labelTitle +=
      (system?.name ? system?.name : '') + `${index < selectedDefectData?.projectLabels?.length - 1 ? ',' : ' '}`;
  });

  const defectId = props.selectedDefectID;
  const { TEXTBOX, LINK, ATTACHMENT, TEXTEDITOR } = FORM_FIELDS;

  const getTemplateForEmailConfig = async () => {
    try {
      let response = await getTemplateForEmailConfigReq(project.id);
      if (response?.data?.responseCode === 200 && response?.data?.responseObject) {
        let template = response?.data?.responseObject;
        setDefectMgmtData(template);
        return template;
      }
    } catch (err) {
      console.error('get Template For Email Config api error', err);
    }
  };
  const getSingleDefect = async () => {
    try {
      const response = await getSingleDefectReq(project?.id, defectId);
      if (response?.data?.responseCode === 200) {
        setSelectedDefectData(response?.data?.responseObject);
        setComments(response.data.responseObject.comments);
        setIsLoading(false);
        return response?.data?.responseObject;
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.error('Get Single defect error', err);
    }
  };

  const initDefectData = async () => {
    setdetailsViewData([]);
    const template = await getTemplateForEmailConfig();
    const singleDefectData = await getSingleDefect();
    mapKeyAndValue(template?.defect_details_templates[0]?.defectDetails, singleDefectData?.defectDetails);
  };
  useEffect(() => {
    initDefectData();
  }, []);

  function mapKeyAndValue(defectTemplateData, defectDetails) {
    defectTemplateData.forEach((item) => {
      if (['Assign to', 'Created By', 'Modified By'].includes(item?.label)) {
        item.value = defectDetails && defectDetails[item.label]?.name;
      } else {
        item.value = defectDetails && defectDetails[item.label];
      }
    });
    setdetailsViewData(defectTemplateData);
  }

  const downloadFile = (file) => {
    downloadFileFromCloud(file.id)
      .then((d) => {
        saveFileFromBlob(d.data, file.name);
      })
      .catch((err) => {
        console.error('donwload file from cloud', err);
      });
  };

  
  const handleOnClickDefectValues = (data) => {
    if (data?.value) {
      const fieldId = document.getElementById(data?.label);
      if (fieldId?.classList?.contains('wrapContent')) {
        fieldId?.classList?.remove('wrapContent');
        fieldId?.classList?.add('contentDataStyle');
      } else {
        fieldId?.classList?.remove('contentDataStyle');
        fieldId?.classList?.add('wrapContent');
      }
    }
  };
  const getDefectValue = (data, value) => {

    const lengthyValue = value?.length > DEFECTS_VALUES_MAX_CHAR_COUNT
    
    return (
      <>
        { lengthyValue? (
          <Tooltip title={value} placement="top">
            <label
              id={data.label}
              className="wrapContent label-overflow cursor-pointer"
              onClick={() => {
                handleOnClickDefectValues(data);
              }}
            >
              {value}
            </label>
          </Tooltip>
        ) : (
          <label className="label-overflow">{value || '--'}</label>
        )}
      </>
    );
  };
  const getDisplayTimeZoneBasedDate = (data) => {
    let label = data.label.concat(' - onDisplay');
    let value = selectedDefectData?.defectDetails[label];
    return getDefectValue(data, value);
  };
  const detailViewComponents = (data) => {
    let value;
    if (data.type !== ATTACHMENT) {
      value = data.value && Array.isArray(data.value) ? data.value.join(',') : data.value;
    }
    if (data?.label === 'Labels') {
      return (
        <Tooltip title={labelTitle?.trim() && labelTitle}>
          <div className="fontPoppinsRegularSm label-overflow ">{labelTitle?.trim() || '--'}</div>
        </Tooltip>
      );
    }

    if (data.label === 'Module') {
      return (
        <div
          id={data.label}
          className={`fontPoppinsRegularSm label-overflow text-left cursor-pointer ${isModuleEllipsisOpen ? '' : ' wrapContent'} `}
          onClick={(_) => (data?.value ? setIsModuleEllipsisOpen(!isModuleEllipsisOpen) : null)}
        >
          <Tooltip title={data?.value !== '--' && data.value?.split(':')?.shift()} placement="left">
            <span>{data.value?.split('/')?.pop()?.split(':')?.shift()}</span>
          </Tooltip>
        </div>
      );
    }

    if (data.type === LINK && value) {
      return (
        <>
          <Tooltip title={value !== '--' && value?.length > 25 && value} placement="top">
            <a href={value} target="_blank" className="label-overflow tab-style-common">
              {value?.length > 25 ? `${value.substring(0, 25)}...` : value}
            </a>
          </Tooltip>
        </>
      );
    }

    if (data.type === DATE && [CREATED_ON, MODIFIED_ON].includes(data.label)) {
      return getDisplayTimeZoneBasedDate(data);
    }
    if (data.type === ATTACHMENT) {
      if (data?.value && Array.isArray(data?.value) && data?.value.length > 0) {
        return (
          <div>
            {data?.value?.map((option, index) => (
              <div key={index}>
                <Tooltip title={option?.name !== '--' && option?.name} placement="top">
                  <label className="label-overflow fontPoppinsRegularSm">
                    {option?.name.length > 25 ? `${option?.name.substring(0, 25)}...` : option?.name || '--'}
                  </label>
                </Tooltip>
                <Download
                  color="primary"
                  className="h-6 w-6 mt-1 mr-2 origin-center cursor-pointer"
                  onClick={() => {
                    downloadFile(option);
                  }}
                />
              </div>
            ))}
          </div>
        );
      } else {
        <label className="label-overflow fontPoppinsRegularSm">--</label>;
      }
    }
    if (data.type === TEXTEDITOR) {
      return (
        <div className="mt-2 border-2 border-gray-300 max-h-48 overflow-auto">
          <RichTextEditor
            mode="view"
            convertedContent={value || ''}
            readOnly={true}
            toolbarHidden={true}
            analyticsClasses={true}
          />
        </div>
      );
    }
    return (
      <>
        {value !== '--' && value?.length > 30 ? (
          <Tooltip title={value !== '--' && value?.length > 30 && value} placement="top">
            <label
              id={data.label}
              className="wrapContent label-overflow fontPoppinsRegularSm cursor-pointer"
              onClick={() => {
                if (data?.value) {
                  const fieldId = document.getElementById(data?.label);
                  if (fieldId?.classList?.contains('wrapContent')) {
                    fieldId?.classList?.remove('wrapContent');
                    fieldId?.classList?.add('contentDataStyle');
                  } else {
                    fieldId?.classList?.remove('contentDataStyle');
                    fieldId?.classList?.add('wrapContent');
                  }
                }
              }}
            >
              {value || '--'}
            </label>
          </Tooltip>
        ) : (
          <label className="label-overflow fontPoppinsRegularSm text-left">{value || '--'}</label>
        )}
      </>
    );
  };
  const detailViewElements = (data) => {
    return (
      <>
        <div className="flex flex-col justify-center">
          <div className="flex">
            <div className="flex ">
              <label className="fontPoppinsMediumSm">
                {data ? (
                  <Tooltip title={data?.label} placement="top">
                    <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-left">
                      {data?.label.length > 32 ? `${data?.label.substring(0, 32)}...` : data?.label}
                    </div>
                  </Tooltip>
                ) : null}
              </label>
            </div>
          </div>
          <div className="contentDataStyle ">
            {data?.label === 'Comments' ? (
              <>
                <div className="comment-area">
                  <div className="comment-section">
                    <commentContext.Provider>
                      {comments?.length > 0
                        ? comments?.map((comment, index) => {
                            return (
                              <Comment
                                key={index}
                                id={index}
                                data={comment}
                                commentsArray={comments}
                                onAddComment={setComments}
                                detailsView={true}
                                analyticsClasses={true}
                              />
                            );
                          })
                        : '--'}
                    </commentContext.Provider>
                  </div>
                </div>
              </>
            ) : (
              detailViewComponents(data)
            )}
          </div>
        </div>
      </>
    );
  };

  const { isEditable, hasFullAccess } = React.useMemo(
    () => ({
      isEditable: window.permission?.isEditAccess('defects'),
      hasFullAccess: window.permission?.isFullAccess('defects'),
    }),
    []
  );

  return isLoading ? (
    <BugSummarySkeltonLoader />
  ) : (
    <div className={`bug_summary_detalis overflow-y-auto ${!props.isAccordion ? 'h-90v' : ''} `}>
      <div className="m-3 shadow-none">
        <Grid container spacing={{ xs: 2, md: 3, sm: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {detailsViewData &&
            detailsViewData.length > 0 &&
            detailsViewData.map((data, index) => {
              return [TEXTBOX, TEXTEDITOR].includes(data.type) && data?.label !== 'Comments' ? (
                <Grid item xs={12} sm={12} md={12} key={index}>
                  {detailViewElements(data)}
                </Grid>
              ) : data?.label !== 'Comments' ? (
                <Grid item xs={4} sm={4} md={4} key={index}>
                  {detailViewElements(data)}
                </Grid>
              ) : null;
            })}
          {detailsViewData &&
            detailsViewData.length > 0 &&
            detailsViewData.map((data, index) => {
              return data?.label === 'Comments' ? (
                <Grid item xs={12} sm={12} md={12} key={index}>
                  {detailViewElements(data)}
                </Grid>
              ) : null;
            })}
        </Grid>
      </div>
    </div>
  );
}

export default BugSummaryDetails;
export const commentContext = createContext();

import React from 'react';
import { FormControl, InputLabel, NativeSelect, FormHelperText } from '@material-ui/core';
import { colors } from '@src/css_config/colorConstants';
import { fontPoppins } from '@src/css_config/fontConstants';
import Select from '@mui/material/Select';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const useStyles = makeStyles((theme) => ({
  select: {
    ...fontPoppins.rMd,
    '.MuiSelect-select': {
      paddingLeft: '10px',
    },
    '& .MuiSelect-select': {
      background: theme.background,
      padding: theme.padding,
      border: theme.border,
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiInput-input:focus': {
      backgroundColor: 'transparent',
    },
    '& .css-1cccqvr:focus': {
      backgroundColor: 'transparent !important',
    },
    width: theme.width,
    minWidth: theme.minWidth,
    borderRadius: theme.borderRadius,
    marginRight: theme.marginRight,
    background: theme.backgroundRest ? theme.backgroundRest : colors.text_white,
    color: theme.color && colors.blue_dark,
    letterSpacing: '0px',
    borderBottom: theme.borderBottom,
    opacity: 1,
    border: '1px solid lightgray',
    ...fontPoppins.rLg,
  },
  selected: {
    marginTop: '7px',
    width: theme.plusIconCheck && '417px',
    '.MuiMenuItem-root': {
      letterSpacing: '0px',
      color: colors.grey_dark,
      opacity: 1,
      ...fontPoppins.rMd,
    },
    '&& .Mui-selected': {
      opacity: 1,
      color: colors.text_white,
      background: `${colors.rs_primary} 0% 0% no-repeat padding-box`,
      backgroundColor: `${colors.rs_primary}`,
      ...fontPoppins.rMd,
    },
    '&& .Mui-selected:hover': {
      backgroundColor: `${colors.rs_primary}`,
    },
    '& .MuiMenuItem-root:hover': {
      opacity: 1,
      color: colors.text_white,
      background: `${colors.rs_primary} 0% 0% no-repeat padding-box`,
      backgroundColor: `${colors.rs_primary} `,
    },
    '& .MuiInput-input:focus': {
      backgroundColor: 'transparent',
    },
    '& ul': {
      marginTop: '10px',
      marginBottom: '10px',
    },
  },
}));
const MySelect = ({ label, error, helperText, children, ...rest }) => {
  const {
    width,
    minWidth,
    borderRadius,
    marginRight,
    sxPadding,
    variant,
    fullWidth,
    sxBorderBottom,
    boxShadow,
    verticalAnchor,
    verticalTransform,
    horizontalTransform,
    horizontalAnchor,
    marginTop,
    classSelect,
  } = rest;

  const classes = useStyles();
  return rest?.variableInput === undefined || rest?.variableInput === '' ? (
    <FormControl error={error} InputLabelProps={{ shrink: true }}>
      <InputLabel htmlFor="age-native-helper" shrink>
        {label}
      </InputLabel>
      <NativeSelect
        InputLabelProps={{
          shrink: true,
        }}
        {...rest}
      >
        {children}
      </NativeSelect>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  ) : (
    <Select
      type="text"
      variant={variant ? variant : 'standard'}
      disableUnderline
      fullWidth={fullWidth}
      className={`${classes.select} ${classSelect}`}
      sx={{
        boxShadow: boxShadow,
        padding: sxPadding ? sxPadding : '',
        borderBottom: sxBorderBottom,
        width: width,
        minWidth: minWidth,
        borderRadius: borderRadius,
        marginTop: marginTop,
        marginRight: marginRight,
      }}
      MenuProps={{
        classes: { paper: classes.selected },
        anchorOrigin: {
          vertical: verticalAnchor,
          horizontal: horizontalAnchor,
        },
        transformOrigin: {
          vertical: verticalTransform,
          horizontal: horizontalTransform,
        },
        style: {
          marginTop: marginTop,
        },
      }}
      IconComponent={KeyboardArrowDownIcon}
      {...rest}
    >
      {children}
    </Select>
  );
};

export default MySelect;

import { useMemo, useState, useEffect } from 'react';
import cx from 'classnames';
import LicenseRowCardStyle from '@pages/licenses/styles/license-row-card.module.scss';
import style from '../styles/licenses.module.scss';
import { ReactComponent as ShieldUserIcon } from '@assets/svg-imports/license2.0.svg';
import { ReactComponent as LicenseExpired } from '@assets/svg-imports/license2.0_expired.svg';
import { ReactComponent as ExpiringSoon } from '@assets/svg-imports/license2.0_expiring_soon.svg';
import { ReactComponent as PencilIcon } from '@assets/svg-imports/pencil.svg';
import DoneIcon from '@mui/icons-material/Done';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Link } from 'react-router-dom';
import {
  encode,
  KEYBOARD_KEYS,
  getLicenseStatus,
  getLicenseExpirationDays,
  getCurrentLicenseId,
  isEmptyValue,
} from '@util/common_utils';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import LicenseDetailsDrawer from '@pages/licenses/components/LicenseDetails';
import OwnerDetailsDrawer from '@pages/licenses/components/OwnerDetails';
import ChangeLicenseModal from '@pages/licenses/components/ChangeLicenseModal';
import LicenseExpAlert from '@pages/licenses/components/LicenseExpAlert';
import {
  LICENSE_NAME_VALIDATIONS,
  LICENSE_PERIOD_STATUS,
  getLicensePeriodStatus,
  hasPaidFuturePlan,
  getRemainingDays,
  automationFeatures,
  FREE_LICENSE_TYPE,
} from '@util/licenseUtils';
import { LICENSE_EXPIRED_CONSTANTS, LICENSE_DETAILS_CONSTANTS } from '@src/common/ui-constants';
import UpcomingPlanDetails from './UpcomingPlanDetails';

import { HighlightText, toPascalCase } from '@util/common_utils';

const ToolTipRenderer = ({ title, fontSize }) => {
  return <span className={fontSize}>{title}</span>;
};

function LicenseRowCard({ data, isSelected, onLicenseSelect, onNameChange, onRenew, userSearched, showButtons }) {
  let licenseTransactionAllowed = JSON.parse(localStorage.getItem('allowedProjects'))?.features?.licenses;
  const userlicenseStatus = getLicenseStatus();
  const userlicenseExpires = getLicenseExpirationDays();
  const userlicenseID = getCurrentLicenseId();

  const isSuperAdmin = data?.displayData?.userPrivilege === 'Super Admin';

  const [editName, setEditName] = useState('');
  const [licenseNameEditErrors, setLicenseNameEditErrors] = useState([]);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isLicenseDetailsDrawerOpen, setIsLicenseDetailsDrawerOpen] = useState(false);
  const [upcomingPlanDetails, setUpcomingPlanDetails] = useState(false);
  const [isOwnerDetailsDrawerOpen, setIsOwnerDetailsDrawerOpen] = useState(false);
  const [isDefaultLicenseChanging, setIsDefaultLicenseChanging] = useState(false);
  let orderId = JSON?.parse(localStorage?.getItem('orderId'));
  const [licenseExpiryAlert, setLicenseExpiryAlert] = useState(false);
  const [openExpAlert, setOpenExpAlert] = useState(false);
  const [licenseUser] = data?.licenseUsers?.filter((user) => data?.licenseOwner === user.emailId);

  const licensePeriodStatus = useMemo(() => getLicensePeriodStatus(data), [data]);

  const isActive = licensePeriodStatus === LICENSE_PERIOD_STATUS.ACTIVE;
  const isExpired = licensePeriodStatus === LICENSE_PERIOD_STATUS.EXPIRED;
  const willExpire = licensePeriodStatus === LICENSE_PERIOD_STATUS.WILL_EXPIRE;

  const licenseIconRenderer = (classNames) => {
    if (isActive) {
      return <ShieldUserIcon className={classNames} />;
    } else if (licensePeriodStatus === LICENSE_PERIOD_STATUS.WILL_EXPIRE) {
      return <ExpiringSoon className={classNames} />;
    } else {
      return <LicenseExpired className={classNames} />;
    }
  };

  function handleLicenseNameEdit(event) {
    setEditName(event.target.value);
    let errors = [];
    if (editName.trim().length < LICENSE_NAME_VALIDATIONS?.MIN_LENGTH) {
      errors = LICENSE_NAME_VALIDATIONS.TOO_SHORT;
    } else if (editName.trim().length > LICENSE_NAME_VALIDATIONS?.MAX_LENGTH) {
      errors = LICENSE_NAME_VALIDATIONS.TOO_LONG;
    }

    setLicenseNameEditErrors(errors);
  }

  async function handleNameChangeSubmit(event, licenseId) {
    let errors = [];
    const eventType = event.type;
    const isBlurEvent = eventType === 'blur' && !event.currentTarget.contains(event.relatedTarget);
    if (
      isBlurEvent ||
      (eventType === 'keyup' && [KEYBOARD_KEYS.ENTER, KEYBOARD_KEYS.ESCAPE].includes(event?.key)) ||
      eventType === 'click'
    ) {
      const newName = editName.trim();
      if (newName && newName !== data.licenseName) {
        if (newName.length < LICENSE_NAME_VALIDATIONS?.MIN_LENGTH) {
          if (event?.key === KEYBOARD_KEYS.ESCAPE || isBlurEvent) {
            setIsEditingName(false);
            setEditName('');
          } else {
            errors = LICENSE_NAME_VALIDATIONS.TOO_SHORT;
          }
        } else if (newName.length > LICENSE_NAME_VALIDATIONS?.MAX_LENGTH) {
          if (event?.key === KEYBOARD_KEYS.ESCAPE || isBlurEvent) {
            setIsEditingName(false);
            setEditName('');
          } else {
            errors = LICENSE_NAME_VALIDATIONS.TOO_LONG;
          }
        } else {
          try {
            await onNameChange(newName, licenseId);
            setIsEditingName(false);
            setEditName('');
          } catch (error) {
            console.error('Failed to change license name', error);
            errors = LICENSE_NAME_VALIDATIONS.ALREADY_EXISTS;
          }
        }
      } else {
        setIsEditingName(false);
        setEditName('');
      }
      setLicenseNameEditErrors(errors);
    }
  }

  let hasAutomation = automationFeatures?.some((service) => data?.licenseFeatures?.includes(service));

  function toggleDrawer(isOpen, setIsOpen) {
    return (event) => {
      if (event.type === 'keydown' && (event.key === KEYBOARD_KEYS.TAB || event.key === KEYBOARD_KEYS.SHIFT)) {
        return;
      }

      setIsOpen(isOpen);
    };
  }

  function handleClosExpiryAlert() {
    setOpenExpAlert(false);
  }

  useEffect(() => {
    if (
      willExpire &&
      userlicenseStatus !== 'EXPIRED' &&
      data?.remainingDays <= 7 &&
      userlicenseExpires !== null &&
      userlicenseID === data.id &&
      data?.licenseType === 'C-Basic'
    ) {
      setLicenseExpiryAlert(true);
    } else if (
      willExpire &&
      userlicenseStatus !== 'EXPIRED' &&
      userlicenseExpires !== null &&
      data?.billingCycle === 'Yearly' &&
      userlicenseID === data.id &&
      data?.licenseType !== FREE_LICENSE_TYPE
    ) {
      setLicenseExpiryAlert(true);
    } else if (
      willExpire &&
      userlicenseStatus !== 'EXPIRED' &&
      userlicenseExpires <= 7 &&
      userlicenseExpires !== null &&
      data?.billingCycle !== 'Yearly' &&
      userlicenseID === data.id &&
      data?.licenseType !== FREE_LICENSE_TYPE
    ) {
      setLicenseExpiryAlert(true);
    }
  }, [openExpAlert, userlicenseStatus, userlicenseExpires]);

  const showRenewButton = () => {
    if (isSuperAdmin) {
      if (
        (willExpire || isExpired) &&
        !hasPaidFuturePlan(data) &&
        data?.licenseType !== FREE_LICENSE_TYPE &&
        licenseTransactionAllowed
      ) {
        return true;
      } else if (
        willExpire &&
        licensePeriodStatus === data?.licenseStatus &&
        data?.licenseType !== FREE_LICENSE_TYPE &&
        licenseTransactionAllowed
      ) {
        return true;
      } else return false;
    } else {
      return false;
    }
  };
  const showUpgradeButton = () => {
    if (isSuperAdmin && !isExpired && !hasPaidFuturePlan(data) && licenseTransactionAllowed) {
      return true;
    } else if (isSuperAdmin && isExpired && data?.licenseType === FREE_LICENSE_TYPE && licenseTransactionAllowed) {
      return true;
    } else return false;
  };

  return (
    <div
      className={cx(LicenseRowCardStyle['wrapper'], {
        [LicenseRowCardStyle['selected']]: isSelected,
      })}
    >
      {isDefaultLicenseChanging && (
        <ChangeLicenseModal
          data={data}
          onLicenseSelect={onLicenseSelect}
          openForLicenseChange
          handleCloseForLicenseChange={() => setIsDefaultLicenseChanging(false)}
        />
      )}
      {licenseExpiryAlert && !hasPaidFuturePlan(data) && isEmptyValue(orderId?.orderId) && (
        <LicenseExpAlert
          data={data}
          openExpAlert={licenseExpiryAlert}
          handleClosExpAlert={handleClosExpiryAlert}
          setExpAlertLicense={setLicenseExpiryAlert}
          onRenew={() => onRenew(data)}
        />
      )}
      {isOwnerDetailsDrawerOpen && (
        <Drawer anchor="right" open>
          <Box
            sx={{ width: 342, overflowX: 'hidden' }}
            role="presentation"
            onKeyDown={toggleDrawer(false, setIsOwnerDetailsDrawerOpen)}
          >
            <OwnerDetailsDrawer setIsOwnerDetailsDrawerOpen={setIsOwnerDetailsDrawerOpen} data={data} />
          </Box>
        </Drawer>
      )}
      {isLicenseDetailsDrawerOpen && (
        <Drawer anchor="right" open>
          <Box
            sx={{ width: 342, overflow: 'hidden' }}
            role="presentation"
            onKeyDown={toggleDrawer(false, setIsLicenseDetailsDrawerOpen)}
          >
            <LicenseDetailsDrawer
              hasAutomation={hasAutomation}
              licenseIconRenderer={licenseIconRenderer}
              data={data}
              showRenewButton={showRenewButton}
              setIsLicenseDetailsDrawerOpen={setIsLicenseDetailsDrawerOpen}
              onRenew={() => onRenew(data)}
              setUpcomingPlanDetails={setUpcomingPlanDetails}
            />
          </Box>
        </Drawer>
      )}
      {upcomingPlanDetails && (
        <Drawer anchor="right" open transitionDuration={{ appear: 1000, enter: 1500, exit: 1500 }}>
          <Box
            sx={{ width: 342, overflow: 'hidden' }}
            role="presentation"
            onKeyDown={toggleDrawer(false, setUpcomingPlanDetails)}
          >
            <UpcomingPlanDetails
              hasAutomation={hasAutomation}
              setIsLicenseDetailsDrawerOpen={setIsLicenseDetailsDrawerOpen}
              licenseIconRenderer={licenseIconRenderer}
              data={data}
              setUpcomingPlanDetails={setUpcomingPlanDetails}
            />
          </Box>
        </Drawer>
      )}

      <div className="flex items-center h-full">
        <div
          className={cx(
            'flex justify-center items-center border-r h-full',
            LicenseRowCardStyle['radio-control-wrapper'],
            LicenseRowCardStyle['col-0.5']
          )}
        >
          <label htmlFor={data?.id} className={cx(LicenseRowCardStyle['radio-control'])}>
            <input
              type="radio"
              id={data?.id}
              name="licenses"
              checked={isSelected}
              value={data?.id}
              onClick={() => {
                isSelected ? setIsDefaultLicenseChanging(false) : setIsDefaultLicenseChanging(true);
              }}
              readOnly
              disabled={isExpired}
            />
            <span
              className={cx(LicenseRowCardStyle['control-indicator'], {
                [LicenseRowCardStyle['disable']]: isExpired,
              })}
            />
          </label>
        </div>
        <div className={cx('flex items-center justify-between px-2', LicenseRowCardStyle['col-11.5'])}>
          <div className="flex items-center w-1/5">
            <Tooltip
              data-html="true"
              title={
                <div className="p-1 flex flex-col text-xss">
                  <p className="mb-1 fontPoppinsSemiboldXs8px text-white">{data?.displayData?.licenseType}</p>
                  <p className="fontPoppinsRegularXs8px">
                    {isExpired ? (
                      <>
                        <span className="fontPoppinsSemiboldXs8px">
                          {data.remainingDays <= 0 && data?.licenseStatus === 'EXPIRED'
                            ? ` ${toPascalCase(LICENSE_EXPIRED_CONSTANTS?.EXPIRED)} ${Math.abs(data.remainingDays)} days ago`
                            : data?.remainingDays == 0 && data?.licenseStatus !== 'EXPIRED'
                              ? LICENSE_DETAILS_CONSTANTS?.SUBSCRIPTION_EXPIRING_TODAY
                              : ''}
                        </span>
                      </>
                    ) : (
                      <>
                        {LICENSE_EXPIRED_CONSTANTS?.LICENSE_WILL_EXPIRE}
                        {data.remainingDays > 0 && ' in '}
                        <span className="fontPoppinsSemiboldXs8px">
                          {data.remainingDays > 0
                            ? ` ${data.remainingDays} ` + getRemainingDays(data.remainingDays)
                            : ' today'}
                        </span>
                      </>
                    )}
                  </p>
                </div>
              }
              classes={{
                popper: cx(LicenseRowCardStyle['custom-tooltip'], LicenseRowCardStyle[licensePeriodStatus]),
              }}
            >
              {licenseIconRenderer('w-5 h-6')}
            </Tooltip>
            <div className="flex flex-col ml-3 w-8/12">
              <h2 className={cx(LicenseRowCardStyle['title'], 'fontPoppinsSemiboldSm')}>
                {LICENSE_DETAILS_CONSTANTS?.NAME}
                {!!licenseNameEditErrors.length && isEditingName && (
                  <Tooltip
                    arrow
                    title={
                      <div>
                        {licenseNameEditErrors.map((errorMessage) => (
                          <p key={`${errorMessage}-${data.licenseName}`}>{errorMessage}</p>
                        ))}
                      </div>
                    }
                  >
                    <InfoOutlinedIcon className={cx('ml-2', LicenseRowCardStyle['danger'])} fontSize="inherit" />
                  </Tooltip>
                )}
              </h2>
              <div
                className="inline-flex flex-row items-center overflow-hidden overflow-ellipsis whitespace-nowrap"
                onBlur={(event) => handleNameChangeSubmit(event, data.id)}
              >
                {isEditingName ? (
                  <TextField
                    error={!!licenseNameEditErrors.length}
                    value={editName}
                    onChange={handleLicenseNameEdit}
                    variant="standard"
                    autoFocus
                    onKeyUp={(event) => handleNameChangeSubmit(event, data.id)}
                  />
                ) : (
                  <Tooltip
                    data-html="true"
                    title={<ToolTipRenderer title={data?.licenseName} fontSize="fontPoppinsRegularXs" />}
                    classes={{
                      popper: cx(LicenseRowCardStyle['ToolTipRenderer']),
                    }}
                  >
                    <button
                      onClick={toggleDrawer(true, setIsLicenseDetailsDrawerOpen)}
                      className={cx(
                        LicenseRowCardStyle['value'],
                        'overflow-hidden overflow-ellipsis whitespace-nowrap fontPoppinsSemiboldSm'
                      )}
                    >
                      <HighlightText text={data.licenseName} highlight={userSearched} />
                    </button>
                  </Tooltip>
                )}
                {isSuperAdmin && (
                  <Tooltip
                    data-html="true"
                    title={
                      <ToolTipRenderer
                        title={!editName || editName === data.licenseName ? 'Edit Name' : 'Done'}
                        fontSize="fontPoppinsRegularXs8px"
                      />
                    }
                    classes={{
                      popper: cx(LicenseRowCardStyle['ToolTipRenderer']),
                    }}
                  >
                    <span>
                      {!editName || editName === data.licenseName ? (
                        <button
                          type="button"
                          className={LicenseRowCardStyle['edit-button']}
                          onClick={() => {
                            setEditName(data.licenseName);
                            setIsEditingName(true);
                          }}
                          disabled={!!editName}
                        >
                          <PencilIcon width={12} height={12} className="ml-2" />
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="z-50"
                          onClick={(event) => handleNameChangeSubmit(event, data.id)}
                        >
                          <DoneIcon width={12} height={12} className={cx('ml-2', LicenseRowCardStyle['success'])} />
                        </button>
                      )}
                    </span>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col w-1/12">
            <h2 className={cx(LicenseRowCardStyle['title'], 'fontPoppinsSemiboldSm')}>
              {LICENSE_DETAILS_CONSTANTS?.MY_PRIVILEGE}
            </h2>
            <span className="fontPoppinsRegularSm whitespace-nowrap text-black">{data.displayData.userPrivilege}</span>
          </div>
          <div className="flex flex-col w-2/12 pl-6">
            <h2 className={cx(LicenseRowCardStyle['title'], 'fontPoppinsSemiboldSm')}>
              {LICENSE_DETAILS_CONSTANTS?.TYPE}
            </h2>
            <span className="fontPoppinsRegularSm whitespace-nowrap text-black">{data.displayData.licenseType}</span>
          </div>

          <div className="flex flex-col w-2/12 pl-6">
            <h2 className={cx(LicenseRowCardStyle['title'], 'fontPoppinsSemiboldSm')}>
              {LICENSE_DETAILS_CONSTANTS?.PARALLEL_RUNS_DISPLAY(data?.numberOfParallelRuns, true)}
            </h2>
            <span className="fontPoppinsRegularSm whitespace-nowrap text-black">
              {hasAutomation ? data?.numberOfParallelRuns : '--'}
            </span>
          </div>

          <div className="flex flex-col w-1/12">
            <h2 className={cx(LicenseRowCardStyle['title'], 'fontPoppinsSemiboldSm')}>
              {LICENSE_DETAILS_CONSTANTS?.STATUS}
            </h2>
            <span
              className={cx('fontPoppinsMediumSm whitespace-nowrap', {
                [LicenseRowCardStyle['success']]: [
                  LICENSE_PERIOD_STATUS.ACTIVE,
                  LICENSE_PERIOD_STATUS.WILL_EXPIRE,
                ].includes(licensePeriodStatus),
                [LicenseRowCardStyle['danger']]: isExpired,
              })}
            >
              {toPascalCase(data.licenseStatus)}
            </span>
          </div>
          <div className={cx('flex flex-col w-1/12', !showButtons ? 'mr-4' : '')}>
            <h2 className={cx(LicenseRowCardStyle['title'], 'pl-1', 'fontPoppinsSemiboldSm')}>
              {LICENSE_DETAILS_CONSTANTS?.PURCHASED_BY}
            </h2>
            <div className="flex overflow-hidden overflow-ellipsis whitespace-nowrap">
              <Tooltip
                title={<ToolTipRenderer title={data.displayData.managedBy || '--'} fontSize="fontPoppinsRegularXs" />}
                classes={{
                  popper: cx(LicenseRowCardStyle['ToolTipRenderer']),
                }}
              >
                <button
                  disabled={!licenseUser}
                  onClick={toggleDrawer(true, setIsOwnerDetailsDrawerOpen)}
                  className={cx(
                    LicenseRowCardStyle['value'],
                    'text-left overflow-hidden overflow-ellipsis whitespace-nowrap px-1 border-gray-300 fontPoppinsSemiboldSm',
                    !licenseUser && 'pointer-events-none'
                  )}
                >
                  <HighlightText text={data.displayData.managedBy || '--'} highlight={userSearched} />
                </button>
              </Tooltip>
            </div>
          </div>
          {showButtons && (
            <div className="ml-6">
              {licenseTransactionAllowed && (
                <div className={cx(LicenseRowCardStyle['w-2.5/12'], 'ml-6')}>
                  <div className={cx('flex justify-end items-center gap-4')}>
                    {showRenewButton() && (
                      <Link
                        to={`/licenses/${encode(data.id)}/FireFlink License/renew`}
                        className={cx(
                          style.primaryBtn,
                          'fontPoppinsRegularXs px-6 py-2.5 text-white rounded-md ml-2 inline-flex items-center'
                        )}
                      >
                        {LICENSE_DETAILS_CONSTANTS?.RENEW}
                      </Link>
                    )}
                    {showUpgradeButton() && (
                      <Link
                        to={`/licenses/${encode(data.id)}/FireFlink License/upgrade`}
                        className={cx(
                          style.primaryBtn,
                          'fontPoppinsRegularXs px-6 py-2.5 text-white rounded-md ml-2 inline-flex items-center'
                        )}
                      >
                        {LICENSE_DETAILS_CONSTANTS?.UPGRADE}
                      </Link>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

LicenseRowCard.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onLicenseSelect: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onRenew: PropTypes.func.isRequired,
};

export default LicenseRowCard;

import { DotsVerticalIcon } from '@heroicons/react/solid';
import React, { useEffect, useState, useContext } from 'react';
import { useFilters, useTable } from 'react-table';
import { Menu, Popover, Transition } from '@headlessui/react';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import '../shared_recorded_elements.scss';
import { Fragment } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { getAllSharedElements, getSharedElementReq, getSingleUserRequest } from '@api/api_services';
import LocatorDetailsModal from '@pages/repository/components/project_elements/modals/locator_details_modal';
import ElementModal from '@pages/repository/components/project_elements/modals/ElementModal';
import { GrFormFilter } from 'react-icons/gr';
import UserDetailsModal from '@pages/test-development/shared/user-details-modal';
import { encodeSpecialCharater } from '@pages/test-development/shared/common-methods';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { Tooltip } from '@mui/material';
import SearchBar from '@pages/analytics/common/search-bar';
import { HighlightText, isEmptyValue, isValueIncludes } from '@util/common_utils';
import NoResultsFound from '@pages/common/Table/NoResultFound';
import { CommonLoader } from '@src/pages/common/common-loader';
import { VISUAL_TESTING_IMAGE_FILE_EXTENTION } from '@src/common/ui-constants';

export const MultipleFilter = (rows, accessor, filterValue) => {
  const arr = [];
  rows.forEach((val) => {
    if (filterValue.includes(val.original[accessor])) arr.push(val);
  });
  return arr;
};

const SharedElements = (props) => {
  let history = useHistory();
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  let [sharedElements, setSharedElements] = useState([]);
  let [elementDetailsModal, setElementDetailsModal] = useState(false);
  let [locDetailsModal, setLocDetailsModal] = useState(false);
  let [selectedSharedEle, setSelectedSharedEle] = useState();
  let [selectedLocators, setSelectedLocators] = useState();
  const [openElementModal, setOpenElementModal] = useState(false);
  const [reloadTable, setReloadTable] = useState(true);
  let [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);
  const [userDetails, setUserDetails] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const project = JSON.parse(localStorage.getItem('selected-project'));

  function setFilteredParams(filterArr, val) {
    if (filterArr.includes(val)) {
      filterArr = filterArr.filter((n) => {
        return n !== val;
      });
    } else filterArr.push(val);

    if (filterArr.length === 0) filterArr = undefined;
    return filterArr;
  }

  function on() {
    const tableFilter = document.getElementById('tablefilter');
    if (tableFilter) {
      tableFilter.style.display = 'block';
      tableFilter.focus();
    }
  }

  function off() {
    document.getElementById('tablefilter').style.display = 'none';
  }

  let contextMenuData = [
    'None',
    'Link',
    'Textbox',
    'Button',
    'Radiobutton',
    'Checkbox',
    'Text',
    'Textarea',
    'image',
    'Dropdown',
    'visual testing',
  ];
  if (
    (project.type === 'Mobile' &&
      ['Android', 'iOS', 'Ios'].includes(props.platForm) &&
      ['Hybrid', 'Native'].includes(project.appType)) ||
    (project.type === 'Web & Mobile' && ['Android', 'MobileWeb', 'iOS', 'Ios', 'Mobile'].includes(props.platForm))
  ) {
    contextMenuData = [
      'link',
      'textfield',
      'icon',
      'button',
      'radioButton',
      'checkbox',
      'tab',
      'action overflow button',
      'hamburger menu',
      'toggle button',
      'steppers',
      'sliders',
      'text',
      'option',
      'text area',
      'suggestion',
      'time picker',
      'date picker',
      'toaster message',
      'card',
      'tooltip',
      'dropdown',
      'image',
      'calendar',
      'visual testing',
    ];
  }
  if ((project.type === 'Web & Mobile' && props.platForm === 'Web') || project.type === 'Web') {
    contextMenuData = [
      'link',
      'textfield',
      'button',
      'radioButton',
      'checkbox',
      'tab',
      'text',
      'textarea',
      'image',
      'dropdown',
      'frame',
      'suggestion',
      'option',
      'time picker',
      'date picker',
      'popup',
      'toaster message',
      'card',
      'icon',
      'tooltip',
      'slider',
      'hamburger icon',
      'toggle button',
      'action overflow button',
      'visual testing',
    ];
  }
  if (project.type === 'Web') {
    contextMenuData = [
      'link',
      'textfield',
      'button',
      'radioButton',
      'checkbox',
      'tab',
      'text',
      'textarea',
      'image',
      'dropdown',
      'frame',
      'suggestion',
      'option',
      'time picker',
      'date picker',
      'popup',
      'toaster message',
      'card',
      'icon',
      'tooltip',
      'slider',
      'hamburger icon',
      'toggle button',
      'action overflow button',
      'visual testing',
    ];
  }

  function SelectColumnFilter({ column: { filterValue = [], setFilter, preFilteredRows, id } }) {
    const backgroundStyleFilter = {
      backgroundColor: 'white',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      borderRadius: '5px',
    };

    return (
      <span className="flex">
        <GrFormFilter className="ml-3 mt-1 text-lg cursor-pointer" onClick={on} />
        <div
          className="status-filter absolute flex-col mt-9 step-result mx-7 py-1"
          style={backgroundStyleFilter}
          onBlur={(e) => {
            if (!e.currentTarget.contains(e.relatedTarget)) {
              off();
            }
          }}
        >
          <Popover className="relative">
            <Popover.Panel static className={`bg-white bg-opacity-100 focus:outline-none hidden`} id="tablefilter">
              <div className="bg-white bg-opacity-100">
                <div className="h-80 overflow-y-auto" id="journal-scroll">
                  {contextMenuData.map((type) => (
                    <div className="hoverRow w-52 h-8">
                      <input
                        type="checkbox"
                        className="h-4 w-4 text-blue-600 border-gray-300 ml-2 cursor-pointer"
                        id={type}
                        name={type}
                        value={type}
                        onChange={(e) => {
                          if (e.target.value !== 'None') {
                            setFilter(setFilteredParams(filterValue, e.target.value));
                          }
                        }}
                      />
                      <label
                        htmlFor={type}
                        className="ml-1.5 font-medium text-gray-700 fontPoppinsSemiboldMd inline-block w-10/12 cursor-pointer"
                      >
                        {type}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Popover>
        </div>
      </span>
    );
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  const contextMenuVariableTabContent = [
    {
      name: 'Details',
    },
    // {
    //     name: "History",
    // },
  ];
  useEffect(() => {
    if (reloadTable) {
      setTimeout(() => {
        getAllSharedElementsReq();
        setReloadTable(false);
      }, 90);
    }
  }, [reloadTable]);

  useEffect(() => {
    if (props.platForm) {
      getAllSharedElementsReq();
    }
  }, [props.platForm]);

  let returnname = (nameArray) => {
    let names = [];
    for (let i = 0; i < nameArray?.length; i++) {
      names.push(nameArray[i].name);
    }
    return names.toString();
  };

  let getElementDetails = async (sharedEle, locdetails) => {
    let response;
    try {
      response = await getSharedElementReq(sharedEle.id);
      setSelectedSharedEle(response?.data?.responseObject);
      setSelectedLocators(response?.data?.responseObject?.locators);
      if (locdetails === 'locDetails') {
        setLocDetailsModal(true);
      } else {
        setElementDetailsModal(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  let settingElementName = (element) => {
    let elementData = {
      Name: element.name,
      id: element.id,
      parenName: element.pageIdsNames ? returnname(element.pageIdsNames) : '--',
    };
    localStorage.setItem('sharedEle', JSON.stringify(elementData));
  };

  let getAllSharedElementsReq = async () => {
    setIsLoading(true);
    try {
      resetProgress();
      startOrResumeProgress({
        stopAt: 90,
      });
      let response = await getAllSharedElements(project.id, project.type, props.platForm);
      stopProgress();
      setSharedElements(response?.data?.responseObject || []);
      setIsLoading(false);

      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 10,
      });
    } catch (err) {
      resetProgress();
      setIsLoading(false);
      console.error('GET_ALL_SHARED_ELEMNTS : ', err);
    }
  };

  let getUserDetails = async (userId) => {
    let response;
    try {
      response = await getSingleUserRequest(userId);
      if (response?.data?.responseObject) {
        setUserDetails(response.data.responseObject);
        setOpenUserDetailsModal(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const closeModal = (val) => {
    if (elementDetailsModal) {
      setElementDetailsModal(val);
    } else if (openElementModal) {
      setOpenElementModal(val);
    } else if (openUserDetailsModal) {
      setOpenUserDetailsModal(val);
    } else if (locDetailsModal) {
      setLocDetailsModal(val);
    }
  };

  let menuData = (sharedEle) => {
    return (
      <div className="actionIcons">
        {global.permission.isEditAccess('repository') && (
          <Tooltip title="Edit" placement="top">
            <EditOutlinedIcon
              className="float-left text-blue-700 configmoreIcon mr-4 cursor-pointer"
              onClick={() => {
                sharedEle.parentName = sharedEle.pageIdsNames ? returnname(sharedEle.pageIdsNames) : '--';
                let locators = sharedEle.locators;
                let updatedLocators = [];
                locators.forEach((locator) => {
                  if (['static', 'Static'].includes(locator.type)) {
                    updatedLocators.push(locator);
                  } else if (VISUAL_TESTING_IMAGE_FILE_EXTENTION.includes(locator.type)) {
                    updatedLocators.push(locator);
                  } else if (['dynamic', 'Dynamic'].includes(locator.type)) {
                    let value = locator.value;
                    locator.value = value.replaceAll('dynamic', 'Dynamic Val');
                    updatedLocators.push(locator);
                  }
                });
                sharedEle.locators = updatedLocators;
                setSelectedSharedEle(sharedEle);
                setOpenElementModal(true);
              }}
            />
          </Tooltip>
        )}
        <Tooltip title="More" placement="top">
          <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
              <>
                <div>
                  <Menu.Button className="bg-gray-100 rounded-full flex items-center  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 focus:ring-indigo-500">
                    <span className="sr-only">Open options</span>
                    <DotsVerticalIcon className="h-5 w-5 configmoreIcon text-blue-700" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div className="py-1">
                      {contextMenuVariableTabContent.map((data) => (
                        <Menu.Item>
                          {({ active }) => (
                            <label
                              onClick={(event) => {
                                event.preventDefault();
                                if (data.name === 'Details') {
                                  getElementDetails(sharedEle);
                                }
                              }}
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-600' : 'text-gray-600',
                                'block px-4 py-2 text-sm'
                              )}
                            >
                              {data.name}
                            </label>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </Tooltip>
      </div>
    );
  };

  // TODO :: handle Search filter :: START

  const [userSearched, setuserSearched] = useState('');
  let [openSearch, setOpenSearch] = useState(false);
  const [data, setData] = useState([]);
  let [isHighlight, setIsHighlight] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');

  useEffect(() => {
    if (sharedElements) {
      setData(sharedElements);
      if (openSearch && userSearched) {
        handleSearchSubmit(searchTextHighlight);
      }
    }
  }, [sharedElements]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(sharedElements);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setSearchTextHighlight('');
    setData(sharedElements);
    setIsHighlight(!isHighlight);
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const filtertedData = sharedElements.filter(({ name, modifiedByUname }) => {
          return isValueIncludes(e, name) || isValueIncludes(e, modifiedByUname);
        });
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
        setData(filtertedData);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  // TODO :: handle Search filter :: END

  let headerType = 'PAGES';
  if (project.type === 'Mobile') {
    headerType = 'SCREENS';
  } else if (
    (project.type === 'Web' || ['Web & Mobile', 'Salesforce'].includes(project.type)) &&
    props.platForm === 'Web'
  ) {
    headerType = 'PAGES';
  } else if (
    ['Web & Mobile', 'Salesforce'].includes(project.type) &&
    ['Android', 'iOS', 'Ios', 'MobileWeb'].includes(props.platForm)
  ) {
    headerType = 'SCREENS';
  } else {
    headerType = 'PAGES';
  }

  const columns = React.useMemo(
    () => [
      {
        Header: <span className="pl-4">SHARED ELEMENT</span>,
        accessor: 'name',
        width: '15%',
        Cell: (e) => (
          <div
            className="fontPoppinsRegularMd pl-4 text-blue-700 cursor-pointer path-trimmer pr-4"
            onClick={() => {
              settingElementName(e.row.original);
              history.push(
                `/repository/element/shared-elements/${encodeSpecialCharater(e.value)}/Shared Element?eleId=${
                  e.row.original.id
                }`
              );
            }}
            title={e.value || '--'}
          >
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: `SHARED BETWEEN ${headerType}`,
        accessor: 'sharedBetweenPages',
        width: '20%',
        Cell: (e) => {
          return (
            <div
              className="path-trimmer"
              title={e.row.original.pageIdsNames ? returnname(e.row.original.pageIdsNames) : '--'}
            >
              {e.row.original.pageIdsNames ? returnname(e.row.original.pageIdsNames) : '--'}
            </div>
          );
        },
      },
      {
        Header: <span className="float-left">TYPE</span>,
        accessor: 'type',
        Filter: SelectColumnFilter,
        filter: MultipleFilter,
        Cell: (e) => <div>{e.value}</div>,
      },
      {
        Header: 'LOCATORS',
        accessor: 'locatorsCount',
        Cell: (e) => {
          return (
            <span
              className="text-blue-700 cursor-pointer"
              onClick={() => {
                e.row.original.parentName = e.row.original.pageIdsNames
                  ? returnname(e.row.original.pageIdsNames)
                  : '--';
                let locators = e.row.original.locators;
                let updatedLocators = [];
                locators?.forEach((locator) => {
                  if (['static', 'Static'].includes(locator.type)) {
                    updatedLocators.push(locator);
                  } else if (locator.type === 'png') {
                    updatedLocators.push(locator);
                  } else if (['dynamic', 'Dynamic'].includes(locator.type)) {
                    let value = locator.value;
                    locator.value = value.replaceAll('dynamic', 'Dynamic Val');
                    updatedLocators.push(locator);
                  }
                });
                e.row.original.locators = updatedLocators;
                setSelectedSharedEle(e.row.original);
                getElementDetails(e.row.original, 'locDetails');
              }}
            >
              {e.value}
            </span>
          );
        },
      },
      {
        Header: 'MODIFIED BY',
        accessor: 'modifiedByUname',
        Cell: (e) => (
          <Tooltip title={e.value} placement="top">
            <span
              className="text-blue-700 cursor-pointer"
              onClick={() => {
                getUserDetails(e.row.original.modifiedBy);
              }}
            >
              <HighlightText
                text={e.value && e.value > 12 ? e.value.substring(0, 12) + '...' : e.value}
                highlight={searchTextHighlight}
              />
            </span>
          </Tooltip>
        ),
      },
      {
        Header: 'ACTIONS',
        accessor: 'actions',
        Cell: (e) => menuData(e.row.original),
      },
    ],
    [props, isHighlight]
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
    },
    useFilters
  );

  return (
    <div className=" " id="journal-scroll">
      <div className="content-wrapper">
        <div className="content_area_style" id="journal-scroll">
          <div className="flex justify-between items-center m-4 ">
            <div className=" pageHeading">Shared Elements</div>
            <div className=" pr-4">
              <SearchBar
                open={openSearch}
                searchValue={userSearched}
                handleSearch={handleSearch}
                onSearchClose={handleSearchClose}
                handleSearchOpen={handleSearchOpen}
                enterKeyHandler={handleSearchSubmit}
                disable={isEmptyValue(sharedElements)}
              />
            </div>
          </div>
          <div className="sharedPage" id="journal-scroll">
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    className="fontPoppinsSemiboldMd headerStyle h-12 ml-4 sticky top-0 zIndex-1"
                  >
                    {headerGroup.headers.map((column) => (
                      <th width={column.width} {...column.getHeaderProps()} className="text-left">
                        {column.render('Header')}
                        {column.id === 'type' ? <span>{column.canFilter ? column.render('Filter') : null}</span> : null}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="configdataStyle " id="journal-scroll">
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className="configHover h-10 content-start">
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()} className="p-0 text-left fontPoppinsRegularMd">
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {isLoading ? (
              <CommonLoader />
            ) : isEmptyValue(sharedElements) ? (
              <div className="fontPoppinsRegularMd no-steps">
                <div className="">
                  <p className="font-bold mb-6">Quick Start</p>
                  <p className="mb-2">
                    <span className="font-bold">Step 1 :</span>Start making the elements as shared elements from project
                    elements
                  </p>
                </div>
              </div>
            ) : (
              <>{openSearch && isEmptyValue(data) && <NoResultsFound className="mt-14" />}</>
            )}
          </div>

          {elementDetailsModal ? (
            <LocatorDetailsModal
              locatorsArray={selectedLocators}
              popupType={'element'}
              data={selectedSharedEle}
              closeModal={closeModal}
              containerName="Page"
              isShared={true}
              mode="ELEMENT_DETAILS"
            />
          ) : null}
          {locDetailsModal ? (
            <LocatorDetailsModal
              locatorsArray={selectedLocators}
              popupType="locators"
              data={selectedSharedEle}
              closeModal={closeModal}
              containerName="Page"
              mode="LOCATORS_DETAILS"
            />
          ) : null}
          {openElementModal ? (
            <ElementModal
              treeData={[]}
              isShared={true}
              data={selectedSharedEle}
              reloadTree={setReloadTable}
              closeModal={closeModal}
              containerName="Page"
              mode="EDIT"
              MyAlert={props.MyAlert}
              projectType={project.type}
              platForm={props.platForm}
            />
          ) : null}
          {openUserDetailsModal ? <UserDetailsModal data={userDetails} closeModal={closeModal} /> : null}
        </div>
      </div>
    </div>
  );
};

export default withRouter(SharedElements);

import React, { useState, useEffect } from 'react';
import { FilterItem } from './filter-item';
import './filters.css';
import { ChevronDownIcon , ChevronRightIcon } from '@heroicons/react/solid';
import { Checkbox } from 'fireflink-ui';

export const FilterCategory = (props) => {
  const [expandCategory, setExpandCategory] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  };
  useEffect(() => {
    if (props.suiteArray?.includes(props.label) || props.id === 'SubMenu0') {
      setExpandCategory(true);
    } else {
      setExpandCategory(false);
    }
  }, [props]);

  const allowCheck = (categories, parentName) => {

    if (categories && parentName === 'Suite'){
    let element = document.getElementById(props.id);
    if (props.checkedExecutionIds && props.checkedExecutionIds.length > 0) {
      let isFound = false;
      let allfound = categories?.every((category) => props.checkedExecutionIds.includes(category['label']));
      if (allfound) {
        return allfound;
      } else {
        isFound = categories?.some((category) => props.checkedExecutionIds.includes(category['label']));
        if (isFound) {
          return false;
        }
      }
      if (!allfound && !isFound) {
        return false;
      }
    } else {
      return false;
    }
  }
  else if (categories && parentName === 'projectLabel') {
    if (props.checkedLabelsIds && props.checkedLabelsIds?.length > 0) {
      let isFound = false;
      let allfound = [categories]?.every((category) => props.checkedLabelsIds.includes(category['label']));
      if (allfound) {
        return allfound;
      } else {
        isFound = [categories]?.some((category) => props.checkedLabelsIds.includes(category['label']));
        if (isFound) {
          return false;
        }
      }
      if (!allfound && !isFound) {
        return false;
      }
    }
  }
    
  };

  return (
    <div className="category">
      {props.category === 'checkbox' ? (
        <div title={props.label} className="filterData-trimmer filter_category m-2"> 
          <Checkbox
            label={props.label}
            id={props.id}
            name={props.parentName}
            onChange={(e) => props.handleFilterValues(e.target)}
            value={props.label}
            checked={allowCheck(props , props.parentName)}
          /> 
          
        </div>
      ) : (
        <div className="grid grid-cols-6 -ml-3 category_accordian mb-1 ">
          <div
            className="col-span-5 filterData-trimmer pl-2 my-2 inline-block fontPoppinsMediumSm rs-text-black"
            title={props.label}
          >
            {!!props.categories ? props.label : props.parentName}
          </div>
          <div className="col-span-1 inline-block">
            {expandCategory ? (
              <ChevronDownIcon
                className="ChevronIcon cursor-hand Analytics_ChevronIcon"
                onClick={() => {
                  setExpandCategory(false);
                }}
              />
            ) : (
              <ChevronRightIcon
                className="ChevronIcon cursor-hand Analytics_ChevronIcon"
                onClick={() => {
                  setExpandCategory(true);
                }}
              />
            )}
          </div>
        </div>
      )}
      {expandCategory && (
        <div>
          <>
            <div className="flex items-center m-2">             
              <Checkbox
                label={`Total Execution ID (${props?.categories?.length})`}
                name={props.parentName}
                id={props.id}
                onChange={(e) => {
                  props?.handleAll(e, props);
                  handleSelectAll(e);
                }}
                value={props.label}
                checked={allowCheck(props?.categories , props.parentName)}
              />
            </div>
            {props?.categories?.map((filterItem, index) => (
              <FilterItem
                id={filterItem.id}
                {...filterItem}
                key={index}
                checkedExecutionIds={props.checkedExecutionIds}
                checkedLabelsIds={props.checkedLabelsIds}
                handleFilterValues={props.handleFilterValues}
                checked={checked}
              />
            ))}
          </>
        </div>
      )}
    </div>
  );
};

import React, { useEffect, useState, useContext } from 'react';
import Dropzone from 'react-dropzone';
import { getFilesReq, downloadFileFromCloud, updateFileReq } from '@api/api_services';
import { trimPath } from '../../../common/ui-constants';
import { CommonLoader } from '@src/pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import Tooltip from '@material-ui/core/Tooltip';

function FileDetailsPage(props) {
  const [fileData, setFileData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const search = window.location.search;
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const isEditable = React.useMemo(() => window.permission?.isEditAccess('testData'), []);

  const id = new URLSearchParams(search).get('id');
  const parentId = new URLSearchParams(search).get('parentId');
  useEffect(() => {
    getElementDetails(parentId, id);
  }, [id]);

  const { name = '', createdByUname = '', modifiedOn = '', createdOn = '', modifiedByUname = '' } = fileData || {};
  let path;
  if (fileData) {
    path = trimPath(fileData?.actualPath);
  }
  const getElementDetails = (parentId, id) => {
    setIsLoading(true);
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    getFilesReq(id)
      .then((result) => {
        stopProgress();
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 10,
        });
        if (result.data.responseObject) {
          setFileData(result.data.responseObject);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        resetProgress();
        console.error('GET_FILE_REQ : ', err);
      });
  };
  let project = JSON.parse(localStorage.getItem('selected-project'));

  const downloadFile = () => {
    downloadFileFromCloud(fileData.id).then((results) => {
      saveFileFromBlob(results.data, name);
    });
  };
  const replaceFile = async (files, nodeObj) => {
    const file = new FormData();
    files.forEach((data, index) => {
      file.append('file', data);
    });
    let response;
    try {
      response = await updateFileReq(nodeObj.id, file);
      if (response.data.responseCode === 200 && response.data.status === 'SUCCESS') {
        props.MyAlert.success(`${response.data.responseObject.name} file replaced successfully`);
      } else {
        props.MyAlert.info(response.data.message);
      }
    } catch (err) {
      console.error('file replacement error', err);
    }
  };

  function saveFileFromBlob(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  }
  if (isLoading) {
    return <CommonLoader />;
  }

  return (
    <div className="page-table-body">
      {/* basavarj changes */}
      <div className="content-wrapper">
        <div className="content_area_style" id="journal-scroll">
          {fileData ? (
            <>
              <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
                <label className="project_label text-sm flex-auto title-trim" title={name}>
                  {name}
                </label>
              </div>
              <hr className="divider-file mt-3" />
              <div className="table-height overflow-auto " id="journal-scroll">
                <div className="grid grid-cols-3">
                  <div className="">
                    <div className="grid-rows-4 ml-8">
                      <div className="">
                        <div className="mt-8 ">
                          <h1 className="details-key-style-common">Name</h1>
                          <p className="pt-2 text-gray-700">
                            {name?.length > 17 ? (
                              <Tooltip title={name || '--'} placement="bottom-start">
                                <div className="details-data-style-common file-Name">{name || '--'}</div>
                              </Tooltip>
                            ) : (
                              <div className="details-data-style-common">{name || '--'}</div>
                            )}
                            <Tooltip title="Download" placement="right">
                              <img
                                style={{
                                  cursor: 'pointer',
                                }}
                                className="inline ml-40 -mt-12 text-lg"
                                onClick={downloadFile}
                                src="/assets/images/file_download.svg"
                                alt=""
                                height="20px"
                                width="20px"
                              />
                            </Tooltip>
                            {isEditable && (
                              <Dropzone noDrag={true} onDrop={(acceptedFiles) => replaceFile(acceptedFiles, fileData)}>
                                {({ getRootProps, getInputProps }) => (
                                  <Tooltip title="Replace" placement="right">
                                    <div
                                      {...getRootProps({
                                        className: 'dropzone inline',
                                      })}
                                    >
                                      <input {...getInputProps()} className="inline-block" />
                                      <img
                                        className="inline ml-3 -mt-12 text-lg"
                                        src="/assets/execution_logos/Replace_File.svg"
                                        alt=""
                                        height="20px"
                                        width="20px"
                                      />
                                    </div>
                                  </Tooltip>
                                )}
                              </Dropzone>
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="">
                        <div className="mt-10">
                          <h1 className="details-key-style-common">Created By</h1>
                          <div className="pt-2 text-gray-700">
                            <Tooltip title={createdByUname} placement="bottom-start">
                              <div className="details-data-style-common ">
                                {createdByUname
                                  ? createdByUname.length > 16
                                    ? createdByUname.substring(0, 16) + '...'
                                    : createdByUname
                                  : '--'}
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="mt-10">
                          <h1 className="details-key-style-common">Modified On</h1>
                          <p className="pt-2 text-gray-700">
                            <span className="details-data-style-common">{modifiedOn || '--'}</span>
                          </p>
                        </div>
                      </div>
                      <div className=""></div>
                    </div>
                  </div>
                  <div className="">
                    <div className="grid-rows-3">
                      <div className="">
                        <div className="mt-8">
                          <h1 className="details-key-style-common">Project Name</h1>
                          <p className="pt-2 text-gray-700">
                            <span className="details-data-style-common">{project.name}</span>
                          </p>
                        </div>
                      </div>
                      <div className="">
                        <div className="mt-12">
                          <h1 className="details-key-style-common">Created On</h1>
                          <div className="pt-2 text-gray-700">
                            <div className="details-data-style-common ">{createdOn || '--'}</div>
                          </div>
                        </div>
                      </div>
                      <div className=""></div>
                    </div>
                  </div>
                  <div className="">
                    <div className="grid-rows-3">
                      <div className="">
                        <div className="mt-8">
                          <h1 className="details-key-style-common">Path</h1>
                          <div className="pt-2 text-gray-700">
                            <Tooltip title={path || '--'} placement="bottom-start">
                              <div className="details-data-style-common desc-modal">{path || '--'} </div>
                            </Tooltip>
                            <div className="details-data-style-common desc"></div>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="mt-12">
                          <h1 className="details-key-style-common">Modified By</h1>
                          <div className="pt-2 text-gray-700">
                            {modifiedByUname && (
                              <Tooltip title={modifiedByUname} placement="bottom-start">
                                <div className="details-data-style-common">
                                  {modifiedByUname
                                    ? modifiedByUname.length > 20
                                      ? modifiedByUname.substring(0, 20) + '...'
                                      : modifiedByUname
                                    : '--'}
                                </div>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className=""> </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default FileDetailsPage;

import cx from 'classnames';
import DashboardStyle from '../styles/dashboard.module.scss';
import SectionHeader from '../components/SectionHeader';
import ExecutionSuitesStatusChart from '../charts/ExecutionSuitesStatusChart';
import ExecutionSuitesInfo from '../components/ExecutionSuitesInfo';
import ExecutionSuiteModules from '../charts/ExecutionSuiteModules';
import ExecutionSuiteScripts from '../charts/ExecutionSuiteScripts';
import ExecutionSchedulesInfo from '../components/ExecutionSchedulesInfo';
import { useEffect, useState } from 'react';
import {
  getIndividualProjectScheduledExecution,
  getIndividualProjectSuiteInfoGraph,
  getIndividualProjectSuiteNames,
} from '@src/api/api_services';

function ExecutionSuitesAndSchedules({ projectId }) {
  const [projectExecutionSuiteList, setProjectExecutionSuiteList] = useState(null);
  const [suiteInfoGraphData, setSuiteInfoGraphData] = useState(null);
  const [projectScheduledExecutionData, setProjectScheduledExecutionData] = useState(null);
  const [getMachineSuiteIndex, setGetMachineSuiteIndex] = useState(null);
  const [moduleInfo, setModulesInfo] = useState(null);
  const [scriptInfo, setScriptsInfo] = useState(null);

  useEffect(() => {
    getIndividualProjectSuiteNamesList();
    getIndividualProjectSuiteInfoGraphData();
    getIndividualProjectScheduledExecutionData();
  }, [projectId]);

  const getIndividualProjectSuiteNamesList = () => {
    getIndividualProjectSuiteNames().then((response) => {
      setProjectExecutionSuiteList(response?.data?.responseObject?.filter((suite) => suite._id));
    });
  };
  const getIndividualProjectSuiteInfoGraphData = () => {
    getIndividualProjectSuiteInfoGraph().then((response) => {
      setSuiteInfoGraphData(response?.data?.responseObject);
    });
  };
  const getIndividualProjectScheduledExecutionData = () => {
    getIndividualProjectScheduledExecution().then((response) => {
      setProjectScheduledExecutionData(response?.data?.responseObject?.executionSchedules);
    });
  };
  function handleExecutionSuiteChange() {
    setModulesInfo(getMachineSuiteIndex?.moduleInfo);
    setScriptsInfo(getMachineSuiteIndex?.scriptInfo);
  }

  useEffect(() => {
    handleExecutionSuiteChange();
  }, [getMachineSuiteIndex]);

  return (
    <div className={cx('grid grid-cols-4 gap-6 mb-6 ', DashboardStyle['execution-suites-and-schedules-section'])}>
      <div
        className={cx({
          'col-span-3': projectScheduledExecutionData?.length,
          'col-span-4': !projectScheduledExecutionData?.length,
        })}
      >
        <SectionHeader>EXECUTION SUITES</SectionHeader>
        <div className={cx('grid grid-cols-3 gap-2 my-1', DashboardStyle['content-wrapper'])}>
          <ExecutionSuitesStatusChart suiteData={suiteInfoGraphData} />

          <ExecutionSuitesInfo
            suites={projectExecutionSuiteList}
            handleSuiteChange={handleExecutionSuiteChange}
            setGetMachineSuiteIndex={setGetMachineSuiteIndex}
          />
          <div className={cx('grid grid-rows-2 gap-2 grid-cols-1', DashboardStyle['content-wrapper'])}>
            <ExecutionSuiteModules modulesData={moduleInfo} />
            <ExecutionSuiteScripts scriptsData={scriptInfo} />
          </div>
        </div>
      </div>
      {projectScheduledExecutionData?.length ? (
        <div className="col-span-1">
          <SectionHeader>EXECUTION SCHEDULES</SectionHeader>
          <div className={cx('my-1', DashboardStyle['content-wrapper'])}>
            <ExecutionSchedulesInfo schedules={projectScheduledExecutionData} />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ExecutionSuitesAndSchedules;

import { AZURE_DEVOPS_CONSTANTS, SUITES_CONSTANTS } from '@src/common/ui-constants';
import DownloadClientModal from '@src/pages/configuration/system_config/system_config v1.1/modals/download_client_modal';
import { Button, Icon } from 'fireflink-ui';
function InvalidMachineModal({ handleClose = () => {}, closeModal, downloadClientModal }) {
  return (
    <>
      <div className="col-span-5 details-div allign-div-center config_div_table_height">
        {downloadClientModal && <DownloadClientModal closeModal={closeModal} />}
        <div className="fontPoppinsRegularMd">
          <div className="">
            <p className="font-bold mb-4">{AZURE_DEVOPS_CONSTANTS.QUICK_START}</p>
            <p className="mb-4 flex items-center">
              <span className="font-bold">{AZURE_DEVOPS_CONSTANTS.STEP_1}:&nbsp;</span> Click &nbsp;
              <Button
                label={SUITES_CONSTANTS.DOWNLOAD_CLIENT}
                variant="primary"
                disabled={false}
                onClick={() => {
                  handleClose();
                }}
                size="small"
              >
                <div className="flex allign-div-center ">
                  <Icon name="download" hoverEffect={true} height={15} width={15} />
                  <span>{SUITES_CONSTANTS.DOWNLOAD_CLIENT}</span>
                </div>
              </Button>
              &nbsp;&nbsp; {SUITES_CONSTANTS.AUTOMATICALLY_CONFIGURE_LOCAL_MACHINE} &nbsp;
              <span>&#38;&nbsp;</span> {SUITES_CONSTANTS.DEVICE}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default InvalidMachineModal;

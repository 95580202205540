import React, { useState, useEffect, useRef } from 'react';
import styles from './analytics.module.scss';
import { colors } from '@src/css_config/colorConstants';
import FailureProgressBar from './common/FailureProgressBar';
import cx from 'classnames';
import AnalyticTable from './common/analyticTable';
import { noDataMessage } from '@common/ui-constants';
import { getTableData, getTableName } from './common/util';
import { scrollTop } from '@src/util/common_utils';

const FailureAnalysisComp = (props) => {
  let {
    failures,
    scriptResultData,
    openFailureAnalysisResultModal,
    closeResultModal,
    selectedScript,
    selectedScriptName,
    getScriptResultData,
    setSelectedScript,
    setSelectedScriptName,
    suiteMode,
    reqBody,
    callTableData,
  } = props;

  let getTableHeader = () => {
    let num = 0;
    let header = 'known Failures';

    failures?.forEach((item, index) => {
      if (Number(item.resultPercentage) > num) {
        num = Number(item.resultPercentage);
        header = item.resultName;
      }
    });
    return header;
  };
  let tempHeader = getTableHeader();

  const [initTableCall, setInitTableCall] = useState(true);
  const [failureRateResultItems, setFailureRateResultItems] = useState({
    header: getTableHeader(),
    color:
      tempHeader === 'Unknown Failures'
        ? colors.rs_failed
        : tempHeader === 'Partially Analysed Failures'
          ? colors.yellow_failure
          : tempHeader === 'known Failures'
            ? colors.orange_failure
            : '',
    resultItems: [],
    isSelected: true,
  });
  const [isFetchingData, setIsFetchingData] = useState(false);

  const [stopScrollApiCall, setStopScrollApiCall] = useState(0); //? to stop scroll api on count of totalScripts
  const failureRateRef = useRef();
  const [failureRateScrollPos, setFailureRateScrollPos] = useState(false);
  const [initTable, setInitTable] = useState({
    from: 0,
    to: 20,
  });

  const getFailureRateData = async (from, to, tableHeader) => {
    let tempHeader = getTableName(tableHeader);
    try {
      if (from === 0 && to === 20) {
        let data = await getTableData(reqBody, tempHeader, from, to);
        setFailureRateResultItems({
          ...failureRateResultItems,
          resultItems: data?.scripts,
        });
        setStopScrollApiCall(data?.totalScripts);
      } else {
        if (stopScrollApiCall > initTable.from) {
          let data = await getTableData(reqBody, tempHeader, from, to);
          setFailureRateResultItems({
            ...failureRateResultItems,
            resultItems: [...failureRateResultItems.resultItems, ...data?.scripts],
          });
        }
      }
    } catch (error) {
      console.error('FAILURE ANALYSIS API FAILED!', error);
    }
  };
  useEffect(() => {
    getFailureRateData(0, 20, tempHeader);
    setInitTableCall(false);
  }, []);

  useEffect(() => {
    if (callTableData && initTableCall === false) {
      setInitTable({
        from: 0,
        to: 20,
      });
      setFailureRateResultItems({ ...failureRateResultItems, resultItems: [] });
      scrollTop(failureRateRef);
      getFailureRateData(0, 20, failureRateResultItems.header);
    }
  }, [callTableData]);

  const fetchData = async () => {
    if (callTableData) {
      try {
        setIsFetchingData(true);

        // Make the API call
        await getFailureRateData(initTable.from, initTable.to, failureRateResultItems.header);
      } catch (error) {
        console.error(error);
      } finally {
        setIsFetchingData(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [failureRateResultItems.header]);

  useEffect(() => {
    if (failureRateScrollPos) {
      setInitTable({
        from: initTable.to,
        to: initTable.to + 10,
      });
    }
  }, [failureRateScrollPos]);

  useEffect(() => {
    if (initTable.from !== 0 && initTable.to !== 20 && callTableData === false) {
      getFailureRateData(initTable.from, initTable.to, failureRateResultItems.header);
    }
  }, [initTable]);

  let failureAnalysisColumn = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: '20%',
        Cell: ({ row, value }) => {
          let { scriptId, executionId, name , clientId , runId} = row.original;
          return (
            <div
              className={`overflow-hidden overflow-ellipsis whitespace-nowrap pr-2 rs-blue ${runId ? 'cursor-pointer' : 'cursor-default rs-skipped'}`}
              onClick={() => {
                if (runId && suiteMode === 'automation-suite') {
                  getScriptResultData(scriptId, executionId, clientId , runId , 'failureAnalysisModal');
                  setSelectedScript({ scriptId, executionId });
                  setSelectedScriptName(name);
                }
              }}
              title={value}
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Module',
        accessor: 'pathData',
        width: '20%',
        disableGlobalFilter: true,
        Cell: ({ value }) => {
          return (
            <div className="overflow-hidden overflow-ellipsis whitespace-nowrap pr-2" title={value.path}>
              {value.module}
            </div>
          );
        },
      },
      {
        Header: 'Execution Id',
        accessor: 'executionId',
        width: '20%',
        Cell: ({ value }) => {
          return <div className="overflow-hidden overflow-ellipsis whitespace-nowrap pr-2">{value}</div>;
        },
      },
      {
        Header: 'Tagged Steps',
        accessor: 'count',
        width: '20%',
        disableGlobalFilter: true,
        Cell: ({ value }) => {
          return <div className="overflow-hidden overflow-ellipsis whitespace-nowrap pr-2">{value}</div>;
        },
      },
    ],
    []
  );

  return (
    <section className={styles['failureAnalysisBlock']}>
      <div className={styles['failureAnalaysisChartBlock']}>
        <div className={cx(styles['failureAnalysisHeader'], 'fontPoppinsSemiboldSm uppercase')}>Failure Analysis</div>
        <div className={styles['progressBlock']}>
          {failures?.map((fail, index) => {
            let { resultName } = fail;
            let data = {
              ...fail,
              color:
                resultName === 'Unknown Failures'
                  ? colors.rs_failed
                  : resultName === 'Partially Analysed Failures'
                    ? colors.yellow_failure
                    : resultName === 'known Failures'
                      ? colors.orange_failure
                      : '',
            };
            const handleClick = async () => {
              // TODO :: add api call to get Table data on click
              setInitTable({
                from: 0,
                to: 20,
              });
              scrollTop(failureRateRef);

              setFailureRateResultItems({
                ...failureRateResultItems,
                header: resultName,
                isSelected: true,
                color:
                  resultName === 'Unknown Failures'
                    ? colors.rs_failed
                    : resultName === 'Partially Analysed Failures'
                      ? colors.yellow_failure
                      : resultName === 'known Failures'
                        ? colors.orange_failure
                        : '',
              });
            };
            return (
              <FailureProgressBar
                data={data}
                handleClick={handleClick}
                key={`${resultName}-${index}`}
                failureRateResultItems={failureRateResultItems}
                isFetchingData={isFetchingData}
              />
            );
          })}
        </div>
      </div>
      <div className={styles['failureAnalysisTable']}>
        <AnalyticTable
          setScrollPos={setFailureRateScrollPos}
          ref={failureRateRef}
          color={failureRateResultItems.color}
          header={failureRateResultItems.header}
          columns={failureAnalysisColumn}
          data={failureRateResultItems.resultItems || []}
          message={noDataMessage.failureAnalysis}
          scriptResultData={scriptResultData}
          openScriptResultModal={openFailureAnalysisResultModal}
          closeResultModal={closeResultModal}
          selectedScript={selectedScript}
          selectedScriptName={selectedScriptName}
        />
      </div>
    </section>
  );
};

export default FailureAnalysisComp;

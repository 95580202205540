import { Box, Skeleton } from '@mui/material';

const BugSummarySkeltonLoader = () => {
  let ThreeBox = (_) => (
    <Box className="w-full flex justify-start gap-24 items-center m-2">
      <Skeleton variant="rounded" width={210} height={60} />
      <Skeleton variant="rounded" width={210} height={60} />
      <Skeleton variant="rounded" width={210} height={60} />
    </Box>
  );

  return (
    <>
      <ThreeBox />
      <div className="m-2">
        <Skeleton variant="rounded" height={150} />
      </div>
      <ThreeBox />
      <ThreeBox />
      <ThreeBox />
      <ThreeBox />
    </>
  );
};

export default BugSummarySkeltonLoader;

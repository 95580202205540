import React, { useState } from 'react';
import { Link, useHistory, Switch, Route } from 'react-router-dom';
import ImportExportProject from './ImportExportProject';
import { useAlert } from '@src/pages/common/alert_service/useAlert';

function ImportAndExportLandingPage() {
  const history = useHistory();
  const pathArray = history.location.pathname.split('/');
  const activeTab = pathArray[pathArray.length - 1];
  const { AlertContatiner, MyAlert } = useAlert();

  const tabsList = [
    {
      name: 'Import',
      pathUrl: `/configuration/import-export/Import`,
      selectedName: 'Import',
    },

    {
      name: 'Export',
      pathUrl: `/configuration/import-export/Export`,
      selectedName: 'Export',
    },
  ];

  function renderTabs() {
    if (!['Import', 'Export'].includes(activeTab)) {
      return null;
    } else {
      return (
        <div>
          <ul className="navBar text-blue-600 flex flex-row primary-tab">
            {tabsList.map((tab, index) => {
              return (
                <li key={tab.name}>
                  <Link to={tab.pathUrl} className={`${activeTab === tab.selectedName ? 'selected_tab_style' : ''}`}>
                    {tab.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
  }
  return (
    <>
      {renderTabs()}
      <Switch>
        <Route
          exact
          path="/configuration/import-export/Import"
          render={(props) => (
            <ImportExportProject
              key="import"
              MyAlert={MyAlert}
              isExportPage={false}
              AlertContatiner={AlertContatiner}
            />
          )}
        />
        <Route
          exact
          path="/configuration/import-export/Export"
          render={(props) => (
            <ImportExportProject key="export" MyAlert={MyAlert} isExportPage={true} AlertContatiner={AlertContatiner} />
          )}
        />
      </Switch>
    </>
  );
}

export default ImportAndExportLandingPage;

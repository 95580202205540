import React, { useState, useEffect } from 'react';
import TableTree from '@pages/common/table_tree/table_tree';
import { Tooltip } from '@material-ui/core';
import UserMachineChildPage from './user-machine-child';
import { TREE_LABELS } from '@src/common/ui-constants';
import { getCurrentProjectData, isEmptyValue } from '@src/util/common_utils';

const UserMachineDistribution = (props) => {
  let [treeData, setTreeData] = useState([]);
  let [rootData, setRootData] = useState([]);
  let [isExpandAll, setIsExpandAll] = useState(false);
  let [isLoading, setIsLoading] = React.useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  const project = getCurrentProjectData();
  const moduleArray = props?.moduleArray ? [...props.moduleArray] : [];
  const [selectedNodeArray, setSelectedNodeArray] = React.useState([]);
  const [userMachineData, setUserMachineData] = React.useState([]);
  const [userMachineScripts, setUserMachineScripts] = React.useState({});
  const [userMachineDistributedData, setUserMachineDistributedData] = React.useState([]);
  let renderTree;

  const treeDataColumns = () => {
    let treeColumns = [];
    if (project?.type?.toLowerCase().includes('web')) {
      treeColumns.push(
        {
          field: 'Browser',
          title: 'Browser',
          class: 'browser truncate',
          width: '80px',
          render: (nodeObj) => {
            return (
              <Tooltip title={nodeObj.data.browser ? nodeObj.data.browser : ''}>
                <span className="version-text-color">{nodeObj.data.browser}</span>
              </Tooltip>
            );
          },
        },
        {
          field: 'Browser Version',
          title: 'Browser Version',
          class: 'browserVersion',
          width: '70px',
          render: (nodeObj) => {
            return (
              <Tooltip title={nodeObj.data.browserVersion ? nodeObj.data.browserVersion : ''}>
                <div className="version-text-color truncate">{nodeObj.data.browserVersion}</div>
              </Tooltip>
            );
          },
        }
      );
    }

    if (project?.type?.toLowerCase().includes('mobile')) {
      treeColumns.push(
        {
          field: 'Device',
          title: 'Device',
          class: 'device truncate',
          width: '60px',
          render: (nodeObj) => {
            return (
              <Tooltip title={nodeObj.data?.device ? nodeObj.data.device : ''}>
                <span className="version-text-color">{nodeObj.data.device ? nodeObj.data.device : ''}</span>
              </Tooltip>
            );
          },
        },
        {
          field: 'Device Version',
          title: 'Device Version',
          class: 'deviceVersion',
          width: '50px',
          render: (nodeObj) => {
            return (
              <Tooltip title={nodeObj.data?.deviceVersion ? nodeObj.data.deviceVersion : ''}>
                <div className="version-text-color truncate">
                  {nodeObj.data.deviceVersion ? nodeObj.data.deviceVersion : ''}
                </div>
              </Tooltip>
            );
          },
        }
      );
    }
    return treeColumns;
  };

  const commonColumns = [
    {
      field: 'title',
      title: 'Module',
      class: 'title',
      isTitle: true,
      width: `490px`,
      folderTitleWidth: `490px`,
      render: (nodeObj) => {
        return (
          <>
            {nodeObj.isRootNode && showCheckbox && (
              <>
                <span class="count-badge folder-count ml-2">
                  M {treeData?.[0]?.totalModuleCount ? treeData[0]?.totalModuleCount : 0}{' '}
                </span>
                <span class="count-badge file-count">
                  {' '}
                  S {treeData?.[0]?.totalScriptCount ? treeData[0]?.totalScriptCount : 0}{' '}
                </span>
              </>
            )}
            {!nodeObj.isRootNode && nodeObj.data.scriptCount > 0 && (
              <span class="count-badge file-count"> S {nodeObj.data.scriptCount} </span>
            )}
          </>
        );
      },
    },
    {
      field: 'User',
      title: 'User',
      class: 'User truncate',
      width: '130px',
      render: (nodeObj) => {
        return (
          <Tooltip title={nodeObj.data.user ? nodeObj.data.user : ''}>
            <span className="text-xs table-non-link-color-common">{nodeObj.data.user}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'Client Machine',
      title: 'Client Machine',
      class: 'ClientMachine truncate',
      width: '120px',
      render: (nodeObj) => {
        return (
          <Tooltip title={nodeObj.data.clientMachine ? nodeObj.data.clientMachine : ''}>
            <span className="text-blue-700 link cursor-pointer">{nodeObj.data.clientMachine}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'Execution Environment',
      title: 'Execution Environment',
      class: 'executionEnvironment',
      width: '110px',
      render: (nodeObj) => {
        return <span className="text-blue-700 link cursor-pointer">{nodeObj.data.executionEnv}</span>;
      },
    },
    {
      field: 'Os',
      title: 'Os',
      class: 'os truncate',
      width: '100px',
      render: (nodeObj) => {
        return (
          <Tooltip title={nodeObj.data.Os ? nodeObj.data.Os : ''}>
            <span className="version-text-color">{nodeObj.data.Os ? nodeObj.data.Os : ''}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'Os Version',
      title: 'Os Version',
      class: 'osVersion truncate',
      width: '90px',
      render: (nodeObj) => {
        return (
          <Tooltip title={nodeObj.data.osVersion ? nodeObj.data.osVersion : ''}>
            <span className="version-text-color">{nodeObj.data.osVersion ? nodeObj.data.osVersion : ''}</span>
          </Tooltip>
        );
      },
    },
  ];

  const colDefs = commonColumns.concat(treeDataColumns());

  if (isLoading) {
    renderTree = (
      <div className="base-h flex items-center justify-center">
        <span className="text-center font-bold align-middle empty_page_info -mt-20">
          <h2>data loading...</h2>
        </span>
      </div>
    );
  } else if (treeData && treeData.length > 0) {
    renderTree = (
      <TableTree
        data={treeData}
        rootData={rootData}
        colDefs={colDefs}
        filter={''}
        expandAll={isExpandAll}
        hideElements={false}
        hideConditions={true}
        showCheckbox={showCheckbox}
        conditions={[]}
        hideConditionBtn={true}
        onCheckedNodes={onCheckedNodes}
        labels={TREE_LABELS}
        onNodeSelected={onNodeSelected}
        id="userDistributeTree"
      />
    );
  } else {
    renderTree = (
      <div>
        <div>
          <TableTree
            data={[]}
            rootData={rootData}
            colDefs={colDefs}
            filter={''}
            showCheckbox={true}
            onCheckedNodes={onCheckedNodes}
            expandAll={isExpandAll}
            hideElements={false}
            hideConditions={true}
            conditions={[]}
            hideConditionBtn={true}
            labels={TREE_LABELS}
            onNodeSelected={onNodeSelected}
            id="userDistributeTree"
          />
        </div>
        <div className="overflow-y-auto h-82">
          <div className="fontPoppinsRegularMd mt-28 no-steps">
            <div className="create-set-layout">
              <div className="qucBtn">
                <label className="quick-start-style px-1">Select some modules</label>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  useEffect(() => {
    setUserMachineData(props?.userMachineList?.length ? props?.userMachineList : []);
    if (moduleArray?.length) {
      moduleArray?.forEach((obj) => {
        if (obj) {
          obj.checkbox = true;
          ['user', 'browser', 'browserVersion', 'device', 'executionEnv', 'Os', 'osVersion', 'clientMachine'].forEach(
            (e) => delete obj[e]
          );
        }
      });
    }
    setTreeData([]);
    let _userMachineData = userMachineData;
    let _userMachineDistributedData = userMachineDistributedData;
    let _userMachineScripts = {};
    const machines = props?.runSettingsManualObject?.machines;
    const { selectedMachines, executionType, distribution } = { ...machines };
    if (isEmptyValue(_userMachineData) && machines && executionType === 'DISTRIBUTE') {
      _userMachineData = selectedMachines;
      _userMachineDistributedData = distribution;
    }
    if (_userMachineData?.length && _userMachineDistributedData) {
      reloadModuleTree(_userMachineDistributedData, _userMachineData, moduleArray, _userMachineScripts);
      setUserMachineScripts({ ..._userMachineScripts });
    } else {
      updateModuleTree();
    }
  }, [props?.moduleArray]);

  function updateModuleTree(modulesObject) {
    setIsLoading(true);
    let root;
    let rootChildren;
    let rootDetails = {};
    rootDetails.children = modulesObject ? modulesObject : [...moduleArray];
    const _rootDetails = rootDetails.children[0];
    root = {
      subModuleCount: _rootDetails?.totalModuleCount,
      title: _rootDetails?.parentName,
      key: _rootDetails?.parentId,
      ver: _rootDetails?.ver,
      modifiedByUname: _rootDetails?.modifiedByUname,
      scriptCount: _rootDetails?.totalScriptCount,
    };
    rootChildren = rootDetails.children;
    setRootData(root);
    setIsExpandAll(rootDetails.expanded);
    if (isEmptyValue(rootChildren)) {
      setShowCheckbox(false);
    } else {
      setShowCheckbox(true);
    }
    setTreeData(rootChildren);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }

  function onCheckedNodes(nodes) {
    setSelectedNodeArray(nodes);
  }

  function onNodeSelected() {}

  const handleModal = (modalStatus) => {
    setOpenUserModal(modalStatus);
  };

  const handleSelecteduser = (machine) => {
    if (machine && machine.length) {
      let userMachine = machine[0];
      let selectedMachines = userMachineData ? userMachineData : [];
      let scriptToMachines = userMachineScripts ? userMachineScripts : {};
      if (selectedMachines?.length) {
        selectedMachines = selectedMachines.filter(function (obj) {
          return obj['clientId'] !== userMachine['clientId'];
        });
      }
      selectedMachines.push(userMachine);
      let tempSelectedNodArray = [...selectedNodeArray];
      tempSelectedNodArray.forEach((modObj) => {
        if (!modObj?.data?.folder && modObj?.data?.searchKey) {
          scriptToMachines[modObj?.data?.searchKey] = userMachine['clientId'];
        }
      });
      setUserMachineScripts({ ...scriptToMachines });
      updateDistributionObject(scriptToMachines, selectedMachines, true);
      handleModal(false);
      setSelectedNodeArray([]);
    }
  };

  const updateDistributionObject = (scriptToMachines, selectedMachines, update) => {
    let distributionMachineObject = getDistributionObject(scriptToMachines, selectedMachines);
    selectedMachines = distributionMachineObject['machines'];
    setUserMachineData([...selectedMachines]);
    setUserMachineDistributedData([...distributionMachineObject['distribution']]);
    if (update) {
      reloadModuleTree(distributionMachineObject['distribution'], selectedMachines);
    }
    props.handleSelecteduser(selectedMachines, distributionMachineObject['distribution']);
  };

  const copyData = (childrenObject) => {
    return JSON.parse(JSON.stringify(childrenObject));
  };

  const reloadModuleTree = (distributionObject, selectedMachines, childrenObj, scriptToMachines) => {
    let modulesData = childrenObj ? copyData(childrenObj) : copyData(moduleArray);
    if (distributionObject) {
      assigneMachinesToNode(distributionObject, selectedMachines, modulesData, scriptToMachines);
    }
    if (scriptToMachines && selectedMachines) {
      updateDistributionObject(scriptToMachines, selectedMachines);
    }
    updateModuleTree(modulesData);
  };

  const assigneMachinesToNode = (distributionObject, selectedMachines, childrenObj, scriptToMachines) => {
    childrenObj.map((nodeObj) => {
      if (nodeObj?.children?.length) {
        assigneMachinesToNode(distributionObject, selectedMachines, nodeObj?.children, scriptToMachines);
      } else {
        let clientObj = getClientMachineObject(distributionObject, selectedMachines, nodeObj?.searchKey);
        clientObj = clientObj?.machineInstances?.length ? clientObj?.machineInstances?.[0] : clientObj;
        if (clientObj) {
          if (scriptToMachines) {
            scriptToMachines[nodeObj.searchKey] = clientObj.clientId;
          }
          nodeObj.user = clientObj.clientId;
          nodeObj.browser = clientObj.browserName;
          nodeObj.Os = clientObj.machineInfo?.osName;
          nodeObj.osVersion = clientObj.machineInfo?.osVersion;
          nodeObj.clientMachine = clientObj.machineInfo?.hostName;
          nodeObj.executionEnv = clientObj.executionEnv;
          nodeObj.browserVersion = clientObj.browserVersion;
          if (clientObj?.deviceInfo?.length) {
            let deviceName;
            let deviceVersion;
            clientObj?.deviceInfo?.forEach((obj) => {
              if (deviceName) {
                deviceName = deviceName + ',' + obj?.name;
                deviceVersion = deviceVersion + ',' + obj?.version;
              } else {
                deviceName = obj?.name;
                deviceVersion = obj?.version;
              }
            });
            nodeObj.device = deviceName;
            nodeObj.deviceVersion = deviceVersion;
          } else {
            nodeObj.device = '';
          }
        }
      }
    });
    return childrenObj;
  };

  const getClientMachineObject = (distributionObject, selectedMachines, searchKey) => {
    let distributeObj = distributionObject.find(
      (resObj) => resObj?.selectedModules && resObj?.selectedModules.includes(searchKey)
    );
    if (distributeObj) {
      return selectedMachines.find((machine) => machine?.clientId === distributeObj?.clientSystemId);
    }
  };

  const getDistributionObject = (scriptToMachines, selectedMachines) => {
    let tempDistributionData = [];
    let clientIds = [];
    let distributedMachineObj = {};
    Object.entries(scriptToMachines).map(([key, value]) => {
      let obj = {
        client: value,
        searchKey: key,
      };
      tempDistributionData.push(obj);
    });
    var group_to_values = tempDistributionData.reduce(function (obj, item) {
      obj[item.client] = obj[item.client] || [];
      obj[item.client].push(item.searchKey);
      return obj;
    }, {});
    var finalDistributionData = Object.keys(group_to_values).map(function (key) {
      let selectedScripts = group_to_values[key];
      if (selectedScripts?.length) {
        clientIds.push(key);
        return { clientSystemId: key, selectedModules: selectedScripts };
      }
    });
    if (selectedMachines?.length) {
      distributedMachineObj['machines'] = selectedMachines.filter(function (obj) {
        return clientIds.includes(obj['clientId']);
      });
    }
    distributedMachineObj['distribution'] = finalDistributionData.filter((x) => x !== undefined);
    return distributedMachineObj;
  };

  return (
    <>
      <div className="content_area_header_style">
        <div>
          <label className="main-header-label float-left ">Modules / Scripts</label>
        </div>
        <div className="flex flex-row float-right">
          <button
            className={`${props?.rerunMode ? 'secondary-btn-disable opacity-50 cursor-auto' : selectedNodeArray?.length ? 'secondary-btn' : 'secondary-btn-disable opacity-50 cursor-auto'} mr-3`}
            disabled={props?.rerunMode || !selectedNodeArray?.length}
            onClick={() => {
              handleModal(true);
            }}
          >
            Select User
          </button>
        </div>
      </div>
      <div className="manual_tree_height overflow-auto grid" id="journal-scroll">
        {renderTree}
      </div>

      {openUserModal && (
        <UserMachineChildPage
          userData={props.userData}
          selectedUserType={props.selectedUserType}
          handleSelecteduser={handleSelecteduser}
          userMachineList={[]}
          osDetails={props.osDetails}
          browserDetails={props.browserDetails}
          enableAddButton={props.enableAddButton}
          selectedProject={props.selectedProject}
          openModal={openUserModal}
          handleModal={handleModal}
          selectedOption={props.selectedOption}
        />
      )}
    </>
  );
};

export default UserMachineDistribution;

import React, { useEffect, Fragment, useState, useContext } from 'react';

import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import EditIcon from '@mui/icons-material/Edit';

import { getGithubList } from '@api/api_services';

import GitlabDetails from './gitHub_details';
import { Menu, Transition } from '@headlessui/react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CommonLoader } from '@src/pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { Tooltip } from '@material-ui/core';
import SearchBar from '@pages/analytics/common/search-bar';
import { HighlightText, isEmptyValue, isValueIncludes } from '@util/common_utils';
import NoResultsFound from '@pages/common/Table/NoResultFound';
import SyncIcon from '@mui/icons-material/Sync';
import { useTable } from 'react-table';
import { GITHUB_CONSTANTS } from '../plugin_constants';
import { ReactComponent as QuickStart } from '@assets/svg-imports/QuickStart.svg';
import { ReactComponent as AddIcon } from '@assets/svg-imports/addIcon.svg';
import GithubCreateEditInstance from './github-Create_Edit-Instance';
import GitHubSyncronizeInstance from './github-syncronize';
import DeleteInstanceGithub from './deleteInstanceGithub';

function GithubTable(props) {
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [reloadTree, setReloadTree] = useState(true);
  let [isLoading, setIsLoading] = useState(false);
  const [account, setAccount] = useState([]);
  const [accountData, setAccountData] = useState();
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [mode, setMode] = useState('');
  let [isHighlight, setIsHighlight] = useState(false);
  const [openSync, setOpenSync] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');
  const [pageIndexValue, setPageIndex] = useState(0);

  const openSyncModel = (value, actionType) => {
    setOpenSync(true);
  };
  const closeSyncModel = () => {
    setOpenSync(false);
  };
  const openModalDelete = (value) => {
    setOpenDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  let reloadpage = () => {
    setReloadTree(true);
  };
  const [tabsList, setTabsList] = useState([
    {
      name: 'Github',
      pathUrl: `/configuration/emailConfiguration/emailGroup`,
      selectedName: 'emailGroup',
    },
  ]);
  const renderTabs = () => {
    return (
      <div>
        <ul className="navBar table-header-text flex flex-row primary-tab ml-6">
          {tabsList.map((tab, index) => {
            return (
              <li key={tab.name}>
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    props.history.push(tab.pathUrl);
                  }}
                  className={`selected_tab_style`}
                >
                  {tab.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const getGitHubs = () => {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    getGithubList()
      .then((response) => {
        stopProgress();
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 10,
        });
        if (response.data.responseObject) {
          response.data.responseObject.forEach((res, i) => {
            res.Actions = [];
            res.Actions.push(
              <div className="actionIcons">
                <Tooltip title="Edit">
                  <EditIcon
                    className="float-left  project-action action-button mr-3  cursor-pointer"
                    onClick={() => {
                      setMode('EDIT');
                      setAccountData(res);
                      setOpenAccountModal(true);
                    }}
                  />
                </Tooltip>
                <Tooltip title="Delete">
                  <DeleteOutlinedIcon
                    className="float-left project-action action-button  mr-3 cursor-pointer"
                    onClick={() => {
                      setAccountData(res);

                      setOpenDeleteModal(true);
                    }}
                  />
                </Tooltip>
                <Tooltip title="Sync">
                  <SyncIcon
                    className="float-left project-action action-button mr-3  cursor-pointer"
                    onClick={() => {
                      setAccountData(res);
                      openSyncModel('Sync');
                    }}
                  />
                </Tooltip>
                <Tooltip title="More">
                  <Menu as="div" className="relative inline-block text-left " key="user-menu">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button
                            className="flex items-center  focus:outline-none "
                            onMouseOver={(e) => {
                              let mouse = e.clientY;
                              setPageIndex(mouse);
                            }}
                          >
                            <span className="action-button">
                              {' '}
                              <MoreVertIcon />
                            </span>
                          </Menu.Button>
                        </div>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className={`${pageIndexValue > 520 ? '-mt-24 ' : 'ml-2'} origin-top-right absolute right-0 z-40 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                          >
                            <div className="py-5">
                              <Menu.Item>
                                <div
                                  onClick={() => {
                                    setAccountData(res);
                                    setOpenDetailsModal(true);
                                  }}
                                  className="block px-4 py-1 text-sm details-background"
                                >
                                  {GITHUB_CONSTANTS.DETAILS}
                                </div>
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </Tooltip>
              </div>
            );
            res.slNo = i + 1;
          });
          setAccount((d) => response.data.responseObject);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setAccount([]);
        }
      })
      .catch((err) => {
        resetProgress();
        console.error('GET_GITHUB_LIST :', err);
      });
  };

  const closeModal = (value) => {
    if (openAccountModal) {
      setOpenAccountModal(false);
    } else if (openDetailsModal) {
      setOpenDetailsModal(false);
    }
  };

  // TODO :: handle Search filter :: START
  const [userSearched, setuserSearched] = useState('');
  const [data, setData] = useState([]);
  let [openSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    setData(account);
    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [account]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(account);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setData(account);
    setSearchTextHighlight('');
    setIsHighlight(!isHighlight);
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = account.filter(
          ({
            instanceName,
            createdByUname,
            gitHubRepositoryName,
            fireFlinkProjectName,
            suiteName,
            modifiedByUname,
          }) => {
            return (
              isValueIncludes(e, instanceName) ||
              isValueIncludes(e, createdByUname) ||
              isValueIncludes(e, gitHubRepositoryName) ||
              isValueIncludes(e, fireFlinkProjectName) ||
              isValueIncludes(e, suiteName) ||
              isValueIncludes(e, modifiedByUname)
            );
          }
        );
        setData(newData);
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  // TODO :: handle Search filter :: END

  const columns = React.useMemo(
    () => [
      {
        Header: 'INSTANCE NAME',
        accessor: 'instanceName',
        disableFilters: true,
        width: '15%',
        Cell: (e) => (
          <div className=" float-left table-content fontPoppinsMediumSm name-trimmer" title={e.value}>
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'USERNAME',
        accessor: 'createdByUname',
        disableFilters: true,
        width: '13%',
        Cell: (e) => (
          <div className=" float-left  table-content fontPoppinsMediumSm name-trimmer" title={e.value}>
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'REPOSITORY',
        accessor: 'gitHubRepositoryName',
        disableFilters: true,
        width: '15%',
        Cell: (e) => (
          <div className=" float-left  table-content fontPoppinsMediumSm name-trimmer" title={e.value}>
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'FIREFLINK PROJECT',
        accessor: 'fireFlinkProjectName',
        disableFilters: true,
        width: '14%',
        Cell: ({ value }) => {
          return (
            <div
              className="table-content float-left  fontPoppinsMediumSm overflow-hidden overflow-ellipsis whitespace-nowrap w-20"
              title={value}
            >
              <HighlightText text={value} highlight={searchTextHighlight} />
            </div>
          );
        },
      },
      {
        Header: 'SUITE NAME',
        accessor: 'suiteName',
        disableFilters: true,
        width: '13%',
        Cell: (e) => (
          <div
            title={e.value}
            className="float-left name-trimmer table-content fontPoppinsMediumSm overflow-hidden overflow-ellipsis whitespace-nowrap w-24"
          >
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'MODIFIED BY',
        accessor: 'modifiedByUname',
        disableFilters: true,
        width: '15%',
        Cell: (e) => (
          <div title={e.value} className="float-left name-trimmer table-content fontPoppinsMediumSm">
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'ACTIONS',
        accessor: 'Actions',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common table-font-style-common">{value}</div>;
        },
      },
    ],
    [isHighlight]
  );

  useEffect(() => {
    if (reloadTree) {
      setIsLoading(true);
      getGitHubs();
    }
    setReloadTree(false);
  }, [reloadTree]);

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data,
  });
  return (
    <>
      <div className="page-list-body">
        <div>{renderTabs()}</div>
        <div className="page-table-body">
          <div className="content_area_style_Int">
            <div className="content_area_style_tcTemplate" id="journal-scroll">
              <div className="pt-3 pb-3 border_style flex flex-wrap items-center justify-end">
                <div className="flex items-center justify-end  w-2/5 mr-40 mt-2">
                  <SearchBar
                    open={openSearch}
                    searchValue={userSearched || ''}
                    handleSearch={(e) => {
                      setuserSearched(e.target.value);
                    }}
                    onSearchClose={handleSearchClose}
                    handleSearchOpen={handleSearchOpen}
                    enterKeyHandler={handleSearchSubmit}
                    disable={isEmptyValue(account)}
                  />
                </div>
                <div
                  className="grid grid-cols-12 col-span-11 addTempBtn flex-row cursor-pointer mr-5"
                  onClick={() => {
                    setOpenAccountModal(true);
                    setMode('ADD');
                  }}
                >
                  <span className="col-span-2">
                    <AddIcon className="addDefectIcon" />
                  </span>
                  <span className=" col-span-10 absolute mt-2 fontPoppinsMediumSm create-instance-text">
                    {GITHUB_CONSTANTS.INSTANCE}
                  </span>
                </div>
              </div>
              {/* </div> */}
              <div className="table-height overflow-y-auto overflow-x-hidden mx-3 " id="journal-scroll">
                <table {...getTableProps()} className="h-3 ">
                  <thead className="projectDetailsDataa">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()} className="text-sm h-9  top-0">
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps()}
                            width={column.width}
                            className=" text-left mx-2 fontPoppinsSemiboldSm pl-3 table-line"
                          >
                            <span className="table-header-text">{column.render('Header')}</span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()} className="text text-gray-700">
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} className="configHover h-10 hover:bg-gray-100 mt-10">
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="p-0  text-left text-gray-700 fontPoppinsRegularMd pl-4 project-row "
                              >
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {isLoading ? (
                  <CommonLoader />
                ) : isEmptyValue(account) ? (
                  <div className="fontPoppinsRegularMd no-steps">
                    <div>
                      <span className=" ml-10 mb-5">
                        <QuickStart className="quick-start" />
                      </span>
                      <p className="fontPoppinsSemiboldMd mb-4">{GITHUB_CONSTANTS.QUICK_START}</p>
                      <div className="flex flex-row">
                        <div className="fontPoppinsRegularSm mt-2">{GITHUB_CONSTANTS.STEP_1_CLICK}</div>
                        <div
                          className="grid grid-cols-12 col-span-11 QuickStartAddBtn  cursor-pointer ml-4"
                          onClick={() => {
                            setOpenAccountModal(true);
                            setMode('ADD');
                          }}
                        >
                          <span className="col-span-2">
                            <AddIcon className="addDefectIcon" />
                          </span>
                          <span className=" col-span-10 absolute mt-2 fontPoppinsMediumSm create-instance-text">
                            {GITHUB_CONSTANTS.INSTANCE}
                          </span>
                        </div>
                        <div className="fontPoppinsRegularSm mt-2 ml-1">{GITHUB_CONSTANTS.ADDING_INSTANCE}</div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>{userSearched && isEmptyValue(data) && <NoResultsFound />}</>
                )}
              </div>
            </div>

            {openAccountModal ? (
              <GithubCreateEditInstance
                isOpen={openAccountModal}
                onClose={closeModal}
                reloadTree={reloadpage}
                mode={mode}
                MyAlert={props.MyAlert}
                data={accountData}
                type={props.mode}
                instanceList={account}
                git={props.git}
              />
            ) : null}
            {openDetailsModal ? <GitlabDetails data={accountData} onClose={closeModal} /> : null}
            {openSync && (
              <GitHubSyncronizeInstance
                isOpen={openSync}
                onClose={closeSyncModel}
                reloadTree={reloadpage}
                data={accountData}
                MyAlert={props.MyAlert}
              />
            )}
            {openDeleteModal && (
              <DeleteInstanceGithub
                openModal={openModalDelete}
                closeModal={closeDeleteModal}
                reloadTree={reloadpage}
                MyAlert={props.MyAlert}
                data={accountData}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default GithubTable;

import React, { useEffect, useState } from 'react';
import { addAsNewRecordedEleReq, clearMergeConflictApiReq, deleteSingleRecordedElementReq } from '@api/api_services';
import { DragIndicatorOutlined } from '@material-ui/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DiscardElementModal from '../shared_elements/discard_elemen_modal';
import { MenuItem, Select, TextField } from '@material-ui/core';
import DropdownTree from '../../../../../common/dropdown_tree';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@src/css_config/colorConstants.js';
const useStylesElementType = makeStyles((theme) => ({
  select: {
    minWidth: 100,
    borderRadius: 8,
    fontSize: '14px',
    '&:focus': {
      borderRadius: 12,
      background: 'none',
    },
  },
  menuPaper: {
    maxHeight: 300,
    fontSize: '14px',
  },
  menulist: {
    overflowY: 'auto',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    height: '200px',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colors.soft_blue,
    },
    paddingTop: 15,
    paddingBottom: 0,
    fontSize: '14px',
    background: 'none',
    '& li': {
      fontSize: '14px',
      color: colors.grey_dark,
    },
    '& li:hover': {
      background: colors.sky_blue_dark,
      color: colors.blue_dark,
    },
    '& li.Mui-selected': {
      color: colors.blue_dark,
      background: colors.sky_blue_dark,
    },
    '& li.Mui-selected:hover': {
      background: colors.sky_blue_dark,
      color: colors.blue_dark,
    },
  },
}));
const RecordedEleConflictPage = (props) => {
  let [selectedIndex, setSelectedIndex] = useState(0);
  let project = JSON.parse(localStorage.getItem('selected-project'));
  let [recordedLoc, setRecordedLoc] = useState([...props.recordedElements[0]?.locators]);
  let [projectLoc, setprojectLoc] = useState([...props.conflictedprojElements[0]?.locators]);
  let [selectedEle, setSelectedEle] = useState({
    ...props.recordedElements[0],
  });
  let [nameValue, setNameValue] = useState('');
  let [parentValue, setParentValue] = useState('');
  let [eleType, setEleTypeValue] = useState('');
  const [selectedPage, setSelectedPage] = useState('');
  const regx = /^[^-\s][A-Za-z0-9 ]*[A-Za-z0-9\W|_][A-Za-z0-9\W|_ ]*$/;
  let [nameError, setNameError] = useState(false);
  let [specialCharError, setSpecialCharError] = useState(false);
  let [enableSaveAsNew, setEnableSaveAsNew] = useState(true);
  let [eleIds, setEleIds] = useState([...props.recEles]);
  let [idForEnableSave, setidForEnableSave] = useState([...props.recEles]);
  let [disableSaveAsNew, setDisableSaveAsNew] = useState(false);
  let [discardElementModal, setDiscardElementModal] = useState(false);
  let [isNew, setIsNew] = useState(false);
  let [discard, setDiscard] = useState();
  let isshared = false;
  let [treeData, setTreeData] = useState(props.treeData);
  let [projelementData, setProjElementData] = useState([...props.conflictedprojElements]);

  const [elementTypeDropDown, setElementTypeDropDown] = useState([]);
  let dropDown = [];
  const [recordedElementsData, setRecordedElementsData] = useState([...props.recordedElements]);
  const classesElementType = useStylesElementType();

  const clearMergeConflictReq = async () => {
    let response;
    let payload = projelementData;
    let elesString = eleIds.toString();
    try {
      response = await clearMergeConflictApiReq(payload, elesString);
      if (response.responseCode === 200 && response.status === 'SUCCESS') {
        props.MyAlert.success(`${payload.length} elements moved successfully`);
      }
      props?.getAllElemtsReq(props.projectDetails?.type, props.platForm);
      props.getAllRecordedElements();
    } catch (err) {
      console.error('error while merging element', err);
    }
  };

  let createAsNewEle = async () => {
    let payload = [];
    payload.push({ ...selectedEle });
    payload.locators = [...recordedLoc];
    let parentId = payload[0].parentId;
    let eleId = payload[0].id;
    delete payload[0].id;
    if (payload[0]?.isDrag) {
      delete payload[0].isDrag;
    }
    let response;
    try {
      response = await addAsNewRecordedEleReq(payload, parentId, eleId);
      if (
        response.data.responseCode === 200 &&
        response.data.status === 'SUCCESS' &&
        recordedElementsData.length === 1
      ) {
        props.getAllElemtsReq(props.projectDetails?.type, props.platForm);
        props.getAllRecordedElements();
        props.closeModal(false);
      } else if (response.data.responseCode === 200 && response.data.status === 'SUCCESS') {
        props.getAllElemtsReq(props.projectDetails?.type, props.platForm);
        props.MyAlert.success(`${selectedEle?.name} ${selectedEle?.type} element added successfully.`);
        let eledata = projelementData;
        let recorData = recordedElementsData;
        let dltId = [];
        dltId.push(recorData[selectedIndex].id);
        eledata.splice(selectedIndex, 1);
        recorData.splice(selectedIndex, 1);
        if (recorData.length === 0) {
          props.getAllElemtsReq(props.projectDetails?.type, props.platForm);
          props.getAllRecordedElements();
          props.closeModal(false);
        } else if (selectedIndex > recorData.length - 1) {
          setSelectedIndex(recorData.length - 1);
          setRecordedLoc(recorData[recorData.length - 1]?.locators);
          setprojectLoc(eledata[eledata.length - 1]?.locators);
        } else {
          setRecordedLoc([...recorData[selectedIndex]?.locators]);
          setprojectLoc([...eledata[selectedIndex]?.locators]);
        }
        let enableIds = [...idForEnableSave];
        enableIds.splice(enableIds.indexOf(selectedEle?.id), 1);
        setidForEnableSave([...enableIds]);
        let receleids = eleIds;
        receleids?.splice(receleids.indexOf(eleId), 1);
        setEleIds(receleids);
        setProjElementData([...eledata]);
        setRecordedElementsData(recorData);
        props.getAllRecordedElements();
      } else {
        if (
          response.data.responseCode === 400 &&
          (response.data.message ===
            `Element ${selectedEle?.name} and type ${selectedEle?.type} with page Id ${selectedEle?.parentId}already exist in Shared Element` ||
            response.data.message ===
              `Element ${selectedEle?.name} and type ${selectedEle?.type} with page name ${selectedEle?.parentName} already exist`)
        ) {
          props.MyAlert.info(
            `Element ${selectedEle?.name} and type ${selectedEle?.type} already exist in selected page.`
          );
        }
        setSelectedEle({ ...recordedElementsData[selectedIndex] });
      }
    } catch (err) {
      console.error('error while saving element as new element', err);
    }
  };

  let closeModal = (val) => {
    setDiscardElementModal(val);
  };

  let handelDiscardEle = async (isSkip) => {
    let eledata = [...projelementData];
    let recorData = [...recordedElementsData];
    let dltId = [];
    dltId.push(recorData[selectedIndex].id);
    let response;
    try {
      response = await deleteSingleRecordedElementReq(dltId);
      if (response.data.responseCode === 200) {
        setDisableSaveAsNew(true);
      }
      if (recordedElementsData.length === 1) {
        props.getAllElemtsReq(props.projectDetails?.type, props.platForm);
        props.getAllRecordedElements();
        props.closeModal(false);
      }
      props.MyAlert.success(
        `${recorData[selectedIndex].name} ${recorData[selectedIndex].type} element discarded successfully.`
      );
    } catch (err) {
      console.error('error while discarding element', err);
    }
    eledata.splice(selectedIndex, 1);
    let dltRecEle = recorData.splice(selectedIndex, 1);
    if (recorData.length === 0) {
      props.getAllElemtsReq(props.projectDetails?.type, props.platForm);
      props.getAllRecordedElements();
      props.closeModal(false);
    } else if (selectedIndex > recorData.length - 1) {
      setSelectedIndex(recorData.length - 1);
      setRecordedLoc(recorData[recorData.length - 1]?.locators);
      setprojectLoc(eledata[eledata.length - 1]?.locators);
    } else {
      setRecordedLoc([...recorData[selectedIndex]?.locators]);
      setprojectLoc([...eledata[selectedIndex]?.locators]);
    }
    let enableIds = [...idForEnableSave];
    enableIds.splice(enableIds.indexOf(recorData[selectedIndex]?.id), 1);
    setidForEnableSave([...enableIds]);
    let receleids = [...eleIds];
    receleids?.splice(receleids.indexOf(dltRecEle.id), 1);
    setEleIds([...receleids]);
    setProjElementData([...eledata]);
    setRecordedElementsData([...recorData]);
  };

  let handleDelete = (index, eleType) => {
    if (eleType === 'recordedLoc') {
      let recElementsLoc = [...recordedLoc];
      let projelementsLoc = [...projectLoc];
      recElementsLoc.splice(index, 1);
      if (recElementsLoc.length === 0) {
        let prjEle = [...projelementData];
        let recEle = [...recordedElementsData];
        prjEle[selectedIndex].locators = projelementsLoc;
        recEle[selectedIndex].locators = recElementsLoc;
        setProjElementData([...prjEle]);
        setRecordedElementsData([...recEle]);
        let enableIds = [...idForEnableSave];
        enableIds.splice(enableIds.indexOf(recordedElementsData[selectedIndex]?.id), 1);
        setidForEnableSave([...enableIds]);
      }
      setRecordedLoc([...recElementsLoc]);
    } else if (eleType === 'projLoc') {
      let projelementsLoc = [...projectLoc];
      projelementsLoc.splice(index, 1);
      let prjEle = [...projelementData];
      prjEle[selectedIndex].locators = projelementsLoc;
      setProjElementData([...prjEle]);
      setprojectLoc([...projelementsLoc]);
    } else if (eleType === 'Ele') {
      let projEles = [...projelementData];
      let recEles = [...recordedElementsData];
      let enableIds = [...idForEnableSave];
      let recEle = recEles.splice(index, 1);
      projEles.splice(index, 1);
      enableIds.splice(enableIds.indexOf(recEle?.id), 1);
      setidForEnableSave([...enableIds]);
      let receleids = [...eleIds];
      receleids?.splice(receleids.indexOf(recEle?.id), 1);
      setEleIds([...receleids]);
      if (recEles.length === 0) {
        props.getAllElemtsReq(props.projectDetails?.type, props.platForm);
        props.getAllRecordedElements();
        props.closeModal(false);
      } else if (selectedIndex > recEles.length - 1) {
        setSelectedIndex(recEles.length - 1);
        setRecordedLoc(recEles[recEles.length - 1]?.locators);
        setprojectLoc(projEles[recEles.length - 1]?.locators);
      } else {
        if (selectedIndex === index) {
          setRecordedLoc([...recEles[index]?.locators]);
          setprojectLoc([...projEles[index]?.locators]);
        }
      }
      props.MyAlert.success(
        `${recordedElementsData[index]?.name} ${recordedElementsData[index]?.type} element skipped successfully.`
      );
      setProjElementData([...projEles]);
      setRecordedElementsData([...recEles]);
    }
  };

  useEffect(() => {
    if (
      (project.type === 'Mobile' &&
        (props.platForm === 'Android' || props.platForm === 'iOS' || props.platForm === 'Ios') &&
        (project.appType === 'Hybrid' || project.appType === 'Native')) ||
      (project.type === 'Web & Mobile' &&
        (props.platForm === 'Android' ||
          props.platForm === 'MobileWeb' ||
          props.platForm === 'iOS' ||
          props.platForm === 'Ios'))
    ) {
      dropDown.push({
        label: 'Link',
        value: 'link',
      });
      dropDown.push({
        label: 'Textfield',
        value: 'textfield',
      });
      dropDown.push({
        label: 'Icon',
        value: 'icon',
      });
      dropDown.push({
        label: 'Button',
        value: 'button',
      });

      dropDown.push({
        label: 'Radiobutton',
        value: 'radiobutton',
      });
      dropDown.push({
        label: 'Checkbox',
        value: 'checkbox',
      });
      dropDown.push({
        label: 'Tab',
        value: 'tab',
      });
      dropDown.push({
        label: 'Action overflow button',
        value: 'action over button',
      });
      dropDown.push({
        label: 'Hamburger menu',
        value: 'hamburge menu',
      });
      dropDown.push({
        label: 'Toggle button',
        value: 'toggle button',
      });
      dropDown.push({
        label: 'Steppers',
        value: 'steppers',
      });
      dropDown.push({
        label: 'Sliders',
        value: 'sliders',
      });
      setElementTypeDropDown(dropDown);
    }
    if ((project.type === 'Web & Mobile' && props.platForm === 'Web') || project.type === 'Web') {
      dropDown = [];
      dropDown.push({
        label: 'Link',
        value: 'link',
      });
      dropDown.push({
        label: 'Textfield',
        value: 'textfield',
      });
      dropDown.push({
        label: 'Button',
        value: 'button',
      });
      dropDown.push({
        label: 'Radiobutton',
        value: 'radiobutton',
      });
      dropDown.push({
        label: 'Checkbox',
        value: 'checkbox',
      });
      dropDown.push({
        label: 'Tab',
        value: 'tab',
      });
      dropDown.push({
        label: 'Text',
        value: 'text',
      });
      dropDown.push({
        label: 'Textarea',
        value: 'textarea',
      });
      dropDown.push({
        label: 'Image',
        value: 'image',
      });
      dropDown.push({
        label: 'Dropdown',
        value: 'dropdown',
      });
      setElementTypeDropDown(dropDown);
    }
    if (project.type === 'Web') {
      dropDown = [];
      dropDown.push({
        label: 'link',
        value: 'link',
      });
      dropDown.push({
        label: 'Textfield',
        value: 'textfield',
      });
      dropDown.push({
        label: 'Button',
        value: 'button',
      });
      dropDown.push({
        label: 'Radiobutton',
        value: 'radiobutton',
      });
      dropDown.push({
        label: 'Checkbox',
        value: 'checkbox',
      });
      dropDown.push({
        label: 'Text',
        value: 'text',
      });
      dropDown.push({
        label: 'Textarea',
        value: 'textarea',
      });
      dropDown.push({
        label: 'Image',
        value: 'image',
      });
      dropDown.push({
        label: 'Dropdown',
        value: 'dropdown',
      });
      dropDown.push({
        label: 'Tab',
        value: 'tab',
      });
      setElementTypeDropDown(dropDown);
    }
    if (project.type === 'Salesforce') {
      dropDown = [];
      dropDown.push({
        label: 'link',
        value: 'link',
      });
      dropDown.push({
        label: 'textfield',
        value: 'textfield',
      });
      dropDown.push({
        label: 'button',
        value: 'button',
      });
      dropDown.push({
        label: 'radiobutton',
        value: 'radiobutton',
      });
      dropDown.push({
        label: 'checkbox',
        value: 'checkbox',
      });
      dropDown.push({
        label: 'text',
        value: 'text',
      });
      dropDown.push({
        label: 'textarea',
        value: 'textarea',
      });
      dropDown.push({
        label: 'image',
        value: 'image',
      });
      dropDown.push({
        label: 'dropdown',
        value: 'dropdown',
      });
      dropDown.push({
        label: 'tab',
        value: 'tab',
      });
      dropDown.push({
        label: 'formula',
        value: 'formula',
      });

      dropDown.push({
        label: 'lookuprelationship',
        value: 'lookuprelationship',
      });
      dropDown.push({
        label: 'date',
        value: 'date',
      });
      dropDown.push({
        label: 'email',
        value: 'email',
      });
      dropDown.push({
        label: 'picklist',
        value: 'picklist',
      });
      dropDown.push({
        label: 'password',
        value: 'password',
      });
      dropDown.push({
        label: 'icon',
        value: 'icon',
      });
      setElementTypeDropDown(dropDown);
    }
  }, []);

  let onDragEnd = (result) => {
    let projelementsLoc = projectLoc;
    let recElementsLoc = recordedLoc;
    if (
      !result.destination ||
      isNew ||
      projelementData[selectedIndex]?.isShared === 'Y' ||
      projelementData[selectedIndex]?.isShared === 'YES'
    )
      return;

    if (
      result.destination.droppableId === 'projectLocdragdrop' &&
      result.source?.droppableId === 'recordedLocdragdrop'
    ) {
      const projitems = Array.from(projelementsLoc);
      const recordeditems = Array.from(recElementsLoc);
      if (isshared === false) {
        const [modifiedRecItem] = recordeditems.splice(result.source.index, 1);
        modifiedRecItem.key = 'draged';
        projitems.splice(result.destination.index, 0, modifiedRecItem);
        projelementsLoc = projitems;
        recElementsLoc = recordeditems;
        if (recElementsLoc.length === 0) {
          let prjEle = [...projelementData];
          let recEle = [...recordedElementsData];
          prjEle[selectedIndex].locators = projelementsLoc;
          recEle[selectedIndex].locators = recElementsLoc;
          setProjElementData([...prjEle]);
          setRecordedElementsData([...recEle]);
          let enableIds = [...idForEnableSave];
          enableIds.splice(enableIds.indexOf(recordedElementsData[selectedIndex]?.id), 1);
          setidForEnableSave([...enableIds]);
        }
        setRecordedLoc(recElementsLoc);
        setprojectLoc(projelementsLoc);
      }
      isshared = false;
    } else {
      return;
    }
  };

  let handelSaveAsNewMode = () => {
    let ele = {
      locators: [...recordedElementsData[selectedIndex].locators],
      ...recordedElementsData[selectedIndex],
    };
    setNameValue(ele.name);
    setParentValue(ele.parentName);
    setEleTypeValue(ele.type);
    setSelectedEle({ ...ele });
    setIsNew(true);
    if (!enableSaveAsNew) {
      setEnableSaveAsNew(true);
    }
  };

  const treeNodeSelected = (data) => {
    let recdele = selectedEle;
    if (recordedElementsData[selectedIndex].parentId !== data.data.key) {
      setEnableSaveAsNew(false);
    }
    recdele.parentId = data.data.key;
    recdele.parentName = data.data.name;
    setSelectedEle({ ...recdele });
    setParentValue(data.data.name);
    setSelectedPage(data);
  };

  let nameOnBlur = (e) => {
    let recdele = selectedEle;
    recdele.name = e.target.value;
    setSelectedEle({ ...recdele });
    if (nameValue.length < 2 || nameValue.length > 100 || e.target.value.match(regx) === null) {
      setEnableSaveAsNew(true);
      setNameError(true);
    } else if (recordedElementsData[selectedIndex]?.name === e.target.value) {
      setNameError(false);
    } else {
      setNameError(false);
      setEnableSaveAsNew(false);
    }
  };

  let handelChangeofSaveAsNew = (e, field) => {
    let recdele = { ...selectedEle };
    if (field === 'name') {
      if (e.target.value.match(regx) === null) {
        setSpecialCharError(true);
      } else {
        setSpecialCharError(false);
      }
      setNameValue(e.target.value);
    } else if (field === 'eleType') {
      setEleTypeValue(e.target.value);
      if (recdele.type !== e.target.value) {
        setEnableSaveAsNew(false);
      }
      recdele.type = e.target.value;
      setSelectedEle(recdele);
    }
  };

  let beforStart = (result) => {
    let projelementsLoc = projectLoc;
    let recElementsLoc = recordedLoc;
    const projitems = Array.from(projelementsLoc);
    const recordeditems = Array.from(recElementsLoc);
    let locIndex = result.source.index;
    let share = recordeditems[locIndex];
    if (result.source?.droppableId === 'recordedLocdragdrop') {
      for (let i = 0; i < projitems.length; i++) {
        if (
          share?.name === 'id' ||
          share?.name === 'name' ||
          share?.name === 'className' ||
          share?.name === 'tagName' ||
          share?.name === 'linkText' ||
          share?.name === 'accessibilityID'
        ) {
          if (projitems[i].name === share.name) {
            isshared = true;
            break;
          }
        } else if (projitems[i]?.name === share?.name && projitems[i]?.value === share?.value) {
          isshared = true;
          break;
        }
      }
    }
  };

  let RecordedLocatorComponent = (props) => {
    let [locValue, setLocValue] = useState(props?.loctor?.name + '-' + props?.loctor?.value);
    let [isLocatorEditable, setIsLocatorEditable] = useState(false);
    let [isSame, setIsSame] = useState(false);

    useEffect(() => {
      projectLoc?.map((prjLoc) => {
        if (prjLoc?.value === props?.loctor?.value && prjLoc?.name === props?.loctor?.name) {
          setIsSame(true);
        }
        return null;
      });
    }, []);

    let handelChange = (e) => {
      let editedVal = e.target.value.split('-');
      if (editedVal[1]) {
        setLocValue(props.loctor.name + '-' + editedVal[1]);
      } else {
        setLocValue(props.loctor.name + '-');
      }
    };

    let locOnBlur = (e) => {
      let editedVal = e.target.value.split('-');
      // props.projEleLocator?.map((recorLoc) => {
      //   if (
      //     recorLoc?.value === editedVal[1] &&
      //     recorLoc?.name === editedVal[0]
      //   ) {
      //     setIsSame(true);
      //   }
      // });

      if (editedVal[1]) {
        setLocValue(props.loctor.name + '-' + editedVal[1]);
        let recEleLoc = [...recordedLoc];
        recEleLoc[props?.index].value = editedVal[1];
        setRecordedLoc([...recEleLoc]);
      } else {
        setLocValue(props.loctor.name + '-' + props?.loctor?.value);
      }
    };

    return (
      <Draggable
        key={`${props.loctor.name}${props.loctor.value}`}
        draggableId={`reco${props.loctor.name}${props.loctor.value}`}
        index={props.index}
      >
        {(provided, snapshot) => (
          <div
            className="conflictBodyLocatorCapsule flex items-center float-left"
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <span
              className={`${isSame ? 'fontPoppinsRegularSm' : 'conflictBodyDangerText fontPoppinsRegularSm'} individualLocStyle  flex items-center fontPoppinsRegularSm`}
            >
              <span {...provided.dragHandleProps}>
                <DragIndicatorOutlined fontSize="small" className="text-blue-600 cursor-grab" />
              </span>
              {!isLocatorEditable ? (
                <div
                  className="path-trimmer"
                  title={`${props.loctor.value ? props.loctor.name + '-' + props.loctor.value : '--'}`}
                >
                  {props.loctor.name}-{props.loctor.value}
                </div>
              ) : (
                <div>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // error={meta.touched && meta.error}
                    autoComplete="off"
                    autoFocus={true}
                    onChange={(event) => {
                      handelChange(event);
                    }}
                    onBlur={(e) => {
                      setIsLocatorEditable(false);
                      locOnBlur(e);
                    }}
                    className=""
                    value={locValue}
                    type="text"
                    // {...field}
                    placeholder="Enter element name"
                  />
                </div>
              )}
            </span>
            <button
              className="locActionIcon"
              onClick={() => {
                if (
                  props.loctor.name === 'xpath' ||
                  props.loctor.name === 'cssSelector' ||
                  props.loctor.name === 'partialLinkText' ||
                  props.loctor.name === 'AndroidUiAutomator' ||
                  props.loctor.name === 'Pridicate String' ||
                  props.loctor.name === 'Class Chain'
                )
                  setIsLocatorEditable(true);
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18px" height="18px" fill="#1648c6">
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z" />
              </svg>
            </button>
            <button
              className="locActionIcon"
              onClick={() => {
                handleDelete(props.index, 'recordedLoc');
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18px" height="18px" fill="#1648c6">
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" />
              </svg>
            </button>
          </div>
        )}
      </Draggable>
    );
  };

  let ProjLocatorComponent = (props) => {
    let [isProjectloc, setIsProjectloc] = useState(props?.loctor?.key === 'draged' ? false : true);
    let [isSame, setIsSame] = useState(false);

    useEffect(() => {
      recordedLoc?.map((recorLoc) => {
        if (recorLoc?.value === props?.loctor?.value && recorLoc?.name === props?.loctor?.name) {
          setIsSame(true);
        }
        return null;
      });
    }, []);

    return (
      <Draggable
        key={`${props.loctor.name}${props.loctor.value}`}
        draggableId={`proj${props.loctor.name}${props.loctor.value}`}
        index={props.index}
      >
        {(provided, snapshot) => (
          <div
            className={` ${
              isProjectloc ? 'unEditableconflictLocatorCapsule' : 'conflictBodyLocatorCapsule'
            } flex items-center float-left`}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <span
              className={`${
                isProjectloc ? 'unEditableindividualLocStyle' : 'individualLocStyle'
              }  ${isSame ? 'fontPoppinsRegularSm' : props.loctor.key === 'draged' ? 'shareloctxt fontPoppinsRegularSm' : 'conflictBodyDangerText fontPoppinsRegularSm'} flex items-center fontPoppinsRegularSm`}
            >
              <div
                className="path-trimmer"
                title={`${props.loctor.value ? props.loctor.name + '-' + props.loctor.value : '--'}`}
              >
                {props.loctor.name}-{props.loctor.value}
              </div>
            </span>
            {!isProjectloc ? (
              <button className="locActionIcon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18px" height="18px" fill="#1648c6">
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z" />
                </svg>
              </button>
            ) : null}
            {!isProjectloc ? (
              <button
                className="locActionIcon"
                onClick={() => {
                  handleDelete(props.index, 'projLoc');
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18px" height="18px" fill="#1648c6">
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" />
                </svg>
              </button>
            ) : null}
          </div>
        )}
      </Draggable>
    );
  };

  return (
    <div className="conflictPage">
      <div className="conflictPageHeader flex items-center mb-5">
        <span className="conflictHeader-style">Element Conflict</span>
        <span className="float-right ml-auto">
          <button
            type="button"
            onClick={() => {
              props.getAllElemtsReq(props.projectDetails?.type, props.platForm);
              props.getAllRecordedElements();
              props.closeModal(false);
            }}
            className="gray-btn mr-4 "
          >
            <span className="cancel-label-common cancel-label-layout-style-project fontPoppinsMediumMd">Close</span>
          </button>
        </span>
      </div>
      <div className="flex items-start conflictBody overflow-y-auto" id="journal-scroll">
        <div className="ConflictEleDisplay overflow-y-auto" id="journal-scroll">
          {recordedElementsData?.map((ele, index) => {
            return (
              <div className="elementCapsule mt-2.5 flex items-center">
                <span
                  className="mr-2.5 cursor-pointer"
                  onClick={() => {
                    handleDelete(index, 'Ele');
                  }}
                >
                  <svg
                    id="cancel_black_24dp"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path id="Path_519" data-name="Path 519" d="M0,0H24V24H0Z" fill="none" />
                    <path
                      id="Path_520"
                      data-name="Path 520"
                      d="M12,2A10,10,0,1,0,22,12,9.991,9.991,0,0,0,12,2Zm5,13.59L15.59,17,12,13.41,8.41,17,7,15.59,10.59,12,7,8.41,8.41,7,12,10.59,15.59,7,17,8.41,13.41,12Z"
                      fill="#c50303"
                    />
                  </svg>
                </span>
                <div
                  className={`${
                    selectedIndex === index ? 'conflictEleCapsuleClicked' : 'conflictEleCapsule'
                  } flex items-center cursor-default inline-block`}
                  onClick={() => {
                    if (selectedIndex !== index) {
                      setDisableSaveAsNew(false);
                      setIsNew(false);
                      if (recordedLoc.length !== 0) {
                        let prjEle = [...projelementData];
                        let recEle = [...recordedElementsData];
                        prjEle[selectedIndex].locators = projectLoc;
                        recEle[selectedIndex].locators = recordedLoc;
                        setProjElementData([...prjEle]);
                        setRecordedElementsData([...recEle]);
                      }
                      setRecordedLoc(ele?.locators);
                      setprojectLoc(projelementData[index]?.locators);
                      setSelectedIndex(index);
                    }
                  }}
                >
                  <span className=" ml-4 flex items-center w-full">
                    <div
                      className="path-trimmer elementCapsuleNameStyle fontPoppinsMediumMd"
                      title={`${ele.name && ele.parentName ? ele.name + '-' + ele.parentName : '--'}`}
                    >
                      {ele.name} - {ele.parentName}
                    </div>
                  </span>
                </div>
              </div>
            );
          })}
        </div>
        <div className="ConflictDisplay ml-7 overflow-y-auto" id="journal-scroll">
          <div className="elementConflictBodyHeader  flex items-center">
            <div
              className="path-trimmer fontPoppinsSemiboldLg"
              title={recordedElementsData[selectedIndex].name ? recordedElementsData[selectedIndex].name : '--'}
            >
              {recordedElementsData[selectedIndex].name}
            </div>
          </div>
          <div className="overflow-y-auto" id="journal-scroll">
            <div className="overflow-y-auto conflictInnerBody" id="journal-scroll">
              {!isNew ? (
                <div className=" float-left recordedEleConflictBody">
                  <div className="flex items-center h-8">
                    <span className="conflictBodySubHeader pr-2.5">Element In :</span>
                    <span className="conflictBodyDangerText ">Recorded Elements</span>
                  </div>
                  <div className="flex items-center h-8">
                    <span className="conflictBodySubHeader pr-2.5">Element Name :</span>
                    <span
                      className="conflictBodyText path-trimmer"
                      title={recordedElementsData[selectedIndex].name ? recordedElementsData[selectedIndex].name : '--'}
                    >
                      {recordedElementsData[selectedIndex].name}
                    </span>
                  </div>
                  <div className="flex items-center h-8">
                    <span className="conflictBodySubHeader pr-2.5">Page Name :</span>
                    <span
                      className="conflictBodyText path-trimmer"
                      title={
                        recordedElementsData[selectedIndex].parentName
                          ? recordedElementsData[selectedIndex].parentName
                          : '--'
                      }
                    >
                      {recordedElementsData[selectedIndex].parentName}
                    </span>
                  </div>
                  <div className="flex items-center h-8">
                    <span className="conflictBodySubHeader pr-2.5">Type :</span>
                    <span
                      className="conflictBodyText path-trimmer "
                      title={recordedElementsData[selectedIndex].type ? recordedElementsData[selectedIndex].type : '--'}
                    >
                      {recordedElementsData[selectedIndex].type}
                    </span>
                  </div>
                </div>
              ) : (
                <div className=" float-left recordedEleConflictBody">
                  <div className="flex items-center h-8">
                    <span className="conflictBodySubHeader pr-2.5">Element In :</span>
                    <span className="conflictBodyDangerText ">Recorded Elements</span>
                  </div>
                  <div className="flex items-center h-8">
                    <span className="conflictBodySubHeader pr-2.5">Element Name :</span>
                    <span
                      className="conflictBodyText"
                      title={recordedElementsData[selectedIndex].name ? recordedElementsData[selectedIndex].name : '--'}
                    >
                      <TextField
                        autoComplete="off"
                        autoFocus={true}
                        onChange={(event) => {
                          handelChangeofSaveAsNew(event, 'name');
                        }}
                        onBlur={(e) => {
                          nameOnBlur(e);
                        }}
                        className=""
                        value={nameValue}
                        type="text"
                        placeholder="Enter element name"
                      />
                      {!nameValue ? (
                        <div className="error-font-style" id={'error' + nameValue}>
                          {' '}
                          Element name is required
                        </div>
                      ) : nameValue?.length < 2 || nameValue?.length > 100 ? (
                        <div className="error-font-style" id={'error' + nameValue}>
                          {' '}
                          Name must be between 2 and 100 characters
                        </div>
                      ) : specialCharError ? (
                        <div className="error-font-style" id={'error' + nameValue}>
                          Only whitespaces are not allowed
                        </div>
                      ) : null}
                    </span>
                  </div>
                  <div className="flex items-center h-8">
                    <span className="conflictBodySubHeader pr-2.5">Page Name :</span>
                    <span className="conflictBodyText -mt-2.5 ">
                      <DropdownTree
                        initalValue={parentValue ? parentValue : ''}
                        isFromRecorded={true}
                        overlay={true}
                        onTyping={() => {
                          setParentValue('');
                          setSelectedPage('');
                        }}
                        showOnlyFolders={true}
                        noDataMessage={`${
                          project.type === 'Mobile'
                            ? 'Screen'
                            : project.type === 'Web'
                              ? 'Page'
                              : project.type === 'Web & Mobile' && props.platForm === 'Web'
                                ? 'Page'
                                : project.type === 'Web & Mobile' &&
                                    ['Android', 'iOS', 'Ios', 'MobileWeb'].includes(props.platForm)
                                  ? 'Screen'
                                  : 'Page'
                        } not found.`}
                        operation={null}
                        placeholder={`Search and select parent ${
                          project.type === 'Mobile'
                            ? 'screen'
                            : project.type === 'Web'
                              ? 'page'
                              : project.type === 'Web & Mobile' && props.platForm === 'Web'
                                ? 'page'
                                : project.type === 'Web & Mobile' &&
                                    ['Android', 'iOS', 'Ios', 'MobileWeb'].includes(props.platForm)
                                  ? 'screen'
                                  : 'page'
                        }`}
                        treeData={treeData}
                        nodeSelected={treeNodeSelected.bind(this)}
                        labelValue={null}
                      />
                    </span>
                  </div>
                  <div className="flex items-center h-8">
                    <span className="conflictBodySubHeader pr-2.5">Type :</span>
                    <span
                      className="conflictBodyText path-trimmer"
                      title={recordedElementsData[selectedIndex].type ? recordedElementsData[selectedIndex].type : '--'}
                    >
                      <Select
                        classes={{
                          root: classesElementType.select,
                        }}
                        value={eleType}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          classes: {
                            paper: classesElementType.menuPaper,
                            list: classesElementType.menulist,
                          },
                          getContentAnchorEl: null,
                        }}
                        onChange={(event) => {
                          handelChangeofSaveAsNew(event, 'eleType');
                        }}
                        onBlur={(e) => {}}
                      >
                        {elementTypeDropDown.map((data, index) => (
                          <MenuItem value={data.value}>{data.label}</MenuItem>
                        ))}
                      </Select>
                    </span>
                  </div>
                </div>
              )}
              <div
                className={`${
                  isNew ||
                  projelementData[selectedIndex]?.isShared === 'Y' ||
                  projelementData[selectedIndex]?.isShared === 'YES'
                    ? 'opacity-50'
                    : null
                } float-right projectEleConflictBody`}
              >
                <div className="flex items-center h-8">
                  <span className="conflictBodySubHeader pr-2.5">Element In :</span>
                  <span className="conflictBodyDangerText pr-2.5 ">
                    {projelementData[selectedIndex]?.isShared === 'Y' ||
                    projelementData[selectedIndex]?.isShared === 'YES'
                      ? 'Shared Elements'
                      : 'Project Elements'}
                  </span>
                </div>
                <div className="flex items-center h-8">
                  <span className="conflictBodySubHeader pr-2.5">Element Name :</span>
                  <span
                    className="conflictBodyText path-trimmer "
                    title={projelementData[selectedIndex].name ? projelementData[selectedIndex].name : '--'}
                  >
                    {projelementData[selectedIndex].name}
                  </span>
                </div>
                <div className="flex items-center h-8">
                  <span className="conflictBodySubHeader pr-2.5">Page Name :</span>
                  <span
                    className="conflictBodyText path-trimmer "
                    title={projelementData[selectedIndex].parentName ? projelementData[selectedIndex].parentName : '--'}
                  >
                    {projelementData[selectedIndex].parentName}
                  </span>
                </div>
                <div className="flex items-center h-8">
                  <span className="conflictBodySubHeader pr-2.5">Type :</span>
                  <span
                    className="conflictBodyText path-trimmer "
                    title={projelementData[selectedIndex].type ? projelementData[selectedIndex].type : '--'}
                  >
                    {projelementData[selectedIndex].type}
                  </span>
                </div>
              </div>
              <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={beforStart}>
                <div className="conflictBodyLocators w-full flex items-center">
                  <Droppable key="recordedLocdragdrop" droppableId="recordedLocdragdrop">
                    {(provided, snapshot) => (
                      <span
                        className="conflictBodyLocatorStyls overflow-y-auto"
                        id="journal-scroll"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <div className="conflictBodySubHeader">Locators</div>
                        {recordedLoc?.map((loctor, index) => {
                          return <RecordedLocatorComponent loctor={loctor} index={index} />;
                        })}
                      </span>
                    )}
                  </Droppable>
                  <span
                    className={`${
                      isNew ||
                      projelementData[selectedIndex]?.isShared === 'Y' ||
                      projelementData[selectedIndex]?.isShared === 'YES'
                        ? 'opacity-50'
                        : null
                    } locatorsMoveIcon flex items-center`}
                  >
                    <svg
                      id="arrow_circle_right_black_24dp"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g id="Group_6218" data-name="Group 6218">
                        <rect id="Rectangle_1515" data-name="Rectangle 1515" width="24" height="24" fill="none" />
                      </g>
                      <g id="Group_6219" data-name="Group 6219">
                        <path
                          id="Path_514"
                          data-name="Path 514"
                          d="M22,12A10,10,0,1,0,12,22,10,10,0,0,0,22,12ZM12,13H8V11h4V8l4,4-4,4Z"
                          fill="#3a62c9"
                        />
                      </g>
                    </svg>
                  </span>
                  <Droppable key="projectLocdragdrop" droppableId="projectLocdragdrop">
                    {(provided, snapshot) => (
                      <span
                        className={`${
                          isNew ||
                          projelementData[selectedIndex]?.isShared === 'Y' ||
                          projelementData[selectedIndex]?.isShared === 'YES'
                            ? 'opacity-50'
                            : null
                        } conflictBodyLocatorStyls overflow-y-auto`}
                        id="journal-scroll"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <div className="conflictBodySubHeader">Locators</div>
                        {projectLoc?.map((loctor, index) => {
                          if (loctor?.key === 'draged' && !disableSaveAsNew) {
                            setDisableSaveAsNew(true);
                          }
                          return (
                            <ProjLocatorComponent
                              conflictedprojElements={props.conflictedprojElements}
                              loctor={loctor}
                              index={index}
                            />
                          );
                        })}{' '}
                      </span>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>
            </div>
            <div className="conflicyBodyFooter flex items-center">
              <span className="ml-auto">
                {!isNew ? (
                  <button
                    type="button"
                    className="gray-btn mr-4 Recordedsave"
                    disabled={disableSaveAsNew || recordedLoc.length === 0}
                    onClick={() => {
                      if (!disableSaveAsNew) {
                        handelSaveAsNewMode();
                      }
                    }}
                  >
                    <span className="cancel-label-common cancel-label-layout-style-project fontPoppinsMediumMd">
                      Save as New
                    </span>
                  </button>
                ) : null}
                <button
                  className="gray-btn mr-4"
                  onClick={() => {
                    if (isNew) {
                      setIsNew(false);
                    }
                    setDiscard(true);
                    setDiscardElementModal(true);
                  }}
                >
                  <span className="cancel-label-common cancel-label-layout-style-project fontPoppinsMediumMd">
                    Discard
                  </span>
                </button>
                {isNew ? (
                  <button
                    className="gray-btn mr-4"
                    onClick={() => {
                      setIsNew(false);
                    }}
                  >
                    <span className="cancel-label-common cancel-label-layout-style-project fontPoppinsMediumMd">
                      cancel
                    </span>
                  </button>
                ) : null}
                <button
                  type="button"
                  className="primary-btn mr-4"
                  disabled={isNew ? enableSaveAsNew || nameError : idForEnableSave.length !== 0}
                  onClick={() => {
                    if (isNew) {
                      createAsNewEle();
                      setIsNew(false);
                    } else {
                      clearMergeConflictReq();
                      props.getAllElemtsReq(props.projectDetails?.type, props.platForm);
                      props.getAllRecordedElements();
                      props.closeModal(false);
                    }
                  }}
                >
                  <span className="create-label-common create-label-layout-style-project fontPoppinsMediumMd">
                    Save {!isNew ? 'Changes' : null}
                  </span>
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      {discardElementModal ? (
        <DiscardElementModal closeModal={closeModal} discard={discard} handelDiscardEle={handelDiscardEle} />
      ) : null}
    </div>
  );
};

export default RecordedEleConflictPage;

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { ReactComponent as YellowAlert } from '@assets/svg-imports/Icon-warning.svg';
import { deleteProjectRequest } from '@api/api_services';
import '@pages/configuration/Memory_Config/MemoryConfig.scss';
import { getUserName } from '@util/common_utils';
import { MEMORYCONFIG_CONSTANTS } from '@src/common/ui-constants';

function DeleteMemoryModal(props) {
  const projectId = props.selectedData.id;
  const Username = getUserName();
  const ProjectName = props.selectedData.name;
  const [createUpdateCalled, setCreateUpdateCalled] = useState(true);

  let handelDlt = () => {
    if (createUpdateCalled) {
      setCreateUpdateCalled(false);
      deleteProjectRequest(projectId)
        .then((res) => {
          setCreateUpdateCalled(true);
          props.closeModal();
          props.getVariables();
          props.MyAlert.success(`${ProjectName} ${res?.data?.message}`);
        })
        .catch((error) => console.error(error));
    }
  };
  return (
    <>
      <Modal
        open={props.openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="focus:outline-none outline-none"
      >
        <Box className="changeMemoryBoxModal focus:outline-none outline-none">
          <div className="headerMemoryChange">
            <div className="successImg">
              <YellowAlert className="memorySvgImgStyle" />
            </div>
            <div className="mt-8">
              <div className="flex flex-row">
                <p className="svgYellow fontPoppinsMediumXX2 ml-6">{MEMORYCONFIG_CONSTANTS.WARNING}</p>
                <p className="fontPoppinsMediumXX2 content-style ml-2">{MEMORYCONFIG_CONSTANTS.DELETE}</p>
              </div>
              <br />
              <div className="fontPoppinsRegularLg ml-7 -mt-3  flex flex-col">
                <span className="fontPoppinsRegularMd content-style">
                  {MEMORYCONFIG_CONSTANTS.HI} {Username},
                </span>
                <span className="mt-2 fontPoppinsRegularMd content-style">
                  {MEMORYCONFIG_CONSTANTS.DATA_LOST} <br />
                  <span className="fontPoppinsSemiboldMd content-style">{ProjectName}</span>{' '}
                  {MEMORYCONFIG_CONSTANTS.PROJECT}
                </span>
                <span className="mt-2 fontPoppinsRegularMd content-style">{MEMORYCONFIG_CONSTANTS.PROCEED}</span>
              </div>
              <span className="ml-7 mb-2"> </span>
              <div className="ml-7"></div>
            </div>
          </div>
          <div className="memory-delete-footer flex mt-1 -mx-1">
            <button
              className="memory-button-cancel cancel-info cursor-pointer fontMontserratMediumXs"
              onClick={props.closeModal}
            >
              {MEMORYCONFIG_CONSTANTS.CANCEL}
            </button>
            <button
              className="memory-delete-button delete-info cursor-pointer fontMontserratMediumXs"
              onClick={() => {
                handelDlt();
              }}
            >
              {MEMORYCONFIG_CONSTANTS.DELETE}
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default DeleteMemoryModal;

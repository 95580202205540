import React from 'react';
import { useHistory } from 'react-router';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { changeActivePhoneNumber } from '@api/api_services';
import useGeoLocation from 'react-ipgeolocation';
import '@pages/auth/login.css';
import { PHONE_NUMBER_VALIDATION } from '@src/common/ui-constants';
import { useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import CommonPage from '../common/CommonPage';
import cx from 'classnames';
import commonStyles from '@pages/auth/common.module.scss';
import CommonBorder from '../common/common-border';

export default function PhoneNumberValid() {
  const [ChangedPhoneNumber, setChangedPhoneNumber] = React.useState('');
  const [phNoindex, setIndex] = React.useState(0);
  const [fields, setFields] = React.useState([{ value: null }]);
  const [countryLocate, setCountryLocate] = React.useState('US');
  const [phoneValidate, setPhoneValidate] = React.useState('');
  const [validNumber, setValidNumber] = useState(false);
  const location = useGeoLocation();

  React.useEffect(() => {
    setCountryLocate(location?.country);
  }, [location]);

  function handleChange(values) {
    const isValidNumber = isValidPhoneNumber('+' + values?.toString()) === true;
    if (isValidNumber) {
      setValidNumber(isValidNumber);
      setChangedPhoneNumber(values);
      setPhoneValidate('');
    } else {
      setValidNumber(false);
      setPhoneValidate('Valid Phone number is required');
    }
  }
  let history = useHistory();
  const [createUpdateCalled, setCreateUpdateCalled] = React.useState(true);
  const OnSubmitActivePhoneNumber = () => {
    if (ChangedPhoneNumber.length === 0) {
      setPhoneValidate('Phone number required');
    }
    if (createUpdateCalled && validNumber) {
      setCreateUpdateCalled(false);
      const userId = sessionStorage?.getItem('userid');
      const data = {
        id: userId,
        phoneNumbers: {
          primaryPhone: ChangedPhoneNumber,
        },
      };
      changeActivePhoneNumber(data)
        .then((resp) => {
          let respUrl = resp.data.responseObject.url;
          sessionStorage.setItem('respUrl', respUrl);
          sessionStorage.setItem('ChangedPhoneNumber', ChangedPhoneNumber);
          history.push('/otp-verification');
          setCreateUpdateCalled(false);
          setValidNumber(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const handlePhoneNumberBlur = () => {
    if (validNumber) {
      setCreateUpdateCalled(true);
    } else {
      setPhoneValidate('Valid Phone number is required');
    }
  };
  return (
    <CommonPage wrapperClassname={`common-padding`}>
      <div className="">
        <div className={cx('flex justify-center items-center flex-col', commonStyles['content-header'])}>
          <span>{PHONE_NUMBER_VALIDATION.CHANGE_PHONE_NUMBER}</span>
          <CommonBorder />
        </div>
        <div className="flex flex-col justify-center items-center gap-4 mt-4">
          <div className="text-center fontPoppinsRegularMd width-97-per">
            To change your phone number, please be aware that the process will generate a new <strong>OTP</strong>{' '}
            (One-Time Password) for verification. This <strong>OTP</strong> will be sent to your new phone number to
            confirm the change.
          </div>
          <div className="text-center fontPoppinsRegularMd width-97-per">{PHONE_NUMBER_VALIDATION.CONFIRM_MESSAGE}</div>
        </div>
        <div className="mb-9 mt-4">
          {fields.map((field, idx) => {
            return (
              <>
                <div key={`${field}-${idx}`} className="flex flex-row mt-3">
                  <PhoneInput
                    type="text"
                    id="phoneNumbers1"
                    value={field.value || ''}
                    autoComplete="off"
                    name="phoneNumbers"
                    specialLabel=""
                    searchPlaceholder="Search..."
                    searchNotFound="please try again"
                    onBlur={handlePhoneNumberBlur}
                    defaultCountry={location?.country?.toLowerCase()}
                    enableAreaCodeStretch
                    country={location?.country?.toLowerCase()}
                    enableSearch
                    inputStyle={{
                      height: '26px',
                      width: '93%',
                      border: '1px solid #E0E0E0',
                      borderRadius: '5px',
                      boxShadow: '0px 0px 6px #00000000',
                      marginLeft: '-1px',
                    }}
                    searchStyle={{
                      borderBottom: '1px solid #989797',
                      borderRadius: '0px',
                      borderTop: 'none',
                      borderLeft: 'none',
                      borderRight: 'none',
                      boxShadow: '0px 0px',
                      width: '90%',
                    }}
                    dropdownStyle={{
                      width: '250px',
                      height: '236px',
                      borderRadius: '5px',
                      boxShadow: '0px 0px 6px #00000029',
                      textAlign: 'left',
                      position: 'fixed',
                      backgroundColor: '#FBFCFD',
                    }}
                    international
                    countryCodeEditable={false}
                    onChange={(e) => handleChange(e)}
                    onClick={() => setIndex(idx)}
                    className="max-w-lg block w-11/12 fontPoppinsRegularMd"
                    placeholder="Enter Your Phone Number"
                  />
                </div>
              </>
            );
          })}

          <span id="validPhn" className="fontPoppinsRegularXs">
            {phoneValidate}
          </span>
        </div>

        <div className="mb-4">
          <button onClick={() => OnSubmitActivePhoneNumber()} className={commonStyles['sign-in-button']}>
            {PHONE_NUMBER_VALIDATION.CHANGE_AND_RESEND_OTP}
          </button>
        </div>
      </div>
    </CommonPage>
  );
}

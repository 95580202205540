import { Tooltip } from '@material-ui/core';

const getBrowserImage = (fileName, altName) => {
  return (
    <img
      src={`/assets/images/${fileName}`}
      className="float-left mt-0.5 mr-2 inline-block browser-logo"
      alt={altName}
    />
  );
};
export const getBrowserName = (value) => {
  value = value ? value.toLowerCase() : '';
  if (value.includes('opera')) {
    return 'Opera Mini';
  } else if (value.includes('chrome')) {
    return 'Google Chrome';
  } else if (value.includes('ie') || value.includes('internet explorer')) {
    return 'Internet Explorer';
  } else if (value.includes('firefox')) {
    return 'Firefox';
  } else if (value.includes('safari')) {
    return 'Safari';
  } else if (value.includes('iphone') || value.includes('ipad')) {
    return value;
  } else if (value.includes('android')) {
    return value;
  } else if (value.includes('samsung')) {
    return value;
  } else if (value.includes('edge')) {
    return 'Microsoft Edge';
  } else {
    return value;
  }
};
export function getBrowserLabel(value, toolTipRequired = false) {
  const getBrowserLogo = (value) => {
    value = value ? value.toLowerCase() : '';
    if (value.includes('opera')) {
      return getBrowserImage('opera.png', 'opera');
    } else if (value.includes('chrome')) {
      return getBrowserImage('Chrome.svg', 'chrome');
    } else if (value.includes('ie') || value.includes('internet explorer')) {
      return getBrowserImage('ie.png', 'ie');
    } else if (value.includes('firefox')) {
      return getBrowserImage('Firefox.svg', 'firefox');
    } else if (value.includes('safari')) {
      return getBrowserImage('safari.png', 'safari');
    } else if (value.includes('iphone') || value.includes('ipad')) {
      return getBrowserImage('apple_icon.svg', 'apple');
    } else if (value.includes('android')) {
      return getBrowserImage('android.png', 'android');
    } else if (value.includes('samsung')) {
      return getBrowserImage('samsung.png', 'samsung');
    } else if (value.includes('edge')) {
      return getBrowserImage('Microsoft_Edge.svg', 'edge');
    } else {
      return '';
    }
  };
  return [
    toolTipRequired ? (
      <Tooltip title={getBrowserName(value)} placement="top">
        <span className="table-font-style-common">
          {' '}
          {getBrowserLogo(value)} {getBrowserName(value)}
        </span>
      </Tooltip>
    ) : (
      <span className="table-font-style-common">
        {' '}
        {getBrowserLogo(value)} {getBrowserName(value)}
      </span>
    ),
  ];
}

const getOsImage = (fileName, altName, classList) => {
  return (
    <img
      src={`/assets/images/${fileName}`}
      className={`${classList || 'float-left mr-2 mt-0.5'} os-logo`}
      alt={altName}
    />
  );
};

export function getOsLabel(value, classList = false, isFromMachineTable = false) {
  const getOsLogo = (value) => {
    value = value ? value : '--';
    if (value.toLowerCase().includes('windows')) {
      return getOsImage('windows-10-icon-logo-5BC5C69712-seeklogo.com.svg', 'windows', classList);
    } else if (
      value.toLowerCase().includes('mac') ||
      value.toLowerCase().includes('os') ||
      value.toLowerCase().includes('ios') ||
      value.toLowerCase().includes('os x')
    ) {
      return getOsImage('apple_icon.svg', 'apple', classList);
    } else if (value.toLowerCase().includes('linux')) {
      return getOsImage('LINUX-LOGO.svg', 'linux', classList);
    } else if (value.toLowerCase().includes('android')) {
      return getOsImage('android_icon.svg', 'android', classList);
    } else {
      return (
        <span className={isFromMachineTable ? '' : 'table-non-link-color-common table-font-style-common'}>
          {' '}
          {value}
        </span>
      );
    }
  };
  return [
    <span className={isFromMachineTable ? '' : 'table-font-style-common'}>
      {' '}
      {getOsLogo(value)} <span className="pl-2 fontPoppinsRegularSm"> {value}</span>
    </span>,
  ];
}

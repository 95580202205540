import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import { postPackageReq, putPackageReq } from '../../../../../api/api_services';
import DropdownTree from '../../../../common/dropdown_tree';
import { checkPageExistParentPage } from '../../../../../common/ui-constants';
import { TextareaAutosize, TextField } from '@material-ui/core';
const PackageModal = (props) => {
  const { children, ...default_node } = props.treeData[0];
  const [showModal, setShowModal] = useState(true);
  const [selectedPackage, setSelectedPackage] = useState('');
  const [descCount, setdescCount] = React.useState(0);
  let [noOfRows, setNoOfRows] = useState(1);
  const [createUpdateCalled, setCreateUpdateCalled] = useState(true);
  const search = window.location.search;
  const id = new URLSearchParams(search).get('id');
  let initialValues;
  let [executionOrder, setExecutionOrder] = useState(
    props.nodeToAdd ? (props.nodeToAdd === 0 ? 1 : findExecutionOrder(props.nodeToAdd)) : 1
  );
  function findExecutionOrder(node) {
    let x, y;
    if (node.getLastChild() !== null) {
      let myNode;
      if (node.getLastChild().title === 'Postconditions') {
        myNode = node.getLastChild().getPrevSibling();
        x = node.getLastChild().data.executionOrder;
      } else {
        myNode = node.getLastChild();
      }
      while (myNode.hasChildren()) {
        if (myNode.getLastChild().title === 'Postconditions') {
          myNode = myNode.getLastChild().getPrevSibling();
          x = myNode.getLastChild().data.executionOrder;
        } else {
          myNode = myNode.getLastChild();
        }
      }
      y = myNode.data.executionOrder;
    } else {
      y = node.data.executionOrder;
    }
    if (x === undefined) {
      if (node.getNextSibling() !== null) {
        x = node.getNextSibling().data.executionOrder;
      } else {
        if (node.title === 'Root package') {
          x = 0;
        } else {
          let parent = node.parent;
          for (let i = node.getLevel(); i >= 1; i--) {
            if (parent.getNextSibling() !== null) {
              x = parent.getNextSibling().data.executionOrder;
              break;
            } else if (i === 1) {
              x = parent.data.executionOrder;
              break;
            } else {
              parent = parent.parent;
            }
          }
        }
      }
    }
    if (x === undefined || node.title === 'Root package') {
      if (props.treeData[0].title === 'Root package') {
        return y + 1;
      } else {
        return Number((y + 0.0001).toFixed(7));
      }
    } else {
      return (x + y) / 2;
    }
  }
  useEffect(() => {
    if (props.mode === 'EDIT') {
      setdescCount(props.data.desc.length);
    }
  }, []);
  if (props.data) {
    initialValues = {
      packageName: props.data.name,
      description: props.data.desc,
      parentPage: props.data.parentName,
    };
  } else if (props?.parentName) {
    initialValues = {
      packageName: '',
      description: '',
      parentPage: props?.parentName,
    };
  } else if (props.treeData[0].children.length > 0) {
    initialValues = {
      packageName: '',
      description: '',
      parentPage: '',
    };
  } else {
    initialValues = {
      packageName: '',
      description: '',
      parentPage: 'Root',
    };
  }

  const treeNodeSelected = (data) => {
    if (data && data.data.key) {
      setExecutionOrder(findExecutionOrder(data.node));
      setSelectedPackage(data);
      formikDetails.setFieldValue('parentPage', data.title);
    }
  };

  const onTyping = () => {
    formikDetails.setFieldValue('parentPage', '');
  };
  async function createContainerReq(reqBody) {
    let response;
    try {
      response = await postPackageReq(reqBody);
      if (response.data.responseObject) {
        props.closeModal(false);
        props.reloadTree(true);
        props.MyAlert.success(
          `${reqBody.name} ${reqBody.name.toLowerCase().includes('package') ? '' : 'Package'} created successfully`
        );
      } else {
        if (response.data.responseCode === 400 && response.data.message.includes('already exists')) {
          formikDetails.setFieldError('packageName', 'Name already exists');
          setCreateUpdateCalled(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function updateContainerReq(reqBody) {
    let response;
    try {
      response = await putPackageReq(reqBody.id, reqBody);
      if (response.data.responseObject) {
        props.closeModal(false);
        //this is code is written to maintain the state of tree and passing the update date to tree
        props.updatedValue(response.data.responseObject.responseObject, 'folder');
        props.MyAlert.success(
          `${reqBody.name} ${reqBody.name.toLowerCase().includes('package') ? '' : 'Package'} updated successfully`
        );
        if (id === reqBody.id) {
          props.reloadTree(true);
        }
      } else {
        if (response.data.responseCode === 400 && response.data.message.includes('already exists')) {
          formikDetails.setFieldError('packageName', 'Name already exists');
          setCreateUpdateCalled(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const updatePackage = () => {
    const values = formikDetails.values;
    if (values.packageName.toLowerCase() === 'root') {
      props.closeModal(false);
      props.MyAlert.warning(`Package name should not be ${values.packageName}`);
    }
    let exist = false;
    let noupdate = false;
    if (values.packageName === props.data.name && values.description === props.data.desc) {
      noupdate = true;
    } else if (values.packageName === props.data.name && values.description !== props.data.desc) {
      exist = false;
    } else if (values.packageName.toLowerCase() === props.data.name.toLowerCase()) {
      exist = false;
    } else if (values.packageName !== props.data.name) {
      exist = checkPageExistParentPage(props.treeData, props.data.parentId, values.packageName);
    }
    if (noupdate) {
      props.closeModal(false);
      props.MyAlert.info(`nothing there  to update`);
    } else if (exist) {
      formikDetails.setFieldError('packageName', 'Name already exists');
      setCreateUpdateCalled(true);
    } else {
      props.data.name = values.packageName;
      props.data.desc = values.description;
      updateContainerReq(props.data);
    }
  };
  const createPackage = () => {
    const values = formikDetails.values;
    if (values.packageName.toLowerCase() === 'root') {
      props.closeModal(false);
      props.MyAlert.warning(`Package name should not be ${values.packageName}`);
    }
    let exist = false;
    if (props.treeData[0]?.children.length > 0) {
      if (props.parentId) {
        exist = checkPageExistParentPage(props.treeData, props.parentId, values.packageName);
      } else if (!selectedPackage) {
        exist = checkPageExistParentPage(props.treeData, default_node.key, values.packageName);
      } else {
        exist = checkPageExistParentPage(props.treeData, selectedPackage.data.key, values.packageName);
      }
    }
    let requestBody;
    if (exist) {
      formikDetails.setFieldError('packageName', 'Name already exists');
      setCreateUpdateCalled(true);
    } else {
      if (values) {
        requestBody = {
          name: values.packageName,
          desc: values.description,
          executionOrder: executionOrder,
        };
        requestBody['programElements'] = [];
        if (props.parentId) {
          requestBody['parentId'] = props.parentId;
          requestBody['parentName'] = props.parentName;
        } else if (!selectedPackage) {
          requestBody['parentId'] = default_node.key;
          requestBody['parentName'] = default_node.title;
        } else {
          requestBody['parentId'] = selectedPackage.data.key;
          requestBody['parentName'] = selectedPackage.title;
        }
        createContainerReq(requestBody);
      }
    }
  };
  const validationSchema = yup.object({
    packageName: yup
      .string()
      .required('Name is required')
      .matches(/^[a-zA-Z0-9_$]*$/, 'Name should allow _ and $ only')
      .min(1, 'Name must contain minimum 1 character'),
    parentPage: yup.string().required('Parent package is required'),
    description: yup.string(),
  });

  const formikDetails = useFormik({
    initialValues,
    validationSchema,
  });

  let errorExistForParentPage = false;
  if (formikDetails.values.parentPage === '' && formikDetails.touched.parentPage) {
    errorExistForParentPage = true;
  } else {
    errorExistForParentPage = false;
  }
  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false);
        props.closeModal(false);
      }}
      className="modal-dialog"
    >
      <div className="modal-container page-modal-size">
        <div className="modal-header">
          {props.mode === 'ADD' || props.mode === 'ADD_SUB' ? (
            <h2 className="title"> Create Package</h2>
          ) : (
            <div className="title title-trim" title={props.data.name}>
              Edit Package - {props.data.name}
            </div>
          )}
          <IconButton
            color="primary"
            component="span"
            className="close"
            onClick={() => {
              props.closeModal(false);
              setShowModal(false);
            }}
          >
            <img
              style={{
                cursor: 'pointer',
              }}
              src="/assets/images/close_black.svg"
              alt=""
              height="25px"
              width="25px"
            />
          </IconButton>
        </div>
        <div className="modal-body" id="journal-scroll">
          <form onSubmit={formikDetails.handleSubmit}>
            <div className="">
              <div>
                <TextField
                  error={formikDetails.errors.packageName && formikDetails.touched.packageName}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoFocus={true}
                  className="w-1/2"
                  type="text"
                  name="packageName"
                  id="packageName"
                  autoComplete="off"
                  placeholder={`Enter package name`}
                  onBlur={formikDetails.handleBlur}
                  onChange={formikDetails.handleChange}
                  value={formikDetails.values.packageName}
                  label={
                    <>
                      {' '}
                      <span className="text-red-400 mr-1">&#42;</span>Name
                    </>
                  }
                />
              </div>
              {formikDetails.errors.packageName && formikDetails.touched.packageName ? (
                <div className="errorMessage">{formikDetails.errors.packageName}</div>
              ) : null}
            </div>
            <div className="mt-5 w-full">
              <label htmlFor="description" className="input-filed-label-style-common">
                Description
              </label>
              <TextareaAutosize
                rowsMax={noOfRows}
                onBlur={(e) => {
                  setNoOfRows(1);
                  e.target.classList.add('descriptionStyle');
                }}
                onFocus={(e) => {
                  setNoOfRows(null);
                  e.target.classList.remove('descriptionStyle');
                }}
                id="description"
                name="description"
                maxLength="200"
                className="-mt-1 block w-full input-style-textarea popup-input-bg  border-0 border-b input-field-color descriptionStyle"
                onKeyUp={(e) => setdescCount(e.target.value.length)}
                value={formikDetails.values.description}
                placeholder={props.mode === 'ADD' || props.mode === 'ADD_SUB' ? 'Your description goes here...' : ''}
                onChange={formikDetails.handleChange}
              />
              <div className="mt-1 text-sm text-gray-500 text-right">{descCount}/200</div>
              {formikDetails.errors.description && formikDetails.touched.description && (
                <p style={{ color: 'red' }}>{formikDetails.errors.description}</p>
              )}
            </div>
            <div className="mt-2">
              <div>
                <div className="mt-2 text-sm text-blue-700">
                  <DropdownTree
                    errorExist={errorExistForParentPage}
                    initalValue={
                      props.parentName
                        ? props.parentName
                        : props.data && props.data.parentName
                          ? props.data.parentName
                          : props.treeData[0]?.children.length > 0
                            ? ''
                            : 'Root Package'
                    }
                    noDataMessage="Package not found."
                    showOnlyFolders={true}
                    onTyping={onTyping}
                    operation={
                      props.mode === 'ADD_SUB' || props.mode === 'EDIT'
                        ? 'edit'
                        : props.treeData[0]?.children.length > 0
                          ? ''
                          : 'edit'
                    }
                    placeholder="Search and select parent package"
                    treeData={props.treeData ? props.treeData : []}
                    nodeSelected={treeNodeSelected.bind(this)}
                    labelValue="Parent Package"
                  />
                </div>
              </div>
              {formikDetails.errors.parentPage &&
              formikDetails.touched.parentPage &&
              formikDetails.values.parentPage === '' ? (
                <div className="errorMessage">{formikDetails.errors.parentPage}</div>
              ) : null}
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <button
            className="gray-btn mr-2"
            onClick={() => {
              props.closeModal(false);
              setShowModal(false);
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="primary-btn"
            onClick={() => {
              formikDetails.setTouched({
                packageName: true,
                parentPage: true,
              });
              if (
                formikDetails.values.packageName !== '' &&
                formikDetails.values.parentPage !== '' &&
                formikDetails.values.packageName.length >= 1 &&
                formikDetails.errors.packageName !== 'Name should allow _ and $ only' &&
                formikDetails.errors.packageName !== 'Only whitespaces are not allowed'
              ) {
                if (props.mode === 'ADD' || props.mode === 'ADD_SUB') {
                  if (createUpdateCalled) {
                    setCreateUpdateCalled(false);
                    createPackage();
                  }
                } else {
                  if (createUpdateCalled) {
                    setCreateUpdateCalled(false);
                    updatePackage();
                  }
                }
              }
            }}
          >
            {props.mode === 'ADD' || props.mode === 'ADD_SUB' ? <span>Create</span> : <span>Update</span>}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PackageModal;

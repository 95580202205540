import React, { memo } from 'react';
import IconButton from '@mui/material/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { LAYOUT_CONSTANTS } from '../constants/LayoutConstants';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as RightArrowIcon } from '@assets/svg-imports/arrow_right.svg';
import { ReactComponent as DownArrowIcon } from '@assets/svg-imports/arrow_down.svg';
import { ReactComponent as AllProjectsIcon } from '@assets/svg-imports/all_projects_menu.svg';
import { Tooltip } from '@mui/material';
import { fontPoppins } from '@src/css_config/fontConstants';
import styles from '@pages/common/Layout/styles/side_navigation_style.module.scss';
import { getUserStatus, getLicenseStatus } from '@util/common_utils';

const useStyles = makeStyles({
  menuButton: {
    left: '5%',
    top: '7px',
  },
  listItem: {
    backgroundColor: LAYOUT_CONSTANTS.ALL_PROJECT_LIST_BG_COLOR,
    padding: 0,
    cursor: 'pointer',
  },
  menuClosedButton: {
    left: '-28%',
    top: '7px',
  },
  textOverflow: {
    ...fontPoppins.sLg,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '88px',
    marginLeft: '-10px',
  },
});

export const AllProjectsComponent = (props) => {
  const licenseStatus = getLicenseStatus();
  const userStatus = getUserStatus();
  const isDisabled =
    licenseStatus === 'EXPIRED' || userStatus === 'DISABLED' || licenseStatus === null || userStatus === null;
  const classes = useStyles();
  const { menuLabel, isDrawerOpen, isAllProjectClick, handleAllProjectsClick } = props;

  return (
    <>
      <Tooltip title={'Project Selected:' + menuLabel} placement="right">
        <ListItem
          className={`block absolute ${classes.listItem}
          ${isDisabled ? styles['side-menu-list-disable'] : styles['side-menu-list-enable']} `}
          key={'all-projects'}
          onClick={() => {
            handleAllProjectsClick();
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: 3,
              justifyContent: 'center',
              ml: 2,
            }}
          >
            <AllProjectsIcon />
          </ListItemIcon>

          <ListItemText
            className={classes.textOverflow}
            disableTypography={true}
            primary={menuLabel}
            sx={{
              opacity: isDrawerOpen ? 1 : 0,
              display: 'block',
            }}
          />
          <IconButton className={isDrawerOpen ? classes.menuButton : classes.menuClosedButton}>
            {isAllProjectClick ? <DownArrowIcon /> : <RightArrowIcon />}
          </IconButton>
        </ListItem>
      </Tooltip>
    </>
  );
};
export default memo(AllProjectsComponent);

import React, { Fragment } from 'react';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { Tooltip } from '@material-ui/core';
function ActionButton({ editAction, deleteAction, MoreAction, menuoptions }) {
  let menuData = () => {
    return (
      <div className="actionIcons">
        {editAction && (
          <Tooltip title="Edit">
            <EditOutlinedIcon
              className="float-left cursor-pointer text-base mr-3 text-blue-700"
              onClick={() => editAction()}
            />
          </Tooltip>
        )}
        {deleteAction && (
          <Tooltip title="Delete">
            <DeleteOutlinedIcon
              className="float-left cursor-pointer mr-3 text-blue-700 text-base"
              onClick={() => deleteAction()}
            />
          </Tooltip>
        )}
        {MoreAction && (
          <div className="inline-block">
            <Menu as="div" className="relative inline-block text-left mt-px">
              {({ open }) => (
                <>
                  <div>
                    <Menu.Button className="bg-gray-100 rounded-full flex items-center  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 focus:ring-indigo-500">
                      <span className="sr-only">Open options</span>

                      <DotsVerticalIcon className="h-5 w-5 configmoreIcon" aria-hidden="true" />
                    </Menu.Button>
                  </div>

                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className=" origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <div className="py-1">
                        {menuoptions?.map((item) => (
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'block px-4 py-2 text-sm w-full text-left'
                                )}
                                onClick={item.action}
                              >
                                {item.label}
                              </button>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        )}
      </div>
    );
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  return menuData();
}

export default ActionButton;

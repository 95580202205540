import { MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import * as yup from 'yup';
import {
  addStepGroupVariable,
  updateStepGroupVariable,
  createGlobalVariable,
  createLocalVariableReq,
  createProjectEnvironmentVariable,
  updateGloabalVariable,
  updateLocalVariableReq,
  updateProjectEnvironmentVairable,
  getAllLocalVariablesReq,
  getAllGlobalVariableListReq,
  getAllProjectVariableListReq,
  getAllTestDataFiles,
} from '@api/api_services';
import '../../test-development.scss';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/styles';
import MySelect from '@pagescommon/Inputs/MySelect';
import MyInput from '@pagescommon/Inputs/MyInput';
import { colors } from '@src/css_config/colorConstants';
import { Checkbox } from '@mui/material';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';
import InfoIcon from '@pages/test-development/script/scripts/webservice/restapi/request/request_parameter/images/info-gray-24dp.svg';
import InfoIconHover from '@pages/test-development/script/scripts/webservice/restapi/request/request_parameter/images/info_black_24dp.svg';
import { ASSERT_CONSTANT } from '@src/pages/test-development/script/scripts/webservice/restapi/request/assert/jsx/constant';
import { WEBSERVICE } from '../../script/scripts/webservice/restapi/constants/constants';
import useEscapeKeyCloseModal from '@src/hooks/useEscapeKeyCloseModal';
import { pathName, renderFilePath, searchFilePathBasedOnHash } from '@src/util/common_utils';
import { HASH_LABELS } from '@common/ui-constants';
import { ClickAwayListener } from '@mui/base';

const useStyles = makeStyles({
  checkbox: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-checked': {
      color: colors.rs_primary,
    },
  },
});

const usePlaceholderStyles = makeStyles({
  placeholder: {
    color: colors.light_gray_100,
    fontSize: 12,
  },
});
Modal.setAppElement('#root');

function CreateVariable(props) {
  const createVariableBtnRef = useRef(null);
  const createVariableSelectRef = useRef(null);
  const classes = useStyles();
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  const variableTypeOptions = [
    { name: 'Global Variable', value: 'GLOBAL' },
    { name: 'Project Environment Variable', value: 'PROJECT_ENVIRONMENT' },
  ];
  const { MODULE, ROOT, STEPGROUP } = ASSERT_CONSTANT;
  const { CAPTURE_GET_JSON_PATH, CAPTURE_GET_HEADER_KEY, JSON_PATH_TITLE, HEADER_KEY_TITLE } = WEBSERVICE;
  if (![MODULE, ROOT].includes(props?.label)) {
    if (props.type === STEPGROUP) {
      variableTypeOptions.unshift({ name: 'Step Group Variable', value: 'Step' });
    } else {
      variableTypeOptions.unshift({ name: 'Local Variable', value: 'LOCAL' });
    }
  }
  if (props.createVariableFromModuleCondition && props.createVariableFromModuleCondition === true) {
    variableTypeOptions.shift();
  }
  const project = JSON.parse(localStorage.getItem('selected-project'));
  let editData = {};
  if (props?.data) {
    editData = props.data;
  }
  const [openModal, setOpenModal] = useState(true);
  const [createCalled, setCreateCalled] = useState(false);
  const [isValueMasked, setIsValueMasked] = useState(props?.data?.masked ? true : false);
  const [onMouseHover, setonMouseHover] = useState(false);
  const typeOfModal = HASH_LABELS.DRAWER_TYPE_DROPDOWN;
  const customStylesPage = {
    content: {
      position: 'relative',
      minWidth: '450px',
      minHeight: '100%',
      opacity: '1',
      float: 'right',
      border: 'none',
      padding: 0,
      borderRadius: '10px 0px 0px 10px',
      overflow: 'hidden',
      background: `${colors.text_white} 0% 0% no-repeat padding-box`,
      boxShadow: 'none',
    },
  };
  const validationSchema = yup.object({
    variableName: yup
      .string()
      .trim('Space is not allowed at starting and at the end')
      .strict(true)
      .required('Name is required!!')
      .matches(/^[-_A-Za-z0-9 ]*$|^[-_A-Za-z0-9 ][A-Za-z0-9 ]*[-_A-Za-z-_]$/, 'Name should be alphanumeric')
      .min(3, 'Name should contain atleast 3 characters')
      .max(25, 'Name can contain atmost 25 characters'),
    variableType: yup.string().required('Type is required'),
    variableValue: yup.string(),
    variableValueMasked: yup.boolean(),
  });

  let initialValues;
  if (props?.data) {
    initialValues = {
      variableName: editData?.name,
      variableType: editData?.type,
      variableValue: editData?.value,
      variableValueMasked: editData?.masked,
    };
  } else {
    initialValues = {
      variableName: '',
      variableType: props?.variableType ? props.variableType : props?.type ? 'Step' : 'LOCAL',
      variableValue: '',
      variableValueMasked: false,
    };
  }
  /************for steps*************/
  if (props?.data && props?.variableName) {
    //for edit
    initialValues = {
      variableName: props?.data?.name,
      variableType:
        props?.data?.type === 'LOCAL' && props?.data?.parentVariableType === 'STEPGROUP' ? 'Step' : props?.data?.type,
      variableValue: props?.data?.value,
      variableValueMasked: props?.data?.masked,
    };
  } else if (!props?.data && props?.variableName && !props?.variableValue) {
    //for create
    initialValues = {
      variableName: props?.variableName,
      variableType: [MODULE, ROOT].includes(props?.label) ? 'GLOBAL' : props?.variableType ? props.variableType : '',
      variableValue: props?.variableName,
      variableValueMasked: props?.variableName?.masked,
    };
  } else if (!props?.data && props?.variableName && props?.variableValue) {
    initialValues = {
      variableName: props?.variableName,
      variableType: [MODULE, ROOT].includes(props?.label) ? 'GLOBAL' : props?.variableType ? props.variableType : '',
      variableValue: props?.variableValue,
      variableValueMasked: props?.variableName?.masked,
    };
  }
  /************for steps*************/

  function validateVariableValue(str) {
    if (str.trim().length === 0) {
      return true;
    }
    return false;
  }
  async function checkForDuplicateName(enteredVariableName, enteredVariableType) {
    try {
      let res;
      if (enteredVariableType === 'LOCAL') {
        res = await getAllLocalVariablesReq(moduleId, scriptId);
      } else if (enteredVariableType === 'GLOBAL') {
        res = await getAllGlobalVariableListReq();
      } else if (enteredVariableType === 'PROJECT_ENVIRONMENT') {
        res = await getAllProjectVariableListReq();
      }
      if (res?.data?.responseObject) {
        const variablesList = [...res.data.responseObject];
        return variablesList.some(
          (variable, index) => variable.name.toLowerCase().trim() === enteredVariableName.toLowerCase().trim()
        );
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
    }
  }
  const onSubmit = async (values) => {
    document.getElementById('createVariableID').disabled = true;
    let data;
    if (values) {
      if (values.variableValue.length && validateVariableValue(values.variableValue)) {
        document.getElementById('createVariableID').disabled = false;
        formikDetails.setFieldError('variableValue', 'White spaces are not allowed');
      } else {
        data = {
          name: values.variableName,
          type: values.variableType,
          value: values.variableValue,
          masked: values.variableValueMasked,
        };
        let actualPathIndex = fileArray.findIndex((name) => pathName(name.actualPath) === values.variableValue);
        if (actualPathIndex >= 0) {
          data['value'] = fileArray[actualPathIndex].actualPath;
        }

        if (!createCalled) {
          setCreateCalled(true);
          if (props?.data) {
            updateVariable(editData.id, data);
          } else {
            createVariable(data);
          }
        }
        const isNameExists =
          props?.data && props?.data?.name.trim().toLowerCase() === values.variableName.trim().toLowerCase()
            ? false
            : await checkForDuplicateName(values.variableName, values.variableType);
        if (isNameExists) {
          document.getElementById('createVariableID').disabled = false;
          formikDetails.setFieldError('variableName', 'Name already exists');
        }
      }
    }
  };
  const formikDetails = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  const [variableValue, setVariableValue] = useState({
    variableVal: formikDetails.values.variableValue,
    isVariableChanged: false,
  });
  useEffect(() => {
    if (!props.isCreateForm) {
      createVariableBtnRef?.current?.focus();
    }
  }, [formikDetails.values.variableType]);
  async function createVariable(data) {
    let response;
    try {
      if (data.type === 'Step') {
        const search = window.location.search;
        const id = new URLSearchParams(search).get('id');
        const parentId = new URLSearchParams(search).get('parentId');
        let requestbody = {
          name: data.name,
          value: data.value,
          type: 'LOCAL',
          returningStep: '',
          projectId: project.id,
          parentVariableType: 'STEPGROUP',
          parentVariableId: parentId,
          subParentVariableId: id,
          masked: data.masked,
        };
        response = await addStepGroupVariable(requestbody);
      } else if (data.type === 'LOCAL') {
        let requestBody = {
          ...data,
          parentVariableType: 'MODULE',
          parentVariableId: props.id.moduleId,
          subParentVariableId: props.id.scriptId,
          // "projectId": project.id
        };
        response = await createLocalVariableReq(requestBody);
      } else if (data.type === 'GLOBAL') {
        response = await createGlobalVariable(data);
      } else {
        response = await createProjectEnvironmentVariable(data);
      }

      if (response && response.data.status === 'SUCCESS') {
        props.MyAlert.success(response.data.message);
        /************for steps*************/
        if (props.variableClick) {
          props.variableClick(response.data.responseObject, data.type);
        }

        if (props?.onSetAllVariableName) {
          props?.onSetAllVariableName(response.data.responseObject, data.type);
        }

        if (props?.getRecentVariable) {
          props?.getRecentVariable(response.data.responseObject);
        }
        /************for steps*************/
        if (props?.reload) {
          props.reload(true);
        }
        props.closeModal(false);
        if (props?.variableDetails?.group === JSON_PATH_TITLE) {
          props?.cdfrResponseVariableUpdate(CAPTURE_GET_JSON_PATH);
        } else if (props?.variableDetails?.group === HEADER_KEY_TITLE) {
          props?.cdfrResponseVariableUpdate(CAPTURE_GET_HEADER_KEY);
        } else {
          props?.cdfrResponseVariableUpdate(props?.variableDetails?.value?.title);
        }
      } else if (response.data.status === 'FAILURE') {
        formikDetails.setFieldError('variableName', 'Name already exists');
        document.getElementById('createVariableID').disabled = false;
        setCreateCalled(false);
      } else {
        props.MyAlert.warning(`${response.data.message}`);
        props.closeModal(false);
      }
    } catch (err) {
      console.error(err);
    }
  }
  async function updateVariable(id, data) {
    let response;
    try {
      if (data.type === 'LOCAL') {
        let requestBody = {
          ...data,
          parentVariableType: editData.parentVariableType,
          parentVariableId: editData.parentVariableId,
          subParentVariableId: editData.subParentVariableId,
          // "projectId": editData.projectId
        };
        response = await updateLocalVariableReq(id, requestBody);
      } else if (data.type === 'GLOBAL') {
        response = await updateGloabalVariable(id, data);
      } else if (data.type === 'Step') {
        props.data.name = data.name;
        props.data.value = data.value;
        props.data.masked = data.masked;
        response = await updateStepGroupVariable(id, props.data);
      } else {
        response = await updateProjectEnvironmentVairable(id, data);
      }
      if (response && response.data && response.data.status === 'SUCCESS') {
        props.MyAlert.success(response.data.message);
        /************for steps*************/
        if (props.variableClick) {
          props.data.name = data.name;
          props.variableClick(response.data.responseObject, data.type);
        }

        if (props?.onSetAllVariableName) {
          props?.onSetAllVariableName(response.data.responseObject, data.type, 'update');
        }

        if (props?.getRecentVariable) {
          props?.getRecentVariable(response.data.responseObject);
        }
        /************for steps*************/
        if (props.reload) {
          props.reload(true);
        }
        props.closeModal(false);
        if (props?.variableDetails?.group === JSON_PATH_TITLE) {
          props?.cdfrResponseVariableUpdate(CAPTURE_GET_JSON_PATH);
        } else if (props?.variableDetails?.group === HEADER_KEY_TITLE) {
          props?.cdfrResponseVariableUpdate(CAPTURE_GET_HEADER_KEY);
        } else {
          props?.cdfrResponseVariableUpdate(props?.variableDetails?.value?.title);
        }
      } else if (response.data.status === 'FAILURE') {
        formikDetails.setFieldError('variableName', 'Name already exists');
        document.getElementById('createVariableID').disabled = false;
        setCreateCalled(false);
      } else {
        props.MyAlert.warning(`${response.data.status}`);
        props.closeModal(false);
      }
    } catch (err) {
      console.error(err);
    }
  }

  const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
  };

  const [showFileSuggestion, setShowFileSuggestion] = useState(false);
  let [fileArray, setFileArray] = useState([]);
  const [fileSearch, setFileSearch] = useState([]);
  const showVariables = () => {
    setShowFileSuggestion(true);
  };
  const hideVariables = () => {
    setShowFileSuggestion(false);
  };

  useEffect(() => {
    getFolderPath();
  }, []);

  const getFolderPath = async () => {
    try {
      const response = await getAllTestDataFiles();
      const {
        data: { responseObject, responseCode },
      } = response || {};

      if (responseObject?.length > 0 && responseCode === 200) {
        const folderPaths = responseObject.map((folder) => {
          return {
            ...folder,
            path: pathName(folder.actualPath),
            actualPath: folder.actualPath,
          };
        });
        setFileArray([...folderPaths]);
        setFileSearch([...folderPaths]);
      } else {
        setFileArray([]);
      }
    } catch (err) {
      console.error('GET_FOLDER_PATH :', err);
    }
  };

  const handleOptionSelect = (option) => {
    formikDetails.setFieldValue('variableValue', option.actualPath);
    hideVariables();
  };

  const handleInputChange = (e) => {
    const { inputValue, fileArrayOption, filteredArray } = searchFilePathBasedOnHash(e, fileArray, fileSearch);
    const { variableVal } = variableValue;
    if (variableVal !== e.target.value) {
      setVariableValue({ ...variableValue, isVariableChanged: true });
    } else {
      setVariableValue({ ...variableValue, isVariableChanged: false });
    }
    if (fileArrayOption) {
      formikDetails.setFieldValue('variableValue', fileArrayOption.actualPath);
      hideVariables();
    } else if (!fileArrayOption) {
      if (inputValue.startsWith('#') && inputValue.length > 1) {
        showVariables();
        setFileArray(filteredArray);
      } else if (inputValue.startsWith('#')) {
        showVariables();
        getFolderPath();
      } else {
        hideVariables();
      }
      formikDetails.handleChange(e);
    }
  };

  const variableCreationForm = () => {
    return (
      <form onSubmit={formikDetails.handleSubmit} className={props?.isCreateForm ? 'variable-form' : ''}>
        <div className="pt-4">
          <div className="fontPoppinsMediumSm select-none mb-2 pl-4 pr-4">
            <span className="mr-0.5" style={{ color: colors.rs_error }}>
              *
            </span>
            Variable Name
          </div>
          <div className="w-full mb-2 pl-4 pr-4">
            <MyInput
              error={formikDetails.errors.variableName && formikDetails.touched.variableName}
              type="text"
              name="variableName"
              id="variableName"
              autocomplete="off"
              placeholder="Enter variable name"
              onBlur={formikDetails.handleBlur}
              onChange={formikDetails.handleChange}
              value={formikDetails.values.variableName}
              ref={createVariableSelectRef}
              className="common-modal-input"
              autoFocus={false}
              variableInput={false}
              inputValue={'input'}
              handleSaveAsSteps={props?.handleSaveAsSteps}
            />
            {formikDetails.errors.variableName && formikDetails.touched.variableName ? (
              <div className="text-red-500 fontPoppinsRegularSm md:mt-1">{formikDetails.errors.variableName}</div>
            ) : null}
          </div>
          <div className="fontPoppinsMediumSm select-none mb-2 pl-4 pr-4">
            <span className=" mr-0.5" style={{ color: colors.rs_error }}>
              *
            </span>
            Variable Type
          </div>
          <div className="w-full mb-2 pl-4 pr-4">
            <MySelect
              variableInput={'restApi'}
              sxPadding={'1px 10px 1px 10px'}
              boxShadow={`0px 0px 1px grey`}
              verticalAnchor={'bottom'}
              verticalTransform={'top'}
              marginTop={'3px'}
              borderRadius={'5px'}
              marginRight={'3px'}
              classSelect={'request-dropdown'}
              name="variableType"
              value={formikDetails.values.variableType}
              onChange={formikDetails.handleChange}
              minWidth={'417px'}
              disabled={
                (props?.variableType && !props.variableClick) || props?.data || props?.returnVariable ? true : false
              }
              onBlur={formikDetails.handleBlur}
              onSelect={formikDetails.handleChange}
              renderValue={
                formikDetails.values.variableType !== ''
                  ? undefined
                  : () => <Placeholder className="fontPoppinsRegularSm">Select script type</Placeholder>
              }
              ref={createVariableSelectRef}
              plusIconCheck={true}
              MenuProps={{
                PaperProps: {
                  className: 'create-variable-dropdown',
                },
              }}
            >
              {variableTypeOptions.map((data, key) => {
                if ((props?.inputFieldName && data.name !== 'Project Environment Variable') || !props?.inputFieldName) {
                  return (
                    <MenuItem key={key} value={data.value} className="h-[30px]">
                      <span className="fontPoppinsRegularMd">{data.name}</span>
                    </MenuItem>
                  );
                }
              })}
            </MySelect>
            {formikDetails.errors.variableType && formikDetails.touched.variableType ? (
              <div className="text-red-400 fontPoppinsRegularSm md:mt-1">{formikDetails.errors.variableType}</div>
            ) : null}
          </div>
          <div className="fontPoppinsMediumSm select-none mb-2 pl-4 pr-4">Variable Value</div>
          <div className="w-full  pl-4 pr-4 relative">
            <MyInput
              error={formikDetails.errors.variableValue && formikDetails.touched.variableValue}
              className="common-modal-input"
              autoComplete="off"
              type={isValueMasked ? 'password' : 'text'}
              name="variableValue"
              id="variableValue"
              placeholder={HASH_LABELS?.ENTER_VALUE_OR_SELECT_PATH}
              onBlur={formikDetails.handleBlur}
              onChange={handleInputChange}
              value={formikDetails.values.variableValue}
              ref={createVariableSelectRef}
              inputValue={'input'}
              variableInput={false}
              handleSaveAsSteps={props?.handleSaveAsSteps}
            />
            {showFileSuggestion && (
              <ClickAwayListener onClickAway={hideVariables}>
                <div className="absolute">{renderFilePath(fileArray, handleOptionSelect, typeOfModal)}</div>
              </ClickAwayListener>
            )}
            {formikDetails.errors.variableValue && formikDetails.touched.variableValue ? (
              <div className="text-red-400 fontPoppinsRegularXs md:mt-1">{formikDetails.errors.variableValue}</div>
            ) : null}
            <div className="flex -ml-2 variable-input-value items-center">
              <Checkbox
                name="variableValueMasked"
                id="variableValueMasked"
                size="small"
                className={`mr-0.5 masked-checkbox ${classes.checkbox}`}
                style={{ marginTop: '4px', padding: '0 0 0 5px' }}
                onChange={(e) => {
                  setIsValueMasked(e?.target?.checked);
                  formikDetails.handleChange(e);
                }}
                disabled={props?.variableName?.masked || props?.data?.masked}
                defaultChecked={isValueMasked}
                value={isValueMasked}
              />
              <InputLabel shrink htmlFor="variableValueMasked" className="fontPoppinsRegularMd mt-1.5 mb-1 ml-1">
                <span> Hide value</span>
              </InputLabel>
              <CustomTooltip title="Once the value is hidden, it cannot be undone" placement="bottom-start">
                <img
                  alt="info-icon"
                  height="15"
                  draggable="false"
                  className="text-blue-600 mt-1.5 mb-1 -ml-2 text-xs w-4 h-4"
                  src={onMouseHover ? InfoIconHover : InfoIcon}
                  onMouseEnter={() => setonMouseHover(true)}
                  onMouseLeave={() => setonMouseHover(false)}
                />
              </CustomTooltip>
            </div>
          </div>
        </div>

        <div className={!props?.isCreateForm ? 'modal-footer-section modal-footer-container' : ''}>
          <div className={props?.isCreateForm ? 'float-right mr-4' : 'float-right mr-4'}>
            <button onClick={() => props.closeModal(false)} type="button" className="modal-close-btn">
              {WEBSERVICE.CREATE_VARIABLE_CANCEL}
            </button>
            <button
              type="submit"
              id="createVariableID"
              className="modal-save-btn"
              ref={createVariableBtnRef}
              disabled={props?.data && !(formikDetails.dirty && formikDetails.isValid)}
            >
              {props?.data ? WEBSERVICE.CREATE_VARIABLE_UPDATE : WEBSERVICE.CREATE_VARIABLE_CREATE}
            </button>
          </div>
        </div>
      </form>
    );
  };

  useEscapeKeyCloseModal(true, () => {
    setOpenModal(false);
    props.closeModal(false);
  });

  return (
    <>
      {props?.isCreateForm ? (
        variableCreationForm()
      ) : (
        <div onMouseOver={(e) => e.stopPropagation()}>
          <Modal
            isOpen={openModal}
            onRequestClose={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            style={customStylesPage}
          >
            <div className="modal-header-api">
              <div className="pl-2">
                <h2 className="title white fontPoppinsMediumSm">
                  {' '}
                  {props?.data
                    ? `Edit Variable - ${editData?.name}`
                    : props?.dataValue && props?.variableDetails && props?.isExistVariable
                    ? `Edit Variable - ${props?.variableDetails?.value}`
                    : 'Create Variable'}
                </h2>
                <button
                  onClick={() => {
                    setOpenModal(false);
                    props.closeModal(false);
                  }}
                  type="button"
                  className="create-edit-close"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
            {variableCreationForm()}
          </Modal>
        </div>
      )}
    </>
  );
}

export default CreateVariable;

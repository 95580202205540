import React, { useEffect, useRef, Fragment, useState } from 'react';
import AceEditor from 'react-ace';
import VariableSelector from '@pagescommon/variable-selector-myinput';
import { ASSERT_CONSTANT } from '../../../assert/jsx/constant';

const JWTPayloadEditor = (props) => {
  const { JWT_EDITOR_INITIAL_VALUES, DEFAULT_EDITOR_X_AXIS, DROPDOWN_STYLE, DEFAULT_CHAR_SIZE, MAX_HORIZONTAL_LENGTH, JWT_PAYLOAD } =
    ASSERT_CONSTANT;

  const [jwtEditorValues, setJwtEditorValues] = useState({ ...JWT_EDITOR_INITIAL_VALUES, value: props.value });
  const [dropDownStyle, setDropDownStyle] = useState({ ...DROPDOWN_STYLE });

  const editorRef = useRef(null);
  let variableObj = {};
  let apiDataCode = '';

  useEffect(() => {
    if (
      jwtEditorValues.editorValue?.lines[0] === '$' ||
      (jwtEditorValues.value && jwtEditorValues.value[jwtEditorValues.value.length - 1] === '$')
    ) {
      setJwtEditorValues((prevJwtEditorValues) => ({ ...prevJwtEditorValues, inputValue: jwtEditorValues.value }));
    }
  }, [jwtEditorValues.value]);

  const onJWTEditorBlur = () => {
    setTimeout(() => {
      setJwtEditorValues((prevJwtEditorValues) => ({ ...prevJwtEditorValues, showDropDown: false }));
    }, 500);
  };

  const onJWTFocusValue = () => {
    if (jwtEditorValues.value?.charAt(jwtEditorValues.value.length - 1) === '$') {
      setJwtEditorValues((prevJwtEditorValues) => ({ ...prevJwtEditorValues, showDropDown: true }));
    }
  };

  const onJWTChange = (value, event) => {
    setJwtEditorValues((prevJwtEditorValues) => ({
      ...prevJwtEditorValues,
      value: value,
      editorValue: event,
    }));
    if (event.lines[0] === '$' && event.action === 'insert') {
      const editorValueLength = editorRef.current.editor.getValue()?.length;
      const leftValue = DEFAULT_EDITOR_X_AXIS + editorValueLength * DEFAULT_CHAR_SIZE;
      if (leftValue < MAX_HORIZONTAL_LENGTH) {
        setDropDownStyle((prevStyle) => ({
          ...prevStyle,
          left: `${leftValue}px`,
        }));
      }
      setJwtEditorValues((prevJwtEditorValues) => ({
        ...prevJwtEditorValues,
        showDropDown: true,
        variableDetails: event.start,
      }));
    }

    if (value !== '' && value !== ' ') {
      let objKeys = Object.keys(variableObj).join('|');
      let newObjStr = new RegExp(`${objKeys}`, 'g');
      apiDataCode = addVariable(value, newObjStr, variableObj);
    } else {
      apiDataCode = '';
    }
    props.getCodeValue(apiDataCode, value);
    if (event?.lines[0].length > 0) {
      props.handleSaveAsSteps(false);
    }
  };

  const onVariableSelect = (variableName, variableData) => {
    let jwtEditorLines = [];
    let currentRow = jwtEditorValues.variableDetails.row;
    let currentColumn = jwtEditorValues.variableDetails.column;
    const newlineIndex = jwtEditorValues.inputValue.indexOf('\n');

    if (newlineIndex !== -1) {
      jwtEditorLines = jwtEditorValues.inputValue.split('\n');
    } else {
      jwtEditorLines = jwtEditorValues.inputValue.split('\r');
    }

    jwtEditorLines[currentRow] =
      jwtEditorLines[currentRow].slice(0, currentColumn) + jwtEditorLines[currentRow].slice(currentColumn + 1);

    let newValue = props.onSetValueByType(variableName, variableData.type, variableData);
    jwtEditorLines[currentRow] = addStr(jwtEditorLines[currentRow], currentColumn, newValue);
    let updatedJwtEditorValue = jwtEditorLines.join('\n');
    variableObj[variableName] = variableData.id;

    let variableKeysPattern = Object.keys(variableObj).join('|');
    let variableRegex = new RegExp(`${variableKeysPattern}`, 'g');

    setJwtEditorValues((prevJwtEditorValues) => ({
      ...prevJwtEditorValues,
      showDropDown: false,
      value: updatedJwtEditorValue,
    }));

    apiDataCode = addVariable(updatedJwtEditorValue, variableRegex, variableObj);
    props.getCodeValue(apiDataCode, updatedJwtEditorValue);
  };

  const addStr = (str, index, stringToAdd) => {
    if (str[str?.length - 1] === '$' && jwtEditorValues.editorValue.action === 'remove') {
      return str?.substring(0, index) + stringToAdd.slice(1) + str?.substring(index, str.length);
    }
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  };

  const addVariable = (str, objKeys, varObj) => {
    return str.replace(objKeys, function (matched) {
      if (matched !== '') {
        return varObj[matched];
      } else {
        return '';
      }
    });
  };

  const handleEditorValue = (value) => {
    setJwtEditorValues((prevJwtEditorValues) => ({ ...prevJwtEditorValues, showDropDown: value }));
  };

  const { editorHeight, editorWidth, readOnly, value, showDropDown, editorValue } = jwtEditorValues;

  return (
    <Fragment>
      <AceEditor
        className="ace-editor"
        onBlur={onJWTEditorBlur}
        onFocus={onJWTFocusValue}
        height={editorHeight}
        width={editorWidth}
        readOnly={readOnly}
        onChange={onJWTChange}
        ref={editorRef}
        setOptions={{
          useWorker: false,
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
          showLineNumbers: true,
          tabSize: 2,
        }}
        value={value}
        {...props}
      />
      {showDropDown && value ? (
        <div style={dropDownStyle}>
          <VariableSelector
            ShowDropDown={showDropDown}
            onClickGetValue={onVariableSelect}
            onBlurValue={onJWTEditorBlur}
            currentValue={editorValue}
            type="Editor"
            value={value}
            handleEditorValue={handleEditorValue}
            editorRef={editorRef}
            editorCheck={true}
            name={JWT_PAYLOAD}
            {...props}
          />
        </div>
      ) : null}
    </Fragment>
  );
};

export default JWTPayloadEditor;

import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTable, usePagination, useFilters, useGlobalFilter, useSortBy } from 'react-table';
import matchSorter from 'match-sorter';
import { MenuItem, Select, Tooltip } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import UserDetailsPage from '../../common/user-details-page';
import { useHistory } from 'react-router';
import ExecutionIdDetailsPage from './execution-id-details-page';
import Stack from '@mui/material/Stack';
import {
  deleteSheduledDataReq,
  getAllScheduleReq,
  getSingleClientSystemReq,
  updateScheduleExecutionReq,
} from '@api/api_services';
import MachineDetails from '../../../test-development/execution-dashboard/modals/machine-details';
import { useAlert } from '../../../common/alert_service/useAlert';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import {
  compareDates,
  DATE_FORMATS,
  getTruncatedText,
  HighlightText,
  isEmptyValue,
  isValueIncludes,
  TimeZoneDates,
  convertGMTTimeToUserGivenTime,
  convertGivenTimeToGMT,
} from '@src/util/common_utils';
import { CommonLoader } from '@src/pages/common/common-loader';
import SearchBar from '@src/pages/analytics/common/search-bar';
import NoResultsFound from '@src/pages/common/Table/NoResultFound';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Label } from '@src/common/atoms/LabelComponent';
import { TIMEZONE_CONSTANTS } from '@src/common/ui-constants';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const SuiteScheduledInstance = ({ MyAlert, ...props }) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  var today = new Date();
  let tab = window.location.pathname.split('/')[5];
  let history = useHistory();
  const classes = useStyles();
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [dashboardData, setDashboardData] = React.useState([]);
  const [expiryDate, setExpiryDate] = React.useState();
  const [sheduledDate, setSheduledDate] = React.useState();
  const [expiryTime, setExpiryTime] = React.useState();
  const [selectedDataObj, setSelectedDataObj] = React.useState({});
  const [scheduleTime, setScheduleTime] = React.useState();
  const [repeatData, setRepeat] = React.useState();
  const [selectedSystem, setSelectedSystem] = React.useState();
  const { AlertContatiner } = useAlert();
  const [pageIndexVal] = React.useState(0);
  const [validationMinTime, setValidationMinTime] = React.useState();
  const [minHour, setMinHour] = React.useState();
  const [minMinute, setMinMinute] = React.useState();
  const [scheduleBtnDisable, setScheduleBtnDisable] = React.useState(true);
  const [expiryDateDisable, setExpiryDateDisable] = React.useState(true);
  const [scheduleDateDisable, setScheduleDateDisable] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [expireBtnDisable, setExpireBtnDisable] = React.useState(true);
  let [userSearched, setuserSearched] = useState('');
  let [isHighlight, setIsHighlight] = useState(false);
  let [openSearch, setOpenSearch] = useState(false);
  let [data, setData] = useState([]);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');
  let search = window.location.search;
  let urlSuiteId = new URLSearchParams(search).get('suiteId');
  let urlSuiteType = new URLSearchParams(search).get('suiteType');
  const [timezonedataValue, setTimeZoneDataValue] = useState(
    props?.timezonedatalist?.length ? props.timezonedatalist[0] : null
  );
  const [timeZoneName, setTimeZoneName] = useState(
    selectedDataObj &&
      selectedDataObj?.schedule &&
      selectedDataObj?.schedule?.timezone !== undefined &&
      selectedDataObj?.schedule?.timezone
  );
  const minDate = new Date().toLocaleString(TIMEZONE_CONSTANTS.US_LOCALE_TIME_ZONE, {
    timeZone: timeZoneName,
  });

  const handleTimeZoneChange = (event) => {
    const selectedOption = props.timezonedatalist.find((option) => option.value === event.target.value);
    setTimeZoneDataValue(selectedOption);
    let timeZoneKeyName = selectedOption?.value;
    setTimeZoneName(timeZoneKeyName);
    let sheduleTime = TimeZoneDates(TIMEZONE_CONSTANTS.US_LOCALE_TIME_ZONE, timeZoneKeyName);
    setSheduledDate(sheduleTime.$d);
    setExpiryDate(sheduleTime.$d);
    const currentTimeInSelectedTimezone = dayjs().tz(timeZoneKeyName);
    setScheduleTime(currentTimeInSelectedTimezone.$d);
    setExpiryTime(currentTimeInSelectedTimezone.$d);

    checkExpiryValidation();
    checkScheduleValidation();
    checkExpiryDateValidation();
    checkScheduleDateValidation();
  };

  const handleChange = (event) => {
    setRepeat(event.target.value);
    checkExpiryValidation();
    checkScheduleValidation();
    checkExpiryDateValidation();
    checkScheduleDateValidation();
  };

  const changeExpiryTime = (newValue) => {
    setExpiryTime(newValue);
    checkExpiryValidation();
    checkScheduleValidation();
    checkExpiryDateValidation();
    checkScheduleDateValidation();
  };

  const changeScheduleTime = (newValue) => {
    setScheduleTime(newValue);
    checkExpiryValidation();
    checkScheduleValidation();
    checkExpiryDateValidation();
    checkScheduleDateValidation();
  };

  const changeExpiryDate = (date) => {
    setExpiryDate(date);
    checkExpiryValidation();
    checkScheduleValidation();
    checkExpiryDateValidation();
    checkScheduleDateValidation();
  };

  const changeScheduleDate = (date) => {
    setSheduledDate(date);
    checkExpiryValidation();
    checkScheduleValidation();
    checkExpiryDateValidation();
    checkScheduleDateValidation();
    setTimeout(() => {
      let htmlEleForError = document.getElementById('date-picker-inline-expiredate-helper-text');
      if (htmlEleForError) {
        htmlEleForError.innerHTML = 'Expiry Date should not be less than Schedule Date';
      }
    });
  };

  function getTimeForValidation() {
    let d = new Date();
    let time = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
    setValidationMinTime(time);
    setMinHour(d.getHours());
    setMinMinute(d.getMinutes());
    setExpireBtnDisable(true);
    setScheduleBtnDisable(true);
    setExpiryDateDisable(true);
    setScheduleDateDisable(true);
  }

  const { isEditable, hasFullAccess } = React.useMemo(
    () => ({
      isEditable: window.permission?.isEditAccess('execution'),
      hasFullAccess: window.permission?.isFullAccess('execution'),
    }),
    []
  );

  function reScheduleExecutionFun(data) {
    let selctedSchedObj = { ...data };

    selctedSchedObj.schedule.repeat = repeatData;
    selctedSchedObj.schedule.scheduleDate = convertGMTTimeToUserGivenTime(sheduledDate);
    selctedSchedObj.schedule.scheduleTime = convertGMTTimeToUserGivenTime(scheduleTime);
    selctedSchedObj.schedule.expireDate = convertGMTTimeToUserGivenTime(expiryDate);
    selctedSchedObj.schedule.expireTime = convertGMTTimeToUserGivenTime(expiryTime);
    selctedSchedObj.schedule.timezone = timeZoneName;



    const { responseCopy, ...payload } = selctedSchedObj;
    updateScheduleExecutionReq(selctedSchedObj.id, payload)
      .then((res) => {
        if (res.data && res.data.responseObject) {
          setShowModal(false);
          getAllDashboard();
          createAlertFun(
            'success',
            `${getTruncatedText(selctedSchedObj?.schedule?.suiteName, 22)} rescheduled successfully`
          );
        } else {
          // childalert.danger(res.data.message);
          if (res?.data?.message && res?.data?.message.toLowerCase().includes('please connect a device')) {
            createAlertFun('info', res?.data?.message);
          } else if (res?.data?.message && res?.data?.message.toLowerCase().includes('please install a browser')) {
            createAlertFun('info', `${res?.data?.message}`);
          } else if (
            res?.data?.message &&
            res?.data?.message.startsWith('Client') &&
            res?.data?.message.toLowerCase().includes('is not available')
          ) {
            createAlertFun('info', `${res?.data?.message}`);
          } else {
            createAlertFun('danger', res.data.message);
          }
        }
      })
      .catch((error) => {
        console.error('error');
      });
  }

  const formik = useFormik({
    initialValues: {
      Name: '',
    },

    validationSchema: Yup.object({
      Name: Yup.string().min(2, 'Minimum 2 characters').max(15, 'Maximum 50 characters').required('Required!'),
    }),
    onSubmit: (setVal) => {
      console.log(`values`, setVal);
    },
  });

  // Define a default UI for filtering
  function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    return (
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search...`}
      />
    );
  }

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  React.useEffect(() => {
    getAllDashboard();
  }, []);

  function getMachineDetails(macObj, exeObj) {
    let { hostName, osName } = macObj?.machineInfo;
    let { browserName, executionStatus, deviceInfo } = macObj;
    let { createdByUname } = exeObj;
    let reqData = {
      machineName: hostName,
      os: osName,
      browser: browserName,
      device: deviceInfo?.[0]?.name || '--',
      status: executionStatus || 'N/A',
      owner: createdByUname,
    };
    if (
      exeObj?.executionStatus !== 'Cancelled' &&
      exeObj?.executionStatus !== 'Running' &&
      exeObj?.executionStatus !== 'Pending' &&
      exeObj?.executionStatus !== 'Terminated'
    ) {
      if (exeObj?.resultStatus === 'PASS') {
        reqData.status = 'Passed';
      } else if (exeObj?.resultStatus === 'FAIL') {
        reqData.status = 'Failed';
      }
    } else {
      reqData.status = exeObj?.executionStatus;
    }

    getSingleClientSystemReq(macObj.clientId).then((res) => {
      if (res.data.responseObject) {
        reqData['executions'] = res.data.responseObject;
      } else {
        reqData['executions'] = [];
      }
      setSelectedSystem(reqData);
      openModalFun('systemDetails');
    });
  }

  function createAlertFun(type, msg) {
    if (type === 'success') {
      MyAlert.success(msg);
    } else if (type === 'danger') {
      props.MyAlert.danger(msg);
    }
  }

  function getAllDashboard() {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    setIsLoading(true);
    const suiteObj = JSON.parse(localStorage.getItem('selectedSuiteObj'));
    let search = window.location.search;
    let urlSuiteId = new URLSearchParams(search).get('suiteId');
    const suiteId = urlSuiteId ? urlSuiteId : suiteObj?.id;

    getAllScheduleReq(suiteId)
      .then((results) => {
        stopProgress();
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 10,
        });
        const response = results?.data?.responseObject;

        if (response) {
          response.forEach((res, i) => {
            res.machine = [];
            if (res && res?.suite) {
              let selectedMachine = res?.suite?.machines?.selectedMachines;
              if (selectedMachine && selectedMachine.length) {
                let machineArray = [];
                res.machine = [];
                let machineName;
                selectedMachine.map((selObj, index) => {
                  if (selObj?.machineInstances) {
                    selObj?.machineInstances?.map((machineDetails) => {
                      machineArray.push(
                        <span onClick={() => getMachineDetails(machineDetails, res)}>
                          {' '}
                          {index === selectedMachine.length - 1
                            ? machineDetails?.machineInfo?.hostName
                            : machineDetails?.machineInfo?.hostName + ','}{' '}
                        </span>
                      );

                      if (machineName) {
                        machineName = machineName + ', ' + machineDetails.machineInfo.hostName;
                      } else {
                        machineName = machineDetails.machineInfo.hostName;
                      }
                    });
                  } else {
                    machineArray.push(
                      <span onClick={() => getMachineDetails(selObj, res)}>
                        {' '}
                        {index === selectedMachine.length - 1
                          ? selObj.machineInfo.hostName
                          : selObj.machineInfo.hostName + ','}{' '}
                      </span>
                    );
                    if (machineName) {
                      machineName = machineName + ',' + selObj.machineInfo.hostName;
                    } else {
                      machineName = selObj.machineInfo.hostName;
                    }
                  }
                });
                res.machine = machineArray;
                res.schedule.machineName = machineName;
                if (res.suite?.machines?.multiple === 'false') {
                  res.schedule.runIn = 'Single Machine';
                } else {
                  res.schedule.runIn = 'Multiple Machine';
                }
              }
            }

            res.scheduleTime = {};
            res.expireTime = {};
            if (res) {
              res.scheduleTime =
                res?.schedule?.updatedScheduleDate +
                ',' +
                res?.schedule?.updatedScheduleTime +
                ' (' +
                res?.schedule?.timeZoneOffSet +
                ')';
              res.expireTime =
                res?.schedule?.updatedExpireDate +
                ',' +
                res?.schedule?.updatedExpireTime +
                ' (' +
                res?.schedule?.timeZoneOffSet +
                ')';
            }

            res.responseCopy = res;
          });
          setDashboardData((d) => response);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        resetProgress();
        console.error('GET_SCHEDULED_REQ : ', error);
      });

    return [];
  }

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val) => !val;

  // Our table component
  function Table({ columns, data }) {
    const filterTypes = React.useMemo(
      () => ({
        // Add a new fuzzyTextFilterFn filter type.
        fuzzyText: fuzzyTextFilterFn,
        // Or, override the default text filter to use
        // "startWith"
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      []
    );

    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
      {
        columns,
        data,
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
        initialState: { pageIndex: pageIndexVal, pageSize: 9 },
      },
      useFilters, // useFilters!
      useGlobalFilter, // useGlobalFilter!
      useSortBy,
      usePagination
    );
    return (
      <>
        <table class="relative w-full " {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr class="mt-4" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    class="sticky top-0 px-3 py-3 table-header_font-style-exe-dashboard bg-gray-100 ml-3"
                    {...column.getHeaderProps()}
                  >
                    <span className="flex flex-row">
                      {column.render('Header')}
                      {/* Render the columns filter UI */}
                      <div>{column.canFilter ? column.render('Filter') : null}</div>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr className="project-row schedule-instance-row-height" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td class="px-3 py-3 text-center text-xs" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {openSearch && isEmptyValue(data) && <NoResultsFound />}
        <br />
        {isLoading ? (
          <CommonLoader />
        ) : (
          dashboardData?.length === 0 && (
            <div className="fontPoppinsRegularSm no-steps">
              <p className="font-bold"> No data found</p>
            </div>
          )
        )}
      </>
    );
  }

  // TODO :: handle Search filter :: START
  useEffect(() => {
    setData(dashboardData);
    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [dashboardData]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(dashboardData);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = dashboardData.filter(({ id, name, createdByUname, schedule }) => {
          return (
            isValueIncludes(e, id) ||
            isValueIncludes(e, name) ||
            isValueIncludes(e, createdByUname) ||
            isValueIncludes(e, schedule?.machineName)
          );
        });
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
        setData(newData);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setSearchTextHighlight('');
    setData(dashboardData);
    setIsHighlight(!isHighlight);
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  // TODO :: handle Search filter :: END

  const columns = React.useMemo(
    () => [
      {
        Header: 'EXECUTION ID',
        accessor: 'id',
        disableFilters: true,
        Cell: (e) => (
          <a
            href="/"
            onClick={(event) => {
              event.preventDefault();
              getData(e.cell.row.original, 'details');
            }}
            className="hyper_link_color-common float-left table-font-style-common "
          >
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </a>
        ),
      },
      {
        Header: 'NAME',
        accessor: 'name',
        disableFilters: true,
        Cell: (e) => (
          <div className="table-non-link-color-common float-left table-font-style-common ">
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      // {
      //   Header: 'MACHINE',
      //   accessor: 'machine',
      //   disableFilters: true,
      //   width: '11%',
      //   Cell: (e) => {
      //     let selectedMachine = e.cell.row.original?.responseCopy?.suite?.machines?.selectedMachines;

      //     let machineTitle = '';

      //     selectedMachine?.forEach((machine) => {
      //       machine?.machineInstances?.forEach((system) => {
      //         if (machineTitle) {
      //           machineTitle = machineTitle + ', ' + system['machineInfo']['hostName'];
      //         } else {
      //           machineTitle = system['machineInfo']['hostName'];
      //         }
      //       });
      //     });

      //     return (
      //       <div className="hyper_link_color-common float-left cursor-hand table-font-style-common w-160px truncate">
      //         {selectedMachine?.map((selObj) => {
      //           return (
      //             <>
      //               {selObj?.machineInstances?.map((systemInfo, index) => {
      //                 return (
      //                   <Tooltip title={machineTitle}>
      //                     <span onClick={() => getMachineDetails(systemInfo, e.cell.row.original?.responseCopy)}>
      //                       <HighlightText
      //                         text={
      //                           index === selectedMachine.length - 1
      //                             ? systemInfo?.machineInfo?.hostName
      //                             : systemInfo?.machineInfo?.hostName + ','
      //                         }
      //                         highlight={searchTextHighlight}
      //                       />
      //                     </span>
      //                   </Tooltip>
      //                 );
      //               })}
      //             </>
      //           );
      //         })}
      //       </div>
      //     );
      //   },
      // },

      // {
      //   Header: 'EXECUTION TYPE',
      //   accessor: 'suite.machines.executionType',
      //   disableFilters: true,
      //   Cell: ({ value }) => {
      //     return (
      //       <div className="table-non-link-color-common float-left table-font-style-common">
      //         {value === 'SEND_SUITE_TO_ALL' ? 'Parallel' : value === 'DISTRIBUTE' ? 'Distribute' : 'Sequential'}
      //       </div>
      //     );
      //   },
      // },
      {
        Header: 'SCHEDULED BY',
        accessor: 'createdByUname',
        disableFilters: true,
        Cell: (e) => (
          <a href="/" className=" float-left  table-font-style-common">
            {e.cell.row.original.createdByUname && e.cell.row.original.createdByUname !== '--' ? (
              <span
                className="hyper_link_color-common cursor-hand"
                onClick={(event) => {
                  event.preventDefault();
                  setShowModal(true);
                  setOperation('createdBy');
                  setSelectedDataObj(e.cell.row.original);
                }}
              >
                <HighlightText text={e.cell.row.original.createdByUname} highlight={searchTextHighlight} />
              </span>
            ) : (
              <span className="table-non-link-color-common">--</span>
            )}{' '}
          </a>
        ),
      },
      {
        Header: 'SCHEDULED ON',
        accessor: 'scheduleTime',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left table-font-style-common">{value}</div>;
        },
      },
      {
        Header: 'EXPIRES ON',
        accessor: 'expireTime',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left table-font-style-common">{value}</div>;
        },
      },
      {
        Header: 'RECURRENCE',
        accessor: 'schedule.repeat',
        disableFilters: true,

        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left table-font-style-common">{value}</div>;
        },
      },

      ...(isEditable
        ? [
            {
              Header: 'ACTIONS',
              accessor: 'Actions',
              disableFilters: true,
              Cell: (e) => {
                return (
                  <div className="items-center flex-row project-action delete-icon-style">
                    {isEditable && (
                      <Tooltip title="Reschedule">
                        <EventOutlinedIcon
                          className="icon-blue-style delete-icon-style cursor-pointer"
                          onClick={() => {
                            openModalFun('schedule', e?.cell?.row?.original?.responseCopy);
                          }}
                        ></EventOutlinedIcon>
                      </Tooltip>
                    )}
                    {hasFullAccess && (
                      <Tooltip title="Delete">
                        <DeleteOutlinedIcon
                          className="icon-blue-style float-right ml-2 delete-icon-style cursor-pointer"
                          onClick={() => {
                            setOpen(true);
                            setSelectedDataObj(e?.cell?.row?.original?.responseCopy);
                          }}
                        />
                      </Tooltip>
                    )}
                  </div>
                );
              },
            },
          ]
        : []),
    ],
    [isHighlight, openModalFun]
  );

  const [operation, setOperation] = React.useState();
  let scheduleRepeatArray = ['Once', 'Daily', 'Weekly', 'Monthly'];
  const [showModal, setShowModal] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  let handelDlt = (exeObj) => {
    deleteSheduledDataReq(exeObj.id).then((res) => {
      if (res) {
        getAllDashboard();
        setOpen(false);
      }
    });
    createAlertFun('success', `${exeObj?.id} deleted successfully.`);
  };

  function getData(data, type) {
    let pathUrl = window.location.pathname;
    history.push(`${pathUrl}/${data.id}?suiteId=${urlSuiteId}&&suiteType=${urlSuiteType}`);
    setSelectedDataObj(data);
  }

  function openModalFun(type, res) {
    if (type === 'schedule') {
      setExpiryDate(convertGivenTimeToGMT(res?.schedule.expireDate));
      setScheduleTime(convertGivenTimeToGMT(res?.schedule.scheduleTime));
      setSheduledDate(convertGivenTimeToGMT(res?.schedule.scheduleDate));
      setExpiryTime(convertGivenTimeToGMT(res?.schedule.expireTime));
      setRepeat(res?.schedule.repeat);
      const selectedOption = props.timezonedatalist.find((option) => {
        return option.value === res.schedule.timezone;
      });
      setTimeZoneDataValue(selectedOption);
      setTimeZoneName(res.schedule.timezone);
      setShowModal(true);
      setSelectedDataObj(res);
      setOperation(type);
      getTimeForValidation();
      setTimeout(() => {
        let htmlEleForError = document.getElementById('date-picker-inline-helper-text');
        let htmlEleForErrorExpiry = document.getElementById('date-picker-inline-expiredate-helper-text');
        if (htmlEleForError) {
          htmlEleForError.innerHTML = 'Schedule Date should not be less than current Date';
          setScheduleDateDisable(true);
        }
        if (htmlEleForErrorExpiry) {
          htmlEleForErrorExpiry.innerHTML = 'Expiry Date should not be less than Schedule Date';
          setExpiryDateDisable(true);
        }
      });
    } else if (type === 'systemDetails') {
      setOperation(type);
    }
  }

  function closeMachineDetailsModal() {
    setShowModal(false);
    setOperation();
  }

  function checkExpiryValidation() {
    setTimeout(() => {
      let expiry = document?.getElementById('expiryId');
      let expiryAreaInvalid = expiry?.getAttribute('aria-invalid');
      expiry && expiry?.value > '23:59' ? setScheduleBtnDisable(true) : setScheduleBtnDisable(false);

      if (expiryAreaInvalid === 'true') {
        setExpireBtnDisable(true);
      } else {
        setExpireBtnDisable(false);
      }
    });
  }

  function checkScheduleValidation() {
    setTimeout(() => {
      let schedule = document?.getElementById('scheduleId');
      let scheduleAreaInvalid = schedule?.getAttribute('aria-invalid');
      schedule && schedule?.value > '23:59' ? setScheduleBtnDisable(true) : setScheduleBtnDisable(false);

      if (scheduleAreaInvalid === 'true') {
        setScheduleBtnDisable(true);
      } else {
        setScheduleBtnDisable(false);
      }
    });
  }

  function checkExpiryDateValidation() {
    setTimeout(() => {
      let expiryDateInvalid = document?.getElementById('date-picker-inline-expiredate-helper-text');
      if (expiryDateInvalid) {
        setExpiryDateDisable(true);
      } else {
        setExpiryDateDisable(false);
      }
    });
  }

  function checkScheduleDateValidation() {
    setTimeout(() => {
      let scheduledDateInvalid = document?.getElementById('date-picker-inline-helper-text');
      if (scheduledDateInvalid) {
        setScheduleDateDisable(true);
      } else {
        setScheduleDateDisable(false);
      }
    });
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block marginupper pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/4">
                <div className="inline-block">
                  <div className="flex flex-start ml-2">
                    <span className="pt-5 pl-3">
                      <ExclamationIcon className="h-6 w-6 text-yellow-600 " />
                    </span>
                    <Dialog.Title as="h3" className="text-sm leading-6 ml-4 pt-3 font-small text-gray-900">
                      <span className="fontPoppinsSemiboldLg block">Warning </span>
                      <span className="fontPoppinsMediumMd">Delete </span>
                    </Dialog.Title>
                  </div>
                </div>

                <hr className="line mt-px" />
                <div className="fontPoppinsMediumMd mt-3 text-gray-500 ml-16 mr-8">
                  <p className=""> Your data will be lost. Are you sure you want to delete {selectedDataObj.id}? </p>
                  <p className="mt-2"> How do you want to proceed?</p>
                </div>
                <hr className="line mt-3" />
                <div className="mt-2 pb-2 flex flex-row float-right">
                  <button type="button" className="mt-3 gray-btn ml-1" onClick={() => handelDlt(selectedDataObj)}>
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 ml-4 primary-btn "
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Modal open={showModal} className="modal-dialog">
        {operation === 'schedule' ? (
          <div className="modal-container page-modal-size outline-none overflow-y-auto" style={{ width: '80%' }}>
            <form onSubmit={formik.handleSubmit}>
              <div className="testEnv-header-section ">
                <label className="Popup-header-common reschedule-header fontPoppinsSemiboldMd">
                  {TIMEZONE_CONSTANTS.RESCHEDULE_TEST}- {selectedDataObj?.schedule.suiteName}
                </label>
                <IconButton
                  component="span"
                  className="close"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  <CloseIcon className="closeIconStyle" />
                </IconButton>
              </div>
              <div className="schedule-popup-height" id="journal-scroll">
                <div className="grid grid-cols-2 gap-5 mt-2 ml-1 ">
                  <div className="re-schedule-style">
                    <Label required={true} label={TIMEZONE_CONSTANTS.NAME} />
                    <TextField
                      id="standard-basic"
                      disabled="true"
                      className={classes.root}
                      value={
                        selectedDataObj && selectedDataObj?.schedule && selectedDataObj?.name
                          ? selectedDataObj?.name
                          : null
                      }
                    />
                  </div>
                  <div>
                    <Label required={true} label={TIMEZONE_CONSTANTS.RECURRENCE} />
                    <div className={`${classes.formControl} ml-2`}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={repeatData}
                        onChange={handleChange}
                        style={{ width: '80%' }}
                      >
                        {scheduleRepeatArray.map((scheduleVal) => (
                          <MenuItem value={scheduleVal}>{scheduleVal}</MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
                <Label required={true} label={TIMEZONE_CONSTANTS.TIME_ZONE} />
                <div className="className={classes.formControl} ml-2">
                  <Select
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      PaperProps: {
                        style: {
                          maxHeight: 137,
                        },
                      },
                    }}
                    labelId="demo-simple-select-label-timezone-reschedul"
                    id="demo-simple-select-label-timezone-reschedule"
                    name="timezone-reschedule"
                    value={timezonedataValue}
                    onChange={handleTimeZoneChange}
                    className="w-64 max-h-10"
                    renderValue={(timeZoneData) => timeZoneData?.label}
                  >
                    {props?.timezonedatalist?.map((timeZoneOption) => (
                      <MenuItem value={timeZoneOption?.value}>{timeZoneOption?.label}</MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="grid grid-cols-2 gap-5 mt-2  ml-1 ">
                  <div className="schedule-style">
                    <Label required={true} label={TIMEZONE_CONSTANTS.SCHEDULE_DATE_TIME} />
                    <div className="flex flex-row mr-3">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justifyContent="space-around">
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            InputProps={{ readOnly: true }}
                            format={DATE_FORMATS.DATE_WITH_MONTH_NAME}
                            minDate={new Date(minDate)}
                            margin="normal"
                            id="date-picker-inline"
                            value={sheduledDate}
                            onChange={changeScheduleDate}
                            autoOk={true}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                      <Grid container justifyContent="space-around" item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Stack spacing={3}>
                            <TimePicker
                              ampm={false}
                              openTo="hours"
                              views={['hours', 'minutes']}
                              inputFormat="HH:mm"
                              mask="__:__"
                              minTime={compareDates(expiryDate, sheduledDate, scheduleTime)}
                              shouldDisableTime={(timeValue, clockType) => {
                                if (
                                  JSON.stringify(sheduledDate)?.substring(0, 11) ===
                                  JSON?.stringify(today)?.substring(0, 11)
                                ) {
                                  let scheduledHour = moment(new Date(scheduleTime)).format('HH');
                                  if (JSON.stringify(minHour) === scheduledHour) {
                                    if (clockType === 'minutes') {
                                      return timeValue <= minMinute;
                                    }
                                  }
                                }
                              }}
                              value={scheduleTime}
                              onChange={(newValue) => {
                                changeScheduleTime(newValue);
                                checkScheduleValidation();
                              }}
                              renderInput={(params) => <TextField id="scheduleId" {...params} />}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </Grid>
                    </div>
                    {!scheduleTime ? <div className="re-schedule-no-data-style">Select Time</div> : null}
                  </div>
                  <div className="schedule-style">
                    <Label required={true} label={TIMEZONE_CONSTANTS.EXPIRE_DATE_TIME} />
                    <div className="flex flex-row mr-3 ">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justifyContent="space-around">
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            InputProps={{ readOnly: true }}
                            format={DATE_FORMATS.DATE_WITH_MONTH_NAME}
                            margin="normal"
                            minDate={new Date(sheduledDate)}
                            id="date-picker-inline-expiredate"
                            value={expiryDate}
                            onChange={changeExpiryDate}
                            autoOk={true}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                      <Grid container justifyContent="space-around" item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Stack spacing={3}>
                            <TimePicker
                              ampm={false}
                              openTo="hours"
                              views={['hours', 'minutes']}
                              inputFormat="HH:mm"
                              mask="__:__"
                              minTime={compareDates(expiryDate, sheduledDate, scheduleTime)}
                              shouldDisableTime={(timeValue, clockType) => {
                                if (
                                  JSON.stringify(sheduledDate)?.substring(0, 11) ===
                                  JSON.stringify(expiryDate)?.substring(0, 11)
                                ) {
                                  let scheduledHour = moment(new Date(scheduleTime)).format('HH');
                                  let expiredHour = moment(new Date(expiryTime)).format('HH');
                                  let minExpMinute = moment(new Date(scheduleTime)).format('mm');

                                  if (expiredHour === scheduledHour) {
                                    if (clockType === 'minutes') {
                                      let stringVal = '40';
                                      return timeValue <= minExpMinute;
                                    }
                                  }
                                }
                              }}
                              value={expiryTime}
                              onChange={(newValue) => {
                                changeExpiryTime(newValue);
                                checkExpiryValidation();
                              }}
                              renderInput={(params) => <TextField id="expiryId" {...params} />}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </Grid>
                    </div>
                    {!expiryTime ? <div className="re-expiry-no-data-style">Select Time</div> : null}
                  </div>
                </div>
                <hr />
                <div className="float-right mr-4 pb-3 mt-2">
                  {/* <div>
                                        <InfoOutlinedIcon className="hyper_link_color-common mr-2 ml-1" /><label><span className=" schedule-font-style">Estimated Duration is</span><span className="schedule-time-font-style"> 02:30:00</span></label>

                                    </div> */}
                  <div className="flex justify-center items-center px-3 ">
                    <div className="mx-2 flex gap-2">
                      <button
                        className="gray-btn"
                        onClick={() => {
                          setShowModal(false);
                        }}
                      >
                        Cancel
                      </button>

                      <button
                        type="submit"
                        className="primary-btn"
                        disabled={
                          scheduleBtnDisable ||
                          expireBtnDisable ||
                          expiryDateDisable ||
                          !expiryTime ||
                          !scheduleTime ||
                          scheduleDateDisable
                        }
                        onClick={() => reScheduleExecutionFun(selectedDataObj)}
                      >
                        Reschedule
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        ) : operation === 'createdBy' ? (
          <div className="modal-container page-modal-size">
            <div className="modal-header Popup-header-common">
              <label> User Details - {selectedDataObj.createdByUname}</label>

              <IconButton
                color="primary"
                component="span"
                className="close"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                {/* <FaSearch className="search-icon-maximize-style " /> */}
                <CloseIcon />
              </IconButton>
            </div>
            <div className="modal-body" id="journal-scroll">
              <UserDetailsPage modifiedBy={selectedDataObj.createdBy} />
            </div>
          </div>
        ) : null}
      </Modal>

      {operation === 'systemDetails' ? (
        <MachineDetails data={selectedSystem} closeModal={closeMachineDetailsModal} />
      ) : null}
      {!tab ? (
        <div className="content_area_style">
          <AlertContatiner />
          <div className="content_area_header_style pb-2">
            <div className="flex justify-between items-center">
              <label className="main-header-label float-left ">Scheduled Instances</label>
              <div className="pr-4">
                <div className="ml-auto pl-4">
                  <SearchBar
                    open={openSearch}
                    searchValue={userSearched}
                    handleSearch={handleSearch}
                    onSearchClose={handleSearchClose}
                    handleSearchOpen={handleSearchOpen}
                    enterKeyHandler={handleSearchSubmit}
                    disable={isEmptyValue(dashboardData)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="table-height-executionDashboard overflow-auto dashboard-table-height" id="journal-scroll">
              <Table columns={columns} data={data} />
            </div>
          </div>
        </div>
      ) : (
        <ExecutionIdDetailsPage selectedSuite={selectedDataObj} />
      )}
    </>
  );
};

export default SuiteScheduledInstance;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Box from '@mui/material/Box';
import HomeIcon from '@material-ui/icons/Home';
import NativeSelect from '@mui/material/NativeSelect';
import './dateConfig.css';
import { getDateTimeConfigReq, putDateTimeConfigReq } from '../../../api/api_services';
import { useAlert } from '../../common/alert_service/useAlert';
const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
    color: 'rgb(22,72,198)',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Date_Time_config = (props) => {
  const classes = useStyles();
  const [dateFormat, setDateFormat] = useState('');
  const [dateSeparator, setDateSeparator] = useState('');
  const [timeFormat, setTimeFormat] = useState('');
  const [timeZone, setTimeZone] = useState('');
  const [id, setId] = useState('');
  const [disablebtn, setDisableBtn] = useState(true);
  const [disableCancelbtn, setDisableCancelBtn] = useState(true);
  // const [allData,setAllData]=useState("")
  const [date, setDate] = useState(new Date());
  // const [hyphenvalue, setHyphenValue] =useState('-');
  // const [time,setTime]=useState('hh-mm-ss-ms')

  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };
  // const handleHyphenChange = (event) => {
  //   setHyphenValue(event.target.value);
  // };
  // const handleTimeChange = (event) => {
  //   setTime(event.target.value);
  // };

  useEffect(() => {
    getDateTimeConfigReq()
      .then((results) => {
        // setAllData(results.data.responseObject[0])

        setDateFormat(results.data.responseObject.dateFormat);
        setDateSeparator(results.data.responseObject.dateSeparator);
        setTimeFormat(results.data.responseObject.timeFormat);
        setTimeZone(results.data.responseObject.timeZone);
        setId(results.data.responseObject.id);
        slashDate();
        hyphenDate();
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  let dmyForwordFormat = new Date().getDate() + '/' + (new Date().getMonth() + 1) + '/' + new Date().getFullYear();
  let dmyHyphen = new Date().getDate() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getFullYear();

  let mdyForwordFormat = new Date().getMonth() + 1 + '/' + new Date().getDate() + '/' + new Date().getFullYear();
  let mdyHyphen = new Date().getMonth() + 1 + '-' + new Date().getDate() + '-' + new Date().getFullYear();

  const slashDate = () => {
    if (dateFormat == 'dd-mm-yyyy') {
      document.querySelector('#datePreview').innerText = dmyForwordFormat;
    }
    if (dateFormat == 'mm-dd-yyyy') {
      document.querySelector('#datePreview').innerText = mdyForwordFormat;
    }
  };

  const hyphenDate = () => {
    // document.querySelector("#datePreview").innerText=dmyHyphen
    if (dateFormat == 'dd-mm-yyyy') {
      document.querySelector('#datePreview').innerText = dmyHyphen;
    }
    if (dateFormat == 'mm-dd-yyyy') {
      document.querySelector('#datePreview').innerText = mdyHyphen;
    }
  };
  const dmyDate = () => {
    if (dateSeparator == '-') {
      document.querySelector('#datePreview').innerText = dmyHyphen;
    }
    if (dateSeparator == '/') {
      document.querySelector('#datePreview').innerText = dmyForwordFormat;
    }
  };
  const mdyFormat = () => {
    if (dateSeparator == '-') {
      document.querySelector('#datePreview').innerText = mdyHyphen;
    }
    if (dateSeparator == '/') {
      document.querySelector('#datePreview').innerText = mdyForwordFormat;
    }
  };

  const sethmsm = () => {
    let currentdate = new Date();
    let hourMsm =
      currentdate.getHours() +
      ':' +
      currentdate.getMinutes() +
      ':' +
      currentdate.getSeconds() +
      ': ' +
      currentdate.getMilliseconds();

    document.querySelector('#timePreview').innerText = hourMsm;
  };
  const sethms = () => {
    let currentdate = new Date();
    let hour = currentdate.getHours() + ':' + currentdate.getMinutes() + ':' + currentdate.getSeconds();

    document.querySelector('#timePreview').innerText = hour;
    // alert(timeFormat)
  };
  const cancelValue = () => {
    setDisableCancelBtn(true);
    setDisableBtn(true);
    getDateTimeConfigReq()
      .then((results) => {
        setDateFormat(results.data.responseObject.dateFormat);
        setDateSeparator(results.data.responseObject.dateSeparator);
        setTimeFormat(results.data.responseObject.timeFormat);
        setTimeZone(results.data.responseObject.timeZone);
        setId(results.data.responseObject.id);
        slashDate();
        hyphenDate();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updateVal = (e) => {
    setDisableCancelBtn(true);
    setDisableBtn(true);
    let data = {
      dateFormat: dateFormat,
      dateSeparator: dateSeparator,
      timeFormat: timeFormat,
      id: id,
      timeZone: timeZone,
    };

    putDateTimeConfigReq(id, data)
      .then((results) => {
        if (results.data.responseCode === 200) {
          MyAlert.success(`Changes are saved successfully`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let currentdate = new Date();
  const { AlertContatiner, MyAlert } = useAlert();

  const isEditable = React.useMemo(() => window.permission?.isEditAccess('configuration'), []);

  const handleRadioClick = React.useCallback((clickHandler) => (isEditable ? clickHandler : () => {}), [isEditable]);

  return (
    <div className="page-container">
      <div className={classes.root} style={{ marginTop: '-2px', display: 'flex' }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" href="/">
            <HomeIcon className={classes.icon} />
          </Link>
          <Link className="Headerbreadcrumb">Configuration</Link>
          <Link className="Headerbreadcrumb">Date & Time Configuration</Link>
          <Link className="activeBreadcrumb">Date & Time Configuration</Link>
        </Breadcrumbs>
      </div>
      <div className="alert-position-style">
        <AlertContatiner></AlertContatiner>
      </div>
      <div class="flex mt-1">
        <div class="relative inline-block text-left">
          <div>
            <span class=" text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center">
              <span class="mr-1  heading"> Date & Time Configuration</span>
              <Link to="/configuration/date&TimeConfiguration/history" className="text-sm history">
                History
              </Link>
            </span>
          </div>
        </div>
      </div>
      <div className="page-list-body mt-1">
        <div className="page-table-body">
          <div className="content_area_style" id="journal-scroll">
            <div className="grid grid-cols-2 mb-4 ">
              <span className="hearderData grid grid-cols-1 ml-3 mt-4 configPageHeading">
                Date & Time Configuration
              </span>
              {isEditable && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginRight: '15px',
                  }}
                >
                  <button
                    onClick={cancelValue}
                    disabled={disableCancelbtn}
                    className={
                      disableCancelbtn
                        ? 'fontPoppinsMediumMd mt-4 w-16 ml-10  h-8 mr-6  items-center float-right border-2 border-gray-200 opacity-1 rounded'
                        : ' mt-4 w-16 ml-10  h-8 mr-6  bg-white  text-sm  text-gray-800  border border-gray-400 opacity-100 rounded'
                    }
                  >
                    <span
                      className={disableCancelbtn ? 'text-sm text-gray-400 ' : 'fl_button_Style text-sm text-gray-700 '}
                    >
                      Cancel
                    </span>
                  </button>

                  <button
                    disabled={disablebtn}
                    onClick={updateVal}
                    className={
                      disablebtn
                        ? 'primary-btn opacity-1 mt-4 w-16 h-8  rounded items-center float-right'
                        : 'primary-btn rounded  mt-4 w-16 h-8  items-center float-right opacity-100'
                    }
                  >
                    <span>Save</span>
                  </button>
                </div>
              )}
            </div>
            <hr></hr>

            <div className="screenShotConfig grid grid-cols-3 ">
              <div className="screenSection">
                <span className="screenShot  mx-4">
                  <h4 className="ml-3 text-sm dtConfig">Date Configuration</h4>
                </span>

                <span>
                  <h6
                    className="fontPoppinsRegularMd"
                    style={{
                      color: '#727171',
                      marginLeft: '45px',
                      marginTop: '-12px',
                      height: '17px',
                    }}
                  >
                    Date Format
                  </h6>
                </span>

                <div className="radioGroup mt-2" style={{ marginLeft: '45px' }}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="steps"
                      name="row-radio-buttons-group"
                      style={{ fontSize: '10px' }}
                      // value={value}
                      value={dateFormat}
                      // onChange={handleChange}
                    >
                      <FormControlLabel
                        disabled={!isEditable}
                        onClick={handleRadioClick(dmyDate)}
                        value="dd-mm-yyyy"
                        control={<Radio />}
                        id="dmy"
                        onChange={(e) => {
                          setDateFormat(e.target.value);
                          setDisableCancelBtn(false);
                          setDisableBtn(false);
                        }}
                        label={<span style={{ fontSize: '13px' }}>dd-mm-yyyy</span>}
                      />
                      <FormControlLabel
                        disabled={!isEditable}
                        value="mm-dd-yyyy"
                        control={<Radio />}
                        id="mdy"
                        onClick={handleRadioClick(mdyFormat)}
                        onChange={(e) => {
                          setDateFormat(e.target.value);
                          setDisableCancelBtn(false);
                          setDisableBtn(false);
                        }}
                        label={<span style={{ fontSize: '13px' }}>mm-dd-yyyy</span>}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>

              {/* <div
                className="selectSection"
                style={{ marginTop: "3.4rem", paddingLeft: "4rem" }}
              >
                <h6
                  className="ml-5  "
                  style={{
                    color: "grey",
                    marginLeft: "45px",
                    fontSize: "14px",
                    marginTop: "-12px"
                  }}
                >
                 Date Separator
                </h6>

                <FormControl component="fieldset" >
                    <RadioGroup
                      row
                      aria-label="steps"
                      name="row-radio-buttons-group"
                      className="mt-2"
                      style={{ fontSize: "10px",marginLeft:'40px' }}
                      // value={hyphenvalue}
                      // onChange={handleHyphenChange}
                      value={dateSeparator}
                    >
                      <FormControlLabel
                       onClick={hyphenDate}
                        
                        value="-"
                        onChange={(e)=>{setDateSeparator(e.target.value);setDisableCancelBtn(false);setDisableBtn(false)}}
                        control={<Radio />}
                        id="hyphen"
                        label={
                          <span style={{ fontSize: "13px" }}>
                          -   &nbsp;&nbsp;(Hyphen)
                          </span>
                        }
                      />
                      <FormControlLabel
                        value="/"
                        id="slash"
                        onClick={slashDate}
                        onChange={(e)=>{setDateSeparator(e.target.value); setDisableCancelBtn(false);setDisableBtn(false)}}
                        control={<Radio />}
                        label={
                          <span style={{ fontSize: "13px" }}>
                           / (Forward Slash)
                          </span>
                        }
                      />
                      
                    </RadioGroup>
                  </FormControl>

                
              </div> */}
            </div>
            <hr style={{ borderStyle: 'dashed', marginTop: '5px' }}></hr>

            {/*-------------------- 2nd Component -------------------------*/}
            <div className="screenShotConfig grid grid-cols-3 ">
              <div className="screenSection">
                <span className="screenShot mt-1 mx-4">
                  <h6 className="ml-3 text-sm dtConfig">Time Configuration</h6>
                </span>

                <span>
                  <h6
                    className="ml-5  fontPoppinsRegularMd "
                    style={{
                      color: 'grey',
                      marginLeft: '45px',
                      fontSize: '14px',
                      marginTop: '-12px',
                    }}
                  >
                    Time Format
                  </h6>
                </span>

                <div className="radioGroup" style={{ marginLeft: '45px' }}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="steps"
                      name="row-radio-buttons-group"
                      style={{ fontSize: '10px' }}
                      className="mt-2"
                      // value={time}
                      // onChange={handleTimeChange}
                      value={timeFormat}
                    >
                      <FormControlLabel
                        disabled={!isEditable}
                        onClick={handleRadioClick(sethms)}
                        value="hh:mm:ss"
                        control={<Radio />}
                        onChange={(e) => {
                          setTimeFormat(e.target.value);
                          setDisableCancelBtn(false);
                          setDisableBtn(false);
                        }}
                        label={<span style={{ fontSize: '13px' }}>hh:mm:ss</span>}
                      />
                      <FormControlLabel
                        disabled={!isEditable}
                        value="hh:mm:ss:ms"
                        onClick={handleRadioClick(sethmsm)}
                        control={<Radio />}
                        onChange={(e) => {
                          setTimeFormat(e.target.value);
                          setDisableCancelBtn(false);
                          setDisableBtn(false);
                        }}
                        label={<span style={{ fontSize: '13px' }}>hh:mm:ss:ms</span>}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>

              <div className="my-8" style={{ paddingLeft: '4rem' }}>
                <h6
                  className="ml-5 fontPoppinsRegularMd "
                  style={{
                    color: 'grey',
                    marginLeft: '45px',
                    fontSize: '14px',
                    marginTop: '16px',
                  }}
                >
                  Time Zone
                </h6>
                <Box className="MuiInputBase-root " sx={{ width: 330, marginLeft: 6, marginTop: 1 }}>
                  <FormControl fullWidth>
                    <NativeSelect
                      disabled={!isEditable}
                      className="NativeSelect"
                      defaultValue={timeZone}
                      value={timeZone}
                      onChange={(e) => {
                        setTimeZone(e.target.value);
                        setDisableCancelBtn(false);
                        setDisableBtn(false);
                      }}
                    >
                      {/* <option  >{timeZone}</option> */}

                      <option>(UTC+05:30) Chennai,Kolkata,Mumbai,New Delhi</option>
                      <option>(UTC-12:00) International Date Line West</option>
                      <option>(UTC-11:00) Coordinated Universal Time-11</option>
                      <option>(UTC-10:00) Hawaii</option>
                      <option>(UTC-09:00) Alaska</option>
                      <option>(UTC-08:00) Baja California</option>
                      <option>(UTC-03:00) Brasilia</option>
                      <option>(UTC+02:00) E. Europe</option>
                      <option>(UTC+03:00) Kuwait, Riyadh</option>
                      <option>(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk</option>
                      <option>(UTC+04:00) Abu Dhabi, Muscat</option>
                      <option>(UTC+04:30) Kabul</option>
                      <option>(UTC+07:00) Bangkok, Hanoi, Jakarta</option>
                    </NativeSelect>
                  </FormControl>
                </Box>
              </div>
            </div>
            <hr style={{ borderStyle: 'dashed', marginTop: '5px' }}></hr>
            {/*-------------------- 3rd Component -------------------------*/}
            <div className="screenShotConfig grid grid-cols-3 ">
              <div className="screenSection">
                <span className="screenShot mt-1 mx-4">
                  <h6 className="ml-5 text-sm font-semibold dtConfig ">Preview</h6>
                </span>

                <span>
                  <h6
                    className="ml-5  fontPoppinsRegularMd "
                    style={{
                      color: 'grey',
                      marginLeft: '45px',
                      fontSize: '14px',
                    }}
                  >
                    Date :{' '}
                    <span className="text-black" id="datePreview">
                      {dateFormat == 'dd-mm-yyyy'
                        ? dmyForwordFormat
                        : dateFormat == 'dd-mm-yyyy'
                          ? dmyHyphen
                          : dateFormat == 'mm-dd-yyyy'
                            ? mdyForwordFormat
                            : dateFormat == 'mm-dd-yyyy'
                              ? mdyHyphen
                              : ''}
                    </span>
                  </h6>
                </span>
                <span>
                  <h6
                    className="ml-5  fontPoppinsRegularMd "
                    style={{
                      color: 'grey',
                      marginLeft: '45px',
                      fontSize: '14px',
                    }}
                  >
                    Time :{' '}
                    <label id="timePreview" className="text-black">
                      {timeFormat === 'hh:mm:ss:ms'
                        ? currentdate.getHours() +
                          ':' +
                          currentdate.getMinutes() +
                          ':' +
                          currentdate.getSeconds() +
                          ': ' +
                          currentdate.getMilliseconds() +
                          ' ' +
                          (currentdate.getHours() >= 12 ? 'PM' : 'AM')
                        : timeFormat === 'hh:mm:ss'
                          ? currentdate.getHours() +
                            ':' +
                            currentdate.getMinutes() +
                            ':' +
                            currentdate.getSeconds() +
                            ' ' +
                            (currentdate.getHours() >= 12 ? 'PM' : 'AM')
                          : ''}
                    </label>
                  </h6>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Date_Time_config);

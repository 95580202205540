import { ReactComponent as Web } from '@assets/language_black_24dpTwo.svg';
import { ReactComponent as Mobile } from '@assets/smartphone_black_24dp.svg';
import { ReactComponent as WebMobile } from '@assets/language_black_24dpThree.svg';
import { ReactComponent as Salesforce } from '@assets/cloud_black_24dp.svg';
import { ReactComponent as WebServices } from '@assets/language_black_24dpOne.svg';
import { ReactComponent as MSDynamics } from '@assets/microsoft-dynamics-icon.svg';
import styles from '@pages/common/Layout/styles/search_projects.style.module.scss';

function getProjectTypeIcons(projectType, requireStyle = true) {
  switch (projectType) {
    case 'Web':
      return <Web className={requireStyle && styles['search-project-project-type']} />;

    case 'Mobile':
      return <Mobile className={requireStyle && styles['search-project-project-type']} />;

    case 'Web & Mobile':
      return <WebMobile className={requireStyle && styles['search-project-project-type']} />;

    case 'Salesforce':
      return <Salesforce className={requireStyle && styles['search-project-project-type']} />;

    case 'Web Service':
      return <WebServices className={requireStyle && styles['search-project-project-type']} />;

    case 'MS Dynamics':
      return <MSDynamics className={requireStyle && styles['search-project-project-type']} />;

    default:
      return <></>;
  }
}

export default getProjectTypeIcons;

import { ReactComponent as WindowsIcon } from '../assets/svg/windows-logo.svg';
import { ReactComponent as LinuxIcon } from '../assets/svg/linux.svg';
import { ReactComponent as AppleIcon } from '../assets/svg/apple.svg';
import HexColorCodes from './hex-color-codes.json';

// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export const clientTypes = ['all', 'windows', 'linux', 'mac'];

function getFirstNHexColorCodes(totalColorCodes = 0) {
  return HexColorCodes.slice(0, parseInt(totalColorCodes));
}

export const initialOsChartData = {
  [clientTypes[0]]: {
    title: 'OS',
    labels: ['Windows', 'Linux', 'Mac'],
    colors: ['#2376BC', '#F4AD00', '#000000'],
    series: [0, 0, 0],
    totalLabel: 'Total Machine',
    icons: [WindowsIcon, LinuxIcon, AppleIcon],
  },
  [clientTypes[1]]: {
    title: 'Versions',
    totalLabel: 'Total Machine',
    icons: [WindowsIcon],
  },
  [clientTypes[2]]: {
    title: 'Versions',
    totalLabel: 'Total Machine',
    icons: [LinuxIcon],
  },
  [clientTypes[3]]: {
    title: 'Versions',
    totalLabel: 'Total Machine',
    icons: [AppleIcon],
  },
};

export async function formatOsChartData(data, selectedClientType, setOsChartData) {
  let osChartData = initialOsChartData[selectedClientType];
  const osDetailsFromAPI = (selectedClientType === clientTypes[0] ? data?.osDetails : data?.osVersion) || [];

  const labels =
    osChartData.labels ||
    [
      ...new Set(
        osDetailsFromAPI.map((osVersion) =>
          osVersion.osName
            ?.split('.')
            .slice(0, 2)
            .join('.')
            .replace(/(\.[0]*)$/, '')
            .capitalize()
        )
      ),
    ]
      .sort()
      .reverse();
  const series = await labels.map((label) => {
    return osDetailsFromAPI.reduce((totalCount, typeDetail) => {
      if ((typeDetail.name || typeDetail.osName)?.toLowerCase().startsWith(label?.toLowerCase()) && typeDetail.count) {
        return totalCount + typeDetail.count;
      }
      return totalCount;
    }, 0);
  });
  osChartData = {
    ...osChartData,
    labels: labels.length ? labels : ['nodata'],
    colors: osChartData.colors || getFirstNHexColorCodes(labels?.length || 1),
    series: series.length ? series : [0],
  };

  setOsChartData(osChartData);
}

const browsers = {
  firefox: {
    label: 'Firefox',
    color: '#E57300',
  },
  edge: {
    key: 'MicrosoftEdge',
    label: 'Edge',
    color: '#0179D5',
  },
  chrome: {
    label: 'Chrome',
    color: '#0F9D58',
  },
  safari: {
    label: 'Safari',
    color: '#22A6F2',
  },
  ie: {
    key: 'internet explorer',
    label: 'IE',
    color: '#1DB5E7',
  },
  opera: {
    label: 'Opera',
    color: '#E53637',
  },
};

const clientStatusLabelsAndColors = {
  keys: ['available', 'inactive', 'busy', 'disabled'],
  labels: ['Available', 'Inactive', 'Busy', 'Disabled'],
  colors: ['#0F9D58', '#727171', '#EFBB07', '#E1DFDF'],
};

export function getStatusesData(statusesData = []) {
  const series = clientStatusLabelsAndColors.labels.map((label, labelIndex) => {
    const labelKey = clientStatusLabelsAndColors.keys[labelIndex];
    const totalCount = statusesData.reduce((returnCount, statusData) => {
      if (statusData.name?.toLowerCase() === (labelKey || label?.toLowerCase())) {
        return returnCount + statusData.count;
      }
      return returnCount;
    }, 0);

    return {
      name: label,
      data: [totalCount],
    };
  });

  return {
    ...clientStatusLabelsAndColors,
    series,
  };
}

export function getBrowerVersionsData(type, browserDetails = []) {
  const labels = [];
  const colors = [];
  const series = [];
  const versions = [];
  let browsersInType = [];
  const { firefox, edge, chrome, ie, opera, safari } = browsers;
  switch (type) {
    case clientTypes[1]:
      browsersInType = [firefox, edge, chrome, ie, opera];
      break;
    case clientTypes[2]:
      browsersInType = [firefox, edge, chrome, opera];
      break;
    case clientTypes[3]:
      browsersInType = [firefox, edge, chrome, safari, opera];
      break;
    default:
      browsersInType = Object.values(browsers);
      break;
  }

  browsersInType.forEach((browser, index) => {
    labels.push(browser.label);
    colors.push(browser.color);
    const { totalCount, allVersions } = browserDetails.reduce(
      (returnCountAndVersions, browserData) => {
        if (browserData.name?.toLowerCase() === (browser.key?.toLowerCase() || browser.label?.toLowerCase())) {
          return {
            totalCount: returnCountAndVersions.totalCount + (browserData.count || 0),
            allVersions: [...new Set([...returnCountAndVersions.allVersions, ...(browserData.versions || [])])],
          };
        }
        return returnCountAndVersions;
      },
      { totalCount: 0, allVersions: [] }
    );

    series.push({
      name: browser.label,
      data: [totalCount],
    });

    versions.push(allVersions);
  });

  return {
    labels,
    colors,
    series,
    versions,
  };
}

const clientPhoneOsLabelsAndColors = {
  labels: ['Android', 'IOS'],
  colors: ['#147AD6', '#EC6666'],
};

export function getPhoneOsData(devices = []) {
  const categories = devices
    .reduce((returnVersions, device) => {
      return [...new Set([...returnVersions, ...device.versions])];
    }, [])
    .sort((a, b) => a - b);

  const series = clientPhoneOsLabelsAndColors.labels.map((label) => {
    const device = devices.find((device) => device.type?.toLowerCase() === label?.toLowerCase());
    const categoryCounts = categories.map((category) => {
      return (device?.versions || []).filter((version) => version === category).length;
    });
    return {
      name: label,
      data: categoryCounts.length ? categoryCounts : [0],
    };
  });

  return {
    ...clientPhoneOsLabelsAndColors,
    categories,
    series,
  };
}

export function getAggregationData(data, selectedClientType) {
  return selectedClientType === clientTypes[0]
    ? data?.aggregation
    : data?.subAggregations?.find((aggregation) => aggregation.type?.toLowerCase() === selectedClientType);
}

const clientAccessData = {
  title: 'Access',
  keys: ['private', 'partial_public', 'public'],
  labels: ['Private', 'Partial Public', 'Public'],
  colors: ['#800000', '#EFBB07', '#0F9D58'],
  series: [0, 0, 0],
  yAxisLabel: 'No. of occupied systems',
};

export function getClientsAccessData(accessesData = []) {
  const series = clientAccessData.keys.map((accessLabelKey) =>
    accessesData.reduce(
      (totalCount, accessData) =>
        totalCount + (accessData?.name?.toLowerCase() === accessLabelKey?.toLowerCase() ? accessData.count || 0 : 0),
      0
    )
  );

  return {
    ...clientAccessData,
    series,
  };
}

import React, { useState } from 'react';
import { Checkbox, Tooltip } from '@mui/material';
import styles from './delete-user-pop-up.module.scss';
import { EXECUTION_CONSTANTS, WARNING_POPUP_CONSTANTS } from '@src/common/ui-constants';
import { getUserName } from '@src/util/common_utils';
import cx from 'classnames';
import { Icon } from 'fireflink-ui';
import { colors } from '@src/css_config/colorConstants';

const DeleteUser = ({ data, checkbox, setCheckBox }) => {
  const [switchInfoIcon, setSwitchInfoIcon] = useState(false);
  return (
    <div className={styles['warning-content-block']}>
      <p className="fontPoppinsRegularMd">Hi {getUserName()},</p>
      <p className="fontPoppinsRegularMd">
        {WARNING_POPUP_CONSTANTS?.ARE_YOU_SURE_YOU_WANT_TO_REMOVE}
        <span className="fontPoppinsSemiboldMd">{data?.name}</span>?
      </p>
      <p className="fontPoppinsRegularMd">{WARNING_POPUP_CONSTANTS?.SUB_BODY_TEXT}</p>
      <div className={styles['checkbox-content']}>
        <Checkbox
          id="suite-access"
          name="suite-access"
          value={checkbox}
          checked={checkbox}
          onChange={(e) => setCheckBox(e.target.checked)}
          style={{ padding: 2, pointerEvents: 'auto' }}
          size="small"
        />
        <label htmlFor="suite-access">
          <p
            className={cx('fontPoppinsRegularMd')}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {EXECUTION_CONSTANTS?.SUITE_ACCESS_CHANGE}
            <span className="absolute mt-1 ml-1">
              <Tooltip
                arrow={true}
                title={
                  <div className="flex flex-col">
                    <p className="w-full fontPoppinsMediumSm border-b border-black">{EXECUTION_CONSTANTS?.SUITE}</p>
                    <p className="fontPoppinsRegularSm">{EXECUTION_CONSTANTS?.IF_UNCHECKED} </p>
                    <p className="fontPoppinsRegularSm">
                      {data?.name} {EXECUTION_CONSTANTS?.WILL_BE_DELETED}
                    </p>
                  </div>
                }
                placement="bottom"
                classes={{
                  popper: cx(styles['custom-tooltip']),
                }}
              >
                <span onMouseOver={() => setSwitchInfoIcon(true)} onMouseOut={() => setSwitchInfoIcon(false)}>
                  <Icon name="info_grey" color={colors.grey_light} hoverEffect={true} height={14} width={14} />
                </span>
              </Tooltip>
            </span>
          </p>
        </label>
      </div>
    </div>
  );
};

export default DeleteUser;

const commonElementTypes = [
  {
    label: 'Link',
    value: 'link',
  },
  {
    label: 'Text Field',
    value: 'textfield',
  },
  {
    label: 'Icon',
    value: 'icon',
  },
  {
    label: 'Button',
    value: 'button',
  },
  {
    label: 'Radio Button',
    value: 'radioButton',
  },
  {
    label: 'Text',
    value: 'text',
  },
  {
    label: 'Textarea',
    value: 'textarea',
  },
  {
    label: 'Image',
    value: 'image',
  },
  {
    label: 'Dropdown',
    value: 'dropdown',
  },
  {
    label: 'Checkbox',
    value: 'checkbox',
  },
  {
    label: 'Tab',
    value: 'tab',
  },
  {
    label: 'Action Overflow button',
    value: 'action overflow button',
  },
  {
    label: 'Hamburger menu',
    value: 'hamburger icon',
  },
  {
    label: 'Toggle Button',
    value: 'toggle button',
  },
  {
    label: 'Suggestion',
    value: 'suggestion',
  },
  {
    label: 'Time Picker',
    value: 'time picker',
  },
  {
    label: 'Date Picker',
    value: 'date picker',
  },
  {
    label: 'Toaster Message',
    value: 'toaster message',
  },
  {
    label: 'Card',
    value: 'card',
  },
  {
    label: 'Tooltip',
    value: 'tooltip',
  },
  {
    label: 'Option',
    value: 'option',
  },
  {
    label: 'Calender',
    value: 'calender',
  },
  {
    label: 'Sliders',
    value: 'sliders',
  },
  {
    label: 'Visual Testing',
    value: 'visual testing',
  },
];

const commonLocatorTypes = [
  {
    label: 'id',
    value: 'id',
  },
  {
    label: 'name',
    value: 'name',
  },
  {
    label: 'xpath',
    value: 'xpath',
  },
];

const nonIosCommonLocators = [
  {
    label: 'className',
    value: 'className',
  },
  {
    label: 'tagName',
    value: 'tagName',
  },
  {
    label: 'linkText',
    value: 'linkText',
  },
  {
    label: 'partialLinkText',
    value: 'partialLinkText',
  },
  {
    label: 'cssSelector',
    value: 'cssSelector',
  },
];
export const getElementTypeOptions = (platform) => {
  if (platform?.toLowerCase() === 'web') {
    return [
      ...commonElementTypes,
      {
        label: 'Popup',
        value: 'popup',
      },
      {
        label: 'Frame',
        value: 'frame',
      },
    ];
  } else {
    return [
      ...commonElementTypes,
      {
        label: 'Steppers',
        value: 'steppers',
      },
    ];
  }
};

export const getLocatorTypeOptions = (platform) => {
  platform = platform.toLowerCase();
  if (platform === 'web') {
    return [...commonLocatorTypes, ...nonIosCommonLocators];
  } else if (platform === 'android') {
    return [
      ...commonLocatorTypes,
      ...nonIosCommonLocators,
      {
        label: 'accessibilityId',
        value: 'accessibilityId',
      },
      {
        label: 'androidUiAutomator',
        value: 'androidUiAutomator',
      },
    ];
  } else if (platform === 'ios') {
    return [
      ...commonLocatorTypes,
      {
        label: 'accessibilityId',
        value: 'accessibilityId',
      },
      {
        label: 'Predicate String',
        value: 'iOSNsPredicateString',
      },
      {
        label: 'Class Chain',
        value: 'iOSClassChain',
      },
    ];
  } else if (['mobile', 'mobileweb'].includes(platform)) {
    return [
      ...commonLocatorTypes,
      ...nonIosCommonLocators,
      {
        label: 'accessibilityId',
        value: 'accessibilityId',
      },
      {
        label: 'androidUiAutomator',
        value: 'androidUiAutomator',
      },
      {
        label: 'Predicate String',
        value: 'iOSNsPredicateString',
      },
      {
        label: 'Class Chain',
        value: 'iOSClassChain',
      },
    ];
  }
  return [];
};

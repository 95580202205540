import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTable } from 'react-table';
import { Tooltip } from '@material-ui/core';
import { useAlert } from '@pages/common/alert_service/useAlert';
import {
  getAllDefectTemplate,
  deleteDefectTemplateRequest,
  updateDefaultDefectTemplateRequest,
} from '@api/api_services';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ContextMenu from '../../common/context_menu';
import DetailsModal from '../commons/TemplateDetailsModal';
import matchSorter from 'match-sorter';
import '../commons/template.scss';
import { getSingleUserRequest } from '@api/api_services';
import UserDetailsModal from '../../test-development/shared/user-details-modal';
import DeleteTemplateModal from '../commons/DeleteTemplateModal';
import { CommonLoader } from '@src/pages/common/common-loader';
import ChangeDefaultTemplateModal from '../commons/ChangeDefaultTemplateModal';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import SearchBar from '@pages/analytics/common/search-bar';
import { HighlightText, getCurrentLicenseId, isEmptyValue, isValueIncludes } from '@util/common_utils';
import NoResultsFound from '@pages/common/Table/NoResultFound';

export default function DefectDetailsTemplate() {
  const { AlertContatiner, MyAlert } = useAlert();
  const [disableBtn, setDisableBtn] = useState(true);
  const [defectTemplateData, setDefectTemplateData] = useState([]);
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const projectId = JSON.parse(localStorage.getItem('selected-project')).id;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [reloadTree, setReloadTree] = useState(true);
  const [defaultTemplate, setDefaultTemplate] = useState({});
  const [OpenDefaultTempModal, setOpenDefaulTemptModal] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);
  const [showDetails, setShowDetails] = useState({});
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const location = useLocation();
  const actiont = new URLSearchParams(location.search).get('actiont');
  const [deleteTempId, setDeleteTempId] = useState('');
  const [deleteTprojectId, setDeleteTprojectIdId] = useState('');
  const [deleteTempName, setDeleteTempName] = useState('');
  const [defaultTempEvent, setDefaultTemplateEvent] = useState({});
  const [defaultTempName, setDefaultTempName] = useState('');
  const [userName, setUserName] = useState('');
  const [errorContent, setErrorContent] = React.useState();
  const [openDeleteTempModal, setOpenDeleteTempModal] = useState(false);
  const [licenseUserDetails, setLicenseUserDetails] = useState({});

  const getUserDetails = async (userId) => {
    if (userId) {
      let res = await getSingleUserRequest(userId);
      if (res.data.responseCode === 200 && res.data.responseObject) {
        setUserDetails(res.data.responseObject);
        setOpenUserDetailsModal(true);
        let [data] = res?.data?.responseObject?.licenses?.filter((value) => value.id === getCurrentLicenseId());
        setLicenseUserDetails(data);
      }
    }
  };

  const closeUserDetailsModal = (value) => {
    setOpenUserDetailsModal(value);
    setUserDetails({});
  };

  const contextMenuData = [
    {
      option: 'Details',
    },
  ];

  function contextMenuFunction(event, resObj) {
    if (event.target.textContent === 'Details') {
      setOpenDetailsModal(true);
      setShowDetails(resObj);
    }
  }

  const closeDetailsModal = (value) => {
    setOpenDetailsModal(value);
  };

  function fuzzyTextFilterFn(page, id, filterValue) {
    return matchSorter(page, filterValue, { keys: [(page) => page.values[id]] });
  }

  function Table({ columns, data }) {
    const filterTypes = React.useMemo(
      () => ({
        fuzzyText: fuzzyTextFilterFn,
        text: (page, id, filterValue) => {
          return page.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      [defectTemplateData, defaultTemplate]
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
      columns,
      data,
      filterTypes,
    });
    return (
      <>
        <table className="relative w-full h-12" {...getTableProps()}>
          <thead className=" sticky top-0 table_header_color h-12">
            {headerGroups.map((headerGroup, i) => (
              <tr key={`project-list-tr-${i}`} {...headerGroup.getHeaderGroupProps()} className="h-12">
                {headerGroup.headers.map((column, colIndex) => (
                  <th key={`project-list-head-${colIndex}`} className="mb-4 px-6 py-4 text-sm  ml-4 z-10 h-12">
                    <span className="flex flex-row mr-12 border-none text-gray-700 decoration-[#3C3838] text-xs fontPoppinsSemiboldMd">
                      {column.render('Header')}
                      <div>
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <AiOutlineArrowDown className="mt-1 ml-1" />
                            ) : (
                              <AiOutlineArrowUp className="mt-1 ml-1" />
                            )
                          ) : (
                            ''
                          )}
                        </span>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="divide-y z-10" {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  key={`project-list-row-${i}`}
                  className="project-row border-none h-12  configHover"
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <td
                        key={`project-list-td-${cellIndex}`}
                        className="px-4 py-0 mr-2 text-sm fontPoppinsRegularMd pl-[17px]"
                        {...cell.getCellProps({})}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }

  //*need this for reference
  const defaulTemplateRadioHandler = () => {
    const templateId = defaultTempEvent.row.original._id;
    const tempName = defaultTempEvent.row.original.name;
    let { Actions, ...tempTemplateData } = defaultTempEvent.row.original;
    setDefaultTemplate({ ...tempTemplateData });
    updateDefaultDefectTemplateRequest(projectId, tempTemplateData, templateId)
      .then((results) => {
        if (results.data && results.data.responseCode === 200) {
          reloadpage();
          MyAlert.success(`${tempName} has been successfully added as your default template`);
          setDisableBtn(true);
        } else {
          setErrorContent(results.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error('Update Default Template Error', error.response.message);
        }
      });
  };

  // TODO :: handle Search filter :: START
  const [userSearched, setuserSearched] = useState('');
  const [data, setData] = useState([]);
  let [openSearch, setOpenSearch] = useState(false);
  let [isHighlight, setIsHighlight] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');

  useEffect(() => {
    setData(defectTemplateData);

    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [defaultTemplate, defectTemplateData]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(defectTemplateData);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  let handleSearchClose = () => {
    setOpenSearch(false);
    setSearchTextHighlight('');
    setuserSearched('');
    setData(defectTemplateData);
    setIsHighlight(!isHighlight);
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = defectTemplateData.filter(({ name, modifiedByUname }) => {
          return isValueIncludes(e, name) || isValueIncludes(e, modifiedByUname);
        });
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setData(newData);
        setIsHighlight(!isHighlight);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };
  // TODO :: handle Search filter :: END

  const columns = useMemo(
    () => [
      {
        Header: ' ',
        Cell: (e) =>
          projectId ? (
            <input
              type="radio"
              disabled={!isEditable}
              id="template-name"
              name="template-name"
              onChange={() => {
                setDefaultTemplateEvent(e);
                setUserName(e.row.original.createdByUname);
                setDefaultTempName(e.row.original.name);
                setOpenDefaulTemptModal(true);
              }}
              value={e.row.original._id}
              defaultChecked={e.row.original._id === defaultTemplate._id ? true : false}
            ></input>
          ) : null,
        columns: !projectId && [],
      },

      {
        Header: 'NAME',
        accessor: 'name',
        disableFilters: true,

        Cell: (e) => (
          <Tooltip title={e.value} placement="top">
            <div
              href=""
              onClick={() => {
                history.push(
                  `/configuration/template/defecttemplate/defectdetails/defecttemplatecreation?dmode=view`,
                  JSON.stringify({ data: e.row.original })
                );
              }}
              className="hyper_link_color-common  float-left ml-2  cursor-pointer path-trimmer"
            >
              {' '}
              {e.cell.row.values.status !== 'Archive' ? (
                <HighlightText text={e.value} highlight={searchTextHighlight} />
              ) : (
                <span className="collumnCellSpan">
                  <HighlightText text={e.value} highlight={searchTextHighlight} />{' '}
                </span>
              )}
            </div>
          </Tooltip>
        ),
      },

      {
        Header: 'TEMPLATE TYPE',
        accessor: 'templateType',
        filter: 'fuzzyText',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common word_wrap float-left ml-2">{value}</div>;
        },
      },
      {
        Header: 'FROM',
        Cell: (e) =>
          e.row.original.projectId ? (
            <div className="table-non-link-color-common float-left ml-2">Project</div>
          ) : (
            <div className="table-non-link-color-common float-left ml-2">License</div>
          ),
        columns: !projectId && [],
      },
      {
        Header: 'MODIFIED BY',
        accessor: 'modifiedByUname',
        disableFilters: true,
        Cell: (e) =>
          e?.value === '--' ? (
            <div className="table-non-link-color-common float-left ml-2">{e?.value}</div>
          ) : (
            <Tooltip title={e?.value}>
              <div
                className="hyper_link_color-common cursor-pointer float-left ml-2  path-trimmer"
                onClick={() => {
                  getUserDetails(e?.row.original?.modifiedBy);
                }}
              >
                <HighlightText text={e?.value} highlight={searchTextHighlight} />
              </div>
            </Tooltip>
          ),
      },
      {
        Header: 'MODIFIED ON',
        accessor: 'modifiedOn',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common word_wrap float-left ml-2">{value}</div>;
        },
      },
      {
        Header: 'ACTIONS',
        accessor: 'Actions',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common rs-icon-alignment-font-style">{value}</div>;
        },
      },
    ],
    [defectTemplateData, defaultTemplate, isHighlight]
  );

  useEffect(() => {
    if (reloadTree) {
      setIsLoading(true);
      getDefectTemplate();
    }
    if (actiont) {
      const tempName = new URLSearchParams(location.search).get('tempName');
      switch (actiont) {
        case 'create':
          MyAlert.success(`${tempName} defect template has been created successfully`);
          setTimeout(() => {
            history.push(`/configuration/template/defecttemplate/defectdetails`);
          }, 10);
          break;
        case 'edit':
          MyAlert.success(`${tempName} defect template has been updated successfully`);
          setTimeout(() => {
            history.push(`/configuration/template/defecttemplate/defectdetails`);
          }, 10);
          break;
      }
    }
    setReloadTree(false);
  }, [reloadTree, defectTemplateData, defaultTemplate]);

  const reloadpage = () => {
    setReloadTree(true);
  };

  const deleteTemplate = () => {
    if (deleteTempId) {
      deleteDefectTemplateRequest(deleteTempId, !!deleteTprojectId)
        .then((results) => {
          if (results.data && results.data.responseCode === 200) {
            reloadpage();
            MyAlert.success(`${deleteTempName} defect template has been deleted successfully`);
          } else if (
            results.data &&
            results.data.responseCode === 400 &&
            results.data.message.includes("Default template can't be deleted")
          ) {
            MyAlert.info(
              `You are not allowed to delete ${deleteTempName},  as it is being used as the default template`
            );
          } else {
            setErrorContent(results.data.message);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.error('Delete Template Error', error.response.message);
          }
        });
    }
  };

  useEffect(() => {
    if (defectTemplateData) {
      defectTemplateData.map((res, i) => {
        res.Actions = [];
        res.Actions.push(
          <div className="actionIcons flex justify-start align-middle">
            {isEditable && (
              <>
                <button
                  disabled={(!res.hasOwnProperty('projectId') && !!projectId) || res.templateType === 'Predefined'}
                  onClick={() => {
                    history.push(
                      `/configuration/template/defecttemplate/defectdetails/defecttemplatecreation?dmode=edit`,
                      JSON.stringify({ data: res })
                    );
                  }}
                >
                  <Tooltip
                    title={
                      !res.hasOwnProperty('projectId') && !!projectId && res.templateType === 'Userdefined'
                        ? 'Template from all projects cannot be edited'
                        : res.templateType === 'Predefined'
                          ? 'Predefined template cannot be edited'
                          : 'Edit'
                    }
                    placement="top"
                  >
                    <EditOutlinedIcon
                      className={
                        (!res.hasOwnProperty('projectId') && !!projectId) || res.templateType === 'Predefined'
                          ? ' opacity-50 mr-3'
                          : 'float-left cursor-pointer mr-3 text-blue-700 opacity-100'
                      }
                    />
                  </Tooltip>
                </button>

                {hasFullAccess && (
                  <button
                    disabled={(!res.hasOwnProperty('projectId') && !!projectId) || res.templateType === 'Predefined'}
                    onClick={() => {
                      setUserName(res?.createdByUname);
                      setDeleteTempName(res.name);
                      setDeleteTempId(res._id);
                      setDeleteTprojectIdId(res.projectId);
                      setOpenDeleteTempModal(true);
                    }}
                  >
                    <Tooltip
                      title={
                        !res.hasOwnProperty('projectId') && !!projectId && res.templateType === 'Userdefined'
                          ? 'Template from all projects cannot be deleted'
                          : res.templateType === 'Predefined'
                            ? 'Predefined template cannot be deleted'
                            : 'Delete'
                      }
                      placement="top"
                    >
                      <DeleteOutlinedIcon
                        className={
                          (!res.hasOwnProperty('projectId') && !!projectId) || res.templateType === 'Predefined'
                            ? 'opacity-50 mr-3'
                            : 'float-left cursor-pointer  mr-3 text-blue-700 opacity-100'
                        }
                      />
                    </Tooltip>
                  </button>
                )}
              </>
            )}
            <div className="project-action cursor-pointer mr-3">
              <ContextMenu
                contextMenuData={res ? contextMenuData : [...contextMenuData]}
                func={(e) => {
                  contextMenuFunction(e, res);
                }}
                configSection={true}
              />
            </div>
          </div>
        );
      });
    }
  }, [defectTemplateData, defaultTemplate]);

  async function getDefectTemplate() {
    try {
      resetProgress();
      startOrResumeProgress({
        stopAt: 90,
      });
      const getdata = await getAllDefectTemplate('config');
      stopProgress();
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 10,
      });
      // need this for reference
      // if (getdata?.data?.responseObject?.manualScriptPresent) {
      //     setDisableTemplateRadioButton(getdata.data.responseObject.manualScriptPresent);
      // } else {
      //     setDisableTemplateRadioButton(false);
      // }
      if (getdata.data.responseObject.defaultDefectTemplate) {
        setDefaultTemplate({ ...getdata.data.responseObject.defaultDefectTemplate });
      }
      if (getdata.data.responseObject.defectTemplates) {
        const defectTemplates = getdata.data.responseObject.defectTemplates;
        const exists =
          defectTemplates.filter(function (o) {
            return o.hasOwnProperty('projectId');
          }).length > 0;

        if (exists) {
          setDisableBtn(true);
        } else {
          setDisableBtn(false);
        }
      }
      if (getdata.data.responseObject.defectTemplates) {
        setDefectTemplateData(getdata.data.responseObject.defectTemplates);
      } else {
        setIsLoading(false);
        setDefectTemplateData([]);
      }
      setIsLoading(false);
    } catch (err) {
      resetProgress();
      console.error('Error occoured  defect template', err);
    }
  }

  const { isEditable, hasFullAccess } = useMemo(
    () => ({
      isEditable: window.permission?.isEditAccess('configuration'),
      hasFullAccess: window.permission?.isFullAccess('configuration'),
    }),
    []
  );

  return (
    <>
      <div className="content_area_style">
        <div className="alert-position-style">
          <AlertContatiner></AlertContatiner>
        </div>

        <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
          <label className="project_label text-sm flex-auto">Defect Details Template</label>
          <div className="pr-4">
            <div className="flex flex-row ml-32">
              <SearchBar
                open={openSearch}
                searchValue={userSearched}
                handleSearch={handleSearch}
                onSearchClose={handleSearchClose}
                handleSearchOpen={handleSearchOpen}
                enterKeyHandler={handleSearchSubmit}
                disable={isEmptyValue(defectTemplateData)}
              />

              {isEditable && (
                <button
                  disabled={disableBtn}
                  onClick={() => {
                    history.push(
                      `/configuration/template/defecttemplate/defectdetails/defecttemplatecreation?dmode=create`
                    );
                  }}
                  className="primary-btn ml-4"
                >
                  + Template
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="table-height overflow-auto h-12" id="journal-scroll">
          <Table columns={columns} data={data} />
          {isLoading ? (
            <CommonLoader />
          ) : isEmptyValue(defectTemplateData) ? (
            <div className="fontPoppinsRegularMd text-sm no-steps">
              <div className="">
                <p className="font-bold mb-4">{isEditable ? 'Quick Start' : 'No Templates!!'}</p>
                {isEditable && (
                  <p className="mb-4">
                    <span className="font-bold">Step 1 :</span> Click
                    <button
                      className="primary-btn ml-4"
                      onClick={() => {
                        history.push(
                          `/configuration/template/defecttemplate/defectdetails/defecttemplatecreation?dmode=create`
                        );
                      }}
                    >
                      + Template
                    </button>
                    {`  to start adding the Templates`}
                  </p>
                )}
              </div>
            </div>
          ) : (
            <>{openSearch && isEmptyValue(data) && <NoResultsFound className="mt-14" />}</>
          )}
        </div>
        {openUserDetailsModal ? (
          <UserDetailsModal
            data={userDetails}
            closeModal={closeUserDetailsModal}
            licenseUserDetails={licenseUserDetails}
          />
        ) : null}
        {openDetailsModal ? <DetailsModal showDetails={showDetails} closeModal={closeDetailsModal} /> : null}
        {openDeleteTempModal && (
          <DeleteTemplateModal
            openForDeleteTempChange
            handleCloseForDeleteTempChange={() => {
              setOpenDeleteTempModal(false);
            }}
            deleteTemplate={deleteTemplate}
            deleteTempName={deleteTempName}
            userName={userName}
          />
        )}
        {OpenDefaultTempModal && (
          <ChangeDefaultTemplateModal
            defaulTemplateRadioHandler={defaulTemplateRadioHandler}
            openForDefaultTempChange
            handleCloseForDefaultTempChange={() => {
              setOpenDefaulTemptModal(false);
            }}
            defaultTempName={defaultTempName}
            userName={userName}
          />
        )}
      </div>
    </>
  );
}

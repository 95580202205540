import React from 'react';
import Chart from 'react-apexcharts';
import { colors } from '@src/css_config/colorConstants.js';

const ResultCharts = (props) => {
  const statisticsKey = [
    'moduleStats',
    'scriptStats',
    'stepResultStats',
    'dependantScriptStats',
    'preConditionStats',
    'postConditionStats',
  ];
  const graphStatistsicKeys = ['totalPassed', 'totalFailed', 'totalWarning', 'totalSkipped'];
  let displayLabel = false;
  let graphDataArray = [];
  let graphStatisticsObject = {};
  buildGraphData(props?.chartData);
  displayCheck();

  let data = {
    options: {
      chart: {
        type: 'bar',
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 50,
        },
        fontFamily: 'Poppins-Regular',
        toolbar: {
          show: false,
        },
      },
      colors: colors.apexChartColors,
      grid: {
        show: false,
        padding: {
          top: 0,
          right: 30,
          bottom: 0,
          left: -120,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%',
          endingShape: 'rounded',
          borderRadius: 3,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      stroke: {
        show: true,
        width: 8,
        colors: ['transparent'],
      },
      xaxis: {
        categories: [''],
        crosshairs: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        shared: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return chartTooltip(series, seriesIndex, dataPointIndex, props.suiteType);
        },
        intersect: false,
        style: {
          top: '-11px',
        },
        x: {
          show: false,
        },
        fixed: {
          enabled: true,
          position: 'topLeft',
          offsetX: 0,
          offsetY: -14,
        },
      },
    },
  };
  function chartTooltip(series, seriesIndex, dataPointIndex, suiteType) {
    let _tooltipHtml =
      '<div class="chart-tooltip-wrapper chart-tooltip top fontPoppinsRegularXs">' +
      '<span>' +
      'Passed : ' +
      (series[seriesIndex][dataPointIndex] === 0.3 ? 0 : series[seriesIndex][dataPointIndex]) +
      '&ensp;' +
      'Failed : ' +
      (series[seriesIndex + 1][dataPointIndex] === 0.3 ? 0 : series[seriesIndex + 1][dataPointIndex]) +
      '<br>' +
      'Warning : ' +
      (series[seriesIndex + 2][dataPointIndex] === 0.3 ? 0 : series[seriesIndex + 2][dataPointIndex]) +
      '&ensp;' +
      'Skipped : ' +
      (series[seriesIndex + 3][dataPointIndex] === 0.3 ? 0 : series[seriesIndex + 3][dataPointIndex]) +
      '</span>' +
      '</div>';
    if (suiteType === 'Manual') {
      _tooltipHtml =
        '<div class="chart-tooltip-wrapper chart-tooltip top fontPoppinsRegularXs">' +
        '<span>' +
        'Passed : ' +
        (series[seriesIndex][dataPointIndex] === 0.3 ? 0 : series[seriesIndex][dataPointIndex]) +
        '&ensp;' +
        'Failed : ' +
        (series[seriesIndex + 1][dataPointIndex] === 0.3 ? 0 : series[seriesIndex + 1][dataPointIndex]) +
        '&ensp;' +
        'Warning : ' +
        (series[seriesIndex + 2][dataPointIndex] === 0.3 ? 0 : series[seriesIndex + 2][dataPointIndex]) +
        '<br>' +
        'Skipped : ' +
        (series[seriesIndex + 3][dataPointIndex] === 0.3 ? 0 : series[seriesIndex + 3][dataPointIndex]) +
        '&ensp;' +
        'Partially Executed:' +
        (series[seriesIndex + 4][dataPointIndex] === 0.3 ? 0 : series[seriesIndex + 4][dataPointIndex]) +
        '<br>' +
        'Not Executed:' +
        (series[seriesIndex + 5][dataPointIndex] === 0.3 ? 0 : series[seriesIndex + 5][dataPointIndex]) +
        '</span>' +
        '</div>';
    }
    return _tooltipHtml;
  }

  function displayCheck() {
    let stats = props?.chartData['scriptStats'];
    if (props?.suiteType === 'Manual') {
      graphStatistsicKeys.push('totalPartiallyExecuted', 'totalNotExecuted');
    }
    if (stats) {
      graphStatistsicKeys.map((key) => {
        if (stats.hasOwnProperty(key) && stats[key] !== 0) {
          displayLabel = true;
          return;
        }
        return null;
      });
    }
  }

  function getGraphObject(graphData, statsKey, graphKey, totalExecuted) {
    let graphValue = graphData[statsKey][graphKey];
    totalExecuted = totalExecuted + graphValue;
    // if(graphValue === 0) {
    //   graphValue = 0.3;
    // }
    let graphObject = {
      name: graphKey,
      data: [graphValue],
    };
    return graphObject;
  }

  function buildGraphData(graphData) {
    statisticsKey.map((statsKey) => {
      let temp = [];
      if (graphData[statsKey]) {
        if (graphData[statsKey].hasOwnProperty('totalPassed')) {
          temp.push(getGraphObject(graphData, statsKey, 'totalPassed'));
        }
        if (graphData[statsKey].hasOwnProperty('totalFailed')) {
          temp.push(getGraphObject(graphData, statsKey, 'totalFailed'));
        }
        if (graphData[statsKey].hasOwnProperty('totalWarning')) {
          temp.push(getGraphObject(graphData, statsKey, 'totalWarning'));
        }
        if (graphData[statsKey].hasOwnProperty('totalSkipped')) {
          temp.push(getGraphObject(graphData, statsKey, 'totalSkipped'));
        }
        if (props?.suiteType === 'Manual') {
          if (graphData[statsKey].hasOwnProperty('totalPartiallyExecuted')) {
            temp.push(getGraphObject(graphData, statsKey, 'totalPartiallyExecuted'));
          }
          let totalExecutedData = temp
            ?.map((x) => x.data.map((y) => y))
            .flat()
            .reduce((a, b) => a + b, 0);
          let notExecutedData = graphData[statsKey].total - totalExecutedData;
          temp.push({ name: 'totalNotExecuted', data: [notExecutedData] });
        }
        if (graphData[statsKey].hasOwnProperty('total')) {
          graphStatisticsObject[statsKey] = graphData[statsKey].total;
        }
        /*Object.keys(graphData[statsKey]).map((graphKey) => {
              
                if(graphStatistsicKeys.includes(graphKey)){
                  let graphValue = graphData[statsKey][graphKey];
                  if(graphValue === 0) {
                    graphValue = 0.3;
                  }
                    let graphObject =  {
                        name: graphKey,
                        data: [graphValue]
                    }
                    temp.push(graphObject);
                } else if(graphKey !== 'totalTerminated' && graphKey.includes('total')) {
                  graphStatisticsObject[statsKey] = graphData[statsKey][graphKey]
                }
            });*/
        graphDataArray.push(temp);
      }
      return null;
    });
  }
  const ifNotEmpty = (ser) => {
    let res = 0;
    ser.map((sr) => {
      res = res + sr.data[0];
      return null;
    });
    return res;
  };

  return (
    <div>
      <div className="flex items-center">
        <div className="ml-2 mt-3 w-0 flex-1">
          <div key="legend" className="grid grid-1 gap-2 mt-1">
            <span className="fontPoppinsRegularXs">
              <span>
                {' '}
                <svg className="svg circle" width={22} height={22} fill={'#79B62F'}>
                  <circle className="svg-circle-bg" cx={10} cy={10} r={5} />{' '}
                </svg>
                <span>Passed</span>
              </span>

              <span className="ml-1">
                {' '}
                <svg className="svg circle" width={22} height={22} fill={'#C50303'}>
                  <circle className="svg-circle-bg" cx={10} cy={10} r={5} />{' '}
                </svg>
                <span>Failed</span>
              </span>

              <span className="ml-1">
                {' '}
                <svg className="svg circle" width={22} height={22} fill={'#D9A903'}>
                  <circle className="svg-circle-bg" cx={10} cy={10} r={5} />{' '}
                </svg>
                <span>Warning</span>
              </span>

              <span className="ml-1">
                {' '}
                <svg className="svg circle" width={22} height={22} fill={'#727171'}>
                  <circle className="svg-circle-bg" cx={10} cy={10} r={5} />{' '}
                </svg>
                <span>Skipped</span>
              </span>
              {props?.suiteType === 'Manual' && (
                <span className="ml-1">
                  {' '}
                  <svg className="svg circle" width={22} height={22} fill={'#69b086'}>
                    <circle className="svg-circle-bg" cx={10} cy={10} r={5} />{' '}
                  </svg>
                  <span>Partially Executed</span>
                </span>
              )}
              {props?.suiteType === 'Manual' && (
                <span className="ml-1">
                  {' '}
                  <svg className="svg circle" width={22} height={22} fill={'#969A95'}>
                    <circle className="svg-circle-bg" cx={10} cy={10} r={5} />{' '}
                  </svg>
                  <span>Not Executed</span>
                </span>
              )}

              {/* Partially Executed and Not Executed ,code removed for current release... any one needs this code please contact abhirami*/}
            </span>
          </div>

          <div className="grid grid-cols-4 gap-2 mr-2" key="graph">
            {graphDataArray &&
              graphDataArray.map((ser, index) => {
                if (ifNotEmpty(ser)) {
                  return (
                    <div className="col-span-1" key={`graph${index}`}>
                      <Chart
                        id="chart"
                        className="fontPoppinsRegularSm"
                        options={data.options}
                        series={ser}
                        type="bar"
                        height="85%"
                        width="300"
                        key={`chart${index}`}
                      />
                    </div>
                  );
                }

                return null;
              })}
          </div>
          <div>
            {props?.type === 'Script' ? (
              <div className="grid grid-cols-4 gap-2 mr-2 -mt-8 graph-labels">
                {graphStatisticsObject?.stepResultStats ? (
                  <div className="text-center">Steps({graphStatisticsObject?.stepResultStats})</div>
                ) : (
                  ''
                )}
                {graphStatisticsObject?.dependantScriptStats ? (
                  <div className="text-center">Depends On Scripts({graphStatisticsObject?.dependantScriptStats})</div>
                ) : (
                  ''
                )}
                {graphStatisticsObject?.preConditionStats ? (
                  <div className="text-center">Pre Condition({graphStatisticsObject?.preConditionStats})</div>
                ) : (
                  ''
                )}
                {graphStatisticsObject?.postConditionStats ? (
                  <div className="text-center">Post Condition({graphStatisticsObject?.postConditionStats})</div>
                ) : (
                  ''
                )}
              </div>
            ) : (
              <div className="grid grid-cols-4 gap-2 mr-2 -mt-8 graph-labels">
                {graphStatisticsObject?.moduleStats && displayLabel ? (
                  <div className="text-center">Module({graphStatisticsObject?.moduleStats})</div>
                ) : (
                  ''
                )}
                {graphStatisticsObject?.scriptStats && displayLabel ? (
                  <div className="text-center">Scripts({graphStatisticsObject?.scriptStats})</div>
                ) : (
                  ''
                )}
                {graphStatisticsObject?.preConditionStats ? (
                  <div className="text-center">Pre Condition({graphStatisticsObject?.preConditionStats})</div>
                ) : (
                  ''
                )}
                {graphStatisticsObject?.postConditionStats ? (
                  <div className="text-center">Post Condition({graphStatisticsObject?.postConditionStats})</div>
                ) : (
                  ''
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultCharts;

import React, { Component, Fragment } from 'react';
import '../css/Ace_Editor.css';
// import '../css/common_attribute.css';
import AceEditor from 'react-ace-builds';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-beautify';
import 'react-ace-builds/webpack-resolver-min';
import 'brace/ext/searchbox';
import 'brace/keybinding/emacs';
import 'brace/keybinding/vim';
import 'brace/ext/language_tools';
import 'brace/ext/settings_menu';

export default class Ace_editor_query_pop_up extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorHeight: 341,
      editorWidth: '98%',
      readOnly: true,
    };
  }

  render() {
    return (
      <Fragment>
        <AceEditor
          mode={this.props.mode}
          theme="tomorrow"
          name="UNIQUE_ID_OF_DIV_Query"
          className="ace-common-styles"
          height={this.state.editorHeight}
          width={this.state.editorWidth}
          showGutter={true}
          readOnly={this.state.readOnly}
          editorProps={{ $blockScrolling: true }}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true,
          }}
          value={this.props.modeValue}
        />
      </Fragment>
    );
  }
}

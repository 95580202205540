import React, { useState, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import LicenseTabRouter from './LicenseTabRouter';

const LicenseComponentRouter = (props) => {
  const history = useHistory();
  if (history.location.pathname === '/licenses') {
    history.push({
      pathname: '/licenses/fireflink-license',
      state: history?.location?.state,
    });
  }
  let [showHistory, setShowHistory] = useState(false);

  useEffect(() => {
    setShowHistory(props.showLicenseHistory);
  }, [props.showLicenseHistory]);

  return (
    <Switch>
      <Route path="/licenses/fireflink-license" exact={true} strict={true} key="fireflink-license-page">
        <LicenseTabRouter showHistory={showHistory} />
      </Route>
      {/* <Route
                path="/licenses/firecloud-license"
                exact={true}
                strict={true}
                key="firecloud-license-page"
                component={LicenseTabRouter}
            ></Route> */}
      <Route path="/licenses/payment-history" exact={true} strict={true} key="payment-history-page">
        <LicenseTabRouter showHistory={showHistory} />
      </Route>
    </Switch>
  );
};
export default LicenseComponentRouter;

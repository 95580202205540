import React, { useState, useEffect, useRef } from 'react';
import {
  getAllMachineListReq,
  getBrowserStackList,
  getSauceLabsList,
  getAllLambdaTestsList,
  getUserAllAvailableMachineReq,
} from '@api/api_services';
import MachineTable from '@src/pages/common/MachineTable/MachineTable';
import MachineDetails from './run-settings-machines-common/machine-details';
import { getBrowserLabel, getOsLabel, getDeviceLabel } from './run-settings-machines-common/common-methods';
import Modal from 'react-modal';
import { useAlert } from '@pages/common/alert_service/useAlert';
import { enableDisableHeadlessSwitch } from '@pages/execution/suites/create-edit-child-pages/run-settings-child-pages/machine-landing-page';
import MachineEnvironmentLayout from '@src/common/MachineEnvironmentLayout';
import MachineEnvironmentTableLayout from '@src/common/MachineEnvironmentTableLayout';
import AlertPopUps from '@src/pages/common/alert_pop_ups/AlertPopUps';
import {
  ALERT_MODAL_CONSTANTS,
  DEFECT_CONSTANTS,
  EXECUTION_CONSTANTS,
  LABELS_CONSTANTS,
  FIRECLOUD_DATA,
} from '@src/common/ui-constants';
import { AiOutlineDelete } from 'react-icons/ai';
import { fetchMachineData, getScriptTypes, isEmptyValue, maxNumOfRunsAlert } from '@src/util/common_utils';
import DownloadClientModal from '@src/pages/configuration/system_config/system_config v1.1/modals/download_client_modal';
import { Button } from 'fireflink-ui';
import InvalidMachineModal from '@src/pages/execution/common/invalid-machine-modal';
import { UI_VALIDATIONS } from '@src/util/validations';

const SingleMachineLandingPage = (props) => {
  const { AlertContatiner, MyAlert } = useAlert();
  const project = JSON.parse(localStorage.getItem('selected-project'));
  const [defaultSystem, setDefaultSystem] = useState({});
  const [selectedSystem, setSelectedSystem] = useState({});
  const [allClientSystems, setAllClientSystems] = useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [instanceList, setInstanceList] = useState({});
  const [updatedSelectedMachine, setUpdatedSelectedMachine] = useState();
  const [selectedTableData, setSelectedTableData] = useState([]);
  const [disableSelectMachineButton, setDisableSelectMachineButton] = useState(true);
  const [noCallGetUpdated, setNoCallGetUpdated] = useState(false);
  const [enableButton, setEnableButton] = useState(false);
  const [disableHeadlessSwitch, setdisableHeadlessSwitch] = useState(true);
  const disableHeadlessSwitchRef = useRef();
  disableHeadlessSwitchRef.current = disableHeadlessSwitch;
  let selectedProject = JSON.parse(localStorage.getItem('selected-project'));
  const machineDetailsRef = React.useRef();
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [deleteInstanceId, setDeleteInstanceId] = useState();
  const { WARNING } = ALERT_MODAL_CONSTANTS;
  const LoggedInUserName = JSON.parse(localStorage.getItem('test-optimize-user')).name;
  let scriptTypes = getScriptTypes(props?.moduleArray);
  const user = JSON.parse(localStorage.getItem('test-optimize-user'));
  const [downloadClientModal, setDownloadClientModal] = useState(false);
  const [isData, setIsData] = useState('');

  const closeModal = (val) => {
    if (downloadClientModal) {
      setDownloadClientModal(val);
    }
  };

  const columnHeader = [
    {
      Header: 'Execution Environment',
      accessor: 'executionEnvironment',
      width: '180',
      disableFilters: true,
      Cell: ({ value, row }) => {
        return (
          <div className="table-non-link-color-common float-left table-font-style-common w-180px truncate">
            {value}
            {`${row?.original?.headless === true ? '(headless)' : ''}`}
          </div>
        );
      },
    },
    {
      Header: 'Os & Os Version',
      accessor: 'selectedOs',
      width: '140',
      disableFilters: true,
      Cell: ({ value, row }) => {
        return (
          <div className=" table-non-link-color-common float-left table-font-style-common w-140px truncate version-text-color">
            {value ? value : ''}
            {`${row?.original?.machine?.osVersion}`}
          </div>
        );
      },
    },
    {
      Header: 'Browser',
      accessor: 'selectedBrowser',
      width: '80',
      disableFilters: true,
      Cell: ({ value }) => {
        return <span className="w-80px version-text-color float-left inline truncate">{value ? value : ''}</span>;
      },
    },
    {
      Header: 'Browser Version',
      accessor: 'systemConfigDetails[0].version',
      width: '140',
      disableFilters: true,
      Cell: ({ value, row }) => {
        return (
          <div className="float-left table-non-link-color-common inline version-text-color truncate">
            {row.original.systemConfigDetails?.[0]?.version ? value : row.original.browserVersion}
          </div>
        );
      },
    },
    {
      Header: 'Devices',
      accessor: 'deviceNames',
      width: '100',
      disableFilters: true,
      Cell: ({ value, row }) => {
        return (
          <div className="w-100px table-non-link-color-common float-left table-font-style-common truncate">
            {defaultSystem[0]?.executionEnvironment === 'FireCloud' ? project.type !== 'Web & Mobile' && value : value}
          </div>
        );
      },
    },
    {
      Header: 'No. of Runs',
      accessor: 'numberOfRuns',
      disableFilters: true,
      width: '100',
      Cell: ({ value }) => {
        return (
          <div className="table-display-common table-non-link-color-common  w-100px table-font-style-common">
            {value}
          </div>
        );
      },
    },
    {
      Header: 'Action',
      accessor: 'Action',
      width: '80',
      className: 'text-center',
    },
  ];

  async function getUserAllAvailableMachine() {
    try {
      const runSetApiDataResponse = await fetchMachineData(user, getUserAllAvailableMachineReq, getAllMachineListReq);
      let runSetApiData;
      const isFirecloudEnabled = await getAllMachineListReq();
      if (isFirecloudEnabled.data.responseObject.firecloudEnabled) {
        runSetApiData = runSetApiDataResponse;
      } else {
        runSetApiData = runSetApiDataResponse.filter((machineDetail) => {
          return machineDetail?.executionEnvironment?.toLowerCase() !== FIRECLOUD_DATA.FIRE_CLOUD;
        });
      }

      if (runSetApiData) {
        setAllClientSystems(runSetApiData);
        let instanceList = {};
        if (runSetApiData) {
          const browserStackResponse = await getBrowserStackList();
          if (browserStackResponse?.data?.responseCode === 200) {
            instanceList['browserInstanceArray'] = browserStackResponse?.data?.responseObject;
          }
          const saucelabResponse = await getSauceLabsList();
          if (saucelabResponse?.data?.responseCode === 200) {
            instanceList['sauceLabInstanceArray'] = saucelabResponse?.data?.responseObject;
          }
          const lambdaTestResponse = await getAllLambdaTestsList();
          if (lambdaTestResponse?.data?.responseCode === 200) {
            instanceList['lambdaTestsInstanceArray'] = lambdaTestResponse?.data?.responseObject;
          }
          setInstanceList({ ...instanceList });
          if (props?.singleJsonData?.length) {
            let singleJsonData = props?.singleJsonData;
            if (runSetApiData?.length) {
              singleJsonData?.forEach((obj) => {
                let ind = runSetApiData?.findIndex((cliObj) => cliObj?.clientId === obj.clientId);
                let formObject = [];
                if (ind > -1) {
                  obj?.machineInstances?.forEach((machineInstance, instanceIndex) => {
                    let defaultSystemObj = { ...runSetApiData[ind] };
                    const rowId = `${instanceIndex}-${defaultSystemObj.clientId}`;
                    defaultSystemObj['rowId'] = rowId;
                    defaultSystemObj.executionEnvironment = machineInstance?.executionEnv;
                    defaultSystemObj.machine = machineInstance?.machineInfo;
                    defaultSystemObj['numberOfRuns'] = machineInstance?.numberOfRuns || 1;
                    defaultSystemObj['selectedOs'] = getOsLabel(machineInstance?.machineInfo?.osName);
                    if (project.type.toLowerCase() === 'web') {
                      defaultSystemObj.systemConfigDetails = getSingleBrowserList(
                        runSetApiData[ind]?.systemConfigDetails || runSetApiData[1]?.systemConfigDetails
                      );
                      if (defaultSystemObj.systemConfigDetails?.length) {
                        defaultSystemObj.systemConfigDetails[0].name = machineInstance?.browserName;
                        defaultSystemObj.systemConfigDetails[0].platform = machineInstance?.browserName;
                        defaultSystemObj.systemConfigDetails[0].version = machineInstance?.browserVersion;
                      }
                      defaultSystemObj['selectedBrowser'] = getBrowserLabel(machineInstance?.browserName);
                    } else if (project.type.toLowerCase() === 'mobile') {
                      let deviceArray = [];
                      let deviceName;
                      if (machineInstance?.deviceInfo?.length) {
                        let systemConfig = machineInstance?.deviceInfo;
                        systemConfig?.forEach((obj) => {
                          if (obj?.subType === 'device') {
                            deviceArray?.push(obj);
                          }
                        });
                      }
                      if (deviceArray?.length) {
                        deviceArray?.forEach((devObj) => {
                          deviceName = deviceName + devObj?.name ? devObj?.name : devObj?.name + ',';
                        });
                      }
                      defaultSystemObj['deviceNames'] = getDeviceLabel(deviceArray);
                      defaultSystemObj.systemConfigDetails = deviceArray;
                    } else if (['web & mobile', 'salesforce', 'ms dynamics'].includes(project.type.toLowerCase())) {
                      let deviceArray = [];
                      let deviceName;
                      let browserObjArray = getSingleBrowserList(runSetApiData[ind]?.systemConfigDetails);
                      if (browserObjArray?.length) {
                        browserObjArray[0].name = machineInstance?.browserName;
                        browserObjArray[0].platform = machineInstance?.browserName;
                        browserObjArray[0].version = machineInstance?.browserVersion;
                      }
                      defaultSystemObj['selectedBrowser'] = getBrowserLabel(machineInstance?.browserName);
                      if (machineInstance?.deviceInfo?.length) {
                        let systemConfig = machineInstance?.deviceInfo;
                        systemConfig?.forEach((obj) => {
                          if (obj?.subType === 'device') {
                            deviceArray?.push(obj);
                          }
                        });
                      }
                      if (deviceArray?.length) {
                        deviceName = deviceArray.map((devObj) => devObj?.name).join(',');
                      }
                      defaultSystemObj['deviceNames'] = getDeviceLabel(deviceArray);
                      defaultSystemObj.systemConfigDetails = browserObjArray?.concat(deviceArray);
                    }
                    defaultSystemObj['Action'] = [];
                    defaultSystemObj['Action'].push(
                      <span
                        className={props?.rerunMode ? 'disable_icon flex flex-row' : 'flex flex-row project-action'}
                      >
                        <AiOutlineDelete
                          className=" cursor-hand icon-blue-style mt-2"
                          size={20}
                          onClick={() => {
                            setOpenWarningModal(true);
                            setDeleteInstanceId(rowId);
                          }}
                        />
                      </span>
                    );
                    defaultSystemObj['headless'] = machineInstance?.headless;
                    defaultSystemObj['browserVersion'] = machineInstance.browserVersion;
                    formObject.push(defaultSystemObj);
                  });
                }
                setDefaultSystem([...formObject]);
                setSelectedTableData([...formObject]);
                setSelectedSystem({ ...runSetApiData[ind] });
                setEnableButton(true);
              });
            }
          } else {
            const tempSystem = runSetApiData.find((machineTemp) => !machineTemp.disabled);
            if (tempSystem) {
              setSelectedSystem({ ...tempSystem });
              setDefaultSystem([{ ...tempSystem, numberOfRuns: 1, systemConfigDetails: [] }]);
              setEnableButton(true);
            }
          }
          setDisableSelectMachineButton(false);
        }
      }
    } catch (error) {
      console.error('GET_USER_ALL_AVAILABLE_MACHINE : ', error);
    }
  }

  useEffect(() => {
    getUserAllAvailableMachine();
    if (!props.singleJsonData?.length) {
      props.storeSingleMachineData([]);
    }
  }, []);

  function setTableContent() {
    setdisableHeadlessSwitch(enableDisableHeadlessSwitch(props.moduleArray, selectedProject));
    // history.push(`/execution/suite/create-suite?hideParentLevelParallelRun=${false}`)
    setShowModal(true);
  }

  function handleDeleteSystem() {
    const defaultSystemCopy = [...defaultSystem];
    const tableDataCopy = [...selectedTableData];
    const deleteInstanceIndex = selectedTableData.findIndex((tableRow) => tableRow.rowId === deleteInstanceId);
    if (deleteInstanceIndex > -1) {
      tableDataCopy.splice(deleteInstanceIndex, 1);
      defaultSystemCopy.splice(deleteInstanceIndex, 1);
    }
    let updatedJsonData = [];
    if (defaultSystemCopy.length === 0) {
      const tempSystem = allClientSystems.find((machineTemp) => !machineTemp.disabled);
      if (tempSystem) {
        setSelectedSystem({ ...tempSystem });
        setDefaultSystem([{ ...tempSystem, numberOfRuns: 1, systemConfigDetails: [] }]);
      }
    } else {
      const machineInstances = [];
      defaultSystemCopy.forEach((systemTemp) => {
        let deviceArray = [];
        let browserObj = {};
        if (systemTemp?.systemConfigDetails?.length) {
          let systemConfig = systemTemp?.systemConfigDetails;
          systemConfig?.forEach((obj) => {
            if (obj?.subType === 'device') {
              deviceArray?.push(obj);
            } else {
              browserObj = { ...obj };
            }
          });
          let selecteSystemObj = {
            numberOfRuns: systemTemp?.numberOfRuns,
            clientId: systemTemp?.clientId,
            executionEnv: systemTemp?.executionEnvironment,
            browserName: browserObj.name || '',
            browserVersion: browserObj?.version || '',
            systemUrl: systemTemp?.systemUrl,
            machineInfo: {
              osName: systemTemp?.machine?.osName,
              osVersion: systemTemp?.machine?.osVersion,
              hostName: systemTemp?.machine?.hostName,
            },
            deviceInfo: deviceArray || [],
            headless: systemTemp?.headless || false,
          };
          if (project.type.toLowerCase() === 'web') {
            selecteSystemObj.deviceInfo = [];
          } else if (project.type.toLowerCase() === 'mobile') {
            selecteSystemObj.browserName = '';
            selecteSystemObj.browserVersion = '';
          }
          machineInstances.push({ ...selecteSystemObj });
        }
      });
      updatedJsonData = [{ clientId: machineInstances?.[0]?.clientId, machineInstances: [...machineInstances] }];
      setDefaultSystem([...defaultSystemCopy]);
    }
    setSelectedTableData([...tableDataCopy]);
    props?.storeSingleMachineData([...updatedJsonData]);
    setDeleteInstanceId();
    setOpenWarningModal(false);
  }

  const columns = React.useMemo(() => {
    if (project?.type?.toLowerCase() === 'web') {
      let x = columnHeader.slice(0, 4).concat(columnHeader.slice(5, 8));
      return x;
    } else if (project?.type?.toLowerCase() === 'mobile') {
      let x = columnHeader.slice(0, 1).concat(columnHeader.slice(4, 5)).concat(columnHeader.slice(5, 8));
      return x;
    } else if (project?.type?.toLowerCase() === 'web & mobile' || project?.type?.toLowerCase() === 'web services') {
      return columnHeader;
    } else {
      return columnHeader;
    }
  });

  function getSelectedDeviceData() {
    let updatedSystem;
    if (updatedSelectedMachine?.[0]?.clientId === defaultSystem[0]?.clientId) {
      updatedSystem = [...updatedSelectedMachine];
    } else {
      updatedSystem = [...defaultSystem];
    }
    const machineInstances = [];
    const dataForTable = [];
    updatedSystem.forEach((systemTemp, rowIndex) => {
      let tableRowData = { ...systemTemp };
      let deviceArray = [];
      let deviceNames = '';
      let browserObj = {};
      if (systemTemp?.systemConfigDetails?.length) {
        let systemConfig = systemTemp?.systemConfigDetails;
        systemConfig?.forEach((obj) => {
          if (obj.name && obj.subType === 'device') {
            deviceArray?.push(obj);
          } else if (obj.name) {
            browserObj = { ...obj };
          }
        });
        if (deviceArray?.length) {
          deviceNames = deviceArray.map((devObj) => devObj?.name).join(',');
        }
        tableRowData['rowId'] = `${rowIndex}-${systemTemp.clientId}`;
        tableRowData['Action'] = [];
        tableRowData['Action'].push(
          <span className={props?.rerunMode ? 'disable_icon flex flex-row' : 'flex flex-row project-action'}>
            <AiOutlineDelete
              className=" cursor-hand icon-blue-style mt-2"
              size={20}
              onClick={() => {
                setOpenWarningModal(true);
                setDeleteInstanceId(tableRowData.rowId);
              }}
            />
          </span>
        );
        tableRowData['selectedBrowser'] = getBrowserLabel(browserObj?.name);
        tableRowData['selectedOs'] = getOsLabel(systemTemp?.machine?.osName);
        let selecteSystemObj = {
          numberOfRuns: systemTemp?.numberOfRuns,
          clientId: systemTemp?.clientId,
          executionEnv: systemTemp?.executionEnvironment,
          browserName: browserObj.name || null,
          browserVersion: browserObj?.version || null,
          systemUrl: '',
          machineInfo: {
            osName: systemTemp?.machine?.osName,
            osVersion: systemTemp?.machine?.osVersion,
            hostName: systemTemp?.machine?.hostName,
          },
          deviceInfo: deviceArray || null,
          headless: systemTemp?.headless || false,
        };
        tableRowData['browserVersion'] = selecteSystemObj.browserVersion;

        if (project.type.toLowerCase() === 'web') {
          selecteSystemObj.deviceInfo = [];
        } else if (project.type.toLowerCase() === 'mobile') {
          selecteSystemObj.browserName = '';
          selecteSystemObj.browserVersion = '';
          tableRowData['deviceNames'] = getDeviceLabel(deviceArray);
        } else if (['web & mobile', 'salesforce', 'ms dynamics'].includes(project.type.toLowerCase())) {
          tableRowData['deviceNames'] = getDeviceLabel(deviceArray);
        }
        dataForTable.push({ ...tableRowData });
        machineInstances.push({ ...selecteSystemObj });
      }
    });
    setDefaultSystem([...updatedSystem]);
    setSelectedTableData([...dataForTable]);
    props?.storeSingleMachineData([
      { clientId: machineInstances?.[0]?.clientId, machineInstances: [...machineInstances] },
    ]);
    setShowModal(false);
    setNoCallGetUpdated(false);
  }

  const handleChangeDefaultMachine = (machine) => {
    if (props?.singleJsonData?.length && machine[0]?.clientId === props.singleJsonData[0]?.clientId) {
      const machineInstancesCopy = props.singleJsonData[0]?.machineInstances;
      const formObject = [];
      machineInstancesCopy.forEach((machineInstance) => {
        let defaultSystemObj = { ...machine[0] };
        defaultSystemObj.executionEnvironment = machineInstance?.executionEnv;
        defaultSystemObj.machine = machineInstance?.machineInfo;
        defaultSystemObj['numberOfRuns'] = machineInstance?.numberOfRuns || 1;
        if (project.type.toLowerCase() === 'web') {
          defaultSystemObj.systemConfigDetails = getSingleBrowserList(machine[0]?.systemConfigDetails);
          if (defaultSystemObj.systemConfigDetails?.length) {
            defaultSystemObj.systemConfigDetails[0].name = machineInstance?.browserName;
            defaultSystemObj.systemConfigDetails[0].platform = machineInstance?.browserName;
            defaultSystemObj.systemConfigDetails[0].version = machineInstance?.browserVersion;
          }
          defaultSystemObj['selectedBrowser'] = getBrowserLabel(machineInstance?.browserName);
        } else if (project.type.toLowerCase() === 'mobile') {
          let deviceArray = [];
          if (machineInstance?.deviceInfo?.length) {
            let systemConfig = machineInstance?.deviceInfo;
            systemConfig?.forEach((obj) => {
              if (obj?.subType === 'device') {
                deviceArray?.push(obj);
              }
            });
          }
          defaultSystemObj.systemConfigDetails = deviceArray;
        } else if (['web & mobile', 'salesforce', 'ms dynamics'].includes(project.type.toLowerCase())) {
          let deviceArray = [];
          let deviceName;
          let browserObjArray = getSingleBrowserList(machine[0]?.systemConfigDetails);
          if (browserObjArray?.length) {
            browserObjArray[0].name = machineInstance?.browserName;
            browserObjArray[0].platform = machineInstance?.browserName;
            browserObjArray[0].version = machineInstance?.browserVersion;
          }
          defaultSystemObj['selectedBrowser'] = getBrowserLabel(machineInstance?.browserName);
          if (machineInstance?.deviceInfo?.length) {
            let systemConfig = machineInstance?.deviceInfo;
            systemConfig?.forEach((obj) => {
              if (obj?.subType === 'device') {
                deviceArray?.push(obj);
              }
            });
          }
          if (deviceArray?.length) {
            deviceName = deviceArray.map((devObj) => devObj?.name).join(',');
          }
          defaultSystemObj['deviceNames'] = getDeviceLabel(deviceArray);
          defaultSystemObj.systemConfigDetails = browserObjArray?.concat(deviceArray);
        }
        defaultSystemObj['headless'] = machineInstance?.headless;
        formObject.push(defaultSystemObj);
      });
      setDefaultSystem([...formObject]);
    } else {
      setDefaultSystem([{ ...machine[0], numberOfRuns: 1, systemConfigDetails: [] }]);
    }
  };

  function getSingleBrowserList(systemConfig) {
    let tempArray = [];
    if (systemConfig?.length) {
      systemConfig?.forEach((obj) => {
        if (obj.subType === 'browser') {
          if (!tempArray?.length) {
            tempArray?.push({ ...obj });
          }
        }
      });
      return [...tempArray];
    } else {
      return systemConfig;
    }
  }

  const handleClose = () => {
    setDownloadClientModal(true);
  };

  const handleSelectedSystem = (system) => {
    handleChangeDefaultMachine([{ ...system }]);
    if (system?.clientId !== selectedSystem?.clientId) {
      setSelectedSystem(system);
    }
  };

  const getUpdatedSelectedMachine = (updatedSystem) => {
    setIsData(updatedSystem);
    if (updatedSystem?.[0]?.clientId === defaultSystem?.[0]?.clientId) {
      setDefaultSystem([...updatedSystem]);
    }
    setUpdatedSelectedMachine([...updatedSystem]);
  };

  function cancelFunction() {
    if (selectedTableData.length) {
      setDefaultSystem([...selectedTableData]);
    }
    setNoCallGetUpdated(false);
  }

  const validateMachine = async () => {
    if (maxNumOfRunsAlert(defaultSystem)) {
      const isValid = await machineDetailsRef.current?.validateForm();
      if (isEmptyValue(isValid?.machineInstances)) {
        getSelectedDeviceData();
      }
    } else if (!maxNumOfRunsAlert(defaultSystem)) {
      return MyAlert?.info(`${EXECUTION_CONSTANTS.MAXIMUM_RUNS} ${UI_VALIDATIONS.MAX_NUMBER_OF_RUNS}`);
    } else {
      const hasUndefined = isData.some((item) => item.machine.osName === undefined);
      const isValid = await machineDetailsRef.current?.validateForm();
      if (!hasUndefined) {
        if (!isValid?.machineInstances?.length) {
          getSelectedDeviceData();
        }
      }
    }
  };

  return (
    <div>
      {openWarningModal && (
        <AlertPopUps
          dialogClass={'info-child-modal-z-index'}
          execution={true}
          open={openWarningModal}
          alertType={WARNING}
          saveBtnText={'Cancel'}
          cancelBtnText={'Delete'}
          content={[
            <div className="mb-2 capitalize">Hi {LoggedInUserName},</div>,
            <p>Are you sure you want to delete {defaultSystem?.[0]?.machine?.hostName}? </p>,
          ]}
          alertTitle={'Delete'}
          onSaveBtnClick={() => {
            setOpenWarningModal(false);
          }}
          onCancelBtnClick={() => {
            handleDeleteSystem();
          }}
        />
      )}
      <Modal
        isOpen={showModal}
        className="popup-height-runsettings react-modal-main-style modal-overlay"
        onRequestClose={() => {
          setShowModal(false);
        }}
      >
        <div className="py-2 execution-modal-color">
          <span className="text-white fontPoppinsSemiboldMd opacity-100 ml-3"> Test Environments</span>
          <button
            onClick={() => {
              setShowModal(false);
            }}
            type="button"
            className="float-right mr-4 rounded-md text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div className="alert-variable-modal-position-style">
          <AlertContatiner />
        </div>
        {!isEmptyValue(allClientSystems) ? (
          <div className="m-2 qrs-system-grid-container grid grid-cols-10 gap-2.5 px-2 py-1">
            <div className="qrs-machine-table col-span-4">
              <MachineTable
                inputType={'radio'}
                systems={allClientSystems}
                currentVisibleSystem={selectedSystem}
                selectedSystems={[{ ...defaultSystem[0] }]}
                onRowClickHandler={handleSelectedSystem}
                classes={''}
              />
            </div>
            <div className="qrs-details-div overflow-y-scroll col-span-6 divide-y-4 divide-dashed">
              <MachineDetails
                executionType="sequential"
                ref={machineDetailsRef}
                selectedSystem={selectedSystem}
                instanceList={instanceList}
                defaultSystem={defaultSystem}
                project={project}
                getUpdatedSelectedMachine={getUpdatedSelectedMachine}
                noCallGetUpdated={noCallGetUpdated}
                setNoCallGetUpdated={setNoCallGetUpdated}
                disableHeadless={disableHeadlessSwitchRef.current}
                scriptTypes={scriptTypes}
                type={props?.type}
                MyAlert={MyAlert}
              />
            </div>
          </div>
        ) : (
          <InvalidMachineModal
            handleClose={handleClose}
            downloadClientModal={downloadClientModal}
            closeModal={closeModal}
          />
        )}
        {!isEmptyValue(allClientSystems) && (
          <div className="float-right mr-4 pb-3 flex flex-row">
            <Button
              className="mr-2"
              label={LABELS_CONSTANTS.CANCEL}
              variant="secondary"
              onClick={() => {
                setShowModal(false);
                cancelFunction();
              }}
            />

            <Button
              type="submit"
              label={DEFECT_CONSTANTS.SELECT}
              variant="primary"
              onClick={validateMachine}
              disabled={!enableButton}
            />
          </div>
        )}
      </Modal>

      <MachineEnvironmentLayout
        onHeaderBtnClick={() => {
          setTableContent();
        }}
        disableHeaderBtn={props.rerunMode === true}
        headerBtnProps={{ disabled: disableSelectMachineButton }}
      >
        {selectedTableData?.length && (
          <MachineEnvironmentTableLayout
            headerLabel={selectedTableData?.[0]?.machine?.hostName}
            accessDetails={selectedTableData?.[0]?.access}
            columns={columns}
            data={selectedTableData}
            project={project}
          />
        )}
      </MachineEnvironmentLayout>
    </div>
  );
};

export default SingleMachineLandingPage;

import Chart from 'react-apexcharts';
import { colors } from '@src/css_config/colorConstants.js';
import { chartFontFamily, chartFontStyle } from '@common/ui-constants';

function LineChart(props) {
  let index = props.categories.length - 1;
  let date1 = props.categories[0];
  let date2 = props.categories[index];
  var options = {
    series: props.series,
    chart: {
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
        tools: {
          download: false,
        },
      },
    },

    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      fontSize: '10px',
      fontFamily: chartFontFamily,
    },
    colors: colors.scriptTrendLine,

    stroke: {
      curve: 'smooth',
      lineCap: 'round',
      width: 3,
    },
    xaxis: {
      type: 'datetime',
      min: new Date(date1).getTime(),
      max: new Date(date2).getTime(),
      tooltip: {
        enabled: true,
        ...chartFontStyle,
      },
      categories: props.categories,
      axisTicks: {
        show: false,
      },
      marker: {
        show: false,
      },

      title: {
        text: 'Number of days',
        offsetY: 65,
        ...chartFontStyle,
      },
      labels: {
        ...chartFontStyle,
        formatter: (value) => {
          const categoryTime = new Date(value);
          return categoryTime.toLocaleDateString('en-IN', { year: 'numeric', month: 'short', day: 'numeric' });
        },
      },
    },
    yaxis: {
      title: {
        text: 'Number of scripts',
        ...chartFontStyle,
      },
      labels: {
        ...chartFontStyle,
      },
    },
    tooltip: {
      shared: true,
      enabled: true,
      onDatasetHover: {
        highlightDataSeries: true,
      },
      style: {
        fontSize: '10px',
        fontFamily: chartFontFamily,
      },
      x: {
        show: true,
        ...chartFontStyle,
      },
      y: {
        show: true,
        ...chartFontStyle,
      },

      marker: {
        show: true,
      },
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        right: 60,
      },
    },
  };
  return (
    <div className="scriptTrendBlock">
      <Chart options={options} series={options.series} type="line" height="250px" />
    </div>
  );
}
export default LineChart;

import React, { useEffect, useState } from 'react';
import StepGroupTree from './step_group_tree';

function LibraryParent(props) {
  const [data, setData] = useState(false);
  const search = window.location.search;
  const id = new URLSearchParams(search).get('id');
  const test = () => {
    setData(true);
  };
  useEffect(() => {
    test();
  }, [id]);

  return (
    <>
      <StepGroupTree dat={data} pageType="parent" MyAlert={props.MyAlert} />
    </>
  );
}

export default LibraryParent;

import Chart from 'react-apexcharts';
import { colors } from '@src/css_config/colorConstants.js';
import { chartFontFamily, chartFontStyle } from '@common/ui-constants';

function ScriptTrendChart(props) {
  var options = {
    series: props.series,
    chart: {
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
        tools: {
          download: false,
        },
      },
    },

    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      fontSize: '10px',
      fontFamily: chartFontFamily,
    },
    colors: colors.scriptTrendLine,

    stroke: {
      curve: 'smooth',
      lineCap: 'round',
      width: 3,
    },
    xaxis: {
      categories: props.categories,
      tooltip: {
        enabled: true,
        ...chartFontStyle,
      },
      axisTicks: {
        show: false,
      },
      marker: {
        show: false,
      },

      title: {
        text: 'Number of days',
        offsetY: 80,
        ...chartFontStyle,
      },
      labels: {
        ...chartFontStyle,
        trim: true,
        offsetX: -5.5,
      },
    },
    yaxis: {
      title: {
        text: 'Number of scripts',
        ...chartFontStyle,
      },
      labels: {
        ...chartFontStyle,
      },
    },

    tooltip: {
      shared: true,
      enabled: true,
      onDatasetHover: {
        highlightDataSeries: true,
      },
      ...chartFontStyle,
      x: {
        show: true,
        ...chartFontStyle,
      },
      y: {
        show: true,
        ...chartFontStyle,
      },

      marker: {
        show: true,
      },
    },
    grid: {
      show: true,
      padding: {
        left: 20,
        right: 20,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
  };

  return (
    <div className="scriptTrendBlockfor7days flex items-center justify-center">
      <Chart
        options={options}
        series={options.series}
        type="line"
        height="250px"
        width={props.showfilter ? '755px' : '1040px'}
      />
    </div>
  );
}
export default ScriptTrendChart;

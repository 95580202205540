import React from 'react';
import LicenceManagementRouting from './LicenceManagementRouting';

function Licence_management() {
  return (
    <div className="page-container">
      <div className="page-table-body">
        <div className="content_area_style">
          <LicenceManagementRouting />
        </div>
      </div>
    </div>
  );
}

export default Licence_management;

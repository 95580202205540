import React, { useEffect, useState, useRef } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TreeWithRadioButton from '@pages/common/table_tree/table-tree-with-radio-button';
import { getTCMAllModuleTreeReq, getTCMSingleModuleTreeReq } from '@api/api_services';
import { createScript } from '@pages/test-development/script/modules/module/utils/script-create-api';
import { CommonLoader } from '@pages/common/common-loader';
import TemplateSelectDropdown from './template-select-dropdown';
import { useHistory } from 'react-router-dom';
import { scrollIntoView } from '@pages/test-development/script/modules/module/utils/common-functions';

const ExistingManualScript = (props) => {
  const { isToggled } = props;
  let history = useHistory();
  const [selectedScript, setSelectedScript] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [treeData, setTreeData] = useState([]);
  const [manualDataExist, setManualDataExist] = useState(false);

  const totalModCount = useRef(0);
  let initialValues = {
    scriptName: '',
    templateId: '',
  };
  const validationSchema = yup.object({
    scriptName: yup.string().required('Script Name is required'),
    templateId: yup.string().required('Template is required'),
  });

  const onSubmit = async (values) => {
    const modifiedScriptName = values?.scriptName?.split(' - ').slice(1).join(' - ');
    if (values) {
      const requestBody = {
        name: modifiedScriptName,
        scriptType: selectedScript.data.scriptType,
        desc: selectedScript.data.desc,
        parentId: selectedScript.data.parentId,
        parentName: selectedScript.data.parentName,
        executionOrder: selectedScript.data.executionOrder,
        hierarchy: selectedScript.data.hierarchy,
        testCaseType: 'manual',
        templateId: values.templateId,
        manualTestCase: {},
        pair: isToggled,
      };
      await createScript(requestBody, 'manual', history, props.MyAlert, selectedScript.data.testCaseType);
      props.closeModal(false);
    }
  };
  const formikDetails = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  const templateOnChange = (value) => {
    setSelectedTemplate(value);
    formikDetails.setFieldValue('templateId', value[0]._id);
  };
  const treeNodeSelected = ([data]) => {
    if (!data) {
      formikDetails.setFieldValue('scriptName', '');
      setSelectedScript();
    } else if (data && data.data.key) {
      setSelectedScript(data);
      formikDetails.setFieldValue('scriptName', data.data.title);
    }
  };

  const checkManualTcExist = (chilArray) => {
    if (chilArray?.length) {
      let tempArray = chilArray;
      tempArray.map((obj) => {
        if (obj.children) {
          checkManualTcExist(obj.children);
        } else {
          if (obj?.type === 'Script') {
            totalModCount.current++;
          }
        }
      });
    }
  };

  const getScripts = async () => {
    try {
      let treeResponse;
      if (props.moduleIdForAdd || props.individualTreeId) {
        const id = props.moduleIdForAdd ? props.moduleIdForAdd : props.individualTreeId;
        treeResponse = await getTCMSingleModuleTreeReq(id, 'automation');
      } else {
        treeResponse = await getTCMAllModuleTreeReq('automation');
      }
      if (treeResponse?.data?.responseObject?.moduleTree) {
        let tempArray = treeResponse?.data.responseObject.moduleTree[0].children;
        if (tempArray?.length) {
          checkManualTcExist(tempArray);
          if (totalModCount.current > 0) {
            setTreeData([...treeResponse?.data?.responseObject?.moduleTree]);
            setManualDataExist(true);
          } else {
            treeResponse.data.responseObject.moduleTree[0].children = [];
            setTreeData([...treeResponse?.data?.responseObject?.moduleTree]);
            setManualDataExist(false);
          }
        }
      } else {
        setTreeData([]);
      }
      setIsLoading(false);
    } catch (err) {
      console.error('GET_AUTOIMATION_SCRIPTS : ', err);
    }
  };
  useEffect(() => {
    getScripts();
  }, []);

  useEffect(() => {
    if (props?.defaultTemplate && props?.templates?.length) {
      templateOnChange([...props.defaultTemplate]);
    }
  }, [props.templates, props.defaultTemplate]);

  const { isEditable, hasViewAccess, hasFullAccess } = React.useMemo(
    () => ({
      hasViewAccess: window.permission?.isViewAccess('configuration'),
      isEditable: window.permission?.isEditAccess('configuration'),
      hasFullAccess: window.permission?.isFullAccess('configuration'),
    }),
    []
  );

  return (
    <form id="existingScriptForm" onSubmit={formikDetails.handleSubmit}>
      <div className="mt-3 grid gap-4">
        <div className="parent-module-field-test-dev">
          <label
            htmlFor="scriptName"
            className={
              formikDetails.errors.scriptName && formikDetails.touched.scriptName
                ? 'text-xs text-red-500'
                : 'input-filed-label-style-common'
            }
          >
            <span className="text-red-400">&#42;</span>Select Script
          </label>
          <TreeWithRadioButton
            disableRootRadioButton={true}
            individualTree={props.moduleIdForAdd || props.individualTreeId ? true : false}
            hideRootRadioBtn={true}
            defaultDisabled={[{ key: 'folder', value: true }]}
            data={treeData}
            hideElements={false}
            hideElementsBtn={false}
            placeholder={''}
            nodeSelected={treeNodeSelected.bind(this)}
            buttonLabel="Script"
            dataExist={manualDataExist}
            noDataMsg="No automation scripts are available"
          />
          {formikDetails.errors.scriptName && formikDetails.touched.scriptName ? (
            <div className="text-red-500 text-xs md:mt-1 fontPoppinsRegularSm">{formikDetails.errors.scriptName}</div>
          ) : null}
        </div>
        <div className="">
          <div className="grid grid-cols-2">
            <label
              htmlFor="templateId"
              className={
                formikDetails.errors.templateId && formikDetails.touched.templateId
                  ? 'text-xs text-red-500'
                  : 'input-filed-label-style-common'
              }
            >
              <span className="text-red-400">&#42;</span>Testcase Template
            </label>
            {selectedTemplate.length > 0 && (hasViewAccess || isEditable || hasFullAccess) && (
              <button
                type="button"
                onClick={() => {
                  localStorage.setItem('selectedTemplate', JSON.stringify({ data: selectedTemplate[0] }));
                  window.open(
                    `/configuration/template/templatecreation?mode=view&templateId=${selectedTemplate[0]._id}`,
                    '_blank'
                  );
                }}
                className="ml-auto float-right text-blue-700 text-xs mr-9 fontPoppinsRegularMd"
              >
                View Template
              </button>
            )}
          </div>
          <div className="my-2">
            <TemplateSelectDropdown
              templateOnChange={templateOnChange}
              options={props.templates}
              value={selectedTemplate}
              disable={props.disableTemplateDropdown}
              onDropdownOpen={scrollIntoView}
            />
          </div>
          {formikDetails.errors.templateId && formikDetails.touched.templateId ? (
            <div className="text-red-500 text-xs md:mt-1">{formikDetails.errors.templateId}</div>
          ) : null}
        </div>
      </div>
    </form>
  );
};

export default ExistingManualScript;

import { CommonLoader } from '@pages/common/common-loader';
import React, { useState } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');
const LoaderModal = () => {
  const [openModal] = useState(true);
  const customStylesPage = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '640px',
      height: 'fit-content',
      borderRadius: '4px',
      opacity: '1',
    },
  };
  return (
    <Modal isOpen={openModal} style={customStylesPage} className="p-px">
      <div className="mt-2 p-1">
        <CommonLoader />
      </div>
    </Modal>
  );
};

export default LoaderModal;

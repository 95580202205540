export const _labelPositionPreviousRow = (recipient) => {
  if (recipient.length >= 1) {
    let getLabelName = document.querySelectorAll('.label-name');
    let svgIcon = document.querySelectorAll('.svg-id');
    let getLabelEmail = document.querySelectorAll('.label-email');
    if (getLabelName.length >= 1) {
      let lastGetLabelName = [...getLabelName].pop();
      let lastGetLabelEmail = [...getLabelEmail].pop();
      let lastGetSvgIcon = [...svgIcon].pop();
      lastGetLabelName.style.display = 'block';
      lastGetLabelEmail.style.display = 'block';
      lastGetSvgIcon.style.marginTop = '17px';
    }
  }
};

export const _labelPositionNextRow = (ref) => {
  if (ref.current) {
    let getLabelName = document.querySelectorAll('.label-name');
    const svgIcon = document.querySelectorAll('.svg-id');
    let getLabelEmail = document.querySelectorAll('.label-email');
    if (getLabelName.length > 1) {
      for (let i = 0; i < getLabelName.length - 1; i++) {
        getLabelName[i].style.display = 'none';
        getLabelEmail[i].style.display = 'none';
        svgIcon[i].style.marginTop = '0px';
      }
    }
  }
};

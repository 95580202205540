const getUserSession = () => {
  const user = localStorage.getItem('test-optimize-user');
  const userSession = user ? JSON.parse(user) : {};
  return userSession;
};

const getUserRole = () => {
  const user = localStorage.getItem('test-optimize-user');
  const userSession = user ? JSON.parse(user) : {};
  return userSession?.privilege;
};

const getSelectedProject = () => {
  const project = localStorage.getItem('selected-project');
  const selectedProject = project ? JSON.parse(project) : {};
  return selectedProject;
};

const isProjectOpen = () => {
  return getSelectedProject().status === 'Open';
};

// // NoAccess, View, Edit, FullAccess
// No API call for permission - Admin, Super Admin
// FullAccess - Create, Edit, Delete
// Edit - Create, Edit

let tabPermissions = {
  configuration: 'FullAccess',
  execution: 'FullAccess',
  repository: 'FullAccess',
  testData: 'FullAccess',
  testDevelopment: 'FullAccess',
  dashboard: 'FullAccess',
  analytics: 'FullAccess',
  defects: 'FullAccess',
};

const adminPermissions = { ...tabPermissions };

const initPermissions = (permission) => {
  if (getUserRole() === 'Super Admin') {
    tabPermissions = adminPermissions;
  }
  if (getUserRole() === 'Admin') {
    tabPermissions = adminPermissions;
  }
  if (getUserRole() === 'User') {
    tabPermissions = {};
  }
};

const updatePermissions = (permission) => {
  tabPermissions = permission;
};

const getAllPermissions = () => {
  return tabPermissions;
};

const getPermission = (moduleName) => {
  if (tabPermissions[moduleName]) {
    return tabPermissions[moduleName];
  }
  return 'NoAccess';
};

const isNoAccess = (moduleName) => {
  if (tabPermissions[moduleName] && tabPermissions[moduleName] === 'NoAccess') {
    return true;
  }
  return false;
};

const isViewAccess = (moduleName) => {
  if (
    tabPermissions[moduleName] &&
    (tabPermissions[moduleName] === 'View' ||
      tabPermissions[moduleName] === 'Edit' ||
      tabPermissions[moduleName] === 'FullAccess')
  ) {
    return true;
  }
  return false;
};

const isEditAccess = (moduleName) => {
  if (
    tabPermissions[moduleName] &&
    (tabPermissions[moduleName] === 'Edit' || tabPermissions[moduleName] === 'FullAccess') &&
    (!getSelectedProject().hasOwnProperty('status') || isProjectOpen())
  ) {
    return true;
  }
  return false;
};

const isFullAccess = (moduleName) => {
  if (
    tabPermissions[moduleName] &&
    tabPermissions[moduleName] === 'FullAccess' &&
    (!getSelectedProject().hasOwnProperty('status') || isProjectOpen())
  ) {
    return true;
  }
  return false;
};

global.permission = {
  getUserRole: getUserRole,
  initPermissions: initPermissions,
  updatePermissions: updatePermissions,
  getUserSession: getUserSession,
  getSelectedProject: getSelectedProject,
  getAllPermissions: getAllPermissions,
  getPermission: getPermission,
  isNoAccess: isNoAccess,
  isViewAccess: isViewAccess,
  isEditAccess: isEditAccess,
  isFullAccess: isFullAccess,
  isProjectOpen,
};

import React from 'react';
import { TextField, withStyles } from '@material-ui/core';
import './input_component.scss';
import { Label } from '../LabelComponent';
import { colors } from '@src/css_config/colorConstants';

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& input': {
        borderBottom: 'unset',
        padding: '9.5px 8px',
        height: '16px',
      },
      '&.Mui-disabled fieldset': {
        border: `1px solid ${colors.rs_border}`,
        opacity: 0.5,
      },
      '& fieldset': {
        borderColor: colors.rs_border,
      },
      '&:hover fieldset': {
        borderColor: colors.rs_border,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${colors.rs_primary}`,
      },
      '&.Mui-error fieldset': {
        border: `1px solid ${colors.rs_error}`,
      },
    },
  },
})(TextField);

function InputBox({
  value = '',
  error = '',
  errMsg = '',
  showLabel = true,
  size = 'large',
  disabled = false,
  placeholder = '',
  onInputChange = () => {},
  labelProps,
  ...props
}) {
  return (
    <>
      {showLabel && <Label {...labelProps} />}
      <div className="textfield-container">
        <CustomTextField
          onChange={onInputChange}
          disabled={disabled}
          value={value}
          placeholder={placeholder}
          size={size}
          fullWidth
          variant="outlined"
          error={error}
          InputLabelProps={{
            shrink: true,
          }}
          {...props}
        />
        {error && <p className="overflow-hidden overflow-ellipsis whitespace-nowrap">{errMsg}</p>}
      </div>
    </>
  );
}

export default InputBox;

import React, { useContext, useMemo, useState, useEffect } from 'react';
import { RestContext } from '@pages/test-development/script/scripts/webservice/restapi/restapi';
import '../css/table.css';
import '@pages/test-development/script/scripts/webservice/restapi/css/common_Webservice.scss';
import MyInput from '@pagescommon/Inputs/MyInput';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';
import CustomCheckBox from '@pages/test-development/script/scripts/webservice/CustomCheckBox';
import { WEBSERVICE } from '@src/pages/test-development/script/scripts/webservice/restapi/constants/constants';

export default function DataUrlFormData(props) {
  const context = useContext(RestContext);
  const [formValues, setFormValues] = useState([...(context.values?.urlformData || [])]);
  const [isAllSelected, setAllSelected] = useState(false);
  const isSomeSelected = formValues.filter((item) => item.isEnabled);
  const [formValuesLength, setFormValuesLength] = useState(0);
  const [isDisabled, setDisabled] = useState(true);
  const [UrlEncodedCrosshover, setUrlEncodedCrosshover] = useState(false);
  const [onMouseHoverRow, setonMouseHoverRow] = useState(-1);
  const isIndeterminate = isSomeSelected.length && isSomeSelected.length < formValues.length;
  const isEditable = useMemo(() => window.permission?.isEditAccess('testDevelopment'), []);
  const { DEFAULT_URL_FORM_DATA } = WEBSERVICE;

  useEffect(() => {
    const modifiedFormValues = formValues.filter((item) => item.name && item.value);
    const modifiedFormValuesLength = modifiedFormValues.length;
    setFormValuesLength(modifiedFormValuesLength);
  }, [formValues]);

  useEffect(() => {
    getStepsUpdate();
  }, [props.selectedStepData]);

  useEffect(() => {
    return () => context.setFieldValue('currentUrlFormValue', {});
  }, []);

  const getStepsUpdate = () => {
    if (Object.keys(props?.selectedStepData)?.length > 0) {
      const tempParmvalue = props.selectedStepData;
      if (tempParmvalue.bodyType === 'x-www-form-urlencoded') {
        if (tempParmvalue.urlEncoded.length > 0) {
          const isSelected = tempParmvalue.urlEncoded.every((item) => item.isEnabled);
          setFormValues(tempParmvalue.urlEncoded);
          context.setFieldValue('urlformData', tempParmvalue.urlEncoded);
          setAllSelected(isSelected);
        }
      } else {
        context.values.urlformData = DEFAULT_URL_FORM_DATA;
        setFormValues(DEFAULT_URL_FORM_DATA)
      }
    }
  };

  const handleArrayValues = (fieldName, fieldValue, index) => {
    const myvalues = formValues.map((value, i) => {
      return i === index ? { ...value, [fieldName]: fieldValue } : value;
    });
    setFormValues(myvalues);
    context.setFieldValue('urlformData', myvalues);
    props.handleSaveAsSteps(false);
  };
  const selectAll = () => {
    let select = !isAllSelected;
    const myvalues = formValues.map((item) => ({ ...item, isEnabled: select }));
    setFormValues(myvalues);
    context.setFieldValue('urlformData', myvalues);
    setAllSelected(select);
    props.handleSaveAsSteps(false);
  };
  const handleRemove = (id) => {
    let myvalues = [{ isEnabled: false, isModified: false, isSelected: false, name: '', value: '' }];
    if (formValues.length > 1) {
      myvalues = formValues.filter((item, idx) => idx !== id);
    }
    setFormValues(myvalues);
    context.setFieldValue('urlformData', myvalues);
    props.handleSaveAsSteps(false);
  };
  const handleAdd = (values) => {
    if (values.name && values.value) {
      const myvalues = [...formValues, { isEnabled: true, name: '', value: '' }];
      setFormValues(myvalues);
      context.setFieldValue('urlformData', myvalues);
      props.handleSaveAsSteps(false);
    }
  };
  const handleMouseOver = (i) => {
    setonMouseHoverRow(i);
  };
  const handleMouseOut = () => {
    setonMouseHoverRow(-1);
  };
  return (
    <div className="flex request-url-table">
      <div className="cont_div" id="journal-scroll">
        <table className="relative">
          <thead className="fontPoppinsSemiboldMd request-form-table-head">
            <tr className=" text-left">
              <th className="pl-4">
                <CustomCheckBox
                  checked={isSomeSelected.length}
                  onChange={selectAll}
                  className="request-parameter-checkbox"
                  indeterminate={isIndeterminate}
                />
              </th>
              <th className="w-1/3 pl-2 table-header-for-webservice" onDragStart={props.handleDragStart}>
                Key
              </th>
              <th className="w-1/3 pl-2 table-header-for-webservice" onDragStart={props.handleDragStart}>
                Value
              </th>
              <th className="w-1/5 table-header-for-webservice relative" onDragStart={props.handleDragStart}>
                <span className="flex justify-center request-url-actions">Action</span>
              </th>
              <th className="w-1/5 table-header-for-webservice" onDragStart={props.handleDragStart}>
                <span>|</span>
                <span className="request-url-total ml-7">
                  Total:<span className="request-url-count">{formValuesLength}</span>
                </span>
              </th>
            </tr>
          </thead>
          <tbody className="request-url-scroll">
            {formValues.map((item, idx) => (
              <TableRow
                handleArrayValues={handleArrayValues}
                initialValues={item}
                key={'form' + idx}
                id={idx}
                handleRemove={handleRemove}
                handleAdd={handleAdd}
                props={props}
                isEditable={isEditable}
                formValues={formValues}
                isDisabled={isDisabled}
                setUrlEncodedCrosshover={setUrlEncodedCrosshover}
                onMouseHoverRow={onMouseHoverRow}
                handleMouseOver={handleMouseOver}
                handleMouseOut={handleMouseOut}
              />
            ))}
            <div className="h-4"></div>
          </tbody>
        </table>
      </div>
    </div>
  );
}

const TableRow = ({
  initialValues = {},
  id,
  handleRemove,
  handleAdd,
  handleArrayValues,
  props,
  isEditable,
  setUrlEncodedCrosshover,
  formValues,
  isDisabled,
  onMouseHoverRow,
  handleMouseEnter,
  handleMouseOut,
  handleMouseOver,
}) => {
  const context = useContext(RestContext);
  const values = initialValues;
  const handleChange = (e) => {
    const { value, data, action, name } = e.target || 'unknown';
    const requestUrlDataInput = name === 'name' ? value : props.getUserInputValue(value, false, data, action);
    handleArrayValues(name, requestUrlDataInput, id);
    const currentUrlFormValue = { ...(context.values.currentUrlFormValue || {}), [name]: e };
    context.setFieldValue('currentUrlFormValue', currentUrlFormValue);
  };
  const handleOnPasteInRequestURLFormData = (e) => {
    const urlFormData = e.clipboardData.getData('text');
    if (urlFormData === values.name) {
      props.handleSaveAsSteps(false);
    }
  };

  return (
    <tr
      className={`relative request-url-form-key text-left ${onMouseHoverRow === id ? 'common-input-mouse-over' : 'common-input-mouse-out'} `}
      onMouseOver={() => handleMouseOver(id)}
      onMouseOut={handleMouseOut}
    >
      <td className="absolute top-2.5 left-2 pl-2">
        <CustomCheckBox
          checked={values.isEnabled}
          onChange={() => handleArrayValues('isEnabled', !values.isEnabled, id)}
          className="request-parameter-checkbox"
        />
      </td>
      <td className="pl-2 w-2/5">
        <MyInput
          className="common-input-field input w-60 h-full"
          type="text"
          name="name"
          autoComplete="off"
          value={values.name}
          onChange={handleChange}
          placeholder="Enter URL encoded key"
          inputValue={'input'}
          onPaste={handleOnPasteInRequestURLFormData}
        />
      </td>
      <td className="w-2/5">
        <MyInput
          canCreateVariable={isEditable}
          type="text"
          placeholder={'Enter URL encoded value'}
          variableInput={true}
          value={props.getUserInputValue(values?.value, true)}
          onChange={handleChange}
          autoComplete="off"
          name="value"
          fullWidth
          className={'common-urlinput-field input w-60 h-full '}
          inputValue={'input'}
          currentValue={context.values.currentUrlFormValue?.value}
          {...props}
        />
      </td>
      <td className="request-url-encoded-actions absolute   w-1/5">
        <span className="flex ">
          <span className="mr-3">
            {values.isEnabled ? (
              <CustomTooltip title={'Delete'} placement="bottom">
                <DeleteIcon
                  onMouseEnter={() => setUrlEncodedCrosshover(true)}
                  onMouseLeave={() => setUrlEncodedCrosshover(false)}
                  className={`cursor-pointer ${!values?.isEnabled && (values?.name || values?.value || formValues?.length > 1) ? ' opacity-100 text-gray-500 delete-icon-hover-effect delete-enable-effect' : 'opacity-50 text-gray-400 p-0.5'}`}
                  alt="showmore"
                  draggable="false"
                />
              </CustomTooltip>
            ) : (
              <CustomTooltip title={'Delete'} placement="bottom">
                <DeleteIcon
                  onMouseEnter={() => setUrlEncodedCrosshover(true)}
                  onMouseLeave={() => setUrlEncodedCrosshover(false)}
                  className={`cursor-pointer ${!values?.isEnabled && (values?.name || values?.value || formValues?.length > 1) ? ' opacity-100 text-gray-500 delete-icon-hover-effect delete-enable-effect' : 'opacity-50 text-gray-400 p-0.5'}`}
                  alt="showmore"
                  onClick={() => handleRemove(id)}
                  draggable="false"
                />
              </CustomTooltip>
            )}
          </span>
          <span className="pl-0">
            {formValues.length - 1 === id && (
              <CustomTooltip title="Add">
                <AddIcon
                  onClick={() => handleAdd(values)}
                  className={`${values?.value && values?.name ? 'plus-enable-effect text-gray-500' : 'text-gray-300 '}`}
                />
              </CustomTooltip>
            )}
          </span>
        </span>
      </td>
      <td className="w-1/6"></td>
    </tr>
  );
};

/**
 *  Create routes mapping
 *
 *  All dynamic params will display automatically.
 *  not that even though '/users/:id' route is not in configuration file,
 *  it's corresponding link it will be displayed as the value of ':id'
 */

export const routes = {
  //Project Menu routes
  '/projectmenu': 'Projects Menu',
  '/testData': 'Test Data',
  '/projectmenu/user': '  Users',
  '/projectmenu/role': '  Roles',
  '/projectmenu/user-individual': 'Users',
  '/projectmenu/project-config': 'Project Config',
  '/projectmenu/role-individual': 'Roles',
  '/projectmenu/project': ' Projects',

  //Repository routes
  '/repository': 'Repository',
  '/repository/element': '  Elements',
  '/repository/element/project_elements': ' Project Elements',
  '/repository/program_element': '  Program Element',
  '/repository/program_elements': '  Program Elements',
  '/repository/step_group': '  Step Groups',
  '/repository/element/recorded_element': '  Recorded_element',
  '/repository/element/element_storet': '  Element_store',
  '/repository/element/web': 'Web',
  '/repository/element/ios': 'Ios',
  '/repository/element/mobile': '  Mobile',
  '/repository/element/android': '  Android',
  '/repository/element/web/project_elements': '  Project Elements',
  '/repository/element/ios/project_elements': '  Project Elements',
  '/repository/element/mobile/project_elements': '  Project Elements',
  '/repository/element/android/project_elements': '  Project Elements',
  '/repository/element/shared-elements': 'Shared Elements',
  '/repository/element/recorded-elements': 'Recorded Elements',
  '/repository/element/shared-elements/:element_name/shared-element': 'Shared Element',
  //DashBoard routes
  '/dashboard/project-dashboard': 'Project Dashboard',
  '/dashboard/automation-suite': 'Automation Suite Analytics',
  '/dashboard/manual-suite': 'Manual Suite Analytics',
  '/dashboard/defects': 'Defect Analytics',
  '/dashboard/custom-dashboard': 'Custom Dashboard',
  //Configuration routes
  '/configuration/environmentconfiguration/systemconfiguration': 'Environment Configuration',
  '/configuration/environmentconfiguration': 'Run Configuration',
  '/configuration/pluginconfiguration': 'Plugin Configuration',
  '/configuration/pluginconfiguration/plugin': 'Plugin',
  '/configuration/pluginconfiguration/plugin/BrowserStack': 'Browser Stack',
  '/configuration': 'Configuration',
  '/configuration/RunConfiguration': 'Run Configuration',
  '/configuration/RunConfiguration/Waitconfiguration': 'Wait Configuration',
  '/configuration/RunConfiguration/Screenshot&Videoconfiguration': 'Screenshot & video Configuration',
  '/configuration/ResultConfiguration': 'Result Configuration',
  '/configuration/Result&HistoryConfiguration/ResultConfiguration': 'Result Configuration',
  '/configuration/Result&HistoryConfiguration/HistoryConfiguration': 'History Configuration',
  '/configuration/emailConfiguration': 'Email Configuration',
  '/configuration/emailConfiguration/emailGroup': 'Email Group',
  '/configuration/emailConfiguration/history': 'History',
  '/configuration/ElementConfiguration': 'Element Configuration',
  '/configuration/ElementConfiguration/ElementType': 'Element Type',
  '/configuration/ElementConfiguration/ElementType/android': 'Android',
  '/configuration/ElementConfiguration/ElementType/ios': 'iOS',
  '/configuration/ElementConfiguration/Locators/web': 'Web',
  '/configuration/ElementConfiguration/Locators/android': 'Android',
  '/configuration/ElementConfiguration/Locators/ios': 'iOS',
  '/configuration/Configurationvariables': 'Variables',
  '/configuration/Configurationvariables/Projectenvironmentvariable': 'Project Environment Variable',
  '/configuration/Label': 'Label',
  '/configuration/Externallibraries': 'External libraries',
  '/configuration/memoryConfiguration': 'Memory Configuration',
  '/configuration/memoryConfiguration/distributeMemory': 'Distribute Memory',
  '/configuration/Externallibraries/ExternalLibraries': 'External Libraries',
  '/configuration/import-export': 'Import & Export',
  '/configuration/import-export/Import': 'Import',
  '/configuration/import-export/Export': 'Export',
  '/configuration/template': '',
  '/configuration/template/templatecreation': '',
  '/configuration/template/elementcreation': 'Element Creation',
  '/configuration/template/viewtemplate': 'View Template',
  '/configuration/template/defecttemplate': 'Defect Template',
  '/configuration/template/defecttemplate/defectdetails': 'Defect Details Template',
  '/configuration/template/defecttemplate/defectlifecycleTemplate': 'Defect Life Cycle Template',
  '/configuration/template/defecttemplate/defectdetails/defecttemplatecreation': '',
  '/configuration/TimeZone': 'Time Zone',
  '/configuration/template/defecttemplate/defectlifecycleTemplate/defectlifecycleview':
    'Defect Life Cycle Template View',
  '/configuration/defectManagement': 'Defect Management',
  //Integration routes
  '/configuration': 'Configuration',
  '/configuration/integration': 'Integrations',
  '/configuration/integration/plugin': 'Plugin',
  '/configuration/integration/plugin/Azure': 'Azure DevOps',
  //Test Development routes
  '/testdevelopment': 'Test Development',
  '/testdevelopment/script': 'Scripts',
  '/testdevelopment/Script': 'Scripts',
  '/testdevelopment/Execution Dashboard/:executionName/Root Module/result': 'Result',
  '/testdevelopment/Execution Dashboard/:executionName/Root Module/:ModuleName/result': 'Result',
  '/testdevelopment/Execution Dashboard/:executionName/Root Module/:ModuleName/:scriptName/script': 'script',
  '/testdevelopment/Execution Dashboard/:executionName/Root Module/:ModuleName/:scriptName/script/result': 'Result',

  //Execution Routes
  '/execution': 'Execution',
  '/execution/suite': 'Suite',
  '/execution/suiteofsuite': 'Suite Of Suite',
  '/execution/execution-dashboard': 'Execution Dashboard',
  '/execution/suite/suite1/suite-details': 'Suite Details',
  '/execution/suite/suite1/suite-dashboard': 'Execution Dashboard',
  '/execution/suiteofsuite/suite1/dashboard': 'Execution Dashboard',
  '/execution/suite/suite1/suite-scheduled-instance': 'Scheduled Instance',
  '/execution/suite/edit-suite': 'Edit  Suite',
  '/execution/suiteofsuite/create-suite-of-suites': 'Create Suite Of Suites',
  '/execution/suiteofsuite/suite1/details': 'Details',
  '/execution/suiteofsuite/suite1/scheduled-instance': 'Scheduled Instance',
  '/execution/suite/create-suite': 'Create Suite',

  //for sequential suite results
  '/execution/suite/:suiteName/execution dashboard': 'Execution Dashboard',
  '/execution/suite/:suiteName/execution dashboard/Root Module/result': 'Result',
  '/execution/suite/:suiteName/execution dashboard/Root Module/results': 'Result',
  '/execution/suite/:suiteName/execution dashboard/Root Module/:scriptName/script': null,
  '/execution/suite/:suiteName/execution dashboard/Root Module/:scriptName/script/result': 'Result',
  '/execution/suite/:suiteName/execution dashboard/Root Module/:scriptName/script/results': 'Result',

  //For parallel suite results
  '/execution/suite/:suiteName/execution dashboard/overview': 'Overview',
  '/execution/suite/:suiteName/execution dashboard/overview/machines': 'Machines',
  '/execution/suite/:suiteName/execution dashboard/overview/machines/Result': 'Result',
  '/execution/suite/:suiteName/execution dashboard/overview/machines/:machineName/Root Module/results': 'Result',
  '/execution/suite/:executionName/execution dashboard/overview/machines/:machineNames/:parentModule/:scriptName/Result':
    'Result',

  '/settings': 'Settings', // will receive ('/settings',null)

  /*
   *  NULLs, FALSEs and empty strings (if listed explicitly) will be skipped from breadcrumb chain.
   *  Otherwise if url is matched but not provided in mapping, the corresponding url part will be displayed as crumb title
   *
   *  If callback returns NULL, FALSE or an empty string, the breadcrumb is hidden from chain
   */

  //  will skip this link from breadcrumbs. Without this line the crumb title for url will be "posts"
  '/users/:id/posts': null,

  //License routes
  // "/licenses/:licenseId/renew": "Renew License",
  // "/licenses/:licenseId/upgrade-and-renew": "Renew License",
  // "/licenses/:licenseId/downgrade-and-renew": "Renew License",
  // "/licenses/:licenseId/upgrade": "Upgrade License",
  // "/licenses/:licenseId/downgrade": "Downgrade License",
  '/licenses/:licenseId': false,
  '/licenses': 'Licenses',
  '/licenses/fireflink-license': 'FireFlink License',
  '/licenses/firecloud-license': 'FireCloud License',
  '/licenses/fireflink-license/buy': 'Buy New License',
  '/licenses/:licenseId/FireFlink License/upgrade': 'Upgrade License',
  '/licenses/:licenseId/FireFlink License/renew': 'Renew License',
  '/licenses/payment-history': 'Payment History',
  // "//licenses/:licenseId/FireFlink License/renew": "Renew License",
  // Dashboard
  '/dashboard': 'Dashboard',

  // Analytics
  '/analytics-menu': 'Analytics',
  '/analytics-menu/automation-suite': 'Automation Suite',
  '/analytics-menu/manual-suite': 'Manual Suite',
  '/analytics-menu/defects': 'Defects',

  //Defects routes
  '/defect': 'Defect',
  '/defect/:id': false,
  '/defect/:id/details': 'Details',
};

import React from 'react';
import '@src/css_config/machine-environment-table.scss';
import { useTable } from 'react-table';
import { isEmptyValue } from '@src/util/common_utils';
import { NO_DATA_FOUND } from '@common/ui-constants';

function MachineEnvironmentTable({ data, columns, project }) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data,
  });
  return (
    <div className="table-height overflow-auto" id="journal-scroll">
      <div className="">
        <table {...getTableProps()} className="">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="h-9 sticky top-0 zIndex-1">
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    width={column.width}
                    className={`fontPoppinsMediumSm text-left bg-white label-color table-border-bottom pl-3 uppercase`}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="configHover h-10 project-row">
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className={`fontPoppinsRegularMd table-border-bottom pl-3`}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {isEmptyValue(data) && <div className="fontPoppinsRegularMd p-2 m-2 text-center">{NO_DATA_FOUND}</div>}
      </div>
    </div>
  );
}

export default MachineEnvironmentTable;

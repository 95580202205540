import { InputAdornment } from '@mui/material';
import LabeledInput from '@pages/common/Inputs/LabeledInput';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useState } from 'react';

function LabeledPasswordInput(props) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  return (
    <LabeledInput
      type={isPasswordVisible ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <span
              aria-label="toggle password visibility fontPoppinsRegularMd"
              onClick={() => setIsPasswordVisible((isVisible) => !isVisible)}
            >
              {isPasswordVisible ? (
                <VisibilityOutlinedIcon fontSize="inherit" className="mt-0 -ml-4 cursor-pointer" />
              ) : (
                <VisibilityOffOutlinedIcon fontSize="inherit" className="mt-0 -ml-4 cursor-pointer" />
              )}
            </span>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}

export default LabeledPasswordInput;

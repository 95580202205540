import { resetActivationLink } from '@api/api_services';
import { ReactComponent as CircleIcon } from '@assets/circle-outline-icon.svg';
import { ReactComponent as InfoIcon } from '@assets/info-icon.svg';
import warningicon from '@assets/warning-icon.svg';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import commonStyles from '@pages/auth/common.module.scss';
import '@pages/auth/login.css';
import { ACTIVATION_WARNING_CONSTANTS, GLOBAL_CONSTANTS } from '@src/common/ui-constants';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import CommonPage from '../common/CommonPage';

const useStyles = makeStyles({
  root: {
    background: 'unset',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  activationAlert: {
    '& .MuiAlert-icon': {
      marginTop: 5,
    },
    '&.MuiAlert-standardSuccess': {
      backgroundColor: 'transparent',
      justifyContent: 'center',
    },
  },
});
export default function ActivationLink(props) {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState({ open: false, message: '' });
  const [createUpdateCalled, setCreateUpdateCalled] = useState(true);
  const [showActiveUserText, setActiveUserText] = useState({
    open: false,
    message: '',
  });
  let history = useHistory();
  const classes = useStyles();
  function verifyIsUserActive(response) {
    if (response.responseCode === 400 && response.message === GLOBAL_CONSTANTS.USER_ACTIVATED_MESSAGE) {
      setActiveUserText({ open: true, message: response.message });
    } else if (response.responseObject) {
      setTimeout(() => {
        setCreateUpdateCalled(true);
      }, 10000);
      setSuccess({ open: true });
    }
  }

  function redirectToSignIn() {
    history.push('/signin');
  }

  function resentActivation(values) {
    if (createUpdateCalled) {
      setCreateUpdateCalled(false);
      const data = {
        emailId: email,
      };
      resetActivationLink(data)
        .then((res) => {
          if (res.data) {
            verifyIsUserActive(res.data);
          }
          setTimeout(() => {
            setSuccess({ open: false });
          }, 5000);
        })
        .catch((error) => {
          console.error('resend activation link error', error);
        });
    }
  }

  useEffect(() => {
    const search = window.location.search;
    const emailId = new URLSearchParams(search).get('email');
    setEmail(emailId);
  }, []);

  return (
    <CommonPage marginTop={(showActiveUserText.open || success.open) && 'mt-16'} wrapperClassname={`common-padding`}>
      <div className="">
        {success.open && (
          <Alert
            className={classes.root}
            iconMapping={{
              success: <CircleIcon fontSize="inherit" className="alertIconRegSuc " />,
            }}
          >
            <span className="activationSuccess fontPoppinsRegularLg">
              {GLOBAL_CONSTANTS.ACTIVATION_LINK_SENT_SUCCESSFULLY}
            </span>
          </Alert>
        )}
        {showActiveUserText.open && (
          <Alert
            className={classes.root}
            iconMapping={{
              success: <CircleIcon fontSize="inherit" className="alertIconRegSuc " />,
            }}
          >
            <span className="activationSuccess fontPoppinsRegularLg">{showActiveUserText.message}</span>
          </Alert>
        )}
        {showActiveUserText.open ? (
          <div className="flex flex-col justify-center items-center gap-4">
            <img src={warningicon} alt="warning icon" className="m-auto" />
            <div className={cx('text-center fontPoppinsMediumLg ', commonStyles['expired-text'])}>
              {ACTIVATION_WARNING_CONSTANTS.WARNING_ACTIVATION}
            </div>
            <Alert
              className="fontPoppinsRegularMd flex items-center"
              iconMapping={{
                success: <InfoIcon fontSize="inherit" className="mb-3.5" />,
              }}
            >
              <span className="text-center">{GLOBAL_CONSTANTS.ACTIVATED_MESSAGE}</span>
            </Alert>
            <button
              className={cx('cursor-pointer', commonStyles['btn-bg-orange'], commonStyles['common-btn'])}
              onClick={redirectToSignIn}
            >
              <span className="fontPoppinsSemiboldLg">&nbsp;{GLOBAL_CONSTANTS.PROCEED_TO_SIGN_IN}</span>
            </button>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center gap-4">
            <img src={warningicon} alt="warning icon" className="m-auto "></img>
            <div className={cx('text-center fontPoppinsMediumLg ', commonStyles['expired-text'])}>
              {ACTIVATION_WARNING_CONSTANTS.WARNING_ACTIVATION}
            </div>
            <div className="fontPoppinsRegularMd text-center letter-spacing-29">
              {ACTIVATION_WARNING_CONSTANTS.NOT_ACTIVATED_MESSAGE}
            </div>
            <button
              className={cx(
                'cursor-pointer fontPoppinsRegularSm',
                commonStyles['btn-bg-orange'],
                commonStyles['common-btn']
              )}
              onClick={resentActivation}
              onBlur={() => {
                setCreateUpdateCalled(true);
              }}
            >
              <span className="fontPoppinsSemiboldLg">&nbsp;{GLOBAL_CONSTANTS.RESEND_ACTIVATION_LINK}</span>
            </button>
            <button
              className={cx('cursor-pointer', commonStyles['btn-bg-white'], commonStyles['common-btn'])}
              onClick={redirectToSignIn}
            >
              <span className="fontPoppinsSemiboldLg">&nbsp;{GLOBAL_CONSTANTS.PROCEED_TO_SIGN_IN}</span>
            </button>
          </div>
        )}
      </div>
    </CommonPage>
  );
}

export const setExecutionTreeWithSelectedNodes = (listOfSelectedNodes) => {
  let allSelectedNodes = [];
  if (listOfSelectedNodes) {
    listOfSelectedNodes.forEach((selectedNode) => {
      let node = selectedNode?.node;
      let data = selectedNode?.data;
      if (node?.folder && node?.selected && node?.parent?.title === 'root') {
        let ifDataExist = allSelectedNodes.find((treeData) => treeData['key'] === node['key']);
        if (!ifDataExist || allSelectedNodes.length < 1) {
          allSelectedNodes.push(buildModuleObject(node, data));
        }
      } else {
        var keyNode = node;
        if (keyNode) {
          var topNode = keyNode.getParentList()[0];
          if (
            topNode &&
            topNode?.folder &&
            (topNode?.selected || topNode?.partsel) &&
            topNode?.parent?.title == 'root'
          ) {
            let ifDataExist = allSelectedNodes.find((treeData) => treeData['key'] === topNode['key']);
            if (!ifDataExist || allSelectedNodes.length < 1) {
              allSelectedNodes.push(buildModuleObject(topNode, topNode?.data));
            }
          }
        }
      }
    });
  }
  // setExecutionTree(allSelectedNodes);
  return allSelectedNodes;
};

const buildModuleObject = (moduleData, nodeData) => {
  if (!nodeData) {
    nodeData = {};
  }
  let treeObject = {
    key: moduleData?.key,
    title: moduleData?.title,
    children: filterSelectedScripts(moduleData?.children),
    folder: moduleData?.folder,
    type: moduleData?.type ? moduleData?.type : 'Module',
  };
  const mergedObject = { ...treeObject, ...nodeData };
  delete mergedObject?.status;
  return mergedObject;
};
const determineTestCaseType = (data) => {
  if (data?.testCaseType?.length === 1 && data?.testCaseType[0].type === 'manual') {
    return 'manual';
  }
  return 'Automation';
};
const filterSelectedScripts = (moduleChildren) => {
  let moduleData = [];
  if (moduleChildren) {
    moduleChildren.forEach((child) => {
      if (child && child?.selected && !child?.folder) {
        let data = {};
        if (child?.data) {
          data = child?.data;
        }
        const testCaseType = determineTestCaseType(data);
        if (testCaseType === 'Automation') {
          let treeObject = {
            key: data?.stepGroupId ? data?.stepGroupId : child?.key,
            title: data?.stepGroupName ? data?.stepGroupName : child?.title,
            children: child?.children,
            folder: child?.folder,
            type: child?.type ? child?.type : 'Script',
          };
          const mergedObject = { ...treeObject, ...data };
          delete mergedObject?.status;
          moduleData.push(mergedObject);
        }
      } else if (child?.folder && (child?.selected || (!child?.selected && child?.partsel))) {
        moduleData.push(buildModuleObject(child, child?.data));
      }
    });
  }
  return moduleData;
};

// execution tree for run only failed

export function setPreselectedValue(selectedModuleData) {
  let failedTree = [];
  failedTree = selectedModuleData.filter((module) => module.status === 'FAIL');

  if (failedTree.length) {
    failedTree.forEach((treeObj, index) => {
      if (treeObj.folder) {
        if (treeObj.status === 'FAIL') {
          let scriptArray = filterFailedScripts(treeObj?.children);
          treeObj.children = scriptArray;
        }
      }
      treeObj.status = '';
    });
    return failedTree;
  }
}

function filterFailedScripts(scriptArray) {
  const failedChild = scriptArray.filter((childNode) => childNode.status === 'FAIL');

  if (failedChild && failedChild.length) {
    failedChild.forEach((obj, index) => {
      if (obj?.folder) {
        setPreselectedValue([obj]);
      }
      obj.status = '';
    });
    return failedChild;
  }
}

// end of run only failed
export function getModuleAndScriptData(tempSelectedModulesAndScripts, searchKeys) {
  let selectedModules = tempSelectedModulesAndScripts.filter((module) => searchKeys.includes(module['key']));
  if (selectedModules.length) {
    selectedModules.forEach((treeObj) => {
      if (treeObj.folder) {
        if (searchKeys.includes(treeObj['key'])) {
          let scriptArray = filterScripts(treeObj?.children, searchKeys);
          treeObj.children = scriptArray;
          treeObj.calculatedScriptCount = scriptArray?.length ? scriptArray.length : 0;
        }
      }
    });
    return selectedModules;
  }
}

function filterScripts(scriptArray, searchKeys) {
  const filteredChild = scriptArray.filter((childNode) => searchKeys.includes(childNode['key']));
  if (filteredChild && filteredChild.length) {
    filteredChild.forEach((obj, index) => {
      if (obj?.folder) {
        getModuleAndScriptData([obj], searchKeys);
      }
      obj.status = '';
    });
    return filteredChild;
  }
}

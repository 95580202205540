import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import '../css/assert_results.css';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { fontPoppins } from '@src/css_config/fontConstants';
import { colors } from '@src/css_config/colorConstants.js';
import InfoBlue from '@pages/test-development/script/scripts/webservice/restapi/response/assert_results/images/info-blue.svg';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';

const useStyles = (
  type,
  activeButton,
  successBorderStyle,
  successColorStyle,
  errorBorderStyle,
  errorColorStyle,
  skippedBorderStyle,
  skippedColorStyle
) =>
  makeStyles({
    root: {
      letterSpacing: '0px',
      color: `${colors.text_white}`,
      fontSize: '11px',
      opacity: 1,
      ...fontPoppins.rSm,
    },
    resizedButton: {
      fontSize: '18px',
      width: '50px',
      height: '25px',
      borderRadius: '5px',
      textTransform: 'capitalize',
      '&:hover': {
        color: `${colors.text_white}`,
        backgroundColor:
          type === 'SUCCESS' && activeButton === 1
            ? `${colors.button_green_50}`
            : type === 'FAILURE' && activeButton === 2
            ? `${colors.button_red_50}`
            : type === 'ALL' && activeButton === 0
            ? `${colors.button_blue_50}`
            : `${colors.grey_light}`,
        border:
          type === 'SUCCESS' && activeButton === 1
            ? `2px solid ${colors.button_green_50}`
            : type === 'FAILURE' && activeButton === 2
            ? `2px solid ${colors.button_red_50}`
            : type === 'ALL' && activeButton === 0
            ? `2px solid ${colors.button_blue_50}`
            : `2px solid ${colors.grey_light}`,
      },
    },
    successCustomButton: {
      border: successBorderStyle,
      color: successColorStyle,
    },
    errorCustomButton: {
      border: errorBorderStyle,
      color: errorColorStyle,
    },
    skippedCustomButton: {
      border: skippedBorderStyle,
      color: skippedColorStyle,
    },
  });
const AssertResult = (props) => {
  const { responseAssertionResultsValue, assertionResultValue } = props;
  const cAssertionResults = assertionResultValue?.assertionResult?.assertionResults || [];
  const [type, setType] = useState('ALL');
  const [activeButton, setActiveButton] = useState(false);
  const [hoveredOnBtn, setHoveredOnBtn] = useState(false);
  const [isHoveredButton, setIsHoveredButton] = useState(false);
  let successBorderStyle = '';
  let successColorStyle = '';
  let errorBorderStyle = '';
  let errorColorStyle = '';
  let skippedBorderStyle = '';
  let skippedColorStyle = '';
  if (isHoveredButton) {
    if ((activeButton === 1 && type === 'SUCCESS') || type === 'SUCCESS') {
      successBorderStyle = `1px solid ${colors.button_green_50}`;
      successColorStyle = colors.button_green_50;
    } else if ((activeButton === 2 && type === 'FAILURE') || type === 'FAILURE') {
      errorBorderStyle = `1px solid ${colors.button_red_50}`;
      errorColorStyle = colors.button_red_50;
    } else if ((activeButton === 3 && type === 'SKIPPED') || type === 'SKIPPED') {
      skippedBorderStyle = `1px solid ${colors.grey_light}`;
      skippedColorStyle = colors.grey_light;
    }
  } else {
    if (type === 'SUCCESS') {
      successBorderStyle = `1px solid ${colors.button_green_50}`;
      successColorStyle = colors.button_green_50;
    } else if (type === 'FAILURE') {
      errorBorderStyle = `1px solid ${colors.button_red_50}`;
      errorColorStyle = colors.button_red_50;
    } else if (type === 'SKIPPED') {
      skippedBorderStyle = `1px solid ${colors.grey_light}`;
      skippedColorStyle = colors.grey_light;
    }
  }
  const classes = useStyles(
    type,
    activeButton,
    successBorderStyle,
    successColorStyle,
    errorBorderStyle,
    errorColorStyle,
    skippedBorderStyle,
    skippedColorStyle
  );
  const getButtonIndexInOnChange = (buttonIndex) => {
    setActiveButton(buttonIndex);
  };
  const assertResultLength =
    Object.hasOwn(...cAssertionResults, 'message') ||
    props.assertionResultValue?.assertionResult?.assertionResults?.length > 0 ||
    (responseAssertionResultsValue && responseAssertionResultsValue.length > 0);

  const initialAssertHeaderResult = () => {
    return (
      <div className="assert-result-header mt-2 flex flex-col">
        {(type === 'ALL' || type === 'SUCCESS' || type === 'FAILURE' || type === 'SKIPPED') && (
          <div className={`${type === 'ALL' ? 'assert-result-all-header' : 'assert-result-rest-header'}`}>
            <div className="assert-all-success-header">Passed</div>
            <div className="assert-all-failed-header">Failed</div>
            <div className="assert-all-skipped-header">Skipped</div>
            <div className="assert-all-total-header ">
              <span>|</span>
              <span className="key-later fontPoppinsSemiboldSm">
                {' '}
                Total:<span className="ml-1.5"></span>
                {responseAssertionResultsValue?.length + cAssertionResults?.length}{' '}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };
  return (
    <div className="assert-result-container relative">
      <div className="assert-result-status-option  assert-result-button-alignments">
        <span className="flex items-center gap-1.5 button-container">
          <Button
            disabled={!assertResultLength}
            variant="outlined"
            onMouseEnter={() => {
              setHoveredOnBtn(true);
              getButtonIndexInOnChange(0);
            }}
            onMouseLeave={() => setHoveredOnBtn(false)}
            className={`assert-All-button-common fontMontserratMediumXs ${classes.resizedButton} ${
              activeButton === 0 && type === 'ALL'
                ? 'active-border-of-assert-All-button'
                : !assertResultLength
                ? 'disable-border-of-assert-All-button'
                : 'border-of-assert-All-button'
            } ${
              activeButton === 0 && !hoveredOnBtn
                ? 'active-color-of-assert-All-button'
                : hoveredOnBtn && activeButton === 0
                ? 'hover-color-of-assert-All-button'
                : !assertResultLength
                ? 'disable-color-of-assert-All-button'
                : 'color-of-assert-All-button'
            }`}
            color={type === 'ALL' ? 'primary' : ''}
            onClick={() => {
              setType('ALL');
              getButtonIndexInOnChange(0);
            }}
          >
            <span>All</span>
          </Button>
          <Button
            disabled={!assertResultLength}
            variant="outlined"
            onMouseEnter={() => {
              setHoveredOnBtn(true);
              getButtonIndexInOnChange(1);
              setIsHoveredButton(false);
            }}
            onMouseLeave={() => {
              setHoveredOnBtn(false);
              setIsHoveredButton(true);
            }}
            className={`assert-All-button-common fontMontserratMediumXs ${classes.resizedButton} ${
              classes.successCustomButton
            } ${
              activeButton === 1 && type === 'SUCCESS'
                ? 'active-border-of-assert-Passed-button'
                : !assertResultLength
                ? 'disable-border-of-assert-Passed-button'
                : 'border-of-assert-Passed-button'
            } ${
              activeButton === 1 && !hoveredOnBtn
                ? 'active-color-of-assert-Passed-button'
                : hoveredOnBtn && activeButton === 1
                ? 'hover-color-of-assert-Passed-button'
                : !assertResultLength
                ? 'disable-color-of-assert-Passed-button'
                : 'color-of-assert-Passed-button'
            }`}
            color={
              type === 'SUCCESS' && [...cAssertionResults, ...responseAssertionResultsValue]?.length > 0
                ? 'success'
                : type === 'SUCCESS'
                ? 'primary'
                : ''
            }
            onClick={() => {
              setType('SUCCESS');
              getButtonIndexInOnChange(1);
            }}
          >
            <span>Passed</span>
          </Button>
          <Button
            disabled={!assertResultLength}
            variant="outlined"
            onMouseEnter={() => {
              setHoveredOnBtn(true);
              getButtonIndexInOnChange(2);
              setIsHoveredButton(false);
            }}
            onMouseLeave={() => {
              setHoveredOnBtn(false);
              setIsHoveredButton(true);
            }}
            className={`assert-All-button-common fontMontserratMediumXs ${classes.resizedButton} ${
              classes.errorCustomButton
            } ${
              activeButton === 2 && type === 'FAILURE'
                ? 'active-border-of-assert-Failed-button'
                : !assertResultLength
                ? 'disable-border-of-assert-Failed-button'
                : 'border-of-assert-Failed-button'
            } ${
              activeButton === 2 && !hoveredOnBtn
                ? 'active-color-of-assert-Failed-button'
                : hoveredOnBtn && activeButton === 2
                ? 'hover-color-of-assert-Failed-button'
                : !assertResultLength
                ? 'disable-color-of-assert-Failed-button'
                : 'color-of-assert-Failed-button'
            }`}
            color={
              type === 'FAILURE' && [...cAssertionResults, ...responseAssertionResultsValue]?.length > 0
                ? 'error'
                : type === 'FAILURE'
                ? 'primary'
                : ''
            }
            onClick={() => {
              setType('FAILURE');
              getButtonIndexInOnChange(2);
            }}
          >
            <span>Failed</span>
          </Button>
          <Button
            disabled={!assertResultLength}
            variant="outlined"
            onMouseEnter={() => {
              setHoveredOnBtn(true);
              getButtonIndexInOnChange(3);
              setIsHoveredButton(false);
            }}
            onMouseLeave={() => {
              setHoveredOnBtn(false);
              setIsHoveredButton(true);
            }}
            className={`assert-All-button-common fontMontserratMediumXs ${classes.resizedButton} ${
              classes.skippedCustomButton
            } ${
              activeButton === 3 && type === 'SKIPPED'
                ? 'active-border-of-assert-Skipped-button'
                : !assertResultLength
                ? 'disable-border-of-assert-Skipped-button'
                : 'border-of-assert-Skipped-button'
            } ${
              activeButton === 3 && !hoveredOnBtn
                ? 'active-color-of-assert-Skipped-button'
                : hoveredOnBtn && activeButton === 3
                ? 'hover-color-of-assert-Skipped-button'
                : !assertResultLength
                ? 'disable-color-of-assert-Skipped-button'
                : 'color-of-assert-Skipped-button'
            }`}
            color={type === 'SKIPPED' ? 'primary' : ''}
            onClick={() => {
              setType('SKIPPED');
              getButtonIndexInOnChange(3);
            }}
          >
            <span>Skipped</span>
          </Button>
        </span>
        <span className="float-right"></span>
      </div>
      {initialAssertHeaderResult()}

      <div
        className={`${
          props.requestSectionCollapse ? 'max-h-80' : 'max-h-52'
        } mt-0 overflow-x-auto overflow-y-auto main-container-for-assert-result Response-header-scrollbar ${
          assertResultLength ? 'assert-result-length' : 'assert-result'
        }`}
      >
        {[...cAssertionResults, ...responseAssertionResultsValue]?.map((item) => (
          <div
            className={`${
              item.state === 'SKIPPED' && (type === 'ALL' || type === 'SKIPPED')
                ? 'assert-all-passed-response-skipped opacity-100'
                : item.state === 'SKIPPED' && (type === 'SUCCESS' || type === 'FAILURE')
                ? 'assert-all-passed-response-skipped opacity-25'
                : item.state === 'FAILURE' && (type === 'ALL' || type === 'FAILURE')
                ? 'assert-all-passed-response-failed opacity-100'
                : item.state === 'FAILURE' && (type === 'SUCCESS' || type === 'SKIPPED')
                ? 'assert-all-passed-response-failed opacity-25'
                : item.state === 'SUCCESS' && (type === 'ALL' || type === 'SUCCESS')
                ? 'assert-all-passed-response opacity-100'
                : item.state === 'SUCCESS' &&
                  (type === 'SKIPPED' || type === 'FAILURE') &&
                  'assert-all-passed-response opacity-25'
            }`}
          >
            <div className={`w-fit`} id="journal-scroll">
              {item.state === 'SUCCESS' ? (
                <div>
                  <div className="assertTabCss assert-all-passed-response mt-1.5 pl-2.5 assert-result-success">
                    <Grid container wrap="nowrap" spacing={16}>
                      <Grid item>
                        {(item.assertionRequestDto?.name?.length || item.assertionRequestDto?.group?.length) > 25 ? (
                          <CustomTooltip
                            responseTooltip={true}
                            title={
                              <span>{`${item.assertionRequestDto?.name || item.assertionRequestDto?.group}`}</span>
                            }
                            placement="right"
                          >
                            <Typography className={classes.root}>
                              <span className="fontPoppinsMediumSm11">
                                {item.assertionRequestDto?.name?.slice(0, 25) + '...' ||
                                  item.assertionRequestDto?.group?.slice(0, 25) + '...'}
                              </span>
                            </Typography>
                          </CustomTooltip>
                        ) : (
                          <Typography className={classes.root}>
                            <span className="fontPoppinsMediumSm11">
                              {item.assertionRequestDto?.name || item.assertionRequestDto?.group}
                            </span>
                          </Typography>
                        )}
                      </Grid>
                      <Grid item>
                        <CustomTooltip
                          responseTooltip={true}
                          title={<span>{`${item.resultMessage}`}</span>}
                          placement="right"
                        >
                          <img
                            src={InfoBlue}
                            alt=""
                            className="assert-results-tooltip-icon cursor-pointer  ml-4"
                            height="15px"
                            width="15px"
                            draggable="false"
                          />
                        </CustomTooltip>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              ) : item.state === 'FAILURE' ? (
                <div>
                  <div className="assertTabCss assert-all-passed-response mt-1.5 pl-2.5 assert-result-failure">
                    <Grid container wrap="nowrap" spacing={16}>
                      <Grid item>
                        {(item.assertionRequestDto?.name?.length || item.assertionRequestDto?.group?.length) > 25 ? (
                          <CustomTooltip
                            responseTooltip={true}
                            title={
                              <span>{`${item.assertionRequestDto?.name || item.assertionRequestDto?.group}`}</span>
                            }
                            placement="right"
                          >
                            <Typography className={classes.root}>
                              <span className="fontPoppinsMediumSm11">
                                {item.assertionRequestDto?.name?.slice(0, 25) + '...' ||
                                  item.assertionRequestDto?.group?.slice(0, 25) + '...'}
                              </span>
                            </Typography>
                          </CustomTooltip>
                        ) : (
                          <Typography className={classes.root}>
                            <span className="fontPoppinsMediumSm11">
                              {item.assertionRequestDto?.name || item.assertionRequestDto?.group}
                            </span>
                          </Typography>
                        )}
                      </Grid>
                      <Grid item>
                        <CustomTooltip
                          responseTooltip={true}
                          title={<span>{`${item.resultMessage}`}</span>}
                          placement="right"
                        >
                          <img
                            src={InfoBlue}
                            alt=""
                            className="assert-results-tooltip-icon ml-4 cursor-pointer"
                            height="15px"
                            width="15px"
                            draggable="false"
                          />
                        </CustomTooltip>
                      </Grid>
                    </Grid>
                  </div>{' '}
                </div>
              ) : item.state === 'SKIPPED' ? (
                <div>
                  <div className="assertTabCss assert-all-passed-response mt-1.5 pl-2.5 assert-result-skipped">
                    <Grid container wrap="nowrap" spacing={16}>
                      <Grid item>
                        {(item.assertionRequestDto?.name?.length || item.assertionRequestDto?.group?.length) > 24 ? (
                          <CustomTooltip
                            responseTooltip={true}
                            title={
                              <span>{`${item.assertionRequestDto?.name || item.assertionRequestDto?.group}`}</span>
                            }
                            placement="bottom-start"
                          >
                            <Typography className={classes.root}>
                              <span className="fontPoppinsMediumSm11">
                                {item.assertionRequestDto?.name?.slice(0, 25) + '...' ||
                                  item.assertionRequestDto?.group?.slice(0, 25) + '...'}
                              </span>
                            </Typography>
                          </CustomTooltip>
                        ) : (
                          <Typography className={classes.root}>
                            <span className="fontPoppinsMediumSm11">
                              {item.assertionRequestDto?.name || item.assertionRequestDto?.group}
                            </span>
                          </Typography>
                        )}
                      </Grid>
                      <Grid item>
                        <CustomTooltip
                          responseTooltip={true}
                          title={<span>{`${item.resultMessage}`}</span>}
                          placement="right"
                        >
                          <img
                            src={InfoBlue}
                            alt=""
                            className="assert-results-tooltip-icon ml-4 cursor-pointer"
                            height="15px"
                            width="15px"
                            draggable="false"
                          />
                        </CustomTooltip>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AssertResult;

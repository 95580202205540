import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@material-ui/core/styles';
import '../css/response_headers.scss';
import { fontPoppins } from '@src/css_config/fontConstants';
import { colors } from '@src/css_config/colorConstants.js';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: `${colors.rs_primary} 0% 0% no-repeat padding-box`,
    height: 30,
    padding: 0,
    paddingLeft: 16,
    color: `${colors.text_white}`,
    ...fontPoppins.sSm,
  },
  [`&.${tableCellClasses.body}`]: {
    color: `${colors.rs_skipped}`,
    ...fontPoppins.rMd,
  },
}));

const ResponseHeaders = (props) => {
  const [projectTypeList, setProjectTypeList] = useState([]);
  useEffect(() => {
    if (props.responseHeadersValue === '') {
      setProjectTypeList([]);
    } else {
      setProjectTypeList(props.responseHeadersValue);
    }
  }, [props.responseHeadersValue]);
  return (
    <div
      className="flex flex-wrap border-light-red-300 relative parent-container-response-header bg-white"
      id="journal-scroll"
    >
      <div className="cont_div relative">
        {props.responseHeaders === 'enable' && (
          <Paper sx={{ width: '100%', boxShadow: 'none', marginTop: '10px' }}>
            <TableContainer sx={{ borderRadius: '14px 14px 0 0' }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{ [`& .${tableCellClasses.root}`]: { width: '35%', padding: '6px 15px' } }}
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell className="text-xs ml-2 select-none">
                      <div className="flex">
                        <span>Key</span>
                        <span className="key-later"></span>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell className="ml-2 select-none">
                      <div className="flex justify-between">
                        <span className="">Value</span>
                        <span className="mr-4">| Total : {props?.responseHeadersValue?.length}</span>
                      </div>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    height: props.requestSectionCollapse ? 254 : 249,
                    display: 'block',
                    width: '200%',
                    borderBottom: `2px solid ${colors.light_gray_50}`,
                    borderLeft: `2px solid ${colors.light_gray_50}`,
                    borderRight: `2px solid ${colors.light_gray_50}`,
                  }}
                ></TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
        {Array.isArray(projectTypeList) && projectTypeList.length > 0 ? (
          <Paper sx={{ width: '100%', boxShadow: 'none', marginTop: '10px' }}>
            <TableContainer sx={{ borderRadius: '6px 14px 0 0' }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: '2px solid #F2F2F2',
                    width: '50%',
                    padding: '6px 15px',
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell className="text-xs ml-2 select-none">
                      <div className="flex">
                        <span>Key</span>
                        <span className="key-later"></span>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell className="ml-2 select-none">
                      <div className="flex justify-between">
                        <span className="">Value</span>
                        <span className="mr-5">| Total : {props?.responseHeadersValue?.length}</span>
                      </div>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    maxHeight: props.requestSectionCollapse ? 376 : 240,
                    overflowY: 'auto',
                    display: 'block',
                    width: '200%',
                  }}
                  className="Response-header-scrollbar"
                >
                  {projectTypeList.map((row, index) => (
                    <TableRow key={`${row.id}`}>
                      <TableCell
                        component="th"
                        scope="row"
                        className="flex flex-wrap response-header-name"
                        sx={{ color: colors.text_black, ...fontPoppins.rSm, opacity: '1' }}
                      >
                        <div className="flex flex-row" style={{ paddingLeft: '0px' }}>
                          <span>{row.name}</span>
                        </div>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colors.text_black, ...fontPoppins.rSm, opacity: '1' }}
                      >
                        <div className="flex flex-row tabelCellContentValue">
                          <span>{row.value}</span>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        ) : (
          projectTypeList
        )}
      </div>
    </div>
  );
};

export default ResponseHeaders;

import SeperatedBarChart from './SeperatedBarChart';

const labelKeys = ['pass', 'fail', 'notRun', 'skip', 'warning', 'terminated'];
const labels = ['Pass', 'Fail', 'Not Run', 'Skipped', 'Warning', 'Terminated'];
const colors = ['#79B62F', '#C50303', '#727171', '#727171', '#EFBB07', '#C50303'];
const pivotIndex = 3;

function ExecutionSuitesStatusChart({ suiteData }) {
  return (
    <>
      {suiteData && (
        <SeperatedBarChart
          labels={labels}
          colors={colors}
          series={labelKeys.map((labelKey) => suiteData?.[labelKey] || 0)}
          pivotIndex={pivotIndex}
          roundedBorders
          roundedDirection="bl"
        />
      )}
    </>
  );
}

export default ExecutionSuitesStatusChart;

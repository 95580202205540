import { HighlightText } from '@src/util/common_utils';
import { Tooltip } from '@mui/material';
import { getSelectedProject } from '@src/service/common_service';
import { getScriptSpecificDefectsReq } from '@api/api_services';
import { getFailedSteps } from '@pages/results/components/script-result/script-result-util';
import cx from 'classnames';
import styles from '@pages/configuration/DefectConfig/defect-config.module.scss';

export async function getScriptSpecificDefects(payload) {
  try {
    const projectId = getSelectedProject()?.id || '';
    const {
      data: { responseObject },
    } = await getScriptSpecificDefectsReq(projectId, payload);
    return {
      dataList: responseObject || [],
    };
  } catch (error) {
    console.error('GET_SCRIPT_SPECIFIC_DEFECTS_REQ : ', error);
  }
}

export const getStepsNameResultTree = (nodeObj) => {
  const stepsNameData = [];
  if (nodeObj?.data?.dependantExecutionEntityResponses?.length) {
    nodeObj.data.dependantExecutionEntityResponses.forEach((step) => {
      stepsNameData.push({ name: step.name });
    });
  }
  if (nodeObj?.data?.preConditions?.stepResults?.length) {
    nodeObj.data?.preConditions?.stepResults.forEach((step) => {
      stepsNameData.push({ name: step.name });
    });
  }
  if (nodeObj?.data?.stepResults?.length) {
    nodeObj.data.stepResults.forEach((step) => {
      stepsNameData.push({ name: step.name });
    });
  }
  if (nodeObj?.data.postConditions?.stepResults?.length) {
    nodeObj?.data.postConditions?.stepResults.forEach((step) => {
      stepsNameData.push({ name: step.name });
    });
  }
  return stepsNameData;
};

const stepsToLoop = (stepResults, steps, data) => {
  stepResults.find((step) => {
    steps.push({ name: step?.name });
    return step.stepId === data.stepId;
  });
};
export const getStepsNameAutomationSteps = (data, execResp) => {
  const getSteps = (stepsData) => {
    if (stepsData) {
      return stepsData.map((step) => ({ name: step?.name }));
    }
    return [];
  };
  let steps = [];
  if (data.type === 'dependsOn') {
    stepsToLoop(execResp?.dependantExecutionEntityResponses, steps, data);
  }
  if (data.type === 'steps') {
    steps = [
      ...getSteps(execResp?.dependantExecutionEntityResponses),
      ...getSteps(execResp?.preConditions?.stepResults),
    ];
    stepsToLoop(execResp?.stepResults, steps, data);
  }
  if (data.type === 'preCondition') {
    steps = [...getSteps(execResp?.dependantExecutionEntityResponses)];
    stepsToLoop(execResp?.preConditions?.stepResults, steps, data);
  }
  if (data.type === 'postCondition') {
    steps = [
      ...getSteps(execResp?.dependantExecutionEntityResponses),
      ...getSteps(execResp?.preConditions?.stepResults),
      ...getSteps(execResp?.stepResults),
    ];
    stepsToLoop(execResp?.postConditions?.stepResults, steps, data);
  }
  return steps;
};

export const getStepsNameResultSteps = (nodeObj, scriptData) => {
  let allSteps = [];
  if (nodeObj.section === 'dependsOnScripts') {
    allSteps = [...scriptData?.dependantExecutionEntityResponses];
  } else if (nodeObj.section === 'steps') {
    if (scriptData?.dependantExecutionEntityResponses) {
      allSteps = [...scriptData?.dependantExecutionEntityResponses];
    }
    if (scriptData?.preConditions?.stepResults) {
      allSteps = [...allSteps, ...scriptData?.preConditions?.stepResults];
    }
    if (scriptData?.stepResults?.length > 0) {
      allSteps = [...allSteps, ...scriptData?.stepResults];
    }
    if (scriptData?.postConditions?.stepResults) {
      allSteps = [...allSteps, ...scriptData?.postConditions?.stepResults];
    }
  }
  return [...getFailedSteps(allSteps, nodeObj.id)];
};

export const getDefectListColumns = (searchTextHighlight, handleOnIdClick) => [
  {
    Header: 'ID',
    accessor: '_id',
    width: '85px',
    disableFilters: true,
    Cell: ({ value, row }) => {
      const isClickable = row?.original?.type === 'Defect' || !row?.original?.type;
      return (
        <span
          className={cx('float-left truncate', {
            [styles['clickable-text']]: isClickable,
          })}
        >
          <span
            className={isClickable ? 'cursor-pointer' : ''}
            onClick={() => {
              if (isClickable) {
                handleOnIdClick(value, row);
              }
            }}
          >
            <HighlightText text={value} highlight={searchTextHighlight} />{' '}
          </span>
        </span>
      );
    },
  },
  {
    Header: 'EXECUTION ID',
    accessor: 'scriptRunDetails.executionId',
    width: '115px',
    disableFilters: true,
    Cell: ({ value }) => (
      <span className="float-left truncate w-28">
        <HighlightText text={value} highlight={searchTextHighlight} />
      </span>
    ),
  },
  {
    Header: 'SUMMARY',
    accessor: 'defectDetails.Summary',
    width: '205px',
    disableFilters: true,
    Cell: ({ value, row }) => {
      return value ? (
        <Tooltip title={value}>
          <span className="float-left truncate w-48">
            <HighlightText text={value || '--'} highlight={searchTextHighlight} />
          </span>
        </Tooltip>
      ) : (
        <span className="float-left truncate w-48">
          <HighlightText text={value || '--'} highlight={searchTextHighlight} />
        </span>
      );
    },
  },
  {
    Header: 'CREATED ON',
    accessor: 'createdOn',
    width: '150px',
    disableFilters: true,
    Cell: ({ value }) => <span className="float-left truncate w-36">{value}</span>,
  },
  {
    Header: 'CREATED BY',
    accessor: 'createdByUname',
    width: '120px',
    disableFilters: true,
    Cell: ({ value }) => {
      return (
        <Tooltip title={value}>
          <p className="float-left truncate w-28">
            <HighlightText text={value || '--'} highlight={searchTextHighlight} />
          </p>
        </Tooltip>
      );
    },
  },
];

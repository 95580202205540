/*= @author Aravindhakumar Raman
 * - description - Program Elements Section
 * - description - Ace Editor screen
 */
import React, { Component, Fragment } from 'react';
import '../css/Ace_Editor.css';
import '../css/common_attribute.css';
import AceEditor from 'react-ace-builds';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-beautify';
import 'react-ace-builds/webpack-resolver-min';
import 'brace/ext/searchbox';
import 'brace/keybinding/emacs';
import 'brace/keybinding/vim';
import 'brace/ext/language_tools';
import 'brace/ext/settings_menu';
import { ACEEDITOR_CONSTANT } from '../Js/constant';

export default class Ace_Editor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorHeight: ACEEDITOR_CONSTANT.DEFAULT_EMPTY,
      editorWidth: ACEEDITOR_CONSTANT.DEFAULT_EMPTY,
      change: ACEEDITOR_CONSTANT.DEFAULT_EMPTY,
      readOnly: ACEEDITOR_CONSTANT.EDITOR_FALSE,
      defaultValue: ACEEDITOR_CONSTANT.DEFAULT_VALUE,
    };
  }

  componentDidMount() {
    this.onLoad();
  }

  /* onLoad Method for Add,Edit,view All Screens
     return the codevalue and Editor properties
  */
  onLoad = (value, event) => {
    if (this.props.modeValue == ACEEDITOR_CONSTANT.MODEVALUE_ADD) {
      this.setState({
        editorHeight: ACEEDITOR_CONSTANT.EDITOR_HEIGHT_FULL,
        editorWidth: ACEEDITOR_CONSTANT.EDITOR_WIDTH_FULL,
        readOnly: ACEEDITOR_CONSTANT.EDITOR_FALSE,
        change: ACEEDITOR_CONSTANT.DEFAULT_VALUE,
      });
      this.props.getCodeValue(this.state.defaultValue);
    } else if (this.props.modeValue == ACEEDITOR_CONSTANT.MODEVALUE_ADD_SUB) {
      this.setState({
        editorHeight: ACEEDITOR_CONSTANT.EDITOR_HEIGHT_FULL,
        editorWidth: ACEEDITOR_CONSTANT.EDITOR_WIDTH_FULL,
        readOnly: ACEEDITOR_CONSTANT.EDITOR_FALSE,
        change: ACEEDITOR_CONSTANT.DEFAULT_VALUE,
      });
      this.props.getCodeValue(this.state.defaultValue);
    } else if (this.props.modeValue == ACEEDITOR_CONSTANT.MODEVALUE_EDIT) {
      let Editvalues = this.props.Editvalues;
      this.setState({
        editorHeight: ACEEDITOR_CONSTANT.EDITOR_HEIGHT_FULL,
        editorWidth: ACEEDITOR_CONSTANT.EDITOR_WIDTH_FULL,
        change: Editvalues,
        readOnly: this.props?.disable ? this.props?.disable : ACEEDITOR_CONSTANT.EDITOR_FALSE,
      });
      this.props.getCodeValue(this.props.Editvalues);
    } else if (this.props.screenType == ACEEDITOR_CONSTANT.MODEVALUE_DETAILPOPUP) {
      this.setState({
        editorHeight: ACEEDITOR_CONSTANT.EDITOR_HEIGHT_HALF,
        editorWidth: ACEEDITOR_CONSTANT.EDITOR_WIDTH_HALF,
        change: this.props.codeModalValue,
        readOnly: ACEEDITOR_CONSTANT.EDITOR_TRUE,
      });
    } else if (this.props.screenType == ACEEDITOR_CONSTANT.MODEVALUE_VIEWSCREEN) {
      this.setState({
        editorHeight: ACEEDITOR_CONSTANT.EDITOR_HEIGHT_HALF,
        editorWidth: ACEEDITOR_CONSTANT.EDITOR_WIDTH_HALF,
        change: this.props.codeviewValue,
        readOnly: ACEEDITOR_CONSTANT.EDITOR_TRUE,
      });
    } else if (this.props.screenType == ACEEDITOR_CONSTANT.MODEVALUE_VIEWONLY) {
      this.setState({
        editorHeight: ACEEDITOR_CONSTANT.EDITOR_HEIGHT_HALF,
        editorWidth: ACEEDITOR_CONSTANT.EDITOR_WIDTH_QUATER,
        change: this.props.codeviewValue,
        readOnly: ACEEDITOR_CONSTANT.EDITOR_TRUE,
      });
    }
  };

  /*Update Method for Add,Edit Screens
    return the Updated codevalue
 */
  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.change != this.state.change) {
      return ACEEDITOR_CONSTANT.EDITOR_TRUE;
    } else {
      return ACEEDITOR_CONSTANT.EDITOR_FALSE;
    }
  }

  /*Onchange Method for Add,Edit Screens
    return the Updated codevalue
 */
  onChangeValue = (value, event) => {
    if (
      this.props.modeValue == ACEEDITOR_CONSTANT.MODEVALUE_ADD ||
      this.props.modeValue == ACEEDITOR_CONSTANT.MODEVALUE_EDIT ||
      this.props.modeValue == ACEEDITOR_CONSTANT.MODEVALUE_ADD_SUB
    ) {
      this.setState({
        change: value,
      });
      this.props.getCodeValue(this.state.change);
    }
  };

  render() {
    return (
      <Fragment>
        <AceEditor
          mode={ACEEDITOR_CONSTANT.MODE}
          theme={ACEEDITOR_CONSTANT.THEME}
          name={ACEEDITOR_CONSTANT.UNIQUE_ID_OF_DIV}
          height={this.state.editorHeight}
          width={this.state.editorWidth}
          showGutter={ACEEDITOR_CONSTANT.EDITOR_TRUE}
          readOnly={this.state.readOnly}
          fontSize={this.props.analyticsClass ? ACEEDITOR_CONSTANT.ANALYTICS_FONTSIZE : ACEEDITOR_CONSTANT.FONTSIZE}
          minLines={ACEEDITOR_CONSTANT.MINILINES}
          maxLines={ACEEDITOR_CONSTANT.MAXLINES}
          onChange={this.onChangeValue}
          editorProps={{ $blockScrolling: ACEEDITOR_CONSTANT.EDITOR_TRUE }}
          setOptions={{
            enableBasicAutocompletion: ACEEDITOR_CONSTANT.EDITOR_TRUE,
            enableLiveAutocompletion: ACEEDITOR_CONSTANT.EDITOR_TRUE,
            enableSnippets: ACEEDITOR_CONSTANT.EDITOR_TRUE,
            showLineNumbers: ACEEDITOR_CONSTANT.EDITOR_TRUE,
          }}
          value={this.state.change}
        />
      </Fragment>
    );
  }
}

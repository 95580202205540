import { resetActivationLink } from '@api/api_services';
import { ReactComponent as CircleIcon } from '@assets/circle-outline-icon.svg';
import righticon from '@assets/right-icon.svg';
import { ReactComponent as BackArrow } from '@assets/svg-imports/BackArrowOrange.svg';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import commonStyles from '@pages/auth/common.module.scss';
import '@pages/auth/login.css';
import { GLOBAL_CONSTANTS, REGISTRATION_CONSTANTS } from '@src/common/ui-constants';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import CommonPage from '../common/CommonPage';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '0px',
    justifyContent: 'center',
    backgroundColor: 'unset',
  },
  activationAlert: {
    '& .MuiAlert-icon': {
      marginTop: 5,
    },
    '&.MuiAlert-standardSuccess': {
      backgroundColor: 'transparent',
      marginLeft: '70px',
    },
  },
}));

const alertStyles = makeStyles((theme) => ({
  root: {
    gap: '0px',
    top: '11%',
    right: '12.5%',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'unset',
  },
  activationAlert: {
    '& .MuiAlert-icon': {
      marginTop: 5,
    },
    '&.MuiAlert-standardSuccess': {
      backgroundColor: 'transparent',
      marginLeft: '70px',
    },
  },
}));

export default function RegistrationSuccessful(props) {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState({ open: false, message: '' });
  const [isPaid, setIsPaid] = useState(false);
  const [createUpdateCalled, setCreateUpdateCalled] = useState(true);
  const [showActiveUserText, setActiveUserText] = useState({
    open: false,
    message: '',
  });
  let history = useHistory();

  const classes = useStyles();
  const alertClasses = alertStyles();

  function verifyIsUserActive(response) {
    if (response.responseCode === 400 && response.message === GLOBAL_CONSTANTS.USER_ACTIVATED_MESSAGE) {
      setActiveUserText({ open: true, message: response.message });
    } else if (response.responseObject) {
      setTimeout(() => {
        setCreateUpdateCalled(true);
      }, 10000);
      setSuccess({ open: true });
    }
  }

  function redirectToSignIn() {
    history.push('/signin');
  }

  function resentActivation(values) {
    if (createUpdateCalled) {
      setCreateUpdateCalled(false);
      const data = {
        emailId: email,
      };
      resetActivationLink(data)
        .then((res) => {
          if (res.data) {
            verifyIsUserActive(res.data);
          }
          setTimeout(() => {
            setSuccess({ open: false });
          }, 5000);
        })
        .catch((error) => {
          console.error('resend activation link error', error);
        });
    }
  }
  useEffect(() => {
    const search = window.location.search;
    const email = new URLSearchParams(search).get('email');
    const plan = new URLSearchParams(search).get('plan');
    setEmail(email);
    setIsPaid(plan?.toLowerCase() === 'c-professional');
  }, []);
  return (
    <CommonPage wrapperClassname={`common-padding`} marginTop={success.open ? '-mt-4' : 'mt-9'}>
      <div className="">
        {success.open && (
          <Alert
            className={cx(alertClasses.root, 'fontPoppinsRegularLg')}
            iconMapping={{
              success: <CircleIcon fontSize="inherit" className="alertIconRegSuc" />,
            }}
          >
            <span className="activationSuccess  fontPoppinsRegularLg">
              {GLOBAL_CONSTANTS.RESEND_ACTIVATION_LINK_SENT_SUCCESSFULLY}
            </span>
          </Alert>
        )}
        {showActiveUserText.open && (
          <Alert
            className={cx(classes.root, 'fontPoppinsRegularLg')}
            iconMapping={{
              success: <CircleIcon fontSize="inherit" className="alertIconRegSuc" />,
            }}
          >
            <span className="activationSuccess  fontPoppinsSemiboldLg">{showActiveUserText.message}</span>
          </Alert>
        )}
        <div className="">
          <div className="">
            <img alt="right" src={righticon} className={cx('m-auto mb-2', success.open && 'mt-20')} />
          </div>
          {/* {isPaid && (
            <p className="fontPoppinsRegularLg text-center">
              Thank you for the payment.
            </p>
          )} */}
          <div className={cx('text-center fontPoppinsSemibold-size-26 mb-3.5', commonStyles['text-green'])}>
            {REGISTRATION_CONSTANTS.REGISTRATION_SUCCESSFUL}
          </div>
          {showActiveUserText.open ? (
            <div className="flex flex-col gap-4 items-center">
              <Alert severity="info" className="fontPoppinsRegularSm">
                {GLOBAL_CONSTANTS.ACTIVATED_MESSAGE}
              </Alert>
              <div
                className={cx('cursor-pointer', commonStyles['btn-bg-orange'], commonStyles['common-btn'])}
                onClick={redirectToSignIn}
              >
                &nbsp;{GLOBAL_CONSTANTS.PROCEED_TO_SIGN_IN}
              </div>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center gap-4  fontPoppinsMediumMd">
              <div className="flex flex-col justify-center gap-4 ">
                <div className="text-center fontPoppinsRegularMd">
                  <strong>{REGISTRATION_CONSTANTS.CONGRATULATIONS}</strong>
                  {REGISTRATION_CONSTANTS.REG_SUCCESS_MESSAGE}
                </div>
                <div className="text-center fontPoppinsRegularMd">{REGISTRATION_CONSTANTS.WELCOME_ABOARD}</div>
                <div className="text-center fontPoppinsRegularMd pl-3.5 pr-3.5">
                  {REGISTRATION_CONSTANTS.REG_ACTIVATE_MESSAGE}
                </div>
              </div>
              <div className="flex flex-col justify-center gap-4">
                <button
                  className={cx('cursor-pointer', commonStyles['btn-bg-orange'], commonStyles['common-btn'])}
                  onClick={resentActivation}
                  onBlur={() => {
                    setCreateUpdateCalled(true);
                  }}
                >
                  <span className="fontPoppinsSemiboldLg">{GLOBAL_CONSTANTS.RESEND_ACTIVATION_LINK}</span>
                </button>
                <button
                  className={cx(commonStyles['btn-bg-white'], commonStyles['common-btn'])}
                  onClick={redirectToSignIn}
                >
                  <BackArrow />
                  <span className="fontPoppinsSemiboldLg">{GLOBAL_CONSTANTS.BACK_TO_SIGN_IN}</span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </CommonPage>
  );
}

import ChartCard from '../components/ChartCard';
import Chart from 'react-apexcharts';
import { useEffect, useMemo, useRef } from 'react';
import DashboardStyle from '../styles/dashboard.module.scss';
import cx from 'classnames';
import { fontPoppins } from '@src/css_config/fontConstants';
const REST_FILLED_COLOR = '#7388A95A';
const REST_FILLED_LABEL = 'Remaining';

function getColumnSeries(currentSeriesIndex, series) {
  const seriesData = series.map((seriesValue, seriesIndex) => (seriesIndex === currentSeriesIndex ? seriesValue : 0));
  if (!seriesData.some((number) => number > 0)) {
    seriesData[currentSeriesIndex] = 0.1;
  }
  return seriesData;
}

function getRestSeries(maxNumberOfData, series) {
  return series.map((seriesValue) => maxNumberOfData - seriesValue);
}

function handleChartElementsStyle(element) {
  if (element) {
    const restSeriesLegendElement = element.querySelector(
      `.apexcharts-legend-series[seriesname="${REST_FILLED_LABEL}"]`
    );

    if (restSeriesLegendElement) {
      restSeriesLegendElement.style.display = 'none';
    }

    const titleTextElement = element.querySelector('.apexcharts-title-text');
    const parentElementWidth = element.offsetWidth;

    if (titleTextElement && parentElementWidth) {
      titleTextElement.setAttribute('x', (parentElementWidth * 55) / 100);
    }
  }
}

function customTooltipFormatterForStackFilledChart({ seriesIndex, dataPointIndex, series, w }) {
  const { seriesNames, colors } = w.globals;
  return `
    <div class="px-4 py-2 border-2 rounded-lg" style="border-color: ${colors?.[seriesIndex]}">
      <span>${seriesNames?.[seriesIndex]} : ${Math.floor(series?.[seriesIndex][dataPointIndex])}</span>
    </div>
  `;
}

function StackFilledBarChart({
  title = '',
  labels = [],
  colors = [],
  series = [],
  yAxisLabel = '',
  customTooltipFormatter = customTooltipFormatterForStackFilledChart,
}) {
  const tooltipEnabled = series.some((value) => value >= 0.1);
  const wrapperRef = useRef();
  function handleTooltip(a, b, { seriesIndex }) {
    if (wrapperRef.current) {
      const element = wrapperRef.current;
      const elementRect = element?.getBoundingClientRect();
      const barSeriesElement = element.querySelector(`.apexcharts-series[rel="${seriesIndex + 1}"]`);
      const innerChartElement = element.querySelector('.apexcharts-inner');
      const xAxisElement = element.querySelector('.apexcharts-xaxis');
      if (barSeriesElement) {
        const barElement = barSeriesElement.querySelectorAll('path')?.[seriesIndex];
        if (barElement) {
          const barRect = barElement.getBoundingClientRect();
          const totalBottomOfTooltip =
            elementRect.height -
            innerChartElement.getBoundingClientRect().height +
            xAxisElement.getBoundingClientRect().height +
            (barRect?.height || 0) -
            20;
          const tooltipElement = element.querySelector('.apexcharts-tooltip');
          const left = barRect.x - elementRect.x;
          if (tooltipElement?.style) {
            if (tooltipElement?.style?.bottom !== `${totalBottomOfTooltip}px`) {
              tooltipElement.style.bottom = `${totalBottomOfTooltip}px`;
            }
            if (tooltipElement?.style?.left !== `${left}px`) {
              tooltipElement.style['margin-left'] = `${left}px`;
            }
          }
        }
      }
    }
  }
  const options = useMemo(
    () => ({
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      chart: {
        stacked: true,
        stackType: 'normal',
        toolbar: {
          show: false,
        },
        redrawOnWindowResize: true,
        redrawOnParentResize: true,
        events: {
          mounted: () => {
            handleChartElementsStyle(wrapperRef?.current);
          },
          updated: () => {
            handleChartElementsStyle(wrapperRef?.current);
          },
          dataPointMouseEnter: handleTooltip,
          mouseMove: handleTooltip,
        },
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
          },
        },
      },
      colors: [...colors, REST_FILLED_COLOR],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50px',
          borderRadius: 4,
        },
      },
      xaxis: {
        categories: [''],
        crosshairs: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        enabled: tooltipEnabled,
        followCursor: true,
        inverseOrder: true,
        enabledOnSeries: Array.from(series.keys()),
        custom: customTooltipFormatter,
      },
      legend: {
        onItemClick: {
          toggleDataSeries: false,
        },
        show: true,
        position: 'right',
        formatter: (seriesName, options) => {
          return seriesName === REST_FILLED_LABEL
            ? ''
            : `${seriesName} : <span class="fontPoppinsSemiboldXs">${Math.floor(
                options.w.globals.series[options.seriesIndex][options.seriesIndex]
              )}<span>`;
        },
        horizontalAlign: 'center',
        ...fontPoppins.rXs,
        markers: {
          radius: 50,
          fillColors: [...colors, 'transparent'],
        },
        offsetY: 10,
        itemMargin: {
          horizontal: 5,
          vertical: 1,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 8,
        colors: ['transparent'],
      },
      yaxis: {
        title: {
          text: yAxisLabel,
          style: {
            ...fontPoppins.rXs,
            color: '#7C828A',
          },
        },
      },
      title: {
        text: title,
        margin: 10,
        offsetY: 0,
        floating: true,
        style: {
          ...fontPoppins.sDm,
          color: '#3C3838',
        },
      },
    }),
    [colors, series, title, yAxisLabel, customTooltipFormatter, tooltipEnabled]
  );

  const maxNumberOfData = (Math.max(...series) || 1) * 1.5;

  const seriesData = useMemo(
    () => [
      ...labels.map((labelValue, seriesIndex) => {
        return {
          name: labelValue,
          data: getColumnSeries(seriesIndex, series),
        };
      }),
      {
        name: REST_FILLED_LABEL,
        data: getRestSeries(maxNumberOfData, series),
      },
    ],
    [labels, series, maxNumberOfData]
  );

  useEffect(() => {
    wrapperRef.current && handleChartElementsStyle(wrapperRef.current);
  });
  return (
    <div ref={wrapperRef}>
      <ChartCard
        className={cx(DashboardStyle['client-section-small-chart'], DashboardStyle['client-access-chart'])}
        roundedBorders={false}
      >
        <Chart type="bar" options={options} series={seriesData} height="100%" />
      </ChartCard>
    </div>
  );
}

export default StackFilledBarChart;

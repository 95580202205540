import { Tooltip } from '@material-ui/core';
import '@src/css_config/executionruntable.scss';

function StatusBadge({
  label = 'Public',
  variant = 'primary',
  placement = 'bottom',
  showTooltip = true,
  tooltipTitle = '',
}) {
  const statusBadgeLabel = (
    <label className={`status-button ${variant}Status fontPoppinsSemiboldXs flex items-center`}> {label} </label>
  );
  return showTooltip ? (
    <Tooltip title={tooltipTitle} placement={placement}>
      {statusBadgeLabel}
    </Tooltip>
  ) : (
    statusBadgeLabel
  );
}

export default StatusBadge;

import React from 'react';
import { createRoot } from 'react-dom/client';
import InfoModal from './modals/info-modal';

const UserLeaveConfirmation = (message, callback, isModalOpen, closeInfoModal) => {
  const container = document.createElement('div');

  container.setAttribute('custom-confirm-view', '');

  const handleConfirm = (callbackState) => {
    sessionStorage.removeItem('infoModalOpen');
    createRoot(container).unmount();
    callback(true);
    closeInfoModal(false);
  };

  const handleCancel = (callbackState) => {
    createRoot(container).unmount();
    callback(false);
    closeInfoModal(true);
    sessionStorage.removeItem('infoModalOpen');
  };

  document.body.appendChild(container);
  const { headerTitle, subHeaderTitle, bodyText, subBodyText } = JSON.parse(message);

  if (!sessionStorage.getItem('infoModalOpen') && isModalOpen) {
    sessionStorage.setItem('infoModalOpen', true);
    const infoModalElement = (
      <InfoModal
        open={isModalOpen}
        closeModal={handleCancel}
        onConfirm={handleConfirm}
        headerTitle={headerTitle}
        subHeaderTitle={subHeaderTitle}
        bodyText={bodyText}
        subBodyText={subBodyText}
      />
    );
    createRoot(container).render(<>{infoModalElement}</>);
  }
};

export default UserLeaveConfirmation;

import React, { useEffect, useState, useContext } from 'react';
import ReactTable from '@src/pages/common/Table/ReactTable';
import ParamterModal from '../modals/parameter_modal';
import { deleteParameter, gellAllParameter, getParameter, getSingleUserRequest } from '@api/api_services';
import DeleteModal from '@pages/common/delete_modal';
import { DeleteOutlined, EditOutlined } from '@material-ui/icons';
import UserDetailsModal from '@pages/test-development/shared/user-details-modal';
import VariableDetailsModal from '../modals/variable_details_modal';
import { Tooltip } from '@material-ui/core';
import { CommonLoader } from '@src/pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import ContextMenu from '@pages/common/context_menu';
import SearchBar from '@pages/analytics/common/search-bar';
import { HighlightText, isEmptyValue, isValueIncludes } from '@util/common_utils';
import NoResultsFound from '@pages/common/Table/NoResultFound';
import cx from 'classnames';

function Parameter(props) {
  const search = window.location.search;
  const id = new URLSearchParams(search).get('id');
  const parentId = new URLSearchParams(search).get('parentId');
  const defaultStepGroup = new URLSearchParams(search).get('defaultStepGroup');
  const isDefaultStepGroup = defaultStepGroup === 'true';

  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [openParamaterModal, setOpenParamaterModal] = useState(false);
  const [openDetailsParamaterModal, setOpenDetailsParamaterModal] = useState(false);
  const [parameters, setParameters] = React.useState([]);
  const [message, setMessage] = useState('');
  let [isLoading, setIsLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [reloadTree, setReloadTree] = useState(true);
  const [mode, setMode] = useState('ADD');
  const [paramaterdata, setparamaterData] = useState();
  const [userDetails, setUserDetails] = React.useState([]);
  let [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);
  let [isHighlight, setIsHighlight] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');

  const reloadTreeData = (value) => {
    setReloadTree(value);
  };

  useEffect(() => {
    if (reloadTree) {
      getAllParameter();
      setReloadTree(false);
    }
  }, [reloadTree]);

  const contextMenuData = [
    {
      option: 'Details',
    },
  ];

  function contextMenuFunction(event, ele) {
    if (event.target.textContent === 'Details') {
      getParamtersDetails(ele);
    }
  }
  const deleteparameter = () => {
    deleteParameter(parentId, id, paramaterdata._id).then((response) => {
      if (response.data.responseCode === 200) {
        setOpenDeleteModal(false);
        reloadTreeData(true);
        props.MyAlert.success(`${paramaterdata.name} parameter deleted successfully`);
      }
    });
  };
  function getAllParameter() {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    setIsLoading(true);
    gellAllParameter(parentId, id)
      .then((response) => {
        stopProgress();
        if (response.data.responseObject) {
          setParameters(response.data.responseObject);
          startOrResumeProgress({
            stopAt: 100,
            intervalDuration: 10,
          });
          setIsLoading(false);
        } else {
          resetProgress();
          setIsLoading(false);
          setParameters([]);
        }
      })
      .catch((err) => {
        resetProgress();
        console.error('GET_ALL_PARAMETERS : ', err);
      });
  }

  const getParamtersDetails = (res) => {
    getParameter(parentId, id, res._id).then((response) => {
      setparamaterData(response.data.responseObject);
      setOpenDetailsParamaterModal(true);
    });
  };
  const closeModal = () => {
    if (openParamaterModal) {
      setOpenParamaterModal(false);
    } else if (openDetailsParamaterModal) {
      setOpenDetailsParamaterModal(false);
    } else if (openDeleteModal) {
      setOpenDeleteModal(false);
    } else if (openUserDetailsModal) {
      setOpenUserDetailsModal(false);
    }
  };

  const openModifiedBy = (value) => {
    getSingleUserRequest(value.modifiedBy).then((results) => {
      if (results.data.responseCode === 200 && results.data.responseObject) {
        setUserDetails(results.data.responseObject);
        setOpenUserDetailsModal(true);
      }
    });
  };

  // TODO :: handle Search filter :: START

  const [userSearched, setuserSearched] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(parameters);
    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [parameters]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(parameters);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let [openSearch, setOpenSearch] = useState(false);
  let handleSearchClose = () => {
    setOpenSearch(false);
    setSearchTextHighlight('');
    setuserSearched('');
    setData(parameters);
    setIsHighlight(!isHighlight);
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = parameters.filter(({ name, modifiedByUname }) => {
          return isValueIncludes(e, name) || isValueIncludes(e, modifiedByUname);
        });
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
        setData(newData);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  // TODO :: handle Search filter :: END

  const columns = React.useMemo(
    () => [
      {
        Header: 'NAME',
        accessor: 'name',
        disableFilters: true,
        Cell: (e) => (
          <span
            className="text-blue-700 cursor-pointer"
            title={e.value}
            onClick={() => {
              getParamtersDetails(e.row.original);
            }}
          >
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </span>
        ),
      },
      {
        Header: 'TYPE',
        accessor: 'type',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div
              className="table-non-link-color-common float-left ml-3 table-font-style-common"
              title={value.charAt(0).toUpperCase() + value.slice(1)}
            >
              {value.charAt(0).toUpperCase() + value.slice(1)}
            </div>
          );
        },
      },
      {
        Header: 'USED IN STEP',
        accessor: 'usedInStep',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common float-left ml-3 table-font-style-common">
              {value?.length > 0 ? value?.join(', ') : '--'}
            </div>
          );
        },
      },
      {
        Header: 'MODIFIED BY',
        accessor: 'modifiedByUname',
        disableFilters: true,
        Cell: (e) => (
          <span
            onClick={() => {
              openModifiedBy(e.cell.row.original);
            }}
            className="hyper_link_color cursor-hand  float-left table-font-style-common text-blue-700"
          >
            <HighlightText text={e.value} highlight={searchTextHighlight} />
          </span>
        ),
      },
      {
        Header: 'ACTIONS',
        accessor: 'Actions',
        disableFilters: true,
        Cell: (e) => {
          return (
            <div className="table-non-link-color-common rs-icon-alignment-font-style">
              <div className="actionIcons">
                {isEditable && (
                  <>
                    <Tooltip title="Edit" placement="top">
                      <EditOutlined
                        className={cx(
                          'float-left cursor-pointer text-base mr-3 text-blue-700',
                          isDefaultStepGroup && 'opacity-50'
                        )}
                        onClick={() => {
                          setMode('Edit');
                          setparamaterData(e.row.original);
                          setOpenParamaterModal(!isDefaultStepGroup);
                        }}
                      />
                    </Tooltip>
                    {hasFullAccess && (
                      <Tooltip title="Delete" placement="top">
                        <DeleteOutlined
                          className={cx(
                            'float-left cursor-pointer text-base mr-3 text-blue-700',
                            isDefaultStepGroup && 'opacity-50'
                          )}
                          onClick={() => {
                            setparamaterData(e.row.original);
                            setMessage(`${e.row.original.name} parameter`);
                            setOpenDeleteModal(!isDefaultStepGroup);
                          }}
                        />
                      </Tooltip>
                    )}
                  </>
                )}
                <div className="float-left project-action text-blue-700 text-base cursor-pointer">
                  <ContextMenu
                    contextMenuData={e.row.original ? contextMenuData : [...contextMenuData]}
                    func={(event) => {
                      contextMenuFunction(event, e.row.original);
                    }}
                  />
                </div>
              </div>
            </div>
          );
        },
      },
    ],
    [isHighlight]
  );

  const { isEditable, hasFullAccess } = React.useMemo(
    () => ({
      isEditable: window.permission?.isEditAccess('repository'),
      hasFullAccess: window.permission?.isFullAccess('repository'),
    }),
    []
  );

  return (
    <div className="content-wrapper">
      <div className="content_area_style" id="journal-scroll">
        <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
          <label className="project_label text-sm flex-auto">Parameters</label>
          {isEditable && (
            <div className="pr-4">
              <div className="flex flex-row items-center">
                <SearchBar
                  open={openSearch}
                  searchValue={userSearched}
                  handleSearch={handleSearch}
                  onSearchClose={handleSearchClose}
                  handleSearchOpen={handleSearchOpen}
                  enterKeyHandler={handleSearchSubmit}
                  disable={isEmptyValue(parameters)}
                />

                <div className=" pl-2">
                  <button
                    className="ml-3 primary-btn"
                    onClick={() => {
                      setOpenParamaterModal(true);
                      setMode('ADD');
                    }}
                    disabled={defaultStepGroup === 'false' || defaultStepGroup === 'null' ? false : true}
                  >
                    + Parameter
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="table-height overflow-auto" id="journal-scroll">
          <ReactTable data={data} columns={columns} tableHeightClass={'main-table-height'} />
          {isLoading ? (
            <CommonLoader />
          ) : isEmptyValue(parameters) ? (
            <div className="fontPoppinsRegularMd no-steps">
              <div className="">
                <p className="font-bold mb-4">{isEditable ? 'Quick Start' : 'No Parameters!!'}</p>
                {isEditable && (
                  <p className="mb-4">
                    <span className="font-bold">Step 1 :</span> Click
                    <button
                      onClick={() => {
                        setOpenParamaterModal(true);
                        setMode('ADD');
                      }}
                      disabled={defaultStepGroup === 'false' || defaultStepGroup === 'null' ? false : true}
                      className="ml-3 mr-3 primary-btn"
                    >
                      + Parameter
                    </button>
                    to start adding Parameters
                  </p>
                )}
              </div>
            </div>
          ) : (
            <> {openSearch && isEmptyValue(data) && <NoResultsFound className="mt-14" />}</>
          )}
        </div>
      </div>
      {openParamaterModal ? (
        <ParamterModal
          closeModal={closeModal}
          reloadTree={reloadTreeData}
          mode={mode}
          MyAlert={props.MyAlert}
          data={paramaterdata}
        />
      ) : null}
      {openDetailsParamaterModal ? (
        <VariableDetailsModal type="Parameter" closeModal={closeModal} data={paramaterdata} />
      ) : null}
      {openDeleteModal ? <DeleteModal message={message} closeModal={closeModal} method={deleteparameter} /> : null}
      {openUserDetailsModal ? <UserDetailsModal data={userDetails} closeModal={closeModal} /> : null}
    </div>
  );
}

export default Parameter;

import righticon from '@assets/right-icon.svg';
import commonStyles from '@pages/auth/common.module.scss';
import '@pages/auth/login.css';
import { ACTIVATION_SUCCESS_CONSTANTS, GLOBAL_CONSTANTS } from '@src/common/ui-constants';
import cx from 'classnames';
import { useHistory } from 'react-router';
import CommonPage from '../common/CommonPage';

export default function ActivationSuccess() {
  const history = useHistory();

  function redirectToSignIn() {
    history.push('/signin');
  }

  return (
    <>
      <CommonPage wrapperClassname={`common-padding`} marginTop={'mt-20'}>
        <div className="">
          <img alt="right icon" src={righticon} className="ml-auto mr-auto mb-4" />
          <div className={cx('text-center fontPoppinsSemibold-size-26 ', commonStyles['text-green'])}>
            {ACTIVATION_SUCCESS_CONSTANTS.ACTIVATION_SUCCESS}
          </div>
          <div className="text-center fontPoppinsRegularMd pt-4 pb-4 letter-spacing-25">
            {ACTIVATION_SUCCESS_CONSTANTS.ACTIVATION_MESSAGE}
          </div>
          <div className="flex items-center justify-center">
            <button
              className={cx(commonStyles['btn-bg-orange'], commonStyles['common-btn'])}
              onClick={redirectToSignIn}
            >
              <span className="fontPoppinsSemiboldLg">{GLOBAL_CONSTANTS.PROCEED_TO_SIGN_IN}</span>
            </button>
          </div>
        </div>
      </CommonPage>
    </>
  );
}

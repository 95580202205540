export const WEBSITE_ENVIRONMENT_URL_CONFIG = {
  LOCAL_HOST: 'localhost',
  DEV_URL: 'http://web.fireflink.com',
  TEST_URL: 'http://webtest.fireflink.com',
  PRE_PROD_URL: 'https://websitepreprod.fireflink.com',
  PRODUCTION1_URL: 'https://webprod.fireflink.com',
  PRODUCTION_URL: 'https://www.fireflink.com',
  ALPHA_URL: 'https://webalpha.fireflink.com',
  BETA_URL: 'http://webbeta.fireflink.com',
  DEV_WEB_SERVICE_URL: 'http://devwswb.fireflink.com',
  CB_CP:"http://37.61.218.179:81",
  FIRE_CLOUD : "http://cloud.fireflink.com"
};

export default WEBSITE_ENVIRONMENT_URL_CONFIG;

export const ASSERT_CONSTANT = {
  TRUE: true,
  FALSE: false,
  NO_LOG_YET: 'No Log yet',
  EMPTY_ARRAY: [],
  EMPTY_STRING: 'No Log yet',
  COMPILATION_SUCCESS: 'Compiled successfully',
  NULL: null,
  CLASSNAME_PREREQUISITE_FIRST: 'prerequisite overflow-auto',
  ID_JOURNAL: 'journal-scroll',
  m: 'pt-2 pb-2 flex flex-wrap border-2 border_style items-center',
  LABEL_SUBHEADING: 'label-Sub-Heading fontPoppinsRegularLg flex-auto',
  PREREQUISITE_CREATE_LABEL: 'Create Custom Assert Script',
  PR_4: 'pr-4',
  PR_2: 'pr-2',
  FLEX_FLEX_ROW: 'flex flex-row ml-96 relative left-80',
  SECONDARY_BUTTON: 'secondary-btn_new',
  COMPILE_AND_SAVE: 'Compile & Save',
  FLEX_ROW_JUSTIFY: 'flex flex-row justify-start',
  CLASSNAME_SNIPPET_FIRST: 'flex justify-end h-10 pr-2 bg-gray-200',
  LABEL_SNIPPETS_CLASSNAME: 'font-bold text-gray-400 sub_tab_name fontPoppinsSemiboldMd -mr-2',
  LABEL_SNIPPETS: 'Snippets',
  SNIPPET_INSIDE_DIV: 'flex ml-32 cursor-pointer select-none',
  MENU_ICON: '/assets/images/menu-icon.svg',
  WIDTH_20_PER: '20%',
  WIDTH_21_PER: '21%',
  WIDTH_20_PIX: '20px',
  HEIGHT_20_PIX: '20px',
  TOGGLE_MENU: '/assets/images/toggle_menu.svg',
  SNIPPET_TABLE_ONE: 'flex flex-col',
  FLEX_FLEX_ROW_ML_2: 'flex flex-row ml-2',
  STICKY_TABLE: 'sticky table',
  MAX_HEIGHT: 200,
  CONSOLE_DIV_ONE: 'ace-console-wrp-prerequiste- w-4/5 relative bottom-20 left-60',
  CONSOLE_DIV_TWO: 'pt-3 pb-3 border_style flex flex-wrap items-center',
  CONSOLE_LABEL_ONE: 'sub_tab_name fontPoppinsSemiboldMd flex-auto ml-10',
  CONSOLE_LABEL: 'Console',
  CLEAR_BUTTON_DIV: 'flex flex-row ml-32',
  SECONDARY_CLEAR_BUTTON: 'secondary-btn_new ml-3',
  TOGGLE_MT1: 'toggle mt-1 ml-5',
  SHOWMORE: 'showmore',
  WIDTH_20: '20',
  CONSOLE_OUTPUT_CONTAINER: 'console_output_container_prerequisite_element overflow-y-auto shadow',
  CONSOLE_DIV: 'Request_Div flex flex-wrap pt-3 pb-3 sub_tab_name fontPoppinsSemiboldMd ml-5 pr-4 w-full -mt-7',
  CLEAR_LABEL: 'Clear',
  CONSOLE_CLASSNAME: 'ml-72 text-sm flex-auto',
  SETARROWPOSITIONCLOSE: 'toggle setIsArrowPositionClose',
  WIDTH_98_PER: '98%',
  HEIGHT_35_PIX: '35px',
  WIDTH_25: '25',
  BACKGROUND_COLOR: '#e5ecfc',
  EDITOR_HEIGHT: '250',
  EDITOR_WIDTH: 'auto',
  ONlOAD_CHANGE: `//Each snippet receive input as input attributes and return output attributes
//we support these many operators(==, <, <=, >, >=, !=, substring, startsWith and endsWith)
//Prefixes for validation: Response Body (responseBody.), Response Time (metaData.apiTime), Status Code (statusCode), Status Code string (statusCodeValue), Headers ("(headers[?name=='{headerKey}'].value)[0]") 
//insert the key value pair to output attributes
//Assign_Variable - Use "$" to set variable for Assign_Variable
//Assign_Value - Use "$" to set variable for Assign value or hardcode value
//put("key","value"); 
//doAssert("Name","JSON_Path","Operator","Expected_Value");`,
  TABSIZE: 6,
  FONTSIZE: 12,
  UNIQUE_ID_OF_DIV: 'ace-editor',
  TOMMORROW: 'tomorrow',
  JAVA: 'java',
  MINILINES: 25,
  MAXLINES: 100,
  ENABLE_DEFAULT_ASSERT: 'EnableDefaultAssert',
  DISABLE_DEFAULT_ASSERT: 'DisableDefaultAssert',
  ENABLE_CUSTOM_ASSERT: 'EnableCustomAssert',
  DISABLE_CUSTOM_ASSERT: 'DisableCustomAssert',
  ACTIVE_DEFAULT: 'activeDefault',
  ACTIVE_DEFAULT_HISTORY: 'activeDefaultSnippet',
  IN_ACTIVE_DEFAULT: 'InactiveDefault',
  ACTIVE_CUSTOM: 'activeCustom',
  ACTIVE_CUSTOM_HISTORY: 'activeCustomSnippet',
  INACTIVE_CUSTOM: 'InactiveCustom',
  MAIN_DIV_ASSERT: 'border-top-1',
  MAIN_SECOND_DIV_ASSERT: 'flex flex-wrap flex-row items-center tab-links-color tab',
  MAIN_SECOND_DIV_ASSERT_MAIN: 'flex flex-wrap flex-row items-center tab-links-color',
  DEFAULT_ASSERT_BUTTON: 'tab-button fontPoppinsRegularMd tablinks ml-1 text-sm',
  DEFAULT_ASSERT_BUTTON_HISTORY: 'tab-button fontPoppinsSemiboldMd tablinks text-sm',
  CUSTOM_ASSERT_BUTTON: ' tab-button fontPoppinsRegularMd tablinks ml-5 text-sm',
  CUSTOM_ASSERT_BUTTON_HISTORY: ' tab-button fontPoppinsSemiboldMd tablinks ml-0 text-sm',
  DEFAULT_ASSERT_LABEL: 'Default Assert',
  CUSTOM_ASSERT_LABEL: 'Custom Assert',
  DEFAULT_ASSERT_ID: 'DefaultAssert',
  CUSTOM_ASSERT_ID: 'CustomAssert',
  BORDER_DEFAULT: 'border-2 border-style border-opacity-100',
  BORDER_CUSTOM: 'border-opacity-100',
  EXECUTED__REQUESTS: 'Executed Requests',
  GLOBAL_HISTORY: 'Global History',
  CUSTOM_NAVBAR: 'h-10 text-left pt-2 pl-3 hover:bg-violet-600',
  REQUEST_TYPE: ['GET', 'POST', 'PUT', 'PATCH', 'DELETE', 'OPTIONS', 'HEAD'],
  RESPONSE_METHOD_TYPE: [
    { label: 'response-body', value: 'Response Body' },
    { label: 'response-header', value: 'Response Headers' },
    { label: 'assert-results', value: 'Assert Results' },
    { label: 'captured-data-from-response', value: 'Captured Data From Response' },
  ],
  AUTHORIZATION: [
    { label: 'Inherit from Parent', value: 'Inherit from Parent' },
    { label: 'No Auth', value: 'No Auth' },
    { label: 'Basic Auth', value: 'Basic' },
    { label: 'Bearer Token', value: 'BearerToken' },
    { label: 'Oauth1.0', value: 'Oauth1' },
    { label: 'Oauth2.0', value: 'Oauth2' },
    { label: 'Digest Auth', value: 'Digest' },
    { label: 'AWS', value: 'AWS' },
    { label: 'Hawk', value: 'Hawk' },
    { label: 'Microsoft NTLM', value: 'NTLM' },
    { label: 'JWT Bearer', value: 'JWTBearer' },
    { label: 'API Key', value: 'APIKey' },
  ],
  REQUEST_BODY_CONTENT_TYPE: [
    { label: 'text/plain', value: 'Text' },
    { label: 'application/javascript', value: 'JavaScript' },
    { label: 'application/json', value: 'JSON' },
    { label: 'text/html', value: 'HTML' },
    { label: 'application/xml', value: 'XML' },
  ],
  REST_API: [
    { label: 'query-parameter', value: 'Query Parameter' },
    { label: 'request-header', value: 'Request Header' },
    { label: 'request-body', value: 'Request Body' },
    { label: 'authorization', value: 'Authorization' },
    { label: 'pre-requisite', value: 'Pre Requisite' },
    { label: 'assert', value: 'Assert' },
  ],
  PARAMETER_TYPE: [
    'String',
    'Array',
    'Integer',
    'Long',
    'Object',
    'Short',
    'Double',
    'Char',
    'Class',
    'Byte',
    'Boolean',
    'BigInteger',
    'BigDecimal',
    'Arrays',
    'Float',
  ],
  ROOT: 'root',
  MODULE: 'module',
  SCRIPT: 'script',
  WEBSERVICE: 'Webservice',
  STEPGROUP: 'Step Group',
  NO_AUTH_MSG: 'This request does not have any Authorization !',
  SCRIPT_PARENT_INHERIT_MSG: 'This script is inheriting',
  MODULE_PARENT_INHERIT_MSG: 'This module is inheriting',
  WEBSERVICE_PARENT_INHERIT_MSG: 'This Request is inheriting',
  WEBSERVICE_AUTH_SUCCESS: 'Auth Details Updated Successfully',
  AUTHORIZATION_WARNING: 'Authorization tab is empty, Please fill the mandatory fields.',
  WEBSERVICE_AUTH_TITLE: 'Web Service Authorization',
  UPDATE: 'Update',
  AUTH_TYPE_TITLE: 'Auth Type',
  AUTH_TYPE: ['BearerToken', 'Basic', 'Oauth1', 'Digest', 'AWS', 'Hawk', 'NTLM', 'JWTBearer', 'APIKey'],
  CLICK_HERE: 'Click here',
  TO_SEE: 'to see',
  ASSERT_ERROR_MESSAGE: 'Default Assert tab is empty, Please fill the mandatory fields',
  FROM: 'from',
  STEP_GROUP_PARENT_INHERIT_MSG:
    'There is no authorization being inherited by this request. Utilize the corresponding Step Group within any script to access the parent level authorization.',
  TYPE_TEXT: '"Type"',
  NO_VARIABLES_CREATED_TEXT: 'No Variables Created',
  EXPECTED_VALUE: 'Enter expected value',
  EXPECTED_HEADER_VALUE: 'Enter expected header value',
  NO_AUTH_TEXT: 'No Auth',
  INHERIT_FROM_PARENT_TEXT: 'Inherit from Parent',
  CREATE: 'create',
  EXPECTED_JSON_VALUE: 'Enter json path',
  TEXT_FORMAT: 'text/plain',
  HTML_FORMAT: 'text/html',
  XML_FORMAT: 'application/xml',
  JSON_FORMAT: 'application/json',
  JAVASCRIPT_FORMAT: 'application/javascript',
  LHS: 'lhs',
  RHS: 'rhs',
  REQUEST_URL: 'requestUrl',
  ACTION_OPTIONS: [
    {
      label: 'Request Header',
      value: 'requestHeader',
    },
    {
      label: 'Query Param',
      value: 'queryParam',
    },
  ],
  ALGORITHM_OPTIONS: [
    { label: 'HS256', value: 'HS256', header: 'HMAC - SHA-256' },
    { label: 'HS384', value: 'HS384', header: 'HMAC - SHA-384' },
    { label: 'HS512', value: 'HS512', header: 'HMAC - SHA-512' },
    { label: 'RS256', value: 'RS256', header: 'RSA - SHA-256 (RSASSA-PKCS1-v1_5)' },
    { label: 'RS384', value: 'RS384', header: 'RSA - SHA-384 (RSASSA-PKCS1-v1_5)' },
    { label: 'RS512', value: 'RS512', header: 'RSA - SHA-512 (RSASSA-PKCS1-v1_5)' },
    { label: 'ES256', value: 'ES256', header: 'ECDSA - SHA-256' },
    { label: 'ES384', value: 'ES384', header: 'ECDSA - SHA-384' },
    { label: 'ES512', value: 'ES512', header: 'ECDSA - SHA-512' },
    { label: 'PS256', value: 'PS256', header: 'RSA-PSS - SHA-256' },
    { label: 'PS384', value: 'PS384', header: 'RSA-PSS - SHA-384' },
    { label: 'PS512', value: 'PS512', header: 'RSA-PSS - SHA-512' },
  ],
  ADD_TOKEN_TO: 'Add Token To',
  ALGORITHM_TITLE: 'Algorithm',
  ALGORITHM: 'algorithm',
  ACTION: 'action',
  SECRET: 'Secret',
  SECRET_KEY_NAME: 'secret',
  SECRET_KEY_PLACEHOLDER: 'Enter Secret',
  ENCODED_NAME: 'encoded',
  PAYLOAD: 'Payload',
  SECRET_KEY_TOOLTIP: 'Secret used to generate  the JWT Signature.',
  PAYLOAD_TOOLTIP: 'JSON object that contains claims conveyed by the JWT',
  REQUEST_HEADER_PREFIX: 'Request Header Prefix',
  REQUEST_HEADER_PREFIX_PLACEHOLDER: 'Enter Request Header Prefix',
  QUERY_PARAM_NAME_PLACEHOLDER: 'Enter Query Param Name',
  JWT_HEADERS: 'Jwt Headers',
  JWT_HEADER_TOOLTIP: 'Values to be appended to JWT header',
  REQUEST_HEADER_PREFIX_TOOLTIP: 'Header Prefix added to authorization header of the request',
  PRIVATE_KEY: 'Private Key',
  PRIVATE_KEY_TOOLTIP: 'Private key used to generate  the JWT Signature in PEM Format.',
  PAYLOAD_EDITOR_NAME: 'payload',
  JWT_HEADER_EDITOR_NAME: 'jwtHeaders',
  JWT_SECRET_KEY_VALIDATION: 'Secret key is required',
  JWT_PRIVATE_KEY_VALIDATION: 'Private key is required',
  JWT_PAYLOAD_VALIDATION: 'Payload is required',
  TOKEN_PREFIX: 'tokenPrefix',
  ADVANCE_EDITOR_TEXT: 'Headers Specific to the algorithm are added Automatically',
  CHOOSE_FILE: 'Choose File',
  REPLACE_FILE: 'Replace File',
  PRIVATE_KEY_NAME: 'privateKey',
  PRIVATE_KEY_FILE_NAME: 'privateKeyFileName',
  ADVANCE_TEXT: 'Advanced',
  ENCODED: 'encoded',
  PRIMARY_COLOR: 'primary',
  ENCODED_TEXT: 'Base 64 Encoded',
  JWT_EDITOR_INITIAL_VALUES: {
    editorHeight: '112px',
    editorWidth: '100%',
    readOnly: false,
    value: '',
    showDropDown: false,
    variableDetails: null,
    editorValue: null,
    inputValue: '',
  },
  DEFAULT_EDITOR_X_AXIS: 281,
  PEM_FILE_EXTENSION: 'pem',
  DROPDOWN_STYLE: {
    position: 'absolute',
    zIndex: 10,
    top: '140px',
    left: '281px',
  },
  DEFAULT_CHAR_SIZE: 7,
  MAX_HORIZONTAL_LENGTH: 650,
  QUERY_PARAM_NAME: 'Query Param Name',
  REQUEST_HEADER: 'requestHeader',
  JWT_PAYLOAD: 'jwt-payload',
  REQUEST_HEADER_TOOLTIP: 'To specify how the JWT token will be added to your request',
  ALGORITHM_TOOLTIP: 'Algorithm utilized for generating the signature of a JWT',
};

import React from 'react';
import { colors } from '@src/css_config/colorConstants';
import '@pages/test-development/script/scripts/webservice/restapi/css/common_Webservice.scss';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
function MyPassword(props) {
  const [password, setPassword] = React.useState(false);
  const commonPasswordStyle = {
    color: `${colors.light_grey}`,
    marginLeft: '-45px',
    marginTop: '6px',
  };
  return (
    <div className="flex">
      <input variant="outlined" type={password ? 'text' : 'password'} fullWidth className="relative" {...props} />
      <span aria-label="toggle password visibility" onClick={() => setPassword((v) => !v)}>
        {password ? (
          <CustomTooltip title="hide" placement="bottom">
            <VisibilityOutlinedIcon className="ml-2.5 common-password-icon-hover-effect" style={commonPasswordStyle} />
          </CustomTooltip>
        ) : (
          <CustomTooltip title="show" placement="bottom">
            <VisibilityOffOutlinedIcon className="common-password-icon-hover-effect" style={commonPasswordStyle} />
          </CustomTooltip>
        )}
      </span>
    </div>
  );
}

export default MyPassword;

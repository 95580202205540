import React, { useMemo, useContext, useEffect, useCallback } from 'react';
import MyInput from '@pagescommon/Inputs/MyInput';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { RestContext } from '@pages/test-development/script/scripts/webservice/restapi/restapi';
import { WEBSERVICE } from '@pages/test-development/script/scripts/webservice/restapi/constants/constants';
import '@pages/test-development/script/scripts/webservice/restapi/css/common_Webservice.scss';
import Info from '../../request_parameter/images/info-gray-24dp.svg';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';
import InfoIconHover from '@pages/test-development/script/scripts/webservice/restapi/request/request_parameter/images/info_black_24dp.svg';
import useSyncContextValues from '@src/hooks/useSyncContextValues';

export default function Aws(props) {
  const isEditable = useMemo(() => window.permission?.isEditAccess('testDevelopment'), []);
  const context = useContext(RestContext);
  const formik = useFormik({
    initialValues: {
      accessKey: '',
      secretKey: '',
      awsRegion: '',
      serviceName: '',
      sessionToken: '',
      ...(context?.values?.awsAuthData || {}),
    },
    validationSchema: Yup.object({
      accessKey: Yup.string().required(WEBSERVICE.AWS_ACCESS_KEY),
      secretKey: Yup.string().required(WEBSERVICE.AWS_SECRET_KEY),
    }),
  });

  useSyncContextValues(formik, context, 'awsAuthData');
  const { onAuthIconHover, authIconHover } = props;

  const handleChange2 = (e) => {
    context.setFieldValue('authType', 'AWS');
    const { value, data, action, name, variableId } = e.target || 'unknown';
    const updatedValues = props.getUserInputValue(value, false, data, action);
    const updatedAWSValues = {
      ...(context?.values?.awsAuthData || {}),
      [name]: WEBSERVICE.ITERATION_PREFIX_REGEX.test(value) ? updatedValues : variableId || updatedValues,
    };
    context.setFieldValue('awsAuthData', updatedAWSValues);
    formik.validateForm(updatedAWSValues).then((errors) => context.setFieldValue('errors', errors));
    context.setFieldValue('currentAwsValue', { ...(context.values.currentAwsValue || {}), [name]: e });
    props.handleSaveAsSteps(false);
  };

  useEffect(() => {
    formik.validateForm().then((errors) => context.setFieldValue('errors', errors));
    return () => {
      context.setFieldValue('currentAwsValue', {});
    };
  }, []);

  return (
    <div className="relative top-12 aws-main-container userPassword-container mt-3">
      <form onSubmit={formik.handleSubmit} className="space-y-6" noValidate>
        <div className="grid grid-cols-3 gap-8">
          <div>
            <div
              className="fontPoppinsMediumSm text-left flex common-text-color-in-request"
              onDragStart={props.handleDragStart}
            >
              <span className="common-auth-star-symbol">*</span> Access Key
            </div>
            <MyInput
              canCreateVariable={isEditable}
              type="text"
              placeholder="Enter access Key"
              variableInput={true}
              className="input-style-for-auth2-common"
              inputValue="input"
              error={formik.errors.accessKey && formik.touched.accessKey}
              value={props.getUserInputValue(context?.values?.awsAuthData?.accessKey, true)}
              onMouseDown={formik.handleBlur}
              onChange={handleChange2}
              autoComplete="off"
              name="accessKey"
              helperText={formik.touched.accessKey && formik.errors.accessKey}
              currentValue={context.values.currentAwsValue?.accessKey}
              MyAlert={props.MyAlert}
              {...props}
            />
          </div>
          <div>
            <div
              className="fontPoppinsMediumSm common-text-color-in-request text-left flex"
              onDragStart={props.handleDragStart}
            >
              <span className="common-auth-star-symbol">*</span> Secret Key
            </div>
            <MyInput
              canCreateVariable={isEditable}
              type="text"
              placeholder="Enter secret key"
              variableInput={true}
              className="input-style-for-auth2-common"
              inputValue="input"
              error={formik.errors.secretKey && formik.touched.secretKey}
              value={props.getUserInputValue(context?.values?.awsAuthData?.secretKey, true)}
              onMouseDown={formik.handleBlur}
              onChange={handleChange2}
              autoComplete="off"
              name="secretKey"
              helperText={formik.touched.secretKey && formik.errors.secretKey}
              currentValue={context.values.currentAwsValue?.secretKey}
              MyAlert={props.MyAlert}
              {...props}
            />
          </div>
        </div>

        <div>
          <h5 className="font-bold text-left advanced-text-style" onDragStart={props.handleDragStart}>
            Advanced
          </h5>
          <div className="borderStyle3"></div>
          <div className="grid grid-cols-3 mt-2.5 gap-8">
            <div>
              <div
                className="fontPoppinsMediumSm text-left flex common-text-color-in-request"
                onDragStart={props.handleDragStart}
              >
                AWS Region
                <CustomTooltip
                  title={
                    <span className="fontPoppinsRegularXs">
                      This is the Region that is receiving the request (default value: us-east-1)
                    </span>
                  }
                  placement="right"
                >
                  <img
                    src={authIconHover?.aws_region ? InfoIconHover : Info}
                    id="aws_region"
                    onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                    onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                    className="ml-2 w-4 h-4 object-contain"
                    alt=""
                    height="15px"
                    width="15px"
                    draggable="false"
                  />
                </CustomTooltip>
              </div>
              <MyInput
                canCreateVariable={isEditable}
                type="text"
                placeholder="Enter AWS region"
                variableInput={true}
                className="input-style-for-auth2-common"
                inputValue="input"
                value={props.getUserInputValue(context?.values?.awsAuthData?.awsRegion, true)}
                onChange={handleChange2}
                autoComplete="off"
                name="awsRegion"
                currentValue={context.values.currentAwsValue?.awsRegion}
                MyAlert={props.MyAlert}
                {...props}
              />
            </div>
            <div>
              <div
                className="fontPoppinsMediumSm text-left flex common-text-color-in-request"
                onDragStart={props.handleDragStart}
              >
                Service Name
                <CustomTooltip
                  title={
                    <span className="fontPoppinsRegularXs">This is the service that is receiving the Request.</span>
                  }
                  placement="right"
                >
                  <img
                    src={authIconHover?.service_name ? InfoIconHover : Info}
                    id="service_name"
                    onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                    onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                    className="ml-2 w-4 h-4 object-contain"
                    alt=""
                    height="15px"
                    width="15px"
                    draggable="false"
                  />
                </CustomTooltip>
              </div>
              <MyInput
                canCreateVariable={isEditable}
                type="text"
                placeholder="Enter service name"
                variableInput={true}
                className="input-style-for-auth2-common"
                inputValue="input"
                value={props.getUserInputValue(context?.values?.awsAuthData?.serviceName, true)}
                onChange={handleChange2}
                autoComplete="off"
                name="serviceName"
                currentValue={context.values.currentAwsValue?.serviceName}
                MyAlert={props.MyAlert}
                {...props}
              />
            </div>
            <div>
              <div
                className="fontPoppinsMediumSm text-left flex common-text-color-in-request"
                onDragStart={props.handleDragStart}
              >
                Session Token
                <CustomTooltip
                  title={
                    <span className="fontPoppinsRegularXs">
                      This is only Required when using temporary security credentials.
                    </span>
                  }
                  placement="right"
                >
                  <img
                    src={authIconHover?.session_token ? InfoIconHover : Info}
                    id="session_token"
                    onMouseEnter={(e) => onAuthIconHover(e.target.id, true)}
                    onMouseLeave={(e) => onAuthIconHover(e.target.id, false)}
                    className="ml-2 w-4 h-4 object-contain"
                    alt=""
                    height="15px"
                    width="15px"
                    draggable="false"
                  />
                </CustomTooltip>
              </div>
              <MyInput
                canCreateVariable={isEditable}
                type="text"
                placeholder="Enter session token"
                variableInput={true}
                className="input-style-for-auth2-common"
                inputValue="input"
                value={props.getUserInputValue(context?.values?.awsAuthData?.sessionToken, true)}
                onChange={handleChange2}
                autoComplete="off"
                name="sessionToken"
                currentValue={context.values.currentAwsValue?.sessionToken}
                MyAlert={props.MyAlert}
                {...props}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

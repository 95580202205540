import React from 'react';
import { ReactComponent as LogoFireflink } from '@assets/FireFlink_logo.svg';
import style from '@pages/auth/SignupPage/signup.module.scss';
import cx from 'classnames';

function SignUpNavbar() {
  return (
    <div className={cx(style['signup_navbar'])}>
      <LogoFireflink />
    </div>
  );
}
export default SignUpNavbar;

import React from 'react';
import { usePagination, useTable, useGlobalFilter } from 'react-table';
import { ReactComponent as ShrinkIcon } from '@assets/analytics/ShrinkIcon.svg';
import ExeComparisionScriptResultAccordion from '../components/stepAccordions/exe-comparision-script-result-accordion';
import { isEmptyValue } from '@src/util/common_utils';

const ExeComparisonTableLeft = (props) => {
  let {
    columns,
    data,
    closeResultModal,
    openleftModal,
    scriptResultLeft,
    isTableExpanded,
    selectedScriptNameLeft,
    selectedScriptLeft,
  } = props;

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  );

  return (
    <div className={`col-span-12 ${isTableExpanded ? 'comparison-section' : ' exe-compariosn-table-shadow'}`}>
      <div className={`col-span-12 ${isTableExpanded ? 'px-2' : 'analytics-table-container'} `}>
        <table
          {...getTableProps()}
          className={`col-span-12 ${isTableExpanded ? 'analyticstableExpanded' : ' analyticstable'} analytics-table `}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="fontPoppinsSemiboldSm analyticsTableHeaderText h-8 ml-4 uppercase"
              >
                {headerGroup.headers.map((column) => (
                  <th width={column.width} {...column.getHeaderProps()} className="text-left">
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()} className="analytics-table-body">
            {rows.map((row) => {
              prepareRow(row);
              return (
                <>
                  <tr {...row.getRowProps()} className=" h-8 fontPoppinsRegularXs border-b">
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className="text-left">
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                  {row?.original?.scriptId === selectedScriptLeft?.scriptId &&
                    row?.original?.executionId === selectedScriptLeft?.executionId &&
                    scriptResultLeft?.length !== 0 &&
                    openleftModal && (
                      <tr>
                        <td className="resultSectionModal" colSpan={4}>
                          <div className="flex justify-between px-2">
                            <div
                              className="fontPoppinsSemiboldSm rs-blue uppercase tableHead-trimmer"
                              title={selectedScriptNameLeft}
                            >
                              {selectedScriptNameLeft}
                            </div>
                            <div
                              className=" expandIconContainer cursor-pointer"
                              onClick={() => closeResultModal('leftModal')}
                            >
                              <ShrinkIcon />
                            </div>
                          </div>
                          <ExeComparisionScriptResultAccordion
                            content={scriptResultLeft}
                            selectedScript={selectedScriptLeft}
                            AccordUniqueId="left" // NOTE :: AccordUniqueId is must pass to create unique ID for accordionIcon
                          />
                        </td>
                      </tr>
                    )}
                </>
              );
            })}
          </tbody>
        </table>
        {isEmptyValue(data) && <div className="analyticstable_message fontPoppinsMediumSm">{props?.message}</div>}
      </div>
    </div>
  );
};
export default ExeComparisonTableLeft;

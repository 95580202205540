import React, { useEffect, useState } from 'react';
import { Drawer, Select } from 'fireflink-ui';
import cx from 'classnames';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Select as MultiSelect } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { FiBarChart2 } from 'react-icons/fi';
import { FaChartPie } from 'react-icons/fa';
import Box from '@mui/material/Box';
import Input from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import { Checkbox, ListItemText, Tooltip } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import style from '@pages/common/Inputs/inputs.module.scss';
import { DASHBOARD_CONSTANTS } from '@src/common/ui-constants';
import {
  getDropDownOptions,
  getAllUesrsList,
  getAllLabelsByProjectId,
  getAllMachines,
  createCustomGraph,
  editCustomGraph,
} from '@src/api/api_services';
import { getCurrentProjectId, getCurrentProjectType } from '@util/common_utils';
import '../dashBoard.scss';
import { getScriptOptions } from '@src/pages/test-development/script/modules/module/utils/common-functions';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getLicenseFeatures } from '@src/util/common_utils';
import {
  getCustomGraphElementType,
  CUSTOM_GRAPH_PLATFORM,
  CUSTOM_GRAPH_WEB_PLATFORM,
  CUSTOM_GRAPH_MOBILE_PLATFORM,
} from '../custom_graph/custom_graph_constant';
import { isEmptyValue } from '@src/util/common_utils';

const CreateEditGraph = (props) => {
  const [dropDownList, setDropDownList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [selectedOption1, setSelectedOption1] = useState();
  const [selectedOption2, setSelectedOption2] = useState('');
  const [selectedOption3, setSelectedOption3] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectDisabled, setSelectDisabled] = useState(true);
  const [selectedOptionData, setSelectedOptionData] = useState([]);
  const [userID, setUserID] = useState([]);
  const [haslistdata, sethasListdata] = useState(false);
  const [queryParam, setQueryParam] = useState('');
  const [createAPICall, setCreateAPICall] = useState(false);
  const [hasOptionsData, setHasOptionsdata] = useState(false);
  const [xAxisLabels, setXAxisLabels] = useState([]);
  const [yAxisLabels, setYAxisLabels] = useState([]);

  useEffect(() => {
    if (props.actionType === 'Edit') {
      setSelectedOption2(formik.values.optionTwo);
      setSelectedOption1(formik.values.optionOne);
    } else {
      sethasListdata(true);
    }
  }, [props]);

  const licenseFeatures = getLicenseFeatures();
  const hasAutomation = window.privilege.hasAutomation(licenseFeatures);
  const hasDefects = window.privilege.hasDefects(licenseFeatures);
  const hasManualTestCase = window.privilege.hasManualTestCase(licenseFeatures);
  const hasCBasic = window.privilege.hasCBasic(licenseFeatures);

  useEffect(() => {
    if (props?.actionType === 'Edit') {
      sethasListdata(true);
    }
  }, [selectedOption3, userID]);

  useEffect(() => {
    if (hasCBasic) {
      setQueryParam('All');
    } else if (hasAutomation && hasDefects && hasManualTestCase) {
      setQueryParam('A-M-D');
    } else if (hasAutomation && hasManualTestCase) {
      setQueryParam('A-M');
    } else if (hasAutomation && hasDefects) {
      setQueryParam('A-D');
    } else if (hasManualTestCase && hasDefects) {
      setQueryParam('M-D');
    } else if (hasAutomation) {
      setQueryParam('A');
    } else if (hasManualTestCase) {
      setQueryParam('M');
    } else if (hasDefects) {
      setQueryParam('D');
    }
  }, [queryParam]);

  const getoptionTwo = (name) => {
    return name?.split('v/s' || 'vs')[1]?.trim();
  };

  const checkOptionThreeData = (data) => {
    if (!isEmptyValue(data)) {
      setHasOptionsdata(false);
    } else {
      setHasOptionsdata(true);
    }
  };

  const getOptionThree = (data) => {
    const options = [
      'users',
      'labels',
      'scripts',
      'machines',
      'executionRunTypes',
      'statuses',
      'suiteNames',
      'types',
      'platforms',
      'valuesToFilter',
    ];

    for (const value of options) {
      if (!isEmptyValue(data?.[value])) {
        return data[value];
      }
    }
    return null;
  };
  const formik = useFormik({
    initialValues: {
      graphType: props?.actionType === 'Edit' ? props?.data.graphType : 'BarGraph',
      optionOne: props?.actionType === 'Edit' ? props?.data.option : '',
      optionTwo: props?.actionType === 'Edit' ? getoptionTwo(props?.data.name) : '',
      optionThree: props?.actionType === 'Edit' ? getOptionThree(props?.data) : [],
    },
    validationSchema: Yup.object({
      optionOne: Yup.string().required('Option 1 is required'),
      optionTwo: Yup.string().required('Option 2 is required'),
      optionThree: Yup.array().min(1, `field is required`).required('Option 3 is required'),
    }),
    onSubmit: (values) => {
      if (props?.actionType === 'Add') {
        createGraph(values);
      } else {
        editGraph(values);
      }
    },
  });

  const createGraph = async (values) => {
    let projectID = projectId;
    let search = window.location.search;
    const dashBoardID = new URLSearchParams(search).get('id');
    let payload = {
      name: values.optionOne + ' v/s ' + values.optionTwo,
      dashBoardId: dashBoardID,
      graphType: values.graphType,
      projectId: projectID,
      option: values.optionOne,
    };

    if (['Number of Automation Executions', 'Number of Manual Executions'].includes(values.optionOne)) {
      switch (values.optionTwo) {
        case 'RunInitiated By':
          payload.users = values.optionThree;
          break;
        case 'Machine':
          payload.machines = values.optionThree;
          break;
        case 'ExecutionRunType':
          payload.executionRunTypes = values.optionThree;
          break;
        case 'Status':
          payload.statuses = values.optionThree;
          break;
        case 'SuiteName':
          payload.suiteNames = values.optionThree;
          break;

        default:
          break;
      }
    } else if (['Number of Automation Scripts', 'Number of Manual Test cases'].includes(values.optionOne)) {
      switch (values.optionTwo) {
        case 'Created By':
        case 'Modified By':
          payload.users = values.optionThree;
          payload.userType = values.optionTwo === 'Created By' ? 'createdBy' : 'modifiedBy';
          break;
        case 'Labels':
          payload.labels = values.optionThree;
          break;
        case 'Script Type':
          payload.scripts = values.optionThree;
          break;
        default:
          break;
      }
    } else if (
      ['Number of Elements', 'Number of Program Elements', 'Number of Step Groups'].includes(values.optionOne)
    ) {
      switch (values.optionTwo) {
        case 'Created By':
        case 'Modified By':
          payload.users = values.optionThree;
          payload.userType = values.optionTwo === 'Created By' ? 'createdBy' : 'modifiedBy';
          break;
        case 'Element Type':
        case 'Step Group type':
          payload.types = values.optionThree;
          break;
        case 'Platform Type':
          payload.platforms = values.optionThree;
          break;
        default:
          break;
      }
    } else if (['Number of defects'].includes(values.optionOne)) {
      if (['Created By', 'Modified By', 'Assign to'].includes(values.optionTwo)) {
        payload.users = values.optionThree;
      } else {
        payload.valuesToFilter = values.optionThree;
      }
    }
    let response = await createCustomGraph(payload);
    if (response?.data?.responseCode === 201 && response?.data?.responseObject) {
      props?.handleChange(response?.data?.responseObject, 'Add');
      props?.onClose();
      props.MyAlert.success(response.data.message);
    }
  };

  const editGraph = async (values) => {
    let graphId = props?.data?.id;
    let payload = {
      name: values.optionOne + ' v/s ' + values.optionTwo,
      dashBoardId: props?.data?.dashBoardId,
      graphType: values.graphType,
      projectId: props?.data?.dashBoardId,
      option: values.optionOne,
      createdBy: props?.data?.createdBy,
      createdByUname: props?.data?.createdByUname,
      createdOn: props?.data?.createdOn,
    };
    if (values.optionOne !== 'Number of defects') {
      switch (values.optionTwo) {
        case 'Created By':
        case 'Modified By':
        case 'RunInitiated By':
          payload.users = values.optionThree;
          payload.userType =
            values.optionTwo === 'Created By' ? 'createdBy' : values.optionTwo === 'Modified By' ? 'modifiedBy' : '';
          break;
        case 'Labels':
          payload.labels = values.optionThree;
          break;
        case 'Script Type':
          payload.scripts = values.optionThree;
          break;
        case 'Machine':
          payload.machines = values.optionThree;
          break;
        case 'ExecutionRunType':
          payload.executionRunTypes = values.optionThree;
          break;
        case 'Status':
          payload.statuses = values.optionThree;
          break;
        case 'SuiteName':
          payload.suiteNames = values.optionThree;
          break;
        case 'Element Type':
        case 'Step Group type':
          payload.types = values.optionThree;
          break;
        case 'Platform Type':
          payload.platforms = values.optionThree;
          break;
        default:
          break;
      }
    } else {
      if (['Created By', 'Modified By', 'Assign to'].includes(values.optionTwo)) {
        payload.users = values.optionThree;
      } else {
        payload.valuesToFilter = values.optionThree;
      }
    }
    let response = await editCustomGraph(payload, graphId);
    if (response?.data?.responseCode === 200 && response?.data?.responseObject) {
      props?.handleChange(response?.data?.responseObject, 'Edit');
      props?.onClose();
      props.MyAlert.success(response.data.message);
    }
  };
  useEffect(() => {
    if (!createAPICall && queryParam !== '') {
      getDropdownsList(queryParam);
    } else {
      setCreateAPICall(false);
    }
  }, [queryParam]);

  const getDropdownsList = async (data) => {
    try {
      const response = await getDropDownOptions(data);
      if (response?.data?.responseCode === 200 && response?.data.message === 'SUCCESS') {
        let optionArray = [];
        setCreateAPICall(true);
        setDropDownList(response?.data?.responseObject);
        response.data.responseObject.options.forEach((option) => {
          optionArray.push({ name: option.name, value: option.name });
        });
        if (response.data.responseObject.optionsData) {
          response.data.responseObject.optionsData.forEach((option) => {
            optionArray.push({ name: option.firstOption, value: option.firstOption });
          });
        }
        setXAxisLabels(optionArray);

        if (props.actionType === 'Edit') {
          let secondOptionList = Object.keys(response?.data?.responseObject?.optionsData[0].secondOptions);
          setYAxisLabels(secondOptionList);
        }
      }
    } catch (error) {
      console.error('API error:', error);
      setCreateAPICall(false);
    }
  };

  const projectId = getCurrentProjectId();
  const projectType = getCurrentProjectType();
  const [option3List, setOption3List] = useState([]);
  const automationExecution = [
    {
      name: 'Sequential',
    },
    {
      name: 'Parallel',
    },
    {
      name: 'Automatic Distribution',
    },
    {
      name: 'Manual Distribution',
    },
  ];
  const manualExecution = [
    {
      name: 'Single User',
    },
    {
      name: 'Multiple User',
    },
  ];
  const resultStatus = [
    {
      name: 'Pass',
    },
    {
      name: 'Fail',
    },
    {
      name: 'Warning',
    },
    {
      name: 'Skip',
    },
  ];
  const getAllOptionThreeData = (listData, label) => {
    switch (label) {
      case 'user':
        let personData = [];
        let selectedPerson = [];
        let userId = [];
        listData.map((user) => {
          personData.push({
            id: user.id,
            name: user.name,
            checked: true,
          });
          selectedPerson.push({
            userId: user.id,
            name: user.name,
            emailId: user.emailId,
          });
          userId.push(user.id);
        });
        formik.setFieldValue('optionThree', selectedPerson);
        setUserID(userId);
        setSelectedOptionData(selectedPerson);
        setSelectedOption3(personData);
        break;
      case 'machines':
        let machineData = [];
        let selectedMachineData = [];
        let clientId = [];
        listData.map((machine) => {
          if (selectedOption1 === 'Number of Manual Executions') {
            machineData.push({
              hostName: machine.hostName,
              clientId: machine.clientId,
              id: machine.id,
              checked: true,
            });
            selectedMachineData.push({
              hostName: machine.hostName,
              clientId: machine.clientId,
              id: machine.id,
            });
            clientId.push(machine.id);
          } else {
            machineData.push({
              hostName: machine.hostName,
              clientId: machine.clientId,
              checked: true,
            });
            selectedMachineData.push({
              hostName: machine.hostName,
              clientId: machine.clientId,
            });
            clientId.push(machine.clientId);
          }
        });
        setUserID(clientId);
        setSelectedOptionData(selectedMachineData);
        setSelectedOption3(machineData);
        formik.setFieldValue('optionThree', selectedMachineData);

      default:
        break;
    }
  };
  const getUsers = async () => {
    try {
      const response = await getAllUesrsList(projectId);

      if (response?.data?.responseCode === 200 && response?.data.message === 'SUCCESS') {
        const users = response.data.responseObject;
        checkOptionThreeData(users);
        setOption3List(users);
        setUsersList(users);
        setSelectAll(false);
        if (props.actionType === 'Add') {
          setSelectAll(true);
          getAllOptionThreeData(users, 'user');
        } else {
          if (getoptionTwo(props.data.name) === formik.values.optionTwo) {
            const optionList = props.data.users.map((item) => ({
              id: item.userId,
              name: item.name,
              checked: true,
            }));
            setUserID(optionList.map((item) => item.id));
            setSelectedOption3(optionList);
            setSelectedOptionData(props.data.users);
            formik.setFieldValue('optionThree', props.data.users);
            const selectAllFlag = props.data.users.length === users.length;
            setSelectAll(selectAllFlag);
          } else {
            setSelectAll(true);
            getAllOptionThreeData(users, 'user');
          }
        }
      }
    } catch (error) {
      console.error('API error:', error);
    }
  };

  const getAllSuites = async () => {
    let params = selectedOption1 === 'Number of Automation Executions' ? 'Automation' : 'Manual';

    try {
      const response = await getAllMachines(params);
      if (response?.data?.responseCode === 200 && response?.data.message === 'SUCCESS') {
        let suiteArray = [];
        response?.data?.responseObject?.suiteNames?.forEach((suite) => {
          suiteArray.push({ name: suite });
        });
        setOption3List(suiteArray);
        setSelectAll(false);
        checkOptionThreeData(suiteArray);
        if (props?.actionType === 'Add') {
          setSelectAll(true);
          setSelectedOption3(suiteArray.map((item) => item.name));
          formik.setFieldValue(
            'optionThree',
            suiteArray.map((item) => item.name)
          );
        } else {
          if (getoptionTwo(props?.data.name) === formik.values.optionTwo) {
            const selectAllFlag = suiteArray.length === props?.data?.suiteNames?.length;
            setSelectAll(selectAllFlag);
            setSelectedOption3(props?.data?.suiteNames);
            formik.setFieldValue('optionThree', props?.data?.suiteNames);
          } else {
            setSelectAll(true);
            setSelectedOption3(suiteArray.map((item) => item.name));
            formik.setFieldValue(
              'optionThree',
              suiteArray.map((item) => item.name)
            );
          }
        }
      }
    } catch (error) {
      console.error('API error:', error);
    }
  };
  const getAllMachine = async () => {
    try {
      let params = selectedOption1 === 'Number of Automation Executions' ? 'Automation' : 'Manual';
      const response = await getAllMachines(params);
      if (response?.data?.responseCode === 200 && response?.data.message === 'SUCCESS') {
        let machines = response?.data?.responseObject?.machineNames;

        setOption3List(response?.data?.responseObject?.machineNames);
        setSelectAll(false);
        checkOptionThreeData(response?.data?.responseObject?.machineNames);
        if (props.actionType === 'Add') {
          setSelectAll(true);
          getAllOptionThreeData(machines, 'machines');
        } else {
          if (getoptionTwo(props.data.name) === formik.values.optionTwo) {
            if (selectedOption1 !== 'Number of Manual Executions') {
              const optionList = props.data.machines.map((item) => ({
                clientId: item.clientId,
                hostName: item.hostName,
                checked: true,
              }));
              setUserID(optionList.map((item) => item.clientId));
              setSelectedOption3(optionList);
              setSelectedOptionData(props.data.machines);
              formik.setFieldValue('optionThree', props.data.machines);
              const selectAllFlag = props.data.machines.length === machines.length;
              setSelectAll(selectAllFlag);
            } else {
              const optionList = props.data.machines.map((item) => ({
                clientId: item.clientId,
                hostName: item.hostName,
                id: item.id,
                checked: true,
              }));
              setUserID(optionList.map((item) => item.id));
              setSelectedOption3(optionList);
              setSelectedOptionData(props.data.machines);
              formik.setFieldValue('optionThree', props.data.machines);
              const selectAllFlag = props.data.machines.length === machines.length;
              setSelectAll(selectAllFlag);
            }
          } else {
            setSelectAll(true);
            getAllOptionThreeData(machines, 'machines');
          }
        }
      }
    } catch (error) {
      console.error('API error:', error);
    }
  };
  const getAllLabels = async () => {
    try {
      const response = await getAllLabelsByProjectId(projectId);
      if (response?.data?.responseCode === 200 && response?.data.message === 'SUCCESS') {
        checkOptionThreeData(response?.data?.responseObject);
        setOption3List(response?.data?.responseObject);
        setSelectAll(false);
        if (props?.actionType === 'Add') {
          setSelectAll(true);
          setSelectedOption3(response?.data?.responseObject.map((item) => item.name));
          formik.setFieldValue(
            'optionThree',
            response?.data?.responseObject.map((item) => item.name)
          );
        } else {
          if (getoptionTwo(props?.data.name) === formik.values.optionTwo) {
            const selectAllFlag = response?.data?.responseObject.length === props?.data?.labels?.length;
            setSelectAll(selectAllFlag);
            if (formik.values.optionOne === 'Number of defects') {
              setSelectedOption3(props?.data?.valuesToFilter);
              formik.setFieldValue('optionThree', props?.data?.valuesToFilter);
            } else {
              setSelectedOption3(props?.data?.labels);
              formik.setFieldValue('optionThree', props?.data?.labels);
            }
          } else {
            setSelectAll(true);
            setSelectedOption3(response?.data?.responseObject.map((item) => item.name));
            formik.setFieldValue(
              'optionThree',
              response?.data?.responseObject.map((item) => item.name)
            );
          }
        }
      }
    } catch (error) {
      console.error('API error:', error);
    }
  };

  const getAllScriptType = () => {
    const scriptType = getScriptOptions();
    setOption3List(scriptType);
    checkOptionThreeData(scriptType);
    if (props?.actionType === 'Add') {
      setSelectAll(true);
      setSelectedOption3(scriptType.map((item) => item.name));
      formik.setFieldValue(
        'optionThree',
        scriptType.map((item) => item.name)
      );
    } else {
      if (getoptionTwo(props?.data.name) === formik.values.optionTwo) {
        const selectAllFlag = scriptType.length === props?.data?.scripts?.length;
        setSelectAll(selectAllFlag);
        setSelectedOption3(props?.data?.scripts);
        formik.setFieldValue('optionThree', props?.data?.scripts);
      } else {
        setSelectAll(true);
        setSelectedOption3(scriptType.map((item) => item.name));
        formik.setFieldValue(
          'optionThree',
          scriptType.map((item) => item.name)
        );
      }
    }
  };
  const getExecutionRunType = () => {
    let executionRunTypeData = [];
    if (selectedOption1 === 'Number of Automation Executions') {
      executionRunTypeData = automationExecution;
      setOption3List(automationExecution);
    } else if (selectedOption1 === 'Number of Manual Executions') {
      executionRunTypeData = manualExecution;
      setOption3List(manualExecution);
    }
    checkOptionThreeData(executionRunTypeData);
    if (props?.actionType === 'Add') {
      setSelectAll(true);
      setSelectedOption3(executionRunTypeData.map((item) => item.name));
      formik.setFieldValue(
        'optionThree',
        executionRunTypeData.map((item) => item.name)
      );
    } else {
      if (getoptionTwo(props?.data.name) === formik.values.optionTwo) {
        const selectAllFlag = executionRunTypeData.length === props?.data?.executionRunTypes?.length;
        setSelectAll(selectAllFlag);
        setSelectedOption3(props?.data?.executionRunTypes);
        formik.setFieldValue('optionThree', props?.data?.executionRunTypes);
      } else {
        setSelectAll(true);
        setSelectedOption3(executionRunTypeData.map((item) => item.name));
        formik.setFieldValue(
          'optionThree',
          executionRunTypeData.map((item) => item.name)
        );
      }
    }
  };
  const getAllStatus = () => {
    setOption3List(resultStatus);
    checkOptionThreeData(resultStatus);
    if (props?.actionType === 'Add') {
      setSelectAll(true);
      setSelectedOption3(resultStatus.map((item) => item.name));
      formik.setFieldValue(
        'optionThree',
        resultStatus.map((item) => item.name)
      );
    } else {
      if (getoptionTwo(props?.data.name) === formik.values.optionTwo) {
        const selectAllFlag = resultStatus.length === props?.data?.statuses?.length;
        setSelectAll(selectAllFlag);
        setSelectedOption3(props?.data?.statuses);
        formik.setFieldValue('optionThree', props?.data?.statuses);
      } else {
        setSelectAll(true);
        setSelectedOption3(resultStatus.map((item) => item.name));
        formik.setFieldValue(
          'optionThree',
          resultStatus.map((item) => item.name)
        );
      }
    }
  };

  const getAllElements = () => {
    let elements = [];
    elements = getCustomGraphElementType(projectType);
    setOption3List(elements);
    if (props?.actionType === 'Add') {
      setSelectAll(true);
      setSelectedOption3(elements.map((item) => item.name));
      formik.setFieldValue(
        'optionThree',
        elements.map((item) => item.name)
      );
    } else {
      if (getoptionTwo(props?.data.name) === formik.values.optionTwo) {
        const selectAllFlag = elements.length === props?.data?.types?.length;
        setSelectAll(selectAllFlag);
        setSelectedOption3(props?.data?.types);
        formik.setFieldValue('optionThree', props?.data?.types);
      } else {
        setSelectAll(true);
        setSelectedOption3(elements.map((item) => item.name));
        formik.setFieldValue(
          'optionThree',
          elements.map((item) => item.name)
        );
      }
    }
  };

  const getAllPlatform = () => {
    let platform = [];
    if (projectType === 'Web') {
      platform = CUSTOM_GRAPH_WEB_PLATFORM;
    } else if (projectType === 'Mobile') {
      platform = CUSTOM_GRAPH_MOBILE_PLATFORM;
    } else {
      platform = CUSTOM_GRAPH_PLATFORM;
    }
    setOption3List(platform);
    if (props?.actionType === 'Add') {
      setSelectAll(true);
      setSelectedOption3(platform.map((item) => item.name));
      formik.setFieldValue(
        'optionThree',
        platform.map((item) => item.name)
      );
    } else {
      if (getoptionTwo(props?.data.name) === formik.values.optionTwo) {
        const selectAllFlag = platform.length === props?.data?.platforms?.length;
        setSelectAll(selectAllFlag);
        setSelectedOption3(props?.data?.platforms);
        formik.setFieldValue('optionThree', props?.data?.platforms);
      } else {
        setSelectAll(true);
        setSelectedOption3(platform.map((item) => item.name));
        formik.setFieldValue(
          'optionThree',
          platform.map((item) => item.name)
        );
      }
    }
  };

  const getAllStepGroups = () => {
    const scriptType = getScriptOptions();
    setOption3List(scriptType);
    if (props?.actionType === 'Add') {
      setSelectAll(true);
      setSelectedOption3(scriptType.map((item) => item.name));
      formik.setFieldValue(
        'optionThree',
        scriptType.map((item) => item.name)
      );
    } else {
      if (getoptionTwo(props?.data.name) === formik.values.optionTwo) {
        const selectAllFlag = scriptType.length === props?.data?.types?.length;
        setSelectAll(selectAllFlag);
        setSelectedOption3(props?.data?.types);
        formik.setFieldValue('optionThree', props?.data?.types);
      } else {
        setSelectAll(true);
        setSelectedOption3(scriptType.map((item) => item.name));
        formik.setFieldValue(
          'optionThree',
          scriptType.map((item) => item.name)
        );
      }
    }
  };
  const getAllDefectsList = (option2, defectData) => {
    let defectList = defectData?.optionsData?.[0]?.secondOptions?.[option2];
    let resultList = [];
    defectList?.map((option) => {
      resultList.push({ name: option });
    });
    setOption3List(resultList);
    setSelectAll(false);
    if (props?.actionType === 'Add') {
      setSelectAll(true);
      setSelectedOption3(resultList.map((item) => item.name));
      formik.setFieldValue(
        'optionThree',
        resultList.map((item) => item.name)
      );
    } else {
      if (getoptionTwo(props?.data.name) === formik.values.optionTwo) {
        setSelectAll(false);
        const selectAllFlag = resultList.length === props?.data?.valuesToFilter?.length;
        setSelectAll(selectAllFlag);
        setSelectedOption3(props?.data?.valuesToFilter);
        formik.setFieldValue('optionThree', props?.data?.valuesToFilter);
      } else {
        setSelectAll(true);
        setSelectedOption3(resultList.map((item) => item.name));
        formik.setFieldValue(
          'optionThree',
          resultList.map((item) => item.name)
        );
      }
    }
  };

  useEffect(() => {
    if (selectedOption1 !== 'Number of defects') {
      switch (selectedOption2) {
        case 'Modified By':
        case 'Created By':
        case 'RunInitiated By':
          getUsers();
          break;
        case 'Labels':
          getAllLabels();
          break;
        case 'Script Type':
          getAllScriptType();
          break;
        case 'ExecutionRunType':
          getExecutionRunType();
          break;
        case 'Status':
          getAllStatus();
          break;
        case 'SuiteName':
          getAllSuites();
          break;
        case 'Machine':
          getAllMachine();
          break;
        case 'Element Type':
          getAllElements();
          break;
        case 'Platform Type':
          getAllPlatform();
          break;
        case 'Step Group type':
          getAllStepGroups();
          break;
        default:
          break;
      }
    } else {
      switch (selectedOption2) {
        case 'Modified By':
        case 'Created By':
        case 'Assign to':
          getUsers();
          break;
        case 'Labels':
          getAllLabels();
          break;
        default:
          getAllDefectsList(selectedOption2, dropDownList);
          break;
      }
    }
  }, [selectedOption2, selectedOption1, dropDownList]);

  const ITEM_HEIGHT = 52;
  const ITEM_PADDING_TOP = 10;
  const MENU_GAP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        height: 'auto',
        marginTop: MENU_GAP,
      },
    },
    classes: {
      root: cx(style['select-box-menu-list-wrapper']),
    },
  };

  const handleChange = (event) => {
    const { value, checked } = event.target;
    if (value === 'All') {
      setSelectedOption3(checked ? option3List.map((item) => item.name) : []);
      setSelectAll(checked);
      if (checked) {
        formik.setFieldValue(
          'optionThree',
          option3List.map((item) => item.name)
        );
      } else {
        formik.setFieldValue('optionThree', []);
      }
    } else {
      const updatedPersonName = checked ? [...selectedOption3, value] : selectedOption3.filter((id) => id !== value);

      setSelectedOption3(updatedPersonName);
      formik.setFieldValue('optionThree', updatedPersonName);
      setSelectAll(option3List.every((item) => updatedPersonName.includes(item.name)));
    }
  };

  const getDropdwonTwoOptions = (option) => {
    if (option === 'Number of defects') {
      let optionArray = [];
      dropDownList.optionsData.forEach((option) => {
        optionArray = Object.keys(option.secondOptions);
      });
      setYAxisLabels(optionArray);
    }
  };

  const handleOption1Change = (event) => {
    setSelectedOption1(event.value);
    setSelectedOption2('');
    getDropdwonTwoOptions(event.value);
  };
  const handleOption2Change = (event) => {
    setSelectedOption2(event.value);
    setSelectedOption3([]);
    setUserID([]);
    setSelectedOption3([]);
    setSelectedOptionData([]);
  };
  const handleIconClick = () => {
    setSelectDisabled((prevDisabled) => !prevDisabled);
  };

  const handleChipDelete = (e, value) => {
    const updatedoptions = selectedOption3.filter((item) => item !== value);
    setSelectedOption3(updatedoptions);
    formik.setFieldValue('optionThree', updatedoptions);
    setSelectAll(option3List.every((item) => updatedoptions.includes(item.name)));
  };

  const handleChangeUser = (event, item) => {
    const { value, checked } = event.target;
    if (selectedOption2 === 'Machine') {
      if (value === 'All') {
        if (checked) {
          let machineData = [];
          let selectedMachineData = [];
          let clientId = [];
          item.map((machine) => {
            machineData.push({
              hostName: machine.hostName,
              clientId: machine.clientId,
              checked: checked,
            });
            selectedMachineData.push({
              hostName: machine.hostName,
              clientId: machine.clientId,
            });
            if (selectedOption1 !== 'Number of Manual Executions') {
              clientId.push(machine.clientId);
            } else {
              clientId.push(machine.id);
            }
          });
          setUserID(clientId);
          setSelectedOptionData(selectedMachineData);
          setSelectedOption3(machineData);
          formik.setFieldValue('optionThree', selectedMachineData);
        } else {
          formik.setFieldValue('optionThree', []);
          setSelectedOption3([]);
          setSelectedOptionData([]);
          setUserID([]);
        }
        setSelectAll(checked);
      } else {
        let updatedmachineData = selectedOption3;
        let updatedSelectedMachineData = selectedOptionData;
        let clientId = userID;
        if (selectedOption1 !== 'Number of Manual Executions') {
          if (checked) {
            let indexValue = updatedmachineData.findIndex((machine) => machine.clientId === item.clientId);
            if (indexValue >= 0) {
              selectedOption3[indexValue].checked = checked;
            } else {
              updatedmachineData.push({
                hostName: item.hostName,
                clientId: item.clientId,
                checked: checked,
              });
            }

            updatedSelectedMachineData.push({
              hostName: item.hostName,
              clientId: item.clientId,
            });
            clientId.push(item.clientId);
            setUserID(clientId);
            setSelectedOptionData(updatedSelectedMachineData);
            setSelectedOption3(updatedmachineData);
            formik.setFieldValue('optionThree', updatedSelectedMachineData);
          } else {
            clientId.splice(userID.indexOf(item.clientId), 1);
            updatedmachineData = selectedOption3.map((machine) =>
              machine.clientId === item.clientId ? { ...machine, checked: false } : machine
            );
            updatedSelectedMachineData = selectedOptionData.filter((machine) => machine.clientId !== item.clientId);
            setUserID(clientId);
            setSelectedOptionData(updatedSelectedMachineData);
            setSelectedOption3(updatedmachineData);
            formik.setFieldValue('optionThree', updatedSelectedMachineData);
          }
          setSelectAll(option3List.every((item) => clientId.includes(item.clientId)));
        } else {
          if (checked) {
            let indexValue = updatedmachineData.findIndex((machine) => machine.id === item.id);
            if (indexValue >= 0) {
              selectedOption3[indexValue].checked = checked;
            } else {
              updatedmachineData.push({
                hostName: item.hostName,
                clientId: item.clientId,
                id: item.id,
                checked: checked,
              });
            }

            updatedSelectedMachineData.push({
              hostName: item.hostName,
              clientId: item.clientId,
              id: item.id,
            });
            clientId.push(item.id);
            setUserID(clientId);
            setSelectedOptionData(updatedSelectedMachineData);
            setSelectedOption3(updatedmachineData);
            formik.setFieldValue('optionThree', updatedSelectedMachineData);
          } else {
            clientId.splice(userID.indexOf(item.id), 1);
            updatedmachineData = selectedOption3.map((machine) =>
              machine.id === item.id ? { ...machine, checked: false } : machine
            );
            updatedSelectedMachineData = selectedOptionData.filter((machine) => machine.id !== item.id);
            setUserID(clientId);
            setSelectedOptionData(updatedSelectedMachineData);
            setSelectedOption3(updatedmachineData);
            formik.setFieldValue('optionThree', updatedSelectedMachineData);
          }
          setSelectAll(option3List.every((item) => clientId.includes(item.id)));
        }
      }
    } else if (['Created By', 'Modified By', 'RunInitiated By', 'Assign to'].includes(selectedOption2)) {
      if (value === 'All') {
        if (checked) {
          let personData = [];
          let selectedPerson = [];
          let userId = [];
          item.map((user) => {
            personData.push({
              id: user.id,
              name: user.name,
              checked: checked,
            });
            selectedPerson.push({
              userId: user.id,
              name: user.name,
              emailId: user.emailId,
            });
            userId.push(user.id);
          });
          formik.setFieldValue('optionThree', selectedPerson);
          setUserID(userId);
          setSelectedOptionData(selectedPerson);
          setSelectedOption3(personData);
        } else {
          setSelectedOption3([]);
          setSelectedOptionData([]);
          setUserID([]);
          formik.setFieldValue('optionThree', []);
        }
        setSelectAll(checked);
      } else {
        let updatedPersonName = [...selectedOption3];
        let updateUserData = [...selectedOptionData];
        let userId = [...userID];

        if (checked) {
          let indexValue = updatedPersonName.findIndex((user) => user.id === item.id);
          if (indexValue >= 0) {
            selectedOption3[indexValue].checked = checked;
          } else {
            updatedPersonName.push({
              id: item.id,
              name: item.name,
              checked: checked,
            });
          }

          updateUserData.push({
            userId: item.id,
            name: item.name,
            emailId: item.emailId,
          });
          userId.push(item.id);
          setUserID(userId);
          setSelectedOptionData(updateUserData);
          setSelectedOption3(updatedPersonName);
          formik.setFieldValue('optionThree', updateUserData);
        } else {
          userId.splice(userID.indexOf(item.id), 1);
          updatedPersonName = selectedOption3.map((user) => (user.id === item.id ? { ...user, checked: false } : user));
          updateUserData = selectedOptionData.filter((user) => user.userId !== item.id);
          setUserID(userId);
          setSelectedOptionData(updateUserData);
          setSelectedOption3(updatedPersonName);
          formik.setFieldValue('optionThree', updateUserData);
        }

        setSelectAll(usersList.every((item) => userId.includes(item.id)));
      }
    }
  };
  const handleChipDeleteUser = (e, value) => {
    if (selectedOption2 === 'Machine') {
      let updatedmachineData = selectedOption3;
      let updatedSelectedMachineData = selectedOptionData;
      let clientId = userID;
      clientId.splice(userID.indexOf(value.clientId), 1);
      updatedmachineData = selectedOption3.map((machine) =>
        machine.clientId === value.clientId ? { ...machine, checked: false } : machine
      );
      updatedSelectedMachineData = selectedOptionData.filter((machine) => machine.clientId !== value.clientId);
      formik.setFieldValue('optionThree', updatedSelectedMachineData);
      setUserID(clientId);
      setSelectedOptionData(updatedSelectedMachineData);
      setSelectedOption3(updatedmachineData);
      setSelectAll(option3List.every((item) => clientId.includes(item.clientId)));
    } else if (['Created By', 'Modified By', 'RunInitiated By', 'Assign to'].includes(selectedOption2)) {
      let updatedPersonName = selectedOption3;
      let updateUserData = selectedOptionData;
      let userId = userID;

      userId.splice(userID.indexOf(value.id), 1);
      updatedPersonName = selectedOption3.map((user) => (user.id === value.id ? { ...user, checked: false } : user));
      updateUserData = selectedOptionData.filter((user) => user.userId !== value.id);
      formik.setFieldValue('optionThree', updateUserData);
      setUserID(userId);
      setSelectedOptionData(updateUserData);
      setSelectedOption3(updatedPersonName);
      setSelectAll(usersList.every((item) => userId.includes(item.id)));
    }
  };
  const filteredOptions2 =
    dropDownList?.options?.find((option) => option.name === formik.values.optionOne)?.values || [];

  function getLabelText(option) {
    switch (option) {
      case 'Created By':
      case 'Modified By':
      case 'RunInitiated By':
      case 'Assign to':
        return 'Select Users';
      case 'Labels':
        return 'Select Labels';
      case 'Script Type':
        return 'Select Script Types';
      case 'Step Group type':
        return 'Select Step Group Types';
      case 'ExecutionRunType':
        return 'Select ExecutionRunType';
      case 'Status':
        return 'Select Status';
      case 'SuiteName':
        return 'Select Suites';
      case 'Machine':
        return 'Select Machine';
      case 'Element Type':
        return 'Select Element Type';
      case 'Platform Type':
        return 'Select Platform';
      case option:
        return `Select ${option}`;
      default:
        return null;
    }
  }

  useEffect(() => {
    if (props?.actionType === 'Edit') {
      if (formik.values.optionTwo === formik.initialValues.optionTwo) {
        if (['Created By', 'Modified By', 'RunInitiated By'].includes(formik.values.optionTwo)) {
          formik.values.optionThree.sort((a, b) => a?.userId?.localeCompare(b.userId));
          formik.initialValues.optionThree.sort((a, b) => a?.userId?.localeCompare(b.userId));
        } else if (formik.values.optionTwo === 'Machine') {
          formik.values.optionThree.sort((a, b) => a?.clientId?.localeCompare(b.clientId));
          formik.initialValues.optionThree.sort((a, b) => a?.clientId?.localeCompare(b.clientId));
        } else {
          formik.values.optionThree.sort();
          formik.initialValues.optionThree.sort();
        }
      }
    }
  }, [formik.values, formik.initialValues]);
  const isValueChanged = JSON.stringify(formik.values) !== JSON.stringify(formik.initialValues);
  const primaryButtonProps = {
    label: props.actionType === 'Edit' ? DASHBOARD_CONSTANTS.UPDATE : DASHBOARD_CONSTANTS.CREATE,
    disabled: props.actionType === 'Edit' && !isValueChanged && true,
    onClick: () => {
      formik.handleSubmit();
    },
  };
  const secondaryButtonProps = {
    label: DASHBOARD_CONSTANTS.CANCEL,
    disabled: false,
    onClick: props.onClose,
  };

  const getOptionThreeValue = (selectedOption1, selectedOption2, item) => {
    if (['Machine'].includes(selectedOption2)) {
      return selectedOption1 === 'Number of Manual Executions' ? item.id : item.hostName;
    } else {
      return item.name;
    }
  };
  const getChipLabel = (selectedOption1, selectedOption2, value) => {
    if (['Machine'].includes(selectedOption2)) {
      if (selectedOption1 === 'Number of Manual Executions') {
        return value?.id?.length > 25 ? value?.id.substring(0, 25) + '...' : value?.id;
      } else {
        return value?.hostName?.length > 25 ? value?.hostName.substring(0, 25) + '...' : value?.hostName;
      }
    } else {
      return value?.name?.length > 25 ? value?.name.substring(0, 25) + '...' : value?.name;
    }
  };

  return (
    <Drawer
      isOpen={true}
      onClose={props.onClose}
      overlay={true}
      title={
        props.actionType === 'Edit' ? DASHBOARD_CONSTANTS.EDIT_CUSTOM_GRAPH : DASHBOARD_CONSTANTS.ADD_CUSTOMISE_GRAPH
      }
      primaryButtonProps={primaryButtonProps}
      secondaryButtonProps={secondaryButtonProps}
    >
      {haslistdata && (
        <form>
          <div className="custom-graph-box flex flex-col justify-center items-center ">
            <div className="radioGraphContainer">
              <div className="fontPoppinsMediumSm integration-label">
                {
                  <>
                    {' '}
                    <span className="text-red-600">*</span> {'Graph Type'}
                  </>
                }{' '}
              </div>
              <div className="flex-row  items-center">
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={formik.values.graphType}
                    onChange={(event) => {
                      formik.setFieldValue('graphType', event.target.value);
                    }}
                  >
                    <FormControlLabel
                      className="fontPoppinsRegularSm"
                      value="BarGraph"
                      control={<Radio className="radioButton-custom-graph" />}
                      label={
                        <>
                          <div className="flex flex-row -ml-1">
                            <span className="integration-label fontPoppinsRegularSm ">
                              {DASHBOARD_CONSTANTS.BAR_GRAPH}
                            </span>
                            <span className="ml-2 mt-0.5 ">
                              <FiBarChart2 className="icons-style custom-radio-checked" />
                            </span>
                          </div>
                        </>
                      }
                    />
                    <FormControlLabel
                      className="fontPoppinsRegularSm"
                      value="PieChart"
                      control={<Radio className="radioButton-custom-graph" />}
                      label={
                        <>
                          <div className="flex flex-row -ml-1">
                            <span className=" fontPoppinsRegularSm">{DASHBOARD_CONSTANTS.PIE_CHART}</span>
                            <span className="ml-2 mt-0.5">
                              <FaChartPie className="icons-style" />
                            </span>
                          </div>
                        </>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="selectContainer">
              <div className="integration-label fontPoppinsMediumSm">
                {
                  <>
                    {' '}
                    <span className="text-red-600">*</span> {'Option 1'}
                  </>
                }
              </div>
              <div className="select-content-box label-margin">
                <Select
                  name="option1"
                  className="fontPoppinsRegularSm"
                  onChange={(event) => {
                    formik.setFieldValue('optionTwo', '');
                    formik.setFieldValue('optionOne', event.value);
                    handleOption1Change(event);
                  }}
                  options={xAxisLabels?.map((data) => ({
                    label: data.name,
                    value: data.name,
                  }))}
                  selectedOption={{
                    label: formik.values.optionOne || 'Select Option 1',
                    value: formik.values.optionOne || 'Select Option 1',
                  }}
                  error={formik.errors.optionOne && formik.touched.optionOne}
                />
              </div>
              <span className="fontPoppinsRegularXs8px error-message mt-0.5 ml-0.5">
                {formik.touched.optionOne && formik.errors.optionOne}
              </span>
            </div>

            <div className="selectContainer">
              <div className="integration-label fontPoppinsMediumSm">
                {
                  <>
                    {' '}
                    <span className="text-red-600">*</span> {'Option 2'}
                  </>
                }
              </div>
              <div className="select-content-box label-margin mt-1">
                <Select
                  name="option2"
                  className="fontPoppinsRegularSm"
                  onChange={(event) => {
                    formik.setFieldValue('optionThree', []);
                    formik.setFieldValue('optionTwo', event.value);
                    handleOption2Change(event);
                  }}
                  options={(formik.values.optionOne === 'Number of defects' ? yAxisLabels : filteredOptions2)?.map(
                    (value) => ({
                      label: value,
                      value: value,
                    })
                  )}
                  disabled={!formik.values.optionOne}
                  selectedOption={{
                    label: formik.values.optionTwo || 'Select Option 2',
                    value: formik.values.optionTwo || 'Select Option 2',
                  }}
                  error={formik.errors.optionTwo && formik.touched.optionTwo}
                />
              </div>
              {
                <span className="fontPoppinsRegularXs8px error-message mt-0.5 ml-0.5">
                  {formik.touched.optionTwo && formik.errors.optionTwo}
                </span>
              }
            </div>
            {formik.values.optionTwo && (
              <div className="selectContainer">
                <div className="integration-label fontPoppinsMediumSm">
                  <span className="text-red-600">*</span>
                  <span className="ml-1">{getLabelText(formik.values.optionTwo)}</span>
                </div>
                <div className="select-content-box label-margin checked-checkbox">
                  <FormControl className="form-control-select">
                    <MultiSelect
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      value={formik.values.optionThree}
                      error={
                        (formik.errors.optionThree && formik.touched.optionThree) ||
                        (isEmptyValue(option3List) && hasOptionsData)
                      }
                      onChange={(event) => {
                        handleChange(event);
                      }}
                      sx={{ minHeight: '35px' }}
                      className={`customSelect-field customSelect-field-graph select-field-graph custom-input custom-svg ${
                        formik.values.optionThree?.length > 0 ? 'h-auto' : 'custom-field-height'
                      }`}
                      onBlur={formik.handleBlur}
                      input={<Input />}
                      IconComponent={KeyboardArrowDownIcon}
                      onClick={handleIconClick}
                      disabled={isEmptyValue(option3List)}
                      name="option3"
                      placeholder={formik.values.optionThree?.length > 0 ? '' : DASHBOARD_CONSTANTS.SELECT_USERS}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.length === 0 ? (
                            <span>{DASHBOARD_CONSTANTS.SELECT_USERS}</span>
                          ) : (
                            <>
                              {selectAll ? (
                                <span className="selectedText">All</span>
                              ) : (
                                selectedOption3?.map((value) => (
                                  <div className="chip-component-custom-graph">
                                    {['Modified By', 'Created By', 'RunInitiated By', 'Machine', 'Assign to'].includes(
                                      selectedOption2
                                    ) ? (
                                      <>
                                        {value.checked && (
                                          <Tooltip
                                            title={
                                              ['Machine'].includes(selectedOption2)
                                                ? value?.hostName?.length > 25
                                                  ? value?.hostName
                                                  : null
                                                : value?.name?.length > 25
                                                ? value?.name
                                                : null
                                            }
                                            placement="bottom-start"
                                          >
                                            <Chip
                                              key={getOptionThreeValue(selectedOption1, selectedOption2, value)}
                                              label={getChipLabel(selectedOption1, selectedOption2, value)}
                                              clickable
                                              deleteIcon={
                                                <CancelOutlinedIcon
                                                  className=""
                                                  style={{
                                                    height: '9px',
                                                    width: '9px',
                                                  }}
                                                  onMouseDown={(event) => event.stopPropagation()}
                                                />
                                              }
                                              className={`chip-styling   fontPoppinsRegularXs8px `}
                                              onDelete={(e) => handleChipDeleteUser(e, value)}
                                              style={{ height: '19px' }}
                                            />
                                          </Tooltip>
                                        )}
                                      </>
                                    ) : (
                                      <Chip
                                        key={value}
                                        label={value}
                                        clickable
                                        deleteIcon={
                                          <CancelOutlinedIcon
                                            className=""
                                            style={{
                                              height: '9px',
                                              width: '9px',
                                            }}
                                            onMouseDown={(event) => event.stopPropagation()}
                                          />
                                        }
                                        className={`chip-styling   fontPoppinsRegularXs8px `}
                                        onDelete={(e) => handleChipDelete(e, value)}
                                        style={{ height: '19px' }}
                                      />
                                    )}
                                  </div>
                                ))
                              )}
                            </>
                          )}
                        </Box>
                      )}
                      variant="standard"
                      MenuProps={MenuProps}
                    >
                      <section className="menuItems-container">
                        <MenuItem key="all" value="all" dense className="list-options">
                          <Checkbox
                            checked={selectAll}
                            onChange={(event) => {
                              ['Modified By', 'Created By', 'RunInitiated By', 'Machine', 'Assign to'].includes(
                                selectedOption2
                              )
                                ? handleChangeUser(event, option3List)
                                : handleChange(event);
                            }}
                            value="All"
                          />
                          <ListItemText primary=" All" />
                        </MenuItem>
                        {option3List.map((item) => (
                          <MenuItem
                            key={getOptionThreeValue(selectedOption1, selectedOption2, item)}
                            value={getOptionThreeValue(selectedOption1, selectedOption2, item)}
                            dense
                          >
                            <Checkbox
                              checked={
                                ['Modified By', 'Created By', 'RunInitiated By', 'Assign to'].includes(selectedOption2)
                                  ? userID?.includes(item.id) || selectAll
                                  : ['Machine'].includes(selectedOption2)
                                  ? (selectedOption1 === 'Number of Manual Executions'
                                      ? userID?.includes(item.id)
                                      : userID?.includes(item.clientId)) || selectAll
                                  : selectedOption3.includes(item.name)
                              }
                              onChange={(event) => {
                                ['Modified By', 'Created By', 'RunInitiated By', 'Machine', 'Assign to'].includes(
                                  selectedOption2
                                )
                                  ? handleChangeUser(event, item)
                                  : handleChange(event);
                              }}
                              value={getOptionThreeValue(selectedOption1, selectedOption2, item)}
                            />
                            <ListItemText
                              className="list-options"
                              primary={getOptionThreeValue(selectedOption1, selectedOption2, item)}
                            />
                          </MenuItem>
                        ))}
                      </section>
                    </MultiSelect>
                  </FormControl>
                </div>
                {!isEmptyValue(option3List) && (
                  <span className="fontPoppinsRegularXs8px error-message mt-0.5 ml-0.5">
                    {formik.touched.optionThree && formik.errors.optionThree}
                  </span>
                )}
                {isEmptyValue(option3List) && hasOptionsData && (
                  <span className="fontPoppinsRegularXs8px error-message mt-0.5 ml-0.5">
                    {DASHBOARD_CONSTANTS.NO_RESOURCES}
                  </span>
                )}
              </div>
            )}
          </div>
        </form>
      )}
    </Drawer>
  );
};

export default CreateEditGraph;

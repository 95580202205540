import React, { Fragment, useContext, useState, useEffect } from 'react';
import { CommonLoader } from '@src/pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { useTable, useFilters, useGlobalFilter, useSortBy } from 'react-table';
import '../common/react_table.css';
import './userDetails.scss';
import UserCreatePage from './create_component_session/user_create_page';
import Modal from 'react-modal';
import ContextMenu from '../common/context_menu';
import { Dialog, Transition } from '@headlessui/react';
import UserDetailsPage from './create_component_session/user_details_page';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import ProjectDetailsPage from './create_component_session/project_details_page';
import IndividualUserTablePage from './individual_user_table_page';
import {
  getAllUserRequest,
  deletesingleUserRequest,
  addUsersListReq,
  resetActivationLink,
  getUsersByProjectIdReq,
  getSingleUserRequest,
  getSingleRoleRequest,
  deleteUserReq,
} from '@api/api_services';
import { getSelectedProject } from '../../service/common_service';
import RoleDetailsPage from './create_component_session/role_details_page';
import { ColumnFilter } from '../common/table_tree/react_table/ColumnFilter';
import { DateColumnFilter } from '../common/table_tree/react_table/DateColumnFilter';
import {
  textFilterFn,
  fuzzyTextFilterFn,
  multipleRowFilterFn,
  dateBetweenFilterFn,
} from '../common/table_tree/react_table/CommonFunctions';

import cx from 'classnames';
import AlertPopUps from '../common/alert_pop_ups/AlertPopUps';
import { ALERT_MODAL_CONSTANTS } from '@src/common/ui-constants';
import SearchBar from '@src/pages/analytics/common/search-bar';
import { HighlightText, isEmptyValue, isValueIncludes } from '@util/common_utils';
import NoResultsFound from '@src/pages/common/Table/NoResultFound';
import { useLocation } from 'react-router-dom';
import DeleteUserPopup from './DeleteUser';
import { Icon, Button } from 'fireflink-ui';
import { Tooltip } from '@mui/material';

export const UserPage = ({ MyAlert }) => {
  const { WARNING } = ALERT_MODAL_CONSTANTS;
  const loggedInUser = JSON.parse(localStorage.getItem('test-optimize-user'));
  const [message, setMessage] = useState('');
  const [warnOpen, setWarnOpen] = useState(false);
  const [userID, setUserID] = useState('');
  const [userName, setUserName] = useState('');
  const [userSearched, setuserSearched] = useState('');
  let [isHighlight, setIsHighlight] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');
  const [userDeleteModal, setUserDeleteModal] = useState(false);
  const [checkbox, setCheckBox] = useState(true);
  const location = useLocation();

  fuzzyTextFilterFn.autoRemove = (val) => !val;

  const { isAdminOrSuperAdmin, isProjectOpen } = React.useMemo(
    () => ({
      isAdminOrSuperAdmin: window.permission?.getUserRole() !== 'User',
      isProjectOpen: window.permission?.isProjectOpen() || !window.permission?.getSelectedProject().id,
    }),
    []
  );

  useEffect(() => {
    let timeoutId;

    timeoutId = setTimeout(() => {
      setCheckBox(true);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [userDeleteModal]);

  function Table({ columns, data }) {
    const filterTypes = React.useMemo(
      () => ({
        fuzzyText: fuzzyTextFilterFn,
        text: textFilterFn,
        multiple: multipleRowFilterFn,
        dateBetween: dateBetweenFilterFn,
      }),
      []
    );

    const defaultColumn = React.useMemo(
      () => ({
        Filter: ColumnFilter,
      }),
      []
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
      {
        columns,
        data,
        defaultColumn,
        filterTypes,
      },
      useFilters,
      useGlobalFilter,
      useSortBy
    );

    return (
      <>
        <div className="primary-table-height">
          <table className="relative  " {...getTableProps()}>
            <thead className="table_header_color stick-table-thead">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="table_header_color h-12 sticky top-0 zIndex-1">
                  {headerGroup.headers.map((column) => (
                    <th
                      className="sticky top-0 mb-4 px-6 py-4 text-sm  ml-4 z-10"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <span
                        className="uppercase fontPoppinsSemiboldMd flex flex-row mr-10 border-none text-gray-700"
                        style={{
                          color: '#3C3838',
                        }}
                      >
                        {column.render('Header')}
                        <div className="flex">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <AiOutlineArrowDown className="mt-1 ml-1" />
                            ) : (
                              <AiOutlineArrowUp className="mt-1 ml-1" />
                            )
                          ) : (
                            ''
                          )}
                        </div>
                      </span>
                      {column.canFilter ? column.render('Filter') : null}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="divide-y z-10" {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr className="project-row border-none h-12" {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td className="px-4 py-0 mr-2 text-sm " {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {openSearch && isEmptyValue(data) && <NoResultsFound className="mt-14" />}
        </div>
      </>
    );
  }

  function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue >= filterValue;
    });
  }

  filterGreaterThan.autoRemove = (val) => typeof val !== 'number';

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState({});
  const [role, setSelectedRole] = React.useState();
  const { resetProgress, startOrResumeProgress } = useContext(ProgressContainerContext);

  const customStylesProject = {
    content: {
      position: 'absolute',
      top: '1%',
      left: '27%',
      right: '10%',

      width: '960px',
      overflow: 'hidden',
      maxHeight: '1080px',
      background: '#FBFCFD',
    },
  };
  const customStylesDeleteProject = {
    content: {
      position: 'absolute',
      top: '1%',
      left: '25%',
      overflow: 'hidden',
      right: '35%',
      width: '640px',
      maxHeight: 'fit-content',
    },
  };
  const customStylesEditUserIndividual = {
    content: {
      position: 'absolute',
      left: '150px',
      right: '30%',
      overflow: 'hidden',
      height: '541px !important',
      width: '1007px',
      top: '50px',
    },
  };
  const customStylesAddUser = {
    content: {
      position: 'absolute',
      left: '150px',
      right: '30%',
      overflow: 'hidden',
      height: '541px !important',
      width: '1007px',
      top: '50px',
    },
  };
  let call = true;
  const viewUser = (user) => {
    getSingleUserRequest(user.id || user.userId).then((results) => {
      const user = results.data?.responseObject;
      const projects = results.data?.responseMap;
      setSelectedUser({ user, projects });

      setpopupHeaderAndBtn('User Details');
      setShowModal(true);
      call = true;
    });
  };

  function viewCreatedByUser(user) {
    getSingleUserRequest(user.createdBy || user.userId || user).then((results) => {
      const user = results.data.responseObject;
      const projects = results.data.responseMap;
      setSelectedUser({ user, projects });
      setpopupHeaderAndBtn('User Details');
      setShowModal(true);
      call = true;
    });
  }
  const [popupStyle, setpopupStyle] = React.useState();

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        filter: 'multiple',
        disableFilters: true,
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();
              if (call) {
                call = false;
                viewUser(e.cell.row.original);
              }
              setdetailsHeader(event.target.innerText);
            }}
            className="hyper_link_color-common float-left ml-2 fontPoppinsRegularMd cursor-pointer"
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              maxWidth: '150px',
              float: 'left',
            }}
          >
            <span>
              {' '}
              <HighlightText text={e.value} highlight={searchTextHighlight} />
            </span>
          </div>
        ),
      },
      {
        Header: 'Privilege',
        accessor: 'privilege',
        filter: 'multiple',
        disableFilters: true,

        Cell: ({ value }) => {
          return value !== 'Super Admin' ? (
            <div className="table-non-link-color-common fontPoppinsRegularMd float-left ml-2 ">
              {value && value?.charAt(0).toUpperCase() + value.substr(1).toLowerCase()}
            </div>
          ) : (
            <div className="table-non-link-color-common fontPoppinsRegularMd float-left ml-2">{'Super Admin'}</div>
          );
        },
      },
      {
        Header: 'Modified By',
        accessor: 'modifiedByUname',
        filter: 'multiple',
        disableFilters: true,
        Cell: (e) =>
          !e.value || e.value === '--' ? (
            <div className="table-non-link-color-common fontPoppinsRegularMd float-left ml-2 cursor-pointer">--</div>
          ) : (
            <div
              title={e.value}
              onClick={(event) => {
                event.preventDefault();
                if (call) {
                  call = false;
                  const [modifiedByUser] = e.data?.filter((user) => {
                    let tempUser;
                    if (user.name === e.cell.row.original.modifiedByUname) {
                      tempUser = user;
                    }
                    return tempUser;
                  });
                  viewCreatedByUser(modifiedByUser || e.cell.row.original.modifiedBy);
                }
                setdetailsHeader(event.target.innerText);
              }}
              className="hyper_link_color-common float-left ml-2 fontPoppinsRegularMd cursor-pointer"
              style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: '155px',
                float: 'left',
              }}
            >
              <span>
                {' '}
                <HighlightText text={e.value} highlight={searchTextHighlight} />
              </span>
            </div>
          ),
      },
      {
        Header: 'Modified On',
        accessor: 'modifiedOn',
        filter: 'dateBetween',
        disableFilters: true,
        Filter: DateColumnFilter,
        Cell: ({ value }) =>
          !value || value === '--' ? (
            <div className="table-non-link-color-common fontPoppinsRegularMd float-left ml-2">--</div>
          ) : (
            <div className="table-non-link-color-common fontPoppinsRegularMd float-left ml-2">{value}</div>
          ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        filter: 'multiple',
        disableFilters: true,
        Cell: ({ value }) => {
          return value === 'Active' || value === 'ACTIVE' ? (
            <div className="fontPoppinsRegularMd" style={{ color: '#399365', marginLeft: '7px', float: 'left' }}>
              {value.charAt(0).toUpperCase() + value.substr(1).toLowerCase()}
            </div>
          ) : value === 'DISABLED' ? (
            <div className="fontPoppinsRegularMd" style={{ color: 'grey', marginLeft: '7px', float: 'left' }}>
              {value.charAt(0).toUpperCase() + value.substr(1).toLowerCase()}
            </div>
          ) : (
            <div className="fontPoppinsRegularMd" style={{ color: '#C50303', marginLeft: '7px', float: 'left' }}>
              {'Activation Pending'}
            </div>
          );
        },
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common">{value}</div>;
        },
      },
    ],
    [isHighlight]
  );

  const contextMenuData = [
    {
      option: 'Details',
    },
  ];

  const columns1 = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        filter: 'multiple',
        disableFilters: true,
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              if (call) {
                call = false;
                viewUser(e.cell.row.original);
              }
              event.preventDefault();
              setdetailsHeader(event.target.innerText);
            }}
            className="hyper_link_color-common fontPoppinsRegularMd float-left ml-2 cursor-pointer"
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              maxWidth: '130px',
              float: 'left',
            }}
          >
            <span>
              {' '}
              <HighlightText text={e.value} highlight={searchTextHighlight} />
            </span>
          </div>
        ),
      },
      {
        Header: 'Privilege',
        accessor: 'userPrivilege',
        filter: 'multiple',
        disableFilters: true,
        Cell: ({ value }) => {
          return value !== 'Super Admin' ? (
            <div className="table-non-link-color-common fontPoppinsRegularMd float-left ml-2">
              {value && value?.charAt(0).toUpperCase() + value.substr(1).toLowerCase()}
            </div>
          ) : (
            <div className="table-non-link-color-common fontPoppinsRegularMd float-left ml-2">{'Super Admin'}</div>
          );
        },
      },
      {
        Header: 'Role',
        accessor: 'role',
        filter: 'multiple',
        disableFilters: true,
        Cell: (e) => {
          const user = e.row.original;
          return (
            <div
              title={e.value.name}
              onClick={(event) => {
                event.preventDefault();
                if (call) {
                  call = false;
                  viewRole(e.cell.row.original);
                }
              }}
              className="hyper_link_color-common fontPoppinsRegularMd float-left ml-2 cursor-pointer"
              style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: '100px',
                float: 'left',
              }}
            >
              <span>{user && user.role && user.role.name ? user.role.name : '--'}</span>
            </div>
          );
        },
      },
      {
        Header: 'Modified By',
        accessor: 'modifiedByUname',
        filter: 'multiple',
        disableFilters: true,
        Cell: (e) =>
          e.value === '--' ? (
            <div className="table-non-link-color-common fontPoppinsRegularMd float-left ml-2 cursor-pointer">
              <HighlightText text={e.value} highlight={searchTextHighlight} />
            </div>
          ) : (
            <div
              title={e.value}
              onClick={(event) => {
                event.preventDefault();
                if (call) {
                  call = false;
                  const [modifiedByUser] = e.data?.filter((user) => {
                    let tempUser;
                    if (user.name === e.cell.row.original.modifiedByUname) {
                      tempUser = user;
                    }
                    return tempUser;
                  });
                  viewCreatedByUser(modifiedByUser || e.cell.row.original.modifiedBy);
                }
                setdetailsHeader(event.target.innerText);
              }}
              className="hyper_link_color-common float-left ml-2 fontPoppinsRegularMd cursor-pointer"
              style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: '120px',
                float: 'left',
              }}
            >
              <HighlightText text={e.value} highlight={searchTextHighlight} />
            </div>
          ),
      },
      {
        Header: 'Modified On',
        accessor: 'modifiedOn',
        filter: 'dateBetween',
        disableFilters: true,
        Filter: DateColumnFilter,
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common fontPoppinsRegularMd float-left ml-2 overflow-hidden max-w-[160px] text-ellipsis whitespace-nowrap">
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'activationStatus',
        filter: 'multiple',
        disableFilters: true,
        Cell: ({ value }) => {
          return value === 'ACTIVE' ? (
            <div className="fontPoppinsRegularMd" style={{ color: '#399365', marginLeft: '7px', float: 'left' }}>
              {value.charAt(0).toUpperCase() + value.substr(1).toLowerCase()}
            </div>
          ) : value === 'DISABLED' ? (
            <div className="fontPoppinsRegularMd" style={{ color: 'grey', marginLeft: '7px', float: 'left' }}>
              {value.charAt(0).toUpperCase() + value.substr(1).toLowerCase()}
            </div>
          ) : (
            <div className="fontPoppinsRegularMd" style={{ color: '#C50303', marginLeft: '7px', float: 'left' }}>
              {'Activation Pending'}
            </div>
          );
        },
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common flex items-center justify-end max-w-[160px] ml-8">{value}</div>
          );
        },
      },
    ],
    [isHighlight]
  );

  function viewRole(roleObj) {
    getSingleRoleRequest(roleObj.role.id).then((results) => {
      const role = results.data.responseObject;

      setSelectedRole(role);
      setShowModal(true);
      setpopupHeaderAndBtn('Role Details');
      setdetailsHeader(role.name);
      call = true;
    });
  }

  function callFunction(functionName) {
    if (functionName === 'getAllUsers') {
      getAllUsers();
    } else if (functionName === 'closePopup') {
      closePopup();
    }
  }

  function contextMenuFunction(event, resObj) {
    if (event.target.textContent === 'Details') {
      setdetailsHeader(resObj.name);
      viewUser(resObj);
    } else if (event.target.textContent === 'Resend activation link') {
      const payload = {
        emailId: resObj.emailId,
      };
      resetActivationLink(payload)
        .then((res) => {
          if (res.status && res.status === 200) {
            MyAlert.success('Activation Link sent  successfully');
          }
        })
        .catch((error) => {
          console.error('error', error);
        });
    } else if (event.target.textContent === 'Delete') {
      setWarnOpen(true);
      setMessage(`are you sure you want to remove ${resObj.name}`);
      setUserID(resObj.userId);
      setUserName(resObj.name);
    }
  }
  let deleteUserFromLicense = async (userId, changeSuiteAccess) => {
    try {
      let { data } = await deleteUserReq(userId, changeSuiteAccess);
      if (data?.responseCode === 200 && data?.status === 'SUCCESS') {
        initUsersList();
        MyAlert.success(`${selectedUser?.name} user has been removed from license`);
        setUserDeleteModal(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  let handleUserDelete = () => {
    setWarnOpen(false);
    deletesingleUserRequest(userID)
      .then((res) => {
        if (res.data.status === 'SUCCESS') {
          MyAlert.success(`${userName} ${res.data.message}`);
        } else {
          MyAlert.info(res.data.message);
        }
        initUsersList();
      })
      .catch((error) => console.error(error));
  };

  function getAllUsers() {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    getAllUserRequest()
      .then((results) => {
        if (results.status === 200) {
          resetProgress();
        }
        setIsLoaded(true);
        let response = results.data.responseObject ? results.data.responseObject : [];
        let usersList = [];

        let tempArray = response.license.licenseUsers;
        const userPrivilege = JSON.parse(localStorage.getItem('test-optimize-user')).privilege;
        const _userId = JSON.parse(localStorage.getItem('test-optimize-user')).id;

        tempArray.map((obj, i) => {
          obj.actions = [
            <div className="ml-1 mx-1 flex items-center">
              {isAdminOrSuperAdmin && isProjectOpen && (
                <span data-title="Edit" id="remove1" className="px-1">
                  {userPrivilege === 'Super Admin' ? (
                    <button
                      disabled={userPrivilege === 'Super Admin' && obj.userId === _userId}
                      className=" float-left project-action  state-button cursor-pointer"
                      onClick={() => {
                        setShowModal(true);
                        setSelectedUser(obj);
                        setpopupHeaderAndBtn('Edit User', 'Update');
                        setdetailsHeader(obj.name);
                      }}
                    >
                      <img src="/assets/images/edit_blue.svg" alt="edit-icon" className="h-5 w-5" />
                    </button>
                  ) : userPrivilege === 'Admin' ? (
                    <button
                      disabled={['Super Admin'].includes(obj.privilege) || obj.userId === _userId}
                      className=" float-left project-action  state-button cursor-pointer"
                      onClick={() => {
                        setShowModal(true);
                        setSelectedUser(obj);
                        setpopupHeaderAndBtn('Edit User', 'Update');
                        setdetailsHeader(obj.name);
                      }}
                    >
                      <img src="/assets/images/edit_blue.svg" alt="edit-icon" className="h-5 w-5" />
                    </button>
                  ) : (
                    <button
                      disabled
                      className=" float-left project-action  state-button cursor-pointer"
                      onClick={() => {
                        setShowModal(true);
                        setSelectedUser(obj);
                        setpopupHeaderAndBtn('Edit User', 'Update');
                        setdetailsHeader(obj.name);
                      }}
                    >
                      <img src="/assets/images/edit_blue.svg" alt="edit-icon" className="h-5 w-5" />
                    </button>
                  )}
                </span>
              )}
              {isAdminOrSuperAdmin && isProjectOpen && (
                <Tooltip title="Remove User">
                  <span id="remove1" className="px-2 ml-1">
                    {userPrivilege === 'Super Admin' ? (
                      <button
                        disabled={userPrivilege === 'Super Admin' && obj.userId === _userId}
                        className=" float-left project-action  state-button cursor-pointer"
                        onClick={() => {
                          setSelectedUser(obj);
                          setUserDeleteModal(true);
                        }}
                      >
                        <Icon name="Remove_user" height={16} width={16} />
                      </button>
                    ) : userPrivilege === 'Admin' ? (
                      <button
                        disabled={['Super Admin'].includes(obj.privilege) || obj.userId === _userId}
                        className=" float-left project-action  state-button cursor-pointer"
                        onClick={() => {
                          setSelectedUser(obj);
                          setUserDeleteModal(true);
                        }}
                      >
                        <Icon name="Remove_user" height={16} width={16} />
                      </button>
                    ) : (
                      <button
                        disabled
                        className=" float-left project-action  state-button cursor-pointer"
                        onClick={() => {
                          setSelectedUser(obj);
                          setUserDeleteModal(true);
                        }}
                      >
                        <Icon name="Remove_user" height={16} width={16} />
                      </button>
                    )}
                  </span>
                </Tooltip>
              )}

              <div className="project-action cursor-pointer">
                <ContextMenu
                  contextMenuData={
                    obj.status === 'ACTIVE' || obj.status === 'DISABLED' || userPrivilege === 'User'
                      ? contextMenuData
                      : userPrivilege === 'Super Admin' ||
                        (userPrivilege === 'Admin' && ['Admin', 'User'].includes(obj.privilege))
                      ? [
                          ...contextMenuData,
                          {
                            active: true,
                            option: 'Resend activation link',
                          },
                          {
                            active: true,
                            option: 'Delete',
                          },
                        ]
                      : contextMenuData
                  }
                  func={(e) => {
                    contextMenuFunction(e, obj);
                  }}
                />
              </div>
            </div>,
          ];
        });

        usersList = [...usersList, ...response.license.licenseUsers];

        setUsers(usersList);
      })
      .catch((error) => {
        resetProgress();
        setIsLoaded(true);
      });

    return [];
  }

  function getUsersByProjectId(selectedProject) {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    getUsersByProjectIdReq(selectedProject.id, 'sss')
      .then((results) => {
        if (results.status === 200) {
          resetProgress();
        }
        setIsLoaded(true);
        const rawData = [];

        let response = results.data?.responseObject ? results.data.responseObject : [];
        response = response.map(({ user: res, role }, i) => {
          res.role = role;

          let projectLicenseId = JSON.parse(localStorage.getItem('test-optimize-user')).licenseId;
          const userPrivilegeIndivisual = JSON.parse(localStorage.getItem('test-optimize-user')).privilege;
          const ProjectStatus = JSON.parse(localStorage.getItem('selected-project')).status;

          res.licenses.map((data) => {
            if (data.id === projectLicenseId) {
              res.actions = [
                <div className="-ml-20 flex items-center">
                  {isAdminOrSuperAdmin && isProjectOpen && (
                    <span data-title="Edit" id="remove" className="py-0 px-1 mr-0 rounded-l">
                      <button
                        disabled={data.privilege === 'Super Admin' || data.privilege === 'Admin'}
                        style={{ marginRight: '3px' }}
                        className="project-action  state-button py-0 px-1 rounded-l cursor-pointer"
                        onClick={() => {
                          setShowModal(true);
                          setSelectedUser(res);
                          setpopupHeaderAndBtn('Edit User', 'Update');
                          setdetailsHeader(res.name);
                        }}
                      >
                        <img src="/assets/images/edit_blue.svg" alt="edit-icon" height="20px" width="20px" />
                      </button>
                    </span>
                  )}

                  <div className="project-action cursor-pointer mr-12">
                    {isAdminOrSuperAdmin && isProjectOpen && (
                      <span id="remove" data-title="Remove" className="mt-1 px-1 rounded-l inline-block ">
                        <button
                          disabled={data.privilege === 'Super Admin' || data.privilege === 'Admin'}
                          style={{ marginRight: '3px' }}
                          className="project-action  state-button  cursor-pointer "
                          onClick={() => {
                            setOpen(true);
                            setSelectedUser(res);
                            setpopupHeaderAndBtn('Delete');
                          }}
                        >
                          <img
                            src="/assets/images/Remove_user.svg"
                            alt="remove-user"
                            color="blue"
                            height="20px"
                            width="20px"
                          />
                        </button>
                      </span>
                    )}
                    <span
                      className={`project-action cursor-pointer ${
                        userPrivilegeIndivisual === 'User' || ProjectStatus === 'Closed' ? 'ml-10' : 'text-left'
                      }`}
                    >
                      <ContextMenu
                        contextMenuData={
                          data.licenseStatus === 'ACTIVE' ||
                          data.licenseStatus === 'Active' ||
                          data.licenseStatus === 'DISABLED'
                            ? contextMenuData
                            : [
                                ...contextMenuData,
                                {
                                  active: true,
                                  option: 'Resend activation link',
                                },
                              ]
                        }
                        func={(e) => {
                          contextMenuFunction(e, res);
                        }}
                      />
                    </span>
                  </div>
                </div>,
              ];
              const getUserList = { ...res, userPrivilege: data.privilege };
              rawData.push(getUserList);
            }
          });

          return res;
        });

        setUsers(rawData);

        setuserSearched('');
        setOpenSearch(false);
        setIsHighlight(!isHighlight);
      })
      .catch((error) => {
        console.error(error);
        resetProgress();
        setIsLoaded(true);
        setUsers([]);
      });
  }

  const initUsersList = () => {
    const selectedProject = getSelectedProject();
    const isAllProjectLevel = location.pathname === '/projectmenu/user';
    if (isAllProjectLevel) {
      getAllUsers();
    } else {
      getUsersByProjectId(selectedProject);
    }
  };

  React.useEffect(() => {
    initUsersList();
  }, []);

  // TODO :: handle Search filter :: START

  const [openSearch, setOpenSearch] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(users);

    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [users]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(users);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setData(users);
    setIsHighlight(!isHighlight);
    setSearchTextHighlight('');
  };
  let handleSearchOpen = () => {
    setOpenSearch(true);
  };
  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = users.filter(({ name, modifiedByUname }) => {
          return isValueIncludes(e, name) || isValueIncludes(e, modifiedByUname);
        });
        setData(newData);
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };
  // TODO :: handle Search filter :: END

  const [popupHeader, setpopupHeader] = React.useState();
  const [popupBtn, setpopupbtn] = React.useState();
  const [showModal, setShowModal] = React.useState(false);

  const setpopupHeaderAndBtn = (header, btn) => {
    setpopupHeader(header);
    setpopupbtn(btn);
    if (header === 'Add user to License') {
      setpopupStyle(customStylesDeleteProject);
    } else if (header === 'Edit User' && tab !== 'user-individual') {
      setpopupStyle(customStylesDeleteProject);
    } else if (header === 'Add user to Project') {
      setpopupStyle(customStylesAddUser);
    } else if (header === 'Edit User' && tab === 'user-individual') {
      setpopupStyle(customStylesEditUserIndividual);
    } else {
      setpopupStyle(customStylesProject);
    }
  };

  const onSaveCompleted = () => {
    setShowModal(false);
    initUsersList();
  };

  const onCancelClicked = () => {
    setShowModal(false);
    setWarnOpen(false);
  };

  function closePopup() {
    setShowModal(false);
  }

  const [open, setOpen] = React.useState(false);
  let handelDlt = (load = undefined) => {
    const selectedProject = getSelectedProject();
    const payload = [
      {
        id: selectedUser.id,
        projectRoles: [],
      },
    ];

    if (selectedProject?.id === 'all' || selectedProject?.id === '') {
      deletesingleUserRequest(selectedUser.id)
        .then((res) => {
          if (res) {
            if (res.data.responseObject) {
              MyAlert.success(`${selectedUser.name} User removed successfully`);
            }

            setOpen(false);
          }
          initUsersList();
        })
        .catch((error) => console.error(error));
    } else {
      addUsersListReq(selectedProject.id, payload)
        .then((d) => {
          if (d) {
            if (d.data.responseObject) {
              MyAlert.success(`${selectedUser.name} User removed successfully`);
            }
            setOpen(false);
          }
          initUsersList();
        })
        .catch((error) => console.error('Delete user API failed...', error));
    }
  };
  const [disabled, setDisabled] = React.useState(false);
  const [detailsHeader, setdetailsHeader] = React.useState();
  const tab = window.location.pathname.split('/')[2];

  if (!isLoaded) {
    return <CommonLoader />;
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={open}
          onClose={() => setOpen(true)}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className="inline-block marginupper align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/4"
                onBlur={() => {
                  setDisabled(false);
                }}
                style={{ width: '640px', height: '217px' }}
              >
                <div className="inline-block">
                  <div className="flex flex-start ml-2">
                    <span className="pt-5 pl-3">
                      <img src="/assets/images/remove-user-icon.svg" alt="remove-user" height="25px" width="25px" />
                    </span>
                    <Dialog.Title as="h3" className="leading-6 pl-4 pt-2 text-gray-900">
                      <div className="fontPoppinsSemiboldLg error-popup-header">Warning.</div>
                      <div className="fontPoppinsSemiboldMd error-popup-content">Remove User</div>
                    </Dialog.Title>
                  </div>
                </div>

                <hr className="line mt-px" />
                <div className="fontPoppinsSemiboldMd mt-3 text-gray-500 ml-14 mr-8">
                  <p>Are you sure you want to remove {selectedUser?.name} from the project?</p>
                  <p className="mt-2">How do you want to proceed?</p>
                </div>

                {selectedUser?.name?.length < 15 ? (
                  <>
                    <hr className="line mt-6" />
                    <div className="sm:mt-2 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="primary-btn cancel-button-layout-style-delete-project ml-6 mr-6"
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="gray-btn"
                        onClick={() => {
                          handelDlt();
                          setDisabled(true);
                          setInterval(() => {
                            setDisabled(false);
                          }, 3000);
                        }}
                        disabled={disabled}
                      >
                        <span> Remove</span>
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <hr className="line mt-4" />
                    <div style={{ width: '100%', height: '3px' }}></div>
                    <div className="sm:mt-2 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="primary-btn cancel-button-layout-style-delete-project ml-6 "
                        onClick={() => setOpen(false)}
                      >
                        <span>Cancel</span>
                      </button>
                      <button
                        type="button"
                        className="gray-btn"
                        onClick={() => {
                          handelDlt();
                          setDisabled(true);
                        }}
                        disabled={disabled}
                      >
                        <span> Remove</span>
                      </button>
                    </div>
                  </>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={userDeleteModal} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={open}
          onClose={() => setUserDeleteModal(true)}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block marginupper  align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-40v">
                <div className="flex">
                  <div className="ml-2 p-4">
                    <span className="pt-5 pl-3">
                      <Icon name="warning_icon" height={60} width={60} />
                    </span>
                  </div>
                  <div className="inline-block">
                    <div className="leading-6 pl-4 pt-7 text-gray-900">
                      <span className="fontPoppinsSemiboldXX2 text-yellow-400">Warning!</span>
                      <span className="fontPoppinsSemiboldXX2 ml-1">Remove</span>
                    </div>
                    <div className="pt-3 pl-4">
                      <DeleteUserPopup data={selectedUser} checkbox={checkbox} setCheckBox={setCheckBox} />
                    </div>
                  </div>
                </div>
                <div>
                  <hr className="line mt-6" />
                  <div className="pr-3 md:mt-0 sm:mt-2 py-1 background-for-btn">
                    <div className="relative right-2 sm:flex sm:flex-row-reverse gap-4">
                      <Button
                        label="Button"
                        variant="primary"
                        size="medium"
                        onClick={() => {
                          deleteUserFromLicense(selectedUser?.userId, checkbox);
                        }}
                      >
                        <span>Confirm</span>
                      </Button>
                      <Button
                        label="Button"
                        variant="primary"
                        size="medium"
                        onClick={() => {
                          setUserDeleteModal(false);
                        }}
                      >
                        <span> Cancel</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Modal
        isOpen={showModal}
        onRequestClose={(e) => {
          if (e.key === 'Escape') {
            setShowModal(false);
          }
        }}
        style={popupStyle}
      >
        <div>
          <div className="Popup-header-common Popup-header-layout-style-user">
            {popupHeader === 'Add user to License' ? (
              <label> {popupHeader}</label>
            ) : popupHeader === 'Edit User' ? (
              <label
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  maxWidth: '250px',
                  float: 'left',
                  fontSize: '16px',
                }}
              >
                {' '}
                {popupHeader} - {detailsHeader}
              </label>
            ) : popupHeader === 'User Details' ? (
              <label>
                {' '}
                {popupHeader} - {detailsHeader}
              </label>
            ) : popupHeader === 'Project Details' ? (
              <label> {popupHeader} - Just Dial</label>
            ) : popupHeader === 'Add user to Project' ? (
              <label> {popupHeader} </label>
            ) : popupHeader === 'Edit  User' ? (
              <label>
                {' '}
                {popupHeader} - {detailsHeader}
              </label>
            ) : popupHeader === 'Role Details' ? (
              <label>
                {' '}
                {popupHeader} - {detailsHeader}
              </label>
            ) : null}
          </div>
          <button
            onClick={() => setShowModal(false)}
            type="button"
            className="float-right -mt-6 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        {(popupHeader === 'Add user to License' || popupHeader === 'Edit User') && tab === 'user' ? (
          <div className="mt-3.5 border-t border-gray-200" style={{ width: '104%', marginLeft: '-18px' }}>
            <UserCreatePage
              func={callFunction}
              user={selectedUser}
              button={popupBtn}
              popupHeader={popupHeader}
              MyAlert={MyAlert}
              userCount={users}
            />
          </div>
        ) : popupHeader === 'User Details' ? (
          <div className="mt-2 border-t border-#e2e5ec-200" style={{ width: '104%', marginLeft: '-18px' }}>
            <UserDetailsPage userDetails={users} selectedUser={selectedUser} />
            <div className=" border-t ">
              <div className="user_delete_btn_style_userdetails ">
                <button onClick={() => setShowModal(false)} className="gray-btn mr-3">
                  Close
                </button>
              </div>
            </div>
          </div>
        ) : popupHeader === 'Project Details' ? (
          <div className="m-3.5 border-t border-gray-200">
            <ProjectDetailsPage />
          </div>
        ) : popupHeader === 'Role Details' ? (
          <div className="m-3.5 border-t border-gray-200" style={{ width: '104%', marginLeft: '-18px' }}>
            <RoleDetailsPage roleDetails={role} />
            <div className=" border-t  ">
              <div className="user_delete_btn_style_userdetails">
                <button onClick={() => setShowModal(false)} className="gray-btn" style={{ marginRight: '35px' }}>
                  <span>Close</span>
                </button>
              </div>
            </div>
          </div>
        ) : popupHeader === 'Add user to Project' ? (
          <div className="m-3.5 border-t border-gray-200" style={{ width: '104%', marginLeft: '-18px' }}>
            <IndividualUserTablePage
              action={popupHeader}
              cancelClicked={onCancelClicked}
              saveCompleted={onSaveCompleted}
              MyAlert={MyAlert}
            />
          </div>
        ) : popupHeader === 'Edit User' && tab === 'user-individual' ? (
          <div className="m-3.5 border-t border-gray-200" style={{ width: '104%', marginLeft: '-18px' }}>
            <IndividualUserTablePage
              action={popupHeader}
              user={selectedUser}
              cancelClicked={onCancelClicked}
              saveCompleted={onSaveCompleted}
              MyAlert={MyAlert}
            />
          </div>
        ) : null}
      </Modal>
      <div className="content_area_style">
        {tab === 'user-individual' ? (
          <div className="table-height overflow-scroll overflow-x-hidden">
            <div
              className={cx(
                'pb-3 border_style flex flex-wrap items-center stick-table-parent-header',
                isAdminOrSuperAdmin ? 'pt-3' : 'pt-6'
              )}
            >
              <label className="project_label text-sm flex-auto">Users List</label>
              <div className="pr-4">
                <div className="flex flex-row items-center">
                  <SearchBar
                    open={openSearch}
                    searchValue={userSearched}
                    handleSearch={handleSearch}
                    onSearchClose={handleSearchClose}
                    handleSearchOpen={handleSearchOpen}
                    enterKeyHandler={handleSearchSubmit}
                    disable={isEmptyValue(users)}
                  />
                  {isAdminOrSuperAdmin && isProjectOpen && (
                    <>
                      {tab === 'user-individual' && (
                        <button
                          className="primary-btn ml-4"
                          onClick={() => {
                            setShowModal(true);
                            setpopupHeaderAndBtn('Add user to Project', 'Add');
                          }}
                        >
                          Add User
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <Table columns={columns1} data={data} />
          </div>
        ) : tab === 'user' ? (
          <>
            <div className="alert-position-style">
              <AlertPopUps
                open={warnOpen}
                alertType={WARNING}
                showHowToProceed={true}
                showCancelBtn={true}
                showSaveBtn={true}
                saveBtnText="Confirm"
                onCancelBtnClick={onCancelClicked}
                onSaveBtnClick={handleUserDelete}
                content={[<div className="mb-2 capitalize">Hi {loggedInUser?.name},</div>, <div>{message}</div>]}
              />
            </div>
            <div className="table-height overflow-scroll overflow-x-hidden ">
              <div
                className={cx(
                  'pb-3 border_style flex flex-wrap items-center stick-table-parent-header',
                  isAdminOrSuperAdmin ? 'pt-3' : 'pt-6'
                )}
              >
                <label className="project_label text-sm flex-auto">Users List</label>
                <div className="pr-4">
                  <div className="flex flex-row items-center">
                    <SearchBar
                      open={openSearch}
                      searchValue={userSearched}
                      handleSearch={handleSearch}
                      onSearchClose={handleSearchClose}
                      handleSearchOpen={handleSearchOpen}
                      enterKeyHandler={handleSearchSubmit}
                      disable={isEmptyValue(users)}
                    />
                    {isAdminOrSuperAdmin && (
                      <>
                        {tab === 'user' && (
                          <button
                            className="primary-btn ml-4"
                            onClick={() => {
                              setShowModal(true);
                              setSelectedUser((d) => ({ id: '' }));
                              setpopupHeaderAndBtn('Add user to License', 'Create');
                            }}
                          >
                            + User
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <Table columns={columns} data={data} />
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default UserPage;

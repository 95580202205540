import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@material-ui/core/styles';
import '../../response_headers/css/response_headers.scss';
import { fontPoppins } from '@src/css_config/fontConstants';
import { colors } from '@src/css_config/colorConstants.js';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: `${colors.rs_primary} 0% 0% no-repeat padding-box`,
    height: 30,
    padding: 0,
    paddingLeft: 16,
    color: `${colors.text_white}`,
    border: 'none',
    ...fontPoppins.sSm,
  },
  [`&.${tableCellClasses.body}`]: {
    color: `${colors.text_black}`,
    ...fontPoppins.rSm,
  },
}));

const CapturedDataFromResponse = (props) => {
  const [projectTypeList, setProjectTypeList] = useState([]);
  const projectTypeHeader = ['Variable Name', 'Variable Type', 'Captured Value'];

  useEffect(() => {
    if (props.capturedDataFromResponseValue === '') {
      setProjectTypeList([]);
    } else {
      setProjectTypeList(props.capturedDataFromResponseValue);
    }
  }, [props.capturedDataFromResponseValue]);
  return (
    <div
      className="flex flex-wrap border-light-red-300 relative parent-container-response-header bg-white"
      id="journal-scroll"
    >
      <div className="cont_div relative">
        {!projectTypeList?.length && (
          <Paper sx={{ width: '100%', boxShadow: 'none', marginTop: '10px' }}>
            <TableContainer
              sx={{ borderRadius: '14px 14px 0 0', height: '283px' }}
              className="captured-response-scrollbar"
            >
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{ [`& .${tableCellClasses.root}`]: { width: '22%', padding: '6px 15px' } }}
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell className="text-xs ml-2">
                      Name<span className="key-later"></span>
                    </StyledTableCell>
                    {projectTypeHeader.map((item) => (
                      <StyledTableCell className="ml-2">
                        <span className="">{item}</span>
                      </StyledTableCell>
                    ))}
                    <StyledTableCell className="text-xs ml-2">
                      <span className="ml-5">|</span>
                      <span className="key-later ml-5">Total: {props.capturedDataFromResponseValue?.length}</span>{' '}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    height: props.requestSectionCollapse ? 254 : 249,
                    display: 'block',
                    width: '454.6%',
                    borderBottom: `2px solid ${colors.light_gray_50}`,
                    borderLeft: `2px solid ${colors.light_gray_50}`,
                    borderRight: `2px solid ${colors.light_gray_50}`,
                  }}
                ></TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
        {projectTypeList?.length > 0 && (
          <Paper sx={{ width: '100%', boxShadow: 'none', marginTop: '10px' }}>
            <TableContainer
              sx={{ borderRadius: '14px 14px 0 0', height: '283px' }}
              className="captured-response-scrollbar"
            >
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: '2px solid #F2F2F2',
                    width: '22%',
                    padding: '6px 15px',
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell className="text-xs ml-2">
                      Name<span className="key-later"></span>
                    </StyledTableCell>
                    {projectTypeHeader.map((item) => (
                      <StyledTableCell className="ml-2">
                        <span className="">{item}</span>
                      </StyledTableCell>
                    ))}
                    <StyledTableCell className="text-xs ml-2">
                      <span className="ml-5">|</span>
                      <span className="key-later ml-5">Total: {props.capturedDataFromResponseValue?.length}</span>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ overflowY: 'auto', maxHeight: '400px' }}>
                  {projectTypeList &&
                    projectTypeList.map((row) => (
                      <TableRow key={`${row.id}`} className="capture-response-alignment-rows">
                        <TableCell
                          component="th"
                          scope="row"
                          className="flex flex-wrap captured-response-value"
                          sx={{
                            color: colors.text_black,
                            ...fontPoppins.rSm,
                            opacity: '1',
                            borderRight: `2px solid ${colors.silver}`,
                          }}
                        >
                          <div className="flex flex-row">
                            {row?.name ? (
                              <CustomTooltip responseTooltip={true} title={<span>{row?.name}</span>}>
                                <span className="text-black">
                                  {' '}
                                  {row?.name === '' ? (
                                    'NULL'
                                  ) : row?.name?.length >= 15 ? (
                                    <span className="lengthyCharName">{row?.name}</span>
                                  ) : (
                                    <span>{row?.name}</span>
                                  )}{' '}
                                </span>
                              </CustomTooltip>
                            ) : (
                              <CustomTooltip responseTooltip={true} title={<span>{row?.group}</span>}>
                                <span className="text-black">
                                  {' '}
                                  {row?.group === '' ? (
                                    'NULL'
                                  ) : row?.group?.length >= 15 ? (
                                    <span className="lengthyCharName">{row?.group}</span>
                                  ) : (
                                    <span>{row?.group}</span>
                                  )}{' '}
                                </span>
                              </CustomTooltip>
                            )}
                          </div>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className="captured-response-value"
                          sx={{
                            color: colors.text_black,
                            ...fontPoppins.rSm,
                            opacity: '1',
                            borderRight: `2px solid ${colors.silver}`,
                          }}
                        >
                          <div className="flex flex-row tabelCellContentValue">
                            <CustomTooltip responseTooltip={true} title={<span>{row?.variable?.name}</span>}>
                              <span className="text-black">
                                {' '}
                                {row?.variable?.name === '' ? (
                                  'NULL'
                                ) : row?.variable?.name?.length >= 25 ? (
                                  <span className="lengthy-char-name">{row?.variable?.name}</span>
                                ) : (
                                  <span>{row?.variable?.name}</span>
                                )}{' '}
                              </span>
                            </CustomTooltip>
                          </div>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className="captured-response-value"
                          sx={{
                            color: colors.text_black,
                            ...fontPoppins.rSm,
                            opacity: '1',
                            borderRight: `2px solid ${colors.silver}`,
                          }}
                        >
                          <div className="flex flex-row tabelCellContentValue">
                            <CustomTooltip responseTooltip={true} title={<span>{row?.variable?.type}</span>}>
                              <span className="text-black">
                                {' '}
                                {row?.variable?.type === '' ? (
                                  'NULL'
                                ) : (row?.variable?.type).length >= 15 ? (
                                  <span>{row?.variable?.type}</span>
                                ) : (
                                  <span>{row?.variable?.type}</span>
                                )}{' '}
                              </span>
                            </CustomTooltip>
                          </div>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className="captured-response-value"
                          sx={{ color: colors.text_black, ...fontPoppins.rSm, opacity: '1' }}
                        >
                          <div className="flex flex-row tabelCellContentValue">
                            <CustomTooltip responseTooltip={true} title={<span>{row?.variable?.value}</span>}>
                              <span className="text-black">
                                {' '}
                                {row?.variable?.value === '' ? (
                                  'NULL'
                                ) : row?.variable?.value?.length >= 25 ? (
                                  <span className="lengthy-char-name">{row?.variable?.value}</span>
                                ) : (
                                  <span>{row?.variable?.value}</span>
                                )}{' '}
                              </span>
                            </CustomTooltip>
                          </div>
                        </TableCell>
                        <TableCell component="th" scope="row" className="captured-response-value">
                          <div className="flex flex-row tabelCellContentValue"></div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </div>
    </div>
  );
};

export default CapturedDataFromResponse;

import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import * as yup from 'yup';
import {
  createDataproviderReq,
  getAllExcelFilesSearchReq,
  getAllExcelFilesSheetsReq,
  updateDataproviderReq,
} from '@api/api_services';
import '@pages/test-development/test-development.scss';
import '@src/index.css';
import { AddToPhotosOutlined, Clear, Sync } from '@material-ui/icons';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useAlert } from '@pages/common/alert_service/useAlert';
import { LABELS_CONSTANTS } from '@src/common/ui-constants';

const VariableNameTextfield = (props) => {
  const [value, setValue] = useState(props.value);
  const [error, setError] = useState(props.error);

  const handleChange = (e) => {
    setValue(e.target.value);
    props.handleChange(e.target.value, props.index);
    props.setFieldValue('dirty', true);
  };

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  useEffect(() => {
    if (value !== props.value) {
      setValue(props.value);
    }
  }, [props.value]);
  return (
    <div className={error ? 'mt-9' : 'mt-2'}>
      <TextField
        error={error ? true : false}
        className="w-11/12 "
        type="text"
        name={`variable${props.index + 1}`}
        id={`variable${props.index + 1}`}
        onChange={handleChange}
        value={value}
        autoComplete="off"
      />
      {error && <div className="text-red-400 fontPoppinsRegularSm md:mt-1">{error}</div>}
    </div>
  );
};
const SearchableSelect = (props) => {
  const [value, setValue] = React.useState(props.value);
  const [inputValue, setInputValue] = React.useState('');
  const handleChange = (value) => {
    setValue(value);
    props.handleSelectedHeader(value, props.index);
    props.setFieldValue('dirty', true);
  };

  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value);
    }
  }, [props.value]);
  return (
    <div>
      <Autocomplete
        className="mt-1 fontPoppinsRegularMd w-11/12"
        value={value}
        onChange={(event, newValue) => {
          handleChange(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id={`searchable-dropdown${props.index}`}
        options={props.options}
        sx={{ width: 300 }}
        disableClearable
        getOptionDisabled={(option) => {
          let x = false;
          props.selectedHeaders.forEach((header, i) => {
            if (option === header.dropDownValue) {
              x = true;
            }
          });
          return x;
        }}
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            InputLabelProps={{
              shrink: true,
            }}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              readOnly: true,
            }}
          />
        )}
      />
    </div>
  );
};

const TableRow = (props) => {
  const index = props.index;
  const columnHeader = props.columnHeader;
  const [dropDownValue, setDropDownValue] = useState(props?.row?.dropDownValue);
  const [variableTextfieldValue, setVariableTextfieldValue] = useState(props?.row?.variableValue);
  const [variableTextfieldError, setVariableTextfieldError] = useState('');
  const tableBodyData = props.tableBodyData;
  function checkValidation(data, indexValue) {
    let text = data;
    let duplicateExist = false;
    let pattern = /^[-_A-Za-z0-9 ]*$|^[-_A-Za-z0-9 ][A-Za-z0-9 ]*[-_A-Za-z-_]$/;
    if (!text) {
      return 'Variable name is required';
    }
    if (text) {
      const result = pattern.test(text);
      if (!result) {
        return 'Variable name should be alphanumeric';
      } else {
        let childPattern = /^[-a-zA-Z0-9-()_]+(\s+[-a-zA-Z0-9-()_]+)*$/;
        let onlySpecialCharPattern = /[a-zA-Z]/;
        const childResult = childPattern.test(text);
        const specCharResult = onlySpecialCharPattern.test(text);
        if (!childResult) {
          return 'Space is not allowed in the beginning and at the end';
        } else if (!specCharResult) {
          return 'Variable name should be alphanumeric';
        } else {
          if (tableBodyData.length) {
            let valueArr = tableBodyData.map((item) => item.variableValue);
            duplicateExist = valueArr.some((item, idx) => {
              return valueArr.indexOf(item) !== idx && idx === indexValue && item;
            });
            if (duplicateExist) {
              return 'Variable name should be unique';
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    const error = checkValidation(variableTextfieldValue, index);
    setVariableTextfieldError(error);
  }, [variableTextfieldValue, tableBodyData]);

  useEffect(() => {
    setDropDownValue(props?.row?.dropDownValue);
    setVariableTextfieldValue(props?.row?.variableValue);
  }, [props?.row]);
  return (
    <tr key={`row${index}`} className="text-left text-gray-600 text-sm">
      <td> {index + 1}</td>
      <td className="overflow-hidden">
        <SearchableSelect
          options={columnHeader}
          value={dropDownValue}
          selectedHeaders={tableBodyData}
          handleSelectedHeader={props.handleSelectedHeader}
          index={index}
          setFieldValue={props.setFieldValue}
        />
      </td>
      <td className="overflow-hidden">
        <VariableNameTextfield
          validateFieldValue={checkValidation}
          error={variableTextfieldError}
          value={variableTextfieldValue}
          handleChange={props.handleDataproviderVariableName}
          index={index}
          setFieldValue={props.setFieldValue}
        />
      </td>
      <td>
        {' '}
        <span
          onClick={() => {
            props.removeRowFromTable(index);
          }}
        >
          {' '}
          <Clear fontSize="small" className="text-gray-600" />{' '}
        </span>{' '}
      </td>
    </tr>
  );
};

function VariableTable(props) {
  const [tableData, setTableData] = useState(props.tableData);
  const [columnHeader, setColumnHeader] = useState(props.colmnHeader);
  useEffect(() => {
    if (props.tableData.length === 0) {
      props.MyAlert.alertbanner('Data Provider variable columns cannot be empty');
    }
    setTableData(props.tableData);
    setColumnHeader(props.colmnHeader);
  }, [props.tableData, props.colmnHeader]);
  return (
    <div className="mt-4">
      <table>
        <thead className="my-1 h-8">
          <tr className="text-gray-600 text-xs py-2">
            <th className="width-per-8 text-left text-gray-700 fontPoppinsSemiboldSm">Sl No.</th>
            <th className="width-per-45 text-left text-gray-700 fontPoppinsSemiboldSm">Column Name in Excel Sheet</th>
            <th className="width-per-42 text-left text-gray-700 fontPoppinsSemiboldSm">Dataprovider Variable Name</th>
            <th className="width-per-5 text-left"></th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <TableRow
              key={`row${index}`}
              tableBodyData={tableData}
              columnHeader={columnHeader}
              row={row}
              index={index}
              handleSelectedHeader={props.handleSelectedHeader}
              handleDataproviderVariableName={props.handleDataproviderVariableName}
              removeRowFromTable={props.removeRowFromTable}
              setFieldValue={props.setFieldValue}
            />
          ))}
        </tbody>
      </table>
      <div className="flex items-center">
        <div className="flex-1">
          <button
            type="button"
            disabled={tableData?.length === columnHeader?.length}
            onClick={props.addRowToTable}
            className={
              (tableData?.length === columnHeader?.length ? 'opacity-30' : 'cursor-pointer') + ' text-justify mt-2 ml-4'
            }
          >
            <span className="text-blue-700 fontPoppinsRegularSm">
              {' '}
              <AddToPhotosOutlined fontSize="small" className="ml-1" /> Add{' '}
            </span>
          </button>
        </div>
        <div className="flex-1">
          <button
            type="button"
            disabled={!(tableData?.length < columnHeader?.length)}
            onClick={props.resetVariableTable}
            className={
              (!(tableData?.length < columnHeader?.length) ? 'opacity-30' : 'cursor-pointer') +
              ' text-justify mt-3 ml-4'
            }
          >
            <span className="text-blue-700 fontPoppinsRegularSm">
              {' '}
              <Sync fontSize="small" className="ml-1" /> Reset{' '}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}
const CustomWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 415,
  },
});
Modal.setAppElement('#root');
function CreateEditDataproviders(props) {
  const { AlertContatiner, MyAlert } = useAlert();
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  const project = JSON.parse(localStorage.getItem('selected-project'));
  let [colmnHeader, setColmnHeader] = useState([]);
  let [variableTableBody, setVariableTableBody] = useState([]);
  const [showCreateEditModal, setShowCreateEditModal] = useState(false);
  let [createVariableForAllColumns, setCreateVariableForAllColumns] = useState(true);
  let [fileName, setFileName] = useState('');
  let [inputValueFile, setInputValueFile] = useState('');
  let [inputValueSheet, setInputValueSheet] = useState('');
  let [init, setInit] = useState(true);
  let [noOfRows, setNoOfRows] = useState(props.data ? parseInt(props.data.toRow) : 1);
  let [fileNameOptions, setFileNameOptions] = useState([]);
  let [sheetNameOptions, setSheetNameOptions] = useState([]);
  const [invalidFile, setInvalidFile] = useState(false);
  let editData = {};
  if (props?.data) {
    editData = props.data;
  }
  const [openModal, setOpenModal] = useState(true);
  const customStylesPage = {
    overlay: {
      maxHeight: 'fit-content !important',
    },
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '640px',
      height: 'fit-content',
      background: '#fbfcfd 0% 0% no-repeat padding-box',
      boxShadow: '0px 0px 11px #000000a1',
      borderRadius: '4px',
      opacity: '1',
    },
  };

  const getFileNames = async () => {
    try {
      let opt = [];
      const response = await getAllExcelFilesSearchReq(project.id);
      if (response.data && response.data.responseObject) {
        response.data.responseObject.forEach((file) => {
          if (props.data && editData.fileId === file.id) {
            setFileName(file.actualPath.substr(file.actualPath.search('/Root/') + 6));
          }
          if (file.actualPath.includes('/Root/')) {
            opt.push({
              label: file.actualPath.substr(file.actualPath.search('/Root/') + 6),
              value: file.id,
              data: file,
            });
          }
        });
        setFileNameOptions(opt);
      }
    } catch (err) {
      console.log(err);
    }
  };

  async function getAllSheets(fileId) {
    try {
      let opt = [];
      const response = await getAllExcelFilesSheetsReq(fileId);
      if (response.data && response.data.responseObject) {
        response.data.responseObject.sheetdata.forEach((sheet) => {
          if (init && props.data && sheet.sheetName === editData.sheetName && fileId === editData.fileId) {
            setNoOfRows(Number(sheet.toRow) - 1);
            setColmnHeader(sheet.header);
            let d = [];
            if (props.data.variables && props.data.variables.length) {
              props.data.variables.forEach((val) => {
                d.push({ dropDownValue: val.colName, variableValue: val.varname });
              });
            }
            setVariableTableBody(d);
            setShowCreateEditModal(true);
            setInit(false);
          }
          opt.push({ label: sheet.sheetName, value: sheet.sheetName, data: sheet });
          setInvalidFile(false);
        });
      } else {
        setInvalidFile(true);
        const reject = response.response.data;
        if (
          (reject.message && reject.message.includes('Index') && reject.message.includes('out of bounds for length')) ||
          reject.message === null
        ) {
          formikDetails.setFieldTouched('fileName', true);
          formikDetails.setFieldError('fileName', 'Selected file is not in proper format');
        }
      }
      return opt;
    } catch (err) {
      setInvalidFile(true);
      MyAlert.info(`Invalid File Format`);

      console.error('Invalid File error :', err);
    }
  }
  let initialValues;
  if (props?.data) {
    initialValues = {
      dataproviderName: editData?.name,
      fileName: editData?.fileId,
      sheetName: editData?.sheetName,
      fromRow: parseInt(editData?.fromRow),
      toRow: parseInt(editData?.toRow),
      dataproviderType: editData?.type,
      iterationType: editData?.iterationType,
    };
  } else {
    initialValues = {
      dataproviderName: '',
      fileName: '',
      sheetName: '',
      fromRow: '',
      toRow: '',
      dataproviderType: 'Excluding pre post condition',
      iterationType: 'All iterations as one script',
    };
  }

  function handleFileChange(value) {
    if (value === '') {
      formikDetails.setFieldValue('fileName', '', true);
      setFileName(value);
      setNoOfRows(null);
      setSheetNameOptions([]);
      formikDetails.setFieldValue('sheetName', '', true);
      formikDetails.setFieldValue('fromRow', '', true);
      formikDetails.setFieldValue('toRow', '', true);
    } else {
      fileNameOptions.forEach(async (file) => {
        if (file.label === value) {
          setNoOfRows(null);
          formikDetails.setFieldValue('fileName', file.data.id);
          formikDetails.setFieldValue('sheetName', '');
          formikDetails.setFieldValue('fromRow', '');
          formikDetails.setFieldValue('toRow', '');
          setFileName(value);
          let val = await getAllSheets(file.data.id);
          setSheetNameOptions(val);
        }
      });
    }
  }
  function handleSheetChange(value) {
    if (value === '' || sheetNameOptions.length === 0) {
      formikDetails.setFieldValue('sheetName', '', true);
      formikDetails.setFieldValue('fromRow', '', true);
      formikDetails.setFieldValue('toRow', '', true);
      setNoOfRows(null);
      setColmnHeader([]);
      setVariableTableBody([]);
      setCreateVariableForAllColumns(true);
    } else {
      sheetNameOptions.forEach((sheet) => {
        if (sheet.label === value) {
          formikDetails.setFieldValue('sheetName', sheet.data.sheetName, true);
          formikDetails.setFieldValue('fromRow', Number(sheet.data.fromRow), false);
          formikDetails.setFieldValue('toRow', Number(sheet.data.toRow) - 1, false);
          setColmnHeader(sheet.data.header);
          setNoOfRows(parseInt(sheet.data.toRow) - 1);
          let d = [];
          sheet.data.header.forEach((val) => {
            d.push({ dropDownValue: val, variableValue: val });
          });
          setVariableTableBody([...d]);
          setShowCreateEditModal(true);
        }
      });
    }
  }
  const createDataprovider = async (moduleId, scriptId, data) => {
    try {
      const response = await createDataproviderReq(moduleId, scriptId, data);
      if (response && response.data && response.data.responseObject) {
        props.reload(true);
        props.closeModal(false);
        props.MyAlert.success(`${formikDetails.values.dataproviderName} dataprovider created successfully`);
      } else if (
        response.data.responseCode === 400 &&
        response.data.message === `DataProvider with name ${formikDetails.values.dataproviderName} already exists`
      ) {
        formikDetails.setFieldError('dataproviderName', 'Name already exists');
      } else {
        props.MyAlert.warning(`${response.data.message}`);
        formikDetails.setFieldError('dataproviderName', 'Name already exists');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateDataprovider = async (moduleId, scriptId, data) => {
    try {
      const response = await updateDataproviderReq(moduleId, scriptId, data);
      if (response && response.data && response.data.responseObject) {
        props.reload(true);
        props.closeModal(false);
        props.MyAlert.success(`${formikDetails.values.dataproviderName} dataprovider updated successfully`);
      } else if (
        response.data.responseCode === 400 &&
        response.data.message === `DataProvider with name ${formikDetails.values.dataproviderName} already exists`
      ) {
        formikDetails.setFieldError('dataproviderName', 'Name already exists');
      } else {
        props.closeModal(false);
        props.MyAlert.warning(`${response.data.message}`);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = (values) => {
    let data;
    let wrongData = false;
    let valueArr = variableTableBody.map((item) => item.variableValue);
    let isDuplicate = valueArr.some((item, idx) => valueArr.indexOf(item) !== idx);
    if (values && !isDuplicate) {
      variableTableBody?.forEach((obj) => {
        let text = obj?.variableValue;
        let pattern = /^[-_A-Za-z0-9 ]*$|^[-_A-Za-z0-9 ][A-Za-z0-9 ]*[-_A-Za-z-_]$/;
        if (text) {
          const result = pattern.test(text);
          if (!result) {
            if (!wrongData) {
              wrongData = true;
            }
          } else {
            let childPattern = /^[-a-zA-Z0-9-()_]+(\s+[-a-zA-Z0-9-()_]+)*$/;
            let onlySpecialCharPattern = /[a-zA-Z]/;
            const childResult = childPattern.test(text);
            const specCharResult = onlySpecialCharPattern.test(text);
            if (!childResult) {
              if (!wrongData) {
                wrongData = true;
              }
            } else if (!specCharResult) {
              if (!wrongData) {
                wrongData = true;
              }
            }
          }
        } else {
          wrongData = true;
        }
      });
      if (!wrongData) {
        if (props?.data) {
          if (parseInt(values.fromRow) <= parseInt(values.toRow)) {
            const includePrePostCondition = values.dataproviderType === 'Including pre post condition' ? true : false;
            let dataproviderVariables = [];
            variableTableBody.forEach((row, index) => {
              dataproviderVariables.push({
                varname: row.variableValue,
                dpName: values.dataproviderName,
                colName: row.dropDownValue,
                value: null,
                type: 'DATAPROVIDER',
                parentType: 'module',
                parentId: moduleId,
                subParentId: scriptId,
              });
            });
            data = {
              id: props.data._id,
              name: values.dataproviderName,
              type: values.dataproviderType,
              steps: [],
              fileId: values.fileName,
              sheetName: values.sheetName,
              fromRow: parseInt(values.fromRow, 10),
              toRow: parseInt(values.toRow, 10),
              iterationType: values.iterationType,
              columns: [],
              dataProviderList: [],
              index: 0,
              includePrePostCondition: includePrePostCondition,
              sheetdata: [],
              variables: [...dataproviderVariables],
            };
            updateDataprovider(moduleId, scriptId, data);
          } else {
            formikDetails.setFieldError('fromRow', 'From row value should be lesser than to row');
          }
        } else {
          if (parseInt(values.fromRow) <= parseInt(values.toRow)) {
            const includePrePostCondition = values.dataproviderType === 'Including pre post condition' ? true : false;
            let dataproviderVariables = [];
            variableTableBody.forEach((row, index) => {
              dataproviderVariables.push({
                varname: row.variableValue,
                dpName: values.dataproviderName,
                colName: row.dropDownValue,
                value: null,
                type: 'DATAPROVIDER',
                parentType: 'module',
                parentId: moduleId,
                subParentId: scriptId,
              });
            });
            data = {
              name: values.dataproviderName,
              type: values.dataproviderType,
              steps: [],
              fileId: values.fileName,
              sheetName: values.sheetName,
              fromRow: parseInt(values.fromRow, 10),
              toRow: parseInt(values.toRow, 10),
              iterationType: values.iterationType,
              columns: [],
              dataProviderList: [],
              index: 0,
              includePrePostCondition: includePrePostCondition,
              sheetdata: [],
              variables: [...dataproviderVariables],
            };
            createDataprovider(moduleId, scriptId, data);
          } else {
            formikDetails.setFieldError('fromRow', 'From row value should be lesser than to row');
          }
        }
      }
    }
  };

  const validationSchema = yup.object({
    dataproviderName: yup
      .string()
      .trim('Space is not allowed at starting and at the end')
      .strict(true)
      .required('Name is required')
      .matches(/^[a-zA-Z0-9-_ ]*$/, 'Name should be alphanumeric')
      .min(3, 'Name should contain atleast 3 characters')
      .max(25, 'Name can contain atmost 25 characters'),
    fileName: yup.string().required('File name is required'),
    sheetName: yup.string().required('Sheet name is required'),
    fromRow: yup
      .number()
      .required('From row is required')
      .typeError('Enter only numbers')
      .min(1, 'Minimum value is 1')
      .max(noOfRows, 'Exceeds the maximum limit'),
    toRow: yup
      .number()
      .required('To row is required')
      .typeError('Enter only numbers')
      .min(1, 'Minimum value is 1')
      .max(noOfRows, 'Exceeds the maximum limit'),
  });

  const formikDetails = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  function handleSelectedHeader(value, index) {
    let a = [...variableTableBody];
    a[index].dropDownValue = value;
    setVariableTableBody(a);
    setShowCreateEditModal(true);
  }
  function handleDataproviderVariableName(value, index) {
    let a = variableTableBody.map((obj) => {
      return { ...obj };
    });
    a[index].variableValue = value;
    setVariableTableBody([...a]);
    setShowCreateEditModal(true);
  }
  function removeRowFromTable(index) {
    let newList = [...variableTableBody];
    newList.splice(index, 1);
    setVariableTableBody(newList);
    setShowCreateEditModal(true);
    formikDetails.setFieldValue('dirty', true);
  }
  function resetVariableTable() {
    let d = [];
    colmnHeader.forEach((val) => {
      d.push({ dropDownValue: val, variableValue: val });
    });
    setVariableTableBody([...d]);
    setShowCreateEditModal(true);
    formikDetails.setFieldValue('dirty', true);
  }
  function addRowToTable() {
    let isExists;
    let value = '';
    for (const header of colmnHeader) {
      isExists = false;
      for (const row of variableTableBody) {
        if (row.dropDownValue === header) {
          isExists = true;
          break;
        }
      }
      if (!isExists) {
        value = header;
        break;
      }
    }
    let a = [...variableTableBody];
    a.push({ dropDownValue: value, variableValue: value });
    setVariableTableBody(a);
    setShowCreateEditModal(true);
    formikDetails.setFieldValue('dirty', true);
  }
  function validateFromAndToRow(totalNoOfRows) {
    formikDetails.setFieldValue('fromRow', formikDetails.values.fromRow, true);
    formikDetails.setFieldValue('toRow', formikDetails.values.toRow, totalNoOfRows > 1 ? true : false);
  }
  useEffect(() => {
    validateFromAndToRow(noOfRows);
  }, [noOfRows]);
  useEffect(() => {
    async function setSheetOpt(id) {
      let val = await getAllSheets(id);
      setSheetNameOptions(val);
    }
    if (props.data) {
      setSheetOpt(props.data.fileId);
    }
    getFileNames();
  }, []);

  function shouldEnableAction(formikDetails, variableTableBody) {
    return (!(formikDetails.dirty && formikDetails.isValid && variableTableBody.length > 0));
  }

  return (
    <Modal
      isOpen={openModal}
      style={customStylesPage}
      className="p-px "
      shouldCloseOnOverlayClick={false}
      onRequestClose={() => {
        props.closeModal(false);
        setOpenModal(false);
      }}
    >
      <div className="mt-2">
        {props?.data ? (
          <span className="Popup-header-common pl-5"> Edit Dataprovider - {editData?.name}</span>
        ) : (
          <span className="Popup-header-common pl-5"> Create Dataprovider</span>
        )}
        <button
          onClick={() => {
            setOpenModal(false);
            props.closeModal(false);
          }}
          type="button"
          className="float-right mr-5 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-6" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div className="alert-variable-modal-position-style">
        <AlertContatiner />
      </div>
      <form className="border-b border-t border-blue-100 mt-2 mb-2" onSubmit={formikDetails.handleSubmit}>
        <div id="journal-scroll" className="overflow-y-auto data-provider-modal-height">
          <div className="ml-5 mr-5 create-edit-data-providers-input">
            <div className="mt-2 w-5/12">
              <TextField
                error={formikDetails.errors.dataproviderName && formikDetails.touched.dataproviderName}
                autoFocus={true}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                type="text"
                name="dataproviderName"
                id="dataproviderName"
                placeholder="Enter dataprovider name"
                onBlur={formikDetails.handleBlur}
                onChange={formikDetails.handleChange}
                value={formikDetails.values.dataproviderName}
                autoComplete="off"
                label={
                  <>
                    {' '}
                    <span className="text-red-400">*</span> Name
                  </>
                }
              />
              {formikDetails.errors.dataproviderName && formikDetails.touched.dataproviderName ? (
                <div className="text-red-400 fontPoppinsRegularSm md:mt-1">{formikDetails.errors.dataproviderName}</div>
              ) : null}
            </div>
            <div className="mt-4">
              <div>
                <Autocomplete
                  className="mt-1 fontPoppinsRegularMd"
                  value={fileName}
                  onChange={(event, newValue) => {
                    handleFileChange(newValue);
                  }}
                  inputValue={inputValueFile}
                  onInputChange={(event, newInputValue) => {
                    if (newInputValue === '') {
                      handleFileChange('');
                    }
                    setInputValueFile(newInputValue);
                  }}
                  id="fileName"
                  options={fileNameOptions?.length ? fileNameOptions.map((option) => option.label) : []}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      error={formikDetails.errors.fileName && formikDetails.touched.fileName}
                      fullWidth
                      {...params}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Search and select file name"
                      variant="standard"
                      label={
                        <>
                          {' '}
                          <span className="text-red-400">*</span> File Name
                        </>
                      }
                    />
                  )}
                />
              </div>
              {formikDetails.errors.fileName && formikDetails.touched.fileName ? (
                <div className="text-red-400 fontPoppinsRegularSm md:mt-1">{formikDetails.errors.fileName}</div>
              ) : null}
            </div>
            <div className="mt-4">
              <div>
                <Autocomplete
                  className="mt-1 fontPoppinsRegularMd"
                  value={formikDetails.values.sheetName}
                  onChange={(event, newValue) => {
                    handleSheetChange(newValue);
                  }}
                  inputValue={inputValueSheet}
                  onInputChange={(event, newInputValue) => {
                    if (newInputValue === '') {
                      handleSheetChange('');
                    }
                    setInputValueSheet(newInputValue);
                  }}
                  id="sheetName"
                  disableClearable
                  options={sheetNameOptions?.length ? sheetNameOptions.map((option) => option.label) : []}
                  renderInput={(params) => (
                    <TextField
                      error={formikDetails.errors.sheetName && formikDetails.touched.sheetName}
                      fullWidth
                      {...params}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Search and select sheet name"
                      variant="standard"
                      label={
                        <>
                          {' '}
                          <span className="text-red-400">*</span> Sheet Name
                        </>
                      }
                    />
                  )}
                />
              </div>
              {formikDetails.errors.sheetName && formikDetails.touched.sheetName ? (
                <div className="text-red-400 fontPoppinsRegularSm md:mt-1">{formikDetails.errors.sheetName}</div>
              ) : null}
            </div>
            <div className="mt-3 grid grid-cols-2">
              <div className="mt-2 w-11/12">
                <div>
                  <TextField
                    error={formikDetails.errors.fromRow && formikDetails.touched.fromRow}
                    fullWidth={true}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="text"
                    name="fromRow"
                    id="fromRow"
                    placeholder="Enter from row"
                    onBlur={formikDetails.handleBlur}
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.fromRow}
                    autoComplete="off"
                    label={
                      <>
                        {' '}
                        <span className="text-red-400">*</span> From Row
                      </>
                    }
                  />
                </div>
                {formikDetails.errors.fromRow && formikDetails.touched.fromRow ? (
                  <div className="text-red-400 fontPoppinsRegularSm md:mt-1">{formikDetails.errors.fromRow}</div>
                ) : null}
              </div>
              <div className="mt-2 ml-auto float-right w-11/12">
                <div>
                  <TextField
                    error={formikDetails.errors.toRow && formikDetails.touched.toRow}
                    fullWidth={true}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="text"
                    name="toRow"
                    id="toRow"
                    placeholder="Enter to row"
                    onBlur={formikDetails.handleBlur}
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.toRow}
                    autoComplete="off"
                    label={
                      <>
                        {' '}
                        <span className="text-red-400">*</span> To Row
                      </>
                    }
                  />
                </div>
                {formikDetails.errors.toRow && formikDetails.touched.toRow ? (
                  <div className="text-red-400 fontPoppinsRegularSm md:mt-1">{formikDetails.errors.toRow}</div>
                ) : null}
              </div>
            </div>
            <div className="mt-4">
              <h1 className="input-filed-label-style-common">Assign Column to Variable</h1>
              <input
                name="createVariableForAllColumns"
                disabled={true}
                onBlur={formikDetails.handleBlur}
                onChange={(e) => {
                  setCreateVariableForAllColumns(e.target.checked);
                }}
                checked={createVariableForAllColumns}
                className={`${true ? 'opacity-30' : ''} rounded mt-1.5`}
                type="checkbox"
              />
              <label htmlFor="createVariableForAllColumns" className="ml-3 mt-2.5 input-filed-label-style-common">
                Create variables for all columns
              </label>
            </div>
            <div>
              {formikDetails.values.sheetName !== '' && showCreateEditModal && (
                <VariableTable
                  tableData={variableTableBody}
                  colmnHeader={colmnHeader}
                  handleSelectedHeader={handleSelectedHeader}
                  handleDataproviderVariableName={handleDataproviderVariableName}
                  removeRowFromTable={removeRowFromTable}
                  addRowToTable={addRowToTable}
                  resetVariableTable={resetVariableTable}
                  MyAlert={MyAlert}
                  setFieldValue={formikDetails.setFieldValue}
                />
              )}
            </div>
            {/* Dataprovider Type, Iteration Type both fields are commented backend implementation pending (Required for future)  */}
            {/* <div className="mt-2 grid grid-cols-2">
                            <div className="mt-2.5 mb-4">
                                <span className="fontPoppinsSemiboldMd">
                                    Dataprovider Type
                                </span>
                                <div>
                                    <div>
                                        {props.disableIncludePrePostCondition && editData.type !== "Including pre post condition" ?
                                            <CustomWidthTooltip title=
                                                {` 1. Only one dataprovider can run including pre and post condition inside a script.         
                                                   2. Already  '${props.dataproviderWithPrePostCondition}'  is using including pre and post condition so this dataprovider cannot use the same.`}
                                                placement='top'>
                                                <span className='pre-tool-div'>
                                                    <Radio
                                                        checked={formikDetails.values.dataproviderType === "Including pre post condition"}
                                                        disabled={true}
                                                        value="Including pre post condition"
                                                        name="dataproviderType"
                                                        inputProps={{ 'aria-label': 'Including Pre and Post Condition' }}
                                                    />
                                                    <label htmlFor="includePrePostCond" className="fontPoppinsRegularMd">
                                                        Including Pre and Post Condition
                                                    </label>
                                                </span>

                                            </CustomWidthTooltip> :
                                            <>
                                                <Radio
                                                    checked={formikDetails.values.dataproviderType === "Including pre post condition"}
                                                    onChange={(e) => { formikDetails.handleChange(e); formikDetails.setFieldValue('iterationType', "All iterations as one script") }}
                                                    onBlur={formikDetails.handleBlur}
                                                    value="Including pre post condition"
                                                    name="dataproviderType"
                                                    inputProps={{ 'aria-label': 'Including Pre and Post Condition' }}
                                                />
                                                <label htmlFor="includePrePostCond" className="fontPoppinsRegularMd">
                                                    Including Pre and Post Condition
                                                </label>
                                            </>
                                        }
                                    </div>
                                    <div>
                                        <Radio
                                            checked={formikDetails.values.dataproviderType === "Excluding pre post condition"}
                                            onChange={(e) => { formikDetails.handleChange(e); formikDetails.setFieldValue('iterationType', "") }}
                                            onBlur={formikDetails.handleBlur}
                                            value="Excluding pre post condition"
                                            name="dataproviderType"
                                            inputProps={{ 'aria-label': "Excluding pre post condition" }}
                                        />
                                        <label htmlFor="excludePrePostCond" className="fontPoppinsRegularMd">
                                            Excluding Pre and Post Condition
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {formikDetails.values.dataproviderType === "Including pre post condition" ?
                                <div className="mt-2.5 mb-4 ml-3">
                                    <span className=" ml-3 fontPoppinsSemiboldMd">
                                        Iteration Type
                                    </span>
                                    <div>
                                        <div>
                                            <Radio
                                                checked={formikDetails.values.iterationType === "All iterations as one script"}
                                                onChange={formikDetails.handleChange}
                                                onBlur={formikDetails.handleBlur}
                                                value="All iterations as one script"
                                                name="iterationType"
                                                inputProps={{ 'aria-label': 'All iterations as one script' }}
                                            />
                                            <label htmlFor="allAsOneScript" className="fontPoppinsRegularMd">
                                                All iterations as one script
                                            </label>
                                        </div>
                                        <div>
                                            <Radio
                                                checked={formikDetails.values.iterationType === "Each iterations as one script"}
                                                onChange={formikDetails.handleChange}
                                                onBlur={formikDetails.handleBlur}
                                                value="Each iterations as one script"
                                                name="iterationType"
                                                inputProps={{ 'aria-label': 'Each iterations as one script' }}
                                            />
                                            <label htmlFor="eachAsOneScript" className="fontPoppinsRegularMd">
                                                Each iterations as one script
                                            </label>
                                        </div>
                                    </div>
                                </div> : null}

                        </div> */}
          </div>
        </div>
        <div className="float-right mt-3.5 mb-3 mr-5">
          <button onClick={() => props.closeModal(false)} type="button" className="ml-8 gray-btn">
            Cancel
          </button>
          <button
            type="submit"
            className="ml-4 primary-btn"
            disabled={shouldEnableAction(formikDetails, variableTableBody)}
          >
            {props?.data ? LABELS_CONSTANTS.UPDATE : LABELS_CONSTANTS.CREATE}
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default CreateEditDataproviders;

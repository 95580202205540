import React from 'react';
import { Route, Switch } from 'react-router-dom';
import GlobalVariables from '../../../shared/variables/global-variables';
import ProjectEnvironmentVariables from '../../../shared/variables/project-environment-variables';

import Variables from './local-variables';

const VariableRouter = (props) => {
  const index = props.location.pathname.lastIndexOf('/');
  const path = props.location.pathname.slice(0, index);
  return (
    <Switch>
      <Route path={`${path}/Local Variables`} exact={true} strict={true} key="local-variables-page">
        <Variables {...props} MyAlert={props.MyAlert} />
      </Route>
      <Route path={`${path}/Global Variables`} exact={true} strict={true} key="global-variables-page">
        <GlobalVariables {...props} MyAlert={props.MyAlert} />
      </Route>
      <Route path={`${path}/Project Environment Variables`} exact={true} strict={true} key="project-variables-page">
        <ProjectEnvironmentVariables {...props} MyAlert={props.MyAlert} />
      </Route>
    </Switch>
  );
};

export default VariableRouter;

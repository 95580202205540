import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import { useDropzone } from 'react-dropzone';
import { useFilters, usePagination, useTable } from 'react-table';
import { Radio, Tooltip } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import { useAlert } from '../../common/alert_service/useAlert';
import { AiOutlineDelete } from 'react-icons/ai';
import { extLibJarFiles, getLibFilesReq } from '../../../api/api_services';

let fileNames = [];
let originData = [];

function UploadLibrary(props) {
  const { AlertContatiner, MyAlert } = useAlert();

  const [showModal, setShowModal] = useState(true);
  const [pageIndexValue, setPageIndex] = React.useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  let [dragedRow, setDragedRow] = useState();
  let { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: '.jar',
    onDrop: (acceptedFiles) => {
      let dragedFiles = [...selectedFiles];
      let rawData = [...originData];
      console.log(fileNames, acceptedFiles);
      acceptedFiles.map((data, index) => {
        if (!fileNames.includes(data.name)) {
          originData.push(data);
          dragedFiles.push({
            jarname: data.name,
            action: (
              <button
                className={`float-left blue configmoreIcon pt-0.5 mr-4 cursor-pointer`}
                onClick={() => {
                  deletedragedFile(index, data.name);
                }}
              >
                <Tooltip title="Delete" placement="top">
                  <DeleteOutlinedIcon className="blue" />
                </Tooltip>
              </button>
            ),
          });
          fileNames.push(data.name);
        }
      });
      // setDragedRow(dragedFiles.map((fileData,index)=>{
      //   return (<tr  className="hover:bg-gray-100 visibleData " style={{height:'30px'}}>
      //   <td style={{textAlign:'left'}}><span className="text-sm ml-8 text-gray-700">{fileData?.name}</span></td>

      //   <td className="text-gray-700 text-blue-700 mt-2" style={{marginRight:'20px',float:'right'}} >
      //   <button
      //       className={`float-left blue configmoreIcon pt-0.5 mr-4 cursor-pointer`}
      //       onClick={() => {
      //         deletedragedFile(index,fileData);
      //       }}
      //     >
      //       <Tooltip title="Delete" placement="top">
      //         <DeleteOutlinedIcon className="blue" />
      //       </Tooltip>
      //     </button>
      //   </td>

      //  </tr>)
      // }))
      setSelectedFiles(dragedFiles);
    },
  });

  let deletedragedFile = (index, name) => {
    let rawData = [...originData];
    let dragedFiles = [];

    rawData.splice(fileNames.indexOf(name), 1);
    console.log(dragedFiles);
    fileNames = [];
    console.log(fileNames);
    rawData.map((data, index) => {
      fileNames.push(data.name);
      dragedFiles.push({
        jarname: data.name,
        action: (
          <button
            className={`float-left blue configmoreIcon pt-0.5 mr-4 cursor-pointer`}
            onClick={() => {
              deletedragedFile(index, data.name);
            }}
          >
            <Tooltip title="Delete" placement="top">
              <DeleteOutlinedIcon className="blue" />
            </Tooltip>
          </button>
        ),
      });
    });
    if (dragedFiles.length > 0) {
      // setDragedRow(dragedFiles.map((fileData,index)=>{
      //   console.log(fileData.name);
      //   return (<tr  className="hover:bg-gray-100 visibleData " style={{height:'30px'}}>
      //   <td style={{textAlign:'left'}}><span className="text-sm ml-8 text-gray-700">{fileData?.name}</span></td>
      //   <td className="text-gray-700 text-blue-700 mt-2" style={{marginRight:'20px',float:'right'}} >
      //   <button
      //       className={`float-left blue configmoreIcon pt-0.5 mr-4 cursor-pointer`}
      //       onClick={() => {
      //         deletedragedFile(index,fileData);
      //       }}
      //     >
      //       <Tooltip title="Delete" placement="top">
      //         <DeleteOutlinedIcon className="blue" />
      //       </Tooltip>
      //     </button>
      //   </td>
      //  </tr>)
      // }))
    } else {
      setDragedRow([]);
    }

    originData = rawData;
    setSelectedFiles(dragedFiles);
  };

  const [folderId, setFolderId] = useState();
  useEffect(() => {
    fileNames = [];
    originData = [];
  }, []);

  let uploadLibFilesData = async () => {
    try {
      const file = new FormData();
      originData.map((data, index) => {
        console.log(data);
        file.append('file', data);
      });
      let response = await extLibJarFiles(props?.project?.id, file);
      let responseObject = response?.data?.responseObject;
      if (response?.data?.responseCode === 200) {
        props.MyAlert.success(`${data[0].jarname} Created Successfully`);
        props?.getExtLibdata();
      }
      if (
        response?.data?.responseCode === 200 &&
        response?.data?.message.includes('Files') &&
        response?.data?.message.includes('already exist')
      ) {
        props.MyAlert.warning(`${response?.data?.message}`);
        props?.getExtLibdata();
      }

      // props?.getExtLibdata();

      console.log('name', data[0].jarname);
    } catch (err) {
      console.log(err);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'JarName',
        accessor: 'jarname',
        width: '85%',
      },
      {
        Header: 'Action',
        accessor: 'action',
      },
    ],
    []
  );

  const data = React.useMemo(() => selectedFiles, [selectedFiles]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: pageIndexValue, pageSize: 5 },
    },
    useFilters,
    usePagination
  );

  useEffect(() => {
    if (selectedFiles.length === 5 * pageIndexValue && pageIndexValue != 0) {
      setPageIndex(pageIndexValue - 1);
    }
  }, [selectedFiles]);

  return (
    <div>
      <Modal open={showModal} className="modal-dialog">
        <div className="modal-container import-jar-modal">
          <div className="modal-header">
            <h2 className="title">Import Libraries</h2>
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                props.closeModal(false);
                setShowModal(false);
              }}
            >
              <img
                style={{
                  cursor: 'pointer',
                }}
                src="/assets/images/close_black.svg"
                alt=""
                height="25px"
                width="25px"
              />
            </IconButton>
          </div>
          <div className="modal-body" id="journal-scroll">
            <AlertContatiner />
            <p className="UploadJarBoxHeader">Upload jar(s)</p>
            <div className="">
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <div className="UploadJarBox" style={{ maxWidth: '95vw' }}>
                  <p className="draganddrop">
                    Drag and Drop here <br />
                    <span className="mt-2">or</span>
                    <br />
                    <span className="browseText">Browse files</span>
                  </p>
                </div>
              </div>
            </div>{' '}
            <div className="page-table-body mt-4 ">
              <div
                className="configPage pr-0 content_area_style "
                id="journal-scroll"
                style={{ height: '37vh', width: '98.4%' }}
              >
                <div
                  className=" grid grid-cols-3 "
                  style={{
                    // background: "rgb(240,243,249)",
                    display: 'flex',
                  }}
                >
                  {/* <table>
    
    <tbody >
    {dragedRow}
    </tbody>
    
    </table> */}
                  <table {...getTableProps()} className="">
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="">
                          {headerGroup.headers.map((column) => (
                            <th width={column.width} {...column.getHeaderProps()} className=""></th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="text text-xs">
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()} className=" h-10 ">
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()} className="p-0 pl-7 text-left text-sm ">
                                  {cell.render('Cell')}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                <div className="pagecountborder ">
                  <div className="float-right pr-4">
                    <span className="pagecount">
                      {pageSize * pageIndex + 1}-{page.length + pageSize * pageIndex + 1 - 1} of {data.length}{' '}
                    </span>
                    <span className="pageborderleft ml-2 pl-2">
                      <button
                        onClick={() => {
                          previousPage();
                          setPageIndex(pageIndexValue - 1);
                        }}
                        disabled={!canPreviousPage}
                        className={`${!canPreviousPage ? 'pageArrow' : 'pageArrowEnable'}`}
                      >
                        {'<'}
                      </button>{' '}
                      <span className="currentpagecount ml-2 mr-2">
                        {' '}
                        <span className="pl-2 pr-2">{pageIndex + 1}</span>
                      </span>
                      <button
                        onClick={() => {
                          nextPage();
                          setPageIndex(pageIndexValue + 1);
                        }}
                        disabled={!canNextPage}
                        className={`${!canNextPage ? 'pageArrow' : 'pageArrowEnable'}`}
                      >
                        {'>'}
                      </button>{' '}
                    </span>
                  </div>
                </div>
                {/* <div className="pagecountborder-set mb-3" id="footer-notfixed">
<div className="float-right pr-4">
  <span className="pagecount">1-5 of 5</span>
  <span className="pageborderleft ml-2 pl-2">
    <button className="pageArrow">{"<"}</button>{" "}
    <span className="currentpagecount ml-2 mr-2">
      {" "}
      <span className="pl-2 pr-2">1</span>
    </span>
    <button className="pageArrow">{">"}</button>{" "}
  </span>
</div>
</div> */}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="gray-btn mr-3"
              onClick={() => {
                props.closeModal(false);
                setShowModal(false);
              }}
            >
              Close
            </button>
            <button
              className="primary-btn  w-25  ml-10 items-center float-right"
              onClick={() => {
                uploadLibFilesData();
                props.closeModal(false);
                setShowModal(false);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default UploadLibrary;

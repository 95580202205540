import React from 'react';
import { withRouter } from 'react-router';
import { useAlert } from '../common/alert_service/useAlert';

import Tab from '../common/tab';

var logoArray = [
  {
    name: 'Projects',
    logo: '/assets/images/project_rec_logo.svg',
    selectedLogo: '/assets/images/project_rec_logo_blue.svg',
    pathUrl: '/projectmenu/project',
    selectedName: 'project',
  },
  {
    name: 'Users',
    logo: '/assets/images/user_logo.svg',
    selectedLogo: '/assets/images/user_logo_blue.svg',
    pathUrl: '/projectmenu/user',
    selectedName: 'user',
  },
  {
    name: 'Roles',
    logo: '/assets/images/role_logo.svg',
    selectedLogo: '/assets/images/role_logo_blue.svg',
    pathUrl: '/projectmenu/role',
    selectedName: 'role',
  },
  // {
  //     "name": "History",
  //     "logo": "/assets/images/history_logo.svg",
  //     "selectedLogo": "/assets/images/history_logo_blue.svg",
  //     "pathUrl": "/projectmenu/history",
  //     "selectedName": "history"
  // }
];

function DashboardPage(props) {
  const { AlertContatiner, MyAlert } = useAlert();
  const tab = props.location.pathname.split('/')[2];
  if (props.location.pathname === '/projectmenu') {
    const project = JSON.parse(localStorage.getItem('selected-project'));
    if (project) {
      if (project.id !== '') {
        props.history.push(`/projectmenu/project-config?id=${project.id}`);
      } else {
        props.history.push('/projectmenu/project');
      }
    } else {
      props.history.push('/projectmenu/project');
    }
  }
  if (tab) {
    if (tab === 'project') {
      logoArray[0].name = 'Projects';
      logoArray[0].pathUrl = '/projectmenu/project';
      logoArray[0].selectedName = 'project';
      logoArray[1].name = 'Users';
      logoArray[1].pathUrl = '/projectmenu/user';
      logoArray[1].selectedName = 'user';
      logoArray[2].name = 'Roles';
      logoArray[2].pathUrl = '/projectmenu/role';
      logoArray[2].selectedName = 'role';
    } else if (tab === 'project-config') {
      logoArray[0].name = 'Project Config';
      logoArray[0].pathUrl = '/projectmenu/project-config';
      logoArray[0].selectedName = 'project-config';
      logoArray[1].name = 'Users';
      logoArray[1].pathUrl = '/projectmenu/user-individual';
      logoArray[1].selectedName = 'user-individual';
      logoArray[2].name = 'Roles';
      logoArray[2].pathUrl = '/projectmenu/role-individual';
      logoArray[2].selectedName = 'role-individual';
    }
  } else {
    logoArray[0].name = 'Projects';
    logoArray[0].pathUrl = '/projectmenu/project';
    logoArray[0].selectedName = 'project';
    logoArray[1].name = 'Users';
    logoArray[1].pathUrl = '/projectmenu/user';
    logoArray[1].selectedName = 'user';
    logoArray[2].name = 'Roles';
    logoArray[2].pathUrl = '/projectmenu/role';
    logoArray[2].selectedName = 'role';
  }
  React.useEffect(() => {
    if (tab === 'project-config') {
      logoArray[0].name = 'Project Config';
      logoArray[0].pathUrl = '/projectmenu/project-config';
      logoArray[0].selectedName = 'project-config';
      logoArray[1].name = 'Users';
      logoArray[1].pathUrl = '/projectmenu/user-individual';
      logoArray[1].selectedName = 'user-individual';
      logoArray[2].name = 'Roles';
      logoArray[2].pathUrl = '/projectmenu/role-individual';
      logoArray[2].selectedName = 'role-individual';
    } else if (tab === 'user-individual') {
      logoArray[0].name = 'Project Config';
      logoArray[0].pathUrl = '/projectmenu/project-config';
      logoArray[0].selectedName = 'project-config';
      logoArray[1].name = 'Users';
      logoArray[1].pathUrl = '/projectmenu/user-individual';
      logoArray[1].selectedName = 'user-individual';
      logoArray[2].name = 'Roles';
      logoArray[2].pathUrl = '/projectmenu/role-individual';
      logoArray[2].selectedName = 'role-individual';
    } else if (tab === 'role-individual') {
      logoArray[0].name = 'Project Config';
      logoArray[0].pathUrl = '/projectmenu/project-config';
      logoArray[0].selectedName = 'project-config';
      logoArray[1].name = 'Users';
      logoArray[1].pathUrl = '/projectmenu/user-individual';
      logoArray[1].selectedName = 'user-individual';
      logoArray[2].name = 'Roles';
      logoArray[2].pathUrl = '/projectmenu/role-individual';
      logoArray[2].selectedName = 'role-individual';
    }
  }, []);

  return (
    <div>
      <div className="alert-position-style">
        <AlertContatiner></AlertContatiner>
      </div>
      <Tab props={props} logoArray={logoArray} MyAlert={MyAlert} />
    </div>
  );
}

export default withRouter(DashboardPage);

import React, { useMemo, useState, useEffect } from 'react';
import { useTable } from 'react-table';
import { TextField, Tooltip } from '@material-ui/core';
import Chip from '@mui/material/Chip';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useAlert } from '@pages/common/alert_service/useAlert';
import { REGEX } from '@util/validations';
import { USER_SETTINGS_SPECIAL_CHARACTERS_ERROR } from '@common/ui-constants';
import { useFormik } from 'formik';
import * as Yup from 'yup';

let userMachinesBackUp = [];
let selectedRow;
let selUserObj = {};
let selUserName;
const UserMachineChildPage = (props) => {
  const { AlertContatiner, MyAlert } = useAlert();
  const [selectedUser, setSelectedUser] = React.useState('');
  const [executionEnvOptions, setExecutionEnvOptions] = useState(['Local', 'BrowserStack', 'Saucelabs', 'LambdaTest']);
  const [osDetails, setOsDetails] = React.useState([]);
  const [browserDetails, setBrowserDetails] = React.useState([]);
  const [userMachines, setUserMachines] = useState(props?.userMachineList ? Object.create(props.userMachineList) : []);
  const [userData, setUserData] = React.useState([]);
  const [errorMessages, setErrorMessages] = useState({});
  const [userMachineData, setUserMachineData] = React.useState({});
  const [projectType, setProjectType] = useState(props?.selectedProject ? props.selectedProject.type : 'web');
  const [duplicateMessage, setDuplicateMessage] = useState('');
  const [invalidMachineNameMessage, setInvalidMachineNameMessage] = useState('');
  const [enableButton, setEnableButton] = useState(false);
  const [selectedAction, setSelectedAction] = useState();
  let selectedStringOption = USER_SETTINGS_SPECIAL_CHARACTERS_ERROR.SELECTED_STRING_OPTION;
  const [multipleMachine, setMultipleMachine] = useState(false);
  const initialValues = {
    machine_name: userMachineData?.machineInfo?.hostName,
    os_version: userMachineData?.machineInfo?.osVersion,
    browser_version1: userMachineData?.browserVersion,
    device_version1: userMachineData?.deviceInfo?.[0]?.version,
    device1: userMachineData?.deviceInfo?.[0]?.name,
  };

  const machineNameValidationSchema = Yup.string()
    .trim('Space is not allowed at the start and the end')
    .matches(/^[a-zA-Z0-9_()\- ]+$/, 'Name should be alphanumeric')
    .strict(true)
    .min(3, 'Minimum 3 characters required')
    .required('Name is required');
  const baseValidationSchema = Yup.string()
    .trim('Space is not allowed at the start and the end')
    .matches(/^[a-zA-Z0-9_/(.)\- ]+$/, 'Name should be alphanumeric')
    .strict(true)
    .required('Name is required');

  const getSchemaFieldsToRemove = (projectType) => {
    const type = projectType.toLowerCase();
    const fieldsToRemoveMap = {
      web: ['device_version1', 'device1'],
      'web service': ['device_version1', 'device1'],
      mobile: ['browser_version1'],
      'web & mobile': [],
      'ms dynamics': [],
      salesforce: [],
    };

    return fieldsToRemoveMap[type] || [];
  };

  const createValidationSchema = (projectType) => {
    const schema = {
      machine_name: machineNameValidationSchema,
      os_version: baseValidationSchema,
      browser_version1: baseValidationSchema,
      device_version1: baseValidationSchema,
      device1: baseValidationSchema,
    };

    const fieldsToRemove = getSchemaFieldsToRemove(projectType);
    fieldsToRemove.forEach((field) => delete schema[field]);

    return Yup.object().shape(schema);
  };

  const validationSchema = createValidationSchema(projectType);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
  });
  useEffect(() => {
    let defaultIndex;
    let defaultUser;
    let tempUserData = Object.create(props.userData);
    if (props?.userMachineList && props.userMachineList.length) {
      let preUserList = props?.userMachineList ? props.userMachineList : [];
      formik.values.machine_name = preUserList?.[0]?.machineInfo?.hostName;
      formik.values.os_version = preUserList?.[0]?.machineInfo?.osVersion;
      formik.values.device_version1 = preUserList?.[0]?.deviceInfo?.[0]?.version;
      formik.values.browser_version1 = preUserList?.[0]?.browserVersion;
      formik.values.device1 = preUserList?.[0]?.deviceInfo?.[0]?.name;
      setUserMachineData(preUserList[preUserList.length - 1]);
      if (preUserList.length && tempUserData.length) {
        tempUserData.forEach((user) => {
          let test = preUserList.find((mach) => mach.clientId === user.name);
          if (test) {
            user['check'] = true;
            setSelectedUser(user.name);
            let tempObj = {
              name: user.name,
            };
            selectedRow = tempObj;
          } else {
            user['check'] = false;
          }
        });
        setUserData([...tempUserData]);
      }
    } else {
      setUserMachines([]);
      tempUserData.forEach((user, i) => {
        if (props?.selectedOption !== selectedStringOption && i === 0) {
          user['check'] = false;
          defaultUser = user;
          defaultIndex = i;
        } else {
          user['check'] = false;
        }
      });
      setUserData([...tempUserData]);
      if (!selectedUser && tempUserData && tempUserData.length) {
        setSelectedUser(tempUserData[0].name);
        let tempObj = {
          name: tempUserData[0].name,
        };
        selectedRow = tempObj;
        let selectedSystemObj = defaultSystemObject(tempUserData[0].name);
        setUserMachineData(selectedSystemObj);
      }
    }
    if (props.selectedUserType === 'Multiple Users') {
      if (defaultUser) addUserMachine('', defaultIndex, defaultUser);
      setMultipleMachine(true);
    } else {
      setMultipleMachine(false);
    }
    if (props?.selectedOption === selectedStringOption && props?.userMachineList?.length) {
      selUserName = props?.userMachineList[0].clientId;
    }
    userMachinesBackUp = JSON.parse(localStorage.getItem('userMachineBackupData')) || [];
    setBrowserDetails(props.browserDetails);
    setOsDetails(props.osDetails);
  }, []);

  const defaultSystemObject = (userName, userData) => {
    let selectedSystemObj = {
      clientId: userName || selectedUser,
      executionEnv: userData?.executionEnv || '',
      machineInfo: {
        osName: userData?.osName || '',
        osVersion: userData?.osVersion || '',
        hostName: userData?.hostName || '',
      },
      action: multipleMachine ? 'multiple' : 'edit',
    };

    if (projectType.toLowerCase().includes('mobile')) {
      selectedSystemObj['deviceInfo'] = [
        {
          version: userData?.deviceVersion || '',
          name: userData?.deviceName || '',
          serial_no: '',
          deviceUniqueId: '',
          subType: 'device',
        },
      ];
    }
    if (projectType.toLowerCase().includes('web')) {
      selectedSystemObj['browserName'] = userData?.browserName || '';
      selectedSystemObj['browserVersion'] = userData?.browserVersion || '';
    }
    if (['Salesforce', 'MS Dynamics'].includes(projectType)) {
      selectedSystemObj['deviceInfo'] = [
        {
          version: userData?.deviceVersion || '',
          name: userData?.deviceName || '',
          serial_no: '',
          deviceUniqueId: '',
          subType: 'device',
        },
      ];
      selectedSystemObj['browserName'] = userData?.browserName || '';
      selectedSystemObj['browserVersion'] = userData?.browserVersion || '';
    }
    return selectedSystemObj;
  };

  const handleCheck = (value, checkEvent, index) => {
    let selectedUserDetails = {};
    let data = userData;
    setSelectedUser(value);
    selUserName = value;
    if (props.selectedUserType === 'Single User') {
      setUserMachines([]);
      setUserMachineData({});
      if (props?.selectedOption === selectedStringOption) {
        addUserMachine('', index, value);
      }
      if (data.length) {
        data?.forEach((obj, i) => {
          if (index === i) {
            obj.check = !obj.check;
          } else {
            obj.check = false;
          }
        });
      }
      handleRowSelect(value, userMachines);
      setUserData(data);
    } else {
      data[index].check = !data[index].check;
      setUserData(data);
      if (checkEvent.target.checked) {
        addUserMachine('', index, value);
      } else {
        if (userMachinesBackUp.length) {
          let index = userMachinesBackUp.findIndex((x) => x?.clientId && x.clientId === value);
          if (index > -1) {
            userMachinesBackUp.splice(index, 1);
          }
          if (userMachinesBackUp.length) {
            setEnableButton(true);
          } else {
            let htmlEle = document.getElementById('selectBtn');
            setTimeout(() => {
              if (htmlEle) {
                htmlEle.classList.remove('primary-btn');
                htmlEle.classList.add('disabled-update-btn');
              }
            }, 100);
          }
        }

        let tempUserMachineList = [...userMachines];
        const uniqueIds = [];
        const unique = userMachines.filter((element) => {
          const isDuplicate = uniqueIds.includes(element.clientId);
          if (!isDuplicate) {
            uniqueIds.push(element.clientId);
            return true;
          }
          return false;
        });
        if (unique.length) {
          let index = unique.findIndex((x) => x?.clientId && x.clientId === value);
          if (index > -1) {
            unique[index].browserName = '';
            unique[index].browserVersion = '';
            unique[index].executionEnv = '';
            unique[index].machineInfo.hostName = '';
            unique[index].machineInfo.osName = '';
            unique[index].machineInfo.osVersion = '';
            selectedUserDetails = { ...unique[index] };
            unique.splice(index, 1);
          }
        }

        setUserMachines([...unique]);
        if (tempUserMachineList.length) {
          setEnableButton(true);
          setUserMachineData(selectedUserDetails);
        } else {
          setEnableButton(false);
        }
      }
    }
  };

  const addUserMachine = (action, index, userName) => {
    let selectedSystemObj = {};
    let tempData = [];
    let tempUserMachine = [...userMachines];
    tempUserMachine.forEach((_tmp) => {
      if (_tmp.hasOwnProperty('headless')) {
        delete _tmp['headless'];
      }
    });
    if (action === 'addMore') {
      selectedSystemObj = defaultSystemObject(userName);
      let data = tempUserMachine[tempUserMachine.length - 1];
      if (data) {
        let validated = validateObject(data);
        let duplicates = handleDuplicateMachines(tempUserMachine);
        if (validated && !duplicates) {
          tempUserMachine.push(selectedSystemObj);
        }
      }
      setEnableButton(false);
    } else {
      if (selectedRow?.name === userName) {
        selectedSystemObj = defaultSystemObject(userName, selUserObj);
      } else {
        selectedSystemObj = defaultSystemObject(userName);
      }
      let validatedSingleData = validateObject(selectedSystemObj);
      if (validatedSingleData) {
        setEnableButton(true);
      } else {
        setEnableButton(false);
      }
      if (!tempUserMachine.length || multipleMachine || props.selectedUserType === 'Multiple Users') {
        tempUserMachine.map((obj, index) => {
          if (obj?.clientId === selectedSystemObj.clientId) {
            tempUserMachine.splice(index, 1);
          }
        });

        userMachinesBackUp.push(selectedSystemObj);
        tempUserMachine.push(selectedSystemObj);
      } else {
        if (tempUserMachine?.length) {
          const _selectedUserMachineData = tempUserMachine.find((_umac) => _umac.clientId === userName);
          if (_selectedUserMachineData) {
            selectedSystemObj = { ..._selectedUserMachineData };
          }
        }
        if (props.selectedUserType === 'Single User') {
          tempUserMachine = [];
          tempUserMachine.push(selectedSystemObj);
        } else {
          tempUserMachine.splice(index, 1, selectedSystemObj);
        }
      }
    }
    let selectedUserData;
    if (tempUserMachine?.length && action !== 'addMore') {
      selectedUserData = tempUserMachine.find((mach) => mach.clientId === userName);
    }
    setUserMachines(tempUserMachine);
    Object.assign(tempData, [...tempUserMachine]);
    setUserMachineData(selectedUserData || tempData[tempData.length - 1]);
    selUserObj = {};
    selectedRow = undefined;
    setSelectedAction(action);
  };

  const handleChipDelete = (index) => {
    userMachines.splice(index, 1);
    setUserMachines([...userMachines]);
    if (userMachines?.length) {
      let wrongData = false;
      userMachines.map((obj) => {
        if (Object.values(obj).every((v) => v != '')) {
          if (Object.values(obj.machineInfo).every((v) => v != '')) {
            if (!wrongData) {
              wrongData = true;
            }
          }
          if (!wrongData) {
            wrongData = true;
          }
        }
      });
      if (!wrongData) {
        setEnableButton(true);
      }
    } else {
      setEnableButton(false);
    }
  };

  const handleChipClick = (chipData) => {
    setUserMachineData(chipData);
  };

  const handleDuplicateMachines = (machinesList) => {
    if (props.enableAddButton) {
      setDuplicateMessage('');
      let seen = new Set();
      seen.clear();
      var hasDuplicates = machinesList.some(function (currentObject) {
        return seen.size === seen.add(currentObject?.machineInfo?.hostName).size;
      });

      if (hasDuplicates) {
        setDuplicateMessage('Duplicate machine name.');
        return true;
      } else {
        setDuplicateMessage('');
        return false;
      }
      seen.clear();
    }
    return false;
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Select User',
        accessor: 'name',
        width: '80%',
        Cell: (e) => {
          let value = e.value;
          let check = e?.row?.original?.check ? true : false;
          let index = e.row.index;
          return (
            <span className="font-style-common">
              {props.selectedUserType === 'Multiple Users' ? (
                <input
                  className="inline-block"
                  type="checkbox"
                  name="user"
                  defaultChecked={userData.find((el) => el.name === value).check}
                  id={`user-${value}`}
                  onClick={(event) => {
                    handleCheck(value, event, index);
                  }}
                />
              ) : (
                <input
                  className="inline-block"
                  type="radio"
                  name="user"
                  defaultChecked={userData.find((el) => el.name === value).check}
                  id={`user-${value}`}
                  onClick={(event) => {
                    handleCheck(value, event, index);
                  }}
                />
              )}

              <span
                className="pt-1 inline-block w-11/12 cursor-pointer"
                onClick={() => {
                  handleRowSelect(value, userMachines);
                }}
              >
                <span className="pl-4 pr-3 table-non-link-color-common font-style-common">{value}</span>
              </span>
            </span>
          );
        },
      },
    ],
    [userMachines, userData]
  );

  const validateDevice = (machineData) => {
    let deviceIsValid = Object.keys(machineData?.deviceInfo?.[0])?.every((_key) => {
      if (machineData.deviceInfo[0]['version'] !== '' && machineData.deviceInfo[0]['name'] !== '') {
        return true;
      } else {
        return false;
      }
    });
    return deviceIsValid;
  };

  const validateObject = (machineData) => {
    if (isValidUserDetail(machineData)) {
      const isValid = Object.keys(machineData.machineInfo).every((_key) => {
        if (machineData.machineInfo[_key] !== '') {
          if (_key === 'hostName') {
            return ifMachineNameValid(machineData.machineInfo[_key]);
          }
          return true;
        }
        return false;
      });
      if (['web', 'web service']?.includes(projectType.toLowerCase())) {
        let isBrowserValid = machineData?.browserName !== '' && machineData?.browserVersion !== '';
        return isValid && isBrowserValid;
      } else if (projectType.toLowerCase() === 'mobile') {
        return isValid && validateDevice(machineData);
      } else if (['web & mobile', 'salesforce', 'ms dynamics'].includes(projectType.toLowerCase())) {
        let isBrowserValid = machineData?.browserName !== '' && machineData?.browserVersion !== '';
        return isBrowserValid && validateDevice(machineData) && isValid;
      }
    }
    return false;
  };

  const isValidUserDetail = (machineData) => {
    const excludedKeys = ['browserName', 'browserVersion', 'deviceInfo'];
    return Object.entries(machineData)
      .filter(([key]) => !excludedKeys.includes(key))
      .every(([_, value]) => value !== '');
  };

  const handleUserData = (machineData) => {
    machineData.clientId = selectedUser;
    if (
      props.selectedUserType === 'Single User' &&
      props.selectedOption !== 'Run this suite on multiple machine for compatibility'
    ) {
      setUserMachines([machineData]);
    } else {
      setUserMachines([...userMachines]);
    }
    let selectedUserData = userMachines.find((mach) => mach.clientId === selectedUser);
    if (selectedUserData) {
      let validated = validateObject(machineData);
      if (validated) {
        setEnableButton(true);
      } else {
        setEnableButton(false);
      }
    } else {
      setEnableButton(false);
    }
  };

  const ifMachineNameValid = (value) => {
    if (value.includes('/')) {
      setInvalidMachineNameMessage("Cannot have '/' in machine name.");
      return false;
    } else {
      setInvalidMachineNameMessage('');
      return true;
    }
  };

  const data = useMemo(() => {
    return userData;
  }, [userData]);

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      backgroundColor: state.isSelected ? '#F0F3F9' : '',
    }),
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const validateInput = (value, fieldName) => {
    if (REGEX.USER_SETTINGS_SPECIAL_CHARACTERS.test(value)) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [fieldName]: USER_SETTINGS_SPECIAL_CHARACTERS_ERROR.ERROR_MESSAGE,
      }));
      return false;
    } else if (REGEX.VALIDATE_TEXT_EXECUTION_TEXTFIELD.test(value)) {
      setErrorMessages((prevErrors) => ({ ...prevErrors, [fieldName]: Object.values(formik.errors) }));
      return false;
    } else {
      setErrorMessages((prevErrors) => ({ ...prevErrors, [fieldName]: '' }));
      return true;
    }
  };

  const userMachine = (machine, index) => {
    let selectedUserTempObj = userMachinesBackUp.find((mach) => mach.clientId === machine.clientId);
    return (
      <div className="user-machine-details-div grid grid-cols-1 gap-4 py-3">
        <div className="">
          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-2">
              <h1 className="details-data-style-common">
                <span className="text-red-400">*</span> Execution Environment :
              </h1>
            </div>
            {props.enableAddButton && (
              <div className={`text-right mr-6 ${userMachines?.length ? 'text-blue-500' : 'disabled-action'}`}>
                <AddToPhotosIcon onClick={() => addUserMachine('addMore', index, selectedUser)} />
              </div>
            )}
          </div>
          <select
            value={machine.executionEnv}
            className="system-config-execution-dropdown text-sm mt-1"
            style={customStyles}
            defaultValue=""
            onChange={(e) => {
              machine.executionEnv = e.target.value;
              handleUserData(machine);
              selUserObj.executionEnv = e.target.value;
              if (selectedUserTempObj && props?.selectedOption !== selectedStringOption) {
                selectedUserTempObj.executionEnv = e.target.value;
              }
            }}
          >
            <option value="">None</option>
            {executionEnvOptions.map((option, i) => (
              <option key={i} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="">
          <div>
            <span className="details-data-style-common">
              <span className="text-red-400">*</span> Machine :
            </span>
          </div>
          <TextField
            className="w-60 fontPoppinsRegularLg mt-1"
            type="text"
            autoComplete="off"
            name="machine_name"
            error={formik.errors.machine_name && formik.touched.machine_name}
            id="machine"
            placeholder={`Enter Machine Name`}
            onChange={(e) => {
              validateInput(e.target.value, 'machine');
              formik.handleChange(e);
              machine.machineInfo.hostName = e.target.value;
              handleUserData(machine);
              selUserObj['hostName'] = e.target.value;
              if (ifMachineNameValid(e.target.value)) {
                if (selectedUserTempObj && props?.selectedOption !== selectedStringOption) {
                  selectedUserTempObj.machineInfo.hostName = e.target.value;
                }
              }
            }}
            onBlur={(e) => {
              handleDuplicateMachines(userMachines);
              formik.handleBlur(e);
            }}
            value={machine.machineInfo.hostName}
            helperText={
              <div className="fontPoppinsRegularSm w-max">
                {formik.touched.machine_name && formik.errors.machine_name}
              </div>
            }
          />
          {duplicateMessage && <div className="errorMessage">{duplicateMessage}</div>}
        </div>
        <div className="">
          <h1 className="details-data-style-common">
            <span className="text-red-400">*</span> OS :
          </h1>
          <div className="">
            <select
              value={machine?.machineInfo?.osName}
              className="system-config-execution-dropdown text-sm mt-1"
              style={customStyles}
              defaultValue=""
              onChange={(e) => {
                machine.machineInfo.osName = e.target.value;
                handleUserData(machine);
                selUserObj['osName'] = e.target.value;
                if (selectedUserTempObj && props?.selectedOption !== selectedStringOption) {
                  selectedUserTempObj.machineInfo.osName = e.target.value;
                }
              }}
            >
              <option value="">None</option>
              {osDetails.map((option, i) => (
                <option key={i} value={option?.value}>
                  {option?.value}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="">
          <h1 className="details-data-style-common">
            <span className="text-red-400">*</span> OS Version :
          </h1>
          <div className="mt-1">
            <TextField
              className="w-60 fontPoppinsRegularLg"
              type="text"
              autoComplete="off"
              name="os_version"
              id="machine"
              error={formik.errors.os_version && formik.touched.os_version}
              placeholder={`Enter Os Version`}
              onChange={(e) => {
                validateInput(e.target.value, 'osVersion');
                formik.handleChange(e);
                machine.machineInfo.osVersion = e.target.value;
                handleUserData(machine);
                selUserObj['osVersion'] = e.target.value;
                if (selectedUserTempObj && props?.selectedOption !== selectedStringOption) {
                  selectedUserTempObj.machineInfo.osVersion = e.target.value;
                }
              }}
              onBlur={(e) => {
                formik.handleBlur(e);
              }}
              value={machine.machineInfo.osVersion}
              helperText={
                <div className="fontPoppinsRegularSm w-max">
                  {formik.touched.os_version && formik.errors.os_version}
                </div>
              }
            />
          </div>
        </div>
        {projectType.toLowerCase().includes('mobile') && (
          <>
            <div className="mt-1">
              <h1 className="details-data-style-common">
                <span class="text-red-400">*</span> Device :
              </h1>
              <div className="mt-1">
                <TextField
                  className="w-60 fontPoppinsRegularLg"
                  type="text"
                  autoComplete="off"
                  name="device1"
                  id="machine"
                  error={formik.errors.device1 && formik.touched.device1}
                  placeholder={`Enter Device Name`}
                  onChange={(e) => {
                    validateInput(e.target.value, 'deviceName');
                    formik.handleChange(e);
                    if (machine.deviceInfo?.length) {
                      machine.deviceInfo[0].name = e.target.value;
                    }
                    handleUserData(machine);
                    selUserObj['deviceName'] = e.target.value;
                    if (selectedUserTempObj && props?.selectedOption !== selectedStringOption) {
                      selectedUserTempObj.deviceInfo[0].name = e.target.value;
                    }
                  }}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                  helperText={
                    <div className="fontPoppinsRegularSm w-max">{formik.touched.device1 && formik.errors.device1}</div>
                  }
                  value={machine?.deviceInfo[0]?.name}
                />
              </div>
            </div>
            <div className="mt-1">
              <h1 className="details-data-style-common">
                <span class="text-red-400">*</span> Device Version :
              </h1>
              <div className="mt-1">
                <TextField
                  className="w-60 fontPoppinsRegularLg"
                  type="text"
                  autoComplete="off"
                  name="device_version1"
                  error={formik.errors.device_version1 && formik.touched.device_version1}
                  id="machine"
                  placeholder={`Enter Device Version`}
                  onChange={(e) => {
                    validateInput(e.target.value, 'deviceVersion');
                    formik.handleChange(e);
                    if (machine.deviceInfo?.length) {
                      machine.deviceInfo[0].version = e.target.value;
                    }
                    handleUserData(machine);
                    selUserObj['deviceVersion'] = e.target.value;
                    if (selectedUserTempObj && props?.selectedOption !== selectedStringOption) {
                      selectedUserTempObj.deviceInfo[0].version = e.target.value;
                    }
                  }}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                  helperText={
                    <div className="fontPoppinsRegularSm w-max">
                      {formik.touched.device_version1 && formik.errors.device_version1}
                    </div>
                  }
                  value={machine?.deviceInfo[0]?.version}
                />
              </div>
            </div>
          </>
        )}
        {projectType.toLowerCase().includes('web') && (
          <>
            <div className="mt-1">
              <h1 className="details-data-style-common">
                <span class="text-red-400">*</span> Browser :
              </h1>
              <div className="mt-1">
                <select
                  value={machine.browserName}
                  className="system-config-execution-dropdown text-sm mt-1"
                  style={customStyles}
                  defaultValue=""
                  onChange={(e) => {
                    machine.browserName = e.target.value;
                    handleUserData(machine);
                    selUserObj['browserName'] = e.target.value;
                    if (selectedUserTempObj && props?.selectedOption !== selectedStringOption) {
                      selectedUserTempObj.browserName = e.target.value;
                    }
                  }}
                >
                  <option value="">None</option>
                  {browserDetails.map((option, i) => (
                    <option key={i} value={option?.value}>
                      {option?.value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mt-1">
              <h1 className="details-data-style-common">
                <span class="text-red-400">*</span> Browser Version :
              </h1>
              <div className="mt-1">
                <TextField
                  className="w-60 fontPoppinsRegularLg"
                  type="text"
                  autoComplete="off"
                  name="browser_version1"
                  error={formik.errors.browser_version1 && formik.touched.browser_version1}
                  id="machine"
                  placeholder={`Enter Browser Version`}
                  onChange={(e) => {
                    validateInput(e.target.value, 'browserVersion');
                    formik.handleChange(e);
                    machine.browserVersion = e.target.value;
                    handleUserData(machine);
                    selUserObj['browserVersion'] = e.target.value;
                    if (selectedUserTempObj && props?.selectedOption !== selectedStringOption) {
                      selectedUserTempObj.browserVersion = e.target.value;
                    }
                  }}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                  value={machine.browserVersion}
                  helperText={
                    <div className="fontPoppinsRegularSm w-max">
                      {formik.touched.browser_version1 && formik.errors.browser_version1}
                    </div>
                  }
                />
              </div>
            </div>
          </>
        )}
        {['Salesforce', 'MS Dynamics'].includes(projectType) && (
          <>
            <div className="mt-1">
              <h1 className="details-data-style-common">
                <span className="text-red-400">*</span> Device :
              </h1>
              <div className="mt-1">
                <TextField
                  className="w-60 fontPoppinsRegularLg"
                  type="text"
                  autoComplete="off"
                  id="machine"
                  name="device1"
                  error={formik.errors.device1 && formik.touched.device1}
                  placeholder={`Enter Device Name`}
                  onChange={(e) => {
                    validateInput(e.target.value, 'deviceName');
                    formik.handleChange(e);
                    if (machine.deviceInfo?.length) {
                      machine.deviceInfo[0].name = e.target.value;
                    }
                    handleUserData(machine);
                    selUserObj['deviceName'] = e.target.value;
                    if (selectedUserTempObj && props?.selectedOption !== selectedStringOption) {
                      selectedUserTempObj.deviceInfo[0].name = e.target.value;
                    }
                  }}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                  helperText={
                    <div className="fontPoppinsRegularSm w-max">{formik.touched.device1 && formik.errors.device1}</div>
                  }
                  value={machine?.deviceInfo[0]?.name}
                />
              </div>
            </div>
            <div className="mt-1">
              <h1 className="details-data-style-common">
                <span className="text-red-400">*</span> Device Version :
              </h1>
              <div className="mt-1">
                <TextField
                  className="w-60 fontPoppinsRegularLg"
                  type="text"
                  autoComplete="off"
                  name="device_version1"
                  error={formik.errors.device_version1 && formik.touched.device_version1}
                  id="machine"
                  placeholder={`Enter Device Version`}
                  onChange={(e) => {
                    validateInput(e.target.value, 'deviceVersion');
                    formik.handleChange(e);
                    if (machine.deviceInfo?.length) {
                      machine.deviceInfo[0].version = e.target.value;
                    }
                    handleUserData(machine);
                    selUserObj['deviceVersion'] = e.target.value;
                    if (selectedUserTempObj && props?.selectedOption !== selectedStringOption) {
                      selectedUserTempObj.deviceInfo[0].version = e.target.value;
                    }
                  }}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                  helperText={
                    <div className="fontPoppinsRegularSm w-max">
                      {formik.touched.device_version1 && formik.errors.device_version1}
                    </div>
                  }
                  value={machine?.deviceInfo[0]?.version}
                />
              </div>
            </div>
            <div className="mt-1">
              <h1 className="details-data-style-common">
                <span className="text-red-400">*</span> Browser :
              </h1>
              <div className="mt-1">
                <select
                  value={machine.browserName}
                  className="system-config-execution-dropdown text-sm mt-1"
                  style={customStyles}
                  defaultValue=""
                  onChange={(e) => {
                    machine.browserName = e.target.value;
                    handleUserData(machine);
                    selUserObj['browserName'] = e.target.value;
                    if (selectedUserTempObj && props?.selectedOption !== selectedStringOption) {
                      selectedUserTempObj.browserName = e.target.value;
                    }
                  }}
                >
                  <option value="">None</option>
                  {browserDetails.map((option, i) => (
                    <option key={i} value={option?.value}>
                      {option?.value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mt-1">
              <h1 className="details-data-style-common">
                <span class="text-red-400">*</span> Browser Version :
              </h1>
              <div className="mt-1">
                <TextField
                  className="w-60 fontPoppinsRegularLg"
                  type="text"
                  autoComplete="off"
                  name="browser_version1"
                  error={formik.errors.browser_version1 && formik.touched.browser_version1}
                  id="machine"
                  placeholder={`Enter Browser Version`}
                  onChange={(e) => {
                    validateInput(e.target.value, 'browserVersion');
                    formik.handleChange(e);
                    machine.browserVersion = e.target.value;
                    handleUserData(machine);
                    selUserObj['browserVersion'] = e.target.value;
                    if (selectedUserTempObj && props?.selectedOption !== selectedStringOption) {
                      selectedUserTempObj.browserVersion = e.target.value;
                    }
                  }}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                  helperText={
                    <div className="fontPoppinsRegularSm w-max">
                      {formik.touched.browser_version1 && formik.errors.browser_version1}
                    </div>
                  }
                  value={machine.browserVersion}
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const handleRowSelect = (rowSelected, userMachineData) => {
    setSelectedUser(rowSelected);
    let tempObj = {
      name: rowSelected,
    };
    selectedRow = tempObj;
    selUserObj = {};
    if (props?.selectedOption !== selectedStringOption) {
      if (userMachineData?.length) {
        let selectedUser = userMachineData.find((mach) => mach.clientId === rowSelected);
        if (selectedUser) {
          setUserMachineData(selectedUser);
          let validated = validateObject(selectedUser);
          if (validated) {
            setEnableButton(true);
          } else {
            setEnableButton(false);
          }
        } else {
          updateUserMachineAndSetEnableButton(rowSelected);
        }
      } else {
        updateUserMachineAndSetEnableButton(rowSelected);
      }
    }
  };

  const updateUserMachineAndSetEnableButton = (rowSelected) => {
    let selectedSystemObj = defaultSystemObject(rowSelected);
    setUserMachineData(selectedSystemObj);
    setEnableButton(false);
  };

  const handleSelect = () => {
    let duplicates = handleDuplicateMachines(userMachines);
    let machinesList = userMachines.filter((ele) => userData.find((val) => val.name === ele.clientId && val.check));
    let wrongData = false;
    if (props.selectedUserType === 'Multiple Users') {
      const uniqueIds = [];
      const unique = machinesList.filter((element) => {
        const isDuplicate = uniqueIds.includes(element.clientId);
        if (!isDuplicate) {
          uniqueIds.push(element.clientId);
          return true;
        }
        return false;
      });
      unique?.forEach((machine) => {
        if (isValidUserDetail(machine)) {
          if (Object.values(machine.machineInfo).every((v) => v !== '')) {
          } else {
            if (!wrongData) {
              wrongData = true;
            }
          }
        } else {
          if (!wrongData) {
            wrongData = true;
          }
        }
      });
      if (!duplicates && !wrongData) {
        props.handleSelecteduser(unique);
        localStorage.removeItem('userMachineBackupData');
      } else {
        MyAlert.info('User details required');
      }
    } else {
      if (!duplicates) {
        if (props?.selectedOption === 'Run this suite on multiple machine for compatibility') {
          let tempUserArray = [];
          if (userMachines.length) {
            userMachines.forEach((obj) => {
              if (ifMachineNameValid(obj.machineInfo.hostName)) {
                if (obj?.clientId === selUserName) {
                  tempUserArray.push(obj);
                } else {
                  MyAlert.info('User details required');
                }
              } else {
                MyAlert.info(invalidMachineNameMessage);
              }
            });
          }
          props.handleSelecteduser([...tempUserArray]);
        } else {
          props.handleSelecteduser(userMachines);
        }
        localStorage.removeItem('userMachineBackupData');
      }
    }
    selUserObj = {};
    selectedRow = undefined;
    selUserName = undefined;
  };

  function cancelFunction() {
    selUserObj = {};
    selectedRow = undefined;
    selUserName = undefined;
    const userMachinesBackUp = JSON.parse(localStorage.getItem('userMachineBackupData'));
    setUserMachines(userMachinesBackUp || []);
    props.handleSelecteduser(userMachinesBackUp);
    localStorage.removeItem('userMachineBackupData');
  }

  return (
    <Modal open={props.openModal} className="modal-dialog">
      <div className="modal-container device-modal-style">
        <div className="modal-header bg-gray-100">
          <label className="Popup-header-common">Test Environments</label>
          <IconButton
            color="primary"
            component="span"
            className="close"
            onClick={() => {
              props.handleModal(false);
              cancelFunction();
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div className="modal-body bg-gray-100" id="journal-scroll">
          <div className="alert-variable-modal-position-style">
            <AlertContatiner />
          </div>
          <div className="table_height overflow-hidden" id="journal-scroll">
            <div className="m-2 qrs-system-grid-container grid grid-cols-7 gap-2.5 px-4 py-1">
              <div className="qrs-machine-table col-span-4">
                <table {...getTableProps()} className="table-non-link-color-common font-style-common">
                  <thead className="">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()} className="config-table-header h-10">
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps()}
                            key={column.Header}
                            width={column.width}
                            className="pl-5 text-left text-sm"
                          >
                            {column.render('Header')}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows?.length ? (
                      rows.map((row, i) => {
                        prepareRow(row);
                        return (
                          <tr
                            key={i}
                            className={`border-b border-blue-100 h-10 config-table-row`}
                            onClick={() => {
                              handleRowSelect(row?.values?.name, userMachines);
                            }}
                          >
                            {row.cells.map((cell, cellIndex) => {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  key={i}
                                  className={`pl-3 text-left text-xs ${
                                    cell?.row?.original?.name === selectedUser ? 'selected-row-style' : ''
                                  }`}
                                  width={columns[cellIndex].width}
                                >
                                  {cell.render('Cell')}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="no-user-info">No Users are Available</tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="qrs-details-div col-span-3">
                {(selectedUser || userMachines.length) && (
                  <div className="qrs-details-overflow">
                    {
                      <div className="px-4 py-2 grid grid-cols-1 gap-4" key={`user-machine`}>
                        <h1 className="details-data-style-common">User Details :</h1>
                        <span className="table-non-link-color-common font-style-common">
                          {selectedUser || (userMachines?.length ? userMachines[0].clientId : '')}
                        </span>
                        <div className="grid grid-cols-6">
                          {userMachines.length > 1 &&
                            props.enableAddButton &&
                            userMachines.map((machine, index) => {
                              return (
                                <>
                                  {userMachines.length !== index + 1 &&
                                    machine?.machineInfo?.hostName &&
                                    machine?.clientId === selectedUser && (
                                      <div>
                                        <Tooltip title={machine?.machineInfo?.hostName}>
                                          <Chip
                                            className="fontPoppinsRegularSm"
                                            label={machine?.machineInfo?.hostName}
                                            onDelete={() => handleChipDelete(index)}
                                            onClick={() => handleChipClick(machine)}
                                          />
                                        </Tooltip>
                                      </div>
                                    )}
                                </>
                              );
                            })}
                        </div>
                        {userMachineData?.machineInfo && userMachine(userMachineData)}
                      </div>
                    }
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer bg-gray-100">
          <button
            className="gray-btn mr-3"
            onClick={() => {
              props.handleModal(false);
              cancelFunction();
            }}
          >
            Cancel
          </button>
          <button
            id="selectBtn"
            className={`fontPoppinsMediumMd ${
              enableButton && duplicateMessage === '' ? 'primary-btn' : 'disabled-update-btn'
            }`}
            onClick={() => handleSelect()}
            disabled={Object.keys(formik.errors).length !== 0}
          >
            Select
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default UserMachineChildPage;

import axios from 'axios';
import {
  logger,
  getRefreshToken,
  getUserEmailId,
  getCurrentLicenseId,
  getWebSiteHostName,
  getCurrentProjectTimeZoneName,
  timeZoneConversion,
} from '@src/util/common_utils';

const managementBaseUrl = process.env.REACT_APP_MANAGEMENT_BASE_URL;

const instance = axios.create({});

instance.interceptors.request.use(function (config) {
  const project = localStorage.getItem('selected-project');
  const gitProject = localStorage.getItem('selected-Git-project');
  const user = localStorage.getItem('test-optimize-user');
  const userSession = user ? JSON.parse(user) : {};
  const selectedProject = project ? JSON.parse(project) : {};
  const selectedGitProject = gitProject ? JSON.parse(gitProject) : {};
  const configRequest = JSON.parse(localStorage.getItem('configReq'));
  const configPutRequest = JSON.parse(localStorage.getItem('configPutReq'));
  const ResultGetRequest = JSON.parse(localStorage.getItem('resultGetReq'));
  const ResultPutRequest = JSON.parse(localStorage.getItem('resultPutReq'));

  let token = null;
  if (userSession) {
    token = userSession.access_token;
  }
  const passProjectId = config.passProjectId === undefined ? true : config.passProjectId;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  if(userSession.licenseType) {
    config.headers.licenseType  = userSession.licenseType;
  }
  
  if (!config.headers?.projectId && passProjectId) {
    if (!configRequest && !configPutRequest && !ResultGetRequest && !ResultPutRequest) {
      config.headers['projectId'] = selectedProject ? selectedProject.id : 'PJT6549';
      config.headers['projectType'] = selectedProject ? selectedProject.type : undefined;
      if (config.headers['projectId'] === undefined) {
        config.headers['projectId'] = '';
      }
      if (selectedGitProject.id && !selectedProject.id) {
        config.headers['projectId'] = selectedGitProject ? selectedGitProject.id : 'PJT6549';
      }
    }
  }

  const initialTimeZoneName = getCurrentProjectTimeZoneName() || '';
  let licenseLevelTimeZoneName = localStorage.getItem('license-level-timezone');

  if (!licenseLevelTimeZoneName) {
    localStorage.setItem('license-level-timezone', licenseLevelTimeZoneName);
  }
  config.headers['timeZone'] = selectedProject?.id ? initialTimeZoneName : licenseLevelTimeZoneName;

  if (config.responseType === 'blob') {
    return config;
  }

  if (config?.headers?.type === 'formdata') {
    config.headers['Content-Type'] = 'multipart/form-data';
    return config;
  }

  if (config?.headers?.type === 'text') {
    config.headers['Content-Type'] = 'text/plain';
  } else {
    config.headers['Content-Type'] = 'application/json';
  }

  config.headers['Accept'] = 'application/json';
  return config;
});

const updateTokenData = (response) => {
  if (response.status === 200 && response?.data?.access_token) {
    const user = localStorage.getItem('test-optimize-user');
    const userSession = user ? JSON.parse(user) : {};
    userSession['access_token'] = response.data.access_token;
    userSession['refresh_token'] = response.data.refresh_token;
    localStorage.setItem('test-optimize-user', JSON.stringify(userSession));
    logger('TokenData Updated!', response);
  }
};

const refreshTokenExpired = (error) => {
  logger('Refresh Token Expired! ', error);
  localStorage.clear();
  window.location.replace(getWebSiteHostName() + '/signin');
};
createAxiosResponseInterceptor();

function createAxiosResponseInterceptor() {
  instance.interceptors.response.use(
    (response) => {
      let modifiedResponse = timeZoneConversion(response);

      const isTokenExpired = localStorage.getItem('refreshToken');
      if (isTokenExpired) {
        localStorage.removeItem('refreshToken');
        logger('Token Expired!', response);
      }
      return modifiedResponse;
    },
    (error) => {
      logger('Interceptor Error =>', error);

      // commented for upcoming release
      // if(!error.response){
      //   localStorage.clear();
      //  window.location.replace("/service-downtime");
      // }

      if (error.response?.status === 401) {
        logger('Refresh Token API called!');
        localStorage.setItem('refreshToken', getRefreshToken());
        return axios
          .post(`${managementBaseUrl}/optimize/v1/public/user/refresh`, {
            refreshToken: getRefreshToken(),
            currentLicenseId: getCurrentLicenseId(),
          })
          .then((response) => {
            updateTokenData(response);
            const newAccessToken = response?.data?.access_token;
            error.response.config.headers['Authorization'] = 'Bearer ' + newAccessToken;
            return axios(error.response.config);
          })
          .catch((error2) => {
            refreshTokenExpired(error2);
            return Promise.reject(error2);
          });
      }
      if (error.response?.status !== 401) {
        if (error.response?.status === 404 && error.response?.data?.type === 'text/xml') {
          return Promise.resolve(error);
        }
        return Promise.reject(error);
      }
    }
  );
}

export default instance;

import SectionHeader from '../components/SectionHeader';
import Select from 'react-dropdown-select';
import DashboardStyle from '../styles/dashboard.module.scss';
import { ClientsContext } from './ClientsProvider';
import { useContext, useRef } from 'react';
import { clientTypes } from '../utils/clientsSectionUtils';

const clientTypesForSelect = clientTypes.map((type) => ({ label: type }));
function ClientSectionHeader() {
  const { selectedClientType, setSelectedClientType } = useContext(ClientsContext);
  const ref = useRef();

  /**Create a custom svg icon for the client header using path, and align the carrot icon using viewport. */
  if (ref?.current?.props) {
    let refContainer = document.querySelector('.react-dropdown-select-dropdown-handle').querySelector('svg');
    let path = refContainer.querySelector('path');
    path.setAttribute('d', 'M12 14l-4-4h8z');
    refContainer.setAttribute('viewBox', '0 0 23 23');
  }

  return (
    <SectionHeader className="py-0 pr-0">
      <div className="flex justify-between items-center  select-none">
        <span>Clients</span>
        <Select
          ref={ref}
          options={clientTypesForSelect}
          values={[{ label: selectedClientType }]}
          className={DashboardStyle['clients-section-header-dropdown-filter-wrapper']}
          searchable={false}
          direction="auto"
          dropdownPosition="auto"
          valueField="label"
          onChange={(values) => setSelectedClientType(values[0].label)}
        />
      </div>
    </SectionHeader>
  );
}

export default ClientSectionHeader;

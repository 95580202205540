import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import '@pages/licenses/styles/license-alert-popup.scss';
import { ReactComponent as YellowAlert } from '@assets/svg-imports/yellow_alert.svg';

function DeleteTemplateModal(props) {
  const userName = JSON.parse(localStorage.getItem('test-optimize-user'))?.name;
  return (
    <>
      <Modal
        open={props.openForDeleteTempChange}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="focus:outline-none outline-none"
      >
        <Box className="changeLicenseBoxModal focus:outline-none outline-none">
          <div className="headerLicenseChange">
            <div className="successImg">
              <YellowAlert className="svgImgStyle" />
            </div>
            <div className="mt-8">
              <p className="svgContentYellow mb-2 fontPoppinsMediumPageSubHeader">
                Warning! <span className="text-black">Delete Template</span>
              </p>
              <p className="fontPoppinsRegularLg ml-7 mt-3">
                Hi {userName}, <br /> Your data will be lost. Are you sure you want to delete{'  '}
                <span className="flex flex-row">
                  <div className="fontPoppinsSemiboldLg word-wrap-class-for-alert mr-1">{props.deleteTempName} </div>?
                </span>
              </p>
            </div>
          </div>
          <div className="footerBlue flex ">
            <button
              className="buttonStyleForChangeLicense cursor-pointer fontPoppinsRegularMd"
              onClick={() => {
                props.handleCloseForDeleteTempChange();
              }}
            >
              Cancel
            </button>
            <button
              className="primaryBtnStyleForBlue cursor-pointer fontPoppinsRegularMd"
              onClick={() => {
                props.deleteTemplate();
                props.handleCloseForDeleteTempChange();
              }}
            >
              Delete
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default DeleteTemplateModal;

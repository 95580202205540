import { Radio } from '@mui/material';
import { getAllTestCaseTemplate } from '@api/api_services';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import NewManualScript from './modal-components/new-manual-script';
import ExistingManualScript from './modal-components/existing-manual-script';
import { Close } from '@mui/icons-material';
import { getLicenseFeatures, purchasedFeatureTypeOfAutomation } from '@src/util/common_utils';
import { useAlert } from '@pages/common/alert_service/useAlert';

Modal.setAppElement('#root');
const CreateManualScript = (props) => {
  const { isToggled, setIsToggled, hasManualOrBoth } = props;
  const { AlertContatiner, MyAlert } = useAlert();
  const [openModal, setOpenModal] = useState(true);
  const customStylesPage = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '640px',
      height: 'fit-content',
      background: '#fbfcfd 0% 0% no-repeat padding-box',
      boxShadow: '0px 0px 11px #000000a1',
      borderRadius: '4px',
      opacity: '1',
    },
  };
  const [newScript, setNewScript] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [defaultTemplate, setDefaultTemplate] = useState();
  const [disableTemplateDropdown, setDisableTemplateDropdown] = useState(false);
  const licenseFeatures = getLicenseFeatures();
  const hasAutomation = window.privilege.hasAutomation(licenseFeatures);
  const project = JSON.parse(localStorage.getItem('selected-project'));
  const hasAutomationBasedOnProjType = purchasedFeatureTypeOfAutomation(project.type);
  const handleRadioChange = (e) => {
    if (e.target.value === 'newScript') {
      setNewScript(true);
    } else {
      setNewScript(false);
    }
  };

  const getTemplates = async () => {
    try {
      const templateResponse = await getAllTestCaseTemplate('config');
      if (templateResponse?.data?.responseObject?.templates) {
        const allTemplates = templateResponse.data.responseObject.templates.map((temp) => {
          return { ...temp, label: temp.name };
        });
        setTemplates([...allTemplates]);
        if (templateResponse.data.responseObject?.defaultTemplate) {
          const defaultTemplateTemp = templateResponse.data.responseObject.defaultTemplate;
          setDefaultTemplate([{ ...defaultTemplateTemp, label: defaultTemplateTemp.name }]);
        } else {
          setDefaultTemplate();
        }
        if (templateResponse.data.responseObject?.manualScriptPresent) {
          setDisableTemplateDropdown(templateResponse.data.responseObject.manualScriptPresent);
        } else {
          setDisableTemplateDropdown(false);
        }
      } else {
        setTemplates([]);
      }
    } catch (err) {
      console.error('GET_TEMPLATES : ', err);
    }
  };
  useEffect(() => {
    getTemplates();
  }, []);

  const handleEscapeClose = (e) => {
    if (e.key === 'Escape') {
      setOpenModal(false);
      props.closeModal(false);
    }
  };
  return (
    <Modal isOpen={openModal} style={customStylesPage} className="p-px" onRequestClose={handleEscapeClose}>
      <div className="mt-2 p-1">
        <div className="step-alert-position-style ml-32 mt-20">
          <AlertContatiner></AlertContatiner>
        </div>
        <span className="Popup-header-common pl-3 inline-block w-10/12 text-overflow-style">
          <span className=""> Create Manual Test Case</span>
        </span>
        <div className="float-right">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="ml-5 mr-5 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <Close color="action" />
          </button>
        </div>
      </div>
      <div
        className="border-b border-t border-blue-100 my-px modal-body-testdevheight overflow-y-auto"
        id="journal-scroll"
      >
        <div className="mx-6 my-1 mb-3 manual-script-modal">
          <div className="grid">
            {hasAutomation && hasAutomationBasedOnProjType && (
              <>
                <p className="pr-4 my-1 fontPoppinsRegularMd">
                  Do you want to create a new Manual test case or add to existing Automation script ?
                </p>
                <p className="my-1 fontPoppinsRegularMd">
                  <span>
                    <Radio
                      className="mr-2"
                      color="primary"
                      name="addType"
                      checked={newScript}
                      value="newScript"
                      onChange={handleRadioChange}
                    />{' '}
                    Create new Manual test case
                  </span>
                </p>
                <p className="my-1 fontPoppinsRegularMd">
                  <span className={`${!isToggled && 'opacity-60'}`}>
                    <Radio
                      className="mr-2"
                      color="primary"
                      name="addType"
                      disabled={!isToggled}
                      checked={!newScript}
                      value="existingScript"
                      onChange={handleRadioChange}
                    />{' '}
                    Add Manual test case to existing Automation script
                  </span>
                </p>
              </>
            )}
            <div className="">
              {newScript ? (
                <NewManualScript
                  {...props}
                  templates={templates}
                  defaultTemplate={defaultTemplate}
                  disableTemplateDropdown={disableTemplateDropdown}
                  MyAlert={props.MyAlert}
                  labelAlert={MyAlert}
                  isToggled={isToggled}
                  setIsToggled={setIsToggled}
                  hasManualOrBoth={hasManualOrBoth}
                />
              ) : (
                <ExistingManualScript
                  {...props}
                  templates={templates}
                  defaultTemplate={defaultTemplate}
                  disableTemplateDropdown={disableTemplateDropdown}
                  isToggled={isToggled}
                  handleToggle={() => {
                    setIsToggled(!isToggled);
                  }}
                  hasManualOrBoth={hasManualOrBoth}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="float-right my-3 mx-5">
        <button onClick={() => props.closeModal(false)} type="button" className="gray-btn">
          Cancel
        </button>
        <button form={newScript ? 'newScriptForm' : 'existingScriptForm'} type="submit" className="primary-btn ml-3">
          Create
        </button>
      </div>
    </Modal>
  );
};

export default CreateManualScript;

const getFont = (family = 'Poppins-Regular', size = '16px') => {
  return {
    fontFamily: `${family}`,
    fontSize: `${size}`,
  };
};
export const fontPoppins = {
  rXL: getFont('Poppins-Regular', '18px'),
  rLg: getFont('Poppins-Regular', '16px'),
  rMd: getFont('Poppins-Regular', '14px'),
  rSm: getFont('Poppins-Regular', '12px'),
  rXs: getFont('Poppins-Regular', '10px'),
  mLg: getFont('Poppins-Medium', '16px'),
  mMd: getFont('Poppins-Medium', '14px'),
  mSm: getFont('Poppins-Medium', '12px'),
  sXlg: getFont('Poppins-SemiBold', '18px'),
  sLg: getFont('Poppins-SemiBold', '16px'),
  sMd: getFont('Poppins-SemiBold', '14px'),
  sSm: getFont('Poppins-SemiBold', '12px'),
  sDm: getFont('Poppins-SemiBold', '13px'),
};

import React, { useState, Fragment } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import ElementDetailsPage from '../../element_details_page';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import SharedEleHistory from './sharedEle-history';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const SharedElementsRouter = (props) => {
  const { history } = props;
  let historyLocation = history.location.pathname;
  let path;
  let index = historyLocation.lastIndexOf('/');
  path = historyLocation.slice(0, index);
  let count = path.split('/').length;

  let activeTab;
  const search = window.location.search;
  const eleId = new URLSearchParams(search).get('eleId');

  activeTab = history.location.pathname.split('/')[count];

  const [tabsList, setTabsList] = useState([
    {
      name: 'Shared Element',
      logo: '/assets/images/project_rec_logo.svg',
      selectedLogo: '/assets/images/project_rec_logo_blue.svg',
      pathUrl: `${path}/Shared Element?eleId=${eleId}`,
      key: 'Shared-element-tab',
      selectedName: 'Shared Element',
    },
  ]);
  let renderTabs = () => {
    return (
      <div>
        <ul className="navBar text-blue-600 flex flex-row primary-tab">
          {tabsList.map((tab, index) => {
            return (
              <li>
                <a
                  href="#"
                  key={tab.key}
                  onClick={(event) => {
                    event.preventDefault();
                    props.history.push(tab.pathUrl);
                  }}
                  className={`${activeTab === tab.selectedName ? 'selected_tab_style' : ''}`}
                >
                  {tab.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };
  return (
    <div className="page-list-body">
      <div className="page-tab-with-actions">
        {renderTabs()}
        <Menu as="div" className="ml-auto">
          {({ open }) => (
            <>
              <div>
                <Menu.Button className="bg-gray-100  rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                  <span className="sr-only">Open options</span>
                  <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Help
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
      <Switch>
        <Route path={`${path}/Shared Element`} exact={true} strict={true} key="Shared-elem-detail-page">
          <ElementDetailsPage isShared={true} />
        </Route>
        <Route
          path={`${path}/history`}
          exact={true}
          strict={true}
          key="Shared-elem-history-page"
          component={SharedEleHistory}
        ></Route>
      </Switch>
    </div>
  );
};

export default withRouter(SharedElementsRouter);

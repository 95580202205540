import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import '../commons/template.scss';
import { TextField, TextareaAutosize, Select, Tooltip } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import Grid from '@mui/material/Grid';
import { createDefectTemplateRequest, updateDefectTemplateRequest } from '@api/api_services';
import FieldForm from '../commons/field_form';
import { useAlert } from '@pages/common/alert_service/useAlert';
import { getLabel, getOptionLabel } from '../utils/common-functions';
import CancelCreateTemp from '../commons/CancelCreateTemp';
import RichTextEditor from '@src/pages/common/rich-text-editor';

export default function DefectTemplateCreationPage() {
  const history = useHistory();
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [openTSColumnModal, setOpenTSColumnModal] = useState(false);
  const location = useLocation();
  const [selectedCF, setSelectedCF] = useState('');
  const [showErrorMsg, setShowErrorMsg] = useState('');
  const [editFieldValue, setEditFieldValue] = useState({});
  const [createCalled, setCreateCalled] = useState(false);

  const projectId = JSON.parse(localStorage.getItem('selected-project')).id;
  const dmode = new URLSearchParams(location.search).get('dmode');
  const getData = () => {
    if (['edit', 'view'].includes(dmode)) {
      const selectedTemplateId = new URLSearchParams(location.search).get('templateId');
      if (selectedTemplateId) {
        return JSON.parse(localStorage.getItem('selectedTemplate'));
      } else {
        localStorage.removeItem('selectedTemplate');
        return JSON.parse(location.state);
      }
    } else {
      localStorage.removeItem('selectedTemplate');
      return '';
    }
  };
  const editData = getData();
  const { AlertContatiner, MyAlert } = useAlert();
  const [openDataLossModal, setOpenDataLossModal] = useState(false);

  const data = {
    defectDetails: [
      {
        enable: 'no',
        label: 'ID',
        mandatory: 'no',
        order: '1',
        placeholder: 'Your Id goes here',
        type: 'textField',
      },
      {
        defaultValue: '',
        enable: 'yes',
        label: 'Summary',
        mandatory: 'yes',
        maxLength: '',
        minLength: '',
        order: '2',
        placeholder: 'Summary goes here',
        type: 'textField',
      },
      {
        enable: 'yes',
        label: 'Module',
        mandatory: 'yes',
        options: [],
        order: '3',
        placeholder: 'Select module',
        type: 'dropdown',
      },
      {
        enable: 'yes',
        label: 'Labels',
        mandatory: 'no',
        options: [],
        order: '4',
        placeholder: 'Select Labels',
        type: 'dropdown',
      },
      {
        enable: 'yes',
        label: 'Description',
        mandatory: 'no',
        order: '5',
        type: 'textEditor',
      },
      {
        enable: 'yes',
        label: 'Severity',
        mandatory: 'yes',
        options: ['Blocker', 'Critical', 'Major', 'Minor'],
        order: '6',
        placeholder: 'Select severity',
        type: 'dropdown',
      },
      {
        enable: 'yes',
        label: 'Priority',
        mandatory: 'yes',
        options: ['High', 'Medium', 'Low'],
        order: '7',
        placeholder: 'Select priority',
        type: 'dropdown',
      },
      {
        enable: 'yes',
        label: 'State',
        mandatory: 'yes',
        options: [
          'NEW',
          'ASSIGNED',
          'FIXED',
          'CLOSED',
          'REOPENED',
          'DUPLICATE',
          'REJECTED',
          'DEFERRED',
          'NOT A DEFECT',
        ],
        order: '8',
        placeholder: 'State',
        type: 'dropdown',
      },
      {
        defaultValue: '',
        enable: 'no',
        label: 'Created By',
        mandatory: 'no',
        maxLength: '',
        minLength: '',
        order: '9',
        placeholder: 'Name goes here',
        type: 'textField',
      },
      {
        dateFormat: 'dd-mm-yyyy',
        enable: 'no',
        label: 'Created On',
        mandatory: 'no',
        order: '10',
        placeholder: '',
        type: 'date',
      },
      {
        defaultValue: '',
        enable: 'no',
        label: 'Modified By',
        mandatory: 'no',
        maxLength: '',
        minLength: '',
        order: '11',
        placeholder: 'Name goes here',
        type: 'textField',
      },
      {
        dateFormat: 'dd-mm-yyyy',
        enable: 'no',
        label: 'Modified On',
        mandatory: 'no',
        order: '12',
        placeholder: '',
        type: 'date',
      },
      {
        enable: 'yes',
        label: 'Assign to',
        mandatory: 'no',
        options: [],
        order: '13',
        placeholder: 'Select Assignee',
        type: 'dropdown',
      },
      {
        defaultValue: '',
        enable: 'yes',
        label: 'Release',
        mandatory: 'no',
        maxLength: '25',
        minLength: '3',
        order: '14',
        placeholder: 'Release goes here',
        type: 'textField',
      },
      {
        defaultValue: '',
        enable: 'yes',
        label: 'Build ID',
        mandatory: 'no',
        maxLength: '25',
        minLength: '3',
        order: '15',
        placeholder: 'Build ID goes here',
        type: 'textField',
      },
      {
        enable: 'yes',
        label: 'Attach Video',
        mandatory: 'no',
        order: '16',
        type: 'attachment',
      },
      {
        enable: 'yes',
        label: 'Attach multiple images',
        mandatory: 'no',
        order: '17',
        type: 'attachment',
      },
      {
        defaultValue: '',
        enable: 'yes',
        label: 'Linked Defects',
        mandatory: 'no',
        maxLength: '25',
        minLength: '3',
        order: '18',
        placeholder: 'Linked defects goes here',
        type: 'textField',
      },
      {
        defaultValue: '',
        enable: 'yes',
        label: 'Comments',
        mandatory: 'no',
        maxLength: '25',
        minLength: '3',
        order: '19',
        placeholder: 'Comments goes here',
        type: 'textField',
      },
    ],
    name: '',
  };

  const editOrViewMode = dmode === 'edit' || dmode === 'view';
  const [TemplateData, setTemplateData] = useState(
    dmode === 'create'
      ? JSON.parse(JSON.stringify(data))
      : editOrViewMode
        ? JSON.parse(JSON.stringify(editData.data))
        : null
  );
  const [templateName, setTemplateName] = useState(
    dmode === 'create' ? '' : editOrViewMode ? editData.data.name : null
  );
  const [templateId, setTemplateId] = useState(editOrViewMode ? editData.data._id : '');

  const closeModal = (value) => {
    if (openAccountModal) {
      setEditFieldValue({});
      setOpenAccountModal(false);
    }
    if (openTSColumnModal) {
      setOpenTSColumnModal(false);
    }
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .ensure()
      .required('Template Name is required')
      .matches(/^[-_A-Za-z0-9 ]*$|^[-_A-Za-z0-9 ][A-Za-z0-9 ]*[-_A-Za-z-_]$/, 'Name should be alphanumeric')
      .matches(/^[-a-zA-Z0-9-()_]+(\s+[-a-zA-Z0-9-()_]+)*$/, 'Space is not allowed in the beginning and at the end')
      .min(3, 'Minimum three characters are required')
      .max(100, 'Maximum hundred characters are required'),
  });

  let checkValidationForAlphanumeric = /^[-_A-Za-z0-9 ]*$|^[-_A-Za-z0-9 ][A-Za-z0-9 ]*[-_A-Za-z-_]$/;

  const addNameField = (e) => {
    setShowErrorMsg('');
    setTemplateName(e.target.value);
    TemplateData.name = templateName;
  };

  const formikDetails = useFormik({
    validationSchema,
  });

  const removeTestDetailsData = (value) => {
    let tempTemplateData = TemplateData;
    if (value) {
      let index = tempTemplateData.defectDetails.findIndex((x) => x.type === value.type && x.label === value.label);
      tempTemplateData.defectDetails.forEach((value, i) => {
        if (i > index) {
          value.order = parseInt(value.order) - 1 + '';
        }
      });
      index !== -1 && tempTemplateData.defectDetails.splice(index, 1);
      setTemplateData({ ...tempTemplateData });
      MyAlert.success(
        `${value.label.length > 30 ? value.label.substring(0, 32) + '...' : value.label} field removed successfully`
      );
    }
  };

  const testCaseDetailDescElements = (data) => {
    switch (data.type) {
      case 'textField':
        return (
          <div className="rs-user-input-container">
            <TextField
              disabled={true}
              InputProps={{ disableUnderline: true }}
              className="lg:w-72"
              title={data?.placeholder?.length > 30 ? data?.placeholder : null}
              placeholder={data?.placeholder?.length > 30 ? data.placeholder?.substr(0, 30) + '...' : data?.placeholder}
              value={data.defaultValue}
            />
          </div>
        );

      case 'textArea':
        return (
          <>
            <div className="rs-user-input-container">
              <TextareaAutosize
                disabled={true}
                title={data.placeholder.length > 30 ? data.placeholder : null}
                id="description"
                name="description"
                className=" w-11/12 rs-input-style-textarea popup-input-bg pl-px mt-1 input-field-color textarea_content_style_Defect"
                placeholder={data.placeholder.length > 30 ? data.placeholder.substr(0, 30) + '...' : data.placeholder}
                value={data.defaultValue}
              />
            </div>
          </>
        );

      case 'radioButton':
        return (
          <>
            <div className="lg:w-72">
              <div className="grid grid-cols-2 gap-1">
                {data.options.map((option, index) => (
                  <div>
                    <input
                      type="radio"
                      className="mr-2"
                      name={`option${index}`}
                      id={`option${index}`}
                      value={option}
                      disabled={true}
                    />
                    <label className="details-data-style-common">{getOptionLabel(option)}</label>
                  </div>
                ))}
              </div>
            </div>
          </>
        );

      case 'link':
        return (
          <>
            <div className=" flex mt-3">
              <div>
                <InsertLinkOutlinedIcon className="-mt-2 mr-2 origin-center rotate-90 opacity-60" />
              </div>
              <div aria-disabled={true} className="details-data-style-common opacity-50 lg:w-64">
                {getLabel('Attached Link will be shown here if any URL is added')}
              </div>
            </div>
          </>
        );

      case 'dropdown':
        return (
          <>
            <FormControl variant="standard">
              <Select
                variant="standard"
                disabled={['State', 'Module', 'Labels', 'Assign to'].includes(data?.label) ? true : false}
                displayEmpty
                disableUnderline
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                renderValue={(selected) =>
                  !selected ? (
                    <span className="fontPoppinsRegularSm">{data?.placeholder}</span>
                  ) : (
                    <span className="fontPoppinsRegularMd">{selected || ''}</span>
                  )
                }
                name="selectCustomField"
                id="selectCustomField"
                className="lg:w-72 lg:h-8 sm:w-40"
              >
                {data?.options?.map((option, index) => (
                  <Tooltip title={option}>
                    <option
                      className="hover:text-blue-700 cursor-pointer hover:bg-blue-100 text-xs lg:w-72 sm:w-40 fontPoppinsRegularMd"
                      value={option}
                      disabled={true}
                    >
                      {option.length > 16 ? option.substr(0, 16) + '...' : option}
                    </option>
                  </Tooltip>
                ))}
              </Select>
            </FormControl>
          </>
        );

      case 'date':
        return (
          <div className="datePickerAlignment">
            <div className="flex">
              <div className=" h-8 border-2 border-gray-300 w-72"></div>
              <div className=" bg-gray-200">
                <CalendarTodayOutlinedIcon className="mt-1 " />
              </div>
            </div>
            {TemplateData?.templateType === 'Predefined' ? (
              <div className="text-xs opacity-50 details-data-style-common">[dd-mm-yyyy]</div>
            ) : (
              <div className="text-xs opacity-50 details-data-style-common">[{data.dateFormat}]</div>
            )}
          </div>
        );
      case 'checkbox':
        return (
          <>
            <div className="lg:w-72">
              <div className="grid grid-cols-2 gap-1">
                {data?.options?.map((option, index) => (
                  <div>
                    <input
                      type="checkbox"
                      className="mr-2"
                      name={`option${index}`}
                      id={`option${index}`}
                      value={option}
                      disabled={true}
                    />
                    <label className="details-data-style-common">{getOptionLabel(option)}</label>
                  </div>
                ))}
              </div>
            </div>
          </>
        );

      case 'attachment':
        return (
          <>
            <TextField
              InputProps={{ disableUnderline: true }}
              placeholder="Search and select file name"
              disabled={true}
              className="lg:w-72"
            />
          </>
        );

      case 'textEditor':
        return (
          <div>
            <RichTextEditor convertedContent="" readOnly={true} toolbarHidden={false} className="w-full" />
          </div>
        );

      default:
        return (
          <>
            <TextField disabled={true} className="w-80" />
          </>
        );
    }
  };
  const testCaseDetailElements = (props) => {
    let editFields =
      ![
        'ID',
        'Module',
        'Labels',
        'Summary',
        'Description',
        'Severity',
        'Priority',
        'State',
        'Created By',
        'Created On',
        'Modified By',
        'Modified On',
        'Assign to',
        'Comments',
      ].includes(props.data.label) && dmode !== 'view';
    return (
      <>
        <div className="flex flex-col justify-content-start">
          <div className="">
            <h6 className="fontPoppinsMediumMd">
              {props.data.mandatory === 'yes' ? <span className="text-red-400 mr-1">&#42;</span> : null}
              {getLabel(props.data.label)}
            </h6>
          </div>
          <div className="flex ">
            <div
              className={
                props.data.type === 'textArea'
                  ? 'editContentDataStyle_TextArea_Date'
                  : props.data.type === 'textEditor'
                    ? 'editorContentDataStyle'
                    : 'contentDataStyle'
              }
            >
              {testCaseDetailDescElements(props.data)}
            </div>
            <div
              className={
                props.data.type !== 'textEditor' || props.data.type !== 'textArea'
                  ? props.data.type === 'date'
                    ? 'editDate'
                    : ' flex ml-5 '
                  : ' flex mt-5'
              }
            >
              {editFields ? (
                <>
                  {
                    <>
                      <button
                        type="button"
                        onClick={(e) => {
                          onEditFieldHandler(e, props.data);
                        }}
                      >
                        <Tooltip title="Edit Field" placement="top">
                          <EditOutlinedIcon className="iconStyle cursor-pointer mt-3 hover:text-blue-700" />
                        </Tooltip>
                      </button>

                      <button type="button" className="ml-3">
                        <Tooltip title="Remove Field" placement="top">
                          <CloseIcon
                            className="iconStyle cursor-pointer mt-3 hover:text-red-700"
                            onClick={() => removeTestDetailsData(props?.data)}
                          />
                        </Tooltip>
                      </button>
                    </>
                  }
                </>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  };

  const testCaseDetailSection = () => {
    return (
      <>
        <div className=" mt-3 mx-3 my-5 border">
          <div className="w-90v responsiveTestDetails">
            <Accordion defaultExpanded={true} className="">
              <div className="h-auto backgroundColor">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="cursor-pointer" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="flex-auto project_label_TCM">Defect Details</div>
                </AccordionSummary>
              </div>
              <AccordionDetails className=" h-auto backgroundColor">
                <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-7">
                  {TemplateData.defectDetails.map((data) =>
                    data?.type === 'textArea' || data?.type === 'textEditor' ? (
                      <Grid item xs={12} sm={12} md={12}>
                        {testCaseDetailElements({ data })}
                      </Grid>
                    ) : (
                      <Grid item xs={6}>
                        {testCaseDetailElements({ data })}
                      </Grid>
                    )
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </>
    );
  };

  const templateNameValidation = () => {
    if (!TemplateData.name.length) {
      setShowErrorMsg('This field is required');
      return true;
    }
    if (TemplateData.name.length > 50 || TemplateData.name.length < 2) {
      setShowErrorMsg('Name must be in between 2 to 50 characters');
      return true;
    }
    if (TemplateData.name.length !== TemplateData.name.trim().length) {
      setShowErrorMsg('Name must not contain space at the beginning or at the end');
      return true;
    }
    if (!checkValidationForAlphanumeric.test(TemplateData.name)) {
      setShowErrorMsg('Name must not contain special characters');
      return true;
    }
    return false;
  };

  const createTemplate = (e) => {
    let tempTemplateData = commentToLast(TemplateData);
    if (templateNameValidation()) {
      setCreateCalled(false);
      return;
    }
    createDefectTemplateRequest(tempTemplateData)
      .then((results) => {
        const tempName = results.data?.responseObject?.name;
        if (results.data && results.data.responseObject && results.data.responseCode === 200) {
          history.push(`/configuration/template/defecttemplate/defectdetails?actiont=create&tempName=${tempName}`);
          setCreateCalled(false);
        } else if (
          results.data &&
          results.data.responseCode === 400 &&
          results.data.message.includes('already exists')
        ) {
          setCreateCalled(false);
          MyAlert.warning(`${results.data.message}`);
        } else if (
          results.data &&
          results.data.responseCode === 400 &&
          results.data.message.includes('Request Validation Failed')
        ) {
          setCreateCalled(false);
          MyAlert.warning(`${results.data.details}`);
        } else {
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error('Create Template Error', error.response.message);
        }
      });
  };

  const updateTemplate = (e) => {
    const tempTemplateData = commentToLast(TemplateData);
    if (templateNameValidation()) {
      setCreateCalled(false);
      return;
    }
    if (JSON.stringify(editData.data) === JSON.stringify(tempTemplateData)) {
      MyAlert.info(`No Changes were done to the ${tempTemplateData.name} template`);
      setCreateCalled(false);
      return;
    }
    updateDefectTemplateRequest(templateId, tempTemplateData)
      .then((results) => {
        const tempName = results.data?.responseObject?.name;
        if (results.data && results.data.responseObject && results.data.responseCode === 200) {
          history.push(`/configuration/template/defecttemplate/defectdetails?actiont=edit&tempName=${tempName}`);
          setCreateCalled(false);
        } else if (
          results.data &&
          results.data.responseCode === 400 &&
          results.data.message.includes('Resource with name')
        ) {
          setCreateCalled(false);
          MyAlert.warning(results.data.message);
        } else {
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error('Update Template Error', error.response.message);
        }
      });
  };

  const onAddFieldHandler = () => {
    setOpenAccountModal(true);
  };

  const onEditFieldHandler = (e, data) => {
    setEditFieldValue(data);
    setOpenAccountModal(true);
  };

  const setselectedCFvalue = (value) => {
    setSelectedCF(value);
  };

  const commentToLast = (tempData) => {
    if (tempData.defectDetails[tempData.defectDetails.length - 1].label !== 'Comments') {
      let lastOrder = tempData.defectDetails[tempData.defectDetails.length - 1].order;
      let objIndex = tempData.defectDetails.findIndex((obj) => obj.label === 'Comments');
      tempData.defectDetails[objIndex].order = (Number(lastOrder) + 1).toString();
      return tempData;
    } else {
      return tempData;
    }
  };

  const addFormField = (value) => {
    let tempTemplateData = commentToLast(TemplateData);
    if (Object.keys(editFieldValue).length !== 0) {
      const index = tempTemplateData.defectDetails.findIndex((x) => x.order === editFieldValue.order);
      const indexD = tempTemplateData.defectDetails.findIndex(
        (x) => x.label.toLowerCase() === value.label.toLowerCase()
      );
      if (indexD === -1 || indexD === index) {
        value.order = editFieldValue?.order;
        tempTemplateData.defectDetails[index] = value;
        setTemplateData(tempTemplateData);
        MyAlert.success(
          `${value.label.length > 30 ? value.label.substring(0, 32) + '...' : value.label} field updated successfully`
        );
        setEditFieldValue({});
      } else {
        MyAlert.warning(`Form field with ${value.label} label already exists`);
      }
    } else {
      value.order = tempTemplateData.defectDetails.length + 1 + '';
      const index = tempTemplateData.defectDetails.findIndex(
        (x) => x.label.toLowerCase() === value.label.toLowerCase()
      );
      if (index === -1) {
        tempTemplateData.defectDetails.push(value);
        MyAlert.success(
          `${value.label.length > 30 ? value.label.substring(0, 32) + '...' : value.label} field added successfully`
        );
      } else {
        MyAlert.warning(
          `Form field with ${
            value.label.length > 30 ? value.label.substring(0, 32) + '...' : value.label
          } label already exists`
        );
      }
      setTemplateData(tempTemplateData);
    }
  };

  const isEditable = React.useMemo(() => window.permission?.isEditAccess('configuration'), []);

  return (
    <div className="page-list-body">
      <div className="page-table-body mt-4 w-full" id="journal-scroll">
        <>
          <div className="content_area_style_TCM" id="journal-scroll">
            <div className="content_area_style_tcTemplate">
              <div className="alert-position-style">
                <AlertContatiner></AlertContatiner>
              </div>
              <div>
                <div className="border_style flex  items-center border-b-2">
                  {dmode === 'create' ? (
                    <label className="project_label flex-auto ">Create Defect Details Template</label>
                  ) : dmode === 'edit' ? (
                    <label className="project_label flex-auto ">Edit Defect Details Template - {templateName}</label>
                  ) : (
                    <label className="project_label flex-auto ">Defect Template Details- {templateName}</label>
                  )}

                  <div className="pr-4">
                    <div className="flex flex-row ml-32">
                      {dmode === 'view' ? (
                        <>
                          <button
                            className="secondary-btn"
                            onClick={() => {
                              history.push(`/configuration/template/defecttemplate/defectdetails`);
                            }}
                          >
                            Back
                          </button>
                          {isEditable && (
                            <button
                              className="primary-btn ml-5"
                              onClick={() => {
                                history.push(
                                  `/configuration/template/defecttemplate/defectdetails/defecttemplatecreation?dmode=edit`,
                                  JSON.stringify({ data: TemplateData })
                                );
                              }}
                              disabled={
                                TemplateData?.templateType === 'Predefined' ||
                                (!TemplateData.hasOwnProperty('projectId') && projectId)
                              }
                            >
                              Edit
                            </button>
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                  <br />
                  <br />
                  <hr className="dividerLine" />
                </div>
                <div className="flex justify-between mt-4 px-4">
                  <div className="rs-user-input-container">
                    <TextField
                      error={formikDetails.errors.templateName && formikDetails.touched.templateName}
                      name="templateName"
                      id="templateName"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      autoComplete="off"
                      onBlur={addNameField}
                      onInput={addNameField}
                      disabled={dmode === 'view' ? true : false}
                      value={templateName}
                      className=" w-60"
                      type="text"
                      label={
                        <>
                          <span className="text-red-400 mr-1">&#42;</span> Template Name
                        </>
                      }
                    />
                    {showErrorMsg && <div className="fontPoppinsRegularSm errorMessage absolute">{showErrorMsg}</div>}
                  </div>
                  <div className=" float-right">
                    {dmode !== 'view' ? (
                      <button className="primary-btn ml-4" onClick={onAddFieldHandler}>
                        +Fields
                      </button>
                    ) : null}
                  </div>
                </div>
                <hr className=" ml-3 my-4 w-90v" />
              </div>
              <div className="tab-height-defect content-height">{testCaseDetailSection()}</div>

              {dmode !== 'view' && (
                <div className="border_style flex justify-end  items-center border-t-2">
                  <div className="flex flex-row mr-2 my-3">
                    {dmode !== 'view' && dmode === 'edit' ? (
                      <button
                        className="secondary-btn mr-4"
                        onClick={() => {
                          history.push(`/configuration/template/defecttemplate/defectdetails`);
                        }}
                      >
                        Cancel
                      </button>
                    ) : dmode !== 'view' && dmode === 'create' ? (
                      <button className="secondary-btn mr-4" onClick={() => setOpenDataLossModal(true)}>
                        Cancel
                      </button>
                    ) : null}

                    {dmode === 'create' ? (
                      <button
                        className="primary-btn ml-4"
                        onClick={() => {
                          if (!createCalled) {
                            setCreateCalled(true);
                            createTemplate();
                          }
                        }}
                      >
                        Create
                      </button>
                    ) : dmode === 'edit' ? (
                      <button
                        className="primary-btn ml-4"
                        onClick={() => {
                          if (!createCalled) {
                            setCreateCalled(true);
                            updateTemplate();
                          }
                        }}
                      >
                        Update
                      </button>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          </div>

          {openAccountModal && (
            <FieldForm
              closeModal={closeModal}
              selectedCF={setselectedCFvalue}
              addFormField={addFormField}
              editFieldValue={editFieldValue}
              data={TemplateData}
              source="defect"
            />
          )}

          {openDataLossModal && (
            <CancelCreateTemp
              openCancelCreateTemp
              handleCloseForCancelCreateTemp={() => {
                setOpenDataLossModal(false);
              }}
            />
          )}
        </>
      </div>
    </div>
  );
}

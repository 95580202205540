import React, { useEffect, useRef, useState } from 'react';
import RadioButton from '../../../../common/radio_button';
import SingleMachineLandingPage from './machine-child-pages/single-machine-landing-page';
import MultipleMachineLandingPage from './machine-child-pages/multiple-machine-landing-page';

const MachineLandingPage = (props) => {
  const [disableHeadlessSwitch, setdisableHeadlessSwitch] = useState(true);
  const disableHeadlessSwitchRef = useRef();
  disableHeadlessSwitchRef.current = disableHeadlessSwitch;
  let selectedProject = JSON.parse(localStorage.getItem('selected-project'));
  let machineData = ['Sequential Run', 'Parallel Run', 'Distributed Run'];
  const [machineType, setMachineType] = useState('');

  useEffect(() => {
    if (props.moduleArray && props.moduleArray.length) {
      setdisableHeadlessSwitch(
        enableDisableHeadlessSwitch(props.moduleArray, selectedProject, props?.distributionMechanism)
      );
    }
  }, []);

  function storeSingleMachineData(jsonObj) {
    props?.singleMachineAllData(jsonObj);
  }
  function storeMultipleMachineData(jsonObj) {
    props?.multipleMachineAllData(jsonObj);
  }

  useEffect(() => {
    const machineTypeResult =
      props?.multiple === 'true'
        ? props?.distributionMechanism === 'MULTIPLE'
          ? machineData[1]
          : machineData[2]
        : props?.multiple === 'false'
          ? machineData[0]
          : null;
    setMachineType(machineTypeResult);
  }, [props?.multiple, props?.distributionMechanism]);

  function setMachineFun(radioVal) {
    setMachineType(radioVal);
    if (radioVal === machineData[0]) {
      let macObj = {
        multiple: 'false',
      };
      props.setMultiple('false');
      props?.getMachineDetails(macObj);
      props?.changeRadioFunction('single');
    } else if (radioVal === machineData[1]) {
      let macObj = {
        multiple: 'true',
      };
      props.setMultiple('true');
      props?.getMachineDetails(macObj);
      props.setDistributionMechanism('MULTIPLE');
      props?.changeRadioFunction('multiple');
    } else if (radioVal === machineData[2]) {
      let macObj = {
        multiple: 'true',
      };
      props.setMultiple('true');
      props.getMachineDetails(macObj);
      props?.changeRadioFunction('AUTOMATIC');
      props.setDistributionMechanism('AUTOMATIC');
    }
  }

  return (
    <div className="device-section">
      <div className="left-border-style">
        <RadioButton
          rerunMode={props?.rerunMode}
          radioList={machineData}
          editData={machineType}
          radioName="emailData"
          func={setMachineFun}
          className="fontPoppinsRegularSm"
        />
      </div>
      <div className="mt-4  mb-5">
        {machineType === machineData[0] ? (
          <SingleMachineLandingPage
            storeSingleMachineData={storeSingleMachineData}
            singleJsonData={props?.singleJsonData}
            disableHeadless={disableHeadlessSwitchRef.current}
            operationForRunSettings={props?.operationForRunSettings}
            moduleArray={props?.moduleArray}
            rootData={props?.rootData}
            rerunMode={props?.rerunMode}
          />
        ) : (
          (machineType === machineData[1] || machineType === machineData[2]) && (
            <MultipleMachineLandingPage
              distributionMechanism={props?.distributionMechanism}
              storeMultipleMachineData={storeMultipleMachineData}
              setDistributionMechanism={props?.setDistributionMechanism}
              multipleJsonData={props?.multipleJsonData}
              rerunMode={props?.rerunMode}
              operationForRunSettings={props?.operationForRunSettings}
              disableHeadless={disableHeadlessSwitchRef.current}
              automaticDistributionAllData={props?.automaticDistributionAllData}
              automaticJsonData={props?.automaticJsonData}
              moduleArray={props?.moduleArray}
              rootData={props?.rootData}
              changeRadioFunction={props?.changeRadioFunction}
              manualDistributionAllData={props?.manualDistributionAllData}
              manualJsonData={props?.manualJsonData}
              manualDistribution={props?.manualDistribution}
              numberOfParallelRuns={props?.numberOfParallelRuns}
              createAlertFun={props.createAlertFun}
            />
          )
        )}
      </div>
    </div>
  );
};

export default MachineLandingPage;

export const enableDisableHeadlessSwitch = (moduleArray, selectedProject) => {
  if (selectedProject.type.toLowerCase() === 'web') {
    return false;
  }
  if (['salesforce', 'web & mobile', 'ms dynamics'].includes(selectedProject?.type?.toLowerCase())) {
    if (moduleArray && ifValidToggleCondition(moduleArray)) {
      return false;
    }
  }
  return true;
};

export const ifValidToggleCondition = (selectedScriptsAndModules) => {
  for (const _node of selectedScriptsAndModules) {
    if (_node.type === 'Script') {
      if (
        ['web', 'web & mobile', 'web & mobile (android & ios)', 'web & mobile (android or ios)'].includes(
          _node.scriptType.toLowerCase()
        )
      ) {
        return true;
      }
    } else if (_node.children) {
      if (ifValidToggleCondition(_node.children)) {
        return true;
      }
    }
  }
  return false;
};

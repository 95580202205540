import React, { useEffect, useState, useRef, Fragment, useContext } from 'react';
import { useHistory, withRouter } from 'react-router';
import { Tooltip, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { FiInfo } from 'react-icons/fi';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {
  AddOutlined,
  DragIndicatorOutlined,
  HighlightOffRounded,
  PlayCircleOutlineOutlined,
  Sync,
} from '@material-ui/icons';
import { Popover, Menu, Transition } from '@headlessui/react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import BugIcon from '@pages/configuration/DefectConfig/BugIcon';
import AlertPopUps from '@src/pages/common/alert_pop_ups/AlertPopUps';
import {
  ALERT_MODAL_CONSTANTS,
  NLP_CONDITIONAL_STEPS,
  NLP_SUGGESTION_CONDITIONS,
  REPO,
  NAVIGATING_TO_NLP_HELP,
} from '@src/common/ui-constants';
import { Icon } from 'fireflink-ui';
// Internal imports
import './steps.scss';
import {
  getNLPReq,
  getScriptReq,
  updateScriptReq,
  getStepGroupReq,
  updateStepGroupReq,
  gellAllParameter,
  getLibraryTreeReq,
  getUserAllAvailableMachineReq,
  createExecutionReq,
  getModuleResultTreeReq,
  getBrowserStackList,
  getSauceLabsList,
  getAllLambdaTestsList,
  cloneWebServiceStepReq,
  getIndividualScriptResult,
  getStepResult,
  getExecutionData,
  getNewNlpReq,
  sendNlpDetails,
} from '@api/api_services';
import { ReactComponent as Mobile } from '@assets/smartphone_black_24dp.svg';
import { EditDependsOnScripts } from '../settings/depends-on-scripts/depends-on-scripts';
import { getFormattedTextStatus } from '@pages/results/result';
import DeleteModal from '@pages/test-development/shared/delete-modal';
import StepsNlpInputParameter from './create-edit-steps';
import QuickRunSettings from '@pages/test-development/shared/quick-run-settings';
import StepGroupModal from '@pages/repository/components/step_groups/modals/step_group_modal';
import StepModal from '@pages/results/components/modals/step_result_modal';
import CreateEditScriptConditions from '../settings/pre-post-conditions/create-edit-script-conditions';
import {
  decodeSpecialCharacter,
  getBrowserName,
  getStepId,
  getUniqueId,
} from '@pages/test-development/shared/common-methods';
import { CommonLoader } from '@pages/common/common-loader';
import { splNlpLength } from '@src/common/ui-constants';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { fontPoppins } from '@src/css_config/fontConstants';
import { SCRIPT_STEPS } from '@src/common/ui-constants';
import { getIDB, removeIDB, setIDB } from '@src/util/localForage_idb_controller';
import {
  getLicenseFeatures,
  purchasedFeatureTypeOfAutomation,
  getStringToBooleanValue,
  forLoopScript,
  dataProviderScript,
  ifCondition_ParentCheck,
  generateConditionalOptionalParam,
  addPropsForIfCondition,
} from '@src/util/common_utils';
import { v4 as uuidv4 } from 'uuid';
import { isEmptyValue } from '@util/common_utils';
import useEscapeKeyCloseModal from '@src/hooks/useEscapeKeyCloseModal';
import MemoryWarningPopup from '@src/pages/configuration/Memory_Config/MemoryWarningPopup';
import { colors } from '@src/css_config/colorConstants.js';
import { END_IF_CONDITIONS_NLP } from './specialNLPConditions';
import { ASSERT_CONSTANT } from '../webservice/restapi/request/assert/jsx/constant';
import { getPrivilege } from '@src/util/common_utils';
const { MARGIN_LEFT, NLP_CONDITION_START_ITERATION } = NLP_CONDITIONAL_STEPS;

let serialNo = 1;
const NlpSuggestion = (props) => {
  const scriptTypes = [
    'Mobile (Android & iOS)',
    'Mobile (Android or iOS)',
    'Web & Mobile (Android & iOS)',
    'Web & Mobile (Android or iOS)',
  ];
  let [nlpDropDownData, setNlpDropDownData] = useState([]);
  let [filterData, setFilterData] = useState(nlpDropDownData);
  let [nlpName, setNlpName] = useState(props.editData ? props.editData : '');
  let [nlpSelected, setNlpSelected] = useState(false);
  let [conditionNlpObject, setConditionNlpObject] = useState({});
  const endConditionObject = ['EndIfCondition', 'EndElseIfCondition'];
  let [selectedNLPData, setSelectedNLPData] = useState({});
  const [nlpDetails, setNlpDetails] = useState();
  const project = JSON.parse(localStorage.getItem('selected-project'));
  const history = useHistory();
  const updatedPath = history.location.pathname.slice(0, history.location.pathname.lastIndexOf('/'));
  const locationSearch = window.location.search;
  const moduleId = new URLSearchParams(locationSearch).get('moduleId');
  const scriptId = new URLSearchParams(locationSearch).get('scriptId');
  const libraryId = new URLSearchParams(locationSearch).get('parentId');
  const stepGroupId = new URLSearchParams(locationSearch).get('id');
  const defaultStepGroup = new URLSearchParams(locationSearch).get('defaultStepGroup');
  const stepGroupName = new URLSearchParams(locationSearch).get('stepGroup');
  let path = `${updatedPath}/webservices/restapi?moduleId=${moduleId}&scriptId=${scriptId}`;
  if (props?.type === 'Step Group') {
    path = `${updatedPath}/webservices?id=${stepGroupId}&parentId=${libraryId}&stepGroup=${stepGroupName}&defaultStepGroup=${defaultStepGroup}`;
  }
  const licenseFeatures = getLicenseFeatures();
  const hasWebService = window.privilege.hasWebService(licenseFeatures);
  const hasCBasic = window.privilege.hasCBasic(licenseFeatures);
  const privilege = getPrivilege();
  const {
    drpPos,
    type,
    getExecutionOrder,
    script,
    selectedStepIndex,
    parentIterationId,
    steps,
    conditionId,
    conditionSearchKey,
  } = props;
  const handleChange = (e) => {
    setNlpName(e.target.value);
    // search(e.target.value); //!old code
  };

  const handleClick = (data) => {
    setNlpName(data.searchName);
    props.openStepgroupInputDiv(data);
    props.handleNlpData({
      ...data,
      defaultDisplayName: data.displayName,
      defaultToolTip: data.toolTip,
    });
    props.handleConditionNlpObject(conditionNlpObject);
  };

  const onHoverSelectedNLP = (ele) => {
    setSelectedNLPData(ele);
  };

  //!old code
  // let search = (data) => {
  //   let x = [];

  //   if (data !== '') {
  //     x = filterBasedOnCriteria(data);
  //     setFilterData(x);
  //   } else {
  //     setFilterData(nlpDropDownData);
  //   }
  // };

  // NLP suggestion using AI

  //! INFO: This api is for NLP Suggestion for frequently used NLP's

  const sendNlpDetailsToAI = (_id, description, searchName) => {
    const detailsPayload = {
      user_Input: nlpName,
      _id: _id,
      searchName: searchName,
      description: description,
      ai_api: nlpDetails,
    };
    if (description) {
      sendNlpDetails(detailsPayload);
    }
  };

  //!old code
  // let filterBasedOnCriteria = (value) => {
  //   let filteredData = [],
  //     startsWithList = [],
  //     includesAtSecondIndexList = [],
  //     includesAtThirdIndexList = [],
  //     includesList = [];
  //   if (value) {
  //     value = value.trim();
  //     nlpDropDownData.forEach((action) => {
  //       if (action['searchName']) {
  //         let actionNameArray = action['searchName'].replace(': ', '');
  //         actionNameArray = actionNameArray.split(' ');
  //         if (action['searchName'].toLowerCase().startsWith(value.toLowerCase())) {
  //           startsWithList.push(action);
  //         } else if (actionNameArray[1] && actionNameArray[1].toLowerCase().includes(value.toLowerCase())) {
  //           includesAtSecondIndexList.push(action);
  //         } else if (actionNameArray[2] && actionNameArray[2].toLowerCase().includes(value.toLowerCase())) {
  //           includesAtThirdIndexList.push(action);
  //         } else if (
  //           action['searchName'].toLowerCase().includes(value.toLowerCase()) &&
  //           !action['searchName'].toLowerCase().startsWith(value.toLowerCase())
  //         ) {
  //           includesList.push(action);
  //         }
  //       }
  //     });
  //     filteredData = startsWithList
  //       .concat(includesAtSecondIndexList)
  //       .concat(includesAtThirdIndexList)
  //       .concat(includesList);
  //   }
  //   return filteredData;
  // };

  const getNlp = async () => {
    let platform = props.type === 'Step Group' ? props.stepGroup.type : props.script.scriptType;
    const payload = {
      module: 'nlpSearch',
      collectionName: 'nlps',
      searchText: nlpName,
    };
    const headers = {
      projectType: project.type,
      projectId: project.id,
      platform: platform,
    };
    let filteredNLPResult = [];

    try {
      // const response = await getNLPReq(project.type, platform);   //!old code
      const response = await getNewNlpReq(payload, headers, NLP_SUGGESTION_CONDITIONS.ALL);
      setNlpDetails(response.data.aiNlpSuggestion);
      if (response.data && response.data.responseObject) {
        response.data.responseObject.forEach((ele) => {
          if (
            (ele.nlpType === 'NLP' &&
              !['EndIteration', 'EndForLoop', ...Object.keys(END_IF_CONDITIONS_NLP)]?.includes(ele.nlpName)) ||
            (ele.nlpType === 'STEP_GROUP' && ele.parentId !== props?.stepGroup?.id) ||
            ele.nlpType === 'PROGRAM_ELEMENTS'
          ) {
            if (ele.nlpType === 'STEP_GROUP') {
              ele.searchName = ele.name;
              ele.displayName = ele.name;
              filteredNLPResult.push(ele);
            } else if (ele.nlpType === 'PROGRAM_ELEMENTS') {
              ele.searchName = ele.desc;
              ele.displayName = ele.desc;
              filteredNLPResult.push(ele);
            } else {
              if (props?.previousStepData && endConditionObject.includes(props?.previousStepData?.nlpName)) {
                filteredNLPResult.push(ele);
              } else {
                if (ele?.nlpName !== 'ElseIfCondition' && ele?.nlpName !== 'ElseCondition') {
                  filteredNLPResult.push(ele);
                }
              }
            }
          }

          // NOTE:: commented as a part of NLP suggestion API not giving ENDIF condition nlp data
          // var isEmptyObj = Object.keys(conditionNlpObject).length;

          // if (isEmptyObj < 3) {
          //   if (endIfNlpList.includes(ele?.nlpName)) {
          //     conditionNlpObject[ele?.nlpName] = ele;
          //   }
          // }
        });
        setConditionNlpObject(END_IF_CONDITIONS_NLP);

        setNlpDropDownData(filteredNLPResult);
        setFilterData(filteredNLPResult);
      }
    } catch (err) {
      console.error('GET_NLP :', err);
    }
  };
  //! old code
  // useEffect(() => {
  //   getNlp();
  // }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      getNlp();
    }, 500);
    return () => clearTimeout(timer);
  }, [nlpName]);

  function filterStepsByCriteria(steps, nlpName, specialNlpId) {
    return steps.filter((step) => step?.nlpName === nlpName && step?.specialNlpId === specialNlpId);
  }

  const onWebServiceSteps = () => {
    const selectedRowIndex = selectedStepIndex - 1 || 0;
    const pushToHistory = (stepData) => {
      history.push(path, stepData);
    };
    if (selectedRowIndex === 0 && selectedStepIndex === undefined) {
      pushToHistory();
    } else {
      const scriptSteps = script?.steps;
      const currentStep = scriptSteps[selectedRowIndex];
      const currentStepMarginLeft = currentStep?.marginLeft;
      const executionOrder = getExecutionOrder(selectedRowIndex, scriptSteps);
      if (drpPos && type !== 'Step Group') {
        if (['StartIteration', 'EndIteration'].includes(steps?.nlpName) || steps?.parentSpecialNlpId) {
          const filteredParentSteps = filterStepsByCriteria(script?.steps, 'StartIteration', steps?.parentSpecialNlpId);
          const getParentSteps = { ...filteredParentSteps[0], script: script };
          pushToHistory(getParentSteps);
        } else {
          pushToHistory();
        }
      } else if (parentIterationId) {
        if (parentIterationId.includes('itr_')) {
          const filteredParentSteps = props?.script?.steps.filter(
            (items) => items?.nlpName === 'StartIteration' && items?.specialNlpId === props?.parentIterationId
          );
          let getParentSteps = { ...filteredParentSteps[0], ...{ script: props?.script } };
          const dataProviderParentItr = dataProviderScript(script, selectedRowIndex, currentStepMarginLeft);
          let parentData = null;
          if (currentStep?.parentSpecialNlpId && currentStep?.parentSpecialNlpId.includes('forloop_')) {
            parentData = forLoopScript(script, selectedRowIndex, currentStepMarginLeft);
          }
          let conditionalOptionalParam = generateConditionalOptionalParam(
            executionOrder,
            dataProviderParentItr,
            parentIterationId,
            currentStep
          );
          if (parentData) {
            const parentConditionalStep = parentData;
            conditionalOptionalParam = generateConditionalOptionalParam(
              executionOrder,
              parentConditionalStep,
              parentIterationId,
              currentStep
            );
          }
          getParentSteps = {
            ...getParentSteps,
            marginLeft: (conditionalOptionalParam?.marginLeft ?? 0) + MARGIN_LEFT,
            executionOrder: executionOrder,
            ...conditionalOptionalParam,
            ...(dataProviderParentItr && { dataProviderVariables: dataProviderParentItr?.dataProviderVariables }),
          };
          pushToHistory(getParentSteps);
        } else {
          const dataProviderParent = dataProviderScript(script, selectedRowIndex, currentStepMarginLeft);
          const parentConditionalStep = forLoopScript(script, selectedRowIndex, currentStepMarginLeft);
          const conditionalOptionalParam = generateConditionalOptionalParam(
            executionOrder,
            parentConditionalStep,
            currentStep?.specialNlpId || currentStep?.parentSpecialNlpId,
            currentStep
          );
          const getParentSteps = {
            ...(dataProviderParent && { ...dataProviderParent, ...{ script: props?.script } }),
            ...conditionalOptionalParam,
            marginLeft: conditionalOptionalParam?.marginLeft,
            executionOrder: executionOrder,
          };
          pushToHistory(getParentSteps);
        }
      } else {
        const ifParentStep = ifCondition_ParentCheck(script, selectedRowIndex, currentStepMarginLeft);
        const filteredParentSteps = script?.steps.filter(
          (items) => items?.nlpName === 'StartIteration' && items?.specialNlpId === parentIterationId
        );
        const getParentSteps = { ...filteredParentSteps[0], script: props?.script };
        const parentStep = getParentSteps?.script?.steps[selectedRowIndex];
        let conditionalOptionalParam = generateConditionalOptionalParam(
          executionOrder,
          parentStep,
          parentIterationId,
          currentStep
        );
        if (NLP_CONDITION_START_ITERATION.some((iteration) => ifParentStep?.name.includes(iteration))) {
          addPropsForIfCondition(conditionalOptionalParam, conditionId, conditionSearchKey, currentStepMarginLeft);
        }
        if (ifParentStep?.parentSpecialNlpId) {
          if (ifParentStep?.parentSpecialNlpId.includes('itr_')) {
            const dataProviderParent = dataProviderScript(script, selectedRowIndex - 1, currentStepMarginLeft);
            conditionalOptionalParam = {
              ...conditionalOptionalParam,
              ...(dataProviderParent && { ...dataProviderParent, ...{ script: props?.script } }),
            };
          } else if (ifParentStep?.parentSpecialNlpId.includes('forloop_')) {
            let parentData = forLoopScript(script, selectedRowIndex, currentStepMarginLeft);
            const dataProviderParentItr = dataProviderScript(script, selectedRowIndex + 1, currentStepMarginLeft);
            const ifConditionalOptionalParam = generateConditionalOptionalParam(
              executionOrder,
              parentData,
              parentIterationId,
              currentStep
            );
            conditionalOptionalParam = {
              ...conditionalOptionalParam,
              ...ifConditionalOptionalParam,
              ...(dataProviderParentItr && { ...dataProviderParentItr, ...{ script: props?.script } }),
            };
          }
        }
        conditionalOptionalParam = {
          ...conditionalOptionalParam,
          executionOrder: executionOrder,
          marginLeft: NLP_CONDITION_START_ITERATION.includes(currentStep?.name)
            ? ifParentStep?.marginLeft + MARGIN_LEFT
            : currentStep?.marginLeft || 0,
        };
        pushToHistory(conditionalOptionalParam);
      }
    }
  };

  useEscapeKeyCloseModal(true, () => props.closeModal(true));
  const navigateToHelp = () => {
    window.open(process.env.REACT_APP_FIREFLINK_NLP_HELP, '_blank');
  };

  const clickOutsideRef = useClickOutSide(() => {
    props?.closeModal(true);
  });

  return (
    <div className={` relative fontPoppinsRegularMd text-blue-700`} ref={clickOutsideRef}>
      <div className={`${props.drpPos ? 'w-full' : 'w-45'} flex relative`}>
        <input
          autoFocus={true}
          autoComplete="off"
          type="search"
          id="textareaTd"
          className="w-full search-nlp fontPoppinsRegularMd text-rs-primary ml-6 mb-1 pr-8 "
          value={nlpName}
          onChange={handleChange}
          onBlur={() => {
            if (props.removeInputField && !nlpSelected && !navigateToHelp) {
              props.removeInputField(false);
              props.closeModal(true);
            }
          }}
          placeholder="Search for NLP"
        />
        {privilege?.nlpHelpEnabled && (
          <Tooltip title={NAVIGATING_TO_NLP_HELP.NLP_HELP} placement="bottom">
            <span
              onClick={navigateToHelp}
              className="absolute z-50 right-2 top-1/2 transform -translate-y-1/2 text-rs-primary flex items-center cursor-pointer"
            >
              <Icon name="help" height={14} width={14} color={colors.rs_primary} className=" cursor-pointer mb-1" />
            </span>
          </Tooltip>
        )}
      </div>
      <div
        className="flex absolute w-full"
        onMouseLeave={() => {
          onHoverSelectedNLP(null);
        }}
      >
        <div className="flex nlpdataloading absolute w-full">
          <div
            id="journal-scroll"
            className={`${
              props.drpPos ? 'w-full' : 'steps-w-45'
            }  absolute ml-6 border mt-px p-px max-h-48 shadow rounded fontPoppinsRegularMd bg-white nlp-details-show z-10 nlpdataloading`}
          >
            <div
              className="max-h-152 overflow-y-auto overflow-x-hidden"
              onMouseEnter={() => {
                setNlpSelected(true);
              }}
            >
              {filterData.map((ele, index) => {
                return (
                  <div
                    className={`${
                      ele?._id === selectedNLPData?._id ? 'bg-gray-100 cursor-pointer' : ''
                    } mt-1 mb-1 flex`}
                    onMouseOver={() => onHoverSelectedNLP(ele)}
                    onClick={() => {
                      handleClick(ele);
                      const { _id, description, searchName } = ele;
                      sendNlpDetailsToAI(_id, description, searchName);
                    }}
                  >
                    <div
                      id={'sg' + index.toString()}
                      key={index}
                      className="text-xs cursor-pointer inline-flex text-gray-500 nlp-info items-center"
                    >
                      <span className="mr-2 fontPoppinsRegularMd">
                        {ele?.nlpType === 'NLP' ? (
                          <b className="nlp-type-NLP">NLP</b>
                        ) : ele?.nlpType === 'STEP_GROUP' ? (
                          <b className="nlp-type-step-group">SG</b>
                        ) : ele?.nlpType === 'PROGRAM_ELEMENTS' ? (
                          <b className="nlp-type-program-elements">PE</b>
                        ) : (
                          ''
                        )}
                      </span>
                      {ele?.nlpType !== 'PROGRAM_ELEMENTS' &&
                      ['Web', 'iOS', 'Android', 'Generic'].includes(ele?.platform) &&
                      (scriptTypes.includes(props?.script?.scriptType) ||
                        scriptTypes.includes(props?.stepGroup?.type)) ? (
                        ele?.platform === 'Web' ? (
                          <span className="w-5">
                            <img
                              src="/assets/images/web_black_48dp.svg"
                              alt=""
                              width="20px"
                              height="20px"
                              className="inline"
                            />
                          </span>
                        ) : ele?.platform === 'iOS' ? (
                          <span className="w-5">
                            <img
                              src="/assets/images/apple_icon.svg"
                              alt=""
                              width="20px"
                              height="20px"
                              className="inline"
                            />
                          </span>
                        ) : ele?.platform === 'Generic' ? (
                          <span className="w-5">
                            <Mobile className="mr-3 h-4 w-4 text-blue-700" />
                          </span>
                        ) : (
                          <span className="w-5">
                            <img
                              src="/assets/images/android_icon.svg"
                              alt=""
                              width="20px"
                              height="20px"
                              className="inline"
                            />
                          </span>
                        )
                      ) : null}
                      {ele?.nlpType === 'NLP' ? (
                        <p className="ml-2 nlp-type-details fontPoppinsRegularMd">{ele?.searchName}</p>
                      ) : ele?.nlpType === 'PROGRAM_ELEMENTS' ? (
                        <p className="ml-2 nlp-type-details fontPoppinsRegularMd">{ele?.desc}</p>
                      ) : ele?.nlpType === 'STEP_GROUP' ? (
                        <p className="ml-2 nlp-type-details fontPoppinsRegularMd">{ele?.searchName}</p>
                      ) : (
                        <Tooltip title={ele.toolTip ? ele.toolTip : ''} placement="right-end">
                          <p className="mt-3">{ele?.searchName}</p>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            {filterData.length > 0 && (hasWebService || hasCBasic) && (
              <div
                onMouseEnter={() => {
                  setNlpSelected(true);
                }}
                className="web-service-steps-section bg-gray-100 flex items-center justify-center"
              >
                <button
                  onMouseEnter={() => {
                    setNlpSelected(true);
                  }}
                  className="fontPoppinsMediumXs web-service-steps-btn"
                  onClick={onWebServiceSteps}
                >
                  + Web Service Step
                </button>
              </div>
            )}
          </div>

          <div className={`${props.drpPos ? 'steps-left-102 w-6' : 'left-1/2 w-3'} nlp-separator nlpdataloading`}></div>
          {filterData?.length > 0 && selectedNLPData?._id && (
            <div
              className={`${
                props.drpPos ? 'w-full steps-left-102' : 'steps-w-48 left'
              } nlpdataloading relative nlp-details-hide border mt-px p-px max-h-48 overflow-y-auto overflow-x-hidden shadow rounded fontPoppinsRegularMd bg-white z-10`}
            >
              <div className="nlp-details-header mt-4">Name:</div>
              <div className="nlp-details nlpdiv-check">
                {selectedNLPData?.displayName ? selectedNLPData?.displayName : '--'}
              </div>
              <div className="nlp-details-header mt-4">Description:</div>
              <div className="nlp-details nlpdiv-check">
                {selectedNLPData?.description ? selectedNLPData?.description : '--'}
              </div>
              {selectedNLPData?.nlpType === 'STEP_GROUP' && (
                <>
                  <div className="nlp-details-header mt-4">Path:</div>
                  <div className="nlp-details nlpdiv-check">
                    {selectedNLPData?.path ? selectedNLPData?.path?.slice(6) : '--'}
                  </div>
                </>
              )}
              <div className="nlp-details-header mt-4">Inputs:</div>
              {selectedNLPData?.stepInputs?.length > 0 ? (
                selectedNLPData?.stepInputs?.map((stepInput, index) => {
                  const stepInputType = stepInput?.type ? stepInput?.type?.split('.').pop() : '--';
                  return (
                    <>
                      <div className="nlp-details-header">Input{++index}:</div>
                      <div className="nlp-details">type: {stepInputType}</div>
                      <div className="nlp-details mb-4">name: {stepInput?.name}</div>
                    </>
                  );
                })
              ) : (
                <div className="nlp-details mb-4">--</div>
              )}
              <div className="nlp-details-header">Output:</div>
              <div className="nlp-details mb-4">{selectedNLPData?.returnType ? selectedNLPData?.returnType : '--'}</div>
              <div className="w-1/6"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  select: {
    borderRadius: 8,
    ...fontPoppins.rMd,
    '&:focus': {
      borderRadius: 12,
      background: 'none',
    },
  },
  menuPaper: {
    maxHeight: 200,
    ...fontPoppins.rMd,
  },
  menulist: {
    paddingTop: 15,
    paddingBottom: 0,
    ...fontPoppins.rMd,
    background: 'none',
    '& li': {
      ...fontPoppins.rMd,
      color: colors.grey_dark,
    },
    '& li:hover': {
      background: colors.sky_blue_dark,
      color: colors.blue_dark,
    },
    '& li.Mui-selected': {
      color: colors.blue_dark,
      background: colors.sky_blue_dark,
    },
    '& li.Mui-selected:hover': {
      background: colors.sky_blue_dark,
      color: colors.blue_dark,
    },
  },
  smallRadioButton: {
    '& svg': {
      width: '0.75em',
      height: '0.75em',
    },
  },
});

const Steps = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('test-optimize-user'));
  const project = JSON.parse(localStorage.getItem('selected-project'));
  const countData = JSON.parse(localStorage.getItem('allowedProjects'))?.features?.repository?.restrictions;
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  const libraryId = new URLSearchParams(search).get('parentId');
  const stepGroupId = new URLSearchParams(search).get('id');
  const stepId = new URLSearchParams(search).get('stepId');
  const condition = new URLSearchParams(search).get('condition');
  let parsedStepData = null;
  if (stepId) {
    try {
      parsedStepData = JSON?.parse(stepId) || null;
    } catch (error) {
      console.error('Unable to parse stepId...', error);
    }
  }
  const defaultStepGroup = new URLSearchParams(search).get('defaultStepGroup');
  const stepGroupName = new URLSearchParams(search).get('stepGroup');
  const isDefaultStepGroup = getStringToBooleanValue(new URLSearchParams(search).get('defaultStepGroup'));
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [memoryWarningPopup, setMemoryWarningPopup] = useState(false);
  const [qrsData, setQRSData] = useState({});
  const [showAddStepButton, setshowAddStepButton] = useState(true);
  const [addStepAtIndex, setAddStepAtIndex] = useState();
  const [midExecutionOrder, setMidExecutionOrder] = useState();
  let [action, setAction] = useState('create');
  const { WARNING } = ALERT_MODAL_CONSTANTS;
  const [mode, setMode] = useState();
  const modeRef = useRef();
  modeRef.current = mode;
  const [selectedStepIndex, setSelectedStepIndex] = useState();
  const [disableHeadlessSwitch, setDisableHeadlessSwitch] = useState(false);
  let [openCreatePreCondition, setOpenCreatePreCondition] = useState(false);
  let [reload, setReload] = useState(true);
  let [isLoading, setIsLoading] = useState(false);
  let [data, setData] = useState([]);
  let [executionOrder, setExecutionOrder] = useState(1);
  let [noSteps, setNoSteps] = useState(data?.length > 0 ? false : true);
  let [openStepGroupInputParameterDiv, setOpenStepGroupInputParameterDiv] = useState(false);
  let [stepGroupInputParameterDivData, setStepGroupInputParameterDivData] = useState('');
  let [editPreCondition, setEditPreCondition] = useState(false);
  let [openDeleteModal, setOpenDeleteModal] = useState(false);
  let [openDeleteStepsModal, setOpenDeleteStepsModal] = useState(false);
  let [stepIndex, setStepIndex] = useState(-2);
  let [stepGroupParameterData, setStepGroupParameterData] = useState([]);
  let [filterParameterData, setfilterParameterData] = useState([]);
  let [editData, setEditData] = useState(null);
  let [stepsDataObject, setStepsDataObject] = useState('');
  let [conditionNlpObject, setConditionNlpObject] = useState({});
  let [selectedDefaultMachine, setselectedDefaultMachine] = useState();
  const [quickRunSettingsApiData, setQuickRunSettingsApiData] = useState();
  const [selectedDefaultValue, setSelectedDefaultValue] = useState();
  let [scripts, setScripts] = useState([]);
  let [previousStepData, setPreviousStepData] = useState({});
  const [scriptsFromApi, setScriptsFromApi] = useState([]);
  let [dependScriptData, setDependScriptData] = useState([]);
  let [preConditionData, setPreConditionData] = useState([]);
  let [postConditionData, setPostConditionData] = useState([]);
  let [dependScriptStats, setDependScriptStats] = useState();
  let [preConditionStats, setPreConditionStats] = useState();
  let [postConditionStats, setPostConditionStats] = useState();
  let [stepResultStats, setStepResultStats] = useState();
  let [scriptResultData, setScriptResultData] = useState();
  let [stepGroup, setStepGroup] = useState();
  let [arrayOfReturnValues, setArrayofReturnValues] = useState([]);
  let [disableDropDown, setDisableDropDown] = useState(true);
  let [selectedReturnTypeValue, setSelectedReturnTypeValue] = useState('void');
  let [openStepGroupModal, setOpenStepGroupModal] = useState(false);
  let [stepsForStepGroup, setStepsForStepGroup] = useState([]);
  let [stepsChecked, setStepsChecked] = useState([]);
  let [dependentScriptChecked, setDependentScriptChecked] = useState([]);
  let [preConditionChecked, setPreConditionChecked] = useState([]);
  let [postConditionChecked, setPostConditionChecked] = useState([]);
  let [runFlag, setRunFlag] = useState(true);
  let [refData, setRefData] = useState('');
  let [screenshotDeleted, setScreenshotDeleted] = useState(false);
  let [videoDeleted, setVideoDeleted] = useState(false);
  let element = null;
  const { STEPGROUP } = ASSERT_CONSTANT;

  const removeHighlighter = () => {
    let elements = document.getElementsByClassName('highlight-text');
    if (elements.length) {
      elements[0]?.classList?.remove('highlight-text');
    }
  };

  const scrollToText = () => {
    element = document.getElementById(parsedStepData);
    if (element?.id == parsedStepData) {
      if (condition == 'post') {
        expandCollapse('postcondition', 'rightarrow');
      }
      if (condition == 'pre') {
        expandCollapse('precondition', 'rightarrow');
      }
      element?.classList.add('highlight-text');
      setTimeout(() => element?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 1000);
      let queryStringAfterSlice = history?.location?.search
        ?.split('&')
        ?.filter((query) => !query?.includes('stepId'))
        ?.join('&');
      history.replace(`${history.location.pathname + queryStringAfterSlice}`);
    }
  };

  useEffect(() => {
    if (parsedStepData) {
      setTimeout(() => scrollToText(), 1000);
    }
  }, [parsedStepData]);
  const [conditionId, setConditionId] = useState();
  const [conditionSearchKey, setConditionSearchKey] = useState();
  const [dataProviderVariablesArray, setdataProviderVariablesArray] = useState();

  let runFlagRef = useRef();
  runFlagRef.current = runFlag;
  // states for select and run
  const [stepsSelectedForRun, setStepsSelectedForRun] = useState([]);
  const [dependsOnScriptsSelectedForRun, setDependsOnScriptsSelectedForRun] = useState([]);
  const [preConditionsSelectedForRun, setPreConditionsSelectedForRun] = useState([]);
  const [postConditionsSelectedForRun, setPostConditionsSelectedForRun] = useState([]);
  // end of states for select and run

  // start of states for pre-post conditions and depends-on scripts updates
  const [openPreConditionStepGroupInputParameterDiv, setOpenPreConditionStepGroupInputParameterDiv] = useState(false);
  const [preConditionDataForUpdate, setPreConditionDataForUpdate] = useState({});
  const [openPostConditionStepGroupInputParameterDiv, setOpenPostConditionStepGroupInputParameterDiv] = useState(false);
  const [postConditionDataForUpdate, setPostConditionDataForUpdate] = useState({});
  const [openDependsOnScriptsDiv, setOpenDependsOnScriptsDiv] = useState(false);
  const [dependsOnScriptsDataForUpdate, setDependsOnScriptsDataForUpdate] = useState({});
  let [openStepModal, setOpenStepModal] = useState(false);
  let [stepResultData, setStepResultData] = useState({});
  let [browserInstanceArray, setBrowserInstanceArray] = useState([]);
  let [sauceLabInstanceArray, setSauceLabInstanceArray] = useState([]);
  const [lambdaTestsInstanceArray, setLambdaTestsInstanceArray] = useState([]);

  const [showStepResultHeader, setShowStepResultHeader] = useState(false);
  const [showPreConditionResultHeader, setShowPreConditionResultHeader] = useState(false);
  const [showPostConditionResultHeader, setShowPostConditionResultHeader] = useState(false);
  const [showDependsOnScriptsResultHeader, setShowDependsOnScriptsResultHeader] = useState(false);
  const [showAbortedStatusHeader, setShowAbortedStatusHeader] = useState(true);
  const licenseFeatures = getLicenseFeatures();
  const hasAutomation = window.privilege.hasAutomation(licenseFeatures);
  const hasAutomationBasedOnProjType = purchasedFeatureTypeOfAutomation(project.type);
  //to restrict delete multiple steps on repeatedly click of delete button
  const [allowDeleteFlag, setAllowDeleteFlag] = useState(true);
  const [showOptions, setShowOptions] = useState(false);

  const [selectedSystem, setSelectedSysyem] = useState();
  let [scriptRowIndex, setScriptRowIndex] = useState(1);
  const [individualScriptResult, setIndividualScriptResult] = useState({});
  const [disableStepButtonOnload, setDisableStepButtonOnload] = useState(false);
  let [isEditStep, setEditStep] = useState(false);
  let [stepID, setStepID] = useState('');
  let [stepUpdatedData, setUpdatedStepData] = useState([]);
  const [enableSyncButton, setEnableSyncButton] = useState(false);
  let [stepClicked, setStepClicked] = useState(0);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheckAllDependsOnScripts, setIsCheckAllDependsOnScripts] = useState(false);
  const [isCheckAllPreCondition, setIsCheckAllPreCondition] = useState(false);
  const [isCheckAllSteps, setIsCheckAllSteps] = useState(false);
  const [isCheckAllPostCondition, setIsCheckAllPostCondition] = useState(false);
  let [dropdownTreeData, setDropdownTreeData] = useState([]);
  let [parentSpecialNlpId, setParentSpecialNlpId] = useState();
  let [isSpecialNlp, setIsSpecialNlp] = useState(false);
  let [conditionSet, setConditionSet] = useState('');
  let [conditionSetId, setConditionSetId] = useState('');
  let [parentStartDataproviderStep, setParentStartDataproviderStep] = useState();
  let [parentStartIfStep, setParentStartIfStep] = useState();
  const [stepBoxCheck, setStepBoxCheck] = useState();
  let [endConditionObject, setEndConditionObject] = useState([
    'EndIfCondition',
    'EndElseIfCondition',
    'EndElseCondition',
  ]);
  let [startConditionObject, setStartConditionObject] = useState(['IfCondition', 'ElseIfCondition', 'ElseCondition']);
  let [checkedIndices, setCheckedIndices] = useState([]);
  const [pageIndexValue, setPageIndex] = React.useState(0);
  const [pageIndex, setPage] = React.useState(0);
  const [margin, setMargin] = React.useState(0);
  let nonCloneSteps = [
    'NLP1554',
    'NLP1555',
    'NLP1556',
    'NLP1557',
    'NLP1558',
    'NLP1559',
    'Start If Condition',
    'End If Condition',
    'Start Else If Condition',
    'End Else If Condition',
    'Start Else Condition',
    'End Else Condition',
  ];
  let excludedNlpTypes = ['_startiteration', '_enditeration', '_startforloop', '_endforloop'];
  let excludedNlpNames = [
    'IfCondition',
    'EndIfCondition',
    'ElseIfCondition',
    'EndElseIfCondition',
    'ElseCondition',
    'EndElseCondition',
  ];
  let startConditionNlpNames = ['IfCondition', 'ElseIfCondition', 'ElseCondition'];
  let endConditionNlpNames = ['EndIfCondition', 'EndElseIfCondition', 'EndElseCondition'];
  const startNlpList = ['_startiteration', '_startforloop'];
  const endNlpList = ['_enditeration', '_endforloop'];
  const [enableStepGroupButton, setEnableStepGroupButton] = useState(false);
  const [showPrePostConditionTableData, setShowPrePostConditionTableData] = useState({
    showPreConditionTableData: false,
    showPostConditionTableData: false,
  });
  const [disableAllSteps, setDisableAllSteps] = useState(false);

  let scriptName = decodeSpecialCharacter(props?.match?.params?.scriptName);
  if (scriptName.includes('_')) {
    let index = scriptName.lastIndexOf('_');
    if (index != scriptName.length - 1) {
      scriptName = scriptName.substring(0, index);
    }
  }

  useEffect(() => {
    if (openStepGroupInputParameterDiv) {
      closeModal(false);
    }
    getStepGroupData();
  }, [stepGroupId]);

  // if click outside of dropdown also it will close
  let menuRef = useRef();
  useEffect(() => {
    let handeler = (event) => {
      if (menuRef.current && !menuRef?.current?.contains(event.target)) {
        setShowOptions(false);
      }
    };
    document.addEventListener('mousedown', handeler);
    return () => {
      document.removeEventListener('mouseleave', handeler);
    };
  }, []);

  let sl_no;

  const serialNoRendrer = (sln, row, index) => {
    if (!sl_no) {
      sl_no = 0;
    }
    return ++sl_no;
  };
  useEffect(() => {
    let disabledSteps = data.filter((item) => item.isDisabled === true);
    if (disabledSteps.length === data?.length) {
      setDisableAllSteps(true);
    } else {
      setDisableAllSteps(false);
    }
  }, [data]);
  const closePreConditionsModal = () => {
    myCssMethodForConditions(-1, 'pre-condition');
    setOpenPreConditionStepGroupInputParameterDiv(false);
    setPreConditionDataForUpdate({});
  };

  const closePostConditionsModal = () => {
    myCssMethodForConditions(-1, 'post-condition');
    setOpenPostConditionStepGroupInputParameterDiv(false);
    setPostConditionDataForUpdate({});
  };

  const closeDependsOnScriptModal = () => {
    myCssMethodForConditions(-1, 'depends-on-script');
    setOpenDependsOnScriptsDiv(false);
    setDependsOnScriptsDataForUpdate({});
  };

  const updateDependsOnScripts = (scriptData, scriptIndex) => {
    if (isEditable) {
      if (openPostConditionStepGroupInputParameterDiv) {
        closePostConditionsModal();
      }
      if (openPreConditionStepGroupInputParameterDiv) {
        closePreConditionsModal();
      }
      if (openStepGroupInputParameterDiv) {
        closeModal(true);
      }
      myCssMethodForConditions(scriptIndex, 'depends-on-script');
      setDependsOnScriptsDataForUpdate(scriptData);
      setOpenDependsOnScriptsDiv(true);
    }
  };

  const updatePreCondition = (conditionData, conditionIndex) => {
    if (isEditable) {
      if (openPostConditionStepGroupInputParameterDiv) {
        closePostConditionsModal();
      }
      if (openDependsOnScriptsDiv) {
        closeDependsOnScriptModal();
      }
      if (openStepGroupInputParameterDiv) {
        closeModal(true);
      }
      myCssMethodForConditions(conditionIndex, 'pre-condition');
      setPreConditionDataForUpdate(conditionData);
      setOpenPreConditionStepGroupInputParameterDiv(true);
    }
  };

  const updatePostCondition = (conditionData, conditionIndex) => {
    if (isEditable) {
      if (openPreConditionStepGroupInputParameterDiv) {
        closePreConditionsModal();
      }
      if (openDependsOnScriptsDiv) {
        closeDependsOnScriptModal();
      }
      if (openStepGroupInputParameterDiv) {
        closeModal(true);
      }
      myCssMethodForConditions(conditionIndex, 'post-condition');
      setPostConditionDataForUpdate(conditionData);
      setOpenPostConditionStepGroupInputParameterDiv(true);
    }
  };

  const myCssMethodForConditions = (index, type) => {
    let className = '';
    if (type === 'pre-condition') {
      className = 'pre-condition-row';
    } else if (type === 'post-condition') {
      className = 'post-condition-row';
    } else {
      className = 'dependent-script-row';
    }
    const myClass = document.getElementsByClassName(className);
    for (let i = 0; i < myClass.length; i++) {
      if (index >= 0) {
        myClass[i].classList.remove('configHover');
      } else {
        myClass[i].classList.add('configHover');
      }
      if (i === index) {
        myClass[i].classList.add('shadow-md', 'bg-white');
        myClass[i].style.boxShadow = '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)';
      } else {
        myClass[i].classList.remove('shadow-md', 'bg-white');
        myClass[i].style.boxShadow = 'none';
      }
    }
  };
  // end of states for pre-post conditions and depends-on scripts updates

  const openStepGroupDiv = (data, index) => {
    setshowAddStepButton(false);
    setOpenStepGroupInputParameterDiv(true);
    setStepGroupInputParameterDivData(JSON.parse(JSON.stringify(data)));
    if ('replace' !== mode && typeof index !== 'undefined') {
      nlpSuggestionBoxRenderer(index);
      setEditData(data);
      setStepsDataObject(data);
    }
  };

  function handleOnDragEnd(result) {
    removeHighlighter();
    let returnstepId;
    if (!result.destination) return;

    if (props.type === 'Step Group' && defaultStepGroup === 'true') return;

    const items = data;
    let execOrder;
    if (items[result.destination.index + 1] === undefined) {
      execOrder = items[result?.destination?.index]?.executionOrder + 1;
    } else if (items[result.destination.index - 1] === undefined) {
      execOrder = items[result?.destination?.index]?.executionOrder / 2;
    } else {
      if (result.destination.index > result.source.index) {
        execOrder =
          (items[result?.destination?.index]?.executionOrder + items[result?.destination?.index + 1]?.executionOrder) /
          2;
      } else {
        execOrder =
          (items[result?.destination?.index]?.executionOrder + items[result?.destination?.index - 1]?.executionOrder) /
          2;
      }
    }
    let returnTypeStepNo;
    if (selectedReturnTypeValue !== 'void' && props.type === 'Step Group') {
      let retrurnType = selectedReturnTypeValue?.split('Step');
      returnTypeStepNo = Math.floor(retrurnType?.[retrurnType?.length - 1]);
      returnstepId = items?.[returnTypeStepNo - 1]?.stepId;
    }
    const reorderedItem = items[result.source.index];
    const tempItem =
      result.destination.index > result.source.index
        ? items[result.destination.index]
        : items[result.destination.index - 1];

    // To handle leftMargin of steps after drag and drop
    const nlpName = tempItem?.nlpName;
    const destinationIndex = result?.destination?.index;
    const nextItem = items[destinationIndex + 1];
    const oldleftMargin = reorderedItem?.marginLeft;
    const tempItemLeftMargin = tempItem?.marginLeft;
    let newLeftMargin = reorderedItem.marginLeft;
    if (destinationIndex === 0 || nextItem === undefined) {
      newLeftMargin = 0;
    } else if (['StartForLoop', 'StartIteration', ...startConditionNlpNames].includes(nlpName)) {
      newLeftMargin = tempItemLeftMargin + MARGIN_LEFT;
    } else if (['EndForLoop', 'EndIteration', ...endConditionNlpNames].includes(nlpName)) {
      newLeftMargin = tempItemLeftMargin;
    } else {
      newLeftMargin = tempItemLeftMargin;
    }
    reorderedItem.marginLeft = newLeftMargin;

    if (tempItem?.isIterationStep || ['StartIteration'].includes(tempItem?.nlpName)) {
      reorderedItem['isIterationStep'] = true;
      reorderedItem['parentSpecialNlpId'] = tempItem?.isIterationStep
        ? tempItem?.parentSpecialNlpId
        : tempItem.specialNlpId;
    } else if (tempItem?.isForLoopStep || ['StartForLoop'].includes(tempItem?.nlpName)) {
      reorderedItem['isForLoopStep'] = true;
      reorderedItem['parentSpecialNlpId'] = tempItem?.isForLoopStep
        ? tempItem?.parentSpecialNlpId
        : tempItem.specialNlpId;
    } else if (tempItem?.parentSpecialNlpId) {
      reorderedItem['parentSpecialNlpId'] = tempItem?.parentSpecialNlpId;
    } else {
      if (reorderedItem && reorderedItem.hasOwnProperty('isIterationStep')) {
        delete reorderedItem['isIterationStep'];
      } else if (reorderedItem && reorderedItem.hasOwnProperty('isForLoopStep')) {
        delete reorderedItem['isForLoopStep'];
      }
      reorderedItem && delete reorderedItem['parentSpecialNlpId'];
    }
    if (tempItem?.conditionId) {
      if (tempItem?.conditionSearchKey) {
        if (!endConditionNlpNames.includes(tempItem?.nlpName)) {
          reorderedItem['conditionId'] = tempItem.conditionId;
          reorderedItem['conditionSearchKey'] = tempItem.conditionSearchKey;
        } else {
          let _conditionSearchKey, _parentIfConditionId;
          const conditionSearchKeyList = tempItem.conditionSearchKey.split('/');
          _parentIfConditionId = conditionSearchKeyList.at(-3);
          if (conditionSearchKeyList.length) {
            let __index = tempItem.conditionSearchKey.lastIndexOf('/');
            _conditionSearchKey = tempItem.conditionSearchKey.substring(0, __index);
          }
          if (_parentIfConditionId && _conditionSearchKey) {
            reorderedItem['conditionId'] = _parentIfConditionId;
            reorderedItem['conditionSearchKey'] = _conditionSearchKey;
          } else {
            deleteConditionIdAndSearchKey(reorderedItem);
          }
        }
      }
    } else {
      deleteConditionIdAndSearchKey(reorderedItem);
    }
    const scriptSteps = { ...reorderedItem, executionOrder: execOrder };
    const scriptWithReorderedSteps = { ...scripts, steps: [scriptSteps] };
    items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, scriptSteps);
    setScripts(scriptWithReorderedSteps);
    setScriptsFromApi(scriptWithReorderedSteps);
    if (props.type === 'Step Group' && selectedReturnTypeValue !== 'void') {
      for (let i = 0; i < items.length; i++) {
        if (items[i]?.stepId === returnstepId) {
          let retrurnType = selectedReturnTypeValue?.split('Step');
          retrurnType[retrurnType?.length - 1] = i + 1;
          scriptWithReorderedSteps.stepGroupReferenceInfo.stepNumber = i + 1;
          scriptWithReorderedSteps.returnType = retrurnType.join('Step');
          break;
        }
      }
    }
    if (result.source.index !== result.destination.index) {
      updateSteps(scriptWithReorderedSteps);
    }
  }
  const deleteConditionIdAndSearchKey = (reorderedItem) => {
    if (reorderedItem.hasOwnProperty('conditionId')) {
      delete reorderedItem['conditionId'];
      if (reorderedItem.hasOwnProperty('conditionSearchKey')) delete reorderedItem['conditionSearchKey'];
    }
  };
  async function updateSteps(payload) {
    try {
      if (props.type === 'Step Group') {
        payload.parameters = null;
        let response = await updateStepGroupReq(libraryId, stepGroupId, 'UPDATE', payload);
        if (response?.data?.responseObject?.returnType !== 'void') {
          let name = `${response?.data?.responseObject?.returnType}`;
          let data = {
            name: name,
            value: name,
          };
          setSelectedReturnTypeValue(data.name);
        }
        setOfStepsWithStepGroup(response?.data?.responseObject);
        setReload(true);
      } else {
        let response = await updateScriptReq(moduleId, scriptId, 'UPDATE', payload);
        setData(response?.data?.responseObject?.steps);
        setReload(true);
      }
    } catch (err) {
      console.error('UPDATE_STEPS : ', err);
    }
  }

  const deleteSteps = async (delData, index) => {
    let returnstepId;
    let stepCopy = [...scripts.steps];
    let splNlpPayload = [];
    if (delData.type === '_startiteration') {
      splNlpPayload.push(stepCopy.at(index));
      const endIterationIndex = scripts.steps.findIndex(
        (step) => step.type === '_enditeration' && step.specialNlpId === delData.specialNlpId
      );
      if (endIterationIndex > -1) {
        const endIterationStepHTMLElement = document.getElementById(`StepCheckbox-${endIterationIndex + 1}`);
        if (endIterationStepHTMLElement) {
          endIterationStepHTMLElement.indeterminate = false;
          endIterationStepHTMLElement.checked = false;
        }
        splNlpPayload.push(stepCopy.at(endIterationIndex));
      }
    } else if (delData.type === '_startforloop') {
      splNlpPayload.push(stepCopy.at(index));
      const endForLoopIndex = scripts.steps.findIndex(
        (step) => step.type === '_endforloop' && step.specialNlpId === delData.specialNlpId
      );
      if (endForLoopIndex > -1) {
        const endForLoopStepHTMLElement = document.getElementById(`StepCheckbox-${endForLoopIndex + 1}`);
        if (endForLoopStepHTMLElement) {
          endForLoopStepHTMLElement.indeterminate = false;
          endForLoopStepHTMLElement.checked = false;
        }
        splNlpPayload.push(stepCopy.at(endForLoopIndex));
      }
    } else if (startConditionObject.includes(delData?.nlpName)) {
      // need requirement clarification, below Line is required for future
      // let endIndex = scripts.steps.findIndex((tempObj) => endConditionObject.includes(tempObj?.nlpName) && tempObj?.conditionId === delData?.conditionId);
      splNlpPayload = [scripts?.steps.at(index), scripts?.steps[index + 1]];
    } else {
      let returnTypeStepNo;
      if (selectedReturnTypeValue !== 'void' && props.type === 'Step Group') {
        let retrurnType = selectedReturnTypeValue?.split('Step');
        returnTypeStepNo = Math.floor(retrurnType?.[retrurnType?.length - 1]);
        returnstepId = scripts.steps?.[returnTypeStepNo - 1]?.stepId;
      }
      if (returnTypeStepNo - 1 === index && props.type === 'Step Group') {
        scripts.returnType = 'void';
        delete scripts.stepGroupReferenceInfo;
      }
      scripts.steps.splice(index, 1);
      if (
        props.type === 'Step Group' &&
        selectedReturnTypeValue !== 'void' &&
        returnTypeStepNo - 1 !== index &&
        scripts.steps.length >= 1
      ) {
        for (let i = 0; i < scripts.steps.length; i++) {
          if (scripts.steps[i]?.stepId === returnstepId) {
            let retrurnType = selectedReturnTypeValue?.split('Step');
            retrurnType[retrurnType?.length - 1] = i + 1;
            scripts.stepGroupReferenceInfo.stepNumber = i + 1;
            scripts.returnType = retrurnType.join('Step');
            break;
          }
        }
      }
    }
    try {
      let response;
      let stepsCheckedArray = stepsChecked;
      let scriptsData = [...data];
      if (stepsChecked.length && stepsChecked.includes(index)) {
        if (!(stepClicked - 1 > 0)) {
          document.getElementById('rootStepsCheckbox').indeterminate = false;
          document.getElementById('rootStepsCheckbox').checked = false;
          document.getElementById(`StepCheckbox-${index + 1}`).checked = false;
        }
        setStepClicked(stepClicked - 1);
        let indexArr = stepsCheckedArray.indexOf(index);
        if (indexArr !== -1) {
          stepsCheckedArray.splice(indexArr, 1);
        }
        setStepsChecked(stepsCheckedArray);
      }
      const stepsForStepGroupArr = [...stepsForStepGroup];
      const findMatch = stepsForStepGroupArr.findIndex((item) => item.stepId === delData.stepId);
      if (findMatch >= 0) {
        stepsForStepGroupArr.splice(findMatch, 1);
      }
      if (startNlpList.includes(delData?.type)) {
        const endStepIndex = stepsForStepGroupArr?.findIndex(
          (item) => item?.specialNlpId === delData?.specialNlpId && endNlpList.includes(item.type)
        );
        if (endStepIndex >= 0) {
          stepsForStepGroupArr.splice(endStepIndex, 1);
        }
        setParentSpecialNlpId('');
        setParentStartDataproviderStep('');
        setStepClicked(stepClicked - 2); //pair of steps in case of for loop and data provider iteration
      }
      setStepsForStepGroup([...stepsForStepGroupArr]);
      const selectedStepsForExecution = [];
      stepsForStepGroupArr.forEach((_step) => {
        if (_step?.stepId) selectedStepsForExecution.push(_step.stepId);
      });
      setStepsSelectedForRun([...selectedStepsForExecution]);
      if (props.type === 'Step Group') {
        scripts.parameters = null;
        if (scripts.steps.length === 0) {
          scripts.returnType = 'void';
          delete scripts.stepGroupReferenceInfo;
        }
        let PayloadONStep = splNlpPayload.length === splNlpLength ? splNlpPayload : [delData];

        response = await updateStepGroupReq(libraryId, stepGroupId, 'DELETE', {
          ...scripts,
          steps: PayloadONStep,
        });
        if (response?.data?.responseObject?.returnType === 'void') {
          setReload(true);
          setSelectedReturnTypeValue('void');
        } else if (response?.data?.responseObject?.returnType !== 'void') {
          setReload(true);
          setSelectedReturnTypeValue(response?.data?.responseObject?.returnType);
        }
        setOfStepsWithStepGroup(response?.data?.responseObject);
      } else {
        const deleteStepData = {
          stepId: delData.stepId,
          name: delData.name,
          elementDetails: delData?.elementDetails || [],
        };
        let PayloadONStep = splNlpPayload.length === splNlpLength ? splNlpPayload : [deleteStepData];

        response = await updateScriptReq(moduleId, scriptId, 'DELETE', {
          ...scripts,
          steps: PayloadONStep,
        });
        splNlpPayload = [];
      }

      if (response.data.responseCode === 200 && response.data.status === 'SUCCESS') {
        document.getElementById('rootStepsCheckbox').indeterminate = false;
        document.getElementById('rootStepsCheckbox').checked = false;
        scriptsData?.forEach((value, index) => {
          document.getElementById(`StepCheckbox-${index + 1}`).checked = false;
          document.getElementById(`StepCheckbox-${index + 1}`).indeterminate = false;
        });
        setStepBoxCheck(0);
        setIsCheckAllSteps(false);
        setAllowDeleteFlag(true);
        updateStepsAfterDelete(response);
        setshowAddStepButton(true);
        setStepsForStepGroup([]);
        props.MyAlert.success('Step deleted successfully');
      } else {
        props.MyAlert.warning(`${response.data.message}`);
      }
    } catch (err) {
      setAllowDeleteFlag(true);
      console.error('DELETE_STEPS :', err);
    }
  };
  const updateStepsAfterDelete = (res) => {
    setDisableStepButtonOnload(true);
    if (res.data && res.data.responseObject) {
      setScripts(res.data && res.data.responseObject);
      setScriptsFromApi(res.data && res.data.responseObject);
      if (!['Web', 'Web & Mobile'].includes(res.data.responseObject?.scriptType)) {
        setDisableHeadlessSwitch(true);
      } else {
        setDisableHeadlessSwitch(false);
      }
      if (res.data.responseObject?.steps && res.data.responseObject.steps?.length) {
        updateStepsData(res.data.responseObject?.steps, [], 0);
        setStepIndex(res.data.responseObject.steps.length + 1);
      } else {
        setData([]);
        setStepIndex(1);
        setNoSteps(true);
        setShowStepResultHeader(false);
        setDisableStepButtonOnload(false);
      }
      if (res.data.responseObject.conditions && res.data.responseObject.conditions?.length) {
        setPreConditionData(
          res.data.responseObject.conditions.filter((condition) => {
            return condition?.status === 'enabled' && condition?.type === 'PRE';
          })
        );
        setPostConditionData(
          res.data.responseObject.conditions.filter((condition) => {
            return condition?.status === 'enabled' && condition?.type === 'POST';
          })
        );
      } else {
        setPreConditionData([]);
        setPostConditionData([]);
      }
      if (res.data.responseObject.dependentScript && res.data.responseObject.dependentScript.length) {
        setDependScriptData(res.data.responseObject.dependentScript);
      } else {
        setDependScriptData([]);
      }
    } else {
      setData([]);
      setStepIndex(1);
      setNoSteps(true);
      setPreConditionData([]);
      setPostConditionData([]);
      setDependScriptData([]);
    }
  };

  const handleEditStep = (index, data) => {
    if (openPreConditionStepGroupInputParameterDiv) {
      closePreConditionsModal();
    } else if (openPostConditionStepGroupInputParameterDiv) {
      closePostConditionsModal();
    }
    nlpSuggestionBoxRenderer(index);
    setAddStepAtIndex(index);
    setStepsDataObject(data);
    setStepIndex(index + 1);
    setEditPreCondition(true);
    setMode('replace');
    setSelectedStepIndex(index);
    setEditData(data);
    setEditStep(true);
    setStepID(data.stepId);

    if (
      document.getElementById(`StepCheckbox-${index + 1}`).checked &&
      document.getElementById('rootStepsCheckbox').checked
    ) {
      document.getElementById(`StepCheckbox-${index + 1}`).checked = true;
    }
    if (
      document.getElementById('rootStepsCheckbox').checked ||
      document.getElementById('rootStepsCheckbox').indeterminate
    ) {
      document.getElementById('rootStepsCheckbox').indeterminate = true;
    }

    if (stepClicked - 1 === 0) {
      document.getElementById('rootStepsCheckbox').checked = false;
      document.getElementById('rootStepsCheckbox').indeterminate = false;
    }
  };

  const myCssMethod = (index) => {
    // This method will be removed when all the scenarios are tested
    const myClass = document.getElementsByClassName('myRow');
  };

  const reloadPreConditions = (val) => {
    setReload(val);
  };

  const closeModal = (value) => {
    setMode();
    setSelectedStepIndex();
    myCssMethod(-1);
    if (openDeleteModal) {
      setOpenDeleteModal(value);
      setStepGroupInputParameterDivData('');
    }
    setOpenStepGroupInputParameterDiv(false);
    setStepGroupInputParameterDivData('');
    setOpenCreatePreCondition(false);
    if (action !== 'update') {
      if (addStepAtIndex) removeEmptyStep(null, addStepAtIndex);
    }
    if (editPreCondition) {
      setEditPreCondition(false);
    }
    if (data.length === 0) {
      setNoSteps(true);
    }
    setStepIndex(data.length + 1);
    setEditData(null);
    setshowAddStepButton(true);
    setParentSpecialNlpId('');
    setParentStartDataproviderStep('');
    myCssMethod(-1);
    if (openStepGroupModal) {
      setOpenStepGroupModal(false);
    }
  };

  async function getUserAllAvailableMachine(defaultMachineData) {
    try {
      const response = await getUserAllAvailableMachineReq(user.id, project.id);
      const qrsResponse = response?.data?.responseMap?.projectQuickRunSetting;
      if (qrsResponse) {
        if (defaultMachineData) {
          setselectedDefaultMachine(defaultMachineData);
        } else {
          let defaultMachine = qrsResponse;
          setselectedDefaultMachine(defaultMachine);
        }
        const runSetApiData = qrsResponse.waitConfiguration;
        setQuickRunSettingsApiData(runSetApiData);
      } else if (response?.data?.responseCode === 404) {
        setSelectedDefaultValue(response.data.responseCode);
      }
    } catch (err) {
      console.error('GET_USER_MACHINE :', err);
    }
  }

  const getData = async (exeId) => {
    try {
      let { data } = await getExecutionData(exeId);
      setScreenshotDeleted(data?.responseObject?.fancyTreeData?.responseObject?.isScreenshotDeleted);
      setVideoDeleted(data?.responseObject?.fancyTreeData?.responseObject?.isVideoDeleted);
    } catch (err) {
      console.error('GET_DATA', err);
    }
  };

  let viewStepResultModal = (step, stepResult) => {
    if (step.status !== 'SKIP' && step.type !== 'Group') {
      if (stepResult?.uniqueId) {
        updateStepResult(stepResult?.uniqueId);
      } else {
        setStepResultData(stepResult);
        setOpenStepModal(true);
      }
    }
  };

  const updateStepResult = async (uniqueId) => {
    const response = await getStepResult(uniqueId);
    if (response?.data?.responseObject) {
      setStepResultData(response?.data?.responseObject);
      setOpenStepModal(true);
    }
  };

  const clearAllResults = () => {
    setPostConditionStats();
    setPreConditionStats();
    setDependScriptStats();
    setStepResultStats();
    setScriptResultData();
    removeIDB('script-data');
    setIndividualScriptResult();
    clearTreeLocalStorage();
  };

  const runStepsController = () => {
    if (runFlagRef.current) {
      setRunFlag(false);
      clearAllResults();
      runExecution();
    }
  };

  const sleep = async (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const getScriptTemp = async () => {
    setIsLoading(true);
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    setDisableStepButtonOnload(true);
    let res = await getScriptReq(moduleId, scriptId);
    stopProgress();
    const _responseObj = res?.data?.responseObject;
    if (_responseObj) {
      setScripts(_responseObj);
      setScriptsFromApi(_responseObj);
      if (!['Web', 'Web & Mobile'].includes(_responseObj?.scriptType)) {
        setDisableHeadlessSwitch(true);
      } else {
        setDisableHeadlessSwitch(false);
      }
      if (_responseObj?.steps?.length) {
        updateStepsData(_responseObj?.steps, [], 0);
        setStepIndex(_responseObj.steps.length + 1);
      } else {
        setData([]);
        setStepIndex(1);
        setNoSteps(true);
        setShowStepResultHeader(false);
        setDisableStepButtonOnload(false);
      }
      if (_responseObj?.conditions?.length) {
        setPreConditionData(
          _responseObj.conditions.filter((condition) => {
            return condition?.type === 'PRE';
          })
        );
        setPostConditionData(
          _responseObj.conditions.filter((condition) => {
            return condition?.type === 'POST';
          })
        );
      } else {
        setPreConditionData([]);
        setPostConditionData([]);
      }
      if (_responseObj?.dependentScript?.length) {
        setDependScriptData(_responseObj.dependentScript);
      } else {
        setDependScriptData([]);
      }
    } else {
      setData([]);
      setStepIndex(1);
      setNoSteps(true);
      setPreConditionData([]);
      setPostConditionData([]);
      setDependScriptData([]);
    }
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 10,
    });
    setIsLoading(false);
  };

  const getScriptResult = async () => {
    let res = await getIndividualScriptResult(scriptId);
    if (res?.data?.responseObject) {
      let scriptResult = res.data.responseObject;
      let manipulatedScriptResult = {};
      if (scriptResult?.stepResults && scriptResult?.stepResults?.length) {
        setShowStepResultHeader(true);
        setStepResultStats(scriptResult.stepResultStats);
        manipulatedScriptResult['stepResult'] = stepResultIterator(scriptResult?.stepResults);
      }
      if (scriptResult?.preConditions?.stepResults?.length) {
        setShowPreConditionResultHeader(true);
        setPreConditionStats(scriptResult.preConditionStats);
        manipulatedScriptResult['preConditionResult'] = stepResultIterator(scriptResult?.preConditions?.stepResults);
      }
      if (scriptResult?.postConditions?.stepResults?.length) {
        setPostConditionStats(scriptResult.postConditionStats);
        setShowPostConditionResultHeader(true);
        manipulatedScriptResult['postConditionResult'] = stepResultIterator(scriptResult?.postConditions?.stepResults);
      }
      if (scriptResult?.dependantExecutionEntityResponses?.length) {
        setShowDependsOnScriptsResultHeader(true);
        setDependScriptStats(scriptResult.dependantScriptStats);
        manipulatedScriptResult['dependantScriptResult'] = stepResultIterator(
          scriptResult?.dependantExecutionEntityResponses
        );
      }
      setIndividualScriptResult({ ...manipulatedScriptResult });
      setScriptResultData(scriptResult);
      setIDB('script-data', [scriptResult]);
      setSelectedSysyem(scriptResult.selectedSystem);
      setEnableSyncButton(true);
    } else {
      clearTreeLocalStorage();
    }
  };

  const stepResultIterator = (resultData) => {
    let stepResultData = {};
    resultData.forEach((stepResult) => {
      if (!stepResult.message) {
        stepResult['message'] = getMessage(stepResult);
      }
      stepResultData[stepResult.stepId] = stepResult;
    });
    return stepResultData;
  };

  const updateStepsData = async (stepsData, stepsSubArray, start) => {
    let end = start + 10;
    let subArray = stepsData.slice(start, end);
    let updatedArray = [...stepsSubArray, ...subArray];
    setData(updatedArray);
    setUpdatedStepData(updatedArray);
    if (end < stepsData.length) {
      await sleep(2);
      updateStepsData(stepsData, updatedArray, end);
    } else {
      setDisableStepButtonOnload(false);
    }
  };

  const getStepGroupData = async () => {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    let res = await getStepGroupReq(libraryId, stepGroupId);
    stopProgress();
    let stepsNameArray = [];
    if (res?.data?.responseObject?.steps) {
      setStepGroup(res.data.responseObject);
      setScripts(res.data.responseObject);
      setScriptsFromApi(res.data.responseObject);
      for (let index = 0; index < res.data.responseObject.steps.length; index++) {
        if (['PRE', 'POST'].includes(res.data.responseObject.steps[index].type)) {
          const ifFailedStepInput = {
            name: 'ifCheckPointIsFailed',
            type: 'java.lang.String',
            value: res.data.responseObject.steps[index].ifCheckPointIsFailed,
          };
          res.data.responseObject.steps[index].type = 'Group';
          res.data.responseObject.steps[index].stepInputs.push(ifFailedStepInput);
          stepsNameArray.push(res.data.responseObject.steps[index]);
        } else {
          if (!excludedNlpTypes.includes(res.data.responseObject.steps[index].type)) {
            const _sn = serialNoRendrer(sl_no, res.data.responseObject.steps[index], index);
            res.data.responseObject.steps[index]['slNo'] = _sn;
          }
          stepsNameArray.push(res.data.responseObject.steps[index]);
        }
      }
      if (stepsNameArray.length) {
        setData(stepsNameArray);
        setStepIndex(stepsNameArray.length + 1);
      } else {
        setData([]);
        setStepIndex(1);
        setNoSteps(true);
      }
    } else {
      setData([]);
      setStepIndex(1);
      setNoSteps(true);
    }
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 10,
    });
    setIsLoading(false);
    if (res.data.responseObject?.returnType) {
      let returnType = res.data.responseObject.returnType;
      if (returnType !== 'void') {
        let name = returnType;
        let data = {
          name: name,
          value: name,
        };
        setSelectedReturnTypeValue(data.name);
      }
    }
    setOfStepsWithStepGroup(res.data.responseObject);
  };

  function groupBy(objectArray, property, type) {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (key !== undefined) {
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
      }
      return acc;
    }, {});
  }

  const findDpStep = (id, array = []) => {
    let result;
    for (let arr of array) {
      let obj = arr;
      if (['_startforloop', 'Iteration'].includes(obj.type)) {
        if (obj.stepId === id) {
          result = obj;
          return result;
        } else {
          if (obj.stepResults) {
            result = findDpStep(id, obj.stepResults);
          }
          if (result) {
            return result;
          }
        }
      }
    }
    return result;
  };
  /**
   * method to set Start Iteration step status stats and return boolean for a step having result
   * @param {*} responseObject : responseObject of script received as per api response
   * @param {*} index
   * @param {*} scriptResult : script result in the response object
   */
  const setDataproviderStepResultAndStatus = (responseObject, index, scriptResult) => {
    let isStepHavingResult = false;
    let dpStepResultObject;
    if (scriptResult) {
      dpStepResultObject = findDpStep(responseObject?.steps[index]?.stepId, scriptResult?.stepResults);
    }
    if (dpStepResultObject?.stepResults) {
      //dpStep is the found Start Iteration step from the steps array for which stepResultStats has to be set
      const dpStep = responseObject.steps.find((_step) => _step.stepId === dpStepResultObject.stepId);
      const allChildren = responseObject.steps.filter((item) => item.parentSpecialNlpId === dpStep.specialNlpId);
      const _iterationstep = scriptResultData?.stepResults?.[0];
      if (_iterationstep?.hasOwnProperty('iteration') && _iterationstep?.iteration) {
        //if for loop is not executed, then step result steps will not have "iteration" key/value
        let _ite_arr = groupBy(dpStepResultObject.stepResults, 'iteration');
        let iterationList = getIterationList(_ite_arr);
        //adding stepResultStats to Start Iteration step to show the Status stats
        if (dpStep && allChildren && allChildren.length === _ite_arr[0].length) {
          dpStep['stepResultStats'] = dpStepResultObject.stepResultStats;
        }
        iterationList?.length &&
          iterationList[iterationList.length - 1].itr.forEach((stp) => {
            if (stp && ['step', 'Group'].includes(stp.type)) {
              stp['message'] = stp.message ? stp.message : getMessage(stp);
              individualScriptResult.stepResult[stp.stepId] = { ...stp };
            }
          });
      } else {
        if (dpStep && dpStepResultObject?.stepResults) {
          dpStep['stepResultStats'] = dpStepResultObject.stepResultStats;
          dpStepResultObject.stepResults.forEach((stp) => {
            if (stp && ['step', 'Group'].includes(stp.type)) {
              stp['message'] = stp.message ? stp.message : getMessage(stp);
              individualScriptResult.stepResult[stp.stepId] = { ...stp };
            }
          });
        }
      }
    }
    return isStepHavingResult;
  };

  const getIterationList = (_iteArr) => {
    const arr = Object.values(_iteArr);
    const iterationList = [];
    arr.forEach((_eachArr, index) => {
      let _status = '';
      _eachArr.forEach((_itr) => {
        _status = getOverallStatus(_itr);
      });
      iterationList.push({ itr: _eachArr, status: _status });
    });
    return iterationList;
  };

  const getMessage = (stepData) => {
    let msg = '';
    stepData['name'] = stepData.name ? stepData.name : '';
    if (stepData.status === 'PASS') {
      msg = stepData.name.concat(' is passed');
    } else if (stepData.status === 'FAIL') {
      msg = stepData.name.concat(' is failed');
    } else if (stepData.status === 'WARNING') {
      msg = stepData.name.concat(' has a warning');
    } else if (stepData.status === 'SKIP') {
      msg = stepData.name.concat(' is skipped');
    } else if (stepData.status === 'Terminated') {
      msg = stepData.name.concat(' is Terminated');
    } else if (stepData.status === 'N/A') {
      msg = stepData.name.concat(' is N/A');
    }
    return msg;
  };

  const clearTreeLocalStorage = async () => {
    removeIDB('tree-data');
    removeIDB('script-data');
  };

  const handleNlpData = (a) => {
    if (a) {
      setStepsDataObject(a);
    }
  };

  const handleConditionNlpObject = (conditionsObj) => {
    if (conditionsObj) {
      setConditionNlpObject(conditionsObj);
    }
  };

  useEffect(() => {
    if (pageIndexValue > 550) {
      setPage(-250);
      setMargin(150);
    } else {
      setPage(100);
      setMargin();
    }
  }, [pageIndexValue]);

  const openStepGroup = () => {
    getLibraryTreeReq(null, false).then((response) => {
      let data = response?.data?.responseObject?.libraryTree;
      let customLibraryFound = false;
      data?.[0]?.children?.forEach((child) => {
        if (child.defaultLibrary === false) {
          customLibraryFound = true;
          return;
        }
      });
      if (customLibraryFound) {
        if (response?.data?.responseObject?.licenseLevelStepGroupCount < countData.numberOfStepGroups) {
          let tempDropdownTreeData = response.data.responseObject.libraryTree;
          tempDropdownTreeData[0].title = tempDropdownTreeData[0].title + ' Library';
          setDropdownTreeData(tempDropdownTreeData);
          setOpenStepGroupModal(true);
        } else {
          props.MyAlert.info('Exceeded allocated limit, please upgrade or buy new license');
        }
      } else {
        props.MyAlert.info(`No library found`);
      }
    });
  };

  const handleCheckedData = (e, data, index, type) => {
    let stepsForStepGroupArray = stepsForStepGroup;
    if (type === 'dependscript') {
      let dependentScriptCheckedArray = [...dependentScriptChecked];
      let depScrForRun = [...dependsOnScriptsSelectedForRun];
      if (e.target.checked) {
        dependentScriptCheckedArray.push(index);
        depScrForRun.push(data.stepId);
        setDependentScriptChecked(dependentScriptCheckedArray);
        setDependsOnScriptsSelectedForRun([...depScrForRun]);
      } else {
        dependentScriptCheckedArray.forEach((_data, arrIndex) => {
          if (depScrForRun[arrIndex] === data.stepId) {
            depScrForRun.splice(arrIndex, 1);
            setDependsOnScriptsSelectedForRun([...depScrForRun]);
          }
          if (_data === index) {
            dependentScriptCheckedArray.splice(arrIndex, 1);
            setDependentScriptChecked(dependentScriptCheckedArray);
          }
        });
      }
      const checkbox = document.getElementById('rootDependsOnScriptCheckbox');
      if (dependentScriptCheckedArray.length > 0) {
        if (checkbox && dependentScriptCheckedArray.length === dependScriptData.length) {
          checkbox.indeterminate = false;
          checkbox.checked = true;
          setIsCheckAllDependsOnScripts(true);
        } else if (checkbox) {
          checkbox.checked = false;
          checkbox.indeterminate = true;
          setIsCheckAllDependsOnScripts(false);
        }
      } else {
        if (checkbox) {
          checkbox.indeterminate = false;
          checkbox.checked = false;
          setIsCheckAllDependsOnScripts(false);
        }
      }
    } else if (type === 'precondition') {
      let preConditionCheckedArray = preConditionChecked;
      const preConditionForRun = [...preConditionsSelectedForRun];
      if (e.target.checked) {
        data.stepNumber = index + 1;
        setStepClicked(stepClicked + 1);
        if (!stepsForStepGroupArray?.some((_stg) => _stg?.stepId === data?.stepId)) {
          stepsForStepGroupArray.push(data);
        }
        setStepsForStepGroup(stepsForStepGroupArray);
        preConditionCheckedArray.push(index);
        setPreConditionChecked(preConditionCheckedArray);
        preConditionForRun.push(data.stepId);
        setPreConditionsSelectedForRun([...preConditionForRun]);
      } else {
        stepsForStepGroupArray.forEach((step, index) => {
          if (step.stepGroupId === data.stepGroupId) {
            stepsForStepGroupArray.splice(index, 1);
          }
        });
        setStepsForStepGroup(stepsForStepGroupArray);
        setStepClicked(stepClicked === 0 ? 0 : stepClicked - 1);
        preConditionCheckedArray.forEach((_data, arrIndex) => {
          if (preConditionForRun[arrIndex] === data.stepId) {
            preConditionForRun.splice(arrIndex, 1);
            setPreConditionsSelectedForRun([...preConditionForRun]);
          }
          if (_data === index) {
            preConditionCheckedArray.splice(arrIndex, 1);
            setPreConditionChecked(preConditionCheckedArray);
          }
        });
      }
      const selectedPreCdns = stepsForStepGroupArray.filter((cdn) => cdn.type === 'PRE');
      let checkbox = document.getElementById('rootPreConditionCheckbox');
      if (selectedPreCdns.length > 0) {
        if (checkbox && selectedPreCdns.length === preConditionData.length) {
          checkbox.indeterminate = false;
          checkbox.checked = true;
          setIsCheckAllPreCondition(true);
        } else if (checkbox) {
          checkbox.checked = false;
          checkbox.indeterminate = true;
          setIsCheckAllPreCondition(false);
        }
      } else {
        if (checkbox) {
          checkbox.indeterminate = false;
          checkbox.checked = false;
          setIsCheckAllPreCondition(false);
        }
      }
    } else if (type === 'postcondition') {
      let postConditionCheckedArray = postConditionChecked;
      const postConditionsForRun = [...postConditionsSelectedForRun];
      if (e.target.checked) {
        data.stepNumber = index + 1;
        setStepClicked(stepClicked + 1);
        if (!stepsForStepGroupArray?.some((_stg) => _stg?.stepId === data?.stepId)) {
          stepsForStepGroupArray.push(data);
        }
        setStepsForStepGroup(stepsForStepGroupArray);
        postConditionCheckedArray.push(index);
        setPostConditionChecked(postConditionCheckedArray);
        postConditionsForRun.push(data.stepId);
        setPostConditionsSelectedForRun([...postConditionsForRun]);
      } else {
        stepsForStepGroupArray.forEach((step, index) => {
          if (step.stepGroupId === data.stepGroupId) {
            stepsForStepGroupArray.splice(index, 1);
          }
        });
        setStepsForStepGroup(stepsForStepGroupArray);
        setStepClicked(stepClicked === 0 ? 0 : stepClicked - 1);
        postConditionCheckedArray.forEach((_data, arrIndex) => {
          if (postConditionsForRun[arrIndex] === data.stepId) {
            postConditionsForRun.splice(arrIndex, 1);
          }
          if (_data === index) {
            postConditionCheckedArray.splice(arrIndex, 1);
            setPostConditionChecked(postConditionCheckedArray);
          }
        });
      }
      const selectedPostCdns = stepsForStepGroupArray.filter((cdn) => cdn.type === 'POST');
      let checkbox = document.getElementById('rootPostConditionCheckbox');
      if (selectedPostCdns.length > 0) {
        if (checkbox && selectedPostCdns.length === postConditionData.length) {
          checkbox.indeterminate = false;
          checkbox.checked = true;
          setIsCheckAllPostCondition(true);
        } else if (checkbox) {
          checkbox.checked = false;
          checkbox.indeterminate = true;
          setIsCheckAllPostCondition(false);
        }
      } else {
        if (checkbox) {
          checkbox.indeterminate = false;
          checkbox.checked = false;
          setIsCheckAllPostCondition(false);
        }
      }
    }
  };

  const [selectedStepObjects, setSelectedStepObjects] = useState({});

  const getListOfIterationSteps = (stepdata) => {
    const _iterationStepsList = data.filter((_dat) => {
      if (_dat.parentSpecialNlpId && _dat.parentSpecialNlpId === stepdata.specialNlpId) {
        return true;
      } else {
        if (stepdata.type === 'step' || stepdata.type === 'Group') {
          if (_dat.parentSpecialNlpId && _dat.parentSpecialNlpId === stepdata.parentSpecialNlpId) {
            return true;
          }
        }
        return false;
      }
    });
    return _iterationStepsList;
  };

  /**
   * Step check operation handling for Dataprovider iteration/For loop steps
   * @param {*} e
   * @param {*} stepdata:object | checked/unchecked For loop/Iteration step object
   * @param {*} index:number | index of the step checked/unchecked
   * @param {*} _selectedStepObj:object | Object to hold all the valid checked steps of iteration and loops with iteration_set reference
   * @param {*} stepsForStepGroupArray:array | array to hold all the checked steps to create a step group
   * @param {*} itr_set:string | unique iteration_set created with specialNlpId to refer all of the steps under the iteraation
   * @param {*} selectedStepsForExecution:array | array of checked steps stepId
   */
  const handleStartIterationStepCheck = (
    e,
    stepdata,
    clickedStepType,
    index,
    _selectedStepObj,
    listOfIterationSteps,
    stepsForStepGroupArray,
    itr_set,
    selectedStepsForExecution
  ) => {
    const selectedSteps = [...stepsChecked];
    if (startNlpList.includes(stepdata.type)) {
      let totalChecked = stepClicked;
      if (
        stepdata.hasOwnProperty('parentSpecialNlpId') &&
        _selectedStepObj[itr_set][stepdata.parentSpecialNlpId] &&
        _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].length > -1
      ) {
        if (e.target.checked) {
          if (!checkIfDuplicateStep(_selectedStepObj, stepdata, itr_set)) {
            _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].push(stepdata);
            const endIterationStep = data.find(
              (_data) => _data.specialNlpId === stepdata.specialNlpId && endNlpList.includes(_data.type)
            );
            _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].push(endIterationStep);
            if (!stepsForStepGroupArray?.some((_stg) => _stg?.stepId === stepdata?.stepId)) {
              stepsForStepGroupArray.push(stepdata);
            }
            if (!stepsForStepGroupArray?.some((_stg) => _stg?.stepId === endIterationStep?.stepId)) {
              stepsForStepGroupArray.push(endIterationStep);
            }
            updateIterationStep(
              e,
              _selectedStepObj,
              stepdata,
              listOfIterationSteps,
              clickedStepType,
              stepsForStepGroupArray,
              itr_set
            );
          }
        } else {
          _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].forEach((step, _ind) => {
            if (step.stepId === stepdata.stepId) {
              _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].splice(_ind, 1);
              _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].splice(
                _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].findIndex(
                  (_data) => _data.specialNlpId === stepdata.specialNlpId && endNlpList.includes(_data.type)
                ),
                1
              );
              stepsForStepGroupArray.splice(
                stepsForStepGroupArray.findIndex(
                  (_data) => _data.specialNlpId === stepdata.specialNlpId && endNlpList.includes(_data.type)
                ),
                1
              );
              stepsForStepGroupArray.splice(
                stepsForStepGroupArray.findIndex(
                  (_data) => _data.specialNlpId === stepdata.specialNlpId && endNlpList.includes(_data.type)
                ),
                1
              );
              updateIterationStep(
                e,
                _selectedStepObj,
                stepdata,
                listOfIterationSteps,
                clickedStepType,
                stepsForStepGroupArray,
                itr_set
              );
            }
          });
        }
      } else {
        let _selectedKey = 'root';
        if (stepdata.hasOwnProperty('parentSpecialNlpId')) {
          _selectedKey = stepdata.parentSpecialNlpId;
        }
        if (e.target.checked) {
          if (!checkIfDuplicateStep(_selectedStepObj, stepdata, itr_set)) {
            _selectedStepObj[itr_set][_selectedKey] = [stepdata];
            const endIterationStep = data.find(
              (_data) => _data.specialNlpId === stepdata.specialNlpId && endNlpList.includes(_data.type)
            );
            _selectedStepObj[itr_set][_selectedKey].push(endIterationStep);
            if (!stepsForStepGroupArray?.some((_stg) => _stg?.stepId === stepdata?.stepId)) {
              stepsForStepGroupArray.push(stepdata);
            }
            if (stepdata.type !== '_enditeration') {
              updateIterationStep(
                e,
                _selectedStepObj,
                stepdata,
                listOfIterationSteps,
                clickedStepType,
                stepsForStepGroupArray,
                itr_set
              );
            }
          }
          if (
            stepdata.hasOwnProperty('specialNlpId') &&
            startNlpList.includes(stepdata.type) &&
            !_selectedStepObj?.[itr_set]?.hasOwnProperty(stepdata.specialNlpId)
          ) {
            _selectedStepObj[itr_set][stepdata.specialNlpId] = [];
          }
        } else {
          _selectedStepObj[itr_set][_selectedKey].forEach((step, _ind) => {
            if (step.stepId === stepdata.stepId) {
              _selectedStepObj[itr_set][_selectedKey].splice(_ind, 1);
              _selectedStepObj[itr_set][_selectedKey].splice(
                _selectedStepObj[itr_set][_selectedKey].findIndex(
                  (_data) => _data.specialNlpId === stepdata.specialNlpId && endNlpList.includes(_data.type)
                ),
                1
              );
              _selectedStepObj[itr_set][stepdata.specialNlpId] = [];
            }
          });
        }
      }

      setSelectedStepObjects(_selectedStepObj);
      let tempStepClicked = 0;
      Object.assign(tempStepClicked, stepClicked);
      for (let i = index; i < data.length; i++) {
        let eachStep = data[i];
        let stepCheckbox = document.getElementById(`StepCheckbox-${i + 1}`);
        if (e.target.checked) {
          if (stepCheckbox && !stepCheckbox.checked) {
            totalChecked = totalChecked + 1;
            setStepClicked(stepClicked + 1);
            stepCheckbox.checked = true;
            if (!stepsForStepGroupArray?.some((_stg) => _stg?.stepId === eachStep?.stepId)) {
              stepsForStepGroupArray.push(eachStep);
            }
            selectedSteps.push(index);
          }
        } else {
          stepCheckbox.checked = false;
          const _deleteSTGindex = stepsForStepGroupArray.findIndex((stg) => stg.stepId === eachStep.stepId);
          if (_deleteSTGindex > -1) {
            stepsForStepGroupArray.splice(_deleteSTGindex, 1);
          }
          tempStepClicked = tempStepClicked - 1;
        }
        if (endNlpList.includes(eachStep.type)) {
          let end_stepCheckbox = document.getElementById(`StepCheckbox-${i + 1}`);

          setSelectedStepObjects(_selectedStepObj);
          if (end_stepCheckbox) {
            if (
              selectedStepObjects.hasOwnProperty(itr_set) &&
              selectedStepObjects[itr_set].hasOwnProperty(stepdata.specialNlpId) &&
              listOfIterationSteps.length === selectedStepObjects[itr_set][stepdata.specialNlpId].length
            ) {
              end_stepCheckbox.indeterminate = false;
              end_stepCheckbox.checked = true;
            } else {
              end_stepCheckbox.indeterminate = true;
            }
            if (!e.target.checked) {
              end_stepCheckbox.indeterminate = false;
              end_stepCheckbox.checked = false;
            }

            showEndIterationCheckbox(e.target.checked, end_stepCheckbox);
          }
          if (eachStep.specialNlpId === stepdata.specialNlpId) {
            break;
          }
        }
      }
    } else {
      if (stepdata.type !== '_enditeration') {
        updateIterationStep(
          e,
          _selectedStepObj,
          stepdata,
          listOfIterationSteps,
          clickedStepType,
          stepsForStepGroupArray,
          itr_set
        );
      }
    }
    selectedStepsForExecution = [];
    if (stepsForStepGroupArray.length) {
      stepsForStepGroupArray.forEach((_step) => {
        selectedStepsForExecution.push(_step.stepId);
      });
      setStepsSelectedForRun([...selectedStepsForExecution]);
    }

    if (stepsForStepGroupArray.length === 0) {
      setStepClicked(0);
    }
  };
  /**
   * update all the iteration steps of a particular start iteration step
   * @param {*} e
   * @param {*} _selectedStepObj
   * @param {*} startStepData
   * @returns
   */
  const updateIterationStep = (
    e,
    _selectedStepObj,
    startStepData,
    listOfIterationSteps,
    clickedStepType,
    stepsForStepGroupArray,
    itr_set
  ) => {
    if (e.target.checked) {
      listOfIterationSteps.forEach((_step) => {
        if (
          _selectedStepObj.hasOwnProperty(itr_set) &&
          _selectedStepObj[itr_set][startStepData.specialNlpId] &&
          _selectedStepObj[itr_set][startStepData.specialNlpId].length > -1
        ) {
          if (startNlpList.includes(_step.type)) {
            updateStartIterationStepIntoSelectedStep(
              e,
              _step,
              _selectedStepObj,
              clickedStepType,
              stepsForStepGroupArray,
              itr_set
            );
          } else if (_step.type === 'step' || _step.type === 'Group') {
            if (!checkIfDuplicateStep(_selectedStepObj, _step, itr_set)) {
              _selectedStepObj[itr_set][startStepData.specialNlpId].push(_step);
              if (!stepsForStepGroupArray?.some((_stg) => _stg?.stepId === _step?.stepId)) {
                stepsForStepGroupArray.push(_step);
              }
            }
          }
        } else {
          _selectedStepObj[itr_set][startStepData.specialNlpId] = [_step];
        }
        setStepClicked(stepClicked + 1);
      });
    } else {
      _selectedStepObj[itr_set][startStepData.specialNlpId] = [];
      setStepClicked(stepClicked - 1);
    }
  };

  const showEndIterationCheckbox = (status, endCheckBoxHtmlEle) => {
    if (status) {
      endCheckBoxHtmlEle.style.visibility = 'visible';
      endCheckBoxHtmlEle.style.opacity = 0.5;
    } else {
      endCheckBoxHtmlEle.style.visibility = 'hidden';
      endCheckBoxHtmlEle.style.opacity = 0.5;
    }
  };
  const setCheckboxIndeterminate = (status, start_checkbox, end_checkbox) => {
    start_checkbox.indeterminate = status;
    end_checkbox.indeterminate = status;
    showEndIterationCheckbox(status, end_checkbox);
  };
  const setCheckboxChecked = (status, start_checkbox, end_checkbox) => {
    start_checkbox.checked = status;
    end_checkbox.checked = status;
    showEndIterationCheckbox(status, end_checkbox);
  };
  /**
   * to check if the step to be added into selectedStepObjects is already present
   * @param {*} _selectedStepObj
   * @param {*} stepdata
   * @returns
   */
  const checkIfDuplicateStep = (_selectedStepObj, stepdata, itr_set) => {
    let isDuplicateStep = false;
    if (stepdata.type === 'step' || stepdata.type === 'Group') {
      isDuplicateStep = _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].some(
        (_stp) => _stp.stepId === stepdata.stepId
      );
    } else if (startNlpList.includes(stepdata.type)) {
      if (stepdata.hasOwnProperty('parentSpecialNlpId')) {
        if (
          _selectedStepObj.hasOwnProperty(itr_set) &&
          _selectedStepObj[itr_set][stepdata.parentSpecialNlpId] &&
          _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].length
        ) {
          isDuplicateStep = _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].some(
            (_stp) => _stp.stepId === stepdata.stepId
          );
        }
      } else {
        if (
          _selectedStepObj.hasOwnProperty(itr_set) &&
          _selectedStepObj[itr_set]['root'] &&
          _selectedStepObj[itr_set]['root'].length
        ) {
          isDuplicateStep = _selectedStepObj[itr_set]['root'].some((_stp) => _stp.stepId === stepdata.stepId);
        }
      }
    }
    return isDuplicateStep;
  };
  const updateStartIterationStepIntoSelectedStep = (
    e,
    stepdata,
    _selectedStepObj,
    clickedStepType,
    stepsForStepGroupArray,
    itr_set
  ) => {
    let stepsCheckedArray = stepsChecked;
    const listOfIteration = getListOfIterationSteps(stepdata);
    if (
      stepdata.hasOwnProperty('parentSpecialNlpId') &&
      _selectedStepObj[itr_set][stepdata.parentSpecialNlpId] &&
      _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].length > -1
    ) {
      if (e.target.checked) {
        if (!checkIfDuplicateStep(_selectedStepObj, stepdata, itr_set)) {
          _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].push(stepdata);
          const endIterationStep = data.find(
            (_data) => _data.specialNlpId === stepdata.specialNlpId && endNlpList.includes(_data.type)
          );
          _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].push(endIterationStep);
          setStepClicked(stepClicked + 1);
          if (!stepsForStepGroupArray?.some((_stg) => _stg?.stepId === stepdata?.stepId)) {
            stepsForStepGroupArray.push(stepdata);
          }
          if (!stepsForStepGroupArray?.some((_stg) => _stg?.stepId === endIterationStep?.stepId)) {
            stepsForStepGroupArray.push(endIterationStep);
          }
          //to update the steps of a dataprovider iteration
          if (listOfIteration.length)
            updateIterationStep(
              e,
              _selectedStepObj,
              stepdata,
              listOfIteration,
              clickedStepType,
              stepsForStepGroupArray,
              itr_set
            );
        }
      } else {
        _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].forEach((step, _ind) => {
          if (step.stepId === stepdata.stepId) {
            _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].splice(_ind, 1);
            _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].splice(
              _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].findIndex(
                (_data) => _data.specialNlpId === stepdata.specialNlpId && endNlpList.includes(_data.type)
              ),
              1
            );
            setStepClicked(stepClicked - 1);
          }
        });
      }
    } else {
      let _selectedKey = 'root';
      if (stepdata.hasOwnProperty('parentSpecialNlpId')) {
        _selectedKey = stepdata.parentSpecialNlpId;
      }
      if (e.target.checked) {
        if (!checkIfDuplicateStep(_selectedStepObj, stepdata, itr_set)) {
          _selectedStepObj[itr_set][_selectedKey] = [stepdata];
          const _enditerationStep = data.find(
            (_data) => _data.specialNlpId === stepdata.specialNlpId && endNlpList.includes(_data.type)
          );
          _selectedStepObj[itr_set][_selectedKey].push(_enditerationStep);
          setStepClicked(stepClicked + 1);
          if (!stepsForStepGroupArray?.some((_stg) => _stg?.stepId === stepdata?.stepId)) {
            stepsForStepGroupArray.push(stepdata);
          }
          if (!stepsForStepGroupArray?.some((_stg) => _stg?.stepId === _enditerationStep?.stepId)) {
            stepsForStepGroupArray.push(_enditerationStep);
          }
        }
      } else {
        if (_selectedStepObj.hasOwnProperty(itr_set) && _selectedStepObj[itr_set].hasOwnProperty('root')) {
          _selectedStepObj[itr_set]['root'].forEach((step, _ind) => {
            if (step.stepId === stepdata.stepId) {
              _selectedStepObj[itr_set]['root'].splice(_ind, 1);
              _selectedStepObj[itr_set]['root'].splice(
                _selectedStepObj[itr_set]['root'].findIndex(
                  (_data) => _data.specialNlpId === stepdata.specialNlpId && endNlpList.includes(_data.type)
                ),
                1
              );
              setStepClicked(stepClicked - 1);
            }
          });
        }
      }
    }
    if (clickedStepType === '_startiteration') {
      if (listOfIteration?.length) {
        listOfIteration.forEach((_itrStep) => {
          if (_itrStep.type !== 'step' && _itrStep.type === '_startiteration') {
            updateStartIterationStepIntoSelectedStep(
              e,
              _itrStep,
              _selectedStepObj,
              clickedStepType,
              stepsForStepGroupArray,
              itr_set
            );
          } else {
            //for iteration steps other than start and end
            if (e.target.checked) {
              if (!checkIfDuplicateStep(_selectedStepObj, stepdata, itr_set)) {
                if (
                  _selectedStepObj.hasOwnProperty(itr_set) &&
                  _selectedStepObj[itr_set].hasOwnProperty(stepdata.specialNlpId)
                ) {
                  _selectedStepObj[itr_set][stepdata.specialNlpId].push(_itrStep);
                } else {
                  _selectedStepObj[itr_set][stepdata.specialNlpId] = [_itrStep];
                }
              }
            } else {
              _selectedStepObj[itr_set][stepdata.specialNlpId] = [];
            }
          }
        });
      }
    }
  };
  const handleIterationStepCheckbox = (
    e,
    stepdata,
    clickedStepType,
    index,
    _selectedStepObj,
    listOfIterationSteps,
    stepsForStepGroupArray,
    itr_set,
    selectedStepsForExecution
  ) => {
    let allParentStartIterationSteps = [];
    let stepsCheckedArray = stepsChecked;
    let listOfStarts = getIterationSetAndListOfStarts(stepdata, 'listOfStarts');
    listOfStarts.push(stepdata);
    if (_selectedStepObj.hasOwnProperty(itr_set)) {
      if (_selectedStepObj[itr_set].hasOwnProperty(stepdata?.parentSpecialNlpId)) {
        if (_selectedStepObj[itr_set][stepdata.parentSpecialNlpId].length) {
          if (e.target.checked) {
            if (!checkIfDuplicateStep(_selectedStepObj, stepdata, itr_set)) {
              _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].push(stepdata);
            }
          } else {
            _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].forEach((step, _ind) => {
              if (step.stepId === stepdata.stepId) {
                _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].splice(_ind, 1);
                if (!_selectedStepObj[itr_set][stepdata.parentSpecialNlpId].length) {
                  const _stepGroupArray_startStepIndex = stepsForStepGroupArray.findIndex(
                    (_stgStep) =>
                      _stgStep?.specialNlpId === stepdata?.parentSpecialNlpId && startNlpList.includes(_stgStep.type)
                  );
                  if (_stepGroupArray_startStepIndex > -1) {
                    stepsForStepGroupArray.splice(_stepGroupArray_startStepIndex, 1);
                    const _stepGroupArray_endStepIndex = stepsForStepGroupArray.findIndex(
                      (_stgStep) =>
                        _stgStep?.specialNlpId === stepdata?.parentSpecialNlpId && endNlpList.includes(_stgStep.type)
                    );
                    if (_stepGroupArray_endStepIndex > -1) {
                      stepsForStepGroupArray.splice(_stepGroupArray_endStepIndex, 1);
                    }
                  }
                }
                const parentStartStep = data.find(
                  (_dt) => _dt.specialNlpId === stepdata.parentSpecialNlpId && startNlpList.includes(_dt.type)
                );
                if (parentStartStep?.parentSpecialNlpId) {
                  _selectedStepObj[itr_set][parentStartStep.parentSpecialNlpId].forEach((_stp, _index) => {
                    if (_stp.stepId === parentStartStep.stepId) {
                      _selectedStepObj[itr_set][parentStartStep.parentSpecialNlpId].splice(_index, 1);
                      _selectedStepObj[itr_set][parentStartStep.parentSpecialNlpId].splice(
                        data.findIndex((_data) => _data.stepId === _stp.stepId && endNlpList.includes(_data.type))
                      );
                    }
                  });
                }
              }
            });
            _selectedStepObj[itr_set]['root'] = []; //root is emptied if any iteration step is unchecked
          }
        } else {
          _selectedStepObj[itr_set][stepdata.parentSpecialNlpId] = [stepdata];
        }
      } else {
        _selectedStepObj[itr_set][stepdata?.parentSpecialNlpId] = [stepdata];
      }
    }

    setSelectedStepObjects(_selectedStepObj);
    //to get a list of all the start iteration steps for the checked iteration step
    for (let j = listOfStarts.length - 1; j >= 0; j--) {
      let _step = listOfStarts[j];
      if (_step.parentSpecialNlpId) {
        let _startIterationStep = {
          stepData: data.find(
            (_dt) => _dt.specialNlpId === _step.parentSpecialNlpId && startNlpList.includes(_dt.type)
          ),
          index: data.findIndex(
            (_dt) => _dt.specialNlpId === _step.parentSpecialNlpId && startNlpList.includes(_dt.type)
          ),
        };
        allParentStartIterationSteps.push(_startIterationStep);
        if (!_startIterationStep?.stepData?.parentSpecialNlpId) {
          break;
        }
      }
    }
    if (allParentStartIterationSteps.length) {
      allParentStartIterationSteps.forEach((_startStep) => {
        const start_checkbox = document.getElementById(`StepCheckbox-${_startStep.index + 1}`);
        const startStepData = _startStep.stepData;
        let endStepData = data.find(
          (_data) => _data.specialNlpId === _startStep.stepData.specialNlpId && endNlpList.includes(_data.type)
        );
        const end_checkbox = document.getElementById(
          `StepCheckbox-${
            data.findIndex(
              (_data) => _data.specialNlpId === _startStep.stepData.specialNlpId && endNlpList.includes(_data.type)
            ) + 1
          }`
        );
        if (e.target.checked) {
          if (!stepsForStepGroupArray?.some((_checkedStep) => _checkedStep?.stepId === startStepData?.stepId)) {
            stepsForStepGroupArray.push(startStepData);
          }
          if (!stepsForStepGroupArray?.some((_checkedStep) => _checkedStep?.stepId === endStepData?.stepId)) {
            stepsForStepGroupArray.push(endStepData);
          }
        }
        if (start_checkbox && end_checkbox) {
          /* if all the steps under a specific iteration are checked */
          if (
            _selectedStepObj.hasOwnProperty(itr_set) &&
            _selectedStepObj[itr_set].hasOwnProperty(stepdata.parentSpecialNlpId)
          ) {
            /**
             * if the current _startStep is parent of the checked iteration step then it need to be checked as per the conditions.
             * Partially check if all the children steps are not checked
             *  */
            if (listOfIterationSteps.length === _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].length) {
              if (_startStep.stepData.specialNlpId === stepdata.parentSpecialNlpId) {
                if (e.target.checked) {
                  stepsCheckedArray.push(_startStep.index);
                  setCheckboxChecked(true, start_checkbox, end_checkbox);
                  updateStartIterationStepIntoSelectedStep(
                    e,
                    startStepData,
                    _selectedStepObj,
                    clickedStepType,
                    stepsForStepGroupArray,
                    itr_set
                  );
                }
                start_checkbox.indeterminate = false;
                end_checkbox.indeterminate = false;
              } else {
                if (e.target.checked) {
                  setCheckboxIndeterminate(true, start_checkbox, end_checkbox);
                } else {
                  setCheckboxIndeterminate(false, start_checkbox, end_checkbox);
                }
              }
            } else if (
              _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].length &&
              listOfIterationSteps.length > _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].length
            ) {
              if (_startStep.stepData.specialNlpId === stepdata.parentSpecialNlpId) {
                setCheckboxIndeterminate(true, start_checkbox, end_checkbox);
              } else {
                if (e.target.checked) {
                  setCheckboxIndeterminate(true, start_checkbox, end_checkbox);
                } else {
                  setCheckboxIndeterminate(false, start_checkbox, end_checkbox);
                }
              }
            } else if (!selectedStepObjects[itr_set][stepdata.parentSpecialNlpId].length) {
              setCheckboxIndeterminate(false, start_checkbox, end_checkbox);
              setCheckboxChecked(false, start_checkbox, end_checkbox);
              updateStartIterationStepIntoSelectedStep(
                e,
                startStepData,
                _selectedStepObj,
                clickedStepType,
                stepsForStepGroupArray,
                itr_set
              );
            }
          } else {
            if (e.target.checked) {
              setCheckboxIndeterminate(true, start_checkbox, end_checkbox);
            } else {
              setCheckboxChecked(false, start_checkbox, end_checkbox);
              setCheckboxIndeterminate(false, start_checkbox, end_checkbox);
              updateStartIterationStepIntoSelectedStep(
                e,
                startStepData,
                _selectedStepObj,
                clickedStepType,
                stepsForStepGroupArray,
                itr_set
              );
            }
          }
        }
      });
    }
    selectedStepsForExecution = [];
    if (stepsForStepGroupArray.length) {
      stepsForStepGroupArray.forEach((_step) => {
        selectedStepsForExecution.push(_step.stepId);
      });
      setStepsSelectedForRun([...selectedStepsForExecution]);
    }
  };
  const getIterationSetAndListOfStarts = (stepdata, mode) => {
    let _stepObj = stepdata;
    let itr_set;
    let listOfStarts = [];
    while (_stepObj?.parentSpecialNlpId) {
      _stepObj = data.find(
        (_dat) => startNlpList.includes(_dat.type) && _dat.specialNlpId === _stepObj.parentSpecialNlpId
      );
      listOfStarts.push(_stepObj);
    }
    if (_stepObj.specialNlpId) {
      itr_set = 'set_' + _stepObj.specialNlpId;
    }
    if (mode === 'listOfStarts') {
      return listOfStarts;
    } else {
      return itr_set;
    }
  };
  /**
   * method to handle checkbox selection of steps
   * @param {*} e
   * @param {*} stepdata  clicked row data
   */
  const handleChecked = (e, stepdata, index) => {
    let selectedStepsForExecution = [...stepsSelectedForRun];
    let _selectedStepObj = selectedStepObjects;
    const listOfIterationSteps = getListOfIterationSteps(stepdata);
    let stepsForStepGroupArray = stepsForStepGroup;
    let stepsCheckedArray = stepsChecked;
    const clickedStepType = stepdata.type;
    const itr_set = getIterationSetAndListOfStarts(stepdata);
    if (e.target.checked && itr_set) {
      if (!_selectedStepObj.hasOwnProperty(itr_set)) _selectedStepObj[itr_set] = {};
    }

    if (startNlpList.includes(stepdata.type)) {
      handleStartIterationStepCheck(
        e,
        stepdata,
        clickedStepType,
        index,
        _selectedStepObj,
        listOfIterationSteps,
        stepsForStepGroupArray,
        itr_set,
        selectedStepsForExecution
      );
    } else if (startConditionObject.includes(stepdata?.nlpName) || stepdata?.conditionSearchKey) {
      let stepGroupsArray = [];
      if (stepsForStepGroupArray?.length) {
        stepGroupsArray = stepsForStepGroupArray?.concat(stepGroupsArray);
      }
      checkedIndices = stepsCheckedArray?.length ? checkedIndices?.concat(stepsCheckedArray) : [];
      conditionCheckEvent(e, stepdata, index, stepGroupsArray, _selectedStepObj);
      stepsCheckedArray = [...new Set(checkedIndices)];
      setStepsForStepGroup(stepGroupsArray);
      setStepClicked(stepClicked + 1);
      setStepsChecked(stepsCheckedArray);
      selectedStepsForExecution = [];
      stepGroupsArray.forEach((_stg) => {
        if (_stg.stepId) {
          selectedStepsForExecution.push(_stg.stepId);
        }
      });
      setStepsSelectedForRun([...selectedStepsForExecution]); //for conditions
    } else {
      if (stepdata.isIterationStep || stepdata.isForLoopStep) {
        handleIterationStepCheckbox(
          e,
          stepdata,
          clickedStepType,
          index,
          _selectedStepObj,
          listOfIterationSteps,
          stepsForStepGroupArray,
          itr_set,
          selectedStepsForExecution
        );
      }
      if (e.target.checked) {
        stepdata.stepNumber = index + 1;
        setStepClicked(stepClicked + 1);
        if (!stepsForStepGroupArray?.some((_stg) => _stg?.stepId === stepdata?.stepId)) {
          stepsForStepGroupArray.push(stepdata);
        }
        setStepsForStepGroup(stepsForStepGroupArray);
        stepsCheckedArray.push(index);
      } else {
        stepsForStepGroupArray.forEach((step, index) => {
          if (step.stepId === stepdata.stepId) {
            stepsForStepGroupArray.splice(index, 1);
          }
        });
        setStepsForStepGroup(stepsForStepGroupArray);
        setStepClicked(stepClicked - 1);
        stepsCheckedArray.forEach((data, arrIndex) => {
          if (data === index) {
            stepsCheckedArray.splice(arrIndex, 1);
          }
        });
      }
      setStepsChecked(stepsCheckedArray);
      selectedStepsForExecution = [];
      stepsForStepGroupArray.forEach((_step) => {
        if (_step?.stepId) selectedStepsForExecution.push(_step.stepId);
      });
      setStepsSelectedForRun([...selectedStepsForExecution]); //for iteration step
    }
    let stepCheckboxCount = 0;
    data.forEach((data, index) => {
      if (isEmptyValue(data.isDisabled) || data.isDisabled === false) {
        let stepCheckbox = document.getElementById(`StepCheckbox-${index + 1}`);
        if (stepCheckbox.checked) {
          stepCheckboxCount = stepCheckboxCount + 1;
        }
        setStepBoxCheck(stepCheckboxCount);
      }
    });
    let enabledSteps = data.filter((data) => isEmptyValue(data.isDisabled) || data.isDisabled === false);
    if (stepCheckboxCount > 0) {
      if (stepCheckboxCount === enabledSteps.length) {
        let checkbox = document.getElementById('rootStepsCheckbox');
        if (checkbox) {
          checkbox.indeterminate = false;
          checkbox.checked = true;
        }
        setIsCheckAll(true);
      } else {
        let checkbox = document.getElementById('rootStepsCheckbox');
        if (checkbox) {
          checkbox.checked = false;
          checkbox.indeterminate = true;
        }
      }
    } else {
      let checkbox = document.getElementById('rootStepsCheckbox');
      if (checkbox) {
        checkbox.indeterminate = false;
        checkbox.checked = false;
      }
      setIsCheckAll(false);

      if (preConditionsSelectedForRun.length > 0 || postConditionsSelectedForRun.length > 0) {
        setStepsForStepGroup((selectedData) =>
          selectedData.filter((tempValue) =>
            [...preConditionsSelectedForRun, ...postConditionsSelectedForRun].includes(tempValue.stepId)
          )
        );
      } else {
        setStepsForStepGroup([]);
      }
      setStepClicked(0);
    }
  };
  const uncheckConditionSteps = (event, stepData, stepIndex, endIndex, stepGroupsArray, currentData) => {
    for (let i = stepIndex + 1; i <= endIndex + 1; i++) {
      checkUncheckCondition(event, stepData, true, i);
      if (stepGroupsArray.length > 0) {
        const tempIndex = checkedIndices.indexOf(i);
        if (tempIndex > -1) {
          let tempData = currentData[i - 1];
          checkedIndices.splice(tempIndex, 1);
          if (tempData) {
            let stepGroupIndex = stepGroupsArray.findIndex((obj) => obj.executionOrder === tempData.executionOrder);
            if (stepGroupIndex > -1) {
              stepGroupsArray.splice(stepGroupIndex, 1);
            }
          }
        }
      }
    }
  };

  const conditionCheckEvent = (event, stepData, stepIndex, stepGroupsArray, _selectedStepObj) => {
    let currentData = [];
    Object.assign(currentData, data);
    if (!event?.target?.checked) {
      //unchecked
      if (!startConditionObject.includes(stepData?.nlpName)) {
        const tempIndex = checkedIndices.findIndex((tempObj) => tempObj === stepIndex + 1);
        checkedIndices.splice(tempIndex, 1);
        stepGroupsArray.splice(tempIndex, 1);
      } else {
        //if start condition checkbox clicked
        let endIndex = currentData.findIndex(
          (tempObj) => endConditionObject.includes(tempObj?.nlpName) && tempObj?.conditionId === stepData?.conditionId
        );
        if (['IfCondition'].includes(stepData?.nlpName)) {
          const _filteredData = currentData.filter((_curDat) =>
            _curDat?.conditionSearchKey?.includes(stepData?.conditionId)
          );
          if (_filteredData?.length) {
            _filteredData.forEach((_fd) => {
              if (!startConditionObject.includes(_fd?.nlpName)) {
                const tempIndex = checkedIndices.findIndex((tempObj) => tempObj === stepIndex + 1);
                checkedIndices.splice(tempIndex, 1);
                let stepGroupIndex = stepGroupsArray.findIndex((obj) => obj.executionOrder === _fd.executionOrder);
                if (stepGroupIndex > -1) {
                  stepGroupsArray.splice(stepGroupIndex, 1);
                }
              } else {
                endIndex = currentData.findIndex(
                  (tempObj) =>
                    endConditionObject.includes(tempObj?.nlpName) && tempObj?.conditionId === _fd?.conditionId
                );
                uncheckConditionSteps(event, stepData, stepIndex, endIndex, stepGroupsArray, currentData);
              }
            });
          }
        } else {
          uncheckConditionSteps(event, stepData, stepIndex, endIndex, stepGroupsArray, currentData);
        }
      }
    } else {
      //checked
      conditionBlockIndices(event, stepData, currentData, stepGroupsArray, _selectedStepObj);
    }
    checkedIndices = [...new Set(checkedIndices)];
  };

  const checkUncheckCondition = (event, stepData, indeterminate, index) => {
    let stepCheckbox = document.getElementById(`StepCheckbox-${index}`);
    if (event?.target?.checked) {
      stepCheckbox.checked = true;
      if (!indeterminate) {
        stepCheckbox.indeterminate = true;
      } else {
        stepCheckbox.indeterminate = false;
      }
      if (endConditionObject.includes(stepData?.nlpName)) {
        stepCheckbox.style.visibility = '';
        stepCheckbox.style.opacity = '.5';
      }
    } else {
      stepCheckbox.checked = false;
      stepCheckbox.indeterminate = false;
      if (endConditionObject.includes(stepData?.nlpName)) {
        stepCheckbox.style.visibility = 'hidden';
        stepCheckbox.style.opacity = '1';
      }
    }
  };

  const conditionBlockIndices = (event, stepData, currentData, stepGroupsArray, _selectedStepObj, selectMode) => {
    let _ifCheckedStepIsStartCondition = startConditionObject.includes(stepData?.nlpName);
    currentData.forEach((step, index) => {
      if (_ifCheckedStepIsStartCondition) {
        if (stepData?.nlpName === 'IfCondition') {
          //checked step is start if
          if (step?.nlpName === 'IfCondition') {
            if (step?.conditionId === stepData?.conditionId) {
              //if checked step is same start if step
              checkedIndices?.push(index + 1);
              if (startNlpList.includes(step?.type)) {
                nestedIterationStepCheck(event, step, stepData, index, stepGroupsArray);
              }
              if (!stepGroupsArray?.some((_stg) => _stg?.stepId === step?.stepId)) {
                stepGroupsArray.push(step);
              }
              checkUncheckCondition(event, step, true, index + 1);
            } else {
              const _flag = belongToSameConditionSet(step, stepData);
              if (_flag && step?.conditionSearchKey?.includes(stepData?.conditionId)) {
                conditionBlockIndices(event, step, currentData, stepGroupsArray, _selectedStepObj, 'all');
                checkedIndices.push(index + 1);
                if (!stepGroupsArray?.some((_stg) => _stg?.stepId === step?.stepId)) {
                  stepGroupsArray.push(step);
                }
              }
            }
          } else {
            conditionCheckElse(event, step, stepData, index, stepGroupsArray, selectMode);
          }
        } else {
          //checked step is not start if
          if (step?.conditionId === stepData?.conditionId) {
            //when same step
            checkedIndices.push(index + 1);
            if (startNlpList.includes(step?.type)) {
              nestedIterationStepCheck(event, step, stepData, index, stepGroupsArray);
            }
            if (!stepGroupsArray?.some((_stg) => _stg?.stepId === step?.stepId)) {
              stepGroupsArray.push(step);
            }
            checkUncheckCondition(event, step, true, index + 1);
          } else if (belongToSameConditionSet(stepData, step)) {
            //if checked stepData is child of :step
            if (startConditionObject.includes(step?.nlpName)) {
              conditionBlockIndices(event, step, currentData, stepGroupsArray, _selectedStepObj);
              checkedIndices.push(index + 1);
              if (!stepGroupsArray?.some((_stg) => _stg?.stepId === step?.stepId)) {
                stepGroupsArray.push(step);
              }
            }
          } else if (step?.conditionSearchKey && step?.conditionSearchKey.includes(stepData?.conditionId)) {
            // child start condition of current step
            checkedIndices.push(index + 1);
            if (!stepGroupsArray?.some((_stg) => _stg?.stepId === step?.stepId)) {
              stepGroupsArray.push(step);
            }
            checkUncheckCondition(event, step, true, index + 1);
          }
        }
      } else {
        //if clicked step is non condition child
        if (
          step?.conditionId === stepData?.conditionId &&
          (startConditionObject.includes(step?.nlpName) || endConditionObject.includes(step?.nlpName))
        ) {
          checkedIndices.push(index + 1);
          if (!stepGroupsArray?.some((_stg) => _stg?.stepId === step?.stepId)) {
            stepGroupsArray.push(step);
          }
          checkUncheckCondition(event, step, false, index + 1);
        } else if (step?.stepId === stepData?.stepId) {
          checkedIndices.push(index + 1);
          if (!stepGroupsArray?.some((_stg) => _stg?.stepId === step?.stepId)) {
            stepGroupsArray.push(step);
          }
          checkUncheckCondition(event, step, true, index + 1);
        }
      }
    });
    const _uniqueIndicesArray = [...new Set(checkedIndices)];
    setCheckedIndices(_uniqueIndicesArray);
  };

  const conditionCheckElse = (event, step, stepData, index, stepGroupsArray, selectMode) => {
    if (step?.conditionId === stepData?.conditionId) {
      //if the step is child of If condition
      checkedIndices.push(index + 1);
      if (startNlpList.includes(step?.type)) {
        nestedIterationStepCheck(event, step, stepData, index, stepGroupsArray);
      }
      if (!stepGroupsArray?.some((_stg) => _stg?.stepId === step?.stepId)) {
        stepGroupsArray.push(step);
      }
      checkUncheckCondition(event, step, true, index + 1);
    } else if (step?.conditionSearchKey?.includes(stepData?.conditionId) && selectMode === 'all') {
      checkedIndices.push(index + 1);
      if (startNlpList.includes(step?.type)) {
        nestedIterationStepCheck(event, step, stepData, index, stepGroupsArray);
      }
      if (!stepGroupsArray?.some((_stg) => _stg?.stepId === step?.stepId)) {
        stepGroupsArray.push(step);
      }
      checkUncheckCondition(event, step, true, index + 1);
    }
  };

  const belongToSameConditionSet = (step, stepData) => {
    const _splittedArr = step?.conditionSearchKey?.split('/');
    const _parentConditionId = _splittedArr?.at(-2);
    return _parentConditionId && _parentConditionId === stepData?.conditionId;
  };

  const nestedIterationStepCheck = (event, step, stepData, index, stepGroupsArray) => {
    let selectedStepsForExecution = [...stepsSelectedForRun];
    let _selectedStepObj = selectedStepObjects;
    const listOfIterationSteps = getListOfIterationSteps(step);
    const clickedStepType = step.type;
    const itr_set = getIterationSetAndListOfStarts(step);
    if (event.target.checked && itr_set) {
      if (!_selectedStepObj.hasOwnProperty(itr_set)) _selectedStepObj[itr_set] = {};
    }
    handleStartIterationStepCheck(
      event,
      step,
      clickedStepType,
      index,
      _selectedStepObj,
      listOfIterationSteps,
      stepGroupsArray,
      itr_set,
      selectedStepsForExecution
    );
  };
  const unCheckSelectedConditionsCheckbox = () => {
    let stepsForStepGroupArray = [...stepsForStepGroup];
    let totalChecked = stepClicked;

    if (dependScriptData.length) {
      setIsCheckAllPreCondition(false);
      dependScriptData.forEach((data, index) => {
        let stepCheckbox = document.getElementById(`dependscriptcheckbox-${index + 1}`);
        if (stepCheckbox?.checked) {
          totalChecked = totalChecked - 1;
          stepCheckbox.checked = false;
        }
      });
      stepsForStepGroupArray = stepsForStepGroupArray.filter((stps) => stps.type !== 'DEPENDSONSCRIPT');
      setStepClicked(totalChecked);
      setPreConditionChecked([]);
      if (document.getElementById('rootDependsOnScriptCheckbox')) {
        document.getElementById('rootDependsOnScriptCheckbox').indeterminate = false;
        document.getElementById('rootDependsOnScriptCheckbox').checked = false;
      }
    }

    if (preConditionData.length) {
      setIsCheckAllPreCondition(false);
      preConditionData.forEach((data, index) => {
        let stepCheckbox = document.getElementById(`preconditioncheckbox-${index + 1}`);
        if (stepCheckbox?.checked) {
          totalChecked = totalChecked - 1;
          stepCheckbox.checked = false;
        }
      });
      stepsForStepGroupArray = stepsForStepGroupArray.filter((stps) => stps.type !== 'PRE');
      setStepClicked(totalChecked);
      setPreConditionChecked([]);
      if (document.getElementById('rootPreConditionCheckbox')) {
        document.getElementById('rootPreConditionCheckbox').indeterminate = false;
        document.getElementById('rootPreConditionCheckbox').checked = false;
      }
    }

    if (postConditionData.length) {
      setIsCheckAllPostCondition(false);
      postConditionData.forEach((data, index) => {
        let stepCheckbox = document.getElementById(`postconditioncheckbox-${index + 1}`);
        if (stepCheckbox?.checked) {
          totalChecked = totalChecked - 1;
          stepCheckbox.checked = false;
        }
      });
      stepsForStepGroupArray = stepsForStepGroupArray.filter((stps) => stps.type !== 'POST');
      setPostConditionChecked([]);
      if (document.getElementById('rootPostConditionCheckbox')) {
        document.getElementById('rootPostConditionCheckbox').indeterminate = false;
        document.getElementById('rootPostConditionCheckbox').checked = false;
      }
    }
    setStepsForStepGroup(stepsForStepGroupArray);
    setStepClicked(totalChecked);
  };
  const uncheckSelectedCheckbox = () => {
    unCheckSelectedConditionsCheckbox();
    setIsCheckAll(false);
    setIsCheckAllSteps(false);
    data.forEach((data, index) => {
      let stepCheckbox = document.getElementById(`StepCheckbox-${index + 1}`);
      if (stepCheckbox) {
        stepCheckbox.checked = false;
      }

      if (stepCheckbox && endNlpList.includes(data.type)) {
        stepCheckbox.style.visibility = 'hidden';
        stepCheckbox.style.opacity = '1';
      }
    });
    let stepCheckbox = document.getElementById(`rootStepsCheckbox`);
    if (stepCheckbox) {
      stepCheckbox.checked = false;
      stepCheckbox.indeterminate = false;
    }
    setStepClicked(0);
    setStepsChecked([]);
    setCheckedIndices([]);
    setStepsSelectedForRun([]);
    setStepsForStepGroup([]);
  };
  const runUntil = (runIndex, type) => {
    const depScriptForRun = [];
    const preConditionsForRun = [];
    const stepsForRun = [];
    const postConditionsForRun = [];
    if (type === 'dependscript') {
      let dependentScriptCheckedArray = [];
      for (let index = 0; index <= runIndex; index++) {
        dependentScriptCheckedArray.push(index);
        depScriptForRun.push(dependScriptData[index].stepId);
      }
      setDependentScriptChecked(dependentScriptCheckedArray);
      setDependsOnScriptsSelectedForRun([...depScriptForRun]);
    } else if (type === 'precondition') {
      let dependentScriptCheckedArray = [];
      for (let index = 0; index < dependScriptData.length; index++) {
        dependentScriptCheckedArray.push(index);
        depScriptForRun.push(dependScriptData[index].stepId);
      }
      setDependentScriptChecked(dependentScriptCheckedArray);
      setDependsOnScriptsSelectedForRun([...depScriptForRun]);

      let preConditionCheckedArray = [];
      for (let index = 0; index <= runIndex; index++) {
        preConditionCheckedArray.push(index);
        preConditionsForRun.push(preConditionData[index].stepId);
      }
      setPreConditionChecked(preConditionCheckedArray);
      setPreConditionsSelectedForRun([...preConditionsForRun]);
    } else if (type === 'steps') {
      let dependentScriptCheckedArray = [];
      for (let index = 0; index < dependScriptData.length; index++) {
        dependentScriptCheckedArray.push(index);
        depScriptForRun.push(dependScriptData[index].stepId);
      }
      setDependentScriptChecked(dependentScriptCheckedArray);
      setStepsSelectedForRun(depScriptForRun);

      let preConditionCheckedArray = [];
      for (let index = 0; index < preConditionData.length; index++) {
        preConditionCheckedArray.push(index);
        preConditionsForRun.push(preConditionData[index].stepId);
      }
      setPreConditionChecked(preConditionCheckedArray);
      setPreConditionsSelectedForRun([...preConditionsForRun]);

      let stepsCheckedArray = [];
      for (let index = 0; index <= runIndex; index++) {
        stepsCheckedArray.push(index);
        stepsForRun.push(data[index].stepId);
      }
      setStepsChecked(stepsCheckedArray);
      setStepsSelectedForRun([...stepsForRun]);
    } else if (type === 'postcondition') {
      let dependentScriptCheckedArray = [];
      for (let index = 0; index < dependScriptData.length; index++) {
        dependentScriptCheckedArray.push(index);
        depScriptForRun.push(dependScriptData[index].stepId);
      }
      setDependentScriptChecked(dependentScriptCheckedArray);
      setDependsOnScriptsSelectedForRun([...depScriptForRun]);

      let preConditionCheckedArray = [];
      for (let index = 0; index < preConditionData.length; index++) {
        preConditionCheckedArray.push(index);
        preConditionsForRun.push(preConditionData[index].stepId);
      }
      setPreConditionChecked(preConditionCheckedArray);
      setPreConditionsSelectedForRun([...preConditionsForRun]);

      let stepsCheckedArray = [];
      for (let index = 0; index < data.length; index++) {
        stepsCheckedArray.push(index);
        stepsForRun.push(data[index].stepId);
      }
      setStepsChecked(stepsCheckedArray);
      setStepsSelectedForRun([...stepsForRun]);

      let postConditionCheckedArray = [];
      for (let index = 0; index <= runIndex; index++) {
        postConditionCheckedArray.push(index);
        postConditionsForRun.push(postConditionData[index].stepId);
      }
      setPostConditionChecked(postConditionCheckedArray);
      setPostConditionsSelectedForRun([...postConditionsForRun]);
    }
    runExecution(true, depScriptForRun, preConditionsForRun, stepsForRun, postConditionsForRun);
  };
  const checkUncheckAllDependsOnScripts = () => {
    if (isCheckAllDependsOnScripts === false) {
      setIsCheckAllDependsOnScripts(true);
      let dependentScriptCheckedArray = [...dependentScriptChecked];
      const selectedDependsOnScriptsForRun = [];
      dependScriptData.forEach((depScr, index) => {
        selectedDependsOnScriptsForRun.push(depScr.stepId);
        let dependsOnScriptCheckbox = document.getElementById(`dependscriptcheckbox-${index + 1}`);
        if (dependsOnScriptCheckbox && !dependsOnScriptCheckbox.checked) {
          dependsOnScriptCheckbox.checked = true;
          dependentScriptCheckedArray.push(index);
        }
      });
      setDependentScriptChecked(dependentScriptCheckedArray);
      setDependsOnScriptsSelectedForRun([...selectedDependsOnScriptsForRun]);
      if (document.getElementById('rootDependsOnScriptCheckbox')) {
        document.getElementById('rootDependsOnScriptCheckbox').indeterminate = false;
        document.getElementById('rootDependsOnScriptCheckbox').checked = true;
      }
    } else {
      setIsCheckAllDependsOnScripts(false);
      dependScriptData.forEach((data, index) => {
        let dependsOnCheckbox = document.getElementById(`dependscriptcheckbox-${index + 1}`);
        if (dependsOnCheckbox?.checked) {
          dependsOnCheckbox.checked = false;
        }
      });
      setDependentScriptChecked([]);
      setDependsOnScriptsSelectedForRun([]);
      setCheckedIndices([]);
      if (document.getElementById('rootDependsOnScriptCheckbox')) {
        document.getElementById('rootDependsOnScriptCheckbox').indeterminate = false;
        document.getElementById('rootDependsOnScriptCheckbox').checked = false;
      }
    }
  };
  const checkUncheckAllPreConditions = () => {
    const selectedPreConditions = [...preConditionChecked];
    const selectedPreConditionsForRun = [];
    let stepsForStepGroupArray = [...stepsForStepGroup];
    if (isCheckAllPreCondition === false) {
      setIsCheckAllPreCondition(true);
      let totalChecked = stepClicked;
      preConditionData.forEach((cdn, index) => {
        selectedPreConditionsForRun.push(cdn.stepId);
        let conditionCheckbox = document.getElementById(`preconditioncheckbox-${index + 1}`);
        if (conditionCheckbox && !conditionCheckbox.checked) {
          totalChecked = totalChecked + 1;
          conditionCheckbox.checked = true;
          if (!stepsForStepGroupArray?.some((_stg) => _stg?.stepId === cdn?.stepId)) {
            stepsForStepGroupArray.push(cdn);
          }
          selectedPreConditions.push(index);
        }
      });
      setStepsForStepGroup(stepsForStepGroupArray);
      setStepClicked(totalChecked);
      setPreConditionChecked(selectedPreConditions);
      setPreConditionsSelectedForRun([...selectedPreConditionsForRun]);
      if (document.getElementById('rootPreConditionCheckbox')) {
        document.getElementById('rootPreConditionCheckbox').indeterminate = false;
        document.getElementById('rootPreConditionCheckbox').checked = true;
      }
    } else {
      setIsCheckAllPreCondition(false);
      let totalChecked = stepClicked;
      preConditionData.forEach((data, index) => {
        let stepCheckbox = document.getElementById(`preconditioncheckbox-${index + 1}`);
        if (stepCheckbox?.checked) {
          totalChecked = totalChecked - 1;
          stepCheckbox.checked = false;
        }
      });
      const updatedStepGrpArray = stepsForStepGroupArray.filter((stps) => stps.type !== 'PRE');
      setStepsForStepGroup(updatedStepGrpArray);
      setStepClicked(totalChecked);
      setPreConditionChecked([]);
      setPreConditionsSelectedForRun([]);
      if (document.getElementById('rootPreConditionCheckbox')) {
        document.getElementById('rootPreConditionCheckbox').indeterminate = false;
        document.getElementById('rootPreConditionCheckbox').checked = false;
      }
    }
  };
  const checkUncheckAllSteps = () => {
    const _selectedStepObj = {};
    const selectedSteps = [...stepsChecked];
    const selectedStepsForRun = [];
    let stepsForStepGroupArray = [...stepsForStepGroup];
    if (isCheckAllSteps === false) {
      setIsCheckAllSteps(true);
      let totalChecked = stepClicked;
      data.forEach((cdn, index) => {
        selectedStepsForRun.push(cdn.stepId);
        let stepCheckbox = document.getElementById(`StepCheckbox-${index + 1}`);
        if (stepCheckbox && !stepCheckbox.checked) {
          totalChecked = totalChecked + 1;
          cdn.stepNumber = index + 1;
          stepCheckbox.checked = true;
          if (stepCheckbox.indeterminate) {
            stepCheckbox.indeterminate = false;
          }
          if (!stepsForStepGroupArray?.some((_stg) => _stg?.stepId === cdn?.stepId)) {
            stepsForStepGroupArray.push(cdn);
          }
          selectedSteps.push(index);
          if ((startNlpList.includes(cdn.type) || cdn.parentSpecialNlpId) && !endNlpList.includes(cdn.type)) {
            let itr_set = getIterationSetAndListOfStarts(cdn);
            setSelectedStepsObjOnCheckAll(true, cdn, index, stepsForStepGroupArray, itr_set);
          }
          if (endNlpList.includes(cdn.type)) {
            stepCheckbox.style.visibility = '';
            stepCheckbox.style.opacity = '.5';
          }
        }
      });
      setStepsForStepGroup(stepsForStepGroupArray);
      setStepClicked(totalChecked);
      setStepsChecked(selectedSteps);
      setStepsSelectedForRun([...selectedStepsForRun]);
      setStepBoxCheck(0);
      if (document.getElementById('rootStepsCheckbox')) {
        document.getElementById('rootStepsCheckbox').indeterminate = false;
        document.getElementById('rootStepsCheckbox').checked = true;
      }
    } else {
      setIsCheckAllSteps(false);
      let totalChecked = stepClicked;
      setSelectedStepObjects(_selectedStepObj);
      data.forEach((_data, index) => {
        let stepCheckbox = document.getElementById(`StepCheckbox-${index + 1}`);
        if (stepCheckbox?.checked) {
          totalChecked = totalChecked - 1;
          stepCheckbox.checked = false;
          if (stepCheckbox.indeterminate) {
            stepCheckbox.indeterminate = false;
          }
          if (endNlpList.includes(_data.type)) {
            stepCheckbox.style.visibility = 'hidden';
            stepCheckbox.style.opacity = '1';
          }
        }
      });
      const updatedStepGrpArray = stepsForStepGroupArray.filter(
        (stps) => !['Group', 'step', ...excludedNlpTypes].includes(stps.type)
      );
      setStepsForStepGroup(updatedStepGrpArray);
      setStepClicked(totalChecked);
      setStepsChecked([]);
      setCheckedIndices([]);
      setStepsSelectedForRun([]);
      if (document.getElementById('rootStepsCheckbox')) {
        document.getElementById('rootStepsCheckbox').indeterminate = false;
        document.getElementById('rootStepsCheckbox').checked = false;
      }
    }
    setStepClicked(0);
  };
  const checkUncheckAllPostConditions = () => {
    const selectedPostConditions = [...postConditionChecked];
    const selectedPostConditionsForRun = [];
    let stepsForStepGroupArray = [...stepsForStepGroup];
    if (isCheckAllPostCondition === false) {
      setIsCheckAllPostCondition(true);
      let totalChecked = stepClicked;
      postConditionData.forEach((cdn, index) => {
        selectedPostConditionsForRun.push(cdn.stepId);
        let conditionCheckbox = document.getElementById(`postconditioncheckbox-${index + 1}`);
        if (conditionCheckbox && !conditionCheckbox.checked) {
          totalChecked = totalChecked + 1;
          conditionCheckbox.checked = true;
          if (!stepsForStepGroupArray?.some((_stg) => _stg?.stepId === cdn?.stepId)) {
            stepsForStepGroupArray.push(cdn);
          }
          selectedPostConditions.push(index);
        }
      });
      setStepsForStepGroup(stepsForStepGroupArray);
      setStepClicked(totalChecked);
      setPostConditionChecked(selectedPostConditions);
      setPostConditionsSelectedForRun([...selectedPostConditionsForRun]);
      if (document.getElementById('rootPostConditionCheckbox')) {
        document.getElementById('rootPostConditionCheckbox').indeterminate = false;
        document.getElementById('rootPostConditionCheckbox').checked = true;
      }
    } else {
      setIsCheckAllPostCondition(false);
      let totalChecked = stepClicked;
      postConditionData.forEach((data, index) => {
        let stepCheckbox = document.getElementById(`postconditioncheckbox-${index + 1}`);
        if (stepCheckbox?.checked) {
          totalChecked = totalChecked - 1;
          stepCheckbox.checked = false;
        }
      });
      const updatedStepGrpArray = stepsForStepGroupArray.filter((stps) => stps.type !== 'POST');
      setStepsForStepGroup(updatedStepGrpArray);
      setStepClicked(totalChecked);
      setPostConditionChecked([]);
      setPostConditionsSelectedForRun([]);
      if (document.getElementById('rootPostConditionCheckbox')) {
        document.getElementById('rootPostConditionCheckbox').indeterminate = false;
        document.getElementById('rootPostConditionCheckbox').checked = false;
      }
    }
  };
  const setSelectedStepsObjOnCheckAll = (checked, stepdata, index, stepsForStepGroupArray, itr_set) => {
    const _selectedStepObj = selectedStepObjects;
    if (checked && itr_set) {
      if (!_selectedStepObj.hasOwnProperty(itr_set)) _selectedStepObj[itr_set] = {};
    }
    if (startNlpList.includes(stepdata.type)) {
      if (
        stepdata.hasOwnProperty('parentSpecialNlpId') &&
        _selectedStepObj[itr_set][stepdata.parentSpecialNlpId] &&
        _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].length
      ) {
        if (checked) {
          _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].push(stepdata);
          _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].push(
            data.find((_data) => _data.specialNlpId === stepdata.specialNlpId && endNlpList.includes(_data.type))
          );
        } else {
          _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].forEach((step, _ind) => {
            if (step.stepId === stepdata.stepId) {
              _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].splice(_ind, 1);
              _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].splice(
                _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].findIndex(
                  (_data) => _data.specialNlpId === stepdata.specialNlpId && endNlpList.includes(_data.type)
                ),
                1
              );
            }
          });
        }
      } else {
        if (checked) {
          _selectedStepObj[itr_set]['root'] = [stepdata];
          _selectedStepObj[itr_set]['root'].push(
            data.find((_data) => _data.specialNlpId === stepdata.specialNlpId && endNlpList.includes(_data.type))
          );
        } else {
          _selectedStepObj[itr_set]['root'].forEach((step, _ind) => {
            if (step.stepId === stepdata.stepId) {
              _selectedStepObj[itr_set]['root'].splice(_ind, 1);
              _selectedStepObj[itr_set]['root'].splice(
                _selectedStepObj[itr_set]['root'].findIndex(
                  (_data) => _data.specialNlpId === stepdata.specialNlpId && endNlpList.includes(_data.type)
                ),
                1
              );
            }
          });
        }
      }
    } else {
      //for iteration steps
      if (
        _selectedStepObj.hasOwnProperty(itr_set) &&
        _selectedStepObj[itr_set][stepdata.parentSpecialNlpId] &&
        _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].length
      ) {
        if (checked) {
          _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].push(stepdata);
        } else {
          _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].forEach((step, _ind) => {
            if (step.stepId === stepdata.stepId) {
              _selectedStepObj[itr_set][stepdata.parentSpecialNlpId].splice(_ind, 1);
            }
          });
        }
      } else {
        _selectedStepObj[itr_set][stepdata.parentSpecialNlpId] = [stepdata];
      }
    }
    setSelectedStepObjects(_selectedStepObj);
  };
  const checkUncheckAll = () => {
    if (isCheckAll === false) {
      let stepsForStepGroupArray = [];
      setIsCheckAll(true);
      let totalChecked = 0;
      data.forEach((data, index) => {
        let stepCheckbox = document.getElementById(`StepCheckbox-${index + 1}`);
        if (stepCheckbox) {
          totalChecked = totalChecked + 1;
          stepCheckbox.checked = true;
          data.stepNumber = index + 1;
          if (!stepsForStepGroupArray?.some((_stg) => _stg?.stepId === data?.stepId)) {
            stepsForStepGroupArray.push(data);
          }
        }
      });
      setStepsForStepGroup(stepsForStepGroupArray);
      setStepClicked(totalChecked);
      if (document.getElementById('rootStepsCheckbox')) {
        document.getElementById('rootStepsCheckbox').checked = true;
      }
    } else {
      setIsCheckAll(false);
      let totalChecked = stepClicked;
      data.forEach((data, index) => {
        let stepCheckbox = document.getElementById(`StepCheckbox-${index + 1}`);
        if (stepCheckbox) {
          totalChecked = totalChecked - 1;
          stepCheckbox.checked = false;
        }
      });
      setStepsForStepGroup([]);
      setStepClicked(0);
      if (document.getElementById('rootStepsCheckbox')) {
        document.getElementById('rootStepsCheckbox').checked = false;
      }
    }
  };

  function expandCollapse(section, arrowType) {
    if (arrowType === 'rightarrow') {
      document.getElementById(section + '-' + arrowType).style.display = 'none';
      document.getElementById(section + '-downarrow').style.display = 'block';
      if (section === 'dependscript') {
        dependScriptData.forEach((data, index) => {
          document.getElementById(section + '-' + index).style.display = 'table-row';
        });
      } else if (section === 'precondition') {
        setShowPrePostConditionTableData({
          ...showPrePostConditionTableData,
          showPreConditionTableData: true,
        });
        preConditionData.forEach((data, index) => {
          document.getElementById(section + '-' + index).style.display = 'table-row';
        });
      } else if (section === 'steps') {
        data.forEach((data, index) => {
          document.getElementById(section + '-' + index).style.display = 'table-row';
        });
      } else if (section === 'postcondition') {
        setShowPrePostConditionTableData({
          ...showPrePostConditionTableData,
          showPostConditionTableData: true,
        });
        postConditionData.forEach((data, index) => {
          document.getElementById(section + '-' + index).style.display = 'table-row';
        });
      }
    } else if (arrowType === 'downarrow') {
      document.getElementById(section + '-' + arrowType).style.display = 'none';
      document.getElementById(section + '-rightarrow').style.display = 'block';
      if (section === 'dependscript') {
        dependScriptData.forEach((data, index) => {
          document.getElementById(section + '-' + index).style.display = 'none';
        });
      } else if (section === 'precondition') {
        preConditionData.forEach((data, index) => {
          document.getElementById(section + '-' + index).style.display = 'none';
        });
      } else if (section === 'steps') {
        data.forEach((data, index) => {
          document.getElementById(section + '-' + index).style.display = 'none';
        });
      } else if (section === 'postcondition') {
        postConditionData.forEach((data, index) => {
          document.getElementById(section + '-' + index).style.display = 'none';
        });
      }
    }
  }

  const [openRunSettings, setOpenRunSettings] = useState({
    openModal: false,
    title: '',
  });
  const runSettings = (modalTitle = 'Quick Run Settings') => {
    if (selectedDefaultValue) {
      props.MyAlert.info(
        `There are no machines associated with user ${user.name}.  Download and install the client from Environment configuration.`
      );
      setRunFlag(true);
    } else {
      getBrowserStackList().then((response) => {
        if (response.data.responseCode === 200) {
          setBrowserInstanceArray(response.data.responseObject);
        }
        getSauceLabsList().then((res) => {
          if (res.data.responseCode === 200) {
            setSauceLabInstanceArray(res.data.responseObject);
          }
          getAllLambdaTestsList().then((res) => {
            if (res.data.responseCode === 200) {
              setLambdaTestsInstanceArray(res.data.responseObject);
              setOpenRunSettings({ openModal: true, title: modalTitle });
            }
          });
        });
      });
    }
  };

  const closeRunSettings = (val) => {
    setRunFlag(true);
    setOpenRunSettings({ openModal: false, title: '' });
  };

  const moduleObject = (title, key, children) => {
    const obj = {
      title: title,
      folder: true,
      type: 'Module',
      key: key,
      children: [children],
    };
    return obj;
  };

  const createParentTree = (data, tree) => {
    const parent = data.path.split('/');
    parent.pop();
    parent.shift();
    const parentKey = data.searchKey.split('/');
    parentKey.pop();
    parentKey.shift();
    let completeTree = tree;
    for (let i = parent.length - 1; i >= 0; i--) {
      if (i === 0) {
        completeTree = moduleObject('Root Module', parentKey[i], completeTree);
      } else {
        completeTree = moduleObject(parent[i], parentKey[i], completeTree);
      }
    }
    return [completeTree];
  };
  const runExecution = async (
    isRunUntill = false,
    runUntillDependsOnScripts = [],
    runUntillPreConditions = [],
    runUntillSteps = [],
    runUntillPostConditions = [],
    runFromQrs = {},
    deleteSpillOverMemory = false
  ) => {
    if (selectedDefaultMachine || runFromQrs?.defaultMachine) {
      setRunFlag(false);
      const newRunConfigData = {};
      if (Object.keys(runFromQrs).length) {
        newRunConfigData['selectedMachine'] = runFromQrs?.defaultMachine;
        newRunConfigData['results'] = runFromQrs?.results;
        const {
          implicitWait,
          explicitWait: explicitlyWait,
          delayBetweenSteps,
          implicitWaitUnit,
          explicitWaitUnit: explicitlyWaitUnit,
          delayBetweenStepsUnit,
        } = runFromQrs?.waitConfiguration || {};
        newRunConfigData['waitTime'] = {
          implicitWait,
          explicitlyWait,
          delayBetweenSteps,
          implicitWaitUnit,
          explicitlyWaitUnit,
          delayBetweenStepsUnit,
        };
      } else {
        newRunConfigData['selectedMachine'] = selectedDefaultMachine?.defaultMachine;
        newRunConfigData['results'] = selectedDefaultMachine?.results;
        const {
          implicitWait,
          explicitWait: explicitlyWait,
          delayBetweenSteps,
          implicitWaitUnit,
          explicitWaitUnit: explicitlyWaitUnit,
          delayBetweenStepsUnit,
        } = selectedDefaultMachine?.waitConfiguration || {};
        newRunConfigData['waitTime'] = {
          implicitWait,
          explicitlyWait,
          delayBetweenSteps,
          implicitWaitUnit,
          explicitlyWaitUnit,
          delayBetweenStepsUnit,
        };
      }
      let browserInfo = newRunConfigData?.selectedMachine?.systemConfigDetails.filter(
        (data) => data.subType === 'browser'
      );
      let deviceInfo = newRunConfigData?.selectedMachine?.systemConfigDetails.filter(
        (data) => data.subType === 'device'
      );
      let screenshot = newRunConfigData?.results?.captureScreenShotStatus;
      let screenshotStatus = false;
      if (screenshot === 'failedSteps' || screenshot === 'allSteps') {
        screenshotStatus = true;
      }
      const modTree = {
        ver: scripts?.ver,
        scriptType: scripts?.scriptType,
        title: scripts?.prefix + ' - ' + scripts?.name,
        type: scripts?.type ? scripts.type : 'Script',
        stepCount: scripts?.stepCount,
        key: scripts?.id,
        parentId: scripts?.parentId,
        scriptExecutionDetails: {
          // contains selected stepId's
          dependentScript: isRunUntill ? [...runUntillDependsOnScripts] : [...dependsOnScriptsSelectedForRun],
          preCondition: isRunUntill ? [...runUntillPreConditions] : [...preConditionsSelectedForRun],
          postCondition: isRunUntill ? [...runUntillPostConditions] : [...postConditionsSelectedForRun],
          steps: isRunUntill ? [...runUntillSteps] : [...stepsSelectedForRun],
        },
      };
      /**********************condition for run all*************************/
      if (
        !isRunUntill &&
        !dependsOnScriptsSelectedForRun.length &&
        !preConditionsSelectedForRun.length &&
        !postConditionsSelectedForRun.length &&
        !stepsSelectedForRun.length
      ) {
        delete modTree.scriptExecutionDetails;
      }
      /**********************condition for run all*************************/
      const execTree = createParentTree({ searchKey: scripts.searchKey, path: scripts.path }, modTree);
      let executionJson = {
        name: scriptName || scripts?.name,
        installedDir: 'D:\\resources\\local',
        screenshotRequired: screenshotStatus,
        failedStepsScreenshot: screenshot === 'failedSteps',
        videoRequired: ['failedSteps', 'allSteps'].includes(newRunConfigData?.results?.captureVideoStatus)
          ? true
          : false,
        failedScriptVideoRequired: ['notCapture', 'allSteps'].includes(newRunConfigData?.results?.captureVideoStatus)
          ? false
          : true,
        waitTime: {
          ...newRunConfigData.waitTime,
        },
        executionTree: execTree,
        projectName: project?.name,
        projectId: project?.id,
        type: 'INDIVIDUAL',
        scriptRunDetails: {
          scriptId: scriptId,
        },
        selectedSystem: [
          {
            clientId: newRunConfigData?.selectedMachine?.clientId,
            machineInstances: [
              {
                clientId: newRunConfigData?.selectedMachine?.clientId,
                executionEnv: newRunConfigData?.selectedMachine?.executionEnvironment,
                browserName: browserInfo.length ? getBrowserName(browserInfo[0]?.name) : null,
                numberOfRuns: 0,
                version: browserInfo.length ? browserInfo[0]?.version : null,
                systemUrl: newRunConfigData?.selectedMachine?.machine?.hostName,
                browserInfo: browserInfo.length ? { ...browserInfo[0] } : null,
                deviceInfo: [...deviceInfo],
                headless: disableHeadlessSwitch
                  ? false
                  : newRunConfigData?.selectedMachine?.headless
                  ? newRunConfigData.selectedMachine.headless
                  : false,
                machineInfo: {
                  ...newRunConfigData?.selectedMachine?.machine,
                },
              },
            ],
            executionEnv: newRunConfigData?.selectedMachine?.executionEnvironment,
            browserName: browserInfo?.length ? getBrowserName(browserInfo[0]?.name) : null,
            version: browserInfo.length ? browserInfo[0]?.version : null,
            systemUrl: newRunConfigData?.selectedMachine?.machine?.hostName,
            browserInfo: browserInfo.length ? { ...browserInfo[0] } : null,
            deviceInfo: [...deviceInfo],
            headless: disableHeadlessSwitch
              ? false
              : newRunConfigData?.selectedMachine?.headless
              ? newRunConfigData.selectedMachine.headless
              : false,
            machineInfo: {
              ...newRunConfigData?.selectedMachine?.machine,
            },
          },
        ],
        deleteSpillOverMemory,
      };
      const executionResponse = await createExecutionReq(executionJson);
      if (executionResponse && executionResponse.data && executionResponse.data.responseObject) {
        history.push(`/testdevelopment/Execution Dashboard`);
        setEnableSyncButton(true);
      } else if (
        executionResponse?.data?.message &&
        executionResponse?.data?.message.toLowerCase().includes('please connect a device')
      ) {
        props.MyAlert.info(executionResponse?.data?.message);
      } else if (
        executionResponse?.data?.message &&
        executionResponse?.data?.message.toLowerCase().includes('please install a browser')
      ) {
        props.MyAlert.info(executionResponse?.data?.message);
      } else if (
        executionResponse?.data?.message &&
        executionResponse?.data?.message.startsWith('Client') &&
        executionResponse?.data?.message.toLowerCase().includes('is not available')
      ) {
        props.MyAlert.info(executionResponse?.data?.message);
      } else if (executionResponse.data && executionResponse.data.responseCode === 400) {
        props.MyAlert.warning(`${executionResponse.data.message}`);
      } else if (executionResponse.data && executionResponse.data.status === 'FAILURE') {
        props.MyAlert.info(executionResponse?.data?.message);
      } else if (executionResponse?.data?.message === 'Consumed Memory is above 85%') {
        setQRSData({
          isRunUntill,
          runUntillDependsOnScripts,
          runUntillPreConditions,
          runUntillSteps,
          runUntillPostConditions,
          runFromQrs,
        });
        setMemoryWarningPopup(true);
      }
      setRunFlag(true);
      setStepsSelectedForRun([]);
      setDependsOnScriptsSelectedForRun([]);
      setPreConditionsSelectedForRun([]);
      setPostConditionsSelectedForRun([]);
      setCheckedIndices([]);
      setDependentScriptChecked([]);
      setPreConditionChecked([]);
      setPostConditionChecked([]);
      setStepsChecked([]);
      setCheckedIndices([]);
      uncheckSelectedCheckbox();
    } else {
      runSettings('Select System');
    }
  };

  const getStepGroup = () => {
    if (stepGroupId && libraryId) {
      getStepGroupReq(libraryId, stepGroupId).then((response) => {
        if (response.data.responseObject) {
          setStepGroup(response.data.responseObject);
        }
      });
    }
  };
  const setOfStepsWithStepGroup = (response) => {
    let steps = response?.steps;
    setArrayofReturnValues([]);
    let array = [];
    if (steps?.length > 0) {
      steps?.forEach((step, index) => {
        if (step?.returnType !== 'void') {
          if (step?.stepReferenceInfo) {
            let name = `${step.stepReferenceInfo.name}: Step${index + 1}`;
            let data = {
              name: name,
              value: name,
              type: step.stepReferenceInfo.type,
              referenceId: step.stepReferenceInfo.referenceId,
            };
            array.push(data);
          }
        }
      });
      setArrayofReturnValues(array);
      if (array.length === 0) {
        setDisableDropDown(true);
        setSelectedReturnTypeValue('void');
      } else if (defaultStepGroup) {
        if (defaultStepGroup === 'false') {
          setDisableDropDown(false);
        } else {
          setDisableDropDown(true);
        }
      }
    } else {
      setDisableDropDown(true);
    }
  };

  const closeStepModal = (val) => {
    setOpenStepModal(val);
  };

  const getParameterList = () => {
    const search = window.location.search;
    const id = new URLSearchParams(search).get('id');
    const parentId = new URLSearchParams(search).get('parentId');
    if (id && parentId) {
      gellAllParameter(parentId, id).then((res) => {
        if (res.data && res.data.responseObject) {
          setStepGroupParameterData(res.data.responseObject);
          setfilterParameterData(res.data.responseObject);
        }
      });
    }
  };

  const setReturnValueToStepGroup = (value) => {
    stepGroup.steps = data;
    stepGroup.parameters = null;
    if (value === 'void') {
      if (stepGroup.stepGroupReferenceInfo) {
        delete stepGroup.stepGroupReferenceInfo;
        delete stepGroup.steps;
      }
      stepGroup.returnType = 'void';
      updateStepGroupReq(libraryId, stepGroupId, 'UPDATE', stepGroup).then((response) => {
        if (response.data.responseCode === 200 && response.data.status === 'SUCCESS') {
          setSelectedReturnTypeValue('void');
          setDisableDropDown(true);
          reloadPreConditions(true);
        }
      });
    } else {
      const [variableName, selectedStep] = value?.name?.split(': ');
      let stepGroupReferenceInfo = {
        stepNumber: parseInt(selectedStep.slice(4)),
        type: value.type ? value.type : 'STEPGROUP',
        name: variableName,
        referenceId: value.referenceId,
      };
      stepGroup.stepGroupReferenceInfo = stepGroupReferenceInfo;
      stepGroup.returnType = value?.name;
      stepGroup.parameters = null;
      delete stepGroup.steps;

      updateStepGroupReq(libraryId, stepGroupId, 'UPDATE', stepGroup).then((response) => {
        if (response.data.responseCode === 200 && response.data.status === 'SUCCESS') {
          reloadPreConditions(true);
        }
      });
    }
  };

  const searchParameter = (event) => {
    let data = event.target.value;
    setRefData(data);
    let x = [];
    x = stepGroupParameterData.filter((element) => element.name.toLowerCase().includes(data?.toLowerCase()));
    setfilterParameterData(x);
  };

  const textColor = (status, type) => {
    if (type === 'badge') {
      switch (status) {
        case 'PASS':
          return 'result-badge-passed';
        case 'FAIL':
          return 'result-badge-failed';
        case 'WARNING':
          return 'result-badge-warning';
        case 'SKIP':
          return 'result-badge-skipped';
        case 'Aborted':
          return 'result-badge-failed';
        case 'N/A':
          return 'result-badge-na';
        case 'Terminated':
          return 'result-badge-failed';
        default:
          return '';
      }
    } else {
      switch (status) {
        case 'PASS':
          return 'result-passed';
        case 'FAIL':
          return 'result-failed';
        case 'WARNING':
          return 'result-warning';
        case 'SKIP':
          return 'result-skipped';
        case 'Aborted':
          return 'result-failed';
        case 'N/A':
          return 'result-skipped';
        case 'Terminated':
          return 'result-failed';
        default:
          return '';
      }
    }
  };

  const getOverallStatus = (type) => {
    let stepData;
    if (type === 'dependant' && dependScriptStats) {
      stepData = dependScriptStats;
    } else if (type === 'preconditions' && preConditionStats) {
      stepData = preConditionStats;
    } else if (type === 'postconditions' && postConditionStats) {
      stepData = postConditionStats;
    } else if (type === 'steps' && stepResultStats) {
      stepData = stepResultStats;
    }
    if (stepData) {
      if (stepData.totalTerminated > 0) {
        return 'Terminated';
      }
      if (stepData.totalFailed > 0) {
        return 'FAIL';
      }
      if (stepData.totalWarning > 0) {
        return 'WARNING';
      }
      if (stepData.totalSkipped > 0) {
        return 'SKIP';
      }
      if (stepData.totalPassed > 0) {
        return 'PASS';
      }
      return 'PASS';
    }
    return '';
  };

  const getOverallMessage = (status, type) => {
    let _message = '';
    let _concatedMessage = '';
    if (status) {
      if (type === 'steps') {
        _message = 'Steps are passed';
        if (status !== 'PASS') {
          _message = 'One or more steps are ';
        }
      } else if (type === 'preconditions') {
        _message = 'Preconditions are passed';
        if (status !== 'PASS') {
          _message = 'One or more preconditions are ';
        }
      } else if (type === 'postconditions') {
        _message = 'Postconditions are passed';
        if (status !== 'PASS') {
          _message = 'One or more postconditions are ';
        }
      } else if (type === 'dependant') {
        _message = 'Dependant scripts are passed';
        if (status !== 'PASS') {
          _message = 'One or more dependant scripts are ';
        }
      }
      if (status === 'Terminated') {
        _concatedMessage = 'Execution is Terminated';
      } else if (status === 'FAIL') {
        _concatedMessage = _message.concat('failed');
      } else if (status === 'WARNING') {
        _concatedMessage = _message.concat('warning');
      } else if (status === 'SKIP') {
        _concatedMessage = _message.concat('skipped');
      } else {
        _concatedMessage = _message;
      }
    }
    return _concatedMessage;
  };

  const removeInputField = (val) => {
    setOpenCreatePreCondition(val);
    myCssMethod(-1);
    setEditData(null);
    setAddStepAtIndex();
  };
  function getModuleResultTree(executionId) {
    localStorage.removeItem('dependent-data');
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    setIsLoading(true);
    getModuleResultTreeReq(executionId)
      .then((response) => {
        stopProgress();
        if (response.data.responseObject) {
          getTreeData(response.data);
        }
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 10,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        resetProgress();
        setIsLoading(false);
        console.error('GET_MODULE_TREE_RESULT : ', error);
      });
  }

  const getTreeData = (response) => {
    let response_data = response?.responseObject?.executionResponses[0];
    let returned_data = response_data?.children;
    let dependentData = response_data?.dependantExecutionEntityResponses;
    if (dependentData?.length) {
      setIDB('dependent-data', dependentData);
    }
    if (response_data.executionId) {
      returned_data[0]['executionId'] = response_data?.executionId;
    }
    if (response_data.storageDetails) {
      returned_data[0]['storageDetails'] = response_data?.storageDetails;
    }
    /* Indexed Db */
    setIDB('tree-data', returned_data[0]);
    setIDB('breadcrumb-data', returned_data[0]);
  };
  const navigateToResult = async () => {
    const exe_id = scriptResultData?.executionId || '';
    const clientId = scriptResultData?.selectedSystem?.clientId;
    const scriptStatusRunId = localStorage.getItem('scriptRunId') || scriptResultData?.runId;
    let dependentData = scriptResultData?.dependantExecutionEntityResponses;
    if (dependentData?.length) {
      let incompleteDependentData = false;
      dependentData.forEach((element) => {
        if (!element.stepResults || !element.scriptType) {
          incompleteDependentData = true;
        }
      });
      if (incompleteDependentData) {
        getModuleResultTreeReq(exe_id)
          .then((response) => {
            if (response.data.responseObject) {
              dependentData = response.data.responseObject.executionResponses[0].dependantExecutionEntityResponses;
              if (dependentData?.length) {
                setIDB('dependent-data', dependentData);
              }
            }
          })
          .catch((error) => {
            console.error('GET_MODULE_TREE_RESULT : ', error);
          });
      } else {
        if (dependentData?.length) {
          setIDB('dependent-data', dependentData);
        }
      }
    }
    let _treeData = await getIDB('tree-data');
    if (!_treeData || _treeData.executionId !== exe_id) {
      getModuleResultTree(exe_id);
    }
    setIDB('script-data', [scriptResultData]);
    let moduleName = decodeSpecialCharacter(window.location.pathname?.split('/')[3]);
    history.push({
      pathname: `/testdevelopment/Execution Dashboard/${scriptResultData?.name}/Root Module/${moduleName}/${scriptResultData?.name}/script/result`,
      search: `?id=${scriptResultData?.key}&executionId=${scriptResultData?.executionId}&moduleId=${moduleId}&clientId=${clientId}&runId=${scriptStatusRunId}&moduleName=${moduleName}`,
    });
  };

  /**
   * useClickOutSide: custom generic hook to handle click outside of a defined element
   * _DOMNode: used as a ref
   */
  let _DOMNode = useClickOutSide((event) => {
    if (addStepAtIndex && !openStepGroupInputParameterDiv) {
      removeEmptyStep(event, addStepAtIndex);
    }
  });

  const getExecutionOrder = (index, data) => {
    if (index === data.length - 1) {
      return Number(data[index].executionOrder) + 1;
    } else {
      return (Number(data[index]?.executionOrder) + Number(data[index + 1]?.executionOrder)) / 2;
    }
  };
  const addStepBelow = (event, row, index) => {
    let _currentData = [];
    setParentStartIfStep({});
    Object.assign(_currentData, data);
    if (_currentData && _currentData.length) {
      setMidExecutionOrder(getExecutionOrder(index, data));
      _currentData.splice(index + 1, 0, {
        stepId: uuidv4(),
        type: 'NLP-field',
      });
      setData(_currentData);
      setAddStepAtIndex(index + 1);
      setshowAddStepButton(false);
      if (_currentData[index]) {
        let previousNlpData = [];
        Object.assign(previousNlpData, _currentData[index]);
        setPreviousStepData(previousNlpData);
      }
    }
    if (index > 3) {
      setTimeout(() => {
        let theOddOnes = document.querySelectorAll('.nlpdataloading');
        for (let _oddOne of theOddOnes) {
          _oddOne.style.bottom = '22px';
        }
      }, 200);
    }
  };
  const removeEmptyStep = (ev, index) => {
    let _currentData = [];
    Object.assign(_currentData, data);
    if (_currentData && _currentData.length) {
      _currentData.splice(index, 1);
      setData(_currentData);
      setAddStepAtIndex();
      setMidExecutionOrder();
      setshowAddStepButton(true);
    }
  };

  const addStepAtEnd = () => {
    let _currentData = [];
    Object.assign(_currentData, data);
    let index = _currentData.length - 1;
    if (_currentData && _currentData.length && _currentData[index]) {
      let previousNlpData = [];
      Object.assign(previousNlpData, _currentData[index]);
      if (endConditionObject.includes(previousNlpData?.nlpName) || endNlpList.includes(previousNlpData.type)) {
        setPreviousStepData(previousNlpData);
      } else {
        setPreviousStepData({});
      }
    }
  };

  const getParentIteration = (scripts, currentStepIndex) => {
    const dataProviderIteration = dataProviderScript(
      scripts,
      currentStepIndex,
      scripts?.steps[currentStepIndex]?.marginLeft
    );
    let nestedIteration = null;
    if (currentStepIndex < scripts.steps.length) {
      let previousSteps = scripts.steps.slice(0, currentStepIndex);
      if (previousSteps?.length) {
        previousSteps = previousSteps.reverse();
        if (dataProviderIteration) {
          nestedIteration = previousSteps.find((step) => ['_startiteration'].includes(step?.type));
        } else {
          nestedIteration = previousSteps.find((step) => ['_startforloop'].includes(step?.type));
        }
      }
    }
    return nestedIteration;
  };

  /**
   * The method checks whether the step being created is a part of any iteration.
   * If yes then sets its parent start iteration id i.e. parentSpecialNlpId and other respective params
   * @param {*} index - index of the new step input field being created
   * @returns NLP suggestion input field
   */
  const nlpSuggestionBoxRenderer = (index, row) => {
    let _isSpecialNlp = false;
    setshowAddStepButton(false);
    let _parentIterationId;
    let _parentIfConditionId;
    if (_parentIterationId) setParentSpecialNlpId(_parentIterationId);
    if (scripts) {
      if (index < scripts.steps.length) {
        for (let j = index - 1; j >= 0; j--) {
          const step = scripts.steps[index - 1];
          if (startNlpList.includes(step.type)) {
            _isSpecialNlp = true;
            _parentIterationId = step.specialNlpId;
            break;
          } else if (step.conditionId) {
            if (endConditionObject.includes(step?.nlpName) && step?.conditionSearchKey) {
              if (step?.conditionSearchKey.includes('/')) {
                const conditionSearchKeyList = step?.conditionSearchKey.split('/');
                _parentIfConditionId = conditionSearchKeyList.at(-2);
                if (['EndElseIfCondition', 'EndElseCondition'].includes(step?.nlpName)) {
                  _parentIfConditionId = conditionSearchKeyList.at(-3);
                }
                if (_parentIfConditionId) {
                  break;
                }
              }
              if (!_parentIfConditionId && step?.conditionId && ['IfCondition'].includes(step?.nlpName)) {
                _parentIfConditionId = step.conditionId;
              }
              if (step.hasOwnProperty('parentSpecialNlpId')) {
                _parentIterationId = step.parentSpecialNlpId;
              }
              break;
            } else if (
              startConditionObject.includes(step?.nlpName) ||
              endNlpList.includes(step.type) ||
              step.conditionId
            ) {
              _parentIfConditionId = step.conditionId;
              break;
            }
          } else {
            if (step.hasOwnProperty('parentSpecialNlpId')) {
              _isSpecialNlp = true;
              _parentIterationId = step.parentSpecialNlpId;
              break;
            } else {
              if (endNlpList.includes(step.type) && !step.hasOwnProperty('parentSpecialNlpId')) {
                _isSpecialNlp = false;
                _parentIterationId = step.parentSpecialNlpId;
                break;
              } else {
                continue;
              }
            }
          }
        }
      }
      let parentStart = scripts.steps.find(
        (step) => startNlpList.includes(step.type) && step.specialNlpId === _parentIterationId
      );
      if (!parentStart) {
        parentStart = getParentIteration(scripts, index);
      }
      let parentStartIf;
      if (_parentIfConditionId) {
        parentStartIf = scripts.steps.find(
          (step) => startConditionObject.includes(step?.nlpName) && step?.conditionId === _parentIfConditionId
        );
        setParentStartIfStep(parentStartIf);
      }
      setIsSpecialNlp(_isSpecialNlp);
      setParentSpecialNlpId(_parentIterationId);
      setParentStartDataproviderStep(parentStart);
    }
    const selectedStep = scripts.steps[row?.slNo];
    return (
      <div className="w-100">
        <NlpSuggestion
          drpPos={false}
          handleNlpData={handleNlpData}
          openStepgroupInputDiv={openStepGroupDiv}
          handleConditionNlpObject={handleConditionNlpObject}
          script={scripts}
          scriptRowIndex={scriptRowIndex}
          {...props}
          stepGroup={stepGroup}
          previousStepData={previousStepData}
          removeInputField={removeInputField}
          closeModal={closeModal}
          steps={selectedStep}
          parentIterationId={_parentIterationId}
          selectedStepIndex={selectedStepIndex}
          getExecutionOrder={getExecutionOrder}
          conditionId={conditionId}
          conditionSearchKey={conditionSearchKey}
          dataProviderVariablesArray={dataProviderVariablesArray}
        />
      </div>
    );
  };

  /**
   *
   * @param {*} step the step to be deleted
   * @param {*} index
   * @returns boolean which ensures if a step can be deleted or not
   */
  const allowDelete = (step, index) => {
    let value = true;
    if (endConditionObject.includes(step?.nlpName)) {
      value = false;
    } else if (['IfCondition', 'ElseIfCondition', 'ElseCondition'].includes(step.nlpName)) {
      const nextStep = data[index + 1];
      if (nextStep) {
        return endConditionObject.includes(nextStep.nlpName);
      } else {
        return true;
      }
    } else if (
      !endNlpList.includes(step.type) &&
      !startNlpList.includes(step.type) &&
      (!step.isSpecialNlp || !step.isIterationStep)
    ) {
      value = true;
    } else if (step.isSpecialNlp && startNlpList.includes(step.type)) {
      const nextStep = data[index + 1];
      if (nextStep) {
        if (
          !endNlpList.includes(nextStep.type) &&
          (nextStep.isSpecialNlp || nextStep.isIterationStep || nextStep.parentSpecialNlpId)
        ) {
          value = false;
        } else {
          value = true;
        }
      } else {
        value = true;
      }
    } else {
      value = false;
    }
    return value;
  };

  const allowMoreActionsMenu = (step) => {
    let value = true;
    if (excludedNlpNames.includes(step?.nlpName) || ['_enditeration', '_startiteration'].includes(step?.type)) {
      value = false;
    }
    return value;
  };

  const allowRunUntil = (step) => {
    if (endNlpList.includes(step.type)) {
      if (step.parentSpecialNlpId) {
        return false;
      } else {
        return true;
      }
    } else if (step?.conditionSearchKey) {
      if (step?.marginLeft === 0 && endConditionObject.includes(step?.nlpName)) {
        return true;
      } else {
        return false;
      }
    } else {
      if (!startNlpList.includes(step.type) && !(step.isIterationStep || step.isForLoopStep) && !step?.isDisabled) {
        return true;
      }
      return false;
    }
  };

  useEffect(() => {
    if (reload) {
      setScriptRowIndex(1);
      const fetchData = async () => {
        if (props.type === 'Step Group') {
          await getStepGroupData();
        } else {
          await getScriptResult();
          await getScriptTemp();
          getStepGroup();
        }
        await getUserAllAvailableMachine();
        getParameterList();
        setReload(false);
      };
      fetchData();
    }
  }, [reload]);

  const getScriptData = async () => {
    if (props.type === 'Step Group') {
      await getStepGroupData();
    } else {
      await getScriptResult();
      await getScriptTemp();
      getStepGroup();
    }
    await getUserAllAvailableMachine();
    getParameterList();
    setReload(false);
  };

  useEffect(() => {
    if (data.length > 0) {
      setExecutionOrder(data[data.length - 1].executionOrder + 1);
    } else {
      setExecutionOrder(1);
    }
  }, [data]);

  useEffect(() => {
    if (props.createCalled) {
      setOpenCreatePreCondition(false);
      if (noSteps) {
        setNoSteps(false);
      }
    }
  }, [props.createCalled, noSteps]);

  useEffect(() => {
    /*Developer : Basavaraj Channur changes Beginning*/
    const Scriptadd = localStorage.getItem('Scriptadd');
    if (Scriptadd) {
      props.MyAlert.success(`${Scriptadd} script updated successfully`);
      localStorage.removeItem('Scriptadd');
    }
    /*Basavaraj Channur changes Ending*/
  }, []);

  /**
   * method to render Status stats count
   * @Param _stepResultStats is the object of stats
   */
  const getStatusStats = (_stepResultStats) => {
    return (
      <section className="inline-block  text-xs font-semibold">
        {_stepResultStats?.totalFailed > 0 && (
          <span className="result-failed">
            {_stepResultStats?.totalFailed} / {_stepResultStats?.total} Failed
          </span>
        )}
        {_stepResultStats?.totalWarning > 0 && (
          <span className="result-warning ml-2">
            {_stepResultStats?.totalWarning} / {_stepResultStats?.total} Warning
          </span>
        )}
        {_stepResultStats?.totalSkipped > 0 && (
          <span className="result-skipped ml-2">
            {_stepResultStats?.totalSkipped} / {_stepResultStats?.total} Skipped
          </span>
        )}
        {_stepResultStats?.totalPassed > 0 && (
          <span className="result-passed ml-2">
            {_stepResultStats?.totalPassed} / {_stepResultStats?.total} Passed
          </span>
        )}
      </section>
    );
  };

  const getFormattedStatusIfElse = (status, row) => {
    if (['StartForLoop', 'EndForLoop'].includes(row?.nlpName)) {
      return '';
    } else {
      return status;
    }
  };

  const { moduleName, isEditable, hasFullAccess, isProjectOpen, isRepositoryEditable } = React.useMemo(() => {
    const moduleNameOfstartsWith = {
      repository: '/repository',
      testDevelopment: '/testdevelopment',
    };

    const locationPath = history.location.pathname;
    const moduleName = Object.entries(moduleNameOfstartsWith).find(([moduleName, startsWithString]) =>
      locationPath.startsWith(startsWithString)
    );
    return {
      isProjectOpen: window.permission.isProjectOpen(),
      moduleName: moduleName?.[0],
      isEditable: moduleName ? window.permission.isEditAccess(moduleName[0]) : true,
      hasFullAccess: moduleName ? window.permission.isFullAccess(moduleName[0]) : true,
      isRepositoryEditable: window.permission.isEditAccess('repository'),
    };
  }, [history]);
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const cloneStep = async (rowdata, index) => {
    let stepdata = JSON.parse(JSON.stringify(rowdata));
    let payload = scripts;
    let stepPayload = { ...stepdata };
    stepPayload.isDisabled = false;
    let cloneSingleStep = { ...payload, steps: [stepPayload] };
    if (stepdata.nlpName === 'RunWebService') {
      try {
        const webServiceCloneResponse = await cloneWebServiceStepReq(stepdata.stepId);
        const webserviceStepId = webServiceCloneResponse.data?.success?.id;
        if (webserviceStepId) {
          delete stepPayload.stepInputs[0].value;
          stepPayload.stepId = webserviceStepId;
          props.MyAlert.success('Steps Cloned Successfully');
        } else {
          console.error('FAILED_TO_CLONE :', webServiceCloneResponse?.data);
          return;
        }
      } catch (err) {
        console.error('FAILED_TO_CLONE_WEBSERVICE_STEP :', err);
        return;
      }
    } else {
      stepPayload.stepId = getStepId();
      props.MyAlert.success('Steps Cloned Successfully');
    }
    stepPayload.executionOrder = getExecutionOrder(index, scripts?.steps);
    payload.steps = [stepPayload];
    try {
      if (props.type === 'Step Group') {
        let response = await updateStepGroupReq(libraryId, stepGroupId, 'ADD', payload);
        if (response?.data?.status.toLowerCase() === 'success') {
          props.MyAlert.success('Steps Cloned Successfully');
        }
        setReload(true);
      } else {
        let cloneResponse = await updateScriptReq(moduleId, scriptId, 'ADD', cloneSingleStep);
        if (cloneResponse) {
          props.MyAlert.success('Steps Cloned Successfully');
        }
        setReload(true);
      }
    } catch (err) {
      console.error('CLONE_STEP_ERROR :', err);
    }
  };

  const updateSelectSteps = (steps) => {
    enableStepGroupButton && setStepsForStepGroup(steps);
  };
  const enableDisableStepGroup = async (rowdata, index, isDisable) => {
    let stepdata = JSON.parse(JSON.stringify(rowdata));
    let payload = scripts;
    let stepPayload = { ...stepdata };
    stepPayload.isDisabled = isDisable;
    let disableSingleStep = { ...payload, steps: [stepPayload] };
    let response;
    try {
      if (props.type === 'Step Group') {
        response = await updateStepGroupReq(libraryId, stepGroupId, 'UPDATE', disableSingleStep);
        if (response?.data?.responseCode === 200 && response?.data?.status === 'SUCCESS') {
          props.MyAlert.success(`Step ${isDisable ? 'Disabled' : 'Enabled'} Successfully`);
        }
      } else {
        response = await updateScriptReq(moduleId, scriptId, 'UPDATE', disableSingleStep);
        if (response?.data?.responseCode === 200 && response?.data?.status === 'SUCCESS') {
          props.MyAlert.success(`Step ${isDisable ? 'Disabled' : 'Enabled'} Successfully`);
        }
      }
      setReload(true);
      updateSelectSteps(response?.data?.responseObject?.steps);
    } catch (err) {
      console.error('DISABLE_OR_ENABLE :', err);
    }
  };

  const getWebserviceUpdatedPath = (row, index) => {
    const currentStepMarginLeft = row?.marginLeft;
    const currentWebserviceStep = scripts?.steps[index];
    const executionOrder = getExecutionOrder(index, scripts?.steps);
    const updatedPath = history.location.pathname.slice(0, history.location.pathname.lastIndexOf('/'));
    let path = `${updatedPath}/webservices/restapi?moduleId=${moduleId}&scriptId=${scriptId}&stepId=${row.stepId}`;
    const pushToHistory = (stepData) => {
      history.push(path, stepData);
    };
    const ifConditionParentStep = ifCondition_ParentCheck(scripts, index, currentStepMarginLeft);
    let ifConditionalOptionalParam = generateConditionalOptionalParam(
      executionOrder,
      row,
      row?.parentSpecialNlpId,
      row
    );
    const iterationDataForStepGroup = props?.type === STEPGROUP && scripts;
    if (row?.conditionId && NLP_CONDITION_START_ITERATION.includes(ifConditionParentStep?.name)) {
      row.parentSpecialNlpId = ifConditionParentStep.parentSpecialNlpId;
    }
    if (NLP_CONDITION_START_ITERATION.some((iteration) => ifConditionParentStep?.name.includes(iteration))) {
      addPropsForIfCondition(
        ifConditionalOptionalParam,
        ifConditionParentStep?.conditionId,
        ifConditionParentStep?.conditionSearchKey,
        MARGIN_LEFT
      );
    }
    if (props?.type === STEPGROUP) {
      path = `${updatedPath}/webservices?id=${stepGroupId}&parentId=${libraryId}&stepGroup=${stepGroupName}&defaultStepGroup=${defaultStepGroup}&stepId=${row.stepId}`;
    }
    if (props?.type === STEPGROUP && !row?.parentSpecialNlpId) {
      history.push(path, scripts);
    } else if (row?.parentSpecialNlpId) {
      if (row?.parentSpecialNlpId.includes('forloop_')) {
        const dataProviderParent = dataProviderScript(scripts, index, currentStepMarginLeft);
        const parentConditionalStep = forLoopScript(scripts, index, currentStepMarginLeft);
        const conditionalOptionalParam = generateConditionalOptionalParam(
          executionOrder,
          parentConditionalStep,
          row?.parentSpecialNlpId,
          row
        );
        if (ifConditionParentStep?.parentSpecialNlpId) {
          ifConditionalOptionalParam = {
            ...ifConditionalOptionalParam,
            ...conditionalOptionalParam,
            ...(dataProviderParent && { ...dataProviderParent, ...{ script: scripts } }),
          };
          pushToHistory(ifConditionalOptionalParam);
        } else {
          const getParentSteps = {
            ...iterationDataForStepGroup,
            ...conditionalOptionalParam,
            ...(dataProviderParent && { ...dataProviderParent, ...{ script: scripts } }),
            marginLeft: conditionalOptionalParam?.marginLeft,
          };
          pushToHistory(getParentSteps);
        }
      } else if (row?.parentSpecialNlpId.includes('itr_')) {
        const filteredParentSteps = scripts.steps.filter(
          (items) => items?.nlpName === 'StartIteration' && items?.specialNlpId === row?.parentSpecialNlpId
        );
        let getParentSteps = {
          ...filteredParentSteps[0],
          ...{ script: scripts },
        };
        const dataProviderParent = dataProviderScript(scripts, index, currentStepMarginLeft);
        let parentData = null;
        if (
          currentWebserviceStep?.parentSpecialNlpId &&
          currentWebserviceStep?.parentSpecialNlpId.includes('forloop_')
        ) {
          parentData = forLoopScript(scripts, index, currentStepMarginLeft, row?.parentSpecialNlpId);
        }
        let conditionalOptionalParam = null;
        if (parentData) {
          const parentConditionalStep = parentData;
          conditionalOptionalParam = generateConditionalOptionalParam(
            executionOrder,
            parentConditionalStep,
            row?.parentSpecialNlpId,
            row
          );
        }
        if (ifConditionParentStep?.parentSpecialNlpId) {
          ifConditionalOptionalParam = {
            ...iterationDataForStepGroup,
            ...ifConditionalOptionalParam,
            ...getParentSteps,
            ...conditionalOptionalParam,
            dataProviderVariables: dataProviderParent?.dataProviderVariables,
            marginLeft: row?.marginLeft,
          };
          pushToHistory(ifConditionalOptionalParam);
        } else {
          getParentSteps = {
            ...iterationDataForStepGroup,
            ...getParentSteps,
            ...conditionalOptionalParam,
            ...(dataProviderParent && { dataProviderVariables: dataProviderParent?.dataProviderVariables }),
          };
          pushToHistory(getParentSteps);
        }
      }
    } else {
      history.push(path, scripts);
    }
  };

  const updateStepsDataOnAdd = (scriptData) => {
    setScriptsFromApi({ ...scriptData });
    setScripts({ ...scriptData });
    if (addStepAtIndex >= 0) {
      let entries = [...scriptData?.steps];
      let startIndex = 0;
      let tempArray = [];
      entries.map((step, index) => {
        let stepObj = {};
        Object.assign(stepObj, step);
        if (!excludedNlpTypes.includes(stepObj?.type)) {
          startIndex = startIndex + 1;
          stepObj['slNo'] = startIndex;
        }
        tempArray.push({ ...stepObj });
      });
      scriptData['steps'] = tempArray;
      setData(tempArray);
      setAddStepAtIndex();
      setMidExecutionOrder();
      setshowAddStepButton(true);
      if (!editPreCondition) {
        setScriptRowIndex(startIndex + 1);
      }
    } else {
      setData([...scriptData?.steps]);
      if (!editPreCondition) {
        setScriptRowIndex(scriptRowIndex + 1);
      }
    }
    handleCloseModal(scriptData);
  };

  const handleCloseModal = async (scriptData) => {
    setMode();
    setSelectedStepIndex();
    setshowAddStepButton(true);
    setOpenStepGroupInputParameterDiv(false);
    setStepGroupInputParameterDivData('');
    setOpenCreatePreCondition(false);
    setParentSpecialNlpId('');
    setParentStartDataproviderStep('');
    if (data.length === 0) {
      setNoSteps(true);
    }
    if (editPreCondition) {
      setEditPreCondition(false);
    }
    setStepIndex(scriptData?.steps.length + 1);
    setEditData(null);
    props.MyAlert.success(
      `${scripts?.name.length > 22 ? scripts?.name?.substring(0, 22) + '...' : scripts?.name} 
      script updated successfully`
    );
  };
  useEffect(() => {
    setEnableStepGroupButton(getEnableStepGroupButton);
  }, [stepsForStepGroup.length]);

  const getEnableStepGroupButton = () => {
    if (dependentScriptChecked.length > 0) {
      return false;
    } else if (
      stepsForStepGroup.length > 0 ||
      stepClicked > 0 ||
      preConditionChecked.length > 0 ||
      postConditionChecked.length > 0
    ) {
      return true;
    }
    return false;
  };

  const alertReturn = () => {
    setOpenDeleteStepsModal(true);
  };

  const multipleStepDelete = async () => {
    try {
      setOpenDeleteStepsModal(false);
      let response;
      let deleteStepData;
      let PayloadONStep = [];
      let scriptData = [...data];
      const stepsForStepGroupArr = [...stepsForStepGroup];
      scriptData?.forEach((checkedStep, index) => {
        if (
          document.getElementById(`StepCheckbox-${index + 1}`).checked === true &&
          document.getElementById(`StepCheckbox-${index + 1}`).indeterminate === false &&
          (isEmptyValue(checkedStep.isDisabled) || checkedStep.isDisabled === false)
        ) {
          deleteStepData = {
            stepId: checkedStep?.stepId,
          };
          PayloadONStep.push(deleteStepData);
          if (stepsForStepGroupArr.length) {
            const _stepIndex = stepsForStepGroupArr.findIndex((item) => item.stepId === checkedStep.stepId);
            if (_stepIndex > -1) {
              stepsForStepGroupArr.splice(_stepIndex, 1);
            }
            if (checkedStep?.isForLoopStep || checkedStep?.isIterationStep) {
              //to handle for loop and dp iteration steps delete
              const _startParentStepIndex = stepsForStepGroupArr.findIndex(
                (item) => item?.specialNlpId === checkedStep?.parentSpecialNlpId && startNlpList.includes(item.type)
              );
              if (_startParentStepIndex > -1 && stepsForStepGroupArr.length >= _startParentStepIndex) {
                stepsForStepGroupArr.splice(_startParentStepIndex, 1);
              }
              const _endParentStepIndex = stepsForStepGroupArr.findIndex(
                (item) => item?.specialNlpId === checkedStep?.parentSpecialNlpId && endNlpList.includes(item.type)
              );
              if (_endParentStepIndex > -1 && stepsForStepGroupArr.length >= _endParentStepIndex) {
                stepsForStepGroupArr.splice(_endParentStepIndex, 1);
              }
              setSelectedStepObjects({});
            }
          }
        }
      });
      setStepsForStepGroup([...stepsForStepGroupArr]);
      if (PayloadONStep?.length && stepClicked >= PayloadONStep.length) {
        setStepClicked(stepClicked - PayloadONStep.length);
      } else if (stepClicked < PayloadONStep.length) {
        setStepClicked(0);
      }
      if (props.type === 'Step Group') {
        // TODO :: handle returnType for Step Group
        scripts.returnType = PayloadONStep?.length && scripts?.steps?.length ? 'void' : scripts?.returnType;
        if (scripts.returnType === 'void') {
          delete scripts.stepGroupReferenceInfo;
        }

        response = await updateStepGroupReq(libraryId, stepGroupId, 'DELETE', {
          ...scripts,
          steps: PayloadONStep,
        });
        setSelectedReturnTypeValue(response?.data?.responseObject?.returnType);
      } else {
        response = await updateScriptReq(moduleId, scriptId, 'DELETE', {
          ...scripts,
          steps: PayloadONStep,
        });
      }

      if (response.data.responseCode === 200 && response.data.status === 'SUCCESS') {
        document.getElementById('rootStepsCheckbox').checked = false;
        document.getElementById('rootStepsCheckbox').indeterminate = false;
        scriptData?.forEach((value, index) => {
          document.getElementById(`StepCheckbox-${index + 1}`).checked = false;
          document.getElementById(`StepCheckbox-${index + 1}`).indeterminate = false;
        });
        setStepBoxCheck(0);
        setIsCheckAllSteps(false);
        setAllowDeleteFlag(true);
        reloadPreConditions(true);
        setshowAddStepButton(true);
        props.MyAlert.success(SCRIPT_STEPS.SUCCESS_MESSAGE);
        setStepsForStepGroup([]);
      } else {
        props.MyAlert.warning(`${response.data.message}`);
      }
      PayloadONStep = [];
      setStepsSelectedForRun([]);
    } catch (err) {
      setAllowDeleteFlag(true);
      console.error('DELETE_STEPS :', err);
    }
  };

  const onCancelClicked = () => {
    setOpenDeleteStepsModal(false);
  };

  const disableEditIcon = (rowData) => {
    if (
      excludedNlpTypes.includes(rowData.type) ||
      excludedNlpNames.includes(rowData.nlpName) ||
      isDefaultStepGroup ||
      rowData.isDisabled
    ) {
      return 'opacity-40 cursor-default';
    } else return 'cursor-pointer';
  };

  const handleEditStepClick = (rowIndex, rowData) => {
    if (rowData.nlpName.toLowerCase() === 'runwebservice') {
      getWebserviceUpdatedPath(rowData, rowIndex);
    } else if (
      !excludedNlpTypes.includes(rowData.type) &&
      !excludedNlpNames.includes(rowData.nlpName) &&
      !isDefaultStepGroup
    ) {
      handleEditStep(rowIndex, rowData);
    }
  };
  const isStepCheckBoxSelected = stepBoxCheck > 0 || isCheckAllSteps;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEscapeKeyCloseModal(isPopoverOpen, () => setIsPopoverOpen(false));

  const renderAddButton = () =>
    isEditable &&
    showAddStepButton &&
    !openStepGroupInputParameterDiv &&
    !openPreConditionStepGroupInputParameterDiv &&
    !openPostConditionStepGroupInputParameterDiv &&
    !disableStepButtonOnload;

  return (
    <>
      {openDeleteStepsModal ? (
        <div>
          <AlertPopUps
            open={true}
            alertType={WARNING}
            showHowToProceed={false}
            showCancelBtn={true}
            showSaveBtn={true}
            saveBtnText="Delete"
            onCancelBtnClick={onCancelClicked}
            onSaveBtnClick={multipleStepDelete}
            content={[
              <div className="mb-2 capitalize">{SCRIPT_STEPS.DELETE_MESSAGE}</div>,
              <div>{SCRIPT_STEPS.CONFIRM_MESSAGE}</div>,
            ]}
          />
        </div>
      ) : null}
      <div className="testdev-steps">
        <div className="page-table-body">
          <div className="content_area_style">
            <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
              {decodeSpecialCharacter(props?.match?.params?.scriptName)?.length > 100 ||
              props?.stepGroupName?.length > 100 ? (
                <label className="project_label flex-auto add">
                  <Tooltip title={props?.type ? props?.stepGroupName : scriptName} placement="bottom-start">
                    <span className="flex">
                      <div className="script-name truncate w-fit" id="wrapper">
                        {props?.type ? props?.stepGroupName : scriptName}
                      </div>
                      {(showStepResultHeader ||
                        showPreConditionResultHeader ||
                        showPostConditionResultHeader ||
                        showDependsOnScriptsResultHeader) &&
                      scriptResultData &&
                      scriptResultData?.status ? (
                        <span
                          className={`text-xs rounded-xl ${
                            scriptResultData?.status === 'N/A' || scriptResultData?.status === 'Aborted'
                              ? ''
                              : 'cursor-pointer'
                          } ${textColor(scriptResultData?.status, 'badge')} text-white px-2 pt-1 pb-1 ml-3`}
                          onClick={() => {
                            if (scriptResultData?.status === 'N/A' || scriptResultData?.status === 'Aborted') {
                            } else navigateToResult();
                          }}
                        >
                          {getFormattedTextStatus(scriptResultData?.status)}
                        </span>
                      ) : (
                        ''
                      )}
                    </span>
                  </Tooltip>
                </label>
              ) : (
                <div className="project_label flex">
                  <div className=" truncate w-fit">{props?.type ? props?.stepGroupName : scriptName}</div>
                  {(showStepResultHeader ||
                    showPreConditionResultHeader ||
                    showPostConditionResultHeader ||
                    showDependsOnScriptsResultHeader ||
                    showAbortedStatusHeader) &&
                  scriptResultData &&
                  scriptResultData?.status ? (
                    <div
                      className={`text-xs rounded-xl ${
                        scriptResultData?.status === 'N/A' || scriptResultData?.status === 'Aborted'
                          ? ''
                          : 'cursor-pointer'
                      } ${textColor(scriptResultData?.status, 'badge')}  text-rs-white px-2 py-1.5 ml-3`}
                      onClick={() => {
                        if (scriptResultData?.status === 'N/A' || scriptResultData?.status === 'Aborted') {
                        } else navigateToResult();
                      }}
                    >
                      {getFormattedTextStatus(scriptResultData?.status)}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              )}
              <div className="pr-4">
                <div className="flex flex-row ml-32">
                  <div className="ml-auto pl-4">
                    {props?.type ? (
                      <div>
                        {/* <svg
                        className="mx-3 float-left mt-1"
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        fill="#696969"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                      </svg> */}
                        <Select
                          classes={{ root: classes.select }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            classes: {
                              paper: classes.menuPaper,
                              list: classes.menulist,
                            },
                            getContentAnchorEl: null,
                          }}
                          value={selectedReturnTypeValue}
                          className="return-DropDown"
                          disabled={disableDropDown || !isEditable}
                        >
                          <MenuItem
                            className="fontPoppinsRegularMd"
                            value="void"
                            onClick={() => {
                              setReturnValueToStepGroup('void');
                            }}
                          >
                            Void
                          </MenuItem>
                          {arrayOfReturnValues.length > 0
                            ? arrayOfReturnValues.map((data, index) => (
                                <MenuItem
                                  className="fontPoppinsRegularMd"
                                  key={data.name}
                                  value={data.name}
                                  onClick={() => {
                                    setReturnValueToStepGroup(data);
                                  }}
                                >
                                  {data.name}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                        {isEditable && (
                          <>
                            {!enableStepGroupButton ? (
                              <button
                                onClick={() => {
                                  setMode('create');
                                  setSelectedStepIndex();
                                  if (noSteps) {
                                    setNoSteps(false);
                                  }
                                  setParentSpecialNlpId('');
                                  setParentStartDataproviderStep('');
                                  setOpenCreatePreCondition(true);
                                  setTimeout(() => {}, 200);
                                  setEditPreCondition(false);
                                  setshowAddStepButton(false);
                                }}
                                className="ml-3 primary-btn"
                                disabled={defaultStepGroup ? (defaultStepGroup === 'false' ? false : true) : false}
                              >
                                + Step
                              </button>
                            ) : (
                              isRepositoryEditable && (
                                <button
                                  className="ml-3 primary-btn"
                                  onClick={() => {
                                    openStepGroup();
                                  }}
                                >
                                  + Step Group
                                </button>
                              )
                            )}
                          </>
                        )}

                        <button
                          className="h-8 w-14 ml-3  gray-btn"
                          onClick={() => {
                            const dropdown = document.querySelector(`.return-dropDown`);
                            if (dropdown) {
                              dropdown.style.display = 'block';
                              dropdown.style.position = 'absolute';
                              dropdown.focus();
                              setIsPopoverOpen(true);
                            }
                          }}
                        >
                          <img
                            src="/assets/images/toggle_menu.svg"
                            alt=""
                            width="24px"
                            height="24px"
                            className="inline"
                          />
                        </button>
                        <div
                          className="dropdown-tree-wrapper overlay-dropdown-tree bg-white z-40 bg-opacity-100 "
                          onBlur={(e) => {
                            if (!e.currentTarget.contains(e.relatedTarget)) {
                              const dropdown = document.querySelector(`.return-dropDown`);
                              if (dropdown) {
                                setIsPopoverOpen(false);
                              }
                            }
                          }}
                        >
                          <Popover className="relative">
                            <>
                              <Popover.Panel
                                static
                                className={`return-dropDown bg-white bg-opacity-100 ${
                                  stepClicked > 0 ? 'parametersAside' : null
                                }`}
                                style={{
                                  display: isPopoverOpen ? 'block' : 'none',
                                }}
                              >
                                <div className="shadow-lg ring-1 ring-black rounded-sm	 ring-opacity-5 bg-white bg-opacity-100">
                                  <div className="parameter-header">
                                    <span className="parameter-text">Parameter List</span>
                                    <button
                                      className="float-right inline"
                                      onClick={() => {
                                        const dropdown = document.querySelector(`.return-dropDown`);
                                        if (dropdown) {
                                          dropdown.style.display = 'none';
                                          setIsPopoverOpen(false);
                                        }
                                        setRefData('');
                                        getParameterList();
                                      }}
                                    >
                                      <img
                                        alt="Close Icon"
                                        className="pr-3 w-7 h-6"
                                        src="/assets/images/close_black.svg"
                                      />
                                    </button>
                                  </div>
                                  <div>
                                    {(!isEmptyValue(filterParameterData) || !isEmptyValue(stepGroupParameterData)) && (
                                      <input
                                        type="text"
                                        className="ml-5 w-50"
                                        onChange={searchParameter}
                                        placeholder="search"
                                        name={refData}
                                        value={refData}
                                      />
                                    )}
                                    <div className="h-72 overflow-y-auto" id="journal-scroll">
                                      {!isEmptyValue(filterParameterData) ? (
                                        filterParameterData?.map((data) => {
                                          return (
                                            <div key={data.name} className="h-8 pl-5 pt-1 fontPoppinsRegularMd">
                                              {data.name}
                                            </div>
                                          );
                                        })
                                      ) : (
                                        <p className="fontPoppinsRegularLg text-center mt-10">
                                          {REPO?.NO_PARAMETER_FOUND}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </Popover.Panel>
                            </>
                          </Popover>
                        </div>
                      </div>
                    ) : (
                      <>
                        {/* <button
                        disabled={
                          openStepGroupInputParameterDiv ||
                          openPreConditionStepGroupInputParameterDiv ||
                          openPostConditionStepGroupInputParameterDiv
                        }
                        className={
                          openStepGroupInputParameterDiv ||
                          openPreConditionStepGroupInputParameterDiv ||
                          openPostConditionStepGroupInputParameterDiv
                            ? "opacity-50 float-left"
                            : " float-left ml-3 hover:text-blue-700"
                        }
                      >
                        <Tooltip title="Search" placement="top">
                          <SearchOutlined className="text-gray-500 ml-3 mt-1 cursor-pointer" />
                        </Tooltip>
                      </button> */}
                        {isProjectOpen && (
                          <button
                            disabled={
                              openStepGroupInputParameterDiv ||
                              openPreConditionStepGroupInputParameterDiv ||
                              openPostConditionStepGroupInputParameterDiv
                            }
                            onClick={() => runStepsController()}
                            className={
                              openStepGroupInputParameterDiv ||
                              openPreConditionStepGroupInputParameterDiv ||
                              openPostConditionStepGroupInputParameterDiv
                                ? 'opacity-30 float-left'
                                : 'opacity-100 float-left'
                            }
                          >
                            <Tooltip title="Run" placement="top">
                              <PlayCircleOutlineOutlined className="text-green-700 ml-3 mt-1 cursor-pointer" />
                            </Tooltip>
                          </button>
                        )}
                        {isEditable && hasAutomation && hasAutomationBasedOnProjType && (
                          <button
                            disabled={
                              openStepGroupInputParameterDiv ||
                              openPreConditionStepGroupInputParameterDiv ||
                              openPostConditionStepGroupInputParameterDiv
                            }
                            onClick={() => {
                              runSettings();
                            }}
                            className={`${
                              openStepGroupInputParameterDiv ||
                              openPreConditionStepGroupInputParameterDiv ||
                              openPostConditionStepGroupInputParameterDiv
                                ? 'opacity-30 float-left'
                                : 'float-left'
                            }`}
                          >
                            <Tooltip title="Quick Run Settings" placement="top">
                              <img
                                className="ml-3 mt-1 cursor-pointer"
                                src="/assets/images/run-settings-gray.svg"
                                alt="search"
                                width="24"
                                height="24"
                              />
                            </Tooltip>
                          </button>
                        )}
                        {isProjectOpen && (
                          <button
                            disabled={
                              !scriptResultData ||
                              openStepGroupInputParameterDiv ||
                              openPreConditionStepGroupInputParameterDiv ||
                              openPostConditionStepGroupInputParameterDiv
                            }
                            onClick={() => {
                              clearAllResults();
                            }}
                            className={`${
                              !scriptResultData ||
                              openStepGroupInputParameterDiv ||
                              openPreConditionStepGroupInputParameterDiv ||
                              openPostConditionStepGroupInputParameterDiv
                                ? 'opacity-50'
                                : 'opacity-100'
                            } text-gray-500 text-xl ml-3 mr-1`}
                          >
                            <Tooltip title="Clear Result" placement="top">
                              <span>
                                {' '}
                                <HighlightOffRounded />
                              </span>
                            </Tooltip>
                          </button>
                        )}
                        {isProjectOpen && (
                          <button
                            disabled={
                              !enableSyncButton ||
                              openStepGroupInputParameterDiv ||
                              openPreConditionStepGroupInputParameterDiv ||
                              openPostConditionStepGroupInputParameterDiv
                            }
                            onClick={() => {
                              if (!isLoading) {
                                setReload(true);
                              }
                            }}
                            className={`${
                              !enableSyncButton ||
                              openStepGroupInputParameterDiv ||
                              openPreConditionStepGroupInputParameterDiv ||
                              openPostConditionStepGroupInputParameterDiv
                                ? 'opacity-30 cursor-none'
                                : 'cursor-pointer'
                            }  ml-3 mr-1`}
                          >
                            <Tooltip title="Sync Status" placement="top">
                              <Sync fontSize="small" className="mr-3" />
                            </Tooltip>
                          </button>
                        )}
                        {isEditable && (
                          <>
                            {enableStepGroupButton ? (
                              isRepositoryEditable && (
                                <button
                                  className="ml-3 primary-btn"
                                  onClick={() => {
                                    openStepGroup();
                                    setAction('create');
                                  }}
                                >
                                  + Step Group
                                </button>
                              )
                            ) : (
                              <Tooltip
                                title={disableStepButtonOnload ? 'Steps are loading' : 'Add Step'}
                                placement="bottom-start"
                              >
                                <button
                                  disabled={
                                    openStepGroupInputParameterDiv ||
                                    openPreConditionStepGroupInputParameterDiv ||
                                    openPostConditionStepGroupInputParameterDiv ||
                                    disableStepButtonOnload
                                  }
                                  onClick={() => {
                                    setMode('create');
                                    setSelectedStepIndex();
                                    if (noSteps) {
                                      setNoSteps(false);
                                    }
                                    setParentSpecialNlpId('');
                                    setParentStartDataproviderStep('');
                                    setOpenCreatePreCondition(true);
                                    setTimeout(() => {}, 200);
                                    setEditPreCondition(false);
                                    setshowAddStepButton(false);
                                    addStepAtEnd();
                                  }}
                                  className="ml-3 primary-btn"
                                >
                                  + Step
                                </button>
                              </Tooltip>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="table-height overflow-auto" id="journal-scroll">
              {memoryWarningPopup && (
                <MemoryWarningPopup
                  handleCancel={() => setMemoryWarningPopup(false)}
                  handleContinue={() =>
                    runExecution(
                      qrsData?.isRunUntill,
                      qrsData?.runUntillDependsOnScripts,
                      qrsData?.runUntillPreConditions,
                      qrsData?.runUntillSteps,
                      qrsData?.runUntillPostConditions,
                      qrsData?.runFromQrs,
                      true
                    )
                  }
                  isOpen={memoryWarningPopup}
                />
              )}
              <div className="">
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <table>
                    <thead className="sticky top-0 z-50">
                      {props?.type ? (
                        <tr className="fontPoppinsSemiboldMd uppercase h-9 xl:12 ml-4 bg-gray-100 hover:bg-gray-100">
                          <th className="pl-2 pr-2 text-left table-width"></th>
                          <th className="pl-1 text-left w-5/6">{SCRIPT_STEPS.DESCRIPTION}</th>
                          <th className="pl-5 text-left actions-style">{SCRIPT_STEPS.ACTIONS}</th>
                        </tr>
                      ) : (
                        <tr className="fontPoppinsSemiboldMd uppercase h-9 xl:h-12 ml-4 bg-gray-100 hover:bg-gray-100">
                          <th className="pl-2 pr-2 text-left table-width"></th>
                          <th className="pl-1 text-left w-1/2">{SCRIPT_STEPS.DESCRIPTION}</th>
                          <th className="pl-5 text-left w-2/6">{SCRIPT_STEPS.RESULT}</th>
                          <th className="pl-5 text-left w-1/12">{SCRIPT_STEPS.STATUS}</th>
                          <th className="pl-5 text-left actions-style">{SCRIPT_STEPS.ACTIONS}</th>
                        </tr>
                      )}
                    </thead>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <tbody
                          className="text fontPoppinsRegularMd"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {props?.type ? (
                            <tr className="fontPoppinsSemiboldMd h-9 xl:12 ml-4 configHover">
                              <th className="pl-2 pr-2 text-left table-width"></th>
                              <th className="pl-1 text-left w-5/6">
                                {(isEditable || moduleName !== 'repository') && (
                                  <input
                                    disabled={data.length === 0 || disableAllSteps}
                                    type="checkbox"
                                    name="rootStepsCheckbox"
                                    id="rootStepsCheckbox"
                                    className={`relative mr-2 border-3 rounded border-black steps-checkbox -ml-1 ${
                                      disableAllSteps ? 'opacity-40' : 'opacity-100'
                                    }`}
                                    onChange={checkUncheckAllSteps}
                                  />
                                )}
                                {SCRIPT_STEPS.STEPS}
                              </th>
                              {!hasFullAccess || project?.status === 'Closed' || isDefaultStepGroup ? (
                                <th></th>
                              ) : (
                                <th className="pl-5 text-left actions-style">
                                  {data?.length ? (
                                    <span>
                                      <Tooltip
                                        title={`${
                                          isStepCheckBoxSelected
                                            ? SCRIPT_STEPS.DELETE_SELECTED_STEPS
                                            : SCRIPT_STEPS.SELECT_STEPS
                                        }`}
                                      >
                                        <DeleteOutlinedIcon
                                          id="multi-steps-delete"
                                          className={`float-left mr-2 mt-1 text-blue-700 text-base 
                                ${isStepCheckBoxSelected ? 'opacity-100	cursor-pointer' : 'opacity-40'}`}
                                          onClick={() => {
                                            if (isStepCheckBoxSelected) alertReturn();
                                          }}
                                        />
                                      </Tooltip>
                                    </span>
                                  ) : null}
                                </th>
                              )}
                            </tr>
                          ) : (
                            <tr></tr>
                          )}
                          {dependScriptData.length ? (
                            <tr className="configHover h-12 content-start myRoww">
                              <td className="p-px text-left fontPoppinsRegularMd"></td>
                              <td className="p-px text-left fontPoppinsRegularMd">
                                <span>
                                  {(isEditable || moduleName !== 'repository') && (
                                    <input
                                      disabled={dependScriptData.length === 0}
                                      type="checkbox"
                                      name="rootDependsOnScriptCheckbox"
                                      id="rootDependsOnScriptCheckbox"
                                      className="relative mr-2 border-3 rounded border-black steps-checkbox"
                                      onChange={checkUncheckAllDependsOnScripts}
                                    />
                                  )}
                                  <b
                                    className="fontPoppinsSemibold
                                Md"
                                  >
                                    Depends On Scripts
                                  </b>
                                  <button
                                    className="cursor-pointer arrow-class "
                                    onClick={() => {
                                      expandCollapse('dependscript', 'rightarrow');
                                    }}
                                  >
                                    <div
                                      style={{
                                        margin: '-90%',
                                        visibility: 'hidden',
                                      }}
                                    >
                                      .
                                    </div>
                                    <img
                                      id="dependscript-rightarrow"
                                      className="cursor-pointer "
                                      onClick={() => {
                                        expandCollapse('dependscript', 'rightarrow');
                                      }}
                                      src="/assets/images/arrow_right_black_filled.svg"
                                      alt="search"
                                      width="24"
                                      height="24"
                                    />
                                  </button>
                                  <button
                                    className="cursor-pointer arrow-class"
                                    onClick={() => {
                                      expandCollapse('dependscript', 'downarrow');
                                    }}
                                  >
                                    <div
                                      style={{
                                        margin: '-90%',
                                        visibility: 'hidden',
                                      }}
                                    >
                                      .
                                    </div>
                                    <img
                                      id="dependscript-downarrow"
                                      className="hidden cursor-pointer"
                                      onClick={() => {
                                        expandCollapse('dependscript', 'downarrow');
                                      }}
                                      src="/assets/images/arrow_drop_down_black.svg"
                                      alt="search"
                                      width="24"
                                      height="24"
                                    />
                                  </button>
                                  {showDependsOnScriptsResultHeader && getStatusStats(dependScriptStats)}
                                </span>
                              </td>
                              <td className="p-px text-left fontPoppinsRegularMd lg:fontPoppinsRegularMd xl:fontPoppinsRegularLg">
                                {showDependsOnScriptsResultHeader && dependScriptStats ? (
                                  <span className={`mt-2 pl-5 truncate ${textColor(getOverallStatus('dependant'))}`}>
                                    {' '}
                                    {getOverallMessage(getOverallStatus('dependant'), 'dependant')}{' '}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td className="p-px text-left fontPoppinsRegularMd lg:fontPoppinsRegularMd xl:fontPoppinsRegularLg">
                                {showDependsOnScriptsResultHeader && dependScriptStats ? (
                                  <span className={`mt-2 pl-5 truncate ${textColor(getOverallStatus('dependant'))}`}>
                                    {' '}
                                    {getFormattedTextStatus(getOverallStatus('dependant'))}{' '}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td className="p-px text-left fontPoppinsRegularMd"></td>
                            </tr>
                          ) : null}
                          {dependScriptData.map((a, index) => {
                            let stepResult = individualScriptResult?.dependantScriptResult
                              ? individualScriptResult?.dependantScriptResult[a.stepId]
                              : {};
                            return (
                              <tr
                                key={a.stepId + index}
                                className="configHover h-12 content-start dependent-script-row myRoww hidden relative"
                                id={`dependscript-${index}`}
                              >
                                <td className="p-px text-left fontPoppinsRegularMd"></td>
                                <td className="p-px text-left fontPoppinsRegularMd">
                                  <div className="precondition-container">
                                    <span className="w-2 min-w-full checked-box">
                                      {(isEditable || moduleName !== 'repository') && (
                                        <input
                                          type="checkbox"
                                          onClick={(event) => {
                                            handleCheckedData(event, a, index, 'dependscript');
                                          }}
                                          name=""
                                          checked={dependentScriptChecked.includes(index)}
                                          id={`dependscriptcheckbox-${index + 1}`}
                                          className="relative mr-2 border-3 rounded border-black actionIcons steps-checkbox"
                                        />
                                      )}
                                    </span>
                                    <Tooltip title={a?.name} placement="top">
                                      <span
                                        className="fontPoppinsRegularMd text-blue-700 mr-1 cursor-pointer length-script"
                                        onClick={() => {
                                          // myCssMethod(index);
                                          updateDependsOnScripts(a, index);
                                        }}
                                      >
                                        {index + 1}. {a?.name}
                                      </span>
                                    </Tooltip>
                                  </div>
                                </td>
                                <td className="p-px text-left fontPoppinsRegularMd lg:fontPoppinsRegularMd xl:fontPoppinsRegularLg">
                                  <span className={`mt-2 pl-5 step-result-truncate ${textColor(stepResult?.status)}`}>
                                    {' '}
                                    {stepResult?.message}{' '}
                                  </span>
                                </td>
                                <td className="p-px text-left fontPoppinsRegularMd lg:fontPoppinsRegularMd xl:fontPoppinsRegularLg">
                                  <span className={`mt-2 pl-5 truncate ${textColor(stepResult?.status)}`}>
                                    <div className=" pl-5 status_message pb-3">
                                      {getFormattedTextStatus(stepResult?.status)}
                                      {getFormattedTextStatus(stepResult?.status) === 'Failed' && isEditable && (
                                        <BugIcon
                                          MyAlert={props?.MyAlert}
                                          issueData={{
                                            section: 'steps',
                                            nodeObj: { data: scriptsFromApi },
                                            stepsDetails: {
                                              data: {
                                                type: 'dependsOn',
                                                stepId: a.stepId,
                                              },
                                              scriptResultData: scriptResultData,
                                            },
                                            systemData: selectedSystem,
                                            executionId: scriptResultData?.executionId,
                                          }}
                                        />
                                      )}
                                    </div>
                                  </span>
                                </td>
                                <td className="p-0 pl-5 text-left fontPoppinsRegularMd ">
                                  <span className="actionIcons">
                                    {isProjectOpen && (
                                      <Tooltip title="Run Until">
                                        <svg
                                          className="mr-2 float-left mt-1 cursor-pointer"
                                          xmlns="http://www.w3.org/2000/svg"
                                          height="24px"
                                          viewBox="0 0 24 24"
                                          width="24px"
                                          fill="#1648c6"
                                          onClick={() => {
                                            runUntil(index, 'dependscript');
                                          }}
                                        >
                                          <path d="M10.8 15.9l4.67-3.5c.27-.2.27-.6 0-.8L10.8 8.1c-.33-.25-.8-.01-.8.4v7c0 .41.47.65.8.4zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                                        </svg>
                                      </Tooltip>
                                    )}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}

                          {preConditionData.length ? (
                            <tr className="configHover h-12 content-start myRoww">
                              <td className="p-px text-left fontPoppinsRegularMd"></td>
                              <td className="p-px text-left fontPoppinsRegularMd">
                                <span>
                                  {(isEditable || moduleName !== 'repository') && (
                                    <input
                                      disabled={preConditionData.length === 0}
                                      type="checkbox"
                                      name="rootPreConditionCheckbox"
                                      id="rootPreConditionCheckbox"
                                      className="relative mr-2 border-3 rounded border-black steps-checkbox"
                                      onChange={checkUncheckAllPreConditions}
                                    />
                                  )}
                                  <b className="fontPoppinsSemiboldMd">Pre Condition</b>
                                  <button
                                    className="cursor-pointer arrow-class "
                                    onClick={() => {
                                      expandCollapse('precondition', 'rightarrow');
                                    }}
                                  >
                                    <div
                                      style={{
                                        margin: '-90%',
                                        visibility: 'hidden',
                                      }}
                                    >
                                      .
                                    </div>
                                    <img
                                      id="precondition-rightarrow"
                                      className="cursor-pointer "
                                      onClick={() => {
                                        expandCollapse('precondition', 'rightarrow');
                                      }}
                                      src="/assets/images/arrow_right_black_filled.svg"
                                      alt="search"
                                      width="24"
                                      height="24"
                                    />
                                  </button>
                                  <button
                                    className="cursor-pointer arrow-class"
                                    onClick={() => {
                                      expandCollapse('precondition', 'downarrow');
                                    }}
                                  >
                                    <div
                                      style={{
                                        margin: '-90%',
                                        visibility: 'hidden',
                                      }}
                                    >
                                      .
                                    </div>
                                    <img
                                      id="precondition-downarrow"
                                      className="hidden cursor-pointer "
                                      onClick={() => {
                                        expandCollapse('precondition', 'downarrow');
                                      }}
                                      src="/assets/images/arrow_drop_down_black.svg"
                                      alt="search"
                                      width="24"
                                      height="24"
                                    />
                                  </button>
                                  {showPreConditionResultHeader && getStatusStats(preConditionStats)}
                                </span>
                              </td>
                              <td className="p-px text-left fontPoppinsRegularMd lg:fontPoppinsRegularMd xl:fontPoppinsRegularLg">
                                {showPreConditionResultHeader && preConditionStats ? (
                                  <span
                                    className={`mt-2 pl-5 truncate ${textColor(getOverallStatus('preconditions'))}`}
                                  >
                                    {' '}
                                    {getOverallMessage(getOverallStatus('preconditions'), 'preconditions')}{' '}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td className="p-px text-left fontPoppinsRegularMd lg:fontPoppinsRegularMd xl:fontPoppinsRegularLg">
                                {showPreConditionResultHeader && preConditionStats ? (
                                  <span
                                    className={`mt-2 pl-5 truncate ${textColor(getOverallStatus('preconditions'))}`}
                                  >
                                    {' '}
                                    {getFormattedTextStatus(getOverallStatus('preconditions'))}{' '}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td className="p-px text-left fontPoppinsRegularMd"></td>
                            </tr>
                          ) : null}
                          {preConditionData.map((a, index) => {
                            let stepResult = individualScriptResult?.preConditionResult
                              ? individualScriptResult?.preConditionResult[a.stepId]
                              : {};
                            return (
                              <tr
                                key={a.stepId + index}
                                className={` ${
                                  showPrePostConditionTableData?.showPreConditionTableData ? 'visible ' : 'hidden '
                                } configHover h-12 content-start myRoww pre-condition-row relative `}
                                id={`precondition-${index}`}
                              >
                                <td className="p-0 pl-2 pr-3 text-left fontPoppinsRegularMd"></td>
                                <td className="p-px text-left fontPoppinsRegularMd">
                                  <div className="precondition-container">
                                    <span className="w-2 min-w-full checked-box">
                                      {(isEditable || moduleName !== 'repository') && (
                                        <input
                                          checked={preConditionChecked.includes(index)}
                                          type="checkbox"
                                          onClick={(event) => {
                                            handleCheckedData(event, a, index, 'precondition');
                                          }}
                                          name=""
                                          id={`preconditioncheckbox-${index + 1}`}
                                          className="relative mr-2 border-3 rounded border-black actionIcons steps-checkbox"
                                        />
                                      )}
                                    </span>
                                    <Tooltip
                                      placement="top"
                                      title={`${a?.stepGroupName} ${
                                        a.cascaded === 'cascaded' ? ` - Cascaded from ${a.moduleName}` : ''
                                      }`}
                                    >
                                      <span
                                        id={a?.stepId}
                                        className="fontPoppinsRegularMd lg:fontPoppinsRegularMd xl:fontPoppinsRegularLg text-blue-700 mr-1 cursor-pointer length-script"
                                        onClick={() => {
                                          updatePreCondition(a, index);
                                        }}
                                      >
                                        {index + 1}. {a?.stepGroupName}{' '}
                                        {a.cascaded === 'cascaded' ? ` - Cascaded from ${a.moduleName}` : null}
                                      </span>
                                    </Tooltip>
                                  </div>
                                </td>
                                <td className="p-px text-left fontPoppinsRegularMd lg:fontPoppinsRegularMd xl:fontPoppinsRegularLg">
                                  <span className={`mt-2 pl-5 step-result-truncate ${textColor(stepResult?.status)}`}>
                                    {' '}
                                    {stepResult?.message}{' '}
                                  </span>
                                </td>
                                <td className="p-px text-left fontPoppinsRegularMd lg:fontPoppinsRegularMd xl:fontPoppinsRegularLg">
                                  <span className={`mt-2 pl-5 truncate ${textColor(stepResult?.status)}`}>
                                    <div className="pl-5 status_message pb-3">
                                      {getFormattedTextStatus(stepResult?.status)}
                                      {getFormattedTextStatus(stepResult?.status) === 'Failed' && isEditable && (
                                        <BugIcon
                                          MyAlert={props?.MyAlert}
                                          issueData={{
                                            section: 'steps',
                                            nodeObj: { data: scriptsFromApi },
                                            stepsDetails: {
                                              data: {
                                                type: 'preCondition',
                                                stepId: a.stepId,
                                              },
                                              scriptResultData: scriptResultData,
                                            },
                                            systemData: selectedSystem,
                                            executionId: scriptResultData?.executionId,
                                          }}
                                        />
                                      )}
                                    </div>
                                  </span>
                                </td>
                                <td className="p-0 pl-5 text-left fontPoppinsRegularMd ">
                                  <span className="actionIcons">
                                    {isProjectOpen && (
                                      <Tooltip title="Run Until">
                                        <svg
                                          className="mr-2 float-left mt-1 cursor-pointer"
                                          xmlns="http://www.w3.org/2000/svg"
                                          height="24px"
                                          viewBox="0 0 24 24"
                                          width="24px"
                                          fill="#1648c6"
                                          onClick={() => {
                                            runUntil(index, 'precondition');
                                          }}
                                        >
                                          <path d="M10.8 15.9l4.67-3.5c.27-.2.27-.6 0-.8L10.8 8.1c-.33-.25-.8-.01-.8.4v7c0 .41.47.65.8.4zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                                        </svg>
                                      </Tooltip>
                                    )}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}

                          {props.type !== 'Step Group' ? (
                            <>
                              <tr className="configHover h-12 content-start myRoww">
                                <td className="p-px text-left fontPoppinsRegularMd"></td>
                                <td className="p-px text-left fontPoppinsRegularMd">
                                  <span>
                                    {(isEditable || moduleName !== 'repository') && (
                                      <input
                                        disabled={data.length === 0 || disableAllSteps}
                                        type="checkbox"
                                        name="rootStepsCheckbox"
                                        id="rootStepsCheckbox"
                                        className={`relative mr-2 border-3 rounded border-black steps-checkbox ${
                                          disableAllSteps ? 'opacity-40' : 'opacity-100'
                                        }`}
                                        onChange={checkUncheckAllSteps}
                                      />
                                    )}
                                    <b className="fontPoppinsSemiboldMd">Steps</b>
                                    <button
                                      disabled={data.length === 0}
                                      className="cursor-pointer arrow-class "
                                      onClick={() => {
                                        expandCollapse('steps', 'rightarrow');
                                      }}
                                    >
                                      <div
                                        style={{
                                          margin: '-90%',
                                          visibility: 'hidden',
                                        }}
                                      >
                                        .
                                      </div>
                                      <img
                                        id="steps-rightarrow"
                                        className="hidden cursor-pointer "
                                        src="/assets/images/arrow_right_black_filled.svg"
                                        alt="search"
                                        width="24"
                                        height="24"
                                      />
                                    </button>
                                    <button
                                      disabled={data.length === 0}
                                      className="cursor-pointer arrow-class"
                                      onClick={() => {
                                        expandCollapse('steps', 'downarrow');
                                      }}
                                    >
                                      <div
                                        style={{
                                          margin: '-90%',
                                          visibility: 'hidden',
                                        }}
                                      >
                                        .
                                      </div>
                                      <img
                                        id="steps-downarrow"
                                        className="arrow-class"
                                        src="/assets/images/arrow_drop_down_black.svg"
                                        alt="search"
                                        width="24"
                                        height="24"
                                      />
                                    </button>
                                    {data.length > 0 && showStepResultHeader && getStatusStats(stepResultStats)}
                                  </span>
                                </td>

                                <td className="p-px text-left fontPoppinsRegularMd">
                                  {!noSteps && stepResultStats && showStepResultHeader ? (
                                    <span className={`mt-2 pl-5 truncate ${textColor(getOverallStatus('steps'))}`}>
                                      {' '}
                                      {getOverallMessage(getOverallStatus('steps'), 'steps')}
                                    </span>
                                  ) : (postConditionData.length || preConditionData.length) &&
                                    noSteps &&
                                    data.length === 0 ? (
                                    preConditionData.length > 0 && postConditionData.length > 0 ? (
                                      <div className="fontPoppinsRegularMd">
                                        {isEditable && (
                                          <div className="mt-5">
                                            <p className="font-bold mb-2 text-left">Quick Start</p>
                                            <p className="mb-2 text-left flex flex-nowrap stepgroup-button-width-101">
                                              <span className="font-bold">Step 1 :</span> Click
                                              <button
                                                onClick={() => {
                                                  setMode('create');
                                                  setSelectedStepIndex();
                                                  setNoSteps(false);
                                                  setOpenCreatePreCondition(true);
                                                }}
                                                className={`ml-3 mr-3  -mt-1 ${
                                                  props?.type ? 'secondary-btn' : 'primary-btn'
                                                }`}
                                              >
                                                + Step
                                              </button>
                                              to start adding the Steps
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div
                                        className={`fontPoppinsRegularMd ${
                                          preConditionData.length > 0 ? 'quickstartcontent' : ''
                                        }`}
                                      >
                                        {isEditable && (
                                          <div className="mt-5">
                                            <p className="font-bold mb-2 text-left">Quick Start</p>
                                            <p className="mb-2 text-left flex flex-nowrap stepgroup-button-width-101">
                                              <span className="font-bold">Step 1 :</span> Click
                                              <button
                                                onClick={() => {
                                                  setMode('create');
                                                  setSelectedStepIndex();
                                                  setNoSteps(false);
                                                  setOpenCreatePreCondition(true);
                                                }}
                                                className={`ml-3 mr-3  -mt-1 ${
                                                  props?.type ? 'secondary-btn' : 'primary-btn'
                                                }`}
                                              >
                                                + Step
                                              </button>
                                              to start adding the Steps
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                    )
                                  ) : stepResultStats && showStepResultHeader ? (
                                    <span className={`mt-2 pl-5 truncate ${textColor(getOverallStatus('steps'))}`}>
                                      {' '}
                                      {getOverallMessage(getOverallStatus('steps'), 'steps')}{' '}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </td>
                                <td className="p-px text-left fontPoppinsRegularMd">
                                  {stepResultStats && showStepResultHeader ? (
                                    <span className={`mt-2 pl-5 truncate ${textColor(getOverallStatus('steps'))}`}>
                                      {' '}
                                      {getFormattedTextStatus(getOverallStatus('steps'))}{' '}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </td>
                                {!hasFullAccess || project?.status === 'Closed' || isDefaultStepGroup ? (
                                  <td></td>
                                ) : (
                                  <td className="p-px text-left fontPoppinsRegularMd">
                                    {data?.length ? (
                                      <span>
                                        <Tooltip
                                          title={`${
                                            isStepCheckBoxSelected
                                              ? SCRIPT_STEPS.DELETE_SELECTED_STEPS
                                              : SCRIPT_STEPS.SELECT_STEPS
                                          }`}
                                        >
                                          <DeleteOutlinedIcon
                                            id="multi-step-delete"
                                            className={`float-left ml-6 mt-1  text-blue-700 text-base ${
                                              isStepCheckBoxSelected ? 'opacity-100	cursor-pointer' : 'opacity-40'
                                            }`}
                                            onClick={() => {
                                              if (isStepCheckBoxSelected) alertReturn();
                                            }}
                                          />
                                        </Tooltip>
                                      </span>
                                    ) : null}
                                  </td>
                                )}
                                <td className="p-px text-left fontPoppinsRegularMd"></td>
                              </tr>
                            </>
                          ) : null}
                          {data.map((row, index) => {
                            if (index === 0) {
                              serialNo = 1;
                            }
                            let stepResult = individualScriptResult?.stepResult
                              ? individualScriptResult?.stepResult[row.stepId]
                              : {};
                            if (!excludedNlpTypes.includes(row?.type) && !excludedNlpNames.includes(row?.nlpName)) {
                              row['slNo'] = serialNo;
                              serialNo++;
                              setScriptRowIndex(serialNo);
                            }
                            if (startNlpList.includes(row?.type) && scripts) {
                              setDataproviderStepResultAndStatus(scripts, index, scriptResultData);
                            }
                            return (
                              <>
                                {row?.type !== 'NLP-field' ? (
                                  <Draggable
                                    isDragDisabled={
                                      !isEditable ||
                                      openStepGroupInputParameterDiv ||
                                      openPreConditionStepGroupInputParameterDiv ||
                                      openPostConditionStepGroupInputParameterDiv
                                    }
                                    key={'dragRow' + index}
                                    draggableId={'dragRow' + index}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <tr
                                        key={`tableRow${index}`}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        className={`configHover h-12 content-start myRow lastStep ${
                                          row?.stepId === stepGroupInputParameterDivData?.stepId
                                            ? 'text-left shadow-md bg-white h-10 pl-8'
                                            : ''
                                        }`}
                                        id={`steps-${index}`}
                                      >
                                        <td
                                          className="p-0 pl-2 pr-3 text-left fontPoppinsRegularMd"
                                          key={`drag-${index}`}
                                        >
                                          {!startNlpList.includes(row.type) &&
                                          !endNlpList.includes(row.type) &&
                                          !endConditionObject.includes(row?.nlpName) &&
                                          !startConditionObject.includes(row?.nlpName) &&
                                          defaultStepGroup !== 'true' &&
                                          data.length > 1 &&
                                          isEditable ? (
                                            <span
                                              className={row.isDisabled ? 'invisible' : 'actionIcons'}
                                              {...provided.dragHandleProps}
                                            >
                                              {row?.displayName?.length >= 70 &&
                                              row.type === 'step' &&
                                              props.type !== 'Step Group' ? (
                                                <DragIndicatorOutlined
                                                  fontSize="small"
                                                  className="text-blue-600 mb-6"
                                                />
                                              ) : (
                                                <DragIndicatorOutlined fontSize="small" className="text-blue-600 " />
                                              )}
                                            </span>
                                          ) : (
                                            <span className="actionIcons" {...provided.dragHandleProps}></span>
                                          )}
                                        </td>
                                        <td className="p-px text-left fontPoppinsRegularMd pr-3" key={`desc-${index}`}>
                                          {stepIndex - 1 === index && editData !== null && isEditStep ? (
                                            openStepGroupInputParameterDiv ? (
                                              <span className={`pt-2 w-full text-xs text-blue-700`}>
                                                {index + 1}. {stepGroupInputParameterDivData.displayName}
                                              </span>
                                            ) : (
                                              <div className="inline-block w-full">
                                                {/* disabling select row effect for end iteration step */}
                                                {!endNlpList.includes(row.type) &&
                                                !endConditionObject.includes(row?.nlpName)
                                                  ? myCssMethod(index)
                                                  : ''}
                                                <NlpSuggestion
                                                  editData={
                                                    row?.nlpId?.includes('PE_NLP') ||
                                                    row?.nlpType?.includes('PROGRAM_ELEMENTS')
                                                      ? row.description || row.displayName
                                                      : row.displayName
                                                  }
                                                  handleNlpData={handleNlpData}
                                                  handleConditionNlpObject={handleConditionNlpObject}
                                                  openStepgroupInputDiv={openStepGroupDiv}
                                                  scriptRowIndex={scriptRowIndex}
                                                  script={scripts}
                                                  stepGroup={stepGroup}
                                                  type={props.type}
                                                  previousStepData={previousStepData}
                                                  drpPos={true}
                                                  removeInputField={removeInputField}
                                                  closeModal={closeModal}
                                                  steps={row}
                                                  selectedStepIndex={selectedStepIndex}
                                                  getExecutionOrder={getExecutionOrder}
                                                  conditionId={conditionId}
                                                  conditionSearchKey={conditionSearchKey}
                                                  dataProviderVariablesArray={dataProviderVariablesArray}
                                                />
                                              </div>
                                            )
                                          ) : (
                                            <div className="flex items-center">
                                              <span className="flex">
                                                {(isEditable || moduleName !== 'repository') &&
                                                  (props.type === 'Step Group' ? (
                                                    <input
                                                      type="checkbox"
                                                      onClick={(event) => {
                                                        handleChecked(event, row, index);
                                                      }}
                                                      name=""
                                                      disabled={
                                                        !endNlpList.includes(row?.type) &&
                                                        !endConditionObject.includes(row?.nlpName)
                                                          ? false
                                                          : true
                                                      }
                                                      id={`StepCheckbox-${index + 1}`}
                                                      className={`relative mr-2 border-3 rounded border-black ${
                                                        row.isDisabled ? 'invisible' : 'actionIcons'
                                                      } steps-checkbox ${
                                                        !endNlpList.includes(row?.type) &&
                                                        !endConditionObject.includes(row?.nlpName)
                                                          ? ''
                                                          : 'inputBox opacity-40'
                                                      }`}
                                                    />
                                                  ) : (
                                                    <input
                                                      type="checkbox"
                                                      onClick={(event) => {
                                                        handleChecked(event, row, index);
                                                      }}
                                                      name=""
                                                      disabled={
                                                        !endNlpList.includes(row?.type) &&
                                                        !endConditionObject.includes(row?.nlpName)
                                                          ? false
                                                          : true
                                                      }
                                                      id={`StepCheckbox-${index + 1}`}
                                                      className={`relative mr-2 border-3 rounded border-black ${
                                                        row.isDisabled ? 'invisible' : 'actionIcons'
                                                      } steps-checkbox ${
                                                        !endNlpList.includes(row?.type) &&
                                                        !endConditionObject.includes(row?.nlpName)
                                                          ? ''
                                                          : 'inputBox opacity-40'
                                                      }`}
                                                    />
                                                  ))}
                                              </span>
                                              <span
                                                className="fontPoppinsRegularMd text-blue-700 mr-1 cursor-pointer"
                                                onClick={() => {
                                                  if (isEditable && !isLoading && !row.isDisabled) {
                                                    setMode('update');
                                                    setSelectedStepIndex(index);
                                                    setAction('update');
                                                    if (row?.nlpName?.toLowerCase() === 'runwebservice') {
                                                      getWebserviceUpdatedPath(row, index);
                                                    } else {
                                                      if (openPostConditionStepGroupInputParameterDiv) {
                                                        closePostConditionsModal();
                                                      } else if (openPreConditionStepGroupInputParameterDiv) {
                                                        closePreConditionsModal();
                                                      } else if (openDependsOnScriptsDiv) {
                                                        closeDependsOnScriptModal();
                                                      }
                                                      if (addStepAtIndex) {
                                                        if (index < addStepAtIndex) {
                                                          setStepIndex(index + 1);
                                                          myCssMethod(index);
                                                        } else {
                                                          setStepIndex(index);
                                                          myCssMethod(index - 1);
                                                        }
                                                        removeEmptyStep(null, addStepAtIndex);
                                                      } else {
                                                        if (
                                                          !endNlpList.includes(row?.type) &&
                                                          !endConditionObject.includes(row?.nlpName)
                                                        ) {
                                                          setStepIndex(index + 1);
                                                          myCssMethod(index);
                                                        }
                                                      }
                                                      if (openCreatePreCondition) {
                                                        setOpenCreatePreCondition(false);
                                                      }
                                                      if (
                                                        !endNlpList.includes(row?.type) &&
                                                        !endConditionObject.includes(row?.nlpName)
                                                      ) {
                                                        setEditPreCondition(true);
                                                        openStepGroupDiv(row, index);
                                                      }
                                                    }
                                                  }
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    marginLeft: row.marginLeft ? row.marginLeft + 'em' : '',
                                                  }}
                                                  className={`${
                                                    row?.isDisabled
                                                      ? 'text-gray-300 cursor-default'
                                                      : 'hyper_link_color-common'
                                                  } flex-wrapper`}
                                                >
                                                  <span className="mr-1">
                                                    {!excludedNlpTypes.includes(row?.type) &&
                                                    !excludedNlpNames.includes(row?.nlpName)
                                                      ? `${row?.slNo}.`
                                                      : ' '}
                                                  </span>
                                                  <span>
                                                    {openStepGroupInputParameterDiv ||
                                                    openDependsOnScriptsDiv ||
                                                    openPostConditionStepGroupInputParameterDiv ||
                                                    openPreConditionStepGroupInputParameterDiv ? (
                                                      <span
                                                        className={`${
                                                          excludedNlpTypes.includes(row?.type) ||
                                                          excludedNlpNames.includes(row?.nlpName)
                                                            ? 'step-iteration-header'
                                                            : ''
                                                        } step-name-truncate`}
                                                      >
                                                        {row.nlpType === 'PROGRAM_ELEMENTS' ||
                                                        row?.nlpId?.includes('PE_NLP')
                                                          ? row?.description || row?.displayName
                                                          : row?.displayName}{' '}
                                                        {row?.type === 'Group' ? '- Step Group' : ''}
                                                      </span>
                                                    ) : (
                                                      <Tooltip
                                                        title={
                                                          row?.toolTip
                                                            ? row.nlpType === 'PROGRAM_ELEMENTS' ||
                                                              row?.nlpId?.includes('PE_NLP')
                                                              ? `${row?.defaultDisplayName} in ${row?.toolTip
                                                                  .split(':')
                                                                  .splice(0, 1)}`
                                                              : row?.toolTip
                                                            : ''
                                                        }
                                                        placement="top"
                                                      >
                                                        <span
                                                          id={row?.stepId}
                                                          className={`${
                                                            excludedNlpTypes.includes(row?.type) ||
                                                            excludedNlpNames.includes(row?.nlpName)
                                                              ? 'step-iteration-header'
                                                              : ''
                                                          } ${
                                                            props?.type === 'Step Group'
                                                              ? 'stepgroup-step-name-truncate'
                                                              : 'step-name-truncate'
                                                          } step-break`}
                                                        >
                                                          {row.nlpType === 'PROGRAM_ELEMENTS' ||
                                                          row?.nlpId?.includes('PE_NLP')
                                                            ? row?.description || row?.displayName
                                                            : row?.displayName}{' '}
                                                          {row?.type === 'Group' ? '- Step Group' : ''}
                                                        </span>
                                                      </Tooltip>
                                                    )}
                                                  </span>
                                                </div>
                                              </span>
                                              <span className="flex">
                                                {renderAddButton() ? (
                                                  <button
                                                    disabled={
                                                      openStepGroupInputParameterDiv ||
                                                      openPreConditionStepGroupInputParameterDiv ||
                                                      openPostConditionStepGroupInputParameterDiv
                                                    }
                                                    className={
                                                      openStepGroupInputParameterDiv ||
                                                      openPreConditionStepGroupInputParameterDiv ||
                                                      openPostConditionStepGroupInputParameterDiv
                                                        ? 'HideAddStepBelow'
                                                        : ' ShowAddStepBelow actionIcons cursor-pointer'
                                                    }
                                                    onClick={(ev) => {
                                                      setMode('create');
                                                      setConditionSearchKey(row?.conditionSearchKey);
                                                      setConditionId(row?.conditionId);
                                                      setSelectedStepIndex(index + 1);
                                                      setAction('create');
                                                      setdataProviderVariablesArray(row?.dataProviderVariables);
                                                      if (defaultStepGroup) {
                                                        if (defaultStepGroup === 'false') {
                                                          addStepBelow(ev, row, index);
                                                        }
                                                      } else {
                                                        addStepBelow(ev, row, index);
                                                      }
                                                    }}
                                                  >
                                                    <Tooltip title="Add" placement="top">
                                                      <AddOutlined
                                                        fontSize="small"
                                                        className={`fontPoppinsRegularMd ml-1 text-blue-700 ${
                                                          defaultStepGroup
                                                            ? defaultStepGroup === 'false'
                                                              ? 'opacity-100	cursor-pointer actionIcons'
                                                              : 'opacity-50	actionIcons cursor-default'
                                                            : 'opacity-100 actionIcons'
                                                        }`}
                                                      />
                                                    </Tooltip>
                                                  </button>
                                                ) : (
                                                  ''
                                                )}
                                                <span className="pl-2">
                                                  {startNlpList.includes(row.type)
                                                    ? getStatusStats(stepResult?.stepResultStats)
                                                    : ''}
                                                </span>
                                              </span>
                                            </div>
                                          )}
                                        </td>
                                        {props.type === 'Step Group' ? null : (
                                          <>
                                            <td className="p-0 pl-5 text-left fontPoppinsRegularMd">
                                              <span className="step-result step-result-truncate pr-3">
                                                <div className="flex">
                                                  {!['_startforloop', '_endforloop'].includes(row?.type) && (
                                                    <>
                                                      <Tooltip title={stepResult?.message} placement="bottom-start">
                                                        <span
                                                          className={`mt-2 step-result-truncate ${textColor(
                                                            stepResult?.status
                                                          )}`}
                                                        >
                                                          {stepResult?.message}{' '}
                                                        </span>
                                                      </Tooltip>
                                                      {stepResult?.status === 'Terminated' && (
                                                        <Tooltip
                                                          title={
                                                            'Following steps will be skipped as the execution was terminated at this step'
                                                          }
                                                          placement="right-end"
                                                        >
                                                          <div className="mt-2 ml-1">
                                                            {' '}
                                                            <FiInfo />
                                                          </div>
                                                        </Tooltip>
                                                      )}
                                                    </>
                                                  )}
                                                </div>
                                              </span>
                                            </td>
                                            <td className="p-0 pl-5 text-left fontPoppinsRegularMd">
                                              <span
                                                className={
                                                  row?.type === 'Step Group' ? `${textColor(stepResult?.status)}` : ``
                                                }
                                              >
                                                <div className="status_message">
                                                  {!['_startforloop', '_endforloop'].includes(row?.type) && (
                                                    <>
                                                      <div
                                                        className={`cursor-pointer ${textColor(stepResult?.status)}`}
                                                        onClick={() => {
                                                          getData(scriptResultData?.executionId);
                                                          viewStepResultModal(row, stepResult);
                                                        }}
                                                      >
                                                        {getFormattedTextStatus(
                                                          getFormattedStatusIfElse(stepResult?.status, row)
                                                        )}
                                                      </div>
                                                      {getFormattedTextStatus(stepResult?.status) === 'Failed' &&
                                                        isEditable && (
                                                          <BugIcon
                                                            MyAlert={props?.MyAlert}
                                                            issueData={{
                                                              section: 'steps',
                                                              nodeObj: {
                                                                data: scriptsFromApi,
                                                              },
                                                              stepsDetails: {
                                                                data: {
                                                                  type: 'steps',
                                                                  stepId: row.stepId,
                                                                },
                                                                scriptResultData: scriptResultData,
                                                              },
                                                              systemData: selectedSystem,
                                                              executionId: scriptResultData?.executionId,
                                                            }}
                                                          />
                                                        )}
                                                    </>
                                                  )}
                                                </div>
                                              </span>
                                            </td>
                                          </>
                                        )}
                                        <td className="p-0 pl-5 text-left fontPoppinsRegularMd ">
                                          <span className="actionIcons">
                                            {props.type === 'Step Group' || !isProjectOpen ? null : (
                                              // opacity and disabled for iteration steps
                                              <Tooltip title="Run Until">
                                                <svg
                                                  className={`mr-2 float-left mt-1 cursor-pointer ${
                                                    allowRunUntil(row) ? 'opacity-100' : 'opacity-40'
                                                  }`}
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  height="24px"
                                                  viewBox="0 0 24 24"
                                                  width="24px"
                                                  fill="#1648c6"
                                                  onClick={() => {
                                                    const _flag = allowRunUntil(row);
                                                    if (_flag) {
                                                      runUntil(index, 'steps');
                                                    }
                                                  }}
                                                >
                                                  <path d="M10.8 15.9l4.67-3.5c.27-.2.27-.6 0-.8L10.8 8.1c-.33-.25-.8-.01-.8.4v7c0 .41.47.65.8.4zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                                                </svg>
                                              </Tooltip>
                                            )}
                                            {isEditable && (
                                              <>
                                                <Tooltip title="Edit">
                                                  <EditOutlinedIcon
                                                    className={`float-left  text-base mr-2 mt-1 text-blue-700 ${disableEditIcon(
                                                      row
                                                    )}`}
                                                    onClick={(e) => {
                                                      if (!row?.isDisabled) {
                                                        handleEditStepClick(index, row);
                                                        setAction('update');
                                                      }
                                                    }}
                                                  />
                                                </Tooltip>
                                                {hasFullAccess && (
                                                  <Tooltip title="Delete">
                                                    <DeleteOutlinedIcon
                                                      id="step-delete"
                                                      className={`float-left  mr-2 mt-1 text-blue-700 text-base ${
                                                        (!nonCloneSteps || defaultStepGroup) &&
                                                        !startNlpList.includes(row?.type) &&
                                                        !endNlpList.includes(row?.type)
                                                          ? defaultStepGroup === 'false' && allowDelete(row, index)
                                                            ? 'opacity-100	cursor-pointer'
                                                            : 'opacity-50	cursor-none'
                                                          : allowDelete(row, index)
                                                          ? 'opacity-100	cursor-pointer'
                                                          : 'opacity-50	cursor-none'
                                                      }`}
                                                      onClick={() => {
                                                        removeHighlighter();
                                                        // if they select checkbox we should allow the to edit or delete
                                                        if (allowDelete(row, index)) {
                                                          if (defaultStepGroup) {
                                                            if (defaultStepGroup === 'false') {
                                                              if (allowDeleteFlag) {
                                                                setAllowDeleteFlag(false);
                                                                deleteSteps(row, index);
                                                              }
                                                            }
                                                          } else {
                                                            if (allowDeleteFlag) {
                                                              setAllowDeleteFlag(false);
                                                              deleteSteps(row, index);
                                                            }
                                                          }
                                                        }
                                                      }}
                                                    />
                                                  </Tooltip>
                                                )}
                                                <Tooltip title="More">
                                                  <div className="inline-block cursor-pointer">
                                                    <Menu
                                                      as="div"
                                                      className={`relative inline-block text-left mt-px mt-1.5 ${
                                                        allowMoreActionsMenu(row)
                                                          ? 'opacity-100'
                                                          : 'opacity-40	pointer-events-none'
                                                      }`}
                                                    >
                                                      {({ open }) => (
                                                        <>
                                                          <div>
                                                            <Menu.Button
                                                              className={`bg-gray-100 rounded-full flex items-center  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 focus:ring-indigo-500 ${
                                                                defaultStepGroup &&
                                                                !startNlpList.includes(row?.type) &&
                                                                !endNlpList.includes(row?.type)
                                                                  ? defaultStepGroup === 'false'
                                                                    ? 'opacity-100	cursor-pointer'
                                                                    : 'opacity-50	cursor-none'
                                                                  : startNlpList.includes(row?.type) ||
                                                                    endNlpList.includes(row?.type)
                                                                  ? 'opacity-50	cursor-none'
                                                                  : allowDelete(row, index)
                                                                  ? 'opacity-100	cursor-pointer'
                                                                  : 'opacity-50	cursor-none'
                                                              }`}
                                                              onMouseOver={(e) => {
                                                                let mouse = e.clientY;
                                                                setPageIndex(mouse);
                                                              }}
                                                            >
                                                              <span className="sr-only">Open options</span>
                                                              <DotsVerticalIcon
                                                                className="h-5 w-5 configmoreIcon"
                                                                aria-hidden="true"
                                                              />
                                                            </Menu.Button>
                                                          </div>
                                                          {(row.type === 'step' &&
                                                            !nonCloneSteps.includes(row.nlpId) &&
                                                            !nonCloneSteps.includes(row.name) &&
                                                            !(defaultStepGroup === 'true')) ||
                                                          row?.type === 'Group' ? (
                                                            <Transition
                                                              show={open}
                                                              as={Fragment}
                                                              enter="transition ease-out duration-100"
                                                              enterFrom="transform opacity-0 scale-95"
                                                              enterTo="transform opacity-100 scale-100"
                                                              leave="transition ease-in duration-75"
                                                              leaveFrom="transform opacity-100 scale-100"
                                                              leaveTo="transform opacity-0 scale-95"
                                                            >
                                                              <Menu.Items
                                                                static
                                                                className="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                style={{
                                                                  top: `${pageIndex}%`,
                                                                  marginRight: `${margin}%`,
                                                                  width: '215px',
                                                                }}
                                                              >
                                                                <div className="py-1">
                                                                  <Menu.Item>
                                                                    {({ active }) => (
                                                                      <div
                                                                        className="menu-item"
                                                                        onClick={() => {
                                                                          cloneStep(row, index);
                                                                        }}
                                                                      >
                                                                        <button
                                                                          className={classNames(
                                                                            active
                                                                              ? 'bg-gray-200 hyper_link_color-common'
                                                                              : '',
                                                                            'text-gray-700 block w-full text-left px-4 py-2 fontPoppinsRegularMd'
                                                                          )}
                                                                        >
                                                                          Clone
                                                                        </button>
                                                                      </div>
                                                                    )}
                                                                  </Menu.Item>
                                                                  <Menu.Item>
                                                                    {({ active }) => (
                                                                      <div
                                                                        className="menu-item"
                                                                        onClick={() => {
                                                                          enableDisableStepGroup(
                                                                            row,
                                                                            index,
                                                                            !row?.isDisabled
                                                                          );
                                                                        }}
                                                                      >
                                                                        <button
                                                                          className={classNames(
                                                                            active
                                                                              ? 'bg-gray-200 hyper_link_color-common'
                                                                              : '',
                                                                            'text-gray-700 block w-full text-left px-4 py-2 fontPoppinsRegularMd'
                                                                          )}
                                                                        >
                                                                          {row?.isDisabled ? 'Enable' : 'Disable'}
                                                                        </button>
                                                                      </div>
                                                                    )}
                                                                  </Menu.Item>
                                                                </div>
                                                              </Menu.Items>
                                                            </Transition>
                                                          ) : null}
                                                        </>
                                                      )}
                                                    </Menu>
                                                  </div>
                                                </Tooltip>
                                              </>
                                            )}
                                          </span>
                                        </td>
                                      </tr>
                                    )}
                                  </Draggable>
                                ) : (
                                  <tr ref={_DOMNode} className="content-start">
                                    <td className="content-start" colSpan={5}>
                                      <div className="mt-2">
                                        {openStepGroupInputParameterDiv ? (
                                          <p
                                            style={{
                                              boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
                                            }}
                                            className="pt-2 text-left w-full text-xs text-blue-700 shadow-md bg-white h-10 pl-8"
                                            title={stepGroupInputParameterDivData?.name}
                                          >
                                            {stepGroupInputParameterDivData?.searchName}
                                          </p>
                                        ) : (
                                          nlpSuggestionBoxRenderer(index, row)
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </>
                            );
                          })}
                          {openCreatePreCondition ? (
                            <tr className="content-start">
                              <td className="content-start" colSpan={5}>
                                <div className="mt-2">
                                  {openStepGroupInputParameterDiv ? (
                                    <p
                                      style={{
                                        boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
                                      }}
                                      className="pt-2 text-left w-full text-xs text-blue-700 shadow-md bg-white h-10 pl-8"
                                      title={stepGroupInputParameterDivData?.name}
                                    >
                                      {stepGroupInputParameterDivData?.searchName}
                                    </p>
                                  ) : (
                                    <div className="w-100">
                                      <NlpSuggestion
                                        drpPos={false}
                                        handleNlpData={handleNlpData}
                                        openStepgroupInputDiv={openStepGroupDiv}
                                        handleConditionNlpObject={handleConditionNlpObject}
                                        scriptRowIndex={scriptRowIndex}
                                        script={scripts}
                                        {...props}
                                        previousStepData={previousStepData}
                                        stepGroup={stepGroup}
                                        removeInputField={removeInputField}
                                        closeModal={closeModal}
                                        selectedStepIndex={selectedStepIndex}
                                        getExecutionOrder={getExecutionOrder}
                                        conditionId={conditionId}
                                        conditionSearchKey={conditionSearchKey}
                                        dataProviderVariablesArray={dataProviderVariablesArray}
                                      />
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ) : null}
                          {postConditionData.length ? (
                            <tr className="configHover h-12 content-start myRoww">
                              <td className="p-px text-left fontPoppinsRegularMd"></td>
                              <td className="p-px text-left fontPoppinsRegularMd">
                                <span>
                                  {(isEditable || moduleName !== 'repository') && (
                                    <input
                                      disabled={postConditionData.length === 0}
                                      type="checkbox"
                                      name="rootPostConditionCheckbox"
                                      id="rootPostConditionCheckbox"
                                      className="mr-2 border-3 rounded border-black steps-checkbox"
                                      onChange={checkUncheckAllPostConditions}
                                    />
                                  )}
                                  <b className="fontPoppinsSemiboldMd">Post Condition</b>
                                  <button
                                    className="cursor-pointer arrow-class "
                                    onClick={() => {
                                      expandCollapse('postcondition', 'rightarrow');
                                    }}
                                  >
                                    <div
                                      style={{
                                        margin: '-90%',
                                        visibility: 'hidden',
                                      }}
                                    >
                                      .
                                    </div>
                                    <img
                                      id="postcondition-rightarrow"
                                      className="cursor-pointer"
                                      onClick={() => {
                                        expandCollapse('postcondition', 'rightarrow');
                                      }}
                                      src="/assets/images/arrow_right_black_filled.svg"
                                      alt="search"
                                      width="24"
                                      height="24"
                                    />
                                  </button>
                                  <button
                                    className="cursor-pointer arrow-class"
                                    onClick={() => {
                                      expandCollapse('postcondition', 'downarrow');
                                    }}
                                  >
                                    <div
                                      style={{
                                        margin: '-90%',
                                        visibility: 'hidden',
                                      }}
                                    >
                                      .
                                    </div>
                                    <img
                                      id="postcondition-downarrow"
                                      className="hidden cursor-pointer"
                                      onClick={() => {
                                        expandCollapse('postcondition', 'downarrow');
                                      }}
                                      src="/assets/images/arrow_drop_down_black.svg"
                                      alt="search"
                                      width="24"
                                      height="24"
                                    />
                                  </button>
                                  {showPostConditionResultHeader && getStatusStats(postConditionStats)}
                                </span>
                              </td>
                              <td className="p-px text-left fontPoppinsRegularMd">
                                {postConditionStats && showPostConditionResultHeader ? (
                                  <span
                                    className={`mt-2 pl-5 truncate ${textColor(getOverallStatus('postconditions'))}`}
                                  >
                                    {' '}
                                    {getOverallMessage(getOverallStatus('postconditions'), 'postconditions')}{' '}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td className="p-px text-left fontPoppinsRegularMd">
                                {postConditionStats && showPostConditionResultHeader ? (
                                  <span
                                    className={`mt-2 pl-5 truncate ${textColor(getOverallStatus('postconditions'))}`}
                                  >
                                    {' '}
                                    {getFormattedTextStatus(getOverallStatus('postconditions'))}{' '}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td className="p-px text-left fontPoppinsRegularMd"></td>
                            </tr>
                          ) : null}
                          {postConditionData.map((a, index) => {
                            let stepResult = individualScriptResult?.postConditionResult
                              ? individualScriptResult?.postConditionResult[a.stepId]
                              : {};
                            return (
                              <tr
                                key={a.stepId + index}
                                className={` ${
                                  showPrePostConditionTableData?.showPostConditionTableData ? 'visible ' : 'hidden '
                                } configHover h-12 content-start post-condition-row myRoww`}
                                id={`postcondition-${index}`}
                              >
                                <td className="p-px text-left fontPoppinsRegularMd"></td>
                                <td className="p-px text-left fontPoppinsRegularMd">
                                  <div className="precondition-container">
                                    <span className="w-2 min-w-full checked-box">
                                      {(isEditable || moduleName !== 'repository') && (
                                        <input
                                          type="checkbox"
                                          onClick={(event) => {
                                            handleCheckedData(event, a, index, 'postcondition');
                                          }}
                                          checked={postConditionChecked.includes(index)}
                                          name=""
                                          id={`postconditioncheckbox-${index + 1}`}
                                          className="relative mr-2 border-3 rounded border-black actionIcons steps-checkbox"
                                        />
                                      )}
                                    </span>
                                    <Tooltip
                                      title={`${a?.stepGroupName} ${
                                        a.cascaded === 'cascaded' ? ` - Cascaded from ${a.moduleName}` : ''
                                      }`}
                                      placement="top"
                                    >
                                      <span
                                        id={a?.stepId}
                                        className="text-blue-700 mr-1 cursor-pointer length-script"
                                        onClick={() => {
                                          // myCssMethod(index);
                                          updatePostCondition(a, index);
                                        }}
                                      >
                                        {index + 1}. {a?.stepGroupName}{' '}
                                        {a.cascaded === 'cascaded' ? ` - Cascaded from ${a.moduleName}` : null}
                                      </span>
                                    </Tooltip>
                                  </div>
                                </td>
                                <td className="p-px text-left fontPoppinsRegularMd">
                                  <span className={`mt-2 pl-5 step-result-truncate ${textColor(stepResult?.status)}`}>
                                    {' '}
                                    {stepResult?.message}{' '}
                                  </span>
                                </td>
                                <td className="p-px text-left fontPoppinsRegularMd">
                                  <span className={`mt-2 truncate ${textColor(stepResult?.status)}`}>
                                    <div className="pl-5 status_message">
                                      {getFormattedTextStatus(stepResult?.status)}
                                      {getFormattedTextStatus(stepResult?.status) === 'Failed' && isEditable && (
                                        <BugIcon
                                          MyAlert={props?.MyAlert}
                                          issueData={{
                                            section: 'steps',
                                            nodeObj: { data: scriptsFromApi },
                                            stepsDetails: {
                                              data: {
                                                type: 'postCondition',
                                                stepId: a.stepId,
                                              },
                                              scriptResultData: scriptResultData,
                                            },
                                            systemData: selectedSystem,
                                            executionId: scriptResultData?.executionId,
                                          }}
                                        />
                                      )}
                                    </div>
                                  </span>
                                </td>
                                <td className="p-0 pl-5 text-left fontPoppinsRegularMd ">
                                  <span className="actionIcons">
                                    {isProjectOpen && (
                                      <Tooltip title="Run Until">
                                        <svg
                                          className="mr-2 float-left mt-1 cursor-pointer"
                                          xmlns="http://www.w3.org/2000/svg"
                                          height="24px"
                                          viewBox="0 0 24 24"
                                          width="24px"
                                          fill="#1648c6"
                                          onClick={() => {
                                            runUntil(index, 'postcondition');
                                          }}
                                        >
                                          <path d="M10.8 15.9l4.67-3.5c.27-.2.27-.6 0-.8L10.8 8.1c-.33-.25-.8-.01-.8.4v7c0 .41.47.65.8.4zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                                        </svg>
                                      </Tooltip>
                                    )}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    </Droppable>
                  </table>
                </DragDropContext>
              </div>
              {isLoading ? (
                <CommonLoader />
              ) : !(postConditionData.length || preConditionData.length) && noSteps && data.length === 0 ? (
                <div className="fontPoppinsRegularMd" style={{ marginTop: '120px' }}>
                  {isEditable && (
                    <div style={{ marginLeft: '440px' }}>
                      <p className="font-bold mb-2 text-left">Quick Start</p>
                      <p className="mb-2 text-left">
                        <span className="font-bold">Step 1 :</span> Click
                        <button
                          onClick={() => {
                            setNoSteps(false);
                            setOpenCreatePreCondition(true);
                            setMode('create');
                            setSelectedStepIndex();
                          }}
                          className={`ml-3 mr-3 ${props?.type ? 'primary-btn' : 'primary-btn'}`}
                        >
                          + Step
                        </button>
                        to start adding the Steps
                      </p>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
            {/* NLP input parameter div */}
            {openStepGroupInputParameterDiv && (
              <StepsNlpInputParameter
                {...props}
                executionOrder={midExecutionOrder ? midExecutionOrder : executionOrder}
                orderOfInsertion={stepIndex}
                addStepAtIndex={addStepAtIndex}
                setAddStepAtIndex={setAddStepAtIndex}
                edit={editPreCondition}
                mode={mode}
                selectedStepIndex={selectedStepIndex}
                editData={editData}
                reload={reloadPreConditions}
                closeModal={closeModal}
                MyAlert={props.MyAlert}
                data={stepGroupInputParameterDivData}
                stepsDataObject={stepsDataObject}
                conditionNlpObject={conditionNlpObject}
                parentStartIfStep={parentStartIfStep}
                scripts={scriptsFromApi}
                previousStepData={previousStepData}
                moduleId={stepGroup}
                type={props?.type === 'Step Group' ? 'Step Group' : 'Test Development'}
                scriptRowIndex={scriptRowIndex}
                updateStepsDataOnAdd={updateStepsDataOnAdd}
                stepGroup={stepGroup}
                setOfStepsWithStepGroup={setOfStepsWithStepGroup}
                clickOutsideRef={_DOMNode}
                isSpecialNlp={isSpecialNlp}
                parentSpecialNlpId={parentSpecialNlpId}
                parentStartDataproviderStep={parentStartDataproviderStep}
                isEditStep={isEditStep}
                getScriptTemp={getScriptTemp}
                getExecutionOrder={getExecutionOrder}
                nlpSuggestionBoxRenderer={nlpSuggestionBoxRenderer}
              />
            )}

            {/* step-group input parameter for pre-condition */}
            {openPreConditionStepGroupInputParameterDiv && (
              <CreateEditScriptConditions
                edit={true}
                editData={null}
                reload={reloadPreConditions}
                closeModal={closePreConditionsModal}
                MyAlert={props.MyAlert}
                data={preConditionDataForUpdate}
                type={'PRE'}
              />
            )}
            {/* step-group input paramater for post-condition */}
            {openPostConditionStepGroupInputParameterDiv && (
              <CreateEditScriptConditions
                edit={true}
                editData={null}
                reload={reloadPreConditions}
                closeModal={closePostConditionsModal}
                MyAlert={props.MyAlert}
                data={postConditionDataForUpdate}
                type={'POST'}
              />
            )}
            {openDependsOnScriptsDiv && (
              <EditDependsOnScripts
                MyAlert={props.MyAlert}
                reload={reloadPreConditions}
                closeModal={closeDependsOnScriptModal}
                data={dependsOnScriptsDataForUpdate}
              />
            )}

            {/* modal for delete pre-condition */}
            {openDeleteModal ? (
              <DeleteModal
                closeModal={closeModal}
                handleDelete={deleteSteps}
                reload={reloadPreConditions}
                dataObj={{ type: 'Step', data: stepIndex }}
              />
            ) : null}
            {/* modal for run settings */}
            {openRunSettings.openModal ? (
              <QuickRunSettings
                disableHeadless={disableHeadlessSwitch}
                title={openRunSettings.title}
                MyAlert={props?.MyAlert}
                closeModal={closeRunSettings}
                updateDefaultMachine={getUserAllAvailableMachine}
                nodeSelected={true}
                qrsRun={runExecution}
                clearAllResults={clearAllResults}
                browserInstanceArray={browserInstanceArray}
                sauceLabInstanceArray={sauceLabInstanceArray}
                lambdaTestsInstanceArray={lambdaTestsInstanceArray}
              />
            ) : null}
            {openStepGroupModal ? (
              <StepGroupModal
                MyAlert={props?.MyAlert}
                closeModal={closeModal}
                mode="ADD"
                type={props.type === 'Step Group' ? stepGroup.type : scripts.scriptType}
                treeData={dropdownTreeData}
                stepsForStepGroup={stepsForStepGroup}
                data={stepGroup}
                uncheckSelectedCheckbox={uncheckSelectedCheckbox}
                section={props.type === 'Step Group' ? 'StepGroup' : 'Step'}
              />
            ) : null}
            {openStepModal && (
              <StepModal
                executionId={scriptResultData?.executionId}
                stepResult={stepResultData}
                closeStepModal={closeStepModal}
                screenshotDeleted={screenshotDeleted}
                videoDeleted={videoDeleted}
                scriptId={scriptId}
                moduleId={moduleId}
                hideGoToSteps={true}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
/**
 * custom generic hook to hanlde click outside event of a predefined element
 */
const useClickOutSide = (handler) => {
  const _genericDOMNode = useRef();
  useEffect(() => {
    const _handler = (event) => {
      if (_genericDOMNode.current && !_genericDOMNode.current.contains(event.target)) {
        handler();
      }
    };
    document.addEventListener('mousedown', _handler);
    return () => {
      document.removeEventListener('mousedown', _handler);
    };
  }, [handler]);
  return _genericDOMNode;
};

export default withRouter(Steps);

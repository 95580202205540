export const REPOSITORY_VALUES = {
  PROJECT_ELEMENTS: 'projectElements',
  STEP_GROUPS: 'stepGroups',
  TEST_DATA: 'testData',
  REPOSITORY: 'repository',
  PROGRAM_ELEMENTS: 'programElements',
};

export const TEST_EXECUTION_VALUE = {
  SCRIPTS: 'scripts',
  SUITES: 'suites',
  EXECUTIONS: 'executions',
  TEST_CASES: 'Test Cases',
};

export const TEMPLATE_VALUE = {
  MANUAL: 'testCase',
  DEFECTS: 'defects',
  TEMPLATES: 'templates',
  DEFECT: 'defect',
};

export const IMPORT_EXPORT = {
  REPOSITORY: {
    label: 'Repository',
    id: '1',
    checked: false,
    disabled: false,
    value: REPOSITORY_VALUES.REPOSITORY,
  },

  TEST_DATA: {
    label: 'Test Data',
    checked: false,
    id: '2',
    disabled: false,
    value: REPOSITORY_VALUES.TEST_DATA,
  },

  TEST_EXECUTION: {
    label: 'Test Execution',
    id: '3',
    checked: false,
    disabled: false,
    value: TEST_EXECUTION_VALUE.EXECUTIONS,
  },

  DEFECTS: {
    label: 'Defects',
    checked: false,
    id: '4',
    disabled: false,
    value: TEMPLATE_VALUE.DEFECTS,
  },

  TEMPLATE: {
    label: 'Templates',
    id: '5',
    checked: false,
    disabled: false,
    value: TEMPLATE_VALUE.TEMPLATES,
  },

  REPOSITORY_DATA: [
    {
      label: 'Project Elements',
      checked: false,
      id: '1',
      disabled: false,
      value: REPOSITORY_VALUES.PROJECT_ELEMENTS,
    },
    {
      label: 'Step Group',
      checked: false,
      id: '2',
      disabled: false,
      value: REPOSITORY_VALUES.STEP_GROUPS,
    },
  ],

  TEST_EXECUTION_DATA: [
    {
      label: 'Scripts',
      checked: false,
      id: '1',
      disabled: false,
      value: TEST_EXECUTION_VALUE.SCRIPTS,
    },
    {
      label: 'Suites',
      checked: false,
      id: '2',
      disabled: false,
      value: TEST_EXECUTION_VALUE.SUITES,
    },
  ],

  TEMPLATE_DATA: [
    {
      label: 'Manual TestCase Template',
      checked: false,
      id: '1',
      disabled: false,
      value: TEMPLATE_VALUE.MANUAL,
    },
    {
      label: 'Defect Template',
      checked: false,
      id: '2',
      disabled: false,
      value: TEMPLATE_VALUE.DEFECT,
    },
  ],
};

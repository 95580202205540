import React from 'react';
import './alert.scss';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';

function Alerts({ type = 'success', message, open = false, close }) {
  if (!open) return <></>;
  else
    return (
      <div className={'notification ' + type?.toLowerCase()} key={`alert${type}`}>
        <div className="icon flex flex-row">
          {getIcons(type)}
          <span className="msg-type">{getType(type)}</span>
        </div>
        <div className="message mr-5">{message || type}</div>
        <div className="dismiss">
          <CloseIcon onClick={close} className="close-icon-style-alert" />
        </div>
      </div>
    );
}

export default Alerts;

const getIcons = (type) => {
  switch (type?.toLowerCase()) {
    case 'danger':
      return <RemoveCircleIcon />;
    case 'warning':
      return <WarningIcon />;
    case 'alertbanner':
      return <WarningIcon />;
    case 'info':
      return <InfoIcon className="InfoIcon-style" />;
    default:
      return <CheckCircleIcon />;
  }
};

const getType = (type) => {
  switch (type?.toLowerCase()) {
    case 'danger':
      return 'Failed.';
    case 'warning':
      return 'Warning.';
    case 'alertbanner':
      return 'Alert.';
    case 'info':
      return 'Information.';
    default:
      return 'Success.';
  }
};

import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { GITHUB_CONSTANTS } from '../plugin_constants';
import Verification from './gitHubVerification';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import { getEncryptData } from '@common/security';

import { Tooltip } from '@material-ui/core';
import {
  validateGitHub,
  isInstanceExistGitHub,
  updateGithubInstance,
  getProjectListDropdownReq,
  getAllSuiteReq,
  isRepositoryExistGitHub,
  validateGitHubRepository,
  getOrganizationList,
} from '@api/api_services';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CommonDrawerJs from '../CommonIntegrationDrawer';
import { ReactComponent as Information } from '@assets/svg-imports/info icon.svg';
import Typography from '@mui/material/Typography';
import style from '@pages/common/Inputs/inputs.module.scss';
import cx from 'classnames';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const CreateEditInstance = (props) => {
  const [openVerification, setOpenVerification] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [organizationNameFilterArray, setOrganizationNameFilterArray] = useState([]);
  const [projectFilterArray, setProjectFilterArray] = useState([]);
  const [scriptFilterArray, setScriptFilterArray] = useState([]);
  const [projectId, setProjectId] = useState('');
  const [projectType, setProjectType] = useState(props.mode === 'EDIT' ? props.data.projectType : '');
  const [suiteId, setSuiteId] = useState('');
  const [scriptName, setScriptName] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [fireflinkProjectName, setFireFlinkProjectName] = useState('');
  const [suiteName, setSuiteName] = useState('');
  const [showError, setShowError] = useState(false);
  const [instanceError, setInstanceError] = useState(false);
  const [repositoryError, setRepositoryError] = useState(false);
  const [invalidScope, setInvalidScope] = useState(false);
  const [repositoryFound, setRepositoryFound] = useState(false);
  const [matchingData, setMatchingData] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const initialValues = {
    username: '',
    userPasswordField: '',
    organization: '',
    suiteName: '',
    instanceName: '',
    repositoryName: '',
    fireFlinkProject: '',
  };

  if (props.mode === 'EDIT') {
    initialValues.username = props?.data?.gitHubUserName;
    initialValues.suiteName = props?.data?.suiteName;
    initialValues.instanceName = props?.data?.instanceName;
    initialValues.repositoryName = props?.data?.gitHubRepositoryName;
    initialValues.fireFlinkProject = props?.data?.fireFlinkProjectName;
    initialValues.organization = props?.data?.organization;
  }
  const validationSchema = yup.object({
    username: yup.string().required('Username  is required'),
    userPasswordField: yup.string().required('Access Token is required'),
    instanceName: yup
      .string()
      .matches(/^[A-Za-z0-9 _]*$/, 'Name can not have special characters')
      .min(3, 'Name should contain at least 3 characters')
      .max(25, 'Name can contain at most 25 characters')
      .matches(/^[^\s].*[^\s]$/, 'Space is not allowed in the beginning and at the end')
      .required('Instance Name is required'),
    repositoryName: yup
      .string()
      .min(3, 'Name should contain atleast 3 characters')
      .max(25, 'Name can contain atmost 25 characters')
      .required('Repository Name  is required')
      .matches(/^\S*$/, 'Space is not allowed'),
    fireFlinkProject: yup.string().required('FireFlink Project is required'),
    suiteName: yup.string().required('Suite  is required'),
  });

  const handleOpenVerification = () => {
    const requiredFields = ['instanceName', 'repositoryName', 'fireFlinkProject', 'suiteName'];

    const allFieldsValid = requiredFields.every((field) => {
      const value = formikDetails.values[field].trim();
      if (value === '') {
        formikDetails.setFieldError(field, `${field} is required`);
        return false;
      }
      return true;
    });

    if (allFieldsValid && !instanceError && !repositoryError) {
      setOpenVerification(true);
    }
  };

  const handleCloseVerification = () => {
    setOpenVerification(false);
  };
  const formikDetails = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleOpenVerification,
  });

  const validInstance =
    formikDetails?.values?.instanceName?.length >= 3 && formikDetails?.values?.instanceName.length <= 25 && true;
  const iSsuiteExist = formikDetails?.values?.suiteName?.length > 0 && true;
  const getSelectedProjectName = (projectName) => {
    let project = projectFilterArray.filter((e) => e.name === projectName);
    setProjectId(project[0].id);
    setProjectType(project[0].type);

    project
      ? localStorage.setItem('selected-Git-project', JSON.stringify(project[0]))
      : localStorage.setItem('selected-Git-project', JSON.stringify({ id: '', name: 'All Projects' }));
    getAllSuiteReq().then((results) => {
      let _suiteType = results?.data?.responseObject.filter((suiteType) => suiteType?.suiteType === 'Automation');
      setSuiteId(_suiteType[0]?.id);
      setScriptFilterArray(_suiteType ? _suiteType : []);
    });
  };

  const handleSheetChange = (value) => {
    if (value) {
      getSelectedProjectName(value);
    }
    formikDetails.setFieldValue('projectName', value, true);
    formikDetails.setFieldValue('suiteName', '', true);
    setFireFlinkProjectName(value);
  };
  const handleSuiteChange = (value) => {
    if (value) {
      setScriptName(scriptFilterArray.filter((e) => e.name === value));
      scriptFilterArray.forEach((suite) => {
        if (suite?.name === value) {
          setSuiteId(suite?.id);
        }
      });
    }
    formikDetails.setFieldValue('suiteName', value, true);
    setSuiteName(value);
  };

  const isValidGitHub = () => {
    const payLoad = {
      gitHubUserName: formikDetails.values.username,
      gitHubAppPassword: formikDetails.values.userPasswordField,
    };

    if (payLoad.gitHubUserName.trim() === '' || payLoad.gitHubAppPassword.trim() === '') {
      formikDetails.setFieldError('username', payLoad.gitHubUserName.trim() === '' ? 'Username is required' : '');
      formikDetails.setFieldError(
        'userPasswordField',
        payLoad.gitHubAppPassword.trim() === '' ? 'Password is required' : ''
      );
    } else {
      payLoad.gitHubAppPassword = getEncryptData(payLoad.gitHubAppPassword);
      validateGitHub(payLoad)
        .then((response) => {
          if (response?.data?.responseCode === 200 && response?.data.message === 'SUCCESS') {
            fetchOrganizationData(payLoad);
            setShowDetails(true);
          } else if (response?.data?.responseCode === 400 && response?.data?.status === 'Invalid GitHub Credentials') {
            setShowDetails(false);
            setShowError(true);
            setInvalidScope(false);
            formikDetails.setFieldError('username', GITHUB_CONSTANTS.INVALID_USERNAME_ACCESS_TOKEN);
            formikDetails.setFieldError('userPasswordField', GITHUB_CONSTANTS.INVALID_USERNAME_ACCESS_TOKEN);
          } else if (response?.data?.responseCode === 400 && response?.data?.status === 'Invalid Scopes') {
            setInvalidScope(true);
            setShowError(false);
            formikDetails.setFieldError('username', GITHUB_CONSTANTS.INVALID_ACCESS);
            formikDetails.setFieldError('userPasswordField', GITHUB_CONSTANTS.INVALID_ACCESS);
          }
        })
        .catch((error) => {
          console.error('API error:', error);
        });
    }
  };
  const fetchOrganizationData = async (payLoad) => {
    try {
      const response = await getOrganizationList(payLoad);
      const organizationList = response?.data?.responseObject?.organization;
      if (response?.data?.responseCode === 200 || response?.data?.message === 'SUCCESS') {
        setOrganizationNameFilterArray(organizationList);
      } else {
        console.error('API error:', 'Unable to fetch organization');
      }
    } catch (error) {
      console.error('API error:', error);
    }
  };

  useEffect(() => {
    const fetchRepositoryData = async () => {
      const repositoryName = formikDetails.values.repositoryName;
      let repositoryData = {
        gitHubUserName: formikDetails.values.username,
        gitHubAppPassword: getEncryptData(formikDetails.values.userPasswordField),
        gitHubRepositoryName: formikDetails.values.repositoryName,
        organization: formikDetails.values.organization ? formikDetails.values.organization : 'N/A',
      };
      if (showDetails && repositoryName.length >= 3 && repositoryName.length <= 25) {
        try {
          const response = await isRepositoryExistGitHub(repositoryData);
          if (response?.data?.responseCode === 200) {
            setRepositoryError(false);
          } else if (response?.data?.responseCode === 400) {
            setRepositoryError(true);
          }
        } catch (error) {
          console.error('API error:', error);
        }
      }
    };

    fetchRepositoryData();
  }, [
    showDetails,
    formikDetails.values.repositoryName,
    formikDetails.values.username,
    formikDetails.values.userPasswordField,
    formikDetails.values.organization,
  ]);
  useEffect(() => {
    const fetchInstanceData = async () => {
      const instanceName = formikDetails?.values?.instanceName;
      const id = props?.data?.id;
      if (showDetails && instanceName?.trim().length >= 3 && instanceName.trim().length <= 25) {
        try {
          const response = await isInstanceExistGitHub(props.mode === 'EDIT' ? { instanceName, id } : { instanceName });
          if (response?.data?.responseCode === 200) {
            setInstanceError(false);
          } else if (response?.data?.responseCode === 400 && response?.data?.responseObject === null) {
            setInstanceError(true);
          }
        } catch (error) {
          console.error('API error:', error);
        }
      }
    };

    fetchInstanceData();
  }, [formikDetails?.values?.instanceName, instanceError, props?.data?.id, props.mode, showDetails]);

  useEffect(() => {
    if (showDetails) {
      getProjectListDropdownReq()
        .then((results) => {
          const responseProjects = results.data.responseObject;

          setProjectFilterArray(responseProjects);
        })
        .catch((error) => {
          console.error('failed due to api response', error);
        });
    }
    if (props.mode === 'EDIT' && showDetails) {
      localStorage.setItem(
        'selected-Git-project',
        JSON.stringify({ id: props?.data?.projectId, name: props?.data?.projectName })
      );
      getAllSuiteReq()
        .then((results) => {
          let _suiteType = results?.data?.responseObject.filter((suiteType) => suiteType.suiteType === 'Automation');
          setScriptFilterArray(_suiteType ? _suiteType : []);
        })
        .catch((error) => {
          console.error('API error:', error);
        });
      const repositoryData = props.data;
      repositoryData.gitHubAppPassword = getEncryptData(props.data?.gitHubAppPassword);
      repositoryData.fireFlinkPassword = getEncryptData(props.data?.fireFlinkPassword);

      validateGitHubRepository(repositoryData)
        .then((response) => {
          if (response?.data?.responseCode === 200) {
            setRepositoryFound(false);
          } else if (response?.data?.responseCode === 404) {
            setRepositoryFound(true);
          }
        })
        .catch((error) => {
          console.error('API error:', error);
        });
    }
    return () => {
      localStorage.removeItem('selected-Git-project');
    };
  }, [props?.mode, showDetails]);

  const isRepositoryNameEmpty = props.mode === 'EDIT' && formikDetails.values.repositoryName === '';

  const currentInstanceData = [
    {
      instanceName: formikDetails?.values?.instanceName,
      suiteName: formikDetails?.values?.suiteName,
      projectName: formikDetails?.values?.fireFlinkProject,
      organization: formikDetails?.data?.organization,
    },
  ];

  const exsitingInstanceData = [
    {
      instanceName: props?.data?.instanceName,
      suiteName: props?.data?.suiteName,
      projectName: props?.data?.fireFlinkProjectName,
      organization: props?.data?.organization,
    },
  ];

  useEffect(() => {
    if (props.mode === 'EDIT' && showDetails) {
      if (JSON.stringify(currentInstanceData) === JSON.stringify(exsitingInstanceData)) {
        setMatchingData(true);
      } else {
        setMatchingData(false);
      }
    }
  }, [currentInstanceData, exsitingInstanceData]);
  const updateInstance = () => {
    const updatedData = {
      gitHubUserName: props?.data?.gitHubUserName,
      gitHubAppPassword: getEncryptData(formikDetails.values.userPasswordField),
      instanceName: formikDetails.values.instanceName,
      projectType: projectType,
      suiteId: scriptName[0]?.id ? scriptName[0]?.id : props.data.suiteId,
      suiteName: formikDetails.values.suiteName,
      fireFlinkProjectName: formikDetails.values.fireFlinkProject,
      organization: formikDetails.values.organization,
    };
    if (!instanceError && !isRepositoryNameEmpty) {
      updateGithubInstance(updatedData, props?.data?.id)
        .then((response) => {
          if (response?.data?.status === 'SUCCESS') {
            props.onClose();
            props.reloadTree();
            props.MyAlert.success(`${updatedData.instanceName} ${response?.data?.message}`);
          } else if (response.data.responseCode === 400 && response.data.status.includes('Server Busy')) {
            props.onClose();
            props.MyAlert.warning(response?.data?.message);
          }
        })
        .catch((error) => {
          console.error('API error:', error);
        });
    }
  };
  return (
    <CommonDrawerJs
      isDrawerOpen={true}
      headerContent={
        props.mode === 'EDIT'
          ? `${GITHUB_CONSTANTS.EDIT_INSTANCE} - ${props?.data?.instanceName}`
          : GITHUB_CONSTANTS.CREATE_INSTANCE
      }
      drawerWidth="450px"
      rightButtonText={
        props.mode === 'EDIT'
          ? showDetails
            ? GITHUB_CONSTANTS.UPDATE
            : GITHUB_CONSTANTS.VERIFY
          : showDetails
          ? GITHUB_CONSTANTS.CREATE
          : GITHUB_CONSTANTS.VERIFY
      }
      leftButtonText="Cancel"
      isLeftButtonVisible={true}
      onDrawerOpen={props.onClose}
      onRightButtonClick={
        props.mode === 'EDIT' && showDetails
          ? updateInstance
          : showDetails
          ? formikDetails?.handleSubmit
          : isValidGitHub
      }
      onLeftButtonClick={props.onClose}
      disableRightButton={
        formikDetails.values.username.length === 0 ||
        formikDetails.values.userPasswordField.length === 0 ||
        (showDetails &&
          props.mode === 'EDIT' &&
          (matchingData || repositoryFound === true || !validInstance || !iSsuiteExist))
      }
    >
      <div className="w-full  flex flex-col justify-center items-center overflow-hidden h-auto">
        <div className="w-full flex flex-col mt-2 ml-8">
          <div className="fontPoppinsMediumSm integration-label mt-1 -mb-1">
            {
              <>
                {' '}
                <span className="text-red-600">*</span> {GITHUB_CONSTANTS.GITHUB_USERNAME}
              </>
            }{' '}
          </div>
          <div className="mt-2">
            <TextField
              className={`instance-input-field text-field-Style ${
                (showDetails || props.mode === 'EDIT') && 'disabled-field'
              }`}
              variant="outlined"
              name="username"
              autoComplete="off"
              placeholder={GITHUB_CONSTANTS.ENTER_GITHUB_USER_NAME}
              value={formikDetails.values.username}
              onChange={formikDetails.handleChange}
              onBlur={formikDetails.handleBlur}
              error={formikDetails.errors.username && formikDetails.touched.username}
              disabled={showDetails || props.mode === 'EDIT'}
            />
            {formikDetails.values.username.length === 0 && formikDetails.touched.username ? (
              <div className="error-message fontPoppinsRegularXs ml-2">{formikDetails.errors.username}</div>
            ) : null}
          </div>
        </div>
        <div className="w-full flex flex-col mt-2 ml-8">
          <div className="fontPoppinsMediumSm flex flex-row integration-label mt-1 -mb-1">
            <div>
              {
                <>
                  {' '}
                  <span className="text-red-600">*</span> {GITHUB_CONSTANTS.GITHUB_ACCESS_TOKEN}
                </>
              }
            </div>
            <div className="ml-1 mt-1">
              {!showDetails && (
                <Tooltip
                  title={
                    <div>
                      <Typography className="table-line info-text">{GITHUB_CONSTANTS.REQUIRED_ACCESS}</Typography>
                      <Typography className="info-text">{GITHUB_CONSTANTS.REPOSITORY_FULL_ACCESS}</Typography>
                      <Typography className="info-text">{GITHUB_CONSTANTS.WORKFLOW_FULL_ACCESS}</Typography>
                      <Typography className="info-text">{GITHUB_CONSTANTS.ORGANIZATION_FULL_ACCESS}</Typography>
                    </div>
                  }
                  placement={'right'}
                >
                  <span>
                    <Information className="info-icon-span" />
                  </span>
                </Tooltip>
              )}
            </div>
          </div>
          <div className="mt-2">
            <TextField
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                      {!showDetails &&
                        (showPassword ? (
                          <VisibilityOutlinedIcon className="mt-0 plugin-visibilityIcon" />
                        ) : (
                          <VisibilityOffOutlinedIcon className="mt-0 ml-2 plugin-visibilityIcon" />
                        ))}
                    </span>
                  </InputAdornment>
                ),
              }}
              autoComplete="new-password"
              className={`instance-input-field text-field-Style ${showDetails && 'disabled-field'}`}
              placeholder={GITHUB_CONSTANTS.ENTER_GITHUB_ACCESS_TOKEN}
              name="userPasswordField"
              value={formikDetails.values.password}
              onChange={formikDetails.handleChange}
              onBlur={formikDetails.handleBlur}
              error={formikDetails.errors.userPasswordField && formikDetails.touched.userPasswordField}
              disabled={showDetails}
            />
            {formikDetails.values.userPasswordField.length === 0 && formikDetails.touched.userPasswordField ? (
              <div className="error-message fontPoppinsRegularXs mr-72 ml-3">
                {formikDetails.errors.userPasswordField}
              </div>
            ) : null}
            {formikDetails.values.userPasswordField.length > 0 && formikDetails.errors.userPasswordField && (
              <>
                {showError &&
                  (props.mode !== 'EDIT' ? (
                    <div className="error-message fontPoppinsRegularXs9px mr-60">
                      {GITHUB_CONSTANTS.INVALID_USERNAME_ACCESS_TOKEN}
                    </div>
                  ) : (
                    <div className="error-message fontPoppinsRegularXs9px mr-80 ml-4">
                      {GITHUB_CONSTANTS.INVALID_ACCESS_TOKEN}
                    </div>
                  ))}

                {invalidScope && (
                  <div className="error-message fontPoppinsRegularXs invalid-message">
                    {GITHUB_CONSTANTS.INVALID_ACCESS}
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {showDetails && (
          <>
            <div className="w-full flex flex-col mt-2 ml-8">
              <div className="integration-label fontPoppinsMediumSm mt-1 -mb-1">
                {
                  <>
                    {' '}
                    <span className="text-red-600">*</span> {GITHUB_CONSTANTS.INSTANCE_NAME}
                  </>
                }
              </div>
              <div className="mt-2">
                <TextField
                  className="instance-input-field ml-5"
                  variant="outlined"
                  name="instanceName"
                  style={{ width: '420px' }}
                  autoComplete="off"
                  placeholder={GITHUB_CONSTANTS.ENTER_INSTANCE_NAME}
                  onChange={formikDetails.handleChange}
                  error={(formikDetails.touched.instanceName && formikDetails.errors.instanceName) || instanceError}
                  onBlur={formikDetails.handleBlur}
                  value={formikDetails.values.instanceName}
                />
                {formikDetails.errors.instanceName && formikDetails.touched.instanceName ? (
                  <div className="error-message fontPoppinsRegularXs9px mr-56 ml-1">
                    {formikDetails.errors.instanceName}
                  </div>
                ) : null}
                {instanceError ? (
                  <div className="error-message fontPoppinsRegularXs9px mr-56 ml-1">
                    {GITHUB_CONSTANTS.INSTANCE_ERROR}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="w-full flex flex-col mt-2 ml-8">
              <div className="integration-label fontPoppinsMediumSm mt-1 -mb-1">
                {<>{GITHUB_CONSTANTS.ORGANIZATION_NAME}</>}
              </div>
              <div className="mt-2">
                <FormControl>
                  <Select
                    className={`instance-input-field select-field-Style ${
                      showDetails && props.mode === 'EDIT' && 'disabled-field'
                    }`}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    name="organization"
                    onChange={(event) => {
                      formikDetails.setFieldValue('organization', event.target.value);
                    }}
                    value={formikDetails.values.organization}
                    disabled={props?.mode === 'EDIT'}
                    renderValue={(selected) => {
                      if (!selected)
                        return (
                          <span className="fontPoppinsRegularSm" style={{ opacity: '0.5' }}>
                            {GITHUB_CONSTANTS.SELECT_ORGANIZATIONS}
                          </span>
                        );
                      else return <span className="fontPoppinsRegularSm">{selected}</span>;
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: '180px',
                          minHeight: '50px',
                          marginLeft: '3px',
                        },
                      },
                      classes: {
                        root: cx(style['select-box-menu-list-wrapper']),
                      },
                    }}
                  >
                    {organizationNameFilterArray?.map((organization) => {
                      return (
                        <MenuItem value={organization} key={organization}>
                          <span>{organization}</span>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="w-full flex flex-col mt-2 ml-8">
              <div className="integration-label fontPoppinsMediumSm mt-1 -mb-1">
                {
                  <>
                    {' '}
                    <span className="text-red-600">*</span> {GITHUB_CONSTANTS.REPOSITORY_NAME}
                  </>
                }
              </div>
              <div className="mt-2">
                <TextField
                  label=""
                  variant="outlined"
                  name="repositoryName"
                  className={`instance-input-field ml-5 text-field-Style ${props.mode === 'EDIT' && 'disabled-field'}`}
                  autoComplete="off"
                  placeholder={GITHUB_CONSTANTS.ENTER_REPOSITORY_NAME}
                  onChange={formikDetails.handleChange}
                  error={
                    (formikDetails.errors.repositoryName && formikDetails.touched.repositoryName) || repositoryError
                  }
                  onBlur={formikDetails.handleBlur}
                  value={formikDetails.values.repositoryName}
                  disabled={props.mode === 'EDIT'}
                />
                {formikDetails.errors.repositoryName &&
                formikDetails.touched.repositoryName &&
                props.mode !== 'EDIT' ? (
                  <div className="error-message fontPoppinsRegularXs9px mr-56 ml-1">
                    {formikDetails.errors.repositoryName}
                  </div>
                ) : null}
                {repositoryError && props.mode !== 'EDIT' ? (
                  <div className="error-message fontPoppinsRegularXs9px mr-56 ml-1">
                    {GITHUB_CONSTANTS.REPOSITORY_ERROR}
                  </div>
                ) : null}
                {repositoryFound ? (
                  <div className="error-message fontPoppinsRegularXs9px field-wrap ml-1">
                    {GITHUB_CONSTANTS.REPOSITORY_NOT_FOUND}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="w-full flex flex-col mt-2 ml-8">
              <div className="integration-label fontPoppinsMediumSm mt-1 -mb-1">
                {
                  <>
                    {' '}
                    <span className="text-red-600">*</span> {GITHUB_CONSTANTS.FIRE_FLINK_PROJECT}
                  </>
                }
              </div>
              <div className="mt-2">
                <FormControl>
                  <Select
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    className="select-field-Style"
                    name="fireFlinkProject"
                    onChange={(event) => {
                      handleSheetChange(event.target.value);
                      formikDetails.setFieldValue('fireFlinkProject', event.target.value);
                    }}
                    value={formikDetails.values.fireFlinkProject}
                    error={formikDetails.touched.fireFlinkProject && formikDetails.errors.fireFlinkProject}
                    renderValue={(selected) => {
                      if (!selected)
                        return (
                          <span className="fontPoppinsRegularSm" style={{ opacity: '0.5' }}>
                            {GITHUB_CONSTANTS.SELECT_FIREFLINK_PROJECT}
                          </span>
                        );
                      else return <span className="fontPoppinsRegularSm">{selected}</span>;
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: '180px',
                          minHeight: '50px',
                          marginLeft: '3px',
                        },
                      },
                      classes: {
                        root: cx(style['select-box-menu-list-wrapper']),
                      },
                    }}
                  >
                    {(projectFilterArray.every((option) => option.status === 'Closed') ||
                      !projectFilterArray.length) && <MenuItem>{GITHUB_CONSTANTS.NO_OPTIONS}</MenuItem>}
                    {projectFilterArray?.map((option, optionIndex) => {
                      if (option.status !== 'Closed') {
                        return (
                          <MenuItem value={option.name} key={option.name}>
                            <span>{option.name}</span>
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
                {formikDetails.errors.fireFlinkProject && formikDetails.touched.fireFlinkProject ? (
                  <div className="error-message fontPoppinsRegularXs9px ml-1">
                    {formikDetails.errors.fireFlinkProject}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="w-full flex flex-col mt-2 ml-8">
              <div className="integration-label fontPoppinsMediumSm mt-1 -mb-1">
                {
                  <>
                    {' '}
                    <span className="text-red-600">*</span> {GITHUB_CONSTANTS.SUITE_NAME}
                  </>
                }
              </div>
              <div className="mt-2">
                <FormControl>
                  <Select
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    className="select-field-Style"
                    name="suiteName"
                    onChange={(event) => {
                      handleSuiteChange(event.target.value);
                      formikDetails.setFieldValue('suiteName', event.target.value);
                    }}
                    renderValue={(selected) => {
                      if (!selected)
                        return (
                          <span className="fontPoppinsRegularSm" style={{ opacity: '0.5' }}>
                            {GITHUB_CONSTANTS.SELECT_SUITE_NAME}
                          </span>
                        );
                      else return <span className="fontPoppinsRegularSm">{selected}</span>;
                    }}
                    value={formikDetails.values.suiteName}
                    error={formikDetails.touched.suiteName && formikDetails.errors.suiteName}
                    IconComponent={KeyboardArrowDownIcon}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: '180px',
                          minHeight: '50px',
                          marginLeft: '3px',
                        },
                      },
                      classes: {
                        root: cx(style['select-box-menu-list-wrapper']),
                      },
                    }}
                  >
                    {scriptFilterArray
                      ?.filter((option) => option?.suiteType === 'Automation')
                      .map((option) => {
                        return (
                          <MenuItem value={option?.name} key={option.name}>
                            <span title={option?.name?.length > 25 ? option.name : ''}>
                              {option?.name?.length > 25 ? `${option?.name?.substring(0, 25)}...` : option?.name}
                            </span>
                          </MenuItem>
                        );
                      })}
                    {scriptFilterArray?.filter((option) => option?.suiteType === 'Automation').length === 0 ? (
                      <MenuItem>{GITHUB_CONSTANTS.NO_OPTIONS}</MenuItem>
                    ) : null}
                  </Select>
                </FormControl>
                {formikDetails.errors.suiteName && formikDetails.touched.suiteName ? (
                  <div className="error-message fontPoppinsRegularXs9px ml-1">{formikDetails.errors.suiteName}</div>
                ) : null}
              </div>
            </div>
          </>
        )}
      </div>
      {openVerification && (
        <Verification
          isOpen={openVerification}
          onClose={handleCloseVerification}
          verificationDetails={formikDetails.values}
          projectId={projectId}
          suiteId={suiteId}
          projectType={projectType}
          close={props.onClose}
          reloadTree={props.reloadTree}
          suiteName={suiteName}
          fireflinkProjectName={fireflinkProjectName}
          repositoryName={formikDetails.values.repositoryName}
          MyAlert={props.MyAlert}
        />
      )}
    </CommonDrawerJs>
  );
};

export default CreateEditInstance;

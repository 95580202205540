import { LICENSE_FEATURES, AUTOMATION_SERVICES } from '@src/common/ui-constants';

const hasDefects = (licenseFeatures) => licenseFeatures?.includes(LICENSE_FEATURES.DEFECTS) || false;

const hasManualTestCase = (licenseFeatures) => licenseFeatures?.includes(LICENSE_FEATURES.MANUAL_TESTCASES) || false;

const hasWebService = (licenseFeatures) =>
  licenseFeatures?.includes(LICENSE_FEATURES.WEB_SERVICE || LICENSE_FEATURES.ALL) || false;

const hasWeb = (licenseFeatures) => licenseFeatures?.includes(LICENSE_FEATURES.WEB || LICENSE_FEATURES.ALL) || false;

const hasMobile = (licenseFeatures) =>
  licenseFeatures?.includes(LICENSE_FEATURES.MOBILE || LICENSE_FEATURES.ALL) || false;

const hasSalesForce = (licenseFeatures) =>
  licenseFeatures?.includes(LICENSE_FEATURES.SALESFORCE || LICENSE_FEATURES.ALL) || false;

const hasMsDynamics = (licenseFeatures) =>
  licenseFeatures?.includes(LICENSE_FEATURES.MS_DYNAMICS || LICENSE_FEATURES.ALL) || false;

const hasAutomation = (licenseFeatures) =>
  AUTOMATION_SERVICES?.some((service) => licenseFeatures?.includes(service)) ||
  licenseFeatures?.includes(LICENSE_FEATURES.ALL) ||
  false;

const hasCBasic = (licenseFeatures) => licenseFeatures?.includes(LICENSE_FEATURES.ALL) || false;

const getAutomationPurchasedProjects = (privilegeData) => {
  let allowedAutomationProjects;
  allowedAutomationProjects = privilegeData?.features?.projectsMenu?.restrictions?.allowedProjects?.filter((type) => {
    if (type?.featureType === 'automation') {
      return true;
    }
    return false;
  })?.[0]?.projects;

  return allowedAutomationProjects;
};

global.privilege = {
  hasDefects,
  hasManualTestCase,
  hasWebService,
  hasWeb,
  hasMobile,
  hasSalesForce,
  hasMsDynamics,
  hasAutomation,
  hasCBasic,
  getAutomationPurchasedProjects,
};

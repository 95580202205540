import React, { useState, useEffect, useContext } from 'react';
import ReactTable from '@src/pages/common/Table/ReactTable';
import GitlabStyles from './gitlab-styles.module.scss';
import cx from 'classnames';
import { DeleteOutlined, EditOutlined, Visibility } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { CommonLoader } from '@pages/common/common-loader';
import DeleteModal from '@pages/common/delete_modal';
import CreateEditGitlabInstance from './modals/create-edit-gitlab-instance';
import GitlabInstanceDetails from './modals/gitlab-instance-details';
import { deleteGitlabInstanceReq, getGitlabInstanceReq } from '@api/api_services';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import ContextMenu from '@pages/common/context_menu';
import SearchBar from '@pages/analytics/common/search-bar';
import { HighlightText, isEmptyValue, isValueIncludes } from '@util/common_utils';
import NoResultsFound from '@pages/common/Table/NoResultFound';

const GitlabTable = (props) => {
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(true);
  const [instancesData, setInstancesData] = useState([]);
  const [selectedInstance, setSelectedInstance] = useState();

  const [openCreateInstanceModal, setOpenCreateInstanceModal] = useState(false);
  const [openInstanceDetailsModal, setOpenInstanceDetailsModal] = useState(false);
  const [openDeleteInstanceModal, setOpenDeleteInstanceModal] = useState(false);

  const [pageIndexValue, setPageIndex] = React.useState(0);
  let [isHighlight, setIsHighlight] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');

  const contextMenuData = [
    {
      option: 'Details',
    },
  ];

  function contextMenuFunction(event, ele) {
    if (event.target.textContent === 'Details') {
      setSelectedInstance({ ...ele });
      setOpenInstanceDetailsModal(true);
    }
  }
  const reloadTable = () => {
    setReload(true);
  };

  const closeModal = (value) => {
    if (selectedInstance) {
      setSelectedInstance();
    }

    if (openCreateInstanceModal) {
      setOpenCreateInstanceModal(value);
    } else if (openInstanceDetailsModal) {
      setOpenInstanceDetailsModal(value);
    } else if (openDeleteInstanceModal) {
      setOpenDeleteInstanceModal(value);
    }
  };

  const deleteGitlabInstance = async () => {
    try {
      const response = await deleteGitlabInstanceReq(selectedInstance._id);
      if (response?.data?.responseObject) {
        props.MyAlert.success(
          selectedInstance.name?.length >= 22
            ? `${selectedInstance.name.substring(0, 22)} ... instance is deleted successfully`
            : `${selectedInstance.name} instance is deleted successfully`
        );
        reloadTable();
      } else {
        props.MyAlert.warning(response?.data?.message);
      }
      closeModal();
    } catch (err) {
      console.error('DELETE_GITLAB_INSTANCE : ', err);
    }
  };

  const menuData = (instance) => {
    return (
      <div className="actionIcons">
        <button
          className={`float-left  text-base mr-3 text-blue-700 cursor-pointer`}
          onClick={() => {
            setSelectedInstance({ ...instance });
            setOpenCreateInstanceModal(true);
          }}
        >
          <Tooltip title="Edit">
            <EditOutlined />
          </Tooltip>
        </button>
        <button
          className={`float-left  text-base mr-3 text-blue-700 cursor-pointer`}
          onClick={() => {
            setSelectedInstance({ ...instance });
            setOpenDeleteInstanceModal(true);
          }}
        >
          <Tooltip title="Delete">
            <DeleteOutlined />
          </Tooltip>
        </button>
        <div className="float-left project-action text-blue-700 text-base cursor-pointer">
          <ContextMenu
            contextMenuData={instance ? contextMenuData : [...contextMenuData]}
            func={(e) => {
              contextMenuFunction(e, instance);
            }}
            configSection={true}
          />
        </div>
      </div>
    );
  };

  const getALlInstances = async () => {
    try {
      setIsLoading(true);
      resetProgress();
      startOrResumeProgress({
        stopAt: 90,
      });
      let tempInstanceData = [];
      let res = await getGitlabInstanceReq();
      stopProgress();
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 10,
      });
      if (res?.data?.responseObject?.length) {
        res.data.responseObject.map((instance) =>
          tempInstanceData.push({
            ...instance,
            actions: menuData(instance),
          })
        );
        if (res.data.responseObject.length === 7 * pageIndexValue && pageIndexValue !== 0) {
          setPageIndex(pageIndexValue - 1);
        }
        setInstancesData(tempInstanceData);
      } else {
        setInstancesData([]);
      }
      setIsLoading(false);
    } catch (err) {
      resetProgress();
      console.error('GET_GITLAB_INSTANCES : ', err);
    }
  };

  // TODO :: handle Search filter :: START
  const [userSearched, setuserSearched] = useState('');
  const [data, setData] = useState([]);
  let [openSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    setData(instancesData);

    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [instancesData]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(instancesData);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setData(instancesData);
    setSearchTextHighlight('');
    setIsHighlight(!isHighlight);
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const newData = instancesData.filter(({ name, url, createdByUname }) => {
          return isValueIncludes(e, name) || isValueIncludes(e, url) || isValueIncludes(e, createdByUname);
        });
        setData(newData);
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  // TODO :: handle Search filter :: END

  const columns = React.useMemo(
    () => [
      {
        Header: 'INSTANCE NAME',
        accessor: 'name',
        Cell: ({ value }) => (
          <div className="table-non-link-color-common float-left name-trimmer" title={value}>
            <HighlightText text={value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'URL',
        accessor: 'url',
        Cell: ({ value }) => (
          <div
            className="table-non-link-color-common float-left overflow-hidden overflow-ellipsis whitespace-nowrap w-20"
            title={value}
          >
            <HighlightText text={value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'PERSONAL TOKEN',
        accessor: 'personalToken',
        Cell: ({ value }) => (
          <div className={cx('table-non-link-color-common float-left  ', GitlabStyles['hide-characters'])}>{value}</div>
        ),
      },
      {
        Header: 'CREATED BY',
        accessor: 'createdByUname',
        Cell: ({ value }) => (
          <Tooltip title={value} placement="top">
            <div
              className="table-non-link-color-common float-left overflow-hidden overflow-ellipsis whitespace-nowrap w-24"
              title={value}
            >
              <HighlightText text={value} highlight={searchTextHighlight} />
            </div>
          </Tooltip>
        ),
      },
      {
        Header: 'CREATED ON',
        accessor: 'createdOn',
        Cell: ({ value }) => <div className="table-non-link-color-common float-left  ">{value}</div>,
      },
      {
        Header: 'ACTIONS',
        accessor: 'actions',
      },
    ],
    [isHighlight]
  );

  useEffect(() => {
    if (reload) {
      getALlInstances();
    }
    setReload(false);
  }, [reload]);

  return (
    <>
      <div className="page-list-body">
        <div className="page-table-body">
          <div className="content_area_style_Int">
            <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
              <label className="project_label text-sm flex-auto">Git Lab Configuration</label>
              <div className="pr-4">
                <div className="flex flex-row items-center">
                  <SearchBar
                    open={openSearch}
                    searchValue={userSearched}
                    handleSearch={handleSearch}
                    onSearchClose={handleSearchClose}
                    handleSearchOpen={handleSearchOpen}
                    enterKeyHandler={handleSearchSubmit}
                    disable={isEmptyValue(instancesData)}
                  />

                  <div className="ml-auto">
                    <button
                      onClick={() => {
                        setOpenCreateInstanceModal(true);
                      }}
                      className="ml-3 primary-btn"
                    >
                      + Instance
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-height overflow-auto" id="journal-scroll">
              <ReactTable data={data} columns={columns} tableHeightClass={'main-table-height'} />
              {isLoading ? (
                <CommonLoader />
              ) : isEmptyValue(instancesData) ? (
                <div className="fontPoppinsRegularMd no-steps">
                  <div className="">
                    <p className="font-bold mb-4">Quick Start</p>
                    <p className="mb-4">
                      <span className="font-bold">Step 1 :</span> Click
                      <button
                        onClick={() => {
                          setOpenCreateInstanceModal(true);
                        }}
                        className="ml-3 mr-3 primary-btn"
                      >
                        + Instance
                      </button>
                      to start adding the Instances
                    </p>
                  </div>
                </div>
              ) : (
                <>{openSearch && isEmptyValue(data) && <NoResultsFound className="mt-14" />}</>
              )}
            </div>

            {/* modal to create and edit instance */}
            {openCreateInstanceModal && (
              <CreateEditGitlabInstance
                closeModal={closeModal}
                data={selectedInstance}
                MyAlert={props.MyAlert}
                reload={reloadTable}
              />
            )}
            {/* modal for delete variable */}
            {openDeleteInstanceModal && (
              <DeleteModal
                message={selectedInstance?.name + ' Instance'}
                closeModal={closeModal}
                method={deleteGitlabInstance}
              />
            )}
            {/* modal for variable details */}
            {openInstanceDetailsModal && <GitlabInstanceDetails closeModal={closeModal} data={selectedInstance} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default GitlabTable;

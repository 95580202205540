import { Button } from '@material-ui/core';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { fontPoppins } from '@src/css_config/fontConstants';
const ButtonComponent = ({
  label = 'Button',
  variant = 'contained',
  onBtnClick = () => {},
  size = 'small',
  muiProps = '',
  showIcon = false,
  iconOrintation = 'start',
  borderColor = '',
  textColor = '#FFFFFF',
  bgColor = '#434DB8',
  fullWidth = false,
  disabled = false,
  startIconProp,
  endIconProp,
  textTransform = 'none',
  onHoverBorderColor = '',
  onHoverTextColor = '',
  onHoverBGColor = '',
  ...rest
}) => {
  const CustomButton = withStyles({
    root: {
      textTransform: textTransform,
      backgroundColor: bgColor,
      borderColor: borderColor,
      color: textColor,
      ...fontPoppins.mMd,
      '&:hover': {
        backgroundColor: onHoverBGColor || bgColor,
        borderColor: onHoverBorderColor || borderColor,
        boxShadow: variant === 'outlined' ? '0px 0px 6px #434DB880' : '0px 5px 20px #70707080',
        borderWidth: variant === 'outlined' ? '2px' : '1px',
        color: onHoverTextColor || textColor,
      },
    },
  })(Button);
  return (
    <div className={`inline ${muiProps}`}>
      <CustomButton
        variant={variant}
        onClick={onBtnClick}
        size={size}
        startIcon={showIcon && iconOrintation === 'start' ? startIconProp : <></>}
        endIcon={showIcon && iconOrintation === 'end' ? endIconProp : <></>}
        fullWidth={fullWidth}
        disabled={disabled}
        {...rest}
      >
        {label}
      </CustomButton>
    </div>
  );
};

export default ButtonComponent;

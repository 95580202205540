import React, { forwardRef, useRef } from 'react';

import { useTable } from 'react-table';
import cx from 'classnames';
import styles from './TableStyles.module.scss';
import { colors } from '@src/css_config/colorConstants';
import { getScrollPosition } from '@src/pages/analytics/common/util';

const ReactTable = forwardRef((props, ref) => {
  let { data, columns, tableHeightClass, analyticsClass, setScrollPosition } = props;
  let defaultRef = useRef(null);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data,
  });
  return (
    <div
      className="table-height overflow-auto"
      id="journal-scroll"
      ref={ref || defaultRef}
      onScroll={() => getScrollPosition(ref, setScrollPosition)}
    >
      <div className={tableHeightClass && data?.length ? tableHeightClass : ''}>
        <table {...getTableProps()} className="">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className={`${analyticsClass ? 'h-8 fontPoppinsSemiboldSm ' : 'h-12 fontPoppinsSemiboldMd'}   bg-gray-100 hover:bg-gray-100 sticky top-0 zIndex-1`}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    width={column.width}
                    style={{
                      minWidth: column.minWidth ? column.minWidth : '',
                      color: analyticsClass ? colors.rs_primary : '',
                    }}
                    className={`${analyticsClass ? 'p-0' : 'pl-5 '} text-left`}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={cx(
                    `${analyticsClass ? 'h-6' : 'h-10'} content-start fontPoppinsRegularMd`,
                    styles['rowHover']
                  )}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className={`${analyticsClass ? 'p-0' : 'pl-5 '} text-left`}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default ReactTable;

import React from 'react';
import { useHistory } from 'react-router';
// import { useLicencePackage } from '../../hooks/useLicencePackage';
const tiers = [
  {
    name: 'C-Basic',
    href: '#',
    priceMonthly: 'Its Free!',
    buy: 'Get Started',
    description: 'For smallitem trying out, TestOptimize for an year',
    includedFeatures: ['Web Testing', 'Data Testing', 'API Testing', 'Database Testing.'],
    includedFeaturesAdvantage: [
      'Build upto 100 Test Script',
      'Create uptp 25 Pages',
      'Create upto 50 Elements/Page',
      'Create upto 50 Programe Elements',
      'Create upto 25 Test Step Groups',
    ],
  },
  {
    name: 'C-Professional',
    href: '#',
    priceMonthly: '$349',
    description: 'For small and Medium sized business',
    buy: 'Buy Now',
    includedFeatures: ['Web Testing', 'Data Testing', 'API Testing', 'Database Testing.'],
    includedFeaturesAdvantage: [
      'Build upto 100 Test Script',
      'Create uptp 25 Pages',
      'Create upto 50 Elements/Page',
      'Create upto 50 Programe Elements',
      'Create upto 25 Test Step Groups',
    ],
  },
  {
    name: 'On Premises - Enterprise',
    href: '#',
    priceMonthly: '',
    description: 'For Small team trying out. TestOptimaize for an year',
    buy: 'Contact Sales',
    includedFeatures: ['Web Testing', 'Data Testing', 'API Testing', 'Database Testing.'],
    includedFeaturesAdvantage: [
      'Build upto 100 Test Script',
      'Create uptp 25 Pages',
      'Create upto 50 Elements/Page',
      'Create upto 50 Programe Elements',
      'Create upto 25 Test Step Groups',
    ],
  },
];

export default function LicencePackageForm() {
  let history = useHistory();
  // const [licencePackage,setLicencePackage]=useLicencePackage();
  const handleLogin = () => {
    console.log('loginpage details');
    history.push('/login');
  };
  const handleGetStarted = (name) => {
    console.log('handle Get Started');
    // setLicencePackage(name)
    history.push('/signUp');
  };
  return (
    <div className="bg-white">
      <div className="header">
        <div className="flex container-body">
          <div className="flex-1 logo">
            <h2>
              TES<span className="testTo">TO</span>PTIMIZE
            </h2>
          </div>
          <div className="flex-1 ..."></div>
          <div className="flex-1 ...">
            <button
              onClick={handleLogin}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded content-center loginbtn"
            >
              Login
            </button>
          </div>
        </div>
      </div>
      <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3 gridLayout">
        {tiers.map((tier) => (
          <div key={tier.name} className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200 cards">
            <div className="p-6">
              <h2 className="text-lg leading-6 font-medium text-gray-900">{tier.name}</h2>
              <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
              <p className="mt-8">
                <span className="text-4xl font-bold text-gray-900 price">{tier.priceMonthly}</span>{' '}
                <span className="text-base font-medium text-gray-500"></span>
              </p>
              <a
                href="#"
                onClick={() => handleGetStarted(tier.name)}
                className="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900 buyBtn"
              >
                {tier.buy}
              </a>
            </div>
            <div className="pt-6 pb-8 px-6 cardList">
              <ul className="mt-6 space-y-4">
                {tier.includedFeatures.map((feature) => (
                  <li key={feature} className="flex space-x-3">
                    {/* <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" /> */}
                    <span className="text-sm text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="pt-6 pb-8 px-6 cardListdetails">
              <ul className="mt-6 space-y-4">
                {tier.includedFeaturesAdvantage.map((featureadvantage) => (
                  <li key={featureadvantage} className="flex space-x-3">
                    {/* <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" /> */}
                    <span className="text-sm text-gray-500">{featureadvantage}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

import React from 'react';
import { Drawer, Box } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '@assets/analytics/ShrinkIconWhite.svg';
import TextField from '@mui/material/TextField';
import { BITBUCKET_CONSTANTS } from '../plugin_constants';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import { colors } from '@src/css_config/colorConstants';
import { validateFireFlink, createBitbucketInstance } from '@api/api_services';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { getEncryptData } from '@common/security';

const Verification = (props) => {
  const verificationData = props.verificationDetails;
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const emailId = JSON.parse(localStorage.getItem('test-optimize-user')).userName;
  const initialValues = {
    password: '',
  };
  const validationSchema = yup.object({
    password: yup.string().required('Password  is required'),
  });
  const handleVerificationClick = () => {
    if (formikDetails.isValid) {
      let data = {
        fireFlinkPassword: getEncryptData(formikDetails.values.password),
      };
      validateFireFlink(data).then((response) => {
        if (response?.data?.responseCode === 200) {
          let payload = {
            bitBucketUserName: verificationData.username,
            bitBucketAppPassword: getEncryptData(verificationData.userPasswordField),
            bitBucketWorkspaceUUID: props.workSpaceUUID,
            instanceName: verificationData.instanceName,
            bitBucketRepositoryName: verificationData.repositoryName,
            bitBucketProjectUUID: props.projectUUID,
            fireFlinkPassword: getEncryptData(response?.data?.responseObject?.fireFlinkPassword),
            suiteId: props.suiteId,
            projectId: props.projectId,
            projectType: props.projectType,
            projectName: props.projectName,
            suiteName: props.suiteName,
            fireFlinkProjectName: props.fireflinkProjectName,
            repositoryName: props.repositoryName,
            bitBucketWorkspaceName: props.workSpaceName,
          };
          createBitbucketInstance(payload).then((response) => {
            if (response?.data?.status === 'SUCCESS') {
              props.close();
              props.reloadTree();
              props.MyAlert.success(
                `${verificationData?.instanceName} ${BITBUCKET_CONSTANTS.INSTANCE_CREATED_SUCCESSFULLY}`
              );
            }
          });
        } else if (response?.data?.responseCode === 400) {
          formikDetails.setFieldError('password', 'Invalid Password');
        }
      });
    }
  };
  const formikDetails = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleVerificationClick,
  });
  return (
    <Drawer anchor="right" open={props.isOpen} onClose={props.onClose} className="verification-dialog-container">
      <Box
        sx={{
          width: 450,
          overflowX: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        role="presentation"
      >
        <section className="w-full  flex flex-col" style={{ height: 'auto' }}>
          <div className="header-drawer">
            <div className="flex items-center gap-2">
              <ArrowBackIcon fontSize="" onClick={props.onClose} />
              <span className="fontPoppinsMediumSm ">{BITBUCKET_CONSTANTS.VERIFICATION}</span>
            </div>
            <div className="verification-close-icon">
              <CloseIcon onClick={props.onClose} />
            </div>
          </div>
          <div className="w-full flex flex-col mt-4 ml-4">
            <div className="integration-label fontPoppinsMediumSm">
              {
                <>
                  {' '}
                  <span className="text-red-600">*</span> {BITBUCKET_CONSTANTS.EMAIL}
                </>
              }
            </div>
            <div className="mt-2">
              <TextField
                className="instance-input-field"
                variant="outlined"
                name="name"
                autoComplete="off"
                placeholder="Enter Email"
                value={emailId}
                disabled={true}
                style={{ width: '420px', background: colors.rs_disabled_color }}
              />
            </div>
          </div>
          <div className="w-full flex flex-col mt-2 ml-4">
            <div>
              <div className="integration-label fontPoppinsMediumSm">
                {
                  <>
                    {' '}
                    <span className="text-red-600">*</span> {BITBUCKET_CONSTANTS.PASSWORD}
                  </>
                }
              </div>
            </div>

            <div className="mt-2">
              <TextField
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                        {showPassword ? (
                          <VisibilityOutlinedIcon className="mt-0 text-[#727171]" />
                        ) : (
                          <VisibilityOffOutlinedIcon className="mt-0 ml-2 text-[#727171]" />
                        )}
                      </span>
                    </InputAdornment>
                  ),
                }}
                autoComplete="new-password"
                name="password"
                style={{ width: '420px' }}
                className="instance-input-field"
                placeholder="Enter fireflink password"
                onChange={formikDetails.handleChange}
                onBlur={formikDetails.handleBlur}
                error={formikDetails.errors.password}
              />
            </div>
            {formikDetails.errors.password && (
              <div className="error-message  fontPoppinsRegularXs9px ml-1">{formikDetails.errors.password}</div>
            )}
          </div>
          <div className="verification-footer  px-3 flex justify-end gap-4 button-content absolute bottom-0 right-0 w-full bg-white">
            <button
              type="button"
              className="buttonStyleInstance success-instance fontMontserratMediumXs"
              onClick={props.onClose}
            >
              {BITBUCKET_CONSTANTS.CANCEL}
            </button>
            <button
              type="submit"
              id="createBatch"
              className=" createInstance  cancel-instance fontMontserratMediumXs"
              onClick={formikDetails.handleSubmit}
            >
              {BITBUCKET_CONSTANTS.VERIFY}
            </button>
          </div>
        </section>
      </Box>
    </Drawer>
  );
};

export default Verification;

import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';
import { useAlert } from '../../common/alert_service/useAlert';
import Breadcrumbs from '../../common/breadcrumbs';
import { SearchIcon } from '@heroicons/react/solid';

const ConfigLibraryHistory = (props) => {
  const { AlertContatiner, MyAlert } = useAlert();
  let activeTab = props.location.pathname.split('/')[3];

  const [tabsList, setTabsList] = useState([
    {
      name: 'External Libraries',
      pathUrl: `/configuration/Externallibraries/ExternalLibraries`,
      selectedName: 'ExternalLibraries',
    },

    {
      name: 'History',
      pathUrl: `/configuration/Externallibraries/history`,
      selectedName: 'history',
    },
  ]);

  const renderTabs = () => {
    return (
      <div>
        <ul className="navBar text-blue-600 flex flex-row primary-tab">
          {tabsList.map((tab, index) => {
            return (
              <li key={tab.name}>
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    props.history.push(tab.pathUrl);
                  }}
                  className={`${activeTab === tab.selectedName ? 'selected_tab_style' : ''}`}
                >
                  {tab.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <div className="page-container">
      <div className="page-list-body">
        {renderTabs()}
        <AlertContatiner />
        <div className="page-table-body">
          <div className="configPage pr-0 content_area_style" id="journal-scroll">
            <div className="grid grid-cols-2 mb-4 ">
              <span className=" ml-3 mt-4 configPageHeading">History</span>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginRight: '15px',
                }}
              >
                <SearchIcon
                  style={{ marginRight: '-1.5rem', color: 'grey' }}
                  className=" mt-4 w-20 h-7   items-center float-left"
                />
              </div>
            </div>
            <hr></hr>

            <div className="fontPoppinsRegularMd grid cols-grid-6">
              <span className="text-sm text-gray-700 hover:bg-gray-100 h-10 p-3">
                Sangana Gouda has created apachee poi 4.0.1 on 28 jan 2021 13:20
              </span>
              <span className="text-sm text-gray-700 hover:bg-gray-100 h-10 p-3">
                Sangana Gouda has created apachee poi 4.0.1 on 21 feb 2021 13:20
              </span>
              <span className="text-sm text-gray-700 hover:bg-gray-100 h-10 p-3">
                Sangana Gouda has created apachee poi 4.0.1 on 28 jan 2021 13:20
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ConfigLibraryHistory);

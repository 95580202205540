import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import '@pages/auth/login.css';
// import firebase from 'firebase/app';
// import 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

import { activateEmail, validateUserToken } from '@api/api_services';
import Alert from '@material-ui/lab/Alert';
import { VERIFICATION_CODE_CONSTANTS, GLOBAL_CONSTANTS } from '@src/common/ui-constants';
import { getDecodedPhoneNumber } from '@src/util/common_utils';
import cx from 'classnames';
import CommonPage from '../common/CommonPage';
import commonStyles from '@pages/auth/common.module.scss';
import { ReactComponent as CircleIcon } from '@assets/circle-outline-icon.svg';

const useStyles = (open) =>
  makeStyles({
    root: {
      backgroundColor: 'unset',
      position: 'absolute',
      right: open ? '14%' : '5%',
      top: '16%',
      marginBottom: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0px',
    },
    activationAlert: {
      '& .MuiAlert-icon': {
        marginTop: 0,
      },
      '&.MuiAlert-standardSuccess': {
        backgroundColor: 'unset',
      },
    },
  });

export default function VerificationCode(props) {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASEUR,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAINSENDERID,
    appId: process.env.REACT_APP_MANAGEMENT_APPID,
    measurementId: process.env.REACT_APP_MANAGEMENT_MEASUREMENTID,
  };
  const app = initializeApp(firebaseConfig);
  let history = useHistory();
  const [success, setSuccess] = useState({ open: false, message: '' });
  const [otpValue, setOtpValue] = useState([]);
  const [mobileNo, setMobileNo] = useState('');
  const [errorContent, setErrorContent] = useState();
  const [showError, setShowError] = useState(false);
  const [phoneMask, setPhoneMask] = useState('');
  const [validToken, setValidToken] = useState(false);
  const [tokenRef, setTokenRef] = useState(false);
  const [isResendClick, setResendClick] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isResendAlert, setResendAlert] = useState({ open: false, message: '' });
  const [activeUser, setActiveUser] = useState(false);
  const ChangedPhoneNumber = sessionStorage.getItem('ChangedPhoneNumber');
  const respUrlItem = sessionStorage.getItem('respUrl');
  let setAlert = false;
  const hasActivatedUser = useRef(true);
  const search = window.location.search;
  const userId = new URLSearchParams(search).get('userid');
  const emailId = new URLSearchParams(search).get('emailId');
  const token = new URLSearchParams(search).get('token');
  const phone = getDecodedPhoneNumber(new URLSearchParams(search).get('phone'));
  const isCloudEnv = validToken && process.env.REACT_APP_ENVIRONMENT !== 'onprem';

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'onprem') {
      if (hasActivatedUser.current) {
        activateUser();
        hasActivatedUser.current = false;
      }
    } else {
      if (!!initializeApp.length) {
        const auth = getAuth(app);
        // firebase.initializeApp(firebaseConfig);
        // window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        //   size: 'invisible',
        // });
        window.recaptchaVerifier = new RecaptchaVerifier(
          'recaptcha-container',
          {
            size: 'invisible',
          },
          auth
        );
        window.recaptchaVerifier.render();
        setUserDetails();
      }
    }
  }, []);
  useEffect(() => {
    if (mobileNo) {
      sessionStorage.setItem('userid', userId);
      setPhoneMask(phone);
    }
  }, [mobileNo]);

  useEffect(() => {
    verifyToken(userId, emailId, token);
  }, []);

  useEffect(() => {
    if (tokenRef) {
       if (isCloudEnv) {
        sendOTP();
      } else if (activeUser) {
        history.push(`/token-expired?emailId=${emailId}?activationStatus=ACTIVE`);
      } else if (!validToken) {
        redirectTo(emailId);
      }
      sessionStorage.removeItem('ChangedPhoneNumber');
    }
  }, [tokenRef]);

  useEffect(() => {
    if (ChangedPhoneNumber) {
      history.push(`/otp-verification?${respUrlItem}`);
      setMobileNo('+' + ChangedPhoneNumber);
      setSuccess({ open: true });
      setTimeout(() => {
        setSuccess({ open: false });
      }, 5000);
    }
  }, [ChangedPhoneNumber]);

  const resendOtpRef = useCallback((node) => {
    if (node !== null) {
      setResendClick(true);
    }
  }, []);

  const setUserDetails = () => {
    setPhoneMask(phone);
    setMobileNo('+' + phone);
    sessionStorage.setItem('userid', userId);
  };
  function sendOTP() {
    if (validToken) {
      const appVerifier = window.recaptchaVerifier;
      const auth = getAuth();
      // firebase
      //   .auth()
      //   .signInWithPhoneNumber(mobileNo, appVerifier)
      signInWithPhoneNumber(auth, mobileNo, appVerifier)
        .then((result) => {
          window.confirmationResult = result;
          if (setAlert && isResendClick && window.confirmationResult?.verificationId) {
            setResendAlert({
              open: true,
              message: 'Resent a new code to the registered contact number successfully',
            });
            setTimeout(() => {
              setSuccess({ open: false });
              setResendAlert({ open: false });
            }, 15000);
          }
        })
        .catch((error) => {
          console.error('Send OTP Failed!', error.message);
        });
    }
  }

  const verifyToken = async (id, emailId, token) => {
    let payload = {
      id: id,
      emailId: emailId,
      passwordToken: token,
      fromPage: 'otp-verification',
    };
    try {
      let result = await validateUserToken(payload);
      if (result.data && result.data.responseCode === 200) {
        setValidToken(true);
      } else if (result.data.responseCode === 400 && result.data.message === GLOBAL_CONSTANTS.USER_ACTIVATED_MESSAGE) {
        setActiveUser(true);
        history.push(`/token-expired?emailId=${emailId}&activationStatus=ACTIVE`);
      }
      setTokenRef(true);
    } catch (error) {
      setTokenRef(true);
      console.error('Verify Token Failed!', error);
    }
  };

  function redirectTo(emailId) {
    history.push(`/token-expired?emailId=${emailId}`);
  }

  function registerKeypress() {
    const inputs = document.querySelectorAll('#otp > *[id]');
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener('keydown', function (event) {
        if (event.key === 'Backspace') {
          let idx = event.currentTarget.id;
          idx = Number(idx.split('input')[1]);

          if (inputs[idx - 1] === undefined || inputs[idx - 1].value < 10) {
            if (idx - 1 !== 0) {
              inputs[idx - 2].focus();
              inputs[idx - 1].value = '';
              // inputs[i].value = "";
              otpValue[i] = inputs[i].value;
              setOtpValue(otpValue);

              event.preventDefault();
            } else if (idx - 1 == 0) {
              inputs[idx - 1].focus();
              inputs[idx - 1].value = '';
              // inputs[i].value = "";
              otpValue[i] = inputs[i].value;
              setOtpValue(otpValue);

              event.preventDefault();
            }
          } else if (inputs[i - 1] !== '' && i === inputs.length - 1) {
            inputs[i - 1].value = '';
            if (i !== 0) inputs[i - 1].focus();
          }
        } else {
          if (i === inputs.length - 1 && inputs[i].value !== '' && !event.shiftKey) {
            return true;
          } else if (event.keyCode > 47 && event.keyCode < 58 && !event.shiftKey) {
            inputs[i].value = event.key;
            otpValue[i] = inputs[i].value;
            setOtpValue(otpValue);

            if (i !== inputs.length - 1) inputs[i + 1].focus();
            event.preventDefault();
          } else if (
            (event.keyCode > 64 && event.keyCode < 91) ||
            event.keyCode == 190 ||
            event.keyCode == 188 ||
            event.keyCode == 191 ||
            event.keyCode == 186 ||
            event.keyCode == 222 ||
            event.keyCode == 219 ||
            event.keyCode == 221 ||
            event.keyCode == 220 ||
            event.keyCode == 189 ||
            event.keyCode == 187 ||
            event.keyCode == 219 ||
            event.keyCode == 221 ||
            event.keyCode == 192 ||
            event.keyCode == 32 ||
            event.shiftKey
          ) {
            event.preventDefault();
            return false;
          }
        }
      });
    }
  }

  registerKeypress();

  function handleKeyPress(event, index) {
    let key = event.keyCode || event.charCode;

    if (key == 8 || key == 46) return false;
    else {
      let data = [...otpValue];

      data[index] = event.target.value;

      let ob = event.target;

      setOtpValue(data);

      if (ob.nextElementSibling != null) {
        ob.nextElementSibling?.focus();
      } else {
      }
    }
  }
  const [createUpdateCalled, setCreateUpdateCalled] = useState(true);
  function verityOTP(event) {
    if (createUpdateCalled) {
      setCreateUpdateCalled(false);
      let data = [...otpValue];

      const result = data.filter((word) => word != '');

      let a = document.getElementById('errorId');
      if (result.length == 0) {
        let a = document.getElementById('errorId');
        a.innerHTML = 'Verification code is required';
      } else if (result.length == 6) {
        a.innerHTML = '';
        setPhoneMask(phone);
        setMobileNo('+' + phone);
      } else {
        a.innerHTML = '';
      }
      const myOtp = otpValue.join('');

      setShowError(false);
      window.confirmationResult
        ?.confirm(myOtp)
        .then((result) => {
          setIsLoading(true);
          activateUser();
        })
        .catch((error) => {
          setShowError(true);
          setErrorContent('This verification code is invalid, please enter a valid code');
        });
    }
  }

  const activateUser = async () => {
    if (userId && token) {
      const payload = {
        id: userId,
        passwordToken: token,
      };
      try {
        let response = await activateEmail(payload);
        if (response?.data?.responseCode === 200) {
          setIsLoading(false);
          history.push('/activation-successful');
        } else if (
          response?.data?.responseCode === 400 &&
          response?.data?.message === GLOBAL_CONSTANTS.USER_ACTIVATED_MESSAGE
        ) {
          setActiveUser(true);
          history.push(`/token-expired?emailId=${emailId}?activationStatus=ACTIVE`);
        }
      } catch (error) {
        console.error('ACTIVATE_USER_API_FAILURE', error);
      }
    }
  };
  React.useEffect(() => {
    document.getElementById('input1')?.focus();
  }, []);

  function MaskCharacter(str, mask, n = 1) {
    return ('' + str).slice(0, -n).replace(/./g, mask) + ('' + str).slice(-n);
  }

  const resendOTP = (event) => {
    if (event.type === 'click' && isResendClick) {
      setAlert = true;
      sendOTP();
    }
  };

  const classes = useStyles(success.open);
  // if(process?.env?.REACT_APP_ENVIRONMENT === 'onprem') {
  //   return <div className="min-h-screen bg-white flex justify-center items-center">
  //     <p>Activation in progress...</p>
  //   </div>
  // }
  return (
    isCloudEnv && (
      <CommonPage marginTop={(isResendAlert.open || success.open) && 'mt-2'}>
        <div id="recaptcha-container"></div>
        <div className="">
          {success.open && !isResendAlert.open && (
            <Alert
              className={cx(classes.root, 'flex justify-center items-center absolute')}
              iconMapping={{
                success: <CircleIcon className="alertIconRegSuc" fontSize="inherit" />,
              }}
            >
              <span className="activationSuccess fontPoppinsRegularLg text-center">
                {success?.message ? success?.message : 'Phone number changed successfully'}
              </span>
            </Alert>
          )}
          {isResendAlert.open && (
            <Alert
              className={cx(classes.root, 'flex justify-center items-center absolute')}
              iconMapping={{
                success: <CircleIcon className="alertIconRegSuc" fontSize="inherit" />,
              }}
            >
              <span className="activationSuccess resend-alert green-message fontPoppinsRegularLg text-center">
                {isResendAlert?.message}
              </span>
            </Alert>
          )}
          {isCloudEnv && (
            <>
              <div className="">
                <div
                  className={cx(
                    'text-center fontPoppinsSemibold-size-26 mb-5',
                    (isResendAlert.open || success.open) && 'mt-28'
                  )}
                >
                  {VERIFICATION_CODE_CONSTANTS.VERIFICATION_CODE}
                </div>
                <div className="flex flex-col text-center fontPoppinsMediumMd gap-2">
                  <div className="text-center fontPoppinsRegularMd">
                    &nbsp;{VERIFICATION_CODE_CONSTANTS.ENTER_THE_CODE}&nbsp;
                    {MaskCharacter(phoneMask, '*', 4)}
                  </div>
                  <div className="text-center fontPoppinsRegularMd mb-7">
                    if the number is incorrect &nbsp;
                    <Link to={`/phone-validation`} className="blue-link fontPoppinsMediumMd">
                      {GLOBAL_CONSTANTS.CLICK_HERE}
                    </Link>
                    &nbsp; to change it &nbsp;
                  </div>
                </div>
              </div>
              <div id="otpText" className="otpText">
                <div id="otp" className="">
                  {[1, 2, 3, 4, 5, 6].map((item, i) => (
                    <input
                      key={`key_${i}`}
                      id={`inputs${item}`}
                      autoComplete="off"
                      onChange={(e) => {
                        handleKeyPress(e, i);
                        e.target.value = e.target.value.replace(/\D/g, '');
                      }}
                      onKeyUp={(e) => {
                        e.target.value = e.target.value.replace(/\D/g, '');
                      }}
                      className="m-2 text-center  "
                      type="text"
                      maxLength="1"
                      onBlur={() => {
                        setCreateUpdateCalled(true);
                      }}
                    />
                  ))}{' '}
                </div>
                {showError && <span className="code-error-message fontPoppinsRegularSm">{errorContent}</span>}
                <span id="errorId" className="code-error-message fontPoppinsRegularSm "></span>
              </div>
              <div className="flex flex-col justify-center items-center gap-7 mb-3">
                <div className="fontPoppinsMediumMd">
                  {VERIFICATION_CODE_CONSTANTS.HAVENT_RECEIVED_CODE}
                  <span
                    ref={resendOtpRef}
                    onClick={resendOTP}
                    className=" cursor-pointer fontPoppinsMediumMd blue-link"
                  >
                    &nbsp; {VERIFICATION_CODE_CONSTANTS.RESEND_NEW_CODE}
                  </span>
                </div>
                <button
                  className={cx('cursor-pointer', commonStyles['btn-bg-orange'], commonStyles['common-btn'])}
                  onClick={verityOTP}
                >
                  <span className="fontPoppinsSemiboldSm">
                    {isLoading
                      ? `${VERIFICATION_CODE_CONSTANTS.VERIFYING}`
                      : `${VERIFICATION_CODE_CONSTANTS.VERIFYING_AND_CONTINUE}`}
                  </span>
                </button>
              </div>
            </>
          )}
        </div>
      </CommonPage>
    )
  );
}

import Style from '@pages/common/LicensePurchaseComponents/styles/purchase-summary-card.module.scss';
import PropTypes from 'prop-types';
import { getUSDFormatAmountString } from '@util/common_utils';
import {
  getSelectedLicenseFeature,
  getSelectedCloudServices,
  selectedServicesTextFormat,
  FREE_LICENSE_TYPE,
  getLicensePlanCost,
  getRemainingDaysCost,
  LICENSE_FORM_TYPES,
  daysInMonth,
  getPlatfromCharge,
} from '@util/licenseUtils';
import cx from 'classnames';
import { LICENSE_DETAILS_CONSTANTS, LICENSE_CONSTANTS } from '@common/ui-constants';
import { useState, useEffect } from 'react';
import { ReactComponent as Information } from '@assets/svg-imports/LicenseMangementTooltipIcon.svg';
import { Tooltip } from '@material-ui/core';
function PurchaseSummaryCard({
  parallelRuns,
  storage,
  columnTitles,
  costPerQuantityText,
  planDetails,
  isAutomation,
  SelectedCloudServices,
  SelectedServices,
  licenseTypes,
  months,
  formType,
  licenseData,
  previousFeatures,
  newFeatures,
  isCBasic,
  licensePlanType,
  netTotal,
}) {
  let licenseFeatures = planDetails.licenseFeatures.filter((item) => item !== LICENSE_DETAILS_CONSTANTS?.AUTOMATION);
  let isBasicLicenseType = planDetails.licenseType === FREE_LICENSE_TYPE || planDetails.licenseType === undefined;
  let isLicenseType = planDetails.licenseType;
  let isSelectedbillingCycle = planDetails.selectedBillingLabel;
  let licenseResponse = licenseTypes;
  let hasAutomationServices = SelectedServices.length >= 1;

  const [serviceType, setServiceType] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [storageCost, setStorageCost] = useState(0);
  const [numberOfParallelRuns, setNumberofParallelRuns] = useState(planDetails.parallelRuns);
  const [grandTotal, setGrandTotal] = useState();
  const [isParallelRunsIncreased, setIsParallelRunsIncreased] = useState(false);
  const defaultParallelRuns = 1;

  const isUpgrade = formType === LICENSE_FORM_TYPES?.upgrade?.type;
  const isUpgradeAndParallelChanged = isUpgrade && isParallelRunsIncreased;
  useEffect(() => {
    if (parallelRuns?.quantity) {
      setNumberofParallelRuns(parallelRuns?.quantity);
    }
  }, [parallelRuns?.quantity]);

  useEffect(() => {
    if (parallelRuns?.quantity > licenseData?.numberOfParallelRuns) {
      setIsParallelRunsIncreased(true);
    } else if (parallelRuns?.quantity <= licenseData?.numberOfParallelRuns) {
      setIsParallelRunsIncreased(false);
    }
  }, [parallelRuns?.quantity]);

  useEffect(() => {
    let totalCharge = 0;
    let serviceTypeCostPerQuantity = {};
    let Totalamount = 0;

    const calculateCharge = (data, service) => {
      let charge;
      switch (isSelectedbillingCycle) {
        case 'Monthly':
          charge = data.monthlyCharge;
          break;
        case 'Quarterly':
          charge = data.quarterlyCharge;
          break;
        case 'HalfYearly':
          charge = data.halfYearlyCharge;
          break;
        case 'Yearly':
          charge = data.yearlyCharge;
          break;
        default:
          charge = data.monthlyCharge;
          break;
      }
      serviceTypeCostPerQuantity[service] = charge;
      totalCharge += charge;
      Totalamount += totalCharge;
    };

    SelectedServices.forEach((service) => {
      licenseResponse.forEach((licdata) => {
        if (licdata.type === planDetails?.licenseType) {
          licdata.plans.forEach((data) => {
            if (data.plan === service) {
              calculateCharge(data, service);
            }
          });
        }
      });
    });

    if (licenseFeatures) {
      planDetails.licenseFeatures
        .filter((item) => item !== 'Automation')
        .forEach((item) => {
          licenseResponse.forEach((licdata) => {
            if (licdata.type === planDetails?.licenseType) {
              licdata.plans.forEach((data) => {
                if (data.plan === item) {
                  calculateCharge(data, item);
                }
              });
            }
          });
        });
    }
    if (storage) {
      licenseResponse.forEach((licdata) => {
        if (licdata.type === planDetails?.licenseType) {
          let storageCost = licdata.additionalStorageCost;
          setStorageCost(storageCost);
          Totalamount += storageCost * storage.quantity;
        }
      });
    }
    setSelectedFeature(totalCharge);
    setServiceType(serviceTypeCostPerQuantity);
    setGrandTotal(Totalamount);
    getLicensePlanCost({
      numberOfParallelRuns: numberOfParallelRuns,
    });
  }, [SelectedServices, isSelectedbillingCycle, licenseResponse, storage, grandTotal]);

  function calculatePlatformPrice(isSelectedbillingCycle) {
    return getPlatfromCharge(isSelectedbillingCycle, licensePlanType);
  }
  const platformPrice = calculatePlatformPrice(isSelectedbillingCycle);

  let upgradeStorageCost =
    (storageCost / daysInMonth) * (licenseData?.remainingDays == 0 ? 1 : licenseData?.remainingDays);

  return (
    <div className={cx(Style['card-wrapper'], 'h-full')}>
      <h1 className={cx(Style['card-header'], 'fontPoppinsSemiboldSm flex items-center')}>
        <span>{LICENSE_CONSTANTS.PURCHASE_SUMMARY}</span>{' '}
        {isUpgrade && licenseData?.licenseType !== FREE_LICENSE_TYPE && (
          <Tooltip
            title={`Charges applied for remaining ${licenseData?.remainingDays == 0 ? 1 : licenseData?.remainingDays}days of the billing cycle.`}
          >
            <span className="ml-2">{<Information />}</span>
          </Tooltip>
        )}
      </h1>
      <div className={cx(Style['table-container'], Style['purchase-summary'])}>
        <div className={Style['table-head']}>
          <div className={cx('flex', Style['table-row'])}>
            <div className={cx(Style['table-head-cell'], 'fontPoppinsSemiboldSm')}>
              {isUpgrade && !isCBasic ? (
                <div className="flex flex-row">
                  <span>{columnTitles.specifications}</span>
                </div>
              ) : (
                <span> {columnTitles.specifications}</span>
              )}
            </div>
            <div className={cx(Style['table-head-cell'], 'fontPoppinsSemiboldSm')}>{columnTitles.perUnitCost}</div>
            <div className={cx(Style['table-head-cell'], 'fontPoppinsSemiboldSm')}>{columnTitles.quantity}</div>
            <div className={cx(Style['table-head-cell'], 'fontPoppinsSemiboldSm')}>{columnTitles.totalCost}</div>
          </div>
        </div>
        {!isUpgrade || (isUpgrade && isCBasic) ? (
          <div className={Style['table-body']}>
            {licenseFeatures &&
              licenseFeatures.map((feature, index) => {
                const selectedLicenseFeature = getSelectedLicenseFeature(feature);
                const featureQuantity = 1;
                const featureCost = getUSDFormatAmountString(serviceType[feature]);
                const totalFeatureCost = getUSDFormatAmountString(serviceType[feature]);

                return (
                  <div key={`${feature}-${index}`} className={cx('flex', Style['table-row'])}>
                    <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>{selectedLicenseFeature}</div>
                    <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>{featureCost}</div>
                    <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>{featureQuantity}</div>
                    <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>{totalFeatureCost}</div>
                  </div>
                );
              })}

            {!isBasicLicenseType &&
              SelectedServices &&
              SelectedServices.map((service, index) => (
                <div key={`${index}-${service}`} className={cx('flex', Style['table-row'])}>
                  <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                    {selectedServicesTextFormat(service) + ` ${LICENSE_DETAILS_CONSTANTS?.AUTOMATION}`}
                  </div>
                  <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                    {getUSDFormatAmountString(serviceType[service])}
                  </div>
                  <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                    {isLicenseType && parallelRuns?.quantity}&nbsp;
                    {LICENSE_DETAILS_CONSTANTS?.PARALLEL_RUNS_DISPLAY(parallelRuns?.quantity, hasAutomationServices)}
                  </div>

                  <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                    {getUSDFormatAmountString(serviceType[service] * parallelRuns?.quantity)}
                  </div>
                </div>
              ))}

            {SelectedCloudServices &&
              SelectedCloudServices.map((service) => (
                <div key={service} className={cx('flex', Style['table-row'])}>
                  <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                    {getSelectedCloudServices(service)}
                  </div>
                  <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>{'90.00'}</div>
                  <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                    {`2 ${LICENSE_DETAILS_CONSTANTS?.PARALLEL_RUNS_DISPLAY(2)}`}
                  </div>
                  <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>{'180.00'}</div>
                </div>
              ))}
            {
              <div className={cx('flex', Style['table-row'])}>
                <div className={cx(cx(Style['table-body-cell'], 'fontPoppinsRegularSm'), 'fontPoppinsRegularSm')}>
                  {LICENSE_DETAILS_CONSTANTS?.FIREFLINK__PLATFORM}
                </div>
                <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                  {isBasicLicenseType ? '0.00' : platformPrice + '.00'}
                </div>
                <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                  {isLicenseType && parallelRuns?.quantity}&nbsp;
                  {LICENSE_DETAILS_CONSTANTS?.PARALLEL_RUNS_DISPLAY(parallelRuns?.quantity, hasAutomationServices)}
                </div>

                <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                  {getUSDFormatAmountString(isBasicLicenseType ? '0.00' : platformPrice * parallelRuns?.quantity)}
                </div>
              </div>
            }
            {storage.quantity > 0 && (
              <div className={cx('flex', Style['table-row'])}>
                <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                  {LICENSE_DETAILS_CONSTANTS?.STORAGE}{' '}
                </div>
                <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                  {getUSDFormatAmountString(storageCost * months)}
                  {costPerQuantityText.storage && (
                    <span className={Style['cost-per-quantity-text']}>{costPerQuantityText.storage}</span>
                  )}
                </div>
                <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                  {isBasicLicenseType ? '2GB' : storage.quantity}
                </div>
                <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                  {getUSDFormatAmountString(storageCost * months * storage.quantity)}
                </div>
              </div>
            )}
          </div>
        ) : isUpgradeAndParallelChanged ? (
          <div className={Style['table-body']}>
            {licenseFeatures &&
              licenseFeatures.map((feature, index) => {
                if (!previousFeatures?.includes(feature)) {
                  const selectedLicenseFeature = getSelectedLicenseFeature(feature);
                  const featureQuantity = 1;
                  const featureCost = getUSDFormatAmountString(
                    getRemainingDaysCost(serviceType[feature], licenseData?.remainingDays, months)
                  );
                  const totalFeatureCost = getUSDFormatAmountString(
                    getRemainingDaysCost(serviceType[feature], licenseData?.remainingDays, months)
                  );

                  return (
                    <div key={`${feature}-${index}`} className={cx('flex', Style['table-row'])}>
                      <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                        {selectedLicenseFeature}
                      </div>
                      <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>{featureCost}</div>
                      <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>{featureQuantity}</div>
                      <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>{totalFeatureCost}</div>
                    </div>
                  );
                }
              })}

            {!isBasicLicenseType &&
              SelectedServices &&
              SelectedServices.map(
                (service, index) =>
                  !previousFeatures?.includes(service) && (
                    <div key={`${index}-${service}`} className={cx('flex', Style['table-row'])}>
                      <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                        {selectedServicesTextFormat(service) + ` ${LICENSE_DETAILS_CONSTANTS?.AUTOMATION}`}
                      </div>
                      <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                        {getUSDFormatAmountString(
                          getRemainingDaysCost(serviceType[service], licenseData?.remainingDays, months)
                        )}
                      </div>
                      <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                        {isLicenseType && parallelRuns?.quantity}&nbsp;
                        {LICENSE_DETAILS_CONSTANTS?.PARALLEL_RUNS_DISPLAY(
                          parallelRuns?.quantity,
                          hasAutomationServices
                        )}
                      </div>

                      <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                        {getUSDFormatAmountString(
                          getRemainingDaysCost(serviceType[service], licenseData?.remainingDays, months) *
                            parallelRuns?.quantity
                        )}
                      </div>
                    </div>
                  )
              )}

            {!isBasicLicenseType &&
              SelectedServices &&
              SelectedServices.map(
                (service, index) =>
                  previousFeatures?.includes(service) && (
                    <div key={`${index}-${service}`} className={cx('flex', Style['table-row'])}>
                      <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                        {selectedServicesTextFormat(service) + ` ${LICENSE_DETAILS_CONSTANTS?.AUTOMATION}`}
                      </div>
                      <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                        {getUSDFormatAmountString(
                          getRemainingDaysCost(serviceType[service], licenseData?.remainingDays, months)
                        )}
                      </div>
                      <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                        {isLicenseType && parallelRuns?.quantity - licenseData?.numberOfParallelRuns}&nbsp;
                        {LICENSE_DETAILS_CONSTANTS.PARALLEL_RUNS_DISPLAY(parallelRuns?.quantity)}
                      </div>

                      <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                        {getUSDFormatAmountString(
                          getRemainingDaysCost(serviceType[service], licenseData?.remainingDays, months) *
                            (parallelRuns?.quantity - licenseData?.numberOfParallelRuns)
                        )}
                      </div>
                    </div>
                  )
              )}

            {SelectedCloudServices &&
              SelectedCloudServices.map(
                (service) =>
                  !previousFeatures?.includes(service) && (
                    <div key={service} className={cx('flex', Style['table-row'])}>
                      <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                        {getSelectedCloudServices(service)}
                      </div>
                      <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>{'90.00'}</div>
                      <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                        {`2 ${LICENSE_DETAILS_CONSTANTS?.PARALLEL_RUNS_DISPLAY(2)} `}
                      </div>
                      <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>{'180.00'}</div>
                    </div>
                  )
              )}
            {isParallelRunsIncreased && (
              <div className={cx('flex', Style['table-row'])}>
                <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                  {LICENSE_DETAILS_CONSTANTS?.FIREFLINK__PLATFORM}
                </div>
                <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                  {isBasicLicenseType
                    ? '0.00'
                    : (platformPrice / (daysInMonth * months)) *
                        (licenseData?.remainingDays == 0 ? 1 : licenseData?.remainingDays) +
                      '.00'}
                </div>
                <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                  {isLicenseType && parallelRuns?.quantity - licenseData?.numberOfParallelRuns}&nbsp;
                  {LICENSE_DETAILS_CONSTANTS?.PARALLEL_RUNS_DISPLAY(parallelRuns?.quantity, hasAutomationServices)}
                </div>

                <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                  {getUSDFormatAmountString(
                    isBasicLicenseType
                      ? '0.00'
                      : (platformPrice / (daysInMonth * months)) *
                          (licenseData?.remainingDays == 0 ? 1 : licenseData?.remainingDays) *
                          (parallelRuns?.quantity - licenseData?.numberOfParallelRuns)
                  )}
                </div>
              </div>
            )}
            {storage.quantity > 0 && (
              <div className={cx('flex', Style['table-row'])}>
                <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                  {LICENSE_DETAILS_CONSTANTS?.STORAGE}
                </div>
                <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                  {getUSDFormatAmountString(upgradeStorageCost)}
                  {costPerQuantityText.storage && (
                    <span className={Style['cost-per-quantity-text']}>{costPerQuantityText.storage}</span>
                  )}
                </div>
                <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                  {isBasicLicenseType ? '2GB' : storage.quantity}
                </div>
                <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                  {getUSDFormatAmountString(upgradeStorageCost * storage.quantity)}
                </div>
              </div>
            )}
          </div>
        ) : !isUpgradeAndParallelChanged ? (
          <div className={Style['table-body']}>
            {licenseFeatures &&
              licenseFeatures.map((feature, index) => {
                let selectedLicenseFeature, featureQuantity, featureCost, totalFeatureCost;
                if (!previousFeatures?.includes(feature)) {
                  selectedLicenseFeature = getSelectedLicenseFeature(feature);
                  featureQuantity = 1;
                  featureCost = getUSDFormatAmountString(
                    getRemainingDaysCost(serviceType[feature], licenseData?.remainingDays, months)
                  );
                  totalFeatureCost = getUSDFormatAmountString(
                    getRemainingDaysCost(serviceType[feature], licenseData?.remainingDays, months)
                  );
                }

                return (
                  <div key={`${feature}-${index}`} className={cx('flex', Style['table-row'])}>
                    <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>{selectedLicenseFeature}</div>
                    <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>{featureCost}</div>
                    <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>{featureQuantity}</div>
                    <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>{totalFeatureCost}</div>
                  </div>
                );
              })}

            {!isBasicLicenseType &&
              SelectedServices &&
              SelectedServices.map(
                (service, index) =>
                  !previousFeatures?.includes(service) && (
                    <div key={`${index}-${service}`} className={cx('flex', Style['table-row'])}>
                      <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                        {selectedServicesTextFormat(service)} {LICENSE_DETAILS_CONSTANTS?.AUTOMATION}
                      </div>
                      <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                        {getUSDFormatAmountString(
                          getRemainingDaysCost(serviceType[service], licenseData?.remainingDays, months)
                        )}
                      </div>
                      <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                        {isLicenseType && defaultParallelRuns}&nbsp;
                        {LICENSE_DETAILS_CONSTANTS?.PARALLEL_RUNS_DISPLAY(
                          parallelRuns?.quantity,
                          hasAutomationServices
                        )}
                      </div>

                      <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                        {getUSDFormatAmountString(
                          getRemainingDaysCost(serviceType[service], licenseData?.remainingDays, months)
                        )}
                      </div>
                    </div>
                  )
              )}

            {SelectedCloudServices &&
              SelectedCloudServices.map(
                (service) =>
                  !previousFeatures?.includes(service) && (
                    <div key={service} className={cx('flex', Style['table-row'])}>
                      <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                        {getSelectedCloudServices(service)}
                      </div>
                      <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>{'90.00'}</div>
                      <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                        {`2 ${LICENSE_DETAILS_CONSTANTS?.PARALLEL_RUNS_DISPLAY(2)}`}
                      </div>
                      <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>{'180.00'}</div>
                    </div>
                  )
              )}
            {isParallelRunsIncreased && (
              <div className={cx('flex', Style['table-row'])}>
                <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                  {LICENSE_DETAILS_CONSTANTS?.FIREFLINK__PLATFORM}
                </div>
                <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                  {isBasicLicenseType ? '0.00' : platformPrice + '.00'}
                </div>
                <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                  {isLicenseType && parallelRuns?.quantity}
                  {LICENSE_DETAILS_CONSTANTS?.PARALLEL_RUNS_DISPLAY(parallelRuns?.quantity, hasAutomationServices)}
                </div>

                <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                  {getUSDFormatAmountString(isBasicLicenseType ? '0.00' : platformPrice * parallelRuns?.quantity)}
                </div>
              </div>
            )}
            {storage.quantity > 0 && (
              <div className={cx('flex', Style['table-row'])}>
                <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                  {' '}
                  {LICENSE_DETAILS_CONSTANTS?.STORAGE}
                </div>
                <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                  {getUSDFormatAmountString(upgradeStorageCost)}
                  {costPerQuantityText.storage && (
                    <span className={Style['cost-per-quantity-text']}>{costPerQuantityText.storage}</span>
                  )}
                </div>
                <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                  {isBasicLicenseType ? '2GB' : storage.quantity}
                </div>
                <div className={cx(Style['table-body-cell'], 'fontPoppinsRegularSm')}>
                  {getUSDFormatAmountString(upgradeStorageCost * storage.quantity)}
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
      <div className={Style['total-amount']}>
        <p className="fontPoppinsSemiBoldSm">Total Amount</p>
        <p className="fontPoppinsSemiBoldSm pr-24">${getUSDFormatAmountString(netTotal)}</p>
      </div>
    </div>
  );
}

PurchaseSummaryCard.propTypes = {
  parallelRuns: PropTypes.shape({
    quantity: PropTypes.number,
    unitCost: PropTypes.number,
    totalCost: PropTypes.number,
  }),
  storage: PropTypes.shape({
    quantity: PropTypes.number,
    unitCost: PropTypes.number,
    totalCost: PropTypes.number,
  }),
  columnTitles: PropTypes.shape({
    specifications: PropTypes.string,
    perUnitCost: PropTypes.string,
    quantity: PropTypes.string,
    totalCost: PropTypes.string,
  }),
  costPerQuantityText: PropTypes.shape({
    parallelRuns: PropTypes.string,
    storage: PropTypes.string,
  }),
  planDetails: PropTypes.shape({
    totalCost: PropTypes.number,
    parallelRuns: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    storage: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  }),
};

PurchaseSummaryCard.defaultProps = {
  parallelRuns: {
    quantity: 0,
    unitCost: 0,
    totalCost: 0,
  },
  storage: {
    quantity: 0,
    unitCost: 0,
    totalCost: 0,
  },
  columnTitles: {
    specifications: 'Specifications',
    perUnitCost: 'Cost ($)',
    quantity: 'Quantity',
    totalCost: 'Total Cost ($)',
  },
  costPerQuantityText: PropTypes.shape({
    parallelRuns: '',
    storage: '',
  }),
  planDetails: PropTypes.shape({
    totalCost: undefined,
    parallelRuns: 0,
    storage: 0,
  }),
};

export default PurchaseSummaryCard;

import React, { useState, Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { addAsNewElementReq, postElementReq } from '../../../../../../api/api_services';
function MergeConflictModal(props) {
  const [open, setOpenModal] = useState(true);
  let [elementConflictModal, setElementConflictModal] = useState(true);
  let [sharingEle, setSharingEle] = useState(props.sharingEle);
  let name = props.sharingEle?.name;
  let handelElementConflictModal = (val) => {
    setElementConflictModal(val);
  };
  useEffect(() => {
    getAllSharedElementsAndElementsReq();
  }, [props.sharingEle]);

  let getAllSharedElementsAndElementsReq = async () => {
    setSharingEle(props.sharingEle);
  };
  let createAsEle = async () => {
    let payload = sharingEle;
    payload.isShared = 'N';
    let response;
    if (props.createAsSharedEle) {
      try {
        response = await postElementReq(payload, props.pageKeyForEle);
        if (response.data.responseObject) {
          props.reloadTree(true);
          props.MyAlert.success(`${payload.name} ${payload.type} created successfully`);
          props?.getAllElemtsReq(props?.projectType, props?.platForm);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  let addAsNewSharedElementReq = async () => {
    let payload = sharingEle;
    let parentId = payload.parentId;
    let eleId = payload.id;
    payload.isShared = 'YES';
    delete payload.id;
    try {
      let response = await addAsNewElementReq(payload, eleId, parentId);
      if (response.data?.responseCode === 201) {
        props?.getAllElemtsReq(props?.projectType, props?.platForm);
        props.reloadTree(true);
        props.updateSharedData('Y');
      } else {
        if (props.createAsSharedEle) {
          createAsEle();
        }
      }
      if (props.createAsSharedEle && response.data?.responseCode === 201) {
        props.reloadTree(true);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={() => {}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block marginupper pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/4">
              <div className="flex flex-start ml-2">
                <span className="pt-5 pl-3">
                  <ExclamationIcon className="h-6 w-6 text-yellow-600 " />
                </span>
                <Dialog.Title as="h3" className="text-sm leading-6 ml-4 pt-3 font-small text-gray-900">
                  <span className="fontPoppinsSemiboldLg block">Warning </span>
                  <span className="fontPoppinsMediumMd">Element Conflict - {name} </span>
                </Dialog.Title>
              </div>
              <hr className="line mt-px" />
              <div className="fontPoppinsRegularMd mt-3 text-gray-500 ml-16 mr-8">
                <p className="mb-4">Do you want to...</p>
                <p className="mb-4">
                  <input
                    name="elementConflict"
                    defaultChecked={true}
                    onClick={() => {
                      handelElementConflictModal(true);
                    }}
                    className=" mr-4"
                    type="radio"
                  />
                  Merge the locators of the elements?{' '}
                </p>
                <p className="mt-2">
                  <input
                    name="elementConflict"
                    onClick={() => {
                      handelElementConflictModal(false);
                    }}
                    className="mr-4"
                    type="radio"
                  />{' '}
                  Add this element as a separate element in shared element?
                </p>
              </div>
              <hr className="line mt-3" />
              <div className="mt-2 pb-2 flex flex-row float-right">
                <button
                  type="button"
                  className="mt-3 ml-4 primary-btn"
                  onClick={() => {
                    props.closeModal(false);
                    setOpenModal(false);
                    props.openElementConflict(elementConflictModal);
                    if (!elementConflictModal) {
                      addAsNewSharedElementReq();
                    }
                  }}
                >
                  <span className="create-label-common create-label-layout-style-project fontPoppinsMediumMd">
                    Save
                  </span>
                </button>
                <button
                  type="button"
                  className="mt-3 ml-4 gray-btn"
                  onClick={() => {
                    createAsEle();
                    props.unCheckShareElementBtn(sharingEle);
                    setOpenModal(false);
                    props.closeModal(false);
                  }}
                >
                  <span className="cancel-label-common cancel-label-layout-style-project fontPoppinsMediumMd">
                    Cancel
                  </span>
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default MergeConflictModal;

import LicenseWizardProvider from '@pages/licenses/components/LicenseProvider';
import LicenseWizard from '@pages/licenses/components/LicenseWizard';
import style from '@pages/licenses/styles/license-form.module.scss';
import { useRef } from 'react';

function LicenseForm() {
  const licenseFormPageRef = useRef();

  return (
    <div ref={licenseFormPageRef}>
      <div className="pl-1">
        <div className={style['form-layout']}>
          <LicenseWizardProvider>
            <LicenseWizard ref={licenseFormPageRef} />
          </LicenseWizardProvider>
        </div>
      </div>
    </div>
  );
}

export default LicenseForm;

import React from 'react';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { fontPoppins } from '@src/css_config/fontConstants';
const { rSm } = fontPoppins;
const useStyles = makeStyles((theme) => ({
  customTextField: {
    '& input::placeholder': {
      ...rSm,
    },
  },
}));
const theme = createTheme({
  overrides: {
    MuiInput: {
      underline: {
        '&:hover:not($disabled):after': {
          borderColor: '#989797 !important',
        },
        '&:hover:not($disabled):before': {
          borderColor: '#989797 !important',
        },
        '&&:hover::before': {
          borderColor: '#989797 !important',
        },
        '&&:hover::after': {
          borderColor: '#989797 !important',
        },
      },
    },
  },
});
function MyInput({ error, ...props }) {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <div className="rs-user-input-container">
        <TextField
          classes={{ root: classes.customTextField }}
          autoComplete="new-password"
          autoFocus={props}
          error={error}
          InputLabelProps={{
            shrink: true,
          }}
          {...props}
        />
      </div>
    </ThemeProvider>
  );
}

export default MyInput;

import React from 'react';
import { Modal, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

function CommonModalComponent({
  headerContent = '',
  titleText = '',
  isModalOpen = false,
  rightButtonText = 'Save',
  leftButtonText = 'Cancel',
  isRightButtonVisible = true,
  isLeftButtonVisible = true,
  disableRightButton = false,
  disableLeftButton = false,
  disableCloseButton = false,
  onRightButtonClick = () => {},
  onLeftButtonClick = () => {},
  footerContent = null,
  handleClose = () => {},
  children = '',
  classNames = '',
  removeCloseIcon = false,
  size = 'sm',
}) {
  return (
    <div>
      <Modal open={isModalOpen} className="modal-dialog" onClose={handleClose}>
        <div
          className={`${size === 'md' ? 'min-w-65vw' : size === 'lg' ? '90vw' : '45vw'} common-var-max-popup-style   modal-container `}
        >
          <div className="modal-header ">
            {headerContent ? headerContent : <label className="Popup-header-common">{titleText}</label>}
            {!removeCloseIcon && (
              <IconButton
                color="primary"
                component="span"
                className="close"
                disabled={disableCloseButton}
                onClick={() => {
                  handleClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>
          <div className={classNames ? classNames : 'p-6 h-screen'}>{children}</div>
          <div className="modal-footer">
            {footerContent ? (
              footerContent
            ) : (
              <div className="float-right">
                {isLeftButtonVisible && (
                  <button className="gray-btn mr-3" disabled={disableLeftButton} onClick={onLeftButtonClick}>
                    {leftButtonText}
                  </button>
                )}
                {isRightButtonVisible && (
                  <button
                    type="button"
                    className="primary-btn"
                    disabled={disableRightButton}
                    onClick={onRightButtonClick}
                  >
                    {rightButtonText}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CommonModalComponent;

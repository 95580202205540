import { ReactComponent as ClosedDefectsIcon } from '@assets/analytics/ClosedDefectsIcon.svg';
import { ReactComponent as TotalDefectsIcon } from '@assets/analytics/TotalDectsIcon.svg';
import { ReactComponent as DensityIcon } from '@assets/analytics/DensityIcon.svg';
import { ReactComponent as OpenDefectsIcon } from '@assets/analytics/OpenDefectsIcon.svg';
import { ReactComponent as QualityScoreIcon } from '@assets/analytics/QualityScoreIcon.svg';

export default function DefectsCardIconsRenderer(props) {
  switch (props.name) {
    case 'Total Defects':
      return <TotalDefectsIcon />;

    case 'defect Density':
      return <DensityIcon />;

    case 'New Defects':
      return <OpenDefectsIcon />;

    case 'Closed Defects':
      return <ClosedDefectsIcon />;

    case 'Quality Score':
      return <QualityScoreIcon />;

    default:
      return <></>;
  }
}

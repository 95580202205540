import React, { useState, useEffect, useContext } from 'react';
import '@pages/test-development/script/scripts/webservice/restapi/request/request_parameter/css/request_parameter.scss';
import '@pages/test-development/script/scripts/webservice/restapi/css/common_Webservice.scss';
import { getAllHeaderKey, getAllHeaderValueApi } from '@api/api_services';
import MyInput from '@pagescommon/Inputs/MyInput';
import { RestContext } from '@pages/test-development/script/scripts/webservice/restapi/restapi';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { colors } from '@src/css_config/colorConstants.js';
import InfoIcon from '@pages/test-development/script/scripts/webservice/restapi/request/request_parameter/images/info-gray-24dp.svg';
import InfoIconHover from '@pages/test-development/script/scripts/webservice/restapi/request/request_parameter/images/info_black_24dp.svg';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';
import CustomCheckBox from '@pages/test-development/script/scripts/webservice/CustomCheckBox';
import { WEBSERVICE } from '../../../constants/constants';
import { isEmptyObject } from '@src/util/common_utils.js';

const DataRequestParamGrid = (props) => {
  const { DISABLE_REQUEST_HEADER_INDEXES, DEFAULT_REQUEST_HEADERS, DEFAULT_ARRAY_LENGTH } = WEBSERVICE;
  const context = useContext(RestContext);
  const [projectTypeList, setProjectTypeList] = useState([...(context.values?.requestHeaders || [])]);
  const [isSelectAll, setSelectAll] = useState(false);
  const [headerKey, setHeaderKey] = useState([]);
  const [headerValue, setHeaderValue] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDataValue, setFilteredDataValue] = useState([]);
  const [rowIndex, setRowIndex] = useState();
  const [projectTypeLength, setProjectTypeLength] = useState(0);
  const [onMouseHover, setonMouseHover] = useState(false);
  const [onMouseHoverRow, setonMouseHoverRow] = useState(-1);
  const isIndeterminate = !isSelectAll && projectTypeList.some((rows) => rows?.isEnabled === true);

  useEffect(() => {
    const modifiedProjectTypeList = projectTypeList.filter((item) => item.name && item.value);
    const modifiedProjectTypeListLength = modifiedProjectTypeList.length;
    setProjectTypeLength(modifiedProjectTypeListLength);
  }, [projectTypeList]);

  useEffect(() => {
    if (projectTypeList.length < DEFAULT_ARRAY_LENGTH) {
      projectTypeList.push({
        name: '',
        value: '',
        id: 7,
        idx: 7,
        isEnabled: false,
        isRowshowInputKey: true,
        isKeySearchEnable: false,
        isValueSearchEnable: false,
        isRowshowInputValue: true,
        canRemove: true,
      });
      setProjectTypeList([...projectTypeList]);
      context.values.requestHeaders = projectTypeList;
      getAllHeaderKeyValue('a');
      getAllHeaderValue('a');
    }
  }, []);

  useEffect(() => {
    getStepsUpdate();
  }, [props.selectedStepData]);

  useEffect(() => {
    let valueofSes = sessionStorage.getItem('arrayofContenttype')?.toLowerCase();
    if (valueofSes) {
      const lastBookIndex = projectTypeList.findIndex((row) => row.name === 'Content-Type');
      const modifiedProjectTypeList = [...context.values.requestHeaders]
      if (lastBookIndex !== -1)
        modifiedProjectTypeList[lastBookIndex] = {
          id: 6,
          idx: 6,
          name: 'Content-Type',
          value: `${valueofSes}`,
          canRemove: false,
          isRowshowInputKey: false,
          isRowshowInputValue: false,
          isEnabled: true,
          toolTip:
            "The Content-Type header is added to help the server to identify the media type of the request body which is selected by the user in 'Content-Type' dropdown in this request. Use the request body tab to control the data or to remove this header.",
        };
      setProjectTypeList([...modifiedProjectTypeList]);
      context.setFieldValue('requestHeaders', modifiedProjectTypeList);
    }
  }, [sessionStorage.getItem('arrayofContenttype')]);

  const getAllHeaderKeyValue = (rowName) => {
    getAllHeaderKey(rowName)
      .then((response) => {
        let headerKey = response.data.success;
        setHeaderKey(headerKey);
      })
      .catch((error) => { });
  };

  const getAllHeaderValue = (rowValue) => {
    getAllHeaderValueApi(rowValue)
      .then((response) => {
        let headerValue = response.data.success;
        setHeaderValue(headerValue);
      })
      .catch((error) => { });
  };

  const handleClick = (item) => {
    let sampleNameValue = document.getElementById(`rowInputFieldSearchName + ${rowIndex}`);
    sampleNameValue.value = item;
    sampleNameValue.name = item;
    projectTypeList[rowIndex].name = item;
    projectTypeList[rowIndex].isKeySearchEnable = false;
    setProjectTypeList([...projectTypeList]);
    context.setFieldValue('requestHeaders', projectTypeList);
    setFilteredData([]);
  };

  const handleClick1 = (item) => {
    let sampleValue = document.getElementById(`rowInputFieldSearchValue + ${rowIndex}`);
    sampleValue.value = item;
    sampleValue.name = item;
    projectTypeList[rowIndex].value = item;
    projectTypeList[rowIndex].isValueSearchEnable = false;
    setProjectTypeList([...projectTypeList]);
    context.setFieldValue('requestHeaders', projectTypeList);
    setFilteredDataValue([]);
  };

  const getStepsUpdate = () => {
    if (!isEmptyObject(props.selectedStepData)) {
      const tempParmvalue = props.selectedStepData;
      if (tempParmvalue.headers?.length > 0) {
        const updateProjectList = tempParmvalue.headers.map((row, index) =>
          DISABLE_REQUEST_HEADER_INDEXES.includes(index) ? { ...row, isEnabled: true } : { ...row }
        );
        const selectAll = updateProjectList.every((row) => row.isEnabled);
        setSelectAll(selectAll);
        setProjectTypeList(updateProjectList);
        context.values.requestHeaders = updateProjectList;
      } else {
        context.values.requestHeaders = DEFAULT_REQUEST_HEADERS;
        setProjectTypeList(DEFAULT_REQUEST_HEADERS)
      }
    }
  };

  const handleClickOnCrossIcon = (row, index) => {
    if (projectTypeList.length > 8 && !row.isEnabled) {
      let projectTypeList1 = projectTypeList.filter((item, idx1) => idx1 !== index);
      const selectAll = projectTypeList1.every((row) => row.isEnabled);
      setSelectAll(selectAll);
      setProjectTypeList([...projectTypeList1]);
      context.setFieldValue('requestHeaders', projectTypeList1);
    } else if (index === 7 && !row.isEnabled) {
      row.isValueSearchEnable = false;
      row.isKeySearchEnable = false;
      row.name = '';
      row.value = '';
      setProjectTypeList([...projectTypeList]);
      context.setFieldValue('requestHeaders', projectTypeList);
    }
  };

  const handleAddRequestHeader = (row) => {
    let idxpFisrt = projectTypeList.length;
    if (row.name && row.value) {
      projectTypeList.push({
        name: '',
        value: '',
        id: idxpFisrt,
        idx: idxpFisrt,
        isEnabled: true,
        toolTip: '',
        isRowshowInputKey: true,
        isRowshowInputValue: true,
      });
      row.isValueSearchEnable = false;
      row.isKeySearchEnable = false;
      setProjectTypeList([...projectTypeList]);
      context.setFieldValue('requestHeaders', projectTypeList);
    }
  };

  const onMouseEnter = (i) => {
    setonMouseHover(i);
  };

  const onMouseLeave = (i) => {
    setonMouseHover(false);
  };

  const handleMouseEnter = (i) => {
    setonMouseHoverRow(i);
  };

  const handleMouseLeave = () => {
    setonMouseHoverRow(-1);
  };

  return (
    <div className="h-96">
      <div className="cont_div">
        <table>
          <thead className="fontPoppinsSemiboldSm">
            <tr className="text-left table-row-bg query-param-table-head flex pt-1.5">
              <th className="pl-4">
                <CustomCheckBox
                  checked={isSelectAll}
                  onChange={(e) => {
                    projectTypeList.map((row, index) => {
                      if (DISABLE_REQUEST_HEADER_INDEXES.includes(index)) {
                        row.isEnabled = true;
                      } else {
                        row.isEnabled = e.target.checked;
                      }
                    });
                    setSelectAll(e.target.checked);
                    setProjectTypeList([...projectTypeList]);
                    context.setFieldValue('requestHeaders', projectTypeList);
                    props.handleSaveAsSteps(false);
                  }}
                  className="request-parameter-checkbox"
                  indeterminate={isIndeterminate}
                />
              </th>
              <th className="w-2/5 pl-2 pt-0.5" onDragStart={props.handleDragStart}>
                Key
              </th>
              <th className="w-2/5 pl-2 pt-0.5" onDragStart={props.handleDragStart}>
                Value
              </th>
              <th className="w-1/5 pt-0.5" onDragStart={props.handleDragStart}>
                <span className="flex justify-start">Action</span>
              </th>
              <th className="w-1/5 pt-0.5" onDragStart={props.handleDragStart}>
                <span>|</span>
                <span className="ml-3">Total:{projectTypeLength}</span>
              </th>
            </tr>
          </thead>
          <tbody className="query-param-scroll" onDragStart={props.handleDragStart}>
            {projectTypeList.map((row, index) => (
              <tr
                key={`${index}`}
                id={`${index}`}
                className={`flex request-header-table-row-height ${onMouseHoverRow === index ? 'common-input-mouse-over' : 'common-input-mouse-out'
                  } `}
                onMouseOver={() => handleMouseEnter(index)}
                onMouseOut={handleMouseLeave}
                onMouseLeave={handleMouseLeave}
              >
                <td className="pl-4 mt-1 mb-1 flex items-center">
                  <CustomCheckBox
                    checked={row.isEnabled}
                    disabled={DISABLE_REQUEST_HEADER_INDEXES.includes(index)}
                    onChange={(e) => {
                      row.isEnabled = e.target.checked;
                      const selectAll = projectTypeList.every((row) => row.isEnabled);
                      setProjectTypeList([...projectTypeList]);
                      context.setFieldValue('requestHeaders', projectTypeList);
                      setSelectAll(selectAll);
                      context.handleChange(e);
                      props.handleSaveAsSteps(false);
                    }}
                    className={`${DISABLE_REQUEST_HEADER_INDEXES.includes(index) && 'disable-checkbox-bg-color'
                      } request-parameter-checkbox`}
                  />
                </td>
                <td className="pl-2 w-2/5 mt-1 mb-1">
                  {
                    <span className={`${index >= 0 && index <= 6 && 'flex'} relative`}>
                      <MyInput
                        id={`rowInputFieldSearchName + ${index}`}
                        autocomplete="off"
                        className={`common-input-field input`}
                        type="text"
                        placeholder="Enter Request Header Key or use $"
                        onClick={() => {
                          setRowIndex(index);
                          row.isValueSearchEnable = false;
                        }}
                        onChange={(e) => {
                          const name = props.getUserInputValue(e.target.value, false, e.target?.data, e.target?.action);
                          row.name = name;
                          const searchWord = e.target.value;
                          const newFilter = headerKey.filter((value) => {
                            return value.toLowerCase().includes(searchWord.toLowerCase());
                          });

                          if (searchWord === '') {
                            setFilteredData([]);
                            row.isKeySearchEnable = false;
                          } else {
                            setFilteredData(newFilter);
                            row.isKeySearchEnable = true;
                          }
                          setProjectTypeList([...projectTypeList]);
                          context.setFieldValue('requestHeaders', projectTypeList);
                          context.handleChange(e);
                          context.setFieldValue('currentRequestKey', e);
                          props.handleSaveAsSteps(false);
                        }}
                        value={props.getUserInputValue(row.name, true)}
                        onMouseDown={() => {
                          row.showInput = false;
                          setProjectTypeList([...projectTypeList]);
                          context.setFieldValue('requestHeaders', projectTypeList);
                        }}
                        variableInput={true}
                        inputValue={'input'}
                        currentValue={context.values.currentRequestKey}
                        disabled={index >= 0 && index <= 6}
                        index={index}
                        requestHeaderProps={true}
                        style={{
                          color: DISABLE_REQUEST_HEADER_INDEXES.includes(index) ? colors.silver : colors.black_border,
                        }}
                        handleMouseLeave={handleMouseLeave}
                        {...props}
                      />
                      <div className="flex flex-row float-left w-4">
                        {DEFAULT_REQUEST_HEADERS[index] && DEFAULT_REQUEST_HEADERS[index]['toolTip'] ? (
                          <CustomTooltip
                            title={`${DEFAULT_REQUEST_HEADERS[index]['toolTip']}`}
                            placement="right"
                            infoIcon="infoIcon"
                          >
                            <img
                              className="ml-1 infoIcon w-4 h-4 mt-2 object-contain"
                              src={onMouseHover === index ? InfoIconHover : InfoIcon}
                              alt="showMore"
                              width="15"
                              draggable="false"
                              onMouseEnter={() => onMouseEnter(index)}
                              onMouseLeave={() => onMouseLeave(index)}
                            />
                          </CustomTooltip>
                        ) : null}
                      </div>
                    </span>
                  }
                  {rowIndex === index && row.isKeySearchEnable === true && filteredData.length !== 0 ? (
                    <div className="relative">
                      <div className="absolute top-0 box_value overflow-auto cursor z-10">
                        <ul>
                          {filteredData.slice(0, 15).map((item) => (
                            <li className="cursor h-6 w-70 ml-2" key={item} onClick={() => handleClick(item)}>
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </td>
                <td className="w-2/5 mt-1 mb-1">
                  {
                    <span className="relative">
                      <MyInput
                        type="text"
                        id={`rowInputFieldSearchValue + ${index}`}
                        placeholder="Enter Request Header Value or use $"
                        className={`common-input-field input`}
                        autocomplete="off"
                        onClick={() => {
                          setRowIndex(index);
                          row.isKeySearchEnable = false;
                        }}
                        onChange={(e) => {
                          const value = props.getUserInputValue(
                            e.target.value,
                            false,
                            e.target?.data,
                            e.target?.action
                          );
                          row.value = value;
                          const searchWord = e.target.value;
                          const newFilter = headerValue.filter((value) => {
                            return value.toLowerCase().includes(searchWord.toLowerCase());
                          });
                          if (searchWord === '') {
                            setFilteredDataValue([]);
                            row.isValueSearchEnable = false;
                          } else {
                            setFilteredDataValue(newFilter);
                            row.isValueSearchEnable = true;
                          }
                          setProjectTypeList([...projectTypeList]);
                          context.setFieldValue('requestHeaders', projectTypeList);
                          context.handleChange(e);
                          context.setFieldValue('currentRequestValue', e);
                          props.handleSaveAsSteps(false);
                        }}
                        value={props.getUserInputValue(row.value, true)}
                        variableInput={true}
                        inputValue={'input'}
                        currentValue={context.values.currentRequestValue}
                        disabled={index >= 0 && index <= 6}
                        index={index}
                        requestHeaderProps={true}
                        style={{
                          color: DISABLE_REQUEST_HEADER_INDEXES.includes(index) ? colors.silver : colors.black_border,
                        }}
                        handleMouseLeave={handleMouseLeave}
                        {...props}
                      />
                    </span>
                  }
                  {rowIndex === index && row.isValueSearchEnable === true && filteredDataValue.length !== 0 ? (
                    <div className="relative">
                      {' '}
                      <div className="absolute top-0 box_value overflow-auto cursor z-10">
                        <ul>
                          {filteredDataValue.slice(0, 15).map((item) => (
                            <li className="cursor h-6 w-70 ml-2" key={item} onClick={() => handleClick1(item)}>
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </td>
                <td className="w-1/5 mt-2 mb-1" key={index}>
                  <div className="flex">
                    <span class={projectTypeList.length - 1 === index ? 'ml-0' : 'ml-0 mr-1'}>
                      {index > 6 ? (
                        <>
                          <CustomTooltip title={'Delete'} placement="bottom">
                            <DeleteIcon
                              className={`cursor-pointer ${!row.isEnabled && index > 6 && (row.name || row.value || projectTypeList.length > 8)
                                ? 'opacity-100 text-gray-500 delete-enable-effect'
                                : 'opacity-50 text-gray-400'
                                } `}
                              alt="showmore"
                              width="15px"
                              height="15px"
                              onClick={() => handleClickOnCrossIcon(row, index)}
                              draggable="false"
                            />
                          </CustomTooltip>
                        </>
                      ) : (
                        ''
                      )}
                    </span>
                    {projectTypeList.length - 1 === index && (
                      <span className="ml-4">
                        <CustomTooltip title="Add">
                          <AddIcon
                            className={`${index > 6 && row.name && row.value
                              ? 'opacity-100 plus-enable-effect'
                              : 'plus-disable-effect'
                              }   -ml-1 cursor-pointer`}
                            onClick={() => handleAddRequestHeader(row)}
                          />
                        </CustomTooltip>
                      </span>
                    )}
                  </div>
                </td>
                <td className="w-1/5 mt-1 mb-1"></td>
              </tr>
            ))}
            <div className="h-4"></div>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DataRequestParamGrid;

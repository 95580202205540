import { forgotPasswordRequest, resetActivationLink } from '@api/api_services';
import { ReactComponent as CircleIcon } from '@assets/circle-outline-icon.svg';
import ClockImg from '@assets/clock-icon.svg';
import { ReactComponent as InfoIcon } from '@assets/info-icon.svg';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import commonStyles from '@pages/auth/common.module.scss';
import '@pages/auth/login.css';
import { GLOBAL_CONSTANTS, PASSWORD_CONSTANTS } from '@src/common/ui-constants';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import CommonPage from '../common/CommonPage';
import { isEmptyValue } from '@src/util/common_utils';

const alertStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '0px',
    backgroundColor: 'unset',
  },
  activationAlert: {
    '& .MuiAlert-icon': {
      marginTop: 5,
    },
    '&.MuiAlert-standardSuccess': {
      backgroundColor: 'transparent',
      marginLeft: '70px',
    },
  },
}));

export default function TokenExpired(props) {
  const [email, setEmail] = useState('');
  const [pageType, setPageType] = useState(null);
  const [success, setSuccess] = useState({ open: false, message: '' });
  const [createUpdateCalled, setCreateUpdateCalled] = useState(true);
  const [showActiveUserText, setActiveUserText] = useState({
    open: false,
    message: '',
  });
  const [activationStatus, setActivationStatus] = useState(false);
  let history = useHistory();
  const alertClasses = alertStyles();

  function resendActivationLink() {
    if (createUpdateCalled) {
      setCreateUpdateCalled(false);
      const data = {
        emailId: email,
      };
      resetActivationLink(data)
        .then((res) => {
          if (res.data) {
            verifyIsUserActive(res.data);
          }
          setTimeout(() => {
            setSuccess({ open: false });
          }, 5000);
        })
        .catch((error) => {
          console.error('resend activation link error', error);
        });
    }
  }

  function redirectToSignIn() {
    history.push('/signin');
  }

  function verifyIsUserActive(response) {
    if (response.responseCode === 400 && response.message === GLOBAL_CONSTANTS.USER_ACTIVATED_MESSAGE) {
      setActiveUserText({ open: true, message: response.message });
    } else if (response.responseObject) {
      setTimeout(() => {
        setCreateUpdateCalled(true);
      }, 10000);
      setSuccess({ open: true });
    }
  }
  useEffect(() => {
    const search = window.location.search;
    const emailId = new URLSearchParams(search).get('emailId');
    const page = new URLSearchParams(search).get('page');
    const activationStatus = new URLSearchParams(search).get('activationStatus');
    setActivationStatus(activationStatus);
    if (activationStatus === 'ACTIVE' && isEmptyValue(page)) {
      setActiveUserText({ open: true, message: GLOBAL_CONSTANTS.USER_ACTIVATED_MESSAGE });
    }
    setEmail(emailId);
    setPageType(page);
  }, []);

  const resendResetPasswordLink = () => {
    const data = {
      emailId: email,
    };
    forgotPasswordRequest(data)
      .then((res) => {
        const response = res.data.responseCode;
        if (response === 200) {
          history.push('/email-send');
        } else if (res.data && res.data.message === 'User is not activated') {
          history.push('/user-activation?email=' + email);
        }
      })
      .catch((error) => {
        console.error('Forgot Password Link API Failed', error);
      });
  };

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        history.go(1);
      }
    };
  }, [history]);

  if (pageType === 'change-password' && activationStatus === 'ACTIVE') {
    return (
      <CommonPage marginTop={'mt-36'} wrapperClassname={`common-padding`}>
        <div className="">
          {success.open && (
            <Alert
              className={alertClasses.root}
              iconMapping={{
                success: <CircleIcon fontSize="inherit" className="alertIconRegSuc" />,
              }}
            >
              <span className="fontPoppinsRegularLg">{PASSWORD_CONSTANTS.SET_PASSWORD_LINK_SENT_SUCCESSFULLY}</span>
            </Alert>
          )}
          {showActiveUserText.open && (
            <Alert
              className={alertClasses.root}
              iconMapping={{
                success: <CircleIcon fontSize="inherit" className="alertIconRegSuc" />,
              }}
            >
              <span className="activationSuccess  fontPoppinsRegularLg">{showActiveUserText.message}</span>
            </Alert>
          )}
          <img src={ClockImg} alt="clock" className="m-auto " />
          <div className="flex flex-col justify-center items-center gap-4 mt-4">
            <div className={cx('text-center fontPoppinsMediumLg ', commonStyles['expired-text'])}>
              {PASSWORD_CONSTANTS.SET_PASSWORD_LINK_EXPIRED}
            </div>
            <div className="text-center fontPoppinsRegularMd letter-spacing-29">
              {PASSWORD_CONSTANTS.CHECK_MAIL_FOR_LATEST_LINK}
            </div>
            <button
              onClick={resendResetPasswordLink}
              className={cx(
                'cursor-pointer fontPoppinsSemiboldLg',
                commonStyles['btn-bg-orange'],
                commonStyles['common-btn']
              )}
            >
              &nbsp;{PASSWORD_CONSTANTS.RESEND_PASSWORD_LINK}
            </button>
          </div>
        </div>
      </CommonPage>
    );
  }

  return (
    <CommonPage wrapperClassname={`common-padding`}>
      <div className="items-center">
        {success.open && (
          <Alert
            className={alertClasses.root}
            iconMapping={{
              success: <CircleIcon fontSize="inherit" className="alertIconRegSuc -ml-8" />,
            }}
          >
            <span className="activationSuccess  fontPoppinsRegularLg">
              {GLOBAL_CONSTANTS.ACTIVATION_LINK_SENT_SUCCESSFULLY}
            </span>
          </Alert>
        )}
        {!showActiveUserText.open && (
          <>
            <img
              src={ClockImg}
              alt="clock"
              width="96"
              height="90"
              className={cx('m-auto', showActiveUserText.open && 'margin-top-88', success.open && 'margin-top-92')}
            />

            <div className="text-center token-expired-text fontPoppinsMediumLg mb-4">
              {GLOBAL_CONSTANTS.ACTIVATION_LINK_EXPIRED}
            </div>
          </>
        )}
        {showActiveUserText.open ? (
          <div>
            <Alert
              className={alertClasses.root}
              iconMapping={{
                success: <CircleIcon fontSize="inherit" className="alertIconRegSuc" />,
              }}
            >
              <span className={cx('text-center', commonStyles['text-green'], 'fontPoppinsSemibold-size-26')}>
                {console.log('showActiveUserText.message', showActiveUserText.message)}
                {showActiveUserText.message}
              </span>
            </Alert>
            <img src={ClockImg} alt="clock" width="96" height="90" className={cx('m-auto')} />

            <div className="text-center token-expired-text fontPoppinsMediumLg mb-4">
              {GLOBAL_CONSTANTS.ACTIVATION_LINK_EXPIRED}
            </div>
            <Alert
              className="fontPoppinsRegularMd"
              iconMapping={{
                success: <InfoIcon fontSize="inherit" className="mt-1" />,
              }}
            >
              {GLOBAL_CONSTANTS.ACTIVATED_MESSAGE}
            </Alert>
            <div className="mt-4 flex items-center justify-center">
              <button
                className={cx('cursor-pointer', commonStyles['btn-bg-orange'], commonStyles['common-btn'])}
                onClick={redirectToSignIn}
              >
                <span className="fontPoppinsSemiboldLg">&nbsp;{GLOBAL_CONSTANTS.PROCEED_TO_SIGN_IN}</span>
              </button>
            </div>
          </div>
        ) : (
          <div className="fontPoppinsRegularLg pl-0.5 pr-0.5 text-center">
            {success.open
              ? `Please check your mail box for a latest link or click on`
              : `This message serves as a notification to inform the user that the previously provided activation link is no longer valid due to its expiration. 
            To proceed, the user may need to request a new activation link by clicking on "Resend Activation Link" button.`}

            <div className="flex items-center justify-center mt-4">
              <button
                onClick={resendActivationLink}
                className={cx(
                  'cursor-pointer fontPoppinsSemiboldLg',
                  commonStyles['btn-bg-orange'],
                  commonStyles['common-btn']
                )}
              >
                &nbsp;{GLOBAL_CONSTANTS.RESEND_ACTIVATION_LINK}
              </button>
            </div>
          </div>
        )}
      </div>
    </CommonPage>
  );
}

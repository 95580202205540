import React from 'react';
import moment from 'moment';

export function DateColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id } }) {
  const [min, max] = React.useMemo(() => {
    let firstDate = new Date(preFilteredRows[0]?.values[id]);
    let min = new Date(0);
    let max = new Date(0);
    if (moment(firstDate).isValid()) {
      min = firstDate;
      max = moment(firstDate, 'MM-DD-YYYY').add(1, 'days');
    }

    preFilteredRows.forEach((row) => {
      const rowDate = new Date(row.values[id]);
      if (moment(rowDate).isValid()) {
        min = (rowDate <= min && rowDate) || min;
        max = (rowDate >= max && moment(rowDate, 'MM-DD-YYYY').add(1, 'days')) || max;
      }
    });

    return [min, max];
  }, [id, preFilteredRows]);

  let inputStyle = {
    padding: '0px 0px 2px 4px',
    fontSize: '12px',
    boxShadow: '0px 0px 0px 1px',
    borderColor: 'hsl(0,0%,80%)',
    backgroundColor: 'hsl(0,0%,100%)',
    borderSyle: 'solid',
    borderRadius: '2px',
    borderWidth: '0px',
    marginTop: '5px',
    height: '25px',
    opacity: '0.5',
    backgroundcolor: '#F0F3F9',
    color: '#727171',
  };

  return (
    <div
      style={{
        // display: "flex",
        height: '25px',
        // justifyContent: "space-between",
        fontSize: '12px',
        textAlign: 'left',
      }}
    >
      <input
        value={filterValue[0] || ''}
        type="date"
        min={min.toISOString().slice(0, 10)}
        max={max.toISOString().slice(0, 10)}
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [val ? val : undefined, old[1]]);
        }}
        style={inputStyle}
      />
      {' : '}
      <input
        value={filterValue[1]?.slice(0, 10) || ''}
        type="date"
        max={max.toISOString().slice(0, 10)}
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [old[0], val ? val : undefined]);
        }}
        style={inputStyle}
      />
    </div>
  );
}

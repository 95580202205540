import React, { useState, useEffect } from 'react';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CheckIcon from '@mui/icons-material/Check';
import CreateVariable from '@src/pages/test-development/shared/variables/create-edit-variables';
import Modal from '@material-ui/core/Modal';
import CommonButton from '@src/pages/common/button/CommonButton';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Tooltip } from '@material-ui/core';
import Info from '../../script/scripts/webservice/restapi/request/request_parameter/images/info-gray-24dp.svg';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@src/css_config/colorConstants.js';
import ArrowDown from '../../script/scripts/webservice/restapi/images/arrow_down.svg';
import ArrowRight from '../../script/scripts/webservice/restapi/images/arrow_right.svg';
import ShowHide from '../../script/scripts/webservice/restapi/images/show-hides.svg';
import ShowHideWhite from '../../script/scripts/webservice/restapi/images/show-hides-white.svg';
import BlackArrowRight from '../../script/scripts/webservice/restapi/images/arrow_right_black.svg';
import ShowExpandHover from '../../script/scripts/webservice/restapi/images/show-hide-black.svg';
import {
  updateLocalVariableReq,
  updateGloabalVariable,
  updateStepGroupVariable,
  updateProjectEnvironmentVairable,
  getAllGlobalVariableListReq,
  getAllLocalVariablesReq,
  getAllProjectVariableListReq,
} from '@api/api_services';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';
import Checkbox from '@mui/material/Checkbox';
import { TEST_DEV } from '@src/common/ui-constants';
import { WEBSERVICE } from '../../script/scripts/webservice/restapi/constants/constants';

const useStyles = makeStyles({
  customModal: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    border: 'none',
  },
  checkbox: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-checked': {
      color: colors.rs_primary,
    },
  },
});

const ViewVariable = (props) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [localAccordionOpen, setLocalAccordionOpen] = useState(false);
  const [globalAccordionOpen, setGlobalAccordionOpen] = useState(false);
  const [projectAccordionOpen, setProjectAccordionOpen] = useState(false);
  const [localRowIdx, setLocalRowIdx] = useState(-1);
  const [globalRowIdx, setGlobalRowIdx] = useState(-1);
  const [projectRowIdx, setProjectRowIdx] = useState(-1);
  const [isVariableModalOpen, setIsVariableModalOpen] = useState(false);
  const [tempVariableName, setTempVariableName] = useState([]);
  const [tempVariableValue, setTempVariableValue] = useState([]);
  const [tempVariableId, setTempVariableId] = useState([]);
  const [tempIsHide, setTempIsHide] = useState(false);
  const [updatedField, setUpdatedField] = useState({});
  const [localVariable, setLocalVariable] = useState([]);
  const [globalVariable, setGlobalVariable] = useState([]);
  const [projectVariable, setProjectVariable] = useState([]);
  const [isLocalHoveredIcon, setIsLocalHoveredIcon] = useState(false);
  const [isGlobalHoveredIcon, setIsGlobalHoveredIcon] = useState(false);
  const [isProjectEnvHoveredIcon, setIsProjectEnvHoveredIcon] = useState(false);
  const [variableErrors, setIsVariableErrors] = useState({});
  const [createCalled, setCreateCalled] = useState(false);
  const classes = useStyles();
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('scriptId');
  const selectedProjectDetails = JSON.parse(localStorage.getItem('selected-project'));
  const selectedProjectStatus = selectedProjectDetails?.status;
  const { isViewVariableClick, setViewVariableClick, MyAlert } = props;
  const { LOCAL_VAR_LABEL, GLOBAL_VAR_LABEL, PROJECT_ENV_VAR_LABEL } = WEBSERVICE;

  useEffect(() => {
    if (isViewVariableClick) {
      handleViewVariable(true);
      setViewVariableClick(false);
    }
  }, [isViewVariableClick]);

  useEffect(() => {
    getAllLocalVariables();
    getAllGlobalVariables();
    getProjectEnvironmentVariable();
  }, [isViewVariableClick]);
  const getAllLocalVariables = () => {
    getAllLocalVariablesReq(moduleId, scriptId).then((response) => {
      const localVariableResponse = response.data.responseObject;
      if (localVariableResponse && localVariableResponse.length > 0) {
        setLocalVariable(localVariableResponse);
      }
    });
  };
  const getAllGlobalVariables = () => {
    getAllGlobalVariableListReq().then((response) => {
      const globalVariableResponse = response.data.responseObject;
      if (globalVariableResponse && globalVariableResponse.length > 0) {
        setGlobalVariable(globalVariableResponse);
      }
    });
  };
  const getProjectEnvironmentVariable = () => {
    getAllProjectVariableListReq().then((response) => {
      const projectEnvResponse = response.data.responseObject;
      if (projectEnvResponse && projectEnvResponse.length > 0) {
        setProjectVariable(projectEnvResponse);
      }
    });
  };
  const onSetAllVariableName = (data, type, action) => {
    const variableData = { data, type, action };
    props?.onSetRecentVariableName(variableData);
    if (type === 'LOCAL') {
      if (!action) {
        const updateLocalVariable = [...localVariable, data];
        setLocalVariable(updateLocalVariable);
      }
    } else if (type === 'GLOBAL') {
      if (!action) {
        const updateGLobalVariable = [...globalVariable, data];
        setGlobalVariable(updateGLobalVariable);
      }
    } else {
      if (!action) {
        const updateProjEnvironmentVariable = [...projectVariable, data];
        setProjectVariable(updateProjEnvironmentVariable);
      }
    }
  };
  const handleViewVariable = (status) => {
    setIsVariableModalOpen(status);
    setIsFormOpen(false);
    if (!status) {
      setLocalRowIdx(-1);
      setGlobalRowIdx(-1);
      setProjectRowIdx(-1);
      setLocalAccordionOpen(false);
      setGlobalAccordionOpen(false);
      setProjectAccordionOpen(false);
    }
  };

  const handleCreateVariable = (status) => {
    setIsFormOpen(status);
  };
  const handleMouseEnter = (e, field) => {
    e.target.style.backgroundColor = `${colors.text_white}`;
    e.target.style.borderRadius = '50px';
    e.target.src = field ? ShowExpandHover : BlackArrowRight;
  };
  const handleMouseLeave = (e, field) => {
    e.target.style.backgroundColor = 'initial';
    e.target.src = field ? ArrowDown : ArrowRight;
  };

  const onVariableMouseOver = (name, status) => {
    if (name === 'isLocalHoveredIcon') {
      setIsLocalHoveredIcon(status);
    } else if (name === 'isGlobalHoveredIcon') {
      setIsGlobalHoveredIcon(status);
    } else if (name === 'isProjectEnvHoveredIcon') {
      setIsProjectEnvHoveredIcon(status);
    }
  };

  const onVariableMouseOut = (name, status) => {
    if (name === 'isLocalHoveredIcon') {
      setIsLocalHoveredIcon(status);
    } else if (name === 'isGlobalHoveredIcon') {
      setIsGlobalHoveredIcon(status);
    } else if (name === 'isProjectEnvHoveredIcon') {
      setIsProjectEnvHoveredIcon(status);
    }
  };

  const getVariableImageSource = (isArrowIconHover, isArrowIconOpen) => {
    return isArrowIconHover
      ? isArrowIconOpen
        ? ShowHide
        : BlackArrowRight
      : isArrowIconOpen
      ? ShowHideWhite
      : ArrowRight;
  };

  const handleInputChange = (e, index, field, variableType) => {
    let data = [...variableType];
    if (field === 'isHide') {
      data[index][field] = e.target.checked;
    } else {
      data[index][field] = e.target.value;
      if (field === 'name') {
        data[index].showErrorMessage = getErrorMessage(data[index][field], data, index);
      }
    }
    data[index].isChanged = true;
    setUpdatedField(data[index]);
    if (variableType === localVariable) {
      setLocalVariable(data);
    } else if (variableType === globalVariable) {
      setGlobalVariable(data);
    } else if (variableType === projectVariable) {
      setProjectVariable(data);
    }
  };
  const handleEditMode = (index, field, key, variableType) => {
    const finalVar = variableType;
    let tempIndex = variableType.findIndex((val) => val.id === tempVariableId);
    finalVar[tempIndex] = {
      ...finalVar[tempIndex],
      name: tempVariableName,
      value: tempVariableValue,
      isHide: tempIsHide,
    };

    setTempVariableName(JSON.parse(JSON.stringify(key.name)));
    setTempVariableValue(JSON.parse(JSON.stringify(key.value)));
    setTempVariableId(JSON.parse(JSON.stringify(key.id)));
    setTempIsHide(key?.isHide);
    if (field === 'localRowIdx') {
      setLocalRowIdx(index);
    } else if (field === 'globalRowIdx') {
      setGlobalRowIdx(index);
    } else if (field === 'projectRowIdx') {
      setProjectRowIdx(index);
    }
    key = finalVar;
  };

  const handleCancelIcon = (index, field, variableType) => {
    const finalVar = variableType;
    finalVar[index] = {
      ...finalVar[index],
      name: tempVariableName,
      value: tempVariableValue,
      isHide: tempIsHide,
      showErrorMessage: '',
    };
    setUpdatedField({});
    if (field === 'localRowIdx') {
      setLocalRowIdx(-1);
    } else if (field === 'globalRowIdx') {
      setGlobalRowIdx(-1);
    } else if (field === 'projectRowIdx') {
      setProjectRowIdx(-1);
    }
    if (variableType === localVariable) {
      setLocalVariable(finalVar);
    } else if (variableType === globalVariable) {
      setGlobalVariable(finalVar);
    } else if (variableType === projectVariable) {
      setProjectVariable(finalVar);
    }
  };

  const handleCheckIcon = (index, field, variableType) => {
    const { id, isHide } = updatedField;
    let data = [...variableType];
    if (!data[index].masked) {
      data[index].masked = isHide;
    }
    updateVariable(id, updatedField);
    setUpdatedField({});
    setTempVariableName([]);
    setTempVariableValue([]);
    setTempVariableId([]);
    if (field === 'localRowIdx') {
      setLocalRowIdx(-1);
    } else if (field === 'globalRowIdx') {
      setGlobalRowIdx(-1);
    } else if (field === 'projectRowIdx') {
      setProjectRowIdx(-1);
    }
    if (variableType === localVariable) {
      setLocalVariable(data);
    } else if (variableType === globalVariable) {
      setGlobalVariable(data);
    } else if (variableType === projectVariable) {
      setProjectVariable(data);
    }
  };

  const getErrorMessage = (value, data, indexParam) => {
    let error = '';
    if (!value) {
      error = TEST_DEV.VALIDATION_VARIABLE_NAME;
    } else if (value?.startsWith(' ') || value.endsWith(' ')) {
      error = TEST_DEV.VALIDATION_VARIABLE_WHITESPACE;
    } else if (/[^a-zA-Z0-9 _-]/.test(value)) {
      error = TEST_DEV.VALIDATION_VARIABLE_ALPHANUMERIC;
    } else if (value.length > 25) {
      error = TEST_DEV.VALIDATION_VARIABLE_MAX_LENGTH;
    } else if (value.length < 3) {
      error = TEST_DEV.VALIDATION_VARIABLE_MIN_LENGTH;
    } else {
      const exists = data.some((variable, index) => index !== indexParam && variable.name === value);
      if (exists) error = TEST_DEV.VALIDATION_EXISTING_VARIABLE_NAME;
    }
    return error;
  };
  const updateVariable = async (id, data) => {
    const type = data.type;
    const action = 'update';
    const variableData = { data, type, action };
    props?.onSetRecentVariableName(variableData);
    let response;
    try {
      switch (type) {
        case 'LOCAL':
          response = await updateLocalVariableReq(id, data);
          break;

        case 'GLOBAL':
          response = await updateGloabalVariable(id, data);
          break;

        case 'Step':
          props.data.name = data.name;
          props.data.value = data.value;
          response = await updateStepGroupVariable(id, props.data);
          break;

        default:
          response = await updateProjectEnvironmentVairable(id, data);
          break;
      }

      if (response?.data?.status === 'SUCCESS') {
        MyAlert.success(response.data.message);
        setIsFormOpen(false);
        if (type === 'LOCAL') {
          setLocalRowIdx(-1);
        } else if (type === 'GLOBAL') {
          setGlobalRowIdx(-1);
        } else if (type === 'PROJECT_ENVIRONMENT') {
          setProjectRowIdx(-1);
        }
      } else if (response.data.status === 'FAILURE') {
        setIsVariableErrors('Name already exists');
        setCreateCalled(false);
        document.getElementById('createVariableID').disabled = false;
      } else {
        MyAlert.warning(`${response.data.status}`);
        setIsFormOpen(false);
      }
    } catch (err) {
      console.error('ERROR OCCURED WHILE UPDATING VARIABLE', err);
    }
  };

  const variableDisableValidate = (variable, option) => {
    if (option === 'class') {
      return !variable?.isChanged || variable?.showErrorMessage;
    } else {
      return variable?.isChanged && !variable?.showErrorMessage;
    }
  };

  const variableNameInput = (rowIdx, variable, handleInputChange, index, variableType) => (
    <input
      type="text"
      name="name"
      className={`view-variable-input ${rowIdx === index ? 'editable' : ''}`}
      readOnly={rowIdx !== index}
      value={rowIdx !== index && variable.name.length > 17 ? variable.name.substr(0, 17) + '...' : variable.name}
      onChange={(e) => handleInputChange(e, index, 'name', variableType)}
    />
  );

  const variableValueInput = (rowIdx, variable, index, variableType) => (
    <input
      type={(variable?.masked || variable?.isHide) && rowIdx === index ? 'password' : 'text'}
      name="value"
      readOnly={rowIdx !== index}
      className={`view-variable-input-value ${rowIdx === index ? 'editable' : ''}`}
      value={
        rowIdx < 0 && variable.value.length <= 0
          ? 'NULL'
          : variable?.masked && rowIdx !== index
          ? '****'
          : rowIdx !== index
          ? variable.value.length > 17
            ? variable.value.substr(0, 17) + '...'
            : variable.value
          : variable.value
      }
      onChange={(e) => handleInputChange(e, index, 'value', variableType)}
    />
  );

  return (
    <Modal
      open={isVariableModalOpen}
      onClose={() => {
        setIsVariableModalOpen(false);
        handleViewVariable(false);
      }}
      className={`${classes.customModal} modal-dialog`}
    >
      <div className="view-variable-modal">
        <div className="modal-header-api">
          <div className="pl-2">
            <h2 className="title white fontPoppinsMediumSm">Variable Details</h2>
            {selectedProjectStatus !== 'Closed' && (
              <CommonButton
                className="add-variable-btn"
                btnType={`${
                  isFormOpen || localRowIdx >= 0 || globalRowIdx >= 0 || projectRowIdx >= 0 ? 'disable' : 'whitelist'
                }`}
                label="Add Variable"
                onClick={() => {
                  localRowIdx >= 0 || globalRowIdx >= 0 || projectRowIdx >= 0
                    ? setIsFormOpen(false)
                    : setIsFormOpen(true);
                }}
              />
            )}
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                handleViewVariable(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="modal-body " id="journal-scroll">
          <div>
            {isFormOpen && (
              <CreateVariable
                MyAlert={MyAlert}
                data={null}
                closeModal={() => {
                  handleCreateVariable(false);
                }}
                variableType={''}
                id={{ moduleId: moduleId, scriptId: scriptId }}
                isCreateForm={true}
                onSetAllVariableName={onSetAllVariableName}
              />
            )}
          </div>
          <div className={isFormOpen ? 'accrodion-body ml-4 mr-4' : 'variable-body ml-4 mr-4 mt-4'}>
            <div
              className={`Request_Div response-expand-container ${
                isFormOpen === true ? '' : ''
              } flex flex-wrap border-2 border-opacity-100 h-35`}
            >
              <label className="ml-5 text-sm flex-auto fontPoppinsSemiboldSm select-none">{LOCAL_VAR_LABEL}</label>
              <div>
                <CustomTooltip responseTooltip={true} title={localAccordionOpen ? 'collapse' : 'expand'}>
                  <img
                    className={
                      localAccordionOpen
                        ? 'variable-option-collapse cursor-pointer'
                        : 'variable-option-expand cursor-pointer'
                    }
                    src={getVariableImageSource(isLocalHoveredIcon, localAccordionOpen)}
                    alt="showmore"
                    onClick={() => {
                      setLocalAccordionOpen((prevState) => !prevState);
                      setLocalRowIdx(-1);
                    }}
                    draggable="false"
                    onMouseEnter={(e) => handleMouseEnter(e, localAccordionOpen)}
                    onMouseLeave={(e) => handleMouseLeave(e, localAccordionOpen)}
                    onMouseOver={() => onVariableMouseOver('isLocalHoveredIcon', true)}
                    onMouseOut={() => onVariableMouseOut('isLocalHoveredIcon', false)}
                  />
                </CustomTooltip>
              </div>
            </div>
            {localAccordionOpen && (
              <table className="w-full variable-table-modal text-sm text-left text-gray-500 dark:text-gray-400">
                <div>
                  <thead className="text-xs text-gray-700 fontPoppinsSemiBold  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr className="h-35">
                      <th scope="col" className=" pl-3 accordion-table-name fontPoppinsSemiboldSm">
                        Name
                      </th>
                      <th scope="col" class=" pl-3 text-left accordion-table-value fontPoppinsSemiboldSm">
                        Value
                      </th>
                    </tr>
                  </thead>
                </div>
                <div className="Local-view-variable relative overflow-x-hidden">
                  <tbody>
                    {localVariable.map((variable, index) => (
                      <>
                        <tr className="relative bg-white dark:bg-gray-800 dark:border-gray-700 h-35">
                          <td
                            className={`text-left text-gray-900 whitespace-nowrap dark:text-white accordion-table-name`}
                          >
                            {variable.name.length > 17 ? (
                              <CustomTooltip responseTooltip={true} title={variable.name}>
                                {variableNameInput(localRowIdx, variable, handleInputChange, index, localVariable)}
                              </CustomTooltip>
                            ) : (
                              variableNameInput(localRowIdx, variable, handleInputChange, index, localVariable)
                            )}
                            {variable.showErrorMessage && localRowIdx === index && (
                              <div className="request-section-errors">
                                {variable.showErrorMessage.length > 30 ? (
                                  <div>
                                    {variable.showErrorMessage.slice(0, 37)}
                                    <br />
                                    {variable.showErrorMessage.slice(37)}
                                  </div>
                                ) : (
                                  variable.showErrorMessage
                                )}
                              </div>
                            )}
                          </td>
                          <td className="text-left accordion-table-value">
                            {!variable?.masked && variable.value.length > 17 ? (
                              <CustomTooltip responseTooltip={true} title={variable.value}>
                                {variableValueInput(localRowIdx, variable, index, localVariable)}
                              </CustomTooltip>
                            ) : (
                              variableValueInput(localRowIdx, variable, index, localVariable)
                            )}
                            {localRowIdx !== index && selectedProjectStatus !== 'Closed' && (
                              <CustomTooltip responseTooltip={true} placement="bottom" title="Edit">
                                <EditRoundedIcon
                                  className={`${
                                    !isFormOpen && globalRowIdx < 0 && localRowIdx < 0 && projectRowIdx < 0
                                      ? 'modal-edit-icon text-gray-500 opacity-100'
                                      : 'modal-edit-disable'
                                  } absolute right-3 top-1.5 cursor-pointer`}
                                  onClick={() => {
                                    if (!isFormOpen && globalRowIdx < 0 && localRowIdx < 0 && projectRowIdx < 0)
                                      handleEditMode(index, 'localRowIdx', variable, localVariable);
                                  }}
                                />
                              </CustomTooltip>
                            )}
                          </td>
                        </tr>
                        <tr className="border-b">
                          <td className="flex relative ml-1 variable-input-value left-52">
                            {localRowIdx === index && (
                              <>
                                <Checkbox
                                  name="variableValueMasked"
                                  id="variableValueMasked"
                                  size="small"
                                  onChange={(e) => handleInputChange(e, index, 'isHide', localVariable)}
                                  disabled={variable?.masked}
                                  defaultChecked={variable?.isHide}
                                  checked={variable?.isHide || variable?.masked}
                                  className={`${variable?.masked ? 'view-variable-masked-checkbox' : ''} ${
                                    (variable?.isHide || variable?.masked) && 'view-variable-checked'
                                  } viewVariablecheckbox cursor pointer ${classes.checkbox}`}
                                />
                                <span className="fontPoppinsRegularSm mt-2.5 mb-2 ml-1"> Hide value</span>
                                <span className="mt-3">
                                  <CustomTooltip
                                    responseTooltip={true}
                                    placement="right"
                                    title="Once the value is hidden, it cannot be undone"
                                  >
                                    <img
                                      src={Info}
                                      alt=""
                                      className="ml-1 w-4 h-4 object-contain"
                                      height="15px"
                                      width="15px"
                                      draggable="false"
                                    />
                                  </CustomTooltip>
                                </span>
                                <span>
                                  <CustomTooltip responseTooltip={true} placement="bottom" title="Cancel">
                                    <CloseIcon
                                      className="absolute right-11 variable-action-icons variable-hover-effect  cursor-pointer mt-2"
                                      onClick={() => handleCancelIcon(index, 'localRowIdx', localVariable)}
                                    />
                                  </CustomTooltip>
                                  <CustomTooltip responseTooltip={true} placement="bottom" title="Update">
                                    <CheckIcon
                                      className={`${
                                        variableDisableValidate(variable, 'class')
                                          ? 'variable-check-disable'
                                          : 'variable-hover-effect '
                                      } absolute mt-2 right-3 cursor-pointer `}
                                      onClick={() => {
                                        variableDisableValidate(variable, null) &&
                                          handleCheckIcon(index, 'localRowIdx', localVariable);
                                      }}
                                    />
                                  </CustomTooltip>
                                </span>
                              </>
                            )}
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </div>
              </table>
            )}
            <div className="Request_Div response-expand-container mt-1 flex flex-wrap border-2 border-opacity-100 h-35">
              <label className="ml-5 text-sm flex-auto fontPoppinsSemiboldSm select-none">{GLOBAL_VAR_LABEL}</label>
              <div>
                <Tooltip title={globalAccordionOpen ? 'collapse' : 'expand'}>
                  <img
                    className={
                      globalAccordionOpen
                        ? 'variable-option-collapse cursor-pointer'
                        : 'variable-option-expand cursor-pointer'
                    }
                    src={getVariableImageSource(isGlobalHoveredIcon, globalAccordionOpen)}
                    alt="showmore"
                    onClick={() => {
                      setGlobalAccordionOpen((prevState) => !prevState);
                      setGlobalRowIdx(-1);
                    }}
                    draggable="false"
                    onMouseEnter={(e) => handleMouseEnter(e, globalAccordionOpen)}
                    onMouseLeave={(e) => handleMouseLeave(e, globalAccordionOpen)}
                    onMouseOver={() => onVariableMouseOver('isGlobalHoveredIcon', true)}
                    onMouseOut={() => onVariableMouseOut('isGlobalHoveredIcon', false)}
                  />
                </Tooltip>
              </div>
            </div>
            {globalAccordionOpen && (
              <table className="w-full variable-table-modal text-sm text-left text-gray-500 dark:text-gray-400">
                <div>
                  <thead className="text-xs text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr className="h-35">
                      <th scope="col" class="pl-3 accordion-table-name fontPoppinsSemiboldSm">
                        Name
                      </th>
                      <th scope="col" class="pl-3  text-left accordion-table-value fontPoppinsSemiboldSm">
                        Value
                      </th>
                    </tr>
                  </thead>
                </div>
                <div className="Local-view-variable relative overflow-x-hidden">
                  <tbody>
                    {globalVariable.map((variable, index) => (
                      <>
                        <tr className="relative bg-white  dark:bg-gray-800 dark:border-gray-700 h-35">
                          <td
                            className={`text-left text-gray-900 whitespace-nowrap dark:text-white accordion-table-name`}
                          >
                            {variable.name.length > 17 ? (
                              <CustomTooltip responseTooltip={true} title={variable.name}>
                                {variableNameInput(globalRowIdx, variable, handleInputChange, index, globalVariable)}
                              </CustomTooltip>
                            ) : (
                              variableNameInput(globalRowIdx, variable, handleInputChange, index, globalVariable)
                            )}
                            {variable.showErrorMessage && globalRowIdx === index && (
                              <div className="request-section-errors">
                                {variable.showErrorMessage.length > 30 ? (
                                  <div>
                                    {variable.showErrorMessage.slice(0, 37)}
                                    <br />
                                    {variable.showErrorMessage.slice(37)}
                                  </div>
                                ) : (
                                  variable.showErrorMessage
                                )}
                              </div>
                            )}
                          </td>
                          <td className="text-left accordion-table-value">
                            {!variable?.masked && variable.value.length > 17 ? (
                              <CustomTooltip responseTooltip={true} title={variable.value}>
                                {variableValueInput(globalRowIdx, variable, index, globalVariable)}
                              </CustomTooltip>
                            ) : (
                              variableValueInput(globalRowIdx, variable, index, globalVariable)
                            )}
                            {globalRowIdx !== index && selectedProjectStatus !== 'Closed' && (
                              <CustomTooltip responseTooltip={true} placement="bottom" title="Edit">
                                <EditRoundedIcon
                                  className={`${
                                    !variable.isSystemVariable &&
                                    !isFormOpen &&
                                    localRowIdx < 0 &&
                                    globalRowIdx < 0 &&
                                    projectRowIdx < 0
                                      ? 'modal-edit-icon'
                                      : 'modal-edit-disable'
                                  } absolute right-3 top-1.5 cursor-pointer`}
                                  onClick={() => {
                                    if (
                                      !variable.isSystemVariable &&
                                      !isFormOpen &&
                                      localRowIdx < 0 &&
                                      globalRowIdx < 0 &&
                                      projectRowIdx < 0
                                    )
                                      handleEditMode(index, 'globalRowIdx', variable, globalVariable);
                                  }}
                                />
                              </CustomTooltip>
                            )}
                          </td>
                        </tr>
                        <tr className="border-b">
                          <td className="flex relative ml-1 variable-input-value left-52">
                            {globalRowIdx === index && (
                              <>
                                <Checkbox
                                  name="variableValueMasked"
                                  id="variableValueMasked"
                                  size="small"
                                  onChange={(e) => handleInputChange(e, index, 'isHide', globalVariable)}
                                  disabled={variable?.masked}
                                  defaultChecked={variable?.isHide}
                                  checked={variable?.isHide || variable?.masked}
                                  className={`${variable?.masked ? 'view-variable-masked-checkbox' : ''} ${
                                    (variable?.isHide || variable?.masked) && 'view-variable-checked'
                                  } viewVariablecheckbox cursor pointer ${classes.checkbox}`}
                                />
                                <span className="fontPoppinsRegularSm mt-2.5 mb-2 ml-1"> Hide value</span>
                                <span className="mt-3">
                                  <CustomTooltip
                                    responseTooltip={true}
                                    placement="right"
                                    title="Once the value is hidden, it cannot be undone"
                                  >
                                    <img
                                      src={Info}
                                      alt=""
                                      className="ml-1 w-4 h-4 object-contain"
                                      height="15px"
                                      width="15px"
                                      draggable="false"
                                    />
                                  </CustomTooltip>
                                </span>

                                <span>
                                  <CustomTooltip responseTooltip={true} placement="bottom" title="Cancel">
                                    <CloseIcon
                                      className="absolute  right-11 variable-action-icons variable-hover-effect cursor-pointer mt-2 "
                                      onClick={() => handleCancelIcon(index, 'globalRowIdx', globalVariable)}
                                    />
                                  </CustomTooltip>
                                  <CustomTooltip responseTooltip={true} placement="bottom" title="Update">
                                    <CheckIcon
                                      className={`${
                                        variableDisableValidate(variable, 'class')
                                          ? 'variable-check-disable'
                                          : 'variable-hover-effect '
                                      } absolute mt-2 right-3  cursor-pointer`}
                                      onClick={() => {
                                        variableDisableValidate(variable, null) &&
                                          handleCheckIcon(index, 'globalRowIdx', globalVariable);
                                      }}
                                    />
                                  </CustomTooltip>
                                </span>
                              </>
                            )}
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </div>
              </table>
            )}
            <div className="Request_Div response-expand-container mt-1 flex flex-wrap border-2 border-opacity-100 h-35">
              <label className="ml-5 text-sm flex-auto fontPoppinsSemiboldSm select-none">
                {PROJECT_ENV_VAR_LABEL}
              </label>
              <div>
                <CustomTooltip responseTooltip={true} title={projectAccordionOpen ? 'collapse' : 'expand'}>
                  <img
                    className={
                      projectAccordionOpen
                        ? 'variable-option-collapse cursor-pointer'
                        : 'variable-option-expand cursor-pointer'
                    }
                    src={getVariableImageSource(isProjectEnvHoveredIcon, projectAccordionOpen)}
                    alt="showmore"
                    onClick={() => {
                      setProjectAccordionOpen((prevState) => !prevState);
                      setProjectRowIdx(-1);
                    }}
                    draggable="false"
                    onMouseEnter={(e) => handleMouseEnter(e, projectAccordionOpen)}
                    onMouseLeave={(e) => handleMouseLeave(e, projectAccordionOpen)}
                    onMouseOver={() => onVariableMouseOver('isProjectEnvHoveredIcon', true)}
                    onMouseOut={() => onVariableMouseOut('isProjectEnvHoveredIcon', false)}
                  />
                </CustomTooltip>
              </div>
            </div>
            {projectAccordionOpen && (
              <table className="w-full variable-table-modal text-sm text-left text-gray-500 dark:text-gray-400">
                <div>
                  <thead className="text-xs text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr className="h-35">
                      <th scope="col" className="pl-3 accordion-table-name fontPoppinsSemiboldSm">
                        Name
                      </th>
                      <th scope="col" class="pl-3  text-left accordion-table-value fontPoppinsSemiboldSm">
                        Value
                      </th>
                    </tr>
                  </thead>
                </div>
                <div className="Local-view-variable relative overflow-x-hidden">
                  <tbody>
                    {projectVariable.map((variable, index) => (
                      <>
                        <tr className="relative bg-white dark:bg-gray-800 dark:border-gray-700 h-35">
                          <td
                            className={`text-left text-gray-900 whitespace-nowrap dark:text-white accordion-table-name`}
                          >
                            {variable.name.length > 17 ? (
                              <CustomTooltip responseTooltip={true} title={variable.name}>
                                {variableNameInput(projectRowIdx, variable, handleInputChange, index, projectVariable)}
                              </CustomTooltip>
                            ) : (
                              variableNameInput(projectRowIdx, variable, handleInputChange, index, projectVariable)
                            )}
                            {variable.showErrorMessage && projectRowIdx === index && (
                              <div className="request-section-errors">
                                {variable.showErrorMessage.length > 30 ? (
                                  <div>
                                    {variable.showErrorMessage.slice(0, 37)}
                                    <br />
                                    {variable.showErrorMessage.slice(37)}
                                  </div>
                                ) : (
                                  variable.showErrorMessage
                                )}
                              </div>
                            )}
                          </td>
                          <td class="text-left accordion-table-value">
                            {!variable?.masked && variable.value.length > 17 ? (
                              <CustomTooltip responseTooltip={true} title={variable?.value}>
                                {variableValueInput(projectRowIdx, variable, index, projectVariable)}
                              </CustomTooltip>
                            ) : (
                              variableValueInput(projectRowIdx, variable, index, projectVariable)
                            )}
                            {projectRowIdx !== index && selectedProjectStatus !== 'Closed' && (
                              <CustomTooltip responseTooltip={true} placement="bottom" title="Edit">
                                <EditRoundedIcon
                                  className={`${
                                    !variable.isSystemVariable &&
                                    !isFormOpen &&
                                    localRowIdx < 0 &&
                                    projectRowIdx < 0 &&
                                    globalRowIdx < 0
                                      ? 'modal-edit-icon'
                                      : 'modal-edit-disable'
                                  } absolute right-3 top-1.5 cursor-pointer`}
                                  onClick={() => {
                                    if (
                                      !variable.isSystemVariable &&
                                      !isFormOpen &&
                                      localRowIdx < 0 &&
                                      projectRowIdx < 0 &&
                                      globalRowIdx < 0
                                    )
                                      handleEditMode(index, 'projectRowIdx', variable, projectVariable);
                                  }}
                                />
                              </CustomTooltip>
                            )}
                          </td>
                        </tr>
                        <tr className="border-b">
                          <td className="flex relative ml-1 variable-input-value left-52">
                            {projectRowIdx === index && (
                              <>
                                <Checkbox
                                  name="variableValueMasked"
                                  id="variableValueMasked"
                                  size="small"
                                  className={`${variable?.masked ? 'view-variable-masked-checkbox' : ''} ${
                                    (variable?.isHide || variable?.masked) && 'view-variable-checked'
                                  } viewVariablecheckbox mr-0.5 masked-checkboxs cursor pointer ${classes.checkbox}`}
                                  onChange={(e) => handleInputChange(e, index, 'isHide', projectVariable)}
                                  disabled={variable?.masked}
                                  defaultChecked={variable?.isHide}
                                  checked={variable?.isHide || variable?.masked}
                                />
                                <span className="fontPoppinsRegularSm mt-2.5 ml-1 mb-2"> Hide value</span>
                                <span>
                                  <CustomTooltip
                                    responseTooltip={true}
                                    placement="right"
                                    title="Once the value is hidden, it cannot be undone"
                                  >
                                    <img
                                      src={Info}
                                      alt=""
                                      className="ml-1 w-4 h-4 mt-3 object-contain"
                                      height="15px"
                                      width="15px"
                                      draggable="false"
                                    />
                                  </CustomTooltip>
                                </span>
                                <span>
                                  <CustomTooltip responseTooltip={true} placement="bottom" title="Cancel">
                                    <CloseIcon
                                      className="absolute top-2 right-11 variable-action-icons variable-hover-effect  cursor-pointer"
                                      onClick={() => handleCancelIcon(index, 'projectRowIdx', projectVariable)}
                                    />
                                  </CustomTooltip>
                                  <CustomTooltip responseTooltip={true} placement="bottom" title="Update">
                                    <CheckIcon
                                      className={`${
                                        variableDisableValidate(variable, 'class')
                                          ? 'variable-check-disable'
                                          : 'variable-hover-effect '
                                      } absolute top-2 right-3  cursor-pointer`}
                                      onClick={() => {
                                        variableDisableValidate(variable, null) &&
                                          handleCheckIcon(index, 'projectRowIdx', projectVariable);
                                      }}
                                    />
                                  </CustomTooltip>
                                </span>
                              </>
                            )}
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </div>
              </table>
            )}
          </div>
        </div>
        <div className="modal-footer-section modal-footer-container">
          <div className="float-right mr-4">
            <button
              onClick={() => {
                handleViewVariable(false);
              }}
              type="button"
              className=" modal-close-btn"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default ViewVariable;

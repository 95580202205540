import ClientStatusModal from '@pagescommon/execution/client-status.jsx';

export const beforeRunningStatuses = [
  'Initializing Execution',
  'Initialized',
  'Parallel Runs Exceeded for license',
  'Client is busy',
  'Awaiting Client Acknowledgement',
  'Client Acknowledged',
  'Preparing client for execution',
  'Updating client libraries',
  'Client Restarting',
  'Queued',
  'Exceeded',
  'Checking...',
];

export const partialResultPageStatuses = [
  'Initialized',
  'Parallel Runs Exceeded for license',
  'Client is busy',
  'Awaiting Client Acknowledgement',
  'Client Acknowledged',
  'Preparing client for execution',
  'Updating client libraries',
  'Client Restarting',
  'Queued',
  'Exceeded',
  'Checking...',
];
export const getStatusTypeForPending = (execData) => {
  let statusSpanTag;
  if (!execData) {
    return '--';
  }
  const clientStatusMessage = execData?.clientStatus?.clientMessage?.message;
  if (!clientStatusMessage) {
    statusSpanTag = 'Checking...';
  } else if (clientStatusMessage.toLowerCase().includes('there are other execution requests pending on this machine')) {
    statusSpanTag = 'Queued';
  } else if (
    clientStatusMessage.includes('This license is limited to') &&
    clientStatusMessage.includes('parallel runs, there are already')
  ) {
    statusSpanTag = 'Exceeded';
  } else {
    statusSpanTag = execData?.executionStatus;
  }
  return statusSpanTag;
};

export const suiteResultStatistics = (executionResult, exeDashboard) => {
  if (executionResult?.resultStatus && executionResult.scriptStatistics) {
    if (executionResult.resultStatus === 'FAIL') {
      return (
        <>
          <div
            className={`float-left cursor-pointer opacity-1 result-failed ${exeDashboard !== true ? 'table-font-style-common' : 'exetable-font-style-common'}`}
          >
            {executionResult.scriptStatistics?.totalFailed}/{executionResult.scriptStatistics?.total} Failed
          </div>
        </>
      );
    } else if (executionResult.resultStatus === 'WARNING') {
      return (
        <>
          <div
            className={`float-left cursor-pointer opacity-1 text-yellow-400 ${exeDashboard !== true ? 'table-font-style-common' : 'exetable-font-style-common'}`}
          >
            {executionResult.scriptStatistics?.totalWarning}/{executionResult.scriptStatistics?.total} Warning
          </div>
        </>
      );
    } else if (executionResult.resultStatus === 'SKIP') {
      return (
        <>
          <div
            className={`float-left cursor-pointer opacity-1 result-skipped ${exeDashboard !== true ? 'table-font-style-common' : 'exetable-font-style-common'}`}
          >
            {executionResult.scriptStatistics?.totalSkipped}/{executionResult.scriptStatistics?.total} Skipped
          </div>
        </>
      );
    } else if (executionResult.resultStatus === 'PASS') {
      return (
        <>
          <div
            className={`float-left cursor-pointer opacity-1 result-passed ${exeDashboard !== true ? 'table-font-style-common' : 'exetable-font-style-common'}`}
          >
            {executionResult.scriptStatistics?.totalPassed}/{executionResult.scriptStatistics?.total} Passed
          </div>
        </>
      );
    }
  }
  return (
    <>
      <div
        className={`float-left cursor-pointer opacity-1 result-skipped ${exeDashboard !== true ? 'table-font-style-common' : 'exetable-font-style-common'}`}
      >
        {' '}
        N/A
      </div>
    </>
  );
};

export const executionDashboardInfo = (executionDashboard) => {
  const { clientStatus, executionStatus, headless } = executionDashboard;
  return ['Terminated', 'Pending'].includes(executionStatus) ? (
    <ClientStatusModal clientStatus={clientStatus} executionStatus={executionStatus} headless={headless || false} />
  ) : (
    ''
  );
};

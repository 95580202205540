export const PREREQUISITE_CONSTANT = {
  TRUE: true,
  FALSE: false,
  NO_LOG_YET: 'No Log yet',
  EMPTY_ARRAY: [],
  EMPTY_STRING: 'No Log yet',
  COMPILATION_SUCCESS: 'Compiled successfully',
  NULL: null,
  CLASSNAME_PREREQUISITE_FIRST: 'prerequisite overflow-auto',
  ID_JOURNAL: 'journal-scroll',
  CLASSNAME_PREREQUISITE_TWO: 'flex justify-end prerequisite_left_sidebar_section_two',
  LABEL_SUBHEADING: 'label-Sub-Heading fontPoppinsRegularLg flex-auto',
  PREREQUISITE_CREATE_LABEL: 'Create PreRequisite Script',
  PR_4: 'pr-4',
  PR_2: 'pr-2',
  FLEX_FLEX_ROW: 'flex flex-row ml-10',
  SECONDARY_BUTTON: 'secondary-btn_new ',
  COMPILE_AND_SAVE: 'Compile & Save',
  FLEX_ROW_JUSTIFY: 'flex flex-row justify-end',
  CLASSNAME_SNIPPET_FIRST: 'flex justify-start width-50',
  LABEL_SNIPPETS_CLASSNAME: 'ml-3 sub_tab_name fontPoppinsSemiboldMd',
  LABEL_SNIPPETS: 'Snippets',
  BEAUTIFY: 'Beautify',
  SNIPPET_INSIDE_DIV: 'flex justify-end cursor-pointer width-50 pr-0.5 select-none',
  SNIPPET_INSIDE_DIV_REQUEST_BODY: 'flex justify-end cursor-pointer  pr-0.5 w-6 relative left-56',
  MENU_ICON: '/assets/images/menu-icon.svg',
  WIDTH_20_PER: '20%',
  WIDTH_20_PIX: '20px',
  HEIGHT_20_PIX: '20px',
  TOGGLE_MENU: '/assets/images/toggle_menu.svg',
  SNIPPET_TABLE_ONE: 'flex mt-1',
  FLEX_FLEX_ROW_ML_2: 'flex flex-row ml-2',
  STICKY_TABLE: 'sticky table',
  MAX_HEIGHT: 200,
  CONSOLE_DIV_ONE: 'ace-console-wrp-prerequiste',
  CONSOLE_DIV_TWO: 'flex expand-console-label-section fontPoppinsSemiboldMd',
  CONSOLE_LABEL_ONE: 'prerequisite-left-sidebar-label flex-auto',
  CONSOLE_LABEL: 'Console',
  CLEAR_BUTTON_DIV: 'flex flex-row mr-5 clear_console_section fontPoppinsSemiboldSm',
  SECONDARY_CLEAR_BUTTON: 'secondary-btn_new  ml-3',
  TOGGLE_MT1: 'toggle ml-5',
  SHOWMORE: 'showmore',
  WIDTH_20: '20',
  CONSOLE_OUTPUT_CONTAINER: 'prerequisite_console_output_container overflow-y-auto',
  CONSOLE_DIV:
    'Request_Div flex prerequisite-left-sidebar-label prerequisite-maincontainer-section-two fontPoppinsSemiboldSm11',
  CLEAR_LABEL: 'Clear',
  CONSOLE_CLASSNAME: 'ml-4 text-sm flex-auto',
  SETARROWPOSITIONCLOSE: 'toggle setIsArrowPositionClose',
  WIDTH_98_PER: '98%',
  HEIGHT_35_PIX: '35px',
  WIDTH_25: '25',
  BACKGROUND_COLOR: '#e5ecfc',
  EDITOR_HEIGHT: '250',
  EDITOR_WIDTH: 'auto',
  ONlOAD_CHANGE: `//Each snippet receive input as input attributes and return output attributes
//insert the key value pair to output attributes
//Assign_Variable - Use "$" to set variable for Assign_Variable
//Assign_Value - Use "$" to set variable for Assign value or hardcode value
//put("key","value");`,
  TABSIZE: 6,
  FONTSIZE: 12,
  UNIQUE_ID_OF_DIV: 'UNIQUE_ID_OF_DIV',
  TOMMORROW: 'tomorrow',
  JAVA: 'java',
  MINILINES: 25,
  MAXLINES: 100,
  PREREQUISITE_LEFT_SIDEBAR: 'prerequisite-left-side-bar',
  PREREQUISITE_LEFT_SIDEBAR_SECTION_ONE: 'prerequisite_left_sidebar_section_one',
  PREREQUISITE_LEFT_SIDEBAR_HIDE: 'prerequisite_left_sidebar_hide',
  COMPILE_BTN_SECTION: 'compile_btn_section',
  PREREQUISITE_MAIN_CONTAINER: 'prerequisite-main-container',
  PREREQUISITE_MAIN_CONTAINER_SECTION_ONE: 'prerequisite-main-container-section-one',
  PREREQUISITE_MAIN_CONTAINER_SECTION_TWO: 'prerequisite-main-container-section-two',
  PREREQUISITE_MAIN_CONTAINER_FULL_SECTION: 'prerequisite-main-container-full-section',
  PREREQUISITE_LEFT_SIDEBAR_LABEL: 'ml-3 prerequisite-left-sidebar-label fontPoppinsRegularMd select-none',
  PREREQUISITECODEVALUE: 'preRequisiteCodeValue',
};

import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import '@pages/licenses/styles/license-alert-popup.scss';
import { ReactComponent as YellowAlert } from '@assets/svg-imports/yellow_alert.svg';
const ChangeDefaultTemplateModal = (props) => {
  const userName = JSON.parse(localStorage.getItem('test-optimize-user'))?.name;
  return (
    <>
      <Modal
        open={props.openForDefaultTempChange}
        onClose={props.handleCloseForDefaultTempChange}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="changeLicenseBoxModal">
          <div className="headerLicenseChange">
            <div className="successImg">
              <YellowAlert className="svgImgStyle" />
            </div>
            <div className="mt-8">
              <p className="svgContentYellow fontPoppinsMediumPageSubHeader">
                Warning! <span className="text-black">Default Template</span>
              </p>
              <br />
              <p className="ml-7 -mt-3 fontPoppinsRegularLg">
                Hi {userName},<br /> Are you sure you want to change default template?
              </p>
            </div>
          </div>
          <div className="footerBlue flex ">
            <button
              className="buttonStyleForChangeLicense cursor-pointer fontPoppinsRegularMd"
              onClick={() => {
                props.handleCloseForDefaultTempChange();
              }}
            >
              Cancel
            </button>
            <button
              className="primaryBtnStyleForBlue cursor-pointer fontPoppinsRegularMd"
              onClick={() => {
                props.defaulTemplateRadioHandler();
                props.handleCloseForDefaultTempChange();
              }}
            >
              Change
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};
export default ChangeDefaultTemplateModal;

import React, { useState, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router';

import { autoRerunExecutionReq, getRunSettingsDataReq, postSuiteReq, updateSuiteReq } from '@api/api_services';
import ModuleScript from './create-edit-child-pages/module-script';
import {
  getTruncatedText,
  getCurrentProjectType,
  getAutomationProjectTypes,
  getPrivilege,
  purchasedFeatureTypeOfAutomation,
  getFinalMachineObject,
  archive,
  convertStringToBoolean,
} from '@src/util/common_utils';
import { getLicenseFeatures } from '@src/util/common_utils';
import { isEmptyValue } from '@src/util/common_utils';
let clonedProjectVarArray = [];
let clonedGlobalVarArray = [];
let emailGroupArray = [];
let emailRecipientArray = [];
const CreateEditSuite = (props) => {
  let history = useHistory();
  let no_sync = 'No Sync';
  const [suiteObjForPreview, setSuiteObjForPreview] = useState({});
  const [descCount, setdescCount] = useState(0);
  const [selectedSyncValue, setSelectedSyncValue] = useState(
    props?.suiteObj?.syncScripts ? props?.suiteObj?.syncScripts : no_sync
  );
  const [previewScreen, setPreviewScreen] = useState(false);
  const [emailData, setEmailData] = useState();
  const [slackData, setSlackData] = useState();
  const [projectGlobalVarData, setProjectGlobalVarData] = useState();
  const [testData, setTestData] = useState();
  const [fileArray, setFileArray] = useState([]);
  const [rootData, setRootDataForModule] = useState();
  const [createUpdateCalled, setCreateUpdateCalled] = useState(false);
  const [suiteType, setSuiteType] = useState(props?.suiteObj?.suiteType ? props?.suiteObj?.suiteType : 'Manual');
  const [expanded, setExpanded] = useState('panel1');
  const [updateEmailData, setUpdateEmailData] = useState(false);
  const [suiteNameClicked, setSuiteNameClicked] = useState(props.operation === 'update' || false);
  let [typeSwitch, setTypeSwitch] = useState(false);
  const [wrongPattern, setWrongPattern] = useState();
  const [isEmailDropdownOpen, setIsEmailDropdownOpen] = useState(false);
  const [onLoading, setonLoading] = useState(false);
  const [runSettingsObject, setRunSettingsObject] = useState({});
  const [runSettingsManualObject, setRunSettingsManualObject] = useState({ ...props?.runSettingsManualObject });
  const [selectedSuiteObjForExecution, setSelectedSuiteObjForExecution] = useState({});
  const [operationForRunSettings, setOperationForRunSettings] = useState();
  const [suiteObj, setSuiteObj] = useState({});
  const [suiteTypeList, setSuiteTypeList] = useState([]);
  const [loadModuleScripts, setLoadModuleScripts] = useState(false);
  const [memoryWarningPopup, setMemoryWarningPopup] = useState(false);
  const [waitTimeObj, setWaitTime] = useState({
    implicitWait:
      props.suiteObj && props.suiteObj.waitTime && props.suiteObj.waitTime.implicitWait
        ? props.suiteObj.waitTime.implicitWait
        : 0,
    explicitlyWait:
      props.suiteObj && props.suiteObj.waitTime && props.suiteObj.waitTime.explicitlyWait
        ? props.suiteObj.waitTime.explicitlyWait
        : 0,
    delayBetweenSteps:
      props.suiteObj && props.suiteObj.waitTime && props.suiteObj.waitTime.delayBetweenSteps
        ? props.suiteObj.waitTime.delayBetweenSteps
        : 0,
    implicitWaitUnit:
      props.suiteObj && props.suiteObj.waitTime && props.suiteObj.waitTime.implicitWaitUnit
        ? props.suiteObj.waitTime.implicitWaitUnit
        : 'Msecs',
    explicitlyWaitUnit:
      props.suiteObj && props.suiteObj.waitTime && props.suiteObj.waitTime.explicitlyWaitUnit
        ? props.suiteObj.waitTime.explicitlyWaitUnit
        : 'Msecs',
    delayBetweenStepsUnit:
      props.suiteObj && props.suiteObj.waitTime && props.suiteObj.waitTime.delayBetweenStepsUnit
        ? props.suiteObj.waitTime.delayBetweenStepsUnit
        : 'Msecs',
  });
  const [resultSettingObj, setResultSetting] = useState({
    captureScreenshots:
      props.suiteObj && props.suiteObj.resultSetting
        ? props.suiteObj.resultSetting.captureScreenshots
        : 'For Failed steps only',
    captureVideoForFailedTestScript:
      props.suiteObj && props.suiteObj.resultSetting && props.suiteObj.resultSetting.captureVideoForFailedTestScript
        ? props.suiteObj.resultSetting.captureVideoForFailedTestScript
        : 'For Failed scripts only',
    displayLogsInScriptResult:
      props.suiteObj && props.suiteObj.resultSetting && props.suiteObj.resultSetting.displayLogsInScriptResult
        ? props.suiteObj.resultSetting.displayLogsInScriptResult
        : 'On Suite Execution Completion / Termination',
  });
  const [exeTerminationObj, setExeTermintion] = useState({
    terminateScriptIfTakesMoreTime:
      props.suiteObj && props.suiteObj.executionTermination
        ? props.suiteObj.executionTermination.terminateScriptIfTakesMoreTime
        : 0,
    terminateScriptUnit:
      props.suiteObj && props.suiteObj.executionTermination
        ? props.suiteObj.executionTermination.terminateScriptUnit
        : 'Hrs.',
    terminateScriptSendEmail:
      props.suiteObj && props.suiteObj.executionTermination
        ? props.suiteObj.executionTermination.terminateScriptSendEmail
        : 'false',
    terminateSuiteIfTakesMoreTime:
      props.suiteObj && props.suiteObj.executionTermination
        ? props.suiteObj.executionTermination.terminateSuiteIfTakesMoreTime
        : 0,
    terminateSuitetUnit:
      props.suiteObj && props.suiteObj.executionTermination
        ? props.suiteObj.executionTermination.terminateSuitetUnit
        : 'Hrs.',
    terminateSuiteSendEmail:
      props.suiteObj && props.suiteObj.executionTermination
        ? props.suiteObj.executionTermination.terminateSuiteSendEmail
        : 'false',
  });

  const [machineDetails, setmachineDetails] = useState(props?.suiteObj?.machines || {});

  const [moduleArray, setModuleArray] = useState(
    props?.suiteObj && props?.suiteObj?.selectedModulesAndScripts && props?.suiteObj?.selectedModulesAndScripts.length
      ? props?.suiteObj?.selectedModulesAndScripts[0].children
      : []
  );
  const [selectedModulesStatus, setSelectedModulesStatus] = useState(props?.suiteObj?.selectedModulesStatus || {});
  let projectType = getCurrentProjectType();
  const [automationTypes, setAutomationTypes] = useState([]);
  const [isAutomationProject, setIsAutomationProject] = useState(false);
  const licenseFeatures = getLicenseFeatures();
  const hasManualTestCase = window.privilege.hasManualTestCase(licenseFeatures);
  const hasAutomation = window.privilege.hasAutomation(licenseFeatures);
  const hasDefects = window.privilege.hasDefects(licenseFeatures);
  const hasCBasic = window.privilege.hasCBasic(licenseFeatures);
  const project = JSON.parse(localStorage.getItem('selected-project'));
  const hasAutomationBasedOnProjType = purchasedFeatureTypeOfAutomation(project.type);
  const privilegeObj = {
    hasManualTestCase,
    hasAutomation,
    hasDefects,
    hasCBasic,
    hasAutomationBasedOnProjType,
  };

  useEffect(() => {
    setAutomationTypes(getAutomationProjectTypes(getPrivilege()));
    if (props?.operation) {
      setOperationForRunSettings(props?.operation);
    } else {
      setOperationForRunSettings('create');
    }
  }, []);

  useEffect(() => {
    setIsAutomationProject(automationTypes?.includes(projectType));
  }, [projectType]);

  const formik = useFormik({
    initialValues: {
      suiteName: props?.suiteObj?.name,
      description: props?.suiteObj?.description,
      syncScriptOption: props?.suiteObj?.syncScripts ? props?.suiteObj?.syncScripts : 'No Sync',
    },

    validationSchema: Yup.object({
      suiteName: Yup.string()
        .min(3, 'Minimum 3 characters')
        .required('Name is Required!')
        .matches(/^[a-zA-Z0-9-_() ]*$/, 'Name should be alphanumeric')
        .matches(/^[-a-zA-Z0-9-()_]+(\s+[-a-zA-Z0-9-()_]+)*$/, 'Space is not allowed in the beginning and at the end'),
      syncScriptOption: Yup.string()
        .min(2, 'Minimum 2 characters')
        .max(15, 'Maximum 50 characters')
        .required('Required!'),
    }),
  });

  let stepperArry = [
    'Scripts',
    'Variables',
    'Test Data',
    'Execution Settings',
    'Report Configurations',
    'User Settings',
  ];

  useEffect(() => {
    let text = formik.values.suiteName;
    let pattern = /[a-zA-Z]/;
    if (text) {
      const result = pattern.test(text);
      setWrongPattern(!result);
    } else {
      setWrongPattern(true);
    }
  }, [formik.values.suiteName]);

  useEffect(() => {
    initializeSuiteType(props.suiteObj);
    if (props?.suiteObj?._id) {
      getRunSettingsData(props.suiteObj);
      if (props?.suiteObj?.selectedModulesAndScripts) {
        let rootObj = {
          key: props?.suiteObj?.selectedModulesAndScripts[0]?.key,
          title: props?.suiteObj?.selectedModulesAndScripts[0]?.title,
        };
        setRootDataForModule(rootObj);
      }
    }
    if (!props?.suiteObj?.name) {
      setWaitTime({
        implicitWait: 0,
        explicitlyWait: 0,
        delayBetweenSteps: 0,
        implicitWaitUnit: 'Secs',
        explicitlyWaitUnit: 'Secs',
        delayBetweenStepsUnit: 'Secs',
      });
      setResultSetting({
        captureScreenshots: 'For Failed steps only',
        captureVideoForFailedTestScript: 'For Failed scripts only',
        displayLogsInScriptResult: 'On Suite Execution Completion / Termination',
      });

      setExeTermintion({
        terminateScriptIfTakesMoreTime: 0,
        terminateScriptUnit: 'Hrs.',
        terminateScriptSendEmail: 'false',
        terminateSuiteIfTakesMoreTime: 0,
        terminateSuitetUnit: 'Hrs.',
        terminateSuiteSendEmail: 'false',
      });
    }
  }, [props.suiteObj]);

  const initializeSuiteType = (suiteObj) => {
    const { hasAutomation, hasManualTestCase, hasCBasic, hasAutomationBasedOnProjType } = privilegeObj;
    let _suiteTypeList = [];
    let definedSuiteType = suiteObj?.suiteType;
    let newSuiteType = '';
    if ((hasAutomation && hasAutomationBasedOnProjType && hasManualTestCase) || hasCBasic) {
      _suiteTypeList = ['Automation', 'Manual'];
      newSuiteType = 'Manual';
    } else if (hasAutomation && hasAutomationBasedOnProjType && !hasManualTestCase) {
      _suiteTypeList = ['Automation'];
      newSuiteType = 'Automation';
      setTypeSwitch(true);
    } else if (!(hasAutomation && hasAutomationBasedOnProjType) && hasManualTestCase) {
      _suiteTypeList = ['Manual'];
      newSuiteType = 'Manual';
      setTypeSwitch(false);
    } else if (hasAutomation && hasAutomationBasedOnProjType && hasManualTestCase && !isAutomationProject) {
      _suiteTypeList = ['Manual'];
      newSuiteType = 'Manual';
      setTypeSwitch(false);
    }
    setSuiteType(definedSuiteType ? definedSuiteType : newSuiteType);
    setSuiteTypeList(_suiteTypeList);
    setLoadModuleScripts(true);
  };

  function getModule(data) {
    setModuleArray(data);
  }
  function getSelectedModulesStatus(data) {
    setSelectedModulesStatus(data);
  }

  function getChangedFile(data) {
    let tempFileArray = fileArray;
    if (data?.length) {
      data.forEach((fileObj) => {
        if (fileObj) {
          let file = new File([fileObj.file], fileObj.fileNewName, {
            type: fileObj?.file?.type,
          });
          tempFileArray.push(file);
          setFileArray(tempFileArray);
        }
      });
    }
  }
  let rootModule = {
    key: rootData?.key,
    title: rootData?.title,
    type: 'Module',
    children: moduleArray,
  };
// Note: The use of "==" in here is intentional due to specific requirements.
// Do not change "==" to "===" as it may affect the logic.
  function createSuite(type) {
    let macValidation = true;
    let waitTimeValidation = true;
    let emailValidation;
    let slackValidation;
    let emailReportValidation;
    let slackReportValidation;
    if (emailData?.emailTheReportAfterExecution == 'true') {
      if (emailData?.emailRecipient?.length || emailData?.recipientGroup?.length) {
        emailValidation = true;
      } else {
        emailValidation = false;
      }
      if (suiteType === 'Manual') {
        emailReportValidation = true;
      } else {
        if (emailData?.emailOnSuiteExecutionStart == 'true') {
          emailReportValidation = true;
        } else if (emailData?.emailOnSuiteExecutionCompletion == 'true') {
          emailReportValidation = true;
        } else {
          emailReportValidation = false;
        }
      }
    } else {
      emailValidation = true;
      emailReportValidation = true;
    }
    if (slackData?.slackReportSelected === 'true') {
      if (slackData?.recipientGroup?.length > 0 || slackData?.selectedSlackInstances?.length > 0) {
        slackValidation = true;
      } else {
        slackValidation = false;
      }
      if (suiteType === 'Manual') {
        slackReportValidation = true;
      } else {
        if (slackData?.on_Suite_Execution_Starts == true) {
          slackReportValidation = true;
        } else if (slackData?.on_Suite_Execution_Ends == true) {
          slackReportValidation = true;
        } else if (slackData?.attachPdfReport == true) {
          slackReportValidation = true;
        } else if (slackData?.attachHtmlReport == true) {
          slackReportValidation = true;
        } else {
          slackReportValidation = false;
        }
      }
    } else {
      slackValidation = true;
      slackReportValidation = true;
    }

    if (suiteType !== 'Manual') {
      if (
        (waitTimeObj?.delayBetweenStepsUnit === 'Secs' && waitTimeObj?.delayBetweenSteps > 99) ||
        (waitTimeObj?.delayBetweenStepsUnit === 'Msecs' && waitTimeObj?.delayBetweenSteps > 99000) ||
        (waitTimeObj?.implicitWaitUnit === 'Secs' && waitTimeObj?.implicitWait > 999) ||
        (waitTimeObj?.implicitWaitUnit === 'Msecs' && waitTimeObj?.implicitWait > 999000) ||
        (waitTimeObj?.explicitlyWaitUnit === 'Secs' && waitTimeObj?.explicitlyWait > 999) ||
        (waitTimeObj?.explicitlyWaitUnit === 'Msecs' && waitTimeObj?.explicitlyWait > 999000)
      ) {
        waitTimeValidation = false;
      }
    }

    if (type === 'Create') {
      let SuiteJson = {
        name: formik.values.suiteName,
        description: formik.values.description,
        syncScripts: selectedSyncValue,
        selectedModulesAndScripts: moduleArray?.length ? [rootModule] : [],
        selectedModulesStatus: selectedModulesStatus,
        usePeVariableFrom:
          suiteType === 'Manual'
            ? ''
            : projectGlobalVarData &&
              projectGlobalVarData?.projectVarJsonObject &&
              projectGlobalVarData?.projectVarJsonObject?.usePeVariableFrom
            ? projectGlobalVarData?.projectVarJsonObject?.usePeVariableFrom
            : 'TEST_DEVELOPMENT',
        projectEnvironmentVariables:
          suiteType === 'Manual' ? [] : projectGlobalVarData?.projectVarJsonObject?.projectEnvironmentVariables,
        useGlobalVariableFrom:
          suiteType === 'Manual'
            ? ''
            : projectGlobalVarData &&
              projectGlobalVarData?.globalVarJsonObject &&
              projectGlobalVarData?.globalVarJsonObject?.useGlobalVariableFrom
            ? projectGlobalVarData?.globalVarJsonObject?.useGlobalVariableFrom
            : 'TEST_DEVELOPMENT',
        globalVariables: suiteType === 'Manual' ? [] : projectGlobalVarData?.globalVarJsonObject?.globalVariables,
        useTestDataFrom:
          suiteType === 'Manual' ? '' : testData?.useTestDataFrom ? testData?.useTestDataFrom : 'TEST_DEVELOPMENT',
        sourceTestDataSet: suiteType === 'Manual' ? [] : testData?.sourceTestDataSet,
        suiteType: suiteType,
        waitTime: waitTimeObj,
        resultSetting: resultSettingObj,
        executionTermination: exeTerminationObj,
        machines: isEmptyValue(getFinalMachineObject(machineDetails)?.selectedMachines)
          ? null
          : getFinalMachineObject(machineDetails),
      };

      if (suiteType === 'Manual') {
        SuiteJson['clonedProjectEnvironmentVariables'] = [];
        SuiteJson['selectedPESetName'] = '';
        SuiteJson['clonedGlobalVariables'] = [];
        SuiteJson['selectedGVSetName'] = '';
        SuiteJson['clonedTestDataSets'] = [];
        SuiteJson['selectedTDSetName'] = '';
      } else {
        SuiteJson.clonedProjectEnvironmentVariables = clonedProjectVarArray;
        SuiteJson.selectedPESetName = projectGlobalVarData?.projectVarJsonObject?.selectedPESetName;
        SuiteJson.clonedGlobalVariables = clonedGlobalVarArray;
        SuiteJson.selectedGVSetName = projectGlobalVarData?.globalVarJsonObject?.selectedGVSetName;
        SuiteJson.clonedTestDataSets = testData?.clonedTestDataSets;
        SuiteJson.selectedTDSetName = testData?.selectedTDSetName;
      }

      if (emailData && emailData?.recipientGroup && emailData?.recipientGroup.length) {
        let groupNameArray = [];
        emailData?.recipientGroup?.forEach((groupObj) => {
          if (groupObj?.groupName) {
            groupNameArray.push(groupObj.groupName);
          }
        });
        if (groupNameArray?.length) {
          let uniqueChars = [...new Set(groupNameArray)];
          emailGroupArray = uniqueChars;
        } else {
          emailGroupArray = emailData?.recipientGroup;
        }
      } else {
        emailGroupArray = [];
      }

      if (emailData && emailData.emailRecipient && emailData.emailRecipient.length) {
        let uniqueChars = [...new Set(emailData.emailRecipient)];
        emailRecipientArray = uniqueChars;
      } else {
        emailRecipientArray = [];
      }

      if (emailData) {
        let emailTempObj = {
          emailTheReportAfterExecution: convertStringToBoolean(emailData.emailTheReportAfterExecution),
          recipientGroup: convertStringToBoolean(emailGroupArray),
          emailRecipient: convertStringToBoolean(emailRecipientArray),
          emailOnSuiteExecutionStart: convertStringToBoolean(emailData.emailOnSuiteExecutionStart),
          emailOnSuiteExecutionCompletion: convertStringToBoolean(emailData.emailOnSuiteExecutionCompletion),
          attachPdfReport: convertStringToBoolean(emailData.attachPdfReport),
          attachHtmlReport: convertStringToBoolean(emailData.attachHtmlReport),
          attachExecutionLogs: convertStringToBoolean(emailData.attachExecutionLogs),
          emailManuallyTriggerEmailNotification: convertStringToBoolean(
            emailData.emailManuallyTriggerEmailNotification
          ),
        };
        SuiteJson.emailData = emailTempObj;
      } else {
        let emailTempObj = {
          emailTheReportAfterExecution: false,
        };
        SuiteJson.emailData = emailTempObj;
      }
      if (slackData?.slackReportSelected == 'true') {
        let slackTempObj = {
          slackManuallyTriggerNotification: convertStringToBoolean(slackData?.slackManuallyTriggerNotification),
          on_Suite_Execution_Ends: convertStringToBoolean(slackData?.on_Suite_Execution_Ends),
          on_Suite_Execution_Starts: convertStringToBoolean(slackData?.on_Suite_Execution_Starts),
          pdfAttachment: convertStringToBoolean(slackData?.attachPdfReport || slackData?.pdfAttachment),
          htmlAttachment: convertStringToBoolean(slackData?.attachHtmlReport || slackData?.htmlAttachment),
          selectedSlackInstances: convertStringToBoolean(
            slackData?.recipientGroup || slackData?.selectedSlackInstances
          ),
        };
        SuiteJson.slackData = slackTempObj;
      }

      const formData = new FormData();
      const unique = [...new Map(fileArray.map((item) => [item['name'], item])).values()];
      unique.forEach((tag) => formData.append('files', tag));
      formData.append('data', JSON.stringify(SuiteJson));
      setCreateUpdateCalled(true);
      if (emailValidation) {
        if (emailReportValidation) {
          if (slackReportValidation) {
            if (slackValidation) {
              if (macValidation) {
                if (waitTimeValidation) {
                  postSuiteReq(formData)
                    .then((res) => {
                      if (res.data && res.data.responseObject) {
                        history.push('/execution/suite');
                        props.createAlertFun(
                          'success',
                          `${getTruncatedText(SuiteJson.name, 22)} suite created successfully`
                        );
                        props.getAllSuite();
                        props.romoveRerun();
                        removeLocalStorageData();
                        setCreateUpdateCalled(false);
                      } else {
                        console.error('error', res.data.message);
                        props.createAlertFun('danger', res.data.message);
                        setCreateUpdateCalled(false);
                      }
                    })
                    .catch((error) => {
                      console.error('error');
                      setCreateUpdateCalled(false);
                    });
                } else {
                  setCreateUpdateCalled(false);
                }
              } else {
                props.createAlertFun('danger', `please assign machine/s for all the modules`);
                setCreateUpdateCalled(false);
              }
            } else {
              props.createAlertFun(
                'danger',
                `Please select at least one slack instance  to send a execution reports after execution to Slack channels.`
              );
              setCreateUpdateCalled(false);
            }
          } else {
            props.createAlertFun(
              'danger',
              `Please select slack Reports in “Slack” section to send a report after execution.`
            );
            setCreateUpdateCalled(false);
          }
        } else {
          props.createAlertFun(
            'danger',
            `Please select Email Reports in “Email” section to send a report after execution.`
          );
          setCreateUpdateCalled(false);
        }
      } else {
        props.createAlertFun(
          'danger',
          `Please add at least one email in the “Email” section to send a report after execution.`
        );
        setCreateUpdateCalled(false);
      }
    } else {
      let clonedGlobalVarArray = [];
      if (props.suiteObj && props.suiteObj.clonedGlobalVariables) {
        if (props.suiteObj.clonedGlobalVariables.length) {
          props.suiteObj.clonedGlobalVariables.forEach((obj) => {
            let tempObj = {
              name: obj.name,
              variableSets: obj.variableSets,
              createdBy: obj.createdBy,
              createdOn: obj.createdOn,
              modifiedBy: obj.modifiedBy,
              lastSync: obj?.lastSync,
            };
            clonedGlobalVarArray.push(tempObj);
          });
        }
      }
      let clonedTestData = [];
      if (suiteType !== 'Manual') {
        if (testData?.clonedTestDataSets) {
          if (testData.clonedTestDataSets.length) {
            testData.clonedTestDataSets.forEach((obj) => {
              let tempObj = {
                name: obj.name,
                testDataSets: obj.testDataSets,
                createdBy: obj.createdBy,
                createdOn: obj.createdOn,
                modifiedBy: obj.modifiedBy,
              };
              clonedTestData.push(tempObj);
            });
          }
        }
      }

      let rootModule = {
        key: rootData?.key,
        title: rootData?.title,
        type: 'Module',
        children: moduleArray,
      };

      let SuiteJson = {
        name: formik.values.suiteName,
        description: formik.values.description,
        syncScripts: selectedSyncValue,
        selectedModulesAndScripts: moduleArray?.length ? [rootModule] : [],
        selectedModulesStatus: selectedModulesStatus,
        usePeVariableFrom: suiteType === 'Manual' ? '' : projectGlobalVarData?.projectVarJsonObject?.usePeVariableFrom,
        projectEnvironmentVariables:
          suiteType === 'Manual' ? [] : projectGlobalVarData?.projectVarJsonObject?.projectEnvironmentVariables,
        useGlobalVariableFrom:
          suiteType === 'Manual'
            ? ''
            : projectGlobalVarData?.globalVarJsonObject &&
              projectGlobalVarData?.globalVarJsonObject?.useGlobalVariableFrom
            ? projectGlobalVarData?.globalVarJsonObject?.useGlobalVariableFrom
            : props.suiteObj.useGlobalVariableFrom,
        globalVariables:
          suiteType === 'Manual'
            ? []
            : projectGlobalVarData?.globalVarJsonObject && projectGlobalVarData?.globalVarJsonObject?.globalVariables
            ? projectGlobalVarData?.globalVarJsonObject?.globalVariables
            : props.suiteObj.globalVariables,
        useTestDataFrom: suiteType === 'Manual' ? '' : testData?.useTestDataFrom,
        sourceTestDataSet: suiteType === 'Manual' ? [] : testData?.sourceTestDataSet,
        createdBy: props.suiteObj.createdBy,
        createdByUname: props.suiteObj.createdByUname,
        createdOn: props.suiteObj.createdOn,
        noOfScripts: props.suiteObj.noOfScripts,
        id: props.suiteObj.id,
        access: props.suiteObj.access,
        suiteType: suiteType,
        waitTime: waitTimeObj,
        resultSetting: resultSettingObj,
        executionTermination: exeTerminationObj,
        machines: isEmptyValue(getFinalMachineObject(getMachineObj())?.selectedMachines)
          ? null
          : getFinalMachineObject(getMachineObj()),
      };

      if (suiteType === 'Manual') {
        SuiteJson['clonedProjectEnvironmentVariables'] = [];
        SuiteJson['selectedPESetName'] = '';
        SuiteJson['clonedGlobalVariables'] = [];
        SuiteJson['selectedGVSetName'] = '';
        SuiteJson['clonedTestDataSets'] = [];
        SuiteJson['selectedTDSetName'] = '';
      } else {
        let clonedProjectVarArray = [];
        if (projectGlobalVarData?.projectVarJsonObject?.clonedProjectEnvironmentVariables) {
          if (projectGlobalVarData?.projectVarJsonObject?.clonedProjectEnvironmentVariables.length) {
            projectGlobalVarData?.projectVarJsonObject?.clonedProjectEnvironmentVariables.forEach((obj) => {
              let tempObj = {
                name: obj.name,
                variableSets: obj.variableSets,
                createdBy: obj.createdBy,
                createdOn: obj.createdOn,
                modifiedBy: obj.modifiedBy,
                lastSync: obj?.lastSync,
              };
              clonedProjectVarArray.push(tempObj);
            });
          }
        }
        SuiteJson.clonedProjectEnvironmentVariables = clonedProjectVarArray;
        SuiteJson.selectedPESetName = projectGlobalVarData?.projectVarJsonObject?.selectedPESetName;
        SuiteJson.selectedGVSetName =
          projectGlobalVarData?.globalVarJsonObject && projectGlobalVarData?.globalVarJsonObject?.selectedGVSetName
            ? projectGlobalVarData?.globalVarJsonObject?.selectedGVSetName
            : props.suiteObj.selectedGVSetName;
        if (
          projectGlobalVarData?.globalVarJsonObject &&
          projectGlobalVarData?.globalVarJsonObject?.clonedGlobalVariables
        ) {
          if (projectGlobalVarData?.globalVarJsonObject?.clonedGlobalVariables.length) {
            clonedGlobalVarArray = [];
            projectGlobalVarData?.globalVarJsonObject?.clonedGlobalVariables.forEach((obj) => {
              let tempObj = {
                name: obj.name,
                variableSets: obj.variableSets,
                createdBy: obj.createdBy,
                createdOn: obj.createdOn,
                modifiedBy: obj.modifiedBy,
                lastSync: obj?.lastSync,
              };
              clonedGlobalVarArray.push(tempObj);
            });
          }
        }
        SuiteJson.clonedGlobalVariables = clonedGlobalVarArray;
        SuiteJson.clonedTestDataSets = testData?.clonedTestDataSets;
        SuiteJson.selectedTDSetName = testData?.selectedTDSetName;
      }
      if (emailData) {
        let emailGroupArray = [];
        let emailRecipientArray = [];
        if (emailData && emailData.recipientGroup && emailData.recipientGroup.length) {
          let groupNameArray = [];
          emailData.recipientGroup.forEach((groupObj) => {
            if (groupObj?.groupName) {
              groupNameArray.push(groupObj.groupName);
            }
          });
          if (groupNameArray?.length) {
            let uniqueChars = [...new Set(groupNameArray)];
            emailGroupArray = uniqueChars;
          } else {
            emailGroupArray = emailData.recipientGroup;
          }
        }

        if (emailData && emailData.emailRecipient && emailData.emailRecipient.length) {
          let uniqueChars = [...emailData.emailRecipient];
          emailRecipientArray = uniqueChars;
        }
        let emailTempObj = {
          emailTheReportAfterExecution: convertStringToBoolean(emailData.emailTheReportAfterExecution),
          recipientGroup: emailGroupArray,
          emailRecipient: emailRecipientArray,
          emailOnSuiteExecutionStart: convertStringToBoolean(emailData.emailOnSuiteExecutionStart),
          emailOnSuiteExecutionCompletion: convertStringToBoolean(emailData.emailOnSuiteExecutionCompletion),
          attachPdfReport: convertStringToBoolean(emailData.attachPdfReport),
          attachHtmlReport: convertStringToBoolean(emailData.attachHtmlReport),
          attachExecutionLogs: convertStringToBoolean(emailData.attachExecutionLogs),
          emailManuallyTriggerEmailNotification: convertStringToBoolean(
            emailData.emailManuallyTriggerEmailNotification
          ),
        };

        SuiteJson.emailData = emailTempObj;
      } else {
        SuiteJson.emailData = props.suiteObj.emailData;
      }

      if (slackData?.slackReportSelected === 'true') {
        let slackTempObj = {
          slackManuallyTriggerNotification: slackData.slackManuallyTriggerNotification,
          on_Suite_Execution_Ends: slackData?.on_Suite_Execution_Ends,
          on_Suite_Execution_Starts: slackData?.on_Suite_Execution_Starts,
          pdfAttachment: slackData?.attachPdfReport || slackData?.pdfAttachment,
          htmlAttachment: slackData?.attachHtmlReport || slackData?.htmlAttachment,
          selectedSlackInstances: slackData?.recipientGroup,
        };

        SuiteJson.slackData = slackTempObj;
      } else {
        SuiteJson.slackData = slackData;
      }
      const formData = new FormData();
      formData.append('data', JSON.stringify(SuiteJson));
      if (emailValidation) {
        if (emailReportValidation) {
          if (slackReportValidation) {
            if (slackValidation) {
              if (macValidation) {
                if (waitTimeValidation) {
                  updateSuiteReq(props.suiteObj?.id ? props.suiteObj?.id : props.suiteObj?._id, formData)
                    .then((res) => {
                      if (res.data && res.data.responseObject) {
                        history.push('/execution/suite');
                        let title = `${
                          SuiteJson.name?.length > 22 ? SuiteJson?.name?.substring(0, 22) + '...' : SuiteJson?.name
                        } suite updated successfully`;
                        props.createAlertFun(
                          'success',
                          `${getTruncatedText(SuiteJson.name, 22)} suite updated successfully`
                        );
                        props.getAllSuite();
                        props.romoveRerun();
                        removeLocalStorageData();
                      } else {
                        props.createAlertFun('danger', res.data.message);
                      }
                    })
                    .catch((error) => {
                      console.error('error:', error);
                    });
                }
              } else {
                props.createAlertFun('danger', getMachineValidationMessage());
                setCreateUpdateCalled(false);
              }
            } else {
              props.createAlertFun(
                'danger',
                `Please select at least one slack instance  to send a execution reports after execution to Slack channels.`
              );
            }
          } else {
            props.createAlertFun(
              'danger',
              `Please select slack Reports in “Slack” section to send a report after execution.`
            );
            setCreateUpdateCalled(false);
          }
        } else {
          props.createAlertFun(
            'danger',
            `Please select Email Reports in “Email” section to send a report after execution.`
          );
          setCreateUpdateCalled(false);
        }
      } else {
        props.createAlertFun(
          'danger',
          `Please add at least one email in the “Email” section to send a report after execution.`
        );
        setCreateUpdateCalled(false);
      }
    }
  }

  const getMachineValidationMessage = () => {
    return `Please assign ${suiteType === 'Manual' ? 'user(s)' : 'machine(s)'} for all the modules`;
  };

  function showAlertMessagee() {
    if (!formik.values.suiteName) {
      props.createAlertFun('danger', 'Suite name is mandatory');
    } else if (formik.values.suiteName && formik.errors.suiteName) {
      props.createAlertFun('danger', formik.errors.suiteName);
    } else if (!moduleArray.length) {
      props.createAlertFun('danger', 'Please select the modules/scripts');
    } else if (
      suiteType !== 'Manual' &&
      projectGlobalVarData?.projectVarJsonObject?.usePeVariableFrom === 'SET' &&
      projectGlobalVarData?.projectVarJsonObject?.selectedPESetName === ''
    ) {
      props.createAlertFun('danger', 'Please select the project environment variables set');
    } else if (
      suiteType !== 'Manual' &&
      projectGlobalVarData?.globalVarJsonObject?.useGlobalVariableFrom === 'SET' &&
      projectGlobalVarData?.globalVarJsonObject?.selectedGVSetName === ''
    ) {
      props.createAlertFun('danger', 'Please select the global variables set');
    } else if (suiteType !== 'Manual' && !testData?.selectedTDSetName && testData?.useTestDataFrom === 'SET') {
      props.createAlertFun('danger', 'Please select the test data set');
    } else if (
      emailData?.emailTheReportAfterExecution === 'true' &&
      !emailData?.emailRecipient?.length &&
      !emailData?.recipientGroup?.length
    ) {
      props.createAlertFun(
        'danger',
        'Please add at least one email in the “Email” section to send a report after execution.'
      );
    } else if (slackData?.slackReportSelected === 'true' && !slackData?.recipientGroup.length) {
      props.createAlertFun(
        'danger',
        'Please select at least one slack instance  to send a execution reports after execution to Slack channels.'
      );
    } else {
      const _validity = runSettingValidation();
      if (_validity) {
        setPreviewScreen(true);
        navigateToPreview();
      }
    }
  }
  const runSettingValidation = () => {
    let runSettingsGlobalObj = {
      waitTime: waitTimeObj,
      resultSetting: resultSettingObj,
      executionTermination: exeTerminationObj,
      machines: getMachineObj(),
      suiteType: suiteType,
    };
    if (runSettingsGlobalObj) {
      let waitTimeDataValidation = true;
      let exeTerminationDataValidation = true;
      let macValidation = true;
      if (runSettingsGlobalObj.waitTime) {
        Object.keys(runSettingsGlobalObj.waitTime).forEach((key) => {
          if (runSettingsGlobalObj.waitTime[key] === '') {
            waitTimeDataValidation = false;
          }
        });
      }
      if (runSettingsGlobalObj.executionTermination) {
        Object.keys(runSettingsGlobalObj.executionTermination).forEach((key) => {
          if (runSettingsGlobalObj.executionTermination[key] === '') {
            exeTerminationDataValidation = false;
          }
        });
      }
      if (runSettingsGlobalObj?.machines?.selectedMachines?.length) {
        if (
          runSettingsGlobalObj?.machines?.multiple === 'true' &&
          runSettingsGlobalObj?.machines?.executionType === 'DISTRIBUTE'
        ) {
          if (runSettingsGlobalObj?.machines?.distributionMechanism === 'MANUAL') {
            let assignedScripts = [];
            let _selectedModules = [];
            let filteredModules = [];
            if (moduleArray?.length) {
              _selectedModules = moduleArray?.length ? [...moduleArray] : [];
            } else if (suiteObj?.selectedModulesAndScripts?.[0].children) {
              _selectedModules = suiteObj?.selectedModulesAndScripts[0].children;
            }
            let tempSelectedModulesAndScripts = JSON.parse(JSON.stringify([..._selectedModules]));

            getModuleAndScriptList(tempSelectedModulesAndScripts, filteredModules);
            runSettingsGlobalObj.machines.selectedMachines.forEach((selectedMachine) => {
              selectedMachine.machineInstances.forEach((machineInstance) => {
                assignedScripts = assignedScripts.concat(machineInstance?.distribution?.selectedModules);
              });
            });
            if (assignedScripts?.length < filteredModules?.length) {
              macValidation = false;
            } else {
              macValidation = true;
            }
          }
        }
        if (macValidation) {
          if (suiteType.toLowerCase() !== 'manual') {
            if (waitTimeDataValidation) {
              if (exeTerminationDataValidation) {
                return true;
              } else {
                props.createAlertFun('info', 'Execution Termination data is required');
              }
            } else {
              props.createAlertFun('info', 'Wait Time data is required');
            }
          } else {
            return true;
          }
        } else {
          props.createAlertFun('danger', getMachineValidationMessage());
        }
      } else {
        return true;
      }
    }
    return false;
  };

  const getModuleAndScriptList = (selectedModulesAndScripts, filteredModules) => {
    selectedModulesAndScripts.forEach((_modScript) => {
      if (_modScript.children) {
        getModuleAndScriptList(_modScript.children, filteredModules);
      } else if (!['PRE', 'POST'].includes(_modScript?.type)) {
        filteredModules.push(_modScript);
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        columns: [],
      },

      {
        Header: 'Name',
        accessor: 'name',
        disableFilters: true,
        Cell: (e) => (
          <a href="" className="hyper_link_color-common float-left ml-2">
            {' '}
            {e.value}{' '}
          </a>
        ),
      },

      {
        Header: 'Created By',
        accessor: 'modifiedByUname',
        disableFilters: true,
        Cell: (e) => (
          <a href="" className="hyper_link_color-common float-left ml-3">
            {' '}
            {e.value}{' '}
          </a>
        ),
      },
      {
        Header: 'Created On',
        accessor: 'createdOn',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left ml-3">{value}</div>;
        },
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left">{value}</div>;
        },
      },
    ],
    []
  );
  const getMachineObj = () => {
    if (machineDetails && Object.entries(machineDetails).length) {
      return machineDetails;
    } else if (runSettingsObject?.machines) {
      return runSettingsObject?.machines;
    } else if (suiteObjForPreview?.machines) {
      return suiteObjForPreview?.machines;
    } else {
      return {};
    }
  };
  function goToSuiteTable() {
    localStorage.removeItem('selectedSuite');
    history.push('/execution/suite');
    props.getAllSuite();
    props.romoveRerun();
    removeLocalStorageData();
  }

  function removeLocalStorageData() {
    localStorage.removeItem('selectedSingleSystem');
    localStorage.removeItem('selectedMultpleSystem');
    localStorage.removeItem('selectedDistributionSystem');
    localStorage.removeItem('selectedAutomaticDistributionSystem');
    localStorage.removeItem('selectedSingleMobDevice');
    localStorage.removeItem('selectedMobDevice');
    localStorage.removeItem('automaticSelectedMobDevice');
    localStorage.removeItem('manualySelectedMobDevice');
  }

  function navigateToPreview() {
    if (moduleArray) {
      if (moduleArray.length) {
        moduleArray.forEach((obj) => {
          obj.checkbox = false;
        });
      }
    }

    let suiteObj = {
      projectGlobalVarData: projectGlobalVarData,
      useTestDataFrom: testData?.useTestDataFrom ? testData?.useTestDataFrom : '',
      sourceTestDataSet: testData?.sourceTestDataSet ? testData.sourceTestDataSet : [],
      selectedTDSetName: testData?.selectedTDSetName ? testData?.selectedTDSetName : '',
      clonedTestDataSets: testData?.clonedTestDataSets ? testData?.clonedTestDataSets : [],
      selectedModulesAndScripts: moduleArray,
      emailData: emailData,
      slackData: slackData?.slackReportSelected === 'true' ? slackData : null,
      waitTime: waitTimeObj,
      resultSetting: resultSettingObj,
      executionTermination: exeTerminationObj,
      machines: getFinalMachineObject(getMachineObj()),
      suiteType: suiteType,
      name: props?.suiteObj?.name,
      usePeVariableFrom: props?.suiteObj?.usePeVariableFrom,
      selectedPESetName: props?.suiteObj?.selectedPESetName,
      clonedProjectEnvironmentVariables: props?.suiteObj?.clonedProjectEnvironmentVariables,
      useGlobalVariableFrom: props?.suiteObj?.useGlobalVariableFrom,
      selectedGVSetName: props?.suiteObj?.selectedGVSetName,
      clonedGlobalVariables: props?.suiteObj?.clonedGlobalVariables,
    };
    if (props?.suiteObj) {
      suiteObj['_id'] = props?.suiteObj._id;
    }
    if (
      suiteType === 'Automation' &&
      suiteObj?.slackData?.slackReportSelected === 'true' &&
      !suiteObj?.slackData?.on_Suite_Execution_Ends &&
      !suiteObj?.slackData?.on_Suite_Execution_Starts &&
      (!suiteObj?.slackData?.attachPdfReport || !suiteObj?.slackData?.attachHtmlReport)
    ) {
      props?.createAlertFun(
        'warning',
        `Please select slack Reports in “Slack” section to send a report after execution.`
      );
      setPreviewScreen(false);
    } else {
      setSuiteObjForPreview(suiteObj);
      localStorage.setItem('selectedSuite', JSON.stringify(suiteObj));
      setPreviewScreen(true);
    }
  }

  function navigateToCreateScreen() {
    const _suiteObjLocal = JSON.parse(localStorage.getItem('selectedSuite'));
    if (_suiteObjLocal) getRunSettingsData(_suiteObjLocal);
    setPreviewScreen(false);
    setEmailData({ ...emailData });
    props.suiteObj['machines'] =
      machineDetails && Object.entries(machineDetails).length
        ? getFinalMachineObject(machineDetails)
        : getFinalMachineObject(runSettingsObject?.machines);
    props.suiteObj['resultSetting'] = resultSettingObj;
    props.suiteObj['waitTime'] = waitTimeObj;
    props.suiteObj['executionTermination'] = exeTerminationObj;
    props.suiteObj['slackData'] = _suiteObjLocal?.slackData;
  }

  function createSuiteMainFunction(data) {
    const newData = { ...data };
    setEmailData(newData);
  }
  function createSuiteSlackFunction(data) {
    const newData = { ...data };
    setSlackData(newData);
  }

  function createSuiteMainFunctionForProjectVariable(data) {
    clonedProjectVarArray = [];
    clonedGlobalVarArray = [];
    if (data.projectVarJsonObject && data.projectVarJsonObject.clonedProjectEnvironmentVariables) {
      if (data.projectVarJsonObject.clonedProjectEnvironmentVariables.length) {
        data.projectVarJsonObject.clonedProjectEnvironmentVariables.forEach((obj) => {
          let tempObj = {
            name: obj.name,
            variableSets: obj.variableSets,
            createdBy: obj.createdBy,
            createdOn: obj.createdOn,
            modifiedBy: obj.modifiedBy,
            lastSync: obj?.lastSync,
          };
          clonedProjectVarArray.push(tempObj);
        });
      }
    }
    if (data.globalVarJsonObject && data.globalVarJsonObject.clonedGlobalVariables) {
      if (data.globalVarJsonObject.clonedGlobalVariables.length) {
        data.globalVarJsonObject.clonedGlobalVariables.forEach((obj) => {
          let tempObj = {
            name: obj.name,
            variableSets: obj.variableSets,
            createdBy: obj.createdBy,
            createdOn: obj.createdOn,
            modifiedBy: obj.modifiedBy,
            lastSync: obj?.lastSync,
          };
          clonedGlobalVarArray.push(tempObj);
        });
      }
    }
    setProjectGlobalVarData(data);
  }

  function createTestDataFunction(data) {
    setTestData(data);
  }

  function clearFunc() {
    history.push('/execution/suite');
    setTimeout(() => {
      props.clearFun();
    });
    props.romoveRerun();
    removeLocalStorageData();
  }

  function getRootData(rootData) {
    setRootDataForModule(rootData);
  }

  function goToSuiteDashboard() {
    let search = window.location.search;
    let urlSuiteId = new URLSearchParams(search).get('suiteId');
    let urlSuiteType = new URLSearchParams(search).get('suiteType');
    let specificModPage = new URLSearchParams(search).get('detailsPage');
    let executionId = new URLSearchParams(search).get('executionId');
    if (specificModPage) {
      props.setScreenMode('moduleSpecificPage');
      props?.getAllDashboard();
      history.push(
        `/execution/suite/${props?.suiteObj?.name}/suite-dashboard/${executionId}?executionId=${executionId}&suiteId=${urlSuiteId}&detailsPage=${specificModPage}&suiteType=${urlSuiteType}`
      );
    } else {
      props.setScreenMode('executionDashboard');
      props?.getAllDashboard();
      history.push(
        `/execution/suite/${props?.suiteObj?.name}/suite-dashboard?suiteId=${urlSuiteId}&suiteType=${urlSuiteType}`
      );
    }
  }

  function autoRerunExeFunction(deleteSpillOverMemory = false) {
    setonLoading(true);
    let search = window.location.search;
    let executionId = new URLSearchParams(search).get('executionId');
    let urlSuiteId = new URLSearchParams(search).get('suiteId');
    let urlSuiteType = new URLSearchParams(search).get('suiteType');
    autoRerunExecutionReq(executionId, {}, deleteSpillOverMemory)
      .then((res) => {
        if (res?.data && res?.data?.responseObject) {
          props?.getAllDashboard();
          localStorage?.removeItem('rerun');
          removeLocalStorageData();
          props?.setScreenMode('executionDashboard');
          history.push(
            `/execution/suite/${props?.suiteObj?.name}/suite-dashboard?suiteId=${urlSuiteId}&suiteType=${urlSuiteType}`
          );
          archive.suiteFlags[urlSuiteId] = false;
          props.setChecked(false);
        } else if (res?.data?.message === 'Consumed Memory is above 85%') {
          setMemoryWarningPopup(true);
        } else {
          console.error('error', res.data.message);
          props.createAlertFun('danger', res.data.message);
          setonLoading(false);
        }
      })
      .catch((error) => {
        console.error('error:', error);
        setonLoading(false);
      });
  }

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleSuiteTypeChange = (radioVal) => {
    setSuiteType(radioVal);
    if (emailData) {
      let emailTempObj = {
        emailTheReportAfterExecution: convertStringToBoolean(emailData?.emailTheReportAfterExecution),
        recipientGroup: convertStringToBoolean(emailData?.recipientGroup),
        emailRecipient: convertStringToBoolean(emailData?.emailRecipient),
        emailOnSuiteExecutionStart: convertStringToBoolean(emailData?.emailOnSuiteExecutionStart),
        emailOnSuiteExecutionCompletion: convertStringToBoolean(emailData?.emailOnSuiteExecutionCompletion),
        attachPdfReport: convertStringToBoolean(emailData.attachPdfReport),
        attachHtmlReport: convertStringToBoolean(emailData.attachHtmlReport),
        attachExecutionLogs: convertStringToBoolean(emailData.attachExecutionLogs),
        emailManuallyTriggerEmailNotification: convertStringToBoolean(emailData.emailManuallyTriggerEmailNotification),
      };
      if (radioVal === 'Automation') {
        emailTempObj['emailOnSuiteExecutionStart'] = 'true';
        emailTempObj['emailOnSuiteExecutionCompletion'] = 'true';
        setEmailData(emailTempObj);
      } else {
        emailTempObj['emailOnSuiteExecutionStart'] = 'false';
        emailTempObj['emailOnSuiteExecutionCompletion'] = 'false';
        setEmailData(emailTempObj);
      }
    }
    if (slackData) {
      let slackTempObj = {
        slackManuallyTriggerNotification: convertStringToBoolean(slackData?.slackManuallyTriggerNotification),
        on_Suite_Execution_Ends: convertStringToBoolean(slackData?.on_Suite_Execution_Ends),
        on_Suite_Execution_Starts: convertStringToBoolean(slackData?.on_Suite_Execution_Starts),
        pdfAttachment: convertStringToBoolean(slackData?.attachPdfReport || slackData?.pdfAttachment),
        htmlAttachment: convertStringToBoolean(slackData?.attachHtmlReport || slackData?.htmlAttachment),
        selectedSlackInstances: convertStringToBoolean(slackData?.recipientGroup || slackData?.selectedSlackInstances),
      };
      if (suiteType === 'Automation') {
        slackTempObj['on_Suite_Execution_Starts'] = true;
        slackTempObj['attachPdfReport'] = true;
        slackTempObj['attachHtmlReport'] = true;
        slackTempObj['on_Suite_Execution_Ends'] = true;
        setSlackData(slackTempObj);
      } else {
        slackTempObj['on_Suite_Execution_Starts'] = false;
        slackTempObj['on_Suite_Execution_Ends'] = false;
        setSlackData(slackTempObj);
      }
    }
  };

  const handleTypeSwitch = (radioVal) => {
    setTypeSwitch(radioVal);
  };
  const [emailOptions, setemailOptions] = useState({});

  const updateEmailOptions = (_key, data) => {
    let _emailOpts = { ...emailOptions };
    _emailOpts[_key] = data;
    setemailOptions(_emailOpts);
  };

  function getRunSettingsData(selectedSuite) {
    let tempMacObj;
    if (selectedSuite?.machines?.selectedMachines && selectedSuite?.machines?.selectedMachines.length) {
      tempMacObj = {
        executionTermination: selectedSuite.executionTermination,
        machines: selectedSuite.machines,
        resultSetting: selectedSuite.resultSetting,
        waitTime: selectedSuite.waitTime,
      };
    } else {
      getRunSettingsDataReq(selectedSuite?._id, 'run').then((results) => {
        if (results?.data?.responseObject) {
          tempMacObj = results?.data?.responseObject;
        }
      });
    }
    if (tempMacObj) {
      if (selectedSuite?.suiteType?.toLowerCase() === 'manual') {
        let tempManualObj = {
          machines: tempMacObj.machines,
        };
        setRunSettingsManualObject(tempManualObj);
        setmachineDetails({ ...tempManualObj.machines });
      } else {
        const { executionTermination, machines, resultSetting, waitTime } = tempMacObj;
        let tempObj = {
          executionTermination,
          machines,
          resultSetting,
          waitTime,
        };
        setRunSettingsObject(tempObj);
        setmachineDetails({ ...tempObj.machines });
        setOperationForRunSettings('update');
      }
      setSelectedSuiteObjForExecution(selectedSuite);
      setSuiteObj(selectedSuite);
    } else {
      setRunSettingsManualObject({});
      props?.setRunSettingsManualObject({});
      setRunSettingsObject({ noData: true });
      setSelectedSuiteObjForExecution(selectedSuite);
      setSuiteObj(selectedSuite);
      setOperationForRunSettings('create');
    }
  }

  return (
    <div className="suite_specific">
      {loadModuleScripts && (
        <ModuleScript
          suiteObjMain={props?.suiteObj}
          rerunMode={props?.rerunMode}
          operation={props?.operation}
          createAlertFun={props?.createAlertFun}
          suiteName={formik?.values?.suiteName}
          syncScripts={formik?.values?.syncScriptOption}
          executionType={props?.executionType}
          description={formik.values.description}
          numberOfParallelRuns={props?.numberOfParallelRuns}
          privilegeObject={privilegeObj}
          suiteTypeList={suiteTypeList}
          {...{
            setExeTermintion,
            rootData,
            operationForRunSettings,
            setWaitTime,
            setResultSetting,
            setmachineDetails,
            selectedSuiteObjForExecution,
            runSettingsManualObject,
            runSettingsObject,
            getMachineObj,
            runSettingValidation,
            waitTimeObj,
            exeTerminationObj,
            resultSettingObj,
            emailData,
            slackData,
            navigateToCreateScreen,
            descCount,
            moduleArray,
            testData,
            projectGlobalVarData,
            setUpdateEmailData,
            updateEmailData,
            createSuiteMainFunction,
            createSuiteSlackFunction,
            setIsEmailDropdownOpen,
            getChangedFile,
            createTestDataFunction,
            createSuiteMainFunctionForProjectVariable,
            setdescCount,
            suiteNameClicked,
            stepperArry,
            handleChange,
            expanded,
            setSuiteNameClicked,
            createSuite,
            wrongPattern,
            createUpdateCalled,
            showAlertMessagee,
            clearFunc,
            goToSuiteTable,
            formik,
            previewScreen,
            goToSuiteDashboard,
            onLoading,
            autoRerunExeFunction,
            isEmailDropdownOpen,
            suiteType,
            handleSuiteTypeChange,
            typeSwitch,
            handleTypeSwitch,
            getModule,
            getSelectedModulesStatus,
            getRootData,
            selectedModulesStatus,
            suiteObjForPreview,
            memoryWarningPopup,
            setMemoryWarningPopup,
          }}
        />
      )}
    </div>
  );
};

export default CreateEditSuite;

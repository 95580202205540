import React, { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { FaCaretDown } from 'react-icons/fa';
import ExecutionResult from './execution-result';
import { useHistory } from 'react-router';
import { CommonLoader } from '@src/pages/common/common-loader';
import { getIDB, setIDB } from '@src/util/localForage_idb_controller';
import { useAlert } from '@src/pages/common/alert_service/useAlert';
import { getTruncatedText } from '@src/util/common_utils';
import { encodeSpecialCharater } from '@src/pages/test-development/shared/common-methods';

function MultiMachineDropdown(props) {
  const [machineList, setMachineList] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState();
  const [selectedProject, setSelectedProject] = useState();
  const [selectedMachineRunId, setSelectedMachineRunId] = useState();
  const [treeData, setTreeData] = useState({});
  const [infoData, setInfoData] = useState({});
  const [chartData, setChartData] = useState({});
  const [platformData, setPlatformData] = useState();
  const machineName = window.location.pathname.split('/')[7];
  const [rootData, setRootData] = useState();

  const [machineNames, setMachineNames] = useState();
  const [execResult, setExecResult] = useState();
  const { AlertContatiner, MyAlert } = useAlert();
  const selectedId = selectedMachineRunId ? selectedMachineRunId : selectedMachine?.title;

  useEffect(() => {
    (async () => {
      const tempValue = await getIDB('execResult');
      setExecResult(tempValue);
    })();
  }, []);
  const suiteName = window.location.pathname.split('/')[3];
  const [resultType, setResultType] = useState('suite');
  let history = useHistory();
  const search = window.location.search;
  const executionId = new URLSearchParams(search).get('executionId');
  const [suiteType, setSuiteType] = useState('');

  const updateSuiteType = (_executionResult) => {
    let _suiteTYPE;
    if (_executionResult && _executionResult?.responseObject?.executionResponses?.length) {
      let actualid = _executionResult.responseObject.executionResponses[0].executionId;
      if (actualid === executionId && _executionResult?.responseObject?.suiteType) {
        _suiteTYPE = _executionResult.responseObject.suiteType;
      }
      setSuiteType(_suiteTYPE);
    }
    return _suiteTYPE;
  };
  const retrieveExecutionEnv = (index) => {
    if (execResult?.responseObject?.executionResponses?.[index]?.platformStatistics?.[0]?.executionEnv) {
        return execResult.responseObject.executionResponses[index].platformStatistics[0].executionEnv;
    } 
    else if (execResult?.responseObject?.executionResponses?.[index]?.children?.[0]?.children?.[0]?.children?.[0]?.selectedSystem?.machineInstances?.[0]?.executionEnv) {
        return execResult.responseObject.executionResponses[index].children[0].children[0].children[0].selectedSystem.machineInstances[0].executionEnv;
    } 
    else {
        return null;  
    }
};

  const infoDataValue = (index) => {
    const info = {
      runId: execResult?.responseObject?.executionResponses[index]?.executionId,
      status: execResult?.responseObject?.executionResponses[index]?.status,
      execution: retrieveExecutionEnv(index),
      duration: execResult?.responseObject?.executionResponses[index]?.moduleStats?.executionDurationInHourMinSecFormat,
      executedOn: execResult?.responseObject?.executionResponses[index]?.executedOn,
      machine: execResult?.responseObject?.executionResponses[index]?.machine,
      suiteName: suiteName,
      os: [execResult?.responseObject?.executionResponses[index]?.platformStatistics[0]?.os],
      browser: execResult?.responseObject?.executionResponses[index]?.platformStatistics[0]?.browser,
      deviceName: execResult?.responseObject?.executionResponses[index]?.platformStatistics[0]?.deviceName,
    };
    setInfoData(info);
  };
  const getSelectedMachineIndex = (data, searchKey, searchValue) => {
    return data?.findIndex((item) => item?.[searchKey] === searchValue);
  };
  const getTreeAndRootData = () => {
    if (execResult) {
      let res;
      const runId = selectedMachineRunId ? selectedMachineRunId : new URLSearchParams(search).get('runId');
      let _suiteType = updateSuiteType(execResult);
      let machineList = JSON.parse(localStorage.getItem('machineInfo'));
      let data = [];
      if (_suiteType === 'Manual') {
        data = machineList?.map((el) => ({ ...el, label: el.clientId }));
      } else {
        data = machineList?.map((el) => ({ ...el, label: el.title }));
      }
      setMachineList(data);
      updateSuiteType(execResult);
      setSelectedProject(JSON.parse(localStorage.getItem('selected-project')));
      if (selectedMachine?.label) {
        const index = runId
          ? getSelectedMachineIndex(data, 'runId', runId)
          : getSelectedMachineIndex(data, 'label', selectedMachine?.label);
        let returned_data = execResult?.responseObject?.executionResponses[index]?.children;
        res = returned_data ? returned_data[0]?.children : [];
        const { children, ...root_data } = returned_data[0];
        if (!root_data.name) {
          root_data['name'] = root_data.title;
        }
        setRootData(root_data);
        setSelectedMachine(data[index]);
        setSelectedMachineRunId(data[index]?.runId);
        setTreeData(res);
        setMachineNames(data[index]?.label);
        const search = window.location.search;
        const executionId = new URLSearchParams(search).get('executionId');
        const exec_name = window.location.pathname.split('/')[3];
        history.push(
          `/execution/suite/${exec_name}/execution dashboard/overview/machines/${
            data[index]?.label
          }/Result?executionId=${executionId}${runId ? `&runId=${runId}` : ''}`
        );
        infoDataValue(index);
        setPlatformData(execResult?.responseObject?.executionResponses[index]?.platformStatistics);
        setGraphChartData(execResult?.responseObject?.executionResponses[index]?.children[0]);
      } else {
        let decodedMachineName = decodeURIComponent(machineName);
        const index = runId
          ? getSelectedMachineIndex(data, 'runId', runId)
          : getSelectedMachineIndex(data, 'label', decodedMachineName);
        setMachineNames(data[index]?.label);
        setSelectedMachine(data[index]);
        setSelectedMachineRunId(data[index]?.runId);
        setTreeData(res);
      }
    }
  };

  const handleStatusChange = async () => {
    setTreeData([]);
    setRootData([]);
    const execResultTemp = await getIDB('execResult');
    setExecResult(execResultTemp);
    getTreeAndRootData();
  };

  useEffect(() => {
    getTreeAndRootData();
  }, [selectedId, execResult]);

  const setGraphChartData = (data) => {
    let _defaultStats = {
      total: 0,
      totalWarning: 0,
      totalFailed: 0,
      totalSkipped: 0,
      totalTerminated: 0,
      totalPassed: 0,
    };
    let _chart = {
      moduleStats: _defaultStats,
      scriptStats: _defaultStats,
      preConditionStats: _defaultStats,
      postConditionStats: _defaultStats,
    };
    if (data) {
      if (data?.moduleStats) {
        _chart['moduleStats'] = data?.moduleStats;
      }
      if (data?.scriptStats) {
        _chart['scriptStats'] = data?.scriptStats;
      }
      if (data?.preConditionStats) {
        _chart['preConditionStats'] = data?.preConditionStats;
      }
      if (data?.postConditionStats) {
        _chart['postConditionStats'] = data?.postConditionStats;
      }
    }
    setChartData(_chart);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const setMachineName = (machine) => {
    // Empty values to load fancy tree on switching machines
    if (
      (selectedMachine?.runId && selectedMachine?.runId !== machine?.runId) ||
      selectedMachine?.title !== machine?.title
    ) {
      setTreeData([]);
      setRootData([]);
      setSelectedMachine(machine);
      setSelectedMachineRunId(machine?.runId);
    }
  };

  const onNodeSelected = (nodeObj) => {
    let executionName = window.location.pathname.split('/')[3];

    if (nodeObj.data.folder) {
      const node = nodeObj.node;
      var dict = node.toDict(true, function (dict, node) {
        // Remove partsel, selected because they are not required
        // Remove data object from dict to make it like input json object
        const data = { ...dict.data };
        delete dict.partsel;
        delete dict.selected;
        delete dict.data;
        Object.assign(dict, data);
      });
      setIDB('sub-exec-tree-data', dict);
      localStorage.setItem('moduleId', nodeObj?.node?.key);
      setIDB('sub-tree-data', dict);
      history.push({
        pathname: `/execution/suite/${executionName}/execution dashboard/overview/machines/${machineNames}/${rootData.name}/${nodeObj.node.title}/results`,
        search: `?executionId=${executionId}&id=${nodeObj?.node?.key}${
          selectedMachineRunId ? `&runId=${selectedMachineRunId}` : ''
        }&multiple=true`,
      });
    } else {
      if (nodeObj.node.type === 'Script') {
        const node = nodeObj.node;
        const scriptData = [nodeObj.data];
        const clientId = node.parent.data.selectedSystem.clientId;
        setIDB('script-data', scriptData);
        if (nodeObj.node.data.status !== 'SKIP' || (suiteType === 'Manual' && nodeObj.node.data.status === 'SKIP')) {
          history.push({
            pathname: `/execution/suite/${executionName}/execution dashboard/overview/machines/${machineNames}/${rootData.name}/${encodeSpecialCharater(nodeObj?.node?.title)}/Result`,
            search: `?id=${node.key}&executionId=${executionId}${
              selectedMachineRunId ? `&runId=${selectedMachineRunId}` : ''
            }&multiple=true&clientId=${clientId}&moduleId=${node.parent.key}&moduleName=${encodeSpecialCharater(node.parent.title)}`,
          });
        }
      }
    }
  };

  return (
    <div>
      <div className="grid grid-cols-5 pb-4">
        <div className="fontPoppinsSemiboldXlg">Results</div>
        <div className="col-span-2 flex">
          <div class="flex flex-1 justify-center">
            <div className="alert-position-style-result">
              <AlertContatiner></AlertContatiner>
            </div>
          </div>
        </div>
        <div className="col-span-2">
          <div className="flex flex-1 justify-end header-project-selection-dropdown">
            <Menu as="div" className="relative inline-block fixed text-left w-64" key="project-menu">
              {({ open }) => (
                <>
                  <div className="flex flex-1 justify-end">
                    <Menu.Button
                      title={selectedMachine ? selectedMachine?.title : 'Select Machine'}
                      className="dark:bg-gray-800 dark:text-white rounded-md py-2 focus:outline-none"
                    >
                      <span
                        className="fontPoppinsMediumMd menu-btn-title machine-menu-btn-width"
                        style={{ color: '#1648C6' }}
                      >
                        {selectedMachine?.title ? getTruncatedText(selectedMachine.title, 20) : 'All Machines'}
                      </span>
                    </Menu.Button>
                    <Menu.Button
                      title={selectedMachine ? selectedMachine?.title : 'Select Machine'}
                      className="fontPoppinsMediumMd dark:bg-gray-800 dark:text-white rounded-md py-2 focus:outline-none"
                    >
                      <FaCaretDown className="-mr-1 h-5 w-5 down-arrow-icon" aria-hidden="true" />
                    </Menu.Button>
                  </div>

                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      className="bg-white dark:bg-gray-700 absolute right-0 mt-0 w-40 h-44 mr-7 focus:outline-none z-10 project-dropdown-menu journal-scroll"
                      style={{ height: '155px', outline: '#f1f1f8 solid 1px' }}
                    >
                      <div className="py-2">
                        {machineList.map((machine, optindex) => (
                          <Menu.Item key={`project-menu-${optindex}`} title={machine.title}>
                            {({ active }) => (
                              <button
                                onClick={(e) => setMachineName(machine)}
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'dark:bg-gray-700 dark:text-white bg-transparent block px-4 py-2 fontPoppinsRegularMd text-left w-full truncate'
                                )}
                              >
                                {machine.title}
                              </button>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        </div>
      </div>

      <div className=" flex" id="journal-scroll">
        <div className="flex-1 focus:outline-none">
          <main className="flex-1 relative pb-2 z-0 base-card">
            {!treeData || !rootData || !infoData || !chartData ? (
              <CommonLoader />
            ) : (
              <ExecutionResult
                {...props}
                treeData={treeData}
                selectedProject={selectedProject}
                infoData={infoData}
                chartData={chartData}
                rootData={rootData}
                platformData={platformData}
                onNodeSelected={onNodeSelected}
                resultType={resultType}
                handleStatusChange={handleStatusChange}
                suiteType={suiteType}
                MyAlert={MyAlert}
              />
            )}
          </main>
        </div>
      </div>
    </div>
  );
}

export default MultiMachineDropdown;

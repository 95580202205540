import React, { useState } from 'react';
import MatModal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import './data_set_modal.scss';
import { Tooltip } from '@material-ui/core';
import MyResultTable from './MyResultTable';

function DataSetModal(props) {
  const [showModal, setShowModal] = useState(true);
  console.log('dataSetData=', props.dataSetData);
  const vcolumns = React.useMemo(
    () => [
      {
        Header: 'Step',
        accessor: 'step',
        width: '20%',
      },
      {
        Header: 'Name',
        accessor: 'name',
        width: '20%',
      },
      {
        Header: 'Value',
        accessor: 'value',
        width: '20%',
      },
    ],
    []
  );
  const getFormattedName = (name) => {
    let formattedName = name;
    const splittedArr = name.split(':');
    if (splittedArr && splittedArr.length) {
      formattedName = splittedArr[1];
    }
    return formattedName || name;
  };
  return (
    <>
      <MatModal open={showModal} className="data-set-modal modal-dialog">
        <div className="modal-container data-View-size">
          <div className="modal-header">
            <div className="title fontPoppinsMediumMd">View Data Set</div>
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                props.closeDataSetModal(false);
                setShowModal(false);
              }}
            >
              <CloseIcon className="close-button" />
            </IconButton>
          </div>
          <div className="modal-body" id="journal-scroll">
            <div>
              <div className="grid ">
                <div className="col-span-2 sm:col-span-2 md:col-span-2 fontPoppinsRegularLg">
                  {props.dataSetData.length == 0 ? (
                    'No data provider variables are used in this iteration'
                  ) : (
                    <MyResultTable
                      data={props.dataSetData.map((input, index) => ({
                        step: <span className="text-gray-700 fontPoppinsRegularMd"> {input?.step}</span>,
                        name: (
                          <span className="text-gray-700 fontPoppinsRegularMd">{getFormattedName(input?.value)}</span>
                        ),
                        value: (
                          <span className="text-gray-700 fontPoppinsRegularMd table-Value-truncate">
                            <Tooltip title={input?.actualValue} placement="bottom-start">
                              <div className="truncate">{input?.actualValue}</div>
                            </Tooltip>
                          </span>
                        ),
                      }))}
                      columns={vcolumns}
                      top={props.dataSetData.length >= 8 ? '0' : '50'}
                      dataSetCheck={true}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="cancel-button-common close-btn-style-for-details-layout mr-3 cursor-pointer"
              onClick={() => {
                props.closeDataSetModal(false);
                setShowModal(false);
              }}
            >
              <label className="cancel-label-common cursor-pointer fontPoppinsMediumMd">Close</label>
            </button>
          </div>
        </div>
      </MatModal>
    </>
  );
}

export default DataSetModal;

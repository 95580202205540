import { Tooltip } from '@material-ui/core';
import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { withRouter } from 'react-router-dom';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import {
  deleteProjectEnvironmentVariable,
  getAllProjectVariableListReq,
  getSingleUserRequest,
} from '@api/api_services';
import ReactTable from '@src/pages/common/Table/ReactTable';
import CreateVariable from './CreateEditModal';
import DeleteVariable from './DeleteVariable';
import UserDetailsModal from '@pages/test-development/shared/user-details-modal';
import { useAlert } from '@pages/common/alert_service/useAlert';
import { CommonLoader } from '@src/pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import VariablesDetailsModal from '@src/pages/test-development/shared/variables/variables-details';
import SearchBar from '@src/pages/analytics/common/search-bar';
import { HighlightText, isEmptyValue, isValueIncludes, getMaskedString } from '@util/common_utils';
import NoResultsFound from '@src/pages/common/Table/NoResultFound';
import { EditOutlined, DeleteOutlined } from '@material-ui/icons';

const ProjectEnv = (props) => {
  const search = window.location.search;
  const defaultStepGroup = new URLSearchParams(search).get('defaultStepGroup');
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);

  let [reload, setReload] = useState(true);
  let [variablesData, setVariablesData] = useState([]);
  let [openCreateVariableModal, setOpenCreateVariableModal] = useState(false);
  let [openVariableDetailsModal, setOpenVariableDetailsModal] = useState(false);
  let [openVariableDeleteModal, setOpenVariableDeleteModal] = useState(false);
  let [editVariableData, setEditVariableData] = useState(null);
  let [isLoading, setIsLoading] = useState(false);
  let [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);
  let [userDetails, setUserDetails] = useState({});
  const { AlertContatiner, MyAlert } = useAlert();
  const reloadVariables = (val) => {
    setReload(val);
  };

  const getUserDetails = async (key) => {
    if (key) {
      let res = await getSingleUserRequest(key);
      if (res.data.responseCode === 200 && res.data.responseObject) {
        setUserDetails(res.data.responseObject);
        setOpenUserDetailsModal(true);
      }
    }
  };

  const closeUserDetailsModal = (value) => {
    setOpenUserDetailsModal(value);
    setUserDetails({});
  };
  const getVariables = async () => {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    setIsLoading(true);

    let res = await getAllProjectVariableListReq();
    stopProgress();
    if (res.data && res.data.responseObject && res.data.responseObject.length > 0) {
      setVariablesData(res.data.responseObject);
    } else {
      setVariablesData([]);
    }
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 10,
    });
    setIsLoading(false);
  };

  const editVariable = (varData) => {
    setOpenCreateVariableModal(true);
    setEditVariableData(varData);
  };
  const deleteVariable = (varData) => {
    setOpenVariableDeleteModal(true);
    setEditVariableData(varData);
  };
  const deleteProjectEnvVariable = async (data) => {
    try {
      const response = await deleteProjectEnvironmentVariable(data.id, data.name);
      if (response.data.responseCode === 200 || response.data.status === 'SUCCESS') {
        reloadVariables(true);
        MyAlert.success(`${data.name} variable deleted successfully`);
      } else {
        MyAlert.warning(`${response.data.message}`);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const getVariableDetails = (varData) => {
    setOpenVariableDetailsModal(true);
    setEditVariableData(varData);
  };
  let menuData = (variable) => {
    return (
      <div className="actionIcons">
        {isEditable && (
          <button
            disabled={(defaultStepGroup && defaultStepGroup === 'true') || variable.isSystemVariable}
            className={`float-left  text-base mr-3 text-blue-700 ${(defaultStepGroup && defaultStepGroup === 'true') || variable.isSystemVariable ? 'opacity-50' : 'cursor-pointer'}`}
            onClick={() => {
              if (defaultStepGroup) {
                if (defaultStepGroup === 'false') {
                  editVariable(variable);
                }
              } else {
                editVariable(variable);
              }
            }}
          >
            <Tooltip title="Edit">
              <EditOutlined />
            </Tooltip>
          </button>
        )}
        {hasFullAccess && (
          <button
            disabled={(defaultStepGroup && defaultStepGroup === 'true') || variable.isSystemVariable}
            className={`float-left ${(defaultStepGroup && defaultStepGroup === 'true') || variable.isSystemVariable ? 'opacity-50' : 'cursor-pointer'} mr-3 text-blue-700 text-base1`}
            onClick={() => {
              if (defaultStepGroup) {
                if (defaultStepGroup === 'false') {
                  deleteVariable(variable);
                }
              } else {
                deleteVariable(variable);
              }
            }}
          >
            <Tooltip title="Delete">
              <DeleteOutlined />
            </Tooltip>
          </button>
        )}
        <Menu as="div" className="relative inline-block text-left mt-px">
          {({ open }) => (
            <>
              <div>
                <Menu.Button className="bg-gray-100 rounded-full flex items-center  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span className="sr-only">Open options</span>
                  <Tooltip title="More">
                    <span>
                      <DotsVerticalIcon className="h-5 w-5 configmoreIcon" aria-hidden="true" />
                    </span>
                  </Tooltip>
                </Menu.Button>
              </div>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm w-full text-left'
                          )}
                          onClick={() => {
                            getVariableDetails(variable);
                          }}
                        >
                          Details
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    );
  };

  const { isEditable, hasFullAccess } = React.useMemo(
    () => ({
      isEditable: window.permission?.isEditAccess('configuration'),
      hasFullAccess: window.permission?.isFullAccess('configuration'),
    }),
    []
  );

  const closeModal = (value) => {
    if (openVariableDetailsModal) {
      setOpenVariableDetailsModal(false);
    }
    if (openCreateVariableModal) {
      setOpenCreateVariableModal(value);
    }
    if (openVariableDeleteModal) {
      setOpenVariableDeleteModal(false);
    }
    setEditVariableData(null);
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  // TODO :: handle Search filter :: START

  const [userSearched, setuserSearched] = useState('');
  const [data, setData] = useState([]);
  let [isHighlight, setIsHighlight] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');

  useEffect(() => {
    setData(variablesData);
    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [variablesData]);

  useEffect(() => {
    if (openSearch && !userSearched) {
      setData(variablesData);
      setSearchTextHighlight('');
      setIsHighlight(!isHighlight);
    }
  }, [userSearched]);

  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let [openSearch, setOpenSearch] = useState(false);
  let handleSearchClose = () => {
    setOpenSearch(false);
    setuserSearched('');
    setSearchTextHighlight('');
    setData(variablesData);
    setIsHighlight(!isHighlight);
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        const filtertedData = variablesData.filter(({ name, modifiedByUname }) => {
          return isValueIncludes(e, name) || isValueIncludes(e, modifiedByUname);
        });
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
        setData(filtertedData);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  // TODO :: handle Search filter :: END

  const columns = React.useMemo(
    () => [
      {
        Header: 'NAME',
        accessor: 'name',
        width: '20%',
        Cell: (e) => (
          <Tooltip title={e.value} placement="top">
            <div
              className="text-blue-700 cursor-pointer path-trimmer"
              onClick={() => getVariableDetails(e.row.original)}
            >
              <HighlightText text={e?.value} highlight={searchTextHighlight} />
            </div>
          </Tooltip>
        ),
      },
      {
        Header: 'VALUE',
        accessor: 'value',
        width: '15%',
        Cell: (e) => (
          <Tooltip title={e?.row?.original?.masked ? '' : e.value} placement="top">
            <span className="text-gray-700 fontPoppinsRegularMd lengthyChar">
              {isEmptyValue(e.value) ? 'NULL' : e.row.original?.masked ? getMaskedString(e.value) : e.value}
            </span>
          </Tooltip>
        ),
      },
      // {
      //   Header: "STATE",
      //   accessor: "state",
      //   width: "20%",
      // },
      // {
      //   Header: "VERSION",
      //   accessor: "version",
      //   width: "15%",
      // },
      {
        Header: 'MODIFIED BY',
        accessor: 'modifiedByUname',
        width: '15%',
        Cell: (e) => (
          <Tooltip title={e.value} placement="top">
            <div
              onClick={() => {
                if (e.value !== '--') {
                  getUserDetails(e.row.original.modifiedBy);
                }
              }}
              className="text-blue-700 cursor-pointer path-trimmer"
            >
              <HighlightText text={e?.value} highlight={searchTextHighlight} />
            </div>
          </Tooltip>
        ),
      },
      {
        Header: 'ACTIONS',
        accessor: 'actions',
        width: '15%',
        Cell: (e) => menuData(e.row.original),
      },
    ],
    [isHighlight]
  );

  useEffect(() => {
    if (reload) {
      getVariables();
    }
    setReload(false);
  }, [reload]);
  let activeTab = props.location.pathname.split('/')[3];

  const [tabsList] = useState([
    {
      name: 'Project Environment Variables',
      pathUrl: `/configuration/Configurationvariables/Projectenvironmentvariable`,
      selectedName: 'Projectenvironmentvariable',
    },
    {
      name: 'Global Variables',
      pathUrl: `/configuration/Configurationvariables/Global Variables`,
      selectedName: 'Global Variables',
    },
  ]);
  const renderTabs = () => {
    return (
      <div>
        <ul className="navBar text-blue-600 flex flex-row primary-tab">
          {tabsList.map((tab, index) => {
            return (
              <li key={tab.name}>
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    props.history.push(tab.pathUrl);
                  }}
                  className={`${activeTab === tab.selectedName ? 'selected_tab_style' : ''}`}
                >
                  {tab.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };
  return (
    <div className="page-table-body">
      <div className="alert-position-style">
        <AlertContatiner></AlertContatiner>
      </div>

      {renderTabs()}
      <div className="content_area_style">
        <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
          <label className="project_label text-sm flex-auto">Project Environment Variables</label>
          <div className="pr-4">
            <div className="flex flex-row ml-32">
              <div className="ml-auto pl-4 flex justify-center items-center">
                <SearchBar
                  open={openSearch}
                  searchValue={userSearched}
                  handleSearch={handleSearch}
                  onSearchClose={handleSearchClose}
                  handleSearchOpen={handleSearchOpen}
                  enterKeyHandler={handleSearchSubmit}
                  disable={isEmptyValue(variablesData)}
                />
                {isEditable && (
                  <button
                    onClick={() => {
                      setOpenCreateVariableModal(true);
                    }}
                    className="ml-3 primary-btn"
                    disabled={defaultStepGroup ? (defaultStepGroup === 'false' ? false : true) : false}
                  >
                    + Variable
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="table-height overflow-auto" id="journal-scroll">
          <ReactTable data={data} columns={columns} tableHeightClass={'main-table-height'} />
          {isLoading ? (
            <CommonLoader />
          ) : isEmptyValue(variablesData) ? (
            <div className="fontPoppinsRegularMd no-steps">
              {isEditable && (
                <div className="">
                  <p className="font-bold mb-4">Quick Start</p>
                  <p className="mb-4">
                    <span className="font-bold">Step 1 :</span> Click
                    <button
                      onClick={() => {
                        setOpenCreateVariableModal(true);
                      }}
                      className="ml-3 mr-3 primary-btn"
                      disabled={defaultStepGroup ? (defaultStepGroup === 'false' ? false : true) : false}
                    >
                      + Variable
                    </button>
                    to start adding the Variables
                  </p>
                </div>
              )}
            </div>
          ) : (
            <>{openSearch && isEmptyValue(data) && <NoResultsFound className="mt-14" />}</>
          )}
        </div>

        {/* modal to create and edit variable */}
        {openCreateVariableModal ? (
          <CreateVariable
            MyAlert={MyAlert}
            data={editVariableData}
            closeModal={closeModal}
            reload={reloadVariables}
            variableType={'PROJECT_ENVIRONMENT'}
          />
        ) : null}
        {/* modal for delete variable */}
        {openVariableDeleteModal ? (
          <DeleteVariable
            closeModal={closeModal}
            handleDelete={deleteProjectEnvVariable}
            reload={reloadVariables}
            dataObj={{ type: 'Variable', data: editVariableData }}
          />
        ) : null}
        {/* modal for user details */}
        {openUserDetailsModal ? <UserDetailsModal data={userDetails} closeModal={closeUserDetailsModal} /> : null}
        {/* modal for variable details */}
        {openVariableDetailsModal ? <VariablesDetailsModal closeModal={closeModal} data={editVariableData} /> : null}
      </div>
    </div>
  );
};

export default withRouter(ProjectEnv);

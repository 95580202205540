import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  getSingleExecutionDataReq,
  getSingleExecutionDetailsReq,
  reExecuteSuiteReq,
  terminateExecutionSuiteReq,
  getExecutionTreeExpandAllResultReq,
  getExecutionTreeResultSyncReq,
} from '@api/api_services';
import TableTree from '@pages/common/table_tree/table_tree';
import { SearchOutlined, Sync } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { getFormattedTextStatus, textColor } from '@pages/results/result';
import CreateEditSuite from '@pages/execution/suites/create-edit-suit';
import { CommonLoader } from '@src/pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { getIDB, setIDB } from '@src/util/localForage_idb_controller';
import {
  executionDashboardInfo,
  partialResultPageStatuses,
} from '@src/pages/test-development/execution-dashboard/execution-dashboard-util';
import { getTruncatedText } from '@src/util/common_utils';
import TerminateWarningComponent from '@src/pages/test-development/execution-dashboard/modals/terminate-warning-component';
import { CircularProgressWithLabel, useCircularProgress } from '@src/common/CircularProgressContainer';

export const getStatusTypeForPending = (execData) => {
  let status;
  const clientStatusMessage = execData?.clientStatus?.clientMessage?.message?.toLowerCase();
  if (!clientStatusMessage) {
    status = 'Checking...';
  } else if (
    clientStatusMessage.includes('execution is already triggered on this machine') ||
    clientStatusMessage.includes('there are other execution requests pending on this machine')
  ) {
    status = 'Queued';
  } else if (
    clientStatusMessage.includes('this license is limited to') &&
    clientStatusMessage.includes('parallel runs, there are already')
  ) {
    status = 'Exceeded';
  } else {
    status = execData?.value;
  }
  return status;
};

const ModuleDetailsPage = ({ MyAlert }) => {
  const selectedExecutionId = window.location.pathname.split('/')[5];

  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [executionData, setExecutionData] = useState({});
  const [allTreeRootData, setAllTreeRootData] = useState({});
  let [isLoading, setIsLoading] = useState(true);
  let [treeData, setTreeData] = useState([]);
  let [suiteDetails, setSuiteDetails] = useState();

  let [rootData, setRootData] = useState([]);
  let [isExpandAll, setIsExpandAll] = useState(false);
  let renderTree;
  const history = useHistory();
  let [resultStatus, setResultStatus] = useState({});
  const [screenMode, setScreenMode] = useState('moduleSpecificPage');
  const [isSyncDisabled, setIsSyncDisabled] = useState(false);
  const [tabData, setTabData] = useState([]);
  const [value, setValue] = useState(0);
  const [exeResponse, setExeResponse] = useState([]);
  const [tabRunId, setTabRunId] = useState('');
  let machineNameArray = [];
  let selectedMechianObj;
  selectedMechianObj = machineNameArray?.[value];
  machineNameArray = [];
  executionData?.suite?.machines?.selectedMachines?.forEach((macInstances) =>
    macInstances?.machineInstances?.forEach((retMacObj) => {
      machineNameArray.push(retMacObj);
    })
  );
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [selectedExeObject, setSelectedExeObject] = useState({});
  const execResponseDataRef = useRef(null);
  const { suite, executionStatus = '', resultStatus: execResultStatus = '' } = executionData || {};

  const { progress, startCircularProgress } = useCircularProgress(`sequential-${selectedExecutionId}`);

  useEffect(() => {
    getSingleExecutionData('', 0);
  }, []);

  useEffect(() => {
    let timerId = null;
    if (isSyncDisabled) {
      timerId = setTimeout(() => {
        setIsSyncDisabled(false);
      }, process.env.REACT_APP_SUITE_EXECUTION_INTERVAL || 10000);
    }
    return () => clearTimeout(timerId);
  }, [isSyncDisabled]);

  function getExecutionData() {
    getSingleExecutionDataReq(selectedExecutionId)
      .then((results) => {
        const response = results?.data?.responseObject;
        setExecutionData((d) => response);
        if (response?.suite.selectedModulesAndScripts[0]?.children?.length) {
          setSuiteDetails(response.suite);
          let tempArray = response?.suite.selectedModulesAndScripts[0]?.children;
          tempArray.forEach((obj) => {
            if (obj?.checkbox) {
              obj.checkbox = false;
            }
          });
        }
        setTreeData(response?.suite.selectedModulesAndScripts[0]?.children);
      })
      .catch((error) => {
        console.log(error);
      });
    return [];
  }

  async function getSingleExecutionData(runId, index) {
    const sequentialTabRunId = runId || tabRunId;
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
      isBulkLoad: true,
    });
    setIsLoading(true);
    getSingleExecutionDataReq(selectedExecutionId)
      .then(async (results) => {
        const response = results?.data?.responseObject;
        setExecutionData((d) => response);
        if (response) {
          let machineInstaces = [];
          let selectedMacArray = response?.suite?.machines?.selectedMachines;
          if (selectedMacArray) {
            selectedMacArray?.forEach((macObj) => {
              macObj?.machineInstances.forEach((macInstances) => {
                for (let tabData = 0; tabData < macInstances?.numberOfRuns; tabData++) {
                  machineInstaces.push(macInstances);
                }
              });
            });
            setTabData(machineInstaces);
          }
        }
        if (response) {
          let clientExist;
          const seqTabRunId =
            runId || tabRunId || response?.fancyTreeData?.responseObject?.executionResponses[0]?.runId;
          setTabRunId(seqTabRunId);
          if (response.suite) setSuiteDetails(response.suite);
          if (response?.executionStatus === 'Completed') {
            if (response?.fancyTreeData) {
              if (response?.fancyTreeData.responseObject.executionResponses) {
                if (seqTabRunId) {
                  clientExist = response?.fancyTreeData?.responseObject?.executionResponse.find(
                    (tempObj) => tempObj.runId === seqTabRunId
                  );
                } else {
                  clientExist = response?.fancyTreeData?.responseObject?.executionResponse.find(
                    (tempObj) => tempObj?.clientId === selectedMechianObj?.clientId
                  );
                }
                if (clientExist) {
                  if (clientExist?.children?.[0].children) {
                    let tempArray = clientExist?.children[0].children;
                    tempArray.forEach((obj) => {
                      if (obj?.checkbox) {
                        obj.checkbox = false;
                      }
                    });
                    setTreeData(tempArray);
                  }
                  setAllTreeRootData(clientExist?.children?.[0]);
                }
              }
              setTimeout(() => {
                resetProgress();
                setIsLoading(false);
              }, 100);
            } else {
              resetProgress();
              startOrResumeProgress({
                stopAt: 100,
                intervalDuration: 80,
              });
              setIsLoading(true);
              getModuleResultTree(selectedExecutionId, false, runId);
            }
          } else if (
            ['Running', 'Terminated', 'Fetching Results', 'Processing Result'].includes(response?.executionStatus)
          ) {
            if (response?.suite.selectedModulesAndScripts[0]?.children?.length) {
              setSuiteDetails(response.suite);
              let tempArray = response?.suite.selectedModulesAndScripts[0]?.children;
              tempArray.forEach((obj) => {
                if (obj?.checkbox) {
                  obj.checkbox = false;
                }
              });
            }
            setTreeData(response?.suite.selectedModulesAndScripts[0]?.children);
            try {
              const res = await getExecutionTreeResultSyncReq(selectedExecutionId);
              const resp = res?.data?.responseObject;
              const responseValue = response?.fancyTreeData?.responseObject?.executionResponses?.map(
                (resValue) => resValue?.children[0]
              );
              let clientExist;
              if (resp?.executionResponses?.length) {
                if (index > -1) {
                  clientExist = resp?.executionResponses[index];
                } else {
                  if (sequentialTabRunId) {
                    clientExist = resp?.executionResponses.find((tempObj) => tempObj.runId === sequentialTabRunId);
                  } else {
                    clientExist = resp?.executionResponses.find(
                      (tempObj) => tempObj?.clientId === selectedMechianObj?.clientId
                    );
                  }
                }
                if (clientExist) {
                  setResultStatus(clientExist?.executionStatusObject);
                } else {
                  setResultStatus();
                }
              }

              if (resp) {
                if (response?.suite.selectedModulesAndScripts[0]?.children) {
                  let tempArray = response?.suite.selectedModulesAndScripts[0]?.children;
                  tempArray.forEach((obj) => {
                    if (obj?.checkbox) {
                      obj.checkbox = false;
                    }
                  });
                  setTreeData(tempArray);
                }
                if (resp?.executionResponses?.children) {
                  setAllTreeRootData(responseValue?.children[0]);
                } else if (response?.suite?.selectedModulesAndScripts[0]) {
                  setAllTreeRootData(response?.suite?.selectedModulesAndScripts[0]);
                }
              }
            } catch (error) {
              console.error('result sync error:', error);
            }
            getModuleResultTree(selectedExecutionId, true, runId);
          } else {
            if (response?.suite.selectedModulesAndScripts[0]?.children) {
              let tempArray = response?.suite.selectedModulesAndScripts[0]?.children;
              tempArray.forEach((obj) => {
                if (obj?.checkbox) {
                  obj.checkbox = false;
                }
              });
              setTreeData(tempArray);
              setAllTreeRootData(response?.suite.selectedModulesAndScripts[0]);
            }
            resetProgress();
            setIsLoading(false);
          }
        }
      })
      .catch((error) => {
        console.error('GET_EXECUTION_RESULT_TREE :', error);
        resetProgress();
        setIsLoading(false);
      });
    return [];
  }

  const getModuleResultTree = (selectedExecutionId, updateState, runId) => {
    stopProgress();
    startOrResumeProgress({
      stopAt: 90,
      isBulkLoad: true,
    });
    setIsLoading(true);
    getExecutionTreeExpandAllResultReq(selectedExecutionId)
      .then((res) => {
        localStorage.setItem('sequential_runId', JSON.stringify(res?.data?.responseObject?.executionResponses));
        const resp = res?.data?.responseObject;
        const seqTabRunId = runId || tabRunId || resp?.executionResponses[0]?.runId;
        setExeResponse(resp?.executionResponses);
        setTabRunId(seqTabRunId);
        let clientExist;
        if (resp) {
          execResponseDataRef.current = resp;
          if (resp?.executionResponses?.length) {
            if (seqTabRunId) {
              clientExist = resp?.executionResponses.find((tempObj) => tempObj.runId === seqTabRunId);
            } else {
              clientExist = resp?.executionResponses.find(
                (tempObj) => tempObj?.clientId === selectedMechianObj?.clientId
              );
            }
            if (clientExist) {
              if (clientExist?.children?.[0].children) {
                let tempArray = clientExist?.children[0].children;
                tempArray.forEach((obj) => {
                  if (obj?.checkbox) {
                    obj.checkbox = false;
                  }
                });
                if (!updateState) {
                  setTreeData(tempArray);
                }
              }
              if (!updateState) {
                setAllTreeRootData(clientExist?.children?.[0]);
              }
            }
          }
          setTimeout(() => {
            resetProgress();
            setIsLoading(false);
          }, 100);
        }
      })
      .catch((error) => {
        console.error('EXECUTION_TREE_EXPAND_ALL :', error);
        resetProgress();
      });
  };
  const onCheckedNodes = (nodes) => {};

  function onNodeSelected() {
    console.log(`clicked`);
  }
  const onStatusClick = async (nodeObj) => {
    let exec_id;
    let _resTreeData;

    let execResponseDataCopy = execResponseDataRef?.current;
    if (execResponseDataCopy?.fancyTreeData?.responseObject) {
      _resTreeData = execResponseDataCopy?.fancyTreeData.responseObject.executionResponses[0]?.children[0];
      exec_id = execResponseDataCopy?.fancyTreeData.responseObject.executionResponses[0]?.executionId;
    } else if (execResponseDataCopy?.executionResponses && execResponseDataCopy?.executionResponses[0]) {
      _resTreeData = execResponseDataCopy?.executionResponses[0]?.children[0];
      exec_id = execResponseDataCopy?.executionResponses[0]?.executionId;
      if (!_resTreeData.hasOwnProperty('storageDetails') && execResponseDataCopy.executionResponses[0].storageDetails) {
        _resTreeData['storageDetails'] = execResponseDataCopy.executionResponses[0].storageDetails;
      }
    }
    if (_resTreeData && suiteDetails) {
      const exec_name = suiteDetails.name;
      const multiple = suiteDetails.machines.multiple;
      if (!_resTreeData.hasOwnProperty('storageDetails')) {
        if (suiteDetails.storageDetails) {
          _resTreeData['storageDetails'] = suiteDetails.storageDetails;
        }
      }
      if (_resTreeData) {
        _resTreeData['executionId'] = exec_id;
        setIDB('tree-data', _resTreeData);
      }
      if (nodeObj.data.folder || nodeObj.isRootNode) {
        if (nodeObj.data.folder) {
          //if root status clicked
          history.push({
            pathname: `/execution/suite/${exec_name}/execution dashboard/Root Module/${nodeObj.node.title}/results`,
            search: `?executionId=${exec_id}&multiple=${multiple}&runId=${tabRunId}`,
          });
        } else {
          //if a module status clicked
          const node = nodeObj.node;
          var dict = node.toDict(true, function (dict, node) {
            const data = { ...dict.data };
            delete dict.partsel;
            delete dict.selected;
            delete dict.data;
            Object.assign(dict, data);
          });
          setIDB('sub-tree-data', dict);
          if (nodeObj.node.data.status !== 'SKIP') {
            if (nodeObj?.node?.data?.status === 'N/A' || nodeObj?.node?.data?.status === 'Aborted') return;
            history.push({
              pathname: `/execution/suite/${exec_name}/execution dashboard/Root Module/${nodeObj.node.title}/results`,
              search: `?executionId=${exec_id}&id=${nodeObj.data.id}&multiple=${multiple}&runId=${tabRunId}`,
            });
          }
        }
      } else {
        if (nodeObj.node.type === 'Script') {
          setIDB('sub-tree-data', treeData[0]);
          let _treeData = await getIDB('tree-data');
          if (!_treeData) {
            getModuleResultTree(exec_id);
          }
          /** If current localStorage tree data executionId doesn't match with the execution tree id,
           *   will update the localStorage*/
          if (_treeData && _treeData.executionId !== exec_id) {
            getModuleResultTree(exec_id);
          }
          const scriptData = [nodeObj.data];
          const clientId = _resTreeData.selectedSystem.clientId;
          setIDB('script-data', scriptData);
          let __status = nodeObj.node.data.status;
          if (!__status && resultStatus) {
            __status = resultStatus?.[nodeObj?.data?._key];
          }
          if (__status !== 'SKIP') {
            if (__status === 'N/A' || __status === 'Aborted') return;
            history.push({
              pathname: `/execution/suite/${exec_name}/execution dashboard/Root Module/${nodeObj.node.title}/script/results`,
              search: `?id=${nodeObj.node.key}&moduleId=${nodeObj?.data?.parentId}&executionId=${exec_id}&clientId=${clientId}&multiple=${multiple}&runId=${tabRunId}`,
            });
          }
        }
      }
    }
  };

  const LABELS = {
    EXPAND_ALL: 'Expand All',
    COLLAPSE_ALL: 'Collapse All',
    SHOW_ALL_ELEMENTS: 'Show all scripts',
    HIDE_ALL_ELEMENTS: 'Hide all scripts',
    SHOW_PRE_POST_CONDITION: 'Show Pre/Post Conditions',
    HIDE_PRE_POST_CONDITION: 'Hide Pre/Post Conditions',
  };

  const colDefs = [
    {
      field: 'title',
      title: 'MODULE',
      class: 'title',
      isTitle: true,
      width: `calc(100% - 550px)`,
      folderTitleWidth: `calc(100% - 670px)`,
      render: (nodeObj) => {
        return <></>;
      },
    },

    {
      field: 'scriptType',
      title: 'TYPE',
      class: 'Type truncate',
      width: '120px',
      render: (nodeObj) => {
        return <span className="text-xs table-non-link-color-common">{nodeObj?.data?.scriptType}</span>;
      },
    },
    {
      field: 'status',
      title: 'STATUS',
      class: 'status',
      width: '100px',
      render: (nodeObj) => {
        let _status;
        if (nodeObj?.data?.status && nodeObj?.data?.status !== 'disabled' && nodeObj?.data?.status !== 'enabled')
          _status = nodeObj?.data?.status;
        if (!_status && resultStatus) {
          _status = resultStatus?.[nodeObj?.data?.key];
          if (!_status) _status = resultStatus?.[nodeObj?.data?._key];
        }
        return (
          <>
            {_status ? (
              <span
                className={`${_status === 'N/A' || (nodeObj?.data.type === 'Script' && _status === 'Aborted') ? '' : 'cursor-pointer'}  ${textColor(_status)}`}
                name="status"
                onClick={
                  _status.toLowerCase() !== 'skip' || _status.toLowerCase() !== 'n/a'
                    ? () => {
                        if (!nodeObj.isRootNode) {
                          if (nodeObj?.data.type === 'Script' && nodeObj?.data?.status === 'Aborted') {
                          } else {
                            onStatusClick(nodeObj);
                          }
                        }
                      }
                    : ''
                }
              >
                {getFormattedTextStatus(_status)}
              </span>
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      field: 'modifiedByUname',
      title: 'MODIFIED BY',
      class: 'modified-by',
      width: '200px',
      render: (nodeObj) => {
        return (
          <div>
            {!nodeObj.isRootNode && (
              <span className="table-non-link-color-common">{nodeObj?.data?.modifiedByUname}</span>
            )}
          </div>
        );
      },
    },
  ];

  if (isLoading) {
    renderTree = <CommonLoader />;
  } else if (treeData && treeData.length > 0) {
    renderTree = (
      <TableTree
        executionData = {executionData}
        isExecutionTree={true}
        data={treeData}
        rootData={allTreeRootData}
        colDefs={colDefs}
        expandAll={isExpandAll}
        hideElements={false}
        hideConditions={true}
        conditions={[
          { key: 'title', value: 'Preconditions' },
          { key: 'title', value: 'Postconditions' },
        ]}
        hideConditionBtn={false}
        labels={LABELS}
        onNodeSelected={onNodeSelected}
        id="module-details-page"
      />
    );
  } else {
    renderTree = (
      <div>
        <div>
          <TableTree
            executionData = {executionData}
            data={[]}
            rootData={rootData}
            colDefs={colDefs}
            showCheckbox={false}
            onCheckedNodes={onCheckedNodes}
            expandAll={isExpandAll}
            hideConditions={true}
            conditions={[
              { key: 'title', value: 'Preconditions' },
              { key: 'title', value: 'Postconditions' },
            ]}
            hideConditionBtn={false}
            labels={LABELS}
            onNodeSelected={onNodeSelected}
            id="module-details-page"
          />
        </div>
        <div className="overflow-y-auto h-82">
          <div className=" fontPoppinsRegularMd mt-28 no-steps">
            <div classname="create-set-layout">
              <div className="qucBtn">
                <label className="quick-start-style px-1  ">No Data Available </label>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function TerminateExecution(id) {
    terminateExecutionSuiteReq(id).then((results) => {
      const { message = '', status = '' } = results?.data;
      if (status === 'SUCCESS') {
        const { name } = executionData || {};
        const execName = getTruncatedText(name, 30);
        MyAlert.success(`${execName} execution terminated successfully`);
        getSingleExecutionData();
      } else {
        if (message.includes('is completed')) {
          MyAlert.info(message);
        } else {
          MyAlert.warning(message);
        }
      }
    });
  }

  function executeFunction(executionData, deleteSpillOverMemory) {
    reExecuteSuiteReq(executionData?._id, deleteSpillOverMemory).then((results) => {
      const response = results?.data?.responseObject;
      if (response) {
        history.push(`/execution/suite/${executionData?.name}/suite-dashboard?suiteId=${executionData?.suiteId}`);
      } else {
        console.error(results?.data?.message);
      }
    });
  }

  function reRunFailed(execution) {
    getSingleExecutionDetailsReq(execution?._id).then((results) => {
      const response = results.data.responseObject;
      if (response) {
        let treeData = response?.executionResponses[0].executionStatusObject;
        let selectedModuleData = execution?.suite?.selectedModulesAndScripts;
        setPreselectedValue(selectedModuleData[0].children, treeData, execution);
      }
    });
  }

  const onStatus = (nodeObj) => {
    if (nodeObj && nodeObj.suite.name && nodeObj._id) {
      history.push(
        `/execution/suite/${nodeObj.suite.name}/execution dashboard/Root Module/results?executionId=${nodeObj._id}&multiple=false&runId=${tabRunId}`
      );
    }
  };

  function setPreselectedValue(selectedModuleData, treeData, selectedObj) {
    const failedObjectList = [];
    if (selectedModuleData.length) {
      selectedModuleData.forEach((treeObj, index) => {
        if (treeObj.folder) {
          if (treeData[treeObj.key] === 'FAIL') {
            treeObj['children'] = filterFailedScripts(treeObj?.children, treeData, selectedObj);
            failedObjectList.push(treeObj);
          }
        }
      });
      if (failedObjectList?.length) {
        failedObjectList.forEach((obj) => {
          if (obj?.checkbox) {
            obj.checkbox = false;
          }
        });
      }
      selectedObj.suite.selectedModulesAndScripts[0].children = failedObjectList;
      localStorage.setItem('selectedSuite', JSON.stringify(selectedObj));
      localStorage.setItem('rerun', JSON.stringify(true));
      setScreenMode('rerunFailed');
      history.push(
        `/execution/suite/${selectedObj?.name}/suite-dashboard/${selectedObj?._id}?executionId=${selectedObj?._id}&suiteId=${selectedObj?.suiteId}&&detailsPage=moduleDetails`
      );
    }
  }

  function filterFailedScripts(scriptArray, treeData, selectedObj) {
    let moduleData = [];
    if (scriptArray && scriptArray.length) {
      scriptArray.forEach((obj, index) => {
        if (treeData[obj.key] === 'FAIL') {
          if (obj?.folder) {
            obj['children'] = filterFailedScripts(obj?.children, treeData, selectedObj);
            moduleData.push(obj);
          } else {
            moduleData.push(obj);
          }
        }
      });
      return moduleData;
    }
  }

  const handleTab = (runId, newValue) => {
    if (runId !== tabRunId) {
      setTabRunId(runId);
      setValue(newValue);
      selectedMechianObj = machineNameArray?.[newValue];
      getSingleExecutionData(runId, newValue);
    }
  };

  const getSyncStatusIcon = (syncClasses = '') => {
    return progress === 0 ? (
      <Tooltip title="Sync Status" placement="top">
        <Sync
          fontSize="small"
          className={`mr-3 ${syncClasses}`}
          onClick={() => {
            if (!isSyncDisabled) {
              startCircularProgress();
              setIsSyncDisabled(true);
              getSingleExecutionData();
            }
          }}
        />
      </Tooltip>
    ) : (
      <CircularProgressWithLabel value={progress} />
    );
  };

  return (
    <div>
      {screenMode === 'moduleSpecificPage' ? (
        <div className="content_area_style_noTab_screen overflow-auto" id="journal-scroll">
          <div className="content_area_header_style">
            <label className="main-header-label float-left ">
              {getTruncatedText(suite?.name, 40)}

              {['Running', 'Fetching Results', 'Processing Result'].includes(executionStatus) ? (
                <span className="rounded-xl text-xs bg-blue-100 text-blue-700 px-2 py-0.5 ml-3">{executionStatus}</span>
              ) : executionStatus === 'Pending' ? (
                <>
                  <span className={`bg-yellow-400 text-xs rounded-xl text-white px-2 py-0.5 ml-3`}>
                    {getStatusTypeForPending(executionData)}
                  </span>
                  {getStatusTypeForPending(executionData) === 'Queued' && executionDashboardInfo(executionData)}
                </>
              ) : executionStatus === 'Terminated' ? (
                <>
                  {execResultStatus ? (
                    <span
                      className="text-xs rounded-xl bg-red-600 text-white px-2 py-0.5 ml-3"
                      onClick={() => onStatus(executionData)}
                    >
                      {executionStatus}
                    </span>
                  ) : (
                    <>
                      <span className="text-xs rounded-xl bg-red-600 text-white px-2 py-0.5 ml-3 opacity-50">
                        {executionStatus}
                      </span>{' '}
                      {executionDashboardInfo(executionData)}
                    </>
                  )}
                </>
              ) : executionStatus === 'Completed' && execResultStatus === 'PASS' ? (
                <span
                  className="text-xs rounded-xl bg-green-600 text-white px-2 py-0.5 ml-3 cursor-pointer"
                  onClick={() => onStatus(executionData)}
                >
                  Passed
                </span>
              ) : (executionStatus === 'Completed' && execResultStatus === 'FAIL') ||
                execResultStatus === 'Terminated' ? (
                <span
                  className="text-xs rounded-xl bg-red-600 text-white px-2 py-0.5 ml-3 cursor-pointer"
                  onClick={() => onStatus(executionData)}
                >
                  {execResultStatus === 'FAIL' ? 'Failed' : execResultStatus}
                </span>
              ) : executionStatus === 'Cancelled' ? (
                <span className="text-xs rounded-xl bg-gray-100 text-gray-800 px-2 py-0.5 ml-3">{executionStatus}</span>
              ) : execResultStatus === 'WARNING' ? (
                <span
                  className="text-xs rounded-xl warning-status-exe text-white px-2 py-0.5 ml-3 cursor-pointer"
                  onClick={() => onStatus(executionData)}
                >
                  Warning
                </span>
              ) : execResultStatus === 'Aborted' ? (
                <span
                  className="text-xs rounded-xl bg-red-600 text-white px-2 py-0.5 ml-3 cursor-pointer"
                  onClick={() => onStatus(executionData)}
                >
                  Aborted
                </span>
              ) : execResultStatus === 'N/A' && executionStatus !== 'Terminated' ? (
                <span className="text-xs rounded-xl bg-red-600 text-white px-2 py-0.5 ml-3 cursor-pointer">N/A</span>
              ) : partialResultPageStatuses.includes(executionStatus) ? (
                <span className={`bg-yellow-400 text-xs rounded-xl text-white px-2 py-0.5 ml-3`}>
                  {executionStatus}
                </span>
              ) : null}
            </label>
            <div className="flex flex-row float-right mt-1">
              {['Running', 'Fetching Results', 'Pending', 'Processing Result', ...partialResultPageStatuses].includes(
                executionStatus
              ) ? (
                <>
                  {/* <Tooltip title="Search" placement="top">
                                                <SearchOutlined fontSize='small' className="text-gray-500 mr-3 ml-3 cursor-pointer hover:text-blue-700" />
                                            </Tooltip> */}
                  {getSyncStatusIcon()}
                  {['Running', 'Pending', ...partialResultPageStatuses].includes(executionStatus) && (
                    <>
                      <Tooltip title="Terminate" placement="top">
                        <img
                          className="mr-3"
                          src="\assets\execution_logos\terminate.svg"
                          alt="Terminate Execution"
                          onClick={() => {
                            setShowWarningModal(true);
                            setSelectedExeObject(executionData);
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Run" placement="top">
                        <img
                          className="disable_icon mr-3"
                          src="\assets\execution_logos\execution-run.svg"
                          disabled
                          height="20px"
                          width="20px"
                          alt="Terminate Execution"
                          onClick={() => executeFunction(executionData, false)}
                        />
                      </Tooltip>
                    </>
                  )}
                </>
              ) : executionStatus === 'Terminated' ? (
                <>
                  {/* <Tooltip title="Search" placement="top">
                                                    <SearchOutlined fontSize='small' className="text-gray-500 mr-3 ml-3 cursor-pointer hover:text-blue-700" />
                                                </Tooltip> */}
                  {getSyncStatusIcon()}
                  <Tooltip title="Run" placement="top">
                    <img
                      className="mr-3"
                      src="\assets\execution_logos\execution.svg"
                      height="20px"
                      width="20px"
                      alt="Terminate Execution"
                      onClick={() => executeFunction(executionData, false)}
                    />
                  </Tooltip>
                </>
              ) : executionStatus === 'Completed' && execResultStatus === 'PASS' ? (
                <>
                  {/* <Tooltip title="Search" placement="top">
                                                        <SearchOutlined fontSize='small' className="text-gray-500 mr-3 ml-3 cursor-pointer hover:text-blue-700" />
                                                    </Tooltip> */}
                  {getSyncStatusIcon()}
                  <Tooltip title="Run" placement="top">
                    <img
                      className="mr-3"
                      src="\assets\execution_logos\execution.svg"
                      height="20px"
                      width="20px"
                      alt="Terminate Execution"
                      onClick={() => executeFunction(executionData, false)}
                    />
                  </Tooltip>
                </>
              ) : executionStatus === 'Completed' && execResultStatus === 'FAIL' ? (
                <>
                  {/* <Tooltip title="Search" placement="top">
                                                            <SearchOutlined fontSize='small' className="text-gray-500 mr-3 ml-3 cursor-pointer hover:text-blue-700" />
                                                        </Tooltip> */}
                  {getSyncStatusIcon()}
                  <Tooltip title="Run only Failed" placement="top">
                    <img
                      src="\assets\execution_logos\Rerun.svg"
                      className=" cursor-hand mr-3"
                      height="20px"
                      width="20px"
                      onClick={() => reRunFailed(executionData)}
                      alt=""
                    ></img>
                  </Tooltip>
                  <Tooltip title="Run" placement="top">
                    <img
                      className="mr-3"
                      src="\assets\execution_logos\execution.svg"
                      height="20px"
                      width="20px"
                      alt="Terminate Execution"
                      onClick={() => executeFunction(executionData, false)}
                    />
                  </Tooltip>
                </>
              ) : execResultStatus === 'WARNING' ||
                execResultStatus === 'Aborted' ||
                (executionStatus !== 'Terminated' && execResultStatus === 'N/A') ? (
                <>
                  {/* <Tooltip title="Search" placement="top">
                                                                <SearchOutlined fontSize='small' className="text-gray-500 mr-3 ml-3 cursor-pointer hover:text-blue-700" />
                                                            </Tooltip> */}
                  {getSyncStatusIcon()}
                  <Tooltip title="Run" placement="top">
                    <img
                      className="mr-3"
                      src="\assets\execution_logos\execution.svg"
                      height="20px"
                      width="20px"
                      alt="Terminate Execution"
                      onClick={() => executeFunction(executionData, false)}
                    />
                  </Tooltip>
                </>
              ) : null}
            </div>
          </div>
          <div className="dashboard_tree_height" id="journal-scroll">
            {tabData?.length ? (
              <Box sx={{ bgcolor: '#f0f3f9', paddingLeft: '9px' }} className="mb-1">
                <Tabs
                  value={value}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label="scrollable force tabs example"
                >
                  {exeResponse?.map((obj, index) => {
                    return (
                      <Tab
                        className="tab-font-style fontPoppinsRegularMd"
                        onClick={() => {
                          handleTab(obj?.runId, index);
                        }}
                        label={obj?.machine}
                      />
                    );
                  })}
                </Tabs>
              </Box>
            ) : null}
            {renderTree}
          </div>
        </div>
      ) : screenMode === 'rerunFailed' ? (
        <div className="content_area_style overflow-auto" id="journal-scroll">
          <CreateEditSuite
            suiteObj={executionData?.suite}
            rerunMode={true}
            setScreenMode={setScreenMode}
            getAllDashboard={getExecutionData}
          ></CreateEditSuite>
        </div>
      ) : null}
      {showWarningModal && (
        <TerminateWarningComponent
          executionData={selectedExeObject}
          setShowWarningModal={setShowWarningModal}
          terminateExecution={TerminateExecution}
          MyAlert={MyAlert}
          actionKey="Terminate"
        />
      )}
    </div>
  );
};

export default ModuleDetailsPage;

import { useContext } from 'react';
import { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import Button from '../../Button/Button';
import CommentBoxContainer from '../../CommentBoxContainer/CommentBoxContainer';
import CommentForm from '../../CommentForm/CommentForm';
import DeleteModal from '@src/pages/common/delete_modal';
import { editCommentstyle } from '../Comment';
import ThreadComment from '../ThreadComment/ThreadComment';
import { commentContext } from '../../../../defect-create-modal';

export const ReplyComment = (props) => {
  let {
    replyCommentData,
    parentCommentId,
    onReplyUpdate,
    onReplyDelete,
    commentsArray,
    onRespond,
    onUpdateThreadComment,
    onDeleteThreadComment,
    detailsView,
    dataPassToParent,
    defectId,
    projectUsers,
    analyticsClasses = false,
  } = props;

  let replyCommentId = replyCommentData.id;
  let replyUserName = replyCommentData.createdByUname;
  let replyCommentText = replyCommentData.description;
  let replyUserId = replyCommentData.createdByUname;
  let replyCommentsThread = replyCommentData?.comments;
  let userCreated = replyCommentData.createdOn;
  let userModified = replyCommentData.modifiedOn;

  let replyCommentsThreadLength = replyCommentsThread?.length;

  const ParentData = {
    parentCommentId: replyCommentId,
    parentCommentUserId: replyUserId,
    parentCommentLength: replyCommentsThreadLength,
  };

  const currentUser = useContext(commentContext);

  let loginUserName = JSON.parse(localStorage.getItem('test-optimize-user')).name;

  const [showReplyEditComment, setShowReplyEditComment] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [reset, setReset] = useState(false);
  const [editedReplyComment, SetEditedReplyComment] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [showReply, setShowReply] = useState(false);

  const handleReplyEdit = () => {
    onReplyUpdate(parentCommentId, replyCommentId, editedReplyComment);
    setReset(true);
    setShowReplyEditComment(false);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDelete = () => {
    onReplyDelete(parentCommentId, replyCommentId);
    setIsOpen(false);
  };

  const showCommentBox = () => {
    setShowReply(!showReply);
  };

  const closeCommentBox = () => {
    setShowReply(false);
  };

  useEffect(() => {
    if (editedReplyComment && editedReplyComment !== replyCommentText && editedReplyComment.trim().length !== 0) {
      setIsDisabled(false);
    }
    return () => {
      setIsDisabled(true);
    };
  }, [editedReplyComment, replyCommentText, isDisabled]);

  return (
    <div className="replyComments">
      <div className="comment-col1">
        <Avatar size={30} name={replyUserName} title={replyUserName} round={true}></Avatar>
        <div className="dividercomments">
          <div className="threadline"></div>
        </div>
      </div>
      <div className="comment-col2">
        <h6 className={analyticsClasses ? 'fontPoppinsRegularSm' : 'fontPoppinsRegularLg'}>
          {replyUserName} {userModified !== '--' ? userModified : userCreated}{' '}
        </h6>
        {showReplyEditComment ? (
          <div style={editCommentstyle}>
            <CommentForm
              id={replyCommentId}
              getData={SetEditedReplyComment}
              isEdit={true}
              setData={replyCommentText}
              isReset={reset}
              setReset={setReset}
              setShowEditComment={setShowReplyEditComment}
              projectUsers={projectUsers}
            ></CommentForm>
            <div className="edit-comment-footer">
              <button
                type="button"
                className="primary-btn"
                onClick={() => setShowReplyEditComment(!showReplyEditComment)}
              >
                {' '}
                Cancel{' '}
              </button>
              <button
                type="button"
                className="ml-3 primary-btn"
                onClick={handleReplyEdit}
                {...(isDisabled ? { disabled: 'disabled' } : '')}
              >
                {' '}
                Update{' '}
              </button>
            </div>
          </div>
        ) : (
          <>
            <p className={analyticsClasses ? 'fontPoppinsRegularXs' : 'fontPoppinsRegularMd'}>{replyCommentText}</p>
            {detailsView ? (
              <div className="comment-footer fontPoppinsRegularSm"></div>
            ) : (
              <div className="comment-footer fontPoppinsRegularSm">
                <Button type="gost" size="sm" onClick={showCommentBox}>
                  {' '}
                  Reply{' '}
                </Button>
                {loginUserName === replyUserId && (
                  <>
                    <Button
                      type="gost"
                      size="sm"
                      className={'margin-left-4'}
                      onClick={() => setIsOpen(true)}
                      disabled={showReply}
                    >
                      {' '}
                      Delete{' '}
                    </Button>
                    <Button
                      type="gost"
                      size="sm"
                      onClick={() => setShowReplyEditComment(!showReplyEditComment)}
                      className={'margin-left-4'}
                      disabled={showReply}
                    >
                      {' '}
                      Edit{' '}
                    </Button>
                  </>
                )}
              </div>
            )}
          </>
        )}{' '}
        {showReply && (
          <CommentBoxContainer
            id={replyUserId}
            replyCommentsLength={replyCommentsThreadLength}
            commentId={replyCommentId}
            userId={currentUser}
            onCancel={closeCommentBox}
            commentsArray={commentsArray}
            onRespond={onRespond}
            dataPassToParent={dataPassToParent}
            defectId={defectId}
            projectUsers={projectUsers}
          />
        )}
        {replyCommentsThread?.length > 0
          ? replyCommentsThread.map((replyCommentThread, index) => {
              return (
                <ThreadComment
                  key={index}
                  threadData={replyCommentThread}
                  parentData={ParentData}
                  onUpdateThreadComment={onUpdateThreadComment}
                  onDeleteThreadComment={onDeleteThreadComment}
                  onRespond={onRespond}
                  commentsArray={commentsArray}
                  detailsView={detailsView}
                  projectUsers={projectUsers}
                  analyticsClasses={analyticsClasses}
                ></ThreadComment>
              );
            })
          : ''}
      </div>
      {isOpen && <DeleteModal closeModal={handleClose} method={handleDelete} message={`Comments`} />}
    </div>
  );
};

import React, { useState, useEffect, forwardRef } from 'react';
import Select from 'react-dropdown-select';
import styled from '@emotion/styled';
import GitlabStyles from './gitlab-styles.module.scss';
import cx from 'classnames';
import { Checkbox, Tooltip, Chip } from '@mui/material';
import { getScrollPosition } from '@pages/analytics/common/util';
import { ReactComponent as SearchIcon } from '@assets/analytics/searchIcon-rs.svg';
import { isEmptyValue } from '@src/util/common_utils';

// css constants starts

const SearchAndToggle = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  z-index: 1;
  top: 0;
  background-color: #fbfcfd;

  input {
    margin: 6px 10px 6px;
    line-height: 30px;
    padding: 0 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    :focus {
      outline: none;
      border: 1px solid ${({ color }) => color};
    }
  }
`;

const Item = styled.div`
  display: flex;
  margin: 10px;
  align-items: baseline;
  cursor: pointer;
  border-bottom: 1px dotted transparent;

  ${({ disabled }) =>
    disabled
      ? `
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
`
      : ''}
`;

const ItemLabel = styled.div`
  margin: 5px 10px;
`;

const customStyles = {
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ? '#F0F3F9' : '',
  }),
};

// css constants ends

const dropdownRenderer = ({ props, state, methods }) => {
  const regexp = new RegExp(state.search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
  const options = props.options.filter((item) => regexp.test(item[props.searchBy] || item[props.labelField]));
  return (
    <div>
      <SearchAndToggle>
        <input type="text" value={state.search} onChange={methods.setSearch} placeholder="Search and select..." />
      </SearchAndToggle>
      {options.length === 0 ? (
        <div className="noData pl-5 pt-5 pb-5">No options</div>
      ) : (
        <div className="exe-env-options-dropdown">
          {options.map((option) => {
            return (
              <div key={option[props.labelField]} className="">
                <Item
                  disabled={option.disabled}
                  key={option[props.valueField]}
                  className=""
                  onClick={option.disabled ? null : () => methods.addItem(option)}
                >
                  <Tooltip title={option[props.labelField]} placement="top">
                    <ItemLabel className="text-overflow-style">{option[props.labelField]}</ItemLabel>
                  </Tooltip>
                </Item>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const customContentRenderer = ({ props, state, methods }) => {
  const getCountPill = (values) => {
    if (values?.length > 1) {
      return <span className={GitlabStyles['count-pill-temp']}> +{values.length - 1} More </span>;
    } else {
      return null;
    }
  };

  return (
    <div style={{ overflowWrap: 'anywhere' }}>
      <span className="test-sm">
        {state?.values?.length
          ? props.name === 'mapToJira'
            ? state.values[0].key
            : state.values[0].label
          : props.placeholder}
        {getCountPill(state?.values)}
      </span>
    </div>
  );
};

const contentRenderer = ({ props, state, methods }) => {
  return (
    <div>
      <span className="fontPoppinsRegularMd">
        {state?.values?.length ? (
          state?.values[0]?.label?.length > 34 ? (
            <Tooltip title={state?.values[0]?.label}>
              <span>{state?.values[0]?.label?.slice(0, 30) + '...'}</span>
            </Tooltip>
          ) : (
            state?.values[0]?.label
          )
        ) : (
          <span className="fontPoppinsRegularSm">{props.placeholder}</span>
        )}
      </span>
    </div>
  );
};

const customDropdownRenderer = ({ props, state, methods }) => {
  const regexp = new RegExp(state.search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
  const options = props.options.filter((item) => regexp.test(item[props.searchBy] || item[props.labelField]));
  return (
    <div>
      <SearchAndToggle>
        <input type="text" value={state.search} onChange={methods.setSearch} placeholder="Search and select..." />
      </SearchAndToggle>
      {options.length === 0 ? (
        <div className="noData pl-5 pt-5 pb-5">No options</div>
      ) : (
        <div className="overflow-y-auto" id="journal-scroll">
          <div className="exe-env-options-dropdown">
            {options.map((option) => {
              let isSelected = false;
              isSelected = Boolean(state.values?.find((value) => value.label === option.label));
              return (
                <div key={option[props.labelField]} className="">
                  <Item
                    disabled={option.disabled}
                    key={option[props.valueField]}
                    className=""
                    onClick={option.disabled ? null : () => methods.addItem(option)}
                  >
                    <Tooltip title={option[props.labelField]} placement="top">
                      <ItemLabel className="text-overflow-style">
                        {' '}
                        <Checkbox size="small" color="primary" checked={isSelected} /> {option[props.labelField]}
                      </ItemLabel>
                    </Tooltip>
                  </Item>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
export const SelectDropdown = ({ data, disabled, placeholder, onChange, selectedData, onDropdownOpen, section }) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);

  const handleChange = (values) => {
    if (values?.length > 0 && values[0]?.value !== selectedOption?.[0]?.value) {
      setSelectedOption([...values]);
      onChange(values);
    } else if (section === 'email') {
      onChange(values);
    } else if (values.length === 0 && selectedOption?.[0]?.value) {
      onChange(values);
    }
  };

  useEffect(() => {
    setOptions(data?.length ? [...data] : []);
  }, [data]);
  useEffect(() => {
    setSelectedOption(selectedData?.length ? [...selectedData] : []);
  }, [selectedData]);

  return (
    <Select
      options={options}
      closeOnSelect={false}
      searchable={true}
      backspaceDelete={true}
      className={cx('fontPoppinsRegularMd searchable-dropdown-custom', GitlabStyles['select-dropdown'])}
      values={selectedOption}
      disabled={disabled}
      styles={customStyles}
      dropdownRenderer={(innerProps, innerState, innerMethods) =>
        dropdownRenderer(innerProps, innerState, innerMethods)
      }
      contentRenderer={contentRenderer}
      onChange={(values) => {
        handleChange(values);
      }}
      multi={false}
      placeholder={placeholder || 'Search and select'}
      onDropdownOpen={onDropdownOpen}
    />
  );
};

export const MultiSelectDropdown = ({
  data,
  disabled,
  placeholder,
  handleSelectChange,
  selectedData,
  onDropdownOpen,
}) => {
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (values) => {
    if (JSON.stringify(values) !== JSON.stringify(selectedOptions)) {
      setSelectedOptions([...values]);
      handleSelectChange([...values]);
    }
  };

  useEffect(() => {
    setOptions(data?.length ? [...data] : []);
  }, [data]);
  useEffect(() => {
    setSelectedOptions(selectedData?.length ? [...selectedData] : []);
  }, [selectedData]);

  return (
    <Select
      options={options}
      closeOnSelect={false}
      searchable={true}
      backspaceDelete={true}
      className={cx('fontPoppinsRegularMd searchable-dropdown-custom', GitlabStyles['select-dropdown'])}
      values={selectedOptions}
      disabled={disabled}
      styles={customStyles}
      dropdownRenderer={(innerProps, innerState, innerMethods) =>
        customDropdownRenderer(innerProps, innerState, innerMethods)
      }
      contentRenderer={customContentRenderer}
      onChange={(values) => {
        handleChange(values);
      }}
      multi={true}
      placeholder={placeholder}
      onDropdownOpen={onDropdownOpen}
    />
  );
};

export const MultiSelectSearchableDropdown = forwardRef((props, ref) => {
  const {
    data,
    disabled,
    placeholder,
    handleSelectChange,
    selectedData,
    onDropdownOpen,
    name,
    error,
    setScrollPos,
    onDropdownClose,
    onSearch,
    isSearch,
    setSearchReset = () => {},
    setIsSearch,
    editMode,
    setEditMode,
  } = props;
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchTxt, setSearchTxt] = useState('');
  let [disableMoreCount, setDisableMoreCount] = useState(false);

  React.useEffect(() => {
    if ((isEmptyValue(searchTxt) || searchTxt.trim() === '') && isSearch) {
      setSearchReset(true);
      setIsSearch(false);
    } else {
      setSearchReset(false);
    }
  }, [searchTxt]);

  let handleRemoveId = (_jiraOBj) => {
    if (_jiraOBj?.id) {
      let filterData = selectedData?.filter((val) => val?.id !== _jiraOBj?.id);
      setSelectedOptions(filterData);
      handleSelectChange(filterData, 'remove');
    }
  };

  const customSearchDropdownRenderer = ({ props, state, methods }) => {
    let handleSearch = (e) => {
      setSearchTxt(e.target.value);
    };

    return (
      <div>
        <div className="flex justify-between items-center px-2 border-2 border-gray-300 mx-2 mt-4 rounded-md">
          <input
            type="text"
            value={searchTxt}
            onChange={(e) => handleSearch(e)}
            placeholder="Search and select..."
            className="w-11/12 border-none fontPoppinsRegularMd py-0.5"
            style={{ borderBottom: 'none' }}
          />
          <SearchIcon onClick={(e) => onSearch(searchTxt)} className="mt-0.5 cursor-pointer" />
        </div>
        {options.length === 0 ? (
          <div className="noData pl-5 pt-5 pb-5">No options</div>
        ) : (
          <div
            className="overflow-y-auto max-h-44 pb-2"
            id="journal-scroll"
            ref={ref}
            onScroll={() => getScrollPosition(ref, setScrollPos)}
          >
            <div className="exe-env-options-dropdown mt-4">
              {options.map((option, index) => {
                let isSelected = false;
                if (['mapToJira', 'linkIds'].includes(props.name)) {
                  isSelected = Boolean(state.values?.find((value) => value.key === option.key));
                }
                return (
                  <div key={option[props.labelField] + index} className="h-9">
                    <Item
                      disabled={option.disabled || (props?.name === 'linkIds' && option?.mappedScrip === true)}
                      key={option[props.valueField] + index}
                      className=""
                      onClick={
                        option.disabled || (props.name === 'linkIds' && option.mappedScript === true)
                          ? null
                          : () => methods.addItem(option)
                      }
                    >
                      <Tooltip title={option[props.labelField]} placement="top">
                        <ItemLabel className="text-overflow-style fontPoppinsRegularMd">
                          {' '}
                          <Checkbox
                            sx={{ pr: 0.5, pl: 0, pt: 0, pb: 0 }}
                            size="small"
                            color="primary"
                            checked={isSelected}
                          />{' '}
                          {option[props.labelField]}
                        </ItemLabel>
                      </Tooltip>
                    </Item>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleChange = (values) => {
    if (JSON.stringify(values) !== JSON.stringify(selectedOptions)) {
      setSelectedOptions([...values]);
      handleSelectChange([...values]);
    }
  };

  useEffect(() => {
    setOptions(data?.length ? [...data] : []);
    if (selectedData?.length && editMode) {
      setSelectedOptions(selectedData);
      setEditMode(false);
    } else if (editMode && !selectedData?.length) {
      setSelectedOptions(selectedData);
      setEditMode(false);
    }
    if (selectedData?.length > 5) {
      setDisableMoreCount(false);
    }

    if (isEmptyValue(selectedData)) {
      setSelectedOptions([]);
    }
  }, [data, selectedData]);

  const customContentRendererChip = ({ props, state, methods }) => {
    let expandList = () => {
      methods.dropDown('close');
      setDisableMoreCount(true);
    };

    const getCountPill = (values) => {
      if (values?.length > 5) {
        return disableMoreCount ? null : (
          <span
            className={cx(
              GitlabStyles['more-count-button'],
              'absolute top-0 right-2  h-7 bg-white fontPoppinsRegularXs my-auto '
            )}
            onClick={expandList}
          >
            + {values.length - 5} More
          </span>
        );
      } else {
        return null;
      }
    };

    return (
      <div
        className={
          disableMoreCount
            ? 'overflow-auto w-full max-h-20 '
            : 'overflow-hidden w-full h-7 relative fontPoppinsRegularSm flex items-center'
        }
      >
        {state?.values?.length >= 1
          ? state?.values?.map((val, index) => {
              const isMappedToJira = val?.mappedScript;
              const chipClassNames = cx(GitlabStyles['jira-chip'], 'm-1', {
                [GitlabStyles['chip-disabled']]: isMappedToJira,
              });
              if (disableMoreCount) {
                return (
                  <Chip
                    key={val?.id}
                    className={chipClassNames}
                    variant="outlined"
                    size="small"
                    label={<span className="fontPoppinsRegularSm">{val?.key}</span>}
                    onDelete={() => handleRemoveId(val)}
                  />
                );
              } else if (index <= 4 && disableMoreCount === false) {
                return (
                  <Chip
                    key={val?.id}
                    className={chipClassNames}
                    variant="outlined"
                    size="small"
                    label={<span className="fontPoppinsRegularSm">{val?.key}</span>}
                    onDelete={() => {
                      !isMappedToJira && handleRemoveId(val);
                    }}
                  />
                );
              }
            })
          : props.placeholder}

        {getCountPill(state.values)}
      </div>
    );
  };

  return (
    <Select
      options={options}
      closeOnSelect={false}
      // searchable={true}
      backspaceDelete={false}
      className={cx(
        `fontPoppinsRegularMd ${error ? 'searchable-dropdown-custom-error' : 'searchable-dropdown-custom'}`
      )}
      values={selectedOptions}
      disabled={disabled}
      styles={customStyles}
      dropdownRenderer={(innerProps, innerState, innerMethods) =>
        customSearchDropdownRenderer(innerProps, innerState, innerMethods)
      }
      contentRenderer={customContentRendererChip}
      onChange={(values) => {
        handleChange(values);
      }}
      multi={true}
      name={name}
      placeholder={placeholder}
      onDropdownOpen={onDropdownOpen}
      onDropdownClose={onDropdownClose}
      dropdownPosition="auto"
    />
  );
});

// This file contains Special npl end object constants

export const END_IF_CONDITIONS_NLP = {
    EndIfCondition: {
      _id: 'NLP1405',
      nlpType: 'NLP',
      platform: 'Common',
      packageName: 'com.tyss.optimize.nlp.Nlp',
      nlpName: 'EndIfCondition',
      searchName: 'End If Condition',
      displayName: 'End If Condition',
      toolTip: 'End If Condition',
      description: 'This NLP is used to end if condition',
      returnType: 'void',
      passMessage: 'End If Condition passed',
      failMessage: 'End If Condition failed',
      actualFailedResult: 'N/A',
      stepInputs: [],
    },
    EndElseCondition: {
      _id: 'NLP1396',
      nlpType: 'NLP',
      platform: 'Common',
      packageName: 'com.tyss.optimize.nlp.Nlp',
      nlpName: 'EndElseCondition',
      searchName: 'End Else Condition',
      displayName: 'End Else Condition',
      toolTip: 'End Else Condition',
      description: 'This NLP is used to end else condition',
      returnType: 'void',
      passMessage: 'End else Condition passed',
      failMessage: 'End else Condition failed',
      actualFailedResult: 'N/A',
      stepInputs: [],
    },
    EndElseIfCondition: {
      _id: 'NLP1399',
      nlpType: 'NLP',
      platform: 'Common',
      packageName: 'com.tyss.optimize.nlp.Nlp',
      nlpName: 'EndElseIfCondition',
      searchName: 'End Else If Condition',
      displayName: 'End Else If Condition',
      toolTip: 'End Else If Condition',
      description: 'This NLP is used to end else if condition',
      returnType: 'void',
      passMessage: 'End else If Condition passed',
      failMessage: 'End else If Condition failed',
      actualFailedResult: 'N/A',
      stepInputs: [],
    },
  };
  
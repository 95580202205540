import { useState, useEffect } from 'react';
import { getIndividualMemoryDashboardData } from '@api/api_services';
import IndividualMemoryTypeConsumption from '@pages/dashboard/charts/IndividualMemoryTypeConsumption';
import MemoryTotalConsumptionIndividual from '@pages/dashboard/charts/MemoryTotalConsumptionIndividual';
import ChartCard from '@pages/dashboard/components/ChartCard';
import SectionHeader from '@pages/dashboard/components/SectionHeader';
import DashboardStyle from '@pages/dashboard/styles/dashboard.module.scss';
import cx from 'classnames';
import MemoryConsumeStatisticsIndividual from '@pages/dashboard/charts/MemoryConsumeStatisticsIndividual';
import {
  getLicenseFeatures,
  getCurrentProjectType,
  getAutomationProjectTypes,
  getPrivilege,
} from '@src/util/common_utils';

function MemorySectionIndividualProject({ projectId }) {
  const [apiMemoryIndividualProject, setApiMemoryIndividualProject] = useState();

  const licenseFeatures = getLicenseFeatures();
  const hasAutomation = window.privilege.hasAutomation(licenseFeatures);
  let projectType = getCurrentProjectType();
  let isAutomationProject = getAutomationProjectTypes(getPrivilege())?.includes(projectType);

  useEffect(() => {
    getIndividualMemoryDashboardData(projectId).then((data) => {
      setApiMemoryIndividualProject(data?.data?.responseObject);
    });
  }, [projectId]);

  return (
    <div className="my-3 mt-16">
      <SectionHeader>Memory</SectionHeader>
      <div className="grid grid-cols-12 gap-3 my-2">
        <MemoryTotalConsumptionIndividual individualConsumption={apiMemoryIndividualProject?.individualConsumption} />
        <ChartCard className={cx(DashboardStyle['chart-consume-statistics'], 'col-span-7')}>
          <MemoryConsumeStatisticsIndividual projectId={projectId} />
        </ChartCard>
      </div>
      <div className="flex gap-2">
        <IndividualMemoryTypeConsumption
          data={apiMemoryIndividualProject?.projectDetails}
          name={'Test data Files'}
          usedMemory={apiMemoryIndividualProject?.projectDetails?.[0]?.testDataFiles?.usedMemory || '0 MB'}
          legendAssignedMemory={apiMemoryIndividualProject?.individualConsumption?.assignedMemory || '200 MB'}
          totalRemainingMemory={apiMemoryIndividualProject?.individualConsumption?.remainingMemory || '200 MB'}
        />
        {hasAutomation && isAutomationProject && (
          <IndividualMemoryTypeConsumption
            data={apiMemoryIndividualProject?.projectDetails}
            name={'Screenshots'}
            usedMemory={apiMemoryIndividualProject?.projectDetails?.[0]?.screenShots?.usedMemory || '0 MB'}
            legendAssignedMemory={apiMemoryIndividualProject?.individualConsumption?.assignedMemory || '200 MB'}
            totalRemainingMemory={apiMemoryIndividualProject?.individualConsumption?.remainingMemory || '200 MB'}
          />
        )}

        {hasAutomation && isAutomationProject && (
          <IndividualMemoryTypeConsumption
            data={apiMemoryIndividualProject?.projectDetails}
            name={'Videos'}
            usedMemory={apiMemoryIndividualProject?.projectDetails?.[0]?.videos?.usedMemory || '0 MB'}
            legendAssignedMemory={apiMemoryIndividualProject?.individualConsumption?.assignedMemory || '200 MB'}
            totalRemainingMemory={apiMemoryIndividualProject?.individualConsumption?.remainingMemory || '200 MB'}
          />
        )}

        {hasAutomation && isAutomationProject && (
          <IndividualMemoryTypeConsumption
            data={apiMemoryIndividualProject?.projectDetails}
            name={'Program element'}
            usedMemory={apiMemoryIndividualProject?.projectDetails?.[0]?.programElement?.usedMemory || '0 MB'}
            legendAssignedMemory={apiMemoryIndividualProject?.individualConsumption?.assignedMemory || '200 MB'}
            totalRemainingMemory={apiMemoryIndividualProject?.individualConsumption?.remainingMemory || '200 MB'}
          />
        )}
      </div>
    </div>
  );
}
export default MemorySectionIndividualProject;

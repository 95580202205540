import React from 'react';
import './filters.css';
import { Checkbox } from 'fireflink-ui';

export const FilterItemDefects = (props) => {
  return (
    <>
      {props.parentName.includes('Created') ? (
        <div className="item" title={props.label}>
          <div className='m-4'>
          <Checkbox
            label={props.label}
            name={props.parentName}
            onChange={(e) => props.handleFilterValues(e, props.parentGroup)}
            value={props.label}
            checked={props?.checkedCreatedByIds?.includes(props.label) || false}
          />
          </div>
        </div>
      ) : props.parentName.includes('Assign') ? (
        <div className="item" title={props.label}>
          <div className='m-4'>
          <Checkbox
            label={props.label}
            name={props.parentName}
            onChange={(e) => props.handleFilterValues(e, props.parentGroup)}
            value={props.label}
            checked={props?.checkedAssignToIds?.includes(props.label) || false}
          />
          </div>
        </div>
      ) : (
        <div className="item" title={props.label}>
          <div className='m-4'>
          <Checkbox
            label={props.label}
            name={props.parentName}
            onChange={(e) => props.handleFilterValues(e, props.parentGroup)}
            value={props.label}
            checked={props?.checkedLabelsIds?.includes(props.label) || false}
          />
          </div>
        </div>
      )}
    </>
  );
};

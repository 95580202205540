import React, { Fragment, useState, useEffect, useContext } from 'react';
import '../repository/repository.scss';
import TableTree from '../common/table_tree/table_tree';
import MoreInfoButton from '../common/more_info_button';
import { useHistory } from 'react-router-dom';
import {
  deleteFileReq,
  deleteFolderReq,
  getFilesReq,
  getFolderTreeReq,
  getSingleFolderReq,
  getSingleUserRequest,
  downloadFileFromCloud,
  updateFileReq,
  getOnlyContainerFolderTreeReq,
  cloneReq,
} from '@api/api_services';
import TestDataTreeToolbar from './testdata_treetoolbar';
import UserDetailsModal from '../test-development/shared/user-details-modal';
import FolderModal from './folder/folder_modal.js';
import DetailsModal from './shared/details_modal';
import DeleteModal from '../common/delete_modal';
import FileModal from './files/file_modal';
import { REPO } from '../../common/ui-constants';
import Tooltip from '@material-ui/core/Tooltip';
import EditFile from './files/edit_File';
import { encodeSpecialCharater } from '../test-development/shared/common-methods';
import Dropzone from 'react-dropzone';
import CloneInfoModal from '../common/cloneInfo_modal';
import LoaderModal from '../test-development/script/modules/module/modals/loader-modal';
import { CommonLoader } from '@pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { getTruncatedText, HighlightText, validateFileExtension } from '@src/util/common_utils';
import { AddCircleOutlineOutlined } from '@material-ui/icons';
import { TEST_DATA_FILE_ALLOWED } from '@src/util/validations';

function TestDataPage(props) {
  const { isEditable, hasFullAccess, isProjectOpen } = React.useMemo(
    () => ({
      isProjectOpen: window.permission?.isProjectOpen(),
      isEditable: window.permission?.isEditAccess('testData'),
      hasFullAccess: window.permission?.isFullAccess('testData'),
    }),
    []
  );
  let hasCloneOption = isProjectOpen && (isEditable || hasFullAccess);
  let renderTree;
  let [folder, setfolder] = useState(0);
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [message, setMessage] = useState();
  const [rootData, setRootData] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [reloadTree, setReloadTree] = useState(false);
  const [editData, setEditData] = useState();
  let [treeHeader, setTreeHeader] = useState('Test Data');
  const [node, setNode] = useState({});
  const [folderId, setFolderId] = useState();
  const [folderName, setFolderName] = useState();

  const [folderTree, setFolderTree] = useState([]);

  const [userDetails, setUserDetails] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState({});
  const [userDetailsHeader, setUserDetailsHeader] = React.useState({});
  const [showUserModal, setShowUserModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openFileEditModal, setOpenFileEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openFolderModal, setOpenFolderModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openFileModal, setOpenFileModal] = useState(false);
  const [mode, setMode] = useState();
  const [detailsType, setDetailsType] = useState('folder');
  const [details, setDetails] = useState({});
  const history = useHistory();
  let [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);
  let [nodeToAdd, setNodeToAdd] = useState(null);
  let [cloningContainerData, setCloningContainerData] = useState();
  let [openCloneInfoModal, setOpenCloneInfoModal] = useState(false);
  const [showLoaderModal, setShowLoaderModal] = useState(false);
  const project = JSON.parse(localStorage.getItem('selected-project'));
  const [resetFilter, setResetFilter] = useState(false);
  const [searchedText, setSearchedText] = useState('');
  const [searchedTextQuery, setSearchedTextQuery] = useState('');

  const search = window.location.search;
  const getId = new URLSearchParams(search).get('id');
  const [id, setId] = useState(getId);
  if (id !== getId) {
    setId(getId);
  }

  useEffect(() => {
    getFolderTreeFunction();
    setShowLoaderModal(false);
  }, [reloadTree, folder]);

  useEffect(() => {
    setShowLoaderModal(false);
  }, [id]);

  function getFolderTreeFunction() {
    let res;
    setTreeData([]);
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
      isBulkLoad: true,
    });
    setIsLoading(true);
    setFolderId();
    setFolderName();
    getOnlyContainerFolderTree(id);
    if (id) {
      getSingleFolderTreeData(id);
      resetProgress();
    } else {
      getFolderTreeReq()
        .then((response) => {
          setfolder(response.data.responseObject?.folderTree[0].subFolderCount);
          if (response.data.responseObject?.folderTree[0].subFolderCount === 0) {
            setNodeToAdd(0);
          } else {
            setNodeToAdd(null);
          }
          if (response.data.responseObject.folderTree[0]) {
            res = createTreeData(response.data.responseObject.folderTree, 'root');
            setTreeData(res.treeData);
            setRootData(res.rootData);
          }
          resetProgress();
          setIsLoading(false);
        })
        .catch((error) => {
          resetProgress();
          setIsLoading(false);
          console.error('GET_FOLDER_TREE : ', error);
        });
    }
  }
  const getSingleFolderTreeData = (id) => {
    let res;
    getFolderTreeReq(id)
      .then((childResponse) => {
        if (childResponse?.data?.responseObject) {
          setfolder(10);
          const { title: nodeTitle, parentName } = childResponse?.data?.responseObject?.folderTree[0];
          setTreeHeader(nodeTitle);
          if (nodeTitle.toLowerCase() === 'root' && parentName === undefined) {
            res = createTreeData(childResponse.data.responseObject.folderTree, 'root');
          } else {
            res = createTreeData(childResponse.data.responseObject.folderTree, 'child');
          }
          setTreeData(res.treeData);
          setRootData(res.rootData);
          resetProgress();
          setIsLoading(false);
        }
      })
      .catch((error) => {
        resetProgress();
        setIsLoading(false);
        console.error('GET_SINGLE_FOLDER_TREE :', error);
      });
  };

  const getOnlyContainerFolderTree = (id) => {
    if (id) {
      getOnlyContainerFolderTreeReq()
        .then((folderResponse) => {
          if (folderResponse?.data?.responseObject?.folderTree?.length) {
            setFolderTree(folderResponse.data.responseObject.folderTree);
          } else {
            setFolderTree([]);
          }
        })
        .catch((error) => {
          console.error('GET_ONLY_FOLDER_TREE :', error);
        });
    } else {
      getOnlyContainerFolderTreeReq(id)
        .then((folderResponse) => {
          if (folderResponse?.data?.responseObject?.folderTree?.length) {
            setFolderTree(folderResponse.data.responseObject.folderTree);
          } else {
            setFolderTree([]);
          }
        })
        .catch((error) => {
          console.error('GET_ONLY_SINGLE_FOLDER_TREE :', error);
        });
    }
  };

  const loadMoreData = async (_folderId) => {
    let _folderTree = [];
    try {
      let res;
      if (_folderId) {
        res = await getFolderTreeReq(_folderId);
      } else {
        res = await getFolderTreeReq();
      }
      if (res && res.data && res.data.responseCode === 200 && res.data.responseObject) {
        _folderTree = res.data.responseObject.folderTree[0].children;
      }
      return _folderTree;
    } catch (error) {
      console.error('error during load more folder data:', error);
      return _folderTree;
    }
  };

  //this method wrote because when we edit or delete we need to update the parent dropdown in modal
  const updateDropDown = async () => {
    try {
      // NOTE :: commented for future reference
      // const folderResponse = await getOnlyContainerFolderTreeReq(id);
      // const tempfolderTree = folderResponse?.data?.responseObject?.folderTree;
      // if (tempfolderTree?.length === 0 || (tempfolderTree[0].subFolderCount === 0 && tempfolderTree[0].fileCount === 0)) {
      //   history.push(`/testData`);
      // } else {
      // const allTreeResponse = await getFolderTreeReq();
      // console.log("dropdownTreeDataBefore -> ",dropdownTreeData);
      // console.log("allTreeResponse.data.responseObject.folderTree dropdownTreeDataAfter -> ",allTreeResponse.data.responseObject.folderTree);
      // setFolderTree([...tempfolderTree]);
      // }
    } catch (err) {
      console.error('GET_ONLY_FOLDERS : ', err);
    }
  };
  const createTreeData = (response, type) => {
    let res_obj = {
      treeData: [],
      rootData: {},
    };

    if (!response[0].children) {
      response[0]['children'] = [];
    }

    const { children, ...root_data } = response[0];
    if (type === 'root') {
      res_obj.treeData = response[0].children;
    } else {
      res_obj.treeData = response;
    }
    let title = root_data.title;
    if (!root_data.title.toLowerCase().includes('folder') && type === 'root') {
      root_data.title = title.concat(' Folder');
    }
    res_obj.rootData = root_data;
    return res_obj;
  };
  const reloadTreeData = (value) => {
    setReloadTree((pre) => !pre);
  };

  const closeModal = (val) => {
    if (openEditModal) {
      setOpenEditModal(val);
    } else if (openDeleteModal) {
      setOpenDeleteModal(val);
    } else if (openFolderModal) {
      setOpenFolderModal(val);
    } else if (openDetailsModal) {
      setOpenDetailsModal(val);
    } else if (openFileModal) {
      setOpenFileModal(val);
    } else if (openFileEditModal) {
      setOpenFileEditModal(val);
    } else if (openCloneInfoModal) {
      setOpenCloneInfoModal(val);
    }
  };

  const DeleteFunction = () => {
    const { title = '', folder, _key } = node?.data || {};
    if (folder) {
      deleteFolderReq(_key).then((res) => {
        if (res.data.responseCode === 200) {
          props.MyAlert.success(`${getTruncatedText(title, 20)} is deleted successfully`);
          closeModal(false);
          updateDropDown();
          node.node.remove();
          setReloadTree((pre) => !pre);
        }
      });
    } else {
      const { title = '', parentId } = node?.data || {};
      let fileId = node.node.key;
      let folderId = parentId;
      deleteFileReq(fileId, folderId).then((res) => {
        if (res.data.responseCode === 200) {
          props.MyAlert.success(`${getTruncatedText(title, 20)} is deleted successfully`);
          closeModal(false);
          node.node.remove();
          setReloadTree((pre) => !pre);
        }
      });
    }
  };

  const methodName = (name, nodeObj) => {
    setNode(nodeObj);
    if (name === 'Details') {
      if (nodeObj.data.folder) {
        detailsModal('folder', nodeObj);
      } else {
        detailsModal('file', nodeObj);
      }
    } else if (name === 'folder') {
      setFolderId(nodeObj.data._key);
      setFolderName(nodeObj.data.title);
      setNodeToAdd(nodeObj.node);
      setMode('ADD_SUB');
      setOpenFolderModal(true);
    } else if (name === 'file') {
      setFolderId(nodeObj.data._key);
      setFolderName(nodeObj.data.title);
      setNodeToAdd(nodeObj.node);
      setMode('ADD_SUB');
      setOpenFileModal(true);
    } else if (name === 'Clone') {
      if (nodeObj.data.folder) {
        setCloningContainerData(nodeObj.data);
        setOpenCloneInfoModal(true);
      } else {
        cloneFile(nodeObj.data);
      }
    }
  };

  const cloneFile = async (fileData) => {
    resetProgress();
    startOrResumeProgress({
      stopAt: 60,
      intervalDuration: 30,
    });
    let response;
    try {
      response = await cloneReq(fileData._key, fileData.parentId, 'files', project.id, null);
      if (response?.data?.status === 'SUCCESS' || response?.data?.message === 'SUCCESS') {
        props.MyAlert.success(`${getTruncatedText(fileData.name, 20)} file is cloned successfully`);
        setReloadTree((pre) => !pre);
      } else {
        stopProgress();
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 0,
        });
        setShowLoaderModal(false);
        setReloadTree((pre) => !pre);
        props.MyAlert.info(response.data.message);
      }
    } catch (err) {
      stopProgress();
      console.error('clone file api error', err);
    }
  };

  const cloneContainer = async (folderData) => {
    resetProgress();
    startOrResumeProgress({
      stopAt: 60,
      intervalDuration: 30,
    });
    let response;
    try {
      response = await cloneReq(folderData._key, folderData.parentId, 'folders', project.id, null);
      if (response?.data?.message === 'SUCCESS' || response?.data?.status === 'SUCCESS') {
        props.MyAlert.success(`${getTruncatedText(folderData.name, 20)} folder is cloned successfully`);
        setReloadTree((pre) => !pre);
      } else {
        stopProgress();
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 0,
        });
        props.MyAlert.info(response.data.message);
      }
    } catch (err) {
      stopProgress();
      console.error('clone folder api error', err);
    }
  };

  const detailsModal = (type, node) => {
    if (type === 'folder') {
      let key;
      if (node.isRootNode) {
        key = node.data.key;
      } else {
        key = node.data._key;
      }
      getSingleFolderReq(key).then((res) => {
        if (res.data.responseCode === 200 && res.data.responseObject) {
          setDetails(res.data.responseObject);
          setDetailsType('folder');
          setOpenDetailsModal(true);
        }
      });
    } else if (type === 'file') {
      getFilesReq(node.data._key).then((res) => {
        setDetails(res.data.responseObject);
        setDetailsType('file');
        setOpenDetailsModal(true);
      });
    }
  };

  const downloadFile = (node) => {
    downloadFileFromCloud(node.node.key).then((d) => {
      saveFileFromBlob(d.data, node.node.title);
    });
  };

  const replaceFile = async (files, nodeObj) => {
    const file = new FormData();
    files.map((data, index) => {
      file.append('file', data);
      return null;
    });

    if (validateFileExtension(files?.[0], TEST_DATA_FILE_ALLOWED)) {
      if (files[0]?.name === nodeObj?.data?.name) {
        let response;
        try {
          response = await updateFileReq(nodeObj?.data?._key, file);
          if (response.data.responseCode === 200 && response.data.status === 'SUCCESS') {
            props.MyAlert.success(`${response.data.responseObject.name} file replaced successfully`);
            reloadTreeData(true);
          } else {
            props.MyAlert.info(response.data.message);
          }
        } catch (err) {
          console.error('file replacement error', err);
        }
      } else {
        props.MyAlert.danger('Select same file name');
      }
    } else {
      props.MyAlert.warning(`${files?.[0]?.name} is unsupported`);
    }
  };

  function saveFileFromBlob(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  }

  const closeUserDetailsModal = (value) => {
    setOpenUserDetailsModal(value);
    setUserDetails({});
  };
  function getUserDetails(userNode) {
    getSingleUserRequest(userNode.modifiedBy).then((results) => {
      if (results.data.responseCode === 200 && results.data.responseObject) {
        setUserDetails(results.data.responseObject);
        setOpenUserDetailsModal(true);
      }
    });
  }
  const [treeValue, setTreeValue] = useState('');
  const [isExpandAll, setIsExpandAll] = useState(false);

  const rootNodeClicked = (node) => {
    if (
      node.title.toLowerCase() === 'root folder' &&
      node.parentName === undefined &&
      node.title.toLowerCase() !== treeHeader.toLocaleLowerCase() &&
      node.folder &&
      treeData?.length > 0
    ) {
      let libraryData = [
        {
          Name: node.title,
          id: node.key,
        },
      ];
      localStorage.setItem('parentAndSonJson', JSON.stringify(libraryData));
      history.push(`/testData/${node.title}_${node.key}/Folder?id=${node.key}`);
    }
  };

  const onNodeSelected = (nodeObj) => {
    var node = nodeObj.node;
    let expanded;
    let path;
    if (nodeObj.node.expanded) {
      expanded = true;
    } else {
      expanded = false;
    }
    if (nodeObj.node.folder) {
      getSingleFolderReq(node.key).then(async (response) => {
        if (response.data.responseCode === 200) {
          let tempPath = response.data.responseObject.path.slice(1).split('/');
          let tempKey = response.data.responseObject.searchKey.slice(1).split('/');
          let tempParentAndSonJSON = [];
          let tempURLPath = '';
          tempPath.forEach((tempValues, index) => {
            tempParentAndSonJSON.push({
              Name: index === 0 ? 'Root Folder' : tempValues,
              id: tempKey[index],
            });
            tempURLPath +=
              index === 0
                ? `Root Folder_${tempKey[index]}`
                : (tempURLPath === '' ? '' : '/') + encodeSpecialCharater(tempValues) + `_${tempKey[index]}`;
          });
          localStorage.setItem('parentAndSonJson', JSON.stringify(tempParentAndSonJSON));
          history.push(`/testData/${tempURLPath}/Folder?id=${nodeObj.data._key}`);
        }
      });
    } else {
      getFilesReq(nodeObj.data._key).then((response) => {
        if (response.data.responseCode === 200) {
          let tempPath = response.data.responseObject.actualPath.split('/').slice(5);
          let tempKey = response.data.responseObject.searchKey.slice(1).split('/');
          let tempParentAndSonJSON = [];
          let tempURLPath = '';
          tempPath.forEach((tempValues, index) => {
            if (index === 0 && treeHeader.toLowerCase() === 'test data') {
              tempParentAndSonJSON = [];
            } else {
              tempParentAndSonJSON.push({
                Name: index === 0 ? 'Root Folder' : tempValues,
                id: tempKey[index],
                parentId: index === tempPath.length - 1 ? tempPath[index - 1] : null,
              });
              tempURLPath +=
                index === 0
                  ? `Root Folder_${tempKey[index]}`
                  : (tempURLPath === '' ? '' : '/') + encodeSpecialCharater(tempValues) + `_${tempKey[index]}`;
            }
          });
          localStorage.setItem('parentAndSonJson', JSON.stringify(tempParentAndSonJSON));
          history.push(`/testData/${tempURLPath}/File?id=${nodeObj.data._key}&parentId=${node.data.parentId}`);
        }
      });
    }
  };

  const onCheckedNodes = (nodes) => {
    console.log(nodes);
  };

  const updatedValue = (value, type) => {
    const selectedNode = node.node;
    if (type === 'folder') {
      if (mode === 'EDIT') {
        selectedNode.title = value;
        selectedNode.render(true, true);
        updateDropDown();
      } else {
        selectedNode.children = value;
        if (selectedNode.expanded === true) {
          selectedNode.setExpanded(true);
        }
        updateDropDown();
      }
    } else {
      if (mode === 'EDIT') {
        selectedNode.title = value;
        selectedNode.render(true, true);
        updateDropDown();
      } else {
        value.forEach((element) => {
          selectedNode.addChildren(element);
        });
        updateDropDown();
      }
    }
  };
  const onDeleteClicked = (ev, nodeObj) => {
    setNode(nodeObj);
    if (nodeObj.data.folder) {
      if (nodeObj.node.children) {
        if (nodeObj.data.fileCount > 0 && nodeObj.data.subFolderCount === 0) {
          props.MyAlert.info(
            `You are not allowed to delete ${nodeObj.data.title} Folder as the folder contains some files`
          );
        } else if (nodeObj.data.fileCount === 0 && nodeObj.data.subFolderCount > 0) {
          props.MyAlert.info(
            `You are not allowed to delete ${nodeObj.data.title} folder as the folder  contains some sub folder`
          );
        } else {
          props.MyAlert.info(
            `You are not allowed to delete ${nodeObj.data.title} folder since it contains folders & files`
          );
        }
      } else {
        setMessage(`${nodeObj.data.title} Folder`);
        setOpenDeleteModal(true);
      }
    } else {
      setMessage(`${nodeObj.data.title} File`);
      setOpenDeleteModal(true);
    }
  };

  const stateColumnHtml = (node) => {
    return (
      <div className="state-col-wrp">
        <button className="state-button py-0 px-1 rounded-l">New</button>
        <button className="state-active py-0 px-1 rounded-l">Commit</button>
        <button className="state-button py-0 px-1 rounded-l">Publish</button>
      </div>
    );
  };

  const actionColumnHtml = (nodeObj) => {
    let type;
    if (nodeObj.isRootNode) {
      type = 'root';
    } else if (nodeObj.node.folder) {
      type = 'folder';
    } else {
      type = 'nonFolder';
    }
    return (
      <div className="action-col-wrp">
        {/* commenting the code for future use */}
        {/* {!(nodeObj.data.folder || nodeObj.isRootNode) ? (
          <button className="state-button py-0 px-1 rounded-l">
            <ImpactListActionMenu
              optionList={[{ name: "Scripts" }, { name: "Step Groups" }]}
              nodeObj={nodeObj}
              methodName={impactListHandler}
            />
          </button>
        ) : null} */}

        {global.permission.isFullAccess('testData') && !nodeObj.isRootNode && (
          <Tooltip title={nodeObj.data?.defaultFolder ? '' : 'Delete'} placement="top">
            <button
              disabled={nodeObj.data?.defaultFolder}
              className="state-button py-0 px-1 rounded-l "
              onClick={(event) => {
                onDeleteClicked(event, nodeObj);
              }}
            >
              <img src="/assets/images/delete_blue.svg" alt="" height="25px" width="25px" />
            </button>
          </Tooltip>
        )}

        {!nodeObj.isRootNode && (
          <Tooltip title="More" placement="top">
            <MoreInfoButton
              methodListOfFolder={hasCloneOption ? REPO.FOLDER_ACTION_MENU : REPO.ROOT_ACTION_MENU}
              methodListOfNonFolder={hasCloneOption ? REPO.FILE_ACTION_MENU : REPO.ROOT_ACTION_MENU}
              methodListForRoot={REPO.ROOT_ACTION_MENU}
              nodeObj={nodeObj}
              methodName={methodName}
              type={type}
              typeToDisplay="more"
              folderCount={folder}
            />
          </Tooltip>
        )}
      </div>
    );
  };

  const addFolderRootLevel = (name, nodeObj) => {
    methodName('folder', {
      ...nodeObj,
      node: { data: nodeObj.data, title: nodeObj.data.title, _key: nodeObj.data.key, key: nodeObj.data.key },
    });
  };

  const colDefs = [
    {
      field: 'title',
      title: 'FOLDER',
      class: 'title',
      width: `calc(100% - 870px)`,
      folderTitleWidth: `calc(100% - 730px)`,
      isTitle: true,
      render: (nodeObj) => {
        return (
          <>
            {nodeObj.isRootNode ? (
              <Tooltip title={`Folders - ${nodeObj.data.subFolderCount}`} placement="bottom">
                <span className="count-badge folder-count cursor-pointer">F {nodeObj.data.subFolderCount}</span>
              </Tooltip>
            ) : null}
            {/* {( nodeObj.node && nodeObj.node.data && nodeObj.node.data.subPageCount )? <span className="count-badge folder-count"> P {nodeObj.node.data.subPageCount} </span> : null} */}
            {nodeObj.node ? (
              nodeObj.node && nodeObj.node.data && nodeObj.node.folder && !nodeObj.data.isMockNode ? (
                <>
                  <Tooltip title={`Sub Folders - ${nodeObj?.node?.data?.subFolderCount}`} placement="bottom">
                    <span className="count-badge file-count cursor-pointer">
                      {' '}
                      SF {nodeObj?.node?.data?.subFolderCount || 0}{' '}
                    </span>
                  </Tooltip>
                  <Tooltip title={`Files - ${nodeObj.data.fileCount}`} placement="bottom">
                    <span className="count-badge file-count cursor-pointer">
                      {' '}
                      F {nodeObj.node.data.fileCount || 0}{' '}
                    </span>
                  </Tooltip>
                </>
              ) : nodeObj.isRootNode ? (
                <>
                  <Tooltip title={`Files - ${nodeObj.data.fileCount}`} placement="bottom">
                    <span className="count-badge file-count cursor-pointer"> F {nodeObj.data.fileCount}</span>
                  </Tooltip>
                  {isEditable && nodeObj.data.name === 'Root' && (
                    <button className="add-btn">
                      <span
                        onClick={() => {
                          addFolderRootLevel('folder', nodeObj);
                        }}
                      >
                        <Tooltip title="Add Folder" placement="right">
                          <AddCircleOutlineOutlined className="text-gray-400 hover:text-gray-600" />
                        </Tooltip>
                      </span>
                    </button>
                  )}
                </>
              ) : null
            ) : null}
            {global.permission.isEditAccess('testData') && nodeObj.node.folder && !nodeObj.data.isMockNode && (
              <Tooltip title="Add" placement="right">
                <button className="add-btn">
                  <MoreInfoButton
                    type="add"
                    nodeObj={nodeObj}
                    plusIconArray={REPO.TEST_DATA_PLUS_MENU}
                    methodName={methodName}
                  />
                </button>
              </Tooltip>
            )}
            {nodeObj.node.folder === false && (
              <Tooltip title="Download" placement="right">
                <button className="add-btn ">
                  <img
                    onClick={() => {
                      downloadFile(nodeObj);
                    }}
                    className="more-button relative inline-block text-left ml-1"
                    src="/assets/images/file_download.svg"
                    alt=""
                    height="25px"
                    width="25px"
                  />
                </button>
              </Tooltip>
            )}
            {nodeObj.node.folder === false && global.permission.isEditAccess('testData') && (
              <Dropzone noDrag={true} onDrop={(acceptedFiles) => replaceFile(acceptedFiles, nodeObj)}>
                {({ getRootProps, getInputProps }) => (
                  <Tooltip title="Replace" placement="right">
                    <div {...getRootProps({ className: 'dropzone add-btn inline-block' })}>
                      <input {...getInputProps()} className="inline-block" accept={Object.keys(TEST_DATA_FILE_ALLOWED)} />
                      <img
                        className="more-button relative inline-block text-left ml-1"
                        src="/assets/execution_logos/Replace_File.svg"
                        alt=""
                        height="25px"
                        width="25px"
                      />
                    </div>
                  </Tooltip>
                )}
              </Dropzone>
            )}
          </>
        );
      },
    },
    {
      field: 'fileType',
      title: 'TYPE',
      class: 'Type',
      width: '80px',
      render: (node) => {
        const {
          node: { title },
          data: { folder },
        } = node;
        let type;
        if (folder === false) {
          let id = title.lastIndexOf('.');
          type = title.slice(id + 1, title.length);
        }
        return (
          <Tooltip title={type} placement="top">
            <span>{getTruncatedText(type, 10)}</span>
          </Tooltip>
        );
      },
    },
    // {
    //   field: "state",
    //   title: "STATE",
    //   class: "state",
    //   width: "180px",
    //   render: (nodeObj) => {
    //     return (
    //       !nodeObj.data.isMockNode && stateColumnHtml(nodeObj)
    //     )
    //   },
    // },
    // {
    //   field: "ver",
    //   title: "VERSION",
    //   class: "version",
    //   width: "80px",
    //   render: (node) => {
    //     return (
    //       <span className="version-text-color  cursor-pointer">
    //         {node.data.ver?.toFixed(1)}
    //       </span>
    //     );
    //   },
    // },
    {
      field: 'modifiedByUname',
      title: 'MODIFIED BY',
      class: 'modified-by',
      width: '120px',
      render: (nodeObj) => {
        const {
          isRootNode,
          data: { isMockNode, modifiedByUname },
        } = nodeObj || {};
        return (
          <>
            {!isRootNode && !isMockNode && (
              <Tooltip title={modifiedByUname} placement="top">
                <div
                  className="version-text-color  cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap w-24"
                  onClick={() => getUserDetails(nodeObj.data)}
                  name="modifiedBy"
                >
                  <HighlightText text={modifiedByUname} highlight={searchedTextQuery} />
                </div>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      field: 'action',
      title: 'ACTIONS',
      class: 'action',
      width: '150px',
      render: (nodeObj) => {
        return !nodeObj.data.isMockNode && actionColumnHtml(nodeObj);
      },
    },
  ];

  const LABELS = {
    EXPAND_ALL: 'Expand All Folders',
    COLLAPSE_ALL: 'Collapse Folders',
    SHOW_ALL_ELEMENTS: 'Show all files',
    HIDE_ALL_ELEMENTS: 'Hide all files',
  };
  const searchTree = async (_searchText) => {
    setResetFilter(false);
    setTreeValue(_searchText);
    setSearchedTextQuery(searchedText);
  };

  const resetTreeFilter = () => {
    setResetFilter(true);
    setTreeValue('');
    setSearchedText('');
    setSearchedTextQuery('');
  };
  if (isLoading) {
    renderTree = <CommonLoader />;
  } else if (treeData && treeData.length) {
    if (props.pageType === 'parent') {
      renderTree = (
        <div className="table-height overflow-auto" id="journal-scroll">
          <TableTree
            data={treeData}
            rootData={rootData}
            colDefs={colDefs}
            filter={treeValue}
            showCheckbox={false}
            onCheckedNodes={onCheckedNodes}
            expandAll={isExpandAll}
            hideElements={false}
            hideConditions={true}
            onRootNodeClicked={rootNodeClicked}
            conditions={[{ key: 'isPreCondition', value: true }]}
            hideConditionBtn={true}
            labels={LABELS}
            onNodeSelected={onNodeSelected}
            id="test-data-page"
            expandAllApi={loadMoreData}
            loadMoreData={loadMoreData}
            childrenCountKeysArray={['fileCount', 'subFolderCount']}
            resetFilter={resetFilter}
            userSearched={searchedTextQuery}
            setResetFilter={setResetFilter}
          />
        </div>
      );
    } else {
      renderTree = (
        <div className="table-height overflow-auto" id="journal-scroll">
          <TableTree
            data={treeData}
            rootData={rootData}
            colDefs={colDefs}
            filter={treeValue}
            showCheckbox={false}
            onCheckedNodes={onCheckedNodes}
            expandAll={isExpandAll}
            hideElements={false}
            hideConditions={true}
            onRootNodeClicked={rootNodeClicked}
            conditions={[{ key: 'isPreCondition', value: true }]}
            hideConditionBtn={true}
            labels={LABELS}
            onNodeSelected={onNodeSelected}
            id="test-data-page"
            resetFilter={resetFilter}
            userSearched={searchedTextQuery}
            setResetFilter={setResetFilter}
          />
        </div>
      );
    }
  } else {
    renderTree = (
      <div>
        <div>
          <TableTree
            data={[]}
            rootData={rootData}
            colDefs={colDefs}
            filter={treeValue}
            showCheckbox={false}
            onCheckedNodes={onCheckedNodes}
            expandAll={isExpandAll}
            hideElements={false}
            hideConditions={true}
            onRootNodeClicked={rootNodeClicked}
            conditions={[{ key: 'isPreCondition', value: true }]}
            hideConditionBtn={true}
            labels={LABELS}
            onNodeSelected={onNodeSelected}
            id="test-data-page"
            resetFilter={resetFilter}
            userSearched={searchedTextQuery}
            setResetFilter={setResetFilter}
          />
        </div>

        <div className="no-steps z-0">
          {global.permission.isEditAccess('testData') && (
            <div className="">
              <p className="font-bold mb-4 fontPoppinsRegularMd">Quick Start</p>
              <p className="mb-4 fontPoppinsRegularMd">
                <span className="font-bold fontPoppinsRegularMd">Step 1 :</span> Click
                <button
                  onClick={() => {
                    setOpenFolderModal(true);
                    setMode('ADD');
                  }}
                  className="ml-2 mr-2 primary-btn"
                >
                  <span className="text-base fontPoppinsRegularMd">+</span> Folder
                </button>
                to start adding the Folder
              </p>
              <p className="mb-4 fontPoppinsRegularMd">
                <span className="font-bold fontPoppinsRegularMd">Step 2 :</span> Click
                <button disabled={true} className={`ml-2 mr-2 fontPoppinsMediumMd primary-btn btn-disabled`}>
                  <span className="text-base fontPoppinsMediumMd">+</span> File
                </button>
                to start uploading Files inside the Folder
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="page-table-body w-full" id="journal-scroll">
      <div className="content_area_style" id="journal-scroll">
        <TestDataTreeToolbar
          header={treeHeader}
          page="Test Data"
          containerCount={folder}
          treeData={folderTree}
          reloadTree={reloadTreeData}
          MyAlert={props.MyAlert}
          nodeToAdd={nodeToAdd}
          isLoading={isLoading}
          searchTree={searchTree}
          resetTree={resetTreeFilter}
          setSearchedText={setSearchedText}
        />
        {renderTree}

        {openEditModal ? (
          <FolderModal
            data={editData}
            closeModal={closeModal}
            reloadTree={reloadTreeData}
            treeData={folderTree}
            mode={mode}
            nodeToAdd={nodeToAdd}
            MyAlert={props.MyAlert}
            updatedValue={updatedValue}
          />
        ) : null}
        {openDeleteModal ? (
          <DeleteModal nodeToDel={node} message={message} closeModal={closeModal} method={DeleteFunction} />
        ) : null}
        {openFolderModal ? (
          <FolderModal
            nodeToAdd={nodeToAdd}
            closeModal={closeModal}
            reloadTree={reloadTreeData}
            treeData={folderTree}
            parentId={folderId}
            parentName={folderName}
            mode={mode}
            MyAlert={props.MyAlert}
            updatedValue={updatedValue}
          />
        ) : null}
        {openFileModal ? (
          <FileModal
            closeModal={closeModal}
            reloadTree={reloadTreeData}
            treeData={folderTree}
            parentId={folderId}
            nodeToAdd={nodeToAdd}
            parentName={folderName}
            mode={mode}
            MyAlert={props.MyAlert}
            updatedValue={updatedValue}
          />
        ) : null}
        {openDetailsModal ? <DetailsModal type={detailsType} data={details} closeModal={closeModal} /> : null}
        {showUserModal ? (
          <UserDetailsModal
            userDetailsHeader={userDetailsHeader}
            userDetails={userDetails}
            selectedUser={selectedUser}
            closeModal={closeModal}
          />
        ) : null}
        {openFileEditModal ? (
          <EditFile
            closeModal={closeModal}
            data={editData}
            nodeToAdd={nodeToAdd}
            parentName={folderName}
            MyAlert={props.MyAlert}
            reloadTree={reloadTreeData}
            updatedValue={updatedValue}
            mode={mode}
          />
        ) : null}
        {openUserDetailsModal ? <UserDetailsModal data={userDetails} closeModal={closeUserDetailsModal} /> : null}
        {openCloneInfoModal ? (
          <CloneInfoModal
            cloningContainerData={cloningContainerData}
            container={'Folder'}
            cloneContainer={cloneContainer}
            closeModal={closeModal}
          />
        ) : null}
        {showLoaderModal && <LoaderModal />}
      </div>
    </div>
  );
}

export default TestDataPage;
